import { CompleteReportData } from "../../Preview/components/graphs/graphTSInterface";
import { Divider } from "antd";
import { AreaInformations } from "./AreaInformations";
import { AreaListData } from "../../Preview/components/AreaListData";

interface PinsListData {
  areaList: AreaListData[];
  graphicsData: CompleteReportData;
  sessao_index: number;
  grafico_index: number;
}

export function PinsList({
  areaList,
  graphicsData,
  sessao_index,
  grafico_index,
}: PinsListData) {
  const filteredList = [];
  const filteredListStr: string[] = [];

  for (let index = 0; index < graphicsData.pins.length; index++) {
    const area = graphicsData.pins[index];
    for (let i = 0; i < area.values.length; i++) {
      const l2 = area.values[i];
      for (let j = 0; j < l2.values.length; j++) {
        const pinData = l2.values[j];
        const strin =
          pinData.a +
          pinData.c +
          pinData.d +
          pinData.r +
          pinData.t +
          pinData.x +
          pinData.y +
          area.id;
        if (filteredListStr.indexOf(strin) === -1) {
          filteredListStr.push(strin);
          const relatedArea: AreaListData = findAreaById(area.id);
          filteredList.push({
            ikey: filteredListStr.length,
            myname: relatedArea.label,
            studyType: relatedArea.studyType,
            color: relatedArea.cor,
            informations: [
              pinData.d,
              pinData.t,
              pinData.r + "",
              pinData.a,
              pinData.x + "",
              pinData.y + "",
            ],
          });
        }
      }
    }
  }

  function findAreaById(id: number) {
    for (let index = 0; index < areaList.length; index++) {
      const element = areaList[index];
      if (element.id === id) {
        return element;
      }
    }
    return {
      label: "",
      cor: "",
      studyType: "",
      points: [],
      id: -1,
    };
  }

  return (
    <div>
      <div
        key={"sessao" + sessao_index + "_" + (grafico_index - 1)}
        id={"sessao" + sessao_index + "_" + (grafico_index - 1)}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#459CA0",
          }}
        >
          Cor e Forma da Área, Nome do estabelecimento, Tipo de alfinete,
          Avaliação, Endereço, Latitude, Longitude
        </div>
      </div>
      <div style={{ display: "flex", padding: 5 }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {filteredList.map((area, indx) => {
            return (
              <div
                key={"sessaoPin" + indx}
                id={"sessaoPin" + indx}
                style={{ paddingBottom: 1 }}
              >
                <AreaInformations
                  ikey={area.ikey}
                  name={area.myname}
                  studyType={area.studyType}
                  color={area.color}
                  informations={area.informations}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Divider />
    </div>
  );
}
