import fonts from "../../../../../config/fonts";

interface BoxProps {
  title: string;
  text: string;
}

export function Box({ title, text }: BoxProps) {
  return (
    <div
      style={{
        border: "2px solid #D5D5D5",
        borderRadius: "8px",
        margin: "7px",
        paddingBlock: "14px",
        paddingInline: "24px",
      }}
    >
      <p
        style={{
          color: "#459CA0",
          margin: "5px",
          fontSize: fonts.sizes.large,
        }}
      >
        {title}
      </p>
      <p
        style={{
          color: "#B2B2B2",
          fontSize: fonts.sizes.large,
          lineHeight: "30px",
        }}
      >
        {text}
      </p>
    </div>
  );
}
