import { FaCircleExclamation } from "react-icons/fa6";
import { ModalDefault } from "../ModalDefault";
import { TitleModal } from "../../typography/TitleModal";
import { SubTitle } from "../../typography/SubTitle";
import { colors } from "../../../assets/colors";

interface ModalNoticeLimitReportsProps {
  setModalNoticeOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ModalNoticeLimitReports({
  setModalNoticeOpen,
}: ModalNoticeLimitReportsProps) {
  return (
    <ModalDefault
      icon={<FaCircleExclamation />}
      setModalOpen={setModalNoticeOpen}
      title="Limite de relatórios"
      styleContent={{
        maxWidth: 500,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TitleModal>LIMITE DE RELATÓRIOS</TitleModal>
        <SubTitle
          style={{
            textAlign: "center",
            marginBlock: 14,
          }}
        >
          Você atingiu o limite de relatórios na sua conta.
          <br />
          <br />
          Entre em contato com a nossa equipe para adquirir mais relatórios{" "}
          <a
            href=" https://wa.me/5548991158147?text=Tenho+uma+d%C3%BAvida+em+rela%C3%A7%C3%A3o+ao+Deepen+Maps"
            target="_blank"
            rel="noreferrer"
            style={{
              color: colors.default.primary.content,
              textDecoration: "underline",
            }}
          >
            clicando aqui
          </a>{" "}
          .
          <br />
          <br />
        </SubTitle>
      </div>
    </ModalDefault>
  );
}
