import React from "react";
import { Select } from "antd";

import { FaChevronDown } from "react-icons/fa";
import { useState } from "react";

const { Option } = Select;

export const Filter = (props) => {
  const {
    label,
    data,
    selectedType,
    setSelected,
    filter,
    disable,
    halfdisable,
    searchByNumber,
    sortList,
    required,
  } = props;
  const [selected, setSel] = useState();

  const filteredList = [];
  filter.forEach((element) => {
    //SIMPLIFICA OS LABELS TIRANDO OS ACENTOS E CRIANDO UMA COPIA DA LISTA
    if (halfdisable) {
      //ACEITA SOMENTE CNAES COM 5 DIGITOS OU MAIS CASO SOMENTE O ESTADO ESTEJA SELECIONADO
      if (element.value.indexOf(";") >= 5) {
        filteredList.push({
          label: (element.value + "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase(),
          value: element.value,
          key: element.key,
        });
      }
    } else {
      filteredList.push({
        label: (element.value + "")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase(),
        value: element.value,
        key: element.key,
      });
    }
  });
  const sortedfilter = filteredList.sort(
    (
      a,
      b //COMPARA OS LABELS SEM ACENTO PRA ORGANIZAR A LISTA
    ) => a.label.localeCompare(b.label)
  );
  sortedfilter.forEach((element) => {
    //TROCA OS LABELS PELOS VALUES PRA VOLTAR OS ACENTOS, JA QUE ORIGINALMENTE ELES ERAM IGUAIS
    element.label = element.value;
  });
  const sortedfilter2 = filter.sort(
    (
      a,
      b //FAZ MAIS UMA LISTA ORGANIZADA PELO KEY QUE É USADA EM PORTES E CAPITAIS SOCIAIS, PQ SE N FIZER O TRECO SE EMBARALHA DO NADA
    ) => a.key - b.key
  );

  const strForSearch = (str) => {
    return str
      ? str
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
      : str;
  };

  const startsWith = (str, input) => {
    return str ? str.substring(0, input.length) === input && str : str;
  };

  return (
    <div className="filtro">
      <Select
        showSearch
        //style={{ width: '100%', borderColor:()=>{if(required){return 'green'}else{ return selectedType.length>0?'green':'red'}}, border:2 }}
        style={{ width: "100%", marginBottom: 16 }}
        status={required ? (selectedType.length > 0 ? "" : "error") : ""}
        optionFilterProp="children"
        placeholder={label}
        disabled={disable}
        suffixIcon={<FaChevronDown id="iconFilter" />}
        filterOption={(input, option) => {
          if (option.value) {
            if (searchByNumber && !isNaN(Number(input))) {
              return startsWith(option.value.toString(), input);
            } else {
              return strForSearch(option.value).includes(strForSearch(input));
            }
          } else {
            return false;
          }
        }}
        value={selected}
        onChange={(value) => {
          if (value != null) {
            if (
              selectedType.indexOf(data ? data.indexOf(value) : value) === -1
            ) {
              setSelected([
                ...selectedType,
                data ? data.indexOf(value) : value,
              ]);
            }
            setSel(null);
          }
        }}
      >
        {sortList
          ? sortedfilter.map((line, idx) => {
              return (
                <Option value={line.value} key={idx}>
                  {line.label}
                </Option>
              );
            })
          : sortedfilter2.map((line, idx) => {
              return (
                <Option value={line.value} key={idx}>
                  {line.label}
                </Option>
              );
            })}
      </Select>
    </div>
  );
};
