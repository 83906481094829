import { hsl2rgb } from "../../../../../../../assets/colorService";

export function colorizeValuesFeatures(geoJsonState, actualFilters) {
  const {
    isUsingCities,
    slider_value,
    actualState,
    activeLabels,
    actualColors,
  } = actualFilters;
  let id;
  let min;
  let max;

  const normalizeData = (value, min, max) => {
    return (value - min) / (max - min);
  };

  const getValueMinMax = (label) => {
    const labelObj = actualState.labels.find((l) => l.name === label);
    if (labelObj) {
      id = labelObj.name;
      min = actualState.labelsMinMax[id].min;
      max = actualState.labelsMinMax[id].max;
    }
  };

  getValueMinMax(activeLabels[0]);

  for (let i = 0; i < geoJsonState.features.length; i++) {
    let reg = null;
    if (isUsingCities) {
      reg =
        actualState.sectorLabelValues[
          geoJsonState.features[i].properties.CD_GEOCODM
        ];
    } else {
      reg =
        actualState.sectorLabelValues[
          geoJsonState.features[i].properties.CD_GEOCODI
        ];
    }

    if (reg !== undefined) {
      const regValue = reg[id];
      const normalizedValue = normalizeData(regValue, min, max);

      let this_value;
      if (isUsingCities) {
        this_value = normalizedValue * actualState.labels[0].multiplierCity;
      } else {
        this_value = normalizedValue * actualState.labels[0].multiplier;
      }

      if (this_value > 0) {
        const l = Math.floor((this_value - 1) * -1 * (90 - 15 + 1)) + 30;
        const clampedL = Math.min(Math.max(l, 30), 90);
        const rgb = hsl2rgb(
          actualColors[0].hue / 360,
          80 / 100,
          clampedL / 100
        );
        geoJsonState.features[i].properties.color =
          "rgba(" +
          rgb[0] +
          "," +
          rgb[1] +
          "," +
          rgb[2] +
          "," +
          slider_value / 100 +
          ")";
      }
      //   geoJsonState.features[i].properties.color = {
      //     r: rgb[0] / 255,
      //     g: rgb[1] / 255,
      //     b: rgb[2] / 255,
      //     a: slider_value / 100,
      //   };
      // } else {
      //   geoJsonState.features[i].properties.color = {
      //     r: 0,
      //     g: 0,
      //     b: 0,
      //     a: 0,
      //   };
      // }
    }
  }
}
