const grauEmKm = 111.194927;
export function filterOutbounds(area, markers) {
  const result = [];
  //Considerando o raio da Terra como sendo 6.371 km (REGHELIN, 2007)
  //circunferencia igual 2 PI * raio = 40030.1736 km
  //grau por km igual resultado anterior dividido por 360 = 111.194927
  if (markers) {
    if (area.studyType === "RADIUS_STUDY") {
      for (let index = 0; index < markers.length; index++) {
        const element = markers[index];
        if (
          insideCircle(area.points[1], area.points[0], area.points[2], element)
        ) {
          result.push(element);
        }
      }
    } else if (area.studyType === "RECTANGLE_STUDY") {
      var y1 = area.points[0];
      var x1 = area.points[1];
      var y2 = area.points[2];
      var x2 = area.points[3];
      for (let index = 0; index < markers.length; index++) {
        const element = markers[index];
        if (insideRectangle(x1, y1, x2, y2, element)) {
          result.push(element);
        }
      }
    } else if (area.studyType === "POLYGON_STUDY") {
      //ugh, filtrar poligono...
      for (let index = 0; index < markers.length; index++) {
        const element = markers[index];
        //não sei porque o x e y do elemento estão vindo invertidos...
        if (insidePolygon(element.y, element.x, area.points)) {
          result.push(element);
        }
      }
    } else {
      console.log("Tipo de estudo não esperado: " + area.studyType);
    }
  }

  return result;
}

export function insideCircle(xCircle, yCircle, rayCircle, element) {
  const distX = (xCircle - element.x) * grauEmKm;
  const distY = (yCircle - element.y) * grauEmKm;
  const raio = Math.sqrt(distX * distX + distY * distY);
  return raio <= rayCircle;
}

export function insideRectangle(x1, y1, x2, y2, element) {
  var minX;
  var maxX;
  var minY;
  var maxY;
  if (x1 > x2) {
    maxX = x1;
    minX = x2;
  } else {
    maxX = x2;
    minX = x1;
  }
  if (y1 > y2) {
    maxY = y1;
    minY = y2;
  } else {
    maxY = y2;
    minY = y1;
  }
  return (
    maxX > element.x && minX < element.x && maxY > element.y && minY < element.y
  );
}

export function insidePolygon(x, y, polygon) {
  var inside = false;
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html
  /* Original
    for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        var xi = vs[i][0], yi = vs[i][1];
        var xj = vs[j][0], yj = vs[j][1];
        
        var intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    */
  for (
    var iX = 0, iY = 1, jX = polygon.length - 2, jY = polygon.length - 1;
    iX < polygon.length;
    jX = iX, jY = iY, iX += 2, iY += 2
  ) {
    var xi = polygon[iX],
      yi = polygon[iY];
    var xj = polygon[jX],
      yj = polygon[jY];

    var intersectP1 = yi > y;
    var intersectP2 = yj > y;
    var intersectP3 = intersectP1 !== intersectP2;
    var intersectP4 = x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    var intersect = intersectP3 && intersectP4;
    if (intersect) inside = !inside;
  }

  return inside;
}
