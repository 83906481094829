import React from "react";

interface Props {
  fill?: string;
  size?: number;
}

export const AddCompanyIcon = ({ fill, size = 18 }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill={fill}
      viewBox="0 0 22 22"
    >
      <g transform="translate(-1803 -71)">
        <path
          d="M10,11.75a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-1.5H0v4.5a1.538,1.538,0,0,0,1.5,1.5h13a1.538,1.538,0,0,0,1.5-1.5v-4.5H10Zm4.5-6.5H12V3.75a1.538,1.538,0,0,0-1.5-1.5h-5A1.538,1.538,0,0,0,4,3.75v1.5H1.5A1.538,1.538,0,0,0,0,6.75v2.5H16V6.75A1.538,1.538,0,0,0,14.5,5.25Zm-4.5,0H6v-1h4Z"
          transform="translate(1803 70.75)"
          fill="#fff"
        />
        <path
          d="M-6052-6713a.5.5,0,0,1-.5-.5v-2h-2a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h2v-2a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v2h2a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-2v2a.5.5,0,0,1-.5.5Z"
          transform="translate(7873 6791)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
