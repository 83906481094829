import L, { LatLngTuple } from "leaflet";
import { Shapes } from "../../../../../Types/Shapes";
import React from "react";
import { PolygonParamList } from "../../../../../Types/interfacs";
import { getDistanceBetweenPoints } from "../getDistanceBetweenPoints";
import { CircleProps } from "../types";
import { salvaLog } from "../../../../../services/getOptionsData";
import { deselectText } from "../../../Utils";

interface CreateCircleProps {
  map: L.Map;
  layerShapes: L.LayerGroup;
  color: string;
  numberOfStudies: number;
  setPolygon: React.Dispatch<React.SetStateAction<PolygonParamList>>;
  setShapeActive: React.Dispatch<React.SetStateAction<Shapes>>;
  setShapeSelectedToEdition: React.Dispatch<
    React.SetStateAction<{
      id: number;
      shape: L.Circle;
      optionsButton: {
        editButton: boolean;
        deleteButton: boolean;
      };
    }>
  >;
}

export const CreateCircle = ({
  map,
  layerShapes,
  color,
  setPolygon,
  setShapeActive,
  setShapeSelectedToEdition,
}: CreateCircleProps) => {
  let centerLatLng: [number, number];
  let secondLatLng: [number, number];
  let radius: number = 0;
  let circle: CircleProps;
  let toolTip: L.Tooltip;

  map.on("mousedown", handleClickDown);
  map.on("mouseup", handleClickUp);
  map.getContainer().style.cursor = "crosshair";

  function handleClickDown(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      map.dragging.disable();
      centerLatLng = [e.latlng.lat, e.latlng.lng];
      map.on("mousemove", handleMouseMove);
      circle = drawCircle(centerLatLng, radius, color) as CircleProps;

      map.on("mouseout", (e: L.LeafletMouseEvent) => {
        document.addEventListener("mouseup", handleClickUp);

        const zoom = map.getZoom();
        if (circle) {
          const center = circle.getBounds().getCenter();

          const middlePoint = [
            (center.lat + secondLatLng[0]) / 2,
            (center.lng + secondLatLng[1]) / 2,
          ] as LatLngTuple;

          map.setView(middlePoint, zoom);
        }
      });
      circle.addTo(layerShapes);
    } else if (e.originalEvent.button === 1) {
      map.getContainer().style.cursor = "grabbing";
    }
  }

  function handleMouseMove(e: L.LeafletMouseEvent) {
    secondLatLng = [e.latlng.lat, e.latlng.lng];

    radius = getDistanceBetweenPoints(centerLatLng, secondLatLng);

    // Limitando o raio a 100 km
    const maxRadius = 100000; // 100 km em metros
    if (radius > maxRadius) {
      radius = maxRadius;
    }

    if (circle) {
      circle.setRadius(radius);
    }
    circle._path.style.cursor = "crosshair";

    if (toolTip) {
      toolTip.remove();
    }

    const distanceKmRounded = Math.round((radius / 1000) * 100) / 100;

    toolTip = L.tooltip()
      .setLatLng(secondLatLng)
      .setContent(
        `<div
        style="
        background-color: white;
          padding: 5px;
          font-size: 14px;
          font-weight: bold;
        "
        >${distanceKmRounded} km</div>`
      )
      .addTo(map);
  }

  function handleClickUp() {
    map.off("mousedown");
    map.off("mousemove");

    map.off("mouseout");
    document.removeEventListener("mouseup", handleClickUp);

    if (circle && circle.getRadius() > 0) {
      salvaLog({
        action: "Finalizou Círculo",
        params: "",
      }).then((result: any) => {
      });

      setShapeActive("arrow");
      if (toolTip) {
        toolTip.remove();
      }

      addCircleToStudy(circle, setPolygon);

      const idShape = circle._leaflet_id;

      circle.on("mousedown", () => {
        map.off("click");
      });

      circle.on("click", () => {
        setShapeSelectedToEdition({
          id: idShape,
          shape: circle,
          optionsButton: {
            editButton: true,
            deleteButton: true,
          },
        });
      });

      deselectText();

      map.dragging.enable();
    } else {
      map.on("mousedown", handleClickDown);
      map.on("mouseup", handleClickUp);
      circle && layerShapes.removeLayer(circle);

      map.getContainer().style.cursor = "crosshair";
    }
  }

  return null;
};

export function drawCircle(
  centerLatLng: [number, number],
  radius: number,
  color: string,
  dashed?: boolean
) {
  const circle = L.circle(centerLatLng, {
    radius,
    color,
    dashArray: dashed ? "15, 15" : "",
    weight: 4,
    fillOpacity: 0.1,
  });

  return circle;
}

export function addCircleToStudy(
  circle: CircleProps,
  setPolygon: React.Dispatch<React.SetStateAction<PolygonParamList>>
) {
  const idShape = circle._leaflet_id;

  const type = "RADIUS_STUDY";
  const color = circle.options.color as string;
  const { lat, lng } = circle.getLatLng();
  const centerLatLng = [lat, lng];

  const radius = circle.getRadius();

  setPolygon((prev: PolygonParamList) => {
    return {
      ...prev,
      radiusList: [
        ...prev.radiusList,
        {
          id: idShape,
          points: [centerLatLng],
          ray: radius / 1000,
          type,
          color,
        },
      ],
    };
  });
}
