import L, { LatLngTuple } from "leaflet";
import { Shapes } from "../../../../../Types/Shapes";
import React from "react";
import {
  getDistanceBetweenPoints,
  getPointAtDistanceLimit,
} from "../getDistanceBetweenPoints";
import { SurroundingsParams } from "../../../../../Types/Params";
import { SurroundingsProps } from "../types";
import { EditRectangle } from "../Edition/EditRectangle";
import { salvaLog } from "../../../../../services/getOptionsData";
import { deselectText } from "../../../Utils";

interface CreateSurroundingsProps {
  map: L.Map;
  layerShapes: L.LayerGroup;
  color: string;
  setShapeActive: React.Dispatch<React.SetStateAction<Shapes>>;
  setParamsToSurroundings: React.Dispatch<
    React.SetStateAction<SurroundingsParams>
  >;
  setButtonToConfirmSurroundings: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateSurroundings = ({
  map,
  layerShapes,
  color,
  setShapeActive,
  setParamsToSurroundings,
  setButtonToConfirmSurroundings,
}: CreateSurroundingsProps) => {
  let firstLatLng: [number, number];
  let secondLatLng: [number, number];
  let distanceBetweenPoints: number;
  let surroundings: SurroundingsProps;
  let toolTip: L.Tooltip;
  let editMarker: L.Marker;
  let deleteMarker: L.Marker;

  map.getContainer().style.cursor = "crosshair";

  map.on("mousedown", handleClickDown);
  map.on("mouseup", handleClickUp);

  function handleClickDown(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      map.dragging.disable();
      firstLatLng = [e.latlng.lat, e.latlng.lng];
      map.on("mousemove", handleMouseMove);
      surroundings = drawRectangle(
        firstLatLng,
        secondLatLng,
        color
      ) as SurroundingsProps;
      map.on("mouseout", (e: L.LeafletMouseEvent) => {
        document.addEventListener("mouseup", handleClickUp);

        const zoom = map.getZoom();
        if (surroundings) {
          const center = surroundings.getBounds().getCenter();

          const middlePoint = [
            (center.lat + secondLatLng[0]) / 2,
            (center.lng + secondLatLng[1]) / 2,
          ] as LatLngTuple;

          map.setView(middlePoint, zoom);
        }
      });

      surroundings.addTo(layerShapes);
    } else if (e.originalEvent.button === 1) {
      map.getContainer().style.cursor = "grabbing";
    }
  }

  function handleMouseMove(e: L.LeafletMouseEvent) {
    secondLatLng = [e.latlng.lat, e.latlng.lng];

    secondLatLng = getPointAtDistanceLimit(firstLatLng, secondLatLng, 250000);

    distanceBetweenPoints = getDistanceBetweenPoints(firstLatLng, secondLatLng);

    if (toolTip) {
      toolTip.remove();
    }

    if (surroundings) {
      surroundings.setBounds([firstLatLng, secondLatLng]);
    }

    surroundings._path.style.cursor = "crosshair";

    const distanceKm = distanceBetweenPoints / 1000;
    const distanceKmRounded = Math.round(distanceKm * 100) / 100;

    toolTip = L.tooltip()
      .setLatLng(secondLatLng)
      .setContent(
        `<div
          style="
          background-color: white;
            padding: 5px;
            font-size: 14px;
            font-weight: bold;
          "
          >${distanceKmRounded < 250 ? distanceKmRounded : "Max 250"} km</div>`
      )
      .addTo(map);
  }

  function handleClickUp() {
    map.off("mousedown");
    map.off("mouseup");
    map.off("mousemove");
    map.off("mouseout");
    document.removeEventListener("mouseup", handleClickUp);

    if (surroundings && distanceBetweenPoints > 0) {
      salvaLog({
        action: "Finalizou surroundings",
        params: "",
      }).then((result: any) => {
      });

      const idShape = surroundings._leaflet_id;

      setParamsToSurroundings((oldParams: any) => {
        return {
          ...oldParams,
          rect: {
            index: idShape,
            lat1: firstLatLng[0],
            lat2: secondLatLng[0],
            lon1: firstLatLng[1],
            lon2: secondLatLng[1],
          },
        };
      });

      setShapeActive("arrow");
      if (toolTip) {
        toolTip.remove();
      }

      const northEast = surroundings.getBounds().getNorthEast();

      const editIcon = L.icon({
        iconUrl: "edit-variable.png",
        iconSize: [35, 35],
        iconAnchor: [80, 45],
      });

      const editActiveIcon = L.icon({
        iconUrl: "edit-variable-active.png",
        iconSize: [35, 35],
        iconAnchor: [80, 45],
      });

      const deleteIcon = L.icon({
        iconUrl: "delete.png",
        iconSize: [35, 35],
        iconAnchor: [35, 45],
      });

      editMarker = L.marker(northEast, {
        icon: editIcon,
      });

      deleteMarker = L.marker(northEast, {
        icon: deleteIcon,
      });

      deleteMarker.addTo(layerShapes);

      editMarker.addTo(layerShapes);

      deleteMarker.setOpacity(0.7);

      editMarker.setOpacity(0.7);

      deleteMarker.on("mouseover", () => {
        map.dragging.disable();
        deleteMarker.setOpacity(1);
      });

      deleteMarker.on("mouseout", () => {
        map.dragging.enable();
        deleteMarker.setOpacity(0.7);
      });

      editMarker.on("mouseover", () => {
        map.dragging.disable();
        editMarker.setOpacity(1);
      });

      editMarker.on("mouseout", () => {
        map.dragging.enable();
        editMarker.setOpacity(0.7);
      });

      deleteMarker.on("click", () => {
        map.dragging.enable();
        layerShapes.removeLayer(surroundings);
        layerShapes.removeLayer(editMarker);
        layerShapes.removeLayer(deleteMarker);

        setParamsToSurroundings((oldParams: any) => ({
          ...oldParams,
          rect: null,
        }));

        setButtonToConfirmSurroundings(false);

        setShapeActive("surroundings");
      });

      editMarker.on("click", () => {
        if (editMarker.options.icon === editIcon) {
          editMarker.setIcon(editActiveIcon);
          setButtonToConfirmSurroundings(false);
          EditRectangle({
            map,
            layerShapes,
            editMarker,
            deleteMarker,
            rectangle: surroundings,
          });
        } else {
          editMarker.setIcon(editIcon);
          map.off("mousemove");
          map.off("mouseup");

          const surroundingsEdited = surroundings.getBounds();

          setParamsToSurroundings((oldParams: any) => ({
            ...oldParams,
            rect: {
              index: idShape,
              lat1: surroundingsEdited.getSouthWest().lat,
              lat2: surroundingsEdited.getNorthEast().lat,
              lon1: surroundingsEdited.getSouthWest().lng,
              lon2: surroundingsEdited.getNorthEast().lng,
            },
          }));

          setButtonToConfirmSurroundings(true);
        }
      });

      deselectText();

      setButtonToConfirmSurroundings(true);

      map.dragging.enable();
    } else {
      map.on("mousedown", handleClickDown);
      map.on("mouseup", handleClickUp);
      surroundings && layerShapes.removeLayer(surroundings);

      map.getContainer().style.cursor = "crosshair";
    }
  }

  function drawRectangle(
    firstLatLng: [number, number],
    secondLatLng: [number, number],
    color: string
  ) {
    const rectangle = L.rectangle([firstLatLng, secondLatLng], {
      color: color,
      dashArray: "15, 15",
      weight: 4,
      fillOpacity: 0.1,
    });

    return rectangle;
  }

  return null;
};
