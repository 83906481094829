import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { colors } from "../../assets/colors";
import { SelectOption, SelectOptionRadio } from "../../Types/Variable";
import { Radio } from "../Radio/Radio";

interface SelectProps {
  defaultValue?: string;
  placeholder?: React.ReactNode;
  options?: SelectOptionRadio[];
  closeOnSelect?: boolean;
  clearOnSelect?: boolean;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  borderRadius?: number;
  style?: CSSProperties;
  visibleItemCount?: number;
}

type StylesObject = Record<string, CSSProperties>;

const backgroundColor = colors.default.primary.background;
const grayColor = colors.default.disabled.content;
const shadowColor = colors.shadow;

export function SelectRadios({
  options,
  borderRadius,
  closeOnSelect = false,
  clearOnSelect = false,
  placeholder,
  onChange,
  style,
  visibleItemCount = 10, // Número de itens visíveis inicialmente
}: SelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    null
  );

  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: SelectOption, index: number) => {
    !clearOnSelect && setSelectedOption(option);
    if (option === selectedOption) {
      setSelectedOption(null);
      onChange("");
    } else {
      setSelectedOption(option);
      onChange(option.value ? option.value : "");
    }
    closeOnSelect && setIsOpen(false);
  };

  return (
    <div style={{ ...selectStyles.container, ...style }} ref={selectRef}>
      <div
        style={{ ...selectStyles.header, borderRadius: borderRadius || 5 }}
        onClick={toggleDropdown}
      >
        <div
          style={{
            ...selectStyles.selectedOption,
          }}
        >
          {placeholder}
        </div>
      </div>
      {isOpen && options && (
        <ul style={selectStyles.optionList}>
          {options.map((option, index) => {
            return (
              <React.Fragment key={index}>
                <Radio
                  id={option.value as string}
                  color="primary"
                  isChecked={option.value === selectedOption?.value}
                  onChange={() => handleOptionClick(option, index)}
                >
                  {option.label}
                </Radio>
              </React.Fragment>
            );
          })}
        </ul>
      )}
    </div>
  );
}

const selectStyles: StylesObject = {
  container: {
    position: "relative",
    width: "100%",
    height: 48,
    color: grayColor,
  },
  header: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    padding: 8,
    paddingLeft: 12,
    border: `1px solid ${grayColor}`,
    display: "flex",
    alignItems: "center",
  },
  selectedOption: {
    marginRight: 8,
  },
  caretIcon: {
    marginLeft: "auto",
  },
  optionList: {
    listStyle: "none",
    position: "absolute",
    zIndex: 9999,
    top: "105%",
    width: "100%",
    border: `1px solid ${grayColor}`,
    borderRadius: 5,
    background: backgroundColor,
    boxShadow: `0px 2px 8px ${shadowColor}`,
    height: 300,
    overflow: "scroll",
  },
  styleSuboptionItem: {
    cursor: "pointer",
    padding: 8,
    paddingLeft: 12,
    height: 48,
    transition: "background 0.3s",
    display: "flex",
    alignItems: "center",
  },
};
