import { ButtonDefault } from "../../../../../components/Button/ButtonDefault";
import { FaMousePointer } from "react-icons/fa";
import { BsSquareFill, BsPentagonFill, BsCircleFill } from "react-icons/bs";
import { MeasureIcon } from "../../../../../components/icon/MeasureIcon";
import { ExpandableToolbar } from "../../../../../components/ExpandableToolbar/ExpandableToolbar";
import { Shapes } from "../../../../../Types/Shapes";
import { UserToken } from "../../../../../services/userToken";
import GA4 from "react-ga4";
import { PolygonParamList } from "../../../../../Types/interfacs";
import { useEffect, useState } from "react";
import {
  getValidLimitStudies,
  salvaLog,
} from "../../../../../services/getOptionsData";
import { colors } from "../../../../../assets/colors";
import { ModalNoticeLimitStudies } from "../../../../../components/Modal/ModalNotices/ModalNoticeLimitStudies";

interface GeometricShapesProps {
  polygon: PolygonParamList;
  shapeActive: Shapes;
  setShapeActive: React.Dispatch<React.SetStateAction<Shapes>>;
  processing: boolean;
}

export function GeometricShapes({
  polygon,
  shapeActive,
  setShapeActive,
  processing,
}: GeometricShapesProps) {
  const [limitExceeded, setLimitExceeded] = useState(true);
  const [modalNoticeOpen, setModalNoticeOpen] = useState(false);
  const [limit, setLimit] = useState(0);

  useEffect(() => {
    const numberStudies =
      polygon.polygonList.length +
      polygon.rectangleList.length +
      polygon.radiusList.length;

    getValidLimitStudies({
      used: numberStudies + 1,
    }).then((response) => {
      if (!response) return;
      setLimit(response.limit);
      setLimitExceeded(response.valid);
    });
  }, [polygon]);

  return (
    <>
      <ExpandableToolbar startOpen>
        <ButtonDefault
          icon={<FaMousePointer />}
          color={shapeActive === "arrow" ? "primary" : "disabled"}
          transparent={true}
          onClick={() => {
            salvaLog({
              action: "Selecionou Ferramenta de Movimentação",
              params: "",
            }).then((result: any) => {});
            setShapeActive("arrow");
          }}
        />
        <ButtonDefault
          icon={<MeasureIcon />}
          color={shapeActive === "measure" ? "primary" : "disabled"}
          transparent={true}
          onClick={() => {
            salvaLog({
              action: "Selecionou Ferramenta de Régua",
              params: "",
            }).then((result: any) => {});
            setShapeActive("measure");
          }}
        />
        <ButtonDefault
          icon={<BsSquareFill />}
          color={
            limitExceeded
              ? shapeActive === "rectangle"
                ? "primary"
                : "disabled"
              : "orange"
          }
          transparent={true}
          onClick={() => {
            if (!limitExceeded) {
              setModalNoticeOpen(true);
            } else {
              salvaLog({
                action: "Selecionou Ferramenta de Retângulo",
                params: "",
              }).then((result: any) => {});
              setShapeActive("rectangle");
            }
          }}
        />
        <ButtonDefault
          icon={<BsCircleFill />}
          color={
            limitExceeded
              ? shapeActive === "circle"
                ? "primary"
                : "disabled"
              : "orange"
          }
          transparent={true}
          onClick={() => {
            if (!limitExceeded) {
              setModalNoticeOpen(true);
            } else {
              salvaLog({
                action: "Selecionou Ferramenta de Círculo",
                params: "",
              }).then((result: any) => {});
              setShapeActive("circle");
            }
          }}
        />
        <ButtonDefault
          icon={<BsPentagonFill />}
          color={
            limitExceeded
              ? shapeActive === "polygon"
                ? "primary"
                : "disabled"
              : "orange"
          }
          transparent={true}
          onClick={() => {
            if (!limitExceeded) {
              setModalNoticeOpen(true);
            } else {
              salvaLog({
                action: "Selecionou Ferramenta de Polígono",
                params: "",
              }).then((result: any) => {});
              setShapeActive("polygon");
            }
          }}
        />
      </ExpandableToolbar>

      {modalNoticeOpen && (
        <ModalNoticeLimitStudies
          limit={limit}
          setModalNoticeOpen={setModalNoticeOpen}
        />
      )}
    </>
  );
}
