import { FaUpload } from "react-icons/fa";
import { SubTitle } from "../../../../../../components/typography/SubTitle";
import { Title } from "../../../../../../components/typography/Title";
import { ButtonDefault } from "../../../../../../components/Button/ButtonDefault";

export function FileUploadSection() {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          width: 1124,
        }}
      >
        <ButtonDefault
          style={{
            marginRight: 0,
            marginLeft: 0,
            width: 554,
            paddingLeft: 0,
          }}
          size="superLarge"
          title={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: 450,
              }}
            >
              <Title
                style={{
                  color: undefined,
                }}
              >
                <FaUpload /> &nbsp; UPLOAD ARQUIVOS
              </Title>
              <SubTitle
                style={{
                  color: undefined,
                  paddingBottom: 0,
                }}
              >
                Faça o upload dos seus dados de maneira simples e receba por
                email uma
              </SubTitle>

              <SubTitle
                style={{
                  color: undefined,
                  paddingTop: 0,
                }}
              >
                notificação de quando seus dados estiverem prontos para uso.
              </SubTitle>
            </div>
          }
          iconPosition="right"
          border={true}
          color={"secondary"}
          onClick={() => {}}
        />
        <ButtonDefault
          style={{
            marginRight: 0,
            marginLeft: 0,
            width: 554,
            paddingLeft: 0,
          }}
          size="superLarge"
          title={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: 450,
              }}
            >
              <Title
                style={{
                  color: undefined,
                }}
              >
                <FaUpload /> &nbsp; PROCESSAR POTENCIAL
              </Title>
              <SubTitle
                style={{
                  color: undefined,
                  paddingBottom: 0,
                }}
              >
                Insira um json com os parâmetros necessários para criar o
                potencial da
              </SubTitle>

              <SubTitle
                style={{
                  color: undefined,
                  paddingTop: 0,
                }}
              >
                empresa que esta sendo adicionada/atualizada.
              </SubTitle>
            </div>
          }
          iconPosition="right"
          border={true}
          color={"secondary"}
          onClick={() => {}}
        />
      </div>
    </>
  );
}
