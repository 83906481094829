import { colors } from "../../../assets/colors";
import { SubTitle } from "../../../components/typography/SubTitle";
import { Title } from "../../../components/typography/Title";

interface OptionCompanyProps {
  name: string;
  cnae: string;
  active?: boolean;
  separator?: boolean;
  setSelected: () => void;
}

export function OptionCompany({
  name,
  cnae,
  active,
  separator,
  setSelected,
}: OptionCompanyProps) {
  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "16px 16px 0px ",
          borderRadius: 6,
          cursor: "pointer",
          borderEndEndRadius: !active ? 0 : 6,
          borderEndStartRadius: !active ? 0 : 6,
          borderBottom: separator && !active ? "2px solid #E5E5E5" : undefined,
          backgroundColor: active
            ? colors.default.secondary.background
            : "transparent",
          boxShadow: active ? `0px 0px 10px ${colors.shadow}` : undefined,
        }}
        onClick={setSelected}
      >
        <Title
          style={{
            fontWeight: "bold",
          }}
          color={active ? colors.default.secondary.content : undefined}
        >
          {name}
        </Title>
        <SubTitle
          color={
            active
              ? colors.default.secondary.content
              : colors.default.disabled.content
          }
        >
          {cnae}
        </SubTitle>
      </div>
    </>
  );
}
