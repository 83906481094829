import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { getCookie, setCookie } from "../cookies/cookiesManager";
import { Loading } from "../components/Loading/Loading";
import { getCompanyFilters } from "../services/getOptionsData";
import { UserToken } from "../services/userToken";

interface AuthProviderProps {
  children: ReactNode;
}

interface User {
  sub: string;
  ROLES: string[];
  contract: number;
  company_name: string;
  iss: string;
  company: number;
  id: number;
  exp: number;
  user: number;
  username: string;
}

interface setUserProps {
  token: string;
  remember: boolean;
  pass: string;
}

interface AuthContextData {
  user: User | null;
  logout(): void;
  setAuthUser(data: setUserProps): Promise<void>;
}

export const AuthContext = createContext({} as AuthContextData);

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const exdays = 30;

  const parseJwt = (token: string) => {
    if (!token) {
      return null;
    }

    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace("-", "+").replace("_", "/");
    var json = JSON.parse(window.atob(base64));

    return json;
  };

  async function setAuthUser({ token, remember, pass }: setUserProps) {
    var user: User = parseJwt(token);
    if (!remember) {
      setCookie("@deepen:login", "", exdays);
      setCookie("@deepen:pass", "", exdays);
      setCookie("@deepen:token", "", exdays);
    } else {
      setCookie("@deepen:login", JSON.stringify(user), exdays);
      setCookie("@deepen:pass", pass, exdays);
      setCookie("@deepen:token", token, exdays);
    }
    setUser(user);
    UserToken.login = user.username;
    UserToken.token = token;
    getCompanyFilters(user.contract).then((limiters) => {
      UserToken.defaultfilters = limiters;
    });
  }

  function hasPermission(requiredPermission: string) {
    if (!user) {
      return false;
    }

    // Verifique se a permissão requerida está presente nas permissões do usuário
    const hasPermission = user.ROLES.includes(requiredPermission);

    return hasPermission;
  }

  useEffect(() => {
    const isTokenValid = (storagetoken: any) => {
      var tokenParsed = parseJwt(storagetoken);
      if (tokenParsed == null) {
        return false;
      }

      return tokenParsed.exp < new Date();
    };

    async function loadUserStorageDate() {
      const storageUser = getCookie("@deepen:login");
      const storagepass = getCookie("@deepen:pass");
      const storagetoken = getCookie("@deepen:token");

      if (storageUser && storagepass && storagetoken) {
        const user = JSON.parse(storageUser);
        if (isTokenValid(storagetoken)) {
          setUser(user);
          UserToken.login = user.username;
          UserToken.token = storagetoken;
          getCompanyFilters(user.contract).then((limiters) => {
            UserToken.defaultfilters = limiters;
          });

          setCookie("@deepen:login", storageUser, exdays);
          setCookie("@deepen:pass", storagepass, exdays);
          setCookie("@deepen:token", storagetoken, exdays);
        }
      }
      setLoading(false);
    }
    loadUserStorageDate();
  }, []);

  useEffect(() => {
    document.addEventListener("logout", logout);

    return () => {
      document.removeEventListener("logout", logout);
    };
  }, []);

  async function logout() {
    setUser(null);
    setCookie("@deepen:pass", "", exdays);
    setCookie("@deepen:token", "", exdays);
    document.location.reload();
  }

  return (
    <AuthContext.Provider value={{ user, logout, setAuthUser }}>
      {loading ? <Loading /> : <>{children}</>}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
