import { hsl2rgb } from "../../../../../../../assets/colorService";

export function colorizeDominioFeatures(geoJsonState, actualFilters) {
  const {
    isUsingCities,
    slider_value,
    actualState,
    activeLabels,
    actualColors,
  } = actualFilters;

  let valor;
  let maior;
  let soma = 0;
  let idmaior = -1;
  let valorResult;
  let rotulosUsados;
  let reg = null;
  let count = 0;

  for (let i = 0; i < geoJsonState.features?.length; i++) {
    maior = Number.NEGATIVE_INFINITY;
    soma = 0;
    rotulosUsados = 0;
    if (isUsingCities) {
      reg =
        actualState.sectorLabelValues[
          geoJsonState.features[i].properties.CD_GEOCODM
        ];
    } else {
      reg =
        actualState.sectorLabelValues[
          geoJsonState.features[i].properties.CD_GEOCODI
        ];
    }

    if (reg !== undefined) {
      // eslint-disable-next-line no-loop-func
      activeLabels.forEach((label, j) => {
        valor = reg[label];

        if (valor > maior) {
          maior = valor;
          idmaior = j;
        }

        soma += valor;
        rotulosUsados++;
      });

      valorResult = ((maior - soma / rotulosUsados) / soma) * 2;

      if (Number.isNaN(valorResult)) {
        valorResult = 0;
      } else if (valorResult > 1) {
        valorResult = 1;
      }

      if (maior > 0) {
        const l = Math.floor((valorResult - 1) * -1 * (90 - 15 + 1)) + 30;
        const clampedL = Math.min(Math.max(l, 30), 90);
        const rgb = hsl2rgb(
          actualColors[idmaior].hue / 360,
          80 / 100,
          clampedL / 100
        );

        geoJsonState.features[i].properties.color =
          "rgba(" +
          rgb[0] +
          "," +
          rgb[1] +
          "," +
          rgb[2] +
          "," +
          slider_value / 100 +
          ")";
      }
      //   geoJsonState.features[i].properties.color = {
      //     r: rgb[0] / 255,
      //     g: rgb[1] / 255,
      //     b: rgb[2] / 255,
      //     a: slider_value / 100,
      //   };
      // } else {
      //   geoJsonState.features[i].properties.color = {
      //     r: 0,
      //     g: 0,
      //     b: 0,
      //     a: 0,
      //   };
      // }
    }
  }
}
