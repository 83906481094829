import { FaMapMarkerAlt, FaTrash } from "react-icons/fa";
import { ModalDefault } from "../../../../../components/Modal/ModalDefault";
import { TitleModal } from "../../../../../components/typography/TitleModal";
import { SubTitle } from "../../../../../components/typography/SubTitle";

import { Fragment, useEffect, useState } from "react";
import { Divider } from "antd";
import { ButtonDefault } from "../../../../../components/Button/ButtonDefault";
import { CustomInput } from "../../../../../components/Input/CustomInput";
import { Checkbox2 } from "../../../../../components/Checkbox/CheckBox2";
import { VariableSelectOption } from "../../../../../Types/Variable";
import { AutoComplete } from "../../../../../components/AutoComplete/AutoComplete";
import { MdModeEditOutline } from "react-icons/md";
import { ReactComponent as RayExclusivity } from "../../../../../assets/icons/raio-de-exclusividade-icon.svg";
import { PiSelectionPlusBold } from "react-icons/pi";
import { Shapes } from "../../../../../Types/Shapes";
import {
  EditPinParams,
  RadiusExclusivityParams,
} from "../../../../../Types/Params";
import { MarkIcon } from "../../../../../components/icon/MarkIcon";
import { FaCircleExclamation } from "react-icons/fa6";

interface ModalRadiusExclusivityProps {
  limit: number;
  setPinsOnEdition: React.Dispatch<React.SetStateAction<EditPinParams>>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  variableOptions?: VariableSelectOption[];
  setShapeActive: React.Dispatch<React.SetStateAction<Shapes>>;
  setButtonToConfirmPinsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  paramsToRadiusExclusivity: RadiusExclusivityParams;
  setParamsToRadiusExclusivity: React.Dispatch<
    React.SetStateAction<RadiusExclusivityParams>
  >;
  setButtonToConfirmRadiusExclusivity: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

interface ParamsProps {
  disabled: boolean;
  name: string;
  nameDesc: string;
  nameShort: string;
  value: string;
  variable: string;
}

interface RangeProps {
  [key: string]: ParamsProps;
}

export function ModalRadiusExclusivity({
  limit,
  setPinsOnEdition,
  setModalOpen,
  variableOptions,
  setShapeActive,
  setButtonToConfirmPinsVisible,
  paramsToRadiusExclusivity,
  setParamsToRadiusExclusivity,
  setButtonToConfirmRadiusExclusivity,
}: ModalRadiusExclusivityProps) {
  const [params, setParams] = useState<RangeProps>({});
  const [disabled, setFormDisabled] = useState<boolean>(true);
  const [studyAreas, setStudyAreas] = useState<boolean>(true);
  const [pinsOrAreasMarked, setPinsOrAreasMarked] = useState<boolean>(false);
  const [modalNoticeOpen, setModalNoticeOpen] = useState<boolean>(false);

  useEffect(() => {
    let studyAreas;
    if (paramsToRadiusExclusivity) {
      studyAreas = paramsToRadiusExclusivity.studyAreas;

      const newParams: RangeProps = {};
      paramsToRadiusExclusivity.labels?.forEach((label) => {
        newParams[label.name] = {
          disabled: true,
          name: label.name,
          nameDesc: label.nameDesc,
          nameShort: label.nameShort,
          value: String(label.value),
          variable: label.variable,
        };
      });
      setStudyAreas(studyAreas);
      setParams(newParams);

      if (paramsToRadiusExclusivity.points)
        if (
          paramsToRadiusExclusivity.rects.length > 0 ||
          paramsToRadiusExclusivity.points.length > 0
        ) {
          setPinsOrAreasMarked(true);
        }
    }
  }, [paramsToRadiusExclusivity]);

  useEffect(() => {
    if (Object.values(params)?.length > 0) {
      let oneEmpty = false;
      Object.values(params)?.forEach((params) => {
        if (params.value === "") {
          oneEmpty = true;
        }
      });
      setFormDisabled(oneEmpty);
    } else {
      setFormDisabled(true);
    }
  }, [params]);

  const handleParamsToRadiusExclusivity = () => {
    const newParamsToRadiusExclusivity = {
      ...paramsToRadiusExclusivity,
      labels: Object.values(params).map((options) => ({
        ...options,
        value: Number(options.value),
      })),
    };
    setParamsToRadiusExclusivity({
      ...newParamsToRadiusExclusivity,
      studyAreas,
    });
  };

  return (
    <ModalDefault
      icon={<RayExclusivity />}
      setModalOpen={() => {
        setModalOpen(false);
        setParamsToRadiusExclusivity({
          ...paramsToRadiusExclusivity,
          deletePoints: true,
          deleteRects: true,
        });
      }}
      title="Raio de Exclusividade"
      styleContent={{
        maxWidth: 546,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TitleModal>PARÂMETROS</TitleModal>
        <SubTitle
          style={{
            textAlign: "center",
            marginBottom: 26,
          }}
        >
          Entenda o raio de atuação necessário para que um local cubra uma
          determinada audiência.
        </SubTitle>

        <div style={{ width: "100%", marginTop: 16 }}>
          <AutoComplete
            searchFilter={(text, options) => {
              return options
                ?.map((item: any) => ({
                  ...item,
                  options: item.options.filter((option: any) =>
                    option.label.toUpperCase().includes(text.toUpperCase())
                  ),
                }))
                .filter((item) => item.options.length > 0);
            }}
            clearOnSelect
            options={variableOptions}
            placeholder="Selecione os rótulos para análise"
            onChange={(newvalue, option) => {
              const name = newvalue;
              if (!name || !option) return;

              setParams({
                ...params,
                [name]: {
                  disabled: true,
                  name,
                  nameDesc: option.label,
                  nameShort: option.label,
                  value: "",
                  variable: option.variable,
                },
              });
            }}
          />
        </div>

        {params &&
          Object.values(params).map(({ name, value }) => {
            return (
              <Fragment key={name}>
                <div
                  style={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: 1,
                      backgroundColor: "#f0f0f0",
                    }}
                  />

                  <SubTitle
                    style={{
                      margin: "0 10px",
                      maxWidth: 300,
                      textAlign: "center",
                    }}
                  >
                    {name}
                  </SubTitle>

                  <div
                    style={{
                      flex: 1,
                      height: 1,
                      backgroundColor: "#f0f0f0",
                    }}
                  />
                </div>

                <div
                  style={{
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <CustomInput
                    style={{
                      marginRight: 8,
                    }}
                    value={value}
                    onChange={(e) => {
                      const newParams = { ...params };
                      newParams[name].value = e.target.value;
                      setParams(newParams);
                    }}
                    autocomplete="off"
                    placeholder="Valor"
                    type="number"
                  />

                  <div style={{ flex: 1, marginLeft: 8 }}>
                    <ButtonDefault
                      style={{
                        borderRadius: "24px",
                        margin: 0,
                      }}
                      icon={<FaTrash />}
                      size={"smallTwo"}
                      color={"secondary"}
                      onClick={() => {
                        const newParams = { ...params };
                        delete newParams[name];
                        setParams(newParams);
                      }}
                    />
                  </div>
                </div>
              </Fragment>
            );
          })}
        <Checkbox2
          style={{ marginTop: "16px" }}
          color="primary"
          label="Desejo criar estudo de área"
          onChange={() => {
            if (paramsToRadiusExclusivity.points?.length > limit) {
              setModalNoticeOpen(true);
              return;
            }
            setParamsToRadiusExclusivity((prev) => {
              return {
                ...prev,
                studyAreas: !studyAreas,
              };
            });
          }}
          checked={studyAreas}
        />
        <div
          style={{
            width: "100%",
            marginTop: 16,
            alignItems: "center",
            display: "flex",
          }}
        >
          <ButtonDefault
            disabled={disabled}
            color={"secondary"}
            style={{
              justifyContent: "space-between",
              marginRight: "auto",
              marginLeft: "auto",
            }}
            size="medium"
            title="MARCAR PONTOS DE REFERÊNCIA"
            icon={<MarkIcon />}
            iconPosition="right"
            border={true}
            onClick={() => {
              setShapeActive("pinsToRadiusExclusivity");
              setModalOpen(false);
              handleParamsToRadiusExclusivity();
              setButtonToConfirmPinsVisible(true);
            }}
          />
          {paramsToRadiusExclusivity.points?.length > 0 && (
            <div style={{ flex: 1, marginLeft: 8 }}>
              <ButtonDefault
                style={{
                  margin: 0,
                }}
                icon={<MdModeEditOutline />}
                size={"medium"}
                color={"secondary"}
                onClick={() => {
                  setButtonToConfirmPinsVisible(true);
                  setPinsOnEdition("radiusExclusivity");
                  setModalOpen(false);
                }}
              />
            </div>
          )}
        </div>
        <div
          style={{
            width: "100%",
            marginTop: 16,
            alignItems: "center",
            display: "flex",
          }}
        >
          <ButtonDefault
            style={{
              justifyContent: "space-between",
              marginRight: "auto",
              marginLeft: "auto",
            }}
            icon={<PiSelectionPlusBold />}
            size="medium"
            title="MARCAR ÁREAS DE REFERÊNCIA"
            iconPosition="right"
            disabled={disabled}
            color={"secondary"}
            border={true}
            onClick={() => {
              setModalOpen(false);
              setButtonToConfirmRadiusExclusivity(true);
              handleParamsToRadiusExclusivity();
              setShapeActive("radiusExclusivity");
            }}
          />
          {paramsToRadiusExclusivity.rects?.length > 0 && (
            <div style={{ flex: 1, marginLeft: 8 }}>
              <ButtonDefault
                style={{
                  margin: 0,
                }}
                icon={<MdModeEditOutline />}
                size={"medium"}
                color={"secondary"}
                onClick={() => {}}
              />
            </div>
          )}
        </div>

        <Divider />

        <ButtonDefault
          style={{
            width: "min-content",
          }}
          icon={<FaMapMarkerAlt />}
          size="medium"
          title="COMEÇAR ANÁLISE"
          iconPosition="right"
          disabled={!pinsOrAreasMarked}
          color={pinsOrAreasMarked ? "secondary" : "disabled"}
          border={true}
          borderRadius={40}
          onClick={() => {
            setModalOpen(false);
            setParamsToRadiusExclusivity({
              ...paramsToRadiusExclusivity,
              searchAreas: true,
            });
          }}
        />
        {modalNoticeOpen && (
          <ModalDefault
            icon={<FaCircleExclamation />}
            setModalOpen={setModalNoticeOpen}
            title="Limite de estudos"
            styleContent={{
              maxWidth: 500,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TitleModal>LIMITE DE ESTUDOS</TitleModal>
              <SubTitle
                style={{
                  textAlign: "center",
                  marginBlock: 14,
                }}
              >
                Tem Alfinetes demais no mapa. Por favor, remova alguns para
                ativar o estudo de área.
              </SubTitle>
            </div>
          </ModalDefault>
        )}
      </div>
    </ModalDefault>
  );
}
