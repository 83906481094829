import { VariableSelectOption } from "../../../Types/Variable";
import { SubOptionItem } from "./SubOptionItem";

interface OptionGroupProps {
  option: VariableSelectOption;
  handleOptionClick: (
    option: VariableSelectOption,
    index: number,
    variable: string
  ) => void;
}

const style = {
  color: "#888",
  padding: "4px 8px",
  fontWeight: "bold",
};

export function OptionGroup({ option, handleOptionClick }: OptionGroupProps) {
  return (
    <>
      <li style={style}>
        <strong>{option.label}</strong>
      </li>
      {option.options?.map((subOption, subIndex) => (
        <SubOptionItem
          key={subIndex}
          option={subOption}
          index={subIndex}
          handleOptionClick={(subOption, subIndex) =>
            handleOptionClick(subOption, subIndex, option.label)
          }
        />
      ))}
    </>
  );
}
