import { Divider } from "antd";
import { useState } from "react";
import {
  FaArrowAltCircleRight,
  FaArrowCircleRight,
  FaArrowRight,
  FaCheck,
  FaCheckDouble,
  FaCopy,
  FaCross,
  FaEdit,
  FaEye,
  FaEyeSlash,
  FaPlus,
  FaQuestionCircle,
  FaRegSave,
  FaSave,
  FaShareAlt,
  FaSync,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import { FaCircleArrowUp, FaFloppyDisk, FaX } from "react-icons/fa6";
import { colors } from "../../../../assets/colors";
import { ButtonDefault } from "../../../../components/Button/ButtonDefault";
import { ModalDefault } from "../../../../components/Modal/ModalDefault";
import { PolygonParamList, PolygonProps } from "../../../../Types/interfacs";
import {
  atualizaEstudo,
  getEstudosSalvos,
  salvaEstudos,
  salvaLog,
} from "../../../../services/getOptionsData";
import { MapData } from "../../../../services/mapData";
import domtoimage from "dom-to-image";
import { httpConfig } from "../../../../services/httpConfig";
import GA4 from "react-ga4";
import { UserToken } from "../../../../services/userToken";
import { Loading } from "../../../../components/Loading/Loading";
import {
  RectProp,
  PolyProp,
  CircleProp,
} from "../ToolsLayer/PointsOfInterest/PointsOfInterest";
import { IconType } from "react-icons/lib";

export interface SaveLoadModalProps {
  polygonParams: PolygonParamList;
  setPolygon: React.Dispatch<React.SetStateAction<PolygonParamList>>;
  updatefunction: (polygon: any) => void;
}
var triedLoading: boolean = false;
export function SaveLoadModal({
  polygonParams,
  updatefunction,
}: SaveLoadModalProps) {
  const [modalVariables, setModalVariables] = useState(false);
  const [updateIconsNow, setUpdateIconsNow] = useState(false);
  const [saveList, setSaveList] = useState<SaveState[]>([]);
  const [nome, setNome] = useState<string>();
  const [salvandoAnalise, setSalvandoAnalise] = useState(false);
  const [linkSaveItem, setLinkSaveItem] = useState<SaveState | null>();
  const [linkSave, setLinkSave] = useState<boolean>(false);
  const [itemAserSalvo, setItemAserSalvo] = useState<SaveState | null>();
  const [mensagem, setMensagem] = useState<string>("");
  const [mensagemIconAndTitle, setMensagemIconAndTitle] = useState({
    icon: <FaCheckDouble />,
    title: "Confirmação",
  });
  const [mensagemAparecendo, setMensagemAparecendo] = useState<boolean>(false);
  const [mostraConfirmaExluir, setMostraConfirmaExluir] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [itemAserExcluido, setItemAserExcluido] = useState<SaveState | null>();
  const [itemAserAdicionado, setItemAserAdicionado] =
    useState<SaveState | null>();
  const [appendOrOverwrite, setAppendOrOverwrite] = useState(false);

  if (!triedLoading) {
    triedLoading = true;
    loadSaveStates();
  }
  async function loadSaveStates() {
    const result = await getEstudosSalvos();
    if (result) {
      setSaveList(result);
    }
  }
  if (updateIconsNow) {
    setUpdateIconsNow(false);
  }

  interface SaveState {
    id: number;
    study: {
      rectangleList: RectProp[];
      polygonList: PolyProp[];
      radiusList: CircleProp[];
      regionList: [];
      tokens: [];
      pins: [];
    };
    link: string;
    token: string;
    createTime: number;
    name: string;
    isPublic: boolean;
    visible: boolean;
    userId: number;
  }

  function convertCircleList(radiusList: PolygonProps[]): CircleProp[] {
    const answer: CircleProp[] = [];
    for (let index = 0; index < radiusList.length; index++) {
      const element = radiusList[index];
      answer.push({
        index: element.id,
        ilat: element.points[0][0],
        ilon: element.points[0][1],
        radius: element.ray!,
      });
    }
    return answer;
  }

  function convertRectList(rectList: PolygonProps[]): RectProp[] {
    const answer: RectProp[] = [];
    for (let index = 0; index < rectList.length; index++) {
      const element = rectList[index];
      answer.push({
        index: element.id,
        lat1: element.points[0][0],
        lon1: element.points[0][1],
        lat2: element.points[1][0],
        lon2: element.points[1][1],
      });
    }
    return answer;
  }

  function convertPolyList(polyList: PolygonProps[]): PolyProp[] {
    const answer: PolyProp[] = [];
    for (let index = 0; index < polyList.length; index++) {
      const element = polyList[index];
      const arr = [];
      for (let i = 0; i < element.points.length; i++) {
        const point = element.points[i];
        arr.push({ lat: point[0], lon: point[1] });
      }

      answer.push({ index: element.id, latlngs: arr });
    }
    return answer;
  }

  function convertItemToParamSaveNew(item: SaveState, dataUrl: string) {
    const param = {
      name: item.name,
      isPublic: item.isPublic,
      saveStudy: {
        rectangleList: item.study.rectangleList,
        polygonList: item.study.polygonList,
        radiusList: item.study.radiusList,
        regionList: [],
        tokens: item.token,
        pins: [],
        mapPrint: dataUrl,
      },
    };
    return param;
  }

  function convertItemToParamUpdate(item: SaveState) {
    const param = {
      id: item.id,
      study: {
        rectangleList: item.study.rectangleList,
        polygonList: item.study.polygonList,
        radiusList: item.study.radiusList,
        regionList: [],
        tokens: [],
        pins: [],
      },
      link: item.link,
      token: item.token,
      createTime: item.createTime,
      name: item.name,
      isPublic: item.isPublic,
      visible: item.visible,
      userId: item.userId,
    };
    return param;
  }

  function revertCircleParamsToItem(radiusList: CircleProp[]): PolygonProps[] {
    const answer: PolygonProps[] = [];
    for (let index = 0; index < radiusList.length; index++) {
      const element = radiusList[index];
      const mypoints: number[][] = [[element.ilat, element.ilon]];
      answer.push({
        id: index,
        points: mypoints,
        ray: element.radius,
        type: "RADIUS_STUDY",
        color: "blue",
      });
    }
    return answer;
  }

  function revertRectParamsToItem(rectList: RectProp[]): PolygonProps[] {
    const answer: PolygonProps[] = [];
    for (let index = 0; index < rectList.length; index++) {
      const element = rectList[index];
      const mypoints: number[][] = [
        [element.lat1, element.lon1],
        [element.lat2, element.lon2],
      ];
      answer.push({
        id: index,
        points: mypoints,
        ray: 0,
        type: "RECTANGLE_STUDY",
        color: "red",
      });
    }
    return answer;
  }

  function revertPolyParamsToItem(polyList: PolyProp[]): PolygonProps[] {
    const answer: PolygonProps[] = [];
    for (let index = 0; index < polyList.length; index++) {
      const element = polyList[index];
      const mypoints: number[][] = [];
      for (let indexx = 0; indexx < element.latlngs.length; indexx++) {
        const latlng = element.latlngs[indexx];
        mypoints.push([latlng.lat, latlng.lon]);
      }
      answer.push({
        id: index,
        points: mypoints,
        ray: 0,
        type: "POLYGON_STUDY",
        color: "yellow",
      });
    }
    return answer;
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      <ButtonDefault
        icon={<FaFloppyDisk />}
        color={"primary"}
        onClick={() => {
          setModalVariables(!modalVariables);
        }}
      />
      {modalVariables ? (
        <ModalDefault
          icon={<FaFloppyDisk />}
          setModalOpen={setModalVariables}
          title="Minhas Análises"
          styleContent={{
            maxWidth: 700,
          }}
        >
          <p
            style={{
              color: colors.default.primary.content,
              fontWeight: "bold",
              fontSize: "25px",
              padding: "20px",
              paddingTop: "0px",
            }}
          >
            ANÁLISES SALVAS
          </p>
          <p
            style={{
              color: colors.default.disabled.content,
              paddingBottom: "20px",
            }}
          >
            Você pode salvar na lista abaixo novas analises ou carregar o
            resultado de analises salvas previamente com seus estudos.
          </p>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              paddingLeft: "20px",
              paddingRight: "20px",
              borderColor: "lightgray",
              borderRadius: "10px",
              overflow: "scroll",
              maxHeight: "300px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderStyle: "solid",
                borderWidth: "1px",
                borderBottom: "1",
                borderTop: "0",
                borderLeft: "0",
                borderRight: "0",
                borderColor: "lightgray",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  color: colors.default.primary.content,
                  fontWeight: "bold",
                }}
              >
                Nome
              </div>
              <div
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  color: colors.default.primary.content,
                  fontWeight: "bold",
                }}
              >
                Ações
              </div>
            </div>
            {saveList?.map((item, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderBottom: "0",
                    borderTop: idx === 0 ? "0" : "1",
                    borderLeft: "0",
                    borderRight: "0",
                    borderColor: "lightgray",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "50%",
                      alignContent: "center",
                      paddingTop: "12px",
                      paddingBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    {item.name}
                  </div>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "space-evenly",
                      paddingTop: "10px",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>
                      <ButtonDefault
                        icon={<FaEdit />}
                        style={{
                          borderRadius: "15px",
                          margin: 0,
                        }}
                        size={"small"}
                        color={"deepblue"}
                        transparent={false}
                        onClick={() => {
                          setItemAserSalvo(item);
                          setNome(item.name);
                          setSalvandoAnalise(true);
                          salvaLog({
                            action: "Iniciou renomeação de estudo",
                            params: "item id " + item.id,
                          }).then((result: any) => {});
                        }}
                        tooltip="Renomear"
                      />
                    </div>
                    <div>
                      <ButtonDefault
                        icon={item.isPublic ? <FaEye /> : <FaEyeSlash />}
                        style={{
                          borderRadius: "15px",
                          margin: 0,
                        }}
                        size={"small"}
                        color={item.isPublic ? "lightblue" : "gray"}
                        transparent={false}
                        onClick={() => {
                          item.isPublic = !item.isPublic;
                          setUpdateIconsNow(true);
                          salvaLog({
                            action: "Trocou visibilidade de estudo",
                            params: "item id " + item.id,
                          }).then((result: any) => {});
                        }}
                        tooltip={item.isPublic ? "Público" : "Privado"}
                      />
                    </div>
                    <div>
                      <ButtonDefault
                        icon={<FaSave />}
                        style={{
                          borderRadius: "15px",
                          margin: 0,
                        }}
                        size={"small"}
                        color={"green"}
                        transparent={false}
                        onClick={() => {
                          const imgnode = document.getElementById("my-map");
                          if (imgnode) {
                            domtoimage
                              .toPng(imgnode, {
                                width: MapData.width,
                                height: MapData.height,
                              })
                              .then(async function (dataUrl) {
                                item.study = {
                                  rectangleList: convertRectList(
                                    polygonParams.rectangleList
                                  ),
                                  polygonList: convertPolyList(
                                    polygonParams.polygonList
                                  ),
                                  radiusList: convertCircleList(
                                    polygonParams.radiusList
                                  ),
                                  pins: [],
                                  regionList: [],
                                  tokens: [],
                                };
                                await atualizaEstudo(
                                  item.id,
                                  convertItemToParamUpdate(item)
                                );
                                loadSaveStates();
                                setMensagemAparecendo(true);
                                setMensagemIconAndTitle({
                                  icon: <FaCheckDouble />,
                                  title: "Ação Concluída!",
                                });
                                setMensagem("Estudo sobreescrito com sucesso!");
                                salvaLog({
                                  action: "Sobreescreveu estudo",
                                  params: "item id " + item.id,
                                }).then((result: any) => {});
                              });
                          }
                        }}
                        tooltip={"Sobreescrever"}
                      />
                    </div>
                    {/*
                    <div>
                      <ButtonDefault
                        icon={<FaShareAlt />}
                        style={{
                          borderRadius: "15px",
                          margin: 0,
                        }}
                        size={"small"}
                        color={"yellow"}
                        transparent={false}
                        onClick={() => {
                          setLinkSaveItem(item);
                          setLinkSave(true);                          
                          salvaLog({
                                  action: "Compartilhou estudo",
                                  params: "item id " + item.id,
                                }).then((result: any) => {
                                });
                        }}
                        tooltip={"Compartilhar"}
                      />
                    </div>
                    */}
                    <div>
                      <ButtonDefault
                        icon={<FaTrash />}
                        style={{
                          borderRadius: "15px",
                          margin: 0,
                        }}
                        size={"small"}
                        color={"orange"}
                        transparent={false}
                        onClick={async () => {
                          setMostraConfirmaExluir(true);
                          setItemAserExcluido(item);
                        }}
                        tooltip={"Excluir"}
                      />
                    </div>
                    <div>
                      <ButtonDefault
                        icon={<FaCheck />}
                        style={{
                          borderRadius: "15px",
                          margin: 0,
                        }}
                        size={"small"}
                        color={"gray"}
                        transparent={false}
                        onClick={() => {
                          setAppendOrOverwrite(true);
                          setItemAserAdicionado(item);
                        }}
                        tooltip={"Usar no mapa."}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            {salvandoAnalise ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: "15px",
                  borderColor: "lightgray",
                  padding: "10px",
                }}
              >
                <input
                  className="search-input"
                  style={{
                    border: 0,
                    fontFamily: "'D-DIN Regular', sans-serif",
                    fontSize: 16,
                    width: "546px",
                  }}
                  type="text"
                  onChange={(event) => {
                    setNome(event.target.value);
                  }}
                  placeholder={"Digite o nome aqui..."}
                  defaultValue={itemAserSalvo ? itemAserSalvo.name : ""}
                />

                <div
                  style={{
                    display: "flex",
                    width: "60px",
                    justifyContent: "space-between",
                  }}
                >
                  <ButtonDefault
                    icon={<FaCheck />}
                    style={{
                      borderRadius: "15px",
                      margin: 0,
                    }}
                    size={"small"}
                    color={"secondary"}
                    transparent={false}
                    onClick={() => {
                      if (nome !== "") {
                        var found = false;
                        for (let index = 0; index < saveList.length; index++) {
                          const element = saveList[index];
                          if (
                            element !== itemAserSalvo &&
                            element.name === nome
                          ) {
                            found = true;
                          }
                        }
                        if (!found) {
                          setLoading(true);
                          const imgnode = document.getElementById("my-map");
                          if (imgnode) {
                            domtoimage
                              .toPng(imgnode, {
                                width: MapData.width,
                                height: MapData.height,
                              })
                              .then(async function (dataUrl) {
                                if (itemAserSalvo && nome) {
                                  itemAserSalvo.name = nome;
                                  await atualizaEstudo(
                                    itemAserSalvo.id,
                                    convertItemToParamUpdate(itemAserSalvo)
                                  ).then(() => {
                                    setLoading(false);
                                    setItemAserSalvo(null);
                                    loadSaveStates();
                                    setSalvandoAnalise(false);
                                    setMensagemAparecendo(true);
                                    setMensagemIconAndTitle({
                                      icon: <FaCheckDouble />,
                                      title: "Ação Concluída!",
                                    });
                                    setMensagem(
                                      "Estudo atualizado com sucesso!"
                                    );
                                    salvaLog({
                                      action: "Atualizou estudo",
                                      params: "item id " + itemAserSalvo.id,
                                    }).then((result: any) => {});
                                  });
                                  setItemAserSalvo(null);
                                  loadSaveStates();
                                  setSalvandoAnalise(false);
                                  setMensagemAparecendo(true);
                                  setMensagemIconAndTitle({
                                    icon: <FaCheckDouble />,
                                    title: "Ação Concluída!",
                                  });
                                  setMensagem("Estudo atualizado com sucesso!");
                                  salvaLog({
                                    action: "Atualizou estudo",
                                    params: "item id " + itemAserSalvo.id,
                                  }).then((result: any) => {});
                                } else {
                                  const newItem: SaveState = {
                                    id: -1,
                                    study: {
                                      rectangleList: convertRectList(
                                        polygonParams.rectangleList
                                      ),
                                      polygonList: convertPolyList(
                                        polygonParams.polygonList
                                      ),
                                      radiusList: convertCircleList(
                                        polygonParams.radiusList
                                      ),
                                      pins: [],
                                      regionList: [],
                                      tokens: [],
                                    },
                                    link: "",
                                    token: "",
                                    createTime: 0,
                                    name: nome + "",
                                    isPublic: false,
                                    visible: true,
                                    userId: 0,
                                  };
                                  salvaEstudos(
                                    convertItemToParamSaveNew(newItem, dataUrl)
                                  ).then((result: any) => {
                                    setLoading(false);
                                    loadSaveStates();
                                    setSalvandoAnalise(false);
                                    setMensagemAparecendo(true);
                                    setMensagemIconAndTitle({
                                      icon: <FaCheckDouble />,
                                      title: "Ação Concluída!",
                                    });
                                    setMensagem("Estudo criado com sucesso!");
                                    salvaLog({
                                      action: "Salvou novo estudo",
                                      params: "",
                                    }).then((result: any) => {});
                                  });
                                  loadSaveStates();
                                  setSalvandoAnalise(false);
                                  setMensagemAparecendo(true);
                                  setMensagemIconAndTitle({
                                    icon: <FaCheckDouble />,
                                    title: "Sucesso",
                                  });
                                  setMensagem("Estudo criado com sucesso!");
                                  salvaLog({
                                    action: "Salvou novo estudo",
                                    params: "",
                                  }).then((result: any) => {});
                                }
                              });
                          }
                        } else {
                          setMensagemAparecendo(true);
                          setMensagemIconAndTitle({
                            icon: <FaX />,
                            title: "Ação Impossibilitada!",
                          });
                          setMensagem("Nome já utilizado. Escolha outro nome.");
                        }
                      }
                    }}
                  />
                  <ButtonDefault
                    icon={<FaTimes />}
                    style={{
                      borderRadius: "15px",
                      margin: 0,
                    }}
                    size={"small"}
                    color={"gray"}
                    transparent={false}
                    onClick={() => {
                      setItemAserSalvo(null);
                      loadSaveStates();
                      setSalvandoAnalise(false);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div style={{ alignContent: "center", alignItems: "center" }}>
                <ButtonDefault
                  style={{ flex: 1, marginRight: 5, borderRadius: "20px" }}
                  color="secondary"
                  size="medium"
                  title="SALVAR NOVA ANÁLISE"
                  icon={<FaPlus />}
                  iconPosition="right"
                  onClick={() => {
                    setSalvandoAnalise(true);
                  }}
                />
              </div>
            )}
          </div>
          {linkSave ? (
            <ModalDefault
              icon={<FaShareAlt />}
              setModalOpen={setLinkSave}
              title="Compartilhar Relatório"
              styleContent={{
                maxWidth: 500,
              }}
            >
              <div>
                <p
                  style={{
                    fontSize: 24,
                    color: colors.default.primary.content,
                    fontWeight: "bolder",
                    paddingBottom: 24,
                  }}
                >
                  LINK E TOKEN DE ACESSO
                </p>
                <p style={{ fontSize: 16, color: colors.subTextInfo }}>
                  Abaixo você pode copiar o link gerado para compartilhar seu
                  relatório em conjunto com o token para acesso.
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <input
                    id="linkurl"
                    defaultValue={
                      (httpConfig.backendUrl.includes("localhost")
                        ? "http://localhost:3000"
                        : "app.deepen.com.br") +
                      "/#!/share?dw=" +
                      linkSaveItem?.id +
                      "&token=" +
                      linkSaveItem?.link
                    }
                    readOnly={true}
                    style={{ width: "100%", margin: "20px", border: 0 }}
                  ></input>
                  <ButtonDefault
                    icon={<FaCopy />}
                    noshadow={true}
                    color={"deepblueInverted"}
                    shadow={false}
                    size="smallThree"
                    onClick={() => {
                      var copyText = document.getElementById(
                        "linkurl"
                      ) as HTMLInputElement;
                      if (copyText) {
                        copyText.select();
                        copyText.setSelectionRange(0, 99999);
                        navigator.clipboard.writeText(copyText.value);
                      }
                    }}
                  ></ButtonDefault>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    id="accesstoken"
                    defaultValue={linkSaveItem?.token}
                    readOnly={true}
                    style={{
                      fontSize: 30,
                      width: "170px",
                      margin: "0px",
                      border: 0,
                      color: colors.default.primary.content,
                      fontWeight: "bolder",
                    }}
                  ></input>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        color: colors.default.deepblue.background,
                        fontWeight: "bold",
                      }}
                    >
                      COPIAR TOKEN
                    </p>
                    <ButtonDefault
                      icon={<FaCopy />}
                      noshadow={true}
                      size="smallThree"
                      color={"deepblueInverted"}
                      onClick={() => {
                        var copyText = document.getElementById(
                          "accesstoken"
                        ) as HTMLInputElement;
                        if (copyText) {
                          copyText.select();
                          copyText.setSelectionRange(0, 99999);
                          navigator.clipboard.writeText(copyText.value);
                        }
                      }}
                    ></ButtonDefault>
                  </div>
                </div>
                <Divider />
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <div style={{ padding: 20 }} />
                  <ButtonDefault
                    icon={<FaArrowRight />}
                    iconPosition="right"
                    title="VOLTAR PARA AS ANÁLISES SALVAS."
                    style={{
                      borderRadius: "15px",
                      margin: 0,
                    }}
                    size={"medium"}
                    color={"secondary"}
                    transparent={false}
                    onClick={async () => {
                      setLinkSave(false);
                    }}
                  />
                  <div style={{ padding: 20 }} />
                </div>
              </div>
            </ModalDefault>
          ) : (
            <div></div>
          )}
          {mostraConfirmaExluir ? (
            <ModalDefault
              icon={<FaQuestionCircle />}
              setModalOpen={setMostraConfirmaExluir}
              title="Pergunta:"
              styleContent={{
                maxWidth: 500,
              }}
            >
              <div>
                <p>Deseja realmente excluir esta análise?</p>
                <div style={{ padding: 10 }} />
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <ButtonDefault
                    icon={<FaTrash />}
                    iconPosition="right"
                    title="Excluir"
                    style={{
                      borderRadius: "15px",
                      margin: 0,
                    }}
                    size={"medium"}
                    color={"orange"}
                    transparent={false}
                    onClick={async () => {
                      if (itemAserExcluido) {
                        setMostraConfirmaExluir(false);
                        itemAserExcluido.visible = false;
                        await atualizaEstudo(
                          itemAserExcluido.id,
                          convertItemToParamUpdate(itemAserExcluido)
                        );
                        loadSaveStates();
                        setMensagemAparecendo(true);
                        setMensagemIconAndTitle({
                          icon: <FaCheckDouble />,
                          title: "Ação Concluída!",
                        });
                        setMensagem("Estudo excluído com sucesso!");
                        salvaLog({
                          action: "Excluiu um estudo",
                          params: "item id " + itemAserExcluido.id,
                        }).then((result: any) => {});
                        setItemAserExcluido(null);
                      }
                    }}
                  />
                  <div style={{ padding: 20 }} />
                  <ButtonDefault
                    icon={<FaX />}
                    iconPosition="right"
                    title="Cancelar"
                    style={{
                      borderRadius: "15px",
                      margin: 0,
                    }}
                    size={"medium"}
                    color={"secondary"}
                    transparent={false}
                    onClick={async () => {
                      setMostraConfirmaExluir(false);
                      setItemAserExcluido(null);
                    }}
                  />
                </div>
              </div>
            </ModalDefault>
          ) : (
            <div></div>
          )}
          {appendOrOverwrite ? (
            <ModalDefault
              icon={<FaQuestionCircle />}
              setModalOpen={setAppendOrOverwrite}
              title="Substituir ou Adicionar?"
              styleContent={{
                maxWidth: 500,
              }}
            >
              <div>
                <p>Deseja substituir ou adicionar?</p>
                <div style={{ padding: 10 }} />
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <ButtonDefault
                    icon={<FaSync />}
                    iconPosition="right"
                    title="Substituir"
                    style={{
                      borderRadius: "15px",
                      margin: 0,
                    }}
                    size={"medium"}
                    color={"orange"}
                    transparent={false}
                    onClick={async () => {
                      setAppendOrOverwrite(false);
                      if (itemAserAdicionado) {
                        const newpolygons = {
                          ...polygonParams,
                          radiusList: revertCircleParamsToItem(
                            itemAserAdicionado.study.radiusList
                          ),
                          polygonList: revertPolyParamsToItem(
                            itemAserAdicionado.study.polygonList
                          ),
                          rectangleList: revertRectParamsToItem(
                            itemAserAdicionado.study.rectangleList
                          ),
                          lineList: [],
                        };
                        updatefunction(newpolygons);
                        salvaLog({
                          action: "Aplicou estudo",
                          params: "item id " + itemAserAdicionado.id,
                        }).then((result: any) => {});
                        setModalVariables(false);
                      }
                      setItemAserAdicionado(null);
                    }}
                  />
                  <div style={{ padding: 20 }} />
                  <ButtonDefault
                    icon={<FaPlus />}
                    iconPosition="right"
                    title="Adicionar"
                    style={{
                      borderRadius: "15px",
                      margin: 0,
                    }}
                    size={"medium"}
                    color={"secondary"}
                    transparent={false}
                    onClick={async () => {
                      setAppendOrOverwrite(false);
                      if (itemAserAdicionado) {
                        const newpolygons = {
                          ...polygonParams,
                          radiusList: [
                            ...polygonParams.radiusList,
                            ...revertCircleParamsToItem(
                              itemAserAdicionado.study.radiusList
                            ),
                          ],
                          polygonList: [
                            ...polygonParams.polygonList,
                            ...revertPolyParamsToItem(
                              itemAserAdicionado.study.polygonList
                            ),
                          ],
                          rectangleList: [
                            ...polygonParams.rectangleList,
                            ...revertRectParamsToItem(
                              itemAserAdicionado.study.rectangleList
                            ),
                          ],
                          lineList: [],
                        };
                        updatefunction(newpolygons);
                        salvaLog({
                          action: "Aplicou estudo",
                          params: "item id " + itemAserAdicionado.id,
                        }).then((result: any) => {});
                        setModalVariables(false);
                      }
                      setItemAserAdicionado(null);
                    }}
                  />
                </div>
              </div>
            </ModalDefault>
          ) : (
            <div></div>
          )}
          {mensagemAparecendo ? (
            <ModalDefault
              icon={mensagemIconAndTitle.icon}
              setModalOpen={setMensagemAparecendo}
              title={mensagemIconAndTitle.title}
              styleContent={{
                maxWidth: 500,
              }}
            >
              <div>
                <p>{mensagem}</p>
                <div style={{ padding: 20 }} />
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <div style={{ padding: 20 }} />
                  <ButtonDefault
                    icon={<FaCheck />}
                    iconPosition="right"
                    title="OK"
                    style={{
                      borderRadius: "15px",
                      maxWidth: "100px",
                      width: "100px",
                      margin: 0,
                    }}
                    size={"medium"}
                    color={"secondary"}
                    transparent={false}
                    onClick={async () => {
                      setMensagemAparecendo(false);
                    }}
                  />
                  <div style={{ padding: 20 }} />
                </div>
              </div>
            </ModalDefault>
          ) : (
            <div></div>
          )}
        </ModalDefault>
      ) : (
        <></>
      )}
    </>
  );
}
