import { FaFileExport, FaListUl, FaSearch } from "react-icons/fa";
import { AutoComplete } from "../../../../components/AutoComplete/AutoComplete";
import { ButtonDefault } from "../../../../components/Button/ButtonDefault";
import { VariableSelectOption } from "../../../../Types/Variable";
import { Tags } from "../../../Map/components/Preview/components/Tags";
import { useEffect, useState } from "react";
import { getStates, listCSVFile } from "../../../../services/getOptionsData";
import { cnaes } from "../../../../data/dataFilters/cnaes";
import { estadosMunicipios } from "../../../../data/dataFilters/estadosMunicipios";
import { capitais } from "../../../../data/dataFilters/capitais";
import { portes } from "../../../../data/dataFilters/portes";
import { datasDeInicio } from "../../../../data/dataFilters/datasDeInicio";
import { AreasProps, ConfirmaDados } from "../../Companies";
import { SubTitle } from "../../../../components/typography/SubTitle";
import { AllFiltersProps } from "../../../Map/ReactMap";
import { AreaElement } from "./components/AreaElement";
import { SelectRadios } from "../../../../components/Select/SelectRadios";
import { ExportMessagesScreen } from "./components/ExportMessagesScreen";
import { useAuth } from "../../../../contexts/AuthContext";
import { ModalDefault } from "../../../../components/Modal/ModalDefault";
import { FaCircleExclamation } from "react-icons/fa6";
import { TitleModal } from "../../../../components/typography/TitleModal";
import { colors } from "../../../../assets/colors";

interface StatesProps {
  state: string;
  stateCode: number;
}

interface CnaeProps {
  cnae: string;
  descricao: string;
}

interface FiltersCompanyProps {
  areas: AreasProps[] | null;
  allFilters: AllFiltersProps;
  setAreaSelected: React.Dispatch<React.SetStateAction<AreasProps | null>>;
  setAllFilters: React.Dispatch<React.SetStateAction<AllFiltersProps>>;
  handleSeachFilters: () => void;
  handleExportFilters: (
    setMessage: (title: string, message: string) => void,
    setExporting: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  mostraConfirma: boolean;
  setMostraConfirma: React.Dispatch<React.SetStateAction<boolean>>;
  confirmaDados: ConfirmaDados | null;
  setVariableCompaniesList: React.Dispatch<
    React.SetStateAction<
      {
        label: string;
        cnpj: string;
      }[]
    >
  >;
  setPageSelected: React.Dispatch<React.SetStateAction<number>>;
}

export function FiltersCompany({
  areas,
  allFilters,
  setAllFilters,
  setAreaSelected,
  handleSeachFilters,
  handleExportFilters,
  mostraConfirma,
  setMostraConfirma,
  confirmaDados,
  setVariableCompaniesList,
  setPageSelected,
}: FiltersCompanyProps) {
  const [variableStates, setVariableStates] = useState<VariableSelectOption[]>(
    []
  );
  const [cnaeList, setCnaeList] = useState<VariableSelectOption[]>([]);
  const [variableCounties, setVariableCounties] = useState<
    VariableSelectOption[]
  >([]);
  const [variableSharesCapital, setVariableSharesCapital] = useState<
    VariableSelectOption[]
  >([]);

  const [variableDateStart, setVariableDateStart] = useState<
    VariableSelectOption[]
  >([]);

  const [showMessage, setShowMessage] = useState<boolean>(false);

  const [variableSize, setVariableSize] = useState<VariableSelectOption[]>([]);

  const hasFilter = Object.values(allFilters).some(
    (filter) => filter.length > 0
  );
  const [exporting, setExporting] = useState<boolean>(false);
  const [listing, setListing] = useState<boolean>(false);
  const [messageTitle, setMessageTitle] = useState<string>("");
  const [messageTxt, setMessageText] = useState<string>("");
  const { user } = useAuth();

  function setMessage(title: string, text: any) {
    if (title === "OK") {
      setExporting(false);
      setMessageTitle("");
      setMessageText("");
    } else if (title === "list") {
      loadExportList();
    } else {
      setMessageTitle(title);
      setMessageText(text);
    }
  }

  function loadExportList() {
    listCSVFile({ id: user?.id }).then((response) => {
      setExporting(false);
      setListing(true);
    });
  }

  useEffect(() => {
    const sortedCnaeList = cnaes.sort((a: CnaeProps, b: CnaeProps) =>
      a.cnae.localeCompare(b.cnae)
    );
    setCnaeList([
      ...sortedCnaeList.map((filteredCnae: CnaeProps) => {
        return {
          label: filteredCnae.cnae + " - " + filteredCnae.descricao,
          value: filteredCnae.cnae,
        };
      }),
    ]);
  }, []);

  useEffect(() => {
    getStates()
      .then((states) => {
        const sortedStates = states.sort((a: StatesProps, b: StatesProps) =>
          a.state.localeCompare(b.state)
        );
        setVariableStates([
          ...sortedStates.map((filteredState: StatesProps) => {
            return {
              label: filteredState.state,
              value: filteredState.state,
            };
          }),
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const sortedByState = estadosMunicipios.sort((a, b) =>
      a.estado.localeCompare(b.estado)
    );

    const states: VariableSelectOption[] = [];

    const counties = sortedByState.map(({ estado, municipios }) => {
      const sortedMunicipios = municipios.sort((a, b) =>
        a.municipio.localeCompare(b.municipio)
      );

      states.push({
        label: estado,
        value: estado,
      });

      return {
        label: estado,
        options: [
          ...sortedMunicipios.map((filteredMunicipio) => {
            return {
              label: filteredMunicipio.municipio,
              value: filteredMunicipio.municipio,
            };
          }),
        ],
      };
    });

    setVariableStates(states);
    setVariableCounties(counties);
  }, []);

  useEffect(() => {
    const shareCapital: VariableSelectOption[] = capitais.map(
      (capital, index) => {
        return {
          label: capital,
          value: index.toString(),
        };
      }
    );

    setVariableSharesCapital(shareCapital);
  }, []);

  useEffect(() => {
    const size: VariableSelectOption[] = portes.map((porte, index) => {
      return {
        label: porte,
        value: index.toString(),
      };
    });

    setVariableSize(size);
  }, []);

  useEffect(() => {
    const dateStart: VariableSelectOption[] = datasDeInicio.map((date) => {
      return {
        label: date,
        value: date,
      };
    });

    setVariableDateStart(dateStart);
  }, []);

  const autoCompleteData = [
    {
      key: "estado",
      options: variableStates,
      placeholder: "Selecionar um estado",
      caretIcon: true,
      flex: 2,
    },
    {
      key: "cidade",
      options: variableCounties,
      placeholder: "Pesquisar município",
      caretIcon: false,
      flex: 2,
    },
    {
      key: "porte",
      options: variableSize,
      placeholder: "Porte",
      caretIcon: true,
    },
    {
      key: "capitalSocial",
      options: variableSharesCapital,
      placeholder: "Capital social",
      caretIcon: true,
    },
  ];

  const handleOptionChange = (option: string, key: string) => {
    if (option === "") return;
    if (allFilters[key].includes(option)) return;

    const oldValues = allFilters[key];
    const newValues = [...oldValues, option];

    setAllFilters({
      ...allFilters,
      [key]: newValues,
    });
  };

  const cores = [
    "#524FAA",
    "#526BD1",
    "#6987C2",
    "#6C6EB9",
    "#5292D1",
    "#1E3378",
  ];

  let idxColor = 0;

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <AutoComplete
          CaretIcon={false}
          style={{
            flex: 1,
          }}
          searchFilter={(text, options) => {
            return options?.filter((option: any) =>
              option.label.toUpperCase().startsWith(text.toUpperCase())
            );
          }}
          minTextLength={1}
          clearOnSelect
          options={cnaeList}
          placeholder="Pesquisar CNAE"
          onChange={(newvalue) => {
            handleOptionChange(newvalue.toString(), "cnae");
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            marginLeft: 16,
          }}
        >
          <AutoComplete
            key="inicioDeAtividade"
            style={{
              flex: 1,
            }}
            options={variableDateStart}
            CaretIcon={false}
            searchFilter={(text, options) => {
              return options?.filter((option: any) =>
                option.label.toUpperCase().startsWith(text.toUpperCase())
              );
            }}
            minTextLength={0}
            placeholder="Inicio de atividade"
            onChange={(newvalue) => {
              handleOptionChange(newvalue.toString(), "inicioDeAtividade");
            }}
          />

          <SelectRadios
            style={{
              flex: 1,
              marginLeft: 16,
            }}
            onChange={(value) => {
              if (value === "") setAreaSelected(null);
              else
                areas?.[Number(value)] &&
                  setAreaSelected(areas?.[Number(value)]);
            }}
            options={
              areas
                ? [
                    ...areas.map((area, index) => ({
                      label: AreaElement(area.name, area.color, area.studyType),
                      value: index.toString(),
                    })),
                  ]
                : undefined
            }
            placeholder={
              <div
                style={{ display: "flex", alignItems: "center", padding: 8 }}
              >
                <FaListUl />
                <SubTitle
                  style={{
                    fontSize: 16,
                    marginLeft: 15,
                  }}
                >
                  Lista de áreas
                </SubTitle>
              </div>
            }
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          marginTop: 16,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {autoCompleteData.map((data, index) => (
          <AutoComplete
            key={index}
            CaretIcon={data.caretIcon}
            style={{
              flex: 1,
              marginLeft: index !== 0 ? 16 : 0,
            }}
            minTextLength={data.key === "cidade" ? 1 : 0}
            searchFilter={(text, options) => {
              if (data.key === "cidade") {
                const selectedStates: string[] = allFilters.estado;

                return options
                  ?.map((item: any) => ({
                    ...item,
                    options: item.options.filter((option: any) =>
                      option.label.toUpperCase().includes(text.toUpperCase())
                    ),
                  }))
                  .filter((item) => {
                    const state = item.label;
                    return selectedStates.includes(state);
                  });
              } else {
                return options?.filter((option: any) =>
                  option.label.toUpperCase().includes(text.toUpperCase())
                );
              }
            }}
            clearOnSelect
            options={data.options}
            placeholder={data.placeholder}
            onChange={(newvalue) => {
              handleOptionChange(newvalue.toString(), data.key);
            }}
          />
        ))}
      </div>

      <div
        style={{
          width: "100%",
          marginTop: 16,
          alignItems: "center",
          display: "flex",
        }}
      >
        <ButtonDefault
          color="secondary"
          title="PESQUISAR"
          icon={<FaSearch />}
          iconPosition="right"
          size="medium"
          style={{
            width: 166,
            height: 48,
            marginLeft: 0,
          }}
          borderRadius={25}
          onClick={() => {
            setPageSelected(0);
            setVariableCompaniesList([]);
          }}
        />
        <ButtonDefault
          color="tertiary"
          title="EXPORTAR"
          icon={<FaFileExport />}
          size="medium"
          iconPosition="right"
          style={{
            width: 166,
            height: 48,
            marginRight: 27,
          }}
          borderRadius={25}
          onClick={() => {
            if (allFilters.cnae.length > 0) {
              setExporting(true);
              handleExportFilters(setMessage, setExporting);
            } else {
              setShowMessage(true);
            }
          }}
        />
        {exporting && (
          <ExportMessagesScreen
            titulo={messageTitle}
            mensagem={messageTxt}
            mostraConfirma={mostraConfirma}
            setMostraConfirma={setMostraConfirma}
            confirmaDados={confirmaDados}
          />
        )}
        {showMessage && (
          <ModalDefault
            icon={<FaCircleExclamation />}
            setModalOpen={setShowMessage}
            title="Aviso"
            styleContent={{
              maxWidth: 500,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TitleModal>NENHUM CNAE ESCOLHIDO</TitleModal>
              <SubTitle
                style={{
                  textAlign: "center",
                  marginBlock: 14,
                }}
              >
                É necessário escolher pelo menos um CNAE para exportar.
              </SubTitle>
            </div>
          </ModalDefault>
        )}
        {listing ? <div></div> : <div></div>}
        {hasFilter ? (
          <>
            {Object.entries(allFilters).map(([key, value]) => {
              if (value.length > 0) {
                if (idxColor === 5) {
                  idxColor = 0;
                }
                return value.map((filter, index) => (
                  <Tags
                    key={index}
                    label={
                      key === "porte"
                        ? portes[parseInt(filter)]
                        : key === "capitalSocial"
                        ? capitais[parseInt(filter)]
                        : filter
                    }
                    color={cores[idxColor++]}
                    labelColor="#FFFFFF"
                    onClick={() => {
                      //if key is state remove all counties of this state

                      const oldValues = allFilters[key];
                      const newValues = oldValues.filter(
                        (item) => item !== filter
                      );

                      /* Excluir todos os municipios de um estado quando o estado for removido */
                      // if (key === "estado") {
                      //   const oldCounties = allFilters["municipio"];
                      //   const stateToFilter = variableCounties
                      //     .filter((item) => item.label.includes(filter))
                      //     .flatMap((item) => item.options)
                      //     .map((item) => item?.label);

                      //   const newCounties = oldCounties.filter((item) => {
                      //     return !stateToFilter.includes(item);
                      //   });

                      //   setAllFilters({
                      //     ...allFilters,
                      //     [key]: newValues,
                      //     municipio: newCounties,
                      //   });
                      // } else {
                      setAllFilters({
                        ...allFilters,
                        [key]: newValues,
                      });
                      // }
                    }}
                  />
                ));
              }
            })}
          </>
        ) : (
          <Tags label="Nenhum Filtro" color="#F2F2F2" labelColor="#B2B2B2" />
        )}
      </div>
    </>
  );
}
