import { CSSProperties, ChangeEvent, useEffect, useRef, useState } from "react";
import { colors } from "../../assets/colors";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";

interface DateInputProps {
  value: string;
  onChange: (e: string) => void;
  placeholder: string;
  prefix?: string;
  style?: CSSProperties;
}

const grayColor = colors.default.disabled.content;

export function DateInput({
  value,
  onChange,
  placeholder,
  prefix,
  style,
}: DateInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [startDate, setStartDate] = useState(() => {
    if (value) {
      let [day, month, year] = value.split("/");
      const date = new Date(`${year}-${month}-${day} 15:00:00`);

      return date;
    }
    return new Date();
  });
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        isOpen
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    //evento listener para o enter
    document.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        setIsOpen(false);
      }
    });

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", (e) => {
        if (e.key === "Enter") {
          setIsOpen(false);
        }
      });
    };
  }, [isOpen]);

  const handleChange = (e: any) => {
    setStartDate(e);
    setIsOpen(false);

    const date = new Date(e);
    const day = String(date.getDate()).padStart(2, "0"); // Dia com zero à esquerda
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Mês com zero à esquerda
    const year = date.getFullYear(); // Ano

    const formattedDate = `${day}/${month}/${year}`;

    onChange(formattedDate);
  };

  const handleChangeWriting = (e: ChangeEvent<HTMLInputElement>) => {
    let { value: inputValue } = e.target;
    let parsedDate: Date;
    let invalid = false;
    const today = new Date();

    const regexData = /^\d{2}\/\d{2}\/\d{4}$/;

    if (Number(inputValue[0]) > 3) {
      inputValue = inputValue.replace(inputValue[0], "");
      inputValue = inputValue + "3";
    }

    if (Number(inputValue[0]) === 3 && Number(inputValue[1]) > 1) {
      inputValue = inputValue.replace(inputValue[1], "");
      inputValue = inputValue + "1";
    }

    if (Number(inputValue[2]) > 1) {
      const m = inputValue[2];
      inputValue = inputValue.replace(inputValue[2], "");
      inputValue = inputValue + "0" + m;
    }
    if (Number(inputValue[3]) === 1 && Number(inputValue[4]) > 2) {
      inputValue = inputValue.replace(inputValue[4], "");
      inputValue = inputValue + "2";
    }

    if (!regexData.test(inputValue)) {
      inputValue = inputValue.replace(/\D/g, "");
      inputValue = inputValue.replace(/(\d{2})(\d)/, "$1/$2");
      inputValue = inputValue.replace(/(\d{2})(\d)/, "$1/$2");
    }

    if (inputValue.length === 10) {
      let [day, month, year] = inputValue.split("/");

      if (Number(year) < today.getFullYear()) {
        year = String(today.getFullYear());
      }

      do {
        parsedDate = new Date(`${year}-${month}-${day} 15:00:00`);

        const dayParsed = String(parsedDate.getDate()).padStart(2, "0");

        if (parsedDate.toString() === "Invalid Date" || day !== dayParsed) {
          invalid = true;
          day = String(Number(day) - 1);
        } else {
          invalid = false;
          setStartDate(parsedDate);
          inputValue = `${day}/${month}/${year}`;
        }
      } while (invalid);
    }

    onChange(inputValue);
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  return (
    <div
      ref={inputRef}
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: 48,
        borderRadius: 5,
        border: `1px solid ${grayColor}`,

        ...style,
      }}
    >
      <div
        style={{
          paddingLeft: 12,
        }}
      >
        <FaCalendarAlt color={grayColor} onClick={handleClick} />
      </div>
      {prefix && value && (
        <div
          style={{
            width: "100%",
            paddingLeft: 12,
          }}
        >
          {prefix}
        </div>
      )}
      <input
        style={{
          width: "100%",
          height: 46,
          borderRadius: 5,
          fontSize: 16,
          padding: 8,
          paddingLeft: prefix && value ? 0 : 12,
          border: "none",
          outline: "none",
        }}
        autoComplete="off"
        maxLength={10}
        value={value}
        onChange={handleChangeWriting}
        name="interesses"
        type="text"
        placeholder={placeholder}
      />
      {isOpen && (
        <div
          style={{
            position: "absolute",
            right: "101%",
            bottom: -5,
          }}
        >
          <DatePicker
            selected={startDate}
            onChange={handleChange}
            dateFormat="dd/MM/yyyy"
            inline
          />
        </div>
      )}
    </div>
  );
}
