import { useEffect, useState } from "react";
import { ContractResponse, DataTable } from "../../types";
import "./TableCompanies.css";
import {
  ModalToAddOrEditCompany,
  Model,
} from "../ModalToAddOrEditCompany/ModalToAddOrEditCompany";

interface TableCompaniesProps {
  companiesContract: ContractResponse[];
  setCompaniesContract: (value: ContractResponse[]) => void;
  data: DataTable[];
  setData: (value: DataTable[]) => void;
  modalEditCompany: boolean;
  setModalEditCompany: React.Dispatch<React.SetStateAction<boolean>>;
  reloadCompanies: () => void;
  setMessage: (icon: string, title: string, msg: string) => void;
  mensagemAparecendo: boolean;
}

export function TableCompanies({
  companiesContract,
  setCompaniesContract,
  data,
  setData,
  modalEditCompany,
  setModalEditCompany,
  reloadCompanies,
  setMessage,
  mensagemAparecendo,
}: TableCompaniesProps) {
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const [idCompany, setIdCompany] = useState<number>(0);
  const [idContract, setIdContract] = useState<number>(0);

  useEffect(() => {
    const data = companiesContract.map((company) => {
      const maxAdmins = company.company.maxAdminCompany;
      const maxCompanyUsers = company.company.maxCompanyUsers;
      const maxUsers = company.company.maxUsers;
      const countCurrentMaxAdminCompanyUsers =
        company.company.countCurrentMaxAdminCompanyUsers;
      const countCurrentMaxCompanyUsers =
        company.company.countCurrentMaxCompanyUsers;
      const countCurrentMaxUsers = company.company.countCurrentMaxUsers;

      const remainingDays = Math.round(
        (company.expirationTime - Date.now()) / (1000 * 60 * 60 * 24)
      );

      const adminsFranchisee = `${countCurrentMaxAdminCompanyUsers}/${maxAdmins}`;
      const franchisee = `${countCurrentMaxCompanyUsers}/${maxCompanyUsers}`;
      const users = `${countCurrentMaxUsers}/${maxUsers}`;
      const studyLimits = company.limitStudies;
      const numberofReports = company.countReports;

      return {
        id: company.id,
        idcompany: company.company.id,
        companyName: company.company.name,
        active: company.enable ? "Sim" : "Não",
        trial: company.trial ? "Sim" : "Não",
        remainingDays,
        adminsFranchisee,
        franchisee,
        users,
        studyLimits,
        numberofReports,
      };
    });

    setData(data);
  }, [companiesContract]);

  function handleSort(column: string) {
    if (sortColumn === column) {
      // Se já estivermos classificando a mesma coluna, invertemos a direção
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Se for uma nova coluna, definimos a coluna de classificação e a direção como ascendente
      setSortColumn(column);
      if (column === "companyName") {
        setSortDirection("asc");
      } else {
        setSortDirection("desc");
      }
    }
  }

  function extractNumberBeforeSlash(text: string): number {
    const match = /(\d+)\/\d+/.exec(text);
    if (match) {
      return parseInt(match[1], 10);
    }
    return 0;
  }

  const sortedData = [...data];
  if (sortColumn) {
    sortedData.sort((a: any, b: any) => {
      let valueA;
      let valueB;
      if (
        sortColumn === "adminsFranchisee" ||
        sortColumn === "franchisee" ||
        sortColumn === "users"
      ) {
        valueA = extractNumberBeforeSlash(a[sortColumn]);
        valueB = extractNumberBeforeSlash(b[sortColumn]);
      } else {
        valueA = a[sortColumn];
        valueB = b[sortColumn];
      }
      if (!isNaN(Number(valueA)) && !isNaN(Number(valueB))) {
        return (
          (Number(valueA) - Number(valueB)) * (sortDirection === "asc" ? 1 : -1)
        );
      } else {
        if (sortDirection === "asc") {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      }
    });
  }

  const tableColumns = [
    { name: "companyName", label: "Empresa" },
    { name: "active", label: "Ativo" },
    { name: "trial", label: "Trial" },
    { name: "remainingDays", label: "Dias restantes do contrato" },
    { name: "adminsFranchisee", label: "Admins franqueados" },
    { name: "franchisee", label: "Franqueados" },
    { name: "users", label: "Usuários" },
    { name: "studyLimits", label: "Limite de estudos" },
    { name: "numberofReports", label: "Número de relatórios" },
  ];

  return (
    <>
      <div className="Container">
        <div className="Content">
          <table className="Table">
            <thead className="TableHead">
              <tr className="Row RowHeader">
                {tableColumns.map((column) => (
                  <th
                    key={column.name}
                    className={`Header ${column.name}`}
                    onClick={() => handleSort(column.name)}
                  >
                    {column.label}
                    {sortColumn === column.name &&
                      (sortDirection === "asc" ? "▼" : "▲")}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="TableBody">
              {sortedData.map((item, index) => (
                <tr
                  key={index}
                  className="Row"
                  onClick={() => {
                    setIdContract(item.id);
                    setIdCompany(item.idcompany);
                    setModalEditCompany(true);
                  }}
                >
                  <td className="Cell companyName">{item.companyName}</td>
                  <td
                    className={`Cell active ${
                      item.active === "Sim" ? "activated" : "deactivated"
                    }`}
                  >
                    {item.active}
                  </td>
                  <td
                    className={`Cell trial ${
                      item.trial === "Sim" ? "activated" : "deactivated"
                    }`}
                  >
                    {item.trial}
                  </td>
                  <td className="Cell">{item.remainingDays}</td>
                  <td className="Cell">{item.adminsFranchisee}</td>
                  <td className="Cell">{item.franchisee}</td>
                  <td className="Cell">{item.users}</td>
                  <td className="Cell">{item.studyLimits}</td>
                  <td className="Cell">{item.numberofReports}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {modalEditCompany && (
        <ModalToAddOrEditCompany
          idCompany={idCompany}
          idContract={idContract}
          setModalOpen={setModalEditCompany}
          setCompaniesContract={setCompaniesContract}
          reloadCompanies={() => {
            reloadCompanies();
            setModalEditCompany(false);
            setMessage("Check", "Sucesso!", "Empresa atualizada com sucesso!");
          }}
          setMessage={setMessage}
          mensagemAparecendo={mensagemAparecendo}
        />
      )}
    </>
  );
}
