import { Divider } from "antd";
import { FiltersAdministration } from "./components/FilterAdministration/FiltersAdministration";
import { TableCompanies } from "./components/TableCompanies/TableCompanies";
import { useEffect, useState } from "react";
import { getDataCompaniesContract } from "../../services/getOptionsData";
import { ContractResponse, DataTable } from "./types";
import "./Administration.css";
import { Loading } from "../../components/Loading/Loading";
import { SubTitle } from "../../components/typography/SubTitle";
import { TitleModal } from "../../components/typography/TitleModal";
import { FaCheck, FaCheckDouble, FaX } from "react-icons/fa6";
import { ButtonDefault } from "../../components/Button/ButtonDefault";
import { ModalDefault } from "../../components/Modal/ModalDefault";

export function Administration() {
  const [companiesContract, setCompaniesContract] = useState<
    ContractResponse[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<DataTable[]>([]);
  const [modalEditCompany, setModalEditCompany] = useState(false);
  const [mensagemAparecendo, setMensagemAparecendo] = useState<boolean>(false);
  const [mensagem, setMensagem] = useState<string>("");
  const [mensagemIcon, setMensagemIcon] = useState<string>("");
  const [mensagemTitle, setMensagemTitle] = useState<string>("");

  useEffect(() => {
    if (companiesContract.length > 0) {
      setLoading(false);
      return;
    }
    reloadCompanies();
  }, [companiesContract]);

  function reloadCompanies() {
    getDataCompaniesContract().then((response: ContractResponse[]) => {
      if (Array.isArray(response)) {
        setCompaniesContract(response);
      } else {
        setLoading(false);
      }
      setLoading(false);
    });
  }
  function setMessage(icon: string, title: string, msg: string) {
    setMensagemAparecendo(true);
    setMensagemIcon(icon);
    setMensagemTitle(title);
    setMensagem(msg);
  }

  return (
    <>
      {loading && <Loading />}
      <div>
        <div
          id="container-Administration"
          style={{
            position: "absolute",
            zIndex: 2000,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            padding: 32,
            backgroundColor: "#FFFFFF",
            overflow: "hidden",
          }}
        >
          {companiesContract.length > 0 ? (
            <>
              <FiltersAdministration
                companiesContract={companiesContract}
                setCompaniesContract={setCompaniesContract}
                setData={setData}
                reloadCompanies={reloadCompanies}
                setMessage={setMessage}
                mensagemAparecendo={mensagemAparecendo}
              />
              <Divider />
              <TableCompanies
                companiesContract={companiesContract}
                setCompaniesContract={setCompaniesContract}
                data={data}
                setData={setData}
                setModalEditCompany={setModalEditCompany}
                modalEditCompany={modalEditCompany}
                reloadCompanies={reloadCompanies}
                setMessage={setMessage}
                mensagemAparecendo={mensagemAparecendo}
              />
            </>
          ) : (
            <>
              <TitleModal style={{ alignSelf: "center" }}>
                Área de administração
              </TitleModal>
              <SubTitle style={{ alignSelf: "center" }}>
                Aqui somente pessoas com permissão de administrador podem
                acessar.
              </SubTitle>
            </>
          )}
        </div>
        <div>
          {mensagemAparecendo ? (
            <ModalDefault
              icon={
                mensagemIcon === "X" ? (
                  <FaX />
                ) : mensagemIcon === "Check" ? (
                  <FaCheck />
                ) : (
                  <FaCheckDouble />
                )
              }
              setModalOpen={setMensagemAparecendo}
              title={mensagemTitle}
              styleContent={{
                maxWidth: 500,
              }}
            >
              <div>
                <p>{mensagem}</p>
                <div style={{ padding: 20 }} />
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <div style={{ padding: 20 }} />
                  <ButtonDefault
                    icon={<FaCheck />}
                    iconPosition="right"
                    title="OK"
                    style={{
                      borderRadius: "15px",
                      maxWidth: "100px",
                      width: "100px",
                      margin: 0,
                    }}
                    size={"medium"}
                    color={"secondary"}
                    transparent={false}
                    onClick={async () => {
                      setMensagemAparecendo(false);
                    }}
                  />
                  <div style={{ padding: 20 }} />
                </div>
              </div>
            </ModalDefault>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
}
