import React, { ReactNode } from "react";
import { FaTimes } from "react-icons/fa";

interface TagsProps {
  label: string;
  labelColor?: string;
  color: string;
  onClick?: () => void;
}

export function Tags({ label, labelColor, color, onClick }: TagsProps) {
  const truncatedLabel =
    label.length > 20 ? label.substring(0, 20) + "..." : label;

  return (
    <div className="tags" style={{ backgroundColor: color }}>
      {onClick && (
        <>
          <button className="tagsButton" onClick={onClick}>
            <FaTimes id="tagsIcon" style={{ fontSize: 12 }} />
          </button>
          <div className="separationTag"></div>
        </>
      )}
      <p className="tagsLabel" style={{ color: labelColor || "white" }}>
        <span className="tooltiptext">{label}</span>
        {truncatedLabel}
      </p>
    </div>
  );
}
