import { useEffect, useState } from "react";
import "./TableUsers.css";
import { UserByCompany } from "../../../../types";
import { CreateOrEditUser } from "../../../CreateOrEditUser/CreateOrEditUser";

interface TableUsersProps {
  users: UserByCompany[];
  setUsers: (value: UserByCompany[]) => void;
  tokenCompany: string;
  setMessage: (icon: string, title: string, msg: string) => void;
  canclose: boolean;
}

interface DataTable {
  userName: string;
  permission: string;
  active: string;
}

export function TableUsers({
  users,
  setUsers,
  tokenCompany,
  setMessage,
  canclose,
}: TableUsersProps) {
  const [modalToAddUserOpen, setModalToAddUserOpen] = useState(false);
  const [data, setData] = useState<DataTable[]>([
    {
      userName: "",
      permission: "",
      active: "",
    },
  ]);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [user, setUser] = useState<UserByCompany | undefined>();

  useEffect(() => {
    if (!users) {
      return;
    }
    const data = users.map((user) => {
      return {
        userName: user.email,
        permission: user.function.name,
        active: user.enable ? "Sim" : "Não",
      };
    });

    setData(data);
  }, [users]);

  function handleSort(column: string) {
    if (sortColumn === column) {
      // Se já estivermos classificando a mesma coluna, invertemos a direção
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Se for uma nova coluna, definimos a coluna de classificação e a direção como ascendente
      setSortColumn(column);

      setSortDirection("asc");
    }
  }

  const sortedData = [...data];
  if (sortColumn) {
    sortedData.sort((a: any, b: any) => {
      let valueA;
      let valueB;

      valueA = a[sortColumn];
      valueB = b[sortColumn];

      if (sortDirection === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });
  }

  const tableColumns = [
    { name: "userName", label: "Usuário" },
    { name: "permission", label: "Permissão" },
    { name: "active", label: "Ativo" },
  ];

  return (
    <>
      <div className="Container">
        <div className="Content">
          <table className="Table">
            <thead className="TableHead">
              <tr className="RowUser">
                {tableColumns.map((column) => (
                  <th
                    key={column.name}
                    style={{
                      position: "relative",
                    }}
                    className={`HeaderUser ${column.name}`}
                    onClick={() => handleSort(column.name)}
                  >
                    {column.label}
                    {sortColumn === column.name &&
                      (sortDirection === "asc" ? "▼" : "▲")}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="TableBody">
              {sortedData.map((item, index) => (
                <tr
                  key={index}
                  className="RowUser"
                  onClick={() => {
                    setUser(users[index]);
                    setModalToAddUserOpen(true);
                  }}
                >
                  <td className="CellUser userName">{item.userName}</td>

                  <td className="CellUser permission">{item.permission}</td>
                  <td
                    className={`CellUser active ${
                      item.active === "Sim"
                        ? "activated"
                        : item.active === "Não"
                        ? "deactivated"
                        : ""
                    }`}
                  >
                    {item.active}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {modalToAddUserOpen && (
        <CreateOrEditUser
          user={user}
          setUsers={setUsers}
          tokenCompany={tokenCompany}
          setModalOpen={setModalToAddUserOpen}
          setMessage={setMessage}
          canclose={canclose}
        />
      )}
    </>
  );
}
