import L, { layerGroup } from "leaflet";
import * as turf from "@turf/turf";
import { useEffect } from "react";
import { getDistanceBetweenPoints } from "../utils/getDistanceBetweenPoints";

var markersInterestPoints = layerGroup();

export function MarkersInterestPoints({
  map,
  markersGoogle,
  polygon,
  IconCustomGooglePoint,
}) {
  map.addLayer(markersInterestPoints);

  useEffect(() => {
    /*
    const isRectangle = (index, pins) => {
      Object.keys(polygon.rectangleList).forEach((key) => {
        const rectangle = polygon.rectangleList[key];
        if (rectangle.id === index) {
          const points = rectangle.points;
          const lat1 = points[0][0];
          const lat2 = points[1][0];
          const lon1 = points[0][1];
          const lon2 = points[1][1];

          const latMax = lat1 > lat2 ? lat1 : lat2;
          const latMin = lat1 < lat2 ? lat1 : lat2;

          const lonMax = lon1 > lon2 ? lon1 : lon2;
          const lonMin = lon1 < lon2 ? lon1 : lon2;

          pins.forEach(({ y: lat, x: lon, d: title }) => {
            if (
              lat >= latMin &&
              lat <= latMax &&
              lon >= lonMin &&
              lon <= lonMax
            ) {
              L.marker([lat, lon], {
                title: title,
                icon: IconCustomGooglePoint,
              })
                .bindPopup(title)
                .addTo(markersInterestPoints);
            }
          });
        }
      });
    };

    const isCircle = (index, pins) => {
      Object.keys(polygon.radiusList).forEach((key) => {
        const circle = polygon.radiusList[key];
        if (circle.id === index) {
          const center = circle.points[0];

          pins.forEach(({ y: lat, x: lon, d: title }) => {
            const distanceCenter2Point =
              getDistanceBetweenPoints(center, [lat, lon]) / 1000;
            if (distanceCenter2Point < circle.ray) {
              L.marker([lat, lon], {
                title: title,
                icon: IconCustomGooglePoint,
              })
                .bindPopup(title)
                .addTo(markersInterestPoints);
            }
          });
        }
      });
    };

    const isPolygon = (index, pins) => {
      Object.keys(polygon.polygonList).forEach((key) => {
        const poly = polygon.polygonList[key];
        if (poly.id === index) {
          let vertices = poly.points.map(([lat, lon]) => {
            return [lon, lat];
          });
          vertices = [...vertices, [vertices[0][0], vertices[0][1]]];

          pins.forEach(({ y: lat, x: lon, d: title }) => {
            const point = turf.point([lon, lat]);
            const polygon = turf.polygon([vertices]);
            const isInside = turf.booleanPointInPolygon(point, polygon);

            if (isInside) {
              L.marker([lat, lon], {
                title: title,
                icon: IconCustomGooglePoint,
              })
                .bindPopup(title)
                .addTo(markersInterestPoints);
            }
          });
        }
      });
    };
*/
    const updateMarkersGoogle = () => {
      map.removeLayer(markersInterestPoints);
      markersInterestPoints = layerGroup();
      markersInterestPoints.addTo(map);
      Object.keys(markersGoogle).forEach((keyword) => {
        markersGoogle[keyword].forEach(({ pins, index, visible }) => {
          if (visible) {
            pins.forEach(({ y: lat, x: lon, d: title }) => {
              L.marker([lat, lon], {
                title: title,
                icon: IconCustomGooglePoint,
              })
                .bindPopup(title)
                .addTo(markersInterestPoints);
            });
            /*
            isRectangle(index, pins);
            isCircle(index, pins);
            isPolygon(index, pins);
            */
          }
        });
      });
    };

    updateMarkersGoogle(); //TODO mudar jeito de veficar e atualizar o estado
  }, [markersGoogle]);
  return null;
}
