import { PiCircleDuotone, PiSquareDuotone } from "react-icons/pi";
import { Icon } from "../../../../../components/icon/Icon";
import { SubTitle } from "../../../../../components/typography/SubTitle";
import { PentagonIcon } from "../../../../../components/icon/PentagonIcon";

export function AreaElement(name: string, color: string, studyType: string) {
  const icon =
    studyType !== "RADIUS_STUDY" ? (
      studyType === "RECTANGLE_STUDY" ? (
        <PiSquareDuotone size={20} />
      ) : (
        <PentagonIcon size={20} />
      )
    ) : (
      <PiCircleDuotone size={20} />
    );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        paddingLeft: 10,
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon icon={icon} color={color} />
        <SubTitle
          style={{
            fontSize: 16,
            marginLeft: 5,
            marginRight: 5,
          }}
          color={color}
        >
          {name}
        </SubTitle>
      </div>
    </div>
  );
}
