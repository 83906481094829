interface ColorsProps {
  [key: string]: {
    content: string;
    background: string;
  };
  primary: {
    content: string;
    background: string;
  };
  secondary: {
    content: string;
    background: string;
  };
  disabled: {
    content: string;
    background: string;
  };
  success: {
    content: string;
    background: string;
  };
  deepblue: {
    content: string;
    background: string;
  };
  lightblue: {
    content: string;
    background: string;
  };
  green: {
    content: string;
    background: string;
  };
  gray: {
    content: string;
    background: string;
  };
  orange: {
    content: string;
    background: string;
  };
  yellow: {
    content: string;
    background: string;
  };
}

interface optionColorsProps {
  default: ColorsProps;
  transparent: ColorsProps;
  actualHEXColors: string[];
  actualHSLColors: Array<{
    hue: number;
    sat: string;
    lum: string;
  }>;
  actualRGBColors: string[];
  link: string;
  checked: string;
  textLight: string;
  shadow: string;
  subTextInfo: string;
}

export const colors: optionColorsProps = {
  default: {
    primary: {
      content: "#004257",
      background: "#ffffff",
    },
    secondary: {
      content: "#ffffff",
      background: "#004257",
    },
    tertiary: {
      content: "#ffffff",
      background: "#459CA0",
    },
    disabled: {
      content: "#B2B2B2",
      background: "#ffffff",
    },
    success: {
      content: "#ffffff",
      background: "#50BB70",
    },
    lightblue: {
      content: "#ffffff",
      background: "#004257",
    },
    deepblue: {
      content: "#ffffff",
      background: "#459CA0",
    },
    deepblueInverted: {
      content: "#459CA0",
      background: "#ffffff",
    },
    green: {
      content: "#ffffff",
      background: "#50BB70",
    },
    gray: {
      content: "#3A3A3A",
      background: "#D5D5D5",
    },
    orange: {
      content: "#ffffff",
      background: "#F76D68",
    },
    yellow: {
      content: "#3A3A3A",
      background: "#FFFF33",
    },
  },
  transparent: {
    primary: {
      content: "#004257",
      background: "transparent",
    },
    secondary: {
      content: "#ffffff",
      background: "transparent",
    },
    tertiary: {
      content: "#459CA0",
      background: "transparent",
    },
    disabled: {
      content: "#B2B2B2",
      background: "transparent",
    },

    success: {
      content: "#50BB70",
      background: "transparent",
    },
    deepblue: {
      content: "#ffffff",
      background: "transparent",
    },
    lightblue: {
      content: "#459CA0",
      background: "transparent",
    },
    green: {
      content: "#50BB70",
      background: "transparent",
    },
    gray: {
      content: "#D5D5D5",
      background: "transparent",
    },
    orange: {
      content: "#F76D68",
      background: "transparent",
    },
    yellow: {
      content: "#FFFF33",
      background: "transparent",
    },
  },

  actualHSLColors: [
    {
      hue: 215,
      sat: "100%",
      lum: "40%",
    },
    {
      hue: 175,
      sat: "100%",
      lum: "40%",
    },
    {
      hue: 100,
      sat: "100%",
      lum: "40%",
    },
    {
      hue: 75,
      sat: "100%",
      lum: "40%",
    },
    {
      hue: 50,
      sat: "100%",
      lum: "40%",
    },
    {
      hue: 25,
      sat: "100%",
      lum: "40%",
    },
    {
      hue: 325,
      sat: "100%",
      lum: "40%",
    },
    {
      hue: 275,
      sat: "100%",
      lum: "40%",
    },
    {
      hue: 0,
      sat: "100%",
      lum: "40%",
    },
  ],

  actualRGBColors: [
    "rgba(0, 106, 255, #OPACITY)",
    "rgba(0, 255, 234, #OPACITY)",
    "rgba(85, 255, 0, #OPACITY)",
    "rgba(191, 255, 0, #OPACITY)",
    "rgba(255, 213, 0, #OPACITY)",
    "rgba(255, 106, 0, #OPACITY)",
    "rgba(255, 0, 149, #OPACITY)",
    "rgba(149, 0, 255, #OPACITY)",
    "rgba(255, 0, 0, #OPACITY)",
  ],

  actualHEXColors: [
    "#0256CC",
    "#00D9A7",
    "#48C600",
    "#9FC900",
    "#B2AC01",
    "#C35A08",
    "#D70069",
    "#6703DA",
    "#D50014",
    "#808080",
  ],

  subTextInfo: "#808080",
  link: "#459CA0",
  textLight: "#D5D5D5",
  checked: "#3A3A3A",
  shadow: "rgba(0, 0, 0, 0.3)",
};
