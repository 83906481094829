import { FaMapMarkerAlt, FaSearchLocation } from "react-icons/fa";
import { ModalDefault } from "../../../../../components/Modal/ModalDefault";
import { TitleModal } from "../../../../../components/typography/TitleModal";
import { SubTitle } from "../../../../../components/typography/SubTitle";
import { useEffect, useState } from "react";
import { Divider } from "antd";
import { ButtonDefault } from "../../../../../components/Button/ButtonDefault";
import { CustomInput } from "../../../../../components/Input/CustomInput";
import { SurroundingsParams } from "../../../../../Types/Params";
import { Shapes } from "../../../../../Types/Shapes";
import GA4 from "react-ga4";
import { UserToken } from "../../../../../services/userToken";
import { MarkIcon } from "../../../../../components/icon/MarkIcon";
import { salvaLog } from "../../../../../services/getOptionsData";

interface ModalBestAreaProps {
  limit: number;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  paramsToSurroundings: SurroundingsParams;
  setParamsToSurroundings: React.Dispatch<
    React.SetStateAction<SurroundingsParams>
  >;
  setShapeActive: React.Dispatch<React.SetStateAction<Shapes>>;
  setButtonToConfirmPinsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ModalBestSorroundings({
  limit,
  setModalOpen,
  paramsToSurroundings,
  setParamsToSurroundings,
  setShapeActive,
  setButtonToConfirmPinsVisible,
}: ModalBestAreaProps) {
  const [nuAreas, setNuAreas] = useState(
    paramsToSurroundings.nuAreas !== 0
      ? String(paramsToSurroundings.nuAreas)
      : ""
  );
  const [maxRadiusKM, setMaxRadiusKM] = useState(
    paramsToSurroundings.maxRadiusKM !== 0
      ? String(paramsToSurroundings.maxRadiusKM)
      : ""
  );

  const full =
    nuAreas !== "" &&
    maxRadiusKM !== "" &&
    paramsToSurroundings.pins &&
    paramsToSurroundings.pins.length > 0;

  return (
    <ModalDefault
      icon={<FaSearchLocation />}
      setModalOpen={() => {
        setModalOpen(false);
        setParamsToSurroundings({
          ...paramsToSurroundings,
          deletePins: true,
        });
      }}
      title="Melhores áreas (Entorno)"
      styleContent={{
        maxWidth: 546,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TitleModal style={{ fontSize: "24px" }}>ESTUDOS</TitleModal>
        <SubTitle
          style={{
            textAlign: "center",
            marginBottom: 24,
          }}
        >
          Encontre regiões com perfis semelhantes a endereços de sucesso.
        </SubTitle>

        <CustomInput
          value={nuAreas}
          autocomplete="off"
          onChange={(e) => {
            setNuAreas(e.target.value);
            salvaLog({
              action: "Selecionou número de áreas (Entorno)",
              params: "" + e.target.value,
            }).then((result: any) => {});
          }}
          limitNumber={limit}
          placeholder="Número de estudos"
          type="number"
        />
        <CustomInput
          style={{
            marginTop: 16,
          }}
          autocomplete="off"
          value={maxRadiusKM}
          onChange={(e) => {
            setMaxRadiusKM(e.target.value);
            salvaLog({
              action: "Selecionou tamanho dos raios (Entorno)",
              params: "" + e.target.value,
            }).then((result: any) => {});
          }}
          placeholder="Estudos em Km²"
          type="number"
        />

        <ButtonDefault
          style={{
            marginTop: 16,
            justifyContent: "space-between",
            marginRight: "auto",
            marginLeft: "auto",
          }}
          size="medium"
          title="MARCAR PONTOS DE REFERÊNCIA"
          icon={<MarkIcon />}
          iconPosition="right"
          border={true}
          color={"secondary"}
          onClick={() => {
            setShapeActive("pinsToSurroundings");
            setModalOpen(false);
            setParamsToSurroundings((oldState) => ({
              ...oldState,
              nuAreas: Number(nuAreas),
              maxRadiusKM: Number(maxRadiusKM),
              rect: null,
            }));
            setButtonToConfirmPinsVisible(true);
          }}
        />
        <Divider style={{ margin: 24 }} />
        <ButtonDefault
          style={{
            width: "min-content",
          }}
          disabled={!full}
          size="medium"
          title="SELECIONAR ÁREA NO MAPA"
          icon={<FaMapMarkerAlt />}
          iconPosition="right"
          border={true}
          color={full ? "secondary" : "disabled"}
          borderRadius={40}
          onClick={() => {
            setParamsToSurroundings((oldState) => ({
              ...oldState,
              nuAreas: Number(nuAreas),
              maxRadiusKM: Number(maxRadiusKM),
              rect: null,
            }));
            setModalOpen(false);
            setShapeActive("surroundings");
          }}
        />
      </div>
    </ModalDefault>
  );
}
