import { useEffect, useState } from "react";
import fonts from "../../config/fonts";
import images from "../../config/images";
import "./Loading.css";

export function Loading() {
  const [currentComponentIndex, setCurrentComponentIndex] = useState(0);

  const firstPiece = () => {
    return (
      <>
        <img src={images.Pin} className="pin"></img>
      </>
    );
  };

  const secondPiece = () => {
    return (
      <>
        <img src={images.Pin} className="pin"></img>
        <img src={images.Line}></img>
        <img src={images.Pin} className="pin"></img>
      </>
    );
  };

  const thirdPiece = () => {
    return (
      <>
        <img src={images.Pin} className="pin"></img>
        <img src={images.Line}></img>
        <img src={images.Pin} className="pin"></img>
        <img src={images.Line}></img>
        <img src={images.Pin} className="pin"></img>
      </>
    );
  };

  const components = [firstPiece, secondPiece, thirdPiece];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentComponentIndex(
        (prevIndex) => (prevIndex + 1) % components.length
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [components.length]);

  const CurrentComponent = components[currentComponentIndex];

  return (
    <div className="loading-container" style={{ fontSize: 20 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: 580,
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <h1
          className="title"
          style={{
            fontFamily: fonts.expBold,
          }}
        >
          DEEPEN MAPS
        </h1>
        <p
          className="subtitle"
          style={{
            fontFamily: fonts.regular,
            fontSize: fonts.regular,
          }}
        >
          Estudos podem ser feitos em todo o mapa, independente das regiões
          censitárias do mapa
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "left",
            marginTop: 70,
            width: 310,
          }}
        >
          <CurrentComponent />
        </div>
      </div>
    </div>
  );
}
