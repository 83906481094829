import { CSSProperties, useEffect, useState } from "react";
import "./Styles.css";
import GA4 from "react-ga4";
import {
  deepWebLoginService,
  deepWebPasswordRequest,
} from "../../services/login";
import { UserToken } from "../../services/userToken";
import iconEye from "../../assets/icons/Icon-eye.svg";
import iconEyeOff from "../../assets/icons/Icon-off.svg";
import iconArrowRight from "../../assets/icons/Icon-arrow-right.svg";
import { useAuth } from "../../contexts/AuthContext";
import fonts from "../../config/fonts";
import { salvaLog } from "../../services/getOptionsData";

export function SignIn() {
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");
  const [eyeOn, eyeOff] = useState(false);
  const [esqueci, setEsqueci] = useState(false);
  const [enviei, setEnviei] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setAuthUser } = useAuth();

  const enable = login !== "" && pass !== "" ? true : false;

  useEffect(() => {
    const remember = document.getElementById("remember") as HTMLInputElement;
    if (remember && !remember.checked) {
      remember.checked = !remember.checked;
    }
  }, []);

  function doLogin(login: string, pass: string, remember: boolean) {
    const params = { username: login, password: pass };
    setLoading(true);
    deepWebLoginService(params, (response: any) => {
      setLoading(false);
      if (response.status === 200) {
        setAuthUser({
          token: response.headers.get("Authorization"),
          remember,
          pass,
        });
        UserToken.login = login;
        UserToken.token = response.headers.get("Authorization");
        setTimeout(async () => {
          salvaLog({
            action: "Fez Login",
            params: "",
          }).then((result: any) => {});
        }, 3000);
      } else if (response.status === 401) {
        setError(true);
      }
    });
  }

  const handleLogin = (e: any) => {
    e.preventDefault();
    const login = document.getElementById("login") as HTMLInputElement;
    const pass = document.getElementById("password") as HTMLInputElement;
    const remember = document.getElementById("remember") as HTMLInputElement;

    if (pass && login) {
      doLogin(login.value, pass.value, remember?.checked ?? false);
    }
  };

  const InputStyles = (type: boolean) => {
    let style = {
      width: "100%",
      height: type ? "45px" : "50px",
      borderRadius: "8px",
      border: type ? "none" : "1px solid #B2B2B2",
      paddingInline: type ? 0 : 10,
      fontFamily: fonts.regular,
      fontSize: fonts.sizes.regular,
      marginBottom: type ? 0 : 15,
      outline: "none",
      color: "#3A3A3A",
    };

    return style;
  };

  return (
    <div id="header-fixed">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "#F2F2F2",
        }}
      >
        <img
          src="deepen_logo.png"
          alt="DEEPEN LIGHT"
          height="40px"
          style={{ margin: "24px 0px" }}
        />
        {/* line */}
        <div
          style={{
            height: "1px",
            width: "90%",
            background: "#DEDEDE",
            alignSelf: "center",
          }}
        />
      </div>
      <div className="loginContainer">
        <div className="loginBox">
          {esqueci ? (
            enviei ? (
              <div>
                <div
                  style={{
                    color: "#004257",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingTop: "30px",
                    paddingBottom: "20px",
                    fontSize: 20,
                  }}
                >
                  PEDIDO ENVIADO
                </div>
                <div
                  style={{
                    color: "#B2B2B2",
                    textAlign: "center",
                    paddingRight: "25px",
                    paddingLeft: "25px",
                    fontSize: 12,
                  }}
                >
                  Por favor cheque seu email.
                  <br />
                  <br />
                  Se o email digitado estiver associado a um usuário em nosso
                  banco de dados, você receberá um email com um link e
                  instruções para resetar a sua senha.
                  <br />
                  <br />
                  Se nosso email não for recebido dentro de 5 minutos, por favor
                  verifique sua caixa de Spam ou entre em contato com nosso
                  serviço ao cliente para mais assistência.
                  <br />
                </div>
                <div
                  style={{
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    paddingBottom: "20px",
                    width: "100%",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "white",
                      border: 0,
                      color: "#004257",
                      textAlign: "center",
                      alignSelf: "center",
                      fontSize: 16,
                      width: "100%",
                      paddingTop: "0px",
                      padding: "10px",
                    }}
                    onClick={() => {
                      setEsqueci(false);
                      setEnviei(false);
                    }}
                  >
                    Voltar
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    color: "#004257",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingTop: "30px",
                    paddingBottom: "20px",
                    fontSize: 20,
                  }}
                >
                  RECUPERAR A SENHA
                </div>
                <div
                  style={{
                    color: "#B2B2B2",
                    textAlign: "center",
                    paddingRight: "25px",
                    paddingLeft: "25px",
                    fontSize: 12,
                  }}
                >
                  Informe seu email abaixo para receber um link para cadastrar
                  uma nova senha para sua conta.
                </div>
                <div
                  style={{
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    width: "100%",
                  }}
                >
                  <input
                    id="email"
                    type="text"
                    style={{ width: "100%", height: "30px" }}
                    placeholder="Digite aqui seu email cadastrado"
                    defaultValue={login}
                  />
                </div>

                <div
                  style={{
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    paddingBottom: "20px",
                    width: "100%",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "gray",
                      border: 0,
                      color: "white",
                      textAlign: "center",
                      alignSelf: "center",
                      fontSize: 16,
                      width: "100%",
                      padding: "10px",
                      borderRadius: "20px",
                    }}
                    onClick={() => {
                      const email = document.getElementById(
                        "email"
                      ) as HTMLInputElement;
                      const params = { email: email.value };
                      deepWebPasswordRequest(params, () => {
                        setEnviei(true);
                      });
                    }}
                  >
                    RESETAR SENHA
                  </button>
                </div>
                <div
                  style={{
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    paddingBottom: "20px",
                    width: "100%",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "white",
                      border: 0,
                      color: "#004257",
                      textAlign: "center",
                      alignSelf: "center",
                      fontSize: 12,
                      width: "100%",
                      paddingTop: "0px",
                      padding: "10px",
                    }}
                    onClick={() => {
                      setEsqueci(false);
                    }}
                  >
                    Cancelar e voltar para a tela de login
                  </button>
                </div>
              </div>
            )
          ) : (
            <div>
              <p className="titleLogin">FAÇA LOGIN</p>
              <p className="textLogin">
                Entre com seus dados abaixo para fazer login em sua conta e
                acessar o nosso sistema
              </p>
              <form onSubmit={handleLogin}>
                <input
                  id="login"
                  type="text"
                  style={{
                    ...InputStyles(false),
                    borderColor: error ? "#F76D68" : "#B2B2B2",
                  }}
                  placeholder="Digite aqui seu email cadastrado"
                  defaultValue={login}
                  onChange={(e) => {
                    setLogin(e.target.value);
                  }}
                />
                <div
                  className="inputBorder"
                  style={{
                    borderColor: error ? "#F76D68" : "#B2B2B2",
                  }}
                >
                  <input
                    id="password"
                    type={eyeOn ? "text" : "password"}
                    style={InputStyles(true)}
                    placeholder="Digite aqui sua senha"
                    defaultValue={pass}
                    onChange={(e) => {
                      setPass(e.target.value);
                    }}
                  />
                  <img
                    onClick={() => {
                      eyeOff(!eyeOn);
                    }}
                    alt="mostrar/esconder senha"
                    style={{ cursor: "pointer" }}
                    src={eyeOn ? iconEyeOff : iconEye}
                  />
                </div>
                {error && (
                  <p
                    style={{
                      color: "#F76D68",
                      fontSize: 14,
                      textAlign: "start",
                      margin: "10px 0px",
                    }}
                  >
                    Email ou senha inválidos
                  </p>
                )}
                <div className="buttonContainer">
                  <div style={{ display: "flex" }}>
                    <input id="remember" type="checkbox" placeholder="" />
                    <p className="checkboxButton">Continuar conectado</p>
                  </div>
                  <button
                    className="forgotButton"
                    style={{
                      border: "none",
                      outline: "none",
                      backgroundColor: "transparent",
                    }}
                    type="button"
                    onClick={() => {
                      setEsqueci(true);
                    }}
                  >
                    Esqueci minha senha
                  </button>
                </div>
                <button
                  className="loginButton"
                  style={{
                    backgroundColor: enable ? "#004257" : "#B2B2B2",
                    border: "none",
                    outline: "none",
                  }}
                  disabled={!enable}
                  autoFocus
                  type="submit"
                >
                  FAZER LOGIN
                  <img src={iconArrowRight} alt="" />
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
