import { AreaListData } from "../Preview/components/AreaListData";
import {
  CompleteReportData,
  GraphicsListByType,
  GraphTotalPackage,
} from "../Preview/components/graphs/graphTSInterface";
import { Divider, Button } from "antd";
import { Radar } from "react-chartjs-2";
import { colorToRGBString, format } from "../../Utils";
import { getMapaDeDescricoesParaHTML } from "./descricaoDasSessoes";
import { graphOptions } from "./components/CorrectGraphic";
import { TitleModal } from "../../../../components/typography/TitleModal";
import { SubTitleModal } from "../../../../components/typography/SubTitleModalReports";
import { PinsList } from "./components/PinsList";
import { GraphTypeZero } from "./components/GraphTypeZero";
import GA4 from "react-ga4";
import { UserToken } from "../../../../services/userToken";
import { SeparaListaEmDuasColunas } from "./components/SeparaListaEmDuasColunas";
import fonts from "../../../../config/fonts";
import { ReportBox } from "./components/ReportBox";
import { salvaLog } from "../../../../services/getOptionsData";
import { useEffect } from "react";

interface ReportTabsProps {
  areaList: AreaListData[];
  selecionado: number;
  listadesessoes: GraphicsListByType[];
  graphicsData: CompleteReportData;
  simpleStudyData: GraphTotalPackage | undefined;
  cnaesData: GraphTotalPackage[] | undefined;
  setSelecionado: React.Dispatch<React.SetStateAction<number>> | null;
  addressFormat: Function;
  cnaePesquisado: boolean;
}

interface RadarData {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
}

export function ReportTabs({
  areaList,
  listadesessoes,
  graphicsData,
  selecionado,
  simpleStudyData,
  cnaesData,
  setSelecionado,
  addressFormat,
  cnaePesquisado,
}: ReportTabsProps) {
  const descricaoDasSessoes = getMapaDeDescricoesParaHTML();
  const renderButton = (sessao: GraphicsListByType, index: number) => {
    return (
      <div key={"ReportTabs" + index}>
        <Button
          style={{
            backgroundColor: "transparent",
            color: index === selecionado ? "#004257" : "lightgray",
            fontWeight: "bold",
            fontSize: "20px",
            border: "none",
            boxShadow: "none",
          }}
          icon={null}
          onClick={() => {
            if (setSelecionado !== null) {
              const aba = descricaoDasSessoes.get(sessao.name)?.tituloAcentuado;
              salvaLog({
                action: "Abriu Aba do Relatório",
                params: "" + aba,
              }).then((result: any) => {});
              setSelecionado(index);
            }
          }}
        >
          {descricaoDasSessoes.get(sessao.name)?.tituloAcentuado}
        </Button>
      </div>
    );
  };

  useEffect(() => {
    return () => {
      setSelecionado && setSelecionado(0);
    };
  }, []);

  const renderRadarChart = (sessao: GraphicsListByType, index: number) => {
    const lbs: string[] = [];
    const dataRadar: RadarData[] = [];
    var i: number = 0;

    sessao.graphicsList.forEach((element) => {
      lbs.push(element.graphicname);
      for (let index = 0; index < element.data.length; index++) {
        const sample = element.data[index];
        if (i === 0) {
          dataRadar.push({
            label:
              sample.poly.centroid === "Nao encontrado"
                ? "Endereço não encontrado"
                : addressFormat(sample.poly.centroid),
            data: [sample.variable.labels[0].value],
            backgroundColor:
              "rgba(" + colorToRGBString(sample.area.cor) + ", 0.2)",
            borderColor: "rgba(" + colorToRGBString(sample.area.cor) + ", 1)",
            borderWidth: 1,
          });
        } else {
          dataRadar[index].data.push(sample.variable.labels[0].value);
        }
      }
      i++;
    });

    const options = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          titleFont: {
            size: 14,
            family: fonts.regular,
          },
          bodyFont: {
            size: 14,
            family: fonts.regular,
          },
          callbacks: {
            label: function (context: any) {
              return addressFormat(context.dataset.label);
            },
          },
        },
      },
      scales: {
        r: {
          min: 0,
          ticks: {
            backdropColor: "white",
            backdropPadding: 2,
            font: {
              size: 16,
              family: fonts.regular,
            },
            z: 99,
          },
          pointLabels: {
            color: "#459CA0",
            font: {
              size: 16,
              family: fonts.regular,
            },
          },
        },
      },
    };

    const data = {
      labels: lbs,
      datasets: dataRadar,
    };

    return (
      <div key={"renderRadarChart" + index}>
        <div>
          <TitleModal size="large" style={{ textAlign: "center" }}>
            {descricaoDasSessoes.get(sessao.name)?.tituloAcentuado}
          </TitleModal>
          <SubTitleModal>
            {descricaoDasSessoes.get(sessao.name)?.descricao}
          </SubTitleModal>
          {descricaoDasSessoes.get(sessao.name)?.tabela}
        </div>
        <div
          key={"renderRadarChartsessao" + index + "_0"}
          id={"sessao" + index + "_0"}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Radar data={data} options={options} />
        </div>
      </div>
    );
  };

  const renderAlfinetesChart = (sessao: GraphicsListByType, index: number) => {
    return (
      <div key={"renderAlfinetesChart" + index}>
        <div>
          <div>
            <TitleModal size="large" style={{ textAlign: "center" }}>
              {descricaoDasSessoes.get(sessao.name)?.tituloAcentuado}
            </TitleModal>
            <SubTitleModal>
              {descricaoDasSessoes.get(sessao.name)?.descricao}
            </SubTitleModal>
          </div>
        </div>
        <SeparaListaEmDuasColunas lista={sessao.graphicsList} index={index} />
        {sessao.graphicsList.map((grafico, indx) => {
          if (grafico.graphicname === "Lista de todos os alfinetes") {
            return (
              <PinsList
                areaList={areaList}
                graphicsData={graphicsData}
                grafico_index={indx}
                sessao_index={index}
              />
            );
          } else {
            return <div />;
          }
        })}
      </div>
    );
  };
  const renderCNPJChart = (sessao: GraphicsListByType, index: number) => {
    if (!simpleStudyData) {
      return <></>;
    }
    const optionsCS: graphOptions = {
      sessao_indexAxis: "y",
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        title: {
          display: false,
          text: "Capital Social",
        },
        legend: {
          display: false,
        },
      },
      aspectRatio: 2,
    };
    const optionsPort: graphOptions = {
      sessao_indexAxis: "y",
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        title: {
          display: false,
          text: "Porte",
        },
        legend: {
          display: false,
        },
      },
      aspectRatio: 2,
    };

    return (
      <div key={"renderCNPJChart" + index}>
        <div>
          <div>
            <TitleModal size="large" style={{ textAlign: "center" }}>
              {descricaoDasSessoes.get(sessao.name)?.tituloAcentuado}
            </TitleModal>
            <SubTitleModal>
              {descricaoDasSessoes.get(sessao.name)?.descricao}
            </SubTitleModal>
          </div>
        </div>
        <div>
          <div style={{ marginTop: "15px", marginBottom: "15px" }}>
            <div>
              <div
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
                key={"renderCNPJChartsessao" + index + "_" + 0}
                id={"sessao" + index + "_" + 0}
              >
                <ReportBox
                  label={"Total de Empresas"}
                  value={format(simpleStudyData.total, "Inteiro")}
                />
                <Divider />
                {sessao.graphicsList.map((grafico) => {
                  if (grafico.data && grafico.data.length > 1) {
                    return (
                      <SeparaListaEmDuasColunas
                        lista={sessao.graphicsList}
                        index={index}
                      />
                    );
                  } else {
                    return <></>;
                  }
                })}
              </div>
              <div
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
                key={"renderCNPJChartsessao" + index + "_" + 1}
                id={"sessao" + index + "_" + 1}
              >
                <p>Total por Capital Social</p>
                <GraphTypeZero
                  nome={"Total por Capital Social"}
                  dadosGrafico={simpleStudyData.graphCS}
                  graphOptions={optionsCS}
                  sessao={index}
                  index={1}
                  measureUnit={"Integer"}
                />
              </div>
              <div
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
                key={"renderCNPJChartsessao" + index + "_" + 2}
                id={"sessao" + index + "_" + 2}
              >
                <p>Total por Porte</p>
                <GraphTypeZero
                  nome={"Total por Porte"}
                  dadosGrafico={simpleStudyData.graphPort}
                  graphOptions={optionsPort}
                  sessao={index}
                  index={1}
                  measureUnit={"Integer"}
                />
              </div>
            </div>
          </div>
          {cnaesData?.map((cnaeData, inx) => {
            return (
              <div
                key={"cnaesData" + inx}
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
              >
                <div>
                  <div
                    style={{ paddingTop: "15px", paddingBottom: "15px" }}
                    key={"cnaesDatasessao" + index + "_" + (0 + inx * 3)}
                    id={"sessao" + index + "_" + (0 + inx * 3)}
                  >
                    <p style={{ color: "#459CA0", fontSize: "25px" }}>
                      CNAE {cnaeData.cnae.replace(";", ": ")}
                    </p>
                    <Divider />
                    <ReportBox
                      label={"Total de Empresas"}
                      value={format(cnaeData.total, "Inteiro")}
                    />
                  </div>
                  <div
                    style={{ paddingTop: "15px", paddingBottom: "15px" }}
                    key={"cnaesDatasessao" + index + "_" + (1 + inx * 3)}
                    id={"sessao" + index + "_" + (1 + inx * 3)}
                  >
                    <p>Total por Capital Social</p>
                    <GraphTypeZero
                      nome={"Total por Capital Social"}
                      dadosGrafico={cnaeData.graphCS}
                      graphOptions={optionsCS}
                      sessao={index}
                      index={1}
                      measureUnit={"Integer"}
                    />
                  </div>
                  <div
                    style={{ paddingTop: "15px", paddingBottom: "15px" }}
                    key={"cnaesDatasessao" + index + "_" + (2 + inx * 3)}
                    id={"sessao" + index + "_" + (2 + inx * 3)}
                  >
                    <p>Total por Porte</p>
                    <GraphTypeZero
                      nome={"Total por Porte"}
                      dadosGrafico={cnaeData.graphPort}
                      graphOptions={optionsPort}
                      sessao={index}
                      index={1}
                      measureUnit={"Integer"}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div>
      {selecionado !== -1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ padding: 5 }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              width: 1000,
            }}
          >
            {cnaePesquisado
              ? listadesessoes.map((sessao, index) => {
                  return renderButton(sessao, index);
                })
              : listadesessoes
                  .filter((item) => item.name !== "CNPJ")
                  .map((sessao, index) => {
                    return renderButton(sessao, index);
                  })}
          </div>
          <div style={{ padding: 5 }} />
        </div>
      )}
      <Divider />
      {listadesessoes.map((sessao, index) => {
        if (index === selecionado || selecionado === -1) {
          if (sessao.name === "Taxas") {
            return renderRadarChart(sessao, index);
          } else {
            if (sessao.name === "Alfinetes") {
              return renderAlfinetesChart(sessao, 5);
            } else if (sessao.name === "CNPJ") {
              return renderCNPJChart(sessao, 6);
            } else {
              return (
                <div key={"listadesessoes" + index}>
                  <div>
                    <div>
                      <TitleModal size="large" style={{ textAlign: "center" }}>
                        {descricaoDasSessoes.get(sessao.name)?.tituloAcentuado}
                      </TitleModal>
                      <SubTitleModal>
                        {descricaoDasSessoes.get(sessao.name)?.descricao}
                      </SubTitleModal>
                    </div>
                  </div>
                  <SeparaListaEmDuasColunas
                    lista={sessao.graphicsList}
                    index={index}
                  />
                </div>
              );
            }
          }
        }
        return <></>;
      })}
    </div>
  );
}
