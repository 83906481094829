import React from "react";

interface props {
  fill?: string;
}

export const MeasureIcon = ({ fill }: props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox={"0 0 24 24"}
      fill={fill}
    >
      <path
        d="M0,17.9a4.487,4.487,0,0,1,6.454-4.046l8.981-9.076A4.486,4.486,0,1,1,19.513,7.4a4.457,4.457,0,0,1-2.278-.622L8.4,15.7A4.491,4.491,0,1,1,0,17.9Z"
        transform="translate(0 1.6)"
      />
    </svg>
  );
};
