export class MapData {
  static bounds = {
    _northEast: { lat: -27.023654129209838, lng: -47.50488281250001 },
    _southWest: { lat: -28.164032516628062, lng: -49.59503173828126 },
  };
  static zoom = 11;
  static width;
  static height;
  static img;
  static center;
}
