import { useEffect, useState } from "react";
import { ButtonDefault } from "../../../../components/Button/ButtonDefault";
import {
  FaArrowRight,
  FaGlobeAmericas,
  FaMapMarkedAlt,
  FaSearchLocation,
} from "react-icons/fa";
import { ExpandableToolbar } from "../../../../components/ExpandableToolbar/ExpandableToolbar";
import { ModalBestArea } from "./Modals/ModalBestArea";
import { ModalFreeNavigation } from "./Modals/ModalFreeNavigation";
import { ModalBestSorroundings } from "./Modals/ModalBestSorroundings";
import {
  VariableGroupProps,
  VariableSelectOption,
} from "../../../../Types/Variable";
import {
  BestAreaVariablesParams,
  EditPinParams,
  FreeNavigationParams,
  RadiusExclusivityParams,
  SurroundingsParams,
} from "../../../../Types/Params";
import { Shapes } from "../../../../Types/Shapes";
import GA4 from "react-ga4";
import { UserToken } from "../../../../services/userToken";
import {
  getStates,
  getValidLimitStudies,
  salvaLog,
} from "../../../../services/getOptionsData";
import { ReactComponent as RayExclusivity } from "../../../../assets/icons/raio-de-exclusividade-icon.svg";
import { ModalRadiusExclusivity } from "./Modals/ModalRadiusExclusivity";
import { HiXMark } from "react-icons/hi2";
import { PolygonShapesProps } from "../../../../Types/interfacs";
type ModalsTypes = "free" | "variable" | "surroundings" | "exclusivity";

interface MenuCenterProps {
  // Shapes and Polygons
  polygon: PolygonShapesProps;
  shapeActive: Shapes;
  setShapeActive: React.Dispatch<React.SetStateAction<Shapes>>;

  // Variable Groups
  variables?: VariableGroupProps;

  // Edit Pin Parameters
  pinsOnEdition: EditPinParams;
  setPinsOnEdition: React.Dispatch<React.SetStateAction<EditPinParams>>;

  // Best Area Variables
  paramsToBestAreaVariables: BestAreaVariablesParams;
  setParamsToBestAreaVariables: React.Dispatch<
    React.SetStateAction<BestAreaVariablesParams>
  >;
  buttonToConfirmBestAreaVariables: boolean;

  // Surroundings Parameters
  paramsToSurroundings: SurroundingsParams;
  setParamsToSurroundings: React.Dispatch<
    React.SetStateAction<SurroundingsParams>
  >;
  buttonToConfirmSurroundings: boolean;

  // Free Navigation Parameters
  paramsToFreeNavigation: FreeNavigationParams;
  setParamsToFreeNavigation: React.Dispatch<
    React.SetStateAction<FreeNavigationParams>
  >;

  // Radius Exclusivity Parameters
  paramsToRadiusExclusivity: RadiusExclusivityParams;
  setParamsToRadiusExclusivity: React.Dispatch<
    React.SetStateAction<RadiusExclusivityParams>
  >;

  // Processing State
  processing: boolean;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface StatesProps {
  state: string;
  stateCode: number;
}

export function MenuCenter({
  polygon,
  variables,
  pinsOnEdition,
  setPinsOnEdition,
  paramsToBestAreaVariables,
  setParamsToBestAreaVariables,
  paramsToSurroundings,
  setParamsToSurroundings,
  shapeActive,
  processing,
  setProcessing,
  setShapeActive,
  buttonToConfirmSurroundings,
  buttonToConfirmBestAreaVariables,
  paramsToFreeNavigation,
  setParamsToFreeNavigation,
  paramsToRadiusExclusivity,
  setParamsToRadiusExclusivity,
}: MenuCenterProps) {
  const [states, SetStates] = useState<StatesProps[]>();
  const [modalActive, setModalActive] = useState<ModalsTypes>("free");
  const [modalBestVariableOpen, setModalBestVariableOpen] = useState(false);
  const [modalFreeNavigationOpen, setModalFreeNavigationOpen] = useState(false);
  const [modalBestSorroundingsOpen, setModalBestSorroundingsOpen] =
    useState(false);
  const [modalToRadiusExclusivityOpen, setModalRadiusExclusivity] =
    useState(false);
  const [buttonToConfirmPinsVisible, setButtonToConfirmPinsVisible] =
    useState(false);
  const [open, setOpen] = useState<boolean>();
  const [variableOptions, setVariableOptions] =
    useState<VariableSelectOption[]>();
  const [
    buttonToConfirmRadiusExclusivity,
    setButtonToConfirmRadiusExclusivity,
  ] = useState(false);
  const [limit, setLimit] = useState(0);

  useEffect(() => {
    setProcessing(
      modalBestVariableOpen ||
        modalFreeNavigationOpen ||
        modalBestSorroundingsOpen ||
        modalToRadiusExclusivityOpen ||
        buttonToConfirmPinsVisible ||
        buttonToConfirmRadiusExclusivity ||
        shapeActive !== "arrow" ||
        buttonToConfirmSurroundings ||
        buttonToConfirmBestAreaVariables ||
        pinsOnEdition !== null
    );
  }, [
    modalBestVariableOpen,
    modalFreeNavigationOpen,
    modalBestSorroundingsOpen,
    modalToRadiusExclusivityOpen,
    buttonToConfirmPinsVisible,
    buttonToConfirmRadiusExclusivity,
    shapeActive,
    buttonToConfirmSurroundings,
    buttonToConfirmBestAreaVariables,
    pinsOnEdition,
  ]);

  useEffect(() => {
    getStates()
      .then((states) => {
        //ordernar por nome
        const sortedStates = states.sort((a: StatesProps, b: StatesProps) =>
          a.state.localeCompare(b.state)
        );
        SetStates(sortedStates);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!variables) return;
    const variableOptions = Object.keys(variables).flatMap((variable) => {
      return variables[variable].flatMap((subVariable) => {
        return {
          label: subVariable.name,
          options: subVariable.labels.map(({ name, nameDesc }) => ({
            label: nameDesc,
            value: name,
          })),
        };
      });
    });

    const sortedVariableOptions = variableOptions.sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    setVariableOptions(sortedVariableOptions);
  }, [variables]);

  useEffect(() => {
    const numberStudies =
      polygon.polygonList.length +
      polygon.rectangleList.length +
      polygon.radiusList.length;

    getValidLimitStudies({
      used: numberStudies + 1,
    }).then((response) => {
      setLimit(100); //TODO Tirar
      if (!response) return;
      setLimit(response.limit - numberStudies);
    });
  }, [polygon]);

  useEffect(() => {
    if (buttonToConfirmRadiusExclusivity) {
      paramsToRadiusExclusivity.rects.length === 0 &&
        setButtonToConfirmRadiusExclusivity(false);
    } else {
      paramsToRadiusExclusivity.rects.length > 0 &&
        !buttonToConfirmRadiusExclusivity &&
        setButtonToConfirmRadiusExclusivity(true);
    }
  }, [paramsToRadiusExclusivity.rects]);

  return (
    <>
      {processing ? (
        <></>
      ) : (
        <ExpandableToolbar
          style={{
            padding: "10px 0",
          }}
          direction="column"
          setOpen={setOpen}
        >
          <ButtonDefault
            tooltip="Navegação livre"
            icon={<FaGlobeAmericas />}
            title={open ? "Navegação livre" : undefined}
            color={modalActive === "free" ? "primary" : "disabled"}
            transparent={true}
            onClick={() => {
              setModalActive("free");
              setModalFreeNavigationOpen(true);
              salvaLog({
                action: "Abriu Modal Navegação Livre",
                params: "",
              }).then((result: any) => {});
            }}
            style={{
              height: 40,
              justifyContent: open ? "flex-start" : "center",
            }}
          />
          <ButtonDefault
            tooltip="Melhores áreas (Variáveis)"
            icon={<FaMapMarkedAlt />}
            title={open ? "Melhores áreas (Variáveis)" : undefined}
            color={modalActive === "variable" ? "primary" : "disabled"}
            transparent={true}
            onClick={() => {
              setModalActive("variable");
              setModalBestVariableOpen(true);
              salvaLog({
                action: "Abriu Modal Melhores Áreas (Variáveis)",
                params: "",
              }).then((result: any) => {});
            }}
            style={{
              height: 40,
              justifyContent: open ? "flex-start" : "center",
            }}
          />

          <ButtonDefault
            tooltip="Melhores áreas (Entorno)"
            icon={<FaSearchLocation />}
            title={open ? "Melhores áreas (Entorno)" : undefined}
            color={modalActive === "surroundings" ? "primary" : "disabled"}
            transparent={true}
            onClick={() => {
              setModalActive("surroundings");
              setModalBestSorroundingsOpen(true);
              salvaLog({
                action: "Abriu Modal Melhores Áreas (Entorno)",
                params: "",
              }).then((result: any) => {});
            }}
            style={{
              height: 40,
              justifyContent: open ? "flex-start" : "center",
            }}
          />

          <ButtonDefault
            tooltip="Raio de Exclusividade"
            icon={<RayExclusivity />}
            title={open ? "Raio de Exclusividade" : undefined}
            color={modalActive === "exclusivity" ? "primary" : "disabled"}
            transparent={true}
            onClick={() => {
              setModalActive("exclusivity");
              setModalRadiusExclusivity(true);
              salvaLog({
                action: "Abriu Modal Raio de Exclusividade",
                params: "",
              }).then((result: any) => {});
            }}
            style={{
              height: 40,
              justifyContent: open ? "flex-start" : "center",
            }}
          />
        </ExpandableToolbar>
      )}
      {modalFreeNavigationOpen && (
        <ModalFreeNavigation
          variableOptions={variableOptions}
          statesOptions={states}
          setModalOpen={setModalFreeNavigationOpen}
          paramsToFreeNavigation={paramsToFreeNavigation}
          setParamsToFreeNavigation={setParamsToFreeNavigation}
        />
      )}
      {modalBestVariableOpen && (
        <ModalBestArea
          limit={limit}
          variableOptions={variableOptions}
          setModalOpen={setModalBestVariableOpen}
          setShapeActive={setShapeActive}
          paramsToBestAreaVariables={paramsToBestAreaVariables}
          setParamsToBestAreaVariables={setParamsToBestAreaVariables}
        />
      )}
      {modalBestSorroundingsOpen && (
        <ModalBestSorroundings
          limit={limit}
          setShapeActive={setShapeActive}
          setModalOpen={setModalBestSorroundingsOpen}
          paramsToSurroundings={paramsToSurroundings}
          setParamsToSurroundings={setParamsToSurroundings}
          setButtonToConfirmPinsVisible={setButtonToConfirmPinsVisible}
        />
      )}

      {modalToRadiusExclusivityOpen && (
        <ModalRadiusExclusivity
          limit={limit}
          setPinsOnEdition={setPinsOnEdition}
          setShapeActive={setShapeActive}
          variableOptions={variableOptions}
          setModalOpen={setModalRadiusExclusivity}
          paramsToRadiusExclusivity={paramsToRadiusExclusivity}
          setParamsToRadiusExclusivity={setParamsToRadiusExclusivity}
          setButtonToConfirmPinsVisible={setButtonToConfirmPinsVisible}
          setButtonToConfirmRadiusExclusivity={
            setButtonToConfirmRadiusExclusivity
          }
        />
      )}
      {buttonToConfirmPinsVisible && (
        <div
          style={{
            display: "flex",
            width: "fit-content",
            zIndex: 1000,
            position: "fixed",
            bottom: 25,
            right: "50%",
            transform: "translate(50%, 0)",
          }}
        >
          <ButtonDefault
            fontSecondary
            icon={<HiXMark />}
            title="CANCELAR CRIAÇÃO DE ALFINETES"
            color={"primary"}
            iconPosition="right"
            onClick={() => {
              setButtonToConfirmPinsVisible(false);
              if (shapeActive === "pinsToRadiusExclusivity") {
                setParamsToRadiusExclusivity({
                  ...paramsToRadiusExclusivity,
                  deletePoints: true,
                });
              } else if (shapeActive === "pinsToSurroundings") {
                setParamsToSurroundings({
                  ...paramsToSurroundings,
                  deletePins: true,
                });
              }
              if (pinsOnEdition !== null) {
                setPinsOnEdition("redraw");
                setModalRadiusExclusivity(true);
              }
              setShapeActive("arrow");
            }}
            borderRadius={30}
          />

          <ButtonDefault
            icon={<FaSearchLocation />}
            title="CONFIRMAR SELEÇÃO DE ALFINETES"
            color={"secondary"}
            borderRadius={50}
            iconPosition="right"
            onClick={() => {
              setShapeActive("arrow");
              if (shapeActive === "pinsToRadiusExclusivity") {
                setModalRadiusExclusivity(true);
              } else if (shapeActive === "pinsToSurroundings") {
                setModalBestSorroundingsOpen(true);
              }
              setButtonToConfirmPinsVisible(false);
              if (pinsOnEdition !== null) {
                setPinsOnEdition(null);
                setModalRadiusExclusivity(true);
              }
            }}
          />
        </div>
      )}
      {buttonToConfirmRadiusExclusivity && (
        <div
          style={{
            display: "flex",
            width: "fit-content",
            zIndex: 1000,
            position: "fixed",
            bottom: 25,
            right: "50%",
            transform: "translate(50%, 0)",
          }}
        >
          <ButtonDefault
            fontSecondary
            icon={<HiXMark />}
            title="CANCELAR CRIAÇÃO DE ÁREAS"
            color={"primary"}
            iconPosition="right"
            onClick={() => {
              setButtonToConfirmRadiusExclusivity(false);
              setParamsToRadiusExclusivity({
                ...paramsToRadiusExclusivity,
                deleteRects: true,
              });

              setShapeActive("arrow");
            }}
            borderRadius={30}
          />

          <ButtonDefault
            fontSecondary
            icon={<FaArrowRight />}
            title="CONFIRMAR SELEÇÃO DE ÁREAS"
            color={"secondary"}
            iconPosition="right"
            onClick={() => {
              setButtonToConfirmRadiusExclusivity(false);
              setModalRadiusExclusivity(true);
              setShapeActive("arrow");
            }}
            borderRadius={30}
          />
        </div>
      )}
    </>
  );
}
