import { Divider } from "antd";
import { Bar } from "react-chartjs-2";
import { colors } from "../../../../../assets/colors";
import { GraphTotalData } from "../../Preview/components/graphs/graphTSInterface";
import { format } from "../../../Utils";
import { graphOptions } from "./CorrectGraphic";

interface TypeZeroData {
  nome: string;
  dadosGrafico: GraphTotalData;
  graphOptions: graphOptions;
  sessao: number;
  index: number;
  measureUnit: string;
}

export function GraphTypeZero({
  nome,
  dadosGrafico,
  graphOptions,
  sessao,
  index,
  measureUnit,
}: TypeZeroData) {
  for (let index = 0; index < dadosGrafico.datasets.length; index++) {
    const element = dadosGrafico.datasets[index];
    if (element.backgroundColor.length < 8) {
      dadosGrafico.datasets[index].backgroundColor =
        element.backgroundColor + "66";
    }
  }

  if (dadosGrafico.datasets[0].data.length === 1) {
    return (
      <div key={nome + "_" + sessao + "_simplegraph" + index}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              style={{
                verticalAlign: "center",
                textAlign: "center",
                textAlignLast: "center",
                border: "1px solid",
                borderWidth: 2,
                borderColor: "black",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                width: "100%",
                padding: 15,
              }}
            >
              {nome}
            </p>
            <p
              style={{
                verticalAlign: "center",
                textAlign: "center",
                textAlignLast: "center",
                border: "1px solid",
                borderWidth: 2,
                borderTop: 0,
                borderColor: "black",
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
                width: "100%",
                padding: 15,
                color: colors.textLight,
              }}
            >
              {dadosGrafico.datasets[0].data[0]}
            </p>
          </div>
        </div>
        <Divider />
      </div>
    );
  } else {
    return (
      <div key={nome + "_" + sessao + "_normalgraph" + index}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "70%",
                alignContent: "center",
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Bar options={graphOptions} data={dadosGrafico} />
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
            paddingTop: "10px",
            paddingBottom: "10px",
            margin: "20px",
            marginLeft: "20px",
            marginRight: "20px",

            color: "darkgray",

            borderRadius: "20px",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {dadosGrafico.datasets.map((area, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "100%",
                  paddingTop: "10px",
                  paddingBottom: "10px",

                  border: "1px",
                  color: "darkgray",
                  borderColor: "lightgray",
                  borderStyle: "solid",
                  borderRadius: "20px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color:
                      area.backgroundColor.length === 9
                        ? area.backgroundColor.substring(0, 7)
                        : area.backgroundColor,
                    verticalAlign: "center",
                    textAlign: "center",
                    textAlignLast: "center",
                    width: "90%",
                  }}
                >
                  {" " + nome}
                  {area.data.map((val, i) => {
                    return (
                      <div
                        key={
                          nome +
                          "_" +
                          sessao +
                          "_" +
                          dadosGrafico.labels[i] +
                          index +
                          "__" +
                          i
                        }
                        style={{
                          display: "flex",
                          color: "darkgray",
                          justifyContent: "space-between",
                          width: "90%",
                          border: "0px",
                          paddingTop: "5px",
                          borderTop: "1px",
                          borderStyle: "solid",
                        }}
                      >
                        <span
                          style={{
                            color:
                              area.backgroundColor.length === 9
                                ? area.backgroundColor.substring(0, 7)
                                : area.backgroundColor,
                          }}
                        >
                          {dadosGrafico.labels[i] + ": "}
                        </span>
                        <span style={{ color: "darkgray" }}>
                          {format(val, measureUnit)}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <Divider />
      </div>
    );
  }
}
