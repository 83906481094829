import { colors } from "../../../../../assets/colors";
import fonts from "../../../../../config/fonts";

interface ReportBoxData {
  label: string;
  value: string;
}

export function ReportBox({ label, value }: ReportBoxData) {
  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p
          style={{
            verticalAlign: "center",
            textAlign: "center",
            textAlignLast: "center",
            border: "1px solid",
            borderWidth: 2,
            borderColor: colors.textLight,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            width: "100%",
            padding: 15,
            fontFamily: fonts.exp,
          }}
        >
          {label}
        </p>
        <p
          style={{
            verticalAlign: "center",
            textAlign: "center",
            textAlignLast: "center",
            border: "1px solid",
            borderWidth: 2,
            borderTop: 0,
            borderColor: colors.textLight,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            width: "100%",
            padding: 15,
            color: colors.checked,
            fontFamily: fonts.exp,
          }}
        >
          {value}
        </p>
      </div>
    </div>
  );
}
