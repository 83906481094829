import { useEffect, useState } from "react";
import { Title } from "../../../../components/typography/Title";
import { OptionCompany } from "./../OptionCompany";
import { getCompanyByCnpj } from "../../../../services/getOptionsData";
import { CompanyInfoProps } from "../../Companies";
import { VariableSelectOption } from "../../../../Types/Variable";
import { Select } from "../../../../components/Select/Select";
import { setOptions } from "react-chartjs-2/dist/utils";

interface CompaniesListProps {
  loading: boolean;
  variableCompaniesList: Array<{ label: string; cnpj: string }>;
  setSelectedInfo: React.Dispatch<
    React.SetStateAction<CompanyInfoProps | null>
  >;
  setPageSelected: React.Dispatch<React.SetStateAction<number>>;
}

export function CompaniesList({
  loading,
  setPageSelected,
  variableCompaniesList,
  setSelectedInfo,
}: CompaniesListProps) {
  const [selected, setSelected] = useState<number | null>(null);

  useEffect(() => {
    setSelected(null);
    setSelectedInfo(null);
  }, [variableCompaniesList]);

  const handleSelect = (index: number) => {
    setSelected(index);
  };

  const handleInformation = (cnpj: string) => {
    getCompanyByCnpj({ cnpj }).then((response) => {
      setSelectedInfo(response[0]);
    });
  };

  const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const target = event.target as HTMLUListElement;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      setPageSelected((prevState) => prevState + 1);
    }
  };

  return (
    <div
      id="companies-list"
      style={{
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ul
        id="companies-list-body"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowY: "auto",
          paddingRight: 8,
        }}
        onScroll={handleScroll}
      >
        {variableCompaniesList.map((data, index) => (
          <OptionCompany
            key={index}
            name={
              data.label.indexOf("#") > -1
                ? data.label.substring(0, data.label.indexOf("#"))
                : data.label
            }
            cnae={data.cnpj}
            active={selected === index}
            separator={variableCompaniesList.length - 1 !== index}
            setSelected={() => {
              handleSelect(index);
              handleInformation(data.cnpj);
            }}
          />
        ))}
      </ul>
      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>Carregando...</span>
        </div>
      )}
    </div>
  );
}
