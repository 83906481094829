import React, { useEffect } from "react";
import {
  deleteActiveIcon,
  deleteIcon,
  editActiveIcon,
  editIcon,
} from "./IconsButtons";

import GA4 from "react-ga4";
import {
  CircleProps,
  LineProps,
  PolygonProps,
  RectangleProps,
  Shape,
} from "./types";
import L from "leaflet";
import { EditRectangle } from "./Edition/EditRectangle";
import { EditCircle } from "./Edition/EditCircle";
import { PolygonParamList } from "../../../../Types/interfacs";
import { EditPolygon } from "./Edition/EditPolygon";
import { EditLine } from "./Edition/EditLine";
import { UserToken } from "../../../../services/userToken";
import { salvaLog } from "../../../../services/getOptionsData";

interface ControllerShapesProps {
  map: L.Map;
  layerShapes: L.LayerGroup;
  setSelectedArea: any;
  polygon?: {
    polygonList: never[];
    radiusList: never[];
    rectangleList: never[];
    lineList: never[];
    pins: never[];
    deletfunction: (id: number) => void;
    forceUpdate: () => void;
    deleteAllfunction: () => void;
    updatefunction: () => void;
    centerAreas: () => void;
  };
  setPolygon?: React.Dispatch<React.SetStateAction<PolygonParamList>>;
  shapeSelectedToEdition: {
    shape: Shape;
    optionsButton: {
      editButton: boolean;
      deleteButton: boolean;
    };
  };
  setShapeSelectedToEdition: React.Dispatch<
    React.SetStateAction<{
      id: number;
      shape: Shape;
      optionsButton: {
        editButton: boolean;
        deleteButton: boolean;
      };
    } | null>
  >;
}

export function ControllerShapes({
  map,
  layerShapes,
  setSelectedArea,
  polygon,
  shapeSelectedToEdition,
  setShapeSelectedToEdition,
  setPolygon,
}: ControllerShapesProps) {
  map.on("keypress", (e) => {
    const keyPressed = e.originalEvent.code;
    if (keyPressed === "Enter") {
      setShapeSelectedToEdition(null);
    }
  });

  useEffect(() => {
    let inEdit = false;
    const { shape, optionsButton } = shapeSelectedToEdition;

    const { color } = shape.options;
    let firstLatLng: [number, number];
    let secondLatLng: [number, number];

    const { lat: lat1, lng: lng1 } = shape.getBounds().getSouthWest();
    const { lat: lat2, lng: lng2 } = shape.getBounds().getNorthEast();

    firstLatLng = [lat1 - 0.0005, lng1 - 0.0005];
    secondLatLng = [lat2 + 0.0005, lng2 + 0.0005];

    const outlineRetangle = L.rectangle([firstLatLng, secondLatLng], {
      color: color,
      weight: 4,
      fillOpacity: 0,
      dashArray: "10, 10",
    });

    outlineRetangle.on("mousedown", () => {
      setShapeSelectedToEdition(null);
    });

    function removeEdit() {
      layerShapes.removeLayer(outlineRetangle);
      layerShapes.removeLayer(editMarker);
      layerShapes.removeLayer(deleteMarker);
      map.off("keypress");

      map.getContainer().style.cursor = "grab";
    }

    outlineRetangle.addTo(layerShapes);

    const northEast = outlineRetangle.getBounds().getNorthEast();

    const editMarker = L.marker(northEast, {
      icon: editIcon,
    });
    const deleteMarker = L.marker(northEast, {
      icon: deleteIcon,
    });

    if (optionsButton.editButton) {
      editMarker.setOpacity(0.7);

      editMarker.on("mouseover", () => {
        map.dragging.disable();
        editMarker.setOpacity(1);
      });

      editMarker.on("mouseout", () => {
        map.dragging.enable();
        editMarker.setOpacity(0.7);
      });

      editMarker.on("mousedown", () => {
        editMarker.setIcon(editActiveIcon);
      });

      editMarker.on("mouseup", () => {
        if (inEdit) {
          editMarker.setIcon(editIcon);
        } else {
          editMarker.setIcon(editActiveIcon);
        }
        inEdit = !inEdit;
      });

      editMarker.on("click", () => {
        if (inEdit) {
          if (shape instanceof L.Rectangle) {
            EditRectangle({
              map,
              layerShapes,
              outlineRetangle,
              rectangle: shape as RectangleProps,
              editMarker,
              deleteMarker,
              setPolygon,
            });
          } else if (shape instanceof L.Polygon) {
            EditPolygon({
              map,
              layerShapes,
              outlineRetangle,
              polygon: shape as PolygonProps,
              editMarker,
              deleteMarker,
              setPolygon,
            });
          } else if (shape instanceof L.Circle) {
            EditCircle({
              map,
              layerShapes,
              outlineRetangle,
              circle: shape as CircleProps,
              editMarker,
              deleteMarker,
              setPolygon,
            });
          } else if (shape instanceof L.Polyline) {
            EditLine({
              map,
              layerShapes,
              outlineRetangle,
              line: shape as LineProps,
              editMarker,
              deleteMarker,
            });
          }
        } else {
          map.off("mousemove");
          map.off("mouseup");
        }
      });

      editMarker.addTo(layerShapes);
    }

    if (optionsButton.deleteButton) {
      deleteMarker.setOpacity(0.7);

      deleteMarker.on("mouseover", () => {
        map.dragging.disable();
        deleteMarker.setOpacity(1);
      });

      deleteMarker.on("mouseout", () => {
        map.dragging.enable();
        deleteMarker.setOpacity(0.7);
      });

      deleteMarker.addTo(layerShapes);

      deleteMarker.on("mousedown", () => {
        deleteMarker.setIcon(deleteActiveIcon);
      });

      deleteMarker.on("mouseup", () => {
        deleteMarker.setIcon(deleteIcon);
      });

      deleteMarker.on("click", () => {
        map.dragging.enable();
        if (setPolygon) {
          setSelectedArea((prev: any) => {
            for (let index = 0; index < prev.length; index++) {
              const element = prev[index];

              if (element.id === shape._leaflet_id) {
                prev.splice(index, 1);

                if (prev.length > 0) {
                  return [...prev];
                } else {
                  polygon?.forceUpdate();
                  return [];
                }
              }
            }
            if (prev.length > 0) {
              return [...prev];
            } else {
              polygon?.forceUpdate();
              return [];
            }
          });

          polygon?.deletfunction(shape._leaflet_id);
          if (shape instanceof L.Rectangle) {
            setPolygon((prev: PolygonParamList) => {
              const rectangleList = prev.rectangleList.filter(
                (rectangleOption) => rectangleOption.id !== shape._leaflet_id
              );
              const updatedPrev = {
                ...prev,
                rectangleList,
              };
              return updatedPrev;
            });
          } else if (shape instanceof L.Polygon) {
            setPolygon((prev: PolygonParamList) => {
              const polygonList = prev.polygonList.filter(
                (polygonOption) => polygonOption.id !== shape._leaflet_id
              );
              const updatedPrev = {
                ...prev,
                polygonList,
              };
              return updatedPrev;
            });
          } else if (shape instanceof L.Polyline) {
            setPolygon((prev: PolygonParamList) => {
              const lineList = prev.lineList.filter(
                (lineOption) => lineOption.id !== shape._leaflet_id
              );
              const updatedPrev = {
                ...prev,
                lineList,
              };
              return updatedPrev;
            });
          } else if (shape instanceof L.Circle) {
            setPolygon((prev: PolygonParamList) => {
              const radiusList = prev.radiusList.filter(
                (radiusOption) => radiusOption.id !== shape._leaflet_id
              );
              const updatedPrev = {
                ...prev,
                radiusList,
              };
              return updatedPrev;
            });
          }
        }
      });
    }

    function refreshShape() {
      if (shape instanceof L.Rectangle) {
        const { lat: lat1, lng: lng1 } = shape.getBounds().getSouthWest();
        const { lat: lat2, lng: lng2 } = shape.getBounds().getNorthEast();

        firstLatLng = [lat1, lng1];
        secondLatLng = [lat2, lng2];

        salvaLog({
          action: "Editou Retângulo",
          params: "",
        }).then((result: any) => {});

        setPolygon &&
          setPolygon((prev: PolygonParamList) => {
            const rectangleList = prev.rectangleList.map((rectangleOption) => {
              if (rectangleOption.id === shape._leaflet_id) {
                return {
                  ...rectangleOption,
                  points: [firstLatLng, secondLatLng],
                };
              } else {
                return rectangleOption; // Não altere os elementos que não correspondem ao retângulo atual
              }
            });

            // Certifique-se de manter as outras propriedades de prev intactas
            const updatedPrev = {
              ...prev,
              rectangleList,
            };

            return updatedPrev;
          });
      } else if (shape instanceof L.Polygon) {
        const points = shape.getLatLngs()[0];
        let polygonPoints: L.LatLngTuple[] = [];

        Object.values(points).forEach((point) => {
          polygonPoints.push([point.lat, point.lng]);
        });

        salvaLog({
          action: "Editou Polygon",
          params: "",
        }).then((result: any) => {});

        setPolygon &&
          setPolygon((prev: PolygonParamList) => {
            const polygonList = prev.polygonList.map((polygonOption) => {
              if (polygonOption.id === shape._leaflet_id) {
                return {
                  ...polygonOption,
                  points: polygonPoints,
                };
              } else {
                return polygonOption; // Não altere os elementos que não correspondem ao polígono atual
              }
            });

            // Certifique-se de manter as outras propriedades de prev intactas
            const updatedPrev = {
              ...prev,
              polygonList,
            };

            return updatedPrev;
          });
      } else if (shape instanceof L.Circle) {
        const { lat, lng } = shape.getLatLng();
        const centerLatLng = [lat, lng] as L.LatLngTuple;
        const radius = shape.getRadius();

        salvaLog({
          action: "Editou Círculo",
          params: "",
        }).then((result: any) => {});

        setPolygon &&
          setPolygon((prev: PolygonParamList) => {
            const radiusList = prev.radiusList.map((radiusOption) => {
              if (radiusOption.id === shape._leaflet_id) {
                return {
                  ...radiusOption,
                  points: [centerLatLng],
                  ray: radius / 1000,
                };
              } else {
                return radiusOption; // Não altere os elementos que não correspondem ao círculo atual
              }
            });

            // Certifique-se de manter as outras propriedades de prev intactas
            const updatedPrev = {
              ...prev,
              radiusList,
            };

            return updatedPrev;
          });
      } else if (shape instanceof L.Polyline) {
        const points = shape.getLatLngs();
        let linePoints: L.LatLngTuple[] = [];

        Object.values(points).forEach((point) => {
          linePoints.push([point.lat, point.lng]);
        });

        salvaLog({
          action: "Editou Linha",
          params: "",
        }).then((result: any) => {});

        setPolygon &&
          setPolygon((prev: PolygonParamList) => {
            const lineList = prev.lineList.map((lineOption) => {
              if (lineOption.id === shape._leaflet_id) {
                return {
                  ...lineOption,
                  points: linePoints,
                };
              } else {
                return lineOption; // Não altere os elementos que não correspondem ao polígono atual
              }
            });

            // Certifique-se de manter as outras propriedades de prev intactas
            const updatedPrev = {
              ...prev,
              lineList,
            };

            return updatedPrev;
          });
      }
    }

    return () => {
      removeEdit();
      refreshShape();
    };
  }, [shapeSelectedToEdition]);

  return null;
}
