import React from "react";
import { Title } from "../../../../../components/typography/Title";
import { SubTitle } from "../../../../../components/typography/SubTitle";
import { colors } from "../../../../../assets/colors";
import fonts from "../../../../../config/fonts";

interface InformationPointProps {
  title: string;
  data: string;
}

export function InformationPoint({ title, data }: InformationPointProps) {
  return (
    <div
      style={{
        padding: "12px 24px 12px 0",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Title
        style={{
          fontFamily: fonts.expBold,
          paddingRight: 8,
        }}
      >
        {title}
      </Title>
      <SubTitle
        style={{
          paddingBlock: 0,
        }}
        color={colors.subTextInfo}
      >
        {data}
      </SubTitle>
    </div>
  );
}
