import { PiCircleDuotone, PiSquareDuotone } from "react-icons/pi";
import { colors } from "../../../../../assets/colors";
import { PentagonIcon } from "../../../../../components/icon/PentagonIcon";
import { Icon } from "../../../../../components/icon/Icon";
import { SubTitle } from "../../../../../components/typography/SubTitle";
import { Fragment } from "react";

interface AreaInformationsProps {
  studyType: string;
  color: string;
  name: string;
  informations: string[];
  ikey: any;
}

export function AreaInformations({
  studyType,
  color,
  name,
  informations,
  ikey,
}: AreaInformationsProps) {
  const contentColor: string = colors.default.disabled.content;
  const backgroundColor: string = colors.default.disabled.background;
  const icon =
    studyType !== "RADIUS_STUDY" ? (
      studyType === "RECTANGLE_STUDY" ? (
        <PiSquareDuotone size={20} />
      ) : (
        <PentagonIcon size={20} />
      )
    ) : (
      <PiCircleDuotone size={20} />
    );
  return (
    <div
      key={ikey}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: backgroundColor,
        border: `1px solid ${contentColor}`,
        borderRadius: 40,
        height: 48,
        paddingRight: 10,
        paddingLeft: 10,
        margin: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          height: "100%",
        }}
      >
        <Icon icon={icon} color={color} />
        <SubTitle
          style={{
            fontSize: 16,
            marginLeft: 5,
            marginRight: 5,
          }}
          color={color}
        >
          {name}
        </SubTitle>
        {informations.map((item, index) => {
          return (
            <Fragment key={index}>
              <div
                style={{
                  width: 1,
                  borderRadius: 1,
                  margin: 3,
                  height: "60%",
                  backgroundColor: contentColor,
                }}
              />
              <SubTitle
                style={{
                  flexWrap: "nowrap",
                  fontSize: 14,
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                {item}
              </SubTitle>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}
