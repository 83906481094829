export const capitais = [
  "Não informado",
  "1 - 60k",
  "60k - 360k",
  "360k - 1M",
  "1M - 5M",
  "5M - 10M",
  "10M - 30M",
  "30M - 100M",
  "100M - 300M",
  "300M - 500M",
  "500M - 1B",
  "1B >",
];

export function translateCSArray(rawCSArray:[]) {
  const translated = [];
  for (let index = 0; index < rawCSArray.length; index++) {
    const element = rawCSArray[index];
    for (let indexCS = 0; indexCS < capitais.length; indexCS++) {
      if (capitais[indexCS] === element) {
        translated.push(indexCS);
      }
    }
  }
  return translated;
}
