import L, { LatLngTuple } from "leaflet";
import { PolygonParamList } from "../../../../../Types/interfacs";
import { LineProps } from "../types";
import { getDistanceBetweenPoints } from "../getDistanceBetweenPoints";
import { deselectText } from "../../../Utils";

interface EditLineProps {
  map: L.Map;
  layerShapes: L.LayerGroup;
  outlineRetangle?: L.Rectangle;
  line: LineProps;
  editMarker?: L.Marker;
  deleteMarker?: L.Marker;
}

export function EditLine({
  map,
  layerShapes,
  line,
  editMarker,
  deleteMarker,
  outlineRetangle,
}: EditLineProps) {
  let toolTip: L.Tooltip;

  const points = line.getLatLngs() as L.LatLng[];

  const icon = L.icon({
    iconUrl: "edit-Icon.png",
    iconSize: [20, 20],
  });

  let firstLatLng = [points[0].lat, points[0].lng] as L.LatLngTuple;
  let secondLatLng = [points[1].lat, points[1].lng] as L.LatLngTuple;

  const firstMarker = L.marker(firstLatLng, {
    icon,
  });

  const secondMarker = L.marker(secondLatLng, {
    icon,
  });

  firstMarker.setZIndexOffset(1000);
  secondMarker.setZIndexOffset(1000);

  function onMouseOver() {
    map.on("mouseout", (e: L.LeafletMouseEvent) => {
      document.addEventListener("mouseup", handleClickUp);
      const secondLatLng = [e.latlng.lat, e.latlng.lng] as L.LatLngTuple;

      const zoom = map.getZoom();
      if (line) {
        const center = line.getBounds().getCenter();

        const middlePoint = [
          (center.lat + secondLatLng[0]) / 2,
          (center.lng + secondLatLng[1]) / 2,
        ] as LatLngTuple;

        map.setView(middlePoint, zoom);
      }
    });
  }

  function addGuides() {
    firstMarker.addTo(layerShapes);
    secondMarker.addTo(layerShapes);

    map.on("mouseup", handleClickUp);
  }

  firstMarker.on("mousedown", handleCliclOnFirstMarker);
  secondMarker.on("mousedown", handleCliclOnSecondMarker);

  addGuides();

  function removeGuides() {
    layerShapes.removeLayer(firstMarker);
    layerShapes.removeLayer(secondMarker);
  }

  outlineRetangle?.on("remove", () => {
    map.off("mouseup", handleClickUp);
    removeGuides();
  });

  editMarker?.on("click", () => {
    removeGuides();
  });

  deleteMarker?.on("click", () => {
    removeGuides();
  });

  function removeOnMoveOrResize() {
    editMarker?.remove();
    deleteMarker?.remove();
    outlineRetangle?.remove();

    addGuides();
  }

  function addOnMoveOrResizeFinish() {
    const southWest = line.getBounds().getSouthWest();
    const northEast = line.getBounds().getNorthEast();

    const firstLatLng = [southWest.lat - 0.0005, southWest.lng - 0.0005] as [
      number,
      number
    ];
    const secondLatLng = [northEast.lat + 0.0005, northEast.lng + 0.0005] as [
      number,
      number
    ];

    outlineRetangle?.setBounds([firstLatLng, secondLatLng]);

    const northEastOutiline = outlineRetangle?.getBounds().getNorthEast();

    editMarker?.setLatLng(northEastOutiline ?? northEast);
    deleteMarker?.setLatLng(northEastOutiline ?? northEast);
    editMarker?.addTo(layerShapes);
    deleteMarker?.addTo(layerShapes);
    outlineRetangle?.addTo(layerShapes);
  }

  function handleCliclOnFirstMarker(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      onMouseOver();
      map.dragging.disable();
      map.on("mousemove", handleMouseMoveFirstMarker);
      map.on("mouseup", handleClickUp);
      if (editMarker || deleteMarker) {
        removeOnMoveOrResize();
      }
    }
  }

  function handleCliclOnSecondMarker(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      onMouseOver();
      map.dragging.disable();
      map.on("mousemove", handleMouseMoveSecondMarker);
      map.on("mouseup", handleClickUp);
      if (editMarker || deleteMarker) {
        removeOnMoveOrResize();
      }
    }
  }

  function handleMouseMoveFirstMarker(e: L.LeafletMouseEvent) {
    firstLatLng = [e.latlng.lat, e.latlng.lng];
    const distance = getDistanceBetweenPoints(firstLatLng, secondLatLng);

    if (toolTip) {
      toolTip.remove();
    }

    const distanceKmRounded = Math.round((distance / 1000) * 100) / 100;

    toolTip = L.tooltip()
      .setLatLng(firstLatLng)
      .setContent(
        `<div
        style="
        background-color: white;
          padding: 5px;
          font-size: 14px;
          font-weight: bold;
        "
        >${distanceKmRounded} km</div>`
      )
      .addTo(map);

    firstMarker.setLatLng(firstLatLng);
    line.setLatLngs([firstLatLng, secondLatLng]);
  }

  function handleMouseMoveSecondMarker(e: L.LeafletMouseEvent) {
    secondLatLng = [e.latlng.lat, e.latlng.lng];
    const distance = getDistanceBetweenPoints(firstLatLng, secondLatLng);

    if (toolTip) {
      toolTip.remove();
    }
    const distanceKmRounded = Math.round((distance / 1000) * 100) / 100;

    toolTip = L.tooltip()
      .setLatLng(secondLatLng)
      .setContent(
        `<div
        style="
        background-color: white;
          padding: 5px;
          font-size: 14px;
          font-weight: bold;
        "
        >${distanceKmRounded} km</div>`
      )
      .addTo(map);
    secondMarker.setLatLng(secondLatLng);
    line.setLatLngs([firstLatLng, secondLatLng]);
  }

  function handleClickUp() {
    map.off("mousedown");
    map.off("mousemove");
    map.off("mouseup");
    map.off("mouseout");
    document.removeEventListener("mouseup", handleClickUp);

    deselectText();

    if (toolTip) {
      toolTip.remove();
    }

    map.dragging.enable();

    addOnMoveOrResizeFinish();
  }
}
