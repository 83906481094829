interface CityInfo {
  city: string;
  state: string;
  code: string;
  codeIbge: string;
}

function extractCityInfo(line: string): CityInfo | null {
  const parts = line.split(";");
  if (parts.length === 3) {
    const stateCity = parts[0].trim();
    const city = stateCity.substring(3);
    const state = stateCity.substring(0, 2);
    const code = parts[1].trim();
    const codeIbge = parts[2].trim();
    return {
      city,
      state,
      code,
      codeIbge,
    };
  } else {
    return null; // Retorna null se a linha não estiver no formato esperado
  }
}

export const citynumbers = [
  { line: "GO ABADIA DE GOIAS;1050;5200050" },
  { line: "GO ABADIANIA;9201;5200100" },
  { line: "TO ABREULANDIA;337;1700251" },
  { line: "MT ACORIZAL;9001;5100102" },
  { line: "GO ACREUNA;9645;5200134" },
  { line: "GO ADELANDIA;9769;5200159" },
  { line: "MT AGUA BOA;9191;5100201" },
  { line: "MS AGUA CLARA;9003;5000203" },
  { line: "GO AGUA FRIA DE GOIAS;9771;5200175" },
  { line: "GO AGUA LIMPA;9203;5200209" },
  { line: "GO AGUAS LINDAS DE GOIAS;1052;5200258" },
  { line: "TO AGUIARNOPOLIS;72;1700301" },
  { line: "MS ALCINOPOLIS;141;5000252" },
  { line: "GO ALEXANIA;9205;5200308" },
  { line: "TO ALIANCA DO TOCANTINS;9441;1700350" },
  { line: "TO ALMAS;9207;1700400" },
  { line: "GO ALOANDIA;9209;5200506" },
  { line: "MT ALTA FLORESTA;8987;5100250" },
  { line: "MT ALTO ARAGUAIA;9005;5100300" },
  { line: "MT ALTO BOA VISTA;127;5100359" },
  { line: "MT ALTO GARCAS;9007;5100409" },
  { line: "GO ALTO HORIZONTE;85;5200555" },
  { line: "MT ALTO PARAGUAI;9009;5100508" },
  { line: "GO ALTO PARAISO DE GOIAS;9211;5200605" },
  { line: "MT ALTO TAQUARI;9911;5100607" },
  { line: "TO ALVORADA;9213;1700707" },
  { line: "GO ALVORADA DO NORTE;9215;5200803" },
  { line: "MS AMAMBAI;9011;5000609" },
  { line: "GO AMARALINA;1054;5200829" },
  { line: "GO AMERICANO DO BRASIL;9661;5200852" },
  { line: "GO AMORINOPOLIS;9217;5200902" },
  { line: "TO ANANAS;9219;1701002" },
  { line: "GO ANAPOLIS;9221;5201108" },
  { line: "MS ANASTACIO;9013;5000708" },
  { line: "MS ANAURILANDIA;9015;5000807" },
  { line: "MS ANGELICA;9169;5000856" },
  { line: "TO ANGICO;165;1701051" },
  { line: "GO ANHANGUERA;9223;5201207" },
  { line: "GO ANICUNS;9225;5201306" },
  { line: "MS ANTONIO JOAO;9017;5000906" },
  { line: "GO APARECIDA DE GOIANIA;9227;5201405" },
  { line: "GO APARECIDA DO RIO DOCE;71;5201454" },
  { line: "TO APARECIDA DO RIO NEGRO;9713;1701101" },
  { line: "MS APARECIDA DO TABOADO;9019;5001003" },
  { line: "MT APIACAS;9773;5100805" },
  { line: "GO APORE;9229;5201504" },
  { line: "MS AQUIDAUANA;9021;5001102" },
  { line: "GO ARACU;9231;5201603" },
  { line: "GO ARAGARCAS;9233;5201702" },
  { line: "GO ARAGOIANIA;9235;5201801" },
  { line: "TO ARAGOMINAS;167;1701309" },
  { line: "TO ARAGUACEMA;9237;1701903" },
  { line: "TO ARAGUACU;9239;1702000" },
  { line: "MT ARAGUAIANA;9869;5101001" },
  { line: "TO ARAGUAINA;9241;1702109" },
  { line: "MT ARAGUAINHA;9023;5101209" },
  { line: "TO ARAGUANA;169;1702158" },
  { line: "GO ARAGUAPAZ;9669;5202155" },
  { line: "TO ARAGUATINS;9243;1702208" },
  { line: "MS ARAL MOREIRA;9171;5001243" },
  { line: "TO ARAPOEMA;9245;1702307" },
  { line: "MT ARAPUTANGA;8989;5101258" },
  { line: "MT ARENAPOLIS;9025;5101308" },
  { line: "GO ARENOPOLIS;9671;5202353" },
  { line: "MT ARIPUANA;9027;5101407" },
  { line: "TO ARRAIAS;9247;1702406" },
  { line: "GO ARUANA;9249;5202502" },
  { line: "TO AUGUSTINOPOLIS;9685;1702554" },
  { line: "GO AURILANDIA;9251;5202601" },
  { line: "TO AURORA DO TOCANTINS;9253;1702703" },
  { line: "GO AVELINOPOLIS;9255;5202809" },
  { line: "TO AXIXA DO TOCANTINS;9257;1702901" },
  { line: "TO BABACULANDIA;9259;1703008" },
  { line: "GO BALIZA;9261;5203104" },
  { line: "MS BANDEIRANTES;9029;5001508" },
  { line: "TO BANDEIRANTES DO TOCANTINS;74;1703057" },
  { line: "MT BARAO DE MELGACO;9031;5101605" },
  { line: "MT BARRA DO BUGRES;9033;5101704" },
  { line: "MT BARRA DO GARCAS;9035;5101803" },
  { line: "TO BARRA DO OURO;76;1703073" },
  { line: "GO BARRO ALTO;9263;5203203" },
  { line: "TO BARROLANDIA;9693;1703107" },
  { line: "MS BATAGUASSU;9037;5001904" },
  { line: "MS BATAYPORA;9039;5002001" },
  { line: "MS BELA VISTA;9041;5002100" },
  { line: "GO BELA VISTA DE GOIAS;9265;5203302" },
  { line: "TO BERNARDO SAYAO;9695;1703206" },
  { line: "MS BODOQUENA;9801;5002159" },
  { line: "GO BOM JARDIM DE GOIAS;9267;5203401" },
  { line: "GO BOM JESUS DE GOIAS;9269;5203500" },
  { line: "MT BOM JESUS DO ARAGUAIA;1078;5101852" },
  { line: "TO BOM JESUS DO TOCANTINS;341;1703305" },
  { line: "GO BONFINOPOLIS;9775;5203559" },
  { line: "MS BONITO;9043;5002209" },
  { line: "GO BONOPOLIS;1056;5203575" },
  { line: "MS BRASILANDIA;9045;5002308" },
  { line: "TO BRASILANDIA DO TOCANTINS;339;1703602" },
  { line: "DF BRASILIA;9701;5300108" },
  { line: "MT BRASNORTE;9873;5101902" },
  { line: "GO BRAZABRANTES;9271;5203609" },
  { line: "TO BREJINHO DE NAZARE;9273;1703701" },
  { line: "GO BRITANIA;9275;5203807" },
  { line: "GO BURITI ALEGRE;9277;5203906" },
  { line: "GO BURITI DE GOIAS;63;5203939" },
  { line: "TO BURITI DO TOCANTINS;9715;1703800" },
  { line: "GO BURITINOPOLIS;61;5203962" },
  { line: "MS CAARAPO;9055;5002407" },
  { line: "GO CABECEIRAS;9279;5204003" },
  { line: "MT CACERES;9047;5102504" },
  { line: "GO CACHOEIRA ALTA;9281;5204102" },
  { line: "GO CACHOEIRA DE GOIAS;9283;5204201" },
  { line: "GO CACHOEIRA DOURADA;9673;5204250" },
  { line: "TO CACHOEIRINHA;171;1703826" },
  { line: "GO CACU;9285;5204300" },
  { line: "GO CAIAPONIA;9287;5204409" },
  { line: "GO CALDAS NOVAS;9289;5204508" },
  { line: "GO CALDAZINHA;31;5204557" },
  { line: "MS CAMAPUA;9049;5002605" },
  { line: "GO CAMPESTRE DE GOIAS;9291;5204607" },
  { line: "GO CAMPINACU;9687;5204656" },
  { line: "MT CAMPINAPOLIS;9863;5102603" },
  { line: "GO CAMPINORTE;9293;5204706" },
  { line: "GO CAMPO ALEGRE DE GOIAS;9295;5204805" },
  { line: "MS CAMPO GRANDE;9051;5002704" },
  { line: "GO CAMPO LIMPO DE GOIAS;1070;5204854" },
  { line: "MT CAMPO NOVO DO PARECIS;9777;5102637" },
  { line: "MT CAMPO VERDE;9779;5102678" },
  { line: "GO CAMPOS BELOS;9297;5204904" },
  { line: "MT CAMPOS DE JULIO;1032;5102686" },
  { line: "TO CAMPOS LINDOS;173;1703842" },
  { line: "GO CAMPOS VERDES;9781;5204953" },
  { line: "MT CANABRAVA DO NORTE;129;5102694" },
  { line: "MT CANARANA;9193;5102702" },
  { line: "MS CARACOL;9053;5002803" },
  { line: "TO CARIRI DO TOCANTINS;327;1703867" },
  { line: "MT CARLINDA;1034;5102793" },
  { line: "GO CARMO DO RIO VERDE;9299;5205000" },
  { line: "TO CARMOLANDIA;175;1703883" },
  { line: "TO CARRASCO BONITO;177;1703891" },
  { line: "TO CASEARA;9717;1703909" },
  { line: "MS CASSILANDIA;9057;5002902" },
  { line: "MT CASTANHEIRA;9783;5102850" },
  { line: "GO CASTELANDIA;81;5205059" },
  { line: "GO CATALAO;9301;5205109" },
  { line: "GO CATURAI;9303;5205208" },
  { line: "GO CAVALCANTE;9305;5205307" },
  { line: "TO CENTENARIO;343;1704105" },
  { line: "GO CERES;9307;5205406" },
  { line: "GO CEZARINA;9785;5205455" },
  { line: "TO CHAPADA DA NATIVIDADE;80;1705102" },
  { line: "TO CHAPADA DE AREIA;78;1704600" },
  { line: "MT CHAPADA DOS GUIMARAES;9059;5103007" },
  { line: "GO CHAPADAO DO CEU;73;5205471" },
  { line: "MS CHAPADAO DO SUL;9787;5002951" },
  { line: "GO CIDADE OCIDENTAL;77;5205497" },
  { line: "MT CLAUDIA;9789;5103056" },
  { line: "MT COCALINHO;9865;5103106" },
  { line: "GO COCALZINHO DE GOIAS;55;5205513" },
  { line: "MT COLIDER;8979;5103205" },
  { line: "GO COLINAS DO SUL;9791;5205521" },
  { line: "TO COLINAS DO TOCANTINS;9311;1705508" },
  { line: "TO COLMEIA;9529;1716703" },
  { line: "MT COLNIZA;1080;5103254" },
  { line: "TO COMBINADO;9697;1705557" },
  { line: "MT COMODORO;9883;5103304" },
  { line: "TO CONCEICAO DO TOCANTINS;9313;1705607" },
  { line: "MT CONFRESA;131;5103353" },
  { line: "MT CONQUISTA D'OESTE;1082;5103361" },
  { line: "MS CORGUINHO;9061;5003108" },
  { line: "MS CORONEL SAPUCAIA;9997;5003157" },
  { line: "GO CORREGO DO OURO;9315;5205703" },
  { line: "MS CORUMBA;9063;5003207" },
  { line: "GO CORUMBA DE GOIAS;9317;5205802" },
  { line: "GO CORUMBAIBA;9319;5205901" },
  { line: "MS COSTA RICA;9803;5003256" },
  { line: "MT COTRIGUACU;89;5103379" },
  { line: "TO COUTO DE MAGALHAES;9321;1706001" },
  { line: "MS COXIM;9065;5003306" },
  { line: "TO CRISTALANDIA;9323;1706100" },
  { line: "GO CRISTALINA;9325;5206206" },
  { line: "GO CRISTIANOPOLIS;9327;5206305" },
  { line: "GO CRIXAS;9329;5206404" },
  { line: "TO CRIXAS DO TOCANTINS;82;1706258" },
  { line: "GO CROMINIA;9331;5206503" },
  { line: "MT CUIABA;9067;5103403" },
  { line: "GO CUMARI;9333;5206602" },
  { line: "MT CURVELANDIA;1084;5103437" },
  { line: "GO DAMIANOPOLIS;9335;5206701" },
  { line: "GO DAMOLANDIA;9337;5206800" },
  { line: "TO DARCINOPOLIS;179;1706506" },
  { line: "GO DAVINOPOLIS;9339;5206909" },
  { line: "MT DENISE;9833;5103452" },
  { line: "MS DEODAPOLIS;9175;5003454" },
  { line: "MT DIAMANTINO;9069;5103502" },
  { line: "TO DIANOPOLIS;9341;1707009" },
  { line: "GO DIORAMA;9343;5207105" },
  { line: "GO DIVINOPOLIS DE GOIAS;9309;5208301" },
  { line: "TO DIVINOPOLIS DO TOCANTINS;9719;1707108" },
  { line: "MS DOIS IRMAOS DO BURITI;9793;5003488" },
  { line: "TO DOIS IRMAOS DO TOCANTINS;9345;1707207" },
  { line: "MT DOM AQUINO;9071;5103601" },
  { line: "MS DOURADINA;9805;5003504" },
  { line: "MS DOURADOS;9073;5003702" },
  { line: "GO DOVERLANDIA;9675;5207253" },
  { line: "TO DUERE;9347;1707306" },
  { line: "GO EDEALINA;9795;5207352" },
  { line: "GO EDEIA;9349;5207402" },
  { line: "MS ELDORADO;9173;5003751" },
  { line: "TO ESPERANTINA;181;1707405" },
  { line: "GO ESTRELA DO NORTE;9351;5207501" },
  { line: "GO FAINA;9797;5207535" },
  { line: "TO FATIMA;9683;1707553" },
  { line: "MS FATIMA DO SUL;9075;5003801" },
  { line: "GO FAZENDA NOVA;9353;5207600" },
  { line: "MT FELIZ NATAL;1036;5103700" },
  { line: "MS FIGUEIRAO;1178;5003900" },
  { line: "TO FIGUEIROPOLIS;9667;1707652" },
  { line: "MT FIGUEIROPOLIS D'OESTE;9881;5103809" },
  { line: "TO FILADELFIA;9355;1707702" },
  { line: "GO FIRMINOPOLIS;9357;5207808" },
  { line: "GO FLORES DE GOIAS;9359;5207907" },
  { line: "GO FORMOSA;9361;5208004" },
  { line: "GO FORMOSO;9363;5208103" },
  { line: "TO FORMOSO DO ARAGUAIA;9365;1708205" },
  { line: "TO FORTALEZA DO TABOCAO;345;1708254" },
  { line: "GO GAMELEIRA DE GOIAS;1072;5208152" },
  { line: "MT GAUCHA DO NORTE;1038;5103858" },
  { line: "MT GENERAL CARNEIRO;9077;5103908" },
  { line: "MS GLORIA DE DOURADOS;9079;5004007" },
  { line: "MT GLORIA D'OESTE;135;5103957" },
  { line: "GO GOIANAPOLIS;9367;5208400" },
  { line: "GO GOIANDIRA;9369;5208509" },
  { line: "GO GOIANESIA;9371;5208608" },
  { line: "GO GOIANIA;9373;5208707" },
  { line: "GO GOIANIRA;9375;5208806" },
  { line: "TO GOIANORTE;9699;1708304" },
  { line: "GO GOIAS;9377;5208905" },
  { line: "TO GOIATINS;9533;1709005" },
  { line: "GO GOIATUBA;9379;5209101" },
  { line: "GO GOUVELANDIA;9799;5209150" },
  { line: "GO GUAPO;9381;5209200" },
  { line: "TO GUARAI;9627;1709302" },
  { line: "GO GUARAITA;65;5209291" },
  { line: "GO GUARANI DE GOIAS;9383;5209408" },
  { line: "MT GUARANTA DO NORTE;9887;5104104" },
  { line: "GO GUARINOS;9993;5209457" },
  { line: "MS GUIA LOPES DA LAGUNA;9081;5004106" },
  { line: "MT GUIRATINGA;9083;5104203" },
  { line: "TO GURUPI;9385;1709500" },
  { line: "GO HEITORAI;9387;5209606" },
  { line: "GO HIDROLANDIA;9389;5209705" },
  { line: "GO HIDROLINA;9391;5209804" },
  { line: "GO IACIARA;9393;5209903" },
  { line: "MS IGUATEMI;9085;5004304" },
  { line: "GO INACIOLANDIA;69;5209937" },
  { line: "GO INDIARA;9681;5209952" },
  { line: "MT INDIAVAI;9877;5104500" },
  { line: "GO INHUMAS;9395;5210000" },
  { line: "MS INOCENCIA;9087;5004403" },
  { line: "GO IPAMERI;9397;5210109" },
  { line: "GO IPIRANGA DE GOIAS;1074;5210158" },
  { line: "MT IPIRANGA DO NORTE;1184;5104526" },
  { line: "GO IPORA;9399;5210208" },
  { line: "TO IPUEIRAS;84;1709807" },
  { line: "GO ISRAELANDIA;9401;5210307" },
  { line: "GO ITABERAI;9403;5210406" },
  { line: "TO ITACAJA;9405;1710508" },
  { line: "GO ITAGUARI;9919;5210562" },
  { line: "GO ITAGUARU;9407;5210604" },
  { line: "TO ITAGUATINS;9409;1710706" },
  { line: "GO ITAJA;9411;5210802" },
  { line: "MT ITANHANGA;1186;5104542" },
  { line: "GO ITAPACI;9413;5210901" },
  { line: "GO ITAPIRAPUA;9415;5211008" },
  { line: "TO ITAPIRATINS;347;1710904" },
  { line: "MS ITAPORA;9089;5004502" },
  { line: "TO ITAPORA DO TOCANTINS;9417;1711100" },
  { line: "GO ITAPURANGA;9419;5211206" },
  { line: "MS ITAQUIRAI;9807;5004601" },
  { line: "GO ITARUMA;9421;5211305" },
  { line: "MT ITAUBA;9901;5104559" },
  { line: "GO ITAUCU;9423;5211404" },
  { line: "MT ITIQUIRA;9091;5104609" },
  { line: "GO ITUMBIARA;9425;5211503" },
  { line: "MS IVINHEMA;9093;5004700" },
  { line: "GO IVOLANDIA;9427;5211602" },
  { line: "MT JACIARA;9095;5104807" },
  { line: "GO JANDAIA;9429;5211701" },
  { line: "MT JANGADA;9861;5104906" },
  { line: "MS JAPORA;161;5004809" },
  { line: "GO JARAGUA;9431;5211800" },
  { line: "MS JARAGUARI;9097;5004908" },
  { line: "MS JARDIM;9099;5005004" },
  { line: "GO JATAI;9433;5211909" },
  { line: "MS JATEI;9101;5005103" },
  { line: "TO JAU DO TOCANTINS;329;1711506" },
  { line: "GO JAUPACI;9435;5212006" },
  { line: "MT JAURU;8991;5105002" },
  { line: "GO JESUPOLIS;49;5212055" },
  { line: "GO JOVIANIA;9437;5212105" },
  { line: "MT JUARA;9819;5105101" },
  { line: "TO JUARINA;349;1711803" },
  { line: "MT JUINA;9831;5105150" },
  { line: "MT JURUENA;9921;5105176" },
  { line: "MT JUSCIMEIRA;9189;5105200" },
  { line: "GO JUSSARA;9439;5212204" },
  { line: "MS JUTI;9923;5005152" },
  { line: "MS LADARIO;9103;5005202" },
  { line: "TO LAGOA DA CONFUSAO;367;1711902" },
  { line: "TO LAGOA DO TOCANTINS;353;1711951" },
  { line: "GO LAGOA SANTA;1076;5212253" },
  { line: "MS LAGUNA CARAPA;163;5005251" },
  { line: "TO LAJEADO;351;1712009" },
  { line: "MT LAMBARI D'OESTE;137;5105234" },
  { line: "TO LAVANDEIRA;86;1712157" },
  { line: "GO LEOPOLDO DE BULHOES;9443;5212303" },
  { line: "TO LIZARDA;9569;1712405" },
  { line: "MT LUCAS DO RIO VERDE;9925;5105259" },
  { line: "MT LUCIARA;9105;5105309" },
  { line: "GO LUZIANIA;9445;5212501" },
  { line: "TO LUZINOPOLIS;88;1712454" },
  { line: "GO MAIRIPOTABA;9447;5212600" },
  { line: "GO MAMBAI;9449;5212709" },
  { line: "GO MARA ROSA;9451;5212808" },
  { line: "MS MARACAJU;9107;5005400" },
  { line: "MT MARCELANDIA;9899;5105580" },
  { line: "TO MARIANOPOLIS DO TOCANTINS;9711;1712504" },
  { line: "GO MARZAGAO;9453;5212907" },
  { line: "TO MATEIROS;317;1712702" },
  { line: "GO MATRINCHA;9927;5212956" },
  { line: "MT MATUPA;9929;5105606" },
  { line: "GO MAURILANDIA;9457;5213004" },
  { line: "TO MAURILANDIA DO TOCANTINS;183;1712801" },
  { line: "GO MIMOSO DE GOIAS;9931;5213053" },
  { line: "GO MINACU;9647;5213087" },
  { line: "GO MINEIROS;9459;5213103" },
  { line: "TO MIRACEMA DO TOCANTINS;9461;1713205" },
  { line: "MS MIRANDA;9111;5005608" },
  { line: "TO MIRANORTE;9463;1713304" },
  { line: "MT MIRASSOL D'OESTE;9177;5105622" },
  { line: "GO MOIPORA;9465;5213400" },
  { line: "GO MONTE ALEGRE DE GOIAS;9467;5213509" },
  { line: "TO MONTE DO CARMO;9469;1713601" },
  { line: "TO MONTE SANTO DO TOCANTINS;90;1713700" },
  { line: "GO MONTES CLAROS DE GOIAS;9471;5213707" },
  { line: "GO MONTIVIDIU;9933;5213756" },
  { line: "GO MONTIVIDIU DO NORTE;79;5213772" },
  { line: "GO MORRINHOS;9473;5213806" },
  { line: "GO MORRO AGUDO DE GOIAS;9935;5213855" },
  { line: "GO MOSSAMEDES;9475;5213905" },
  { line: "GO MOZARLANDIA;9477;5214002" },
  { line: "GO MUNDO NOVO;9651;5214051" },
  { line: "MS MUNDO NOVO;9179;5005681" },
  { line: "TO MURICILANDIA;187;1713957" },
  { line: "GO MUTUNOPOLIS;9479;5214101" },
  { line: "TO NATIVIDADE;9481;1714203" },
  { line: "MS NAVIRAI;9113;5005707" },
  { line: "TO NAZARE;9483;1714302" },
  { line: "GO NAZARIO;9485;5214408" },
  { line: "GO NEROPOLIS;9487;5214507" },
  { line: "MS NIOAQUE;9115;5005806" },
  { line: "GO NIQUELANDIA;9489;5214606" },
  { line: "MT NOBRES;9117;5105903" },
  { line: "MT NORTELANDIA;9119;5106000" },
  { line: "MT NOSSA SENHORA DO LIVRAMENTO;9121;5106109" },
  { line: "MS NOVA ALVORADA DO SUL;143;5006002" },
  { line: "GO NOVA AMERICA;9491;5214705" },
  { line: "MS NOVA ANDRADINA;9123;5006200" },
  { line: "GO NOVA AURORA;9493;5214804" },
  { line: "MT NOVA BANDEIRANTES;117;5106158" },
  { line: "MT NOVA BRASILANDIA;8981;5106208" },
  { line: "MT NOVA CANAA DO NORTE;9889;5106216" },
  { line: "GO NOVA CRIXAS;9653;5214838" },
  { line: "GO NOVA GLORIA;9655;5214861" },
  { line: "MT NOVA GUARITA;121;5108808" },
  { line: "GO NOVA IGUACU DE GOIAS;87;5214879" },
  { line: "MT NOVA LACERDA;1040;5106182" },
  { line: "MT NOVA MARILANDIA;103;5108857" },
  { line: "MT NOVA MARINGA;111;5108907" },
  { line: "MT NOVA MONTE VERDE;119;5108956" },
  { line: "MT NOVA MUTUM;9937;5106224" },
  { line: "MT NOVA NAZARE;1086;5106174" },
  { line: "MT NOVA OLIMPIA;9893;5106232" },
  { line: "TO NOVA OLINDA;9663;1714880" },
  { line: "GO NOVA ROMA;9495;5214903" },
  { line: "TO NOVA ROSALANDIA;9721;1715002" },
  { line: "MT NOVA SANTA HELENA;1088;5106190" },
  { line: "MT NOVA UBIRATA;1042;5106240" },
  { line: "GO NOVA VENEZA;9497;5215009" },
  { line: "MT NOVA XAVANTINA;9195;5106257" },
  { line: "TO NOVO ACORDO;9499;1715101" },
  { line: "TO NOVO ALEGRE;9703;1715150" },
  { line: "GO NOVO BRASIL;9501;5215207" },
  { line: "GO NOVO GAMA;1058;5215231" },
  { line: "MT NOVO HORIZONTE DO NORTE;9903;5106273" },
  { line: "MS NOVO HORIZONTE DO SUL;159;5006259" },
  { line: "TO NOVO JARDIM;321;1715259" },
  { line: "MT NOVO MUNDO;1044;5106265" },
  { line: "GO NOVO PLANALTO;9735;5215256" },
  { line: "MT NOVO SANTO ANTONIO;1090;5106315" },
  { line: "MT NOVO SAO JOAQUIM;9867;5106281" },
  { line: "TO OLIVEIRA DE FATIMA;92;1715507" },
  { line: "GO ORIZONA;9503;5215306" },
  { line: "GO OURO VERDE DE GOIAS;9505;5215405" },
  { line: "GO OUVIDOR;9507;5215504" },
  { line: "GO PADRE BERNARDO;9509;5215603" },
  { line: "GO PALESTINA DE GOIAS;9737;5215652" },
  { line: "TO PALMAS;9733;1721000" },
  { line: "TO PALMEIRANTE;189;1715705" },
  { line: "GO PALMEIRAS DE GOIAS;9511;5215702" },
  { line: "TO PALMEIRAS DO TOCANTINS;185;1713809" },
  { line: "TO PALMEIROPOLIS;9649;1715754" },
  { line: "GO PALMELO;9513;5215801" },
  { line: "GO PALMINOPOLIS;9515;5215900" },
  { line: "GO PANAMA;9517;5216007" },
  { line: "MS PARAISO DAS AGUAS;1196;5006275" },
  { line: "TO PARAISO DO TOCANTINS;9519;1716109" },
  { line: "TO PARANA;9521;1716208" },
  { line: "MS PARANAIBA;9125;5006309" },
  { line: "GO PARANAIGUARA;9455;5216304" },
  { line: "MT PARANAITA;9885;5106299" },
  { line: "MT PARANATINGA;8983;5106307" },
  { line: "MS PARANHOS;9739;5006358" },
  { line: "GO PARAUNA;9523;5216403" },
  { line: "TO PAU D'ARCO;191;1716307" },
  { line: "MT PEDRA PRETA;9181;5106372" },
  { line: "TO PEDRO AFONSO;9525;1716505" },
  { line: "MS PEDRO GOMES;9127;5006408" },
  { line: "TO PEIXE;9527;1716604" },
  { line: "MT PEIXOTO DE AZEVEDO;9891;5106422" },
  { line: "TO PEQUIZEIRO;9705;1716653" },
  { line: "GO PEROLANDIA;75;5216452" },
  { line: "GO PETROLINA DE GOIAS;9531;5216809" },
  { line: "GO PILAR DE GOIAS;9535;5216908" },
  { line: "TO PINDORAMA DO TOCANTINS;9537;1717008" },
  { line: "GO PIRACANJUBA;9539;5217104" },
  { line: "GO PIRANHAS;9541;5217203" },
  { line: "TO PIRAQUE;355;1717206" },
  { line: "GO PIRENOPOLIS;9543;5217302" },
  { line: "GO PIRES DO RIO;9545;5217401" },
  { line: "TO PIUM;9547;1717503" },
  { line: "GO PLANALTINA;9595;5217609" },
  { line: "MT PLANALTO DA SERRA;91;5106455" },
  { line: "MT POCONE;9129;5106505" },
  { line: "MS PONTA PORA;9131;5006606" },
  { line: "MT PONTAL DO ARAGUAIA;95;5106653" },
  { line: "GO PONTALINA;9549;5217708" },
  { line: "TO PONTE ALTA DO BOM JESUS;9551;1717800" },
  { line: "TO PONTE ALTA DO TOCANTINS;9553;1717909" },
  { line: "MT PONTE BRANCA;9133;5106703" },
  { line: "MT PONTES E LACERDA;8999;5106752" },
  { line: "GO PORANGATU;9555;5218003" },
  { line: "GO PORTEIRAO;1060;5218052" },
  { line: "GO PORTELANDIA;9557;5218102" },
  { line: "MT PORTO ALEGRE DO NORTE;9895;5106778" },
  { line: "TO PORTO ALEGRE DO TOCANTINS;9723;1718006" },
  { line: "MT PORTO DOS GAUCHOS;9135;5106802" },
  { line: "MT PORTO ESPERIDIAO;9875;5106828" },
  { line: "MT PORTO ESTRELA;101;5106851" },
  { line: "MS PORTO MURTINHO;9137;5006903" },
  { line: "TO PORTO NACIONAL;9559;1718204" },
  { line: "GO POSSE;9561;5218300" },
  { line: "MT POXOREO;9139;5107008" },
  { line: "TO PRAIA NORTE;9725;1718303" },
  { line: "TO PRESIDENTE KENNEDY;9629;1718402" },
  { line: "MT PRIMAVERA DO LESTE;9871;5107040" },
  { line: "GO PROFESSOR JAMIL;51;5218391" },
  { line: "TO PUGMIL;94;1718451" },
  { line: "MT QUERENCIA;97;5107065" },
  { line: "GO QUIRINOPOLIS;9563;5218508" },
  { line: "TO RECURSOLANDIA;357;1718501" },
  { line: "MT RESERVA DO CABACAL;9879;5107156" },
  { line: "TO RIACHINHO;193;1718550" },
  { line: "GO RIALMA;9565;5218607" },
  { line: "GO RIANAPOLIS;9567;5218706" },
  { line: "MS RIBAS DO RIO PARDO;9141;5007109" },
  { line: "MT RIBEIRAO CASCALHEIRA;9741;5107180" },
  { line: "MT RIBEIRAOZINHO;99;5107198" },
  { line: "MT RIO BRANCO;8995;5107206" },
  { line: "MS RIO BRILHANTE;9143;5007208" },
  { line: "TO RIO DA CONCEICAO;323;1718659" },
  { line: "TO RIO DOS BOIS;359;1718709" },
  { line: "MS RIO NEGRO;9145;5007307" },
  { line: "GO RIO QUENTE;9995;5218789" },
  { line: "TO RIO SONO;9679;1718758" },
  { line: "GO RIO VERDE;9571;5218805" },
  { line: "MS RIO VERDE DE MATO GROSSO;9147;5007406" },
  { line: "MS ROCHEDO;9149;5007505" },
  { line: "MT RONDOLANDIA;1092;5107578" },
  { line: "MT RONDONOPOLIS;9151;5107602" },
  { line: "MT ROSARIO OESTE;9153;5107701" },
  { line: "GO RUBIATABA;9573;5218904" },
  { line: "MT SALTO DO CEU;8997;5107750" },
  { line: "TO SAMPAIO;9727;1718808" },
  { line: "GO SANCLERLANDIA;9575;5219001" },
  { line: "TO SANDOLANDIA;331;1718840" },
  { line: "GO SANTA BARBARA DE GOIAS;9577;5219100" },
  { line: "MT SANTA CARMEM;123;5107248" },
  { line: "GO SANTA CRUZ DE GOIAS;9579;5219209" },
  { line: "MT SANTA CRUZ DO XINGU;1094;5107743" },
  { line: "GO SANTA FE DE GOIAS;9743;5219258" },
  { line: "TO SANTA FE DO ARAGUAIA;195;1718865" },
  { line: "GO SANTA HELENA DE GOIAS;9581;5219308" },
  { line: "GO SANTA ISABEL;9689;5219357" },
  { line: "TO SANTA MARIA DO TOCANTINS;361;1718881" },
  { line: "GO SANTA RITA DO ARAGUAIA;9583;5219407" },
  { line: "GO SANTA RITA DO NOVO DESTINO;1062;5219456" },
  { line: "MS SANTA RITA DO PARDO;9745;5007554" },
  { line: "TO SANTA RITA DO TOCANTINS;96;1718899" },
  { line: "MT SANTA RITA DO TRIVELATO;1096;5107768" },
  { line: "GO SANTA ROSA DE GOIAS;9585;5219506" },
  { line: "TO SANTA ROSA DO TOCANTINS;9729;1718907" },
  { line: "GO SANTA TEREZA DE GOIAS;9587;5219605" },
  { line: "TO SANTA TEREZA DO TOCANTINS;9731;1719004" },
  { line: "MT SANTA TEREZINHA;9197;5107776" },
  { line: "GO SANTA TEREZINHA DE GOIAS;9589;5219704" },
  { line: "TO SANTA TEREZINHA DO TOCANTINS;98;1720002" },
  { line: "MT SANTO AFONSO;115;5107263" },
  { line: "GO SANTO ANTONIO DA BARRA;83;5219712" },
  { line: "GO SANTO ANTONIO DE GOIAS;53;5219738" },
  { line: "GO SANTO ANTONIO DO DESCOBERTO;9677;5219753" },
  { line: "MT SANTO ANTONIO DO LESTE;1098;5107792" },
  { line: "MT SANTO ANTONIO DO LEVERGER;9155;5107800" },
  { line: "TO SAO BENTO DO TOCANTINS;197;1720101" },
  { line: "GO SAO DOMINGOS;9591;5219803" },
  { line: "MT SAO FELIX DO ARAGUAIA;9183;5107859" },
  { line: "TO SAO FELIX DO TOCANTINS;363;1720150" },
  { line: "GO SAO FRANCISCO DE GOIAS;9593;5219902" },
  { line: "MS SAO GABRIEL DO OESTE;9809;5007695" },
  { line: "GO SAO JOAO DA PARAUNA;9747;5220058" },
  { line: "GO SAO JOAO D'ALIANCA;9597;5220009" },
  { line: "MT SAO JOSE DO POVO;6087;5107297" },
  { line: "MT SAO JOSE DO RIO CLARO;9199;5107305" },
  { line: "MT SAO JOSE DO XINGU;133;5107354" },
  { line: "MT SAO JOSE DOS QUATRO MARCOS;8993;5107107" },
  { line: "GO SAO LUIS DE MONTES BELOS;9599;5220108" },
  { line: "GO SAO LUIZ DO NORTE;9749;5220157" },
  { line: "GO SAO MIGUEL DO ARAGUAIA;9601;5220207" },
  { line: "GO SAO MIGUEL DO PASSA QUATRO;9751;5220264" },
  { line: "TO SAO MIGUEL DO TOCANTINS;199;1720200" },
  { line: "GO SAO PATRICIO;1064;5220280" },
  { line: "MT SAO PEDRO DA CIPA;93;5107404" },
  { line: "TO SAO SALVADOR DO TOCANTINS;333;1720259" },
  { line: "TO SAO SEBASTIAO DO TOCANTINS;9603;1720309" },
  { line: "GO SAO SIMAO;9605;5220405" },
  { line: "TO SAO VALERIO DA NATIVIDADE;9691;1720499" },
  { line: "MT SAPEZAL;1046;5107875" },
  { line: "MS SELVIRIA;9811;5007802" },
  { line: "GO SENADOR CANEDO;9753;5220454" },
  { line: "MT SERRA NOVA DOURADA;1100;5107883" },
  { line: "GO SERRANOPOLIS;9607;5220504" },
  { line: "MS SETE QUEDAS;9813;5007703" },
  { line: "MS SIDROLANDIA;9157;5007901" },
  { line: "GO SILVANIA;9609;5220603" },
  { line: "TO SILVANOPOLIS;9659;1720655" },
  { line: "GO SIMOLANDIA;9755;5220686" },
  { line: "MT SINOP;8985;5107909" },
  { line: "GO SITIO D'ABADIA;9611;5220702" },
  { line: "TO SITIO NOVO DO TOCANTINS;9613;1720804" },
  { line: "MS SONORA;9757;5007935" },
  { line: "MT SORRISO;9907;5107925" },
  { line: "TO SUCUPIRA;335;1720853" },
  { line: "MT TABAPORA;125;5107941" },
  { line: "MS TACURU;9815;5007950" },
  { line: "TO TAGUATINGA;9615;1720903" },
  { line: "TO TAIPAS DO TOCANTINS;325;1720937" },
  { line: "TO TALISMA;100;1720978" },
  { line: "MT TANGARA DA SERRA;9185;5107958" },
  { line: "MT TAPURAH;9763;5108006" },
  { line: "GO TAQUARAL DE GOIAS;9617;5221007" },
  { line: "MS TAQUARUSSU;9817;5007976" },
  { line: "MS TERENOS;9159;5008008" },
  { line: "GO TERESINA DE GOIAS;9759;5221080" },
  { line: "GO TEREZOPOLIS DE GOIAS;57;5221197" },
  { line: "MT TERRA NOVA DO NORTE;9909;5108055" },
  { line: "MT TESOURO;9161;5108105" },
  { line: "TO TOCANTINIA;9619;1721109" },
  { line: "TO TOCANTINOPOLIS;9621;1721208" },
  { line: "MT TORIXOREU;9163;5108204" },
  { line: "MS TRES LAGOAS;9165;5008305" },
  { line: "GO TRES RANCHOS;9623;5221304" },
  { line: "GO TRINDADE;9625;5221403" },
  { line: "GO TROMBAS;9761;5221452" },
  { line: "TO TUPIRAMA;102;1721257" },
  { line: "TO TUPIRATINS;365;1721307" },
  { line: "GO TURVANIA;9631;5221502" },
  { line: "GO TURVELANDIA;9765;5221551" },
  { line: "GO UIRAPURU;59;5221577" },
  { line: "MT UNIAO DO SUL;1048;5108303" },
  { line: "GO URUACU;9633;5221601" },
  { line: "GO URUANA;9635;5221700" },
  { line: "GO URUTAI;9637;5221809" },
  { line: "MT VALE DE SAO DOMINGOS;1102;5108352" },
  { line: "GO VALPARAISO DE GOIAS;1066;5221858" },
  { line: "GO VARJAO;9639;5221908" },
  { line: "MT VARZEA GRANDE;9167;5108402" },
  { line: "MT VERA;9905;5108501" },
  { line: "GO VIANOPOLIS;9641;5222005" },
  { line: "MS VICENTINA;9187;5008404" },
  { line: "GO VICENTINOPOLIS;9657;5222054" },
  { line: "MT VILA BELA DA SANTISSIMA TRINDADE;9109;5105507" },
  { line: "GO VILA BOA;67;5222203" },
  { line: "GO VILA PROPICIO;1068;5222302" },
  { line: "MT VILA RICA;9897;5108600" },
  { line: "TO WANDERLANDIA;9665;1722081" },
  { line: "TO XAMBIOA;9643;1722107" },
  { line: "PA ABAETETUBA;401;1500107" },
  { line: "PA ABEL FIGUEIREDO;375;1500131" },
  { line: "PA ACARA;403;1500206" },
  { line: "AC ACRELANDIA;643;1200013" },
  { line: "PA AFUA;405;1500305" },
  { line: "PA AGUA AZUL DO NORTE;383;1500347" },
  { line: "PA ALENQUER;407;1500404" },
  { line: "PA ALMEIRIM;409;1500503" },
  { line: "RO ALTA FLORESTA D'OESTE;33;1100015" },
  { line: "PA ALTAMIRA;411;1500602" },
  { line: "RR ALTO ALEGRE;305;1400050" },
  { line: "RO ALTO ALEGRE DOS PARECIS;2;1100379" },
  { line: "RO ALTO PARAISO;675;1100403" },
  { line: "AM ALVARAES;289;1300029" },
  { line: "RO ALVORADA D'OESTE;35;1100346" },
  { line: "RR AMAJARI;26;1400027" },
  { line: "AP AMAPA;601;1600105" },
  { line: "AM AMATURA;291;1300060" },
  { line: "PA ANAJAS;413;1500701" },
  { line: "AM ANAMA;293;1300086" },
  { line: "PA ANANINDEUA;415;1500800" },
  { line: "PA ANAPU;40;1500859" },
  { line: "AM ANORI;203;1300102" },
  { line: "AM APUI;969;1300144" },
  { line: "RO ARIQUEMES;7;1100023" },
  { line: "AC ASSIS BRASIL;157;1200054" },
  { line: "AM ATALAIA DO NORTE;205;1300201" },
  { line: "PA AUGUSTO CORREA;417;1500909" },
  { line: "PA AURORA DO PARA;389;1500958" },
  { line: "AM AUTAZES;207;1300300" },
  { line: "PA AVEIRO;419;1501006" },
  { line: "PA BAGRE;421;1501105" },
  { line: "PA BAIAO;423;1501204" },
  { line: "PA BANNACH;42;1501253" },
  { line: "PA BARCARENA;425;1501303" },
  { line: "AM BARCELOS;209;1300409" },
  { line: "AM BARREIRINHA;211;1300508" },
  { line: "PA BELEM;427;1501402" },
  { line: "PA BELTERRA;44;1501451" },
  { line: "PA BENEVIDES;429;1501501" },
  { line: "AM BENJAMIN CONSTANT;213;1300607" },
  { line: "AM BERURI;295;1300631" },
  { line: "RR BOA VISTA;301;1400100" },
  { line: "AM BOA VISTA DO RAMOS;297;1300680" },
  { line: "AM BOCA DO ACRE;215;1300706" },
  { line: "PA BOM JESUS DO TOCANTINS;575;1501576" },
  { line: "RR BONFIM;307;1400159" },
  { line: "PA BONITO;431;1501600" },
  { line: "AM BORBA;217;1300805" },
  { line: "PA BRAGANCA;433;1501709" },
  { line: "PA BRASIL NOVO;639;1501725" },
  { line: "AC BRASILEIA;105;1200104" },
  { line: "PA BREJO GRANDE DO ARAGUAIA;577;1501758" },
  { line: "PA BREU BRANCO;625;1501782" },
  { line: "PA BREVES;435;1501808" },
  { line: "AC BUJARI;645;1200138" },
  { line: "PA BUJARU;437;1501907" },
  { line: "RO BURITIS;4;1100452" },
  { line: "AM CAAPIRANGA;299;1300839" },
  { line: "RO CABIXI;37;1100031" },
  { line: "RO CACAULANDIA;677;1100601" },
  { line: "PA CACHOEIRA DO ARARI;439;1502004" },
  { line: "PA CACHOEIRA DO PIRIA;46;1501956" },
  { line: "RO CACOAL;9;1100049" },
  { line: "AP CALCOENE;603;1600204" },
  { line: "PA CAMETA;441;1502103" },
  { line: "RO CAMPO NOVO DE RONDONIA;679;1100700" },
  { line: "PA CANAA DOS CARAJAS;48;1502152" },
  { line: "RO CANDEIAS DO JAMARI;681;1100809" },
  { line: "RR CANTA;28;1400175" },
  { line: "AM CANUTAMA;219;1300904" },
  { line: "PA CAPANEMA;443;1502202" },
  { line: "PA CAPITAO POCO;445;1502301" },
  { line: "AC CAPIXABA;647;1200179" },
  { line: "RR CARACARAI;303;1400209" },
  { line: "AM CARAUARI;221;1301001" },
  { line: "AM CAREIRO;223;1301100" },
  { line: "AM CAREIRO DA VARZEA;965;1301159" },
  { line: "RR CAROEBE;30;1400233" },
  { line: "PA CASTANHAL;447;1502400" },
  { line: "RO CASTANHEIRAS;691;1100908" },
  { line: "RO CEREJEIRAS;27;1100056" },
  { line: "PA CHAVES;449;1502509" },
  { line: "RO CHUPINGUAIA;6;1100924" },
  { line: "AM COARI;225;1301209" },
  { line: "AM CODAJAS;227;1301308" },
  { line: "PA COLARES;451;1502608" },
  { line: "RO COLORADO DO OESTE;23;1100064" },
  { line: "PA CONCEICAO DO ARAGUAIA;453;1502707" },
  { line: "PA CONCORDIA DO PARA;579;1502756" },
  { line: "RO CORUMBIARA;981;1100072" },
  { line: "RO COSTA MARQUES;21;1100080" },
  { line: "AC CRUZEIRO DO SUL;107;1200203" },
  { line: "RO CUJUBIM;8;1100940" },
  { line: "PA CUMARU DO NORTE;385;1502764" },
  { line: "PA CURIONOPOLIS;581;1502772" },
  { line: "PA CURRALINHO;455;1502806" },
  { line: "PA CURUA;50;1502855" },
  { line: "PA CURUCA;457;1502905" },
  { line: "AP CUTIAS;667;1600212" },
  { line: "PA DOM ELISEU;583;1502939" },
  { line: "AM EIRUNEPE;229;1301407" },
  { line: "PA ELDORADO DOS CARAJAS;377;1502954" },
  { line: "AM ENVIRA;231;1301506" },
  { line: "AC EPITACIOLANDIA;651;1200252" },
  { line: "RO ESPIGAO D'OESTE;25;1100098" },
  { line: "PA FARO;459;1503002" },
  { line: "AC FEIJO;113;1200302" },
  { line: "AP FERREIRA GOMES;611;1600238" },
  { line: "PA FLORESTA DO ARAGUAIA;52;1503044" },
  { line: "AM FONTE BOA;233;1301605" },
  { line: "PA GARRAFAO DO NORTE;585;1503077" },
  { line: "PA GOIANESIA DO PARA;627;1503093" },
  { line: "RO GOVERNADOR JORGE TEIXEIRA;693;1101005" },
  { line: "AM GUAJARA;967;1301654" },
  { line: "RO GUAJARA-MIRIM;1;1100106" },
  { line: "PA GURUPA;461;1503101" },
  { line: "AM HUMAITA;235;1301704" },
  { line: "PA IGARAPE-ACU;463;1503200" },
  { line: "PA IGARAPE-MIRI;465;1503309" },
  { line: "PA INHANGAPI;467;1503408" },
  { line: "AM IPIXUNA;239;1301803" },
  { line: "PA IPIXUNA DO PARA;621;1503457" },
  { line: "RR IRACEMA;32;1400282" },
  { line: "AM IRANDUBA;9835;1301852" },
  { line: "PA IRITUIA;469;1503507" },
  { line: "AM ITACOATIARA;241;1301902" },
  { line: "PA ITAITUBA;471;1503606" },
  { line: "AM ITAMARATI;9837;1301951" },
  { line: "AM ITAPIRANGA;243;1302009" },
  { line: "RO ITAPUA DO OESTE;683;1101104" },
  { line: "AP ITAUBAL;669;1600253" },
  { line: "PA ITUPIRANGA;473;1503705" },
  { line: "PA JACAREACANGA;631;1503754" },
  { line: "PA JACUNDA;475;1503804" },
  { line: "AM JAPURA;245;1302108" },
  { line: "RO JARU;15;1100114" },
  { line: "RO JI-PARANA;5;1100122" },
  { line: "AC JORDAO;653;1200328" },
  { line: "AM JURUA;247;1302207" },
  { line: "PA JURUTI;477;1503903" },
  { line: "AM JUTAI;249;1302306" },
  { line: "AM LABREA;251;1302405" },
  { line: "AP LARANJAL DO JARI;613;1600279" },
  { line: "PA LIMOEIRO DO AJURU;479;1504000" },
  { line: "AP MACAPA;605;1600303" },
  { line: "RO MACHADINHO D'OESTE;39;1100130" },
  { line: "PA MAE DO RIO;587;1504059" },
  { line: "PA MAGALHAES BARATA;481;1504109" },
  { line: "AM MANACAPURU;253;1302504" },
  { line: "AM MANAQUIRI;9839;1302553" },
  { line: "AM MANAUS;255;1302603" },
  { line: "AC MANCIO LIMA;109;1200336" },
  { line: "AM MANICORE;257;1302702" },
  { line: "AC MANOEL URBANO;155;1200344" },
  { line: "AM MARAA;259;1302801" },
  { line: "PA MARABA;483;1504208" },
  { line: "PA MARACANA;485;1504307" },
  { line: "PA MARAPANIM;487;1504406" },
  { line: "AC MARECHAL THAUMATURGO;655;1200351" },
  { line: "PA MARITUBA;54;1504422" },
  { line: "AM MAUES;261;1302900" },
  { line: "AP MAZAGAO;607;1600402" },
  { line: "PA MEDICILANDIA;589;1504455" },
  { line: "PA MELGACO;489;1504505" },
  { line: "RO MINISTRO ANDREAZZA;695;1101203" },
  { line: "RO MIRANTE DA SERRA;697;1101302" },
  { line: "PA MOCAJUBA;491;1504604" },
  { line: "PA MOJU;493;1504703" },
  { line: "PA MOJUI DOS CAMPOS;1190;1504752" },
  { line: "PA MONTE ALEGRE;495;1504802" },
  { line: "RO MONTE NEGRO;685;1101401" },
  { line: "PA MUANA;497;1504901" },
  { line: "RR MUCAJAI;309;1400308" },
  { line: "AM NHAMUNDA;263;1303007" },
  { line: "RR NORMANDIA;311;1400407" },
  { line: "RO NOVA BRASILANDIA D'OESTE;41;1100148" },
  { line: "PA NOVA ESPERANCA DO PIRIA;391;1504950" },
  { line: "PA NOVA IPIXUNA;56;1504976" },
  { line: "RO NOVA MAMORE;47;1100338" },
  { line: "AM NOVA OLINDA DO NORTE;265;1303106" },
  { line: "PA NOVA TIMBOTEUA;499;1505007" },
  { line: "RO NOVA UNIAO;10;1101435" },
  { line: "AM NOVO AIRAO;201;1303205" },
  { line: "AM NOVO ARIPUANA;267;1303304" },
  { line: "RO NOVO HORIZONTE DO OESTE;689;1100502" },
  { line: "PA NOVO PROGRESSO;633;1505031" },
  { line: "PA NOVO REPARTIMENTO;629;1505064" },
  { line: "PA OBIDOS;501;1505106" },
  { line: "PA OEIRAS DO PARA;503;1505205" },
  { line: "AP OIAPOQUE;609;1600501" },
  { line: "PA ORIXIMINA;505;1505304" },
  { line: "PA OUREM;507;1505403" },
  { line: "PA OURILANDIA DO NORTE;591;1505437" },
  { line: "RO OURO PRETO DO OESTE;17;1100155" },
  { line: "PA PACAJA;593;1505486" },
  { line: "RR PACARAIMA;34;1400456" },
  { line: "PA PALESTINA DO PARA;379;1505494" },
  { line: "PA PARAGOMINAS;509;1505502" },
  { line: "PA PARAUAPEBAS;595;1505536" },
  { line: "RO PARECIS;12;1101450" },
  { line: "AM PARINTINS;269;1303403" },
  { line: "PA PAU D'ARCO;387;1505551" },
  { line: "AM PAUINI;271;1303502" },
  { line: "AP PEDRA BRANCA DO AMAPARI;663;1600154" },
  { line: "PA PEIXE-BOI;511;1505601" },
  { line: "PA PICARRA;58;1505635" },
  { line: "RO PIMENTA BUENO;11;1100189" },
  { line: "RO PIMENTEIRAS DO OESTE;14;1101468" },
  { line: "PA PLACAS;60;1505650" },
  { line: "AC PLACIDO DE CASTRO;151;1200385" },
  { line: "PA PONTA DE PEDRAS;513;1505700" },
  { line: "PA PORTEL;515;1505809" },
  { line: "AC PORTO ACRE;649;1200807" },
  { line: "PA PORTO DE MOZ;517;1505908" },
  { line: "AP PORTO GRANDE;671;1600535" },
  { line: "RO PORTO VELHO;3;1100205" },
  { line: "AC PORTO WALTER;657;1200393" },
  { line: "AP PRACUUBA;673;1600550" },
  { line: "PA PRAINHA;519;1506005" },
  { line: "AM PRESIDENTE FIGUEIREDO;9841;1303536" },
  { line: "RO PRESIDENTE MEDICI;19;1100254" },
  { line: "PA PRIMAVERA;521;1506104" },
  { line: "RO PRIMAVERA DE RONDONIA;16;1101476" },
  { line: "PA QUATIPURU;62;1506112" },
  { line: "PA REDENCAO;567;1506138" },
  { line: "AC RIO BRANCO;139;1200401" },
  { line: "RO RIO CRESPO;687;1100262" },
  { line: "PA RIO MARIA;569;1506161" },
  { line: "AM RIO PRETO DA EVA;9843;1303569" },
  { line: "AC RODRIGUES ALVES;659;1200427" },
  { line: "RO ROLIM DE MOURA;29;1100288" },
  { line: "PA RONDON DO PARA;573;1506187" },
  { line: "RR RORAINOPOLIS;36;1400472" },
  { line: "PA RUROPOLIS;597;1506195" },
  { line: "PA SALINOPOLIS;523;1506203" },
  { line: "PA SALVATERRA;525;1506302" },
  { line: "PA SANTA BARBARA DO PARA;369;1506351" },
  { line: "PA SANTA CRUZ DO ARARI;527;1506401" },
  { line: "PA SANTA ISABEL DO PARA;529;1506500" },
  { line: "AM SANTA ISABEL DO RIO NEGRO;237;1303601" },
  { line: "PA SANTA LUZIA DO PARA;371;1506559" },
  { line: "RO SANTA LUZIA D'OESTE;43;1100296" },
  { line: "PA SANTA MARIA DAS BARREIRAS;599;1506583" },
  { line: "PA SANTA MARIA DO PARA;531;1506609" },
  { line: "AC SANTA ROSA DO PURUS;661;1200435" },
  { line: "AP SANTANA;615;1600600" },
  { line: "PA SANTANA DO ARAGUAIA;533;1506708" },
  { line: "PA SANTAREM;535;1506807" },
  { line: "PA SANTAREM NOVO;537;1506906" },
  { line: "AM SANTO ANTONIO DO ICA;273;1303700" },
  { line: "PA SANTO ANTONIO DO TAUA;539;1507003" },
  { line: "PA SAO CAETANO DE ODIVELAS;541;1507102" },
  { line: "PA SAO DOMINGOS DO ARAGUAIA;381;1507151" },
  { line: "PA SAO DOMINGOS DO CAPIM;543;1507201" },
  { line: "RO SAO FELIPE D'OESTE;18;1101484" },
  { line: "PA SAO FELIX DO XINGU;545;1507300" },
  { line: "RO SAO FRANCISCO DO GUAPORE;20;1101492" },
  { line: "PA SAO FRANCISCO DO PARA;547;1507409" },
  { line: "AM SAO GABRIEL DA CACHOEIRA;283;1303809" },
  { line: "PA SAO GERALDO DO ARAGUAIA;619;1507458" },
  { line: "RR SAO JOAO DA BALIZA;313;1400506" },
  { line: "PA SAO JOAO DA PONTA;64;1507466" },
  { line: "PA SAO JOAO DE PIRABAS;393;1507474" },
  { line: "PA SAO JOAO DO ARAGUAIA;549;1507508" },
  { line: "RR SAO LUIZ;315;1400605" },
  { line: "PA SAO MIGUEL DO GUAMA;551;1507607" },
  { line: "RO SAO MIGUEL DO GUAPORE;45;1100320" },
  { line: "AM SAO PAULO DE OLIVENCA;275;1303908" },
  { line: "PA SAO SEBASTIAO DA BOA VISTA;553;1507706" },
  { line: "AM SAO SEBASTIAO DO UATUMA;9845;1303957" },
  { line: "PA SAPUCAIA;66;1507755" },
  { line: "AC SENA MADUREIRA;145;1200500" },
  { line: "AC SENADOR GUIOMARD;153;1200450" },
  { line: "PA SENADOR JOSE PORFIRIO;555;1507805" },
  { line: "RO SERINGUEIRAS;699;1101500" },
  { line: "AP SERRA DO NAVIO;665;1600055" },
  { line: "AM SILVES;277;1304005" },
  { line: "PA SOURE;557;1507904" },
  { line: "AM TABATINGA;9847;1304062" },
  { line: "PA TAILANDIA;395;1507953" },
  { line: "AM TAPAUA;279;1304104" },
  { line: "AC TARAUACA;147;1200609" },
  { line: "AP TARTARUGALZINHO;617;1600709" },
  { line: "AM TEFE;281;1304203" },
  { line: "RO TEIXEIROPOLIS;22;1101559" },
  { line: "PA TERRA ALTA;373;1507961" },
  { line: "PA TERRA SANTA;637;1507979" },
  { line: "RO THEOBROMA;975;1101609" },
  { line: "PA TOME-ACU;559;1508001" },
  { line: "AM TONANTINS;9851;1304237" },
  { line: "PA TRACUATEUA;68;1508035" },
  { line: "PA TRAIRAO;635;1508050" },
  { line: "PA TUCUMA;397;1508084" },
  { line: "PA TUCURUI;561;1508100" },
  { line: "AM UARINI;9849;1304260" },
  { line: "RR UIRAMUTA;38;1400704" },
  { line: "PA ULIANOPOLIS;623;1508126" },
  { line: "PA URUARA;399;1508159" },
  { line: "AM URUCARA;285;1304302" },
  { line: "AM URUCURITUBA;287;1304401" },
  { line: "RO URUPA;977;1101708" },
  { line: "RO VALE DO ANARI;24;1101757" },
  { line: "RO VALE DO PARAISO;979;1101807" },
  { line: "PA VIGIA;563;1508209" },
  { line: "RO VILHENA;13;1100304" },
  { line: "PA VISEU;565;1508308" },
  { line: "AP VITORIA DO JARI;70;1600808" },
  { line: "PA VITORIA DO XINGU;641;1508357" },
  { line: "AC XAPURI;149;1200708" },
  { line: "PA XINGUARA;571;1508407" },
  { line: "CE ABAIARA;1301;2300101" },
  { line: "MA ACAILANDIA;961;2100055" },
  { line: "CE ACARAPE;1231;2300150" },
  { line: "CE ACARAU;1303;2300200" },
  { line: "PI ACAUA;266;2200053" },
  { line: "CE ACOPIARA;1305;2300309" },
  { line: "MA AFONSO CUNHA;701;2100105" },
  { line: "PI AGRICOLANDIA;1001;2200103" },
  { line: "PI AGUA BRANCA;1003;2200202" },
  { line: "MA AGUA DOCE DO MARANHAO;104;2100154" },
  { line: "CE AIUABA;1307;2300408" },
  { line: "PI ALAGOINHA DO PIAUI;9767;2200251" },
  { line: "MA ALCANTARA;703;2100204" },
  { line: "CE ALCANTARAS;1309;2300507" },
  { line: "MA ALDEIAS ALTAS;705;2100303" },
  { line: "PI ALEGRETE DO PIAUI;2269;2200277" },
  { line: "MA ALTAMIRA DO MARANHAO;707;2100402" },
  { line: "CE ALTANEIRA;1311;2300606" },
  { line: "MA ALTO ALEGRE DO MARANHAO;106;2100436" },
  { line: "MA ALTO ALEGRE DO PINDARE;108;2100477" },
  { line: "PI ALTO LONGA;1005;2200301" },
  { line: "MA ALTO PARNAIBA;709;2100501" },
  { line: "CE ALTO SANTO;1313;2300705" },
  { line: "PI ALTOS;1007;2200400" },
  { line: "PI ALVORADA DO GURGUEIA;268;2200459" },
  { line: "MA AMAPA DO MARANHAO;110;2100550" },
  { line: "PI AMARANTE;1009;2200509" },
  { line: "MA AMARANTE DO MARANHAO;711;2100600" },
  { line: "CE AMONTADA;1587;2300754" },
  { line: "MA ANAJATUBA;713;2100709" },
  { line: "MA ANAPURUS;715;2100808" },
  { line: "PI ANGICAL DO PIAUI;1011;2200608" },
  { line: "PI ANISIO DE ABREU;1013;2200707" },
  { line: "CE ANTONINA DO NORTE;1315;2300804" },
  { line: "PI ANTONIO ALMEIDA;1015;2200806" },
  { line: "MA APICUM-ACU;112;2100832" },
  { line: "CE APUIARES;1317;2300903" },
  { line: "CE AQUIRAZ;1319;2301000" },
  { line: "CE ARACATI;1321;2301109" },
  { line: "CE ARACOIABA;1323;2301208" },
  { line: "MA ARAGUANA;114;2100873" },
  { line: "MA ARAIOSES;717;2100907" },
  { line: "MA ARAME;1281;2100956" },
  { line: "CE ARARENDA;989;2301257" },
  { line: "MA ARARI;719;2101004" },
  { line: "CE ARARIPE;1325;2301307" },
  { line: "CE ARATUBA;1327;2301406" },
  { line: "CE ARNEIROZ;1329;2301505" },
  { line: "PI AROAZES;1017;2200905" },
  { line: "PI AROEIRAS DO ITAIM;1188;2200954" },
  { line: "PI ARRAIAL;1019;2201002" },
  { line: "CE ASSARE;1331;2301604" },
  { line: "PI ASSUNCAO DO PIAUI;270;2201051" },
  { line: "CE AURORA;1333;2301703" },
  { line: "PI AVELINO LOPES;1021;2201101" },
  { line: "MA AXIXA;721;2101103" },
  { line: "MA BACABAL;723;2101202" },
  { line: "MA BACABEIRA;116;2101251" },
  { line: "MA BACURI;725;2101301" },
  { line: "MA BACURITUBA;118;2101350" },
  { line: "PI BAIXA GRANDE DO RIBEIRO;2245;2201150" },
  { line: "CE BAIXIO;1335;2301802" },
  { line: "MA BALSAS;727;2101400" },
  { line: "CE BANABUIU;1233;2301851" },
  { line: "MA BARAO DE GRAJAU;729;2101509" },
  { line: "CE BARBALHA;1337;2301901" },
  { line: "PI BARRA D'ALCANTARA;272;2201176" },
  { line: "MA BARRA DO CORDA;731;2101608" },
  { line: "PI BARRAS;1023;2201200" },
  { line: "CE BARREIRA;1235;2301950" },
  { line: "PI BARREIRAS DO PIAUI;1025;2201309" },
  { line: "MA BARREIRINHAS;733;2101707" },
  { line: "CE BARRO;1339;2302008" },
  { line: "PI BARRO DURO;1027;2201408" },
  { line: "CE BARROQUINHA;1237;2302057" },
  { line: "PI BATALHA;1029;2201507" },
  { line: "CE BATURITE;1341;2302107" },
  { line: "CE BEBERIBE;1343;2302206" },
  { line: "CE BELA CRUZ;1345;2302305" },
  { line: "MA BELA VISTA DO MARANHAO;122;2101772" },
  { line: "PI BELA VISTA DO PIAUI;274;2201556" },
  { line: "MA BELAGUA;120;2101731" },
  { line: "PI BELEM DO PIAUI;276;2201572" },
  { line: "PI BENEDITINOS;1031;2201606" },
  { line: "MA BENEDITO LEITE;735;2101806" },
  { line: "MA BEQUIMAO;737;2101905" },
  { line: "MA BERNARDO DO MEARIM;124;2101939" },
  { line: "PI BERTOLINIA;1033;2201705" },
  { line: "PI BETANIA DO PIAUI;278;2201739" },
  { line: "PI BOA HORA;280;2201770" },
  { line: "CE BOA VIAGEM;1347;2302404" },
  { line: "MA BOA VISTA DO GURUPI;126;2101970" },
  { line: "PI BOCAINA;1035;2201804" },
  { line: "MA BOM JARDIM;955;2102002" },
  { line: "PI BOM JESUS;1037;2201903" },
  { line: "MA BOM JESUS DAS SELVAS;128;2102036" },
  { line: "MA BOM LUGAR;130;2102077" },
  { line: "PI BOM PRINCIPIO DO PIAUI;2287;2201919" },
  { line: "PI BONFIM DO PIAUI;2251;2201929" },
  { line: "PI BOQUEIRAO DO PIAUI;282;2201945" },
  { line: "PI BRASILEIRA;2283;2201960" },
  { line: "MA BREJO;739;2102101" },
  { line: "MA BREJO DE AREIA;132;2102150" },
  { line: "PI BREJO DO PIAUI;284;2201988" },
  { line: "CE BREJO SANTO;1349;2302503" },
  { line: "MA BURITI;741;2102200" },
  { line: "MA BURITI BRAVO;743;2102309" },
  { line: "PI BURITI DOS LOPES;1039;2202000" },
  { line: "PI BURITI DOS MONTES;1297;2202026" },
  { line: "MA BURITICUPU;134;2102325" },
  { line: "MA BURITIRANA;136;2102358" },
  { line: "PI CABECEIRAS DO PIAUI;1299;2202059" },
  { line: "MA CACHOEIRA GRANDE;138;2102374" },
  { line: "MA CAJAPIO;745;2102408" },
  { line: "MA CAJARI;747;2102507" },
  { line: "PI CAJAZEIRAS DO PIAUI;286;2202075" },
  { line: "PI CAJUEIRO DA PRAIA;288;2202083" },
  { line: "PI CALDEIRAO GRANDE DO PIAUI;2271;2202091" },
  { line: "CE CAMOCIM;1351;2302602" },
  { line: "MA CAMPESTRE DO MARANHAO;140;2102556" },
  { line: "PI CAMPINAS DO PIAUI;1041;2202109" },
  { line: "PI CAMPO ALEGRE DO FIDALGO;290;2202117" },
  { line: "PI CAMPO GRANDE DO PIAUI;292;2202133" },
  { line: "PI CAMPO LARGO DO PIAUI;294;2202174" },
  { line: "PI CAMPO MAIOR;1043;2202208" },
  { line: "CE CAMPOS SALES;1353;2302701" },
  { line: "PI CANAVIEIRA;2247;2202251" },
  { line: "MA CANDIDO MENDES;749;2102606" },
  { line: "CE CANINDE;1355;2302800" },
  { line: "MA CANTANHEDE;751;2102705" },
  { line: "PI CANTO DO BURITI;1045;2202307" },
  { line: "MA CAPINZAL DO NORTE;142;2102754" },
  { line: "CE CAPISTRANO;1357;2302909" },
  { line: "PI CAPITAO DE CAMPOS;1047;2202406" },
  { line: "PI CAPITAO GERVASIO OLIVEIRA;296;2202455" },
  { line: "PI CARACOL;1049;2202505" },
  { line: "PI CARAUBAS DO PIAUI;298;2202539" },
  { line: "CE CARIDADE;1359;2303006" },
  { line: "PI CARIDADE DO PIAUI;300;2202554" },
  { line: "CE CARIRE;1361;2303105" },
  { line: "CE CARIRIACU;1363;2303204" },
  { line: "CE CARIUS;1365;2303303" },
  { line: "CE CARNAUBAL;1367;2303402" },
  { line: "MA CAROLINA;753;2102804" },
  { line: "MA CARUTAPERA;755;2102903" },
  { line: "CE CASCAVEL;1369;2303501" },
  { line: "PI CASTELO DO PIAUI;1051;2202604" },
  { line: "CE CATARINA;1371;2303600" },
  { line: "CE CATUNDA;983;2303659" },
  { line: "CE CAUCAIA;1373;2303709" },
  { line: "MA CAXIAS;757;2103000" },
  { line: "PI CAXINGO;302;2202653" },
  { line: "MA CEDRAL;759;2103109" },
  { line: "CE CEDRO;1375;2303808" },
  { line: "MA CENTRAL DO MARANHAO;144;2103125" },
  { line: "MA CENTRO DO GUILHERME;146;2103158" },
  { line: "MA CENTRO NOVO DO MARANHAO;148;2103174" },
  { line: "MA CHAPADINHA;761;2103208" },
  { line: "CE CHAVAL;1377;2303907" },
  { line: "CE CHORO;993;2303931" },
  { line: "CE CHOROZINHO;1239;2303956" },
  { line: "MA CIDELANDIA;150;2103257" },
  { line: "PI COCAL;1053;2202703" },
  { line: "PI COCAL DE TELHA;304;2202711" },
  { line: "PI COCAL DOS ALVES;306;2202729" },
  { line: "MA CODO;763;2103307" },
  { line: "MA COELHO NETO;765;2103406" },
  { line: "PI COIVARAS;995;2202737" },
  { line: "MA COLINAS;767;2103505" },
  { line: "PI COLONIA DO GURGUEIA;2249;2202752" },
  { line: "PI COLONIA DO PIAUI;2253;2202778" },
  { line: "PI CONCEICAO DO CANINDE;1055;2202802" },
  { line: "MA CONCEICAO DO LAGO-ACU;152;2103554" },
  { line: "CE COREAU;1381;2304004" },
  { line: "MA COROATA;769;2103604" },
  { line: "PI CORONEL JOSE DIAS;2255;2202851" },
  { line: "PI CORRENTE;1057;2202901" },
  { line: "CE CRATEUS;1383;2304103" },
  { line: "CE CRATO;1385;2304202" },
  { line: "PI CRISTALANDIA DO PIAUI;1059;2203008" },
  { line: "PI CRISTINO CASTRO;1061;2203107" },
  { line: "CE CROATA;1241;2304236" },
  { line: "CE CRUZ;1589;2304251" },
  { line: "PI CURIMATA;1063;2203206" },
  { line: "PI CURRAIS;308;2203230" },
  { line: "PI CURRAL NOVO DO PIAUI;312;2203271" },
  { line: "PI CURRALINHOS;310;2203255" },
  { line: "MA CURURUPU;771;2103703" },
  { line: "MA DAVINOPOLIS;154;2103752" },
  { line: "PI DEMERVAL LOBAO;1065;2203305" },
  { line: "CE DEPUTADO IRAPUAN PINHEIRO;1243;2304269" },
  { line: "PI DIRCEU ARCOVERDE;1229;2203354" },
  { line: "PI DOM EXPEDITO LOPES;1067;2203404" },
  { line: "PI DOM INOCENCIO;1289;2203453" },
  { line: "MA DOM PEDRO;773;2103802" },
  { line: "PI DOMINGOS MOURAO;1141;2203420" },
  { line: "MA DUQUE BACELAR;775;2103901" },
  { line: "PI ELESBAO VELOSO;1069;2203503" },
  { line: "PI ELISEU MARTINS;1071;2203602" },
  { line: "CE ERERE;1245;2304277" },
  { line: "PI ESPERANTINA;1073;2203701" },
  { line: "MA ESPERANTINOPOLIS;777;2104008" },
  { line: "MA ESTREITO;963;2104057" },
  { line: "CE EUSEBIO;1247;2304285" },
  { line: "CE FARIAS BRITO;1387;2304301" },
  { line: "PI FARTURA DO PIAUI;2257;2203750" },
  { line: "MA FEIRA NOVA DO MARANHAO;156;2104073" },
  { line: "MA FERNANDO FALCAO;158;2104081" },
  { line: "PI FLORES DO PIAUI;1075;2203800" },
  { line: "PI FLORESTA DO PIAUI;314;2203859" },
  { line: "PI FLORIANO;1077;2203909" },
  { line: "MA FORMOSA DA SERRA NEGRA;160;2104099" },
  { line: "CE FORQUILHA;1591;2304350" },
  { line: "CE FORTALEZA;1389;2304400" },
  { line: "MA FORTALEZA DOS NOGUEIRAS;779;2104107" },
  { line: "CE FORTIM;987;2304459" },
  { line: "MA FORTUNA;781;2104206" },
  { line: "PI FRANCINOPOLIS;1079;2204006" },
  { line: "PI FRANCISCO AYRES;1081;2204105" },
  { line: "PI FRANCISCO MACEDO;316;2204154" },
  { line: "PI FRANCISCO SANTOS;1083;2204204" },
  { line: "CE FRECHEIRINHA;1391;2304509" },
  { line: "PI FRONTEIRAS;1085;2204303" },
  { line: "PI GEMINIANO;318;2204352" },
  { line: "CE GENERAL SAMPAIO;1393;2304608" },
  { line: "PI GILBUES;1087;2204402" },
  { line: "MA GODOFREDO VIANA;783;2104305" },
  { line: "MA GONCALVES DIAS;785;2104404" },
  { line: "MA GOVERNADOR ARCHER;787;2104503" },
  { line: "MA GOVERNADOR EDISON LOBAO;162;2104552" },
  { line: "MA GOVERNADOR EUGENIO BARROS;789;2104602" },
  { line: "MA GOVERNADOR LUIZ ROCHA;164;2104628" },
  { line: "MA GOVERNADOR NEWTON BELLO;166;2104651" },
  { line: "MA GOVERNADOR NUNES FREIRE;168;2104677" },
  { line: "CE GRACA;1249;2304657" },
  { line: "MA GRACA ARANHA;791;2104701" },
  { line: "MA GRAJAU;793;2104800" },
  { line: "CE GRANJA;1395;2304707" },
  { line: "CE GRANJEIRO;1397;2304806" },
  { line: "CE GROAIRAS;1399;2304905" },
  { line: "PI GUADALUPE;1089;2204501" },
  { line: "CE GUAIUBA;1251;2304954" },
  { line: "CE GUARACIABA DO NORTE;1401;2305001" },
  { line: "CE GUARAMIRANGA;1403;2305100" },
  { line: "PI GUARIBAS;320;2204550" },
  { line: "MA GUIMARAES;795;2104909" },
  { line: "CE HIDROLANDIA;1405;2305209" },
  { line: "CE HORIZONTE;1253;2305233" },
  { line: "PI HUGO NAPOLEAO;1091;2204600" },
  { line: "MA HUMBERTO DE CAMPOS;797;2105005" },
  { line: "CE IBARETAMA;1255;2305266" },
  { line: "CE IBIAPINA;1407;2305308" },
  { line: "CE IBICUITINGA;1257;2305332" },
  { line: "CE ICAPUI;1593;2305357" },
  { line: "MA ICATU;799;2105104" },
  { line: "CE ICO;1409;2305407" },
  { line: "MA IGARAPE DO MEIO;170;2105153" },
  { line: "MA IGARAPE GRANDE;801;2105203" },
  { line: "CE IGUATU;1411;2305506" },
  { line: "PI ILHA GRANDE;322;2204659" },
  { line: "MA IMPERATRIZ;803;2105302" },
  { line: "CE INDEPENDENCIA;1413;2305605" },
  { line: "PI INHUMA;1093;2204709" },
  { line: "CE IPAPORANGA;1259;2305654" },
  { line: "CE IPAUMIRIM;1415;2305704" },
  { line: "PI IPIRANGA DO PIAUI;1095;2204808" },
  { line: "CE IPU;1417;2305803" },
  { line: "CE IPUEIRAS;1419;2305902" },
  { line: "CE IRACEMA;1421;2306009" },
  { line: "CE IRAUCUBA;1423;2306108" },
  { line: "PI ISAIAS COELHO;1097;2204907" },
  { line: "CE ITAICABA;1425;2306207" },
  { line: "PI ITAINOPOLIS;1099;2205003" },
  { line: "MA ITAIPAVA DO GRAJAU;172;2105351" },
  { line: "CE ITAITINGA;991;2306256" },
  { line: "CE ITAPAJE;1427;2306306" },
  { line: "MA ITAPECURU MIRIM;807;2105401" },
  { line: "CE ITAPIPOCA;1429;2306405" },
  { line: "CE ITAPIUNA;1431;2306504" },
  { line: "CE ITAREMA;1595;2306553" },
  { line: "CE ITATIRA;1433;2306603" },
  { line: "PI ITAUEIRA;1101;2205102" },
  { line: "MA ITINGA DO MARANHAO;174;2105427" },
  { line: "PI JACOBINA DO PIAUI;2273;2205151" },
  { line: "CE JAGUARETAMA;1435;2306702" },
  { line: "CE JAGUARIBARA;1437;2306801" },
  { line: "CE JAGUARIBE;1439;2306900" },
  { line: "CE JAGUARUANA;1441;2307007" },
  { line: "PI JAICOS;1103;2205201" },
  { line: "CE JARDIM;1443;2307106" },
  { line: "PI JARDIM DO MULATO;997;2205250" },
  { line: "CE JATI;1445;2307205" },
  { line: "MA JATOBA;176;2105450" },
  { line: "PI JATOBA DO PIAUI;324;2205276" },
  { line: "MA JENIPAPO DOS VIEIRAS;178;2105476" },
  { line: "PI JERUMENHA;1105;2205300" },
  { line: "CE JIJOCA DE JERICOACOARA;985;2307254" },
  { line: "PI JOAO COSTA;326;2205359" },
  { line: "MA JOAO LISBOA;809;2105500" },
  { line: "PI JOAQUIM PIRES;1107;2205409" },
  { line: "PI JOCA MARQUES;328;2205458" },
  { line: "PI JOSE DE FREITAS;1109;2205508" },
  { line: "MA JOSELANDIA;811;2105609" },
  { line: "CE JUAZEIRO DO NORTE;1447;2307304" },
  { line: "PI JUAZEIRO DO PIAUI;330;2205516" },
  { line: "CE JUCAS;1449;2307403" },
  { line: "PI JULIO BORGES;332;2205524" },
  { line: "MA JUNCO DO MARANHAO;180;2105658" },
  { line: "PI JUREMA;334;2205532" },
  { line: "MA LAGO DA PEDRA;813;2105708" },
  { line: "MA LAGO DO JUNCO;815;2105807" },
  { line: "MA LAGO DOS RODRIGUES;184;2105948" },
  { line: "MA LAGO VERDE;817;2105906" },
  { line: "PI LAGOA ALEGRE;999;2205557" },
  { line: "PI LAGOA DE SAO FRANCISCO;338;2205573" },
  { line: "PI LAGOA DO BARRO DO PIAUI;2259;2205565" },
  { line: "MA LAGOA DO MATO;182;2105922" },
  { line: "PI LAGOA DO PIAUI;340;2205581" },
  { line: "PI LAGOA DO SITIO;342;2205599" },
  { line: "MA LAGOA GRANDE DO MARANHAO;186;2105963" },
  { line: "PI LAGOINHA DO PIAUI;336;2205540" },
  { line: "MA LAJEADO NOVO;188;2105989" },
  { line: "PI LANDRI SALES;1111;2205607" },
  { line: "CE LAVRAS DA MANGABEIRA;1451;2307502" },
  { line: "MA LIMA CAMPOS;819;2106003" },
  { line: "CE LIMOEIRO DO NORTE;1453;2307601" },
  { line: "MA LORETO;821;2106102" },
  { line: "PI LUIS CORREIA;1113;2205706" },
  { line: "MA LUIS DOMINGUES;823;2106201" },
  { line: "PI LUZILANDIA;1115;2205805" },
  { line: "CE MADALENA;1261;2307635" },
  { line: "PI MADEIRO;344;2205854" },
  { line: "MA MAGALHAES DE ALMEIDA;825;2106300" },
  { line: "PI MANOEL EMIDIO;1117;2205904" },
  { line: "MA MARACACUME;190;2106326" },
  { line: "CE MARACANAU;1585;2307650" },
  { line: "MA MARAJA DO SENA;192;2106359" },
  { line: "CE MARANGUAPE;1455;2307700" },
  { line: "MA MARANHAOZINHO;194;2106375" },
  { line: "CE MARCO;1457;2307809" },
  { line: "PI MARCOLANDIA;2275;2205953" },
  { line: "PI MARCOS PARENTE;1119;2206001" },
  { line: "CE MARTINOPOLE;1459;2307908" },
  { line: "CE MASSAPE;1461;2308005" },
  { line: "PI MASSAPE DO PIAUI;346;2206050" },
  { line: "MA MATA ROMA;827;2106409" },
  { line: "PI MATIAS OLIMPIO;1121;2206100" },
  { line: "MA MATINHA;829;2106508" },
  { line: "MA MATOES;831;2106607" },
  { line: "MA MATOES DO NORTE;196;2106631" },
  { line: "CE MAURITI;1463;2308104" },
  { line: "CE MERUOCA;1465;2308203" },
  { line: "PI MIGUEL ALVES;1123;2206209" },
  { line: "PI MIGUEL LEAO;1125;2206308" },
  { line: "CE MILAGRES;1467;2308302" },
  { line: "MA MILAGRES DO MARANHAO;198;2106672" },
  { line: "CE MILHA;1597;2308351" },
  { line: "PI MILTON BRANDAO;348;2206357" },
  { line: "MA MIRADOR;833;2106706" },
  { line: "CE MIRAIMA;1263;2308377" },
  { line: "MA MIRANDA DO NORTE;1283;2106755" },
  { line: "MA MIRINZAL;835;2106805" },
  { line: "CE MISSAO VELHA;1469;2308401" },
  { line: "CE MOMBACA;1471;2308500" },
  { line: "MA MONCAO;837;2106904" },
  { line: "PI MONSENHOR GIL;1127;2206407" },
  { line: "PI MONSENHOR HIPOLITO;1129;2206506" },
  { line: "CE MONSENHOR TABOSA;1473;2308609" },
  { line: "PI MONTE ALEGRE DO PIAUI;1131;2206605" },
  { line: "MA MONTES ALTOS;839;2107001" },
  { line: "CE MORADA NOVA;1475;2308708" },
  { line: "CE MORAUJO;1477;2308807" },
  { line: "CE MORRINHOS;1479;2308906" },
  { line: "PI MORRO CABECA NO TEMPO;350;2206654" },
  { line: "PI MORRO DO CHAPEU DO PIAUI;352;2206670" },
  { line: "MA MORROS;841;2107100" },
  { line: "CE MUCAMBO;1481;2309003" },
  { line: "CE MULUNGU;1483;2309102" },
  { line: "PI MURICI DOS PORTELAS;354;2206696" },
  { line: "PI NAZARE DO PIAUI;1133;2206704" },
  { line: "PI NAZARIA;1180;2206720" },
  { line: "MA NINA RODRIGUES;843;2107209" },
  { line: "PI NOSSA SENHORA DE NAZARE;356;2206753" },
  { line: "PI NOSSA SENHORA DOS REMEDIOS;1135;2206803" },
  { line: "MA NOVA COLINAS;200;2107258" },
  { line: "MA NOVA IORQUE;845;2107308" },
  { line: "CE NOVA OLINDA;1485;2309201" },
  { line: "MA NOVA OLINDA DO MARANHAO;202;2107357" },
  { line: "CE NOVA RUSSAS;1487;2309300" },
  { line: "PI NOVA SANTA RITA;370;2207959" },
  { line: "CE NOVO ORIENTE;1489;2309409" },
  { line: "PI NOVO ORIENTE DO PIAUI;1137;2206902" },
  { line: "PI NOVO SANTO ANTONIO;358;2206951" },
  { line: "CE OCARA;1265;2309458" },
  { line: "PI OEIRAS;1139;2207009" },
  { line: "MA OLHO D'AGUA DAS CUNHAS;847;2107407" },
  { line: "PI OLHO D'AGUA DO PIAUI;360;2207108" },
  { line: "MA OLINDA NOVA DO MARANHAO;204;2107456" },
  { line: "CE OROS;1491;2309508" },
  { line: "CE PACAJUS;1493;2309607" },
  { line: "CE PACATUBA;1495;2309706" },
  { line: "MA PACO DO LUMIAR;849;2107506" },
  { line: "CE PACOTI;1497;2309805" },
  { line: "CE PACUJA;1499;2309904" },
  { line: "PI PADRE MARCOS;1143;2207207" },
  { line: "PI PAES LANDIM;1145;2207306" },
  { line: "PI PAJEU DO PIAUI;362;2207355" },
  { line: "CE PALHANO;1501;2310001" },
  { line: "CE PALMACIA;1503;2310100" },
  { line: "PI PALMEIRA DO PIAUI;1147;2207405" },
  { line: "PI PALMEIRAIS;1149;2207504" },
  { line: "MA PALMEIRANDIA;851;2107605" },
  { line: "PI PAQUETA;364;2207553" },
  { line: "CE PARACURU;1505;2310209" },
  { line: "MA PARAIBANO;853;2107704" },
  { line: "CE PARAIPABA;1599;2310258" },
  { line: "CE PARAMBU;1507;2310308" },
  { line: "CE PARAMOTI;1509;2310407" },
  { line: "PI PARNAGUA;1151;2207603" },
  { line: "PI PARNAIBA;1153;2207702" },
  { line: "MA PARNARAMA;855;2107803" },
  { line: "MA PASSAGEM FRANCA;857;2107902" },
  { line: "PI PASSAGEM FRANCA DO PIAUI;1293;2207751" },
  { line: "MA PASTOS BONS;859;2108009" },
  { line: "PI PATOS DO PIAUI;2277;2207777" },
  { line: "PI PAU D'ARCO DO PIAUI;1104;2207793" },
  { line: "MA PAULINO NEVES;206;2108058" },
  { line: "PI PAULISTANA;1155;2207801" },
  { line: "MA PAULO RAMOS;959;2108108" },
  { line: "PI PAVUSSU;366;2207850" },
  { line: "CE PEDRA BRANCA;1511;2310506" },
  { line: "MA PEDREIRAS;861;2108207" },
  { line: "MA PEDRO DO ROSARIO;208;2108256" },
  { line: "PI PEDRO II;1157;2207900" },
  { line: "PI PEDRO LAURENTINO;368;2207934" },
  { line: "CE PENAFORTE;1513;2310605" },
  { line: "MA PENALVA;863;2108306" },
  { line: "CE PENTECOSTE;1515;2310704" },
  { line: "CE PEREIRO;1517;2310803" },
  { line: "MA PERI MIRIM;865;2108405" },
  { line: "MA PERITORO;210;2108454" },
  { line: "PI PICOS;1159;2208007" },
  { line: "PI PIMENTEIRAS;1161;2208106" },
  { line: "MA PINDARE MIRIM;867;2108504" },
  { line: "CE PINDORETAMA;1267;2310852" },
  { line: "MA PINHEIRO;869;2108603" },
  { line: "PI PIO IX;1163;2208205" },
  { line: "MA PIO XII;871;2108702" },
  { line: "CE PIQUET CARNEIRO;1519;2310902" },
  { line: "PI PIRACURUCA;1165;2208304" },
  { line: "MA PIRAPEMAS;873;2108801" },
  { line: "CE PIRES FERREIRA;1269;2310951" },
  { line: "PI PIRIPIRI;1167;2208403" },
  { line: "MA POCAO DE PEDRAS;875;2108900" },
  { line: "CE PORANGA;1521;2311009" },
  { line: "CE PORTEIRAS;1523;2311108" },
  { line: "PI PORTO;1169;2208502" },
  { line: "PI PORTO ALEGRE DO PIAUI;372;2208551" },
  { line: "MA PORTO FRANCO;877;2109007" },
  { line: "MA PORTO RICO DO MARANHAO;212;2109056" },
  { line: "CE POTENGI;1525;2311207" },
  { line: "CE POTIRETAMA;1271;2311231" },
  { line: "PI PRATA DO PIAUI;1171;2208601" },
  { line: "MA PRESIDENTE DUTRA;879;2109106" },
  { line: "MA PRESIDENTE JUSCELINO;881;2109205" },
  { line: "MA PRESIDENTE MEDICI;214;2109239" },
  { line: "MA PRESIDENTE SARNEY;216;2109270" },
  { line: "MA PRESIDENTE VARGAS;883;2109304" },
  { line: "MA PRIMEIRA CRUZ;885;2109403" },
  { line: "PI QUEIMADA NOVA;2279;2208650" },
  { line: "CE QUITERIANOPOLIS;9917;2311264" },
  { line: "CE QUIXADA;1527;2311306" },
  { line: "CE QUIXELO;9853;2311355" },
  { line: "CE QUIXERAMOBIM;1529;2311405" },
  { line: "CE QUIXERE;1531;2311504" },
  { line: "MA RAPOSA;218;2109452" },
  { line: "CE REDENCAO;1533;2311603" },
  { line: "PI REDENCAO DO GURGUEIA;1173;2208700" },
  { line: "PI REGENERACAO;1175;2208809" },
  { line: "CE RERIUTABA;1535;2311702" },
  { line: "MA RIACHAO;887;2109502" },
  { line: "PI RIACHO FRIO;374;2208858" },
  { line: "MA RIBAMAR FIQUENE;220;2109551" },
  { line: "PI RIBEIRA DO PIAUI;376;2208874" },
  { line: "PI RIBEIRO GONCALVES;1177;2208908" },
  { line: "PI RIO GRANDE DO PIAUI;1179;2209005" },
  { line: "MA ROSARIO;891;2109601" },
  { line: "CE RUSSAS;1537;2311801" },
  { line: "CE SABOEIRO;1539;2311900" },
  { line: "CE SALITRE;1273;2311959" },
  { line: "MA SAMBAIBA;893;2109700" },
  { line: "PI SANTA CRUZ DO PIAUI;1181;2209104" },
  { line: "PI SANTA CRUZ DOS MILAGRES;1295;2209153" },
  { line: "PI SANTA FILOMENA;1183;2209203" },
  { line: "MA SANTA FILOMENA DO MARANHAO;222;2109759" },
  { line: "MA SANTA HELENA;895;2109809" },
  { line: "MA SANTA INES;957;2109908" },
  { line: "PI SANTA LUZ;1185;2209302" },
  { line: "MA SANTA LUZIA;897;2110005" },
  { line: "MA SANTA LUZIA DO PARUA;1285;2110039" },
  { line: "CE SANTA QUITERIA;1545;2312205" },
  { line: "MA SANTA QUITERIA DO MARANHAO;899;2110104" },
  { line: "MA SANTA RITA;901;2110203" },
  { line: "PI SANTA ROSA DO PIAUI;2261;2209377" },
  { line: "CE SANTANA DO ACARAU;1541;2312007" },
  { line: "CE SANTANA DO CARIRI;1543;2312106" },
  { line: "MA SANTANA DO MARANHAO;224;2110237" },
  { line: "PI SANTANA DO PIAUI;2281;2209351" },
  { line: "MA SANTO AMARO DO MARANHAO;226;2110278" },
  { line: "PI SANTO ANTONIO DE LISBOA;1187;2209401" },
  { line: "MA SANTO ANTONIO DOS LOPES;903;2110302" },
  { line: "PI SANTO ANTONIO DOS MILAGRES;378;2209450" },
  { line: "PI SANTO INACIO DO PIAUI;1189;2209500" },
  { line: "CE SAO BENEDITO;1547;2312304" },
  { line: "MA SAO BENEDITO DO RIO PRETO;905;2110401" },
  { line: "MA SAO BENTO;907;2110500" },
  { line: "MA SAO BERNARDO;909;2110609" },
  { line: "PI SAO BRAZ DO PIAUI;2263;2209559" },
  { line: "MA SAO DOMINGOS DO AZEITAO;228;2110658" },
  { line: "MA SAO DOMINGOS DO MARANHAO;911;2110708" },
  { line: "MA SAO FELIX DE BALSAS;913;2110807" },
  { line: "PI SAO FELIX DO PIAUI;1191;2209609" },
  { line: "PI SAO FRANCISCO DE ASSIS DO PIAUI;380;2209658" },
  { line: "MA SAO FRANCISCO DO BREJAO;230;2110856" },
  { line: "MA SAO FRANCISCO DO MARANHAO;915;2110906" },
  { line: "PI SAO FRANCISCO DO PIAUI;1193;2209708" },
  { line: "CE SAO GONCALO DO AMARANTE;1549;2312403" },
  { line: "PI SAO GONCALO DO GURGUEIA;382;2209757" },
  { line: "PI SAO GONCALO DO PIAUI;1195;2209807" },
  { line: "MA SAO JOAO BATISTA;917;2111003" },
  { line: "PI SAO JOAO DA CANABRAVA;1291;2209856" },
  { line: "PI SAO JOAO DA FRONTEIRA;384;2209872" },
  { line: "PI SAO JOAO DA SERRA;1197;2209906" },
  { line: "PI SAO JOAO DA VARJOTA;386;2209955" },
  { line: "PI SAO JOAO DO ARRAIAL;388;2209971" },
  { line: "MA SAO JOAO DO CARU;232;2111029" },
  { line: "CE SAO JOAO DO JAGUARIBE;1551;2312502" },
  { line: "MA SAO JOAO DO PARAISO;234;2111052" },
  { line: "PI SAO JOAO DO PIAUI;1199;2210003" },
  { line: "MA SAO JOAO DO SOTER;236;2111078" },
  { line: "MA SAO JOAO DOS PATOS;919;2111102" },
  { line: "MA SAO JOSE DE RIBAMAR;889;2111201" },
  { line: "PI SAO JOSE DO DIVINO;2285;2210052" },
  { line: "PI SAO JOSE DO PEIXE;1201;2210102" },
  { line: "PI SAO JOSE DO PIAUI;1203;2210201" },
  { line: "MA SAO JOSE DOS BASILIOS;238;2111250" },
  { line: "PI SAO JULIAO;1205;2210300" },
  { line: "PI SAO LOURENCO DO PIAUI;2265;2210359" },
  { line: "MA SAO LUIS;921;2111300" },
  { line: "CE SAO LUIS DO CURU;1553;2312601" },
  { line: "PI SAO LUIS DO PIAUI;390;2210375" },
  { line: "MA SAO LUIS GONZAGA DO MARANHAO;805;2111409" },
  { line: "MA SAO MATEUS DO MARANHAO;923;2111508" },
  { line: "PI SAO MIGUEL DA BAIXA GRANDE;392;2210383" },
  { line: "PI SAO MIGUEL DO FIDALGO;394;2210391" },
  { line: "PI SAO MIGUEL DO TAPUIO;1207;2210409" },
  { line: "MA SAO PEDRO DA AGUA BRANCA;240;2111532" },
  { line: "PI SAO PEDRO DO PIAUI;1209;2210508" },
  { line: "MA SAO PEDRO DOS CRENTES;242;2111573" },
  { line: "MA SAO RAIMUNDO DAS MANGABEIRAS;925;2111607" },
  { line: "MA SAO RAIMUNDO DO DOCA BEZERRA;244;2111631" },
  { line: "PI SAO RAIMUNDO NONATO;1211;2210607" },
  { line: "MA SAO ROBERTO;246;2111672" },
  { line: "MA SAO VICENTE FERRER;927;2111706" },
  { line: "MA SATUBINHA;248;2111722" },
  { line: "PI SEBASTIAO BARROS;396;2210623" },
  { line: "PI SEBASTIAO LEAL;398;2210631" },
  { line: "MA SENADOR ALEXANDRE COSTA;250;2111748" },
  { line: "MA SENADOR LA ROCQUE;252;2111763" },
  { line: "CE SENADOR POMPEU;1555;2312700" },
  { line: "CE SENADOR SA;1557;2312809" },
  { line: "MA SERRANO DO MARANHAO;254;2111789" },
  { line: "PI SIGEFREDO PACHECO;1379;2210656" },
  { line: "PI SIMOES;1213;2210706" },
  { line: "PI SIMPLICIO MENDES;1215;2210805" },
  { line: "MA SITIO NOVO;929;2111805" },
  { line: "CE SOBRAL;1559;2312908" },
  { line: "PI SOCORRO DO PIAUI;1217;2210904" },
  { line: "CE SOLONOPOLE;1561;2313005" },
  { line: "MA SUCUPIRA DO NORTE;931;2111904" },
  { line: "MA SUCUPIRA DO RIACHAO;256;2111953" },
  { line: "PI SUSSUAPARA;400;2210938" },
  { line: "CE TABULEIRO DO NORTE;1563;2313104" },
  { line: "CE TAMBORIL;1565;2313203" },
  { line: "PI TAMBORIL DO PIAUI;402;2210953" },
  { line: "PI TANQUE DO PIAUI;404;2210979" },
  { line: "CE TARRAFAS;1275;2313252" },
  { line: "MA TASSO FRAGOSO;933;2112001" },
  { line: "CE TAUA;1567;2313302" },
  { line: "CE TEJUCUOCA;1277;2313351" },
  { line: "PI TERESINA;1219;2211001" },
  { line: "CE TIANGUA;1569;2313401" },
  { line: "MA TIMBIRAS;935;2112100" },
  { line: "MA TIMON;937;2112209" },
  { line: "CE TRAIRI;1571;2313500" },
  { line: "MA TRIZIDELA DO VALE;258;2112233" },
  { line: "MA TUFILANDIA;260;2112274" },
  { line: "MA TUNTUM;939;2112308" },
  { line: "MA TURIACU;941;2112407" },
  { line: "MA TURILANDIA;262;2112456" },
  { line: "CE TURURU;1279;2313559" },
  { line: "MA TUTOIA;943;2112506" },
  { line: "CE UBAJARA;1573;2313609" },
  { line: "CE UMARI;1575;2313708" },
  { line: "CE UMIRIM;9855;2313757" },
  { line: "PI UNIAO;1221;2211100" },
  { line: "MA URBANO SANTOS;945;2112605" },
  { line: "CE URUBURETAMA;1577;2313807" },
  { line: "PI URUCUI;1223;2211209" },
  { line: "CE URUOCA;1579;2313906" },
  { line: "PI VALENCA DO PIAUI;1225;2211308" },
  { line: "MA VARGEM GRANDE;947;2112704" },
  { line: "CE VARJOTA;9857;2313955" },
  { line: "CE VARZEA ALEGRE;1581;2314003" },
  { line: "PI VARZEA BRANCA;2267;2211357" },
  { line: "PI VARZEA GRANDE;1227;2211407" },
  { line: "PI VERA MENDES;406;2211506" },
  { line: "MA VIANA;949;2112803" },
  { line: "CE VICOSA DO CEARA;1583;2314102" },
  { line: "PI VILA NOVA DO PIAUI;408;2211605" },
  { line: "MA VILA NOVA DOS MARTIRIOS;264;2112852" },
  { line: "MA VITORIA DO MEARIM;951;2112902" },
  { line: "MA VITORINO FREIRE;953;2113009" },
  { line: "PI WALL FERRAZ;410;2211704" },
  { line: "MA ZE DOCA;1287;2114007" },
  { line: "PE ABREU E LIMA;2631;2600054" },
  { line: "RN ACARI;1601;2400109" },
  { line: "PE AFOGADOS DA INGAZEIRA;2301;2600104" },
  { line: "RN AFONSO BEZERRA;1605;2400307" },
  { line: "PE AFRANIO;2303;2600203" },
  { line: "PE AGRESTINA;2305;2600302" },
  { line: "AL AGUA BRANCA;2701;2700102" },
  { line: "PB AGUA BRANCA;1901;2500106" },
  { line: "RN AGUA NOVA;1607;2400406" },
  { line: "PE AGUA PRETA;2307;2600401" },
  { line: "PE AGUAS BELAS;2309;2600500" },
  { line: "PB AGUIAR;1903;2500205" },
  { line: "PB ALAGOA GRANDE;1905;2500304" },
  { line: "PB ALAGOA NOVA;1907;2500403" },
  { line: "PB ALAGOINHA;1909;2500502" },
  { line: "PE ALAGOINHA;2311;2600609" },
  { line: "PB ALCANTIL;440;2500536" },
  { line: "RN ALEXANDRIA;1609;2400505" },
  { line: "PB ALGODAO DE JANDAIRA;442;2500577" },
  { line: "PB ALHANDRA;1911;2500601" },
  { line: "PE ALIANCA;2313;2600708" },
  { line: "RN ALMINO AFONSO;1611;2400604" },
  { line: "PE ALTINHO;2315;2600807" },
  { line: "RN ALTO DO RODRIGUES;1613;2400703" },
  { line: "PE AMARAJI;2317;2600906" },
  { line: "PB AMPARO;444;2500734" },
  { line: "AL ANADIA;2703;2700201" },
  { line: "PE ANGELIM;2319;2601003" },
  { line: "RN ANGICOS;1615;2400802" },
  { line: "RN ANTONIO MARTINS;1617;2400901" },
  { line: "PB APARECIDA;446;2500775" },
  { line: "RN APODI;1619;2401008" },
  { line: "PB ARACAGI;1915;2500809" },
  { line: "PE ARACOIABA;544;2601052" },
  { line: "AL ARAPIRACA;2705;2700300" },
  { line: "PB ARARA;1917;2500908" },
  { line: "PE ARARIPINA;2321;2601102" },
  { line: "PB ARARUNA;1919;2501005" },
  { line: "PE ARCOVERDE;2323;2601201" },
  { line: "PB AREIA;1921;2501104" },
  { line: "RN AREIA BRANCA;1621;2401107" },
  { line: "PB AREIA DE BARAUNAS;448;2501153" },
  { line: "PB AREIAL;1923;2501203" },
  { line: "RN ARES;1623;2401206" },
  { line: "PB AROEIRAS;1925;2501302" },
  { line: "RN ASSU;1603;2400208" },
  { line: "PB ASSUNCAO;450;2501351" },
  { line: "AL ATALAIA;2707;2700409" },
  { line: "PB BAIA DA TRAICAO;1929;2501401" },
  { line: "RN BAIA FORMOSA;1627;2401404" },
  { line: "PB BANANEIRAS;1931;2501500" },
  { line: "PB BARAUNA;452;2501534" },
  { line: "RN BARAUNA;3003;2401453" },
  { line: "RN BARCELONA;1629;2401503" },
  { line: "PE BARRA DE GUABIRABA;2325;2601300" },
  { line: "PB BARRA DE SANTA ROSA;1933;2501609" },
  { line: "PB BARRA DE SANTANA;454;2501575" },
  { line: "AL BARRA DE SANTO ANTONIO;2709;2700508" },
  { line: "AL BARRA DE SAO MIGUEL;2711;2700607" },
  { line: "PB BARRA DE SAO MIGUEL;1935;2501708" },
  { line: "PE BARREIROS;2327;2601409" },
  { line: "AL BATALHA;2713;2700706" },
  { line: "PB BAYEUX;1937;2501807" },
  { line: "AL BELEM;2715;2700805" },
  { line: "PB BELEM;1939;2501906" },
  { line: "PE BELEM DE MARIA;2329;2601508" },
  { line: "PE BELEM DE SAO FRANCISCO;2331;2601607" },
  { line: "PB BELEM DO BREJO DO CRUZ;1941;2502003" },
  { line: "PE BELO JARDIM;2333;2601706" },
  { line: "AL BELO MONTE;2717;2700904" },
  { line: "RN BENTO FERNANDES;1631;2401602" },
  { line: "PB BERNARDINO BATISTA;456;2502052" },
  { line: "PE BETANIA;2335;2601805" },
  { line: "PE BEZERROS;2337;2601904" },
  { line: "RN BOA SAUDE;1703;2405306" },
  { line: "PB BOA VENTURA;1943;2502102" },
  { line: "PB BOA VISTA;458;2502151" },
  { line: "AL BOCA DA MATA;2719;2701001" },
  { line: "RN BODO;412;2401651" },
  { line: "PE BODOCO;2339;2602001" },
  { line: "PE BOM CONSELHO;2341;2602100" },
  { line: "PE BOM JARDIM;2343;2602209" },
  { line: "PB BOM JESUS;1945;2502201" },
  { line: "RN BOM JESUS;1633;2401701" },
  { line: "PB BOM SUCESSO;1947;2502300" },
  { line: "PE BONITO;2345;2602308" },
  { line: "PB BONITO DE SANTA FE;1949;2502409" },
  { line: "PB BOQUEIRAO;1951;2502508" },
  { line: "PB BORBOREMA;1955;2502706" },
  { line: "AL BRANQUINHA;2721;2701100" },
  { line: "PE BREJAO;2347;2602407" },
  { line: "PE BREJINHO;2349;2602506" },
  { line: "RN BREJINHO;1635;2401800" },
  { line: "PE BREJO DA MADRE DE DEUS;2351;2602605" },
  { line: "PB BREJO DO CRUZ;1957;2502805" },
  { line: "PB BREJO DOS SANTOS;1959;2502904" },
  { line: "PE BUENOS AIRES;2353;2602704" },
  { line: "PE BUIQUE;2355;2602803" },
  { line: "PB CAAPORA;1961;2503001" },
  { line: "PB CABACEIRAS;1963;2503100" },
  { line: "PB CABEDELO;1965;2503209" },
  { line: "PE CABO DE SANTO AGOSTINHO;2357;2602902" },
  { line: "PE CABROBO;2359;2603009" },
  { line: "PB CACHOEIRA DOS INDIOS;1967;2503308" },
  { line: "PE CACHOEIRINHA;2361;2603108" },
  { line: "PB CACIMBA DE AREIA;1969;2503407" },
  { line: "PB CACIMBA DE DENTRO;1971;2503506" },
  { line: "PB CACIMBAS;460;2503555" },
  { line: "AL CACIMBINHAS;2723;2701209" },
  { line: "PE CAETES;2363;2603207" },
  { line: "PB CAICARA;1973;2503605" },
  { line: "RN CAICARA DO NORTE;414;2401859" },
  { line: "RN CAICARA DO RIO DO VENTO;1637;2401909" },
  { line: "RN CAICO;1639;2402006" },
  { line: "PB CAJAZEIRAS;1975;2503704" },
  { line: "PB CAJAZEIRINHAS;462;2503753" },
  { line: "AL CAJUEIRO;2725;2701308" },
  { line: "PE CALCADO;2365;2603306" },
  { line: "PB CALDAS BRANDAO;1977;2503803" },
  { line: "PE CALUMBI;2367;2603405" },
  { line: "PB CAMALAU;1979;2503902" },
  { line: "PE CAMARAGIBE;2629;2603454" },
  { line: "PE CAMOCIM DE SAO FELIX;2369;2603504" },
  { line: "AL CAMPESTRE;560;2701357" },
  { line: "PB CAMPINA GRANDE;1981;2504009" },
  { line: "AL CAMPO ALEGRE;2727;2701407" },
  { line: "AL CAMPO GRANDE;2729;2701506" },
  { line: "RN CAMPO GRANDE;1625;2401305" },
  { line: "RN CAMPO REDONDO;1641;2402105" },
  { line: "PE CAMUTANGA;2371;2603603" },
  { line: "AL CANAPI;2731;2701605" },
  { line: "RN CANGUARETAMA;1643;2402204" },
  { line: "PE CANHOTINHO;2373;2603702" },
  { line: "AL CAPELA;2733;2701704" },
  { line: "PB CAPIM;464;2504033" },
  { line: "PE CAPOEIRAS;2375;2603801" },
  { line: "PB CARAUBAS;466;2504074" },
  { line: "RN CARAUBAS;1645;2402303" },
  { line: "PE CARNAIBA;2377;2603900" },
  { line: "RN CARNAUBA DOS DANTAS;1647;2402402" },
  { line: "RN CARNAUBAIS;1649;2402501" },
  { line: "PE CARNAUBEIRA DA PENHA;2635;2603926" },
  { line: "AL CARNEIROS;2735;2701803" },
  { line: "PE CARPINA;2379;2604007" },
  { line: "PB CARRAPATEIRA;1983;2504108" },
  { line: "PE CARUARU;2381;2604106" },
  { line: "PE CASINHAS;546;2604155" },
  { line: "PB CASSERENGUE;468;2504157" },
  { line: "PE CATENDE;2383;2604205" },
  { line: "PB CATINGUEIRA;1985;2504207" },
  { line: "PB CATOLE DO ROCHA;1987;2504306" },
  { line: "PB CATURITE;470;2504355" },
  { line: "RN CEARA-MIRIM;1651;2402600" },
  { line: "PE CEDRO;2385;2604304" },
  { line: "RN CERRO CORA;1653;2402709" },
  { line: "PE CHA DE ALEGRIA;2387;2604403" },
  { line: "PE CHA GRANDE;2389;2604502" },
  { line: "AL CHA PRETA;2737;2701902" },
  { line: "AL COITE DO NOIA;2739;2702009" },
  { line: "AL COLONIA LEOPOLDINA;2741;2702108" },
  { line: "PB CONCEICAO;1989;2504405" },
  { line: "PB CONDADO;1991;2504504" },
  { line: "PE CONDADO;2391;2604601" },
  { line: "PB CONDE;1993;2504603" },
  { line: "PB CONGO;1995;2504702" },
  { line: "AL COQUEIRO SECO;2743;2702207" },
  { line: "PB COREMAS;1997;2504801" },
  { line: "RN CORONEL EZEQUIEL;1655;2402808" },
  { line: "RN CORONEL JOAO PESSOA;1657;2402907" },
  { line: "PE CORRENTES;2393;2604700" },
  { line: "PE CORTES;2395;2604809" },
  { line: "AL CORURIPE;2745;2702306" },
  { line: "PB COXIXOLA;472;2504850" },
  { line: "AL CRAIBAS;2889;2702355" },
  { line: "PB CRUZ DO ESPIRITO SANTO;1999;2504900" },
  { line: "RN CRUZETA;1659;2403004" },
  { line: "PB CUBATI;2001;2505006" },
  { line: "PB CUITE;2003;2505105" },
  { line: "PB CUITE DE MAMANGUAPE;474;2505238" },
  { line: "PB CUITEGI;2005;2505204" },
  { line: "PE CUMARU;2397;2604908" },
  { line: "PE CUPIRA;2399;2605004" },
  { line: "RN CURRAIS NOVOS;1661;2403103" },
  { line: "PB CURRAL DE CIMA;476;2505279" },
  { line: "PB CURRAL VELHO;2007;2505303" },
  { line: "PE CUSTODIA;2401;2605103" },
  { line: "PB DAMIAO;478;2505352" },
  { line: "AL DELMIRO GOUVEIA;2747;2702405" },
  { line: "PB DESTERRO;2009;2505402" },
  { line: "PB DIAMANTE;2013;2505600" },
  { line: "AL DOIS RIACHOS;2749;2702504" },
  { line: "PB DONA INES;2015;2505709" },
  { line: "PE DORMENTES;2299;2605152" },
  { line: "RN DOUTOR SEVERIANO;1663;2403202" },
  { line: "PB DUAS ESTRADAS;2017;2505808" },
  { line: "PB EMAS;2019;2505907" },
  { line: "RN ENCANTO;1665;2403301" },
  { line: "RN EQUADOR;1667;2403400" },
  { line: "PE ESCADA;2403;2605202" },
  { line: "PB ESPERANCA;2021;2506004" },
  { line: "RN ESPIRITO SANTO;1669;2403509" },
  { line: "AL ESTRELA DE ALAGOAS;2643;2702553" },
  { line: "RN EXTREMOZ;1671;2403608" },
  { line: "PE EXU;2405;2605301" },
  { line: "PB FAGUNDES;2023;2506103" },
  { line: "AL FEIRA GRANDE;2751;2702603" },
  { line: "PE FEIRA NOVA;2407;2605400" },
  { line: "RN FELIPE GUERRA;1673;2403707" },
  { line: "AL FELIZ DESERTO;2753;2702702" },
  { line: "PE FERNANDO DE NORONHA;3001;2605459" },
  { line: "RN FERNANDO PEDROZA;416;2403756" },
  { line: "PE FERREIROS;2409;2605509" },
  { line: "AL FLEXEIRAS;2755;2702801" },
  { line: "RN FLORANIA;1675;2403806" },
  { line: "PE FLORES;2411;2605608" },
  { line: "PE FLORESTA;2413;2605707" },
  { line: "RN FRANCISCO DANTAS;1677;2403905" },
  { line: "PB FREI MARTINHO;2025;2506202" },
  { line: "PE FREI MIGUELINHO;2415;2605806" },
  { line: "RN FRUTUOSO GOMES;1751;2404002" },
  { line: "PB GADO BRAVO;480;2506251" },
  { line: "RN GALINHOS;1679;2404101" },
  { line: "PE GAMELEIRA;2417;2605905" },
  { line: "PE GARANHUNS;2419;2606002" },
  { line: "AL GIRAU DO PONCIANO;2757;2702900" },
  { line: "PE GLORIA DO GOITA;2421;2606101" },
  { line: "PE GOIANA;2423;2606200" },
  { line: "RN GOIANINHA;1681;2404200" },
  { line: "RN GOVERNADOR DIX-SEPT ROSADO;1683;2404309" },
  { line: "PE GRANITO;2425;2606309" },
  { line: "PE GRAVATA;2427;2606408" },
  { line: "RN GROSSOS;1685;2404408" },
  { line: "RN GUAMARE;1687;2404507" },
  { line: "PB GUARABIRA;2027;2506301" },
  { line: "PB GURINHEM;2029;2506400" },
  { line: "PB GURJAO;2031;2506509" },
  { line: "PE IATI;2429;2606507" },
  { line: "AL IBATEGUARA;2759;2703007" },
  { line: "PB IBIARA;2033;2506608" },
  { line: "PE IBIMIRIM;2431;2606606" },
  { line: "PE IBIRAJUBA;2433;2606705" },
  { line: "RN IELMO MARINHO;1689;2404606" },
  { line: "AL IGACI;2761;2703106" },
  { line: "PB IGARACY;1953;2502607" },
  { line: "PE IGARASSU;2435;2606804" },
  { line: "AL IGREJA NOVA;2763;2703205" },
  { line: "PE IGUARACI;2437;2606903" },
  { line: "PE ILHA DE ITAMARACA;2451;2607604" },
  { line: "PB IMACULADA;2035;2506707" },
  { line: "PE INAJA;2439;2607000" },
  { line: "PB INGA;2037;2506806" },
  { line: "PE INGAZEIRA;2441;2607109" },
  { line: "AL INHAPI;2765;2703304" },
  { line: "RN IPANGUACU;1691;2404705" },
  { line: "PE IPOJUCA;2443;2607208" },
  { line: "PE IPUBI;2445;2607307" },
  { line: "RN IPUEIRA;1693;2404804" },
  { line: "PB ITABAIANA;2039;2506905" },
  { line: "PE ITACURUBA;2447;2607406" },
  { line: "PE ITAIBA;2449;2607505" },
  { line: "RN ITAJA;418;2404853" },
  { line: "PE ITAMBE;2597;2607653" },
  { line: "PE ITAPETIM;2453;2607703" },
  { line: "PE ITAPISSUMA;2633;2607752" },
  { line: "PB ITAPORANGA;2041;2507002" },
  { line: "PB ITAPOROROCA;2043;2507101" },
  { line: "PE ITAQUITINGA;2455;2607802" },
  { line: "PB ITATUBA;2045;2507200" },
  { line: "RN ITAU;1695;2404903" },
  { line: "PE JABOATAO DOS GUARARAPES;2457;2607901" },
  { line: "RN JACANA;1697;2405009" },
  { line: "PB JACARAU;2047;2507309" },
  { line: "AL JACARE DOS HOMENS;2767;2703403" },
  { line: "AL JACUIPE;2769;2703502" },
  { line: "RN JANDAIRA;1699;2405108" },
  { line: "RN JANDUIS;1701;2405207" },
  { line: "AL JAPARATINGA;2771;2703601" },
  { line: "RN JAPI;1705;2405405" },
  { line: "PE JAQUEIRA;548;2607950" },
  { line: "AL JARAMATAIA;2773;2703700" },
  { line: "RN JARDIM DE ANGICOS;1707;2405504" },
  { line: "RN JARDIM DE PIRANHAS;1709;2405603" },
  { line: "RN JARDIM DO SERIDO;1711;2405702" },
  { line: "PE JATAUBA;2459;2608008" },
  { line: "PE JATOBA;550;2608057" },
  { line: "AL JEQUIA DA PRAIA;562;2703759" },
  { line: "PB JERICO;2049;2507408" },
  { line: "PE JOAO ALFREDO;2461;2608107" },
  { line: "RN JOAO CAMARA;1713;2405801" },
  { line: "RN JOAO DIAS;1715;2405900" },
  { line: "PB JOAO PESSOA;2051;2507507" },
  { line: "AL JOAQUIM GOMES;2775;2703809" },
  { line: "PE JOAQUIM NABUCO;2463;2608206" },
  { line: "PB JOCA CLAUDINO;514;2513653" },
  { line: "RN JOSE DA PENHA;1717;2406007" },
  { line: "PB JUAREZ TAVORA;2053;2507606" },
  { line: "PB JUAZEIRINHO;2055;2507705" },
  { line: "PE JUCATI;2295;2608255" },
  { line: "RN JUCURUTU;1719;2406106" },
  { line: "PB JUNCO DO SERIDO;2057;2507804" },
  { line: "AL JUNDIA;2777;2703908" },
  { line: "RN JUNDIA;1108;2406155" },
  { line: "AL JUNQUEIRO;2779;2704005" },
  { line: "PE JUPI;2465;2608305" },
  { line: "PE JUREMA;2467;2608404" },
  { line: "PB JURIPIRANGA;2059;2507903" },
  { line: "PB JURU;2061;2508000" },
  { line: "PB LAGOA;2063;2508109" },
  { line: "AL LAGOA DA CANOA;2781;2704104" },
  { line: "RN LAGOA D'ANTA;1723;2406205" },
  { line: "PB LAGOA DE DENTRO;2065;2508208" },
  { line: "RN LAGOA DE PEDRAS;1725;2406304" },
  { line: "RN LAGOA DE VELHOS;1727;2406403" },
  { line: "PE LAGOA DO CARRO;2289;2608453" },
  { line: "PE LAGOA DO ITAENGA;2469;2608503" },
  { line: "PE LAGOA DO OURO;2471;2608602" },
  { line: "PE LAGOA DOS GATOS;2473;2608701" },
  { line: "PE LAGOA GRANDE;552;2608750" },
  { line: "RN LAGOA NOVA;1729;2406502" },
  { line: "RN LAGOA SALGADA;1731;2406601" },
  { line: "PB LAGOA SECA;2067;2508307" },
  { line: "PE LAJEDO;2475;2608800" },
  { line: "RN LAJES;1733;2406700" },
  { line: "RN LAJES PINTADAS;1735;2406809" },
  { line: "PB LASTRO;2069;2508406" },
  { line: "PE LIMOEIRO;2477;2608909" },
  { line: "AL LIMOEIRO DE ANADIA;2783;2704203" },
  { line: "PB LIVRAMENTO;2071;2508505" },
  { line: "PB LOGRADOURO;482;2508554" },
  { line: "PB LUCENA;2073;2508604" },
  { line: "RN LUCRECIA;1737;2406908" },
  { line: "RN LUIS GOMES;1739;2407005" },
  { line: "RN MACAIBA;1741;2407104" },
  { line: "PE MACAPARANA;2479;2609006" },
  { line: "RN MACAU;1743;2407203" },
  { line: "AL MACEIO;2785;2704302" },
  { line: "PE MACHADOS;2481;2609105" },
  { line: "PB MAE D'AGUA;2075;2508703" },
  { line: "AL MAJOR ISIDORO;2787;2704401" },
  { line: "RN MAJOR SALES;420;2407252" },
  { line: "PB MALTA;2077;2508802" },
  { line: "PB MAMANGUAPE;2079;2508901" },
  { line: "PB MANAIRA;2081;2509008" },
  { line: "PE MANARI;554;2609154" },
  { line: "AL MAR VERMELHO;2797;2704906" },
  { line: "AL MARAGOGI;2789;2704500" },
  { line: "PE MARAIAL;2483;2609204" },
  { line: "AL MARAVILHA;2791;2704609" },
  { line: "PB MARCACAO;484;2509057" },
  { line: "RN MARCELINO VIEIRA;1745;2407302" },
  { line: "AL MARECHAL DEODORO;2793;2704708" },
  { line: "PB MARI;2083;2509107" },
  { line: "AL MARIBONDO;2795;2704807" },
  { line: "PB MARIZOPOLIS;486;2509156" },
  { line: "RN MARTINS;1747;2407401" },
  { line: "PB MASSARANDUBA;2085;2509206" },
  { line: "AL MATA GRANDE;2799;2705002" },
  { line: "PB MATARACA;2087;2509305" },
  { line: "PB MATINHAS;488;2509339" },
  { line: "PB MATO GROSSO;490;2509370" },
  { line: "AL MATRIZ DE CAMARAGIBE;2801;2705101" },
  { line: "PB MATUREIA;492;2509396" },
  { line: "RN MAXARANGUAPE;1749;2407500" },
  { line: "AL MESSIAS;2803;2705200" },
  { line: "RN MESSIAS TARGINO;1721;2407609" },
  { line: "AL MINADOR DO NEGRAO;2805;2705309" },
  { line: "PE MIRANDIBA;2485;2609303" },
  { line: "PB MOGEIRO;2089;2509404" },
  { line: "PB MONTADAS;2091;2509503" },
  { line: "RN MONTANHAS;1753;2407708" },
  { line: "RN MONTE ALEGRE;1755;2407807" },
  { line: "RN MONTE DAS GAMELEIRAS;1757;2407906" },
  { line: "PB MONTE HOREBE;2093;2509602" },
  { line: "PB MONTEIRO;2095;2509701" },
  { line: "AL MONTEIROPOLIS;2807;2705408" },
  { line: "PE MOREILANDIA;2585;2614303" },
  { line: "PE MORENO;2487;2609402" },
  { line: "RN MOSSORO;1759;2408003" },
  { line: "PB MULUNGU;2097;2509800" },
  { line: "AL MURICI;2809;2705507" },
  { line: "RN NATAL;1761;2408102" },
  { line: "PB NATUBA;2099;2509909" },
  { line: "PE NAZARE DA MATA;2489;2609501" },
  { line: "PB NAZAREZINHO;2101;2510006" },
  { line: "RN NISIA FLORESTA;1763;2408201" },
  { line: "RN NOVA CRUZ;1765;2408300" },
  { line: "PB NOVA FLORESTA;2103;2510105" },
  { line: "PB NOVA OLINDA;2105;2510204" },
  { line: "PB NOVA PALMEIRA;2107;2510303" },
  { line: "AL NOVO LINO;2811;2705606" },
  { line: "PB OLHO D'AGUA;2109;2510402" },
  { line: "AL OLHO D'AGUA DAS FLORES;2813;2705705" },
  { line: "RN OLHO D'AGUA DO BORGES;1767;2408409" },
  { line: "AL OLHO D'AGUA DO CASADO;2815;2705804" },
  { line: "AL OLHO D'AGUA GRANDE;2817;2705903" },
  { line: "PE OLINDA;2491;2609600" },
  { line: "PB OLIVEDOS;2111;2510501" },
  { line: "AL OLIVENCA;2819;2706000" },
  { line: "PE OROBO;2493;2609709" },
  { line: "PE OROCO;2495;2609808" },
  { line: "PE OURICURI;2497;2609907" },
  { line: "AL OURO BRANCO;2821;2706109" },
  { line: "RN OURO BRANCO;1769;2408508" },
  { line: "PB OURO VELHO;2113;2510600" },
  { line: "AL PALESTINA;2823;2706208" },
  { line: "PE PALMARES;2499;2610004" },
  { line: "AL PALMEIRA DOS INDIOS;2825;2706307" },
  { line: "PE PALMEIRINA;2501;2610103" },
  { line: "PE PANELAS;2503;2610202" },
  { line: "AL PAO DE ACUCAR;2827;2706406" },
  { line: "RN PARANA;1771;2408607" },
  { line: "PE PARANATAMA;2505;2610301" },
  { line: "PB PARARI;494;2510659" },
  { line: "RN PARAU;1773;2408706" },
  { line: "RN PARAZINHO;1775;2408805" },
  { line: "RN PARELHAS;1777;2408904" },
  { line: "AL PARICONHA;2645;2706422" },
  { line: "AL PARIPUEIRA;2641;2706448" },
  { line: "PE PARNAMIRIM;2507;2610400" },
  { line: "RN PARNAMIRIM;1779;2403251" },
  { line: "RN PASSA E FICA;1781;2409100" },
  { line: "PB PASSAGEM;2115;2510709" },
  { line: "RN PASSAGEM;1783;2409209" },
  { line: "PE PASSIRA;2509;2610509" },
  { line: "AL PASSO DE CAMARAGIBE;2829;2706505" },
  { line: "PB PATOS;2117;2510808" },
  { line: "RN PATU;1785;2409308" },
  { line: "RN PAU DOS FERROS;1787;2409407" },
  { line: "PE PAUDALHO;2511;2610608" },
  { line: "PB PAULISTA;2119;2510907" },
  { line: "PE PAULISTA;2513;2610707" },
  { line: "AL PAULO JACINTO;2831;2706604" },
  { line: "PE PEDRA;2515;2610806" },
  { line: "PB PEDRA BRANCA;2121;2511004" },
  { line: "RN PEDRA GRANDE;1789;2409506" },
  { line: "PB PEDRA LAVRADA;2123;2511103" },
  { line: "RN PEDRA PRETA;1791;2409605" },
  { line: "PB PEDRAS DE FOGO;2125;2511202" },
  { line: "RN PEDRO AVELINO;1793;2409704" },
  { line: "PB PEDRO REGIS;500;2512721" },
  { line: "RN PEDRO VELHO;1795;2409803" },
  { line: "RN PENDENCIAS;1797;2409902" },
  { line: "AL PENEDO;2833;2706703" },
  { line: "PE PESQUEIRA;2517;2610905" },
  { line: "PE PETROLANDIA;2519;2611002" },
  { line: "PE PETROLINA;2521;2611101" },
  { line: "AL PIACABUCU;2835;2706802" },
  { line: "PB PIANCO;2127;2511301" },
  { line: "PB PICUI;2129;2511400" },
  { line: "AL PILAR;2837;2706901" },
  { line: "PB PILAR;2131;2511509" },
  { line: "PB PILOES;2133;2511608" },
  { line: "RN PILOES;1799;2410009" },
  { line: "PB PILOEZINHOS;2135;2511707" },
  { line: "AL PINDOBA;2839;2707008" },
  { line: "AL PIRANHAS;2841;2707107" },
  { line: "PB PIRPIRITUBA;2137;2511806" },
  { line: "PB PITIMBU;2139;2511905" },
  { line: "PE POCAO;2523;2611200" },
  { line: "PB POCINHOS;2141;2512002" },
  { line: "RN POCO BRANCO;1801;2410108" },
  { line: "PB POCO DANTAS;496;2512036" },
  { line: "AL POCO DAS TRINCHEIRAS;2843;2707206" },
  { line: "PB POCO DE JOSE DE MOURA;498;2512077" },
  { line: "PB POMBAL;2143;2512101" },
  { line: "PE POMBOS;2525;2611309" },
  { line: "RN PORTALEGRE;1803;2410207" },
  { line: "AL PORTO CALVO;2845;2707305" },
  { line: "AL PORTO DE PEDRAS;2847;2707404" },
  { line: "RN PORTO DO MANGUE;426;2410256" },
  { line: "AL PORTO REAL DO COLEGIO;2849;2707503" },
  { line: "PB PRATA;2145;2512200" },
  { line: "PE PRIMAVERA;2527;2611408" },
  { line: "PB PRINCESA ISABEL;2147;2512309" },
  { line: "RN PUREZA;1807;2410405" },
  { line: "PB PUXINANA;2149;2512408" },
  { line: "AL QUEBRANGULO;2851;2707602" },
  { line: "PB QUEIMADAS;2151;2512507" },
  { line: "PE QUIPAPA;2529;2611507" },
  { line: "PE QUIXABA;2637;2611533" },
  { line: "PB QUIXABA;2153;2512606" },
  { line: "RN RAFAEL FERNANDES;1809;2410504" },
  { line: "RN RAFAEL GODEIRO;1893;2410603" },
  { line: "PE RECIFE;2531;2611606" },
  { line: "PB REMIGIO;2155;2512705" },
  { line: "PB RIACHAO;502;2512747" },
  { line: "PB RIACHAO DO BACAMARTE;504;2512754" },
  { line: "PB RIACHAO DO POCO;506;2512762" },
  { line: "RN RIACHO DA CRUZ;1811;2410702" },
  { line: "PE RIACHO DAS ALMAS;2533;2611705" },
  { line: "RN RIACHO DE SANTANA;1813;2410801" },
  { line: "PB RIACHO DE SANTO ANTONIO;508;2512788" },
  { line: "PB RIACHO DOS CAVALOS;2157;2512804" },
  { line: "RN RIACHUELO;1815;2410900" },
  { line: "PE RIBEIRAO;2535;2611804" },
  { line: "RN RIO DO FOGO;422;2408953" },
  { line: "PE RIO FORMOSO;2537;2611903" },
  { line: "AL RIO LARGO;2853;2707701" },
  { line: "PB RIO TINTO;2159;2512903" },
  { line: "RN RODOLFO FERNANDES;1817;2411007" },
  { line: "AL ROTEIRO;2855;2707800" },
  { line: "RN RUY BARBOSA;1819;2411106" },
  { line: "PE SAIRE;2539;2612000" },
  { line: "PB SALGADINHO;2161;2513000" },
  { line: "PE SALGADINHO;2541;2612109" },
  { line: "PB SALGADO DE SAO FELIX;2163;2513109" },
  { line: "PE SALGUEIRO;2543;2612208" },
  { line: "PE SALOA;2545;2612307" },
  { line: "PE SANHARO;2547;2612406" },
  { line: "PB SANTA CECILIA;510;2513158" },
  { line: "PB SANTA CRUZ;2165;2513208" },
  { line: "PE SANTA CRUZ;2297;2612455" },
  { line: "RN SANTA CRUZ;1823;2411205" },
  { line: "PE SANTA CRUZ DA BAIXA VERDE;2639;2612471" },
  { line: "PE SANTA CRUZ DO CAPIBARIBE;2549;2612505" },
  { line: "PE SANTA FILOMENA;556;2612554" },
  { line: "PB SANTA HELENA;2167;2513307" },
  { line: "PB SANTA INES;512;2513356" },
  { line: "PB SANTA LUZIA;2169;2513406" },
  { line: "AL SANTA LUZIA DO NORTE;2857;2707909" },
  { line: "RN SANTA MARIA;424;2409332" },
  { line: "PE SANTA MARIA DA BOA VISTA;2551;2612604" },
  { line: "PE SANTA MARIA DO CAMBUCA;2553;2612703" },
  { line: "PB SANTA RITA;2175;2513703" },
  { line: "PB SANTA TERESINHA;2177;2513802" },
  { line: "PE SANTA TEREZINHA;2555;2612802" },
  { line: "PB SANTANA DE MANGUEIRA;2171;2513505" },
  { line: "AL SANTANA DO IPANEMA;2859;2708006" },
  { line: "RN SANTANA DO MATOS;1827;2411403" },
  { line: "AL SANTANA DO MUNDAU;2861;2708105" },
  { line: "RN SANTANA DO SERIDO;1825;2411429" },
  { line: "PB SANTANA DOS GARROTES;2173;2513604" },
  { line: "PB SANTO ANDRE;516;2513851" },
  { line: "RN SANTO ANTONIO;1829;2411502" },
  { line: "PE SAO BENEDITO DO SUL;2557;2612901" },
  { line: "PB SAO BENTINHO;518;2513927" },
  { line: "PB SAO BENTO;2179;2513901" },
  { line: "RN SAO BENTO DO NORTE;1831;2411601" },
  { line: "RN SAO BENTO DO TRAIRI;1833;2411700" },
  { line: "PE SAO BENTO DO UNA;2559;2613008" },
  { line: "AL SAO BRAS;2863;2708204" },
  { line: "PE SAO CAITANO;2561;2613107" },
  { line: "PB SAO DOMINGOS;522;2513968" },
  { line: "PB SAO DOMINGOS DO CARIRI;520;2513943" },
  { line: "RN SAO FERNANDO;1835;2411809" },
  { line: "PB SAO FRANCISCO;524;2513984" },
  { line: "RN SAO FRANCISCO DO OESTE;1821;2411908" },
  { line: "RN SAO GONCALO DO AMARANTE;1837;2412005" },
  { line: "PE SAO JOAO;2563;2613206" },
  { line: "PB SAO JOAO DO CARIRI;2181;2514008" },
  { line: "PB SAO JOAO DO RIO DO PEIXE;1913;2500700" },
  { line: "RN SAO JOAO DO SABUGI;1839;2412104" },
  { line: "PB SAO JOAO DO TIGRE;2183;2514107" },
  { line: "PE SAO JOAQUIM DO MONTE;2565;2613305" },
  { line: "PE SAO JOSE DA COROA GRANDE;2567;2613404" },
  { line: "PB SAO JOSE DA LAGOA TAPADA;2185;2514206" },
  { line: "AL SAO JOSE DA LAJE;2865;2708303" },
  { line: "AL SAO JOSE DA TAPERA;2867;2708402" },
  { line: "PB SAO JOSE DE CAIANA;2187;2514305" },
  { line: "PB SAO JOSE DE ESPINHARAS;2189;2514404" },
  { line: "RN SAO JOSE DE MIPIBU;1841;2412203" },
  { line: "PB SAO JOSE DE PIRANHAS;2191;2514503" },
  { line: "PB SAO JOSE DE PRINCESA;528;2514552" },
  { line: "PE SAO JOSE DO BELMONTE;2569;2613503" },
  { line: "PB SAO JOSE DO BONFIM;2193;2514602" },
  { line: "PB SAO JOSE DO BREJO DO CRUZ;530;2514651" },
  { line: "RN SAO JOSE DO CAMPESTRE;1843;2412302" },
  { line: "PE SAO JOSE DO EGITO;2571;2613602" },
  { line: "PB SAO JOSE DO SABUGI;2195;2514701" },
  { line: "RN SAO JOSE DO SERIDO;1845;2412401" },
  { line: "PB SAO JOSE DOS CORDEIROS;2197;2514800" },
  { line: "PB SAO JOSE DOS RAMOS;526;2514453" },
  { line: "PE SAO LOURENCO DA MATA;2573;2613701" },
  { line: "AL SAO LUIS DO QUITUNDE;2869;2708501" },
  { line: "PB SAO MAMEDE;2199;2514909" },
  { line: "RN SAO MIGUEL;1847;2412500" },
  { line: "PB SAO MIGUEL DE TAIPU;2201;2515005" },
  { line: "RN SAO MIGUEL DO GOSTOSO;430;2412559" },
  { line: "AL SAO MIGUEL DOS CAMPOS;2871;2708600" },
  { line: "AL SAO MIGUEL DOS MILAGRES;2873;2708709" },
  { line: "RN SAO PAULO DO POTENGI;1849;2412609" },
  { line: "RN SAO PEDRO;1851;2412708" },
  { line: "RN SAO RAFAEL;1853;2412807" },
  { line: "AL SAO SEBASTIAO;2875;2708808" },
  { line: "PB SAO SEBASTIAO DE LAGOA DE ROCA;2203;2515104" },
  { line: "PB SAO SEBASTIAO DO UMBUZEIRO;2205;2515203" },
  { line: "RN SAO TOME;1855;2412906" },
  { line: "RN SAO VICENTE;1857;2413003" },
  { line: "PB SAO VICENTE DO SERIDO;2209;2515401" },
  { line: "PE SAO VICENTE FERRER;2575;2613800" },
  { line: "PB SAPE;2207;2515302" },
  { line: "AL SATUBA;2877;2708907" },
  { line: "RN SENADOR ELOI DE SOUZA;1859;2413102" },
  { line: "RN SENADOR GEORGINO AVELINO;1861;2413201" },
  { line: "AL SENADOR RUI PALMEIRA;2891;2708956" },
  { line: "PB SERRA BRANCA;2211;2515500" },
  { line: "RN SERRA CAIADA;1805;2410306" },
  { line: "PB SERRA DA RAIZ;2213;2515609" },
  { line: "RN SERRA DE SAO BENTO;1863;2413300" },
  { line: "RN SERRA DO MEL;1927;2413359" },
  { line: "PB SERRA GRANDE;2215;2515708" },
  { line: "RN SERRA NEGRA DO NORTE;1865;2413409" },
  { line: "PB SERRA REDONDA;2217;2515807" },
  { line: "PE SERRA TALHADA;2577;2613909" },
  { line: "PB SERRARIA;2219;2515906" },
  { line: "RN SERRINHA;1867;2413508" },
  { line: "RN SERRINHA DOS PINTOS;432;2413557" },
  { line: "PE SERRITA;2579;2614006" },
  { line: "PE SERTANIA;2581;2614105" },
  { line: "PB SERTAOZINHO;532;2515930" },
  { line: "RN SEVERIANO MELO;1869;2413607" },
  { line: "PE SIRINHAEM;2583;2614204" },
  { line: "RN SITIO NOVO;1871;2413706" },
  { line: "PB SOBRADO;534;2515971" },
  { line: "PB SOLANEA;2221;2516003" },
  { line: "PB SOLEDADE;2223;2516102" },
  { line: "PE SOLIDAO;2587;2614402" },
  { line: "PB SOSSEGO;536;2516151" },
  { line: "PB SOUSA;2225;2516201" },
  { line: "PB SUME;2227;2516300" },
  { line: "PE SURUBIM;2589;2614501" },
  { line: "PE TABIRA;2591;2614600" },
  { line: "RN TABOLEIRO GRANDE;1873;2413805" },
  { line: "PE TACAIMBO;2593;2614709" },
  { line: "PE TACARATU;2595;2614808" },
  { line: "PB TACIMA;2229;2516409" },
  { line: "RN TAIPU;1875;2413904" },
  { line: "PE TAMANDARE;558;2614857" },
  { line: "RN TANGARA;1877;2414001" },
  { line: "AL TANQUE D'ARCA;2879;2709004" },
  { line: "PB TAPEROA;2231;2516508" },
  { line: "AL TAQUARANA;2881;2709103" },
  { line: "PE TAQUARITINGA DO NORTE;2599;2615003" },
  { line: "PB TAVARES;2233;2516607" },
  { line: "PB TEIXEIRA;2235;2516706" },
  { line: "RN TENENTE ANANIAS;1879;2414100" },
  { line: "RN TENENTE LAURENTINO CRUZ;434;2414159" },
  { line: "PB TENORIO;538;2516755" },
  { line: "AL TEOTONIO VILELA;971;2709152" },
  { line: "PE TEREZINHA;2601;2615102" },
  { line: "PE TERRA NOVA;2603;2615201" },
  { line: "RN TIBAU;428;2411056" },
  { line: "RN TIBAU DO SUL;1881;2414209" },
  { line: "PE TIMBAUBA;2605;2615300" },
  { line: "RN TIMBAUBA DOS BATISTAS;1883;2414308" },
  { line: "PE TORITAMA;2607;2615409" },
  { line: "RN TOUROS;1885;2414407" },
  { line: "PE TRACUNHAEM;2609;2615508" },
  { line: "AL TRAIPU;2883;2709202" },
  { line: "PE TRINDADE;2611;2615607" },
  { line: "PB TRIUNFO;2237;2516805" },
  { line: "PE TRIUNFO;2613;2615706" },
  { line: "RN TRIUNFO POTIGUAR;436;2414456" },
  { line: "PE TUPANATINGA;2615;2615805" },
  { line: "PE TUPARETAMA;2617;2615904" },
  { line: "PB UIRAUNA;2239;2516904" },
  { line: "RN UMARIZAL;1887;2414506" },
  { line: "PB UMBUZEIRO;2241;2517001" },
  { line: "AL UNIAO DOS PALMARES;2885;2709301" },
  { line: "RN UPANEMA;1889;2414605" },
  { line: "PB VARZEA;2243;2517100" },
  { line: "RN VARZEA;1891;2414704" },
  { line: "RN VENHA-VER;438;2414753" },
  { line: "PE VENTUROSA;2619;2616001" },
  { line: "RN VERA CRUZ;1895;2414803" },
  { line: "PE VERDEJANTE;2621;2616100" },
  { line: "PE VERTENTE DO LERIO;2291;2616183" },
  { line: "PE VERTENTES;2623;2616209" },
  { line: "PE VICENCIA;2625;2616308" },
  { line: "AL VICOSA;2887;2709400" },
  { line: "RN VICOSA;1897;2414902" },
  { line: "PB VIEIROPOLIS;540;2517209" },
  { line: "RN VILA FLOR;1899;2415008" },
  { line: "PB VISTA SERRANA;2011;2505501" },
  { line: "PE VITORIA DE SANTO ANTAO;2627;2616407" },
  { line: "PE XEXEU;2293;2616506" },
  { line: "PB ZABELE;542;2517407" },
  { line: "BA ABAIRA;3301;2900108" },
  { line: "BA ABARE;3303;2900207" },
  { line: "BA ACAJUTIBA;3305;2900306" },
  { line: "BA ADUSTINA;3253;2900355" },
  { line: "BA AGUA FRIA;3307;2900405" },
  { line: "BA AIQUARA;3311;2900603" },
  { line: "BA ALAGOINHAS;3313;2900702" },
  { line: "BA ALCOBACA;3315;2900801" },
  { line: "BA ALMADINA;3317;2900900" },
  { line: "BA AMARGOSA;3319;2901007" },
  { line: "BA AMELIA RODRIGUES;3321;2901106" },
  { line: "BA AMERICA DOURADA;3071;2901155" },
  { line: "SE AMPARO DE SAO FRANCISCO;3101;2800100" },
  { line: "BA ANAGE;3323;2901205" },
  { line: "BA ANDARAI;3325;2901304" },
  { line: "BA ANDORINHA;3255;2901353" },
  { line: "BA ANGICAL;3327;2901403" },
  { line: "BA ANGUERA;3329;2901502" },
  { line: "BA ANTAS;3331;2901601" },
  { line: "BA ANTONIO CARDOSO;3333;2901700" },
  { line: "BA ANTONIO GONCALVES;3335;2901809" },
  { line: "BA APORA;3337;2901908" },
  { line: "BA APUAREMA;3257;2901957" },
  { line: "SE AQUIDABA;3103;2800209" },
  { line: "SE ARACAJU;3105;2800308" },
  { line: "BA ARACAS;3259;2902054" },
  { line: "BA ARACATU;3339;2902005" },
  { line: "BA ARACI;3341;2902104" },
  { line: "BA ARAMARI;3343;2902203" },
  { line: "BA ARATACA;3073;2902252" },
  { line: "BA ARATUIPE;3345;2902302" },
  { line: "SE ARAUA;3107;2800407" },
  { line: "SE AREIA BRANCA;3109;2800506" },
  { line: "BA AURELINO LEAL;3347;2902401" },
  { line: "BA BAIANOPOLIS;3349;2902500" },
  { line: "BA BAIXA GRANDE;3351;2902609" },
  { line: "BA BANZAE;3261;2902658" },
  { line: "BA BARRA;3353;2902708" },
  { line: "BA BARRA DA ESTIVA;3355;2902807" },
  { line: "BA BARRA DO CHOCA;3357;2902906" },
  { line: "BA BARRA DO MENDES;3359;2903003" },
  { line: "BA BARRA DO ROCHA;3361;2903102" },
  { line: "SE BARRA DOS COQUEIROS;3111;2800605" },
  { line: "BA BARREIRAS;3363;2903201" },
  { line: "BA BARRO ALTO;3075;2903235" },
  { line: "BA BARRO PRETO;3365;2903300" },
  { line: "BA BARROCAS;1110;2903276" },
  { line: "BA BELMONTE;3367;2903409" },
  { line: "BA BELO CAMPO;3369;2903508" },
  { line: "BA BIRITINGA;3371;2903607" },
  { line: "BA BOA NOVA;3373;2903706" },
  { line: "BA BOA VISTA DO TUPIM;3375;2903805" },
  { line: "BA BOM JESUS DA LAPA;3377;2903904" },
  { line: "BA BOM JESUS DA SERRA;3263;2903953" },
  { line: "BA BONINAL;3379;2904001" },
  { line: "BA BONITO;3265;2904050" },
  { line: "SE BOQUIM;3115;2800670" },
  { line: "BA BOQUIRA;3381;2904100" },
  { line: "BA BOTUPORA;3383;2904209" },
  { line: "SE BREJO GRANDE;3113;2800704" },
  { line: "BA BREJOES;3385;2904308" },
  { line: "BA BREJOLANDIA;3387;2904407" },
  { line: "BA BROTAS DE MACAUBAS;3389;2904506" },
  { line: "BA BRUMADO;3391;2904605" },
  { line: "BA BUERAREMA;3393;2904704" },
  { line: "BA BURITIRAMA;3079;2904753" },
  { line: "BA CAATIBA;3395;2904803" },
  { line: "BA CABACEIRAS DO PARAGUACU;3267;2904852" },
  { line: "BA CACHOEIRA;3397;2904902" },
  { line: "BA CACULE;3399;2905008" },
  { line: "BA CAEM;3401;2905107" },
  { line: "BA CAETANOS;3269;2905156" },
  { line: "BA CAETITE;3403;2905206" },
  { line: "BA CAFARNAUM;3405;2905305" },
  { line: "BA CAIRU;3407;2905404" },
  { line: "BA CALDEIRAO GRANDE;3409;2905503" },
  { line: "BA CAMACAN;3411;2905602" },
  { line: "BA CAMACARI;3413;2905701" },
  { line: "BA CAMAMU;3415;2905800" },
  { line: "BA CAMPO ALEGRE DE LOURDES;3417;2905909" },
  { line: "SE CAMPO DO BRITO;3119;2801009" },
  { line: "BA CAMPO FORMOSO;3419;2906006" },
  { line: "BA CANAPOLIS;3421;2906105" },
  { line: "BA CANARANA;3423;2906204" },
  { line: "BA CANAVIEIRAS;3425;2906303" },
  { line: "BA CANDEAL;3427;2906402" },
  { line: "BA CANDEIAS;3429;2906501" },
  { line: "BA CANDIBA;3431;2906600" },
  { line: "BA CANDIDO SALES;3433;2906709" },
  { line: "SE CANHOBA;3121;2801108" },
  { line: "SE CANINDE DE SAO FRANCISCO;3123;2801207" },
  { line: "BA CANSANCAO;3435;2906808" },
  { line: "BA CANUDOS;3085;2906824" },
  { line: "SE CAPELA;3125;2801306" },
  { line: "BA CAPELA DO ALTO ALEGRE;3081;2906857" },
  { line: "BA CAPIM GROSSO;3083;2906873" },
  { line: "BA CARAIBAS;3271;2906899" },
  { line: "BA CARAVELAS;3437;2906907" },
  { line: "BA CARDEAL DA SILVA;3439;2907004" },
  { line: "BA CARINHANHA;3441;2907103" },
  { line: "SE CARIRA;3127;2801405" },
  { line: "SE CARMOPOLIS;3129;2801504" },
  { line: "BA CASA NOVA;3443;2907202" },
  { line: "BA CASTRO ALVES;3445;2907301" },
  { line: "BA CATOLANDIA;3447;2907400" },
  { line: "BA CATU;3449;2907509" },
  { line: "BA CATURAMA;3273;2907558" },
  { line: "SE CEDRO DE SAO JOAO;3131;2801603" },
  { line: "BA CENTRAL;3451;2907608" },
  { line: "BA CHORROCHO;3453;2907707" },
  { line: "BA CICERO DANTAS;3455;2907806" },
  { line: "BA CIPO;3457;2907905" },
  { line: "BA COARACI;3459;2908002" },
  { line: "BA COCOS;3461;2908101" },
  { line: "BA CONCEICAO DA FEIRA;3463;2908200" },
  { line: "BA CONCEICAO DO ALMEIDA;3465;2908309" },
  { line: "BA CONCEICAO DO COITE;3467;2908408" },
  { line: "BA CONCEICAO DO JACUIPE;3469;2908507" },
  { line: "BA CONDE;3471;2908606" },
  { line: "BA CONDEUBA;3473;2908705" },
  { line: "BA CONTENDAS DO SINCORA;3475;2908804" },
  { line: "BA CORACAO DE MARIA;3477;2908903" },
  { line: "BA CORDEIROS;3479;2909000" },
  { line: "BA CORIBE;3481;2909109" },
  { line: "BA CORONEL JOAO SA;3483;2909208" },
  { line: "BA CORRENTINA;3485;2909307" },
  { line: "BA COTEGIPE;3487;2909406" },
  { line: "BA CRAVOLANDIA;3489;2909505" },
  { line: "BA CRISOPOLIS;3491;2909604" },
  { line: "SE CRISTINAPOLIS;3133;2801702" },
  { line: "BA CRISTOPOLIS;3493;2909703" },
  { line: "BA CRUZ DAS ALMAS;3495;2909802" },
  { line: "SE CUMBE;3137;2801900" },
  { line: "BA CURACA;3497;2909901" },
  { line: "BA DARIO MEIRA;3499;2910008" },
  { line: "BA DIAS D'AVILA;3087;2910057" },
  { line: "SE DIVINA PASTORA;3139;2802007" },
  { line: "BA DOM BASILIO;3501;2910107" },
  { line: "BA DOM MACEDO COSTA;3503;2910206" },
  { line: "BA ELISIO MEDRADO;3505;2910305" },
  { line: "BA ENCRUZILHADA;3507;2910404" },
  { line: "BA ENTRE RIOS;3509;2910503" },
  { line: "BA ERICO CARDOSO;3309;2900504" },
  { line: "BA ESPLANADA;3511;2910602" },
  { line: "SE ESTANCIA;3141;2802106" },
  { line: "BA EUCLIDES DA CUNHA;3513;2910701" },
  { line: "BA EUNAPOLIS;3117;2910727" },
  { line: "BA FATIMA;3089;2910750" },
  { line: "BA FEIRA DA MATA;3275;2910776" },
  { line: "BA FEIRA DE SANTANA;3515;2910800" },
  { line: "SE FEIRA NOVA;3143;2802205" },
  { line: "BA FILADELFIA;3091;2910859" },
  { line: "BA FIRMINO ALVES;3517;2910909" },
  { line: "BA FLORESTA AZUL;3519;2911006" },
  { line: "BA FORMOSA DO RIO PRETO;3521;2911105" },
  { line: "SE FREI PAULO;3145;2802304" },
  { line: "BA GANDU;3523;2911204" },
  { line: "SE GARARU;3149;2802403" },
  { line: "BA GAVIAO;3093;2911253" },
  { line: "SE GENERAL MAYNARD;3147;2802502" },
  { line: "BA GENTIO DO OURO;3525;2911303" },
  { line: "BA GLORIA;3527;2911402" },
  { line: "BA GONGOGI;3529;2911501" },
  { line: "BA GOVERNADOR MANGABEIRA;3531;2911600" },
  { line: "SE GRACCHO CARDOSO;3151;2802601" },
  { line: "BA GUAJERU;3095;2911659" },
  { line: "BA GUANAMBI;3533;2911709" },
  { line: "BA GUARATINGA;3535;2911808" },
  { line: "BA HELIOPOLIS;3097;2911857" },
  { line: "BA IACU;3537;2911907" },
  { line: "BA IBIASSUCE;3539;2912004" },
  { line: "BA IBICARAI;3541;2912103" },
  { line: "BA IBICOARA;3543;2912202" },
  { line: "BA IBICUI;3545;2912301" },
  { line: "BA IBIPEBA;3547;2912400" },
  { line: "BA IBIPITANGA;3551;2912509" },
  { line: "BA IBIQUERA;3553;2912608" },
  { line: "BA IBIRAPITANGA;3555;2912707" },
  { line: "BA IBIRAPUA;3557;2912806" },
  { line: "BA IBIRATAIA;3559;2912905" },
  { line: "BA IBITIARA;3561;2913002" },
  { line: "BA IBITITA;3563;2913101" },
  { line: "BA IBOTIRAMA;3565;2913200" },
  { line: "BA ICHU;3567;2913309" },
  { line: "BA IGAPORA;3569;2913408" },
  { line: "BA IGRAPIUNA;3277;2913457" },
  { line: "BA IGUAI;3571;2913507" },
  { line: "SE ILHA DAS FLORES;3153;2802700" },
  { line: "BA ILHEUS;3573;2913606" },
  { line: "SE INDIAROBA;3155;2802809" },
  { line: "BA INHAMBUPE;3575;2913705" },
  { line: "BA IPECAETA;3577;2913804" },
  { line: "BA IPIAU;3579;2913903" },
  { line: "BA IPIRA;3581;2914000" },
  { line: "BA IPUPIARA;3583;2914109" },
  { line: "BA IRAJUBA;3585;2914208" },
  { line: "BA IRAMAIA;3587;2914307" },
  { line: "BA IRAQUARA;3589;2914406" },
  { line: "BA IRARA;3591;2914505" },
  { line: "BA IRECE;3593;2914604" },
  { line: "SE ITABAIANA;3157;2802908" },
  { line: "SE ITABAIANINHA;3159;2803005" },
  { line: "BA ITABELA;3279;2914653" },
  { line: "BA ITABERABA;3595;2914703" },
  { line: "SE ITABI;3161;2803104" },
  { line: "BA ITABUNA;3597;2914802" },
  { line: "BA ITACARE;3599;2914901" },
  { line: "BA ITAETE;3601;2915007" },
  { line: "BA ITAGI;3603;2915106" },
  { line: "BA ITAGIBA;3605;2915205" },
  { line: "BA ITAGIMIRIM;3607;2915304" },
  { line: "BA ITAGUACU DA BAHIA;3281;2915353" },
  { line: "BA ITAJU DO COLONIA;3609;2915403" },
  { line: "BA ITAJUIPE;3611;2915502" },
  { line: "BA ITAMARAJU;3613;2915601" },
  { line: "BA ITAMARI;3615;2915700" },
  { line: "BA ITAMBE;3617;2915809" },
  { line: "BA ITANAGRA;3619;2915908" },
  { line: "BA ITANHEM;3621;2916005" },
  { line: "BA ITAPARICA;3623;2916104" },
  { line: "BA ITAPE;3625;2916203" },
  { line: "BA ITAPEBI;3627;2916302" },
  { line: "BA ITAPETINGA;3629;2916401" },
  { line: "BA ITAPICURU;3631;2916500" },
  { line: "BA ITAPITANGA;3633;2916609" },
  { line: "SE ITAPORANGA D'AJUDA;3163;2803203" },
  { line: "BA ITAQUARA;3635;2916708" },
  { line: "BA ITARANTIM;3637;2916807" },
  { line: "BA ITATIM;3283;2916856" },
  { line: "BA ITIRUCU;3639;2916906" },
  { line: "BA ITIUBA;3641;2917003" },
  { line: "BA ITORORO;3643;2917102" },
  { line: "BA ITUACU;3645;2917201" },
  { line: "BA ITUBERA;3647;2917300" },
  { line: "BA IUIU;3285;2917334" },
  { line: "BA JABORANDI;9859;2917359" },
  { line: "BA JACARACI;3649;2917409" },
  { line: "BA JACOBINA;3651;2917508" },
  { line: "BA JAGUAQUARA;3653;2917607" },
  { line: "BA JAGUARARI;3655;2917706" },
  { line: "BA JAGUARIPE;3657;2917805" },
  { line: "BA JANDAIRA;3659;2917904" },
  { line: "SE JAPARATUBA;3165;2803302" },
  { line: "SE JAPOATA;3167;2803401" },
  { line: "BA JEQUIE;3661;2918001" },
  { line: "BA JEREMOABO;3663;2918100" },
  { line: "BA JIQUIRICA;3665;2918209" },
  { line: "BA JITAUNA;3667;2918308" },
  { line: "BA JOAO DOURADO;3099;2918357" },
  { line: "BA JUAZEIRO;3669;2918407" },
  { line: "BA JUCURUCU;3287;2918456" },
  { line: "BA JUSSARA;3671;2918506" },
  { line: "BA JUSSARI;3069;2918555" },
  { line: "BA JUSSIAPE;3673;2918605" },
  { line: "BA LAFAIETE COUTINHO;3675;2918704" },
  { line: "SE LAGARTO;3169;2803500" },
  { line: "BA LAGOA REAL;3289;2918753" },
  { line: "BA LAJE;3677;2918803" },
  { line: "BA LAJEDAO;3679;2918902" },
  { line: "BA LAJEDINHO;3681;2919009" },
  { line: "BA LAJEDO DO TABOCAL;3291;2919058" },
  { line: "BA LAMARAO;3683;2919108" },
  { line: "BA LAPAO;3973;2919157" },
  { line: "SE LARANJEIRAS;3171;2803609" },
  { line: "BA LAURO DE FREITAS;3685;2919207" },
  { line: "BA LENCOIS;3687;2919306" },
  { line: "BA LICINIO DE ALMEIDA;3689;2919405" },
  { line: "BA LIVRAMENTO DE NOSSA SENHORA;3691;2919504" },
  { line: "BA LUIS EDUARDO MAGALHAES;1112;2919553" },
  { line: "BA MACAJUBA;3693;2919603" },
  { line: "SE MACAMBIRA;3173;2803708" },
  { line: "BA MACARANI;3695;2919702" },
  { line: "BA MACAUBAS;3697;2919801" },
  { line: "BA MACURURE;3699;2919900" },
  { line: "BA MADRE DE DEUS;3293;2919926" },
  { line: "BA MAETINGA;3975;2919959" },
  { line: "BA MAIQUINIQUE;3701;2920007" },
  { line: "BA MAIRI;3703;2920106" },
  { line: "BA MALHADA;3705;2920205" },
  { line: "BA MALHADA DE PEDRAS;3707;2920304" },
  { line: "SE MALHADA DOS BOIS;3175;2803807" },
  { line: "SE MALHADOR;3177;2803906" },
  { line: "BA MANOEL VITORINO;3709;2920403" },
  { line: "BA MANSIDAO;3977;2920452" },
  { line: "BA MARACAS;3711;2920502" },
  { line: "BA MARAGOGIPE;3713;2920601" },
  { line: "BA MARAU;3715;2920700" },
  { line: "BA MARCIONILIO SOUZA;3717;2920809" },
  { line: "SE MARUIM;3179;2804003" },
  { line: "BA MASCOTE;3719;2920908" },
  { line: "BA MATA DE SAO JOAO;3721;2921005" },
  { line: "BA MATINA;3295;2921054" },
  { line: "BA MEDEIROS NETO;3723;2921104" },
  { line: "BA MIGUEL CALMON;3725;2921203" },
  { line: "BA MILAGRES;3727;2921302" },
  { line: "BA MIRANGABA;3729;2921401" },
  { line: "BA MIRANTE;3297;2921450" },
  { line: "SE MOITA BONITA;3181;2804102" },
  { line: "SE MONTE ALEGRE DE SERGIPE;3183;2804201" },
  { line: "BA MONTE SANTO;3731;2921500" },
  { line: "BA MORPARA;3733;2921609" },
  { line: "BA MORRO DO CHAPEU;3735;2921708" },
  { line: "BA MORTUGABA;3737;2921807" },
  { line: "BA MUCUGE;3739;2921906" },
  { line: "BA MUCURI;3741;2922003" },
  { line: "BA MULUNGU DO MORRO;3299;2922052" },
  { line: "BA MUNDO NOVO;3743;2922102" },
  { line: "BA MUNIZ FERREIRA;3745;2922201" },
  { line: "BA MUQUEM DE SAO FRANCISCO;3005;2922250" },
  { line: "SE MURIBECA;3185;2804300" },
  { line: "BA MURITIBA;3747;2922300" },
  { line: "BA MUTUIPE;3749;2922409" },
  { line: "BA NAZARE;3751;2922508" },
  { line: "SE NEOPOLIS;3187;2804409" },
  { line: "BA NILO PECANHA;3753;2922607" },
  { line: "BA NORDESTINA;3979;2922656" },
  { line: "SE NOSSA SENHORA APARECIDA;3135;2804458" },
  { line: "SE NOSSA SENHORA DA GLORIA;3189;2804508" },
  { line: "SE NOSSA SENHORA DAS DORES;3191;2804607" },
  { line: "SE NOSSA SENHORA DE LOURDES;3193;2804706" },
  { line: "SE NOSSA SENHORA DO SOCORRO;3195;2804805" },
  { line: "BA NOVA CANAA;3755;2922706" },
  { line: "BA NOVA FATIMA;3007;2922730" },
  { line: "BA NOVA IBIA;3009;2922755" },
  { line: "BA NOVA ITARANA;3757;2922805" },
  { line: "BA NOVA REDENCAO;3011;2922854" },
  { line: "BA NOVA SOURE;3759;2922904" },
  { line: "BA NOVA VICOSA;3761;2923001" },
  { line: "BA NOVO HORIZONTE;3013;2923035" },
  { line: "BA NOVO TRIUNFO;3015;2923050" },
  { line: "BA OLINDINA;3763;2923100" },
  { line: "BA OLIVEIRA DOS BREJINHOS;3765;2923209" },
  { line: "BA OURICANGAS;3767;2923308" },
  { line: "BA OUROLANDIA;3017;2923357" },
  { line: "SE PACATUBA;3197;2804904" },
  { line: "BA PALMAS DE MONTE ALTO;3769;2923407" },
  { line: "BA PALMEIRAS;3771;2923506" },
  { line: "BA PARAMIRIM;3773;2923605" },
  { line: "BA PARATINGA;3775;2923704" },
  { line: "BA PARIPIRANGA;3777;2923803" },
  { line: "BA PAU BRASIL;3779;2923902" },
  { line: "BA PAULO AFONSO;3781;2924009" },
  { line: "BA PE DE SERRA;3981;2924058" },
  { line: "SE PEDRA MOLE;3199;2805000" },
  { line: "BA PEDRAO;3783;2924108" },
  { line: "SE PEDRINHAS;3201;2805109" },
  { line: "BA PEDRO ALEXANDRE;3785;2924207" },
  { line: "BA PIATA;3787;2924306" },
  { line: "BA PILAO ARCADO;3789;2924405" },
  { line: "BA PINDAI;3791;2924504" },
  { line: "BA PINDOBACU;3793;2924603" },
  { line: "SE PINHAO;3203;2805208" },
  { line: "BA PINTADAS;3983;2924652" },
  { line: "BA PIRAI DO NORTE;3019;2924678" },
  { line: "SE PIRAMBU;3205;2805307" },
  { line: "BA PIRIPA;3795;2924702" },
  { line: "BA PIRITIBA;3797;2924801" },
  { line: "BA PLANALTINO;3799;2924900" },
  { line: "BA PLANALTO;3801;2925006" },
  { line: "SE POCO REDONDO;3207;2805406" },
  { line: "SE POCO VERDE;3209;2805505" },
  { line: "BA POCOES;3803;2925105" },
  { line: "BA POJUCA;3805;2925204" },
  { line: "BA PONTO NOVO;3021;2925253" },
  { line: "SE PORTO DA FOLHA;3211;2805604" },
  { line: "BA PORTO SEGURO;3807;2925303" },
  { line: "BA POTIRAGUA;3809;2925402" },
  { line: "BA PRADO;3811;2925501" },
  { line: "BA PRESIDENTE DUTRA;3813;2925600" },
  { line: "BA PRESIDENTE JANIO QUADROS;3815;2925709" },
  { line: "BA PRESIDENTE TANCREDO NEVES;3023;2925758" },
  { line: "SE PROPRIA;3213;2805703" },
  { line: "BA QUEIMADAS;3817;2925808" },
  { line: "BA QUIJINGUE;3819;2925907" },
  { line: "BA QUIXABEIRA;3025;2925931" },
  { line: "BA RAFAEL JAMBEIRO;3985;2925956" },
  { line: "BA REMANSO;3821;2926004" },
  { line: "BA RETIROLANDIA;3823;2926103" },
  { line: "BA RIACHAO DAS NEVES;3825;2926202" },
  { line: "SE RIACHAO DO DANTAS;3215;2805802" },
  { line: "BA RIACHAO DO JACUIPE;3827;2926301" },
  { line: "BA RIACHO DE SANTANA;3829;2926400" },
  { line: "SE RIACHUELO;3217;2805901" },
  { line: "BA RIBEIRA DO AMPARO;3831;2926509" },
  { line: "BA RIBEIRA DO POMBAL;3833;2926608" },
  { line: "BA RIBEIRAO DO LARGO;3027;2926657" },
  { line: "SE RIBEIROPOLIS;3219;2806008" },
  { line: "BA RIO DE CONTAS;3835;2926707" },
  { line: "BA RIO DO ANTONIO;3837;2926806" },
  { line: "BA RIO DO PIRES;3839;2926905" },
  { line: "BA RIO REAL;3841;2927002" },
  { line: "BA RODELAS;3843;2927101" },
  { line: "SE ROSARIO DO CATETE;3221;2806107" },
  { line: "BA RUY BARBOSA;3845;2927200" },
  { line: "SE SALGADO;3223;2806206" },
  { line: "BA SALINAS DA MARGARIDA;3847;2927309" },
  { line: "BA SALVADOR;3849;2927408" },
  { line: "BA SANTA BARBARA;3851;2927507" },
  { line: "BA SANTA BRIGIDA;3853;2927606" },
  { line: "BA SANTA CRUZ CABRALIA;3855;2927705" },
  { line: "BA SANTA CRUZ DA VITORIA;3857;2927804" },
  { line: "BA SANTA INES;3859;2927903" },
  { line: "BA SANTA LUZIA;3987;2928059" },
  { line: "SE SANTA LUZIA DO ITANHY;3225;2806305" },
  { line: "BA SANTA MARIA DA VITORIA;3863;2928109" },
  { line: "BA SANTA RITA DE CASSIA;3549;2928406" },
  { line: "SE SANTA ROSA DE LIMA;3229;2806503" },
  { line: "BA SANTA TERESINHA;3869;2928505" },
  { line: "BA SANTALUZ;3861;2928000" },
  { line: "BA SANTANA;3865;2928208" },
  { line: "SE SANTANA DO SAO FRANCISCO;2647;2806404" },
  { line: "BA SANTANOPOLIS;3867;2928307" },
  { line: "BA SANTO AMARO;3871;2928604" },
  { line: "SE SANTO AMARO DAS BROTAS;3231;2806602" },
  { line: "BA SANTO ANTONIO DE JESUS;3873;2928703" },
  { line: "BA SANTO ESTEVAO;3875;2928802" },
  { line: "SE SAO CRISTOVAO;3233;2806701" },
  { line: "BA SAO DESIDERIO;3877;2928901" },
  { line: "BA SAO DOMINGOS;3029;2928950" },
  { line: "SE SAO DOMINGOS;3235;2806800" },
  { line: "BA SAO FELIPE;3881;2929107" },
  { line: "BA SAO FELIX;3879;2929008" },
  { line: "BA SAO FELIX DO CORIBE;3031;2929057" },
  { line: "SE SAO FRANCISCO;3237;2806909" },
  { line: "BA SAO FRANCISCO DO CONDE;3883;2929206" },
  { line: "BA SAO GABRIEL;3989;2929255" },
  { line: "BA SAO GONCALO DOS CAMPOS;3885;2929305" },
  { line: "BA SAO JOSE DA VITORIA;3035;2929354" },
  { line: "BA SAO JOSE DO JACUIPE;3033;2929370" },
  { line: "BA SAO MIGUEL DAS MATAS;3887;2929404" },
  { line: "SE SAO MIGUEL DO ALEIXO;3239;2807006" },
  { line: "BA SAO SEBASTIAO DO PASSE;3889;2929503" },
  { line: "BA SAPEACU;3891;2929602" },
  { line: "BA SATIRO DIAS;3893;2929701" },
  { line: "BA SAUBARA;3037;2929750" },
  { line: "BA SAUDE;3895;2929800" },
  { line: "BA SEABRA;3897;2929909" },
  { line: "BA SEBASTIAO LARANJEIRAS;3899;2930006" },
  { line: "BA SENHOR DO BONFIM;3901;2930105" },
  { line: "BA SENTO SE;3903;2930204" },
  { line: "BA SERRA DO RAMALHO;3039;2930154" },
  { line: "BA SERRA DOURADA;3905;2930303" },
  { line: "BA SERRA PRETA;3907;2930402" },
  { line: "BA SERRINHA;3909;2930501" },
  { line: "BA SERROLANDIA;3911;2930600" },
  { line: "SE SIMAO DIAS;3241;2807105" },
  { line: "BA SIMOES FILHO;3913;2930709" },
  { line: "SE SIRIRI;3243;2807204" },
  { line: "BA SITIO DO MATO;3041;2930758" },
  { line: "BA SITIO DO QUINTO;3043;2930766" },
  { line: "BA SOBRADINHO;3045;2930774" },
  { line: "BA SOUTO SOARES;3915;2930808" },
  { line: "BA TABOCAS DO BREJO VELHO;3917;2930907" },
  { line: "BA TANHACU;3919;2931004" },
  { line: "BA TANQUE NOVO;3991;2931053" },
  { line: "BA TANQUINHO;3921;2931103" },
  { line: "BA TAPEROA;3923;2931202" },
  { line: "BA TAPIRAMUTA;3925;2931301" },
  { line: "BA TEIXEIRA DE FREITAS;3993;2931350" },
  { line: "SE TELHA;3245;2807303" },
  { line: "BA TEODORO SAMPAIO;3927;2931400" },
  { line: "BA TEOFILANDIA;3929;2931509" },
  { line: "BA TEOLANDIA;3931;2931608" },
  { line: "BA TERRA NOVA;3933;2931707" },
  { line: "SE TOBIAS BARRETO;3247;2807402" },
  { line: "SE TOMAR DO GERU;3249;2807501" },
  { line: "BA TREMEDAL;3935;2931806" },
  { line: "BA TUCANO;3937;2931905" },
  { line: "BA UAUA;3939;2932002" },
  { line: "BA UBAIRA;3941;2932101" },
  { line: "BA UBAITABA;3943;2932200" },
  { line: "BA UBATA;3945;2932309" },
  { line: "BA UIBAI;3947;2932408" },
  { line: "SE UMBAUBA;3251;2807600" },
  { line: "BA UMBURANAS;3047;2932457" },
  { line: "BA UNA;3949;2932507" },
  { line: "BA URANDI;3951;2932606" },
  { line: "BA URUCUCA;3953;2932705" },
  { line: "BA UTINGA;3955;2932804" },
  { line: "BA VALENCA;3957;2932903" },
  { line: "BA VALENTE;3959;2933000" },
  { line: "BA VARZEA DA ROCA;3997;2933059" },
  { line: "BA VARZEA DO POCO;3961;2933109" },
  { line: "BA VARZEA NOVA;3995;2933158" },
  { line: "BA VARZEDO;3049;2933174" },
  { line: "BA VERA CRUZ;3963;2933208" },
  { line: "BA VEREDA;3051;2933257" },
  { line: "BA VITORIA DA CONQUISTA;3965;2933307" },
  { line: "BA WAGNER;3967;2933406" },
  { line: "BA WANDERLEY;3999;2933455" },
  { line: "BA WENCESLAU GUIMARAES;3969;2933505" },
  { line: "BA XIQUE-XIQUE;3971;2933604" },
  { line: "MG ABADIA DOS DOURADOS;4001;3100104" },
  { line: "MG ABAETE;4003;3100203" },
  { line: "MG ABRE CAMPO;4005;3100302" },
  { line: "MG ACAIACA;4007;3100401" },
  { line: "MG ACUCENA;4009;3100500" },
  { line: "MG AGUA BOA;4011;3100609" },
  { line: "MG AGUA COMPRIDA;4013;3100708" },
  { line: "MG AGUANIL;4015;3100807" },
  { line: "MG AGUAS FORMOSAS;4017;3100906" },
  { line: "MG AGUAS VERMELHAS;4019;3101003" },
  { line: "MG AIMORES;4021;3101102" },
  { line: "MG AIURUOCA;4023;3101201" },
  { line: "MG ALAGOA;4025;3101300" },
  { line: "MG ALBERTINA;4027;3101409" },
  { line: "MG ALEM PARAIBA;4029;3101508" },
  { line: "MG ALFENAS;4031;3101607" },
  { line: "MG ALFREDO VASCONCELOS;2681;3101631" },
  { line: "MG ALMENARA;4033;3101706" },
  { line: "MG ALPERCATA;4035;3101805" },
  { line: "MG ALPINOPOLIS;4037;3101904" },
  { line: "MG ALTEROSA;4039;3102001" },
  { line: "MG ALTO CAPARAO;564;3102050" },
  { line: "MG ALTO JEQUITIBA;5069;3153509" },
  { line: "MG ALTO RIO DOCE;4041;3102100" },
  { line: "MG ALVARENGA;4043;3102209" },
  { line: "MG ALVINOPOLIS;4045;3102308" },
  { line: "MG ALVORADA DE MINAS;4047;3102407" },
  { line: "MG AMPARO DA SERRA;4049;3102506" },
  { line: "MG ANDRADAS;4051;3102605" },
  { line: "MG ANDRELANDIA;4055;3102803" },
  { line: "MG ANGELANDIA;566;3102852" },
  { line: "MG ANTONIO CARLOS;4057;3102902" },
  { line: "MG ANTONIO DIAS;4059;3103009" },
  { line: "MG ANTONIO PRADO DE MINAS;4061;3103108" },
  { line: "MG ARACAI;4063;3103207" },
  { line: "MG ARACITABA;4065;3103306" },
  { line: "MG ARACUAI;4067;3103405" },
  { line: "MG ARAGUARI;4069;3103504" },
  { line: "MG ARANTINA;4071;3103603" },
  { line: "MG ARAPONGA;4073;3103702" },
  { line: "MG ARAPORA;2903;3103751" },
  { line: "MG ARAPUA;4075;3103801" },
  { line: "MG ARAUJOS;4077;3103900" },
  { line: "MG ARAXA;4079;3104007" },
  { line: "MG ARCEBURGO;4081;3104106" },
  { line: "MG ARCOS;4083;3104205" },
  { line: "MG AREADO;4085;3104304" },
  { line: "MG ARGIRITA;4087;3104403" },
  { line: "MG ARICANDUVA;568;3104452" },
  { line: "MG ARINOS;4089;3104502" },
  { line: "MG ASTOLFO DUTRA;4091;3104601" },
  { line: "MG ATALEIA;4093;3104700" },
  { line: "MG AUGUSTO DE LIMA;4095;3104809" },
  { line: "MG BAEPENDI;4097;3104908" },
  { line: "MG BALDIM;4099;3105004" },
  { line: "MG BAMBUI;4101;3105103" },
  { line: "MG BANDEIRA;4103;3105202" },
  { line: "MG BANDEIRA DO SUL;4105;3105301" },
  { line: "MG BARAO DE COCAIS;4107;3105400" },
  { line: "MG BARAO DE MONTE ALTO;4109;3105509" },
  { line: "MG BARBACENA;4111;3105608" },
  { line: "MG BARRA LONGA;4113;3105707" },
  { line: "MG BARROSO;4117;3105905" },
  { line: "MG BELA VISTA DE MINAS;4119;3106002" },
  { line: "MG BELMIRO BRAGA;4121;3106101" },
  { line: "MG BELO HORIZONTE;4123;3106200" },
  { line: "MG BELO ORIENTE;4125;3106309" },
  { line: "MG BELO VALE;4127;3106408" },
  { line: "MG BERILO;4129;3106507" },
  { line: "MG BERIZAL;570;3106655" },
  { line: "MG BERTOPOLIS;4131;3106606" },
  { line: "MG BETIM;4133;3106705" },
  { line: "MG BIAS FORTES;4135;3106804" },
  { line: "MG BICAS;4137;3106903" },
  { line: "MG BIQUINHAS;4139;3107000" },
  { line: "MG BOA ESPERANCA;4141;3107109" },
  { line: "MG BOCAINA DE MINAS;4143;3107208" },
  { line: "MG BOCAIUVA;4145;3107307" },
  { line: "MG BOM DESPACHO;4147;3107406" },
  { line: "MG BOM JARDIM DE MINAS;4149;3107505" },
  { line: "MG BOM JESUS DA PENHA;4151;3107604" },
  { line: "MG BOM JESUS DO AMPARO;4153;3107703" },
  { line: "MG BOM JESUS DO GALHO;4155;3107802" },
  { line: "MG BOM REPOUSO;4157;3107901" },
  { line: "MG BOM SUCESSO;4159;3108008" },
  { line: "MG BONFIM;4161;3108107" },
  { line: "MG BONFINOPOLIS DE MINAS;4163;3108206" },
  { line: "MG BONITO DE MINAS;572;3108255" },
  { line: "MG BORDA DA MATA;4165;3108305" },
  { line: "MG BOTELHOS;4167;3108404" },
  { line: "MG BOTUMIRIM;4169;3108503" },
  { line: "MG BRAS PIRES;4173;3108701" },
  { line: "MG BRASILANDIA DE MINAS;574;3108552" },
  { line: "MG BRASILIA DE MINAS;4171;3108602" },
  { line: "MG BRASOPOLIS;4177;3108909" },
  { line: "MG BRAUNAS;4175;3108800" },
  { line: "MG BRUMADINHO;4179;3109006" },
  { line: "MG BUENO BRANDAO;4181;3109105" },
  { line: "MG BUENOPOLIS;4183;3109204" },
  { line: "MG BUGRE;576;3109253" },
  { line: "MG BURITIS;4185;3109303" },
  { line: "MG BURITIZEIRO;4187;3109402" },
  { line: "MG CABECEIRA GRANDE;578;3109451" },
  { line: "MG CABO VERDE;4189;3109501" },
  { line: "MG CACHOEIRA DA PRATA;4191;3109600" },
  { line: "MG CACHOEIRA DE MINAS;4193;3109709" },
  { line: "MG CACHOEIRA DE PAJEU;4053;3102704" },
  { line: "MG CACHOEIRA DOURADA;4195;3109808" },
  { line: "MG CAETANOPOLIS;4197;3109907" },
  { line: "MG CAETE;4199;3110004" },
  { line: "MG CAIANA;4201;3110103" },
  { line: "MG CAJURI;4203;3110202" },
  { line: "MG CALDAS;4205;3110301" },
  { line: "MG CAMACHO;4207;3110400" },
  { line: "MG CAMANDUCAIA;4209;3110509" },
  { line: "MG CAMBUI;4211;3110608" },
  { line: "MG CAMBUQUIRA;4213;3110707" },
  { line: "MG CAMPANARIO;4215;3110806" },
  { line: "MG CAMPANHA;4217;3110905" },
  { line: "MG CAMPESTRE;4219;3111002" },
  { line: "MG CAMPINA VERDE;4221;3111101" },
  { line: "MG CAMPO AZUL;580;3111150" },
  { line: "MG CAMPO BELO;4223;3111200" },
  { line: "MG CAMPO DO MEIO;4225;3111309" },
  { line: "MG CAMPO FLORIDO;4227;3111408" },
  { line: "MG CAMPOS ALTOS;4229;3111507" },
  { line: "MG CAMPOS GERAIS;4231;3111606" },
  { line: "MG CANA VERDE;4237;3111903" },
  { line: "MG CANAA;4233;3111705" },
  { line: "MG CANAPOLIS;4235;3111804" },
  { line: "MG CANDEIAS;4239;3112000" },
  { line: "MG CANTAGALO;582;3112059" },
  { line: "MG CAPARAO;4241;3112109" },
  { line: "MG CAPELA NOVA;4243;3112208" },
  { line: "MG CAPELINHA;4245;3112307" },
  { line: "MG CAPETINGA;4247;3112406" },
  { line: "MG CAPIM BRANCO;4249;3112505" },
  { line: "MG CAPINOPOLIS;4251;3112604" },
  { line: "MG CAPITAO ANDRADE;2651;3112653" },
  { line: "MG CAPITAO ENEAS;4253;3112703" },
  { line: "MG CAPITOLIO;4255;3112802" },
  { line: "MG CAPUTIRA;4257;3112901" },
  { line: "MG CARAI;4259;3113008" },
  { line: "MG CARANAIBA;4261;3113107" },
  { line: "MG CARANDAI;4263;3113206" },
  { line: "MG CARANGOLA;4265;3113305" },
  { line: "MG CARATINGA;4267;3113404" },
  { line: "MG CARBONITA;4269;3113503" },
  { line: "MG CAREACU;4271;3113602" },
  { line: "MG CARLOS CHAGAS;4273;3113701" },
  { line: "MG CARMESIA;4275;3113800" },
  { line: "MG CARMO DA CACHOEIRA;4277;3113909" },
  { line: "MG CARMO DA MATA;4279;3114006" },
  { line: "MG CARMO DE MINAS;4281;3114105" },
  { line: "MG CARMO DO CAJURU;4283;3114204" },
  { line: "MG CARMO DO PARANAIBA;4285;3114303" },
  { line: "MG CARMO DO RIO CLARO;4287;3114402" },
  { line: "MG CARMOPOLIS DE MINAS;4289;3114501" },
  { line: "MG CARNEIRINHO;2685;3114550" },
  { line: "MG CARRANCAS;4291;3114600" },
  { line: "MG CARVALHOPOLIS;4293;3114709" },
  { line: "MG CARVALHOS;4295;3114808" },
  { line: "MG CASA GRANDE;4297;3114907" },
  { line: "MG CASCALHO RICO;4299;3115003" },
  { line: "MG CASSIA;4301;3115102" },
  { line: "MG CATAGUASES;4305;3115300" },
  { line: "MG CATAS ALTAS;584;3115359" },
  { line: "MG CATAS ALTAS DA NORUEGA;4307;3115409" },
  { line: "MG CATUJI;2653;3115458" },
  { line: "MG CATUTI;586;3115474" },
  { line: "MG CAXAMBU;4309;3115508" },
  { line: "MG CEDRO DO ABAETE;4311;3115607" },
  { line: "MG CENTRAL DE MINAS;4313;3115706" },
  { line: "MG CENTRALINA;4315;3115805" },
  { line: "MG CHACARA;4317;3115904" },
  { line: "MG CHALE;4319;3116001" },
  { line: "MG CHAPADA DO NORTE;4321;3116100" },
  { line: "MG CHAPADA GAUCHA;588;3116159" },
  { line: "MG CHIADOR;4323;3116209" },
  { line: "MG CIPOTANEA;4325;3116308" },
  { line: "MG CLARAVAL;4327;3116407" },
  { line: "MG CLARO DOS POCOES;4329;3116506" },
  { line: "MG CLAUDIO;4331;3116605" },
  { line: "MG COIMBRA;4333;3116704" },
  { line: "MG COLUNA;4335;3116803" },
  { line: "MG COMENDADOR GOMES;4337;3116902" },
  { line: "MG COMERCINHO;4339;3117009" },
  { line: "MG CONCEICAO DA APARECIDA;4341;3117108" },
  { line: "MG CONCEICAO DA BARRA DE MINAS;4303;3115201" },
  { line: "MG CONCEICAO DAS ALAGOAS;4345;3117306" },
  { line: "MG CONCEICAO DAS PEDRAS;4343;3117207" },
  { line: "MG CONCEICAO DE IPANEMA;4347;3117405" },
  { line: "MG CONCEICAO DO MATO DENTRO;4349;3117504" },
  { line: "MG CONCEICAO DO PARA;4351;3117603" },
  { line: "MG CONCEICAO DO RIO VERDE;4353;3117702" },
  { line: "MG CONCEICAO DOS OUROS;4355;3117801" },
  { line: "MG CONEGO MARINHO;590;3117836" },
  { line: "MG CONFINS;592;3117876" },
  { line: "MG CONGONHAL;4357;3117900" },
  { line: "MG CONGONHAS;4359;3118007" },
  { line: "MG CONGONHAS DO NORTE;4361;3118106" },
  { line: "MG CONQUISTA;4363;3118205" },
  { line: "MG CONSELHEIRO LAFAIETE;4365;3118304" },
  { line: "MG CONSELHEIRO PENA;4367;3118403" },
  { line: "MG CONSOLACAO;4369;3118502" },
  { line: "MG CONTAGEM;4371;3118601" },
  { line: "MG COQUEIRAL;4373;3118700" },
  { line: "MG CORACAO DE JESUS;4375;3118809" },
  { line: "MG CORDISBURGO;4377;3118908" },
  { line: "MG CORDISLANDIA;4379;3119005" },
  { line: "MG CORINTO;4381;3119104" },
  { line: "MG COROACI;4383;3119203" },
  { line: "MG COROMANDEL;4385;3119302" },
  { line: "MG CORONEL FABRICIANO;4387;3119401" },
  { line: "MG CORONEL MURTA;4389;3119500" },
  { line: "MG CORONEL PACHECO;4391;3119609" },
  { line: "MG CORONEL XAVIER CHAVES;4393;3119708" },
  { line: "MG CORREGO DANTA;4395;3119807" },
  { line: "MG CORREGO DO BOM JESUS;4397;3119906" },
  { line: "MG CORREGO FUNDO;594;3119955" },
  { line: "MG CORREGO NOVO;4399;3120003" },
  { line: "MG COUTO DE MAGALHAES DE MINAS;4401;3120102" },
  { line: "MG CRISOLITA;596;3120151" },
  { line: "MG CRISTAIS;4403;3120201" },
  { line: "MG CRISTALIA;4405;3120300" },
  { line: "MG CRISTIANO OTONI;4407;3120409" },
  { line: "MG CRISTINA;4409;3120508" },
  { line: "MG CRUCILANDIA;4411;3120607" },
  { line: "MG CRUZEIRO DA FORTALEZA;4413;3120706" },
  { line: "MG CRUZILIA;4415;3120805" },
  { line: "MG CUPARAQUE;598;3120839" },
  { line: "MG CURRAL DE DENTRO;600;3120870" },
  { line: "MG CURVELO;4417;3120904" },
  { line: "MG DATAS;4419;3121001" },
  { line: "MG DELFIM MOREIRA;4421;3121100" },
  { line: "MG DELFINOPOLIS;4423;3121209" },
  { line: "MG DELTA;602;3121258" },
  { line: "MG DESCOBERTO;4425;3121308" },
  { line: "MG DESTERRO DE ENTRE RIOS;4427;3121407" },
  { line: "MG DESTERRO DO MELO;4429;3121506" },
  { line: "MG DIAMANTINA;4431;3121605" },
  { line: "MG DIOGO DE VASCONCELOS;4433;3121704" },
  { line: "MG DIONISIO;4435;3121803" },
  { line: "MG DIVINESIA;4437;3121902" },
  { line: "MG DIVINO;4439;3122009" },
  { line: "MG DIVINO DAS LARANJEIRAS;4441;3122108" },
  { line: "MG DIVINOLANDIA DE MINAS;4443;3122207" },
  { line: "MG DIVINOPOLIS;4445;3122306" },
  { line: "MG DIVISA ALEGRE;604;3122355" },
  { line: "MG DIVISA NOVA;4447;3122405" },
  { line: "MG DIVISOPOLIS;2657;3122454" },
  { line: "MG DOM BOSCO;606;3122470" },
  { line: "MG DOM CAVATI;4449;3122504" },
  { line: "MG DOM JOAQUIM;4451;3122603" },
  { line: "MG DOM SILVERIO;4453;3122702" },
  { line: "MG DOM VICOSO;4455;3122801" },
  { line: "MG DONA EUZEBIA;4457;3122900" },
  { line: "MG DORES DE CAMPOS;4459;3123007" },
  { line: "MG DORES DE GUANHAES;4461;3123106" },
  { line: "MG DORES DO INDAIA;4463;3123205" },
  { line: "MG DORES DO TURVO;4465;3123304" },
  { line: "MG DORESOPOLIS;4467;3123403" },
  { line: "MG DOURADOQUARA;4469;3123502" },
  { line: "MG DURANDE;2675;3123528" },
  { line: "MG ELOI MENDES;4471;3123601" },
  { line: "MG ENGENHEIRO CALDAS;4473;3123700" },
  { line: "MG ENGENHEIRO NAVARRO;4475;3123809" },
  { line: "MG ENTRE FOLHAS;2663;3123858" },
  { line: "MG ENTRE RIOS DE MINAS;4477;3123908" },
  { line: "MG ERVALIA;4479;3124005" },
  { line: "MG ESMERALDAS;4481;3124104" },
  { line: "MG ESPERA FELIZ;4483;3124203" },
  { line: "MG ESPINOSA;4485;3124302" },
  { line: "MG ESPIRITO SANTO DO DOURADO;4487;3124401" },
  { line: "MG ESTIVA;4489;3124500" },
  { line: "MG ESTRELA DALVA;4491;3124609" },
  { line: "MG ESTRELA DO INDAIA;4493;3124708" },
  { line: "MG ESTRELA DO SUL;4495;3124807" },
  { line: "MG EUGENOPOLIS;4497;3124906" },
  { line: "MG EWBANK DA CAMARA;4499;3125002" },
  { line: "MG EXTREMA;4501;3125101" },
  { line: "MG FAMA;4503;3125200" },
  { line: "MG FARIA LEMOS;4505;3125309" },
  { line: "MG FELICIO DOS SANTOS;4507;3125408" },
  { line: "MG FELISBURGO;4511;3125606" },
  { line: "MG FELIXLANDIA;4513;3125705" },
  { line: "MG FERNANDES TOURINHO;4515;3125804" },
  { line: "MG FERROS;4517;3125903" },
  { line: "MG FERVEDOURO;2683;3125952" },
  { line: "MG FLORESTAL;4519;3126000" },
  { line: "MG FORMIGA;4521;3126109" },
  { line: "MG FORMOSO;4523;3126208" },
  { line: "MG FORTALEZA DE MINAS;4525;3126307" },
  { line: "MG FORTUNA DE MINAS;4527;3126406" },
  { line: "MG FRANCISCO BADARO;4529;3126505" },
  { line: "MG FRANCISCO DUMONT;4531;3126604" },
  { line: "MG FRANCISCO SA;4533;3126703" },
  { line: "MG FRANCISCOPOLIS;608;3126752" },
  { line: "MG FREI GASPAR;4535;3126802" },
  { line: "MG FREI INOCENCIO;4537;3126901" },
  { line: "MG FREI LAGONEGRO;610;3126950" },
  { line: "MG FRONTEIRA;4539;3127008" },
  { line: "MG FRONTEIRA DOS VALES;4935;3127057" },
  { line: "MG FRUTA DE LEITE;612;3127073" },
  { line: "MG FRUTAL;4541;3127107" },
  { line: "MG FUNILANDIA;4543;3127206" },
  { line: "MG GALILEIA;4545;3127305" },
  { line: "MG GAMELEIRAS;614;3127339" },
  { line: "MG GLAUCILANDIA;616;3127354" },
  { line: "MG GOIABEIRA;618;3127370" },
  { line: "MG GOIANA;620;3127388" },
  { line: "MG GONCALVES;4547;3127404" },
  { line: "MG GONZAGA;4549;3127503" },
  { line: "MG GOUVEIA;4551;3127602" },
  { line: "MG GOVERNADOR VALADARES;4553;3127701" },
  { line: "MG GRAO MOGOL;4555;3127800" },
  { line: "MG GRUPIARA;4557;3127909" },
  { line: "MG GUANHAES;4559;3128006" },
  { line: "MG GUAPE;4561;3128105" },
  { line: "MG GUARACIABA;4563;3128204" },
  { line: "MG GUARACIAMA;622;3128253" },
  { line: "MG GUARANESIA;4565;3128303" },
  { line: "MG GUARANI;4567;3128402" },
  { line: "MG GUARARA;4569;3128501" },
  { line: "MG GUARDA-MOR;4571;3128600" },
  { line: "MG GUAXUPE;4573;3128709" },
  { line: "MG GUIDOVAL;4575;3128808" },
  { line: "MG GUIMARANIA;4577;3128907" },
  { line: "MG GUIRICEMA;4579;3129004" },
  { line: "MG GURINHATA;4581;3129103" },
  { line: "MG HELIODORA;4583;3129202" },
  { line: "MG IAPU;4585;3129301" },
  { line: "MG IBERTIOGA;4587;3129400" },
  { line: "MG IBIA;4589;3129509" },
  { line: "MG IBIAI;4591;3129608" },
  { line: "MG IBIRACATU;624;3129657" },
  { line: "MG IBIRACI;4593;3129707" },
  { line: "MG IBIRITE;4595;3129806" },
  { line: "MG IBITIURA DE MINAS;4597;3129905" },
  { line: "MG IBITURUNA;4599;3130002" },
  { line: "MG ICARAI DE MINAS;2693;3130051" },
  { line: "MG IGARAPE;4601;3130101" },
  { line: "MG IGARATINGA;4603;3130200" },
  { line: "MG IGUATAMA;4605;3130309" },
  { line: "MG IJACI;4607;3130408" },
  { line: "MG ILICINEA;4609;3130507" },
  { line: "MG IMBE DE MINAS;626;3130556" },
  { line: "MG INCONFIDENTES;4611;3130606" },
  { line: "MG INDAIABIRA;628;3130655" },
  { line: "MG INDIANOPOLIS;4613;3130705" },
  { line: "MG INGAI;4615;3130804" },
  { line: "MG INHAPIM;4617;3130903" },
  { line: "MG INHAUMA;4619;3131000" },
  { line: "MG INIMUTABA;4621;3131109" },
  { line: "MG IPABA;2665;3131158" },
  { line: "MG IPANEMA;4623;3131208" },
  { line: "MG IPATINGA;4625;3131307" },
  { line: "MG IPIACU;4627;3131406" },
  { line: "MG IPUIUNA;4629;3131505" },
  { line: "MG IRAI DE MINAS;4631;3131604" },
  { line: "MG ITABIRA;4633;3131703" },
  { line: "MG ITABIRINHA;4635;3131802" },
  { line: "MG ITABIRITO;4637;3131901" },
  { line: "MG ITACAMBIRA;4639;3132008" },
  { line: "MG ITACARAMBI;4641;3132107" },
  { line: "MG ITAGUARA;4643;3132206" },
  { line: "MG ITAIPE;4645;3132305" },
  { line: "MG ITAJUBA;4647;3132404" },
  { line: "MG ITAMARANDIBA;4649;3132503" },
  { line: "MG ITAMARATI DE MINAS;4651;3132602" },
  { line: "MG ITAMBACURI;4653;3132701" },
  { line: "MG ITAMBE DO MATO DENTRO;4655;3132800" },
  { line: "MG ITAMOGI;4657;3132909" },
  { line: "MG ITAMONTE;4659;3133006" },
  { line: "MG ITANHANDU;4661;3133105" },
  { line: "MG ITANHOMI;4663;3133204" },
  { line: "MG ITAOBIM;4665;3133303" },
  { line: "MG ITAPAGIPE;4667;3133402" },
  { line: "MG ITAPECERICA;4669;3133501" },
  { line: "MG ITAPEVA;4671;3133600" },
  { line: "MG ITATIAIUCU;4673;3133709" },
  { line: "MG ITAU DE MINAS;5731;3133758" },
  { line: "MG ITAUNA;4675;3133808" },
  { line: "MG ITAVERAVA;4677;3133907" },
  { line: "MG ITINGA;4679;3134004" },
  { line: "MG ITUETA;4681;3134103" },
  { line: "MG ITUIUTABA;4683;3134202" },
  { line: "MG ITUMIRIM;4685;3134301" },
  { line: "MG ITURAMA;4687;3134400" },
  { line: "MG ITUTINGA;4689;3134509" },
  { line: "MG JABOTICATUBAS;4691;3134608" },
  { line: "MG JACINTO;4693;3134707" },
  { line: "MG JACUI;4695;3134806" },
  { line: "MG JACUTINGA;4697;3134905" },
  { line: "MG JAGUARACU;4699;3135001" },
  { line: "MG JAIBA;2893;3135050" },
  { line: "MG JAMPRUCA;2655;3135076" },
  { line: "MG JANAUBA;4701;3135100" },
  { line: "MG JANUARIA;4703;3135209" },
  { line: "MG JAPARAIBA;4705;3135308" },
  { line: "MG JAPONVAR;630;3135357" },
  { line: "MG JECEABA;4707;3135407" },
  { line: "MG JENIPAPO DE MINAS;632;3135456" },
  { line: "MG JEQUERI;4709;3135506" },
  { line: "MG JEQUITAI;4711;3135605" },
  { line: "MG JEQUITIBA;4713;3135704" },
  { line: "MG JEQUITINHONHA;4715;3135803" },
  { line: "MG JESUANIA;4717;3135902" },
  { line: "MG JOAIMA;4719;3136009" },
  { line: "MG JOANESIA;4721;3136108" },
  { line: "MG JOAO MONLEVADE;4723;3136207" },
  { line: "MG JOAO PINHEIRO;4725;3136306" },
  { line: "MG JOAQUIM FELICIO;4727;3136405" },
  { line: "MG JORDANIA;4729;3136504" },
  { line: "MG JOSE GONCALVES DE MINAS;634;3136520" },
  { line: "MG JOSE RAYDAN;636;3136553" },
  { line: "MG JOSENOPOLIS;638;3136579" },
  { line: "MG JUATUBA;2691;3136652" },
  { line: "MG JUIZ DE FORA;4733;3136702" },
  { line: "MG JURAMENTO;4735;3136801" },
  { line: "MG JURUAIA;4737;3136900" },
  { line: "MG JUVENILIA;640;3136959" },
  { line: "MG LADAINHA;4739;3137007" },
  { line: "MG LAGAMAR;4741;3137106" },
  { line: "MG LAGOA DA PRATA;4743;3137205" },
  { line: "MG LAGOA DOS PATOS;4745;3137304" },
  { line: "MG LAGOA DOURADA;4747;3137403" },
  { line: "MG LAGOA FORMOSA;4749;3137502" },
  { line: "MG LAGOA GRANDE;2905;3137536" },
  { line: "MG LAGOA SANTA;4751;3137601" },
  { line: "MG LAJINHA;4753;3137700" },
  { line: "MG LAMBARI;4755;3137809" },
  { line: "MG LAMIM;4757;3137908" },
  { line: "MG LARANJAL;4759;3138005" },
  { line: "MG LASSANCE;4761;3138104" },
  { line: "MG LAVRAS;4763;3138203" },
  { line: "MG LEANDRO FERREIRA;4765;3138302" },
  { line: "MG LEME DO PRADO;642;3138351" },
  { line: "MG LEOPOLDINA;4767;3138401" },
  { line: "MG LIBERDADE;4769;3138500" },
  { line: "MG LIMA DUARTE;4771;3138609" },
  { line: "MG LIMEIRA DO OESTE;2687;3138625" },
  { line: "MG LONTRA;2695;3138658" },
  { line: "MG LUISBURGO;644;3138674" },
  { line: "MG LUISLANDIA;646;3138682" },
  { line: "MG LUMINARIAS;4773;3138708" },
  { line: "MG LUZ;4775;3138807" },
  { line: "MG MACHACALIS;4777;3138906" },
  { line: "MG MACHADO;4779;3139003" },
  { line: "MG MADRE DE DEUS DE MINAS;4781;3139102" },
  { line: "MG MALACACHETA;4783;3139201" },
  { line: "MG MAMONAS;2895;3139250" },
  { line: "MG MANGA;4785;3139300" },
  { line: "MG MANHUACU;4787;3139409" },
  { line: "MG MANHUMIRIM;4789;3139508" },
  { line: "MG MANTENA;4791;3139607" },
  { line: "MG MAR DE ESPANHA;4795;3139805" },
  { line: "MG MARAVILHAS;4793;3139706" },
  { line: "MG MARIA DA FE;4797;3139904" },
  { line: "MG MARIANA;4799;3140001" },
  { line: "MG MARILAC;4801;3140100" },
  { line: "MG MARIO CAMPOS;648;3140159" },
  { line: "MG MARIPA DE MINAS;4803;3140209" },
  { line: "MG MARLIERIA;4805;3140308" },
  { line: "MG MARMELOPOLIS;4807;3140407" },
  { line: "MG MARTINHO CAMPOS;4809;3140506" },
  { line: "MG MARTINS SOARES;650;3140530" },
  { line: "MG MATA VERDE;2659;3140555" },
  { line: "MG MATERLANDIA;4811;3140605" },
  { line: "MG MATEUS LEME;4813;3140704" },
  { line: "MG MATHIAS LOBATO;5431;3171501" },
  { line: "MG MATIAS BARBOSA;4815;3140803" },
  { line: "MG MATIAS CARDOSO;2897;3140852" },
  { line: "MG MATIPO;4817;3140902" },
  { line: "MG MATO VERDE;4819;3141009" },
  { line: "MG MATOZINHOS;4821;3141108" },
  { line: "MG MATUTINA;4823;3141207" },
  { line: "MG MEDEIROS;4825;3141306" },
  { line: "MG MEDINA;4827;3141405" },
  { line: "MG MENDES PIMENTEL;4829;3141504" },
  { line: "MG MERCES;4831;3141603" },
  { line: "MG MESQUITA;4833;3141702" },
  { line: "MG MINAS NOVAS;4835;3141801" },
  { line: "MG MINDURI;4837;3141900" },
  { line: "MG MIRABELA;4839;3142007" },
  { line: "MG MIRADOURO;4841;3142106" },
  { line: "MG MIRAI;4843;3142205" },
  { line: "MG MIRAVANIA;652;3142254" },
  { line: "MG MOEDA;4845;3142304" },
  { line: "MG MOEMA;4847;3142403" },
  { line: "MG MONJOLOS;4849;3142502" },
  { line: "MG MONSENHOR PAULO;4851;3142601" },
  { line: "MG MONTALVANIA;4853;3142700" },
  { line: "MG MONTE ALEGRE DE MINAS;4855;3142809" },
  { line: "MG MONTE AZUL;4857;3142908" },
  { line: "MG MONTE BELO;4859;3143005" },
  { line: "MG MONTE CARMELO;4861;3143104" },
  { line: "MG MONTE FORMOSO;654;3143153" },
  { line: "MG MONTE SANTO DE MINAS;4863;3143203" },
  { line: "MG MONTE SIAO;4867;3143401" },
  { line: "MG MONTES CLAROS;4865;3143302" },
  { line: "MG MONTEZUMA;2697;3143450" },
  { line: "MG MORADA NOVA DE MINAS;4869;3143500" },
  { line: "MG MORRO DA GARCA;4871;3143609" },
  { line: "MG MORRO DO PILAR;4873;3143708" },
  { line: "MG MUNHOZ;4875;3143807" },
  { line: "MG MURIAE;4877;3143906" },
  { line: "MG MUTUM;4879;3144003" },
  { line: "MG MUZAMBINHO;4881;3144102" },
  { line: "MG NACIP RAYDAN;4883;3144201" },
  { line: "MG NANUQUE;4885;3144300" },
  { line: "MG NAQUE;656;3144359" },
  { line: "MG NATALANDIA;658;3144375" },
  { line: "MG NATERCIA;4887;3144409" },
  { line: "MG NAZARENO;4889;3144508" },
  { line: "MG NEPOMUCENO;4891;3144607" },
  { line: "MG NINHEIRA;660;3144656" },
  { line: "MG NOVA BELEM;662;3144672" },
  { line: "MG NOVA ERA;4893;3144706" },
  { line: "MG NOVA LIMA;4895;3144805" },
  { line: "MG NOVA MODICA;4897;3144904" },
  { line: "MG NOVA PONTE;4899;3145000" },
  { line: "MG NOVA PORTEIRINHA;664;3145059" },
  { line: "MG NOVA RESENDE;4901;3145109" },
  { line: "MG NOVA SERRANA;4903;3145208" },
  { line: "MG NOVA UNIAO;4731;3136603" },
  { line: "MG NOVO CRUZEIRO;4905;3145307" },
  { line: "MG NOVO ORIENTE DE MINAS;666;3145356" },
  { line: "MG NOVORIZONTE;668;3145372" },
  { line: "MG OLARIA;4907;3145406" },
  { line: "MG OLHOS-D'AGUA;670;3145455" },
  { line: "MG OLIMPIO NORONHA;4909;3145505" },
  { line: "MG OLIVEIRA;4911;3145604" },
  { line: "MG OLIVEIRA FORTES;4913;3145703" },
  { line: "MG ONCA DE PITANGUI;4915;3145802" },
  { line: "MG ORATORIOS;672;3145851" },
  { line: "MG ORIZANIA;674;3145877" },
  { line: "MG OURO BRANCO;4917;3145901" },
  { line: "MG OURO FINO;4919;3146008" },
  { line: "MG OURO PRETO;4921;3146107" },
  { line: "MG OURO VERDE DE MINAS;4923;3146206" },
  { line: "MG PADRE CARVALHO;676;3146255" },
  { line: "MG PADRE PARAISO;4925;3146305" },
  { line: "MG PAI PEDRO;678;3146552" },
  { line: "MG PAINEIRAS;4927;3146404" },
  { line: "MG PAINS;4929;3146503" },
  { line: "MG PAIVA;4931;3146602" },
  { line: "MG PALMA;4933;3146701" },
  { line: "MG PALMOPOLIS;2661;3146750" },
  { line: "MG PAPAGAIOS;4937;3146909" },
  { line: "MG PARA DE MINAS;4941;3147105" },
  { line: "MG PARACATU;4939;3147006" },
  { line: "MG PARAGUACU;4943;3147204" },
  { line: "MG PARAISOPOLIS;4945;3147303" },
  { line: "MG PARAOPEBA;4947;3147402" },
  { line: "MG PASSA QUATRO;4951;3147600" },
  { line: "MG PASSA TEMPO;4953;3147709" },
  { line: "MG PASSA VINTE;4955;3147808" },
  { line: "MG PASSABEM;4949;3147501" },
  { line: "MG PASSOS;4957;3147907" },
  { line: "MG PATIS;680;3147956" },
  { line: "MG PATOS DE MINAS;4959;3148004" },
  { line: "MG PATROCINIO;4961;3148103" },
  { line: "MG PATROCINIO DO MURIAE;4963;3148202" },
  { line: "MG PAULA CANDIDO;4965;3148301" },
  { line: "MG PAULISTAS;4967;3148400" },
  { line: "MG PAVAO;4969;3148509" },
  { line: "MG PECANHA;4971;3148608" },
  { line: "MG PEDRA AZUL;4973;3148707" },
  { line: "MG PEDRA BONITA;682;3148756" },
  { line: "MG PEDRA DO ANTA;4975;3148806" },
  { line: "MG PEDRA DO INDAIA;4977;3148905" },
  { line: "MG PEDRA DOURADA;4979;3149002" },
  { line: "MG PEDRALVA;4981;3149101" },
  { line: "MG PEDRAS DE MARIA DA CRUZ;2899;3149150" },
  { line: "MG PEDRINOPOLIS;4983;3149200" },
  { line: "MG PEDRO LEOPOLDO;4985;3149309" },
  { line: "MG PEDRO TEIXEIRA;4987;3149408" },
  { line: "MG PEQUERI;4989;3149507" },
  { line: "MG PEQUI;4991;3149606" },
  { line: "MG PERDIGAO;4993;3149705" },
  { line: "MG PERDIZES;4995;3149804" },
  { line: "MG PERDOES;4997;3149903" },
  { line: "MG PERIQUITO;684;3149952" },
  { line: "MG PESCADOR;4999;3150000" },
  { line: "MG PIAU;5001;3150109" },
  { line: "MG PIEDADE DE CARATINGA;686;3150158" },
  { line: "MG PIEDADE DE PONTE NOVA;5003;3150208" },
  { line: "MG PIEDADE DO RIO GRANDE;5005;3150307" },
  { line: "MG PIEDADE DOS GERAIS;5007;3150406" },
  { line: "MG PIMENTA;5009;3150505" },
  { line: "MG PINGO D'AGUA;688;3150539" },
  { line: "MG PINTOPOLIS;690;3150570" },
  { line: "MG PIRACEMA;5011;3150604" },
  { line: "MG PIRAJUBA;5013;3150703" },
  { line: "MG PIRANGA;5015;3150802" },
  { line: "MG PIRANGUCU;5017;3150901" },
  { line: "MG PIRANGUINHO;5019;3151008" },
  { line: "MG PIRAPETINGA;5021;3151107" },
  { line: "MG PIRAPORA;5023;3151206" },
  { line: "MG PIRAUBA;5025;3151305" },
  { line: "MG PITANGUI;5027;3151404" },
  { line: "MG PIUMHI;5029;3151503" },
  { line: "MG PLANURA;5031;3151602" },
  { line: "MG POCO FUNDO;5033;3151701" },
  { line: "MG POCOS DE CALDAS;5035;3151800" },
  { line: "MG POCRANE;5037;3151909" },
  { line: "MG POMPEU;5039;3152006" },
  { line: "MG PONTE NOVA;5041;3152105" },
  { line: "MG PONTO CHIQUE;692;3152131" },
  { line: "MG PONTO DOS VOLANTES;694;3152170" },
  { line: "MG PORTEIRINHA;5043;3152204" },
  { line: "MG PORTO FIRME;5045;3152303" },
  { line: "MG POTE;5047;3152402" },
  { line: "MG POUSO ALEGRE;5049;3152501" },
  { line: "MG POUSO ALTO;5051;3152600" },
  { line: "MG PRADOS;5053;3152709" },
  { line: "MG PRATA;5055;3152808" },
  { line: "MG PRATAPOLIS;5057;3152907" },
  { line: "MG PRATINHA;5059;3153004" },
  { line: "MG PRESIDENTE BERNARDES;5061;3153103" },
  { line: "MG PRESIDENTE JUSCELINO;5063;3153202" },
  { line: "MG PRESIDENTE KUBITSCHEK;5065;3153301" },
  { line: "MG PRESIDENTE OLEGARIO;5067;3153400" },
  { line: "MG PRUDENTE DE MORAIS;5071;3153608" },
  { line: "MG QUARTEL GERAL;5073;3153707" },
  { line: "MG QUELUZITO;5075;3153806" },
  { line: "MG RAPOSOS;5077;3153905" },
  { line: "MG RAUL SOARES;5079;3154002" },
  { line: "MG RECREIO;5081;3154101" },
  { line: "MG REDUTO;696;3154150" },
  { line: "MG RESENDE COSTA;5083;3154200" },
  { line: "MG RESPLENDOR;5085;3154309" },
  { line: "MG RESSAQUINHA;5087;3154408" },
  { line: "MG RIACHINHO;2901;3154457" },
  { line: "MG RIACHO DOS MACHADOS;5089;3154507" },
  { line: "MG RIBEIRAO DAS NEVES;5091;3154606" },
  { line: "MG RIBEIRAO VERMELHO;5093;3154705" },
  { line: "MG RIO ACIMA;5095;3154804" },
  { line: "MG RIO CASCA;5097;3154903" },
  { line: "MG RIO DO PRADO;5101;3155108" },
  { line: "MG RIO DOCE;5099;3155009" },
  { line: "MG RIO ESPERA;5103;3155207" },
  { line: "MG RIO MANSO;5105;3155306" },
  { line: "MG RIO NOVO;5107;3155405" },
  { line: "MG RIO PARANAIBA;5109;3155504" },
  { line: "MG RIO PARDO DE MINAS;5111;3155603" },
  { line: "MG RIO PIRACICABA;5113;3155702" },
  { line: "MG RIO POMBA;5115;3155801" },
  { line: "MG RIO PRETO;5117;3155900" },
  { line: "MG RIO VERMELHO;5119;3156007" },
  { line: "MG RITAPOLIS;5121;3156106" },
  { line: "MG ROCHEDO DE MINAS;5123;3156205" },
  { line: "MG RODEIRO;5125;3156304" },
  { line: "MG ROMARIA;5127;3156403" },
  { line: "MG ROSARIO DA LIMEIRA;698;3156452" },
  { line: "MG RUBELITA;5129;3156502" },
  { line: "MG RUBIM;5131;3156601" },
  { line: "MG SABARA;5133;3156700" },
  { line: "MG SABINOPOLIS;5135;3156809" },
  { line: "MG SACRAMENTO;5137;3156908" },
  { line: "MG SALINAS;5139;3157005" },
  { line: "MG SALTO DA DIVISA;5141;3157104" },
  { line: "MG SANTA BARBARA;5143;3157203" },
  { line: "MG SANTA BARBARA DO LESTE;2667;3157252" },
  { line: "MG SANTA BARBARA DO MONTE VERDE;700;3157278" },
  { line: "MG SANTA BARBARA DO TUGURIO;5145;3157302" },
  { line: "MG SANTA CRUZ DE MINAS;702;3157336" },
  { line: "MG SANTA CRUZ DE SALINAS;704;3157377" },
  { line: "MG SANTA CRUZ DO ESCALVADO;5147;3157401" },
  { line: "MG SANTA EFIGENIA DE MINAS;5149;3157500" },
  { line: "MG SANTA FE DE MINAS;5151;3157609" },
  { line: "MG SANTA HELENA DE MINAS;706;3157658" },
  { line: "MG SANTA JULIANA;5153;3157708" },
  { line: "MG SANTA LUZIA;5155;3157807" },
  { line: "MG SANTA MARGARIDA;5157;3157906" },
  { line: "MG SANTA MARIA DE ITABIRA;5159;3158003" },
  { line: "MG SANTA MARIA DO SALTO;5161;3158102" },
  { line: "MG SANTA MARIA DO SUACUI;5163;3158201" },
  { line: "MG SANTA RITA DE CALDAS;5183;3159209" },
  { line: "MG SANTA RITA DE IBITIPOCA;5187;3159407" },
  { line: "MG SANTA RITA DE JACUTINGA;5185;3159308" },
  { line: "MG SANTA RITA DE MINAS;2669;3159357" },
  { line: "MG SANTA RITA DO ITUETO;5189;3159506" },
  { line: "MG SANTA RITA DO SAPUCAI;5191;3159605" },
  { line: "MG SANTA ROSA DA SERRA;5193;3159704" },
  { line: "MG SANTA VITORIA;5195;3159803" },
  { line: "MG SANTANA DA VARGEM;5165;3158300" },
  { line: "MG SANTANA DE CATAGUASES;5167;3158409" },
  { line: "MG SANTANA DE PIRAPAMA;5169;3158508" },
  { line: "MG SANTANA DO DESERTO;5171;3158607" },
  { line: "MG SANTANA DO GARAMBEU;5173;3158706" },
  { line: "MG SANTANA DO JACARE;5175;3158805" },
  { line: "MG SANTANA DO MANHUACU;5177;3158904" },
  { line: "MG SANTANA DO PARAISO;2673;3158953" },
  { line: "MG SANTANA DO RIACHO;5179;3159001" },
  { line: "MG SANTANA DOS MONTES;5181;3159100" },
  { line: "MG SANTO ANTONIO DO AMPARO;5197;3159902" },
  { line: "MG SANTO ANTONIO DO AVENTUREIRO;5199;3160009" },
  { line: "MG SANTO ANTONIO DO GRAMA;5201;3160108" },
  { line: "MG SANTO ANTONIO DO ITAMBE;5203;3160207" },
  { line: "MG SANTO ANTONIO DO JACINTO;5205;3160306" },
  { line: "MG SANTO ANTONIO DO MONTE;5207;3160405" },
  { line: "MG SANTO ANTONIO DO RETIRO;708;3160454" },
  { line: "MG SANTO ANTONIO DO RIO ABAIXO;5209;3160504" },
  { line: "MG SANTO HIPOLITO;5211;3160603" },
  { line: "MG SANTOS DUMONT;5213;3160702" },
  { line: "MG SAO BENTO ABADE;5215;3160801" },
  { line: "MG SAO BRAS DO SUACUI;5217;3160900" },
  { line: "MG SAO DOMINGOS DAS DORES;710;3160959" },
  { line: "MG SAO DOMINGOS DO PRATA;5219;3161007" },
  { line: "MG SAO FELIX DE MINAS;712;3161056" },
  { line: "MG SAO FRANCISCO;5221;3161106" },
  { line: "MG SAO FRANCISCO DE PAULA;5223;3161205" },
  { line: "MG SAO FRANCISCO DE SALES;5225;3161304" },
  { line: "MG SAO FRANCISCO DO GLORIA;5227;3161403" },
  { line: "MG SAO GERALDO;5229;3161502" },
  { line: "MG SAO GERALDO DA PIEDADE;5231;3161601" },
  { line: "MG SAO GERALDO DO BAIXIO;714;3161650" },
  { line: "MG SAO GONCALO DO ABAETE;5233;3161700" },
  { line: "MG SAO GONCALO DO PARA;5235;3161809" },
  { line: "MG SAO GONCALO DO RIO ABAIXO;5237;3161908" },
  { line: "MG SAO GONCALO DO RIO PRETO;4509;3125507" },
  { line: "MG SAO GONCALO DO SAPUCAI;5239;3162005" },
  { line: "MG SAO GOTARDO;5241;3162104" },
  { line: "MG SAO JOAO BATISTA DO GLORIA;5243;3162203" },
  { line: "MG SAO JOAO DA LAGOA;716;3162252" },
  { line: "MG SAO JOAO DA MATA;5245;3162302" },
  { line: "MG SAO JOAO DA PONTE;5247;3162401" },
  { line: "MG SAO JOAO DAS MISSOES;718;3162450" },
  { line: "MG SAO JOAO DEL REI;5249;3162500" },
  { line: "MG SAO JOAO DO MANHUACU;2677;3162559" },
  { line: "MG SAO JOAO DO MANTENINHA;2679;3162575" },
  { line: "MG SAO JOAO DO ORIENTE;5251;3162609" },
  { line: "MG SAO JOAO DO PACUI;720;3162658" },
  { line: "MG SAO JOAO DO PARAISO;5253;3162708" },
  { line: "MG SAO JOAO EVANGELISTA;5255;3162807" },
  { line: "MG SAO JOAO NEPOMUCENO;5257;3162906" },
  { line: "MG SAO JOAQUIM DE BICAS;722;3162922" },
  { line: "MG SAO JOSE DA BARRA;724;3162948" },
  { line: "MG SAO JOSE DA LAPA;2649;3162955" },
  { line: "MG SAO JOSE DA SAFIRA;5259;3163003" },
  { line: "MG SAO JOSE DA VARGINHA;5261;3163102" },
  { line: "MG SAO JOSE DO ALEGRE;5263;3163201" },
  { line: "MG SAO JOSE DO DIVINO;5265;3163300" },
  { line: "MG SAO JOSE DO GOIABAL;5267;3163409" },
  { line: "MG SAO JOSE DO JACURI;5269;3163508" },
  { line: "MG SAO JOSE DO MANTIMENTO;5271;3163607" },
  { line: "MG SAO LOURENCO;5273;3163706" },
  { line: "MG SAO MIGUEL DO ANTA;5275;3163805" },
  { line: "MG SAO PEDRO DA UNIAO;5277;3163904" },
  { line: "MG SAO PEDRO DO SUACUI;5281;3164100" },
  { line: "MG SAO PEDRO DOS FERROS;5279;3164001" },
  { line: "MG SAO ROMAO;5283;3164209" },
  { line: "MG SAO ROQUE DE MINAS;5285;3164308" },
  { line: "MG SAO SEBASTIAO DA BELA VISTA;5287;3164407" },
  { line: "MG SAO SEBASTIAO DA VARGEM ALEGRE;726;3164431" },
  { line: "MG SAO SEBASTIAO DO ANTA;728;3164472" },
  { line: "MG SAO SEBASTIAO DO MARANHAO;5289;3164506" },
  { line: "MG SAO SEBASTIAO DO OESTE;5291;3164605" },
  { line: "MG SAO SEBASTIAO DO PARAISO;5293;3164704" },
  { line: "MG SAO SEBASTIAO DO RIO PRETO;5295;3164803" },
  { line: "MG SAO SEBASTIAO DO RIO VERDE;5297;3164902" },
  { line: "MG SAO TIAGO;5299;3165008" },
  { line: "MG SAO TOMAS DE AQUINO;5301;3165107" },
  { line: "MG SAO TOME DAS LETRAS;5303;3165206" },
  { line: "MG SAO VICENTE DE MINAS;5305;3165305" },
  { line: "MG SAPUCAI-MIRIM;5307;3165404" },
  { line: "MG SARDOA;5309;3165503" },
  { line: "MG SARZEDO;730;3165537" },
  { line: "MG SEM-PEIXE;734;3165560" },
  { line: "MG SENADOR AMARAL;2689;3165578" },
  { line: "MG SENADOR CORTES;5311;3165602" },
  { line: "MG SENADOR FIRMINO;5313;3165701" },
  { line: "MG SENADOR JOSE BENTO;5315;3165800" },
  { line: "MG SENADOR MODESTINO GONCALVES;5317;3165909" },
  { line: "MG SENHORA DE OLIVEIRA;5319;3166006" },
  { line: "MG SENHORA DO PORTO;5321;3166105" },
  { line: "MG SENHORA DOS REMEDIOS;5323;3166204" },
  { line: "MG SERICITA;5325;3166303" },
  { line: "MG SERITINGA;5327;3166402" },
  { line: "MG SERRA AZUL DE MINAS;5329;3166501" },
  { line: "MG SERRA DA SAUDADE;5331;3166600" },
  { line: "MG SERRA DO SALITRE;5335;3166808" },
  { line: "MG SERRA DOS AIMORES;5333;3166709" },
  { line: "MG SERRANIA;5337;3166907" },
  { line: "MG SERRANOPOLIS DE MINAS;736;3166956" },
  { line: "MG SERRANOS;5339;3167004" },
  { line: "MG SERRO;5341;3167103" },
  { line: "MG SETE LAGOAS;5343;3167202" },
  { line: "MG SETUBINHA;732;3165552" },
  { line: "MG SILVEIRANIA;5345;3167301" },
  { line: "MG SILVIANOPOLIS;5347;3167400" },
  { line: "MG SIMAO PEREIRA;5349;3167509" },
  { line: "MG SIMONESIA;5351;3167608" },
  { line: "MG SOBRALIA;5353;3167707" },
  { line: "MG SOLEDADE DE MINAS;5355;3167806" },
  { line: "MG TABULEIRO;5357;3167905" },
  { line: "MG TAIOBEIRAS;5359;3168002" },
  { line: "MG TAPARUBA;738;3168051" },
  { line: "MG TAPIRA;5361;3168101" },
  { line: "MG TAPIRAI;5363;3168200" },
  { line: "MG TAQUARACU DE MINAS;5365;3168309" },
  { line: "MG TARUMIRIM;5367;3168408" },
  { line: "MG TEIXEIRAS;5369;3168507" },
  { line: "MG TEOFILO OTONI;5371;3168606" },
  { line: "MG TIMOTEO;5373;3168705" },
  { line: "MG TIRADENTES;5375;3168804" },
  { line: "MG TIROS;5377;3168903" },
  { line: "MG TOCANTINS;5379;3169000" },
  { line: "MG TOCOS DO MOJI;740;3169059" },
  { line: "MG TOLEDO;5381;3169109" },
  { line: "MG TOMBOS;5383;3169208" },
  { line: "MG TRES CORACOES;5385;3169307" },
  { line: "MG TRES MARIAS;4115;3169356" },
  { line: "MG TRES PONTAS;5387;3169406" },
  { line: "MG TUMIRITINGA;5389;3169505" },
  { line: "MG TUPACIGUARA;5391;3169604" },
  { line: "MG TURMALINA;5393;3169703" },
  { line: "MG TURVOLANDIA;5395;3169802" },
  { line: "MG UBA;5397;3169901" },
  { line: "MG UBAI;5399;3170008" },
  { line: "MG UBAPORANGA;2671;3170057" },
  { line: "MG UBERABA;5401;3170107" },
  { line: "MG UBERLANDIA;5403;3170206" },
  { line: "MG UMBURATIBA;5405;3170305" },
  { line: "MG UNAI;5407;3170404" },
  { line: "MG UNIAO DE MINAS;742;3170438" },
  { line: "MG URUANA DE MINAS;744;3170479" },
  { line: "MG URUCANIA;5409;3170503" },
  { line: "MG URUCUIA;2699;3170529" },
  { line: "MG VARGEM ALEGRE;746;3170578" },
  { line: "MG VARGEM BONITA;5411;3170602" },
  { line: "MG VARGEM GRANDE DO RIO PARDO;748;3170651" },
  { line: "MG VARGINHA;5413;3170701" },
  { line: "MG VARJAO DE MINAS;750;3170750" },
  { line: "MG VARZEA DA PALMA;5415;3170800" },
  { line: "MG VARZELANDIA;5417;3170909" },
  { line: "MG VAZANTE;5419;3171006" },
  { line: "MG VERDELANDIA;752;3171030" },
  { line: "MG VEREDINHA;754;3171071" },
  { line: "MG VERISSIMO;5423;3171105" },
  { line: "MG VERMELHO NOVO;756;3171154" },
  { line: "MG VESPASIANO;5425;3171204" },
  { line: "MG VICOSA;5427;3171303" },
  { line: "MG VIEIRAS;5429;3171402" },
  { line: "MG VIRGEM DA LAPA;5433;3171600" },
  { line: "MG VIRGINIA;5435;3171709" },
  { line: "MG VIRGINOPOLIS;5437;3171808" },
  { line: "MG VIRGOLANDIA;5439;3171907" },
  { line: "MG VISCONDE DO RIO BRANCO;5441;3172004" },
  { line: "MG VOLTA GRANDE;5443;3172103" },
  { line: "MG WENCESLAU BRAZ;5421;3172202" },
  { line: "ES AFONSO CLAUDIO;5601;3200102" },
  { line: "ES AGUA DOCE DO NORTE;5717;3200169" },
  { line: "ES AGUIA BRANCA;5733;3200136" },
  { line: "ES ALEGRE;5603;3200201" },
  { line: "ES ALFREDO CHAVES;5605;3200300" },
  { line: "ES ALTO RIO NOVO;5719;3200359" },
  { line: "ES ANCHIETA;5607;3200409" },
  { line: "RJ ANGRA DOS REIS;5801;3300100" },
  { line: "RJ APERIBE;2919;3300159" },
  { line: "ES APIACA;5609;3200508" },
  { line: "ES ARACRUZ;5611;3200607" },
  { line: "RJ ARARUAMA;5803;3300209" },
  { line: "RJ AREAL;2925;3300225" },
  { line: "RJ ARMACAO DOS BUZIOS;770;3300233" },
  { line: "RJ ARRAIAL DO CABO;5927;3300258" },
  { line: "ES ATILIO VIVACQUA;5613;3200706" },
  { line: "ES BAIXO GUANDU;5615;3200805" },
  { line: "ES BARRA DE SAO FRANCISCO;5617;3200904" },
  { line: "RJ BARRA DO PIRAI;5805;3300308" },
  { line: "RJ BARRA MANSA;5807;3300407" },
  { line: "RJ BELFORD ROXO;2909;3300456" },
  { line: "ES BOA ESPERANCA;5619;3201001" },
  { line: "RJ BOM JARDIM;5809;3300506" },
  { line: "RJ BOM JESUS DO ITABAPOANA;5811;3300605" },
  { line: "ES BOM JESUS DO NORTE;5621;3201100" },
  { line: "ES BREJETUBA;758;3201159" },
  { line: "RJ CABO FRIO;5813;3300704" },
  { line: "RJ CACHOEIRAS DE MACACU;5815;3300803" },
  { line: "ES CACHOEIRO DE ITAPEMIRIM;5623;3201209" },
  { line: "RJ CAMBUCI;5817;3300902" },
  { line: "RJ CAMPOS DOS GOYTACAZES;5819;3301009" },
  { line: "RJ CANTAGALO;5821;3301108" },
  { line: "RJ CARAPEBUS;772;3300936" },
  { line: "RJ CARDOSO MOREIRA;2915;3301157" },
  { line: "ES CARIACICA;5625;3201308" },
  { line: "RJ CARMO;5823;3301207" },
  { line: "RJ CASIMIRO DE ABREU;5825;3301306" },
  { line: "ES CASTELO;5627;3201407" },
  { line: "ES COLATINA;5629;3201506" },
  { line: "RJ COMENDADOR LEVY GASPARIAN;2927;3300951" },
  { line: "ES CONCEICAO DA BARRA;5631;3201605" },
  { line: "RJ CONCEICAO DE MACABU;5827;3301405" },
  { line: "ES CONCEICAO DO CASTELO;5633;3201704" },
  { line: "RJ CORDEIRO;5829;3301504" },
  { line: "ES DIVINO DE SAO LOURENCO;5635;3201803" },
  { line: "ES DOMINGOS MARTINS;5637;3201902" },
  { line: "ES DORES DO RIO PRETO;5639;3202009" },
  { line: "RJ DUAS BARRAS;5831;3301603" },
  { line: "RJ DUQUE DE CAXIAS;5833;3301702" },
  { line: "ES ECOPORANGA;5641;3202108" },
  { line: "RJ ENGENHEIRO PAULO DE FRONTIN;5835;3301801" },
  { line: "ES FUNDAO;5643;3202207" },
  { line: "ES GOVERNADOR LINDENBERG;1114;3202256" },
  { line: "ES GUACUI;5645;3202306" },
  { line: "RJ GUAPIMIRIM;2907;3301850" },
  { line: "ES GUARAPARI;5647;3202405" },
  { line: "ES IBATIBA;5709;3202454" },
  { line: "ES IBIRACU;5649;3202504" },
  { line: "ES IBITIRAMA;6011;3202553" },
  { line: "ES ICONHA;5651;3202603" },
  { line: "RJ IGUABA GRANDE;774;3301876" },
  { line: "ES IRUPI;2931;3202652" },
  { line: "RJ ITABORAI;5837;3301900" },
  { line: "ES ITAGUACU;5653;3202702" },
  { line: "RJ ITAGUAI;5839;3302007" },
  { line: "RJ ITALVA;5929;3302056" },
  { line: "RJ ITAOCARA;5841;3302106" },
  { line: "ES ITAPEMIRIM;5655;3202801" },
  { line: "RJ ITAPERUNA;5843;3302205" },
  { line: "ES ITARANA;5657;3202900" },
  { line: "RJ ITATIAIA;6003;3302254" },
  { line: "ES IUNA;5659;3203007" },
  { line: "ES JAGUARE;5713;3203056" },
  { line: "RJ JAPERI;2913;3302270" },
  { line: "ES JERONIMO MONTEIRO;5661;3203106" },
  { line: "ES JOAO NEIVA;5721;3203130" },
  { line: "RJ LAJE DO MURIAE;5845;3302304" },
  { line: "ES LARANJA DA TERRA;5723;3203163" },
  { line: "ES LINHARES;5663;3203205" },
  { line: "RJ MACAE;5847;3302403" },
  { line: "RJ MACUCO;776;3302452" },
  { line: "RJ MAGE;5849;3302502" },
  { line: "RJ MANGARATIBA;5851;3302601" },
  { line: "ES MANTENOPOLIS;5665;3203304" },
  { line: "ES MARATAIZES;760;3203320" },
  { line: "ES MARECHAL FLORIANO;2929;3203346" },
  { line: "RJ MARICA;5853;3302700" },
  { line: "ES MARILANDIA;5707;3203353" },
  { line: "RJ MENDES;5855;3302809" },
  { line: "RJ MESQUITA;1116;3302858" },
  { line: "RJ MIGUEL PEREIRA;5857;3302908" },
  { line: "ES MIMOSO DO SUL;5667;3203403" },
  { line: "RJ MIRACEMA;5859;3303005" },
  { line: "ES MONTANHA;5669;3203502" },
  { line: "ES MUCURICI;5671;3203601" },
  { line: "ES MUNIZ FREIRE;5673;3203700" },
  { line: "ES MUQUI;5675;3203809" },
  { line: "RJ NATIVIDADE;5861;3303104" },
  { line: "RJ NILOPOLIS;5863;3303203" },
  { line: "RJ NITEROI;5865;3303302" },
  { line: "RJ NOVA FRIBURGO;5867;3303401" },
  { line: "RJ NOVA IGUACU;5869;3303500" },
  { line: "ES NOVA VENECIA;5677;3203908" },
  { line: "ES PANCAS;5679;3204005" },
  { line: "RJ PARACAMBI;5871;3303609" },
  { line: "RJ PARAIBA DO SUL;5873;3303708" },
  { line: "RJ PARATY;5875;3303807" },
  { line: "RJ PATY DO ALFERES;6005;3303856" },
  { line: "ES PEDRO CANARIO;5715;3204054" },
  { line: "RJ PETROPOLIS;5877;3303906" },
  { line: "RJ PINHEIRAL;778;3303955" },
  { line: "ES PINHEIROS;5681;3204104" },
  { line: "RJ PIRAI;5879;3304003" },
  { line: "ES PIUMA;5683;3204203" },
  { line: "ES PONTO BELO;762;3204252" },
  { line: "RJ PORCIUNCULA;5881;3304102" },
  { line: "RJ PORTO REAL;780;3304110" },
  { line: "ES PRESIDENTE KENNEDY;5685;3204302" },
  { line: "RJ QUATIS;2923;3304128" },
  { line: "RJ QUEIMADOS;2911;3304144" },
  { line: "RJ QUISSAMA;6007;3304151" },
  { line: "RJ RESENDE;5883;3304201" },
  { line: "ES RIO BANANAL;5711;3204351" },
  { line: "RJ RIO BONITO;5885;3304300" },
  { line: "RJ RIO CLARO;5887;3304409" },
  { line: "RJ RIO DAS FLORES;5889;3304508" },
  { line: "RJ RIO DAS OSTRAS;2921;3304524" },
  { line: "RJ RIO DE JANEIRO;6001;3304557" },
  { line: "RJ RIO DE JANEIRO;6001;3304557" },
  { line: "ES RIO NOVO DO SUL;5687;3204401" },
  { line: "ES SANTA LEOPOLDINA;5689;3204500" },
  { line: "ES SANTA MARIA DE JETIBA;5725;3204559" },
  { line: "RJ SANTA MARIA MADALENA;5891;3304607" },
  { line: "ES SANTA TERESA;5691;3204609" },
  { line: "RJ SANTO ANTONIO DE PADUA;5893;3304706" },
  { line: "ES SAO DOMINGOS DO NORTE;2933;3204658" },
  { line: "RJ SAO FIDELIS;5895;3304805" },
  { line: "RJ SAO FRANCISCO DE ITABAPOANA;782;3304755" },
  { line: "ES SAO GABRIEL DA PALHA;5693;3204708" },
  { line: "RJ SAO GONCALO;5897;3304904" },
  { line: "RJ SAO JOAO DA BARRA;5899;3305000" },
  { line: "RJ SAO JOAO DE MERITI;5901;3305109" },
  { line: "RJ SAO JOSE DE UBA;784;3305133" },
  { line: "ES SAO JOSE DO CALCADO;5695;3204807" },
  { line: "RJ SAO JOSE DO VALE DO RIO PRETO;6009;3305158" },
  { line: "ES SAO MATEUS;5697;3204906" },
  { line: "RJ SAO PEDRO DA ALDEIA;5903;3305208" },
  { line: "ES SAO ROQUE DO CANAA;764;3204955" },
  { line: "RJ SAO SEBASTIAO DO ALTO;5905;3305307" },
  { line: "RJ SAPUCAIA;5907;3305406" },
  { line: "RJ SAQUAREMA;5909;3305505" },
  { line: "RJ SEROPEDICA;786;3305554" },
  { line: "ES SERRA;5699;3205002" },
  { line: "RJ SILVA JARDIM;5911;3305604" },
  { line: "ES SOORETAMA;766;3205010" },
  { line: "RJ SUMIDOURO;5913;3305703" },
  { line: "RJ TANGUA;788;3305752" },
  { line: "RJ TERESOPOLIS;5915;3305802" },
  { line: "RJ TRAJANO DE MORAES;5917;3305901" },
  { line: "RJ TRES RIOS;5919;3306008" },
  { line: "RJ VALENCA;5921;3306107" },
  { line: "ES VARGEM ALTA;5727;3205036" },
  { line: "RJ VARRE-SAI;2917;3306156" },
  { line: "RJ VASSOURAS;5923;3306206" },
  { line: "ES VENDA NOVA DO IMIGRANTE;5729;3205069" },
  { line: "ES VIANA;5701;3205101" },
  { line: "ES VILA PAVAO;2935;3205150" },
  { line: "ES VILA VALERIO;768;3205176" },
  { line: "ES VILA VELHA;5703;3205200" },
  { line: "ES VITORIA;5705;3205309" },
  { line: "RJ VOLTA REDONDA;5925;3306305" },
  { line: "SP ADAMANTINA;6101;3500105" },
  { line: "SP ADOLFO;6103;3500204" },
  { line: "SP AGUAI;6105;3500303" },
  { line: "SP AGUAS DA PRATA;6107;3500402" },
  { line: "SP AGUAS DE LINDOIA;6109;3500501" },
  { line: "SP AGUAS DE SANTA BARBARA;7019;3500550" },
  { line: "SP AGUAS DE SAO PEDRO;6111;3500600" },
  { line: "SP AGUDOS;6113;3500709" },
  { line: "SP ALAMBARI;2995;3500758" },
  { line: "SP ALFREDO MARCONDES;6115;3500808" },
  { line: "SP ALTAIR;6117;3500907" },
  { line: "SP ALTINOPOLIS;6119;3501004" },
  { line: "SP ALTO ALEGRE;6121;3501103" },
  { line: "SP ALUMINIO;3065;3501152" },
  { line: "SP ALVARES FLORENCE;6123;3501202" },
  { line: "SP ALVARES MACHADO;6125;3501301" },
  { line: "SP ALVARO DE CARVALHO;6127;3501400" },
  { line: "SP ALVINLANDIA;6129;3501509" },
  { line: "SP AMERICANA;6131;3501608" },
  { line: "SP AMERICO BRASILIENSE;6133;3501707" },
  { line: "SP AMERICO DE CAMPOS;6135;3501806" },
  { line: "SP AMPARO;6137;3501905" },
  { line: "SP ANALANDIA;6139;3502002" },
  { line: "SP ANDRADINA;6141;3502101" },
  { line: "SP ANGATUBA;6143;3502200" },
  { line: "SP ANHEMBI;6145;3502309" },
  { line: "SP ANHUMAS;6147;3502408" },
  { line: "SP APARECIDA;6149;3502507" },
  { line: "SP APARECIDA D'OESTE;6151;3502606" },
  { line: "SP APIAI;6153;3502705" },
  { line: "SP ARACARIGUAMA;3067;3502754" },
  { line: "SP ARACATUBA;6155;3502804" },
  { line: "SP ARACOIABA DA SERRA;6157;3502903" },
  { line: "SP ARAMINA;6159;3503000" },
  { line: "SP ARANDU;6161;3503109" },
  { line: "SP ARAPEI;2991;3503158" },
  { line: "SP ARARAQUARA;6163;3503208" },
  { line: "SP ARARAS;6165;3503307" },
  { line: "SP ARCO-IRIS;790;3503356" },
  { line: "SP AREALVA;6167;3503406" },
  { line: "SP AREIAS;6169;3503505" },
  { line: "SP AREIOPOLIS;6171;3503604" },
  { line: "SP ARIRANHA;6173;3503703" },
  { line: "SP ARTUR NOGUEIRA;6175;3503802" },
  { line: "SP ARUJA;6177;3503901" },
  { line: "SP ASPASIA;2981;3503950" },
  { line: "SP ASSIS;6179;3504008" },
  { line: "SP ATIBAIA;6181;3504107" },
  { line: "SP AURIFLAMA;6183;3504206" },
  { line: "SP AVAI;6185;3504305" },
  { line: "SP AVANHANDAVA;6187;3504404" },
  { line: "SP AVARE;6189;3504503" },
  { line: "SP BADY BASSITT;6191;3504602" },
  { line: "SP BALBINOS;6193;3504701" },
  { line: "SP BALSAMO;6195;3504800" },
  { line: "SP BANANAL;6197;3504909" },
  { line: "SP BARAO DE ANTONINA;6201;3505005" },
  { line: "SP BARBOSA;6199;3505104" },
  { line: "SP BARIRI;6203;3505203" },
  { line: "SP BARRA BONITA;6205;3505302" },
  { line: "SP BARRA DO CHAPEU;2997;3505351" },
  { line: "SP BARRA DO TURVO;6207;3505401" },
  { line: "SP BARRETOS;6209;3505500" },
  { line: "SP BARRINHA;6211;3505609" },
  { line: "SP BARUERI;6213;3505708" },
  { line: "SP BASTOS;6215;3505807" },
  { line: "SP BATATAIS;6217;3505906" },
  { line: "SP BAURU;6219;3506003" },
  { line: "SP BEBEDOURO;6221;3506102" },
  { line: "SP BENTO DE ABREU;6223;3506201" },
  { line: "SP BERNARDINO DE CAMPOS;6225;3506300" },
  { line: "SP BERTIOGA;2965;3506359" },
  { line: "SP BILAC;6227;3506409" },
  { line: "SP BIRIGUI;6229;3506508" },
  { line: "SP BIRITIBA MIRIM;6231;3506607" },
  { line: "SP BOA ESPERANCA DO SUL;6233;3506706" },
  { line: "SP BOCAINA;6235;3506805" },
  { line: "SP BOFETE;6237;3506904" },
  { line: "SP BOITUVA;6239;3507001" },
  { line: "SP BOM JESUS DOS PERDOES;6241;3507100" },
  { line: "SP BOM SUCESSO DE ITARARE;3059;3507159" },
  { line: "SP BORA;6243;3507209" },
  { line: "SP BORACEIA;6245;3507308" },
  { line: "SP BORBOREMA;6247;3507407" },
  { line: "SP BOREBI;7247;3507456" },
  { line: "SP BOTUCATU;6249;3507506" },
  { line: "SP BRAGANCA PAULISTA;6251;3507605" },
  { line: "SP BRAUNA;6255;3507704" },
  { line: "SP BREJO ALEGRE;792;3507753" },
  { line: "SP BRODOWSKI;6257;3507803" },
  { line: "SP BROTAS;6259;3507902" },
  { line: "SP BURI;6261;3508009" },
  { line: "SP BURITAMA;6263;3508108" },
  { line: "SP BURITIZAL;6265;3508207" },
  { line: "SP CABRALIA PAULISTA;6267;3508306" },
  { line: "SP CABREUVA;6269;3508405" },
  { line: "SP CACAPAVA;6271;3508504" },
  { line: "SP CACHOEIRA PAULISTA;6273;3508603" },
  { line: "SP CACONDE;6275;3508702" },
  { line: "SP CAFELANDIA;6277;3508801" },
  { line: "SP CAIABU;6279;3508900" },
  { line: "SP CAIEIRAS;6281;3509007" },
  { line: "SP CAIUA;6283;3509106" },
  { line: "SP CAJAMAR;6285;3509205" },
  { line: "SP CAJATI;2967;3509254" },
  { line: "SP CAJOBI;6287;3509304" },
  { line: "SP CAJURU;6289;3509403" },
  { line: "SP CAMPINA DO MONTE ALEGRE;2999;3509452" },
  { line: "SP CAMPINAS;6291;3509502" },
  { line: "SP CAMPO LIMPO PAULISTA;6293;3509601" },
  { line: "SP CAMPOS DO JORDAO;6295;3509700" },
  { line: "SP CAMPOS NOVOS PAULISTA;6297;3509809" },
  { line: "SP CANANEIA;6299;3509908" },
  { line: "SP CANAS;794;3509957" },
  { line: "SP CANDIDO MOTA;6301;3510005" },
  { line: "SP CANDIDO RODRIGUES;6303;3510104" },
  { line: "SP CANITAR;2947;3510153" },
  { line: "SP CAPAO BONITO;6305;3510203" },
  { line: "SP CAPELA DO ALTO;6307;3510302" },
  { line: "SP CAPIVARI;6309;3510401" },
  { line: "SP CARAGUATATUBA;6311;3510500" },
  { line: "SP CARAPICUIBA;6313;3510609" },
  { line: "SP CARDOSO;6315;3510708" },
  { line: "SP CASA BRANCA;6317;3510807" },
  { line: "SP CASSIA DOS COQUEIROS;6319;3510906" },
  { line: "SP CASTILHO;6321;3511003" },
  { line: "SP CATANDUVA;6323;3511102" },
  { line: "SP CATIGUA;6325;3511201" },
  { line: "SP CEDRAL;6327;3511300" },
  { line: "SP CERQUEIRA CESAR;6329;3511409" },
  { line: "SP CERQUILHO;6331;3511508" },
  { line: "SP CESARIO LANGE;6333;3511607" },
  { line: "SP CHARQUEADA;6335;3511706" },
  { line: "SP CHAVANTES;6337;3557204" },
  { line: "SP CLEMENTINA;6339;3511904" },
  { line: "SP COLINA;6341;3512001" },
  { line: "SP COLOMBIA;6343;3512100" },
  { line: "SP CONCHAL;6345;3512209" },
  { line: "SP CONCHAS;6347;3512308" },
  { line: "SP CORDEIROPOLIS;6349;3512407" },
  { line: "SP COROADOS;6351;3512506" },
  { line: "SP CORONEL MACEDO;6353;3512605" },
  { line: "SP CORUMBATAI;6355;3512704" },
  { line: "SP COSMOPOLIS;6357;3512803" },
  { line: "SP COSMORAMA;6359;3512902" },
  { line: "SP COTIA;6361;3513009" },
  { line: "SP CRAVINHOS;6363;3513108" },
  { line: "SP CRISTAIS PAULISTA;6365;3513207" },
  { line: "SP CRUZALIA;6367;3513306" },
  { line: "SP CRUZEIRO;6369;3513405" },
  { line: "SP CUBATAO;6371;3513504" },
  { line: "SP CUNHA;6373;3513603" },
  { line: "SP DESCALVADO;6375;3513702" },
  { line: "SP DIADEMA;6377;3513801" },
  { line: "SP DIRCE REIS;7249;3513850" },
  { line: "SP DIVINOLANDIA;6379;3513900" },
  { line: "SP DOBRADA;6381;3514007" },
  { line: "SP DOIS CORREGOS;6383;3514106" },
  { line: "SP DOLCINOPOLIS;6385;3514205" },
  { line: "SP DOURADO;6387;3514304" },
  { line: "SP DRACENA;6389;3514403" },
  { line: "SP DUARTINA;6391;3514502" },
  { line: "SP DUMONT;6393;3514601" },
  { line: "SP ECHAPORA;6395;3514700" },
  { line: "SP ELDORADO;6397;3514809" },
  { line: "SP ELIAS FAUSTO;6399;3514908" },
  { line: "SP ELISIARIO;2975;3514924" },
  { line: "SP EMBAUBA;7251;3514957" },
  { line: "SP EMBU DAS ARTES;6401;3515004" },
  { line: "SP EMBU-GUACU;6403;3515103" },
  { line: "SP EMILIANOPOLIS;2961;3515129" },
  { line: "SP ENGENHEIRO COELHO;2949;3515152" },
  { line: "SP ESPIRITO SANTO DO PINHAL;6865;3515186" },
  { line: "SP ESPIRITO SANTO DO TURVO;7253;3515194" },
  { line: "SP ESTIVA GERBI;2959;3557303" },
  { line: "SP ESTRELA DO NORTE;6407;3515301" },
  { line: "SP ESTRELA D'OESTE;6405;3515202" },
  { line: "SP EUCLIDES DA CUNHA PAULISTA;7255;3515350" },
  { line: "SP FARTURA;6409;3515400" },
  { line: "SP FERNANDO PRESTES;6413;3515608" },
  { line: "SP FERNANDOPOLIS;6411;3515509" },
  { line: "SP FERNAO;796;3515657" },
  { line: "SP FERRAZ DE VASCONCELOS;6415;3515707" },
  { line: "SP FLORA RICA;6417;3515806" },
  { line: "SP FLOREAL;6419;3515905" },
  { line: "SP FLORIDA PAULISTA;6421;3516002" },
  { line: "SP FLORINEA;6423;3516101" },
  { line: "SP FRANCA;6425;3516200" },
  { line: "SP FRANCISCO MORATO;6427;3516309" },
  { line: "SP FRANCO DA ROCHA;6429;3516408" },
  { line: "SP GABRIEL MONTEIRO;6431;3516507" },
  { line: "SP GALIA;6433;3516606" },
  { line: "SP GARCA;6435;3516705" },
  { line: "SP GASTAO VIDIGAL;6437;3516804" },
  { line: "SP GAVIAO PEIXOTO;798;3516853" },
  { line: "SP GENERAL SALGADO;6439;3516903" },
  { line: "SP GETULINA;6441;3517000" },
  { line: "SP GLICERIO;6443;3517109" },
  { line: "SP GUAICARA;6445;3517208" },
  { line: "SP GUAIMBE;6447;3517307" },
  { line: "SP GUAIRA;6449;3517406" },
  { line: "SP GUAPIACU;6451;3517505" },
  { line: "SP GUAPIARA;6453;3517604" },
  { line: "SP GUARA;6455;3517703" },
  { line: "SP GUARACAI;6457;3517802" },
  { line: "SP GUARACI;6459;3517901" },
  { line: "SP GUARANI D'OESTE;6461;3518008" },
  { line: "SP GUARANTA;6463;3518107" },
  { line: "SP GUARARAPES;6465;3518206" },
  { line: "SP GUARAREMA;6467;3518305" },
  { line: "SP GUARATINGUETA;6469;3518404" },
  { line: "SP GUAREI;6471;3518503" },
  { line: "SP GUARIBA;6473;3518602" },
  { line: "SP GUARUJA;6475;3518701" },
  { line: "SP GUARULHOS;6477;3518800" },
  { line: "SP GUATAPARA;7257;3518859" },
  { line: "SP GUZOLANDIA;6479;3518909" },
  { line: "SP HERCULANDIA;6481;3519006" },
  { line: "SP HOLAMBRA;2953;3519055" },
  { line: "SP HORTOLANDIA;2951;3519071" },
  { line: "SP IACANGA;6483;3519105" },
  { line: "SP IACRI;6485;3519204" },
  { line: "SP IARAS;7259;3519253" },
  { line: "SP IBATE;6487;3519303" },
  { line: "SP IBIRA;6489;3519402" },
  { line: "SP IBIRAREMA;6491;3519501" },
  { line: "SP IBITINGA;6493;3519600" },
  { line: "SP IBIUNA;6495;3519709" },
  { line: "SP ICEM;6497;3519808" },
  { line: "SP IEPE;6499;3519907" },
  { line: "SP IGARACU DO TIETE;6501;3520004" },
  { line: "SP IGARAPAVA;6503;3520103" },
  { line: "SP IGARATA;6505;3520202" },
  { line: "SP IGUAPE;6507;3520301" },
  { line: "SP ILHA COMPRIDA;2969;3520426" },
  { line: "SP ILHA SOLTEIRA;2943;3520442" },
  { line: "SP ILHABELA;6509;3520400" },
  { line: "SP INDAIATUBA;6511;3520509" },
  { line: "SP INDIANA;6513;3520608" },
  { line: "SP INDIAPORA;6515;3520707" },
  { line: "SP INUBIA PAULISTA;6517;3520806" },
  { line: "SP IPAUSSU;6519;3520905" },
  { line: "SP IPERO;6521;3521002" },
  { line: "SP IPEUNA;6523;3521101" },
  { line: "SP IPIGUA;800;3521150" },
  { line: "SP IPORANGA;6525;3521200" },
  { line: "SP IPUA;6527;3521309" },
  { line: "SP IRACEMAPOLIS;6529;3521408" },
  { line: "SP IRAPUA;6531;3521507" },
  { line: "SP IRAPURU;6533;3521606" },
  { line: "SP ITABERA;6535;3521705" },
  { line: "SP ITAI;6537;3521804" },
  { line: "SP ITAJOBI;6539;3521903" },
  { line: "SP ITAJU;6541;3522000" },
  { line: "SP ITANHAEM;6543;3522109" },
  { line: "SP ITAOCA;3053;3522158" },
  { line: "SP ITAPECERICA DA SERRA;6545;3522208" },
  { line: "SP ITAPETININGA;6547;3522307" },
  { line: "SP ITAPEVA;6549;3522406" },
  { line: "SP ITAPEVI;6551;3522505" },
  { line: "SP ITAPIRA;6553;3522604" },
  { line: "SP ITAPIRAPUA PAULISTA;3055;3522653" },
  { line: "SP ITAPOLIS;6555;3522703" },
  { line: "SP ITAPORANGA;6557;3522802" },
  { line: "SP ITAPUI;6559;3522901" },
  { line: "SP ITAPURA;6561;3523008" },
  { line: "SP ITAQUAQUECETUBA;6563;3523107" },
  { line: "SP ITARARE;6565;3523206" },
  { line: "SP ITARIRI;6567;3523305" },
  { line: "SP ITATIBA;6569;3523404" },
  { line: "SP ITATINGA;6571;3523503" },
  { line: "SP ITIRAPINA;6573;3523602" },
  { line: "SP ITIRAPUA;6575;3523701" },
  { line: "SP ITOBI;6577;3523800" },
  { line: "SP ITU;6579;3523909" },
  { line: "SP ITUPEVA;6581;3524006" },
  { line: "SP ITUVERAVA;6583;3524105" },
  { line: "SP JABORANDI;6585;3524204" },
  { line: "SP JABOTICABAL;6587;3524303" },
  { line: "SP JACAREI;6589;3524402" },
  { line: "SP JACI;6591;3524501" },
  { line: "SP JACUPIRANGA;6593;3524600" },
  { line: "SP JAGUARIUNA;6595;3524709" },
  { line: "SP JALES;6597;3524808" },
  { line: "SP JAMBEIRO;6599;3524907" },
  { line: "SP JANDIRA;6601;3525003" },
  { line: "SP JARDINOPOLIS;6603;3525102" },
  { line: "SP JARINU;6605;3525201" },
  { line: "SP JAU;6607;3525300" },
  { line: "SP JERIQUARA;6609;3525409" },
  { line: "SP JOANOPOLIS;6611;3525508" },
  { line: "SP JOAO RAMALHO;6613;3525607" },
  { line: "SP JOSE BONIFACIO;6615;3525706" },
  { line: "SP JULIO MESQUITA;6617;3525805" },
  { line: "SP JUMIRIM;802;3525854" },
  { line: "SP JUNDIAI;6619;3525904" },
  { line: "SP JUNQUEIROPOLIS;6621;3526001" },
  { line: "SP JUQUIA;6623;3526100" },
  { line: "SP JUQUITIBA;6625;3526209" },
  { line: "SP LAGOINHA;6627;3526308" },
  { line: "SP LARANJAL PAULISTA;6629;3526407" },
  { line: "SP LAVINIA;6631;3526506" },
  { line: "SP LAVRINHAS;6633;3526605" },
  { line: "SP LEME;6635;3526704" },
  { line: "SP LENCOIS PAULISTA;6637;3526803" },
  { line: "SP LIMEIRA;6639;3526902" },
  { line: "SP LINDOIA;6641;3527009" },
  { line: "SP LINS;6643;3527108" },
  { line: "SP LORENA;6645;3527207" },
  { line: "SP LOURDES;2937;3527256" },
  { line: "SP LOUVEIRA;6647;3527306" },
  { line: "SP LUCELIA;6649;3527405" },
  { line: "SP LUCIANOPOLIS;6651;3527504" },
  { line: "SP LUIS ANTONIO;6653;3527603" },
  { line: "SP LUIZIANIA;6655;3527702" },
  { line: "SP LUPERCIO;6657;3527801" },
  { line: "SP LUTECIA;6659;3527900" },
  { line: "SP MACATUBA;6661;3528007" },
  { line: "SP MACAUBAL;6663;3528106" },
  { line: "SP MACEDONIA;6665;3528205" },
  { line: "SP MAGDA;6667;3528304" },
  { line: "SP MAIRINQUE;6669;3528403" },
  { line: "SP MAIRIPORA;6671;3528502" },
  { line: "SP MANDURI;6673;3528601" },
  { line: "SP MARABA PAULISTA;6675;3528700" },
  { line: "SP MARACAI;6677;3528809" },
  { line: "SP MARAPOAMA;2977;3528858" },
  { line: "SP MARIAPOLIS;6679;3528908" },
  { line: "SP MARILIA;6681;3529005" },
  { line: "SP MARINOPOLIS;6683;3529104" },
  { line: "SP MARTINOPOLIS;6685;3529203" },
  { line: "SP MATAO;6687;3529302" },
  { line: "SP MAUA;6689;3529401" },
  { line: "SP MENDONCA;6691;3529500" },
  { line: "SP MERIDIANO;6693;3529609" },
  { line: "SP MESOPOLIS;2983;3529658" },
  { line: "SP MIGUELOPOLIS;6695;3529708" },
  { line: "SP MINEIROS DO TIETE;6697;3529807" },
  { line: "SP MIRA ESTRELA;6701;3530003" },
  { line: "SP MIRACATU;6699;3529906" },
  { line: "SP MIRANDOPOLIS;6703;3530102" },
  { line: "SP MIRANTE DO PARANAPANEMA;6705;3530201" },
  { line: "SP MIRASSOL;6707;3530300" },
  { line: "SP MIRASSOLANDIA;6709;3530409" },
  { line: "SP MOCOCA;6711;3530508" },
  { line: "SP MOGI DAS CRUZES;6713;3530607" },
  { line: "SP MOGI MIRIM;6717;3530805" },
  { line: "SP MOGI GUACU;6715;3530706" },
  { line: "SP MOMBUCA;6719;3530904" },
  { line: "SP MONCOES;6721;3531001" },
  { line: "SP MONGAGUA;6723;3531100" },
  { line: "SP MONTE ALEGRE DO SUL;6725;3531209" },
  { line: "SP MONTE ALTO;6727;3531308" },
  { line: "SP MONTE APRAZIVEL;6729;3531407" },
  { line: "SP MONTE AZUL PAULISTA;6731;3531506" },
  { line: "SP MONTE CASTELO;6733;3531605" },
  { line: "SP MONTE MOR;6737;3531803" },
  { line: "SP MONTEIRO LOBATO;6735;3531704" },
  { line: "SP MORRO AGUDO;6739;3531902" },
  { line: "SP MORUNGABA;6741;3532009" },
  { line: "SP MOTUCA;7263;3532058" },
  { line: "SP MURUTINGA DO SUL;6743;3532108" },
  { line: "SP NANTES;804;3532157" },
  { line: "SP NARANDIBA;6745;3532207" },
  { line: "SP NATIVIDADE DA SERRA;6747;3532306" },
  { line: "SP NAZARE PAULISTA;6749;3532405" },
  { line: "SP NEVES PAULISTA;6751;3532504" },
  { line: "SP NHANDEARA;6753;3532603" },
  { line: "SP NIPOA;6755;3532702" },
  { line: "SP NOVA ALIANCA;6757;3532801" },
  { line: "SP NOVA CAMPINA;3061;3532827" },
  { line: "SP NOVA CANAA PAULISTA;2985;3532843" },
  { line: "SP NOVA CASTILHO;806;3532868" },
  { line: "SP NOVA EUROPA;6759;3532900" },
  { line: "SP NOVA GRANADA;6761;3533007" },
  { line: "SP NOVA GUATAPORANGA;6763;3533106" },
  { line: "SP NOVA INDEPENDENCIA;6765;3533205" },
  { line: "SP NOVA LUZITANIA;6767;3533304" },
  { line: "SP NOVA ODESSA;6769;3533403" },
  { line: "SP NOVAIS;2979;3533254" },
  { line: "SP NOVO HORIZONTE;6771;3533502" },
  { line: "SP NUPORANGA;6773;3533601" },
  { line: "SP OCAUCU;6775;3533700" },
  { line: "SP OLEO;6777;3533809" },
  { line: "SP OLIMPIA;6779;3533908" },
  { line: "SP ONDA VERDE;6781;3534005" },
  { line: "SP ORIENTE;6783;3534104" },
  { line: "SP ORINDIUVA;6785;3534203" },
  { line: "SP ORLANDIA;6787;3534302" },
  { line: "SP OSASCO;6789;3534401" },
  { line: "SP OSCAR BRESSANE;6791;3534500" },
  { line: "SP OSVALDO CRUZ;6793;3534609" },
  { line: "SP OURINHOS;6795;3534708" },
  { line: "SP OURO VERDE;6797;3534807" },
  { line: "SP OUROESTE;808;3534757" },
  { line: "SP PACAEMBU;6799;3534906" },
  { line: "SP PALESTINA;6801;3535002" },
  { line: "SP PALMARES PAULISTA;6803;3535101" },
  { line: "SP PALMEIRA D'OESTE;6805;3535200" },
  { line: "SP PALMITAL;6807;3535309" },
  { line: "SP PANORAMA;6809;3535408" },
  { line: "SP PARAGUACU PAULISTA;6811;3535507" },
  { line: "SP PARAIBUNA;6813;3535606" },
  { line: "SP PARAISO;6815;3535705" },
  { line: "SP PARANAPANEMA;6817;3535804" },
  { line: "SP PARANAPUA;6819;3535903" },
  { line: "SP PARAPUA;6821;3536000" },
  { line: "SP PARDINHO;6823;3536109" },
  { line: "SP PARIQUERA-ACU;6825;3536208" },
  { line: "SP PARISI;2989;3536257" },
  { line: "SP PATROCINIO PAULISTA;6827;3536307" },
  { line: "SP PAULICEIA;6829;3536406" },
  { line: "SP PAULINIA;6831;3536505" },
  { line: "SP PAULISTANIA;810;3536570" },
  { line: "SP PAULO DE FARIA;6833;3536604" },
  { line: "SP PEDERNEIRAS;6835;3536703" },
  { line: "SP PEDRA BELA;6837;3536802" },
  { line: "SP PEDRANOPOLIS;6839;3536901" },
  { line: "SP PEDREGULHO;6841;3537008" },
  { line: "SP PEDREIRA;6843;3537107" },
  { line: "SP PEDRINHAS PAULISTA;2963;3537156" },
  { line: "SP PEDRO DE TOLEDO;6845;3537206" },
  { line: "SP PENAPOLIS;6847;3537305" },
  { line: "SP PEREIRA BARRETO;6849;3537404" },
  { line: "SP PEREIRAS;6851;3537503" },
  { line: "SP PERUIBE;6853;3537602" },
  { line: "SP PIACATU;6855;3537701" },
  { line: "SP PIEDADE;6857;3537800" },
  { line: "SP PILAR DO SUL;6859;3537909" },
  { line: "SP PINDAMONHANGABA;6861;3538006" },
  { line: "SP PINDORAMA;6863;3538105" },
  { line: "SP PINHALZINHO;6867;3538204" },
  { line: "SP PIQUEROBI;6869;3538303" },
  { line: "SP PIQUETE;6871;3538501" },
  { line: "SP PIRACAIA;6873;3538600" },
  { line: "SP PIRACICABA;6875;3538709" },
  { line: "SP PIRAJU;6877;3538808" },
  { line: "SP PIRAJUI;6879;3538907" },
  { line: "SP PIRANGI;6881;3539004" },
  { line: "SP PIRAPORA DO BOM JESUS;6883;3539103" },
  { line: "SP PIRAPOZINHO;6885;3539202" },
  { line: "SP PIRASSUNUNGA;6887;3539301" },
  { line: "SP PIRATININGA;6889;3539400" },
  { line: "SP PITANGUEIRAS;6891;3539509" },
  { line: "SP PLANALTO;6893;3539608" },
  { line: "SP PLATINA;6895;3539707" },
  { line: "SP POA;6897;3539806" },
  { line: "SP POLONI;6899;3539905" },
  { line: "SP POMPEIA;6901;3540002" },
  { line: "SP PONGAI;6903;3540101" },
  { line: "SP PONTAL;6905;3540200" },
  { line: "SP PONTALINDA;2987;3540259" },
  { line: "SP PONTES GESTAL;6907;3540309" },
  { line: "SP POPULINA;6909;3540408" },
  { line: "SP PORANGABA;6911;3540507" },
  { line: "SP PORTO FELIZ;6913;3540606" },
  { line: "SP PORTO FERREIRA;6915;3540705" },
  { line: "SP POTIM;2993;3540754" },
  { line: "SP POTIRENDABA;6917;3540804" },
  { line: "SP PRACINHA;812;3540853" },
  { line: "SP PRADOPOLIS;6919;3540903" },
  { line: "SP PRAIA GRANDE;6921;3541000" },
  { line: "SP PRATANIA;814;3541059" },
  { line: "SP PRESIDENTE ALVES;6923;3541109" },
  { line: "SP PRESIDENTE BERNARDES;6925;3541208" },
  { line: "SP PRESIDENTE EPITACIO;6927;3541307" },
  { line: "SP PRESIDENTE PRUDENTE;6929;3541406" },
  { line: "SP PRESIDENTE VENCESLAU;6931;3541505" },
  { line: "SP PROMISSAO;6933;3541604" },
  { line: "SP QUADRA;816;3541653" },
  { line: "SP QUATA;6935;3541703" },
  { line: "SP QUEIROZ;6937;3541802" },
  { line: "SP QUELUZ;6939;3541901" },
  { line: "SP QUINTANA;6941;3542008" },
  { line: "SP RAFARD;6943;3542107" },
  { line: "SP RANCHARIA;6945;3542206" },
  { line: "SP REDENCAO DA SERRA;6947;3542305" },
  { line: "SP REGENTE FEIJO;6949;3542404" },
  { line: "SP REGINOPOLIS;6951;3542503" },
  { line: "SP REGISTRO;6953;3542602" },
  { line: "SP RESTINGA;6955;3542701" },
  { line: "SP RIBEIRA;6957;3542800" },
  { line: "SP RIBEIRAO BONITO;6959;3542909" },
  { line: "SP RIBEIRAO BRANCO;6961;3543006" },
  { line: "SP RIBEIRAO CORRENTE;6963;3543105" },
  { line: "SP RIBEIRAO DO SUL;6965;3543204" },
  { line: "SP RIBEIRAO DOS INDIOS;818;3543238" },
  { line: "SP RIBEIRAO GRANDE;3057;3543253" },
  { line: "SP RIBEIRAO PIRES;6967;3543303" },
  { line: "SP RIBEIRAO PRETO;6969;3543402" },
  { line: "SP RIFAINA;6973;3543600" },
  { line: "SP RINCAO;6975;3543709" },
  { line: "SP RINOPOLIS;6977;3543808" },
  { line: "SP RIO CLARO;6979;3543907" },
  { line: "SP RIO DAS PEDRAS;6981;3544004" },
  { line: "SP RIO GRANDE DA SERRA;6983;3544103" },
  { line: "SP RIOLANDIA;6985;3544202" },
  { line: "SP RIVERSUL;6971;3543501" },
  { line: "SP ROSANA;7265;3544251" },
  { line: "SP ROSEIRA;6987;3544301" },
  { line: "SP RUBIACEA;6989;3544400" },
  { line: "SP RUBINEIA;6991;3544509" },
  { line: "SP SABINO;6993;3544608" },
  { line: "SP SAGRES;6995;3544707" },
  { line: "SP SALES;6997;3544806" },
  { line: "SP SALES OLIVEIRA;6999;3544905" },
  { line: "SP SALESOPOLIS;7001;3545001" },
  { line: "SP SALMOURAO;7003;3545100" },
  { line: "SP SALTINHO;5445;3545159" },
  { line: "SP SALTO;7005;3545209" },
  { line: "SP SALTO DE PIRAPORA;7007;3545308" },
  { line: "SP SALTO GRANDE;7009;3545407" },
  { line: "SP SANDOVALINA;7011;3545506" },
  { line: "SP SANTA ADELIA;7013;3545605" },
  { line: "SP SANTA ALBERTINA;7015;3545704" },
  { line: "SP SANTA BARBARA D'OESTE;7017;3545803" },
  { line: "SP SANTA BRANCA;7021;3546009" },
  { line: "SP SANTA CLARA D'OESTE;7023;3546108" },
  { line: "SP SANTA CRUZ DA CONCEICAO;7025;3546207" },
  { line: "SP SANTA CRUZ DA ESPERANCA;820;3546256" },
  { line: "SP SANTA CRUZ DAS PALMEIRAS;7027;3546306" },
  { line: "SP SANTA CRUZ DO RIO PARDO;7029;3546405" },
  { line: "SP SANTA ERNESTINA;7031;3546504" },
  { line: "SP SANTA FE DO SUL;7033;3546603" },
  { line: "SP SANTA GERTRUDES;7035;3546702" },
  { line: "SP SANTA ISABEL;7037;3546801" },
  { line: "SP SANTA LUCIA;7039;3546900" },
  { line: "SP SANTA MARIA DA SERRA;7041;3547007" },
  { line: "SP SANTA MERCEDES;7043;3547106" },
  { line: "SP SANTA RITA DO PASSA QUATRO;7051;3547502" },
  { line: "SP SANTA RITA D'OESTE;7049;3547403" },
  { line: "SP SANTA ROSA DE VITERBO;7053;3547601" },
  { line: "SP SANTA SALETE;822;3547650" },
  { line: "SP SANTANA DA PONTE PENSA;7045;3547205" },
  { line: "SP SANTANA DE PARNAIBA;7047;3547304" },
  { line: "SP SANTO ANASTACIO;7055;3547700" },
  { line: "SP SANTO ANDRE;7057;3547809" },
  { line: "SP SANTO ANTONIO DA ALEGRIA;7059;3547908" },
  { line: "SP SANTO ANTONIO DE POSSE;7061;3548005" },
  { line: "SP SANTO ANTONIO DO ARACANGUA;2939;3548054" },
  { line: "SP SANTO ANTONIO DO JARDIM;7063;3548104" },
  { line: "SP SANTO ANTONIO DO PINHAL;7065;3548203" },
  { line: "SP SANTO EXPEDITO;7067;3548302" },
  { line: "SP SANTOPOLIS DO AGUAPEI;7069;3548401" },
  { line: "SP SANTOS;7071;3548500" },
  { line: "SP SAO BENTO DO SAPUCAI;7073;3548609" },
  { line: "SP SAO BERNARDO DO CAMPO;7075;3548708" },
  { line: "SP SAO CAETANO DO SUL;7077;3548807" },
  { line: "SP SAO CARLOS;7079;3548906" },
  { line: "SP SAO FRANCISCO;7081;3549003" },
  { line: "SP SAO JOAO DA BOA VISTA;7083;3549102" },
  { line: "SP SAO JOAO DAS DUAS PONTES;7085;3549201" },
  { line: "SP SAO JOAO DE IRACEMA;2941;3549250" },
  { line: "SP SAO JOAO DO PAU D'ALHO;7087;3549300" },
  { line: "SP SAO JOAQUIM DA BARRA;7089;3549409" },
  { line: "SP SAO JOSE DA BELA VISTA;7091;3549508" },
  { line: "SP SAO JOSE DO BARREIRO;7093;3549607" },
  { line: "SP SAO JOSE DO RIO PARDO;7095;3549706" },
  { line: "SP SAO JOSE DO RIO PRETO;7097;3549805" },
  { line: "SP SAO JOSE DOS CAMPOS;7099;3549904" },
  { line: "SP SAO LOURENCO DA SERRA;5447;3549953" },
  { line: "SP SAO LUIZ DO PARAITINGA;7101;3550001" },
  { line: "SP SAO MANUEL;7103;3550100" },
  { line: "SP SAO MIGUEL ARCANJO;7105;3550209" },
  { line: "SP SAO PAULO;7107;3550308" },
  { line: "SP SAO PEDRO;7109;3550407" },
  { line: "SP SAO PEDRO DO TURVO;7111;3550506" },
  { line: "SP SAO ROQUE;7113;3550605" },
  { line: "SP SAO SEBASTIAO;7115;3550704" },
  { line: "SP SAO SEBASTIAO DA GRAMA;7117;3550803" },
  { line: "SP SAO SIMAO;7119;3550902" },
  { line: "SP SAO VICENTE;7121;3551009" },
  { line: "SP SARAPUI;7123;3551108" },
  { line: "SP SARUTAIA;7125;3551207" },
  { line: "SP SEBASTIANOPOLIS DO SUL;7127;3551306" },
  { line: "SP SERRA AZUL;7129;3551405" },
  { line: "SP SERRA NEGRA;7133;3551603" },
  { line: "SP SERRANA;7131;3551504" },
  { line: "SP SERTAOZINHO;7135;3551702" },
  { line: "SP SETE BARRAS;7137;3551801" },
  { line: "SP SEVERINIA;7139;3551900" },
  { line: "SP SILVEIRAS;7141;3552007" },
  { line: "SP SOCORRO;7143;3552106" },
  { line: "SP SOROCABA;7145;3552205" },
  { line: "SP SUD MENNUCCI;7147;3552304" },
  { line: "SP SUMARE;7149;3552403" },
  { line: "SP SUZANAPOLIS;2945;3552551" },
  { line: "SP SUZANO;7151;3552502" },
  { line: "SP TABAPUA;7153;3552601" },
  { line: "SP TABATINGA;7155;3552700" },
  { line: "SP TABOAO DA SERRA;7157;3552809" },
  { line: "SP TACIBA;7159;3552908" },
  { line: "SP TAGUAI;7161;3553005" },
  { line: "SP TAIACU;7163;3553104" },
  { line: "SP TAIUVA;7165;3553203" },
  { line: "SP TAMBAU;7167;3553302" },
  { line: "SP TANABI;7169;3553401" },
  { line: "SP TAPIRAI;7171;3553500" },
  { line: "SP TAPIRATIBA;7173;3553609" },
  { line: "SP TAQUARAL;824;3553658" },
  { line: "SP TAQUARITINGA;7175;3553708" },
  { line: "SP TAQUARITUBA;7177;3553807" },
  { line: "SP TAQUARIVAI;3063;3553856" },
  { line: "SP TARABAI;7179;3553906" },
  { line: "SP TARUMA;7267;3553955" },
  { line: "SP TATUI;7181;3554003" },
  { line: "SP TAUBATE;7183;3554102" },
  { line: "SP TEJUPA;7185;3554201" },
  { line: "SP TEODORO SAMPAIO;7187;3554300" },
  { line: "SP TERRA ROXA;7189;3554409" },
  { line: "SP TIETE;7191;3554508" },
  { line: "SP TIMBURI;7193;3554607" },
  { line: "SP TORRE DE PEDRA;3227;3554656" },
  { line: "SP TORRINHA;7195;3554706" },
  { line: "SP TRABIJU;826;3554755" },
  { line: "SP TREMEMBE;7197;3554805" },
  { line: "SP TRES FRONTEIRAS;7199;3554904" },
  { line: "SP TUIUTI;2955;3554953" },
  { line: "SP TUPA;7201;3555000" },
  { line: "SP TUPI PAULISTA;7203;3555109" },
  { line: "SP TURIUBA;7205;3555208" },
  { line: "SP TURMALINA;7207;3555307" },
  { line: "SP UBARANA;2971;3555356" },
  { line: "SP UBATUBA;7209;3555406" },
  { line: "SP UBIRAJARA;7211;3555505" },
  { line: "SP UCHOA;7213;3555604" },
  { line: "SP UNIAO PAULISTA;7215;3555703" },
  { line: "SP URANIA;7217;3555802" },
  { line: "SP URU;7219;3555901" },
  { line: "SP URUPES;7221;3556008" },
  { line: "SP VALENTIM GENTIL;7223;3556107" },
  { line: "SP VALINHOS;7225;3556206" },
  { line: "SP VALPARAISO;7227;3556305" },
  { line: "SP VARGEM;2957;3556354" },
  { line: "SP VARGEM GRANDE DO SUL;7231;3556404" },
  { line: "SP VARGEM GRANDE PAULISTA;7273;3556453" },
  { line: "SP VARZEA PAULISTA;7233;3556503" },
  { line: "SP VERA CRUZ;7235;3556602" },
  { line: "SP VINHEDO;7237;3556701" },
  { line: "SP VIRADOURO;7239;3556800" },
  { line: "SP VISTA ALEGRE DO ALTO;7241;3556909" },
  { line: "SP VITORIA BRASIL;828;3556958" },
  { line: "SP VOTORANTIM;7243;3557006" },
  { line: "SP VOTUPORANGA;7245;3557105" },
  { line: "SP ZACARIAS;2973;3557154" },
  { line: "PR ABATIA;7401;4100103" },
  { line: "SC ABDON BATISTA;9939;4200051" },
  { line: "SC ABELARDO LUZ;8001;4200101" },
  { line: "PR ADRIANOPOLIS;7403;4100202" },
  { line: "SC AGROLANDIA;8003;4200200" },
  { line: "SC AGRONOMICA;8005;4200309" },
  { line: "SC AGUA DOCE;8007;4200408" },
  { line: "SC AGUAS DE CHAPECO;8009;4200507" },
  { line: "SC AGUAS FRIAS;5577;4200556" },
  { line: "SC AGUAS MORNAS;8011;4200606" },
  { line: "PR AGUDOS DO SUL;7405;4100301" },
  { line: "SC ALFREDO WAGNER;8013;4200705" },
  { line: "PR ALMIRANTE TAMANDARE;7407;4100400" },
  { line: "PR ALTAMIRA DO PARANA;8455;4100459" },
  { line: "SC ALTO BELA VISTA;886;4200754" },
  { line: "PR ALTO PARAISO;5523;4128625" },
  { line: "PR ALTO PARANA;7409;4100608" },
  { line: "PR ALTO PIQUIRI;7411;4100707" },
  { line: "PR ALTONIA;7951;4100509" },
  { line: "PR ALVORADA DO SUL;7413;4100806" },
  { line: "PR AMAPORA;7415;4100905" },
  { line: "PR AMPERE;7417;4101002" },
  { line: "PR ANAHY;5463;4101051" },
  { line: "SC ANCHIETA;8015;4200804" },
  { line: "PR ANDIRA;7419;4101101" },
  { line: "SC ANGELINA;8017;4200903" },
  { line: "PR ANGULO;5509;4101150" },
  { line: "SC ANITA GARIBALDI;8019;4201000" },
  { line: "SC ANITAPOLIS;8021;4201109" },
  { line: "PR ANTONINA;7421;4101200" },
  { line: "SC ANTONIO CARLOS;8023;4201208" },
  { line: "PR ANTONIO OLINTO;7423;4101309" },
  { line: "SC APIUNA;9941;4201257" },
  { line: "PR APUCARANA;7425;4101408" },
  { line: "SC ARABUTA;5597;4201273" },
  { line: "PR ARAPONGAS;7427;4101507" },
  { line: "PR ARAPOTI;7429;4101606" },
  { line: "PR ARAPUA;830;4101655" },
  { line: "SC ARAQUARI;8025;4201307" },
  { line: "SC ARARANGUA;8027;4201406" },
  { line: "PR ARARUNA;7431;4101705" },
  { line: "PR ARAUCARIA;7435;4101804" },
  { line: "PR ARIRANHA DO IVAI;832;4101853" },
  { line: "SC ARMAZEM;8029;4201505" },
  { line: "SC ARROIO TRINTA;8031;4201604" },
  { line: "SC ARVOREDO;5599;4201653" },
  { line: "SC ASCURRA;8033;4201703" },
  { line: "PR ASSAI;7437;4101903" },
  { line: "PR ASSIS CHATEAUBRIAND;7953;4102000" },
  { line: "PR ASTORGA;7439;4102109" },
  { line: "PR ATALAIA;7441;4102208" },
  { line: "SC ATALANTA;8035;4201802" },
  { line: "SC AURORA;8037;4201901" },
  { line: "SC BALNEARIO ARROIO DO SILVA;888;4201950" },
  { line: "SC BALNEARIO BARRA DO SUL;5549;4202057" },
  { line: "SC BALNEARIO CAMBORIU;8039;4202008" },
  { line: "SC BALNEARIO DE PICARRAS;8251;4212809" },
  { line: "SC BALNEARIO GAIVOTA;890;4202073" },
  { line: "SC BALNEARIO RINCAO;1192;4220000" },
  { line: "PR BALSA NOVA;7443;4102307" },
  { line: "SC BANDEIRANTE;892;4202081" },
  { line: "PR BANDEIRANTES;7445;4102406" },
  { line: "PR BARBOSA FERRAZ;7447;4102505" },
  { line: "SC BARRA BONITA;894;4202099" },
  { line: "PR BARRA DO JACARE;7451;4102703" },
  { line: "SC BARRA VELHA;8041;4202107" },
  { line: "PR BARRACAO;7449;4102604" },
  { line: "PR BELA VISTA DA CAROBA;834;4102752" },
  { line: "PR BELA VISTA DO PARAISO;7453;4102802" },
  { line: "SC BELA VISTA DO TOLDO;896;4202131" },
  { line: "SC BELMONTE;5745;4202156" },
  { line: "SC BENEDITO NOVO;8043;4202206" },
  { line: "SC BIGUACU;8045;4202305" },
  { line: "PR BITURUNA;7455;4102901" },
  { line: "SC BLUMENAU;8047;4202404" },
  { line: "PR BOA ESPERANCA;7457;4103008" },
  { line: "PR BOA ESPERANCA DO IGUACU;5471;4103024" },
  { line: "PR BOA VENTURA DE SAO ROQUE;836;4103040" },
  { line: "PR BOA VISTA DA APARECIDA;7981;4103057" },
  { line: "SC BOCAINA DO SUL;898;4202438" },
  { line: "PR BOCAIUVA DO SUL;7459;4103107" },
  { line: "SC BOM JARDIM DA SERRA;8389;4202503" },
  { line: "SC BOM JESUS;900;4202537" },
  { line: "SC BOM JESUS DO OESTE;902;4202578" },
  { line: "PR BOM JESUS DO SUL;838;4103156" },
  { line: "SC BOM RETIRO;8049;4202602" },
  { line: "PR BOM SUCESSO;7461;4103206" },
  { line: "PR BOM SUCESSO DO SUL;9979;4103222" },
  { line: "SC BOMBINHAS;5537;4202453" },
  { line: "PR BORRAZOPOLIS;7463;4103305" },
  { line: "SC BOTUVERA;8051;4202701" },
  { line: "SC BRACO DO NORTE;8053;4202800" },
  { line: "SC BRACO DO TROMBUDO;5557;4202859" },
  { line: "PR BRAGANEY;7983;4103354" },
  { line: "PR BRASILANDIA DO SUL;5521;4103370" },
  { line: "SC BRUNOPOLIS;904;4202875" },
  { line: "SC BRUSQUE;8055;4202909" },
  { line: "SC CACADOR;8057;4203006" },
  { line: "PR CAFEARA;7465;4103404" },
  { line: "PR CAFELANDIA;7985;4103453" },
  { line: "PR CAFEZAL DO SUL;5491;4103479" },
  { line: "SC CAIBI;8059;4203105" },
  { line: "PR CALIFORNIA;7467;4103503" },
  { line: "SC CALMON;5553;4203154" },
  { line: "PR CAMBARA;7469;4103602" },
  { line: "PR CAMBE;7471;4103701" },
  { line: "PR CAMBIRA;7473;4103800" },
  { line: "SC CAMBORIU;8061;4203204" },
  { line: "PR CAMPINA DA LAGOA;7475;4103909" },
  { line: "PR CAMPINA DO SIMAO;840;4103958" },
  { line: "PR CAMPINA GRANDE DO SUL;7477;4104006" },
  { line: "SC CAMPO ALEGRE;8063;4203303" },
  { line: "SC CAMPO BELO DO SUL;8065;4203402" },
  { line: "PR CAMPO BONITO;8475;4104055" },
  { line: "PR CAMPO DO TENENTE;7479;4104105" },
  { line: "SC CAMPO ERE;8067;4203501" },
  { line: "PR CAMPO LARGO;7481;4104204" },
  { line: "PR CAMPO MAGRO;842;4104253" },
  { line: "PR CAMPO MOURAO;7483;4104303" },
  { line: "SC CAMPOS NOVOS;8069;4203600" },
  { line: "PR CANDIDO DE ABREU;7485;4104402" },
  { line: "PR CANDOI;5499;4104428" },
  { line: "SC CANELINHA;8071;4203709" },
  { line: "SC CANOINHAS;8073;4203808" },
  { line: "PR CANTAGALO;8451;4104451" },
  { line: "PR CAPANEMA;7487;4104501" },
  { line: "SC CAPAO ALTO;906;4203253" },
  { line: "SC CAPINZAL;8075;4203907" },
  { line: "PR CAPITAO LEONIDAS MARQUES;7489;4104600" },
  { line: "SC CAPIVARI DE BAIXO;5545;4203956" },
  { line: "PR CARAMBEI;844;4104659" },
  { line: "PR CARLOPOLIS;7491;4104709" },
  { line: "PR CASCAVEL;7493;4104808" },
  { line: "PR CASTRO;7495;4104907" },
  { line: "PR CATANDUVAS;7497;4105003" },
  { line: "SC CATANDUVAS;8077;4204004" },
  { line: "SC CAXAMBU DO SUL;8079;4204103" },
  { line: "SC CELSO RAMOS;9943;4204152" },
  { line: "PR CENTENARIO DO SUL;7499;4105102" },
  { line: "PR CERRO AZUL;7501;4105201" },
  { line: "SC CERRO NEGRO;5567;4204178" },
  { line: "PR CEU AZUL;7957;4105300" },
  { line: "SC CHAPADAO DO LAGEADO;908;4204194" },
  { line: "SC CHAPECO;8081;4204202" },
  { line: "PR CHOPINZINHO;7503;4105409" },
  { line: "PR CIANORTE;7505;4105508" },
  { line: "PR CIDADE GAUCHA;7507;4105607" },
  { line: "PR CLEVELANDIA;7509;4105706" },
  { line: "SC COCAL DO SUL;5543;4204251" },
  { line: "PR COLOMBO;7513;4105805" },
  { line: "PR COLORADO;7515;4105904" },
  { line: "SC CONCORDIA;8083;4204301" },
  { line: "PR CONGONHINHAS;7517;4106001" },
  { line: "PR CONSELHEIRO MAIRINCK;7519;4106100" },
  { line: "PR CONTENDA;7521;4106209" },
  { line: "PR CORBELIA;7523;4106308" },
  { line: "SC CORDILHEIRA ALTA;5579;4204350" },
  { line: "PR CORNELIO PROCOPIO;7525;4106407" },
  { line: "PR CORONEL DOMINGOS SOARES;846;4106456" },
  { line: "SC CORONEL FREITAS;8085;4204400" },
  { line: "SC CORONEL MARTINS;5735;4204459" },
  { line: "PR CORONEL VIVIDA;7527;4106506" },
  { line: "SC CORREIA PINTO;8395;4204558" },
  { line: "PR CORUMBATAI DO SUL;8479;4106555" },
  { line: "SC CORUPA;8087;4204509" },
  { line: "SC CRICIUMA;8089;4204608" },
  { line: "PR CRUZ MACHADO;7533;4106803" },
  { line: "PR CRUZEIRO DO IGUACU;5473;4106571" },
  { line: "PR CRUZEIRO DO OESTE;7529;4106605" },
  { line: "PR CRUZEIRO DO SUL;7531;4106704" },
  { line: "PR CRUZMALTINA;848;4106852" },
  { line: "SC CUNHA PORA;8091;4204707" },
  { line: "SC CUNHATAI;910;4204756" },
  { line: "PR CURITIBA;7535;4106902" },
  { line: "SC CURITIBANOS;8093;4204806" },
  { line: "PR CURIUVA;7537;4107009" },
  { line: "SC DESCANSO;8095;4204905" },
  { line: "PR DIAMANTE DO NORTE;7539;4107108" },
  { line: "PR DIAMANTE DO SUL;5465;4107124" },
  { line: "PR DIAMANTE D'OESTE;9915;4107157" },
  { line: "SC DIONISIO CERQUEIRA;8097;4205001" },
  { line: "PR DOIS VIZINHOS;7541;4107207" },
  { line: "SC DONA EMMA;8099;4205100" },
  { line: "PR DOURADINA;8465;4107256" },
  { line: "PR DOUTOR CAMARGO;7543;4107306" },
  { line: "SC DOUTOR PEDRINHO;9945;4205159" },
  { line: "PR DOUTOR ULYSSES;5449;4128633" },
  { line: "PR ENEAS MARQUES;7545;4107405" },
  { line: "PR ENGENHEIRO BELTRAO;7547;4107504" },
  { line: "SC ENTRE RIOS;912;4205175" },
  { line: "PR ENTRE RIOS DO OESTE;5529;4107538" },
  { line: "SC ERMO;914;4205191" },
  { line: "SC ERVAL VELHO;8101;4205209" },
  { line: "PR ESPERANCA NOVA;850;4107520" },
  { line: "PR ESPIGAO ALTO DO IGUACU;852;4107546" },
  { line: "PR FAROL;5511;4107553" },
  { line: "PR FAXINAL;7549;4107603" },
  { line: "SC FAXINAL DOS GUEDES;8103;4205308" },
  { line: "PR FAZENDA RIO GRANDE;9983;4107652" },
  { line: "PR FENIX;7551;4107702" },
  { line: "PR FERNANDES PINHEIRO;854;4107736" },
  { line: "PR FIGUEIRA;8457;4107751" },
  { line: "PR FLOR DA SERRA DO SUL;5475;4107850" },
  { line: "SC FLOR DO SERTAO;916;4205357" },
  { line: "PR FLORAI;7553;4107801" },
  { line: "PR FLORESTA;7555;4107900" },
  { line: "PR FLORESTOPOLIS;7557;4108007" },
  { line: "SC FLORIANOPOLIS;8105;4205407" },
  { line: "PR FLORIDA;7559;4108106" },
  { line: "PR FORMOSA DO OESTE;7561;4108205" },
  { line: "SC FORMOSA DO SUL;5581;4205431" },
  { line: "SC FORQUILHINHA;973;4205456" },
  { line: "PR FOZ DO IGUACU;7563;4108304" },
  { line: "PR FOZ DO JORDAO;856;4108452" },
  { line: "SC FRAIBURGO;8107;4205506" },
  { line: "PR FRANCISCO ALVES;7977;4108320" },
  { line: "PR FRANCISCO BELTRAO;7565;4108403" },
  { line: "SC FREI ROGERIO;918;4205555" },
  { line: "SC GALVAO;8109;4205605" },
  { line: "SC GAROPABA;8113;4205704" },
  { line: "SC GARUVA;8115;4205803" },
  { line: "SC GASPAR;8117;4205902" },
  { line: "PR GENERAL CARNEIRO;7567;4108502" },
  { line: "PR GODOY MOREIRA;9947;4108551" },
  { line: "PR GOIOERE;7569;4108601" },
  { line: "PR GOIOXIM;858;4108650" },
  { line: "SC GOVERNADOR CELSO RAMOS;8111;4206009" },
  { line: "PR GRANDES RIOS;7959;4108700" },
  { line: "SC GRAO PARA;8119;4206108" },
  { line: "SC GRAVATAL;8121;4206207" },
  { line: "SC GUABIRUBA;8123;4206306" },
  { line: "PR GUAIRA;7571;4108809" },
  { line: "PR GUAIRACA;7573;4108908" },
  { line: "PR GUAMIRANGA;860;4108957" },
  { line: "PR GUAPIRAMA;7575;4109005" },
  { line: "PR GUAPOREMA;7577;4109104" },
  { line: "PR GUARACI;7579;4109203" },
  { line: "SC GUARACIABA;8125;4206405" },
  { line: "SC GUARAMIRIM;8127;4206504" },
  { line: "PR GUARANIACU;7581;4109302" },
  { line: "PR GUARAPUAVA;7583;4109401" },
  { line: "PR GUARAQUECABA;7585;4109500" },
  { line: "PR GUARATUBA;7587;4109609" },
  { line: "SC GUARUJA DO SUL;8129;4206603" },
  { line: "SC GUATAMBU;5583;4206652" },
  { line: "SC HERVAL D'OESTE;8131;4206702" },
  { line: "PR HONORIO SERPA;9981;4109658" },
  { line: "PR IBAITI;7589;4109708" },
  { line: "PR IBEMA;9949;4109757" },
  { line: "SC IBIAM;920;4206751" },
  { line: "SC IBICARE;8133;4206801" },
  { line: "PR IBIPORA;7591;4109807" },
  { line: "SC IBIRAMA;8135;4206900" },
  { line: "SC ICARA;8137;4207007" },
  { line: "PR ICARAIMA;7593;4109906" },
  { line: "PR IGUARACU;7595;4110003" },
  { line: "PR IGUATU;5467;4110052" },
  { line: "SC ILHOTA;8139;4207106" },
  { line: "SC IMARUI;8141;4207205" },
  { line: "PR IMBAU;862;4110078" },
  { line: "SC IMBITUBA;8143;4207304" },
  { line: "PR IMBITUVA;7597;4110102" },
  { line: "SC IMBUIA;8145;4207403" },
  { line: "PR INACIO MARTINS;7599;4110201" },
  { line: "PR INAJA;7601;4110300" },
  { line: "SC INDAIAL;8147;4207502" },
  { line: "PR INDIANOPOLIS;7961;4110409" },
  { line: "SC IOMERE;922;4207577" },
  { line: "SC IPIRA;8149;4207601" },
  { line: "PR IPIRANGA;7603;4110508" },
  { line: "PR IPORA;7605;4110607" },
  { line: "SC IPORA DO OESTE;9951;4207650" },
  { line: "SC IPUACU;5737;4207684" },
  { line: "SC IPUMIRIM;8151;4207700" },
  { line: "PR IRACEMA DO OESTE;5485;4110656" },
  { line: "SC IRACEMINHA;9953;4207759" },
  { line: "SC IRANI;8153;4207809" },
  { line: "PR IRATI;7607;4110706" },
  { line: "SC IRATI;5585;4207858" },
  { line: "PR IRETAMA;7609;4110805" },
  { line: "SC IRINEOPOLIS;8155;4207908" },
  { line: "SC ITA;8157;4208005" },
  { line: "PR ITAGUAJE;7611;4110904" },
  { line: "SC ITAIOPOLIS;8159;4208104" },
  { line: "PR ITAIPULANDIA;5525;4110953" },
  { line: "SC ITAJAI;8161;4208203" },
  { line: "PR ITAMBARACA;7613;4111001" },
  { line: "PR ITAMBE;7615;4111100" },
  { line: "PR ITAPEJARA D'OESTE;7617;4111209" },
  { line: "SC ITAPEMA;8163;4208302" },
  { line: "PR ITAPERUCU;5451;4111258" },
  { line: "SC ITAPIRANGA;8165;4208401" },
  { line: "SC ITAPOA;9985;4208450" },
  { line: "PR ITAUNA DO SUL;7619;4111308" },
  { line: "SC ITUPORANGA;8167;4208500" },
  { line: "PR IVAI;7621;4111407" },
  { line: "PR IVAIPORA;7623;4111506" },
  { line: "PR IVATE;9955;4111555" },
  { line: "PR IVATUBA;7625;4111605" },
  { line: "SC JABORA;8169;4208609" },
  { line: "PR JABOTI;7627;4111704" },
  { line: "PR JACAREZINHO;7629;4111803" },
  { line: "SC JACINTO MACHADO;8171;4208708" },
  { line: "PR JAGUAPITA;7631;4111902" },
  { line: "PR JAGUARIAIVA;7633;4112009" },
  { line: "SC JAGUARUNA;8173;4208807" },
  { line: "PR JANDAIA DO SUL;7635;4112108" },
  { line: "PR JANIOPOLIS;7637;4112207" },
  { line: "PR JAPIRA;7639;4112306" },
  { line: "PR JAPURA;7641;4112405" },
  { line: "SC JARAGUA DO SUL;8175;4208906" },
  { line: "PR JARDIM ALEGRE;7643;4112504" },
  { line: "PR JARDIM OLINDA;7645;4112603" },
  { line: "SC JARDINOPOLIS;5587;4208955" },
  { line: "PR JATAIZINHO;7647;4112702" },
  { line: "PR JESUITAS;7997;4112751" },
  { line: "SC JOACABA;8177;4209003" },
  { line: "PR JOAQUIM TAVORA;7649;4112801" },
  { line: "SC JOINVILLE;8179;4209102" },
  { line: "SC JOSE BOITEUX;9957;4209151" },
  { line: "PR JUNDIAI DO SUL;7651;4112900" },
  { line: "SC JUPIA;924;4209177" },
  { line: "PR JURANDA;8463;4112959" },
  { line: "PR JUSSARA;7653;4113007" },
  { line: "PR KALORE;7655;4113106" },
  { line: "SC LACERDOPOLIS;8181;4209201" },
  { line: "SC LAGES;8183;4209300" },
  { line: "SC LAGUNA;8185;4209409" },
  { line: "SC LAJEADO GRANDE;5739;4209458" },
  { line: "PR LAPA;7657;4113205" },
  { line: "PR LARANJAL;5501;4113254" },
  { line: "PR LARANJEIRAS DO SUL;7659;4113304" },
  { line: "SC LAURENTINO;8187;4209508" },
  { line: "SC LAURO MULLER;8189;4209607" },
  { line: "SC LEBON REGIS;8191;4209706" },
  { line: "SC LEOBERTO LEAL;8193;4209805" },
  { line: "PR LEOPOLIS;7661;4113403" },
  { line: "PR LIDIANOPOLIS;5507;4113429" },
  { line: "PR LINDOESTE;9959;4113452" },
  { line: "SC LINDOIA DO SUL;9961;4209854" },
  { line: "PR LOANDA;7663;4113502" },
  { line: "PR LOBATO;7665;4113601" },
  { line: "PR LONDRINA;7667;4113700" },
  { line: "SC LONTRAS;8195;4209904" },
  { line: "SC LUIZ ALVES;8197;4210001" },
  { line: "PR LUIZIANA;8481;4113734" },
  { line: "PR LUNARDELLI;8459;4113759" },
  { line: "PR LUPIONOPOLIS;7669;4113809" },
  { line: "SC LUZERNA;926;4210035" },
  { line: "SC MACIEIRA;5575;4210050" },
  { line: "SC MAFRA;8199;4210100" },
  { line: "SC MAJOR GERCINO;8201;4210209" },
  { line: "SC MAJOR VIEIRA;8203;4210308" },
  { line: "PR MALLET;7671;4113908" },
  { line: "PR MAMBORE;7673;4114005" },
  { line: "PR MANDAGUACU;7675;4114104" },
  { line: "PR MANDAGUARI;7677;4114203" },
  { line: "PR MANDIRITUBA;7679;4114302" },
  { line: "PR MANFRINOPOLIS;864;4114351" },
  { line: "PR MANGUEIRINHA;7511;4114401" },
  { line: "PR MANOEL RIBAS;7681;4114500" },
  { line: "SC MARACAJA;8391;4210407" },
  { line: "SC MARAVILHA;8205;4210506" },
  { line: "PR MARECHAL CANDIDO RONDON;7683;4114609" },
  { line: "SC MAREMA;9963;4210555" },
  { line: "PR MARIA HELENA;7685;4114708" },
  { line: "PR MARIALVA;7687;4114807" },
  { line: "PR MARILANDIA DO SUL;7433;4114906" },
  { line: "PR MARILENA;7975;4115002" },
  { line: "PR MARILUZ;7689;4115101" },
  { line: "PR MARINGA;7691;4115200" },
  { line: "PR MARIOPOLIS;7693;4115309" },
  { line: "PR MARIPA;5487;4115358" },
  { line: "PR MARMELEIRO;7695;4115408" },
  { line: "PR MARQUINHO;866;4115457" },
  { line: "PR MARUMBI;7697;4115507" },
  { line: "SC MASSARANDUBA;8207;4210605" },
  { line: "PR MATELANDIA;7699;4115606" },
  { line: "PR MATINHOS;7963;4115705" },
  { line: "PR MATO RICO;5503;4115739" },
  { line: "SC MATOS COSTA;8209;4210704" },
  { line: "PR MAUA DA SERRA;5459;4115754" },
  { line: "PR MEDIANEIRA;7701;4115804" },
  { line: "SC MELEIRO;8211;4210803" },
  { line: "PR MERCEDES;5531;4115853" },
  { line: "PR MIRADOR;7703;4115903" },
  { line: "PR MIRASELVA;7705;4116000" },
  { line: "SC MIRIM DOCE;5559;4210852" },
  { line: "PR MISSAL;8469;4116059" },
  { line: "SC MODELO;8213;4210902" },
  { line: "SC MONDAI;8215;4211009" },
  { line: "SC MONTE CARLO;5561;4211058" },
  { line: "SC MONTE CASTELO;8217;4211108" },
  { line: "PR MOREIRA SALES;7707;4116109" },
  { line: "PR MORRETES;7709;4116208" },
  { line: "SC MORRO DA FUMACA;8219;4211207" },
  { line: "SC MORRO GRANDE;5539;4211256" },
  { line: "PR MUNHOZ DE MELO;7711;4116307" },
  { line: "SC NAVEGANTES;8221;4211306" },
  { line: "PR NOSSA SENHORA DAS GRACAS;7713;4116406" },
  { line: "PR NOVA ALIANCA DO IVAI;7715;4116505" },
  { line: "PR NOVA AMERICA DA COLINA;7717;4116604" },
  { line: "PR NOVA AURORA;7965;4116703" },
  { line: "PR NOVA CANTU;7719;4116802" },
  { line: "SC NOVA ERECHIM;8223;4211405" },
  { line: "PR NOVA ESPERANCA;7721;4116901" },
  { line: "PR NOVA ESPERANCA DO SUDOESTE;5477;4116950" },
  { line: "PR NOVA FATIMA;7723;4117008" },
  { line: "SC NOVA ITABERABA;5589;4211454" },
  { line: "PR NOVA LARANJEIRAS;5479;4117057" },
  { line: "PR NOVA LONDRINA;7725;4117107" },
  { line: "PR NOVA OLIMPIA;7967;4117206" },
  { line: "PR NOVA PRATA DO IGUACU;7995;4117255" },
  { line: "PR NOVA SANTA BARBARA;5457;4117214" },
  { line: "PR NOVA SANTA ROSA;7979;4117222" },
  { line: "PR NOVA TEBAS;9913;4117271" },
  { line: "SC NOVA TRENTO;8225;4211504" },
  { line: "SC NOVA VENEZA;8227;4211603" },
  { line: "SC NOVO HORIZONTE;5591;4211652" },
  { line: "PR NOVO ITACOLOMI;5517;4117297" },
  { line: "SC ORLEANS;8229;4211702" },
  { line: "PR ORTIGUEIRA;7727;4117305" },
  { line: "SC OTACILIO COSTA;8397;4211751" },
  { line: "PR OURIZONA;7729;4117404" },
  { line: "SC OURO;8231;4211801" },
  { line: "SC OURO VERDE;5741;4211850" },
  { line: "PR OURO VERDE DO OESTE;9965;4117453" },
  { line: "SC PAIAL;928;4211876" },
  { line: "PR PAICANDU;7731;4117503" },
  { line: "SC PAINEL;930;4211892" },
  { line: "SC PALHOCA;8233;4211900" },
  { line: "SC PALMA SOLA;8235;4212007" },
  { line: "PR PALMAS;7733;4117602" },
  { line: "PR PALMEIRA;7735;4117701" },
  { line: "SC PALMEIRA;932;4212056" },
  { line: "PR PALMITAL;7737;4117800" },
  { line: "SC PALMITOS;8237;4212106" },
  { line: "PR PALOTINA;7739;4117909" },
  { line: "SC PAPANDUVA;8239;4212205" },
  { line: "SC PARAISO;5747;4212239" },
  { line: "PR PARAISO DO NORTE;7741;4118006" },
  { line: "PR PARANACITY;7743;4118105" },
  { line: "PR PARANAGUA;7745;4118204" },
  { line: "PR PARANAPOEMA;7747;4118303" },
  { line: "PR PARANAVAI;7749;4118402" },
  { line: "SC PASSO DE TORRES;5541;4212254" },
  { line: "SC PASSOS MAIA;5743;4212270" },
  { line: "PR PATO BRAGADO;5533;4118451" },
  { line: "PR PATO BRANCO;7751;4118501" },
  { line: "PR PAULA FREITAS;7753;4118600" },
  { line: "PR PAULO FRONTIN;7755;4118709" },
  { line: "SC PAULO LOPES;8241;4212304" },
  { line: "PR PEABIRU;7757;4118808" },
  { line: "SC PEDRAS GRANDES;8243;4212403" },
  { line: "SC PENHA;8245;4212502" },
  { line: "SC PERITIBA;8247;4212601" },
  { line: "PR PEROBAL;868;4118857" },
  { line: "PR PEROLA;7969;4118907" },
  { line: "PR PEROLA D'OESTE;7759;4119004" },
  { line: "SC PESCARIA BRAVA;1194;4212650" },
  { line: "SC PETROLANDIA;8249;4212700" },
  { line: "PR PIEN;7761;4119103" },
  { line: "PR PINHAIS;5453;4119152" },
  { line: "PR PINHAL DE SAO BENTO;5495;4119251" },
  { line: "PR PINHALAO;7763;4119202" },
  { line: "SC PINHALZINHO;8253;4212908" },
  { line: "PR PINHAO;7765;4119301" },
  { line: "SC PINHEIRO PRETO;8255;4213005" },
  { line: "PR PIRAI DO SUL;7767;4119400" },
  { line: "PR PIRAQUARA;7769;4119509" },
  { line: "SC PIRATUBA;8257;4213104" },
  { line: "PR PITANGA;7771;4119608" },
  { line: "PR PITANGUEIRAS;5461;4119657" },
  { line: "PR PLANALTINA DO PARANA;7773;4119707" },
  { line: "PR PLANALTO;7775;4119806" },
  { line: "SC PLANALTO ALEGRE;5593;4213153" },
  { line: "SC POMERODE;8259;4213203" },
  { line: "PR PONTA GROSSA;7777;4119905" },
  { line: "PR PONTAL DO PARANA;870;4119954" },
  { line: "SC PONTE ALTA;8261;4213302" },
  { line: "SC PONTE ALTA DO NORTE;5569;4213351" },
  { line: "SC PONTE SERRADA;8263;4213401" },
  { line: "PR PORECATU;7779;4120002" },
  { line: "PR PORTO AMAZONAS;7781;4120101" },
  { line: "PR PORTO BARREIRO;872;4120150" },
  { line: "SC PORTO BELO;8265;4213500" },
  { line: "PR PORTO RICO;7783;4120200" },
  { line: "SC PORTO UNIAO;8267;4213609" },
  { line: "PR PORTO VITORIA;7785;4120309" },
  { line: "SC POUSO REDONDO;8269;4213708" },
  { line: "PR PRADO FERREIRA;874;4120333" },
  { line: "SC PRAIA GRANDE;8271;4213807" },
  { line: "PR PRANCHITA;7991;4120358" },
  { line: "SC PRESIDENTE CASTELLO BRANCO;8273;4213906" },
  { line: "PR PRESIDENTE CASTELO BRANCO;7787;4120408" },
  { line: "SC PRESIDENTE GETULIO;8275;4214003" },
  { line: "SC PRESIDENTE NEREU;8277;4214102" },
  { line: "PR PRIMEIRO DE MAIO;7789;4120507" },
  { line: "SC PRINCESA;934;4214151" },
  { line: "PR PRUDENTOPOLIS;7791;4120606" },
  { line: "PR QUARTO CENTENARIO;876;4120655" },
  { line: "PR QUATIGUA;7793;4120705" },
  { line: "PR QUATRO BARRAS;7795;4120804" },
  { line: "PR QUATRO PONTES;5535;4120853" },
  { line: "PR QUEDAS DO IGUACU;7955;4120903" },
  { line: "PR QUERENCIA DO NORTE;7797;4121000" },
  { line: "SC QUILOMBO;8279;4214201" },
  { line: "PR QUINTA DO SOL;7799;4121109" },
  { line: "PR QUITANDINHA;7801;4121208" },
  { line: "PR RAMILANDIA;5527;4121257" },
  { line: "PR RANCHO ALEGRE;7803;4121307" },
  { line: "PR RANCHO ALEGRE D'OESTE;5513;4121356" },
  { line: "SC RANCHO QUEIMADO;8281;4214300" },
  { line: "PR REALEZA;7805;4121406" },
  { line: "PR REBOUCAS;7807;4121505" },
  { line: "PR RENASCENCA;7809;4121604" },
  { line: "PR RESERVA;7811;4121703" },
  { line: "PR RESERVA DO IGUACU;878;4121752" },
  { line: "PR RIBEIRAO CLARO;7813;4121802" },
  { line: "PR RIBEIRAO DO PINHAL;7815;4121901" },
  { line: "PR RIO AZUL;7817;4122008" },
  { line: "PR RIO BOM;7819;4122107" },
  { line: "PR RIO BONITO DO IGUACU;5481;4122156" },
  { line: "PR RIO BRANCO DO IVAI;880;4122172" },
  { line: "PR RIO BRANCO DO SUL;7821;4122206" },
  { line: "SC RIO DAS ANTAS;8283;4214409" },
  { line: "SC RIO DO CAMPO;8285;4214508" },
  { line: "SC RIO DO OESTE;8287;4214607" },
  { line: "SC RIO DO SUL;8291;4214805" },
  { line: "SC RIO DOS CEDROS;8289;4214706" },
  { line: "SC RIO FORTUNA;8293;4214904" },
  { line: "SC RIO NEGRINHO;8295;4215000" },
  { line: "PR RIO NEGRO;7823;4122305" },
  { line: "SC RIO RUFINO;5571;4215059" },
  { line: "SC RIQUEZA;5749;4215075" },
  { line: "SC RODEIO;8297;4215109" },
  { line: "PR ROLANDIA;7825;4122404" },
  { line: "SC ROMELANDIA;8299;4215208" },
  { line: "PR RONCADOR;7827;4122503" },
  { line: "PR RONDON;7829;4122602" },
  { line: "PR ROSARIO DO IVAI;8473;4122651" },
  { line: "PR SABAUDIA;7831;4122701" },
  { line: "SC SALETE;8301;4215307" },
  { line: "PR SALGADO FILHO;7833;4122800" },
  { line: "SC SALTINHO;936;4215356" },
  { line: "PR SALTO DO ITARARE;7835;4122909" },
  { line: "PR SALTO DO LONTRA;7837;4123006" },
  { line: "SC SALTO VELOSO;8303;4215406" },
  { line: "SC SANGAO;5547;4215455" },
  { line: "PR SANTA AMELIA;7839;4123105" },
  { line: "SC SANTA CECILIA;8305;4215505" },
  { line: "PR SANTA CECILIA DO PAVAO;7841;4123204" },
  { line: "PR SANTA CRUZ DE MONTE CASTELO;7843;4123303" },
  { line: "PR SANTA FE;7845;4123402" },
  { line: "PR SANTA HELENA;7971;4123501" },
  { line: "SC SANTA HELENA;5751;4215554" },
  { line: "PR SANTA INES;7847;4123600" },
  { line: "PR SANTA ISABEL DO IVAI;7849;4123709" },
  { line: "PR SANTA IZABEL DO OESTE;7851;4123808" },
  { line: "PR SANTA LUCIA;5469;4123824" },
  { line: "PR SANTA MARIA DO OESTE;5505;4123857" },
  { line: "PR SANTA MARIANA;7853;4123907" },
  { line: "PR SANTA MONICA;5519;4123956" },
  { line: "SC SANTA ROSA DE LIMA;8307;4215604" },
  { line: "SC SANTA ROSA DO SUL;9967;4215653" },
  { line: "PR SANTA TEREZA DO OESTE;9969;4124020" },
  { line: "SC SANTA TEREZINHA;5555;4215679" },
  { line: "PR SANTA TEREZINHA DE ITAIPU;8467;4124053" },
  { line: "SC SANTA TEREZINHA DO PROGRESSO;938;4215687" },
  { line: "PR SANTANA DO ITARARE;7855;4124004" },
  { line: "SC SANTIAGO DO SUL;940;4215695" },
  { line: "SC SANTO AMARO DA IMPERATRIZ;8309;4215703" },
  { line: "PR SANTO ANTONIO DA PLATINA;7859;4124103" },
  { line: "PR SANTO ANTONIO DO CAIUA;7861;4124202" },
  { line: "PR SANTO ANTONIO DO PARAISO;7863;4124301" },
  { line: "PR SANTO ANTONIO DO SUDOESTE;7857;4124400" },
  { line: "PR SANTO INACIO;7865;4124509" },
  { line: "SC SAO BENTO DO SUL;8311;4215802" },
  { line: "SC SAO BERNARDINO;942;4215752" },
  { line: "SC SAO BONIFACIO;8313;4215901" },
  { line: "SC SAO CARLOS;8315;4216008" },
  { line: "PR SAO CARLOS DO IVAI;7867;4124608" },
  { line: "SC SAO CRISTOVAO DO SUL;5573;4216057" },
  { line: "SC SAO DOMINGOS;8317;4216107" },
  { line: "SC SAO FRANCISCO DO SUL;8319;4216206" },
  { line: "PR SAO JERONIMO DA SERRA;7869;4124707" },
  { line: "PR SAO JOAO;7871;4124806" },
  { line: "SC SAO JOAO BATISTA;8321;4216305" },
  { line: "PR SAO JOAO DO CAIUA;7873;4124905" },
  { line: "SC SAO JOAO DO ITAPERIU;5551;4216354" },
  { line: "PR SAO JOAO DO IVAI;7875;4125001" },
  { line: "SC SAO JOAO DO OESTE;5753;4216255" },
  { line: "SC SAO JOAO DO SUL;8323;4216404" },
  { line: "PR SAO JOAO DO TRIUNFO;7877;4125100" },
  { line: "SC SAO JOAQUIM;8325;4216503" },
  { line: "PR SAO JORGE DO IVAI;7879;4125308" },
  { line: "PR SAO JORGE DO PATROCINIO;7999;4125357" },
  { line: "PR SAO JORGE D'OESTE;7881;4125209" },
  { line: "SC SAO JOSE;8327;4216602" },
  { line: "PR SAO JOSE DA BOA VISTA;7883;4125407" },
  { line: "PR SAO JOSE DAS PALMEIRAS;8471;4125456" },
  { line: "SC SAO JOSE DO CEDRO;8329;4216701" },
  { line: "SC SAO JOSE DO CERRITO;8331;4216800" },
  { line: "PR SAO JOSE DOS PINHAIS;7885;4125506" },
  { line: "SC SAO LOURENCO DO OESTE;8333;4216909" },
  { line: "SC SAO LUDGERO;8335;4217006" },
  { line: "PR SAO MANOEL DO PARANA;5515;4125555" },
  { line: "SC SAO MARTINHO;8337;4217105" },
  { line: "PR SAO MATEUS DO SUL;7887;4125605" },
  { line: "SC SAO MIGUEL DA BOA VISTA;5755;4217154" },
  { line: "PR SAO MIGUEL DO IGUACU;7889;4125704" },
  { line: "SC SAO MIGUEL DO OESTE;8339;4217204" },
  { line: "SC SAO PEDRO DE ALCANTARA;944;4217253" },
  { line: "PR SAO PEDRO DO IGUACU;5489;4125753" },
  { line: "PR SAO PEDRO DO IVAI;7891;4125803" },
  { line: "PR SAO PEDRO DO PARANA;7893;4125902" },
  { line: "PR SAO SEBASTIAO DA AMOREIRA;7895;4126009" },
  { line: "PR SAO TOME;7897;4126108" },
  { line: "PR SAPOPEMA;7899;4126207" },
  { line: "PR SARANDI;8461;4126256" },
  { line: "PR SAUDADE DO IGUACU;5493;4126272" },
  { line: "SC SAUDADES;8341;4217303" },
  { line: "SC SCHROEDER;8343;4217402" },
  { line: "SC SEARA;8345;4217501" },
  { line: "PR SENGES;7901;4126306" },
  { line: "SC SERRA ALTA;9989;4217550" },
  { line: "PR SERRANOPOLIS DO IGUACU;882;4126355" },
  { line: "PR SERTANEJA;7903;4126405" },
  { line: "PR SERTANOPOLIS;7905;4126504" },
  { line: "SC SIDEROPOLIS;8347;4217600" },
  { line: "PR SIQUEIRA CAMPOS;7907;4126603" },
  { line: "SC SOMBRIO;8349;4217709" },
  { line: "SC SUL BRASIL;5595;4217758" },
  { line: "PR SULINA;8477;4126652" },
  { line: "SC TAIO;8351;4217808" },
  { line: "PR TAMARANA;884;4126678" },
  { line: "PR TAMBOARA;7909;4126702" },
  { line: "SC TANGARA;8353;4217907" },
  { line: "PR TAPEJARA;7911;4126801" },
  { line: "PR TAPIRA;7973;4126900" },
  { line: "PR TEIXEIRA SOARES;7913;4127007" },
  { line: "PR TELEMACO BORBA;7915;4127106" },
  { line: "PR TERRA BOA;7917;4127205" },
  { line: "PR TERRA RICA;7919;4127304" },
  { line: "PR TERRA ROXA;7921;4127403" },
  { line: "PR TIBAGI;7923;4127502" },
  { line: "SC TIGRINHOS;946;4217956" },
  { line: "SC TIJUCAS;8355;4218004" },
  { line: "PR TIJUCAS DO SUL;7925;4127601" },
  { line: "SC TIMBE DO SUL;8393;4218103" },
  { line: "SC TIMBO;8357;4218202" },
  { line: "SC TIMBO GRANDE;9971;4218251" },
  { line: "PR TOLEDO;7927;4127700" },
  { line: "PR TOMAZINA;7929;4127809" },
  { line: "SC TRES BARRAS;8359;4218301" },
  { line: "PR TRES BARRAS DO PARANA;7987;4127858" },
  { line: "SC TREVISO;948;4218350" },
  { line: "SC TREZE DE MAIO;8361;4218400" },
  { line: "SC TREZE TILIAS;8363;4218509" },
  { line: "SC TROMBUDO CENTRAL;8365;4218608" },
  { line: "SC TUBARAO;8367;4218707" },
  { line: "SC TUNAPOLIS;9991;4218756" },
  { line: "PR TUNAS DO PARANA;5455;4127882" },
  { line: "PR TUNEIRAS DO OESTE;7931;4127908" },
  { line: "PR TUPASSI;7993;4127957" },
  { line: "PR TURVO;8453;4127965" },
  { line: "SC TURVO;8369;4218806" },
  { line: "PR UBIRATA;7933;4128005" },
  { line: "PR UMUARAMA;7935;4128104" },
  { line: "PR UNIAO DA VITORIA;7937;4128203" },
  { line: "SC UNIAO DO OESTE;9973;4218855" },
  { line: "PR UNIFLOR;7939;4128302" },
  { line: "PR URAI;7941;4128401" },
  { line: "SC URUBICI;8371;4218905" },
  { line: "SC URUPEMA;9975;4218954" },
  { line: "SC URUSSANGA;8373;4219002" },
  { line: "SC VARGEAO;8375;4219101" },
  { line: "SC VARGEM;5563;4219150" },
  { line: "SC VARGEM BONITA;5565;4219176" },
  { line: "PR VENTANIA;5497;4128534" },
  { line: "PR VERA CRUZ DO OESTE;7989;4128559" },
  { line: "PR VERE;7945;4128609" },
  { line: "SC VIDAL RAMOS;8377;4219200" },
  { line: "SC VIDEIRA;8379;4219309" },
  { line: "PR VIRMOND;5483;4128658" },
  { line: "SC VITOR MEIRELES;9977;4219358" },
  { line: "PR VITORINO;7947;4128708" },
  { line: "PR WENCESLAU BRAZ;7943;4128500" },
  { line: "SC WITMARSUM;8381;4219408" },
  { line: "PR XAMBRE;7949;4128807" },
  { line: "SC XANXERE;8383;4219507" },
  { line: "SC XAVANTINA;8385;4219606" },
  { line: "SC XAXIM;8387;4219705" },
  { line: "SC ZORTEA;950;4219853" },
  { line: "RS ACEGUA;1118;4300034" },
  { line: "RS AGUA SANTA;8499;4300059" },
  { line: "RS AGUDO;8501;4300109" },
  { line: "RS AJURICABA;8503;4300208" },
  { line: "RS ALECRIM;8505;4300307" },
  { line: "RS ALEGRETE;8507;4300406" },
  { line: "RS ALEGRIA;8497;4300455" },
  { line: "RS ALMIRANTE TAMANDARE DO SUL;1120;4300471" },
  { line: "RS ALPESTRE;8509;4300505" },
  { line: "RS ALTO ALEGRE;8495;4300554" },
  { line: "RS ALTO FELIZ;6045;4300570" },
  { line: "RS ALVORADA;8511;4300604" },
  { line: "RS AMARAL FERRADOR;8493;4300638" },
  { line: "RS AMETISTA DO SUL;5969;4300646" },
  { line: "RS ANDRE DA ROCHA;8491;4300661" },
  { line: "RS ANTA GORDA;8513;4300703" },
  { line: "RS ANTONIO PRADO;8515;4300802" },
  { line: "RS ARAMBARE;5779;4300851" },
  { line: "RS ARARICA;952;4300877" },
  { line: "RS ARATIBA;8517;4300901" },
  { line: "RS ARROIO DO MEIO;8519;4301008" },
  { line: "RS ARROIO DO PADRE;1122;4301073" },
  { line: "RS ARROIO DO SAL;8489;4301057" },
  { line: "RS ARROIO DO TIGRE;8523;4301206" },
  { line: "RS ARROIO DOS RATOS;8521;4301107" },
  { line: "RS ARROIO GRANDE;8525;4301305" },
  { line: "RS ARVOREZINHA;8527;4301404" },
  { line: "RS AUGUSTO PESTANA;8529;4301503" },
  { line: "RS AUREA;8487;4301552" },
  { line: "RS BAGE;8531;4301602" },
  { line: "RS BALNEARIO PINHAL;954;4301636" },
  { line: "RS BARAO;8485;4301651" },
  { line: "RS BARAO DE COTEGIPE;8533;4301701" },
  { line: "RS BARAO DO TRIUNFO;5771;4301750" },
  { line: "RS BARRA DO GUARITA;6069;4301859" },
  { line: "RS BARRA DO QUARAI;956;4301875" },
  { line: "RS BARRA DO RIBEIRO;8537;4301909" },
  { line: "RS BARRA DO RIO AZUL;5959;4301925" },
  { line: "RS BARRA FUNDA;5943;4301958" },
  { line: "RS BARRACAO;8535;4301800" },
  { line: "RS BARROS CASSAL;8539;4302006" },
  { line: "RS BENJAMIN CONSTANT DO SUL;958;4302055" },
  { line: "RS BENTO GONCALVES;8541;4302105" },
  { line: "RS BOA VISTA DAS MISSOES;5981;4302154" },
  { line: "RS BOA VISTA DO BURICA;8543;4302204" },
  { line: "RS BOA VISTA DO CADEADO;1124;4302220" },
  { line: "RS BOA VISTA DO INCRA;1126;4302238" },
  { line: "RS BOA VISTA DO SUL;960;4302253" },
  { line: "RS BOM JESUS;8545;4302303" },
  { line: "RS BOM PRINCIPIO;9823;4302352" },
  { line: "RS BOM PROGRESSO;6071;4302378" },
  { line: "RS BOM RETIRO DO SUL;8547;4302402" },
  { line: "RS BOQUEIRAO DO LEAO;8483;4302451" },
  { line: "RS BOSSOROCA;8549;4302501" },
  { line: "RS BOZANO;1128;4302584" },
  { line: "RS BRAGA;8551;4302600" },
  { line: "RS BROCHIER;8449;4302659" },
  { line: "RS BUTIA;8553;4302709" },
  { line: "RS CACAPAVA DO SUL;8555;4302808" },
  { line: "RS CACEQUI;8557;4302907" },
  { line: "RS CACHOEIRA DO SUL;8559;4303004" },
  { line: "RS CACHOEIRINHA;8561;4303103" },
  { line: "RS CACIQUE DOBLE;8563;4303202" },
  { line: "RS CAIBATE;8565;4303301" },
  { line: "RS CAICARA;8567;4303400" },
  { line: "RS CAMAQUA;8569;4303509" },
  { line: "RS CAMARGO;8447;4303558" },
  { line: "RS CAMBARA DO SUL;8571;4303608" },
  { line: "RS CAMPESTRE DA SERRA;6013;4303673" },
  { line: "RS CAMPINA DAS MISSOES;8573;4303707" },
  { line: "RS CAMPINAS DO SUL;8575;4303806" },
  { line: "RS CAMPO BOM;8577;4303905" },
  { line: "RS CAMPO NOVO;8579;4304002" },
  { line: "RS CAMPOS BORGES;8445;4304101" },
  { line: "RS CANDELARIA;8581;4304200" },
  { line: "RS CANDIDO GODOI;8583;4304309" },
  { line: "RS CANDIOTA;6083;4304358" },
  { line: "RS CANELA;8585;4304408" },
  { line: "RS CANGUCU;8587;4304507" },
  { line: "RS CANOAS;8589;4304606" },
  { line: "RS CANUDOS DO VALE;1130;4304614" },
  { line: "RS CAPAO BONITO DO SUL;1132;4304622" },
  { line: "RS CAPAO DA CANOA;8915;4304630" },
  { line: "RS CAPAO DO CIPO;1134;4304655" },
  { line: "RS CAPAO DO LEAO;8973;4304663" },
  { line: "RS CAPELA DE SANTANA;8443;4304689" },
  { line: "RS CAPITAO;6025;4304697" },
  { line: "RS CAPIVARI DO SUL;962;4304671" },
  { line: "RS CARAA;964;4304713" },
  { line: "RS CARAZINHO;8591;4304705" },
  { line: "RS CARLOS BARBOSA;8593;4304804" },
  { line: "RS CARLOS GOMES;5961;4304853" },
  { line: "RS CASCA;8595;4304903" },
  { line: "RS CASEIROS;8441;4304952" },
  { line: "RS CATUIPE;8597;4305009" },
  { line: "RS CAXIAS DO SUL;8599;4305108" },
  { line: "RS CENTENARIO;5963;4305116" },
  { line: "RS CERRITO;966;4305124" },
  { line: "RS CERRO BRANCO;8439;4305132" },
  { line: "RS CERRO GRANDE;8437;4305157" },
  { line: "RS CERRO GRANDE DO SUL;8435;4305173" },
  { line: "RS CERRO LARGO;8601;4305207" },
  { line: "RS CHAPADA;8603;4305306" },
  { line: "RS CHARQUEADAS;8693;4305355" },
  { line: "RS CHARRUA;5965;4305371" },
  { line: "RS CHIAPETTA;8605;4305405" },
  { line: "RS CHUI;968;4305439" },
  { line: "RS CHUVISCA;970;4305447" },
  { line: "RS CIDREIRA;8433;4305454" },
  { line: "RS CIRIACO;8607;4305504" },
  { line: "RS COLINAS;6029;4305587" },
  { line: "RS COLORADO;8609;4305603" },
  { line: "RS CONDOR;8611;4305702" },
  { line: "RS CONSTANTINA;8613;4305801" },
  { line: "RS COQUEIRO BAIXO;1136;4305835" },
  { line: "RS COQUEIROS DO SUL;5945;4305850" },
  { line: "RS CORONEL BARROS;6055;4305871" },
  { line: "RS CORONEL BICACO;8615;4305900" },
  { line: "RS CORONEL PILAR;1138;4305934" },
  { line: "RS COTIPORA;8977;4305959" },
  { line: "RS COXILHA;5797;4305975" },
  { line: "RS CRISSIUMAL;8617;4306007" },
  { line: "RS CRISTAL;8431;4306056" },
  { line: "RS CRISTAL DO SUL;972;4306072" },
  { line: "RS CRUZ ALTA;8619;4306106" },
  { line: "RS CRUZALTENSE;1140;4306130" },
  { line: "RS CRUZEIRO DO SUL;8621;4306205" },
  { line: "RS DAVID CANABARRO;8623;4306304" },
  { line: "RS DERRUBADAS;6073;4306320" },
  { line: "RS DEZESSEIS DE NOVEMBRO;8429;4306353" },
  { line: "RS DILERMANDO DE AGUIAR;974;4306379" },
  { line: "RS DOIS IRMAOS;8625;4306403" },
  { line: "RS DOIS IRMAOS DAS MISSOES;5971;4306429" },
  { line: "RS DOIS LAJEADOS;8427;4306452" },
  { line: "RS DOM FELICIANO;8627;4306502" },
  { line: "RS DOM PEDRITO;8629;4306601" },
  { line: "RS DOM PEDRO DE ALCANTARA;976;4306551" },
  { line: "RS DONA FRANCISCA;8631;4306700" },
  { line: "RS DOUTOR MAURICIO CARDOSO;8425;4306734" },
  { line: "RS DOUTOR RICARDO;978;4306759" },
  { line: "RS ELDORADO DO SUL;8423;4306767" },
  { line: "RS ENCANTADO;8633;4306809" },
  { line: "RS ENCRUZILHADA DO SUL;8635;4306908" },
  { line: "RS ENGENHO VELHO;5947;4306924" },
  { line: "RS ENTRE IJUIS;8419;4306932" },
  { line: "RS ENTRE RIOS DO SUL;8421;4306957" },
  { line: "RS EREBANGO;8417;4306973" },
  { line: "RS ERECHIM;8637;4307005" },
  { line: "RS ERNESTINA;8415;4307054" },
  { line: "RS ERVAL GRANDE;8641;4307203" },
  { line: "RS ERVAL SECO;8643;4307302" },
  { line: "RS ESMERALDA;8645;4307401" },
  { line: "RS ESPERANCA DO SUL;980;4307450" },
  { line: "RS ESPUMOSO;8647;4307500" },
  { line: "RS ESTACAO;7301;4307559" },
  { line: "RS ESTANCIA VELHA;8649;4307609" },
  { line: "RS ESTEIO;8651;4307708" },
  { line: "RS ESTRELA;8653;4307807" },
  { line: "RS ESTRELA VELHA;982;4307815" },
  { line: "RS EUGENIO DE CASTRO;8413;4307831" },
  { line: "RS FAGUNDES VARELA;8411;4307864" },
  { line: "RS FARROUPILHA;8655;4307906" },
  { line: "RS FAXINAL DO SOTURNO;8657;4308003" },
  { line: "RS FAXINALZINHO;8409;4308052" },
  { line: "RS FAZENDA VILANOVA;984;4308078" },
  { line: "RS FELIZ;8659;4308102" },
  { line: "RS FLORES DA CUNHA;8661;4308201" },
  { line: "RS FLORIANO PEIXOTO;986;4308250" },
  { line: "RS FONTOURA XAVIER;8663;4308300" },
  { line: "RS FORMIGUEIRO;8665;4308409" },
  { line: "RS FORQUETINHA;1142;4308433" },
  { line: "RS FORTALEZA DOS VALOS;9827;4308458" },
  { line: "RS FREDERICO WESTPHALEN;8667;4308508" },
  { line: "RS GARIBALDI;8669;4308607" },
  { line: "RS GARRUCHOS;6081;4308656" },
  { line: "RS GAURAMA;8671;4308706" },
  { line: "RS GENERAL CAMARA;8673;4308805" },
  { line: "RS GENTIL;5799;4308854" },
  { line: "RS GETULIO VARGAS;8677;4308904" },
  { line: "RS GIRUA;8679;4309001" },
  { line: "RS GLORINHA;8407;4309050" },
  { line: "RS GRAMADO;8681;4309100" },
  { line: "RS GRAMADO DOS LOUREIROS;5949;4309126" },
  { line: "RS GRAMADO XAVIER;5763;4309159" },
  { line: "RS GRAVATAI;8683;4309209" },
  { line: "RS GUABIJU;8405;4309258" },
  { line: "RS GUAIBA;8685;4309308" },
  { line: "RS GUAPORE;8687;4309407" },
  { line: "RS GUARANI DAS MISSOES;8689;4309506" },
  { line: "RS HARMONIA;8403;4309555" },
  { line: "RS HERVAL;8639;4307104" },
  { line: "RS HERVEIRAS;988;4309571" },
  { line: "RS HORIZONTINA;8691;4309605" },
  { line: "RS HULHA NEGRA;6085;4309654" },
  { line: "RS HUMAITA;8695;4309704" },
  { line: "RS IBARAMA;8401;4309753" },
  { line: "RS IBIACA;8697;4309803" },
  { line: "RS IBIRAIARAS;8699;4309902" },
  { line: "RS IBIRAPUITA;7299;4309951" },
  { line: "RS IBIRUBA;8701;4310009" },
  { line: "RS IGREJINHA;8703;4310108" },
  { line: "RS IJUI;8705;4310207" },
  { line: "RS ILOPOLIS;8707;4310306" },
  { line: "RS IMBE;7297;4310330" },
  { line: "RS IMIGRANTE;7295;4310363" },
  { line: "RS INDEPENDENCIA;8709;4310405" },
  { line: "RS INHACORA;6051;4310413" },
  { line: "RS IPE;8399;4310439" },
  { line: "RS IPIRANGA DO SUL;7399;4310462" },
  { line: "RS IRAI;8711;4310504" },
  { line: "RS ITAARA;990;4310538" },
  { line: "RS ITACURUBI;7397;4310553" },
  { line: "RS ITAPUCA;6027;4310579" },
  { line: "RS ITAQUI;8713;4310603" },
  { line: "RS ITATI;1144;4310652" },
  { line: "RS ITATIBA DO SUL;8715;4310702" },
  { line: "RS IVORA;7395;4310751" },
  { line: "RS IVOTI;8717;4310801" },
  { line: "RS JABOTICABA;7393;4310850" },
  { line: "RS JACUIZINHO;1146;4310876" },
  { line: "RS JACUTINGA;8719;4310900" },
  { line: "RS JAGUARAO;8721;4311007" },
  { line: "RS JAGUARI;8723;4311106" },
  { line: "RS JAQUIRANA;7391;4311122" },
  { line: "RS JARI;992;4311130" },
  { line: "RS JOIA;9829;4311155" },
  { line: "RS JULIO DE CASTILHOS;8725;4311205" },
  { line: "RS LAGOA BONITA DO SUL;1148;4311239" },
  { line: "RS LAGOA DOS TRES CANTOS;5951;4311270" },
  { line: "RS LAGOA VERMELHA;8727;4311304" },
  { line: "RS LAGOAO;7389;4311254" },
  { line: "RS LAJEADO;8729;4311403" },
  { line: "RS LAJEADO DO BUGRE;5983;4311429" },
  { line: "RS LAVRAS DO SUL;8731;4311502" },
  { line: "RS LIBERATO SALZANO;8733;4311601" },
  { line: "RS LINDOLFO COLLOR;6017;4311627" },
  { line: "RS LINHA NOVA;6047;4311643" },
  { line: "RS MACAMBARA;994;4311718" },
  { line: "RS MACHADINHO;8735;4311700" },
  { line: "RS MAMPITUBA;996;4311734" },
  { line: "RS MANOEL VIANA;6079;4311759" },
  { line: "RS MAQUINE;5783;4311775" },
  { line: "RS MARATA;6039;4311791" },
  { line: "RS MARAU;8737;4311809" },
  { line: "RS MARCELINO RAMOS;8739;4311908" },
  { line: "RS MARIANA PIMENTEL;5759;4311981" },
  { line: "RS MARIANO MORO;8741;4312005" },
  { line: "RS MARQUES DE SOUZA;998;4312054" },
  { line: "RS MATA;8743;4312104" },
  { line: "RS MATO CASTELHANO;5931;4312138" },
  { line: "RS MATO LEITAO;6031;4312153" },
  { line: "RS MATO QUEIMADO;1150;4312179" },
  { line: "RS MAXIMILIANO DE ALMEIDA;8745;4312203" },
  { line: "RS MINAS DO LEAO;5773;4312252" },
  { line: "RS MIRAGUAI;8747;4312302" },
  { line: "RS MONTAURI;7387;4312351" },
  { line: "RS MONTE ALEGRE DOS CAMPOS;1000;4312377" },
  { line: "RS MONTE BELO DO SUL;5993;4312385" },
  { line: "RS MONTENEGRO;8749;4312401" },
  { line: "RS MORMACO;5933;4312427" },
  { line: "RS MORRINHOS DO SUL;5775;4312443" },
  { line: "RS MORRO REDONDO;7385;4312450" },
  { line: "RS MORRO REUTER;6019;4312476" },
  { line: "RS MOSTARDAS;8751;4312500" },
  { line: "RS MUCUM;8753;4312609" },
  { line: "RS MUITOS CAPOES;1002;4312617" },
  { line: "RS MULITERNO;5935;4312625" },
  { line: "RS NAO-ME-TOQUE;8755;4312658" },
  { line: "RS NICOLAU VERGUEIRO;5937;4312674" },
  { line: "RS NONOAI;8757;4312708" },
  { line: "RS NOVA ALVORADA;7383;4312757" },
  { line: "RS NOVA ARACA;8759;4312807" },
  { line: "RS NOVA BASSANO;8761;4312906" },
  { line: "RS NOVA BOA VISTA;5953;4312955" },
  { line: "RS NOVA BRESCIA;8763;4313003" },
  { line: "RS NOVA CANDELARIA;1004;4313011" },
  { line: "RS NOVA ESPERANCA DO SUL;7381;4313037" },
  { line: "RS NOVA HARTZ;7379;4313060" },
  { line: "RS NOVA PADUA;5991;4313086" },
  { line: "RS NOVA PALMA;8765;4313102" },
  { line: "RS NOVA PETROPOLIS;8767;4313201" },
  { line: "RS NOVA PRATA;8769;4313300" },
  { line: "RS NOVA RAMADA;1006;4313334" },
  { line: "RS NOVA ROMA DO SUL;7377;4313359" },
  { line: "RS NOVA SANTA RITA;5757;4313375" },
  { line: "RS NOVO BARREIRO;5985;4313490" },
  { line: "RS NOVO CABRAIS;1008;4313391" },
  { line: "RS NOVO HAMBURGO;8771;4313409" },
  { line: "RS NOVO MACHADO;6057;4313425" },
  { line: "RS NOVO TIRADENTES;5973;4313441" },
  { line: "RS NOVO XINGU;1152;4313466" },
  { line: "RS OSORIO;8773;4313508" },
  { line: "RS PAIM FILHO;8775;4313607" },
  { line: "RS PALMARES DO SUL;8967;4313656" },
  { line: "RS PALMEIRA DAS MISSOES;8777;4313706" },
  { line: "RS PALMITINHO;8779;4313805" },
  { line: "RS PANAMBI;8781;4313904" },
  { line: "RS PANTANO GRANDE;7375;4313953" },
  { line: "RS PARAI;8783;4314001" },
  { line: "RS PARAISO DO SUL;7373;4314027" },
  { line: "RS PARECI NOVO;6041;4314035" },
  { line: "RS PAROBE;9825;4314050" },
  { line: "RS PASSA SETE;1010;4314068" },
  { line: "RS PASSO DO SOBRADO;5765;4314076" },
  { line: "RS PASSO FUNDO;8785;4314100" },
  { line: "RS PAULO BENTO;1154;4314134" },
  { line: "RS PAVERAMA;7371;4314159" },
  { line: "RS PEDRAS ALTAS;1156;4314175" },
  { line: "RS PEDRO OSORIO;8787;4314209" },
  { line: "RS PEJUCARA;8789;4314308" },
  { line: "RS PELOTAS;8791;4314407" },
  { line: "RS PICADA CAFE;6021;4314423" },
  { line: "RS PINHAL;7369;4314456" },
  { line: "RS PINHAL DA SERRA;1158;4314464" },
  { line: "RS PINHAL GRANDE;5787;4314472" },
  { line: "RS PINHEIRINHO DO VALE;5975;4314498" },
  { line: "RS PINHEIRO MACHADO;8793;4314506" },
  { line: "RS PINTO BANDEIRA;1160;4314548" },
  { line: "RS PIRAPO;7367;4314555" },
  { line: "RS PIRATINI;8795;4314605" },
  { line: "RS PLANALTO;8797;4314704" },
  { line: "RS POCO DAS ANTAS;7365;4314753" },
  { line: "RS PONTAO;5939;4314779" },
  { line: "RS PONTE PRETA;5967;4314787" },
  { line: "RS PORTAO;8799;4314803" },
  { line: "RS PORTO ALEGRE;8801;4314902" },
  { line: "RS PORTO LUCENA;8803;4315008" },
  { line: "RS PORTO MAUA;6065;4315057" },
  { line: "RS PORTO VERA CRUZ;6067;4315073" },
  { line: "RS PORTO XAVIER;8805;4315107" },
  { line: "RS POUSO NOVO;7363;4315131" },
  { line: "RS PRESIDENTE LUCENA;6023;4315149" },
  { line: "RS PROGRESSO;7361;4315156" },
  { line: "RS PROTASIO ALVES;7359;4315172" },
  { line: "RS PUTINGA;8807;4315206" },
  { line: "RS QUARAI;8809;4315305" },
  { line: "RS QUATRO IRMAOS;1162;4315313" },
  { line: "RS QUEVEDOS;5789;4315321" },
  { line: "RS QUINZE DE NOVEMBRO;7357;4315354" },
  { line: "RS REDENTORA;8811;4315404" },
  { line: "RS RELVADO;7355;4315453" },
  { line: "RS RESTINGA SECA;8813;4315503" },
  { line: "RS RIO DOS INDIOS;5955;4315552" },
  { line: "RS RIO GRANDE;8815;4315602" },
  { line: "RS RIO PARDO;8817;4315701" },
  { line: "RS RIOZINHO;7353;4315750" },
  { line: "RS ROCA SALES;8819;4315800" },
  { line: "RS RODEIO BONITO;8821;4315909" },
  { line: "RS ROLADOR;1164;4315958" },
  { line: "RS ROLANTE;8823;4316006" },
  { line: "RS RONDA ALTA;8825;4316105" },
  { line: "RS RONDINHA;8827;4316204" },
  { line: "RS ROQUE GONZALES;8829;4316303" },
  { line: "RS ROSARIO DO SUL;8831;4316402" },
  { line: "RS SAGRADA FAMILIA;5987;4316428" },
  { line: "RS SALDANHA MARINHO;7339;4316436" },
  { line: "RS SALTO DO JACUI;8975;4316451" },
  { line: "RS SALVADOR DAS MISSOES;6061;4316477" },
  { line: "RS SALVADOR DO SUL;8833;4316501" },
  { line: "RS SANANDUVA;8835;4316600" },
  { line: "RS SANTA BARBARA DO SUL;8837;4316709" },
  { line: "RS SANTA CECILIA DO SUL;1166;4316733" },
  { line: "RS SANTA CLARA DO SUL;6033;4316758" },
  { line: "RS SANTA CRUZ DO SUL;8839;4316808" },
  { line: "RS SANTA MARGARIDA DO SUL;1168;4316972" },
  { line: "RS SANTA MARIA;8841;4316907" },
  { line: "RS SANTA MARIA DO HERVAL;7337;4316956" },
  { line: "RS SANTA ROSA;8847;4317202" },
  { line: "RS SANTA TEREZA;5995;4317251" },
  { line: "RS SANTA VITORIA DO PALMAR;8849;4317301" },
  { line: "RS SANTANA DA BOA VISTA;8843;4317004" },
  { line: "RS SANTANA DO LIVRAMENTO;8845;4317103" },
  { line: "RS SANTIAGO;8851;4317400" },
  { line: "RS SANTO ANGELO;8853;4317509" },
  { line: "RS SANTO ANTONIO DA PATRULHA;8855;4317608" },
  { line: "RS SANTO ANTONIO DAS MISSOES;8857;4317707" },
  { line: "RS SANTO ANTONIO DO PALMA;5941;4317558" },
  { line: "RS SANTO ANTONIO DO PLANALTO;5957;4317756" },
  { line: "RS SANTO AUGUSTO;8859;4317806" },
  { line: "RS SANTO CRISTO;8861;4317905" },
  { line: "RS SANTO EXPEDITO DO SUL;5977;4317954" },
  { line: "RS SAO BORJA;8863;4318002" },
  { line: "RS SAO DOMINGOS DO SUL;7351;4318051" },
  { line: "RS SAO FRANCISCO DE ASSIS;8865;4318101" },
  { line: "RS SAO FRANCISCO DE PAULA;8867;4318200" },
  { line: "RS SAO GABRIEL;8869;4318309" },
  { line: "RS SAO JERONIMO;8871;4318408" },
  { line: "RS SAO JOAO DA URTIGA;7349;4318424" },
  { line: "RS SAO JOAO DO POLESINE;5791;4318432" },
  { line: "RS SAO JORGE;7347;4318440" },
  { line: "RS SAO JOSE DAS MISSOES;5989;4318457" },
  { line: "RS SAO JOSE DO HERVAL;7345;4318465" },
  { line: "RS SAO JOSE DO HORTENCIO;7343;4318481" },
  { line: "RS SAO JOSE DO INHACORA;6059;4318499" },
  { line: "RS SAO JOSE DO NORTE;8873;4318507" },
  { line: "RS SAO JOSE DO OURO;8875;4318606" },
  { line: "RS SAO JOSE DO SUL;1170;4318614" },
  { line: "RS SAO JOSE DOS AUSENTES;6015;4318622" },
  { line: "RS SAO LEOPOLDO;8877;4318705" },
  { line: "RS SAO LOURENCO DO SUL;8879;4318804" },
  { line: "RS SAO LUIZ GONZAGA;8881;4318903" },
  { line: "RS SAO MARCOS;8883;4319000" },
  { line: "RS SAO MARTINHO;8885;4319109" },
  { line: "RS SAO MARTINHO DA SERRA;5793;4319125" },
  { line: "RS SAO MIGUEL DAS MISSOES;7341;4319158" },
  { line: "RS SAO NICOLAU;8887;4319208" },
  { line: "RS SAO PAULO DAS MISSOES;8889;4319307" },
  { line: "RS SAO PEDRO DA SERRA;6043;4319356" },
  { line: "RS SAO PEDRO DAS MISSOES;1172;4319364" },
  { line: "RS SAO PEDRO DO BUTIA;6063;4319372" },
  { line: "RS SAO PEDRO DO SUL;8891;4319406" },
  { line: "RS SAO SEBASTIAO DO CAI;8893;4319505" },
  { line: "RS SAO SEPE;8895;4319604" },
  { line: "RS SAO VALENTIM;8897;4319703" },
  { line: "RS SAO VALENTIM DO SUL;5997;4319711" },
  { line: "RS SAO VALERIO DO SUL;6075;4319737" },
  { line: "RS SAO VENDELINO;7293;4319752" },
  { line: "RS SAO VICENTE DO SUL;8675;4319802" },
  { line: "RS SAPIRANGA;8899;4319901" },
  { line: "RS SAPUCAIA DO SUL;8901;4320008" },
  { line: "RS SARANDI;8903;4320107" },
  { line: "RS SEBERI;8905;4320206" },
  { line: "RS SEDE NOVA;7335;4320230" },
  { line: "RS SEGREDO;7317;4320263" },
  { line: "RS SELBACH;8907;4320305" },
  { line: "RS SENADOR SALGADO FILHO;1012;4320321" },
  { line: "RS SENTINELA DO SUL;5781;4320354" },
  { line: "RS SERAFINA CORREA;8909;4320404" },
  { line: "RS SERIO;6035;4320453" },
  { line: "RS SERTAO;8911;4320503" },
  { line: "RS SERTAO SANTANA;5761;4320552" },
  { line: "RS SETE DE SETEMBRO;1014;4320578" },
  { line: "RS SEVERIANO DE ALMEIDA;8913;4320602" },
  { line: "RS SILVEIRA MARTINS;7315;4320651" },
  { line: "RS SINIMBU;5767;4320677" },
  { line: "RS SOBRADINHO;8917;4320701" },
  { line: "RS SOLEDADE;8919;4320800" },
  { line: "RS TABAI;1016;4320859" },
  { line: "RS TAPEJARA;8921;4320909" },
  { line: "RS TAPERA;8923;4321006" },
  { line: "RS TAPES;8925;4321105" },
  { line: "RS TAQUARA;8927;4321204" },
  { line: "RS TAQUARI;8929;4321303" },
  { line: "RS TAQUARUCU DO SUL;7313;4321329" },
  { line: "RS TAVARES;8971;4321352" },
  { line: "RS TENENTE PORTELA;8931;4321402" },
  { line: "RS TERRA DE AREIA;7333;4321436" },
  { line: "RS TEUTONIA;9821;4321451" },
  { line: "RS TIO HUGO;1174;4321469" },
  { line: "RS TIRADENTES DO SUL;6077;4321477" },
  { line: "RS TOROPI;1018;4321493" },
  { line: "RS TORRES;8933;4321501" },
  { line: "RS TRAMANDAI;8935;4321600" },
  { line: "RS TRAVESSEIRO;6037;4321626" },
  { line: "RS TRES ARROIOS;7331;4321634" },
  { line: "RS TRES CACHOEIRAS;7329;4321667" },
  { line: "RS TRES COROAS;8937;4321709" },
  { line: "RS TRES DE MAIO;8939;4321808" },
  { line: "RS TRES FORQUILHAS;5777;4321832" },
  { line: "RS TRES PALMEIRAS;7327;4321857" },
  { line: "RS TRES PASSOS;8941;4321907" },
  { line: "RS TRINDADE DO SUL;7325;4321956" },
  { line: "RS TRIUNFO;8943;4322004" },
  { line: "RS TUCUNDUVA;8945;4322103" },
  { line: "RS TUNAS;7323;4322152" },
  { line: "RS TUPANCI DO SUL;5979;4322186" },
  { line: "RS TUPANCIRETA;8947;4322202" },
  { line: "RS TUPANDI;7321;4322251" },
  { line: "RS TUPARENDI;8949;4322301" },
  { line: "RS TURUCU;1020;4322327" },
  { line: "RS UBIRETAMA;1022;4322343" },
  { line: "RS UNIAO DA SERRA;5999;4322350" },
  { line: "RS UNISTALDA;1024;4322376" },
  { line: "RS URUGUAIANA;8951;4322400" },
  { line: "RS VACARIA;8953;4322509" },
  { line: "RS VALE DO SOL;5769;4322533" },
  { line: "RS VALE REAL;6049;4322541" },
  { line: "RS VALE VERDE;1026;4322525" },
  { line: "RS VANINI;7319;4322558" },
  { line: "RS VENANCIO AIRES;8955;4322608" },
  { line: "RS VERA CRUZ;8957;4322707" },
  { line: "RS VERANOPOLIS;8959;4322806" },
  { line: "RS VESPASIANO CORREA;1028;4322855" },
  { line: "RS VIADUTOS;8961;4322905" },
  { line: "RS VIAMAO;8963;4323002" },
  { line: "RS VICENTE DUTRA;8965;4323101" },
  { line: "RS VICTOR GRAEFF;8969;4323200" },
  { line: "RS VILA FLORES;7311;4323309" },
  { line: "RS VILA LANGARO;1030;4323358" },
  { line: "RS VILA MARIA;7309;4323408" },
  { line: "RS VILA NOVA DO SUL;5795;4323457" },
  { line: "RS VISTA ALEGRE;7307;4323507" },
  { line: "RS VISTA ALEGRE DO PRATA;7305;4323606" },
  { line: "RS VISTA GAUCHA;7303;4323705" },
  { line: "RS VITORIA DAS MISSOES;6053;4323754" },
  { line: "RS WESTFALIA;1176;4323770" },
  { line: "RS XANGRI-LA;5785;4323804" },
];

const citiesInfo = citynumbers.map((item) => extractCityInfo(item.line));

export const findCityCode = (city: string) => {
  const mycity = city
    .toUpperCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  const result = citiesInfo.find(
    (item) => item && item.city === mycity
  )?.codeIbge;
  return result;
};

export const findStateCode = (state: string) => {
  return (
    citiesInfo.find((item) => item && item.state === state)?.codeIbge + ""
  ).substring(0, 2);
};

export const cityInfoFilter = (code: string) => {
  return citiesInfo.find(
    (item) => item && parseInt(item.code) === parseInt(code)
  )?.city;
};
