import {
  GraphicsList,
  TableData,
} from "../../Preview/components/graphs/graphTSInterface";
import { Bar } from "react-chartjs-2";
import { Divider } from "antd";
import { ListaValoresGraficoBarComplexo } from "./ListaValoresGraficoBarComplexo";
import { AreaInformations } from "./AreaInformations";
import { format } from "../../../Utils";
import fonts from "../../../../../config/fonts";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ReportBox } from "./ReportBox";

interface CorrectBarsGraphicData {
  grafico: GraphicsList;
  sessao_index: number;
  grafico_index: number;
}

interface GraphicsData {
  label: string;
  data: number[];
  borderRadius: number;
  borderColor: string;
  backgroundColor: string;
}

export interface graphOptions {
  sessao_indexAxis: "x" | "y" | undefined;
  elements: {
    bar: {
      borderWidth: number;
    };
  };
  responsive: boolean;
  plugins: {
    title: {
      display: boolean;
      text: string;
      font?: {
        size?: number;
        fontFamily?: string;
      };
    };
    legend: {
      display: boolean;
    };
    tooltip?: any;
  };
  aspectRatio?: number;
  maintainAspectRatio?: boolean;
  scales?: {
    y?: {
      grace?: string | number;
      ticks?: {
        font?: {
          size?: number;
          fontFamily?: string;
        };
      };
    };
    x?: {
      ticks?: {
        font?: {
          size?: number;
          fontFamily?: string;
        };
      };
    };
  };
}

export function CorrectBarsGraphic({
  grafico,
  sessao_index,
  grafico_index,
}: CorrectBarsGraphicData) {
  const variables = grafico.data;
  const dadosGrafico: GraphicsData[] = [];
  const dadosTabela: TableData[] = [];
  var graphType = 0;

  const labels: string[] = [];
  var filled = false;
  variables.forEach((element) => {
    if (element.variable.labels.length > 1) {
      graphType = 1;
    }
    var tmp: number[] = [];
    element.variable.labels.forEach((lb) => {
      lb.nameDesc = lb.nameDesc.replace("6.060,0)", "6.060,00)"); //o certo é arrumar no banco, mas o responsável vai recusar e mandar fazer gambiarra, então ja vo tocar a gambiarra mesmo...
      if (!filled) {
        labels.push(lb.nameDesc);
      }
      tmp.push(lb.value);
    });
    dadosGrafico.push({
      label: element.area.label,
      data: tmp,
      borderRadius: 5,
      borderColor: element.area.cor,
      backgroundColor: element.area.cor + "66",
    });
    dadosTabela.push({
      areaLabel: element.area.label,
      color: element.area.cor,
      form: element.area.studyType,
      totalValue: tmp,
    });
    filled = true;
  });
  if (graphType === 0) {
    const optionsPessoas: graphOptions | any = {
      sessao_indexAxis: "x",
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      events: [],
      tooltips: { enabled: false },
      hover: { mode: null },
      scales: {
        y: {
          grace: "10%",
        },
      },
      plugins: {
        tooltips: { enabled: false },
        hover: { mode: null },
        title: {
          display: false,
          text: grafico.graphicname,
          font: {
            size: 18,
          },
        },
        legend: {
          display: false,
        },

        datalabels: {
          padding: {
            top: 4,
            bottom: 4,
            right: 6,
            left: 6,
          },
          align: "top",
          anchor: "end",
          backgroundColor: function () {
            return "transparent";
          },
          borderColor: function () {
            return null;
          },

          borderRadius: 15,
          color: "black",
          font: {
            family: fonts.regular,
            size: 13,
          },
          formatter: (value: any, context: any) => {
            if (!value) {
              return "";
            } else if (value >= 1000000) {
              const millionValue =
                format(value / 1000000, grafico.measureUnit) + "M";
              return millionValue.replace(".", ",");
            } else {
              return format(Math.round(value * 100) / 100, grafico.measureUnit);
            }
          },

          display: function () {
            return true;
          },
        },
        onHover: function () {
          var chart: any = this;

          chart.update();
        },
      },
      aspectRatio: 1,
    };

    const data = {
      labels,
      datasets: dadosGrafico,
    };

    if (dadosTabela.length === 1) {
      return (
        <div
          key={grafico_index}
          id={"sessao" + sessao_index + "_" + grafico_index}
        >
          <ReportBox
            label={grafico.graphicname}
            value={format(dadosTabela[0].totalValue[0], grafico.measureUnit)}
          />
          <Divider />
        </div>
      );
    } else {
      return (
        <div
          key={grafico_index}
          id={"sessao" + sessao_index + "_" + grafico_index}
        >
          <div style={{ display: "flex" }}>
            <div style={{ width: "50%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "70%",
                    alignContent: "center",
                    alignSelf: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Bar
                    options={optionsPessoas}
                    data={data}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  verticalAlign: "center",
                  textAlign: "center",
                  textAlignLast: "center",
                }}
              >
                {grafico.graphicname}
              </p>
              {dadosTabela.map((area, index) => {
                return (
                  <div key={index}>
                    <AreaInformations
                      ikey={index}
                      name={"Área " + (index + 1)}
                      studyType={area.form}
                      color={area.color}
                      informations={[
                        format(area.totalValue[0], grafico.measureUnit),
                      ]}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <Divider />
        </div>
      );
    }
  } else {
    const optionsPessoas: graphOptions = {
      sessao_indexAxis: "x",
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: grafico.graphicname, //'Total de pessoas',
          font: {
            size: 18,
          },
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (context: any) {
              return format(context.parsed.y, grafico.measureUnit);
            },
          },
        },
      },
      aspectRatio: 2,
      scales: {
        y: {
          grace: "10%",
          ticks: {
            font: {
              size: 14,
            },
          },
        },
        x: {
          ticks: {
            font: {
              size: 14,
            },
          },
        },
      },
    };

    const data = {
      labels,
      datasets: dadosGrafico,
    };

    return (
      <div
        key={grafico_index}
        id={"sessao" + sessao_index + "_" + grafico_index}
      >
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <div
            style={{
              width: "70%",
              alignContent: "center",
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Bar options={optionsPessoas} data={data} />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          {dadosTabela.map((parametrosevalores, k) => {
            return (
              <ListaValoresGraficoBarComplexo
                k={k}
                parametrosevalores={parametrosevalores}
                grafico={grafico}
              />
            );
          })}
        </div>
        <Divider />
      </div>
    );
  }
}
