import { ButtonDefault } from "../../../../components/Button/ButtonDefault";
import {
  AreaListData,
  getRelatedArea,
} from "../Preview/components/AreaListData";
import {
  CompleteRDPolygon,
  CompleteReportData,
  GraphicsListByType,
  GraphTotalData,
  GraphTotalPackage,
} from "../Preview/components/graphs/graphTSInterface";
import { getMapaDeDescricoesParaPDF } from "./descricaoDasSessoes";
import { chartonumber, convertToHex, format } from "../../Utils";
import { TitleModal } from "../../../../components/typography/TitleModal";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import { SubTitleModal } from "../../../../components/typography/SubTitleModalReports";
import { ReportHtml } from "./reporthtml";
import { UserToken } from "../../../../services/userToken";
import GA4 from "react-ga4";
import { pdf } from "@react-pdf/renderer";
import React from "react";
import { salvaLog } from "../../../../services/getOptionsData";

const ExcelJS = require("exceljs");

interface ReportHtmlProps {
  areaList: AreaListData[];
  graphicsData: CompleteReportData;
  listadesessoes: GraphicsListByType[];
  setSelecionado: React.Dispatch<React.SetStateAction<number>> | null;
  imageUrl: string;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  addressFormat: Function;
  simpleStudyData: GraphTotalPackage | undefined;
}
export function formatDate(date: Date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Os meses em JavaScript são base 0, por isso é necessário adicionar 1
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function IntroMap({
  areaList,
  graphicsData,
  listadesessoes,
  setSelecionado = null,
  imageUrl,
  loading,
  setLoading,
  addressFormat,
  simpleStudyData,
}: ReportHtmlProps): JSX.Element {
  const descricaoDasSessoes = getMapaDeDescricoesParaPDF();
  const linkRef = React.useRef<HTMLAnchorElement>(null);
  function translateStudyType(st: string) {
    if (st === "RADIUS_STUDY") {
      return "Raio";
    }
    if (st === "RECTANGLE_STUDY") {
      return "Retângulo";
    }
    if (st === "POLYGON_STUDY") {
      return "Polígono";
    }
    return st;
  }

  async function createExcelData(callback: () => void): Promise<void> {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = "Palmsoft";
    workbook.lastModifiedBy = "Palmsoft";
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    workbook.properties.date1904 = true;

    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 4000,
        height: 8000,
        firstSheet: 0,
        activeTab: 1,
        visibility: "visible",
      },
    ];

    // create new sheet with pageSetup settings for A4 - landscape
    const worksheet = workbook.addWorksheet("A4 Portrait", {
      pageSetup: { paperSize: 9, orientation: "portrait" },
      headerFooter: {
        firstHeader: "Hello Exceljs",
        firstFooter: "Hello World",
      },
    });

    const todasfiguras: CompleteRDPolygon[] = [
      ...graphicsData.radiusList,
      ...graphicsData.rectangleList,
      ...graphicsData.polygonList,
    ];

    worksheet.columns = [
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
    ];

    worksheet.addRow(["Cor", "Endereço", "Formato", "Área Km²"]);
    worksheet.getRow(1).style = { font: { name: "Calibri", bold: true } };
    for (let index = 0; index < todasfiguras.length; index++) {
      const polygonarea = todasfiguras[index];
      const relArea = getRelatedArea(polygonarea.index, areaList);
      worksheet.addRow([
        "",
        addressFormat(polygonarea.centroid),
        translateStudyType(relArea?.studyType),
        format(polygonarea.area, "Decimal"),
      ]);
      worksheet.getRow(index + 2).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: relArea?.cor.replace("#", "FF") },
      };
    }

    /*
Adiciona a imagem do mapa...
comentado por não estar no mock

        const doc = new jsPDF('p', 'pt');
        const imgProps = doc.getImageProperties(imageUrl);

        const imageBuffer = await axios.get(imageUrl, { responseType: 'arraybuffer' });
        const mapa = workbook.addImage({
            buffer: imageBuffer.data,
            extension: 'png',
        });



        worksheet.addImage(mapa, {
            tl: { col: 0, row: todasfiguras.length + 2 },
            ext: { width: imgProps.width / 2, height: imgProps.height / 2 }
        });

        var currentRow = todasfiguras.length + Math.round(imgProps.height / 40) + 3;
*/
    var currentRow = todasfiguras.length + 3;
    worksheet.getRow(currentRow).values = ""; // adiciona uma linha em branco entre o mapa e o resto dos dados...

    var areaCor = new Map<string, string>();

    for (let indexS = 0; indexS < listadesessoes.length; indexS++) {
      const sessao = listadesessoes[indexS];

      if (sessao.name === "Alfinetes" || sessao.name === "CNPJ") {
        //skip
      } else {
        worksheet.addRow([sessao.name]);
        currentRow++;
        worksheet.getRow(currentRow).height = 20;
        worksheet.getRow(currentRow).getCell(1).style = {
          font: { name: "Calibri", bold: true, size: 20 },
        };

        worksheet.addRow([]);
        currentRow++;

        for (let indexG = 0; indexG < sessao.graphicsList.length; indexG++) {
          const grafico = sessao.graphicsList[indexG];

          worksheet.addRow([grafico.graphicname]);
          currentRow++;
          worksheet.getRow(currentRow).getCell(1).style = {
            font: { name: "Calibri", bold: true },
          };

          for (let indexD = 0; indexD < grafico.data.length; indexD++) {
            const view = grafico.data[indexD];
            var row = worksheet.getRow(currentRow);
            row.getCell(indexD + 2).value = addressFormat(view.poly.centroid);
            row.getCell(indexD + 2).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: view.area.cor.replace("#", "FF") },
            };
            if (!areaCor.has(view.area.cor)) {
              areaCor.set(view.area.cor, addressFormat(view.poly.centroid));
            }

            const vals: { value: number; cell: any }[] = [];
            for (
              let indexV = 0;
              indexV < view.variable.labels.length;
              indexV++
            ) {
              const variavel = view.variable.labels[indexV];
              row = worksheet.getRow(currentRow + 1 + indexV);

              row.getCell(1).value = variavel.nameDesc;
              row.getCell(indexD + 2).value = format(
                variavel.value,
                grafico.measureUnit
              );

              vals.push({
                value: variavel.value,
                cell: row.getCell(indexD + 2),
              });
            }
            for (let index = 0; index < vals.length; index++) {
              for (let indexx = index + 1; indexx < vals.length; indexx++) {
                if (vals[indexx].value > vals[index].value) {
                  var tmp = vals[index];
                  vals[index] = vals[indexx];
                  vals[indexx] = tmp;
                }
              }
              vals[index].cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: {
                  argb: degradeCor(view.area.cor, vals.length, index),
                },
              };
            }
          }

          worksheet.addRow([""]);
          currentRow++;
          //somente contabiliza rows depois de todos dados do grafico serem processados.
          for (let indexD = 0; indexD < grafico.data.length; indexD++) {
            const view = grafico.data[indexD];
            for (
              let indexV = 0;
              indexV < view.variable.labels.length;
              indexV++
            ) {
              currentRow++;
            }
            break;
          }
        }
      }
    }

    worksheet.addRow(["CNPJ"]);
    currentRow++;
    worksheet.getRow(currentRow).height = 20;
    worksheet.getRow(currentRow).getCell(1).style = {
      font: { name: "Calibri", bold: true, size: 20 },
    };
    worksheet.addRow([""]);
    currentRow++;

    if (simpleStudyData) {
      currentRow = CNPJtoXML(
        simpleStudyData.graphCS,
        "Capital Social",
        currentRow,
        worksheet,
        areaCor
      );
      currentRow = CNPJtoXML(
        simpleStudyData.graphPort,
        "Porte",
        currentRow,
        worksheet,
        areaCor
      );
      currentRow = CNPJtoXML(
        simpleStudyData.graphEstado,
        "Estado",
        currentRow,
        worksheet,
        areaCor
      );
      currentRow = CNPJtoXML(
        simpleStudyData.graphInicioAtv,
        "Início de Atividades",
        currentRow,
        worksheet,
        areaCor
      );
    }
    workbook.xlsx.writeBuffer().then(function (data: BlobPart) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      let day = new Date();
      anchor.download = `Relatorio-${formatDate(day)}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });

    callback();
  }

  function degradeCor(
    cor: string,
    totalDeValoresOrdenados: number,
    peso: number
  ) {
    var R = chartonumber(cor.charAt(1)) * 16 + chartonumber(cor.charAt(2));
    var G = chartonumber(cor.charAt(3)) * 16 + chartonumber(cor.charAt(4));
    var B = chartonumber(cor.charAt(5)) * 16 + chartonumber(cor.charAt(6));
    const RStep = (255 - R) / totalDeValoresOrdenados;
    const GStep = (255 - G) / totalDeValoresOrdenados;
    const BStep = (255 - B) / totalDeValoresOrdenados;
    return (
      convertToHex(R + RStep * peso) +
      convertToHex(G + GStep * peso) +
      convertToHex(B + BStep * peso)
    );
  }

  function CNPJtoXML(
    graphtotdata: GraphTotalData,
    nome: string,
    startRow: number,
    worksheet: any,
    areaCor: Map<string, string>
  ) {
    var currentRow = startRow;

    var row;
    worksheet.addRow([]);
    currentRow++;
    worksheet.getRow(currentRow).getCell(1).value = nome;
    worksheet.getRow(currentRow).getCell(1).style = {
      font: { name: "Calibri", bold: true },
    };

    const datarow = currentRow;
    for (
      let indexset = 0;
      indexset < graphtotdata.datasets.length;
      indexset++
    ) {
      currentRow = datarow;

      row = worksheet.getRow(currentRow);

      row.getCell(2 + indexset).value = areaCor.get(
        graphtotdata.datasets[indexset].backgroundColor.substring(0, 7)
      );
      row.getCell(2 + indexset).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: graphtotdata.datasets[indexset].backgroundColor.substring(1, 7),
        },
      };

      const vals: { value: number; cell: any }[] = [];
      for (let indexV = 0; indexV < graphtotdata.labels.length; indexV++) {
        const variavel = graphtotdata.labels[indexV];
        row = worksheet.getRow(currentRow + 1 + indexV);

        row.getCell(1).value = variavel;
        row.getCell(2 + indexset).value = format(
          graphtotdata.datasets[indexset].data[indexV],
          "Integer"
        );

        vals.push({
          value: graphtotdata.datasets[indexset].data[indexV],
          cell: row.getCell(2 + indexset),
        });
      }
      for (let index = 0; index < vals.length; index++) {
        for (let indexx = index + 1; indexx < vals.length; indexx++) {
          if (vals[indexx].value > vals[index].value) {
            var tmp = vals[index];
            vals[index] = vals[indexx];
            vals[indexx] = tmp;
          }
        }
        vals[index].cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: degradeCor(
              graphtotdata.datasets[indexset].backgroundColor.substring(0, 7),
              vals.length,
              index
            ),
          },
        };
      }
      worksheet.addRow([""]);
      currentRow++;
      //somente contabiliza rows depois de todos dados do grafico serem processados.
      for (let indexV = 0; indexV < graphtotdata.labels.length; indexV++) {
        currentRow++;
      }
    }
    return currentRow;
  }

  const items = [] as any;
  const graphics = [] as any;
  const dataForPDF = () => {
    if (graphicsData?.radiusList) {
      for (let i = 0; i < graphicsData.radiusList.length; i++) {
        graphics.push({
          address: addressFormat(graphicsData.radiusList[i].centroid),
          index: graphicsData.radiusList[i].index,
          area: graphicsData.radiusList[i].area,
        });
      }
    }
    if (graphicsData?.rectangleList) {
      for (let i = 0; i < graphicsData.rectangleList.length; i++) {
        graphics.push({
          address: addressFormat(graphicsData.rectangleList[i].centroid),
          index: graphicsData.rectangleList[i].index,
          area: graphicsData.rectangleList[i].area,
        });
      }
    }
    for (let i = 0; i < areaList.length; i++) {
      for (let j = 0; j < graphics.length; j++) {
        if (areaList[i].id === graphics[j].index) {
          items.push({
            id: i,
            color: areaList[i].cor,
            studyType: areaList[i].studyType,
            label: areaList[i].label,
            address: graphics[j].address,
            area: graphics[j].area,
          });
        }
      }
    }
    return items;
  };

  return (
    <div style={{ width: "100%" }}>
      <TitleModal size="large" style={{ textAlign: "center" }}>
        ESTUDOS
      </TitleModal>
      <SubTitleModal>
        Cada estudo criado no mapa é representando por uma cor, ao longo de todo
        relatório essas cores vão ser usadas para representar cada estudo.
      </SubTitleModal>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            marginTop: "10px",
            color: "black",
            display: "flex",
            width: "400px",
            justifyContent: "space-around",
          }}
        >
          <a ref={linkRef} style={{ display: "none" }} />
          <ButtonDefault
            disabled={loading}
            style={{ flex: 1, marginRight: "5px", borderRadius: "50px" }}
            title="EXPORTAR PDF"
            icon={<FaFilePdf />}
            iconPosition="right"
            color="secondary"
            fontSecondary
            border={false}
            onClick={async () => {
              dataForPDF();
              setLoading(true);
              salvaLog({
                action: "Exportar PDF",
                params: "",
              }).then((result: any) => {});
              if (setSelecionado != null) {
                setSelecionado(-1);
                setTimeout(async () => {
                  let document = await ReportHtml(
                    imageUrl,
                    descricaoDasSessoes,
                    listadesessoes,
                    addressFormat,
                    items
                  );

                  let blobPdf = await pdf(document).toBlob();

                  const fileURL = window.URL.createObjectURL(blobPdf);
                  if (linkRef.current) {
                    let day = new Date();
                    linkRef.current.href = fileURL;
                    linkRef.current.download = `Relatorio-${formatDate(
                      day
                    )}.pdf`; // Nome do arquivo de download
                    linkRef.current.click();

                    URL.revokeObjectURL(fileURL);
                    setSelecionado(0);
                    setLoading(false);
                  }
                }, 3000);
              }
            }}
          />
          <ButtonDefault
            disabled={loading}
            style={{ flex: 1, marginRight: "5px", borderRadius: "50px" }}
            title="EXPORTAR EXCEL"
            icon={<FaFileExcel />}
            iconPosition="right"
            color="secondary"
            fontSecondary
            border={false}
            onClick={() => {
              salvaLog({
                action: "Exportar EXCEL",
                params: "",
              }).then((result: any) => {});
              setLoading(true);
              createExcelData(() => setLoading(false));
            }}
          />
        </div>
      </div>
      <div
        id="put-image-here"
        style={{
          marginTop: "10px",
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      ></div>
    </div>
  );
}
