import { useEffect, useState } from "react";
import { GeoJSON } from "react-leaflet";
import { useLeafletContext } from "@react-leaflet/core";
import geojsonvt from "geojson-vt";
import L, { LatLngTuple, LayerGroup } from "leaflet";
import {
  getBrasilGeoJson,
  getStateGeoJson,
} from "../../../../services/getMapShapers";
import { Shapes } from "../../../../Types/Shapes";
import {
  getDataFilter,
  getValidLimitStudies,
  salvaLog,
} from "../../../../services/getOptionsData";
import { ModalDefault } from "../../../../components/Modal/ModalDefault";
import { FaGlobeAmericas } from "react-icons/fa";
import { ButtonDefault } from "../../../../components/Button/ButtonDefault";
import { labelsProps } from "../../ReactMap";
import { colorizeDominioFeatures } from "../../components/Legend/utils/freeNavigation/colorMap/colorizeDominioFeatures";
import { colorizeValuesFeatures } from "../../components/Legend/utils/freeNavigation/colorMap/colorizeValuesFeatures";
import { FreeNavigationParams } from "../../../../Types/Params";
import { Loading } from "../../../../components/Loading/Loading";
import { insidePolygon } from "../../../../utils/checkpoly";
import { drawPolygon } from "../utils/Creation/CreatePolygon";
import { getNextColor } from "../../../../assets/colorService";
import { PolygonShapesProps } from "../../../../Types/interfacs";
import { PolygonProps } from "../utils/types";
import { FaCircleExclamation } from "react-icons/fa6";
import { TitleModal } from "../../../../components/typography/TitleModal";
import { SubTitle } from "../../../../components/typography/SubTitle";

interface GeoJsonLayerProps {
  processing: boolean;
  draggable: Shapes;
  variable: string;
  layerShapes: LayerGroup;
  colorIndex: number;
  polygon: PolygonShapesProps;
  setPolygon: React.Dispatch<React.SetStateAction<PolygonShapesProps>>;
  activeLabels: string[];
  setActualLabels: React.Dispatch<React.SetStateAction<labelsProps[]>>;
  paramsToFreeNavigation: FreeNavigationParams;
  setParamsToFreeNavigation: React.Dispatch<
    React.SetStateAction<FreeNavigationParams>
  >;
  actualColors: {
    hue: number;
    sat: string;
    lum: string;
  }[];
}

let geoJsonLayer = L.layerGroup();

export function GeoJsonLayer({
  processing,
  draggable,
  activeLabels,
  setActualLabels,
  layerShapes,
  colorIndex,
  polygon,
  setPolygon,
  actualColors,
  paramsToFreeNavigation,
  setParamsToFreeNavigation,
}: GeoJsonLayerProps) {
  const map = useLeafletContext().map;
  map.addLayer(geoJsonLayer);

  const [geoJson, setGeoJson] = useState<any>(null);
  const [stateNumber, setStateNumber] = useState<string>("");
  const [newStateNumber, setnewStateNumber] = useState<string>("");
  const [geoJsonState, setGeoJsonState] = useState<any>(null);
  const [actualState, setActualState] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actualGeojson, setActualGeojson] = useState<any>(null);
  const [selectedCities, setSelectedCities] = useState<any>([]);
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [modalNoticeOpen, setModalNoticeOpen] = useState(false);

  let slider_value = 50;

  useEffect(() => {
    getBrasilGeoJson().then((geoJson) => {
      setGeoJson(geoJson);
    });
  }, [map]);

  function checkArray(arr: any[]): boolean {
    if (arr.length === 0) {
      return false;
    }
    const primeiroArray = arr[0];
    const ultimoArray = arr[arr.length - 1];

    return JSON.stringify(primeiroArray) === JSON.stringify(ultimoArray);
  }

  function flattenNestedArrays(array: any): any {
    if (checkArray(array)) {
      return array;
    }

    return array.map((item: any) => {
      if (Array.isArray(item)) {
        return flattenNestedArrays(item).flat(1);
      }
      return item;
    });
  }

  useEffect(() => {
    const newPolygonListString: string[] = [];

    polygon.polygonList.forEach((item: any) => {
      const polygonPoints = item.points;
      const polygonPointsString = JSON.stringify(polygonPoints);

      if (selectedCities.includes(polygonPointsString)) {
        newPolygonListString.push(polygonPointsString);
      }
    });

    setSelectedCities(newPolygonListString);
  }, [polygon]);

  function drawPolygonsFromArray(
    arrays: any[],
    layerShapes: any,
    lng: number,
    lat: number
  ): void {
    arrays.forEach((array: any) => {
      if (Array.isArray(array) && insidePolygon(lng, lat, array.flat(1))) {
        let polygonPoints: any[] = [];
        if (array[0].length === 2) {
          polygonPoints = array.map((item: any) => [item[1], item[0]]);
        } else {
          for (let i = 0; i < array.length - 1; i = i + 2) {
            polygonPoints.push([array[i + 1], array[i]]);
          }
        }

        const polygonPointsString = JSON.stringify(polygonPoints);

        const color = getNextColor(colorIndex);

        if (selectedCities.includes(polygonPointsString)) return;
        const polygonCity = drawPolygon(
          polygonPoints as LatLngTuple[],
          color
        ) as PolygonProps;

        setSelectedCities([...selectedCities, polygonPointsString]);

        polygonCity.addTo(layerShapes);

        const idShape = polygonCity._leaflet_id;

        setPolygon((oldPolygon: any) => {
          return {
            ...oldPolygon,
            polygonList: [
              ...oldPolygon["polygonList"],
              {
                id: idShape,
                points: polygonPoints,
                type: "POLYGON_STUDY",
                color: color,
              },
            ],
          };
        });
      }
    });
  }

  useEffect(() => {
    const numberStudies =
      polygon.polygonList.length +
      polygon.rectangleList.length +
      polygon.radiusList.length;

    getValidLimitStudies({
      used: numberStudies + 1,
    }).then((response) => {
      if (!response) return;
      setLimitExceeded(response.valid);
    });
  }, [polygon]);

  useEffect(() => {
    if (actualGeojson && !limitExceeded) {
      map.on("click", () => {
        setModalNoticeOpen(true);
      });
    }

    if (!actualGeojson || modalOpen || processing || !limitExceeded) return;

    const arrays: any[] = [];

    actualGeojson.features.forEach((feature: any) => {
      let array = feature.geometry.coordinates;
      if (!array) return;
      array = flattenNestedArrays(array);
      arrays.push(...array);
    });

    const selectCity = (e: L.LeafletMouseEvent) => {
      const { lat, lng } = e.latlng;

      drawPolygonsFromArray(arrays, layerShapes, lng, lat);
    };

    map.on("click", selectCity);

    return () => {
      map.off("click");
    };
  }, [
    actualGeojson,
    modalOpen,
    selectedCities,
    colorIndex,
    processing,
    limitExceeded,
  ]);

  useEffect(() => {
    if (
      paramsToFreeNavigation.id !== "" ||
      paramsToFreeNavigation.exclude !== null
    ) {
      handleModalConfirm();
    } else if (paramsToFreeNavigation.exclude === null) {
      if (geoJsonState) {
        map.removeLayer(geoJsonState);
      }
    }
  }, [paramsToFreeNavigation]);

  const handleModalConfirm = async () => {
    setLoading(true);
    let stateId = paramsToFreeNavigation.id;
    if (stateId === "350" || stateId === "351") {
      stateId = "35";
    }
    const dataFilterParams =
      paramsToFreeNavigation.exclude === null
        ? { ...paramsToFreeNavigation, id: stateId }
        : {
            ...paramsToFreeNavigation,
            id: "",
            exclude: paramsToFreeNavigation.exclude,
          };

    const dataFilter = await getDataFilter(dataFilterParams);

    if (dataFilter) {
      const labels = dataFilter.labels.map((item: any) => {
        return {
          name: item.nameDesc,
          value: item.name,
        };
      });

      setStateNumber(paramsToFreeNavigation.id);
      setActualLabels(labels);
      setActualState(dataFilter);
      setModalOpen(false);
      salvaLog({
        action: "Selecionou um Estado",
        params: "" + Number(stateId),
      }).then((result: any) => {});
    }
  };

  const handleModalCancel = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (activeLabels.length > 0) {
      colorizeGeoJson();
    }

    function colorizeGeoJson() {
      /* implementação com glify se necessário trocar pelo geojson-vt*/
      // if (geoJsonState) {
      //   geoJsonState.remove();
      // }

      if (geoJsonState) {
        map.removeLayer(geoJsonState);
      }

      const param =
        paramsToFreeNavigation.exclude === null ? stateNumber : "brazil_cities";
      getStateGeoJson(param, setLoading).then((geoJson) => {
        const actualFilters = {
          isUsingCities: paramsToFreeNavigation.exclude !== null ? true : false,
          slider_value,
          actualState,
          activeLabels,
          actualColors,
        };

        setActualGeojson(geoJson);

        if (activeLabels.length > 1) {
          colorizeDominioFeatures(geoJson, actualFilters);
        } else {
          colorizeValuesFeatures(geoJson, actualFilters);
        }

        const options = {
          maxZoom: 18,
          tolerance: 5,
          extent: 4096,
          buffer: 64,
          debug: 0,

          indexMaxZoom: 0,
          indexMaxPoints: 100000,
        };

        let tileIndex = geojsonvt(geoJson, {
          maxZoom: 18,
          tolerance: 5,
          extent: 4096,
          buffer: 64,
          debug: 0,

          indexMaxZoom: 0,
          indexMaxPoints: 100000,
        });

        var CanvasLayer = L.GridLayer.extend({
          options: {
            async: false,
          },
          myTileIndex: tileIndex,
          initialize: function () {
            L.setOptions(this, options);
          },
          createTile: function (coords: any) {
            var error: any = null;

            // create a <canvas> element for drawing
            var tileD = L.DomUtil.create("canvas", "leaflet-tile");

            // setup tile width and height according to the options
            var size = this.getTileSize();
            tileD.width = size.x;
            tileD.height = size.y;

            var ctx = tileD.getContext("2d");
            if (!ctx) return;

            ctx.save();
            ctx.globalCompositeOperation = "source-over";

            var tile = this.myTileIndex.getTile(coords.z, coords.x, coords.y);
            if (tile) {
              var features = tile.features;

              var backgroundColor = "rgba(0,0,0,0)";

              ctx.strokeStyle = "#000000";

              var pad = 0;

              for (var i = 0; i < features.length; i++) {
                var feature = features[i],
                  type = feature.type;

                ctx.fillStyle = feature.tags.color
                  ? feature.tags.color
                  : backgroundColor;

                ctx.beginPath();

                for (var j = 0; j < feature.geometry.length; j++) {
                  var geom = feature.geometry[j];

                  for (var k = 0; k < geom.length; k++) {
                    var p = geom[k];
                    var extent = 4096;

                    var x = (p[0] / extent) * 256;
                    var y = (p[1] / extent) * 256;

                    if (k) ctx.lineTo(x + pad, y + pad);
                    else ctx.moveTo(x + pad, y + pad);
                  }
                }

                if (type === 3 || type === 1) {
                  ctx.fill("evenodd");
                }
                // ctx.stroke();
              }
            }

            return tileD;
          },
        });

        setSelectedCities([]);
        const geoJsonCanvas = new CanvasLayer();
        geoJsonCanvas.addTo(map);

        /* implementação com glify se necessário trocar pelo geojson-vt*/
        // const geoJsonState = glify.shapes({
        //   map,
        //   data: geoJson,
        //   color: (index, feature) => {
        //     return feature.properties.color
        //       ? feature.properties.color
        //       : {
        //           r: 0,
        //           g: 0,
        //           b: 0,
        //           a: 0,
        //         };
        //   },
        // });

        setLoading(false);

        /* implementação com glify se necessário trocar pelo geojson-vt*/
        // setGeoJsonState(geoJsonState);

        setGeoJsonState(geoJsonCanvas);
      });
    }
  }, [activeLabels, map]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {geoJson &&
            draggable === "arrow" &&
            paramsToFreeNavigation.exclude === null && (
              <GeoJSON
                data={geoJson}
                style={() => ({
                  color: "transparent",
                  fillColor: "transparent",
                })}
                onEachFeature={(feature, layer) => {
                  layer.on({
                    click: () => {
                      let stateNumber = feature.properties.CD_GEOCUF;
                      if (stateNumber === "35") {
                        stateNumber =
                          stateNumber + feature.properties.CD_EXCLUDE;
                      }
                      setnewStateNumber(stateNumber);
                      if (stateNumber !== paramsToFreeNavigation.id) {
                        setModalOpen(true);
                      }
                    },
                  });
                }}
              />
            )}
          {modalOpen && paramsToFreeNavigation.id !== newStateNumber && (
            <ModalDefault
              icon={<FaGlobeAmericas />}
              setModalOpen={setModalOpen}
              title="Navegação Livre"
            >
              <p>Deseja selecionar esse novo estado?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                <ButtonDefault
                  style={{ flex: 1, marginRight: 5 }}
                  color="secondary"
                  size="medium"
                  title="Confirmar"
                  onClick={() => {
                    setParamsToFreeNavigation({
                      ...paramsToFreeNavigation,
                      id: newStateNumber,
                    });
                    setLoading(true);
                  }}
                />
                <ButtonDefault
                  style={{ flex: 1, marginLeft: 5 }}
                  border
                  title="Cancelar"
                  size="medium"
                  onClick={handleModalCancel}
                />
              </div>
            </ModalDefault>
          )}

          {modalNoticeOpen && (
            <ModalDefault
              icon={<FaCircleExclamation />}
              setModalOpen={setModalNoticeOpen}
              title="Limite de estudos excedido"
              styleContent={{
                maxWidth: 500,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TitleModal>LIMITE DE ESTUDOS EXCEDIDO</TitleModal>
                <SubTitle
                  style={{
                    textAlign: "center",
                    marginBlock: 14,
                  }}
                >
                  Você atingiu o limite de estudos disponíveis para o seu plano.
                  Entre em contato com a nossa equipe para adquirir mais
                  estudos.
                </SubTitle>
              </div>
            </ModalDefault>
          )}
        </>
      )}
    </>
  );
}
