import { LatLngExpression } from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { FaBookOpen, FaSearch } from "react-icons/fa";
import { IoMdPin } from "react-icons/io";
import { useDebounce } from "../../../../../utils/useDebounce";
import { ButtonDefault } from "../../../../../components/Button/ButtonDefault";
import {
  google_autocomplete,
  find_lat_lon_by_address,
  salvaLog,
} from "../../../../../services/getOptionsData";
import { MapData } from "../../../../../services/mapData";
import { Title } from "../../../../../components/typography/Title";
import { SubTitle } from "../../../../../components/typography/SubTitle";
import { Icon } from "../../../../../components/icon/Icon";
import "./Search.css";

/*
salvaLog({
  action: "Pesquisou Endereço",
  params: ""+document.getElementById("input-search")?.value,
}).then((result: any) => {
});
*/
interface SearchProps {
  main_text_matched_substrings: Array<{
    offset: number;
    length: number;
  }>;
  secondary_text: string;
  main_text: string;
}

interface ReturnSearchByAddressProps {
  lat: number;
  lon: number;
  address: string;
}

interface ReturnSearchProps {
  structured_formatting: SearchProps;
  description: string;
}

interface SearchProps {
  setCenter: React.Dispatch<React.SetStateAction<LatLngExpression>>;
  setNewSearch: React.Dispatch<
    React.SetStateAction<{
      typePin: boolean;
      latLng: LatLngExpression;
      radius: string;
    }>
  >;
}

export function Search({ setNewSearch }: SearchProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTypePin, setSearchTypePin] = useState(true);
  const [suggestions, setSuggestions] = useState<Array<{
    main_text: string;
    secondary_text: string;
    description: string;
  }> | null>(null);
  const [searchOnHover, setSearchOnHover] = useState(false);
  const [radius, setRadius] = useState("");

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    const thisInput = document.getElementById("radius-input");
    if (thisInput && radius) {
      thisInput.focus();
    }
  }, [radius]);

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      const params = {
        input: debouncedSearchTerm,
        lat: MapData.center.lat,
        lon: MapData.center.lng,
        radius: 2800000,
      };
      google_autocomplete(params).then((data) => {
        const address = data.address;
        const establishment = data.establishment;
        setSuggestions([
          ...address.map((item: ReturnSearchProps) => ({
            main_text: item.structured_formatting.main_text,
            secondary_text: item.structured_formatting.secondary_text,
            description: item.description,
          })),
          ...establishment.map((item: ReturnSearchProps) => ({
            main_text: item.structured_formatting.main_text,
            secondary_text: item.structured_formatting.secondary_text,
            description: item.description,
          })),
        ]);
      });
    } else {
      setSuggestions(null);
    }
  }, [debouncedSearchTerm]);

  const handleSearch = (e: any) => {
    if (e.preventDefault) e.preventDefault();

    const address = typeof e === "string" ? e : searchTerm;
    if (address.length > 0) {
      const params = {
        address,
      };
      find_lat_lon_by_address(params).then(
        (data: ReturnSearchByAddressProps) => {
          if (data != null) {
            const { lat, lon } = data;
            setNewSearch({
              typePin: searchTypePin,
              latLng: [lat, lon],
              radius,
            });
          } else {
            //TODO Endereço não encontrado, então programa não responde.
            //Pedir para o usuário refinar a pesquisa??
            //Acho que não precisa, mas se precisar, implementar aqui...
          }
        }
      );
    }
  };

  function SearchType() {
    return (
      <div id="search-type-container" key="search-type-container">
        <form key="radius-form">
          <Title color="disabled">Tipo de pesquisa</Title>
          <SubTitle>
            Selecione abaixo o tipo de pesquisa que deseja utilizar.
          </SubTitle>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
            }}
          >
            <ButtonDefault
              size="medium"
              title="Alfinete"
              icon={<IoMdPin />}
              color={searchTypePin ? "secondary" : "disabled"}
              border={searchTypePin ? false : true}
              borderRadius={40}
              onClick={() => {
                setSearchTypePin(true);
              }}
            />
            <ButtonDefault
              size="medium"
              title="Estudos"
              icon={<FaBookOpen />}
              color={searchTypePin ? "disabled" : "secondary"}
              border={searchTypePin ? true : false}
              borderRadius={40}
              onClick={() => {
                setSearchTypePin(false);
              }}
            />
          </div>
          {!searchTypePin ? (
            <input
              id="radius-input"
              key="radius-input"
              style={{
                width: "100%",
                height: 48,
                fontSize: 16,
                marginTop: 8,
                padding: 8,
                borderRadius: 5,
                border: "1px solid #D5D5D5",
                opacity: 0.5,
                outline: "none",
              }}
              name="interesses"
              type="number"
              placeholder="Tamanho dos estudos em Km²"
              value={radius}
              onChange={(e) => {
                setRadius(e.target.value);
              }}
            />
          ) : (
            <></>
          )}
        </form>
        {/* implementar depois
        <ButtonLink onClick={() => {}}>Múltiplos endereços</ButtonLink>
        */}
      </div>
    );
  }

  return (
    <div id="base-container" key="base-container">
      <div
        id="all-container-search"
        key="all-container-search"
        onMouseEnter={() => {
          setSearchOnHover(true);
        }}
        onMouseLeave={() => {
          setSearchOnHover(false);
        }}
      >
        <div id="container-search">
          <form id="form-search" onSubmit={handleSearch}>
            <input
              id="input-search"
              type="text"
              placeholder="Pesquisar endereço"
              autoComplete="off"
              value={searchTerm}
              onChange={handleInputChange}
            />
          </form>
          {searchOnHover && (
            <>
              {suggestions && (
                <>
                  <div
                    id="divider"
                    style={{
                      width: "94%",
                      height: 1.5,
                      backgroundColor: "#F2F2F2",
                    }}
                  />
                  <div id="suggestions-container">
                    {suggestions.map((suggestion, index) => (
                      <button
                        key={index}
                        id="suggestion-button"
                        onClick={() => {
                          setSearchTerm(suggestion.description);
                          setSuggestions(null);
                          handleSearch(suggestion.description);
                        }}
                      >
                        <div id="suggestion-icon">
                          <Icon icon={<IoMdPin />} size={20} color="disabled" />
                        </div>
                        <div id="suggestion-text-container">
                          <SubTitle
                            style={{
                              fontSize: 14,
                              maxWidth: "90%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            color="disabled"
                          >
                            {suggestion.main_text} - {suggestion.secondary_text}
                          </SubTitle>
                        </div>
                      </button>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>

        {searchOnHover && <SearchType key="search-type" />}
      </div>
    </div>
  );
}
