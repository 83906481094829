import { SubTitle } from "../../../../components/typography/SubTitle";
import { Box } from "./components/Box";

export function getMapaDeDescricoesParaHTML(): Map<
  string,
  { tituloAcentuado: string; descricao: JSX.Element; tabela: JSX.Element }
> {
  const descricaoDasSessoes = new Map<
    string,
    { tituloAcentuado: string; descricao: JSX.Element; tabela: JSX.Element }
  >();
  descricaoDasSessoes.set("Empresarial", {
    tituloAcentuado: "EMPRESARIAL",
    descricao: (
      <div>
        {" "}
        As variáveis empresariais utilizam dados da receita federal e outras
        bases de dados púbicas e privadas, parceiras da Deepen, os dados são
        atualizados periodicamente.
      </div>
    ),
    tabela: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "30px",
        }}
      >
        <SubTitle style={{ color: "#459CA0" }}>Empresas</SubTitle>
        <SubTitle style={{ color: "#459CA0" }}>
          Colaboradores por idade
        </SubTitle>
        <SubTitle style={{ color: "#459CA0" }}>Sexo</SubTitle>
        <SubTitle style={{ color: "#459CA0" }}>Renda</SubTitle>
      </div>
    ),
  });
  descricaoDasSessoes.set("Potencial de Consumo", {
    tituloAcentuado: "POTENCIAL DE CONSUMO",
    descricao: (
      <div>
        {" "}
        Dados baseados no consumo da população em segmentos variados a partir de
        bases públicas e privadas de dados.
        <br /> <br />
      </div>
    ),
    tabela: <div></div>,
  });
  descricaoDasSessoes.set("Outros", {
    tituloAcentuado: "OUTROS",
    descricao: (
      <div>
        {" "}
        Variáveis pesquisadas frequentemente no deepen, geradoras de fluxo de
        pessoas.
        <br /> <br />
      </div>
    ),
    tabela: <div></div>,
  });
  descricaoDasSessoes.set("Sociodemografia", {
    tituloAcentuado: "SOCIODEMOGRÁFICA",
    descricao: (
      <div>
        {" "}
        As variáveis sociodemográficos tem como fonte de dados o Censo
        demográfico brasileiro de 2010, realizado pelo IBGE, e foram atualizados
        pela Deepen para o ano de 2023.
      </div>
    ),
    tabela: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "30px",
        }}
      >
        <SubTitle style={{ color: "#459CA0" }}>Domícilios</SubTitle>
        <SubTitle style={{ color: "#459CA0" }}>Pessoas</SubTitle>
        <SubTitle style={{ color: "#459CA0" }}>
          homens e mulheres por idade
        </SubTitle>
        <SubTitle style={{ color: "#459CA0" }}>Renda</SubTitle>
        <SubTitle style={{ color: "#459CA0" }}>Quantidade</SubTitle>
      </div>
    ),
  });
  descricaoDasSessoes.set("Taxas", {
    tituloAcentuado: "TAXAS E ÍNDICES",
    descricao: (
      <div>
        {" "}
        Taxas e índice criadas pela Deepen a partir de dados do IBGE e outras
        fontes de dados. <br />
      </div>
    ),
    tabela: (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ display: "flex", flexDirection: "column", width: "33%" }}>
          <Box
            title={"Índice Gini"}
            text={
              "Consiste em um número entre 0 e 1 que indica o a igualdade entre as regiões do estudo, onde 0 corresponde à completa igualdade e 1 corresponde à completa desigualdade."
            }
          />
          <Box
            title={"Taxa de verticalização"}
            text={
              "Indica a quantidade de predios e edificios em relação ao total."
            }
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "33%" }}>
          <Box
            title={"Índice de fluxo de pessoas"}
            text={
              "Indica o quanto pessoas transitam em uma região em comparação a outra."
            }
          />
          <Box
            title={"Taxa de urbanização"}
            text={"Indica a propensão de urbanização na área."}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "33%" }}>
          <Box
            title={"Taxa de domícilios próprios quitados"}
            text={
              "Indica a quantidade de domícilios próprios quitados em relação ao total."
            }
          />
          <Box
            title={"Taxa de saúde"}
            text={"Indica a propensão de saúde na área."}
          />
        </div>
      </div>
    ),
  });

  descricaoDasSessoes.set("Alfinetes", {
    tituloAcentuado: "ALFINETES",
    descricao: (
      <div>
        {" "}
        Lista de alfinetes pertencentes aos estudos.
        <br /> <br />
      </div>
    ),
    tabela: <div></div>,
  });
  descricaoDasSessoes.set("CNPJ", {
    tituloAcentuado: "CNPJ",
    descricao: (
      <div>
        {" "}
        Número de CNPJs pertencentes a CNAEs específicos nos estudos
        <br /> <br />
      </div>
    ),
    tabela: <div></div>,
  });
  return descricaoDasSessoes;
}

export function getMapaDeDescricoesParaPDF(): Map<
  number,
  { tituloAcentuado: string; descricao: string | string[] }
> {
  const descricaoDasSessoes = new Map<
    number,
    { tituloAcentuado: string; descricao: string | string[] }
  >();
  descricaoDasSessoes.set(0, {
    tituloAcentuado: "Sociodemográfica",
    descricao:
      "As variáveis sociodemográficos tem como fonte de dados o Censo demográfico brasileiro de 2010, realizado pelo IBGE, e foram atualizados pela Deepen para o ano de 2023.\nDados disponíveis: domícilios, pessoas, homens e mulheres por idade, renda e quantidade.",
  });
  descricaoDasSessoes.set(1, {
    tituloAcentuado: "Empresarial",
    descricao:
      "As variáveis empresariais utilizam dados da receita federal e outras bases de dados púbicas e privadas, parceiras da Deepen, os dados são atualizados periodicamente.\nDados Disponíveis: Empresas, colaboradores por idade, sexo e renda.",
  });
  descricaoDasSessoes.set(2, {
    tituloAcentuado: "Outros",
    descricao:
      "Variáveis pesquisadas frequentemente no deepen, geradoras de fluxo de pessoas.",
  });

  descricaoDasSessoes.set(3, {
    tituloAcentuado: "Potencial de Consumo",
    descricao:
      "Dados baseados no consumo da população em segmentos variados a partir de bases públicas e privadas de dados.",
  });

  descricaoDasSessoes.set(4, {
    tituloAcentuado: "Taxas",
    descricao: [
      "Índice Gini: consiste em um número entre 0 e 1 que indica o a igualdade entre as regiões do estudo, onde 0 corresponde à completa igualdade e 1 corresponde à completa desigualdade.",
      "Índice de fluxo de pessoas: indica o quanto pessoas transitam em uma regiãoem comparação a outra.",
      "Taxa de domícilios próprios quitados: indica a quantidade de domícilios próprios quitados em relação ao total.",
      "Taxa de verticalização: indica a quantidade de predios e edificios em relação ao total.",
      "Taxa de urbanização: indica a propensão de urbanização na área.",
      "Taxa de saúde: indica a propensão de saúde na área.",
    ],
  });
  descricaoDasSessoes.set(5, {
    tituloAcentuado: "Alfinetes",
    descricao: "Lista de alfinetes pertencentes aos estudos",
  });
  descricaoDasSessoes.set(6, {
    tituloAcentuado: "CNPJ",
    descricao: "Número de CNPJs pertencentes a CNAEs específicos nos estudos",
  });
  return descricaoDasSessoes;
}
