export const portes = [
  "Não informado",
  "Micro empresa",
  "Pequeno porte",
  "Mei",
  "Demais",
];
export function translatePortArray(rawPortArray:[]) {
  const translated = [];
  for (let index = 0; index < rawPortArray.length; index++) {
    const element = rawPortArray[index];
    for (let indexS = 0; indexS < portes.length; indexS++) {
      if (portes[indexS] === element) {
        translated.push(indexS);
      }
    }
  }
  return translated;
}
export function translatePortToStr(portIndex:number) {
  if(portIndex<portes.length)
  return portes[portIndex];
  return portes[4];
}
