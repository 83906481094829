import { httpConfig } from "./httpConfig";
import { httpMethod } from "./httpMethod";

async function deepWebLoginService(params, callback) {
  var url = httpConfig.backendUrl + "/login";

  await fetch(url, {
    method: httpMethod.post,
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response;
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}
async function deepWebPasswordRequest(params, callback) {
  var url = httpConfig.backendUrl + "/reset-password";

  await fetch(url, {
    method: httpMethod.post,
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response;
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

export { deepWebLoginService, deepWebPasswordRequest };
