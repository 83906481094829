import L, { LatLngTuple } from "leaflet";
import GA4 from "react-ga4";
import { PolygonParamList } from "../../../../../Types/interfacs";
import { PolygonProps } from "../types";
import * as turf from "@turf/turf";

interface EditPolygonProps {
  map: L.Map;
  layerShapes: L.LayerGroup;
  outlineRetangle?: L.Rectangle;
  polygon: PolygonProps;
  editMarker?: L.Marker;
  deleteMarker?: L.Marker;
  setPolygon?: React.Dispatch<React.SetStateAction<PolygonParamList>>;
}

export function EditPolygon({
  map,
  layerShapes,
  polygon,
  editMarker,
  deleteMarker,
  outlineRetangle,
}: EditPolygonProps) {
  let indexPoint: number;
  const points = polygon.getLatLngs()[0];
  let polygonPoints: L.LatLngTuple[] = [];
  const { lat, lng } = polygon.getBounds().getCenter();

  let center: L.LatLngTuple = [lat, lng];
  let toolTip: L.Tooltip;
  let area: number;

  Object.values(points).forEach((point) => {
    polygonPoints.push([point.lat, point.lng]);
  });

  const icon = L.icon({
    iconUrl: "edit-Icon.png",
    iconSize: [20, 20],
  });

  const centerMarker = L.marker(center, {
    icon,
  });

  let markers: L.Marker[] = [];
  let intermediarymarkers: L.Marker[] = [];

  function addGuides() {
    polygonPoints.forEach((point, index) => {
      const marker = L.marker(point, {
        icon,
      });
      marker.setZIndexOffset(1000);
      marker.addTo(layerShapes);
      markers.push(marker);
      marker.on("mousedown", (e: L.LeafletMouseEvent) => {
        handleClickOnPoint(e);
        indexPoint = index;
      });
    });

    map.on("mouseup", handleClickUp);
  }

  function addIntermediaryGuides() {
    for (let i = 0; i < polygonPoints.length; i++) {
      let j = i + 1;
      if (i === polygonPoints.length - 1) {
        j = 0;
      }
      const lat = (polygonPoints[i][0] + polygonPoints[j][0]) / 2;
      const lng = (polygonPoints[i][1] + polygonPoints[j][1]) / 2;
      const marker = L.marker([lat, lng], {
        icon,
      });
      marker.setOpacity(0.5);
      marker.setZIndexOffset(1000);
      marker.addTo(layerShapes);
      intermediarymarkers.push(marker);
      // eslint-disable-next-line no-loop-func
      marker.on("mousedown", () => {
        map.dragging.disable();
        const index = intermediarymarkers.indexOf(marker);
        for (let i = polygonPoints.length; i > index; i--) {
          polygonPoints[i] = polygonPoints[i - 1];
        }

        indexPoint = index + 1;
        polygonPoints[indexPoint] = [lat, lng];
        polygon.setLatLngs([polygonPoints]);
        removeGuides();

        map.on("mousemove", handleMouseMove);
        if (editMarker || deleteMarker) {
          removeOnMoveOrResize();
        }
      });
    }
  }

  addGuides();
  centerMarker.addTo(layerShapes);

  centerMarker.on("mousedown", (e: L.LeafletMouseEvent) => {
    handleClickOnCenter(e);
  });

  addIntermediaryGuides();

  function removeGuides() {
    markers.forEach((marker) => {
      layerShapes.removeLayer(marker);
    });

    layerShapes.removeLayer(centerMarker);
    markers = [];
  }

  function removeIntermediaryGuides() {
    intermediarymarkers.forEach((marker) => {
      layerShapes.removeLayer(marker);
    });
    intermediarymarkers = [];
  }

  outlineRetangle?.on("remove", () => {
    map.off("mouseup", handleClickUp);
    removeGuides();
    removeIntermediaryGuides();
  });

  editMarker?.on("click", () => {
    removeGuides();
    removeIntermediaryGuides();
  });

  deleteMarker?.on("click", () => {
    removeGuides();
    removeIntermediaryGuides();
    outlineRetangle?.remove();
  });

  function removeOnMoveOrResize() {
    editMarker?.remove();
    deleteMarker?.remove();
    outlineRetangle?.remove();
    centerMarker.remove();
    removeIntermediaryGuides();

    addGuides();
  }

  function addOnMoveOrResizeFinish() {
    const southWest = polygon.getBounds().getSouthWest();
    const northEast = polygon.getBounds().getNorthEast();

    const firstLatLng = [southWest.lat - 0.0005, southWest.lng - 0.0005] as [
      number,
      number
    ];
    const secondLatLng = [northEast.lat + 0.0005, northEast.lng + 0.0005] as [
      number,
      number
    ];

    outlineRetangle?.setBounds([firstLatLng, secondLatLng]);

    const northEastOutiline = outlineRetangle?.getBounds().getNorthEast();

    const { lat, lng } = polygon.getBounds().getCenter();
    center = [lat, lng];

    centerMarker.setLatLng(center);

    centerMarker.addTo(layerShapes);

    centerMarker.setZIndexOffset(1000);

    centerMarker.on("mousedown", (e: L.LeafletMouseEvent) => {
      handleClickOnCenter(e);
    });

    editMarker?.setLatLng(northEastOutiline ?? northEast);
    deleteMarker?.setLatLng(northEastOutiline ?? northEast);
    editMarker?.addTo(layerShapes);
    deleteMarker?.addTo(layerShapes);
    outlineRetangle?.addTo(layerShapes);
    addIntermediaryGuides();
  }

  function handleClickOnPoint(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      map.dragging.disable();
      map.on("mousemove", handleMouseMove);
      if (editMarker || deleteMarker) {
        removeOnMoveOrResize();
      }
    }
  }

  function handleClickOnCenter(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      map.dragging.disable();
      map.on("mousemove", handleMouseMoveCenter);
      if (editMarker || deleteMarker) {
        removeOnMoveOrResize();
        centerMarker.addTo(layerShapes);
      }
    }
  }

  function handleMouseMoveCenter(e: L.LeafletMouseEvent) {
    const newCenterLatLng = [e.latlng.lat, e.latlng.lng] as L.LatLngTuple;

    const latDiff = newCenterLatLng[0] - center[0];
    const lngDiff = newCenterLatLng[1] - center[1];

    center = newCenterLatLng as L.LatLngTuple;

    polygonPoints.forEach((point) => {
      point[0] += latDiff;
      point[1] += lngDiff;
    });

    polygon.setLatLngs([polygonPoints]);

    markers.forEach((marker) => {
      const latLng = marker.getLatLng();
      marker.setLatLng([latLng.lat + latDiff, latLng.lng + lngDiff]);
    });

    centerMarker.setLatLng(center);
  }

  function handleMouseMove(e: L.LeafletMouseEvent) {
    const latLng = [e.latlng.lat, e.latlng.lng] as L.LatLngTuple;
    const marker = markers[indexPoint];

    const auxPoints = [...polygonPoints];

    polygonPoints[indexPoint] = latLng;
    polygon.setLatLngs([polygonPoints]);

    area = turf.area(polygon.toGeoJSON()) / 1000000;
    area = Math.trunc(area);

    if (area > 31000) {
      polygonPoints = auxPoints;
      polygon.setLatLngs([polygonPoints]);

      addTooltip(latLng, area);
      return;
    }

    marker.setLatLng(latLng);

    addTooltip(latLng, area);
  }

  function handleClickUp(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      removeIntermediaryGuides();
      addOnMoveOrResizeFinish();
      if (toolTip) {
        toolTip.remove();
      }
      map.dragging.enable();
      map.getContainer().focus();

      map.off("mousemove", handleMouseMove);
      map.off("mousemove", handleMouseMoveCenter);
    }
  }

  function addTooltip(latLng: LatLngTuple, area: number) {
    if (polygonPoints.length > 2) {
      if (toolTip) {
        toolTip.remove();
      }
      toolTip = L.tooltip()
        .setLatLng(latLng)
        .setContent(
          `<div
        style="
        background-color: white;
        color: ${area > 31000 ? "red" : "black"};
          padding: 5px;
          font-size: 14px;
          font-weight: bold;
        "
        >${area > 31000 ? "Max 31000" : area}
        km²</div>`
        )
        .addTo(map);
    }
  }
}
