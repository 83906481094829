//http://app.deepen.com.br:8083/cnpjQuadTree/getPins
//POST
//require authorization token
//
//x1: -61.65527343749999
//x2: -38.60595703125
//y1: -16.066928957450106
//y2: -32.491230
//level: 6
//filter:{ model:{
//capitalSocial: []
//cidade: ["","",...]
//cnae: []
//estado: ["","",...]
//inicioDeAtividade: []
//porte: []
//}}
//
import { httpConfig } from "./httpConfig";
import { httpMethod } from "./httpMethod";
import { UserToken } from "../services/userToken";
import { addDefaultParams } from "./getOptionsData";

async function getQuadPins(params, callback) {
  var url = httpConfig.cnpjUrl + "/cnpjQuadTree/getPins";

  if (
    params.filter.model.cnae !== undefined &&
    params.filter.model.cnae !== null
  ) {
    //filtra o label das cnaes para não mandar ele para requisição.
    const novarray = [];
    for (var c = 0; c < params.filter.model.cnae.length; c++) {
      if (params.filter.model.cnae[c].indexOf(";") > -1) {
        novarray.push(
          params.filter.model.cnae[c].substring(
            0,
            params.filter.model.cnae[c].indexOf(";")
          )
        );
      }
    }
    params.filter.model.cnae = novarray;
  }

  const requestParams = JSON.parse(JSON.stringify(params));

  addDefaultParams(requestParams.filter.model);

  await fetch(url, {
    method: httpMethod.post,
    body: JSON.stringify(requestParams),
    headers: {
      "Content-Type": "application/json",
      Authorization: UserToken.token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
}

export default getQuadPins;
