import React, { ReactNode, CSSProperties } from "react";
import { Button, Tooltip } from "antd";
import { colors } from "../../assets/colors";
import fonts from "../../config/fonts";
import { ReactComponent as Loader } from "../../assets/icons/spinner.svg";
import "./Button.css";
type ButtonSize =
  | "small"
  | "smallTwo"
  | "smallThree"
  | "medium"
  | "default"
  | "large"
  | "superLarge";

interface ButtonDefaultProps {
  disabled?: boolean;
  title?: string | ReactNode;
  icon?: ReactNode;
  noshadow?: boolean;
  shadow?: boolean;
  iconPosition?: "left" | "right";
  color?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "disabled"
    | "success"
    | "deepblue"
    | "deepblueInverted"
    | "lightblue"
    | "green"
    | "gray"
    | "orange"
    | "yellow";
  transparent?: boolean;
  border?: boolean;
  borderRadius?: number;
  onClick?: () => void;
  size?: ButtonSize;
  style?: CSSProperties;
  fontSecondary?: boolean;
  tooltip?: string;
  loading?: boolean;
}

export function ButtonDefault(props: ButtonDefaultProps): JSX.Element {
  const {
    title,
    icon,
    shadow = false,
    noshadow = false,
    iconPosition = "left",
    color = "disabled",
    transparent,
    border = false,
    borderRadius = 5,
    onClick,
    size = "default",
    style,
    disabled,
    fontSecondary,
    tooltip = "",
    loading,
  } = props;

  const colorButton = transparent ? "transparent" : "default";
  const shadowColor = colors.shadow;
  const contentColor: string = colors[colorButton][color].content;
  const backgroundColor: string = colors[colorButton][color].background;

  const buttonSizeMap = {
    small: 24,
    smallTwo: 32,
    smallThree: 36,
    medium: 44,
    default: 56,
    large: 66,
    superLarge: 126,
  };

  const buttonSize = buttonSizeMap[size];

  return (
    <Tooltip title={tooltip} mouseLeaveDelay={0}>
      <Button
        style={{
          boxShadow: noshadow
            ? "none"
            : (!title && !transparent) || shadow
            ? `0 0 10px ${shadowColor}`
            : "none",
          backgroundColor: loading ? "#e7e8e9" : backgroundColor,
          width: title ? undefined : buttonSize,
          height: buttonSize,
          padding: 5,
          paddingTop: 6,
          paddingRight: title ? undefined : 5,
          paddingLeft: title ? undefined : 5,
          marginRight: !transparent ? 5 : 0,
          marginLeft: !transparent ? 5 : 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: border ? `1px solid ${contentColor}` : "none",
          borderRadius: borderRadius,
          fill: contentColor,
          color: loading ? "#9fa3a9" : contentColor,

          cursor: disabled || loading ? "not-allowed" : "pointer",

          fontWeight: "bold",
          ...style,
        }}
        icon={
          loading
            ? null
            : icon &&
              iconPosition === "left" &&
              React.cloneElement(icon as React.ReactElement, {
                size: buttonSize !== 126 ? buttonSize / 2.5 : 56 / 2.5,
              })
        }
        onClick={disabled || loading ? undefined : onClick}
      >
        {loading ? (
          <Loader className="spinner" />
        ) : (
          title && (
            <span
              style={{
                paddingTop: 2,
                marginRight: iconPosition === "right" ? 15 : undefined,
                fontSize: fonts.sizes.regular,
                fontFamily: fontSecondary ? fonts.exp : fonts.regular,
              }}
            >
              {title}
            </span>
          )
        )}
        {icon &&
          iconPosition === "right" &&
          React.cloneElement(icon as React.ReactElement, {
            size: buttonSize !== 126 ? buttonSize / 2.5 : 56 / 2.5,
          })}
      </Button>
    </Tooltip>
  );
}
