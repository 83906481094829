import {
  CSSProperties,
  ChangeEvent,
  HTMLInputTypeAttribute,
  useState,
} from "react";
import { colors } from "../../assets/colors";
import fonts from "../../config/fonts";
import { SubTitle } from "../typography/SubTitle";
import { FaCircleExclamation } from "react-icons/fa6";
import { ButtonDefault } from "../Button/ButtonDefault";
import { TitleModal } from "../typography/TitleModal";
import { ModalDefault } from "../Modal/ModalDefault";

interface CustomInputProps {
  limitNumber?: number;
  autocomplete?: string;
  value: string | number;
  editable?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onEnter?: (value: string | number) => void;
  placeholder: string;
  prefix?: string;
  type: HTMLInputTypeAttribute;
  style?: CSSProperties;
}

const grayColor = colors.default.disabled.content;

export function CustomInput({
  limitNumber,
  autocomplete = "on",
  value,
  editable = true,
  onChange,
  onEnter,
  placeholder,
  prefix,
  type,
  style,
}: CustomInputProps) {
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [modalNoticeOpen, setModalNoticeOpen] = useState(false);

  const handleChange = (e: any) => {
    const { value: inputValue } = e.target;
    // Regular expression to match only non-negative integer or decimal numbers
    const regex = /^[0-9]+(\.[0-9]*)?$/;

    if (regex.test(inputValue) || inputValue === "") {
      if (limitNumber !== undefined) {
        if (inputValue < limitNumber) {
          setLimitExceeded(false);
          onChange(e);
        } else {
          setLimitExceeded(true);
          onChange({ target: { value: limitNumber } } as any);
        }
      } else {
        onChange(e);
      }
    }
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: 48,
          borderRadius: 5,
          overflow: "hidden",
          border: `1px solid ${grayColor}`,
          ...style,
        }}
      >
        {prefix && value && (
          <div
            style={{
              width: "fit-content",
              paddingLeft: 12,
            }}
          >
            {prefix}
          </div>
        )}
        {limitExceeded && value && (
          <>
            <ButtonDefault
              icon={<FaCircleExclamation />}
              style={{
                width: "fit-content",
                marginLeft: 12,
              }}
              color={"orange"}
              borderRadius={25}
              size="smallTwo"
              title="Máx "
              onClick={() => {
                setModalNoticeOpen(true);
              }}
            />
          </>
        )}
        <input
          style={{
            flex: 1,
            width: "100%",
            height: 48,
            fontSize: fonts.sizes.regular,
            fontWeight: "normal",
            padding: 8,
            paddingLeft: 12,
            border: "none",
            outline: "none",
          }}
          disabled={!editable}
          autoFocus={false}
          autoComplete={autocomplete}
          value={value}
          onChange={type === "number" ? handleChange : onChange}
          onKeyDown={(key) => {
            if (key.code === "Enter" && onEnter) {
              onEnter(value);
            }
          }}
          name="custom-input"
          type={type}
          placeholder={placeholder}
        />
      </div>
      {modalNoticeOpen && (
        <ModalDefault
          icon={<FaCircleExclamation />}
          setModalOpen={setModalNoticeOpen}
          title="Limite de estudos"
          styleContent={{
            maxWidth: 500,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TitleModal>LIMITE DE ESTUDOS</TitleModal>
            <SubTitle
              style={{
                textAlign: "center",
                marginBlock: 14,
              }}
            >
              Você possui um limite de estudos, delete alguns para adicionar
              novos ou entre em contato com o administrador.
            </SubTitle>
          </div>
        </ModalDefault>
      )}
    </>
  );
}
