/* eslint-disable no-loop-func */
import { latLng, layerGroup, marker } from "leaflet";
import { colors } from "../../../assets/colors";
import L from "leaflet";
import { useEffect, useState } from "react";
import { useMapEvents } from "react-leaflet";
import { getNextColor } from "../../../assets/colorService";
import {
  getBestAreasAnalyze,
  getRadiusExclusivity,
  getSurroundings,
  getValidLimitStudies,
} from "../../../services/getOptionsData";
import { MapData } from "../../../services/mapData";
import getQuadPins from "../../../services/pinsbyfilter";
import { MarkersInterestPoints } from "./MarkersLayer/MarkersInterestPoints";
import { ButtonDefault } from "../../../components/Button/ButtonDefault";
import { HiXMark } from "react-icons/hi2";
import { FaArrowRight } from "react-icons/fa";
import {
  CreateRectangle,
  drawRectangle,
} from "./utils/Creation/CreateRectangle";
import {
  addCircleToStudy,
  CreateCircle,
  drawCircle,
} from "./utils/Creation/CreateCircle";
import { CreateLine } from "./utils/Creation/CreateLine";
import { CreatePolygon, drawPolygon } from "./utils/Creation/CreatePolygon";
import { CreateBestArea } from "./utils/Creation/CreateBestArea";
import { CreateSurroundings } from "./utils/Creation/CreateSurroundings";
import { CreateRadiusExclusivity } from "./utils/Creation/CreateRadiusExclusivity";
import { getDistanceBetweenPoints } from "./utils/getDistanceBetweenPoints";
import { Loading } from "../../../components/Loading/Loading";
import { translatePortArray } from "../../../data/dataFilters/portes";
import { translateCSArray } from "../../../data/dataFilters/capitais";
import { CreatePinToRadiusExclusivity } from "./utils/Creation/CreatePinToRadiusExclusivity";
import { CreatePinToSurroundings } from "./utils/Creation/CreatePinToSurroundings";
import { filterOutbounds } from "../../../utils/checkpoly";
import { ControllerShapes } from "./utils/ControllerShapes";
import { EditPin } from "./utils/Edition/EditPin";
import { CreateHeatmap } from "./utils/Creation/CreateHeatmap";

var markersfilterLayer = layerGroup();
var pin = null;
var pinsLayer = layerGroup();
var geoJsonLayer = layerGroup();
var layerGroupHeatMaps = L.layerGroup();
const colorDefault = colors.default.primary.content;
let cachedPins = [];

const clickfun = (e) => {
  console.log(
    "You clicked the map at LAT: " + e.latlng.lat + " and LONG: " + e.latlng.lng
  );
};

function GrabMapData(props) {
  const map = useMapEvents({
    click: clickfun,
    moveend: moveEnd,
    zoomlevelschange: moveEnd,
  });

  //desativar o dbclick para não dar zoom
  useEffect(() => {
    map.off("dblclick");
  }, []);

  var showFilterPins = true;

  const {
    markers,
    pinsOnEdition,
    setPinsOnEdition,
    markersGoogle,
    setMarkersGoogle,
    setMarkers,
    pan,
    layerShapes,
    colorIndex,
    setColorIndex,
    newSearch,
    shapeActive,
    setShapeActive,
    buttonToConfirmSurroundings,
    setButtonToConfirmSurroundings,
    buttonToConfirmBestAreaVariables,
    setButtonToConfirmBestAreaVariables,
    polygon,
    setPolygon,
    selectedFilter,
    selectedArea,
    setSelectedArea,
    paramsToBestAreaVariables,
    paramsToRadiusExclusivity,
    setParamsToBestAreaVariables,
    setParamsToRadiusExclusivity,
    setParamsToSurroundings,
    paramsToSurroundings,
    shapeSelectedToEdition,
    setShapeSelectedToEdition,
    setModalNoticeOpen,
  } = props;

  function findAreaInPoly(areas, polyList) {
    const resultados = [];

    for (const chave in polyList) {
      if (Array.isArray(polyList[chave])) {
        const arrayA = polyList[chave];
        for (const objetoB of areas) {
          const idProcurado = objetoB.id;
          const resultadoA = arrayA.find(
            (elementoA) => elementoA.id === idProcurado
          );
          if (resultadoA) {
            addAreaToList(resultadoA, resultados);
          }
        }
      }
    }

    return resultados;
  }

  useEffect(() => {
    cachedPins = [];
    if (!pinsOnEdition) {
      pinsLayer.eachLayer((layer) => {
        if (
          layer.options.icon.options.iconUrl === "delete.png" ||
          layer.options.icon.options.iconUrl === "delete-active.png"
        ) {
          pinsLayer.removeLayer(layer);
        }
      });
      return;
    }
    if (pinsOnEdition === "radiusExclusivity") {
      if (paramsToRadiusExclusivity.points.length > 0) {
        paramsToRadiusExclusivity.points.forEach((point) => {
          const latLon = [point.lat, point.lon];
          const pointId = point.id;
          const pin = map._layers[pointId];

          const deleteIcon = L.icon({
            iconUrl: "delete.png",
            iconSize: [35, 35],
            iconAnchor: [0, 80],
          });

          const deleteActiveIcon = L.icon({
            iconUrl: "delete-active.png",
            iconSize: [35, 35],
            iconAnchor: [0, 80],
          });

          const deleteMarker = L.marker(latLon, {
            icon: deleteIcon,
          });

          deleteMarker.setOpacity(0.7);

          deleteMarker.on("mouseover", () => {
            deleteMarker.setOpacity(1);
          });

          deleteMarker.on("mouseout", () => {
            deleteMarker.setOpacity(0.7);
          });

          deleteMarker.on("mousedown", () => {
            deleteMarker.setIcon(deleteActiveIcon);
          });

          deleteMarker.on("mouseup", () => {
            deleteMarker.setIcon(deleteIcon);
          });

          deleteMarker.on("click", () => {
            pinsLayer.removeLayer(pin);
            pinsLayer.removeLayer(deleteMarker);

            cachedPins.push({
              pin,
              point,
            });

            setParamsToRadiusExclusivity((prevParamsToRadiusExclusivity) => ({
              ...prevParamsToRadiusExclusivity,
              points: prevParamsToRadiusExclusivity.points.filter(
                (item) => item.id !== pointId
              ),
            }));
          });

          deleteMarker.on("remove", () => {
            deleteMarker.off("mouseover");
            deleteMarker.off("mouseout");
            deleteMarker.off("mousedown");
            deleteMarker.off("mouseup");
            deleteMarker.off("click");
          });

          EditPin({
            pinsLayer,
            pin,
            deleteMarker,
          });
        });
      }
    } else if (pinsOnEdition === "surroundings") {
    } else if (pinsOnEdition === "redraw") {
      pinsLayer.eachLayer((layer) => {
        if (
          layer.options.icon.options.iconUrl === "delete.png" ||
          layer.options.icon.options.iconUrl === "delete-active.png"
        ) {
          pinsLayer.removeLayer(layer);
        }
      });

      cachedPins.forEach(({ pin, point }) => {
        pinsLayer.addLayer(pin);

        setParamsToRadiusExclusivity((prevParamsToRadiusExclusivity) => ({
          ...prevParamsToRadiusExclusivity,
          points: [...prevParamsToRadiusExclusivity.points, point],
        }));
      });
      setPinsOnEdition(null);
    }
  }, [pinsOnEdition]);

  useEffect(() => {
    if (selectedArea.length > 0) {
      let allShapes = findAreaInPoly(selectedArea, polygon);

      centerMap(allShapes);
    }
  }, [selectedArea, polygon, map]);

  polygon.centerAreas = (areas) => {
    centerMap(findAreaInPoly(areas, polygon));
  };

  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(0);

  useEffect(() => {
    const numberStudies =
      polygon.polygonList.length +
      polygon.rectangleList.length +
      polygon.radiusList.length;

    getValidLimitStudies({
      used: numberStudies + 1,
    }).then((response) => {
      if (!response) return;
      setLimit(response.limit - numberStudies);
    });
  }, [polygon, paramsToRadiusExclusivity.points]);

  //var cargas = 0;
  var pendingAreas = [];
  var pendingMarkers = [];

  polygon.deletfunction = (val) => {
    shapeSelectedToEdition && setShapeSelectedToEdition(null);
    layerShapes.removeLayer(val);
    for (let index = 0; index < polygon.pins.length; index++) {
      const pingroup = polygon.pins[index];
      if (pingroup.id === val) {
        polygon.pins.splice(index, 1);
        index--;
      }
    }
    setMarkersGoogle((prevMarkersGoogle) => {
      const filteredMarkers = Object.keys(prevMarkersGoogle).reduce(
        (result, key) => {
          const filteredItems = prevMarkersGoogle[key].filter(
            (item) => item.index !== val
          );
          result[key] = filteredItems;
          return result;
        },
        {}
      );
      return filteredMarkers;
    });
  };
  polygon.forceUpdate = () => {
    setMarkers([]);
    updateMarkers();
  };

  polygon.deleteAllfunction = () => {
    layerShapes.clearLayers();
    layerGroupHeatMaps.clearLayers();
    pinsLayer.clearLayers();

    map.getPane("popupPane").innerHTML = ""; // apaga todos os popups

    setMarkersGoogle({});
    setMarkers([]);
    updateMarkers();
  };

  const handleRemoveEventlistener = () => {
    const evt = { originalEvent: { code: "Enter" } };
    map.fire("keypress", evt);

    map.off("mousedown");
    map.off("mouseup");
    map.off("mousemove");
    map.off("keypress");

    map.getContainer().style.cursor = "grab";
  };

  let customPin = {
    iconUrl: "pinMarker.png",
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  };
  let IconPin = L.icon(customPin);

  function calculateBounds(polygons) {
    const resultados = [];

    const allpoly = [
      ...polygons.polygonList,
      ...polygons.rectangleList,
      ...polygons.radiusList,
    ];

    for (let index = 0; index < allpoly.length; index++) {
      const element = allpoly[index];
      addAreaToList(element, resultados);
    }
    return resultados;
  }

  function addAreaToList(area, list) {
    if (area.type === "RADIUS_STUDY") {
      var circleBounds = L.latLngBounds(
        [
          area.points[0][0] - area.ray / 100,
          area.points[0][1] - area.ray / 100,
        ],
        [area.points[0][0] + area.ray / 100, area.points[0][1] + area.ray / 100]
      );

      list.push(circleBounds);
    } else if (area.type === "RECTANGLE_STUDY") {
      const rectangle = L.rectangle([area.points[0], area.points[1]], {
        color: area.color,
      });
      list.push(rectangle?.getBounds());
    } else if (area.type === "POLYGON_STUDY") {
      const polygonVertices = area.points.map((point) =>
        L.latLng(point[0], point[1])
      );
      const polygon = L.polygon(polygonVertices, {
        color: area.color,
      });
      list.push(polygon?.getBounds());
    }
  }

  function centerMap(allShapes) {
    if (allShapes.length > 0) {
      let totalBounds = L.latLngBounds();
      for (var i = 0; i < allShapes.length; i++) {
        totalBounds.extend(allShapes[i]);
      }
      if (totalBounds) {
        map?.fitBounds(totalBounds);
      }
    }
  }

  useEffect(() => {
    if (newSearch) {
      map.setView(newSearch.latLng, 14);
      if (newSearch.typePin) {
        if (pin) {
          pin.setLatLng(newSearch.latLng);
        } else {
          pin = L.marker(newSearch.latLng, {
            icon: IconPin,
            alt: "pinSearch",
          });
          pin.addTo(pinsLayer);
        }
      } else {
        const color = getNextColor(colorIndex);
        const radius = newSearch.radius;
        const centerLatLng = newSearch.latLng;

        const newCircle = drawCircle(centerLatLng, radius * 1000, color);
        newCircle.addTo(layerShapes);

        addCircleToStudy(newCircle, setPolygon);
      }
    }
  }, [newSearch]);

  useEffect(() => {
    if (
      shapeActive !== "pinsToSurroundings" &&
      shapeActive !== "pinsToRadiusExclusivity"
    ) {
      if (shapeActive === "arrow") map.dragging.enable();
      else {
        setShapeSelectedToEdition(null);
        let color = colorDefault;

        if (
          shapeActive !== "bestArea" &&
          shapeActive !== "surroundings" &&
          shapeActive !== "radiusExclusivity" &&
          shapeActive !== "measure"
        ) {
          color = getNextColor(colorIndex);
        }

        if (shapeActive === "rectangle") {
          CreateRectangle({
            map,
            layerShapes,
            color,
            setPolygon,
            setShapeActive,
            setShapeSelectedToEdition,
          });
        } else if (shapeActive === "circle") {
          CreateCircle({
            map,
            layerShapes,
            color,
            setPolygon,
            setShapeActive,
            setShapeSelectedToEdition,
          });
        } else if (shapeActive === "measure") {
          CreateLine({
            map,
            layerShapes,
            color,
            setPolygon,
            setShapeActive,
            setShapeSelectedToEdition,
          });
        } else if (shapeActive === "polygon") {
          CreatePolygon({
            map,
            layerShapes,
            color,
            setPolygon,
            setShapeActive,
            setShapeSelectedToEdition,
          });
        } else if (shapeActive === "bestArea") {
          CreateBestArea({
            map,
            layerShapes,
            color,
            setPolygon,
            setShapeActive,
            setParamsToBestAreaVariables,
            setButtonToConfirmBestAreaVariables,
          });
        } else if (shapeActive === "surroundings") {
          CreateSurroundings({
            map,
            layerShapes,
            setShapeActive,
            setPolygon,
            color,
            setParamsToSurroundings,
            setButtonToConfirmSurroundings,
          });
        } else if (shapeActive === "radiusExclusivity") {
          CreateRadiusExclusivity({
            map,
            layerShapes,
            setShapeActive,
            setPolygon,
            color,
            setParamsToRadiusExclusivity,
          });
        }
      }
    } else {
      if (shapeActive === "pinsToRadiusExclusivity") {
        let limitPins = undefined;
        if (paramsToRadiusExclusivity.studyAreas) {
          limitPins = limit;
        }

        const contLimitActual = paramsToRadiusExclusivity.points.length;

        CreatePinToRadiusExclusivity(
          map,
          setParamsToRadiusExclusivity,
          pinsLayer,
          contLimitActual,
          limitPins,
          setModalNoticeOpen
        );
      } else {
        CreatePinToSurroundings(
          map,
          paramsToSurroundings,
          setParamsToSurroundings,
          pinsLayer
        );
      }
    }

    return () => {
      map.dragging.enable();
      handleRemoveEventlistener();
    };
  }, [shapeActive]);

  useEffect(() => {
    if (pan !== null) {
      try {
        var c1 = L.latLng(pan.y, pan.x);
        var c2 = L.latLng(pan.y + pan.h, pan.x + pan.w);
        var bounds = L.latLngBounds(c1, c2);
        map.fitBounds(bounds);
        moveEnd();
      } catch (error) {
        console.log(error);
      }
    }
  }, [pan]);

  //TODO: Refatorar PAN

  useEffect(() => {
    updateMarkers();
  }, [markers]);

  function updateMarkers() {
    var ic = null;
    map.removeLayer(markersfilterLayer);
    markersfilterLayer = layerGroup();
    const myMap = new Map();
    try {
      const capitais = [
        "Não informado",
        "1 - 60k",
        "60k - 360k",
        "360k - 1M",
        "1M - 5M",
        "5M - 10M",
        "10M - 30M",
        "30M - 100M",
        "100M - 300M",
        "300M - 500M",
        "500M - 1B",
        "1B >",
      ];
      const portes = [
        "Não informado",
        "Micro empresa",
        "Empresa de pequeno porte",
        "Mei",
        "Demais",
      ];
      markers.forEach((element) => {
        var collection = myMap.get(element.y + "," + element.x);
        if (collection === null || collection === undefined) {
          collection = [];
        }
        collection.push(element);
        myMap.set(element.y + "," + element.x, collection);
      });
      for (let [, value] of myMap) {
        const element = value[0];
        if (element.d.indexOf("@") === -1) {
          marker([element.y, element.x], {
            title: element.d,
            icon: IconMultComp,
          }).addTo(markersfilterLayer);
          L.tooltip({ offset: [0, 10], direction: "bottom", permanent: true })
            .setLatLng([element.y, element.x])
            .setContent(element.d)
            .addTo(markersfilterLayer);
        } else {
          if (value.length === 1) {
            const dados = element.d.substring(1, element.d.length);
            const valores = dados.split("#");
            if (valores[8] === "true") {
              ic = IconSingleCompDigital;
            } else {
              ic = IconSingleComp;
            }
            const m = marker(latLng(element.y, element.x), {
              title: valores[0],
              icon: ic,
              interactive: true,
            });
            m.bindPopup(
              "<div id='smallbox1'><div id='smalltitle'><b>" +
                valores[0] +
                "</b></div>" +
                "<div id='smalldetails'><hr>" +
                "<b>CNPJ</b>: " +
                valores[7] +
                "<br /><b>Cidade</b>: " +
                valores[2] +
                "<br /><b>Estado</b>: " +
                valores[4] +
                "<br /><b>Inicio de Atividade</b>: " +
                valores[5] +
                "<br /><b>Porte</b>: " +
                portes[valores[6]] +
                "<br /><b>Participante do Edital</b>: " +
                valores[8].replace("true", "Sim").replace("false", "Não") +
                "<br /><b>Capital Social</b>: R$ " +
                capitais[valores[1]] +
                "<br /></div></div>"
            );
            m.addEventListener("click", () => {
              m.openPopup(latLng(element.y, element.x));
            });
            m.addTo(markersfilterLayer);
          } else {
            var dados = element.d.substring(1, element.d.length);
            var valores = dados.split("#");
            var acho = false;
            // eslint-disable-next-line no-loop-func
            value.forEach((item) => {
              //faz varredura pra ver se tem empresa com edital na lista
              dados = item.d.substring(1, item.d.length);
              valores = dados.split("#");
              if (valores[8] === "true") {
                acho = true;
              }
            });
            if (acho) {
              ic = IconSingleCompDigital;
            } else {
              ic = IconSingleComp;
            }
            const m = marker(latLng(element.y, element.x), {
              title: valores[0],
              icon: ic,
              interactive: true,
            });

            var dialog = "";
            if (value.length > 1) {
              dialog = "<div id='smallbox2'>";
            } else {
              dialog = "<div id='smallbox1'>";
            }
            // eslint-disable-next-line no-loop-func
            value.forEach((item) => {
              dados = item.d.substring(1, item.d.length);
              valores = dados.split("#");
              dialog +=
                "<div id='smalltitle'><b>" +
                valores[0] +
                "</b></div>" +
                "<div id='smalldetails'><hr>" +
                "<b>CNPJ</b>: " +
                valores[7] +
                "<br /><b>Cidade</b>: " +
                valores[2] +
                "<br /><b>Estado</b>: " +
                valores[4] +
                "<br /><b>Inicio de Atividade</b>: " +
                valores[5] +
                "<br /><b>Porte</b>: " +
                portes[valores[6]] +
                "<br /><b>Participante do Edital</b>: " +
                valores[8].replace("true", "Sim").replace("false", "Não") +
                "<br /><b>Capital Social</b>: R$ " +
                capitais[valores[1]] +
                "<br /></div><hr>";
            });
            dialog += "</div>";
            m.bindPopup(dialog);
            m.addEventListener("click", () => {
              m.openPopup(latLng(element.y, element.x));
            });
            L.tooltip({ offset: [0, 10], direction: "bottom", permanent: true })
              .setLatLng([element.y, element.x])
              .setContent("" + value.length + "")
              .addTo(markersfilterLayer);
            m.addTo(markersfilterLayer);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    markersfilterLayer.addTo(map);
  }

  let customSingleComp = {
    iconUrl: "IconSingleComp2.png",
    iconSize: [40, 40],
  };
  let IconSingleComp = L.icon(customSingleComp);

  let customSingleCompDigital = {
    iconUrl: "IconSingleComp3.png",
    iconSize: [40, 40],
  };
  let IconSingleCompDigital = L.icon(customSingleCompDigital);

  let customMultComp = {
    iconUrl: "IconMultComp.png",
    iconSize: [40, 40],
  };
  let IconMultComp = L.icon(customMultComp);

  let customGooglePoint = {
    iconUrl: "ic-pin-google-off.png",
    iconSize: [40, 40],
  };
  let IconCustomGooglePoint = L.icon(customGooglePoint);

  useEffect(() => {
    const totalShapes =
      polygon.polygonList.length +
      polygon.radiusList.length +
      polygon.rectangleList.length;

    if (totalShapes === 0) {
      setColorIndex(0);
    } else {
      setColorIndex(totalShapes);
    }
  }, [polygon]);

  function searchBestAreaVariables() {
    let responseData;
    map.dragging.disable();

    const getBestAreasData = async () => {
      responseData = await getBestAreasAnalyze(
        paramsToBestAreaVariables,
        setLoading
      );

      responseData.forEach(
        ({ latitude, longitude, latitudeEnd, longitudeEnd }, index) => {
          const color = getNextColor(colorIndex + index);
          const radius = getDistanceBetweenPoints(
            [latitude, longitude],
            [latitudeEnd, longitudeEnd]
          );
          const centerLatLng = [latitude, longitude];

          const newCircle = drawCircle(centerLatLng, radius, color);
          newCircle.addTo(layerShapes);

          addCircleToStudy(newCircle, setPolygon);
        }
      );
      setColorIndex(colorIndex + responseData.length);
      map.dragging.enable();
    };
    getBestAreasData();
  }

  polygon.updatefunction = (polygon) => {
    updateShapes(polygon, map, layerShapes);
    centerMap(calculateBounds(polygon));
  };

  function updateShapes(polygon, map, layerShapes) {
    /*map.removeLayer(layerShapes);
    layerShapes = layerGroup();
    layerShapes.addTo(map);*/
    layerShapes.clearLayers();
    var geometricShape;
    var colorIndex = 0;
    for (let index = 0; index < polygon.rectangleList.length; index++) {
      const rect = polygon.rectangleList[index];
      const newcolor = getNextColor(colorIndex);
      geometricShape = drawRectangle(
        [rect.points[0][0], rect.points[0][1]],
        [rect.points[1][0], rect.points[1][1]],
        newcolor
      );
      if (geometricShape) {
        geometricShape.addTo(layerShapes);
        const idShape = geometricShape._leaflet_id;
        polygon.rectangleList[index].id = idShape;
        polygon.rectangleList[index].color = newcolor;
      }
      colorIndex++;
    }
    for (let index = 0; index < polygon.radiusList.length; index++) {
      const circle = polygon.radiusList[index];
      const newcolor = getNextColor(colorIndex);
      geometricShape = drawCircle(
        circle.points[0],
        circle.ray * 1000,
        newcolor
      );
      if (geometricShape) {
        geometricShape.addTo(layerShapes);
        const idShape = geometricShape._leaflet_id;
        polygon.radiusList[index].id = idShape;
        polygon.radiusList[index].color = newcolor;
      }
      colorIndex++;
    }
    colorIndex = polygon.rectangleList.length + polygon.radiusList.length;
    for (let index = 0; index < polygon.polygonList.length; index++) {
      const poly = polygon.polygonList[index];
      const newcolor = getNextColor(colorIndex);
      geometricShape = drawPolygon(
        poly.points.map((point) => ({
          lat: point[0],
          lon: point[1],
        })),
        newcolor
      );
      if (geometricShape) {
        geometricShape.addTo(layerShapes);
        const idShape = geometricShape._leaflet_id;
        polygon.polygonList[index].id = idShape;
        polygon.polygonList[index].color = newcolor;
      }
      colorIndex++;
    }
    setColorIndex(colorIndex);
    setPolygon(polygon);
  }

  function searchSurroundings() {
    let data;
    map.dragging.disable();
    const getSurroundingsData = async () => {
      data = await getSurroundings(paramsToSurroundings, setLoading);
      CreateHeatmap(map, data, layerGroupHeatMaps);
      data.radiusStudies.forEach(
        ({ latitude, longitude, latitudeEnd, longitudeEnd }, index) => {
          const color = getNextColor(colorIndex + index);
          const radius = getDistanceBetweenPoints(
            [latitude, longitude],
            [latitudeEnd, longitudeEnd]
          );
          const centerLatLng = [latitude, longitude];

          const newCircle = drawCircle(centerLatLng, radius, color);
          newCircle.addTo(layerShapes);

          addCircleToStudy(newCircle, setPolygon);
        }
      );
      setColorIndex(colorIndex + data.length);
      setParamsToSurroundings({
        ...paramsToSurroundings,
        pins: [],
      });
      map.dragging.enable();
    };
    getSurroundingsData();
  }

  useEffect(() => {
    if (paramsToRadiusExclusivity.searchAreas) {
      setParamsToRadiusExclusivity({
        ...paramsToRadiusExclusivity,
        rects: [],
        points: [],
        searchAreas: false,
      });
      searchRadiusExclusivity();
    }
  }, [paramsToRadiusExclusivity.searchAreas]);

  useEffect(() => {
    if (paramsToSurroundings.deletePins) {
      setParamsToSurroundings({
        ...paramsToSurroundings,
        pins: [],
        deletePins: false,
      });

      paramsToSurroundings.pins?.forEach((pin) => {
        const pinId = pin.id;

        const pinLayer = map._layers[pinId];

        pinsLayer.removeLayer(pinLayer);
      });
    }
  }, [paramsToSurroundings.deletePins]);

  useEffect(() => {
    if (paramsToRadiusExclusivity.deletePoints) {
      setParamsToRadiusExclusivity({
        ...paramsToRadiusExclusivity,
        points: [],
        deletePoints: false,
      });

      paramsToRadiusExclusivity.points.forEach((point) => {
        const pointId = point.id;

        const pointLayer = map._layers[pointId];

        pinsLayer.removeLayer(pointLayer);
      });
    }
  }, [paramsToRadiusExclusivity.deletePoints]);

  useEffect(() => {
    if (paramsToRadiusExclusivity.deleteRects) {
      setParamsToRadiusExclusivity({
        ...paramsToRadiusExclusivity,
        rects: [],
        deleteRects: false,
      });

      paramsToRadiusExclusivity.rects.forEach((rect) => {
        const rectId = rect.index;

        const rectLayer = map._layers[rectId];

        layerShapes.removeLayer(rectLayer);
      });

      removeButtonsMarker();
    }
  }, [paramsToRadiusExclusivity.deleteRects]);

  function searchRadiusExclusivity() {
    let data;

    map.dragging.disable();
    const getRadiusExclusivityData = async () => {
      // só para nao ter perigo de mandar os parametros a mais
      paramsToRadiusExclusivity.searchAreas = undefined;

      data = await getRadiusExclusivity(paramsToRadiusExclusivity, setLoading);
      const popups = [];
      removeButtonsMarker();

      data.resultPoint.forEach(({ lat, lon, value }, index) => {
        const popUp = L.popup({
          closeOnClick: false,
          autoClose: false,
          offset: [0, -30],
        })
          .setLatLng([lat, lon])
          .setContent(
            `<div
              style="
              background-color: white;
                padding: 5px;
                font-size: 14px;
                font-weight: bold;
              "
              >${value} km</div>`
          );

        if (paramsToRadiusExclusivity.studyAreas === true) {
          const color = getNextColor(colorIndex + index);
          const newCircle = drawCircle([lat, lon], value * 1000, color);
          newCircle.addTo(layerShapes);

          addCircleToStudy(newCircle, setPolygon);
        } else {
          const newCircle = drawCircle(
            [lat, lon],
            value * 1000,
            colorDefault,
            true
          );
          newCircle.addTo(layerShapes);
        }

        const pointId = paramsToRadiusExclusivity.points[index].id;

        const point = map._layers[pointId];

        point.on("click", function (e) {
          popUp.openOn(map);
        });

        popups.push(popUp);

        popUp.addTo(map);
      });

      if (paramsToRadiusExclusivity.studyAreas === true) {
        setColorIndex(colorIndex + data.resultPoint.length);
      }

      data.resultRect.forEach(({ lat, lon, value }, index) => {
        const popUp = L.popup({
          closeOnClick: false,
          autoClose: false,
          offset: [0, 10],
        })
          .setLatLng([lat, lon])
          .setContent(
            `<div
              style="
              background-color: white;
                padding: 5px;
                font-size: 14px;
                font-weight: bold;
              "
              >${value} km</div>`
          );

        const rectId = paramsToRadiusExclusivity.rects[index].index;

        const rect = map._layers[rectId];

        rect.on("click", function (e) {
          popUp.openOn(map);
        });

        popups.push(popUp);

        popUp.addTo(map);
      });
      map.dragging.enable();
    };
    getRadiusExclusivityData();
  }

  map.removeLayer(markersfilterLayer);
  markersfilterLayer.addTo(map);
  map.removeLayer(geoJsonLayer);
  geoJsonLayer.addTo(map);
  map.removeLayer(layerShapes);
  layerShapes?.addTo(map);
  map.removeLayer(pinsLayer);
  pinsLayer.addTo(map);
  map.removeLayer(layerGroupHeatMaps);
  layerGroupHeatMaps.addTo(map);

  MapData.width = map.getSize().x;
  MapData.height = map.getSize().y;

  function moveEnd() {
    MapData.center = map.getCenter();
    MapData.bounds = map.getBounds();
    MapData.zoom = map.getZoom();
    MapData.width = map.getSize().x;
    MapData.height = map.getSize().y;
    pendingMarkers = [];
    if (selectedArea.length > 0) {
      pendingAreas = [...selectedArea];
      markers.splice(0, markers.length);
      for (let index = 0; index < selectedArea.length; index++) {
        const element = selectedArea[index];
        updateArea(element);
      }
    } else {
      pendingAreas = [];
      markers.splice(0, markers.length);
      updateArea(null);
    }
  }

  function updateArea(area) {
    var selStudyType = null;
    var selPoints = null;
    if (area) {
      selStudyType = area.studyType;
      selPoints = area.points;
    }
    if (
      showFilterPins &&
      (selectedFilter.selectedCS.length > 0 ||
        selectedFilter.selectedCidades.length > 0 ||
        selectedFilter.selectedCNAE.length > 0 ||
        selectedFilter.selectedEstados.length > 0 ||
        selectedFilter.selectedPort.length > 0 ||
        selectedFilter.selectedIA.length > 0)
    ) {
      const params = {
        x2: MapData.bounds._northEast.lng,
        y1: MapData.bounds._northEast.lat,
        x1: MapData.bounds._southWest.lng,
        y2: MapData.bounds._southWest.lat,
        level: MapData.zoom,
        filter: {
          model: {
            capitalSocial: translateCSArray(selectedFilter.selectedCS),
            cidade: selectedFilter.selectedCidades,
            cnae: selectedFilter.selectedCNAE,
            estado: selectedFilter.selectedEstados,
            inicioDeAtividade: selectedFilter.selectedIA,
            porte: translatePortArray(selectedFilter.selectedPort),
          },
          studyType: selStudyType,
          points: selPoints,
        },
      };
      getQuadPins(params, (response) => {
        if (area) {
          response = filterOutbounds(area, response);
          pendingAreas.splice(pendingAreas.indexOf(area), 1);
        }
        pendingMarkers = [...pendingMarkers, ...response];
        if (pendingAreas.length === 0) {
          setMarkers(pendingMarkers);
        }
      });
    }
  }

  const removeButtonsMarker = () => {
    layerShapes.getLayers().forEach((layer) => {
      layer instanceof L.Marker && layerShapes.removeLayer(layer);
    });
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <MarkersInterestPoints
            map={map}
            markersGoogle={markersGoogle}
            polygon={polygon}
            IconCustomGooglePoint={IconCustomGooglePoint}
            layerGroup={layerGroup}
          />
          {shapeSelectedToEdition && (
            <ControllerShapes
              map={map}
              layerShapes={layerShapes}
              polygon={polygon}
              setPolygon={setPolygon}
              setSelectedArea={setSelectedArea}
              shapeSelectedToEdition={shapeSelectedToEdition}
              setShapeSelectedToEdition={setShapeSelectedToEdition}
            />
          )}
          {buttonToConfirmSurroundings && (
            <div
              style={{
                display: "flex",
                width: "fit-content",
                zIndex: 1000,
                position: "fixed",
                bottom: 25,
                right: "50%",
                transform: "translate(50%, 0)",
              }}
            >
              <ButtonDefault
                fontSecondary
                icon={<HiXMark />}
                title="CANCELAR CRIAÇÃO DE ÁREA"
                color={"primary"}
                iconPosition="right"
                onClick={() => {
                  const rectId = paramsToSurroundings.rect?.index;

                  rectId && layerShapes.removeLayer(rectId);

                  paramsToSurroundings.pins.length > 0 &&
                    paramsToSurroundings.pins.forEach((pin) => {
                      const marker = map._layers[pin.id];
                      marker && pinsLayer.removeLayer(marker);
                    });

                  removeButtonsMarker();

                  setParamsToSurroundings({
                    ...paramsToSurroundings,
                    rects: null,
                    pins: null,
                  });

                  setButtonToConfirmSurroundings(false);
                }}
                borderRadius={30}
              />
              <ButtonDefault
                fontSecondary
                icon={<FaArrowRight />}
                title="CONFIRMAR ÁREA E COMEÇAR ESTUDO"
                color={"secondary"}
                iconPosition="right"
                onClick={() => {
                  setButtonToConfirmSurroundings(false);
                  removeButtonsMarker();
                  searchSurroundings();
                }}
                borderRadius={30}
              />
            </div>
          )}
          {buttonToConfirmBestAreaVariables && (
            <div
              style={{
                display: "flex",
                width: "fit-content",
                zIndex: 1000,
                position: "fixed",
                bottom: 25,
                right: "50%",
                transform: "translate(50%, 0)",
              }}
            >
              <ButtonDefault
                fontSecondary
                icon={<HiXMark />}
                title="CANCELAR CRIAÇÃO DE ÁREA"
                color={"primary"}
                iconPosition="right"
                onClick={() => {
                  const rectId = paramsToBestAreaVariables.rect.index;
                  try {
                    rectId && layerShapes.removeLayer(rectId);
                  } catch (error) {}
                  removeButtonsMarker();

                  setParamsToBestAreaVariables({
                    ...paramsToBestAreaVariables,
                    rects: null,
                  });

                  setButtonToConfirmBestAreaVariables(false);
                }}
                borderRadius={30}
              />
              <ButtonDefault
                fontSecondary
                icon={<FaArrowRight />}
                title="CONFIRMAR ÁREA E COMEÇAR ESTUDO"
                color={"secondary"}
                iconPosition="right"
                onClick={() => {
                  setButtonToConfirmBestAreaVariables(false);
                  removeButtonsMarker();
                  searchBestAreaVariables();
                }}
                borderRadius={30}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default GrabMapData;
