import { CSSProperties, ReactNode } from "react";
import { colors } from "../../assets/colors";
import fonts from "../../config/fonts";

interface ButtonLinkProps {
  children: ReactNode;
  style?: CSSProperties;
  onClick?: React.MouseEventHandler<HTMLElement>;
  underline?: boolean;
  color?: string;
}

const colorLink = colors.link;

export function ButtonLink({
  children,
  onClick,
  style,
  underline = true,
  color,
}: ButtonLinkProps) {
  const textDecoration = underline ? "underline" : "none";

  return (
    <button
      onClick={onClick}
      style={{
        marginTop: 10,
        color: color ? color : colorLink,
        cursor: "pointer",
        fontSize: fonts.sizes.regular,
        fontFamily: fonts.regular,
        textDecoration,
        ...style,
      }}
    >
      {children}
    </button>
  );
}
