import { useEffect, useState } from "react";
import { AreaListData } from "../Preview/components/AreaListData";
import {
  CompleteRDPolygon,
  CompleteReportData,
  GraphicsList,
  GraphicsListByType,
  GraphTotalPackage,
  VariablePoly,
} from "../Preview/components/graphs/graphTSInterface";
import { IntroMap } from "./intromap";
import { ReportAreas } from "./reportareas";
import { Divider } from "antd";
import {
  getCompleteStudy,
  salvaLog,
  setLimitReports,
  userContract,
} from "../../../../services/getOptionsData";
import { screenshotMap } from "../Preview/MapImageRequest";
import { ButtonDefault } from "../../../../components/Button/ButtonDefault";
import { FaFile } from "react-icons/fa";
import { ModalDefault } from "../../../../components/Modal/ModalDefault";
import { organize } from "./order";
import { ReportTabs } from "./reporttabs";
import {
  AreaPinsGroup,
  PolygonParamList,
  PolygonShapesProps,
} from "../../../../Types/interfacs";
import { Loading } from "../../../../components/Loading/Loading";
import { Title } from "../../../../components/typography/Title";
import { colors } from "../../../../assets/colors";
import { useAuth } from "../../../../contexts/AuthContext";
import { FaCircleExclamation } from "react-icons/fa6";
import { TitleModal } from "../../../../components/typography/TitleModal";
import { SubTitle } from "../../../../components/typography/SubTitle";
import { ModalNoticeLimitReports } from "../../../../components/Modal/ModalNotices/ModalNoticeLimitReports";

interface ReportProps {
  polygon: PolygonShapesProps;
  areaList: AreaListData[];
  simpleStudyData: GraphTotalPackage | undefined;
  cnaesData: GraphTotalPackage[] | undefined;
  cnaePesquisado: boolean;
}

interface RectDataList {
  index: number;
  lat1: number;
  lon1: number;
  lat2: number;
  lon2: number;
}
interface PolyDataList {
  index: number;
  latlngs: PoluDataPoints[];
}
interface PoluDataPoints {
  lat: number;
  lon: number;
}
interface CircDataList {
  index: number;
  ilat: number;
  ilon: number;
  radius: number;
}
interface PinsDataList {
  id: number;
  color: string;
  type: string;
  values: number[];
}

export function ReportScreen({
  polygon,
  areaList,
  simpleStudyData,
  cnaesData,
  cnaePesquisado,
}: ReportProps) {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [graphicsData, setGraphicsData] = useState<CompleteReportData>({
    polygonList: [],
    radiusList: [],
    rectangleList: [],
    regionList: [],
    pins: [],
  });
  const [selecionado, setSelecionado] = useState(0);
  const [numberAvailableReports, setNumberAvailableReports] = useState(0);
  const [modalNoticeOpen, setModalNoticeOpen] = useState(false);

  useEffect(() => {
    if (!user?.company) return;
    userContract().then((response) => {
      if (!response) return;
      setNumberAvailableReports(response.countReports);
    });
  }, []);

  useEffect(() => {
    if (imageUrl !== "") {
      requestCompleteStudy(polygon);
    }
  }, [imageUrl]);


  const todasfiguras: CompleteRDPolygon[] = graphicsData.radiusList
    ? [
        ...graphicsData.radiusList,
        ...graphicsData.rectangleList,
        ...graphicsData.polygonList,
      ]
    : [];

  const listadesessoes: GraphicsListByType[] = organize(todasfiguras, areaList); //função para organizar a bagunça do servidor em sessoes e graficos

  if (polygon.pins.length > 0) {
    var total = 0;
    for (let i = 0; i < polygon.pins.length; i++) {
      const pingroup: AreaPinsGroup = polygon.pins[i];
      for (let j = 0; j < pingroup.values.length; j++) {
        const ping = pingroup.values[j];
        total += ping.values.length;
      }
    }

    const graficosDePins: GraphicsList[] = [
      {
        graphicname: "Total de alfinetes",
        measureUnit: "Integer",
        data: [
          {
            variable: {
              fee: false,
              labels: [
                {
                  name: "Total de alfinetes",
                  nameDesc: "Total de alfinetes",
                  value: total,
                  multiplier: 1,
                  multiplierCity: 1,
                },
              ],
              name: "Total de alfinetes",
              order: 0,
              tags: [],
              type: "Pins",
            },
            poly: { area: 0, centroid: "", error: "", index: 0, radius: 0 },
            area: {
              label: "",
              cor: "",
              studyType: "Pins",
              points: [],
              id: 0,
            },
          },
        ],
      },
    ];

    for (let i = 0; i < polygon.pins.length; i++) {
      const pingroup: AreaPinsGroup = polygon.pins[i];
      for (let j = 0; j < pingroup.values.length; j++) {
        const ping = pingroup.values[j];
        var foundGrafic = -1;
        for (let f = 0; f < graficosDePins.length; f++) {
          const gdp = graficosDePins[f];
          if (gdp.graphicname === ping.keyword) {
            foundGrafic = f;
            break;
          }
        }
        if (foundGrafic !== -1) {
          var foundk = -1;
          for (let fk = 0; fk < graficosDePins[foundGrafic].data.length; fk++) {
            const columndata = graficosDePins[foundGrafic].data[fk];
            if (columndata.area.cor === pingroup.color) {
              foundk = fk;
              break;
            }
          }
          if (foundk === -1) {
            graficosDePins[foundGrafic].data.push({
              variable: {
                fee: false,
                labels: [
                  {
                    name: ping.keyword,
                    nameDesc: ping.keyword,
                    value: ping.values.length,
                    multiplier: 1,
                    multiplierCity: 1,
                  },
                ],
                name: ping.keyword,
                order: 0,
                tags: [],
                type: "Pins",
              },
              poly: { area: 0, centroid: "", error: "", index: 0, radius: 0 },
              area: {
                label: pingroup.type,
                cor: pingroup.color,
                studyType: "Pins",
                points: [],
                id: 0,
              },
            });
          } else {
            if (
              graficosDePins[foundGrafic].data[foundk].variable.labels[0]
                .value < ping.values.length
            ) {
              graficosDePins[foundGrafic].data[
                foundk
              ].variable.labels[0].value = ping.values.length;
            }
          }
        } else {
          graficosDePins.push({
            graphicname: ping.keyword,
            measureUnit: "Integer",
            data: [
              {
                variable: {
                  fee: false,
                  labels: [
                    {
                      name: ping.keyword,
                      nameDesc: ping.keyword,
                      value: ping.values.length,
                      multiplier: 1,
                      multiplierCity: 1,
                    },
                  ],
                  name: ping.keyword,
                  order: 0,
                  tags: [],
                  type: "Pins",
                },
                poly: { area: 0, centroid: "", error: "", index: 0, radius: 0 },
                area: {
                  label: pingroup.type,
                  cor: pingroup.color,
                  studyType: "Pins",
                  points: [],
                  id: 0,
                },
              },
            ],
          });
        }
      }
    }
    const listaDePins: GraphicsList = {
      graphicname: "Lista de todos os alfinetes",
      measureUnit: "Pins",
      data: [],
    };

    graficosDePins.push(listaDePins);

    listadesessoes.push({ name: "Alfinetes", graphicsList: graficosDePins });
  }
  if (simpleStudyData && simpleStudyData.total > 0) {
    const graficosDeCNPJs: GraphicsList[] = [];
    const dataOfEmpresarial = listadesessoes.find(
      (item) => item.name === "Empresarial"
    );
    const dataEmpresarial = dataOfEmpresarial?.graphicsList[0]
      .data as VariablePoly[];

    // talvez possa ser feito de outra maneira
    if (dataEmpresarial) {
      const dataCNPJ = dataEmpresarial.map((allData) => {
        total = 0;
        simpleStudyData.totalByArea.forEach((dataByCnaeFilter) => {
          if (dataByCnaeFilter.area.id === allData.area.id) {
            total = dataByCnaeFilter.total;
          }
        });

        const newVariable = {
          fee: false,
          labels: [
            {
              name: "Total de Empresas",
              nameDesc: "Total de Empresas",
              value: total,
              multiplier: 1,
              multiplierCity: 1,
            },
          ],
          name: "Total de Empresas",
          order: 0,
          tags: [],
          type: "CNPJ",
        };

        return {
          variable: newVariable,
          poly: allData.poly,
          area: allData.area,
        };
      });

      const listaDeCnpjs: GraphicsList = {
        graphicname: "Total de Empresas",
        measureUnit: "Integer",
        data: dataCNPJ as VariablePoly[],
      };

      graficosDeCNPJs.push(listaDeCnpjs);

      listadesessoes.push({ name: "CNPJ", graphicsList: graficosDeCNPJs });
    }
  }

  async function requestCompleteStudy(polygon: PolygonParamList) {
    const polylist: PolyDataList[] = [];
    const circlist: CircDataList[] = [];
    const rectlist: RectDataList[] = [];
    graphicsData.pins = polygon.pins;

    //{{label:"Polígono "+i,cor:polygon.color,studyType:polygon.type ,points:allp}}

    areaList.forEach((area) => {
      if (area.label.startsWith("Retângulo")) {
        var lowX;
        var lowY;
        var hiX;
        var hiY;
        if (area.points[0] < area.points[2]) {
          lowX = area.points[2];
          hiX = area.points[0];
        } else {
          lowX = area.points[0];
          hiX = area.points[2];
        }
        if (area.points[1] > area.points[3]) {
          lowY = area.points[3];
          hiY = area.points[1];
        } else {
          lowY = area.points[1];
          hiY = area.points[3];
        }
        rectlist.push({
          index: area.id,
          lat1: lowX,
          lon1: lowY,
          lat2: hiX,
          lon2: hiY,
        });
      } else if (area.label.startsWith("Polígono")) {
        const pon = [];
        for (let index = 0; index < area.points.length; index += 2) {
          pon.push({ lat: area.points[index], lon: area.points[index + 1] });
        }
        polylist.push({ index: area.id, latlngs: pon });
      } else if (area.label.startsWith("Círculo")) {
        circlist.push({
          index: area.id,
          ilat: area.points[0],
          ilon: area.points[1],
          radius: area.points[2],
        });
      }
    });

    const params = {
      pins: polygon.pins,
      polygonList: polylist,
      radiusList: circlist,
      rectangleList: rectlist,
      mapPrint: imageUrl,
      regionList: [],
      tokens: [],
    };

    const val: CompleteReportData = await getCompleteStudy(params, setLoading);
    if (val) {
      val.pins = graphicsData.pins;
      setGraphicsData(val);
    }
  }

  const addressFormat = (addressArr: any) => {
    addressArr = (addressArr + "").replaceAll("  ", " ");
    const str = addressArr.toLowerCase();
    const arr = str.split(",");
    if (arr.length === 2) {
      return addressArr;
    }
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const [
      street,
      streetName,
      number,
      complement,
      district,
      zipcode,
      state,
      city,
    ] = arr;
    const address = `${street} ${streetName}, ${number} - ${
      complement !== "" ? `${complement} - ${district}` : district
    } - ${city} - ${(state + "").toUpperCase()} - ${zipcode}`;
    return address;
  };

  return (
    <>
      <ButtonDefault
        shadow={true}
        color="secondary"
        title="Gerar Relatório"
        icon={<FaFile />}
        onClick={() => {
          if (numberAvailableReports === 0) {
            setModalNoticeOpen(true);
            return;
          }
          setLimitReports().then((response) => {
            if (!response) return;
            setNumberAvailableReports(response.limit);
          });
          if (cnaePesquisado) {
            setLoading(true);
            polygon.afterCNAE = () => {
              salvaLog({
                action: "Abriu Relatório",
                params: "",
              }).then((result: any) => {});
              setOpen(!open);
              screenshotMap(setImageUrl);
            };
            polygon.redoCNAESearch();
          } else {
            setLoading(true);
            polygon.centerAreas(areaList);
            setTimeout(async () => {
              salvaLog({
                action: "Abriu Relatório",
                params: "",
              }).then((result: any) => {});
              setOpen(!open);
              screenshotMap(setImageUrl);
            }, 2000);
          }
        }}
      />
      <div
        style={{
          display: "flex",
          backgroundColor: "#fff",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          minWidth: "25px",
          height: "25px",
          borderRadius: "10px",
          position: "absolute",
          padding: "0px 5px",
          right: "-10px",
          bottom: "70px",
          cursor: "default",
        }}
      >
        <Title
          style={{
            color:
              numberAvailableReports === 0
                ? colors.transparent.orange.content
                : colors.transparent.green.content,
          }}
        >
          {numberAvailableReports}
        </Title>
      </div>
      {modalNoticeOpen && (
        <ModalNoticeLimitReports setModalNoticeOpen={setModalNoticeOpen} />
      )}

      {!open && loading && <Loading />}
      {open && (
        <ModalDefault
          styleContainer={{
            alignItems: "flex-start",
            paddingBlock: 45,
          }}
          icon={<FaFile size={20} />}
          title="Relatório"
          setModalOpen={() => {
            setOpen(!open);
            screenshotMap(setImageUrl);
          }}
        >
          {loading && <Loading />}
          <div
            style={{
              overflowY: "scroll",
              maxHeight: "70vh",
              maxWidth: loading ? "1200px" : "auto",
              minWidth: loading ? "1200px" : "auto",
              width: loading ? "1200px" : "auto",
            }}
          >
            <IntroMap
              areaList={areaList}
              listadesessoes={listadesessoes}
              graphicsData={graphicsData}
              setSelecionado={setSelecionado}
              imageUrl={imageUrl}
              loading={loading}
              setLoading={setLoading}
              simpleStudyData={simpleStudyData}
              addressFormat={addressFormat}
            />
            <ReportAreas
              areaList={areaList}
              graphicsData={graphicsData}
              addressFormat={addressFormat}
            />
            <Divider />
            <ReportTabs
              areaList={areaList}
              listadesessoes={listadesessoes}
              graphicsData={graphicsData}
              selecionado={selecionado}
              setSelecionado={setSelecionado}
              simpleStudyData={simpleStudyData}
              cnaesData={cnaesData}
              addressFormat={addressFormat}
              cnaePesquisado={cnaePesquisado}
            />
          </div>
        </ModalDefault>
      )}
    </>
  );
}
