import L, { LatLng, LatLngTuple } from "leaflet";
import { Shapes } from "../../../../../Types/Shapes";
import React from "react";
import { PolygonParamList } from "../../../../../Types/interfacs";
import { PolygonProps } from "../types";
import { salvaLog } from "../../../../../services/getOptionsData";
import * as turf from "@turf/turf";

interface CreatePolygonProps {
  map: L.Map;
  layerShapes: L.LayerGroup;
  color: string;
  setPolygon: React.Dispatch<React.SetStateAction<PolygonParamList>>;
  setShapeActive: React.Dispatch<React.SetStateAction<Shapes>>;
  setShapeSelectedToEdition: React.Dispatch<
    React.SetStateAction<{
      id: number;
      shape: L.Polygon;
      optionsButton: {
        editButton: boolean;
        deleteButton: boolean;
      };
    }>
  >;
}

export const CreatePolygon = ({
  map,
  layerShapes,
  color,
  setPolygon,
  setShapeActive,
  setShapeSelectedToEdition,
}: CreatePolygonProps) => {
  const polygonPoints: LatLngTuple[] = [];
  let polygon: PolygonProps;
  let toolTip: L.Tooltip;
  let area: number;

  map.on("mousedown", handleClickDown);
  map.on("click", handleClick);
  map.on("mousemove", handleMove);
  map.on("mouseup", handleClickUp);
  map.on("keypress", handleKeyDown);
  map.getContainer().style.cursor = "crosshair";

  function handleClickDown(e: L.LeafletMouseEvent) {
    map.off("keypress", handleKeyDown);
    if (e.originalEvent.button === 0) {
      map.dragging.disable();
      const latLng = [e.latlng.lat, e.latlng.lng] as LatLngTuple;

      polygonPoints.push(latLng);
      salvaLog({
        action: "Adicionou Ponto ao Poligono",
        params: "" + latLng,
      }).then((result: any) => {
      });

      map.on("keypress", handleKeyDown);
      if (polygon) {
        polygon.setLatLngs(polygonPoints);
        area = turf.area(polygon.toGeoJSON()) / 1000000;
        area = Math.trunc(area);
        if (area > 31000) {
          polygonPoints.pop();
          polygon.setLatLngs(polygonPoints);
        }
      } else {
        polygon = drawPolygon(polygonPoints, color) as PolygonProps;
        polygon.addTo(layerShapes);
      }

      polygon._path.style.cursor = "crosshair";
    } else if (e.originalEvent.button === 1) {
      map.getContainer().style.cursor = "grabbing";
    }
  }

  function handleClick(e: L.LeafletMouseEvent) {
    const latLng = [e.latlng.lat, e.latlng.lng] as LatLngTuple;
    addTooltip(latLng, area);
  }

  function handleMove(e: L.LeafletMouseEvent) {
    const latLng = [e.latlng.lat, e.latlng.lng] as LatLngTuple;
    const auxPoints = [...polygonPoints, latLng];
    const aux = drawPolygon(auxPoints, color);

    area = turf.area(aux.toGeoJSON()) / 1000000;
    area = Math.trunc(area);
    addTooltip(latLng, area);
  }

  function handleClickUp(e: L.LeafletMouseEvent) {
    map.dragging.enable();
    map.getContainer().style.cursor = "crosshair";
  }

  function handleKeyDown(e: L.LeafletKeyboardEvent) {
    const keyPressed = e.originalEvent.code;
    if (keyPressed === "Enter") {
      map.dragging.enable();
      salvaLog({
        action: "Finalizou Polígono",
        params: "",
      }).then((result: any) => {
      });
      map.off("mousedown");
      map.off("keypress");
      map.off("click");

      let type = "POLYGON_STUDY";
      let pointsKey = "polygonList";

      const idShape = polygon._leaflet_id;

      polygon.on("mousedown", () => {
        map.off("click");
      });

      polygon.on("click", () => {
        setShapeSelectedToEdition({
          id: idShape,
          shape: polygon,
          optionsButton: {
            editButton: true,
            deleteButton: true,
          },
        });
      });

      if (toolTip) {
        toolTip.remove();
      }

      setPolygon((oldPolygon: any) => {
        return {
          ...oldPolygon,
          [pointsKey]: [
            ...oldPolygon[pointsKey],
            {
              id: idShape,
              points: polygonPoints,
              type: type,
              color: color,
            },
          ],
        };
      });

      setShapeActive("arrow");
    }
  }

  function addTooltip(latLng: LatLngTuple, area: number) {
    if (polygonPoints.length > 2) {
      if (toolTip) {
        toolTip.remove();
      }
      toolTip = L.tooltip()
        .setLatLng(latLng)
        .setContent(
          `<div
        style="
        background-color: white;
        color: ${area > 31000 ? "red" : "black"};
          padding: 5px;
          font-size: 14px;
          font-weight: bold;
        "
        >${area > 31000 ? "Max 31000" : area}
        km²</div>`
        )
        .addTo(map);
    }
  }

  return null;
};

export function drawPolygon(polygonPoints: LatLngTuple[], color: string) {
  const polygon = L.polygon(polygonPoints, {
    color,
    weight: 4,
    fillOpacity: 0.1,
  });

  return polygon;
}

//limiteKMQuadrado = 31000km²
