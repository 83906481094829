import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { colors } from "../../assets/colors";
import { SelectOption, SelectOptionRadio } from "../../Types/Variable";
import { Checkbox2 } from "../Checkbox/CheckBox2";
import { CaretDownOutlined } from "@ant-design/icons";
import fonts from "../../config/fonts";

interface SelectProps {
  defaultValue?: string[];
  placeholder?: React.ReactNode;
  options?: SelectOption[];
  onChange: (selectedValues: string[]) => void; // Modifique o tipo da função onChange
  borderRadius?: number;
  style?: CSSProperties;
}

type StylesObject = Record<string, CSSProperties>;

const backgroundColor = colors.default.primary.background;
const grayColor = colors.default.disabled.content;
const shadowColor = colors.shadow;

export function SelectCheckBoxOfGroup({
  options,
  defaultValue,
  borderRadius,
  placeholder,
  onChange,
  style,
}: SelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [groupOpened, setGroupOpened] = useState<string | null>(null);
  const [optionsOfGroupOpened, setOptionsOfGroupOpened] = useState<
    SelectOptionRadio[] | null
  >(null);

  const selectRef = useRef<HTMLDivElement>(null);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    optionsOfGroupOpened?.every((option) =>
      selectedOptions.includes(option.value as string)
    )
      ? setSelectAll(true)
      : setSelectAll(false);
  }, [groupOpened, selectedOptions]);

  useEffect(() => {
    if (defaultValue) {
      setSelectedOptions(defaultValue);
    }
  }, [defaultValue]);

  const handleOptionClick = (option: SelectOptionRadio) => {
    if (option.value)
      if (selectedOptions.includes(option.value)) {
        // Se a opção já estiver selecionada, remova-a
        const updatedSelection = selectedOptions.filter(
          (value) => value !== option.value
        );
        setSelectedOptions(updatedSelection);
        onChange(updatedSelection);
      } else {
        // Caso contrário, adicione-a à seleção
        setSelectedOptions([...selectedOptions, option.value]);
        onChange([...selectedOptions, option.value]);
      }
  };

  function ArrayHasElement(array: string[], target: string) {
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (target === element) {
        return true;
      }
    }
    return false;
  }

  const handleSelectAll = () => {
    if (selectAll) {
      if (!optionsOfGroupOpened) return;
      const diselected = optionsOfGroupOpened.map(
        (option) => option.value as string
      );
      const newlist = [];
      for (let index = 0; index < selectedOptions.length; index++) {
        const element = selectedOptions[index];
        if (!ArrayHasElement(diselected, element)) {
          newlist.push(element);
        }
      }
      setSelectedOptions(newlist);
      onChange(newlist);
    } else {
      if (!optionsOfGroupOpened) return;
      const newselected = optionsOfGroupOpened.map(
        (option) => option.value as string
      );
      for (let index = 0; index < selectedOptions.length; index++) {
        const element = selectedOptions[index];
        if (!ArrayHasElement(newselected, element)) {
          newselected.push(element);
        }
      }
      setSelectedOptions(newselected);
      onChange(newselected);
    }
    setSelectAll(!selectAll);
  };

  const styleGroup: CSSProperties = {
    all: "unset",
    color: "#888",
    padding: 8,
    fontWeight: "bold",
  };

  return (
    <div style={{ ...selectStyles.container, ...style }} ref={selectRef}>
      <div
        style={{ ...selectStyles.header, borderRadius: borderRadius || 5 }}
        onClick={toggleDropdown}
      >
        <div
          style={{
            ...selectStyles.selectedOption,
          }}
        >
          {placeholder || "Select options"}
        </div>
        <CaretDownOutlined
          style={{
            ...selectStyles.caretIcon,
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
          }}
          className={isOpen ? "open" : ""}
        />
      </div>
      {isOpen && options && (
        <div style={selectStyles.optionList}>
          {options.map((option) => (
            <React.Fragment key={option.label as string}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (groupOpened === option.label) {
                    setGroupOpened(null);
                    setOptionsOfGroupOpened(null);
                  } else {
                    setGroupOpened(option.label as string);
                    setOptionsOfGroupOpened(
                      option.options as SelectOptionRadio[]
                    );
                  }
                }}
              >
                <li style={styleGroup}>
                  <strong>{option.label}</strong>
                </li>
                <CaretDownOutlined
                  style={{
                    ...selectStyles.caretIcon,
                    transform:
                      groupOpened === option.label
                        ? "rotate(0deg)"
                        : "rotate(270deg)",
                  }}
                />
              </div>
              {groupOpened === option.label && (
                <>
                  <Checkbox2
                    style={{
                      border: "none",
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                    label={"Selecionar todos"}
                    checked={selectAll}
                    color={grayColor}
                    onChange={() => handleSelectAll()}
                  />
                  {option.options?.map((subOption, index) => (
                    <div key={index} style={selectStyles.styleSuboptionItem}>
                      <Checkbox2
                        style={{
                          border: "none",
                          paddingLeft: 10,
                        }}
                        label={subOption.label as string}
                        checked={
                          subOption.value
                            ? selectedOptions.includes(subOption.value)
                            : false
                        }
                        color={grayColor}
                        onChange={() => handleOptionClick(subOption)}
                      />
                    </div>
                  ))}
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
}

const selectStyles: StylesObject = {
  container: {
    position: "relative",
    width: "100%",
    height: 48,
    color: grayColor,
    fontSize: fonts.sizes.medium,
    fontWeight: "normal",
  },
  header: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    padding: 8,
    paddingLeft: 12,
    border: `1px solid ${grayColor}`,
    display: "flex",
    alignItems: "center",
  },
  selectedOption: {
    marginRight: 8,
  },
  caretIcon: {
    marginLeft: "auto",
  },
  optionList: {
    position: "absolute",
    zIndex: 9999,
    top: "105%",
    width: "100%",
    border: `1px solid ${grayColor}`,
    borderRadius: 5,
    background: backgroundColor,
    boxShadow: `0px 2px 8px ${shadowColor}`,
    maxHeight: 300, // Alterado para maxHeight
    overflowY: "auto", // Alterado para scroll vertical
    padding: 8, // Adicionado espaçamento
  },
  styleSuboptionItem: {
    cursor: "pointer",
    height: 48,
    display: "flex",
    alignItems: "center",
  },
};
