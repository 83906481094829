import React, { CSSProperties, ReactNode } from "react";
import { colors } from "../../assets/colors";
import fonts from "../../config/fonts";

interface SubTitleProps {
  children: ReactNode;
  color?: string;
  style?: CSSProperties;
}

const colorSubtitle = colors.default.disabled.content;

export function SubTitle({
  children,
  color = colorSubtitle,
  style,
}: SubTitleProps) {
  return (
    <h1
      style={{
        fontSize: fonts.sizes.regular,
        paddingBlock: 10,
        fontWeight: "normal",
        color,
        ...style,
      }}
    >
      {children}
    </h1>
  );
}
