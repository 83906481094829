import { FaSearch } from "react-icons/fa";
import { ButtonDefault } from "../../../../components/Button/ButtonDefault";
import { AddCompanyIcon } from "../../../../components/icon/AddCompanyIcon";
import { useState } from "react";
import { CustomInput } from "../../../../components/Input/CustomInput";

import { ModalToAddOrEditCompany } from "../ModalToAddOrEditCompany/ModalToAddOrEditCompany";
import { ContractResponse, DataTable } from "../../types";
import { FaX } from "react-icons/fa6";

interface FiltersAdministrationProps {
  companiesContract: ContractResponse[];
  setCompaniesContract: (value: ContractResponse[]) => void;
  setData: (value: DataTable[]) => void;
  reloadCompanies: () => void;
  setMessage: (icon: string, title: string, msg: string) => void;
  mensagemAparecendo: boolean;
}

export function FiltersAdministration({
  companiesContract,
  setCompaniesContract,
  setData,
  reloadCompanies,
  setMessage,
  mensagemAparecendo,
}: FiltersAdministrationProps) {
  const [modalAddNewCompany, setModalAddNewCompany] = useState(false);
  const [searchCompany, setSearchCompany] = useState("");
  const doSearch = () => {
    const newData = [] as ContractResponse[];
    companiesContract.map((item) => {
      if (
        item.company.name.toUpperCase().includes(searchCompany.toUpperCase())
      ) {
        newData.push(item);
      }

      const data = newData.map((company) => {
        const maxAdmins = company.company.maxAdminCompany;
        const maxCompanyUsers = company.company.maxCompanyUsers;
        const maxUsers = company.company.maxUsers;
        const countCurrentMaxAdminCompanyUsers =
          company.company.countCurrentMaxAdminCompanyUsers;
        const countCurrentMaxCompanyUsers =
          company.company.countCurrentMaxCompanyUsers;
        const countCurrentMaxUsers = company.company.countCurrentMaxUsers;

        const remainingDays = Math.round(
          (company.expirationTime - Date.now()) / (1000 * 60 * 60 * 24)
        );

        const adminsFranchisee = `${countCurrentMaxAdminCompanyUsers}/${maxAdmins}`;
        const franchisee = `${countCurrentMaxCompanyUsers}/${maxCompanyUsers}`;
        const users = `${countCurrentMaxUsers}/${maxUsers}`;
        const studyLimits = company.limitStudies;
        const numberofReports = company.countReports;

        return {
          id: company.id,
          idcompany: company.company.id,
          companyName: company.company.name,
          active: company.enable ? "Sim" : "Não",
          trial: company.trial ? "Sim" : "Não",
          remainingDays,
          adminsFranchisee,
          franchisee,
          users,
          studyLimits,
          numberofReports,
        };
      });
      setData(data);
    });
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomInput
          onChange={(e) => {
            setSearchCompany(e.target.value);
          }}
          onEnter={(e) => {
            doSearch();
          }}
          placeholder="Pesquisar empresa"
          type="text"
          value={searchCompany}
        />

        <div
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <ButtonDefault
            color="secondary"
            title="PESQUISAR"
            icon={<FaSearch />}
            size="medium"
            iconPosition="right"
            style={{
              width: 166,
              height: 48,
              marginLeft: 30,
            }}
            borderRadius={25}
            onClick={() => doSearch()}
          />
          <ButtonDefault
            color="tertiary"
            title="ADICIONAR EMPRESA"
            icon={<AddCompanyIcon />}
            iconPosition="right"
            size="medium"
            onClick={() => {
              setModalAddNewCompany(true);
            }}
            style={{
              width: 247,
              height: 48,
            }}
            borderRadius={25}
          />
        </div>
      </div>
      {modalAddNewCompany && (
        <ModalToAddOrEditCompany
          setModalOpen={setModalAddNewCompany}
          setCompaniesContract={setCompaniesContract}
          reloadCompanies={() => {
            reloadCompanies();
            setMessage("Check", "Sucesso!", "Empresa criada com sucesso!");
          }}
          setMessage={setMessage}
          mensagemAparecendo={mensagemAparecendo}
        />
      )}
    </>
  );
}
