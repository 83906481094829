import { VariableSelectOption } from "../../../Types/Variable";

interface SubOptionProps {
  option: VariableSelectOption;
  index: number;
  handleOptionClick: (option: VariableSelectOption, index: number) => void;
}

const style: React.CSSProperties = {
  padding: 8,
  paddingLeft: 12,
  height: 48,
  transition: "background 0.3s",
  display: "flex",
  alignItems: "center",
  width: "max-content",
};

export function SubOptionItem({
  option,
  index,
  handleOptionClick,
}: SubOptionProps) {
  return (
    <div
      style={{
        cursor: "pointer",
      }}
      onClick={() => handleOptionClick(option, index)}
      title={option.label}
    >
      <li style={style}>{option.label}</li>
    </div>
  );
}
