import { cityInfoFilter } from "../../data/cityNumbers";
import {
  translatePortArray,
  translatePortToStr,
} from "../../data/dataFilters/portes";
import { formatDate } from "../Map/components/reports/intromap";
import { dateFormat } from "../Map/Utils";
import { CompanyInfoProps } from "./Companies";

export const exportCompaniesToCSV = (
  companies: CompanyInfoProps[],
  setMessage: (title: string, message: string) => void
) => {
  const ExcelJS = require("exceljs");
  const workbook = new ExcelJS.Workbook();
  workbook.creator = "Palmsoft";
  workbook.lastModifiedBy = "Palmsoft";
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();
  workbook.properties.date1904 = true;

  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 4000,
      height: 8000,
      firstSheet: 0,
      activeTab: 1,
      visibility: "visible",
    },
  ];

  // create new sheet with pageSetup settings for A4 - landscape
  const worksheet = workbook.addWorksheet("A4 Portrait", {
    pageSetup: { paperSize: 9, orientation: "portrait" },
    headerFooter: {
      firstHeader: "Empresas",
      firstFooter: "Empresas",
    },
  });

  worksheet.columns = [
    { width: 30 },
    { width: 10 },
    { width: 50 },
    { width: 50 },
    { width: 10 },
    { width: 20 },
    { width: 20 },
    { width: 100 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 50 },
    { width: 30 },
    { width: 30 },
    { width: 150 },
    { width: 10 },
    { width: 10 },
    { width: 30 },
    { width: 30 },
    { width: 15 },
    { width: 50 },
  ];

  worksheet.addRow([
    "CNPJ",
    "Matriz Filial",
    "Razao Social",
    "Nome Fantasia",
    "CNAE",
    "Inicio Atividade",
    "CNAE fiscal",
    "Endereco",
    "Telefone 1",
    "Telefone 2",
    "Fax",
    "Email",
    "Capital Social",
    "Porte",
    "Cnaes Secundários",
    "Estado",
    "Cidade",
    "Latitudo",
    "Longitude",
  ]);
  setMessage("Gerando CSV", "");
  for (let index = 0; index < companies.length; index++) {
    const company: CompanyInfoProps = companies[index];
    if (index % 100 === 0) {
      setMessage("Gerando CSV", index + "/" + companies.length);
    }
    worksheet.addRow([
      company.cnpj,
      company.matriz_filial,
      company.razao_social ? company.razao_social : company.nome_fantasia,
      company.nome_fantasia ? company.nome_fantasia : company.razao_social,
      company.cod_nat_juridica,
      dateFormat("" + company.data_inicio_ativ),
      company.cnae_fiscal,
      company.endereco,
      company.telefone1,
      company.telefone2,
      company.fax,
      company.email,
      company.capital_social,
      translatePortToStr(parseInt("0" + company.porte)),
      company.cnaes_secundarios,
      company.estado,
      company.cidade ? cityInfoFilter(company.cidade) : " ",
      company.lat,
      company.lng,
    ]);
  }
  setMessage("Gerando CSV", "Escrevendo arquivo...");
  workbook.xlsx.writeBuffer().then(function (data: BlobPart) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    let day = new Date();
    anchor.download = `Relatorio-${formatDate(day)}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
  setMessage("OK", "OK");
};

export const exportTxtToCSV = (
  txt: string,
  setMessage: (title: string, message: string) => void
) => {
  const ExcelJS = require("exceljs");
  const workbook = new ExcelJS.Workbook();
  workbook.creator = "Palmsoft";
  workbook.lastModifiedBy = "Palmsoft";
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();
  workbook.properties.date1904 = true;

  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 4000,
      height: 8000,
      firstSheet: 0,
      activeTab: 1,
      visibility: "visible",
    },
  ];

  // create new sheet with pageSetup settings for A4 - landscape
  const worksheet = workbook.addWorksheet("A4 Portrait", {
    pageSetup: { paperSize: 9, orientation: "portrait" },
    headerFooter: {
      firstHeader: "Empresas",
      firstFooter: "Empresas",
    },
  });

  worksheet.columns = [
    { width: 30 },
    { width: 10 },
    { width: 50 },
    { width: 50 },
    { width: 10 },
    { width: 20 },
    { width: 20 },
    { width: 100 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 50 },
    { width: 30 },
    { width: 30 },
    { width: 150 },
    { width: 10 },
    { width: 10 },
    { width: 30 },
    { width: 30 },
    { width: 15 },
    { width: 50 },
  ];

  setMessage("Gerando CSV", "");
  while (txt.indexOf("\n") > 0) {
    var linha = txt.slice(0, txt.indexOf("\n"));
    txt = txt.substring(txt.indexOf("\n") + 1);
    worksheet.addRow(linha.split(";"));
  }
  worksheet.addRow(txt.split(";"));

  setMessage("Gerando CSV", "Escrevendo arquivo...");
  workbook.xlsx.writeBuffer().then(function (data: BlobPart) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    let day = new Date();
    anchor.download = `Relatorio-${formatDate(day)}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
  setMessage("OK", "OK");
};
