import { LatLngExpression, layerGroup } from "leaflet";
import { useEffect, useState } from "react";
import { AreaListData } from "./components/Preview/components/AreaListData";
import { Shapes } from "../../Types/Shapes";
import {
  GraphObj,
  GraphTotalPackage,
} from "./components/Preview/components/graphs/graphTSInterface";
import { VariableDataProps, VariableGroupProps } from "../../Types/Variable";
import {
  BestAreaVariablesParams,
  EditPinParams,
  FreeNavigationParams,
  RadiusExclusivityParams,
  SurroundingsParams,
} from "../../Types/Params";
import { getVariables } from "../../services/getOptionsData";
import { ToolsLayer } from "./components/ToolsLayer/ToolsLayer";
import { MenuCenter } from "./components/MenuCenter/MenuCenter";
import { Legend } from "./components/Legend/Legend";
import { ReportScreen } from "./components/reports";
import { PreviewLayer } from "./components/Preview";
import { MapContainer, TileLayer } from "react-leaflet";
import { GeoJsonLayer } from "./Layers/GeoJsonLayer/GeoJsonLayer";
import GrabMapData from "./Layers/GrabMapData";
import { Companies } from "../Companies/Companies";
import { Header } from "./components/Header/Header";
import { Shape } from "./Layers/utils/types";
import { Administration } from "../Administration/Administration";
import { SubTitle } from "../../components/typography/SubTitle";
import { FaCircleExclamation } from "react-icons/fa6";
import { ModalDefault } from "../../components/Modal/ModalDefault";
import { TitleModal } from "../../components/typography/TitleModal";

var layerShapes = layerGroup();

export interface labelsProps {
  name: string;
  value: string;
}

export interface AllFiltersProps {
  [key: string]: string[];
  cnae: string[];
  cidade: string[];
  estado: string[];
  capitalSocial: string[];
  porte: string[];
  inicioDeAtividade: string[];
}

function ReactMap() {
  const initalCenter: LatLngExpression = [-27.595, -48.55];

  const [colorIndex, setColorIndex] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState({
    selectedCNAE: [],
    selectedCidades: [],
    selectedEstados: [],
    selectedCS: [],
    selectedPort: [],
    selectedIA: [],
  });
  const [center, setCenter] = useState<LatLngExpression | null>(null);
  const [newSearch, setNewSearch] = useState(null);

  const [allFilters, setAllFilters] = useState<AllFiltersProps>({
    cnae: [],
    cidade: [],
    estado: [],
    capitalSocial: [],
    porte: [],
    inicioDeAtividade: [],
  });

  const [polygon, setPolygon] = useState({
    polygonList: [],
    radiusList: [],
    rectangleList: [],
    lineList: [],
    pins: [],
    afterCNAE: () => {},
    redoCNAESearch: () => {},
    deletfunction: () => {},
    forceUpdate: () => {},
    deleteAllfunction: () => {},
    updatefunction: () => {},
    centerAreas: (areas: any) => {},
  });

  const hasContent =
    polygon.polygonList.length > 0 ||
    polygon.radiusList.length > 0 ||
    polygon.rectangleList.length > 0;

  const [shapeSelectedToEdition, setShapeSelectedToEdition] = useState<{
    id: number;
    shape: Shape;
    optionsButton: {
      editButton: boolean;
      deleteButton: boolean;
    };
  } | null>(null);

  const [tabSelected, setTabSelected] = useState<
    "MAPA" | "EMPRESA" | "NOTÍCIAS" | "ADMINISTRAÇÃO"
  >("MAPA");
  const [areaList, setAreaList] = useState<AreaListData[]>([]);

  const [selectedArea, setSelectedArea] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [CNAEPesquisado, setCNAEPesquisado] = useState(false);

  const [markersGoogle, setMarkersGoogle] = useState({});

  const [pan, setPan] = useState({
    x: -47.50488281250001,
    y: -27.023654129209838,
    w: -2.09014893,
    h: -1.14037839,
  });
  const [shapeActive, setShapeActive] = useState<Shapes>("arrow");
  const [buttonToConfirmSurroundings, setButtonToConfirmSurroundings] =
    useState(false);
  const [
    buttonToConfirmBestAreaVariables,
    setButtonToConfirmBestAreaVariables,
  ] = useState(false);

  const [tabOpen, setTabOpen] = useState(false);

  const [variable, setVariable] = useState("Renda total por faixa");

  const [actualLabels, setActualLabels] = useState<labelsProps[]>([]);

  const [actualColors, setActualColors] = useState<
    { hue: number; sat: string; lum: string }[]
  >([]);

  const [activeLabels, setActiveLabels] = useState<string[]>([]);

  const [graphicsData, setGraphicsData] = useState<GraphObj[]>([]);

  const [totalStudyResults, updateStudyResults] = useState<GraphTotalPackage>();
  const [totalStudyResultsCnaes, updateStudyResultsCnaes] =
    useState<GraphTotalPackage[]>();

  const [variables, setVariables] = useState<VariableGroupProps>();

  const [paramsToFreeNavigation, setParamsToFreeNavigation] =
    useState<FreeNavigationParams>({
      exclude: null,
      filterCodes: [],
      id: "",
      ranges: [],
      variable: variable,
    });

  useEffect(() => {
    setParamsToFreeNavigation((prev) => ({
      ...prev,
      variable: variable,
    }));
  }, [variable]);

  const [paramsToBestAreaVariables, setParamsToBestAreaVariables] =
    useState<BestAreaVariablesParams>({
      labelMax: "",
      labelMin: "",
      maxRadiusKM: 0,
      nuAreas: 0,
      rect: null,
    });
  const [paramsToSurroundings, setParamsToSurroundings] =
    useState<SurroundingsParams>({
      maxRadiusKM: 0,
      nuAreas: 0,
      pins: null,
      rect: null,
    });

  const [paramsToRadiusExclusivity, setParamsToRadiusExclusivity] =
    useState<RadiusExclusivityParams>({
      labels: [],
      points: [],
      rects: [],
      studyAreas: true,
      searchAreas: false,
    });

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const getVariablesData = async () => {
      const variableData: VariableDataProps = await getVariables();
      if (!variableData) return;
      setVariables(variableData.variables);
    };
    getVariablesData();
  }, []);

  const [modalNoticeOpen, setModalNoticeOpen] = useState(false);
  const [pinsOnEdition, setPinsOnEdition] = useState<EditPinParams>(null);

  return (
    <>
      <Header setTabSelected={setTabSelected} tabSelected={tabSelected} />
      <div id="container">
        <div id="container-map">
          {tabSelected === "EMPRESA" && (
            <Companies
              polygon={polygon}
              allFilters={allFilters}
              setAllFilters={setAllFilters}
            />
          )}
          {tabSelected === "ADMINISTRAÇÃO" && <Administration />}
          <div
            style={{
              display: "flex",
              position: "absolute",
              alignItems: "flex-end",
              zIndex: "1000",
              bottom: 50,
              right: 0,
              cursor: "pointer",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            v_1.27
          </div>

          <ToolsLayer
            polygon={polygon}
            setPolygon={setPolygon}
            shapeActive={shapeActive}
            setShapeActive={setShapeActive}
            setMarkersGoogle={setMarkersGoogle}
            setCenter={setCenter}
            setNewSearch={setNewSearch}
            processing={processing}
            hasContent={hasContent}
          />

          <div
            style={{
              display: "flex",
              position: "absolute",
              padding: "0px 0px 25px 25px",
              alignItems: "flex-end",
              zIndex: "1000",
              bottom: 0,
            }}
          >
            <div
              style={{
                position: "absolute",
                height: "100vh",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: tabOpen
                    ? actualLabels.length < 4
                      ? "45%"
                      : actualLabels.length < 9
                      ? "35%"
                      : "30%"
                    : "45%",
                }}
              >
                <MenuCenter
                  polygon={polygon}
                  processing={processing}
                  setProcessing={setProcessing}
                  shapeActive={shapeActive}
                  setShapeActive={setShapeActive}
                  buttonToConfirmSurroundings={buttonToConfirmSurroundings}
                  buttonToConfirmBestAreaVariables={
                    buttonToConfirmBestAreaVariables
                  }
                  pinsOnEdition={pinsOnEdition}
                  setPinsOnEdition={setPinsOnEdition}
                  paramsToBestAreaVariables={paramsToBestAreaVariables}
                  setParamsToBestAreaVariables={setParamsToBestAreaVariables}
                  paramsToFreeNavigation={paramsToFreeNavigation}
                  setParamsToFreeNavigation={setParamsToFreeNavigation}
                  paramsToSurroundings={paramsToSurroundings}
                  setParamsToSurroundings={setParamsToSurroundings}
                  paramsToRadiusExclusivity={paramsToRadiusExclusivity}
                  setParamsToRadiusExclusivity={setParamsToRadiusExclusivity}
                  variables={variables}
                />
              </div>
            </div>

            <Legend
              tabOpen={tabOpen}
              setTabOpen={setTabOpen}
              title={variable}
              actualLabels={actualLabels}
              setActiveLabels={setActiveLabels}
              setVariable={setVariable}
              setActualLabels={setActualLabels}
              variables={variables}
              setActualColors={setActualColors}
            />
            {hasContent && (
              <ReportScreen
                polygon={polygon}
                areaList={areaList}
                simpleStudyData={totalStudyResults}
                cnaesData={totalStudyResultsCnaes}
                cnaePesquisado={CNAEPesquisado}
              />
            )}
          </div>
          <PreviewLayer
            hasContent={hasContent}
            selectedArea={selectedArea}
            setSelectedArea={setSelectedArea}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            markers={markers}
            setMarkers={setMarkers}
            CNAEPesquisado={CNAEPesquisado}
            setCNAEPesquisado={setCNAEPesquisado}
            setPan={setPan}
            polygon={polygon}
            setPolygon={setPolygon}
            areaList={areaList}
            setAreaList={setAreaList}
            graphicsData={graphicsData}
            setGraphicsData={setGraphicsData}
            totalStudyResults={totalStudyResults}
            updateStudyResults={updateStudyResults}
            shapeSelectedToEdition={shapeSelectedToEdition}
            updateStudyResultsCnaes={updateStudyResultsCnaes}
          />

          <MapContainer
            center={initalCenter}
            zoom={10}
            zoomControl={false}
            scrollWheelZoom={true}
            id="my-map"
          >
            <TileLayer
              attribution=""
              url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
              maxZoom={18}
              minZoom={4}
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
            />
            <GeoJsonLayer
              processing={processing}
              draggable={shapeActive}
              variable={variable}
              layerShapes={layerShapes}
              colorIndex={colorIndex}
              polygon={polygon}
              setPolygon={setPolygon}
              paramsToFreeNavigation={paramsToFreeNavigation}
              setParamsToFreeNavigation={setParamsToFreeNavigation}
              activeLabels={activeLabels}
              setActualLabels={setActualLabels}
              actualColors={actualColors}
            />

            <GrabMapData
              pan={pan}
              pinsOnEdition={pinsOnEdition}
              setPinsOnEdition={setPinsOnEdition}
              layerShapes={layerShapes}
              newSearch={newSearch}
              center={center}
              setCenter={setCenter}
              colorIndex={colorIndex}
              setColorIndex={setColorIndex}
              markers={markers}
              polygon={polygon}
              shapeActive={shapeActive}
              buttonToConfirmSurroundings={buttonToConfirmSurroundings}
              setButtonToConfirmSurroundings={setButtonToConfirmSurroundings}
              buttonToConfirmBestAreaVariables={
                buttonToConfirmBestAreaVariables
              }
              setButtonToConfirmBestAreaVariables={
                setButtonToConfirmBestAreaVariables
              }
              selectedArea={selectedArea}
              markersGoogle={markersGoogle}
              setMarkers={setMarkers}
              setPolygon={setPolygon}
              setShapeActive={setShapeActive}
              selectedFilter={selectedFilter}
              setSelectedArea={setSelectedArea}
              setMarkersGoogle={setMarkersGoogle}
              paramsToRadiusExclusivity={paramsToRadiusExclusivity}
              setParamsToRadiusExclusivity={setParamsToRadiusExclusivity}
              paramsToBestAreaVariables={paramsToBestAreaVariables}
              setParamsToBestAreaVariables={setParamsToBestAreaVariables}
              setParamsToSurroundings={setParamsToSurroundings}
              paramsToSurroundings={paramsToSurroundings}
              shapeSelectedToEdition={shapeSelectedToEdition}
              setShapeSelectedToEdition={setShapeSelectedToEdition}
              setModalNoticeOpen={setModalNoticeOpen}
            />
          </MapContainer>
        </div>
      </div>
      {modalNoticeOpen && (
        <ModalDefault
          icon={<FaCircleExclamation />}
          setModalOpen={setModalNoticeOpen}
          title="Limite de estudos"
          styleContent={{
            maxWidth: 500,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TitleModal>LIMITE DE ESTUDOS</TitleModal>
            <SubTitle
              style={{
                textAlign: "center",
                marginBlock: 14,
              }}
            >
              O parâmetro para criar estudos está ativado. Desative se deseja
              criar mais alfinetes.
            </SubTitle>
          </div>
        </ModalDefault>
      )}
    </>
  );
}

export default ReactMap;
