import { Select } from "antd";

import { ButtonDefault } from "../../../../../../components/Button/ButtonDefault";
import { HiEye } from "react-icons/hi2";
import { FaTrash } from "react-icons/fa";
import "./InterestOptions.css";
import { InterestProps } from "../PointsOfInterest";
import { useState } from "react";
import { HiEyeOff } from "react-icons/hi";
import { PolygonParamList } from "../../../../../../Types/interfacs";

interface InterestOptionsProps {
  title: string;
  setPoints: React.Dispatch<
    React.SetStateAction<{ [key: string]: InterestProps[] }>
  >;
  setInterests: React.Dispatch<React.SetStateAction<string[]>>;
  setPolygon: React.Dispatch<React.SetStateAction<PolygonParamList>>;
  // points: InterestProps[];
}

export function InterestOptions({
  title,
  setPoints /*, points*/,
  setInterests,
  setPolygon,
}: InterestOptionsProps) {
  const [visible, setVisible] = useState(true);

  const handleDelete = () => {
    setPoints((prevMarkersGoogle) => {
      const newMarkersGoogle = { ...prevMarkersGoogle };
      eliminaDadosDosPins(newMarkersGoogle[title]);
      delete newMarkersGoogle[title];
      return newMarkersGoogle;
    });
    setInterests((prevInterests) => {
      return prevInterests.filter((interest) => interest !== title);
    });
  };
  function eliminaDadosDosPins(studiesData: InterestProps[]) {
    setPolygon((oldPolygon) => {
      for (let index = 0; index < studiesData.length; index++) {
        const p = studiesData[index];
        for (let i = 0; i < oldPolygon.pins.length; i++) {
          const areaPinsGroup = oldPolygon.pins[i];
          if (areaPinsGroup.id === p.index) {
            for (let j = 0; j < areaPinsGroup.values.length; j++) {
              const keywordHolder = areaPinsGroup.values[j];
              if (p.keyword === keywordHolder.keyword) {
                oldPolygon.pins[i].values.splice(j, 1);
                break;
              }
            }
          }
        }
      }
      return { ...oldPolygon };
    });
  }

  const handleVisible = () => {
    setPoints((prevMarkersGoogle) => ({
      ...prevMarkersGoogle,
      [title]: prevMarkersGoogle[title].map((interest) => {
        return {
          ...interest,
          visible: !visible,
        };
      }),
    }));
    setVisible(!visible);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingTop: 10,
      }}
    >
      <Select
        className="select"
        disabled
        value={title}
        style={{
          flex: 1,
        }}
        popupMatchSelectWidth={280}
      ></Select>

      <ButtonDefault
        style={{
          borderRadius: "15px",
        }}
        icon={visible ? <HiEye /> : <HiEyeOff />}
        size={"small"}
        color={"secondary"}
        onClick={handleVisible}
      />
      <ButtonDefault
        style={{
          borderRadius: "15px",
          margin: 0,
        }}
        icon={<FaTrash />}
        size={"small"}
        color={"secondary"}
        onClick={handleDelete}
      />
    </div>
  );
}
