import GA4 from "react-ga4";
import { cnaes } from "../../../../data/dataFilters/cnaes";
import {
  portes,
  translatePortArray,
} from "../../../../data/dataFilters/portes";
import { useState } from "react";
import {
  capitais,
  translateCSArray,
} from "../../../../data/dataFilters/capitais";
import { MapData } from "../../../../services/mapData";
import getQuadPins from "../../../../services/pinsbyfilter";
import {
  getDadosTotalizados,
  salvaLog,
} from "../../../../services/getOptionsData";
import { UserToken } from "../../../../services/userToken";
import { Title } from "../../../../components/typography/Title";
import { SubTitle } from "../../../../components/typography/SubTitle";
import { Filter } from "./components/Filter";
import { ButtonLink } from "../../../../components/Button/ButtonLink";
import { estadosMunicipios } from "../../../../data/dataFilters/estadosMunicipios";
import { datasDeInicio } from "../../../../data/dataFilters/datasDeInicio";
import { Divider } from "antd";
import { Tags } from "./components/Tags";
import { Bar } from "react-chartjs-2";
import fonts from "../../../../config/fonts";
import { filterOutbounds } from "../../../../utils/checkpoly";
import { useEffect } from "react";
import { ModalDefault } from "../../../../components/Modal/ModalDefault";
import { TitleModal } from "../../../../components/typography/TitleModal";
import { ButtonDefault } from "../../../../components/Button/ButtonDefault";
import { FaCircleExclamation } from "react-icons/fa6";
import { colors } from "../../../../assets/colors";

const colorSpan = colors.default.primary.content;

const filterCnaeOptions = cnaes.map((element, i) => ({
  value: element.cnae + ";" + element.descricao,
  label: element.cnae + ";" + element.descricao,
  key: i,
}));

const filterSizeOptions = [];
const filterCSOptions = [];
const filterStatesOptions = [];
const filterCitiesOptions = [];
const filterInicioAtvOptions = [];

var labelsCS = [];
var labelsPort = [];
var labelsInicioAtv = [];
var labelsEstado = [];
var total = 0;
var totalByArea = [];
var pendingMarkers = [];
var cargas = 0;
var pendingTotalsCS = [];
var pendingTotalsPort = [];
var pendingInicioAtv = [];
var pendingEstado = [];
var chargesTotals = 0;
var totaisPorCnae = new Map();
var addedTotais = [];
var setMarkersON = true;

function setPortsInTheCorrectOrder() {
  var i = 0;
  portes.forEach((element) => {
    if (i === 1) {
      filterSizeOptions.push({
        value: element,
        label: element,
        key: 2,
        indice: i,
      });
    } else if (i === 2) {
      filterSizeOptions.push({
        value: element,
        label: element,
        key: 1,
        indice: i,
      });
    } else {
      filterSizeOptions.push({
        value: element,
        label: element,
        key: i,
        indice: i,
      });
    }
    i++;
  });
}
setPortsInTheCorrectOrder();

export function DadosDasEmpresas(props) {
  const {
    polygon,
    areaList,
    markers,
    setMarkers,
    CNAEPesquisado,
    setCNAEPesquisado,
    selectedArea,
    selectedFilter,
    setSelectedFilter,
    totalStudyResults,
    updateStudyResults,
    updateStudyResultsCnaes,
  } = props;

  useEffect(() => {
    onSubmit();
  }, [selectedArea]);

  const [loading, setLoading] = useState(false);
  const [maisFiltros, setMaisFiltros] = useState(false);
  const [noticeButton, setNoticeButton] = useState(false);
  const [modalNoticeOpen, setModalNoticeOpen] = useState(false);

  var i = 0;
  if (filterSizeOptions.length === 0) {
    portes.forEach((element) => {
      if (i === 1) {
        filterSizeOptions.push({
          value: element,
          label: element,
          key: 2,
          indice: i,
        });
      } else if (i === 2) {
        filterSizeOptions.push({
          value: element,
          label: element,
          key: 1,
          indice: i,
        });
      } else {
        filterSizeOptions.push({
          value: element,
          label: element,
          key: i,
          indice: i,
        });
      }
      i++;
    });
  }

  i = 0;
  if (filterCSOptions.length === 0) {
    capitais.forEach((element) => {
      filterCSOptions.push({ value: element, label: element, key: i });
      i++;
    });
  }

  i = 0;
  if (filterStatesOptions.length === 0) {
    const states = [];
    estadosMunicipios.forEach((element) => {
      states.push(element.estado);
    });
    states.sort();
    states.forEach((element) => {
      filterStatesOptions.push({ value: element, label: element, key: i });
      i++;
    });
  }

  i = 0;
  if (filterCitiesOptions.length === 0) {
    const cities = [];
    estadosMunicipios.forEach((element) => {
      element.municipios.forEach(({ municipio }) => {
        cities.push(municipio);
      });
    });
    cities.sort();
    cities.forEach((element) => {
      filterCitiesOptions.push({ value: element, label: element, key: i });
      i++;
    });
  }

  if (filterInicioAtvOptions.length === 0) {
    const inicioAtv = [];
    datasDeInicio.forEach((element) => {
      inicioAtv.push(element);
    });
    inicioAtv.sort();
    inicioAtv.forEach((element) => {
      filterInicioAtvOptions.push({ value: element, label: element, key: i });
      i++;
    });
  }

  polygon.redoCNAESearch = () => {
    if (CNAEPesquisado) {
      setMarkersON = false;
      doSearch();
    } else {
      polygon.afterCNAE();
    }
  };

  const doSearch = () => {
    labelsCS = [];
    labelsPort = [];
    total = 0;
    totalByArea = [];
    pendingMarkers = [];
    pendingTotalsCS = [];
    pendingTotalsPort = [];
    pendingInicioAtv = [];
    pendingEstado = [];
    chargesTotals = 0;
    addedTotais = [];
    for (let index = 0; index < capitais.length; index++) {
      const capitalsocial = capitais[index];
      labelsCS.push(capitalsocial);
    }
    for (let index = 0; index < filterSizeOptions.length; index++) {
      const porte = filterSizeOptions[index];
      labelsPort.push(porte.label);
    }

    if (!setMarkersON) {
      polygon.centerAreas(areaList);
      setTimeout(async () => {
        cargas = areaList.length;
        chargesTotals = areaList.length;
        for (let index = 0; index < areaList.length; index++) {
          const area = areaList[index];
          updateArea(area, index);
        }
      }, 3000);
    } else if (selectedArea.length > 0) {
      cargas = selectedArea.length;
      chargesTotals = selectedArea.length;
      markers.splice(0, markers.length);
      for (let index = 0; index < selectedArea.length; index++) {
        const area = selectedArea[index];
        updateArea(area, index);
      }
    } else {
      cargas = 1;
      chargesTotals = 1;
      markers.splice(0, markers.length);
      updateVisibleMap();
    }
  };

  function getParams(area) {
    var selStudyType = null;
    var selPoints = null;
    if (area) {
      selStudyType = area.studyType;
      selPoints = area.points;
    }
    return {
      x2: MapData.bounds._northEast.lng,
      y1: MapData.bounds._northEast.lat,
      x1: MapData.bounds._southWest.lng,
      y2: MapData.bounds._southWest.lat,
      level: MapData.zoom,
      filter: {
        model: {
          capitalSocial: translateCSArray(selectedFilter.selectedCS),
          cidade: selectedFilter.selectedCidades,
          cnae: selectedFilter.selectedCNAE,
          estado: selectedFilter.selectedEstados,
          inicioDeAtividade: selectedFilter.selectedIA,
          porte: translatePortArray(selectedFilter.selectedPort),
        },
        studyType: selStudyType,
        points: selPoints,
      },
    };
  }

  function getCnaeParams(cnae, area) {
    var selStudyType = null;
    var selPoints = null;
    if (area) {
      selStudyType = area.studyType;
      selPoints = area.points;
    }
    return {
      x2: MapData.bounds._northEast.lng,
      y1: MapData.bounds._northEast.lat,
      x1: MapData.bounds._southWest.lng,
      y2: MapData.bounds._southWest.lat,
      level: MapData.zoom,
      filter: {
        model: {
          capitalSocial: translateCSArray(selectedFilter.selectedCS),
          cidade: selectedFilter.selectedCidades,
          cnae: [cnae],
          estado: selectedFilter.selectedEstados,
          inicioDeAtividade: selectedFilter.selectedIA,
          porte: translatePortArray(selectedFilter.selectedPort),
        },
        studyType: selStudyType,
        points: selPoints,
      },
    };
  }

  function updateVisibleMap() {
    const params = getParams(null);
    getQuadPins(params, (response) => {
      if (response.length === 0) {
        setNoticeButton(true);
      } else {
        setNoticeButton(false);
      }
      pendingMarkers = [...pendingMarkers, ...response];
      cargas--;
      if (cargas === 0) {
        setMarkers(pendingMarkers);
        setCNAEPesquisado(true);
      }
    });
  }

  function updateArea(area, num) {
    const params = getParams(area);
    getQuadPins(params, (response) => {
      if (response.length === 0) {
        setNoticeButton(true);
      } else {
        setNoticeButton(false);
      }

      response = filterOutbounds(area, response);
      if (setMarkersON) {
        pendingMarkers = [...pendingMarkers, ...response];
      }

      cargas--;
      if (cargas === 0) {
        if (setMarkersON) {
          setMarkers(pendingMarkers);
          setCNAEPesquisado(true);
        }
        if (selectedFilter?.selectedCNAE?.length > 1) {
          cargas = selectedArea.length * selectedFilter.selectedCNAE.length;
          totaisPorCnae = new Map();
          for (
            let indexCnae = 0;
            indexCnae < selectedFilter.selectedCNAE.length;
            indexCnae++
          ) {
            const cnae = selectedFilter.selectedCNAE[indexCnae];
            totaisPorCnae.set(cnae, {
              total: 0,
              results: {
                graphCS: {
                  labels: labelsCS,
                  datasets: [],
                },
                graphPort: {
                  labels: labelsPort,
                  datasets: [],
                },
                total: 0,
                cnae: cnae + "",
              },
            });
            for (
              let indexArea = 0;
              indexArea < selectedArea.length;
              indexArea++
            ) {
              const areaC = selectedArea[indexArea];
              createGraphByCnae(cnae, areaC);
            }
          }
        }
      }
    });
    function createGraphByCnae(cnae, area) {
      const params = getCnaeParams(cnae, area);

      getDadosTotalizados(params.filter).then((val) => {
        const valsCS = [];
        const valsPort = [];
        for (let index = 0; index < capitais.length; index++) {
          valsCS.push(val.capitalSocial[index]);
        }
        for (let index = 0; index < filterSizeOptions.length; index++) {
          valsPort.push(val.porte[index]);
        }
        totaisPorCnae.get(cnae).results.total += val.total;
        totaisPorCnae.get(cnae).results.graphCS.datasets.push({
          label: "Capital Social",
          data: valsCS,
          borderColor: area.cor,
          backgroundColor: area.cor + "66",
        });
        totaisPorCnae.get(cnae).results.graphPort.datasets.push({
          label: "Porte",
          data: valsPort,
          borderColor: area.cor,
          backgroundColor: area.cor + "66",
        });
        cargas--;
        if (cargas === 0) {
          const trueResults = [];
          for (
            let indexCnae = 0;
            indexCnae < selectedFilter.selectedCNAE.length;
            indexCnae++
          ) {
            var key = selectedFilter.selectedCNAE[indexCnae];
            trueResults.push(totaisPorCnae.get(key).results);
          }
          updateStudyResultsCnaes(trueResults);
        }
        setLoading(false);
      });
    }
    getDadosTotalizados(params.filter).then((val) => {
      var currentselectedAreas = selectedArea;
      if (!setMarkersON) {
        currentselectedAreas = areaList;
      } else {
        if (selectedArea.length === 0) {
          return;
        }
      }
      total += val.total;
      totalByArea.push({
        area: area,
        total: val.total,
      });
      var totaltmp = 0;
      var iindex = 1969;

      if (addedTotais.indexOf(num) === -1) {
        addedTotais.push(num);
        pendingTotalsCS.push({
          label: "Capital Social",
          data: val.capitalSocial,
          borderColor: currentselectedAreas[num].cor,
          backgroundColor: currentselectedAreas[num].cor + "66",
        });
        pendingTotalsPort.push({
          label: "Porte",
          data: val.porte,
          borderColor: currentselectedAreas[num].cor,
          backgroundColor: currentselectedAreas[num].cor + "66",
        });
        totaltmp = 0;
        iindex = 1969;
        labelsInicioAtv = [];
        const arrayInicioAtv = [];
        for (let index = iindex; index < 2024; index++) {
          if (val.inicioDeAtividade[index] !== undefined) {
            totaltmp += val.inicioDeAtividade[index];
          }
          if ((index + "").endsWith("0")) {
            labelsInicioAtv.push(iindex + "/" + index);
            arrayInicioAtv.push(totaltmp);
            iindex = index + 1;
            totaltmp = 0;
          }
        }
        labelsInicioAtv.push("2021/2023");
        arrayInicioAtv.push(totaltmp);
        pendingInicioAtv.push({
          label: "Empresas:",
          data: arrayInicioAtv,
          borderColor: currentselectedAreas[num].cor,
          backgroundColor: currentselectedAreas[num].cor + "66",
        });
        labelsEstado = [];
        for (let index = 0; index < estadosMunicipios.length; index++) {
          const siglaestado = estadosMunicipios[index].estado;
          if (val.estado[siglaestado]) {
            labelsEstado.push(siglaestado);
            pendingEstado.push({
              label: siglaestado,
              data: [val.estado[siglaestado]],
              borderColor: currentselectedAreas[num].cor,
              backgroundColor: currentselectedAreas[num].cor + "66",
            });
          }
        }
      } else {
        const numIndex = addedTotais.indexOf(num);
        for (let index = 0; index < capitais.length; index++) {
          if (pendingTotalsCS[numIndex] !== undefined) {
            pendingTotalsCS[numIndex].data[index] =
              pendingTotalsCS[numIndex].data[index] + val.capitalSocial[index];
          }
        }
        for (let index = 0; index < filterSizeOptions.length; index++) {
          if (pendingTotalsPort[numIndex] !== undefined) {
            pendingTotalsPort[numIndex].data[index] =
              pendingTotalsPort[numIndex].data[index] + val.porte[index];
          }
        }
        var labelsIndex = 0;
        totaltmp = 0;
        for (let index = iindex; index < 2024; index++) {
          if (val.inicioDeAtividade[index] !== undefined) {
            totaltmp += val.inicioDeAtividade[index];
          }
          if ((index + "").endsWith("0")) {
            pendingInicioAtv[numIndex].data[labelsIndex] += totaltmp;
            totaltmp = 0;
            labelsIndex++;
          }
        }
        var indxEstado = 0;
        for (let index = 0; index < estadosMunicipios.length; index++) {
          if (pendingEstado[indxEstado] !== undefined) {
            const siglaestado = estadosMunicipios[index].estado;
            if (val.estado[siglaestado]) {
              pendingEstado[indxEstado].data[0] += val.estado[siglaestado];
              indxEstado++;
            }
          }
        }
      }
      chargesTotals--;
      if (chargesTotals === 0) {
        updateStudyResults({
          graphCS: {
            labels: labelsCS,
            datasets: pendingTotalsCS,
          },
          graphPort: {
            labels: labelsPort,
            datasets: pendingTotalsPort,
          },
          graphInicioAtv: {
            labels: labelsInicioAtv,
            datasets: pendingInicioAtv,
          },
          graphEstado: {
            labels: labelsEstado,
            datasets: pendingEstado,
          },
          totalByArea: totalByArea,
          total: total,
          cnae: "",
        });
        setLoading(false);
        pendingTotalsCS = [];
        pendingTotalsPort = [];
        total = 0;
        if (!setMarkersON) {
          polygon.afterCNAE();
          setMarkersON = true;
        }
      }
    });
  }

  const onSubmit = () => {
    salvaLog({
      action: "Clicou Aplicar Filtros (Empresas)",
      params: "",
    }).then((result) => {});
    if (allFilters.length > 0) {
      setLoading(true);
      doSearch();
    } else {
      if (setMarkersON) {
        setMarkers([]);
        setCNAEPesquisado(false);
      }
    }
  };

  const allFilters = [
    ...selectedFilter.selectedCNAE,
    ...selectedFilter.selectedCidades,
    ...selectedFilter.selectedEstados,
    ...selectedFilter.selectedCS,
    ...selectedFilter.selectedPort,
    ...selectedFilter.selectedIA,
  ];

  const cores = [
    "#524FAA",
    "#526BD1",
    "#6987C2",
    "#6C6EB9",
    "#5292D1",
    "#1E3378",
  ];

  function getOptions(nome) {
    return {
      indexAxis: "y",
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        title: {
          display: false,
          text: nome,
          font: {
            fontFamily: fonts.dinRegular,
            size: 10,
          },
        },
        legend: {
          display: false,
        },
        datalabels: {
          padding: {
            top: 4,
            bottom: 4,
            right: 6,
            left: 6,
          },
          align: "top",
          anchor: "end",
          backgroundColor: function () {
            return "transparent";
          },
          borderColor: function () {
            return null;
          },

          borderRadius: 15,
          color: "black",
          font: {
            family: fonts.regular,
            size: 11,
          },
          formatter: (value, context) => {
            if (!value) {
              return "";
            } else if (value >= 1000000) {
              const millionValue = (value / 1000000).toFixed(2) + "M";
              return millionValue.replace(".", ",");
            } else {
              const roundedNumber = Math.round(value * 100) / 100;

              const formattedNumber = roundedNumber.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              return formattedNumber;
            }
          },

          display: function () {
            return true;
          },
        },
        onHover: function () {
          this.update();
        },
      },
      maintainAspectRatio: false,
      scales: {
        y: {
          ticks: {
            callback: function (value, data) {
              // truncate the labels only in this axis
              const lbl = this.getLabelForValue(value);
              if (typeof lbl === "string" && lbl.length > 9) {
                return `${lbl.substring(0, 9)}...`;
              }
              return lbl;
            },
            font: {
              fontFamily: fonts.dinRegular,
            },
          },
        },
        x: {
          ticks: {
            font: {
              fontFamily: fonts.dinRegular,
            },
          },
        },
      },
    };
  }

  let idxColor = 0;

  function deleteFilter(selectedCategories, delval) {
    selectedCategories.forEach(({ type, selected, setSelected }) => {
      for (var idxN = 0; idxN < selected.length; idxN++) {
        if (selected[idxN] === delval) {
          selected.splice(idxN, 1);
          setSelected([...selected]);
        }
      }
    });
  }

  const onRemove = () => {
    setSelectedFilter({
      selectedCNAE: [],
      selectedCidades: [],
      selectedEstados: [],
      selectedCS: [],
      selectedPort: [],
      selectedIA: [],
    });
    while (allFilters.length > 0) {
      allFilters.pop();
    }
    onSubmit();
  };

  return (
    <>
      <div>
        <Title>Pesquisar empresas</Title>
        <SubTitle>
          Realize pesquisas por empresas no mapa, aplicando filtros e
          visualizando os resultados.
        </SubTitle>
        <Filter
          label="Digite aqui o CNAE"
          selectedType={selectedFilter.selectedCNAE}
          setSelected={(selectedCNAE) => {
            salvaLog({
              action: "Selecionou CNAE",
              params: "" + selectedCNAE,
            }).then((result) => {});
            setSelectedFilter({ ...selectedFilter, selectedCNAE });
          }}
          filter={filterCnaeOptions}
          searchByNumber={true}
          sortList={true}
          required={true}
        />
        {maisFiltros ? (
          <div>
            <ButtonLink onClick={() => setMaisFiltros(false)}>
              Esconder filtros
            </ButtonLink>
            <div style={{ padding: 10 }} />
            <Filter
              label="Estado:"
              selectedType={selectedFilter.selectedEstados}
              setSelected={(selectedEstados) => {
                salvaLog({
                  action: "Selecionou Estado",
                  params: "" + selectedEstados,
                }).then((result) => {});
                setSelectedFilter({ ...selectedFilter, selectedEstados });
              }}
              filter={filterStatesOptions}
              sortList={true}
              required={false}
            />
            <Filter
              label="Cidade:"
              selectedType={selectedFilter.selectedCidades}
              setSelected={(selectedCidades) => {
                salvaLog({
                  action: "Selecionou Cidade",
                  params: "" + selectedCidades,
                }).then((result) => {});
                setSelectedFilter({ ...selectedFilter, selectedCidades });
              }}
              filter={filterCitiesOptions}
              sortList={true}
              required={false}
            />
            <Filter
              label="Capital Social:"
              selectedType={selectedFilter.selectedCS}
              setSelected={(selectedCS) => {
                salvaLog({
                  action: "Selecionou Capital Social",
                  params: "" + selectedCS,
                }).then((result) => {});
                setSelectedFilter({ ...selectedFilter, selectedCS });
              }}
              filter={filterCSOptions}
              sortList={true}
              required={false}
            />
            <Filter
              label="Porte:"
              selectedType={selectedFilter.selectedPort}
              setSelected={(selectedPort) => {
                salvaLog({
                  action: "Selecionou Port",
                  params: "" + selectedPort,
                }).then((result) => {});
                setSelectedFilter({ ...selectedFilter, selectedPort });
              }}
              filter={filterSizeOptions}
              sortList={true}
              required={false}
            />
            <Filter
              label="Ano de início de atividade:"
              selectedType={selectedFilter.selectedIA}
              setSelected={(selectedIA) => {
                salvaLog({
                  action: "Selecionou Inicio Atividade",
                  params: "" + selectedIA,
                }).then((result) => {});
                setSelectedFilter({ ...selectedFilter, selectedIA });
              }}
              filter={filterInicioAtvOptions}
              sortList={true}
              required={false}
            />
          </div>
        ) : (
          <div>
            <ButtonLink onClick={() => setMaisFiltros(true)}>
              Mostrar mais filtros
            </ButtonLink>
          </div>
        )}

        <Divider
          style={{
            marginTop: "10px",
          }}
        />

        {noticeButton && (
          <>
            <ButtonDefault
              icon={<FaCircleExclamation />}
              style={{
                margin: 0,
                marginBottom: 10,
              }}
              color={"orange"}
              borderRadius={25}
              size="smallThree"
              title="Nenhum resultado encontrado"
              onClick={() => {
                setModalNoticeOpen(true);
              }}
            />
          </>
        )}
        <div className="container">
          <Title>Filtros selecionados</Title>

          {allFilters.length > 0 ? (
            <>
              <div id="tagsList">
                {allFilters.map((item, idx) => {
                  if (idxColor === 5) {
                    idxColor = 0;
                  }

                  return (
                    <Tags
                      label={item}
                      key={idx}
                      color={cores[idxColor++]}
                      onClick={() => {
                        const delval = allFilters[idx];
                        allFilters.splice(idx, 1);
                        onSubmit();
                        const selectedCategories = [
                          {
                            type: "cnaes",
                            selected: selectedFilter.selectedCNAE,
                            setSelected: (selectedCNAE) => {
                              setSelectedFilter({
                                ...selectedFilter,
                                selectedCNAE,
                              });
                            },
                          },
                          {
                            type: "states",
                            selected: selectedFilter.selectedEstados,
                            setSelected: (selectedEstados) => {
                              setSelectedFilter({
                                ...selectedFilter,
                                selectedEstados,
                              });
                            },
                          },
                          {
                            type: "cities",
                            selected: selectedFilter.selectedCidades,
                            setSelected: (selectedCidades) => {
                              setSelectedFilter({
                                ...selectedFilter,
                                selectedCidades,
                              });
                            },
                          },
                          {
                            type: "capitais",
                            selected: selectedFilter.selectedCS,
                            setSelected: (selectedCS) => {
                              setSelectedFilter({
                                ...selectedFilter,
                                selectedCS,
                              });
                            },
                          },
                          {
                            type: "size",
                            selected: selectedFilter.selectedPort,
                            setSelected: (selectedPort) => {
                              setSelectedFilter({
                                ...selectedFilter,
                                selectedPort,
                              });
                            },
                          },
                          {
                            type: "inicioAtv",
                            selected: selectedFilter.selectedIA,
                            setSelected: (selectedIA) => {
                              setSelectedFilter({
                                ...selectedFilter,
                                selectedIA,
                              });
                            },
                          },
                        ];

                        deleteFilter(selectedCategories, delval);

                        if (allFilters.length > 0) {
                          doSearch();
                        }
                      }}
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <div id="tagsList">
              <Tags label="Nenhum" color="#F2F2F2" labelColor="#B2B2B2" />
            </div>
          )}
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Title>
              <ButtonLink
                style={{
                  marginRight: 10,
                  marginBottom: 20,
                }}
                onClick={onSubmit}
              >
                {allFilters.length > 0
                  ? "Atualizar filtros"
                  : "Aplicar filtros"}
              </ButtonLink>
            </Title>
            <Title>
              <ButtonLink onClick={onRemove} color="#B2B2B2">
                Remover filtros
              </ButtonLink>
            </Title>
          </div>
        </div>
        <div>
          {totalStudyResults?.total > 0 && !loading && allFilters.length > 0 ? (
            <div
              style={{
                marginBlock: "40px",
              }}
            >
              <div>
                <p style={{ marginTop: "15px" }}>Dados da Área</p>
                <SubTitle>
                  Prévia de dados relacionados às empresas encontradas no
                  filtro.
                </SubTitle>
                <div style={{ marginBlock: "15px" }}>
                  <p>Total de Empresas</p>
                  <p
                    style={{
                      color: "#459CA0",
                      fontSize: "24px",
                      marginTop: "6px",
                    }}
                  >
                    {totalStudyResults.total}
                  </p>
                </div>
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                  <p style={{ fontFamily: fonts.dinRegular }}>
                    Total por Capital Social
                  </p>
                  <div
                    style={{
                      height: `calc(40px + ${
                        totalStudyResults.graphCS.datasets.length *
                        totalStudyResults.graphCS.datasets[0].data.length *
                        30
                      }px)`,
                    }}
                  >
                    <Bar
                      options={getOptions("Capital Social")}
                      data={totalStudyResults.graphCS}
                    />
                  </div>
                </div>
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                  <p style={{ fontFamily: fonts.dinRegular }}>
                    Total por Porte
                  </p>
                  <div
                    style={{
                      height: `calc(40px + ${
                        totalStudyResults.graphPort.datasets.length *
                        totalStudyResults.graphPort.datasets[0].data.length *
                        30
                      }px)`,
                    }}
                  >
                    <Bar
                      options={getOptions("Total por Porte")}
                      data={totalStudyResults.graphPort}
                    />
                  </div>
                </div>
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                  <p style={{ fontFamily: fonts.dinRegular }}>Estados</p>
                  <div
                    style={{
                      height: `calc(40px + ${
                        totalStudyResults.graphEstado.datasets.length *
                        totalStudyResults.graphEstado.datasets[0].data.length *
                        30
                      }px)`,
                    }}
                  >
                    <Bar
                      options={getOptions("Estados")}
                      data={totalStudyResults.graphEstado}
                    />
                  </div>
                </div>
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                  <p style={{ fontFamily: fonts.dinRegular }}>
                    Inicio de Atividades
                  </p>
                  <div
                    style={{
                      height: `calc(40px + ${
                        totalStudyResults.graphInicioAtv.datasets.length *
                        totalStudyResults.graphInicioAtv.datasets[0].data
                          .length *
                        30
                      }px)`,
                    }}
                  >
                    <Bar
                      options={getOptions("Inicio de Atividade")}
                      data={totalStudyResults.graphInicioAtv}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {modalNoticeOpen && (
        <ModalDefault
          icon={<FaCircleExclamation />}
          setModalOpen={setModalNoticeOpen}
          title="Nenhum resultado encontrado"
          styleContent={{
            maxWidth: 500,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TitleModal>SEM RESULTADOS</TitleModal>
            <SubTitle
              style={{
                textAlign: "center",
                marginBlock: 14,
              }}
            >
              A pesquisa foi realizada porém não houveram resultados para serem
              retornados. Geralmente isso acontece quando o estudo realizado
              está{" "}
              <span style={{ color: colorSpan }}>fora do "campo de visão"</span>{" "}
              ou{" "}
              <span style={{ color: colorSpan }}>
                o conjunto de filtros utilizados não encontrou nenhum resultado
                nas áreas de estudo.
              </span>
            </SubTitle>
          </div>
        </ModalDefault>
      )}
    </>
  );
}
