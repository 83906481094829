import { FaSearch, FaUserPlus } from "react-icons/fa";
import { ButtonDefault } from "../../../../../../components/Button/ButtonDefault";
import { CustomInput } from "../../../../../../components/Input/CustomInput";
import { UserByCompany } from "../../../../types";
import { useState } from "react";
import { CreateOrEditUser } from "../../../CreateOrEditUser/CreateOrEditUser";

interface UserSectionProps {
  searchUser: string;
  tokenCompany: string;
  setUsers: (value: UserByCompany[]) => void;
  setSearchUser: (value: string) => void;
  setMessage: (icon: string, title: string, msg: string) => void;
  canclose: boolean;
}

export function UserSection({
  searchUser,
  tokenCompany,
  setUsers,
  setSearchUser,
  setMessage: setMessage,
  canclose,
}: UserSectionProps) {
  const [modalToAddUserOpen, setModalToAddUserOpen] = useState(false);

  return (
    <>
      <div
        style={{
          width: "100%",
          paddingBottom: 20,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomInput
          onChange={(e) => {
            setSearchUser(e.target.value);
          }}
          placeholder="Pesquisar usuários"
          type="text"
          value={searchUser}
        />

        <div
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <ButtonDefault
            color="secondary"
            title="PESQUISAR"
            icon={<FaSearch />}
            size="medium"
            iconPosition="right"
            style={{
              width: 166,
              height: 48,
              marginLeft: 30,
            }}
            borderRadius={25}
            onClick={() => {}}
          />
          <ButtonDefault
            color="tertiary"
            title="ADICIONAR USUÁRIO"
            icon={<FaUserPlus />}
            iconPosition="right"
            size="medium"
            onClick={() => {
              setModalToAddUserOpen(true);
            }}
            style={{
              width: 247,
              height: 48,
              marginRight: 0,
            }}
            borderRadius={25}
          />
        </div>
      </div>

      {modalToAddUserOpen && (
        <CreateOrEditUser
          setUsers={setUsers}
          tokenCompany={tokenCompany}
          setModalOpen={setModalToAddUserOpen}
          setMessage={setMessage}
          canclose={canclose}
        />
      )}
    </>
  );
}
