import { useEffect, useState } from "react";
import { Divider } from "antd";
import "./Companies.css";
import { FiltersCompany } from "./components/FiltersCompany/FiltersCompany";
import { CompaniesList } from "./components/CompaniesList/CompaniesList";
import { InformationCompany } from "./components/InformationCompany/InformationCompany";
import {
  exportCSVFile,
  getCompanyByCnpj,
  getCompanyList,
  getExportCredits,
  getPagesBySize,
} from "../../services/getOptionsData";
import { PolygonParamList, PolygonProps } from "../../Types/interfacs";
import { AllFiltersProps } from "../Map/ReactMap";
import { exportCompaniesToCSV, exportTxtToCSV } from "./ExportExcel";
import { useAuth } from "../../contexts/AuthContext";
import { ModalDefault } from "../../components/Modal/ModalDefault";
import { TitleModal } from "../../components/typography/TitleModal";
import { SubTitle } from "../../components/typography/SubTitle";
import { FaCircleExclamation } from "react-icons/fa6";
import { colors } from "../../assets/colors";

export interface CompanyInfoProps {
  cnpj: string | null;
  matriz_filial: string | null;
  razao_social: string | null;
  nome_fantasia: string | null;
  cod_nat_juridica: string | null;
  data_inicio_ativ: string | null;
  cnae_fiscal: string | null;
  endereco: string | null;
  telefone1: string | null;
  telefone2: string | null;
  fax: string | null;
  email: string | null;
  capital_social: string | null;
  porte: string | null;
  cnaes_secundarios: string[] | null;
  estado: string | null;
  cidade: string | null;
  lat: string | null;
  lng: string | null;
  participante_edital: string | null;
}

export interface AreasProps {
  name: string;
  color: string;
  studyType: string;
  points: number[];
}

export interface ConfirmaDados {
  titulo: string;
  mensagem1: string;
  mensagem2: string;
  mensagem3: string;
  mensagem4: string;
  mensagem5: string;
  valor1: string;
  valor2: string;
  valor3: string;
  funcaoAoConfirmar: (nome: string) => void;
  funcaoAoCancelar: () => void;
}

export interface DownloadParams {
  amount: number;
  model: {
    model: {
      capitalSocial: string[];
      cidade: string[];
      cnae: string[];
      estado: string[];
      inicioDeAtividade: string[];
      porte: string[];
    };
  };
  name: string;
}

interface CompaniesProps {
  polygon: PolygonParamList;
  allFilters: AllFiltersProps;
  setAllFilters: React.Dispatch<React.SetStateAction<AllFiltersProps>>;
}

export function Companies({
  polygon,
  allFilters,
  setAllFilters,
}: CompaniesProps) {
  const [modalNoticeOpen, setModalNoticeOpen] = useState(false);
  const [variableCompaniesList, setVariableCompaniesList] = useState<
    Array<{ label: string; cnpj: string }>
  >([]);

  const [pageSelected, setPageSelected] = useState<number>(0);
  const [selectedInfo, setSelectedInfo] = useState<CompanyInfoProps | null>(
    null
  );
  const { user } = useAuth();

  const [areaSelected, setAreaSelected] = useState<AreasProps | null>(null);
  const [mostraConfirma, setMostraConfirma] = useState(false);
  const [confirmaDados, setConfirmaDados] = useState<ConfirmaDados | null>(
    null
  );

  const [areas, setAreas] = useState<AreasProps[] | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (variableCompaniesList.length === 0) return;
    handleSeachFilters();
  }, [pageSelected]);

  useEffect(() => {
    if (variableCompaniesList.length === 0) {
      handleSeachFilters();
    }
  }, [variableCompaniesList]);

  const handleSeachFilters = () => {
    setLoading(true);
    const model = {
      ...allFilters,
    };

    const points = areaSelected?.points;
    const studyType = areaSelected?.studyType;

    getCompanyList({
      model: { model, points, studyType }, //back-end recebe dessa forma não sei pq
      pag: pageSelected,
      size: 25,
    }).then((response: Array<{ d: string; f: string }>) => {
      if (response) {
        const newVariableCompaniesList = response.map((item) => {
          return {
            label: item.d,
            cnpj: item.f,
          };
        });
        setVariableCompaniesList([
          ...variableCompaniesList,
          ...newVariableCompaniesList,
        ]);
      }
      setLoading(false);
    });
  };
  /*
  const handleExportFilters = (
    setMessage: (title: string, message: string) => void
  ) => {
    //
    const results: CompanyInfoProps[] = [];
    for (let index = 0; index < variableCompaniesList.length; index++) {
      const element = variableCompaniesList[index];
      getCompanyByCnpj({ cnpj: element.cnpj }).then((response) => {
        results.push(response[0]);
        if (results.length === variableCompaniesList.length) {
          exportCompaniesToCSV(results, setMessage);
        }
      });
    }
  };*/

  var allresponses: Array<{ d: string; f: string }> = [];
  const resultsPerPage = 10000;
  const checaCreditosDeExportacao = (
    setMessage: (title: string, message: string) => void,
    setExporting: (value: boolean) => void
  ) => {
    const idcompany = user?.company;
    getExportCredits({ id: idcompany }).then((data) => {
      const creditAvaliable = data.credits + data.extraCredits;
      if (creditAvaliable > 0) {
        allresponses = [];
        setMessage("Procurando empresas!", "");
        specialSeachFilters(setMessage, creditAvaliable, data.useCredits, 0);
      } else {
        setExporting(false);
        setModalNoticeOpen(true);
      }
    });
  };
  const specialSeachFilters = (
    setMessage: (title: string, message: string) => void,
    creditsAvailable: number,
    useCredits: boolean,
    page: number
  ) => {
    if (allFilters.cnae.length === 0) {
      return;
    }
    const model = {
      ...allFilters,
    };

    const points = areaSelected?.points;
    const studyType = areaSelected?.studyType;
    setMessage("Procurando...", "");
    getCompanyList({
      model: { model, points, studyType }, //back-end recebe dessa forma não sei pq
      pag: page,
      size: resultsPerPage,
    }).then((response: Array<{ d: string; f: string }>) => {
      if (response) {
        allresponses = [...allresponses, ...response];
        setMessage(
          "Procurando empresas!",
          allresponses.length + " encontradas..."
        );
        if (response.length < resultsPerPage - 1) {
          if (useCredits) {
            if (creditsAvailable - allresponses.length > 0) {
              setConfirmaDados({
                titulo: "Confirma Exportação?",
                mensagem1: "Créditos disponíveis:",
                valor1: creditsAvailable + "",
                mensagem2: "Créditos necessários:",
                valor2: allresponses.length + "",
                mensagem3: "Saldo após exportação:",
                valor3: creditsAvailable - allresponses.length + "",
                mensagem4: "",
                mensagem5: "Deseja continuar?",
                funcaoAoConfirmar: (nome) => {
                  const params: DownloadParams = {
                    amount: allresponses.length,
                    model: {
                      model: model,
                    },
                    name: nome ? nome : "export",
                  };
                  setMessage("Exportando arquivo...", "");
                  exportCSVFile(params).then((response) => {
                    exportTxtToCSV(response, setMessage);
                    /*
                    setMessage("Arquivo Exportado com sucesso!", "");
                    setMessage("list", response);*/
                  });
                  /*
                  const newVariableCompaniesList = allresponses.map((item) => {
                    return {
                      label: item.d,
                      cnpj: item.f,
                    };
                  });
                  setMessage(
                    "Procurando...",
                    "Encontrado " +
                      newVariableCompaniesList.length +
                      " resultados."
                  );
                  const results: CompanyInfoProps[] = [];
                  loadPack(newVariableCompaniesList, 0, results, setMessage);
                  */
                },
                funcaoAoCancelar: () => {
                  setMessage("OK", "OK");
                },
              });
            } else {
              setConfirmaDados({
                titulo: "Confirma Exportação?",
                mensagem1: "Créditos disponíveis:",
                valor1: creditsAvailable + "",
                mensagem2: "Créditos necessários:",
                valor2: allresponses.length + "",
                mensagem3: "Saldo após exportação:",
                valor3: "0",
                mensagem4:
                  "Devido a falta de créditos somente " +
                  creditsAvailable +
                  " empresas serão exportadas.",
                mensagem5: "Deseja continuar mesmo assim?",
                funcaoAoConfirmar: (nome: string) => {
                  const params: DownloadParams = {
                    amount: creditsAvailable,
                    model: {
                      model: model,
                    },
                    name: nome ? nome : "export",
                  };
                  setMessage("Exportando arquivo...", "");
                  exportCSVFile(params).then((response) => {
                    exportTxtToCSV(response, setMessage);
                    /*
                    setMessage("Arquivo Exportado com sucesso!", "");
                    setMessage("list", response);*/
                  });

                  /*
                  //vem 1 resultado a menos que o pedido
                  if (allresponses.length > creditsAvailable) {
                    allresponses.splice(
                      creditsAvailable,
                      allresponses.length - creditsAvailable
                    );
                  }

                  const newVariableCompaniesList = allresponses.map((item) => {
                    return {
                      label: item.d,
                      cnpj: item.f,
                    };
                  });
                  setMessage(
                    "Procurando...",
                    "Encontrado " +
                      newVariableCompaniesList.length +
                      " resultados."
                  );
                  const results: CompanyInfoProps[] = [];
                  loadPack(newVariableCompaniesList, 0, results, setMessage);
                  */
                },
                funcaoAoCancelar: () => {
                  setMessage("OK", "OK");
                },
              });
            }
          } else {
            const newVariableCompaniesList = allresponses.map((item) => {
              return {
                label: item.d,
                cnpj: item.f,
              };
            });
            setMessage(
              "Procurando...",
              "Encontrado " + newVariableCompaniesList.length + " resultados."
            );
            const results: CompanyInfoProps[] = [];
            loadPack(newVariableCompaniesList, 0, results, setMessage);
          }
          setMostraConfirma(true);
        } else {
          specialSeachFilters(
            setMessage,
            creditsAvailable,
            useCredits,
            page + 1
          );
        }
      }
    });
  };

  function loadPack(
    newVariableCompaniesList: {
      label: string;
      cnpj: string;
    }[],
    start: number,
    results: CompanyInfoProps[],
    setMessage: (title: string, message: string) => void
  ) {
    const packResults: any[] = [];
    const limit = Math.min(10, newVariableCompaniesList.length - start);

    for (let index = 0; index < limit; index++) {
      const element = newVariableCompaniesList[index + start];
      getCompanyByCnpj({ cnpj: element.cnpj }).then((response) => {
        packResults.push(response[0]);
        if (packResults.length === limit) {
          results.push(...packResults);
          setMessage(
            "Carregando",
            results.length + "/" + newVariableCompaniesList.length
          );
          if (results.length === newVariableCompaniesList.length) {
            exportCompaniesToCSV(results, setMessage);
          } else {
            loadPack(newVariableCompaniesList, start + 10, results, setMessage);
          }
        }
      });
    }
  }

  useEffect(() => {
    const { polygonList, radiusList, rectangleList, lineList } = polygon;

    const arraysObject = {
      polygonList,
      radiusList,
      rectangleList,
      lineList,
    };
    const points = Object.values(arraysObject).flatMap((item) => {
      return item.map((item: PolygonProps) => {
        return {
          points:
            item.type === "RADIUS_STUDY"
              ? [...item.points.flat(), item.ray]
              : [...item.points.flat()],

          studyType: item.type,
          color: item.color,
          name:
            item.type === "RADIUS_STUDY"
              ? "Raio"
              : item.type === "RECTANGLE_STUDY"
              ? "Retângulo"
              : "Polígono",
        } as AreasProps;
      });
    });

    setAreas(points);
  }, [polygon]);

  return (
    <>
      <div
        id="container-companies"
        style={{
          position: "absolute",
          zIndex: 2000,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 32,
          backgroundColor: "#FFFFFF",
          overflow: "hidden",
        }}
      >
        <FiltersCompany
          areas={areas}
          allFilters={allFilters}
          setAllFilters={setAllFilters}
          setAreaSelected={setAreaSelected}
          handleSeachFilters={handleSeachFilters}
          handleExportFilters={checaCreditosDeExportacao}
          mostraConfirma={mostraConfirma}
          setMostraConfirma={setMostraConfirma}
          confirmaDados={confirmaDados}
          setVariableCompaniesList={setVariableCompaniesList}
          setPageSelected={setPageSelected}
        />

        <Divider />

        <div
          style={{
            overflow: "hidden",
            display: "flex",
          }}
        >
          <CompaniesList
            loading={loading}
            setPageSelected={setPageSelected}
            setSelectedInfo={setSelectedInfo}
            variableCompaniesList={variableCompaniesList}
          />

          <Divider
            type="vertical"
            style={{
              height: "100%",
              margin: "0 32px",
            }}
          />

          <InformationCompany selectedInfo={selectedInfo} />
        </div>
      </div>
      {modalNoticeOpen && (
        <ModalDefault
          icon={<FaCircleExclamation />}
          setModalOpen={setModalNoticeOpen}
          title="Limite de estudos"
          styleContent={{
            maxWidth: 500,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TitleModal>CRÉDITOS INSUFICIENTES</TitleModal>
            <SubTitle
              style={{
                textAlign: "center",
                marginBlock: 14,
              }}
            >
              Você possui um limite de créditos para exportação, entre em
              contato com o administrador para rever seu limite{" "}
              <a
                href=" https://wa.me/5548991158147?text=Tenho+uma+d%C3%BAvida+em+rela%C3%A7%C3%A3o+ao+Deepen+Maps"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: colors.default.primary.content,
                  textDecoration: "underline",
                }}
              >
                clicando aqui
              </a>{" "}
              .
            </SubTitle>
          </div>
        </ModalDefault>
      )}
    </>
  );
}
