import { FaMapMarkedAlt, FaMapMarkerAlt } from "react-icons/fa";
import { TitleModal } from "../../../../../components/typography/TitleModal";
import { SubTitle } from "../../../../../components/typography/SubTitle";
import { ButtonLink } from "../../../../../components/Button/ButtonLink";
import { Icon } from "../../../../../components/icon/Icon";
import { FilesIcon } from "../../../../../components/icon/FilesIcon";
import { colors } from "../../../../../assets/colors";
import { useState } from "react";
import { Divider } from "antd";
import { ButtonDefault } from "../../../../../components/Button/ButtonDefault";
import { CustomInput } from "../../../../../components/Input/CustomInput";
import { VariableSelectOption } from "../../../../../Types/Variable";
import { BestAreaVariablesParams } from "../../../../../Types/Params";
import { Shapes } from "../../../../../Types/Shapes";
import GA4 from "react-ga4";
import { ModalDefault } from "../../../../../components/Modal/ModalDefault";
import { UserToken } from "../../../../../services/userToken";
import { AutoComplete } from "../../../../../components/AutoComplete/AutoComplete";
import { salvaLog } from "../../../../../services/getOptionsData";

interface ModalBestAreaProps {
  limit: number;
  variableOptions?: VariableSelectOption[];
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  paramsToBestAreaVariables: BestAreaVariablesParams;
  setParamsToBestAreaVariables: React.Dispatch<
    React.SetStateAction<BestAreaVariablesParams>
  >;
  setShapeActive: React.Dispatch<React.SetStateAction<Shapes>>;
}

export function ModalBestArea({
  limit,
  setModalOpen,
  variableOptions,
  paramsToBestAreaVariables,
  setParamsToBestAreaVariables,
  setShapeActive,
}: ModalBestAreaProps) {
  const [labelMax, setLbelMax] = useState(
    paramsToBestAreaVariables.labelMax ?? ""
  );
  const [labelMin, setLabelMin] = useState(
    paramsToBestAreaVariables.labelMin ?? ""
  );
  const [nuAreas, setNuAreas] = useState(
    paramsToBestAreaVariables.nuAreas !== 0
      ? String(paramsToBestAreaVariables.nuAreas)
      : ""
  );
  const [maxRadiusKM, setMaxRadiusKM] = useState(
    paramsToBestAreaVariables.maxRadiusKM !== 0
      ? String(paramsToBestAreaVariables.maxRadiusKM)
      : ""
  );

  const full =
    labelMax !== "" && labelMin !== "" && nuAreas !== "" && maxRadiusKM !== "";

  return (
    <ModalDefault
      icon={<FaMapMarkedAlt />}
      setModalOpen={setModalOpen}
      title="Melhores áreas (Variáveis)"
      styleContent={{
        maxWidth: 546,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TitleModal style={{ fontSize: "22px" }}>VARIÁVEIS</TitleModal>
        <SubTitle
          style={{
            textAlign: "center",
            marginBottom: 18,
            lineHeight: "23px",
          }}
        >
          Expanda para as melhores áreas usando variáveis da sua persona como
          referência. Encontre regiões com perfis semelhantes a endereços de
          sucesso
        </SubTitle>

        {/* implementar depois
        <ButtonLink
          underline={false}
          style={{
            fontWeight: "bold",
          }}
        >
          REUTILIZAR VARIÁVEIS ANTERIORES&nbsp;
          <Icon icon={<FilesIcon />} color={colors.link} />
        </ButtonLink>
        */}
        <div style={{ width: "100%", marginTop: 16 }}>
          <AutoComplete
            searchFilter={(text, options) => {
              return options
                ?.map((item: any) => ({
                  ...item,
                  options: item.options.filter((option: any) =>
                    option.label.toUpperCase().includes(text.toUpperCase())
                  ),
                }))
                .filter((item) => item.options.length > 0);
            }}
            placeholder="Maximizar"
            options={variableOptions}
            defaultValue={labelMax}
            onChange={(newvalue) => {
              setLbelMax(newvalue);
              salvaLog({
                action: "Selecionou Variável Máxima",
                params: "" + newvalue,
              }).then((result: any) => {});
            }}
          />
        </div>
        <div style={{ width: "100%", marginTop: 16 }}>
          <AutoComplete
            searchFilter={(text, options) => {
              return options
                ?.map((item: any) => ({
                  ...item,
                  options: item.options.filter((option: any) =>
                    option.label.toUpperCase().includes(text.toUpperCase())
                  ),
                }))
                .filter((item) => item.options.length > 0);
            }}
            placeholder="Minimizar"
            options={variableOptions}
            defaultValue={labelMin}
            onChange={(newvalue) => {
              setLabelMin(newvalue);
              salvaLog({
                action: "Selecionou Variável Mínima",
                params: "" + newvalue,
              }).then((result: any) => {});
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            marginTop: 16,
            display: "flex",
          }}
        >
          <CustomInput
            style={{
              marginRight: 8,
            }}
            autocomplete="off"
            value={nuAreas}
            onChange={(e) => {
              setNuAreas(e.target.value);
              salvaLog({
                action: "Selecionou número de áreas (Variáveis)",
                params: "" + e.target.value,
              }).then((result: any) => {});
            }}
            limitNumber={limit}
            placeholder="Número de estudos"
            type="number"
          />
          <CustomInput
            style={{
              marginLeft: 8,
            }}
            autocomplete="off"
            value={maxRadiusKM}
            onChange={(e) => {
              setMaxRadiusKM(e.target.value);
              salvaLog({
                action: "Selecionou tamanho dos raios (Variáveis)",
                params: "" + e.target.value,
              }).then((result: any) => {});
            }}
            placeholder="Estudos em Km²"
            type="number"
          />
        </div>
      </div>

      {/* implementar depois
      <SubTitle
        style={{
          textAlign: "center",
        }}
      >
        Não sabe quais variáveis usar?{" "}
        <ButtonLink underline={false}>Veja nossos recomendados.</ButtonLink>
      </SubTitle>
*/}
      <Divider style={{ margin: 24 }} />
      <ButtonDefault
        style={{
          marginRight: "auto",
          marginLeft: "auto",
          width: "auto",
          paddingInline: 30,
        }}
        disabled={!full}
        size="medium"
        title="SELECIONAR ÁREA NO MAPA"
        icon={<FaMapMarkerAlt />}
        iconPosition="right"
        border={true}
        color={full ? "secondary" : "disabled"}
        borderRadius={40}
        onClick={() => {
          setParamsToBestAreaVariables({
            labelMax,
            labelMin,
            nuAreas: Number(nuAreas),
            maxRadiusKM: Number(maxRadiusKM),
            rect: null,
          });
          setModalOpen(false);
          setShapeActive("bestArea");
        }}
      />
    </ModalDefault>
  );
}
