import fonts from "../../config/fonts";
import "./Checkbox.css";

interface CheckboxProps {
  id: string;
  name: string;
  label: string;
  onChange?: () => void;
  checked: boolean;
  color: string;
}

export function Checkbox({
  id,
  name,
  label,
  onChange,
  checked,
  color,
}: CheckboxProps) {
  return (
    <div className="container-checkbox">
      <input
        style={{
          color: color,
          borderColor: color,
         
        }}
        type="checkbox"
        name={name}
        id={id}
        checked={checked ? checked : false}
        readOnly
        onChange={() => {
          onChange && onChange();
        }}
      />
      <label
        style={{
          fontSize: 16,
          fontFamily: fonts.regular,
          color: color,
        }}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
}
