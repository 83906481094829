import L from "leaflet";

export const editIcon = L.icon({
  iconUrl: "edit-variable.png",
  iconSize: [35, 35],
  iconAnchor: [80, 45],
});

export const editActiveIcon = L.icon({
  iconUrl: "edit-variable-active.png",
  iconSize: [35, 35],
  iconAnchor: [80, 45],
});

export const moveIcon = L.icon({
  iconUrl: "move-area.png",
  iconSize: [35, 35],
  iconAnchor: [125, 45],
});

export const moveActiveIcon = L.icon({
  iconUrl: "move-area-active.png",
  iconSize: [35, 35],
  iconAnchor: [125, 45],
});

export const deleteIcon = L.icon({
  iconUrl: "delete.png",
  iconSize: [35, 35],
  iconAnchor: [35, 45],
});

export const deleteActiveIcon = L.icon({
  iconUrl: "delete-active.png",
  iconSize: [35, 35],
  iconAnchor: [35, 45],
});
