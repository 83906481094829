import {
  AreaListData,
  getRelatedArea,
} from "../Preview/components/AreaListData";
import {
  CompleteRDPolygon,
  GraphicsListByType,
  VariablePoly,
  GraphicsList,
} from "../Preview/components/graphs/graphTSInterface";

export function ordemDasSessoes() {
  return [
    {
      key: "Sociodemografia",
      read: "Sociodemografia",
      type: "Seção",
      subdivision: [
        { key: "Total de homens", read: "Total de Homens", type: "Inteiro" },
        { key: "Homens por idade", read: "Homens por Idade", type: "Inteiro" },
        { key: "Homens por renda", read: "Homens por Renda", type: "Inteiro" },
        {
          key: "Total de mulheres",
          read: "Total de Mulheres",
          type: "Inteiro",
        },
        {
          key: "Mulheres por idade",
          read: "Mulheres por Idade",
          type: "Inteiro",
        },
        {
          key: "Mulheres por renda",
          read: "Mulheres por Renda",
          type: "Inteiro",
        },
        { key: "Total de pessoas", read: "Total de Pessoas", type: "Inteiro" },
        {
          key: "Pessoas por idade",
          read: "Pessoas por Idade",
          type: "Inteiro",
        },
        {
          key: "Pessoas por renda",
          read: "Pessoas por Renda",
          type: "Inteiro",
        },
        {
          key: "Total de domicílios",
          read: "Total de Domicílios",
          type: "Inteiro",
        },
        {
          key: "Domicílios por faixa de renda",
          read: "Domicílios por Faixa de Renda",
          type: "Inteiro",
        },
        { key: "Renda Total", read: "Renda Total", type: "Moeda" },
        {
          key: "Renda total por faixa",
          read: "Renda Total por Faixa",
          type: "Moeda",
        },
      ],
    },
    {
      key: "Empresarial",
      read: "Empresarial",
      type: "Seção",
      subdivision: [
        {
          key: "Total de empresas",
          read: "Total de Empresas",
          type: "Inteiro",
        },
        {
          key: "Total de colaboradores",
          read: "Total de Colaboradores",
          type: "Inteiro",
        },
        {
          key: "Colaboradores homem",
          read: "Colaboradores Homens",
          type: "Inteiro",
        },
        {
          key: "Colaboradores mulher",
          read: "Colaboradores Mulheres",
          type: "Inteiro",
        },
        {
          key: "Colaboradores por idade",
          read: "Colaboradores por Idade",
          type: "Inteiro",
        },
        {
          key: "Colaboradores por salário",
          read: "Colaboradores por Renda",
          type: "Inteiro",
        },
      ],
    },
    {
      key: "Outros",
      read: "Outros",
      type: "Seção",
      subdivision: [
        { key: "Academias", read: "Academias", type: "Inteiro" },
        { key: "Clínicas", read: "Clínicas", type: "Inteiro" },
        { key: "Contabilidades", read: "Contabilidades", type: "Inteiro" },
        { key: "Farmácias", read: "Farmácias", type: "Inteiro" },
        { key: "Imobiliárias", read: "Imobiliárias", type: "Inteiro" },
        {
          key: "Lojas de Conveniência",
          read: "Lojas de Conveniência",
          type: "Inteiro",
        },
        { key: "Pet Shops", read: "Pet Shops", type: "Inteiro" },
        { key: "Restaurantes", read: "Restaurantes", type: "Inteiro" },
      ],
    },
    {
      key: "Potencial de Consumo",
      read: "Potencial de Consumo",
      type: "Seção",
      subdivision: [
        {
          key: "Potencial de Consumo para Alimentação fora do Domicílio",
          read: "Potencial de Consumo para Alimentação Fora do Domicílio",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Alimentação no Domicílio",
          read: "Potencial de Consumo para Alimentação no Domicílio",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Artigos de Limpeza",
          read: "Potencial de Consumo para Artigos de Limpeza",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Bebidas",
          read: "Potencial de Consumo para Bebidas",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Calçados",
          read: "Potencial de Consumo para Calçados",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Eletrodomésticos e Equipamentos",
          read: "Potencial de Consumo para Eletrodomésticos e Equipamentos",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Fumo",
          read: "Potencial de Consumo para Fumo",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Gastos com medicamentos",
          read: "Potencial de Consumo para Gastos com Medicamentos",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Gastos com veículo próprio",
          read: "Potencial de Consumo para Gastos com Veículo Próprio",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Higiene e cuidados pessoais",
          read: "Potencial de Consumo para Higiene e Cuidados Pessoais",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Livros e material escolar",
          read: "Potencial de Consumo para Livros e Material Escolar",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Manutenção do Lar",
          read: "Potencial de Consumo para Manutenção do Lar",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Materiais de contrução",
          read: "Potencial de Consumo para Materiais de Contrução",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Matrículas e mensalidades",
          read: "Potencial de Consumo para Matrículas e Mensalidades",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Mobiliários e artigos do Lar",
          read: "Potencial de Consumo para Mobiliários e Artigos do Lar",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Outras Despesas",
          read: "Potencial de Consumo para Outras Despesas",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Outras despesas com saúde",
          read: "Potencial de Consumo para Outras Despesas com Saúde",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Outras despesas com Vestuário",
          read: "Potencial de Consumo para Outras Despesas com Vestuário",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Recreação e cultura",
          read: "Potencial de Consumo para Recreação e Cultura",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Transporte Urbano",
          read: "Potencial de Consumo para Transporte Urbano",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Vestuário Confeccionado",
          read: "Potencial de Consumo para Vestuário Confeccionado",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo para Viagens",
          read: "Potencial de Consumo para Viagens",
          type: "Moeda",
        },
        {
          key: "Potencial de Consumo Total",
          read: "Potencial de Consumo Total",
          type: "Moeda",
        },
      ],
    },
    {
      key: "Taxas",
      read: "Taxas",
      type: "Seção",
      subdivision: [
        { key: "Gini", read: "Gini", type: "Decimal" },
        { key: "Índice de fluxo", read: "Índice de Fluxo", type: "Decimal" },
        {
          key: "Taxa de domicílio quitado",
          read: "Taxa de Domicílio Quitado",
          type: "Decimal",
        },
        { key: "Taxa de saúde", read: "Taxa de Saúde", type: "Decimal" },
        {
          key: "Taxa de urbanização",
          read: "Taxa de Urbanização",
          type: "Decimal",
        },
        {
          key: "Taxa de verticalização",
          read: "Taxa de Verticalização",
          type: "Decimal",
        },
      ],
    },
  ];
}

export function depara() {
  var base = ordemDasSessoes();
  const dp = new Map<string, string>();
  for (let index = 0; index < base.length; index++) {
    const element = base[index];
    dp.set(element.key, element.read);
    for (let i = 0; i < element.subdivision.length; i++) {
      const sub = element.subdivision[i];
      dp.set(sub.key, sub.read);
    }
  }
  return dp;
}

export function organize(
  todasfiguras: CompleteRDPolygon[],
  areaList: AreaListData[]
): GraphicsListByType[] {
  const sessoes: Map<string, Map<string, VariablePoly[]>> = new Map();
  const listadesessoes: GraphicsListByType[] = [];

  todasfiguras.forEach((element) => {
    const simplepoly = {
      area: element.area,
      centroid: element.centroid,
      error: element.error,
      index: element.index,
      radius: element.radius,
    };
    element.variableList.forEach((vary) => {
      const graficonome = vary.name;
      const sessao = vary.type;
      if (sessoes.has(sessao)) {
        const cursessao = sessoes.get(sessao);
        if (cursessao?.has(graficonome)) {
          cursessao.get(graficonome)?.push({
            variable: vary,
            poly: simplepoly,
            area: getRelatedArea(simplepoly.index, areaList),
          });
        } else {
          cursessao?.set(graficonome, [
            {
              variable: vary,
              poly: simplepoly,
              area: getRelatedArea(simplepoly.index, areaList),
            },
          ]);
        }
      } else {
        const mapagraficos = new Map<string, VariablePoly[]>();
        mapagraficos.set(graficonome, [
          {
            variable: vary,
            poly: simplepoly,
            area: getRelatedArea(simplepoly.index, areaList),
          },
        ]);
        sessoes.set(sessao, mapagraficos);
      }
    });
  });

  sessoes.forEach((type) => {
    const section: GraphicsListByType = { name: "", graphicsList: [] };
    type.forEach((graph) => {
      const newgraph: GraphicsList = {
        graphicname: "",
        measureUnit: "Integer",
        data: [],
      };
      graph.forEach((val) => {
        section.name = val.variable.type;
        newgraph.graphicname = val.variable.name;
        newgraph.data.push(val);
      });
      section.graphicsList.push(newgraph);
    });
    listadesessoes.push(section);
  });

  const listaDeSessoes = ordemDasSessoes();
  const listaordenada = [];
  if (listadesessoes.length > 0)
    for (let index = 0; index < listaDeSessoes.length; index++) {
      const target = listaDeSessoes[index];
      for (let indexx = 0; indexx < listadesessoes.length; indexx++) {
        const element = listadesessoes[indexx];
        if (target.key === element.name || target.read === element.name) {
          element.name = target.read;
          listaordenada.push(element);

          const graphlist = [];
          for (
            let indexxx = 0;
            indexxx < target.subdivision.length;
            indexxx++
          ) {
            const graph = target.subdivision[indexxx];
            for (
              let indexxxxx = 0;
              indexxxxx < element.graphicsList.length;
              indexxxxx++
            ) {
              const paragraph = element.graphicsList[indexxxxx];
              if (
                graph.key === paragraph.graphicname ||
                graph.read === paragraph.graphicname
              ) {
                paragraph.graphicname = graph.read;
                graphlist.push(paragraph);
                paragraph.measureUnit = graph.type;
                break;
              }
            }
          }
          element.graphicsList = graphlist;
          break;
        }
      }
    }
  return listaordenada;
}
