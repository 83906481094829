import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { colors } from "../../assets/colors";
import { SelectOptionRadio } from "../../Types/Variable";
import { CaretDownOutlined } from "@ant-design/icons";
import fonts from "../../config/fonts";

interface SelectProps {
  defaultValue?: string;
  placeholder?: React.ReactNode;
  options?: SelectOptionRadio[];
  onChange: (selectedValue: string) => void;
  borderRadius?: number;
  style?: CSSProperties;
}

type StylesObject = Record<string, CSSProperties>;

const backgroundColor = colors.default.primary.background;
const grayColor = colors.default.disabled.content;
const shadowColor = colors.shadow;

export function SelectDefault({
  options,
  defaultValue,
  borderRadius,
  placeholder,
  onChange,
  style,
}: SelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] =
    useState<React.ReactNode>(defaultValue);

  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  const handleOptionClick = (option: SelectOptionRadio) => {
    setSelectedValue(option.label || "");
    onChange(option.value || "");
    setIsOpen(false);
  };

  return (
    <div style={{ ...selectStyles.container, ...style }} ref={selectRef}>
      <div
        style={{ ...selectStyles.header, borderRadius: borderRadius || 5 }}
        onClick={toggleDropdown}
      >
        <div
          style={{
            ...selectStyles.selectedOption,
            color: selectedValue ? "black" : grayColor,
          }}
        >
          {selectedValue ? selectedValue : placeholder || "Selecione uma opção"}
        </div>
        <CaretDownOutlined
          style={{
            ...selectStyles.caretIcon,
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
          }}
          className={isOpen ? "open" : ""}
        />
      </div>
      {isOpen && options && (
        <div style={selectStyles.optionList}>
          {options.map((option) => (
            <div
              key={option.value}
              style={selectStyles.styleSuboptionItem}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const selectStyles: StylesObject = {
  container: {
    position: "relative",
    width: "100%",
    fontSize: fonts.sizes.medium,
    fontWeight: "normal",
    height: 48,
    color: grayColor,
  },
  header: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    padding: 8,
    paddingLeft: 12,
    border: `1px solid ${grayColor}`,
    display: "flex",
    alignItems: "center",
  },
  selectedOption: {
    marginRight: 8,
  },
  caretIcon: {
    marginLeft: "auto",
  },
  optionList: {
    position: "absolute",
    zIndex: 9999,
    top: "105%",
    width: "100%",
    border: `1px solid ${grayColor}`,
    borderRadius: 5,
    background: backgroundColor,
    boxShadow: `0px 2px 8px ${shadowColor}`,
    maxHeight: 300,
    overflowY: "auto",
    padding: 12,
    paddingTop: 0,
    paddingBottom: 0,
    color: "black",
  },
  styleSuboptionItem: {
    cursor: "pointer",
    height: 48,
    display: "flex",
    alignItems: "center",
  },
};
