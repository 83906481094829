import L from "leaflet";

export function getDistanceBetweenPoints(firstLatLng: any, secondLatLng: any) {
  const lat1 = L.latLng(firstLatLng).lat;
  const lon1 = L.latLng(firstLatLng).lng;
  const lat2 = L.latLng(secondLatLng).lat;
  const lon2 = L.latLng(secondLatLng).lng;
  // Fórmula de Haversine - https://www.movable-type.co.uk/scripts/latlong.html
  const R = 6371e3; // metros
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ em radianos
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c; // em metros

  return d;
}

export function getPointAtDistanceLimit(
  firstLatLng: any,
  secondLatLng: any,
  distanceLimit: number
) {
  const lat1 = L.latLng(firstLatLng).lat;
  const lon1 = L.latLng(firstLatLng).lng;
  const lat2 = L.latLng(secondLatLng).lat;
  const lon2 = L.latLng(secondLatLng).lng;

  const totalDistance = getDistanceBetweenPoints(firstLatLng, secondLatLng);

  if (totalDistance < distanceLimit) {
    // Se a distância total é menor que o limite, retornar o ponto final
    return secondLatLng;
  }

  // Calcular a razão da distância limite em relação à distância total
  const ratio = distanceLimit / totalDistance;

  // Calcular as coordenadas do ponto intermediário
  const intermediateLat = lat1 + ratio * (lat2 - lat1);
  const intermediateLon = lon1 + ratio * (lon2 - lon1);

  // Retornar as coordenadas do ponto intermediário
  return [intermediateLat, intermediateLon];
}
