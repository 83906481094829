import { FaBriefcase, FaCheckDouble, FaX } from "react-icons/fa6";
import { ModalDefault } from "../../../../components/Modal/ModalDefault";
import { SubTitleModal } from "../../../../components/typography/SubTitleModalReports";
import { TitleModal } from "../../../../components/typography/TitleModal";
import { Divider } from "antd";
import { useEffect, useState } from "react";
import { ButtonDefault } from "../../../../components/Button/ButtonDefault";
import { FaCheck, FaSave } from "react-icons/fa";
import { TableUsers } from "./components/TableUsers/TableUsers";
import { CompanyDetails } from "./components/CompanyDetails/CompanyDetails";
import { SelectionSection } from "./components/SelectionSection/SelectionSection";
import { Actions } from "./components/Actions/Actions";
import { UserSection } from "./components/UserSection/UserSection";
import { FileUploadSection } from "./components/FileUploadSection/FileUploadSection";
import { SelectOption, StatesUfProps } from "../../../../Types/Variable";
import {
  createCompany,
  getCompanyFilters,
  getCompanyInformations,
  getUsersByCompany,
  updateCompany,
  updateContract,
} from "../../../../services/getOptionsData";
import { Loading } from "../../../../components/Loading/Loading";
import { ContractResponse, UserByCompany } from "../../types";
import { estadosMunicipios } from "../../../../data/dataFilters/estadosMunicipios";

interface Props {
  setModalOpen: (value: boolean) => void;
  idCompany?: number;
  idContract?: number;
  setCompaniesContract: (value: ContractResponse[]) => void;
  reloadCompanies: () => void;
  setMessage: (icon: string, title: string, msg: string) => void;
  mensagemAparecendo: boolean;
}

export interface Model {
  cidade: string[];
  estado: string[];
  capitalSocial: string[];
  porte: string[];
  inicioDeAtividade: string[];
}

interface ContractRegion {
  id: number;
  sg: string;
  name: string;
  selected: boolean;
}

export interface NewInformationsCompany {
  segments: string[];
  companyRoles: string[];
  variables: number[];
  trial: boolean;
  countReports: string;
  limitStudies: string;
  credits: string;
  name: string;
  maxCompanyUsers: string;
  maxUsers: string;
  expirationTime: string;
  contractRegions: ContractRegion[];
  model: {
    cnae: string[];
    inicioDeAtividade: number[];
    capitalSocial: number[];
    porte: number[];
    estado: string[];
    cidade: string[];
  };
  nodefault: true;
}

interface UpdateContractData {
  expirationTime: string;
  enable: boolean;
  trial: boolean;
  limitStudies: number;
  countReports: number;
  credits: number;
  companyRoles: string[];
  contractRegions: ContractRegion[];
  model: {
    cnae: string[];
    inicioDeAtividade: number[];
    capitalSocial: number[];
    porte: number[];
    estado: string[];
    cidade: string[];
  };
  nodefault: true;
}

interface UpdateCompanyData {
  name: string;
  maxCompanyUsers: number;
  maxUsers: number;
  segments: string[];
  variables: number[];
}
var tryed = false;
export function ModalToAddOrEditCompany({
  setModalOpen,
  idCompany,
  idContract,
  setCompaniesContract,
  reloadCompanies,
  setMessage,
  mensagemAparecendo,
}: Props) {
  const [CompanyId, setCompanyId] = useState<number | undefined>(idCompany);
  const [ContractId, setContractId] = useState<number | undefined>(idContract);

  const [companyName, setCompanyName] = useState("");
  const [maxFranchisees, setMaxFranchisees] = useState("");
  const [maxUsers, setMaxUsers] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [segment, setSegment] = useState<string[]>([]);
  const [counties, setCounties] = useState<string[]>([]);
  const [state, setState] = useState<StatesUfProps[]>([]);
  const [variables, setVariables] = useState<number[]>([]);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [countReports, setCountReports] = useState("");
  const [credits, setCredits] = useState("");
  const [limitStudies, setLimitStudies] = useState("");
  const [trial, setTrial] = useState(false);
  const [active, setActive] = useState(false);

  const [loading, setLoading] = useState(true);

  const [searchUser, setSearchUser] = useState("");
  const [users, setUsers] = useState<UserByCompany[]>([]);
  const [tokenCompany, setTokenCompany] = useState("");
  const [idToSearchUsers, setIdToSearchUsers] = useState<number | undefined>();
  const [variableCounties, setVariableCounties] = useState<SelectOption[]>([]);
  const [countiesOptions, setCountiesOptions] = useState<SelectOption[]>([]);

  const editOrAdd = ContractId
    ? {
        titleModal: "Editar empresa",
        title: "EDITAR EMPRESA",
        subTitle: `Abaixo você pode atualizar ou alterar os dados da empresa ${companyName} disponível em nosso sistema`,
        buttonTitle: "ATUALIZAR DADOS DA EMPRESA",
      }
    : {
        titleModal: "Adicionar empresa",
        title: "ADICIONAR EMPRESA",
        subTitle:
          "Abaixo você insere os dados necessários para criar uma nova empresa em nosso sistema, adicionando todos os detalhes necessário para a mesma.",
        buttonTitle: "SALVAR EMPRESA",
      };

  if (idContract && !tryed && counties.length === 0) {
    tryed = true;
    getCompanyFilters(idContract).then((limiters) => {
      setCounties(limiters.model.cidade);
    });
  }

  useEffect(() => {
    const sortedByState = estadosMunicipios.sort((a, b) =>
      a.estado.localeCompare(b.estado)
    );

    const counties = sortedByState.map(({ estado, municipios }) => {
      const sortedMunicipios = municipios.sort((a, b) =>
        a.municipio.localeCompare(b.municipio)
      );

      return {
        label: estado,
        options: [
          ...sortedMunicipios.map((filteredMunicipio) => {
            return {
              label: filteredMunicipio.municipio,
              value: filteredMunicipio.municipio,
            };
          }),
        ],
      };
    });

    setVariableCounties(counties);
  }, []);

  useEffect(() => {
    const filteredCounties = variableCounties.filter((county) => {
      return state.some((state) => {
        return state.sg === county.label;
      });
    });

    setCountiesOptions(filteredCounties);
  }, [state]);

  useEffect(() => {
    if (ContractId) {
      getCompanyInformations(ContractId).then((company) => {
        const dataObj = new Date(company.expirationTime);

        let dia =
          dataObj.getUTCDate() < 10
            ? "0" + dataObj.getUTCDate()
            : dataObj.getUTCDate();
        let mes =
          dataObj.getUTCMonth() + 1 < 10
            ? "0" + (dataObj.getUTCMonth() + 1)
            : dataObj.getUTCMonth() + 1;
        const ano = dataObj.getUTCFullYear();

        const dataFormatada = dia + "/" + mes + "/" + ano;

        setCompanyName(company.company.name);
        setMaxFranchisees(String(company.company.maxCompanyUsers));
        setMaxUsers(String(company.company.maxUsers));
        setExpirationDate(dataFormatada);
        setTrial(company.trial);
        setActive(company.enable);

        const segments = company.company.segments.map((segment) => {
          return segment.name;
        });
        setSegment(segments);

        const states = company.contractRegions.map((state) => {
          return {
            id: state.id,
            sg: state.sg,
            name: state.name,
            selected: false,
          };
        });
        setState(states);

        const variables = company.company.variablePreferences.map(
          (variable) => {
            return variable.id;
          }
        );
        setVariables(variables);

        const permissions = company.companyRoles.map((role) => {
          return role.name;
        });
        setPermissions(permissions);

        setCountReports(String(company.countReports));

        setCredits(String(company.credits));

        setLimitStudies(String(company.limitStudies));

        setTokenCompany(company.company.activationKey);

        setLoading(false);

        setIdToSearchUsers(company.company.id);
      });
    }
  }, [ContractId]);

  useEffect(() => {
    if (idToSearchUsers && users.length === 0) {
      getUsersByCompany(idToSearchUsers).then((users) => {
        setUsers(users);
      });
    }
  }, [idToSearchUsers, users]);

  const handleSubmit = () => {
    var dateString = expirationDate;

    var partesData = dateString.split("/");
    var day = parseInt(partesData[0], 10);
    var mounth = parseInt(partesData[1], 10) - 1; // Lembre-se que os meses em JavaScript são baseados em zero (janeiro é 0)
    var year = parseInt(partesData[2], 10);

    var date = new Date(year, mounth, day);

    var strStates = state.map((st) => {
      return st.sg;
    });

    const newinformationsCompany: NewInformationsCompany = {
      segments: segment,
      companyRoles: permissions,
      variables: variables,
      trial,
      countReports: "0",
      limitStudies: "0",
      credits: "0",
      name: companyName,
      maxCompanyUsers: maxFranchisees,
      maxUsers: maxUsers,
      expirationTime: date ? date.toISOString() : "",
      contractRegions: state.map((state) => {
        return {
          id: state.id,
          sg: state.sg,
          name: state.name,
          selected: true,
        };
      }),
      model: {
        capitalSocial: [],
        cidade: counties,
        cnae: [],
        estado: strStates,
        inicioDeAtividade: [],
        porte: [],
      },
      nodefault: true,
    };

    const updateContractData: UpdateContractData = {
      expirationTime: date ? date.toISOString() : "",
      enable: active,
      trial,
      limitStudies: Number(limitStudies),
      countReports: Number(countReports),
      credits: Number(credits),
      companyRoles: permissions,
      contractRegions: state.map((state) => {
        return {
          id: state.id,
          sg: state.sg,
          name: state.name,
          selected: true,
        };
      }),

      model: {
        capitalSocial: [],
        cidade: counties,
        cnae: [],
        estado: strStates,
        inicioDeAtividade: [],
        porte: [],
      },
      nodefault: true,
    };

    const updateCompanyData: UpdateCompanyData = {
      name: companyName,
      maxCompanyUsers: Number(maxFranchisees),
      maxUsers: Number(maxUsers),
      segments: segment,
      variables: variables,
    };

    if (idCompany && idContract) {
      updateContract(idContract, updateContractData);
      updateCompany(idCompany, updateCompanyData);
      setCompaniesContract([]);
      reloadCompanies();
    } else {
      createCompany(newinformationsCompany).then((company) => {
        setCompaniesContract([]);
        reloadCompanies();
      });
    }
  };

  return (
    <>
      {loading && idCompany ? (
        <Loading />
      ) : (
        <div>
          <ModalDefault
            styleBody={{
              paddingRight: 5,
            }}
            setModalOpen={() => {
              setModalOpen(false);
            }}
            icon={<FaBriefcase />}
            title={editOrAdd.titleModal}
            canclose={!mensagemAparecendo}
          >
            <div
              style={{
                paddingRight: 28,
                overflowY: "auto",
                maxHeight: "750px",
              }}
            >
              <TitleModal size="large" style={{ textAlign: "center" }}>
                {editOrAdd.title}
              </TitleModal>
              <SubTitleModal>{editOrAdd.subTitle}</SubTitleModal>

              <Divider />

              <CompanyDetails
                companyName={companyName}
                setCompanyName={setCompanyName}
                maxFranchisees={maxFranchisees}
                setMaxFranchisees={setMaxFranchisees}
                credits={credits}
                setCredits={setCredits}
                maxUsers={maxUsers}
                setMaxUsers={setMaxUsers}
                expirationDate={expirationDate}
                setExpirationDate={setExpirationDate}
              />

              <Divider />

              <SelectionSection
                segment={segment}
                counties={counties}
                countiesOptions={countiesOptions}
                state={state}
                variables={variables}
                permissions={permissions}
                setPermissions={setPermissions}
                setVariables={setVariables}
                setSegment={setSegment}
                setCounties={setCounties}
                setState={setState}
              />

              <Divider />

              <Actions
                countReports={countReports}
                setCountReports={setCountReports}
                limitStudies={limitStudies}
                setLimitStudies={setLimitStudies}
                active={active}
                setActive={setActive}
                trial={trial}
                setTrial={setTrial}
              />

              <Divider />

              {ContractId && (
                <>
                  <UserSection
                    tokenCompany={tokenCompany}
                    searchUser={searchUser}
                    setSearchUser={setSearchUser}
                    setUsers={setUsers}
                    setMessage={setMessage}
                    canclose={!mensagemAparecendo}
                  />

                  <TableUsers
                    tokenCompany={tokenCompany}
                    users={users}
                    setUsers={setUsers}
                    setMessage={setMessage}
                    canclose={!mensagemAparecendo}
                  />

                  <Divider />

                  <FileUploadSection />
                </>
              )}

              <Divider />

              <ButtonDefault
                style={{
                  marginTop: 19,
                  marginRight: "auto",
                  marginLeft: "auto",
                  width: "fit-content",
                }}
                size="medium"
                title={editOrAdd.buttonTitle}
                icon={<FaSave />}
                iconPosition="right"
                border={true}
                color={"secondary"}
                borderRadius={40}
                onClick={() => {
                  handleSubmit();
                }}
              />
            </div>
          </ModalDefault>
        </div>
      )}
    </>
  );
}
