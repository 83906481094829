import { useEffect, useState } from "react";
import { ModalDefault } from "../../../../components/Modal/ModalDefault";
import { ButtonLink } from "../../../../components/Button/ButtonLink";
import { SubTitle } from "../../../../components/typography/SubTitle";
import { Title } from "../../../../components/typography/Title";
import { AreaTag } from "./components/AreaTag";
import { ButtonDefault } from "../../../../components/Button/ButtonDefault";
import { FaX } from "react-icons/fa6";
import { FaQuestionCircle, FaShareAlt, FaTrash } from "react-icons/fa";

export function AreaList(props) {
  const {
    areaList,
    selectedArea,
    setSelectedArea,
    polygon,
    setPolygon,
    setAreaList,
    shapeSelectedToEdition,
  } = props;

  const [idUpdate, setIdUpdate] = useState(0);
  const [mostraConfirmaExluir, setMostraConfirmaExluir] = useState(false);

  useEffect(() => {
    return () => {
      areaList.forEach((area) => {
        if (area.id === shapeSelectedToEdition?.id) {
          setIdUpdate(area.id);
        }
      });
    };
  }, [shapeSelectedToEdition]);

  useEffect(() => {
    areaList.forEach((area) => {
      if (area.id === idUpdate) {
        setSelectedArea((prev) => {
          const idxArea = prev.findIndex((a) => a.id === idUpdate);
          const newPrev = [...prev];
          newPrev[idxArea] = area;

          return newPrev;
        });
      }
    });
  }, [areaList]);

  function isSelected(area) {
    for (let index = 0; index < selectedArea.length; index++) {
      const element = selectedArea[index];
      if (element.id === area.id) {
        return true;
      }
    }
    return false;
  }

  function select(area, idx) {
    var notfound = true;

    for (let index = 0; index < selectedArea.length; index++) {
      const element = selectedArea[index];

      if (element.id === area.id) {
        notfound = false;
        selectedArea.splice(index, 1);

        if (selectedArea.length > 0) {
          setSelectedArea([...selectedArea]);
        } else {
          setSelectedArea([]);
          polygon.forceUpdate();
        }
        break;
      }
    }
    if (notfound) {
      setSelectedArea([...selectedArea, area]);
    }
  }

  const deleteAll = () => {
    polygon.deleteAllfunction();

    setPolygon({
      ...polygon,
      polygonList: [],
      radiusList: [],
      rectangleList: [],
      lineList: [],
      pins: [],
    });

    setAreaList([]);
    setSelectedArea([]);
  };

  return (
    <div>
      <Title style={{ color: "black" }}>Áreas marcadas</Title>
      <SubTitle>
        Selecione as áreas abaixo caso queira que os dados do estudo sejam
        apenas referentes a elas.
      </SubTitle>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {areaList.map((area, idx) => {
          return (
            <AreaTag
              key={idx}
              name={"Área " + (idx + 1)}
              studyType={area.studyType}
              color={area.cor}
              selected={isSelected(area)}
              onClick={() => {
                select(area, idx);
              }}
              onClickDelete={() => {
                const delval = areaList[idx];
                for (let n = 0; n < selectedArea.length; n++) {
                  const element = selectedArea[n];
                  if (delval.id === element.id) {
                    //Se a área a ser deletada é a selecionada, deseleciona ela antes de deletar.
                    select(delval);
                  }
                }
                polygon.deletfunction(delval.id);
                var indx = -1;
                var type = 0;
                for (
                  let index = 0;
                  index < polygon.rectangleList.length;
                  index++
                ) {
                  const element = polygon.rectangleList[index];
                  if (delval.id === element.id) {
                    indx = index;
                    type = 1;
                  }
                }
                if (indx === -1) {
                  for (
                    let index = 0;
                    index < polygon.radiusList.length;
                    index++
                  ) {
                    const element = polygon.radiusList[index];
                    if (delval.id === element.id) {
                      indx = index;
                      type = 2;
                    }
                  }
                }
                if (indx === -1) {
                  for (
                    let index = 0;
                    index < polygon.polygonList.length;
                    index++
                  ) {
                    const element = polygon.polygonList[index];
                    if (delval.id === element.id) {
                      indx = index;
                      type = 3;
                    }
                  }
                }
                if (indx !== -1) {
                  if (type === 1) {
                    polygon.rectangleList.splice(indx, 1);
                    setPolygon({
                      ...polygon,
                      rectangleList: polygon.rectangleList,
                    });
                  } else if (type === 2) {
                    polygon.radiusList.splice(indx, 1);
                    setPolygon({
                      ...polygon,
                      radiusList: polygon.radiusList,
                    });
                  } else if (type === 3) {
                    polygon.polygonList.splice(indx, 1);
                    setPolygon({
                      ...polygon,
                      polygonList: polygon.polygonList,
                    });
                  }
                } else {
                  console.log("ERRO!!! POLIGONO NAO ENCONTRADO!!!");
                }
              }}
            />
          );
        })}
        <ButtonLink
          onClick={() => {
            setMostraConfirmaExluir(true);
          }}
        >
          Apagar todas
        </ButtonLink>
      </div>
      {mostraConfirmaExluir ? (
        <ModalDefault
          icon={<FaQuestionCircle />}
          setModalOpen={setMostraConfirmaExluir}
          title="Pergunta:"
          styleContent={{
            maxWidth: 500,
          }}
        >
          <div>
            <p>Deseja realmente apagar todas análises?</p>
            <div style={{ padding: 10 }} />
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <ButtonDefault
                icon={<FaTrash />}
                iconPosition="right"
                title="Apagar"
                style={{
                  borderRadius: "15px",
                  margin: 0,
                }}
                size={"medium"}
                color={"orange"}
                transparent={false}
                onClick={async () => {
                  deleteAll();
                  setMostraConfirmaExluir(false);
                }}
              />
              <div style={{ padding: 20 }} />
              <ButtonDefault
                icon={<FaX />}
                iconPosition="right"
                title="Cancelar"
                style={{
                  borderRadius: "15px",
                  margin: 0,
                }}
                size={"medium"}
                color={"secondary"}
                transparent={false}
                onClick={async () => {
                  setMostraConfirmaExluir(false);
                }}
              />
            </div>
          </div>
        </ModalDefault>
      ) : (
        <div></div>
      )}
    </div>
  );
}
