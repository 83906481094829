import L, { LatLngTuple } from "leaflet";
import GA4 from "react-ga4";
import { PolygonParamList } from "../../../../../Types/interfacs";
import {
  getDistanceBetweenPoints,
  getPointAtDistanceLimit,
} from "../getDistanceBetweenPoints";
import { UserToken } from "../../../../../services/userToken";
import { RectangleProps } from "../types";
import {
  BestAreaVariablesParams,
  RadiusExclusivityParams,
  SurroundingsParams,
} from "../../../../../Types/Params";
import { deselectText } from "../../../Utils";

interface EditRectangleProps {
  map: L.Map;
  layerShapes: L.LayerGroup;
  outlineRetangle?: L.Rectangle;
  rectangle: RectangleProps;
  editMarker?: L.Marker;
  moveMarker?: L.Marker;
  deleteMarker?: L.Marker;
  setPolygon?: React.Dispatch<React.SetStateAction<PolygonParamList>>;
  setParamsToSurroundings?: React.Dispatch<
    React.SetStateAction<SurroundingsParams>
  >;
  setParamsToBestAreaVariables?: React.Dispatch<
    React.SetStateAction<BestAreaVariablesParams>
  >;
  setParamsToRadiusExclusivity?: React.Dispatch<
    React.SetStateAction<RadiusExclusivityParams>
  >;
}

export function EditRectangle({
  map,
  layerShapes,
  outlineRetangle,
  rectangle,
  editMarker,
  moveMarker,
  deleteMarker,
  setParamsToSurroundings,
  setParamsToBestAreaVariables,
}: EditRectangleProps) {
  let toolTip: L.Tooltip;

  let bounds = rectangle.getBounds();
  let center = bounds.getCenter();
  let northEast = bounds.getNorthEast();
  let southWest = bounds.getSouthWest();
  let northWest = bounds.getNorthWest();
  let southEast = bounds.getSouthEast();

  const icon = L.icon({
    iconUrl: "edit-Icon.png",
    iconSize: [20, 20],
  });

  const centerMarker = L.marker(center, {
    icon,
  });

  const northEastMarker = L.marker(northEast, {
    icon,
  });
  const southWestMarker = L.marker(southWest, {
    icon,
  });
  const northWestMarker = L.marker(northWest, {
    icon,
  });
  const southEastMarker = L.marker(southEast, {
    icon,
  });

  centerMarker.setZIndexOffset(1000);
  northEastMarker.setZIndexOffset(1000);
  southWestMarker.setZIndexOffset(1000);
  northWestMarker.setZIndexOffset(1000);

  centerMarker.on("mousedown", handleClickOnCenter);

  northEastMarker.on("mousedown", handleClickOnNorthEast);

  southWestMarker.on("mousedown", handleClickOnSouthWest);

  northWestMarker.on("mousedown", handleClickOnNorthWest);

  southEastMarker.on("mousedown", handleClickOnSouthEast);

  function onMouseOver() {
    map.on("mouseout", (e: L.LeafletMouseEvent) => {
      document.addEventListener("mouseup", handleClickUp);
      const secondLatLng = [e.latlng.lat, e.latlng.lng] as L.LatLngTuple;

      const zoom = map.getZoom();
      if (rectangle) {
        const centerR = rectangle.getBounds().getCenter();

        const middlePoint = [
          (centerR.lat + secondLatLng[0]) / 2,
          (centerR.lng + secondLatLng[1]) / 2,
        ] as LatLngTuple;

        map.setView(middlePoint, zoom);
      }
    });
  }

  function addGuides() {
    centerMarker.addTo(layerShapes);
    northEastMarker.addTo(layerShapes);
    southWestMarker.addTo(layerShapes);
    northWestMarker.addTo(layerShapes);
    southEastMarker.addTo(layerShapes);

    map.on("mouseup", handleClickUp);
  }

  addGuides();

  function removeGuides() {
    layerShapes.removeLayer(centerMarker);
    layerShapes.removeLayer(northEastMarker);
    layerShapes.removeLayer(southWestMarker);
    layerShapes.removeLayer(northWestMarker);
    layerShapes.removeLayer(southEastMarker);
  }

  outlineRetangle?.on("remove", () => {
    map.off("mouseup", handleClickUp);
    removeGuides();
  });

  editMarker?.on("click", () => {
    removeGuides();
  });

  deleteMarker?.on("click", () => {
    removeGuides();
    outlineRetangle?.remove();
  });

  map.getContainer().style.cursor = "crosshair";

  function removeOnMoveOrResize() {
    editMarker?.remove();
    deleteMarker?.remove();
    outlineRetangle?.remove();

    addGuides();
  }

  function addOnMoveOrResizeFinish() {
    const southWest = rectangle.getBounds().getSouthWest();
    const northEast = rectangle.getBounds().getNorthEast();

    const firstLatLng = [southWest.lat - 0.0005, southWest.lng - 0.0005] as [
      number,
      number
    ];
    const secondLatLng = [northEast.lat + 0.0005, northEast.lng + 0.0005] as [
      number,
      number
    ];

    outlineRetangle?.setBounds([firstLatLng, secondLatLng]);

    const northEastOutiline = outlineRetangle?.getBounds().getNorthEast();

    editMarker?.setLatLng(northEastOutiline ?? northEast);
    moveMarker?.setLatLng(northEastOutiline ?? northEast);
    deleteMarker?.setLatLng(northEastOutiline ?? northEast);
    editMarker?.addTo(layerShapes);
    moveMarker?.addTo(layerShapes);
    deleteMarker?.addTo(layerShapes);
    outlineRetangle?.addTo(layerShapes);
  }

  function handleClickOnCenter(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      onMouseOver();
      map.dragging.disable();
      map.on("mousemove", handleMouseMoveCenter);
      map.on("mouseup", handleClickUp);
      if (moveMarker || editMarker || deleteMarker) {
        removeOnMoveOrResize();
      }
    }
  }

  function handleClickOnNorthEast(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      onMouseOver();
      map.dragging.disable();
      map.on("mousemove", handleMouseMoveNorthEast);
      map.on("mouseup", handleClickUp);
      if (moveMarker || editMarker || deleteMarker) {
        removeOnMoveOrResize();
      }
    }
  }

  function handleClickOnSouthWest(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      onMouseOver();
      map.dragging.disable();
      map.on("mousemove", handleMouseMoveSouthWest);
      map.on("mouseup", handleClickUp);
      if (moveMarker || editMarker || deleteMarker) {
        removeOnMoveOrResize();
      }
    }
  }

  function handleClickOnNorthWest(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      onMouseOver();
      map.dragging.disable();
      map.on("mousemove", handleMouseMoveNorthWest);
      map.on("mouseup", handleClickUp);
      if (moveMarker || editMarker || deleteMarker) {
        removeOnMoveOrResize();
      }
    }
  }

  function handleClickOnSouthEast(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      onMouseOver();
      map.dragging.disable();
      map.on("mousemove", handleMouseMoveSouthEast);
      map.on("mouseup", handleClickUp);
      if (moveMarker || editMarker || deleteMarker) {
        removeOnMoveOrResize();
      }
    }
  }

  function handleMouseMoveCenter(e: L.LeafletMouseEvent) {
    const newCenterLatLng = [e.latlng.lat, e.latlng.lng] as L.LatLngTuple;

    const latDiff = newCenterLatLng[0] - center.lat;
    const lngDiff = newCenterLatLng[1] - center.lng;

    const newNorthEastLatLng = [
      northEast.lat + latDiff,
      northEast.lng + lngDiff,
    ] as L.LatLngTuple;
    const newSouthWestLatLng = [
      southWest.lat + latDiff,
      southWest.lng + lngDiff,
    ] as L.LatLngTuple;
    const newNorthWestLatLng = [
      northWest.lat + latDiff,
      northWest.lng + lngDiff,
    ] as L.LatLngTuple;
    const newSouthEastLatLng = [
      southEast.lat + latDiff,
      southEast.lng + lngDiff,
    ] as L.LatLngTuple;

    const newBounds = L.latLngBounds(newNorthEastLatLng, newSouthWestLatLng);

    rectangle.setBounds(newBounds);
    centerMarker.setLatLng(newCenterLatLng);
    northEastMarker.setLatLng(newNorthEastLatLng);
    southWestMarker.setLatLng(newSouthWestLatLng);
    northWestMarker.setLatLng(newNorthWestLatLng);
    southEastMarker.setLatLng(newSouthEastLatLng);
  }

  function handleMouseMoveNorthEast(e: L.LeafletMouseEvent) {
    let newNorthEastLatLng = [e.latlng.lat, e.latlng.lng] as L.LatLngTuple;

    newNorthEastLatLng = getPointAtDistanceLimit(
      southWest,
      newNorthEastLatLng,
      250000
    );

    let distanceBetweenPoints = getDistanceBetweenPoints(
      newNorthEastLatLng,
      southWest
    );

    addTooltip(distanceBetweenPoints, newNorthEastLatLng);

    const newSouthWestLatLng = [southWest.lat, southWest.lng] as [
      number,
      number
    ];
    const newNorthWestLatLng = [newNorthEastLatLng[0], southWest.lng] as [
      number,
      number
    ];
    const newSouthEastLatLng = [southEast.lat, newNorthEastLatLng[1]] as [
      number,
      number
    ];

    const newBounds = L.latLngBounds(newNorthEastLatLng, newSouthWestLatLng);

    rectangle.setBounds(newBounds);

    const newCenterLatLng = newBounds.getCenter();

    centerMarker.setLatLng(newCenterLatLng);
    northEastMarker.setLatLng(newNorthEastLatLng);
    southWestMarker.setLatLng(newSouthWestLatLng);
    northWestMarker.setLatLng(newNorthWestLatLng);
    southEastMarker.setLatLng(newSouthEastLatLng);
  }

  function handleMouseMoveSouthWest(e: L.LeafletMouseEvent) {
    let newSouthWestLatLng = [e.latlng.lat, e.latlng.lng] as L.LatLngTuple;

    newSouthWestLatLng = getPointAtDistanceLimit(
      northEast,
      newSouthWestLatLng,
      250000
    );

    let distanceBetweenPoints = getDistanceBetweenPoints(
      northEast,
      newSouthWestLatLng
    );

    addTooltip(distanceBetweenPoints, newSouthWestLatLng);

    const newNorthEastLatLng = [northEast.lat, northEast.lng] as [
      number,
      number
    ];
    const newNorthWestLatLng = [northWest.lat, newSouthWestLatLng[1]] as [
      number,
      number
    ];
    const newSouthEastLatLng = [newSouthWestLatLng[0], southEast.lng] as [
      number,
      number
    ];

    const newBounds = L.latLngBounds(newNorthEastLatLng, newSouthWestLatLng);

    rectangle.setBounds(newBounds);

    const newCenterLatLng = newBounds.getCenter();

    centerMarker.setLatLng(newCenterLatLng);
    northEastMarker.setLatLng(newNorthEastLatLng);
    southWestMarker.setLatLng(newSouthWestLatLng);
    northWestMarker.setLatLng(newNorthWestLatLng);
    southEastMarker.setLatLng(newSouthEastLatLng);
  }

  function handleMouseMoveNorthWest(e: L.LeafletMouseEvent) {
    let newNorthWestLatLng = [e.latlng.lat, e.latlng.lng] as L.LatLngTuple;

    newNorthWestLatLng = getPointAtDistanceLimit(      
      southEast,
      newNorthWestLatLng,
      250000
    );

    let distanceBetweenPoints = getDistanceBetweenPoints(
      newNorthWestLatLng,
      southEast
    );

    addTooltip(distanceBetweenPoints, newNorthWestLatLng);

    const newSouthEastLatLng = [southEast.lat, southEast.lng] as [
      number,
      number
    ];
    const newNorthEastLatLng = [newNorthWestLatLng[0], northEast.lng] as [
      number,
      number
    ];
    const newSouthWestLatLng = [southWest.lat, newNorthWestLatLng[1]] as [
      number,
      number
    ];

    const newBounds = L.latLngBounds(newNorthEastLatLng, newSouthWestLatLng);

    rectangle.setBounds(newBounds);

    const newCenterLatLng = newBounds.getCenter();

    centerMarker.setLatLng(newCenterLatLng);
    northEastMarker.setLatLng(newNorthEastLatLng);
    southWestMarker.setLatLng(newSouthWestLatLng);
    northWestMarker.setLatLng(newNorthWestLatLng);
    southEastMarker.setLatLng(newSouthEastLatLng);
  }

  function handleMouseMoveSouthEast(e: L.LeafletMouseEvent) {
    let newSouthEastLatLng = [e.latlng.lat, e.latlng.lng] as L.LatLngTuple;

    newSouthEastLatLng = getPointAtDistanceLimit(
      northWest,
      newSouthEastLatLng,
      250000
    );

    let distanceBetweenPoints = getDistanceBetweenPoints(
      northWest,
      newSouthEastLatLng
    );

    addTooltip(distanceBetweenPoints, newSouthEastLatLng);

    const newNorthWestLatLng = [northWest.lat, northWest.lng] as [
      number,
      number
    ];
    const newNorthEastLatLng = [northEast.lat, newSouthEastLatLng[1]] as [
      number,
      number
    ];
    const newSouthWestLatLng = [newSouthEastLatLng[0], southWest.lng] as [
      number,
      number
    ];

    const newBounds = L.latLngBounds(newNorthEastLatLng, newSouthWestLatLng);

    rectangle.setBounds(newBounds);

    const newCenterLatLng = newBounds.getCenter();


    centerMarker.setLatLng(newCenterLatLng);
    northEastMarker.setLatLng(newNorthEastLatLng);
    southWestMarker.setLatLng(newSouthWestLatLng);
    northWestMarker.setLatLng(newNorthWestLatLng);
    southEastMarker.setLatLng(newSouthEastLatLng);
  }

  function handleClickUp() {
    map.off("mousedown");
    map.off("mouseup", handleClickUp);
    map.off("mousemove", handleMouseMoveCenter);
    map.off("mousemove", handleMouseMoveNorthEast);
    map.off("mousemove", handleMouseMoveSouthWest);
    map.off("mousemove", handleMouseMoveNorthWest);
    map.off("mousemove", handleMouseMoveSouthEast);
    map.off("mouseout");
    document.removeEventListener("mouseup", handleClickUp);

    deselectText();

    bounds = rectangle.getBounds();
    center = bounds.getCenter();

    if (toolTip) {
      toolTip.remove();
    }

    northEast = bounds.getNorthEast();
    southWest = bounds.getSouthWest();
    northWest = bounds.getNorthWest();
    southEast = bounds.getSouthEast();

    northEastMarker.setLatLng(northEast);
    southWestMarker.setLatLng(southWest);
    northWestMarker.setLatLng(northWest);
    southEastMarker.setLatLng(southEast);

    addOnMoveOrResizeFinish();

    map.dragging.enable();
    map.getContainer().focus();
  }

  function addTooltip(distanceBetweenPoints: number, position: L.LatLngTuple) {
    if (toolTip) {
      toolTip.remove();
    }
    const distanceKm = distanceBetweenPoints / 1000;
    const distanceKmRounded = Math.round(distanceKm * 100) / 100;
    toolTip = L.tooltip()
      .setLatLng(position)
      .setContent(
        `<div
          style="
          background-color: white;
            padding: 5px;
            font-size: 14px;
            font-weight: bold;
          "
          >${distanceKmRounded < 250 ? distanceKmRounded : "Max 250"} km</div>`
      )
      .addTo(map);
  }
}

