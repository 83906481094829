import * as htmlToImage from "html-to-image";

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import BgPdf from "../../../../assets/images/pdf-bg.png";
import LogoPdf from "../../../../assets/images/deepen-maps-logo.png";

import dinFont from "../../../../assets/fonts/D-DIN.otf";
import dinBoldFont from "../../../../assets/fonts/D-DINCondensed-Bold.otf";
import dinExpBoldFont from "../../../../assets/fonts/D-DINExp-Bold.otf";
import { SubTitle } from "../../../../components/typography/SubTitle";
import fonts from "../../../../config/fonts";

export async function ReportHtml(
  imageUrl: any,
  descricaoDasSessoes: any,
  listadesessoes: any,
  addressFormat: Function,
  items?: any
) {
  Font.register({
    family: "D-DIN",
    fonts: [
      {
        src: dinFont,
      },
      {
        src: dinBoldFont,
        fontWeight: "bold",
      },
      {
        src: dinExpBoldFont,
        fontWeight: 600,
      },
    ],
  });

  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingHorizontal: 35,
      paddingBottom: 35,
    },
    logoContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: 20,
      width: "100%",
    },
    logo: {
      maxWidth: 132,
    },
    body1: {
      paddingTop: 35,
      paddingHorizontal: 35,
      paddingBottom: 35,
      display: "flex",
      justifyContent: "space-evenly",
    },

    containers: {
      width: "100%",
    },
    title: {
      fontSize: 28,
      color: "#004257",
      textAlign: "center",
      marginBottom: 18,
      fontFamily: "D-DIN",
      fontWeight: "bold",
    },
    description: {
      fontSize: 14,
      color: "#B2B2B2",
      marginBottom: 18,
      fontFamily: "D-DIN",
      textAlign: "center",
    },
    address: {
      fontSize: 10,
      color: "#B2B2B2",
      fontFamily: "D-DIN",
      textAlign: "center",
      marginVertical: 6,
    },
    graph0: {
      width: "auto",
      marginTop: 22,
      maxHeight: 280,
      height: "auto",
      marginBottom: 10,
    },
    graph1: {
      width: "auto",
      marginTop: 22,
      maxHeight: 280,
    },
    graph2: {
      width: "auto",
      marginTop: 22,
      maxHeight: 170,
      height: "auto",
    },
    imgBg: {
      opacity: 0.5,
      position: "absolute",
      height: 841.89,
      width: 595.28,
      top: 0,
      left: 0,
    },
    footer: {
      position: "absolute",
      bottom: 5,
      left: 0,
      right: 0,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      gap: 8,
    },
    footerText: {
      fontSize: 10,
      color: "#B2B2B2",
      marginBottom: 18,
      fontFamily: "D-DIN",
      textTransform: "uppercase",
    },
    footerTextCurrent: {
      fontSize: 10,
      color: "#004257",
      marginBottom: 18,
      fontFamily: "D-DIN",
      fontWeight: 600,
      textTransform: "uppercase",
    },
    cardTitle: {
      paddingBottom: "5px",
      color: "#004257",
      fontFamily: "D-DIN",
      fontSize: 12,
    },
    cardDescription: {
      color: "#B2B2B2",
      fontFamily: "D-DIN",
      fontSize: 12,
    },
    cardBorder: {
      border: "2px solid rgb(213, 213, 213)",
      padding: "5px",
      margin: "5px",
      borderRadius: "10px",
      width: "100%",
      display: "flex",
      flexDirection: "column",

      height: "auto",
    },
    cardRow: {
      width: "33%",
      display: "flex",
      flexDirection: "column",
    },
    cards: {
      width: "100%",
      display: "flex",

      flexDirection: "row",

      gap: 12,
    },
    box: {
      display: "flex",
      flexDirection: "row",
      width: "auto",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      border: "1px solid #B2B2B2",
      marginBottom: 10,
    },
    areas: {
      color: "#459CA0",
      fontFamily: "D-DIN",
      fontSize: 12,
      fontWeight: "normal",
    },
  });

  const dataWithCards = (id: string) => {
    const topic = listadesessoes?.find((item: any) => item.name === id);

    if (
      topic?.graphicsList?.find((item: any) => {
        if (
          item?.data?.length <= 1 &&
          item?.data[0]?.variable?.labels?.length < 2
        ) {
          return true;
        } else {
          return false;
        }
      })
    ) {
      return true;
    } else {
      return false;
    }
  };

  const cardDescription = (list: string[]) => {
    const paragraphs = [];

    for (let i = 0; i < list.length; i += 2) {
      const string1 = list[i].split(": ");
      const string2 = i + 1 < list.length ? list[i + 1].split(": ") : null;

      paragraphs.push(
        <View key={i} style={styles.cardRow}>
          <View key={i + "-SubText"} style={styles.cardBorder}>
            <Text style={styles.cardTitle}>{string1[0]}:</Text>
            <Text style={styles.cardDescription}>{string1[1]}</Text>
          </View>

          {string2 && (
            <View key={i + 1 + "-SubText"} style={styles.cardBorder}>
              <Text style={styles.cardTitle}>{string2[0]}:</Text>
              <Text style={styles.cardDescription}>{string2[1]}</Text>
            </View>
          )}
        </View>
      );
    }

    return paragraphs;
  };

  const elementsPages = async (n: any) => {
    let pages: JSX.Element[] = [];
    for (let index = 0; index < n; index++) {
      let m = 0;
      let allGraphs = false;
      let firstPageGraphs: JSX.Element[] = [];
      let pinList: JSX.Element[] = [];
      let graphs: JSX.Element[] = [];

      const desc = descricaoDasSessoes.get(index);
      const withCards = dataWithCards(desc.tituloAcentuado);

      let firstPageTotalImages =
        desc.tituloAcentuado === "Sociodemográfica" ||
        desc.tituloAcentuado === "CNPJ"
          ? 3
          : desc.tituloAcentuado === "Taxas"
          ? 2
          : 4;
      while (allGraphs !== true) {
        let nextGraphic = document.getElementById("sessao" + index + "_" + m);

        if (nextGraphic) {
          let imgData = await htmlToImage.toPng(nextGraphic);
          m += 1;
          if (
            desc.tituloAcentuado === "Alfinetes" ||
            desc.tituloAcentuado === "Taxas" ||
            desc.tituloAcentuado === "CNPJ" ||
            withCards
          ) {
            firstPageGraphs.push(
              <Image
                key={index + "-image-" + m}
                src={imgData}
                style={styles.graph0}
              />
            );
            if (desc.tituloAcentuado === "Alfinetes" && m === 1) {
              var pinNumber = 0;
              var pindata = document.getElementById("sessaoPin" + pinNumber);
              while (pindata) {
                let imgPin = await htmlToImage.toPng(pindata);
                pinList.push(
                  <Image
                    key={index + "-imagepin-" + m}
                    src={imgPin}
                    style={{
                      width: "auto",
                      marginTop: 0,
                      maxHeight: 280,
                      height: "auto",
                      marginBottom: 0,
                    }}
                  />
                );
                pinNumber++;
                pindata = document.getElementById("sessaoPin" + pinNumber);
              }
            }
          } else {
            if (m < firstPageTotalImages) {
              firstPageGraphs.push(
                <Image
                  key={index + "-image-" + m}
                  src={imgData}
                  style={
                    firstPageTotalImages === 3
                      ? styles.graph1
                      : firstPageTotalImages === 4
                      ? styles.graph2
                      : styles.graph0
                  }
                />
              );
            } else {
              graphs.push(
                <Image
                  key={index + "-image-" + m}
                  src={imgData}
                  style={styles.graph0}
                />
              );
            }
          }
        } else {
          m += 0;
          allGraphs = true;
        }
      }

      let pagination: JSX.Element[] = [];

      for (let index = 0; index < n; index++) {
        let page = descricaoDasSessoes?.get(index);

        pagination.push(
          <Text
            style={
              desc?.tituloAcentuado === page?.tituloAcentuado
                ? styles.footerTextCurrent
                : styles.footerText
            }
          >
            {page?.tituloAcentuado}
          </Text>
        );
      }

      if (desc != null) {
        pages.push(
          <Page style={styles.body} wrap>
            <Image src={BgPdf} fixed style={styles.imgBg} />
            <View>
              <Text style={styles.title}>{desc.tituloAcentuado}</Text>
              {typeof desc.descricao === "string" ? (
                <Text style={styles.description}>{desc.descricao}</Text>
              ) : (
                <View style={styles.cards}>
                  {cardDescription(desc.descricao)}
                </View>
              )}
            </View>
            {firstPageGraphs}
            {pinList}
            <View style={styles.footer} fixed>
              {pagination}
            </View>
          </Page>
        );
        if (graphs.length > 0) {
          pages.push(
            <Page style={styles.body1} wrap>
              <Image src={BgPdf} fixed style={styles.imgBg} />
              {graphs}
              <View style={styles.footer} fixed>
                {pagination}
              </View>
            </Page>
          );
        }
      }
    }

    return await pages;
  };

  const addressText = (address: string) => {
    if (address === "Nao encontrado") {
      return "Endereço não encontrado";
    } else {
      return addressFormat(address);
    }
  };

  return (
    <Document>
      <Page style={styles.body}>
        <Image src={BgPdf} fixed style={styles.imgBg} />
        <View style={styles.containers}>
          <View style={styles.logoContainer}>
            <Image src={LogoPdf} style={styles.logo} />
          </View>
          <Text style={styles.title}>Estudos</Text>
          <Text style={styles.description}>
            Cada estudo criado no mapa é representado por uma cor, ao longo de
            todo relatório essas cores vão ser usadas para representar cada
            estudo.
          </Text>
          <Image src={imageUrl} style={styles.graph0} />
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: "30px",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
              }}
            >
              <Text style={styles.areas}>Cor e Nome da Área</Text>
              <Text style={styles.areas}>Endereço</Text>
              <Text style={styles.areas}>Formato</Text>
              <Text style={styles.areas}>Área em Km²</Text>
            </View>
          </View>
          {items?.map((item: any) => {
            return (
              <div style={styles.box}>
                {item.studyType === "RADIUS_STUDY" ? (
                  <div
                    style={{
                      height: 10,
                      width: 10,
                      backgroundColor: item.color,
                      opacity: 0.4,
                      borderRadius: "50%",
                      border: `1px solid ${item.color}`,
                    }}
                  />
                ) : (
                  <div
                    style={{
                      height: 10,
                      width: 10,
                      backgroundColor: item.color,
                      opacity: 0.4,
                      border: `1px solid ${item.color}`,
                    }}
                  />
                )}
                <Text
                  style={{
                    color: item.color,
                    fontSize: 10,
                    fontFamily: "D-DIN",
                    textAlign: "center",
                    marginLeft: 6,
                  }}
                >{`Área ${item.id + 1}`}</Text>
                <Text style={{ ...styles.address, maxWidth: "400px" }}>
                  {` | ${addressText(item.address)}`}
                </Text>
                <Text style={styles.address}>{` | ${item.label}`}</Text>
                <Text style={styles.address}>
                  {` | ${item.area.toFixed(2)}km²`}
                </Text>
              </div>
            );
          })}
        </View>
      </Page>
      {await elementsPages(7)}
    </Document>
  );
}
