import { Divider } from "antd";
import { GraficoHorizontalPessoa } from "./components/graphs/grafico";

export function DadosDasPessoas(props) {
  const { graphicsData } = props;

  return (
    <div
      style={{
        paddingRight: "20px",
      }}
    >
      {graphicsData.map((item, idx) => {
        return (
          <div key={idx}>
            <GraficoHorizontalPessoa
              titulo={item.graphLabel}
              areas={item.graphData}
            />
            <Divider></Divider>
          </div>
        );
      })}
    </div>
  );
}
