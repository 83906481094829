import { ReactNode, useState } from "react";
import { Title } from "../../../../../components/typography/Title";
import { colors } from "../../../../../assets/colors";
import { ButtonDefault } from "../../../../../components/Button/ButtonDefault";
import { FaArrowRight, FaInfo } from "react-icons/fa";
import { Tooltip } from "antd";
import { SubTitle } from "../../../../../components/typography/SubTitle";
import { LabelProps } from "../../../../../Types/Variable";

interface OptionsProps {
  children: ReactNode;
  onClick: () => void;
  info: LabelProps[];
}

const borderColor = colors.textLight;
const previwInfoColor = colors.link;

export function Option({ children, onClick, info }: OptionsProps) {
  const [tooltip, setTooltip] = useState(false);

  const InfoText = () => {
    return (
      <div
        style={{
          width: 240,
          padding: 10,
        }}
      >
        <Title style={{ textAlign: "center" }}>
          A variável <b>"{children}"</b> é utilizada para mostrar{" "}
          <b>"{info.length}"</b> resultados, utilizando de subvariaveis como:
        </Title>

        {info.map(({ nameDesc }, index) => {
          return (
            <SubTitle
              key={index}
              style={{
                color: previwInfoColor,
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              - {nameDesc}
            </SubTitle>
          );
        })}
      </div>
    );
  };

  return (
    <div
      onMouseLeave={() => {
        setTooltip(false);
      }}
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        borderBottom: `1px solid ${borderColor}`,
        alignItems: "center",
        padding: 24,
        height: 70,
      }}
    >
      <div
        style={{
          flex: 1,
        }}
      >
        <Title style={{ fontWeight: "bold", marginRight: 20 }}>
          {children}
        </Title>
      </div>
      <Tooltip
        color="white"
        placement="left"
        open={tooltip}
        title={<InfoText />}
      >
        <ButtonDefault
          style={{
            borderRadius: 15,
          }}
          icon={<FaInfo />}
          size={"small"}
          color={"secondary"}
          onClick={() => {
            setTooltip(!tooltip);
          }}
        />
      </Tooltip>
      <ButtonDefault
        style={{
          borderRadius: 15,
          marginRight: 0,
        }}
        icon={<FaArrowRight />}
        size={"small"}
        color={"success"}
        onClick={onClick}
      />
    </div>
  );
}
