import L, { LatLngTuple } from "leaflet";
import GA4 from "react-ga4";
import { UserToken } from "../../../../../services/userToken";
import { Shapes } from "../../../../../Types/Shapes";
import React, { CSSProperties } from "react";
import { PolygonParamList } from "../../../../../Types/interfacs";
import { getDistanceBetweenPoints } from "../getDistanceBetweenPoints";
import { LineProps } from "../types";
import { salvaLog } from "../../../../../services/getOptionsData";
import { deselectText } from "../../../Utils";

interface CreateLineProps {
  map: L.Map;
  layerShapes: L.LayerGroup;
  color: string;
  setPolygon: React.Dispatch<React.SetStateAction<PolygonParamList>>;
  setShapeActive: React.Dispatch<React.SetStateAction<Shapes>>;
  setShapeSelectedToEdition: React.Dispatch<
    React.SetStateAction<{
      id: number;
      shape: L.Polyline;
      optionsButton: {
        editButton: boolean;
        deleteButton: boolean;
      };
    }>
  >;
}

export const CreateLine = ({
  map,
  layerShapes,
  color,
  setPolygon,
  setShapeActive,
  setShapeSelectedToEdition,
}: CreateLineProps) => {
  let firstLatLng: [number, number];
  let secondLatLng: [number, number];
  let distanceMeters: number;
  let line: LineProps;
  let toolTip: L.Tooltip;

  map.on("mousedown", handleClickDown);
  map.on("mouseup", handleClickUp);
  map.getContainer().style.cursor = "crosshair";

  function handleClickDown(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      map.dragging.disable();
      firstLatLng = [e.latlng.lat, e.latlng.lng];
      secondLatLng = [e.latlng.lat, e.latlng.lng];
      map.on("mousemove", handleMouseMove);
      line = drawLine(firstLatLng, secondLatLng, color) as LineProps;

      map.on("mouseout", (e: L.LeafletMouseEvent) => {
        document.addEventListener("mouseup", handleClickUp);

        const zoom = map.getZoom();
        if (line) {
          const center = line.getBounds().getCenter();

          const middlePoint = [
            (center.lat + secondLatLng[0]) / 2,
            (center.lng + secondLatLng[1]) / 2,
          ] as LatLngTuple;

          map.setView(middlePoint, zoom);
        }
      });
      line.addTo(layerShapes);
    } else if (e.originalEvent.button === 1) {
      map.getContainer().style.cursor = "grabbing";
    }
  }

  function handleMouseMove(e: L.LeafletMouseEvent) {
    secondLatLng = [e.latlng.lat, e.latlng.lng];

    if (line) {
      line.setLatLngs([firstLatLng, secondLatLng]);
    }

    line._path.style.cursor = "crosshair";

    if (toolTip) {
      toolTip.remove();
    }

    distanceMeters = getDistanceBetweenPoints(firstLatLng, secondLatLng);
    const distanceKmRounded = Math.round((distanceMeters / 1000) * 100) / 100;

    toolTip = L.tooltip()
      .setLatLng(secondLatLng)
      .setContent(
        `<div
        style="
        background-color: white;
          padding: 5px;
          font-size: 14px;
          font-weight: bold;
        "
        >${distanceKmRounded} km</div>`
      )
      .addTo(map);
  }

  function handleClickUp() {
    map.off("mousedown");
    map.off("mouseup");
    map.off("mousemove");

    map.off("mouseout");
    document.removeEventListener("mouseup", handleClickUp);

    deselectText();

    if (line && distanceMeters > 0) {
      salvaLog({
        action: "Finalizou Linha",
        params: "",
      }).then((result: any) => {
      });

      const idShape = line._leaflet_id;

      const type = "LINE_STUDY";

      line.on("mousedown", () => {
        map.off("click");
      });

      line.on("click", () => {
        setShapeSelectedToEdition({
          id: idShape,
          shape: line,
          optionsButton: {
            editButton: true,
            deleteButton: true,
          },
        });
      });

      setPolygon((prev: PolygonParamList) => {
        return {
          ...prev,
          lineList: [
            ...prev.lineList,
            {
              id: idShape,
              points: [firstLatLng, secondLatLng],
              type,
              color,
            },
          ],
        };
      });
      setShapeActive("arrow");
      if (toolTip) {
        toolTip.remove();
      }

      map.dragging.enable();
    } else {
      map.on("mousedown", handleClickDown);
      map.on("mouseup", handleClickUp);

      line && layerShapes.removeLayer(line);

      map.getContainer().style.cursor = "crosshair";
    }
  }

  return null;
};

export function drawLine(
  firstLatLng: [number, number],
  secondLatLng: [number, number],
  color: string,
  dashArray?: boolean
) {
  const line = L.polyline([firstLatLng, secondLatLng], {
    color,
    dashArray: dashArray ? "10, 10" : "",
    weight: 4,
    fillOpacity: 0.1,
  });

  return line;
}
