import domtoimage from "dom-to-image";
import { MapData } from "../../../../services/mapData";

export function screenshotMap(setImageUrl) {
  domtoimage
    .toPng(document.getElementById("my-map"), {
      width: MapData.width,
      height: MapData.height,
    })
    .then(function (dataUrl) {
      setImageUrl(dataUrl); //usado como parametro para estudo completo...tb não sei pq...
      var doc = document.getElementById("put-image-here");
      if (doc != null) {
        var img = new Image();
        img.src = dataUrl;
        img.width = doc.clientWidth * 0.9;
        img.height = doc.clientWidth * 0.9 * (MapData.height / MapData.width);
        MapData.img = img;
        if (doc.children.length > 0) {
          doc.removeChild(doc.firstChild);
        }
        doc.appendChild(img);
      }
    })
    .catch(function (error) {
      console.error("oops, something went wrong!", error);
      return error + "";
    });
}
