import { strRGB } from "../../../../../assets/colorService";
import * as d3 from "d3";
import L from "leaflet";
import geojsonvt from "geojson-vt";

export const CreateHeatmap = (
  map: L.Map,
  data: any,
  layerGroupHeatMaps: any
) => {
  var p1 = map.latLngToContainerPoint([
    data.delimiter.lat2,
    data.delimiter.lon2,
  ]);
  var p2 = map.latLngToContainerPoint([
    data.delimiter.lat1,
    data.delimiter.lon1,
  ]);

  var containerSize = {
    x: p1.x - p2.x,
    y: p1.y - p2.y,
  };

  var geoJson = createGeoJSONFromRectangle(
    data,
    containerSize.x,
    containerSize.y,
    data.number_columns,
    data.number_lines
  );

  const options = {
    maxZoom: 18,
    tolerance: 5,
    extent: 4096,
    buffer: 64,
    debug: 0,

    indexMaxZoom: 0,
    indexMaxPoints: 100000,
  };

  let tileIndex = geojsonvt(geoJson, {
    maxZoom: 18,
    tolerance: 5,
    extent: 4096,
    buffer: 64,
    debug: 0,

    indexMaxZoom: 0,
    indexMaxPoints: 100000,
  });

  var CanvasLayer = L.GridLayer.extend({
    options: {
      async: false,
    },
    myTileIndex: tileIndex,
    initialize: function () {
      L.setOptions(this, options);
    },
    createTile: function (coords: any) {
      var error = null;

      // create a <canvas> element for drawing
      var tileD = L.DomUtil.create("canvas", "leaflet-tile");

      // setup tile width and height according to the options
      var size = this.getTileSize();
      tileD.width = size.x;
      tileD.height = size.y;

      var ctx = tileD.getContext("2d");
      if (!ctx) return;

      ctx.save();
      ctx.globalCompositeOperation = "source-over";

      var tile = this.myTileIndex.getTile(coords.z, coords.x, coords.y);

      if (tile) {
        var features = tile.features;

        var backgroundColor = "rgba(0,0,0,0)";

        ctx.strokeStyle = "#000000";

        var pad = 0;

        for (var i = 0; i < features.length; i++) {
          var feature = features[i],
            type = feature.type;

          ctx.fillStyle = feature.tags.color
            ? feature.tags.color
            : backgroundColor;

          ctx.beginPath();
          for (var j = 0; j < feature.geometry.length; j++) {
            var geom = feature.geometry[j];

            for (var k = 0; k < geom.length; k++) {
              var p = geom[k];
              var extent = 4096;

              var x = (p[0] / extent) * 256;
              var y = (p[1] / extent) * 256;

              if (k) ctx.lineTo(x + pad, y + pad);
              else ctx.moveTo(x + pad, y + pad);
            }
          }

          if (type === 3 || type === 1) {
            ctx.fill("evenodd");
          }
          // ctx.stroke();
        }
      }

      return tileD;
    },
  });

  const geoJsonCanvas = new CanvasLayer();
  geoJsonCanvas.addTo(layerGroupHeatMaps);
};

function createGeoJSONFromRectangle(
  data: any,
  width: number,
  height: number,
  numColumns: number,
  numLines: number
) {
  var geoJSONFeatures = [];

  var lat1 = data.delimiter.lat1;
  var lon1 = data.delimiter.lon1;
  var lat2 = data.delimiter.lat2;
  var lon2 = data.delimiter.lon2;

  var latDiff = (lat2 - lat1) / numLines;
  var lonDiff = (lon2 - lon1) / numColumns;

  for (var i = 0; i < numLines; i++) {
    for (var j = 0; j < numColumns; j++) {
      var value = data.grid[i][j];

      var color;

      if (value <= 0) {
        color = "rgba(0, 0, 0, 0)";
      } else if (value <= 0.2) {
        color = strRGB(d3.interpolateYlOrRd(value), 0.5);
      } else {
        color = strRGB(d3.interpolateYlOrRd(value), 0.7);
      }

      var rectangle = {
        type: "Feature",
        properties: {
          index: i * numColumns + j,
          color: color,
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [lon1 + j * lonDiff, lat1 + i * latDiff],
              [lon1 + (j + 1) * lonDiff, lat1 + i * latDiff],
              [lon1 + (j + 1) * lonDiff, lat1 + (i + 1) * latDiff],
              [lon1 + j * lonDiff, lat1 + (i + 1) * latDiff],
              [lon1 + j * lonDiff, lat1 + i * latDiff],
            ],
          ],
        },
      };

      geoJSONFeatures.push(rectangle);
    }
  }

  var geoJSON = {
    type: "FeatureCollection",
    features: geoJSONFeatures,
  };

  return geoJSON as any;
}
