import L from "leaflet";
import React from "react";
import { RadiusExclusivityParams } from "../../../../../Types/Params";

interface CustomMarker extends L.Marker {
  _leaflet_id: number;
}

export function CreatePinToRadiusExclusivity(
  map: L.Map,
  setParamsToRadiusExclusivity: React.Dispatch<
    React.SetStateAction<RadiusExclusivityParams>
  >,
  pinsLayer: L.LayerGroup,
  contLimitActual: number,
  limitPins?: number,
  setModalNoticeOpen?: React.Dispatch<React.SetStateAction<boolean>>
) {
  let cont = contLimitActual;
  let pin = null;

  let positionPin: [number, number] | null = null;

  let customPin: any = {
    iconUrl: "pinMarker.png",
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  };
  let IconPin = L.icon(customPin);

  map.getContainer().style.cursor = "crosshair";
  map.on("mousedown", handleClickDown);

  function handleClickDown(e: L.LeafletMouseEvent) {
    map.on("mouseup", handleClickUp);
    if (e.originalEvent.button === 0) {
      if (limitPins !== undefined && cont >= limitPins) {
        return;
      }
      positionPin = [e.latlng.lat, e.latlng.lng];
    } else if (e.originalEvent.button === 1) {
      map.getContainer().style.cursor = "grabbing";
      map.dragging.enable();
    }
  }

  function handleClickUp(e: any) {
    if (positionPin) {
      pin = L.marker(positionPin, { icon: IconPin }) as CustomMarker;
      pin.addTo(pinsLayer);
      const point = {
        id: pin._leaflet_id,
        lat: positionPin[0],
        lon: positionPin[1],
      };
      setParamsToRadiusExclusivity((oldParams: any) => ({
        ...oldParams,
        points: [...oldParams.points, point],
      }));
      cont++;
      positionPin = null;
    } else {
      if (e.originalEvent.button === 0) {
        setModalNoticeOpen?.(true);
        map.off("mousedown", handleClickDown);
        map.off("mouseup", handleClickUp);
        map.on("mousedown", handleClickDown);
      } else if (e.originalEvent.button === 1) {
        map.getContainer().style.cursor = "crosshair";
      }
    }
  }
}
