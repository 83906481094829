import React, { CSSProperties, useState } from "react";
import { colors } from "../../assets/colors";
import "./Radio.css";

interface RadioProps {
  id: string;
  children?: React.ReactNode;
  color: string;
  style?: CSSProperties;
  isChecked?: boolean;
  onChange?: () => void;
}

export function Radio({
  id,
  color,
  style,
  children,
  isChecked,
  onChange,
}: RadioProps) {
  const contentColor: string = colors.default.disabled.content;
  const handleRadioChange = () => {
    onChange && onChange();
  };

  return (
    <div
      className="container-radio"
      style={{
        width: "100%",
        height: 48,
        fontSize: 16,
        padding: 8,
        borderRadius: 5,
        outline: "none",
        ...style,
      }}
    >
      <input
        style={{
          color: color,
          borderColor: color,
        }}
        type="radio"
        id={id}
        name="radioGroup"
        checked={isChecked}
        onClick={() => {
          if (isChecked) handleRadioChange();
        }}
        onChange={handleRadioChange}
      />

      <div
        style={{
          width: 1,
          borderRadius: 1,
          marginLeft: 16,
          height: "60%",
          backgroundColor: contentColor,
        }}
      />
      <label
        style={{
          fontSize: 16,
          color: colors.checked,
        }}
        htmlFor={id}
      >
        {children}
      </label>
    </div>
  );
}
