export const cnaes = [
  { cnae: "01113", descricao: "CULTIVO DE CEREAIS" },
  {
    cnae: "0111301",
    descricao:
      "ARROZ QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO; BENEFICIAMENTO DE",
  },
  { cnae: "0111301", descricao: "ARROZ; CULTIVO DE" },
  {
    cnae: "0111301",
    descricao:
      "SEMENTE DE ARROZ (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0111302", descricao: "MILHO; CULTIVO DE" },
  {
    cnae: "0111302",
    descricao:
      "MILHO QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO; BENEFICIAMENTO DE",
  },
  {
    cnae: "0111302",
    descricao:
      "SEMENTE DE MILHO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  {
    cnae: "0111303",
    descricao:
      "TRIGO QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO; BENEFICIAMENTO DE",
  },
  { cnae: "0111303", descricao: "TRIGO; CULTIVO DE" },
  {
    cnae: "0111303",
    descricao:
      "SEMENTE DE TRIGO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0111399", descricao: "CENTEIO; CULTIVO DE" },
  { cnae: "0111399", descricao: "CEVADA; CULTIVO DE" },
  {
    cnae: "0111399",
    descricao:
      "SEMENTE DE TRIGO PRETO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  {
    cnae: "0111399",
    descricao:
      "SEMENTE DE CEVADA (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  {
    cnae: "0111399",
    descricao:
      "SEMENTE DE PAINCO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0111399", descricao: "ALPISTE; CULTIVO DE" },
  {
    cnae: "0111399",
    descricao:
      "SEMENTE DE ALPISTE (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0111399", descricao: "MILHETO; CULTIVO DE" },
  { cnae: "0111399", descricao: "PAINCO; CULTIVO DE" },
  {
    cnae: "0111399",
    descricao:
      "SEMENTE DE CENTEIO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0111399", descricao: "TRIGO PRETO; CULTIVO DE" },
  { cnae: "0111399", descricao: "TRIGO MOURISCO; CULTIVO DE" },
  {
    cnae: "0111399",
    descricao:
      "SEMENTE DE MILHETO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0111399", descricao: "TRITICALE; CULTIVO DE" },
  {
    cnae: "0111399",
    descricao:
      "SEMENTE DE TRIGO SARRACENO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  {
    cnae: "0111399",
    descricao:
      "SEMENTE DE SORGO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  {
    cnae: "0111399",
    descricao:
      "SEMENTE DE TRITICALE (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0111399", descricao: "TRIGO SARRACENO; CULTIVO DE" },
  { cnae: "0111399", descricao: "SORGO; CULTIVO DE" },
  { cnae: "0111399", descricao: "AVEIA; CULTIVO DE" },
  {
    cnae: "0111399",
    descricao:
      "SEMENTE DE TRIGO MOURISCO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  {
    cnae: "0111399",
    descricao:
      "SEMENTE DE AVEIA (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  {
    cnae: "01121",
    descricao:
      "CULTIVO DE ALGODAO HERBACEO E DE OUTRAS FIBRAS DE LAVOURA TEMPORARIA",
  },
  {
    cnae: "0112101",
    descricao:
      "ALGODAO QUANDO ATIVIDADE COMPLETAR AO CULTIVO; DESCAROCAMENTO DE",
  },
  {
    cnae: "0112101",
    descricao:
      "CAROCO DE ALGODAO HERBACEO, QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO; PRODUCAO DE",
  },
  {
    cnae: "0112101",
    descricao:
      "PLUMA DE ALGODAO HERBACEO, QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO; PRODUCAO DE",
  },
  { cnae: "0112101", descricao: "LINHO FIBRA; CULTIVO DE" },
  {
    cnae: "0112101",
    descricao:
      "SEMENTE DE ALGODAO HERBACEO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0112101", descricao: "ALGODAO HERBACEO; CULTIVO DE" },
  {
    cnae: "0112102",
    descricao: "MACERACAO E SECAGEM DA FIBRA DE JUTA; PROCESSO DE",
  },
  { cnae: "0112102", descricao: "JUTA; CULTIVO DE" },
  {
    cnae: "0112102",
    descricao:
      "MUDAS DE JUTA (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  {
    cnae: "0112199",
    descricao:
      "SEMENTES DE SORGO VASSOURA (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0112199", descricao: "RAMI; CULTIVO DE" },
  {
    cnae: "0112199",
    descricao:
      "RIZOMAS DE RAMI (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  {
    cnae: "0112199",
    descricao: "FIBRAS TEXTEIS DE LAVOURA TEMPORARIA; CULTIVO DE",
  },
  { cnae: "0112199", descricao: "FIBRAS DE LAVOURA TEMPORARIA; CULTIVO DE" },
  { cnae: "0112199", descricao: "MALVA; CULTIVO DE" },
  {
    cnae: "0112199",
    descricao:
      "MUDAS DE MALVA (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0112199", descricao: "JUNCO; CULTIVO DE" },
  {
    cnae: "0112199",
    descricao:
      "SEMENTE DE MALVA (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0112199", descricao: "SORGO VASSOURA; CULTIVO DE" },
  { cnae: "01130", descricao: "CULTIVO DE CANADEACUCAR" },
  {
    cnae: "0113000",
    descricao:
      "MUDAS DE CANA DE ACUCAR QUANDO COMPLEMENTAR AO CULTIVO; PRODUCAO DE",
  },
  { cnae: "0113000", descricao: "CANA DE ACUCAR; CULTIVO DE" },
  {
    cnae: "0113000",
    descricao:
      "TOLETES DE CANA DE ACUCAR QUANDO COMPLEMENTAR AO CULTIVO; PRODUCAO DE",
  },
  { cnae: "01148", descricao: "CULTIVO DE FUMO" },
  {
    cnae: "0114800",
    descricao:
      "FUMO (SECAGEM, DEFUMACAO E OUTROS PROCESSOS) QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO; BENEFICIAMENTO DE",
  },
  {
    cnae: "0114800",
    descricao:
      "FUMO - SECAGEM, DEFUMACAO E OUTROS PROCESSOS - QUANDO REALIZADO JUNTAMENTE AO CULTIVO; PROCESSAMENTO DE",
  },
  { cnae: "0114800", descricao: "FUMO; CULTIVO DE" },
  {
    cnae: "0114800",
    descricao:
      "SEMENTE DE FUMO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0114800", descricao: "TABACO; CULTIVO DE" },
  { cnae: "01156", descricao: "CULTIVO DE SOJA" },
  {
    cnae: "0115600",
    descricao:
      "SEMENTE DE SOJA (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0115600", descricao: "SOJA; CULTIVO DE" },
  {
    cnae: "01164",
    descricao: "CULTIVO DE OLEAGINOSAS DE LAVOURA TEMPORARIA, EXCETO SOJA",
  },
  {
    cnae: "0116401",
    descricao:
      "SEMENTE DE AMENDOIM (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0116401", descricao: "AMENDOIM; CULTIVO DE" },
  {
    cnae: "0116402",
    descricao:
      "SEMENTE DE GIRASSOL (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0116402", descricao: "GIRASSOL; CULTIVO DE" },
  { cnae: "0116403", descricao: "MAMONA; CULTIVO DE" },
  {
    cnae: "0116403",
    descricao: "MUDAS DE MAMONA (QUANDO COMPLEMENTAR AO CULTIVO); PRODUCAO DE",
  },
  {
    cnae: "0116499",
    descricao:
      "SEMENTE DE LINHO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0116499", descricao: "LINHO; CULTIVO DE" },
  {
    cnae: "0116499",
    descricao: "OLEAGINOSAS DE LAVOURA TEMPORARIA; CULTIVO DE",
  },
  { cnae: "0116499", descricao: "COLZA; CULTIVO DE" },
  { cnae: "0116499", descricao: "GERGELIM; CULTIVO DE" },
  {
    cnae: "0116499",
    descricao:
      "SEMENTE DE COLZA (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  {
    cnae: "0116499",
    descricao:
      "SEMENTE DE GERGELIM (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  {
    cnae: "01199",
    descricao:
      "CULTIVO DE PLANTAS DE LAVOURA TEMPORARIA NAO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "0119901", descricao: "ABACAXI PEROLA; CULTIVO DE" },
  { cnae: "0119901", descricao: "ABACAXI AVAI; CULTIVO DE" },
  { cnae: "0119901", descricao: "ABACAXI; CULTIVO DE" },
  {
    cnae: "0119901",
    descricao: "MUDA DE ABACAXI (QUANDO COMPLEMENTAR AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0119901", descricao: "ABACAXI HAWAII; CULTIVO DE" },
  { cnae: "0119901", descricao: "ANANAS (ABACAXI); CULTIVO DE" },
  {
    cnae: "0119902",
    descricao:
      "SEMENTES E MUDAS DE ALHO QUANDO COMPLEMENTAR AO CULTIVO; PRODUCAO DE",
  },
  { cnae: "0119902", descricao: "ALHO; CULTIVO DE" },
  { cnae: "0119903", descricao: "BATATA; CULTIVO DE" },
  { cnae: "0119903", descricao: "BATATA INGLESA; CULTIVO DE" },
  {
    cnae: "0119903",
    descricao:
      "MUDA DE BATATA INGLESA (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0121101", descricao: "ALCACHOFRA; CULTIVO DE" },
  {
    cnae: "0119903",
    descricao:
      "BATATA SEMENTE (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0119903", descricao: "BATATINHA; CULTIVO DE" },
  { cnae: "0119904", descricao: "CEBOLAS; CULTIVO DE" },
  {
    cnae: "0119904",
    descricao:
      "SEMENTES E MUDAS DE CEBOLA QUANDO COMPLEMENTAR AO CULTIVO; PRODUCAO DE",
  },
  { cnae: "0119904", descricao: "CEBOLA; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO ESPANHA; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO DE CORDA OU MACACAR; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO CARIOQUINHA; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO CAUPI; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO AZUKI; CULTIVO DE" },
  {
    cnae: "0119905",
    descricao:
      "SEMENTE DE FEIJAO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0119905", descricao: "FEIJAO BRANCO; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO ROXINHO; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO CANARIO; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO RAJADINHO; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO CARIOCA; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO JALO; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO FRADINHO; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO MUNGO; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO MULATINHO; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO ADZUKI; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO COMUM DE COR; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO ROSINHA; CULTIVO DE" },
  { cnae: "0119905", descricao: "FEIJAO COMUM PRETO; CULTIVO DE" },
  {
    cnae: "0119906",
    descricao:
      "MANIVAS (MUDAS DE MANDIOCA, MACAXEIRA OU AIPIM), QUANDO COMPLEMENTAR AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0119906", descricao: "MANDIOCA; CULTIVO DE" },
  { cnae: "0119906", descricao: "MACAXEIRA; CULTIVO DE" },
  { cnae: "0119906", descricao: "AIPIM; CULTIVO DE" },
  {
    cnae: "0119907",
    descricao:
      "SEMENTE DE MELAO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0119907", descricao: "MELAO; CULTIVO DE" },
  {
    cnae: "0119908",
    descricao:
      "SEMENTE DE MELANCIA (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0119908", descricao: "MELANCIA; CULTIVO DE" },
  {
    cnae: "0119909",
    descricao:
      "SEMENTE DE TOMATE INDUSTRIAL (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0119909", descricao: "TOMATE INDUSTRIAL; CULTIVO DE" },
  { cnae: "0119909", descricao: "TOMATE RASTEIRO; CULTIVO DE" },
  {
    cnae: "0119909",
    descricao:
      "SEMENTE DE TOMATE RASTEIRO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0119999", descricao: "BRAQUIARA FORRAGEIRA; CULTIVO DE" },
  { cnae: "0119999", descricao: "ABOBORA; CULTIVO DE" },
  { cnae: "0119999", descricao: "MILHO FORRAGEIRO; CULTIVO DE" },
  { cnae: "0119999", descricao: "CANA FORRAGEIRA PARA CORTE; CULTIVO DE" },
  { cnae: "0119999", descricao: "CANA FORRAGEIRA; CULTIVO DE" },
  {
    cnae: "0119999",
    descricao: "CAPIM COLONIAO OU COLONIA FORRAGEIRA PARA CORTE; CULTIVO DE",
  },
  { cnae: "0119999", descricao: "FAVA; CULTIVO DE" },
  { cnae: "0119999", descricao: "FEIJAO DE LIMA; CULTIVO DE" },
  { cnae: "0119999", descricao: "TREVO FORRAGEIRO; CULTIVO DE" },
  { cnae: "0119999", descricao: "ALFAFA (FORRAGEIRA); CULTIVO DE" },
  { cnae: "0119999", descricao: "VETIVER; CULTIVO DE" },
  { cnae: "0119999", descricao: "AVEIA FORRAGEIRA PARA CORTE; CULTIVO DE" },
  { cnae: "0119999", descricao: "MILHO (FORRAGEIRA PARA CORTE); CULTIVO DE" },
  {
    cnae: "0119999",
    descricao: "CAPIM ELEFANTE FORRAGEIRA PARA CORTE; CULTIVO DE",
  },
  { cnae: "0119999", descricao: "MUCUNA FORRAGEIRA; CULTIVO DE" },
  {
    cnae: "0119999",
    descricao: "RAIZES E TUBERCULOS DE LAVOURA TEMPORARIA; CULTIVO DE",
  },
  { cnae: "0119999", descricao: "JERIMUM; CULTIVO DE" },
  { cnae: "0119999", descricao: "ALFAFA (FORRAGEIRA PARA CORTE); CULTIVO DE" },
  { cnae: "0119999", descricao: "PALMAROSA; CULTIVO DE" },
  {
    cnae: "0119999",
    descricao:
      "SEMENTE DE ABOBORA (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0119999", descricao: "SORGO FORRAGEIRO; CULTIVO DE" },
  { cnae: "0119999", descricao: "ALFAFA; CULTIVO DE" },
  { cnae: "0119999", descricao: "CAPIM ELEFANTE FORRAGEIRA; CULTIVO DE" },
  { cnae: "0119999", descricao: "ERVILHA EM GRAO; CULTIVO DE" },
  {
    cnae: "0119999",
    descricao: "LEGUMINOSAS DE LAVOURA TEMPORARIA; CULTIVO DE",
  },
  { cnae: "0119999", descricao: "AZEVEM FORRAGEIRA PARA CORTE; CULTURA DE" },
  {
    cnae: "0119999",
    descricao: "CAPIM GORDURA FORRAGEIRA PARA CORTE; CULTIVO DE",
  },
  { cnae: "0119999", descricao: "MUCUNA; CULTIVO DE" },
  { cnae: "0119999", descricao: "AZEVEM FORRAGEIRA; CULTIVO DE" },
  { cnae: "0119999", descricao: "CORNICHAO FORRAGEIRA PARA CORTE; CULTIVO DE" },
  {
    cnae: "0119999",
    descricao:
      "SEMENTE DE FEIJAO VERDE (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0119999", descricao: "PALMA FORRAGEIRA; CULTIVO DE" },
  {
    cnae: "0119999",
    descricao: "CROTALARIA FORRAGEIRA PARA CORTE; CULTIVO DE",
  },
  {
    cnae: "0119999",
    descricao: "BRAQUIARIA FORRAGEIRA PARA CORTE; CULTIVO DE",
  },
  { cnae: "0119999", descricao: "FORRAGEIRA PARA CORTE; CULTIVO DE" },
  {
    cnae: "0119999",
    descricao: "CAPIM JARAGUA FORRAGEIRA PARA CORTE; CULTIVO DE",
  },
  { cnae: "0119999", descricao: "CEVADA FORRAGEIRA PARA CORTE; CULTIVO DE" },
  { cnae: "0119999", descricao: "ABOBORA MORANGA; CULTIVO DE" },
  { cnae: "0119999", descricao: "TREMOCO FORRAGEIRO; CULTIVO DE" },
  { cnae: "0119999", descricao: "CROTOLARIA FORRAGEIRA; CULTIVO DE" },
  { cnae: "0119999", descricao: "CORNICHAO FORRAGEIRA; CULTIVO DE" },
  { cnae: "0119999", descricao: "FEIJAO VERDE; CULTIVO DE" },
  {
    cnae: "0119999",
    descricao:
      "SEMENTE DE JERIMUM (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "01211", descricao: "HORTICULTURA" },
  { cnae: "0121101", descricao: "CEROFOLIO; CULTIVO DE" },
  { cnae: "0121101", descricao: "REPOLHO CRESPO; CULTIVO DE" },
  { cnae: "0121101", descricao: "ASPARGO; CULTIVO DE" },
  { cnae: "0121101", descricao: "RUIBARBO; CULTIVO DE" },
  { cnae: "0121101", descricao: "MANGARITO; CULTIVO DE" },
  { cnae: "0121101", descricao: "ALFACE; CULTIVO DE" },
  { cnae: "0121101", descricao: "PICAO-DO-CAMPO; CULTIVO DE" },
  {
    cnae: "0121101",
    descricao:
      "CHICORIA (CHICORIA-DE-FOLHA-CRESPA, CHICORIA-DE-FOLHA-LISA, ENDIVIA, ESCAROLA); CULTIVO DE",
  },
  { cnae: "0121101", descricao: "ALFAVACA; CULTIVO DE" },
  { cnae: "0121101", descricao: "AIPO; CULTIVO DE" },
  { cnae: "0121101", descricao: "ACAFROA; CULTIVO DE" },
  { cnae: "0121101", descricao: "LENTILHA; CULTIVO DE" },
  { cnae: "0121101", descricao: "MAXIXE; CULTIVO DE" },
  { cnae: "0121101", descricao: "TREMOCO; CULTIVO DE" },
  {
    cnae: "0121101",
    descricao:
      "SEMENTE DE TOMATE ESTAQUEADO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0121101", descricao: "ERVA CIDREIRA; CULTIVO DE" },
  { cnae: "0121101", descricao: "COUVE-RABANO; CULTIVO DE" },
  { cnae: "0121101", descricao: "GUANDU; CULTIVO DE" },
  { cnae: "0121101", descricao: "SALVA; CULTIVO DE" },
  { cnae: "0121101", descricao: "MELAO-DE-SAO-CAETANO; CULTIVO DE" },
  { cnae: "0155505", descricao: "OVOS DE GANSA; PRODUCAO DE" },
  {
    cnae: "0121101",
    descricao:
      "SEMENTE DE BATATA-DOCE (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0121101", descricao: "ESPINAFRE-DE-NOVA-ZELANDIA; CULTIVO DE" },
  { cnae: "0121101", descricao: "GOBO; CULTIVO DE" },
  { cnae: "0121101", descricao: "SALVIA; CULTIVO DE" },
  { cnae: "0121101", descricao: "ABSINTO (LOSNA); CULTIVO DE" },
  { cnae: "0121101", descricao: "BERINJELA; CULTIVO DE" },
  { cnae: "0121101", descricao: "MILHO VERDE; CULTIVO DE" },
  { cnae: "0121101", descricao: "SALSAO; CULTIVO DE" },
  { cnae: "0121101", descricao: "BELDROEGA; CULTIVO DE" },
  { cnae: "0121101", descricao: "OREGANO; CULTIVO DE" },
  { cnae: "0121101", descricao: "SALSAPARRILHA; CULTIVO DE" },
  { cnae: "0121101", descricao: "BARDANA; CULTIVO DE" },
  { cnae: "0121101", descricao: "ARALIA; CULTIVO DE" },
  { cnae: "0121101", descricao: "COUVE-MINEIRA; CULTIVO DE" },
  { cnae: "0121101", descricao: "GENGIBRE; CULTIVO DE" },
  { cnae: "0121101", descricao: "COUVE-FLOR; CULTIVO DE" },
  { cnae: "0121101", descricao: "HORTELA; CULTIVO DE" },
  { cnae: "0121101", descricao: "GUANDO; CULTIVO DE" },
  { cnae: "0121101", descricao: "ORA-PRO-NOBIS; CULTIVO DE" },
  { cnae: "0121101", descricao: "TOMILHO; CULTIVO DE" },
  { cnae: "0121101", descricao: "COENTRO; CULTIVO DE" },
  {
    cnae: "0121101",
    descricao: "HORTALICAS ORGANICAS, EXCETO MORANGO; CULTIVO DE",
  },
  { cnae: "0121101", descricao: "BETERRABA; CULTIVO DE" },
  { cnae: "0121101", descricao: "JAMBU; CULTIVO DE" },
  { cnae: "0121101", descricao: "ALHO PORRO; CULTIVO DE" },
  { cnae: "0121101", descricao: "ARRUDA; CULTIVO DE" },
  { cnae: "0121101", descricao: "REPOLHO; CULTIVO DE" },
  { cnae: "0121101", descricao: "CHUCHU; CULTIVO DE" },
  { cnae: "0121101", descricao: "CARA; CULTIVO DE" },
  { cnae: "0121101", descricao: "ACAFRAO; CULTIVO DE" },
  { cnae: "0121101", descricao: "RADITE; CULTIVO DE" },
  { cnae: "0121101", descricao: "ABOBRINHA; CULTIVO DE" },
  { cnae: "0121101", descricao: "SALSA; CULTIVO DE" },
  { cnae: "0121101", descricao: "TOMATE ESTAQUEADO; CULTIVO DE" },
  { cnae: "0121101", descricao: "POEJO; CULTIVO DE" },
  { cnae: "0121101", descricao: "AGRIAO; CULTIVO DE" },
  { cnae: "0121101", descricao: "BALDIANA; CULTIVO DE" },
  { cnae: "0121101", descricao: "PIMENTA; CULTIVO DE" },
  { cnae: "0121101", descricao: "BATATA DOCE; CULTIVO DE" },
  { cnae: "0121101", descricao: "BABOSA; CULTIVO DE" },
  { cnae: "0121101", descricao: "ESTEVIA; CULTIVO DE" },
  { cnae: "0121101", descricao: "BATATA BAROA; CULTIVO DE" },
  { cnae: "0121101", descricao: "ALOE; CULTIVO DE" },
  { cnae: "0121101", descricao: "CAPIM LIMAO; CULTIVO DE" },
  { cnae: "0121101", descricao: "QUIABO; CULTIVO DE" },
  { cnae: "0121101", descricao: "ALCACUZ; CULTIVO DE" },
  { cnae: "0121101", descricao: "RABANETE; CULTIVO DE" },
  { cnae: "0121101", descricao: "PEPINO; CULTIVO DE" },
  { cnae: "0121101", descricao: "BOLDO; CULTIVO DE" },
  { cnae: "0121101", descricao: "ALMEIRAO; CULTIVO DE" },
  {
    cnae: "0121101",
    descricao: "ESCAROLA (CHICORIA DE FOLHA LISA); CULTIVO DE",
  },
  { cnae: "0121101", descricao: "FUNCHO; CULTIVO DE" },
  { cnae: "0121101", descricao: "CATALONHA; CULTIVO DE" },
  { cnae: "0121101", descricao: "RABANO; CULTIVO DE" },
  { cnae: "0121101", descricao: "CEBOLINHA; CULTIVO DE" },
  { cnae: "0121101", descricao: "MANJERICAO; CULTIVO DE" },
  { cnae: "0121101", descricao: "ALCAPARRAS; CULTIVO DE" },
  { cnae: "0121101", descricao: "CARURU; CULTIVO DE" },
  { cnae: "0121101", descricao: "COMINHO; CULTIVO DE" },
  { cnae: "0121101", descricao: "FEIJAO VAGEM; CULTIVO DE" },
  { cnae: "0121101", descricao: "COUVE-MANTEIGA; CULTIVO DE" },
  { cnae: "0121101", descricao: "MOSTARDA; CULTIVO DE" },
  { cnae: "0121101", descricao: "CABACA PURUNGA; CULTIVO DE" },
  { cnae: "0121101", descricao: "ARARUTA; CULTIVO DE" },
  { cnae: "0121101", descricao: "ABOBRINHA VERDE; CULTIVO DE" },
  { cnae: "0121101", descricao: "COUVE CRESPA; CULTIVO DE" },
  { cnae: "0121101", descricao: "ALFACE AMERICANA; CULTIVO DE" },
  { cnae: "0121101", descricao: "COGUMELOS; CULTIVO DE" },
  { cnae: "0121101", descricao: "NABICA; CULTIVO DE" },
  { cnae: "0121101", descricao: "CAMOMILA; CULTIVO DE" },
  { cnae: "0121101", descricao: "CENOURA; CULTIVO DE" },
  { cnae: "0121101", descricao: "ALECRIM; CULTIVO DE" },
  { cnae: "0121101", descricao: "RADICHIO; CULTIVO DE" },
  { cnae: "0121101", descricao: "GRAO-DE-BICO; CULTIVO DE" },
  { cnae: "0121101", descricao: "BUCHA (ESPONJA VEGETAL); CULTIVO DE" },
  { cnae: "0121101", descricao: "COUVE-NABO; CULTIVO DE" },
  { cnae: "0121101", descricao: "AZEDINHA; CULTIVO DE" },
  { cnae: "0121101", descricao: "VAGEM OU FEIJAO-VAGEM; CULTIVO DE" },
  { cnae: "0121101", descricao: "MANDIOQUINHA-SALSA; CULTIVO DE" },
  {
    cnae: "0121101",
    descricao: "HORTALICAS HIDROPONICAS, EXCETO MORANGO; CULTIVO DE",
  },
  { cnae: "0121101", descricao: "ACELGA; CULTIVO DE" },
  { cnae: "0121101", descricao: "BERTALHA; CULTIVO DE" },
  { cnae: "0121101", descricao: "LOSNA; CULTIVO DE" },
  { cnae: "0121101", descricao: "CURCUMA; PRODUCAO DE" },
  { cnae: "0121101", descricao: "RUCULA OU PINCHAO; CULTIVO DE" },
  { cnae: "0121101", descricao: "BRANQUINHA; CULTIVO DE" },
  { cnae: "0121101", descricao: "MENTA; CULTIVO DE" },
  { cnae: "0121101", descricao: "INHAME; CULTIVO DE" },
  {
    cnae: "0121101",
    descricao:
      "CHEIRO VERDE (CULTIVO DE SALSA E CULTIVO DE CEBOLINHA); CULTIVO DE",
  },
  { cnae: "0121101", descricao: "COUVE TRONCHUDA; CULTIVO DE" },
  { cnae: "0121101", descricao: "BROCOLIS; CULTIVO DE" },
  { cnae: "0121101", descricao: "COUVE-CATALONHA; CULTIVO DE" },
  { cnae: "0121101", descricao: "ANIS ESTRELADO; CULTIVO DE" },
  { cnae: "0121101", descricao: "SERRALHA; CULTIVO DE" },
  { cnae: "0121101", descricao: "COUVE; CULTIVO DE" },
  { cnae: "0121101", descricao: "PIMENTAO; CULTIVO DE" },
  { cnae: "0121101", descricao: "MANJERONA; CULTIVO DE" },
  { cnae: "0121101", descricao: "HORTELA-PIMENTA; CULTIVO DE" },
  { cnae: "0121101", descricao: "COUVE-DE-BRUXELAS; CULTIVO DE" },
  { cnae: "0121101", descricao: "CEREFOLIO; CULTIVO DE" },
  { cnae: "0121101", descricao: "ESPINAFRE; CULTIVO DE" },
  { cnae: "0121101", descricao: "SEGURELHA; CULTIVO DE" },
  { cnae: "0121101", descricao: "JILO; CULTIVO DE" },
  { cnae: "0121101", descricao: "ERVILHA EM VAGEM; CULTIVO DE" },
  { cnae: "0121101", descricao: "ERVA-DOCE; CULTIVO DE" },
  { cnae: "0121101", descricao: "TAIOBA; CULTIVO DE" },
  { cnae: "0121101", descricao: "CHICORIA AMARGA OU ALMEIRAO; CULTIVO DE" },
  { cnae: "0121101", descricao: "COUVE-CHINESA; CULTIVO DE" },
  { cnae: "0121101", descricao: "NABO; CULTIVO DE" },
  {
    cnae: "0121102",
    descricao:
      "SEMENTES E MUDAS DE MORANGO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0121102", descricao: "MORANGO HIDROPONICO; CULTIVO DE" },
  { cnae: "0121102", descricao: "MORANGO; CULTIVO DE" },
  { cnae: "0121102", descricao: "MORANGO ORGANICO; CULTIVO DE" },
  { cnae: "01229", descricao: "CULTIVO DE FLORES E PLANTAS ORNAMENTAIS" },
  { cnae: "0122900", descricao: "MARGARIDAS; CULTIVO DE" },
  { cnae: "0122900", descricao: "PALMEIRAS; CULTIVO DE" },
  {
    cnae: "0122900",
    descricao: "GREVILHA DE JARDIM (GREVILLEA BANKSII); CULTIVO DE",
  },
  { cnae: "0122900", descricao: "FIGO-DA-INDIA; CULTIVO DE" },
  { cnae: "0122900", descricao: "GLADIOLOS; CULTIVO DE" },
  {
    cnae: "0122900",
    descricao: "PLANTAS PARA PAISAGISMO E JARDINO-CULTURA; CULTIVO DE",
  },
  { cnae: "0122900", descricao: "CRISANTEMOS; CULTIVO DE" },
  {
    cnae: "0122900",
    descricao:
      "SEMENTES E MUDAS CERTIFICADAS DE FLORES E PLANTAS ORNAMENTAIS (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0122900", descricao: "CRAVOS; CULTIVO DE" },
  { cnae: "0122900", descricao: "VIOLETA-AFRICANA; CULTIVO DE" },
  { cnae: "0122900", descricao: "PLANTAS PARA FORRACOES; CULTIVO DE" },
  { cnae: "0122900", descricao: "FLORES PARA CORTE; CULTIVO DE" },
  { cnae: "0122900", descricao: "SAMAMBAIAS" },
  { cnae: "0122900", descricao: "PALMAS; CULTIVO DE" },
  { cnae: "0122900", descricao: "PLANTAS AQUATICAS; CULTIVO DE" },
  { cnae: "0122900", descricao: "VIOLETAS; CULTIVO DE" },
  { cnae: "0122900", descricao: "ORQUIDEAS; CULTIVO DE" },
  { cnae: "0122900", descricao: "FOLHAGENS PARA CORTE; CULTIVO DE" },
  { cnae: "0122900", descricao: "ANTURIOS; CULTIVO DE" },
  { cnae: "0122900", descricao: "PLANTAS DE FLOR EM VASO; CULTIVO DE" },
  { cnae: "0122900", descricao: "GRAMAS; CULTIVO DE" },
  { cnae: "0122900", descricao: "ROSAS; CULTIVO DE" },
  { cnae: "0122900", descricao: "FOLHAGENS EM VASO; CULTIVO DE" },
  { cnae: "0122900", descricao: "LIRIOS; CULTIVO DE" },
  { cnae: "01318", descricao: "CULTIVO DE LARANJA" },
  {
    cnae: "0131800",
    descricao:
      "MUDAS DE LARANJEIRA (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0131800", descricao: "LARANJA; CULTIVO DE" },
  { cnae: "0131800", descricao: "LARANJA LIMA; CULTIVO DE" },
  { cnae: "0131800", descricao: "LARANJA BAIA; CULTIVO DE" },
  { cnae: "0131800", descricao: "LARANJA PERA; CULTIVO DE" },
  { cnae: "0131800", descricao: "LARANJA DA TERRA; CULTIVO DE" },
  { cnae: "0131800", descricao: "LARANJA SELETA; CULTIVO DE" },
  { cnae: "01326", descricao: "CULTIVO DE UVA" },
  { cnae: "0132600", descricao: "VITICULTURA; ATIVIDADES DE" },
  { cnae: "0132600", descricao: "UVA; CULTIVO DE" },
  {
    cnae: "0132600",
    descricao:
      "MUDAS DE UVA (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  {
    cnae: "0132600",
    descricao: "SUCO DE UVA NA UNIDADE AGRICOLA; PRODUCAO DE",
  },
  { cnae: "0132600", descricao: "VIDEIRA; CULTIVO DE" },
  { cnae: "0132600", descricao: "VINHO NA UNIDADE AGRICOLA; PRODUCAO DE" },
  {
    cnae: "01334",
    descricao: "CULTIVO DE FRUTAS DE LAVOURA PERMANENTE, EXCETO LARANJA E UVA",
  },
  { cnae: "0133401", descricao: "FRUTO DE ACAI; PRODUCAO DE" },
  { cnae: "0133401", descricao: "PALMITO DE ACAI; PRODUCAO DE" },
  { cnae: "0133401", descricao: "ACAI; CULTIVO DE" },
  { cnae: "0133402", descricao: "BANANA DA TERRA; CULTIVO DE" },
  { cnae: "0133402", descricao: "BANANA; CULTIVO DE" },
  { cnae: "0133402", descricao: "BANANA MACA; CULTIVO DE" },
  { cnae: "0133402", descricao: "BANANA NANICA; CULTIVO DE" },
  { cnae: "0133402", descricao: "BANANA CATURRA; CULTIVO DE" },
  { cnae: "0133402", descricao: "BANANA OURO; CULTIVO DE" },
  { cnae: "0133402", descricao: "BANANA PRATA; CULTIVO DE" },
  { cnae: "0133403", descricao: "CAJU; CULTIVO DE" },
  { cnae: "0133404", descricao: "TANGELO; CULTIVO DE" },
  { cnae: "0133404", descricao: "LIMA; CULTIVO DE" },
  { cnae: "0133404", descricao: "FRUTAS CITRICAS; CULTIVO DE" },
  { cnae: "0133404", descricao: "LIMAO; CULTIVO DE" },
  { cnae: "0133404", descricao: "TORANJA; CULTIVO DE" },
  { cnae: "0133404", descricao: "MEXERICA; CULTIVO DE" },
  { cnae: "0133404", descricao: "CIDRA; CULTIVO DE" },
  { cnae: "0133404", descricao: "CITRICOS; CULTIVO DE" },
  {
    cnae: "0133404",
    descricao:
      "MUDAS DE LIMAO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0133404", descricao: "GRAPE-FRUIT; CULTIVO DE" },
  { cnae: "0133404", descricao: "POMELO; CULTIVO DE" },
  { cnae: "0133404", descricao: "KINKAN; CULTIVO DE" },
  {
    cnae: "0133404",
    descricao:
      "MUDAS DE TANGERINA (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0133404", descricao: "TANGERINA; CULTIVO DE" },
  { cnae: "0133404", descricao: "PONCA; PRODUCAO DE" },
  { cnae: "0133404", descricao: "TANGOR; CULTIVO DE" },
  { cnae: "0133404", descricao: "BERGAMOTA; CULTIVO DE" },
  { cnae: "0133405", descricao: "COCO-DA-BAIA; CULTIVO DE" },
  { cnae: "0133406", descricao: "GUARANA; CULTIVO DE" },
  { cnae: "0133407", descricao: "MACA; CULTIVO DE" },
  { cnae: "0133408", descricao: "MAMAO; CULTIVO DE" },
  { cnae: "0133409", descricao: "MARACUJA; CULTIVO DE" },
  { cnae: "0133410", descricao: "MANGA; CULTIVO DE" },
  { cnae: "0133411", descricao: "PESSEGO; CULTIVO DE" },
  { cnae: "0133499", descricao: "ARATICUM; CULTIVO DE" },
  { cnae: "0133499", descricao: "NOZ PECA; CULTIVO DE" },
  { cnae: "0133499", descricao: "CAJA-MANGA; CULTIVO DE" },
  { cnae: "0133499", descricao: "MARMELO; CULTIVO DE" },
  { cnae: "0133499", descricao: "MACADAMIA; CULTIVO DE" },
  { cnae: "0133499", descricao: "MANGABEIRA; CULTIVO DE" },
  { cnae: "0133499", descricao: "FRUTA-DE-CONDE; CULTIVO DE" },
  { cnae: "0133499", descricao: "JAMBO; CULTIVO DE" },
  { cnae: "0133499", descricao: "MANGUSTAO; CULTIVO DE" },
  { cnae: "0133499", descricao: "ROMA; CULTIVO DE" },
  { cnae: "0133499", descricao: "ABACATE; CULTIVO DE" },
  { cnae: "0133499", descricao: "AMORA; CULTIVO DE" },
  { cnae: "0133499", descricao: "SAPOTI; CULTIVO DE" },
  { cnae: "0133499", descricao: "ACEROLA; CULTIVO DE" },
  { cnae: "0133499", descricao: "GOIABA; CULTIVO DE" },
  { cnae: "0133499", descricao: "LICHIA; CULTIVO DE" },
  { cnae: "0133499", descricao: "CASTANHA EUROPEIA; CULTIVO DE" },
  { cnae: "0133499", descricao: "PERA; CULTIVO DE" },
  { cnae: "0133499", descricao: "FIGO; CULTIVO DE" },
  { cnae: "0133499", descricao: "NOZ MACADAMIA; CULTIVO DE" },
  { cnae: "0133499", descricao: "JOJOBA; CULTIVO DE" },
  { cnae: "0133499", descricao: "TAMARA; CULTIVO DE" },
  { cnae: "0133499", descricao: "JABUTICABA; CULTIVO DE" },
  { cnae: "0133499", descricao: "CAMU-CAMU; CULTIVO DE" },
  { cnae: "0133499", descricao: "CEREJA OU CEREJA-DA-EUROPA; CULTIVO DE" },
  { cnae: "0133499", descricao: "CARAMBOLA; CULTIVO DE" },
  { cnae: "0133499", descricao: "CUPUACU; CULTIVO DE" },
  { cnae: "0133499", descricao: "JAMELAO; CULTIVO DE" },
  { cnae: "0133499", descricao: "JENIPAPO; CULTIVO DE" },
  { cnae: "0133499", descricao: "PITANGA; CULTIVO DE" },
  { cnae: "0133499", descricao: "TAMARINDO; CULTIVO DE" },
  { cnae: "0133499", descricao: "CAQUI; CULTIVO DE" },
  { cnae: "0133499", descricao: "JACA; CULTIVO DE" },
  { cnae: "0133499", descricao: "NESPERA; CULTIVO DE" },
  { cnae: "0133499", descricao: "GROSELHEIRA; CULTIVO DE" },
  { cnae: "0133499", descricao: "QUIVI (QUIUI); CULTIVO DE" },
  { cnae: "0133499", descricao: "MANGABA; CULTIVO DE" },
  { cnae: "0133499", descricao: "NOZ EUROPEIA; CULTIVO DE" },
  { cnae: "0133499", descricao: "CHERIMOLIA; CULTIVO DE" },
  { cnae: "0133499", descricao: "AMEIXA; CULTIVO DE" },
  { cnae: "0133499", descricao: "ARACA; CULTIVO DE" },
  { cnae: "0133499", descricao: "SERIGUELA; CULTIVO DE" },
  { cnae: "0133499", descricao: "NECTARINA; CULTIVO DE" },
  { cnae: "0133499", descricao: "GRAVIOLA; CULTIVO DE" },
  { cnae: "0133499", descricao: "FRAMBOESA; CULTIVO DE" },
  { cnae: "01342", descricao: "CULTIVO DE CAFE" },
  {
    cnae: "0134200",
    descricao:
      "CAFE QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO; BENEFICIAMENTO DE",
  },
  { cnae: "0134200", descricao: "CAFE; CULTIVO DE" },
  {
    cnae: "0134200",
    descricao:
      "CAFE EM GRAO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO E REALIZADO POR TERCEIROS); PRODUCAO DE",
  },
  {
    cnae: "0134200",
    descricao:
      "MUDAS DE CAFE (QUANDO REALIZADA JUNTAMENTE CULTIVO); PRODUCAO DE",
  },
  {
    cnae: "0134200",
    descricao: "CAFE BENEFICIADO (ASSOCIADO AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "01351", descricao: "CULTIVO DE CACAU" },
  {
    cnae: "0135100",
    descricao:
      "CACAU QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO; BENEFICIAMENTO DE",
  },
  {
    cnae: "0135100",
    descricao:
      "MUDAS DE CACAU (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0135100", descricao: "CACAU; CULTIVO DE" },
  {
    cnae: "01393",
    descricao:
      "CULTIVO DE PLANTAS DE LAVOURA PERMANENTE NAO ESPECIFICADAS ANTERIORMENTE",
  },
  {
    cnae: "0139301",
    descricao:
      "MUDAS DE CHA-DA-INDIA (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0139301", descricao: "CHA-DA-INDIA; CULTIVO DE" },
  { cnae: "0139301", descricao: "CHA PRETO; CULTIVO DE" },
  { cnae: "0139302", descricao: "ERVA-MATE; CULTIVO DE" },
  {
    cnae: "0139302",
    descricao:
      "MUDAS DE ERVA-MATE (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0159899", descricao: "COBAIAS; CRIACAO DE" },
  {
    cnae: "0139303",
    descricao:
      "MUDAS DE PIMENTA DO REINO (QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0139303", descricao: "PIMENTA-DO-REINO; CULTIVO DE" },
  { cnae: "0139304", descricao: "LOURO; CULTIVO DE" },
  {
    cnae: "0139304",
    descricao:
      "MUDAS DE CRAVO-DA-INDIA (QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO); PRODUCAO DE",
  },
  {
    cnae: "0139304",
    descricao:
      "MUDAS DE LOURO (QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0139304", descricao: "URUCU; CULTIVO DE" },
  {
    cnae: "0139304",
    descricao:
      "MUDAS DE BAUNILHA(QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0139304", descricao: "NOZ-MOSCADA; CULTIVO DE" },
  {
    cnae: "0139304",
    descricao:
      "MUDAS DE CANELA (QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0139304", descricao: "BAUNILHA; CULTIVO DE" },
  { cnae: "0139304", descricao: "CANELA; CULTIVO DE" },
  {
    cnae: "0139304",
    descricao:
      "MUDAS DE NOZ-MOSCADA (QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0139304", descricao: "URUCUM; CULTIVO DE" },
  { cnae: "0139304", descricao: "CRAVO-DA-INDIA; CULTIVO DE" },
  {
    cnae: "0139305",
    descricao:
      "MUDAS DE DENDE (QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0139305", descricao: "DENDE (COCO); CULTIVO DE" },
  { cnae: "0139305", descricao: "COCO-DENDE; CULTIVO DE" },
  {
    cnae: "0139306",
    descricao:
      "MUDAS DE SERINGUEIRA (QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO); PRODUCAO DE",
  },
  {
    cnae: "0139306",
    descricao: "LATEX; CULTIVO DE SERINGUEIRA PARA PRODUCAO DE",
  },
  { cnae: "0139399", descricao: "ALGODAO ARBOREO; CULTIVO DE" },
  { cnae: "0139399", descricao: "PALMITO DE GUARIROBA; PRODUCAO DE" },
  { cnae: "0139399", descricao: "COQUEIRO-AMARGOSO; CULTIVO DE" },
  { cnae: "0139399", descricao: "PALMITO DE PUPUNHA; PRODUCAO DE" },
  { cnae: "0139399", descricao: "COCO DE GUEIROBA; PRODUCAO DE" },
  { cnae: "0139399", descricao: "SAGU; CULTIVO DE" },
  {
    cnae: "0139399",
    descricao:
      "DESCAROCAMENTO DE ALGODAO ARBOREO QUANDO ATIVIDADE COMPLEMENTAR AO CULTIVO",
  },
  { cnae: "0139399", descricao: "COCO DE PATI-AMARGOSO; PRODUCAO DE" },
  { cnae: "0139399", descricao: "PALMITO DE GUEROBA; PRODUCAO DE" },
  { cnae: "0139399", descricao: "PATCHOULY; CULTIVO DE" },
  { cnae: "0139399", descricao: "PATI-AMARGOSO; CULTIVO DE" },
  { cnae: "0139399", descricao: "COCO DE PUPUNHA; PRODUCAO DE" },
  { cnae: "0139399", descricao: "SISAL; CULTIVO DE" },
  { cnae: "0139399", descricao: "PALMITO; CULTIVO DE" },
  { cnae: "0139399", descricao: "PALMITO DE PATI-AMARGOSO; PRODUCAO DE" },
  { cnae: "0139399", descricao: "AGAVE; CULTIVO DE" },
  { cnae: "0139399", descricao: "TUCUMA; CULTIVO DE" },
  { cnae: "0139399", descricao: "PALMITO DE GUEIROBA; PRODUCAO DE" },
  { cnae: "0139399", descricao: "COCO DE COQUEIRO-AMARGOSO; PRODUCAO DE" },
  { cnae: "0139399", descricao: "PUPUNHA; CULTIVO DE" },
  { cnae: "0139399", descricao: "PALMITO DE COQUEIRO-AMARGOSO; PRODUCAO DE" },
  { cnae: "0139399", descricao: "AZEITONA; CULTIVO DE" },
  { cnae: "0139399", descricao: "COCO DE GUARIROBA; PRODUCAO DE" },
  { cnae: "0139399", descricao: "TUNGUE; CULTIVO DE" },
  { cnae: "0139399", descricao: "COCO DE GUEROBA; PRODUCAO DE" },
  {
    cnae: "0139399",
    descricao:
      "SEMENTE DE ALGODAO ARBOREO (QUANDO REALIZADA JUNTAMENTE AO CULTIVO); PRODUCAO DE",
  },
  { cnae: "0139399", descricao: "GUEIROBA; CULTIVO DE" },
  { cnae: "0139399", descricao: "GUEROBA; CULTIVO DE" },
  { cnae: "0139399", descricao: "GUARIROBA; CULTIVO DE" },
  { cnae: "0139399", descricao: "LUPULO; CULTIVO DE" },
  { cnae: "0139399", descricao: "VIME; CULTIVO DE" },
  { cnae: "01415", descricao: "PRODUCAO DE SEMENTES CERTIFICADAS" },
  {
    cnae: "0141501",
    descricao: "SEMENTE CERTIFICADA DE MELANCIA; PRODUCAO DE",
  },
  {
    cnae: "0141501",
    descricao: "SEMENTE CERTIFICADA DE FORRAGEIRA PARA CORTE; PRODUCAO DE",
  },
  { cnae: "0141501", descricao: "SEMENTES CERTIFICADAS DE AVEIA; PRODUCAO DE" },
  {
    cnae: "0141501",
    descricao: "SEMENTES CERTIFICADAS DE ALGODAO ARBOREO; PRODUCAO DE",
  },
  { cnae: "0141501", descricao: "SEMENTE CERTIFICADA DE SOJA; PRODUCAO DE" },
  { cnae: "0141501", descricao: "SEMENTE CERTIFICADA DE CENTEIO; PRODUCAO DE" },
  {
    cnae: "0141501",
    descricao: "SEMENTE CERTIFICADA DE TOMATE ESTAQUEADO; PRODUCAO DE",
  },
  { cnae: "0141501", descricao: "SEMENTE CERTIFICADA DE FEIJAO; PRODUCAO DE" },
  { cnae: "0141501", descricao: "SEMENTE CERTIFICADA DE SORGO; PRODUCAO DE" },
  { cnae: "0141501", descricao: "SEMENTE CERTIFICADA DE FUMO; PRODUCAO DE" },
  {
    cnae: "0141501",
    descricao: "SEMENTE CERTIFICADA DE GIRASSOL; PRODUCAO DE",
  },
  { cnae: "0141501", descricao: "SEMENTE CERTIFICADA DE CEVADA; PRODUCAO DE" },
  {
    cnae: "0141501",
    descricao:
      "SEMENTES CERTIFICADAS, EXCETO DE FORRAGEIRAS PARA PASTO; PRODUCAO DE",
  },
  { cnae: "0141501", descricao: "ALHOS-SEMENTES CERTIFICADAS; PRODUCAO DE" },
  { cnae: "0141501", descricao: "SEMENTES CERTIFICADAS DE ARROZ; PRODUCAO DE" },
  { cnae: "0141501", descricao: "SEMENTE CERTIFICADA DE TRIGO; PRODUCAO DE" },
  { cnae: "0141501", descricao: "SEMENTE CERTIFICADA DE MILHO; PRODUCAO DE" },
  {
    cnae: "0141501",
    descricao: "SEMENTES CERTIFICADAS DE ALGODAO HERBACEO; PRODUCAO DE",
  },
  {
    cnae: "0141502",
    descricao:
      "SEMENTES CERTIFICADAS DE ALFAFA, FORRAGEIRA PARA FORMACAO DE PASTO; PRODUCAO DE",
  },
  {
    cnae: "0141502",
    descricao:
      "SEMENTES CERTIFICADAS DE CROTOLARIA, FORRAGEIRA PARA FORMACAO DE PASTO; PRODUCAO DE",
  },
  {
    cnae: "0141502",
    descricao:
      "SEMENTES CERTIFICADAS DE MOMBACA, FORRAGEIRA PARA FORMACAO DE PASTO; PRODUCAO DE",
  },
  {
    cnae: "0141502",
    descricao:
      "SEMENTE CERTIFICADA PARA FORMACAO DE PASTO-FORRAGEIRAS; PRODUCAO DE",
  },
  {
    cnae: "0141502",
    descricao:
      "SEMENTES CERTIFICADAS DE AZEVEM, FORRAGEIRA PARA FORMACAO DE PASTO; PRODUCAO DE",
  },
  {
    cnae: "0141502",
    descricao:
      "SEMENTES CERTIFICADAS DE CAPIM JARAGUA, FORRAGEIRA PARA FORMACAO DE PASTO; PRODUCAO DE",
  },
  {
    cnae: "0141502",
    descricao:
      "SEMENTES CERTIFICADAS DE BRACHIARIAS, FORRAGEIRAS PARA FORMACAO DE PASTO; PRODUCAO DE",
  },
  {
    cnae: "0141502",
    descricao:
      "SEMENTES CERTIFICADAS DE FORRAGEIRAS, PARA FORMACAO DE PASTO; PRODUCAO DE",
  },
  {
    cnae: "0141502",
    descricao: "SEMENTE CERTIFICADA DE FORRAGEIRA PARA PASTO; PRODUCAO DE",
  },
  {
    cnae: "0155505",
    descricao: "ESTERCO DE GALINHAS DE POSTURA ; PRODUCAO DE",
  },
  {
    cnae: "0141502",
    descricao:
      "SEMENTES CERTIFICADAS DE CORNICHAO, FORRAGEIRA PARA FORMACAO DE PASTO; PRODUCAO DE",
  },
  {
    cnae: "0141502",
    descricao:
      "SEMENTES CERTIFICADAS DE CAPIM ELEFANTE, FORRAGEIRA PARA FORMACAO DE PASTO; PRODUCAO DE",
  },
  {
    cnae: "0141502",
    descricao: "SEMENTE CERTIFICADA PARA PASTO / FORRAGEIRAS; PRODUCAO DE",
  },
  {
    cnae: "01423",
    descricao:
      "PRODUCAO DE MUDAS E OUTRAS FORMAS DE PROPAGACAO VEGETAL, CERTIFICADAS",
  },
  { cnae: "0142300", descricao: "BATATAS-SEMENTES CERTIFICADAS; PRODUCAO DE" },
  {
    cnae: "0142300",
    descricao: "MUDAS CERTIFICADAS DE BATATA-BAROA; PRODUCAO DE",
  },
  { cnae: "0142300", descricao: "MUDAS CERTIFICADAS DE LARANJA; PRODUCAO DE" },
  { cnae: "0142300", descricao: "MUDAS CERTIFICADAS DE MACA; PRODUCAO DE" },
  {
    cnae: "0142300",
    descricao:
      "MUDAS CERTIFICADAS DE PLANTAS DE VIVEIRO, EXCETO FLORESTAIS; PRODUCAO DE",
  },
  {
    cnae: "0142300",
    descricao: "MUDAS CERTIFICADAS DE BATATA-DOCE; PRODUCAO DE",
  },
  { cnae: "0142300", descricao: "MUDAS CERTIFICADAS DE CACAU; PRODUCAO DE" },
  { cnae: "0142300", descricao: "MUDAS CERTIFICADAS; PRODUCAO DE" },
  { cnae: "0142300", descricao: "MUDAS CERTIFICADAS DE CAJU; PRODUCAO DE" },
  { cnae: "0142300", descricao: "MUDAS CERTIFICADAS DE CAFE; PRODUCAO DE" },
  { cnae: "0142300", descricao: "MUDAS CERTIFICADAS DE UVA; PRODUCAO DE" },
  {
    cnae: "0142300",
    descricao: "MUDAS CERTIFICADAS DE CANA DE ACUCAR; PRODUCAO DE",
  },
  { cnae: "0142300", descricao: "MUDAS CERTIFICADAS DE PESSEGO; PRODUCAO DE" },
  {
    cnae: "0142300",
    descricao: "MUDAS CERTIFICADAS DE SERINGUEIRA; PRODUCAO DE",
  },
  {
    cnae: "0142300",
    descricao: "MUDAS CERTIFICADAS DE BATATA-INGLESA; PRODUCAO DE",
  },
  {
    cnae: "0142300",
    descricao:
      "TOLETES CERTIFICADOS DE CANA, QUANDO NAO ASSOCIADO AO CULTIVO; PRODUCAO DE",
  },
  { cnae: "01512", descricao: "CRIACAO DE BOVINOS" },
  { cnae: "0151201", descricao: "BOVINOCULTURA PARA CORTE" },
  { cnae: "0151201", descricao: "ESTERCO DE BOVINOS DE CORTE; PRODUCAO DE" },
  { cnae: "0151201", descricao: "BOVINOS PARA CORTE; CRIACAO DE" },
  { cnae: "0151201", descricao: "GADO REPRODUTOR PARA CORTE; CRIACAO DE" },
  { cnae: "0151201", descricao: "SEMEN DE BOVINOS PARA CORTE; PRODUCAO DE" },
  { cnae: "0151201", descricao: "BOVINO REPRODUTOR PARA CORTE; CRIACAO DE" },
  { cnae: "0151202", descricao: "BOVINOCULTURA PARA LEITE" },
  { cnae: "0151202", descricao: "LEITE DE VACA; PRODUCAO DE" },
  { cnae: "0151202", descricao: "BOVINO REPRODUTOR LEITEIRO; PRODUCAO DE" },
  { cnae: "0151202", descricao: "BOVINOS PARA PRODUCAO DE LEITE; CRIACAO DE" },
  { cnae: "0151202", descricao: "SEMEN DE GADO LEITEIRO; PRODUCAO DE" },
  {
    cnae: "0151202",
    descricao: "RESFRIAMENTO DE LEITE DE VACA REALIZADO NA UNIDADE DE PRODUCAO",
  },
  { cnae: "0151202", descricao: "ESTERCO DE BOVINOS DE LEITE; PRODUCAO DE" },
  { cnae: "0151202", descricao: "GADO REPRODUTOR LEITEIRO; CRIACAO DE" },
  { cnae: "0151202", descricao: "SEMEN DE BOVINOS PARA LEITE; PRODUCAO DE" },
  { cnae: "0151203", descricao: "BOVINOS PARA TRABALHO; CRIACAO DE" },
  {
    cnae: "0151203",
    descricao: "BOVINOS EXCETO PARA LEITE E CORTE; CRIACAO DE",
  },
  { cnae: "01521", descricao: "CRIACAO DE OUTROS ANIMAIS DE GRANDE PORTE" },
  { cnae: "0152101", descricao: "LEITE DE BUFALA; PRODUCAO DE" },
  {
    cnae: "0152101",
    descricao:
      "RESFRIAMENTO DE LEITE DE BUFALA REALIZADO NA UNIDADE DE PRODUCAO",
  },
  { cnae: "0152101", descricao: "BUFALOS; CRIACAO DE" },
  { cnae: "0152101", descricao: "SEMEN DE BUFALINOS; PRODUCAO DE" },
  { cnae: "0152101", descricao: "BUFALINOS; CRIACAO DE" },
  { cnae: "0152101", descricao: "BUFALINOCULTURA" },
  { cnae: "0152102", descricao: "SEMEN DE EQUINO; PRODUCAO DE" },
  { cnae: "0152102", descricao: "CAVALOS; CRIACAO DE" },
  { cnae: "0152102", descricao: "EQUINOS; CRIACAO DE" },
  { cnae: "0152102", descricao: "EQUINOCULTURA" },
  { cnae: "0152103", descricao: "SEMEN DE ASININOS; PRODUCAO DE" },
  { cnae: "0152103", descricao: "BURROS; CRIACAO DE" },
  { cnae: "0152103", descricao: "MUARES; CRIACAO DE" },
  { cnae: "0152103", descricao: "JUMENTOS; CRIACAO DE" },
  { cnae: "0152103", descricao: "MULAS; CRIACAO DE" },
  { cnae: "0152103", descricao: "ASININOS; CRIACAO DE" },
  { cnae: "01539", descricao: "CRIACAO DE CAPRINOS E OVINOS" },
  { cnae: "0153901", descricao: "CAPRINOS; CRIACAO DE" },
  {
    cnae: "0153901",
    descricao:
      "RESFRIAMENTO DE LEITE DE CABRA, COMO ATIVIDADE COMPLEMENTAR DA PRODUCAO",
  },
  { cnae: "0153901", descricao: "BODES; CRIACAO DE" },
  { cnae: "0153901", descricao: "LEITE DE CABRA; PRODUCAO DE" },
  { cnae: "0153901", descricao: "SEMEN DE CAPRINOS; PRODUCAO DE" },
  { cnae: "0153901", descricao: "CAPRINOCULTURA" },
  { cnae: "0153901", descricao: "CABRAS; CRIACAO DE" },
  { cnae: "0153902", descricao: "OVINOS; CRIACAO DE" },
  {
    cnae: "0153902",
    descricao: "LA DE OVELHA NA UNIDADE DE CRIACAO; PRODUCAO DE",
  },
  {
    cnae: "0153902",
    descricao: "LA DE TOSQUIA NA UNIDADE DE CRIACAO; PRODUCAO DE",
  },
  { cnae: "0153902", descricao: "OVINOCULTURA" },
  { cnae: "0153902", descricao: "CARNEIROS; CRIACAO DE" },
  { cnae: "0153902", descricao: "OVELHAS; CRIACAO DE" },
  { cnae: "0153902", descricao: "LEITE DE OVELHA; PRODUCAO DE" },
  { cnae: "0153902", descricao: "SEMEN DE OVINO; PRODUCAO DE" },
  {
    cnae: "0153902",
    descricao:
      "RESFRIAMENTO DE LEITE DE OVELHA, COMO ATIVIDADE COMPLEMENTAR DA PRODUCAO",
  },
  { cnae: "01547", descricao: "CRIACAO DE SUINOS" },
  { cnae: "0154700", descricao: "ESTERCO DE SUINOS; PRODUCAO DE" },
  { cnae: "0154700", descricao: "SUINOCULTURA" },
  { cnae: "0154700", descricao: "SEMEN DE SUINO; PRODUCAO DE" },
  { cnae: "0154700", descricao: "PORCOS; CRIACAO DE" },
  { cnae: "0154700", descricao: "SUINOS; CRIACAO DE" },
  { cnae: "01555", descricao: "CRIACAO DE AVES" },
  { cnae: "0155501", descricao: "AVICULTURA DE CORTE" },
  { cnae: "0155501", descricao: "FRANGOS E FRANGAS PARA CORTE; CRIACAO DE" },
  { cnae: "0155501", descricao: "ESTERCO DE FRANGO; PRODUCAO DE" },
  {
    cnae: "0155501",
    descricao: "GALINACEOS (FRANGOS E FRANGAS) PARA CORTE; CRIACAO DE",
  },
  { cnae: "0155502", descricao: "PINTOS DE 1 DIA; PRODUCAO DE" },
  { cnae: "0155502", descricao: "INCUBATORIO DE OVOS" },
  { cnae: "0155502", descricao: "PINTOS - INCUBACAO; PRODUCAO DE" },
  { cnae: "0155503", descricao: "GALOS; CRIACAO DE" },
  {
    cnae: "0155503",
    descricao:
      "FRANGOS, FRANGAS, GALOS, GALINHAS (PARA POSTURA, PARA CRUZA OU PARA ORNAMENTACAO); CRIACAO DE",
  },
  {
    cnae: "0155503",
    descricao: "OUTROS GALINACEOS (EXCETO PARA CORTE); CRIACAO DE",
  },
  { cnae: "0155503", descricao: "GALINHAS; CRIACAO DE" },
  { cnae: "0155503", descricao: "ESTERCO DE OUTROS GALINACEOS; PRODUCAO DE" },
  { cnae: "0155504", descricao: "MARRECOS; CRIACAO DE" },
  { cnae: "0155504", descricao: "GALINHAS D'ANGOLA; CRIACAO DE" },
  { cnae: "0155504", descricao: "FAISOES; CRIACAO DE" },
  { cnae: "0155504", descricao: "CODORNAS; CRIACAO DE" },
  { cnae: "0155504", descricao: "EMAS; CRIACAO DE" },
  { cnae: "0155504", descricao: "PERDIZES; CRIACAO DE" },
  { cnae: "0155504", descricao: "AVESTRUZ - ESTRUTIOCULTURA; CRIACAO DE" },
  { cnae: "0155504", descricao: "PERUS; CRIACAO DE" },
  { cnae: "0155504", descricao: "AVESTRUZ; CRIACAO DE" },
  { cnae: "0155504", descricao: "GANSOS; CRIACAO DE" },
  { cnae: "0155504", descricao: "ESTERCO DE AVES; PRODUCAO DE" },
  { cnae: "0155504", descricao: "PATOS; CRIACAO DE" },
  { cnae: "0155505", descricao: "OVOS; PRODUCAO DE" },
  { cnae: "0155505", descricao: "OVOS DE CODORNAS; PRODUCAO DE" },
  { cnae: "0155505", descricao: "OVOS DE GALINHA; PRODUCAO DE" },
  { cnae: "0155505", descricao: "AVICULTURA DE POSTURA" },
  { cnae: "0155505", descricao: "OVOS DE PERUA; PRODUCAO DE" },
  { cnae: "0155505", descricao: "OVOS DE PATA; PRODUCAO DE" },
  { cnae: "0155505", descricao: "OVOS DE MARRECA; PRODUCAO DE" },
  {
    cnae: "01598",
    descricao: "CRIACAO DE ANIMAIS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "0159801", descricao: "CERA DE ABELHA; PRODUCAO DE" },
  { cnae: "0159801", descricao: "PROPOLIS; PRODUCAO DE" },
  { cnae: "0159801", descricao: "APICULTURA; CRIACAO DE ABELHAS" },
  { cnae: "0159801", descricao: "MEL DE ABELHA; PRODUCAO DE" },
  { cnae: "0159802", descricao: "CANIL; CRIACAO DE CAES" },
  { cnae: "0159802", descricao: "PASSAROS; CRIACAO DE" },
  { cnae: "0159802", descricao: "HAMSTERS; CRIACAO DE" },
  { cnae: "0159802", descricao: "ANIMAIS DOMESTICOS; CRIACAO DE" },
  { cnae: "0159802", descricao: "PORQUINHOS DA INDIA; CRIACAO DE" },
  { cnae: "0159802", descricao: "GATOS; CRIACAO DE" },
  { cnae: "0159803", descricao: "ESCARGO; CRIACAO DE" },
  { cnae: "0159803", descricao: "ESCARGO - HELICICULTURA; CRIACAO DE" },
  { cnae: "0159804", descricao: "BICHO DA SEDA; PRODUCAO DE" },
  { cnae: "0159804", descricao: "SERICULTURA" },
  { cnae: "0159804", descricao: "LARVAS DE BICHO DA SEDA; PRODUCAO DE" },
  { cnae: "0159899", descricao: "BIOTERIO; CRIACAO DE ANIMAIS PARA PESQUISA" },
  { cnae: "0159899", descricao: "PACA; CRIACAO DE" },
  { cnae: "0159899", descricao: "BORBOLETAS; CRIACAO DE" },
  { cnae: "0159899", descricao: "COELHO - CUNICULTURA; CRIACAO DE" },
  { cnae: "0159899", descricao: "MINHOCA; CRIACAO DE" },
  { cnae: "0159899", descricao: "INSETOS; CRIACAO DE" },
  { cnae: "0159899", descricao: "JAVALI; CRIACAO DE" },
  { cnae: "0159899", descricao: "COELHOS (LEBRES); CRIACAO DE" },
  { cnae: "0159899", descricao: "CAPIVARA; CRIACAO DE" },
  { cnae: "0159899", descricao: "TARTARUGA; CRIACAO DE" },
  { cnae: "0159899", descricao: "ANIMAIS PARA PESQUISA; CRIACAO DE" },
  { cnae: "0159899", descricao: "CATETO; CRIACAO DE" },
  { cnae: "0159899", descricao: "MINHOCAS - MINHOCULTURA; CRIACAO DE" },
  { cnae: "0159899", descricao: "CUNICULTURA; CRIACAO DE COELHOS (LEBRES)" },
  { cnae: "01610", descricao: "ATIVIDADES DE APOIO A AGRICULTURA" },
  { cnae: "0161001", descricao: "CONTROLE DE PRAGAS; SERVICOS DE" },
  { cnae: "0161001", descricao: "PULVERIZACAO AEREA; SERVICOS DE" },
  { cnae: "0161001", descricao: "PULVERIZACAO DE LAVOURAS; SERVICO DE" },
  {
    cnae: "0161001",
    descricao: "TRATAMENTO DE CULTIVOS REALIZADO POR TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "0161001",
    descricao: "CONTROLE BIOLOGICO DE PRAGAS AGRICOLAS, SERVICO DE",
  },
  {
    cnae: "0161002",
    descricao: "PODA DE ARVORES VIDEIRAS (PARREIRA DE UVAS); SERVICOS DE",
  },
  { cnae: "0161002", descricao: "PODA DE ARVORES PARA LAVOURA; SERVICO DE" },
  { cnae: "0161002", descricao: "PODA DE ARVORES FRUTIFERAS; SERVICOS DE" },
  { cnae: "0161003", descricao: "CORTE DE CANA; SERVICOS DE" },
  { cnae: "0161003", descricao: "PLANTIO DE MUDAS; SERVICO DE" },
  {
    cnae: "0161003",
    descricao:
      "PREPARACAO DE TERRENOS DE CULTIVO (REALIZADO POR TERCEIROS); SERVICOS DE",
  },
  {
    cnae: "0161003",
    descricao:
      "ROCAGEM, DESTOCAMENTO, LAVRACAO, GRADAGEM (NIVELAMENTO DO SOLO), SULCAMENTO (ABERTURA DE COVAS); SERVICO DE",
  },
  {
    cnae: "0161003",
    descricao:
      "PLANTIO DE MUDAS NOS CAMPOS DE CULTIVO POR TERCEIROS; SERVICO DE",
  },
  { cnae: "0161003", descricao: "PREPARACAO DO SOLO; SERVICO DE" },
  { cnae: "0161003", descricao: "TRANSPLANTE DE MUDAS; SERVICO DE" },
  { cnae: "0161003", descricao: "PLANTIO DE LAVOURAS AGRICOLAS; SERVICO DE" },
  { cnae: "0161003", descricao: "COLHEITA DE PRODUTOS AGRICOLAS; SERVICO DE" },
  {
    cnae: "0161003",
    descricao: "CORTE, PLANTIO, CAPINA MANUAL, CAPINA QUIMICA; SERVICO DE",
  },
  { cnae: "0161003", descricao: "COLHEITA; SERVICOS DE" },
  {
    cnae: "0161003",
    descricao: "SEMEADURA REALIZADA POR TERCEIROS; SERVICO DE",
  },
  {
    cnae: "0161099",
    descricao: "CONSORCIO DE EMPREGADORES DE MAO-DE-OBRA PARA AGRICULTURA",
  },
  { cnae: "0161099", descricao: "MAQUINAS AGRICOLAS COM OPERADOR; ALUGUEL DE" },
  {
    cnae: "0161099",
    descricao: "GATO, EMPREITEIRO DE MAO-DE-OBRA AGRICOLA; SERVICOS DE",
  },
  { cnae: "0161099", descricao: "TRATORES AGRICOLAS COM OPERADOR; LOCACAO DE" },
  {
    cnae: "0161099",
    descricao: "EMPREITEIRO DE MAO-DE-OBRA AGRICOLA; SERVICO DE",
  },
  { cnae: "0161099", descricao: "TRATORES AGRICOLAS COM OPERADOR; ALUGUEL DE" },
  { cnae: "0161099", descricao: "MAQUINAS AGRICOLAS COM OPERADOR; LOCACAO DE" },
  { cnae: "0161099", descricao: "ADUBADORAS COM OPERADOR; ALUGUEL DE" },
  {
    cnae: "0161099",
    descricao: "EMPREGADORES DE MAO-DE-OBRA PARA AGRICULTURA; SERVICO DE",
  },
  {
    cnae: "0161099",
    descricao:
      "EXPLORACAO DE SISTEMAS DE IRRIGACAO (REALIZADO POR TERCEIROS); SERVICOS DE",
  },
  { cnae: "0161099", descricao: "BOMBARDEAMENTO DE NUVENS;SERVICOS DE" },
  { cnae: "0161099", descricao: "COLHEDEIRAS COM OPERADOR; ALUGUEL DE" },
  {
    cnae: "0161099",
    descricao: "CONTRATANTES DE MAO-DE-OBRA PARA A AGRICULTURA, SERVICOS DE",
  },
  {
    cnae: "0161099",
    descricao: "EQUIPAMENTOS AGRICOLAS COM OPERADOR; LOCACAO DE",
  },
  {
    cnae: "0161099",
    descricao: "CHUVA ARTIFICIAL POR BOMBARDEAMENTO OU NUCLEACAO; SERVICOS DE",
  },
  {
    cnae: "0161099",
    descricao: "MAQUINAS E EQUIPAMENTOS AGRICOLAS COM OPERADOR; LOCACAO DE",
  },
  { cnae: "0161099", descricao: "ARADOS COM OPERADOR; ALUGUEL DE" },
  {
    cnae: "0161099",
    descricao: "EMPREGADORES DE MAO-DE-OBRA PARA AGRICULTURA; CONDOMINIO DE",
  },
  { cnae: "0161099", descricao: "MECANIZACAO AGRICOLA, SERVICO DE" },
  {
    cnae: "0161099",
    descricao: "EMPREGADORES DE MAO-DE-OBRA PARA AGRICULTURA; CONSORCIO DE",
  },
  {
    cnae: "0161099",
    descricao: "EQUIPAMENTOS AGRICOLAS COM OPERADOR; ALUGUEL DE",
  },
  { cnae: "0161099", descricao: "NUCLEACAO DE NUVENS;SERVICOS DE" },
  {
    cnae: "0161099",
    descricao: "MAQUINAS E EQUIPAMENTOS AGRICOLAS COM OPERADOR; ALUGUEL DE",
  },
  { cnae: "01628", descricao: "ATIVIDADES DE APOIO A PECUARIA" },
  {
    cnae: "0162801",
    descricao: "INSEMINACAO ARTIFICIAL EM ANIMAIS; SERVICOS DE",
  },
  {
    cnae: "0162801",
    descricao: "FERTILIZACAO ANIMAL IN VITRO (FIV); SERVICOS DE",
  },
  { cnae: "0162802", descricao: "TOSQUIAMENTO DE OVINOS; SERVICO DE" },
  { cnae: "0162802", descricao: "TOSQUIA DE OVINOS; SERVICO DE" },
  {
    cnae: "0162802",
    descricao: "TOSQUIA DE OVINOS REALIZADA POR TERCEIROS; SERVICO DE",
  },
  {
    cnae: "0162803",
    descricao: "MANEJO DE ANIMAIS (CONDUCAO, PASTOREIO); SERVICOS DE",
  },
  {
    cnae: "0162899",
    descricao: "ALOJAMENTO DO GADO DE CURTA DURACAO; SERVICO DE",
  },
  {
    cnae: "0162899",
    descricao: "GATO, EMPREITEIRO DE MAO-DE-OBRA PECUARIA; SERVICOS DE",
  },
  {
    cnae: "0162899",
    descricao: "CONTRATANTES DE MAO-DE-OBRA PARA PECUARIA; SERVICOS DE",
  },
  { cnae: "0162899", descricao: "LIMPEZA DE GALINHEIROS; SERVICOS DE" },
  { cnae: "0162899", descricao: "APANHA DE FRANGOS; SERVICO DE" },
  {
    cnae: "0162899",
    descricao: "ESTABULACAO, CUIDADO E REPRODUCAO DE ANIMAIS DE TERCEIROS",
  },
  { cnae: "0162899", descricao: "CLASSIFICACAO DE PINTOS; SERVICOS DE" },
  { cnae: "0162899", descricao: "PADRONIZACAO DE OVOS DE GALINHA, SERVICO DE" },
  {
    cnae: "0162899",
    descricao: "LIMPEZA DE BANHEIRO CARRAPATICIDA; SERVICOS DE",
  },
  {
    cnae: "0162899",
    descricao: "HOSPEDAGEM DE ANIMAIS DE CURTO PRAZO; SERVICOS DE",
  },
  { cnae: "0162899", descricao: "CATACAO DE FRANGOS; SERVICO DE" },
  { cnae: "0162899", descricao: "SEXAGEM DE PINTOS; SERVICOS DE" },
  { cnae: "0220999", descricao: "FLOR DE COBEIRA; COLETA DE" },
  {
    cnae: "0162899",
    descricao: "EMPREGADORES DE MAO-DE-OBRA PARA PECUARIA; CONDOMINIO DE",
  },
  {
    cnae: "0162899",
    descricao: "MAO-DE-OBRA PARA A PECUARIA; EMPREGADORES DE",
  },
  {
    cnae: "0162899",
    descricao: "EMPREGADORES DE MAO-DE-OBRA PARA PECUARIA; CONSORCIO DE",
  },
  { cnae: "01636", descricao: "ATIVIDADES DE POSCOLHEITA" },
  {
    cnae: "0163600",
    descricao:
      "CACAU REALIZADO EM ESTABELECIMENTO AGRICOLA SOB CONTRATO; BENEFICIAMENTO DE",
  },
  {
    cnae: "0163600",
    descricao: "MILHO REALIZADO SOB CONTRATO, BENEFICIAMENTO DE",
  },
  {
    cnae: "0163600",
    descricao: "TRIGO REALIZADO SOB CONTRATO; BENEFICIAMENTO DE",
  },
  {
    cnae: "0163600",
    descricao: "SECAGEM DE CEREAIS REALIZADO SOB CONTRATO; SERVICOS DE",
  },
  {
    cnae: "0163600",
    descricao: "POS-COLHEITA REALIZADO SOB CONTRATO; SERVICOS DE",
  },
  {
    cnae: "0163600",
    descricao:
      "LIMPEZA E CLASSIFICACAO DE PRODUTOS AGRICOLAS REALIZADO SOB CONTRATO; SERVICOS DE",
  },
  {
    cnae: "0163600",
    descricao: "BENEFICIAMENTO DE ARROZ REALIZADO SOB CONTRATO; SERVICO DE",
  },
  {
    cnae: "0163600",
    descricao:
      "CAFE EM GRAO EM ESTABELECIMENTO AGRICOLA SOB CONTRATO ; PRODUCAO DE",
  },
  {
    cnae: "0163600",
    descricao:
      "FUMO REALIZADO EM ESTABELECIMENTO AGRICOLA SOB CONTRATO; BENEFICIAMENTO DE",
  },
  {
    cnae: "0163600",
    descricao: "BATATAS, LAVAGEM, LIMPEZA SOB CONTRATO; CLASSIFICACAO DE",
  },
  {
    cnae: "0163600",
    descricao: "ENCERAMENTO DE FRUTAS SOB CONTRATO; SERVICO DE",
  },
  {
    cnae: "0163600",
    descricao:
      "CAFE EM COCO PARA CAFE EM GRAO NA UNIDADE DE PRODUCAO SOB CONTRATO; TRANSFORMACAO DE",
  },
  {
    cnae: "0163600",
    descricao: "CANCHEAMENTO DE ERVA MATE SOB CONTRATO; SERVICO DE",
  },
  { cnae: "0220999", descricao: "JUA; COLETA DE" },
  {
    cnae: "0163600",
    descricao:
      "SAPECO, SECAGEM E TRITURACAO, CANCHEAMENTO DAS FOLHAS DE ERVA MATE REALIZADO SOB CONTRATO; SERVICO DE",
  },
  {
    cnae: "0163600",
    descricao: "DESCAROCAMETO DE ALGODAO REALIZADO SOB CONTRATO; SERVICO DE",
  },
  {
    cnae: "0163600",
    descricao:
      "ALGODAO REALIZADO EM ESTABELECIMENTO AGRICOLA SOB CONTRATO; BENEFICIAMENTO DE",
  },
  { cnae: "01709", descricao: "CACA E SERVICOS RELACIONADOS" },
  { cnae: "0170900", descricao: "ANIMAIS, COM ARMADILHA; CAPTURA DE" },
  { cnae: "0170900", descricao: "ANIMAIS, SEM ARMADILHA; CACA DE" },
  {
    cnae: "0170900",
    descricao:
      "CAPTURA DE ANIMAIS, MORTOS OU VIVOS, PARA PESQUISA, UTILIZACAO EM ZOOLOGICOS; SERVICOS DE",
  },
  {
    cnae: "0170900",
    descricao:
      "CAPTURA DE ANIMAIS, MORTOS OU VIVOS, PARA ALIMENTACAO, PARA OBTENCAO DE COUROS E PELES; SERVICOS DE",
  },
  { cnae: "02101", descricao: "PRODUCAO FLORESTAL  FLORESTAS PLANTADAS" },
  {
    cnae: "0210101",
    descricao: "MUDAS DE EUCALIPTO QUANDO COMPLEMENTAR AO CULTIVO; PRODUCAO DE",
  },
  { cnae: "0210101", descricao: "EUCALIPTO; CULTIVO DE" },
  { cnae: "0210102", descricao: "ACACIA-NEGRA; CULTIVO DE" },
  {
    cnae: "0210102",
    descricao:
      "MUDAS DE ACACIA-NEGRA QUANDO COMPLEMENTAR AO CULTIVO; PRODUCAO DE",
  },
  { cnae: "0210103", descricao: "PINUS AMERICANO; CULTIVO DE" },
  {
    cnae: "0210103",
    descricao: "MUDAS DE PINUS QUANDO COMPLEMENTAR AO CULTIVO; PRODUCAO DE",
  },
  { cnae: "0210104", descricao: "TECA; CULTIVO DE" },
  {
    cnae: "0210104",
    descricao: "MUDAS DE TECA QUANDO COMPLEMENTAR AO CULTIVO; PRODUCAO DE",
  },
  { cnae: "0210105", descricao: "BRACATINGA; CULTIVO DE" },
  { cnae: "0210105", descricao: "IPE AMARELO; CULTIVO DE" },
  { cnae: "0210105", descricao: "JACARANDA; CULTIVO DE" },
  { cnae: "0210105", descricao: "CUNIGANIA; CULTIVO DE" },
  { cnae: "0210105", descricao: "ANDIROBA; CULTIVO DE" },
  { cnae: "0210105", descricao: "VINHATICO; CULTIVO DE" },
  { cnae: "0210105", descricao: "ANGICO; CULTIVO DE" },
  { cnae: "0210105", descricao: "CEDRO-JAPONES; CULTIVO DE" },
  { cnae: "0210105", descricao: "ARAUCARIA; CULTIVO DE" },
  { cnae: "0210105", descricao: "CARVALHO CORTICEIRO; CULTIVO DE" },
  { cnae: "0210105", descricao: "SASSAFRAS; CULTIVO DE" },
  { cnae: "0210105", descricao: "GMELINA; CULTIVO DE" },
  { cnae: "0210105", descricao: "ALGAROBEIRA; CULTIVO DE" },
  { cnae: "0210105", descricao: "JACATIRAO; CULTIVO DE" },
  { cnae: "0210105", descricao: "PAU-JACARE; CULTIVO DE" },
  { cnae: "0210105", descricao: "JACARE (ARVORE); CULTIVO DE" },
  { cnae: "0210105", descricao: "CIPRESTE; CULTIVO DE" },
  { cnae: "0210105", descricao: "SABIA; CULTIVO DE" },
  { cnae: "0210105", descricao: "PINHEIRO BRASILEIRO; CULTIVO DE" },
  { cnae: "0210105", descricao: "IMBUIA; CULTIVO DE" },
  { cnae: "0210105", descricao: "CARVALHO SOBREIRO; CULTIVO DE" },
  { cnae: "0210105", descricao: "UCUUBEIRA; CULTIVO DE" },
  { cnae: "0210105", descricao: "CRIPTOMERIA; CULTIVO DE" },
  { cnae: "0210105", descricao: "CANELA (ARVORE); CULTIVO DE" },
  { cnae: "0210105", descricao: "SETE CASACAS; CULTIVO DE" },
  { cnae: "0210105", descricao: "JACOTIRAO; CULTIVO DE" },
  { cnae: "0210105", descricao: "PEROBA; CULTIVO DE" },
  { cnae: "0210105", descricao: "GUAPURUVU; CULTIVO DE" },
  {
    cnae: "0210105",
    descricao: "GREVILHA GIGANTE (GREVILLEA ROBUSTA); CULTIVO DE",
  },
  { cnae: "0210105", descricao: "IPE; CULTIVO DE" },
  { cnae: "0210105", descricao: "GUAJUVIRA; CULTIVO DE" },
  { cnae: "0210105", descricao: "CASUARINA; CULTIVO DE" },
  { cnae: "0210105", descricao: "KIRI; CULTIVO DE" },
  { cnae: "0210105", descricao: "GOMELINA; CULTIVO DE" },
  { cnae: "0210105", descricao: "QUIRI; CULTIVO DE" },
  { cnae: "0210105", descricao: "TAPEBUIA; CULTIVO DE" },
  { cnae: "0210105", descricao: "MOGNO; CULTIVO DE" },
  { cnae: "0210105", descricao: "CEDRO; CULTIVO DE" },
  { cnae: "0210105", descricao: "CAXETA; CULTIVO DE" },
  { cnae: "0210105", descricao: "PAU-BRASIL; CULTIVO DE" },
  {
    cnae: "0210106",
    descricao: "MUDAS CERTIFICADAS DE EUCALIPTO; PRODUCAO DE",
  },
  {
    cnae: "0210106",
    descricao: "MUDAS CERTIFICADAS DE PINHEIRO BRASILEIRO; PRODUCAO DE",
  },
  { cnae: "0210106", descricao: "MUDAS CERTIFICADAS DE PINUS; PRODUCAO DE" },
  {
    cnae: "0210106",
    descricao: "MUDAS CERTIFICADAS DE PINUS AMERICANO; PRODUCAO DE",
  },
  {
    cnae: "0210106",
    descricao: "MUDAS CERTIFICADAS DE ACACIA-NEGRA; PRODUCAO DE",
  },
  {
    cnae: "0210106",
    descricao: "VIVEIROS DE ESPECIES FLORESTAIS; PRODUCAO DE",
  },
  { cnae: "0210106", descricao: "MUDAS CERTIFICADAS DE TECA; PRODUCAO DE" },
  { cnae: "0210107", descricao: "PINUS; ABATE DE" },
  { cnae: "0210107", descricao: "LENHA (FLORESTAS PLANTADAS); EXTRACAO DE" },
  { cnae: "0210107", descricao: "ESTACAS (FLORESTAS PLANTADAS); EXTRACAO DE" },
  { cnae: "0210107", descricao: "REFLORESTAMENTO COM ABATE DE ARVORES" },
  { cnae: "0210107", descricao: "MOIROES (FLORESTAS PLANTADAS); EXTRACAO DE" },
  {
    cnae: "0210107",
    descricao: "ABATE, DERRUBADA DE ARVORES PLANTADAS; EXTRACAO DE",
  },
  { cnae: "0210107", descricao: "TRONCOS (FLORESTAS PLANTADAS); EXTRACAO DE" },
  { cnae: "0210107", descricao: "MADEIRA DE ARVORES PLANTADAS; EXTRACAO DE" },
  { cnae: "0210107", descricao: "ARVORES PLANTADAS CORTE; DERRUBADA DE" },
  {
    cnae: "0210108",
    descricao:
      "EXTRATO PIROLENHOSO SUBPRODUTO DA PRODUCAO DE CARVAO DE FLORESTA PLANTADA; PRODUCAO DE",
  },
  {
    cnae: "0210108",
    descricao: "CARVAO VEGETAL (FLORESTAS PLANTADAS); PRODUCAO DE",
  },
  { cnae: "0210109", descricao: "CASCAS DE ACACIA-NEGRA; PRODUCAO DE" },
  { cnae: "0210199", descricao: "FOLHAS DE EUCALIPTO; PRODUCAO DE" },
  { cnae: "0210199", descricao: "RESINA DE PINUS; PRODUCAO DE" },
  { cnae: "02209", descricao: "PRODUCAO FLORESTAL  FLORESTAS NATIVAS" },
  { cnae: "0220901", descricao: "ARVORES NATIVAS CORTE; DERRUBADA DE" },
  { cnae: "0220901", descricao: "MOIROES (FLORESTAS NATIVAS); EXTRACAO DE" },
  { cnae: "0220901", descricao: "LENHA (FLORESTAS NATIVAS); EXTRACAO DE" },
  { cnae: "0220901", descricao: "MADEIRA DE ARVORES NATIVAS; EXTRACAO DE" },
  {
    cnae: "0220901",
    descricao: "ABATE, DERRUBADA DE ARVORES NATIVAS; EXTRACAO DE",
  },
  { cnae: "0220901", descricao: "TRONCOS (FLORESTAS NATIVAS); EXTRACAO DE" },
  { cnae: "0220901", descricao: "ESTACAS (FLORESTAS NATIVAS); EXTRACAO DE" },
  {
    cnae: "0220902",
    descricao:
      "EXTRATO PIROLENHOSO SUBPRODUTO DA PRODUCAO DE CARVAO DE FLORESTA NATIVA; PRODUCAO DE",
  },
  {
    cnae: "0220902",
    descricao: "CARVAO VEGETAL (FLORESTAS NATIVAS); PRODUCAO DE",
  },
  {
    cnae: "0220902",
    descricao: "CARVAO VEGETAL - ARVORES NATIVAS; PRODUCAO DE",
  },
  { cnae: "0220903", descricao: "CASTANHA-DO-PARA; COLETA DE" },
  { cnae: "0220904", descricao: "LATEX NO LOCAL DE COLETA; BENEFICIAMENTO DE" },
  {
    cnae: "0220904",
    descricao: "LATEX; EXTRACAO DE SERINGUEIRAS NATIVAS; COLETA DE",
  },
  { cnae: "0220905", descricao: "PALMITO; COLETA DE" },
  { cnae: "0220906", descricao: "FLORESTAS NATIVAS; CONSERVACAO DE" },
  {
    cnae: "0220906",
    descricao: "REFLORESTAMENTO DE FLORESTAS NATIVAS; SERVICOS DE",
  },
  { cnae: "0220999", descricao: "BACURI; COLETA DE" },
  { cnae: "0220999", descricao: "URICURI (FOLHA); COLETA DE" },
  { cnae: "0220999", descricao: "COQUINHO BABAO; COLETA DE" },
  { cnae: "0220999", descricao: "TAQUARA; EXTRACAO DE" },
  { cnae: "0220999", descricao: "FRUTO DE GUABIROBA-DO-CAMPO; COLETA DE" },
  { cnae: "0220999", descricao: "FOLHA DE EUCALIPTO; COLETA DE" },
  { cnae: "0220999", descricao: "CHERIMOLIA; COLETA DE" },
  { cnae: "0220999", descricao: "ACAI (FRUTO); COLETA DE" },
  { cnae: "0220999", descricao: "FRUTO DE GUARIROBA-DA-MATA; COLETA DE" },
  { cnae: "0220999", descricao: "MACAUBA; COLETA DE" },
  { cnae: "0220999", descricao: "CERA DE LICURI; COLETA DE" },
  { cnae: "0220999", descricao: "COCO DE COQUEIRO AMARGOSO; COLETA DE" },
  { cnae: "0220999", descricao: "TINGUI; COLETA DE" },
  { cnae: "0220999", descricao: "FIBRA DE GRAVATA; COLETA DE" },
  { cnae: "0220999", descricao: "LICURI (CERA); COLETA DE" },
  { cnae: "0220999", descricao: "XIXA; COLETA DE" },
  { cnae: "0220999", descricao: "FRUTA-PAO; COLETA DE" },
  { cnae: "0220999", descricao: "PITOMBA; COLETA DE" },
  { cnae: "0220999", descricao: "FOLHA DE JABORANDI; COLETA DE" },
  { cnae: "0220999", descricao: "TIMBO; COLETA DE" },
  { cnae: "0220999", descricao: "COCO DE GUEROBA; COLETA DE" },
  { cnae: "0220999", descricao: "FIBRA DE BUTIA; COLETA DE" },
  { cnae: "0220999", descricao: "FRUTO DE GUARIROBA; COLETA DE" },
  { cnae: "0220999", descricao: "MANGABEIRA (GOMA ELASTICA); COLETA DE" },
  { cnae: "0220999", descricao: "CHICHA; COLETA DE" },
  { cnae: "0220999", descricao: "SEMENTE DE MURUMURU; COLETA DE" },
  { cnae: "0220999", descricao: "AURICURI (CERA); COLETA DE" },
  { cnae: "0220999", descricao: "MANICOBA (GOMA ELASTICA); COLETA DE" },
  { cnae: "0220999", descricao: "FRUTO DE GABIROBA; COLETA DE" },
  { cnae: "0220999", descricao: "FIBRA DE MALVA; COLETA DE" },
  { cnae: "0220999", descricao: "PAINAS; COLETA DE" },
  { cnae: "0220999", descricao: "UCUUBA; COLETA DE" },
  { cnae: "0220999", descricao: "PINHAO; COLETA DE" },
  { cnae: "0220999", descricao: "JENIPAPO; COLETA DE" },
  {
    cnae: "0220999",
    descricao: "FOLHA DE GUABIROBA-DO-CAMPO (USO MEDICINAL); COLETA DE",
  },
  { cnae: "0220999", descricao: "CUPUACU; COLETA DE" },
  { cnae: "0220999", descricao: "PERINHA; COLETA DE" },
  { cnae: "0220999", descricao: "PALMITO DE ACAI; COLETA DE" },
  { cnae: "0220999", descricao: "BACABA; COLETA DE" },
  { cnae: "0220999", descricao: "CAMBUCA; COLETA DE" },
  { cnae: "0220999", descricao: "UNHA-DE-GATO; COLETA DE" },
  { cnae: "0220999", descricao: "CANJERANA; COLETA DE" },
  { cnae: "0220999", descricao: "FRUTO DE TATU; COLETA DE" },
  {
    cnae: "0220999",
    descricao: "PALMITO DE GUEROBA (FLORESTAS NATIVAS); EXTRACAO DE",
  },
  { cnae: "0220999", descricao: "FIBRA DE PIACABA; COLETA DE" },
  { cnae: "0220999", descricao: "FRUTO DE EMA; COLETA DE" },
  { cnae: "0220999", descricao: "BORRACHA; COLETA DE" },
  { cnae: "0220999", descricao: "BAMBU; COLETA DE" },
  { cnae: "0220999", descricao: "MARAPUAMA; COLETA DE" },
  { cnae: "0220999", descricao: "CASCA DE ANGICO; COLETA DE" },
  { cnae: "0220999", descricao: "CAUCHO; COLETA DE" },
  { cnae: "0220999", descricao: "COQUIRANA; COLETA DE" },
  {
    cnae: "0220999",
    descricao: "FOLHA DE GABIRABA (USO MEDICINAL); COLETA DE",
  },
  { cnae: "0220999", descricao: "PUPUNHA; COLETA DE" },
  { cnae: "0220999", descricao: "AMENDOA DE BABACU; COLETA DE" },
  { cnae: "0220999", descricao: "FOLHA DE GUARIROBA-DA-MATA; COLETA DE" },
  { cnae: "0220999", descricao: "PAU-TERRA; COLETA DE" },
  { cnae: "0220999", descricao: "COCO DE BUTIA; COLETA DE" },
  {
    cnae: "0220999",
    descricao: "FOLHA DE GUAVIROBA (USO MEDICINAL); COLETA DE",
  },
  { cnae: "0220999", descricao: "ABIU; COLETA DE" },
  { cnae: "0220999", descricao: "COCO DE BURITI; COLETA DE" },
  { cnae: "0220999", descricao: "ABRICO; COLETA DE" },
  { cnae: "0220999", descricao: "COQUILHO DE PIACABA; COLETA DE" },
  { cnae: "0220999", descricao: "VAGEM DE ALGAROBA; COLETA DE" },
  { cnae: "0220999", descricao: "JATOBA; COLETA DE" },
  { cnae: "0220999", descricao: "CIPO TITICA; COLETA DE" },
  { cnae: "0220999", descricao: "CIPO TIMBO; COLETA DE" },
  {
    cnae: "0220999",
    descricao: "FOLHA DE GUARIROBA (USO MEDICINAL); EXTRACAO DE",
  },
  {
    cnae: "0220999",
    descricao: "PALMITO DE GUEIROBA (FLORESTAS NATIVAS); EXTRACAO DE",
  },
  { cnae: "0220999", descricao: "NO DE PORCO; COLETA DE" },
  { cnae: "0220999", descricao: "MACARANDUBA; EXTRACAO DE" },
  { cnae: "0220999", descricao: "GRAVATA; COLETA DE" },
  { cnae: "0220999", descricao: "MAMA CADELA; COLETA DE" },
  { cnae: "0220999", descricao: "BALATA; COLETA DE" },
  { cnae: "0220999", descricao: "LICURI (FOLHA); COLETA DE" },
  { cnae: "0220999", descricao: "OITI (FRUTO); COLETA DE" },
  { cnae: "0220999", descricao: "PALHA DE CARNAUBA; COLETA DE" },
  { cnae: "0220999", descricao: "PALHA DE BURITI; COLETA DE" },
  {
    cnae: "0220999",
    descricao: "PALMITO DE PATI-AMARGOSO (FLORESTAS NATIVAS; EXTRACAO DE",
  },
  { cnae: "0220999", descricao: "URICURI (COQUILHO); COLETA DE" },
  { cnae: "0220999", descricao: "COCO DE OURICURI; COLETA DE" },
  { cnae: "0220999", descricao: "URICURI (CERA); COLETA DE" },
  { cnae: "0220999", descricao: "SEMENTE DE CUMARU; COLETA DE" },
  { cnae: "0220999", descricao: "SORVA (GOMA NAO ELASTICA); COLETA DE" },
  {
    cnae: "0220999",
    descricao: "PALMITO DE GUARIROBA (FLORESTAS NATIVAS); EXTRACAO DE",
  },
  { cnae: "0220999", descricao: "COCO DE GUEIROBA; COLETA DE" },
  { cnae: "0220999", descricao: "FRUTO DE GUARIROBA-DA-MATA; EXTRACAO DE" },
  { cnae: "0220999", descricao: "TAPEREBA; COLETA DE" },
  { cnae: "0220999", descricao: "TRAPUERABA; COLETA DE" },
  { cnae: "0220999", descricao: "MANGABA; COLETA DE" },
  { cnae: "0220999", descricao: "FIBRA DE CAROA; COLETA DE" },
  { cnae: "0220999", descricao: "COCO DE PATI-AMARGOSO; COLETA DE" },
  { cnae: "0220999", descricao: "AURICURI (COQUILHO); COLETA DE" },
  { cnae: "0220999", descricao: "FRUTO AZUL; COLETA DE" },
  { cnae: "0220999", descricao: "MURICI (FRUTO); COLETA DE" },
  { cnae: "0220999", descricao: "JATAI; COLETA DE" },
  { cnae: "0220999", descricao: "IMBU; COLETA DE" },
  { cnae: "0220999", descricao: "ERVA MATE; COLETA DE" },
  { cnae: "0220999", descricao: "COCO DE BABACU; COLETA DE" },
  { cnae: "0220999", descricao: "FRUTO DE GABIRABA; COLETA DE" },
  { cnae: "0220999", descricao: "FOLHA DE LICURI; COLETA DE" },
  { cnae: "0220999", descricao: "SAGU; COLETA DE" },
  { cnae: "0220999", descricao: "CHUVA DE PRATA; COLETA DE" },
  { cnae: "0220999", descricao: "FRUTO DE GUAVIROBA; COLETA DE" },
  {
    cnae: "0220999",
    descricao: "RESINAS (OLEOS E GRAXAS VEGETAIS); COLETA DE",
  },
  { cnae: "0220999", descricao: "CAJARANA; COLETA DE" },
  { cnae: "0220999", descricao: "OLEO DE COPAIBA; COLETA DE" },
  {
    cnae: "0220999",
    descricao: "PALMITO DE COQUEIRO-AMARGOSO (FLORESTAS NATIVAS); EXTRACAO DE",
  },
  { cnae: "0220999", descricao: "CERA DE CARNAUBA; COLETA DE" },
  { cnae: "0220999", descricao: "JARACATIA; COLETA DE" },
  { cnae: "0220999", descricao: "HASTE DE MALVA; COLETA DE" },
  { cnae: "0220999", descricao: "PEQUI; COLETA DE" },
  { cnae: "0220999", descricao: "FIBRA DE COROATA; COLETA DE" },
  { cnae: "0220999", descricao: "COCO DE GUARIROBA; COLETA DE" },
  { cnae: "0220999", descricao: "PEDRA-UME; COLETA DE" },
  { cnae: "0220999", descricao: "BACUPARI; COLETA DE" },
  { cnae: "0220999", descricao: "RESINA DE PINUS; EXTRACAO DE" },
  { cnae: "0220999", descricao: "CASCAS TANIFERAS; COLETA DE" },
  { cnae: "0220999", descricao: "SEMENTE DE ANDIROBA; COLETA DE" },
  { cnae: "0220999", descricao: "BALSAMO DE COPAIBA; COLETA DE" },
  { cnae: "0220999", descricao: "COQUILHO DE LICURI; COLETA DE" },
  { cnae: "0220999", descricao: "CASCA DE BARBATIMAO; COLETA DE" },
  { cnae: "0220999", descricao: "IPECACUANHA; COLETA DE" },
  { cnae: "0220999", descricao: "SEMENTE DE FAVA-DE-TONCA; COLETA DE" },
  { cnae: "0220999", descricao: "CAGAITA; COLETA DE" },
  { cnae: "0220999", descricao: "SEMENTE DE OITICICA; COLETA DE" },
  { cnae: "0220999", descricao: "TUCUM; COLETA DE" },
  { cnae: "0220999", descricao: "FLOR DE LOBEIRA; COLETA DE" },
  { cnae: "0220999", descricao: "AURICURI (FOLHA); COLETA DE" },
  { cnae: "0220999", descricao: "UMBU (FRUTO); COLETA DE" },
  { cnae: "02306", descricao: "ATIVIDADES DE APOIO A PRODUCAO FLORESTAL" },
  { cnae: "0322199", descricao: "ALGAS EM AGUA DOCE; CULTIVO DE" },
  { cnae: "0810002", descricao: "PO DE GRANITO; PRODUCAO DE" },
  {
    cnae: "0230600",
    descricao:
      "ESPECIES FLORESTAIS NATIVAS PARA FINS DE RECUPERACAO AMBIENTAL DE AREAS DESMATADAS;REPOVOAMENTO OU REFLORESTAMENTO DE",
  },
  {
    cnae: "0230600",
    descricao:
      "DENDROMETRIA (MEDICAO DAS DIMENSOES DAS ARVORES; AVALIACAO DA QUANTIDADE DE MADEIRA QUE AS ARVORES PODEM FORNECER); SERVICOS DE",
  },
  { cnae: "0230600", descricao: "DESCARREGAMENTO DE MADEIRAS; SERVICOS DE" },
  { cnae: "0230600", descricao: "AVALIACAO DE MADEIRA; SERVICOS DE" },
  {
    cnae: "0230600",
    descricao: "ABATE, DERRUBADA DE ARVORES E TRANSPORTE DE TORAS; SERVICO DE",
  },
  {
    cnae: "0230600",
    descricao: "AVALIACAO DE MASSA MADEIREIRA EM PE; SERVICOS DE",
  },
  {
    cnae: "0230600",
    descricao: "CORTE, DERRUBADA DE ARVORES E TRANSPORTE DE TORAS; SERVICO DE",
  },
  {
    cnae: "0230600",
    descricao:
      "SERVICOS LIGADOS COM A SILVICULTURA E EXPLORACAO VEGETAL; ATIVIDADE DE",
  },
  { cnae: "0230600", descricao: "CUIDADOS FLORESTAIS; SERVICOS DE" },
  { cnae: "03116", descricao: "PESCA EM AGUA SALGADA" },
  { cnae: "0311601", descricao: "PEIXES EM AGUA SALGADA; PESCA DE" },
  { cnae: "0311601", descricao: "PESCA MARITIMA DE PEIXES" },
  { cnae: "0311602", descricao: "CARANGUEJO; PESCA DE" },
  { cnae: "0311602", descricao: "SIRI; PESCA DE" },
  { cnae: "0311602", descricao: "CAMARAO; PESCA DE" },
  { cnae: "0311602", descricao: "CRUSTACEOS E MOLUSCOS; PESCA DE" },
  { cnae: "0311602", descricao: "LAGOSTA; PESCA DE" },
  { cnae: "0311602", descricao: "MARISCO; PESCA DE" },
  { cnae: "0311602", descricao: "POLVO; PESCA" },
  { cnae: "0311602", descricao: "LULA; PESCA" },
  { cnae: "0311603", descricao: "PEROLAS; COLETA DE" },
  { cnae: "0311603", descricao: "CORAIS; COLETA DE" },
  { cnae: "0311603", descricao: "PRODUTOS DE ORIGEM MARINHA; COLETA DE" },
  { cnae: "0311603", descricao: "ESPONJA; COLETA DE" },
  { cnae: "0311603", descricao: "ALGAS; COLETA DE" },
  {
    cnae: "0311604",
    descricao: "SERVICOS RELACIONADOS A PESCA EM AGUA SALGADA",
  },
  {
    cnae: "0311604",
    descricao: "SERVICOS RELACIONADOS A PESCA EM AGUA SALOBRA",
  },
  {
    cnae: "0311604",
    descricao: "APOIO A PESCA EM AGUA SALGADA E SALOBRA; SERVICO DE",
  },
  { cnae: "03124", descricao: "PESCA EM AGUA DOCE" },
  { cnae: "0312401", descricao: "PEIXES EM AGUA DOCE; PESCA DE" },
  { cnae: "0312401", descricao: "CONTINENTAL DE PEIXES; PESCA" },
  { cnae: "0312401", descricao: "PEIXES EM RIOS E LAGOS; PESCA DE" },
  { cnae: "0312402", descricao: "MOLUSCOS EM AGUA DOCE; PESCA DE" },
  {
    cnae: "0312402",
    descricao: "CRUSTACEOS E MOLUSCOS EM AGUA DOCE; PESCA DE",
  },
  { cnae: "0312402", descricao: "CRUSTACEOS EM AGUA DOCE; PESCA DE" },
  {
    cnae: "0312403",
    descricao: "OUTROS PRODUTOS AQUATICOS DE AGUA DOCE; COLETA DE",
  },
  { cnae: "0312403", descricao: "PRODUTOS AQUATICOS DE AGUA DOCE; COLETA DE" },
  { cnae: "0312404", descricao: "APOIO A PESCA EM AGUA DOCE; SERVICO DE" },
  { cnae: "0312404", descricao: "SERVICOS RELACIONADOS A PESCA EM AGUA DOCE" },
  { cnae: "03213", descricao: "AQUICULTURA EM AGUA SALGADA E SALOBRA" },
  {
    cnae: "0321301",
    descricao: "ORGANISMOS MARINHOS - MARICULTURA; CRIACAO DE",
  },
  { cnae: "0321301", descricao: "PISCICULTURA" },
  {
    cnae: "0321301",
    descricao: "PEIXES EM AGUA SALGADA E SALOBRA; CRIACAO DE",
  },
  {
    cnae: "0321301",
    descricao: "ALEVINOS DE PEIXES DE AGUA SALGADA E SALOBRA; PRODUCAO DE",
  },
  { cnae: "0321301", descricao: "SALMOES; CRIACAO DE" },
  {
    cnae: "0321301",
    descricao:
      "OVAS DE PEIXES (FECUNDADAS PARA REPRODUCAO) DE AGUA SALGADA E SALOBRA; PRODUCAO DE",
  },
  { cnae: "0321302", descricao: "CAMAROES EM AGUA SALOBRA; CRIACAO DE" },
  { cnae: "0321302", descricao: "CAMAROES EM AGUA SALGADA; CRIACAO DE" },
  {
    cnae: "0321302",
    descricao: "POS LARVAS DE CAMARAO DE AGUA SALGADA E SALOBRA; PRODUCAO DE",
  },
  {
    cnae: "0321302",
    descricao: "CAMAROES EM AGUA SALGADA E SALOBRA; CRIACAO DE",
  },
  {
    cnae: "0321302",
    descricao: "CRUSTACEOS - CARCINICULTURA DE AGUA SALGADA; CRIACAO DE",
  },
  {
    cnae: "0321303",
    descricao: "MEXILHOES - MITILICULTURA DE ALGA SALGADA; CRIACAO DE",
  },
  { cnae: "0321303", descricao: "COQUILE; CRIACAO DE" },
  { cnae: "0321303", descricao: "SURURU; CRIACAO DE" },
  { cnae: "0321303", descricao: "VIEIRAS; CRIACAO DE" },
  { cnae: "0321303", descricao: "MARISCOS; CRIACAO DE" },
  { cnae: "0321303", descricao: "OSTRAS; CRIACAO DE" },
  { cnae: "0321303", descricao: "OSTRAS - OSTREICULTURA; CRIACAO DE" },
  { cnae: "0321303", descricao: "LAMBRETAS; CRIACAO DE" },
  {
    cnae: "0321304",
    descricao: "PEIXES ORNAMENTAIS DE AGUA SALOBRA; CRIACAO DE",
  },
  {
    cnae: "0321304",
    descricao: "PEIXES ORNAMENTAIS DE AGUA SALGADA; CRIACAO DE",
  },
  {
    cnae: "0321304",
    descricao:
      "ALEVINOS DE PEIXES ORNAMENTAIS DE AGUA SALGADA E SALOBRA; PRODUCAO DE",
  },
  {
    cnae: "0321304",
    descricao: "PEIXES ORNAMENTAIS DE AGUA SALGADA E SALOBRA; CRIACAO DE",
  },
  {
    cnae: "0321305",
    descricao: "APOIO A AQUICULTURA EM AGUA SALGADA E SALOBRA; SERVICO DE",
  },
  {
    cnae: "0321399",
    descricao: "PEROLAS CULTIVADAS DE AGUA SALOBRA; PRODUCAO DE",
  },
  { cnae: "0321399", descricao: "ALGAS EM AGUA SALOBRA; CULTIVO DE" },
  {
    cnae: "0321399",
    descricao: "PEROLAS CULTIVADAS DE AGUA SALGADA E SALOBRA; PRODUCAO DE",
  },
  {
    cnae: "0321399",
    descricao: "PEROLAS CULTIVADAS DE AGUA SALGADA; PRODUCAO DE",
  },
  { cnae: "0321399", descricao: "ALGAS EM AGUA SALGADA; CULTIVO DE" },
  { cnae: "0321399", descricao: "ALGAS EM AGUA SALGADA E SALOBRA; CULTIVO DE" },
  { cnae: "03221", descricao: "AQUICULTURA EM AGUA DOCE" },
  { cnae: "0322101", descricao: "CARPA; CRIACAO DE" },
  { cnae: "0322101", descricao: "PIAU; CRIACAO DE" },
  { cnae: "0322101", descricao: "TAMBACURI; CRIACAO DE" },
  { cnae: "0322101", descricao: "PEIXES - PISCICULTURA; CRIACAO DE" },
  { cnae: "0322101", descricao: "TAMBAQUI; CRIACAO DE" },
  {
    cnae: "0322101",
    descricao:
      "OVAS DE PEIXES (FECUNDADAS PARA REPRODUCAO) DE AGUA DOCE; PRODUCAO DE",
  },
  { cnae: "0322101", descricao: "PEIXES EM AGUA DOCE; CRIACAO DE" },
  { cnae: "0322101", descricao: "PACU; CRIACAO DE" },
  { cnae: "0322101", descricao: "PIABA; CRIACAO DE" },
  { cnae: "0322101", descricao: "LAMBARI; CRIACAO DE" },
  { cnae: "0322101", descricao: "DOURADO; CRIACAO DE" },
  { cnae: "0322101", descricao: "TRAIRA; CRIACAO DE" },
  {
    cnae: "0322101",
    descricao: "ALEVINOS DE PEIXES DE AGUA DOCE; PRODUCAO DE",
  },
  { cnae: "0322101", descricao: "TRUTAS; CRIACAO DE" },
  { cnae: "0322101", descricao: "SURUBIM; CRIACAO DE" },
  { cnae: "0322101", descricao: "PEIXES - PESQUE PAGUE; CRIACAO PROPRIA DE" },
  { cnae: "0322101", descricao: "PINTADO; CRIACAO DE" },
  { cnae: "0322101", descricao: "BAGRE; CRIACAO DE" },
  { cnae: "0322101", descricao: "TILAPIA; CRIACAO DE" },
  {
    cnae: "0322102",
    descricao: "POS LARVAS DE CAMARAO DE AGUA DOCE; PRODUCAO DE",
  },
  { cnae: "0322102", descricao: "CAMAROES EM AGUA DOCE; CRIACAO DE" },
  {
    cnae: "0322102",
    descricao: "CRUSTACEOS - CARCINICULTURA DE AGUA DOCE; CRIACAO DE",
  },
  { cnae: "0322102", descricao: "PITU; CRIACAO DE" },
  {
    cnae: "0322103",
    descricao: "MEXILHOES - MITILICULTURA DE AGUA DOCE; CRIACAO DE",
  },
  { cnae: "0322103", descricao: "MEXILHOES EM AGUA DOCE; CRIACAO DE" },
  { cnae: "0322103", descricao: "OSTRAS E MEXILHOES EM AGUA DOCE; CRIACAO DE" },
  { cnae: "0322103", descricao: "OSTRAS EM AGUA DOCE; CRIACAO DE" },
  { cnae: "0322104", descricao: "PEIXES ORNAMENTAIS EM AGUA DOCE; CRIACAO DE" },
  {
    cnae: "0322104",
    descricao: "ALEVINOS DE PEIXES ORNAMENTAIS DE AGUA DOCE; PRODUCAO DE",
  },
  { cnae: "0322105", descricao: "RAS; CRIACAO DE" },
  { cnae: "0322105", descricao: "RA; CRIACAO DE" },
  { cnae: "0322106", descricao: "JACARE; CRIACAO DE" },
  {
    cnae: "0322107",
    descricao: "APOIO A AQUICULTURA EM AGUA DOCE; SERVICO DE",
  },
  {
    cnae: "0322199",
    descricao: "PEROLAS CULTIVADAS DE AGUA DOCE; PRODUCAO DE",
  },
  { cnae: "05003", descricao: "EXTRACAO DE CARVAO MINERAL" },
  { cnae: "0500301", descricao: "CARVAO METALURGICO; EXTRACAO DE" },
  { cnae: "0500301", descricao: "LINHITO (LINHITA); EXTRACAO DE" },
  { cnae: "0500301", descricao: "HULHA; EXTRACAO DE" },
  { cnae: "0500301", descricao: "CARVAO MINERAL; EXTRACAO DE" },
  { cnae: "0500301", descricao: "CARVAO ENERGETICO (OU VAPOR); EXTRACAO DE" },
  { cnae: "0500301", descricao: "ANTRACITO (ANTRACITA); EXTRACAO DE" },
  { cnae: "0500302", descricao: "HULHA; BENEFICIAMENTO DE" },
  { cnae: "0500302", descricao: "CARVAO MINERAL; LAVAGEM DE" },
  { cnae: "0500302", descricao: "REJEITOS PIRITOSOS E XISTOSOS" },
  { cnae: "0500302", descricao: "CARVAO MINERAL PRE-LAVADO" },
  { cnae: "0500302", descricao: "CARVAO MINERAL; BENEFICIAMENTO DE" },
  {
    cnae: "0500302",
    descricao: "CARVAO MINERAL EM BRUTO (FINOS DE CARVAO); MOINHA DE",
  },
  { cnae: "06000", descricao: "EXTRACAO DE PETROLEO E GAS NATURAL" },
  { cnae: "0600001", descricao: "OLEOS BRUTOS DE PETROLEO; EXTRACAO DE" },
  { cnae: "0600001", descricao: "PETROLEO E GAS NATURAL; EXTRACAO DE" },
  { cnae: "0600001", descricao: "PETROLEO; EXTRACAO DE" },
  {
    cnae: "0600001",
    descricao:
      "PETROLEO CRU E GAS NATURAL, SERVICOS RELACIONADOS A EXTRACAO, REALIZADOS PELOS OPERADORES DOS POCOS",
  },
  { cnae: "0600001", descricao: "GAS NATURAL; EXTRACAO DE" },
  {
    cnae: "0600002",
    descricao: "XISTO - INCLUSIVE BETUMINOSO E PIROBETUMINOSO; EXTRACAO DE",
  },
  {
    cnae: "0600002",
    descricao:
      "OLEOS BRUTOS DE MINERAIS BETUMINOSOS (OLEOS DE XISTO, OLEOS DE AREIAS BETUMINOSAS); EXTRACAO DE",
  },
  {
    cnae: "0600003",
    descricao: "AREIAS BETUMINOSAS; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0600003", descricao: "AREIAS BETUMINOSAS; EXTRACAO DE" },
  { cnae: "07103", descricao: "EXTRACAO DE MINERIO DE FERRO" },
  {
    cnae: "0710301",
    descricao: "MINERIO DE FERRO (ITABIRITO, HEMATITA, CANGA); EXTRACAO DE",
  },
  { cnae: "0710302", descricao: "SINTER DE FERRO; PRODUCAO DE" },
  {
    cnae: "0710302",
    descricao:
      "MINERIO DE FERRO (ITABIRITO, HEMATITA, CANGA); SINTERIZADO, AGLOMERADO, PELOTIZADO, BENEFIFICADO; PRODUCAO DE",
  },
  {
    cnae: "0710302",
    descricao:
      "MINERIO DE FERRO (ITABIRITO, HEMATITA, CANGA); BENEFICIAMENTO DE",
  },
  {
    cnae: "0710302",
    descricao:
      "MINERIO DE FERRO (ITABIRITO, HEMATITA, CANGA), SINTERIZACAO, AGLOMERACAO, PELOTIZACAO; SERVICO DE",
  },
  { cnae: "07219", descricao: "EXTRACAO DE MINERIO DE ALUMINIO" },
  { cnae: "0721901", descricao: "BAUXITA; EXTRACAO DE" },
  { cnae: "0721901", descricao: "MINERIO DE ALUMINIO; EXTRACAO DE" },
  { cnae: "0721902", descricao: "BAUXITA CALCINADA, PRODUCAO DE" },
  { cnae: "0721902", descricao: "MINERIO DE ALUMINIO; BENEFICIAMENTO DE" },
  { cnae: "0721902", descricao: "BAUXITA; BENEFICIAMENTO DE" },
  { cnae: "07227", descricao: "EXTRACAO DE MINERIO DE ESTANHO" },
  { cnae: "0722701", descricao: "MINERIO DE ESTANHO; EXTRACAO DE" },
  { cnae: "0722701", descricao: "CASSITERITA; EXTRACAO DE" },
  { cnae: "0722702", descricao: "MINERIO DE ESTANHO CONCENTRADO; PRODUCAO DE" },
  { cnae: "0722702", descricao: "CASSITERITA; BENEFICIAMENTO DE" },
  { cnae: "0722702", descricao: "MINERIO DE ESTANHO; BENEFICIAMENTO DE" },
  { cnae: "0722702", descricao: "CASSITERITA CONCENTRADA; PRODUCAO DE" },
  { cnae: "07235", descricao: "EXTRACAO DE MINERIO DE MANGANES" },
  { cnae: "0723501", descricao: "MINERIO DE MANGANES; EXTRACAO DE" },
  { cnae: "0723502", descricao: "MINERIO DE MANGANES; BENEFICIAMENTO DE" },
  {
    cnae: "0723502",
    descricao: "MINERIO DE MANGANES CONCENTRADO; PRODUCAO DE",
  },
  { cnae: "07243", descricao: "EXTRACAO DE MINERIO DE METAIS PRECIOSOS" },
  { cnae: "0724301", descricao: "MINERIO DE PLATINA; EXTRACAO DE" },
  { cnae: "0724301", descricao: "MINERIO DE PRATA; EXTRACAO DE" },
  { cnae: "0724301", descricao: "OURO DE ALUVIAO OU EM PO; EXTRACAO DE" },
  { cnae: "0724301", descricao: "MINERIO DE OURO; EXTRACAO DE" },
  { cnae: "0724301", descricao: "MINERIOS DE METAIS PRECIOSOS; EXTRACAO DE" },
  { cnae: "0724302", descricao: "MINERIO DE OURO; BENEFICIAMENTO DE" },
  {
    cnae: "0724302",
    descricao: "MINERIOS DE METAIS PRECIOSOS; BENEFICIAMENTO DE",
  },
  { cnae: "0724302", descricao: "MINERIO DE PLATINA; BENEFICIAMENTO DE" },
  { cnae: "0724302", descricao: "MINERIO DE PRATA; BENEFICIAMENTO DE" },
  { cnae: "07251", descricao: "EXTRACAO DE MINERAIS RADIOATIVOS" },
  { cnae: "0725100", descricao: "MINERAIS RADIOATIVOS; EXTRACAO DE" },
  { cnae: "0725100", descricao: "MINERAIS RADIOATIVOS; BENEFICIAMENTO DE" },
  { cnae: "0725100", descricao: "MINERIO DE URANIO; EXTRACAO DE" },
  {
    cnae: "0725100",
    descricao: "URANIO CONCENTRADO (YELLOWCAKE); PRODUCAO DE",
  },
  { cnae: "0725100", descricao: "MINERAIS RADIOATIVOS; CONCENTRACAO DE" },
  { cnae: "0725100", descricao: "MINERIO DE TORIO; EXTRACAO DE" },
  { cnae: "0725100", descricao: "MINERIO DE RADIO; EXTRACAO DE" },
  { cnae: "0725100", descricao: "MONAZITA (AREIA MONAZITICA); EXTRACAO DE" },
  {
    cnae: "07294",
    descricao:
      "EXTRACAO DE MINERAIS METALICOS NAOFERROSOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "0729401",
    descricao: "MINERIO DE TITANIO (ILMENITA E RUTILO); EXTRACAO DE",
  },
  {
    cnae: "0729401",
    descricao:
      "MINERIO DE COLOMBIO OU NIOBIO CONCENTRADO (PIROCLORO); PRODUCAO DE",
  },
  {
    cnae: "0729401",
    descricao:
      "MINERIO DE COLOMBIO OU NIOBIO - TANTALO (COLOMBITA - TANTALITA); EXTRACAO DE",
  },
  { cnae: "0729401", descricao: "MINERIO DE NIOBIO; BENEFICIAMENTO DE" },
  { cnae: "0729401", descricao: "MINERIO DE NIOBIO; EXTRACAO DE" },
  { cnae: "0729402", descricao: "MINERIO DE TUNGSTENIO; EXTRACAO DE" },
  { cnae: "0729402", descricao: "MINERIO DE TUNGSTENIO; BENEFICIAMENTO DE" },
  { cnae: "0729403", descricao: "MINERIO DE NIQUEL; EXTRACAO DE" },
  { cnae: "0729403", descricao: "MINERIO DE NIQUEL; BENEFICIAMENTO DE" },
  { cnae: "0729404", descricao: "MINERIO DE CROMO; EXTRACAO DE" },
  { cnae: "0729404", descricao: "MINERIO DE CHUMBO; EXTRACAO DE" },
  { cnae: "0729404", descricao: "MINERIO DE ANTIMONIO; EXTRACAO DE" },
  {
    cnae: "0729404",
    descricao: "MINERIO DE ZIRCONIO (ZIRCONITA); EXTRACAO DE",
  },
  { cnae: "0729404", descricao: "MINERIO DE BERILIO (GLUCINIO); EXTRACAO DE" },
  { cnae: "0729404", descricao: "MINERIO DE COBALTO; EXTRACAO DE" },
  { cnae: "0729404", descricao: "TERRAS RARAS; EXTRACAO DE" },
  { cnae: "0729404", descricao: "MINERIO DE COBRE; EXTRACAO DE" },
  { cnae: "0729404", descricao: "MINERIO DE MOLIBDENIO; EXTRACAO DE" },
  {
    cnae: "0729404",
    descricao:
      "MINERIO DE LITIO (AMBLIGONITA, LEPIDOLITA, PEDALITA); EXTRACAO DE",
  },
  { cnae: "0729404", descricao: "MINERIO DE VANADIO; EXTRACAO DE" },
  { cnae: "0729404", descricao: "MINERIO DE ZINCO; EXTRACAO DE" },
  { cnae: "0729405", descricao: "MINERIO DE CROMO CONCENTRADO; PRODUCAO DE" },
  { cnae: "0729405", descricao: "MINERIO DE ZINCO CONCENTRADO; PRODUCAO DE" },
  { cnae: "0729405", descricao: "MINERIO DE COBRE; BENEFICIAMENTO DE" },
  { cnae: "0729405", descricao: "MINERIO DE COBRE CONCENTRADO; PRODUCAO DE" },
  { cnae: "0729405", descricao: "MINERIO DE CHUMBO CONCENTRADO; PRODUCAO DE" },
  { cnae: "0729405", descricao: "MINERIO DE ZINCO; BENEFICIAMENTO DE" },
  { cnae: "0729405", descricao: "MINERIO DE CHUMBO; BENEFICIAMENTO DE" },
  { cnae: "08100", descricao: "EXTRACAO DE PEDRA, AREIA E ARGILA" },
  {
    cnae: "0810001",
    descricao:
      "ARDOSIA; BENEFICIAMENTO PRIMARIO (CORTE, LIMPEZA,SECAGEM ETC...) ASSOCIADO A EXTRACAO DE",
  },
  { cnae: "0810001", descricao: "ARDOSIA; EXTRACAO DE" },
  { cnae: "0810002", descricao: "GRANITO; EXTRACAO DE" },
  {
    cnae: "0810002",
    descricao:
      "GRANITO; BENEFICIAMENTO PRIMARIO (CORTE, LIMPEZA,SECAGEM ETC...) ASSOCIADO A EXTRACAO DE",
  },
  { cnae: "0810003", descricao: "MARMORE; EXTRACAO DE" },
  { cnae: "0810003", descricao: "PO DE MARMORE; PRODUCAO DE" },
  {
    cnae: "0810003",
    descricao:
      "MARMORE; BENEFICIAMENTO PRIMARIO (CORTE, LIMPEZA,SECAGEM ETC...) ASSOCIADO A EXTRACAO DE",
  },
  { cnae: "0810004", descricao: "CONCHAS CALCARIAS; EXTRACAO DE" },
  { cnae: "0810004", descricao: "CRE; EXTRACAO DE" },
  {
    cnae: "0810004",
    descricao: "CALCARIO; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0810004", descricao: "DOLOMITA; EXTRACAO DE" },
  {
    cnae: "0810004",
    descricao: "DOLOMITA; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0810004", descricao: "CALCARIOS (PEDRAS E MARISCOS); EXTRACAO DE" },
  { cnae: "0810004", descricao: "CALCARIO; EXTRACAO DE" },
  {
    cnae: "0810004",
    descricao: "CONCHAS CALCARIAS; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  {
    cnae: "0810004",
    descricao:
      "DOLOMITA; TRITURACAO, FRAGMENTACAO E OUTROS BENEFICIAMENTOS DE ( ASSOCIADOS A EXTRACAO)",
  },
  {
    cnae: "0810004",
    descricao: "PO CALCARIO; FABRICACAO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0810005", descricao: "GESSO OU GIPSITA; EXTRACAO DE" },
  { cnae: "0810005", descricao: "CAULIM; EXTRACAO DE" },
  { cnae: "0810005", descricao: "GESSO E CAULIM; EXTRACAO DE" },
  { cnae: "0810005", descricao: "ALABASTRO; EXTRACAO DE" },
  { cnae: "0810006", descricao: "AREIA LAVADA PARA CONSTRUCAO; EXTRACAO DE" },
  { cnae: "0810006", descricao: "CASCALHO NATURAL; EXTRACAO DE" },
  { cnae: "0810006", descricao: "AREIA QUARTZOSAS; EXTRACAO DE" },
  { cnae: "0810006", descricao: "AREIA; EXTRACAO DE" },
  {
    cnae: "0810006",
    descricao:
      "AREIAS, CASCALHOS, PEDREGULHOS E SEMELHANTES; BENEFICIAMENTO DE",
  },
  { cnae: "0810006", descricao: "AREIAS SILICIOSAS; EXTRACAO DE" },
  { cnae: "0810007", descricao: "ANDALUZITA (ARGILA); EXTRACAO DE" },
  {
    cnae: "0810007",
    descricao:
      "CIANITA, SILIMANITA E OUTRAS ARGILAS, N.E; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0810007", descricao: "ARGILA; EXTRACAO DE" },
  {
    cnae: "0810007",
    descricao: "MULITA; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  {
    cnae: "0810007",
    descricao:
      "TERRAS DESCORANTES (TERRAS DE FULLER); BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  {
    cnae: "0810007",
    descricao: "TERRAS DESCORANTES (TERRAS DE FULLER); EXTRACAO DE",
  },
  { cnae: "0810007", descricao: "MULITA; EXTRACAO DE" },
  {
    cnae: "0810007",
    descricao: "CIANITA, SILIMANITA E OUTRAS ARGILAS, N.E.; EXTRACAO DE",
  },
  {
    cnae: "0810007",
    descricao: "BENTONITA; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  {
    cnae: "0810007",
    descricao: "ANDALUZITA (ARGILA); BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0810007", descricao: "ARGILAS; BENEFICIAMENTO DE" },
  {
    cnae: "0810007",
    descricao: "ARGILAS - INCLUSIVE REFRATARIAS; EXTRACAO DE",
  },
  {
    cnae: "0810007",
    descricao: "ARGILAS; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0810007", descricao: "BENTONITA; EXTRACAO DE" },
  {
    cnae: "0810008",
    descricao: "SAIBRO; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0810008", descricao: "SAIBRO; EXTRACAO DE" },
  {
    cnae: "0810009",
    descricao: "BASALTO; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0810009", descricao: "BASALTO; EXTRACAO DE" },
  {
    cnae: "0810010",
    descricao: "CAULIM; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  {
    cnae: "0810010",
    descricao: "GESSO E CAULIM ASSOCIADO A EXTRACAO; BENEFICIAMENTO DE",
  },
  {
    cnae: "0810010",
    descricao: "GESSO; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0810099", descricao: "CARBONATO DE CALCIO NATURAL" },
  {
    cnae: "0810099",
    descricao:
      "PEDRAS E OUTROS MATERIAIS PARA CONSTRUCAO; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0810099", descricao: "ARENITO; EXTRACAO DE" },
  {
    cnae: "0810099",
    descricao: "PEDRA BRITADA (BRITAMENTO ASSOCIADO A EXTRACAO); EXTRACAO DE",
  },
  { cnae: "0810099", descricao: "PEDRA, ASSOCIADO A EXTRACAO; BRITAMENTO DE" },
  {
    cnae: "0810099",
    descricao: "QUARTZITO; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0810099", descricao: "CINZA POZOLANICA; EXTRACAO DE" },
  {
    cnae: "0810099",
    descricao:
      "QUARTZITOS EM BRUTO, DESBASTADO, SERRADO EM BLOCOS OU PLACAS; PRODUCAO DE",
  },
  { cnae: "0810099", descricao: "PEDRA ROLADA (SEIXOS); EXTRACAO DE" },
  { cnae: "0810099", descricao: "PEDRAS PARA CONSTRUCAO; EXTRACAO DE" },
  {
    cnae: "0810099",
    descricao: "BARRO COZIDO EM PO E TERRAS DE DINAS; EXTRACAO DE",
  },
  {
    cnae: "0810099",
    descricao:
      "MACADAME DE ESCORIAS DE ALTO-FORNO OU DE OUTROS RESIDUOS; FABRICACAO DE",
  },
  { cnae: "0810099", descricao: "QUARTZITOS; EXTRACAO DE" },
  { cnae: "0810099", descricao: "POZOLANA; EXTRACAO DE" },
  {
    cnae: "08916",
    descricao:
      "EXTRACAO DE MINERAIS PARA FABRICACAO DE ADUBOS, FERTILIZANTES E OUTROS PRODUTOS QUIMICOS",
  },
  {
    cnae: "0891600",
    descricao: "MINERAIS PARA FABRICACAO DE FERTILIZANTES; EXTRACAO DE",
  },
  { cnae: "0891600", descricao: "FOSFATOS ALUMINOCALCICOS; EXTRACAO DE" },
  { cnae: "0891600", descricao: "FOSFATO DE CALCIO NATURAL; EXTRACAO DE" },
  { cnae: "0891600", descricao: "BARITA; EXTRACAO DE" },
  {
    cnae: "0891600",
    descricao: "MINERAIS PARA FABRICACAO DE PRODUTOS QUIMICOS; EXTRACAO DE",
  },
  { cnae: "0891600", descricao: "KIESERITA, EPSOMITA; EXTRACAO DE" },
  {
    cnae: "0891600",
    descricao: "FLUORITA; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0891600", descricao: "SAIS MINERAIS, N.E.; EXTRACAO DE" },
  {
    cnae: "0891600",
    descricao: "OCRAS E OUTROS CORANTES MINERAIS (PIGMENTOS); EXTRACAO DE",
  },
  { cnae: "0891600", descricao: "ENXOFRE; EXTRACAO DE" },
  {
    cnae: "0891600",
    descricao: "ENXOFRE; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0891600", descricao: "FLUORITA; EXTRACAO DE" },
  {
    cnae: "0891600",
    descricao: "SULFATO DE BARIO NATURAL (BARITINA); EXTRACAO DE",
  },
  { cnae: "0891600", descricao: "TERRAS CORANTES; EXTRACAO DE" },
  {
    cnae: "0891600",
    descricao:
      "ENXOFRE EM BRUTO; ENXOFRE NAO  REFINADO; ENXOFRE RECUPERADO; FABRICACAO DE",
  },
  {
    cnae: "0891600",
    descricao: "BARITA; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  {
    cnae: "0891600",
    descricao: "FOSFATOS DE CALCIO NATURAIS (APATITA); EXTRACAO DE",
  },
  { cnae: "0891600", descricao: "CRE (CRE) FOSFATADO; EXTRACAO DE" },
  { cnae: "0891600", descricao: "APATITA; EXTRACAO DE" },
  { cnae: "0891600", descricao: "PIRITA DE FERRO NAO USTULADAS; EXTRACAO DE" },
  { cnae: "0891600", descricao: "SULFATO DE MAGNESIO NATURAL; EXTRACAO DE" },
  { cnae: "0891600", descricao: "BORATOS NATURAIS; EXTRACAO DE" },
  {
    cnae: "0891600",
    descricao: "BORAX (BORATO DE SODIO NATURAL); EXTRACAO DE",
  },
  { cnae: "0891600", descricao: "FOSFATOS NATURAIS; EXTRACAO DE" },
  {
    cnae: "0891600",
    descricao: "SULFATOS NATURAIS; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0891600", descricao: "NITRATOS NATURAIS (SALITRE); EXTRACAO DE" },
  { cnae: "0891600", descricao: "ESPODUMENIO; EXTRACAO DE" },
  {
    cnae: "0891600",
    descricao:
      "SAIS MINERAIS NATURAIS; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0891600", descricao: "SULFATOS NATURAIS; EXTRACAO DE" },
  {
    cnae: "0891600",
    descricao:
      "CARNALITA, SILVINITA OU OUTROS SAIS DE POTASSIO NATURAIS EM BRUTO; EXTRACAO DE",
  },
  { cnae: "0891600", descricao: "ESPATOFLUOR (FLUORITA NATURAL); EXTRACAO DE" },
  {
    cnae: "0891600",
    descricao: "MINERAIS PARA FABRICACAO DE ADUBOS; EXTRACAO DE",
  },
  { cnae: "0891600", descricao: "GUANO; EXTRACAO DE" },
  {
    cnae: "0891600",
    descricao: "CRIOLITA NATURAL; QUIOLITA NATURAL; EXTRACAO DE",
  },
  {
    cnae: "0891600",
    descricao: "FOSFATOS NATURAIS; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  {
    cnae: "0891600",
    descricao:
      "OCRAS E OUTROS CORANTES MINERAIS (PIGMENTOS); BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "08924", descricao: "EXTRACAO E REFINO DE SAL MARINHO E SALGEMA" },
  { cnae: "0892401", descricao: "SAL MARINHO; EXTRACAO DE" },
  { cnae: "0892401", descricao: "SAL GROSSO; PRODUCAO DE" },
  { cnae: "0892401", descricao: "SAL DE SALINA; EXTRACAO DE" },
  { cnae: "0892402", descricao: "SAL-GEMA; BENEFICIAMENTO DE" },
  { cnae: "0892402", descricao: "SAL-GEMA; EXTRACAO DE" },
  { cnae: "0892403", descricao: "SAL MARINHO; BENEFICIAMENTO DE" },
  {
    cnae: "0892403",
    descricao: "SAL DE SALINA; MOAGEM, PURIFICACAO, REFINO DE",
  },
  {
    cnae: "0892403",
    descricao: "CLORETO DE SODIO; MOAGEM, PURIFICACAO, REFINO DE",
  },
  {
    cnae: "0892403",
    descricao:
      "SAL REFINADO (CLORETO DE SODIO); PREPARACAO DE (ASSOCIADA A EXTRACAO)",
  },
  {
    cnae: "0892403",
    descricao:
      "SAL (REFINADO, MOIDO, ETC); PREPARACAO DE (ASSOCIADO A EXTRACAO)",
  },
  {
    cnae: "08932",
    descricao: "EXTRACAO DE GEMAS (PEDRAS PRECIOSAS E SEMIPRECIOSAS)",
  },
  { cnae: "0893200", descricao: "AMETISTA (PEDRA SEMIPRECIOSA); EXTRACAO DE" },
  {
    cnae: "0893200",
    descricao: "GEMAS E DIAMANTES (PEDRA PRECIOSA); EXTRACAO DE",
  },
  { cnae: "0893200", descricao: "GEMAS; BENEFICIAMENTO DE" },
  {
    cnae: "0893200",
    descricao: "AGUA-MARINHA (PEDRA SEMIPRECIOSA); EXTRACAO DE",
  },
  { cnae: "0893200", descricao: "AGATA (PEDRA SEMIPRECIOSA); EXTRACAO DE" },
  { cnae: "0893200", descricao: "BERILO (PEDRA SEMIPRECIOSA); EXTRACAO DE" },
  { cnae: "0893200", descricao: "ONIX (PEDRA SEMIPRECIOSA); EXTRACAO DE" },
  { cnae: "0893200", descricao: "RUBI (PEDRA PRECIOSA); EXTRACAO DE" },
  {
    cnae: "0893200",
    descricao: "DIAMANTE INDUSTRIAL (CARBONATO OU LAVRITA); EXTRACAO DE",
  },
  { cnae: "0893200", descricao: "SAFIRA (PEDRA PRECIOSA); EXTRACAO DE" },
  { cnae: "0893200", descricao: "TURMALINA (PEDRA SEMIPRECIOSA); EXTRACAO DE" },
  { cnae: "0893200", descricao: "TOPAZIO (PEDRA SEMIPRECIOSA); EXTRACAO DE" },
  { cnae: "0893200", descricao: "CORINDON (PEDRA PRECIOSA); EXTRACAO DE" },
  { cnae: "0893200", descricao: "GEMAS; EXTRACAO DE" },
  { cnae: "0893200", descricao: "OPALA (PEDRA PRECIOSA); EXTRACAO DE" },
  { cnae: "0893200", descricao: "PEDRAS SEMIPRECIOSAS; EXTRACAO DE" },
  { cnae: "0893200", descricao: "PEDRAS PRECIOSAS; EXTRACAO DE" },
  { cnae: "0893200", descricao: "GRANADA (PEDRA SEMIPRECIOSA); EXTRACAO DE" },
  { cnae: "0893200", descricao: "ESMERALDA (PEDRA PRECIOSA); EXTRACAO DE" },
  {
    cnae: "08991",
    descricao:
      "EXTRACAO DE MINERAIS NAOMETALICOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "0899101", descricao: "GRAFITA; EXTRACAO DE" },
  {
    cnae: "0899101",
    descricao: "GRAFITA; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0899102", descricao: "QUARTZO (CRISTAL DE ROCHA); EXTRACAO DE" },
  {
    cnae: "0899102",
    descricao:
      "QUARTZO (CRISTAL DE ROCHA); BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0899103", descricao: "AMIANTO OU ASBESTO; EXTRACAO DE" },
  {
    cnae: "0899103",
    descricao: "AMIANTO OU ASBESTO; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0899199", descricao: "ESTEATITA; EXTRACAO DE" },
  {
    cnae: "0899199",
    descricao: "FILITOS (ANTOFILITOS, LEUCOFILITOS, ETC); EXTRACAO DE",
  },
  { cnae: "0899199", descricao: "MICA OU MALACACHETA; EXTRACAO DE" },
  {
    cnae: "0899199",
    descricao: "MAGNESITA; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0899199", descricao: "SILICA (TERRAS ATIVADAS); EXTRACAO DE" },
  {
    cnae: "0899199",
    descricao: "ESMERIL E OUTROS MINERAIS ABRASIVOS; EXTRACAO DE",
  },
  {
    cnae: "0899199",
    descricao:
      "FILITOS (ANTOFILITOS, LEUCOFILITOS, ETC); BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0899199", descricao: "PIROFILITA; EXTRACAO DE" },
  { cnae: "0899199", descricao: "TERRAS SILICIOSAS (KIESELGUHR); EXTRACAO DE" },
  { cnae: "0899199", descricao: "TURFA; EXTRACAO DE" },
  { cnae: "0899199", descricao: "ASFALTO E BETUME NATURAIS; EXTRACAO DE" },
  {
    cnae: "0899199",
    descricao: "MAGNESITA (CARBONATO NATURAL DE MAGNESIO); EXTRACAO DE",
  },
  {
    cnae: "0899199",
    descricao: "VERMICULITA, PERLITA OU CLORITAS; EXTRACAO DE",
  },
  {
    cnae: "0899199",
    descricao:
      "FELDSPATO, LEUCITA OU NEFELITA NATURAIS; BENEFICIAMENTO DE  (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0899199", descricao: "MAGNESIA CALCINADA; FABRICACAO DE" },
  {
    cnae: "0899199",
    descricao: "ESTEATITA; BENEFICIAMENTO DE  (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0899199", descricao: "PEDRAS ABRASIVAS; EXTRACAO DE" },
  {
    cnae: "0899199",
    descricao:
      "TALCO, AGALMATOLITO OU ESTEATITA; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0899199", descricao: "CARBONATOS NATURAIS; EXTRACAO DE" },
  {
    cnae: "0899199",
    descricao: "PEDRA-POMES; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0899199", descricao: "SILEX; EXTRACAO DE" },
  { cnae: "0899199", descricao: "MAGNESITA; PELOTIZACAO DE" },
  { cnae: "0899199", descricao: "TRIPOLE (TERRAS DE INFUSORIO); EXTRACAO DE" },
  { cnae: "0899199", descricao: "AGALMATOLITO; EXTRACAO DE" },
  { cnae: "0899199", descricao: "CORINDO NATURAL; OBTENCAO DE" },
  {
    cnae: "0899199",
    descricao: "TALCO, AGALMATOLITO OU ESTEATITA; EXTRACAO DE",
  },
  {
    cnae: "0899199",
    descricao:
      "VERMICULITA, PERLITA OU CLORITAS; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0899199", descricao: "SINTER DE MAGNESITA; FABRICACAO DE" },
  { cnae: "0899199", descricao: "PEDRA-POMES; EXTRACAO DE" },
  {
    cnae: "0899199",
    descricao:
      "TERRAS SILICIOSAS (KIESELGUHR); BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0899199", descricao: "DIATOMITA; EXTRACAO DE" },
  {
    cnae: "0899199",
    descricao: "DIATOMITA; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  {
    cnae: "0899199",
    descricao:
      "ESMERIL E OUTROS MINERAIS ABRASIVOS; BENEFICIAMENTO DE  (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0899199", descricao: "TERRAS PARA FUNDICAO; EXTRACAO DE" },
  {
    cnae: "0899199",
    descricao: "MICA OU MALACACHETA; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  { cnae: "0899199", descricao: "CELESTITA; EXTRACAO DE" },
  {
    cnae: "0899199",
    descricao: "AGALMATOLITO; BENEFICIAMENTO DE (ASSOCIADO A EXTRACAO)",
  },
  {
    cnae: "09106",
    descricao: "ATIVIDADES DE APOIO A EXTRACAO DE PETROLEO E GAS NATURAL",
  },
  {
    cnae: "0910600",
    descricao:
      "PERFURACAO, REPERFURACAO, DESMANTELAMENTO DE TORRES E OUTROS SERVICOS RELACIONADOS COM A EXTRACAO DE PETROLEO E GAS, EXECUTADOS POR CONTRATO",
  },
  {
    cnae: "0910600",
    descricao:
      "LIQUEFACAO E REGASEIFICACAO DE GAS NATURAL, NA MINA, PARA TRANSPORTE; SERVICOS DE",
  },
  {
    cnae: "0910600",
    descricao: "APOIO A EXTRACAO DE PETROLEO E GAS NATURAL; ATIVIDADES DE",
  },
  {
    cnae: "0910600",
    descricao:
      "PETROLEO E GAS NATURAL; SERVICOS RELACIONADOS COM A EXTRACAO, REALIZADO POR CONTRATO (EXCETO A PROSPECCAO)",
  },
  {
    cnae: "09904",
    descricao:
      "ATIVIDADES DE APOIO A EXTRACAO DE MINERAIS, EXCETO PETROLEO E GAS NATURAL",
  },
  {
    cnae: "0990401",
    descricao:
      "PERFURACAO, PROSPECCAO, RETIRADA DE AMOSTRAS PARA EXTRACAO DE MINERIO DE FERRO REALIZADAS SOB CONTRATO, SERVICOS DE",
  },
  {
    cnae: "0990401",
    descricao: "APOIO A EXTRACAO DE MINERIO DE FERRO; ATIVIDADES DE",
  },
  {
    cnae: "0990401",
    descricao:
      "DRENAGEM E BOMBEAMENTO DE MINAS DE EXTRACAO DE MINERIO DE FERRO REALIZADOS SOB CONTRATO; SERVICOS DE",
  },
  {
    cnae: "0990401",
    descricao:
      "TRANSPORTE OFF-ROAD EM MINAS DE MINERIO DE FERRO REALIZADO SOB CONTRATO, SERVICOS DE",
  },
  {
    cnae: "0990402",
    descricao:
      "APOIO A EXTRACAO DE MINERIO DE CHUMBO, DE NIQUEL OU DE ZINCO; ATIVIDADES DE",
  },
  {
    cnae: "0990402",
    descricao:
      "APOIO A EXTRACAO DE MINERIOS DE METAIS PRECIOSOS; ATIVIDADES DE",
  },
  {
    cnae: "0990402",
    descricao: "APOIO A EXTRACAO DE MINERIO DE COBRE; ATIVIDADES DE",
  },
  { cnae: "2110600", descricao: "PRILOCAINA; FABRICACAO DE" },
  {
    cnae: "0990402",
    descricao:
      "APOIO A EXTRACAO DE MINERIOS DE METAIS NAO-FERROSOS; ATIVIDADES DE",
  },
  {
    cnae: "0990402",
    descricao: "APOIO A EXTRACAO DE MINERIO DE OURO; ATIVIDADES DE",
  },
  {
    cnae: "0990402",
    descricao: "APOIO A EXTRACAO DE MINERAIS RADIOATIVOS; ATIVIDADES DE",
  },
  {
    cnae: "0990402",
    descricao:
      "APOIO A EXTRACAO DE MINERIO DE ALUMINIO (BAUXITA); ATIVIDADES DE",
  },
  {
    cnae: "0990402",
    descricao:
      "APOIO A EXTRACAO DE MINERIO DE ESTANHO (CASSITERITA); ATIVIDADES DE",
  },
  {
    cnae: "0990402",
    descricao: "APOIO A EXTRACAO DE MINERIO DE MANGANES; ATIVIDADES DE",
  },
  {
    cnae: "0990402",
    descricao: "APOIO A EXTRACAO DE MINERIO DE NIQUEL; ATIVIDADES DE",
  },
  {
    cnae: "0990402",
    descricao:
      "APOIO A EXTRACAO DE MINERIO DE NIOBIO OU DE TITANIO; ATIVIDADES DE",
  },
  {
    cnae: "0990402",
    descricao: "APOIO A EXTRACAO DE MINERIO DE TUNGSTENIO; ATIVIDADES DE",
  },
  {
    cnae: "0990403",
    descricao:
      "APOIO A EXTRACAO DE GEMAS (PEDRAS PRECIOSAS E SEMIPRECIOSAS); ATIVIDADES DE",
  },
  {
    cnae: "0990403",
    descricao: "APOIO A EXTRACAO DE MARMORE OU GRANITO; ATIVIDADES DE",
  },
  {
    cnae: "0990403",
    descricao: "APOIO A EXTRACAO DE SAL MARINHO OU SAL-GEMA; ATIVIDADES DE",
  },
  {
    cnae: "0990403",
    descricao: "APOIO A EXTRACAO DE GESSO OU CAULIM; ATIVIDADES DE",
  },
  {
    cnae: "0990403",
    descricao: "APOIO A EXTRACAO DE PEDRA, AREIA E ARGILA; ATIVIDADES DE",
  },
  { cnae: "0990403", descricao: "APOIO A EXTRACAO DE QUARTZO; ATIVIDADES DE" },
  {
    cnae: "0990403",
    descricao: "APOIO A EXTRACAO DE CARVAO MINERAL; ATIVIDADES DE",
  },
  {
    cnae: "0990403",
    descricao: "APOIO A EXTRACAO DE CALCARIO OU DOLOMITA; ATIVIDADES DE",
  },
  {
    cnae: "0990403",
    descricao: "APOIO A EXTRACAO DE MINERAIS NAO-METALICOS; ATIVIDADES DE",
  },
  {
    cnae: "0990403",
    descricao:
      "APOIO A EXTRACAO DE MINERAIS PARA A FABRICACAO DE ADUBOS OU FERTILIZANTES; ATIVIDADES DE",
  },
  {
    cnae: "0990403",
    descricao:
      "APOIO A EXTRACAO DE MINERAIS PARA A FABRICACAO DE PRODUTOS QUIMICOS; ATIVIDADES DE",
  },
  { cnae: "0990403", descricao: "APOIO A EXTRACAO DE GRAFITA; ATIVIDADES DE" },
  { cnae: "10112", descricao: "ABATE DE RESES, EXCETO SUINOS" },
  {
    cnae: "1011201",
    descricao:
      "CARNE BOVINA DEFUMADA; PRODUCAO DE, (QUANDO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1011201",
    descricao: "COUROS VERDES (FRESCOS) DE BOVINOS; PRODUCAO DE",
  },
  {
    cnae: "1011201",
    descricao:
      "FARINHAS, POS OU PELLETS DE CARNE DE BOVINOS; PRODUCAO DE (QUANDO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1011201",
    descricao:
      "MIUDOS E TRIPAS DE BOVINOS (FRESCOS, REFRIGERADOS OU CONGELADOS); PRODUCAO DE",
  },
  {
    cnae: "1011201",
    descricao: "OLEOS E GORDURAS COMESTIVEIS DE BOVINOS; PRODUCAO DE",
  },
  {
    cnae: "1011201",
    descricao:
      "PREPARACOES E CONSERVAS DE BOVINOS (HAMBURGUERES, QUIBES, ALMONDEGAS, ETC); PRODUCAO DE (QUANDO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1011201",
    descricao: "DESPOJOS EM BRUTO DO ABATE DE BOVINOS (GLANDULAS, OSSOS, ETC.)",
  },
  { cnae: "1011201", descricao: "CARNE BOVINA CONGELADA; PRODUCAO DE" },
  {
    cnae: "1011201",
    descricao: "COUROS E PELES DE BOVINOS; SECAGEM E SALGA DE",
  },
  { cnae: "1011201", descricao: "BOVINOS; ABATEDOURO DE" },
  {
    cnae: "1011201",
    descricao: "CARCACA DE BOVINOS (BOVINOS ABATIDO); PRODUCAO DE",
  },
  {
    cnae: "1011201",
    descricao: "COUROS E PELES DE BOVINOS, SEM CURTIR; PRODUCAO DE",
  },
  {
    cnae: "1011201",
    descricao:
      "EMBUTIDOS DE CARNE BOVINOS; PRODUCAO DE (QUANDO INTEGRADA AO ABATE)",
  },
  { cnae: "1011201", descricao: "RESES (BOVINOS); ABATE DE" },
  {
    cnae: "1011201",
    descricao:
      "PRODUTOS DE SALSICHARIA DE CARNE DE BOVINOS; PRODUCAO DE (QUANDO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1011201",
    descricao:
      "SALAME, SALSICHA, LINGUICA, MORTADELA, PRESUNTO E OUTROS PRODUTOS DE SALAMARIA DE CARNE DE BOVINOS; PRODUCAO DE (QUANDO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1011201",
    descricao:
      "CONSERVAS DE CARNE DE BOVINOS; PRODUCAO DE (QUANDO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1011201",
    descricao: "CARNE DE BOVINOS FRESCA (VERDE) OU REFRIGERADA; PRODUCAO DE",
  },
  {
    cnae: "1011201",
    descricao:
      "FARINHAS, POS OU PELLETS COMESTIVEIS DE CARNE OU MIUDEZAS DE BOVINOS; PRODUCAO DE, QUANDO INTEGRADA AO ABATE",
  },
  { cnae: "1011201", descricao: "BOVINOS; ABATE DE" },
  {
    cnae: "1011201",
    descricao: "GORDURAS DE BOVINOS (SEBO), FUNDIDOS OU NAO; PRODUCAO DE",
  },
  {
    cnae: "1011201",
    descricao: "COUROS DE BOVINOS SECOS OU SALGADOS; PRODUCAO DE",
  },
  { cnae: "1011202", descricao: "EQUINOS; ABATE DE" },
  {
    cnae: "1011202",
    descricao:
      "PRODUTOS DE SALSICHARIA DE CARNE DE EQUINOS; PRODUCAO DE (QUANDO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1011202",
    descricao:
      "EMBUTIDOS DIVERSOS DE EQUINOS; PRODUCAO DE (QUANDO INTEGRADA AO ABATE)",
  },
  { cnae: "1011202", descricao: "ASININOS; ABATE DE" },
  { cnae: "1011202", descricao: "CARNE DE EQUINO CONGELADA; PRODUCAO DE" },
  {
    cnae: "1011202",
    descricao:
      "DESPOJOS EM BRUTO DO ABATE DE EQUINOS (PELOS, CERDAS, GLANDULAS, OSSOS, ETC.); PRODUCAO DE",
  },
  {
    cnae: "1011202",
    descricao:
      "MIUDOS E TRIPAS DE EQUINOS (FRESCOS, REFRIGERADOS OU CONGELADOS); PRODUCAO DE",
  },
  {
    cnae: "1011202",
    descricao: "COUROS VERDES (FRESCOS) DE EQUINOS; PRODUCAO DE",
  },
  {
    cnae: "1011202",
    descricao: "OLEOS E GORDURAS COMESTIVEIS DE EQUINOS; PRODUCAO DE",
  },
  {
    cnae: "1011202",
    descricao: "COUROS SECOS E/OU SALGADOS DE EQUINOS; PRODUCAO DE",
  },
  {
    cnae: "1011202",
    descricao: "CARNE DE EQUINO FRESCA (VERDE) OU REFRIGERADA; PRODUCAO DE",
  },
  { cnae: "1011202", descricao: "RESES (EQUINOS); ABATE DE" },
  {
    cnae: "1011202",
    descricao: "COUROS E PELES DE EQUINOS, SEM CURTIR; PRODUCAO DE",
  },
  {
    cnae: "1011202",
    descricao: "COUROS E PELES DE EQUINOS; SECAGEM E SALGA DE",
  },
  { cnae: "1011202", descricao: "CAVALOS; ABATE DE" },
  {
    cnae: "1011202",
    descricao: "COURO DE EQUINOS, SECAGEM E SALGA; SERVICO DE",
  },
  { cnae: "1011202", descricao: "MUARES; ABATE DE" },
  {
    cnae: "1011202",
    descricao:
      "PRODUTOS DIVERSOS DE SALAMARIA DE EQUINOS; PRODUCAO DE (QUANDO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1011202",
    descricao:
      "CONSERVAS DE CARNE DE EQUINOS; PRODUCAO DE (QUANDO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1011202",
    descricao: "CARCACA DE EQUINOS (EQUINO ABATIDO); PRODUCAO DE",
  },
  { cnae: "1011202", descricao: "SEBO DE EQUINO; PRODUCAO DE" },
  {
    cnae: "1011203",
    descricao:
      "DESPOJOS EM BRUTO DO ABATE DE OVINOS OU CAPRINOS (GLANDULAS, OSSOS, ETC.); PRODUCAO DE",
  },
  {
    cnae: "1011203",
    descricao: "CARCACA DE CAPRINO (CAPRINO ABATIDO); PRODUCAO DE",
  },
  {
    cnae: "1011203",
    descricao:
      "CONSERVAS DE CARNE DE OVINOS E CAPRINOS; PRODUCAO DE, QUANDO INTEGRADA AO ABATE",
  },
  {
    cnae: "1011203",
    descricao:
      "MIUDOS E TRIPAS DE OVINOS E CAPRINOS (FRESCOS, REFRIGERADOS OU CONGELADOS); PRODUCAO DE",
  },
  {
    cnae: "1011203",
    descricao:
      "PRODUTOS DE SALSICHARIA DE CARNE DE OVINOS OU CAPRINOS; PRODUCAO DE (QUANDO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1011203",
    descricao:
      "PRODUTOS DE SALAMARIA DE CARNE DE OVINOS OU CAPRINOS; PRODUCAO DE, QUANDO INTEGRADA AO ABATE",
  },
  {
    cnae: "1011203",
    descricao: "CARCACA DE OVINO (OVINO ABATIDO); PRODUCAO DE",
  },
  { cnae: "1011203", descricao: "RESES (OVINOS E CAPRINOS); ABATE DE" },
  {
    cnae: "1011203",
    descricao: "CARNE DE CAPRINO FRESCA (VERDE) OU REFRIGERADA; PRODUCAO DE",
  },
  { cnae: "1011203", descricao: "OVINOS E CAPRINOS; ABATE DE" },
  { cnae: "1011203", descricao: "CARNE DE CAPRINO CONGELADA; PRODUCAO DE" },
  { cnae: "1011203", descricao: "CARNE DE OVINO CONGELADA; PRODUCAO DE" },
  { cnae: "1011203", descricao: "CAPRINOS; ABATE DE" },
  {
    cnae: "1011203",
    descricao: "OLEOS E GORDURAS COMESTIVEIS DE OVINOS E CAPRINOS; PRODUCAO DE",
  },
  {
    cnae: "1011203",
    descricao: "COUROS E PELES DE CAPRINOS; SECAGEM E SALGA DE",
  },
  {
    cnae: "1011203",
    descricao:
      "PRODUTOS DIVERSOS DE CARNE DE OVINOS E CAPRINOS; PRODUCAO DE QUANDO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1011203",
    descricao: "COUROS E PELES DE OVINOS E CAPRINOS, SEM CURTIR; PRODUCAO DE",
  },
  {
    cnae: "1011203",
    descricao:
      "EMBUTIDOS DIVERSOS DE OVINOS OU CAPRINOS; PRODUCAO DE (QUANDO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1011203",
    descricao: "CARNE DE OVINO FRESCA (VERDE) OU REFRIGERADA; PRODUCAO DE",
  },
  { cnae: "1011203", descricao: "LA DE MATADOURO; PRODUCAO DE" },
  {
    cnae: "1011203",
    descricao: "COUROS VERDES (FRESCOS) DE OVINOS OU CAPRINOS; PRODUCAO DE",
  },
  { cnae: "1011204", descricao: "CARCACA DE BUFALINO; PRODUCAO DE" },
  {
    cnae: "1011204",
    descricao:
      "MIUDOS E TRIPAS DE BUFALINOS (FRESCOS, REFRIGERADOS OU CONGELADOS); PRODUCAO DE",
  },
  {
    cnae: "1011204",
    descricao:
      "PRODUTOS DE SALSICHARIA DE CARNE DE BUFALINOS; PRODUCAO DE (QUANDO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1011204",
    descricao: "COUROS E PELES DE BUFALINOS, SEM CURTIR; PRODUCAO DE",
  },
  {
    cnae: "1011204",
    descricao:
      "CONSERVAS DE CARNE DE BUFALINOS; PRODUCAO DE (QUANDO INTEGRADA AO ABATE)",
  },
  { cnae: "1011204", descricao: "COURO DE BUFALINOS; SECAGEM E SALGA DE" },
  {
    cnae: "1011204",
    descricao: "OLEOS E GORDURAS COMESTIVEIS DE BUFALINOS; PRODUCAO DE",
  },
  { cnae: "1011204", descricao: "BUFALINOS; ABATE DE" },
  {
    cnae: "1011204",
    descricao: "COUROS E PELES FRESCOS (VERDES) DE BUFALINOS; PRODUCAO DE",
  },
  {
    cnae: "1011204",
    descricao:
      "PRODUTOS DIVERSOS DE SALAMARIA DE CARNE DE BUFALINOS; PRODUCAO DE (QUANDO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1011204",
    descricao:
      "PARTES COMESTIVEIS RESULTANTES DO ABATE DE BUFALINOS (SANGUE, ORELHA, TESTICULOS, ETC); PRODUCAO DE",
  },
  { cnae: "1011204", descricao: "RESES (BUFALINOS); ABATE DE" },
  {
    cnae: "1011204",
    descricao:
      "EMBUTIDOS DIVERSOS DE BUFALINOS; PRODUCAO DE (QUANDO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1011204",
    descricao: "CARNE VERDE (FRESCA) DE BUFALINO; PRODUCAO DE",
  },
  {
    cnae: "1011204",
    descricao: "COUROS E PELES DE BUFALINOS SECOS E/OU SALGADOS; PRODUCAO DE",
  },
  {
    cnae: "1011204",
    descricao:
      "PRODUTOS DERIVADOS DA CARNE DE BUFALINOS; PRODUCAO DE (QUANDO ASSOCIADA AO ABATE)",
  },
  {
    cnae: "1011205",
    descricao:
      "MATADOUROS MUNICIPAIS OU PARTICULARES - ABATE DE EQUINOS, ASININOS E MUARES SOB CONTRATO",
  },
  {
    cnae: "1011205",
    descricao:
      "MATADOUROS MUNICIPAIS OU PARTICULARES - ABATE DE BOVINOS SOB CONTRATO",
  },
  {
    cnae: "1011205",
    descricao:
      "MATADOURO MUNICIPAIS OU PARTICULARES - ABATE DE CAPRINOS OU DE OVINOS SOB CONTRATO",
  },
  {
    cnae: "1011205",
    descricao:
      "MATADOURO MUNICIPAIS OU PARTICULARES - ABATE DE RESES SOB CONTRATO (EXCETO O ABATE DE SUINOS)",
  },
  {
    cnae: "10121",
    descricao: "ABATE DE SUINOS, AVES E OUTROS PEQUENOS ANIMAIS",
  },
  {
    cnae: "1012101",
    descricao: "HAMBURGUER DE CARNE DE FRANGO OU DE OUTRAS AVES; PRODUCAO DE",
  },
  {
    cnae: "1012101",
    descricao: "CARNE DE PERU CONGELADA (INCLUSIVE EM PEDACOS); PRODUCAO DE",
  },
  {
    cnae: "1012101",
    descricao:
      "EMBUTIDOS DE AVES (SALSICHA, LINGUICA, PRESUNTO, ETC); PRODUCAO DE",
  },
  {
    cnae: "1012101",
    descricao:
      "MIUDOS DE AVES (CORACAO, FIGADO, MOELA, TRIPA, ETC), FRESCOS, REFRIGERADOS, CONGELADOS; PRODUCAO DE",
  },
  {
    cnae: "1012101",
    descricao: "CARNE DE AVES (REFRIGERADA, CONGELADA); PRODUCAO DE",
  },
  {
    cnae: "1012101",
    descricao:
      "AVES ABATIDAS (FRESCAS, REFRIGERADAS OU CONGELADAS); PRODUCAO DE",
  },
  {
    cnae: "1012101",
    descricao: "PENAS DE AVES E OUTROS SUBPRODUTOS DO ABATE; OBTENCAO DE",
  },
  { cnae: "1012101", descricao: "FRANGOS (EXCETO EM AVIARIOS); ABATE DE" },
  {
    cnae: "1012101",
    descricao: "CARNE DE PERU REFRIGERADA (INCLUSIVE EM PEDACOS); PRODUCAO DE",
  },
  { cnae: "1012101", descricao: "AVES; ABATE DE (EXCETO EM AVIARIOS)" },
  {
    cnae: "1012101",
    descricao:
      "DESPOJOS EM BRUTO DO ABATE DE AVES (GLANDULAS, OSSOS, PELES, PENAS, PLUMAS, ETC.); OBTENCAO DE",
  },
  {
    cnae: "1012101",
    descricao:
      "PRODUTOS DIVERSOS A BASE DE CARNE DE AVES (EXCETO PRATOS PRONTOS CONGELADOS); PRODUCAO DE",
  },
  { cnae: "1012101", descricao: "PATES E PASTAS DE AVES; PRODUCAO DE" },
  {
    cnae: "1012101",
    descricao:
      "PREPARACOES DIVERSAS A BASE DE CARNE DE AVES (EXCETO PRATOS PRONTOS CONGELADOS); PRODUCAO DE",
  },
  {
    cnae: "1012102",
    descricao:
      "PRODUTOS DE CARNES DE COELHOS (LEBRES) E OUTROS PEQUENOS ANIMAIS; PRODUCAO DE",
  },
  {
    cnae: "1012102",
    descricao:
      "DESPOJOS EM BRUTO DO ABATE DE COELHOS E OUTROS PEQUENOS ANIMAIS (PELOS, GLANDULAS, OSSOS, ETC.); PRODUCAO DE",
  },
  { cnae: "1012102", descricao: "RA, ABATE DE" },
  {
    cnae: "1012102",
    descricao:
      "PREPARACAO DE SUBPRODUTOS DE COELHOS (LEBRES) E OUTROS PEQUENOS ANIMAIS; PRODUCAO DE",
  },
  {
    cnae: "1012102",
    descricao:
      "MIUDOS E TRIPAS DE COELHOS OU OUTROS PEQUENOS ANIMAIS (FRESCOS, REFRIGERADOS OU CONGELADOS); PRODUCAO DE",
  },
  { cnae: "2051700", descricao: "ATRAZINA, FABRICACAO DE" },
  {
    cnae: "1012102",
    descricao:
      "CARNE DE COELHOS (LEBRES) OU DE OUTROS PEQUENOS ANIMAIS, FRESCA, REFRIGERADA OU CONGELADAS; PRODUCAO DE",
  },
  {
    cnae: "1012102",
    descricao: "COELHOS (LEBRES) E OUTROS PEQUENOS ANIMAIS; ABATE DE",
  },
  {
    cnae: "1012103",
    descricao: "CARNE DE SUINO (PORCO) CONGELADA; PRODUCAO DE",
  },
  { cnae: "1012103", descricao: "RESES (SUINOS) (PORCOS); ABATE DE" },
  {
    cnae: "1012103",
    descricao: "DERIVADOS DIVERSOS DO ABATE DE SUINOS (PORCOS); PRODUCAO DE",
  },
  {
    cnae: "1012103",
    descricao:
      "TOUCINHO DE SUINOS FRESCO, REFRIGERADO OU CONGELADO; PRODUCAO DE",
  },
  {
    cnae: "1012103",
    descricao:
      "PARTES NAO COMESTIVEIS RESULTANTES DO ABATE DE SUINOS (PORCOS); PRODUCAO DE",
  },
  {
    cnae: "1012103",
    descricao:
      "PRODUTOS DE SALSICHARIA DE SUINOS (PORCOS); PRODUCAO DE, QUANDO INTEGRADA AO ABATE",
  },
  { cnae: "1012103", descricao: "SUINOS (PORCOS); ABATE DE" },
  { cnae: "1012103", descricao: "RESES (PORCOS); ABATE DE" },
  {
    cnae: "1012103",
    descricao:
      "TOUCINHO DE SUINOS DEFUMADO OU SALGADO; PRODUCAO DE, QUANDO INTEGRADA AO ABATE",
  },
  {
    cnae: "1012103",
    descricao:
      "FARINHAS, POS OU PELLETS COMESTIVEIS DE CARNE OU MIUDEZAS DE SUINOS (PORCOS); PRODUCAO DE, QUANDO  INTEGRADA AO ABATE",
  },
  { cnae: "1012103", descricao: "BANHA DE PORCO EM RAMA; PRODUCAO DE" },
  {
    cnae: "1012103",
    descricao:
      "PREPARADOS DIVERSOS A BASE DE CARNE DE SUINO (PORCO), EXCETO PRATOS PRONTOS CONGELADOS; PRODUCAO DE, QUANDO INTEGRADA AO ABATE",
  },
  {
    cnae: "1012103",
    descricao:
      "CARNE DE SUINO SALGADA OU DEFUMADA; PRODUCAO DE, QUANDO INTEGRADA AO ABATE",
  },
  {
    cnae: "1012103",
    descricao:
      "CONSERVAS DE CARNE DE SUINOS; PRODUCAO DE, QUANDO INTEGRADA AO ABATE (PORCOS)",
  },
  {
    cnae: "1012103",
    descricao:
      "EMBUTIDOS DIVERSOS DE SUINOS (PORCOS); PRODUCAO DE, QUANDO INTEGRADA AO ABATE",
  },
  {
    cnae: "1012103",
    descricao: "OLEOS E GORDURAS COMESTIVEIS DE SUINOS; PRODUCAO DE",
  },
  { cnae: "1012103", descricao: "BANHA DE PORCO REFINADA; PRODUCAO DE" },
  {
    cnae: "1012103",
    descricao:
      "PREPARACOES E CONSERVAS DE SUINOS (PORCOS) (HAMBURGUERES, QUIBES, ALMONDEGAS, MEDALHAO); PRODUCAO DE, QUANDO INTEGRADA AO ABATE",
  },
  {
    cnae: "1012103",
    descricao:
      "CARNE DE SUINO (PORCO) FRESCA (VERDE) OU REFRIGERADA; PRODUCAO DE",
  },
  {
    cnae: "1012103",
    descricao:
      "PARTES COMESTIVEIS RESULTANTES DO ABATE DE SUINOS (PORCOS) (SANGUE, ORELHA, TESTICULOS, ETC); PRODUCAO DE",
  },
  { cnae: "1012103", descricao: "COUROS E PELES DE SUINOS (PORCOS); SALGA DE" },
  { cnae: "1012103", descricao: "CARCACA DE SUINO (PORCO); PRODUCAO DE" },
  {
    cnae: "1012103",
    descricao:
      "DESPOJOS EM BRUTO, NAO COMESTIVEIS, DO ABATE DE SUINOS (GLANDULAS, OSSOS, ETC.); PRODUCAO DE",
  },
  {
    cnae: "1012103",
    descricao:
      "PRESUNTO (CRU, COZIDO, DEFUMADO, ETC) DE SUINOS (PORCOS); PRODUCAO DE, QUANDO INTEGRADA AO ABATE",
  },
  {
    cnae: "1012103",
    descricao:
      "PRODUTOS DE SALAMARIA DE CARNE DE SUINOS (PORCOS) (SALAME, SALSICHA, LINGUICA, MORTADELA, PRESUNTO, PATE E SEMELHANTE); PRODUCAO DE, QUANDO INTEGRADA AO ABATE",
  },
  { cnae: "1012103", descricao: "SUINO (PORCO) ABATIDO; PRODUCAO DE" },
  { cnae: "1031700", descricao: "FARINHA DE COCO; FABRICACAO DE" },
  {
    cnae: "1012103",
    descricao:
      "HAMBURGUER DE CARNE DE SUINO (PORCO) CRU E CONGELADO ; PRODUCAO DE, QUANDO INTEGRADA AO ABATE",
  },
  {
    cnae: "1012103",
    descricao:
      "MIUDEZAS DE SUINOS (PORCOS) EM BRUTO NAO COMESTIVEIS; PRODUCAO DE",
  },
  {
    cnae: "1012103",
    descricao:
      "PRODUTOS DIVERSOS DE SALAMARIA DE SUINOS (PORCOS); PRODUCAO DE, QUANDO INTEGRADA AO ABATE",
  },
  {
    cnae: "1012103",
    descricao:
      "MIUDOS E TRIPAS DE SUINOS (FRESCOS, REFRIGERADOS OU CONGELADOS); PRODUCAO DE",
  },
  {
    cnae: "1012104",
    descricao: "MATADOURO - ABATE DE SUINOS (PORCOS) SOB CONTRATO",
  },
  {
    cnae: "1012104",
    descricao: "MATADOURO MUNICIPAIS - ABATE DE SUINOS (PORCOS) SOB CONTRATO",
  },
  { cnae: "10139", descricao: "FABRICACAO DE PRODUTOS DE CARNE" },
  {
    cnae: "1013901",
    descricao:
      "LINGUICAS, SALSICHAS E OUTROS PRODUTOS EMBUTIDOS, DE SALAMARIA E DE SALSICHARIA; PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013901",
    descricao:
      "PREPARACOES E CONSERVAS DE BOVINOS (HAMBURGUERES, QUIBES, ALMONDEGAS, ETC); PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013901",
    descricao:
      "EMBUTIDOS DIVERSOS DE SUINOS (PORCOS); PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013901",
    descricao:
      "HAMBURGUER DE SUINO (PORCO); PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  { cnae: "1013901", descricao: "CHARQUE; PRODUCAO DE" },
  {
    cnae: "1013901",
    descricao: "CARNE DE SUINO (PORCO) SECA, SALGADA OU DEFUMADA; PRODUCAO DE",
  },
  {
    cnae: "1013901",
    descricao: "CARNE DE OVINO SECA, SALGADA OU DEFUMADA; PRODUCAO DE",
  },
  {
    cnae: "1013901",
    descricao:
      "EMBUTIDOS DIVERSOS DE BOVINOS; PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013901",
    descricao:
      "CONSERVAS DE CARNE DE BOVINOS; PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013901",
    descricao:
      "EMBUTIDOS DIVERSOS DE BUFALINOS; PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013901",
    descricao:
      "CONSERVAS DE CARNE (SECA, SALGADA, DEFUMADA OU CONSERVADA); PREPARACAO DE",
  },
  { cnae: "1013901", descricao: "CARNE DE SOL; PRODUCAO DE" },
  {
    cnae: "1013901",
    descricao:
      "EMBUTIDOS DIVERSOS DE EQUINOS; PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013901",
    descricao: "CARNE DE EQUINO SECA, SALGADA OU DEFUMADA; PRODUCAO DE",
  },
  {
    cnae: "1013901",
    descricao: "PRESUNTO DE AVES; PRODUCAO DE, QUANDO NAO INTEGRADA AO ABATE",
  },
  { cnae: "1013901", descricao: "CARNE BOVINA DEFUMADA; PRODUCAO DE" },
  {
    cnae: "1013901",
    descricao:
      "CONSERVAS DE CARNE DE OVINOS E CAPRINOS; PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013901",
    descricao:
      "EMBUTIDOS DIVERSOS DE OVINOS E CAPRINOS; PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  { cnae: "1013901", descricao: "CARNE BOVINA SECA; PRODUCAO DE" },
  {
    cnae: "1013901",
    descricao:
      "PREPARACOES E CONSERVAS DE SUINOS (PORCOS) (HAMBURGUERES, QUIBES, ALMONDEGAS, ETC); PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013901",
    descricao:
      "CONSERVAS DE CARNE DE SUINOS(PORCOS); PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013901",
    descricao: "HAMBURGUER BOVINO; PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013901",
    descricao:
      "CONSERVAS DE CARNE DE EQUINOS; PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013901",
    descricao:
      "PREPARACAO DE CARNES DE COELHOS (LEBRES) E OUTROS PEQUENOS ANIMAIS; PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013901",
    descricao: "CARNE DE CAPRINO SECA, SALGADA OU DEFUMADA; PRODUCAO DE",
  },
  {
    cnae: "1013901",
    descricao:
      "PRODUTOS DIVERSOS DE CARNE (HAMBURGUERES, PATES, ALMONDEGAS, ETC.); PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013901",
    descricao:
      "CONSERVAS DE CARNE DE BUFALINOS; PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013902",
    descricao:
      "FARINHAS, POS OU PELLETS DE CARNE OU MIUDEZAS DE ANIMAIS; PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013902",
    descricao:
      "GORDURA ANIMAL, FARINHA DE CARNE, FARINHA DE OSSOS  (GRAXARIA), (QUANDO NAO INTEGRADA AO ABATE); PRODUCAO DE",
  },
  {
    cnae: "1013902",
    descricao:
      "MIUDOS E TRIPAS DE ANIMAIS (RESES) SECOS, SALGADOS OU DEFUMADOS; PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013902",
    descricao:
      "FARINHAS DE CARNE E DE DESPOJOS DA CARNE DE ANIMAIS; PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "1013902",
    descricao:
      "TOUCINHO DE SUINOS (PORCOS) DEFUMADO OU SALGADO; PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  { cnae: "1032599", descricao: "BATATA CONGELADA; PRODUCAO DE" },
  { cnae: "2051700", descricao: "PICLORAM, FABRICACAO DE" },
  {
    cnae: "1013902",
    descricao:
      "SUBPRODUTOS DE COELHOS (LEBRES) E OUTROS PEQUENOS ANIMAIS PREPARADOS OU CONSERVADOS; PRODUCAO DE (QUANDO NAO INTEGRADA AO ABATE)",
  },
  {
    cnae: "10201",
    descricao: "PRESERVACAO DO PESCADO E FABRICACAO DE PRODUTOS DO PESCADO",
  },
  {
    cnae: "1020101",
    descricao: "FILES E OUTRAS CARNES DE PEIXES, REFRIGERADOS OU CONGELADOS",
  },
  {
    cnae: "1020101",
    descricao: "PRODUTOS DERIVADOS DE PEIXES, CONGELADOS; FABRICACAO DE",
  },
  {
    cnae: "1020101",
    descricao: "CARNE DE SIRI OU CARANGUEJO (CONGELADA); PREPARACAO DE",
  },
  { cnae: "1020101", descricao: "RESIDUOS DO PESCADO; PRODUCAO DE" },
  { cnae: "1020101", descricao: "OVAS DE PEIXES; PREPARACAO DE" },
  { cnae: "1020101", descricao: "PATAS DE CARANGUEJO; PRODUCAO DE" },
  {
    cnae: "1020101",
    descricao:
      "CRUSTACEOS E MOLUSCOS REFRIGERADOS OU CONGELADOS; PREPARACAO DE",
  },
  {
    cnae: "1020101",
    descricao: "POLVO OU LULA RESFRIADOS OU CONGELADOS; PREPARACAO DE",
  },
  {
    cnae: "1020101",
    descricao: "PRESERVACAO DO PESCADO (PEIXES, CRUSTACEOS E MOLUSCOS)",
  },
  {
    cnae: "1020101",
    descricao: "LAGOSTA RESFRIADA OU CONGELADA; PREPARACAO DE",
  },
  { cnae: "1020101", descricao: "PEIXES CONGELADOS; PREPARACAO DE" },
  { cnae: "1020101", descricao: "SARDINHA CONGELADA; PREPARACAO DE" },
  {
    cnae: "1020101",
    descricao: "CAMARAO RESFRIADO OU CONGELADO; PREPARACAO DE",
  },
  {
    cnae: "1020102",
    descricao:
      "PEIXES ENLATADOS EM CONSERVAS (OLEO, SALMOURA, MOLHOS, ETC); FABRICACAO DE",
  },
  {
    cnae: "1020102",
    descricao: "SARDINHA ANCHOVADA (ALICHE), EM CONSERVA; FABRICACAO DE",
  },
  {
    cnae: "1020102",
    descricao:
      "FARINHAS DE PEIXE COMESTIVEIS OU NAO COMESTIVEIS; FABRICACAO DE",
  },
  {
    cnae: "1020102",
    descricao: "POLPAS DE PEIXE, INCLUSIVE SURIMI; FABRICACAO DE",
  },
  {
    cnae: "1020102",
    descricao:
      "SARDINHA ENLATADA EM CONSERVA (OLEO, SALMOURA, MOLHOS, ETC); FABRICACAO DE",
  },
  {
    cnae: "1020102",
    descricao: "BARBATANAS DE TUBARAO (SECAS); PREPARACAO DE",
  },
  {
    cnae: "1020102",
    descricao:
      "CRUSTACEOS E MOLUSCOS SECOS, SALGADOS OU DEFUMADOS; PREPARACAO DE",
  },
  { cnae: "1020102", descricao: "PEIXES SECOS OU SALGADOS; PREPARACAO DE" },
  { cnae: "1020102", descricao: "SARDINHA PRENSADA; FABRICACAO DE" },
  {
    cnae: "1020102",
    descricao:
      "POLPA DE CRUSTACEOS (INCLUSIVE PRENSADOS COMO SURIMIS); FABRICACAO DE",
  },
  { cnae: "1020102", descricao: "HAMBURGUER DE PEIXE; FABRICACAO DE" },
  {
    cnae: "1020102",
    descricao:
      "PREPARACOES DIVERSAS DE PEIXES (EXCETO PRATOS PRONTOS CONGELADOS); FABRICACAO DE",
  },
  { cnae: "1020102", descricao: "ALGAS MARINHAS; PREPARACAO DE" },
  { cnae: "1020102", descricao: "CAMARAO SECO OU SALGADO; PREPARACAO DE" },
  {
    cnae: "1020102",
    descricao: "CRUSTACEOS E MOLUSCOS ENLATADOS; FABRICACAO DE",
  },
  { cnae: "1020102", descricao: "FARINHA DE ALGAS MARINHAS; FABRICACAO DE" },
  { cnae: "1020102", descricao: "PASTAS E PATES DE PEIXE; FABRICACAO DE" },
  {
    cnae: "1020102",
    descricao:
      "PREPARACOES DE CRUSTACEOS E MOLUSCOS (EXCETO PRATOS PRONTOS CONGELADOS); FABRICACAO DE",
  },
  { cnae: "1020102", descricao: "FARINHA DE OSTRA; FABRICACAO DE" },
  { cnae: "10317", descricao: "FABRICACAO DE CONSERVAS DE FRUTAS" },
  { cnae: "1031700", descricao: "GELEIA DE UVA; PRODUCAO DE" },
  { cnae: "1031700", descricao: "DOCE DE AMEIXA; PRODUCAO DE" },
  { cnae: "1031700", descricao: "CASCAS DE CITRICOS; OBTENCAO DE" },
  { cnae: "1031700", descricao: "GELEIA DE AMEIXA; PRODUCAO DE" },
  { cnae: "1031700", descricao: "DOCE DE FRUTAS EM CALDA; PRODUCAO DE" },
  { cnae: "1031700", descricao: "GELEIA DE FRUTAS; PRODUCAO DE" },
  { cnae: "1031700", descricao: "DOCE DE MARMELO; PRODUCAO DE" },
  { cnae: "1031700", descricao: "DOCES DE CAJU EM CALDA; PRODUCAO DE" },
  {
    cnae: "1031700",
    descricao: "COCO RALADO (FARINHA DE COCO); FABRICACAO DE",
  },
  { cnae: "1031700", descricao: "POLPA DE FRUTAS; PRODUCAO DE" },
  { cnae: "1031700", descricao: "GELEIA DE BANANA; PRODUCAO DE" },
  { cnae: "1031700", descricao: "DOCE DE AMENDOIM; FABRICACAO DE" },
  { cnae: "1031700", descricao: "DOCE DE FIGO; PRODUCAO DE" },
  { cnae: "1031700", descricao: "DOCE DE GOIABA EM PASTA; FABRICACAO DE" },
  {
    cnae: "1031700",
    descricao: "CONCENTRADO DE TOMATE: EXTRATO DE TOMATE (MASSA); PRODUCAO DE",
  },
  { cnae: "1031700", descricao: "FRUTAS PREPARADAS; PRODUCAO DE" },
  { cnae: "1031700", descricao: "COCADA; FABRICACAO DE" },
  { cnae: "1031700", descricao: "AMENDOIM DESCASCADO; FABRICACAO DE" },
  { cnae: "1031700", descricao: "DOCE DE BANANA EM PASTA; FABRICACAO DE" },
  { cnae: "1031700", descricao: "DOCE DE FRUTAS; PRODUCAO DE" },
  { cnae: "1031700", descricao: "PASTA DE AMENDOIM; FABRICACAO DE" },
  {
    cnae: "1031700",
    descricao: "FRUTAS SECAS, DESIDRATADAS MAS NAO CRISTALIZADAS; PRODUCAO DE",
  },
  { cnae: "1031700", descricao: "GELEIA DE GOIABA; PRODUCAO DE" },
  { cnae: "1031700", descricao: "AMENDOAS DE FRUTOS; PRODUCAO DE" },
  { cnae: "1031700", descricao: "GELEIA DE MORANGO; PRODUCAO DE" },
  { cnae: "1031700", descricao: "DOCE DE ABACAXI; PRODUCAO DE" },
  { cnae: "1031700", descricao: "LEITE DE COCO; PRODUCAO DE" },
  {
    cnae: "1031700",
    descricao: "RESIDUOS E SUBPRODUTOS DE CONSERVACAO DE FRUTAS; PRODUCAO DE",
  },
  { cnae: "1031700", descricao: "CASTANHA-DE-CAJU; BENEFICIAMENTO DE" },
  { cnae: "1031700", descricao: "DOCE DE COCO; FABRICACAO DE" },
  { cnae: "1031700", descricao: "CASTANHA-DO-PARA; BENEFICIAMENTO DE" },
  {
    cnae: "1031700",
    descricao: "AMENDOIM E CASTANHA DE CAJU TORRADOS E SALGADOS; PRODUCAO DE",
  },
  { cnae: "1031700", descricao: "MARMELADA; PRODUCAO DE" },
  {
    cnae: "1031700",
    descricao: "COMPOTAS DE FRUTAS (INCLUSIVE CONSERVAS); PRODUCAO DE",
  },
  { cnae: "1031700", descricao: "UVAS SECAS; PRODUCAO DE" },
  { cnae: "1031700", descricao: "GELEIAS DE FRUTAS; FABRICACAO DE" },
  { cnae: "1031700", descricao: "GELEIA DE AMORA; PRODUCAO DE" },
  { cnae: "1031700", descricao: "GELEIA DE LARANJA; PRODUCAO DE" },
  { cnae: "1031700", descricao: "GOIABADA; PRODUCAO DE" },
  { cnae: "1031700", descricao: "POLPAS INDUSTRIAIS DE TOMATE; PRODUCAO DE" },
  {
    cnae: "1031700",
    descricao:
      "CONCENTRADO DE TOMATE: PURES DE TOMATE (POLPA PRONTA PARA CONSUMO); PRODUCAO DE",
  },
  { cnae: "1031700", descricao: "TOMATE COZIDO; PRODUCAO DE" },
  { cnae: "1031700", descricao: "FRUTOS COZIDOS; PRODUCAO DE" },
  { cnae: "1031700", descricao: "GELEIA DE MARMELO; PRODUCAO DE" },
  { cnae: "1031700", descricao: "DOCES DE PESSEGO EM CALDA; PRODUCAO DE" },
  {
    cnae: "1031700",
    descricao: "DOCES EM MASSA OU PASTA DE FRUTAS DIVERSAS; FABRICACAO DE",
  },
  {
    cnae: "1031700",
    descricao:
      "TOMATES INTEIROS OU EM PEDACOS, COZIDOS CONGELADOS; PRODUCAO DE",
  },
  {
    cnae: "10325",
    descricao: "FABRICACAO DE CONSERVAS DE LEGUMES E OUTROS VEGETAIS",
  },
  { cnae: "1032501", descricao: "PALMITO EM CONSERVAS; PRODUCAO DE" },
  { cnae: "1032599", descricao: "VEGETAIS EM CONSERVAS; PRODUCAO DE" },
  {
    cnae: "1032599",
    descricao: "BATATA FRITA E APERITIVOS A BASE DE BATATA; PRODUCAO DE",
  },
  { cnae: "1032599", descricao: "FARINHA E SEMOLA DE BATATA; PRODUCAO DE" },
  { cnae: "1032599", descricao: "PICLES; PRODUCAO DE" },
  { cnae: "1032599", descricao: "CENOURAS EM CONSERVAS; PRODUCAO DE" },
  { cnae: "1032599", descricao: "PEPINOS EM CONSERVAS; PRODUCAO DE" },
  { cnae: "1032599", descricao: "LEGUMES E VEGETAIS; PROCESSAMENTO DE" },
  { cnae: "1032599", descricao: "AZEITONAS EM CONSERVAS; PRODUCAO DE" },
  {
    cnae: "1032599",
    descricao: "PRODUTOS HORTICOLAS SECOS, TRITURADOS OU EM PO; FABRICACAO DE",
  },
  { cnae: "1032599", descricao: "GELEIAS VEGETAIS; PRODUCAO DE" },
  {
    cnae: "1032599",
    descricao:
      "RESIDUOS E SUBPRODUTOS DE LEGUMES E OUTROS VEGETAIS- EXCETO FRUTAS - (FOLHAS, CASCAS, RAMAS, RASPAS, ETC.) MESMO UTILIZADOS PARA ALIMENTACAO ANIMAL",
  },
  {
    cnae: "1032599",
    descricao: "VEGETAIS CONGELADOS, SUPERGELADOS; PRODUCAO DE",
  },
  {
    cnae: "1032599",
    descricao: "VEGETAIS DESIDRATADOS E LIOFILIZADOS; PRODUCAO DE",
  },
  { cnae: "1032599", descricao: "BATATA PALHA; FABRICACAO DE" },
  { cnae: "1032599", descricao: "VEGETAIS MISTOS; PREPARACAO DE" },
  { cnae: "1032599", descricao: "MILHO EM CONSERVAS; PRODUCAO DE" },
  { cnae: "1032599", descricao: "ERVILHAS EM CONSERVAS; PRODUCAO DE" },
  {
    cnae: "1032599",
    descricao: "BATATA PREPARADA E CONSERVADA; FABRICACAO DE",
  },
  { cnae: "1032599", descricao: "ASPARGOS EM CONSERVAS; PRODUCAO DE" },
  { cnae: "1032599", descricao: "PIMENTOES EM CONSERVAS; PRODUCAO DE" },
  {
    cnae: "1032599",
    descricao:
      "PRODUTOS HORTICOLAS CONSERVADOS, CONGELADOS (EXCETO PRATOS PRONTOS CONGELADOS); PRODUCAO DE",
  },
  { cnae: "1032599", descricao: "CEBOLAS EM CONSERVAS; PRODUCAO DE" },
  {
    cnae: "1032599",
    descricao: "LEGUMES E VEGETAIS EM CONSERVAS; PRODUCAO DE",
  },
  {
    cnae: "10333",
    descricao: "FABRICACAO DE SUCOS DE FRUTAS, HORTALICAS E LEGUMES",
  },
  { cnae: "1033301", descricao: "SUCO CONCENTRADO DE GOIABA; PRODUCAO DE" },
  {
    cnae: "1033301",
    descricao: "SUCO CONCENTRADO DE FRUTAS DIVERSAS; PRODUCAO DE",
  },
  { cnae: "1033301", descricao: "SUCO CONCENTRADO DE ABACAXI; PRODUCAO DE" },
  {
    cnae: "1033301",
    descricao: "SUCOS CONCENTRADOS DE OUTROS VEGETAIS; PRODUCAO DE",
  },
  { cnae: "1033301", descricao: "SUCO CONCENTRADO DE MANGA; PRODUCAO DE" },
  { cnae: "1033301", descricao: "SUCOS CONCENTRADOS DE LIMAO; PRODUCAO DE" },
  { cnae: "1033301", descricao: "SUCOS CONCENTRADOS DE POMELOS; PRODUCAO DE" },
  { cnae: "1033301", descricao: "SUCO CONCENTRADO DE ACEROLA; PRODUCAO DE" },
  { cnae: "1033301", descricao: "SUCO CONCENTRADO DE MARACUJA; PRODUCAO DE" },
  {
    cnae: "1033301",
    descricao: "SUCOS CONCENTRADOS DE TANGERINA; PRODUCAO DE",
  },
  { cnae: "1033301", descricao: "SUCO CONCENTRADO DE LARANJA; PRODUCAO DE" },
  {
    cnae: "1033301",
    descricao: "SUCOS CONCENTRADOS DE LEGUMES E OUTROS VEGETAIS; PRODUCAO DE",
  },
  { cnae: "1033301", descricao: "SUCO CONCENTRADO DE UVA; PRODUCAO DE" },
  { cnae: "1033301", descricao: "SUCO CONCENTRADO DE PESSEGO; PRODUCAO DE" },
  { cnae: "1033301", descricao: "SUCO CONCENTRADO DE CAJU; PRODUCAO DE" },
  { cnae: "1033302", descricao: "SUCOS INTEGRAIS DE MARACUJA; PRODUCAO DE" },
  { cnae: "1033302", descricao: "SUCOS INTEGRAIS DE UVA; PRODUCAO DE" },
  {
    cnae: "1033302",
    descricao: "SUCO DE LARANJA, PRONTO PARA CONSUMO; PRODUCAO DE",
  },
  {
    cnae: "1033302",
    descricao: "SUCO DE GOIABA, PRONTO PARA CONSUMO; PRODUCAO DE",
  },
  {
    cnae: "1033302",
    descricao: "AGUA DE COCO, PRONTA PARA CONSUMO; PRODUCAO DE",
  },
  { cnae: "1033302", descricao: "REFRESCO DE GOIABA; PRODUCAO DE" },
  { cnae: "1033302", descricao: "SUCOS INTEGRAIS DE CAJU; PRODUCAO DE" },
  { cnae: "1033302", descricao: "SUCOS TROPICAIS DE FRUTAS; PRODUCAO DE" },
  { cnae: "1033302", descricao: "REFRESCO DE ABACAXI; PRODUCAO DE" },
  { cnae: "1033302", descricao: "SUCOS COMPOSTOS (MISTOS); PRODUCAO DE" },
  { cnae: "1033302", descricao: "REFRESCO DE MARACUJA; PRODUCAO DE" },
  {
    cnae: "1033302",
    descricao: "SUCO DE MANGA, PRONTO PARA CONSUMO; PRODUCAO DE",
  },
  { cnae: "2110600", descricao: "TINIDAZOL; FABRICACAO DE" },
  {
    cnae: "1033302",
    descricao: "SUCO DE CAJU, PRONTO PARA CONSUMO; PRODUCAO DE",
  },
  { cnae: "1033302", descricao: "REFRESCO DE LARANJA; PRODUCAO DE" },
  { cnae: "1033302", descricao: "REFRESCO DE UVA; PRODUCAO DE" },
  { cnae: "1033302", descricao: "MOSTO DE UVA NAO FERMENTADO; FABRICACAO DE" },
  {
    cnae: "1033302",
    descricao: "SUCO DE ABACAXI, PRONTO PARA CONSUMO; PRODUCAO DE",
  },
  {
    cnae: "1033302",
    descricao: "SUCOS DE FRUTAS PRONTOS PARA CONSUMO; PRODUCAO DE",
  },
  {
    cnae: "1033302",
    descricao: "SUCO DE UVA, PRONTO PARA CONSUMO; PRODUCAO DE",
  },
  {
    cnae: "1033302",
    descricao: "SUCO DE MARACUJA, PRONTO PARA CONSUMO; PRODUCAO DE",
  },
  {
    cnae: "1033302",
    descricao: "SUCO DE PESSEGO, PRONTO PARA CONSUMO; PRODUCAO DE",
  },
  { cnae: "1033302", descricao: "NECTARES DE FRUTAS; FABRICACAO DE" },
  { cnae: "1033302", descricao: "SUCO DE TOMATE; PRODUCAO DE" },
  { cnae: "1033302", descricao: "REFRESCO DE CAJU; PRODUCAO DE" },
  {
    cnae: "1033302",
    descricao: "REFRESCO DE FRUTAS OU DE OUTROS VEGETAIS; PRODUCAO DE",
  },
  { cnae: "1033302", descricao: "SUCOS INTEGRAIS DE FRUTAS; PRODUCAO DE" },
  { cnae: "1033302", descricao: "BEBIDAS A BASE DE SOJA FABRICACAO DE" },
  {
    cnae: "10414",
    descricao: "FABRICACAO DE OLEOS VEGETAIS EM BRUTO, EXCETO OLEO DE MILHO",
  },
  { cnae: "1041400", descricao: "OLEO DE LINHACA; PRODUCAO DE" },
  { cnae: "1041400", descricao: "OLEO DE COLZA EM BRUTO; PRODUCAO DE" },
  {
    cnae: "1041400",
    descricao: "FARINHAS DE SEMENTES OLEAGINOSAS; PRODUCAO DE",
  },
  { cnae: "1041400", descricao: "OLEOS VEGETAIS FIXOS, EM BRUTO; PRODUCAO DE" },
  { cnae: "1041400", descricao: "OLEO DE OITICICA; PRODUCAO DE" },
  { cnae: "1041400", descricao: "OLEO DE MAMONA EM BRUTO; FABRICACAO DE" },
  { cnae: "1041400", descricao: "OLEO DE TUNGUE EM BRUTO; PRODUCAO DE" },
  {
    cnae: "1041400",
    descricao:
      "BAGACOS E OUTROS RESIDUOS SOLIDOS DA EXTRACAO DE OLEOS VEGETAIS; OBTENCAO DE",
  },
  {
    cnae: "1041400",
    descricao: "OLEO DE SEMENTES OLEAGINOSAS (EXCETO MILHO); EXTRACAO DE",
  },
  {
    cnae: "1041400",
    descricao: "OLEOS VEGETAIS (EXCETO DE MILHO); EXTRACAO DE",
  },
  { cnae: "1041400", descricao: "BORRA DE OLEOS" },
  { cnae: "1041400", descricao: "OLEO DE GERGELIM EM BRUTO; PRODUCAO DE" },
  {
    cnae: "1041400",
    descricao: "OLEOS VEGETAIS EM BRUTO, MESMO DEGOMADOS; PRODUCAO DE",
  },
  { cnae: "1041400", descricao: "OLEO DE JOJOBA EM BRUTO; PRODUCAO DE" },
  {
    cnae: "1041400",
    descricao:
      "OLEOS VEGETAIS EM BRUTO, COMESTIVEIS OU NAO (EXCETO DE MILHO); PRODUCAO DE",
  },
  { cnae: "1041400", descricao: "FARINHA DE SOJA; FABRICACAO DE" },
  { cnae: "1041400", descricao: "OLEO DE CANOLA EM BRUTO; PRODUCAO DE" },
  { cnae: "1041400", descricao: "OLEO DE GIRASSOL EM BRUTO; PRODUCAO DE" },
  { cnae: "1041400", descricao: "OLEO DE ARROZ EM BRUTO; PRODUCAO DE" },
  { cnae: "1041400", descricao: "OLEOS DE AMENDOAS; PRODUCAO DE" },
  { cnae: "1041400", descricao: "OLEO DE AZEITONA EM BRUTO; PRODUCAO DE" },
  { cnae: "1041400", descricao: "OLEO DE DORMIDEIRA; PRODUCAO DE" },
  { cnae: "1041400", descricao: "OLEO DE ALGODAO EM BRUTO; FABRICACAO DE" },
  {
    cnae: "1041400",
    descricao: "OLEO DE LICURI, URICURI OU OURICURI; PRODUCAO DE",
  },
  {
    cnae: "1041400",
    descricao: "OLEO DE CASCA DE CASTANHA DE CAJU EM BRUTO (LCC); PRODUCAO DE",
  },
  { cnae: "1041400", descricao: "OLEO DE ANDIROBA; PRODUCAO DE" },
  {
    cnae: "1041400",
    descricao:
      "FARELOS DE SEMENTES OLEAGINOSAS, INCLUSIVE PELETIZADOS (PELOTIZADOS); PRODUCAO DE",
  },
  {
    cnae: "1041400",
    descricao:
      "SUBPRODUTOS RESIDUAIS DA PRODUCAO DE OLEOS (EXCETO MILHO); OBTENCAO DE",
  },
  { cnae: "1041400", descricao: "OLEO DE AMENDOIM EM BRUTO; PRODUCAO DE" },
  {
    cnae: "1041400",
    descricao: "FARINHA DE SOJA PROPRIA PARA ALIMENTACAO HUMANA; PRODUCAO DE",
  },
  {
    cnae: "1041400",
    descricao: "TORTAS E FARELOS (EXCETO DE CEREAIS); PRODUCAO DE",
  },
  {
    cnae: "1041400",
    descricao: "OLEO DE CAROCO DE ALGODAO EM BRUTO; PRODUCAO DE",
  },
  { cnae: "1041400", descricao: "FARELO DE SOJA PELETIZADO; PRODUCAO DE" },
  { cnae: "1041400", descricao: "LINTER DE ALGODAO; OBTENCAO DE" },
  { cnae: "2051700", descricao: "HEXAZINONA, FABRICACAO DE" },
  { cnae: "1041400", descricao: "OLEO DE CARTAMO EM BRUTO; PRODUCAO DE" },
  { cnae: "1041400", descricao: "OLEO DE BABACU EM BRUTO; PRODUCAO DE" },
  { cnae: "1041400", descricao: "OLEO DE COPAIBA; PRODUCAO DE" },
  { cnae: "1041400", descricao: "OLEO DE DENDE (PALMA) EM BRUTO; PRODUCAO DE" },
  { cnae: "1041400", descricao: "OLEO DE RICINO EM BRUTO; PRODUCAO DE" },
  {
    cnae: "1041400",
    descricao: "TORTAS DE SEMENTES OLEAGINOSAS (EXCETO CACAU); PRODUCAO DE",
  },
  { cnae: "1041400", descricao: "OLEO DE COCO, N.E., EM BRUTO; PRODUCAO DE" },
  { cnae: "1041400", descricao: "OLEO DE TUCUM; PRODUCAO DE" },
  { cnae: "1041400", descricao: "OLEO DE MOSTARDA; PRODUCAO DE" },
  { cnae: "1041400", descricao: "OLEO DE PALMISTE EM BRUTO; PRODUCAO DE" },
  {
    cnae: "1041400",
    descricao: "OLEO DE SOJA EM BRUTO, MESMO DEGOMADO; PRODUCAO DE",
  },
  {
    cnae: "1041400",
    descricao: "OLEO DE NABO SILVESTRE, EM BRUTO; PRODUCAO DE",
  },
  {
    cnae: "10422",
    descricao: "FABRICACAO DE OLEOS VEGETAIS REFINADOS, EXCETO OLEO DE MILHO",
  },
  {
    cnae: "1042200",
    descricao: "OLEOS VEGETAIS FIXOS, REFINADOS; PRODUCAO DE",
  },
  {
    cnae: "1042200",
    descricao: "OLEO DE CAROCO DE ALGODAO REFINADO; PRODUCAO DE",
  },
  { cnae: "1042200", descricao: "OLEO DE GERGELIM REFINADO; PRODUCAO DE" },
  { cnae: "1042200", descricao: "OLEO DE ARROZ REFINADO; PRODUCAO DE" },
  { cnae: "1042200", descricao: "OLEOS VEGETAIS NAO COMESTIVEIS; REFINO DE" },
  { cnae: "1042200", descricao: "CERAS DE ORIGEM VEGETAL; PRODUCAO DE" },
  { cnae: "1042200", descricao: "OLEO DE GIRASSOL REFINADO; PRODUCAO DE" },
  {
    cnae: "1042200",
    descricao: "OLEOS VEGETAIS REFINADOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  { cnae: "1042200", descricao: "OLEO DE TUNGUE REFINADO; PRODUCAO DE" },
  {
    cnae: "1042200",
    descricao: "OLEOS VEGETAIS COMESTIVEIS (EXCETO DE MILHO); REFINO DE",
  },
  {
    cnae: "1042200",
    descricao: "RESIDUOS PROVENIENTES DO TRATAMENTO DE CERAS VEGETAIS",
  },
  { cnae: "1042200", descricao: "OLEO DE OLIVA REFINADO; PRODUCAO DE" },
  { cnae: "1042200", descricao: "OLEOS VEGETAIS (EXCETO DE MILHO); REFINO DE" },
  {
    cnae: "1042200",
    descricao: "OLEOS VEGETAIS (EXCETO DE MILHO) HIDROGENACAO; SERVICO DE",
  },
  { cnae: "1042200", descricao: "OLEO DE MAMONA REFINADO; FABRICACAO DE" },
  { cnae: "1042200", descricao: "CERA DE CARNAUBA; FABRICACAO DE" },
  { cnae: "1042200", descricao: "OLEO DE COLZA REFINADO; PRODUCAO DE" },
  { cnae: "1042200", descricao: "CERAS VEGETAIS; FABRICACAO DE" },
  { cnae: "1042200", descricao: "OLEO DE DENDE (PALMA) REFINADO; PRODUCAO DE" },
  { cnae: "1042200", descricao: "OLEO DE COCO REFINADO; PRODUCAO DE" },
  { cnae: "1042200", descricao: "OLEO DE AZEITONA REFINADO; PRODUCAO DE" },
  { cnae: "1042200", descricao: "OLEOS VEGETAIS HIDROGENADOS; PRODUCAO DE" },
  {
    cnae: "1042200",
    descricao: "OLEOS VEGETAIS, N.E. (EXCETO DE MILHO), REFINADOS; PRODUCAO DE",
  },
  {
    cnae: "1042200",
    descricao: "OLEO DE NABO SILVESTRE REFINADO; PRODUCAO DE",
  },
  {
    cnae: "1042200",
    descricao: "CERA DE LICURI, URICURI OU OURICURI; FABRICACAO DE",
  },
  { cnae: "1042200", descricao: "OLEO DE CANOLA REFINADO; PRODUCAO DE" },
  { cnae: "1042200", descricao: "OLEO DE PALMISTE REFINADO; PRODUCAO DE" },
  { cnae: "1042200", descricao: "OLEO DE SOJA REFINADO; PRODUCAO DE" },
  { cnae: "1042200", descricao: "OLEO DE MAMONA HIDROGENADO; FABRICACAO DE" },
  { cnae: "1042200", descricao: "OLEO DE RICINO REFINADO; PRODUCAO DE" },
  {
    cnae: "1042200",
    descricao:
      "AZEITE DE OLIVA VIRGEM OU REFINADO, INCLUSIVE MISTURAS; PRODUCAO DE",
  },
  { cnae: "1042200", descricao: "OLEO DE BABACU REFINADO; PRODUCAO DE" },
  { cnae: "1042200", descricao: "OLEO DE CARTAMO REFINADO; PRODUCAO DE" },
  { cnae: "1042200", descricao: "OLEO DE AMENDOIM REFINADO; PRODUCAO DE" },
  {
    cnae: "10431",
    descricao:
      "FABRICACAO DE MARGARINA E OUTRAS GORDURAS VEGETAIS E DE OLEOS NAOCOMESTIVEIS DE ANIMAIS",
  },
  {
    cnae: "1043100",
    descricao:
      "MISTURAS OU PREPARACOES ALIMENTICIAS DE GORDURAS OU OLEOS DE ORIGEM ANIMAL E VEGETAL; FABRICACAO DE",
  },
  { cnae: "1043100", descricao: "GORDURA DE COCO; PREPARACAO DE" },
  { cnae: "1043100", descricao: "GORDURAS VEGETAIS COMESTIVEIS; PRODUCAO DE" },
  {
    cnae: "1043100",
    descricao:
      "ESTEARINA SOLAR, OLEO DE BANHA DE PORCO OU DE SEBO, ETC, NAO PREPARADOS; FABRICACAO DE",
  },
  {
    cnae: "1043100",
    descricao: "OLEOS NAO-COMESTIVEIS DE ORIGEM ANIMAL; PRODUCAO DE",
  },
  { cnae: "1043100", descricao: "OLEO DE BALEIA PURIFICADO; FABRICACAO DE" },
  {
    cnae: "1043100",
    descricao: "PREPARACOES A BASE DE CREME VEGETAL; PRODUCAO DE",
  },
  {
    cnae: "1043100",
    descricao:
      "DEGRAS E RESIDUOS PROVENIENTES DO TRATAMENTO DAS MATERIAS GRAXAS OU DAS CERAS ANIMAIS OU VEGETAIS; PRODUCAO DE",
  },
  { cnae: "1043100", descricao: "CERAS DE INSETOS; PRODUCAO DE" },
  {
    cnae: "1043100",
    descricao: "GORDURAS VEGETAIS NAO COMESTIVEIS; PRODUCAO DE",
  },
  { cnae: "1043100", descricao: "OLEOS DE MAMIFEROS MARINHOS; PRODUCAO DE" },
  { cnae: "1043100", descricao: "OLEO DE CACAO; PRODUCAO DE" },
  { cnae: "1043100", descricao: "GORDURAS VEGETAIS HIDROGENADAS; PRODUCAO DE" },
  {
    cnae: "1043100",
    descricao: "GORDURAS VEGETAIS, INCLUSIVE COMPOSTAS; PREPARACAO DE",
  },
  { cnae: "1043100", descricao: "OLEOS DE PEIXES; FABRICACAO DE" },
  {
    cnae: "1043100",
    descricao:
      "OLEOS E GORDURAS ANIMAIS HIDROGENADOS, MESMO REFINADOS; PRODUCAO DE",
  },
  { cnae: "1043100", descricao: "MARGARINA; PREPARACAO DE" },
  { cnae: "1043100", descricao: "OLEO DE PEIXES, EM BRUTO; FABRICACAO DE" },
  { cnae: "1043100", descricao: "OLEO DE BALEIA; PRODUCAO DE" },
  { cnae: "10511", descricao: "PREPARACAO DO LEITE" },
  { cnae: "1051100", descricao: "LEITE; FILTRACAO DE" },
  { cnae: "1051100", descricao: "LEITE PRE-AQUECIDO; PREPARACAO DE" },
  { cnae: "1051100", descricao: "PREPARACAO DO LEITE" },
  { cnae: "1051100", descricao: "LEITE; ESTERILIZACAO DE" },
  { cnae: "1051100", descricao: "LEITE LONGA VIDA; PRODUCAO DE" },
  { cnae: "1051100", descricao: "LEITE; BENEFICIAMENTO DE" },
  { cnae: "1051100", descricao: "LEITE INTEGRAL; PRODUCAO DE" },
  { cnae: "1051100", descricao: "LEITE; PASTEURIZACAO DE" },
  { cnae: "1051100", descricao: "LEITE PASTEURIZADO; PREPARACAO DE" },
  { cnae: "1051100", descricao: "LEITE UHT; PRODUCAO DE" },
  { cnae: "1051100", descricao: "LEITE; RESFRIAMENTO DE" },
  { cnae: "1051100", descricao: "LEITE; HOMOGENEIZACAO DE" },
  { cnae: "1051100", descricao: "LEITE RESFRIADO; PREPARACAO DE" },
  { cnae: "1051100", descricao: "LEITE DESNATADO; PRODUCAO DE" },
  { cnae: "10520", descricao: "FABRICACAO DE LATICINIOS" },
  { cnae: "1052000", descricao: "FLANS OU PUDINS DE LEITE; PRODUCAO DE" },
  {
    cnae: "1052000",
    descricao:
      "QUEIJOS DE MASSA MACIA (TIPO CAMEMBERT, BRIE, ETC); PRODUCAO DE",
  },
  {
    cnae: "1052000",
    descricao: "SORO DE LEITE, INCLUSIVE MODIFICADO; FABRICACAO DE",
  },
  {
    cnae: "1052000",
    descricao: "LEITE EM BLOCOS (CONDENSADO E ACUCARADO); FABRICACAO DE",
  },
  {
    cnae: "1052000",
    descricao: "LEITE FERMENTADO COM LACTOBACILOS; FABRICACAO DE",
  },
  {
    cnae: "1052000",
    descricao:
      "QUEIJOS DE MASSA SEMIDURA (TIPO PRATO, EDAN, ESTEPE, GOUDA, ETC); PRODUCAO DE",
  },
  { cnae: "1052000", descricao: "QUEIJO COBOCO; PRODUCAO DE" },
  { cnae: "1052000", descricao: "QUEIJO MINAS; FABRICACAO DE" },
  { cnae: "1052000", descricao: "PRODUTOS LACTEOS; FABRICACAO DE" },
  { cnae: "1052000", descricao: "LEITE DIETETICO; PRODUCAO DE" },
  { cnae: "1052000", descricao: "QUEIJOS; FABRICACAO DE" },
  {
    cnae: "1052000",
    descricao: "QUEIJOS INACABADOS (EM BRUTO); FABRICACAO DE",
  },
  { cnae: "1052000", descricao: "IOGURTES; FABRICACAO DE" },
  { cnae: "1052000", descricao: "QUEIJO PETIT-SUISSE; PRODUCAO DE" },
  {
    cnae: "1052000",
    descricao: "CREME DE LEITE FRESCO / PASTEURIZADO; PRODUCAO DE",
  },
  { cnae: "1052000", descricao: "LACTOSE E XAROPES DE LACTOSE; PRODUCAO DE" },
  { cnae: "1052000", descricao: "CREME DE LEITE; FABRICACAO DE" },
  { cnae: "1052000", descricao: "COALHADAS; FABRICACAO DE" },
  { cnae: "1052000", descricao: "NATA; PRODUCAO DE" },
  { cnae: "1052000", descricao: "QUEIJO MUSSARELA; FABRICACAO DE" },
  { cnae: "1052000", descricao: "LEITE EM PO (EVAPORADO); FABRICACAO DE" },
  {
    cnae: "1052000",
    descricao: "QUEIJOS RALADOS OU EM PO, DE QUALQUER TIPO; PRODUCAO DE",
  },
  { cnae: "1052000", descricao: "CASEINA; FABRICACAO DE" },
  {
    cnae: "1052000",
    descricao: "CREME DE LEITE ESTERILIZADO / UHT; PRODUCAO DE",
  },
  { cnae: "1052000", descricao: "DOCE DE LEITE; FABRICACAO DE" },
  {
    cnae: "1052000",
    descricao:
      "QUEIJOS DE PASTA AZUL (TIPO GORGONZOLA, ROQUEFORT, ETC); PRODUCAO DE",
  },
  { cnae: "1052000", descricao: "QUEIJO PARMESAO; FABRICACAO DE" },
  { cnae: "1052000", descricao: "QUEIJOS FUNDIDOS; FABRICACAO DE" },
  { cnae: "1052000", descricao: "LEITE CONDENSADO; FABRICACAO DE" },
  {
    cnae: "1052000",
    descricao: "PASTA DE UNTAR DE PRODUTOS PROVENIENTES DO LEITE; PRODUCAO DE",
  },
  { cnae: "1052000", descricao: "MANTEIGA; FABRICACAO DE" },
  { cnae: "1052000", descricao: "LEITE AROMATIZADO; FABRICACAO DE" },
  { cnae: "1052000", descricao: "BEBIDAS ACHOCOLATADAS; FABRICACAO DE" },
  { cnae: "1052000", descricao: "LATICINIOS (PRODUTOS DO LEITE)" },
  { cnae: "1052000", descricao: "QUEIJO PRATO; FABRICACAO DE" },
  { cnae: "1052000", descricao: "PRODUTOS DERIVADOS DO LEITE; FABRICACAO DE" },
  { cnae: "1052000", descricao: "REQUEIJAO; FABRICACAO DE" },
  { cnae: "1052000", descricao: "QUEIJO MONTANHES; PRODUCAO DE" },
  { cnae: "1052000", descricao: "BEBIDAS LACTEAS; PRODUCAO DE" },
  { cnae: "1052000", descricao: "QUEIJO RICOTA; PRODUCAO DE" },
  { cnae: "1052000", descricao: "FARINHAS LACTEAS; FABRICACAO DE" },
  {
    cnae: "1052000",
    descricao: "CREME DE LEITE EM PO, BLOCOS OU GRANULOS; PRODUCAO DE",
  },
  { cnae: "1052000", descricao: "LEITE MODIFICADO; PRODUCAO DE" },
  {
    cnae: "1052000",
    descricao:
      "QUEIJOS DE MASSA DURA (TIPO PARMESAO, PROVOLONE, ETC); PRODUCAO DE",
  },
  { cnae: "1052000", descricao: "LEITE CONCENTRADO; PRODUCAO DE" },
  { cnae: "1052000", descricao: "QUEIJOS FRESCOS (NAO CURADOS); PRODUCAO DE" },
  { cnae: "1052000", descricao: "SOBREMESAS LACTEAS; FABRICACAO DE" },
  {
    cnae: "1052000",
    descricao:
      "OLEO BUTIRICO DE MANTEIGA (BUTTER OIL) E OUTRAS MATERIAS GORDAS PROVENIENTES DO LEITE; FABRICACAO DE",
  },
  { cnae: "1052000", descricao: "LEITE MALTADO; FABRICACAO DE" },
  { cnae: "1052000", descricao: "LEITE GELIFICADO; FABRICACAO DE" },
  {
    cnae: "10538",
    descricao: "FABRICACAO DE SORVETES E OUTROS GELADOS COMESTIVEIS",
  },
  { cnae: "1053800", descricao: "PICOLE; FABRICACAO DE" },
  {
    cnae: "1053800",
    descricao:
      "COBERTURAS GELADAS PARA SORVETES, BOLOS, TORTAS, ETC; FABRICACAO DE",
  },
  { cnae: "1053800", descricao: "GELADOS COMESTIVEIS; FABRICACAO DE" },
  { cnae: "1053800", descricao: "BOLOS E TORTAS GELADOS; FABRICACAO DE" },
  {
    cnae: "1053800",
    descricao:
      "BASES LIQUIDAS OU PASTOSAS PARA ELABORACAO DE SORVETES; FABRICACAO DE",
  },
  { cnae: "1053800", descricao: "SORVETES; FABRICACAO DE" },
  {
    cnae: "10619",
    descricao: "BENEFICIAMENTO DE ARROZ E FABRICACAO DE PRODUTOS DO ARROZ",
  },
  {
    cnae: "1061901",
    descricao:
      "ARROZ (DESCASCADO, MOIDO, BRANQUEADO, POLIDO, ETC); BENEFICIAMENTO DE",
  },
  { cnae: "1061901", descricao: "ARROZ PARBOLIZADO; PRODUCAO DE" },
  { cnae: "2110600", descricao: "LAPACHOL; FABRICACAO DE" },
  { cnae: "1061901", descricao: "GRUMOS OU SEMOLAS DE ARROZ; PRODUCAO DE" },
  { cnae: "1061901", descricao: "ARROZ QUEBRADO (QUIRERA, CUIM); PRODUCAO DE" },
  { cnae: "1061902", descricao: "FARINHA DE ARROZ; FABRICACAO DE" },
  { cnae: "1061902", descricao: "XEREM DE ARROZ; FABRICACAO DE" },
  {
    cnae: "1061902",
    descricao: "FLOCOS DE ARROZ E OUTROS PRODUTOS DO ARROZ; PRODUCAO DE",
  },
  {
    cnae: "1061902",
    descricao: "SEMEAS, FARELOS E OUTROS RESIDUOS DE ARROZ; OBTENCAO DE",
  },
  {
    cnae: "1061902",
    descricao: "ALIMENTOS A BASE DE ARROZ OU DE FLOCOS DE ARROZ; PRODUCAO DE",
  },
  { cnae: "10627", descricao: "MOAGEM DE TRIGO E FABRICACAO DE DERIVADOS" },
  {
    cnae: "1062700",
    descricao: "MASSAS PREPARADAS EM PO PARA BOLOS E TORTAS; FABRICACAO DE",
  },
  { cnae: "1062700", descricao: "SEMOLA DE TRIGO; FABRICACAO DE" },
  {
    cnae: "1062700",
    descricao:
      "MASSAS PREPARADAS EM PO PARA PASTEIS, PIZZAS, EMPADAS, ETC; FABRICACAO DE",
  },
  {
    cnae: "1062700",
    descricao:
      "FARINHAS E MASSAS MESCLADAS E PREPARADAS PARA A FABRICACAO DE PAES, BOLOS, BISCOITOS, ETC; PRODUCAO DE",
  },
  {
    cnae: "1062700",
    descricao: "SEMEAS, FARELOS E OUTROS RESIDUOS DO TRIGO; OBTENCAO DE",
  },
  {
    cnae: "1062700",
    descricao:
      "MISTURAS E PASTAS PARA PRODUTOS DE PADARIA, PASTELARIA, ETC.; PRODUCAO DE",
  },
  { cnae: "1062700", descricao: "TRIGO MOIDO OU TRITURADO; FABRICACAO DE" },
  {
    cnae: "1062700",
    descricao: "FARINHA DE MISTURA DE TRIGO COM CENTEIO; PRODUCAO DE",
  },
  {
    cnae: "1062700",
    descricao:
      "MISTURAS DE MASSAS (EM PO) PARA PREPARO DE BOLOS, TORTAS, PREPARACOES SALGADAS, ETC.; PRODUCAO DE",
  },
  { cnae: "1062700", descricao: "FARINHA DE TRIGO; FABRICACAO DE" },
  {
    cnae: "1062700",
    descricao: "ALIMENTOS A BASE DE TRIGO OU DE FLOCOS DE TRIGO; PRODUCAO DE",
  },
  { cnae: "1062700", descricao: "FARINHA DE TRIGO (INTEGRAL); FABRICACAO DE" },
  { cnae: "1062700", descricao: "TRIGO PARA QUIBE; FABRICACAO DE" },
  {
    cnae: "1062700",
    descricao: "GRUMOS, SEMOLAS OU PELLETS DE TRIGO; PRODUCAO DE",
  },
  { cnae: "10635", descricao: "FABRICACAO DE FARINHA DE MANDIOCA E DERIVADOS" },
  {
    cnae: "1063500",
    descricao:
      "FARINHA DE MANDIOCA (INCLUSIVE PARA PANIFICACAO); FABRICACAO DE",
  },
  { cnae: "1063500", descricao: "MASSA DE MANDIOCA; FABRICACAO DE" },
  { cnae: "1063500", descricao: "RASPA DE MANDIOCA; FABRICACAO DE" },
  { cnae: "1063500", descricao: "FARINHA DE RASPA DE MANDIOCA; FABRICACAO DE" },
  {
    cnae: "10643",
    descricao:
      "FABRICACAO DE FARINHA DE MILHO E DERIVADOS, EXCETO OLEOS DE MILHO",
  },
  { cnae: "1064300", descricao: "FUBA; FABRICACAO DE" },
  { cnae: "1064300", descricao: "MILHO PARA PIPOCA; PREPARACAO DE" },
  {
    cnae: "1064300",
    descricao:
      "ALIMENTOS A BASE DE MILHO OU DE FLOCOS DE MILHO (SUCRILHOS); PRODUCAO DE",
  },
  { cnae: "1064300", descricao: "CREME DE MILHO; FABRICACAO DE" },
  {
    cnae: "1064300",
    descricao:
      "FARINHAS DE MILHO TERMICAMENTE TRATADAS OU ALIMENTOS A BASE DE MILHO (POS, FLOCOS, PRODUTOS PRE-COZIDOS, ETC); FABRICACAO DE",
  },
  { cnae: "1064300", descricao: "FLOCOS E BIJUS DE MILHO; FABRICACAO DE" },
  { cnae: "1064300", descricao: "QUIRERA (MILHO QUEBRADO); FABRICACAO DE" },
  { cnae: "1064300", descricao: "CANJICA DE MILHO; FABRICACAO DE" },
  { cnae: "1064300", descricao: "PIPOCA DOCE; FABRICACAO DE" },
  {
    cnae: "1064300",
    descricao:
      "GRAOS E GERMES DE MILHO DESCASCADOS, CORTADOS, PARTIDOS, ETC.; PRODUCAO DE",
  },
  { cnae: "1064300", descricao: "PIPOCA; FABRICACAO DE" },
  { cnae: "1064300", descricao: "GRITZ DE MILHO; FABRICACAO DE" },
  { cnae: "1064300", descricao: "MILHO QUEBRADO (QUIRERA); FABRICACAO DE" },
  {
    cnae: "1064300",
    descricao: "SEMEAS, FARELOS E OUTROS RESIDUOS DO MILHO; OBTENCAO DE",
  },
  { cnae: "1064300", descricao: "XEREM DE MILHO; FABRICACAO DE" },
  {
    cnae: "1064300",
    descricao:
      "FARINHAS CRUAS DE MILHO (CREME DE MILHO, GRITZ DE MILHO, ETC); FABRICACAO DE",
  },
  {
    cnae: "1064300",
    descricao: "FARELOS E OUTROS RESIDUOS DA MOAGEM DO MILHO; FABRICACAO DE",
  },
  { cnae: "1064300", descricao: "FARINHA DE MILHO; FABRICACAO DE" },
  { cnae: "1064300", descricao: "PIPOCA DE MICRO-ONDAS; FABRICACAO DE" },
  { cnae: "1064300", descricao: "GRUMOS OU SEMOLAS DE MILHO; PRODUCAO DE" },
  {
    cnae: "10651",
    descricao: "FABRICACAO DE AMIDOS E FECULAS DE VEGETAIS E DE OLEOS DE MILHO",
  },
  {
    cnae: "1065101",
    descricao: "AMIDOS, FECULAS, GOMAS OU POLVILHOS; PRODUCAO DE",
  },
  { cnae: "1065101", descricao: "AMIDOS E FECULAS; FABRICACAO DE" },
  { cnae: "1065101", descricao: "POLVILHO AZEDO; FABRICACAO DE" },
  { cnae: "1065101", descricao: "POLVILHO DOCE; FABRICACAO DE" },
  { cnae: "1065101", descricao: "AMIDO E FECULA DE MANDIOCA; FABRICACAO DE" },
  { cnae: "1065101", descricao: "GLICOSE E XAROPE DE GLICOSE; PRODUCAO DE" },
  {
    cnae: "1065101",
    descricao: "INULINA, GLUTEN DE TRIGO, DEXTRINA; PRODUCAO DE",
  },
  { cnae: "1065101", descricao: "MALTOSE, ACUCAR INVERTIDO; PRODUCAO DE" },
  {
    cnae: "1065101",
    descricao: "CARAMELOS CORANTES OU XAROPES DE ACUCARES; PRODUCAO DE",
  },
  { cnae: "1065101", descricao: "FECULA DE BATATA; PRODUCAO DE" },
  { cnae: "1065101", descricao: "AMIDO E FECULA DE ARARUTA; FABRICACAO DE" },
  { cnae: "1065101", descricao: "GLUTEN DE TRIGO; FABRICACAO DE" },
  { cnae: "1065101", descricao: "GLUTEN E FARINHA DE GLUTEN; FABRICACAO DE" },
  { cnae: "1065101", descricao: "AMIDOS E FECULAS DE BATATA; FABRICACAO DE" },
  { cnae: "1065101", descricao: "AMIDO E FECULA DE MILHO; FABRICACAO DE" },
  {
    cnae: "1065101",
    descricao: "CARAMELO PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "1065101",
    descricao:
      "TAPIOCA E SEUS SUCEDANEOS, PREPARADOS A PARTIR DE FECULAS, EM GRUMOS, FLOCOS, ETC.; FABRICACAO DE; FABRICACAO DE",
  },
  { cnae: "1065101", descricao: "INULINA; PRODUCAO DE" },
  { cnae: "1065101", descricao: "AMIDO E FECULA DE TRIGO; FABRICACAO DE" },
  { cnae: "1065101", descricao: "AMIDOS E FECULAS MODIFICADOS; PRODUCAO DE" },
  {
    cnae: "1065101",
    descricao: "RESIDUOS DA FABRICACAO DE AMIDO; OBTENCAO DE",
  },
  {
    cnae: "1065101",
    descricao:
      "PRODUTOS ELABORADOS A PARTIR DO AMIDO: ACUCARES (GLICOSE, MALTOSE), TAPIOCA, ETC; FABRICACAO DE",
  },
  { cnae: "1065101", descricao: "FRUTOSE E XAROPE DE FRUTOSE; PRODUCAO DE" },
  { cnae: "1065101", descricao: "FECULAS COMPOSTAS; FABRICACAO DE" },
  {
    cnae: "1065102",
    descricao:
      "TORTAS, FARINHAS E OUTROS RESIDUOS DA EXTRACAO DO OLEO DE MILHO; PRODUCAO DE",
  },
  { cnae: "1065102", descricao: "OLEO DE MILHO EM BRUTO; PRODUCAO DE" },
  { cnae: "1065103", descricao: "OLEO DE MILHO REFINADO; PRODUCAO DE" },
  { cnae: "10660", descricao: "FABRICACAO DE ALIMENTOS PARA ANIMAIS" },
  { cnae: "1066000", descricao: "SAL MINERALIZADO; OBTENCAO DE" },
  {
    cnae: "1066000",
    descricao:
      "ALIMENTOS PREPARADOS PARA GATOS, CACHORROS E OUTROS ANIMAIS; FABRICACAO DE",
  },
  {
    cnae: "1066000",
    descricao: "SUPLEMENTO MINERAL PARA RACOES; FABRICACAO DE",
  },
  {
    cnae: "1066000",
    descricao:
      "RACAO PARA QUALQUER TIPO DE ANIMAL (BOVINOS, SUINOS(PORCOS), AVES, COELHOS, ETC); FABRICACAO DE",
  },
  {
    cnae: "1066000",
    descricao: "PREPARACOES UTILIZADAS NA ALIMENTACAO DE ANIMAIS; PRODUCAO DE",
  },
  {
    cnae: "1066000",
    descricao:
      "RACOES E FORRAGENS BALANCEADAS PARA ANIMAIS (BOVINOS, SUINOS (PORCOS), AVES, COELHOS, ETC); FABRICACAO DE",
  },
  {
    cnae: "1066000",
    descricao:
      "FARINHAS E PELLETS DE RAIZES E OUTROS PRODUTOS FORRAGEIROS; FABRICACAO DE",
  },
  {
    cnae: "1066000",
    descricao:
      "ALIMENTOS PREPARADOS PARA ANIMAIS (BOVINOS, SUINOS (PORCOS), COELHOS, AVES, ETC); FABRICACAO DE",
  },
  {
    cnae: "1066000",
    descricao:
      "SUPLEMENTO VITAMINICO OU MINERAL PARA RACOES (PREMIX VITAMINICO OU MINERAL); FABRICACAO DE",
  },
  {
    cnae: "10694",
    descricao:
      "MOAGEM E FABRICACAO DE PRODUTOS DE ORIGEM VEGETAL NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "1069400",
    descricao:
      "FARINHAS, SEMOLAS E POS DE FRUTAS OU CASCAS DE CITRICOS, ETC; PRODUCAO DE",
  },
  { cnae: "1069400", descricao: "FARINHA DE ARARUTA; FABRICACAO DE" },
  {
    cnae: "1069400",
    descricao: "FARINHAS, SEMOLAS E POS DE RAIZES OU TUBERCULOS; PRODUCAO DE",
  },
  {
    cnae: "1069400",
    descricao:
      "ALIMENTOS A BASE DE CEREAIS OU DE FLOCOS DE CEREAIS; PRODUCAO DE",
  },
  {
    cnae: "1069400",
    descricao:
      "FARINHAS, SEMOLAS E POS DE LEGUMES DE VAGEM, SECOS; PRODUCAO DE",
  },
  { cnae: "1069400", descricao: "FARINHAS DE LIMAO, FABRICACAO DE" },
  { cnae: "1069400", descricao: "FARINHAS COMPOSTAS; FABRICACAO DE" },
  {
    cnae: "1069400",
    descricao:
      "GRUMOS, SEMOLAS OU PELLETS DE AVEIA OU DE OUTROS CEREAIS; PRODUCAO DE",
  },
  {
    cnae: "1069400",
    descricao: "LUPULO TRITURADO, MOIDO OU EM PELLETS; LUPULINA; FABRICACAO DE",
  },
  { cnae: "1069400", descricao: "CEVADA EM GRAO; BENEFICIAMENTO DE" },
  { cnae: "1069400", descricao: "FARINHA DE CEVADA; FABRICACAO DE" },
  {
    cnae: "1069400",
    descricao:
      "SEMEAS, FARELOS E OUTROS RESIDUOS DE CEREAIS E DE LEGUMINOSAS, N.E.; OBTENCAO DE",
  },
  {
    cnae: "1069400",
    descricao:
      "APERITIVOS E ALIMENTOS A BASE DE ARARUTA, CENTEIO, CEVADA, AVEIA, GERMENS DE CEREAIS, ETC; FABRICACAO DE",
  },
  { cnae: "1069400", descricao: "SAGU; BENEFICIAMENTO DE" },
  { cnae: "1069400", descricao: "FARINHAS E FLOCOS DE AVEIA; FABRICACAO DE" },
  {
    cnae: "1069400",
    descricao:
      "FARELOS E OUTROS RESIDUOS DE CEREAIS E DE LEGUMINOSAS; FABRICACAO DE",
  },
  { cnae: "1069400", descricao: "GERMENS DE CEREAIS; FABRICACAO DE" },
  { cnae: "1069400", descricao: "FARINHAS DE MARACUJA, FABRICACAO DE" },
  { cnae: "1069400", descricao: "AVEIA BENEFICIADA; FABRICACAO DE" },
  { cnae: "1069400", descricao: "FARINHAS DE FRUTAS N.E., FABRICACAO DE" },
  { cnae: "1069400", descricao: "FARINHAS DE MACA, FABRICACAO DE" },
  {
    cnae: "1069400",
    descricao:
      "FARINHAS E POS DOS LEGUMES DE VAGEM, SECOS, DE RAIZES, TUBERCULOS, FRUTAS OU CASCAS DE FRUTAS; FABRICACAO DE",
  },
  { cnae: "1069400", descricao: "FARINHA DE CENTEIO; FABRICACAO DE" },
  { cnae: "10716", descricao: "FABRICACAO DE ACUCAR EM BRUTO" },
  { cnae: "1071600", descricao: "CALDO DE CANA NAO FERMENTADO; FABRICACAO DE" },
  {
    cnae: "1071600",
    descricao: "MELACO DE CANA RESULTANTE DA EXTRACAO DO ACUCAR; PRODUCAO DE",
  },
  { cnae: "1071600", descricao: "MELADO; FABRICACAO DE" },
  {
    cnae: "1071600",
    descricao: "CALDO DE CANA FERMENTADO (GARAPA); FABRICACAO DE",
  },
  {
    cnae: "1071600",
    descricao: "ACUCAR VHP (VERY HIGH POLARIZATION); FABRICACAO DE",
  },
  { cnae: "1071600", descricao: "ACUCAR MASCAVO; FABRICACAO DE" },
  { cnae: "1071600", descricao: "RAPADURA; FABRICACAO DE" },
  { cnae: "1071600", descricao: "ACUCAR DEMERARA; FABRICACAO DE" },
  { cnae: "1071600", descricao: "ACUCAR BRUTO (INSTANTANEO); FABRICACAO DE" },
  { cnae: "1071600", descricao: "ACUCAR DE BETERRABA EM BRUTO; FABRICACAO DE" },
  {
    cnae: "1071600",
    descricao:
      "BAGACO DE CANA DE ACUCAR E OUTROS DESPERDICIOS DA INDUSTRIA DO ACUCAR; OBTENCAO DE",
  },
  { cnae: "1071600", descricao: "ACUCAR CRISTAL; FABRICACAO DE" },
  { cnae: "10724", descricao: "FABRICACAO DE ACUCAR REFINADO" },
  { cnae: "1072401", descricao: "ACUCAR MOIDO OU TRITURADO; FABRICACAO DE" },
  { cnae: "1072401", descricao: "ACUCAR LIQUIDO; FABRICACAO DE" },
  {
    cnae: "1072401",
    descricao:
      "ACUCARES REFINADOS COM ADICAO DE AROMATIZANTES OU CORANTES EXCETO POS PARA REFRESCOS; PRODUCAO DE",
  },
  { cnae: "1072401", descricao: "SACAROSE; PRODUCAO DE" },
  {
    cnae: "1072401",
    descricao: "MELACO DE CANA RESULTANTE DO REFINO DO ACUCAR; PRODUCAO DE",
  },
  {
    cnae: "1072401",
    descricao: "ACUCAR AMORFO (MICRO CRISTALINO); FABRICACAO DE",
  },
  { cnae: "1072401", descricao: "ACUCAR REFINADO; FABRICACAO DE" },
  { cnae: "1072401", descricao: "ACUCAR GRANULADO; FABRICACAO DE" },
  { cnae: "1072401", descricao: "ACUCAR DE CONFEITEIRO; FABRICACAO DE" },
  {
    cnae: "1072402",
    descricao:
      "POLPAS DE BETERRABA E OUTROS RESIDUOS DA FABRICACAO DO ACUCAR; OBTENCAO DE",
  },
  { cnae: "1072402", descricao: "ACUCAR DE CEREAIS (DEXTROSE); FABRICACAO DE" },
  { cnae: "1072402", descricao: "ACUCAR DE BETERRABA EM BRUTO; PRODUCAO DE" },
  { cnae: "10813", descricao: "TORREFACAO E MOAGEM DE CAFE" },
  {
    cnae: "1081301",
    descricao: "CAFE; BENEFICIAMENTO DE (NAO ASSOCIADO AO CULTIVO)",
  },
  { cnae: "1081302", descricao: "CAFE; TORREFACAO DE" },
  { cnae: "1081302", descricao: "CAFE; MOAGEM DE" },
  { cnae: "1081302", descricao: "CAFE TORRADO E MOIDO; PRODUCAO DE" },
  { cnae: "1081302", descricao: "CAFE DESCAFEINADO; PRODUCAO DE" },
  {
    cnae: "1081302",
    descricao:
      "CAFE TORRADO E MOIDO, AROMATIZADO (MESMO DESCAFEINADO); PRODUCAO DE",
  },
  { cnae: "1081302", descricao: "CAFE; TORREFACAO E MOAGEM DE" },
  { cnae: "1081302", descricao: "CAFE TORRADO EM GRAOS; PRODUCAO DE" },
  { cnae: "10821", descricao: "FABRICACAO DE PRODUTOS A BASE DE CAFE" },
  {
    cnae: "1082100",
    descricao: "SUCEDANEOS DO CAFE, CONTENDO CAFE; PRODUCAO DE",
  },
  {
    cnae: "1082100",
    descricao: "EXTRATOS, ESSENCIAS E CONCENTRADOS DE CAFE; PRODUCAO DE",
  },
  {
    cnae: "1082100",
    descricao:
      "PREPARACOES A BASE DE ESSENCIAS OU EXTRATOS DE CAFE (CAPPUCCINO); PRODUCAO DE",
  },
  { cnae: "1082100", descricao: "CAFE SOLUVEL; PRODUCAO DE" },
  {
    cnae: "1082100",
    descricao:
      "CHICORIA TORRADA E OUTROS SUCEDANEOS TORRADOS DO CAFE; PRODUCAO DE",
  },
  {
    cnae: "1082100",
    descricao: "CAFE COM LEITE EM MISTURA SOLUVEL; FABRICACAO DE",
  },
  { cnae: "10911", descricao: "FABRICACAO DE PRODUTOS DE PANIFICACAO" },
  { cnae: "1091101", descricao: "BOLOS INDUSTRIALIZADOS; FABRICACAO DE" },
  {
    cnae: "1091101",
    descricao: "PANETONES E SIMILARES (DE INDUSTRIA); FABRICACAO DE",
  },
  {
    cnae: "1091101",
    descricao:
      "PAO DE QUEIJO CONGELADO, INCLUSIVE MASSA PREPARADA CONGELADA; FABRICACAO DE",
  },
  { cnae: "1091101", descricao: "TORRADAS INDUSTRIALIZADAS; FABRICACAO DE" },
  {
    cnae: "1091101",
    descricao:
      "PAES DIVERSOS INDUSTRIALIZADOS (BATATA, GLUTEN, MILHO, INTEGRAL, PRETO, ETC); FABRICACAO DE",
  },
  {
    cnae: "1091101",
    descricao: "PAES DE FORMA INDUSTRIALIZADOS; FABRICACAO DE",
  },
  { cnae: "1091101", descricao: "PRODUTOS DA INDUSTRIA DO PAO, FABRICACAO DE" },
  {
    cnae: "1091101",
    descricao:
      "PAES DE ESPECIARIAS (PAO DE GENGIBRE,ETC.) ELABORADOS A PARTIR DE PANIFICACAO INDUSTRIAL; FABRICACAO DE",
  },
  { cnae: "1091101", descricao: "FARINHA DE ROSCA; FABRICACAO DE" },
  {
    cnae: "1091101",
    descricao: "PAES E ROSCAS INDUSTRIALIZADOS; FABRICACAO DE",
  },
  { cnae: "1091101", descricao: "PAES CONGELADOS; FABRICACAO DE" },
  { cnae: "1091101", descricao: "FARINHA DE PAO; FABRICACAO DE" },
  {
    cnae: "1091102",
    descricao:
      "BOLOS, TORTAS E DOCES, PRODUZIDOS EM PADARIAS OU CONFEITARIAS; FABRICACAO DE",
  },
  {
    cnae: "1091102",
    descricao:
      "BISNAGAS (PAES), PRODUZIDOS EM PADARIAS OU CONFEITARIAS; FABRICACAO DE",
  },
  {
    cnae: "1091102",
    descricao: "PADARIA COM PREDOMINANCIA DE PRODUCAO PROPRIA",
  },
  {
    cnae: "1091102",
    descricao: "PRODUTOS DE CONFEITARIA COM PREDOMINANCIA DE PRODUCAO PROPRIA",
  },
  {
    cnae: "1091102",
    descricao:
      "PAES DE ESPECIARIAS (PAO DE GENGIBRE,ETC.) DE PADARIA E CONFEITARIA COM PREDOMINANCIA DE FABRICACAO PROPRIA; FABRICACAO DE",
  },
  {
    cnae: "1091102",
    descricao: "PANETONES E SIMILARES (DE PANIFICADORAS); FABRICACAO DE",
  },
  {
    cnae: "1091102",
    descricao: "PADARIA E CONFEITARIA COM PREDOMINANCIA DE PRODUCAO PROPRIA",
  },
  {
    cnae: "1091102",
    descricao:
      "BAGUETES (PAES), PRODUZIDOS EM PADARIAS OU CONFEITARIAS; FABRICACAO DE",
  },
  {
    cnae: "1091102",
    descricao: "PANIFICACAO COM PREDOMINANCIA DE PRODUCAO PROPRIA",
  },
  {
    cnae: "1091102",
    descricao:
      "TORRADAS (PANIFICADORAS), PRODUZIDOS EM PADARIAS OU CONFEITARIAS; FABRICACAO DE",
  },
  {
    cnae: "1091102",
    descricao: "PRODUTOS DE PADARIA (EXCETO BISCOITOS); FABRICACAO DE",
  },
  { cnae: "10929", descricao: "FABRICACAO DE BISCOITOS E BOLACHAS" },
  {
    cnae: "1092900",
    descricao:
      "CASQUINHAS PARA SORVETES E FORMAS PARA RECHEIOS DE DOCES E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "1092900",
    descricao:
      "BISCOITOS DOCES, RECHEADOS E/OU COM COBERTURA, ETC; FABRICACAO DE",
  },
  {
    cnae: "1092900",
    descricao:
      "BISCOITOS SALGADOS, RECHEADOS E/OU COM COBERTURA, ETC; FABRICACAO DE",
  },
  {
    cnae: "1092900",
    descricao: "BISCOITOS E BOLACHAS INDUSTRIALIZADOS; FABRICACAO DE",
  },
  {
    cnae: "10937",
    descricao:
      "FABRICACAO DE PRODUTOS DERIVADOS DO CACAU, DE CHOCOLATES E CONFEITOS",
  },
  { cnae: "1093701", descricao: "ACHOCOLATADOS EM PO; PRODUCAO DE" },
  {
    cnae: "1093701",
    descricao: "CHOCOLATE EM BARRAS OU TABLETES; FABRICACAO DE",
  },
  {
    cnae: "1093701",
    descricao: "LIQUOR (CHOCOLATE AMARGO PARA USO INDUSTRIAL); FABRICACAO DE",
  },
  { cnae: "1093701", descricao: "MANTEIGA DE CACAU; FABRICACAO DE" },
  { cnae: "1093701", descricao: "TORTA DE CACAU; FABRICACAO DE" },
  { cnae: "1093701", descricao: "CHOCOLATE EM PO; FABRICACAO DE" },
  { cnae: "1093701", descricao: "PASTA DE CACAU (MASSA); FABRICACAO DE" },
  { cnae: "1093701", descricao: "CHOCOLATE GRANULADO; PRODUCAO DE" },
  {
    cnae: "1093701",
    descricao: "CHOCOLATE AMARGO PARA USO INDUSTRIAL (LIQUOR); FABRICACAO DE",
  },
  {
    cnae: "1093701",
    descricao: "PRODUTOS DERIVADOS DO BENEFICIAMENTO DO CACAU; FABRICACAO DE",
  },
  { cnae: "1093701", descricao: "CACAU EM PO; FABRICACAO DE" },
  { cnae: "1093701", descricao: "BOMBONS; FABRICACAO DE" },
  { cnae: "1093701", descricao: "FARINHAS A BASE DE CHOCOLATE; FABRICACAO DE" },
  { cnae: "1093702", descricao: "CHOCOLATE BRANCO, SEM CACAU; PRODUCAO DE" },
  {
    cnae: "1093702",
    descricao: "BALAS, CONFEITOS, CARAMELOS E SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "1093702", descricao: "FRUTAS CRISTALIZADAS; FABRICACAO DE" },
  { cnae: "1093702", descricao: "PASTILHAS; FABRICACAO DE" },
  { cnae: "1093702", descricao: "GOMAS DE MASCAR, CHICLETES; FABRICACAO DE" },
  { cnae: "10945", descricao: "FABRICACAO DE MASSAS ALIMENTICIAS" },
  {
    cnae: "1094500",
    descricao: "MASSAS ALIMENTICIAS (TALHARIM, ESPAGUETE, ETC); FABRICACAO DE",
  },
  { cnae: "1094500", descricao: "PASTIFICIO" },
  { cnae: "1094500", descricao: "MACARRAO; FABRICACAO DE" },
  {
    cnae: "1094500",
    descricao:
      "MASSAS ALIMENTICIAS COZIDAS, RECHEADAS OU PREPARADAS DE OUTRO MODO (RAVIOLE, CANELONE, ETC); PRODUCAO DE",
  },
  { cnae: "1094500", descricao: "MASSAS ALIMENTICIAS SECAS; FABRICACAO DE" },
  {
    cnae: "1094500",
    descricao:
      "MASSAS PREPARADAS (FRESCAS, CONGELADAS OU RESFRIADAS), PARA LASANHA, PIZZA, ETC, COM OU SEM RECHEIO; FABRICACAO DE",
  },
  {
    cnae: "10953",
    descricao: "FABRICACAO DE ESPECIARIAS, MOLHOS, TEMPEROS E CONDIMENTOS",
  },
  { cnae: "1095300", descricao: "MOLHO INGLES; PRODUCAO DE" },
  { cnae: "1095300", descricao: "MOLHOS; PREPARACAO DE" },
  { cnae: "1095300", descricao: "TEMPEROS EM CONSERVAS; PREPARACAO DE" },
  {
    cnae: "1095300",
    descricao:
      "CREMES E MOLHOS DIVERSOS A BASE DE EMULSAO DO TIPO OLEO E AGUA; PREPARACAO DE",
  },
  { cnae: "1095300", descricao: "MAIONESE; PREPARACAO DE" },
  { cnae: "1095300", descricao: "MOLHO DE SOJA; PRODUCAO DE" },
  { cnae: "1095300", descricao: "BASE PARA MOLHOS; PREPARACAO DE" },
  {
    cnae: "1095300",
    descricao: "TEMPEROS, DESIDRATADOS E LIOFILIZADOS; PREPARACAO DE",
  },
  {
    cnae: "1095300",
    descricao:
      "CATCHUPS E OUTROS MOLHOS (EXCETO CONCENTRADOS E MASSA DE TOMATE); PREPARACAO DE",
  },
  { cnae: "1095300", descricao: "PIMENTA EM CONSERVA; PREPARACAO DE" },
  { cnae: "1311100", descricao: "DESPERDICIOS DO BENEFICIAMENTO DO ALGODAO" },
  {
    cnae: "1095300",
    descricao:
      "MOLHOS PREPARADOS PARA MASSAS (EXCETO CONCENTRADOS E MASSA DE TOMATE); FABRICACAO DE",
  },
  { cnae: "1095300", descricao: "MISTURAS DE ESPECIARIAS; PRODUCAO DE" },
  { cnae: "1095300", descricao: "MOLHOS DE PIMENTA; PREPARACAO DE" },
  { cnae: "1095300", descricao: "MOLHOS DE MOSTARDA; PREPARACAO DE" },
  { cnae: "1095300", descricao: "NOZ-MOSCADA; PREPARACAO DE" },
  {
    cnae: "1095300",
    descricao: "TEMPEROS CONGELADOS E RESFRIADOS; PREPARACAO DE",
  },
  { cnae: "1095300", descricao: "MISTURAS DE CONDIMENTOS; PRODUCAO DE" },
  {
    cnae: "1095300",
    descricao:
      "TEMPEROS DIVERSOS DESIDRATADOS, CONGELADOS, LIOFILIZADOS, EM CONSERVAS, ETC; PREPARACAO DE",
  },
  {
    cnae: "1095300",
    descricao:
      "CONDIMENTOS E ESPECIARIAS (CANELA, COLORAU, SAL COM ALHO, ETC); PREPARACAO DE",
  },
  { cnae: "10961", descricao: "FABRICACAO DE ALIMENTOS E PRATOS PRONTOS" },
  {
    cnae: "1096100",
    descricao: "PRATOS PRONTOS CONGELADOS A BASE DE CARNES; FABRICACAO DE",
  },
  {
    cnae: "1096100",
    descricao: "PRATOS PRONTOS CONGELADOS A BASE DE MASSAS; FABRICACAO DE",
  },
  {
    cnae: "1096100",
    descricao:
      "PRATOS PRONTOS CONGELADOS A BASE DE CARNES DE AVES; FABRICACAO DE",
  },
  {
    cnae: "1096100",
    descricao: "SOBREMESAS PRONTAS PARA CONSUMO; FABRICACAO DE",
  },
  { cnae: "1096100", descricao: "GELATINA PREPARADA; FABRICACAO DE" },
  {
    cnae: "1096100",
    descricao:
      "PRATOS PRONTOS CONGELADOS A BASE DE LEGUMES E OUTROS VEGETAIS; FABRICACAO DE",
  },
  { cnae: "1096100", descricao: "PIZZAS PRONTAS CONGELADAS, FABRICACAO DE" },
  {
    cnae: "1096100",
    descricao:
      "ALIMENTOS CONSERVADOS (FEIJOADAS, ENLATADOS, ETC); PREPARACAO DE",
  },
  {
    cnae: "1096100",
    descricao: "CARNES ENLATADAS (EM CONSERVAS); PRODUCAO DE",
  },
  { cnae: "1096100", descricao: "SALGADINHOS CONGELADOS; FABRICACAO DE" },
  {
    cnae: "1096100",
    descricao:
      "SALGADINHOS, DOCES CASEIROS (EXCETO DE FRUTAS E/OU LEITE) ; FABRICACAO DE",
  },
  {
    cnae: "1096100",
    descricao:
      "GELATINAS, PUDINS, FLANS E OUTRAS SOBREMESAS PRONTAS PARA CONSUMO (EXCETO SOBREMESAS LACTEAS); PRODUCAO DE",
  },
  {
    cnae: "1096100",
    descricao: "PRATOS PRONTOS CONGELADOS A BASE DE PESCADO; FABRICACAO DE",
  },
  { cnae: "1096100", descricao: "PUDINS PREPARADOS; FABRICACAO DE" },
  {
    cnae: "10996",
    descricao:
      "FABRICACAO DE PRODUTOS ALIMENTICIOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "1099601",
    descricao: "VINAGRES DE VINHO, FRUTAS, ALCOOL, ETC; FABRICACAO DE",
  },
  { cnae: "1099602", descricao: "PO PARA PREPARAR MOUSSE; FABRICACAO DE" },
  {
    cnae: "1099602",
    descricao: "GELATINA EM PO PARA FINS ALIMENTARES; PRODUCAO DE",
  },
  { cnae: "1099602", descricao: "POS PARA GELATINAS; FABRICACAO DE" },
  { cnae: "1099602", descricao: "POS PARA PREPARAR SORVETES; FABRICACAO DE" },
  {
    cnae: "1099602",
    descricao: "POS PARA PUDINS, GELATINAS, ETC; FABRICACAO DE",
  },
  { cnae: "1099603", descricao: "LEVEDURA DE CERVEJA; FABRICACAO DE" },
  { cnae: "1099603", descricao: "FERMENTOS E LEVEDURAS; FABRICACAO DE" },
  { cnae: "1099604", descricao: "GELO (EXCETO GELO SECO); FABRICACAO DE" },
  { cnae: "1099605", descricao: "CHA DE ERVAS DIVERSAS; BENEFICIAMENTO DE" },
  { cnae: "1099605", descricao: "CHA PARA INFUSAO EM SAQUINHO; FABRICACAO DE" },
  { cnae: "1099605", descricao: "CHA-DA-INDIA (CHA PRETO); BENEFICIAMENTO DE" },
  { cnae: "1099605", descricao: "CHA MATE EM SAQUINHOS; FABRICACAO DE" },
  { cnae: "1099605", descricao: "ERVAS PARA INFUSAO; FABRICACAO DE" },
  { cnae: "1099606", descricao: "ADOCANTES NATURAIS, FABRICACAO DE" },
  {
    cnae: "1099606",
    descricao:
      "ACUCAR DE STEVIA (STEVIDEO, STEVITAS), GRANULADO, CRISTAL; FABRICACAO DE",
  },
  { cnae: "1099606", descricao: "ADOCANTES, FABRICACAO DE" },
  { cnae: "1099606", descricao: "ACUCAR ESTEVIA (ADOCANTE), FABRICACAO DE" },
  { cnae: "1099606", descricao: "ADOCANTE DE ESTEVIA(STEVIA), FABRICACAO DE" },
  { cnae: "1099606", descricao: "ADOCANTES ARTIFICIAIS, FABRICACAO DE" },
  { cnae: "1099606", descricao: "ADOCANTES DIETETICOS; FABRICACAO DE" },
  { cnae: "1099607", descricao: "COMPLEMENTOS ALIMENTARES; PRODUCAO DE" },
  { cnae: "1099607", descricao: "ALIMENTOS DIETETICOS; PREPARACAO DE" },
  { cnae: "1099607", descricao: "COMPLEMENTOS DIETETICOS; FABRICACAO DE" },
  {
    cnae: "1099607",
    descricao:
      "PRODUTOS ALIMENTICIOS ENRIQUECIDOS COM VITAMINAS OU PROTEINAS; PREPARACAO DE",
  },
  { cnae: "1099699", descricao: "CALDO DE CARNE; FABRICACAO DE" },
  {
    cnae: "1099699",
    descricao: "DOCES DIVERSOS (EXCETO DE FRUTAS OU DE LEITE); FABRICACAO DE",
  },
  { cnae: "1099699", descricao: "GUARANA EM PO; FABRICACAO DE" },
  {
    cnae: "1099699",
    descricao: "PRODUTOS HORTICOLAS HOMOGENEIZADOS; FABRICACAO DE",
  },
  { cnae: "1099699", descricao: "GELEIAS, EXCETO DE FRUTAS; FABRICACAO DE" },
  { cnae: "1099699", descricao: "ALIMENTOS PARA CRIANCAS; PREPARACAO DE" },
  {
    cnae: "1099699",
    descricao: "PREPARACOES ALIMENTICIAS COMPOSTAS HOMOGENEIZADAS; PRODUCAO DE",
  },
  { cnae: "1099699", descricao: "DOCE SUSPIRO; FABRICACAO DE" },
  {
    cnae: "1099699",
    descricao: "SAL DE COZINHA REFINADO, SAL IODADO; PREPARACAO DE",
  },
  { cnae: "1099699", descricao: "CHUVISCO (DOCE); FABRICACAO DE" },
  { cnae: "1099699", descricao: "CALDO DE LEGUMES; FABRICACAO DE" },
  {
    cnae: "1099699",
    descricao: "EXTRATOS E SUCOS DE CARNES DE AVES; PRODUCAO DE",
  },
  { cnae: "1099699", descricao: "GELEIA DE MOCOTO; FABRICACAO DE" },
  {
    cnae: "1099699",
    descricao:
      "PREPARACOES HOMOGENEIZADAS DE CARNE, MIUDEZAS OU SANGUE; PRODUCAO DE",
  },
  { cnae: "1099699", descricao: "AGAR-AGAR; PRODUCAO DE" },
  { cnae: "1099699", descricao: "CALDO DE GALINHA; FABRICACAO DE" },
  {
    cnae: "1099699",
    descricao: "GUARANA (EM BASTAO, EM PO, ETC); BENEFICIAMENTO DE",
  },
  { cnae: "1099699", descricao: "EXTRATO DE MALTE; FABRICACAO DE" },
  {
    cnae: "1099699",
    descricao:
      "OVOS SEM CASCA OU GEMAS DE OVOS CONSERVADAS; OVALBUMINA; PRODUCAO DE",
  },
  { cnae: "1099699", descricao: "ANILINAS PARA ACUCAR; FABRICACAO DE" },
  { cnae: "1099699", descricao: "HOSTIAS; FABRICACAO DE" },
  { cnae: "1099699", descricao: "EXTRATOS E SUCOS DE CARNE; PRODUCAO DE" },
  {
    cnae: "1099699",
    descricao:
      "MEL ARTIFICIAL OU MISTURA DE MEL NATURAL COM MEL ARTIFICIAL; OUTROS PRODUTOS A BASE DE MEL; FABRICACAO DE",
  },
  { cnae: "1099699", descricao: "SUCOS OU EXTRATOS VEGETAIS; PRODUCAO DE" },
  { cnae: "1099699", descricao: "DOCE MARIA MOLE; FABRICACAO DE" },
  {
    cnae: "1099699",
    descricao: "GELATINA EM FOLHAS PARA FINS ALIMENTARES; PRODUCAO DE",
  },
  { cnae: "1099699", descricao: "ALBUMINA DE OVO; FABRICACAO DE" },
  {
    cnae: "1099699",
    descricao: "PRODUTOS A BASE DE MEL OU DE MEL ARTIFICIAL; FABRICACAO DE",
  },
  {
    cnae: "1099699",
    descricao:
      "PRODUTOS ALIMENTARES A BASE DE SOJA (QUEIJO, MASSA FRITA, ETC); FABRICACAO DE",
  },
  {
    cnae: "1099699",
    descricao:
      "PREPARACOES SALGADAS PARA APERITIVOS (TORRESMINHO, ETC); PRODUCAO DE",
  },
  {
    cnae: "1099699",
    descricao: "EXTRATOS E SUCOS DE CARNES DE PEIXES; PRODUCAO DE",
  },
  {
    cnae: "1099699",
    descricao:
      "SOPAS EM ESTADO LIQUIDO OU EM PO, SOPAS CONGELADAS E EM TABLETES; FABRICACAO DE",
  },
  {
    cnae: "1099699",
    descricao: "ALIMENTOS PARA FINS NUTRICIONAIS; PRODUCAO DE",
  },
  {
    cnae: "1099699",
    descricao: "SAL DE COZINHA REFINADO, IODADO; PREPARACAO DE",
  },
  {
    cnae: "1099699",
    descricao: "PROTEINA TEXTURIZADA DE SOJA (CARNE DE SOJA); FABRICACAO DE",
  },
  { cnae: "1099699", descricao: "PECTINA; FABRICACAO DE" },
  {
    cnae: "1099699",
    descricao:
      "LEITE OU QUEIJO DE SOJA OU DE OUTRO SUBSTITUTO VEGETAL DO LEITE; FABRICACAO DE",
  },
  { cnae: "1099699", descricao: "DOCES E GELEIAS HOMOGENEIZADOS; PRODUCAO DE" },
  { cnae: "2063100", descricao: "BATONS; FABRICACAO DE" },
  {
    cnae: "1099699",
    descricao:
      "OVO PREPARADO INDUSTRIALMENTE (PASTEURIZADO, DESIDRATADO, ETC.); FABRICACAO DE",
  },
  {
    cnae: "11119",
    descricao: "FABRICACAO DE AGUARDENTES E OUTRAS BEBIDAS DESTILADAS",
  },
  {
    cnae: "1111901",
    descricao: "AGUARDENTE DE CANA DE ACUCAR; PADRONIZACAO DE",
  },
  {
    cnae: "1111901",
    descricao: "BORRAS OU DESPERDICIOS DAS DESTILARIAS; OBTENCAO DE",
  },
  { cnae: "1111901", descricao: "CACHACA OU CANINHA; PRODUCAO DE" },
  {
    cnae: "1111901",
    descricao: "AGUARDENTE DESNATURADA (IMPROPRIA PARA CONSUMO); PRODUCAO DE",
  },
  { cnae: "1111901", descricao: "AGUARDENTE DE CANA DE ACUCAR; FABRICACAO DE" },
  {
    cnae: "1111902",
    descricao: "BEBIDAS ALCOOLICAS REFRESCANTES (COOLER); FABRICACAO DE",
  },
  { cnae: "1111902", descricao: "BAGACEIRA; FABRICACAO DE" },
  { cnae: "1111902", descricao: "AGUARDENTES COMPOSTAS; FABRICACAO DE" },
  { cnae: "1111902", descricao: "GENEBRA; PRODUCAO DE" },
  { cnae: "1111902", descricao: "AGUARDENTE DE CEREAIS; FABRICACAO DE" },
  {
    cnae: "1111902",
    descricao: "APERITIVOS E AMARGOS (FERNET, BITTER, ETC.); FABRICACAO DE",
  },
  { cnae: "1111902", descricao: "RUM; PRODUCAO DE" },
  { cnae: "1111902", descricao: "BEBIDAS DESTILADAS; FABRICACAO DE" },
  { cnae: "1111902", descricao: "CONHAQUE; FABRICACAO DE" },
  { cnae: "1111902", descricao: "LICORES OU CREMES; FABRICACAO DE" },
  {
    cnae: "1111902",
    descricao: "AGUARDENTE DE UVAS (BAGACEIRA, GRASPA, ETC.); FABRICACAO DE",
  },
  {
    cnae: "1111902",
    descricao: "EXTRATOS E INFUSOES PARA BEBIDAS ALCOOLICAS; FABRICACAO DE",
  },
  { cnae: "1111902", descricao: "AGUARDENTE DE FRUTAS; FABRICACAO DE" },
  { cnae: "1111902", descricao: "BATIDAS; FABRICACAO DE" },
  {
    cnae: "1111902",
    descricao: "GIM, UISQUE, VODCA E OUTROS DESTILADOS; FABRICACAO DE",
  },
  { cnae: "11127", descricao: "FABRICACAO DE VINHO" },
  { cnae: "1112700", descricao: "QUINADOS; FABRICACAO DE" },
  { cnae: "1112700", descricao: "ESPUMANTES; FABRICACAO DE" },
  {
    cnae: "1112700",
    descricao: "MISTURAS DE BEBIDAS FERMENTADAS; PRODUCAO DE",
  },
  {
    cnae: "1112700",
    descricao: "BEBIDAS FERMENTADAS MAS NAO DESTILADAS, FABRICACAO DE",
  },
  { cnae: "1112700", descricao: "FILTRADO DOCE; FABRICACAO DE" },
  { cnae: "1112700", descricao: "VINICOLA" },
  {
    cnae: "1112700",
    descricao:
      "VINHOS DE UVA LICOROSOS E COMPOSTOS (INCLUSIVE VERMUTE); FABRICACAO DE",
  },
  { cnae: "1112700", descricao: "CHAMPANHE; FABRICACAO DE" },
  {
    cnae: "1112700",
    descricao: "VINHOS DE ESSENCIAS ARTIFICIAIS; FABRICACAO DE",
  },
  { cnae: "1112700", descricao: "VINHOS DE FRUTAS; FABRICACAO DE" },
  { cnae: "1112700", descricao: "SIDRA; FABRICACAO DE" },
  { cnae: "1112700", descricao: "CHAMPANHA; FABRICACAO DE" },
  {
    cnae: "1112700",
    descricao:
      "PERADA, HIDROMEL E OUTRAS BEBIDAS FERMENTADAS MAS NAO DESTILADAS, FABRICACAO DE",
  },
  { cnae: "1112700", descricao: "MOSTO DE UVAS, FERMENTADO; FABRICACAO DE" },
  {
    cnae: "1112700",
    descricao:
      "VINHOS DE UVA, PROCESSADOS DO MOSTO E DE VINHOS INACABADOS; FABRICACAO DE",
  },
  {
    cnae: "1112700",
    descricao: "VINHOS DE UVA, PROCESSADOS DIRETAMENTE DA UVA; FABRICACAO DE",
  },
  { cnae: "1112700", descricao: "VINICULTURA (FABRICACAO DE VINHOS)" },
  { cnae: "1112700", descricao: "BORRAS DE VINHO; OBTENCAO DE" },
  {
    cnae: "1112700",
    descricao:
      "AS MISTURAS DE BEBIDAS ALCOOLICAS FERMENTADAS COM BEBIDAS NAO FERMENTADAS, FABRICACAO DE",
  },
  {
    cnae: "1112700",
    descricao: "VINHOS COM BAIXO TEOR ALCOOLICO, FABRICACAO DE",
  },
  { cnae: "1112700", descricao: "VINHOS DE MESA; FABRICACAO DE" },
  {
    cnae: "1112700",
    descricao: "AS MISTURAS DE BEBIDAS ALCOOLICAS FERMENTADAS, FABRICACAO DE",
  },
  { cnae: "1112700", descricao: "SAQUE; FABRICACAO DE" },
  { cnae: "1112700", descricao: "VINHOS SEM ALCOOL, FABRICACAO DE" },
  { cnae: "11135", descricao: "FABRICACAO DE MALTE, CERVEJAS E CHOPES" },
  { cnae: "1113501", descricao: "MALTE UISQUE; FABRICACAO DE" },
  { cnae: "1113501", descricao: "MALTE TORRADO OU NAO; FABRICACAO DE" },
  {
    cnae: "1113502",
    descricao: "BORRAS OU DESPERDICIOS DA INDUSTRIA DE CERVEJA; OBTENCAO DE",
  },
  { cnae: "1113502", descricao: "CERVEJAS; FABRICACAO DE" },
  { cnae: "1113502", descricao: "CERVEJAS SEM ALCOOL; FABRICACAO DE" },
  { cnae: "1113502", descricao: "CHOPE; FABRICACAO DE" },
  { cnae: "11216", descricao: "FABRICACAO DE AGUAS ENVASADAS" },
  { cnae: "1121600", descricao: "AGUAS GASEIFICADAS; PRODUCAO DE" },
  {
    cnae: "1121600",
    descricao: "AGUAS MINERAIS E GASOSAS ARTIFICIAIS; FABRICACAO DE",
  },
  {
    cnae: "1121600",
    descricao: "AGUAS MINERAIS; EXTRACAO, ENGARRAFAMENTO E GASEIFICACAO DE",
  },
  {
    cnae: "1121600",
    descricao:
      "AGUAS POTAVEIS ADICIONADAS DE SAIS MINERAIS OU OUTRAS AGUAS ARTIFICIAIS, SEM ADOCANTES OU AROMATIZANTES; FABRICACAO DE",
  },
  {
    cnae: "1121600",
    descricao:
      "AGUA COMUM PURIFICADA, ADICIONADA OU NAO DE SAIS MINERAIS; PRODUCAO DE",
  },
  {
    cnae: "1121600",
    descricao: "AGUAS MINERAIS NATURAIS E ARTIFICIAIS; FABRICACAO DE",
  },
  {
    cnae: "11224",
    descricao: "FABRICACAO DE REFRIGERANTES E DE OUTRAS BEBIDAS NAOALCOOLICAS",
  },
  { cnae: "1122401", descricao: "REFRIGERANTES; FABRICACAO DE" },
  {
    cnae: "1122401",
    descricao:
      "PREPARACOES EM PO OU EM XAROPE PARA ELABORACAO DE REFRIGERANTES, PARA FINS INDUSTRIAIS; PRODUCAO DE",
  },
  { cnae: "1122402", descricao: "CHAS PRONTOS PARA CONSUMO, FABRICACAO DE" },
  { cnae: "1122402", descricao: "CHA MATE PRONTO PARA CONSUMO; PRODUCAO DE" },
  { cnae: "1122403", descricao: "XAROPES PARA REFRESCOS; FABRICACAO DE" },
  { cnae: "1122403", descricao: "GUARANA NATURAL EM XAROPE; FABRICACAO DE" },
  { cnae: "1122403", descricao: "POS PARA REFRESCOS; FABRICACAO DE" },
  { cnae: "1122403", descricao: "REFRESCOS ARTIFICIAIS; PRODUCAO DE" },
  { cnae: "1122404", descricao: "BEBIDAS ISOTONICAS; FABRICACAO DE" },
  {
    cnae: "1122499",
    descricao: "GUARANA NATURAL PRONTO PARA BEBER; FABRICACAO DE",
  },
  { cnae: "1122499", descricao: "AGUAS AROMATIZADAS; FABRICACAO DE" },
  {
    cnae: "1122499",
    descricao: "GUARANA NATURAL PRONTO PARA CONSUMO; FABRICACAO DE",
  },
  { cnae: "1122499", descricao: "BEBIDAS ENERGETICAS; FABRICACAO DE" },
  { cnae: "12107", descricao: "PROCESSAMENTO INDUSTRIAL DO FUMO" },
  {
    cnae: "1210700",
    descricao:
      "FUMO PROCESSADO INDUSTRIALMENTE (DESTALAMENTO E OUTROS BENEFICIAMENTOS ELABORADOS EM UNIDADES INDUSTRIAIS); PRODUCAO DE",
  },
  {
    cnae: "1210700",
    descricao:
      "PROCESSAMENTO DE FOLHAS DE FUMO (DESTALAMENTO E OUTROS BENEFICIAMENTOS ELABORADOS EM UNIDADES INDUSTRIAIS); SERVICO DE",
  },
  { cnae: "12204", descricao: "FABRICACAO DE PRODUTOS DO FUMO" },
  { cnae: "1220401", descricao: "CIGARROS; FABRICACAO DE" },
  { cnae: "1220402", descricao: "CIGARRILHAS; FABRICACAO DE" },
  { cnae: "1220402", descricao: "CHARUTOS; FABRICACAO DE" },
  { cnae: "1220402", descricao: "CIGARRO DE PALHA; FABRICACAO DE" },
  { cnae: "1220403", descricao: "FILTROS PARA CIGARROS; FABRICACAO DE" },
  { cnae: "1220499", descricao: "RAPE; FABRICACAO DE" },
  { cnae: "1220499", descricao: "FUMO EM ROLO OU EM CORDA; FABRICACAO DE" },
  { cnae: "1220499", descricao: "FUMO DESFIADO; FABRICACAO DE" },
  { cnae: "1220499", descricao: "FUMO EM PASTA; FABRICACAO DE" },
  { cnae: "1220499", descricao: "FUMO PICADO; FABRICACAO DE" },
  {
    cnae: "1220499",
    descricao: "PALHA PREPARADA OU MANUFATURADA PARA CIGARROS; FABRICACAO DE",
  },
  { cnae: "1220499", descricao: "FUMO EM CORDA; FABRICACAO DE" },
  { cnae: "13111", descricao: "PREPARACAO E FIACAO DE FIBRAS DE ALGODAO" },
  { cnae: "1311100", descricao: "FIACAO DE ALGODAO" },
  { cnae: "1311100", descricao: "ALGODAO EM PLUMA; FABRICACAO DE" },
  {
    cnae: "1311100",
    descricao: "RESIDUOS DO BENEFICIAMENTO DA FIBRA DE ALGODAO",
  },
  {
    cnae: "1311100",
    descricao: "FIOS DE ALGODAO SINGELOS (SIMPLES); FABRICACAO DE",
  },
  { cnae: "1311100", descricao: "FIACAO DE ALGODAO; SERVICO DE" },
  {
    cnae: "1311100",
    descricao:
      "RESIDUOS (ESTOPAS, DESPERDICIOS) DA FIACAO DO ALGODAO; OBTENCAO DE",
  },
  {
    cnae: "1311100",
    descricao: "FIOS DE ALGODAO, BENEFICIADOS OU NAO; FABRICACAO DE",
  },
  { cnae: "1311100", descricao: "FIBRAS DE ALGODAO BENEFICIADAS; PRODUCAO DE" },
  {
    cnae: "1311100",
    descricao:
      "FIO DE ALGODAO CRU OU ACABADO (BENEFICIADO), INCLUSIVE MESCLA; FABRICACAO DE",
  },
  { cnae: "1311100", descricao: "ALGODAO EM PASTA; FABRICACAO DE" },
  { cnae: "1311100", descricao: "ALGODAO CARDADO OU PENTEADO; PRODUCAO DE" },
  {
    cnae: "1311100",
    descricao:
      "FIOS DE ALGODAO RETORCIDOS OU RETORCIDOS MULTIPLOS; FABRICACAO DE",
  },
  {
    cnae: "1311100",
    descricao:
      "FIBRA DE ALGODAO MOLDADA, PARA ESTOFAMENTO (MANTAS DE LINTERES, ETC.); FABRICACAO DE",
  },
  {
    cnae: "1311100",
    descricao:
      "ALGODAO CARDADO, PENTEADO, ETC.; BENEFICIAMENTO DE (EM ESTABELECIMENTO NAO AGRICOLA)",
  },
  {
    cnae: "1311100",
    descricao: "ALGODAO, DESCAROCAMENTO DE (EM ESTABELECIMENTO NAO AGRICOLA)",
  },
  {
    cnae: "1311100",
    descricao:
      "FIBRAS DE ALGODAO; BENEFICIAMENTO DE (EM ESTABELECIMENTO NAO AGRICOLA)",
  },
  {
    cnae: "13120",
    descricao: "PREPARACAO E FIACAO DE FIBRAS TEXTEIS NATURAIS, EXCETO ALGODAO",
  },
  {
    cnae: "1312000",
    descricao:
      "FIO DE LINHO CRU OU ACABADO (BENEFICIADO), INCLUSIVE MESCLA; FABRICACAO DE",
  },
  { cnae: "1312000", descricao: "LANOLINA; FABRICACAO DE" },
  { cnae: "1312000", descricao: "LA; BENEFICIAMENTO DE" },
  { cnae: "1312000", descricao: "RAMI; FIACAO DE" },
  { cnae: "1312000", descricao: "RESIDUOS DE SEDA" },
  { cnae: "1312000", descricao: "DESPERDICIOS DE LA" },
  {
    cnae: "1312000",
    descricao: "RESIDUOS DA FIACAO DE FIBRAS TEXTEIS NATURAIS, EXCETO ALGODAO",
  },
  {
    cnae: "1312000",
    descricao: "LA (CARDADA, PENTEADA, TOPS, ETC); FABRICACAO DE",
  },
  { cnae: "1312000", descricao: "FIBRA DE LA LAVADA; PRODUCAO DE" },
  { cnae: "1312000", descricao: "FIBRAS DE SISAL (AGAVE); BENEFICIAMENTO DE" },
  {
    cnae: "1312000",
    descricao:
      "FIO DE RAMI CRU OU ACABADO (BENEFICIADO), INCLUSIVE MESCLA; FABRICACAO DE",
  },
  {
    cnae: "1312000",
    descricao:
      "FIO DE JUTA CRU OU ACABADO (BENEFICIADO , INCLUSIVE MESCLA; FABRICACAO DE",
  },
  {
    cnae: "1312000",
    descricao: "FIBRAS TEXTEIS VEGETAIS (EXCETO ALGODAO); BENEFICIAMENTO DE",
  },
  {
    cnae: "1312000",
    descricao: "FIOS DE CAROA, BENEFICIADOS OU NAO; FABRICACAO DE",
  },
  {
    cnae: "1312000",
    descricao:
      "FIO DE SISAL (AGAVE) CRU OU ACABADO (BENEFICIADO), INCLUSIVE MESCLA; FABRICACAO DE",
  },
  {
    cnae: "1312000",
    descricao:
      "ESTOPAS OU OUTROS RESIDUOS DO BENEFICIAMENTO DE FIBRAS TEXTEIS NATURAIS, EXCETO ALGODAO",
  },
  { cnae: "1312000", descricao: "PELOS FINOS E GROSSEIROS; PRODUCAO DE" },
  { cnae: "1312000", descricao: "DESPERDICIOS DE SEDA" },
  { cnae: "1312000", descricao: "SEDA; FIACAO DE" },
  { cnae: "1312000", descricao: "JUTA; FIACAO DE" },
  { cnae: "1312000", descricao: "CRINAS, PELOS E CERDAS; BENEFICIAMENTO DE" },
  { cnae: "1312000", descricao: "FIBRAS DE MALVA; BENEFICIAMENTO DE" },
  {
    cnae: "1312000",
    descricao: "FIBRAS DE CANHAMO BENEFICIADAS; FABRICACAO DE",
  },
  { cnae: "1312000", descricao: "FIOS DE PAPEL; FABRICACAO DE" },
  { cnae: "1312000", descricao: "CAROA; FIACAO DE" },
  { cnae: "1312000", descricao: "CRINA VEGETAL; BENEFICIAMENTO DE" },
  {
    cnae: "1312000",
    descricao:
      "SUBPRODUTOS RESULTANTES DO BENEFICIAMENTO DE FIBRAS TEXTEIS; OBTENCAO DE",
  },
  { cnae: "1312000", descricao: "LINHO; FIACAO DE" },
  {
    cnae: "1312000",
    descricao:
      "FIO DE SEDA CRU OU ACABADO (BENEFICIADO), INCLUSIVE MESCLA; FABRICACAO DE",
  },
  { cnae: "1312000", descricao: "SEDA CRUA NAO FIADA; PRODUCAO DE" },
  { cnae: "1312000", descricao: "FIBRAS DE RAMI; BENEFICIAMENTO DE" },
  { cnae: "1312000", descricao: "FIBRAS DE LINHO; BENEFICIAMENTO DE" },
  { cnae: "1312000", descricao: "SUARDA; PRODUCAO DE" },
  { cnae: "1312000", descricao: "CANHAMO; FIACAO DE" },
  {
    cnae: "1312000",
    descricao:
      "FIO DE LA CRU OU ACABADO (BENEFICIADO), INCLUSIVE MESCLA; FABRICACAO DE",
  },
  { cnae: "1312000", descricao: "FIBRAS DE JUTA; BENEFICIAMENTO DE" },
  { cnae: "1312000", descricao: "RESIDUOS DE LA" },
  { cnae: "1312000", descricao: "SISAL; FIACAO DE" },
  { cnae: "1312000", descricao: "LA; FIACAO DE" },
  { cnae: "1312000", descricao: "FIBRAS DE COCO; BENEFICIAMENTO DE" },
  { cnae: "13138", descricao: "FIACAO DE FIBRAS ARTIFICIAIS E SINTETICAS" },
  {
    cnae: "1313800",
    descricao:
      "FIBRAS SINTETICAS DESCONTINUAS CARDADAS, PENTEADAS OU PREPARADAS DE OUTRO MODO PARA FIACAO; FABRICACAO DE",
  },
  {
    cnae: "1313800",
    descricao: "RESIDUOS DA FIACAO DE FIBRAS ARTIFICIAIS OU SINTETICAS",
  },
  {
    cnae: "1313800",
    descricao: "FIACAO DE FIBRAS ARTIFICIAIS OU SINTETICAS; SERVICO DE",
  },
  {
    cnae: "1313800",
    descricao:
      "DESPERDICIOS DA FIACAO DE FIBRAS ARTIFICIAIS OU SINTETICAS, DESCONTINUAS; FABRICACAO DE",
  },
  {
    cnae: "1313800",
    descricao:
      "FIOS DE NYLON (NAILON) OU DE OUTRAS POLIAMIDAS, DE POLIESTER OU DE OUTROS FILAMENTOS SINTETICOS; FABRICACAO DE",
  },
  {
    cnae: "1313800",
    descricao:
      "FIO DE FIBRAS ARTIFICIAIS CRU OU ACABADO (BENEFICIADO), INCLUSIVE MESCLAS; FABRICACAO DE",
  },
  {
    cnae: "1313800",
    descricao:
      "FIOS DE FILAMENTOS ARTIFICIAIS OU SINTETICOS  (EXCETO LINHAS PARA COSTURAR), ACONDICIONADOS PARA VENDA A VAREJO; FABRICACAO DE",
  },
  {
    cnae: "1313800",
    descricao:
      "FIO DE FIBRAS SINTETICAS CRU OU ACABADO (BENEFICIADO), INCLUSIVE MESCLA; FABRICACAO DE",
  },
  { cnae: "13146", descricao: "FABRICACAO DE LINHAS PARA COSTURAR E BORDAR" },
  {
    cnae: "1314600",
    descricao: "LINHAS DE ALGODAO PARA COSTURAR E BORDAR; FABRICACAO DE",
  },
  {
    cnae: "1314600",
    descricao:
      "LINHAS DE FIOS ARTIFICIAIS OU SINTETICOS PARA COSTURAR E BORDAR; FABRICACAO DE",
  },
  {
    cnae: "1314600",
    descricao: "LINHAS DE LINHO E RAMI PARA COSTURAR E BORDAR; FABRICACAO DE",
  },
  { cnae: "1314600", descricao: "LINHAS PARA CROCHE; FABRICACAO DE" },
  {
    cnae: "1314600",
    descricao: "LINHAS DE LA PARA COSTURAR E BORDAR; FABRICACAO DE",
  },
  {
    cnae: "1314600",
    descricao: "LINHAS PARA COSTURAR E BORDAR; FABRICACAO DE",
  },
  {
    cnae: "1314600",
    descricao: "LINHAS DE SEDA ANIMAL PARA COSTURAR E BORDAR; FABRICACAO DE",
  },
  { cnae: "13219", descricao: "TECELAGEM DE FIOS DE ALGODAO" },
  {
    cnae: "1321900",
    descricao:
      "TOALHAS DE BANHO OU DE ROSTO DE TECIDOS DE ALGODAO; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1321900",
    descricao:
      "ARTEFATOS DE TECIDOS DE ALGODAO PARA USO DOMESTICO; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  { cnae: "1321900", descricao: "TECIDO DE INDIGO; FABRICACAO DE" },
  {
    cnae: "1321900",
    descricao:
      "COBERTORES, MANTAS OU EDREDONS DE ALGODAO; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1321900",
    descricao:
      "TOALHAS DE BANHO DE TECIDOS DE ALGODAO; CONFECCAO DE, QUANDO INTEGRADA A TECELAGEM",
  },
  { cnae: "1321900", descricao: "TECIDOS TUFADOS; FABRICACAO DE" },
  { cnae: "1321900", descricao: "TECELAGEM PLANA DE ALGODAO; SERVICO DE" },
  {
    cnae: "1321900",
    descricao: "TECIDOS ATOALHADOS (FELPUDOS) DE ALGODAO; FABRICACAO DE",
  },
  { cnae: "1321900", descricao: "TECIDOS RENDADOS DE ALGODAO; FABRICACAO DE" },
  {
    cnae: "1321900",
    descricao:
      "LENCOIS E / OU FRONHAS DE TECIDOS DE ALGODAO; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1321900",
    descricao:
      "TOALHAS DE MESA OU DE COZINHA DE TECIDOS DE ALGODAO; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  { cnae: "1330800", descricao: "TECIDOS DE MALHA; FABRICACAO DE" },
  {
    cnae: "1321900",
    descricao:
      "SACOS DE ALGODAO PARA EMBALAGEM; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  { cnae: "1321900", descricao: "TECIDOS DE ALGODAO TIPO GAZE; FABRICACAO DE" },
  {
    cnae: "1321900",
    descricao:
      "ROUPAS DE CAMA, MESA OU BANHO DE TECIDOS DE ALGODAO; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1321900",
    descricao:
      "TECIDOS PLANOS DE ALGODAO, INCLUSIVE MESCLAS, CRUS OU ACABADOS (BENEFICIADOS); FABRICACAO DE",
  },
  {
    cnae: "1321900",
    descricao:
      "ARTEFATOS DE TECIDOS DE ALGODAO PARA CAMA, MESA OU BANHO; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1321900",
    descricao: "VELUDOS E PELUCIAS DE ALGODAO; FABRICACAO DE",
  },
  {
    cnae: "1321900",
    descricao:
      "COLCHAS DE ALGODAO; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1321900",
    descricao:
      "ARTEFATOS DE TECIDOS DE ALGODAO PARA COPA E COZINHA; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1321900",
    descricao:
      "TECIDO ALGODAO TINTOS OU ESTAMPADOS, INCLUSIVE MESCLA; FABRICACAO DE",
  },
  {
    cnae: "13227",
    descricao: "TECELAGEM DE FIOS DE FIBRAS TEXTEIS NATURAIS, EXCETO ALGODAO",
  },
  {
    cnae: "1322700",
    descricao:
      "TECIDO DE SEDA, CRU OU ACABADO (BENEFICIADO), INCLUSIVE MESCLA; FABRICACAO DE",
  },
  {
    cnae: "1322700",
    descricao:
      "ROUPAS DE CAMA, BANHO, MESA, COPA OU COZINHA DE TECIDOS DE FIBRAS NATURAIS (EXCETO ALGODAO); CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  { cnae: "1322700", descricao: "TELAS DE JUTA; FABRICACAO DE" },
  {
    cnae: "1322700",
    descricao:
      "TOALHAS DE MESA OU DE COZINHA DE TECIDOS DE FIBRAS NATURAIS (EXCETO ALGODAO); CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1322700",
    descricao:
      "TECIDO DE LA, CRU OU ACABADO (BENEFICIADO), INCLUSIVE MESCLA; FABRICACAO DE",
  },
  {
    cnae: "1322700",
    descricao:
      "TECIDOS RENDADOS DE FIBRAS NATURAIS (EXCETO ALGODAO); FABRICACAO DE",
  },
  { cnae: "1322700", descricao: "CANHAMO; TECELAGEM DE" },
  {
    cnae: "1322700",
    descricao:
      "LENCOIS OU FRONHAS DE TECIDOS DE FIBRAS NATURAIS (EXCETO ALGODAO); CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1322700",
    descricao:
      "TECIDO DE LINHO, CRU OU ACABADO (BENEFICIADO), INCLUSIVE MESCLA; FABRICACAO DE",
  },
  {
    cnae: "1322700",
    descricao:
      "ARTEFATOS DE TECIDOS DE FIBRAS NATURAIS (EXCETO ALGODAO) PARA USO DOMESTICO; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1322700",
    descricao:
      "TECIDO DE SISAL (AGAVE), CRU OU ACABADO (BENEFICIADO), INCLUSIVE MESCLA; FABRICACAO DE",
  },
  {
    cnae: "1322700",
    descricao:
      "TECIDO DE RAMI, CRU OU ACABADO (BENEFICIADO), INCLUSIVE MESCLA; FABRICACAO DE",
  },
  {
    cnae: "1322700",
    descricao:
      "COBERTORES, MANTAS E EDREDONS DE FIBRAS NATURAIS (EXCETO ALGODAO); CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  { cnae: "1322700", descricao: "FIBRAS TEXTEIS NATURAIS; TECELAGEM DE" },
  { cnae: "1322700", descricao: "TECIDO DE CETIM; FABRICACAO DE" },
  { cnae: "1322700", descricao: "LINHO; TECELAGEM DE" },
  { cnae: "1322700", descricao: "CAROA; TECELAGEM DE" },
  {
    cnae: "1322700",
    descricao:
      "FRONHAS DE TECIDOS DE FIBRAS NATURAIS (EXCETO ALGODAO); CONFECCAO DE, QUANDO INTEGRADA A TECELAGEM",
  },
  { cnae: "1322700", descricao: "LA; TECELAGEM DE" },
  { cnae: "1322700", descricao: "SEDA; TECELAGEM DE" },
  { cnae: "1322700", descricao: "TECIDOS DE CRINA; FABRICACAO DE" },
  { cnae: "1322700", descricao: "JUTA; TECELAGEM DE" },
  {
    cnae: "1322700",
    descricao:
      "SACOS DE JUTA OU DE OUTRAS FIBRAS TEXTEIS NATURAIS PARA EMBALAGEM; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  { cnae: "1322700", descricao: "RAMI; TECELAGEM DE" },
  {
    cnae: "1322700",
    descricao:
      "TECIDO DE JUTA,  CRU OU ACABADO (BENEFICIADO), INCLUSIVE MESCLAS; FABRICACAO DE",
  },
  {
    cnae: "1322700",
    descricao:
      "TECIDOS DE PELOS GROSSEIROS OU DE CRINA, PUROS OU MISTOS; FABRICACAO DE",
  },
  {
    cnae: "1322700",
    descricao:
      "TOALHAS DE BANHO OU DE ROSTO DE TECIDOS DE FIBRAS NATURAIS (EXCETO ALGODAO); CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1322700",
    descricao:
      "COLCHAS DE FIBRAS NATURAIS (EXCETO ALGODAO); CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1322700",
    descricao:
      "ARTEFATOS DE TECIDOS FIBRAS NATURAIS (EXCETO ALGODAO), PARA BANHO, CAMA E MESA, COPA E COZINHA; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1322700",
    descricao: "TECELAGEM DE FIOS DE FIBRAS TEXTEIS NATURAIS; SERVICO DE",
  },
  { cnae: "1322700", descricao: "MALVA; TECELAGEM DE" },
  {
    cnae: "1322700",
    descricao:
      "VELUDOS E PELUCIAS DE MATERIAS TEXTEIS NATURAIS - EXCETO ALGODAO",
  },
  {
    cnae: "13235",
    descricao: "TECELAGEM DE FIOS DE FIBRAS ARTIFICIAIS E SINTETICAS",
  },
  { cnae: "4211101", descricao: "LAMA ASFALTICA, APLICACAO DE" },
  {
    cnae: "1323500",
    descricao:
      "TECIDOS DE FILAMENTOS CONTINUOS E DE FIOS DE FIBRAS ARTIFICIAIS OU SINTETICAS, CRUS OU ACABADOS (BENEFICIADOS) , INCLUSIVE MESCLA; FABRICACAO DE",
  },
  {
    cnae: "1323500",
    descricao:
      "VELUDOS E PELUCIAS DE FIBRAS ARTIFICIAIS OU SINTETICAS; FABRICACAO DE",
  },
  {
    cnae: "1323500",
    descricao: "TECIDOS RENDADOS DE FIBRAS SINTETICAS; FABRICACAO DE",
  },
  { cnae: "1323500", descricao: "TECIDO VISCOSE; FABRICACAO DE" },
  {
    cnae: "1323500",
    descricao:
      "ROUPAS DE CAMA, MESA OU BANHO DE TECIDOS DE FIBRAS ARTIFICIAIS OU SINTETICAS; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1323500",
    descricao:
      "LENCOIS OU FRONHAS DE TECIDOS DE FIBRAS ARTIFICIAIS OU SINTETICAS; CONFECCAO DE, QUANDO INTEGRADA A TECELAGEM",
  },
  {
    cnae: "1323500",
    descricao:
      "ARTEFATOS DE TECIDOS DE FIBRAS ARTIFICIAIS OU SINTETICAS, PARA COPA E COZINHA; CONFECCAO DE , QUANDO INTEGRADA A TECELAGEM",
  },
  {
    cnae: "1323500",
    descricao:
      "ARTEFATOS DE TECIDOS ARTIFICIAIS OU SINTETICOS, PARA BANHO, CAMA E MESA, COPA E COZINHA; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1323500",
    descricao:
      "COBERTORES, MANTAS E EDREDONS DE TECIDOSA DE FIBRAS ARTIFICIAIS OU SINTETICAS; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  { cnae: "1323500", descricao: "TECIDO DE FIBRA DE VIDRO; FABRICACAO DE" },
  {
    cnae: "1323500",
    descricao:
      "SACOS DE FIOS DE RAFIA, POLIPROPILENO E OUTROS MATERIAIS PLASTICOS TEXTEIS; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1323500",
    descricao:
      "TECIDOS DE FILAMENTOS DE ARAMIDA E/OU DE POLIAMIDA DE ALTA TENACIDADE, SEM FIO DE BORRACHA; FABRICACAO DE",
  },
  {
    cnae: "1323500",
    descricao:
      "TOALHAS DE MESA OU DE COZINHA DE TECIDOS ARTIFICIAIS OU SINTETICOS; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1323500",
    descricao:
      "MANTAS DE FIBRAS ARTIFICIAIS OU SINTETICAS AGULHADAS E/OU PRENSADAS; FABRICACAO DE",
  },
  {
    cnae: "1323500",
    descricao:
      "TOALHAS DE BANHO OU DE ROSTO DE TECIDOS ARTIFICIAIS OU SINTETICOS; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1323500",
    descricao:
      "COLCHAS DE FIBRAS ARTIFICIAIS OU SINTETICAS; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  { cnae: "1323500", descricao: "TECIDO DE POLIESTER; FABRICACAO DE" },
  {
    cnae: "1323500",
    descricao: "TECIDO DE FIOS DE RAFIA OU LAMINAS SINTETICAS; FABRICACAO DE",
  },
  {
    cnae: "1323500",
    descricao:
      "FIOS DE FILAMENTOS CONTINUOS ARTIFICIAIS OU SINTETICAS; TECELAGEM DE",
  },
  {
    cnae: "1323500",
    descricao:
      "ARTEFATOS DE TECIDOS ARTIFICIAIS OU SINTETICOS PARA USO DOMESTICO; CONFECCAO DE (QUANDO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1323500",
    descricao:
      "SACOS, INCLUSIVE CONTENTORES FLEXIVEIS (BIG-BAGS) PARA EMBALAGEM, DE MATERIAS TEXTEIS ARTIFICIAIS OU SINTETICAS, QUANDO INTEGRADOS A TECELAGEM; FABRICACAO DE",
  },
  {
    cnae: "1323500",
    descricao: "TECIDOS DE FIBRAS SINTETICAS DESCONTINUAS; FABRICACAO DE",
  },
  { cnae: "13308", descricao: "FABRICACAO DE TECIDOS DE MALHA" },
  {
    cnae: "1330800",
    descricao:
      "TECIDOS DE MALHA DE ALGODAO COM ELASTOMERO, CIRCULAR; FABRICACAO DE",
  },
  {
    cnae: "1330800",
    descricao:
      "TECIDOS DE MALHA DE MATERIAS TEXTEIS NATURAIS, CIRCULAR; FABRICACAO DE",
  },
  {
    cnae: "1330800",
    descricao:
      "TECIDOS DE MALHA DE FIBRAS ARTIFICIAIS OU SINTETICAS, CIRCULAR; FABRICACAO DE",
  },
  {
    cnae: "1330800",
    descricao:
      "TECIDOS DE MALHA-URDIDURA OU DE OUTROS TIPOS, DE MATERIAS TEXTEIS NATURAIS, FABRICACAO DE",
  },
  {
    cnae: "1330800",
    descricao: "TECIDOS DE MALHA DE ALGODAO, CIRCULAR; FABRICACAO DE",
  },
  {
    cnae: "1330800",
    descricao:
      "TECIDOS DE MALHA-URDIDURA OU DE OUTROS TIPOS, DE FIBRAS ARTIFICIAIS OU SINTETICAS, FABRICACAO DE",
  },
  {
    cnae: "1330800",
    descricao:
      "TECIDOS DE MALHA-URDIDURA OU DE OUTROS TIPOS, DE ALGODAO, FABRICACAO DE",
  },
  {
    cnae: "13405",
    descricao: "ACABAMENTOS EM FIOS, TECIDOS E ARTEFATOS TEXTEIS",
  },
  {
    cnae: "1340501",
    descricao:
      "ESTAMPARIA E TEXTURIZACAO EM ARTIGOS TEXTEIS, INCLUSIVE PECAS DO VESTUARIO; SERVICOS DE",
  },
  {
    cnae: "1340501",
    descricao: "ESTAMPARIA E TEXTURIZACAO EM FIOS OU TECIDOS",
  },
  {
    cnae: "1340502",
    descricao:
      "BRANQUEAMENTO EM FIOS, TECIDOS E ARTIGOS TEXTEIS E EM PECAS DO VESTUARIO",
  },
  {
    cnae: "1340502",
    descricao:
      "ALVEJAMENTO, TINGIMENTO E TORCAO EM FIOS, TECIDOS E ARTIGOS TEXTEIS, INCLUSIVE EM PECAS DO VESTUARIO",
  },
  {
    cnae: "1340599",
    descricao: "CUSTOMIZACAO EM ARTIGOS TEXTEIS E EM PECAS DO VESTUARIO",
  },
  {
    cnae: "1340599",
    descricao:
      "SECAGEM, VAPORIZACAO, SANFORIZACAO, ETC, EM ARTIGOS TEXTEIS E EM PECAS DO VESTUARIO",
  },
  {
    cnae: "1340599",
    descricao:
      "IMPERMEABILIZACAO, COLAGEM, ENGOMAGEM, ESTAMPARIA E SEMELHANTES EM ARTIGOS TEXTEIS E EM PECAS DO VESTUARIO",
  },
  {
    cnae: "1340599",
    descricao:
      "BORDADOS CONFECCIONADOS POR ENCOMENDA EM ROUPAS E ARTEFATOS DE TECIDOS",
  },
  {
    cnae: "1340599",
    descricao: "LAMINACAO OU RESINAGEM EM TECIDOS; SERVICO DE",
  },
  {
    cnae: "1340599",
    descricao:
      "CALANDRAGEM EM TECIDOS E ARTIGOS TEXTEIS E EM PECAS DO VESTUARIO",
  },
  {
    cnae: "1340599",
    descricao: "BORDADOS EM ARTIGOS TEXTEIS E EM PECAS DO VESTUARIO",
  },
  {
    cnae: "1340599",
    descricao:
      "BORDADOS E ACABAMENTOS SEMELHANTES EM ARTEFATOS DE TECIDOS E PECAS DO VESTUARIO;",
  },
  { cnae: "1340599", descricao: "BORDADOS; SERVICOS DE" },
  {
    cnae: "1340599",
    descricao:
      "ACABAMENTOS EM FIOS, TECIDOS E ARTIGOS TEXTEIS, INCLUSIVE EM PECAS DO VESTUARIO",
  },
  {
    cnae: "13511",
    descricao: "FABRICACAO DE ARTEFATOS TEXTEIS PARA USO DOMESTICO",
  },
  {
    cnae: "1351100",
    descricao: "COLCHAS; CONFECCAO DE (QUANDO NAO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1351100",
    descricao:
      "ARTEFATOS DE TECIDOS PARA BANHO, CAMA E MESA, COPA E COZINHA; CONFECCAO DE (QUANDO NAO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1351100",
    descricao:
      "TOALHAS DE BANHO OU DE ROSTO; CONFECCAO DE (QUANDO NAO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1351100",
    descricao:
      "SORTIDOS DE CORTES DE TECIDOS OU FIOS PARA CONFECCCIONAR TAPETES, TOALHAS DE MESA, GUARDANAPOS OU ARTEFATOS SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "1351100",
    descricao:
      "ARTEFATOS DE TECIDOS PARA USO DOMESTICO; CONFECCAO DE (QUANDO NAO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1351100",
    descricao:
      "RODILHAS E OUTROS ARTEFATOS CONFECCIONADOS COM TEXTEIS PARA LIMPEZA; FABRICACAO DE",
  },
  {
    cnae: "1351100",
    descricao:
      "PROTETORES PARA COLCHOES OU TRAVESSEIROS, CONFECCIONADOS COM TECIDOS; CONFECCAO DE",
  },
  {
    cnae: "1351100",
    descricao:
      "ARTEFATOS TEXTEIS PARA COPA E COZINHA; CONFECCAO DE, QUANDO NAO INTEGRADA A TECELAGEM",
  },
  {
    cnae: "1351100",
    descricao:
      "TOALHAS DE MESA OU DE COZINHA; CONFECCAO DE (QUANDO NAO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1351100",
    descricao:
      "LENCOIS OU FRONHAS; CONFECCAO DE (QUANDO NAO INTEGRADA A TECELAGEM)",
  },
  { cnae: "1351100", descricao: "TRAVESSEIROS E ALMOFADAS; CONFECCAO DE" },
  {
    cnae: "1351100",
    descricao:
      "MOSQUITEIROS CONFECCIONADOS COM FILO E OUTROS TECIDOS; CONFECCAO DE",
  },
  {
    cnae: "1351100",
    descricao:
      "COBERTORES, MANTAS E EDREDONS; CONFECCAO DE (QUANDO NAO INTEGRADA A TECELAGEM)",
  },
  { cnae: "1351100", descricao: "CUPULA DE TECIDO PARA ABAJUR; FABRICACAO DE" },
  { cnae: "1351100", descricao: "CORTINAS; CONFECCAO DE" },
  {
    cnae: "1351100",
    descricao: "ARTEFATOS TEXTEIS MATELASSES EM PECAS, ETC; FABRICACAO DE",
  },
  {
    cnae: "1351100",
    descricao:
      "ROUPAS DE CAMA, MESA OU BANHO; CONFECCAO DE (QUANDO NAO INTEGRADA A TECELAGEM)",
  },
  { cnae: "13529", descricao: "FABRICACAO DE ARTEFATOS DE TAPECARIA" },
  {
    cnae: "1352900",
    descricao: "TAPETES, EXCETO DE BORRACHA E PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "1352900",
    descricao: "FORRACOES PARA REVESTIMENTO DE PISOS (CARPETE); FABRICACAO DE",
  },
  { cnae: "1352900", descricao: "ARTEFATOS DE TAPECARIA; FABRICACAO DE" },
  { cnae: "1352900", descricao: "TAPECARIA; FABRICACAO DE" },
  {
    cnae: "1352900",
    descricao:
      "PARTES E PECAS DE MATERIAL TEXTIL PARA REVESTIMENTO DE VEICULOS; FABRICACAO DE",
  },
  { cnae: "1352900", descricao: "TAPECARIA PARA DECORACAO; FABRICACAO DE" },
  {
    cnae: "1352900",
    descricao: "REVESTIMENTOS PARA PAVIMENTACAO; FABRICACAO DE",
  },
  { cnae: "1352900", descricao: "TAPECARIAS FEITAS A MAO; FABRICACAO DE" },
  {
    cnae: "1352900",
    descricao: "REVESTIMENTOS DE MATERIAL TEXTIL PARA VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "1352900",
    descricao:
      "REVESTIMENTOS PARA PAVIMENTO DE CAIRO (FIBRA DE COCO); FABRICACAO DE",
  },
  { cnae: "1352900", descricao: "CARPETES; FABRICACAO DE" },
  { cnae: "13537", descricao: "FABRICACAO DE ARTEFATOS DE CORDOARIA" },
  { cnae: "1353700", descricao: "BARBANTES; FABRICACAO DE" },
  { cnae: "1353700", descricao: "ARTEFATOS DE CORDOARIA, N.E.; FABRICACAO DE" },
  { cnae: "1353700", descricao: "CORDAS, CABOS E CORDEIS; FABRICACAO DE" },
  { cnae: "1353700", descricao: "REDES DE DORMIR; FABRICACAO DE" },
  { cnae: "1353700", descricao: "CORDOARIA" },
  { cnae: "1353700", descricao: "REDES DE PESCA; FABRICACAO DE" },
  { cnae: "1353700", descricao: "REDES E TARRAFAS PARA PESCAR; FABRICACAO DE" },
  {
    cnae: "1353700",
    descricao: "CORDAS, CABOS E CORDEIS DE FIBRAS SINTETICAS; FABRICACAO DE",
  },
  {
    cnae: "1353700",
    descricao:
      "REDES DE PROTECAO (PARA VARANDAS, JANELAS, ETC), DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  {
    cnae: "1353700",
    descricao: "CABOS NAVAIS DE FIBRAS TEXTEIS NATURAIS; FABRICACAO DE",
  },
  {
    cnae: "13545",
    descricao: "FABRICACAO DE TECIDOS ESPECIAIS, INCLUSIVE ARTEFATOS",
  },
  {
    cnae: "1354500",
    descricao:
      "ACESSORIOS PARA FILTROS INDUSTRIAIS  (MANGAS COLETORAS DE PO, PLACAS, SACOS, DISCOS, ETC), DE TECIDOS FILTRANTES; CONFECCAO DE",
  },
  { cnae: "1354500", descricao: "TECIDOS COM FIOS METALIZADOS; CONFECCAO DE" },
  { cnae: "1354500", descricao: "BARRACAS DE ACAMPAMENTO; CONFECCAO DE" },
  { cnae: "1354500", descricao: "TENDAS; FABRICACAO DE" },
  {
    cnae: "1354500",
    descricao:
      "FIBRA MOLDADA PARA ESTOFAMENTO (MANTAS DE CRINA, FIBRAS DE COCO, ETC.); FABRICACAO DE",
  },
  {
    cnae: "1354500",
    descricao:
      "TELAS PARA PNEUMATICOS FABRICADAS COM FIOS SINTETICOS OU ARTIFICIAIS DE ALTA TENACIDADE; FABRICACAO DE",
  },
  {
    cnae: "1354500",
    descricao:
      "PASTAS, TONTISSES, NOS E BOLOTAS DE FIBRAS TEXTEIS; FABRICACAO DE",
  },
  { cnae: "1354500", descricao: "TELAS PARA PNEUMATICOS; FABRICACAO DE" },
  {
    cnae: "1354500",
    descricao:
      "CAPAS E FORRACOES PARA VEICULOS, ESTOFADAS OU NAO (EXCETO TAPETES); CONFECCAO DE",
  },
  { cnae: "1354500", descricao: "TECIDOS ANTIBACTERIANOS; FABRICACAO DE" },
  { cnae: "1354500", descricao: "PAINEIS DE LONA; FABRICACAO DE" },
  {
    cnae: "1354500",
    descricao:
      "ENTRETELAS BENEFICIADAS (PLASTIFICADAS, LAMINADAS OU COSTURADAS, ETC); FABRICACAO DE",
  },
  {
    cnae: "1354500",
    descricao:
      "ARTEFATOS DIVERSOS DE NAO-TECIDOS OU FALSOS TECIDOS; CONFECCAO DE",
  },
  { cnae: "1414200", descricao: "PELES FINAS DE ADORNO; FABRICACAO DE" },
  {
    cnae: "1354500",
    descricao:
      "TECIDO NAO TECIDO OU FALSOS TECIDOS, DE FIBRAS NATURAIS, ARTIFICIAIS OU SINTETICAS, MESMO ACABADOS; FABRICACAO DE",
  },
  {
    cnae: "1354500",
    descricao:
      "ARTEFATOS DE NAO-TECIDOS OU FALSOS TECIDOS PARA USOS TECNICOS, SANITARIOS OU DOMESTICOS; FABRICACAO DE",
  },
  { cnae: "1354500", descricao: "ARTEFATOS DE GEOTEXTEIS; FABRICACAO DE" },
  {
    cnae: "1354500",
    descricao:
      "MANGUEIRAS, TUBOS E CORREIAS DE MATERIAIS TEXTEIS, INCLUSIVE MANGUEIRAS DE INCENDIO; CONFECCAO DE",
  },
  {
    cnae: "1354500",
    descricao:
      "ARTEFATOS DE LONA, PANO-COURO E DE OUTROS TECIDOS DE ACABAMENTO ESPECIAL, N.E.; CONFECCAO DE",
  },
  {
    cnae: "1354500",
    descricao:
      "ENTRETELAS DE FIOS NATURAIS, ARTIFICIAIS OU SINTETICOS; FABRICACAO DE",
  },
  { cnae: "1354500", descricao: "FELTROS; FABRICACAO DE" },
  {
    cnae: "1354500",
    descricao:
      "REDES DE PROTECAO CONTRA DERRAMAMENTO DE PETROLEO NO MAR; FABRICACAO DE",
  },
  {
    cnae: "1354500",
    descricao:
      "ARTEFATOS TUBULARES PARA SAIDA DE EMERGENCIA DE PESSOAS; CONFECCAO DE",
  },
  { cnae: "1354500", descricao: "TOLDOS; FABRICACAO DE" },
  {
    cnae: "1354500",
    descricao:
      "TECIDOS E FELTROS COMBINADOS COM MATERIAS DIVERSAS, INCLUSIVE ARTEFATOS, PARA USOS TECNICOS; FABRICACAO DE",
  },
  { cnae: "1354500", descricao: "TECIDOS COM FIOS METALIZADOS; FABRICACAO DE" },
  { cnae: "1354500", descricao: "LONAS E ENCERADOS; FABRICACAO DE" },
  {
    cnae: "1354500",
    descricao:
      "TECIDOS RVESTIDOS OU IMPREGNADOS, INCLUSIVE AS ENTRETELAS; FABRICACAO DE",
  },
  {
    cnae: "1354500",
    descricao: "PARA-QUEDAS, SUAS PARTES E ACESSORIOS; CONFECCAO DE",
  },
  {
    cnae: "1354500",
    descricao: "TECIDOS PARA TELAS DE DESENHOS E PINTURAS; FABRICACAO DE",
  },
  { cnae: "1354500", descricao: "NAO TECIDOS OU FALSOS TECIDOS; CONFECCAO DE" },
  { cnae: "1354500", descricao: "PANO-COURO E OLEADOS; FABRICACAO DE" },
  { cnae: "1354500", descricao: "TECIDOS IMPERMEAVEIS; FABRICACAO DE" },
  { cnae: "1354500", descricao: "ARTIGOS DE FELTRO; FABRICACAO DE" },
  { cnae: "1354500", descricao: "VELAS PARA BARCOS; FABRICACAO DE" },
  { cnae: "1354500", descricao: "VELAMES; CONFECCAO DE" },
  {
    cnae: "1354500",
    descricao: "ARTEFATOS DE TECIDOS TECNICOS; FABRICACAO DE",
  },
  { cnae: "1354500", descricao: "CORTINAS PAINEIS; FABRICACAO DE" },
  {
    cnae: "1354500",
    descricao:
      "MECHAS PARA CANDEEIRO, ISQUEIROS, VELAS E SEMELHANTES, DE MATERIAIS TEXTEIS; FABRICACAO DE",
  },
  {
    cnae: "1354500",
    descricao: "TECIDOS REVESTIDOS OU IMPREGNADOS; FABRICACAO DE",
  },
  { cnae: "1354500", descricao: "AEROTETO (TOLDOS DE LONA); FABRICACAO DE" },
  { cnae: "1354500", descricao: "FITAS ADESIVAS DE TECIDOS; CONFECCAO DE" },
  {
    cnae: "1354500",
    descricao:
      "TECIDOS DE ACABAMENTO ESPECIAL - COLAGEM EM TECIDOS COM MATERIAL PLASTICO E OUTROS MATERIAIS; FABRICACAO DE",
  },
  {
    cnae: "13596",
    descricao:
      "FABRICACAO DE OUTROS PRODUTOS TEXTEIS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "1359600",
    descricao: "ARTESANATO EM MATERIAL TEXTIL; FABRICACAO DE",
  },
  { cnae: "1359600", descricao: "FIOS TEXTEIS METALIZADOS; FABRICACAO DE" },
  {
    cnae: "1359600",
    descricao:
      "SACOS DE JUTA PARA EMBALAGEM; CONFECCAO DE (QUANDO NAO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1359600",
    descricao:
      "APOIO DE CABECA, COMPRESSAS, SACHES, PARA USO TERAPEUTICO, PREDOMINANTE DE MATERIAL TEXTIL;  FABRICACAO",
  },
  { cnae: "1359600", descricao: "TULES E FILOS; FABRICACAO DE" },
  {
    cnae: "1359600",
    descricao:
      "SORTIDOS DE CORTES DE TECIDOS OU FIOS PARA CONFECCIONAR QUALQUER ARTEFATO; FABRICACAO DE",
  },
  { cnae: "1359600", descricao: "RENDAS E BORDADOS MANUAIS; FABRICACAO DE" },
  {
    cnae: "1359600",
    descricao:
      "SACOS DE FIBRAS TEXTEIS NATURAIS PARA EMBALAGEM; CONFECCAO DE (QUANDO NAO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1359600",
    descricao:
      "LUVAS, PANTUFAS, ALMOFADAS, PARA USO TERAPEUTICO, PREDOMINANTE DE MATERIAL TEXTIL;  FABRICACAO DE",
  },
  { cnae: "1359600", descricao: "COLCHONETES; FABRICACAO DE" },
  { cnae: "1359600", descricao: "FIOS ELASTICOS RECOBERTOS; FABRICACAO DE" },
  {
    cnae: "1359600",
    descricao:
      "EMBALAGEM FLEXIVEIS DE MATERIAS TEXTEIS (BIG BAGS, CONTENTORES, CONTEINERES, CONTAINERES) ; PRODUCAO DE",
  },
  {
    cnae: "1359600",
    descricao:
      "CONTENTORES (CONTEINERES, CONTAINERES) FLEXIVEIS (BIG BAGS), DE MATERIAS TEXTEIS, PARA EMBALAGEM; PRODUCAO DE",
  },
  {
    cnae: "1359600",
    descricao:
      "ARTEFATOS TEXTEIS NAO ESPECIFICADOS ANTERIORMENTE (EXCETO VESTUARIO); FABRICACAO DE",
  },
  {
    cnae: "1359600",
    descricao:
      "BORDADOS QUIMICOS OU AEREOS EM PECAS, TIRAS OU EM MOTIVOS; FABRICACAO DE",
  },
  {
    cnae: "1359600",
    descricao:
      "BIG BAGS (CONTENTORES, CONTEINERES, CONTAINERES) FLEXIVEIS, DE MATERIAS TEXTEIS, PARA EMBALAGEM; PRODUCAO DE",
  },
  { cnae: "1359600", descricao: "SACOS DE DORMIR; FABRICACAO DE" },
  { cnae: "1359600", descricao: "RENDAS; FABRICACAO DE" },
  { cnae: "1359600", descricao: "FILOS; FABRICACAO DE" },
  { cnae: "1359600", descricao: "FITAS DE TECIDOS; FABRICACAO DE" },
  {
    cnae: "1359600",
    descricao: "REDES TECIDAS DE PLASTICO PARA EMBALAGENS; FABRICACAO DE",
  },
  { cnae: "1359600", descricao: "TECIDOS BORDADOS; FABRICACAO DE" },
  { cnae: "1359600", descricao: "ETIQUETAS DE TECIDOS; FABRICACAO DE" },
  {
    cnae: "1359600",
    descricao:
      "SACOS DE ALGODAO PARA EMBALAGEM; CONFECCAO DE (QUANDO NAO INTEGRADA A TECELAGEM)",
  },
  {
    cnae: "1359600",
    descricao: "FIOS E CORDAS DE BORRACHA RECOBERTOS DE TEXTEIS; FABRICACAO DE",
  },
  { cnae: "1359600", descricao: "TECIDOS ELASTICOS; FABRICACAO DE" },
  {
    cnae: "1359600",
    descricao:
      "CADARCOS, GALOES, EMBLEMAS, VIESES E OUTROS ARTEFATOS DE PASSAMANARIA; FABRICACAO DE",
  },
  {
    cnae: "1359600",
    descricao: "ROUPAS, LACOS OU OUTROS ENFEITES PARA ANIMAIS; FABRICACAO DE",
  },
  { cnae: "1359600", descricao: "FITAS ELASTICAS; FABRICACAO DE" },
  {
    cnae: "1359600",
    descricao: "BANDEIRAS, ESTANDARTES E FLAMULAS DE TECIDOS; CONFECCAO DE",
  },
  { cnae: "1359600", descricao: "BORDADO INGLES; CONFECCAO DE" },
  {
    cnae: "1359600",
    descricao:
      "SACOS DE FIOS DE RAFIA, POLIPROPILENO E OUTROS MATERIAS PLASTICAS TEXTEIS; CONFECCAO DE (QUANDO NAO INTEGRADA A TECELAGEM)",
  },
  { cnae: "14118", descricao: "CONFECCAO DE ROUPAS INTIMAS" },
  { cnae: "1411801", descricao: "CUECAS, CONFECCAO DE" },
  {
    cnae: "1411801",
    descricao:
      "MONTAGEM DE PECAS INTERIORES DO VESTUARIO (COSTURA); SERVICO DE",
  },
  {
    cnae: "1411801",
    descricao:
      "ESPARTILHOS, SUSPENSORIOS, LIGAS, ARTEFATOS SEMELHANTES E SUAS PARTES, INCLUSIVE DE MALHA; CONFECCAO DE; FABRICACAO DE",
  },
  {
    cnae: "1411801",
    descricao:
      "ROUPAS INTIMAS DESCARTAVEIS CONFECCIONADAS COM TECIDOS; CONFECCAO DE",
  },
  { cnae: "1411801", descricao: "SUTIA (SOUTIEN) OU CALCINHAS; CONFECCAO DE" },
  { cnae: "1411801", descricao: "ROUPAS INTIMAS; FABRICACAO DE" },
  { cnae: "1411801", descricao: "CAMISOLAS PARA CRIANCAS, ; CONFECCAO DE" },
  { cnae: "1411801", descricao: "ROUPOES E ROBES MASCULINOS; CONFECCAO DE" },
  { cnae: "1411801", descricao: "PIJAMAS ADULTOS OU INFANTIS; CONFECCAO DE" },
  { cnae: "1411801", descricao: "CAMISOLAS E PIJAMAS FEMININOS; CONFECCAO DE" },
  { cnae: "1411801", descricao: "ROUPOES E ROBES FEMININOS; CONFECCAO DE" },
  { cnae: "1411801", descricao: "ANAGUAS E COMBINACOES; CONFECCAO DE" },
  {
    cnae: "1411801",
    descricao: "COSTUREIRA (PARA PECAS INTERIORES DO VESTUARIO)",
  },
  { cnae: "1411801", descricao: "CINTAS ELASTICAS; FABRICACAO DE" },
  { cnae: "1411801", descricao: "CINTAS-LIGA; FABRICACAO DE" },
  { cnae: "1411801", descricao: "MODELADORES FEMININOS; FABRICACAO DE" },
  { cnae: "1411801", descricao: "CALCINHAS PARA CRIANCAS; CONFECCAO DE" },
  { cnae: "1411801", descricao: "CALCINHAS FEMININAS; CONFECCAO DE" },
  { cnae: "1411802", descricao: "ROUPAS INTIMAS; FACCAO DE" },
  {
    cnae: "1411802",
    descricao:
      "FACCAO DE ESPARTILHOS, SUSPENSORIOS, LIGAS, ARTEFATOS SEMELHANTES E SUAS PARTES, INCLUSIVE DE MALHA; SERVICOS DE; FABRICACAO DE",
  },
  { cnae: "1411802", descricao: "FACCAO DE ROUPAS INTIMAS; SERVICO DE" },
  {
    cnae: "1411802",
    descricao: "FACCAO DE PECAS INTERIORES DO VESTUARIO; SERVICO DE",
  },
  {
    cnae: "14126",
    descricao: "CONFECCAO DE PECAS DO VESTUARIO, EXCETO ROUPAS INTIMAS",
  },
  { cnae: "1412601", descricao: "TERNOS E COSTUMES PARA HOMENS; CONFECCAO DE" },
  { cnae: "1412601", descricao: "SAIAS PARA CRIANCAS; CONFECCAO DE" },
  {
    cnae: "1412601",
    descricao:
      "ROUPAS, PECAS DO VESTUARIO E AGASALHOS PARA RECEM-NASCIDOS; CONFECCAO DE",
  },
  { cnae: "1412601", descricao: "ROUPAS DE COURO; CONFECCAO DE" },
  { cnae: "1412601", descricao: "CALCAS COMPRIDAS FEMININAS; CONFECCAO DE" },
  { cnae: "1412601", descricao: "MAIOS; FABRICACAO DE" },
  { cnae: "1412601", descricao: "CASACOS PARA HOMENS; CONFECCAO DE" },
  {
    cnae: "1412601",
    descricao: "VESTIDOS OU CONJUNTO FEMININOS; CONFECCAO DE",
  },
  { cnae: "1412601", descricao: "CALCAS COMPRIDAS PARA HOMENS; CONFECCAO DE" },
  { cnae: "1412601", descricao: "JAQUETAS; CONFECCAO DE" },
  { cnae: "1412601", descricao: "CAMISAS ESPORTE; CONFECCAO DE" },
  {
    cnae: "1412601",
    descricao: "CAPAS DE MATERIAL PLASTICO PARA CRIANCAS; CONFECCAO DE",
  },
  { cnae: "1412601", descricao: "ROUPAS DE PRAIA PARA CRIANCAS; CONFECCAO DE" },
  { cnae: "1412601", descricao: "ROUPAS ESPORTIVAS; CONFECCAO DE" },
  { cnae: "1412601", descricao: "CASACOS DE COURO; CONFECCAO DE" },
  {
    cnae: "1412601",
    descricao: "AGASALHOS DE COURO OU PELE PARA USO FEMININO; CONFECCAO DE",
  },
  {
    cnae: "1412601",
    descricao: "ROUPAS PARA CRIANCAS (EXCETO ROUPAS INTIMAS); CONFECCAO DE",
  },
  { cnae: "1412601", descricao: "CASACOS FEMININOS; CONFECCAO DE" },
  { cnae: "1412601", descricao: "CAMISA SOCIAL PARA ADULTOS; CONFECCAO DE" },
  { cnae: "1412601", descricao: "BIQUINIS DE PRAIA; FABRICACAO DE" },
  { cnae: "1412601", descricao: "CAMISETAS; CONFECCAO DE" },
  {
    cnae: "1412601",
    descricao: "CALCOES, SHORTS E SEMELHANTES PARA CRIANCAS; CONFECCAO DE",
  },
  { cnae: "1412601", descricao: "ROUPAS PARA PRATICAR ESPORTES; CONFECCAO DE" },
  { cnae: "1412601", descricao: "FANTASIAS EM TECIDO; CONFECCAO DE" },
  { cnae: "1412601", descricao: "ROUPAS (EXCETO PECAS INTIMAS); CONFECCAO DE" },
  {
    cnae: "1412601",
    descricao: "ARTIGOS DO VESTUARIO ADORNADOS COM PELES; CONFECCAO DE",
  },
  { cnae: "1412601", descricao: "ATELIER DE COSTURA (EXCETO PECAS INTIMAS)" },
  { cnae: "1412601", descricao: "CAPAS PARA ADULTOS; CONFECCAO DE" },
  {
    cnae: "1412601",
    descricao: "VESTUARIO (EXCETO PECAS INTIMAS); CONFECCAO DE",
  },
  { cnae: "1412601", descricao: "SOBRETUDOS PARA HOMENS; CONFECCAO DE" },
  { cnae: "1412601", descricao: "MONTAGEM DE ROUPAS (COSTURA); SERVICO DE" },
  {
    cnae: "1412601",
    descricao: "BLUSAS, BLUSOES E CAMISAS ESPORTE PARA HOMENS; CONFECCAO DE",
  },
  { cnae: "1412601", descricao: "BERMUDAS; FABRICACAO DE" },
  { cnae: "1412601", descricao: "SAIAS DE COURO; CONFECCAO DE" },
  { cnae: "1412601", descricao: "SUNGAS DE PRAIA; CONFECCAO DE" },
  {
    cnae: "1412601",
    descricao: "ROUPAS ESPORTE PARA USO FEMININO; CONFECCAO DE",
  },
  { cnae: "1412601", descricao: "CALCAS COMPRIDAS DE COURO; CONFECCAO DE" },
  { cnae: "1412601", descricao: "ROUPAS DE PRAIA; FABRICACAO DE" },
  { cnae: "1412601", descricao: "AGASALHOS, N.E.; CONFECCAO DE" },
  {
    cnae: "1412601",
    descricao: "CALCAS COMPRIDAS PARA CRIANCAS; CONFECCAO DE",
  },
  { cnae: "1412601", descricao: "BLAZERS; FABRICACAO DE" },
  { cnae: "1412601", descricao: "SAIAS FEMININAS; CONFECCAO DE" },
  {
    cnae: "1412601",
    descricao:
      "MACACOES, AGASALHOS E CONJUNTOS PARA ESPORTE, DE MALHA; CONFECCAO DE; FABRICACAO DE",
  },
  {
    cnae: "1412601",
    descricao: "BLUSAS, BLUSOES E CAMISAS ESPORTE PARA CRIANCAS; CONFECCAO DE",
  },
  {
    cnae: "1412601",
    descricao: "CALCOES, SHORTS E SEMELHANTES PARA ADULTOS; CONFECCAO DE",
  },
  {
    cnae: "1412601",
    descricao: "VESTIDOS OU CONJUNTOS PARA CRIANCAS; CONFECCAO DE",
  },
  {
    cnae: "1412601",
    descricao: "AGASALHOS DE COURO E PELE, PARA HOMENS; CONFECCAO DE",
  },
  { cnae: "1412601", descricao: "CASACOS PARA CRIANCAS; CONFECCAO DE" },
  { cnae: "1412601", descricao: "PALETOS PARA HOMENS; CONFECCAO DE" },
  { cnae: "1412601", descricao: "BLUSAS FEMININAS; CONFECCAO DE" },
  { cnae: "1412601", descricao: "CAMISA SOCIAL PARA CRIANCAS; CONFECCAO DE" },
  {
    cnae: "1412602",
    descricao: "COSTUREIRAS; CONFECCAO SOB MEDIDA (EXCETO PARA ROUPAS INTIMAS)",
  },
  { cnae: "1412602", descricao: "ALFAIATE" },
  { cnae: "1412602", descricao: "ROUPAS ESPORTIVAS; CONFECCAO SOB MEDIDA DE" },
  {
    cnae: "1412602",
    descricao: "VESTIDOS E COSTUMES; CONFECCAO SOB MEDIDA DE",
  },
  { cnae: "1412602", descricao: "JAQUETAS; CONFECCAO SOB MEDIDA DE" },
  { cnae: "8592999", descricao: "ARTESANATO; CURSO, ENSINO DE" },
  { cnae: "1412602", descricao: "ROUPAS DE PRAIA; CONFECCAO SOB MEDIDA DE" },
  { cnae: "1412602", descricao: "BLUSAS FEMININAS; CONFECCAO SOB MEDIDA DE" },
  { cnae: "1412602", descricao: "CASACOS, CONFECCAO SOB MEDIDA DE" },
  { cnae: "1412602", descricao: "CAMISA SOCIAL; CONFECCAO SOB MEDIDA DE" },
  {
    cnae: "1412602",
    descricao:
      "BLUSAS, BLUSOES E CAMISAS ESPORTE PARA HOMENS; CONFECCAO SOB MEDIDA DE",
  },
  { cnae: "1412602", descricao: "CALCAS COMPRIDAS, CONFECCAO SOB MEDIDA DE" },
  { cnae: "1412602", descricao: "TERNOS; CONFECCAO SOB MEDIDA DE" },
  {
    cnae: "1412602",
    descricao: "CAMISEIROS (CONFECCAO DE CAMISAS SOB MEDIDA)",
  },
  {
    cnae: "1412602",
    descricao: "BLUSAS E CAMISAS PARA CRIANCAS;  CONFECCAO SOB MEDIDA DE",
  },
  {
    cnae: "1412602",
    descricao: "CAMISAS, BLUSAS E BLUSOES, CONFECCAO SOB MEDIDA DE",
  },
  {
    cnae: "1412603",
    descricao:
      "FACCAO DE MACACOES, AGASALHOS E CONJUNTOS PARA ESPORTE, EXCETO DE MALHA; SERVICOS DE; FABRICACAO DE",
  },
  {
    cnae: "1412603",
    descricao:
      "FACCAO DE PECAS DO VESTUARIO (EXCETO ROUPAS INTIMAS); SERVICO DE",
  },
  { cnae: "14134", descricao: "CONFECCAO DE ROUPAS PROFISSIONAIS" },
  {
    cnae: "1413401",
    descricao: "CAPAS DE BORRACHA PARA USO PROFISSIONAL; CONFECCAO DE",
  },
  { cnae: "1413401", descricao: "FARDAMENTOS MILITARES; CONFECCAO DE" },
  { cnae: "1413401", descricao: "ROUPAS PROFISSIONAIS; CONFECCAO DE" },
  {
    cnae: "1413401",
    descricao:
      "PECAS AVULSAS DO VESTUARIO PARA FARDAMENTOS MILITARES; CONFECCAO DE",
  },
  { cnae: "1413401", descricao: "UNIFORMES ESCOLARES; CONFECCAO DE" },
  {
    cnae: "1413401",
    descricao: "MACACOES E OUTRAS ROUPAS PROFISSIONAIS; CONFECCAO DE",
  },
  { cnae: "1413401", descricao: "UNIFORMES PROFISSIONAIS; FABRICACAO DE" },
  {
    cnae: "1413401",
    descricao:
      "PECAS AVULSAS DO VESTUARIO PARA UNIFORMES ESCOLARES; CONFECCAO DE",
  },
  {
    cnae: "1413402",
    descricao: "FARDAMENTOS MILITARES; CONFECCAO SOB MEDIDA DE",
  },
  {
    cnae: "1413402",
    descricao: "ROUPAS PROFISSIONAIS; CONFECCAO SOB MEDIDA DE",
  },
  {
    cnae: "1413402",
    descricao: "UNIFORMES ESCOLARES; CONFECCAO SOB MEDIDA DE",
  },
  { cnae: "1413403", descricao: "FACCAO DE ROUPAS PROFISSIONAIS; SERVICO DE" },
  {
    cnae: "14142",
    descricao:
      "FABRICACAO DE ACESSORIOS DO VESTUARIO, EXCETO PARA SEGURANCA E PROTECAO",
  },
  { cnae: "1414200", descricao: "CINTOS DE PLASTICO; FABRICACAO DE" },
  { cnae: "1414200", descricao: "PELES; PREPARACAO DE" },
  { cnae: "1414200", descricao: "BONES DE QUALQUER MATERIAL; FABRICACAO DE" },
  { cnae: "1414200", descricao: "CINTOS DE TECIDOS; FABRICACAO DE" },
  {
    cnae: "1414200",
    descricao: "CHAPEUS E SUAS PARTES, DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  { cnae: "1414200", descricao: "XALES E ECHARPES; FABRICACAO DE" },
  {
    cnae: "1414200",
    descricao: "CINTOS DE QUALQUER MATERIAL, PARA VESTUARIO; FABRICACAO DE",
  },
  { cnae: "1414200", descricao: "LEQUES; FABRICACAO DE" },
  {
    cnae: "1414200",
    descricao:
      "LUVAS DE COURO (EXCETO PARA USO PROFISSIONAL E ESPORTIVO); CONFECCAO DE",
  },
  { cnae: "1414200", descricao: "BOINAS E GORROS; FABRICACAO DE" },
  { cnae: "1414200", descricao: "GRAVATAS; FABRICACAO DE" },
  {
    cnae: "1414200",
    descricao:
      "ACESSORIOS DO VESTUARIO DESCARTAVEIS CONFECCIONADOS COM TECIDOS; FABRICACAO DE",
  },
  {
    cnae: "1414200",
    descricao: "LUVAS DE TECIDOS, INCLUSIVE TECIDOS DE MALHA; FABRICACAO DE",
  },
  {
    cnae: "1414200",
    descricao: "LENCOS DE TECIDOS, PARA QUALQUER USO; FABRICACAO DE",
  },
  {
    cnae: "1414200",
    descricao: "SUSPENSORIOS DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  { cnae: "14215", descricao: "FABRICACAO DE MEIAS" },
  {
    cnae: "1421500",
    descricao: "MEIAS-CALCAS, INCLUSIVE ELASTICAS; FABRICACAO DE",
  },
  { cnae: "1421500", descricao: "MEIAS PARA FINS ESPORTIVOS; FABRICACAO DE" },
  { cnae: "1421500", descricao: "MEIAS PARA QUALQUER USO; FABRICACAO DE" },
  {
    cnae: "14223",
    descricao:
      "FABRICACAO DE ARTIGOS DO VESTUARIO, PRODUZIDOS EM MALHARIAS E TRICOTAGENS, EXCETO MEIAS",
  },
  {
    cnae: "1422300",
    descricao:
      "ARTIGOS DO VESTUARIO PRODUZIDOS A PARTIR DE LINHA OU LA (TRICOTAGEM); FABRICACAO DE",
  },
  {
    cnae: "1422300",
    descricao:
      "PULOVERES, SUETERES, JAQUETAS E OUTROS ARTIGOS DO VESTUARIO PRODUZIDOS EM TRICOTAGENS; FABRICACAO DE",
  },
  { cnae: "15106", descricao: "CURTIMENTO E OUTRAS PREPARACOES DE COURO" },
  { cnae: "1510600", descricao: "COURO; REGENERACAO DE" },
  { cnae: "1510600", descricao: "COUROS; TINGIMENTO E PINTURA DE" },
  {
    cnae: "1510600",
    descricao:
      "COUROS E PELES DE OFIDIOS, REPTEIS, PEIXES E OUTROS ANIMAIS AQUATICOS; CURTIMENTO E OUTRAS PREPARACOES DE",
  },
  { cnae: "1510600", descricao: "SOLAS (COUROS); FABRICACAO DE" },
  {
    cnae: "1510600",
    descricao:
      "APARAS E OUTROS RESIDUOS DE COURO; SERRAGEM, PO E FARINHA DE COURO; OBTENCAO DE",
  },
  {
    cnae: "1510600",
    descricao:
      "COUROS E PELES DE SUINOS (PORCOS); CURTIMENTO E OUTRAS PREPARACOES DE",
  },
  {
    cnae: "1510600",
    descricao:
      "COUROS E PELES DE CAPRINOS E OVINOS; CURTIMENTO E OUTRAS PREPARACOES DE",
  },
  { cnae: "1510600", descricao: "COURO RECONSTITUIDO; FABRICACAO DE" },
  {
    cnae: "1510600",
    descricao:
      "COUROS E PELES DE BOVINOS CURTIDOS AO CROMO (WET BLUE / BOX CALL); FABRICACAO DE",
  },
  {
    cnae: "1510600",
    descricao:
      "PELES DE ANIMAIS SILVESTRES - EXCETO PARA ADORNO; CURTIMENTO DE",
  },
  {
    cnae: "1510600",
    descricao: "COUROS E PELES DE BOVINOS; CURTIMENTO E OUTRAS PREPARACOES DE",
  },
  {
    cnae: "1510600",
    descricao: "CURTIMENTO E OUTRAS PREPARACOES DO COURO; SERVICO DE",
  },
  { cnae: "1510600", descricao: "COUROS E PELES METALIZADOS; FABRICACAO DE" },
  { cnae: "1510600", descricao: "RASPAS (COUROS); FABRICACAO DE" },
  { cnae: "1510600", descricao: "CAMURCAS; FABRICACAO DE" },
  { cnae: "1510600", descricao: "COUROS ENVERNIZADOS; FABRICACAO DE" },
  {
    cnae: "1510600",
    descricao: "COUROS E PELES CURTIDOS OU PREPARADOS, N.E.; FABRICACAO DE",
  },
  { cnae: "1510600", descricao: "CROMOS (COUROS); FABRICACAO DE" },
  { cnae: "1510600", descricao: "VAQUETAS; FABRICACAO DE" },
  {
    cnae: "1510600",
    descricao:
      "COUROS E PELES DE ANIMAIS DOMESTICOS; CURTIMENTO E OUTRAS PREPARACOES DE",
  },
  {
    cnae: "1510600",
    descricao: "COUROS E PELES DE EQUINOS; CURTIMENTO E OUTRAS PREPARACOES DE",
  },
  { cnae: "1510600", descricao: "PELICAS (COUROS); FABRICACAO DE" },
  {
    cnae: "1510600",
    descricao:
      "COUROS E PELES DE ANIMAIS SILVESTRES; CURTIMENTO E OUTRAS PREPARACOES DE",
  },
  {
    cnae: "15211",
    descricao:
      "FABRICACAO DE ARTIGOS PARA VIAGEM, BOLSAS E SEMELHANTES DE QUALQUER MATERIAL",
  },
  { cnae: "1521100", descricao: "BOLSAS DE COURO; FABRICACAO DE" },
  {
    cnae: "1521100",
    descricao:
      "BOLSAS DE QUALQUER MATERIAL (COURO, PLASTICO, ETC); FABRICACAO DE",
  },
  {
    cnae: "1521100",
    descricao:
      "MOCHILAS, ESCOLARES OU NAO, DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  { cnae: "1521100", descricao: "MOCHILAS DE PLASTICO; FABRICACAO DE" },
  { cnae: "1521100", descricao: "PASTAS DE COURO; FABRICACAO DE" },
  {
    cnae: "1521100",
    descricao: "ARTIGOS DE MADEIRA PARA VIAGEM; FABRICACAO DE",
  },
  {
    cnae: "1521100",
    descricao:
      "PASTAS PARA DOCUMENTOS E PARA ESTUDANTES DE QUALQUER MATERIAL, EXCETO COURO; FABRICACAO DE",
  },
  { cnae: "1521100", descricao: "ARTIGOS DE FIBRA PARA VIAGEM; FABRICACAO DE" },
  { cnae: "1521100", descricao: "BOLSAS DE PLASTICO; FABRICACAO DE" },
  { cnae: "1521100", descricao: "MOCHILAS DE COURO; FABRICACAO DE" },
  { cnae: "1521100", descricao: "ARTIGOS DE COURO PARA VIAGEM; FABRICACAO DE" },
  { cnae: "1521100", descricao: "BOLSAS TERMICAS; FABRICACAO DE" },
  { cnae: "1521100", descricao: "NECESSAIRE, ESTOJO; FABRICACAO DE" },
  {
    cnae: "1521100",
    descricao:
      "CARTEIRAS PARA OU DOCUMENTOS, PORTA-NIQUEIS, CIGARREIRAS, ETC., DE COURO NATURAL OU ARTIFICIAL OU DE OUTRAS MATERIAS TEXTEIS; FABRICACAO DE",
  },
  {
    cnae: "1521100",
    descricao: "BOLSAS E MOCHILAS DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  {
    cnae: "1521100",
    descricao:
      "MALAS, VALISES E OUTROS ARTEFATOS PARA VIAGEM, DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  { cnae: "1521100", descricao: "ARTIGOS DE LONA PARA VIAGEM; FABRICACAO DE" },
  {
    cnae: "15297",
    descricao:
      "FABRICACAO DE ARTEFATOS DE COURO NAO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "1529700", descricao: "PORTA-NIQUEIS, DE COURO; FABRICACAO DE" },
  { cnae: "1529700", descricao: "CARTEIRA DE COURO; FABRICACAO DE" },
  { cnae: "1529700", descricao: "PORTA-NOTAS, DE COURO; FABRICACAO DE" },
  {
    cnae: "1529700",
    descricao: "ARTIGOS DE COURO PARA MAQUINAS; FABRICACAO DE",
  },
  { cnae: "1529700", descricao: "TACOS DE COURO PARA TEARES; FABRICACAO DE" },
  {
    cnae: "1529700",
    descricao:
      "FOCINHEIRAS, MORDACAS, COLEIRAS E OUTROS ARTIGOS DE COURO PARA PEQUENOS ANIMAIS; FABRICACAO DE",
  },
  { cnae: "1529700", descricao: "TUBOS E MANGUEIRAS DE COURO; FABRICACAO DE" },
  {
    cnae: "1529700",
    descricao: "PECAS DE COURO PARA ARREIOS E SELAS; FABRICACAO DE",
  },
  {
    cnae: "1529700",
    descricao: "CORREIAS DE COURO PARA TRANSMISSAO; FABRICACAO DE",
  },
  {
    cnae: "1529700",
    descricao: "PULSEIRAS PARA RELOGIOS (EXCETO METALICAS); FABRICACAO DE",
  },
  { cnae: "1529700", descricao: "ARTEFATOS DE COURO, N.E.; FABRICACAO DE" },
  { cnae: "1529700", descricao: "ARTESANATO EM COUROS E PELES; FABRICACAO DE" },
  { cnae: "1529700", descricao: "PORTA DOCUMENTOS, DE COURO; FABRICACAO DE" },
  {
    cnae: "1529700",
    descricao:
      "ARTIGOS DE COURO NATURAL OU RECONSTITUIDO PARA USOS TECNICOS; FABRICACAO DE",
  },
  { cnae: "1529700", descricao: "ARREIOS E SELAS COMPLETOS; FABRICACAO DE" },
  { cnae: "1529700", descricao: "SELARIA" },
  { cnae: "1529700", descricao: "SELAS COMPLETAS; FABRICACAO DE" },
  {
    cnae: "1529700",
    descricao:
      "CINTOS DE COURO PARA ARMAS E MUNICOES (COLDRES, CINTUROES E TALABARTES); FABRICACAO DE",
  },
  { cnae: "15319", descricao: "FABRICACAO DE CALCADOS DE COURO" },
  {
    cnae: "1531901",
    descricao: "CHINELOS E ALPERCATAS DE COURO, INFANTIS; FABRICACAO DE",
  },
  { cnae: "1531901", descricao: "CALCADOS DE COURO, N.E.; FABRICACAO DE" },
  {
    cnae: "1531901",
    descricao: "CALCADOS DE COURO PARA SEGURANCA INDUSTRIAL; FABRICACAO DE",
  },
  { cnae: "1531901", descricao: "SAPATENIS DE COURO; FABRICACAO DE" },
  { cnae: "1531901", descricao: "CHUTEIRAS; FABRICACAO DE" },
  { cnae: "1531901", descricao: "SAPATOS DE COURO, MASCULINO; FABRICACAO DE" },
  { cnae: "1531901", descricao: "SAPATOS DE COURO, FEMININOS; FABRICACAO DE" },
  { cnae: "1531901", descricao: "CALCADOS DE COURO, INFANTIS; FABRICACAO DE" },
  {
    cnae: "1531901",
    descricao: "SANDALIAS DE COURO, MASCULINO; FABRICACAO DE",
  },
  { cnae: "1531901", descricao: "BOTAS DE COURO; FABRICACAO DE" },
  { cnae: "1531901", descricao: "SANDALIAS DE COURO, FEMININO; FABRICACAO DE" },
  {
    cnae: "1531901",
    descricao: "CHINELOS E ALPERCATAS DE COURO, FEMININOS; FABRICACAO DE",
  },
  {
    cnae: "1531901",
    descricao:
      "CALCADOS DE COURO PARA ESPORTES (EXCETO TENIS OU QUEDIS); FABRICACAO DE",
  },
  {
    cnae: "1531901",
    descricao: "CHINELOS E ALPERCATAS DE COURO, MASCULINOS; FABRICACAO DE",
  },
  {
    cnae: "1531902",
    descricao:
      "PESPONTO E OUTROS ACABAMENTOS EM CALCADOS DE COURO; SERVICOS DE",
  },
  {
    cnae: "1531902",
    descricao: "MONTAGEM E COSTURA DE CALCADOS DE COURO; SERVICOS DE",
  },
  {
    cnae: "1531902",
    descricao: "CUSTOMIZACAO EM CALCADOS DE COURO, SERVICOS DE",
  },
  { cnae: "15327", descricao: "FABRICACAO DE TENIS DE QUALQUER MATERIAL" },
  {
    cnae: "1532700",
    descricao: "TENIS OU QUEDIS DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  { cnae: "15335", descricao: "FABRICACAO DE CALCADOS DE MATERIAL SINTETICO" },
  {
    cnae: "1533500",
    descricao: "CHINELO DE MATERIAL DE PLASTICO MOLDADO; FABRICACAO DE",
  },
  {
    cnae: "1533500",
    descricao:
      "CALCADOS DE PLASTICO PARA ESPORTES (EXCETO TENIS); FABRICACAO DE",
  },
  {
    cnae: "1533500",
    descricao: "CHINELOS DE PLASTICO PARA ADULTOS; FABRICACAO DE",
  },
  {
    cnae: "1533500",
    descricao: "CHINELOS DE PLASTICO PARA CRIANCAS; FABRICACAO DE",
  },
  {
    cnae: "1533500",
    descricao: "CHINELOS DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "1533500",
    descricao: "CALCADOS DE PLASTICO PARA ADULTOS; FABRICACAO DE",
  },
  {
    cnae: "1533500",
    descricao: "CALCADOS DE PLASTICO PARA CRIANCAS; FABRICACAO DE",
  },
  {
    cnae: "1533500",
    descricao: "CALCADOS DE PLASTICO PARA SEGURANCA INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "1533500",
    descricao: "CALCADOS DE MATERIAL SINTETICO MONTADO; FABRICACAO DE",
  },
  {
    cnae: "1533500",
    descricao: "SANDALIAS DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "15394",
    descricao:
      "FABRICACAO DE CALCADOS DE MATERIAIS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "1539400",
    descricao: "SANDALIAS E ALPERCATAS DE LONA E FIBRAS; FABRICACAO DE",
  },
  {
    cnae: "1539400",
    descricao:
      "CALCADOS PARA SEGURANCA (EXCETO DE COURO E PLASTICO); FABRICACAO DE",
  },
  {
    cnae: "1539400",
    descricao:
      "CHINELOS, SANDALIAS E ALPERCATAS DE OUTROS MATERIAIS (EXCETO COURO E PLASTICO); FABRICACAO DE",
  },
  {
    cnae: "1539400",
    descricao: "CHINELOS DE BORRACHA PARA CRIANCAS; FABRICACAO DE",
  },
  {
    cnae: "1539400",
    descricao: "CALCADOS DE BORRACHA PARA SEGURANCA INDUSTRIAL; FABRICACAO DE",
  },
  { cnae: "1539400", descricao: "CALCADOS DE BORRACHA; FABRICACAO DE" },
  { cnae: "1539400", descricao: "TAMANCOS; FABRICACAO DE" },
  {
    cnae: "1539400",
    descricao: "SANDALIAS (CHINELOS) DE BORRACHA PARA CRIANCAS; FABRICACAO DE",
  },
  {
    cnae: "1539400",
    descricao: "SANDALIAS (CHINELOS) DE BORRACHA PARA ADULTOS; FABRICACAO DE",
  },
  {
    cnae: "1539400",
    descricao: "CHINELOS DE BORRACHA PARA ADULTOS; FABRICACAO DE",
  },
  { cnae: "1539400", descricao: "CALCADOS DE TECIDOS; FABRICACAO DE" },
  {
    cnae: "15408",
    descricao: "FABRICACAO DE PARTES PARA CALCADOS, DE QUALQUER MATERIAL",
  },
  {
    cnae: "1540800",
    descricao: "SOLADOS DE MADEIRA PARA CALCADOS; FABRICACAO DE",
  },
  {
    cnae: "1540800",
    descricao: "PARTES DE BORRACHA PARA CALCADOS; FABRICACAO DE",
  },
  {
    cnae: "1540800",
    descricao: "PALMILHAS PARA CALCADOS DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  {
    cnae: "1540800",
    descricao: "SALTOS E SOLADOS DE PLASTICO PARA CALCADOS; FABRICACAO DE",
  },
  {
    cnae: "1540800",
    descricao: "PARTES DE TECIDOS PARA CALCADOS; FABRICACAO DE",
  },
  {
    cnae: "1540800",
    descricao: "CORTES DE COURO PARA CALCADOS; FABRICACAO DE",
  },
  {
    cnae: "1540800",
    descricao: "SALTOS DE COURO PARA CALCADOS; FABRICACAO DE",
  },
  {
    cnae: "1540800",
    descricao: "CONTRAFORTES E COURACAS PARA CALCADOS; FABRICACAO DE",
  },
  {
    cnae: "1540800",
    descricao: "SOLADOS DE COURO PARA CALCADOS; FABRICACAO DE",
  },
  {
    cnae: "1540800",
    descricao: "CABEDAL DE COURO PARA CALCADOS; FABRICACAO DE",
  },
  {
    cnae: "1540800",
    descricao: "SOLAS DE BORRACHA PARA CALCADOS; FABRICACAO DE",
  },
  { cnae: "1540800", descricao: "VIRAS PARA CALCADOS; FABRICACAO DE" },
  {
    cnae: "1540800",
    descricao: "PARTES DE COURO PARA CALCADOS; FABRICACAO DE",
  },
  { cnae: "1540800", descricao: "SOLADOS DE BORRACHA; FABRICACAO DE" },
  {
    cnae: "1540800",
    descricao: "SALTOS DE MADEIRA PARA CALCADOS; FABRICACAO DE",
  },
  {
    cnae: "1540800",
    descricao: "SALTOS DE BORRACHA PARA CALCADOS; FABRICACAO DE",
  },
  {
    cnae: "1540800",
    descricao:
      "CORTES, VIRAS, CONTRAFORTES, TRANCINHAS E OUTROS ACESSORIOS PARA CALCADOS; CONFECCAO DE",
  },
  {
    cnae: "1540800",
    descricao: "SALTOS DE CORTICA PARA CALCADOS; FABRICACAO DE",
  },
  {
    cnae: "1540800",
    descricao: "CEPOS E SOLADOS DE MADEIRA PARA CALCADOS; FABRICACAO DE",
  },
  { cnae: "1540800", descricao: "CONTRAFORTES PARA CALCADOS; FABRICACAO DE" },
  {
    cnae: "1540800",
    descricao:
      "SALTOS E SOLADOS DE COURO PARA CALCADOS, FORRADOS OU NAO; FABRICACAO DE",
  },
  { cnae: "8592999", descricao: "PINTURA; CURSO, ENSINO DE" },
  {
    cnae: "1540800",
    descricao: "PARTES DE MATERIAL PLASTICO PARA CALCADOS; FABRICACAO DE",
  },
  {
    cnae: "1540800",
    descricao: "PARTES DE MATERIAL SINTETICO PARA CALCADOS; FABRICACAO DE",
  },
  { cnae: "16102", descricao: "DESDOBRAMENTO DE MADEIRA" },
  {
    cnae: "1610203",
    descricao:
      "IMUNIZACAO DA MADEIRA QUANDO INTEGRADA A PRODUCAO DE MADEIRA BRUTA DESDOBRADA OU SERRADA EM BRUTO; SERVICOS DE",
  },
  {
    cnae: "1610203",
    descricao: "DESDOBRAMENTO DE MADEIRA EM BRUTO; SERVICO DE",
  },
  { cnae: "1610203", descricao: "MADEIRA SERRADA OU DESDOBRADA; PRODUCAO DE" },
  { cnae: "1610204", descricao: "FORROS DE MADEIRA; PRODUCAO DE" },
  { cnae: "1610204", descricao: "MOIROES DE MADEIRA; FABRICACAO DE" },
  { cnae: "1610204", descricao: "TABUA CORRIDA; FABRICACAO DE" },
  { cnae: "1610204", descricao: "POSTES DE MADEIRA; PRODUCAO DE" },
  { cnae: "1610204", descricao: "MADEIRA RESSERRADA; PRODUCAO DE" },
  { cnae: "1610204", descricao: "LA DE MADEIRA; PRODUCAO DE" },
  { cnae: "1610204", descricao: "MADEIRA PICADA; PRODUCAO DE" },
  { cnae: "1610204", descricao: "DORMENTES; PRODUCAO DE" },
  { cnae: "1610204", descricao: "PRANCHAS E PRANCHOES; PRODUCAO DE" },
  {
    cnae: "1610204",
    descricao:
      "DORMENTES DE MADEIRA PARA VIAS FERREAS E SEMELHANTES, IMPREGNADOS OU NAO; PRODUCAO DE",
  },
  { cnae: "1610204", descricao: "ASSOALHOS DE MADEIRA; PRODUCAO DE" },
  { cnae: "1610204", descricao: "TACOS PARA PISOS; PRODUCAO DE" },
  {
    cnae: "1610204",
    descricao:
      "IMUNIZACAO DE MADEIRA INTEGRADA A PRODUCAO DE MADEIRA RESSERRADA; SERVICOS DE",
  },
  {
    cnae: "1610204",
    descricao: "POSTES DE MADEIRA TRATADOS OU IMPREGNADOS; PRODUCAO DE",
  },
  { cnae: "1610204", descricao: "PARQUETES; PRODUCAO DE" },
  { cnae: "1610204", descricao: "SARRAFOS; PRODUCAO DE" },
  { cnae: "1610204", descricao: "PISOS DE MADEIRA; FABRICACAO DE" },
  { cnae: "1610204", descricao: "TABUAS; PRODUCAO DE" },
  { cnae: "1610204", descricao: "RIPAS; PRODUCAO DE" },
  {
    cnae: "1610205",
    descricao:
      "TRATAMENTO DE MADEIRA COM CREOSOTO OU OUTRO AGENTE DE CONSERVACAO; SERVICOS DE",
  },
  { cnae: "1610205", descricao: "MADEIRA; TRATAMENTO DE" },
  {
    cnae: "1610205",
    descricao: "MADEIRA, SECAGEM, PRESERVACAO E IMUNIZACAO; SERVICO DE",
  },
  {
    cnae: "16218",
    descricao:
      "FABRICACAO DE MADEIRA LAMINADA E DE CHAPAS DE MADEIRA COMPENSADA, PRENSADA E AGLOMERADA",
  },
  {
    cnae: "1621800",
    descricao: "MADEIRA FOLHEADA, LAMINADA OU FAQUEADA; PRODUCAO DE",
  },
  { cnae: "1629301", descricao: "CABIDES DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "1621800",
    descricao:
      "MADEIRA DENSIFICADA (MDF), EM BLOCOS, PRANCHAS, LAMINAS OU PERFIS; FABRICACAO DE",
  },
  { cnae: "1621800", descricao: "LAMINAS DE MADEIRA; FABRICACAO DE" },
  { cnae: "1621800", descricao: "MADEIRA CORTADA EM FOLHAS; FABRICACAO DE" },
  {
    cnae: "1621800",
    descricao:
      "CHAPAS E PLACAS DE MADEIRA PRENSADA OU AGLOMERADA; FABRICACAO DE",
  },
  {
    cnae: "1621800",
    descricao:
      "FOLHA DE MADEIRA PARA FOLHEADOS OU PARA COMPENSADO; FABRICACAO DE",
  },
  {
    cnae: "1621800",
    descricao:
      "PAINEIS E PARTICULAS DE MADEIRA E DE OUTRAS MATERIAS LENHOSAS; FABRICACAO DE",
  },
  { cnae: "1621800", descricao: "RESINADOS (COMPENSADOS); FABRICACAO DE" },
  {
    cnae: "1621800",
    descricao: "MADEIRA COMPENSADA OU CONTRAPLACADA; FABRICACAO DE",
  },
  { cnae: "1621800", descricao: "FOLHAS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "16226",
    descricao:
      "FABRICACAO DE ESTRUTURAS DE MADEIRA E DE ARTIGOS DE CARPINTARIA PARA  CONSTRUCAO",
  },
  {
    cnae: "1622601",
    descricao: "CASAS DE MADEIRA PRE-FABRICADOS; FABRICACAO DE",
  },
  {
    cnae: "1622601",
    descricao:
      "CASAS DE MADEIRA PRE-FABRICADOS; MONTAGEM DE (QUANDO REALIZADA PELA UNIDADE FABRICANTE)",
  },
  {
    cnae: "1622602",
    descricao: "PECAS DE MADEIRA PARA INSTALACOES COMERCIAIS; FABRICACAO DE",
  },
  { cnae: "1622602", descricao: "VENEZIANAS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "1622602",
    descricao: "DIVISOES E PARTICOES DE MADEIRA; FABRICACAO DE",
  },
  { cnae: "1622602", descricao: "PORTAS DE MADEIRA; FABRICACAO DE" },
  { cnae: "1622602", descricao: "DIVISORIAS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "1622602",
    descricao:
      "ESQUADRIAS DE MADEIRA; MONTAGEM DE, QUANDO REALIZADA PELA UNIDADE FABRICANTE",
  },
  { cnae: "1622602", descricao: "BATENTES DE MADEIRA; FABRICACAO DE" },
  { cnae: "1622602", descricao: "ESQUADRIAS DE MADEIRA; FABRICACAO DE" },
  { cnae: "1622602", descricao: "JANELAS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "1622602",
    descricao:
      "CAIXILHOS, ALIZARES, SOLEIRAS E SEMELHANTES DE MADEIRA; FABRICACAO DE",
  },
  {
    cnae: "1622699",
    descricao: "FORMAS DE MADEIRA PARA CONSTRUCAO CIVIL; FABRICACAO DE",
  },
  { cnae: "1622699", descricao: "BARROTES E CAIBROS; PRODUCAO DE" },
  {
    cnae: "1622699",
    descricao: "PAINEIS DE MADEIRA PARA ASSOALHOS; FABRICACAO DE",
  },
  {
    cnae: "1622699",
    descricao:
      "OBRAS DIVERSAS DE CARPINTARIA PARA CONSTRUCAO, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "1622699",
    descricao:
      "ESTRUTURAS DE MADEIRA E VIGAMENTOS PARA CONSTRUCAO; FABRICACAO DE",
  },
  { cnae: "1622699", descricao: "VIGAS DE MADEIRA; PRODUCAO DE" },
  { cnae: "1622699", descricao: "CARPINTARIA; SERVICO DE" },
  {
    cnae: "1622699",
    descricao: "ARMACOES DE MADEIRA DE CONCRETO; FABRICACAO DE",
  },
  {
    cnae: "1622699",
    descricao: "ARTIGOS DE CARPINTARIA PARA CONSTRUCAO; FABRICACAO DE",
  },
  { cnae: "1622699", descricao: "ESCADAS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "16234",
    descricao: "FABRICACAO DE ARTEFATOS DE TANOARIA E DE EMBALAGENS DE MADEIRA",
  },
  {
    cnae: "1623400",
    descricao: "ADUELAS (ARTEFATOS DE TANOARIA); FABRICACAO DE",
  },
  {
    cnae: "1623400",
    descricao: "CAIXAS, CAIXOTES E ENGRADADOS DE MADEIRA; FABRICACAO DE",
  },
  {
    cnae: "1623400",
    descricao: "ESTRADOS DE MADEIRA PARA CARGAS; FABRICACAO DE",
  },
  {
    cnae: "1623400",
    descricao: "PALLETS (ESTRADOS) DE MADEIRA; FABRICACAO DE",
  },
  {
    cnae: "1623400",
    descricao:
      "CARRETEIS DE MADEIRA PARA CABOS (PARA ENROLAMENTO E TRANSPORTE DE CABOS TELEFONICOS E SEMELHANTES); FABRICACAO DE",
  },
  { cnae: "1623400", descricao: "PIPAS DE MADEIRA; FABRICACAO DE" },
  { cnae: "1623400", descricao: "PALLETS (PALETES) DE MADEIRA; FABRICACAO DE" },
  { cnae: "1623400", descricao: "TONEIS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "1623400",
    descricao: "ARTEFATOS DE MADEIRA PARA EMBALAGENS; FABRICACAO DE",
  },
  {
    cnae: "1623400",
    descricao: "BARRIS, BARRICAS, PIPAS E TONEIS; FABRICACAO DE",
  },
  {
    cnae: "1623400",
    descricao:
      "CAIXINHAS DE MADEIRA PARA EMBALAR FOSFOROS DE SEGURANCA; FABRICACAO DE",
  },
  {
    cnae: "16293",
    descricao:
      "FABRICACAO DE ARTEFATOS DE MADEIRA, PALHA, CORTICA, VIME E MATERIAL TRANCADO NAO ESPECIFICADOS ANTERIORMENTE, EXCETO MOVEIS",
  },
  {
    cnae: "1629301",
    descricao:
      "BRIQUETES DE BAGACO DE CANA OU DE MATERIAIS SEMELHANTES (BIOMASSA); FABRICACAO DE",
  },
  { cnae: "1629301", descricao: "ROLOS DE MADEIRA PARA MASSAS; FABRICACAO DE" },
  {
    cnae: "1629301",
    descricao: "DISPLAYS(EXPOSITORES/MOSTRUARIOS) DE MADEIRA; FABRICACAO DE",
  },
  {
    cnae: "1629301",
    descricao:
      "BRIQUETES (PELLETS) A PARTIR DA SECAGEM E COMPACTACAO DE BIOMASSA (BAGACO DE CANA DE ACUCAR, CASCA DE ARROZ OU DE AMENDOIM, BAGACO DE MANDIOCA, PO OU CAVACOS DE MADEIRA E OUTROS RESIDUOS SEMELHANTES)",
  },
  { cnae: "1629301", descricao: "MOLDES E MODELOS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "1629301",
    descricao: "ARTIGOS DE MADEIRA TORNEADA, N.E.; FABRICACAO DE",
  },
  {
    cnae: "1629301",
    descricao: "CARRETEIS E CARRETILHAS DE MADEIRA; FABRICACAO DE",
  },
  {
    cnae: "1629301",
    descricao: "CABOS DE MADEIRA PARA FERRAMENTAS E UTENSILIOS; FABRICACAO DE",
  },
  {
    cnae: "1629301",
    descricao: "CAVILHAS (ENCAIXES PARA MOVEIS); FABRICACAO DE",
  },
  { cnae: "1629301", descricao: "ARGOLAS DE MADEIRA; FABRICACAO DE" },
  { cnae: "1629301", descricao: "TAMPOS SANITARIOS DE MADEIRA; FABRICACAO DE" },
  { cnae: "8592999", descricao: "ESCULTURA; CURSO, ENSINO DE" },
  {
    cnae: "1629301",
    descricao: "ESTOJOS E CAIXAS DE MADEIRA PARA FAQUEIROS; FABRICACAO DE",
  },
  { cnae: "1629301", descricao: "TABUAS DE MADEIRA PARA CARNE; FABRICACAO DE" },
  { cnae: "1629301", descricao: "GAIOLA E ALCAPAO DE MADEIRA; FABRICACAO DE" },
  { cnae: "1629301", descricao: "POLIAS E RODAS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "1629301",
    descricao:
      "SERRAGEM,DESPERDICIOS E RESIDUOS,DE MADEIRA, MESMO AGLOMERADOS EM TORAS, BRIQUETES, PELLETS OU FORMAS SEMELHANTES",
  },
  {
    cnae: "1629301",
    descricao: "ARCOS E BASTIDORES DE MADEIRA, PARA BORDADOS; FABRICACAO DE",
  },
  {
    cnae: "1629301",
    descricao: "PALITOS E PALHETAS DE MADEIRA PARA FOSFOROS; FABRICACAO DE",
  },
  { cnae: "1629301", descricao: "ROLDANAS DE MADEIRA; FABRICACAO DE" },
  { cnae: "1629301", descricao: "PECAS DE MADEIRA TORNEADA; FABRICACAO DE" },
  {
    cnae: "1629301",
    descricao:
      "ARMACOES DE MADEIRA PARA ESCOVAS E ARTIGOS SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "1629301",
    descricao: "IMAGENS E OUTRAS OBRAS DE ENTALHE EM MADEIRA; FABRICACAO DE",
  },
  { cnae: "1629301", descricao: "PILAO DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "1629301",
    descricao:
      "ESTATUETAS, OBJETOS DE ORNAMENTACAO, IMAGENS E OUTRAS OBRAS DE ENTALHE EM MADEIRA; FABRICACAO DE",
  },
  { cnae: "1629301", descricao: "ARTESANATO DE MADEIRA; FABRICACAO DE" },
  { cnae: "1629301", descricao: "ALCAS E PUXADORES DE MADEIRA; FABRICACAO DE" },
  { cnae: "1629301", descricao: "PAINEIS DE MADEIRA; FABRICACAO DE" },
  { cnae: "1629301", descricao: "LANCADEIRA DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "1629301",
    descricao: "ARMACOES DE MADEIRA PARA ARREIOS; FABRICACAO DE",
  },
  { cnae: "1629301", descricao: "FORMAS E MODELOS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "1629301",
    descricao: "TABUAS DE MADEIRA PARA PASSAR ROUPAS; FABRICACAO DE",
  },
  {
    cnae: "1629301",
    descricao: "CAVALETES DE MADEIRA PARA DESENHO E PINTURA; FABRICACAO DE",
  },
  {
    cnae: "1629301",
    descricao: "PALITOS E PAS DE MADEIRA PARA SORVETES; FABRICACAO DE",
  },
  {
    cnae: "1629301",
    descricao:
      "MOLDURAS DE MADEIRA PARA FOTOGRAFIAS, QUADROS E ESPELHOS; FABRICACAO DE",
  },
  {
    cnae: "1629301",
    descricao:
      "FERRAMENTAS E ARMACOES DE FERRAMENTAS DE MADEIRA; FORMAS, ALARGADEIRAS E ESTICADORES DE MADEIRA PARA CALCADOS; FABRICACAO DE",
  },
  {
    cnae: "1629301",
    descricao:
      "CABOS DE MADEIRA PARA VASSOURAS, PINCEIS, ESCOVAS E SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "1629301", descricao: "ESPULAS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "1629301",
    descricao:
      "BRIQUETES, LENHAS OU CARVOES ECOLOGICOS ( DE RESIDUOS DE MADEIRA, CASCA DE COCO OU OUTRAS FIBRAS VEGETAIS); FABRICACAO DE",
  },
  {
    cnae: "1629301",
    descricao: "ESTOJOS E CAIXAS DE MADEIRA PARA JOIAS; FABRICACAO DE",
  },
  {
    cnae: "1629301",
    descricao:
      "BRIQUETES DE BAGACO DE CANA OU DE MATERIAS SEMELHANTES,FABRICACAO DE",
  },
  {
    cnae: "1629301",
    descricao: "PALITOS DE MADEIRA PARA USO PESSOAL; FABRICACAO DE",
  },
  {
    cnae: "1629301",
    descricao:
      "UTENSILIOS DE MADEIRA PARA MESA E COZINHA, INCLUSIVE CAIXAS PARA CONDIMENTOS; FABRICACAO DE",
  },
  {
    cnae: "1629301",
    descricao: "CARVAO DE RESIDUO DE MADEIRA; CARVAO ECOLOGICO; PRODUCAO DE",
  },
  {
    cnae: "1629302",
    descricao:
      "PENEIRAS, CESTOS E SEMELHANTES DE MATERIAL TRANCADO; FABRICACAO DE",
  },
  { cnae: "1629302", descricao: "ESTEIRAS DE PALHA; FABRICACAO DE" },
  { cnae: "1629302", descricao: "CESTOS DE VIME; FABRICACAO DE" },
  {
    cnae: "1629302",
    descricao: "ESTEIRAS (DE MATERIAS VEGETAIS); FABRICACAO DE",
  },
  {
    cnae: "1629302",
    descricao:
      "DISCOS, BATOQUES, GAXETAS E ARTIGOS SEMELHANTES DE CORTICA; FABRICACAO DE",
  },
  { cnae: "1629302", descricao: "BOIAS DE CORTICA; FABRICACAO DE" },
  {
    cnae: "1629302",
    descricao: "BALAIOS, CESTOS E JACAS DE VIME, BAMBU, ETC.; FABRICACAO DE",
  },
  {
    cnae: "1629302",
    descricao:
      "ARTESANATO EM CORTICA, BAMBU, PALHA, VIME OU OUTROS MATERIAIS TRANCADOS; FABRICACAO DE",
  },
  { cnae: "1629302", descricao: "CORTICA GRANULADA; FABRICACAO DE" },
  {
    cnae: "1629302",
    descricao: "ARTEFATOS DE VIME (EXCETO MOVEIS); FABRICACAO DE",
  },
  {
    cnae: "1629302",
    descricao:
      "CAPACHO, DIVISORIAS E OUTROS ARTEFATOS DE MATERIAL TRANCADO; FABRICACAO DE",
  },
  {
    cnae: "1629302",
    descricao: "ARTEFATOS DE BAMBU (EXCETO MOVEIS); FABRICACAO DE",
  },
  {
    cnae: "1629302",
    descricao: "ARTEFATOS DE PALHA TRANCADA (EXCETO MOVEIS); FABRICACAO DE",
  },
  {
    cnae: "1629302",
    descricao: "ARTEFATOS DE JUNCO (EXCETO MOVEIS); FABRICACAO DE",
  },
  {
    cnae: "1629302",
    descricao:
      "ARTEFATOS DE CORTICA (EXCETO PARA SEGURANCA E PROTECAO); FABRICACAO DE",
  },
  { cnae: "1629302", descricao: "PAINEIS DE PALHINHA; FABRICACAO DE" },
  { cnae: "1629302", descricao: "CHAPAS DE CORTICA; FABRICACAO DE" },
  { cnae: "1629302", descricao: "ROLHAS DE CORTICA; FABRICACAO DE" },
  {
    cnae: "17109",
    descricao:
      "FABRICACAO DE CELULOSE E OUTRAS PASTAS PARA A FABRICACAO DE PAPEL",
  },
  {
    cnae: "1710900",
    descricao:
      "PASTAS PARA FABRICACAO DE PAPEL OU DISSOLUCAO (SEMIQUIMICA, TERMOMECANICA, ETC); FABRICACAO DE",
  },
  { cnae: "1710900", descricao: "PASTA QUIMICA; FABRICACAO DE" },
  {
    cnae: "1710900",
    descricao:
      "PASTAS DE MATERIAS FIBROSAS CELULOSICAS, EXCETO DE MADEIRA; FABRICACAO DE",
  },
  { cnae: "1710900", descricao: "CELULOSE DE TODOS OS TIPOS; FABRICACAO DE" },
  { cnae: "1710900", descricao: "PASTA MECANICA; FABRICACAO DE" },
  { cnae: "17214", descricao: "FABRICACAO DE PAPEL" },
  { cnae: "1721400", descricao: "PAPEL-BIBLIA; FABRICACAO DE" },
  {
    cnae: "1721400",
    descricao:
      "DESPERDICIOS OU APARAS DE PAPEL OU DE PAPEL-CARTAO; FABRICACAO DE",
  },
  { cnae: "1721400", descricao: "PAPEL-FILTRO; FABRICACAO DE" },
  {
    cnae: "1721400",
    descricao:
      "PAPEL TESTLINER DE PESO NAO SUPERIOR A 150G/M2, NAO REVESTIDO; FABRICACAO DE",
  },
  { cnae: "1721400", descricao: "PAPEL ABSORVENTE; FABRICACAO DE" },
  {
    cnae: "1721400",
    descricao:
      "PAPEL CARBONO, PAPEL AUTOCOPIATIVO E OUTROS PAPEIS PARA COPIA OU DUPLICACAO, EM ROLOS OU FOLHAS; FABRICACAO DE",
  },
  { cnae: "1721400", descricao: "PAPEL TOALHA; FABRICACAO DE" },
  { cnae: "1721400", descricao: "PAPEL GOMADO; FABRICACAO DE" },
  {
    cnae: "1721400",
    descricao:
      "PAPEL ONDULADO, GOFRADO, ESTAMPADO, CORRUGADO, ETC., NAO ESPECIFICADO; FABRICACAO DE",
  },
  {
    cnae: "1721400",
    descricao:
      "PAPEL OBTIDO POR COLAGEM DE FOLHAS SOBREPOSTAS, NAO REVESTIDO NEM IMPREGNADO; FABRICACAO DE",
  },
  { cnae: "1721400", descricao: "PAPEL JORNAL; FABRICACAO DE" },
  { cnae: "1721400", descricao: "PAPEL FOSFORO; FABRICACAO DE" },
  { cnae: "1721400", descricao: "PAPEL MANILHA OU MANILHINHA ; FABRICACAO DE" },
  {
    cnae: "1721400",
    descricao: "PAPEL VEGETAL, TRANSPARENTE, PARA DESENHO; FABRICACAO DE",
  },
  { cnae: "1721400", descricao: "PAPEL HAVANA E LD; FABRICACAO DE" },
  { cnae: "1721400", descricao: "PAPEL MACULATURA; FABRICACAO DE" },
  { cnae: "1721400", descricao: "PAPEL COUCHE; FABRICACAO DE" },
  { cnae: "1721400", descricao: "PAPEL HD; FABRICACAO DE" },
  {
    cnae: "1721400",
    descricao:
      "PAPEL PARA USO NA ESCRITA, IMPRESSAO E OUTROS USOS GRAFICOS, REVESTIDOS DE MATERIA INORGANICA; FABRICACAO DE",
  },
  {
    cnae: "1721400",
    descricao:
      "PAPEL PREPARADO PARA EMBALAGENS, EM BOBINAS, ROLOS E FOLHAS; FABRICACAO DE",
  },
  {
    cnae: "1721400",
    descricao:
      "PAPEL REVESTIDO OU IMPREGNADO DE SUBSTANCIAS BETUMINOSAS, PLASTICO, CERA, PARAFINA, ETC, MESMO COLORIDOS OU DECORADOS; FABRICACAO DE",
  },
  { cnae: "1721400", descricao: "PAPEL BOUFFANT; FABRICACAO DE" },
  { cnae: "1721400", descricao: "PAPEL SUPER BOND; FABRICACAO DE" },
  {
    cnae: "1721400",
    descricao: "PAPEL PARA EMBALAGEM DE FRUTAS; FABRICACAO DE",
  },
  { cnae: "1721400", descricao: "PAPEL STRONG; FABRICACAO DE" },
  {
    cnae: "1741902",
    descricao: "CADERNOS E CADERNETAS ESCOLARES; FABRICACAO DE",
  },
  {
    cnae: "1721400",
    descricao:
      "PAPEL EM ROLOS CONTINUOS UTILIZADOS NA FABRICACAO DE PAPEIS HIGIENICOS, LENCOS,TOALHAS OU SEMELHANTES PARA USOS DOMESTICO OU SANITARIO; FABRICACAO DE",
  },
  { cnae: "1721400", descricao: "PAPEL MIOLO; FABRICACAO DE" },
  { cnae: "1721400", descricao: "PAPEL PARA CAIXAS E FORROS; FABRICACAO DE" },
  { cnae: "1721400", descricao: "PAPEIS PARA EMBALAGEM; FABRICACAO DE" },
  {
    cnae: "1721400",
    descricao:
      "PAPEL PARA CIGARROS NAO CORTADO, PAPEL-FELTRO OU PAPEIS DE OUTROS TIPOS, NAO REVESTIDOS; FABRICACAO DE",
  },
  { cnae: "1721400", descricao: "PAPEL ESTIVA; FABRICACAO DE" },
  { cnae: "1721400", descricao: "PAPEL ACETINADO; FABRICACAO DE" },
  {
    cnae: "1721400",
    descricao: "PAPEL PRATEADO, DOURADO OU CROMADO; FABRICACAO DE",
  },
  { cnae: "1721400", descricao: "PAPEL IMPRENSA; FABRICACAO DE" },
  { cnae: "1721400", descricao: "PAPEIS PARA IMPRESSAO; FABRICACAO DE" },
  {
    cnae: "1721400",
    descricao:
      "PAPEL PROPRIO PARA A FABRICACAO DE PAPEL DE PAREDE; FABRICACAO DE",
  },
  {
    cnae: "1721400",
    descricao: "PAPEL APERGAMINHADO OU SULFITE PARA ESCREVER; FABRICACAO DE",
  },
  {
    cnae: "1721400",
    descricao:
      "PAPEL PROPRIO PARA A FABRICACAO DE PAPEL-FOTOSSENSIVEL, TERMOSSENSIVEL OU ELETROSSENSIVEL; FABRICACAO DE",
  },
  { cnae: "1721400", descricao: "PAPEL HAMBURGUES OU MACARRAO; FABRICACAO DE" },
  { cnae: "1721400", descricao: "PAPEL CREPOM; FABRICACAO DE" },
  { cnae: "1721400", descricao: "PAPEL SULFITE PARA ESCREVER; FABRICACAO DE" },
  {
    cnae: "1721400",
    descricao:
      "PAPEL DE ACABAMENTO ESPECIAL, IMPREGNADO OU REVESTIDO; FABRICACAO DE",
  },
  { cnae: "1721400", descricao: "PAPEL OFSETE (OFFSET); FABRICACAO DE" },
  { cnae: "1721400", descricao: "PAPEIS PARA ESCREVER; FABRICACAO DE" },
  {
    cnae: "1721400",
    descricao: "PAPEL MONOLUCIDO PARA IMPRESSAO; FABRICACAO DE",
  },
  {
    cnae: "1721400",
    descricao:
      "PAPEL-PERGAMINHO, PAPEL IMPERMEAVEL A GORDURAS E OUTROS PAPEIS CALANDRADOS TRANSPARENTES EM ROLOS OU EM FOLHAS; FABRICACAO DE",
  },
  { cnae: "1721400", descricao: "PAPEL SEDA; FABRICACAO DE" },
  { cnae: "1721400", descricao: "PAPEL IMPERMEAVEL; FABRICACAO DE" },
  { cnae: "1721400", descricao: "PAPEL KRAFT; FABRICACAO DE" },
  { cnae: "17222", descricao: "FABRICACAO DE CARTOLINA E PAPELCARTAO" },
  { cnae: "1722200", descricao: "CARTOLINAS; FABRICACAO DE" },
  { cnae: "1722200", descricao: "PAPEL-CARTAO; FABRICACAO DE" },
  { cnae: "1722200", descricao: "PAPEL-CARTAO DUPLEX; FABRICACAO DE" },
  { cnae: "1722200", descricao: "PAPEL-CARTAO TRIPLEX; FABRICACAO DE" },
  { cnae: "17311", descricao: "FABRICACAO DE EMBALAGENS DE PAPEL" },
  {
    cnae: "1731100",
    descricao:
      "EMBALAGENS PADRONIZADAS DE PAPEL DE ACABAMENTO ESPECIAL; FABRICACAO DE",
  },
  { cnae: "1731100", descricao: "SACOS DE PAPEL MULTIFOLIADOS; FABRICACAO DE" },
  {
    cnae: "1731100",
    descricao: "PAPEL DE EMBALAGEM PARA CIGARROS; FABRICACAO DE",
  },
  {
    cnae: "1731100",
    descricao: "PAPEL DE EMBALAGEM PARA SABONETES; FABRICACAO DE",
  },
  { cnae: "1731100", descricao: "SACOS DE PAPEL IMPERMEAVEL; FABRICACAO DE" },
  {
    cnae: "1731100",
    descricao: "SACOS DE PAPEL IMPRESSOS OU NAO; FABRICACAO DE",
  },
  {
    cnae: "1731100",
    descricao:
      "PAPEL PARA CIGARROS MESMO CORTADO EM DIMENSOES ESPECIFICAS; FABRICACAO DE",
  },
  {
    cnae: "1731100",
    descricao: "EMBALAGENS DE PAPEL, IMPRESSAS OU NAO; FABRICACAO DE",
  },
  {
    cnae: "1731100",
    descricao: "ENVOLTORIOS PARA BALAS E DOCES; FABRICACAO DE",
  },
  { cnae: "1731100", descricao: "SACOS DE PAPEL KRAFT; FABRICACAO DE" },
  {
    cnae: "1731100",
    descricao:
      "SACOS, SACOLAS E BOLSAS DE PAPEL, IMPRESSAS OU NAO; FABRICACAO DE",
  },
  {
    cnae: "17320",
    descricao: "FABRICACAO DE EMBALAGENS DE CARTOLINA E PAPELCARTAO",
  },
  {
    cnae: "1732000",
    descricao:
      "ESTOJOS OU CARTUCHOS DE PAPEL-CARTAO, IMPRESSOS OU NAO, FABRICACAO DE",
  },
  {
    cnae: "1732000",
    descricao:
      "EMBALAGENS DE PAPEL-CARTAO, MESMO LAMINADAS ENTRE SI OU COM OUTRO SUPORTE CELULOSICO; FABRICACAO DE",
  },
  {
    cnae: "1732000",
    descricao: "EMBALAGENS DE CARTOLINA, IMPRESSAS OU NAO; FABRICACAO DE",
  },
  {
    cnae: "1732000",
    descricao: "EMBALAGENS DE PAPEL-CARTAO, IMPRESSAS OU NAO; FABRICACAO DE",
  },
  {
    cnae: "1732000",
    descricao:
      "CARTELAS, LUVAS, SOLAPAS OU DEMAIS ACESSORIOS PARA EMBALAGENS, DE PAPEL-CARTAO; FABRICACAO DE",
  },
  {
    cnae: "17338",
    descricao: "FABRICACAO DE CHAPAS E DE EMBALAGENS DE PAPELAO ONDULADO",
  },
  {
    cnae: "1733800",
    descricao:
      "EMBALAGENS DE PAPELAO ONDULADO, IMPRESSAS OU NAO; FABRICACAO DE",
  },
  {
    cnae: "1733800",
    descricao: "CAIXAS DE PAPELAO ONDULADO, IMPRESSAS OU NAO; FABRICACAO DE",
  },
  {
    cnae: "1733800",
    descricao:
      "CARTUCHOS E CILINDROS DE PAPELAO ONDULADO PARA EMBALAGEM, IMPRESSOS OU NAO; FABRICACAO DE",
  },
  {
    cnae: "1733800",
    descricao: "FACE SIMPLES DE PAPELAO ONDULADO; FABRICACAO DE",
  },
  {
    cnae: "1733800",
    descricao:
      "ACESSORIOS PARA EMBALAGENS DE PAPELAO ONDULADO, IMPRESSOS OU NAO; FABRICACAO DE",
  },
  { cnae: "1733800", descricao: "PAPELAO ONDULADO; FABRICACAO DE" },
  { cnae: "1733800", descricao: "CHAPAS DE PAPELAO ONDULADO; FABRICACAO DE" },
  {
    cnae: "17419",
    descricao:
      "FABRICACAO DE PRODUTOS DE PAPEL, CARTOLINA, PAPELCARTAO E PAPELAO ONDULADO PARA USO COMERCIAL E DE ESCRITORIO",
  },
  {
    cnae: "1741901",
    descricao: "FORMULARIOS CONTINUOS IMPRESSOS OU NAO; FABRICACAO DE",
  },
  {
    cnae: "1741902",
    descricao:
      "PAPEL AUTOCOPIATIVO (COM CORANTES MICROENCAPSULADOS); FABRICACAO DE",
  },
  { cnae: "1741902", descricao: "FITAS ADESIVAS DE PAPEL; FABRICACAO DE" },
  { cnae: "1741902", descricao: "PAPEL PARA CARTAS; FABRICACAO DE" },
  { cnae: "1741902", descricao: "PAPEL ALMACO; FABRICACAO DE" },
  {
    cnae: "1741902",
    descricao: "PAPEL PARA OFICIO, CARTAS E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "1741902",
    descricao:
      "ARTEFATOS DE PAPEL PARA APARELHOS REGISTRADORES, INCLUSIVE EM BOBINAS; FABRICACAO DE",
  },
  { cnae: "1741902", descricao: "PAPEL CARBONO; FABRICACAO DE" },
  { cnae: "1741902", descricao: "AGENDAS; FABRICACAO DE" },
  {
    cnae: "1741902",
    descricao:
      "ARTEFATOS IMPRESSOS DE PAPELARIA, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "1741902",
    descricao:
      "ETIQUETAS DE PAPEL NAO ADESIVAS (TAG), IMPRESSAS OU NAO; FABRICACAO DE",
  },
  { cnae: "1741902", descricao: "NOTAS PROMISSORIAS; FABRICACAO DE" },
  {
    cnae: "1741902",
    descricao:
      "ARTEFATOS DE PAPEL, PAPELAO, CARTOLINA OU PAPEL-CARTAO PARA ESCRITORIO; FABRICACAO DE",
  },
  {
    cnae: "1741902",
    descricao:
      "ARTEFATOS DIVERSOS DE PAPEL UTILIZADOS PARA ESCRITA OU IMPRESSAO, IMPRESSOS, ESTAMPADOS OU PERFURADOS; FABRICACAO DE",
  },
  {
    cnae: "1741902",
    descricao: "ENVELOPES DE PAPEL IMPRESSOS OU NAO; FABRICACAO DE",
  },
  {
    cnae: "1741902",
    descricao: "PAPEL QUADRICULADO, MILIMETRADO E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "1741902",
    descricao:
      "CLASSIFICADORES, GUIAS, FICHAS E SEPARADORES DE PAPELAO OU CARTOLINA, PARA ARQUIVOS; FABRICACAO DE",
  },
  { cnae: "1741902", descricao: "ESTENCIL PARA MIMEOGRAFO; FABRICACAO DE" },
  { cnae: "1741902", descricao: "PAPEL MILIMETRADO; FABRICACAO DE" },
  {
    cnae: "1741902",
    descricao:
      "PASTAS DE CARTOLINA PARA ESCRITORIO, COM OU SEM FERRAGENS; FABRICACAO DE",
  },
  {
    cnae: "1741902",
    descricao: "ETIQUETAS ADESIVAS DE PAPEL, IMPRESSAS OU NAO; FABRICACAO DE",
  },
  {
    cnae: "1741902",
    descricao: "PAPEL PARA MIMEOGRAFO EM RESMA; FABRICACAO DE",
  },
  {
    cnae: "1741902",
    descricao: "BOBINAS DE PAPEL PARA MAQUINAS; FABRICACAO DE",
  },
  {
    cnae: "1741902",
    descricao: "LIVROS DE ESCRITURACAO CONTABIL E FISCAL; FABRICACAO DE",
  },
  {
    cnae: "1741902",
    descricao:
      "ARTEFATOS DE PAPEL-CARTAO GOMADO OU ADESIVO EM TIRAS OU EM ROLOS; FABRICACAO DE",
  },
  {
    cnae: "1741902",
    descricao:
      "ARTIGOS DIVERSOS PARA CORRESPONDENCIA (AEROGRAMAS, BILHETES-POSTAIS OU OUTROS ARTIGOS PARA CORRESPONDENCIA); FABRICACAO DE",
  },
  {
    cnae: "17427",
    descricao:
      "FABRICACAO DE PRODUTOS DE PAPEL PARA USOS DOMESTICO E HIGIENICOSANITARIO",
  },
  { cnae: "1742701", descricao: "FRALDAS DESCARTAVEIS; FABRICACAO DE" },
  { cnae: "1742702", descricao: "ABSORVENTES HIGIENICOS; FABRICACAO DE" },
  { cnae: "1742799", descricao: "FORMINHAS DE PAPEL; FABRICACAO DE" },
  { cnae: "1742799", descricao: "FILTROS DE PAPEL; FABRICACAO DE" },
  { cnae: "1742799", descricao: "COADORES DE PAPEL; FABRICACAO DE" },
  { cnae: "1742799", descricao: "TOALHAS DE PAPEL PARA MAOS; FABRICACAO DE" },
  { cnae: "1742799", descricao: "PAPEL SANITARIO (HIGIENICO); FABRICACAO DE" },
  { cnae: "1742799", descricao: "TOALHA DE PAPEL PARA MESA; FABRICACAO DE" },
  { cnae: "1742799", descricao: "PAPEL HIGIENICO; FABRICACAO DE" },
  {
    cnae: "1742799",
    descricao: "LENCOS DE PAPEL,EXCETO UMEDECIDOS; FABRICACAO DE",
  },
  { cnae: "1742799", descricao: "FORRA-FOGAO; FABRICACAO DE" },
  { cnae: "1742799", descricao: "GUARDANAPOS DE PAPEL; FABRICACAO DE" },
  {
    cnae: "17494",
    descricao:
      "FABRICACAO DE PRODUTOS DE PASTAS CELULOSICAS, PAPEL, CARTOLINA, PAPELCARTAO E PAPELAO ONDULADO NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "1749400",
    descricao:
      "PECAS OU ACESSORIOS PARA MAQUINAS OU EQUIPAMENTOS DE TRANSPORTE DE PAPEL, PAPELAO ONDULADO, CARTOLINA OU PAPEL-CARTAO; FABRICACAO DE",
  },
  {
    cnae: "1749400",
    descricao:
      "ARTEFATOS DE PAPELAO ONDULADO, CARTOLINA OU PAPEL-CARTAO, N.E.; FABRICACAO DE",
  },
  {
    cnae: "1749400",
    descricao: "EMBALAGENS DIVERSAS DE PASTAS DE CELULOSE, FABRICACAO DE",
  },
  { cnae: "1749400", descricao: "PAPEL ESTAMPADO OU FANTASIA; FABRICACAO DE" },
  {
    cnae: "1749400",
    descricao: "COPOS DE PAPEL OU DE PAPEL-CARTAO; FABRICACAO DE",
  },
  {
    cnae: "1749400",
    descricao:
      "ALBUNS DE PAPEL-CARTAO PARA FOTOGRAFIAS, AMOSTRAS OU COLECOES; FABRICACAO DE",
  },
  {
    cnae: "1749400",
    descricao: "BLOCOS E CHAPAS FILTRANTES, DE PASTA DE PAPEL; FABRICACAO DE",
  },
  {
    cnae: "1749400",
    descricao:
      "ARTEFATOS DE PAPEL, PAPELAO ONDULADO, CARTOLINA OU PAPEL-CARTAO DE ACABAMENTO ESPECIAL PARA REVESTIMENTO; FABRICACAO DE",
  },
  {
    cnae: "1749400",
    descricao:
      "CARRETEIS, BOBINAS E SUPORTES SEMELHANTES DE PAPEL, PAPEL-CARTAO E PASTA DE PAPEL; FABRICACAO DE",
  },
  {
    cnae: "1749400",
    descricao:
      "REVESTIMENTOS PARA PAVIMENTOS COM SUPORTE DE PAPEL OU DE PAPEL-CARTAO; FABRICACAO DE",
  },
  {
    cnae: "1749400",
    descricao: "CANUDOS DE PAPEL PARA REFRESCO; FABRICACAO DE",
  },
  { cnae: "1749400", descricao: "BANDEIROLAS DE PAPEL; FABRICACAO DE" },
  {
    cnae: "1749400",
    descricao:
      "ARTESANATO EM PASTAS CELULOSICAS, PAPEL, PAPEL-CARTAO OU PAPELAO; FABRICACAO DE",
  },
  {
    cnae: "1749400",
    descricao:
      "CORTE E DOBRA DE PAPEL(BOBINA) NAO ASSOCIADO A GRAFICA OU A IMPRESSAO; SERVICO DE",
  },
  {
    cnae: "1749400",
    descricao: "ARTIGOS DE FIBRA PRENSADA OU ISOLANTE; FABRICACAO DE",
  },
  {
    cnae: "1749400",
    descricao: "CONFETES, SERPENTINAS E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "1749400",
    descricao:
      "POLPA DE MADEIRA OU DE PASTA MECANICA MOLDADA EM ARTIGOS DIVERSOS; FABRICACAO DE",
  },
  {
    cnae: "1749400",
    descricao: "PAPEL IMPREGNADO OU REVESTIDO; FABRICACAO DE",
  },
  {
    cnae: "1749400",
    descricao: "FLAMULAS E BANDEIROLAS DE PAPEL; FABRICACAO DE",
  },
  {
    cnae: "1749400",
    descricao:
      "PAPEL PARA CIGARROS, CORTADO EM DIMENSOES PROPRIAS, EM FOLHAS, TUBOS OU ROLOS; FABRICACAO DE",
  },
  {
    cnae: "1749400",
    descricao: "REVESTIMENTOS DE MATERIAS TEXTEIS PARA PAREDES, FABRICACAO DE",
  },
  {
    cnae: "1749400",
    descricao:
      "BANDEJAS, TRAVESSAS, PRATOS, COPOS E ARTIGOS SEMELHANTES DE PAPEL OU PAPEL-CARTAO.",
  },
  {
    cnae: "18113",
    descricao:
      "IMPRESSAO DE JORNAIS, LIVROS, REVISTAS E OUTRAS PUBLICACOES PERIODICAS",
  },
  { cnae: "1811301", descricao: "GRAFICA: JORNAIS; IMPRESSAO SOB ENCOMENDA" },
  { cnae: "1811301", descricao: "JORNAIS; IMPRESSAO SOB ENCOMENDA" },
  {
    cnae: "1811302",
    descricao: "REVISTAS NAO PERIODICAS DE CONSUMO; IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1811302",
    descricao: "REVISTAS PERIODICAS DE CONSUMO; IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1811302",
    descricao:
      "LIVROS OU OUTROS IMPRESSOS DIDATICOS E PARADIDATICOS PARA PROGRAMAS GOVERNAMENTAIS, IMPRESSOS SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "1811302",
    descricao:
      "LIVROS, BROCHURAS OU IMPRESSOS LITERARIOS, IMPRESSOS SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "1811302",
    descricao:
      "LIVROS EM GERAL (INCLUSIVE MAPAS E ATLAS); IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1811302",
    descricao:
      "LIVROS CIENTIFICO, TECNICO E PROFISSIONAL (CTP) IMPRESSOS SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "1811302",
    descricao:
      "LIVROS, BROCHURAS OU IMPRESSOS SEMELHANTES (EXCETO LITERARIO, DIDATICOS OU INFANTIS), IMPRESSOS SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "1811302",
    descricao: "GRAFICA: REVISTAS E OUTROS PERIODICOS; IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1811302",
    descricao:
      "REVISTAS NAO PERIODICAS GRATUITAS (B TO B E CUSTOMIZADAS);  IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1811302",
    descricao:
      "BIBLIAS, HINARIOS SEMELHANTES, IMPRESSOS SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "1811302",
    descricao:
      "LIVROS, BROCHURAS OU IMPRESSOS INFANTIS, IMPRESSOS SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "1811302",
    descricao:
      "OBRAS CARTOGRAFICAS DIVERSAS (MAPAS, PLANTAS TOPOGRAFICAS, ETC.) IMPRESSOS SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "1811302",
    descricao:
      "LIVROS OU OUTROS IMPRESSOS DIDATICOS E PARADIDATICOS PARA O MERCADO (EXCETO PARA PROGRAMAS GOVERNAMENTAIS), IMPRESSOS SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "1811302",
    descricao:
      "CATALOGOS DE MUSEUS, BIBLIOTECAS E SEMELHANTES, IMPRESSOS SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "1811302",
    descricao:
      "REVISTAS PERIODICAS GRATUITAS (B TO B E CUSTOMIZADAS); IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1811302",
    descricao:
      "LISTAS TELEFONICAS E DE ENDERECOS IMPRESSOS SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "1811302",
    descricao:
      "PUBLICACOES DE ORGAOS OFICIAIS, TEXTOS DE HINOS, TESES CIENTIFICAS, MONOGRAFIAS OU SEMELHANTES, IMPRESSOS SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "1811302",
    descricao: "PUBLICACOES PERIODICAS;  IMPRESSAO SOB ENCOMENDA",
  },
  { cnae: "18121", descricao: "IMPRESSAO DE MATERIAL DE SEGURANCA" },
  {
    cnae: "1812100",
    descricao:
      "ACOES, CHEQUES, CAUTELAS, TITULOS AO PORTADOR E OUTROS DOCUMENTOS FISCAIS, IMPRESSOS SOB ENCOMENDA",
  },
  { cnae: "1812100", descricao: "TALOES DE CHEQUE; IMPRESSAO SOB ENCOMENDA" },
  {
    cnae: "1812100",
    descricao:
      "GRAFICA: TALOES DE CHEQUE, RECIBOS FISCAIS, CAUTELA E OUTRO MATERIAIS DE SEGURANCA; IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1812100",
    descricao:
      "CARTOES MAGNETICOS GRAVADOS, COM SUPORTE DE QUALQUER MATERIAL; IMPRESSAO SOB ENCOMENDA",
  },
  { cnae: "1812100", descricao: "CHEQUES DE VIAGEM; IMPRESSAO SOB ENCOMENDA" },
  { cnae: "1812100", descricao: "PAPEL-MOEDA, IMPRESSAO SOB ENCOMENDA" },
  {
    cnae: "1812100",
    descricao: "CARTOES TELEFONICOS; IMPRESSAO SOB ENCOMENDA",
  },
  { cnae: "1812100", descricao: "DOCUMENTOS FISCAIS; IMPRESSAO SOB ENCOMENDA" },
  {
    cnae: "1812100",
    descricao: "BILHETES ELETROMAGNETICOS; IMPRESSAO SOB ENCOMENDA",
  },
  { cnae: "1812100", descricao: "SELOS; IMPRESSAO SOB ENCOMENDA" },
  { cnae: "1812100", descricao: "RECIBOS FISCAIS; IMPRESSAO SOB ENCOMENDA" },
  { cnae: "18130", descricao: "IMPRESSAO DE MATERIAIS PARA OUTROS USOS" },
  { cnae: "1813001", descricao: "BRINDES; SERIGRAFIA EM" },
  {
    cnae: "1813001",
    descricao: "FAIXAS E CARTAZES DE PROPAGANDA;  IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1813001",
    descricao:
      "CATALOGOS, CARTAZES, FOLHETOS, ENCARTES E OUTROS IMPRESSOS PARA FINS PROMOCIONAIS; IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1813001",
    descricao: "CARTAZES DE PROPAGANDA; IMPRESSAO SOB ENCOMENDA",
  },
  { cnae: "1813001", descricao: "SERIGRAFIA EM BONES; SERVICO DE" },
  {
    cnae: "1813001",
    descricao: "OUTDOORS, MALAS-DIRETAS, BANNERS; IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1813001",
    descricao: "PROSPECTOS E VOLANTES;  IMPRESSAO SOB ENCOMENDA",
  },
  { cnae: "1813001", descricao: "CALENDARIOS; IMPRESSAO SOB ENCOMENDA" },
  {
    cnae: "1813001",
    descricao: "CATALOGOS DE ARTE, KITS PROMOCIONAIS; IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1813001",
    descricao: "TAMPOGRAFIA PARA USO PUBLICITARIO; SERVICOS DE",
  },
  {
    cnae: "1813001",
    descricao:
      "GRAFICA: CARTAZES, PROSPECTOS, CALENDARIOS, ENCARTES E OUTROS IMPRESSOS DE PUBLICIDADE; IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1813001",
    descricao: "SERIGRAFIA EM MATERIAL PUBLICITARIO; SERVICOS DE",
  },
  {
    cnae: "1813099",
    descricao:
      "IMPRESSOS PARA USO INDUSTRIAL (CAPAS DE CD, DVD OU SEMELHANTES, BULAS, MANUAIS DE INSTRUCAO, ETC.).; FABRICACAO DE",
  },
  { cnae: "1813099", descricao: "COURO; IMPRESSAO SOB ENCOMENDA" },
  {
    cnae: "1813099",
    descricao:
      "IMPRESSOS PARA FINS PUBLICITARIOS OU PROMOCIONAIS EM FILMES, LONA VINILICA, POLIPROPILENO, VINIL ADESIVO, ETC. (BANNERS, BACKLIT, FRONTLIT, ETC.).; FABRICACAO DE",
  },
  {
    cnae: "1813099",
    descricao: "ROTULOS DE QUALQUER MATERIAL IMPRESSOS SOB ENCOMENDA",
  },
  {
    cnae: "1813099",
    descricao:
      "IMPRESSOS PARA FINS PUBLICITARIOS OU PROMOCIONAIS EM PAPEL OU SUPORTE CELULOSICO (CATALOGOS, CARTAZES, FOLHETOS, ENCARTES, OUTDOORS, MALA DIRETA, ETC.).; FABRICACAO DE",
  },
  { cnae: "1813099", descricao: "DECALCOMANIA; IMPRESSAO SOB ENCOMENDA" },
  {
    cnae: "1813099",
    descricao:
      "IMPRESSOS PADRONIZADOS PARA USO COMERCIAL (FORMULARIOS EM BLOCO, BLOCOS DE ENCOMENDAS, DE RECIBOS, DE APONTAMENTOS, ETC, NAO FISCAIS); FABRICACAO DE",
  },
  {
    cnae: "1813099",
    descricao: "MATERIAIS EM SERIGRAFIA (SILK - SCREEN); FABRICACAO DE",
  },
  {
    cnae: "1813099",
    descricao: "TAMPOGRAFIA PARA OUTROS USOS, EXCETO PUBLICITARIO; SERVICOS DE",
  },
  { cnae: "1813099", descricao: "DIARIO DE CLASSE; IMPRESSAO SOB ENCOMENDA" },
  {
    cnae: "1813099",
    descricao:
      "MATERIAIS DIVERSOS (PLASTICO, TECIDO, COURO, ETC);  IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1813099",
    descricao:
      "CONTAS TELEFONICAS, EXTRATOS BANCARIOS E OUTROS IMPRESSOS DE DADOS VARIAVEIS TRANSACIONAIS, INTEGRADOS OU NAO A PROMOCAO (TRANSPROMO); FABRICACAO DE",
  },
  {
    cnae: "1922501",
    descricao: "COMBUSTIVEIS LIQUIDOS DERIVADOS DO PETROLEO; FORMULACAO DE",
  },
  {
    cnae: "1813099",
    descricao:
      "IMPRESSOS EM MATERIAIS DIVERSOS (PLASTICO, TECIDO, COURO, ETC.), EXCETO PAPEL; FABRICACAO DE",
  },
  {
    cnae: "1813099",
    descricao: "SERIGRAFIA (SILK-SCREEN); IMPRESSAO SOB ENCOMENDA",
  },
  { cnae: "1813099", descricao: "RECIBOS; IMPRESSAO SOB ENCOMENDA" },
  {
    cnae: "1813099",
    descricao:
      "IMPRESSOS PARA ADESIVACAO DE VEICULOS (CARROS, ONIBUS, TRENS, ETC.) OU OUTROS IMPRESSOS PARA SINALIZACAO; FABRICACAO DE",
  },
  {
    cnae: "1813099",
    descricao:
      "CARDAPIOS, DIPLOMAS, CONVITES E SEMELHANTES; IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1813099",
    descricao: "GRAFICA: MATERIAIS PARA ESCRITORIO;IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1813099",
    descricao:
      "ETIQUETAS E ROTULOS IMPRESSOS EM SUPORTE DE PLASTICO OU DE OUTRO MATERIAL, EXCETO PAPEL; FABRICACAO DE",
  },
  { cnae: "1813099", descricao: "ROTULOS; SERVICO DE IMPRESSAO DE" },
  {
    cnae: "1813099",
    descricao:
      "DADOS VARIAVEIS IMPRESSOS SOB ENCOMENDA, EXCETO TRANSACIONAIS; FABRICACAO DE",
  },
  {
    cnae: "1813099",
    descricao: "GRAFICA: MATERIAL ESCOLAR; IMPRESSAO SOB ENCOMENDA",
  },
  { cnae: "1813099", descricao: "MATERIAL ESCOLAR; IMPRESSAO SOB ENCOMENDA" },
  {
    cnae: "1813099",
    descricao:
      "GRAFICA, CARDAPIOS, DIPLOMAS, CONVITES E SEMELHANTES; IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1813099",
    descricao:
      "SERIGRAFIA EM PECAS DO VESTUARIO (CAMISETAS, ETC); IMPRESSAO SOB ENCOMENDA",
  },
  {
    cnae: "1813099",
    descricao: "IMPRESSOSPARA USO ESCOLAR (EXCETO CADERNOS); FABRICACAO DE",
  },
  { cnae: "1813099", descricao: "SUBLIMACAO; SERVICOS DE" },
  { cnae: "1813099", descricao: "PLASTICO; IMPRESSAO SOB ENCOMENDA" },
  {
    cnae: "1813099",
    descricao:
      "CARTOES DE FELICITACOES; CARTOES DE VISITA; IMPRESSAO SOB ENCOMENDA",
  },
  { cnae: "18211", descricao: "SERVICOS DE PREIMPRESSAO" },
  { cnae: "1821100", descricao: "PRE-IMPRESSAO; SERVICOS DE" },
  {
    cnae: "1821100",
    descricao:
      "FOTOLITOS, CLICHES, CHAPAS PARA OFSETE (OFF-SET), FOTOGRAVURAS, COMPOSICAO E OUTRAS MATRIZES PARA IMPRESSAO; SERVICOS DE",
  },
  {
    cnae: "1821100",
    descricao:
      "TRATAMENTO DE TEXTOS E IMAGENS EM GERAL (PRE-IMPRESSAO), SERVICO DE",
  },
  { cnae: "1821100", descricao: "PROVAS DE IMPRESSAO; SERVICOS DE" },
  { cnae: "18229", descricao: "SERVICOS DE ACABAMENTOS GRAFICOS" },
  {
    cnae: "1822901",
    descricao:
      "ENCADERNACAO E PLASTIFICACAO (ACABAMENTOS GRAFICOS); SERVICOS DE",
  },
  {
    cnae: "1822901",
    descricao: "ENCADERNACAO (ACABAMENTO GRAFICO); SERVICOS DE",
  },
  {
    cnae: "1822901",
    descricao: "PLASTIFICACAO (ACABAMENTO GRAFICO); SERVICOS DE",
  },
  {
    cnae: "1822999",
    descricao: "INTERCALACAO (ACABAMENTO GRAFICO); SERVICOS DE",
  },
  { cnae: "1822999", descricao: "COLAGEM (ACABAMENTO GRAFICO); SERVICOS DE" },
  {
    cnae: "1822999",
    descricao: "PICOTE (PICOTAR) (ACABAMENTO GRAFICO); SERVICOS DE",
  },
  {
    cnae: "1822999",
    descricao:
      "SERVICOS GRAFICOS PARA TERCEIROS NAO ESPECIFICADOS, EXCETO ENCADERNACAO E PLASTIFICACAO",
  },
  {
    cnae: "1822999",
    descricao: "CORTE E VINCO (ACABAMENTOS GRAFICOS); SERVICOS DE",
  },
  { cnae: "1822999", descricao: "LAMINACAO (ACABAMENTO GRAFICO); SERVICOS DE" },
  { cnae: "1822999", descricao: "FURACAO (ACABAMENTO GRAFICO); SERVICOS DE" },
  {
    cnae: "1822999",
    descricao:
      "GOFRAGEM, ENVERNIZAMENTO, LAMINACAO, HOT STAMPING (ACABAMENTOS GRAFICOS); SERVICOS DE",
  },
  {
    cnae: "1822999",
    descricao: "DOBRA MANUAL E MECANICA (ACABAMENTO GRAFICO); SERVICOS DE",
  },
  {
    cnae: "18300",
    descricao: "REPRODUCAO DE MATERIAIS GRAVADOS EM QUALQUER SUPORTE",
  },
  {
    cnae: "1830001",
    descricao:
      "REPRODUCAO DE SOM EM QUALQUER SUPORTE, A PARTIR DE GRAVACOES ORIGINAIS (MATRIZES); SERVICO DE",
  },
  {
    cnae: "1830002",
    descricao:
      "REPRODUCAO DE VIDEO EM QUALQUER SUPORTE, A PARTIR DE GRAVACOES ORIGINAIS (MATRIZES); SERVICOS SOB ENCOMENDA DE",
  },
  {
    cnae: "1830002",
    descricao:
      "REPRODUCAO DE FITAS E DISCOS DE VIDEO (A PARTIR DE MATRIZES); SERVICO SOB ENCOMENDA DE",
  },
  {
    cnae: "1830003",
    descricao:
      "SOFTWARES EM QUALQUER SUPORTE PARA DIFUSAO COMERCIAL, A PARTIR DE GRAVACOES ORIGINAIS; REPRODUCAO DE",
  },
  {
    cnae: "1830003",
    descricao:
      "REPRODUCAO DE PROGRAMAS DE INFORMATICA PARA DIFUSAO COMERCIAL, A PARTIR DE GRAVACOES ORIGINAIS; SERVICOS SOB ENCOMENDA DE",
  },
  { cnae: "1830003", descricao: "SOFTWARE; REPRODUCAO DE" },
  { cnae: "19101", descricao: "COQUERIAS" },
  {
    cnae: "1910100",
    descricao:
      "COQUE DE CARVAO MINERAL (HULHA, LINHITO (LINHITA) OU TURFA), OBTIDOS EM COQUERIAS INDEPENDENTES; FABRICACAO DE",
  },
  {
    cnae: "1910100",
    descricao:
      "GAS DE COQUERIA, OBTIDO EM COQUERIAS INDEPENDENTES; FABRICACAO DE",
  },
  {
    cnae: "1910100",
    descricao:
      "PRODUTOS DA DESTILACAO DO CARVAO MINERAL, OBTIDOS EM COQUERIAS INDEPENDENTES; FABRICACAO DE",
  },
  {
    cnae: "1910100",
    descricao:
      "ALCATROES DE HULHA, LINHITO (LINHITA), TURFA OU OUTROS ALCATROES MINERAIS OBTIDOS EM COQUERIAS INDEPENDENTES; FABRICACAO DE",
  },
  {
    cnae: "1910100",
    descricao: "GAS DE HULHA, OBTIDO EM COQUERIAS INDEPENDENTES; FABRICACAO DE",
  },
  {
    cnae: "1910100",
    descricao:
      "GAS DE AGUA, GAS POBRE (GAS DE AR) E OUTROS GASES SEMELHANTES, FABRICACAO DE",
  },
  { cnae: "19217", descricao: "FABRICACAO DE PRODUTOS DO REFINO DE PETROLEO" },
  {
    cnae: "1921700",
    descricao:
      "GASOLINA AUTOMOTIVA OU PARA OUTROS USOS, EXCETO PARA AVIACAO; FABRICACAO DE",
  },
  {
    cnae: "1921700",
    descricao: "GAS LIQUEFEITO DE PETROLEO (GLP); FABRICACAO DE",
  },
  { cnae: "1921700", descricao: "QUEROSENE PARA AVIACAO; FABRICACAO DE" },
  {
    cnae: "1921700",
    descricao:
      "ASFALTO DE PETROLEO PREPARADO (EM REFINARIAS), UTILIZADO NA PAVIMENTACAO DE ESTRADAS; FABRICACAO DE",
  },
  {
    cnae: "1921700",
    descricao: "PRODUTOS DO BENEFICIAMENTO DO XISTO; FABRICACAO DE",
  },
  {
    cnae: "1921700",
    descricao:
      "ASFALTO DE PETROLEO, CIMENTO ASFALTICO OU OUTROS RESIDUOS DE OLEOS DE PETROLEO OBTIDOS EM REFINARIA; FABRICACAO DE",
  },
  { cnae: "1921700", descricao: "PROPANO EM BRUTO LIQUEFEITO; FABRICACAO DE" },
  { cnae: "1921700", descricao: "GASOLINA DE AVIACAO; FABRICACAO DE" },
  {
    cnae: "1921700",
    descricao: "GASOLINA ESPECIAL PARA AUTOVEICULOS; FABRICACAO DE",
  },
  {
    cnae: "1921700",
    descricao: "OLEOS DE PETROLEO OU DE MINERAIS BETUMINOSOS; FABRICACAO DE",
  },
  { cnae: "1921700", descricao: "OLEO COMBUSTIVEL; FABRICACAO DE" },
  { cnae: "1921700", descricao: "FUEL-OIL (OLEO COMBUSTIVEL); FABRICACAO DE" },
  {
    cnae: "1921700",
    descricao: "COQUE DE PETROLEO NAO CALCINADO; FABRICACAO DE",
  },
  {
    cnae: "1921700",
    descricao:
      "ETILENO, PROPILENO, BUTILENO, BUTADIENO E OUTROS GASES DE PETROLEO OU HIDROCARBONETOS GASOSOS, NAO ESPECIFICADOS - EXCETO GAS NATURAL; FABRICACAO DE",
  },
  { cnae: "1921700", descricao: "COMBUSTIVEIS PARA AVIACAO; FABRICACAO DE" },
  { cnae: "1921700", descricao: "NAFTA; FABRICACAO DE" },
  { cnae: "1921700", descricao: "GASOLEO (OLEO DIESEL); FABRICACAO DE" },
  {
    cnae: "1921700",
    descricao:
      "PRODUTOS DE MINERAIS BETUMINOSOS (XISTO, AREIAS BETUMINOSAS); OBTENCAO DE",
  },
  { cnae: "1921700", descricao: "BUTANO EM BRUTO LIQUEFEITO; FABRICACAO DE" },
  { cnae: "1921700", descricao: "OLEOS LUBRIFICANTES BASICOS; FABRICACAO DE" },
  { cnae: "1921700", descricao: "GASOLEO PARAFINICO; FABRICACAO DE" },
  { cnae: "1921700", descricao: "QUEROSENE COMUM; FABRICACAO DE" },
  { cnae: "1921700", descricao: "GAS DE REFINARIA; FABRICACAO DE" },
  {
    cnae: "1921700",
    descricao: "GASOLINA COMUM PARA AUTOVEICULOS; FABRICACAO DE",
  },
  { cnae: "1921700", descricao: "ALCATRAO DE PETROLEO; FABRICACAO DE" },
  { cnae: "1921700", descricao: "SOLVENTES DE PETROLEO; FABRICACAO DE" },
  { cnae: "1921700", descricao: "GASOLEOS PETROLIFEROS, N.E.; FABRICACAO DE" },
  { cnae: "1921700", descricao: "OLEO DIESEL; FABRICACAO DE" },
  {
    cnae: "19225",
    descricao:
      "FABRICACAO DE PRODUTOS DERIVADOS DO PETROLEO, EXCETO PRODUTOS DO REFINO",
  },
  {
    cnae: "1922501",
    descricao:
      "OLEO DIESEL A PARTIR DE MISTURA DE CORRENTES DE HIDROCARBONETOS; FORMULACAO DE",
  },
  { cnae: "1922501", descricao: "OLEO DIESEL FORMULADO; FABRICACAO DE" },
  {
    cnae: "1922501",
    descricao:
      "GASOLINA A, COMUM E PREMIUM, A PARTIR DA MISTURA DE CORRENTES DE HIDROCARBONETOS; FORMULACAO DE",
  },
  {
    cnae: "1922501",
    descricao:
      "OLEOS PARA TRANSMISSOES HIDRAULICAS (DERIVADO DO PETROLEO OU DE MINERAIS BETUMINOSOS); FABRICACAO DE",
  },
  {
    cnae: "1922501",
    descricao:
      "PRODUTOS DERIVADOS DO PETROLEO FORMULADOS, MISTURADOS OU PRODUZIDOS DE OUTRAS FORMAS - EXCETO PRODUTOS OBTIDOS NAS REFINARIAS",
  },
  {
    cnae: "1922501",
    descricao:
      "GASOLINA AUTOMOTIVA FORMULADA OU PREPARADA DE OUTRA FORMA; FABRICACAO DE",
  },
  {
    cnae: "1922502",
    descricao: "OLEOS LUBRIFICANTES COM ADITIVOS; FABRICACAO DE",
  },
  {
    cnae: "1922502",
    descricao: "OLEOS LUBRIFICANTES RERREFINADOS, SEM ADITIVOS; FABRICACAO DE",
  },
  {
    cnae: "1922502",
    descricao: "OLEOS LUBRIFICANTES RECUPERADOS (RERREFINADOS); PRODUCAO DE",
  },
  { cnae: "1922502", descricao: "OLEO LUBRIFICANTE QUEIMADO; RECUPERACAO DE" },
  {
    cnae: "1922502",
    descricao:
      "OLEOS LUBRIFICANTES USADOS; RECUPERACAO, RECICLAGEM, RERREFINO DE",
  },
  {
    cnae: "1922502",
    descricao: "OLEOS LUBRIFICANTES RERREFINADOS, COM ADITIVOS; FABRICACAO DE",
  },
  { cnae: "1922599", descricao: "PARAFINA; FABRICACAO DE" },
  {
    cnae: "1922599",
    descricao: "FLUIDO PARA TRANSMISSAO AUTOMATICA; FABRICACAO DE",
  },
  { cnae: "1922599", descricao: "COQUE DE PETROLEO CALCINADO; FABRICACAO DE" },
  {
    cnae: "1922599",
    descricao: "LINHITAS (HULHAS CASTANHAS) AGLOMERADAS; FABRICACAO DE",
  },
  { cnae: "1922599", descricao: "AGUARRAS, EXCETO FRACIONADA; FABRICACAO DE" },
  {
    cnae: "1922599",
    descricao: "OLEOS DESMOLDANTES OU ANTIADERENTES; FABRICACAO DE",
  },
  { cnae: "1922599", descricao: "GRAXAS LUBRIFICANTES; FABRICACAO DE" },
  { cnae: "1922599", descricao: "PARAFINA CLORADA; FABRICACAO DE" },
  {
    cnae: "1922599",
    descricao: "CONCENTRADOS AROMATICOS NAFTALENICOS; FABRICACAO DE",
  },
  { cnae: "1922599", descricao: "OLEOS DE CORTE; FABRICACAO DE" },
  {
    cnae: "1922599",
    descricao:
      "OLEOS MINERAIS BRANCOS (OLEOS DE VASELINA OU PARAFINA); FABRICACAO DE",
  },
  {
    cnae: "1922599",
    descricao:
      "CERAS DE LINHITO (LINHITA), CERAS DE TURFA, CERAS DE PETROLEO OU OUTRAS CERAS MINERAIS OU PRODUTOS SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "1922599", descricao: "BUTANO EM BRUTO LIQUEFEITO; FABRICACAO DE" },
  {
    cnae: "1922599",
    descricao: "OLEOS PARA ISOLAMENTOS ELETRICOS; FABRICACAO DE",
  },
  { cnae: "1922599", descricao: "GASOLINA DE PIROLISE; FABRICACAO DE" },
  {
    cnae: "1922599",
    descricao: "FLUIDO (LIQUIDO) PARA DIRECAO HIDRAULICA; FABRICACAO DE",
  },
  { cnae: "1922599", descricao: "AGUARRAS MINERAL; FABRICACAO DE" },
  { cnae: "1922599", descricao: "HEXANO COMERCIAL; FABRICACAO DE" },
  {
    cnae: "1922599",
    descricao: "CERAS DE PETROLEO MICROCRISTALINAS E OUTRAS; FABRICACAO DE",
  },
  {
    cnae: "1922599",
    descricao: "OLEOS PARA TRANSFORMADORES E DISJUNTORES; FABRICACAO DE",
  },
  { cnae: "1922599", descricao: "VASELINA (PETROLATO); FABRICACAO DE" },
  { cnae: "1922599", descricao: "OLEOS LUBRIFICANTES ACABADOS; FABRICACAO DE" },
  { cnae: "1922599", descricao: "CERAS MINERAIS; FABRICACAO DE" },
  {
    cnae: "1922599",
    descricao: "HULHAS AGLOMERADAS (BOLOTAS, BRIQUETES, ETC.); FABRICACAO DE",
  },
  { cnae: "19314", descricao: "FABRICACAO DE ALCOOL" },
  {
    cnae: "1931400",
    descricao:
      "ALCOOL ETILICO NAO DESNATURADO, COM TEOR ALCOOLICO EM VOLUME MENOR QUE 80%; FABRICACAO DE",
  },
  {
    cnae: "1931400",
    descricao: "ALCOOL ETILICO DE MANDIOCA, ANIDRO; FABRICACAO DE",
  },
  {
    cnae: "1931400",
    descricao:
      "ETANOL, A PARTIR DE PALHA, BAGACO DE CANA OU DE OUTROS RESIDUOS VEGETAIS; PRODUCAO DE",
  },
  { cnae: "1931400", descricao: "ALCOOL PARA USO DOMESTICO" },
  { cnae: "1931400", descricao: "ALCOOL ETILICO DESNATURADO; FABRICACAO DE" },
  {
    cnae: "1931400",
    descricao: "ALCOOL ETILICO DE CANA DE ACUCAR, ANIDRO; FABRICACAO DE",
  },
  {
    cnae: "1931400",
    descricao:
      "ALCOOL ANIDRO OU HIDRATADO PARA FINS CARBURANTES; FABRICACAO DE",
  },
  { cnae: "1931400", descricao: "ALCOOL DE CEREAIS, HIDRATADO; FABRICACAO DE" },
  {
    cnae: "1931400",
    descricao:
      "ALCOOL ETILICO (ETANOL) NAO DESNATURADO, COM TEOR ALCOOLICO EM VOLUME MAIOR OU IGUAL A 80%, ANIDRO OU HIDRATADO PARA FINS CARBURANTES; FABRICACAO DE",
  },
  {
    cnae: "1931400",
    descricao: "ALCOOL ETILICO DE MANDIOCA, HIDRATADO; FABRICACAO DE",
  },
  {
    cnae: "1931400",
    descricao:
      "ALCOOL ETILICO (ETANOL) NAO DESNATURADO, COM TEOR ALCOOLICO EM VOLUME MAIOR OU IGUAL A 80%, PARA FINS NAO CARBURANTES; FABRICACAO DE",
  },
  { cnae: "1931400", descricao: "ALCOOL REDESTILADO; FABRICACAO DE" },
  { cnae: "1931400", descricao: "ALCOOL DE CEREAIS, ANIDRO; FABRICACAO DE" },
  { cnae: "1931400", descricao: "VINHACA (VINHOTO); FABRICACAO DE" },
  { cnae: "1931400", descricao: "ETANOL (BIOETANOL), PRODUCAO DE" },
  { cnae: "1931400", descricao: "OLEO FUSEL; FABRICACAO DE" },
  {
    cnae: "1931400",
    descricao: "ALCOOL ETILICO DE CANA DE ACUCAR, HIDRATADO; FABRICACAO DE",
  },
  { cnae: "19322", descricao: "FABRICACAO DE BIOCOMBUSTIVEIS, EXCETO ALCOOL" },
  { cnae: "1932200", descricao: "BIOCOMBUSTIVEIS; FABRICACAO DE" },
  { cnae: "1932200", descricao: "BIODIESEL DE OLEOS VEGETAIS; FABRICACAO DE" },
  { cnae: "1932200", descricao: "BIODIESEL; FABRICACAO DE" },
  {
    cnae: "1932200",
    descricao: "BIODIESEL DE GORDURAS ANIMAIS; FABRICACAO DE",
  },
  { cnae: "20118", descricao: "FABRICACAO DE CLORO E ALCALIS" },
  { cnae: "2011800", descricao: "POTASSA CAUSTICA; FABRICACAO DE" },
  { cnae: "2011800", descricao: "HIDROXIDO DE SODIO; FABRICACAO DE" },
  {
    cnae: "2011800",
    descricao:
      "CARBONATO NEUTRO DE SODIO (BARRILHA OU SODA CALCINADA); FABRICACAO DE",
  },
  {
    cnae: "2011800",
    descricao: "CLORO (PRODUTO INORGANICO BASICO); FABRICACAO DE",
  },
  { cnae: "2011800", descricao: "CARBONATO DE SODIO; FABRICACAO DE" },
  { cnae: "2011800", descricao: "CLORO E ALCALIS; FABRICACAO DE" },
  {
    cnae: "2011800",
    descricao: "OXIDOS DOS METAIS ALCALINOS, EXCETO OS TERROSOS; FABRICACAO DE",
  },
  { cnae: "2011800", descricao: "HIPOCLORITO DE SODIO; FABRICACAO DE" },
  { cnae: "2011800", descricao: "SODA CAUSTICA; FABRICACAO DE" },
  { cnae: "2011800", descricao: "GAS CLORO; FABRICACAO DE" },
  { cnae: "2011800", descricao: "ACIDO CLORIDRICO; FABRICACAO DE" },
  { cnae: "2011800", descricao: "HIDROXIDO DE POTASSIO; FABRICACAO DE" },
  { cnae: "2011800", descricao: "ALCALIS; FABRICACAO DE" },
  {
    cnae: "20126",
    descricao: "FABRICACAO DE INTERMEDIARIOS PARA FERTILIZANTES",
  },
  { cnae: "2012600", descricao: "CIANAMIDA CALCICA; FABRICACAO DE" },
  {
    cnae: "2012600",
    descricao:
      "ACIDO FOSFORICO UTILIZADO NA PREPARACAO DE ADUBOS E FERTILIZANTES; FABRICACAO DE",
  },
  { cnae: "2012600", descricao: "CLORETO DE POTASSIO; FABRICACAO DE" },
  {
    cnae: "2012600",
    descricao: "OLEUM (ACIDO SULFURICO FUMANTE); FABRICACAO DE",
  },
  { cnae: "2012600", descricao: "AMONIACO LIQUEFEITO; FABRICACAO DE" },
  {
    cnae: "2012600",
    descricao:
      "MISTURAS DE NITRATO DE AMONIO COM CARBONATO DE CALCIO OU COM OUTRAS MATERIAS INORGANICAS DESPROVIDAS DE PODER FERTILIZANTE; FABRICACAO DE",
  },
  { cnae: "2012600", descricao: "AMONIA (AMONIACO LIQUEFEITO); FABRICACAO DE" },
  { cnae: "2012600", descricao: "HEXAMETAFOSFATO; FABRICACAO DE" },
  { cnae: "2012600", descricao: "ACIDO NITRICO; FABRICACAO DE" },
  { cnae: "2012600", descricao: "FOSFATOS DE TRIAMONIO; FABRICACAO DE" },
  {
    cnae: "2012600",
    descricao: "INTERMEDIARIOS PARA FERTILIZANTES QUIMICOS; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "SODIO (METAL ALCALINO); FABRICACAO DE" },
  {
    cnae: "2012600",
    descricao:
      "SAIS DUPLOS E MISTURAS, DE SULFATO DE AMONIO E NITRATO DE AMONIO; FABRICACAO DE",
  },
  { cnae: "2012600", descricao: "SUPERFOSFATOS CONCENTRADOS; FABRICACAO DE" },
  { cnae: "2012600", descricao: "NITROCALCIO; FABRICACAO DE" },
  { cnae: "2012600", descricao: "SUPERFOSFATO TRIPLO; FABRICACAO DE" },
  {
    cnae: "2012600",
    descricao: "ACIDOS NITRICO E SULFONITRICOS; FABRICACAO DE",
  },
  { cnae: "2012600", descricao: "TRIFOSFATOS; FABRICACAO DE" },
  {
    cnae: "2012600",
    descricao: "INTERMEDIARIOS PARA ADUBOS QUIMICOS; FABRICACAO DE",
  },
  { cnae: "2012600", descricao: "FOSFATO DIAMONIO; FABRICACAO DE" },
  { cnae: "2012600", descricao: "FOSFATO MONOAMONIO; FABRICACAO DE" },
  { cnae: "2012600", descricao: "SULFATO DE AMONIO; FABRICACAO DE" },
  { cnae: "2012600", descricao: "UREIA; FABRICACAO DE" },
  { cnae: "2012600", descricao: "ESCORIAS DE DESFOSFORACAO; FABRICACAO DE" },
  { cnae: "2012600", descricao: "SULFATO DE POTASSIO; FABRICACAO DE" },
  { cnae: "2012600", descricao: "SUPERFOSFATO SIMPLES; FABRICACAO DE" },
  { cnae: "2012600", descricao: "FOSFATOS DE AMONIO; FABRICACAO DE" },
  { cnae: "2012600", descricao: "ACIDO SULFURICO; FABRICACAO DE" },
  { cnae: "2012600", descricao: "SALITRE; FABRICACAO DE" },
  { cnae: "2012600", descricao: "NITRATO DE AMONIO; FABRICACAO DE" },
  { cnae: "2012600", descricao: "NITRATO DE SODIO; FABRICACAO DE" },
  { cnae: "2012600", descricao: "SUPERFOSFATOS DUPLO E TRIPLO; FABRICACAO DE" },
  {
    cnae: "2012600",
    descricao:
      "SAIS DUPLOS E MISTURAS, DE NITRATO DE CALCIO E NITRATO DE AMONIO; FABRICACAO DE",
  },
  {
    cnae: "2012600",
    descricao:
      "MISTURAS DE UREIA COM NITRATO DE AMONIO EM SOLUCOES AQUOSAS OU AMONIACAIS; FABRICACAO DE",
  },
  { cnae: "20134", descricao: "FABRICACAO DE ADUBOS E FERTILIZANTES" },
  {
    cnae: "2013401",
    descricao:
      "FERTILIZANTES DE ORIGEM ANIMAL OU VEGETAL, MESMO MISTURADOS ENTRE SI TRATADOS QUIMICAMENTE; FABRICACAO DE",
  },
  {
    cnae: "2013401",
    descricao: "ADUBOS E FERTILIZANTES ORGANOMINERAIS; FABRICACAO DE",
  },
  {
    cnae: "2013402",
    descricao:
      "FERTILIZANTES FOSFATADOS, NITROGENADOS E POTASSICOS; FABRICACAO DE",
  },
  {
    cnae: "2013402",
    descricao:
      "FERTILIZANTES QUIMICOS PUROS, COMPOSTOS E COMPLEXOS; FABRICACAO DE",
  },
  { cnae: "2013402", descricao: "FOSFATO DEFLUORINADO; FABRICACAO DE" },
  {
    cnae: "2013402",
    descricao: "ADUBOS E FERTILIZANTES NITROGENADOS; FABRICACAO DE",
  },
  {
    cnae: "2013402",
    descricao: "ADUBOS E FERTILIZANTES POTASSICOS; FABRICACAO DE",
  },
  {
    cnae: "2013402",
    descricao: "ADUBOS QUIMICOS PARA USO AGRICOLA E DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2013402",
    descricao: "ADUBOS QUIMICO PUROS, COMPOSTOS E COMPLEXOS; FABRICACAO DE",
  },
  {
    cnae: "2013402",
    descricao:
      "FERTILIZANTES QUIMICOS DE USO AGRICOLA E DOMESTICO; FABRICACAO DE",
  },
  { cnae: "2013402", descricao: "FERTILIZANTES COMPOSTOS NPK; FABRICACAO DE" },
  {
    cnae: "2013402",
    descricao:
      "SUBSTRATOS (CASCA DE COCO, PALHA DE ARROZ, ETC.) ENRIQUECIDOS COM COMPOSTOS MINERAIS, FABRICACAO DE",
  },
  { cnae: "2013402", descricao: "SALITRE DO CHILE; FABRICACAO DE" },
  {
    cnae: "2013402",
    descricao: "ADUBOS E FERTILIZANTES FOSFATADOS; FABRICACAO DE",
  },
  {
    cnae: "2013402",
    descricao:
      "SUBSTRATOS FEITOS COM MISTURA DE TERRA, AREIA, ARGILA E MINERAIS, FABRICACAO DE",
  },
  { cnae: "2013402", descricao: "TERMOFOSFATOS; FABRICACAO DE" },
  { cnae: "20142", descricao: "FABRICACAO DE GASES INDUSTRIAIS" },
  { cnae: "2014200", descricao: "GAS CARBONICO; FABRICACAO DE" },
  {
    cnae: "2014200",
    descricao: "GELO SECO (ANIDRIDO CARBONICO); FABRICACAO DE",
  },
  { cnae: "2014200", descricao: "GAS NOBRE; FABRICACAO DE" },
  { cnae: "2014200", descricao: "HIDROGENIO; FABRICACAO DE" },
  { cnae: "2014200", descricao: "ARGONIO; FABRICACAO DE" },
  { cnae: "2014200", descricao: "HELIO; FABRICACAO DE" },
  { cnae: "2014200", descricao: "ANIDRIDO CARBONICO; FABRICACAO DE" },
  {
    cnae: "2014200",
    descricao: "DIOXIDO DE CARBONO (SOLIDO E GASOSO); FABRICACAO DE",
  },
  {
    cnae: "2014200",
    descricao: "MISTURAS DE GASES INDUSTRIAIS; FABRICACAO DE",
  },
  { cnae: "2014200", descricao: "ACETILENO; FABRICACAO DE" },
  { cnae: "2014200", descricao: "OXIGENIO; FABRICACAO DE" },
  { cnae: "2014200", descricao: "XENONIO; FABRICACAO DE" },
  { cnae: "2014200", descricao: "NITROGENIO; FABRICACAO DE" },
  { cnae: "2014200", descricao: "GASES INERTES; FABRICACAO DE" },
  { cnae: "2014200", descricao: "AR LIQUIDO; AR COMPRIMIDO; FABRICACAO DE" },
  { cnae: "2014200", descricao: "GASES RAROS, N.E.; FABRICACAO DE" },
  { cnae: "2014200", descricao: "NEONIO; FABRICACAO DE" },
  {
    cnae: "2014200",
    descricao: "GASES MEDICOS LIQUIDOS OU COMPRIMIDOS; FABRICACAO DE",
  },
  { cnae: "2014200", descricao: "GASES REFRIGERANTES; FABRICACAO DE" },
  { cnae: "2014200", descricao: "OXIDO NITROSO; FABRICACAO DE" },
  {
    cnae: "20193",
    descricao:
      "FABRICACAO DE PRODUTOS QUIMICOS INORGANICOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "2019301",
    descricao: "ELEMENTOS RADIOATIVOS PARA USO INDUSTRIAL; PRODUCAO DE",
  },
  { cnae: "2019301", descricao: "COMBUSTIVEIS NUCLEARES; ELABORACAO DE" },
  { cnae: "2019301", descricao: "URANIO E TORIO; ENRIQUECIMENTO DE" },
  {
    cnae: "2019301",
    descricao:
      "URANIO ENRIQUECIDO OU EMPOBRECIDO E SEUS COMPOSTOS; OUTROS ELEMENTOS, ISOTOPOS E COMPOSTOS RADIOATIVOS; FABRICACAO DE",
  },
  {
    cnae: "2019301",
    descricao:
      "ELEMENTOS COMBUSTIVEIS (CARTUCHOS), NAO IRRADIADOS, PARA REATORES NUCLEARES; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "PIGMENTOS A BASE DE BARITA; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "OXIDO DE FERRO AMARELO (SINTETICO); FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao: "DIOXIDO DE SILICIO TIPO AEROGEL E OUTROS; FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao: "FOSFATO DE POTASSIO MONOBASICO; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "SAL AMARGO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SULFATO DE MAGNESIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "FLUOBORATO DE CHUMBO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "OXIDO FERRICO COM TEOR EM PESO IGUAL OU SUPERIOR A 85% DE FE203; FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao: "CORANTES ACIDOS PRE-METALIZADOS; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "CARBONATO DE BARIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SULFITOS E TISSULFATOS, N.E.; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "FOSFINATOS, FOSFONATOS (FOSFITOS), FOSFATOS E POLIFOSFATOS, N.E.; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "PIGMENTOS DE FERRO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "OXIDOS E HIDROXIDOS DE COBALTO; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "PERCARBONATO DE SODIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SILICATO DE CALCIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "MOLIBDATO DE SODIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "CLORATOS; BROMATOS E PERBROMATOS; IODATOS, N.E.; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "ARSENIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CARBURETO DE SILICIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "SULFATOS, ALUMES, PEROXOSSULFATOS (PERSULFATOS), N.E.; FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao:
      "COMPOSTOS INORGANICOS DE MERCURIO, EXCETO AS AMALGAMAS; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "PIROFOSFATO DE TETRAPOTASSIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "AZUL DA PRUSSIA (FERROCIANETO FERRICO); FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "HIDROXIDO DE AMONIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "ALUMINATO DE SODIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "IODETO DE MERCURIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "PERCLORATO DE AMONIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "HIDROXIDO DE CERIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SULFATO DE CHUMBO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "OXIDOS DE COBALTO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "ACIDO FLUOSSILICICO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CLORETO DE CAL; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SULFURETO DE SODIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "HIPOCLORITO DE CALCIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "PEROXIDO DE HIDROGENIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "METAIS PRECIOSOS NO ESTADO COLOIDAL; FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao:
      "DERIVADOS HALOGENADOS, OXIALOGENADOS OU SULFURADOS DOS ELEMENTOS NAO METALICOS; FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao: "OXIDO DE FERRO NATURAL (PIGMENTO); FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "ACIDO FLUOBORICO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CARBONATO DE NIQUEL; FABRICACAO DE" },
  { cnae: "2019399", descricao: "FLUORETO DE ALUMINIO E SODIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "PIGMENTOS E PREPARACOES A BASE DE DIOXIDO DE TITANIO; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "CARBURETO DE CALCIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "BORATOS; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CLORITO DE SODIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SULFATO DE CROMO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "ACIDO FOSFORICO, EXCETO UTILIZADO NA PREPARACAO DE ADUBOS E FERTILIZANTES; FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao:
      "SULFATO DE BARIO COM TEOR EM PESO IGUAL OU SUPERIOR A 97,5 % DE BAS04",
  },
  { cnae: "2019399", descricao: "FOSFATO BICALCICO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CLORETO DE CERIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "ACIDO SULFONITRICO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "HEXAFLUORALUMITADOS DE SODIO (CROLITA SINTETICA); OUTROS FLUOSSILICATOS; FLUORALUMINATOS E SAIS COMPLEXOS DE FLUOR; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "CARBONATO DE MAGNESIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "OXIDO DE MANGANES; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "TETRABORATO DE SODIO, (BORAX); FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "DIOXIDO DE TITANIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "HIPOSSULFITO DE SODIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "OXIDOS E HIDROXIDOS DE CROMO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SILICATO DE CHUMBO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "ALVAIADE DE CHUMBO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SUBGALATO DE BISMUTO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "PIGMENTOS ACETINADOS; FABRICACAO DE" },
  { cnae: "2019399", descricao: "TERRA CORANTE (PIGMENTO); FABRICACAO DE" },
  { cnae: "2019399", descricao: "OXIDO DE FERRO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "ACIDO CIANIDRICO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "CLORETOS, OXICLORETOS E HIDROXICLORETOS; BROMETOS E OXIBROMETOS; IODETOS E OXIIODETOS, N.E.; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "HIDROXIDO DE ALUMINIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CIANETO DE ZINCO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "SAIS DOS ACIDOS OXOMETALICOS OU PEROXIMETALICOS, N.E. (ALUMINATOS, CROMATOS, PERMANGANATOS, ETC); FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "CARBONATO DE ESTRONCIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "OXIDO MANGANOSO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "OXIDO DE TITANIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "FLUORETO DE LITIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SILICATO DE ZIRCONIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "PRODUTOS TANANTES INORGANICOS A BASE DE SAIS DE TITANIO; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "SULFETO DE BARIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "PIGMENTOS INORGANICOS DE ORIGEM MINERAL OU SINTETICA EM FORMA BASICA OU CONCENTRADA; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "PROTOXIDO DE CHUMBO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "HIDRATO DE ALUMINIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CLORETO DE ZINCO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "FLUORETO DE HIDROGENIO (ACIDO FLUORIDRICO); FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao:
      "ENXOFRE REFINADO E O RECUPERADO, EXCETO O ENXOFRE PRECIPITADO, SUBLIMADO E O COLOIDAL; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "SULFATO FERROSO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CLORETO DE COBALTO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SULFATO DE ALUMINIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "TRIOXIDO DE ANTIMONIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "DIOXIDO DE MANGANES; FABRICACAO DE" },
  { cnae: "2019399", descricao: "OXIDO DE MAGNESIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "GEL DE SILICA (DIOXIDO DE SILICIO); FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "FLUOR; FABRICACAO DE" },
  { cnae: "2019399", descricao: "ACIDO MURIATICO" },
  { cnae: "2019399", descricao: "FOSFATO DE SODIO DIBASICO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SULFATO DE ZINCO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CLORATO DE POTASSIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CARBONATO DE POTASSIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CLORETO FERRICO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "ANIDRIDO SILICICO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "FOSFATO DE POTASSIO DIBASICO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SILICATO DE ALUMINIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CORANTES DE ORIGEM MINERAL; FABRICACAO DE" },
  { cnae: "2019399", descricao: "PIROFOSFATO DE SODIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "OXIDO DE ZINCO (BRANCO DE ZINCO); FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "FLUORETO DE CERIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "NITRATO DE CALCIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "PREPARACOES CORANTES DE OUTROS TIPOS; PRODUTOS INORGANICOS UTILIZADOS COMO LUMINOFOROS; FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao:
      "PEDRAS PRECIOSAS OU SEMIPRECIOSAS SINTETICAS OU RECONSTITUIDAS; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "HIDRAZINA E DERIVADOS; FABRICACAO DE" },
  { cnae: "2019399", descricao: "HIDROCARBONATO DE CHUMBO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "CRIOLITA SINTETICA (FLUORETO DUPLO DE ALUMINIO); FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao: "FOSFATOS HIDROGENO-ORTOFOSFATO DE CALCIO; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "METABISSULFITO DE SODIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "IODETO DE POTASSIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "IODATO DE CALCIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "OXIDO CUPRICO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "NITRITOS E NITRATOS, N.E.; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CLORATO DE SODIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "FLUORETOS (EXCETO FLUORETO DE HIDROGENIO); FLUOSSILICATOS, FLUORALUMINATOS E OUTROS SAIS COMPLEXOS DE FLUOR; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CLOROBENZENO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "ISOTOPOS, N.E., E SEUS COMPOSTOS; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "FOSFATO DE MAGNESIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "METABISSULFITO DE POTASSIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "PO DE ZINCO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "PRODUTOS TANANTES INORGANICOS A BASE DE SAIS DE ZIRCONIO; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "HIDROXIDO DE MAGNESIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CLORETO DE TERRAS RARAS; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "PRODUTOS TANANTES INORGANICOS A BASE DE SAIS DE CROMO; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "TRICLORETO DE FOSFORO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "ACIDOS INORGANICOS E COMPOSTOS OXIGENADOS INORGANICOS; FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao:
      "FOSFETOS DE CONSTITUICAO QUIMICA DEFINIDA OU NAO, EXCETO FERROFOSFOROS; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "SULFATO DE COBRE; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CLORETO DE NIQUEL; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CARBONETOS; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SULFATO DISSODICO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "SULFATO DE SODIO DE OUTROS TIPOS; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "SULFATO DE FERRO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CLORETO DE CALCIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "DISSULFETO DE CARBONO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "TRIOXIDO DE MOLIBDENIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "FLUOBORATO DE COBRE; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "ESTRONCIO (METAL ALCALINO TERROSO); FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "TRIOXIDO DE CROMO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "FOSFATO MONO OU DISSODICO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SULFATO DE CALCIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "ACIDO CLOROSSULFURICO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "FLUORETOS, N.E.; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SULFETO DE SODIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "ENXOFRE PRECIPITADO, SUBLIMADO E O COLOIDAL; FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao:
      "MONOXIDO DE CHUMBO (LITARGIRIO) E OUTROS OXIDOS DE CHUMBO; FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao: "CARBONATO NEUTRO DE POTASSIO (POTASSA); FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "SILICO - ALUMINATO DE SODIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SULFITO DE MERCURIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "CALCIO (METAL ALCALINO TERROSO); FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "PEROXIDO DE ZINCO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "TERRAS CORANTES; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "OXIDOS, HIDROXIDOS, PEROXIDOS, N.E.; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "SILICA GEL; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SILICATO DE POTASSIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "CARBONATOS DE AMONIO - INCLUSIVE O COMERCIAL; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "ACIDO BORICO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "PIRITA DE FERRO USTULADAS (CINZAS DE PIRITAS); PRODUCAO DE",
  },
  { cnae: "2019399", descricao: "OXIDO DE CALCIO" },
  { cnae: "2019399", descricao: "SULFATO DE MANGANES; FABRICACAO DE" },
  { cnae: "2019399", descricao: "ACIDO CLOROSSULFONICO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "BISSULFITO DE SODIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "DIOXIDO DE CLORO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "TRISSILICATO DE MAGNESIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "TIOSSULFATO DE AMONIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CLORETO DE BARIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "CARBONATO DE CALCIO BENEFICIADO, INCLUSIVE PRECIPITADO; PRODUCAO DE",
  },
  { cnae: "2019399", descricao: "SULFAMATO DE NIQUEL; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CORANTES AO ENXOFRE; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "PRODUTOS INORGANICOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "SILICATO DE BORO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "BISSULFETO DE CARBONO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "FLUORACIDOS E OUTROS COMPOSTOS DE FLUOR; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "SULFATO BASICO DE CHUMBO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "PIRITAS DE FERRO USTULADAS (CINZAS DE PIRITAS); FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "SAL DE GLAUBER; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CIANETO DE COBRE; FABRICACAO DE" },
  { cnae: "2019399", descricao: "FLUOSSILICATO DE SODIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "IODO, INCLUSIVE SUBLIMADO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "BICROMATO DE POTASSIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "CORANTES INORGANICOS DE ORIGEM MINERAL OU SINTETICA EM FORMA BASICA OU CONCENTRADA; FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao: "SULFETOS E POLISSULFETOS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao: "ARGILAS E TERRAS ATIVADAS, N.E.; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "METAIS DE TERRAS RARAS; FABRICACAO DE" },
  { cnae: "2019399", descricao: "DIOXIDO DE ENXOFRE; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "ACIDOS POLIFOSFORICOS DE OUTROS TIPOS; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "TIOSSULFATO DE SODIO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "SACARINA; FABRICACAO DE" },
  { cnae: "2019399", descricao: "ARSENICO BRANCO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "BARIO (METAL ALCALINO TERROSO); FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "SILICA GEL (GEL DE SILICA); FABRICACAO DE" },
  { cnae: "2019399", descricao: "PIGMENTOS DE CROMO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "TRIPOLIFOSFATO DE SODIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "METAIS ALCALINOS; FABRICACAO DE" },
  { cnae: "2019399", descricao: "NITRATO DE PRATA; FABRICACAO DE" },
  { cnae: "2019399", descricao: "IODATO DE POTASSIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CARBONATO DE TERRAS RARAS; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CORANTES ACIDOS NORMAIS; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "TANANTES SINTETICOS INORGANICOS; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "OXIDO DE BERILIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "PIGMENTOS A BASE DE CROMATOS; FABRICACAO DE" },
  { cnae: "2019399", descricao: "OXIDO DE NIQUEL; FABRICACAO DE" },
  { cnae: "2019399", descricao: "ACIDO CROMICO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "COMPOSTOS DE METAIS DO GRUPO DAS TERRAS RARAS, DE ITRIO OU DE ESCANDIO OU DAS MISTURAS DESTES METAIS; FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao:
      "HALOGENETOS E OXIALOGENETOS DOS ELEMENTOS NAO METALICOS (CLORETOS E OXICLORETOS DE FOSFORO, DE ENXOFRE, ETC.); FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "LIGA DE CERIO (MICHMETAL); FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "CIANETOS, OXIANETOS E CIANETOS COMPLEXOS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao:
      "OXIDOS, HIDROXIDOS E PEROXIDOS, DE METAIS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "FOSFETOS, N.E.; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "MATERIAIS MINERAIS NATURAIS ATIVADOS, N.E.; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "SULFOCLORETO DE FOSFORO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "NITRATO DE CHUMBO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SULFATO DE COBALTO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "PEROXOBORATOS (PERBORATOS); FABRICACAO DE" },
  { cnae: "2019399", descricao: "OXIDO DE TERRAS RARAS; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SULFITO NEUTRO DE SODIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CIANETO DE SODIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "BICROMATO DE SODIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "FLUOBORATO DE ESTANHO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "TRIFOSFATO DE SODIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CLORETO DE AMONIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "METASSILICATO DE SODIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "PIGMENTOS A BASE DE SAIS DE CADMIO; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "BROMO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "SILICATOS; SILICATOS DOS METAIS ALCALINOS COMERCIAIS, N.E.; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "FOSFATO TRISSODICO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "ISOTOPOS NAO ESPECIFICADOS E SEUS COMPOSTOS, INCLUSIVE AGUA PESADA; FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao: "BENTONITA (MATERIA MINERAL NATURAL ATIVADA); FABRICACAO DE",
  },
  {
    cnae: "2019399",
    descricao:
      "HIDROXIDO E PEROXIDO DE MAGNESIO; OXIDOS, HIDROXIDOS E PEROXIDOS DE ESTRONCIO OU DE BARIO; PEROXIDOS DE SODIO OU DE POTASSIO; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "FOSFATO DE SODIO MONOBASICO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "MONOXIDO DE MANGANES; FABRICACAO DE" },
  { cnae: "2019399", descricao: "NITRATO DE BARIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SULFATO DE NIQUEL; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CLORETO DE MAGNESIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "SILICATOS DUPLOS OU COMPLEXOS; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "PERCLORETO DE FERRO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "BICARBONATO DE AMONIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "PERCLORATOS; FABRICACAO DE" },
  { cnae: "2019399", descricao: "FLUORETO DE ALUMINIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SILICIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "HIDROGENOCARBONATO (BICARBONATO) DE SODIO; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "HIPOSSULFITO DE AMONIO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao:
      "CARBONATOS, PEROXOCARBONATOS (PERCARBONATOS), N.E.; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "HIDROXIDO DE LITIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "ACIDO ARSENIOSO; FABRICACAO DE" },
  {
    cnae: "2019399",
    descricao: "PIGMENTOS A BASE DE METAIS PRECIOSOS; FABRICACAO DE",
  },
  { cnae: "2019399", descricao: "ACIDO PERCLORICO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "HIDROSSULFITO DE SODIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "SILICATO DE SODIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "MOLIBDATO DE AMONIO; FABRICACAO DE" },
  { cnae: "2019399", descricao: "CLORETO DE ALUMINIO; FABRICACAO DE" },
  { cnae: "20215", descricao: "FABRICACAO DE PRODUTOS PETROQUIMICOS BASICOS" },
  {
    cnae: "2021500",
    descricao: "HIDROCARBONETOS ACICLICOS, SATURADOS; FABRICACAO DE",
  },
  { cnae: "2021500", descricao: "TOLUENO; FABRICACAO DE" },
  { cnae: "2021500", descricao: "BENZENO; FABRICACAO DE" },
  { cnae: "2021500", descricao: "PROPENO GRAU POLIMERO; FABRICACAO DE" },
  { cnae: "2021500", descricao: "PRODUTOS AROMATICOS (BTX); FABRICACAO DE" },
  { cnae: "2021500", descricao: "METANOL (ALCOOL METILICO); FABRICACAO DE" },
  {
    cnae: "2021500",
    descricao: "PRODUTOS PETROQUIMICOS BASICOS; FABRICACAO DE",
  },
  { cnae: "2021500", descricao: "PROPENO GRAU QUIMICO; FABRICACAO DE" },
  { cnae: "2021500", descricao: "ISOPROPENOS NAO SATURADOS; FABRICACAO DE" },
  {
    cnae: "2021500",
    descricao: "MISTURA DE ISOMEROS DO SILENO; FABRICACAO DE",
  },
  { cnae: "2021500", descricao: "XILENO MISTOS; FABRICACAO DE" },
  { cnae: "2021500", descricao: "BUTILENO (BUTENO); FABRICACAO DE" },
  {
    cnae: "2021500",
    descricao: "HIDROCARBONETOS ACICLICOS, NAO SATURADOS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2021500",
    descricao:
      "DERIVADOS CLORADOS SATURADOS DOS HIDROCARBONETOS ACICLICOS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2021500",
    descricao: "MISTURA DE ISOMEROS DO XILENO; FABRICACAO DE",
  },
  { cnae: "2021500", descricao: "XILENO-P; FABRICACAO DE" },
  { cnae: "2021500", descricao: "NAFTALINA; FABRICACAO DE" },
  { cnae: "2021500", descricao: "XILENO-0; FABRICACAO DE" },
  { cnae: "2021500", descricao: "ETILENO (ETENO); FABRICACAO DE" },
  {
    cnae: "2021500",
    descricao: "BUTA - 1,3-DIENO NAO-SATURADO; FABRICACAO DE",
  },
  { cnae: "2021500", descricao: "PRODUTOS ORGANICOS BASICOS; FABRICACAO DE" },
  { cnae: "2021500", descricao: "ETENO; FABRICACAO DE" },
  {
    cnae: "2021500",
    descricao: "HIDROCARBONETOS CICLICOS, N.E.; FABRICACAO DE",
  },
  { cnae: "2021500", descricao: "NAFTALENO; FABRICACAO DE" },
  {
    cnae: "20223",
    descricao:
      "FABRICACAO DE INTERMEDIARIOS PARA PLASTIFICANTES, RESINAS E FIBRAS",
  },
  { cnae: "2022300", descricao: "HEXAMETILENOTETRAMINA; FABRICACAO DE" },
  {
    cnae: "2022300",
    descricao: "BUTAN - 1 - OL (ALCOOL N-BUTILICO); FABRICACAO DE",
  },
  { cnae: "2022300", descricao: "ACRILONITRILA; FABRICACAO DE" },
  { cnae: "2022300", descricao: "METILENO DIFENIL ISOCIANATO; FABRICACAO DE" },
  { cnae: "2022300", descricao: "ETILBENZENO; FABRICACAO DE" },
  {
    cnae: "2022300",
    descricao: "1-CLORO-2,3-EPOXIPROPANO (EPICLORIDRINA); FABRICACAO DE",
  },
  { cnae: "2022300", descricao: "MELAMINA; FABRICACAO DE" },
  { cnae: "2022300", descricao: "CRESOIS E SEUS PAIS; FABRICACAO DE" },
  {
    cnae: "2022300",
    descricao: "ESTERES DE METILA DE ACIDO METACRILICO; FABRICACAO DE",
  },
  {
    cnae: "2022300",
    descricao:
      "CICLOEXANOL, METILCICLOEXANOIS E DIMETICICLOEXANOIS; FABRICACAO DE",
  },
  {
    cnae: "2022300",
    descricao: "CIANIDRA DE ACETINO (ACETONA DE CIANIDRA); FABRICACAO DE",
  },
  {
    cnae: "2022300",
    descricao: "ALCOOL ISOBUTILICO (BUTANOL-ISO); FABRICACAO DE",
  },
  { cnae: "2022300", descricao: "ADIPONITRILA; FABRICACAO DE" },
  {
    cnae: "2022300",
    descricao: "DIISOCIANATO DE DIFENILMETANO; FABRICACAO DE",
  },
  { cnae: "2022300", descricao: "CAPROLACTAMA; FABRICACAO DE" },
  {
    cnae: "2022300",
    descricao: "2 ETIL - 1 - HEXANOL (OCTANOL); FABRICACAO DE",
  },
  { cnae: "2022300", descricao: "ESTIRENO; FABRICACAO DE" },
  { cnae: "2022300", descricao: "PARAFORMALDEIDO; FABRICACAO DE" },
  { cnae: "2022300", descricao: "DICLOROETANO; FABRICACAO DE" },
  { cnae: "2022300", descricao: "ISODECANOL; FABRICACAO DE" },
  {
    cnae: "2022300",
    descricao: "HEXAMETILENODIAMINA E SEUS SAIS; FABRICACAO DE",
  },
  { cnae: "2022300", descricao: "METACRILATO DE METILA; FABRICACAO DE" },
  { cnae: "2022300", descricao: "POLIPROPILENOGLICOIS; FABRICACAO DE" },
  { cnae: "2022300", descricao: "FENOL (HIDROXIBENZENO); FABRICACAO DE" },
  { cnae: "2022300", descricao: "ACIDO ADIPICO; FABRICACAO DE" },
  { cnae: "2022300", descricao: "ACRILAMIDA; METACRILAMIDAS; FABRICACAO DE" },
  {
    cnae: "2022300",
    descricao: "4-4' ISOPROPILIDEDIFENOL E SEUS SAIS; FABRICACAO DE",
  },
  { cnae: "2022300", descricao: "ISO-BUTANOL; FABRICACAO DE" },
  { cnae: "2022300", descricao: "PENTAERITRITOL; FABRICACAO DE" },
  { cnae: "2022300", descricao: "METANAL (FORMALDEIDO); FABRICACAO DE" },
  { cnae: "2022300", descricao: "ANIDRIDO MALEICO; FABRICACAO DE" },
  { cnae: "2022300", descricao: "ACETATO DE CELULOSE; FABRICACAO DE" },
  {
    cnae: "2022300",
    descricao: "OCTANOS (ALCOOL OCTILICO) E SEUS ISOMEROS; FABRICACAO DE",
  },
  {
    cnae: "2022300",
    descricao: "PRODUTOS INTERMEDIARIOS PARA RESINAS E FIBRAS; FABRICACAO DE",
  },
  { cnae: "2022300", descricao: "CICLOHEXANOL; FABRICACAO DE" },
  { cnae: "2022300", descricao: "FORMALDEIDO; FABRICACAO DE" },
  { cnae: "2022300", descricao: "ALFA- METILESTIRENO; FABRICACAO DE" },
  { cnae: "2022300", descricao: "ISOTRIDECANOL; FABRICACAO DE" },
  {
    cnae: "2022300",
    descricao:
      "ACIDO ACRILICO E METACRILICO; SEUS SAIS E ESTERES; FABRICACAO DE",
  },
  { cnae: "2022300", descricao: "N-BUTANOL; FABRICACAO DE" },
  { cnae: "2022300", descricao: "ACETATO DE CELULOSE PLASTICO; FABRICACAO DE" },
  {
    cnae: "2022300",
    descricao: "CLORETO DE VINILA (CLOROETILENO) MONOMERO; FABRICACAO DE",
  },
  { cnae: "2022300", descricao: "METENAMINA E SEUS SAIS; FABRICACAO DE" },
  { cnae: "2022300", descricao: "GLIOXAL; FABRICACAO DE" },
  { cnae: "2022300", descricao: "ANIDRIDO FTALICO; FABRICACAO DE" },
  {
    cnae: "2022300",
    descricao: "1,2-DICLOROETANO (CLORETO DE ETILENO); FABRICACAO DE",
  },
  { cnae: "2022300", descricao: "4,4' -DIAMINODIFENILMETANO; FABRICACAO DE" },
  { cnae: "2022300", descricao: "TOLUENO DIISOCIANATO; FABRICACAO DE" },
  { cnae: "2022300", descricao: "ETILENOGLICOL (ETANODIO); FABRICACAO DE" },
  { cnae: "2022300", descricao: "OCTANOL; FABRICACAO DE" },
  { cnae: "2022300", descricao: "ACETATO DE VINILA MONOMERO; FABRICACAO DE" },
  {
    cnae: "2022300",
    descricao: "ADIPATO DE HEXAMETILENODIAMINA; FABRICACAO DE",
  },
  { cnae: "2022300", descricao: "ACETONA-CIANIDRINA; FABRICACAO DE" },
  { cnae: "2022300", descricao: "BISFENOL A; FABRICACAO DE" },
  { cnae: "2022300", descricao: "DIISOCIANATOS DE TOLUENO; FABRICACAO DE" },
  { cnae: "2022300", descricao: "DIMETILTEREFTALATO; FABRICACAO DE" },
  { cnae: "2022300", descricao: "TEREFLALATO DE DIMETILA; FABRICACAO DE" },
  { cnae: "2022300", descricao: "ACIDO TEREFTALICO; FABRICACAO DE" },
  {
    cnae: "2022300",
    descricao: "6-HEXANOLATAMA (EPSILON-CAPROLACTAMA); FABRICACAO DE",
  },
  { cnae: "2022300", descricao: "ALCOOL ISOBUTILICO; FABRICACAO DE" },
  {
    cnae: "20291",
    descricao:
      "FABRICACAO DE PRODUTOS QUIMICOS ORGANICOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "2029100", descricao: "ACIDO ISOFTALICO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "COMPOSTOS DE FUNCAO ALDEIDO, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "COMPOSTOS DE FUNCAO CETONA OU DE FUNCAO QUINONA, N.E.; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CRESOIS E SEUS SAIS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ALCOOL LAURICO (ALCOOL GRAXO (GORDOS) INDUSTRIAL); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "BREU DA DESTILACAO DA MADEIRA; PRODUCAO DE" },
  {
    cnae: "2029100",
    descricao: "ACIDO 1-NAFTILAMINA-5-SULFONICO [ACIDO LAURENT]; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ALCOOIS CICLICOS E SEUS DERIVADOS, N.E.; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "AZOBENZENO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "MATERIAS CORANTES DE ORIGEM VEGETAL OU ANIMAL E PREPARACOES; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ESTER-2-NITROBENZILIDENO METILICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "DIMETIL HIDRAZINA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CLORETO DE TIONILA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "COLOFONIAS E ACIDOS RESINICOS E SEUS DERIVADOS; ESSENCIAS E OLEOS DE COLOFONIAS; GOMAS FUNDIDAS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "OLEOS E PRODUTOS DA DESTILACAO DO ALCATRAO DE HULHA E DE OUTROS ALCATROES MINERAIS; (BENZOIS, NAFTALENO, CREOSOTO, TOLUOIS, XILOIS); FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ACIDO 1-NAFTOL-5-SULFONICO [ACIDO SCHAEFFER]; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ACIDO ANILINA-2,5-DISSULFONICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "METIL SALICILATO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CLORONAFTALENO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "DERIVADOS, APENAS NITRATOS OU APENAS NITROSADOS, DOS HIDROCARBONETOS, SEUS SAIS E ESTERES; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "EXTRATO DE QUEBRACHO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "CERA ARTIFICIAL DE POLIETILENO; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ACETANILIDA, [N-ACETIL-P-FENILENODIAMINA]; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ACIDO 7-AMINONAFTALENO-1,3,6-TRISSULFONICO (SAIS); FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "CLORETO DE ETILA (CLOROETANO); FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ACIDO 4-TOLUIDINA-3-SULFONICO [ACIDO 4B]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "MONOETILENOGLICOL; FABRICACAO DE" },
  { cnae: "2029100", descricao: "3-FENOXIBENZALDEIDO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "MONOAMINAS E POLIAMINAS, N.E.; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "MISTURAS DE ALQUIBENZENOS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACETADO DE ETILGLICOL; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ANTRAQUINONA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ESTERES DE N-BUTILA DO ACIDO METACRILICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "2-AMINO-6-METOXIBENZOTIAZOL; FABRICACAO DE" },
  { cnae: "2029100", descricao: "BROMOFORMIO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "METIL CARBONATO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "BUTILENOGLICOIS E OUTROS ESTERES; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "1-(4-SULFOFENIL)-3-METIL-5-PIRAZOLONA (ACIDO PIRAZOLICO); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "DIMETIL DIVINIL ACETILENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CORANTES A TINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "INTERMEDIARIOS PARA DETERGENTES E TENSOATIVOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "TERPINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "M-FENILENODIAMINA E SEUS SAIS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "2,4-DINITRO-6-BROMOANILINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ACIDO 1-AMINO-4-BROMOANTRAQUINONA-2-SULFONICO E SEUS SAIS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACIDO ESTEARICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "SAIS DE PIRIDINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "2-METOXI-5-METILANILINA [P-CRESIDINA]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACIDO SULFONICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACIDO TRICLOROFENOXIACETICO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "M-NITRO-P-TOLUIDINA E SEUS SAIS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "P-CLOROTOLUENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "XILIDINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ETER HEXILICO DO ETILENOGLICOL; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "AMINOANTRAQUINONA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "BETANAFTOL E SEUS SAIS [2-HIDROXINAFTALENO); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ISOPROPANOL; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CLOROTOLUENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "MONOISOPROPILAMINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "AMIDAS ACICLICAS, N.E.; SEUS DERIVADOS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "AMIDAS CICLICAS, N.E.; SEUS DERIVADOS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "MISTURAS DE AGENTES ORGANICOS DE SUPERFICIE, N.E.; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "M-AMINOFENOL; FABRICACAO DE" },
  { cnae: "2029100", descricao: "2-CLORO-4-NITROANILINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "1-NAFTILAMINA (ALFA-NAFTILAMINA), 2-NAFTILAMINA (BETA-NAFTILAMINA), E SEUS DERIVADOS; SAIS DESTES PRODUTOS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "CORANTES DIRETOS (NORMAIS OU MODERADOS); FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "2-METOXI-5-METILANILINA [P-NITROORTOSINA]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "OXIDO DE ETENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "HIDROXIETILCELULOSE; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACETONA NAO CONTENDO OUTRAS FUNCOES OXIGENADAS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "TALL OIL, MESMO REFINADO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ALCOOL BENZILICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "DODECILBENZENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "2-AMINOANTRAQUINONA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "M-TOLUILENODIAMINA E SEUS SAIS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "QUINIZARINA, [1,4-DIHIDROXIANTRAQUINONA]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ORTOFTALATOS DE DIBUTILA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ACIDO METANILICO [ACIDO 3-AMINOBENZENOSSULFONICO]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "MASTERBATCH; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "2-(4-AMINO-2,5-DIMETOXIFENILSULFONIL)-ETILSULFATO; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ACIDO TOLUENOSSULFONICO, XILENOSSULFONICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ALCATRAO DE MADEIRA; PRODUCAO DE" },
  {
    cnae: "2029100",
    descricao: "FENOIS; FENOIS-ALCOOIS E SEUS DERIVADOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "FORMIATO DE NIQUEL; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ESTERES DE BUTILA DO ACIDO ACRILICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACETATO DE MANGANES; FABRICACAO DE" },
  { cnae: "2029100", descricao: "3-AMINO-5-METIL ISOXAZOL; FABRICACAO DE" },
  { cnae: "2029100", descricao: "OLEO DE ALCATRAO DE MADEIRA; PRODUCAO DE" },
  { cnae: "2029100", descricao: "NITROCELULOSE; FABRICACAO DE" },
  { cnae: "2029100", descricao: "PENTACLOROFENATO DE SODIO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "SAIS DE SODIO DO ACIDO BENZOICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACIDO IMINODIACETICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "DIETILCICLOHEXANO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ETERES MONOALQUILICOS DO DIETILENOGLICOL, N.E.; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACRILATO DE ETILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACIDO BARBITURICO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACIDO ANILINA-2,4-DISSULFONICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "AZELATO DE DIOCTILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "BENZANTRONA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "O-AMINOAZOTOLUENO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ESTERES DE ETILA DO ACIDO ACRILICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "COALHOS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ESSENCIAS, OLEOS E OUTROS DERIVADOS DE COLOFONIAS OU DE ACIDOS RESINICOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACIDO PICRAMICO E SEUS SAIS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ALCOOL ISOPROPOPILICO (PROPAN- 2 -OL); FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ESSENCIAS DE TERENBITINA, OLEO DE PINHO OU OUTRAS ESSENCIAS TERPENICAS, DA DESTILACAO OU DE OUTROS TRATAMENTOS DE MADEIRA; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "EXTRATOS TANANTES DE ORIGEM VEGETAL; TANINOS; SEUS SAIS, ESTERES E OUTROS DERIVADOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "LINEAR ALQUILBENZENO (LAB); FABRICACAO DE" },
  { cnae: "2029100", descricao: "ESTEARINA DUPLA PRESSAO" },
  { cnae: "2029100", descricao: "ETERES GLICOLICOS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "P-AMINOACETANILIDA [4-ACETAMINO-1-AMINOBENZENO]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "QUINONAS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "METILAMINA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ALCOOL FURFURILICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "AMINOAZOTOLUENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "3-HIDROXIETILSULFONILANILINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "1-CLORO-2,4/2,6-DINITROBENZENO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "PLASTIFICANTES; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACIDO 2-NAFTOL-6,8-DISSULFONICO [ACIDO G]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CORANTES ORGANICOS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "TIAZOLIDINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "FERROCIANETO DE SODIO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "2,6 -DIR-TER-BUTIL- P -CRESOL E SEUS SAIS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "CORANTES PARA A INDUSTRIA TEXTIL; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "MONOETILAMINA E SEUS SAIS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACIDO SALICILICO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ESTERES DE METILA DO ACIDO ACRILICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ASPARTAME; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACETATO DE SODIO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "4-NITRO-2-AMINOFENOL E SEUS SAIS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ACIDO 1-NAFTILAMINA-8-SULFONICO [ACIDO PERI] [ACIDO S]; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "DIMETILDITIOCARBAMATO DE SODIO; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "NEGROS DE CARBONO DE OUTROS TIPOS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "PRODUTOS ORGANICOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CITRATO DE DIBUTILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ANTRACENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "BROMOCLOROMETANO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "CORANTES BASICOS (NORMAIS OU MODERADOS); FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "1,3,3-TRIMETIL-2-METILENOINDOLINA; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "DIFENILAMINAALQUILADA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACIDO ACRILICO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ACIDOS GRAXOS MONOCARBOXILICOS INDUSTRIAIS E OLEOS ACIDOS DE REFINACAO, N.E.; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "OXIDO DE MESITILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "PICHE; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "3-HIDROXI-N-(2-ETOXIFENIL)-2-NAFTALENOCARBOXIAMIDA; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ETERES MONOALQUILICOS DO ETILENOGLICOL, N.E.; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "TRIETILAMINA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "LIMONENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ETANOLAMINAS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "SOLUCOES OU EMULSOES DE PRODUTOS TENSOATIVOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "SULFACLORO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CORANTES DE ORIGEM VEGETAL; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "CORANTES ORGANICOS DE ORIGEM ANIMAL, VEGETAL OU SINTETICA EM FORMA BASICA OU CONCENTRADA; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "PRODUTOS TANANTES ORGANICOS SINTETICOS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "DERIVADOS DOS HIDROCARBONETOS; APENAS NITRADOS OU APENAS NITROSADOS; NITROALOGENADOS, NITROSSULFONADOS E OUTROS; SEUS SAIS E ESTERES; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ORTOFTALATOS DE DIOCTILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "DIFENILA (1,1  BIFENILA); FABRICACAO DE" },
  { cnae: "2029100", descricao: "3-4 DICLOROANILINA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "DIBENZANTRONA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACETATO DE CHUMBO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ESTERES FOSFORICOS E ESTERES DE OUTROS ACIDOS INORGANICOS, NAO ESPECIFICADOS, SEUS SAIS; SEUS DERIVADOS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "4,4-DIAMINO-3,3-DIMETILFENIL E SEUS SAIS [0-TOLIDINA]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "TRIMETIL TRITIOFOSFITO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "TERPINEOL EM BRUTO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACRILAMIDA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACETATO DE BUTILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CITRATO MONOSSODICO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "2,4,6-TRIFLUOR-5-CLOROPIRIMIDINA; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ACIDO 2,3-DIHIDROXIQUINOXALINA-6-CARBOXILICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "FTALATO DE DIOCTILA (DOP); FABRICACAO DE" },
  { cnae: "2029100", descricao: "MENTOL; FABRICACAO DE" },
  { cnae: "2029100", descricao: "TRIACETINA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "METILATO DE SODIO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CICLOHEXANONA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACIDO 4-NITROTOLUENO-2-SULFONICO [ACIDO PNTS]; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "CLORIDRATO DE 3-AMINOFENILUREIA [META-UREIDOANILINA]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACIDO BENZOICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "NONENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACIDO TANICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACIDO P-SULFANILICO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ALCOOIS GRAXOS (GORDOS) INDUSTRIAIS, N.E.; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "FOSFATO TRICRESILIO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACIDOS GRAXOS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "1-FENIL-3-METIL-5-PIRAZOLONA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "DERIVADOS HALOGENADOS DOS HIDROCARBONETOS AROMATICOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CITRATO DE CALCIO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ALCOOL POLIVINILICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CUMENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "SULFATO DE DIMETILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "N-ACETOACETIL-M-XILIDINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACIDOS VALERICOS, SEUS SAIS E ESTERES; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "INTERMEDIARIOS DE SINTESE; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "CITRONELA (3,7 -DIMETIL 6-OCETANL); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACIDO LACTOBIONICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ESTEARATOS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACIDO ISOPROPILICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "DICLOROBENZENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CORANTES DISPERSOS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "PIPERONAL; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACIDOS DINITROESTILBENODISSULFONICOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "3-DIETILAMINOFENOL; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CARBOXIMETILCELULOSE; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ACIDO 4-AMINO-2-CLOROTOLUENO-5-SULFONICO [ACIDO 2B]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "OXIRANO (OXIDO DE ETILENO); FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "N, N-DIETILANILINA [N, N-DIETIL-1-AMINOBENZENO]; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ACIDO 6-NITRO-1-DIAZO-2-NAFTOL-4-SULFONICO; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "2-CLORO -N- METILACETOACETAMIDA; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "DERIVADOS CLORADOS SATURADOS DOS HIDROCARBONETOS ACICLICOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACIDO OXALICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "GLICINA E SEUS SAIS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACRILATO DE OCTILA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "RESORCINOL E SEUS SAIS (1,3-DIHIDROXIBENZENO); FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ETER ETILICO DO ETILENOGLICOL; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "4-METIL-3-TIOSEMICARBAZIDA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ANETOL; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "2-METIL - 2,4 - PENTANODIOL (HEXILENOGLICOL); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CORANTES A CUBA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACETATO DE ETILA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACIDO 2-NAFTILAMINA-1,5-DISSULFONICO; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ACIDO 2-CLORO-5-TOLUIDINA-4-SULFONICO [ACIDO CLT]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "OXALATO DE DIETILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACIDOS COLICOS N.E.; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ENZIMAS; ENZIMAS PREPARADAS, N.E.; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "PARA-AMINO FENOL; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACIDO 2,3-QUINOLINODICARBOXILICO; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ETER DIETILICO (OXIDO DE DIETILA); FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "MONOAMINAS E POLIAMINAS ACICLICAS, CICLANICAS, CICLENICAS OU CICLOTERPENICAS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "1,4-DIAMINO-2,3-DIHIDROXIANTRAQUINONA; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACIDO FORMICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CICLOPROPANO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACETATO DE N-BUTILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "HEXACLOROETANO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACIDO FENILACETICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACRILATO DE BUTILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "FTALATO DE BUTILCICLOHEXILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACIDO CRESILICO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ESTER 1-AMINOBENZENO-4-BETA-OXIETILSULFONA DO ACIDO SULFURICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "METIL-ETIL-CETONA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACIDOS AMINONAFTOLSULFONICOS E SEUS SAIS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "DIISOPROPILAMINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "METOXI-4-ACETAMINO-5-CLOROBENZOATO DE METILA; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "BETA-METOXIPROPIONITRILA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "DERIVADOS QUIMICOS DA CELULOSE, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ACIDO N-FENIL-1-NAFTILAMINO-8-SULFONICO E SEUS SAIS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "FTALATO DE DIBUTILA (DBP); FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ETERES MONOMETILICOS DO ETILENOGLICOL / DIETILENOGLICOL; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "OXIDO DE PROPENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "FTALATO DE DIMETILA (DMP); FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACETATO DE AMILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CORANTES REATIVOS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACIDO DIMERICO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "PRODUTOS ORGANICOS SINTETICOS UTILIZADOS COMO AGENTES DE AVIVAMENTO FLUORESCENTES; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ACIDO COLICO, EXCETO FARMOQUIMICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "LACTAMAS, N.E.; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "DERIVADOS DO ESTIENZENO UTILIZADOS COMO AGENTES DE AVIVAMENTO FLUORESCENTES, DE OUTROS TIPOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACETOFENONA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CLOROFLUOROMETANOS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ANILIDAS DOS ACIDOS HIDROXINAFTOICOS E SEUS DERIVADOS; SAIS DESTES PRODUTOS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ACIDO 1-AMINOBENZENO-2-SULFONICO [ACIDO ORTANILICO]; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ESTERES FOSFORICOS E ESTERES DE OUTROS ACIDOS INORGANICOS, N.E., SEUS SAIS E DERIVADOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CICLOHEXANO (HEXAMETILENO); FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "CLORETO CUPROSO [MONOCLORETO DE COBRE]; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "METILOXIRANO (OXIDO DE PROPILENO); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ETIL PERHIDROFENANTRENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACIDO TIOGLICOLICO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACIDO 2-NAFTOL-3,6-DISSULFONICO [ACIDO R]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CUMARINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "COMPOSTOS HETEROCICLICOS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "N, N-DIMETILANILINA E SEUS SAIS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ALCOOIS ACICLICOS E SEUS DERIVADOS, N.E.; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACIDO PIVALICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACETATO DE CALCIO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CLOROFENOL; FABRICACAO DE" },
  { cnae: "2029100", descricao: "METILISOBUTILCETONA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ACIDO 1 -NAFTILAMINA-6-SULFONICO [ACIDO CLEVE 1.6]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "GERANIOL; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ALCOOL ESTEARICO (ALCOOL GRAXO (GORDOS) INDUSTRIAL); FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "2-ETIL-2 (HIDROXIMETIL) PROPANO -1,3-DIOL (TRIMETILOLPROPANO); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CARBOXIPOLIMETILENO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "DERIVADOS NITRALOGENADOS, NITROSSULFONADOS E OUTROS, DOS HIDROCARBONETOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "OLEO DE ANTRACENO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ACIDO 1 -AMINO-8-NAFTOL-3,6-DISSULFONICO E SEUS SAIS [ACIDO H]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACIDO FUMARICO E SEUS SAIS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "METOXI-4-ACETAMINO BENZOATO DE METILA; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ALCOOIS TERPENICOS ACICLICOS (LINALOL, GERANIOL, ETC); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CIPROFLOXACINAS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "FTALATO DE DIISODECILA (DIDP); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "MISTURAS DE ALQUINAFTALENOS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "LACTONAS, NAO ESPECIFICADOS; COMPOSTOS HETEROCICLICOS CUJA ESTRUTURA CONTEM UM CICLO TRIAZINA (HIDROGENADO OU NAO), NAO CONDENSADO, NAO ESPECIFICADOS; HIDANTOINA E SEUS DERIVADOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "DIPROPILENOGLICOL; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "3-HIDROXI-N-FENIL-2-NAFTALENOCARBOXIAMIDA; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "FTALOCIANINA DE COBRE CRUA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "P-NITROANILINA [4-NITROALINILA]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ALVEJANTES OPTICOS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CLORIDRATO DE TRIMETILAMINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "DICLOROMETANO (CLORETO DE METILENO); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "GELATINAS E DERIVADOS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CARVONA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "CORANTES ACIDOS, MESMO METALIZADOS; CORANTES MORDENTES E PREPARACOES A BASE DESSES CORANTES; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "BENZALDEIDO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "PIGMENTOS LUMINOFOROS ORGANICOS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ISOCIANATO DE 3,4 - DICLOROFENILA; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ROSOXACINA E OUTROS COMPOSTOS QUE CONTENHAM UMA ESTRUTURA DE CICLOS QUINOLEINA OU ISOQUINOLEINA; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ACIDOS NUCLEICOS, SEUS SAIS; OUTROS COMPOSTOS HETEROCICLICOS, N.E.; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "DIACETONA ALCOOL; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACIDO LINEAR ALQUILBENZENOSSULFONICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CELULOSE DE LINTER; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "1-(4-BETA-HIDROXIETILSULFOFENIL)-3-METIL-5-PIRAZOLONA; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ACIDO 4-NITRO-4 -AMINODIFENILAMINA-2-SULFONICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "AZUL DE FTALOCIAMINAS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ACIDO 5,5-DIHIDROXI-7,7 -DISSULFO-2,2 -DINAFTILUREIA E SEUS SAIS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ISOPARAFINA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ETER SULFURICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "TRICLOROETANO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "LACAS CORANTES; PREPARACOES A BASE DE LACAS CORANTES; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACIDO SEBACEO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "DERIVADOS PERALOGENADOS, UNICAMENTE COM FLUOR E CLORO; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "2-(4-AMINO-5-METOXI-2-METILFENILSULFONIL)-ETILSULFATO; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "COMPOSTOS HETEROCICLICOS CONTENDO UM CICLO TRIAZOL; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "CLORETO CIANURICO [TRICLOROTRIAZINA]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CORANTES AZOICOS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ACIDO 2-NAFTILAMINA-4,8-DISSULFONICO E SEUS SAIS [ACIDO C]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ETER METILICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACIDO ISONICOTINICO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "DERIVADOS HALOGENADOS DOS HIDROCARBONETOS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "PRODUTOS DA DESTILACAO DO ALCATRAO DE HULHA (BENZOIS, TOLUOIS, XILOIS, NAFTALENO); PRODUCAO DE",
  },
  { cnae: "2029100", descricao: "BENZOIL-METRONIDAZOL; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CLOROFORMIO (TRICLOROMETANO); FABRICACAO DE" },
  { cnae: "2051700", descricao: "DIMETOATO, FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACIDO AMINOSSULFONICO [ACIDO SULFAMICO]; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ACETATOS DE ETERES GLICOLICOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ANIDRIDO ACETICO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "DERIVADOS HALOGENADOS OU DERIVADOS CLORADOS NAO SATURADOS DOS HIDROCARBONETOS ACICLICOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ACIDO 4-CLORO-3-HIDROXI-2,5-DIMETOXI-2-NAFTALANILIDA; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "D-GLUCITOL (SORBITOL) (POLIALCOOL); FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ACIDO ESTEARCIO (ACIDO GRAXO MONOCARBOXILICO INDUSTRIAL); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "TRIMETOXIBENZALDEIDO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "PROPILENOGLICOIS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ESTEARINA DESTILADA" },
  { cnae: "2029100", descricao: "ACIDO 2 - ETILHEXANOICO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "4-METILPENTAN-2 -ONA (METILISOBUTILCETONA); FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "COMPOSTOS ORGANOINORGANICOS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ORTOFTALATOS DE DINONILA OU DE DIDECILA; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "EXTRATOS TANANTES SINTETICOS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "MONOALCOOIS,FABRICACAO DE" },
  { cnae: "2029100", descricao: "DIMETILFORMAMIDA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "2,5-DICLOROANILINA [2,5-DICLORO-1-AMINOBENZENO]; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "CARBONO (NEGROS DE CARBONO OU NEGROS DE FUMO E OUTRAS FORMAS DE CARBONO NAO ESPECIFICADAS); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "N-DIPROPILAMINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ESTERES DO ACIDO METACRILICO, N.E.; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ADIPATO DE DIISOBUTILA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "3,3-DICLOROBENZIDINA E SEUS SAIS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "TIOCOMPOSTOS ORGANICOS, N.E.; FABRICACAO DE" },
  { cnae: "2029100", descricao: "TEREBINTINA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "2,4,5-TRICLOROANILINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "BREU E COQUE DE BREU, A PARTIR DO ALCATRAO DE HULHA",
  },
  {
    cnae: "2029100",
    descricao:
      "ACIDOS MONOCARBOXILICOS CICLICOS; SEUS DERIVADOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "GLICEROFOSFATO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "DI E TRIETILENOGLICOIS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ESTERES DO ACIDO ORTOFTALICO, N.E.; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "PIGMENTOS ORGANICOS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "5-METIL-3-CARBOXIAMIDO ISOXAZOL; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "DECAHIDRONAFTALENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "COLOFONIA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ESSENCIAS TERPENICAS PROVENIENTES DA DESTILACAO OU DE OUTROS TRATAMENTOS DA MADEIRA; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "AMINOBENZOIL-AMINOBENZOL-3-BETA-OXIETILSULFONA; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "LIXIVIAS RESIDUAIS DA FABRICACAO DAS PASTAS DE CELULOSE; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ACIDO OLEICO (ACIDO GRAXO MONOCARBOXILIXO INDUSTRIAL); FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ALCOOL PROPILICO (PROPAN-1-OL); FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ETERES, PEROXIDOS ORGANICOS, EPOXIDOS, ACETAIS E SEMIACETAIS E SEUS DERIVADOS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "OCTIFENOL, NONILFENOL, SEUS ISOMEROS E SAIS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "N-ETIL-N-(2 -HIDROXIETIL)-AMINOBENZENO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "N-ETIL-O-TOLUIDINA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ETILAMINAS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "SORBITOL; FABRICACAO DE" },
  { cnae: "2029100", descricao: "HEXACLORETO DE BENZENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "FORMIATO DE SODIO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ACIDO 4-AMINODIFENILAMINO-2-SULFONICO [ACIDO PADOS]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CLORAL (TRICLOROACETALDEIDO); FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "3-DI-(BETA-ACETOXIETIL)-AMINO-4-METOXIACETANILIDA; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CORANTES MORDENTES; FABRICACAO DE" },
  { cnae: "2029100", descricao: "MENADIONA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "METATIAZANONA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "NAFTOL; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ACIDOS MONOCARBOXILICOS ACICLICOS NAO SATURADOS E ACIDOS MONOCARBOXILICOS CICLICOS, NAO ESPECIFICADOS; SEUS DERIVADOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "PROPILPARABENO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "COMPOSTOS ORGANICOS DE MERCURIO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACRILATO DE METILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "POLIETILENOGLICOIS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "POLIISOBUTENOS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "AMINOFENOL; FABRICACAO DE" },
  { cnae: "2029100", descricao: "TRIMETILOLPROPANO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "2-CLORO-ETAN-1-0L [ETILENO CLORIDRINA]; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ACIDO 4,4-DIAMINODIFENILAMINO-2-SULFONICO E SEUS SAIS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "AGENTES DE BRANQUEIO OPTICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ALDEIDO ACETICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CLOROACETILSULFONANILIDA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ALBUMINAS (EXCETO OVALBUMINA); ALBUMINATOS E OUTROS DERIVADOS DAS ALBUMINAS",
  },
  {
    cnae: "2029100",
    descricao:
      "4-CLORO-2-NITRO-1-AMINOBENZENO [4-CLORO-2-NITROANILINA]; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "HIDANTOINA E SEUS DERIVADOS; OUTROS COMPOSTOS CUJA ESTRUTURA CONTEM UM CICLO IIDAZOL, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "TETRACLOROETILENO (PERCLOROETILENO); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "SULFATO DE DIETILA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "D-ALFA FENIGLICINA, CLORETO CLORIDRATO; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "FTALATO DE BUTILBENZILA (BBP); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ETANAL (ACETALDEIDO); FABRICACAO DE" },
  { cnae: "2029100", descricao: "GLICOSIDEOS E DERIVADOS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "HIDROQUINONA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "MATERIAS CORANTES ORGANICAS SINTETICAS, NAO ESPECIFICADOS, INCLUSIVE CORANTES AZOICOS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ACIDOS CARBOXILICOS CONTENDO FUNCOES OXIGENADAS, N.E., E SEUS DERIVADOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CLORIDRATO DE BENZIDINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "TANANTES SINTETICOS ORGANICOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "N-ACETIL SULFAMETOXAZOL; FABRICACAO DE" },
  { cnae: "2029100", descricao: "AMINOAZOBENZENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "FTALATO DE DIETILA (DEP); FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "OLEO DE HORTELA BENEFICIADO (MENTOL); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "DICLORODIFLUOROMETANO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "FURFURAL; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "MISTURA DE ALCOOIS PRIMARIOS ALIFATICOS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ANISIDINAS E SEUS SAIS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "CORANTES REAGENTES E SUAS PREPARACOES; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ETERES MONOBUTILICOS DOS ETILENOGLICOIS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CARVAO ATIVADO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "COMPOSTOS AMINADOS DE FUNCOES OXIGENADAS, N.E.; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "PROPILAMINAS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "DIACETATO DE NITROFURFURAL; FABRICACAO DE" },
  { cnae: "2029100", descricao: "DIETILAMINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACIDO 3-HIDROXI-2-NAFTOICO [ACIDO BON]; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "3-AMINO-4-METOXIBENZANILIDA [ANIS BASE]; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ACIDOS GRAXOS (GORDOS) DIMERIZADOS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ACIDO 4,4-DIAMINOESTILBENO-2,2-DISSULFONICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "TRIMELITATOS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACETATO DE ISOPROPILA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "FTALATO DE DIISOBUTILA (DIBP); FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ACIDO ALGINICO, SEUS SAIS E ESTERES EM FORMA PRIMARIA; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "DIMETILITIOFOSFORAMIDA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ETILENODIAMINA E SEUS SAIS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ACIDO 1-NAFTOL-4-SULFONICO [ACIDO NEVILLE-WINTHER]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ESTERES DE BORNILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CITRAL; FABRICACAO DE" },
  { cnae: "2029100", descricao: "NITROBENZENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "OLEO DE CREOSOTO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "FTALATO DE BUTILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "OLEO DE PINHO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACIDO ISOOCTILICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "TRICLOROETILENO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "CLORETO DE METILA (CLOROMETANO); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "PIRIDINA, SEUS SAIS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "FTALATO DE DIAMILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "PLASTIFICANTES POLIMEROS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACETOACETANILIDA-2,5-DIMETOXI-4-CLOROANILIDA; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "12,12-DIMETOXIDIBENZANTRONA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CORANTES SOLVENTES; FABRICACAO DE" },
  { cnae: "2029100", descricao: "SEBACATOS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ALCOOL SEC-BUTILICO OU ALCOOL TER-BUTILICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "SAIS DE DIETILAMINA, N.E.; FABRICACAO DE" },
  { cnae: "2029100", descricao: "HIDROPEROXIDO DE CUMENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "OLEINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACIDOS POLICARBOXILICOS, N.E.; SEUS DERIVADOS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "DERIVADOS, APENAS SULFONADOS, DOS HIDROCARBONETOS, SEUS SAIS E ESTERES; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACIDO 6-AMINOPENICILAMICO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ETILATO DE SODIO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "AZUL ULTRAMAR; FABRICACAO DE" },
  { cnae: "2029100", descricao: "FENOLATO DE SODIO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACIDO 2-CLORO-5-NITROBENZENOSSULFONICO; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "INTERMEDIARIOS PARA FARMOQUIMICOS, DEFENSIVOS AGRICOLAS E ADITIVOS EM GERAL; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ETER ISOBUTILICO DO ETILENOGLICOL; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "COMPOSTOS DE FUNCAO NITRILA, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "2,2'-OXIDIETANOL (DIETILENOGLICOL); FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CARBOXIMETILAMIDO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "BUTANONA (METILETILCETONA); FABRICACAO DE" },
  { cnae: "2029100", descricao: "ACIDO ACETICO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ENLOPLATINA, DEXORMAPLATINA, IPROPLATINA; IOBAPLATINA; MIBOPLATINA; ORMAPLATINA; SEBRIPLATINA E ZENIPLATINA; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACETOACETANILIDA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "AMINAS GRAXAS, SEUS SAIS E ESTERES; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "CREOSOTO DE MADEIRA; PRODUCAO DE" },
  { cnae: "2029100", descricao: "MORFOLINA; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "POLITETRAMETILENOTERGLICOL EM FORMAS PRIMARIAS; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ESTERES DE ACIDO ADIPICO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "SAIS DE SODIO DO ACIDO FORMICO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "ACETATO DE ISOBUTILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "SOLVENTES ORGANICOS; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "PIGMENTOS ORGANICOS DE ORIGEM ANIMAL, VEGETAL OU SINTETICA EM FORMA BASICA OU CONCENTRADA; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "DIAMINOANTRAQUINONILBENZANTRONA; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "COMPOSTOS HETEROCICLICOS EXCLUSIVAMENTE DE HETEROATOMOS DE OXIGENIO; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "O-FTALODINITRILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "O-TOLUIL-M-AMINOFENOL; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao:
      "ACIDO METAFENILENODIAMINA-4-SULFONICO [ACIDO METAMINICO N]; FABRICACAO DE",
  },
  { cnae: "2029100", descricao: "TETRAMERO DE PROPENO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "BUTILAMINAS E SEUS SAIS; FABRICACAO DE" },
  { cnae: "2029100", descricao: "CLORETO DE BENZILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "TETRACLORETO DE CARBONO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "ADIPATO DE DIOCTILA; FABRICACAO DE" },
  { cnae: "2029100", descricao: "TRIBROMOMETANO; FABRICACAO DE" },
  { cnae: "2029100", descricao: "NEGRO-DE-FUMO; FABRICACAO DE" },
  {
    cnae: "2029100",
    descricao: "ACIDO 2-NAFTILAMINA-6,8-DISSULFONICO; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ETER ETILICO DE BUTILENOGLICOL; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ETERES, PEROXIDOS ORGANICOS, EPOXIDOS, ACETAIS, SEMIACETAIS OU SEUS DERIVADOS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao:
      "ACETATO DE AMINAS GRAXAS NATURAIS E ESTERES GRAXOS; FABRICACAO DE",
  },
  {
    cnae: "2029100",
    descricao: "ACIDO 2-NAFTILAMINA-1-SULFONICO [ACIDO TOBIAS]; FABRICACAO DE",
  },
  { cnae: "20312", descricao: "FABRICACAO DE RESINAS TERMOPLASTICAS" },
  { cnae: "2031200", descricao: "POLIACRILONITRILA; FABRICACAO DE" },
  {
    cnae: "2031200",
    descricao:
      "POLIMEROS DE PROPILENO EM FORMAS PRIMARIAS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2031200",
    descricao: "RESINA DE PETROLEO, DE CUMARONA; FABRICACAO DE",
  },
  { cnae: "2031200", descricao: "POLIPROPILENO; FABRICACAO DE" },
  {
    cnae: "2031200",
    descricao: "POLIETILENO DE BAIXA DENSIDADE (PEBD); FABRICACAO DE",
  },
  { cnae: "2031200", descricao: "RESINAS DE METIL ACRILATOS; FABRICACAO DE" },
  { cnae: "2031200", descricao: "POLIACETAL; FABRICACAO DE" },
  { cnae: "2031200", descricao: "POLIMEROS EPDM; FABRICACAO DE" },
  { cnae: "2051700", descricao: "DIURON, FABRICACAO DE" },
  {
    cnae: "2031200",
    descricao: "TEREFTALATO DE POLIETILENO (PET); FABRICACAO DE",
  },
  {
    cnae: "2031200",
    descricao: "POLIETILENO DE ALTA DENSIDADE (PEAD); FABRICACAO DE",
  },
  { cnae: "2031200", descricao: "RESINAS NITROCELULOSICAS; FABRICACAO DE" },
  {
    cnae: "2031200",
    descricao: "POLIETILENO LINEAR EM FORMAS PRIMARIAS; FABRICACAO DE",
  },
  {
    cnae: "2031200",
    descricao: "RESINAS DE FLUOROHIDROCARBONO; FABRICACAO DE",
  },
  {
    cnae: "2031200",
    descricao:
      "PERMUTADORES DE IONS A BASE DE POLIMEROS, EM FORMAS PRIMARIAS; FABRICACAO DE",
  },
  { cnae: "2031200", descricao: "CLOROACETATO DE POLIVINILA; FABRICACAO DE" },
  { cnae: "2031200", descricao: "ACETATO DE POLIVINILA; FABRICACAO DE" },
  {
    cnae: "2031200",
    descricao: "COPOLIMERO DE ETILENO E ACETATO DE VINILA (EVA); FABRICACAO DE",
  },
  {
    cnae: "2031200",
    descricao: "POLIMEROS DE ETILENO EM FORMAS PRIMARIAS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2031200",
    descricao: "POLIMEROS DE ESTIRENO EM FORMAS PRIMARIAS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2031200",
    descricao:
      "POLIOXIFENILENO COM E SEM CARGA, EM FORMA PRIMARIA; FABRICACAO DE",
  },
  { cnae: "2031200", descricao: "RESINAS CELULOSICAS; FABRICACAO DE" },
  {
    cnae: "2031200",
    descricao:
      "POLIMEROS NATURAIS, INCLUSIVE MODIFICADOS, EM FORMAS PRIMARIAS; FABRICACAO DE",
  },
  {
    cnae: "2031200",
    descricao: "RESINA DE ESTIRENO - ACRILONITRILA (SAN); FABRICACAO DE",
  },
  { cnae: "2031200", descricao: "RESINAS ACRILICAS; FABRICACAO DE" },
  {
    cnae: "2031200",
    descricao:
      "COPOLIMERO DE ACRILONITRILA-BUTADIENO-ESTIRENO (ABS); FABRICACAO DE",
  },
  {
    cnae: "2031200",
    descricao:
      "POLIAMIDA-6 OU POLIAMIDA-6,6; POLIAMIDA-11; POLIAMIDA-12 EM FORMAS PRIMARIAS (EM BLOCOS IRREGULARES, PEDACOS, POS, GRUMOS, LIQUIDOS OU PASTAS); FABRICACAO DE",
  },
  {
    cnae: "2031200",
    descricao: "POLIAMIDAS, N.E., EM FORMAS PRIMARIAS; FABRICACAO DE",
  },
  {
    cnae: "2031200",
    descricao: "RESINAS DE METIL METACRILATOS; FABRICACAO DE",
  },
  {
    cnae: "2031200",
    descricao: "PLASTICOS DE ENGENHARIA (ABS E POLIACETAL); FABRICACAO DE",
  },
  {
    cnae: "2031200",
    descricao:
      "POLITETRAFLUORETILENOS (PTFE) EM FORMAS PRIMARIAS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2031200",
    descricao:
      "POLIMEROS DE CLORETO DE VINILA OU DE OUTRAS OLEFINAS HALOGENADAS EM FORMAS PRIMARIAS, N.E.; FABRICACAO DE",
  },
  { cnae: "2031200", descricao: "POLIESTIRENO EXPANSIVEL; FABRICACAO DE" },
  { cnae: "2031200", descricao: "POLIMEROS DE ISOBUTILENO; FABRICACAO DE" },
  { cnae: "2031200", descricao: "POLICLORETO DE VINILA (PVC); FABRICACAO DE" },
  {
    cnae: "2031200",
    descricao:
      "COPOLIMERO DE POLIVINILBUTIRAL, EM FORMAS PRIMARIAS; FABRICACAO DE",
  },
  { cnae: "2031200", descricao: "PVC (POLICLORETO DE VINILA); FABRICACAO DE" },
  { cnae: "2031200", descricao: "POLIAMIDAS; FABRICACAO DE" },
  { cnae: "2031200", descricao: "RESINAS DE PETROLEO; FABRICACAO DE" },
  { cnae: "2031200", descricao: "RESINAS VINILICAS; FABRICACAO DE" },
  { cnae: "2031200", descricao: "RESINAS ABS; FABRICACAO DE" },
  { cnae: "2031200", descricao: "RESINA DE TETRAFLUOROETILENO; FABRICACAO DE" },
  { cnae: "2031200", descricao: "RESINAS DE FENOL-FURFURAL; FABRICACAO DE" },
  {
    cnae: "2031200",
    descricao: "RESINAS DE ESTIRENO-BUTADIENO; FABRICACAO DE",
  },
  { cnae: "2031200", descricao: "RESINAS DE DICIANODIAMINA; FABRICACAO DE" },
  { cnae: "2031200", descricao: "POLIESTIRENO; FABRICACAO DE" },
  { cnae: "2031200", descricao: "TEREFTALATO DE POLIBUTILENO; FABRICACAO DE" },
  { cnae: "2031200", descricao: "ACETATO DE VINILA; FABRICACAO DE" },
  {
    cnae: "2031200",
    descricao:
      "POLIMEROS DE ACETATO DE VINILA OU DE OUTROS ESTERES DE VINILA EM FORMAS PRIMARIAS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2031200",
    descricao:
      "POLISSULFETO DE FENILENO, SEM CARGA, EM FORMAS PRIMARIAS; FABRICACAO DE",
  },
  { cnae: "2031200", descricao: "POLIPROPILENO (PP); FABRICACAO DE" },
  { cnae: "2031200", descricao: "RESINAS DE DIISOCIANATO; FABRICACAO DE" },
  {
    cnae: "2031200",
    descricao:
      "COPOLIMERO DE FLUORETANO DE VINILIDENO E HEXAFLUORPROPILENO; FABRICACAO DE",
  },
  { cnae: "2031200", descricao: "POLICARBONATOS; FABRICACAO DE" },
  {
    cnae: "2031200",
    descricao: "POLIMEROS ACRILICOS EM FORMAS PRIMARIAS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2031200",
    descricao:
      "POLIAMIDAS, NAO ESPECIFICADOS, EM FORMAS PRIMARIAS; FABRICACAO DE",
  },
  { cnae: "20321", descricao: "FABRICACAO DE RESINAS TERMOFIXAS" },
  { cnae: "2032100", descricao: "RESINAS TERMOFIXAS; FABRICACAO DE" },
  { cnae: "2032100", descricao: "RESINAS EPOXIDAS; FABRICACAO DE" },
  {
    cnae: "2032100",
    descricao: "RESINA DE POLIESTER INSATURADO; FABRICACAO DE",
  },
  { cnae: "2032100", descricao: "RESINAS FTALICAS; FABRICACAO DE" },
  { cnae: "2032100", descricao: "RESINAS DE POLIURETANO; FABRICACAO DE" },
  {
    cnae: "2032100",
    descricao:
      "POLIESTERES, NAO ESPECIFICADOS, EM FORMAS PRIMARIAS; FABRICACAO DE",
  },
  { cnae: "2032100", descricao: "RESINAS FENOLICAS; FABRICACAO DE" },
  {
    cnae: "2032100",
    descricao:
      "POLITERPENO, POLISSULFETOS, POLISSULFONAS E OUTROS PRODUTOS SEMELHANTES, N.E.; FABRICACAO DE",
  },
  { cnae: "2032100", descricao: "POLIURETANOS; FABRICACAO DE" },
  { cnae: "2032100", descricao: "SILICONES EM FORMA PRIMARIA; FABRICACAO DE" },
  { cnae: "2032100", descricao: "RESINAS DE SILICONE; FABRICACAO DE" },
  { cnae: "2032100", descricao: "RESINAS CRESOLICAS; FABRICACAO DE" },
  { cnae: "2032100", descricao: "RESINAS EPOXI; FABRICACAO DE" },
  { cnae: "2032100", descricao: "RESINAS ALQUIDICAS; FABRICACAO DE" },
  {
    cnae: "2032100",
    descricao: "RESINA DE UREIA - FORMALDEIDO; FABRICACAO DE",
  },
  { cnae: "2032100", descricao: "RESINA DE FENOL FORMALDEIDO; FABRICACAO DE" },
  {
    cnae: "2032100",
    descricao:
      "RESINAS UREICAS E RESINAS DE TIOUREIA, EM FORMAS PRIMARIAS; FABRICACAO DE",
  },
  { cnae: "2032100", descricao: "RESINAS DE CUMARONA - INDENO; FABRICACAO DE" },
  {
    cnae: "2032100",
    descricao:
      "OLEOS HIDROLISADOS DE DIMETILDICLOSILANO (SILICONE); FABRICACAO DE",
  },
  { cnae: "2032100", descricao: "RESINAS AMINICAS; FABRICACAO DE" },
  {
    cnae: "2032100",
    descricao: "RESINA DE MELAMINA - FORMALDEIDO; FABRICACAO DE",
  },
  { cnae: "20339", descricao: "FABRICACAO DE ELASTOMEROS" },
  { cnae: "2033900", descricao: "POLIETILENOS CLOROSULFONADOS; FABRICACAO DE" },
  {
    cnae: "2033900",
    descricao: "LATICES DE ESTIRENO-BUTADIENO; FABRICACAO DE",
  },
  {
    cnae: "2033900",
    descricao: "BORRACHA ESTIRENO-ISOPRENO-ESTIRENO EM CHAPAS; FABRICACAO DE",
  },
  { cnae: "2033900", descricao: "BORRACHAS DE POLIMETILENO; FABRICACAO DE" },
  { cnae: "2033900", descricao: "BORRACHAS DE SILICONE; FABRICACAO DE" },
  { cnae: "2033900", descricao: "LATEX DE SBR; FABRICACAO DE" },
  { cnae: "2033900", descricao: "POLIBUTADIENO; FABRICACAO DE" },
  { cnae: "2033900", descricao: "BORRACHA TIPO ISOCIANATO; FABRICACAO DE" },
  { cnae: "2033900", descricao: "BORRACHAS DE ACRILATOS; FABRICACAO DE" },
  { cnae: "2033900", descricao: "POLICLOROPRENO; FABRICACAO DE" },
  { cnae: "2033900", descricao: "NEOPRENO; FABRICACAO DE" },
  { cnae: "2033900", descricao: "BORRACHAS SINTETICAS; FABRICACAO DE" },
  { cnae: "2033900", descricao: "BORRACHAS NITRILICAS; FABRICACAO DE" },
  {
    cnae: "2033900",
    descricao: "SBR (BORRACHA BUTADIENO - ESTIRENO); FABRICACAO DE",
  },
  {
    cnae: "2033900",
    descricao: "COPOLIMEROS BUTADIENO - ACRILONITRILA; FABRICACAO DE",
  },
  {
    cnae: "2033900",
    descricao: "BORRACHA DE ESTIRENO-BUTADIENO; FABRICACAO DE",
  },
  { cnae: "2033900", descricao: "BORRACHAS BUTILICAS; FABRICACAO DE" },
  { cnae: "2033900", descricao: "POLISSULFETOS; FABRICACAO DE" },
  { cnae: "2051700", descricao: "PARATION METILICO, FABRICACAO DE" },
  {
    cnae: "2033900",
    descricao:
      "BORRACHA SINTETICA E BORRACHA ARTIFICIAL; MISTURAS COM BORRACHA NATURAL, EM FORMAS PRIMARIAS OU EM CHAPAS, FOLHAS OU TIRAS (CLOROBUTADIENO, BUTILA, HALOGENADA, BUTADIENO(BR)); FABRICACAO DE",
  },
  {
    cnae: "2033900",
    descricao: "BORRACHA DE ISOBUTILENO - ISOPRENO; FABRICACAO DE",
  },
  { cnae: "2033900", descricao: "BORRACHAS URETANICAS; FABRICACAO DE" },
  {
    cnae: "2033900",
    descricao:
      "ELASTOMEROS (EXCETO ARTIGOS DE BORRACHA E RECICLAGEM DE BORRACHAS); FABRICACAO DE",
  },
  { cnae: "2033900", descricao: "BORRACHAS ACRILICAS; FABRICACAO DE" },
  {
    cnae: "2033900",
    descricao:
      "BORRACHA DE ISOBUTENO-ISOPRENO (HALOGENADA), EM CHAPAS, FOLHAS, ETC; FABRICACAO DE",
  },
  { cnae: "2033900", descricao: "ELASTOMEROS NAO VULCANIZADOS; FABRICACAO DE" },
  {
    cnae: "2033900",
    descricao:
      "MESCLAS DE BORRACHA SINTETICA E BORRACHA NATURAL OU GOMAS SIMILARES A BORRACHA; FABRICACAO DE",
  },
  { cnae: "2033900", descricao: "BORRACHA DE BUTADIENO; FABRICACAO DE" },
  { cnae: "2033900", descricao: "BORRACHAS DE POLIISOBUTILENO; FABRICACAO DE" },
  {
    cnae: "2033900",
    descricao: "BORRACHAS FLUORIDRICAS E FLUOROCARBONICAS; FABRICACAO DE",
  },
  { cnae: "2033900", descricao: "COPOLIMEROS DE PIRIDINA; FABRICACAO DE" },
  {
    cnae: "2033900",
    descricao: "BORRACHA ETILENO-PROPILENO-DIENO (EPDM); FABRICACAO DE",
  },
  { cnae: "2033900", descricao: "LATICES CARBOXILICOS; FABRICACAO DE" },
  { cnae: "2033900", descricao: "POLIISOPRENO; FABRICACAO DE" },
  { cnae: "2033900", descricao: "BORRACHAS DE CLOROPRENO; FABRICACAO DE" },
  {
    cnae: "2033900",
    descricao: "BORRACHA DE ISOBUTENO-ISOPRENO (BUTILA) (IIR); FABRICACAO DE",
  },
  { cnae: "2033900", descricao: "BORRACHAS CLORADAS; FABRICACAO DE" },
  { cnae: "20401", descricao: "FABRICACAO DE FIBRAS ARTIFICIAIS E SINTETICAS" },
  { cnae: "2040100", descricao: "FIBRAS VINILAL; FABRICACAO DE" },
  {
    cnae: "2040100",
    descricao:
      "FIOS, CABOS E FILAMENTOS SINTETICOS (EXCETO OS FIOS CARDADOS, PENTEADOS, DE FIBRAS E FILAMENTOS SINTETICOS); FABRICACAO DE",
  },
  { cnae: "2040100", descricao: "FIBRAS MODAL; FABRICACAO DE" },
  { cnae: "2040100", descricao: "FIBRAS DE CASEINA; FABRICACAO DE" },
  { cnae: "2040100", descricao: "FIBRAS DE POLIURETANO; FABRICACAO DE" },
  { cnae: "2040100", descricao: "FIOS SIMPLES DE POLIESTERES; FABRICACAO DE" },
  {
    cnae: "2040100",
    descricao: "FIBRAS DE ACETATO SAPONIFICADO; FABRICACAO DE",
  },
  {
    cnae: "2040100",
    descricao: "FIOS SINTETICOS SIMPLES DE OUTROS TIPOS; FABRICACAO DE",
  },
  {
    cnae: "2040100",
    descricao: "FIOS SIMPLES DE NAILON E DE OUTRAS POLIAMIDAS; FABRICACAO DE",
  },
  { cnae: "2040100", descricao: "FIBRAS DE CARBONO; FABRICACAO DE" },
  {
    cnae: "2040100",
    descricao: "FIBRAS METALICAS E METALIZADAS; FABRICACAO DE",
  },
  { cnae: "2040100", descricao: "FIBRAS DE RAYON; FABRICACAO DE" },
  {
    cnae: "2040100",
    descricao:
      "MONOFILAMENTOS, LAMINAS E FORMAS SEMELHANTES DE MATERIAIS TEXTEIS ARTIFICIAIS; FABRICACAO DE",
  },
  {
    cnae: "2040100",
    descricao:
      "FIBRAS, FIOS, CABOS E FILAMENTOS CONTINUOS SINTETICOS (EXCETO OS FIOS CARDADOS, PENTEADOS, DE FIBRAS E FILAMENTOS SINTETICOS); FABRICACAO DE",
  },
  {
    cnae: "2040100",
    descricao:
      "MONOFILAMENTOS, LAMINAS E FORMAS SEMELHANTES DE MATERIAIS TEXTEIS SINTETICAS; FABRICACAO DE",
  },
  { cnae: "2040100", descricao: "FIBRAS CUPRO; FABRICACAO DE" },
  {
    cnae: "2040100",
    descricao:
      "FIOS TEXTURIZADOS DE NAILON E DE OUTRAS POLIAMIDAS; FABRICACAO DE",
  },
  {
    cnae: "2040100",
    descricao:
      "FIBRAS ARTIFICIAIS DESCONTINUAS NAO CARDADAS NEM PENTEADAS; FABRICACAO DE",
  },
  { cnae: "2040100", descricao: "FIBRAS ARTIFICIAIS; FABRICACAO DE" },
  { cnae: "2040100", descricao: "FIBRAS DE ALGINATO; FABRICACAO DE" },
  {
    cnae: "2040100",
    descricao:
      "FIOS DE FILAMENTOS ARTIFICIAIS (SIMPLES E DE ALTA TENACIDADE), INCLUSIVE MONOFILAMENTOS ARTIFICIAIS; FABRICACAO DE",
  },
  {
    cnae: "2040100",
    descricao: "FIBRAS DE POLITETRAFLUORETILENO; FABRICACAO DE",
  },
  {
    cnae: "2040100",
    descricao: "FIBRAS DE CLORETO DE POLIVINILIDENO; FABRICACAO DE",
  },
  { cnae: "2040100", descricao: "FIBRAS DE POLIPROPILENO; FABRICACAO DE" },
  { cnae: "2040100", descricao: "FIBRAS DE ELASTANA; FABRICACAO DE" },
  { cnae: "2040100", descricao: "FIBRAS DE ACETATO; FABRICACAO DE" },
  {
    cnae: "2040100",
    descricao:
      "FIOS DE ALTA TENACIDADE DE NAILON OU DE OUTRAS POLIAMIDAS; FABRICACAO DE",
  },
  { cnae: "2040100", descricao: "FIBRAS DE ROCHA; FABRICACAO DE" },
  { cnae: "2040100", descricao: "FIBRAS VINAL; FABRICACAO DE" },
  {
    cnae: "2040100",
    descricao: "FIOS TEXTURIZADOS DE POLIESTERES; FABRICACAO DE",
  },
  { cnae: "2040100", descricao: "FIBRAS DE POLIETILENO; FABRICACAO DE" },
  { cnae: "2040100", descricao: "FIBRAS ACRILICAS; FABRICACAO DE" },
  { cnae: "2040100", descricao: "FIBRAS DE POLIESTIRENO; FABRICACAO DE" },
  {
    cnae: "2040100",
    descricao:
      "FIOS E FILAMENTOS DE VISCOSE (EXCETO OS FIOS CARDADOS, PENTEADOS, DE FIBRAS E FILAMENTOS DE VISCOSE); FABRICACAO DE",
  },
  {
    cnae: "2040100",
    descricao:
      "FIBRAS, FIOS, CABOS E FILAMENTOS CONTINUOS ARTIFICIAIS (EXCETO OS FIOS CARDADOS, PENTEADOS, DE FIBRAS E FILAMENTOS ARTIFICIAIS); FABRICACAO DE",
  },
  { cnae: "2040100", descricao: "FIBRAS DE POLIESTER; FABRICACAO DE" },
  { cnae: "2040100", descricao: "FIBRAS DE TRIACETATO; FABRICACAO DE" },
  {
    cnae: "2040100",
    descricao: "FIBRAS DE CLORETO DE POLIVINILA; FABRICACAO DE",
  },
  { cnae: "2040100", descricao: "FIBRAS DE ESCORIA; FABRICACAO DE" },
  {
    cnae: "2040100",
    descricao: "FIOS DE ALTA TENACIDADE DE POLIESTERES; FABRICACAO DE",
  },
  { cnae: "2040100", descricao: "FIBRAS DE POLIAMIDA (NYLON); FABRICACAO DE" },
  {
    cnae: "2040100",
    descricao:
      "DESPERDICIOS DE FIBRAS SINTETICAS OU ARTIFICIAIS; FABRICACAO DE",
  },
  {
    cnae: "2040100",
    descricao: "FIOS TEXTURIZADOS DE POLIPROPILENO; FABRICACAO DE",
  },
  {
    cnae: "2040100",
    descricao: "FIOS SINTETICOS TEXTURIZADOS DE OUTROS TIPOS; FABRICACAO DE",
  },
  {
    cnae: "2040100",
    descricao:
      "FIOS E FILAMENTOS DE RAYON (EXCETO OS FIOS CARDADOS, PENTEADOS, TEXTURIZADOS DE FIBRAS E FILAMENTOS DE RAYON (RAIOM)); FABRICACAO DE",
  },
  { cnae: "2040100", descricao: "FIBRAS DE ARAMIDA; FABRICACAO DE" },
  { cnae: "2040100", descricao: "FIBRAS DE VISCOSE; FABRICACAO DE" },
  { cnae: "2040100", descricao: "FIBRAS MULTIPOLIMEROS; FABRICACAO DE" },
  { cnae: "2040100", descricao: "FIBRAS DE POLICARBAMIDA; FABRICACAO DE" },
  { cnae: "2040100", descricao: "FIBRAS DE ELASTODIENO; FABRICACAO DE" },
  {
    cnae: "2040100",
    descricao:
      "FIBRAS SINTETICAS DESCONTINUADAS NAO CARDADAS NEM PENTEADAS; FABRICACAO DE",
  },
  {
    cnae: "2040100",
    descricao:
      "FIOS, CABOS E FILAMENTOS ARTIFICIAIS (EXCETO OS FIOS CARDADOS, PENTEADOS, DE FIBRAS E FILAMENTOS ARTIFICIAIS); FABRICACAO DE",
  },
  { cnae: "2040100", descricao: "FIBRAS MODACRILICAS; FABRICACAO DE" },
  {
    cnae: "2040100",
    descricao: "FIBRAS DE BORRACHA (POLIISOPROPENO NATURAL); FABRICACAO DE",
  },
  {
    cnae: "2040100",
    descricao: "FIOS TEXTURIZADOS DE FILAMENTOS ARTIFICIAIS; FABRICACAO DE",
  },
  {
    cnae: "2040100",
    descricao: "FIBRAS DESCONTINUAS ARTIFICIAIS; FABRICACAO DE",
  },
  {
    cnae: "2040100",
    descricao: "FIBRAS DE POLICLOROTRIFLUORETILENO; FABRICACAO DE",
  },
  {
    cnae: "2040100",
    descricao: "FIOS SIMPLES DE POLIPROPILENO; FABRICACAO DE",
  },
  { cnae: "20517", descricao: "FABRICACAO DE DEFENSIVOS AGRICOLAS" },
  { cnae: "2051700", descricao: "ENDOSULFAN, FABRICACAO DE" },
  {
    cnae: "2051700",
    descricao:
      "INSETICIDAS A BASE DE OLEO MINERAL PARA USO AGRICOLA; FABRICACAO DE",
  },
  { cnae: "2051700", descricao: "FENITROTHION, FABRICACAO DE" },
  { cnae: "2051700", descricao: "SIMAZINA, FABRICACAO DE" },
  { cnae: "2051700", descricao: "CIHEXATIN, FABRICACAO DE" },
  { cnae: "2051700", descricao: "DIQUAT, FABRICACAO DE" },
  {
    cnae: "2051700",
    descricao: "INIBIDORES DE GERMINACAO PARA USO AGRICOLA; FABRICACAO DE",
  },
  { cnae: "2051700", descricao: "METOLACLOR, FABRICACAO DE" },
  { cnae: "2051700", descricao: "AGROQUIMICOS, N.E.; FABRICACAO DE" },
  { cnae: "2051700", descricao: "DEFENSIVOS AGRICOLAS, N.E.; FABRICACAO DE" },
  { cnae: "2051700", descricao: "TRIADIMENOL, FABRICACAO DE" },
  { cnae: "2051700", descricao: "FORMICIDAS PARA USO AGRICOLA; FABRICACAO DE" },
  {
    cnae: "2051700",
    descricao: "INSETICIDAS PARA USO AGRICOLA; FABRICACAO DE",
  },
  { cnae: "2051700", descricao: "DELTAMETRINA, FABRICACAO DE" },
  { cnae: "2051700", descricao: "TRIFLURALINA, FABRICACAO DE" },
  { cnae: "2051700", descricao: "FOSFETO DE ALUMINIO, FABRICACAO DE" },
  { cnae: "2051700", descricao: "PARATION ETILICO, FABRICACAO DE" },
  { cnae: "2051700", descricao: "PESTICIDAS PARA USO AGRICOLA; FABRICACAO DE" },
  { cnae: "2051700", descricao: "CARBOFURAN (CARBOFURANO), FABRICACAO DE" },
  { cnae: "2051700", descricao: "ESTIMULANTE HORMONIO VEGETAL; FABRICACAO DE" },
  { cnae: "2051700", descricao: "MALATHION, FABRICACAO DE" },
  { cnae: "2051700", descricao: "CIPERMETRINA, FABRICACAO DE" },
  { cnae: "2051700", descricao: "THIRAM, FABRICACAO DE" },
  { cnae: "2051700", descricao: "PENDIMETALINA, FABRICACAO DE" },
  { cnae: "2051700", descricao: "TRICLORFON, FABRICACAO DE" },
  { cnae: "2051700", descricao: "PROPANIL, FABRICACAO DE" },
  {
    cnae: "2051700",
    descricao: "AMITRAZ USADO COMO INSETICIDA; FABRICACAO DE",
  },
  {
    cnae: "2051700",
    descricao:
      "TIOFANATO-METILA, TRIADIMENOL E OUTROS PRINCIPIOS ATIVOS PARA FUNGICIDA, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2051700",
    descricao: "FUNGICIDA BIOLOGICO PARA USO AGRICOLA; FABRICACAO DE",
  },
  {
    cnae: "2051700",
    descricao:
      "CIEXATIM; OXIDO DE FEMBUTATIN (OXIDO DE FENBUTATIN) (PRINCIPIO ATIVO PARA DEFENSIVOS AGRICOLAS, NAO ESPECIFICADOS); FABRICACAO DE",
  },
  { cnae: "2051700", descricao: "CLOROTALONIL, FABRICACAO DE" },
  { cnae: "2051700", descricao: "CARBOFURAN, FABRICACAO DE" },
  { cnae: "2051700", descricao: "METAMIDOFOS, FABRICACAO DE" },
  { cnae: "2051700", descricao: "BIOCIDAS DE EFEITO GERAL; FABRICACAO DE" },
  {
    cnae: "2051700",
    descricao:
      "5 - (2- CLORO -4- TRIFLUORMETILFENOX) - 2 - NITROBENZOATO DE 1' -(CARBOETOXI) ETILA (LACTOFEN) (PRINCIPIO ATIVO PARA HERBICIDAS); FABRICACAO DE",
  },
  { cnae: "2051700", descricao: "AMETRINA, FABRICACAO DE" },
  { cnae: "2051700", descricao: "FENVALERATE, FABRICACAO DE" },
  { cnae: "2051700", descricao: "FENITROTHION (FENITROTION), FABRICACAO DE" },
  { cnae: "2051700", descricao: "CAPTAN, FABRICACAO DE" },
  { cnae: "2051700", descricao: "PERMETRINA, FABRICACAO DE" },
  {
    cnae: "2051700",
    descricao:
      "MANEB, MANCOZEB (PRINCIPIO ATIVO PARA FUNGICIDAS); FABRICACAO DE",
  },
  { cnae: "2051700", descricao: "PROPARGITE, FABRICACAO DE" },
  { cnae: "2051700", descricao: "CLORPIRIFOS, FABRICACAO DE" },
  { cnae: "2051700", descricao: "BENTAZON, FABRICACAO DE" },
  {
    cnae: "2051700",
    descricao: "PRODUTOS INIBIDORES DE GERMINACAO; FABRICACAO DE",
  },
  { cnae: "2051700", descricao: "OXIDO CUPROSO, FABRICACAO DE" },
  { cnae: "2051700", descricao: "HERBICIDAS PARA USO AGRICOLA; FABRICACAO DE" },
  {
    cnae: "2051700",
    descricao:
      "GLIFOSATO E SEU SAL DE MONOISOPROPILAMINA (PRINCIPIO ATIVO PARA HERBICIDAS); FABRICACAO DE",
  },
  {
    cnae: "2051700",
    descricao:
      "IMAZETAPIR, PICLORAM; DICLORETO DE PARAQUAT (PRINCIPIOS ATIVOS PARA HERBICIDAS); FABRICACAO DE",
  },
  { cnae: "2051700", descricao: "TRICICLAZOL, FABRICACAO DE" },
  { cnae: "2051700", descricao: "FUNGICIDAS PARA USO AGRICOLA; FABRICACAO DE" },
  { cnae: "2051700", descricao: "OXICLORETO DE COBRE, FABRICACAO DE" },
  { cnae: "2051700", descricao: "ETHION, FABRICACAO DE" },
  {
    cnae: "2051700",
    descricao:
      "METAM SODIO (PRINCIPIO ATIVO PARA DEFENSIVOS AGRICOLAS, NAO ESPECIFICADOS); FABRICACAO DE",
  },
  { cnae: "2051700", descricao: "ACEFATO, FABRICACAO DE" },
  {
    cnae: "2051700",
    descricao: "MONOCROTOFOS (PRINCIPIO ATIVO PARA INSETICIDAS); FABRICACAO DE",
  },
  { cnae: "2051700", descricao: "TEBUTIURON, FABRICACAO DE" },
  { cnae: "2051700", descricao: "IMAZAQUIN, FABRICACAO DE" },
  { cnae: "2051700", descricao: "FENTION, FABRICACAO DE" },
  {
    cnae: "2051700",
    descricao: "FUNGICIDAS A BASE DE MANEB OU MANCOZEB; FABRICACAO DE",
  },
  {
    cnae: "2051700",
    descricao:
      "PROFENOFOS (FOSFORODITIOATO DE O-(4-BROMO-2-CLOROFENILA)-O-ETILA-S-PROPILA (PRINCIPIO ATIVO PARA INSETICIDAS); FABRICACAO DE",
  },
  {
    cnae: "2051700",
    descricao:
      "REGULADORES DE CRESCIMENTO DE PLANTAS, PARA USO AGRICOLA; FABRICACAO DE",
  },
  {
    cnae: "2051700",
    descricao:
      "RATICIDAS, RODENTICIDAS E OUTROS DEFENSIVOS PARA USO NA AGRICULTURA; FABRICACAO DE",
  },
  {
    cnae: "2051700",
    descricao:
      "METAMIDOFOS; DISSULFOTOM; TIOMETON; ACEFATO (PRINCIPIOS ATIVOS PARA INSETICIDAS); FABRICACAO DE",
  },
  {
    cnae: "2051700",
    descricao: "INSETICIDA BIOLOGICO PARA USO AGRICOLA; FABRICACAO DE",
  },
  { cnae: "2051700", descricao: "ENXOFRE MOLHAVEL (ACARICIDA); FABRICACAO DE" },
  {
    cnae: "2051700",
    descricao:
      "ALACLOR; METOLACLOR; PROPANIL (PRINCIPIOS ATIVOS PARA HERBICIDAS); FABRICACAO DE",
  },
  {
    cnae: "2051700",
    descricao:
      "ACIDO 2,4 - DICLOROFENOXIACETICO, SEUS SAIS E ESTERES (PRINCIPIO ATIVO PARA HERBICIDAS); FABRICACAO DE",
  },
  { cnae: "2051700", descricao: "PARAQUAT, FABRICACAO DE" },
  { cnae: "2051700", descricao: "ABAMECTINA, FABRICACAO DE" },
  { cnae: "20525", descricao: "FABRICACAO DE DESINFESTANTES DOMISSANITARIOS" },
  {
    cnae: "2052500",
    descricao:
      "RATICIDAS, RODENCIDAS E OUTROS PRODUTOS SEMELHANTES PARA USOS DOMESTICO, INSTITUCIONAL E/OU INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2052500",
    descricao:
      "CUPINICIDAS PARA USO DOMESTICO, COMERCIAL E/OU INDUSTRIAL, FABRICACAO DE",
  },
  {
    cnae: "2052500",
    descricao: "INSETICIDAS PARA USO DOMISSANITARIO DIRETO; FABRICACAO DE",
  },
  {
    cnae: "2052500",
    descricao: "DESINFESTANTES DOMISSANITARIOS, FABRICACAO DE",
  },
  {
    cnae: "2052500",
    descricao:
      "FUNGICIDAS PARA USO DOMESTICO, INSTITUCIONAL E/OU INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2052500",
    descricao:
      "REGULADORES DE CRESCIMENTO DE PLANTAS, PARA USO DOMISSANITARIO DIRETO; FABRICACAO DE",
  },
  {
    cnae: "2052500",
    descricao: "PRODUTOS FITOSSANITARIOS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2052500",
    descricao: "FUNGICIDA BIOLOGICO PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2052500",
    descricao:
      "FORMICIDAS, PESTICIDAS E SEMELHANTES PARA USO DOMESTICO, COMERCIAL E/OU INDUSTRIAL, FABRICACAO DE",
  },
  { cnae: "2063100", descricao: "LOCAO HIDRATANTE; FABRICACAO DE" },
  { cnae: "85937", descricao: "ENSINO DE IDIOMAS" },
  {
    cnae: "2052500",
    descricao:
      "HERBICIDAS, INIBIDORES DE GERMINACAO E REGULADORES DE CRESCIMENTO PARA PLANTAS PARA USO DOMESTICO, INSTITUCIONAL E/OU INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2052500",
    descricao:
      "DESINFESTANTES PARA USO DOMESTICO, COMERCIAL E/OU INDUSTRIAL, FABRICACAO DE",
  },
  {
    cnae: "2052500",
    descricao:
      "INSETICIDAS PARA USO DOMESTICO, INSTITUCIONAL E/OU INDUSTRIAL; FABRICACAO DE",
  },
  { cnae: "2052500", descricao: "ESPIRAIS MATA-MOSQUITO; FABRICACAO DE" },
  { cnae: "2052500", descricao: "REPELENTES; PRODUCAO DE" },
  {
    cnae: "2052500",
    descricao: "INSETICIDA BIOLOGICO PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2052500",
    descricao:
      "HERBICIDAS PARA USO DOMESTICO, INSTITUCIONAL E/OU INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2052500",
    descricao:
      "RODENTICIDAS E OUTROS PRODUTOS SEMELHANTES, PARA USO DOMISSANITARIO DIRETO; FABRICACAO DE",
  },
  {
    cnae: "2052500",
    descricao:
      "INIBIDORES DE GERMINACAO PARA USO DOMISSANITARIO DIRETO; FABRICACAO DE",
  },
  { cnae: "20614", descricao: "FABRICACAO DE SABOES E DETERGENTES SINTETICOS" },
  {
    cnae: "2061400",
    descricao: "SABOES NA FORMA: PO, LIQUIDA, ESCAMAS E BARRA; FABRICACAO DE",
  },
  { cnae: "2061400", descricao: "GLICEROL EM BRUTO; FABRICACAO DE" },
  { cnae: "2061400", descricao: "GLICERINA; FABRICACAO DE" },
  { cnae: "2061400", descricao: "AGUA E LIXIVIA GLICERICAS; FABRICACAO DE" },
  {
    cnae: "2061400",
    descricao:
      "SABOES PARA USO INDUSTRIAL, EM BARRAS, PEDACOS, ETC; FABRICACAO DE",
  },
  {
    cnae: "2061400",
    descricao: "PRODUTOS PARA LAVAGEM DE PISOS, VIDROS, ETC; FABRICACAO DE",
  },
  {
    cnae: "2061400",
    descricao: "DETERGENTES PARA USO INSTITUCIONAL E DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2061400",
    descricao: "AMACIANTES OU SUAVIZANTES DE TECIDOS; FABRICACAO DE",
  },
  {
    cnae: "2061400",
    descricao: "AGENTES ORGANICOS DE SUPERFICIE; FABRICACAO DE",
  },
  {
    cnae: "2061400",
    descricao: "PREPARACOES TENSOATIVAS PARA LAVAGEM E LIMPEZA; FABRICACAO DE",
  },
  { cnae: "20622", descricao: "FABRICACAO DE PRODUTOS DE LIMPEZA E POLIMENTO" },
  { cnae: "2062200", descricao: "CERAS ARTIFICIAIS OU MISTAS; FABRICACAO DE" },
  { cnae: "2062200", descricao: "PREPARACOES SANITARIAS; FABRICACAO DE" },
  { cnae: "2062200", descricao: "DESINFETANTES; FABRICACAO DE" },
  {
    cnae: "2062200",
    descricao:
      "PREPARACOES PARA DAR BRILHO A PINTURAS DE CARROCERIAS E PRODUTOS SEMELHANTES, EXCETO PREPARACOES PARA DAR BRILHO A METAIS; FABRICACAO DE",
  },
  {
    cnae: "2062200",
    descricao:
      "PREPARACOES PARA CONSERVACAO E LIMPEZA DE MOVEIS, SOALHOS E OUTROS ARTIGOS DE MADEIRA; FABRICACAO DE",
  },
  {
    cnae: "2062200",
    descricao: "OLEOS OU PASTAS PARA POLIR METAIS; FABRICACAO DE",
  },
  { cnae: "2062200", descricao: "ALVEJANTES; FABRICACAO DE" },
  {
    cnae: "2062200",
    descricao: "CLORO PARA LIMPEZA E DESINFECCAO DOMESTICA; FABRICACAO DE",
  },
  { cnae: "2062200", descricao: "PASTA PARA POLIR SAPATOS; FABRICACAO DE" },
  {
    cnae: "2062200",
    descricao:
      "CERAS PREPARADAS, NAO ESPECIFICADOS, EXCETO ARTIFICIAIS OU SINTETICAS; FABRICACAO DE",
  },
  { cnae: "2062200", descricao: "AROMATIZANTES DE AMBIENTE; FABRICACAO DE" },
  { cnae: "2062200", descricao: "SAPOLEO LIQUIDO OU EM PO; FABRICACAO DE" },
  { cnae: "2062200", descricao: "CLORO PARA PISCINA; FABRICACAO DE" },
  { cnae: "2062200", descricao: "CERAS SINTETICAS; FABRICACAO DE" },
  {
    cnae: "2062200",
    descricao: "PREPARADOS PARA PERFUMAR E DESODORIZAR LOCAIS; FABRICACAO DE",
  },
  { cnae: "2062200", descricao: "AGUA SANITARIA; FABRICACAO DE" },
  {
    cnae: "2062200",
    descricao: "POLIDORES DE MOVEIS, AUTOMOVEIS, METAIS; FABRICACAO DE",
  },
  {
    cnae: "2062200",
    descricao: "CRESOIS PARA USO DESINFETANTE; FABRICACAO DE",
  },
  {
    cnae: "2062200",
    descricao: "OLEOS OU PASTAS PARA POLIR MOVEIS; FABRICACAO DE",
  },
  {
    cnae: "2062200",
    descricao: "PASTAS, POS E OUTRAS PREPARACOES PARA AREAR; FABRICACAO DE",
  },
  {
    cnae: "2062200",
    descricao: "PREPARACOES PARA LIMPEZA A SECO; FABRICACAO DE",
  },
  {
    cnae: "2062200",
    descricao: "PRODUTOS DE LIMPEZA E POLIMENTO; FABRICACAO DE",
  },
  { cnae: "2062200", descricao: "BRANQUEADORES; FABRICACAO DE" },
  {
    cnae: "2062200",
    descricao: "CLORO LIQUIDO PARA LIMPEZA DOMESTICA; FABRICACAO DE",
  },
  {
    cnae: "2062200",
    descricao:
      "PREPARACOES PARA DAR BRILHO EM VIDROS, METAIS E OUTROS MATERIAIS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2062200",
    descricao:
      "POMADAS, CREMES E PREPARADOS SEMELHANTES PARA CALCADOS OU COUROS; FABRICACAO DE",
  },
  { cnae: "2062200", descricao: "SAPONACEOS; FABRICACAO DE" },
  { cnae: "2062200", descricao: "COMPOSTOS BRANQUEADORES; FABRICACAO DE" },
  {
    cnae: "2062200",
    descricao:
      "CERAS  PARA ASSOALHOS ARTIFICIAIS OU MISTAS, LIQUIDAS OU EM PASTA; FABRICACAO DE",
  },
  {
    cnae: "20631",
    descricao:
      "FABRICACAO DE COSMETICOS, PRODUTOS DE PERFUMARIA E DE HIGIENE PESSOAL",
  },
  {
    cnae: "2063100",
    descricao:
      "CREMES, TONIFICANTES, MASCARAS OU OUTRAS PREPARACOES PARA CABELOS (CAPILARES); FABRICACAO DE",
  },
  { cnae: "2063100", descricao: "DESCORANTES PARA CABELO; FABRICACAO DE" },
  { cnae: "2063100", descricao: "SAIS DE BANHO; FABRICACAO DE" },
  { cnae: "2063100", descricao: "TINTURAS PARA CABELOS; FABRICACAO DE" },
  {
    cnae: "2063100",
    descricao:
      "CREMES, TONICOS, LOCOES OU OUTRAS PREPARACOES PARA BARBEAR; FABRICACAO DE",
  },
  {
    cnae: "2063100",
    descricao:
      "POS, INCLUINDO OS COMPACTOS, PARA MAQUIAGEM; TALCOS E POLVILHOS; FABRICACAO DE",
  },
  { cnae: "2063100", descricao: "SHAMPOOS (OU XAMPUS); FABRICACAO DE" },
  { cnae: "2063100", descricao: "TALCO (PERFUMARIA); FABRICACAO DE" },
  { cnae: "2063100", descricao: "PRODUTOS PARA MAQUILAGEM; FABRICACAO DE" },
  { cnae: "2063100", descricao: "PERFUMES (EXTRATOS); FABRICACAO DE" },
  {
    cnae: "2063100",
    descricao: "SOMBRA, DELINEADOR, LAPIS PARA SOBRANCELHA; FABRICACAO DE",
  },
  {
    cnae: "2063100",
    descricao: "PROTETORES SOLARES, FILTROS SOLARES; FABRICACAO DE",
  },
  { cnae: "2063100", descricao: "AGUAS DE COLONIA; FABRICACAO DE" },
  { cnae: "2063100", descricao: "PO FACIAL; FABRICACAO DE" },
  {
    cnae: "2063100",
    descricao: "PREPARACOES CAPILARES, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2063100",
    descricao:
      "PRODUTOS DE HIGIENE E PERFUMARIA PARA USO VETERINARIO; FABRICACAO DE",
  },
  { cnae: "2063100", descricao: "CONDICIONADORES PARA CABELOS; FABRICACAO DE" },
  { cnae: "2063100", descricao: "DESODORANTES; FABRICACAO DE" },
  {
    cnae: "2063100",
    descricao: "LAPIS PARA O CONTORNO DOS LABIOS; FABRICACAO DE",
  },
  {
    cnae: "2063100",
    descricao: "BRONZEADORES, ACELERADORES DE BRONZEAMENTO; FABRICACAO DE",
  },
  { cnae: "2063100", descricao: "ALISADORES DE CABELO; FABRICACAO DE" },
  {
    cnae: "2063100",
    descricao: "ARTIGOS DE PERFUMARIA E COSMETICOS; FABRICACAO DE",
  },
  {
    cnae: "2063100",
    descricao: "PREPARACOES OU CREMES PARA BARBEAR; FABRICACAO DE",
  },
  { cnae: "2063100", descricao: "DENTRIFICIOS; FABRICACAO DE" },
  { cnae: "2063100", descricao: "BASE PARA O ROSTO; FABRICACAO DE" },
  {
    cnae: "2063100",
    descricao: "SABOES MEDICINAIS, EM BARRAS, PEDACOS, ETC; FABRICACAO DE",
  },
  { cnae: "2063100", descricao: "OLEOS PARA BANHO; FABRICACAO DE" },
  {
    cnae: "2063100",
    descricao: "CREMES NUTRITIVOS PARA O ROSTO; FABRICACAO DE",
  },
  {
    cnae: "2063100",
    descricao: "SABONETES NA FORMA LIQUIDA E BARRA; FABRICACAO DE",
  },
  { cnae: "2063100", descricao: "FIXADORES PARA CABELO; FABRICACAO DE" },
  { cnae: "2063100", descricao: "CREMES PARA O CORPO; FABRICACAO DE" },
  { cnae: "2063100", descricao: "BASE PARA AS UNHAS; FABRICACAO DE" },
  {
    cnae: "2063100",
    descricao:
      "PREPARACOES PARA ONDULACAO OU ALISAMENTO, PERMANENTES, DOS CABELOS; FABRICACAO DE",
  },
  { cnae: "2063100", descricao: "LOCAO APOS BARBA; FABRICACAO DE" },
  { cnae: "2063100", descricao: "PO COMPACTO; FABRICACAO DE" },
  { cnae: "2063100", descricao: "FIOS OU FITAS DENTAIS; FABRICACAO DE" },
  {
    cnae: "2063100",
    descricao: "PREPARADOS PARA MANICURE OU PEDICURE; FABRICACAO DE",
  },
  { cnae: "2063100", descricao: "BRILHANTINAS PARA CABELO; FABRICACAO DE" },
  { cnae: "2063100", descricao: "PASTAS DE DENTE; FABRICACAO DE" },
  {
    cnae: "2063100",
    descricao: "PREPARACOES PARA HIGIENE BUCAL OU DENTARIA; FABRICACAO DE",
  },
  { cnae: "2063100", descricao: "SOMBRAS PARA OS OLHOS; FABRICACAO DE" },
  {
    cnae: "2063100",
    descricao: "KAJAL E OUTROS LAPIS PARA OS OLHOS; FABRICACAO DE",
  },
  { cnae: "2063100", descricao: "ROUGE; FABRICACAO DE" },
  { cnae: "2063100", descricao: "AGUA-DE-COLONIA; FABRICACAO DE" },
  { cnae: "2063100", descricao: "BLUSH; FABRICACAO DE" },
  { cnae: "2063100", descricao: "REMOVEDOR DE ESMALTES; FABRICACAO DE" },
  { cnae: "2063100", descricao: "COLONIAS; FABRICACAO DE" },
  {
    cnae: "2063100",
    descricao: "PRODUTOS DE BELEZA E HIGIENE PESSOAL; FABRICACAO DE",
  },
  {
    cnae: "2063100",
    descricao:
      "LENCOS UMEDECIDOS COM PREPARACOES,  PARA HIGIENE; FABRICACAO DE",
  },
  { cnae: "2063100", descricao: "COSMETICOS; FABRICACAO DE" },
  {
    cnae: "2063100",
    descricao:
      "LENCOS DE PAPEL ESPECIAL, FALSOS TECIDOS OU MATERIAIS SEMELHANTES, UMEDECIDOS COM PREPARACOES,  PARA HIGIENE; FABRICACAO DE",
  },
  { cnae: "2063100", descricao: "DEPILATORIOS; FABRICACAO DE" },
  { cnae: "2063100", descricao: "CREMES E LOCOES; FABRICACAO DE" },
  { cnae: "2063100", descricao: "CREMES HIDRATANTES; FABRICACAO DE" },
  { cnae: "2063100", descricao: "CREMES DE MASSAGEM; FABRICACAO DE" },
  {
    cnae: "2063100",
    descricao: "PRODUTOS DE HIGIENE E PERFUMARIA PARA ANIMAIS; FABRICACAO DE",
  },
  { cnae: "2063100", descricao: "RIMEL; FABRICACAO DE" },
  { cnae: "2063100", descricao: "ESMALTES; FABRICACAO DE" },
  { cnae: "2063100", descricao: "BANHO DE ESPUMA; FABRICACAO DE" },
  {
    cnae: "2063100",
    descricao: "PREPARACOES PARA MANICUROS E PEDICUROS; FABRICACAO DE",
  },
  {
    cnae: "20711",
    descricao: "FABRICACAO DE TINTAS, VERNIZES, ESMALTES E LACAS",
  },
  {
    cnae: "2071100",
    descricao:
      "LACAS, ESMALTES, VERNIZES PARA REPINTURA AUTOMOTIVA; FABRICACAO DE",
  },
  {
    cnae: "2071100",
    descricao:
      "TINTAS E VERNIZES DISSOLVIDOS EM MEIO AQUOSO PARA QUALQUER USO; FABRICACAO DE",
  },
  {
    cnae: "2071100",
    descricao:
      "ESMALTES METALICOS LIQUIDOS, FRITAS DE VIDRO E OUTROS VIDROS EM PO, EM GRANULOS, EM LAMELAS OU EM FLOCOS; FABRICACAO DE",
  },
  {
    cnae: "2071100",
    descricao:
      "TINTAS, VERNIZES, ESMALTES, LACAS DE SECAGEM A ESTUFA; FABRICACAO DE",
  },
  { cnae: "2071100", descricao: "CORES PREPARADAS; FABRICACAO DE" },
  {
    cnae: "2071100",
    descricao:
      "FRITAS METALICAS (ESMALTES OU COBERTURAS VITRIFICAVEIS); FABRICACAO DE",
  },
  { cnae: "2071100", descricao: "TINTAS E PINTURAS ESPECIAIS; FABRICACAO DE" },
  { cnae: "2071100", descricao: "ESMALTES, LACAS E VERNIZES; FABRICACAO DE" },
  {
    cnae: "2071100",
    descricao: "TINTAS E VERNIZES PARA QUALQUER USO; FABRICACAO DE",
  },
  { cnae: "2071100", descricao: "TINTAS EM PO; FABRICACAO DE" },
  {
    cnae: "2071100",
    descricao:
      "TINTAS, VERNIZES, ESMALTES E LACAS DE SECAGEM AO AR; FABRICACAO DE",
  },
  {
    cnae: "2071100",
    descricao:
      "PIGMENTOS (INCLUIDOS POS E FLOCOS METALICOS); FOLHAS PARA MARCAR A FERRO; TINTURAS E MATERIAS CORANTES, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  { cnae: "2071100", descricao: "TEXTURAS E GRAFIATOS; FABRICACAO DE" },
  {
    cnae: "2071100",
    descricao:
      "TINTAS E VERNIZES DISSOLVIDOS EM MEIO NAO AQUOSO PARA QUALQUER USO; FABRICACAO DE",
  },
  {
    cnae: "2071100",
    descricao:
      "COMPOSICOES VITRIFICAVEIS E PREPARACOES SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "2071100", descricao: "TINTAS AUTOMOTIVAS; FABRICACAO DE" },
  { cnae: "2071100", descricao: "TINTAS A BASE DE PLASTICO; FABRICACAO DE" },
  {
    cnae: "2071100",
    descricao: "PIGMENTOS A AGUA PARA ACABAMENTO DE COUROS; FABRICACAO DE",
  },
  { cnae: "2071100", descricao: "TINTAS A BASE DE AGUA; FABRICACAO DE" },
  { cnae: "2071100", descricao: "TINTAS A BASE DE OLEO; FABRICACAO DE" },
  {
    cnae: "2071100",
    descricao: "TINTAS PARA SINALIZACAO VIARIA; FABRICACAO DE",
  },
  {
    cnae: "2071100",
    descricao:
      "TINTAS, VERNIZES E COMPLEMENTOS A BASE DE EMULSOES; FABRICACAO DE",
  },
  {
    cnae: "2071100",
    descricao:
      "OPACIFICANTES E CORES PREPARADAS PARA AS INDUSTRIAIS DE CERAMICA, DA ESMALTAGEM OU VIDREIRA; FABRICACAO DE",
  },
  { cnae: "20720", descricao: "FABRICACAO DE TINTAS DE IMPRESSAO" },
  { cnae: "2072000", descricao: "TINTAS PARA OFSETE (OFF-SET); FABRICACAO DE" },
  {
    cnae: "2072000",
    descricao:
      "TINTAS GRAFICAS (EXCETO PARA ESCREVER E DESENHAR); FABRICACAO DE",
  },
  { cnae: "2072000", descricao: "TINTAS DE IMPRESSAO; FABRICACAO DE" },
  { cnae: "2072000", descricao: "TINTAS LITOGRAFICAS; FABRICACAO DE" },
  {
    cnae: "20738",
    descricao: "FABRICACAO DE IMPERMEABILIZANTES, SOLVENTES E PRODUTOS AFINS",
  },
  {
    cnae: "2073800",
    descricao:
      "MASTIQUES DE VIDRACEIRO (MASSA), CERAS DE CALAFATE, INDUTOS UTILIZADOS EM PINTURA E SEMELHANTE; FABRICACAO DE",
  },
  { cnae: "2073800", descricao: "MASSA DE VEDACAO; FABRICACAO DE" },
  {
    cnae: "2073800",
    descricao: "IMPERMEBIALIZANTES, SOLVENTES E PRODUTOS AFINS; FABRICACAO DE",
  },
  { cnae: "2073800", descricao: "MASSA DE CALAFATE; FABRICACAO DE" },
  {
    cnae: "2073800",
    descricao: "REMOVEDORES DE TINTAS E GRAXAS; FABRICACAO DE",
  },
  {
    cnae: "2073800",
    descricao:
      "SOLVENTES, DILUENTES E OUTROS PRODUTOS PARA REMOVER TINTAS; FABRICACAO DE",
  },
  {
    cnae: "2073800",
    descricao: "DILUENTES (PARA PINTURA OU TINTAS); FABRICACAO DE",
  },
  {
    cnae: "2073800",
    descricao:
      "MASSA PARA PINTURA E REPINTURA DE MOVEIS, AUTOMOVEIS E IMOVEIS; FABRICACAO DE",
  },
  {
    cnae: "2073800",
    descricao: "SOLVENTES (PARA PINTURA OU TINTAS); FABRICACAO DE",
  },
  {
    cnae: "2073800",
    descricao: "SECANTES (PARA PINTURA OU TINTAS); FABRICACAO DE",
  },
  { cnae: "2073800", descricao: "MASSA PARA VIDROS; FABRICACAO DE" },
  { cnae: "2073800", descricao: "MASSA PLASTICA; FABRICACAO DE" },
  { cnae: "2073800", descricao: "TINERES PARA TINTAS; FABRICACAO DE" },
  { cnae: "20916", descricao: "FABRICACAO DE ADESIVOS E SELANTES" },
  { cnae: "2091600", descricao: "ADESIVOS DE DEXTRINA; FABRICACAO DE" },
  { cnae: "2091600", descricao: "ADESIVOS DE AMIDOS; FABRICACAO DE" },
  { cnae: "2091600", descricao: "ADESIVOS A BASE DE PLASTICOS; FABRICACAO DE" },
  { cnae: "2091600", descricao: "COLA ANIMAL; FABRICACAO DE" },
  { cnae: "2091600", descricao: "COLAS MINERAIS; FABRICACAO DE" },
  { cnae: "2091600", descricao: "COLA VEGETAL; FABRICACAO DE" },
  {
    cnae: "2091600",
    descricao: "CASEINATOS E OUTROS DERIVADOS DA CASEINA, FABRICACAO DE",
  },
  { cnae: "2091600", descricao: "ADESIVOS DE GLUTEN; FABRICACAO DE" },
  {
    cnae: "2091600",
    descricao: "COLAS E OUTROS ADESIVOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2091600",
    descricao:
      "ADESIVOS E SELANTES PARA USO INDUSTRIAL E DOMESTICO; FABRICACAO DE",
  },
  { cnae: "2091600", descricao: "COLAS DE CASEINA E ALBUMINA; FABRICACAO DE" },
  { cnae: "2091600", descricao: "COMPOSTOS SELANTES; FABRICACAO DE" },
  {
    cnae: "2091600",
    descricao: "ADESIVOS DE ORIGEM ANIMAL E VEGETAL; FABRICACAO DE",
  },
  {
    cnae: "2091600",
    descricao: "COLAS E ADESIVOS A BASE DE CIANOCRILATOS; FABRICACAO DE",
  },
  { cnae: "2091600", descricao: "GOMAS VEGETAIS PREPARADAS; FABRICACAO DE" },
  { cnae: "2091600", descricao: "ADESIVOS DE BORRACHA; FABRICACAO DE" },
  {
    cnae: "2091600",
    descricao: "COLAS A BASE DE RESINAS SINTETICAS OU DE LATEX; FABRICACAO DE",
  },
  { cnae: "20924", descricao: "FABRICACAO DE EXPLOSIVOS" },
  { cnae: "2092401", descricao: "EXPLOSIVOS; FABRICACAO DE" },
  {
    cnae: "2092401",
    descricao: "EXPLOSIVOS A BASE DE CLORATOS E PERCLORATOS; FABRICACAO DE",
  },
  {
    cnae: "2092401",
    descricao: "EXPLOSIVOS A BASE DE CELULOSE; FABRICACAO DE",
  },
  {
    cnae: "2092401",
    descricao: "ESPOLETAS, CARTUCHOS E DETONADORES; FABRICACAO DE",
  },
  { cnae: "2092401", descricao: "NITRATO DE POTASSIO, FABRICACAO DE" },
  { cnae: "2092401", descricao: "POLVORAS PROPULSIVAS; FABRICACAO DE" },
  { cnae: "2092401", descricao: "ALGODAO COLODIO, FABRICACAO DE" },
  { cnae: "2092401", descricao: "POLVORAS; FABRICACAO DE" },
  {
    cnae: "2092401",
    descricao:
      "EXPLOSIVOS PREPARADOS, EXCETO POLVORAS PROPULSIVAS; FABRICACAO DE",
  },
  { cnae: "2092401", descricao: "NITROGLICERINA; FABRICACAO DE" },
  {
    cnae: "2092401",
    descricao: "EXPLOSIVOS A BASE DE NITRATO DE AMONIA; FABRICACAO DE",
  },
  { cnae: "2092401", descricao: "AZIDAS, FABRICACAO DE" },
  { cnae: "2092401", descricao: "AGENTES DETONANTES; FABRICACAO DE" },
  {
    cnae: "2092401",
    descricao: "EXPLOSIVOS A BASE DE TRINITROTOLUOL (TNT); FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "ISONIAZIDA; FABRICACAO DE" },
  {
    cnae: "2092401",
    descricao:
      "CORDEIS DETONANTES, FULMINANTES, CAPSULAS FULMINANTES; FABRICACAO DE",
  },
  {
    cnae: "2092401",
    descricao: "ACIDO PICRICO (TRINITROFENOL), FABRICACAO DE",
  },
  { cnae: "2092401", descricao: "CARBOIDRATOS NITRATOS; FABRICACAO DE" },
  {
    cnae: "2092401",
    descricao: "EXPLOSIVOS A BASE DE NITROGLICERINA (DINAMITES); FABRICACAO DE",
  },
  {
    cnae: "2092401",
    descricao: "2,4,6 - TRINITROTOLUENO (TNT); FABRICACAO DE",
  },
  { cnae: "2092401", descricao: "AMATOL, FABRICACAO DE" },
  {
    cnae: "2092401",
    descricao: "ESTOPINS, MECHAS E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2092402",
    descricao:
      "ARTIGOS PIROTECNICOS E OUTROS ARTIGOS DE MATERIAIS INFLAMAVEIS; FABRICACAO DE",
  },
  { cnae: "2092402", descricao: "FOGOS DE ARTIFICIO; FABRICACAO DE" },
  { cnae: "2092403", descricao: "FOSFOROS DE SEGURANCA; FABRICACAO DE" },
  { cnae: "20932", descricao: "FABRICACAO DE ADITIVOS DE USO INDUSTRIAL" },
  {
    cnae: "2093200",
    descricao:
      "SAIS E HIDROXIDOS DE AMONIO QUATERNARIOS; LECITINAS E OUTROS FOSFOAMINOLIPIDEOS; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "CISTEINA; FABRICACAO DE" },
  { cnae: "2093200", descricao: "METIONINA; FABRICACAO DE" },
  { cnae: "2093200", descricao: "OLEO DE SASSAFRAS; PRODUCAO DE" },
  {
    cnae: "2093200",
    descricao: "OLEO ESSENCIAL DE PETIT GRAIN; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE CITRONELA; FABRICACAO DE" },
  { cnae: "2093200", descricao: "SORBATO DE POTASSIO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "GLUCONATO DE CALCIO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE CANELA; FABRICACAO DE" },
  { cnae: "2093200", descricao: "LISINA, SEUS SAIS E ESTERES; FABRICACAO DE" },
  { cnae: "2093200", descricao: "ACETATO DE BENZILA; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "LACTATO DE CALCIO USADO COMO ADITIVO; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao: "ADITIVOS PARA A INDUSTRIA ALIMENTAR; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "ACIDO LACTICO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "FLAVORIZANTES PARA A INDUSTRIA ALIMENTICIA; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE SAFROL; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao:
      "ANTIESPUMANTES CONTENDO FOSFATO DE TRIBUTILA EM SOLUCAO DE ALCOOL ISOPROPILICO; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "CICLAMATO DE CALCIO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "GLUCONATO DE FERRO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "CICLAMATO DE SODIO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "PREPARACOES QUIMICAS AUXILIARES PARA A BORRACHA; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "MISTURAS DE OLEOS ESSENCIAIS; FABRICACAO DE" },
  { cnae: "2093200", descricao: "GORDURAS ESSENCIAIS; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "ADITIVOS PARA A INDUSTRIA DE PAPEL E CELULOSE; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao: "ESTABILIZANTES PARA PRODUTOS SINTETICOS; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao:
      "MISTURAS DE SUBSTANCIAS ODORIFERAS PARA USOS INDUSTRIAIS; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "CONSERVANTES ALIMENTICIOS; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "PREPARACOES QUIMICAS PARA A INDUSTRIA DO COURO; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao: "ESSENCIAS E CONCENTRADOS AROMATICOS ARTIFICIAIS; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "ADITIVOS PARA LUBRIFICANTES; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "ESTABILIZANTES PARA A INDUSTRIA ALIMENTAR; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "GUAIACOL; FABRICACAO DE" },
  { cnae: "2093200", descricao: "AGUA DESTILADA" },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE CABREUVA; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "TRIFOSFATO DE SODIO, GRAU ALIMENTICIO; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao: "FOSFITO DE TRIS-(2,4-DI-TER-BUTILFENILA); FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "EUCALIPTOL; FABRICACAO DE" },
  { cnae: "2093200", descricao: "NITRITO DE SODIO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "SALICILATO DE METILA USADO COMO ADITIVO; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao: "ANTIOXIDANTES PARA A INDUSTRIA ALIMENTICIA; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE SASSAFRAS; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao:
      "PREPARACOES QUIMICAS PARA A INDUSTRIA DA CERAMICA; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "ACELERADORES DE VULCANIZACAO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao:
      "ANTIOXIDANTES E ESTABILIZADORES COMPOSTOS PARA BORRACHA OU MATERIAL PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao: "PREPARACOES QUIMICAS AUXILIARES PARA TEXTIL; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE PAU-ROSA; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao:
      "PREPARACOES ANTIFERRUGEM E ANTICORROSAO (ANTICORROSIVOS); FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE LEMONGRASS; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "PRODUTOS QUIMICOS ANTIESPUMANTES; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao:
      "PREPARACOES A BASE DE ANIDRIDO POLIISOBUTENILSUCCINICO, EM OLEO MINERAL; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao: "PREPARACOES QUIMICAS AUXILIARES PARA TREFILACAO; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "ADITIVOS PARA COMBUSTIVEIS; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "PRODUTOS QUIMICOS ANTICORROSIVOS; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "CITRATO DE SODIO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "DISSULFETO DE BENZOATILA; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao:
      "PREPARACOES QUIMICAS PARA TRATAMENTO E IMPRESSAO GRAFICA; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao: "ACELERADORES DE CURA (ADITIVO ALIMENTAR); FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE GERANIO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "ACIDO TARTARICO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "PREPARACOES QUIMICAS AUXILIARES PARA PAPEL; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao: "GLUCONATO DE MAGNESIO USADO COMO ADITIVO; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao:
      "OLEOS DE CORTE, E OUTRAS PREPARACOES LUBRIFICANTES; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao:
      "PLASTIFICANTES COMPOSTAS PARA BORRACHA OU MATERIAL PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao: "ETER METIL-TER-BUTILICO (MTBE); FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE CUPRESSUS; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "OLEO ESSENCIAL DE FRUTAS CITRICAS; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "LECITINA, FABRICACAO DE" },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE LOURO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao:
      "PREPARACOES CONTENDO BETA-CAROTENO OU OUTRAS MATERIAS CORANTES PROPRIAS PARA COLORIR ALIMENTOS; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "MICROBICIDAS INDUSTRIAIS; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao:
      "PREPARACOES QUIMICAS AUXILIARES PARA TINTAS E VERNIZES; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao: "SAIS E ESTERES DO ACIDO CITRICO; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE COPAIBA; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao:
      "MISTURAS DE SUBSTANCIAS AROMATICAS UTILIZADAS NAS INDUSTRIAS ALIMENTAR E DE BEBIDAS; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "CREMOR ACIDO DE POTASSA; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "ADITIVOS PARA A INDUSTRIA DO COURO; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao: "AROMAS E ESSENCIAS SINTETICAS; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "ACIDO CITRICO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao:
      "MISTURAS DE SUBSTANCIAS AROMATICAS UTILIZADAS COMO MATERIAS BASICA PARA INDUSTRIAS, EXCETO PARA AS INDUSTRIAS ALIMENTAR E DE BEBIDAS; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE PALMAROSA; FABRICACAO DE" },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE LARANJA; FABRICACAO DE" },
  { cnae: "2093200", descricao: "SAIS DO ACIDO GLUTAMICO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "MTBE; FABRICACAO DE" },
  { cnae: "2093200", descricao: "NITRITOS DE POTASSIO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "CITRATO DE POTASSIO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "ESSENCIAS E FRAGRANCIAS SINTETICAS; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "ADITIVOS PARA CONCRETO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "ACIDO GLUCONICO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "ESPECIALIDADES QUIMICAS INDUSTRIAIS; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE TANGERINA; FABRICACAO DE" },
  { cnae: "2093200", descricao: "CASEINATO DE CALCIO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "BHT (2,6-DIETRC-BUTIL-HIDROXITOLUENO); FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE PINHO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "CASEINATO DE SODIO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE BERGAMOTA; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao:
      "SOLUCOES CONCENTRADAS DE OLEOS ESSENCIAIS, OBTIDAS POR TRATAMENTO DE FLORES; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "CREMOR TARTARO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "VANILINA, FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "ADITIVOS PARA A INDUSTRIA TEXTIL; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "ESTERES DO ACIDO TARTARICO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao:
      "ADITIVOS PREPARADOS PARA CIMENTOS, ARGAMASSAS OU CONCRETOS; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "OLEOS ESSENCIAIS VEGETAIS; FABRICACAO DE" },
  { cnae: "2093200", descricao: "GLUTAMATO MONOSSODICO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "DERIVADOS DAS FENILENODIAMINAS; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "ADITIVOS DE USO INDUSTRIAL; FABRICACAO DE" },
  { cnae: "2093200", descricao: "OLEOS ESSENCIAIS; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "ADITIVOS PARA OLEOS LUBRIFICANTES; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE VETIVER; FABRICACAO DE" },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE EUCALIPTO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "PREPARACOES QUIMICAS PARA A CONSTRUCAO CIVIL; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "METILPARABENO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "ACIDO FUMARICO G.A; FABRICACAO DE" },
  { cnae: "2093200", descricao: "BENZOATO DE SODIO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "GLUCONATO DE SODIO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE VASSOURA; FABRICACAO DE" },
  { cnae: "2093200", descricao: "SUBPRODUTOS TERPENICOS; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "PREPARACOES QUIMICAS PARA TRATAMENTO DA MADEIRA; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "NONILFENOL ETOXILADO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "PRODUTOS QUIMICOS PARA GALVANOPLASTIA; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao: "PRODUTOS QUIMICOS ANTIOXIDANTES; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao:
      "PREPARACOES ANTIADERENTES, DESMOLDANTES E SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "AGENTES DE FLOCULACAO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "ADITIVOS PARA A CONSTRUCAO CIVIL; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "ALGINATO DE SODIO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "OLEO DE HORTELA DESMENTOLADO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao:
      "PREPARACOES LUBRIFICANTES (OLEOS DE CORTE, ANTIADERENTES, ANTIFERRUGEM, ANTICORROSAO, DESMOLDANTES, ETC.); FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "ESTABILIZANTES PARA PVC; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "PRODUTOS QUIMICOS PARA TRATAMENTO DA AGUA; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "SAIS DO ACIDO TARTARICO; FABRICACAO DE" },
  { cnae: "2093200", descricao: "BITARTARATO DE POTASSIO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao:
      "PREPARACOES QUIMICAS AUXILIARES PARA A INDUSTRIA DO ACUCAR E ALCOOL; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "DISPERSANTES; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "AROMAS E FRAGRANCIAS SINTETICAS; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao: "ADITIVOS PARA OLEOS, EXCETO OLEOS LUBRIFICANTES; FABRICACAO DE",
  },
  {
    cnae: "2093200",
    descricao:
      "PREPARACOES LUBRIFICANTES PARA TRATAMENTO DE MATERIAS TEXTEIS, COURO E PELETERIA; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "SILICATO DE ETILA; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "PRODUTOS QUIMICOS AUXILIARES PARA FUNDICAO; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "ADITIVOS PARA ASFALTOS; FABRICACAO DE" },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE VERTIVERT; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "PRODUTOS QUIMICOS AUXILIARES PARA A SIDERURGIA; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "PAPAINA; FABRICACAO DE" },
  { cnae: "2093200", descricao: "ANTIEMPEDRANTES; FABRICACAO DE" },
  { cnae: "2093200", descricao: "BAUNILHA; PREPARACAO DE" },
  { cnae: "2093200", descricao: "AGUAS DESTILADAS AROMATICAS; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "PREPARACOES PARA CONSERVACAO DE FRUTAS; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "OLEO ESSENCIAL DE LIMAO; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao:
      "PRODUTOS QUIMICOS AUXILIARES PARA PERFURACAO DE POCOS DE PETROLEO FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "EMULSIONANTES; FABRICACAO DE" },
  {
    cnae: "2093200",
    descricao: "PRODUTOS QUIMICOS ANTIINCRUSTANTES; FABRICACAO DE",
  },
  { cnae: "2093200", descricao: "RESINOIDES; FABRICACAO DE" },
  { cnae: "2093200", descricao: "ADITIVOS PARA POLIMEROS; FABRICACAO DE" },
  { cnae: "20941", descricao: "FABRICACAO DE CATALISADORES" },
  { cnae: "2094100", descricao: "CATALISADORES PARA POLIMEROS; FABRICACAO DE" },
  { cnae: "2094100", descricao: "SAIS DE PRATA; FABRICACAO DE" },
  {
    cnae: "2094100",
    descricao: "CATALISADORES PARA A INDUSTRIA QUIMICA EM GERAL; FABRICACAO DE",
  },
  { cnae: "2094100", descricao: "CATALISADOR PARA AUTOMOVEIS; FABRICACAO DE" },
  { cnae: "2094100", descricao: "CATALISADORES; FABRICACAO DE" },
  { cnae: "2094100", descricao: "HIDROPEROXIDO DE TER-BUTILA; FABRICACAO DE" },
  {
    cnae: "2094100",
    descricao: "PENTOXIDO DE DIVANADIO (ANIDRIDO VANADICO); FABRICACAO DE",
  },
  { cnae: "2094100", descricao: "SAIS DE NIQUEL; FABRICACAO DE" },
  { cnae: "2094100", descricao: "OXIDO CROMICO; FABRICACAO DE" },
  { cnae: "2094100", descricao: "PENTOXIDO DE VANADIO; FABRICACAO DE" },
  { cnae: "2094100", descricao: "INICIADORES; FABRICACAO DE" },
  { cnae: "2094100", descricao: "COBALTO; FABRICACAO DE" },
  { cnae: "2094100", descricao: "CLORETO DE DIETILALUMINIO; FABRICACAO DE" },
  {
    cnae: "2094100",
    descricao:
      "CATALISADORES EM SUPORTE, TENDO METAL PRECIOSOS OU SEUS COMPOSTOS COMO SUBSTANCIA ATIVA; FABRICACAO DE",
  },
  {
    cnae: "2094100",
    descricao:
      "INICIADORES DE REACAO, ACELERADORES DE REACAO E PREPARACOES CATALITICAS; FABRICACAO DE",
  },
  {
    cnae: "2094100",
    descricao: "CATALISADOR PARA CRAQUEAMENTO; FABRICACAO DE",
  },
  {
    cnae: "2094100",
    descricao: "HIDROPEROXIDO DE DIISOPROPILBENZENO; FABRICACAO DE",
  },
  {
    cnae: "2094100",
    descricao:
      "CATALISADORES EM SUPORTE, TENDO NIQUEL OU SEUS COMPOSTOS COMO SUBSTANCIA ATIVA; FABRICACAO DE",
  },
  { cnae: "2094100", descricao: "OXIDO DE MOLIBDENIO; FABRICACAO DE" },
  { cnae: "2094100", descricao: "TRICLORETO DE ETILAUMINIO; FABRICACAO DE" },
  { cnae: "2094100", descricao: "CATALISADORES PETROQUIMICOS; FABRICACAO DE" },
  { cnae: "2094100", descricao: "ZEOLITOS; FABRICACAO DE" },
  {
    cnae: "20991",
    descricao:
      "FABRICACAO DE PRODUTOS QUIMICOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "2099101", descricao: "PELICULAS E FILMES EM ROLO; FABRICACAO DE" },
  { cnae: "2099101", descricao: "FILME CINEMATOGRAFICO; FABRICACAO DE" },
  {
    cnae: "2099101",
    descricao: "FILMES PLANOS PARA RADIOGRAFIA; FABRICACAO DE",
  },
  {
    cnae: "2099101",
    descricao: "PRODUTOS QUIMICOS PARA FOTOGRAFIA; FABRICACAO DE",
  },
  { cnae: "2099101", descricao: "PLACAS FOTOGRAFICAS; FABRICACAO DE" },
  { cnae: "2099101", descricao: "PAPEIS PARA FOTOGRAFIA; FABRICACAO DE" },
  {
    cnae: "2099101",
    descricao: "PLACAS FOTOGRAFICAS PARA RADIOGRAFIA; FABRICACAO DE",
  },
  {
    cnae: "2099101",
    descricao:
      "FILMES PLANOS PARA REPRODUCAO EM OFSETE (OFF-SET); FABRICACAO DE",
  },
  { cnae: "2099101", descricao: "FILMES; FABRICACAO DE" },
  { cnae: "2099101", descricao: "FIXADORES PARA FOTOGRAFIA; FABRICACAO DE" },
  {
    cnae: "2099101",
    descricao:
      "CLORETO DE MERCURIO II (CLORETO MERCURICO) PARA USO FOTOGRAFICO, PRONTO PARA UTILIZACAO; FABRICACAO DE",
  },
  {
    cnae: "2099101",
    descricao:
      "FILME FOTOSSENSIVEL, EM CHAPAS OU ROLOS, PARA RAIO X MEDICO, ODONTOLOGICO E OUTROS; FABRICACAO DE",
  },
  { cnae: "2099101", descricao: "PELICULAS E FILMES EM BANDAS; FABRICACAO DE" },
  { cnae: "2099101", descricao: "MICROFILMES; FABRICACAO DE" },
  {
    cnae: "2099101",
    descricao: "EMULSOES QUIMICAS PARA USO FOTOGRAFICO; FABRICAAO DE",
  },
  {
    cnae: "2099101",
    descricao:
      "CHAPAS OU FILME FOTOSSENSIVEIS PARA ARTES GRAFICAS; FABRICACAO DE",
  },
  {
    cnae: "2099101",
    descricao:
      "CHAPAS, FILMES, PAPEIS E OUTROS MATERIAIS SEMELHANTES PARA FOTOGRAFIA; FABRICACAO DE",
  },
  {
    cnae: "2099101",
    descricao: "FILMES FOTOGRAFICOS E CINEMATOGRAFICOS; FABRICACAO DE",
  },
  {
    cnae: "2099101",
    descricao: "REVELADORES PARA FOTOGRAFIA E FILMES; FABRICACAO DE",
  },
  { cnae: "2099101", descricao: "PAPEIS DIAZOHELIOGRAFICOS; FABRICACAO DE" },
  {
    cnae: "2099101",
    descricao: "PREPARACOES QUIMICAS DE USO FOTOGRAFICO; FABRICACAO DE",
  },
  {
    cnae: "2099199",
    descricao: "SILICIO EM LIGAS POR FUSAO COM QUARTZO (SILICIO METALICO)",
  },
  {
    cnae: "2099199",
    descricao:
      "OLEOS VEGETAIS DESIDRATADOS, SOPRADOS OU QUE SOFRERAM PROCESSAMENTO QUIMICO; FABRICACAO DE",
  },
  {
    cnae: "2099199",
    descricao: "PREPARACOES ANTIDETONANTES, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2099199",
    descricao:
      "POLIMERIZACAO, SOPRAGEM, OXIDACAO, ETC, DE OLEOS VEGETAIS; SERVICO DE",
  },
  {
    cnae: "2099199",
    descricao: "FLUIDO PARA TRANSMISSAO AUTOMATICA (SINTETICO); FABRICACAO DE",
  },
  {
    cnae: "2099199",
    descricao: "RESIDUOS DAS INDUSTRIAS QUIMICAS OU CONEXAS; FABRICACAO DE",
  },
  { cnae: "2099199", descricao: "PROTEINA DE SOJA EM PO; FABRICACAO DE" },
  {
    cnae: "2099199",
    descricao:
      "AGLUTINANTES PREPARADOS PARA MOLDES OU PARA NUCLEOS DE FUNDICAO; FABRICACAO DE",
  },
  {
    cnae: "2099199",
    descricao:
      "ELEMENTOS E COMPOSTOS QUIMICOS IMPURIFICADOS PARA USO EM ELETRONICA; FABRICACAO DE",
  },
  { cnae: "2099199", descricao: "TINTAS PARA ESCREVER; FABRICACAO DE" },
  { cnae: "2099199", descricao: "PEPTONA; FABRICACAO DE" },
  {
    cnae: "2099199",
    descricao:
      "FLUXOS, PASTAS E POS OU OUTRAS PREPARACOES PARA SOLDAR OU PARA DECAPAGEM DE METAIS; FABRICACAO DE",
  },
  { cnae: "2099199", descricao: "PASTAS E POS PARA SOLDAR; FABRICACAO DE" },
  {
    cnae: "2099199",
    descricao: "CERAS E OUTRAS COMPOSICOES PARA DENTISTAS; FABRICACAO DE",
  },
  {
    cnae: "2099199",
    descricao: "LIQUIDOS PARA TRANSMISSOES HIDRAULICAS; FABRICACAO DE",
  },
  {
    cnae: "2099199",
    descricao:
      "SILICIO E OUTROS ELEMENTOS E COMPOSTOS QUIMICOS IMPURIFICADOS PARA USO EM ELETRONICA; FABRICACAO DE",
  },
  {
    cnae: "2099199",
    descricao: "FLUIDO PARA DIRECAO HIDRAULICA (SINTETICO); FABRICACAO DE",
  },
  {
    cnae: "2099199",
    descricao:
      "MEIOS DE CULTURA PREPARADOS PARA O DESENVOLVIMENTO DE MICROORGANISMOS; FABRICACAO DE",
  },
  {
    cnae: "2099199",
    descricao:
      "OLEOS VEGETAIS POLIMERIZADOS, SOPRADOS, OXIDADOS, ETC, PRODUCAO DE",
  },
  {
    cnae: "2099199",
    descricao: "PRODUTOS QUIMICOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2099199",
    descricao: "REAGENTES DE DIAGNOSTICO OU DE LABORATORIO; FABRICACAO DE",
  },
  {
    cnae: "2099199",
    descricao:
      "OLEOS E GORDURAS VEGETAIS, QUIMICAMENTE MODIFICADOS (POLIMERIZADOS, OXIDADOS, ETC)",
  },
  {
    cnae: "2099199",
    descricao:
      "COMPOSICOES E CARGAS PARA APARELHOS EXTINTORES DE INCENDIO; FABRICACAO DE",
  },
  {
    cnae: "2099199",
    descricao: "CARGA PARA EXTINTOR DE INCENDIO; FABRICACAO DE",
  },
  {
    cnae: "2099199",
    descricao: "PREPARACOES ANTICONGELANTES, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2099199",
    descricao: "SAIS DE ACIDOS NAFTENICOS, INSOLUVEIS EM AGUA; FABRICACAO DE",
  },
  { cnae: "2099199", descricao: "TINTAS PARA DESENHAR; FABRICACAO DE" },
  { cnae: "2099199", descricao: "FLUIDO PARA FREIO; FABRICACAO DE" },
  {
    cnae: "2099199",
    descricao:
      "TINTAS PREPARADAS PARA PINTURA ARTISTICA, ATIVIDADES EDUCATIVAS OU DE RECREACAO (GUACHES, AQUARELAS, ETC.); FABRICACAO DE",
  },
  {
    cnae: "2099199",
    descricao:
      "MASSAS E PASTAS PARA MODELAR, INCLUSIVE PARA RECREACAO; FABRICACAO DE",
  },
  { cnae: "21106", descricao: "FABRICACAO DE PRODUTOS FARMOQUIMICOS" },
  { cnae: "2110600", descricao: "TIRATRICOL; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "SALICILATO DE METILA USADO COMO FARMACO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "METOCLOPRAMIDA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "METRONIDAZOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "RANITIDINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ESTRIOL, SUCCINATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CITRATO DE CALCIO FERROSO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ERITROMICINA, ESTEARATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PLASMA (FARMOQUIMICOS); FABRICACAO DE" },
  { cnae: "2110600", descricao: "ISOSORBIDA, DINITRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PEPSINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "HIDROCLOROTIAZIDA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ACETATO DE CIPROTERONA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "SULFATO DE CONDROITINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DIFENOXILATO, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ACIDO NALIDIXICO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "PRINCIPIOS ATIVOS PARA MEDICAMENTOS PARA USO VETERINARIO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "MOCLOBEMIDA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BUCLOSAMIDA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BENZIDAMINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CARBIDOPA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "AMOXILINA TRIHIDRATADA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ACIDO ACETILSALICILICO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLORHEXIDINA, BASE; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BROMOLACTOBIONATO DE CALCIO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "6-MERCAPTOPURINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DICLOFENACO, DIETILAMONIO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ACIDO DEHIDROCOLICO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TARTARATO DE SODIO NEUTRO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ALBENDAZOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ETAVERINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "SALICILAMIDA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "HORMONIOS E SEUS DERIVADOS; OUTROS ESTEROIDES UTILIZADOS PRINCIPALMENTE COMO HORMONIOS; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "MAZINDOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ACIDO HIALURONICO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "SULFAGUANIDINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "SULFANILAMIDA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "CLORIDRATO DE PROPRANOLOL (FARMOQUIMICO); FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "BILE BOVINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "LOVASTATINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "INTERFERONA (FARMOQUIMICO); FABRICACAO DE" },
  { cnae: "2110600", descricao: "ACIDO DESOXICOLICO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DIETILPROPIONA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PROPOXIFENO, NAPSILATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLORDIAZEPOXIDO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TIZANIDINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "AZIDOTIMIDINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PENTAMIDINA, ISETIONATO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "FARMOQUIMICOS PARA MEDICAMENTOS VETERINARIOS; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "NORFLOXACINA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "HIDROCORTIZONA, HEMISUCCINATO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "OXITOCINA SINTETICA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FLUTAMIDA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "OXFENDAZOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TRIMETOPRIM; FABRICACAO DE" },
  { cnae: "2110600", descricao: "LINESTRENOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BUTANILICAINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TIMISTIMULINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DIPIRONA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "AMBROXOL, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "METILDOPA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "MERBROMINO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLORHEXIDINA, DIACETATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "AMPICILINA ANIDRA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FENILBUTAZONA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "ALCALOIDES VEGETAIS, SEUS SAIS, ETERES, ESTERES E OUTROS DERIVADOS; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "TERPINEOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ALFA AMILASE; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PIMOZIDA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "4,4'-BIPIRIDINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ENROFLOXACINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BENZETIMIDA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLOXAZOLAM; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TETRAMISOL, FOSFATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "AMPICILINA SODICA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "EXTRATOS DE GLANDULAS OU DE OUTROS ORGAOS, OU DAS SUAS SECRECOES; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "AMITRAZ USADO COMO FARMACO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CODEINA, SULFATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "MEGLUMINA, DIATRIZOATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "OXITETRACICLINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "SULFAMETAZINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BROXIQUINOLINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FIBRINOLISINA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "VITAMINA C (ACIDO L- OU DL- ASCORBICO); FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "OMEPRAZOL (FARMOQUIMICO); FABRICACAO DE" },
  { cnae: "2110600", descricao: "TRIAZOLAM; FABRICACAO DE" },
  { cnae: "2110600", descricao: "MICONAZOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CIPROFLOXACINA, CLORIDRATO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "ERITROPOIETINA (ERITROPOETINA); FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "FURALTADONA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "OXPRENOLOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "NICOTINATO DE SODIO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DESOGESTREL; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "COMPOSTOS HETEROCICLICOS CUJA ESTRUTURA CONTEM UM CICLO PIRAZOL (HIDROGENADO OU NAO), NAO CONDENSADO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "MICONAZOL, NITRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "VITAMINA D3 - FEED GRADE; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLORHEXIDINA, DICLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CEFACLOR MONOHIDRATADO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ACETANILIDA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TIMOMODULINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DIMINAZENO, ACETURATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DICLOFENACO POTASSICO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "INSULINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FENILLEFRINA, CLORIDRATO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "GLUCONATO DE MAGNESIO USADO COMO FARMACO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "PARACETAMOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TIAPRIDA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PIRILAMIDA, MALEATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FUROSEMIDA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CARBAMAZEPINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TERCONAZOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FLUFENAZINA, ENANTATO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "HEPARINA DE BAIXO PESO MOLECULAR; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "PENICILINA G POTASSICA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "ALCALOIDES E SEUS DERIVADOS (FARMOQUIMICOS); FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "PROMETAZINA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "ESTROGENIOS E PROGESTOGENIOS, N.E.; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "DIAZEPAM; FABRICACAO DE" },
  { cnae: "2110600", descricao: "QUERCETINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ROBENIDINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ZIDOVUDINA (AZT); FABRICACAO DE" },
  { cnae: "2110600", descricao: "NIFUROXAZIDA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "VITAMINA A, ACETATO-FEED GRADE; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "HIDRALAZINA, CLORIDRATO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "ALUMINATO DE MAGNESIO HIDRATADO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "CRIZAROBINA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "ANTIBIOTICOS E SEUS DERIVADOS, NAO ESPECIFICADOS (AMPICILINA, AMOXICILINA, ERITROMICINA, PENICILINAS, TETRACICLINAS, OXITETRACICLINA); FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "CLOSANTEL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLORETO DE CETALCONIO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "HALOPERIDOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "EPITUMOMABE; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BUSPIRONA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "OXAZEPAM; FABRICACAO DE" },
  { cnae: "2110600", descricao: "LACTOBIONATO DE CALCIO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "GENTAMICINA, SULFATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "SALBUTAMOL; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "SUBSTANCIAS DE ORIGEM HUMANA OU ANIMAL PARA FINS PROFILATICOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "SULFADIAZINA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "OXITETRACICLINA, SAL ALQUIL TRIMETILAMONIO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "TRIPSINA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "GLANDULAS E OUTROS ORGAOS DESSECADOS, MESMO EM PO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "NICARBAZINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLEMBUTEROL, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "RIFAMICINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "VERAPAMIL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "NITRATO DE PROPATILA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "LIPOCAICO (FARMOQUIMICO); FABRICACAO DE" },
  { cnae: "2110600", descricao: "CEFALOTINA SODICA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PAMOATO DE PIRANTEL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLOMIFENO, CITRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FOSFATIDILSERINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "EMETINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FERRO, PROTEINSUCCINILATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "LOPERAMIDA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TIZANIDINA, CLORIDRATO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "DIBENZOAZEPINA (IMINOESTILBENO); FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "BETAMETASONA, FOSFATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "VERAPAMIL, CLORIDRATO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "CULTURA DE MICRORGANISMOS (PRODUTOS DE BIOTECNOLOGIA) PARA USOS AGRICOLAS (INOCULANTES), PARA DEFESA AMBIENTAL (BIORREMEDIADORES) E OUTROS USOS, EXCETO LEVEDURAS; FABRICACAO DE",
  },
  {
    cnae: "2110600",
    descricao: "DEXAMETASONA, TER-BUTIL ACETATO; FABRICACAO DE",
  },
  {
    cnae: "2110600",
    descricao: "MALEATO DE ENALAPRIL (FARMOQUIMICO); FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "CLOBAZAM; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FLUBENDAZOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "LIDOCAINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CARBADOX; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FEMPROPOREX; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PEFLOXACINA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "ACUCARES QUIMICAMENTE PUROS (GALACTOSE, ARABINOSE, ETC); ETERES E ESTERES DE ACUCARES E SAIS; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "DEXAMETASONA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ALISAPRIDA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "AMISULPRIDA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FLUNITRAZEPAM; FABRICACAO DE" },
  { cnae: "2110600", descricao: "MEBENDAZOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DIPIRONA MAGNESICA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "ACUCARES QUIMICAMENTE PUROS (GALACTOSE, ARABINOSE, ETC.); ETERES E ESTERES DE ACUCARES E SEUS PAIS; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "FENITOINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CITRATO DE FERRO AMONIACAL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BUPIVACAINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DEXTROSE ANIDRA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "KETAMINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ERITROMICINA, ESTOLATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PIROXICAM; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CODEINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "SULFAS (FARMOQUIMICOS); FABRICACAO DE" },
  { cnae: "2110600", descricao: "DICLOFENACO SODICO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLORANFENICOL, PALMITATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "SULFAMETOXAZOL; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "ESTREPTOMICINAS, N.E., E SEUS DERIVADOS; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "BENZOATO DE AMONIO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FLUNARIZINA, DICLORIDRATO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "DEXAMETASONA, FOSFATO DISSODICO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "PIRAZINAMIDA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DOPAMINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DEHIDROCOLATO DE SODIO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CONDROITIN, SULFATO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "TRANSFORMACAO DO SANGUE E A FABRICACAO DE SEUS DERIVADOS (EXCETO A COLETA DO SANGUE HUMANO); FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "ORNIDAZOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FENITOINA SODICA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DICICLOVERINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PENICILINA V POTASSICA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "AMIODARONA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CEFALEXINA MONOHIDRATADA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "LACTATO DE CALCIO USADO COMO FARMACO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "UROQUINASE; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLOFAZIMINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BETAMETASONA, DIPROPIONATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PILOCARPINA, NITRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DIHIDROERGOTOXINA, MESILATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PENTOXIFILINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "GLUCONATO FERROSO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "GLIBENCLAMIDA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "TRIPSINA E QUIMOTRIPSINA, MISTURA; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "PROZAPINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "LEVODOPA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "MENADIONA, BISSULFITO DE SODIO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "DEXAMETASONA, ACETATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "GUAIAFENESINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "AMILOGLUCOSIDASE; FABRICACAO DE" },
  { cnae: "2110600", descricao: "LEVAMISOL, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ACUCARES SINTETICOS; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BUCLISINA, DICLORIDRATO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "PRINCIPIOS ATIVOS PARA MEDICAMENTOS PARA USO HUMANO FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "GLUBIONATO DE CALCIO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "AMILORIDA, CLORIDRATO HIDRATADO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "PRILOCAINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "8593700", descricao: "ESPANHOL; CURSO, ENSINO DE" },
  { cnae: "2110600", descricao: "FOSFOLIPIDEOS CEREBRAIS; FABRICACAO DE" },
  { cnae: "2110600", descricao: "OLAQUINDOX; FABRICACAO DE" },
  { cnae: "2110600", descricao: "HIDRALAZINA, SULFATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "AZATIOPRINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "GAMAGLOBULINA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "ACIDO SALICILICO; ACIDO O-ACETILSALICILICO; SEUS SAIS E ESTERES; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "CODEINA, FOSFATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLORANFENICOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PRAZIQUANTEL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PROPILTIOURACIL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PIMETIXENO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "ESCOPALAMINA, N-BUTIL BROMETO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "CLORTALIDONA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "PROVITAMINAS E VITAMINAS, INCLUIDOS OS CONCENTRADOS NATURAIS, N.E.; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "ECONAZOL, NITRATO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "DERIVADOS DE ACIDO QUINOLINODICARBOXILICO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "CLORANFENICOL, HEMISUCCINATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DESNALOSIDO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FARMACOS; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ESTRIOL; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "ANTIBIOTICOS NAO-DOSADOS (FARMOQUIMICOS); FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "TALIDOMIDA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DIPIRIDAMOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLORHEXIDINA, DIGLUCONATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "MADURAMICINA AMONICA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "HALOPERIDOL, DECANOATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FUMARATO FERROSO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "NITRENDIPINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "HIDROXOCOBALAMINA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "DIHIDROERGOCRISTINA, MESILATO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "ATENOLOL (FARMOQUIMICO); FABRICACAO DE" },
  { cnae: "2110600", descricao: "VERALIPRIDA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "SANGUE HUMANO E ANIMAL, CULTURA DE MICROORGANISMOS, TOXINAS E OUTROS PRODUTOS SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "LACTOGLUCONATO DE CALCIO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PILOCARPINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FURAZOLIDONA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TRIFLUPERIDOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ALILESTRENOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "HIDROCORTIZONA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PENICILINA G BENZATINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "METIXENO, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FENBEDAZOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "MAPROTILINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "HEPARINOIDE; FABRICACAO DE" },
  { cnae: "2110600", descricao: "AMPICILINA TRIHIDRATADA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "AMITRIPTILINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BETAINA 50% - FEED GRADE; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PIRACETAM; FABRICACAO DE" },
  { cnae: "2110600", descricao: "QUIMOTRIPSINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BETAMETASONA, ACETATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLORANFENICOL, SUCCINATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "LINCOMICINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "SULFONAMIDAS, N.E.; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "HORMONIOS CORTICOSSUPRA-RENAIS E SEUS DERIVADOS; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "MINOXIDIL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ERITROMICINA, BASE; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ESTRADIOL-3-FEMPROPIONATO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "RUTOSIDIO (RUTINA) E SEUS DERIVADOS; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "RANITIDINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "METOCLOPRAMIDA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "SULTOPRIDA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "DERIVADOS HALOGENADOS DOS HORMONIOS CORTICOSSUPRA-RENAIS; FABRICACAO DE",
  },
  {
    cnae: "2110600",
    descricao:
      "PRODUTOS FARMACEUTICOS DE BASE PARA MEDICAMENTOS HUMANOS; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "HIDROCORTIZONA, ACETATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "GLICOPEPTIDEO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "TIAMULINA, FUMARATO HIDROGENADO; FABRICACAO DE",
  },
  {
    cnae: "2110600",
    descricao: "VITAMINA E, ACETATO - FEED GRADE; FABRICACAO DE",
  },
  {
    cnae: "2110600",
    descricao:
      "PRODUTOS FARMACEUTICOS DE BASE PARA MEDICAMENTOS VETERINARIOS; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "NIFEDIPINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CIPROHEPTADINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CAFEINA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao:
      "COMPOSTOS HETEROCICLICOS CUJA ESTRUTURA CONTEM UM CICLO PIRIMIDINA (HIDROGENADO OU NAO), OU PIPERAZINA; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "MONOSSULFIRAM; FABRICACAO DE" },
  { cnae: "2110600", descricao: "OXITETRACICLINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CIMETIDINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BETA-GLUCANASE; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PIRITINOL, BASE; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TETRACICLINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CIANOCOBALAMINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ISOCONAZOL, NITRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "METROPOLOL, TARTARATO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "ACETATO DE D- OU DL- ALFA-TOCOFEROL; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "OXOLINATO DE SODIO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DIHIDROERGOTAMINA, MESILATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "NITROVIN; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TIMOLOL, MALEATO ACIDO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ASTEMISOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CINARIZINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "EUGENOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CETOCONAZOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FERRITINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CITRATO DE MONOSSODICO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "LEVAMISOL, BASE; FABRICACAO DE" },
  { cnae: "2110600", descricao: "MANITOL; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "DIOCTIL SULFOSUCCINATO DE SODIO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "PETIDINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "MAPROTILINA, MESILATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BROMAZEPAM; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ALBENDAZOL, SULFOXIDO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DROPERIDOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLORETO DE BENZALCONIO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLONAZEPAN; FABRICACAO DE" },
  { cnae: "2110600", descricao: "NIMODIPINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CITRATO DE MAGNESIO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PIPERAZINA, CITRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "VITAMINA B2 (RIBOFLAVINA); FABRICACAO DE" },
  { cnae: "2110600", descricao: "CARBOCISTEINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "LIDOCAINA CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "AMPICILINA BENZATINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "AURANOFINA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "ACIDO NICOTINICO, NAO MISTURADO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "BENZOATO DE BENZILA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ACIDO COLICO (FARMOQUIMICO); FABRICACAO DE" },
  { cnae: "2110600", descricao: "PENICILINA G PRACAINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "SALBUTAMOL, SULFATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ACETAZOLAMIDA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ACIDO SULFANILICO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "TARTARATO DUPLO DE POTASSIO E SODIO; FABRICACAO DE",
  },
  {
    cnae: "2110600",
    descricao: "PANTOTENATO DE CALCIO-FEED GRADE; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "VITAMINA H (BIOTINA); FABRICACAO DE" },
  { cnae: "2110600", descricao: "ALPRAZOLAM; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FLURAZEPAM; FABRICACAO DE" },
  { cnae: "2110600", descricao: "MONOENSIMA SODICA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "HEPARINA SODICA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "IMIPRAMINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TRIMETROPINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLORANFENICOL, CINAMATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DOXICLINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DICLOFENACO, RESINATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLOTRIMAZOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TICLOPIDINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "SULFODOXINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TETRAMISOL, BASE; FABRICACAO DE" },
  { cnae: "2110600", descricao: "AMOXILINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLORANFENICOL, ESTEARATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "APROTININA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TENOXICAM; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BUZEPIDA, METIL IODETO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BETAMETASONA, VALERATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TETRAMISOL, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "ORGOTEINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "TETRACICLINA, BASE; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PROPANOLOL, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "VINCAMINA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "NICOTINAMIDA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "BROMOPRIDA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "CLORMEZANONA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DISOFENOL; FABRICACAO DE" },
  { cnae: "2110600", descricao: "DOMPERIDONA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "PRODUTOS FARMOQUIMICOS; FABRICACAO DE" },
  { cnae: "2110600", descricao: "LEVAMISOL, FOSFATO; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "PIRITINOL, CLORIDRATO MONOHIDRATADO; FABRICACAO DE",
  },
  {
    cnae: "2110600",
    descricao: "MENADIONA, DIMETILPIRIMIDINOL BISSULFITO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "EXTRATO DE IPECA; FABRICACAO DE" },
  { cnae: "2110600", descricao: "IMIPRAMINA, EMBONATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "MEPIVACAINA, CLORIDRATO; FABRICACAO DE" },
  { cnae: "2110600", descricao: "FLUMEQUINA; FABRICACAO DE" },
  {
    cnae: "2110600",
    descricao: "ACIDO 7-AMINODESACETOXICEFALOSPORANICO; FABRICACAO DE",
  },
  { cnae: "2110600", descricao: "BELLAFOLINA A 60%; FABRICACAO DE" },
  { cnae: "21211", descricao: "FABRICACAO DE MEDICAMENTOS PARA USO HUMANO" },
  { cnae: "2121101", descricao: "ANTIDISMENORREICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE AMPICILINA, PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "NEUROTROFICOS, NEUROTROPICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIGRIPAIS E ANTITUSSIGENOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "CICATRIZANTES PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "PRODUTOS PARA O SANGUE OU FORMADORES DE SANGUE PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao: "TUBERCULOSTATICOS INCLUINDO ESTREPTOMICINA; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTIARRITMICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "CORTICOIDES TOPICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANESTESICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "PSICOLEPTICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "PREPARADOS ANTIVARICOSOS E ANTIHEMORROIDARIOS; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao: "PRODUTOS DERMATOLOGICOS PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao: "PREPARADOS GINECOLOGICOS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE PENICILINAS, EXCETO AMOXICILINA E AMPICILINA; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "VACINAS PARA USO HUMANO; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "PREPARACOES FARMACEUTICAS PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE SILFADIL, VARDENAFIL, TADALAFIL, LODENAFIL E OUTROS INIBIDORES DE FOSFODIESTERASE (PDE5); FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "OREXIGENOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "CORTICOSTEROIDES SISTEMICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "DILATADORES DO PLASMA; FABRICACAO DE" },
  { cnae: "2121101", descricao: "OPOTERAPICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "PARASSIMPATOLITICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE OUTROS HORMONIOS, MAS NAO CONTENDO ANTIBIOTICOS NEM INSULINA; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao: "ANTIINFLAMATORIOS E ANTI-REUMATICOS; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "AGENTES BETA-BLOQUEADORES; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE ACIDOS NUCLEICOS E SEUS SAIS OUTROS HETEROCICLICOS, PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTIHISTAMINICOS SISTEMICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTILEUCEMICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "EXPECTORANTES PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "COAGULANTES; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE ACIDO ASCORBICO, PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao: "SOROS E VACINAS PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "8593700", descricao: "IDIOMAS; CURSO, ENSINO DE" },
  {
    cnae: "2121101",
    descricao: "REMEDIOS ALOPATICOS PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "DIGESTIVOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "ANTIMICOTICOS SISTEMICOS EXCLUINDO GRISEOFULVINA; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "SOLUCOES PARENTERAIS (SORO FISIOLOGICO E OUTRAS); FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTIDOTOS PARA USO HUMANO; FABRICACAO DE" },
  { cnae: "2121101", descricao: "LAXANTES; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "ASSOCIACOES OFTALMOLOGICAS E OTOLOGICAS; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao: "SOLUCOES ENTERAIS, PARENTERAIS; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE HORMONIOS CORTICOSSUPRA-RENAIS, PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "SOROS ESPECIFICOS DE ANIMAIS E OUTROS CONSTITUINTES DO SANGUE, PARA MEDICINA HUMANA; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTIGOTOSOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "PREPARACOES FARMACEUTICAS ALOPATICAS PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTICOLINERGICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE COMPOSTOS HETEROCICLICOS EXCLUSIVAMENTE DE HETEROATOMOS DE NITROGENIO, PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "FIBRINOLITICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIVARICOSOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "SOROS (ANTISSOROS), OUTRAS FRACOES DO SANGUE E PRODUTOS IMUNOLOGICOS MODIFICADOS, PARA USO HUMANO (ANTIOFIDICOS, ANTITETANICOS, ANTIDIFTERICOS, ETC.); FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTI-REUMATICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "ANTICONVULSIVANTES ANTIEPILEPTICOS; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao: "ANTI-HELMINTICOS PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "DERMATOLOGICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE OXITETRACICLINA, PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTIESPAMODICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "INIBIDORES DE COAGULACAO; FABRICACAO DE" },
  { cnae: "2121101", descricao: "PREPARACOES DERMATOLOGICAS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE DICLOFENACOS (DE POTASSIO, DE SODIO, DE DIETILAMONIO, INCLUSIVE NA FORMA DE RESINATO); FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "COLIRIOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "NEUROTONICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "EUREPTICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS N.E., A BASE DE SULFONAMIDAS, PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "INIBIDORES ACE; FABRICACAO DE" },
  { cnae: "2121101", descricao: "EXPANSORES DO PLASMA; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "VITAMINAS E SUPLEMENTOS MINERAIS PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "SAIS EFERVECENTES; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANOREXIGENOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "AGENTES HEMATOLOGICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "PRODUTOS CARDIOVASCULARES E CIRCULATORIOS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE ESTATINAS (SINVASTATINA, ATORVASTATINA, PROVASTATINA, ROSUVASTATINA, ETC); FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "RELAXANTES MUSCULARES; FABRICACAO DE" },
  { cnae: "2121101", descricao: "HEPATOPROTETORES; FABRICACAO DE" },
  { cnae: "2121101", descricao: "TONICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIPARASITARIOS HUMANOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIPSORIASE; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "ESPECIALIDADES FARMACEUTICAS ALOPATICAS PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTIANEMICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE LORATADINA PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTIFIBRINOLITICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ESQUISTOSSOMICIDAS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "DESCONGESTIONANTES NASAIS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "MEDICAMENTOS A BASE DE CAFEINA, PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao: "PRODUTOS PARA O SISTEMA RESPIRATORIO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTITOXICOS PARA USO HUMANO; FABRICACAO DE" },
  { cnae: "2121101", descricao: "MICOTICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "PRODUTOS TERAPEUTICOS PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao: "SOLUCOES SUBSTITUTAS DE SANGUE E PLASMA; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao: "ANTIEMETICOS E ANTIVERTIGINOSOS; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "MNEMOTONICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "OTOLOGICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ALERGENICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "LIPOLITICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE OUTRAS VITAMINAS PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE OMEPRAZOL, PANTORAZOL, LANSOPRAZOL, ESOMEPRAZOL, RABEPRAZOL, TENATOPRAZOL E OUTROS INIBIDORES SIMILARES DA BOMBA DE PROTONS; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao: "ANTIINFECCIOSOS PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANALGESICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE OUTROS ALCALOIDES E SEUS DERIVADOS PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "MIORRELAXANTES; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "PRODUTOS FARMACEUTICOS MANIPULADOS; CENTRAIS DE MANIPULACAO DE",
  },
  {
    cnae: "2121101",
    descricao: "SULFONAMIDAS SISTEMICAS PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "OFTALMOLOGICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS PARA SISTEMA MUSCULO-ESQUELETICO, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE LOVASTATINA, PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "EMOLIENTES; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIFISETICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE VITAMINA A (RETINOL) E SEUS SAIS, PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao: "MEDICAMENTOS A BASE DE ACIDO SALICILICO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTIVIROTICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "PREPARACOES PARA GARGANTA; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIBIOTICOS TOPICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "MUCOLITICOS PARA USO HUMANO; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTI-HEMOFILICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "ANTITOXOPLASMOTICOS PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "ANTICOAGULANTES E INIBIDORES DE AGREGACAO PLAQUETARIA; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTIPRURIGINOSOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "ANTI-SEPTICOS ORAIS MEDICINAIS; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTIMICOTICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ENZIMAS ANTIINFLAMATORIAS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "VACINAS PARA MEDICINA HUMANA; FABRICACAO DE" },
  { cnae: "2121101", descricao: "LIPOTROPICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "ANTIACIDOS, ANTIFISETICOS E ANTIULCEROSOS; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ENEMAS; FABRICACAO DE" },
  { cnae: "3099700", descricao: "CHARRETES; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE NIMESULIDA, PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao: "MEDICAMENTOS A BASE DE ENZIMAS, PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "SOROS ESPECIFICOS DE USO HUMANO E OUTROS CONSTITUINTES DO SANGUE; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "AMEBICIDAS PARA USO HUMANO; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIACNEICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "VASODILATADORES; FABRICACAO DE" },
  { cnae: "2121101", descricao: "PARASSIMPATOMIMETICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "COLAGOGOS, COLERETICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ESTIMULANTES DE APETITE; FABRICACAO DE" },
  { cnae: "2121101", descricao: "NEUROLEPTICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIASMATICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ESTOMALOGICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "CITOSTATICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE ATENOLOL, PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "INDUTORES DA OVULACAO; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTI-HIPERURICEMICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "MEDICAMENTOS A BASE DE PARACETAMOL; BROMOPRIDA; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "CARDIOTERAPICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "IMUNOMODULADORES; FABRICACAO DE" },
  { cnae: "2121101", descricao: "LAXATIVOS E PURGATIVOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "REMEDIOS PARA USO HUMANO; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIDIABETICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "CARDIOTONICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTITROMBOTICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ENTORPECENTES; FABRICACAO DE" },
  { cnae: "2121101", descricao: "OCITOCITOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE ACETATO DE TOCOFEROL, PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "HIPNOTICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTITIREOIDIANOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE ESTREPTOMICINAS E SEUS DERIVADOS, PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "TERAPIA TIROIDEANA; FABRICACAO DE" },
  { cnae: "2121101", descricao: "CALICIDAS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "MEDICAMENTOS PARA SNC, N.E.; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ACIDIFICANTES; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANABOLIZANTES SISTEMICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "POTENCIALIZADORES DE ANESTESICOS GERAIS; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE COMPOSTOS DAS FUNCOES CARBOXIAMIDA E AMIDA DO ACIDO CARBONICO PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "PRODUTOS ANGIOLOGICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "SOROS E GAMAGLOBULINAS PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "SOLUCOES HOSPITALARES, FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTITERMICOS PARA USO HUMANO; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "MEDICAMENTOS A BASE DE ACIDO ACETILSALICILICO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "SOLUCOES PARA PERFUSAO, FABRICACAO DE" },
  { cnae: "2121101", descricao: "SOLUCOES PARA DIALISE, FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "ANTIATEROMAS E HIPOLIPIDEMICOS; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE INSULINA MAS NAO CONTENDO ANTIBIOTICOS, PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "DIURETICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "PREPARADOS ANOREXICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "VASOTERAPIA CEREBRAL E PERIFERICA; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE AMOXICILINA E SEUS SAIS, PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "MIDRIATICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ADITIVOS PARA SOLUCOES; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTAGONISTAS DE COAGULANTES; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "BRONCODILATADORES ANTIASMATICOS; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTITUSSIGENOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "PREPARACOES NASAIS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "CONTRACEPTIVOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE VITAMINAS, EXCETO VITAMINAS A, VITAMINA C (ACIDO ASCORBICO) E VITAMINA E (TOCOFEROL); FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE DIPIRONA, PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE CEFTRIAXONA, PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "PRODUTOS GERIATRICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "MIOCARDIOTROPICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANSIOLITICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIULCERAS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE CLORIDRATO DE LICOMICINA, PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTIDISTONICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS SISTEMICOS ESPECIFICOS PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "UROLOGICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "TUBERCULOSTATICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTILEISHMANIASE; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIPSICOTICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTINEVRALGICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIPARKISONIANOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIBIOTICOS SISTEMICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANALEPTICOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE LINCOSAMIDAS OU SEUS DERIVADOS, EXCETO CLORIDRATO DE LINCOMICINA; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "PREPARACOES QUIMICAS CONTRACEPTIVAS A BASE DE HORMONIOS OU DE ESPERMICIDAS, PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE ESTATINAS (SINVASTATINA, ATORVASTATINA, PROVASTATINA, ROSUVASTATINA, ETC.), EXCETO LOVASTATINA; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "HORMONIOS SEXUAIS SISTEMICOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIGENOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ACTH, FABRICACAO DE" },
  { cnae: "2121101", descricao: "HIPOTENSORES ARTERIAIS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIDEPRESSIVOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIINFECCIOSOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "REPOSITORES ELETROLITICOS ORAIS; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "EXTRATOS MEDICINAIS BOTANICOS (POMADAS, PILULAS, SOLIDO OU FLUIDO) PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao: "SOLUCOES HOSPITALARES INTRAVENOSAS, FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "ANTIDIARREICOS, ANTIDISENTERICOS PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "HORMONIOS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIPARQUINSONIANOS; FABRICACAO DE" },
  { cnae: "3211601", descricao: "TURMALINA, LAPIDACAO DE" },
  {
    cnae: "2121101",
    descricao: "ANTIINFECCIOSOS GINECOLOGICOS; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTICONCEPCIONAIS; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTI-HIPERTENSIVOS; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE CETOCONAZOL, PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "ANTAGONISTAS DO CALCIO; FABRICACAO DE" },
  {
    cnae: "2121101",
    descricao: "ANTIESTROGENICOS PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao: "ANTI-HEMORRAGICOS PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121101",
    descricao:
      "MEDICAMENTOS A BASE DE CAPTOPRIL, PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121101", descricao: "NUTRIENTES EM GERAL; FABRICACAO DE" },
  { cnae: "2121101", descricao: "ANTIMALARICOS; FABRICACAO DE" },
  {
    cnae: "2121102",
    descricao: "PRODUTOS HOMEOPATICOS PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121102",
    descricao:
      "PRODUTOS TERAPEUTICOS HOMEOPATICOS PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121102",
    descricao:
      "ESPECIALIDADES FARMACEUTICAS HOMEOPATICAS PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121102",
    descricao: "MEDICAMENTOS HOMEOPATICOS PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121102",
    descricao:
      "PREPARACOES FARMACEUTICAS HOMEOPATICAS PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "2121102",
    descricao: "REMEDIOS HOMEOPATICOS PARA USO HUMANO; FABRICACAO DE",
  },
  { cnae: "2121103", descricao: "MEDICAMENTOS NATURAIS; FABRICACAO DE" },
  {
    cnae: "2121103",
    descricao: "MEDICAMENTOS FITOTERAPICOS PARA USO HUMANO; FABRICACAO DE",
  },
  {
    cnae: "21220",
    descricao: "FABRICACAO DE MEDICAMENTOS PARA USO VETERINARIO",
  },
  { cnae: "2122000", descricao: "VACINA CONTRA BRUCELOSE; FABRICACAO DE" },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE ACIDO ASCORBICO, PARA USO VETERINARIO; FABRICACAO DE",
  },
  { cnae: "2122000", descricao: "ENDECTOCIDAS VETERINARIAS; FABRICACAO DE" },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE CETOCONAZOL, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "SOROS ESPECIFICOS DE ANIMAIS E OUTROS CONSTITUINTES DO SANGUE, PARA MEDICINA VETERINARIA; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "DEFENSIVOS ANIMAIS (PREPARACOES); FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE OUTROS HORMONIOS MAS NAO CONTENDO ANTIBIOTICOS NEM INSULINA, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "PRODUTOS TERAPEUTICOS PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "VACINAS PARA SUINOS (PORCOS) (RINITE ATROFICA, PNEUMOENTERITE, ETC); FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE HORMONIOS CORTICOSSUPRA-RENAIS, PARA USO VETERINARIO; FABRICACAO DE",
  },
  { cnae: "2122000", descricao: "ESPECIALIDADES VETERINARIAS; FABRICACAO DE" },
  {
    cnae: "2122000",
    descricao: "REMEDIOS PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE DIPIRONA, PARA USO VETERINARIOS; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE ATENOLOL, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "PRODUTOS BIOLOGICOS VETERINARIOS; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "PREPARACOES FARMACEUTICAS PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE OUTRAS VITAMINAS PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE ACETATO DE TOCOFEROL, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE AMOXILINA E SEUS SAIS, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE CAFEINA, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE CLORIDRATO DE LICOMICINA, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "VACINAS PARA AVES (NEWCASTLE, MAREK, CORIZA, GUMBORO, ETC); FABRICACAO DE",
  },
  { cnae: "2122000", descricao: "VACINA CONTRA PESTE SUINA; FABRICACAO DE" },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE SULFONAMIDAS PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "ENERGETICOS VITAMINICOS VETERINARIOS; FABRICACAO DE",
  },
  { cnae: "2122000", descricao: "ANTIDIARREICOS VETERINARIOS; FABRICACAO DE" },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE VITAMINA A (RETINOL) E SEUS SAIS, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "ESPECIALIDADES FARMACEUTICAS (ALOPATICAS E HOMEOPATICAS) PARA USO VETERINARIO; FABRICACAO DE",
  },
  { cnae: "2122000", descricao: "TONICOS VETERINARIOS; FABRICACAO DE" },
  {
    cnae: "2122000",
    descricao: "ANTITOXICOS SULFAS NITROFURANOS VETERINARIOS; FABRICACAO DE",
  },
  { cnae: "2122000", descricao: "FORTIFICANTES VETERINARIOS; FABRICACAO DE" },
  { cnae: "2122000", descricao: "CARRAPATICIDAS; FABRICACAO DE" },
  {
    cnae: "2122000",
    descricao:
      "VACINAS PARA BOVINOS (CARBUNCULO SINTOMATICO, BOTULISMO, CARBUNCULO HEMATICO, PENEUMOENTERITE, ETC); FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE LOVASTATINA, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "VACINAS PARA MEDICINA VETERINARIA; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE ENZIMAS, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE OUTRAS LINCOSAMIDAS E SEUS DERIVADOS, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE OUTROS ALCALOIDES E SEUS DERIVADOS, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE ACIDOS NUCLEICOS E SEUS SAIS OUTROS HETEROCICLICOS, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "PREPARACOES QUIMICAS CONTRACEPTIVAS A BASE DE HORMONIOS OU DE ESPERMICIDAS, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "ANTIBACTERIANOS VETERINARIOS (INJETAVEIS, ORAIS, INTRAMAMARIOS, TOPICOS, INTRA-UTERINOS); FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "COCCIDICIDAS / COCCIDIOSTATICOS VETERINARIOS; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "ECTOPARASITICIDAS VETERINARIAS; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS OU PREPARACOES COM PRIORIDADES ANTISSEPTICAS, DESINFETANTES, BACTERICIDAS, ETC, PARA USO VETERINARIO; FABRICACAO DE",
  },
  { cnae: "2122000", descricao: "ANTIMAMITICOS VETERINARIOS; FABRICACAO DE" },
  {
    cnae: "2122000",
    descricao: "VACINAS PARA PEQUENOS ANIMAIS; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "ENDOPARASITICIDAS VETERINARIAS; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "MEDICAMENTOS VITAMINICOS VETERINARIOS; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "ANTI-HELMINTICOS VETERINARIOS; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS CONTENDO PRODUTOS MISTURADOS OU NAO MISTURADOS, NAO ESPECIFICADOS (USO VERINARIO); FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "ANTIBIOTICOS VETERINARIOS (ORAL E INJETAVEL); FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS PARA USO VETERINARIO A BASE DE VITAMINAS, EXCETO VITAMINA A, VITAMINA C (ACIDO ASCORBICO) E VITAMINA E (TOCOFEROL); FABRICACAO DE",
  },
  { cnae: "2122000", descricao: "TERAPEUTICOS VETERINARIOS; FABRICACAO DE" },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE ALCALOIDES OU SEUS DERIVADOS (USO VETERINARIO); FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "ANTIVERMINOSOS PARA USO VETERINARIO; FABRICACAO DE",
  },
  { cnae: "2122000", descricao: "VACINA CONTRA RAIVA; FABRICACAO DE" },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE CEFTRIAXONA, PARA USO VETERINARIOS; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE INSULINA MAS NAO CONTENDO ANTIBIOTICOS, PARA USO VETERINARIOS; FABRICACAO DE",
  },
  { cnae: "2122000", descricao: "LARVICIDAS; FABRICACAO DE" },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE NIMESULIDA, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE CAPTOPRIL, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "PRODUTOS HOMEOPATICOS PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE LORATADINA, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE COMPOSTOS DAS FUNCOES CARBOXIAMIDA E AMIDA DO ACIDO CARBONICO PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE OUTRAS PENICILINAS, PARA USO VETERINARIO; FABRICACAO DE",
  },
  { cnae: "2122000", descricao: "SARNICIDAS; FABRICACAO DE" },
  { cnae: "2122000", descricao: "BERNICIDAS; FABRICACAO DE" },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE OXITETRACICLINA, PARA USO VETERINARIO; FABRICACAO DE",
  },
  { cnae: "2122000", descricao: "VACINAS PARA EQUINOS; FABRICACAO DE" },
  {
    cnae: "2122000",
    descricao:
      "SUPLEMENTOS ALIMENTARES PROMOTORES DO CRESCIMENTO, VITAMINICOS E MINERAIS; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE OUTROS ANTIBIOTICOS, PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE COMPOSTOS HETEROCICLICOS EXCLUSIVAMENTE DE HETEROATOMOS DE NITROGENIO, PARA USO VETERINARIO; FABRICACAO DE",
  },
  { cnae: "2122000", descricao: "ANTICOCCIDIANOS VETERINARIOS; FABRICACAO DE" },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE ESTREPTOMICINAS E SEUS DERIVADOS, PARA USO VETERINARIO; FABRICACAO DE",
  },
  { cnae: "2122000", descricao: "VACINA CONTRA AFTOSA; FABRICACAO DE" },
  {
    cnae: "2122000",
    descricao:
      "MEDICAMENTOS A BASE DE AMPLICILINA, PARA USO VETERINARIOS; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "PRODUTOS ALOPATICOS PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "ADITIVOS MEDICAMENTOSOS PARA RACAO ANIMAL; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "VERMIFUGOS PARA USO VETERINARIO; FABRICACAO DE",
  },
  {
    cnae: "2122000",
    descricao: "ANTIPARASITARIOS VETERINARIOS; FABRICACAO DE",
  },
  { cnae: "21238", descricao: "FABRICACAO DE PREPARACOES FARMACEUTICAS" },
  {
    cnae: "2123800",
    descricao:
      "PASTAS (OUATES), GAZES, ATADURAS E ARTIGOS SEMELHANTES, IMPREGNADOS OU RECOBERTOS COM QUALQUER SUBSTANCIA; FABRICACAO DE",
  },
  { cnae: "2123800", descricao: "TINTURAS E ALCOOLATURAS; FABRICACAO DE" },
  {
    cnae: "2123800",
    descricao:
      "CURATIVOS IMPREGNADOS COM SUBSTANCIAS MEDICINAIS; FABRICACAO DE",
  },
  {
    cnae: "2123800",
    descricao:
      "GAZES, ATADURAS, EMPLASTROS, CATAPLASMAS E ARTIGOS SEMELHANTES, IMPREGNADOS OU RECOBERTOS DE SUBSTANCIAS FARMACEUTICAS; FABRICACAO DE",
  },
  {
    cnae: "2123800",
    descricao: "PRODUTOS MEDICINAIS NAO TERAPEUTICOS; FABRICACAO DE",
  },
  { cnae: "2123800", descricao: "AGUA BORICADA; FABRICACAO DE" },
  { cnae: "2123800", descricao: "KITS DIAGNOSTICOS; FABRICACAO DE" },
  { cnae: "2123800", descricao: "CURATIVOS; FABRICACAO DE" },
  {
    cnae: "2123800",
    descricao:
      "ADESIVOS E OUTROS MATERIAIS ESTERILIZADOS PARA SUTURAS CIRURGICAS; FABRICACAO DE",
  },
  {
    cnae: "2123800",
    descricao: "SOLUCOES PARA LENTES DE CONTATO; FABRICACAO DE",
  },
  {
    cnae: "2123800",
    descricao: "CIMENTOS PARA RECONSTRUCAO DE OSSOS; FABRICACAO DE",
  },
  {
    cnae: "2123800",
    descricao:
      "ESTOJOS E CAIXAS DE PRIMEIROS SOCORROS GUARNECIDAS (COM GAZE, CURATIVOS, ETC.); FABRICACAO DE",
  },
  {
    cnae: "2123800",
    descricao: "SUBSTANCIAS RADIOATIVAS PARA DIAGNOSTICO; FABRICACAO DE",
  },
  { cnae: "2123800", descricao: "ANTI-SEPTICOS CIRURGICOS; FABRICACAO DE" },
  {
    cnae: "2123800",
    descricao:
      "PREPARACOES OPACIFICANTES PARA EXAMES RADIOGRAFICOS (PARA APLICACAO NO PACIENTE); FABRICACAO DE",
  },
  { cnae: "2123800", descricao: "AGUA OXIGENADA; FABRICACAO DE" },
  {
    cnae: "2123800",
    descricao:
      "ESPARADRAPOS, IMPREGNADOS OU NAO COM SUBSTANCIAS MEDICINAIS; FABRICACAO DE",
  },
  {
    cnae: "2123800",
    descricao:
      "CURATIVOS LIQUIDOS (AGUA OXIGENADA, TINTURA DE IODO, ETC), FABRICACAO DE",
  },
  {
    cnae: "2123800",
    descricao: "CURATIVOS CIRURGICOS PREPARADOS; FABRICACAO DE",
  },
  {
    cnae: "2123800",
    descricao:
      "ALGODAO HIDROFILO IMPREGNADO COM QUALQUER SUBSTANCIA; FABRICACAO DE",
  },
  {
    cnae: "2123800",
    descricao:
      "REAGENTES DESTINADOS A DETERMINACAO DE GRUPOS OU DE FATORES SANGUINEOS; FABRICACAO DE",
  },
  { cnae: "2123800", descricao: "CONTRASTES RADIOLOGICOS; FABRICACAO DE" },
  {
    cnae: "2123800",
    descricao:
      "PRODUTOS RADIOLOGICOS, EXCETO PLACAS FOTOGRAFICAS PARA RADIOGRAFIAS; FABRICACAO DE",
  },
  {
    cnae: "2123800",
    descricao:
      "REAGENTES PARA ANALISES CLINICAS E LABORATORIAIS; FABRICACAO DE",
  },
  { cnae: "2123800", descricao: "TINTURA DE IODO; FABRICACAO DE" },
  {
    cnae: "2123800",
    descricao: "MATERIAIS PARA MEDICINA OU CIRURGIA, N.E.; FABRICACAO DE",
  },
  { cnae: "2123800", descricao: "AGENTES DIAGNOSTICOS; FABRICACAO DE" },
  {
    cnae: "2123800",
    descricao:
      "CURATIVOS ADESIVOS PREPARADOS, IMPREGNADOS COM SUBSTANCIAS FARMACEUTICAS; FABRICACAO DE",
  },
  {
    cnae: "2123800",
    descricao:
      "REAGENTES DE DIAGNOSTICO CONCEBIDOS PARA SEREM ADMINISTRADOS AO PACIENTE; FABRICACAO DE",
  },
  {
    cnae: "2123800",
    descricao: "CIMENTOS PARA RECONSTITUICAO OSSEA; FABRICACAO DE",
  },
  {
    cnae: "2123800",
    descricao:
      "PENSOS ADESIVOS E OUTROS ARTIGOS COM UMA CAMADA ADESIVA, IMPREGNADOS OU RECOBERTOS COM QUALQUER SUBSTANCIA; FABRICACAO DE",
  },
  { cnae: "22111", descricao: "FABRICACAO DE PNEUMATICOS E DE CAMARASDEAR" },
  {
    cnae: "2211100",
    descricao: "PNEUS PARA TRATORES E MAQUINAS; FABRICACAO DE",
  },
  { cnae: "2211100", descricao: "CAMARAS-DE-AR PARA PNEUS; FABRICACAO DE" },
  {
    cnae: "2211100",
    descricao:
      "PROTETORES, BANDAS DE RODAGEM AMOVIVEIS PARA PNEUMATICOS (CAMELBACKS) E FLAPS DE BORRACHA; FABRICACAO DE",
  },
  {
    cnae: "2211100",
    descricao:
      "PARTES DE PNEUS (BORRACHA DE LIGACAO, CAMELBACKS, ETC) FABRICACAO DE",
  },
  {
    cnae: "2211100",
    descricao:
      "CAMARAS-DE-AR PARA PNEUS DE VEICULOS OU DE MAQUINAS; FABRICACAO DE",
  },
  {
    cnae: "2211100",
    descricao:
      "PNEUS PARA AUTOMOVEIS, CAMINHOES, ONIBUS, AERONAVES, BICICLETAS, MOTOCICLETAS E OUTROS VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "2211100",
    descricao: "PNEUMATICOS PARA TRATORES E MAQUINAS; FABRICACAO DE",
  },
  {
    cnae: "2211100",
    descricao:
      "CAMARAS-DE-AR PARA PNEUMATICOS DE AUTOMOVEIS, CAMINHOES, ONIBUS, AERONAVES, MOTOCICLETAS, BICICLETAS E OUTROS VEICULOS; FABRICACAO DE",
  },
  { cnae: "2211100", descricao: "PNEUMATICOS PARA VEICULOS; FABRICACAO DE" },
  { cnae: "2211100", descricao: "PNEUS; FABRICACAO DE" },
  { cnae: "22129", descricao: "REFORMA DE PNEUMATICOS USADOS" },
  {
    cnae: "2212900",
    descricao:
      "PNEUS (RECONDICIONAMENTO, RECAUCHUTAGEM, RECAPAGEM OU REMOLDAGEM); SERVICO DE",
  },
  {
    cnae: "2212900",
    descricao: "PNEUS RECAPADOS, RECAUCHUTADOS OU REMOLDADOS; FABRICACAO DE",
  },
  { cnae: "2212900", descricao: "PNEUS RECAUCHUTADOS; PRODUCAO DE" },
  {
    cnae: "2212900",
    descricao:
      "PNEUMATICOS RECAPADOS, RECAUCHUTADOS OU REMOLDADOS; FABRICACAO DE",
  },
  {
    cnae: "22196",
    descricao:
      "FABRICACAO DE ARTEFATOS DE BORRACHA NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "2219600",
    descricao: "ARTEFATOS DE BORRACHA PARA USO DOMESTICO, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao: "ARTIGOS DIVERSOS DE BORRACHA ENDURECIDA; FABRICACAO DE",
  },
  { cnae: "2221800", descricao: "FILMES DE POLIETILENO; FABRICACAO DE" },
  {
    cnae: "2219600",
    descricao:
      "PECAS E ACESSORIOS DE BORRACHA VULCANIZADA NAO ENDURECIDA PARA MAQUINAS E APARELHOS; FABRICACAO DE",
  },
  { cnae: "2219600", descricao: "ESPUMA DE BORRACHA; FABRICACAO DE" },
  {
    cnae: "2219600",
    descricao:
      "BORRACHA MISTURADA, NAO VULCANIZADA, EM FORMAS PRIMARIAS OU EM CHAPAS, FOLHAS OU TIRAS; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao: "ARTEFATOS DE BORRACHA PARA USO PESSOAL, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "BORRACHA ENDURECIDA (POR EXEMPLO EBONITE) EM BARRAS, PERFIS, TUBOS, CHAPAS, MASSAS, BLOCOS OU FORMAS SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "2219600", descricao: "ARTESANATO EM BORRACHA; FABRICACAO DE" },
  {
    cnae: "2219600",
    descricao:
      "PECAS E ACESSORIOS DE BORRACHA PARA USOS INDUSTRIAIS DIVERSOS; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "AMORTECEDORES, GAXETAS, RETENTORES, SAPATAS, ETC, DE BORRACHA; FABRICACAO DE",
  },
  { cnae: "2219600", descricao: "PRESERVATIVOS; FABRICACAO DE" },
  {
    cnae: "2219600",
    descricao:
      "TUBOS, CANOS E MANGUEIRAS DE BORRACHA VULCANIZADA NAO ENDURECIDA, NAO REFORCADOS, COM OU SEM ACESSORIOS, INCLUSIVE MANGUEIRAS PARA VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao: "BICOS DE BORRACHA PARA MAMADEIRA; FABRICACAO DE",
  },
  { cnae: "2219600", descricao: "MOLDES DE BORRACHA; FABRICACAO DE" },
  {
    cnae: "2219600",
    descricao: "BORRACHA PARA LIMPADOR DE PARA-BRISAS; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "TECIDO COM A BORRACHA COMO COMPONENTE PRINCIPAL (IMPREGNADO, COBERTO OU LAMINADO); FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "VARETAS E PERFIS DE BORRACHA VULCANIZADA NAO ENDURECIDA; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao: "ARTIGOS DE ELASTOMEROS VULCANIZADOS; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao: "DESENTUPIDORES DE BORRACHA PARA PIAS; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao: "ARTEFATOS DE ESPUMA DE BORRACHA; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "BORRACHA VEGETAL (LAVAGEM, CENTRIFUGACAO, PRENSAGEM EM BLOCOS, GRANULACAO E OUTROS BENEFICIAMENTOS); SERVICO DE",
  },
  { cnae: "2219600", descricao: "REVESTIMENTOS DE CILINDROS; FABRICACAO DE" },
  {
    cnae: "2219600",
    descricao: "COLCHOES INFLAVEIS DE BORRACHA; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "CHAPAS, LAMINAS, LENCOIS OU PLACAS DE BORRACHA NATURAL OU SINTETICA, MICROPOROSAS OU NAO; FABRICACAO DE",
  },
  { cnae: "2219600", descricao: "ESPONJAS DE LATEX; FABRICACAO DE" },
  {
    cnae: "2219600",
    descricao:
      "TUBOS, CANOS E MANGUEIRAS DE BORRACHA VULCANIZADA NAO ENDURECIDA, REFORCADOS COM MATERIAS TEXTEIS, COM OU SEM ACESSORIOS, INCLUSIVE MANGUEIRAS PARA VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "CORREIAS TRANSPORTADORAS DE BORRACHA VULCANIZADA, REFORCADAS COM METAL, PLASTICO OU OUTRO MATERIAL; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "PECAS E ACESSORIOS DE BORRACHA PARA A INDUSTRIA DE MATERIAL DE TRANSPORTE; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "PECAS E ACESSORIOS DE BORRACHA PARA A INDUSTRIA MECANICA; FABRICACAO DE",
  },
  { cnae: "2219600", descricao: "PASSADEIRAS DE BORRACHA; FABRICACAO DE" },
  { cnae: "2219600", descricao: "FIOS DE BORRACHA; FABRICACAO DE" },
  {
    cnae: "2219600",
    descricao:
      "MATERIAIS PARA REPARACAO DE CAMARAS-DE-AR E OUTROS ARTIGOS DE BORRACHA; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "ARTIGOS DE BORRACHA PARA HIGIENE E FARMACIA, N.E.; FABRICACAO DE",
  },
  { cnae: "2219600", descricao: "LAMINADOS DE BORRACHA; FABRICACAO DE" },
  {
    cnae: "2219600",
    descricao:
      "CANOS, TUBOS, MANGUEIRAS E MANGOTES DE BORRACHA PARA VEICULOS, MAQUINAS E APARELHOS; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "ARTIGOS DE BORRACHA VULCANIZADA PARA USOS NAO ESPECIFICADOS; FABRICACAO DE",
  },
  { cnae: "2219600", descricao: "PERFIS DE BORRACHA; FABRICACAO DE" },
  {
    cnae: "2219600",
    descricao:
      "VARETAS, TUBOS, DISCOS E ARRUELAS DE BORRACHA NAO VULCANIZADA; FABRICACAO DE",
  },
  { cnae: "2219600", descricao: "PISOS DE BORRACHA; FABRICACAO DE" },
  {
    cnae: "2219600",
    descricao:
      "CORREIAS E ESTEIRAS DE BORRACHA PARA MAQUINAS (PLANAS, CILINDRICAS, TRAPEZOIDAIS E SEMELHANTES); FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "RESIDUOS, DESPERDICIOS E APARAS DE BORRACHA NAO ENDURECIDA, MESMO REDUZIDOS A PO OU A GRANULOS; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao: "REVESTIMENTOS DE BORRACHA PARA PAVIMENTOS; FABRICACAO DE",
  },
  { cnae: "2219600", descricao: "TUBOS DE BORRACHA; FABRICACAO DE" },
  {
    cnae: "2219600",
    descricao:
      "TUBOS, CANOS E MANGUEIRAS DE BORRACHA VULCANIZADA NAO ENDURECIDA, REFORCADOS COM OUTRAS MATERIAS (EXCETO TEXTEIS), COM OU SEM ACESSORIOS; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao: "BOTAS OU GALOCHAS DE BORRACHA; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "PECAS E ACESSORIOS DE BORRACHA VULCANIZADA NAO ENDURECIDA, PARA VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "BUCHAS, GAXETAS, POLIAS, SAPATAS, RETENTORES, ETC, DE BORRACHA; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "BLOCOS, CHAPAS, FOLHAS E TIRAS DE BORRACHA VULCANIZADA NAO ENDURECIDA; FABRICACAO DE",
  },
  { cnae: "2219600", descricao: "CHUPETAS DE BORRACHA; FABRICACAO DE" },
  {
    cnae: "2219600",
    descricao: "BORRACHA NATURAL E SINTETICA; BENEFICIAMENTO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "PECAS E ACESSORIOS DE BORRACHA PARA A INDUSTRIA DE MATERIAL ELETRICO E ELETRONICO; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao: "TAPETES (CAPACHOS) DE BORRACHA; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao: "ARTEFATOS DE BORRACHA PARA USO PELAS INDUSTRIAS; FABRICACAO DE",
  },
  { cnae: "2219600", descricao: "CONEXOES DE BORRACHA; FABRICACAO DE" },
  {
    cnae: "2219600",
    descricao:
      "CANOS, TUBOS, MANGUEIRAS E MANGOTES DE BORRACHA PARA AGUA, AR, GAS, GASOLINA, ETC; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "BORRACHA VEGETAL, SOLIDA, BENEFICIADA (LAVAGEM, CENTRIFUGACAO, PRENSAGEM EM BLOCOS, GRANULACAO, ETC); FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "LATEX VEGETAL: CENTRIFUGACAO, COAGULACAO, VULCANIZACAO E OUTROS BENEFICIAMENTOS DE",
  },
  { cnae: "2219600", descricao: "TECIDOS COM BORRACHA; FABRICACAO DE" },
  {
    cnae: "2219600",
    descricao: "CORREIAS DE BORRACHA PARA VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "BORRACHA REGENERADA, EM FORMAS PRIMARIAS OU EM CHAPAS, FOLHAS OU TIRAS; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao:
      "JUNTAS, GAXETAS E SEMELHANTES DE BORRACHA VULCANIZADA NAO ENDURECIDA (PARA VEICULOS, MAQUINAS, ETC.); FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao: "CAMARAS-DE-AR PARA BOLAS ESPORTIVAS; FABRICACAO DE",
  },
  {
    cnae: "2219600",
    descricao: "CORREIAS DE TRANSMISSAO DE BORRACHA VULCANIZADA; FABRICACAO DE",
  },
  {
    cnae: "22218",
    descricao:
      "FABRICACAO DE LAMINADOS PLANOS E TUBULARES DE MATERIAL PLASTICO",
  },
  {
    cnae: "2221800",
    descricao:
      "FITA RAFIA DE POLIPROPILENO, POLIETILENO E OUTROS, FABRICACAO DE",
  },
  {
    cnae: "2221800",
    descricao: "MATERIAL SINTETICO (COURO SINTETICO), FABRICACAO DE",
  },
  {
    cnae: "2221800",
    descricao:
      "PELICULAS AUTOADESIVAS DE PLASTICOS, MESMO EM ROLOS; FABRICACAO DE",
  },
  { cnae: "2221800", descricao: "ESPUMAS DE POLIURETANO; FABRICACAO DE" },
  {
    cnae: "2221800",
    descricao: "FITAS GOMADAS PARA VEDACAO HIDRAULICA; FABRICACAO DE",
  },
  {
    cnae: "2221800",
    descricao:
      "FLMES (PELICULAS) DE MATERIAL PLASTICO, INCLUSIVE BOPP PARA EMBALAGEM, MESMO IMPRESSOS, METALIZADOS OU TRABALHADOS POR OUTRO PROCESSO, INCLUSIVE EM BOBINAS; FABRICACAO DE",
  },
  {
    cnae: "2221800",
    descricao: "PLASTICOS EM LENCOL, ESTAMPADOS (FILMES); FABRICACAO DE",
  },
  { cnae: "2221800", descricao: "FIOS DE PLASTICO; FABRICACAO DE" },
  {
    cnae: "2221800",
    descricao: "PELICULA DE CONTROLE VISUAL E SOLAR; FABRICACAO DE",
  },
  { cnae: "2221800", descricao: "TRIPAS ARTIFICIAIS, FABRICACAO DE" },
  {
    cnae: "2221800",
    descricao: "LAMINADOS DE ESPUMA DE PLASTICO EXPANDIDO; FABRICACAO DE",
  },
  {
    cnae: "2221800",
    descricao: "FITAS ADESIVAS DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2221800",
    descricao: "LAMINADOS TUBULARES DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  { cnae: "2221800", descricao: "TECIDOS ADESIVOS DE PLASTICO; FABRICACAO DE" },
  {
    cnae: "2221800",
    descricao:
      "FILMES TUBULARES DE PLASTICO PARA CONFECCAO DE EMBALAGENS EM GERAL; FABRICACAO DE",
  },
  {
    cnae: "2221800",
    descricao:
      "TIRAS OU FITAS AUTOADESIVAS DE PLASTICOS, MESMO EM ROLOS (FITA ISOLANTE); FABRICACAO DE",
  },
  { cnae: "2221800", descricao: "ACRILICO EM FORMAS PRIMARIAS; FABRICACAO DE" },
  {
    cnae: "2221800",
    descricao: "CHAPAS, FILMES E PELICULAS EM ACRILICO; FABRICACAO DE",
  },
  {
    cnae: "2221800",
    descricao:
      "FILMES (PELICULAS) DE MATERIAL PLASTICO (EXCETO BOPP) PARA EMBALAGEM, MESMO IMPRESSOS, METALIZADOS OU TRABALHADOS POR OUTRO PROCESSO, INCLUSIVE EM BOBINAS OU ROLOS; FABRICACAO DE",
  },
  {
    cnae: "2221800",
    descricao: "LAMINADOS PLANOS DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2221800",
    descricao: "FITAS DE PLASTICO, INCLUSIVE ADESIVAS; FABRICACAO DE",
  },
  {
    cnae: "2221800",
    descricao: "FORMAS PLANAS DE PLASTICO, N.E.; FABRICACAO DE",
  },
  { cnae: "2221800", descricao: "PLASTICOS EM LENCOL (FILMES); FABRICACAO DE" },
  { cnae: "2221800", descricao: "CORDOALHAS DE PLASTICO; FABRICACAO DE" },
  { cnae: "2221800", descricao: "TECIDOS DE PLASTICO LAMINADO; FABRICACAO DE" },
  { cnae: "2221800", descricao: "BLOCOS DE PLASTICO, N.E.; FABRICACAO DE" },
  {
    cnae: "2221800",
    descricao:
      "BLOCOS DE ESPUMA DE PLASTICO EXPANDIDO (POLIURETANO, POLIESTIRENO E OUTROS); FABRICACAO DE",
  },
  {
    cnae: "2221800",
    descricao: "FILME DE POLIPROPILENO BI-ORIENTADO (BOPP); FABRICACAO DE",
  },
  { cnae: "22226", descricao: "FABRICACAO DE EMBALAGENS DE MATERIAL PLASTICO" },
  {
    cnae: "2222600",
    descricao: "BOTIJOES DE PLASTICO PARA EMBALAGEM; FABRICACAO DE",
  },
  {
    cnae: "2222600",
    descricao:
      "CAIXAS, CAIXOTES E SEMELHANTES DE PLASTICO, PARA EMBALAGENS; FABRICACAO DE",
  },
  {
    cnae: "2222600",
    descricao:
      "BARRIS OU TAMBORES DE PLASTICO PARA EMBALAGEM, REFORCADOS OU NAO; FABRICACAO DE",
  },
  { cnae: "2222600", descricao: "SACOLAS DE MATERIAL PLASTICO; FABRICACAO DE" },
  { cnae: "2222600", descricao: "EMBALAGENS DE ISOPOR; FABRICACAO DE" },
  { cnae: "2222600", descricao: "EMBALAGENS DE PLASTICO; FABRICACAO DE" },
  {
    cnae: "2222600",
    descricao:
      "GARRAFOES, GARRAFAS E ARTIGOS SEMELHANTES DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2222600",
    descricao:
      "CARRETEIS, FUSOS E SUPORTES SEMELHANTES DE PLASTICO; FABRICACAO DE",
  },
  { cnae: "2222600", descricao: "GARRAFAS DE PLASTICO; FABRICACAO DE" },
  {
    cnae: "2222600",
    descricao:
      "EMBALAGENS DE PLASTICO NAO IMPRESSAS PARA PRODUTOS DE PERFUMARIA, HIGIENE E LIMPEZA; FABRICACAO DE",
  },
  { cnae: "2222600", descricao: "ROLHAS E TAMPAS DE PLASTICO; FABRICACAO DE" },
  {
    cnae: "2222600",
    descricao: "SACOS DE MATERIAL PLASTICO PARA LIXO; FABRICACAO DE",
  },
  {
    cnae: "2222600",
    descricao:
      "EMBALAGENS DE PLASTICO PARA PRODUTOS FARMACEUTICOS; FABRICACAO DE",
  },
  {
    cnae: "2222600",
    descricao:
      "EMBALAGENS DE MATERIAL PLASTICO (POLIETILENO, PVC E SEMELHANTES); FABRICACAO DE",
  },
  {
    cnae: "2222600",
    descricao: "CARTUCHOS DE MATERIAL PLASTICO PARA EMBALAGENS; FABRICACAO DE",
  },
  {
    cnae: "2222600",
    descricao:
      "TAMPAS E CAPSULAS E OUTROS DISPOSITIVOS DE PLASTICO, PARA FECHAR RECIPIENTES; FABRICACAO DE",
  },
  { cnae: "2222600", descricao: "SACOS DE PLASTICO; FABRICACAO DE" },
  {
    cnae: "2222600",
    descricao: "BUJOES DE PLASTICO PARA EMBALAGEM; FABRICACAO DE",
  },
  {
    cnae: "2222600",
    descricao:
      "PRE-FORMAS PET (ESBOCOS) DE EMBALAGENS PLASTICAS; FABRICACAO DE",
  },
  { cnae: "2222600", descricao: "FRASCOS DE PLASTICO; FABRICACAO DE" },
  {
    cnae: "2222600",
    descricao:
      "ENGRADADOS PLASTICOS PARA ACONDICIONAMENTO DE GARRAFAS; FABRICACAO DE",
  },
  {
    cnae: "2222600",
    descricao:
      "CAIXAS E ESTOJOS DE MATERIAL PLASTICO PARA EMBALAGEM; FABRICACAO DE",
  },
  {
    cnae: "2222600",
    descricao:
      "EMBALAGENS DE PLASTICO PARA PRODUTOS ALIMENTICIOS; FABRICACAO DE",
  },
  {
    cnae: "22234",
    descricao:
      "FABRICACAO DE TUBOS E ACESSORIOS DE MATERIAL PLASTICO PARA USO NA CONSTRUCAO",
  },
  {
    cnae: "2223400",
    descricao:
      "CANOS DE MATERIAL PLASTICO, INCLUSIVE COM REFORCO, PARA USO NA CONSTRUCAO CIVIL; FABRICACAO DE",
  },
  {
    cnae: "2223400",
    descricao:
      "MANILHAS DE MATERIAL PLASTICO, INCLUSIVE COM REFORCO DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  { cnae: "2223400", descricao: "TUBOS DE PVC; FABRICACAO DE" },
  {
    cnae: "2223400",
    descricao: "SOLDA (SOLDAGEM) EM TUBOS PLASTICOS; SERVICOS DE",
  },
  {
    cnae: "2223400",
    descricao:
      "TUBOS E SEUS ACESSORIOS (JUNTAS, COTOVELOS, FLANGES, UNIOES) DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2223400",
    descricao:
      "TUBOS DE MATERIAL PLASTICO, INCLUSIVE COM REFORCO, PARA CONSTRUCAO CIVIL; FABRICACAO DE",
  },
  {
    cnae: "2223400",
    descricao:
      "CONEXOES DE MATERIAL PLASTICO PARA USO NA CONSTRUCAO; FABRICACAO DE",
  },
  {
    cnae: "22293",
    descricao:
      "FABRICACAO DE ARTEFATOS DE MATERIAL PLASTICO NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "2229301",
    descricao:
      "CONJUNTO (JOGO OU APARELHO) PARA JANTAR, CAFE, CHA, ETC, DE PLASTICO; FABRICACAO DE",
  },
  { cnae: "2229301", descricao: "CESTO DE PLASTICO PARA LIXO; FABRICACAO DE" },
  {
    cnae: "2229301",
    descricao:
      "UTENSILIOS DE MATERIAL PLASTICO PARA USO PESSOAL; FABRICACAO DE",
  },
  {
    cnae: "2229301",
    descricao: "SALEIROS E PALITEIROS DE PLASTICO; FABRICACAO DE",
  },
  { cnae: "2229301", descricao: "TRAVESSAS DE PLASTICO; FABRICACAO DE" },
  {
    cnae: "2229301",
    descricao:
      "ESTATUETAS E OUTROS ARTEFATOS PARA DECORACAO, DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2229301",
    descricao:
      "ARTEFATOS DE MATERIAL PLASTICO PARA USO PESSOAL OU DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2229301",
    descricao:
      "ARTIGOS DE MATERIAL PLASTICO PARA MESA, COPA, COZINHA E OUTROS USOS DOMESTICOS; FABRICACAO DE",
  },
  {
    cnae: "2229301",
    descricao: "BALDES PARA GELO, DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2229301",
    descricao: "MAMADEIRAS COMPLETAS DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  { cnae: "2229301", descricao: "PORTA DOCUMENTOS DE PLASTICO; FABRICACAO DE" },
  {
    cnae: "2229301",
    descricao: "ESPREMEDORES DE FRUTAS, DE PLASTICO; FABRICACAO DE",
  },
  { cnae: "2229301", descricao: "CARTEIRA DE PLASTICO; FABRICACAO DE" },
  { cnae: "2229301", descricao: "PORTA-FITA (PLASTICO); FABRICACAO DE" },
  {
    cnae: "2229301",
    descricao: "CUPULA DE PLASTICO PARA ABAJUR; FABRICACAO DE",
  },
  {
    cnae: "2229301",
    descricao:
      "ASSENTOS E TAMPOS DE MATERIAL PLASTICO PARA VASOS SANITARIOS, INCLUSIVE REFORCADO, FABRICACAO DE",
  },
  { cnae: "2229301", descricao: "TAPETES DE PLASTICO; FABRICACAO DE" },
  {
    cnae: "2229301",
    descricao: "CANECAS OU XICARAS DE PLASTICO; FABRICACAO DE",
  },
  { cnae: "2229301", descricao: "LIXEIRA DE PLASTICO PARA PIA; FABRICACAO DE" },
  { cnae: "2229301", descricao: "PORTA-CD (PLASTICO); FABRICACAO DE" },
  {
    cnae: "2229301",
    descricao: "PECAS DE PLASTICO PARA MESA OU COZINHA; FABRICACAO DE",
  },
  {
    cnae: "2229301",
    descricao: "ARTIGOS DE PLASTICO PARA HIGIENE E TOUCADOR; FABRICACAO DE",
  },
  { cnae: "2229301", descricao: "NHOQUEIRA DE PLASTICO; FABRICACAO DE" },
  {
    cnae: "2229301",
    descricao:
      "UTENSILIOS DE MATERIAL PLASTICO PARA USO DOMESTICO; FABRICACAO DE",
  },
  { cnae: "2229301", descricao: "CESTO DE PLASTICO PARA ROUPA; FABRICACAO DE" },
  {
    cnae: "2229302",
    descricao:
      "PECAS E ACESSORIOS DE MATERIAL PLASTICO PARA A INDUSTRIA DE TRANSPORTE; FABRICACAO DE",
  },
  {
    cnae: "2229302",
    descricao:
      "REDES TUBULARES DE PLASTICO PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  { cnae: "8640212", descricao: "UNIDADES DE COLETA DE SANGUE" },
  {
    cnae: "2229302",
    descricao:
      "ARTEFATOS DE MATERIAL PLASTICO, REFORCADOS OU NAO, PARA A INDUSTRIA DE TRANSPORTE; FABRICACAO DE",
  },
  { cnae: "2229302", descricao: "INJECAO DE PECAS PLASTICAS" },
  { cnae: "2229302", descricao: "PECAS TECNICAS EM ACRILICO, FABRICACAO DE" },
  {
    cnae: "2229302",
    descricao:
      "CAIXAS DE MATERIAL PLASTICO OU SINTETICO PARA BATERIAS; FABRICACAO DE",
  },
  {
    cnae: "2229302",
    descricao:
      "ARTEFATOS DE MATERIAL PLASTICO, REFORCADOS OU NAO, PARA A INDUSTRIA DE MATERIAL ELETRICO E ELETRONICO; FABRICACAO DE",
  },
  {
    cnae: "2229302",
    descricao: "PUXADORES DE PLASTICO PARA MOVEIS; FABRICACAO DE",
  },
  {
    cnae: "2229302",
    descricao:
      "ARTEFATOS DE ESPUMA SINTETICA DE PLASTICO EXPANDIDO PARA APLICACOES INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2229302",
    descricao:
      "ARTEFATOS PLASTICOS, REFORCADOS OU NAO, PARA A INDUSTRIA MECANICA; FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao:
      "ELEMENTOS ESTRUTURAIS DE PLASTICO, REFORCADO OU NAO, UTILIZADOS EM PISOS, PAREDES, TELHADOS, ETC; FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao:
      "SANCAS, FORROS E COBERTURAS DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao:
      "ESQUADRIAS DE MATERIAL PLASTICO, INCLUSIVE COM REFORCO DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao:
      "CAIXAS DE AGUA DE MATERIAL PLASTICO REFORCADO COM FIBRA DE VIDRO; FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao: "REVESTIMENTOS DE PAVIMENTOS, DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao:
      "EQUIPAMENTOS HIDRAULICOS E SANITARIOS DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  { cnae: "2229303", descricao: "GUARITAS DE FIBRA DE VIDRO; FABRICACAO DE" },
  {
    cnae: "2229303",
    descricao: "CAIXAS DE DESCARGA DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  { cnae: "2229303", descricao: "MARMORE SINTETICO; FABRICACAO DE" },
  {
    cnae: "2229303",
    descricao:
      "PISCINAS DE MATERIAL PLASTICO REFORCADO COM FIBRA DE VIDRO; FABRICACAO DE",
  },
  { cnae: "2229303", descricao: "PERFIS DE MATERIAL PLASTICO; FABRICACAO DE" },
  { cnae: "2229303", descricao: "PISOS DE MATERIAL PLASTICO; FABRICACAO DE" },
  {
    cnae: "2229303",
    descricao:
      "RESERVATORIOS, CUBAS E OUTROS ARTEFATOS DE PLASTICO PARA USO NA CONSTRUCAO CIVIL; FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao: "CONDUITES E CANELETAS DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao:
      "BANHEIRAS DE PLASTICO, REFORCADAS OU NAO, INCLUSIVE COM EQUIPAMENTO PARA HIDROMASSAGEM; FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao:
      "ARTEFATOS DE MATERIAL PLASTICO, REFORCADOS OU NAO, PARA A CONSTRUCAO CIVIL; FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao:
      "LAVATORIOS (PIAS) DE PLASTICO, INCLUSIVE REFORCADO; FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao:
      "REVESTIMENTOS DE PAREDES OU DE TETOS, DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao:
      "CONSTRUCOES PRE-FABRICADAS DE PLASTICO, REFORCADO OU NAO; FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao:
      "TELHAS E CHAPAS DE MATERIAL PLASTICO PARA CONSTRUCAO; FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao: "DIVISORIAS SANFONADAS (PVC RIGIDO); FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao: "BOX PARA BANHEIRO COM ESTRUTURA EM ACRILICO; FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao: "PORTAS SANFONADAS (PVC RIGIDO); FABRICACAO DE",
  },
  {
    cnae: "2229303",
    descricao:
      "CABINE DE TELEFONE E ORELHOES DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  { cnae: "2229303", descricao: "BOX SANFONADO PARA BANHEIRO; FABRICACAO DE" },
  {
    cnae: "2229303",
    descricao:
      "ELEMENTOS ESTRUTURAIS DE PLASTICO, REFORCADO OU NAO, UTILIZADOS EM PISOS, PAREDES, TELHADOS, ETC.; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao:
      "MONOFILAMENTOS (MONOFIOS), VARAS BASTOES E PERFIS DE MATERIAS PLASTICAS, NAO REFORCADOS COM FIBRAS; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao:
      "SOLDA (SOLDAGEM) EM ARTEFATOS DE MATERIAL PLASTICOS, EXCETO TUBOS PLASTICOS; SERVICOS DE",
  },
  {
    cnae: "2229399",
    descricao: "COLHERES, GARFOS E FACAS DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao:
      "DISPLAYS (EXPOSITORES/MOSTRUARIOS) DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  { cnae: "2229399", descricao: "COBERTURA DE PISO DE VINIL; FABRICACAO DE" },
  { cnae: "2229399", descricao: "MANGUEIRAS DE PLASTICO (PVC); FABRICACAO DE" },
  {
    cnae: "2229399",
    descricao:
      "MANGUEIRAS DE PLASTICO (PVC), REFORCADAS OU NAO, COM OU SEM ACESSORIOS; FABRICACAO DE",
  },
  { cnae: "2229399", descricao: "CAIXAS E URNAS DE ACRILICO; FABRICACAO DE" },
  {
    cnae: "2229399",
    descricao: "LINOLEOS, INCLUSIVE PASSADEIRAS; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao: "SELOS DE SEGURANCA DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao: "COPOS DESCARTAVEIS DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao:
      "PECAS E ACESSORIOS DE PLASTICO, REFORCADOS OU NAO, PARA VEICULOS FERROVIARIOS E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao:
      "ARTIGOS DE PLASTICO PARA USO EM MEDICINA, EM LABORATORIO OU ARTIGOS DE FARMACIA; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao: "BANHEIRAS DE PLASTICO PARA BEBES; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao:
      "CANUDINHOS DE PLASTICO PARA REFRIGERANTES, SORVETES, ETC; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao: "ARTIGOS DESCARTAVEIS DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao: "MANGUEIRAS FLEXIVEIS DE PLASTICO (PVC); FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao: "ASSENTOS E TAMPAS DE SANITARIO, DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao:
      "MONOFILAMENTOS (MONOFIOS), VARAS, BASTOES E PERFIS DE PLASTICOS, REFORCADOS COM FIBRAS; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao: "PERSIANAS DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao:
      "FRASCOS E POTES DE PLASTICO PARA EXAMES DE LABORATORIO; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao:
      "PECAS, COMPONENTES OU ACESSORIOS DE PLASTICO, REFORCADOS OU NAO, PARA USOS NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao: "CANTONEIRAS E SIMILARES DE PLASTICO; FABRICACAO DE",
  },
  { cnae: "2229399", descricao: "COURVIM, FABRICACAO DE" },
  {
    cnae: "2229399",
    descricao:
      "DESPERDICIOS, RESIDUOS E APARAS DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  { cnae: "2229399", descricao: "ARTEFATOS DE ISOPOR; FABRICACAO DE" },
  {
    cnae: "2229399",
    descricao:
      "PASTAS DE MATERIAL PLASTICO (PARA ESCOLA, PARA DOCUMENTOS, ETC); FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao:
      "TUBOS E TUBETES FLEXIVEIS DE PLASTICOS, EXCETO PARA CONSTRUCAO CIVIL; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao: "ALBUNS DE MATERIAL PLASTICO PARA FOTOGRAFIAS; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao: "ARTIGOS DE MATERIAL PLASTICO PARA ESCRITORIO; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao:
      "ARTIGOS DIVERSOS DE PLASTICO PARA USO SANITARIO OU HIGIENICO, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao: "COLCHOES INFLAVEIS (PLASTICO); FABRICACAO DE",
  },
  { cnae: "2229399", descricao: "FLOCOS PLASTICO, FABRICACAO DE" },
  {
    cnae: "2229399",
    descricao: "ARTIGOS ESCOLARES DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao: "DISPLAYS (EXPOSITORES/MOSTRUARIOS) DE ACRILICO; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao: "COPOS, PRATOS E OUTROS ARTIGOS PLASTICO; FABRICACAO DE",
  },
  { cnae: "2229399", descricao: "TUBOS DE ACRILICO; FABRICACAO DE" },
  {
    cnae: "2229399",
    descricao:
      "CORREIAS DE TRANSMISSAO, TRANSPORTADORAS OU PARA ELEVADORES, DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao:
      "RESERVATORIOS, CAIXAS-D'AGUA (CAIXAS DE AGUA), CISTERNAS, PISCINAS E ARTEFATOS SEMELHANTES, DE PLASTICO REFORCADOS OU NAO; FABRICACAO DE",
  },
  { cnae: "2229399", descricao: "ARTEFATOS EM ACRILICO; FABRICACAO DE" },
  { cnae: "2229399", descricao: "CONGOLEOS E LINOLEOS; FABRICACAO DE" },
  {
    cnae: "2229399",
    descricao:
      "EQUIPAMENTOS PARA USO EM OSTOMIA (BOLSA PARA USO EM COLOSTOMIA, ETC); FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao:
      "MATERIAL PLASTICO; TINGIMENTO, PIGMENTACAO E OUTROS BENEFICIAMENTOS DE",
  },
  {
    cnae: "2229399",
    descricao:
      "PECAS E ACESSORIOS DE PLASTICO, REFORCADOS OU NAO, PARA VEICULOS AUTOMOTORES, MOTOCICLETAS, BICICLETAS E SIMILARES; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao: "ARTESANATO EM MATERIAL PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao:
      "PORTAS, JANELAS, ALIZARES, SOLEIRAS E SEMELHANTES, DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2229399",
    descricao: "TORNEIRAS DE PLASTICO PARA QUALQUER USO; FABRICACAO DE",
  },
  { cnae: "23117", descricao: "FABRICACAO DE VIDRO PLANO E DE SEGURANCA" },
  {
    cnae: "2311700",
    descricao:
      "VIDROS PARA FAROIS, FAROLETES E APARELHOS PARA SINALIZACAO; FABRICACAO DE",
  },
  {
    cnae: "2311700",
    descricao:
      "VIDRO ESTIRADO OU SOPRADO, EM FOLHAS, MAS NAO TRABALHADO DE OUTRO MODO; FABRICACAO DE",
  },
  { cnae: "2311700", descricao: "VIDRO PLANO; FABRICACAO DE" },
  {
    cnae: "2311700",
    descricao:
      "VIDRO FLOTADO E VIDRO DESBASTADO OU POLIDO, COM CAMADA REFLETORA OU NAO, EM CHAPAS OU FOLHAS (REFLETIVO, ESPELHADO); FABRICACAO DE",
  },
  {
    cnae: "2311700",
    descricao: "VIDROS PARA PARA-BRISAS DE VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "2311700",
    descricao:
      "VIDRO VAZADO OU LAMINADO, EM CHAPAS, FOLHAS OU PERFIS, MAS NAO TRABALHADO DE OUTRO MODO; FABRICACAO DE",
  },
  {
    cnae: "2311700",
    descricao: "VIDROS DE SEGURANCA (LAMINADO OU TEMPERADO); FABRICACAO DE",
  },
  { cnae: "23125", descricao: "FABRICACAO DE EMBALAGENS DE VIDRO" },
  { cnae: "2312500", descricao: "BOMBONAS DE VIDRO; FABRICACAO DE" },
  {
    cnae: "2312500",
    descricao: "AMPOLAS DE VIDRO PARA MEDICAMENTOS; FABRICACAO DE",
  },
  {
    cnae: "2312500",
    descricao: "ROLHAS, TAMPAS E SEMELHANTES, DE VIDRO; FABRICACAO DE",
  },
  {
    cnae: "2312500",
    descricao: "FRASCOS E POTES DE VIDRO PARA EMBALAGEM; FABRICACAO DE",
  },
  {
    cnae: "2312500",
    descricao: "BOIOES DE VIDRO PARA CONSERVA; FABRICACAO DE",
  },
  { cnae: "2312500", descricao: "VASILHAMES DE VIDRO, N.E.; FABRICACAO DE" },
  {
    cnae: "2312500",
    descricao: "GARRAFAS E GARRAFOES DE VIDRO; FABRICACAO DE",
  },
  { cnae: "2312500", descricao: "EMBALAGENS DE VIDRO; FABRICACAO DE" },
  {
    cnae: "2312500",
    descricao: "EMBALAGENS TUBULARES DE VIDRO; FABRICACAO DE",
  },
  { cnae: "23192", descricao: "FABRICACAO DE ARTIGOS DE VIDRO" },
  {
    cnae: "2319200",
    descricao:
      "VIDROS PARA RELOGIOS, VIDRO OPTICO (OTICO) E PECAS DE VIDRO SEM LAVRAR; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "PECAS AVULSAS DE VIDRO E CRISTAL PARA ISOLADORES, INTERRUPTORES, LUSTRES E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "CONTAS, IMITACOES DE PEROLAS OU DE PEDRAS PRECIOSAS OU SEMIPRECIOSAS, MICROESFERAS E ARTIGOS SEMELHANTES, DE VIDRO; FABRICACAO DE",
  },
  { cnae: "2319200", descricao: "TAMPOS DE VIDRO PARA MESA; FABRICACAO DE" },
  { cnae: "2319200", descricao: "VITRAIS; FABRICACAO DE" },
  {
    cnae: "2319200",
    descricao:
      "AMPOLAS DE VIDRO PARA GARRAFAS E OUTROS RECIPIENTES TERMICOS; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "ARTIGOS DE VIDRO PARA MESA, COZINHA E OUTROS USOS DOMESTICOS; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao: "ESPELHOS DE VIDRO PARA MOVEIS, MOLDURAS E AFINS; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao: "COPOS DE VIDRO PARA LIQUIDIFICADORES; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "CONTAS, VIDRILHOS, MISSANGAS E LANTEJOULAS DE VIDRO E CRISTAL; FABRICACAO DE",
  },
  { cnae: "2319200", descricao: "TELHAS DE VIDRO; FABRICACAO DE" },
  {
    cnae: "2319200",
    descricao:
      "OBJETOS DE VIDRO PARA TOUCADOR, ESCRITORIO OU USOS SEMELHANTES, EXCETO PARA MESA E COZINHA; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "ARTEFATOS DE VIDRO E CRISTAL PARA USO DOMESTICO, PARA HOTEIS, RESTAURANTES E SIMILARES, N.E.; FABRICACAO DE",
  },
  { cnae: "2319200", descricao: "VIDROS CURVOS; FABRICACAO DE" },
  { cnae: "2319200", descricao: "PORTA DE VIDRO; FABRICACAO DE" },
  { cnae: "2319200", descricao: "TIJOLOS DE VIDRO; FABRICACAO DE" },
  {
    cnae: "2319200",
    descricao:
      "ESPELHOS DE VIDRO PARA EMBARCACOES, AVIOES E VEICULOS DE TODOS OS TIPOS; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "BULBOS DE VIDRO PARA AMPOLAS DE GARRAFAS TERMICAS; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "MANGAS DE VIDRO E CRISTAL PARA LUSTRES E SEMELHANTES - INCLUSIVE PARA LAMPIOES; FABRICACAO DE",
  },
  { cnae: "2319200", descricao: "CANECAS OU XICARAS DE VIDRO; FABRICACAO DE" },
  {
    cnae: "2319200",
    descricao:
      "VIDRO EM CHAPAS OU FOLHAS, RECURVADO, BISELADO, GRAVADO, ESMALTADO OU TRABALHADO DE OUTRO MODO; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao: "VIDROS ELABORADOS PARA USOS N.E.; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "APARELHOS COMPLETOS DE VIDRO PARA SERVICO DE MESA (JANTAR, CAFE E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao: "VIDROS CONCAVOS PARA QUALQUER FIM; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao: "FIOS E FILAMENTOS DE FIBRA DE VIDRO; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao: "ARTEFATOS DE VIDRO PARA SINALIZACAO; FABRICACAO DE",
  },
  { cnae: "2319200", descricao: "LA DE VIDRO; FABRICACAO DE" },
  { cnae: "2319200", descricao: "ESFERAS DE VIDRO PARA EROSAO; FABRICACAO DE" },
  { cnae: "2319200", descricao: "VASOS DE CRISTAL; FABRICACAO DE" },
  {
    cnae: "2319200",
    descricao:
      "MECHAS, MESMO TORCIDOS (ROVING) E FIOS DE FIBRA DE VIDRO; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao: "ARTESANATO EM VIDRO OU CRISTAL; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "APARELHOS COMPLETOS DE CRISTAL PARA SERVICO DE MESA (JANTAR, CAFE E SEMELHANTES); FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "PARTES DE VIDRO PARA LAMPADAS E APARELHOS DE ILUMINACAO, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "PECAS DE VIDRO PARA EMBARCAOES, AVIOES E VEICULOS DE TODOS OS TIPOS; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao: "GLOBO DE VIDRO PARA ILUMINACAO; FABRICACAO DE",
  },
  { cnae: "2319200", descricao: "CONTA-GOTAS DE VIDRO; FABRICACAO DE" },
  {
    cnae: "2319200",
    descricao: "OBJETOS DE VIDRO PARA TOUCADOR; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "ARTEFATOS DE VIDRO PARA USOS DIVERSOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "ESPELHOS RETROVISORES, EMOLDURADOS OU NAO, PARA QUALQUER VEICULO, FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao: "ESPELHOS DE VIDRO PARA BOLSO E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "ARTEFATOS DE VIDRO PARA APLICACOES INDUSTRIAIS, N.E.; FABRICACAO DE",
  },
  { cnae: "2319200", descricao: "TUBOS DE VIDRO PARA LAMPADAS; FABRICACAO DE" },
  { cnae: "2319200", descricao: "VIDRO EM BARRAS; FABRICACAO DE" },
  {
    cnae: "2319200",
    descricao:
      "VEUS, MANTAS, LAS, ESTEIRAS, PAINEIS E OUTROS ARTEFATOS NAO TECIDOS, DE FIBRA DE VIDRO; FABRICACAO DE",
  },
  { cnae: "2319200", descricao: "VIDROS RECORTADOS; FABRICACAO DE" },
  {
    cnae: "2319200",
    descricao: "ESPELHOS DE VIDRO EM LAMINAS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao: "MISSANGAS DE VIDRO PARA USO EM ELETRICIDADE; FABRICACAO DE",
  },
  { cnae: "2319200", descricao: "COPOS DE VIDRO; FABRICACAO DE" },
  {
    cnae: "2319200",
    descricao: "VIDROS ISOLANTES DE PAREDES MULTIPLAS; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao: "ASSADEIRA DE VIDRO REFRATARIO; FABRICACAO DE",
  },
  { cnae: "2330303", descricao: "CANOS DE FIBROCIMENTO; FABRICACAO DE" },
  {
    cnae: "2319200",
    descricao:
      "VIDRO EM BLOCOS OU MASSAS; FRAGMENTOS E OUTROS RESIDUOS DO VIDRO; FABRICACAO DE",
  },
  {
    cnae: "2330303",
    descricao: "PLACAS DE FIBROCIMENTO LISAS OU ONDULADAS; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "PRODUTOS NAO TECIDOS DE FIBRA DE VIDRO (VEUS, COLCHOES, PAINEIS, ETC); FABRICACAO DE",
  },
  { cnae: "2319200", descricao: "ARTEFATOS DE FIBRA DE VIDRO; FABRICACAO DE" },
  {
    cnae: "2319200",
    descricao: "ISOLADORES DE VIDRO PARA USOS ELETRICOS; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao: "ESTEIRAS (MATS) DE FIBRA DE VIDRO; FABRICACAO DE",
  },
  { cnae: "2319200", descricao: "VIDRO EM TUBOS; FABRICACAO DE" },
  {
    cnae: "2319200",
    descricao:
      "ARTEFATOS DE VIDRO PARA LABORATORIOS, HOSPITAIS E AFINS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao: "CONJUNTO DE VIDRO E CRISTAL PARA TOUCADOR; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "BACIAS DE VIDRO E CRISTAL PARA LUSTRES E SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "2319200", descricao: "COPOS DE CRISTAL; FABRICACAO DE" },
  { cnae: "2319200", descricao: "BOLAS DE VIDRO PARA MOINHOS; FABRICACAO DE" },
  {
    cnae: "2319200",
    descricao: "MANTAS IRREGULARES DE FIBRA DE VIDRO; FABRICACAO DE",
  },
  { cnae: "2319200", descricao: "PRATOS DE VIDRO; FABRICACAO DE" },
  {
    cnae: "2319200",
    descricao:
      "COPOS GRADUADOS DE VIDRO PARA LABORATORIOS DE ANALISES, HOSPITAIS E AFINS; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao: "PROVETAS, PIPOS E PIPETAS DE VIDRO; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "BLOCOS, PLACAS, TIJOLOS, LADRILHOS E OUTROS ARTEFATOS DE VIDRO PARA CONSTRUCAO; FABRICACAO DE",
  },
  { cnae: "2319200", descricao: "FIBRA DE VIDRO (LA DE VIDRO); FABRICACAO DE" },
  {
    cnae: "2319200",
    descricao:
      "ESPELHOS DE VIDRO PARA USOS NAO ESPECIFICADOS, EMOLDURADOS OU NAO; FABRICACAO DE",
  },
  { cnae: "2319200", descricao: "PANELAS DE VIDRO; FABRICACAO DE" },
  { cnae: "2319200", descricao: "FUNIS DE VIDRO; FABRICACAO DE" },
  { cnae: "2319200", descricao: "JARRAS E JARROES DE CRISTAL; FABRICACAO DE" },
  {
    cnae: "2319200",
    descricao: "OBJETOS DE VIDRO E CRISTAL PARA ADORNO, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao:
      "ARTEFATOS DE VIDRO REFRATARIO PARA MESA, COPA E COZINHA; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao: "TUBINHOS DE VIDRO PARA FUSIVEIS; FABRICACAO DE",
  },
  {
    cnae: "2319200",
    descricao: "SALEIROS E PALITEIROS DE VIDRO; FABRICACAO DE",
  },
  { cnae: "2319200", descricao: "LADRILHOS DE VIDRO; FABRICACAO DE" },
  { cnae: "23206", descricao: "FABRICACAO DE CIMENTO" },
  { cnae: "2320600", descricao: "CIMENTOS ALUMINOSOS; FABRICACAO DE" },
  { cnae: "2320600", descricao: "CIMENTO PORTLAND COMUM; FABRICACAO DE" },
  {
    cnae: "2320600",
    descricao: "CIMENTOS PORTLAND DE ALTA RESISTENCIA INICIAL; FABRICACAO DE",
  },
  {
    cnae: "2320600",
    descricao: "CLINQUERES PARA CIMENTOS ALUMINOSOS; FABRICACAO DE",
  },
  {
    cnae: "2320600",
    descricao: "CIMENTOS PORTLAND POZOLANICOS; FABRICACAO DE",
  },
  { cnae: "2320600", descricao: "CIMENTO PORTLAND; FABRICACAO DE" },
  {
    cnae: "2320600",
    descricao: "CLINQUERES PARA CIMENTOS PORTLAND; FABRICACAO DE",
  },
  {
    cnae: "2320600",
    descricao: "CIMENTOS PORTLAND DE ALTO-FORNO; FABRICACAO DE",
  },
  { cnae: "2320600", descricao: "CIMENTOS HIDRAULICOS; FABRICACAO DE" },
  { cnae: "2320600", descricao: "CIMENTOS PORTLAND COMPOSTOS; FABRICACAO DE" },
  {
    cnae: "2320600",
    descricao:
      "ESCORIAS DE ALTO-FORNO BRITADAS PARA A INDUSTRIA DO CIMENTO; FABRICACAO DE",
  },
  {
    cnae: "2320600",
    descricao:
      "CIMENTOS PORTLAND BRANCOS, MESMO CORADOS ARTIFICIALMENTE; FABRICACAO DE",
  },
  {
    cnae: "23303",
    descricao:
      "FABRICACAO DE ARTEFATOS DE CONCRETO, CIMENTO, FIBROCIMENTO, GESSO E MATERIAIS SEMELHANTES",
  },
  { cnae: "2330301", descricao: "DORMENTES DE CONCRETO; FABRICACAO DE" },
  {
    cnae: "2330301",
    descricao: "ESTRUTURAS PRE MOLDADAS DE CONCRETO ARMADO; FABRICACAO DE",
  },
  { cnae: "2330301", descricao: "PLACAS DE CIMENTO; FABRICACAO DE" },
  { cnae: "2330301", descricao: "MOIRAO DE CONCRETO; FABRICACAO DE" },
  { cnae: "2330301", descricao: "VIGAS DE CONCRETO; FABRICACAO DE" },
  { cnae: "2330301", descricao: "POSTES DE CONCRETO; FABRICACAO DE" },
  { cnae: "2330301", descricao: "ESTACAS DE CONCRETO; FABRICACAO DE" },
  {
    cnae: "2330301",
    descricao: "ESTRUTURAS PRE MOLDADAS DE CIMENTO; FABRICACAO DE",
  },
  {
    cnae: "2330301",
    descricao:
      "TELHAS,LADRILHOS, LAJES E ARTEFATOS SEMELHANTES DE CIMENTO OU CONCRETO; FABRICACAO DE",
  },
  { cnae: "2330301", descricao: "BLOCOS DE CIMENTO; FABRICACAO DE" },
  {
    cnae: "2330301",
    descricao: "ADUELAS, DORMENTES E LONGARINAS DE CONCRETO; FABRICACAO DE",
  },
  {
    cnae: "2330301",
    descricao:
      "ELEMENTOS PRE-FABRICADOS PARA CONSTRUCAO CIVIL DE CIMENTO OU CONCRETO (ESTACAS, POSTES, CAIXAS DE AGUA, ETC.); FABRICACAO DE",
  },
  {
    cnae: "2330301",
    descricao: "LAJES DE CIMENTO PRE MOLDADAS; FABRICACAO DE",
  },
  { cnae: "2330301", descricao: "LAJES TRELICAS DE CONCRETO; FABRICACAO DE" },
  {
    cnae: "2330302",
    descricao:
      "PECAS DE MARMORITE, GRANITINA E MATERIAIS SEMELHANTES, N.E.; FABRICACAO DE",
  },
  { cnae: "2330302", descricao: "CANOS E TUBOS DE CONCRETO; FABRICACAO DE" },
  {
    cnae: "2330302",
    descricao: "CAIXAS DE GORDURAS EM CIMENTO; FABRICACAO DE",
  },
  { cnae: "2330302", descricao: "CONEXOES DE CIMENTO; FABRICACAO DE" },
  { cnae: "2330302", descricao: "BLOQUETES DE CIMENTO; FABRICACAO DE" },
  { cnae: "2330302", descricao: "LADRILHOS DE MARMORITE; FABRICACAO DE" },
  { cnae: "2330302", descricao: "MANILHAS DE CIMENTO; FABRICACAO DE" },
  {
    cnae: "2330302",
    descricao: "CAIXAS DILUIDORAS OU SECCIONADORAS DE CIMENTO; FABRICACAO DE",
  },
  { cnae: "2330302", descricao: "MEIO-FIO DE CIMENTO; FABRICACAO DE" },
  { cnae: "2330302", descricao: "TIJOLOS DE CIMENTO; FABRICACAO DE" },
  {
    cnae: "2330302",
    descricao: "CAIXAS DE CIMENTO OU CONCRETO PARA QUALQUER USO; FABRICACAO DE",
  },
  { cnae: "2330302", descricao: "CUMEEIRAS DE CIMENTO; FABRICACAO DE" },
  { cnae: "2330302", descricao: "TANQUES DE CIMENTO; FABRICACAO DE" },
  { cnae: "2330302", descricao: "MARMORITE EM PLACAS; FABRICACAO DE" },
  { cnae: "2330302", descricao: "FOSSAS SEPTICAS EM CIMENTO; FABRICACAO DE" },
  { cnae: "2330302", descricao: "TUBOS DE CIMENTO; FABRICACAO DE" },
  { cnae: "2330302", descricao: "MOSAICOS DE CIMENTO; FABRICACAO DE" },
  {
    cnae: "2330302",
    descricao: "ARTEFATOS DE CIMENTO PARA USO NA CONSTRUCAO; FABRICACAO DE",
  },
  { cnae: "2330302", descricao: "LAJOTAS DE CIMENTO; FABRICACAO DE" },
  { cnae: "2330302", descricao: "CANOS DE CIMENTO; FABRICACAO DE" },
  { cnae: "2330302", descricao: "GRANITINA EM PLACAS; FABRICACAO DE" },
  { cnae: "2330302", descricao: "LADRILHOS DE CIMENTO; FABRICACAO DE" },
  { cnae: "2330302", descricao: "CANTONEIRAS DE MARMORITE; FABRICACAO DE" },
  { cnae: "2330302", descricao: "CAIXAS DE AGUA DE CIMENTO; FABRICACAO DE" },
  { cnae: "2330302", descricao: "MESAS DE MARMORITE PARA PIAS; FABRICACAO DE" },
  {
    cnae: "2330303",
    descricao: "CAIXAS DE DESCARGA DE FIBROCIMENTO; FABRICACAO DE",
  },
  { cnae: "2330303", descricao: "CONEXOES DE FIBROCIMENTO; FABRICACAO DE" },
  {
    cnae: "2330303",
    descricao: "CAIXA DE AGUA DE FIBROCIMENTO; FABRICACAO DE",
  },
  {
    cnae: "2330303",
    descricao:
      "ARTEFATOS DE FIBROCIMENTO PARA USO NA CONSTRUCAO; FABRICACAO DE",
  },
  { cnae: "2330303", descricao: "TUBOS DE FIBROCIMENTO; FABRICACAO DE" },
  { cnae: "2330303", descricao: "MANILHAS DE FIBROCIMENTO; FABRICACAO DE" },
  {
    cnae: "2330303",
    descricao:
      "CHAPAS, PAINEIS, LADRILHOS, TELHAS, CANOS, TUBOS OU OUTROS ARTEFATOS DE FIBROCIMENTO, CIMENTO-CELULOSE OU SEMELHANTES NAO CONTENDO AMIANTO; FABRICACAO DE",
  },
  { cnae: "3211601", descricao: "AMETISTA, LAPIDACAO DE" },
  {
    cnae: "2330303",
    descricao: "RESERVATORIOS DE FIBROCIMENTO; FABRICACAO DE",
  },
  { cnae: "2330303", descricao: "CALHAS DE FIBROCIMENTO; FABRICACAO DE" },
  {
    cnae: "2330303",
    descricao: "TELHAS DE FIBROCIMENTO LISAS OU ONDULADAS; FABRICACAO DE",
  },
  { cnae: "2829101", descricao: "ROTULADORES, FABRICACAO DE" },
  {
    cnae: "2330304",
    descricao:
      "CONSTRUCOES PRE-FABRICADAS DE CIMENTO OU CONCRETO, EXCETO ESTRUTURAS PRE-MOLDADAS; FABRICACAO DE",
  },
  {
    cnae: "2330304",
    descricao:
      "CASAS, SILOS E SIMILARES PRE-FABRICADOS DE CONCRETO; FABRICACAO DE",
  },
  {
    cnae: "2330305",
    descricao:
      "ARGAMASSAS OU OUTROS AGLOMERANTES NAO REFRATARIOS; FABRICACAO DE",
  },
  {
    cnae: "2330305",
    descricao:
      "MASSA DE CONCRETO (CIMENTO, AREIA, BRITA, AGUA, ADITIVOS, ETC.) DOSADAS POR USINAS INCLUSIVE O TRANSPORTE ATRAVES DE CAMINHOES BETONEIRAS OU POR DUTOS ATE O LOCAL DA CONSTRUCAO; PREPARACAO DE",
  },
  { cnae: "2330305", descricao: "ARGAMASSA PREPARADA, EM PO; FABRICACAO DE" },
  {
    cnae: "2330305",
    descricao:
      "MASSA DE CONCRETO, INCLUSIVE O LANCAMENTO (COLOCACAO) NA OBRA ATRAVES DE BOMBAS; PREPARACAO DE",
  },
  {
    cnae: "2330305",
    descricao: "REBOCO PREPARADO PARA CONSTRUCAO; FABRICACAO DE",
  },
  {
    cnae: "2330305",
    descricao: "ARGAMASSA PREPARADA PARA CONSTRUCAO; FABRICACAO DE",
  },
  {
    cnae: "2330305",
    descricao: "MASSA DE CONCRETO PREPARADA PARA CONSTRUCAO; FABRICACAO DE",
  },
  {
    cnae: "2330399",
    descricao:
      "ARTEFATOS DE CIMENTO, EXCETO PARA USO NA CONSTRUCAO; FABRICACAO DE",
  },
  { cnae: "2330399", descricao: "OBJETOS DE GESSO; FABRICACAO DE" },
  { cnae: "2330399", descricao: "PLACAS DE GESSO; FABRICACAO DE" },
  { cnae: "2330399", descricao: "CANTONEIRAS DE GESSO; FABRICACAO DE" },
  {
    cnae: "2330399",
    descricao: "ESTATUETAS E IMAGENS DE GESSO; FABRICACAO DE",
  },
  {
    cnae: "2330399",
    descricao:
      "MADEIRA MINERALIZADA (CHAPAS DE CIMENTO COM REFORCO DE MADEIRA); FABRICACAO DE",
  },
  {
    cnae: "2330399",
    descricao: "ARTESANATO EM FIBROCIMENTO OU GESSO; FABRICACAO DE",
  },
  {
    cnae: "2330399",
    descricao:
      "ARTEFATOS DE CONCRETO, EXCETO PARA USO NA CONSTRUCAO; FABRICACAO DE",
  },
  {
    cnae: "2330399",
    descricao:
      "CHAPAS, PLACAS, PAINEIS, LADRILHOS E SEMELHANTES, DE GESSO; FABRICACAO DE",
  },
  { cnae: "2330399", descricao: "ARTEFATOS DE GESSO; FABRICACAO DE" },
  { cnae: "2330399", descricao: "CALHAS DE GESSO; FABRICACAO DE" },
  { cnae: "2330399", descricao: "SANCAS DE GESSO; FABRICACAO DE" },
  { cnae: "2330399", descricao: "VASOS DE CIMENTO; FABRICACAO DE" },
  {
    cnae: "2330399",
    descricao:
      "ARTEFATOS DE FIBROCIMENTO, EXCETO PARA USO NA CONSTRUCAO; FABRICACAO DE",
  },
  {
    cnae: "2330399",
    descricao:
      "ARTIGOS DE FIBROCIMENTO, CIMENTO-CELULOSE OU SEMELHANTES, NAO ESPECIFICADOS, CONTENDO AMIANTO, EXCETO UTILIZADO EM CONSTRUCAO; FABRICACAO DE",
  },
  { cnae: "2330399", descricao: "FLOROES DE GESSO; FABRICACAO DE" },
  { cnae: "2330399", descricao: "OBJETOS DE ADORNO EM GESSO; FABRICACAO DE" },
  {
    cnae: "2330399",
    descricao:
      "CHAPAS DE CIMENTO COM REFORCO DE MADEIRA PARA DIVISORIAS, FORROS E REVESTIMENTOS EXTERNOS (MADEIRA MINERALIZADA); FABRICACAO DE",
  },
  {
    cnae: "2330399",
    descricao:
      "MATERIAIS DE CONSTRUCAO DE SUBSTANCIAS VEGETAIS (LA DE MADEIRA, PALHA, ETC), AGLOMERADOS COM GESSO; FABRICACAO DE",
  },
  { cnae: "23419", descricao: "FABRICACAO DE PRODUTOS CERAMICOS REFRATARIOS" },
  {
    cnae: "2341900",
    descricao: "PRODUTOS CERAMICOS REFRATARIOS; FABRICACAO DE",
  },
  {
    cnae: "2341900",
    descricao: "REFRATARIOS SILICO-ALUMINOSOS; FABRICACAO DE",
  },
  {
    cnae: "2341900",
    descricao:
      "PEDRAS REFRATARIAS PARA CALEFACAO (PEDRAS PARA FOGAREIROS, FOGOES E SEMELHANTES); FABRICACAO DE",
  },
  {
    cnae: "2341900",
    descricao: "PECAS REFRATARIAS DE DOLOMITA; FABRICACAO DE",
  },
  { cnae: "2341900", descricao: "REFRATARIOS GRAFITOSOS; FABRICACAO DE" },
  {
    cnae: "2341900",
    descricao: "LADRILHOS CERAMICOS REFRATARIOS; FABRICACAO DE",
  },
  {
    cnae: "2341900",
    descricao: "MASSA REFRATARIA - INCLUSIVE CHAMOTE; FABRICACAO DE",
  },
  { cnae: "2341900", descricao: "REFRATARIOS, N.E.; FABRICACAO DE" },
  {
    cnae: "2341900",
    descricao:
      "CONCRETOS (BETOES) REFRATARIOS E COMPOSICOES SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2341900",
    descricao: "TIJOLOS CERAMICOS REFRATARIOS; FABRICACAO DE",
  },
  {
    cnae: "2341900",
    descricao:
      "TIJOLOS, PLACAS, LADRILHOS E OUTRAS PECAS DE CERAMICA REFRATARIA PARA CONSTRUCAO, EXCETO DE FARINHAS SILICIOSAS FOSSEIS; FABRICACAO DE",
  },
  { cnae: "2341900", descricao: "POS EXOTERMICOS; FABRICACAO DE" },
  {
    cnae: "2341900",
    descricao: "PECAS REFRATARIAS DE CERAMICA; FABRICACAO DE",
  },
  {
    cnae: "2341900",
    descricao:
      "PRODUTOS REFRATARIOS CONTENDO MAGNESITA, DOLOMITA OU CROMITA; FABRICACAO DE",
  },
  { cnae: "2341900", descricao: "CERAMICA REFRATARIA; FABRICACAO DE" },
  { cnae: "2341900", descricao: "ARGAMASSA REFRATARIA; FABRICACAO DE" },
  { cnae: "2341900", descricao: "REFRATARIOS ALUMINOSOS; FABRICACAO DE" },
  { cnae: "2341900", descricao: "PO REFRATARIO; FABRICACAO DE" },
  { cnae: "2341900", descricao: "CIMENTO REFRATARIO; PRODUCAO DE" },
  { cnae: "2341900", descricao: "REFRATARIOS SILICOSOS; FABRICACAO DE" },
  {
    cnae: "23427",
    descricao:
      "FABRICACAO DE PRODUTOS CERAMICOS NAOREFRATARIOS PARA USO ESTRUTURAL NA CONSTRUCAO",
  },
  { cnae: "2342701", descricao: "AZULEJO DECORADO; FABRICACAO DE" },
  {
    cnae: "2342701",
    descricao: "MOSAICOS E PASTILHAS CERAMICAS; FABRICACAO DE",
  },
  {
    cnae: "2342701",
    descricao:
      "LADRILHOS, PLACAS E AZULEJOS DE CERAMICA PARA PAVIMENTACAO OU REVESTIMENTO, ESMALTADOS (LADO SUPERIOR OU IGUAL A 7 CM); FABRICACAO DE",
  },
  { cnae: "2342701", descricao: "PISO LAJOTAO DE CERAMICA; FABRICACAO DE" },
  { cnae: "2342701", descricao: "PORCELANATO; FABRICACAO DE" },
  {
    cnae: "2342701",
    descricao: "LADRILHOS CERAMICOS - EXCETO REFRATARIOS; FABRICACAO DE",
  },
  {
    cnae: "2342701",
    descricao: "PISOS CERAMICOS, ESMALTADOS OU NAO; PRODUCAO DE",
  },
  {
    cnae: "2342701",
    descricao: "AZULEJO LISO - INCLUSIVE EM CORES; FABRICACAO DE",
  },
  { cnae: "2342702", descricao: "CANOS DE CERAMICA; FABRICACAO DE" },
  {
    cnae: "2342702",
    descricao: "TELHAS CERAMICAS OU DE BARRO COZIDO; FABRICACAO DE",
  },
  { cnae: "2342702", descricao: "PRODUTOS DE OLARIA; FABRICACAO DE" },
  { cnae: "2342702", descricao: "LAJOTAS DE CERAMICA; FABRICACAO DE" },
  {
    cnae: "2342702",
    descricao:
      "LADRILHOS E PLACAS DE CERAMICA PARA PAVIMENTACAO OU REVESTIMENTO, NAO ESMALTADOS (LADO SUPERIOR OU IGUAL A 7 CM); FABRICACAO DE",
  },
  {
    cnae: "2342702",
    descricao:
      "TIJOLOS CERAMICOS OU DE BARRO COZIDO - EXCETO REFRATARIO; FABRICACAO DE",
  },
  {
    cnae: "2342702",
    descricao:
      "ELEMENTOS DE CHAMINES, ORNAMENTOS ARQUITETONICOS, ETC., DE CERAMICA; FABRICACAO DE",
  },
  {
    cnae: "2342702",
    descricao:
      "CALHAS, CANTOS, RODAPES E SEMELHANTES DE CERAMICA; FABRICACAO DE",
  },
  { cnae: "2342702", descricao: "TUBOS DE CERAMICA; FABRICACAO DE" },
  { cnae: "2342702", descricao: "MANILHAS DE CERAMICA; FABRICACAO DE" },
  { cnae: "2342702", descricao: "CONEXOES DE CERAMICA; FABRICACAO DE" },
  {
    cnae: "2342702",
    descricao:
      "PASTILHAS, CUBOS E ARTIGOS SEMELHANTES DE CERAMICA, PARA MOSAICOS, ESMALTADOS OU NAO (LADO INFERIOR A 7 CM); FABRICACAO DE",
  },
  {
    cnae: "2342702",
    descricao: "CERAMICA VAZADA PARA ORNAMENTACAO DE FACHADAS; FABRICACAO DE",
  },
  {
    cnae: "23494",
    descricao:
      "FABRICACAO DE PRODUTOS CERAMICOS NAOREFRATARIOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "2349401",
    descricao:
      "ARTIGOS DE CERAMICA, EXCETO PORCELANA, PARA HIGIENE OU TOUCADOR; FABRICACAO DE",
  },
  { cnae: "2349401", descricao: "VASOS SANITARIOS DE LOUCA; FABRICACAO DE" },
  { cnae: "2349401", descricao: "BANHEIRAS DE LOUCA; FABRICACAO DE" },
  {
    cnae: "2349401",
    descricao: "MATERIAL SANITARIO DE CERAMICA; FABRICACAO DE",
  },
  {
    cnae: "2349401",
    descricao:
      "PORTA-TOALHAS, SABONETEIRAS E OUTROS ACESSORIOS DE LOUCA PARA BANHEIROS; FABRICACAO DE",
  },
  { cnae: "2349401", descricao: "PIAS DE LOUCA; FABRICACAO DE" },
  { cnae: "2349401", descricao: "BIDES DE LOUCA; FABRICACAO DE" },
  { cnae: "2349401", descricao: "LOUCAS SANITARIAS; FABRICACAO DE" },
  { cnae: "2349499", descricao: "TRAVESSAS DE CERAMICA; FABRICACAO DE" },
  {
    cnae: "2349499",
    descricao: "GUIAS-FIO DE PORCELANA PARA MAQUINA TEXTIL; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao:
      "ESTATUETAS E OUTROS OBJETOS DE ORNAMENTACAO, DE CERAMICA; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao: "LOUCAS PARA CHA, CAFE, JANTAR; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao: "COPOS, CANECAS E XICARAS DE LOUCA; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao:
      "APARELHOS COMPLETOS DE LOUCA PARA SERVICO DE MESA (JANTAR, CAFE, CHA, E SEMELHANTES); FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao: "VELAS DE CERAMICA PARA FILTROS DE USO DOMESTICO; FABRICACAO DE",
  },
  { cnae: "2349499", descricao: "PRATOS DE LOUCA; FABRICACAO DE" },
  {
    cnae: "2349499",
    descricao: "TALHAS E FILTROS DE CERAMICA OU BARRO COZIDO; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao:
      "ARTEFATOS DE CERAMICA PARA USO QUIMICO OU OUTROS USOS TECNICOS; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao:
      "CERAMICA TECNICA (PARA USO QUIMICO, ELETRICO, MECANICO, ETC); FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao: "ISOLADORES DE CERAMICA PARA USOS ELETRICOS; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao: "COPOS, CANECAS E XICARAS DE CERAMICA; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao: "ARTEFATOS DE CERAMICA BRANCA PARA QUALQUER USO; FABRICACAO DE",
  },
  { cnae: "2349499", descricao: "VELAS FILTRANTES; FABRICACAO DE" },
  {
    cnae: "2349499",
    descricao:
      "ARTIGOS DE PORCELANA OU CERAMICA DECORADOS, GRAVADOS, VITRIFICADOS OU TRABALHOS DE OUTRO MODO; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao: "BOLAS DE PORCELANA PARA MOINHOS; FABRICACAO DE",
  },
  { cnae: "2349499", descricao: "BONECO DE BARRO; FABRICACAO DE" },
  { cnae: "2349499", descricao: "FILTROS DE PORCELANA; FABRICACAO DE" },
  {
    cnae: "2349499",
    descricao: "MORINGAS DE CERAMICA OU BARRO COZIDO; FABRICACAO DE",
  },
  { cnae: "2349499", descricao: "TRAVESSAS DE LOUCA; FABRICACAO DE" },
  {
    cnae: "2349499",
    descricao: "BOLAS DE CERAMICA PARA MOINHOS; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao: "PECAS DE PORCELANA PARA ELETRICIDADE; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao: "TALHAS E FILTROS DE PORCELANA; FABRICACAO DE",
  },
  { cnae: "2349499", descricao: "BIBELOS DE PORCELANA; FABRICACAO DE" },
  {
    cnae: "2349499",
    descricao: "POTES DE CERAMICA OU BARRO COZIDO; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao:
      "ARTIGOS DE CERAMICA OU DE PORCELANA, PARA USOS NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao: "PECAS AVULSAS DE LOUCA PARA MESA E COZINHA; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao: "VASOS DE CERAMICA OU BARRO COZIDO; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao:
      "ISOLADORES DE PORCELANA DE TODOS OS TIPOS (SEM FERRAGENS); FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao:
      "COPOS GRADUADOS E DEMAIS ARTIGOS DE CERAMICA PARA LABORATORIOS; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao:
      "VASILHAS DE CERAMICA PARA TRANSPORTE OU EMBALAGEM; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao: "FILTROS DE CERAMICA OU BARRO COZIDO; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao: "OBJETOS DE CERAMICA ARTISTICA - INCLUSIVE VASOS; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao:
      "ARTESANATO EM CERAMICA, LOUCA, PORCELANA OU BARRO COZIDO; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao:
      "CERAMICOS TECNICOS PARA USO ELETROELETRONICO, QUIMICO, TERMICO, MECANICO, BIOLOGICO, ETC; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao:
      "BASES DE PORCELANA PARA CHAVES ELETRICAS, FUSIVEIS, PORTA-FUSIVEIS E TOMADAS; FABRICACAO DE",
  },
  {
    cnae: "2349499",
    descricao: "PANELAS DE CERAMICA OU BARRO COZIDO; FABRICACAO DE",
  },
  { cnae: "23915", descricao: "APARELHAMENTO E OUTROS TRABALHOS EM PEDRAS" },
  {
    cnae: "2391501",
    descricao: "BRITAS (NAO ASSOCIADA A EXTRACAO); PRODUCAO DE",
  },
  {
    cnae: "2391501",
    descricao:
      "PEDRA BRITADA (BRITAMENTO NAO ASSOCIADO A EXTRACAO); PRODUCAO DE",
  },
  {
    cnae: "2391501",
    descricao: "PEDRA, NAO ASSOCIADA A EXTRACAO; BRITAMENTO DE",
  },
  {
    cnae: "2391502",
    descricao:
      "POLIMENTO, APICOAMENTO, FLAMAGEM, CORTE, ACABAMENTO, RESINAGEM E TELAGEM DE PLACAS E CHAPAS DE MARMORE, GRANITO, ARDOSIA E OUTRAS PEDRAS; SERVICOS DE",
  },
  {
    cnae: "2391502",
    descricao: "LAJOTAS, SOLEIRAS, DEGRAUS E RODAPES DE MARMORE; PRODUCAO DE",
  },
  { cnae: "2391502", descricao: "DEGRAUS DE PEDRAS; FABRICACAO DE" },
  { cnae: "2391502", descricao: "CANTONEIRAS DE MARMORE; PRODUCAO DE" },
  { cnae: "2391502", descricao: "DEGRAUS DE MARMORE; FABRICACAO DE" },
  { cnae: "2391502", descricao: "MEIOS-FIOS DE PEDRA; PRODUCAO DE" },
  { cnae: "2391502", descricao: "GRANITO EM PLACAS; PRODUCAO DE" },
  {
    cnae: "2391502",
    descricao: "LAJOTAS, SOLEIRAS, DEGRAUS E RODAPES DE PEDRAS; PRODUCAO DE",
  },
  { cnae: "2391502", descricao: "MARMORE EM PLACAS; SERVICOS DE" },
  { cnae: "2391502", descricao: "ARDOSIA EM CHAPAS; PRODUCAO DE" },
  {
    cnae: "2391502",
    descricao:
      "LADRILHOS, CUBOS, PASTILHAS E SEMELHANTES DE PEDRAS PARA CONSTRUCAO; FABRICACAO DE",
  },
  { cnae: "2391502", descricao: "PARALELEPIPEDOS; PRODUCAO DE" },
  { cnae: "2391502", descricao: "PEDRAS PARA CALCAMENTO DE RUAS; PRODUCAO DE" },
  { cnae: "2391502", descricao: "CANTARIA (PEDRAS)" },
  { cnae: "2391502", descricao: "PEDRAS PARA CONSTRUCAO, N.E.; PRODUCAO DE" },
  {
    cnae: "2391503",
    descricao: "BANCOS, MESAS E OUTRAS PECAS DE ARDOSIA; FABRICACAO DE",
  },
  {
    cnae: "2391503",
    descricao:
      "DESDOBRAGEM, SERRAGEM E CORTE DE PLACAS DE MARMORE, GRANITO, ARDOSIA E OUTRAS PEDRAS; ATIVIDADES DE",
  },
  {
    cnae: "2391503",
    descricao:
      "ARDOSIA TRABALHADA E OBRAS DE ARDOSIA NATURAL OU AGLOMERADA; FABRICACAO DE",
  },
  {
    cnae: "2391503",
    descricao:
      "PEDRAS DE CONSTRUCAO TALHADAS, SERRADAS OU TRABALHADAS DE OUTRO MODO, INCLUSIVE PEDRA SABAO; FABRICACAO DE",
  },
  {
    cnae: "2391503",
    descricao: "JAZIGOS, TUMULOS E SEPULTURAS DE GRANITO; PRODUCAO DE",
  },
  { cnae: "2391503", descricao: "MESAS DE MARMORE PARA PIAS; PRODUCAO DE" },
  {
    cnae: "2391503",
    descricao: "GRANITO RECORTADO A PARTIR DE PLACAS OU CHAPAS; PRODUCAO DE",
  },
  {
    cnae: "2391503",
    descricao:
      "CORTE DE PLACAS DE MARMORE, GRANITO, ARDOSIA E OUTRAS PEDRAS EM CHAPAS, TIRAS OU LADRILHOS; ATIVIDADES DE",
  },
  { cnae: "2391503", descricao: "OBJETOS DE ARTE EM PEDRA-SABAO; PRODUCAO DE" },
  {
    cnae: "2391503",
    descricao:
      "BASES DE ARDOSIA PARA CHAVES ELETRICAS, PORTA-FUSIVEIS, ETC; PRODUCAO DE",
  },
  { cnae: "2391503", descricao: "MESAS DE GRANITO PARA PIAS; PRODUCAO DE" },
  {
    cnae: "2391503",
    descricao: "BANCADAS DE MARMORE PARA PIAS; FABRICACAO DE",
  },
  {
    cnae: "2391503",
    descricao:
      "CINZEIROS, IMAGENS E OUTROS TRABALHOS EM ARDOSIA E OUTRAS PEDRAS; FABRICACAO DE",
  },
  {
    cnae: "2391503",
    descricao:
      "OBJETOS DE ARTE EM GRANITO - INCLUSIVE PARA JAZIGOS; PRODUCAO DE",
  },
  {
    cnae: "2391503",
    descricao:
      "PECAS DECORATIVAS EM MARMORE, GRANITO E OUTRAS PEDRAS; FABRICACAO DE",
  },
  {
    cnae: "2391503",
    descricao: "BANCADAS DE GRANITO PARA PIAS; FABRICACAO DE",
  },
  {
    cnae: "2391503",
    descricao:
      "PEDRAS RECORTADAS (MARMORE, GRANITO E OUTRAS) A PARTIR DE PLACAS OU CHAPAS; PRODUCAO DE",
  },
  {
    cnae: "2391503",
    descricao:
      "ARTEFATOS DE PEDRAS DECORATIVAS (ARDOSIA, SAO TOME, GRANITO, ETC), FABRICACAO DE",
  },
  { cnae: "2391503", descricao: "PRODUTOS DE MARMORARIA; FABRICACAO DE" },
  { cnae: "3211601", descricao: "SAFIRA, LAPIDACAO DE" },
  {
    cnae: "2391503",
    descricao:
      "GRANITO TALHADO, SERRADO OU TRABALHADO DE OUTRO MODO, INCLUSIVE CHAPAS DE GRANITO PARA PIAS; FABRICACAO DE",
  },
  {
    cnae: "2391503",
    descricao: "JAZIGOS, TUMULOS E SEPULTURAS DE MARMORE; PRODUCAO DE",
  },
  {
    cnae: "2391503",
    descricao: "JAZIGOS, TUMULOS E SEPULTURAS DE PEDRAS; PRODUCAO DE",
  },
  {
    cnae: "2391503",
    descricao:
      "ARTESANATO EM MARMORE, GRANITO, ARDOSIA OU OUTRAS PEDRAS; FABRICACAO DE",
  },
  { cnae: "2391503", descricao: "OBJETOS DE ARTE EM PEDRAS; PRODUCAO DE" },
  {
    cnae: "2391503",
    descricao: "MARMORE RECORTADO A PARTIR DE PLACAS OU CHAPAS; PRODUCAO DE",
  },
  {
    cnae: "2391503",
    descricao:
      "PECAS DE ALABASTRO PARA LUSTRES, ABAJURES E SEMELHANTES; PRODUCAO DE",
  },
  {
    cnae: "2391503",
    descricao:
      "MARMORE E ALABASTRO TALHADO, SERRADO OU TRABALHADO DE OUTRO MODO, INCLUSIVE CHAPAS DE MARMORE PARA PIAS; FABRICACAO DE",
  },
  {
    cnae: "2391503",
    descricao:
      "PLACAS APARELHADAS DE MARMORE, GRANITO, ARDOSIA E OUTRAS PEDRAS, PARA USOS INDUSTRIAIS, N.E.; PRODUCAO DE",
  },
  {
    cnae: "2391503",
    descricao:
      "OBJETOS DE ARTE EM MARMORE - INCLUSIVE PARA JAZIGOS; PRODUCAO DE",
  },
  { cnae: "23923", descricao: "FABRICACAO DE CAL E GESSO" },
  { cnae: "2392300", descricao: "CAL EXTINTA; FABRICACAO DE" },
  { cnae: "2392300", descricao: "GESSO OU GIPSITA; BENEFICIAMENTO DE" },
  { cnae: "2392300", descricao: "CAL HIDRATADA (EXTINTA); FABRICACAO DE" },
  { cnae: "2392300", descricao: "CAL HIDRAULICA; FABRICACAO DE" },
  {
    cnae: "2392300",
    descricao: "DOLOMITA CALCINADA OU SINTERIZADA; PRODUCAO DE",
  },
  { cnae: "2392300", descricao: "GESSO CORADO OU NAO; FABRICACAO DE" },
  { cnae: "2392300", descricao: "CAL VIRGEM; FABRICACAO DE" },
  { cnae: "2392300", descricao: "CAL VIVA; FABRICACAO DE" },
  { cnae: "2392300", descricao: "CAL APAGADA; FABRICACAO DE" },
  {
    cnae: "23991",
    descricao:
      "FABRICACAO DE PRODUTOS DE MINERAIS NAOMETALICOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "2399101",
    descricao:
      "OBJETOS DECORADOS DE CERAMICA, LOUCA, VIDRO E CRISTAL, INCLUSIVE LADRILHOS; PRODUCAO DE",
  },
  {
    cnae: "2399101",
    descricao:
      "DECORACAO, LAPIDACAO, GRAVACAO, ESPELHACAO, VITRIFICACAO, BISOTAGEM E OUTROS TRABALHOS EM LOUCAS, VIDROS, CRISTAIS E LADRILHOS; SERVICOS DE",
  },
  {
    cnae: "2399101",
    descricao:
      "ARTIGOS DE PORCELANA OU CERAMICA DECORADOS, GRAVADOS, VITRIFICADOS OU TRABALHADOS DE OUTRO MODO, FABRICACAO DE",
  },
  { cnae: "2399102", descricao: "PEDRA-POMES; FABRICACAO DE" },
  { cnae: "2399102", descricao: "LIXA EM ROLO PARA MADEIRA; FABRICACAO DE" },
  { cnae: "2399102", descricao: "ESMERIL EM DISCO; FABRICACAO DE" },
  { cnae: "2399102", descricao: "PO PARA ESMERIL; FABRICACAO DE" },
  { cnae: "2399102", descricao: "LIXA EM FOLHA PARA METAL; FABRICACAO DE" },
  {
    cnae: "2399102",
    descricao:
      "ABRASIVOS NATURAIS OU ARTIFICIAIS, EM PO OU EM GRAOS, APLICADOS SOBRE QUALQUER MATERIAL; FABRICACAO DE",
  },
  {
    cnae: "2399102",
    descricao: "REBOLO DE ESMERIL PARA METAIS COMUNS; FABRICACAO DE",
  },
  { cnae: "2399102", descricao: "ARTIGOS ABRASIVOS, N.E.; FABRICACAO DE" },
  { cnae: "2399102", descricao: "LIXA EM ROLO PARA METAL; FABRICACAO DE" },
  { cnae: "2399102", descricao: "RODAS DE PANO PARA POLIMENTO; FABRICACAO DE" },
  {
    cnae: "2399102",
    descricao:
      "MOS, REBOLOS E ARTEFATOS SEMELHANTES, PARA MOER, DESFIBRAR, TRITURAR, AMOLAR, RETIFICAR OU CORTAR; FABRICACAO DE",
  },
  { cnae: "2399102", descricao: "LIXA EM ROLO; FABRICACAO DE" },
  { cnae: "2399102", descricao: "LIXA EM FOLHA; FABRICACAO DE" },
  {
    cnae: "2399102",
    descricao: "PEDRAS PARA AMOLAR, POLIR, AFIAR; FABRICACAO DE",
  },
  { cnae: "2399102", descricao: "LIXA EM FOLHA PARA MADEIRA; FABRICACAO DE" },
  {
    cnae: "2399102",
    descricao:
      "REBOLO DE ESMERIL PARA PASTILHAS DE METAIS DUROS; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "LA DE ESCORIA, LA DE ROCHA E OUTRAS LAS MINERAIS SIMILARES; FABRICACAO DE",
  },
  { cnae: "2399199", descricao: "ARTIGOS DE MICA; FABRICACAO DE" },
  {
    cnae: "2399199",
    descricao: "DISCOS DE TECIDOS PARA POLIAS; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao: "CAULIM BENEFICIADO, BENEFICIAMENTO NAO ASSOCIADO A EXTRACAO",
  },
  {
    cnae: "2399199",
    descricao:
      "FOSFATOS ALUMINOCALCICOS NATURAIS E CRE FOSFATADO; BENEFICIAMENTO NAO ASSOCIADO A EXTRACAO",
  },
  {
    cnae: "2399199",
    descricao:
      "MATERIAL ISOLANTE DE ORIGEM MINERAL (LA DE ESCORIA, LA DE ROCHA E OUTRAS LAS SIMILARES); FABRICACAO DE",
  },
  { cnae: "2399199", descricao: "CORRETIVO DE ACIDEZ DO SOLO; FABRICACAO DE" },
  {
    cnae: "2399199",
    descricao:
      "ARTIGOS DE GRAFITE OU DE OUTROS CARBONOS, EXCETO PARA USO EM ELETRICO; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "MATERIAIS DE CONSTRUCAO DE SUBSTANCIAS VEGETAIS (LA DE MADEIRA, PALHA, ETC), AGLOMERADOS COM ASFALTO OU BETUME; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao: "ARTIGOS DE ASFALTO E PRODUTOS SEMELHANTES, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "PASTILHAS DE FREIO A BASE DE SUBSTANCIAS MINERAIS OU CELULOSE, NAO MONTADAS; FABRICACAO DE",
  },
  { cnae: "2399199", descricao: "PASTA ELETRODICA E CATODICA; FABRICACAO DE" },
  {
    cnae: "2399199",
    descricao:
      "SULFATO DE BARIO NATURAL BENEFICIADO (BARITA, BARITINA, ESPATO-PESADO), NAO ASSOCIADO A EXTRACAO - EXCETO REFINADO OU OBTIDO POR VIA QUIMICA",
  },
  {
    cnae: "2399199",
    descricao:
      "PRODUTOS DE LA DE ESCORIA, LA DE ROCHA E OUTRAS LAS MINERAIS, PARA ISOLAMENTO TERMICO OU ACUSTICO; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao: "FIOS, CORDOALHAS E FITAS DE AMIANTO; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao: "PO CALCARIO (NAO ASSOCIADO A EXTRACAO); FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "BENEFICIAMENTO DE MINERAIS NAO-METALICOS NAO ASSOCIADO A EXTRACAO; SERVICO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "MISTURAS E OBRAS DE MATERIAIS MINERAIS PARA ISOLAMENTO TERMICO OU ACUSTICO; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "FITAS, CORDOALHAS, JUNTAS, GAXETAS E SEMELHANTES DE AMIANTO OU ASBESTO PARA VEICULOS, MAQUINAS E APARELHOS, ETC; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "GRAFITA ARTIFICIAL, COLOIDAL OU SEMICOLOIDAL; PREPARACOES A BASE DE GRAFITA OU DE OUTROS CARBONOS EM PASTAS, BLOCOS, ETC.; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "PLACAS, FOLHAS E OUTROS ARTIGOS DE MICA AGLOMERADA OU RECONSTITUIDA, MESMO COM SUPORTE; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "MISTURAS BETUMINOSAS A BASE DE ASFALTO OU BETUME (CUT-BACKS), OBTIDAS A PARTIR DE ASFALTO COMPRADO, UTILIZADOS PRINCIPALMENTE PARA REVESTIMENTO DE ESTRADAS",
  },
  {
    cnae: "2399199",
    descricao: "ISOLANTES ELETRICOS A BASE DE MICA (MICANITE); FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "PECAS E ACESSORIOS DE AMIANTO OU ASBESTO PARA VEICULOS, MAQUINAS E APARELHOS, ETC; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao: "ARTEFATOS DE AMIANTO (OU REVESTIDO DE); FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao: "CALCARIO BENEFICIADO, NAO ASSOCIADO A EXTRACAO; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "ARTIGOS DE ASFALTO, DE BREU DE ALCATRAO DE HULHA E DE MATERIAIS SIMILARES; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "FOSFATOS DE CALCIO NATURAIS (APATITA); BENEFICIAMENTO NAO ASSOCIADO A EXTRACAO",
  },
  { cnae: "2399199", descricao: "PRODUTOS DE TURFA; FABRICACAO DE" },
  {
    cnae: "2399199",
    descricao:
      "PASTAS CARBONADAS PARA ELETRODOS, PARA REVESTIMENTO DE FORNOS E OUTROS USOS; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "VERMICULITA, ARGILAS E PRODUTOS MINERAIS SEMELHANTES EXPANDIDOS, MESMO MISTURADOS ENTRE SI; FABRICACAO DE",
  },
  { cnae: "2399199", descricao: "CADINHOS DE GRAFITA; FABRICACAO DE" },
  {
    cnae: "2422902",
    descricao:
      "BOBINAS OU CHAPAS DE ACOS SILICIOSAS, INCLUSIVE TIRAS; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "ARTEFATOS DE GRAFITA, N.E., (EXCETO MINAS PARA LAPISEIRAS E SEMELHANTES, E ARTEFATOS PARA USO ELETRICO); FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "PISOS DE ALTA RESISTENCIA PARA APLICACOES INDUSTRIAIS E/OU CONSTRUCAO; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "ARTIGOS DE LA DE VIDRO PARA ISOLAMENTO TERMICO E ACUSTICO; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "ASFALTOS PREPARADOS OU MISTURAS BETUMINOSAS A BASE DE ASFALTO OU BETUME (OUT-BACKS) UTILIZADOS PRINCIPALMENTE PARA REVESTIMENTO DE ESTRADAS (EXCETO ASFALTO DE REFINARIAS)",
  },
  { cnae: "2399199", descricao: "MASSA PARA CALAFATE; FABRICACAO DE" },
  { cnae: "2399199", descricao: "FOSFATO DE CALCIO; FABRICACAO DE" },
  {
    cnae: "2399199",
    descricao:
      "GUARNICOES DE FRICCAO (PLACAS, ROLOS, TIRAS, SEGMENTOS, DISCOS, ANEIS, PASTILHAS, ETC), NAO MONTADAS, PARA FREIOS OU OUTRO MECANISMO DE FRICCAO, DE SUBSTANCIAS MINERAIS OU DE CELULOSE, MESMO COMBINADAS COM OUTRAS MATERIAS; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "CAULIM BENEFICIADO, BENEFICIAMENTO NAO ASSOCIADO A EXTRACAO; PRODUCAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "LONAS DE FREIO A BASE DE SUBSTANCIAS MINERAIS OU CELULOSE, NAO MONTADAS; FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao: "FELDSPATO, BENEFICIAMENTO NAO ASSOCIADO A EXTRACAO",
  },
  {
    cnae: "2399199",
    descricao: "TARMACADAME (PEDRA BRITADA AGLUTINADA); PRODUCAO DE",
  },
  {
    cnae: "2399199",
    descricao: "ANEIS DE GRAFITA (INCLUSIVE PARA VEICULOS); FABRICACAO DE",
  },
  { cnae: "2399199", descricao: "TECIDOS DE AMIANTO; FABRICACAO DE" },
  {
    cnae: "2399199",
    descricao: "BETUMES FLUIDIFICADOS (CUT-BACKS); FABRICACAO DE",
  },
  {
    cnae: "2399199",
    descricao:
      "AREIA PREPARADA PARA MOLDES UTILIZADOS EM FUNDICAO; FABRICACAO DE",
  },
  { cnae: "2399199", descricao: "CORINDO ARTIFICIAL; FABRICACAO DE" },
  {
    cnae: "2399199",
    descricao: "MOLDES DE AREIA PARA FUNDICAO; FABRICACAO DE",
  },
  { cnae: "2399199", descricao: "MASTIQUES BETUMINOSOS; FABRICACAO DE" },
  { cnae: "24113", descricao: "PRODUCAO DE FERROGUSA" },
  { cnae: "2411300", descricao: "FERRO-GUSA; PRODUCAO DE" },
  {
    cnae: "2411300",
    descricao: "ESCORIAS E OUTROS RESIDUOS DA PRODUCAO DO FERRO GUSA",
  },
  {
    cnae: "2411300",
    descricao:
      "PRODUTOS FERROSOS OBTIDOS PELA REDUCAO DIRETA DOS MINERIOS DE FERRO E OUTROS PRODUTOS FERROSOS ESPONJOSOS (FERRO-ESPONJA); FABRICACAO DE",
  },
  { cnae: "24121", descricao: "PRODUCAO DE FERROLIGAS" },
  {
    cnae: "2412100",
    descricao: "LIGAS DE METAIS EM FORMAS PRIMARIAS; PRODUCAO DE",
  },
  {
    cnae: "2412100",
    descricao: "FERROTITANIO EM FORMAS PRIMARIAS; PRODUCAO DE",
  },
  {
    cnae: "2412100",
    descricao: "FERROMANGANES EM FORMAS PRIMARIAS; PRODUCAO DE",
  },
  {
    cnae: "2412100",
    descricao: "FERROSSILICIO-MANGANES EM FORMAS PRIMARIAS; PRODUCAO DE",
  },
  {
    cnae: "2412100",
    descricao: "FERROFOSFOROS EM FORMAS PRIMARIAS; PRODUCAO DE",
  },
  {
    cnae: "2412100",
    descricao: "FERRO E ACOS-LIGAS EM FORMAS PRIMARIAS; PRODUCAO DE",
  },
  { cnae: "2412100", descricao: "FERROCROMO EM FORMAS PRIMARIAS; PRODUCAO DE" },
  {
    cnae: "2412100",
    descricao: "FERROSSILICIO EM FORMAS PRIMARIAS; PRODUCAO DE",
  },
  {
    cnae: "2412100",
    descricao: "METAIS LIGADOS EM FORMAS PRIMARIAS; PRODUCAO DE",
  },
  {
    cnae: "2412100",
    descricao: "FERRONIQUEL EM FORMAS PRIMARIAS; PRODUCAO DE",
  },
  { cnae: "2412100", descricao: "FERROLIGAS EM FORMAS PRIMARIAS; PRODUCAO DE" },
  {
    cnae: "2412100",
    descricao: "FERROMOLIBIDENIO EM FORMAS PRIMARIAS; PRODUCAO DE",
  },
  {
    cnae: "2412100",
    descricao: "FERROVANADIO EM FORMAS PRIMARIAS; PRODUCAO DE",
  },
  { cnae: "2412100", descricao: "INOCULANTES PARA FUNDICAO; FABRICACAO DE" },
  {
    cnae: "2412100",
    descricao: "FERROTUNGSTENIO EM FORMAS PRIMARIAS; PRODUCAO DE",
  },
  {
    cnae: "2412100",
    descricao: "FERRONIOBIO EM FORMAS PRIMARIAS; PRODUCAO DE",
  },
  {
    cnae: "2412100",
    descricao:
      "FERROLIGAS, NAO ESPECIFICADOS (FERROMOLIBDENIO, FERROTITANIO, FERROTUNGSTENIO, FERROVANADIO, ETC.); INOCULANTES PARA FUNDICAO; FABRICACAO DE",
  },
  {
    cnae: "2412100",
    descricao: "ESCORIAS E OUTROS RESIDUOS DA PRODUCAO DE FERROLIGAS",
  },
  { cnae: "24211", descricao: "PRODUCAO DE SEMIACABADOS DE ACO" },
  {
    cnae: "2421100",
    descricao:
      "BLOCOS, TARUGOS, BILETES E PALANQUILHAS DE ACOS ESPECIAIS; PRODUCAO DE",
  },
  {
    cnae: "2421100",
    descricao: "FERRO E ACO EM FORMAS PRIMARIAS; PRODUCAO DE",
  },
  {
    cnae: "2421100",
    descricao: "FERRO E ACO DOCE EM FORMAS PRIMARIAS; PRODUCAO DE",
  },
  { cnae: "2421100", descricao: "TARUGOS DE ACO CARBONO; PRODUCAO DE" },
  { cnae: "2421100", descricao: "ACO LIQUIDO; PRODUCAO DE" },
  {
    cnae: "2421100",
    descricao:
      "BLOCOS, TARUGOS, BILETES E PALANQUILHAS DE ACO CARBONO; PRODUCAO DE",
  },
  {
    cnae: "2421100",
    descricao:
      "DESPERDICIOS, RESIDUOSOU SUCATAS DA PRODUCAO DE SEMIACABADOS DE ACOS; FABRICACAO DE",
  },
  { cnae: "2421100", descricao: "PLACAS DE ACOS ESPECIAIS; PRODUCAO DE" },
  { cnae: "2421100", descricao: "ACOS SEMI-ACABADOS; PRODUCAO DE" },
  { cnae: "2421100", descricao: "TARUGOS DE ACOS ESPECIAIS; PRODUCAO DE" },
  { cnae: "2421100", descricao: "LINGOTES DE ACO CARBONO; PRODUCAO DE" },
  { cnae: "2421100", descricao: "LINGOTES DE ACOS ESPECIAIS; PRODUCAO DE" },
  { cnae: "2421100", descricao: "PLACAS DE ACO CARBONO; PRODUCAO DE" },
  {
    cnae: "2421100",
    descricao: "LINGOTES REFUNDIDOS DE FERRO OU ACO; FABRICACAO DE",
  },
  {
    cnae: "2421100",
    descricao:
      "GRANALHAS E POS DE FERRO FUNDIDO OU DE FERRO E ACO; OBTENCAO DE",
  },
  { cnae: "24229", descricao: "PRODUCAO DE LAMINADOS PLANOS DE ACO" },
  { cnae: "2422901", descricao: "BOBINAS DE FOLHAS-DE-FLANDRES; PRODUCAO DE" },
  { cnae: "2422901", descricao: "TIRAS E FITAS DE ACO, N.E.; PRODUCAO DE" },
  {
    cnae: "2422901",
    descricao: "CHAPAS DE ACO CARBONO, A FRIO, NAO REVESTIDAS; PRODUCAO DE",
  },
  {
    cnae: "2422901",
    descricao:
      "BOBINAS OU CHAPAS DE ACOS AO CARBONO, REVESTIDOS DE CHUMBO, ALUMINIO, PLASTICO OU OUTRO MATERIAL, PINTADAS OU ENVERNIZADAS; FABRICACAO DE",
  },
  {
    cnae: "2422901",
    descricao: "TIRAS FINAS DE ACO CARBONO, A FRIO, NAO REVESTIDA; PRODUCAO DE",
  },
  { cnae: "2422901", descricao: "LAMINADOS PLANOS DE ACO; PRODUCAO DE" },
  {
    cnae: "2422901",
    descricao: "FITAS DE ACO CARBONO, A FRIO, NAO REVESTIDAS; PRODUCAO DE",
  },
  {
    cnae: "2422901",
    descricao: "BOBINAS DE ACO CARBONO, A QUENTE, NAO REVESTIDAS; PRODUCAO DE",
  },
  {
    cnae: "2422901",
    descricao: "BOBINAS, CHAPAS, FITAS E TIRAS DE ACO REVESTIDAS; PRODUCAO DE",
  },
  {
    cnae: "2422901",
    descricao: "BOBINAS DE ACO CARBONO, A FRIO, NAO REVESTIDAS; PRODUCAO DE",
  },
  { cnae: "2422901", descricao: "TIRAS DE ACO REVESTIDAS; PRODUCAO DE" },
  { cnae: "2422901", descricao: "FITAS DE ACO REVESTIDAS; PRODUCAO DE" },
  {
    cnae: "2422901",
    descricao:
      "ESCORIAS E OUTROS RESIDUOS DA PRODUCAO DE LAMINADOS PLANOS DE ACO CARBONO",
  },
  {
    cnae: "2422901",
    descricao: "FOLHAS E BOBINAS DE FOLHAS DE ACO, NAO REVESTIDAS; PRODUCAO DE",
  },
  {
    cnae: "2422901",
    descricao: "BOBINAS GROSSAS DE ACO CARBONO, NAO REVESTIDAS; PRODUCAO DE",
  },
  { cnae: "2422901", descricao: "FOLHAS-DE-FLANDRES; PRODUCAO DE" },
  {
    cnae: "2422901",
    descricao: "CHAPAS DE ACO CARBONO, A QUENTE, NAO REVESTIDAS; PRODUCAO DE",
  },
  { cnae: "2422901", descricao: "CHAPAS DE ACO REVESTIDAS; PRODUCAO DE" },
  {
    cnae: "2422901",
    descricao: "FITAS DE ACO CARBONO, A QUENTE, NAO REVESTIDAS; PRODUCAO DE",
  },
  {
    cnae: "2422901",
    descricao:
      "TIRAS FINAS DE ACO CARBONO, A QUENTE, NAO REVESTIDAS; PRODUCAO DE",
  },
  {
    cnae: "2422901",
    descricao: "CHAPAS GROSSAS DE ACO CARBONO, NAO REVESTIDAS; PRODUCAO DE",
  },
  { cnae: "2422902", descricao: "CHAPAS DE ACO SILICIO; PRODUCAO DE" },
  { cnae: "3211601", descricao: "ESMERALDA, LAPIDACAO DE" },
  {
    cnae: "2422902",
    descricao:
      "ESCORIAS E OUTROS RESIDUOS DA PRODUCAO DE LAMINADOS PLANOS DE ACOS ESPECIAIS",
  },
  {
    cnae: "2422902",
    descricao:
      "BOBINAS OU CHAPAS DE ACOS INOXIDAVEIS, INCLUSIVE TIRAS; FABRICACAO DE",
  },
  { cnae: "2422902", descricao: "LAMINADOS PLANOS DE FERROCROMO; PRODUCAO DE" },
  {
    cnae: "2422902",
    descricao:
      "DESPERDICIOS, RESIDUOS OU SUCATAS DA PRODUCAO DE LAMINADOS PLANOS DE ACOS; FABRICACAO DE",
  },
  { cnae: "2422902", descricao: "CHAPAS DE ACOS ESPECIAIS; PRODUCAO DE" },
  {
    cnae: "2422902",
    descricao:
      "BOBINAS OU CHAPAS DE ACOS DE CORTE RAPIDO (ESPECIAIS), INCLUSIVE TIRAS; FABRICACAO DE",
  },
  { cnae: "2422902", descricao: "CHAPAS DE ACOS INOXIDAVEIS; PRODUCAO DE" },
  { cnae: "2422902", descricao: "TIRAS E FITAS DE ACO ESPECIAIS; PRODUCAO DE" },
  {
    cnae: "2422902",
    descricao:
      "BOBINAS OU CHAPAS DE ACOS ZINCADAS (GALVANIZADAS); FABRICACAO DE",
  },
  { cnae: "2422902", descricao: "LAMINADOS PLANOS DE FERROLIGAS; PRODUCAO DE" },
  { cnae: "2422902", descricao: "BOBINAS OU CHAPAS CROMADAS; FABRICACAO DE" },
  {
    cnae: "2422902",
    descricao: "LAMINADOS PLANOS DE FERROMANGANES; PRODUCAO DE",
  },
  { cnae: "24237", descricao: "PRODUCAO DE LAMINADOS LONGOS DE ACO" },
  {
    cnae: "2423701",
    descricao:
      "TUBOS E CANOS DE ACO SEM COSTURA, INCLUSIVE CONEXOES; PRODUCAO DE",
  },
  {
    cnae: "2423701",
    descricao:
      "DESPERDICIOS, RESIDUOS OU SUCATAS DA PRODUCAO DE LAMINADOS LONGOS DE ACOS; FABRICACAO DE",
  },
  { cnae: "2423702", descricao: "BARRAS DE ACO ESPECIAL; PRODUCAO DE" },
  {
    cnae: "2423702",
    descricao: "LAMINADOS LONGOS (NAO-PLANOS) DE FERROLIGAS; PRODUCAO DE",
  },
  { cnae: "2423702", descricao: "VERGALHOES DE ACO, LISOS; PRODUCAO DE" },
  {
    cnae: "2423702",
    descricao: "BARRAS TREFILADOS DE ACO CARBONO; PRODUCAO DE",
  },
  { cnae: "2423702", descricao: "PERFIS MEDIOS DE ACO; PRODUCAO DE" },
  { cnae: "2423702", descricao: "FIO-MAQUINA DE ACOS FERRAMENTA; PRODUCAO DE" },
  { cnae: "2423702", descricao: "FIO-MAQUINA DE ACO CARBONO; PRODUCAO DE" },
  {
    cnae: "2423702",
    descricao:
      "BARRAS DE ACO CARBONO (REDONDAS, QUADRADAS, CHATAS E OUTRAS); PRODUCAO DE",
  },
  { cnae: "2423702", descricao: "TRILHOS DE ACO PARA FERROVIAS; PRODUCAO DE" },
  { cnae: "2423702", descricao: "PERFIS PESADOS DE ACO; PRODUCAO DE" },
  { cnae: "2423702", descricao: "PERFIS LEVES DE ACO; PRODUCAO DE" },
  {
    cnae: "2423702",
    descricao: "BARRAS TREFILADOS DE ACO LIGADO; PRODUCAO DE",
  },
  {
    cnae: "2423702",
    descricao: "FIO-MAQUINA DE ACOS INOXIDAVEIS; PRODUCAO DE",
  },
  {
    cnae: "2423702",
    descricao: "LAMINADOS LONGOS DE ACO CARBONO E LIGADOS; PRODUCAO DE",
  },
  { cnae: "2423702", descricao: "VERGALHOES DE ACO, NERVURADOS; PRODUCAO DE" },
  {
    cnae: "2423702",
    descricao: "BARRAS LAMINADAS DE ACOS FERRAMENTA E RAPIDO; PRODUCAO DE",
  },
  {
    cnae: "2423702",
    descricao: "BARRAS LAMINADAS DE ACOS INOXIDAVEIS; PRODUCAO DE",
  },
  {
    cnae: "2423702",
    descricao: "BARRAS DE ACO CONSTRUCAO MECANICA; PRODUCAO DE",
  },
  { cnae: "2423702", descricao: "ESTACAS-PRANCHA DE ACOS; FABRICACAO DE" },
  {
    cnae: "2423702",
    descricao:
      "ESCORIAS E OUTROS RESIDUOS DA PRODUCAO DE LAMINADOS LONGOS DE ACO",
  },
  {
    cnae: "2423702",
    descricao: "FIO-MAQUINA DE ACO CONSTRUCAO MECANICA; PRODUCAO DE",
  },
  {
    cnae: "24245",
    descricao: "PRODUCAO DE RELAMINADOS, TREFILADOS E PERFILADOS DE ACO",
  },
  { cnae: "2424501", descricao: "ARAMES DE ACO COBREADOS; PRODUCAO DE" },
  { cnae: "2424501", descricao: "ARAMES DE ACO OVALADOS; PRODUCAO DE" },
  { cnae: "2424501", descricao: "TREFILARIA (PRODUCAO DE ARAMES)" },
  {
    cnae: "2424501",
    descricao: "ARAMES DE ACO PARA CONSTRUCAO CIVIL; PRODUCAO DE",
  },
  { cnae: "2424501", descricao: "ARAME DE ACO CARBONO; PRODUCAO DE" },
  {
    cnae: "2424501",
    descricao:
      "ARAMES E FIOS DE ACOS INOXIDAVEIS OU DE OUTRAS LIGAS DE ACOS; FABRICACAO DE",
  },
  { cnae: "2424501", descricao: "ARAMES DE ACO GALVANIZADOS; PRODUCAO DE" },
  { cnae: "2424501", descricao: "ARAME FARPADO DE ACO CARBONO; PRODUCAO DE" },
  { cnae: "2424501", descricao: "ARAMES DE ACO NAO REVESTIDOS; PRODUCAO DE" },
  { cnae: "2424501", descricao: "ARAME DE ACOS ESPECIAIS; PRODUCAO DE" },
  { cnae: "2424501", descricao: "ARAME RETREFILADO; PRODUCAO DE" },
  {
    cnae: "2424502",
    descricao: "CHAPAS DE ACOS INOXIDAVEIS, RELAMINADAS; PRODUCAO DE",
  },
  {
    cnae: "2424502",
    descricao: "PERFIS DE ACOS AO CARBONO CONFORMADOS A FRIO; FABRICACAO DE",
  },
  {
    cnae: "2424502",
    descricao: "CHAPAS DE ACO CARBONO, RELAMINADAS; PRODUCAO DE",
  },
  {
    cnae: "2424502",
    descricao: "CHAPAS DE FERRO E ACO ZINCADAS, RELAMINADAS; PRODUCAO DE",
  },
  {
    cnae: "2424502",
    descricao: "ACESSORIOS DE ACO PARA TRILHOS PARA VIAS FERREAS; PRODUCAO DE",
  },
  { cnae: "2424502", descricao: "PERFIS DE ACO, SOLDADOS; PRODUCAO DE" },
  {
    cnae: "2424502",
    descricao: "CHAPAS DE ACO CROMADAS, RELAMINADAS; PRODUCAO DE",
  },
  {
    cnae: "2424502",
    descricao: "BOBINAS DE ACO CARBONO, RELAMINADAS; PRODUCAO DE",
  },
  {
    cnae: "2424502",
    descricao: "VERGALHOES DE ACO, LISOS, RELAMINADOS; PRODUCAO DE",
  },
  { cnae: "2424502", descricao: "RETREFILADOS DE ACO; PRODUCAO DE" },
  {
    cnae: "2424502",
    descricao: "CHAPAS DE ACO CHUMBADAS, RELAMINADAS; PRODUCAO DE",
  },
  {
    cnae: "2424502",
    descricao: "VERGALHOES DE ACO, NERVURADOS, RELAMINADOS; PRODUCAO DE",
  },
  {
    cnae: "2424502",
    descricao:
      "BARRAS, VERGALHOES, FIO-MAQUINA E OUTROS PRODUTOS LONGOS DE ACO, RELAMINADOS; FABRICACAO DE",
  },
  {
    cnae: "2424502",
    descricao: "FITAS DE ACOS ESPECIAIS, RELAMINADAS; PRODUCAO DE",
  },
  {
    cnae: "2424502",
    descricao: "BARRAS DE ACO CARBONO, RELAMINADAS; PRODUCAO DE",
  },
  {
    cnae: "2424502",
    descricao: "BARRAS DE ACO ESPECIAL, RELAMINADAS; PRODUCAO DE",
  },
  {
    cnae: "2424502",
    descricao: "FITAS DE ACO CARBONO, RELAMINADAS; PRODUCAO DE",
  },
  {
    cnae: "2424502",
    descricao: "TIRAS DE ACOS ESPECIAIS, RELAMINADAS; PRODUCAO DE",
  },
  {
    cnae: "2424502",
    descricao: "CHAPAS DE ACO AO SILICIO, RELAMINADAS; PRODUCAO DE",
  },
  { cnae: "2424502", descricao: "RELAMINADOS DE FERRO E ACO; PRODUCAO DE" },
  { cnae: "2424502", descricao: "RETREFILARIA, RELAMINACAO" },
  { cnae: "2424502", descricao: "PERFIS DE ACO, ESTAMPADOS; PRODUCAO DE" },
  {
    cnae: "2424502",
    descricao:
      "BOBINAS, CHAPAS, FITAS E TIRAS FINAS DE ACO CARBONO, A FRIO, NAO REVESTIDAS, RELAMINADAS; PRODUCAO DE",
  },
  { cnae: "2424502", descricao: "FIO-MAQUINA, RELAMINADO; PRODUCAO DE" },
  {
    cnae: "2424502",
    descricao: "CHAPAS DE ACOS ESPECIAIS, RELAMINADAS; PRODUCAO DE",
  },
  {
    cnae: "2424502",
    descricao: "TIRAS DE ACO CARBONO, RELAMINADAS; PRODUCAO DE",
  },
  { cnae: "2424502", descricao: "RETREFILADOS, RELAMINADOS, PERFILADOS" },
  { cnae: "24318", descricao: "PRODUCAO DE TUBOS DE ACO COM COSTURA" },
  {
    cnae: "2431800",
    descricao:
      "TUBOS, CANOS E PERFIS OCOS DE ACO, COM COSTURA, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2431800",
    descricao:
      "TUBOS DE ACO COM COSTURA UTILIZADOS NA PROSPECAO DE PETROLEO E GAS; PRODUCAO DE",
  },
  {
    cnae: "2431800",
    descricao:
      "TUBOS E CANOS DE ACO COM COSTURA, INCLUSIVE CONEXOES; PRODUCAO DE",
  },
  {
    cnae: "2431800",
    descricao:
      "ACESSORIOS PARA TUBOS OU CANOS DE ACO COM COSTURA; FABRICACAO DE",
  },
  {
    cnae: "2431800",
    descricao:
      "TUBOS DE ACO COM COSTURA UTILIZADOS EM GASODUTOS OU OLEODUTOS; PRODUCAO DE",
  },
  { cnae: "24393", descricao: "PRODUCAO DE OUTROS TUBOS DE FERRO E ACO" },
  {
    cnae: "2439300",
    descricao: "TUBOS TREFILADOS DE ACO, INCLUSIVE CONEXOES; PRODUCAO DE",
  },
  { cnae: "2439300", descricao: "TUBOS RETREFILADOS DE ACO; PRODUCAO DE" },
  {
    cnae: "2439300",
    descricao:
      "ACESSORIOS PARA TUBOS OU CANOS DE FERRO FUNDIDOS, TREFILADOS, FLEXIVEIS E OUTROS; FABRICACAO DE",
  },
  {
    cnae: "2439300",
    descricao:
      "TUBOS E CANOS DE FERRO FUNDIDO, INCLUSIVE CONEXOES; PRODUCAO DE",
  },
  {
    cnae: "2439300",
    descricao: "TUBOS DE ACO FLEXIVEIS, INCLUSIVE CONEXOES; PRODUCAO DE",
  },
  { cnae: "24415", descricao: "METALURGIA DO ALUMINIO E SUAS LIGAS" },
  { cnae: "2441501", descricao: "ALUMINIO LIQUIDO; PRODUCAO DE" },
  {
    cnae: "2441501",
    descricao:
      "ACESSORIOS PARA TUBOS E ALUMINIO (UNIOES, COTOVELOS, LUVAS, ETC.); FABRICACAO DE",
  },
  { cnae: "2441501", descricao: "ALUMINA; FABRICACAO DE" },
  {
    cnae: "2441501",
    descricao:
      "LIGAS DE ALUMINIO EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  {
    cnae: "2441501",
    descricao: "OXIDO DE ALUMINIO (ALUMINA CALCINADA); FABRICACAO DE",
  },
  {
    cnae: "2441501",
    descricao: "DESPERDICIOS E RESIDUOS DE ALUMINIO; FABRICACAO DE",
  },
  { cnae: "2441501", descricao: "ALUMINIO EM PO; FABRICACAO DE" },
  {
    cnae: "2441501",
    descricao:
      "LIGAS DE ALUMINIO EM FORMAS BRUTAS (LINGOTES, PLAQUETAS, GRANALHAS, ETC.); FABRICACAO DE",
  },
  { cnae: "2441501", descricao: "TUBOS DE LIGAS DE ALUMINIO; FABRICACAO DE" },
  {
    cnae: "2441501",
    descricao: "ALUMINIO EM LINGOTES E OUTRAS FORMAS PRIMARIAS; PRODUCAO DE",
  },
  {
    cnae: "2441501",
    descricao: "TUBOS DE ALUMINIO NAO LIGADOS; FABRICACAO DE",
  },
  {
    cnae: "2441501",
    descricao:
      "CINZAS E RESIDUOS CONTENDO PRINCIPALMENTE ALUMINIO; FABRICACAO DE",
  },
  {
    cnae: "2441501",
    descricao:
      "ALUMINIO NAO LIGADO EM FORMAS BRUTAS (LIQUIDO, MASSA, LINGOTES, BILETES, GRANALHAS, ETC.); FABRICACAO DE",
  },
  { cnae: "2441501", descricao: "FIOS DE LIGAS DE ALUMINIO; FABRICACAO DE" },
  { cnae: "2441501", descricao: "FIOS DE ALUMINIO NAO LIGADOS; FABRICACAO DE" },
  { cnae: "2441501", descricao: "ALUMINIO SEMI-ACABADO; PRODUCAO DE" },
  { cnae: "2441502", descricao: "CONEXOES DE ZAMAK; PRODUCAO DE" },
  { cnae: "2441502", descricao: "PERFIS DE ZAMAK; PRODUCAO DE" },
  { cnae: "2441502", descricao: "VERGALHOES DE ALUMINIO; PRODUCAO DE" },
  { cnae: "2441502", descricao: "PERFIS DE ALUMINIO; PRODUCAO DE" },
  {
    cnae: "2441502",
    descricao: "CHAPAS E TIRAS DE ALUMINIO E SUAS LIGAS; PRODUCAO DE",
  },
  { cnae: "2441502", descricao: "CANOS E TUBOS DE ALUMINIO; PRODUCAO DE" },
  { cnae: "2441502", descricao: "PERFIS DE TOMBAK; PRODUCAO DE" },
  { cnae: "2441502", descricao: "CANOS E TUBOS DE ZAMAK; PRODUCAO DE" },
  { cnae: "2441502", descricao: "CONEXOES DE ALUMINIO; PRODUCAO DE" },
  { cnae: "2441502", descricao: "LAMINADOS LONGOS DE ALUMINIO; PRODUCAO DE" },
  { cnae: "2441502", descricao: "CONEXOES DE TOMBAK; PRODUCAO DE" },
  { cnae: "2441502", descricao: "RELAMINADOS DE ALUMINIO; PRODUCAO DE" },
  {
    cnae: "2441502",
    descricao: "LAMINADOS PLANOS DE LIGAS DE ALUMINIO; PRODUCAO DE",
  },
  { cnae: "2441502", descricao: "ARAMES E FIOS DE ALUMINIO; PRODUCAO DE" },
  {
    cnae: "2441502",
    descricao: "VERGALHOES DE LIGAS DE ALUMINIO; PRODUCAO DE",
  },
  {
    cnae: "2441502",
    descricao:
      "FOLHA DE ALUMINIO RELAMINADA (PAPEL ALUMINIO) PARA EMBALAGEM; PRODUCAO DE",
  },
  {
    cnae: "2441502",
    descricao:
      "FOLHAS E TIRAS, DELGADAS, DE ALUMINIO (MESMO IMPRESSAS OU COM SUPORTE), DE ESPESSURA NAO SUPERIOR A 0,2MM; PAPEL ALUMINIO; FABRICACAO DE",
  },
  { cnae: "2441502", descricao: "BARRAS DE ZAMAK; PRODUCAO DE" },
  { cnae: "2441502", descricao: "CANOS E TUBOS DE TOMBAK; PRODUCAO DE" },
  { cnae: "2441502", descricao: "POS E PARTICULAS DE ALUMINIO; PRODUCAO DE" },
  { cnae: "2441502", descricao: "BARRAS DE ALUMINIO; PRODUCAO DE" },
  {
    cnae: "2441502",
    descricao: "LAMINADOS LONGOS DE LIGAS DE ALUMINIO; PRODUCAO DE",
  },
  { cnae: "2441502", descricao: "LAMINADOS PLANOS DE ZAMAK; PRODUCAO DE" },
  { cnae: "2441502", descricao: "PAPEL ALUMINIO; FABRICACAO DE" },
  { cnae: "2441502", descricao: "BARRAS DE TOMBAK; PRODUCAO DE" },
  { cnae: "2441502", descricao: "LAMINADOS PLANOS DE ALUMINIO; PRODUCAO DE" },
  {
    cnae: "2441502",
    descricao: "RELAMINADOS DE LIGAS DE ALUMINIO; PRODUCAO DE",
  },
  { cnae: "2441502", descricao: "LAMINADOS PLANOS DE TOMBAK; PRODUCAO DE" },
  { cnae: "24423", descricao: "METALURGIA DOS METAIS PRECIOSOS" },
  {
    cnae: "2442300",
    descricao:
      "PALADIO EM BARRAS, FIOS, PERFIS, CHAPAS E OUTRAS FORMAS LAMINADAS; PRODUCAO DE",
  },
  { cnae: "2442300", descricao: "LAMINADOS DE OURO; PRODUCAO DE" },
  {
    cnae: "2442300",
    descricao:
      "METAIS COMUNS FOLHEADOS OU CHAPEADOS DE PRATA EM FORMAS BRUTAS, SEMIMANUFATURADAS OU EM PO; FABRICACAO DE",
  },
  {
    cnae: "2442300",
    descricao: "PRATA EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  {
    cnae: "2442300",
    descricao: "OURO EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  { cnae: "2442300", descricao: "LAMINADOS DE METAIS PRECIOSOS; PRODUCAO DE" },
  { cnae: "2442300", descricao: "PRATA GRANULADA; PRODUCAO DE" },
  {
    cnae: "2442300",
    descricao: "DESPERDICIOS DE METAIS PRECIOSOS; FABRICACAO DE",
  },
  {
    cnae: "2442300",
    descricao: "PLATINA EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  { cnae: "2442300", descricao: "FIOS DE METAIS PRECIOSOS; PRODUCAO DE" },
  { cnae: "2442300", descricao: "METALURGIA DOS METAIS PRECIOSOS" },
  { cnae: "2442300", descricao: "OURO GRANULADO; PRODUCAO DE" },
  { cnae: "2442300", descricao: "LAMINADOS DE PRATA; PRODUCAO DE" },
  {
    cnae: "2442300",
    descricao:
      "PALADIO EM LINGOTES OU OUTRAS FORMAS BRUTAS OU SEMIACABADAS; PRODUCAO DE",
  },
  { cnae: "2442300", descricao: "LAMINADOS DE PLATINA; PRODUCAO DE" },
  { cnae: "24431", descricao: "METALURGIA DO COBRE" },
  {
    cnae: "2443100",
    descricao:
      "FIOS DE COBRE REFINADO OU DE LIGAS DE COBRE (LATAO, CUPRONIQUEL, MAILLECHORT, ETC.); FABRICACAO DE",
  },
  {
    cnae: "2443100",
    descricao:
      "TUBOS, CANOS E ACESSORIOS (UNIOES, COTOVELOS, LUVAS, ETC.) DE COBRE E DE LIGAS DE COBRE; FABRICACAO DE",
  },
  {
    cnae: "2443100",
    descricao:
      "COTOVELOS, UNIOES E OUTROS ACESSORIOS PARA TUBOS DE COBRE OU DE LIGAS DE COBRE; FABRICACAO DE",
  },
  { cnae: "2443100", descricao: "POS E ESCAMAS DE COBRE; FABRICACAO DE" },
  {
    cnae: "2443100",
    descricao: "COBRE EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  { cnae: "2443100", descricao: "VERGALHOES DE COBRE; PRODUCAO DE" },
  { cnae: "2443100", descricao: "PERFIS DE COBRE; PRODUCAO DE" },
  {
    cnae: "2443100",
    descricao: "CHAPAS E TIRAS DE COBRE OU DE LIGAS DE COBRE; PRODUCAO DE",
  },
  {
    cnae: "2443100",
    descricao:
      "COBRE NAO REFINADO; ANODOS DE COBRE PARA REFINACAO ELETROLITICA; FABRICACAO DE",
  },
  { cnae: "2443100", descricao: "BARRAS DE COBRE; PRODUCAO DE" },
  { cnae: "2443100", descricao: "CONEXOES DE COBRE; PRODUCAO DE" },
  {
    cnae: "2443100",
    descricao:
      "LIGAS DE COBRE EM FORMAS PRIMARIAS OU SEMIACABADAS, EXCETO BRONZE E LATAO; PRODUCAO DE",
  },
  { cnae: "2443100", descricao: "MATES DE COBRE; PRODUCAO DE" },
  { cnae: "2443100", descricao: "LAMINADOS PLANOS DE COBRE; PRODUCAO DE" },
  { cnae: "2443100", descricao: "CANOS E TUBOS DE COBRE; PRODUCAO DE" },
  { cnae: "2443100", descricao: "ARAMES E FIOS DE COBRE; PRODUCAO DE" },
  {
    cnae: "2443100",
    descricao:
      "CINZAS E RESIDUOS CONTENDO PRINCIPALMENTE COBRE OU SEUS COMPOSTOS; FABRICACAO DE",
  },
  {
    cnae: "2443100",
    descricao:
      "DESPERDICIOS DE COBRE (LAMAS PROVENIENTES DA FABRICACAO DE FIOS DE COBRE); FABRICACAO DE",
  },
  {
    cnae: "24491",
    descricao:
      "METALURGIA DOS METAIS NAOFERROSOS E SUAS LIGAS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "2449101",
    descricao:
      "LIGAS DE ZINCO EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  {
    cnae: "2449101",
    descricao: "ZINCO EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  {
    cnae: "2449101",
    descricao:
      "ZINCO E LIGAS DE ZINCO EM FORMAS BRUTAS (LINGOTES, PLACAS, ETC.); FABRICACAO DE",
  },
  {
    cnae: "2449102",
    descricao:
      "TUBOS, CANOS E SEUS ACESSORIOS (UNIOES, COTOVELOS, LUVAS, ETC.), DE ZINCO; FABRICACAO DE",
  },
  { cnae: "2449102", descricao: "CONEXOES DE ZINCO; PRODUCAO DE" },
  { cnae: "2449102", descricao: "PERFIS DE ZINCO; PRODUCAO DE" },
  { cnae: "2449102", descricao: "BARRAS DE ZINCO; PRODUCAO DE" },
  { cnae: "2449102", descricao: "LAMINADOS PLANOS DE ZINCO; PRODUCAO DE" },
  { cnae: "2449102", descricao: "CANOS E TUBOS DE ZINCO; PRODUCAO DE" },
  {
    cnae: "2449103",
    descricao: "SOLDA EM BARRAS, FIOS, TUBOS E VARETAS; PRODUCAO DE",
  },
  { cnae: "2449103", descricao: "ANODOS E CATODOS; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao:
      "TUBOS FLEXIVEIS DE METAIS NAO-FERROSOS, EXCETO ALUMINIO E ZINCO; PRODUCAO DE",
  },
  { cnae: "2449199", descricao: "BARRAS DE METAIS NAO-FERROSOS; PRODUCAO DE" },
  { cnae: "8640213", descricao: "LITOTRIPSIA; SERVICOS DE" },
  {
    cnae: "2449199",
    descricao: "LINGOTES DE METAIS NAO-FERROSOS, N.E.; PRODUCAO DE",
  },
  { cnae: "2449199", descricao: "CANOS E TUBOS DE BRONZE; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao:
      "URANIO NATURAL E SEUS COMPOSTOS; LIGAS, DISPERSOES (INCLUIDOS OS CERAMAIS (CERMETS), PRODUTOS CERAMICOS E MISTURAS CONTENDO URANIO NATURAL OU COMPOSTOS DE URANIO NATURAL; FABRICACAO DE",
  },
  {
    cnae: "2449199",
    descricao:
      "CERAMAIS (CERMETS) E SUAS OBRAS, INCLUIDOS OS DESPERDICIOS E RESIDUOS; FABRICACAO DE",
  },
  { cnae: "2449199", descricao: "LAMINADOS PLANOS DE LATAO, PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao:
      "LIGAS DE CHUMBO EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  {
    cnae: "2449199",
    descricao:
      "ANTIMONIO, BISMUTO, CROMO, GERMANIO E VANADIO E SUAS OBRAS, INCLUIDOS OS DESPERDICIOS E RESIDUOS; FABRICACAO DE",
  },
  {
    cnae: "2449199",
    descricao: "TUBOS TREFILADOS DE METAIS NAO-FERROSOS, N.E.; PRODUCAO DE",
  },
  { cnae: "2449199", descricao: "CONEXOES DE LATAO, PRODUCAO DE" },
  { cnae: "2449199", descricao: "LAMINADOS PLANOS DE CROMO; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao: "ARAMES E FIOS DE METAIS NAO-FERROSOS, N.E; PRODUCAO DE",
  },
  { cnae: "2449199", descricao: "CONEXOES DE CHUMBO; PRODUCAO DE" },
  { cnae: "2449199", descricao: "PERFIS DE ESTANHO; PRODUCAO DE" },
  { cnae: "2449199", descricao: "PERFIS DE LATAO; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao: "BERILIO (GLUCINIO) EM TODAS AS FORMAS; PRODUCAO DE",
  },
  {
    cnae: "2449199",
    descricao:
      "LIGAS DE METAIS NAO-FERROSOS EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  {
    cnae: "2449199",
    descricao: "LATAO EM FORMAS PRIMARIAS OU SEMIACABADAS, PRODUCAO DE",
  },
  {
    cnae: "2449199",
    descricao:
      "DESPERDICIOS E RESIDUOS DE CHUMBO, ESTANHO, NIQUEL OU ZINCO; FABRICACAO DE",
  },
  { cnae: "2449199", descricao: "PERFIS DE CROMO; PRODUCAO DE" },
  { cnae: "2449199", descricao: "CONEXOES DE ESTANHO; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao: "COBALTO EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  { cnae: "2449199", descricao: "PERFIS DE METAIS NAO-FERROSOS; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao: "TUNGSTENIO EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  { cnae: "2449199", descricao: "CHUMBO EM FORMAS PRIMARIAS; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao: "CANOS E TUBOS DE METAIS NAO-FERROSOS, N.E; PRODUCAO DE",
  },
  {
    cnae: "2449199",
    descricao: "RELAMINADOS DE METAIS NAO-FERROSOS, N.E.; PRODUCAO DE",
  },
  {
    cnae: "2449199",
    descricao: "CONEXOES DE METAIS NAO-FERROSOS, N.E.; PRODUCAO DE",
  },
  { cnae: "2449199", descricao: "GLUCINIO EM TODAS AS FORMAS; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao: "MANGANES EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  { cnae: "2449199", descricao: "PERFIS DE NIQUEL; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao: "METALURGIA DO BRONZE (LIGA DE COBRE-ESTANHO), FABRICACAO DE",
  },
  { cnae: "2449199", descricao: "LAMINADOS DE TUNGSTENIO; PRODUCAO DE" },
  { cnae: "2449199", descricao: "LAMINADOS PLANOS DE BRONZE; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao: "VERGALHOES DE METAIS NAO FERROSOS, N.E.; PRODUCAO DE",
  },
  { cnae: "2449199", descricao: "VERGALHOES DE BRONZE; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao:
      "LAMINADOS LONGOS DE METAIS NAO-FERROSOS E SUAS LIGAS (EXCETO ALUMINIO E ZINCO); PRODUCAO DE",
  },
  {
    cnae: "2449199",
    descricao:
      "METALURGIA DO CUPRONIQUEL (LIGA DE COBRE-NIQUEL); FABRICACAO DE",
  },
  {
    cnae: "2449199",
    descricao: "ZIRCONIO EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  { cnae: "2449199", descricao: "BARRAS DE CROMO; PRODUCAO DE" },
  { cnae: "2449199", descricao: "CANOS E TUBOS DE CHUMBO; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao: "CATODOS DE NIQUEL NAO LIGADO FORMA BRUTO; FABRICACAO DE",
  },
  {
    cnae: "2449199",
    descricao:
      "POS E PARTICULAS DE MINERAIS METALICOS NAO-FERROSOS (EXCETO ALUMINIO E ZINCO); PRODUCAO DE",
  },
  { cnae: "2449199", descricao: "BARRAS E PERFIS DE BRONZE; PRODUCAO DE" },
  { cnae: "2449199", descricao: "BARRAS DE LATAO; PRODUCAO DE" },
  { cnae: "2449199", descricao: "VERGALHOES DE LATAO, PRODUCAO DE" },
  { cnae: "2449199", descricao: "PERFIS DE CHUMBO; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao:
      "COLOMBIO (NIOBIO) EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  { cnae: "2449199", descricao: "CONEXOES DE BRONZE; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao: "NIQUEL EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  {
    cnae: "2449199",
    descricao:
      "LIGAS DE ESTANHO EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  {
    cnae: "2449199",
    descricao:
      "ESCORIAS E OUTROS RESIDUOS DA METALURGIA DOS METAIS NAO-FERROSOS",
  },
  { cnae: "2449199", descricao: "MATES DE NIQUEL; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao: "RETREFILADOS DE METAIS NAO-FERROSOS, N.E.; PRODUCAO DE",
  },
  {
    cnae: "2449199",
    descricao: "VANADIO EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  {
    cnae: "2449199",
    descricao:
      "METALURGIA DE OUTRAS LIGAS DE COBRE MAILLECHORT (COBRE, NIQUEL, ZINCO, ETC), FABRICACAO DE",
  },
  { cnae: "2449199", descricao: "URANIO; FUNDICAO E REFINO DE" },
  {
    cnae: "2449199",
    descricao:
      "CINZAS E RESIDUOS CONTENDO TITANIO, VANADIO, ARSENIO, MERCURIO, CHUMBO, ZINCO OU OUTROS MINERIOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2449199",
    descricao: "CROMO EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  { cnae: "2449199", descricao: "LAMINADOS PLANOS DE CHUMBO; PRODUCAO DE" },
  { cnae: "2449199", descricao: "LAMINADOS PLANOS DE ESTANHO; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao: "MOLIBDENIO EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  {
    cnae: "2449199",
    descricao:
      "METAIS NAO-FERROSOS EM FORMAS PRIMARIAS (EXCETO ALUMINIO E ZINCO); PRODUCAO DE",
  },
  { cnae: "2449199", descricao: "CANOS E TUBOS DE LATAO, PRODUCAO DE" },
  { cnae: "2449199", descricao: "BARRAS DE NIQUEL; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao: "ESTANHO EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  {
    cnae: "2449199",
    descricao: "METALURGIA DO LATAO (LIGA DE COBRE-ZINCO), FABRICACAO DE",
  },
  {
    cnae: "2449199",
    descricao: "LAMINADOS PLANOS DE METAIS NAO-FERROSOS, N.E.; PRODUCAO DE",
  },
  { cnae: "2449199", descricao: "LAMINADOS DE MANGANES; PRODUCAO DE" },
  { cnae: "2449199", descricao: "LAMINADOS PLANOS DE NIQUEL; PRODUCAO DE" },
  { cnae: "2449199", descricao: "CANOS E TUBOS DE ESTANHO; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao: "ANTIMONIO EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  {
    cnae: "2449199",
    descricao: "BRONZE EM FORMAS PRIMARIAS OU SEMIACABADAS; PRODUCAO DE",
  },
  { cnae: "2449199", descricao: "BARRAS DE ESTANHO; PRODUCAO DE" },
  {
    cnae: "2449199",
    descricao: "TIRAS E FITAS DE METAIS NAO-FERROSOS, N.E.; PRODUCAO DE",
  },
  { cnae: "24512", descricao: "FUNDICAO DE FERRO E ACO" },
  { cnae: "2451200", descricao: "SINOS DE METAL; FABRICACAO DE" },
  {
    cnae: "2451200",
    descricao:
      "RALOS, GRELHAS, TAMPOES, CAIXAS SECCIONADORAS E SEMELHANTES DE FERRO FUNDIDO; PRODUCAO DE",
  },
  { cnae: "2451200", descricao: "FUNDICAO DE METAIS FERROSOS; SERVICO DE" },
  { cnae: "2451200", descricao: "METAIS FERROSOS, FUNDICAO DE" },
  { cnae: "2451200", descricao: "PECAS FUNDIDAS DE FERRO E ACO; PRODUCAO DE" },
  {
    cnae: "2451200",
    descricao: "FERRO E ACO FUNDIDO EM FORMAS E PECAS, N.E.; PRODUCAO DE",
  },
  { cnae: "24521", descricao: "FUNDICAO DE METAIS NAOFERROSOS E SUAS LIGAS" },
  {
    cnae: "2452100",
    descricao: "BRONZE FUNDIDO EM FORMAS E PECAS; PRODUCAO DE",
  },
  {
    cnae: "2452100",
    descricao: "NIQUEL FUNDIDO EM FORMAS E PECAS; PRODUCAO DE",
  },
  { cnae: "2452100", descricao: "METAIS NAO FERROSOS, FUNDICAO DE" },
  {
    cnae: "2452100",
    descricao:
      "PECAS FUNDIDAS DE METAIS NAO FERROSOS E SUAS LIGAS; PRODUCAO DE",
  },
  {
    cnae: "2452100",
    descricao: "COBRE FUNDIDO EM FORMAS E PECAS; PRODUCAO DE",
  },
  {
    cnae: "2452100",
    descricao: "METAIS NAO-FERROSOS FUNDIDOS EM FORMAS E PECAS; PRODUCAO DE",
  },
  {
    cnae: "2452100",
    descricao:
      "LIGAS DE METAIS NAO-FERROSOS FUNDIDAS EM FORMAS E PECAS; PRODUCAO DE",
  },
  { cnae: "2452100", descricao: "FUNDICAO DE METAIS NAO FERROSOS; SERVICO DE" },
  {
    cnae: "2452100",
    descricao: "ALUMINIO FUNDIDO EM FORMAS E PECAS; PRODUCAO DE",
  },
  { cnae: "25110", descricao: "FABRICACAO DE ESTRUTURAS METALICAS" },
  {
    cnae: "2511000",
    descricao: "PONTES E ELEMENTOS DE PONTES DE FERRO E ACO; FABRICACAO DE",
  },
  {
    cnae: "2511000",
    descricao: "TORRES E PORTICOS (PILARES) DE FERRO E ACO; FABRICACAO DE",
  },
  {
    cnae: "2511000",
    descricao:
      "ELEMENTOS MODULARES (MODULOS) DE METAL PARA EXPOSICOES; FABRICACAO DE",
  },
  {
    cnae: "2511000",
    descricao:
      "TORRES METALICAS PARA LINHAS DE TRANSMISSAO ELETRICA, TELETRANSMISSAO, ETC.; FABRICACAO DE",
  },
  {
    cnae: "2511000",
    descricao:
      "PORTA PALETES E ESTRUTURAS METALICAS SEMELHANTES PARA ARMAZENAGEM; FABRICACAO DE",
  },
  { cnae: "2511000", descricao: "TORRES DE TELEGRAFIA; FABRICACAO DE" },
  {
    cnae: "2511000",
    descricao: "QUIOSQUES METALICOS PARA CAIXAS ELETRONICOS; FABRICACAO DE",
  },
  {
    cnae: "2511000",
    descricao: "ESTRUTURA METALICA PARA SUBESTACOES; FABRICACAO DE",
  },
  {
    cnae: "2511000",
    descricao: "CONSTRUCOES PRE-FABRICADAS DE METAL; FABRICACAO DE",
  },
  { cnae: "2511000", descricao: "ANDAIMES TUBULARES; FABRICACAO DE" },
  {
    cnae: "2511000",
    descricao:
      "ESTRUTURA METALICA PARA EDIFICIOS COMERCIAIS E RESIDENCIAIS; FABRICACAO DE",
  },
  {
    cnae: "2511000",
    descricao: "ESTRUTURA METALICA PARA TELECOMUNICACOES; FABRICACAO DE",
  },
  {
    cnae: "2511000",
    descricao: "ESTRUTURA METALICA PARA PONTES E VIADUTOS; FABRICACAO DE",
  },
  {
    cnae: "2511000",
    descricao:
      "ESTRUTURA METALICA PARA GALPOES, COBERTURAS E SILOS FABRICACAO DE",
  },
  {
    cnae: "2511000",
    descricao: "ESTRUTURA METALICA PARA PASSARELAS; FABRICACAO DE",
  },
  {
    cnae: "2511000",
    descricao:
      "ESTRUTURA METALICA PARA ANTENAS DE EMISSORAS DE RADIO E TELEVISAO; FABRICACAO DE",
  },
  { cnae: "2511000", descricao: "ESTRUTURAS METALICAS, N.E.; FABRICACAO DE" },
  {
    cnae: "2511000",
    descricao:
      "ESTRUTURAS METALICAS; MONTAGEM DE, QUANDO REALIZADA PELA UNIDADE FABRICANTE",
  },
  {
    cnae: "2511000",
    descricao: "TORRES PARA EXTRACAO DE PETROLEO; FABRICACAO DE",
  },
  { cnae: "25128", descricao: "FABRICACAO DE ESQUADRIAS DE METAL" },
  { cnae: "2512800", descricao: "BASCULANTES METALICAS; FABRICACAO DE" },
  { cnae: "2512800", descricao: "PORTAS PANTOGRAFICAS; FABRICACAO DE" },
  { cnae: "2512800", descricao: "JANELA DE CORRER COM GRADE; FABRICACAO DE" },
  {
    cnae: "2512800",
    descricao: "ESQUADRIAS DE METAIS NAO-FERROSOS; FABRICACAO DE",
  },
  {
    cnae: "2512800",
    descricao:
      "ESQUADRIAS METALICAS; MONTAGEM DE, QUANDO REALIZADA PELA UNIDADE FABRICANTE",
  },
  { cnae: "2512800", descricao: "JANELAS DE ALUMINIO; FABRICACAO DE" },
  {
    cnae: "2512800",
    descricao:
      "PORTAS E DIVISOES SANFONADAS, REVESTIDAS DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  { cnae: "2512800", descricao: "SERRALHERIA PARA ESQUADRIAS" },
  { cnae: "2512800", descricao: "ESQUADRIAS DE FERRO E ACO; FABRICACAO DE" },
  { cnae: "2512800", descricao: "FECHAMENTO DE AREA; FABRICACAO DE" },
  { cnae: "2512800", descricao: "PORTOES METALICOS; FABRICACAO DE" },
  {
    cnae: "2512800",
    descricao:
      "BALAUSTRADAS E BALCOES, PORTOES E PORTAS CORREDICOS DE FERRO E ACO; FABRICACAO DE",
  },
  {
    cnae: "2512800",
    descricao: "PORTAS METALICAS CONTRA INCENDIO (CORTA-FOGO), FABRICACAO DE",
  },
  { cnae: "2512800", descricao: "PORTAS DE ALUMINIO; FABRICACAO DE" },
  {
    cnae: "2512800",
    descricao:
      "ESQUADRIAS METALICAS (MARCOS, BATENTES E SEMELHANTES), FABRICACAO DE",
  },
  { cnae: "2512800", descricao: "JANELAS METALICAS; FABRICACAO DE" },
  {
    cnae: "2512800",
    descricao: "PRODUTOS DE SERRALHERIA ARTISTICA; FABRICACAO DE",
  },
  {
    cnae: "2512800",
    descricao:
      "MONTAGEM DE ESQUADRIAS METALICAS (QUANDO EXECUTADA PELA UNIDADE FABRICANTE), SERVICO DE",
  },
  { cnae: "2512800", descricao: "VENEZIANAS DE METAL; FABRICACAO DE" },
  { cnae: "2512800", descricao: "GRADES METALICAS; FABRICACAO DE" },
  { cnae: "2512800", descricao: "PORTAS METALICAS; FABRICACAO DE" },
  {
    cnae: "2512800",
    descricao: "PORTAS DE ACO ONDULADAS OU FRISADAS; FABRICACAO DE",
  },
  {
    cnae: "2512800",
    descricao: "BOX PARA BANHEIRO COM ESTRUTURA DE ALUMINIO; FABRICACAO DE",
  },
  { cnae: "25136", descricao: "FABRICACAO DE OBRAS DE CALDEIRARIA PESADA" },
  {
    cnae: "2513600",
    descricao:
      "OBRAS DE CALDEIRARIA PESADA PARA AS INDUSTRIAS MECANICA, QUIMICA, SIDERURGICA, ETC; FABRICACAO DE",
  },
  {
    cnae: "2513600",
    descricao: "PECAS E OBRAS DIVERSAS DE CALDEIRARIA PESADA; FABRICACAO DE",
  },
  { cnae: "2513600", descricao: "COMPORTAS; FABRICACAO DE" },
  {
    cnae: "2513600",
    descricao: "PAINEIS DE ESCOTILHA PARA CONVES; FABRICACAO DE",
  },
  {
    cnae: "2513600",
    descricao:
      "PECAS E OBRAS DE CALDEIRARIA PESADA; MONTAGEM DE, QUANDO REALIZADA PELA UNIDADE FABRICANTE",
  },
  {
    cnae: "2513600",
    descricao: "CONDUTOS FORCADOS (CALDEIRARIA PESADA); FABRICACAO DE",
  },
  {
    cnae: "2513600",
    descricao: "LIMPA-GRADES (CALDEIRARIA PESADA); FABRICACAO DE",
  },
  { cnae: "2513600", descricao: "COMPORTAS DE EMERGENCIA; FABRICACAO DE" },
  {
    cnae: "2513600",
    descricao:
      "OBRAS DE CALDEIRARIA PESADA PARA INDUSTRIA FERROVIARIA; FABRICACAO DE",
  },
  { cnae: "2513600", descricao: "ESCOTILHAS; FABRICACAO DE" },
  {
    cnae: "2513600",
    descricao:
      "OBRAS DE CALDEIRARIA PESADA PARA CONSTRUCAO NAVAL; FABRICACAO DE",
  },
  {
    cnae: "2513600",
    descricao:
      "OBRAS DE CALDEIRARIA PESADA PARA APLICACOES EM HIDROVIAS E HIDRELETRICAS; FABRICACAO DE",
  },
  { cnae: "2513600", descricao: "MASTROS TUBULARES; FABRICACAO DE" },
  {
    cnae: "25217",
    descricao:
      "FABRICACAO DE TANQUES, RESERVATORIOS METALICOS E CALDEIRAS PARA AQUECIMENTO CENTRAL",
  },
  {
    cnae: "2521700",
    descricao:
      "TANQUES E RESERVATORIOS - INCLUSIVE DE ACO INOXIDAVEL, DE TETOS FLUTUANTES; FABRICACAO DE",
  },
  {
    cnae: "2521700",
    descricao:
      "CALDEIRAS GERADORAS DE VAPOR PARA AQUECIMENTO CENTRAL; FABRICACAO DE",
  },
  {
    cnae: "2521700",
    descricao:
      "PECAS E ACESSORIOS PARA CALDEIRAS GERADORAS DE VAPOR PARA AQUECIMENTO CENTRAL; FABRICACAO DE",
  },
  {
    cnae: "2521700",
    descricao: "RESERVATORIOS METALICOS PARA AGUA POTAVEL; FABRICACAO DE",
  },
  {
    cnae: "2521700",
    descricao: "PECAS E ACESSORIOS PARA TANQUES E RESERVATORIOS; FABRICACAO DE",
  },
  { cnae: "2521700", descricao: "BUJOES PARA GAS; FABRICACAO DE" },
  {
    cnae: "2521700",
    descricao:
      "TANQUES E RESERVATORIOS - INCLUSIVE DE ACO INOXIDAVEL, DE TETOS FIXOS; FABRICACAO DE",
  },
  {
    cnae: "2521700",
    descricao:
      "TANQUES E RESERVATORIOS - INCLUSIVE DE ACO INOXIDAVEL, ESFERICOS; FABRICACAO DE",
  },
  { cnae: "2541100", descricao: "SABRES E PUNHAIS; FABRICACAO DE" },
  {
    cnae: "2521700",
    descricao:
      "RECIPIENTES DE QUALQUER CAPACIDADE PARA TRANSPORTE E ARMAZENAGEM DE GASES COMPRIMIDOS E LIQUEFEITOS; FABRICACAO DE",
  },
  {
    cnae: "2521700",
    descricao:
      "TANQUES E RESERVATORIOS - INCLUSIVE DE ACO INOXIDAVEL, CILINDRICOS; FABRICACAO DE",
  },
  { cnae: "2521700", descricao: "BOTIJOES PARA GAS; FABRICACAO DE" },
  {
    cnae: "2521700",
    descricao: "CILINDROS DE FERRO PARA EXTINTORES DE INCENDIO; FABRICACAO DE",
  },
  {
    cnae: "2521700",
    descricao:
      "BUJOES, GARRAFAS E CILINDROS METALICOS PARA EMBALAR GASES; FABRICACAO DE",
  },
  {
    cnae: "2521700",
    descricao:
      "TUBOS E GARRAFAS METALICOS PARA TRANSPORTE DE GASES COMPRIMIDOS E LIQUEFEITOS; FABRICACAO DE",
  },
  {
    cnae: "2521700",
    descricao: "CILINDROS DE FERRO PARA EMBALAR GASES; FABRICACAO DE",
  },
  {
    cnae: "2521700",
    descricao:
      "TANQUES DE FERRO E ACO, N.E. - INCLUSIVE RESERVATORIOS PARA COMBUSTIVEIS E LUBRIFICANTES; FABRICACAO DE",
  },
  {
    cnae: "25225",
    descricao:
      "FABRICACAO DE CALDEIRAS GERADORAS DE VAPOR, EXCETO PARA AQUECIMENTO CENTRAL E PARA VEICULOS",
  },
  {
    cnae: "2522500",
    descricao:
      "CALDEIRAS GERADORAS DE VAPOR PARA USOS DIVERSOS - EXCETO PARA AQUECIMENTO CENTRAL E PARA VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "2522500",
    descricao:
      "PECAS E ACESSORIOS PARA CALDEIRAS GERADORAS DE VAPOR, EXCETO PARA AQUECIMENTO CENTRAL E PARA VEICULOS; FABRICACAO DE",
  },
  { cnae: "2522500", descricao: "REATORES NUCLEARES; FABRICACAO DE" },
  {
    cnae: "2522500",
    descricao: "ECONOMIZADOR UTILIZADO COM GERADORES DE VAPOR; FABRICACAO DE",
  },
  {
    cnae: "2522500",
    descricao: "CONDENSADORES PARA MAQUINAS A VAPOR; FABRICACAO DE",
  },
  { cnae: "2522500", descricao: "CILINDROS COLETORES; FABRICACAO DE" },
  {
    cnae: "25314",
    descricao:
      "PRODUCAO DE FORJADOS DE ACO E DE METAIS NAOFERROSOS E SUAS LIGAS",
  },
  {
    cnae: "2531401",
    descricao: "FERRO E ACO FORJADO EM FORMAS E PECAS, N. E.; PRODUCAO DE",
  },
  {
    cnae: "2531401",
    descricao: "BLOCOS FORJADOS DE ACO FERRAMENTA, NAO USINADOS; PRODUCAO DE",
  },
  { cnae: "2531401", descricao: "FORJARIA DE ACO; SERVICO DE" },
  {
    cnae: "2531401",
    descricao:
      "DISCOS, EIXOS E CILINDROS FORJADOS DE ACO CONSTRUCAO MECANICA; PRODUCAO DE",
  },
  {
    cnae: "2531401",
    descricao: "PECAS FORJADAS DE FERRO E ACO, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2531401",
    descricao: "FORJARIA DE PECAS DE METAIS FERROSOS; SERVICO DE",
  },
  { cnae: "2531402", descricao: "FORJARIA DE METAIS NAO-FERROSOS; SERVICO DE" },
  {
    cnae: "2531402",
    descricao: "METAIS NAO-FERROSOS FORJADOS EM FORMAS E PECAS; PRODUCAO DE",
  },
  {
    cnae: "25322",
    descricao: "PRODUCAO DE ARTEFATOS ESTAMPADOS DE METAL, METALURGIA DO PO",
  },
  {
    cnae: "2532201",
    descricao: "ARTEFATOS DIVERSOS DE COBRE ESTAMPADO; FABRICACAO DE",
  },
  { cnae: "2532201", descricao: "PECAS METALICAS REPUXADAS; FABRICACAO DE" },
  { cnae: "2532201", descricao: "BALDES METALICOS; FABRICACAO DE" },
  { cnae: "2532201", descricao: "ESTAMPARIA DE METAIS; SERVICO DE" },
  { cnae: "2532201", descricao: "ARTIGOS DE FUNILARIA, FABRICACAO DE" },
  {
    cnae: "2532201",
    descricao:
      "ARTEFATOS DIVERSOS DE METAIS NAO FERROSOS ESTAMPADOS, EXCETO COBRE; FABRICACAO DE",
  },
  {
    cnae: "2532201",
    descricao:
      "ARTEFATOS DIVERSOS DE FERRO E ACO ESTAMPADO, REPUXADO OU CONFORMADO, EXCETO PARA A INDUSTRIA AUTOMOBILISTICA; FABRICACAO DE",
  },
  {
    cnae: "2532201",
    descricao: "PECAS METALICAS ESTAMPADAS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2532201",
    descricao:
      "CALHAS E CONDUTORES METALICOS (ARTEFATOS DE FUNILARIA); PRODUCAO DE",
  },
  {
    cnae: "2532201",
    descricao:
      "ARMACOES METALICAS PARA GUARDA-CHUVAS E SOMBRINHAS; FABRICACAO DE",
  },
  {
    cnae: "2532201",
    descricao:
      "ARTEFATOS ESTAMPADOS DE METAL, EXCETO UTENSILIOS DOMESTICOS; FABRICACAO DE",
  },
  { cnae: "2532201", descricao: "TELHAS DE ALUMINIO; FABRICACAO DE" },
  {
    cnae: "2532201",
    descricao:
      "PECAS ESTAMPADAS DE FERRO E ACO, EXCETO UTENSILIOS DOMESTICOS; FABRICACAO DE",
  },
  { cnae: "2532201", descricao: "ESTAMPARIA DE FERRO E ACO; SERVICO DE" },
  {
    cnae: "2532201",
    descricao:
      "CAIXAS E ESTOJOS PARA FERRAMENTAS, COSMETICOS (ESTOJOS PARA PINTURA DO ROSTO), CIGARREIRAS, ETC.; FABRICACAO DE",
  },
  { cnae: "2532201", descricao: "TELHAS DE ZINCO; PRODUCAO DE" },
  {
    cnae: "2532201",
    descricao: "ESTAMPARIA DE METAIS NAO-FERROSOS; SERVICO DE",
  },
  {
    cnae: "2532201",
    descricao: "TELHAS METALICAS ESTAMPADAS, LISAS OU ONDULADAS; FABRICACAO DE",
  },
  { cnae: "2532201", descricao: "REGADORES METALICOS; FABRICACAO DE" },
  {
    cnae: "2532202",
    descricao: "BUCHAS AUTOLUBRIFICANTES SINTERIZADAS; PRODUCAO DE",
  },
  {
    cnae: "2532202",
    descricao:
      "PECAS MOLDADAS EM PO METALICO (SINTERIZADAS) OU REVESTIDAS; PRODUCAO DE",
  },
  { cnae: "2532202", descricao: "PECAS SINTERIZADAS; PRODUCAO DE" },
  { cnae: "2532202", descricao: "PRODUTOS DA METALURGIA DO PO; FABRICACAO DE" },
  {
    cnae: "2532202",
    descricao:
      "PECAS MOLDADAS EM PO METALICO (SINTERIZADAS) OU REVESTIDAS; FABRICACAO DE",
  },
  {
    cnae: "25390",
    descricao:
      "SERVICOS DE USINAGEM, SOLDA, TRATAMENTO E REVESTIMENTO EM METAIS",
  },
  { cnae: "2539001", descricao: "FRESAGEM; SERVICO DE" },
  { cnae: "2539001", descricao: "SOLDA; SERVICO DE" },
  {
    cnae: "2539001",
    descricao: "JATO DE AREIA (JATEAMENTO-USINAGEM); SERVICO DE",
  },
  { cnae: "2539001", descricao: "USINAGEM (TORNO, FRESA, ETC); SERVICO DE" },
  {
    cnae: "2539001",
    descricao: "JATEAMENTO EM PECAS, MAQUINAS E EQUIPAMENTOS; SERVICOS DE",
  },
  { cnae: "2539001", descricao: "JATEAMENTO DE GRANALHAS; SERVICOS DE" },
  { cnae: "2539001", descricao: "TORNEARIA; SERVICO DE" },
  { cnae: "2539002", descricao: "ESTANHAGEM; SERVICO DE" },
  { cnae: "2539002", descricao: "DECAPAGEM; SERVICO DE" },
  { cnae: "2539002", descricao: "PLACAS METALICAS GRAVADAS, FABRICACAO DE" },
  { cnae: "2539002", descricao: "CROMAGEM; SERVICO DE" },
  { cnae: "2539002", descricao: "COBREAGEM; SERVICO DE" },
  { cnae: "2539002", descricao: "DOURACAO EM METAIS; SERVICO DE" },
  { cnae: "2539002", descricao: "ZINCAGEM; SERVICO DE" },
  { cnae: "2539002", descricao: "PINTURA INDUSTRIAL; SERVICO DE" },
  { cnae: "2539002", descricao: "TRATAMENTO TERMICO DO ACO; SERVICO DE" },
  {
    cnae: "2539002",
    descricao:
      "TEMPERA, CEMENTACAO DE ACO, RECOZIMENTO DE ARAME, TRATAMENTO TERMICO; SERVICO DE",
  },
  { cnae: "2539002", descricao: "METALIZACAO; SERVICOS DE" },
  { cnae: "2539002", descricao: "CRIOGENIA EM FERRAMENTAS; SERVICOS DE" },
  { cnae: "2539002", descricao: "PINTURA EM PRODUTOS METALICOS; SERVICO DE" },
  {
    cnae: "2539002",
    descricao:
      "REVESTIMENTO NAO METALICO EM METAIS (PLASTIFICACAO, LACAGEM, ETC); SERVICOS DE",
  },
  { cnae: "2539002", descricao: "ANODIZACAO; SERVICO DE" },
  { cnae: "2539002", descricao: "ESMALTAGEM; SERVICO DE" },
  { cnae: "2539002", descricao: "GALVANOPLASTIA; SERVICO DE" },
  {
    cnae: "2539002",
    descricao: "TRATAMENTO E REVESTIMENTO EM METAIS; SERVICOS DE",
  },
  { cnae: "2539002", descricao: "PINTURA ELETROSTATICA; SERVICO DE" },
  { cnae: "2539002", descricao: "CHAPAS E PECAS METALICAS, IMPRESSAO DE" },
  { cnae: "2539002", descricao: "GALVANIZACAO; SERVICOS DE" },
  { cnae: "2539002", descricao: "NIQUELACAO; SERVICO DE" },
  { cnae: "2539002", descricao: "POLIMENTO DE METAIS; SERVICO DE" },
  {
    cnae: "2539002",
    descricao:
      "AFIACAO, AMOLAR FERRAMENTAS (FACAS, FACOES, EXCETO DE USO PESSOAL E DOMESTICO); SERVICOS DE",
  },
  { cnae: "25411", descricao: "FABRICACAO DE ARTIGOS DE CUTELARIA" },
  { cnae: "2541100", descricao: "ALICATES PARA UNHAS; FABRICACAO DE" },
  {
    cnae: "2541100",
    descricao: "TALHERES DE METAIS (EXCETO DE METAIS PRECIOSOS); FABRICACAO DE",
  },
  { cnae: "2541100", descricao: "CANIVETES; FABRICACAO DE" },
  {
    cnae: "2541100",
    descricao: "FAQUEIROS COMPLETOS DE METAL COMUM; FABRICACAO DE",
  },
  {
    cnae: "2541100",
    descricao:
      "ESPATULAS, ABRE-CARTAS, CORTA-PAPEIS E OUTROS ARTEFATOS DE CUTELARIA PARA ESCRITORIO; FABRICACAO DE",
  },
  {
    cnae: "2541100",
    descricao: "TESOURAS, EXCETO PARA CORTAR METAIS; FABRICACAO DE",
  },
  {
    cnae: "2541100",
    descricao: "APARELHOS DE BARBEAR METALICOS, NAO-ELETRICOS; FABRICACAO DE",
  },
  { cnae: "2541100", descricao: "ESPADAS; FABRICACAO DE" },
  {
    cnae: "2541100",
    descricao: "ARMAS BRANCAS (NAVALHAS, CANIVETES, ETC); FABRICACAO DE",
  },
  {
    cnae: "2541100",
    descricao: "CONCHAS E ESCUMADEIRAS DE METAL; FABRICACAO DE",
  },
  {
    cnae: "2541100",
    descricao: "FACOES - EXCETO PARA USO PROFISSIONAL; FABRICACAO DE",
  },
  { cnae: "2541100", descricao: "LAMINAS PARA BARBEAR; FABRICACAO DE" },
  { cnae: "2541100", descricao: "FLORETES E ESPADAS; FABRICACAO DE" },
  {
    cnae: "2541100",
    descricao: "COLHERES, GARFOS E FACAS DE METAL; FABRICACAO DE",
  },
  { cnae: "2541100", descricao: "CORTADORES DE UNHAS; FABRICACAO DE" },
  {
    cnae: "2541100",
    descricao: "APARELHOS DE BARBEAR, MESMO DE PLASTICO; FABRICACAO DE",
  },
  { cnae: "2541100", descricao: "ARTEFATOS DE CUTELARIA, N.E.; FABRICACAO DE" },
  {
    cnae: "25420",
    descricao: "FABRICACAO DE ARTIGOS DE SERRALHERIA, EXCETO ESQUADRIAS",
  },
  {
    cnae: "2542000",
    descricao:
      "FECHADURAS, GUARNICOES E SEMELHANTES PARA MOVEIS, BOLSAS, MALAS; FABRICACAO DE",
  },
  {
    cnae: "2542000",
    descricao:
      "ARTEFATOS PARA ENCANAMENTOS CONFECCIONADOS EM SERRALHERIAS; FABRICACAO DE",
  },
  { cnae: "2542000", descricao: "TOLDOS EM ALUMINIO; FABRICACAO DE" },
  { cnae: "2542000", descricao: "ESPORAS, ESTRIBOS E FREIOS; FABRICACAO DE" },
  { cnae: "2542000", descricao: "DOBRADICAS; FABRICACAO DE" },
  {
    cnae: "2542000",
    descricao: "SUPORTE METALICO PARA FORNO E PARA TV; FABRICACAO DE",
  },
  { cnae: "2542000", descricao: "FECHADURAS PARA VEICULOS; FABRICACAO DE" },
  {
    cnae: "2542000",
    descricao: "RODIZIOS, COM RODAS DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  {
    cnae: "2542000",
    descricao:
      "GUARNICOES, FERRAGENS E ARTEFATOS SEMELHANTES PARA CONSTRUCAO CIVIL; FABRICACAO DE",
  },
  {
    cnae: "2542000",
    descricao: "ARTEFATOS DE SERRALHERIA, EXCETO ESQUADRIAS; FABRICACAO DE",
  },
  {
    cnae: "2542000",
    descricao:
      "FERRAGENS PARA BOLSAS, MALAS, PASTAS E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2542000",
    descricao: "COBERTURA METALICA MODULAR DESMONTAVEL; FABRICACAO DE",
  },
  {
    cnae: "2542000",
    descricao:
      "FECHADURAS, GUARNICOES E OUTRAS FERRAGENS PARA CONSTRUCAO; FABRICACAO DE",
  },
  { cnae: "2542000", descricao: "FERRAGENS PARA MOVEIS, N.E.; FABRICACAO DE" },
  { cnae: "2542000", descricao: "TRAVAS DE SEGURANCA; FABRICACAO DE" },
  { cnae: "2542000", descricao: "FERRAGENS PARA SELARIA; FABRICACAO DE" },
  {
    cnae: "2542000",
    descricao:
      "GUARNICOES, FERRAGENS E ARTEFATOS SEMELHANTES PARA USOS NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2542000",
    descricao:
      "COBERTURAS METALICAS PARA VEICULOS E OUTROS USOS RESIDENCIAIS; FABRICACAO DE",
  },
  { cnae: "2542000", descricao: "CADEADOS; FABRICACAO DE" },
  {
    cnae: "2542000",
    descricao:
      "PARTES E PECAS PARA CADEADOS, FECHADURAS E FERROLHOS; FABRICACAO DE",
  },
  {
    cnae: "2542000",
    descricao: "BOX PARA BANHEIRO COM ESTRUTURA DE ALUMINIO; FABRICACAO DE",
  },
  { cnae: "2542000", descricao: "PRODUTOS DE CALDEIRARIA LEVE; FABRICACAO DE" },
  { cnae: "2542000", descricao: "TANQUES PARA TRANSFORMADORES, FABRICACAO DE" },
  { cnae: "2542000", descricao: "ALCAS E PUXADORES METALICOS; FABRICACAO DE" },
  {
    cnae: "2542000",
    descricao: "CHAVES PARA FECHADURAS DE VEICULOS; FABRICACAO DE",
  },
  { cnae: "2542000", descricao: "SERRALHERIA (EXCETO ESQUADRIAS)" },
  { cnae: "2542000", descricao: "CORRIMAO METALICO; FABRICACAO DE" },
  {
    cnae: "2542000",
    descricao:
      "GUARNICOES, FERRAGENS E ARTEFATOS SEMELHANTES PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  { cnae: "2542000", descricao: "CHAVES PARA FECHADURAS; FABRICACAO DE" },
  { cnae: "25438", descricao: "FABRICACAO DE FERRAMENTAS" },
  {
    cnae: "2543800",
    descricao: "MOLDES DE METAL PARA FUNDICAO; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "LAMINAS PARA SERRA; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao:
      "PARTES E PECAS DE METAL PARA SERRAS MANUAIS (ARMACOES, ARCOS, CABOS, ETC.); FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "PICARETAS; FABRICACAO DE" },
  { cnae: "2543800", descricao: "GARFOS PARA FENO; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao: "DIAMANTES (FERRAMENTAS INDUSTRIAIS); FABRICACAO DE",
  },
  {
    cnae: "2543800",
    descricao: "FERRAMENTAS INDUSTRIAIS DE PRECISAO; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "SACA-PARAFUSOS; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao: "CINZEL PARA ESCULTURAS E OUTROS TRABALHOS; FABRICACAO DE",
  },
  {
    cnae: "2543800",
    descricao: "CORTA-VIDROS (FERRAMENTAS MANUAIS); FABRICACAO DE",
  },
  {
    cnae: "2543800",
    descricao: "PLACAS PARA MAQUINAS-FERRAMENTA, FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "FURADEIRA MANUAL; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao: "ESCAVADEIRAS OU ESCAVADORAS (FERRAMENTA MANUAL); FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "CORTADORES DE METAIS; FABRICACAO DE" },
  { cnae: "2543800", descricao: "BROCAS PARA MADEIRA; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao:
      "BOMBAS MANUAIS PARA ENCHER CAMARAS DE PNEUMATICOS, BOLAS, ETC.; FABRICACAO DE",
  },
  {
    cnae: "2543800",
    descricao:
      "BROCAS, PONTAS, FRESAS E OUTROS ACESSORIOS INTERCAMBIAVEIS PARA FERRAMENTAS E PARA MAQUINAS FERRAMENTAS; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "FORJAS PORTATEIS; FABRICACAO DE" },
  { cnae: "2543800", descricao: "PLACAS DE FUNDO PARA MOLDES; FABRICACAO DE" },
  { cnae: "2543800", descricao: "SERRAS MANUAIS; FABRICACAO DE" },
  { cnae: "2543800", descricao: "BIGORNAS DE BANCADA; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao: "CORRENTES CORTANTES DE SERRAS; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "SACA-POLIAS; FABRICACAO DE" },
  { cnae: "2543800", descricao: "PUNCOES DE TODOS OS TIPOS; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao:
      "PLAQUETAS OU PASTILHAS, VARETAS, PONTAS E OBJETOS SEMELHANTES, PARA FERRAMENTAS, NAO MONTADOS, DE CARBONETOS METALICOS SINTETIZADOS OU DE CERAMAIS (CERMETS); FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "SERROTES; FABRICACAO DE" },
  { cnae: "2543800", descricao: "TALHADEIRAS DE ACO; FABRICACAO DE" },
  { cnae: "2543800", descricao: "CHAVES INGLESAS; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao: "FERRAMENTAS AGRICOLAS MANUAIS; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "MARRETAS; FABRICACAO DE" },
  { cnae: "2543800", descricao: "SERVICOS DE FERRAMENTARIA" },
  { cnae: "2543800", descricao: "MODELOS PARA MOLDES; FABRICACAO DE" },
  { cnae: "2543800", descricao: "ENXADAS E ENXADOES; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao: "MACHOS E COSSINETES PARA TARRAXAS; FABRICACAO DE",
  },
  {
    cnae: "2543800",
    descricao:
      "MOLDES, MODELOS, MATRIZES E ESTAMPOS DE METAL PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "ENGRAXADEIRAS; FABRICACAO DE" },
  { cnae: "2543800", descricao: "PLACAS PARA TORNOS, FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao: "FERRAMENTAS DE PERFURACAO E SONDAGENS; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "PONTEIRAS DE ACO; FABRICACAO DE" },
  { cnae: "2543800", descricao: "SARGENTOS (TORNIQUETES); FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao: "CORTADORES DE VIDRO COM PONTAS DIAMANTADAS; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "FOICES; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao: "FERRAMENTAS DE EMBUTIR, ESTAMPAR OU PUNCIONAR; FABRICACAO DE",
  },
  {
    cnae: "2543800",
    descricao:
      "FERRAMENTAS DE ENDIREITAR, APLAINAR OU RETIFICAR; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "COLHERES PARA PEDREIRO; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao: "FACAS E LAMINAS PARA MAQUINAS INDUSTRIAIS; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "BROCAS PARA MINERACAO, FABRICACAO DE" },
  { cnae: "2543800", descricao: "CAVADEIRAS; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao:
      "FERRAMENTAS, UTENSILIOS E INSTRUMENTOS PARA TRABALHOS MANUAIS; FABRICACAO DE",
  },
  {
    cnae: "2543800",
    descricao: "FACAS E FACOES PARA USO PROFISSIONAL; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "LIMAS, GROSAS E LIMATOES; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao: "FERRAMENTAS DE CORTE E EXTRUSAO, N.E.; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "BROCAS PARA METAIS; FABRICACAO DE" },
  { cnae: "2543800", descricao: "PINCAS (FERRAMENTAS); FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao:
      "PARTES E PECAS DE METAL PARA FERRAMENTAS MANUAIS; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "TESOURAS PARA CORTAR METAIS; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao: "FERRAMENTAS MANUAIS PARA AGRICULTURA; FABRICACAO DE",
  },
  { cnae: "9312300", descricao: "CLUBE ESPORTIVO" },
  { cnae: "2543800", descricao: "PAS (FERRAMENTAS); FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao:
      "FIEIRAS DE METAIS DUROS (TUNGSTENIO, VIDEA, ETC); FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "ENTALHADEIRAS PARA MADEIRAS; FABRICACAO DE" },
  { cnae: "2543800", descricao: "FOLHAS DE SERRAS; FABRICACAO DE" },
  { cnae: "2543800", descricao: "ALAVANCAS E PES-DE-CABRA; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao: "FERRAMENTAS PARA VEICULOS AUTOMOTORES (CHAVES); FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "CAIXAS DE FUNDICAO; FABRICACAO DE" },
  { cnae: "2543800", descricao: "CHAVES PARA PARAFUSOS; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao: "CISALHAS PARA METAIS E FERRAMENTAS SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2543800",
    descricao: "FERRAMENTAS INDUSTRIAIS, N.E.; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "PLAINAS MANUAIS PARA MADEIRA; FABRICACAO DE" },
  { cnae: "2543800", descricao: "FORMOES E GOIVAS; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao:
      "FERRAMENTAS DE CHANFRAR, MOLDURAR, RANHURAR, ETC; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "ARCOS-DE-SERRA MANUAL; FABRICACAO DE" },
  { cnae: "2543800", descricao: "MARTELOS; FABRICACAO DE" },
  { cnae: "2543800", descricao: "ANCINHOS MANUAIS; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao: "ALICATES, TORQUESES E TENAZES; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "MACHADOS E MACHADINHAS; FABRICACAO DE" },
  { cnae: "2543800", descricao: "ARCOS-DE-PUA; FABRICACAO DE" },
  { cnae: "2543800", descricao: "ALMOTOLIAS (ENGRAXADEIRAS); FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao:
      "TESOURAS PARA USOS PROFISSIONAIS (CABELEIREIROS, CORREEIROS, CHAPELEIROS, ETC); FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "VAZADORES; FABRICACAO DE" },
  {
    cnae: "2543800",
    descricao: "TARRAXAS COMPLETAS COM MACHOS E COSSINETES; FABRICACAO DE",
  },
  { cnae: "2543800", descricao: "MOLDES DE PLASTICO; PRODUCAO DE" },
  { cnae: "2543800", descricao: "TORNOS DE BANCADAS; FABRICACAO DE" },
  {
    cnae: "25501",
    descricao:
      "FABRICACAO DE EQUIPAMENTO BELICO PESADO, ARMAS DE FOGO E MUNICOES",
  },
  {
    cnae: "2550101",
    descricao:
      "PECAS E ACESSORIOS PARA MUNICAO DE EQUIPAMENTO BELICO PESADO; FABRICACAO DE",
  },
  { cnae: "2550101", descricao: "EQUIPAMENTO BELICO PESADO; FABRICACAO DE" },
  {
    cnae: "2550101",
    descricao:
      "PECAS E ACESSORIOS PARA EQUIPAMENTO BELICO PESADO; FABRICACAO DE",
  },
  { cnae: "2550101", descricao: "METRALHADORAS PESADAS; FABRICACAO DE" },
  {
    cnae: "2550101",
    descricao:
      "BOMBAS, CARGAS DE PROFUNDIDADE, GRANADAS, MINAS, TORPEDOS, ETC; FABRICACAO DE",
  },
  {
    cnae: "2550101",
    descricao: "MUNICAO PARA EQUIPAMENTO BELICO PESADO; FABRICACAO DE",
  },
  { cnae: "2550101", descricao: "EQUIPAMENTOS MILITARES, N.E.; FABRICACAO DE" },
  { cnae: "2550102", descricao: "PISTOLAS (ARMAS DE FOGO); FABRICACAO DE" },
  {
    cnae: "2550102",
    descricao:
      "ESPINGARDAS, CARABINAS E PISTOLAS DE MOLA, DE AR COMPRIMIDO OU DE GAS; FABRICACAO DE",
  },
  {
    cnae: "2550102",
    descricao: "METRALHADORAS LEVES (PORTATEIS); FABRICACAO DE",
  },
  {
    cnae: "2550102",
    descricao: "PECAS E ACESSORIOS PARA ARMAS; FABRICACAO DE",
  },
  { cnae: "2550102", descricao: "ESPINGARDAS; FABRICACAO DE" },
  { cnae: "2550102", descricao: "CARABINAS; FABRICACAO DE" },
  { cnae: "2550102", descricao: "REVOLVERES (ARMAS DE FOGO); FABRICACAO DE" },
  { cnae: "2550102", descricao: "ARMAS PARA CACA E ESPORTE; FABRICACAO DE" },
  {
    cnae: "2550102",
    descricao: "MUNICAO PARA ARMAS DE FOGO E PARA OUTRAS ARMAS; FABRICACAO DE",
  },
  { cnae: "25918", descricao: "FABRICACAO DE EMBALAGENS METALICAS" },
  {
    cnae: "2591800",
    descricao: "EMBALAGENS FLEXIVEIS DE ALUMINIO; FABRICACAO DE",
  },
  {
    cnae: "2591800",
    descricao:
      "RESERVATORIOS, BARRIS, TAMBORES, CAIXAS E RECIPIENTES SEMELHANTES DE ALUMINIO, COM CAPACIDADE NAO SUPERIOR A 300 L; FABRICACAO DE",
  },
  { cnae: "2591800", descricao: "LATAS DE METAL; FABRICACAO DE" },
  {
    cnae: "2591800",
    descricao:
      "RECIPIENTES DE FERRO, ACO OU ALUMINIO COM CAPACIDADE INFERIOR A 300L, ISOTERMICOS, REFRIGERADOS A NITROGENIO LIQUIDO, UTILIZADOS PARA SEMEN, SANGUE,TECIDOS BIOLOGICOS OU SIMILARES; FABRICACAO DE",
  },
  {
    cnae: "2591800",
    descricao: "EMBALAGENS EM PAPEL ALUMINIO (QUENTINHA, ETC); FABRICACAO DE",
  },
  { cnae: "2591800", descricao: "AEROSSOIS (EMBALAGENS); FABRICACAO DE" },
  {
    cnae: "2591800",
    descricao: "TAMPAS DE METAL PARA EMBALAGENS; FABRICACAO DE",
  },
  {
    cnae: "2591800",
    descricao: "LATAS METALICAS PARA EMBALAGEM; FABRICACAO DE",
  },
  { cnae: "2591800", descricao: "ROLHAS E CAPSULAS DE METAIS; FABRICACAO DE" },
  {
    cnae: "2591800",
    descricao: "LATAS DE ALUMINIO PARA EMBALAGEM; FABRICACAO DE",
  },
  {
    cnae: "2591800",
    descricao: "TAMBORES E TANQUES METALICOS PARA EMBALAGEM; FABRICACAO DE",
  },
  { cnae: "2591800", descricao: "EMBALAGEM DE AEROSSOL; FABRICACAO DE" },
  {
    cnae: "2591800",
    descricao: "RECIPIENTES METALICOS PARA EMBALAGEM, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2591800",
    descricao: "CARTUCHOS DE METAL PARA EMBALAGENS; FABRICACAO DE",
  },
  {
    cnae: "2591800",
    descricao:
      "BARRIS, TAMBORES E RECIPIENTES SEMELHANTES (EXCETO LATAS) DE FERRO E ACO PARA ACONDICIONAMENTO DE PRODUTOS, COM CAPACIDADE INFERIOR A 50L; FABRICACAO DE",
  },
  {
    cnae: "2591800",
    descricao:
      "RESERVATORIOS, BARRIS, TAMBORES, LATAS (EXCETO PARA GASES), DE FERRO E ACO COM CAPACIDADE IGUAL OU SUPERIOR A 50L E INFERIOR A 300L, PARA EMBALAGEM OU TRANSPORTE DE PRODUTOS DIVERSOS; FABRICACAO DE",
  },
  {
    cnae: "2591800",
    descricao: "LATOES DE METAL PARA LATICINIOS; FABRICACAO DE",
  },
  {
    cnae: "2591800",
    descricao: "BISNAGAS METALICAS PARA EMBALAGEM; FABRICACAO DE",
  },
  { cnae: "2591800", descricao: "EMBALAGENS METALICAS; FABRICACAO DE" },
  { cnae: "25926", descricao: "FABRICACAO DE PRODUTOS DE TREFILADOS DE METAL" },
  {
    cnae: "2592601",
    descricao:
      "GRADES OU REDES DE FIOS DE FERRO E ACO, INCLUSIVE REVESTIDAS; FABRICACAO DE",
  },
  {
    cnae: "2592601",
    descricao:
      "ESPONJAS, LUVAS, E SEMELHANTES, PARA LIMPEZA E POLIMENTO, DE FIOS DE ALUMINIO; FABRICACAO DE",
  },
  {
    cnae: "2592601",
    descricao: "PINOS E CONTRAPINOS METALICOS; FABRICACAO DE",
  },
  { cnae: "2592601", descricao: "REBITES METALICOS; FABRICACAO DE" },
  { cnae: "2592601", descricao: "PORCAS E ARRUELAS METALICAS; FABRICACAO DE" },
  {
    cnae: "2592601",
    descricao:
      "ESPONJAS, LUVAS E SEMELHANTES, PARA LIMPEZA E POLIMENTO, DE FIOS DE COBRE; FABRICACAO DE",
  },
  {
    cnae: "2592601",
    descricao: "CHAPAS E TIRAS, DISTENDIDAS, DE FERRO E ACO; FABRICACAO DE",
  },
  { cnae: "2592601", descricao: "PARAFUSOS METALICOS; FABRICACAO DE" },
  { cnae: "2592602", descricao: "ARAMADOS PARA QUALQUER USO; FABRICACAO DE" },
  {
    cnae: "2592602",
    descricao: "CORDAS, CABOS E SEMELHANTES DE COBRE, NUS; FABRICACAO DE",
  },
  {
    cnae: "2592602",
    descricao: "PREGOS, TACHAS E ARESTAS DE METAIS; FABRICACAO DE",
  },
  { cnae: "2592602", descricao: "MODULOS ARAMADOS; FABRICACAO DE" },
  { cnae: "2592602", descricao: "CALCEIRO ARAMADO; FABRICACAO DE" },
  { cnae: "2592602", descricao: "PREGOS E GRAMPOS PARA CERCA; FABRICACAO DE" },
  { cnae: "2592602", descricao: "GRAMPOS DE FIXACAO; FABRICACAO DE" },
  {
    cnae: "2592602",
    descricao:
      "CORRENTES ANTIDERRAPANTES E SUAS PARTES, DE FERRO E ACO; FABRICACAO DE",
  },
  {
    cnae: "2592602",
    descricao: "CORDAS, CABOS E SEMELHANTES DE ALUMINIO, NUS; FABRICACAO DE",
  },
  {
    cnae: "2592602",
    descricao: "CORRENTES DE METAIS NAO-FERROSOS; FABRICACAO DE",
  },
  { cnae: "2592602", descricao: "CESTAS DE ARAME; FABRICACAO DE" },
  { cnae: "2592602", descricao: "LAS DE ACO; FABRICACAO DE" },
  {
    cnae: "2592602",
    descricao:
      "ARARAS, CALCEIROS, PRATELEIRAS E BIOMBOS ARAMADOS; FABRICACAO DE",
  },
  { cnae: "2592602", descricao: "CERCAS METALICAS (TELAS); FABRICACAO DE" },
  { cnae: "2592602", descricao: "ESPONJAS DE ACO (LA DE ACO); FABRICACAO DE" },
  {
    cnae: "2592602",
    descricao: "CORRENTES E CADEIAS METALICAS E SUAS PARTES; FABRICACAO DE",
  },
  {
    cnae: "2592602",
    descricao: "MOLAS DE TODOS OS TIPOS, EXCETO PARA VEICULOS; FABRICACAO DE",
  },
  { cnae: "2592602", descricao: "CORRENTES DE FERRO E ACO; FABRICACAO DE" },
  { cnae: "2592602", descricao: "SAPATEIRAS ARAMADAS; FABRICACAO DE" },
  {
    cnae: "2592602",
    descricao:
      "CORDAS, CABOS, TRANCAS E SEMELHANTES DE METAL (METALICOS), NAO ISOLADOS (NUS); FABRICACAO DE",
  },
  { cnae: "2592602", descricao: "TELAS METALICAS; FABRICACAO DE" },
  { cnae: "2592602", descricao: "CABOS E CORDOALHAS DE ACO; FABRICACAO DE" },
  { cnae: "2592602", descricao: "GAIOLAS E VIVEIROS METALICOS; FABRICACAO" },
  { cnae: "2592602", descricao: "GAIOLAS E VIVEIROS DE ARAME; FABRICACAO DE" },
  {
    cnae: "2592602",
    descricao:
      "ARTEFATOS DE TREFILADOS, N.E., - (EXCETO PRODUTOS PADRONIZADOS); PRODUCAO DE",
  },
  {
    cnae: "2592602",
    descricao: "REDES DE PROTECAO (TELAS ARAMADAS); FABRICACAO DE",
  },
  { cnae: "2592602", descricao: "TELAS DE ARAME; FABRICACAO DE" },
  { cnae: "2592602", descricao: "PALHA DE ACO; FABRICACAO DE" },
  {
    cnae: "25934",
    descricao: "FABRICACAO DE ARTIGOS DE METAL PARA USO DOMESTICO E PESSOAL",
  },
  { cnae: "2593400", descricao: "FRUTEIRAS METALICAS; FABRICACAO DE" },
  { cnae: "2593400", descricao: "PIAS METALICAS; FABRICACAO DE" },
  { cnae: "2593400", descricao: "BAIXELA EM ACO INOX; FABRICACAO DE" },
  { cnae: "2593400", descricao: "PANQUEQUEIRA; FABRICACAO DE" },
  { cnae: "2593400", descricao: "ASSADEIRA DE METAL; FABRICACAO DE" },
  { cnae: "2593400", descricao: "PANELAS DE PRESSAO; FABRICACAO DE" },
  { cnae: "2593400", descricao: "SACA-ROLHAS E QUEBRA-NOZES; FABRICACAO DE" },
  {
    cnae: "2593400",
    descricao: "FRIGIDEIRAS DE METAL PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2593400",
    descricao: "ARTEFATOS DE METAL PARA USO DOMESTICO, N.E.; FABRICACAO DE",
  },
  { cnae: "2593400", descricao: "FERRAMENTAS DE USO DOMESTICO; FABRICACAO DE" },
  {
    cnae: "2593400",
    descricao: "CHUVEIROS METALICOS NAO-ELETRICOS, FABRICACAO DE",
  },
  {
    cnae: "2593400",
    descricao: "BALCAO METALICO (TABUA) PARA PASSAR ROUPA; FABRICACAO DE",
  },
  { cnae: "2593400", descricao: "BANDEJA INOX; FABRICACAO DE" },
  { cnae: "2593400", descricao: "BALDES PARA GELO, METALICOS; FABRICACAO DE" },
  {
    cnae: "2593400",
    descricao: "UTENSILIOS DOMESTICOS DE ALUMINIO; PRODUCAO DE",
  },
  { cnae: "2593400", descricao: "BANHEIRAS METALICAS; FABRICACAO DE" },
  {
    cnae: "2593400",
    descricao: "CAFETEIRAS DOMESTICAS, NAO-ELETRICAS; FABRICACAO DE",
  },
  { cnae: "2593400", descricao: "PRATOS E TRAVESSAS METALICOS; FABRICACAO DE" },
  { cnae: "2593400", descricao: "PLAINA PARA CORTAR QUEIJO; FABRICACAO DE" },
  {
    cnae: "2593400",
    descricao: "MAQUINAS DE MOER CARNE (MOEDORES) NAO ELETRICAS; FABRICACAO DE",
  },
  { cnae: "2593400", descricao: "PIAS DE ACO INOXIDAVEL, FABRICACAO DE" },
  { cnae: "2593400", descricao: "CONJUNTO DE PANELAS (METAL); FABRICACAO DE" },
  {
    cnae: "2593400",
    descricao:
      "UTENSILIOS DOMESTICOS DE METAIS (EXCETO TALHERES); FABRICACAO DE",
  },
  {
    cnae: "2593400",
    descricao: "SECADORES METALICOS PARA ROUPAS; FABRICACAO DE",
  },
  {
    cnae: "2593400",
    descricao: "CONJUNTO DE METAL PARA SOBREMESA; FABRICACAO DE",
  },
  {
    cnae: "2593400",
    descricao: "GRAMPOS E CLIPES PARA CABELOS; FABRICACAO DE",
  },
  { cnae: "2593400", descricao: "RALADOR METALICO; FABRICACAO DE" },
  {
    cnae: "2593400",
    descricao:
      "UTENSILIOS METALICOS PARA MESA, COPA E COZINHA, INCLUSIVE JOGOS COMPLETOS; FABRICACAO DE",
  },
  { cnae: "2593400", descricao: "CORTADOR DE LEGUMES (MANUAL); FABRICACAO DE" },
  {
    cnae: "2593400",
    descricao: "MESA PARA PASSAR ROUPA (EM FERRO); FABRICACAO DE",
  },
  { cnae: "2593400", descricao: "COPOS E CANECAS METALICOS; FABRICACAO DE" },
  { cnae: "2593400", descricao: "ESPREMEDORES DE ALHO; FABRICACAO DE" },
  { cnae: "2593400", descricao: "CASTICAIS DE METAL; PRODUCAO DE" },
  { cnae: "2593400", descricao: "MARMITAS METALICAS; FABRICACAO DE" },
  { cnae: "2593400", descricao: "CORTADORES DE QUEIJO; FABRICACAO DE" },
  {
    cnae: "2593400",
    descricao: "PECAS AVULSAS METALICAS PARA MESA E COZINHA; FABRICACAO DE",
  },
  {
    cnae: "2593400",
    descricao: "ESCORREDORES METALICOS PARA COPOS E PRATOS; FABRICACAO DE",
  },
  {
    cnae: "2593400",
    descricao: "PANELAS DE METAL PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2593400",
    descricao: "ABRIDORES DE LATAS E GARRAFAS; FABRICACAO DE",
  },
  {
    cnae: "25993",
    descricao:
      "FABRICACAO DE PRODUTOS DE METAL NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "2599301",
    descricao: "ARMACOES METALICAS PARA CONSTRUCAO, SERVICO DE CONFECCAO DE",
  },
  { cnae: "2599302", descricao: "CORTE E DOBRA DE METAIS; SERVICO DE" },
  {
    cnae: "2599302",
    descricao: "CORTE E DOBRA DE CALHAS METALICAS; SERVICO DE",
  },
  { cnae: "2599302", descricao: "METAIS DOBRADOS OU RECORTADOS; PRODUCAO DE" },
  {
    cnae: "2599302",
    descricao: "FERRO E ACO CORTE E DOBRA (NAO ASSOCIADO AO COMERCIO)",
  },
  { cnae: "2599302", descricao: "FERRO E ACO RECORTADO; PRODUCAO DE" },
  { cnae: "2599399", descricao: "LIXEIRA METALICAS; FABRICACAO DE" },
  {
    cnae: "2599399",
    descricao:
      "FERRAGENS ELETROTECNICAS PARA ELETRICIDADE, TELEFONIA (TELECOMUNICACOES), ETC; FABRICACAO DE",
  },
  { cnae: "2599399", descricao: "PALETES (PALLETS) METALICOS; FABRICACAO DE" },
  { cnae: "2599399", descricao: "HELICES PARA NAVIOS; FABRICACAO DE" },
  { cnae: "2599399", descricao: "GRAMPO PARA GRAMPEADORES; FABRICACAO DE" },
  { cnae: "2599399", descricao: "ESCADAS METALICAS; FABRICACAO DE" },
  {
    cnae: "2599399",
    descricao: "CINTAS OU BRACADEIRAS PARA POSTES, FABRICACAO DE",
  },
  {
    cnae: "2599399",
    descricao: "ESCORAMENTOS METALICOS PARA CONSTRUCAO CIVIL, FABRICACAO DE",
  },
  {
    cnae: "2599399",
    descricao:
      "CHAPAS, BOBINAS  E OUTROS LAMINADOS METALICOS CORTADOS EM QUALQUER FORMA; FABRICACAO DE",
  },
  {
    cnae: "2599399",
    descricao: "ESFERAS METALICAS PARA EROSAO; FABRICACAO DE",
  },
  {
    cnae: "2599399",
    descricao:
      "ACESSORIOS METALICOS PARA SONDAGENS E CONSTRUCAO DE ENCOSTAS, FABRICACAO DE",
  },
  {
    cnae: "2599399",
    descricao: "ARTEFATOS DIVERSOS DE ALUMINIO; FABRICACAO DE",
  },
  {
    cnae: "2599399",
    descricao:
      "ARTEFATOS DIVERSOS DE METAIS NAO FERROSOS (EXCETO ALUMINIO), NAO ESPECIFICADOS ANTERIORMENTE; FABRICACAO DE",
  },
  { cnae: "2599399", descricao: "SELO DE SEGURANCA METALICO; FABRICACAO DE" },
  { cnae: "2599399", descricao: "IMAS (FERRITES); FABRICACAO DE" },
  {
    cnae: "2599399",
    descricao: "CABINAS METALICAS DE SEGURANCA; FABRICACAO DE",
  },
  { cnae: "2599399", descricao: "ARTEFATOS DE CHUMBO, N.E.; FABRICACAO DE" },
  {
    cnae: "2599399",
    descricao: "COFRES E CAIXAS-FORTES DE ACO; FABRICACAO DE",
  },
  { cnae: "2599399", descricao: "POSTES METALICOS, FABRICACAO DE" },
  {
    cnae: "2599399",
    descricao: "CAIXAS METALICAS PARA CORREIO; FABRICACAO DE",
  },
  { cnae: "2599399", descricao: "PORTAS PARA CAIXAS-FORTES; FABRICACAO DE" },
  {
    cnae: "2599399",
    descricao:
      "SUPORTES (GANCHOS) PARA VESTUARIO, PARA ARTEFATOS DE TECIDO, PARA ESCOVAS, PARA CHAVES, ETC.; FABRICACAO DE",
  },
  {
    cnae: "2599399",
    descricao:
      "JUNTAS DE EXPANSAO E OUTRAS JUNTAS PARA TUBULACOES E ENCANAMENTOS; FABRICACAO DE",
  },
  {
    cnae: "2599399",
    descricao:
      "MOLDURAS PARA FOTOGRAFIAS, GRAVURAS E SEMELHANTES, DE METAL; FABRICACAO DE",
  },
  {
    cnae: "2599399",
    descricao:
      "ANCORAGENS, BAINHAS METALICAS, JUNTAS DE DILATACAO E SEMELHANTES PARA CONCRETO, FABRICACAO DE",
  },
  { cnae: "2599399", descricao: "GRAMPOS E CLIPES PARA PAPEL; FABRICACAO DE" },
  {
    cnae: "2599399",
    descricao:
      "BRITAMENTO, LAVAGEM, PENEIRACAO E OUTROS BENEFICIAMENTOS DE ESCORIAS OU DE OUTROS RESIDUOS METALURGICOS",
  },
  {
    cnae: "2599399",
    descricao:
      "SINOS, GONZOS E ARTEFATOS SEMELHANTES, NAO ELETRICOS; FABRICACAO DE",
  },
  {
    cnae: "2599399",
    descricao:
      "ARTEFATOS DE METAL PARA ESCRITORIO, EXCETO ARTEFATOS DE CUTELARIA; FABRICACAO DE",
  },
  {
    cnae: "2599399",
    descricao: "ARTEFATOS DIVERSOS DE FERRO E ACO; FABRICACAO DE",
  },
  {
    cnae: "2599399",
    descricao: "ARTESANATO EM METAIS (EXCETO METAIS PRECIOSOS); FABRICACAO DE",
  },
  { cnae: "2599399", descricao: "PERFURACAO EM CHAPAS METALICAS; SERVICO DE" },
  { cnae: "2599399", descricao: "PAINEIS METALICOS; FABRICACAO DE" },
  {
    cnae: "2599399",
    descricao: "EXPOSITORES, DISPLAYS OU MOSTRUARIOS METALICOS; FABRICACAO DE",
  },
  {
    cnae: "2599399",
    descricao: "ELETRODOS PARA SOLDA ELETRICA, EXCETO DE GRAFITA; PRODUCAO DE",
  },
  {
    cnae: "2599399",
    descricao: "ABRACADEIRAS, CANTONEIRAS E SUPORTES METALICOS; FABRICACAO DE",
  },
  { cnae: "2599399", descricao: "HELICES PARA EMBARCACOES; FABRICACAO DE" },
  {
    cnae: "2599399",
    descricao:
      "ESTATUETAS E OUTROS OBJETOS METALICOS PARA ORNAMENTACAO; FABRICACAO DE",
  },
  { cnae: "2599399", descricao: "HASTES DE ATERRAMENTO, FABRICACAO DE" },
  {
    cnae: "2599399",
    descricao:
      "FORROS E REVESTIMENTOS METALICOS PARA ISOLAMENTO TERMICO; FABRICACAO DE",
  },
  { cnae: "2599399", descricao: "PERCEVEJOS; FABRICACAO DE" },
  {
    cnae: "2599399",
    descricao: "EMBALAGEM INDUSTRIAL DE PRODUTOS SIDERURGICOS; SERVICOS DE",
  },
  { cnae: "2599399", descricao: "ANCORAS PARA EMBARCACOES; FABRICACAO DE" },
  {
    cnae: "2599399",
    descricao:
      "FIOS, VARETAS, TUBOS, ELETRODOS E ARTEFATOS SEMELHANTES PARA SOLDAGEM; FABRICACAO DE",
  },
  {
    cnae: "2599399",
    descricao:
      "SUCATAS E OUTROS RESIDUOS METALICOS N.E (EXCETO OBTIDOS EM SIDERURGICAS)",
  },
  { cnae: "2599399", descricao: "ETIQUETAS DE METAL; FABRICACAO DE" },
  { cnae: "2599399", descricao: "CINZEIROS METALICOS; FABRICACAO DE" },
  { cnae: "26108", descricao: "FABRICACAO DE COMPONENTES ELETRONICOS" },
  { cnae: "2610800", descricao: "DIODO LASER; FABRICACAO DE" },
  { cnae: "2610800", descricao: "CIRCUITOS INTEGRADOS (CI); FABRICACAO DE" },
  { cnae: "2610800", descricao: "TIRISTOR DE POTENCIA; FABRICACAO DE" },
  {
    cnae: "2610800",
    descricao: "CARTOES INCORPORANDO UM CIRCUITO ELETRONICO; FABRICACAO DE",
  },
  {
    cnae: "2610800",
    descricao:
      "PECAS, ACESSORIOS E MATERIAIS ELETRONICOS BASICOS, N.E.; FABRICACAO DE",
  },
  { cnae: "2610800", descricao: "MICROPROCESSADORES; FABRICACAO DE" },
  { cnae: "2610800", descricao: "SEMICONDUTORES; FABRICACAO DE" },
  {
    cnae: "2610800",
    descricao: "SEMICONDUTORES ACABADOS OU SEMIACABADOS; FABRICACAO DE",
  },
  {
    cnae: "2610800",
    descricao: "CABOS DE IMPRESSORAS E CABOS DE MONITOR; FABRICACAO DE",
  },
  {
    cnae: "2610800",
    descricao: "COMPONENTES EM PLACAS DE CIRCUITOS IMPRESSOS; MONTAGEM DE",
  },
  {
    cnae: "2610800",
    descricao: "DIODOS EMISSORES DE LUZ (LED); FABRICACAO DE",
  },
  {
    cnae: "2610800",
    descricao: "INTERRUPTORES PARA APLICACOES ELETRONICAS; FABRICACAO DE",
  },
  { cnae: "2610800", descricao: "SOQUETES PARA ELETRONICA; FABRICACAO DE" },
  { cnae: "2610800", descricao: "POTENCIOMETROS, FABRICACAO DE" },
  {
    cnae: "2610800",
    descricao: "TRANSDUTORES PARA APLICACOES ELETRONICAS; FABRICACAO DE",
  },
  {
    cnae: "2610800",
    descricao:
      "LAMPADAS, TUBOS OU VALVULAS, ELETRONICOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2610800",
    descricao:
      "TUBOS DE IMAGEM PARA RECEPTORES DE TELEVISAO E MONITORES DE VIDEO; TUBOS DE CAPTACAO PARA CAMERAS DE TELEVISAO; VALVULAS, LAMPADAS E OUTROS TUBOS; FABRICACAO DE",
  },
  { cnae: "2610800", descricao: "BOBINAS ELETRONICAS; FABRICACAO DE" },
  { cnae: "2610800", descricao: "VALVULAS ELETRONICAS; FABRICACAO DE" },
  {
    cnae: "2610800",
    descricao: "CELULA FOTOSSENSIVEL (LUMISTOR); FABRICACAO DE",
  },
  { cnae: "2610800", descricao: "DIODOS; FABRICACAO DE" },
  {
    cnae: "2610800",
    descricao: "SOLENOIDES PARA APLICACOES ELETRONICAS; FABRICACAO DE",
  },
  { cnae: "2610800", descricao: "TIRESTORES, DIACS, UJT, ETC; FABRICACAO DE" },
  { cnae: "2610800", descricao: "TRIODOS; FABRICACAO DE" },
  {
    cnae: "2610800",
    descricao:
      "PARTES OU PECAS DE OUTROS TIPOS PARA MONTAGEM DE SEMICONDUTORES; FABRICACAO DE",
  },
  {
    cnae: "2610800",
    descricao: "COMPONENTES ELETRONICOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  { cnae: "2610800", descricao: "LEDS, DISPLAYS; FABRICACAO DE" },
  { cnae: "2610800", descricao: "CINESCOPIOS; FABRICACAO DE" },
  { cnae: "2610800", descricao: "CABOS USB; FABRICACAO DE" },
  {
    cnae: "2610800",
    descricao: "MOSTRADOR DE CRISTAL LIQUIDO (LCD); FABRICACAO DE",
  },
  { cnae: "2610800", descricao: "FLASHES ELETRONICOS; FABRICACAO DE" },
  { cnae: "2610800", descricao: "TRANSISTORES; FABRICACAO DE" },
  {
    cnae: "2610800",
    descricao: "LAMINAS DE SILICIO PARA MICROELETRONICA; FABRICACAO DE",
  },
  { cnae: "2610800", descricao: "CELULAS FOTOELETRICAS; FABRICACAO DE" },
  {
    cnae: "2610800",
    descricao: "CARTOES INTELIGENTES (SMART CARDS), FABRICACAO DE",
  },
  {
    cnae: "2610800",
    descricao:
      "PARTES OU PECAS PARA CIRCUITOS INTEGRADOS ELETRONICOS; FABRICACAO DE",
  },
  { cnae: "2610800", descricao: "RESISTENCIAS ELETRONICAS; FABRICACAO DE" },
  { cnae: "2610800", descricao: "PLACA-MAE; FABRICACAO DE" },
  { cnae: "2610800", descricao: "CELULA FOTOVOLTAICA; FABRICACAO DE" },
  { cnae: "2610800", descricao: "TERMISTORES; FABRICACAO DE" },
  {
    cnae: "2610800",
    descricao: "CRISTAIS OSCILADORES DE QUARTZO; FABRICACAO DE",
  },
  { cnae: "2610800", descricao: "CIRCUITO IMPRESSO; FABRICACAO DE" },
  {
    cnae: "2610800",
    descricao: "CIRCUITOS ELETRONICOS PARA TERCEIROS, MONTAGEM DE",
  },
  { cnae: "2610800", descricao: "VARISTOR, TERMISTOR, ETC; FABRICACAO DE" },
  { cnae: "2610800", descricao: "PLACAS DE INTERFACE; FABRICACAO DE" },
  {
    cnae: "2610800",
    descricao:
      "PARTES OU PECAS PARA TUBOS DE IMAGEM, LAMPADAS, TUBOS OU VALVULAS, ELETRONICOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  { cnae: "2610800", descricao: "PLACAS DE CIRCUITO IMPRESSO; FABRICACAO DE" },
  {
    cnae: "2610800",
    descricao: "CONECTORES PARA CIRCUITO IMPRESSO; FABRICACAO DE",
  },
  { cnae: "26213", descricao: "FABRICACAO DE EQUIPAMENTOS DE INFORMATICA" },
  {
    cnae: "2621300",
    descricao: "LAPTOP (MICROCOMPUTADOR PORTATIL); FABRICACAO DE",
  },
  {
    cnae: "2621300",
    descricao: "COMPUTADORES DE MAO (HAND-HELDS); FABRICACAO DE",
  },
  { cnae: "2621300", descricao: "MAINFRAMES; FABRICACAO DE" },
  { cnae: "2651500", descricao: "VOLTIMETROS; FABRICACAO DE" },
  {
    cnae: "2621300",
    descricao:
      "MICROCOMPUTADOR PORTATIL, TIPO LAPTOP OU NOTEBOOK; FABRICACAO DE",
  },
  { cnae: "2621300", descricao: "NETBOOK; FABRICACAO DE" },
  { cnae: "2621300", descricao: "COMPUTADORES DE GRANDE PORTE; FABRICACAO DE" },
  {
    cnae: "2621300",
    descricao:
      "EQUIPAMENTOS DE SISTEMAS ELETRONICOS DEDICADOS A AUTOMACAO GERENCIAL; FABRICACAO DE",
  },
  {
    cnae: "2621300",
    descricao: "COMPUTADORES DE MESA (DESKTOPS); FABRICACAO DE",
  },
  { cnae: "2621300", descricao: "MINICOMPUTADORES; FABRICACAO DE" },
  { cnae: "2621300", descricao: "SERVIDORES (COMPUTADORES); FABRICACAO DE" },
  { cnae: "2621300", descricao: "MICROCOMPUTADORES; FABRICACAO DE" },
  { cnae: "2621300", descricao: "PALMTOP E PDA; FABRICACAO DE" },
  {
    cnae: "2621300",
    descricao: "MAQUINAS PARA PROCESSAMENTO DE DADOS; FABRICACAO DE",
  },
  {
    cnae: "26221",
    descricao: "FABRICACAO DE PERIFERICOS PARA EQUIPAMENTOS DE INFORMATICA",
  },
  {
    cnae: "2622100",
    descricao: "ATM (AUTOMATIC TELLER-MACHINE); FABRICACAO DE",
  },
  {
    cnae: "2622100",
    descricao: "PERIFERICOS PARA COMPUTADORES; FABRICACAO DE",
  },
  { cnae: "2622100", descricao: "DRIVES; FABRICACAO DE" },
  { cnae: "2622100", descricao: "URNAS ELETRONICAS, FABRICACAO DE" },
  {
    cnae: "2622100",
    descricao:
      "EQUIPAMENTOS DE SISTEMA ELETRONICOS DEDICADOS A AUTOMACAO GERENCIAL OU COMERCIAL; FABRICACAO DE",
  },
  {
    cnae: "2622100",
    descricao: "CAIXA PAGADORA (CASH-DISPENSER); FABRICACAO DE",
  },
  {
    cnae: "2622100",
    descricao:
      "APARELHOS PARA PROJECAO PARA USO EM COMPUTADOR (DATASHOW); FABRICACAO DE",
  },
  { cnae: "2622100", descricao: "IMPRESSORAS; FABRICACAO DE" },
  {
    cnae: "2622100",
    descricao: "LEITORES / GRAVADORES DE CARTOES INTELIGENTES; FABRICACAO DE",
  },
  { cnae: "2622100", descricao: "FLASH DRIVE; FABRICACAO DE" },
  { cnae: "2622100", descricao: "LEITOR DE CODIGO DE BARRAS; FABRICACAO DE" },
  {
    cnae: "2622100",
    descricao:
      "MONITORES DE VIDEO PARA EQUIPAMENTOS DE INFORMATICA; FABRICACAO DE",
  },
  { cnae: "2622100", descricao: "MULTIPLEXADORES ELETRONICOS; FABRICACAO DE" },
  {
    cnae: "2622100",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS PARA PROCESSAMENTO DE DADOS E SUAS UNIDADES PERIFERICAS; FABRICACAO DE",
  },
  {
    cnae: "2622100",
    descricao: "EQUIPAMENTOS AUTENTICADORES ELETRONICOS; FABRICACAO DE",
  },
  { cnae: "2622100", descricao: "CAIXAS AUTOMATICOS; FABRICACAO DE" },
  {
    cnae: "2622100",
    descricao: "MAQUINAS ELETRONICAS PARA VOTAR, FABRICACAO DE",
  },
  { cnae: "2622100", descricao: "UNIDADE DE DISCO; FABRICACAO DE" },
  {
    cnae: "2622100",
    descricao:
      "LEITORAS PARA MAQUINAS ELETRONICAS PARA TRATAMENTO DE INFORMACOES (LEITORAS OPTICAS, SCANNERS, ETC.); FABRICACAO DE",
  },
  { cnae: "2622100", descricao: "PEN DRIVE (PENDRIVE); FABRICACAO DE" },
  { cnae: "2622100", descricao: "MOUSE; FABRICACAO DE" },
  {
    cnae: "2622100",
    descricao: "MAQUINAS TOMADORAS DE APOSTAS; FABRICACAO DE",
  },
  { cnae: "2622100", descricao: "CARTUCHOS DE TONER; FABRICACAO DE" },
  { cnae: "2622100", descricao: "CARTUCHOS RECARREGADOS, PRODUCAO DE" },
  { cnae: "2622100", descricao: "WEBCAM (CAMERA WEB); FABRICACAO DE" },
  { cnae: "2622100", descricao: "IMPRESSORA DE JATO DE TINTA; FABRICACAO DE" },
  { cnae: "2622100", descricao: "MINI DRIVE; FABRICACAO DE" },
  {
    cnae: "2622100",
    descricao:
      "UNIDADES DE MAQUINAS AUTOMATICAS PARA PROCESSAMENTO DE DADOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  { cnae: "2622100", descricao: "PLOTADORAS (PLOTTERS); FABRICACAO DE" },
  {
    cnae: "2622100",
    descricao: "MAQUINAS DE PREENCHER CHEQUES; FABRICACAO DE",
  },
  { cnae: "2622100", descricao: "COLETOR DE DADOS; FABRICACAO DE" },
  { cnae: "2622100", descricao: "MAQUINAS TOMADORAS DE VOTOS; FABRICACAO DE" },
  { cnae: "2622100", descricao: "IMPRESSORAS MULTIFUNCIONAIS; FABRICACAO DE" },
  {
    cnae: "2622100",
    descricao: "TECLADOS (PERIFERICO PARA COMPUTADOR); FABRICACAO DE",
  },
  {
    cnae: "2622100",
    descricao: "CARTOES DE MEMORIA (MEMORY CARD); FABRICACAO DE",
  },
  {
    cnae: "2622100",
    descricao: "MAQUINAS REGISTRADORAS (TERMINAIS ELETRONICOS); FABRICACAO DE",
  },
  { cnae: "2622100", descricao: "TERMINAIS BANCARIOS; FABRICACAO DE" },
  { cnae: "2622100", descricao: "MESAS DIGITALIZADORAS; FABRICACAO DE" },
  { cnae: "2622100", descricao: "TERMINAIS ELETRONICOS, N.E.; FABRICACAO DE" },
  { cnae: "2622100", descricao: "EQUIPAMENTO MULTIFUNCIONAIS; FABRICACAO DE" },
  {
    cnae: "2622100",
    descricao:
      "EQUIPAMENTOS ELETRONICOS DEDICADOS AO PROCESSAMENTO DE TEXTO; FABRICACAO DE",
  },
  {
    cnae: "26311",
    descricao: "FABRICACAO DE EQUIPAMENTOS TRANSMISSORES DE COMUNICACAO",
  },
  {
    cnae: "2631100",
    descricao:
      "CAPSULAS TRANSDUTORAS DE VOZ PARA APARELHOS TELEFONICOS; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "APARELHOS DE MULTIPLEXACAO (PARA TELEGRAFIA); FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "TRANSMISSORES DE TELECOMUNICACOES; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "CONCENTRADORES DE CIRCUITOS DIGITAIS (DCME); FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao:
      "TRANSMISSORES OU RECEPTORES DE TELEFONIA CELULAR; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "TRANSMISSORES DE RADIODIFUSAO; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao:
      "SISTEMA DE COMUNICACAO DE DADOS PONTO A PONTO (TRANSDATA); FABRICACAO DE",
  },
  { cnae: "2631100", descricao: "GERADORES DE CARACTERES; FABRICACAO DE" },
  { cnae: "2631100", descricao: "CENTRAL DE RECADOS, FABRICACAO DE" },
  { cnae: "2631100", descricao: "SISTEMAS DE ALTO-FALANTES; FABRICACAO DE" },
  {
    cnae: "2631100",
    descricao: "APARELHOS DE RADIOTELEFONIA OU RADIOCOMUNICACAO; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "EQUIPAMENTOS DE TELECOMUNICACOES; FABRICACAO DE",
  },
  { cnae: "2631100", descricao: "ROTEADORES DIGITAIS; FABRICACAO DE" },
  { cnae: "2631100", descricao: "COMPRESSOR DE DADOS; FABRICACAO DE" },
  {
    cnae: "2631100",
    descricao: "APARELHOS DE RADIOCOMUNICACAO E RADIODIFUSAO; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "KS CONTROLADO POR MICROPROCESSADOR; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "GERADORES DE EFEITOS ESPECIAIS; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "CENTRAIS AUTOMATICAS PARA COMUTACAO DE PACOTES; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "SISTEMA DIGITAL DE SUPERVISAO; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao:
      "ANTENAS DE QUALQUER TIPO (INCLUSIVE PARTES) PARA TELEFONES CELULARES E OUTROS APARELHOS DE COMUNICACAO; FABRICACAO DE",
  },
  { cnae: "2631100", descricao: "MULTIPLEXADOR DE DADOS; FABRICACAO DE" },
  {
    cnae: "2631100",
    descricao:
      "CENTRAL PUBLICA DE COMUTACAO DE TEXTOS (TELEX) TIPO CPA; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao:
      "EQUIPAMENTOS ELETRONICOS PARA TRANSMISSAO DE RADIO E TELEVISAO; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao:
      "BASTIDORES E ARMACOES PARA APARELHOS DE TELEFONIA E TELEGRAFIA; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "PABX-CPA DE QUALQUER CAPACIDADE; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao:
      "APARELHOS PARA TRANSMISSAO DE DADOS ATRAVES DE LINHAS TELEFONICAS (MODEMS); FABRICACAO DE",
  },
  { cnae: "2631100", descricao: "MICRO PABX (ATE 32 RAMAIS); FABRICACAO DE" },
  {
    cnae: "2631100",
    descricao:
      "APARELHOS PARA GRAVACAO DE SOM PARA ESTUDIOS DE RADIO, TELEVISAO E GRAVADORAS; FABRICACAO DE",
  },
  { cnae: "2631100", descricao: "MODEM BANDA BASE; FABRICACAO DE" },
  { cnae: "2631100", descricao: "CENTRAIS TELEFONICAS; FABRICACAO DE" },
  {
    cnae: "2631100",
    descricao: "CONCETRADORES ELETRONICOS PARA COMUNICACAO; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao:
      "UNIDADE CHAVEADORA DE CONVERSOR OU DE AMPLIFICADOR PARA TELECOMUNICACOES POR SATELITE; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao:
      "TRANSMISSORES OU RECEPTORES DE TELEVISAO OU DE RADIOFUSAO, NAO ESPECIFICADOS (INCLUSIVE FREQUENCIAS DE MICROONDAS); FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "EQUIPAMENTOS DE COMUTACAO DIGITAL, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "CENTRAL DE COMUTACAO DE DADOS; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "CONVERSOR DE PROTOCOLO DE COMUNICACAO DE DADOS; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "GERADORES DE SINAIS ELETRICOS; FABRICACAO DE",
  },
  { cnae: "2631100", descricao: "TRANSMISSORES DE TELEVISAO; FABRICACAO DE" },
  {
    cnae: "2631100",
    descricao:
      "TRANSMISSORES OU RECEPTORES DE RADIOTELEFONIA EM FREQUENCIA DE MICROONDAS DIG.F < 15GHZ, T < = 8 MBIT/S; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "EQUIPAMENTOS PARA ESTUDIOS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "APARELHOS TRANSMISSORES DE TELEVISAO; FABRICACAO DE",
  },
  { cnae: "2631100", descricao: "MODEM ANALOGICO; FABRICACAO DE" },
  {
    cnae: "2631100",
    descricao:
      "EQUIPAMENTOS TRANSMISSORES DE RADIO E TELEVISAO, MONTAGEM E INSTALACAO EXECUTADAS PELO FABRICANTE",
  },
  {
    cnae: "2631100",
    descricao:
      "AMPLIFICADORES DE SOM PARA TRANSMISSAO DE RADIO E TELEVISAO; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "TRANSCEPTORES DE TELECOMUNICACOES POR SATELITE; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao:
      "CENTRAL PUBLICA DE COMUTACAO TELEFONICA TIPO CPA; FABRICACAO DE",
  },
  { cnae: "2631100", descricao: "CONTROLADORA DE COMUNICACAO; FABRICACAO DE" },
  {
    cnae: "2631100",
    descricao: "EQUIPAMENTOS PARA RADIO-CHAMADAS; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao:
      "TRANSMISSORES OU RECEPTORES DE SISTEMA TRONCALIZADO (TRUNKING); FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao:
      "ACOPLADOR ACUSTICO PARA TRANSMISSAO DE DADOS ATRAVES DE LINHAS TELEFONICAS; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "CENTRAIS AUTOMATICAS DE TELEX; FABRICACAO DE",
  },
  { cnae: "2631100", descricao: "ESTACOES TELEFONICAS; FABRICACAO DE" },
  { cnae: "2631100", descricao: "ESTACAO REMOTA PARA VSAT; FABRICACAO DE" },
  {
    cnae: "2631100",
    descricao: "TERMINAIS DE TELEFONIA CELULAR; FABRICACAO DE",
  },
  { cnae: "2631100", descricao: "CAMERAS DE TELEVISAO; FABRICACAO DE" },
  {
    cnae: "2631100",
    descricao:
      "PECAS E ACESSORIOS PARA TRANSMISSORES DE TELECOMUNICACAO; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao: "AMPLIFICADORES DE RADIOFREQUENCIA; FABRICACAO DE",
  },
  {
    cnae: "2631100",
    descricao:
      "APARELHOS PARA RECEPCAO, CONVERSAO, EMISSAO E TRANSMISSAO OU REGENERACAO DE VOZ, IMAGENS OU OUTROS DADOS, NAO ESPECIFICADOS ANTERIORMENTE; FABRICACAO DE",
  },
  {
    cnae: "26329",
    descricao:
      "FABRICACAO DE APARELHOS TELEFONICOS E DE OUTROS EQUIPAMENTOS DE COMUNICACAO",
  },
  {
    cnae: "2632900",
    descricao: "APARELHOS DE TELE-FAC-SIMILE (FAX); FABRICACAO DE",
  },
  { cnae: "2632900", descricao: "INTERCOMUNICADORES; FABRICACAO DE" },
  { cnae: "2632900", descricao: "TELEFONE CELULAR; FABRICACAO DE" },
  { cnae: "2632900", descricao: "CENTRAL DE PORTARIA; FABRICACAO DE" },
  {
    cnae: "2632900",
    descricao:
      "SISTEMAS DE INTERCOMUNICACAO (DITAFONES E SEMELHANTES); FABRICACAO DE",
  },
  { cnae: "2632900", descricao: "INTERFONES; FABRICACAO DE" },
  { cnae: "2632900", descricao: "PORTEIRO ELETRONICO; FABRICACAO DE" },
  { cnae: "2632900", descricao: "TELEFONES; FABRICACAO DE" },
  {
    cnae: "2632900",
    descricao: "PECAS PARA APARELHOS TELEFONICOS; FABRICACAO DE",
  },
  { cnae: "2632900", descricao: "IDENTIFICADOR DE CHAMADAS; FABRICACAO DE" },
  { cnae: "2632900", descricao: "SECRETARIAS ELETRONICAS; FABRICACAO DE" },
  {
    cnae: "2632900",
    descricao:
      "APARELHOS TELEFONICOS (TELEFONES) CONJUGADOS A OUTROS APARELHOS; FABRICACAO DE",
  },
  { cnae: "2632900", descricao: "CIRCUITOS FECHADOS DE TV; FABRICACAO DE" },
  { cnae: "2632900", descricao: "APARELHOS TELEFONICOS; FABRICACAO DE" },
  {
    cnae: "2632900",
    descricao: "APARELHOS TELEFONICOS (TELEFONES) PUBLICOS; FABRICACAO DE",
  },
  { cnae: "2632900", descricao: "RADIO DE COMUNICACAO; FABRICACAO DE" },
  {
    cnae: "26400",
    descricao:
      "FABRICACAO DE APARELHOS DE RECEPCAO, REPRODUCAO, GRAVACAO E AMPLIFICACAO DE AUDIO E VIDEO",
  },
  { cnae: "2640000", descricao: "VIDEOCAMERAS; FABRICACAO DE" },
  { cnae: "2640000", descricao: "RADIO-GRAVADOR; FABRICACAO DE" },
  {
    cnae: "2640000",
    descricao: "MICRO SYSTEM, MINI SYSTEM E SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "2640000", descricao: "AUDIOFONES (HEADFONE); FABRICACAO DE" },
  {
    cnae: "2640000",
    descricao:
      "APARELHOS PORTATEIS DE MUSICA, VIDEO E OUTRAS FUNCOES (MPX PLAYER) ; FABRICACAO DE",
  },
  { cnae: "2640000", descricao: "COMPACT DISC PLAYER; FABRICACAO DE" },
  { cnae: "2640000", descricao: "APARELHOS DE SOM CONJUGADOS; FABRICACAO DE" },
  { cnae: "2640000", descricao: "RADIOS PARA CARROS; FABRICACAO DE" },
  {
    cnae: "2640000",
    descricao:
      "EQUIPAMENTOS DE EXECUCAO AUTOMATICA DE AUDIO, VIDEO E OUTROS (INCLUSIVE JUKEBOXES) FABRICACAO DE",
  },
  {
    cnae: "2640000",
    descricao:
      "APARELHOS DE GRAVACAO DE SOM; APARELHOS DE REPRODUCAO DE SOM; APARELHOS DE GRAVACAO E DE REPRODUCAO DE SOM; FABRICACAO DE",
  },
  { cnae: "2640000", descricao: "DVD KARAOKE; FABRICACAO DE" },
  { cnae: "2640000", descricao: "ALTO-FALANTES; FABRICACAO DE" },
  { cnae: "2640000", descricao: "MP3 PLAYER; FABRICACAO DE" },
  {
    cnae: "2640000",
    descricao: "APARELHO RADIORRECEPTOR PARA AUTOMOVEL; FABRICACAO DE",
  },
  {
    cnae: "2640000",
    descricao: "VIDEOGAMES (JOGOS DE VIDEO), EXCETO CARTUCHOS; FABRICACAO DE",
  },
  { cnae: "2640000", descricao: "RADIOS; FABRICACAO DE" },
  { cnae: "2640000", descricao: "GRAVADOR E REPRODUTOR DE DVD; FABRICACAO DE" },
  {
    cnae: "2640000",
    descricao: "APARELHOS RECEPTORES DE TELEVISAO; FABRICACAO DE",
  },
  { cnae: "2640000", descricao: "TELEVISORES; FABRICACAO DE" },
  { cnae: "2640000", descricao: "DVD PLAYER; FABRICACAO DE" },
  { cnae: "2640000", descricao: "TELEVISAO; FABRICACAO DE" },
  { cnae: "2640000", descricao: "DVD PLAYER AUTOMOTIVO; FABRICACAO DE" },
  {
    cnae: "2640000",
    descricao:
      "PARTES, PECAS E ACESSORIOS PARA VIDEOGAMES (JOGOS DE VIDEO), EXCETO CARTUCHOS; FABRICACAO DE",
  },
  { cnae: "2640000", descricao: "FONE DE OUVIDO; FABRICACAO DE" },
  {
    cnae: "2640000",
    descricao: "MPX  PLAYER (PMP OU PORTABLE MEDIA PLAYER) ; FABRICACAO DE",
  },
  {
    cnae: "2640000",
    descricao:
      "PARTES, PECAS E ACESSORIOS PARA APARELHOS RECEPTORES, GRAVADORES OU REPRODUTORES DE AUDIO E VIDEO, EXCETO DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  { cnae: "2640000", descricao: "APARELHO DVD; FABRICACAO DE" },
  {
    cnae: "2640000",
    descricao:
      "APARELHOS PORTATEIS MULTIFUNCAO (MUSICA, VIDEO, CAMERA, TELEFONE, ETC); FABRICACAO DE",
  },
  {
    cnae: "2640000",
    descricao: "AMPLIFICADORES DE SOM PARA RECEPCAO; FABRICACAO DE",
  },
  { cnae: "2640000", descricao: "SOM PORTATIL; FABRICACAO DE" },
  { cnae: "2640000", descricao: "APARELHOS RADIORRECEPTORES; FABRICACAO DE" },
  { cnae: "2640000", descricao: "AUTORRADIO; FABRICACAO DE" },
  { cnae: "2640000", descricao: "MICROFONES; FABRICACAO DE" },
  { cnae: "2640000", descricao: "APARELHO DE VIDEOGAME; FABRICACAO DE" },
  { cnae: "2640000", descricao: "HOME THEATER; FABRICACAO DE" },
  {
    cnae: "2640000",
    descricao:
      "PARTES E PECAS PARA MICROFONES, ALTO-FALANTE, CAIXAS ACUSTICAS, AMPLIFICADORES DE AUDIO, FONES DE OUVIDO; FABRICACAO DE",
  },
  {
    cnae: "2640000",
    descricao:
      "ANTENAS E REFLETORES DE ANTENAS DE QUALQUER IPO (INCLUSIVE PARTES), EXCETO PARA TELEFONES CELULARES; FABRICACAO DE",
  },
  { cnae: "2640000", descricao: "CAIXA ACUSTICA; FABRICACAO DE" },
  { cnae: "2640000", descricao: "MP4 PLAYER; FABRICACAO DE" },
  { cnae: "3211601", descricao: "AGUA-MARINHA, LAPIDACAO DE" },
  {
    cnae: "2640000",
    descricao: "ANTENAS DE TELEVISAO, INCLUSIVE PARABOLICAS; FABRICACAO DE",
  },
  {
    cnae: "2640000",
    descricao:
      "RECEPTORES PESSOAIS DE RADIOMENSAGEM E OUTROS APARELHOS DE RECEPTORES SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2640000",
    descricao: "AUTORRADIO COM REPRODUTOR DE FITA; FABRICACAO DE",
  },
  {
    cnae: "2640000",
    descricao: "MONITORES DE VIDEO, EXCETO PARA COMPUTADORES; FABRICACAO DE",
  },
  {
    cnae: "2640000",
    descricao: "AMPLIFICADOR COM SINTONIZADOR (RECEIVER); FABRICACAO DE",
  },
  { cnae: "2640000", descricao: "CARTUCHO PARA VIDEOGAME; FABRICACAO DE" },
  { cnae: "2640000", descricao: "SISTEMAS INTEGRADOS DE SOM; FABRICACAO DE" },
  {
    cnae: "2640000",
    descricao:
      "RECEPTOR-DECODIFICADOR DE SINAIS DE VIDEO CODIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2640000",
    descricao:
      "PECAS E ACESSORIOS PARA TELEVISORES, RADIOS E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2640000",
    descricao: "PROJETORES DE VIDEO, EXCETO PARA COMPUTADORES; FABRICACAO DE",
  },
  { cnae: "2640000", descricao: "RADIO PARA AUTOMOVEIS; FABRICACAO DE" },
  { cnae: "2640000", descricao: "CD PLAYER; FABRICACAO DE" },
  { cnae: "2640000", descricao: "RADIO DIGITAL; FABRICACAO DE" },
  { cnae: "2640000", descricao: "GRAVADOR DE DVD; FABRICACAO DE" },
  {
    cnae: "26515",
    descricao:
      "FABRICACAO DE APARELHOS E EQUIPAMENTOS DE MEDIDA, TESTE E CONTROLE",
  },
  { cnae: "2651500", descricao: "POLARIMETROS E SACARIMETROS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "HIGROMETRO E HIDROGRAFO; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao: "INDICADOR DE NIVEL DE GASOLINA; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "BAROMETROS E BAROGRAFOS; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao:
      "BANCOS DE ENSAIO DE OUTROS TIPOS, INCLUSIVE PARA MOTORES; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "GPS NAVEGADOR; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao: "APARELHOS DE RADIODETECCAO E RADIOSONDAGEM; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao:
      "APARELHOS E EQUIPAMENTOS PARA LABORATORIOS DE PESQUISA CIENTIFICA; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "CALIBRADORES DE PNEUS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "TECNIGRAFOS; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao:
      "MAQUINAS, APARELHOS E EQUIPAMENTOS ELETRONICOS DEDICADOS A AUTOMACAO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao:
      "CONTROLADORES DE PRESSAO (INSTRUMENTO PARA CONTROLE DO PROCESSO PRODUTIVO); FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "INSTRUMENTOS DE MEDIDA ELETRICOS OU ELETRONICOS; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao:
      "EQUIPAMENTOS E INSTRUMENTOS PARA CONTROLE DE PROCESSOS PRODUTIVOS E ANALISES; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "MICROSCOPIOS (EXCETO OPTICOS); FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "CONTROLADOR PROGRAMAVEL -CP; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao:
      "SENSORES PARA AUTOMOCAO INDUSTRIAL OU CONTROLE DO PROCESSO PRODUTIVOS; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "TELEMETROS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "APARELHOS DE RADIONAVEGACAO; FABRICACAO DE" },
  { cnae: "2651500", descricao: "PAQUIMETROS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "UMIDOSTATOS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "RASTREADORES POR SATELITE; FABRICACAO DE" },
  { cnae: "2651500", descricao: "PRESSOSTATOS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "SONARES; FABRICACAO DE" },
  { cnae: "2651500", descricao: "ESTACAO DE TRABALHO; FABRICACAO DE" },
  { cnae: "2651500", descricao: "TRANSMISSOR DIGITAL; FABRICACAO DE" },
  { cnae: "2651500", descricao: "PLUVIOMETRO E PLUVIOGRAFO; FABRICACAO DE" },
  { cnae: "2651500", descricao: "CONTADORES DE GASES; FABRICACAO DE" },
  { cnae: "2651500", descricao: "AMPERIMETROS; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao: "FOCOMETROS, FOTOMETROS E APARELHOS SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "MEDIDORES DE GAS; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao: "CONTROLADOR DIGITAL DE PROCESSO; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "ESCALAS DE REDUCAO; FABRICACAO DE" },
  { cnae: "2651500", descricao: "PIROMETROS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "CONTADORES DE LIQUIDOS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "MANOMETRO; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao:
      "INDICADORES DE VELOCIDADE E TACOMETROS; ESTROBOSCOPIOS; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "APARELHOS E INSTRUMENTOS PARA METEOROLOGIA; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "MEDIDORES DE KWH MONOFASICOS OU POLIFASICOS; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "COMPUTADOR DE BORDO PARA VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "PECAS E ACESSORIOS PARA MEDIDORES DE GAS; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "MICROMETRICOS, CALIBRES E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "SISTEMA DIGITAL DE CONTROLE DISTRIBUIDO (SDCD); FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "OHMITER; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao:
      "UNIDADES CENTRAIS PARA SUPERVISAO E CONTROLE DE AUTOMACAO; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "PROJETORES DE PERFIS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "RADARES (RADIOCOMUNICACAO), FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao:
      "MAQUINAS E APARELHOS PARA ENSAIOS MECANICOS DE METAIS; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "TERMOSTATOS E SEMELHANTES; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao:
      "TAXIMETROS, PEDOMETROS, TACOMETROS, VELOCIMETROS E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao:
      "CONTROLADOR DIGITAL DE DEMANDA DE ENERGIA ELETRICA; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "REFRATOMETROS; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao:
      "PARTES E PECAS PARA DENSIMETROS, AREOMETRO, HIGROMETRO, TERMOMETROS (EXCETO CLINICOS) E INSTRUMENTOS SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao:
      "MAQUINAS, APARELHOS E UTENSILIOS PARA LABORATORIOS DE ANALISES NAO-CLINICAS (ENSAIO DE SOLO E OUTROS MATERIAIS PARA TESTES DE CIMENTO, CONCRETO, ASFALTO, ETC); FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao:
      "MAQUINAS E APARELHOS PARA ENSAIOS DE PAPEIS E CARTOES; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "PILOTOS AUTOMATICOS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "BALANCAS SENSIVEIS; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao: "MAQUINAS E APARELHOS PARA ENSAIOS DE TEXTEIS; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "MESAS E MAQUINAS DE DESENHAR, MESMO AUTOMATICAS; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "APARELHOS DE RADIO PARA APOIO A NAVEGACAO; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "CONTADORES DE ELETRICIDADE; FABRICACAO DE" },
  { cnae: "2651500", descricao: "WATTIMETROS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "HIDROMETROS; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao:
      "APARELHOS E INSTRUMENTOS PARA ANALISES FISICAS OU QUIMICAS (ESPECTROMETROS, COLORIMETROS, CALORIMETROS, ETC); FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "APARELHOS DE RADIOTELECOMANDO; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "MAQUINAS DE BALANCEAR PECAS MECANICAS; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "CONTROLADORES DE PRESSAO E TEMPERATURA; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "CONTROLADORES LOGICOS PROGRAMAVEIS (CLP); FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "BALANCAS DE PRECISAO; FABRICACAO DE" },
  { cnae: "2651500", descricao: "AMPERO-VOLTIMETROS; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao: "ESTOJOS COMPLETOS PARA DESENHO; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao:
      "EQUIPAMENTOS E INSTRUMENTOS PARA NAVEGACAO AEREA; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "FREQUENCIMETROS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "OSCILOSCOPIOS E OSCILOGRAFOS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "TEODOLITOS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "GASOMETROS; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao:
      "APARELHOS E EQUIPAMENTOS PARA PROCURA E DETECCAO; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao:
      "PARTES E PECAS PARA APARELHOS DE RADIODETECCAO, RADIOSSONDAGEM (RADAR), RADIONAVEGACAO; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao:
      "PECAS E ACESSORIOS PARA APARELHOS DE MEDIDA, TESTE E CONTROLE; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "SISMOMETROS E SISMOGRAFOS; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao: "COMANDO NUMERICO COMPUTADORIZADO - UNIDADE CNC; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "TERMOMETROS NAO CLINICOS; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao: "EQUIPAMENTOS PARA MONITORAMENTO AMBIENTAL; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao:
      "CONTADORES DE VOLTAS, CONTADORES DE PRODUCAO, TAXIMETROS E OUTROS CONTADORES SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao:
      "MULTIMETROS, VOLTIMETROS, AMPERIMETROS E APARELHOS SEMELHANTES PARA MEDIDA OU CONTROLE; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "TERMOMETROS (EXCETO TERMOMETROS CLINICOS); FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "ANEMOMETROS; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao:
      "EQUIPAMENTOS DIGITAIS AUTOMATICOS PARA CONTROLE DE VEICULOS FERROVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao:
      "INSTRUMENTO, UTENSILIOS E APARELHOS DE MEDIDA, TESTE E CONTROLE; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "APARELHOS E INSTRUMENTOS PARA GEODESIA; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao:
      "INSTRUMENTOS DE OUTROS TIPOS PARA MEDIDA E CONTROLE DE GRANDEZAS ELETRICAS E PARA MEDIDAOU DETECCAO DE RADIACOES IONIZANTES; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao:
      "MICROTOMOS; PARTES, PECAS E ACESSORIOS PARA OUTROS INSTRUMENTOS E APARELHOS PARA ANALISE, ETC.; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "PANTOGRAFOS; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao: "INSTRUMENTOS E APARELHOS PARA NAVEGACAO AEREA; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "ANALISADORES DE GASES OU DE FUMACA (FUMO); FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "BANCOS DE PROVAS, INCLUSIVE PARA VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao:
      "APARELHOS E EQUIPAMENTOS PARA LABORATORIOS DE PESQUISA E DESENVOLVIMENTO; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "ALTIMETROS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "INSTRUMENTOS AERONAUTICOS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "GPS AUTOMOTIVO; FABRICACAO DE" },
  { cnae: "2651500", descricao: "BUSSOLAS; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao:
      "APARELHOS, INSTRUMENTOS E UTENSILIOS DE MEDIDA PARA USOS TECNICOS E PROFISSIONAIS; FABRICACAO DE",
  },
  {
    cnae: "2651500",
    descricao: "SISTEMA DE CONTROLE E SUPERVISAO - SCS; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "COMPASSOS; FABRICACAO DE" },
  { cnae: "2651500", descricao: "DENSIMETROS E AREOMETROS; FABRICACAO DE" },
  {
    cnae: "2651500",
    descricao: "PECAS E ACESSORIOS PARA MEDIDORES DE KWH; FABRICACAO DE",
  },
  { cnae: "2651500", descricao: "TESTADORES DE VALVULAS; FABRICACAO DE" },
  { cnae: "26523", descricao: "FABRICACAO DE CRONOMETROS E RELOGIOS" },
  {
    cnae: "2652300",
    descricao:
      "CRONOMETROS, PARQUIMETROS, TEMPORIZADORES E OUTROS DISPOSITIVOS; FABRICACAO DE",
  },
  {
    cnae: "2652300",
    descricao: "CAIXAS PARA RELOGIOS DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  { cnae: "2652300", descricao: "DESPERTADOR; FABRICACAO DE" },
  {
    cnae: "2652300",
    descricao:
      "RELOGIOS PARA PAINEIS DE INSTRUMENTOS DE AUTOMOVEIS E OUTROS VEICULOS; FABRICACAO DE",
  },
  { cnae: "2652300", descricao: "RELOGIOS DE PAREDE; FABRICACAO DE" },
  {
    cnae: "2652300",
    descricao: "MONTAGEM DE CRONOMETROS E RELOGIOS; SERVICO DE",
  },
  { cnae: "2652300", descricao: "RELOGIOS DE PULSO; FABRICACAO DE" },
  {
    cnae: "2652300",
    descricao: "MECANISMOS DE RELOJOARIA E SUAS PARTES; FABRICACAO DE",
  },
  { cnae: "2652300", descricao: "RELOGIOS DE BOLSO; FABRICACAO DE" },
  { cnae: "2652300", descricao: "PARTES E PECAS PARA RELOGIOS; FABRICACAO DE" },
  {
    cnae: "2652300",
    descricao:
      "APARELHOS DE OUTROS TIPOS PARA ACIONAR MECANISMO EM TEMPO DETERMINADO, INCLUSIVE INTERRUPTORES HORARIOS; FABRICACAO DE",
  },
  { cnae: "2652300", descricao: "RELOGIOS; FABRICACAO DE" },
  { cnae: "2652300", descricao: "RELOGIOS DE PONTO; FABRICACAO DE" },
  {
    cnae: "26604",
    descricao:
      "FABRICACAO DE APARELHOS ELETROMEDICOS E ELETROTERAPEUTICOS E EQUIPAMENTOS DE IRRADIACAO",
  },
  { cnae: "2660400", descricao: "APARELHOS ELETRODIAGNOSTICOS; FABRICACAO DE" },
  { cnae: "2660400", descricao: "CARDIODESFIBRILADOR; FABRICACAO DE" },
  {
    cnae: "2660400",
    descricao: "APARELHOS ELETRONICOS PARA ODONTOLOGIA; FABRICACAO DE",
  },
  {
    cnae: "2660400",
    descricao: "APARELHOS DE TOMOGRAFIA COMPUTADORIZADA; FABRICACAO DE",
  },
  {
    cnae: "2660400",
    descricao:
      "PARTES E ACESSORIOS PARA MARCA-PASSOS, PARA APARELHOS AUDITIVOS OU PARA CARDIODESFIBRILADOR AUTOMATICO; FABRICACAO DE",
  },
  { cnae: "2660400", descricao: "APARELHOS PARA FISIOTERAPIA; FABRICACAO DE" },
  {
    cnae: "2660400",
    descricao: "APARELHOS PARA USO ELETROCIRURGICOS; FABRICACAO DE",
  },
  { cnae: "2660400", descricao: "INCUBADORA PARA BEBES; FABRICACAO DE" },
  {
    cnae: "2660400",
    descricao:
      "APARELHOS DE RAIO X PARA USO MEDICO-ODONTOLOGICO; FABRICACAO DE",
  },
  { cnae: "2660400", descricao: "MARCAPASSOS; FABRICACAO DE" },
  { cnae: "2660400", descricao: "APARELHOS ELETRODENTARIOS; FABRICACAO DE" },
  {
    cnae: "2660400",
    descricao: "EQUIPAMENTOS MEDICOS HOSPITALARES; FABRICACAO DE",
  },
  {
    cnae: "2660400",
    descricao:
      "PECAS E ACESSORIOS ELETRONICOS PARA INSTALACOES HOSPITALARES, MEDICAS, ODONTOLOGICAS E PARA LABORATORIOS; FABRICACAO DE",
  },
  {
    cnae: "2660400",
    descricao:
      "ELETROCARDIOGRAFOS, ENDOSCOPIOS E OUTROS APARELHOS PARA ELETRODIAGNOSTICO; FABRICACAO DE",
  },
  { cnae: "2660400", descricao: "APARELHOS AUDITIVOS; FABRICACAO DE" },
  { cnae: "2660400", descricao: "VALVULAS CARDIACAS; FABRICACAO DE" },
  {
    cnae: "2660400",
    descricao: "APARELHOS DE RESSONANCIA MAGNETICA; FABRICACAO DE",
  },
  { cnae: "2660400", descricao: "APARELHOS ELETROTERAPEUTICOS; FABRICACAO DE" },
  {
    cnae: "2660400",
    descricao:
      "PECAS PARA APARELHOS ELETRONICOS PARA USOS MEDICO, CIRURGICO, E ODONTOLOGICO; FABRICACAO DE",
  },
  { cnae: "2660400", descricao: "APARELHOS PARA ENDOSCOPIA; FABRICACAO DE" },
  {
    cnae: "2660400",
    descricao:
      "APARELHOS PARA APLICACAO DE ULTRAVIOLETA E INFRAVERMELHO; FABRICACAO DE",
  },
  {
    cnae: "26701",
    descricao:
      "FABRICACAO DE EQUIPAMENTOS E INSTRUMENTOS OPTICOS, FOTOGRAFICOS E CINEMATOGRAFICOS",
  },
  {
    cnae: "2670101",
    descricao: "DISPOSITIVOS DE CRISTAIS LIQUIDOS (LCD); FABRICACAO DE",
  },
  { cnae: "2670101", descricao: "MICROSCOPIOS OPTICOS; FABRICACAO DE" },
  {
    cnae: "2670101",
    descricao:
      "INSTRUMENTOS OPTICOS (PARA ASTRONOMIA E COSMOGRAFIA); FABRICACAO DE",
  },
  {
    cnae: "2670101",
    descricao:
      "PARTES, PECAS E ACESSORIOS PARA APARELHOS DE PROJECAO FIXA, ETC.; FABRICACAO DE",
  },
  { cnae: "2670101", descricao: "PRISMAS OPTICOS; FABRICACAO DE" },
  { cnae: "2670101", descricao: "TELESCOPIOS; FABRICACAO DE" },
  {
    cnae: "2722802",
    descricao: "BATERIAS E ACUMULADORES PARA VEICULOS, RECONDICIONAMENTO DE",
  },
  {
    cnae: "2670101",
    descricao:
      "PARTES E PECAS PARA INSTRUMENTOS DE ASTRONOMIA E PARA MICROSCOPIOS OU OUTROS APARELHOS OU INSTRUMENTOS OPTICOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2670101",
    descricao:
      "APARELHO DE TUBO DE DESCARGA, PARA PRODUCAO DE LUZ-RELAMPAGO (FLASHES ELETRONICOS); FABRICACAO DE",
  },
  {
    cnae: "2670101",
    descricao:
      "LENTES, FILTROS, PRISMAS, ESPELHOS E OUTROS ELEMENTOS DE OPTICA, MONTADOS, PARA INSTRUMENTOS E APARELHOS; FABRICACAO DE",
  },
  {
    cnae: "2670101",
    descricao:
      "DISPOSITIVOS, APARELHOS E INSTRUMENTOS OPTICOS DE OUTROS TIPOS, EXCETO DISPOSITIVOS DE CRISTAIS LIQUIDOS; FABRICACAO DE",
  },
  { cnae: "2670101", descricao: "BINOCULOS E LUNETAS; FABRICACAO DE" },
  {
    cnae: "2670101",
    descricao:
      "LENTES, PRISMAS, ESPELHOS E OUTROS ELEMENTOS DE OPTICA, NAO MONTADOS; FABRICACAO DE",
  },
  { cnae: "2670102", descricao: "APARELHOS CINEMATOGRAFICOS; FABRICACAO DE" },
  {
    cnae: "2670102",
    descricao:
      "LAMPADAS, CUBOS E SEMELHANTES DE LUZ RELAMPAGO PARA FOTOGRAFIA; FABRICACAO DE",
  },
  {
    cnae: "2670102",
    descricao:
      "TELAS PARA PROJECOES CINEMATOGRAFICAS, SLIDES E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2670102",
    descricao:
      "APARELHOS DE PROJECAO FIXA; LEITORAS DE MICROFILMES E OUTROS MICROFORMATOS; CAMERAS FOTOGRAFICAS DE AMPLIACAO E REDUCAO; FABRICACAO DE",
  },
  { cnae: "2670102", descricao: "LENTES FOTOGRAFICAS; FABRICACAO DE" },
  { cnae: "2670102", descricao: "MAQUINAS FOTOGRAFICAS; FABRICACAO DE" },
  {
    cnae: "2670102",
    descricao:
      "PECAS E ACESSORIOS PARA APARELHOS E INSTRUMENTOS OPTICOS; FABRICACAO DE",
  },
  { cnae: "2670102", descricao: "PROJETORES CINEMATOGRAFICOS; FABRICACAO DE" },
  {
    cnae: "2670102",
    descricao: "LAMPADAS PARA MAQUINAS FOTOGRAFICAS; FABRICACAO DE",
  },
  { cnae: "2670102", descricao: "AMPLIADORES FOTOGRAFICOS; FABRICACAO DE" },
  { cnae: "2670102", descricao: "CAMERAS FOTOGRAFICAS; FABRICACAO DE" },
  {
    cnae: "2670102",
    descricao: "APARELHOS DE FOTOGRAMETRIA - EXCETO CAMARAS; FABRICACAO DE",
  },
  {
    cnae: "2670102",
    descricao: "CAMERAS FOTOGRAFICAS DIGITAIS; FABRICACAO DE",
  },
  { cnae: "2670102", descricao: "FILMADORAS; FABRICACAO DE" },
  {
    cnae: "2670102",
    descricao:
      "APARELHOS, INSTRUMENTOS E MATERIAIS PARA FOTOGRAFIAS, N.E.; FABRICACAO DE",
  },
  { cnae: "2670102", descricao: "CAMERAS CINEMATOGRAFICAS; FABRICACAO DE" },
  {
    cnae: "2670102",
    descricao:
      "PECAS E ACESSORIOS PARA APARELHO FOTOGRAFICO E CINEMATOGRAFICO; FABRICACAO DE",
  },
  { cnae: "2670102", descricao: "APARELHOS FOTOGRAFICOS; FABRICACAO DE" },
  {
    cnae: "2670102",
    descricao: "APARELHOS PARA LABORATORIOS FOTOGRAFICOS; FABRICACAO DE",
  },
  {
    cnae: "26809",
    descricao: "FABRICACAO DE MIDIAS VIRGENS, MAGNETICAS E OPTICAS",
  },
  {
    cnae: "2680900",
    descricao:
      "DISCOS MAGNETICOS VIRGENS PARA SOM, VIDEO E DADOS; FABRICACAO DE",
  },
  {
    cnae: "2680900",
    descricao:
      "SUPORTES VIRGENS PARA GRAVACAO DE SOM, VIDEO E DADOS INFORMATICOS; FABRICACAO DE",
  },
  { cnae: "2680900", descricao: "DISQUETES; FABRICACAO DE" },
  { cnae: "2680900", descricao: "BLU-RAY DISC; FABRICACAO DE" },
  {
    cnae: "2680900",
    descricao: "CARTOES MAGNETICOS NAO GRAVADOS, FABRICACAO DE",
  },
  { cnae: "2680900", descricao: "FITAS CASSETES VIRGENS; FABRICACAO DE" },
  { cnae: "2680900", descricao: "COMPACT DISC (CD), VIRGEM; FABRICACAO DE" },
  { cnae: "2680900", descricao: "DISCOS E FITAS VIRGENS; FABRICACAO DE" },
  {
    cnae: "2680900",
    descricao:
      "MIDIAS VIRGENS MAGNETICAS OU OPTICAS (PARA GRAVACAO DE SOM, IMAGEM E DADOS INFORMATICOS); FABRICACAO DE",
  },
  { cnae: "2680900", descricao: "FITAS DE VIDEO VIRGENS; FABRICACAO DE" },
  {
    cnae: "27104",
    descricao: "FABRICACAO DE GERADORES, TRANSFORMADORES E MOTORES ELETRICOS",
  },
  {
    cnae: "2710401",
    descricao:
      "PECAS E ACESSORIOS PARA GERADORES DE ENERGIA ELETRICA; FABRICACAO DE",
  },
  {
    cnae: "2710401",
    descricao: "BOBINAS DE REATANCIA E DE AUTOINDUCAO; FABRICACAO DE",
  },
  {
    cnae: "2710401",
    descricao: "MAQUINAS DE CORRENTE CONTINUA (GERADORES); FABRICACAO DE",
  },
  { cnae: "2710401", descricao: "GRUPOS ELETROGENEOS; FABRICACAO DE" },
  {
    cnae: "2710401",
    descricao: "MAQUINAS SINCRONAS (ALTERNADORES); FABRICACAO DE",
  },
  {
    cnae: "2710401",
    descricao: "HIDROGERADORES E TURBOGERADORES; FABRICACAO DE",
  },
  {
    cnae: "2710401",
    descricao:
      "MAQUINAS SINCRONAS (GERADORES DE CORRENTE ALTERNADA, ALTERNADORES); FABRICACAO DE",
  },
  {
    cnae: "2710401",
    descricao: "GERADORES DE ENERGIA ELETRICA; FABRICACAO DE",
  },
  { cnae: "2710401", descricao: "GERADORES A GASOLINA; FABRICACAO DE" },
  { cnae: "2710401", descricao: "GRUPOS MOTO-GERADORES; FABRICACAO DE" },
  {
    cnae: "2710401",
    descricao: "TRANSFORMADORES DE DIELETRICO LIQUIDO; FABRICACAO DE",
  },
  { cnae: "2710402", descricao: "CONVERSORES DE CORRENTE; FABRICACAO DE" },
  {
    cnae: "2710402",
    descricao:
      "EQUIPAMENTOS DE ALIMENTACAO ININTERRUPTA DE ENERGIA (NO BREAK); FABRICACAO DE",
  },
  { cnae: "2710402", descricao: "TRANSFORMADORES DE FORCA; FABRICACAO DE" },
  { cnae: "2710402", descricao: "TRANSFORMADORES DE CORRENTE; FABRICACAO DE" },
  {
    cnae: "2710402",
    descricao: "PARTES E PECAS PARA CONVERSORES ELETRICOS; FABRICACAO DE",
  },
  { cnae: "2710402", descricao: "CONVERSORES, N.E.; FABRICACAO DE" },
  {
    cnae: "2710402",
    descricao: "TRANSFORMADORES DE ALTA E BAIXA TENSAO; FABRICACAO DE",
  },
  { cnae: "2710402", descricao: "GRUPOS CONVERSORES; FABRICACAO DE" },
  { cnae: "2710402", descricao: "TRANSFORMADORES DE POTENCIAL; FABRICACAO DE" },
  {
    cnae: "2710402",
    descricao:
      "PECAS E ACESSORIOS PARA TRANSFORMADORES, INDUTORES, CONVERSORES, SINCRONIZADORES E SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "2710402", descricao: "TRANSFORMADORES ELETRICOS; FABRICACAO DE" },
  {
    cnae: "2710402",
    descricao: "TRANSFORMADORES DE DISTRIBUICAO; FABRICACAO DE",
  },
  {
    cnae: "2710402",
    descricao:
      "INDUTORES, CONVERSORES E SINCRONIZADORES ELETRICOS; FABRICACAO DE",
  },
  { cnae: "2710402", descricao: "CONVERSORES DE FREQUENCIA; FABRICACAO DE" },
  { cnae: "2710403", descricao: "MICROMOTORES ELETRICOS; FABRICACAO DE" },
  { cnae: "2710403", descricao: "MOTORES UNIVERSAIS; FABRICACAO DE" },
  { cnae: "2710403", descricao: "MOTORES ELETRICOS TRIFASICOS; FABRICACAO DE" },
  {
    cnae: "2710403",
    descricao: "PECAS E ACESSORIOS PARA MOTORES ELETRICOS; FABRICACAO DE",
  },
  {
    cnae: "2710403",
    descricao: "MOTORES ELETRICOS MONOFASICOS; FABRICACAO DE",
  },
  { cnae: "2710403", descricao: "MOTORES DE CORRENTE CONTINUA; FABRICACAO DE" },
  {
    cnae: "2710403",
    descricao: "ROTORES PARA MOTORES ELETRICOS; FABRICACAO DE",
  },
  { cnae: "2710403", descricao: "MOTORES ELETRICOS, N.E.; FABRICACAO DE" },
  {
    cnae: "2710403",
    descricao: "MOTORES ELETRICOS PARA VEICULOS FERROVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "27210",
    descricao:
      "FABRICACAO DE PILHAS, BATERIAS E ACUMULADORES ELETRICOS, EXCETO PARA VEICULOS AUTOMOTORES",
  },
  {
    cnae: "2721000",
    descricao: "BATERIAS E ACUMULADORES, EXCETO PARA VEICULOS; FABRICACAO DE",
  },
  { cnae: "2721000", descricao: "BATERIAS SECAS; FABRICACAO DE" },
  { cnae: "2721000", descricao: "PILHAS SECAS; FABRICACAO DE" },
  {
    cnae: "2721000",
    descricao:
      "ACUMULADORES OU BATERIAS ELETRICOS, EXCETO PARA VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "2721000",
    descricao:
      "PARTES E PECAS PARA ACUMULADORES ELETRICOS (SEPARADORES, PLACAS, ETC) - EXCETO PARA VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "2721000",
    descricao: "ACUMULADORES ELETRICOS NAO AUTOMOTIVOS; FABRICACAO DE",
  },
  {
    cnae: "27228",
    descricao:
      "FABRICACAO DE BATERIAS E ACUMULADORES PARA VEICULOS AUTOMOTORES",
  },
  {
    cnae: "2722801",
    descricao: "BATERIAS E ACUMULADORES PARA VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "2722801",
    descricao:
      "PARTES E PECAS PARA BATERIAS E ACUMULADORES PARA VEICULOS (PLACAS, SEPARADORES, ETC); FABRICACAO DE",
  },
  {
    cnae: "27317",
    descricao:
      "FABRICACAO DE APARELHOS E EQUIPAMENTOS PARA DISTRIBUICAO E CONTROLE DE ENERGIA ELETRICA",
  },
  {
    cnae: "2731700",
    descricao:
      "CENTROS DE CONTROLE DE MOTORES EM MEDIA TENSAO (CCM S); FABRICACAO DE",
  },
  { cnae: "2731700", descricao: "PARA-RAIOS TIPO ESTACAO; FABRICACAO DE" },
  {
    cnae: "2731700",
    descricao: "PARA-RAIOS DE PROTECAO DE LINHAS; FABRICACAO DE",
  },
  {
    cnae: "2731700",
    descricao:
      "PAINEIS DE COMANDO DE ENERGIA ELETRICA; SERVICOS DE PRODUCAO E SERVICOS AUXILIARES",
  },
  { cnae: "2731700", descricao: "DISJUNTORES; FABRICACAO DE" },
  { cnae: "2731700", descricao: "FUSIVEIS PARA ALTA TENSAO; FABRICACAO DE" },
  {
    cnae: "2731700",
    descricao: "PAINEIS DE DISTRIBUICAO DE BAIXA TENSAO; FABRICACAO DE",
  },
  {
    cnae: "2731700",
    descricao:
      "CENTROS DE CONTROLE DE MOTORES DE BAIXA TENSAO (CCM S); FABRICACAO DE",
  },
  {
    cnae: "2731700",
    descricao:
      "SUBESTACOES PARA DISTRIBUICAO E CONTROLE DE ENERGIA ELETRICA; FABRICACAO DE",
  },
  { cnae: "2731700", descricao: "CHAVES SECCIONADORAS; FABRICACAO DE" },
  {
    cnae: "2731700",
    descricao:
      "QUADROS DE COMANDO OU DISTRIBUICAO DE ENERGIA ELETRICA; FABRICACAO DE",
  },
  {
    cnae: "2731700",
    descricao: "PAINEIS DE DISTRIBUICAO DE MEDIA TENSAO; FABRICACAO DE",
  },
  {
    cnae: "2731700",
    descricao:
      "CASAS OU CABINES DE FORCA PARA DISTRIBUICAO E CONTROLE DE ENERGIA ELETRICA; FABRICACAO DE",
  },
  { cnae: "2731700", descricao: "MESAS DE COMANDO; FABRICACAO DE" },
  {
    cnae: "2731700",
    descricao:
      "REGULADORES, ESTABILIZADORES E VARIADORES DE VOLTAGEM, EXCETO PARA VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "2731700",
    descricao: "RETIFICADORES DE CORRENTE ELETRICA; FABRICACAO DE",
  },
  {
    cnae: "2731700",
    descricao: "CHAVES MAGNETICAS DE BAIXA TENSAO; FABRICACAO DE",
  },
  { cnae: "2731700", descricao: "PARA-RAIOS TIPO SUBESTACAO; FABRICACAO DE" },
  { cnae: "2731700", descricao: "PARA-RAIOS TIPO DISTRIBUICAO; FABRICACAO DE" },
  { cnae: "2731700", descricao: "SECCIONADORAS DE ALTA TENSAO; FABRICACAO DE" },
  { cnae: "2731700", descricao: "PAINEIS DE PROTECAO; FABRICACAO DE" },
  {
    cnae: "2731700",
    descricao: "CHAVES FUSIVEIS PARA BAIXA TENSAO; FABRICACAO DE",
  },
  { cnae: "2731700", descricao: "CHAVES ELETRICAS, N.E.; FABRICACAO DE" },
  {
    cnae: "2731700",
    descricao:
      "PECAS E ACESSORIOS PARA EQUIPAMENTOS DE DISTRIBUICAO E CONTROLE DE ENERGIA ELETRICA; FABRICACAO DE",
  },
  {
    cnae: "2731700",
    descricao:
      "PAINEIS DE COMANDO E CONTROLE DE ENERGIA ELETRICA; FABRICACAO DE",
  },
  { cnae: "2731700", descricao: "INVERSORES DE CORRENTE; FABRICACAO DE" },
  {
    cnae: "27325",
    descricao:
      "FABRICACAO DE MATERIAL ELETRICO PARA INSTALACOES EM CIRCUITO DE CONSUMO",
  },
  {
    cnae: "2732500",
    descricao:
      "CONECTORES PARA CABOS PLANOS DE CONDUTOR PARALELO, DE TENSAO MENOR OU IGUAL A 1 KV; FABRICACAO DE",
  },
  {
    cnae: "2732500",
    descricao:
      "APARELHOS DE OUTROS TIPOS PARA PROTECAO DE CIRCUITOS ELETRICOS PARA TENSAO MENOR OU IGUAL A 1 KV; FABRICACAO DE",
  },
  { cnae: "2732500", descricao: "PINOS PARA TOMADAS; FABRICACAO DE" },
  {
    cnae: "2732500",
    descricao: "PLUGUES PARA FERRO ELETRICO E OUTROS FINS; FABRICACAO DE",
  },
  {
    cnae: "2732500",
    descricao: "BOTOEIRAS DE COMANDO PARA CHAVES ELETRICAS; FABRICACAO DE",
  },
  {
    cnae: "2732500",
    descricao:
      "PECAS E ACESSORIOS PARA MATERIAIS ELETRICOS USADOS EM CIRCUITO DE CONSUMO; FABRICACAO DE",
  },
  { cnae: "2732500", descricao: "RELES, EXCETO PARA VEICULOS; FABRICACAO DE" },
  {
    cnae: "2732500",
    descricao: "DISJUNTORES PARA TENSAO MENOR OU IGUAL A 1 KV; FABRICACAO DE",
  },
  { cnae: "2732500", descricao: "CIGARRAS, CAMPAINHAS, ETC.; FABRICACAO DE" },
  {
    cnae: "2732500",
    descricao:
      "CONECTORES PARA FIBRAS OPTICAS, FEIXES OU CABOS DE FIBRAS OPTICAS; FABRICACAO DE",
  },
  {
    cnae: "2732500",
    descricao:
      "APARELHOS PARA INTERRUPCAO DE OUTROS TIPOS PARA CIRCUITOS ELETRICOS DE TENSAO MENOR OU IGUAL A 1 KV, INCLUSIVE TOMADAS DE CONTATO DESLIZANTE; FABRICACAO DE",
  },
  {
    cnae: "2732500",
    descricao:
      "MATERIAL ELETRICO PARA INSTALACOES EM CIRCUITO DE CONSUMO; FABRICACAO DE",
  },
  {
    cnae: "2732500",
    descricao: "BASES COMPLETAS PARA FUSIVEIS; FABRICACAO DE",
  },
  { cnae: "2732500", descricao: "BOTOES PARA CAMPAINHAS; FABRICACAO DE" },
  { cnae: "2732500", descricao: "TOMADAS ELETRICAS; FABRICACAO DE" },
  {
    cnae: "2732500",
    descricao: "MINUTERIAS (INTERRUPTORES DE ACAO RETARDADA); FABRICACAO DE",
  },
  { cnae: "2732500", descricao: "INTERRUPTORES E COMUTADORES; FABRICACAO DE" },
  {
    cnae: "2732500",
    descricao:
      "PARTES E PECAS PARA APARELHOS PARA INTERRUPCAO, PROTECAO, LIGACAO E SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "2732500", descricao: "FUSIVEIS PARA BAIXA TENSAO; FABRICACAO DE" },
  {
    cnae: "27333",
    descricao: "FABRICACAO DE FIOS, CABOS E CONDUTORES ELETRICOS ISOLADOS",
  },
  {
    cnae: "2733300",
    descricao:
      "FIOS, CABOS E CONDUTORES ELETRICOS DE ALUMINIO, ISOLADOS; FABRICACAO DE",
  },
  {
    cnae: "2733300",
    descricao: "CORDOALHAS PARA ANTENAS DE RADIO E TELEVISAO; FABRICACAO DE",
  },
  {
    cnae: "2733300",
    descricao: "CHICOTES ELETRICOS, EXCETO PARA VEICULOS; FABRICACAO DE",
  },
  { cnae: "2733300", descricao: "FIOS TELEFONICOS; FABRICACAO DE" },
  {
    cnae: "2733300",
    descricao:
      "FIOS, CABOS OU CONDUTORES ELETRICO DE OUTROS TIPOS, MUNIDOS DE PECAS DE CONEXAO, TENSAO MENOR OU IGUAL A 1000V; FABRICACAO DE",
  },
  {
    cnae: "2733300",
    descricao:
      "CABOS COAXIAIS E OUTROS CONDUTORES ELETRICOS COAXIAIS; FABRICACAO DE",
  },
  {
    cnae: "2733300",
    descricao:
      "FIOS MAGNETICOS PARA ENROLAMENTOS DE BOBINAS, MOTORES E TRANSFORMADORES; FABRICACAO DE",
  },
  {
    cnae: "2733300",
    descricao:
      "FIOS, CABOS E CONDUTORES ELETRICOS ISOLADOS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2733300",
    descricao:
      "CORDOALHAS E OUTROS CONDUTORES ELETRICOS ISOLADOS; FABRICACAO DE",
  },
  {
    cnae: "2733300",
    descricao:
      "FIOS, CABOS E CONDUTORES ELETRICOS DE COBRE, ISOLADOS; FABRICACAO DE",
  },
  {
    cnae: "2733300",
    descricao: "FIBRA OPTICA E CABOS DE FIBRA OPTICA; FABRICACAO DE",
  },
  {
    cnae: "27406",
    descricao: "FABRICACAO DE LAMPADAS E OUTROS EQUIPAMENTOS DE ILUMINACAO",
  },
  {
    cnae: "2740601",
    descricao: "LAMPADAS DE RAIOS INFRAVERMELHO, ULTRAVIOLETA; FABRICACAO DE",
  },
  {
    cnae: "2740601",
    descricao:
      "DISPOSITIVOS DE PARTIDA PARA LAMPADAS FLUORESCENTES (STARTERS); FABRICACAO DE",
  },
  { cnae: "2740601", descricao: "LAMPADAS HALOGENAS; FABRICACAO DE" },
  {
    cnae: "2740601",
    descricao: "BASES DE METAL COMPLETAS PARA LAMPADAS; FABRICACAO DE",
  },
  {
    cnae: "2740601",
    descricao: "LAMPADAS DESCARTAVEIS PARA FLASH; FABRICACAO DE",
  },
  { cnae: "2740601", descricao: "SOQUETES PARA LAMPADAS; FABRICACAO DE" },
  {
    cnae: "2740601",
    descricao: "LAMPADAS REFLETORAS (ESPELHADAS); FABRICACAO DE",
  },
  { cnae: "2740601", descricao: "LAMPADAS MINIATURAS; FABRICACAO DE" },
  { cnae: "2740601", descricao: "LAMPADAS INCANDESCENTES; FABRICACAO DE" },
  { cnae: "2740601", descricao: "FILAMENTOS PARA LAMPADAS; FABRICACAO DE" },
  { cnae: "2740601", descricao: "LAMPADAS FLUORESCENTES; FABRICACAO DE" },
  { cnae: "2740601", descricao: "LAMPADAS DE CARVAO; FABRICACAO DE" },
  { cnae: "2740601", descricao: "REATORES PARA LAMPADAS; FABRICACAO DE" },
  {
    cnae: "2740601",
    descricao: "TUBOS DE DESCARGA PARA LAMPADAS ELETRICAS; FABRICACAO DE",
  },
  {
    cnae: "2740601",
    descricao:
      "PARTES E PECAS PARA LAMPADA E TUBOS ELETRICOS INCANDESCENTES, ETC.; FABRICACAO DE",
  },
  { cnae: "2740601", descricao: "LAMPADAS DE GAS DE MERCURIO; FABRICACAO DE" },
  { cnae: "2740601", descricao: "LAMPADAS, N.E.; FABRICACAO DE" },
  {
    cnae: "2740601",
    descricao:
      "PARTES E PECAS PARA REATORES PARA LAMPADAS E TUBOS DE DESCARGA; FABRICACAO DE",
  },
  { cnae: "2740601", descricao: "LAMPADAS A VAPOR DE SODIO; FABRICACAO DE" },
  {
    cnae: "2740601",
    descricao: "LAMPADAS PARA FAROIS DE AUTOMOVEIS; FABRICACAO DE",
  },
  {
    cnae: "2740601",
    descricao:
      "LAMPADAS PARA SINALIZACAO EXTERNA DE AUTOMOVEIS, EXCETO PARA FAROIS; FABRICACAO DE",
  },
  {
    cnae: "2740601",
    descricao: "JOGOS DE LAMPADAS USADOS EM ARVORES DE NATAL; FABRICACAO DE",
  },
  { cnae: "2740601", descricao: "LAMPADAS DE FILAMENTOS; FABRICACAO DE" },
  { cnae: "2740601", descricao: "ACESSORIOS PARA LAMPADAS; FABRICACAO DE" },
  { cnae: "2740602", descricao: "LANTERNAS, FABRICACAO DE" },
  {
    cnae: "2740602",
    descricao: "PROJETORES DE LUZ, REFLETORES E SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "2740602", descricao: "LUMINARIAS COMPLETAS; FABRICACAO DE" },
  {
    cnae: "2740602",
    descricao:
      "PARTES E PECAS PARA APARELHOS DE ILUMINACAO - EXCETO DE PLASTICO E VIDRO; FABRICACAO DE",
  },
  { cnae: "2740602", descricao: "ABAJURES; FABRICACAO DE" },
  {
    cnae: "2740602",
    descricao:
      "APARELHOS ELETRICOS DE OUTROS TIPOS PARA ILUMINACAO; FABRICACAO DE",
  },
  {
    cnae: "2740602",
    descricao: "SINALIZADORES PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  { cnae: "2740602", descricao: "LUSTRES; FABRICACAO DE" },
  {
    cnae: "2740602",
    descricao:
      "CALHAS METALICAS COMPLETAS PARA LAMPADAS FLUORESCENTES; FABRICACAO DE",
  },
  {
    cnae: "2740602",
    descricao:
      "APARELHOS DE ILUMINACAO OU DE SINALIZACAO VISUAL PARA BICICLETAS; FABRICACAO DE",
  },
  {
    cnae: "2740602",
    descricao: "APARELHOS NAO ELETRICOS DE ILUMINACAO; FABRICACAO DE",
  },
  {
    cnae: "27511",
    descricao:
      "FABRICACAO DE FOGOES, REFRIGERADORES E MAQUINAS DE LAVAR E SECAR PARA USO DOMESTICO",
  },
  {
    cnae: "2751100",
    descricao: "MAQUINAS DE SECAR ROUPAS PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2751100",
    descricao: "MAQUINAS DE LAVAR ROUPAS PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2751100",
    descricao: "MAQUINAS DE LAVAR LOUCAS PARA USO DOMESTICO; FABRICACAO DE",
  },
  { cnae: "2751100", descricao: "REFRIGERADORES DOMESTICOS; FABRICACAO DE" },
  {
    cnae: "2751100",
    descricao: "FORNOS DE MICROONDAS PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2751100",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS DE SECAR ROUPAS (SECADORAS) DE USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2751100",
    descricao:
      "PECAS E ACESSORIOS PARA REFRIGERADORES DOMESTICOS; FABRICACAO DE",
  },
  {
    cnae: "2751100",
    descricao: "SECADORAS DE ROUPA DE USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2751100",
    descricao:
      "LAVADORAS DE LOUCAS (LAVA-LOUCAS) PARA USO DOMESTICO; FABRICACAO DE",
  },
  { cnae: "2751100", descricao: "GELADEIRAS DE USO DOMESTICO; FABRICACAO DE" },
  {
    cnae: "2751100",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS DE LAVAR ROUPAS (LAVADORAS) DE USO DOMESTICO; FABRICACAO DE",
  },
  { cnae: "2751100", descricao: "FOGOES PARA USO DOMESTICO; FABRICACAO DE" },
  {
    cnae: "2751100",
    descricao:
      "PECAS E ACESSORIOS PARA FOGOES PARA USO DOMESTICO; FABRICACAO DE",
  },
  { cnae: "2751100", descricao: "FREEZERS PARA USO DOMESTICO; FABRICACAO DE" },
  {
    cnae: "2751100",
    descricao:
      "CONGELADORES OU CONSERVADORAS (FREEZERS) DOMESTICOS; FABRICACAO DE",
  },
  {
    cnae: "2751100",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS DE LAVAR LOUCAS; FABRICACAO DE",
  },
  {
    cnae: "2751100",
    descricao: "LAVADORA TANQUINHO PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2751100",
    descricao: "LAVADORAS DE ROUPA DE USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2751100",
    descricao:
      "PECAS E ACESSORIOS PARA GELADEIRAS DE USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2751100",
    descricao:
      "PECAS E ACESSORIOS PARA CONGELADORES OU CONSERVADORES DOMESTICOS (FREEZERS); FABRICACAO DE",
  },
  {
    cnae: "27597",
    descricao:
      "FABRICACAO DE APARELHOS ELETRODOMESTICOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "2759701",
    descricao:
      "PARTES E PECAS PARA MAQUINAS DE BARBEAR, DEPILAR E CORTAR CABELO E SEMELHANTES, COM MOTOR ELETRICO; FABRICACAO DE",
  },
  {
    cnae: "2759701",
    descricao: "COBERTORES OU MANTAS ELETRICOS; FABRICACAO DE",
  },
  { cnae: "2759701", descricao: "BARBEADORES ELETRICOS; FABRICACAO DE" },
  {
    cnae: "2759701",
    descricao: "SECADOR MODELADOR PARA CABELO; FABRICACAO DE",
  },
  {
    cnae: "2759701",
    descricao:
      "PRANCHA ALISADORA (CHAPINHA) ELETRICA PARA CABELO; FABRICACAO DE",
  },
  {
    cnae: "2759701",
    descricao:
      "SECADORES E OUTROS APARELHOS PARA CUIDADOS DO CABELO; APARELHOS PARA SECAR AS MAOS; FABRICACAO DE",
  },
  {
    cnae: "2759701",
    descricao: "ESCOVA MODELADORA (MODELADOR) PARA CABELO; FABRICACAO DE",
  },
  {
    cnae: "2759701",
    descricao: "APARELHOS DE BARBEAR, ELETRICOS; FABRICACAO DE",
  },
  {
    cnae: "2759701",
    descricao:
      "SECADORES E MODELADORES DE CABELO PARA USO PESSOAL; FABRICACAO DE",
  },
  { cnae: "2759701", descricao: "DEPILADORES ELETRICOS; FABRICACAO DE" },
  {
    cnae: "2759799",
    descricao: "ENCERADEIRAS ELETRICAS PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao:
      "CHURRASQUEIRA ELETRICA OU NAO PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "CORTADORES DE FRIOS DE USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "EXTRATOR DE SUCOS, DE USO DOMESTICO; FABRICACAO DE",
  },
  { cnae: "2759799", descricao: "CHUVEIROS E DUCHAS ELETRICOS; FABRICACAO DE" },
  {
    cnae: "2759799",
    descricao: "EXAUSTORES ELETRICOS PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "FORNOS ELETRICOS DE USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "TORRADEIRAS ELETRICAS PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "FRITADEIRA ELETRICA, DE USO DOMESTICO; FABRICACAO DE",
  },
  { cnae: "2759799", descricao: "CENTRIFUGA, DE USO DOMESTICO; FABRICACAO DE" },
  {
    cnae: "2759799",
    descricao:
      "FILTROS, DEPURADORES, OZONIZADORES E SEMELHANTES PARA USO DOMESTICO",
  },
  { cnae: "2759799", descricao: "MULTI-PANELA ELETRICA; FABRICACAO DE" },
  {
    cnae: "2759799",
    descricao: "FERROS ELETRICOS A VAPOR OU A SECO; FABRICACAO DE",
  },
  { cnae: "2759799", descricao: "FACA ELETRICA; FABRICACAO DE" },
  {
    cnae: "2759799",
    descricao: "OZONIZADORES DE USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "COIFAS (EXAUSTORES) DOMESTICOS; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "AQUECEDORES DOMESTICOS PARA AGUA; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao:
      "CIRCULADORES DE AR, ELETRICOS, PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao:
      "APARELHOS NAO-ELETRICOS PARA USO DOMESTICO, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "MINI-FORNOS PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "VENTILADORES ELETRICOS, PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao:
      "PARTES E PECAS PARA FORNOS, AQUECEDORES DE ALIMENTOS E APARELHOS DOMESTICOS NAO ELETRICOS SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao:
      "GERADORES OU DISTRIBUIDORES, NAO ELETRICOS E SUAS PARTES, DE FERRO FUNDIDO, FERRO OU ACO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "PURIFICADOR DE AGUA DE USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao:
      "PARTES E PECAS PARA AQUECEDORES ELETRICOS DE AGUA, INCLUSIVE OS DE IMERSAO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "FOGAREIROS ELETRICOS, DE USO DOMESTICO; FABRICACAO DE",
  },
  { cnae: "2759799", descricao: "ELETROPORTATEIS, FABRICACAO DE" },
  { cnae: "2759799", descricao: "MICROFORNO (DOMESTICO); FABRICACAO DE" },
  {
    cnae: "2759799",
    descricao: "ESPREMEDORES DE FRUTAS PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao:
      "BATEDEIRAS DE BOLO, ELETRICAS, PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "MULTIPROCESSADOR, DE USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "PURIFICADOR DE AR, DE USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao:
      "MAQUINAS PARA MOER CARNE, ELETRICAS, PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "SANDUICHEIRA DE USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "RESISTENCIAS ELETRICAS DE AQUECIMENTO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao:
      "APARELHOS ELETRICOS DE OUTROS TIPOS PARA AQUECIMENTO DE AMBIENTES, INCLUSIVE RADIADORES DE ACUMULACAO; FABRICACAO DE",
  },
  { cnae: "2759799", descricao: "GRILL, PARA USO DOMESTICO; FABRICACAO DE" },
  {
    cnae: "2759799",
    descricao:
      "PROCESSADORES (MULTIPROCESSADORES) DE ALIMENTOS PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "ASPIRADORES DE PO PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "DEPURADOR (EXAUSTOR), PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "TOSTADOR (TORRADEIRA), DE USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "CAFETEIRAS ELETRICAS, PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "FILTROS DE METAL COMPLETOS PARA USO DOMESTICO; MONTAGEM DE",
  },
  {
    cnae: "2759799",
    descricao: "ADEGAS DE VINHO CLIMATIZADAS PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "SELADORA PORTATIL PARA PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "MAQUINA DE LIMPEZA A VAPOR, DE USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "MIX (BATEDEIRA), DE USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao:
      "FILTROS, DEPURADORES, OZONIZADORES E SEMELHANTES PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "PANIFICADORA AUTOMATICA PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "TRITURADOR DE ALIMENTOS, DE USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao: "LIQUIDIFICADORES ELETRICOS, PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2759799",
    descricao:
      "PECAS E ACESSORIOS PARA APARELHOS ELETRODOMESTICOS; FABRICACAO DE",
  },
  {
    cnae: "27902",
    descricao:
      "FABRICACAO DE EQUIPAMENTOS E APARELHOS ELETRICOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "2790201", descricao: "ELETROIMAS; FABRICACAO DE" },
  {
    cnae: "2790201",
    descricao: "IMAS PERMANENTES E APARELHOS PARA MAGNETIZACAO; FABRICACAO DE",
  },
  { cnae: "2790201", descricao: "MASSA ISOLANTE; FABRICACAO DE" },
  {
    cnae: "2790201",
    descricao: "ESCOVAS E CONTATOS DE CARVAO PARA MOTORES; FABRICACAO DE",
  },
  {
    cnae: "2790201",
    descricao:
      "ARTEFATOS DE CARVAO E GRAFITA PARA MAQUINAS E APARELHOS ELETRICOS; FABRICACAO DE",
  },
  {
    cnae: "2790201",
    descricao: "NUCLEOS DE PO FERROMAGNETICOS; FABRICACAO DE",
  },
  { cnae: "2790201", descricao: "FITA ISOLANTE; FABRICACAO DE" },
  {
    cnae: "2790201",
    descricao:
      "ISOLADORES PARA APARELHOS OU EQUIPAMENTOS ELETRICOS; FABRICACAO DE",
  },
  {
    cnae: "2790201",
    descricao:
      "ELETRODOS, ESCOVAS E OUTROS ARTIGOS DE CARVAO OU GRAFITA PARA USOS ELETRICOS; FABRICACAO DE",
  },
  {
    cnae: "2790201",
    descricao: "CARVAO PARA USO EM ELETRICIDADE; FABRICACAO DE",
  },
  {
    cnae: "2790201",
    descricao: "PLACAS E BASTOES DE CARVAO E GRAFITA; FABRICACAO DE",
  },
  { cnae: "2790201", descricao: "ELETRODOS DE GRAFITA; FABRICACAO DE" },
  {
    cnae: "2790202",
    descricao: "APARELHOS DE ALARME CONTRA INCENDIO, FABRICACAO DE",
  },
  {
    cnae: "2790202",
    descricao:
      "APARELHOS OU EQUIPAMENTOS DE SINALIZACAO E ALARME, N.E., FABRICACAO DE",
  },
  {
    cnae: "2790202",
    descricao: "APARELHOS PARA SINALIZACAO DE FERROVIAS; FABRICACAO DE",
  },
  {
    cnae: "2790202",
    descricao: "CONTROLADOR DIGITAL DE TRAFEGO; FABRICACAO DE",
  },
  {
    cnae: "2790202",
    descricao:
      "APARELHOS PARA CONTROLE DE TRAFEGO DE AUTOMOTORES; FABRICACAO DE",
  },
  { cnae: "2790202", descricao: "ALARMES PARA VEICULOS, FABRICACAO DE" },
  {
    cnae: "2790202",
    descricao: "APARELHO PARA SINALIZACAO LUMINOSA; FABRICACAO DE",
  },
  {
    cnae: "2790202",
    descricao:
      "PECAS E ACESSORIOS PARA APARELHOS DE SINALIZACAO, FABRICACAO DE",
  },
  {
    cnae: "2790202",
    descricao:
      "APARELHOS ELETRICOS DE SINALIZACAO ACUSTICA (SIRENES, ETC); FABRICACAO DE",
  },
  {
    cnae: "2790202",
    descricao:
      "APARELHOS PARA CONTROLE DE TRAFEGO DE VIAS FERREAS; FABRICACAO DE",
  },
  {
    cnae: "2790202",
    descricao: "APARELHOS DE ALARME CONTRA ROUBO, FABRICACAO DE",
  },
  { cnae: "2790202", descricao: "FAROIS MARITIMOS; FABRICACAO DE" },
  { cnae: "2790202", descricao: "ALARMES RESIDENCIAIS, FABRICACAO DE" },
  {
    cnae: "2790202",
    descricao: "APARELHOS PARA SINALIZACAO DE PISTAS DE POUSO; FABRICACAO DE",
  },
  {
    cnae: "2790202",
    descricao:
      "APARELHOS PARA CONTROLE DE SINALIZACAO DE TRANSITO; FABRICACAO DE",
  },
  {
    cnae: "2790202",
    descricao: "ALARMES CONTRA INCENDIO OU SOBREAQUECIMENTOS, FABRICACAO DE",
  },
  {
    cnae: "2790202",
    descricao:
      "PARTES E PECAS PARA APARELHOS ELETRICOS PARA SINALIZACAO E ALARME; FABRICACAO DE",
  },
  { cnae: "2790202", descricao: "SEMAFOROS (SINAIS LUMINOSOS); FABRICACAO DE" },
  {
    cnae: "2790202",
    descricao:
      "APARELHOS AUXILIARES ELETRONICOS PARA CONTROLE DE TRAFEGO RODOVIARIO, AEREO, FERROVIARIO E MARITIMO; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao: "EQUIPAMENTOS DE OUTROS TIPOS PARA ESTUDIO; FABRICACAO DE",
  },
  { cnae: "2790299", descricao: "CARREGADORES DE BATERIAS; FABRICACAO DE" },
  {
    cnae: "2790299",
    descricao:
      "TUBOS ISOLANTES E SUAS PECAS, PARA MAQUINAS, APARELHOS E INSTALACOES ELETRICAS, EXCETO DE CERAMICA OU DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao: "NUCLEOS DE PO FERROMAGNETICOS; FABRICACAO DE",
  },
  { cnae: "2790299", descricao: "PORTAS E PORTOES AUTOMATICOS; FABRICACAO DE" },
  {
    cnae: "2790299",
    descricao:
      "APARELHOS PARA GALVANOPLASTIA, ELETROLISE OU ELETROFORESE; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao: "GERADORES DE SINAIS ELETRICOS; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao: "APARELHOS ELETRICOS PARA ESPANTAR ROEDORES; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao:
      "DESPERDICIOS E RESIDUOS DE PILHAS, BATERIAS E ACUMULADORES ELETRICOS; FABRICACAO DE",
  },
  { cnae: "2790299", descricao: "ELETRIFICADORES DE CERCAS; FABRICACAO DE" },
  {
    cnae: "2790299",
    descricao: "RESISTENCIAS, EXCETO ELETRONICA; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao: "PARTES E PECAS PARA RESISTORES ELETRICOS; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao:
      "PECAS PARA APARELHOS E EQUIPAMENTOS ELETRICOS PARA FINS ELETROQUIMICOS E OUTROS USOS TECNICOS; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao: "RESISTORES VARIAVEIS OU AJUSTAVEIS; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao: "DISPOSITIVOS INDUSTRIAIS DE CONTROLE ELETRICO; FABRICACAO DE",
  },
  { cnae: "2790299", descricao: "MAQUINAS PARA GALVANIZACAO, FABRICACAO DE" },
  { cnae: "2790299", descricao: "FONTE DE ALIMENTACAO; FABRICACAO DE" },
  {
    cnae: "2790299",
    descricao:
      "PARTES E PECAS PARA MAQUINAS E APARELHOS ELETRICOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  { cnae: "2790299", descricao: "FREIOS ELETROMAGNETICOS; FABRICACAO DE" },
  { cnae: "2790299", descricao: "PLACAR ELETRONICO; FABRICACAO DE" },
  {
    cnae: "2790299",
    descricao:
      "PARTES OU PECAS PARA CAPACITORES ELETRICOS FIXOS, VARIAVEIS, AJUSTAVEIS; FABRICACAO DE",
  },
  { cnae: "2790299", descricao: "MAQUINAS PARA CROMAGEM; FABRICACAO DE" },
  { cnae: "2790299", descricao: "DETONADORES ELETRICOS; FABRICACAO DE" },
  { cnae: "2790299", descricao: "ELIMINADOR DE PILHA; FABRICACAO DE" },
  {
    cnae: "2790299",
    descricao:
      "CONTROLE REMOTO PARA APARELHOS ELETROELETRONICOS; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao: "FERROS OU PISTOLAS PARA SOLDA ELETRICA; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao:
      "ACOPLAMENTOS, EMBREAGENS, ETC, ELETROMAGNETICOS E SUAS PARTES; FABRICACAO DE",
  },
  { cnae: "2790299", descricao: "ELIMINADOR DE BATERIA; FABRICACAO DE" },
  {
    cnae: "2790299",
    descricao: "MAQUINAS TOMADORAS DE VOTOS OU DE APOSTAS; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao: "CAPACITORES OU CONDENSADORES ELETRONICOS; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao:
      "EMBREAGEM E VARIADORES DE VELOCIDADE ELETROMAGNETICOS PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao:
      "MAQUINAS E APARELHOS AUXILIARES PARA VIDEO (GERADORES DE EFEITOS ESPECIAIS  DE CARACTERES DIGITAIS, CONTROLADOR DE EDICAO, MISTURADOR DIGITAL, ETC.); FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao: "APARELHOS ELETRONICOS PARA USOS TECNICOS, N.E., FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao:
      "TRANSCODIFICADOR OU CONVERSOR DE PADROES DE TELEVISAO; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao: "APARELHOS ELETRICOS PARA ESPANTAR INSETOS; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao: "RESISTENCIAS PARA AQUECIMENTO; FABRICACAO DE",
  },
  { cnae: "2790299", descricao: "ACELERADORES DE PARTICULAS; FABRICACAO DE" },
  {
    cnae: "2790299",
    descricao: "PAINEIS FOTOELETRICOS; FABRICACAO, MONTAGEM DE",
  },
  {
    cnae: "2790299",
    descricao: "PECAS ISOLANTES PARA MAQUINAS; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao:
      "PECAS E DISPOSITIVOS ELETRICOS E ELETRONICOS PARA MOTORES E MAQUINAS; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao: "AMPLIFICADORES DE RADIOFREQUENCIA; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao:
      "APARELHOS ELETRICOS PARA FINS ELETROQUIMICOS E PARA OUTROS USOS TECNICOS; FABRICACAO DE",
  },
  {
    cnae: "2790299",
    descricao:
      "CARTOES E ETIQUETAS DE ACIONAMENTO POR APROXIMACAO; FABRICACAO DE",
  },
  {
    cnae: "28119",
    descricao:
      "FABRICACAO DE MOTORES E TURBINAS, EXCETO PARA AVIOES E VEICULOS RODOVIARIOS",
  },
  { cnae: "2811900", descricao: "RODAS HIDRAULICAS; FABRICACAO DE" },
  {
    cnae: "2811900",
    descricao: "MOTORES DIESEL PARA EMBARCACOES; FABRICACAO DE",
  },
  { cnae: "9312300", descricao: "CLUBE DE BOXE" },
  {
    cnae: "2811900",
    descricao:
      "MOTORES DIESEL ESTACIONARIOS (EXCETO PARA VEICULOS AUTOMOTORES); FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao: "MOTORES COMPLETOS PARA TRATORES; FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao:
      "PECAS E ACESSORIOS PARA TURBINAS E RODAS HIDRAULICAS; FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao:
      "MOTORES, TURBINAS E OUTROS DISPOSITIVOS EOLICOS (A VENTO); FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao:
      "MOTORES COMPLETOS PARA MAQUINAS DE PAVIMENTACAO E TERRAPLENAGEM; FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao:
      "MAQUINAS MOTRIZES NAO-ELETRICAS; INSTALACAO DE, QUANDO EXECUTADA PELO FABRICANTE",
  },
  { cnae: "2811900", descricao: "MOTORES PARA AUTOMOTRIZES; FABRICACAO DE" },
  {
    cnae: "2811900",
    descricao:
      "MAQUINAS A VAPOR PARA EMBARCACOES, COM OU SEM CALDEIRAS; FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao: "MAQUINAS MOTRIZES, NAO-ELETRICAS; FABRICACAO DE",
  },
  { cnae: "2811900", descricao: "MOTORES DE POPA; FABRICACAO DE" },
  {
    cnae: "2811900",
    descricao:
      "PARTES OU PECAS DE MOTORES A EXPLOSAO OU DE MOTORES ESTACIONARIOS DIESEL OU SEMIDIESEL PARA MAQUINAS OU EQUIPAMENTOS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS MOTRIZES NAO-ELETRICA, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao: "CALDEIRAS GERADORAS DE VAPOR PARA LOCOMOTIVAS; FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao:
      "MOTORES DE COMBUSTAO INTERNA (EXCETO PARA VEICULOS AUTOMOTORES); FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao:
      "MOTORES DE COMBUSTAO INTERNA PARA LOCOMOTIVAS, CARROS-MOTOR E AUTOMOTRIZES; FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao:
      "MOTORES A GASOLINA, ESTACIONARIOS (EXCETO PARA VEICULOS RODOVIARIOS); FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao: "PECAS PARA MOTORES DE TRATORES; FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao: "PECAS E ACESSORIOS PARA MOTORES MARITIMOS; FABRICACAO DE",
  },
  { cnae: "2811900", descricao: "MOTORES MARITIMOS, N.E.; FABRICACAO DE" },
  {
    cnae: "2811900",
    descricao: "TURBINAS A GAS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao: "TURBINAS, MOTORES E OUTROS DIPSOSITIVOS EOLICOS; FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao: "CALDEIRAS GERADORAS DE VAPOR PARA EMBARCACOES; FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao: "PARTES E PECAS PARA TURBINAS A VAPOR; FABRICACAO DE",
  },
  { cnae: "2811900", descricao: "TURBINAS HIDRAULICAS; FABRICACAO DE" },
  {
    cnae: "2811900",
    descricao: "MAQUINAS A VAPOR PARA LOCOMOTIVAS, CONSTRUCAO DE",
  },
  { cnae: "2811900", descricao: "TURBINAS A VAPOR; FABRICACAO DE" },
  {
    cnae: "2811900",
    descricao:
      "PECAS E ACESSORIOS PARA MOTORES DIESEL PARA EMBARCACOES; FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao: "TURBINAS A VAPOR PARA EMBARCACOES; FABRICACAO DE",
  },
  { cnae: "2811900", descricao: "MOINHOS DE VENTO; FABRICACAO DE" },
  {
    cnae: "2811900",
    descricao: "TURBINAS, MOTORES E OUTROS DISPOSITIVOS EOLICOS; FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao:
      "MOTORES ESTACIONARIOS DIESEL OU SEMIDIESEL PARA MAQUINAS E EQUIPAMENTOS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2811900",
    descricao:
      "PECAS E ACESSORIOS PARA MOTORES ESTACIONARIOS DE COMBUSTAO INTERNA (EXCETO PARA VEICULOS AUTOMOTORES); FABRICACAO DE",
  },
  {
    cnae: "28127",
    descricao:
      "FABRICACAO DE EQUIPAMENTOS HIDRAULICOS E PNEUMATICOS, EXCETO VALVULAS",
  },
  {
    cnae: "2812700",
    descricao:
      "PECAS E ACESSORIOS PARA BOMBAS (ROTATIVAS, CENTRIFUGAS, ETC); FABRICACAO DE",
  },
  { cnae: "2812700", descricao: "BOMBAS HIDRAULICAS; FABRICACAO DE" },
  {
    cnae: "2812700",
    descricao:
      "MOTORES HIDRAULICOS, INCLUSIVE DE MOVIMENTO RETILINEO; FABRICACAO DE",
  },
  {
    cnae: "2812700",
    descricao:
      "MOTORES PNEUMATICOS, INCLUSIVE DE MOVIMENTO RETILINEO; FABRICACAO DE",
  },
  { cnae: "2812700", descricao: "BOMBAS VOLUMETRICAS; FABRICACAO DE" },
  {
    cnae: "2812700",
    descricao:
      "BOMBAS PARA LIQUIDOS, COMBUSTIVEIS E LUBRIFICANTES; FABRICACAO DE",
  },
  { cnae: "2812700", descricao: "CILINDROS PNEUMATICOS; FABRICACAO DE" },
  {
    cnae: "2812700",
    descricao:
      "BOMBAS CENTRIFUGAS, NAO ESPECIFICADOS, INCLUSIVE ELETROBOMBAS; FABRICACAO DE",
  },
  { cnae: "2812700", descricao: "ELETROBOMBAS; PRODUCAO DE" },
  {
    cnae: "2812700",
    descricao:
      "BOMBAS PARA LIQUIDOS, COMBUSTIVEIS E LUBRIFICANTES; MONTAGEM E INSTALACAO DE, QUANDO EXECUTADA PELA UNIDADE FABRICANTE",
  },
  { cnae: "2812700", descricao: "CILINDROS HIDRAULICOS; FABRICACAO DE" },
  { cnae: "2812700", descricao: "BOMBAS A VACUO; FABRICACAO DE" },
  { cnae: "2812700", descricao: "CARNEIROS HIDRAULICOS; FABRICACAO DE" },
  { cnae: "2812700", descricao: "BOMBAS DE AR OU DE VACUO; FABRICACAO DE" },
  {
    cnae: "2812700",
    descricao:
      "PECAS PARA BOMBAS DE DISTRIBUICAO DE COMBUSTIVEIS; FABRICACAO DE",
  },
  {
    cnae: "2812700",
    descricao:
      "PARTES E PECAS PARA MOTORES HIDRAULICOS, INCLUSIVE DE MOVIMENTO RETILINEO; FABRICACAO DE",
  },
  { cnae: "2812700", descricao: "MOTOBOMBAS; FABRICACAO DE" },
  {
    cnae: "2812700",
    descricao: "BOMBAS PARA POSTOS DE GASOLINA; FABRICACAO DE",
  },
  {
    cnae: "2812700",
    descricao: "BOMBAS PARA DISTRIBUICAO DE COMBUSTIVEIS; FABRICACAO DE",
  },
  { cnae: "2812700", descricao: "BOMBAS PARA CONCRETO; FABRICACAO DE" },
  {
    cnae: "2812700",
    descricao: "PARTES E PECAS PARA CILINDROS PNEUMATICOS; FABRICACAO DE",
  },
  {
    cnae: "2812700",
    descricao: "ROTOR (PECA PARA BOMBA HIDRAULICA); FABRICACAO DE",
  },
  {
    cnae: "2812700",
    descricao:
      "PARTES E PECAS PARA MOTORES PNEUMATICOS, INCLUSIVE DE MOVIMENTO RETILINEO; FABRICACAO DE",
  },
  {
    cnae: "28135",
    descricao: "FABRICACAO DE VALVULAS, REGISTROS E DISPOSITIVOS SEMELHANTES",
  },
  {
    cnae: "2813500",
    descricao: "VALVULAS BORBOLETA PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2813500",
    descricao: "VALVULAS GAVETA PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  { cnae: "3311200", descricao: "ARMAS DE FOGO; REPARACAO DE" },
  {
    cnae: "2813500",
    descricao:
      "PECAS E ACESSORIOS PARA VALVULAS INDUSTRIAIS (EXCETO PECAS FUNDIDAS OU FORJADAS); FABRICACAO DE",
  },
  {
    cnae: "2813500",
    descricao:
      "VALVULAS, TORNEIRAS E REGISTROS HIDRAULICOS METALICOS; PRODUCAO DE",
  },
  {
    cnae: "2813500",
    descricao:
      "VALVULAS PARA SISTEMA DE PERFURACAO DE POCOS DE PETROLEO (ESPECIAIS, DE LAMA E OUTRAS); FABRICACAO DE",
  },
  {
    cnae: "2813500",
    descricao:
      "VALVULAS DE DESCARGA RAPIDA E PERIODICA PARA CALDEIRAS; FABRICACAO DE",
  },
  {
    cnae: "2813500",
    descricao: "VALVULAS ANGULARES PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  { cnae: "2813500", descricao: "METAIS SANITARIOS; FABRICACAO DE" },
  {
    cnae: "2813500",
    descricao:
      "VALVULAS DE SEGURANCA E ALIVIO PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2813500",
    descricao: "VALVULAS PARA FINS INDUSTRIAIS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2813500",
    descricao: "VALVULAS ESFERICAS PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2813500",
    descricao: "VALVULAS DE DIAFRAGMA PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2813500",
    descricao: "VALVULAS TIPO MACHO PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2813500",
    descricao: "VALVULAS DE PRESSAO PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2813500",
    descricao: "VALVULAS GLOBO PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2813500",
    descricao: "VALVULAS AGULHA PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2813500",
    descricao: "VALVULAS MINIATURIZADAS PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2813500",
    descricao: "VALVULAS DE RETENCAO PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2813500",
    descricao: "VALVULAS, TORNEIRAS E REGISTROS SANITARIOS; FABRICACAO DE",
  },
  { cnae: "2813500", descricao: "VALVULAS AUTOMATICAS; FABRICACAO DE" },
  {
    cnae: "2813500",
    descricao: "VALVULAS DIRECIONAIS PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  { cnae: "28143", descricao: "FABRICACAO DE COMPRESSORES" },
  {
    cnae: "2814301",
    descricao: "COMPRESSORES PARA USO INDUSTRIAL, FABRICACAO DE",
  },
  {
    cnae: "2814301",
    descricao: "COMPRESSORES FRIGORIFICOS PARA USO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2814301",
    descricao:
      "PECAS E ACESSORIOS PARA COMPRESSORES INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2814301",
    descricao: "COMPRESSORES DE GAS PARA USO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2814302",
    descricao: "COMPRESSORES PARA USO NAO-INDUSTRIAL, FABRICACAO DE",
  },
  {
    cnae: "28151",
    descricao:
      "FABRICACAO DE EQUIPAMENTOS DE TRANSMISSAO PARA FINS INDUSTRIAIS",
  },
  {
    cnae: "2815101",
    descricao:
      "CAIXAS PARA ROLAMENTOS PARA TRANSMISSAO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2815101",
    descricao:
      "ESFERAS METALICAS PARA ROLAMENTOS PARA TRANSMISSAO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2815101",
    descricao:
      "ROLAMENTOS DE TODOS OS TIPOS PARA TRANSMISSAO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2815101",
    descricao:
      "PECAS E ACESSORIOS PARA ROLAMENTOS PARA TRANSMISSAO INDUSTRIAL; FABRICACAO DE",
  },
  { cnae: "2815102", descricao: "SELOS MECANICOS, FABRICACAO DE" },
  {
    cnae: "2815102",
    descricao:
      "PECAS E ACESSORIOS PARA EQUIPAMENTOS DE TRANSMISSAO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2815102",
    descricao: "ENGRENAGENS PARA TRANSMISSAO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2815102",
    descricao: "EIXOS CARDAN PARA TRANSMISSAO INDUSTRIAL; FABRICACAO DE",
  },
  { cnae: "2815102", descricao: "JUNTAS MECANICAS; FABRICACAO DE" },
  {
    cnae: "2815102",
    descricao: "VIRABREQUINS PARA TRANSMISSAO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2815102",
    descricao: "ROSCAS SEM FIM (PARA TRANSMISSAO INDUSTRIAL); FABRICACAO DE",
  },
  {
    cnae: "2815102",
    descricao:
      "CORRENTES DE ELOS ARTICULADOS PARA TRANSMISSAO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2815102",
    descricao:
      "DISPOSITIVOS DE ACOPLAMENTO PARA TRANSMISSAO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2815102",
    descricao:
      "POLIAS E VOLANTES DE TODOS OS TIPOS, PARA TRANSMISSAO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2815102",
    descricao:
      "EMBREAGENS, JUNTAS DE ARTICULACAO E ACOPLAMENTOS PARA TRANSMISSAO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2815102",
    descricao: "EIXOS DE TRANSMISSAO PARA INDUSTRIA; FABRICACAO DE",
  },
  {
    cnae: "2815102",
    descricao: "EQUIPAMENTOS PARA TRANSMISSAO INDUSTRIAL, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2815102",
    descricao:
      "JUNTAS PARA MAQUINAS INDUSTRIAIS (EXCETO DE BORRACHA, PLASTICO E FIBRA PRENSADA); FABRICACAO DE",
  },
  {
    cnae: "2815102",
    descricao: "REDUTORES E VARIADORES DE VELOCIDADE; FABRICACAO DE",
  },
  {
    cnae: "2815102",
    descricao: "VOLANTES OU POLIAS PARA TRANSMISSAO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2815102",
    descricao: "CREMALHEIRAS (PARA TRANSMISSAO INDUSTRIAL); FABRICACAO DE",
  },
  {
    cnae: "2815102",
    descricao: "COROAS E PINHOES PARA TRANSMISSAO INDUSTRIAL, FABRICACAO DE",
  },
  {
    cnae: "2815102",
    descricao:
      "JUNTAS DE ARTICULACAO PARA TRANSMISSAO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2815102",
    descricao: "MANCAIS PARA TRANSMISSAO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "28216",
    descricao:
      "FABRICACAO DE APARELHOS E EQUIPAMENTOS PARA INSTALACOES TERMICAS",
  },
  {
    cnae: "2821601",
    descricao: "FORNOS INDUSTRIAIS NAO-ELETRICOS; FABRICACAO DE",
  },
  {
    cnae: "2821601",
    descricao:
      "PECAS E ACESSORIOS PARA APARELHOS E EQUIPAMENTOS PARA INSTALACOES TERMICAS; FABRICACAO DE",
  },
  {
    cnae: "2821601",
    descricao:
      "MAQUINAS, APARELHOS E EQUIPAMENTOS TERMICOS PARA FINS INDUSTRIAIS, N.E.; FABRICACAO DE",
  },
  { cnae: "2821601", descricao: "QUEIMADORES INDUSTRIAIS; FABRICACAO DE" },
  { cnae: "2821601", descricao: "INCINERADORES, FABRICACAO DE" },
  {
    cnae: "2821601",
    descricao:
      "AQUECEDORES INDUSTRIAIS PARA AGUA, NAO-ELETRICOS, FABRICACAO DE",
  },
  {
    cnae: "2821601",
    descricao:
      "PECAS E ACESSORIOS PARA FORNOS INDUSTRIAIS NAO-ELETRICOS; FABRICACAO DE",
  },
  {
    cnae: "2821601",
    descricao: "MAQUINAS E EQUIPAMENTOS PARA CALEFACAO; FABRICACAO DE",
  },
  {
    cnae: "2821601",
    descricao:
      "MAQUINAS, APARELHOS E EQUIPAMENTOS PARA INSTALACOES TERMICAS ALIMENTADOS POR ENERGIA SOLAR, FABRICACAO DE",
  },
  {
    cnae: "2821601",
    descricao:
      "PECAS E ACESSORIOS PARA ESTUFAS, SECADORES E AUTOCLAVES NAO-ELETRICAS; FABRICACAO DE",
  },
  {
    cnae: "2821601",
    descricao:
      "AQUECEDORES DE AGUA, ALIMENTADOS POR ENERGIA SOLAR, PARA QUALQUER USO, FABRICACAO DE",
  },
  {
    cnae: "2821601",
    descricao:
      "AQUECEDORES DE AGUA, A GAS OU ENERGIA SOLAR, DE USO DOMESTICO, FABRICACAO DE",
  },
  {
    cnae: "2821601",
    descricao: "EVAPORADORES E CONCENTRADORES, FABRICACAO DE",
  },
  {
    cnae: "2821601",
    descricao:
      "APARELHOS E EQUIPAMENTOS NAO-ELETRICOS PARA INSTALACOES TERMICAS, INSTALACAO DE, QUANDO EXECUTADA PELA UNIDADE FABRICANTE",
  },
  {
    cnae: "2821601",
    descricao: "ESTUFAS, SECADORES E AUTOCLAVES NAO-ELETRICAS; FABRICACAO DE",
  },
  {
    cnae: "2821601",
    descricao: "EQUIPAMENTOS PARA COZINHAS INDUSTRIAIS, N.E.; FABRICACAO DE",
  },
  { cnae: "2821601", descricao: "TORRES DE RESFRIAMENTO; FABRICACAO DE" },
  {
    cnae: "2821601",
    descricao:
      "PARTES E PECAS PARA QUEIMADORES, FORNALHAS, GRELHAS E DISPOSITIVOS SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2821601",
    descricao: "BOILER EM ACO, ELETRICO OU A GAS; FABRICACAO DE",
  },
  {
    cnae: "2821601",
    descricao:
      "PARTES E PECAS PARA AQUECEDORES DE AGUA, A GAS OU ENERGIA SOLAR; FABRICACAO DE",
  },
  { cnae: "2821601", descricao: "RADIADORES DE CALOR; FABRICACAO DE" },
  { cnae: "2821601", descricao: "AQUECEDORES PARA PISCINAS; FABRICACAO DE" },
  {
    cnae: "2821601",
    descricao: "PECAS E ACESSORIOS PARA INCINERADORES, FABRICACAO DE",
  },
  { cnae: "2821601", descricao: "COLETORES SOLARES, FABRICACAO DE" },
  {
    cnae: "2821602",
    descricao:
      "PECAS E ACESSORIOS PARA ESTUFAS E FORNOS ELETRICOS PARA FINS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2821602",
    descricao: "ESTUFAS E AUTOCLAVES ELETRICAS; FABRICACAO DE",
  },
  {
    cnae: "2821602",
    descricao:
      "FORNOS INDUSTRIAIS ELETRICOS E APARELHOS SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2821602",
    descricao: "FORNOS ELETRICOS PARA SIDERURGIA E METALURGIA; FABRICACAO DE",
  },
  {
    cnae: "2821602",
    descricao:
      "EQUIPAMENTOS TERMICOS (ELETRICOS) PARA COZINHAS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "28224",
    descricao:
      "FABRICACAO DE MAQUINAS, EQUIPAMENTOS E APARELHOS PARA TRANSPORTE E ELEVACAO DE CARGAS E PESSOAS",
  },
  { cnae: "2822401", descricao: "TELEFERICOS; FABRICACAO DE" },
  {
    cnae: "2822401",
    descricao: "PECAS E ACESSORIOS PARA TELEFERICOS; FABRICACAO DE",
  },
  { cnae: "2822401", descricao: "PONTES ROLANTES; FABRICACAO DE" },
  { cnae: "2822401", descricao: "CABINAS PARA ELEVADORES; FABRICACAO DE" },
  { cnae: "2822401", descricao: "ESCADAS ROLANTES; FABRICACAO DE" },
  {
    cnae: "2822401",
    descricao:
      "MAQUINAS, EQUIPAMENTOS E APARELHOS PARA TRANSPORTE E ELEVACAO DE PESSOAS, INSTALACAO E MANUTENCAO DE, QUANDO EXECUTADA PELA UNIDADE FABRICANTE",
  },
  {
    cnae: "2822401",
    descricao:
      "ELEVADORES PARA TRANSPORTE E ELEVACAO DE PESSOAS, INSTALACAO E MANUTENCAO DE, QUANDO EXECUTADA PELA UNIDADE FABRICANTE",
  },
  { cnae: "2822401", descricao: "ELEVADORES PARA PASSAGEIROS; FABRICACAO DE" },
  {
    cnae: "2822402",
    descricao:
      "PECAS PARA MAQUINAS DE TRANSPORTE E ELEVACAO DE CARGA; FABRICACAO DE",
  },
  {
    cnae: "2822402",
    descricao:
      "VEICULOS SEM DISPOSITIVO PARA ELEVACAO, UTILIZADO EM FABRICAS; FABRICACAO DE",
  },
  { cnae: "2822402", descricao: "TAPETES ROLANTES, FABRICACAO DE" },
  {
    cnae: "2822402",
    descricao:
      "ELEVADORES HIDRAULICOS (PARA POSTOS DE AUTO-SERVICOS E OUTROS FINS); FABRICACAO DE",
  },
  { cnae: "2822402", descricao: "EMPILHADEIRAS DE CARGAS; FABRICACAO DE" },
  {
    cnae: "2822402",
    descricao:
      "MAQUINAS, EQUIPAMENTOS E APARELHOS PARA TRANSPORTE E ELEVACAO DE CARGAS, INSTALACAO E MANUTENCAO DE, QUANDO EXECUTADA PELA UNIDADE FABRICANTE",
  },
  {
    cnae: "2822402",
    descricao: "PORTICOS PARA ELEVACAO E TRANSPORTE DE CARGAS; FABRICACAO DE",
  },
  {
    cnae: "2822402",
    descricao: "POSTES FIXOS PARA ELEVACAO DE CARGAS; FABRICACAO DE",
  },
  { cnae: "2822402", descricao: "TALHAS E GUINCHOS; FABRICACAO DE" },
  { cnae: "2822402", descricao: "MACACOS PARA VEICULOS; FABRICACAO DE" },
  {
    cnae: "2822402",
    descricao:
      "CACAMBAS, PAS, GANCHOS, ETC., PARA MAQUINAS DE TERRAPLENAGEM; FABRICACAO DE",
  },
  {
    cnae: "2822402",
    descricao: "VAGONETAS, BASCULANTES OU NAO; FABRICACAO DE",
  },
  { cnae: "2822402", descricao: "GUINDASTES; FABRICACAO DE" },
  {
    cnae: "2822402",
    descricao: "PARTES E PECAS PARA EMPILHADEIRAS PROPULSORAS; FABRICACAO DE",
  },
  {
    cnae: "2822402",
    descricao:
      "ELEVADORES PARA TRANSPORTE E ELEVACAO DE CARGAS, INSTALACAO E MANUTENCAO DE, QUANDO EXECUTADA PELA UNIDADE FABRICANTE",
  },
  {
    cnae: "2822402",
    descricao: "PARTES E PECAS PARA ELEVADORES; FABRICACAO DE",
  },
  {
    cnae: "2822402",
    descricao:
      "GUINDASTES, PONTES E VIGAS ROLANTES, PORTICOS, PONTES-GUINDASTES E CARROS-PORTICOS; FABRICACAO DE",
  },
  {
    cnae: "2822402",
    descricao: "ESTEIRAS PARA LINHA DE MONTAGEM; FABRICACAO DE",
  },
  { cnae: "2822402", descricao: "ELEVADORES PARA CARGAS; FABRICACAO DE" },
  {
    cnae: "2822402",
    descricao: "MAQUINAS E VEICULOS PARA MOVIMENTACAO DE CARGA; FABRICACAO DE",
  },
  { cnae: "2822402", descricao: "MACACOS HIDRAULICOS; FABRICACAO DE" },
  {
    cnae: "2822402",
    descricao:
      "PARTES E PECAS PARA VEICULOS SEM DISPOSITIVO PARA ELEVACAO, UTILIZADO EM FABRICAS; FABRICACAO DE",
  },
  {
    cnae: "2822402",
    descricao:
      "PARTES E PECAS PARA TALHAS, GUINCHOS, MACACOS, PORTICOS E GUINDASTES; FABRICACAO DE",
  },
  {
    cnae: "2822402",
    descricao: "TRANSPORTADORES MECANICOS DE CORREIA OU ESTEIRA; FABRICACAO DE",
  },
  {
    cnae: "2822402",
    descricao: "MAQUINAS PARA TRANSPORTE E ELEVACAO DE CARGA; FABRICACAO DE",
  },
  {
    cnae: "28232",
    descricao:
      "FABRICACAO DE MAQUINAS E APARELHOS DE REFRIGERACAO E VENTILACAO PARA USO INDUSTRIAL E COMERCIAL",
  },
  {
    cnae: "2823200",
    descricao: "MAQUINAS E EQUIPAMENTOS PARA REFRIGERACAO, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao: "UMIDIFICADORES E DESUMIDIFICADORES INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "CONGELADORES OU CONSERVADORES COMERCIAIS, ELETRICOS (FREEZERS); FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao: "COIFAS (EXAUSTORES) DE USO INDUSTRIAL; FABRICACAO DE",
  },
  { cnae: "2823200", descricao: "APARELHOS AEROTECNICOS, N.E.; FABRICACAO DE" },
  {
    cnae: "2823200",
    descricao: "UNIDADES FORNECEDORAS DE SUCOS E OUTRAS BEBIDAS; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao: "ARMAZENS FRIGORIFICOS PRE-FABRICADOS; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "BALCOES FRIGORIFICOS EQUIPADOS COM UNIDADES DE REFRIGERACAO; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "MAQUINAS PARA PRODUCAO DE FRIO E BOMBAS DE CALOR, N.E; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "APARELHOS E DISPOSITIVOS PARA LIQUEFACAO DO AR OU OUTROS GASES; FABRICACAO DE",
  },
  { cnae: "2823200", descricao: "GELADEIRA PARA USO COMERCIAL; FABRICACAO DE" },
  {
    cnae: "2823200",
    descricao:
      "PECAS PARA CAMARAS FRIGORIFICAS E REFRIGERADORES COMERCIAIS; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "EQUIPAMENTOS PARA REFRIGERACAO OU PARA AR CONDICIONADO, COM CAPACIDADE NAO SUPERIOR A 120.000 BTU'S; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "RESFRIADORES DE AGUA E OUTROS LIQUIDOS PARA USOS INDUSTRIAIS OU COMERCIAIS; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "MAQUINAS E APARELHOS DE REFRIGERACAO E VENTILACAO DE USO INDUSTRIAL E COMERCIAL, MONTAGEM E INSTALACAO DE, QUANDO EXECUTADA PELA UNIDADE FABRICANTE",
  },
  {
    cnae: "2823200",
    descricao:
      "MAQUINAS PARA CONSERVACAO DE SORVETES, NAO DOMESTICAS; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "PECAS E ACESSORIOS PARA EQUIPAMENTOS AEROTECNICOS (VENTILADORES, EXAUSTORES, ASPIRADORES DE AR, ETC); FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "ASPIRADORES INDUSTRIAIS EQUIPADOS OU NAO COM MOTORES ELETRICOS; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "GABINETES OU MOVEIS PARA RECEBER EQUIPAMENTO PARA PRODUCAO DE FRIO; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "EXAUSTORES INDUSTRIAIS EQUIPADOS OU NAO COM MOTORES ELETRICOS; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "GRUPOS DE COMPRESSAO, CUJO CONDENSADOR SEJA CONSTITUIDO POR UM TROCADOR DE CALOR, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  { cnae: "2823200", descricao: "BEBEDOUROS ELETRICOS; FABRICACAO DE" },
  {
    cnae: "2823200",
    descricao: "SORVETEIRAS PARA BARES, LANCHONETES, ETC; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "CAMARAS FRIGORIFICAS EQUIPADAS OU NAO COM UNIDADES DE REFRIGERACAO; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "VENTILADORES INDUSTRIAIS EQUIPADOS OU NAO COM MOTORES ELETRICOS; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "REFRIGERADORES COMERCIAIS EQUIPADOS OU NAO COM UNIDADES DE REFRIGERACAO; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "REFRIGERADORES, VITRINAS, CAMARAS FRIGORIFICAS E SEMELHANTES PARA PRODUCAO DE FRIO PARA USOS INDUSTRIAL E COMERCIAL; FABRICACAO DE",
  },
  {
    cnae: "2823200",
    descricao:
      "RECIPIENTE REFRIGERADOR, COM CIRCULACAO DE FLUIDO REFRIGERADOR; FABRICACAO DE",
  },
  {
    cnae: "28241",
    descricao: "FABRICACAO DE APARELHOS E EQUIPAMENTOS DE AR CONDICIONADO",
  },
  {
    cnae: "2824101",
    descricao:
      "PECAS E ACESSORIOS PARA EQUIPAMENTOS DE AR CONDICIONADO CENTRAL, FABRICACAO DE",
  },
  {
    cnae: "2824101",
    descricao:
      "APARELHOS DE AR CONDICIONADO PARA USO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2824101",
    descricao:
      "APARELHOS E EQUIPAMENTOS DE AR CONDICIONADO PARA USO INDUSTRIAL, INSTALACAO E MANUTENCAO DE, QUANDO EXECUTADA PELA UNIDADE FABRICANTE",
  },
  {
    cnae: "2824101",
    descricao: "EQUIPAMENTOS DE AR CONDICIONADO CENTRAL, FABRICACAO DE",
  },
  {
    cnae: "2824102",
    descricao: "APARELHOS DE AR CONDICIONADO PARA VEICULOS, FABRICACAO DE",
  },
  {
    cnae: "2824102",
    descricao:
      "APARELHOS DE AR CONDICIONADO PARA USO NAO-INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2824102",
    descricao:
      "APARELHOS DE AR CONDICIONADO PARA USO DOMESTICO, INCLUSIVE OS DO TIPO SPLIT SYSTEM; FABRICACAO DE",
  },
  {
    cnae: "2824102",
    descricao:
      "APARELHOS E EQUIPAMENTOS DE AR CONDICIONADO PARA USO NAO INDUSTRIAL, INSTALACAO E MANUTENCAO DE, QUANDO EXECUTADA PELA UNIDADE FABRICANTE",
  },
  {
    cnae: "2824102",
    descricao:
      "PECAS E ACESSORIOS PARA APARELHOS DE AR CONDICIONADO NAO INDUSTRIAL; FABRICACAO DE",
  },
  {
    cnae: "2824102",
    descricao:
      "SPLIT SYSTEM (APARELHOS DE AR CONDICIONADO PARA USO DOMESTICO), FABRICACAO DE",
  },
  {
    cnae: "28259",
    descricao:
      "FABRICACAO DE MAQUINAS E EQUIPAMENTOS PARA SANEAMENTO BASICO E AMBIENTAL",
  },
  {
    cnae: "2825900",
    descricao:
      "EQUIPAMENTOS PARA TRATAMENTO E DISPOSICAO DE RESIDUOS SOLIDOS; FABRICACAO DE",
  },
  {
    cnae: "2825900",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA SANEAMENTO BASICO E AMBIENTAL, FABRICACAO DE",
  },
  {
    cnae: "2825900",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS E EQUIPAMENTOS PARA SANEAMENTO BASICO E AMBIENTAL; FABRICACAO DE",
  },
  {
    cnae: "2825900",
    descricao:
      "MAQUINAS E APARELHOS PARA DESOBSTRUCAO DE TUBULACOES; FABRICACAO DE",
  },
  {
    cnae: "2825900",
    descricao:
      "EQUIPAMENTOS PARA TRATAMENTO DE AGUA E ESGOTOS SANITARIOS; FABRICACAO DE",
  },
  {
    cnae: "2825900",
    descricao:
      "EQUIPAMENTOS PARA TRATAMENTO DE EFLUENTES INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2825900",
    descricao: "EQUIPAMENTOS PARA TRATAMENTO DO AR; FABRICACAO DE",
  },
  {
    cnae: "28291",
    descricao:
      "FABRICACAO DE MAQUINAS E EQUIPAMENTOS DE USO GERAL NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "2829101",
    descricao: "MAQUINAS REGISTRADORAS NAO-ELETRONICAS; FABRICACAO DE",
  },
  { cnae: "2829101", descricao: "GRAMPEADORES PARA PAPEL, FABRICACAO DE" },
  {
    cnae: "2829101",
    descricao: "MAQUINAS REGISTRADORAS ELETRONICAS; FABRICACAO DE",
  },
  {
    cnae: "2829101",
    descricao:
      "MAQUINAS, APARELHOS E EQUIPAMENTOS NAO-ELETRONICOS PARA ESCRITORIO; FABRICACAO DE",
  },
  {
    cnae: "2829101",
    descricao:
      "MAQUINAS PARA COPIAS ELETROSTATICAS, HELIOGRAFICAS, SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2829101",
    descricao:
      "APONTADORES MECANICOS PARA LAPIS, PERFURADORES, GRAMPEADORES OU SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "2829101", descricao: "CALCULADORA DE BOLSO; FABRICACAO DE" },
  { cnae: "2829101", descricao: "MAQUINAS PARA APONTAR LAPIS, FABRICACAO DE" },
  {
    cnae: "2829101",
    descricao: "MAQUINA DE CALCULAR ELETRONICA; FABRICACAO DE",
  },
  {
    cnae: "2829101",
    descricao:
      "MAQUINAS DE SOMAR E CALCULAR ELETRICAS OU ELETRONICAS (EXCETO COMPUTADORES); FABRICACAO DE",
  },
  {
    cnae: "2829101",
    descricao:
      "PARTES E PECAS PARA MAQUINAS E APARELHOS PARA ESCRITORIO, NAO ESPECIFICADOS, EXCETO PARA COMPUTADORES; FABRICACAO DE",
  },
  {
    cnae: "2829101",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS E EQUIPAMENTOS, NAO-ELETRONICOS, PARA ESCRITORIO; FABRICACAO DE",
  },
  {
    cnae: "2829101",
    descricao:
      "MAQUINAS PARA IMPRESSAO POR OFSETE (OFF-SET), ALIMENTADOS POR FOLHAS DE FORMATO NAO SUPERIOR A 22 X 36 CM; FABRICACAO DE",
  },
  {
    cnae: "2829101",
    descricao: "MAQUINAS PARA COPIAS FOTOSTATICAS; FABRICACAO DE",
  },
  {
    cnae: "2829101",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS DE CALCULAR ELETRONICAS; FABRICACAO DE",
  },
  {
    cnae: "2829101",
    descricao:
      "APARELHOS DE FOTOCOPIA, POR SISTEMA OPTICO OU POR CONTATO, E APARELHOS DE TERMOCOPIA; FABRICACAO DE",
  },
  {
    cnae: "2829101",
    descricao:
      "MAQUINAS DE ESCREVER, MECANICAS, ELETRICAS OU ELETRONICAS; FABRICACAO DE",
  },
  { cnae: "2829101", descricao: "MIMEOGRAFOS E DUPLICADORES; FABRICACAO DE" },
  {
    cnae: "2829101",
    descricao: "PECAS PARA MAQUINAS DE CONTABILIDADE; FABRICACAO DE",
  },
  {
    cnae: "2829101",
    descricao: "DUPLICADORES HECTOGRAFICOS OU A ESTENCIL; FABRICACAO DE",
  },
  {
    cnae: "2829101",
    descricao:
      "MAQUINAS PARA FRANQUIAR CORRESPONDENCIA, EMITIR BILHETES E SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "2829101", descricao: "AGENDA ELETRONICA; FABRICACAO DE" },
  {
    cnae: "2829101",
    descricao: "PECAS PARA MAQUINAS DE ESCREVER; FABRICACAO DE",
  },
  { cnae: "2829101", descricao: "FURADORES DE PAPEL; FABRICACAO DE" },
  { cnae: "2829101", descricao: "MAQUINAS DE CONTABILIDADE; FABRICACAO DE" },
  {
    cnae: "2829101",
    descricao: "PECAS PARA MAQUINAS DE SOMAR E CALCULAR; FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "ENSACADEIRA AUTOMATICA; FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao: "PARTES, PECAS E ACESSORIOS PARA HEMODIALISADOR; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "FILTROS ELETROSTATICOS E OUTROS APARELHOS PARA FILTRARO DEPURAR GASES; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "PARTES E PECAS PARA GERADORES DE GAS DE AR, GAS, AGUA OU ACETILENO; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "UNIDADES DE RETIFICACAO E DESTILACAO PARA INDUSTRIAS QUIMICAS; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "SPRINKLERS (EQUIPAMENTOS PARA SISTEMA CONTRA INCENDIO); FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "COMPACTADORES DE LIXO; FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao: "MAQUINAS AUTOMATICAS PARA VENDA DE PRODUTOS; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "PARTES E PECAS DE CALANDRAS E LAMINADORES, EXCETO LAMINADORAS DE METAIS; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "MAQUINAS E APARELHOS PARA LIMPEZA INDUSTRIAL, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "TERMINAIS COMERCIAIS DE AUTOATENDIMENTO; MAQUINAS DE DISTRIBUIR OU DE TROCAR DINHEIRO; FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "EXTINTORES DE INCENDIO; FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao: "PISTOLAS AEROGRAFICAS E APARELHOS SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "EQUIPAMENTOS PARA POSTOS DE GASOLINA; FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "CALANDRAS PARA COURO; FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao:
      "MAQUINAS AUTOMATICAS PARA LAVAR E LUBRIFICAR CARROS; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "MAQUINAS PARA LAVAR CARROCERIAS DE VEICULOS (LAVA-JATO); FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "CENTRIFUGADORES, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "MAQUINAS PARA MOER CAFE, ELETRICAS, PARA USO COMERCIAL; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "PARTES E PECAS DE APARELHOS PARA FILTRAR OU DEPURAR GASES OU LIQUIDOS; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "BALCOES, VITRINES, ESTUFAS E EQUIPAMENTOS SEMELHANTES ELETRICOS, PARA BARES, LANCHONETES E ETC",
  },
  {
    cnae: "2829199",
    descricao:
      "UNIDADES DE RETIFICACAO E DESTILACAO PARA INDUSTRIAS DE BEBIDAS; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "MAQUINAS PARA FATIAR, ELETRICAS OU NAO; FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "MAQUINA PARA ALGODAO DOCE; FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao:
      "MAQUINAS DE LAVAR LOUCAS, EXCETO PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "UNIDADES DE RETIFICACAO E DESTILACAO PARA REFINARIAS DE PETROLEO; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "BALANCAS OU BASCULAS AUTOMATICAS OU NAO, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "MAQUINAS DE LIMPEZA OU POLIMENTO POR JATO DE AGUA, AREIA, ESFERAS DE VIDRO GRANALHA DE ACO; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "TROCADORES (PERMUTADORES) DE CALOR, FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "CALANDRAS PARA FINS TEXTEIS; FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao: "PERMUTADORES (TROCADORES) DE CALOR, FABRICACAO DE",
  },
  {
    cnae: "2833000",
    descricao: "DISTRIBUIDORES DE FERTILIZANTES; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "CENTRIFUGADOR PARA LABORATORIO DE ANALISE. ENSAIO OU PESQUISA CIENTIFICA; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "PARTES E PECAS PARA OUTRAS MAQUINAS OU APARELHOS SEM CONEXAO ELETRICA; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "PARTES E PECAS PARA MAQUINAS E APARELHOS DE IMPRESSAO, PARA FOTOCOPIA OU TERMOCOPIA, PARA ESCRITORIO; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "PARTES E PECAS PARA MAQUINAS AUTOMATICAS PARA VENDA DE PRODUTOS; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "PARTES E PECAS PARA MAQUINAS PARA ENCHER, FECHAR, EMBALAR; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "PECAS E ACESSORIOS PARA BALANCAS OU BASCULAS; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "ESPREMEDORES DE FRUTAS PARA BARES E LANCHONETES; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "CHURRASQUEIRAS PARA USO COMERCIAL; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "PECAS E ACESSORIOS PARA EXTINTORES DE INCENDIO; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "FRITADEIRAS, REFRESQUEIRAS, SANDUICHEIRAS, CAFETEIRAS E SEMELHANTES PARA BARES E LANCHONETES; FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "CALANDRAS PARA PLASTICO; FABRICACAO DE" },
  { cnae: "2829199", descricao: "BALANCAS DE USO DOMESTICO, FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao:
      "TAMBORES ROTATIVOS COM PRATOS OU DISCOS SEPARADORES, PESO SUPERIOR A 300KG; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "FILTROS INDUSTRIAIS (EXCETO VELAS FILTRANTES); FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "ARTIGOS E EQUIPAMENTOS PARA SAUNAS; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "ASSADEIRA DE USO COMERCIAL PARA FRANGO (FRANGUEIRA); FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "CALANDRAS PARA A INDUSTRIA GRAFICA; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "CORTADORES DE FRIOS PARA BARES, LANCHONETES OU OUTROS USOS COMERCIAIS; FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "PLATAFORMAS DE PESAGEM; FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao: "FOGOES PARA COZINHAS INDUSTRIAIS; FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "CABINES PARA PINTURA; FABRICACAO DE" },
  { cnae: "2829199", descricao: "TORREFADORES; FABRICACAO DE" },
  { cnae: "2829199", descricao: "FILTROS PARA PISCINAS; FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao:
      "MAQUINAS E APARELHOS PARA LIMPAR OU SECAR GARRAFAS OU OUTROS RECIPIENTES; FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "BALANCAS COMERCIAIS; FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao:
      "PARTES E PECAS PARA MAQUINAS DE LAVAR LOUCAS, EXCETO PARA USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "APARELHOS PARA PROJETAR, ESPALHAR,PULVERIZAR, EXCETO PARA AGRICULTURA; FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "JUNTAS METALOPLASTICAS; FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao: "MAQUINAS PARA ENVASAR (ENVASADORAS), FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "CARROSSEL, FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao:
      "PARTES E PECAS PARA MAQUINAS PARA LIMPAR OU SECAR GARRAFAS E OUTROS RECIPIENTES; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "MAQUINAS PARA BARES, LANCHONETES E SEMELHANTES, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "COLUNAS DE DESTILACAO / RETIFICACAO - EXCETO ALAMBIQUES; FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "DESENTUPIDORAS; FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao:
      "PARTES E PECAS PARA FOGOES, FORNOS E OUTROS EQUIPAMENTOS SEMELHANTES PARA RESTAURANTES, LANCHONETES, ETC.; FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "CAMARAS DE BRONZEAMENTO; FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao: "APARELHOS PARA PREPARACAO DE BEBIDAS QUENTES; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "PARTES E PECAS PARA ESTERELIZADORES E OUTROS APARELHOS PARA TRATAMENTO DE ALIMENTOS E BEBIDAS, MEDIANTE TROCA DE TEMPERATURA; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "PARTES E PECAS PARA TROCADORES DE CALOR; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "MOENDAS DE CANA DE ACUCAR PARA USO COMERCIAL; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "APARELHOS E INSTRUMENTOS DE PESAGEM; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "ESTERILIZADORES, EXCETO MEDICO-CIRURGICOS OU DE LABORATORIO; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "APARELHOS INDUSTRIAIS PARA FILTRAR OU DEPURAR LIQUIDOS; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "APARELHOS INDUSTRIAIS PARA FILTRAR E DEPURAR GASES; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "MAQUINAS E APARELHOS PARA EMBALAR, ENSACAR E / OU ETIQUETAR; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "LAVADORAS DE CARPETES E ESTOFADOS; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "MAQUINAS E APARELHOS PARA ENCHER, FECHAR, ENCAPSULAR OU ROTULAR; FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "PISTOLAS PARA PINTURA; FABRICACAO DE" },
  { cnae: "2829199", descricao: "HEMODIALISADOR (USO MEDICO); FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao: "FILTROS DE AR PARA MOTORES A EXPLOSAO OU DIESEL; FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "CALANDRAS, N.E.; FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao:
      "CARROSSEIS, BALANCOS E OUTROS BRINQUEDOS OU EQUIPAMENTOS PARA FEIRAS E PARQUES DE DIVERSOES; FABRICACAO DE",
  },
  { cnae: "2829199", descricao: "CALANDRAS PARA LAVANDERIAS, FABRICACAO DE" },
  {
    cnae: "2829199",
    descricao:
      "GERADORES DE GAS DE AR OU DE GAS DE AGUA, GERADORES DE ACETILENO E GERADORES SEMELHANTES DE GAS; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "MAQUINAS PARA APLICACAO DE ROTULOS; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao: "MAQUINAS PARA APLICACAO DE CODIGO EM EMBALAGENS; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS E EQUIPAMENTOS DE USO GERAL; FABRICACAO DE",
  },
  {
    cnae: "2829199",
    descricao:
      "MAQUINAS E APARELHOS PARA DESOBSTRUCAO DE TUBULACAO; FABRICACAO DE",
  },
  { cnae: "28313", descricao: "FABRICACAO DE TRATORES AGRICOLAS" },
  {
    cnae: "2831300",
    descricao: "PECAS PARA TRATORES AGRICOLAS; FABRICACAO DE",
  },
  {
    cnae: "2831300",
    descricao: "LAGARTAS PARA TRATORES AGRICOLAS; FABRICACAO DE",
  },
  {
    cnae: "2831300",
    descricao: "LAMINAS PARA TRATORES AGRICOLAS; FABRICACAO DE",
  },
  { cnae: "2831300", descricao: "TRATORES AGRICOLAS; FABRICACAO DE" },
  {
    cnae: "28321",
    descricao: "FABRICACAO DE EQUIPAMENTOS PARA IRRIGACAO AGRICOLA",
  },
  { cnae: "2832100", descricao: "BARRA IRRIGADORA; FABRICACAO DE" },
  {
    cnae: "2832100",
    descricao: "EQUIPAMENTOS PARA IRRIGACAO AGRICOLA, FABRICACAO DE",
  },
  {
    cnae: "2832100",
    descricao: "ASPERSORES PARA IRRIGACAO AGRICOLA; FABRICACAO DE",
  },
  {
    cnae: "2832100",
    descricao: "FILTROS PARA IRRIGACAO AGRICOLA; FABRICACAO DE",
  },
  {
    cnae: "28330",
    descricao:
      "FABRICACAO DE MAQUINAS E EQUIPAMENTOS PARA A AGRICULTURA E PECUARIA, EXCETO PARA IRRIGACAO",
  },
  {
    cnae: "2833000",
    descricao:
      "MAQUINAS E APARELHOS DE TRACAO ANIMAL PARA USO NA AGRICULTURA; FABRICACAO DE",
  },
  {
    cnae: "2833000",
    descricao:
      "CRIADEIRAS PARA AVES, SUINOS (PORCOS) E OUTROS PEQUENOS ANIMAIS (CELAS, BAIAS, ETC); FABRICACAO DE",
  },
  { cnae: "2833000", descricao: "GRADES DE DENTE; FABRICACAO DE" },
  {
    cnae: "2833000",
    descricao: "PLANTADORES E TRANSPLANTADORES; FABRICACAO DE",
  },
  { cnae: "2833000", descricao: "INCUBADORAS AVICOLAS; FABRICACAO DE" },
  { cnae: "2833000", descricao: "SEMEADEIRAS; FABRICACAO DE" },
  {
    cnae: "2833000",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA A CRIACAO DE PEQUENOS ANIMAIS; FABRICACAO DE",
  },
  {
    cnae: "2833000",
    descricao:
      "MAQUINAS, APARELHOS E IMPLEMENTOS PARA AGRICULTURA, N.E.; FABRICACAO DE",
  },
  { cnae: "4223500", descricao: "OLEODUTOS, CONSTRUCAO DE" },
  {
    cnae: "2833000",
    descricao:
      "MAQUINAS PARA LIMPAR E SELECIONAR PRODUTOS AGRICOLAS; FABRICACAO DE",
  },
  {
    cnae: "2833000",
    descricao:
      "PARTES E PECAS PARA MAQUINAS PARA COLHEITA, DEBULHA, ETC.; FABRICACAO DE",
  },
  { cnae: "2833000", descricao: "CULTIVADORES AGRICOLAS; FABRICACAO DE" },
  {
    cnae: "2833000",
    descricao: "INCUBADORA DE OVOS (CHOCADEIRAS); FABRICACAO DE",
  },
  {
    cnae: "2833000",
    descricao:
      "REBOQUES E SEMIRREBOQUES AUTOCARREGAVEIS, ETC., PARA USO AGRICOLA; FABRICACAO DE",
  },
  {
    cnae: "2833000",
    descricao: "MAQUINAS AGRICOLAS (EXCETO TRATORES); FABRICACAO DE",
  },
  { cnae: "2833000", descricao: "TOSQUIADORES DE LA; FABRICACAO DE" },
  { cnae: "2833000", descricao: "COLHEITADEIRAS; FABRICACAO DE" },
  {
    cnae: "2833000",
    descricao: "CORTADORES DE GRAMA NAO-DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2833000",
    descricao:
      "MAQUINAS PARA BENEFICIAMENTO DE PRODUTOS AGRICOLAS; FABRICACAO DE",
  },
  {
    cnae: "2833000",
    descricao: "DESCAROCADEIRAS DE PRODUTOS AGRICOLAS; FABRICACAO DE",
  },
  { cnae: "2833000", descricao: "GRADES DE DISCO; FABRICACAO DE" },
  { cnae: "2833000", descricao: "ADUBADORAS; FABRICACAO DE" },
  {
    cnae: "2833000",
    descricao: "COMEDOUROS E BEBEDOUROS PARA ANIMAIS; FABRICACAO DE",
  },
  {
    cnae: "2833000",
    descricao: "MAQUINAS E APARELHOS PARA APICULTURA; FABRICACAO DE",
  },
  { cnae: "2833000", descricao: "ARADOS; FABRICACAO DE" },
  { cnae: "2833000", descricao: "GERMINADORES; FABRICACAO DE" },
  { cnae: "2833000", descricao: "COLHEDEIRAS AGRICOLAS; FABRICACAO DE" },
  { cnae: "2833000", descricao: "PULVERIZADORES; FABRICACAO DE" },
  {
    cnae: "2833000",
    descricao: "TRILHADEIRAS AGRICOLAS COMBINADAS OU NAO; FABRICACAO DE",
  },
  { cnae: "2833000", descricao: "PLANTADEIRAS; FABRICACAO DE" },
  { cnae: "2833000", descricao: "DEBULHADORES; FABRICACAO DE" },
  {
    cnae: "2833000",
    descricao: "CARROCERIAS E CARRETAS AGRICOLAS; FABRICACAO DE",
  },
  {
    cnae: "2833000",
    descricao: "MAQUINAS E APARELHOS PARA ORDENHAR; FABRICACAO DE",
  },
  { cnae: "9312300", descricao: "CLUBE DE BRIDGE" },
  {
    cnae: "2833000",
    descricao: "MAQUINAS ELETRONICAS PARA SELECAO DE CEREAIS; FABRICACAO DE",
  },
  {
    cnae: "2833000",
    descricao:
      "PARTES E PECAS PARA MAQUINAS E APARELHOS PARA AGRICULTURA, HORTICULTURA, SILVICULTURA, AVICULTURA OU APICULTURA; FABRICACAO DE",
  },
  { cnae: "2833000", descricao: "POLVILHADEIRAS; FABRICACAO DE" },
  {
    cnae: "2833000",
    descricao:
      "PARTES E PECAS DE APARELHOS PARA PROJETAR OU PULVERIZAR PARA USO AGRICOLA; FABRICACAO DE",
  },
  {
    cnae: "2833000",
    descricao:
      "PARTES E PECAS PARA MAQUINAS PARA LIMPEZA, SELECAO, ETC., DE GRAOS; FABRICACAO DE",
  },
  {
    cnae: "2833000",
    descricao:
      "MAQUINAS E APARELHOS PARA COLHEITA E DEBULHA DE PRODUTOS AGRICOLAS; FABRICACAO DE",
  },
  { cnae: "2833000", descricao: "DISCOS PARA ARADOS E GRADES; FABRICACAO DE" },
  {
    cnae: "2833000",
    descricao: "MISTURADORES E DESINTEGRADORES PARA RACOES; FABRICACAO DE",
  },
  { cnae: "2833000", descricao: "CEIFADEIRAS AGRICOLAS; FABRICACAO DE" },
  { cnae: "2833000", descricao: "ENFARDADEIRAS; FABRICACAO DE" },
  { cnae: "2833000", descricao: "IMPLEMENTOS AGRICOLAS; FABRICACAO DE" },
  { cnae: "2833000", descricao: "CLASSIFICADORES PARA OVOS; FABRICACAO DE" },
  {
    cnae: "2833000",
    descricao:
      "MAQUINAS AGRICOLAS PARA O PREPARO DO SOLO, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2833000",
    descricao:
      "PECAS PARA MAQUINAS, APARELHOS E IMPLEMENTOS AGRICOLAS; FABRICACAO DE",
  },
  {
    cnae: "2833000",
    descricao:
      "MAQUINAS OU APARELHOS PARA AGRICULTURA E PECUARIA, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2833000",
    descricao: "MAQUINAS E APARELHOS PARA AVICULTURA; FABRICACAO DE",
  },
  { cnae: "2833000", descricao: "ANCINHOS MECANICOS; FABRICACAO DE" },
  { cnae: "2833000", descricao: "AIVECA PARA ARADOS; FABRICACAO DE" },
  { cnae: "28402", descricao: "FABRICACAO DE MAQUINASFERRAMENTA" },
  { cnae: "2840200", descricao: "MOTOSSERRAS; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao:
      "FURADEIRAS, LIXADEIRAS, SERRAS, POLITRIZES E OUTRAS FERRAMENTAS MANUAIS ELETRICAS OU A AR COMPRIMIDO; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "SERRA (CIRCULAR, COMBINADA, DE DESDOBRO, DE FOLHAS MULTIPLAS, DE PENDULO, RADIAL, ETC) PARA INDUSTRIA DA MADEIRA; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "PLAINAS COMBINADAS PARA TRABALHAR MADEIRA; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "MANDRILHADEIRA (HORIZONTAL, VERTICAL, MULTIPLA); FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "FURADEIRA DE COLUNA; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao: "RETIFICADEIRA (HORIZONTAL, VERTICAL, ETC); FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "MAQUINAS PARA APLAINAR, PLAINAS-LIMADORAS E MAQUINAS PARA ESCATELAR, INCLUSIVE DE COMANDO NUMERICO; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "MAQUINAS-FERRAMENTA A ULTRASSOM; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "MAQUINAS-FERRAMENTA DE COMANDO NUMERICO OU QUE INTEGRE VARIAS FASES DO TRABALHO; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "MAQUINAS DE SISTEMA MONOSTATICO; MAQUINAS DE ESTACOES MULTIPLAS PARA TRABALHAR METAIS; FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "CENTROS DE USINAGEM; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao: "PECAS E ACESSORIOS PARA FURADEIRAS INDUSTRIAS; FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "TORNO VERTICAL; FABRICACAO DE" },
  { cnae: "2840200", descricao: "TORNO TIPICAMENTE AUTOMATICO; FABRICACAO DE" },
  { cnae: "2840200", descricao: "MAQUINAS PARA SOLDAR A LASER; FABRICACAO DE" },
  { cnae: "2840200", descricao: "TORNO DE REPUXAR; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao: "SERRAS PARA METAIS, N.E., (MAQUINAS); FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "TORNO PARALELO UNIVERSAL; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao: "PARTES E PECAS PARA MAQUINAS PARA SOLDAR; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "TORNOS PARA METAIS, INCLUSIVE DE COMANDO NUMERICO; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "TUPIAS PARA TRABALHAR MADEIRA; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "SERRAS (DE FITA, DE LAMINA, ETC) PARA TRABALHAR METAIS; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "MAQUINAS-FERRAMENTA PARA APLAINAR, CORTAR OU ACABAR ENGRENAGENS E OUTRAS QUE TRABALHEM POR ELIMINACAO DE METAL; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "MAQUINAS-FERRAMENTA, INCLUIDAS AS PRENSAS, PARA FORJAR OU ESTAMPAR, INCLUSIVE DE COMANDO NUMERICO; FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "ACABADORA DE ENGRENAGENS; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao:
      "MAQUINAS-FERRAMENTA PARA FURAR, ESCAREAR, FRESAR OU ROSCAR, INCLUSIVE DE COMANDO NUMERICO; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "PECAS E ACESSORIOS PARA FERRAMENTAS ELETRICAS; FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "DESBASTADORA PARA METAIS; FABRICACAO DE" },
  { cnae: "2840200", descricao: "LAMINADORES PARA MADEIRA; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao:
      "MAQUINAS-FERRAMENTA PARA REBARBAR, AFIAR, AMOLAR, RETIFICAR, BRUNIR, POLIR OU OUTROS ACABAMENTO, INCLUSIVE DE COMANDO NUMERICO; FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "DESEMPENADEIRA DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao: "PLAINAS PARA TRABALHAR METAIS; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "ACESSORIOS PARA MAQUINAS-FERRAMENTA (MANDRIS, PORTA-FERRAMENTAS, ETC); FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "MAQUINAS-FERRAMENTA PARA MOLDAR BAQUELITE A QUENTE; FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "FURADEIRA DE BANCADA; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao: "PRENSAS INDUSTRIAIS PARA MADEIRA OU METAL; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "DOBRADEIRAS E VIRADEIRAS PARA METAIS; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "MAQUINAS ELETRICA PARA SOLDA, INCLUSIVE ROBOS; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "ESMERILHADEIRAS (FERRAMENTAS ELETRICAS MANUAIS); FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "TORNOS, INCLUSIVE DE COMANDO NUMERICO; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "FURADEIRAS (FERRAMENTAS ELETRICAS MANUAIS); FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "MANDRILHADEIRA DE COORDENADAS; FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "FERROS E PISTOLAS PARA SOLDA; FABRICACAO DE" },
  { cnae: "2840200", descricao: "TESOUROES PARA CORTAR METAIS; FABRICACAO DE" },
  { cnae: "2840200", descricao: "POLITRIZES E MOTO-ESMERIS; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao: "MANDRILADORAS-FRESADORAS DE METAIS; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "PECAS E ACESSORIOS PARA TORNOS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "PARTES E PECAS PARA SERRAS DE CORRENTE, DE USO MANUAL; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "TALHADEIRAS (MAQUINAS PARA MADEIRA); FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "TORNO COPIADOR; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao: "MAQUINAS-FERRAMENTA PARA SERRARIAS; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "MAQUINAS-FERRAMENTA A ELETROEROSAO; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "PORTA-FERRAMENTAS OU PORTA-PINCAS; FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "PRENSAS HIDRAULICAS; FABRICACAO DE" },
  { cnae: "2840200", descricao: "FURADEIRA PARA METAIS, N.E.; FABRICACAO DE" },
  { cnae: "2840200", descricao: "FURADEIRA RADIAL; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao: "MAQUINAS-FERRAMENTA PARA INDUSTRIA DA BORRACHA; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "PRENSAS PARA EXTRUSAO; PRENSAS PARA MOLDAGEM DE PO METALICO POR SINTERIZACAO; FABRICACAO DE",
  },
  {
    cnae: "2854200",
    descricao:
      "ESCAVADEIRAS OU ESCAVADORAS(MAQUINAS PARA TERRAPLENAGEM, MINERACAO,CONSTRUCAO, ETC); FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "MAQUINAS-FERRAMENTAS OPERANDO POR PROCESSOS ELETROQUIMICOS, POR FEIXES DE ELETRONS, IONICOS OU POR JATO DE PLASMA; MAQUINAS DE CORTE A JATO DE AGUA; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "DESENGROSSADEIRA PARA MADEIRA; FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "AFIADEIRA DE SERRAS; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao: "FURADEIRA PARA A INDUSTRIA DA MADEIRA; FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "MAQUINAS PARA SOLDA A PONTO; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao:
      "MAQUINAS PARA MANDRILAR, INCLUSIVE DE COMANDO NUMERICO; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "FERRAMENTAS ELETRICAS MANUAIS; FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "MAQUINAS-FERRAMENTA A LASER; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao:
      "MAQUINAS-FERRAMENTA PARA TRABALHAR QUAISQUER MATERIAIS POR DESBASTE, INCLUSIVE DE COMANDO NUMERICO; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "FRESADORAS (HORIZONTAIS, VERTICAIS, UNIVERSAIS); FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "PECAS PARA MAQUINAS-FERRAMENTA; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "DISPOSITIVOS DIVISORES PARA MAQUINAS-FERRAMENTA; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "MAQUINAS PARA SERRAR OU SECCIONAR, INCLUSIVE DE COMANDO NUMERICO; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "PARTES E PECAS DE FERRAMENTAS HIDRAULICAS DE MOTOR NAO ELETRICO, DE USO MANUAL; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "FERRAMENTAS HIDRAULICAS DE MOTOR NAO ELETRICO, DE USO MANUAL (SERRAS DE CORRENTE, MAQUINAS PNEUMATICAS); FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "TORNO FRONTAL OU DE PLATO; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao: "MAQUINAS-FERRAMENTA NAO-ELETRICAS; FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "ESMERILHADORA PARA METAIS; FABRICACAO DE" },
  { cnae: "2840200", descricao: "ROSQUEADEIRAS; FABRICACAO DE" },
  { cnae: "2840200", descricao: "TORNO-REVOLVER; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao: "LIXADEIRAS (FERRAMENTAS ELETRICAS MANUAIS); FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "LIXADEIRAS PARA MADEIRA, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao: "PECAS E ACESSORIOS PARA PRENSAS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2840200",
    descricao:
      "MAQUINAS PARA SOLDA ELETRICA OU NAO, COM OU SEM CAPACIDADE PARA CORTAR METAIS; FABRICACAO DE",
  },
  { cnae: "2840200", descricao: "FURADEIRA MULTIPLA; FABRICACAO DE" },
  {
    cnae: "2840200",
    descricao: "PECAS E ACESSORIOS PARA SERRAS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "28518",
    descricao:
      "FABRICACAO DE MAQUINAS E EQUIPAMENTOS PARA A PROSPECCAO E EXTRACAO DE PETROLEO",
  },
  {
    cnae: "2851800",
    descricao:
      "PECAS PARA MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DE PROSPECCAO E EXTRACAO DE PETROLEO; FABRICACAO DE",
  },
  {
    cnae: "2851800",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA PERFURACAO E SONDAGEM DE POCOS DE PETROLEO); INSTALACAO DE, QUANDO EXECUTADA PELA UNIDADE FABRICANTE",
  },
  {
    cnae: "2851800",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA PERFURACAO E SONDAGEM DE POCOS DE PETROLEO); MANUTENCAO DE, QUANDO EXECUTADA PELA UNIDADE FABRICANTE",
  },
  {
    cnae: "2851800",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA PERFURACAO E SONDAGEM DE POCOS DE PETROLEO; FABRICACAO DE",
  },
  {
    cnae: "2851800",
    descricao:
      "PERFURATRIZES PARA PROSPECCAO E EXTRACAO DE PETROLEO; FABRICACAO DE",
  },
  {
    cnae: "2851800",
    descricao: "BROCAS PARA PERFURACAO - ARVORES DE NATAL; FABRICACAO DE",
  },
  {
    cnae: "2851800",
    descricao: "BROCAS ROTATIVAS PARA POCOS DE PETROLEO; FABRICACAO DE",
  },
  {
    cnae: "2851800",
    descricao: "SONDAS PARA PROSPECCAO E EXTRACAO DE PETROLEO; FABRICACAO DE",
  },
  {
    cnae: "28526",
    descricao:
      "FABRICACAO DE OUTRAS MAQUINAS E EQUIPAMENTOS PARA USO NA EXTRACAO MINERAL, EXCETO NA EXTRACAO DE PETROLEO",
  },
  {
    cnae: "2852600",
    descricao: "MAQUINAS E EQUIPAMENTOS PARA MINERACAO; FABRICACAO DE",
  },
  {
    cnae: "2852600",
    descricao:
      "PENEIRAS ROTATIVAS, VIBRATORIAS E SEMELHANTES PARA SELECAO DE PEDRAS; FABRICACAO DE",
  },
  {
    cnae: "2852600",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA MINERACAO, MARMORARIAS E SEMELHANTES; MONTAGEM E INSTALACAO DE, QUANDO EXECUTADA PELA UNIDADE FABRICANTE",
  },
  {
    cnae: "2852600",
    descricao:
      "MAQUINAS PARA AGLOMERAR OU MOLDAR COMBUSTIVEIS MINERAIS SOLIDOS, ETC.; FABRICACAO DE",
  },
  {
    cnae: "2852600",
    descricao: "MAQUINAS PARA LAVAR E TRATAR PEDRAS; FABRICACAO DE",
  },
  {
    cnae: "2852600",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA SELECIONAR, PENEIRAR, LAVAR, ETC., SUBSTANCIAS MINERAIS SOLIDAS; FABRICACAO DE",
  },
  { cnae: "2852600", descricao: "BRITADORES DE MANDIBULA; FABRICACAO DE" },
  {
    cnae: "2852600",
    descricao: "CORPOS MOEDORES PARA INDUSTRIA DA MINERACAO; FABRICACAO DE",
  },
  {
    cnae: "2852600",
    descricao: "CORTADORES DE CARVAO OU ROCHA, AUTOPROPULSORES; FABRICACAO DE",
  },
  {
    cnae: "2852600",
    descricao:
      "ELEVADORES E TRANSPORTADORES DE ACAO CONTINUA PARA TRABALHOS SUBTERRANEOS; FABRICACAO DE",
  },
  {
    cnae: "2852600",
    descricao: "MAQUINAS E EQUIPAMENTOS PARA MARMORARIAS; FABRICACAO DE",
  },
  {
    cnae: "2852600",
    descricao:
      "PECAS PARA MAQUINAS E EQUIPAMENTOS PARA A EXTRACAO E BENEFICIAMENTO DE MINERIOS; FABRICACAO DE",
  },
  {
    cnae: "2852600",
    descricao:
      "MAQUINAS PARA EXTRACAO E BENEFICIAMENTO DE MINERIOS, N.E.; FABRICACAO DE",
  },
  { cnae: "28534", descricao: "FABRICACAO DE TRATORES, EXCETO AGRICOLAS" },
  {
    cnae: "2853400",
    descricao:
      "TRATORES DE RODAS OU ESTEIRAS PARA USO NA MINERACAO; FABRICACAO DE",
  },
  {
    cnae: "2853400",
    descricao: "PECAS PARA TRATORES DE ESTEIRA; FABRICACAO DE",
  },
  {
    cnae: "2853400",
    descricao:
      "LAMINAS PARA TRATORES DE ESTEIRA E PARA TRATORES DE USO NA CONSTRUCAO E MINERACAO; FABRICACAO DE",
  },
  { cnae: "2853400", descricao: "TRATORES - EXCETO AGRICOLAS; FABRICACAO DE" },
  {
    cnae: "2853400",
    descricao: "TRATORES PARA TERRAPLENAGEM E PAVIMENTACAO; FABRICACAO DE",
  },
  {
    cnae: "2853400",
    descricao:
      "LAGARTAS PARA TRATORES (EXCETO TRATORES AGRICOLAS); FABRICACAO DE",
  },
  {
    cnae: "2853400",
    descricao:
      "PECAS PARA TRATORES DE USO NA CONSTRUCAO E MINERACAO; FABRICACAO DE",
  },
  {
    cnae: "2853400",
    descricao:
      "TRATORES DE RODAS OU ESTEIRAS DE USO NA CONSTRUCAO; FABRICACAO DE",
  },
  {
    cnae: "28542",
    descricao:
      "FABRICACAO DE MAQUINAS E EQUIPAMENTOS PARA TERRAPLENAGEM, PAVIMENTACAO E CONSTRUCAO, EXCETO TRATORES",
  },
  {
    cnae: "2854200",
    descricao:
      "PAS MECANICAS, ESCAVADORES, CARREGADORAS E PAS-CARREGADORAS; FABRICACAO DE",
  },
  {
    cnae: "2854200",
    descricao: "LAMINAS PARA MAQUINAS DE TERRAPLENAGEM; FABRICACAO DE",
  },
  {
    cnae: "2854200",
    descricao: "PERFURADORAS (PARA TERRAPLENAGEM); FABRICACAO DE",
  },
  { cnae: "2854200", descricao: "NIVELADORES; FABRICACAO DE" },
  {
    cnae: "2854200",
    descricao: "RASPO-TRANSPORTADOR (SCRAPER); FABRICACAO DE",
  },
  {
    cnae: "2854200",
    descricao: "MISTURADORES E ESPALHADORES DE ASFALTO; FABRICACAO DE",
  },
  { cnae: "2854200", descricao: "BETONEIRAS; FABRICACAO DE" },
  {
    cnae: "2854200",
    descricao:
      "MAQUINAS COMPACTADORAS - INCLUSIVE ROLOS COMPRESSORES; FABRICACAO DE",
  },
  {
    cnae: "2854200",
    descricao:
      "MAQUINAS E APARELHOS PARA OBRAS PUBLICAS E CONSTRUCAO CIVIL; FABRICACAO DE",
  },
  { cnae: "2854200", descricao: "CAMINHOES FORA-DE-ESTRADA; FABRICACAO DE" },
  {
    cnae: "2854200",
    descricao: "MAQUINAS PARA MISTURAR MATERIAIS COM BETUME, FABRICACAO DE",
  },
  { cnae: "2854200", descricao: "RETROESCAVADEIRA; FABRICACAO DE" },
  {
    cnae: "2854200",
    descricao: "MOTONIVELADORA PARA TERRAPLENAGEM; FABRICACAO DE",
  },
  { cnae: "2854200", descricao: "CARREGADORAS-TRANSPORTADORAS; FABRICACAO DE" },
  {
    cnae: "2854200",
    descricao: "ROLOS COMPRESSORES (PARA TERRAPLENAGEM); FABRICACAO DE",
  },
  {
    cnae: "2854200",
    descricao: "ESCARIFICADORES (PARA TERRAPLENAGEM); FABRICACAO DE",
  },
  {
    cnae: "2854200",
    descricao:
      "MAQUINAS PARA PAVIMENTACAO, N.E.(EXCETO TRATORES PARA PAVIMENTACAO); FABRICACAO DE",
  },
  {
    cnae: "2854200",
    descricao:
      "PECAS PARA MAQUINAS DE PAVIMENTACAO E TERRAPLENAGEM; FABRICACAO DE",
  },
  {
    cnae: "2854200",
    descricao:
      "MAQUINAS E EQUIPAMENTOS DE COMPRIMIR OU COMPACTAR TERRA, ETC., EXCETO AUTOPROPULSORAS (ESCAVO-CARREGADORAS, RASPO-TRANSPORTADORES); FABRICACAO DE",
  },
  { cnae: "2854200", descricao: "VIBRADORES DE CONCRETO; FABRICACAO DE" },
  {
    cnae: "2854200",
    descricao: "LAMINAS PARA BULDOZERS E ANGLEDOZERS; FABRICACAO DE",
  },
  {
    cnae: "2854200",
    descricao:
      "MAQUINAS PARA TERRAPLENAGEM, N.E. (EXCETO TRATORES DE TERRAPLENAGEM); FABRICACAO DE",
  },
  {
    cnae: "2854200",
    descricao: "BATE-ESTACAS E ARRANCA-ESTACAS, FABRICACAO DE",
  },
  {
    cnae: "2854200",
    descricao: "DUMPERS PARA UTILIZACAO FORA DAS RODOVIAS; FABRICACAO DE",
  },
  {
    cnae: "2854200",
    descricao:
      "PECAS PARA MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DA CONSTRUCAO; FABRICACAO DE",
  },
  {
    cnae: "2854200",
    descricao: "CARROCERIAS PARA DUMPERS (TRATOR TRANSPORTADOR); FABRICACAO DE",
  },
  {
    cnae: "2854200",
    descricao:
      "MAQUINAS E APARELHOS AUTOMOTRIZES PARA ESPALHAR E CALCAR PAVIMENTOS BETUMINOSOS; FABRICACAO DE",
  },
  {
    cnae: "2854200",
    descricao:
      "USINAS DE CONCRETO (MAQUINAS PARA FABRICAR CONCRETO); FABRICACAO DE",
  },
  { cnae: "2854200", descricao: "BULLDOZERS E ANGLEDOZERS; FABRICACAO DE" },
  {
    cnae: "28615",
    descricao:
      "FABRICACAO DE MAQUINAS PARA A INDUSTRIA METALURGICA, EXCETO MAQUINASFERRAMENTA",
  },
  { cnae: "2861500", descricao: "CONVERTEDORES; FABRICACAO DE" },
  { cnae: "2861500", descricao: "ASSENTOS DE LINGOTEIRAS; FABRICACAO DE" },
  {
    cnae: "2861500",
    descricao:
      "PECAS PARA MAQUINAS USADAS NA INDUSTRIA METALURGICA; FABRICACAO DE",
  },
  {
    cnae: "2861500",
    descricao: "MAQUINAS PARA A INDUSTRIA METALURGICA; FABRICACAO DE",
  },
  { cnae: "2861500", descricao: "LAMINADORES DE METAIS; FABRICACAO DE" },
  {
    cnae: "2861500",
    descricao: "CADINHOS E COLHERES PARA FUNDICAO; FABRICACAO DE",
  },
  {
    cnae: "2861500",
    descricao:
      "PECAS PARA MAQUINAS USADAS EM SIDERURGIA E TRABALHO DE METAIS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2861500",
    descricao:
      "MAQUINA DE VAZAR (MOLDAR) PARA INDUSTRIA SIDERURGICA; FABRICACAO DE",
  },
  { cnae: "2861500", descricao: "LINGOTEIRAS PARA SIDERURGIA; FABRICACAO DE" },
  {
    cnae: "2861500",
    descricao:
      "CONVERSORES, CADINHOS OU COLHERES DE FUNDICAO PARA ACIARIA, FUNDICAO OU METALURGIA; FABRICACAO DE",
  },
  {
    cnae: "2861500",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA SIDERURGICA; FABRICACAO DE",
  },
  { cnae: "2861500", descricao: "TRENS DE LAMINACAO; FABRICACAO DE" },
  {
    cnae: "28623",
    descricao:
      "FABRICACAO DE MAQUINAS E EQUIPAMENTOS PARA AS INDUSTRIAS DE ALIMENTOS, BEBIDAS E FUMO",
  },
  {
    cnae: "2862300",
    descricao:
      "PECAS PARA MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA ALIMENTAR; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao: "PENEIRAS VIBRATORIAS PARA CEREAIS; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "PRENSAS PARA MASSAS (MAQUINAS PARA PANIFICACAO E PASTIFICIO); FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao: "PRENSAS PARA INDUSTRIA DE BEBIDAS; FABRICACAO DE",
  },
  { cnae: "2862300", descricao: "MAQUINAS PARA FABRICAR GELO; FABRICACAO DE" },
  { cnae: "2862300", descricao: "EVISCERADORA DE PEIXES; FABRICACAO DE" },
  {
    cnae: "2862300",
    descricao: "MAQUINAS PARA INDUSTRIA DE LATICINIOS, N.E.; FABRICACAO DE",
  },
  { cnae: "2862300", descricao: "MAQUINAS PARA SERRAR OSSOS; FABRICACAO DE" },
  { cnae: "2862300", descricao: "CENTRIFUGADORES DE ACUCAR; FABRICACAO DE" },
  {
    cnae: "2862300",
    descricao: "MAQUINAS PARA A INDUSTRIA DO ACUCAR; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "DOSADORES (MAQUINAS PARA A INDUSTRIA DE BEBIDAS); FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao: "CILINDROS PARA PANIFICACAO E PASTIFICIOS; FABRICACAO DE",
  },
  { cnae: "2862300", descricao: "FORNOS PARA DEFUMACAO; FABRICACAO DE" },
  {
    cnae: "2862300",
    descricao:
      "MAQUINAS PARA FABRICACAO DE VINHO, SIDRA, SUCO DE FRUTAS OU BEBIDAS SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "MAQUINAS PARA BENEFICIAMENTO DE PRODUTOS AGRICOLAS USADAS NA INDUSTRIA DA MOAGEM; FABRICACAO DE",
  },
  { cnae: "2862300", descricao: "FILTROS PARA OLEOS VEGETAIS; FABRICACAO DE" },
  {
    cnae: "2862300",
    descricao: "FORNOS PARA SECAGEM DE PRODUTOS AGRICOLAS; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "MAQUINAS PARA ENCHER SALSICHAS, LINGUICAS E OUTROS EMBUTIDOS; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao: "MAQUINAS PARA INDUSTRIA DE BEBIDAS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "MAQUINAS E APARELHOS PARA INDUSTRIA DE OLEOS PARA FINS ALIMENTARES, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao: "MAQUINAS PARA INDUSTRIA CERVEJEIRA; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "PRENSAS PARA SEMENTES OLEAGINOSAS (MAQUINAS PARA INDUSTRIA DO OLEO); FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "PARTES E PECAS PARA MAQUINAS DE ORDENHAR E PARA INDUSTRIA DE LATICINIOS; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "MAQUINAS PARA EXTRACAO OU PREPARACAO DE OLEO OU GORDURA ANIMAL OU VEGETAL; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "PARTES E PECAS PARA APARELHOS E DISPOSITIVOS PARA TRATAMENTO DE MATERIAS QUE IMPLIQUEM MUDANCA DE TEMPERATURA (TORREFACAO, PASTEURIZACAO, DESTILACAO, ETC.); FABRICACAO DE",
  },
  { cnae: "2862300", descricao: "DESCAMADORES DE PEIXES; FABRICACAO DE" },
  {
    cnae: "2862300",
    descricao: "PRENSAS PARA FABRICACAO DE QUEIJOS; FABRICACAO DE",
  },
  { cnae: "2862300", descricao: "FORNOS PARA PADARIAS; FABRICACAO DE" },
  { cnae: "2862300", descricao: "MAQUINAS PARA FECULARIAS; FABRICACAO DE" },
  {
    cnae: "2862300",
    descricao: "SECADORES PARA PRODUTOS AGRICOLAS; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "PARTES E PECAS PARA MAQUINAS PARA FABRICACAO DE VINHO, SIDRA E SUCO DE FRUTAS OU BEBIDAS SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "MOENDAS PARA CANA (MAQUINAS PARA INDUSTRIA DO ACUCAR); FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao: "MOEDORES PARA CARNE, INDUSTRIAIS E COMERCIAIS; FABRICACAO DE",
  },
  { cnae: "2862300", descricao: "ALAMBIQUES; FABRICACAO DE" },
  { cnae: "2862300", descricao: "DEPENADEIRAS PARA AVES; FABRICACAO DE" },
  {
    cnae: "2862300",
    descricao:
      "MAQUINAS PARA PREPARACAO E FABRICACAO INDUSTRIAL DE ALIMENTOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "MISTURADORES DE MASSA PARA PANIFICACAO E PASTIFICIO; FABRICACAO DE",
  },
  { cnae: "2862300", descricao: "LAVADORA DE PEIXES; FABRICACAO DE" },
  {
    cnae: "2862300",
    descricao: "MAQUINAS PARA PANIFICACAO E PASTIFICIO, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao: "MAQUINAS DE PASTEURIZAR LEITE; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao: "MAQUINAS PARA INDUSTRIA DO CIGARRO; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao: "MAQUINAS PARA FABRICACAO DE CIGARROS; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "MAQUINAS E APARELHOS PARA INDUSTRIA DE CONSERVAS DE FRUTAS E LEGUMES; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "FORNOS PARA TRATAMENTO DE ALIMENTOS E BEBIDAS (PASTEURIZAR, CONDENSAR, ETC); FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "FORNOS INDUSTRIAIS NAO ELETRICOS PARA PADARIAS, PASTELARIAS OU INDUSTRIAS DE BISCOITOS; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao: "MAQUINAS PARA A INDUSTRIA DE BALAS E BOMBONS; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao: "MAQUINAS PARA INDUSTRIA DE CACAU E CHOCOLATE; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao: "SECADORES-CENTRIFUGADORES DE ROUPAS; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "APARELHOS E DISPOSITIVOS PARA TRATAMENTO DE MATERIAS QUE IMPLIQUEM MUDANCA DE TEMPERATURA (TORREFACAO, PASTEURIZACAO, DESTILACAO, ESTERILIZACAO, ETC.); FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "PECAS PARA MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DO FUMO; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "CORTADORES DE MASSAS PARA PANIFICACAO E PASTIFICIO; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao:
      "DESCASCADEIRAS E COZINHADORES (MAQUINAS PARA A INDUSTRIA ALIMENTAR); FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao: "TORRADORES INDUSTRIAIS PARA CAFE; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao: "MAQUINAS E APARELHOS PARA A INDUSTRIA DO FUMO; FABRICACAO DE",
  },
  {
    cnae: "2862300",
    descricao: "MAQUINAS PARA MATADOURO E ABATEDOURO, N.E.; FABRICACAO DE",
  },
  { cnae: "2862300", descricao: "DESNATADEIRAS; FABRICACAO DE" },
  {
    cnae: "2862300",
    descricao: "PICADORES (MAQUINAS PARA A INDUSTRIA DO FUMO); FABRICACAO DE",
  },
  { cnae: "2862300", descricao: "DESTILARIAS DE AGUARDENTE; FABRICACAO DE" },
  {
    cnae: "28631",
    descricao: "FABRICACAO DE MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA TEXTIL",
  },
  {
    cnae: "2863100",
    descricao:
      "MAQUINAS PARA ACABAMENTOS DE FIOS E TECIDOS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "MAQUINAS PARA TINGIMENTO DE FIOS E TECIDOS; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "MAQUINAS PARA TEXTURIZACAO DE FIOS E TECIDOS; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "MAQUINAS PARA ESTAMPARIA DE TECIDOS; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "MACAROQUEIRAS DE TODOS OS TIPOS; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "MAQUINAS PARA INSPECIONAR TECIDOS; FABRICACAO DE",
  },
  { cnae: "2863100", descricao: "SECADORES PARA TECIDOS; FABRICACAO DE" },
  {
    cnae: "2863100",
    descricao: "AGULHAS PARA MAQUINAS PARA A INDUSTRIA TEXTIL; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "DESCAROCADEIRAS E DESLINTADEIRAS DE ALGODAO; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "MAQUINAS PARA CARDAR DE TODOS OS TIPOS; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "MEADEIRAS (MAQUINAS PARA INDUSTRIA TEXTIL); FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "PARTES, PECAS E ACESSORIOS PARA TEARES; FABRICACAO DE",
  },
  { cnae: "2863100", descricao: "ENGOMADEIRAS DE TECIDOS; FABRICACAO DE" },
  {
    cnae: "2863100",
    descricao: "TORCEDEIRAS E RETORCEDEIRAS PARA FIOS TEXTEIS; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao:
      "MAQUINAS PARA FABRICACAO OU ACABAMENTO DE FELTRO OU DE FALSOS TECIDOS E SUAS PARTES; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "MAQUINAS PARA A INDUSTRIA TEXTIL, N.E.; FABRICACAO DE",
  },
  { cnae: "2863100", descricao: "PECAS PARA MAQUINAS TEXTEIS; FABRICACAO DE" },
  { cnae: "2863100", descricao: "LICOS PARA TEARES; FABRICACAO DE" },
  {
    cnae: "2863100",
    descricao: "MAQUINAS PARA FABRICACAO DE TECIDO DE MALHA; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "MAQUINAS MANUAIS PARA TRICOTAR (INDUSTRIAIS); FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "BOBINADEIRAS (MAQUINAS PARA INDUSTRIA TEXTIL); FABRICACAO DE",
  },
  { cnae: "2863100", descricao: "TEARES DE TODOS OS TIPOS; FABRICACAO DE" },
  { cnae: "2863100", descricao: "QUADROS PARA TEARES; FABRICACAO DE" },
  {
    cnae: "2863100",
    descricao: "MAQUINAS INDUSTRIAIS MOTORIZADAS PARA TRICOTAR; FABRICACAO DE",
  },
  { cnae: "2863100", descricao: "ENGOMADEIRAS DE FIOS; FABRICACAO DE" },
  { cnae: "2863100", descricao: "GUARNICOES PARA CARDAS; FABRICACAO DE" },
  {
    cnae: "2863100",
    descricao: "VIAJANTES (CURSORES) PARA MAQUINAS DE FIAR; FABRICACAO DE",
  },
  { cnae: "2863100", descricao: "FUSOS PARA FILATORIOS, N.E.; FABRICACAO DE" },
  {
    cnae: "2863100",
    descricao:
      "FIADEIRAS OU FILATORIOS (MAQUINA PARA INDUSTRIA TEXTIL); FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "PENTEADEIRAS PARA INDUSTRIA TEXTIL; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "URDIDEIRAS PARA INDUSTRIA TEXTIL; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao:
      "MAQUINAS E APARELHOS AUXILIARES PARA AS MAQUINAS DAS INDUSTRIAS TEXTEIS, NAO ESPECIFICADOS(RATIEIRAS E MECANISMOS JACQUARD, ETC.); FABRICACAO DE",
  },
  { cnae: "2863100", descricao: "LANCADEIRAS PARA TEARES; FABRICACAO DE" },
  {
    cnae: "2863100",
    descricao: "MAQUINAS PARA FIACAO (FILATORIOS), N.E.; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao:
      "MAQUINAS PARA EXTRUDAR, ESTIRAR, TEXTURIZAR OU CORTAR MATERIAS TEXTEIS SINTETICAS OU ARTIFICIAIS; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "TUBETES DE MADEIRA PARA FIACAO; FABRICACAO DE",
  },
  { cnae: "2863100", descricao: "CARDAS; FABRICACAO DE" },
  {
    cnae: "2863100",
    descricao:
      "BATEDORES-ABRIDORES (MAQUINAS PARA INDUSTRIA TEXTIL); FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao:
      "MAQUINAS PARA FABRICACAO OU PREPARACAO DE FIOS TEXTEIS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "ESPULADEIRAS (MAQUINA PARA INDUSTRIA TEXTIL); FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao:
      "MAQUINAS DE LAVAR, TINGIR OU BRANQUEAR FIOS, TECIDOS OU ARTEFATOS DA INDUSTRIA TEXTIL, EXCETO VESTUARIO; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao:
      "CARRETEIS E BOBINAS DE MADEIRA PARA MAQUINAS TEXTEIS; FABRICACAO DE",
  },
  {
    cnae: "2863100",
    descricao: "TRENS DE ESTIRAGEM PARA MAQUINAS DE FIAR; FABRICACAO DE",
  },
  {
    cnae: "28640",
    descricao:
      "FABRICACAO DE MAQUINAS E EQUIPAMENTOS PARA AS INDUSTRIAS DO VESTUARIO, DO COURO E DE CALCADOS",
  },
  {
    cnae: "2864000",
    descricao:
      "MAQUINAS E PRENSAS PARA PASSAR, INCLUSIVE AS PRENSAS FIXADORAS (PARA INDUSTRIA DO VESTUARIO); FABRICACAO DE",
  },
  { cnae: "6622300", descricao: "CORRETORA DE SEGUROS" },
  {
    cnae: "2864000",
    descricao: "MAQUINAS MANUAIS PARA TRICOTAR, DOMESTICAS; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao:
      "PECAS PARA MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DO CALCADO; FABRICACAO DE",
  },
  { cnae: "2864000", descricao: "PESPONTADEIRAS PARA CALCADOS; FABRICACAO DE" },
  { cnae: "2864000", descricao: "MAQUINAS PARA PREGAR BOTOES; FABRICACAO DE" },
  {
    cnae: "2864000",
    descricao: "MAQUINAS PARA TRABALHAR COUROS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao:
      "PECAS PARA MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DO COURO; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao:
      "MAQUINAS PARA PREPARAR, CURTIR E TRABALHAR COUROS E PELES; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao:
      "MAQUINAS PARA LAVAR OU SECAR TECIDOS (PARA INDUSTRIA DO VESTUARIO); FABRICACAO DE",
  },
  { cnae: "2864000", descricao: "MAQUINAS PARA CASEAR; FABRICACAO DE" },
  {
    cnae: "2864000",
    descricao:
      "PARTES E PECAS, INCLUSIVE AGULHAS, PARA REMALHADEIRAS, LANCADEIRAS E OUTRAS MAQUINAS DE COSTURA INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao: "AGULHAS PARA MAQUINAS DE COSTURA; FABRICACAO DE",
  },
  { cnae: "2864000", descricao: "MAQUINA PARA CORTAR TECIDOS; FABRICACAO DE" },
  {
    cnae: "2864000",
    descricao: "CABECOTES PARA MAQUINAS DE COSTURA INDUSTRIAIS; FABRICACAO DE",
  },
  { cnae: "2864000", descricao: "MAQUINAS PARA BORDAR; FABRICACAO DE" },
  {
    cnae: "2864000",
    descricao: "MAQUINAS DE COSTURA DOMESTICAS; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao: "PECAS PARA MAQUINAS DE LAVAR DE USO INDUSTRIAL; FABRICACAO DE",
  },
  { cnae: "2864000", descricao: "MAQUINAS PARA CURTUME; FABRICACAO DE" },
  {
    cnae: "2864000",
    descricao:
      "PECAS PARA MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DO VESTUARIO; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao:
      "PARTES E PECAS PARA MAQUINAS DE COSTURA DE USO DOMESTICO; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao: "MAQUINAS PARA REPARACAO DE CALCADOS; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao:
      "MAQUINAS PARA FABRICACAO OU CONSERTOS DE ARTIGOS DE COURO E PELES; FABRICACAO DE",
  },
  { cnae: "9312300", descricao: "CLUBE RECREATIVO" },
  { cnae: "2864000", descricao: "PRENSAS PARA COUROS; FABRICACAO DE" },
  {
    cnae: "2864000",
    descricao: "MAQUINAS DE COSTURA INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao: "MAQUINAS DE TRICOTAR DOMESTICAS; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao:
      "PARTES E PECAS PARA OUTRAS MAQUINAS PARA TRABALHAR PRODUTOS TEXTEIS; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao: "PECAS PARA MAQUINAS DE COSTURA; FABRICACAO DE",
  },
  { cnae: "2864000", descricao: "CORTADORES PARA COURO; FABRICACAO DE" },
  {
    cnae: "2864000",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA INSTALACOES EM LAVANDERIAS, TINTURARIAS E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao:
      "MAQUINAS PARA LAVAR, TINGIR, BRANQUEAR OU SECAR ARTEFATOS DA INDUSTRIA DO VESTUARIO; FABRICACAO DE",
  },
  { cnae: "2864000", descricao: "OVERLOCK PORTATIL; FABRICACAO DE" },
  {
    cnae: "2864000",
    descricao: "CABECOTES PARA MAQUINAS DE COSTURA DOMESTICAS; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao:
      "MOVEIS, BASES E TAMPAS PARA MAQUINAS DE COSTURA E SUAS PARTES; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao:
      "MAQUINAS E APARELHOS PARA INDUSTRIA DO VESTUARIO, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao:
      "PARTES E PECAS PARA SECADORES DE ROUPA, CENTRIFUGOS, COM CAPACIDADE NAO SUPERIOR A 6KG; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao: "MAQUINAS PARA INDUSTRIA DE CALCADOS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2864000",
    descricao: "MAQUINAS DE LAVAR, DE USOS INDUSTRIAL; FABRICACAO DE",
  },
  { cnae: "2864000", descricao: "ALISADORES PARA COUROS; FABRICACAO DE" },
  {
    cnae: "28658",
    descricao:
      "FABRICACAO DE MAQUINAS E EQUIPAMENTOS PARA AS INDUSTRIAS DE CELULOSE, PAPEL E PAPELAO E ARTEFATOS",
  },
  {
    cnae: "2865800",
    descricao:
      "CORTADEIRAS OU BOBINADORAS PARA PAPEL OU PAPEL-CARTAO, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao:
      "MAQUINAS PARA FABRICACAO E ACABAMENTO DE PAPEL OU PAPEL-CARTAO (PARA BOBINAR, IMPREGNAR, ONDULAR, ETC.); FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao: "FORNOS PARA A PRODUCAO DE PAPEL E CELULOSE; FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao: "RASPADEIRAS E CORTADORES DE PAPEL; FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao: "MAQUINAS PARA CONFECCAO DE CADERNOS; FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao: "GRAMPEADEIRAS PARA CARTONAGEM; FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao:
      "TESOUROES E GUILHOTINAS PARA A INDUSTRIA DO PAPEL E DE PRODUTOS DO PAPEL; FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao:
      "PECAS PARA MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DE CELULOSE, PAPEL E PAPELAO; FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao:
      "PARTES E PECAS DE MAQUINAS PARA FABRICACAO DE PASTA DE MATERIA CELULOSICA; FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao:
      "REFINADORAS PARA FABRICACAO DE PASTA DE MATERIA CELULOSICA; FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao:
      "PARTES E PECAS PARA MAQUINAS PARA TRABALHOS DE PASTA DE PAPEL, PAPEL OU PAPEL-CARTAO, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao:
      "MAQUINAS E APARELHOS PARA INDUSTRIA DE ARTEFATOS DE PAPEL, PAPELAO E CARTONAGEM, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao:
      "EMPASTADORES (MAQUINAS PARA INDUSTRIA DO PAPEL E PAPELAO); FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao:
      "MAQUINAS PARA TRABALHAR MATERIA-PRIMA, PARA FABRICAR PASTA DE CELULOSE (CLASSIFICADORAS, DEPURADORAS, REFINADORAS, ETC.); FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao:
      "PECAS PARA MAQUINAS E EQUIPAMENTOS PARA AS INDUSTRIAS DE ARTEFATOS DE PAPEL E CARTONAGEM; FABRICACAO DE",
  },
  { cnae: "2865800", descricao: "MAQUINAS PARA CORRUGAR PAPEL; FABRICACAO DE" },
  {
    cnae: "2865800",
    descricao:
      "DESPOLPADEIRAS (MAQUINAS PARA INDUSTRIA DO PAPEL E PAPELAO); FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao:
      "COZINHADORES (MAQUINAS PARA INDUSTRIA DO PAPEL E PAPELAO); FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao:
      "SECADORES PARA MADEIRAS, PASTAS DE PAPEL, PAPEIS OU CARTOES; FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao:
      "CLARIFICADORES (MAQUINAS PARA INDUSTRIA DO PAPEL E PAPELAO); FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao:
      "PARTES E PECAS DE MAQUINAS PARA FABRICACAO OU ACABAMENTO DE PAPEL OU PAPEL-CARTAO; FABRICACAO DE",
  },
  {
    cnae: "2865800",
    descricao:
      "MAQUINAS PARA FABRICACAO DE PAPEL E PAPELAO, N.E.; FABRICACAO DE",
  },
  {
    cnae: "28666",
    descricao:
      "FABRICACAO DE MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DO PLASTICO",
  },
  {
    cnae: "2866600",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS E EQUIPAMENTOS USADOS NA INDUSTRIA DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2866600",
    descricao:
      "LAVADORAS E SECADORAS PARA A INDUSTRIA DE PLASTICO; FABRICACAO DE",
  },
  { cnae: "2866600", descricao: "INJETORAS DE PLASTICO; FABRICACAO DE" },
  {
    cnae: "2866600",
    descricao:
      "MAQUINAS PARA INDUSTRIA DO MATERIAL PLASTICO, N.E.; FABRICACAO DE",
  },
  { cnae: "2866600", descricao: "EXTRUSORAS PARA PLASTICO; FABRICACAO DE" },
  {
    cnae: "2866600",
    descricao: "MAQUINAS PARA INJETAR PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2866600",
    descricao: "MOINHOS PARA A INDUSTRIA DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2866600",
    descricao:
      "MAQUINAS DE MOLDAR PARA TRABALHAR PRODUTOS DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2866600",
    descricao: "BICOS E PONTEIRAS PARA INJETORES DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2866600",
    descricao: "AGLUTINADORES PARA A INDUSTRIA DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "2866600",
    descricao: "MAQUINAS PARA A INDUSTRIA DE ARTIGOS PLASTICOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "POLIAS DO MOTOR PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "28691",
    descricao:
      "FABRICACAO DE MAQUINAS E EQUIPAMENTOS PARA USO INDUSTRIAL ESPECIFICO NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "2869100",
    descricao: "MAQUINAS PARA TRABALHAR FIOS DE FIBRA DE VIDRO; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "CORPOS MOEDORES PARA INDUSTRIA DO CIMENTO; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "MAQUINAS PARA PRENSAR SABAO E SABONETE; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "MAQUINAS E APARELHOS PARA INDUSTRIA DE ARTEFATOS DE CIMENTO; FABRICACAO DE",
  },
  { cnae: "2869100", descricao: "MAROMBAS PARA BARRO E ARGILA; FABRICACAO DE" },
  {
    cnae: "2869100",
    descricao: "COZINHADORES DE SABOES E SABONETES; FABRICACAO DE",
  },
  { cnae: "2869100", descricao: "ROBOS INDUSTRIAIS COMPLETOS; FABRICACAO DE" },
  {
    cnae: "2869100",
    descricao:
      "DISTRIBUIDORES E DOSADORES DE SOLIDOS OU LIQUIDOS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "MAQUINAS E APARELHOS PARA INDUSTRIA GRAFICA, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "MAQUINAS PARA A INDUSTRIA DO ALCOOL; FABRICACAO DE",
  },
  { cnae: "2869100", descricao: "SIMULADORES DE VOO; FABRICACAO DE" },
  { cnae: "2869100", descricao: "EXTRUSORAS PARA BORRACHA; FABRICACAO DE" },
  {
    cnae: "2869100",
    descricao: "MAQUINAS PARA INDUSTRIA DO VIDRO; FABRICACAO DE",
  },
  { cnae: "2869100", descricao: "MAQUINAS PARA GALVANOPLASTIA; FABRICACAO DE" },
  { cnae: "2869100", descricao: "BETUMADEIRAS; FABRICACAO DE" },
  { cnae: "2869100", descricao: "USINAS DE ASFALTO (MAQUINA); FABRICACAO DE" },
  { cnae: "2869100", descricao: "DESTILARIAS DE ALCOOL; FABRICACAO DE" },
  {
    cnae: "2869100",
    descricao: "MAQUINAS PARA INDUSTRIA DA BORRACHA, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DO REFINO DO PETROLEO; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "MAQUINAS PARA RECAUCHUTAR PNEUS; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA GRAFICA; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS PARA A INDUSTRIA DA MADEIRA; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "MAQUINAS PARA COMPOSICAO TIPOGRAFICA; FABRICACAO DE",
  },
  { cnae: "2869100", descricao: "MAQUINAS PARA CLICHERIA; FABRICACAO DE" },
  {
    cnae: "2869100",
    descricao:
      "PECAS PARA MAQUINAS E EQUIPAMENTOS DE USO ESPECIFICO; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "PRENSAS PARA PICOTAR OU MARCAR PAPEL; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DE PRODUTOS DE FIBRA DE VIDRO; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "MAQUINAS E APARELHOS PARA INDUSTRIAS DE PRODUTOS FARMACEUTICOS; FABRICACAO DE",
  },
  { cnae: "2869100", descricao: "MAQUINAS PARA LITOGRAFIA; FABRICACAO DE" },
  {
    cnae: "2869100",
    descricao:
      "MAQUINAS PARA FABRICACAO DE MOLDES DE AREIA PARA FUNDICAO; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "CORPOS MOEDORES PARA MAQUINAS INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "MAQUINAS E APARELHOS PARA INDUSTRIAS DE PRODUTOS QUIMICOS; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "MAQUINAS PARA A INDUSTRIA DA MADEIRA, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "APARELHOS E DISPOSITIVOS PARA LANCAMENTO OU ATERRISAGEM DE VEICULOS AEREOS (EM PORTA-AVIOES) ESUAS PARTES; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "MAQUINAS PARA IMPRSSAO, NAO ESPECIFICADOS, NAO ESPECIFICADOS (OFSETE, TIPOGRAFICA, SERIGRAFIA, FLEXOGRAFICOS, JATO DE TINTA, HELIOGRAFICOS, ETC.); FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "MAQUINAS TRATAMENTO DE METAIS; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "APARELHOS E EQUIPAMENTOS SIMULADORES DE DIRECAO PARA AUTO ESCOLA;FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "MAQUINAS PARA BROCHURA OU ENCADERNACAO, INCLUSIVE AS MAQUINAS DE COSTURAR CADERNOS; FABRICACAO DE",
  },
  { cnae: "2869100", descricao: "MAQUINAS PARA VULCANIZACAO; FABRICACAO DE" },
  {
    cnae: "2869100",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS E EQUIPAMENTO PARA A INDUSTRIA QUIMICA; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DE PERFUMARIA, SABOES E VELAS; FABRICACAO DE",
  },
  { cnae: "2869100", descricao: "MAQUINAS PARA FABRICAR PNEUS; FABRICACAO DE" },
  { cnae: "2869100", descricao: "PRENSAS PARA LIVROS; FABRICACAO DE" },
  {
    cnae: "2869100",
    descricao: "MAQUINAS E APARELHOS PARA OLARIAS; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DO ALCOOL; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "APARELHOS E EQUIPAMENTOS SIMULADORES PARA MAQUINAS DE USO AGROPECUARIO, INDUSTRIAL E INFRA ESTRUTURA PORTUARIA, FABRICACAO DE",
  },
  { cnae: "2869100", descricao: "GUILHOTINAS, N.E.; FABRICACAO DE" },
  {
    cnae: "2869100",
    descricao:
      "PARTES E PECAS DE OUTRAS MAQUINAS E APARELHOS PARA USOS INDUSTRIAIS, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "CORTADORES DE SABOES E SABONETES; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "MAQUINAS PARA INDUSTRIA DO CIMENTO; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DE CERAMICA; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "PICOTADEIRAS PARA A INDUSTRIA GRAFICA; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "PARTES E PECAS DE OUTRAS MAQUINAS PARA FABRICAR OU TRABALHAR VIDRO E SUAS OBRAS A QUENTE; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "PECAS PARA MAQUINAS PARA A INDUSTRIA DO CIMENTO; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "MAQUINAS PARA INDUSTRIA DE PERFUMARIA, SABOES E VELAS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "MAQUINAS E EQUIPAMENTOS PARA SERIGRAFIA; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "DISPOSITIVOS DE EVAPORACAO PARA ARREFECIMENTO DO AR; FABRICACAO DE",
  },
  { cnae: "2869100", descricao: "MAQUINAS PARA FUNDIR TIPOS; FABRICACAO DE" },
  {
    cnae: "2869100",
    descricao:
      "SILOS METALICOS PARA CEREAIS, FIXOS, INCLUSIVE COM MECANISMOS; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "CILINDROS MISTURADORES PARA INDUSTRIA DO SABAO; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "MAQUINAS AUXILIARES PARA IMPRESSAO; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "PRENSAS PARA INDUSTRIA DE CERAMICA; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "MAQUINAS E APARELHOS PARA INDUSTRIA CERAMICA; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "MAQUINAS PARA AMASSAR, ESMAGAR, MOER, MISTURAR, SEPARAR, ETC., SEM APLICACAO ESPECIFICA; FABRICACAO DE",
  },
  { cnae: "2869100", descricao: "MAQUINAS PARA ENCADERNACAO; FABRICACAO DE" },
  {
    cnae: "2869100",
    descricao: "GUILHOTINAS PARA A INDUSTRIA GRAFICA; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao: "MAQUINAS PARA REFINACAO DE PETROLEO; FABRICACAO DE",
  },
  {
    cnae: "2869100",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DA BORRACHA; FABRICACAO DE",
  },
  {
    cnae: "29107",
    descricao: "FABRICACAO DE AUTOMOVEIS, CAMIONETAS E UTILITARIOS",
  },
  {
    cnae: "2910701",
    descricao: "VEICULOS PARA CAMPOS DE GOLFE; FABRICACAO DE",
  },
  { cnae: "2910701", descricao: "UTILITARIOS; FABRICACAO DE" },
  {
    cnae: "2910701",
    descricao:
      "VEICULOS PARA TRANSPORTE ESPECIALIZADO (AMBULANCIAS, CARROS CELULARES, ETC); FABRICACAO DE",
  },
  {
    cnae: "2910701",
    descricao:
      "AUTOMOVEIS, CAMIONETAS E UTILITARIOS; CUSTOMIZACAO,TRANSFORMACAO DE",
  },
  { cnae: "2910701", descricao: "AUTOMOVEIS, MONTAGEM DE" },
  {
    cnae: "2910701",
    descricao: "AUTOMOVEIS E UTILITARIOS TRANSFORMADOS; FABRICACAO DE",
  },
  {
    cnae: "2910701",
    descricao:
      "CAMINHONETES (CAMIONETAS, CAMIONETES) E UTILITARIOS; FABRICACAO DE",
  },
  { cnae: "2910701", descricao: "AUTOMOVEIS COMPLETOS; FABRICACAO DE" },
  { cnae: "2910701", descricao: "UTILITARIOS TRANSFORMADOS; FABRICACAO DE" },
  { cnae: "2910701", descricao: "KART; FABRICACAO DE" },
  {
    cnae: "2910702",
    descricao:
      "CHASSIS COM MOTOR PARA AUTOMOVEIS, CAMINHONETES (CAMIONETAS, CAMIONETES) E UTILITARIOS; FABRICACAO DE",
  },
  {
    cnae: "2910702",
    descricao:
      "CHASSIS PREPARADOS, A PARTIR DE CHASSIS COMPLETOS, PARA AUTOMOVEIS; FABRICACAO DE",
  },
  {
    cnae: "2910703",
    descricao:
      "MOTORES PARA AUTOMOVEIS, CAMINHONETES (CAMIONETAS, CAMIONETES) E UTILITARIOS; FABRICACAO DE",
  },
  { cnae: "29204", descricao: "FABRICACAO DE CAMINHOES E ONIBUS" },
  { cnae: "2920401", descricao: "ONIBUS ELETRICOS (TROLEBUS); FABRICACAO DE" },
  {
    cnae: "2920401",
    descricao: "VEICULOS PARA TRANSPORTES ESPECIAIS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2920401",
    descricao: "CAMINHOES FRIGORIFICOS COMPLETOS; FABRICACAO DE",
  },
  {
    cnae: "2920401",
    descricao:
      "VASSOURAS MECANICAS PARA LIMPEZA DE VIAS PUBLICAS; FABRICACAO DE",
  },
  { cnae: "2920401", descricao: "CAMINHAO-BETONEIRA; FABRICACAO DE" },
  {
    cnae: "2920401",
    descricao:
      "CAMINHOES PROVIDOS DE TANQUES, BOMBAS, GUINCHOS, ESCADAS, VASSOURAS E OUTRA QUALQUER APARELHAGEM; FABRICACAO DE",
  },
  {
    cnae: "2920401",
    descricao: "VEICULOS DE COMBATE A INCENDIO; FABRICACAO DE",
  },
  { cnae: "2920401", descricao: "VEICULOS PARA MUDANCAS; FABRICACAO DE" },
  { cnae: "2920401", descricao: "CAMINHOES COMPLETOS; FABRICACAO DE" },
  { cnae: "2920401", descricao: "CAVALO MECANICO; FABRICACAO DE" },
  {
    cnae: "2920401",
    descricao:
      "CHASSIS PREPARADOS, A PARTIR DE CHASSIS COMPLETOS, PARA ONIBUS; FABRICACAO DE",
  },
  {
    cnae: "2920401",
    descricao: "CAMINHOES E ONIBUS; CUSTOMIZACAO,TRANSFORMACAO DE",
  },
  { cnae: "2920401", descricao: "ONIBUS E MICROONIBUS; FABRICACAO DE" },
  {
    cnae: "2920401",
    descricao: "VEICULOS PARA ENTREGA DE QUALQUER TIPO; FABRICACAO DE",
  },
  {
    cnae: "2920401",
    descricao:
      "CAMINHAO-TRATOR PARA SEMI-REBOQUE (TRUCK RODOVIARIO); FABRICACAO DE",
  },
  {
    cnae: "2920401",
    descricao:
      "CHASSIS COM MOTOR PARA CAMINHOES, ONIBUS E MICROONIBUS; FABRICACAO DE",
  },
  {
    cnae: "2920402",
    descricao: "MOTORES PARA CAMINHOES E ONIBUS; FABRICACAO DE",
  },
  {
    cnae: "29301",
    descricao:
      "FABRICACAO DE CABINES, CARROCERIAS E REBOQUES PARA VEICULOS AUTOMOTORES",
  },
  { cnae: "2930101", descricao: "CARROCERIAS PARA CAMINHOES; FABRICACAO DE" },
  {
    cnae: "2930101",
    descricao: "CARROCERIAS TANQUE (METALICAS) PARA CAMINHOES; FABRICACAO DE",
  },
  {
    cnae: "2930101",
    descricao:
      "REBOQUES, SEMIRREBOQUES OU CARRETAS COM CARROCERIA ABERTA (PRANCHA, SILO PARA TRANSPORTE DE CONTAINERES, ETC); FABRICACAO DE",
  },
  {
    cnae: "2930101",
    descricao:
      "REBOQUES, SEMIRREBOQUES OU CARRETAS COM CARROCERIA ABERTA; FABRICACAO DE",
  },
  {
    cnae: "2930101",
    descricao:
      "CARROCERIAS BASCULANTES (METALICAS) PARA CAMINHOES; FABRICACAO DE",
  },
  { cnae: "2930101", descricao: "CABINES PARA CAMINHOES; FABRICACAO DE" },
  {
    cnae: "2930101",
    descricao:
      "CARROCERIAS METALICAS ESPECIALIZADAS PARA CAMINHOES; FABRICACAO DE",
  },
  {
    cnae: "2930101",
    descricao:
      "CARROCERIAS FRIGORIFICAS (METALICAS) PARA CAMINHOES; FABRICACAO DE",
  },
  { cnae: "2930101", descricao: "CACAMBAS PARA CAMINHOES; FABRICACAO DE" },
  {
    cnae: "2930101",
    descricao:
      "CARROCERIAS PARA LIXO (METALICAS) PARA CAMINHOES; FABRICACAO DE",
  },
  { cnae: "2930101", descricao: "CARROCERIAS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "2930101",
    descricao:
      "CONTENTORES METALICOS (CONTAINERES; CONTEINERES); FABRICACAO DE",
  },
  {
    cnae: "2930102",
    descricao:
      "CARROCERIAS DE FIBRA DE VIDRO PARA ONIBUS E MICROONIBUS; FABRICACAO DE",
  },
  {
    cnae: "2930102",
    descricao: "CARROCERIAS PARA ONIBUS E MICROONIBUS; FABRICACAO DE",
  },
  {
    cnae: "2930103",
    descricao:
      "CAPOTAS METALICAS OU DE FIBRA DE VIDRO PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  { cnae: "2930103", descricao: "TRAILLERS; FABRICACAO DE" },
  { cnae: "2930103", descricao: "CONTEINERES; FABRICACAO DE" },
  {
    cnae: "2930103",
    descricao:
      "REBOQUES E SEMIRREBOQUES (EXCETO PARA CAMINHOES); FABRICACAO DE",
  },
  {
    cnae: "2930103",
    descricao:
      "CAPOTAS DE LONA E PLASTICO PARA VEICULOS DE PASSEIO E UTILITARIOS; FABRICACAO DE",
  },
  {
    cnae: "2930103",
    descricao:
      "CONTEINERES (CONTENTORES) DE QUALQUER MATERIAL, ESPECIALMENTE CONCEBIDOS PARA UM OU VARIOS MEIOS DE TRANSPORTE; FABRICACAO DE",
  },
  {
    cnae: "2930103",
    descricao: "CARRETAS PARA TRANSPORTE DE MADEIRA; FABRICACAO DE",
  },
  { cnae: "2930103", descricao: "CASA-REBOQUE (TRAILER); FABRICACAO DE" },
  {
    cnae: "2930103",
    descricao:
      "CARROCERIAS, TRANSFORMADAS OU ADAPTADAS, PARA VEICULOS ESPECIAIS (AMBULANCIAS, BOMBEIROS, TELEFONIA, ETC); FABRICACAO DE",
  },
  {
    cnae: "2930103",
    descricao:
      "CARROCERIAS PARA AUTOMOVEIS,  CAMINHONETES (CAMIONETAS, CAMIONETES) E UTILITARIOS, DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  {
    cnae: "2930103",
    descricao:
      "PARTES E PECAS PARA REBOQUES (CHASSIS, EIXOS, ETC.); FABRICACAO DE",
  },
  {
    cnae: "29417",
    descricao:
      "FABRICACAO DE PECAS E ACESSORIOS PARA O SISTEMA MOTOR DE VEICULOS AUTOMOTORES",
  },
  {
    cnae: "2941700",
    descricao: "DISCOS DE EMBREAGEM PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "BOMBAS DE AGUA PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "CAMISAS DE CILINDRO PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "BOMBAS DE OLEO PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "COLMEIAS DO RADIADOR PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "PLATOS DE EMBREAGEM PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "FILTROS DE GASOLINA PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "VENTILADOR DO MOTOR PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "JUNTAS PARA MOTORES DE VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "PISTOES DO MOTOR PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao:
      "CREMALHEIRA DO RADIADOR PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "BIELAS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao:
      "PECAS E ACESSORIOS PARA MOTORES DE VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao:
      "BLOCOS DO MOTOR, USINADOS, PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "EMBREAGEM PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "CILINDRO DE MOTOR PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "BOMBAS DE GASOLINA PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "AFOGADORES PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "BRONZINAS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "RADIADORES COMPLETOS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "CARBURADORES PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "CARTER PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "FILTROS DE AR PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao:
      "EIXO DO COMANDO DE VALVULA PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao:
      "ANEIS DE SEGMENTO PARA PISTOES DE VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "BOMBAS INJETORAS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "CABECOTES PARA MOTORES DE VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao:
      "VALVULAS DE ADMISSAO E EXAUSTAO PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao:
      "VIRABREQUINS (EIXO OU ARVORE DE MANIVELA) PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2941700",
    descricao: "FILTROS DE OLEO PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "29425",
    descricao:
      "FABRICACAO DE PECAS E ACESSORIOS PARA OS SISTEMAS DE MARCHA E TRANSMISSAO DE VEICULOS AUTOMOTORES",
  },
  {
    cnae: "2942500",
    descricao: "BENGALAS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2942500",
    descricao:
      "EIXOS DE TRANSMISSAO COM DIFERENCIAL, MESMO COM OUTROS ORGAOS DE TRANSMISSAO, PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2942500",
    descricao:
      "EIXO DO COMANDO DA CAIXA DE MUDANCA PARA VEICULO RODOVIARIO; FABRICACAO DE",
  },
  {
    cnae: "2942500",
    descricao:
      "PECAS E ACESSORIOS PARA OS SISTEMAS DE MARCHA E TRANSMISSAO PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2942500",
    descricao: "DIFERENCIAIS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2942500",
    descricao:
      "SISTEMAS DE MARCHA E TRANSMISSAO COMPLETOS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2942500",
    descricao: "EIXO CARDAN PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2942500",
    descricao: "EIXO DIFERENCIAL PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2942500",
    descricao: "COROAS E PINHOES PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2942500",
    descricao:
      "EIXOS, SEMIEIXOS, ENGRENAGENS, MANCAIS, JUNTAS DE ARTICULACAO OU OUTRAS PECAS PARA TRANSMISSAO PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2942500",
    descricao:
      "CAIXAS DE MUDANCA DE MARCHA PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "29433",
    descricao:
      "FABRICACAO DE PECAS E ACESSORIOS PARA O SISTEMA DE FREIOS DE VEICULOS AUTOMOTORES",
  },
  {
    cnae: "2943300",
    descricao:
      "FREIOS A DISCO (SISTEMA COMPLETO) PARA VEICULO RODOVIARIO; FABRICACAO DE",
  },
  {
    cnae: "2943300",
    descricao:
      "PECAS E ACESSORIOS PARA O SISTEMA DE FREIOS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2943300",
    descricao:
      "FREIOS A LONA (SISTEMA COMPLETO) PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2943300",
    descricao: "CILINDROS DE FREIO PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2943300",
    descricao:
      "PISTAO DO CILINDRO MESTRE DO FREIO PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2943300",
    descricao:
      "SISTEMAS DE FREIOS COMPLETOS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2943300",
    descricao: "FREIOS PARA VEICULOS RODOVIARIOS, N.E.; FABRICACAO DE",
  },
  {
    cnae: "2943300",
    descricao: "TAMBOR DO FREIO PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "29441",
    descricao:
      "FABRICACAO DE PECAS E ACESSORIOS PARA O SISTEMA DE DIRECAO E SUSPENSAO DE VEICULOS AUTOMOTORES",
  },
  {
    cnae: "2944100",
    descricao: "MECANISMOS DE DIRECAO PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao: "EIXO DO BALANCIN PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao: "BARRA ESTABILIZADORA PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao:
      "ESTABILIZADORES DIANTEIROS OU TRASEIROS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao:
      "PECAS E ACESSORIOS PARA OS SISTEMAS DE DIRECAO E SUSPENSAO PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao: "CAIXAS DE DIRECAO PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao:
      "CONJUNTO DE SUSPENSAO DIANTEIRA PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao:
      "EIXOS DIANTEIROS COMPLETOS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao: "FEIXES DE MOLAS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao:
      "ARTICULADORES DA ARVORE DE DIRECAO PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao:
      "CONJUNTO DE SUSPENSAO TRASEIRA PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao:
      "LAMINAS PARA FEIXES DE MOLAS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao:
      "PECAS DE AMORTECEDORES PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao: "VOLANTES DE DIRECAO PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao: "EIXOS TRASEIROS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao:
      "MOLAS DE TODOS OS TIPOS PARA VEICULOS RODOVIARIOS (EXCETO FEIXES DE MOLAS); FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao:
      "BARRAS DO SISTEMA DE DIRECAO PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao: "BARRA DE TORCAO PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao:
      "SISTEMAS DE DIRECAO E SUSPENSAO COMPLETOS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2944100",
    descricao:
      "AMORTECEDORES (EXCETO DE BORRACHA) PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "29450",
    descricao:
      "FABRICACAO DE MATERIAL ELETRICO E ELETRONICO PARA VEICULOS AUTOMOTORES, EXCETO BATERIAS",
  },
  {
    cnae: "2945000",
    descricao: "COMANDOS ELETRICOS PARA TRAVAMENTOS DE PORTAS; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao:
      "PAINEIS OU QUADRO DE INSTRUMENTOS (COMPLETOS) PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao:
      "COMANDOS ELETRICOS PARA MOVIMENTACAO DE VIDROS DE VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao:
      "MOTORES DE PARTIDA (ARRANQUE) PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao: "ISQUEIROS ELETRICOS PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao:
      "REGULADORES DE VOLTAGEM PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao: "BOBINAS DE IGNICAO PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao:
      "LIMPADORES DE PARA-BRISAS PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao:
      "UNIDADE DE CONTROLE PARA INJECAO ELETRONICA PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao: "SISTEMAS DE PARTIDA PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao: "BUZINAS PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao: "DISTRIBUIDORES PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao: "INJECAO ELETRONICA AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao: "DINAMOS E GERADORES PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao: "PLATINADOS PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao:
      "COMANDOS ELETRICOS PARA MOVIMENTACAO DE ANTENAS DE VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao: "VELAS DE IGNICAO PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao:
      "CONTROLADORES ELETRONICOS AUTOMATICOS PARA VEICULOS AUTOMOTORES (IGNICAO, INJECAO, SUSPENSAO, TRANSMISSAO)",
  },
  {
    cnae: "2945000",
    descricao: "CONDENSADORES PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao: "RELES PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao:
      "UNIDADE DE CONTROLE PARA IGNICAO ELETRONICA PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao:
      "COMPONENTES ELETRICOS DE IGNICAO PARA MOTORES DE VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao: "ALTERNADORES PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao:
      "MATERIAL ELETRICO PARA VEICULOS AUTOMOTORES (EXCETO BATERIAS); FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao:
      "APARELHOS DE SINALIZACAO ACUSTICA PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao: "FAROIS PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao:
      "JOGOS DE FIOS PARA VELAS DE IGNICAO E OUTROS CHICOTES ELETRICOS PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao:
      "PARTES DE OUTROS MATERIAIS ELETRICOS, NAO ESPECIFICADOS, PARA MOTORES E PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2945000",
    descricao:
      "PECAS E ACESSORIOS ELETRICOS PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "29492",
    descricao:
      "FABRICACAO DE PECAS E ACESSORIOS PARA VEICULOS AUTOMOTORES NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "2949201",
    descricao:
      "PARTES E PECAS DE QUALQUER MATERIAL PARA ASSENTOS DE VEICULOS RODOVIARIOS, FABRICACAO DE",
  },
  {
    cnae: "2949201",
    descricao:
      "BANCOS E ESTOFADOS DE QUALQUER MATERIAL PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2949201",
    descricao:
      "PARTES OU PECAS, INCLUSIVE MODULARES, DE QUALQUER MATERIAL PARA BANCOS DE VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao:
      "SISTEMA DE INSUFLACAO E OUTRAS PARTES PARA AIRBAGS DE VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao: "CINTOS DE SEGURANCA, PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao: "PARA-LAMAS PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao: "TUBOS DE ESCAPE PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao: "ALAVANCAS DE MUDANCA PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao: "CHASSIS SEM MOTOR PARA CAMINHOES E ONIBUS; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao: "PEDAIS DE ACELERADOR PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao: "PORTAS PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao:
      "SILENCIOSOS PARA CANOS DE DESCARGA DE VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao:
      "PECAS E ACESSORIOS NAO-ELETRICOS, N.E., PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao: "CANOS DE DESCARGA PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao:
      "CATALISADOR DE GASES DE ESCAPAMENTO PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao:
      "TANQUES DE COMBUSTIVEIS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao:
      "AIRBAGS (BOLSAS INFLAVEIS DE SEGURANCA COM SISTEMA DE INSUFLACAO); FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao: "RODAS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao:
      "CALOTAS METALICAS PARA RODAS DE VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao:
      "DISPOSITIVOS PARA COMANDO DE ACELERADOR, MARCHA, ETC, DO TIPO DOS UTILIZADOS POR PESSOAS INCAPACITADAS; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao:
      "PAINEIS OU QUADROS (INCOMPLETOS) PARA INSTRUMENTOS DOS VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao:
      "PECAS E ACESSORIOS PARA CARROCERIAS DE VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao: "PARA-CHOQUES PARA VEICULOS AUTOMOTORES; FABRICACAO DE",
  },
  {
    cnae: "2949299",
    descricao: "TRUQUES COMPLETOS PARA VEICULOS RODOVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "29506",
    descricao:
      "RECONDICIONAMENTO E RECUPERACAO DE MOTORES PARA VEICULOS AUTOMOTORES",
  },
  {
    cnae: "2950600",
    descricao:
      "MOTORES DE VEICULOS RODOVIARIOS (RECONSTRUCAO, RECUPERACAO); SERVICOS DE",
  },
  {
    cnae: "2950600",
    descricao: "RECUPERACAO DE MOTORES DE VEICULOS RODOVIARIOS; SERVICO DE",
  },
  {
    cnae: "2950600",
    descricao:
      "MOTORES DE VEICULOS RODOVIARIOS, RECUPERADOS, REMANUFATURADOS,RECONDICIONADOS OU RECONSTRUIDOS",
  },
  {
    cnae: "2950600",
    descricao:
      "RECONSTRUCAO, REMANUFATURAMENTO E SERVICOS SEMELHANTES EM MOTORES DE VEICULOS RODOVIARIOS; SERVICO DE",
  },
  {
    cnae: "30113",
    descricao: "CONSTRUCAO DE EMBARCACOES E ESTRUTURAS FLUTUANTES",
  },
  {
    cnae: "3011301",
    descricao: "NAVIOS DE PASSAGEIRO (GRANDE PORTE), CONSTRUCAO DE",
  },
  {
    cnae: "3011301",
    descricao: "CASCOS PARA EMBARCACOES DE GRANDE PORTE, CONSTRUCAO DE",
  },
  {
    cnae: "3011301",
    descricao:
      "PLATAFORMAS MARITIMAS PARA TORRE DE PERFURACAO DE PETROLEO, CONSTRUCAO DE",
  },
  {
    cnae: "3011301",
    descricao: "NAVIOS PETROLEIROS (GRANDE PORTE), CONSTRUCAO DE",
  },
  { cnae: "3011301", descricao: "NAVIOS GRANELEIROS; CONSTRUCAO DE" },
  {
    cnae: "3011301",
    descricao:
      "BARCOS-FAROIS, BARCOS-BOMBAS, BARCOS DE COMBATE A INCENDIO, GUINDASTES FLUTUANTES E EMBARCACOES SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "3011301",
    descricao: "NAVIOS CARGUEIROS CONVENCIONAIS, CONSTRUCAO DE",
  },
  {
    cnae: "3011301",
    descricao:
      "PLATAFORMAS DE PERFURACAO OU DE EXPLORACAO, FLUTUANTES OU SUBMERSIVEIS; FABRICACAO DE",
  },
  {
    cnae: "3011301",
    descricao: "NAVIOS DE CARGA (GRANDE PORTE), CONSTRUCAO DE",
  },
  {
    cnae: "3011301",
    descricao:
      "NAVIOS-HOSPITAIS, NAVIOS DE GUERRA, EMBARCACOES PARA PESQUISA CIENTIFICA E OUTRAS EMBARCACOES SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "3011301",
    descricao:
      "NAVIOS MODERNIZADOS OU NAVIOS TRANSFORMADOS (EM UNIDADES ESTACIONARIAS DE PRODUCAO, EM OUTRO TIPO DE NAVIO, ETC)",
  },
  { cnae: "3011301", descricao: "NAVIOS TANQUE, CONSTRUCAO DE" },
  {
    cnae: "3011301",
    descricao:
      "EMBARCACOES DE OUTROS TIPOS PARA O TRANSPORTE DE CARGAS OU PESSOAS (TRANSAATLANTICOS, FERRY-BOATS, GRANELEIROS E OUTROS CARGUEIROS SEMELHANTES, ETC.); FABRICACAO DE",
  },
  { cnae: "3011301", descricao: "ESTRUTURAS PARA NAVIOS, CONSTRUCAO DE" },
  {
    cnae: "3011301",
    descricao:
      "EMBARCACOES DE GRANDE PORTE (NAVIOS DE CARGA, PASSAGEIROS, PETROLEIROS E OUTROS), N.E., CONSTRUCAO DE",
  },
  {
    cnae: "3011301",
    descricao: "CHATAS, BARCAS, PONTOES E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "3011302",
    descricao:
      "BARCOS PESQUEIROS (PARA PESCA DE LINHA, CAMARONEIROS, TRAINEIRAS, LAGOSTEIROS, ETC), CONSTRUCAO DE",
  },
  {
    cnae: "3011302",
    descricao: "DESEMBARCADOUROS (ESTRUTURAS FLUTUANTES), CONSTRUCAO DE",
  },
  {
    cnae: "3011302",
    descricao:
      "ESTRUTURAS FLUTUANTES (DESEMBARCADOUROS, DIQUES, PONTOES, BOIAS E OUTROS), CONSTRUCAO DE",
  },
  {
    cnae: "3011302",
    descricao: "CASCOS PARA EMBARCACOES, EXCETO DE GRANDE PORTE, CONSTRUCAO DE",
  },
  {
    cnae: "3011302",
    descricao: "EMBARCACOES PARA USOS MILITARES, CONSTRUCAO DE",
  },
  { cnae: "3011302", descricao: "REBOCADORES, CONSTRUCAO DE" },
  {
    cnae: "3011302",
    descricao: "EMBARCACOES PARA USOS ESPECIAIS; CONSTRUCAO DE",
  },
  {
    cnae: "3011302",
    descricao: "DIQUES (ESTRUTURAS FLUTUANTES), CONSTRUCAO DE",
  },
  { cnae: "3011302", descricao: "DRAGAS, CONSTRUCAO DE" },
  { cnae: "3011302", descricao: "TRAINEIRAS, CONSTRUCAO DE" },
  {
    cnae: "3011302",
    descricao: "EMBARCACOES DE APOIO MARITIMO A EXPLORACAO DE PETROLEO",
  },
  { cnae: "3011302", descricao: "BARCOS FRIGORIFICO, CONSTRUCAO DE" },
  { cnae: "3011302", descricao: "BARCOS CAMARONEIROS, CONSTRUCAO DE" },
  { cnae: "3011302", descricao: "BARCACAS OU CHATAS, CONSTRUCAO DE" },
  { cnae: "3011302", descricao: "BARCOS-FAROL, CONSTRUCAO DE" },
  {
    cnae: "3011302",
    descricao:
      "PECAS E ACESSORIOS PARA EMBARCACOES DE USO COMERCIAL, PARA TRANSPORTE DE PASSAGEIROS OU USOS MULTIPLOS; FABRICACAO DE",
  },
  { cnae: "3011302", descricao: "BATELOES, CONSTRUCAO DE" },
  {
    cnae: "3011302",
    descricao:
      "VEICULOS AERODESLIZANTES (PARA TRANSPORTE DE PASSAGEIROS), CONSTRUCAO DE",
  },
  {
    cnae: "3011302",
    descricao:
      "BARCOS DE BORRACHA - EXCETO PARA ESPORTE E LAZER; FABRICACAO DE",
  },
  {
    cnae: "3011302",
    descricao: "EMBARCACOES PARA USO DO CORPO DE BOMBEIROS, CONSTRUCAO DE",
  },
  {
    cnae: "3011302",
    descricao: "BALSAS INFLAVEIS E DEPOSITOS FLUTUANTES; FABRICACAO DE",
  },
  {
    cnae: "30121",
    descricao: "CONSTRUCAO DE EMBARCACOES PARA ESPORTE E LAZER",
  },
  {
    cnae: "3012100",
    descricao: "BOTES INFLAVEIS PARA ESPORTE E LAZER; FABRICACAO DE",
  },
  {
    cnae: "3012100",
    descricao:
      "IATES E OUTRAS EMBARCACOES DE RECREIO OU ESPORTE, CONSTRUCAO DE",
  },
  { cnae: "3012100", descricao: "BARCOS INFLAVEIS; FABRICACAO DE" },
  { cnae: "3012100", descricao: "PEDALINHOS, CONSTRUCAO DE" },
  { cnae: "3012100", descricao: "CANOAS, CONSTRUCAO DE" },
  { cnae: "3012100", descricao: "CAIAQUES, CONSTRUCAO DE" },
  { cnae: "3012100", descricao: "JET ESQUI; FABRICACAO DE" },
  { cnae: "3012100", descricao: "BOTES, CONSTRUCAO DE" },
  {
    cnae: "3012100",
    descricao: "EMBARCACOES PARA ESPORTE E LAZER, N.E.; CONSTRUCAO DE",
  },
  { cnae: "3012100", descricao: "VELEIROS, CONSTRUCAO DE" },
  { cnae: "3012100", descricao: "BARCOS A VELA, CONSTRUCAO DE" },
  {
    cnae: "3012100",
    descricao:
      "PECAS E ACESSORIOS PARA EMBARCACOES PARA ESPORTE E LAZER; FABRICACAO DE",
  },
  { cnae: "3012100", descricao: "LANCHAS, CONSTRUCAO DE" },
  {
    cnae: "30318",
    descricao: "FABRICACAO DE LOCOMOTIVAS, VAGOES E OUTROS MATERIAIS RODANTES",
  },
  {
    cnae: "3031800",
    descricao: "VAGOES PARA VEICULOS FERROVIARIOS; CONSTRUCAO DE",
  },
  {
    cnae: "3031800",
    descricao: "DRESINA (VEICULO FERROVIARIO); FABRICACAO DE",
  },
  { cnae: "3031800", descricao: "LOCOMOTIVAS, N.E., CONSTRUCAO DE" },
  { cnae: "3031800", descricao: "VAGOES TANQUES, CONSTRUCAO DE" },
  { cnae: "3031800", descricao: "VAGOES FRIGORIFICOS, CONSTRUCAO DE" },
  {
    cnae: "3031800",
    descricao:
      "VEICULOS PARA INSPECAO E MANUTENCAO DE VIAS FERREAS (VAGOES-OFICINA, VAGOES-GUINDASTES, ETC); FABRICACAO DE",
  },
  {
    cnae: "3031800",
    descricao: "CARROS-MOTOR (VEICULO FERROVIARIO), CONSTRUCAO DE",
  },
  { cnae: "3031800", descricao: "VAGOES DE PASSAGEIRO, CONSTRUCAO DE" },
  { cnae: "3031800", descricao: "VAGOES DE CARGA, CONSTRUCAO DE" },
  { cnae: "3031800", descricao: "LITORINAS (AUTOMOTORAS); FABRICACAO DE" },
  { cnae: "3031800", descricao: "LOCOMOTIVAS E LOCOTRATORES, CONSTRUCAO DE" },
  {
    cnae: "30326",
    descricao: "FABRICACAO DE PECAS E ACESSORIOS PARA VEICULOS FERROVIARIOS",
  },
  {
    cnae: "3032600",
    descricao: "SAPATAS PARA FREIOS PARA VEICULOS FERROVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "3032600",
    descricao:
      "APARELHOS OU EQUIPAMENTOS MECANICOS OU ELETRO-MECANICOS PARA SINALIZACAO OU SEGURANCA EM FERROVIAS; FABRICACAO DE",
  },
  {
    cnae: "3032600",
    descricao:
      "POLTRONAS, BANCOS E OUTROS ASSENTOS PARA VEICULOS FERROVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "3032600",
    descricao: "MATERIAL FIXO DE VIAS FERREAS E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "3032600",
    descricao: "PECAS E ACESSORIOS PARA VEICULOS FERROVIARIOS; FABRICACAO DE",
  },
  {
    cnae: "3032600",
    descricao: "RODAS E EIXOS PARA VEICULOS FERROVIARIOS; FABRICACAO DE",
  },
  { cnae: "30415", descricao: "FABRICACAO DE AERONAVES" },
  { cnae: "3041500", descricao: "SONDAS (VEICULOS ESPACIAIS), CONSTRUCAO DE" },
  { cnae: "3041500", descricao: "HELICOPTEROS, CONSTRUCAO DE" },
  { cnae: "3041500", descricao: "ASAS DELTA, CONSTRUCAO DE" },
  {
    cnae: "3041500",
    descricao:
      "AVIOES OU OUTROS VEICULOS AEREOS DE PESO SUPERIOR A 15.000 KG, VAZIOS; FABRICACAO DE",
  },
  {
    cnae: "3041500",
    descricao: "AERONAVES PARA PASSAGEIROS, CONSTRUCAO E MONTAGEM DE",
  },
  { cnae: "3041500", descricao: "VEICULOS ESPACIAIS; CONSTRUCAO DE" },
  { cnae: "3041500", descricao: "SATELITES, CONSTRUCAO DE" },
  {
    cnae: "3041500",
    descricao:
      "AVIOES OU OUTROS VEICULOS AEREOS DE PESO SUPERIOR A 2.000 KG E MENOR OU IGUAL A 15.000 KG, VAZIOS; FABRICACAO DE",
  },
  {
    cnae: "3041500",
    descricao:
      "AVIOES OU OUTROS VEICULOS AEREOS DE PESO MENOR OU IGUAL A 2.000 KG, VAZIOS; FABRICACAO DE",
  },
  {
    cnae: "3041500",
    descricao: "AERONAVES PARA FINS MILITARES, CONSTRUCAO E MONTAGEM DE",
  },
  { cnae: "3041500", descricao: "BALOES METEOROLOGICOS, CONSTRUCAO DE" },
  {
    cnae: "3041500",
    descricao: "AERONAVES PARA ESPORTE, CONSTRUCAO E MONTAGEM DE",
  },
  { cnae: "3041500", descricao: "AERONAVES, MONTAGEM DE" },
  {
    cnae: "3041500",
    descricao:
      "PLANADORES, ASAS-DELTA E OUTRAS AERONAVES SEMELHANTES; CONSTRUCAO DE",
  },
  {
    cnae: "30423",
    descricao:
      "FABRICACAO DE TURBINAS, MOTORES E OUTROS COMPONENTES E PECAS PARA AERONAVES",
  },
  {
    cnae: "3042300",
    descricao: "HELICES, ROTORES E SUAS PARTES PARA AERONAVES; FABRICACAO DE",
  },
  {
    cnae: "3042300",
    descricao:
      "PROPULSORES A REACAO, PARA AERONAVES - EXCETO TURBORREATORES; FABRICACAO DE",
  },
  { cnae: "3042300", descricao: "MOTORES PARA AERONAVES; CONSTRUCAO DE" },
  {
    cnae: "3042300",
    descricao: "PECAS E ACESSORIOS PARA MOTORES DE AERONAVES; FABRICACAO DE",
  },
  { cnae: "3042300", descricao: "TURBINAS PARA AERONAVES; CONSTRUCAO DE" },
  {
    cnae: "3042300",
    descricao: "ASSENTOS EJETAVEIS PARA AERONAVES; FABRICACAO DE",
  },
  {
    cnae: "3042300",
    descricao:
      "TURBORREATORES E TURBOPROPULSORES (TURBINAS) PARA AERONAVES; FABRICACAO DE",
  },
  {
    cnae: "3042300",
    descricao: "POLTRONAS E OUTROS ASSENTOS PARA AERONAVES, FABRICACAO DE",
  },
  {
    cnae: "3042300",
    descricao:
      "LIMPADORES DE PARA-BRISAS ELETRICOS; ACUMULADORES HIDRAULICOS PARA AERONAVES, FABRICACAO DE",
  },
  {
    cnae: "3042300",
    descricao: "PECAS E ACESSORIOS ELETRICOS PARA AERONAVES; FABRICACAO DE",
  },
  {
    cnae: "3042300",
    descricao: "PARTES, PECAS E ACESSORIOS PARA AERONAVES; FABRICACAO DE",
  },
  {
    cnae: "3042300",
    descricao:
      "PARTES E PECAS DE TURBORREATORES E TURBOPROPULSORES PARA AERONAVES; FABRICACAO DE",
  },
  { cnae: "30504", descricao: "FABRICACAO DE VEICULOS MILITARES DE COMBATE" },
  { cnae: "3050400", descricao: "TANQUES DE GUERRA; CONSTRUCAO DE" },
  {
    cnae: "3050400",
    descricao: "VEICULOS MILITARES DE COMBATE; CONSTRUCAO DE",
  },
  {
    cnae: "3050400",
    descricao: "VEICULOS ANFIBIOS BLINDADOS PARA COMBATE; CONSTRUCAO DE",
  },
  { cnae: "30911", descricao: "FABRICACAO DE MOTOCICLETAS" },
  { cnae: "3091101", descricao: "MOTONETAS; FABRICACAO DE" },
  { cnae: "3091101", descricao: "CICLOMOTORES COM MOTOR; FABRICACAO DE" },
  {
    cnae: "3091101",
    descricao:
      "MOTOCICLOS (MOTOCICLETAS, MOTOS, MOTONETAS E SEMELHANTES); FABRICACAO DE",
  },
  { cnae: "3091101", descricao: "SIDE-CARS; FABRICACAO DE" },
  { cnae: "3091101", descricao: "TRICICLOS COM MOTOR; FABRICACAO DE" },
  { cnae: "3091101", descricao: "MOTOCICLETAS; FABRICACAO DE" },
  {
    cnae: "3091102",
    descricao: "MOTORES, PECAS E ACESSORIOS PARA MOTOCICLETAS; FABRICACAO DE",
  },
  {
    cnae: "30920",
    descricao: "FABRICACAO DE BICICLETAS E TRICICLOS NAOMOTORIZADOS",
  },
  {
    cnae: "3092000",
    descricao:
      "PECAS E ACESSORIOS PARA CADEIRAS DE RODAS E OUTROS VEICULOS PARA INVALIDOS; FABRICACAO DE",
  },
  {
    cnae: "3092000",
    descricao:
      "PARTES, PECAS E ACESSORIOS PARA CARRINHOS E VEICULOS SEMELHANTES PARA BEBE; FABRICACAO DE",
  },
  {
    cnae: "3092000",
    descricao:
      "PECAS E ACESSORIOS PARA BICICLETAS E TRICICLOS NAO MOTORIZADOS; FABRICACAO DE",
  },
  {
    cnae: "3092000",
    descricao:
      "FREIOS, CUBOS DE FREIOS E SUAS PARTES, PARA BICICLETAS; FABRICACAO DE",
  },
  { cnae: "3092000", descricao: "AROS E RAIOS PARA BICICLETAS; FABRICACAO DE" },
  { cnae: "3092000", descricao: "BICICLETAS SEM MOTOR; FABRICACAO DE" },
  { cnae: "3092000", descricao: "CARRINHOS PARA BEBE; FABRICACAO DE" },
  { cnae: "3092000", descricao: "TRICICLOS SEM MOTOR; FABRICACAO DE" },
  {
    cnae: "3092000",
    descricao:
      "CADEIRAS DE RODAS E OUTROS VEICULOS PARA DEFICIENTES FISICOS COM OU SEM MOTOR; FABRICACAO DE",
  },
  {
    cnae: "30997",
    descricao:
      "FABRICACAO DE EQUIPAMENTOS DE TRANSPORTE NAO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "3099700", descricao: "CARROS DE TRACAO ANIMAL; FABRICACAO DE" },
  {
    cnae: "3099700",
    descricao: "CARRINHOS DE MAO - INCLUSIVE JIRICA; FABRICACAO DE",
  },
  {
    cnae: "3099700",
    descricao: "PECAS E ACESSORIOS PARA OUTROS VEICULOS; FABRICACAO DE",
  },
  { cnae: "3099700", descricao: "CARRINHOS INDUSTRIAIS DE ACO; FABRICACAO DE" },
  {
    cnae: "3099700",
    descricao:
      "CARRINHOS TERMICOS PARA TRANSPORTE DE QUALQUER PRODUTO; FABRICACAO DE",
  },
  { cnae: "3099700", descricao: "JIRICA (CARRINHO DE MAO); FABRICACAO DE" },
  {
    cnae: "3099700",
    descricao: "VEICULOS DE TRACAO ANIMAL, N.E.; FABRICACAO DE",
  },
  { cnae: "3099700", descricao: "CARRETAS DE TRACAO ANIMAL; FABRICACAO DE" },
  { cnae: "3099700", descricao: "CARRINHOS PARA SUPERMERCADO; FABRICACAO DE" },
  { cnae: "3099700", descricao: "CARROCAS; FABRICACAO DE" },
  {
    cnae: "31012",
    descricao: "FABRICACAO DE MOVEIS COM PREDOMINANCIA DE MADEIRA",
  },
  { cnae: "3101200", descricao: "COMODAS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "3101200",
    descricao:
      "GONDOLAS E OUTRAS INSTALACOES COMERCIAIS SEMELHANTES DE MADEIRA",
  },
  { cnae: "3101200", descricao: "CAMAS DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "CAMISEIROS (MOVEIS); FABRICACAO DE" },
  { cnae: "3101200", descricao: "VITRINES DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "3101200",
    descricao: "COMODAS OU CAMISEIROS DE MADEIRA; FABRICACAO DE",
  },
  { cnae: "3101200", descricao: "BICAMA DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "MESAS DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "PRATELEIRAS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "3101200",
    descricao:
      "MOVEIS MODULADOS DE MADEIRA PARA ESCRITORIO, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  {
    cnae: "3101200",
    descricao: "MODULOS DE MADEIRA, ESTOFADOS; FABRICACAO DE",
  },
  {
    cnae: "3101200",
    descricao: "ARMARIOS MODULADOS DE MADEIRA; FABRICACAO DE",
  },
  { cnae: "3101200", descricao: "MOVEIS DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "BANCADAS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "3101200",
    descricao: "ESTRADOS DE MADEIRA PARA CAMAS; FABRICACAO DE",
  },
  { cnae: "3101200", descricao: "GUARDA-ROUPA DE MADEIRA; FABRICACAO DE" },
  { cnae: "9312300", descricao: "CLUBE DE BASQUETE" },
  {
    cnae: "3101200",
    descricao: "MESINHAS DE CABECEIRA DE MADEIRA; FABRICACAO DE",
  },
  { cnae: "3101200", descricao: "COZINHA MODULADA DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "DUPLEX DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "DORMITORIO DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "3101200",
    descricao:
      "COMPONENTES, PARTES E PECAS DE MADEIRA PARA MOVEIS (PORTAS, LATERAIS, PRATELEIRAS E SEMELHANTES); FABRICACAO DE",
  },
  {
    cnae: "3101200",
    descricao: "ESTANTE DE MADEIRA PARA BEBIDAS; FABRICACAO DE",
  },
  { cnae: "3101200", descricao: "SALAS (EM MADEIRA); FABRICACAO DE" },
  { cnae: "3101200", descricao: "RACK DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "CADEIRA LONGARINA DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "3101200",
    descricao: "CAIXAS DE MADEIRA PARA COLETA DE ROUPA SUJA; FABRICACAO DE",
  },
  {
    cnae: "3101200",
    descricao:
      "CAIXAS E GABINETES DE MADEIRA PARA RADIOS, TELEVISORES, MAQUINAS DE COSTURA, ETC; FABRICACAO DE",
  },
  {
    cnae: "3101200",
    descricao: "PRATELEIRAS DECORATIVAS DE MADEIRA; FABRICACAO DE",
  },
  {
    cnae: "3101200",
    descricao:
      "MOVEIS DE MADEIRA DE USO RESIDENCIAL, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  { cnae: "3101200", descricao: "BAU; FABRICACAO DE" },
  {
    cnae: "3101200",
    descricao: "MOVEIS MODULADOS DE MADEIRA PARA COZINHAS; FABRICACAO DE",
  },
  { cnae: "3101200", descricao: "BELICHE DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "CRIADO-MUDO DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "CRISTALEIRA DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "MARCENARIA ( FABRICACAO  DE MOVEIS )" },
  { cnae: "3101200", descricao: "ESTANTES DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "MAPOTECAS DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "CAMAS-BELICHE DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "PANELEIRO; FABRICACAO DE" },
  {
    cnae: "3101200",
    descricao:
      "MOVEIS DIVERSOS DE MADEIRA PARA ESCRITORIO, EXCETO MODULADOS; FABRICACAO DE",
  },
  {
    cnae: "3101200",
    descricao: "ARMARIOS E OUTROS MOVEIS EMBUTIDOS, DE MADEIRA; FABRICACAO DE",
  },
  {
    cnae: "3101200",
    descricao:
      "MONTAGEM E ACABAMENTO DE MOVEIS DE MADEIRA, ASSOCIADOS A FABRICACAO DE MOVEIS; SERVICO DE",
  },
  { cnae: "3101200", descricao: "MOVEIS EMBUTIDOS DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "CADEIRAS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "3101200",
    descricao: "POLTRONA DE MADEIRA (INCLUSIVE RECLINAVEL); FABRICACAO DE",
  },
  { cnae: "3101200", descricao: "CAMA-BAU EM MADEIRA; FABRICACAO DE" },
  {
    cnae: "3101200",
    descricao: "MESAS E PRANCHETAS DE MADEIRA PARA DESENHO; FABRICACAO DE",
  },
  {
    cnae: "3101200",
    descricao: "ESQUELETOS DE MADEIRA PARA MOVEIS; FABRICACAO DE",
  },
  {
    cnae: "3101200",
    descricao: "CADEIRAS DE MADEIRA PARA PRAIA; FABRICACAO DE",
  },
  { cnae: "3101200", descricao: "CONSOLES DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "3101200",
    descricao:
      "CADEIRAS DE MADEIRA PARA BARBEIROS E CABELELEIROS; FABRICACAO DE",
  },
  { cnae: "3101200", descricao: "BERCOS DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "MESINHA LATERAL DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "3101200",
    descricao: "CARTEIRAS E BANCOS ESCOLARES DE MADEIRA; FABRICACAO DE",
  },
  { cnae: "3101200", descricao: "BANCOS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "3101200",
    descricao:
      "MOVEIS DIVERSOS DE MADEIRA PARA INSTALACOES COMERCIAIS, PARA ESCOLAS, IGREJAS, OFICINAS E OUTRAS INSTALACOES SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "3101200", descricao: "CADEIRA GIRATORIA DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "ESTANTE RACK DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "SOFA-CAMA DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "3101200",
    descricao: "CARRINHOS DE MADEIRA, PARA CHA; FABRICACAO DE",
  },
  {
    cnae: "3101200",
    descricao: "COZINHAS PLANEJADAS (EM MADEIRA); FABRICACAO DE",
  },
  {
    cnae: "3101200",
    descricao: "ARCAS DE MADEIRA DE USO RESIDENCIAL; FABRICACAO DE",
  },
  {
    cnae: "3101200",
    descricao: "BALCOES DE MADEIRA SEM INSTALACOES FRIGORIFICAS; FABRICACAO DE",
  },
  {
    cnae: "3101200",
    descricao: "BANCOS E BANQUETAS DE MADEIRA; FABRICACAO DE",
  },
  { cnae: "3101200", descricao: "BI-BOX (BICAMA) EM MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "ROUPEIRO DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "3101200",
    descricao:
      "PARTES E PECAS PARA ASSENTOS E CADEIRAS DE MADEIRA, EXCETO PARA VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "3101200",
    descricao: "BANHEIROS PLANEJADOS (EM MADEIRA); FABRICACAO DE",
  },
  { cnae: "3101200", descricao: "ARQUIVOS DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "3101200",
    descricao:
      "MOVEIS DE MADEIRA PARA COZINHAS, EXCETO MODULADOS; FABRICACAO DE",
  },
  { cnae: "3101200", descricao: "SAPATEIRO DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "CONJUNTO ESTOFADO DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "ARMARIOS DE MADEIRA; FABRICACAO DE" },
  { cnae: "3101200", descricao: "CONJUNTO DE COPA, DE MADEIRA; FABRICACAO DE" },
  {
    cnae: "31021",
    descricao: "FABRICACAO DE MOVEIS COM PREDOMINANCIA DE METAL",
  },
  { cnae: "3102100", descricao: "MOVEIS TUBULARES (ACO); FABRICACAO DE" },
  { cnae: "3102100", descricao: "PRATELEIRAS DE METAL; FABRICACAO DE" },
  {
    cnae: "3102100",
    descricao: "POLTRONAS METALICAS PARA VEICULOS; FABRICACAO DE",
  },
  { cnae: "3102100", descricao: "BANCADAS METALICAS; FABRICACAO DE" },
  { cnae: "3102100", descricao: "GABINETES DE METAL; FABRICACAO DE" },
  {
    cnae: "3102100",
    descricao: "PECAS E ARMACOES METALICAS PARA MOVEIS; FABRICACAO DE",
  },
  { cnae: "3102100", descricao: "ARQUIVOS METALICOS; FABRICACAO DE" },
  {
    cnae: "3102100",
    descricao:
      "PARTES E PECAS DE METAL PARA ASSENTOS E CADEIRAS DE METAL, EXCETO PARA VEICULOS; FABRICACAO DE",
  },
  { cnae: "3102100", descricao: "CADEIRA LONGARINA DE METAL; FABRICACAO DE" },
  { cnae: "3102100", descricao: "BICAMA METALICA; FABRICACAO DE" },
  { cnae: "3102100", descricao: "BANCOS METALICOS; FABRICACAO DE" },
  {
    cnae: "3102100",
    descricao:
      "MOVEIS MODULADOS DE METAL PARA ESCRITORIO, NAO ESPECIFICADOS; FABRICACAO DE",
  },
  { cnae: "3102100", descricao: "LATERAL (MESINHA) DE METAL; FABRICACAO DE" },
  { cnae: "3102100", descricao: "CAMAS METALICAS; FABRICACAO DE" },
  {
    cnae: "3102100",
    descricao: "MESAS DE METAL PARA ESCRITORIO; FABRICACAO DE",
  },
  { cnae: "3102100", descricao: "BERCOS DE METAL; FABRICACAO DE" },
  { cnae: "3102100", descricao: "BELICHE DE FERRO TUBULAR; FABRICACAO DE" },
  { cnae: "3102100", descricao: "CADEIRAS METALICAS; FABRICACAO DE" },
  {
    cnae: "3102100",
    descricao: "POLTRONA DE METAL (INCLUSIVE RECLINAVEL); FABRICACAO DE",
  },
  { cnae: "3102100", descricao: "ESTANTES METALICAS; FABRICACAO DE" },
  {
    cnae: "3102100",
    descricao:
      "CADEIRAS METALICAS PARA BARBEIROS E CABELELEIROS; FABRICACAO DE",
  },
  { cnae: "3102100", descricao: "MESAS METALICAS; FABRICACAO DE" },
  { cnae: "3102100", descricao: "MOVEIS DE ACO; FABRICACAO DE" },
  { cnae: "3102100", descricao: "CADEIRA GIRATORIA DE METAL; FABRICACAO DE" },
  {
    cnae: "3102100",
    descricao: "MOVEIS DIVERSOS DE METAL PARA ESCRITORIO; FABRICACAO DE",
  },
  { cnae: "3102100", descricao: "DORMITORIO METALICO; FABRICACAO DE" },
  { cnae: "3102100", descricao: "CONJUNTO COPA, DE METAL; FABRICACAO DE" },
  {
    cnae: "3102100",
    descricao: "CADEIRA PARA BEBE, PARA VEICULO; FABRICACAO DE",
  },
  {
    cnae: "3102100",
    descricao: "GONDOLAS E OUTRAS INSTALACOES COMERCIAIS SEMELHANTES DE METAL",
  },
  {
    cnae: "3102100",
    descricao: "BALCOES METALICOS SEM INSTALACOES FRIGORIFICAS; FABRICACAO DE",
  },
  {
    cnae: "3102100",
    descricao: "BANHEIROS PLANEJADOS (EM METAL); FABRICACAO DE",
  },
  {
    cnae: "3102100",
    descricao:
      "POLTRONAS E SOFAS METALICOS, EXCETO PARA ESCRITORIO; FABRICACAO DE",
  },
  {
    cnae: "3102100",
    descricao: "CARRINHOS DE METAL PARA CHA OU BEBIDAS; FABRICACAO DE",
  },
  { cnae: "3102100", descricao: "BALCOES E VITRINES DE METAL; FABRICACAO DE" },
  { cnae: "3102100", descricao: "CADEIRAS DE METAL PARA PRAIA; FABRICACAO DE" },
  { cnae: "3102100", descricao: "RACK METALICO; FABRICACAO DE" },
  {
    cnae: "3102100",
    descricao:
      "MONTAGEM E ACABAMENTO DE MOVEIS DE METAL, ASSOCIADO A FABRICACAO DE MOVEIS; SERVICO DE",
  },
  { cnae: "3102100", descricao: "MOVEIS AVULSOS DE METAL; FABRICACAO DE" },
  {
    cnae: "3102100",
    descricao: "CAMA DOBRAVEL EM FERRO TUBULAR; FABRICACAO DE",
  },
  {
    cnae: "3102100",
    descricao: "COZINHAS PLANEJADAS (EM METAL); FABRICACAO DE",
  },
  { cnae: "3102100", descricao: "CONJUNTO ESTOFADO EM METAL; FABRICACAO DE" },
  { cnae: "3102100", descricao: "ESTANTE RACK DE METAL; FABRICACAO DE" },
  {
    cnae: "3102100",
    descricao: "ASSENTOS E CADEIRAS DE METAL PARA ESCRITORIO; FABRICACAO DE",
  },
  { cnae: "3102100", descricao: "SALAS (EM METAL); FABRICACAO DE" },
  {
    cnae: "3102100",
    descricao:
      "MOVEIS DE METAL PARA INSTALACOES HOSPITALARES, N.E.; FABRICACAO DE",
  },
  {
    cnae: "3102100",
    descricao:
      "PARTES E PECAS DE CADEIRAS PARA SALOES DE CABELEIREIRO; FABRICACAO DE",
  },
  { cnae: "3102100", descricao: "ARMARIOS METALICOS; FABRICACAO DE" },
  {
    cnae: "31039",
    descricao:
      "FABRICACAO DE MOVEIS DE OUTROS MATERIAIS, EXCETO MADEIRA E METAL",
  },
  {
    cnae: "3103900",
    descricao:
      "CAIXAS E GABINETES DE PLASTICO PARA RADIOS, TELEVISORES, MAQUINAS DE COSTURA, ETC; FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao:
      "PARTES E PECAS DE VIME, BAMBU E SEMELHANTES PARA MOVEIS; FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao: "RACK (EXCETO DE METAL OU MADEIRA); FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao: "MESINHA LATERAL (EXCETO DE METAL OU MADEIRA); FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao: "BICAMA (EXCETO DE METAL OU MADEIRA); FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao:
      "ASSENTOS E CADEIRAS DE MATERIAIS NAO ESPECIFICADOS, EXCETO PARA ESCRITORIO; FABRICACAO DE",
  },
  { cnae: "3103900", descricao: "MOVEIS DE MATERIAL PLASTICO; FABRICACAO DE" },
  {
    cnae: "3103900",
    descricao: "CONJUNTO COPA (EXCETO DE METAL OU MADEIRA); FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao: "ARMARIOS DE PLASTICO DE USO RESIDENCIAL; FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao:
      "BANHEIROS PLANEJADOS (EXCETO EM METAL OU MADEIRA); FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao: "CADEIRAS DE PLASTICO PARA PRAIA; FABRICACAO DE",
  },
  { cnae: "3103900", descricao: "BANCOS DE PLASTICO; FABRICACAO DE" },
  {
    cnae: "3103900",
    descricao: "CADEIRAS DE MATERIAL PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao:
      "GONDOLAS E OUTRAS INSTALACOES COMERCIAIS SEMELHANTES DE PLASTICO; FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao: "PARTES DE PLASTICO PARA MOVEIS; FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao:
      "MONTAGEM E ACABAMENTO DE MOVEIS DE MATERIAL PLASTICO, VIME, JUNCO E OUTROS MATERIAIS, ASSOCIADOS A FABRICACAO DE MOVEIS; SERVICO DE",
  },
  {
    cnae: "3103900",
    descricao:
      "ASSENTOS E CADEIRAS DE MATERIAIS NAO ESPECIFICADOS, PARA ESCRITORIO; FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao: "MOVEIS DE VIME, BAMBU, JUNCO E SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "3103900", descricao: "MESAS DE MATERIAL PLASTICO; FABRICACAO DE" },
  {
    cnae: "3103900",
    descricao: "ESTANTE RACK (EXCETO DE METAL OU MADEIRA); FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao: "CONJUNTO ESTOFADO (EXCETO EM METAL OU MADEIRA); FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao: "SALAS (EXCETO EM METAL OU MADEIRA); FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao: "CADEIRA LONGARINA (EXCETO DE MADEIRA OU METAL); FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao:
      "PARTES E PECAS PARA ASSENTOS E CADEIRAS DE MATERIAIS NAO ESPECIFICADOS, EXCETO PARA VEICULOS; FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao: "SOFAS E POLTRONAS DE ESPUMA SINTETICA; FABRICACAO DE",
  },
  {
    cnae: "3103900",
    descricao:
      "COZINHAS PLANEJADAS (EXCETO EM METAL OU MADEIRA); FABRICACAO DE",
  },
  { cnae: "31047", descricao: "FABRICACAO DE COLCHOES" },
  { cnae: "3104700", descricao: "COLCHOES DE MOLAS; FABRICACAO DE" },
  {
    cnae: "3104700",
    descricao:
      "COLCHOES DE BORRACHA, DE PLASTICOS ALVEOLARES OU DE ESPUMAS DE PLASTICO, INCLUSIVE COLCHOES ORTOPEDICOS; FABRICACAO DE",
  },
  {
    cnae: "3104700",
    descricao:
      "SUPORTES ELASTICOS (DE MADEIRA, METAL, ETC.) PARA ARTIGOS DO MOBILIARIO; FABRICACAO DE",
  },
  { cnae: "3104700", descricao: "COLCHOES ANATOMICOS; FABRICACAO DE" },
  { cnae: "3104700", descricao: "COLCHOES; FABRICACAO DE" },
  {
    cnae: "3104700",
    descricao: "COLCHOES DE CRINA, PALHA, PAINA E SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "3104700", descricao: "COLCHOES DE ESPUMA; FABRICACAO DE" },
  {
    cnae: "3104700",
    descricao:
      "COLCHOES DE ALGODAO, CRINA, PENAS OU OUTRAS MATERIAS SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "3104700",
    descricao: "COLCHOES INFLAVEIS (BORRACHA); FABRICACAO DE",
  },
  {
    cnae: "32116",
    descricao:
      "LAPIDACAO DE GEMAS E FABRICACAO DE ARTEFATOS DE OURIVESARIA E JOALHERIA",
  },
  { cnae: "3211601", descricao: "GRANADA, LAPIDACAO DE" },
  { cnae: "3211601", descricao: "PEROLAS TRABALHADAS; FABRICACAO DE" },
  { cnae: "3211601", descricao: "GEMAS E DIAMANTES, LAPIDACAO DE" },
  { cnae: "3211601", descricao: "AGATA, LAPIDACAO DE" },
  {
    cnae: "3211601",
    descricao:
      "PO DE DIAMANTES, DE GEMAS (PEDRAS PRECIOSAS OU SEMIPRECIOSAS) OU DE PEDRAS SINTETICAS; FABRICACAO DE",
  },
  {
    cnae: "3211601",
    descricao:
      "PEROLAS CULTIVADAS TRABALHADAS, EXCETO ENFIADAS, MONTADAS OU ENGASTADAS; FABRICACAO DE",
  },
  { cnae: "3211601", descricao: "CORINDON (PEDRA PRECIOSA), LAPIDACAO DE" },
  {
    cnae: "3211601",
    descricao: "PEDRAS PRECIOSAS E SEMIPRECIOSAS, LAPIDACAO DE",
  },
  {
    cnae: "3211601",
    descricao:
      "DIAMANTES INDUSTRIAIS TRABALHADOS, MAS NAO MONTADOS NEM ENGASTADOS; FABRICACAO DE",
  },
  {
    cnae: "3211601",
    descricao:
      "PEDRAS PRECIOSAS OU SEMIPRECIOSAS SINTETICAS, MESMO TRABALHADAS, MAS NAO ENFIADAS, MONTADAS OU ENGASTADAS; FABRICACAO DE",
  },
  { cnae: "3211601", descricao: "TOPAZIO, LAPIDACAO DE" },
  { cnae: "3211601", descricao: "RUBI, LAPIDACAO DE" },
  {
    cnae: "3211601",
    descricao:
      "DIAMANTES NAO INDUSTRIAIS TRABALHADOS, MAS NAO MONTADOS NEM ENGASTADOS; FABRICACAO DE",
  },
  {
    cnae: "3211601",
    descricao:
      "GEMAS (PEDRAS PRECIOSAS (EXCETO DIAMANTES) OU SEMIPRECIOSAS) TRABALHADAS, MAS NAO ENFIADAS, MONTADAS OU ENGASTADAS; FABRICACAO DE",
  },
  {
    cnae: "3211602",
    descricao:
      "GARFOS, FACAS, COLHERES E OUTROS ARTEFATOS DE CUTELARIA, DE METAIS PRECIOSOS; FABRICACAO DE",
  },
  {
    cnae: "3211602",
    descricao:
      "COLARES, CORDOES E GARGANTILHAS DE METAIS PRECIOSOS; FABRICACAO DE",
  },
  {
    cnae: "3211602",
    descricao:
      "CALICES E TACAS DE OURO E PRATA, PARA FINS RELIGIOSOS; FABRICACAO DE",
  },
  {
    cnae: "3211602",
    descricao:
      "ARTIGOS DE USOS TECNICOS E DE LABORATORIO ELABORADOS COM METAIS PRECIOSOS; FABRICACAO DE",
  },
  {
    cnae: "3211602",
    descricao: "ARTESANATO EM METAIS PRECIOSOS; FABRICACAO DE",
  },
  {
    cnae: "3211602",
    descricao: "ANEIS E ALIANCAS DE METAIS PRECIOSOS; FABRICACAO DE",
  },
  { cnae: "3211602", descricao: "SEMIJOIAS; FABRICACAO DE" },
  {
    cnae: "3211602",
    descricao: "ARTEFATOS PARA COPA E MESA, DE OURO E PRATA; FABRICACAO DE",
  },
  {
    cnae: "3211602",
    descricao: "PULSEIRAS DE METAIS PRECIOSOS; FABRICACAO DE",
  },
  {
    cnae: "3211602",
    descricao: "PULSEIRAS DE METAIS PRECIOSOS PARA RELOGIO; FABRICACAO DE",
  },
  {
    cnae: "3211602",
    descricao: "TACAS COMEMORATIVAS DE METAIS PRECIOSOS; FABRICACAO DE",
  },
  {
    cnae: "3211602",
    descricao: "ARTEFATOS DE JOALHERIA E OURIVESARIA; FABRICACAO DE",
  },
  {
    cnae: "3211602",
    descricao:
      "BRINCOS, BROCHES E PINGENTES DE METAIS PRECIOSOS; FABRICACAO DE",
  },
  { cnae: "3211602", descricao: "TALHERES AVULSOS DE PRATA; FABRICACAO DE" },
  {
    cnae: "3211602",
    descricao: "FAQUEIROS COMPLETOS DE METAL PRECIOSO; FABRICACAO DE",
  },
  { cnae: "3211602", descricao: "JOIAS; FABRICACAO DE" },
  { cnae: "3211603", descricao: "MEDALHAS DE METAL; CUNHAGEM DE" },
  { cnae: "3211603", descricao: "MOEDAS, CUNHAGEM DE" },
  { cnae: "3211603", descricao: "MEDALHAS DE METAIS PRECIOSOS, CUNHAGEM DE" },
  {
    cnae: "32124",
    descricao: "FABRICACAO DE BIJUTERIAS E ARTEFATOS SEMELHANTES",
  },
  {
    cnae: "3212400",
    descricao: "ANEIS E ALIANCAS (EXCETO DE METAIS PRECIOSOS); FABRICACAO DE",
  },
  {
    cnae: "3212400",
    descricao:
      "COLARES, CORDOES E GARGANTILHAS (EXCETO DE METAIS PRECIOSOS); FABRICACAO DE",
  },
  {
    cnae: "3212400",
    descricao:
      "BRINCOS, BROCHES E PINGENTES (EXCETO DE METAIS PRECIOSOS); FABRICACAO DE",
  },
  { cnae: "3212400", descricao: "PULSEIRAS (BIJUTERIAS); FABRICACAO DE" },
  {
    cnae: "3212400",
    descricao: "PULSEIRAS METALICAS PARA RELOGIOS; FABRICACAO DE",
  },
  {
    cnae: "3212400",
    descricao: "ARTIGOS DE BIJUTERIAS (BIJOUTERIAS); FABRICACAO DE",
  },
  { cnae: "32205", descricao: "FABRICACAO DE INSTRUMENTOS MUSICAIS" },
  { cnae: "3220500", descricao: "HARPAS; FABRICACAO DE" },
  { cnae: "3220500", descricao: "CAIXAS DE MUSICA; FABRICACAO DE" },
  {
    cnae: "3220500",
    descricao: "VIOLA, CAVAQUINHO, BANDOLIM, BANJO; FABRICACAO DE",
  },
  {
    cnae: "3220500",
    descricao: "INSTRUMENTOS MUSICAIS DE SOPRO; FABRICACAO DE",
  },
  { cnae: "3220500", descricao: "FLAUTAS, CLARINETA; FABRICACAO DE" },
  { cnae: "3220500", descricao: "METRONOMOS; FABRICACAO DE" },
  {
    cnae: "3220500",
    descricao: "REALEJOS, PASSAROS CANTORES, ETC; FABRICACAO DE",
  },
  { cnae: "3220500", descricao: "APITOS; FABRICACAO DE" },
  { cnae: "3220500", descricao: "GAITAS (HARMONICAS DE BOCA); FABRICACAO DE" },
  { cnae: "3220500", descricao: "SAXOFONE; FABRICACAO DE" },
  {
    cnae: "3220500",
    descricao: "INSTRUMENTOS MUSICAIS DE PERCUSSAO; FABRICACAO DE",
  },
  {
    cnae: "3220500",
    descricao: "PECAS E ACESSORIOS PARA INSTRUMENTOS MUSICAIS; FABRICACAO DE",
  },
  { cnae: "3220500", descricao: "CONTRABAIXO; FABRICACAO DE" },
  {
    cnae: "3220500",
    descricao: "CORDAS PARA INSTRUMENTOS MUSICAIS; FABRICACAO DE",
  },
  {
    cnae: "3220500",
    descricao: "INSTRUMENTOS MUSICAIS DE CORDA; FABRICACAO DE",
  },
  { cnae: "3220500", descricao: "VIOLINO, VIOLONCELO; FABRICACAO DE" },
  { cnae: "3220500", descricao: "SINTETIZADORES; FABRICACAO DE" },
  {
    cnae: "3220500",
    descricao: "ORGAOS E HARMONICAS (INSTRUMENTOS MUSICAIS); FABRICACAO DE",
  },
  { cnae: "3220500", descricao: "BATUTA PARA MAESTRO; FABRICACAO DE" },
  { cnae: "3220500", descricao: "DIAPASOES DE TODOS OS TIPOS; FABRICACAO DE" },
  {
    cnae: "3220500",
    descricao: "INSTRUMENTOS MUSICAIS ELETRONICOS; FABRICACAO DE",
  },
  { cnae: "3220500", descricao: "VIOLAO (VIOLOES); FABRICACAO DE" },
  { cnae: "3220500", descricao: "TROMPETE, CORNETA; FABRICACAO DE" },
  {
    cnae: "3220500",
    descricao: "INSTRUMENTOS MUSICAIS DE TECLADO; FABRICACAO DE",
  },
  { cnae: "3220500", descricao: "TROMPAS E TROMBONES; FABRICACAO DE" },
  { cnae: "3220500", descricao: "PIANOS E CRAVOS; FABRICACAO DE" },
  { cnae: "32302", descricao: "FABRICACAO DE ARTEFATOS PARA PESCA E ESPORTE" },
  { cnae: "3230200", descricao: "BOLAS PARA FUTEBOL; FABRICACAO DE" },
  { cnae: "3230200", descricao: "EQUIPAMENTOS PARA MUSCULACAO; FABRICACAO DE" },
  { cnae: "3230200", descricao: "REDES PARA ESPORTES; FABRICACAO DE" },
  { cnae: "3230200", descricao: "LUVAS DE BOXE; FABRICACAO DE" },
  {
    cnae: "3230200",
    descricao: "PARTES E ACESSORIOS DE PATINS; FABRICACAO DE",
  },
  {
    cnae: "3230200",
    descricao: "ANZOIS PARA PESCA, MESMO MONTADOS; FABRICACAO DE",
  },
  { cnae: "3230200", descricao: "ARTEFATOS PARA ESPORTE, N.E.; FABRICACAO DE" },
  {
    cnae: "3230200",
    descricao: "JOELHEIRAS, TORNOZELEIRAS E CANELEIRAS; FABRICACAO DE",
  },
  { cnae: "3230200", descricao: "BOLAS DE COURO; FABRICACAO DE" },
  { cnae: "3230200", descricao: "APARELHOS PARA GINASTICA; FABRICACAO DE" },
  { cnae: "3230200", descricao: "CAMAS ELASTICAS; FABRICACAO DE" },
  { cnae: "3230200", descricao: "EQUIPAMENTOS DE GINASTICA; FABRICACAO DE" },
  { cnae: "3230200", descricao: "CANICOS PARA PESCA; FABRICACAO DE" },
  { cnae: "3230200", descricao: "PATINS; FABRICACAO DE" },
  { cnae: "3230200", descricao: "BOLAS DE QUALQUER MATERIAL; FABRICACAO DE" },
  { cnae: "3230200", descricao: "BICICLETA ERGOMETRICA; FABRICACAO DE" },
  { cnae: "3230200", descricao: "ARTEFATOS DE CACA E PESCA; FABRICACAO DE" },
  {
    cnae: "3230200",
    descricao:
      "BOLAS PARA ESPORTES (EXCETO GOLFE, TENIS DE MESA E FUTEBOL); FABRICACAO DE",
  },
  {
    cnae: "3230200",
    descricao:
      "TACOS PARA GOLFE, POLO, BEISEBOL E OUTROS ESPORTES; FABRICACAO DE",
  },
  {
    cnae: "3230200",
    descricao: "ESQUIS E OUTROS EQUIPAMENTOS PARA ESQUIAR; FABRICACAO DE",
  },
  {
    cnae: "3230200",
    descricao: "ISCAS ARTIFICIAIS E CHAMARIZES; FABRICACAO DE",
  },
  { cnae: "3230200", descricao: "RAQUETES PARA ESPORTE; FABRICACAO DE" },
  {
    cnae: "3230200",
    descricao: "EQUIPAMENTOS PARA CACA SUBMARINA; FABRICACAO DE",
  },
  { cnae: "3230200", descricao: "ESTEIRA ERGOMETRICA; FABRICACAO DE" },
  { cnae: "3230200", descricao: "PUCAS OU CAMAROEIROS; FABRICACAO DE" },
  { cnae: "3230200", descricao: "CHUMBADAS PARA PESCA; FABRICACAO DE" },
  {
    cnae: "3230200",
    descricao: "ARMADILHAS E PIOS (ARTEFATOS PARA CACA E PESCA); FABRICACAO DE",
  },
  {
    cnae: "3230200",
    descricao: "ARTIGOS E EQUIPAMENTOS PARA ATLETISMO; FABRICACAO DE",
  },
  {
    cnae: "3230200",
    descricao: "LUVAS E CAPACETES PARA PRATICAR ESPORTES; FABRICACAO DE",
  },
  {
    cnae: "3230200",
    descricao:
      "MASCARAS, CAPACETES E PROTETORES DE CABECA PARA ESPORTE; FABRICACAO DE",
  },
  { cnae: "3230200", descricao: "MOLINETES PARA VARA DE PESCA; FABRICACAO DE" },
  { cnae: "3230200", descricao: "PRANCHAS, COM OU SEM VELA; FABRICACAO DE" },
  { cnae: "3230200", descricao: "PISCINAS DE PLASTICO; FABRICACAO DE" },
  {
    cnae: "3230200",
    descricao: "LINHAS PARA PESCA DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  { cnae: "32400", descricao: "FABRICACAO DE BRINQUEDOS E JOGOS RECREATIVOS" },
  { cnae: "3240001", descricao: "FLIPERAMA; FABRICACAO DE" },
  {
    cnae: "3240001",
    descricao: "APARELHOS PARA JOGOS E DIVERSOES ELETRONICOS; FABRICACAO DE",
  },
  {
    cnae: "3240002",
    descricao:
      "MESAS PARA BILHAR, DE SNOOKER E ACESSORIOS, NAO ASSOCIADA A LOCACAO; FABRICACAO DE",
  },
  {
    cnae: "3240002",
    descricao:
      "MESA DE SINUCA E ACESSORIOS, NAO ASSOCIADA A LOCACAO; FABRICACAO DE",
  },
  {
    cnae: "3240003",
    descricao:
      "MESA DE SINUCA E ACESSORIOS, ASSOCIADA A LOCACAO; FABRICACAO DE",
  },
  {
    cnae: "3240003",
    descricao:
      "JOGOS DE SALAO, JOGOS DE COMPETICAO E OUTROS ARTIGOS PARA JOGOS NAO ESPECIFICADOS (MESAS ESPECIAIS PARA JOGOS E SEUS ACESSORIOS, BOLICHES, AUTORAMAS, ETC.); FABRICACAO DE",
  },
  {
    cnae: "3240003",
    descricao:
      "MESAS DE BILHAR, DE SNOOKER E ACESSORIOS, ASSOCIADA A LOCACAO; FABRICACAO DE",
  },
  {
    cnae: "3240099",
    descricao:
      "JOGOS DE DAMA, XADREZ, BINGO, GAMAO, DOMINO, DADOS E OUTROS JOGOS RECREATIVOS; FABRICACAO DE",
  },
  {
    cnae: "3240099",
    descricao: "BRINQUEDOS DE PAPEL E PAPELAO; FABRICACAO DE",
  },
  { cnae: "3240099", descricao: "XADREZ (JOGO); FABRICACAO DE" },
  { cnae: "3240099", descricao: "BRINQUEDOS EDUCATIVOS; FABRICACAO DE" },
  { cnae: "3240099", descricao: "TRICICLOS DE BRINQUEDO; FABRICACAO DE" },
  { cnae: "9411100", descricao: "FEDERACAO PATRONAL" },
  {
    cnae: "3240099",
    descricao:
      "BONECAS E BONECOS DE QUALQUER MATERIAL, MECANIZADOS OU NAO; FABRICACAO DE",
  },
  { cnae: "3240099", descricao: "VELOCIPEDE (BRINQUEDO); FABRICACAO DE" },
  { cnae: "3240099", descricao: "CARRINHOS PARA BONECOS; FABRICACAO DE" },
  { cnae: "3240099", descricao: "TECLADO (BRINQUEDO); FABRICACAO DE" },
  {
    cnae: "3240099",
    descricao:
      "BRINQUEDOS OU CONJUNTOS DE MODELOS RDUZIDOS PARA MONTAGEM OU CONSTRUCAO; FABRICACAO DE",
  },
  { cnae: "3240099", descricao: "ROUPAS DE BONECA; CONFECCAO DE" },
  {
    cnae: "3240099",
    descricao: "INSTRUMENTOS E APARELHOS MUSICAIS DE BRINQUEDO; FABRICACAO DE",
  },
  { cnae: "3240099", descricao: "JOGOS ACIONADOS POR FICHAS; FABRICACAO DE" },
  { cnae: "3240099", descricao: "JOGO DE DAMA; FABRICACAO DE" },
  {
    cnae: "3240099",
    descricao:
      "PIPAS E PAPAGAIOS (BRINQUEDOS), DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  {
    cnae: "3240099",
    descricao:
      "BRINQUEDOS NAO ESPECIFICADOS, DE QUALQUER MATERIAL, MOTORIZADOS, INCLUSIVE ACESSORIOS; FABRICACAO DE",
  },
  {
    cnae: "3240099",
    descricao:
      "PATINETES, VELOCIPEDES, AUTOMOVEIS E OUTROS VEICULOS PARA CRIANCAS; FABRICACAO DE",
  },
  {
    cnae: "3240099",
    descricao: "JOGOS DE MONTAR (BRINQUEDOS EDUCATIVOS); FABRICACAO DE",
  },
  {
    cnae: "3240099",
    descricao:
      "BRINQUEDOS DE QUALQUER MATERIAL, MECANIZADOS OU NAO; FABRICACAO DE",
  },
  { cnae: "3240099", descricao: "CARTAS DE JOGAR (BARALHOS); FABRICACAO DE" },
  {
    cnae: "3240099",
    descricao: "BRINQUEDOS, DE QUALQUER MATERIAL, PARA ANIMAIS; FABRICACAO DE",
  },
  {
    cnae: "3240099",
    descricao: "INSTALACOES PARA BOLICHES, BOCHAS, ETC; FABRICACAO DE",
  },
  { cnae: "3240099", descricao: "QUEBRA-CABECAS (BRINQUEDO); FABRICACAO DE" },
  {
    cnae: "3240099",
    descricao:
      "PECAS E ACESSORIOS PARA BRINQUEDOS, MECANIZADOS OU NAO; FABRICACAO DE",
  },
  {
    cnae: "3240099",
    descricao:
      "BRINQUEDOS NAO ESPECIFICADOS, DE QUALQUER MATERIAL, NAO MOTORIZADOS; FABRICACAO DE",
  },
  {
    cnae: "32507",
    descricao:
      "FABRICACAO DE INSTRUMENTOS E MATERIAIS PARA USO MEDICO E ODONTOLOGICO E DE ARTIGOS OPTICOS",
  },
  { cnae: "3250701", descricao: "NEBULIZADOR; FABRICACAO DE" },
  {
    cnae: "3250701",
    descricao:
      "INSTRUMENTOS E UTENSILIOS NAO-ELETRONICOS PARA MEDICINA; FABRICACAO DE",
  },
  { cnae: "3250701", descricao: "APARELHO PARA MEDIR PRESSAO; FABRICACAO DE" },
  {
    cnae: "3250701",
    descricao:
      "PECAS E ACESSORIOS PARA APARELHOS NAO-ELETRONICOS PARA MEDICINA, CIRURGIA, ODONTOLOGIA E LABORATORIO; FABRICACAO DE",
  },
  { cnae: "3250701", descricao: "RESPIRADORES DE REANIMACAO; FABRICACAO DE" },
  {
    cnae: "3250701",
    descricao: "RESPIRADORES AUTOMATICOS (PULMOES DE ACO); FABRICACAO DE",
  },
  {
    cnae: "3250701",
    descricao:
      "ESTERILIZADORES PARA USOS MEDICO-HOSPITALARES E PARA LABORATORIO; FABRICACAO DE",
  },
  {
    cnae: "3250701",
    descricao: "CATETERES, CANULAS E SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "3250701",
    descricao: "APARELHOS NAO-ELETRONICOS PARA CIRURGIA; FABRICACAO DE",
  },
  {
    cnae: "3250701",
    descricao:
      "INSTRUMENTOS E APARELHOS PARA TRANSFUSAO DE SANGUE, ETC.; FABRICACAO DE",
  },
  { cnae: "3250701", descricao: "TERMOMETROS MEDICOS; FABRICACAO DE" },
  { cnae: "3250701", descricao: "BISTURIS DE TODOS OS TIPOS; FABRICACAO DE" },
  {
    cnae: "3250701",
    descricao: "APARELHOS PARA RESPIRACAO ARTIFICIAL; FABRICACAO DE",
  },
  { cnae: "3250701", descricao: "MASCARAS CONTRA GASES; FABRICACAO DE" },
  {
    cnae: "3250701",
    descricao: "APARELHOS NAO-ELETRONICOS PARA ODONTOLOGIA; FABRICACAO DE",
  },
  {
    cnae: "3250701",
    descricao:
      "GRAMPOS, CLIPES,APLICADOR, EXTRATOR PARA APARELHOS MEDICINAIS; FABRICACAO DE",
  },
  { cnae: "3250701", descricao: "AGULHAS HIPODERMICAS; FABRICACAO DE" },
  {
    cnae: "3250701",
    descricao:
      "INSTRUMENTOS E UTENSILIOS NAO-ELETRONICOS PARA LABORATORIOS MEDICOS; FABRICACAO DE",
  },
  {
    cnae: "3250701",
    descricao:
      "HASTES (FLEXIVEIS OU NAO) COM EXTREMIDADES ENVOLTAS EM ALGODAO, PROPRIO PARA HIGIENE PESSOAL; FABRICACAO DE",
  },
  {
    cnae: "3250701",
    descricao:
      "APARELHOS DE OZONOTERAPIA, DE OXIGENOTERAPIA, RESPIRATORIO DE REANIMACAO E OUTROS DE TERAPIA RESPIRATORIA, INCLUSIVE PULMOES DE ACO; FABRICACAO DE",
  },
  {
    cnae: "3250701",
    descricao: "PINCAS E TESOURAS PARA USO MEDICO-CIRURGICOS; FABRICACAO DE",
  },
  { cnae: "3250701", descricao: "ESTETOSCOPIO, FABRICACAO DE" },
  {
    cnae: "3250701",
    descricao:
      "PECAS E ACESSORIOS PARA INSTRUMENTOS E UTENSILIOS NAO-ELETRONICOS PARA MEDICINA, CIRURGIA, ODONTOLOGIA E LABORATORIO; FABRICACAO DE",
  },
  {
    cnae: "3250701",
    descricao:
      "INSTRUMENTOS E APARELHOS PARA MEDICINA, CIRURGIA, ETC., DE OUTROS TIPOS; FABRICACAO DE",
  },
  { cnae: "3250701", descricao: "SERINGAS E AGULHAS; FABRICACAO DE" },
  {
    cnae: "3250701",
    descricao:
      "MAQUINAS E APARELHOS ELETRICOS PARA USO HOSPITALAR, FABRICACAO DE",
  },
  {
    cnae: "3250701",
    descricao: "APARELHOS NAO ELETRONICOS PARA LABORATORIOS; FABRICACAO DE",
  },
  {
    cnae: "3250701",
    descricao: "BOTICOES PARA EXTRACAO DENTARIA; FABRICACAO DE",
  },
  {
    cnae: "3250701",
    descricao:
      "IMPLANTES EXPANSIVEIS, DE ACO INOXIDAVEL,PARA DILATAR ARTERIAS (STENTS); FABRICACAO DE",
  },
  {
    cnae: "3250702",
    descricao:
      "MOBILIARIOS PARA MEDICINA, ODONTOLOGIA OU VETERINARIA (CADEIRAS DE DENTISTAS, MESAS CIRURGICAS, ETC.), INCLUSIVE PARTES; FABRICACAO DE",
  },
  {
    cnae: "3250702",
    descricao:
      "ARTEFATOS DE TECIDO NAO TECIDO PARA USO ODONTO-MEDICO-HOSPITALAR",
  },
  {
    cnae: "3250702",
    descricao:
      "MOBILIARIO PARA USO MEDICO, CIRURGICO, ODONTOLOGICO E DE LABORATORIO, FABRICACAO DE",
  },
  {
    cnae: "3250702",
    descricao: "CADEIRAS E COLUNAS PARA OFTALMOLOGIA; FABRICACAO DE",
  },
  { cnae: "3250702", descricao: "MESAS CIRURGICAS; FABRICACAO DE" },
  {
    cnae: "3250702",
    descricao:
      "MOVEIS PARA USO MEDICO, CIRURGICO, ODONTOLOGICO E DE LABORATORIO, FABRICACAO DE",
  },
  {
    cnae: "3250702",
    descricao: "EQUIPAMENTOS PARA MECANOTERAPIA E MASSAGEM; FABRICACAO DE",
  },
  {
    cnae: "3250702",
    descricao: "CADEIRAS E EQUIPOS COMPLETOS PARA ODONTOLOGIA; FABRICACAO DE",
  },
  {
    cnae: "3250703",
    descricao: "PROTESE DE MAO, SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250703",
    descricao: "SUSPENSORIOS ORTOPEDICOS, SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250703",
    descricao: "CALCADOS ORTOPEDICOS, SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250703",
    descricao:
      "APARELHOS DE MECANOTERAPIA, DE MASSAGEM OU DE PSICOTECNICA; FABRICACAO DE",
  },
  {
    cnae: "3250703",
    descricao: "APARELHOS ORTOPEDICOS EM GERAL, SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250703",
    descricao: "PALMILHAS ORTOPEDICAS, SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250703",
    descricao:
      "PECAS ARTIFICIAIS DO CORPO HUMANO, SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250703",
    descricao: "PROTESE DE PERNA, SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250703",
    descricao:
      "APARELHOS PARA CORRECAO DA ARCADA DENTARIA, SOB ENCOMENDA; FABRICACAO DE",
  },
  { cnae: "3250703", descricao: "PROTESE DE PE, SOB ENCOMENDA; FABRICACAO DE" },
  {
    cnae: "3250703",
    descricao:
      "PROTESES ARTICULARES (PROTESE FEMURAL), SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250703",
    descricao:
      "APARELHOS PARA CORRECAO DE DEFEITOS FISICOS, SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250703",
    descricao:
      "APARELHOS PARA DIALISE DENOMINADOS RINS ARTIFICIAIS; FABRICACAO DE",
  },
  {
    cnae: "3250703",
    descricao: "PROTESE MAMARIA, SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250703",
    descricao: "PROTESES COM CORPO DE SILICONE, SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250704",
    descricao: "PROTESE DE PERNA, EXCETO SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250704",
    descricao:
      "PROTESES ARTICULARES (PROTESE FEMURAL), EXCETO SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250704",
    descricao: "PROTESE MAMARIA, EXCETO SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250704",
    descricao:
      "APARELHOS PARA CORRECAO DA ARCADA DENTARIA, EXCETO SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250704",
    descricao:
      "APARELHOS PARA SURDEZ (EQUIPADOS OU NAO COM PILHAS); FABRICACAO DE",
  },
  {
    cnae: "3250704",
    descricao:
      "PARTES E ACESSORIOS PARA ARTIGOS E APARELHOS PARA COMPENSAR DEFICIENCIA, EXCETO PARA MARCA-PASSOS E PARA APARELHOS AUDITIVOS; FABRICACAO DE",
  },
  {
    cnae: "3250704",
    descricao:
      "PECAS ARTIFICIAIS DO CORPO HUMANO, EXCETO SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250704",
    descricao:
      "PROTESES COM CORPO DE SILICONE, EXCETO SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250704",
    descricao: "SUSPENSORIOS ORTOPEDICOS, EXCETO SOB ENCOMENDA; FABRICACAO DE",
  },
  { cnae: "3250704", descricao: "MULETAS; FABRICACAO DE" },
  { cnae: "3250704", descricao: "ANDADEIRAS; FABRICACAO DE" },
  {
    cnae: "3250704",
    descricao:
      "APARELHOS PARA CORRECAO DE DEFEITOS FISICOS, EXCETO SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250704",
    descricao: "PROTESE DE MAO, EXCETO SOB ENCOMENDA; FABRICACAO DE",
  },
  { cnae: "3250704", descricao: "MULETAS REGULAVEIS; FABRICACAO DE" },
  {
    cnae: "3250704",
    descricao: "PROTESE DE PE, EXCETO SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250704",
    descricao:
      "APARELHOS ORTOPEDICOS EM GERAL, EXCETO SOB ENCOMENDA; FABRICACAO DE",
  },
  {
    cnae: "3250705",
    descricao: "MATERIAIS PARA ODONTOLOGIA, N.E.; FABRICACAO DE",
  },
  {
    cnae: "3250705",
    descricao:
      "GAZES ESTERILIZADAS NAO IMPREGNADAS COM SUBSTANCIAS FARMACEUTICAS; FABRICACAO DE",
  },
  {
    cnae: "3250705",
    descricao: "COMPOSICOES A BASE DE GESSO PARA DENTISTAS; FABRICACAO DE",
  },
  {
    cnae: "3250705",
    descricao:
      "CURATIVOS NAO IMPREGNADOS COM SUBSTANCIAS MEDICINAIS; FABRICACAO DE",
  },
  { cnae: "3250705", descricao: "ATADURAS; FABRICACAO DE" },
  { cnae: "3250705", descricao: "BANDAGENS; FABRICACAO DE" },
  { cnae: "3250705", descricao: "LAMINARIAS ESTERILIZADAS; FABRICACAO DE" },
  {
    cnae: "3250705",
    descricao:
      "ALGODAO HIDROFILO NAO IMPREGNADO COM SUBSTANCIAS MEDICINAIS; FABRICACAO DE",
  },
  {
    cnae: "3250705",
    descricao:
      "HASTES (FLEXIVEIS OU NAO) COM EXTREMIDADES ENVOLTAS EM ALGODAO PARA HIGIENE PESSOAL; FABRICACAO DE",
  },
  {
    cnae: "3250705",
    descricao:
      "HEMOSTATICOS ESTERILIZADOS ABSORVIVEIS PARA CIRURGIA OU ODONTOLOGIA; FABRICACAO DE",
  },
  { cnae: "3250705", descricao: "GESSO DENTAL; FABRICACAO DE" },
  {
    cnae: "3250705",
    descricao: "MATERIAIS EMPREGADOS EM OBTURACOES DENTARIAS; FABRICACAO DE",
  },
  {
    cnae: "3250705",
    descricao:
      "FIOS E MATERIAIS PARA SUTURAS, DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  { cnae: "3250705", descricao: "FIOS CIRURGICOS; FABRICACAO DE" },
  { cnae: "3250705", descricao: "CIMENTOS PARA USO DENTARIO, FABRICACAO DE" },
  {
    cnae: "3250705",
    descricao:
      "CATEGUTES (CATGUTS) ESTERILIZADOS PARA SUTURAS CIRURGICAS; FABRICACAO DE",
  },
  {
    cnae: "3250705",
    descricao:
      "PREPARACOES LUBRIFICANTES A BASE DE GEL, PARA USOS MEDICOS EM EXAMES OU CIRURGIAS; FABRICACAO DE",
  },
  {
    cnae: "3250705",
    descricao:
      "CERAS DENTAIS E COMPOSTOS PARA RESTAURACOES DENTARIAS; FABRICACAO DE",
  },
  {
    cnae: "3250705",
    descricao: "CIMENTOS PARA OBTURACOES DENTARIAS; FABRICACAO DE",
  },
  {
    cnae: "3250705",
    descricao:
      "ALGODAO HIDROFILO, GAZES, ATADURAS E ARTIGOS SEMELHANTES NAO IMPREGNADO COM SUBSTANCIA FARMACEUTICA; CAMPOS CIRURGICOS DE FALSO TECIDO; FABRICACAO DE",
  },
  {
    cnae: "3250705",
    descricao:
      "PRODUTOS PARA OBTURACOES DENTARIAS (AMALGAMAS, ETC); FABRICACAO DE",
  },
  {
    cnae: "3250705",
    descricao:
      "ADESIVOS, HEMOSTATICOS, LAMINARIAS E OUTROS MATERIAIS ESTERILIZADOS PARA CIRURGIA OU ODONTOLOGIA, ABSORVIVEIS OU NAO; FABRICACAO DE",
  },
  {
    cnae: "3250705",
    descricao:
      "ESTOJOS OU CAIXAS DE URGENCIA COM CURATIVO, GAZE, ETC; FABRICACAO DE",
  },
  { cnae: "3250705", descricao: "GEL PARA MOLDES DE DENTADURA; FABRICACAO DE" },
  { cnae: "3250705", descricao: "COTONETES; FABRICACAO DE" },
  {
    cnae: "3250705",
    descricao: "EMPLASTROS, CATAPLASMAS E SINAPISMOS; FABRICACAO DE",
  },
  {
    cnae: "3250706",
    descricao:
      "ARTIGOS E APARELHOS PARA PROTESE DENTARIA, INCLUSIVE DENTES ARTIFICIAIS; FABRICACAO DE",
  },
  { cnae: "3250706", descricao: "DENTES ARTIFICIAIS; FABRICACAO DE" },
  {
    cnae: "3250706",
    descricao:
      "PARTES, PECAS E ACESSORIOS PARA OUTROS ARTIGOS E APARELHOS DE PROTESE; FABRICACAO DE",
  },
  { cnae: "3250706", descricao: "PROTESE DENTARIA; SERVICO DE" },
  { cnae: "3250707", descricao: "OCULOS DE SEGURANCA; FABRICACAO DE" },
  { cnae: "3250707", descricao: "OCULOS DE SOL; FABRICACAO DE" },
  {
    cnae: "3250707",
    descricao: "LENTES PARA OCULOS, COM OU SEM GRAU; FABRICACAO DE",
  },
  { cnae: "3250707", descricao: "FILTROS OPTICOS; FABRICACAO DE" },
  { cnae: "3250707", descricao: "AROS E ARMACOES PARA OCULOS; FABRICACAO DE" },
  {
    cnae: "3250707",
    descricao: "PECAS E ACESSORIOS PARA OCULOS; FABRICACAO DE",
  },
  {
    cnae: "3250707",
    descricao:
      "ARTIGOS E APARELHOS PARA PROTESE (LENTES INTRAOCULARES, VALVULAS CARDIACAS E SEMELHANTES); FABRICACAO DE",
  },
  { cnae: "3250707", descricao: "APARELHOS PARA OFTALMOLOGIA; FABRICACAO DE" },
  {
    cnae: "3250707",
    descricao: "OCULOS COMPLETOS, COM OU SEM GRAU; FABRICACAO DE",
  },
  {
    cnae: "3250707",
    descricao: "BLOCOS DE VIDRO OPTICO (OTICO); FABRICACAO DE",
  },
  { cnae: "3250707", descricao: "LENTES DE CONTATO; FABRICACAO DE" },
  { cnae: "3250709", descricao: "SURFACAGEM; SERVICOS DE" },
  { cnae: "3250709", descricao: "LABORATORIOS OPTICOS; SERVICOS DE" },
  {
    cnae: "3250709",
    descricao: "LABORATORIOS OPTICOS (LAPIDACAO DE LENTES); SERVICOS DE",
  },
  { cnae: "32914", descricao: "FABRICACAO DE ESCOVAS, PINCEIS E VASSOURAS" },
  {
    cnae: "3291400",
    descricao: "ESCOVAS (PECAS) PARA MAQUINAS; FABRICACAO DE",
  },
  { cnae: "3291400", descricao: "BROXAS E TRINCHAS; FABRICACAO DE" },
  { cnae: "3291400", descricao: "ESCOVAS PARA SAPATOS; FABRICACAO DE" },
  { cnae: "3291400", descricao: "ESCOVAS PARA UNHAS; FABRICACAO DE" },
  { cnae: "3291400", descricao: "ESCOVAS PARA DENTES; FABRICACAO DE" },
  { cnae: "3291400", descricao: "ESCOVAS METALICAS; FABRICACAO DE" },
  { cnae: "3291400", descricao: "ESCOVAS PARA CABELOS; FABRICACAO DE" },
  {
    cnae: "3291400",
    descricao: "APAGADOR PARA QUADROS, LOUSAS E SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "3291400", descricao: "PINCEIS DE QUALQUER MATERIAL; FABRICACAO DE" },
  { cnae: "3291400", descricao: "RODOS; FABRICACAO DE" },
  { cnae: "3291400", descricao: "VASSOURAS; FABRICACAO DE" },
  { cnae: "3291400", descricao: "ESPANADORES; FABRICACAO DE" },
  { cnae: "3291400", descricao: "ROLOS PARA PINTURA; FABRICACAO DE" },
  { cnae: "3291400", descricao: "VASSOURINHAS; FABRICACAO DE" },
  { cnae: "3291400", descricao: "ESCOVAS PARA ROUPAS; FABRICACAO DE" },
  { cnae: "3291400", descricao: "ESFREGOES; FABRICACAO DE" },
  { cnae: "3291400", descricao: "PINCEIS PARA BARBA; FABRICACAO DE" },
  {
    cnae: "3291400",
    descricao: "ESCOVAS PARA QUALQUER FINALIDADE; FABRICACAO DE",
  },
  {
    cnae: "32922",
    descricao:
      "FABRICACAO DE EQUIPAMENTOS E ACESSORIOS PARA SEGURANCA E PROTECAO PESSOAL E PROFISSIONAL",
  },
  {
    cnae: "3292201",
    descricao:
      "VESTUARIO E ACESSORIOS DE BORRACHA PARA SEGURANCA E PROTECAO, EXCETO CAPACETES E LUVAS; FABRICACAO DE",
  },
  {
    cnae: "3292201",
    descricao:
      "ROUPAS DE PROTECAO E SEGURANCA RESISTENTES A FOGO, FABRICACAO DE",
  },
  {
    cnae: "3292201",
    descricao:
      "LUVAS DE TECIDOS PLANOS, INCLUSIVE IMPREGNADAS, REVESTIDAS OU RECOBERTAS DE PLASTICO OU BORRACHA, PARA SEGURANCA E PROTECAO; FABRICACAO DE",
  },
  {
    cnae: "3292201",
    descricao:
      "ARTEFATOS DE TECIDO NAO TECIDO (FALSOS TECIDOS) PARA SEGURANCA E PROTECAO (GORROS, MASCARAS PROTETORAS, ETC.); FABRICACAO DE",
  },
  {
    cnae: "3292201",
    descricao: "ROUPAS DE AMIANTO RESISTENTES A FOGO, FABRICACAO DE",
  },
  {
    cnae: "3292201",
    descricao:
      "VESTUARIO, CALCADOS, ACESSORIOS E ARTEFATOS SEMELHANTES, DE AMIANTO; FABRICACAO DE",
  },
  { cnae: "9411100", descricao: "FEDERACAO EMPRESARIAL" },
  {
    cnae: "3292201",
    descricao:
      "LUVAS DE MALHA, INCLUSIVE IMPREGNADAS, REVESTIDAS OU RECOBERTAS DE PLASTICO OU BORRACHA, PARA SEGURANCA E PROTECAO; FABRICACAO DE",
  },
  {
    cnae: "3292202",
    descricao:
      "CAPACETES OU CHAPEUS PARA USO PROFISSIONAL (SEGURANCA E PROTECAO); FABRICACAO DE",
  },
  {
    cnae: "3292202",
    descricao:
      "TOUCAS, LUVAS, MASCARAS E OUTROS ACESSORIOS DE USO MEDICO-HOSPITALAR; FABRICACAO DE",
  },
  { cnae: "3292202", descricao: "LUVAS CIRURGICAS; FABRICACAO DE" },
  {
    cnae: "3292202",
    descricao: "MASCARAS PROTETORAS PARA SEGURANCA INDUSTRIAL; CONFECCAO DE",
  },
  {
    cnae: "3292202",
    descricao: "ESTERILIZACAO DE LUVAS CIRURGICAS; FABRICACAO DE",
  },
  { cnae: "3292202", descricao: "COLETES A PROVA DE BALAS; CONFECCAO DE" },
  {
    cnae: "3292202",
    descricao:
      "ACESSORIOS DE PLASTICO PARA SEGURANCA E PROTECAO, EXCETO CAPACETES; FABRICACAO DE",
  },
  {
    cnae: "3292202",
    descricao: "CINTOS DE SEGURANCA DE QUALQUER MATERIAL; CONFECCAO DE",
  },
  {
    cnae: "3292202",
    descricao:
      "ACESSORIOS E EQUIPAMENTOS, NAO ESPECIFICADOS, PARA SEGURANCA E PROTECAO; FABRICACAO DE",
  },
  {
    cnae: "3292202",
    descricao: "EQUIPAMENTOS DE SEGURANCA INDUSTRIAL, N.E.; CONFECCAO DE",
  },
  { cnae: "3292202", descricao: "LUVAS E MASCARAS DE AMIANTO, FABRICACAO DE" },
  {
    cnae: "3292202",
    descricao: "LENCOIS DE TECIDO NAO TECIDO PARA USO ODONTO-MEDICO-HOSPITALAR",
  },
  {
    cnae: "3292202",
    descricao:
      "CAPACETES OU CHAPEUS DE BORRACHA OU PLASTICO PARA SEGURANCA E PROTECAO; FABRICACAO DE",
  },
  {
    cnae: "3292202",
    descricao:
      "CINTOS E COLETES SALVA-VIDAS CONFECCIONADOS COM TEXTEIS; FABRICACAO DE",
  },
  {
    cnae: "3292202",
    descricao: "LUVAS DE BORRACHA PARA USO PROFISSIONAL; CONFECCAO DE",
  },
  {
    cnae: "3292202",
    descricao: "LUVAS DE PROTECAO PARA USO PROFISSIONAL; FABRICACAO DE",
  },
  {
    cnae: "3292202",
    descricao: "LUVAS DE COURO PARA USO PROFISSIONAL; FABRICACAO DE",
  },
  {
    cnae: "3292202",
    descricao:
      "CALCADOS PARA SEGURANCA INDUSTRIAL, DE AMIANTO OU ASBESTO, FABRICACAO DE",
  },
  {
    cnae: "3292202",
    descricao:
      "AVENTAIS, GORROS, MASCARAS PROTETORAS E SEMELHANTES DE NAO-TECIDOS OU FALSOS TECIDOS PARA USO MEDICO-HOSPITALAR; FABRICACAO DE",
  },
  {
    cnae: "3292202",
    descricao:
      "CAPACETES PARA SEGURANCA INDUSTRIAL, DE AMIANTO OU ASBESTO, FABRICACAO DE",
  },
  {
    cnae: "3292202",
    descricao:
      "CINTOS, BOIAS, COLETES OU EQUIPAMENTOS SEMELHANTES PARA SALVAMENTO, DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  {
    cnae: "3292202",
    descricao: "EQUIPAMENTOS PARA SEGURANCA, A PROVA DE BALAS; FABRICACAO DE",
  },
  {
    cnae: "3292202",
    descricao: "ARTEFATOS DE CORTICA PARA SEGURANCA E PROTECAO; FABRICACAO DE",
  },
  {
    cnae: "32990",
    descricao:
      "FABRICACAO DE PRODUTOS DIVERSOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "3299001", descricao: "GUARDA-CHUVAS E SOMBRINHAS; FABRICACAO DE" },
  { cnae: "3299001", descricao: "GUARDA-SOIS DE PRAIA; FABRICACAO DE" },
  {
    cnae: "3299001",
    descricao: "GUARDA-CHUVAS, SOMBRINHAS E GUARDA-SOIS; FABRICACAO DE",
  },
  { cnae: "3299001", descricao: "BENGALA; FABRICACAO DE" },
  {
    cnae: "3299002",
    descricao: "FITAS IMPRESSORAS PARA MAQUINAS, N.E.; FABRICACAO DE",
  },
  { cnae: "3299002", descricao: "CARGAS E PECAS PARA CANETAS; FABRICACAO DE" },
  { cnae: "3299002", descricao: "CANETAS ESFEROGRAFICAS; FABRICACAO DE" },
  { cnae: "3299002", descricao: "CANETAS; FABRICACAO DE" },
  { cnae: "3299002", descricao: "CARIMBOS E SINETES; FABRICACAO DE" },
  {
    cnae: "3299002",
    descricao: "ALMOFADAS PARA CARIMBOS DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  { cnae: "3299002", descricao: "CANETAS HIDROGRAFICAS; FABRICACAO DE" },
  { cnae: "3299002", descricao: "CORRETIVO MULTIUSO; FABRICACAO DE" },
  { cnae: "3299002", descricao: "LAPISEIRAS DE TODOS OS TIPOS; FABRICACAO DE" },
  {
    cnae: "3299002",
    descricao: "MINAS PARA LAPIS E LAPISEIRAS; FABRICACAO DE",
  },
  { cnae: "3299002", descricao: "ESFERAS PARA CANETAS; FABRICACAO DE" },
  { cnae: "3299002", descricao: "BORRACHAS DE APAGAR; FABRICACAO DE" },
  {
    cnae: "3299002",
    descricao: "LAPIS DE TODOS OS TIPOS, EXCETO COSMETICOS; FABRICACAO DE",
  },
  {
    cnae: "3299003",
    descricao: "PLACAS E PLAQUETAS PARA VEICULOS; FABRICACAO DE",
  },
  { cnae: "3299003", descricao: "LETRAS EM METAL; FABRICACAO DE" },
  { cnae: "3299003", descricao: "PAINEIS DE PROPAGANDA, FABRICACAO DE" },
  {
    cnae: "3299003",
    descricao: "PLACAS PARA INDICACAO DE NOME E NUMERO DE RUAS; FABRICACAO DE",
  },
  {
    cnae: "3299003",
    descricao: "PLACAS METALICAS INDICADORAS, PARA QUALQUER FIM; FABRICACAO DE",
  },
  {
    cnae: "3299003",
    descricao:
      "PLACAS INDICADORAS PARA FINS COMERCIAIS E INDUSTRIAIS; FABRICACAO DE",
  },
  {
    cnae: "3299003",
    descricao:
      "PAINEIS DE ACRILICO E DE OUTROS MATERIAIS TRANSPARENTES; FABRICACAO DE",
  },
  { cnae: "3299003", descricao: "PLACAS PROFISSIONAIS; FABRICACAO DE" },
  { cnae: "3299003", descricao: "LETRAS EM ACRILICO; FABRICACAO DE" },
  {
    cnae: "3299003",
    descricao: "PLACAS PARA SINALIZACAO E ORIENTACAO RODOVIARIA; FABRICACAO DE",
  },
  {
    cnae: "3299004",
    descricao: "LUMINOSOS EM ACRILICO, GAS NEON, ETC.; FABRICACAO DE",
  },
  { cnae: "3299004", descricao: "LETRAS EM NEON; FABRICACAO DE" },
  { cnae: "3299004", descricao: "LETREIROS LUMINOSOS; FABRICACAO DE" },
  { cnae: "3299004", descricao: "PLACAS LUMINOSAS; FABRICACAO DE" },
  {
    cnae: "3299004",
    descricao: "ANUNCIOS E PLACAS INDICADORAS, LUMINOSOS; FABRICACAO DE",
  },
  { cnae: "3299005", descricao: "ILHOSES; FABRICACAO DE" },
  { cnae: "3299005", descricao: "COLCHETES DE PRESSAO; FABRICACAO DE" },
  { cnae: "3299005", descricao: "FECHO-ECLER; FABRICACAO DE" },
  { cnae: "3299005", descricao: "ZIPERES; FABRICACAO DE" },
  { cnae: "3299005", descricao: "FIVELAS DE QUALQUER MATERIAL; FABRICACAO DE" },
  { cnae: "3299005", descricao: "AVIAMENTOS PARA COSTURA; FABRICACAO DE" },
  { cnae: "3299005", descricao: "BOTOES DE QUALQUER MATERIAL; FABRICACAO DE" },
  { cnae: "3299006", descricao: "VELAS DECORATIVAS; FABRICACAO DE" },
  {
    cnae: "3299006",
    descricao: "VELAS (CERA, ESTEARINA, SEBO, ETC); FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao: "PRANCHETAS ESCOLARES DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "PORTA-RETRATOS; FABRICACAO DE" },
  {
    cnae: "3299099",
    descricao:
      "FERROCERIO E OUTRAS LIGAS PIROFORICAS SOB QUALQUER FORMA; COMBUSTIVEIS LIQUIDOS OU GASOSOS PARA CARREGAR ISQUEIROS OU ACENDEDORES; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao:
      "EQUIPAMENTOS DIDATICOS PARA ENSINO EXPERIMENTAL E AUDIOVISUAL; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao:
      "FORMAS E OUTRAS PARTES DE BOTOES; ESBOCOS DE BOTOES; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "CRACHAS DE QUALQUER MATERIAL; FABRICACAO DE" },
  {
    cnae: "3299099",
    descricao:
      "APARELHOS SIMULADORES DE DIRECAO PARA AUTO-ESCOLA; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "ATAUDES; FABRICACAO DE" },
  { cnae: "3299099", descricao: "MANEQUINS; FABRICACAO DE" },
  {
    cnae: "3299099",
    descricao: "ISQUEIROS DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao: "OBJETOS DE ADORNO DE MATERIAL N.E.; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao:
      "INSTRUMENTOS, MODELOS E APARELHOS CONCEBIDOS PARA DEMONSTRACAO; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao:
      "AGULHAS DE TRICO, AGULHAS DE CROCHE, FURADORES PARA BORDAR E ARTEFATOS SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "LAMPIOES; FABRICACAO DE" },
  { cnae: "3299099", descricao: "ARRANJOS DECORATIVOS; FABRICACAO DE" },
  {
    cnae: "3299099",
    descricao:
      "MATERIAS VEGETAIS OU MINERAIS DE ENTALHAR TRABALHADAS E OBRAS DESSAS MATERIAS; CAPSULAS DE GELATINA DIGERIVEIS PARA MEDICAMENTOS; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "JARRAS TERMICAS; FABRICACAO DE" },
  { cnae: "9411100", descricao: "CONFEDERACAO PATRONAL" },
  {
    cnae: "3299099",
    descricao: "CILIOS ARTIFICIAIS (POSTICOS); FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao: "VELAS, PAVIOS, CIRIOS E ARTIGOS SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "CUIA PARA CHIMARRAO; FABRICACAO DE" },
  { cnae: "3299099", descricao: "TAXIDERMIA; SERVICO DE" },
  {
    cnae: "3299099",
    descricao:
      "PROTOTIPOS, MODELOS E SEMELHANTES DE QUALQUER MATERIA PRIMA OBTIDOS POR PROTOTIPAGEM RAPIDA; PRODUCAO DE",
  },
  { cnae: "3299099", descricao: "FLORES DESIDRATADAS; FABRICACAO DE" },
  {
    cnae: "3299099",
    descricao:
      "CAPSULAS DE GELATINA DIGERIVEIS PARA MEDICAMENTOS, ETC; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao: "FOLHAS, FLORES E FRUTOS ARTIFICIAIS; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao:
      "MARFIM, OSSOS, CHIFRES E OUTRAS MATERIAS ANIMAIS PARA ENTALHAR, E SUAS OBRAS; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "FLUIDOS PARA ISQUEIRO; FABRICACAO DE" },
  {
    cnae: "3299099",
    descricao:
      "ARTIGOS PARA FESTAS, CARNAVAL E OUTROS DIVERTIMENTOS; ARTIGOS DE MAGIA E SEMELHANTES; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "PERUCAS; FABRICACAO DE" },
  {
    cnae: "3299099",
    descricao: "STANDS DE ALVOS PARA EXERCICIO DE TIROS; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "AEROMODELOS; FABRICACAO DE" },
  { cnae: "3299099", descricao: "CERVEJEIRAS TERMICAS; FABRICACAO DE" },
  {
    cnae: "3299099",
    descricao: "TELAS PREPARADAS PARA PINTURA; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "LOUSAS E QUADROS NEGROS; FABRICACAO DE" },
  {
    cnae: "3299099",
    descricao: "QUADROS COMPLETOS (COM GRAVURAS, ESTAMPAS, ETC); FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao:
      "PENTES E TRAVESSAS PARA CABELOS, DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao: "OBRAS DE TRIPA, DE BEXIGA OU DE TENDOES; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao:
      "ARTEFATOS MODELADOS OU TALHADOS DE CERAS OU RESINAS NATURAIS; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao:
      "ARTEFATOS PARA FESTAS (BOLAS DE SOPRAR, BALOES, BEXIGAS), CARNAVAL E OUTROS DIVERTIMENTOS (EXCETO DE PAPEL); FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao: "CHICOTES DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "CACHIMBOS E PITEIRAS; FABRICACAO DE" },
  {
    cnae: "3299099",
    descricao: "ARTEFATOS DE MADREPEROLA, OSSO, MARFIM, ETC; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao: "GARRAFAS, JARRAS E MARMITAS TERMICAS; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao:
      "PARTES, GUARNICOES E ACESSORIOS PARA BENGALAS, BENGALAS-ASSENTO, CHICOTES E ARTEFATOS SEMELHANTES; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao:
      "VAPORIZADORES DE TOUCADOR, SUAS ARMACOES E CABECAS DE ARMACOES; ESPONJAS PARA APLICACAO DE PRODUTOS DE TOUCADOR; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "QUADROS NEGROS; FABRICACAO DE" },
  { cnae: "3299099", descricao: "PRODUTOS DIVERSOS; FABRICACAO DE" },
  {
    cnae: "3299099",
    descricao:
      "AGULHAS E ALFINETES (EXCETO AGULHAS PARA MAQUINAS); FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao: "CAIXOES MORTUARIOS, INCLUSIVE URNAS; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao:
      "ARTEFATOS DE CHIFRES, GARRAS, PELOS, PLUMAS, CRINAS E OUTROS DESPOJOS DE ANIMAIS; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "ARTIGOS DE MAGIA; FABRICACAO DE" },
  {
    cnae: "3299099",
    descricao: "GIZ EM BASTOES E OUTRAS FORMAS; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "FONTES LUMINOSAS; FABRICACAO DE" },
  {
    cnae: "3299099",
    descricao:
      "ARTESANATO EM MATERIAS DIVERSOS, NAO ESPECIFICADOS ANTERIORMENTE; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao: "CHOPEIRAS E CERVEJEIRAS TERMICAS; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "PENEIRAS DE ARAME; FABRICACAO DE" },
  { cnae: "3299099", descricao: "VEUS E CAMISAS PARA LAMPIOES; FABRICACAO DE" },
  {
    cnae: "3299099",
    descricao: "DEFUMADORES, INCENSOS E ERVAS SECAS PARA BANHOS; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "GLOBOS GEOGRAFICOS; FABRICACAO DE" },
  { cnae: "3299099", descricao: "VALVULAS PARA AEROSSOL; FABRICACAO DE" },
  { cnae: "3299099", descricao: "BALOES DE BORRACHA; FABRICACAO DE" },
  {
    cnae: "3299099",
    descricao: "ADORNOS PARA ARVORES DE NATAL; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao: "QUADROS OU LOUSAS PARA ESCREVER OU DESENHAR; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "MARMITAS TERMICAS; FABRICACAO DE" },
  { cnae: "3299099", descricao: "SUPORTE PARA GARRAFAO; FABRICACAO DE" },
  { cnae: "3299099", descricao: "ACENDEDORES AUTOMATICOS; FABRICACAO DE" },
  {
    cnae: "3299099",
    descricao:
      "TROFEUS DE QUALQUER MATERIAL, PARA COMEMORACOES OU COMPETICOES; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao:
      "DISTINTIVOS, ESCUDOS E CRACHAS DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao: "CHAVEIROS DE QUALQUER MATERIAL; FABRICACAO DE",
  },
  {
    cnae: "3299099",
    descricao:
      "ISQUEIROS E OUTROS ACENDEDORES, MESMO MECANICOS OU ELETRICOS E SUAS PARTES, EXCETO PEDRAS E PAVIOS; FABRICACAO DE",
  },
  { cnae: "3299099", descricao: "PEDRAS PARA ISQUEIROS; FABRICACAO DE" },
  {
    cnae: "33112",
    descricao:
      "MANUTENCAO E REPARACAO DE TANQUES, RESERVATORIOS METALICOS E CALDEIRAS, EXCETO PARA VEICULOS",
  },
  {
    cnae: "3311200",
    descricao:
      "BUJOES, GARRAFAS E CILINDROS METALICOS PARA EMBALAR GASES; MANUTENCAO E REPARACAO DE",
  },
  {
    cnae: "3311200",
    descricao:
      "RESERVATORIOS METALICOS PARA AGUA POTAVEL; MANUTENCAO E REPARACAO DE",
  },
  {
    cnae: "3311200",
    descricao:
      "CILINDROS DE FERRO PARA EXTINTORES DE INCENDIO; MANUTENCAO E REPARACAO DE",
  },
  {
    cnae: "3311200",
    descricao:
      "TANQUES, RESERVATORIOS E CALDEIRA (EXCETO PARA VEICULOS), MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3311200",
    descricao:
      "TUBOS, GARRAFAS OU CILINDROS METALICOS PARA TRANSPORTE DE GASES COMPRIMIDOS E LIQUEFEITOS; MANUTENCAO E REPARACAO DE",
  },
  {
    cnae: "3311200",
    descricao:
      "EQUIPAMENTO BELICO PESADO, REPARACAO E MANUTENCAO DE; QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "33121",
    descricao: "MANUTENCAO E REPARACAO DE EQUIPAMENTOS ELETRONICOS E OPTICOS",
  },
  {
    cnae: "3312102",
    descricao: "INSTRUMENTOS DE MEDICAO E CONTROLE, MANUTENCAO E REPARACAO DE",
  },
  {
    cnae: "3312102",
    descricao:
      "MAQUINAS, APARELHOS E EQUIPAMENTOS ELETRONICOS PARA CONTROLE DO PROCESSOS INDUSTRIAIS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3312102",
    descricao:
      "INSTRUMENTOS DE MEDICAO (GASOMETRO,HIDROMETRO,PLUVIOMETRO,ETC); MANUTENCAO E REPARACAO DE",
  },
  {
    cnae: "3312102",
    descricao: "TACOGRAFOS, TACOMETROS, HODOMETROS, TAXIMETROS; MANUTENCAO DE",
  },
  {
    cnae: "3312102",
    descricao:
      "INSTRUMENTOS DE REGULACAO E CONTROLE, MANUTENCAO E REPARACAO DE",
  },
  {
    cnae: "3312102",
    descricao: "INSTRUMENTOS PARA CONTROLE AERONAUTICO, MANUTENCAO DE",
  },
  {
    cnae: "3312102",
    descricao: "APARELHOS DE MEDICAO DE ENERGIA, MANUTENCAO E REPARACAO DE",
  },
  {
    cnae: "3312102",
    descricao:
      "RADARES E OUTROS APARELHOS DE CONTROLE SEMELHANTES; MANUTENCAO E REPARACAO DE",
  },
  {
    cnae: "3312102",
    descricao: "INSTRUMENTOS AERONAUTICOS, LABORATORIO DE REVISAO DE",
  },
  {
    cnae: "3312102",
    descricao:
      "APARELHOS E INSTRUMENTOS DE MEDIDA, TESTE E CONTROLE, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3312102",
    descricao:
      "APARELHOS E EQUIPAMENTOS PARA LABORATORIOS DE PESQUISA CIENTIFICA, MANUTENCAO E REPARACAO DE",
  },
  {
    cnae: "3312102",
    descricao:
      "APARELHOS E EQUIPAMENTOS PARA LABORATORIOS DE PESQUISA E DESENVOLVIMENTO, MANUTENCAO E REPARACAO DE",
  },
  {
    cnae: "3312103",
    descricao:
      "APARELHOS E EQUIPAMENTOS CIRURGICOS, MANUTENCAO E REPARACAO EXECUTADA POR  UNIDADE  ESPECIALIZADA",
  },
  { cnae: "9411100", descricao: "SINDICATO PATRONAL" },
  {
    cnae: "3312103",
    descricao:
      "APARELHOS E EQUIPAMENTOS ELETROMEDICOS E ELETROTERAPEUTICOS, MANUTENCAO E REPARACAO EXECUTADA POR  UNIDADE  ESPECIALIZADA",
  },
  {
    cnae: "3312103",
    descricao:
      "MARCAPASSOS; MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3312103",
    descricao:
      "APARELHOS AUDITIVOS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3312104",
    descricao:
      "EQUIPAMENTOS E INSTRUMENTOS OPTICOS, MANUTENCAO E REPARACAO EXECUTADA POR  UNIDADE  ESPECIALIZADA",
  },
  {
    cnae: "3312104",
    descricao:
      "EQUIPAMENTO FOTOGRAFICO DE USO PROFISSIONAL; MANUTENCAO E REPARACAO EXECUTADA POR  UNIDADE  ESPECIALIZADA",
  },
  {
    cnae: "3312104",
    descricao:
      "APARELHOS E INSTRUMENTOS CINEMATOGRAFICOS; MANUTENCAO E REPARACAO EXECUTADA POR  UNIDADE  ESPECIALIZADA",
  },
  {
    cnae: "33139",
    descricao: "MANUTENCAO E REPARACAO DE MAQUINAS E EQUIPAMENTOS ELETRICOS",
  },
  {
    cnae: "3313901",
    descricao:
      "MOTORES ELETRICOS, MANUTENCAO OU REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3313901",
    descricao:
      "GERADORES DE CORRENTE CONTINUA OU ALTERNADA, MANUTENCAO OU REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3313901",
    descricao:
      "TRANSFORMADORES, INDUTORES, CONVERSORES, SINCRONIZADORES E SEMELHANTES, MANUTENCAO OU REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3313902",
    descricao:
      "BATERIAS E ACUMULADORES ELETRICOS (EXCETO PARA VEICULOS), MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3313999",
    descricao:
      "APARELHOS E EQUIPAMENTOS PARA DISTRIBUICAO E CONTROLE DE ENERGIA; MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3313999",
    descricao:
      "MAQUINAS, APARELHOS, EQUIPAMENTOS E MATERIAIS ELETRICOS, N.E., MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "33147",
    descricao:
      "MANUTENCAO E REPARACAO DE MAQUINAS E EQUIPAMENTOS DA INDUSTRIA MECANICA",
  },
  {
    cnae: "3314701",
    descricao:
      "MOTORES PARA EMBARCACOES, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314701",
    descricao:
      "MAQUINAS MOTRIZES NAO-ELETRICOS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314701",
    descricao:
      "MAQUINAS A VAPOR PARA EMBARCACOES, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314701",
    descricao:
      "MOINHOS DE VENTO, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314701",
    descricao:
      "MOTORES MARITIMOS, N.E., MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314701",
    descricao:
      "TURBINAS E RODAS HIDRAULICAS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314701",
    descricao:
      "CALDEIRA GERADORA DE VAPOR PARA LOCOMOTIVA, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314701",
    descricao:
      "MOTORES ESTACIONARIOS DE COMBUSTAO INTERNA (GASOLINA E DIESEL) - EXCETO PARA VEICULOS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314701",
    descricao:
      "CALDEIRA GERADORA DE VAPOR PARA VEICULOS FERROVIARIOS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314702",
    descricao:
      "EQUIPAMENTOS HIDRAULICOS E PNEUMATICOS (EXCETO VALVULAS); REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314702",
    descricao:
      "CILINDROS HIDRAULICOS E PNEUMATICOS; REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314702",
    descricao:
      "MOTOBOMBAS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314702",
    descricao:
      "BOMBAS HIDRAULICAS; REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314703",
    descricao:
      "VALVULAS INDUSTRIAIS, N.E., REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314703",
    descricao:
      "VALVULAS PARA SISTEMA DE PERFURACAO DE POCOS DE PETROLEO (ESPECIAIS, DE LAMA E OUTRAS), REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314703",
    descricao:
      "VALVULAS AGULHA PARA FINS INDUSTRIAIS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314703",
    descricao:
      "VALVULAS DE PRESSAO PARA FINS INDUSTRIAIS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314703",
    descricao:
      "VALVULAS ESFERICAS PARA FINS INDUSTRIAIS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314703",
    descricao:
      "VALVULAS BORBOLETA PARA FINS INDUSTRIAIS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314704",
    descricao:
      "COMPRESSORES DE AR, ESTACIONARIOS OU PORTATEIS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314704",
    descricao:
      "COMPRESSORES PARA USO INDUSTRIAL, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314704",
    descricao:
      "COMPRESSORES PARA QUALQUER USO, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314705",
    descricao:
      "REDUTORES E VARIADORES DE VELOCIDADE PARA USO INDUSTRIAL, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314705",
    descricao:
      "EIXOS DE TRANSMISSAO PARA INDUSTRIA, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314705",
    descricao:
      "ENGRENAGENS PARA TRANSMISSAO INDUSTRIAL, DE TODOS OS TIPOS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314705",
    descricao:
      "ROLAMENTOS DE TODOS OS TIPOS PARA USO INDUSTRIAL; MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314705",
    descricao:
      "EQUIPAMENTOS DE TRANSMISSAO PARA FINS INDUSTRIAIS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314706",
    descricao:
      "COLETORES SOLARES, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314706",
    descricao:
      "ESTUFAS, SECADORES E AUTOCLAVES NAO-ELETRICOS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314706",
    descricao:
      "ESTUFAS E FORNOS ELETRICOS PARA FINS INDUSTRIAIS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314706",
    descricao:
      "APARELHOS E EQUIPAMENTOS NAO-ELETRICOS PARA INSTALACOES TERMICAS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314707",
    descricao:
      "COIFAS (EXAUSTORES INDUSTRIAIS), MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314707",
    descricao:
      "BEBEDOUROS ELETRICOS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314707",
    descricao:
      "EXAUSTORES INDUSTRIAIS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314707",
    descricao:
      "REFRIGERADORES COMERCIAIS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314707",
    descricao:
      "VENTILADORES INDUSTRIAIS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314707",
    descricao:
      "ASPIRADORES INDUSTRIAIS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314707",
    descricao:
      "MAQUINAS E APARELHOS DE VENTILACAO PARA USO INDUSTRIAL E COMERCIAL, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314707",
    descricao:
      "BALCOES E CAMARAS FRIGORIFICAS, DE USOS INDUSTRIAL E COMERCIAL, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314707",
    descricao:
      "CONGELADORES OU CONSERVADORES COMERCIAIS (FREEZERS), MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314707",
    descricao:
      "APARELHOS DE REFRIGERACAO E VENTILACAO DE USO INDUSTRIAL, N.E., MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3319800",
    descricao:
      "TOLDOS E VELAS PARA BARCOS; REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314707",
    descricao:
      "MAQUINAS E APARELHOS DE REFRIGERACAO PARA USO INDUSTRIAL E COMERCIAL, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314708",
    descricao:
      "TRANSPORTADORES MECANICOS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314708",
    descricao:
      "MAQUINAS PARA TRANSPORTE E ELEVACAO DE CARGAS(EXCETO ELEVADORES), MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  { cnae: "3314708", descricao: "GUINDASTES, MANUTENCAO DE" },
  {
    cnae: "3314708",
    descricao:
      "EMPILHADEIRAS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314708",
    descricao:
      "MACACOS HIDRAULICOS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314709",
    descricao:
      "MAQUINAS DE CONTABILIDADE; MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314709",
    descricao:
      "MAQUINAS DE ESCREVER, MANUAL E ELETRICA; MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314709",
    descricao:
      "CAIXAS REGISTRADORAS; MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314709",
    descricao:
      "MAQUINAS DE ESCRITORIO; MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314709",
    descricao:
      "MAQUINAS DE ESCREVER, CALCULAR E OUTROS EQUIPAMENTOS NAO-ELETRONICOS PARA ESCRITORIO, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314709",
    descricao:
      "MAQUINAS DE CALCULAR, ELETRONICAS, MANUAIS OU DE MESA; MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314710",
    descricao:
      "MAQUINAS PARA SANEAMENTO BASICO E AMBIENTAL; MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314710",
    descricao:
      "FILTROS INDUSTRIAIS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314710",
    descricao:
      "MAQUINAS AUTOMATICAS PARA LAVAR E LUBRIFICAR CARROS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314710",
    descricao:
      "EXTINTORES DE INCENDIO, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314710",
    descricao:
      "CALANDRAS INDUSTRIAIS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314710",
    descricao:
      "CABINAS PARA PINTURA, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314710",
    descricao:
      "BALANCAS COMERCIAIS E INDUSTRIAIS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314710",
    descricao:
      "TROCADORES (PERMUTADORES) DE CALOR, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314710",
    descricao:
      "MAQUINAS PARA EMBALAR E ENSACAR, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314710",
    descricao:
      "MAQUINAS PARA FATIAR (COMERCIAL), MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314710",
    descricao:
      "INCINERADORES, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314710",
    descricao:
      "LAVADORAS DE CARPETE E ESTOFADOS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314710",
    descricao:
      "MAQUINAS AUTOMATICAS PARA VENDA DE PRODUTOS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314710",
    descricao:
      "MAQUINAS PARA BARES E LANCHONETES, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314710",
    descricao:
      "ENSACADEIRA AUTOMATICA, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314710",
    descricao:
      "BOMBAS PARA DISTRIBUICAO DE COMBUSTIVEIS, MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314711",
    descricao:
      "ADUBADORAS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314711",
    descricao:
      "TOSQUIADORES DE LA, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314711",
    descricao:
      "PULVERIZADORES, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314711",
    descricao: "ARADOS, MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314711",
    descricao:
      "MAQUINAS PARA AGRICULTURA, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314711",
    descricao:
      "APARELHOS E MAQUINAS PARA APICULTURA E CRIACAO DE PEQUENOS ANIMAIS, MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314711",
    descricao:
      "CULTIVADORES AGRICOLAS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314711",
    descricao:
      "DISTRIBUIDORES DE FERTILIZANTES, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314711",
    descricao:
      "MAQUINAS E APARELHOS PARA AVICULTURA, MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314711",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA IRRIGACAO AGRICOLA; MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314711",
    descricao:
      "INCUBADORAS AVICOLAS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314712",
    descricao:
      "TRATORES AGRICOLAS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314713",
    descricao:
      "LAMINADORES PARA MADEIRA, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314713",
    descricao:
      "MAQUINAS-FERRAMENTA PARA INDUSTRIA DA BORRACHA, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314713",
    descricao: "MOTOSSERRAS, REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314713",
    descricao:
      "CENTROS DE USINAGEM, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314713",
    descricao:
      "PRENSAS INDUSTRIAIS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314713",
    descricao:
      "MAQUINAS-FERRAMENTA PARA SERRARIAS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314713",
    descricao:
      "FERRAMENTAS ELETRICAS MANUAIS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314713",
    descricao:
      "FURADEIRAS, LIXADEIRAS, POLITRIZES E OUTRAS FERRAMENTAS INDUSTRIAIS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314713",
    descricao:
      "MAQUINAS-FERRAMENTA; REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314713",
    descricao:
      "MAQUINAS-FERRAMENTA DE COMANDO NUMERICO, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314713",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA SOLDA; REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314713",
    descricao:
      "MAQUINAS-FERRAMENTA PARA TRABALHAR METAIS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314713",
    descricao:
      "MAQUINAS-FERRAMENTA NAO-ELETRICOS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314713",
    descricao:
      "TORNOS INDUSTRIAIS, MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314714",
    descricao:
      "PERFURATRIZES PARA PROSPECCAO E EXTRACAO DE PETROLEO, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314714",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DE PROSPECCAO E EXTRACAO DE PETROLEO, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314715",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA A EXTRACAO E BENEFICIAMENTO DE MINERIOS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314715",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA BENEFICIAR MINERAIS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314716",
    descricao:
      "TRATORES, EXCETO AGRICOLAS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  { cnae: "3831901", descricao: "SUCATAS DE ALUMINIO; REDUCAO MECANICA DE" },
  {
    cnae: "3314717",
    descricao:
      "PAS MECANICAS, ESCAVADORES, CARREGADORAS E PAS-CARREGADORAS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314717",
    descricao:
      "VIBRADORES DE CONCRETO, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314717",
    descricao:
      "BETONEIRAS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314717",
    descricao:
      "MOTONIVELADORAS PARA TERRAPLENAGEM, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314717",
    descricao:
      "MAQUINAS PARA PAVIMENTACAO, N.E.(EXCETO TRATORES), MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314717",
    descricao:
      "MAQUINAS E EQUIPAMENTOS DE TERRAPLENAGEM E PAVIMENTACAO, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314717",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DA CONSTRUCAO, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314717",
    descricao:
      "ESCAVADEIRAS OU ESCAVADORAS(MAQUINAS PARA TERRAPLENAGEM, MINERACAO,CONSTRUCAO E ETC); MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314717",
    descricao:
      "MISTURADORES E ESPALHADORES DE ASFALTO, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314718",
    descricao:
      "MAQUINAS PARA A INDUSTRIA SIDERURGICA, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314718",
    descricao:
      "LAMINADORES DE METAIS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314718",
    descricao:
      "MAQUINAS PARA A INDUSTRIA METALURGICA, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314719",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA FRIGORIFICOS, MATADOUROS E ABATEDOUROS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314719",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DE BEBIDAS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314719",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DO FUMO, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314719",
    descricao:
      "MAQUINAS E APARELHOS PARA PANIFICACAO, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314719",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA ALIMENTAR, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314720",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA DE CALCADOS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314720",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA INSTALACOES EM LAVANDERIAS, TINTURARIAS E SEMELHANTES, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314720",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA TEXTIL, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314720",
    descricao:
      "MAQUINAS DE COSTURA INDUSTRIAIS; REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314720",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA AS INDUSTRIAS DO VESTUARIO E CONFECCAO, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314720",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA AS INDUSTRIAS DO COURO E CURTUME, REPARACAO E MANUTENCAO DE",
  },
  {
    cnae: "3314721",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA AS INDUSTRIAS DE CELULOSE, PAPEL E PAPELAO, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314722",
    descricao:
      "MAQUINAS PARA A INDUSTRIA DO PLASTICO, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314799",
    descricao:
      "MAQUINAS E APARELHOS PARA A INDUSTRIA DE PRODUTOS QUIMICOS E FARMACEUTICOS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314799",
    descricao:
      "MAQUINAS PARA DESTILARIAS DE ALCOOL, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314799",
    descricao:
      "MAQUINAS PARA A INDUSTRIA DA BORRACHA, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314799",
    descricao:
      "MAQUINAS PARA INDUSTRIA DE ARTEFATOS DE CIMENTO, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314799",
    descricao:
      "MAQUINAS PARA A INDUSTRIA DA MADEIRA, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314799",
    descricao:
      "MAQUINAS PARA AS INDUSTRIAS DE PERFUMARIA, SABOES E VELAS, REPARACAO E MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314799",
    descricao:
      "MAQUINAS E APARELHOS PARA A INDUSTRIA GRAFICA, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3314799",
    descricao:
      "MAQUINAS PARA REFINACAO DE PETROLEO, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "33155",
    descricao: "MANUTENCAO E REPARACAO DE VEICULOS FERROVIARIOS",
  },
  {
    cnae: "3315500",
    descricao:
      "VEICULOS FERROVIARIOS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  { cnae: "33163", descricao: "MANUTENCAO E REPARACAO DE AERONAVES" },
  {
    cnae: "3316301",
    descricao:
      "AERONAVES, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3316301",
    descricao:
      "TURBINAS E MOTORES DE AERONAVES, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3316302",
    descricao:
      "AERONAVES NA PISTA, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  { cnae: "33171", descricao: "MANUTENCAO E REPARACAO DE EMBARCACOES" },
  {
    cnae: "3317101",
    descricao:
      "BARCOS PESQUEIROS; MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3317101",
    descricao:
      "DRAGAS; MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3317101",
    descricao:
      "NAVIOS NO PORTO; MANUTENCAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3317101",
    descricao:
      "LIMPEZA DE CASCO DE NAVIOS; SERVICOS EXECUTADO POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3317101",
    descricao:
      "EMBARCACAO PARA USO DO CORPO DE BOMBEIROS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3317101",
    descricao:
      "NAVIOS PETROLEIROS (GRANDE PORTE), MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3317101",
    descricao:
      "EMBARCACAO PARA USO MILITAR, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3317101",
    descricao:
      "EMBARCACOES PARA TRANPORTE DE PASSAGEIROS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3317101",
    descricao:
      "EMBARCACOES PARA TRANPORTE DE CARGAS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3317101",
    descricao:
      "REBOCADORES, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3317102",
    descricao:
      "PEDALINHOS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3317102",
    descricao:
      "BARCOS A VELA MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3317102",
    descricao:
      "EMBARCACOES PARA ESPORTE E LAZER; REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3317102",
    descricao:
      "IATES E OUTRAS EMBARCACOES DE RECREIO OU ESPORTE, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3317102",
    descricao:
      "LANCHAS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3317102",
    descricao:
      "VELEIROS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3317102",
    descricao:
      "CAIAQUES, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "33198",
    descricao:
      "MANUTENCAO E REPARACAO DE EQUIPAMENTOS E PRODUTOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "3831901",
    descricao: "SUCATAS DE ALUMINIO; TRITURACAO, LIMPEZA E TRIAGEM DE",
  },
  {
    cnae: "3319800",
    descricao:
      "TONEIS, BARRIS E PALETES DE MADEIRA, REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3319800",
    descricao:
      "VEICULOS DE TRACAO ANIMAL, REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3319800",
    descricao: "TAMBORES E TANQUES METALICOS PARA EMBALAGEM, RECUPERACAO DE",
  },
  {
    cnae: "3319800",
    descricao:
      "MOBILIARIO ESPECIFICO PARA USO MEDICO-HOSPITALAR, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  { cnae: "3319800", descricao: "CONTEINERES, MANUTENCAO E REPARACAO" },
  {
    cnae: "3319800",
    descricao:
      "RECUPERACAO E ARTEFATOS METALICOS, N.E.; SERVICOS EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3319800",
    descricao: "CONTENTORES METALICOS (CONTAINERES); REPARACAO DE",
  },
  {
    cnae: "3319800",
    descricao:
      "INSTRUMENTOS NAO-ELETRONICOS PARA USO MEDICO-HOSPITALAR, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3319800",
    descricao: "CARRINHOS PARA SUPERMERCADO; MANUTENCAO OU REPARACAO DE",
  },
  {
    cnae: "3319800",
    descricao:
      "BARRACAS DE ACAMPAMENTO; REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3319800",
    descricao:
      "CORDAS, VELAMES E LONAS, REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "33210",
    descricao: "INSTALACAO DE MAQUINAS E EQUIPAMENTOS INDUSTRIAIS",
  },
  {
    cnae: "3321000",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA USO GERAL, INSTALACAO E MONTAGEM EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA USOS INDUSTRIAIS ESPECIFICOS, INSTALACAO E MONTAGEM EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA USO AGRICOLA, INSTALACAO E MONTAGEM EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA USO NA EXTRACAO MINERAL, INSTALACAO E MONTAGEM EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA INDUSTRIA ALIMENTAR, DE BEBIDAS E FUMO, INSTALACAO E MONTAGEM EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA INDUSTRIA DO PLASTICO, INSTALACAO E MONTAGEM EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "EQUIPAMENTOS E INSTRUMENTOS DE MEDIDA, TESTE E CONTROLE, INSTALACAO E MONTAGEM EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "MOTORES, BOMBAS, COMPRESSORES E EQUIPAMENTOS DE TRANSMISSAO, INSTALACAO E MONTAGEM EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "EQUIPAMENTOS TRANSMISSORES DE RADIO E TELEVISAO, MONTAGEM E INSTALACAO EXECUTADAS POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA TERRAPLENAGEM, PAVIMENTACAO E CONSTRUCAO, INSTALACAO E MONTAGEM EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "ESTUFAS, SECADORES E AUTOCLAVES NAO-ELETRICAS, MONTAGEM E INSTALACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "MAQUINAS-FERRAMENTA, INSTALACAO E MONTAGEM EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA INDUSTRIA TEXTIL, DO VESTUARIO, COURO E CALCADOS, INSTALACAO E MONTAGEM EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "APARELHOS E EQUIPAMENTOS DE IRRADIACAO ELETROMEDICOS E ELETROTERAPEUTICOS, INSTALACAO E MONTAGEM EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "EQUIPAMENTOS E INSTRUMENTOS OPTICOS, INSTALACAO E MONTAGEM EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA INDUSTRIA DE CELULOSE, PAPEL, PAPELAO E SEUS ARTEFATOS, INSTALACAO E MONTAGEM EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA INDUSTRIA METALURGICA, SIDERURGICA OU MECANICA, INSTALACAO E MONTAGEM EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3321000",
    descricao:
      "GERADORES, TRANSFORMADORES, MOTORES E OUTROS EQUIPAMENTOS ELETRICOS, INSTALACAO E MONTAGEM EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "33295",
    descricao: "INSTALACAO DE EQUIPAMENTOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "3329501",
    descricao:
      "MONTAGEM DE MOVEIS DE MADEIRA PARA CONSUMIDOR FINAL, NAO ASSOCIADA AO COMERCIO, QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3329501",
    descricao:
      "MONTAGEM DE MOVEIS DE QUALQUER MATERIAL PARA CONSUMIDOR FINAL, NAO ASSOCIADA AO COMERCIO, QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "3329501",
    descricao:
      "MONTAGEM DE MOVEIS DE MADEIRA PARA CONSUMIDOR FINAL QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA; SERVICO DE",
  },
  {
    cnae: "3329599",
    descricao:
      "EQUIPAMENTOS OU PRODUTOS NAO ESPECIFICADOS, MONTAGEM E INSTALACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  { cnae: "35115", descricao: "GERACAO DE ENERGIA ELETRICA" },
  {
    cnae: "3511501",
    descricao:
      "ENERGIA ELETRICA DE ORIGEM TERMICA (CARVAO E PRODUTOS DERIVADOS); GERACAO, PRODUCAO DE",
  },
  { cnae: "3511501", descricao: "ENERGIA HIDRELETRICA; GERACAO, PRODUCAO DE" },
  {
    cnae: "3511501",
    descricao:
      "ENERGIA ELETRICA DE ORIGEM EOLICA (VENTO); GERACAO, PRODUCAO DE",
  },
  {
    cnae: "3511501",
    descricao: "ENERGIA ELETRICA POR MEIO DE TURBINAS HIDRAULICAS; PRODUCAO DE",
  },
  {
    cnae: "3511501",
    descricao:
      "REDES DE ELETRICIDADE POR EMPRESA PRODUTORA (GERADORA) DE ENERGIA ELETRICA; MANUTENCAO DE",
  },
  {
    cnae: "3511501",
    descricao:
      "ENERGIA ELETRICA ATRAVES DA INCINERACAO DE RESIDUOS; GERACAO, PRODUCAO DE",
  },
  {
    cnae: "3511501",
    descricao:
      "ENERGIA ELETRICA DE ORIGEM TERMICA (DIESEL); GERACAO, PRODUCAO DE",
  },
  { cnae: "3511501", descricao: "ENERGIA ELETRICA (AUTOPRODUTOR)" },
  {
    cnae: "3511501",
    descricao:
      "ENERGIA ELETRICA (HIDRAULICA, TERMICA, NUCLEAR, EOLICA, SOLAR, ETC.); ESTACAO DE GERACAO, PRODUCAO DE",
  },
  {
    cnae: "3511501",
    descricao:
      "ELEMENTOS COMBUSTIVEIS (CARTUCHOS), USADOS (IRRADIADOS)  PARA REATORES NUCLEARES; TRATAMENTO DE",
  },
  {
    cnae: "3511501",
    descricao: "ENERGIA ELETRICA DE ORIGEM HIDRAULICA; GERACAO, PRODUCAO DE",
  },
  {
    cnae: "3511501",
    descricao:
      "ENERGIA ELETRICA POR MEIO DE MOTO-GERADORES DE COMBUSTAO INTERNA; GERACAO, PRODUCAO DE",
  },
  {
    cnae: "3511501",
    descricao: "ELETRICIDADE ATRAVES DA BIOMASSA; GERACAO, PRODUCAO DE",
  },
  {
    cnae: "3511501",
    descricao:
      "ENERGIA ELETRICA (HIDRAULICA, TERMICA, NUCLEAR, EOLICA, SOLAR, ETC.); PRODUCAO INTEGRADA DE",
  },
  {
    cnae: "3511501",
    descricao:
      "CONSUMO DE ENERGIA ELETRICA POR EMPRESA PRODUTORA (GERADORA) DE ENERGIA ELETRICA; MEDICAO DE",
  },
  {
    cnae: "3511501",
    descricao:
      "ENERGIA ELETRICA DE ORIGEM TERMICA (COMBUSTIVEIS RENOVAVEIS); GERACAO DE",
  },
  {
    cnae: "3511501",
    descricao: "ELETRICIDADE GEOTERMICA; GERACAO, PRODUCAO DE",
  },
  {
    cnae: "3511501",
    descricao: "ENERGIA ELETRICA DE ORIGEM SOLAR; GERACAO, PRODUCAO DE",
  },
  {
    cnae: "3511501",
    descricao: "ENERGIA ELETRICA DE ORIGEM TERMICA (GAS); GERACAO, PRODUCAO DE",
  },
  {
    cnae: "3511501",
    descricao: "ENERGIA ELETRICA DE ORIGEM NUCLEAR; GERACAO, PRODUCAO DE",
  },
  {
    cnae: "3511501",
    descricao:
      "ENERGIA ELETRICA DE ORIGEM TERMICA (COMBUSTIVEIS RENOVAVEIS); PRODUCAO DE",
  },
  {
    cnae: "3511501",
    descricao: "ELETRICIDADE ATRAVES DAS MARES; GERACAO, PRODUCAO DE",
  },
  {
    cnae: "3511502",
    descricao:
      "O PLANEJAMENTO E A PROGRAMACAO DA OPERACAO E O DESPACHO CENTRALIZADO DA GERACAO",
  },
  {
    cnae: "3511502",
    descricao:
      "A SUPERVISAO E A COORDENACAO DOS CENTROS DE OPERACAO DE SISTEMAS ELETRICOS;",
  },
  {
    cnae: "3511502",
    descricao:
      "A CONTRATACAO E A ADMINISTRACAO DOS SERVICOS DE TRANSMISSAO DE ENERGIA ELETRICA",
  },
  {
    cnae: "3511502",
    descricao:
      "A SUPERVISAO E O CONTROLE DA OPERACAO DO SIN E DEMAIS INTERLIGACOES INTERNACIONAIS",
  },
  {
    cnae: "3511502",
    descricao:
      "A DIVULGACAO DOS INDICADORES DE DESEMPENHO DOS DESPACHOS REALIZADOS PELO SIN",
  },
  {
    cnae: "3511502",
    descricao:
      "SUPERVISAO E A COORDENACAO DOS CENTROS DE OPERACAO DE SISTEMAS ELETRICOS",
  },
  {
    cnae: "3511502",
    descricao:
      "COORDENACAO E CONTROLE DA OPERACAO DA GERACAO E TRANSMISSAO DE ENERGIA ELETRICA; ATIVIDADES DE",
  },
  {
    cnae: "3511502",
    descricao:
      "A PROPOSICAO AO PODER CONCEDENTE DAS AMPLIACOES E REFORCOS NA REDE BASICA",
  },
  {
    cnae: "3511502",
    descricao:
      "A PROPOSICAO DE REGRAS A OPERACAO DAS INSTALACOES DE TRANSMISSAO DA REDE BASICA DO SIN, CONSOLIDADOS EM PROCEDIMENTOS DE REDE",
  },
  { cnae: "35123", descricao: "TRANSMISSAO DE ENERGIA ELETRICA" },
  {
    cnae: "3512300",
    descricao:
      "SISTEMAS DE TRANSMISSAO DE ENERGIA ELETRICA QUE TRANSPORTAM A ELETRICIDADE RECEBIDA DOS SISTEMAS DE GERACAO PARA OS SISTEMAS DE DISTRIBUICAO; OPERACAO DE",
  },
  { cnae: "3512300", descricao: "ENERGIA ELETRICA; TRANSPORTE DE" },
  { cnae: "3512300", descricao: "ENERGIA ELETRICA; TRANSMISSAO DE" },
  { cnae: "35131", descricao: "COMERCIO ATACADISTA DE ENERGIA ELETRICA" },
  {
    cnae: "3513100",
    descricao: "ENERGIA ELETRICA PRODUZIDA POR TERCEIROS; INTERMEDIACAO DE",
  },
  {
    cnae: "3513100",
    descricao:
      "ENERGIA ELETRICA PRODUZIDA POR TERCEIROS; CORRETAGEM E AGENTES (AGENCIAMENTO) DE",
  },
  {
    cnae: "3513100",
    descricao:
      "ENERGIA ELETRICA, INCLUSIVE IMPORTACAO E EXPORTACAO; COMERCIO ATACADISTA DE",
  },
  { cnae: "3513100", descricao: "ENERGIA ELETRICA; COMERCIO ATACADISTA DE" },
  { cnae: "35140", descricao: "DISTRIBUICAO DE ENERGIA ELETRICA" },
  {
    cnae: "3514000",
    descricao:
      "MEDIDORES DE ELETRICIDADE QUANDO EXECUTADA POR EMPRESAS DE DISTRIBUICAO DE ENERGIA ELETRICA; MANUTENCAO DE",
  },
  {
    cnae: "3514000",
    descricao: "ENERGIA ELETRICA; OPERACAO DE SISTEMAS DE DISTRIBUICAO DE",
  },
  {
    cnae: "3514000",
    descricao:
      "REDES DE ELETRICIDADE QUANDO EXECUTADA POR EMPRESAS DE DISTRIBUICAO DE ENERGIA ELETRICA; MANUTENCAO DE",
  },
  {
    cnae: "3514000",
    descricao:
      "REDES DE DISTRIBUICAO DE ELETRICIDADE QUANDO EXECUTADA POR EMPRESA DE DISTRIBUICAO DE ENERGIA ELETRICA; MANUTENCAO DE",
  },
  {
    cnae: "3514000",
    descricao:
      "CONSUMO DE ELETRICIDADE QUANDO EXECUTADA POR EMPRESA DE DISTRIBUICAO DE ENERGIA ELETRICA; MEDICAO DE",
  },
  {
    cnae: "3514000",
    descricao:
      "SISTEMAS DE TRANSMISSAO DE ENERGIA ELETRICA QUE TRANSPORTAM A ELETRICIDADE RECEBIDA DOS SISTEMAS DE GERACAO OU TRANSMISSAO PARA O CONSUMIDOR FINAL; OPERACAO DE",
  },
  {
    cnae: "35204",
    descricao:
      "PRODUCAO DE GAS, PROCESSAMENTO DE GAS NATURAL, DISTRIBUICAO DE COMBUSTIVEIS GASOSOS POR REDES URBANAS",
  },
  {
    cnae: "3520401",
    descricao: "GAS NATURAL LIQUEFEITO (GNL) (LNG); FABRICACAO DE",
  },
  {
    cnae: "3520401",
    descricao:
      "COMBUSTIVEIS GASOSOS DE ORIGENS DIVERSAS (PURIFICADOS, MISTURADOS, ETC.) POR TUBULACOES, PRODUCAO (ASSOCIADA A DISTRIBUICAO) DE",
  },
  {
    cnae: "3520401",
    descricao:
      "GAS DERIVADO DE FONTES PRIMARIAS DE ENERGIA, POR PROCESSOS QUE ENVOLVEM REACAO QUIMICA (GAS DE CARVAO VEGETAL OU NAFTA); PRODUCAO DE",
  },
  { cnae: "3520401", descricao: "GAS MANUFATURADO; PRODUCAO DE" },
  {
    cnae: "3520401",
    descricao:
      "GAS NATURAL POR TUBULACOES; PRODUCAO (ASSOCIADA A DISTRIBUICAO) DE",
  },
  {
    cnae: "3520401",
    descricao:
      "GAS A PARTIR DA DECOMPOSICAO BIOLOGICA DE MATERIA ORGANICA (RESTOS DE ESTERCO OU LIXO DOMESTICO); OBTENCAO DE",
  },
  {
    cnae: "3520401",
    descricao:
      "COMBUSTIVEIS GASOSOS COM DETERMINADO PODER CALORIFICO OBTIDOS POR PURIFICACAO, MISTURA OU OUTROS TRATAMENTOS A PARTIR DE GASES DE ORIGENS DIVERSAS; PRODUCAO DE",
  },
  {
    cnae: "3520401",
    descricao:
      "GAS DE NAFTA CRAQUEADA POR TUBULACOES; PRODUCAO DE (ASSOCIADA A DISTRIBUICAO)",
  },
  {
    cnae: "3520401",
    descricao:
      "MEDIDORES DE GAS QUANDO EXECUTADA POR EMPRESAS DE PRODUTORAS E DISTRIBUIDORAS; MANUTENCAO DE",
  },
  {
    cnae: "3520402",
    descricao: "DISTRIBUICAO DE GAS; SERVICOS DE UTILIDADE PUBLICA DE",
  },
  {
    cnae: "3520402",
    descricao:
      "MEDIDORES DE GAS, QUANDO EXECUTADA POR EMPRESAS DE DISTRIBUICAO DE COMBUSTIVEIS GASOSOS; MANUTENCAO DE",
  },
  {
    cnae: "3520402",
    descricao:
      "CORRETORES OU AGENTES DE GAS QUE ORGANIZAM A VENDA DE GAS ATRAVES DE SISTEMAS DE DISTRIBUICAO OPERADOS SOB CONTRATO; ATIVIDADES DE",
  },
  {
    cnae: "3520402",
    descricao: "COMBUSTIVEIS GASOSOS; DISTRIBUICAO POR TUBULACOES DE",
  },
  {
    cnae: "3520402",
    descricao:
      "GAS NATURAL LIQUEFEITO (GNL) OU GAS NATURAL COMPRIMIDO (GNC) POR CAMINHOES; DISTRIBUICAO DE",
  },
  {
    cnae: "35301",
    descricao:
      "PRODUCAO E DISTRIBUICAO DE VAPOR, AGUA QUENTE E AR CONDICIONADO",
  },
  { cnae: "3530100", descricao: "VAPOR; GERACAO E DISTRIBUICAO DE" },
  {
    cnae: "3530100",
    descricao: "AGUA GELADA PARA FINS DE RESFRIAMENTO; PRODUCAO DE",
  },
  {
    cnae: "3530100",
    descricao:
      "VAPOR E AGUA QUENTE PARA AQUECIMENTO, ENERGIA MOTRIZ; PRODUCAO, ARMAZENAGEM E DISTRIBUICAO DE",
  },
  {
    cnae: "3530100",
    descricao:
      "VAPOR E AGUA QUENTE PARA CALEFACAO, ENERGIA E OUTROS USOS; PRODUCAO, CAPTACAO E DISTRIBUICAO DE",
  },
  { cnae: "3530100", descricao: "SUPRIMENTO DE AR CONDICIONADO; SERVICO DE" },
  { cnae: "3530100", descricao: "VAPOR; DISTRIBUICAO DE" },
  {
    cnae: "3530100",
    descricao:
      "UTILIDADES INDUSTRIAIS (VAPOR, AGUA, REFRIGERACAO, AR COMPRIMIDO); PRODUCAO DE",
  },
  {
    cnae: "3530100",
    descricao:
      "AR CONDICIONADO PARA EFEITOS DE CLIMATIZACAO DE AMBIENTES; PRODUCAO DE",
  },
  { cnae: "36006", descricao: "CAPTACAO, TRATAMENTO E DISTRIBUICAO DE AGUA" },
  {
    cnae: "3600601",
    descricao:
      "AGUA DE LAGOS PARA FINS DE ABASTECIMENTO; CAPTACAO, TRATAMENTO E DISTRIBUICAO DE",
  },
  { cnae: "3600601", descricao: "CANAIS DE IRRIGACAO; OPERACAO DE" },
  {
    cnae: "3600601",
    descricao:
      "AGUA DE FONTES PARA FINS DE ABASTECIMENTO; CAPTACAO, TRATAMENTO E DISTRIBUICAO DE",
  },
  {
    cnae: "3600601",
    descricao:
      "AGUA DO MAR OU SUBTERRANEA PARA PRODUCAO DE AGUA POTAVEL; DESSALINIZACAO DE",
  },
  {
    cnae: "3600601",
    descricao: "AGUA; CAPTACAO, TRATAMENTO E DISTRIBUICAO DE",
  },
  {
    cnae: "3600601",
    descricao:
      "AGUA DE CHUVA PARA FINS DE ABASTECIMENTO; CAPTACAO, TRATAMENTO E DISTRIBUICAO DE",
  },
  {
    cnae: "3600601",
    descricao: "FILTRAGEM DE AGUA PARA FINS DE ABASTECIMENTO; SERVICO DE",
  },
  {
    cnae: "3600601",
    descricao:
      "SUPRIMENTO DE AGUA PUBLICO OU PRIVADO (EXCETO IRRIGACAO); SERVICO DE",
  },
  {
    cnae: "3600601",
    descricao:
      "AGUA ATRAVES DE UMA REDE PERMANENTE DE LINHAS, TUBULACOES E DUTOS; ARMAZENAGEM EM RESERVATORIOS E A DISTRIBUICAO DE",
  },
  {
    cnae: "3600601",
    descricao:
      "AGUA DE SUBSOLO PARA FINS DE ABASTECIMENTO; CAPTACAO, TRATAMENTO E DISTRIBUICAO DE",
  },
  { cnae: "3600601", descricao: "ABASTECIMENTO DE AGUA; SERVICO DE" },
  {
    cnae: "3600601",
    descricao:
      "CONSUMO DE AGUA QUANDO EXECUTADOS POR EMPRESAS DE CAPTACAO, TRATAMENTO E/OU DISTRIBUICAO DE AGUA, MEDICAO DE",
  },
  {
    cnae: "3600601",
    descricao:
      "AGUA PARA FINS DE ABASTECIMENTO (DESINFECCAO, COAGULACAO, FLOCULACAO, DECANTACAO, FILTRACAO, CORRECAO DO PH E FLUORETACAO); TRATAMENTO E PURIFICACAO DE",
  },
  {
    cnae: "3600601",
    descricao:
      "AGUA DE RIOS PARA FINS DE ABASTECIMENTO; CAPTACAO, TRATAMENTO E DISTRIBUICAO DE",
  },
  {
    cnae: "3600602",
    descricao:
      "AGUA TRATADA (POTAVEL) ATRAVES DE CAMINHAO PIPA; DISTRIBUICAO DE",
  },
  {
    cnae: "3600602",
    descricao: "AGUA TRATADA (POTAVEL) ATRAVES DE CAMINHAO PIPA; TRANSPORTE DE",
  },
  { cnae: "37011", descricao: "GESTAO DE REDES DE ESGOTO" },
  {
    cnae: "3701100",
    descricao:
      "AGUAS RESIDUAIS DE INDUSTRIAS PARA PREVENCAO DA POLUICAO; TRATAMENTO DE",
  },
  { cnae: "3701100", descricao: "REDES DE ESGOTOS INDUSTRIAIS; GESTAO DE" },
  {
    cnae: "3701100",
    descricao:
      "ESGOTO DOMESTICO OU INDUSTRIAL E DE AGUAS PLUVIAIS POR MEIO DE REDES DE COLETORES, TANQUES OU OUTROS MEIOS DE TRANSPORTE; COLETA E TRANSPORTE DE",
  },
  {
    cnae: "3701100",
    descricao:
      "ESGOTO POR MEIO DE PROCESSOS FISICOS, QUIMICOS E BIOLOGICOS, TAIS COMO: A DILUICAO, SELECAO, FILTRAGEM E SEDIMENTACAO; TRATAMENTO DE",
  },
  {
    cnae: "3701100",
    descricao: "ESTACOES DE TRATAMENTO DE ESGOTO (ETE); OPERACAO DE",
  },
  {
    cnae: "3701100",
    descricao: "TRATAMENTO DE EFLUENTE (ESGOTO); SERVICOS DE",
  },
  {
    cnae: "3701100",
    descricao:
      "REDES DE ESGOTOS DOMESTICOS OU INDUSTRIAIS E AGUAS PLUVIAIS; GESTAO DE",
  },
  { cnae: "3701100", descricao: "DRENAGEM DE AGUAS SERVIDAS; SERVICOS DE" },
  { cnae: "3701100", descricao: "REDES DE ESGOTOS DOMESTICOS; GESTAO DE" },
  {
    cnae: "37029",
    descricao: "ATIVIDADES RELACIONADAS A ESGOTO, EXCETO A GESTAO DE REDES",
  },
  {
    cnae: "3702900",
    descricao: "ESVAZIAMENTO DE FOSSAS SEPTICAS; SERVICOS DE",
  },
  { cnae: "3702900", descricao: "RETIRADA DE LAMA; SERVICOS DE" },
  { cnae: "3702900", descricao: "LIMPEZA DE CANAIS URBANOS; SERVICOS DE" },
  {
    cnae: "3702900",
    descricao:
      "TANQUES DE INFILTRACAO E FOSSAS SEPTICAS, SUMIDOUROS E POCOS DE ESGOTO; ESVAZIAMENTO E LIMPEZA DE",
  },
  {
    cnae: "3702900",
    descricao: "LIMPEZA DE TANQUES DE INFILTRACAO; SERVICOS DE",
  },
  {
    cnae: "3702900",
    descricao:
      "CAIXAS DE ESGOTO, GALERIAS DE AGUAS PLUVIAIS E TUBULACOES; LIMPEZA DE",
  },
  { cnae: "3702900", descricao: "LIMPEZA EM SANITARIOS QUIMICOS; SERVICOS DE" },
  { cnae: "3702900", descricao: "LIMPEZA DE GALERIAS PLUVIAIS; SERVICOS DE" },
  {
    cnae: "3702900",
    descricao: "DESENTUPIMENTO DE GALERIAS PLUVIAIS; SERVICOS DE",
  },
  { cnae: "3702900", descricao: "LIMPEZA DE FOSSAS SEPTICAS; SERVICOS DE" },
  { cnae: "38114", descricao: "COLETA DE RESIDUOS NAOPERIGOSOS" },
  { cnae: "3811400", descricao: "ENTULHO; COLETA, REMOCAO E TRANSPORTE" },
  {
    cnae: "3811400",
    descricao: "ESTACOES DE TRANSFERENCIA DE LIXO; GESTAO DE",
  },
  {
    cnae: "3811400",
    descricao:
      "RESIDUOS NAO-PERIGOSOS DE ORIGEM URBANA ATRAVES DE LIXEIRAS, VEICULOS OU CACAMBAS; COLETA DE",
  },
  { cnae: "3811400", descricao: "MATERIAIS RECUPERAVEIS; COLETA DE" },
  {
    cnae: "3811400",
    descricao: "ENTULHOS E REFUGOS DE OBRAS E DEMOLICOES; COLETA DE",
  },
  {
    cnae: "3811400",
    descricao:
      "LIMPEZA URBANA, EXCETO GESTAO DE ATERROS SANITARIOS; SERVICOS DE",
  },
  {
    cnae: "3811400",
    descricao:
      "RESIDUOS NAO-PERIGOSOS DE ORIGEM INDUSTRIAL ATRAVES DE LIXEIRAS, VEICULOS OU CACAMBAS; COLETA DE",
  },
  {
    cnae: "3811400",
    descricao:
      "ESTACOES DE TRANSFERENCIA DE RESIDUOS NAO-PERIGOSOS, RESPONSAVEIS PELO ARMAZENAMENTO TEMPORARIO E A TRANSFERENCIA DEFINITIVA DE RESIDUOS NAO-PERIGOSOS PARA OS ATERROS SANITARIOS OU LIXOES; OPERACAO DE",
  },
  {
    cnae: "3811400",
    descricao: "LIXO URBANO; SERVICOS DE COLETA E TRANSPORTE DE",
  },
  {
    cnae: "3811400",
    descricao: "ENTULHOS APOS O TERMINO DAS OBRAS; RETIRADA DE",
  },
  {
    cnae: "3811400",
    descricao: "RESIDUOS EM PEQUENAS LIXEIRAS PUBLICAS; COLETA DE",
  },
  {
    cnae: "3811400",
    descricao:
      "RESIDUOS NAO-PERIGOSOS DE ORIGEM DOMESTICA ATRAVES DE LIXEIRAS, VEICULOS OU CACAMBAS; COLETA DE",
  },
  { cnae: "3811400", descricao: "REMOCAO DE LIXO URBANO; SERVICOS DE" },
  { cnae: "38122", descricao: "COLETA DE RESIDUOS PERIGOSOS" },
  { cnae: "3812200", descricao: "COLETA DE RESIDUOS TOXICOS; SERVICOS DE" },
  { cnae: "3812200", descricao: "COLETA DE RESIDUOS BIOLOGICOS; SERVICOS DE" },
  {
    cnae: "3812200",
    descricao:
      "RESIDUOS QUE CONTENHAM SUBSTANCIAS OU FORMULACOES INFLAMAVEIS; COLETA DE",
  },
  {
    cnae: "3812200",
    descricao:
      "ESTACOES DE TRANSFERENCIA DE RESIDUOS PERIGOSOS, RESPONSAVEIS PELO ARMAZENAMENTO TEMPORARIO E A TRANSFERENCIA DEFINITIVA DE RESIDUOS PERIGOSOS PARA OS LOCAIS DEFINITIVOS; OPERACAO DE",
  },
  {
    cnae: "3812200",
    descricao:
      "RESIDUOS PERIGOSOS PARA FINS DE TRANSPORTE; IDENTIFICACAO, TRATAMENTO E A ROTULAGEM DE",
  },
  {
    cnae: "3812200",
    descricao:
      "RESIDUOS QUE CONTENHAM SUBSTANCIAS OU FORMULACOES CORROSIVAS; COLETA DE",
  },
  {
    cnae: "3812200",
    descricao:
      "RESIDUOS QUE CONTENHAM SUBSTANCIAS OU FORMULACOES OXIDANTES; COLETA DE",
  },
  {
    cnae: "3812200",
    descricao:
      "RESIDUOS QUE CONTENHAM SUBSTANCIAS OU FORMULACOES TOXICAS; COLETA DE",
  },
  {
    cnae: "3812200",
    descricao:
      "RESIDUOS QUE CONTENHAM SUBSTANCIAS OU FORMULACOES INFECCIOSAS; COLETA DE",
  },
  {
    cnae: "3812200",
    descricao:
      "RESIDUOS PERIGOSOS QUALQUER ESTADO FISICO (SOLIDO, LIQUIDO, PASTOSO, GRANULADO); COLETA DE",
  },
  {
    cnae: "3812200",
    descricao:
      "RESIDUOS QUE CONTENHAM SUBSTANCIAS OU FORMULACOES CANCERIGENAS; COLETA DE",
  },
  {
    cnae: "3812200",
    descricao:
      "RESIDUOS QUE CONTENHAM SUBSTANCIAS OU FORMULACOES IRRITANTES; COLETA DE",
  },
  { cnae: "3812200", descricao: "RESIDUOS BIOLOGICOS PERIGOSOS; COLETA DE" },
  {
    cnae: "3812200",
    descricao: "OLEO USADO DE ESTALEIROS E POSTOS DE COMBUSTIVEIS; COLETA DE",
  },
  { cnae: "3812200", descricao: "COLETA DE RESIDUOS NUCLEARES; SERVICOS DE" },
  {
    cnae: "3812200",
    descricao:
      "RESIDUOS QUE CONTENHAM SUBSTANCIAS OU FORMULACOES EXPLOSIVAS; COLETA DE",
  },
  {
    cnae: "3812200",
    descricao:
      "RESIDUOS QUE CONTENHAM SUBSTANCIAS OU FORMULACOES PREJUDICIAIS A SAUDE HUMANA AO MEIO AMBIENTE; COLETA DE",
  },
  {
    cnae: "3812200",
    descricao:
      "LIXO HOSPITALAR; SERVICO DE COLETA, ACONDICIONAMENTO E TRANSPORTE DE",
  },
  {
    cnae: "3812200",
    descricao: "COLETA DE PILHAS OU BATERIAS USADAS; SERVICOS DE",
  },
  {
    cnae: "38211",
    descricao: "TRATAMENTO E DISPOSICAO DE RESIDUOS NAOPERIGOSOS",
  },
  {
    cnae: "3821100",
    descricao:
      "RESIDUOS NAO-PERIGOSOS PELA COMBUSTAO OU INCINERACAO, COM OU SEM OBJETIVO DE GERACAO DE ELETRICIDADE OU VAPOR, CINZAS OU OUTROS SUBPRODUTOS PARA POSTERIOR APROVEITAMENTO; ELIMINACAO DE",
  },
  { cnae: "3821100", descricao: "USINAS INCINERADORAS DE LIXO; GESTAO DE" },
  {
    cnae: "3821100",
    descricao:
      "RESIDUOS NAO-PERIGOSOS EM LOCAIS DE DISPOSICAO CONTROLADA OU VAZADOUROS; DESPEJO DE",
  },
  {
    cnae: "3821100",
    descricao:
      "DEPOSITOS DE LIXO E ATERROS SANITARIOS PARA DISPOSICAO DE RESIDUOS NAO-PERIGOSOS; OPERACAO DE",
  },
  { cnae: "3821100", descricao: "INCINERACAO DE LIXO; SERVICOS DE" },
  { cnae: "3821100", descricao: "ATERROS SANITARIOS; GESTAO DE" },
  { cnae: "38220", descricao: "TRATAMENTO E DISPOSICAO DE RESIDUOS PERIGOSOS" },
  {
    cnae: "3822000",
    descricao:
      "RESIDUOS PERIGOSOS EM QUALQUER ESTADO FISICO (SOLIDO, LIQUIDO, PASTOSO, GRANULADO, ETC.); TRATAMENTO E DISPOSICAO DE",
  },
  {
    cnae: "3822000",
    descricao: "TRATAMENTO DE RESIDUOS NUCLEARES; SERVICO DE",
  },
  {
    cnae: "3822000",
    descricao: "RESIDUOS RADIOATIVOS; TRATAMENTO E DISPOSICAO DE",
  },
  {
    cnae: "3822000",
    descricao: "RESIDUOS CONTAMINADOS; TRATAMENTO E DISPOSICAO DE",
  },
  {
    cnae: "3822000",
    descricao: "RESIDUOS PERIGOSOS; INCINERACAO OU COMBUSTAO DE",
  },
  {
    cnae: "3822000",
    descricao:
      "ANIMAIS INTOXICADOS (VIVOS OU MORTOS); TRATAMENTO E DISPOSICAO DE",
  },
  {
    cnae: "3822000",
    descricao:
      "RESIDUOS RADIOATIVOS PARA ARMAZENAGEM; ENCAPSULACAO E PREPARACAO DE",
  },
  {
    cnae: "3822000",
    descricao: "TRATAMENTO E ELIMINACAO DE RESIDUOS TOXICOS; SERVICOS DE",
  },
  {
    cnae: "3822000",
    descricao:
      "RESIDUOS DE TRANSICAO RADIOATIVOS (DIMINUINDO A RADIOATIVIDADE DENTRO DO PERIODO DE TRANSPORTE); TRATAMENTO E DISPOSICAO DE",
  },
  { cnae: "3822000", descricao: "RESIDUOS RADIOATIVOS; TRATAMENTO DE" },
  { cnae: "38319", descricao: "RECUPERACAO DE MATERIAIS METALICOS" },
  { cnae: "3831901", descricao: "LATAS DE ALUMINIO USADAS; RECUPERACAO DE" },
  { cnae: "3831901", descricao: "SUCATAS DE ALUMINIO; SELECAO DE" },
  { cnae: "3831901", descricao: "PECAS DE ALUMINIO; RECUPERACAO DE" },
  {
    cnae: "3831901",
    descricao: "LATAS DE ALUMINIO USADAS; SELECAO, CLASSIFICACAO E TRIAGEM DE",
  },
  {
    cnae: "3831901",
    descricao: "DESPERDICIOS DIVERSOS DE ALUMINIO; RECUPERACAO DE",
  },
  { cnae: "3831901", descricao: "SUCATAS DE ALUMINIO; RECUPERACAO DE" },
  { cnae: "3831901", descricao: "SUCATAS DE ALUMINIO; COMPACTACAO DE" },
  {
    cnae: "3831999",
    descricao: "PECAS DE FERRO VOLUMOSAS, REDUCAO MECANICA DE",
  },
  {
    cnae: "3831999",
    descricao:
      "DESMANCHE DE VEICULOS AUTOMOTORES, SEM COMERCIALIZACAO DE PARTES, PECAS E ACESSORIOS; SERVICOS DE",
  },
  {
    cnae: "3831999",
    descricao:
      "METAIS FERROSOS E NAO-FERROSOS DESCARTADOS, TRITURACAO PARA A RECUPERACAO DE",
  },
  {
    cnae: "3831999",
    descricao:
      "SUCATAS DE FERRO, ACO, METAIS FERROSOS E NAO FERROSOS, SELECAO DE",
  },
  {
    cnae: "3831999",
    descricao:
      "PECAS DE FERRO VOLUMOSAS, REDUCAO MECANICA PARA A RECUPERACAO DE",
  },
  { cnae: "3831999", descricao: "SUCATAS DE BRONZE, RECUPERACAO DE" },
  { cnae: "3831999", descricao: "SUCATAS DE FERRO E ACO, RECUPERACAO DE" },
  { cnae: "3831999", descricao: "SUCATAS DE ACO INOX, RECUPERACAO DE" },
  { cnae: "3831999", descricao: "SUCATAS DE CHUMBO, RECUPERACAO DE" },
  { cnae: "3831999", descricao: "SUCATAS DE ANTIMONIO, RECUPERACAO DE" },
  {
    cnae: "3831999",
    descricao: "BENS USADOS (AUTOMOVEIS, GELADEIRAS, ETC.), DESMANTELAMENTOS",
  },
  { cnae: "3831999", descricao: "SUCATAS DE ESTANHO, RECUPERACAO DE" },
  {
    cnae: "3831999",
    descricao:
      "MATERIAIS METALICOS PARA FINS DE RECUPERACAO, SELECAO E CLASSIFICACAO DE",
  },
  { cnae: "3831999", descricao: "EMBARCACOES; DESMANTELAMENTO DE" },
  { cnae: "3831999", descricao: "NAVIOS, DESMONTAGEM DE" },
  {
    cnae: "3831999",
    descricao:
      "SUCATAS DE METAIS FERROSOS E NAO-FERROSOS (EXCETO DE ALUMINIO), RECICLAGEM DE",
  },
  {
    cnae: "3831999",
    descricao:
      "SUCATAS E FERRAGENS METALICAS (METAIS FERROSOS E NAO FERROSOS), COMPACTACAO DE",
  },
  {
    cnae: "3831999",
    descricao:
      "SUCATAS (AUTOMOVEIS, MAQUINAS DE LAVAR, ETC.), TRITURACAO, LIMPEZA E TRIAGEM DE",
  },
  { cnae: "3831999", descricao: "SUCATAS DE ZAMAC (ZAMAK), RECUPERACAO DE" },
  { cnae: "3831999", descricao: "SUCATAS DE LATAO, RECUPERACAO DE" },
  {
    cnae: "3831999",
    descricao:
      "BENS USADOS (AUTOMOVEIS, GELADEIRAS, ETC.), DESMANTELAMENTOS DE",
  },
  {
    cnae: "3831999",
    descricao:
      "METAIS FERROSOS E NAO-FERROSOS, EXCETO ALUMINIO, RECUPERACAO DE",
  },
  {
    cnae: "3831999",
    descricao:
      "METAIS FERROSOS E NAO-FERROSOS DESCARTADOS, REDUCAO MECANICA PARA A RECUPERACAO DE",
  },
  { cnae: "3831999", descricao: "SUCATAS DE COBRE, RECUPERACAO DE" },
  {
    cnae: "3831999",
    descricao:
      "MATERIAIS METALICOS, REDUCAO DE VOLUME PARA A RECUPERACAO DE (CORTE, PRENSAGEM OU OUTROS METODOS DE TRATAMENTO MECANICO)",
  },
  {
    cnae: "3831999",
    descricao: "METAIS DE RESIDUOS FOTOGRAFICOS, RECUPERACAO DE",
  },
  {
    cnae: "3831999",
    descricao:
      "METAIS FERROSOS E NAO-FERROSOS DESCARTADOS, SELECAO PARA A RECUPERACAO DE",
  },
  { cnae: "38327", descricao: "RECUPERACAO DE MATERIAIS PLASTICOS" },
  {
    cnae: "3832700",
    descricao:
      "TRITURACAO, GRANULACAO OU DE MOAGEM DE MATERIAIS PLASTICOS DESCARTADOS; SERVICO DE",
  },
  {
    cnae: "3832700",
    descricao:
      "GRANULOS A PARTIR DA RECUPERACAO DE MATERIAIS PLASTICOS DESCARTADOS; OBTENCAO DE",
  },
  {
    cnae: "3832700",
    descricao:
      "EMBALAGENS PLASTICAS USADAS; SELECAO, CLASSIFICACAO E TRIAGEM DE",
  },
  {
    cnae: "3832700",
    descricao: "MATERIAIS PLASTICOS DESCARTADOS; RECUPERACAO DE",
  },
  {
    cnae: "3832700",
    descricao: "SUCATAS DE MATERIAIS PLASTICOS; COMPACTACAO DE",
  },
  { cnae: "3832700", descricao: "GARRAFAS PET; RECUPERACAO DE" },
  {
    cnae: "3832700",
    descricao:
      "MATERIAIS PLASTICOS EM GRAOS (GRANULOS) A PARTIR DE SUCATAS OU RESIDUOS DESCARTADOS DE PLASTICO; OBTENCAO DE",
  },
  {
    cnae: "3832700",
    descricao:
      "SUCATAS DE MATERIAIS PLASTICOS PARA GRANULAGEM; PROCESSAMENTO DE (LIMPEZA, DERRETIMENTO, TRITURACAO)",
  },
  {
    cnae: "3832700",
    descricao:
      "SUCATAS DE MATERIAIS PLASTICOS; TRITURACAO, LIMPEZA E TRIAGEM DE",
  },
  {
    cnae: "3832700",
    descricao: "SUCATAS DE MATERIAIS PLASTICOS; REDUCAO MECANICA DE",
  },
  {
    cnae: "38394",
    descricao: "RECUPERACAO DE MATERIAIS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "3839401",
    descricao:
      "COMPOSTOS ORGANICOS PARA FERTILIZACAO DO SOLO A PARTIR DE PROCESSO DE DEGRADACAO BIOLOGICA DE RESIDUOS ORGANICOS (RESTOS DE ALIMENTOS, ESTERCOS ANIMAIS, RESTOS DE CULTURAS AGRICOLAS); OBTENCAO DE",
  },
  {
    cnae: "3839499",
    descricao:
      "TRIAGEM E RECUPERACAO DE MATERIAIS DESCARTADOS DA COLETA SELETIVA, EXCETO MATERIAIS PLASTICOS E METALICOS; SERVICOS DE",
  },
  {
    cnae: "3839499",
    descricao: "VIDRO, SELECAO, TRITURACAO, LIMPEZA E TRIAGEM DE",
  },
  { cnae: "3839499", descricao: "BORRACHA DE PNEUS USADOS, RECUPERACAO DE" },
  { cnae: "3839499", descricao: "PAPEL, PAPELAO E APARAS, RECUPERACAO DE" },
  { cnae: "3839499", descricao: "MATERIAL TEXTIL, COMPACTACAO DE" },
  {
    cnae: "3839499",
    descricao: "BORRACHA DE PNEUS USADOS, REDUCAO MECANICA DE",
  },
  { cnae: "3839499", descricao: "BORRACHA DE PNEUS USADOS, COMPACTACAO DE" },
  { cnae: "3839499", descricao: "MATERIAL TEXTIL, RECUPERACAO DE" },
  { cnae: "3839499", descricao: "BORRACHA, COMPACTACAO PARA RECUPERACAO DE" },
  {
    cnae: "3839499",
    descricao: "MATERIAIS DE BATERIAS USADAS, RECUPERACAO DE",
  },
  {
    cnae: "3839499",
    descricao: "PAPEL, PAPELAO E APARAS, REDUCAO MECANICA PARA RECUPERACAO DE",
  },
  {
    cnae: "3839499",
    descricao: "PAPEL, PAPELAO E APARAS, COMPACTACAO PARA RECUPERACAO DE",
  },
  {
    cnae: "3839499",
    descricao: "DESPERDICIOS DE MADEIRA, REDUCAO MECANICA PARA RECUPERACAO DE",
  },
  { cnae: "3839499", descricao: "VIDRO, REDUCAO MECANICA PARA RECUPERACAO DE" },
  {
    cnae: "3839499",
    descricao: "MATERIAL TEXTIL; TRIAGEM PARA RECUPERACAO DE",
  },
  { cnae: "3839499", descricao: "DESPERDICIOS DE MADEIRA, RECUPERACAO DE" },
  {
    cnae: "3839499",
    descricao:
      "TRIAGEM E RECUPERACAO DE MATERIAIS DESCARTADOS DA COLETA DOMICILIAR, EXCETO MATERIAIS PLASTICOS E METALICOS; SERVICOS DE",
  },
  {
    cnae: "3839499",
    descricao:
      "PAPEL, PAPELAO E APARAS, SELECAO, TRITURACAO, LIMPEZA E TRIAGEM DE",
  },
  {
    cnae: "3839499",
    descricao: "RESIDUOS CONTENDO PRODUTOS QUIMICOS, RECUPERACAO DE",
  },
  {
    cnae: "3839499",
    descricao:
      "OLEOS E LUBRIFICANTES USADOS, COMO MATERIA-PRIMA SECUNDARIA; PROCESSAMENTO E RECUPERACAO DE",
  },
  {
    cnae: "3839499",
    descricao: "MATERIAIS DE BATERIAS USADAS, TRIAGEM PARA RECUPERACAO DE",
  },
  {
    cnae: "3839499",
    descricao: "DESPERDICIOS DE MADEIRA, COMPACTACAO PARA RECUPERACAO DE",
  },
  { cnae: "3839499", descricao: "VIDRO, COMPACTACAO PARA RECUPERACAO DE" },
  {
    cnae: "3839499",
    descricao: "MATERIAL TEXTIL, REDUCAO MECANICA PARA RECUPERACAO DE",
  },
  {
    cnae: "3839499",
    descricao: "SUBSTANCIAS QUIMICAS A PARTIR DE DESPERDICIOS; REGENERACAO DE",
  },
  {
    cnae: "3839499",
    descricao:
      "BORRACHA DE PNEUS USADOS, SELECAO, TRITURACAO, LIMPEZA E TRIAGEM PARA RECUPERACAO DE",
  },
  { cnae: "3839499", descricao: "VIDRO, RECUPERACAO DE" },
  { cnae: "3839499", descricao: "BORRACHA, RECUPERACAO DE" },
  {
    cnae: "3839499",
    descricao:
      "DESPERDICIOS DE MADEIRA, SELECAO, TRITURACAO, LIMPEZA E TRIAGEM PARA RECUPERACAO DE",
  },
  {
    cnae: "3839499",
    descricao: "BORRACHA, REDUCAO MECANICA PARA RECUPERACAO DE",
  },
  {
    cnae: "39005",
    descricao: "DESCONTAMINACAO E OUTROS SERVICOS DE GESTAO DE RESIDUOS",
  },
  { cnae: "3900500", descricao: "SOLO CONTAMINADO; LIMPEZA DE" },
  {
    cnae: "3900500",
    descricao: "SOLO ATRAVES DE DESSORTEACAO EM REATOR, DESCONTAMINACAO DO",
  },
  {
    cnae: "3900500",
    descricao:
      "SOLO ATRAVES DE COMBUSTAO, PIROLISE OU INCINERACAO, DESCONTAMINACAO DO",
  },
  {
    cnae: "3900500",
    descricao:
      "SOLO ATRAVES DE PROCESSOS DE VITRIFICACAO OU ELETROCINETICOS, DESCONTAMINACAO DO",
  },
  {
    cnae: "3900500",
    descricao: "SOLO ATRAVES DE REATORES BIOLOGICOS, DESCONTAMINACAO DO",
  },
  {
    cnae: "3900500",
    descricao:
      "SOLO OU AGUA ATRAVES DE PROCESSOS FISICOS, QUIMICOS, TERMICOS, BIOLOGICOS, ISOLAMENTO OU CONFINAMENTO, DESCONTAMINACAO DO",
  },
  {
    cnae: "3900500",
    descricao:
      "SOLO ATRAVES DE LANDFARMING, COMETABOLISMO OU DESNITRIFICACAO, DESCONTAMINACAO DO",
  },
  {
    cnae: "3900500",
    descricao: "SOLO ATRAVES DE LAVAGEM OU EXTRACAO, DESCONTAMINACAO DO",
  },
  { cnae: "41107", descricao: "INCORPORACAO DE EMPREENDIMENTOS IMOBILIARIOS" },
  {
    cnae: "4110700",
    descricao: "EMPREENDIMENTOS IMOBILIARIOS; INCORPORACAO DE",
  },
  {
    cnae: "4110700",
    descricao:
      "EMPREENDIMENTOS IMOBILIARIOS, RESIDENCIAIS OU NAO, PROVENDO RECURSOS FINANCEIROS, TECNICOS E MATERIAIS PARA A SUA EXECUCAO (VISANDO A VENDA TOTAL OU PARCIAL DAS UNIDADES CONSTRUIDAS); PROMOCAO E REALIZACAO DE",
  },
  { cnae: "41204", descricao: "CONSTRUCAO DE EDIFICIOS" },
  { cnae: "4120400", descricao: "PREDIOS COMERCIAIS, CONSTRUCAO DE" },
  { cnae: "4120400", descricao: "GUARITAS, CONSTRUCAO DE" },
  {
    cnae: "4120400",
    descricao:
      "IGREJAS, TEMPLOS E SIMILARES,SINAGOGAS, MESQUITAS, CATEDRAIS E OUTROS TIPOS DE CONSTRUCOES PARA FINS RELIGIOSOS, CONSTRUCAO E/OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao:
      "APARTAMENTOS, CASAS, CONJUNTOS HABITACIONAIS, PREDIOS, EDIFICIOS, EDIFICACOES, CONDOMINIOS, RESIDENCIAS, ETC., CONSTRUCAO DE",
  },
  { cnae: "4120400", descricao: "PREDIOS INDUSTRIAIS, CONSTRUCAO DE" },
  {
    cnae: "4120400",
    descricao:
      "EDIFICIOS PRE-MOLDADOS OU PRE-FABRICADOS DE QUALQUER MATERIAL, DE NATUREZA PERMANENTE OU TEMPORARIA QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA, MONTAGEM DE",
  },
  {
    cnae: "4120400",
    descricao: "TERMINAIS RODOVIARIOS, FERROVIARIOS, CONSTRUCAO DE",
  },
  {
    cnae: "4120400",
    descricao:
      "PRISOES, PRESIDIOS, DELEGACIAS, BATALHOES, FORTES, FORTALEZA, CONSTRUCAO DE",
  },
  {
    cnae: "4120400",
    descricao:
      "ESCOLAS, FACULDADES, UNIVERSIDADES, COLEGIOS, CRECHES E OUTROS EDIFICIOS DESTINADOS AO ENSINO, CONSTRUCAO OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao:
      "CASAS PRE-FABRICADAS, KITS DE HABITACAO, ETC.; MONTAGEM DE (QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA)",
  },
  { cnae: "4120400", descricao: "CASA DE GUARDA; CONSTRUCAO OU REFORMA DE" },
  {
    cnae: "4120400",
    descricao: "CLINICAS, HOSPITAIS; CONSTRUCAO OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao:
      "EDIFICIOS RESIDENCIAIS MULTIFAMILIARES, INCLUSIVE ARRANHA-CEUS, CONSTRUCAO OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao:
      "RESTAURANTES, BARES, LANCHONETES, CAFES, LANCHERIAS, PADARIAS, CANTINAS, REFEITORIOS E OUTROS ESTABELECIMENTOS DESTINADOS A SERVIR REFEICOES, CONSTRUCAO E/OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao:
      "HOSPITAIS, POSTOS DE SAUDE, ASILOS, CASAS DE REPOUSO, SPAS, ORFANATOS, CONSTRUCAO OU REFORMA DE",
  },
  { cnae: "4120400", descricao: "TERMINAIS AEREOS, CONSTRUCAO DE" },
  { cnae: "4120400", descricao: "CONSTRUTORES RESIDENCIAIS" },
  {
    cnae: "4120400",
    descricao:
      "PALACIOS, CASTELOS, PALACETES, MANSOES, SOLARES, ETC., CONSTRUCAO OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao:
      "REFORMAS EM EDIFICACOES NAO RESIDENCIAIS: ESCRITORIOS, LOJAS, PLANTAS INDUSTRIAIS, PREDIOS GOVERNAMENTAIS, OBRAS DE",
  },
  {
    cnae: "4120400",
    descricao:
      "FABRICAS, OFICINAS, GALPOES E OUTROS TIPOS DE EDIFICIO PARA FINS INDUSTRIAIS, CONSTRUCAO OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao:
      "EDIFICACOES PRE-FABRICADAS, MONTAGEM DE (QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA)",
  },
  {
    cnae: "4120400",
    descricao:
      "ESTACOES DE PASSAGEIROS PARA TRENS, BONDES E METROPOLITANOS (METRO), CONSTRUCAO OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao:
      "EDIFICIOS GARAGEM E GARAGENS SUBTERRANEAS, CONSTRUCAO OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao:
      "EDIFICACOES PRE MOLDADAS, QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA, MONTAGEM DE",
  },
  { cnae: "4120400", descricao: "IMOVEIS; REFORMA DE" },
  {
    cnae: "4120400",
    descricao:
      "HOTEIS, MOTEIS, POUSADAS, PENSOES, ALBERGUES, CASAS DE REPOUSO, SPAS, ASILOS E OUTROS TIPOS DE ALOJAMENTOS E ABRIGOS, CONSTRUCAO E/OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao:
      "INSTALACOES PARA EMBARQUE E DESEMBARQUE DE PASSAGEIROS (AEROPORTOS, RODOVIARIAS, PORTOS, ETC.), CONSTRUCAO OU REFORMA DE",
  },
  { cnae: "4120400", descricao: "REPARACAO DE IMOVEIS" },
  { cnae: "4120400", descricao: "EDIFICIOS DE APARTAMENTOS, CONSTRUCAO DE" },
  {
    cnae: "4120400",
    descricao:
      "ESTABULOS E OUTROS EDIFICIOS DESTINADOS AO USO AGROPECUARIO, CONSTRUCAO OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao:
      "APARTAMENTOS, CASAS, CONJUNTOS HABITACIONAIS, PREDIOS, EDIFICIOS, EDIFICACOES, CONDOMINIOS, RESIDENCIAS, ETC., REFORMAS EM",
  },
  {
    cnae: "4120400",
    descricao: "EDIFICACOES RESIDENCIAIS, CONSTRUCAO (CONSTRUTOR) E REFORMA EM",
  },
  {
    cnae: "4120400",
    descricao: "EDIFICIOS COMERCIAIS, CONSTRUCAO OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao:
      "APARTAMENTOS, CASAS, CONJUNTOS HABITACIONAIS, PREDIOS, EDIFICIOS, EDIFICACOES, CONDOMINIOS, RESIDENCIAS, ETC., REFORMA DE (CONSTRUTOR GERAL)",
  },
  {
    cnae: "4120400",
    descricao:
      "GALPOES, MONTAGEM DE (QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA)",
  },
  { cnae: "4120400", descricao: "PLANTAS DE INCINERACAO, CONSTRUCAO DE" },
  {
    cnae: "4120400",
    descricao:
      "CABINES (CONTEINERES) PARA USOS RESIDENCIAL, COMERCIAL OU INDUSTRIAL; CONSTRUCAO DE",
  },
  {
    cnae: "4120400",
    descricao: "ESCRITORIOS COMERCIAIS, CONSTRUCAO OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao:
      "CENTROS COMERCIAIS, GALERIAS, SHOPPING CENTERS, LOJAS COMERCIAIS, ETC.; CONSTRUCAO OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao:
      "LOJAS, GALERIAS, CENTROS COMERCIAIS E SHOPPING CENTERS CONSTRUCAO DE",
  },
  {
    cnae: "4120400",
    descricao: "POSTOS DE COMBUSTIVEL (GASOLINA), CONSTRUCAO OU REFORMA DE",
  },
  { cnae: "4120400", descricao: "INSTALACOES INDUSTRIAIS, EDIFICACOES" },
  {
    cnae: "4120400",
    descricao: "FORTES E FORTALEZAS, CONSTRUCAO OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao: "EDIFICACOES PARA FINS CULTURAIS OU RECREATIVOS, CONSTRUCAO DE",
  },
  {
    cnae: "4120400",
    descricao:
      "EDIFICACOES NAO RESIDENCIAIS: ESCRITORIOS, LOJAS, PLANTAS INDUSTRIAIS, PREDIOS GOVERNAMENTAIS E ETC., OBRAS DE REFORMAS EM",
  },
  { cnae: "4120400", descricao: "HANGARES, CONSTRUCAO DE" },
  {
    cnae: "4120400",
    descricao:
      "ESTADIOS ESPORTIVOS, QUADRAS COBERTAS, ACADEMIAS DE GINASTICA, ETC. CONSTRUCAO OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao:
      "CINEMAS, TEATROS, CLUBES, CIRCOS, SALAS DE ESPETACULOS, PARQUES DE DIVERSAO, ETC.; CONSTRUCAO OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao: "CONSULTORIOS E CLINICAS MEDICAS, CONSTRUCAO OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao: "ARMAZENS, SILOS, DEPOSITOS E ETC.; CONSTRUCAO OU REFORMA DE",
  },
  {
    cnae: "4120400",
    descricao: "CABINES SANITARIAS DE CONCRETO E PVC; CONSTRUCAO DE",
  },
  { cnae: "4120400", descricao: "REFINARIAS, CONSTRUCAO DE" },
  {
    cnae: "4120400",
    descricao:
      "CASAS, RESIDENCIAS, MORADIAS, APARTAMENTOS, ETC. UNIFAMILIARES, CONSTRUCAO OU REFORMA DE",
  },
  { cnae: "42111", descricao: "CONSTRUCAO DE RODOVIAS E FERROVIAS" },
  { cnae: "4211101", descricao: "LINHAS FERREAS, CONSTRUCAO DE" },
  {
    cnae: "4211101",
    descricao: "RODOVIAS, INCLUSIVE PAVIMENTACAO; MANUTENCAO DE",
  },
  {
    cnae: "4211101",
    descricao: "REVESTIMENTO DE PAVIMENTACAO ASFALTICA, RECOMPOSICAO DE",
  },
  {
    cnae: "4211101",
    descricao:
      "RODOVIAS, INCLUSIVE PAVIMENTACAO, CONSTRUCAO E/OU MANUTENCAO DE",
  },
  { cnae: "4211101", descricao: "BACIAS DE AMORTECIMENTO, CONSTRUCAO DE" },
  { cnae: "4211101", descricao: "PISTAS DE AEROPORTOS, CONSTRUCAO DE" },
  { cnae: "4211101", descricao: "LIGANTE ASFALTICO, APLICACAO DE" },
  { cnae: "4211101", descricao: "ESTRADAS DE FERRO, CONSTRUCAO DE" },
  {
    cnae: "4211101",
    descricao: "CONSTRUCAO DE VIAS PARA METROPOLITANOS, EMPREITEIRO DE",
  },
  { cnae: "4211101", descricao: "FRESAGEM ASFALTICA, EXECUCAO DE" },
  { cnae: "4211101", descricao: "CONSERVACAO RODOVIARIA" },
  {
    cnae: "4211101",
    descricao: "PAVIMENTACAO (ASFALTO, CIMENTO), EM RODOVIAS, OBRAS DE",
  },
  {
    cnae: "4211101",
    descricao: "CONSTRUCAO DE VIAS PARA METROPOLITANOS, CONSTRUTOR GERAL",
  },
  { cnae: "4211101", descricao: "PAVIMENTO FIXO E/OU FLEXIVEL, CONSTRUCAO DE" },
  {
    cnae: "4211101",
    descricao: "FRISAGEM DE ESTRADAS E RODOVIAS; EXECUCAO DE",
  },
  {
    cnae: "4211101",
    descricao: "AREIA-ASFALTO (A QUENTE E A FRIO), APLICACAO DE",
  },
  {
    cnae: "4211101",
    descricao:
      "REVESTIMENTO, BASE, SUB-BASE DE ESTRADAS E RODOVIAS, CONSTRUCAO DE",
  },
  { cnae: "4211101", descricao: "RECAPEAMENTO DE RODOVIAS, OBRAS DE" },
  {
    cnae: "4211101",
    descricao:
      "BANQUETAS, VALETAS E ABAULAMENTOS DE LEITOS DE ESTRADAS OU RODOVIAS, EXECUCAO DE",
  },
  { cnae: "4211101", descricao: "RECAPEAMENTO ASFALTICO" },
  {
    cnae: "4211101",
    descricao: "CONCRETO BETUMINOSO USINADO A QUENTE (CBUQ), APLICACAO DE",
  },
  { cnae: "4211101", descricao: "SUB-LEITO, REFORCO DE" },
  {
    cnae: "4211101",
    descricao: "TAPA-BURACO COM MISTURA BETUMINOSA, EXECUCAO DE",
  },
  {
    cnae: "4211101",
    descricao: "IMPRIMACAO (LIGANTE BETUMINOSO), EXECUCAO DE",
  },
  { cnae: "4211101", descricao: "AUTO-ESTRADAS, CONSTRUCAO DE" },
  {
    cnae: "4211101",
    descricao: "CONSTRUCAO DE VIAS PARA METROPOLITANOS, CONTRATANTE",
  },
  { cnae: "4211101", descricao: "ESTRADAS, CONSTRUCAO DE" },
  { cnae: "4211101", descricao: "PINTURA DE LIGACAO, EXECUCAO DE" },
  { cnae: "4211101", descricao: "MACADAME BETUMINOSO, APLICACAO DE" },
  {
    cnae: "4211101",
    descricao: "CAIXAS COLETORAS DE AGUAS PLUVIAIS, CONSTRUCAO DE",
  },
  { cnae: "4211101", descricao: "FRISAGEM ASFALTICA, EXECUCAO DE" },
  {
    cnae: "4211101",
    descricao:
      "ESTRUTURAS INFERIOR E SUPERIOR DE ESTRADAS E RODOVIAS, CONSTRUCAO DE",
  },
  { cnae: "4211101", descricao: "FERROVIAS, CONSTRUCAO DE" },
  { cnae: "4211101", descricao: "REVESTIMENTO ASFALTICO, EXECUCAO DE" },
  { cnae: "4211101", descricao: "ASFALTO, PAVIMENTACAO DE RODOVIAS, OBRAS DE" },
  {
    cnae: "4211101",
    descricao: "BACIAS DE CAPTACAO DE AGUAS PLUVIAIS, CONSTRUCAO DE",
  },
  { cnae: "4211101", descricao: "REFORMA DE ESTRADAS E RODOVIAS" },
  {
    cnae: "4211101",
    descricao:
      "DESCIDAS D'AGUA, BIGODES, SARJETAS E OUTRAS OBRAS DE ESCOAMENTO, CONSTRUCAO EM ESTRADAS E RODOVIAS",
  },
  { cnae: "4211101", descricao: "ESTRADAS; MANUTENCAO DE" },
  { cnae: "4211101", descricao: "CONSTRUCAO RODOVIARIA, EMPREITEIRO DE" },
  { cnae: "4211101", descricao: "RECUPERACAO DE ESTRADAS E RODOVIAS" },
  { cnae: "4211101", descricao: "OBRAS VIARIAS, CONSTRUCAO DE" },
  { cnae: "4211101", descricao: "SOLO-BETUME, EXECUCAO DE" },
  {
    cnae: "4211101",
    descricao: "VIAS FERREAS, INCLUSIVE PARA METROPOLITANOS, CONSTRUCAO DE",
  },
  { cnae: "4211101", descricao: "FERROVIAS; MANUTENCAO DE" },
  { cnae: "4211101", descricao: "PRE-MISTURADO A FRIO (PMF), APLICACAO DE" },
  { cnae: "4211101", descricao: "LIGANTE BETUMINOSO, APLICACAO DE" },
  { cnae: "4211101", descricao: "LEITO ESTRADAL, RECUPERACAO DE" },
  { cnae: "4211101", descricao: "REPARACAO DE ESTRADAS E RODOVIAS" },
  {
    cnae: "4211101",
    descricao:
      "BANQUETAS, VALETAS E ABAULAMENTOS DE LEITOS DE ESTRADAS OU RODOVIAS; MANUTENCAO DE",
  },
  {
    cnae: "4211101",
    descricao: "FRESAGEM DE ESTRADAS OU RODOVIAS; EXECUCAO DE",
  },
  {
    cnae: "4211101",
    descricao: "RECUPERACAO ASFALTICA DE ESTRADAS E RODOVIAS",
  },
  {
    cnae: "4211101",
    descricao:
      "TRATAMENTO SUPERFICIAL (SIMPLES, DUPLO E TRIPLO) DE ESTRADAS E RODOVIAS (LIGANTE ASFALTICO)",
  },
  { cnae: "4211101", descricao: "BUEIROS, RECUPERACAO DE" },
  {
    cnae: "4211101",
    descricao: "BUEIROS (DE TALVEGUE / GROTA E DE GREIDE), CONSTRUCAO DE",
  },
  {
    cnae: "4211102",
    descricao:
      "SINALIZACAO COM PINTURA EM RODOVIAS E AEROPORTOS (CONSTRUCAO); SERVICO DE",
  },
  { cnae: "4211102", descricao: "SINALIZACAO RODOVIARIA" },
  {
    cnae: "4211102",
    descricao: "PINTURA PARA SINALIZACAO EM AEROPORTOS; SERVICO DE",
  },
  {
    cnae: "4211102",
    descricao: "IMPLANTACAO DE SINALIZACAO EM ESTRADAS E RODOVIAS",
  },
  {
    cnae: "4211102",
    descricao: "PLACAS DE SINALIZACAO DE TRAFEGO; INSTALACAO DE",
  },
  {
    cnae: "4211102",
    descricao: "PINTURA PARA SINALIZACAO EM PISTAS RODOVIARIAS; SERVICO DE",
  },
  { cnae: "42120", descricao: "CONSTRUCAO DE OBRASDEARTE ESPECIAIS" },
  { cnae: "4212000", descricao: "PASSARELAS, CONSTRUCAO DE" },
  {
    cnae: "4212000",
    descricao:
      "PONTES, TUNEIS, VIADUTOS, ELEVADOS, PASSARELAS, ETC., CONSTRUCAO DE",
  },
  {
    cnae: "4212000",
    descricao:
      "RECUPERACAO DE OBRAS DE ARTE: PONTES, TUNEIS, VIADUTOS, PASSARELAS",
  },
  {
    cnae: "4212000",
    descricao: "GRANDES ESTRUTURAS E OBRAS DE ARTE, CONSTRUCAO DE",
  },
  { cnae: "4212000", descricao: "OBRAS DE ARTE CORRENTE, CONSTRUCAO DE" },
  { cnae: "4212000", descricao: "OBRAS DE ARTE ESPECIAIS, CONSTRUCAO DE" },
  { cnae: "4212000", descricao: "PONTES, TUNEIS, VIADUTOS; MANUTENCAO DE" },
  {
    cnae: "4212000",
    descricao: "CHOCOS, SUBSTITUICAO DE (MANUTENCAO EM TUNEIS)",
  },
  { cnae: "4212000", descricao: "VIADUTOS, CONSTRUCAO DE" },
  {
    cnae: "4212000",
    descricao:
      "TUNEIS (URBANOS, EM RODOVIAS, FERROVIAS, DE METROPOLITANOS), CONSTRUCAO DE",
  },
  { cnae: "4212000", descricao: "TUNEIS; MANUTENCAO DE" },
  { cnae: "4212000", descricao: "OBRAS DE ARTE RODOVIARIAS; MANUTENCAO DE" },
  { cnae: "4212000", descricao: "RECOMPOSICAO DE OBRAS DE ARTE" },
  { cnae: "42138", descricao: "OBRAS DE URBANIZACAO  RUAS, PRACAS E CALCADAS" },
  {
    cnae: "4213800",
    descricao:
      "CONSERVACAO DE VIAS PUBLICAS (TAPA-BURACO, TAPA-PANELA, LAMA ASFALTICA E CONGENERES)",
  },
  { cnae: "4213800", descricao: "LAMA ASFALTICA, APLICACAO EM VIAS PUBLICAS" },
  { cnae: "4213800", descricao: "TAPA-BURACO EM VIAS PUBLICAS, EXECUCAO DE" },
  {
    cnae: "4213800",
    descricao:
      "RECUPERACAO DE VIAS PUBLICAS (TAPA-BURACO, LAMA ASFALTICA, TAPA-PANELA, ETC.)",
  },
  {
    cnae: "4213800",
    descricao: "ASFALTAMENTO DE VIAS PUBLICAS (RUAS, AVENIDAS, PRACAS, ETC.)",
  },
  { cnae: "4213800", descricao: "FRISAGEM DE VIAS PUBLICAS; SERVICOS DE" },
  { cnae: "4213800", descricao: "TAPA-PANELA EM VIAS PUBLICAS, EXECUCAO DE" },
  { cnae: "4213800", descricao: "PRACAS, CONSTRUCAO DE" },
  { cnae: "4213800", descricao: "PAVIMENTACAO DE RUAS, OBRAS DE" },
  {
    cnae: "4213800",
    descricao: "RECUPERACAO DE LOGRADOUROS (PRACAS, RUAS, AVENIDAS)",
  },
  { cnae: "4213800", descricao: "LOGRADOUROS, CONSTRUCAO DE" },
  {
    cnae: "4213800",
    descricao: "RUAS, PRACAS, CALCADAS, CONSTRUCAO E/OU REFORMA DE",
  },
  { cnae: "4213800", descricao: "FRESAGEM DE VIAS PUBLICAS; SERVICOS DE" },
  {
    cnae: "4213800",
    descricao:
      "VIAS URBANAS, PRACAS, CALCADAS, PARQUES, CHAFARIZES, ESTACIONAMENTOS, ETC., CONSTRUCAO DE",
  },
  { cnae: "4213800", descricao: "CALCADAS, CONSTRUCAO DE" },
  { cnae: "4213800", descricao: "RUAS, PRACAS, CALCADAS; MANUTENCAO DE" },
  { cnae: "4213800", descricao: "RUAS; MANUTENCAO DE" },
  {
    cnae: "4213800",
    descricao:
      "SINALIZACAO COM PINTURAS EM RUAS E ESTACIONAMENTOS (CONSTRUCAO)",
  },
  { cnae: "4213800", descricao: "CALCAMENTO DE RUAS; SERVICO DE" },
  {
    cnae: "4213800",
    descricao: "MEIO-FIOS EM VIAS PUBLICAS, CONSTRUCAO E/OU RECUPERACAO DE",
  },
  { cnae: "4213800", descricao: "PRACAS; MANUTENCAO DE" },
  { cnae: "4213800", descricao: "CALCADAS, REFORMA DE" },
  { cnae: "4213800", descricao: "CALCADAS; MANUTENCAO DE" },
  { cnae: "4213800", descricao: "PRACAS, REFORMA DE" },
  { cnae: "4213800", descricao: "LOGRADOUROS, PAVIMENTACAO DE" },
  {
    cnae: "4213800",
    descricao:
      "SARJETAS, DESCIDAS D'AGUA, BIGODE E SIMILARES EM VIAS PUBLICAS, CONSTRUCAO DE",
  },
  {
    cnae: "42219",
    descricao:
      "OBRAS PARA GERACAO E DISTRIBUICAO DE ENERGIA ELETRICA E PARA TELECOMUNICACOES",
  },
  { cnae: "4221901", descricao: "REPRESAS PARA HIDRELETRICAS, CONSTRUCAO DE" },
  { cnae: "4221901", descricao: "BARRAGENS PARA HIDRELETRICAS, CONSTRUCAO DE" },
  {
    cnae: "4221901",
    descricao:
      "BARRAGENS E REPRESAS PARA GERACAO DE ENERGIA ELETRICA, CONSTRUCAO DE",
  },
  {
    cnae: "4221901",
    descricao: "BARRAGENS E REPRESAS PARA ENERGIA ELETRICA, OBRAS DE",
  },
  { cnae: "4221902", descricao: "USINAS TERMELETRICAS; CONSTRUCAO DE" },
  { cnae: "4221902", descricao: "USINAS HIDRELETRICAS, CONSTRUCAO DE" },
  { cnae: "4221902", descricao: "PLANTAS NUCLEARES, CONSTRUCAO DE" },
  {
    cnae: "4221902",
    descricao: "ESTACAO CENTRAL (PLANTA DE ELETRICIDADE), CONSTRUCAO DE",
  },
  {
    cnae: "4221902",
    descricao:
      "SUBESTACOES: EOLICAS, HIDRELETRICAS, NUCLEARES, TERMELETRICAS, CONSTRUCAO DE",
  },
  {
    cnae: "4221902",
    descricao:
      "REDES DE ELETRIFICACAO PARA FERROVIAS E METROPOLITANOS, CONSTRUCAO DE",
  },
  { cnae: "4221902", descricao: "ELETRIFICACAO RURAL; SERVICO DE" },
  {
    cnae: "4221902",
    descricao: "LINHAS DE TRANSMISSAO DE ENERGIA ELETRICA, INSTALACAO DE",
  },
  { cnae: "4221902", descricao: "PLANTAS HIDRELETRICAS, CONSTRUCAO DE" },
  {
    cnae: "4221902",
    descricao:
      "REDES DE TRANSMISSAO E DISTRIBUICAO DE ENERGIA ELETRICA, CONSTRUCAO DE",
  },
  { cnae: "4221902", descricao: "USINAS EOLICAS; CONSTRUCAO DE" },
  {
    cnae: "4221902",
    descricao: "PLANTAS DE GERACAO DE ENERGIA ELETRICA, CONSTRUCAO DE",
  },
  {
    cnae: "4221902",
    descricao:
      "SISTEMA DE PRODUCAO E DISTRIBUICAO DE ENERGIA ELETRICA, CONSTRUCAO DE",
  },
  { cnae: "4221902", descricao: "USINAS NUCLEARES; CONSTRUCAO DE" },
  {
    cnae: "4221902",
    descricao: "ESTACOES DE TRANSMISSAO DE ENERGIA ELETRICA, CONSTRUCAO DE",
  },
  {
    cnae: "4221902",
    descricao:
      "ESTACOES E REDES DE DISTRIBUICAO DE ENERGIA ELETRICA, CONSTRUCAO DE",
  },
  {
    cnae: "4221902",
    descricao: "ESTRUTURAS PARA REATORES NUCLEARES, CONSTRUCAO DE",
  },
  { cnae: "4221902", descricao: "ESTACOES DE FORCA E LUZ, CONSTRUCAO DE" },
  {
    cnae: "4221902",
    descricao: "REDES DE TRANSMISSAO DE ENERGIA ELETRICA, CONSTRUCAO DE",
  },
  {
    cnae: "4221902",
    descricao: "ESTACOES DE GERACAO DE ENERGIA ELETRICA, CONSTRUCAO DE",
  },
  { cnae: "4221903", descricao: "REDES ELETRICAS; MANUTENCAO DE" },
  {
    cnae: "4221903",
    descricao:
      "SISTEMA DE PRODUCAO E DISTRIBUICAO DE ENERGIA ELETRICA; MANUTENCAO DE",
  },
  {
    cnae: "4221903",
    descricao:
      "LINHAS DE TRANSMISSAO DE ENERGIA ELETRICA, REPARACAO E MANUTENCAO DE",
  },
  { cnae: "4221903", descricao: "REDES DE ENERGIA ELETRICA; MANUTENCAO DE" },
  {
    cnae: "4221904",
    descricao: "ESTACAO REPETIDORA, CONSTRUCAO DE INSTALACOES PARA",
  },
  {
    cnae: "4221904",
    descricao:
      "INSTALACOES DE CABOS PARA TELEFONES, TELEGRAFOS, OBRAS CIVIS DE",
  },
  {
    cnae: "4221904",
    descricao: "ESTACOES TELEFONICAS, CONSTRUCAO DE INSTALACOES PARA",
  },
  {
    cnae: "4221904",
    descricao:
      "REDES DE TELECOMUNICACOES DE LONGA E MEDIA DISTANCIA, CONSTRUCAO DE",
  },
  {
    cnae: "4221904",
    descricao: "REDES DE TRANSMISSAO PARA TELEFONES, CONSTRUCAO DE",
  },
  { cnae: "4221904", descricao: "TORRES PARA COMUNICACAO; INSTALACAO DE" },
  {
    cnae: "4221904",
    descricao: "CENTRAIS TELEFONICAS, CONSTRUCAO DE INSTALACOES PARA",
  },
  {
    cnae: "4221904",
    descricao: "INSTALACAO DE REDES DE CABOS TELEFONICOS, OBRAS CIVIS",
  },
  {
    cnae: "4221904",
    descricao: "REDE TELEFONICA DE LONGA E MEDIA DISTANCIA, CONSTRUCAO DE",
  },
  { cnae: "4221904", descricao: "TORRES PARA ANTENAS; INSTALACAO DE" },
  {
    cnae: "4221904",
    descricao: "ESTACOES E REDES DE TELEFONIA E COMUNICACAO, CONSTRUCAO DE",
  },
  {
    cnae: "4221905",
    descricao:
      "ESTACAO E REDES DE TELEFONIA E COMUNICACOES; MANUTENCAO DAS INSTALACOES PARA",
  },
  {
    cnae: "4221905",
    descricao:
      "REDES DE TELECOMUNICACOES DE LONGA E MEDIA DISTANCIA; MANUTENCAO POR TERCEIROS",
  },
  {
    cnae: "42227",
    descricao:
      "CONSTRUCAO DE REDES DE ABASTECIMENTO DE AGUA, COLETA DE ESGOTO E CONSTRUCOES CORRELATAS",
  },
  {
    cnae: "4222701",
    descricao: "SISTEMAS DE DISTRIBUICAO DE AGUA, CONSTRUCAO DE",
  },
  { cnae: "4222701", descricao: "SISTEMA DE SANEAMENTO BASICO, CONSTRUCAO DE" },
  {
    cnae: "4222701",
    descricao: "REDES DE DISTRIBUICAO DE AGUA, CONSTRUCAO DE",
  },
  { cnae: "4222701", descricao: "REDES DE ESGOTOS, CONSTRUCAO DE" },
  {
    cnae: "4222701",
    descricao: "ESTACOES DE TRATAMENTO DE ESGOTOS, CONSTRUCAO DE",
  },
  {
    cnae: "4222701",
    descricao: "REDES DE ESGOTO, INCLUSIVE INTERCEPTORES, CONSTRUCAO DE",
  },
  { cnae: "4222701", descricao: "RESERVATORIOS DE AGUA, CONSTRUCAO DE" },
  {
    cnae: "4222701",
    descricao: "SISTEMA DE ABASTECIMENTO DE AGUA, CONSTRUCAO DE",
  },
  {
    cnae: "4222701",
    descricao: "ESGOTO SANITARIO, INCLUSIVE INTERCEPTORES, CONSTRUCAO DE",
  },
  {
    cnae: "4222701",
    descricao: "SISTEMAS DE ESGOTOS SANITARIOS, CONSTRUCAO DE",
  },
  {
    cnae: "4222701",
    descricao:
      "PLANTAS DE TRATAMENTO DE AGUA, CONSTRUCAO DE (CONTRATANTE GERAL)",
  },
  {
    cnae: "4222701",
    descricao: "LAGOAS DE TRATAMENTO DE ESGOTOS, CONSTRUCAO DE",
  },
  {
    cnae: "4222701",
    descricao:
      "ESTACOES DE BOMBEAMENTO DE AGUA, ESGOTO, AGUAS PLUVIAIS, CONSTRUCAO DE",
  },
  { cnae: "4222701", descricao: "GALERIAS PLUVIAIS, CONSTRUCAO DE" },
  { cnae: "4222702", descricao: "IRRIGACAO, OBRAS DE" },
  { cnae: "4222702", descricao: "CANAIS DE IRRIGACAO, CONSTRUCAO DE" },
  {
    cnae: "42235",
    descricao:
      "CONSTRUCAO DE REDES DE TRANSPORTES POR DUTOS, EXCETO PARA AGUA E ESGOTO",
  },
  {
    cnae: "4223500",
    descricao:
      "TRANSPORTE POR DUTOS, OLEODUTOS, GASODUTOS, MINERODUTOS; CONSTRUCAO DE REDES DE",
  },
  { cnae: "4223500", descricao: "REDES DE DISTRIBUICAO DE GAS, CONSTRUCAO DE" },
  {
    cnae: "4223500",
    descricao:
      "SISTEMA DE DISTRIBUICAO DE COMBUSTIVEIS LIQUIDOS E GASOSOS, CONSTRUCAO DE",
  },
  { cnae: "4223500", descricao: "DUTOS DE GAS NATURAL, CONSTRUCAO DE" },
  {
    cnae: "4223500",
    descricao: "REDES DE DISTRIBUICAO (OLEODUTOS, MINERODUTOS), CONSTRUCAO DE",
  },
  { cnae: "4223500", descricao: "DUTOS, CONSTRUCAO DE" },
  { cnae: "42910", descricao: "OBRAS PORTUARIAS, MARITIMAS E FLUVIAIS" },
  {
    cnae: "4291000",
    descricao: "TERMINAIS MARITIMOS E FLUVIAIS, CONSTRUCAO DE",
  },
  { cnae: "4291000", descricao: "DRAGAGEM, OBRAS DE" },
  { cnae: "4291000", descricao: "DOCAS E PIERS, CONSTRUCAO DE" },
  {
    cnae: "4291000",
    descricao: "CONSTRUCAO DE ECLUSAS E CANAIS DE NAVEGACAO, OBRAS DE",
  },
  { cnae: "4291000", descricao: "EMISSARIOS SUBMARINOS, CONSTRUCAO DE" },
  { cnae: "4291000", descricao: "CABOS SUBMARINOS; INSTALACAO DE" },
  { cnae: "4291000", descricao: "MARINAS, CONSTRUCAO DE" },
  {
    cnae: "4291000",
    descricao:
      "RIOS, PORTOS, CANAIS, BAIAS, LAGOS, LAGOAS, REPRESAS, ACUDES E SIMILARES, LIMPEZA DE",
  },
  { cnae: "4291000", descricao: "INSTALACOES PORTUARIAS, CONSTRUCAO DE" },
  { cnae: "4291000", descricao: "EMISSARIO MARITIMO, CONSTRUCAO DE" },
  {
    cnae: "4291000",
    descricao: "CONSTRUCAO DE PORTOS, MARITIMOS E FLUVIAIS, OBRAS DE",
  },
  {
    cnae: "4291000",
    descricao:
      "DIQUES E OUTRAS ESTRUTURAS DE CONTROLES DE ENCHENTES, CONSTRUCAO DE",
  },
  { cnae: "4291000", descricao: "CONSTRUCAO DE MARINAS, OBRAS DE" },
  { cnae: "4291000", descricao: "ATERRO HIDRAULICO, OBRAS DE" },
  {
    cnae: "4291000",
    descricao: "BARRAGENS (EXCETO PARA HIDRELETRICAS), CONSTRUCAO DE",
  },
  {
    cnae: "4291000",
    descricao:
      "CANAIS, DIQUES, BARRAGENS (EXCETO PARA HIDRELETRICAS), CONSTRUCAO DE",
  },
  { cnae: "4291000", descricao: "CANAIS DE NAVEGACAO, CONSTRUCAO DE" },
  { cnae: "4291000", descricao: "PORTOS E CANAIS, CONSTRUCAO DE" },
  {
    cnae: "42928",
    descricao: "MONTAGEM DE INSTALACOES INDUSTRIAIS E DE ESTRUTURAS METALICAS",
  },
  {
    cnae: "4292801",
    descricao:
      "ESTRUTURAS METALICAS QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA; MONTAGEM DE",
  },
  {
    cnae: "4292801",
    descricao:
      "COBERTURAS METALICAS; MONTAGEM DE QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  { cnae: "4292801", descricao: "SOLDAGEM PARA CONSTRUCAO CIVIL; SERVICO DE" },
  {
    cnae: "4292801",
    descricao:
      "ESTRUTURAS METALICAS; MONTAGEM DE QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  { cnae: "4292801", descricao: "ESTRUTURAS METALICAS MOVEIS; MONTAGEM DE" },
  {
    cnae: "4292801",
    descricao:
      "ESTRUTURAS PRE-FABRICADAS DE METAL; MONTAGEM DE (QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA)",
  },
  {
    cnae: "4292802",
    descricao:
      "MONTAGENS INDUSTRIAIS (TUBULACOES, REDES DE FACILIDADES, ETC.); OBRAS DE",
  },
  {
    cnae: "42995",
    descricao: "OBRAS DE ENGENHARIA CIVIL NAO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "4299501", descricao: "PISCINAS PARA COMPETICAO, CONSTRUCAO DE" },
  {
    cnae: "4299501",
    descricao:
      "INSTALACOES DESPORTIVAS TAIS COMO PISTAS DE COMPETICAO; QUADRAS ESPORTIVAS, PISCINAS, ETC.; CONSTRUCAO DE",
  },
  {
    cnae: "4299501",
    descricao: "INSTALACOES ESPORTIVAS (EXCETO EDIFICACOES); CONSTRUCAO DE",
  },
  {
    cnae: "4299501",
    descricao: "INSTALACOES ESPORTIVAS (EXCETO EDIFICACOES); MANUTENCAO DE",
  },
  { cnae: "4299501", descricao: "QUADRAS ESPORTIVAS; OBRAS DE MANUTENCAO" },
  { cnae: "4299501", descricao: "QUADRAS ESPORTIVAS; OBRAS DE, (CONSTRUCAO)" },
  {
    cnae: "4299501",
    descricao:
      "CAMPOS PARA A PRATICA DE ESPORTES (FUTEBOL, VOLEI, BASQUETE, HANDEBOL, ETC.,); CONSTRUCAO DE",
  },
  { cnae: "4299599", descricao: "OBRAS DE OUTROS TIPOS (CONSTRUCAO)" },
  {
    cnae: "4299599",
    descricao:
      "OBRAS DE ESCORAMENTO PARA A CONTENCAO DE ESTRUTURAS FIXAS; EXECUCAO DE",
  },
  {
    cnae: "4299599",
    descricao:
      "OBRAS DE INFRA-ESTRUTURA PARA EXECUCAO DE PLANTAS INDUSTRIAIS; CONSTRUCAO DE",
  },
  {
    cnae: "4299599",
    descricao:
      "OBRAS DE ESTABILIDADE: ENROCAMENTO, MURO DE CONCRETO CICLOPICO, RIP-RAP, GABIAO, BERNA, ESCALONAMENTO; EXECUCAO DE",
  },
  {
    cnae: "4299599",
    descricao:
      "LOTEAMENTO (SUBDIVISAO DE TERRAS) COM EXECUCAO DE BENFEITORIAS; SERVICOS DE",
  },
  { cnae: "4299599", descricao: "OBRAS DE ACUDES" },
  { cnae: "4299599", descricao: "TANQUES PARA COMBUSTIVEIS; INSTALACAO DE" },
  { cnae: "4299599", descricao: "CONTENCAO DE ENCOSTAS; OBRAS DE" },
  {
    cnae: "4299599",
    descricao: "ATIRANTAMENTOS E CORTINAS DE PROTECAO DE ENCOSTAS; OBRAS DE",
  },
  { cnae: "43118", descricao: "DEMOLICAO E PREPARACAO DE CANTEIROS DE OBRAS" },
  { cnae: "4311801", descricao: "DESMONTE (CONSTRUCAO); SERVICOS DE" },
  {
    cnae: "4311801",
    descricao:
      "ESTRUTURAS EDIFICADAS (EDIFICIOS, PONTES, PREDIOS, ETC.); IMPLOSAO DE",
  },
  {
    cnae: "4311801",
    descricao:
      "ARRASAMENTO DE ESTRUTURAS EDIFICADAS (CONSTRUCOES); SERVICOS DE",
  },
  {
    cnae: "4311801",
    descricao: "DEMOLICAO (DEMOLICOES) EM GERAL (CONSTRUCAO); SERVICOS DE",
  },
  {
    cnae: "4311801",
    descricao: "DEMOLICOES DE PREDIOS E OUTRAS ESTRUTURAS; OBRAS DE",
  },
  {
    cnae: "4311802",
    descricao: "PREPARACAO DE CANTEIROS DE OBRAS; SERVICOS DE",
  },
  { cnae: "4311802", descricao: "TERRENOS; PREPARACAO DE" },
  {
    cnae: "4311802",
    descricao: "TERRENOS PARA EXECUCAO DE CONSTRUCAO; OBRAS DE LIMPEZA DE",
  },
  { cnae: "4311802", descricao: "CANTEIROS; PREPARACAO DE" },
  { cnae: "43126", descricao: "PERFURACOES E SONDAGENS" },
  {
    cnae: "4312600",
    descricao: "EXPLORACAO MINERAL; PERFURACAO (PERFURACOES) PARA",
  },
  {
    cnae: "4312600",
    descricao: "PERFURACOES PARA TESTES EM MINAS (CONSTRUCAO)",
  },
  { cnae: "4312600", descricao: "SONDAGEM A TRADO" },
  { cnae: "4312600", descricao: "SONDAGENS E PERFURACOES; SERVICOS DE" },
  { cnae: "4312600", descricao: "TESTE DE SOLO (CONSTRUCAO)" },
  { cnae: "4312600", descricao: "SONDAGEM A PERCUSSAO" },
  { cnae: "4312600", descricao: "SOLO PARA CONSTRUCAO; INVESTIGACAO DE" },
  { cnae: "4312600", descricao: "ESCAVACAO DE TRINCHEIRA; SONDAGEM POR" },
  {
    cnae: "4312600",
    descricao: "PERFURACOES PARA TESTES EM EDIFICACOES (CONSTRUCAO)",
  },
  { cnae: "4312600", descricao: "SONDAGEM ROTATIVA" },
  { cnae: "4312600", descricao: "ESCAVACAO DE POCO; SONDAGEM POR" },
  {
    cnae: "4312600",
    descricao:
      "PROSPECCAO (CONSTRUCAO), EXCETO PARA EXPLORACAO DE PETROLEO; PERFURACAO PARA",
  },
  {
    cnae: "4312600",
    descricao: "SONDAGEM (SONDAGENS) DESTINADAS A CONSTRUCAO CIVIL",
  },
  {
    cnae: "4312600",
    descricao: "SONDAGEM (SONDAGENS) PARA CONSTRUCAO; EXECUCAO DE",
  },
  { cnae: "43134", descricao: "OBRAS DE TERRAPLENAGEM" },
  {
    cnae: "4313400",
    descricao:
      "DRENOS EM CAMADAS (COLCHAO DRENANTE, CAMADA DRENANTE); EXECUCAO DE",
  },
  {
    cnae: "4313400",
    descricao:
      "NIVELACAO (NIVELAMENTO) PARA CONSTRUCAO DE RODOVIAS (VIARIAS) E AEROPORTOS; OBRAS DE",
  },
  { cnae: "4313400", descricao: "DERROCAMENTOS; SERVICOS DE (CONSTRUCAO)" },
  {
    cnae: "4313400",
    descricao:
      "TERRAPLANAGEM (TERRAPLENAGEM) PARA PROTECAO AMBIENTAL; OBRAS DE",
  },
  { cnae: "4313400", descricao: "DRENO PROFUNDO, CONSTRUCAO DE" },
  { cnae: "4313400", descricao: "TERRAPLANAGEM(TERRAPLENAGEM); SERVICOS DE" },
  {
    cnae: "4313400",
    descricao: "NIVELAMENTOS DIVERSOS PARA CONSTRUCAO CIVIL (OBRAS)",
  },
  { cnae: "4313400", descricao: "MOTONIVELADORES COM OPERADOR; LOCACAO DE" },
  {
    cnae: "4313400",
    descricao: "TERRAPLENAGEM (TERRAPLENAGEM) EM CORTES E EM ATERROS",
  },
  {
    cnae: "4313400",
    descricao: "TERRAPLENAGEM (TERRAPLENAGEM) MECANIZADA, MANUAL OU COMPENSADA",
  },
  {
    cnae: "4313400",
    descricao:
      "TERRAPLANAGEM (TERRAPLENAGEM): GREIDE, LEITO, SUB-LEITO, CORTE, ATERRO; SERVICOS DE",
  },
  { cnae: "4313400", descricao: "LEITOS OU PERFIS DE RIOS; REGULARIZACAO DE" },
  { cnae: "4313400", descricao: "TERRA; REMOCAO, RETIRADA DE" },
  { cnae: "4313400", descricao: "ROCHAS ATRAVES DE EXPLOSIVOS; REMOCAO DE" },
  {
    cnae: "4313400",
    descricao:
      "NIVELACAO (NIVELAMENTO), EXCETO PARA CONSTRUCAO DE RODOVIAS E AEROPORTOS; OBRAS DE",
  },
  { cnae: "4313400", descricao: "BOTA FORA; SERVICOS DE" },
  { cnae: "4313400", descricao: "TERRA; MOVIMENTACAO DE (CONSTRUCAO)" },
  {
    cnae: "4313400",
    descricao:
      "TERRAPLANAGEM (TERRAPLENAGEM) PARA CONSTRUCAO DE RODOVIAS E AEROPORTOS; OBRAS DE",
  },
  { cnae: "4313400", descricao: "DESATERRO; SERVICOS DE" },
  {
    cnae: "4313400",
    descricao: "MAQUINAS DE TERRAPLENAGEM COM OPERADOR; ALUGUEL (LOCACAO) DE",
  },
  {
    cnae: "4313400",
    descricao:
      "ESCAVACAO (ESCAVACOES) DIVERSAS PARA A EXECUCAO DE CONSTRUCAO (OBRAS)",
  },
  {
    cnae: "4313400",
    descricao:
      "DRENOS LINEARES (LONGITUDINAIS, TRANSVERSAIS, OBLIQUOS, VERTICAIS, SUB-HORIZONTAIS); EXECUCAO DE",
  },
  {
    cnae: "4313400",
    descricao: "MOTONIVELADORES PARA CONSTRUCAO COM OPERADOR; ALUGUEL DE",
  },
  { cnae: "4313400", descricao: "GRANDES MOVIMENTACOES DE TERRA (CONSTRUCAO)" },
  { cnae: "4313400", descricao: "MOVIMENTACAO DE TERRA; SERVICOS DE" },
  { cnae: "4313400", descricao: "TERRAPLANAGEM (TERRAPLENAGEM); OBRAS DE" },
  {
    cnae: "4313400",
    descricao: "DRENO PROFUNDO; RECOMPOSICAO (RECONSTRUCAO) DE",
  },
  { cnae: "4313400", descricao: "CORTE E ATERRO; SERVICOS DE" },
  {
    cnae: "4313400",
    descricao: "ESCAVADORAS PARA CONSTRUCAO COM OPERADOR; ALUGUEL(LOCACAO) DE",
  },
  { cnae: "4313400", descricao: "COMPACTACAO DO TERRENO; SERVICO DE" },
  { cnae: "4313400", descricao: "DINAMITACAO (CONSTRUCAO)" },
  {
    cnae: "4313400",
    descricao: "ROCHAS; ESCAVACAO (ESCAVACOES) E REMOCOES DE",
  },
  {
    cnae: "43193",
    descricao:
      "SERVICOS DE PREPARACAO DO TERRENO NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "4319300",
    descricao: "VALAS, REGOS E FOSSAS; OBRAS (CONSTRUCAO) DE",
  },
  {
    cnae: "4319300",
    descricao: "LENCOL (LENCOIS) FREATICOS, REBAIXAMENTO DE (CONSTRUCAO)",
  },
  { cnae: "4319300", descricao: "PREPARACAO DE MINAS; OBRAS DE" },
  { cnae: "4319300", descricao: "VALAS, REGOS E FOSSAS; EXECUCAO DE" },
  {
    cnae: "4319300",
    descricao: "BOMBEAMENTO E DRENAGEM (CONSTRUCAO); OBRAS DE",
  },
  { cnae: "4319300", descricao: "ESCOAMENTO; OBRAS DE (CONSTRUCAO)" },
  { cnae: "4319300", descricao: "MINAS; ESCAVACAO DE" },
  { cnae: "4319300", descricao: "LENCOL (LENCOIS) DE AGUA; REBAIXAMENTO DE" },
  {
    cnae: "4319300",
    descricao: "REBAIXAMENTO DE LENCOIS FREATICOS; SERVICOS DE",
  },
  {
    cnae: "4319300",
    descricao: "LOCAIS PARA EXPLORACAO MINERAL; PREPARACAO DE",
  },
  { cnae: "43215", descricao: "INSTALACOES ELETRICAS" },
  {
    cnae: "4321500",
    descricao:
      "CABOS PARA INSTALACOES TELEFONICAS EM EDIFICACOES; INSTALACAO DE",
  },
  { cnae: "4321500", descricao: "PARA-RAIOS; INSTALACAO DE" },
  {
    cnae: "4321500",
    descricao: "SISTEMAS DE ALARMES CONTRA ROUBO EM EDIFICACOES; MANUTENCAO DE",
  },
  {
    cnae: "4321500",
    descricao:
      "SISTEMA DE ALARMES CONTRA ROUBOS EM EDIFICACOES; MANUTENCAO DE INSTALACOES PARA",
  },
  {
    cnae: "4321500",
    descricao:
      "SISTEMAS DE COMUNICACAO ELETRICOS EM EDIFICACOES; INSTALACAO DE",
  },
  {
    cnae: "4321500",
    descricao: "SISTEMAS ANTI-ROUBO EM EDIFICACOES; INSTALACAO DE",
  },
  {
    cnae: "4321500",
    descricao:
      "PADRAO DE ENERGIA (CAIXAS, QUADROS, PAINEIS OU CUBICULOS); INSTALACAO DE",
  },
  { cnae: "4321500", descricao: "SERVICO DE INSTALACAO ELETRICA RESIDENCIAL" },
  {
    cnae: "4321500",
    descricao: "ALARME CONTRA ROUBO EM EDIFICACOES; INSTALACAO DE",
  },
  {
    cnae: "4321500",
    descricao: "SISTEMAS ELETRICOS EM EDIFICACOES; INSTALACAO DE",
  },
  {
    cnae: "4321500",
    descricao: "EQUIPAMENTOS DE INTERCOMUNICACAO EM EDIFICACOES; INSTALACAO DE",
  },
  { cnae: "4321500", descricao: "CABEACAO LOGICA; INSTALACAO DE" },
  {
    cnae: "4321500",
    descricao: "SISTEMAS DE ALARME CONTRA ROUBO EM EDIFICACOES; INSTALACAO DE",
  },
  { cnae: "4321500", descricao: "PARA-RAIOS; MANUTENCAO DE INSTALACOES PARA" },
  {
    cnae: "4321500",
    descricao:
      "CABOS PARA INSTALACOES TELEFONICAS, INFORMATICAS E COMUNICACOES EM EDIFICACOES DE QUALQUER TIPO, OBRAS DE INSTALACAO, MANUTENCAO E REPARACAO",
  },
  {
    cnae: "4321500",
    descricao: "AUTOMACAO PREDIAL, INSTALACAO E MANUTENCAO DE",
  },
  {
    cnae: "4321500",
    descricao: "CPD, CONSTRUCAO E MANUTENCAO DE INSTALACOES PARA",
  },
  { cnae: "4321500", descricao: "CABOS LOGICOS, PASSAGEM DE" },
  {
    cnae: "4321500",
    descricao: "OBRAS DE INSTALACOES PARA ELETRIFICACAO RURAL",
  },
  {
    cnae: "4321500",
    descricao:
      "PAINEIS FOTOVOLTAICOS SOLARES PARA GERACAO DE ENERGIA ELETRICA EM INSTALACOES PREDIAIS; INSTALACAO DE",
  },
  {
    cnae: "4321500",
    descricao:
      "CENTRO DE PROCESSAMENTO DE DADOS, CONSTRUCAO E MANUTENCAO DE INSTALACOES PARA",
  },
  { cnae: "4321500", descricao: "INTERFONE, OBRAS PARA INSTALACAO DE" },
  { cnae: "4321500", descricao: "INSTALACOES ELETRICAS, OBRAS DE" },
  { cnae: "4321500", descricao: "PORTAO ELETRONICO; MANUTENCAO DE" },
  {
    cnae: "4321500",
    descricao:
      "PREPARACAO DE INSTALACOES ELETRICAS PREDIAIS PARA POSSIBILITAR O USO DE APARELHOS E EQUIPAMENTOS DOMESTICOS; SERVICO DE",
  },
  {
    cnae: "4321500",
    descricao: "CABOS PARA TELEVISAO EM EDIFICACOES; INSTALACAO DE",
  },
  {
    cnae: "4321500",
    descricao: "CABOS ELETRICOS EM EDIFICACOES, INSTALACAO E MANUTENCAO DE",
  },
  {
    cnae: "4321500",
    descricao: "SISTEMA DE CONTROLE ELETRONICO; INSTALACAO DE",
  },
  { cnae: "4321500", descricao: "ELETRICISTA RESIDENCIAL; SERVICO DE" },
  {
    cnae: "4321500",
    descricao: "PORTAO ELETRONICO; MANUTENCAO DE INSTALACOES PARA",
  },
  {
    cnae: "4321500",
    descricao: "AUTOMACAO BANCARIA, OBRAS PARA INSTALACOES DE",
  },
  {
    cnae: "4321500",
    descricao:
      "EQUIPAMENTOS PARA A GERACAO DE ENERGIA ELETRICA POR FONTE SOLAR EM INSTALACOES PREDIAIS; INSTALACAO DE",
  },
  {
    cnae: "4321500",
    descricao: "SISTEMAS DE CONTROLE ELETRONICO EM EDIFICACOES; INSTALACAO DE",
  },
  { cnae: "4321500", descricao: "CABOS LOGICOS; INSTALACAO DE" },
  {
    cnae: "4321500",
    descricao:
      "INSTALACOES PARA ANTENAS COLETIVAS E PARABOLICAS; MANUTENCAO DE",
  },
  {
    cnae: "4321500",
    descricao:
      "CABOS PARA INSTALACOES DE COMUNICACAO E INFORMATICA EM EDIFICACOES; INSTALACAO DE",
  },
  {
    cnae: "4321500",
    descricao: "INSTALACOES ELETRICAS EM EDIFICACOES, OBRAS DE",
  },
  { cnae: "4321500", descricao: "ANTENAS DE TELEVISAO; INSTALACAO DE" },
  {
    cnae: "4321500",
    descricao: "CAIXAS DE ENTRADA DE ENERGIA EM EDIFICACOES; INSTALACAO DE",
  },
  { cnae: "4321500", descricao: "PARA-RAIOS; MANUTENCAO DE" },
  { cnae: "4321500", descricao: "PORTAO ELETRONICO; INSTALACAO DE" },
  {
    cnae: "4321500",
    descricao: "ANTENAS COLETIVAS E PARABOLICAS; INSTALACAO DE",
  },
  {
    cnae: "4321500",
    descricao:
      "INSTALACAO DE SISTEMAS DE ELETRICIDADE (CABOS DE QUALQUER TENSAO, FIACAO, MATERIAIS ELETRICOS), OBRAS DE INSTALACAO, MANUTENCAO E REPARACAO",
  },
  {
    cnae: "43223",
    descricao:
      "INSTALACOES HIDRAULICAS, DE SISTEMAS DE VENTILACAO E REFRIGERACAO",
  },
  {
    cnae: "4322301",
    descricao:
      "SISTEMA DE AQUECIMENTO SOLAR, QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA; INSTALACAO DE",
  },
  {
    cnae: "4322301",
    descricao: "REDES PARA DISTRIBUICAO DE GASES E FLUIDOS; INSTALACAO DE",
  },
  {
    cnae: "4322301",
    descricao:
      "PREPARACAO DE INSTALACOES HIDRAULICAS OU DE GAS PREDIAIS PARA POSSIBILITAR O USO DE APARELHOS E EQUIPAMENTOS DOMESTICOS; SERVICO DE",
  },
  {
    cnae: "4322301",
    descricao:
      "REDES PARA DISTRIBUICAO PARA FLUIDOS DIVERSOS (OXIGENIO NOS HOSPITAIS), REPARACAO OU MANUTENCAO DE",
  },
  {
    cnae: "4322301",
    descricao:
      "REDES PARA DISTRIBUICAO DE FLUIDOS DIVERSOS (OXIGENIO NOS HOSPITAIS); INSTALACAO DE",
  },
  {
    cnae: "4322301",
    descricao:
      "PLACAS COLETORAS PARA INSTALACOES TERMICAS ALIMENTADAS POR ENERGIA SOLAR, QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA; INSTALACAO DE",
  },
  {
    cnae: "4322301",
    descricao:
      "INSTALACOES HIDRAULICAS, SANITARIAS E DE GAS, REPARACAO OU MANUTENCAO DE",
  },
  { cnae: "4322301", descricao: "ENCANACAO RESIDENCIAL; SERVICO DE" },
  { cnae: "4322301", descricao: "ENCANAMENTOS; INSTALACAO DE" },
  { cnae: "4322301", descricao: "SERVICOS HIDRAULICOS" },
  {
    cnae: "4322301",
    descricao: "INSTALACOES HIDRAULICAS, SANITARIAS E DE GAS (CONSTRUCAO)",
  },
  {
    cnae: "4322302",
    descricao:
      "SISTEMAS DE AR CONDICIONADO, DE VENTILACAO E REFRIGERACAO; INSTALACAO DE",
  },
  {
    cnae: "4322302",
    descricao:
      "SISTEMAS DE REFRIGERACAO CENTRAL EM IMOVEIS RESIDENCIAIS E COMERCIAIS, MONTAGEM DE",
  },
  { cnae: "4322302", descricao: "AR CONDICIONADO CENTRAL; MANUTENCAO DE" },
  {
    cnae: "4322302",
    descricao: "SISTEMAS CENTRAIS PARA CALEFACAO; INSTALACAO  DE",
  },
  {
    cnae: "4322302",
    descricao:
      "SISTEMA DE VENTILACAO MECANICA CONTROLADA, REPARACAO OU MANUTENCAO DE",
  },
  {
    cnae: "4322302",
    descricao:
      "SISTEMAS DE VENTILACAO MECANICA CONTROLADA, INCLUSIVE EXAUSTORES; INSTALACAO DE",
  },
  {
    cnae: "4322302",
    descricao:
      "SISTEMA DE REFRIGERACAO CENTRAL EM IMOVEIS RESIDENCIAIS E COMERCIAIS, REPARACAO OU MANUTENCAO DE",
  },
  {
    cnae: "4322302",
    descricao:
      "SISTEMAS CENTRAIS DE AQUECIMENTO CENTRAL EM EDIFICIOS RESIDENCIAIS E COMERCIAIS; INSTALACAO DE",
  },
  {
    cnae: "4322302",
    descricao: "DUTOS PARA SISTEMAS DE AR CONDICIONADO; INSTALACAO DE",
  },
  {
    cnae: "4322303",
    descricao: "EQUIPAMENTOS EXTINTORES DE INCENDIO; INSTALACAO DE",
  },
  {
    cnae: "4322303",
    descricao:
      "INSTALACOES DE SISTEMAS DE PREVENCAO CONTRA INCENDIO (CONSTRUCAO)",
  },
  {
    cnae: "4322303",
    descricao: "SISTEMAS DE ALARME CONTRA INCENDIO; MANUTENCAO DE",
  },
  {
    cnae: "4322303",
    descricao: "SPRINKLERS AUTOMATICOS CONTRA FOGO; INSTALACAO DE",
  },
  {
    cnae: "4322303",
    descricao:
      "SISTEMAS DE PREVENCAO CONTRA INCENDIO, REPARACAO OU MANUTENCAO DE",
  },
  { cnae: "4322303", descricao: "ALARME ANTI-FOGO; INSTALACAO DE" },
  {
    cnae: "4322303",
    descricao: "SISTEMA DE DETECCAO DE INCENDIO; INSTALACAO DE",
  },
  {
    cnae: "4322303",
    descricao: "SISTEMAS DE PREVENCAO CONTRA INCENDIO; INSTALACAO DE",
  },
  {
    cnae: "43291",
    descricao:
      "OBRAS DE INSTALACOES EM CONSTRUCOES NAO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "4329101", descricao: "LETREIROS LUMINOSOS; INSTALACAO DE" },
  {
    cnae: "4329101",
    descricao: "ANUNCIOS LUMINOSOS OU NAO, REPARACAO OU MANUTENCAO DE",
  },
  { cnae: "4329101", descricao: "OUTDOOR; INSTALACAO DE" },
  {
    cnae: "4329101",
    descricao: "PLACAS OU PAINEIS DE IDENTIFICACAO; INSTALACAO DE",
  },
  { cnae: "4329101", descricao: "ANUNCIOS LUMINOSOS OU NAO; INSTALACAO DE" },
  {
    cnae: "4329102",
    descricao:
      "EQUIPAMENTOS PARA ORIENTACAO A NAVEGACAO MARITIMA, FLUVIAL E LACUSTRE; INSTALACAO DE",
  },
  {
    cnae: "4329103",
    descricao:
      "ELEVADORES, ESCADAS E ESTEIRAS ROLANTES, MONTAGEM, INSTALACAO, REPARACAO E MANUTENCAO DE, QUANDO REALIZADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "4329104",
    descricao: "ILUMINACAO DE PISTAS DE DECOLAGEM; INSTALACAO DE",
  },
  { cnae: "4329104", descricao: "CANCELAS ELETRONICAS; INSTALACAO DE" },
  {
    cnae: "4329104",
    descricao:
      "SISTEMAS DE ILUMINACAO E SINALIZACAO EM VIAS PUBLICAS, MANUTENCAO E REPARACAO DE",
  },
  {
    cnae: "4329104",
    descricao:
      "FAIXAS E DELIMITADORES LUMINOSOS (TACHAS) PARA RODOVIAS E AEROPORTOS, IMPLANTACAO DE",
  },
  { cnae: "4329104", descricao: "REDE DE ILUMINACAO PUBLICA; MANUTENCAO DE" },
  {
    cnae: "4329104",
    descricao: "SINAIS LUMINOSOS (SEMAFOROS), MANUTENCAO E REPARACAO DE",
  },
  { cnae: "4329104", descricao: "ILUMINACAO PUBLICA; INSTALACAO DE" },
  { cnae: "4329104", descricao: "SUBSTITUICAO DE POSTES DE ILUMINACAO" },
  {
    cnae: "4329104",
    descricao:
      "SISTEMAS E EQUIPAMENTOS DE ILUMINACAO E SINALIZACAO EM VIAS PUBLICAS, PORTOS E AEROPORTOS, REPARACAO OU MANUTENCAO DE",
  },
  {
    cnae: "4329104",
    descricao: "ILUMINACAO URBANA E SEMAFOROS, IMPLANTACAO DE",
  },
  { cnae: "4329104", descricao: "POSTES DE ILUMINACAO, COLOCACAO DE" },
  {
    cnae: "4329104",
    descricao:
      "SISTEMAS DE ILUMINACAO E DE SINALIZACAO EM VIAS PUBLICAS, RODOVIAS, FERROVIAS, PORTOS E AEROPORTOS, MONTAGEM E INSTALACAO DE",
  },
  { cnae: "4329105", descricao: "FORROS E PLACAS ACUSTICAS; INSTALACAO DE" },
  { cnae: "4329105", descricao: "PAINEIS ACUSTICOS; INSTALACAO DE" },
  {
    cnae: "4329105",
    descricao:
      "MANTAS DE POLIETILENO EXPANDIDO EM PAREDES E FORROS PARA TRATAMENTO TERMICO OU ACUSTICO, COLOCACAO DE",
  },
  {
    cnae: "4329105",
    descricao:
      "ISOLAMENTOS TERMICOS OU ACUSTICOS EM LAJES, TELHADOS, TELHAS, PAREDES, RESERVATORIOS, TANQUES, ETC., APLICACAO DE",
  },
  { cnae: "4329105", descricao: "ISOLAMENTO TERMICO (CONSTRUCAO)" },
  {
    cnae: "4329105",
    descricao: "SISTEMA DE ISOLAMENTO ACUSTICO E TERMICO; INSTALACAO DE",
  },
  {
    cnae: "4329105",
    descricao:
      "SISTEMAS DE AMORTECIMENTO DE VIBRACOES EM EDIFICACOES; INSTALACAO DE",
  },
  {
    cnae: "4329105",
    descricao:
      "ALMOFADAS TERMICAS E ACUSTICAS PARA CONSTRUCAO CIVIL; INSTALACAO DE",
  },
  {
    cnae: "4329105",
    descricao:
      "SISTEMAS DE MONITORAMENTO DINAMICO DE VIBRACOES EM ESTRUTURAS DIVERSAS; INSTALACAO DE",
  },
  {
    cnae: "4329105",
    descricao:
      "SISTEMAS DE AMORTECIMENTO (ATENUACAO) E CONTROLE DE VIBRACOES E RUIDOS EM EDIFICACOES; INSTALACAO DE",
  },
  {
    cnae: "4329105",
    descricao:
      "MANTAS OU PLACAS DE LA DE ROCHA OU LA DE VIDRO EM PAREDES E FORROS PARA TRATAMENTO TERMICO OU ACUSTICO, COLOCACAO DE",
  },
  {
    cnae: "4329105",
    descricao: "TRATAMENTOS ACUSTICOS E TERMICOS (CONSTRUCAO)",
  },
  {
    cnae: "4329105",
    descricao: "TRATAMENTO ACUSTICO E TERMICO, REPARACAO OU MANUTENCAO DE",
  },
  { cnae: "4329199", descricao: "DUTOS E TUBULACOES, REVESTIMENTO DE" },
  {
    cnae: "4329199",
    descricao:
      "SISTEMAS DE LIMPEZA DE DUTOS E INSTALACOES HIDRAULICAS POR VACUO; INSTALACAO DE",
  },
  {
    cnae: "4329199",
    descricao:
      "BLINDAGEM DE ESTRUTURAS (CABINES DE SEGURANCA, SALA DE SEGURANCA, CLAUSURAS, PASSA DOCUMENTOS, PASSA DELIVERY, DATACENTER, BUNKERS E SEMELHANTES); SERVICOS DE",
  },
  { cnae: "4329199", descricao: "REBAIXAMENTO DE TETO (CONSTRUCAO)" },
  { cnae: "4329199", descricao: "REVESTIMENTO DE TUBULACOES; INSTALACAO DE" },
  {
    cnae: "4329199",
    descricao:
      "ESTRUTURAS DE MADEIRA, MONTAGEM DE (QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA)",
  },
  { cnae: "43304", descricao: "OBRAS DE ACABAMENTO" },
  {
    cnae: "4330401",
    descricao:
      "IMPERMEABILIZACAO EM JUNTAS DE DILATACAO OU ESTRUTURAIS; SERVICOS DE",
  },
  {
    cnae: "4330401",
    descricao: "IMPERMEABILIZACAO DE AREAS MOLHADAS; SERVICOS DE",
  },
  { cnae: "4330401", descricao: "IMPERMEABILIZACAO DE PISOS; SERVICOS DE" },
  {
    cnae: "4330401",
    descricao:
      "IMPERMEABILIZACAO EM LAJES, COBERTURAS, TELHADOS, CALHAS; SERVICOS DE",
  },
  {
    cnae: "4330401",
    descricao:
      "APLICACAO DE MANTA ASFALTICA OU ELASTOMERICA EM PISOS E COBERTURAS; SERVICOS DE",
  },
  {
    cnae: "4330401",
    descricao: "PAREDES, CAIXAS DE AGUA, PISCINA, ETC., IMPERMEABILIZACAO DE",
  },
  {
    cnae: "4330401",
    descricao: "APLICACAO DE ARGAMASSA IMPERMEAVEL; SERVICOS DE",
  },
  {
    cnae: "4330401",
    descricao: "IMPERMEABILIZACAO DE CAIXAS D'AGUA SERVICOS DE",
  },
  {
    cnae: "4330401",
    descricao:
      "IMPERMEABILIZACAO DE PISCINAS, FLOREIRAS E JARDINEIRAS; SERVICOS DE",
  },
  {
    cnae: "4330401",
    descricao: "OBRAS DE ENGENHARIA CIVIL, IMPERMEABILIZACAO DE",
  },
  { cnae: "4330402", descricao: "STANDS PARA FEIRAS; INSTALACAO DE" },
  {
    cnae: "4330402",
    descricao: "EXECUCAO DE TRABALHOS DE CARPINTARIA EM OBRAS",
  },
  {
    cnae: "4330402",
    descricao:
      "ARMARIOS DE COZINHAS PLANEJADAS QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA , COLOCACAO DE",
  },
  {
    cnae: "4330402",
    descricao:
      "COZINHAS EQUIPADAS; INSTALACAO DE (QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA)",
  },
  {
    cnae: "4330402",
    descricao:
      "TRABALHOS EM MADEIRA EM INTERIORES: TETOS, DIVISORIAS, ARMARIOS EMBUTIDOS, TABUA CORRIDA, ETC., EXECUCAO DE",
  },
  {
    cnae: "4633802",
    descricao: "AVES VIVAS PARA ALIMENTACAO, OVOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4330402",
    descricao:
      "FORROS OU DIVISORIAS DE QUALQUER MATERIAL, COLOCACAO OU INSTALACAO DE",
  },
  {
    cnae: "4330402",
    descricao: "PAREDES E DIVISORIAS EM DRY WALL, EXECUCAO DE",
  },
  { cnae: "4330402", descricao: "STANDS PARA FEIRAS, MONTAGEM DE" },
  {
    cnae: "4330402",
    descricao: "ESQUADRIAS (CHUMBAMENTO) NA CONSTRUCAO, FIXACAO DE",
  },
  {
    cnae: "4330402",
    descricao:
      "ESCADAS; INSTALACAO DE (QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA)",
  },
  {
    cnae: "4330402",
    descricao:
      "DOBRADICAS, FECHADURAS, TRANCAS E OUTRAS FERRAGENS EM ESQUADRIAS QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA; INSTALACAO DE",
  },
  {
    cnae: "4330402",
    descricao: "CARPINTARIA, TRABALHOS DE CARPINTARIA; MANUTENCAO DE",
  },
  {
    cnae: "4330402",
    descricao:
      "ARMARIOS EMBUTIDOS QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA, COLOCACAO DE",
  },
  {
    cnae: "4330402",
    descricao: "CABECEIRAS E PEITORIS (OU SOLEIRAS), FIXACAO DE",
  },
  {
    cnae: "4330402",
    descricao: "ESTANDES (STANDS) PARA FEIRAS E EVENTOS; INSTALACAO DE",
  },
  {
    cnae: "4330402",
    descricao:
      "TRABALHOS EM MADEIRA EM INTERIORES QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA, EXECUCAO DE",
  },
  {
    cnae: "4330402",
    descricao:
      "ESQUADRIAS DE METAL, MADEIRA, PVC OU QUALQUER OUTRO MATERIAL, QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA; INSTALACAO DE",
  },
  {
    cnae: "4330402",
    descricao:
      "EXECUCAO DE TRABALHOS DE CARPINTARIA EM RESIDENCIAS, LOJAS E ETC",
  },
  {
    cnae: "4330402",
    descricao:
      "PORTAS, JANELAS, ADUELAS, ALISARES, MARCOS E CONTRAMARCOS QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA; INSTALACAO DE",
  },
  { cnae: "4330403", descricao: "TETOS EM GESSO, REBAIXAMENTO DE" },
  {
    cnae: "4330403",
    descricao:
      "ELEMENTOS DE DECORACAO DIVERSOS EM GESSO EM PAREDES, FACHADAS, TETOS, COLUNAS E VIGAS, COLOCACAO DE",
  },
  { cnae: "4330403", descricao: "GESSO, ACABAMENTOS EM GESSO" },
  { cnae: "4330403", descricao: "GESSO, REBAIXAMENTO DE TETO, PAREDES, ETC" },
  { cnae: "4330403", descricao: "GESSO ARAMADO, APLICACAO DE" },
  { cnae: "4330403", descricao: "GESSO ESTRUTURADO, APLICACAO DE" },
  {
    cnae: "4330403",
    descricao: "REVESTIMENTOS EM GESSO E ESTUQUE, COLOCACAO DE",
  },
  { cnae: "4330403", descricao: "FOLHAS DE GESSO ACARTONADO, COLOCACAO DE" },
  { cnae: "4330403", descricao: "ESTUQUE VENEZIANO EM PAREDES, APLICACAO DE" },
  { cnae: "4330403", descricao: "RODATETOS EM GESSO, COLOCACAO DE" },
  { cnae: "4330403", descricao: "SANCAS DE GESSO, COLOCACAO DE" },
  { cnae: "4330403", descricao: "ACABAMENTO EM GESSO E ESTUQUE; SERVICO DE" },
  { cnae: "4330403", descricao: "FORROS EM GESSO OU DE ESTUQUE, COLOCACAO DE" },
  { cnae: "9411100", descricao: "ASSOCIACAO PATRONAL" },
  {
    cnae: "4330403",
    descricao:
      "GESSO PARA DECORACAO E ACABAMENTO EM CONSTRUCAO CIVIL, APLICACAO DE",
  },
  { cnae: "4330403", descricao: "FORRO DE PLACAS DE GESSO, APLICACAO DE" },
  {
    cnae: "4330403",
    descricao: "MOLDURAS EM GESSO PARA PAREDES E TETOS, COLOCACAO DE",
  },
  {
    cnae: "4330404",
    descricao: "MASSA CORRIDA EM PAREDES E ESQUADRIAS DE MADEIRA, APLICACAO DE",
  },
  {
    cnae: "4330404",
    descricao:
      "PINTURA DE TETOS, PAREDES, ESQUADRIAS, RODAPES, ETC.; SERVICOS DE",
  },
  {
    cnae: "4330404",
    descricao: "PINTURA EM OBRAS DE ENGENHARIA CIVIL; SERVICO DE",
  },
  {
    cnae: "4330404",
    descricao:
      "PINTURA, INTERIOR E EXTERIOR, EM EDIFICACOES DE QUALQUER TIPO; SERVICO DE",
  },
  {
    cnae: "4330404",
    descricao: "PINTURA TEXTURIZADA (TEXTURIZACAO); SERVICOS DE",
  },
  {
    cnae: "4330404",
    descricao: "PINTURA, EDIFICACOES RESIDENCIAIS; SERVICO DE",
  },
  {
    cnae: "4330404",
    descricao: "PINTURA, CASAS, APARTAMENTOS, CONDOMINIOS; SERVICO DE",
  },
  { cnae: "4330404", descricao: "PINTURA, EDIFICACOES COMERCIAIS; SERVICO DE" },
  {
    cnae: "4330404",
    descricao: "PINTURA, EDIFICACOES DE QUALQUER TIPO; SERVICOS DE",
  },
  {
    cnae: "4330404",
    descricao:
      "VERNIZ EM ESQUADRIAS, PECAS DE MADEIRA OU CONCRETO APARENTE, APLICACAO DE",
  },
  { cnae: "4330404", descricao: "ZARCAO EM ESQUADRIAS DE FERRO, APLICACAO DE" },
  {
    cnae: "4330405",
    descricao:
      "REVESTIMENTO EM PASTILHAS PARA PAREDES E FACHADAS, COLOCACAO DE",
  },
  {
    cnae: "4330405",
    descricao:
      "CALAFETAGEM, RASPAGEM, POLIMENTO E APLICACAO DE RESINAS EM PISOS (CONSTRUCAO)",
  },
  {
    cnae: "4330405",
    descricao:
      "PISOS DE PLASTICO, BORRACHA E MATERIAIS SEMELHANTES, COLOCACAO DE",
  },
  { cnae: "4330405", descricao: "PISOS ENCERADOS, EXECUCAO DE" },
  { cnae: "4330405", descricao: "PAPEIS DE PAREDE, COLOCACAO DE" },
  {
    cnae: "4330405",
    descricao:
      "REVESTIMENTOS EM ALUMINIO COMPOSTO PARA PAREDES, COLUNAS, VIGAS E FACHADAS, EXECUCAO DE",
  },
  {
    cnae: "4330405",
    descricao: "PELICULA DE POLIESTER (INSULFILM), EM IMOVEIS, APLICACAO DE",
  },
  {
    cnae: "4330405",
    descricao: "REVESTIMENTOS EM PAREDES COM ARGAMASSA, EXECUCAO DE",
  },
  { cnae: "4330405", descricao: "TABUADO CORRIDO DE MADEIRA, COLOCACAO DE" },
  { cnae: "4330405", descricao: "AZULEJOS, COLOCACAO DE" },
  {
    cnae: "4330405",
    descricao: "PELICULA NAO REFLETIVA (INSULFILM), EM IMOVEIS, APLICACAO DE",
  },
  {
    cnae: "4330405",
    descricao:
      "RASPAGEM, POLIMENTO E APLICACAO DE RESINAS EM PISOS, PAREDES E TETOS; SERVICOS DE",
  },
  { cnae: "4330405", descricao: "CALAFETAGEM; SERVICOS DE" },
  {
    cnae: "4330405",
    descricao:
      "REVESTIMENTOS EM MARMORES, GRANITOS E OUTRAS PEDRAS EM PISOS, PAREDES, TETOS E FACHADAS, COLOCACAO DE",
  },
  {
    cnae: "4330405",
    descricao: "CARPETES, TAPETES E PASSADEIRAS, COLOCACAO DE",
  },
  {
    cnae: "4330405",
    descricao: "SYNTEKO (SINTECO) EM PISOS DE MADEIRA, APLICACAO DE",
  },
  {
    cnae: "4330405",
    descricao:
      "REVESTIMENTOS DE CERAMICA, AZULEJOS, MARMORE, GRANITO, PEDRAS E OUTROS MATERIAIS EM PAREDES E PISOS, TANTO NO INTERIOR QUANTO NO EXTERIOR DE EDIFICACOES, COLOCACAO DE",
  },
  {
    cnae: "4330405",
    descricao:
      "TACOS, TACOES, TABUAS E OUTROS TIPOS ASSOALHOS DE MADEIRA, COLOCACAO DE",
  },
  {
    cnae: "4330405",
    descricao:
      "INSULFILM (FILM OU PELICULA DE CONTROLE SOLAR), EM IMOVEIS, APLICACAO DE",
  },
  { cnae: "4330405", descricao: "TABUAS CORRIDAS, COLOCACAO DE" },
  { cnae: "4330405", descricao: "PISOS E AZULEJOS, ASSENTAMENTO DE" },
  { cnae: "4330405", descricao: "PISOS E AZULEJOS, COLOCACAO DE" },
  { cnae: "4330499", descricao: "VIDROS, CRISTAIS E ESPELHOS, COLOCACAO DE" },
  { cnae: "4330499", descricao: "CHAPISCO; SERVICOS DE" },
  {
    cnae: "4330499",
    descricao: "CONTROLE DE CORROSAO EM ESTRUTURAS (CONSTRUCAO)",
  },
  {
    cnae: "4330499",
    descricao:
      "BALCOES E EQUIPAMENTOS PARA LOJAS COMERCIAIS; INSTALACAO DE (QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA)",
  },
  {
    cnae: "4330499",
    descricao: "EDIFICIOS APOS O TERMINO DA FASE DE CONSTRUCAO, LIMPEZA DE",
  },
  { cnae: "4330499", descricao: "OBRAS DE ACABAMENTO, N.E., (CONSTRUCAO)" },
  { cnae: "4330499", descricao: "EMBOCO E REBOCO; SERVICO DE" },
  { cnae: "4330499", descricao: "TOLDOS E PERSIANAS; INSTALACAO DE" },
  {
    cnae: "4330499",
    descricao:
      "PISCINAS PRE FABRICADAS, QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA; INSTALACAO DE",
  },
  {
    cnae: "4330499",
    descricao: "TRINCAS E FISSURAS EM PAREDES, TRATAMENTO DE",
  },
  { cnae: "4330499", descricao: "BRISES; INSTALACAO DE" },
  { cnae: "4330499", descricao: "PISCINAS RESIDENCIAIS, CONSTRUCAO DE" },
  { cnae: "4330499", descricao: "EMBOCAMENTO; SERVICOS DE" },
  {
    cnae: "4330499",
    descricao:
      "SOMBREADORES PARA ESTACIONAMENTOS (COBERTURAS EM LONA); INSTALACAO DE",
  },
  { cnae: "4330499", descricao: "TEXTURIZACAO EM PAREDES; SERVICOS DE" },
  {
    cnae: "4330499",
    descricao: "BLINDAGEM DE ESQUADRIAS (VIDROS); SERVICOS DE",
  },
  { cnae: "43916", descricao: "OBRAS DE FUNDACOES" },
  {
    cnae: "4391600",
    descricao: "FUNDACOES EM ESTACAS ESCAVADAS, CONSTRUCAO DE",
  },
  {
    cnae: "4391600",
    descricao: "ESTAQUEAMENTO OU CRAVACAO DE ESTACAS; SERVICOS DE",
  },
  {
    cnae: "4391600",
    descricao:
      "EQUIPAMENTOS DE PERFURACAO (PERFURATRIZES) PARA EXECUCAO DE FUNDACOES COM OPERADOR; LOCACAO DE",
  },
  {
    cnae: "4391600",
    descricao: "FUNDACOES EM ESTACAS HELICE CONTINUAS, CONSTRUCAO DE",
  },
  {
    cnae: "4391600",
    descricao: "TUBULAO ENCAMISADO A CEU ABERTO, EXECUCAO DE",
  },
  { cnae: "4391600", descricao: "FUNDACOES, REFORCO DE" },
  {
    cnae: "4391600",
    descricao: "TUBULAO ENCAMISADO A AR COMPRIMIDO, EXECUCAO DE",
  },
  {
    cnae: "4391600",
    descricao:
      "ESTACAS PARA FUNDACOES (STRAUSS, FRANKI, SIMPLEX, METALICAS, PRE MOLDADAS, RAIZ, MEGA, PRENSADA, MOLDADAS IN-LOCO), CRAVACAO DE",
  },
  {
    cnae: "4391600",
    descricao: "FUNDACOES INDIRETAS OU PROFUNDAS, CONSTRUCAO DE",
  },
  { cnae: "4391600", descricao: "FUNDACOES CONTINUAS, CONSTRUCAO DE" },
  { cnae: "4391600", descricao: "FUNDACOES RASAS OU DIRETAS, CONSTRUCAO DE" },
  { cnae: "4391600", descricao: "BATE-ESTACAS COM OPERADOR; LOCACAO DE" },
  { cnae: "4391600", descricao: "JET GROUTING, CONSTRUCAO DE" },
  {
    cnae: "4391600",
    descricao: "FUNDACAO (FUNDACOES) POR TUBULAO (TUBULOES), EXECUCAO DE",
  },
  { cnae: "4391600", descricao: "RADIERS, CONSTRUCAO DE" },
  {
    cnae: "4391600",
    descricao:
      "FUNDACOES DIVERSAS PARA EDIFICIOS E OUTRAS OBRAS DE ENGENHARIA, EXECUCAO DE",
  },
  {
    cnae: "4391600",
    descricao:
      "ESTACAS (RAIZ, TRADO, TIPO FRANKI, PRE MOLDADAS, STRAUSS), OBRAS DE CRAVACAO DE",
  },
  {
    cnae: "4391600",
    descricao:
      "PERFURACOES E EXECUCAO DE FUNDACOES DESTINADAS A CONSTRUCAO CIVIL",
  },
  {
    cnae: "4391600",
    descricao:
      "FUNDACOES PARA EDIFICACOES E OUTRAS OBRAS DE ENGENHARIA CIVIL (CONSTRUCAO), EXECUCAO DE",
  },
  { cnae: "4391600", descricao: "BALDRAMES, CONSTRUCAO DE" },
  { cnae: "4391600", descricao: "FUNDACOES PROFUNDAS, CONSTRUCAO DE" },
  {
    cnae: "4391600",
    descricao: "EDIFICACOES E ESTRUTURAS, PERFURACAO (CONSTRUCAO) PARA",
  },
  { cnae: "4391600", descricao: "FUNDACOES EM ESTACAS RAIZ, CONSTRUCAO DE" },
  { cnae: "4391600", descricao: "FUNDACOES TIPO SAPATAS, CONSTRUCAO DE" },
  {
    cnae: "4391600",
    descricao: "ALICERCES E BLOCOS DE FUNDACAO, CONSTRUCAO DE",
  },
  {
    cnae: "4391600",
    descricao: "FUNDACOES EM SAPATAS COMBINADAS, CONSTRUCAO DE",
  },
  { cnae: "4391600", descricao: "ESTAQUEAMENTO (FUNDACAO POR ESTACAS)" },
  { cnae: "4391600", descricao: "FUNDACOES ISOLADAS, CONSTRUCAO DE" },
  { cnae: "4391600", descricao: "SAPATAS OU FUNDACOES DIRETAS, CONSTRUCAO DE" },
  {
    cnae: "43991",
    descricao:
      "SERVICOS ESPECIALIZADOS PARA CONSTRUCAO NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "4399101",
    descricao:
      "OBRAS POR CONTRATO DE CONSTRUCAO POR ADMINISTRACAO, DIRECAO E RESPONSABILIDADE TECNICA DE",
  },
  { cnae: "9411100", descricao: "ORGANIZACAO PATRONAL" },
  {
    cnae: "4399101",
    descricao:
      "OBRAS POR CONTRATO DE CONSTRUCAO POR ADMINISTRACAO, GERENCIAMENTO DE",
  },
  {
    cnae: "4399101",
    descricao:
      "OBRAS POR CONTRATO DE CONSTRUCAO POR ADMINISTRACAO, EXECUCAO DE",
  },
  { cnae: "4399101", descricao: "ADMINISTRACAO DE OBRAS; SERVICO DE" },
  {
    cnae: "4399101",
    descricao:
      "GERENCIAMENTO E EXECUCAO DE OBRAS POR CONTRATO DE CONSTRUCAO POR ADMINISTRACAO; SERVICO DE",
  },
  {
    cnae: "4399102",
    descricao: "PLATAFORMAS DE TRABALHO, MONTAGEM E DESMONTAGEM DE",
  },
  {
    cnae: "4399102",
    descricao:
      "ANDAIMES, PLATAFORMAS, FORMAS PARA CONCRETO E ESCORAMENTO, MONTAGEM DE",
  },
  {
    cnae: "4399102",
    descricao:
      "ANDAIMES, PLATAFORMAS, FORMAS PARA CONCRETO E ESCORAMENTO, DESMONTAGEM DE",
  },
  {
    cnae: "4399102",
    descricao:
      "TERCAS E TRELICAS METALICAS PARA ESCORAMENTO DE PONTES, VIADUTOS E PASSARELAS, MONTAGEM E DESMONTAGEM DE",
  },
  {
    cnae: "4399102",
    descricao:
      "FORMAS METALICAS PARA LAJES, COLUNAS, VIGAS E PILARES, MONTAGEM E DESMONTAGEM DE",
  },
  {
    cnae: "4399102",
    descricao:
      "MONTAGEM E DESMONTAGEM DE ESCORAMENTO METALICOS E EM MADEIRA PARA A SUSTENTACAO DE FORMAS PARA O CONCRETO ARMADO, SERVICOS DE;",
  },
  { cnae: "4399102", descricao: "ESCORAS, MONTAGEM E DESMONTAGEM DE" },
  {
    cnae: "4399102",
    descricao:
      "ESTRUTURAS METALICAS TUBULARES TEMPORARIAS PARA USOS DIVERSOS (ARQUIBANCADAS, PALCOS, ETC.), EXCETO POR CONTA DE TERCEIROS, MONTAGEM E DESMONTAGEM DE",
  },
  { cnae: "4399102", descricao: "APARALIXOS, MONTAGEM E DESMONTAGEM DE" },
  { cnae: "4399102", descricao: "ESTRUTURAS METALICAS MOVEIS, DESMONTAGEM DE" },
  {
    cnae: "4399102",
    descricao: "ANDAIMES TUBULARES, MONTAGEM E DESMONTAGEM DE",
  },
  {
    cnae: "4399102",
    descricao: "ESTRUTURAS TEMPORARIAS, MONTAGEM E DESMONTAGEM DE",
  },
  { cnae: "4399102", descricao: "ANDAIME TORRE, MONTAGEM E DESMONTAGEM DE" },
  {
    cnae: "4399102",
    descricao: "ANDAIMES E PLATAFORMAS SUSPENSAS, MONTAGEM E DESMONTAGEM DE",
  },
  {
    cnae: "4399102",
    descricao:
      "ANDAIMES DE ENCAIXE EM QUADROS PARA USO EM FACHADAS (FACHADEIROS), MONTAGEM E DESMONTAGEM DE",
  },
  {
    cnae: "4399102",
    descricao: "ANDAIMES INDUSTRIAIS, MONTAGEM E DESMONTAGEM DE",
  },
  {
    cnae: "4399102",
    descricao: "ANDAIMES FACHADEIROS, MONTAGEM E DESMONTAGEM DE",
  },
  { cnae: "4399102", descricao: "CIMBRAMENTO, MONTAGEM E DESMONTAGEM DE" },
  { cnae: "4399102", descricao: "ANDAIMES, MONTAGEM E DESMONTAGEM DE" },
  {
    cnae: "4399103",
    descricao: "PAREDES E MUROS EM ALVENARIA, LEVANTAMENTO DE",
  },
  { cnae: "4399103", descricao: "PAREDES DE PEDRA, CONSTRUCAO DE" },
  { cnae: "4399103", descricao: "ALVENARIA, OBRAS DE" },
  { cnae: "4399103", descricao: "MUROS DE TIJOLOS, CONSTRUCAO DE" },
  {
    cnae: "4399103",
    descricao: "PAREDES DE BLOCOS DE CONCRETO, CONSTRUCAO DE",
  },
  { cnae: "4399103", descricao: "MUROS DE BLOCOS DE CONCRETO, CONSTRUCAO DE" },
  {
    cnae: "4399103",
    descricao: "TRABALHOS DE PEDREIRO PARA OBRAS DE ALVENARIA, EXECUCAO DE",
  },
  { cnae: "4399103", descricao: "MUROS DE PEDRA, CONSTRUCAO DE" },
  { cnae: "4399103", descricao: "CANTARIA, OBRAS DE" },
  { cnae: "4399103", descricao: "EXECUCAO DE ALVENARIA" },
  { cnae: "4399103", descricao: "PAREDES DE ALVENARIA, CONSTRUCAO DE" },
  {
    cnae: "4399104",
    descricao:
      "EMPILHADEIRAS COM OPERADOR PARA USO NA CONSTRUCAO CIVIL; LOCACAO DE",
  },
  {
    cnae: "4399104",
    descricao:
      "MACACO HIDRAULICO COM OPERADOR PARA USO NA CONSTRUCAO CIVIL; LOCACAO DE",
  },
  {
    cnae: "4399104",
    descricao:
      "MINI-CARREGADEIRA COM OPERADOR PARA USO NA CONSTRUCAO CIVIL; LOCACAO DE",
  },
  {
    cnae: "4399104",
    descricao:
      "EQUIPAMENTOS DIVERSOS PARA TRANSPORTE E ELEVACAO DE CARGAS E PESSOAS COM OPERADOR PARA USO NA CONSTRUCAO CIVIL; LOCACAO DE",
  },
  {
    cnae: "4399104",
    descricao:
      "PLATAFORMAS DE TRABALHO PARA CONSTRUCAO CIVIL, MANUAIS OU AUTOMATICAS, COM OPERADOR; LOCACAO DE",
  },
  {
    cnae: "4399104",
    descricao: "ELEVADORES DE OBRA (CARGAS E PESSOAS) COM OPERADOR, LOCACAO",
  },
  {
    cnae: "4399104",
    descricao:
      "CARRO PLATAFORMA COM OPERADOR PARA USO NA CONSTRUCAO CIVIL; LOCACAO DE",
  },
  {
    cnae: "4399104",
    descricao:
      "ALUGUEL DE GUINDASTES, EMPILHADEIRAS PARA USO NA CONSTRUCAO CIVIL, COM OPERADOR",
  },
  {
    cnae: "4399104",
    descricao:
      "GUINDASTES MOVEIS OU FIXOS COM OPERADOR PARA USO NA CONSTRUCAO CIVIL; LOCACAO",
  },
  {
    cnae: "4399104",
    descricao:
      "LANCA TELESCOPICA COM OPERADOR PARA USO NA CONSTRUCAO CIVIL; LOCACAO DE",
  },
  {
    cnae: "4399104",
    descricao:
      "PLATAFORMAS PANTOGRAFICAS COM OPERADOR PARA USO NA CONSTRUCAO CIVIL; LOCACAO DE",
  },
  {
    cnae: "4399104",
    descricao: "DUMPER COM OPERADOR PARA USO NA CONSTRUCAO CIVIL; LOCACAO DE",
  },
  {
    cnae: "4399104",
    descricao: "GUINCHOS COM OPERADOR PARA USO NA CONSTRUCAO CIVIL; LOCACAO",
  },
  {
    cnae: "4399104",
    descricao: "MINI-GRUAS COM OPERADOR PARA USO NA CONSTRUCAO CIVIL; LOCACAO",
  },
  {
    cnae: "4399104",
    descricao:
      "GRUAS (MOVEIS, FIXAS OU ASCENSIONAIS) COM OPERADOR PARA USO NA CONSTRUCAO CIVIL; LOCACAO",
  },
  {
    cnae: "4399104",
    descricao: "TROLLEY COM OPERADOR PARA USO NA CONSTRUCAO CIVIL; LOCACAO DE",
  },
  {
    cnae: "4399104",
    descricao:
      "GUINDASTES, EMPILHADEIRAS PARA CONSTRUCAO CIVIL, COM OPERADOR; LOCACAO DE",
  },
  {
    cnae: "4399104",
    descricao:
      "LANCA ARTICULADA COM OPERADOR PARA USO NA CONSTRUCAO CIVIL; LOCACAO DE",
  },
  { cnae: "4399105", descricao: "POCOS ARTESIANOS; MANUTENCAO DE" },
  { cnae: "4399105", descricao: "POCOS ARTESIANOS, PERFURACAO DE" },
  { cnae: "4399105", descricao: "POCOS ARTESIANOS, REVESTIMENTO DE" },
  { cnae: "4399105", descricao: "POCOS DE AGUA, CONSTRUCAO E PERFURACAO DE" },
  { cnae: "4399105", descricao: "POCOS DE AGUA SUBTERRANEA, PERFURACAO DE" },
  { cnae: "4399105", descricao: "POCOS ARTESIANOS, REAPROFUNDAMENTO DE" },
  { cnae: "4399105", descricao: "POCOS ARTESIANOS, CONSTRUCAO DE" },
  { cnae: "4399105", descricao: "PERFURACAO DE POCOS DE AGUA, OBRAS DE" },
  { cnae: "4399105", descricao: "POCOS SEMIARTESIANOS, PERFURACAO DE" },
  { cnae: "4399105", descricao: "POCOS TUBULARES, PERFURACAO DE" },
  {
    cnae: "4399199",
    descricao:
      "PARTES DE EDIFICIOS (TELHADOS, CAIXAS D'AGUA, CHAMINES, ETC.), CONSTRUCAO DE",
  },
  {
    cnae: "4399199",
    descricao:
      "BOMBEAMENTO DE CONCRETO, EXCETO USINAS DE CONCRETO; SERVICOS DE",
  },
  { cnae: "4399199", descricao: "TELHADOS, CONSTRUCAO DE" },
  {
    cnae: "4399199",
    descricao: "CHAMINES, LAREIRAS, CHURRASQUEIRAS, CONSTRUCAO DE",
  },
  { cnae: "4399199", descricao: "COLOCACAO DE TELHADOS, COBERTURAS, OBRAS DE" },
  { cnae: "4399199", descricao: "CALHAS; COLOCACAO, INSTALACAO DE" },
  { cnae: "4399199", descricao: "COBERTURAS, CONSTRUCAO DE" },
  { cnae: "4399199", descricao: "PISO ELEVADO, EXECUCAO DE" },
  {
    cnae: "4399199",
    descricao:
      "LIMPEZA DE FACHADAS, COM JATEAMENTO DE AREIA E SEMELHANTES; SERVICOS DE",
  },
  { cnae: "4399199", descricao: "CHAMINES INDUSTRIAIS, CONSTRUCAO DE" },
  { cnae: "4399199", descricao: "FORNOS INDUSTRIAIS, CONSTRUCAO DE" },
  {
    cnae: "4399199",
    descricao:
      "TRABALHOS EM EDIFICIOS E EM ESTRUTURAS DE GRANDE ALTURA, ESCALAGEM PARA EXECUCAO DE",
  },
  { cnae: "4399199", descricao: "CHURRASQUEIRAS DE ALVENARIA, CONSTRUCAO DE" },
  {
    cnae: "4399199",
    descricao:
      "LIMPEZA DE FACHADAS COM JATEAMENTO DE VAPOR OU AGUA; SERVICOS DE",
  },
  { cnae: "4399199", descricao: "LAREIRAS, CONSTRUCAO DE" },
  {
    cnae: "4399199",
    descricao:
      "CONCRETAGEM (EM FORMAS) DE VIGAS, COLUNAS, LAJES E OUTRAS PECAS ESTRUTURAIS EM OBRAS DE CONSTRUCAO CIVIL; OBRAS DE",
  },
  {
    cnae: "45111",
    descricao: "COMERCIO A VAREJO E POR ATACADO DE VEICULOS AUTOMOTORES",
  },
  {
    cnae: "4511101",
    descricao:
      "AUTOMOVEL, CAMINHONETES (CAMIONETAS, CAMIONETES) E UTILITARIOS NOVOS; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4511101",
    descricao: "REBOQUES E SEMIRREBOQUES NOVOS; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4511101",
    descricao:
      "CARROS NOVOS PARA GOLFE E OUTROS ESPORTES; COMERCIO VAREJISTA DE",
  },
  { cnae: "4511101", descricao: "TRAILLERS NOVOS; COMERCIO VAREJISTA DE" },
  {
    cnae: "4511102",
    descricao:
      "CARROS USADOS PARA GOLFE E OUTROS ESPORTES, COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4511102",
    descricao: "REBOQUES E SEMIRRERBOQUES USADOS; COMERCIO VAREJISTA DE",
  },
  { cnae: "4511102", descricao: "TRAILLERS USADOS; COMERCIO VAREJISTA" },
  {
    cnae: "4511102",
    descricao:
      "AUTOMOVEL, CAMINHONETES (CAMIONETAS, CAMIONETES) E UTILITARIOS USADOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4511103",
    descricao:
      "AUTOMOVEL, CAMINHONETES (CAMIONETAS, CAMIONETES) E UTILITARIOS, NOVOS E USADOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4511103", descricao: "AMBULANCIAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4511103",
    descricao:
      "CARROS NOVOS E USADOS PARA GOLFE E OUTROS ESPORTES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4511104",
    descricao:
      "CARROCERIA PARA CAMINHAO, NOVAS E USADAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4511104",
    descricao: "BETONEIRAS DE CONCRETO, VEICULO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4511104", descricao: "GRUAS, VEICULO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4511104",
    descricao: "CAMINHOES NOVOS E USADOS, COMERCIO ATACADISTA",
  },
  {
    cnae: "4511105",
    descricao:
      "REBOQUES E SEMIRREBOQUES NOVOS E USADOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4511105",
    descricao: "TRAILLERS, NOVOS E USADOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4511106",
    descricao: "ONIBUS E MICROONIBUS NOVOS E USADOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "45129",
    descricao:
      "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE VEICULOS AUTOMOTORES",
  },
  {
    cnae: "4512901",
    descricao:
      "INTERMEDIARIOS NA VENDA DE VEICULOS AUTOMOTORES, ATACADISTA E VAREJISTA",
  },
  {
    cnae: "4512901",
    descricao:
      "REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE VEICULOS AUTOMOTORES, ATACADISTA E VAREJISTA",
  },
  {
    cnae: "4512902",
    descricao:
      "COMERCIO SOB CONSIGNACAO DE VEICULOS AUTOMOTORES; ATACADO E VAREJO",
  },
  { cnae: "4512902", descricao: "CORRETORA DE VEICULOS (EM CONSIGNACAO)" },
  {
    cnae: "45200",
    descricao: "MANUTENCAO E REPARACAO DE VEICULOS AUTOMOTORES",
  },
  {
    cnae: "4520001",
    descricao: "CAMBAGEM EM ONIBUS, CAMINHOES E VEICULOS PESADOS; SERVICOS DE",
  },
  {
    cnae: "4520001",
    descricao:
      "ADAPTACAO DE VEICULOS AUTOMOTORES PARA DEFICIENTES FISICOS; SERVICOS DE",
  },
  {
    cnae: "4520001",
    descricao: "CAMINHOES, ONIBUS E VEICULOS PESADOS; MANUTENCAO MECANICA DE",
  },
  { cnae: "4520001", descricao: "CAMBAGEM DE AUTOMOVEL; SERVICOS DE" },
  {
    cnae: "4520001",
    descricao:
      "MANUTENCAO E REPARACAO DE RODAS E AROS DE VEICULOS AUTOMOTORES; SERVICOS DE",
  },
  {
    cnae: "4520001",
    descricao:
      "INSTALACAO DE KIT DE GAS VEICULAR PARA CONVERSAO DE MOTORES DE VEICULOS AUTOMOTORES; SERVICOS DE",
  },
  {
    cnae: "4520001",
    descricao:
      "INSPECAO MECANICA E ELETRICA  PARA VEICULOS AUTOMOTORES PARA FINS DE VISTORIA",
  },
  {
    cnae: "4520001",
    descricao: "VIDRACARIA, VIDRACEIRO EM VEICULO; SERVICOS DE",
  },
  { cnae: "4520001", descricao: "OFICINA MECANICA DE VEICULO AUTOMOTOR" },
  {
    cnae: "4520001",
    descricao:
      "VIDRACARIA EM CAMINHOES, ONIBUS E VEICULOS PESADOS; SERVICOS DE",
  },
  {
    cnae: "4520001",
    descricao: "CONVERSAO DE MOTORES DE AUTOMOVEL; SERVICOS DE",
  },
  {
    cnae: "4520001",
    descricao:
      "CARROCARIAS, CARROCERIAS PARA CAMINHOES, ONIBUS E VEICULOS PESADOS (INCLUSIVE MADEIRA); MANUTENCAO E REFORMA",
  },
  {
    cnae: "4520001",
    descricao:
      "TANQUES, RESERVATORIOS E CILINDROS METALICOS PARA VEICULOS AUTOMOTORES; MANUTENCAO E REPARACAO DE",
  },
  {
    cnae: "4520001",
    descricao: "MONTAGEM DE CALIBRADORES EM ONIBUS E CAMINHOES; SERVICOS DE",
  },
  {
    cnae: "4520001",
    descricao:
      "REPARACAO EM SISTEMAS DE INJECAO E IGNICAO ELETRONICA EM VEICULOS AUTOMOTORES; SERVICOS DE",
  },
  {
    cnae: "4520002",
    descricao:
      "PINTURA, LANTERNAGEM E FUNILARIA DE ONIBUS, CAMINHOES E VEICULOS PESADOS; SERVICOS DE",
  },
  { cnae: "4520002", descricao: "MARTELINHO DE OURO; SERVICOS DE" },
  {
    cnae: "4520002",
    descricao: "PINTURA, LANTERNAGEM E FUNILARIA DE VEICULO; SERVICOS DE",
  },
  {
    cnae: "4520003",
    descricao: "CAMINHOES, ONIBUS E VEICULOS PESADOS; MANUTENCAO ELETRICA DE",
  },
  {
    cnae: "4520003",
    descricao: "MANUTENCAO E REPARACAO ELETRICA DE AUTOMOVEL",
  },
  {
    cnae: "4520003",
    descricao:
      "BATERIAS E ACUMULADORES ELETRICOS PARA VEICULOS AUTOMOTORES; MANUTENCAO E REPARACAO DE",
  },
  {
    cnae: "4520003",
    descricao: "RADIOS E AUTO-FALANTES PARA AUTOMOVEL; REPARACAO",
  },
  {
    cnae: "4520004",
    descricao: "ALINHAMENTO E BALANCEAMENTO DE AUTOMOVEL; SERVICOS DE",
  },
  {
    cnae: "4520004",
    descricao:
      "ALINHAMENTO E BALANCEAMENTO DE ONIBUS, CAMINHOES E VEICULOS PESADOS; SERVICOS DE",
  },
  { cnae: "4520005", descricao: "LAVAGEM AUTOMOTIVA A SECO; SERVICOS DE" },
  {
    cnae: "4520005",
    descricao: "LAVAGEM A SECO DE VEICULOS AUTOMOTORES; SERVICOS DE",
  },
  {
    cnae: "4520005",
    descricao:
      "LAVAGEM A SECO (ECOLOGICA)  DE VEICULOS AUTOMOTORES; SERVICOS DE",
  },
  {
    cnae: "4520005",
    descricao:
      "LAVAGEM, LUBRIFICACAO E POLIMENTO DE VEICULO AUTOMOTOR; SERVICOS DE",
  },
  {
    cnae: "4520005",
    descricao:
      "LAVAGEM, LUBRIFICACAO E POLIMENTO DE CAMINHOES, ONIBUS E VEICULOS PESADOS; SERVICOS DE",
  },
  {
    cnae: "4520005",
    descricao: "LAVA JATO, LAVA RAPIDO DE VEICULO AUTOMOTOR; SERVICOS DE",
  },
  {
    cnae: "4520006",
    descricao: "BORRACHARIA DE VEICULO AUTOMOTOR; SERVICOS DE",
  },
  {
    cnae: "4520006",
    descricao: "PNEUS E CAMARAS-DE-AR DE VEICULO AUTOMOTOR; REPARACAO DE",
  },
  {
    cnae: "4520006",
    descricao: "BORRACHEIRO E GOMARIA DE VEICULO AUTOMOTOR; SERVICOS DE",
  },
  {
    cnae: "4520007",
    descricao: "INSTALACAO DE INSULFILM PARA AUTOMOVEIS; SERVICOS DE",
  },
  {
    cnae: "4520007",
    descricao: "AR CONDICIONADO PARA VEICULO AUTOMOTOR; MANUTENCAO",
  },
  {
    cnae: "4520007",
    descricao:
      "INSTALACAO DE KITS DE BLINDAGEM DE VEICULOS AUTOMOTORES; SERVICOS DE",
  },
  {
    cnae: "4520007",
    descricao: "AR CONDICIONADO PARA VEICULO AUTOMOTOR; INSTALACAO",
  },
  {
    cnae: "4520007",
    descricao: "RECONDICIONAMENTO DE PECAS AUTOMOTIVAS; SERVICOS DE",
  },
  { cnae: "4520007", descricao: "INSULFILM PARA AUTOMOVEL; COLOCACAO DE" },
  {
    cnae: "4520007",
    descricao: "ALARMES E SISTEMAS DE SEGURANCA EM AUTOMOVEL; INSTALACAO DE",
  },
  {
    cnae: "4520007",
    descricao:
      "INSTALACAO DE ACESSORIOS PARA VEICULOS AUTOMOTORES; SERVICOS DE",
  },
  { cnae: "4520008", descricao: "CAPOTEIRO DE AUTOMOVEIS; SERVICOS DE" },
  { cnae: "4520008", descricao: "ESTOFARIA DE VEICULOS; SERVICOS DE" },
  {
    cnae: "4520008",
    descricao: "CAPOTEIRO DE ONIBUS, CAMINHOES E VEICULOS PESADOS; SERVICOS DE",
  },
  {
    cnae: "4520008",
    descricao: "REFORMA E MANUTENCAO DE ESTOFADOS DE VEICULOS; SERVICOS DE",
  },
  {
    cnae: "45307",
    descricao: "COMERCIO DE PECAS E ACESSORIOS PARA VEICULOS AUTOMOTORES",
  },
  {
    cnae: "4530701",
    descricao: "ALARMES PARA VEICULOS AUTOMOTIVOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao:
      "EQUIPAMENTOS DE SOM NOVOS PARA VEICULO AUTOMOTOR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao:
      "AMORTECEDORES E MOLAS NOVOS PARA VEICULO AUTOMOTOR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao:
      "AR CONDICIONADO NOVO PARA VEICULO AUTOMOTOR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao:
      "PECAS E ACESSORIOS NOVOS PARA VEICULO AUTOMOTOR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao:
      "RODAS E AROS NOVOS PARA VEICULO AUTOMOTOR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao:
      "VIDROS E ESPELHOS PARA VEICULOS AUTOMOTORES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao:
      "BATERIAS E ACUMULADORES NOVOS PARA VEICULO AUTOMOTOR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao: "RADIADORES PARA VEICULOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao:
      "PECAS E ACESSORIOS NOVOS PARA CARROCERIAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao:
      "CANOS E SILENCIOSOS NOVOS PARA VEICULO AUTOMOTOR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao:
      "ROLAMENTOS NOVOS PARA VEICULOS AUTOMOTORES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao: "CAPOTAS NOVAS PARA VEICULO AUTOMOTOR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao:
      "AUTO-FALANTES PARA VEICULOS AUTOMOTIVOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao:
      "CAPAS E ENCERADOS NOVOS PARA VEICULO AUTOMOTOR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao:
      "EXTINTORES DE INCENDIO NOVOS PARA VEICULO AUTOMOTOR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao:
      "PECAS E ADESIVOS NOVOS (ESPOLEERS, SAIS, AEROFOLIS, ETC) PARA TUNAGEM DE VEICULOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao: "ALARMES PARA AUTOMOVEIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao:
      "BANCOS ESTOFADOS NOVOS PARA VEICULO AUTOMOTOR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530701",
    descricao:
      "MOTORES NOVOS E RECONDICIONADOS PARA VEICULO AUTOMOTOR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4530702",
    descricao:
      "PNEUS E CAMARAS DE AR NOVOS E USADOS PARA VEICULO AUTOMOTOR; COMERCIO ATACADISTA DE (INCLUSIVE RECAUCHUTADO)",
  },
  {
    cnae: "4530703",
    descricao: "RADIADORES PARA VEICULOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530703",
    descricao: "AUTO-FALANTES PARA VEICULOS AUTOMOTIVOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530703",
    descricao:
      "PECAS E ACESSORIOS NOVOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530703",
    descricao: "ALARMES PARA VEICULOS AUTOMOTIVOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530703",
    descricao: "ROLAMENTOS NOVOS PARA VEICULOS AUTOMOTORES; COMERCIO VAREJISTA",
  },
  { cnae: "4530703", descricao: "ALARMES PARA AUTOMOVEIS; COMERCIO VAREJISTA" },
  {
    cnae: "4530703",
    descricao: "CAPOTAS NOVAS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4530703",
    descricao:
      "BATERIAS E ACUMULADORES NOVOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530703",
    descricao:
      "AR CONDICIONADO NOVO PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530703",
    descricao:
      "PECAS E ACESSORIOS NOVOS PARA CARROCERIAS; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4530703",
    descricao:
      "CANOS E SILENCIOSOS NOVOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530703",
    descricao:
      "BANCOS ESTOFADOS NOVOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530703",
    descricao:
      "MOTORES NOVOS E RECONDICIONADOS PARA VEICULOS AUTOMOTORES; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530703",
    descricao:
      "VIDROS E ESPELHOS PARA VEICULOS AUTOMOTORES; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4530703",
    descricao:
      "AMORTECEDORES E MOLAS NOVOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530703",
    descricao: "RODAS E AROS NOVOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530703",
    descricao:
      "EQUIPAMENTOS DE SOM NOVOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530703",
    descricao:
      "CAPAS E ENCERADOS NOVOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530703",
    descricao:
      "PECAS E ADESIVOS NOVOS (ESPOLEERS, SAIS, AEROFOLIS, ETC) PARA TUNAGEM DE VEICULOS; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4530703",
    descricao:
      "EXTINTORES DE INCENDIO NOVOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530704",
    descricao:
      "PECAS E ACESSORIOS USADOS PARA CARROCERIAS; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4530704",
    descricao:
      "PECAS E ACESSORIOS USADOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530704",
    descricao:
      "EQUIPAMENTOS DE SOM USADOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530704",
    descricao:
      "BATERIAS E ACUMULADORES USADOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530704",
    descricao:
      "MOTORES USADOS E RECONDICIONADOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530704",
    descricao:
      "BANCOS ESTOFADOS USADOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530704",
    descricao:
      "DESMANCHE DE VEICULOS AUTOMOTORES, COM COMERCIALIZACAO DE PARTES, PECAS E ACESSORIOS; SERVICOS DE",
  },
  {
    cnae: "4530704",
    descricao:
      "AMORTECEDORES E MOLAS USADOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530704",
    descricao: "RODAS E AROS USADOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530704",
    descricao:
      "EXTINTORES DE INCENDIO USADOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530704",
    descricao:
      "AR CONDICIONADO USADO PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530704",
    descricao:
      "CANOS E SILENCIOSOS USADOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530704",
    descricao: "CAPOTAS USADAS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4530704",
    descricao:
      "CAPAS E ENCERADOS USADOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530705",
    descricao:
      "PNEUS E CAMARAS DE AR NOVOS E USADOS PARA VEICULO AUTOMOTOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4530706",
    descricao:
      "REPRESENTANTE COMERCIAL E AGENTE DE COMERCIO DE PECAS E ACESSORIOS NOVOS E USADOS PARA VEICULO AUTOMOTOR; ATACADISTA E VAREJISTA",
  },
  {
    cnae: "45412",
    descricao:
      "COMERCIO POR ATACADO E A VAREJO DE MOTOCICLETAS, PECAS E ACESSORIOS",
  },
  {
    cnae: "4541201",
    descricao: "MOTOS NOVAS E USADAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4541201",
    descricao:
      "MOTOCICLETAS E MOTONETAS NOVAS E USADAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4541202",
    descricao:
      "PECAS, PARTES E ACESSORIOS PARA MOTOCICLETAS, MOTOS E MOTONETAS, NOVAS E USADAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4541203",
    descricao: "TRICICLOS, QUADRICICLOS MOTORIZADOS; COMERCIO VAREJISTA DE",
  },
  { cnae: "4541203", descricao: "MOTOS E MOTONETAS NOVAS; COMERCIO VAREJISTA" },
  {
    cnae: "4541204",
    descricao: "MOTOCICLETAS, MOTOS E MOTONETAS USADAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4541206",
    descricao:
      "PECAS, PARTES E ACESSORIOS PARA MOTOCICLETAS, MOTOS E MOTONETAS, NOVAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4541207",
    descricao:
      "PECAS, PARTES E ACESSORIOS PARA MOTOCICLETAS, MOTOS E MOTONETAS, USADAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "45421",
    descricao:
      "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MOTOCICLETAS, PECAS E ACESSORIOS",
  },
  {
    cnae: "4542101",
    descricao:
      "REPRESENTANTE COMERCIAL E AGENTE DE COMERCIO DE PECAS E ACESSORIOS NOVOS E USADOS PARA MOTOCICLETAS, MOTOS E MOTONETAS; ATACADISTA E VAREJISTA",
  },
  {
    cnae: "4542102",
    descricao: "COMERCIO SOB CONSIGNACAO DE MOTOCICLETAS E MOTONETAS",
  },
  { cnae: "45439", descricao: "MANUTENCAO E REPARACAO DE MOTOCICLETAS" },
  {
    cnae: "4543900",
    descricao: "MOTOCICLETAS, MOTOS E MOTONETAS; MANUTENCAO E REPARACAO",
  },
  {
    cnae: "4543900",
    descricao: "ACESSORIOS EM MOTOCICLETAS E MOTONETAS; INSTALACAO DE",
  },
  {
    cnae: "46117",
    descricao:
      "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MATERIASPRIMAS AGRICOLAS E ANIMAIS VIVOS",
  },
  {
    cnae: "4611700",
    descricao:
      "PALHA, CERA DE CARNAUBA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao: "CAFE EM GRAO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "LEGUMINOSAS EM BRUTO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "OLEAGINOSAS EM BRUTO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "BORRACHA NATURAL, GOMA VEGETAL; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "ERVAS MEDICINAIS EM BRUTO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao: "SEMEN BOVINOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao: "CAFE VERDE; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "SEMENTES, BAGAS, GRAOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "SUINOS (PORCOS); REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao: "CAPRINOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO",
  },
  {
    cnae: "4611700",
    descricao:
      "MILHO EM GRAO, SOJA EM GRAO, CAFE EM COCO, ARROZ EM CASCA, TRIGO EM GRAO, CANA DE ACUCAR, AMENDOIM EM CASCA, BABACU; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "FIOS E FIBRAS TEXTEIS NAO BENEFICIADAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao: "FORRAGENS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao: "OVINOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao: "ANIMAIS VIVOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "PRODUTOS SEMIACABADOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "CASULOS DE BICHO DA SEDA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "CAFE IN NATURA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "MATERIAS PRIMAS TEXTEIS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "CEREAIS EM BRUTO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "ALGODAO EM CAROCO, JUTA, SISAL, FIBRAS VEGETAIS, LA, CRINAS, PELOS, CERDAS ANIMAIS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "COURO EM BRUTO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "FUMO EM FOLHA NAO BENEFICIADO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao: "BOVINOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "PRODUTOS AGRICOLAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao:
      "MATERIAS PRIMAS AGRICOLAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao: "CAFE EM COCO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4611700",
    descricao: "EQUINO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "46125",
    descricao:
      "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE COMBUSTIVEIS, MINERAIS, PRODUTOS SIDERURGICOS E QUIMICOS",
  },
  {
    cnae: "4612500",
    descricao:
      "PRODUTOS QUIMICOS PARA HIGIENE DOMESTICA; INTERMEDIARIO DO COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4612500",
    descricao: "SAL MARINHO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "MINERAIS NAO METALICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "PRODUTOS SIDERURGICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "PRODUTOS QUIMICOS EM GERAL; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "SAL INDUSTRIAL; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "ACO CARBONO BRUTO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao: "ANILINA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao: "COMBUSTIVEIS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "PRODUTOS SIDERURGICOS E METALURGICOS, EXCETO PARA CONSTRUCAO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "MINERAIS METALICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "CORRETIVOS DO SOLO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao: "LUBRIFICANTES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "GAS LIQUEFEITO DE PETROLEO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao: "COLA QUIMICA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "RESINA SINTETICA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao: "SODA CAUSTICA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao: "PETROQUIMICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "INSUMOS AGRICOLAS INDUSTRIAIS (FERTILIZANTES, ADUBOS, AGROTOXICOS, BACTERICIDAS E SIMILARES); REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao: "PARAFINA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "PRODUTOS QUIMICOS DE USO NA AGROPECUARIA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "METAIS EM FORMA PRIMARIA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "PRODUTOS QUIMICOS PARA LIMPEZA E CONSERVACAO DE PREDIOS E DOMICILIOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao: "FERTILIZANTES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao: "SAL GEMA; REPRESENTANTE COMERCIAL E AGENTE DE COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "DERIVADOS DO REFINO DO PETROLEO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "DEFENSIVOS AGRICOLAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao: "COLORANTES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4612500",
    descricao:
      "PRODUTOS QUIMICOS INDUSTRIAIS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "46133",
    descricao:
      "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MADEIRA, MATERIAL DE CONSTRUCAO E FERRAGENS",
  },
  {
    cnae: "4613300",
    descricao:
      "MATERIAL DE PINTURA (TINTURA, SOLVENTE, ETC.), REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO",
  },
  {
    cnae: "4613300",
    descricao:
      "PRODUTOS SIDERURGICOS E METALURGICOS PARA CONSTRUCAO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao:
      "CAIXA DE DESCARGA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao:
      "MATERIAL DE REVESTIMENTO (AZULEJOS, PISOS, MOSAICOS E SIMILARES); REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO",
  },
  {
    cnae: "4613300",
    descricao:
      "BOMBAS HIDRAULICAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao: "VIDROS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao:
      "TINERES PARA TINTAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao:
      "LOUCAS SANITARIAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao: "CAIXA DE AGUA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao:
      "DIVISORIAS SANFONADAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao:
      "ARTIGOS E EQUIPAMENTOS PARA SAUNAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao: "FERRAGENS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao: "MADEIRA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao: "FERRAMENTAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao:
      "PORTAS SANFONADAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao: "LAMPADAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao:
      "MATERIAL ELETRICO PARA CONSTRUCAO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao: "PARAFUSOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao:
      "MATERIAL DE CONSTRUCAO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao:
      "PRODUTOS SANITARIOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao:
      "PRODUTOS METALURGICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao:
      "TINTAS, ESMALTES, VERNIZES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao:
      "APARELHOS SANITARIOS (PIAS, LAVATORIOS, BANHEIRAS); REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO",
  },
  {
    cnae: "4613300",
    descricao:
      "MATERIAIS ELETRICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4613300",
    descricao:
      "MATERIAL ELETRICO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "46141",
    descricao:
      "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MAQUINAS, EQUIPAMENTOS, EMBARCACOES E AERONAVES",
  },
  {
    cnae: "4614100",
    descricao:
      "EQUIPAMENTOS PARA INFORMATICA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "EQUIPAMENTOS PARA USOS TECNICO OU PROFISSIONAL; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO",
  },
  {
    cnae: "4614100",
    descricao:
      "MAQUINAS PARA INSTALACOES HIDRAULICAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "CORTADORES DE FRIOS PARA BARES E LANCHONETES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "MAQUINAS E EQUIPAMENTOS AGRICOLAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "APARELHOS ELETRONICOS PARA INDUSTRIA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "FRESADORES (HORIZONTAIS, VERTICAIS, UNIVERSAIS); REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "PECAS PARA MAQUINAS INDUSTRIAIS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "EXTINTORES DE INCENDIO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "ESMERILHADEIRAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "MOBILIARIO PARA USO MEDICO-HOSPITALAR; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "MAQUINAS PARA TRANSPORTE DE CARGAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO",
  },
  {
    cnae: "4614100",
    descricao:
      "PECAS E ACESSORIOS PARA EMBARCACOES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "MAQUINAS PARA USOS TECNICO OU PROFISSIONAL; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "MOBILIARIO ODONTOLOGICO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "AIVECA PARA ARADOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "MAQUINAS PARA PAVIMENTACAO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO",
  },
  {
    cnae: "4614100",
    descricao:
      "PLACAS DE GERACAO DE ENERGIA ELETRICA; REPRESENTANTE COMERCIAL E AGENTE DE COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA AUTOMACAO GERENCIAL E COMERCIAL; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "MAQUINAS PARA ELEVACAO DE CARGAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "EQUIPAMENTOS PARA MONITORAMENTO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "TRATORES DE RODA OU ESTEIRA DE USO NA CONSTRUCAO OU MINERACAO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "ANCINHOS MECANICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "MAQUINAS E EQUIPAMENTOS INDUSTRIAIS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao: "EMBARCACOES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao: "TELEFONES; REPRESENTANTE COMERCIAL E AGENTE DE COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "MAQUINAS OPERATRIZES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO",
  },
  {
    cnae: "4614100",
    descricao:
      "TOSQUIADORES DE LA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "PECAS PARA MAQUINAS AGRICOLAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao: "AERONAVES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "SISTEMA DE SEGURANCA PARA USO INDUSTRIAL; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "FRITADORAS, REFRESQUEIRAS, SANDUICHEIRA E CAFETEIRAS PARA BARES E LANCHONETES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao: "PLANTADEIRAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "LAGARTAS PARA TRATORES DE USO NA CONSTRUCAO E MINERACAO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "APARELHOS ELETRONICOS DE USO PROFISSIONAL; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "TRILHADEIRAS AGRICOLAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "MOTORES MARITIMOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "BARBEIROS OU CABELEIREIROS, MAQUINAS DE; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO",
  },
  {
    cnae: "4614100",
    descricao:
      "CAMERAS DE SEGURANCA PARA USO INDUSTRIAL; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "TRATORES AGRICOLAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "EQUIPAMENTOS DE SEGURANCA PARA USO INDUSTRIAL; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "MAQUINAS E IMPLEMENTOS AGRICOLAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "EQUIPAMENTOS MEDICO-CIRURGICOS DIVERSOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "DEBULHADORES AGRICOLAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "PECAS E ACESSORIOS PARA MOTORES MARITIMOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "MAQUINAS PARA APLICACAO DE CODIGO DE BARRAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA ESCRITORIO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4614100",
    descricao:
      "MAQUINAS FERRAMENTA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO",
  },
  {
    cnae: "4614100",
    descricao:
      "CLASSIFICADORES PARA OVOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "46150",
    descricao:
      "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE ELETRODOMESTICOS, MOVEIS E ARTIGOS DE USO DOMESTICO",
  },
  {
    cnae: "4615000",
    descricao:
      "ELETRODOMESTICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "EQUIPAMENTOS DE MONITORACAO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "ARTIGOS DE CUTELARIA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "APARELHOS ELETRONICOS DOMESTICOS E PESSOAIS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "FILMADORAS, MAQUINAS FOTOGRAFICAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "EQUIPAMENTOS DE SEGURANCA PARA USO DOMESTICO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "CAMERAS DE SEGURANCA PARA USO DOMESTICO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "ARTIGOS DE ILUMINACAO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "MATERIAL DE LIMPEZA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "LUSTRES, LUMINARIAS, ABAJURES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618402",
    descricao: "PROTESES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "SISTEMA DE SEGURANCA PARA USO DOMESTICO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "PANELAS, UTENSILIOS DE COZINHA DOMESTICO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "ARTIGOS DE USO DOMESTICO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "MATERIAIS, ARTIGOS FOTOGRAFICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "ALARMES - USO DOMESTICO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao: "MOVEIS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "ARTIGOS PARA RESIDENCIAS DE LOUCA, VIDRO, METAL, MADEIRA, PLASTICO, BORRACHA E DE OUTROS MATERIAIS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "UTENSILIOS DOMESTICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao: "LOUCAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4615000",
    descricao:
      "ARTIGOS DE LOUCA, VIDRO, METAL, MADEIRA, PLASTICO, BORRACHA E DE OUTROS MATERIAIS - PARA USO DOMESTICO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "46168",
    descricao:
      "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE TEXTEIS, VESTUARIO, CALCADOS E ARTIGOS DE VIAGEM",
  },
  {
    cnae: "4616800",
    descricao:
      "ROUPAS MASCULINAS, FEMININAS, INFANTIS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4616800",
    descricao:
      "ARTIGOS DE VIAGEM; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4616800",
    descricao:
      "ACESSORIOS DO VESTUARIO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4616800",
    descricao:
      "UNIFORMES MEDICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4616800",
    descricao: "CALCADOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4616800",
    descricao: "COURO CURTIDO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4616800",
    descricao:
      "INJETADOS, PARTES PARA CALCADOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4616800",
    descricao:
      "ARTIGOS DE CAMA, MESA E BANHO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4616800",
    descricao:
      "ARTIGOS DO VESTUARIO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4616800",
    descricao: "CONFECCOES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4616800",
    descricao:
      "ARTIGOS TEXTEIS EM GERAL; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4616800",
    descricao:
      "ROUPAS PARA SEGURANCA PESSOAL; REPRESENTANTE COMERCIAL E AGENTES DO COMERCIO DE",
  },
  {
    cnae: "4616800",
    descricao:
      "UNIFORMES ESCOLARES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4616800",
    descricao:
      "ARTIGOS DE COURO PARA VIAGEM E VESTUARIO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4616800",
    descricao:
      "ARTIGOS DE ARMARINHO, AVIAMENTOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4616800",
    descricao:
      "COMPLEMENTOS DO VESTUARIO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4616800",
    descricao: "TECIDOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "46176",
    descricao:
      "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE PRODUTOS ALIMENTICIOS, BEBIDAS E FUMO",
  },
  {
    cnae: "4617600",
    descricao:
      "PRODUTOS DO FUMO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao:
      "BEBIDAS ALCOOLICAS E NAO ALCOOLICAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao:
      "CACHACA OU CANINHA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao:
      "PEIXES, CRUSTACEOS E MOLUSCOS, FRIGORIFICADO OU CONGELADO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao: "CHOCOLATE; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao: "OVOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao:
      "CAFE TORRADO, MOIDO E SOLUVEL; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao: "LEITE; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao:
      "PRODUTOS DE SALSICHARIA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao: "AVES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao:
      "GENEROS ALIMENTICIOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao: "LATICINIOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao:
      "CEREAIS BENEFICIADOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao: "SAQUE; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao:
      "LICORES OU CREME; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao:
      "PRODUTOS ALIMENTICIOS EMBUTIDOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao:
      "SECOS E MOLHADOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao: "GELO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao:
      "PRODUTOS FRIGORIFICADOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao: "CONHAQUE; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao:
      "PRODUTOS ALIMENTICIOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao:
      "FUMO BENEFICIADO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao:
      "UISQUE, WHISKY; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao:
      "HORTIGRANJEIROS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4617600",
    descricao:
      "HORTIFRUTIGRANJEIROS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "46184",
    descricao:
      "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO ESPECIALIZADO EM PRODUTOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "4618401",
    descricao: "MEDICAMENTOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCO DE",
  },
  {
    cnae: "4618401",
    descricao:
      "PRODUTOS FARMACEUTICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618401",
    descricao:
      "PRODUTOS DE PERFUMARIA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618401",
    descricao: "COSMETICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618401",
    descricao:
      "BASES PARA UNHA E ROSTO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618401",
    descricao:
      "ARTIGOS, PRODUTOS DE TOUCADOR; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618401",
    descricao:
      "PRODUTOS VETERINARIOS (INCLUSIVE MEDICAMENTOS); REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618401",
    descricao:
      "BRONZEADORES E ACELERADORES DE BRONZEAMENTO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618401",
    descricao:
      "FLORA MEDICINAL, PRODUTOS DE; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618401",
    descricao: "ESMALTES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618402",
    descricao:
      "APARELHOS NAO ELETRICOS PARA ODONTOLOGIA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618402",
    descricao:
      "MATERIAL HOSPITALAR; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618402",
    descricao:
      "DENTES ARTIFICIAIS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618402",
    descricao:
      "ARTIGOS DE ORTOPEDIA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618402",
    descricao:
      "PRODUTOS MEDICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618402",
    descricao:
      "MATERIAL MEDICO CIRURGICO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618402",
    descricao:
      "PRODUTOS ODONTOLOGICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618402",
    descricao:
      "PINOS DE TITANIO E OUTROS MATERIAIS PARA ORTOPEDIA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618402",
    descricao:
      "BOTICOES PARA EXTRACAO DENTARIA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618402",
    descricao:
      "CERAS, DENTES E COMPOSTOS PARA RESTAURACAO DENTARIA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618402",
    descricao:
      "PLACAS DE TITANIO E OUTROS MATERIAIS PARA ORTOPEDIA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618402",
    descricao:
      "GEL PARA MOLDES DE DENTADURAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618402",
    descricao:
      "PRODUTOS HOSPITALARES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618403",
    descricao:
      "JORNAIS, REVISTAS E OUTRAS PUBLICACOES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618403",
    descricao: "LIVROS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618403",
    descricao:
      "INTERMEDIACAO NA VENDA DE ASSINATURAS DE JORNAIS, REVISTAS E OUTRAS PUBLICACOES; SERVICOS DE",
  },
  {
    cnae: "4618403",
    descricao:
      "DISTRIBUIDORES DE JORNAIS, REVISTAS E OUTRAS PUBLICACOES PARA O COMERCIO, SOB CONTRATO DE COMISSAO",
  },
  {
    cnae: "4618499",
    descricao:
      "FIOS E FIBRAS TEXTEIS BENEFICIADAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "PURIFICADORES DE AGUA DOMESTICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "PECAS PARA BICICLETAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao: "RACOES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "SUPRIMENTOS PARA NAVIOS REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "EMBALAGENS DE QUALQUER MATERIAL; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "MATERIAL ESCOLAR; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "MOLINETES E LINHAS PARA VARA DE PESCAR; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "LUVAS DE BOXE, RAQUETES, REDES ESPORTIVAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao: "RELOGIOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "MATERIAL ELETROFORETICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao: "ARTIGOS DE COMISSARIA; INTERMEDIARIO DO COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4618499",
    descricao:
      "PAPEL DE PAREDE E SIMILARES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "ARTIGOS DE RELOJOARIA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao: "AQUARIOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao: "PERSIANAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "EQUIPAMENTOS PARA CACA SUBMARINA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "ALBUNS FOTOGRAFICOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao: "BRINQUEDOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "BICICLETAS, TRICICLOS E OUTROS VEICULOS RECREATIVOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "ARTIGOS PARA AQUARIO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "SAL MINERAL E OUTROS ALIMENTOS PARA ANIMAIS; REPRESENTANTE COMERCIAL DE",
  },
  {
    cnae: "4618499",
    descricao:
      "ARTIGOS ESPORTIVOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "JOIAS, SEMIJOIAS, BIJUTERIAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "ARTIGOS DE ESCRITORIO E DE PAPELARIA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "CARPETES E TAPETES; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "ARTIGOS VETERINARIOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "PAPEL E PAPELAO; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "FLORES E PLANTAS NATURAIS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "ESTEIRA ERGOMETRICA DOMESTICA; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao: "TOLDOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4618499",
    descricao:
      "BOLAS DE FUTEBOL, JOELHEIRAS, TORNOZELEIRAS, CANELEIRAS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "46192",
    descricao:
      "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MERCADORIAS EM GERAL NAO ESPECIALIZADO",
  },
  {
    cnae: "4619200",
    descricao:
      "REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE MERCADORIAS SEM PREDOMINANCIA",
  },
  {
    cnae: "4619200",
    descricao:
      "PRODUTOS DIVERSOS; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  {
    cnae: "4619200",
    descricao:
      "REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO ATACADISTA NAO ESPECIALIZADO",
  },
  {
    cnae: "4619200",
    descricao:
      "COMERCIO EM GERAL; REPRESENTANTE COMERCIAL E AGENTE DO COMERCIO DE",
  },
  { cnae: "46214", descricao: "COMERCIO ATACADISTA DE CAFE EM GRAO" },
  { cnae: "4621400", descricao: "CAFE IN NATURA; COMERCIO ATACADISTA DE" },
  { cnae: "4621400", descricao: "CAFE EM COCO; COMERCIO ATACADISTA DE" },
  { cnae: "4621400", descricao: "CAFE EM GRAO; COMERCIO ATACADISTA DE" },
  { cnae: "4621400", descricao: "CAFE VERDE; COMERCIO ATACADISTA DE" },
  { cnae: "46222", descricao: "COMERCIO ATACADISTA DE SOJA" },
  {
    cnae: "4622200",
    descricao: "SOJA NAO BENEFICIADA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46231",
    descricao:
      "COMERCIO ATACADISTA DE ANIMAIS VIVOS, ALIMENTOS PARA ANIMAIS E MATERIASPRIMAS AGRICOLAS, EXCETO CAFE E SOJA",
  },
  { cnae: "4623101", descricao: "PORCO; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "OVELHAS; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "SEMEN EQUINO; COMERCIO ATACADISTA" },
  { cnae: "4623101", descricao: "BODE; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "RESES; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "ANIMAIS VIVOS; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "SUINOS (PORCOS); COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "SEMEN BOVINO; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "TOURO; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "MULA; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "BOVINOS; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "BURRO, BURRINHO; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "EQUINOS; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "CAVALO; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "CAO, CACHORRO; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "JUMENTO; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "CABRA; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "CARNEIRO; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "BOI; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "BUFALOS; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "CAPRINOS; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "GATO; COMERCIO ATACADISTA DE" },
  { cnae: "4623101", descricao: "OVINOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4623102",
    descricao: "CALCULO BOVINO (PEDRA FEL); COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4623102",
    descricao: "COURO VERDE (FRESCO); COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4623102",
    descricao:
      "COUROS E PELES, CHIFRES, OSSOS, CASCOS, CRINAS, LA, PELOS E CERDAS EM BRUTO, PENAS E PLUMAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4623103",
    descricao: "ALGODAO EM CAROCO OU EM PLUMA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4623103", descricao: "ALGODAO EM PLUMA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4623104",
    descricao: "FUMO EM FOLHA NAO BENEFICIADO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4623105", descricao: "CACAU EM BAGAS; COMERCIO ATACADISTA DE" },
  { cnae: "4623105", descricao: "CACAU EM AMENDOAS; COMERCIO ATACADISTA DE" },
  { cnae: "4623106", descricao: "MUDAS DE PLANTAS; COMERCIO ATACADISTA DE" },
  { cnae: "4623106", descricao: "MUDAS DE PINUS; COMERCIO ATACADISTA DE" },
  { cnae: "4623106", descricao: "VIME, XAXIM; COMERCIO ATACADISTA DE" },
  { cnae: "4623106", descricao: "SEMENTES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4623106",
    descricao: "FLORES, PLANTAS, GRAMA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4623106", descricao: "TERRA PARA JARDIM; COMERCIO ATACADISTA DE" },
  {
    cnae: "4623106",
    descricao: "ARTIGOS, PRODUTOS DE FLORICULTURA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4623106",
    descricao: "SEMENTES CERTIFICADAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4623106", descricao: "FLORES NATURAIS; COMERCIO ATACADISTA DE" },
  { cnae: "4623107", descricao: "SISAL; COMERCIO ATACADISTA DE" },
  {
    cnae: "4623108",
    descricao:
      "GERGELIM EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "PRODUTOS AGRICOLAS EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "COLZA EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "SORGO EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "GIRASSOL EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "LEGUMINOSAS EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "CENTEIO EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "CEVADA EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "CEREAIS EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "TRITICALE EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "PAINCO EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "ERVILHA EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "LINHO EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "AMENDOIM EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "ALPISTE EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "TRIGO EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "MAMONA EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "ARROZ EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "FAVA EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "COMERCIO ATACADISTA DE MATERIAS-PRIMAS AGRICOLAS COM ATIVIDADE DE FRACIONAMENTO E ACONDICIONAMENTO ASSOCIADA",
  },
  { cnae: "4633802", descricao: "PATOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4623108",
    descricao:
      "MILHO EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "AVEIA EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "FEIJAO EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623108",
    descricao:
      "SOJA EM BRUTO; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO DE",
  },
  {
    cnae: "4623109",
    descricao: "RACOES PARA ANIMAIS DOMESTICOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4623109",
    descricao:
      "RACAO E OUTROS PRODUTOS ALIMENTICIOS PARA ANIMAIS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4623109", descricao: "SILAGEM, FENO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4623109",
    descricao: "ALIMENTOS PARA ANIMAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4623109",
    descricao:
      "SAL MINERAL E OUTROS ALIMENTOS PARA ANIMAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4623109",
    descricao: "RACAO PARA ANIMAIS DOMESTICOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4623109", descricao: "RACOES PARA ANIMAIS; COMERCIO ATACADISTA DE" },
  { cnae: "4623199", descricao: "ARROZ EM CASCA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4623199",
    descricao: "VIME (MATERIA-PRIMA); COMERCIO ATACADISTA DE",
  },
  { cnae: "4623199", descricao: "CENTEIO EM BRUTO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4623199",
    descricao: "ERVAS MEDICINAIS EM BRUTO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4623199", descricao: "FORRAGENS; COMERCIO ATACADISTA DE" },
  { cnae: "4623199", descricao: "TRIGO EM BRUTO; COMERCIO ATACADISTA DE" },
  { cnae: "4623199", descricao: "RAMI; COMERCIO ATACADISTA DE" },
  {
    cnae: "4623199",
    descricao: "LEGUMINOSAS EM BRUTO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4623199",
    descricao: "ESTERCO DE ANIMAIS NAO TRATADO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4623199", descricao: "MILHO EM BRUTO; COMERCIO ATACISTA DE" },
  { cnae: "4623199", descricao: "FEIJAO EM BRUTO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4623199",
    descricao: "FIBRAS TEXTEIS NAO BENEFICIADAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4623199", descricao: "JUTA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4623199",
    descricao: "MILHO EM GRAO NAO BENEFICIADO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4623199",
    descricao: "CERA DE CARNAUBA, IN NATURA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4623199",
    descricao:
      "MELACO (COMPLEMENTO ALIMENTAR PARA ANIMAIS); COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4623199",
    descricao: "LATEX NATURAL E BORRACHA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4623199",
    descricao:
      "MATERIAS PRIMAS AGRICOLAS DIVERSAS NAO BENEFICIADAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4623199", descricao: "CEREAIS EM BRUTO; COMERCIO ATACADISTA DE" },
  { cnae: "4623199", descricao: "AMENDOIM EM BRUTO; COMERCIO ATACADISTA DE" },
  { cnae: "4623199", descricao: "AVEIA EM BRUTO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4623199",
    descricao: "CASULOS DE BICHO DA SEDA; COMERCIO ATACADISTA DE",
  },
  { cnae: "46311", descricao: "COMERCIO ATACADISTA DE LEITE E LATICINIOS" },
  { cnae: "4631100", descricao: "QUEIJO; COMERCIO ATACADISTA DE" },
  { cnae: "4631100", descricao: "REQUEIJAO; COMERCIO ATACADISTA DE" },
  { cnae: "4631100", descricao: "LEITE CRU; COMERCIO ATACADISTA DE" },
  { cnae: "4631100", descricao: "MARGARINAS; COMERCIO ATACADISTA DE" },
  { cnae: "4631100", descricao: "LEITE E LATICINIOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4631100",
    descricao: "LEITE E PRODUTOS DO LEITE; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4631100",
    descricao:
      "LEITE RESFRIADO, PASTEURIZADO, AROMATIZADO E EM PO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4631100", descricao: "LATICINIOS; COMERCIO ATACADISTA DE" },
  { cnae: "4631100", descricao: "IOGURTE; COMERCIO ATACADISTA DE" },
  { cnae: "4631100", descricao: "MANTEIGA; COMERCIO ATACADISTA DE" },
  {
    cnae: "46320",
    descricao:
      "COMERCIO ATACADISTA DE CEREAIS E LEGUMINOSAS BENEFICIADOS, FARINHAS, AMIDOS E FECULAS",
  },
  { cnae: "4632001", descricao: "CENTEIO BENEFICIADO; COMERCIO ATACADISTA DE" },
  { cnae: "4632001", descricao: "SORGO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4632001",
    descricao: "AMENDOIM BENEFICIADO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4632001", descricao: "MILHO BENEFICIADO; COMERCIO ATACADISTA DE" },
  { cnae: "4632001", descricao: "SOJA BENEFICIADA; COMERCIO ATACADISTA DE" },
  { cnae: "4632001", descricao: "FARINHA DE TRIGO ; COMERCIO ATACADISTA DE" },
  { cnae: "4632001", descricao: "FEIJAO BENEFICIADO; COMERCIO ATACADISTA DE" },
  { cnae: "4632001", descricao: "TRIGO BENEFICIADO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4632001",
    descricao: "CEREAIS BENEFICIADOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4632001",
    descricao: "LEGUMINOSAS BENEFICIADAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4632001", descricao: "CEVADA BENEFICIADA; COMERCIO ATACADISTA DE" },
  { cnae: "4632001", descricao: "ARROZ BENEFICIADO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4632002",
    descricao: "FARINHAS, AMIDOS, FECULAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4632002", descricao: "POLVILHO; COMERCIO ATACADISTA DE" },
  { cnae: "4632002", descricao: "GLUTEN; COMERCIO ATACADISTA DE" },
  {
    cnae: "4632003",
    descricao:
      "EMPACOTAMENTO DE CEREAIS BENEFICIADOS POR CONTA PROPRIA; SERVICOS DE",
  },
  {
    cnae: "4632003",
    descricao:
      "SOJA BENEFICIADA; COMERCIO ATACADISTA DE, COM ACONDICIONAMENTO ASSOCIADO",
  },
  {
    cnae: "4632003",
    descricao:
      "ERVILHA BENEFICIADA; COMERCIO ATACADISTA DE, COM ACONDICIONAMENTO ASSOCIADO",
  },
  {
    cnae: "4632003",
    descricao:
      "AMENDOIM BENEFICIADO; COMERCIO ATACADISTA DE, COM ACONDICIONAMENTO ASSOCIADO",
  },
  {
    cnae: "4632003",
    descricao:
      "ARROZ BENEFICIADO; COMERCIO ATACADISTA DE, COM ACONDICIONAMENTO ASSOCIADO",
  },
  {
    cnae: "4632003",
    descricao:
      "TRIGO BENEFICIADO; COMERCIO ATACADISTA DE, COM ACONDICIONAMENTO ASSOCIADO",
  },
  {
    cnae: "4632003",
    descricao:
      "MILHO BENEFICIADO; COMERCIO ATACADISTA DE, COM ACONDICIONAMENTO ASSOCIADO",
  },
  {
    cnae: "4632003",
    descricao:
      "FEIJAO BENEFICIADO; COMERCIO ATACADISTA DE, COM ACONDICIONAMENTO ASSOCIADO",
  },
  {
    cnae: "4632003",
    descricao:
      "COMERCIO ATACADISTA DE CEREAIS E LEGUMINOSAS BENEFICIADOS, FARINHAS, AMIDOS E FECULAS, COM ATIVIDADE DE FRACIONAMENTO E ACONDICIONAMENTO ASSOCIADO",
  },
  {
    cnae: "4632003",
    descricao:
      "CEREAIS BENEFICIADOS; COMERCIO ATACADISTA DE, COM ACONDICIONAMENTO ASSOCIADO",
  },
  {
    cnae: "4632003",
    descricao:
      "FARINHA BENEFICIADA; COMERCIO ATACADISTA DE, COM ACONDICIONAMENTO ASSOCIADO",
  },
  {
    cnae: "4632003",
    descricao:
      "AMIDOS E FECULAS; COMERCIO ATACADISTA DE, COM ACONDICIONAMENTO ASSOCIADO",
  },
  {
    cnae: "4632003",
    descricao:
      "LEGUMINOSAS BENEFICIADO; COMERCIO ATACADISTA DE, COM ACONDICIONAMENTO ASSOCIADO",
  },
  { cnae: "46338", descricao: "COMERCIO ATACADISTA DE HORTIFRUTIGRANJEIROS" },
  { cnae: "4633801", descricao: "LARANJA; COMERCIO ATACADISTA DE" },
  { cnae: "4633801", descricao: "FRUTAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4633801",
    descricao:
      "FRUTAS, VERDURAS, RAIZES, TUBERCULOS, HORTALICAS E LEGUMES FRESCOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4633801", descricao: "COCO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4633801",
    descricao: "VERDURAS, HORTALICAS, LEGUMES FRESCOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4633801", descricao: "BANANA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4633801",
    descricao: "HORTIFRUTIGRANJEIROS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4633801", descricao: "HORTIGRANJEIROS; COMERCIO ATACADISTA DE" },
  { cnae: "4633801", descricao: "CASCAS DE FRUTAS; COMERCIO ATACADISTA DE" },
  { cnae: "4633801", descricao: "ALHO (HORTICOLA); COMERCIO ATACADISTA DE" },
  { cnae: "4633801", descricao: "RAIZES, TUBERCULOS; COMERCIO ATACADISTA DE" },
  { cnae: "4633802", descricao: "OVOS; COMERCIO ATACADISTA DE" },
  { cnae: "4633802", descricao: "PERUS; COMERCIO ATACADISTA DE" },
  { cnae: "4633802", descricao: "GALINHAS; COMERCIO ATACADISTA DE" },
  { cnae: "4633803", descricao: "COELHOS (LEBRES); COMERCIO ATACADISTA DE" },
  {
    cnae: "46346",
    descricao: "COMERCIO ATACADISTA DE CARNES, PRODUTOS DA CARNE E PESCADO",
  },
  {
    cnae: "4634601",
    descricao: "CHARQUES SECA OU SALGADA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4634601", descricao: "CARNE SUINA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4634601",
    descricao: "CARNE BOVINA SECA OU SALGADA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4634601",
    descricao: "CARNE SUINA SECA OU SALGADA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4634601",
    descricao: "PRODUTOS DE SALSICHARIA, EMBUTIDOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4634601",
    descricao: "PRODUTOS DA CARNE BOVINA E SUINA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4634601",
    descricao:
      "CARNES BOVINAS FRESCA, FRIGORIFICADAS OU CONGELADAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4634601",
    descricao:
      "CARNES SUINAS FRESCAS, FRIGORIFICADAS OU CONGELADAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4634601", descricao: "CARNE ENLATADA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4634601",
    descricao: "PRODUTOS DE SALSICHARIA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4634601", descricao: "PRODUTOS DA CARNE; COMERCIO ATACADISTA DE" },
  {
    cnae: "4634602",
    descricao: "FRANGOS, GALINHAS ABATIDAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4634602",
    descricao: "MIUDOS DE AVES, VISCERAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4634602",
    descricao:
      "AVES ABATIDAS FRESCAS, FRIGORIFICADAS OU CONGELADAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4634603",
    descricao: "PESCADO PREPARADO, ENLATADO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4634603",
    descricao: "PESCADOS E FRUTOS DO MAR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4634603",
    descricao:
      "PEIXES, CRUSTACEOS E MOLUSCOS, CONGELADOS OU FRIGORIFICADOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4634603",
    descricao:
      "PESCADOS FRESCOS, CONGELADOS OU FRIGORIFICADOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4634603", descricao: "PEIXES FRESCOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4634603",
    descricao:
      "CAMARAO FRESCO, FRIGORIFICADO E CONGELADO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4634699",
    descricao:
      "CARNE FRESCA, FRIGORIFICADA OU CONGELADA DE CAPRINOS, OVINOS E EQUIDEOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4634699",
    descricao:
      "MIUDOS E VISCERAS DE CAPRINOS, OVINOS E EQUIDEOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "46354", descricao: "COMERCIO ATACADISTA DE BEBIDAS" },
  { cnae: "4635401", descricao: "AGUA MINERAL; COMERCIO ATACADISTA DE" },
  {
    cnae: "4635401",
    descricao: "DISTRIBUIDORA DE AGUA MINERAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4635402",
    descricao: "DISTRIBUIDORA DE REFRIGERANTES; COMERCIO ATACADISTA DE",
  },
  { cnae: "4635402", descricao: "CHOPP; COMERCIO ATACADISTA DE" },
  {
    cnae: "4635402",
    descricao: "CERVEJA, CHOPE E REFRIGERANTE; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4635402",
    descricao: "DISTRIBUIDORA DE CERVEJA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4635402",
    descricao: "DISTRIBUIDORA DE CHOPE; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4635402",
    descricao: "CERVEJA, REFRIGERANTES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4635403",
    descricao:
      "FRACIONAMENTO E ACONDICIONAMENTO DE BEBIDAS ALCOOLICAS E NAO ALCOOLICAS ASSOCIADO AO COMERCIO; SERVICOS DE",
  },
  {
    cnae: "4635403",
    descricao:
      "BEBIDAS EM GERAL; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO",
  },
  { cnae: "4635499", descricao: "CACHACA CANINHA; COMERCIO ATACADISTA DE" },
  { cnae: "4635499", descricao: "SAQUE  COMERCIO ATACADISTA DE" },
  { cnae: "4635499", descricao: "LICORES OU CREMES; COMERCIO ATACADISTA DE" },
  { cnae: "4635499", descricao: "BEBIDAS DESTILADAS; COMERCIO ATACADISTA DE" },
  { cnae: "4635499", descricao: "CONHAQUE; COMERCIO ATACADISTA DE" },
  { cnae: "4635499", descricao: "AGUARDENTES; COMERCIO ATACADISTA DE" },
  { cnae: "4635499", descricao: "VINHOS; COMERCIO ATACADISTA DE" },
  { cnae: "4635499", descricao: "UISQUE, WHISKY; COMERCIO ATACADISTA DE" },
  { cnae: "4635499", descricao: "RUM; COMERCIO ATACADISTA DE" },
  { cnae: "46362", descricao: "COMERCIO ATACADISTA DE PRODUTOS DO FUMO" },
  { cnae: "4636201", descricao: "FUMO BENEFICIADO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4636202",
    descricao: "CIGARRO, CIGARRILHA, CHARUTO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4636202", descricao: "PRODUTOS DO FUMO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4636202",
    descricao: "ARTIGOS DE TABACARIA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4636202", descricao: "TABACO; COMERCIO ATACADISTA DE" },
  {
    cnae: "46371",
    descricao:
      "COMERCIO ATACADISTA ESPECIALIZADO EM PRODUTOS ALIMENTICIOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "4637101",
    descricao: "CAFE TORRADO, MOIDO, SOLUVEL; COMERCIO ATACADISTA DE",
  },
  { cnae: "4637102", descricao: "ACUCAR; COMERCIO ATACADISTA DE" },
  {
    cnae: "4637103",
    descricao: "OLEOS REFINADOS E GORDURAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4637103",
    descricao: "OLEO DE SOJA EM BRUTO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4637103",
    descricao: "OLEOS REFINADOS COMESTIVEIS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4637103", descricao: "AZEITE REFINADO; COMERCIO ATACADISTA DE" },
  { cnae: "4637104", descricao: "BOLOS; COMERCIO ATACADISTA DE" },
  { cnae: "4637104", descricao: "PRODUTOS DE PADARIA; COMERCIO ATACADISTA DE" },
  { cnae: "4637104", descricao: "BISCOITOS; COMERCIO ATACADISTA DE" },
  { cnae: "4637104", descricao: "BOLACHAS; COMERCIO ATACADISTA DE" },
  { cnae: "4637104", descricao: "PAES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4637104",
    descricao: "PAES, BOLOS, BISCOITOS E SIMILARES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4637105",
    descricao: "MASSAS ALIMENTICIAS EM GERAL; COMERCIO ATACADISTA DE",
  },
  { cnae: "4637106", descricao: "SORVETES; COMERCIO ATACADISTA DE" },
  { cnae: "4637106", descricao: "PICOLES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4637106",
    descricao: "TORTAS GELADAS DE SORVETE; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4637107",
    descricao: "CHOCOLATES E BOMBONS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4637107",
    descricao: "BALAS, CONFEITOS E SEMELHANTES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4637199",
    descricao: "CONSERVAS DE FRUTAS E LEGUMES; COMERCIO ATACADISTA DE",
  },
  { cnae: "4637199", descricao: "FRUTAS SECAS; COMERCIO ATACADISTA DE" },
  { cnae: "4637199", descricao: "POLPA DE FRUTAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4637199",
    descricao:
      "COMPLEMENTOS E SUPLEMENTOS  ALIMENTICIOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4637199",
    descricao: "PRODUTOS ALIMENTICIOS NATURAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4637199",
    descricao: "FRUTAS SECAS E CRISTALIZADAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4637199", descricao: "PRODUTOS DIETETICOS; COMERCIO ATACADISTA DE" },
  { cnae: "4637199", descricao: "SOLUCOES ENTERAIS; COMERCIO ATACADISTA DE" },
  { cnae: "4637199", descricao: "BATATA FRITA EM PACOTE; COMERCIO ATACADISTA" },
  { cnae: "4637199", descricao: "CHA, MATE; COMERCIO ATACADISTA DE" },
  { cnae: "4637199", descricao: "VINAGRE; COMERCIO ATACADISTA DE" },
  {
    cnae: "4637199",
    descricao: "PRODUTOS ALIMENTICIOS INDUSTRIALIZADOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4637199", descricao: "SAL DE COZINHA; COMERCIO ATACADISTA DE" },
  { cnae: "4637199", descricao: "FERMENTOS; COMERCIO ATACADISTA DE" },
  { cnae: "4637199", descricao: "MEL; COMERCIO ATACADISTA DE" },
  {
    cnae: "4637199",
    descricao: "ALIMENTOS PREPARADOS EM FRITURAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4637199",
    descricao: "CONDIMENTOS, ESPECIARIAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4637199", descricao: "SUCO DE FRUTAS; COMERCIO ATACADISTA DE" },
  { cnae: "4637199", descricao: "ADOCANTES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4637199",
    descricao:
      "ALIMENTOS PRONTOS CONGELADOS PARA PREPARO EM MICROONDAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4637199",
    descricao: "OLEOS VEGETAIS EM BRUTO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4637199", descricao: "GELO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4637199",
    descricao: "ALIMENTOS PREPARADOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4637199", descricao: "ALHO EM CONSERVA; COMERCIO ATACADISTA DE" },
  { cnae: "4637199", descricao: "FRIOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4637199",
    descricao: "ERVA MATE BENEFICIADA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46397",
    descricao: "COMERCIO ATACADISTA DE PRODUTOS ALIMENTICIOS EM GERAL",
  },
  {
    cnae: "4639701",
    descricao: "PRODUTOS ALIMENTICIOS EM GERAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4639701",
    descricao: "CESTAS DE PRODUTOS ALIMENTARES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4639702",
    descricao:
      "PRODUTOS ALIMENTICIOS INDUSTRIALIZADOS; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO",
  },
  {
    cnae: "46419",
    descricao:
      "COMERCIO ATACADISTA DE TECIDOS, ARTEFATOS DE TECIDOS E DE ARMARINHO",
  },
  {
    cnae: "4641901",
    descricao: "TECIDOS DE MALHA PARA CONFECCAO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4641901", descricao: "TECIDOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4641902",
    descricao: "ARTIGOS DE CAMA, MESA E BANHO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4641902",
    descricao: "ROUPA DE CAMA, MESA E BANHO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4641902",
    descricao:
      "LENCOL, FRONHA, COBERTOR, COLCHA E SIMILARES, COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4641903",
    descricao:
      "AGULHA, ALFINETE, LINHA, BOTAO, ZIPER E OUTROS PRODUTOS DE ARMARINHO, COMERCIO ATACADISTA DE",
  },
  { cnae: "4641903", descricao: "FELTROS, ENTRETELAS; COMERCIO ATACADISTA DE" },
  { cnae: "4641903", descricao: "RENDAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4641903",
    descricao: "ARTIGOS DE PASSAMANARIA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4641903",
    descricao: "ARTIGOS DE ARMARINHO, AVIAMENTOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46427",
    descricao: "COMERCIO ATACADISTA DE ARTIGOS DO VESTUARIO E ACESSORIOS",
  },
  { cnae: "4642701", descricao: "CONFECCOES; COMERCIO ATACADISTA DE" },
  { cnae: "4642701", descricao: "VESTUARIO; COMERCIO ATACADISTA DE" },
  { cnae: "4642701", descricao: "ENXOVAL; COMERCIO ATACADISTA DE" },
  { cnae: "4642701", descricao: "MEIAS; COMERCIO ATACADISTA DE" },
  { cnae: "4642701", descricao: "ROUPAS DE COURO; COMERCIO ATACADISTA DE" },
  { cnae: "4642701", descricao: "VESTUARIO DE COURO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4642701",
    descricao:
      "BLUSA, CAMISA, VESTIDO, SAIA, CALCA, CAMISOLA, PIJAMA E OUTROS ARTIGOS DE VESTUARIO, COMERCIO ATACADISTA DE",
  },
  { cnae: "4642701", descricao: "GRAVATAS; COMERCIO ATACADISTA DE" },
  { cnae: "4642701", descricao: "LUVAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4642701",
    descricao: "COMPLEMENTOS DO VESTUARIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4642701",
    descricao: "MODA MASCULINA, FEMININA, INFANTIL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4642701",
    descricao: "VESTUARIO, ROUPAS PARA ANIMAIS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4642701", descricao: "LINGERIE; COMERCIO ATACADISTA DE" },
  { cnae: "4642701", descricao: "ROUPAS DESPORTIVAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4642701",
    descricao: "ARTIGOS DO VESTUARIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4642701",
    descricao: "GUARDA-CHUVA, SOMBRINHA, BENGALA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4642701",
    descricao: "ROUPAS MASCULINAS, FEMININAS, INFANTIS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4642701", descricao: "CINTOS; COMERCIO ATACADISTA DE" },
  { cnae: "4642701", descricao: "LENCOS; COMERCIO ATACADISTA DE" },
  { cnae: "4642701", descricao: "CHAPEUS; COMERCIO ATACADISTA DE" },
  { cnae: "4642701", descricao: "ROUPAS INTIMAS; COMERCIO ATACADISTA DE" },
  { cnae: "4642702", descricao: "UNIFORMES ESCOLARES; COMERCIO ATACADISTA DE" },
  { cnae: "4642702", descricao: "UNIFORMES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4642702",
    descricao: "CINTOS PARA SEGURANCA NO TRABALHO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4642702", descricao: "FARDAMENTO ESCOLAR; COMERCIO ATACADISTA DE" },
  {
    cnae: "4642702",
    descricao: "ROUPAS PARA SEGURANCA PESSOAL; COMERCIO ATACADISTA DE",
  },
  { cnae: "4642702", descricao: "OCULOS DE SEGURANCA; COMERCIO ATACADISTA DE" },
  { cnae: "4642702", descricao: "FARDAMENTO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4642702",
    descricao: "CAPACETES PARA SEGURANCA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4642702",
    descricao: "MASCARAS PARA SEGURANCA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4642702",
    descricao: "LUVAS PARA SEGURANCA NO TRABALHO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4642702",
    descricao:
      "EQUIPAMENTOS DE PROTECAO INDIVIDUAL (EPI); COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4642702",
    descricao:
      "ROUPAS E ACESSORIOS PARA USO PROFISSIONAL E DE SEGURANCA DO TRABALHO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4642702",
    descricao: "COLETES A PROVA DE BALAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4642702",
    descricao: "CAPACETES PARA SEGURANCA INDUSTRIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46435",
    descricao: "COMERCIO ATACADISTA DE CALCADOS E ARTIGOS DE VIAGEM",
  },
  {
    cnae: "4643501",
    descricao: "CALCADOS DESPORTIVOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4643501", descricao: "SANDALIAS; COMERCIO ATACADISTA DE" },
  { cnae: "4643501", descricao: "TENIS; COMERCIO ATACADISTA DE" },
  { cnae: "4643501", descricao: "SAPATOS; COMERCIO ATACADISTA DE" },
  { cnae: "4643501", descricao: "SAPATOS ESPORTIVOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4643501",
    descricao: "CALCADOS DE QUALQUER MATERIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4643502",
    descricao: "ARTIGOS DE COURO PARA VIAGEM; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4643502",
    descricao:
      "ARTIGOS PARA VIAGEM DE QUALQUER MATERIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4643502",
    descricao: "BOLSAS E SACOLAS DE TECIDOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4643502",
    descricao:
      "BOLSAS, MOCHILAS, MALAS, VALISES DE QUALQUER MATERIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46443",
    descricao:
      "COMERCIO ATACADISTA DE PRODUTOS FARMACEUTICOS PARA USO HUMANO E VETERINARIO",
  },
  {
    cnae: "4644301",
    descricao:
      "DISTRIBUIDORA DE REMEDIOS DE USO HUMANO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4644301",
    descricao: "AGUA FILTRADA (USO EM HEMODIALISE); COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4644301",
    descricao: "REMEDIOS PARA USO HUMANO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4644301", descricao: "SORO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4644301",
    descricao: "SOLUCOES PARENTERAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4644301",
    descricao: "PRODUTOS FARMACEUTICOS DE USO HUMANO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4644301",
    descricao:
      "DISTRIBUIDORA DE MEDICAMENTOS DE USO HUMANO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4644301",
    descricao: "MEDICAMENTOS PARA USO HUMANO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4644301",
    descricao: "VACINAS PARA USO HUMANO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4644301",
    descricao:
      "PRODUTOS FARMACEUTICOS DOSADOS E NAO-DOSADOS DE USO HUMANO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4644301",
    descricao:
      "FRACIONAMENTO E ENVASAMENTO DE PRODUTOS FARMACEUTICOS DE USO HUMANO PROPRIOS; SERVICOS DE",
  },
  {
    cnae: "4644301",
    descricao: "FLORA MEDICINAL, ARTIGOS DA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4644302",
    descricao:
      "DISTRIBUIDORA DE MEDICAMENTOS DE USO VETERINARIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4644302",
    descricao: "REMEDIOS PARA USO VETERINARIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4644302",
    descricao:
      "FRACIONAMENTO E ENVASAMENTO DE PRODUTOS FARMACEUTICOS DE USO VETERINARIO PROPRIOS; SERVICOS DE",
  },
  {
    cnae: "4644302",
    descricao: "MEDICAMENTOS PARA USO VETERINARIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4644302",
    descricao: "REMEDIOS PARA ANIMAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4644302",
    descricao: "VACINAS PARA USO VETERINARIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4644302",
    descricao: "DISTRIBUIDORA DE REMEDIOS PARA ANIMAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4644302",
    descricao: "MEDICAMENTOS PARA ANIMAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4644302",
    descricao:
      "PRODUTOS FARMACEUTICOS DE USO VETERINARIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46451",
    descricao:
      "COMERCIO ATACADISTA DE INSTRUMENTOS E MATERIAIS PARA USO MEDICO, CIRURGICO, ORTOPEDICO E ODONTOLOGICO",
  },
  {
    cnae: "4645101",
    descricao: "TUBOS DE ENSAIO (LABORATORIOS); COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4645101",
    descricao: "LUVAS E ATADURAS CIRURGICAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4645101",
    descricao: "KITS DIAGNOSTICO PARA EXAMES; COMERCIO ATACADISTA DE",
  },
  { cnae: "4645101", descricao: "NEBULIZADOR; COMERCIO ATACADISTA DE" },
  {
    cnae: "4645101",
    descricao: "MEDIDORES DE PRESSAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4645101",
    descricao: "INSTRUMENTOS ODONTOLOGICOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4645101", descricao: "MATERIAL HOSPITALAR; COMERCIO ATACADISTA DE" },
  { cnae: "4645101", descricao: "BISTURIS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4645101",
    descricao: "HEMOGLUCOTESTE, KIT DE; COMERCIO ATACADISTA DE",
  },
  { cnae: "4645101", descricao: "VAPORIZADOR; COMERCIO ATACADISTA DE" },
  { cnae: "4645101", descricao: "TERMOMETRO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4645101",
    descricao:
      "BISTURIS, BOTICOES, ESTETOSCOPIOS, PINCAS E OUTROS INSTRUMENTOS MEDICO-CIRURGICOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4645101",
    descricao: "INSTRUMENTOS MEDICO-CIRURGICOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4645101", descricao: "MATERIAL CIRURGICO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4645101",
    descricao:
      "INSTRUMENTOS E MATERIAIS PARA USO MEDICO, CIRURGICO, HOSPITALAR E DE LABORATORIOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4645101", descricao: "ESTETOSCOPIO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4645101",
    descricao:
      "INSTRUMENTOS E MATERIAIS MEDICO-CIRURGICOS-HOSPITALARES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4645101",
    descricao:
      "SERINGAS, AGULHAS PARA SUTURAS, PUNCOES OU INJECOES; COMERCIO ATACADISTA DE",
  },
  { cnae: "4645101", descricao: "VALVULAS BIOLOGICAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4645101",
    descricao: "KITS DIAGNOSTICO DE GRAVIDEZ; COMERCIO ATACADISTA DE",
  },
  { cnae: "4645101", descricao: "MATERIAL MEDICO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4645101",
    descricao: "INSTRUMENTOS CIRURGICOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4645101", descricao: "BOTICOES; COMERCIO ATACADISTA DE" },
  { cnae: "4645101", descricao: "MASSAGEADORES; COMERCIO ATACADISTA DE" },
  { cnae: "4645101", descricao: "MARCAPASSOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4645101",
    descricao: "KITS PARA EXAMES DE LABORATORIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4645102",
    descricao: "GESSO SINTETICO PARA USO HOSPITALAR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4645102",
    descricao: "APARELHOS AUDITIVOS (SURDEZ); COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4645102",
    descricao: "CALCADOS ORTOPEDICOS PRONTOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4645102",
    descricao:
      "PINOS DE TITANIO E OUTROS MATERIAIS PARA ORTOPEDIA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4645102",
    descricao:
      "PLACAS DE TITANIO E OUTROS MATERIAIS PARA ORTOPEDIA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4645102", descricao: "PROTESES; COMERCIO ATACADISTA DE" },
  { cnae: "4645102", descricao: "MULETAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4645102",
    descricao: "ARTIGOS DE ORTOPEDIA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4645102", descricao: "CADEIRA DE RODAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4645102",
    descricao: "PROTESES E ARTIGOS DE ORTOPEDIA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4645103",
    descricao:
      "CERAS DENTAIS E COMPOSTOS PARA RESTAURACAO DENTARIA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4645103",
    descricao: "GESSO SINTETICO PARA USO ODONTOLOGICO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4645103", descricao: "GESSO DENTAL; COMERCIO ATACADISTA DE" },
  {
    cnae: "4645103",
    descricao: "CIMENTOS PARA USO ODONTOLOGICO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4645103",
    descricao: "GEL PARA MOLDES DE DENTADURAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4729699", descricao: "SORVETES; COMERCIO VAREJISTA" },
  { cnae: "4645103", descricao: "DENTES ARTIFICIAIS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4645103",
    descricao: "PRODUTOS ODONTOLOGICOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46460",
    descricao:
      "COMERCIO ATACADISTA DE COSMETICOS, PRODUTOS DE PERFUMARIA E DE HIGIENE PESSOAL",
  },
  {
    cnae: "4646001",
    descricao:
      "BRONZEADORES E ACELERADORES DE BRONZEAMENTO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4646001",
    descricao:
      "CONCENTRADOS AROMATICOS MANIPULADOS PARA PERFUMES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4646001",
    descricao: "PRODUTOS DE PERFUMARIA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4646001",
    descricao: "ARTIGOS DE PERFUMARIA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4646001", descricao: "COSMETICOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4646001",
    descricao:
      "PRODUTOS DE HIGIENE E PERFUMARIA PARA ANIMAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4646001",
    descricao: "PRODUTOS, ARTIGOS DE BELEZA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4646001", descricao: "ARTIGOS DE TOUCADOR; COMERCIO ATACADISTA DE" },
  { cnae: "4646001", descricao: "PERFUMARIA; COMERCIO ATACADISTA DE" },
  { cnae: "4646001", descricao: "ESMALTES; COMERCIO ATACADISTA DE" },
  { cnae: "4646001", descricao: "BATOM E BLUSH; COMERCIO ATACADISTA DE" },
  {
    cnae: "4646001",
    descricao:
      "PRODUTOS DE HIGIENE E PERFUMARIA PARA USO VETERINARIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4646001",
    descricao: "ESSENCIAS MANIPULADAS PARA PERFUMES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4646001",
    descricao: "BASES PARA UNHA E ROSTO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4646001", descricao: "PERFUMES; COMERCIO ATACADISTA DE" },
  { cnae: "4646001", descricao: "CREMES E LOCOES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4646002",
    descricao: "PRESERVATIVOS (CAMISINHAS); COMERCIO ATACADISTA DE",
  },
  { cnae: "4646002", descricao: "PASTA DE DENTE; COMERCIO ATACADISTA DE" },
  { cnae: "4646002", descricao: "DENTIFRICIO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4646002",
    descricao: "PRODUTOS DE HIGIENE PESSOAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4646002",
    descricao: "FRALDAS DESCARTAVEIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4646002",
    descricao: "ABSORVENTES HIGIENICOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4646002", descricao: "CREME DENTAL; COMERCIO ATACADISTA DE" },
  {
    cnae: "4646002",
    descricao: "ARTIGOS DE HIGIENE PESSOAL; COMERCIO ATACADISTA DE",
  },
  { cnae: "4646002", descricao: "TALCO; COMERCIO ATACADISTA DE TALCO" },
  { cnae: "4646002", descricao: "SABONETES; COMERCIO ATACADISTA DE" },
  {
    cnae: "46478",
    descricao:
      "COMERCIO ATACADISTA DE ARTIGOS DE ESCRITORIO E DE PAPELARIA, LIVROS, JORNAIS E OUTRAS PUBLICACOES",
  },
  {
    cnae: "4647801",
    descricao: "ARTIGOS DE PAPELARIA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4647801",
    descricao: "ARTIGOS DE ESCRITORIO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4647801", descricao: "CADERNOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4647801",
    descricao:
      "PAPELAO E SEUS ARTEFATOS,EXCETO EMBALAGEM; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4647801",
    descricao: "ETIQUETAS DE PAPEL, PLASTICO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4647801", descricao: "ARTIGOS ESCOLARES; COMERCIO ATACADISTA DE" },
  { cnae: "4647801", descricao: "ARTEFATOS DE PAPEL; COMERCIO ATACADISTA DE" },
  {
    cnae: "4647801",
    descricao: "CANETAS, LAPIS, BORRACHAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4647801", descricao: "PAPELARIA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4647801",
    descricao:
      "GRAMPEADORES, ROTULADORES, PERFURADORES; COMERCIO ATACADISTA DE",
  },
  { cnae: "4647802", descricao: "JORNAIS; COMERCIO ATACADISTA DE" },
  { cnae: "4647802", descricao: "APOSTILAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4647802",
    descricao: "LIVROS, JORNAIS E OUTRAS PUBLICACOES; COMERCIO ATACADISTA DE",
  },
  { cnae: "4647802", descricao: "PERIODICOS; COMERCIO ATACADISTA DE" },
  { cnae: "4647802", descricao: "LIVROS; COMERCIO ATACADISTA DE" },
  { cnae: "4647802", descricao: "REVISTAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "46494",
    descricao:
      "COMERCIO ATACADISTA DE EQUIPAMENTOS E ARTIGOS DE USO PESSOAL E DOMESTICO NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "4649401",
    descricao:
      "EQUIPAMENTOS DE SEGURANCA - USO DOMESTICO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649401",
    descricao: "MAQUINA DE LAVAR E SECAR DOMESTICAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649401", descricao: "FORNO DE MICROONDAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649401",
    descricao: "AR CONDICIONADO PARA RESIDENCIAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649401",
    descricao:
      "MAQUINAS, APARELHOS E EQUIPAMENTOS ELETRICOS DE USO PESSOAL E DOMESTICO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649401",
    descricao: "SISTEMAS DE SEGURANCA - USO DOMESTICO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649401", descricao: "FOGAO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649401",
    descricao: "APARELHOS ELETRICOS DE USO DOMESTICO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649401",
    descricao:
      "CAMERAS DE SEGURANCA PARA USO DOMESTICO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649401",
    descricao: "APARELHOS ELETRODOMESTICOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649401",
    descricao:
      "ALARMES ELETRONICOS - USO DOMESTICO (EXCETO VEICULOS); COMERCIO ATACADISTA DE",
  },
  { cnae: "4649401", descricao: "GELADEIRA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649402",
    descricao: "CAMERAS ,CAMARAS FOTOGRAFICAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649402", descricao: "MAQUINA FOTOGRAFICA; COMERCIO ATACADISTA DE" },
  { cnae: "4649402", descricao: "FILMADORA; COMERCIO ATACADISTA DE" },
  { cnae: "4649402", descricao: "RADIO; COMERCIO ATACADISTA DE" },
  { cnae: "4649402", descricao: "APARELHO DE BLU-RAY; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649402",
    descricao: "EQUIPAMENTOS DE FOTO, CINE E SOM; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649402", descricao: "VIDEOCASSETE; COMERCIO ATACADISTA DE" },
  { cnae: "4649402", descricao: "DVD APARELHO DE; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649402",
    descricao:
      "APARELHOS ELETRONICOS DE USO PESSOAL E DOMESTICO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649402",
    descricao: "APARELHOS ELETRONICOS DOMESTICOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649402", descricao: "TELEVISAO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649403",
    descricao: "PECAS E ACESSORIOS PARA BICICLETAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649403",
    descricao: "BARCOS, PEDALINHOS, TRICICLOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649403", descricao: "KART; COMERCIO ATACADISTA DE" },
  { cnae: "4649403", descricao: "BICICLETAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649403",
    descricao:
      "PECAS E ACESSORIOS PARA VEICULOS RECREATIVOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649403",
    descricao: "VEICULOS RECREATIVOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649404",
    descricao: "MOVEIS EM GERAL, DE QUALQUER MATERIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649404",
    descricao: "MOVEIS PARA ESCRITORIO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649404", descricao: "TRAVESSEIROS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649404",
    descricao:
      "ESTOFADOS (EXCETO PARA AUTOMOVEIS), SOFAS E POLTRONAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649404",
    descricao: "COLCHAO DE QUALQUER MATERIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649404",
    descricao: "ARTIGOS DE COLCHOARIA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649405", descricao: "CARPETES; COMERCIO ATACADISTA DE" },
  { cnae: "4649405", descricao: "TAPETES; COMERCIO ATACADISTA DE" },
  { cnae: "4649405", descricao: "CORTINAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649405",
    descricao: "ARTIGOS DE TAPECARIA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649405", descricao: "PERSIANAS; COMERCIO ATACADISTA DE" },
  { cnae: "4649406", descricao: "LUSTRES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649406",
    descricao: "ARTIGOS DE ILUMINACAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649406",
    descricao: "LUMINARIAS, ABAJURES; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649407", descricao: "FITAS CASSETES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649407",
    descricao: "FILMES PARA FOTO E CINE; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649407", descricao: "DISCOS E FITAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649407",
    descricao: "DISCOS BLU-RAY GRAVADOS OU NAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649407",
    descricao: "CDS E DVDS GRAVADOS OU NAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649407",
    descricao: "FITAS DE AUDIO GRAVADAS OU NAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649407",
    descricao: "FITAS DE VIDEO GRAVADAS OU NAO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649407", descricao: "DISCOS DE VINIL; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649408",
    descricao: "SABAO, DETERGENTE, ALVEJANTE; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649408",
    descricao: "SANEANTE DOMISSANITARIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649408",
    descricao:
      "FUNGICIDAS, FORMICIDAS E INSETICIDAS BIOLOGICO PARA USO DOMESTICO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649408",
    descricao:
      "PRODUTOS QUIMICOS PARA LIMPEZA E CONSERVACAO DE PREDIOS E DOMICILIOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649408",
    descricao: "CRESOIS PARA USO DESINFETANTE; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649408", descricao: "SAPOLEO E SAPONACEO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649408",
    descricao: "PRODUTOS DE CONSERVACAO DOMICILIAR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649408",
    descricao: "OLEO PARA POLIMENTO DE MOVEIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649408",
    descricao: "PRODUTOS DE LIMPEZA, HIGIENE DOMESTICA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649408", descricao: "REPELENTES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649408",
    descricao: "PRODUTOS DE LIMPEZA PARA VEICULOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649409",
    descricao:
      "PRODUTOS DE CONSERVACAO DOMICILIAR; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO",
  },
  {
    cnae: "4649409",
    descricao:
      "PRODUTOS DE LIMPEZA DOMICILIAR; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO",
  },
  {
    cnae: "4649409",
    descricao:
      "PRODUTOS DE HIGIENE PESSOAL; COMERCIO ATACADISTA DE COM ACONDICIONAMENTO ASSOCIADO",
  },
  { cnae: "4649410", descricao: "JOIAS E RELOGIOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649410",
    descricao: "PEDRAS CORADAS LAPIDADAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649410",
    descricao: "PEDRAS PRECIOSAS, SEMIPRECIOSAS LAPIDADAS; COMERCIO ATACADISTA",
  },
  { cnae: "4649410", descricao: "JOIAS E BIJUTERIAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649410",
    descricao: "ARTIGOS DE JOALHERIA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649410",
    descricao:
      "PEDRAS PRECIOSAS E SEMI-PRECIOSAS LAPIDADAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649410",
    descricao: "METAIS PRECIOSOS LAPIDADOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao:
      "BOLAS PARA FUTEBOL, JOELHEIRAS, TORNOZELEIRAS E CANELEIRAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649499", descricao: "LOUCAS; COMERCIO ATACADISTA DE" },
  { cnae: "4649499", descricao: "FILTROS DE AGUA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649499",
    descricao:
      "ARTIGOS FUNERARIOS - INCLUSIVE URNAS MORTUARIAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649499", descricao: "TALHERES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649499",
    descricao: "ARTIGOS PARA AQUARIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao:
      "ESQUIS E OUTROS EQUIPAMENTOS PARA ESQUIAR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao: "APARELHOS PARA GINASTICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao:
      "ARTIGOS DE PORCELANA OU CERAMICA DECORADOS, GRAVADOS, VITRIFICADOS OU TRABALHOS DE OUTRO MODO PARA USO PESOAL OU DOMESTICO, COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao: "MAQUINAS DE COSTURA DE USO DOMESTICO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649499", descricao: "VASSOURAS; COMERCIO ATACADISTA DE" },
  { cnae: "4649499", descricao: "OCULOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649499",
    descricao: "ARTIGOS DE CACA, PESCA, CAMPING; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649499", descricao: "PANELAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649499",
    descricao:
      "ARTEFATOS DE BORRACHA PARA USO RESIDENCIAL; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649499", descricao: "FLORES ORNAMENTAIS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649499",
    descricao:
      "SOFAS, ESTOFADOS E POLTRONAS DE ESPUMA SINTETICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao: "ACESSORIOS PARA INSTRUMENTOS MUSICAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao: "VELAS DECORATIVAS E PARA CULTOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649499", descricao: "LUVAS DE BOXE; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649499",
    descricao: "PURIFICADORES DE AGUA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649499", descricao: "AQUARIOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649499",
    descricao: "LINHAS E MOLINETES PARA VARA DE PESCAR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao: "ARTIGOS DE CUTELARIA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao: "CAIXOES MORTUARIOS, INCLUSIVE URNAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649499", descricao: "PEIXES ORNAMENTAIS; COMERCIO ATACADISTA DE" },
  { cnae: "4649499", descricao: "LENTES PARA OCULOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649499",
    descricao: "VELAS (CERA) PARA ILUMINACAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao: "ARTIGOS ESPORTIVOS, DESPORTIVOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao:
      "ARTIGOS DESCARTAVEIS EM GERAL (COPOS, TALHERES, GUARDANAPOS, EMBALAGENS PARA ALIMENTOS PREPARADOS E SIMILARES); COMERCIO ATACADISTA DE",
  },
  { cnae: "4649499", descricao: "PATINS; COMERCIO ATACADISTA DE" },
  { cnae: "4649499", descricao: "PERUCAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649499",
    descricao: "OZONIZADORES DE AGUA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649499", descricao: "ARMAS PARA CACA; COMERCIO ATACADISTA DE" },
  { cnae: "4649499", descricao: "CARRINHOS DE BEBE; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649499",
    descricao: "ISQUEIROS, CACHIMBOS, PITEIRAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649499", descricao: "ARTIGOS RECREATIVOS; COMERCIO ATACADISTA DE" },
  { cnae: "4649499", descricao: "ESPANADORES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649499",
    descricao: "BRINQUEDOS DE QUALQUER MATERIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao: "ARTIGOS DE RELOJOARIA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao: "ARTIGOS PARA DECORACAO DE FESTAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao:
      "ARTIGOS DE BORRACHA E PLASTICO PARA USO DOMESTICO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649499", descricao: "ESCOVAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649499",
    descricao: "UTENSILIOS DOMESTICOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao: "RAQUETES E REDES ESPORTIVAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao: "ARTIGOS PARA HABITACAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao: "EQUIPAMENTOS PARA CACA SUBMARINA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649499", descricao: "REDES DE DORMIR; COMERCIO ATACADISTA DE" },
  { cnae: "4649499", descricao: "BARRACAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649499",
    descricao: "ARTIGOS RELIGIOSOS E DE CULTO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao:
      "MATERIAL ESPORTIVO (TROFEUS, CAMISAS, CHUTEIRAS, BOLAS E SEMELHANTES); COMERCIO ATACADISTA DE",
  },
  { cnae: "4649499", descricao: "ESOTERICOS ARTIGOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649499",
    descricao: "PAPEL DE PAREDE E SIMILARES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao: "ARMACOES PARA OCULOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao: "TALHAS E FILTROS DE QUALQUER MATERIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao: "ARTIGOS DE ARTESANATO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4649499",
    descricao: "ARTIGOS PARA FESTAS EM GERAL; COMERCIO ATACADISTA DE",
  },
  { cnae: "4649499", descricao: "ARTIGOS DE OPTICA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4649499",
    descricao: "INSTRUMENTOS MUSICAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46516",
    descricao:
      "COMERCIO ATACADISTA DE COMPUTADORES, PERIFERICOS E SUPRIMENTOS DE INFORMATICA",
  },
  {
    cnae: "4651601",
    descricao: "PROGRAMAS INFORMATICOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4651601", descricao: "MICROCOMPUTADORES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4651601",
    descricao:
      "EQUIPAMENTOS PERIFERICOS DE INFORMATICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4651601",
    descricao:
      "PROGRAMAS DE COMPUTADOR NAO CUSTOMIZAVEIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4651601",
    descricao: "TECLADOS PARA COMPUTADORES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4651601",
    descricao: "TERMINAIS PARA COMPUTADORES; COMERCIO ATACADISTA DE",
  },
  { cnae: "4651601", descricao: "IMPRESSORAS; COMERCIO ATACADISTA DE" },
  { cnae: "4651601", descricao: "LEITORAS OPTICAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4651601",
    descricao: "PECAS E EQUIPAMENTOS PARA INFORMATICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4651601",
    descricao:
      "PROGRAMAS INFORMATICOS NAO PERSONALIZADOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4651601",
    descricao: "EQUIPAMENTOS DE INFORMATICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4651601",
    descricao:
      "ASSESSORIA EM INFORMATICA ASSOCIADA A VENDA DE COMPUTADORES E PERIFERICOS",
  },
  {
    cnae: "4651601",
    descricao: "PECAS E ACESSORIOS PARA COMPUTADORES; COMERCIO ATACADISTA DE",
  },
  { cnae: "4651601", descricao: "SOFTWARE; COMERCIO ATACADISTA DE" },
  {
    cnae: "4651601",
    descricao: "MONITOR, VIDEO PARA COMPUTADORES; COMERCIO ATACADISTA DE",
  },
  { cnae: "4651601", descricao: "COMPUTADORES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4651602",
    descricao:
      "CIRCUITOS IMPRESSOS PARA USO NA INFORMATICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4651602",
    descricao: "SUPRIMENTOS DE INFORMATICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4651602",
    descricao:
      "MICROCHIPS E CIRCUITOS INTEGRADOS PARA USO NA INFORMATICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4651602",
    descricao:
      "TONER, CARTUCHO DE TINTA PARA IMPRESSORAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4651602",
    descricao: "SEMICONDUTORES PARA USO NA INFORMATICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4651602",
    descricao:
      "DISQUETES E DISCOS OPTICOS PARA USO NA INFORMATICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46524",
    descricao:
      "COMERCIO ATACADISTA DE COMPONENTES ELETRONICOS E EQUIPAMENTOS DE TELEFONIA E COMUNICACAO",
  },
  {
    cnae: "4652400",
    descricao: "EQUIPAMENTOS DE RADIOAMADORES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4652400",
    descricao: "CENTRAIS DE COMUNICACAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4652400",
    descricao: "CENTRAL DE COMUNICACAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4652400",
    descricao:
      "COMPONENTES ELETRONICOS, EXCETO PARA USO NA INFORMATICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4652400",
    descricao:
      "SEMICONDUTORES, EXCETO PARA USO NA INFORMATICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4652400",
    descricao: "VALVULAS E TUBOS ELETRONICOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4652400",
    descricao: "SECRETARIA ELETRONICA, FAX; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4652400",
    descricao:
      "MICROCHIPS E CIRCUITOS INTEGRADOS, EXCETO PARA USO NA INFORMATICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4652400",
    descricao: "TELEFONES, INTER-COMUNICADORES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4652400",
    descricao: "CENTRAIS TELEFONICAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4652400",
    descricao:
      "CIRCUITOS IMPRESSOS, EXCETO PARA USO NA INFORMATICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4652400",
    descricao: "EQUIPAMENTOS PARA COMUNICACAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4652400",
    descricao: "PECAS E ACESSORIOS PARA COMUNICACAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4652400",
    descricao: "APARELHOS DE RADIOAMADORES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46613",
    descricao:
      "COMERCIO ATACADISTA DE MAQUINAS, APARELHOS E EQUIPAMENTOS PARA USO AGROPECUARIO, PARTES E PECAS",
  },
  {
    cnae: "4661300",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA USO NA AVICULTURA E APICULTURA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4661300", descricao: "MOTOSSERRAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4661300",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA AGRICULTURA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4661300", descricao: "APARADOR DE GRAMA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4661300",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS E EQUIPAMENTOS AGROPECUARIOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4661300",
    descricao:
      "PECAS E ACESSORIOS PARA APARELHOS AGROPECUARIOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4661300", descricao: "ROCADEIRAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4661300",
    descricao: "PULVERIZADORES AGRICOLAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4661300",
    descricao: "PECAS PARA TRATORES AGRICOLAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4661300",
    descricao: "SEMEADEIRAS AGRICOLAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4661300",
    descricao: "ARTIGOS PARA SERICICULTURA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4661300",
    descricao: "MAQUINAS E IMPLEMENTOS AGRICOLAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4661300", descricao: "CORTADORAS DE GRAMA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4661300",
    descricao: "TRILHADEIRAS AGRICOLAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4661300",
    descricao: "APARELHOS PARA USO AGROPECUARIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4661300",
    descricao:
      "PNEUS E CAMARAS DE AR PARA TRATORES AGRICOLAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4661300",
    descricao: "INSTRUMENTOS E ACESSORIOS AGRICOLAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4661300",
    descricao: "CLASSIFICADORES DE OVOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4661300", descricao: "TRATORES AGRICOLAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4661300",
    descricao: "MAQUINAS PARA USO AGROPECUARIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4661300",
    descricao: "PECAS PARA MAQUINAS AGRICOLAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4661300",
    descricao: "IMPLEMENTOS AGRICOLAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4661300",
    descricao: "EQUIPAMENTOS DE IRRIGACAO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4661300", descricao: "ARADOS AGRICOLAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4661300",
    descricao: "MAQUINAS E EQUIPAMENTOS AGRICOLAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4661300", descricao: "ANCINHOS MECANICOS; COMERCIO ATACADISTA DE" },
  { cnae: "4661300", descricao: "TOSQUIADORES DE LA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4661300",
    descricao: "EQUIPAMENTOS PARA USO AGROPECUARIO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4661300", descricao: "PLANTADEIRAS; COMERCIO ATACADISTA DE" },
  { cnae: "4661300", descricao: "AIVECA PARA ARADOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4661300",
    descricao: "CULTIVADORES AGRICOLAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4661300", descricao: "MAQUINAS AGRICOLAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "46621",
    descricao:
      "COMERCIO ATACADISTA DE MAQUINAS, EQUIPAMENTOS PARA TERRAPLENAGEM, MINERACAO E CONSTRUCAO, PARTES E PECAS",
  },
  {
    cnae: "4662100",
    descricao: "APARELHOS E EQUIPAMENTOS TOPOGRAFICOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4662100",
    descricao:
      "LAGARTAS PARA TRATORES DE USO NA CONSTRUCAO, MINERACAO E TERRAPLENAGEM; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4662100",
    descricao:
      "BETONEIRAS DE CONCRETO EQUIPAMENTO, EXCETO CAMINHOES DE; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4662100",
    descricao:
      "MAQUINAS, APARELHOS E EQUIPAMENTOS PARA TERRAPLENAGEM; COMERCIO ATACADISTA DE",
  },
  { cnae: "4662100", descricao: "BETONEIRAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4662100",
    descricao:
      "MAQUINAS, APARELHOS E EQUIPAMENTOS PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4662100",
    descricao: "MAQUINAS DE TERRAPLENAGEM; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4662100",
    descricao:
      "MAQUINAS, APARELHOS E EQUIPAMENTOS PARA MINERACAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4662100",
    descricao:
      "PARTES E PECAS DE MAQUINAS E EQUIPAMENTOS PARA TERRAPLENAGEM, MINERACAO E CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4662100",
    descricao:
      "TRATORES DE RODA OU ESTEIRA, EXCETO AGRICOLA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4662100",
    descricao: "NIVELADORA, RASPADORA DE PISOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46630",
    descricao:
      "COMERCIO ATACADISTA DE MAQUINAS E EQUIPAMENTOS PARA USO INDUSTRIAL, PARTES E PECAS",
  },
  {
    cnae: "4663000",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS INDUSTRIAIS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4663000", descricao: "CONTAINERS, COMERCIO ATACADISTA" },
  {
    cnae: "4663000",
    descricao:
      "FRESADORES HORIZONTAIS, VERTICAIS E UNIVERSAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao: "APARELHOS PARA USO INDUSTRIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao:
      "HIDRAULICOS E VEDACAO INDUSTRIAL, PRODUTOS, PECAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao: "MAQUINAS PARA USO INDUSTRIAL; COMERCIO ATACADISTA DE",
  },
  { cnae: "4663000", descricao: "MAQUINAS-FERRAMENTA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4663000",
    descricao: "EQUIPAMENTOS PNEUMATICOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao: "MANGUEIRAS PARA MAQUINAS INDUSTRIAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao: "CORREIAS PARA USO INDUSTRIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS OPERATRIZES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao: "VALVULAS INDUSTRIAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao:
      "FURADEIRAS DE BANCADA, COLUNA OU MULTIPLA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4663000", descricao: "ESMERILHADEIRAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4663000",
    descricao: "MAQUINAS E FERRAMENTAS OPERATRIZES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao:
      "PECAS, ACESSORIOS E COMPONENTES PARA MAQUINAS, EQUIPAMENTOS E APARELHOS PARA USO INDUSTRIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao: "PECAS PARA MAQUINAS INDUSTRIAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao: "EQUIPAMENTOS PARA USO INDUSTRIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao: "GAXETAS PARA MAQUINAS INDUSTRIAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao: "ROLAMENTOS PARA MAQUINAS INDUSTRIAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao: "RETENTORES PARA MAQUINAS INDUSTRIAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao: "EQUIPAMENTOS PARA AUTOMACAO INDUSTRIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao: "ABRASIVOS, FERRAMENTA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4663000",
    descricao: "CONEXOES PARA MAQUINAS INDUSTRIAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46648",
    descricao:
      "COMERCIO ATACADISTA DE MAQUINAS, APARELHOS E EQUIPAMENTOS PARA USO ODONTOMEDICOHOSPITALAR, PARTES E PECAS",
  },
  {
    cnae: "4664800",
    descricao:
      "MAQUINAS E EQUIPAMENTOS MEDICO-CIRURGICOS DIVERSOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4664800",
    descricao:
      "EQUIPAMENTOS MEDICO-CIRURGICOS DIVERSOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4664800",
    descricao: "MOBILIARIO ODONTOLOGICO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4664800",
    descricao:
      "MAQUINAS, APARELHOS E EQUIPAMENTOS PARA USO VETERINARIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4664800",
    descricao: "MOBILIARIO PARA USO MEDICO-HOSPITALAR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4664800",
    descricao:
      "FISIOTERAPIA, EQUIPAMENTOS, APARELHOS PARA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4664800",
    descricao: "EQUIPAMENTOS DE RAIOS X; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4664800",
    descricao: "MAQUINAS DE ULTRA SOM; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4664800",
    descricao:
      "COMPONENTES NAO ELETRONICOS PARA MAQUINAS E EQUIPAMENTOS PARA USO ODONTO MEDICO HOSPITALAR",
  },
  {
    cnae: "4664800",
    descricao: "EQUIPAMENTOS PARA FISIOTERAPIA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4664800",
    descricao: "EQUIPAMENTOS DE MAMOGRAFIA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4664800",
    descricao:
      "PECAS E ACESSORIOS PARA EQUIPAMENTOS HOSPITALARES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4664800",
    descricao: "MAQUINAS DE LABORATORIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4664800",
    descricao: "MAQUINAS E EQUIPAMENTOS DE LABORATORIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4664800",
    descricao: "EQUIPAMENTOS DE MONITORACAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4664800",
    descricao: "EQUIPAMENTOS HOSPITALARES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4664800",
    descricao: "EQUIPAMENTOS DE LABORATORIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4664800",
    descricao: "EQUIPAMENTOS DE ULTRA SOM; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46656",
    descricao:
      "COMERCIO ATACADISTA DE MAQUINAS E EQUIPAMENTOS PARA USO COMERCIAL, PARTES E PECAS",
  },
  {
    cnae: "4665600",
    descricao: "MAQUINAS REGISTRADORAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao: "IMPRESSORAS FISCAIS E NAO FISCAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao: "MAQUINAS DE FATIAR PARA USO COMERCIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao:
      "MAQUINAS DE REFRIGERACAO PARA USO COMERCIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao: "IMPRESSORAS DE CHEQUES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao:
      "BOMBAS DE GASOLINA, MAQUINAS E EQUIPAMENTOS PARA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA USO COMERCIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao: "SORVETEIRAS PARA BARES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao:
      "CORTADORES DE FRIOS PARA BARES E LANCHONETES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao: "REFRESQUEIRAS PARA BARES E LANCHONETES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao: "BALANCAS PARA USO COMERCIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao: "EQUIPAMENTOS DE AUTOMACAO COMERCIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA AUTOMACAO GERENCIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao: "MAQUINAS ETIQUETADORAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao:
      "MAQUINAS PARA APLICACAO DE CODIGOS EM EMBALAGENS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao: "REFRIGERADORES PARA USO COMERCIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao: "CAFE, MAQUINAS PARA PREPARAR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao: "LEITORES DE CODIGO DE BARRAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao:
      "FRITADORAS E SANDUICHEIRA PARA BARES E LANCHONETES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao: "MAQUINAS DE EMISSAO DE CUPOM FISCAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao: "CAFETEIRAS PARA BARES E LANCHONETES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao: "MOEDORES DE CAFE PARA USO COMERCIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao: "BALCOES FRIGORIFICOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao:
      "EXPOSITORES PARA LOJAS (MANEQUINS, ARARAS, CABIDES, ETC.); COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao:
      "INSTALACOES COMERCIAIS, BALCOES, PRATELEIRAS, GONDOLAS, PROVADORES, EXPOSITORES, ILHAS, LEITORAS, ESTUFAS, DISPLAYS, CHECK-OUTS, VITRINES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4665600",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS E EQUIPAMENTOS PARA O COMERCIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46699",
    descricao:
      "COMERCIO ATACADISTA DE MAQUINAS, APARELHOS E EQUIPAMENTOS NAO ESPECIFICADOS ANTERIORMENTE, PARTES E PECAS",
  },
  { cnae: "4669901", descricao: "COMPRESSORES; COMERCIO ATACADISTA DE" },
  { cnae: "9411100", descricao: "ORGANIZACAO EMPRESARIAL" },
  {
    cnae: "4669901",
    descricao: "BOMBAS E CARNEIROS HIDRAULICOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669901",
    descricao:
      "PECAS E ACESSORIOS PARA BOMBAS E COMPRESSORES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669901",
    descricao:
      "PECAS E ACESSORIOS PARA CARNEIROS HIDRAULICOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4669999", descricao: "PAS MECANICAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4669999",
    descricao:
      "EMBARCACOES -INCLUSIVE PARA ESPORTE E LAZER; COMERCIO ATACADISTA DE",
  },
  { cnae: "4669999", descricao: "GERADOR ELETRICO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4669999",
    descricao: "CONDICIONADORES DE AR NAO-RESIDENCIAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "APARELHOS DE MEDIDA E PRECISAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "MAQUINAS DE ESCREVER; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "PECAS E ACESSORIOS PARA MOTORES MARITIMOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "PLACAS DE ENERGIA SOLAR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "INSTRUMENTOS DE MEDICAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "MAQUINAS DE COSTURA DE USO INDUSTRIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "ARMAS E MUNICOES, EXCETO ESPORTIVA E PARA CACA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "ESCAVADEIRAS (ESCAVADORAS); COMERCIO ATACADISTA DE",
  },
  { cnae: "4669999", descricao: "COFRES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4669999",
    descricao: "PECAS PARA AVIOES DE PEQUENO PORTE; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "AR CONDICIONADO, CONDICIONADORES DE AR PARA USO COMERCIAL; COMERCIO ATACADISTA DE",
  },
  { cnae: "4669999", descricao: "EMPILHADEIRA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4669999",
    descricao:
      "ALARMES ELETRONICOS - USO INDUSTRIAL (EXCETO VEICULOS); COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "EXTINTORES DE INCENDIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "MAQUINAS PARA USOS TECNICO E PROFISSIONAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "PECAS PARA AERONAVES DE PEQUENO PORTE; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "MAQUINAS DE CALCULAR; COMERCIO ATACADISTA DE",
  },
  { cnae: "4669999", descricao: "AQUECEDORES SOLARES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4669999",
    descricao:
      "PECAS E ACESSORIOS PARA EMBARCACOES -INCLUSIVE PARA ESPORTE E LAZER; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "RENOVADORES DE AR NAO-RESIDENCIAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "RELOGIO DE PONTO PARA EMPRESAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "EQUIPAMENTOS DE SEGURANCA - USO INDUSTRIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "MOTORES ESTACIONARIOS PARA COMBUSTAO INTERNA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "TRANSFORMADOR DE DISTRIBUICAO MONOFASICO E TRIFASICO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "MOTORES ELETRICOS PARA MOVIMENTACAO DE PORTOES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS, APARELHOS E EQUIPAMENTOS DE USO TECNICO E PROFISSIONAL; COMERCIO ATACADISTA DE",
  },
  { cnae: "4669999", descricao: "JET SKI; COMERCIO ATACADISTA DE" },
  {
    cnae: "4669999",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA ESCRITORIO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4669999", descricao: "MAQUINAS COPIADORAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4669999",
    descricao: "PARA RAIOS, EQUIPAMENTOS PARA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "EQUIPAMENTOS PARA PRODUCAO DE ENERGIA ELETRICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "EQUIPAMENTOS PARA USOS TECNICO E PROFISSIONAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "MOBILIARIO, MOVEIS PARA CABELEIREIROS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4669999", descricao: "PALETEIRAS. COMERCIO ATACADISTA DE" },
  {
    cnae: "4669999",
    descricao: "MEDIDORES DE GAS, APARELHOS DE; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "PAINEL, PAINEIS SOLARES FOTOVOLTAICOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "SISTEMAS DE SEGURANCA - USO INDUSTRIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS E EQUIPAMENTOS DE ESCRITORIO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4669999", descricao: "MOTORES MARITIMOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4669999",
    descricao: "PLACAS DE GERACAO DE ENERGIA ELETRICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA CABELEIREIROS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4669999", descricao: "GUILHOTINAS MANUAIS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4669999",
    descricao:
      "SINALIZACAO FERROVIARIA EQUIPAMENTOS DE; COMERCIO ATACADISTA DE",
  },
  { cnae: "4669999", descricao: "ANTENAS PARABOLICAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4669999",
    descricao:
      "MAQUINAS COPIADORAS, XEROX, XEROGRAFICAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4669999", descricao: "GUINDASTES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4669999",
    descricao: "INSTRUMENTOS DE MEDIDA E PRECISAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "EQUIPAMENTOS PARA CONDICIONAMENTO FISICO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "PLACAS SOLARES FOTOVOLTAICAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4669999", descricao: "NIVELADORAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4669999",
    descricao:
      "APARELHOS PARA USOS TECNICO E PROFISSIONAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "PECAS E ACESSORIOS PARA EMBARCACOES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "MATRIZES PARA IMPRESSAO (FOTOLITOS, CLICHES, CHAPAS PARA OFSETE (OFF-SET), FOTOGRAVURAS, COMPOSICAO, ETC ; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "BROCAS PARA PERFURACAO DE PETROLEO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "CALIBRADORES DE PNEUS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "EQUIPAMENTOS METEOROLOGICOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "PECAS, ACESSORIOS E COMPONENTES PARA MAQUINAS, EQUIPAMENTOS E APARELHOS PARA USOS TECNICO E PROFISSIONAL; COMERCIO ATACADISTA DE",
  },
  { cnae: "4669999", descricao: "AERONAVES, AVIOES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4669999",
    descricao:
      "MOBILIARIO, MOVEIS PARA SALAO DE BELEZA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA SALAO DE BELEZA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "ELEVADORES, PECAS E ACESSORIOS PARA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "EQUIPAMENTOS DE COMBATE A INCENDIOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao: "TRITURADOR DE RESIDUOS INDUSTRIAIS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4669999", descricao: "COPIADORAS; COMERCIO ATACADISTA DE" },
  { cnae: "4669999", descricao: "VAGAO FERROVIARIO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4669999",
    descricao: "BEBEDOUROS NAO RESIDENCIAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4669999",
    descricao:
      "CAMERAS DE SEGURANCA PARA USO INDUSTRIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46711",
    descricao: "COMERCIO ATACADISTA DE MADEIRA E PRODUTOS DERIVADOS",
  },
  {
    cnae: "4671100",
    descricao: "BRIQUETE (SERRAGEM COMPACTADA); COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4671100",
    descricao: "ESQUADRIAS DE MADEIRA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4671100", descricao: "MADEIRA; COMERCIO ATACADISTA DE" },
  { cnae: "4671100", descricao: "COMPENSADOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4671100",
    descricao: "MADEIRAS PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  { cnae: "9602501", descricao: "CABELEIREIRO; SERVICOS DE" },
  {
    cnae: "4671100",
    descricao: "FORROS E DIVISORIAS DE MADEIRA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4671100",
    descricao: "TACOS DE MADEIRA PARA PISOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4671100",
    descricao:
      "PRODUTOS DERIVADOS DA MADEIRA PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46729",
    descricao: "COMERCIO ATACADISTA DE FERRAGENS E FERRAMENTAS",
  },
  { cnae: "4672900", descricao: "CADEADOS; COMERCIO ATACADISTA DE" },
  { cnae: "4672900", descricao: "FECHADURAS; COMERCIO ATACADISTA DE" },
  { cnae: "4672900", descricao: "TELAS DE ARAME; COMERCIO ATACADISTA DE" },
  {
    cnae: "4672900",
    descricao: "FERRAMENTAS MANUAIS NAO-ELETRICAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4672900", descricao: "ALUMINIO, CHAPAS DE; COMERCIO ATACADISTA DE" },
  {
    cnae: "4672900",
    descricao: "PARAFUSOS, PORCAS, ARRUELAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4672900",
    descricao: "FERRAGENS E FERRAMENTAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4672900",
    descricao: "FERRAGENS E METAIS SANITARIOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4672900",
    descricao:
      "FERRAGENS (CORRENTES, DOBRADICAS, FERROLHOS, GANCHOS, BRACADEIRAS); COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4672900",
    descricao: "FERRAMENTAS MANUAIS ELETRICAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "46737", descricao: "COMERCIO ATACADISTA DE MATERIAL ELETRICO" },
  {
    cnae: "4673700",
    descricao: "FIOS E CABOS ELETRICOS PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4673700",
    descricao: "MATERIAL ELETRICO PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4673700",
    descricao: "CONDUTORES ELETRICOS PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4673700", descricao: "LAMPADAS; COMERCIO ATACADISTA DE" },
  { cnae: "4673700", descricao: "ISOLADOR TERMICO; COMERCIO ATACADISTA DE" },
  { cnae: "4673700", descricao: "CHUVEIROS ELETRICOS; COMERCIO ATACADISTA DE" },
  { cnae: "46745", descricao: "COMERCIO ATACADISTA DE CIMENTO" },
  { cnae: "4674500", descricao: "CIMENTO; COMERCIO ATACADISTA DE" },
  {
    cnae: "46796",
    descricao:
      "COMERCIO ATACADISTA ESPECIALIZADO DE MATERIAIS DE CONSTRUCAO NAO ESPECIFICADOS ANTERIORMENTE E DE MATERIAIS DE CONSTRUCAO EM GERAL",
  },
  {
    cnae: "4679601",
    descricao: "TINTAS, VERNIZES E SIMILARES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4679601",
    descricao: "PINCEIS, BROCHAS, ROLOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4679601",
    descricao: "TEXTURAS E GRAFIATOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4679601", descricao: "TINERES PARA TINTA; COMERCIO ATACADISTA DE" },
  { cnae: "4679601", descricao: "TINTA AUTOMOTIVA; COMERCIO ATACADISTA DE" },
  { cnae: "4679601", descricao: "TINTAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4679601",
    descricao: "SOLVENTES PARA PINTURA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4679601", descricao: "IMPERMEABILIZANTES; COMERCIO ATACADISTA DE" },
  { cnae: "4679601", descricao: "AGUARRAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4679602",
    descricao: "PEDRAS DECORATIVAS PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4679602",
    descricao: "MARMORE E GRANITO PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4679602",
    descricao:
      "PECAS PRONTAS DE MARMORES E GRANITOS PARA USO NA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4679603",
    descricao: "VIDROS PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4679603",
    descricao: "VIDROS PLANOS E DE SEGURANCA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4679603",
    descricao: "VIDRACARIA, EXCETO DE VEICULOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4679603", descricao: "VITRAIS; COMERCIO ATACADISTA DE" },
  { cnae: "4679603", descricao: "ESPELHOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4679604",
    descricao:
      "EQUIPAMENTOS PARA TRATAMENTO DE AGUA DE PISCINAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4679604", descricao: "TELHAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4679604",
    descricao: "FORROS E DIVISORIAS METALICAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4679604",
    descricao: "ARTEFATOS DE CIMENTO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4679604", descricao: "GESSO; COMERCIO ATACADISTA DE" },
  { cnae: "4679604", descricao: "TIJOLO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4679604",
    descricao:
      "SAUNAS E EQUIPAMENTOS PARA SUA INSTALACAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4679604",
    descricao: "FORROS E DIVISORIAS DE PLASTICO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4679604", descricao: "CAIXA DE DESCARGA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4679604",
    descricao: "ESQUADRIAS METALICAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4679604",
    descricao:
      "PRODUTOS, ARTIGOS SIDERURGICOS E METALURGICOS PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4679604", descricao: "AREIA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4679604",
    descricao: "QUARTZITO SERICITICO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4679604", descricao: "METAIS SANITARIOS; COMERCIO ATACADISTA DE" },
  { cnae: "4679604", descricao: "PORTAS ELETRONICAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4679604",
    descricao:
      "PEDRAS DECORATIVAS (ARDOSIA, SAO TOME, GRANITO); COMERCIO ATACADISTA DE",
  },
  { cnae: "4679604", descricao: "AZULEJOS, CERAMICAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4679604",
    descricao:
      "PISCINAS E EQUIPAMENTOS PARA SUA INSTALACAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4679604",
    descricao:
      "PISOS E REVESTIMENTOS CERAMICOS PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4679604", descricao: "RODAPES DE CERAMICA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4679604",
    descricao: "ESQUADRIAS DE PLASTICO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4679604", descricao: "ASFALTO; COMERCIO ATACADISTA DE" },
  { cnae: "4679604", descricao: "ARGILA REFRATARIA; COMERCIO ATACADISTA DE" },
  { cnae: "4679604", descricao: "PORTOES ELETRONICOS; COMERCIO ATACADISTA DE" },
  { cnae: "4679604", descricao: "CAL; COMERCIO ATACADISTA DE" },
  {
    cnae: "4679604",
    descricao: "APARELHOS E ARTIGOS SANITARIOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4679604",
    descricao:
      "PELICULAS DE POLIESTER (INSULFILME) PARA REVESTIMENTO DE VIDROS RESIDENCIAIS E COMERCIAIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4679604",
    descricao: "DIVISORIAS SANFONADAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4679604", descricao: "CAIXA DE AGUA; COMERCIO ATACADISTA DE" },
  { cnae: "4679604", descricao: "LIXA, COMERCIO ATACADISTA" },
  { cnae: "4679604", descricao: "PREGOS; COMERCIO ATACADISTA DE" },
  { cnae: "4679604", descricao: "LOUCAS SANITARIAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4679604",
    descricao: "CANOS, TUBOS, CONEXOES; COMERCIO ATACADISTA DE",
  },
  { cnae: "4679604", descricao: "CALHAS PARA CONSTRUCAO; COMERCIO ATACADISTA" },
  {
    cnae: "4679604",
    descricao: "PRE-MOLDADOS PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4679604", descricao: "PORTAS SANFONADAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4679699",
    descricao:
      "MATERIAL DE CONSTRUCAO EM GERAL(NO MESMO ESTABELECIMENTO); COMERCIO ATACADISTA",
  },
  {
    cnae: "4679699",
    descricao:
      "BOX PARA BANHEIRO COM ESTRUTURA DE ALUMINIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4679699",
    descricao: "MATERIAIS DE CONSTRUCAO EM GERAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46818",
    descricao:
      "COMERCIO ATACADISTA DE COMBUSTIVEIS SOLIDOS, LIQUIDOS E GASOSOS, EXCETO GAS NATURAL E GLP",
  },
  {
    cnae: "4681801",
    descricao:
      "ALCOOL CARBURANTE - EXCETO REALIZADO POR TRANSPORTADOR RETALHISTA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681801",
    descricao:
      "COMBUSTIVEIS DERIVADOS DO REFINO DO PETROLEO - EXCETO REALIZADO POR TRANSPORTE RETALHISTA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681801",
    descricao: "COMBUSTIVEIS PARA AERONAVES, AVIOES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681801",
    descricao: "QUEROSENE DE AVIACAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681801",
    descricao:
      "GASOLINA - EXCETO REALIZADO POR TRANSPORTADOR RETALHISTA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4681801", descricao: "ETANOL; COMERCIO ATACADISTA DE" },
  {
    cnae: "4681801",
    descricao: "COQUE DERIVADO DO PETROLEO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681801",
    descricao:
      "BIODIESEL - EXCETO REALIZADO POR TRANSPORTADOR RETALHISTA; COMERCIO ATACADISTA",
  },
  {
    cnae: "4681801",
    descricao: "COMBUSTIVEIS PARA EMBARCACOES, BARCOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681802",
    descricao: "REVENDEDOR RETALHISTA DE COMBUSTIVEIS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681802",
    descricao: "RETALHISTA DE GASOLINA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681802",
    descricao: "RETALHISTA DE ALCOOL CARBURANTE; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681802",
    descricao:
      "REVENDEDOR RETALHISTA DE DERIVADOS DO PETROLEO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681802",
    descricao: "RETALHISTA DE BIODIESEL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681802",
    descricao: "RETALHISTA DE DERIVADOS DO PETROLEO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681802",
    descricao: "RETALHISTA DE LUBRIFICANTES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681802",
    descricao: "RETALHISTA DE QUEROSENE; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681803",
    descricao:
      "CARVAO ECOLOGICO DE ORIGEM VEGETAL, UTILIZADO COMO COMBUSTIVEL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681803",
    descricao: "BRIQUETES, UTILIZADOS COMO COMBUSTIVEL; COMERCIO ATACADISTA DE",
  },
  { cnae: "4681803", descricao: "CARVAO VEGETAL; COMERCIO ATACADISTA DE" },
  {
    cnae: "4681803",
    descricao:
      "BAGACO DE CANA, UTILIZADO COMO COMBUSTIVEL; COMERCIO ATACADISTA DE",
  },
  { cnae: "4681803", descricao: "LENHA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4681803",
    descricao: "OLEO CARBURANTE DE MAMONA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681803",
    descricao: "BIOMASSA, UTILIZADA COMO COMBUSTIVEL; COMERCIO ATACADISTA DE",
  },
  { cnae: "4681804", descricao: "TURFA; COMERCIO ATACADISTA DE" },
  { cnae: "4681804", descricao: "CARVAO MINERAL; COMERCIO ATACADISTA DE" },
  { cnae: "4681804", descricao: "ANTRACITO; COMERCIO ATACADISTA DE" },
  { cnae: "4681804", descricao: "LINHITO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4681804",
    descricao:
      "COMERCIALIZACAO DE PETROLEO CRU OU EM BRUTO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4681804", descricao: "PETROLEO CRU; COMERCIO ATACADISTA DE" },
  { cnae: "4681805", descricao: "ANTICORROSIVOS; COMERCIO ATACADISTA DE" },
  { cnae: "4681805", descricao: "OLEO DE TEMPERA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4681805",
    descricao: "OLEOS PARA ENGRENAGENS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4681805", descricao: "LUBRIFICANTES; COMERCIO ATACADISTA DE" },
  { cnae: "4681805", descricao: "OLEOS DESMOLDANTES; COMERCIO ATACADISTA DE" },
  { cnae: "4681805", descricao: "GRAXA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4681805",
    descricao:
      "FLUIDOS DERIVADOS OU NAO DO REFINO DO PETROLEO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681805",
    descricao: "FLUIDOS PARA FREIO E TRANSMISSAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681805",
    descricao:
      "ADITIVOS DERIVADOS OU NAO DO REFINO DO PETROLEO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681805",
    descricao: "LUBRIFICANTES AUTOMOTIVO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4681805",
    descricao: "OLEOS LUBRIFICANTES INDUSTRIAIS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4681805", descricao: "DESENGRAXANTES; COMERCIO ATACADISTA DE" },
  {
    cnae: "46826",
    descricao: "COMERCIO ATACADISTA DE GAS LIQUEFEITO DE PETROLEO (GLP)",
  },
  {
    cnae: "4682600",
    descricao:
      "DISTRIBUIDORA DE GAS LIQUEFEITO DE PETROLEO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4682600",
    descricao: "GAS LIQUEFEITO DE PETROLEO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4682600", descricao: "GAS DE BOTIJAO; COMERCIO ATACADISTA DE" },
  { cnae: "4682600", descricao: "GAS DE COZINHA; COMERCIO ATACADISTA DE" },
  { cnae: "4682600", descricao: "GAS ENGARRAFADO; COMERCIO ATACADISTA DE" },
  { cnae: "4682600", descricao: "GAS BUTANO; COMERCIO ATACADISTA DE" },
  {
    cnae: "46834",
    descricao:
      "COMERCIO ATACADISTA DE DEFENSIVOS AGRICOLAS, ADUBOS, FERTILIZANTES E CORRETIVOS DO SOLO",
  },
  { cnae: "4683400", descricao: "FUNGICIDAS; COMERCIO ATACADISTA DE" },
  { cnae: "4683400", descricao: "ADUBOS QUIMICOS; COMERCIO ATACADISTA DE" },
  { cnae: "4683400", descricao: "AGROTOXICOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4683400",
    descricao: "INSETICIDA PARA USO AGRICOLA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4683400",
    descricao: "DEFENSIVOS AGRICOLAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4683400",
    descricao: "CALCARIO PARA CORRECAO DO SOLO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4683400",
    descricao: "INSETICIDA BIOLOGICA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4683400", descricao: "AEROTOXICOS; COMERCIO ATACADISTA DE" },
  { cnae: "4683400", descricao: "CORRETIVOS DO SOLO; COMERCIO ATACADISTA DE" },
  { cnae: "4683400", descricao: "FERTILIZANTES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4683400",
    descricao: "DEFENSIVOS AGRICOLAS BIOLOGICOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4683400",
    descricao: "INSUMOS AGROPECUARIOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4683400",
    descricao: "PRODUTOS QUIMICOS PARA AGRICULTURA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4683400",
    descricao:
      "PRODUTOS DE BIOTECNOLOGIA , PARA AGRICULTURA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4683400",
    descricao: "INSUMOS AGRICOLAS INDUSTRIAIS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4683400", descricao: "HUMUS PARA PLANTAS; COMERCIO ATACADISTA DE" },
  { cnae: "4683400", descricao: "FUNGICIDA BIOLOGICA; COMERCIO ATACADISTA DE" },
  { cnae: "4683400", descricao: "HERBICIDAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "46842",
    descricao:
      "COMERCIO ATACADISTA DE PRODUTOS QUIMICOS E PETROQUIMICOS, EXCETO AGROQUIMICOS",
  },
  { cnae: "4684201", descricao: "POLICARBONATOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4684201",
    descricao: "SILICONE EM FORMA PRIMARIA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4684201", descricao: "ELASTOMEROS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4684201",
    descricao: "ELASTOMEROS NAO VULCANIZADOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4684201", descricao: "RESINAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4684201",
    descricao: "RESINAS TERMOPLASTICAS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4684201", descricao: "RESINAS TERMOFIXAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4684201",
    descricao: "GRANULADOS DE POLIETILENO (RESINAS) ; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4684201",
    descricao: "LATEX OU LATICE DE SBR; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4684202",
    descricao: "SOLVENTES PARA USOS INDUSTRIAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4684299",
    descricao:
      "ADITIVOS PARA COMBUSTIVEIS E LUBRIFICANTES; COMERCIO ATACADISTA DE",
  },
  { cnae: "4684299", descricao: "ALCOOL EM GEL; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "SAL INDUSTRIAL; COMERCIO ATACADISTA DE" },
  {
    cnae: "4684299",
    descricao: "PRODUTOS PARA LIMPEZA HOSPITALAR; COMERCIO ATACADISTA DE",
  },
  { cnae: "4684299", descricao: "CLORO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4684299",
    descricao:
      "CONCENTRADOS AROMATICOS NAO MANIPULADOS PARA PERFUMES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4684299",
    descricao: "TINTAS PARA SERIGRAFIA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4684299",
    descricao: "ARTIGOS PIROTECNICOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4684299", descricao: "HELIO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4684299",
    descricao:
      "FILMES PARA RAIO X PARA USO MEDICO, ODONTOLOGICO E SIMILARES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4684299",
    descricao: "ESSENCIAS PARA USO EM ALIMENTOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4684299", descricao: "COLORANTES; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "PARAFINA; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "ALCOOL ETILICO; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "EXPLOSIVOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4684299",
    descricao:
      "ESSENCIAS NAO MANIPULADAS PARA PERFUMES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4684299",
    descricao: "PRODUTOS PETROQUIMICOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4684299", descricao: "GAS CARBONICO; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "ACETILENO; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "FOGOS DE ARTIFICIO; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "DETONADORES; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "GAS INERTE; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "ENZIMAS EM GERAL; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "EMULSAO ASFALTICA; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "GAS REFRIGERANTE; COMERCIO ATACADISTA DE" },
  {
    cnae: "4684299",
    descricao: "PRODUTOS CARBOQUIMICOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4684299", descricao: "OXIGENIO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4684299",
    descricao: "PRODUTOS FARMOQUIMICOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4684299", descricao: "COLA QUIMICA; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "GASES INDUSTRIAIS; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "SODA CAUSTICA; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "MATERIAL PARA SOLDA; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "COALHO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4684299",
    descricao:
      "CARGAS, PREPARADOS PARA EXTINTORES DE INCENDIO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4684299", descricao: "TINTAS GRAFICAS; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "GASES NOBRES; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "ARGONIO; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "RESINAS SINTETICA; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "ABRASIVOS QUIMICOS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4684299",
    descricao:
      "REAGENTES DE DIAGNOSTICOS OU DE LABORATORIO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4684299", descricao: "HIDROGENIO; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "NITROGENIO; COMERCIO ATACADISTA DE" },
  { cnae: "4684299", descricao: "ANILINA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4684299",
    descricao: "POLVORA, EXPLOSIVOS E DETONANTES; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4684299",
    descricao: "MICROORGANISMOS (GLUTEN); COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46851",
    descricao:
      "COMERCIO ATACADISTA DE PRODUTOS SIDERURGICOS E METALURGICOS, EXCETO PARA CONSTRUCAO",
  },
  {
    cnae: "4685100",
    descricao: "LAMINADOS - EXCETO PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4685100",
    descricao:
      "ACO CARBONO BRUTO - EXCETO PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4685100",
    descricao: "CHAPAS DE ACO - EXCETO PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4685100",
    descricao:
      "PRODUTOS SIDERURGICOS - EXCETO PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4685100", descricao: "BOBINAS DE ACO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4685100",
    descricao:
      "METAIS EM FORMA PRIMARIA - EXCETO PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4685100",
    descricao:
      "PRODUTOS SIDERURGICOS E METALURGICOS, EXCETO PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4685100",
    descricao: "FITAS E TIRAS DE ACO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4685100",
    descricao:
      "TREFILADOS DE METAL, EXCETO PARA CONSTRUCAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46869",
    descricao:
      "COMERCIO ATACADISTA DE PAPEL E PAPELAO EM BRUTO E DE EMBALAGENS",
  },
  {
    cnae: "4686901",
    descricao:
      "PAPEL E PAPELAO PARA FABRICACAO DE ARTEFATOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4686901",
    descricao: "PAPEL E PAPELAO EM BRUTO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4686902",
    descricao: "EMBALAGEM DE ALUMINIO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4686902", descricao: "SACOS NOVOS; COMERCIO ATACADISTA DE" },
  { cnae: "4686902", descricao: "BOMBONAS; COMERCIO ATACADISTA DE" },
  { cnae: "4686902", descricao: "SACARIA NOVA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4686902",
    descricao:
      "EMBALAGENS DE VIDRO PARA ACONDICIONAMENTO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4686902",
    descricao: "EMBALAGEM DE PAPELAO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4686902",
    descricao: "TAMBORES DE QUALQUER MATERIAL; COMERCIO ATACADISTA DE",
  },
  { cnae: "4686902", descricao: "SACOS DE LIXO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4686902",
    descricao: "PALETES (PALLETS) DE MADEIRA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4686902",
    descricao: "EMBALAGENS PARA MARMITEX; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4686902",
    descricao: "EMBALAGENS DE QUALQUER MATERIAL; COMERCIO ATACADISTA DE",
  },
  { cnae: "46877", descricao: "COMERCIO ATACADISTA DE RESIDUOS E SUCATAS" },
  { cnae: "4687701", descricao: "APARAS DE PAPEL; COMERCIO ATACADISTA DE" },
  {
    cnae: "4687701",
    descricao: "PAPEL, PAPELAO, SUCATAS DE; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4687701",
    descricao:
      "PAPEL E PAPELAO COMO MATERIA PRIMA DA INDUSTRIA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4687701",
    descricao:
      "A COLETA, CLASSIFICACAO E SEPARACAO (SEM TRANSFORMACAO) DE RESIDUOS E SUCATAS DE PAPEL E PAPELAO PARA OBTENCAO DE PECAS PARA SEREM REUTILIZADAS E COMERCIALIZADAS",
  },
  { cnae: "4687701", descricao: "RESIDUOS DE PAPEL; COMERCIO ATACADISTA DE" },
  { cnae: "4687702", descricao: "ACUMULADORES USADOS; COMERCIO ATACADISTA DE" },
  { cnae: "4687702", descricao: "ESTOPAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4687702",
    descricao: "RESIDUOS DE TECELAGEM; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4687702",
    descricao: "SUCATA DE BATERIAS, ACUMULADORES; COMERCIO ATACADISTA DE",
  },
  { cnae: "4687702", descricao: "RESIDUOS TEXTEIS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4687702",
    descricao: "RESIDUOS INDUSTRIAIS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4687702", descricao: "SUCATA DE VIDRO; COMERCIO ATACADISTA DE" },
  { cnae: "4687702", descricao: "LUBRIFICANTE USADO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4687702",
    descricao:
      "A COLETA, CLASSIFICACAO E SEPARACAO (SEM TRANSFORMACAO) DE RESIDUOS E SUCATAS NAO METALIC0S , EXCETO DE PAPEL E PAPELAO, PARA OBTENCAO DE PECAS PARA SEREM REUTILIZADAS E COMERCIALIZADAS",
  },
  { cnae: "4687702", descricao: "SUCATA NAO METALICA; COMERCIO ATACADISTA DE" },
  { cnae: "4687702", descricao: "SUCATA DE PLASTICO; COMERCIO ATACADISTA DE" },
  { cnae: "4687702", descricao: "RESIDUOS DE FIACAO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4687702",
    descricao: "OLEO COMBUSTIVEL USADO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4687702",
    descricao: "RESIDUO DE LUBRIFICANTE; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4687702",
    descricao: "RESIDUO DE OLEO COMBUSTIVEL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4687703",
    descricao: "RESIDUOS E SUCATAS METALICOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4687703", descricao: "SUCATA METALICA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4687703",
    descricao:
      "A COLETA, CLASSIFICACAO E SEPARACAO (SEM TRANSFORMACAO) DE RESIDUOS E SUCATAS METALICOS , PARA OBTENCAO DE PECAS PARA SEREM REUTILIZADAS E COMERCIALIZADAS",
  },
  {
    cnae: "46893",
    descricao:
      "COMERCIO ATACADISTA ESPECIALIZADO DE OUTROS PRODUTOS INTERMEDIARIOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "4689301", descricao: "SAL MARINHO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4689301",
    descricao: "METAIS NOBRES EM BRUTO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4689301", descricao: "CINZA POZOLANICA; COMERCIO ATACADISTA DE" },
  { cnae: "4689301", descricao: "MINERAIS METALICOS; COMERCIO ATACADISTA DE" },
  { cnae: "4689301", descricao: "SAL-GEMA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4689301",
    descricao: "MINERAIS NAO METALICOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689301",
    descricao: "MINERAIS PRECIOSOS EM BRUTO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689301",
    descricao: "PRODUTOS EXTRATIVOS DE ORIGEM MINERAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689301",
    descricao: "METAIS PRECIOSOS EM BRUTO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689301",
    descricao:
      "METAIS FERROSOS E NAO FERROSOS EM FORMA PRIMARIA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689301",
    descricao: "PEDRAS PRECIOSAS NAO LAPIDADAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689301",
    descricao: "MINERAIS METALICOS NAO FERROSOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689301",
    descricao: "PEDRAS SEMIPRECIOSAS NAO LAPIDADAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689301",
    descricao: "PEDRAS SEMIPRECIOSAS LAPIDADAS; COMERCIO ATACADISTA",
  },
  {
    cnae: "4689301",
    descricao: "MINERAIS METALICOS FERROSOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689302",
    descricao: "FIOS NATURAIS BENEFICIADOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689302",
    descricao: "FIOS TEXTEIS BENEFICIADOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689302",
    descricao: "FIBRAS TEXTEIS BENEFICIADAS: COMERCIO ATACADISTA",
  },
  { cnae: "4689302", descricao: "FIOS NATURAIS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4689302",
    descricao: "FIOS E FIBRAS TEXTEIS BENEFICIADAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689302",
    descricao: "FIOS ARTIFICIAIS E SINTETICOS, COMERCIO ATACADISTA",
  },
  { cnae: "4689399", descricao: "SILICA, SACHE; COMERCIO ATACADISTA DE" },
  { cnae: "4689399", descricao: "ETIQUETAS METALICAS; COMERCIO ATACADISTA DE" },
  { cnae: "4689399", descricao: "MOLDURAS DE QUADRO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4689399",
    descricao: "TRILHOS PARA FERROVIA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689399",
    descricao: "BASE DE BORRACHA PARA CARIMBOS; COMERCIO ATACADISTA",
  },
  {
    cnae: "4689399",
    descricao: "ETIQUETAS DE TECIDOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689399",
    descricao:
      "ARTEFATOS DE BORRACHA (EXCETO PARA VEICULOS E USO RESIDENCIAL); COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689399",
    descricao: "BATERIAS (EXCETO PARA VEICULOS); COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689399",
    descricao: "QUADROS, LOUSAS ESCOLARES; COMERCIO ATACADISTA DE",
  },
  { cnae: "4689399", descricao: "SELO METALICO; COMERCIO ATACADISTA DE" },
  {
    cnae: "4689399",
    descricao: "AVIAMENTOS PARA SAPATEIRO; COMERCIO ATACADISTA DE",
  },
  { cnae: "4689399", descricao: "BRINDES; COMERCIO ATACADISTA DE" },
  { cnae: "4689399", descricao: "ARTIGOS DE SELARIA; COMERCIO ATACADISTA DE" },
  {
    cnae: "4689399",
    descricao: "ISOPOR (POLIESTIRENO EXPANSIVEL), COMERCIO ATACADISTA",
  },
  { cnae: "4689399", descricao: "PELES; COMERCIO ATACADISTA" },
  {
    cnae: "4689399",
    descricao:
      "PRODUTOS DIVERSOS DE PLASTICO - EXCETO USO DOMESTICO, PARA VEICULOS E EMBALAGENS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689399",
    descricao:
      "PECAS E ACESSORIOS PARA APARELHOS ELETRODOMESTICOS E ELETRONICOS - EXCETO PARA ESCRITORIO, TELEFONIA E INFORMATICA; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689399",
    descricao: "CARROCAS DE TRACAO ANIMAL; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689399",
    descricao: "MANGUEIRAS PARA INCENDIO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689399",
    descricao:
      "PARTES, INJETADOS E ACESSORIOS PARA CALCADOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4689399", descricao: "SACARIA USADA; COMERCIO ATACADISTA DE" },
  { cnae: "4689399", descricao: "CORDAS E CORDOARIAS; COMERCIO ATACADISTA DE" },
  { cnae: "4689399", descricao: "OLEO DE SASSAFRAS; COMERCIO ATACADISTA DE" },
  {
    cnae: "4689399",
    descricao: "PLACAS E PLAQUETAS PARA VEICULOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4689399",
    descricao: "INJETADOS PARA CALCADOS; COMERCIO ATACADISTA DE",
  },
  { cnae: "4689399", descricao: "PILHAS; COMERCIO ATACADISTA DE" },
  { cnae: "4689399", descricao: "FITA STRETCH; COMERCIO ATACADISTA DE" },
  { cnae: "4689399", descricao: "FITAS ADESIVAS; COMERCIO ATACADISTA DE" },
  { cnae: "4689399", descricao: "BARBANTES; COMERCIO ATACADISTA DE" },
  { cnae: "4689399", descricao: "CARTAO TELEFONICO; COMERCIO ATACADISTA DE" },
  { cnae: "4689399", descricao: "ROLHAS DE CORTICA; COMERCIO ATACADISTA DE" },
  {
    cnae: "46915",
    descricao:
      "COMERCIO ATACADISTA DE MERCADORIAS EM GERAL, COM PREDOMINANCIA DE PRODUTOS ALIMENTICIOS",
  },
  {
    cnae: "4691500",
    descricao:
      "MERCADORIAS EM GERAL, COM PREDOMINANCIA DE PRODUTOS ALIMENTICIOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46923",
    descricao:
      "COMERCIO ATACADISTA DE MERCADORIAS EM GERAL, COM PREDOMINANCIA DE INSUMOS AGROPECUARIOS",
  },
  {
    cnae: "4692300",
    descricao:
      "ARTIGOS AGROPECUARIOS EM GERAL, NO MESMO ESTABELECIMENTO; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4692300",
    descricao: "QUINQUILHARIAS AGRICOLAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4692300",
    descricao:
      "PRODUTOS AGROPECUARIOS EM GERAL, MIX DE; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4692300",
    descricao:
      "MERCADORIAS EM GERAL, COM PREDOMINANCIA DE INSUMOS AGROPECUARIOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "46931",
    descricao:
      "COMERCIO ATACADISTA DE MERCADORIAS EM GERAL, SEM PREDOMINANCIA DE ALIMENTOS OU DE INSUMOS AGROPECUARIOS",
  },
  {
    cnae: "4693100",
    descricao:
      "MERCADORIAS EM GERAL (NAO ESPECIALIZADO) SEM PREDOMINANCIA DE ARTIGOS PARA USO NA AGROPECUARIA; COMERCIO ATACADISTA DE",
  },
  { cnae: "4693100", descricao: "MASSAMES; COMERCIO ATACADISTA DE" },
  {
    cnae: "4693100",
    descricao:
      "MERCADORIAS EM GERAL, SEM PREDOMINANCIA DE ALIMENTOS OU DE INSUMOS AGROPECUARIOS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "4693100",
    descricao:
      "SERIGRAFIA EM GERAL, ARTIGOS DE - EXCETO TINTAS; COMERCIO ATACADISTA DE",
  },
  {
    cnae: "47113",
    descricao:
      "COMERCIO VAREJISTA DE MERCADORIAS EM GERAL, COM PREDOMINANCIA DE PRODUTOS ALIMENTICIOS  HIPERMERCADOS E SUPERMERCADOS",
  },
  { cnae: "4711301", descricao: "HIPERMERCADO" },
  {
    cnae: "4711301",
    descricao:
      "MERCADORIAS EM GERAL, COM PREDOMINANCIA DE PRODUTOS ALIMENTICIOS, COM AREA DE VENDA SUPERIOR A 5000 METROS QUADRADOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4711301",
    descricao:
      "HIPERMERCADO - AREA DE VENDA SUPERIOR A 5000 METROS QUADRADOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4711302",
    descricao:
      "MERCADORIAS EM GERAL, COM PREDOMINANCIA DE PRODUTOS ALIMENTICIOS, COM AREA DE VENDA ENTRE 300 E 5000 METROS QUADRADOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4711302",
    descricao:
      "SUPERMERCADO - AREA DE VENDA DE 300 A 5000 METROS QUADRADOS; COMERCIO VAREJISTA",
  },
  { cnae: "4711302", descricao: "SUPERMERCADO" },
  {
    cnae: "47121",
    descricao:
      "COMERCIO VAREJISTA DE MERCADORIAS EM GERAL, COM PREDOMINANCIA DE PRODUTOS ALIMENTICIOS  MINIMERCADOS, MERCEARIAS E ARMAZENS",
  },
  { cnae: "4712100", descricao: "ARMAZENS VAREJISTAS" },
  { cnae: "4712100", descricao: "EMPORIO; COMERCIO VAREJISTA" },
  { cnae: "4712100", descricao: "MERCEARIA; COMERCIO VAREJISTA" },
  { cnae: "4712100", descricao: "MINI-MARKET; COMERCIO VAREJISTA" },
  { cnae: "4712100", descricao: "ARMAZEM VAREJISTA" },
  { cnae: "4712100", descricao: "MINIMERCADOS" },
  { cnae: "4712100", descricao: "SECOS E MOLHADOS; COMERCIO VAREJISTA" },
  { cnae: "4712100", descricao: "MINI-MERCADO; COMERCIO VAREJISTA" },
  { cnae: "4712100", descricao: "ARMAZEM; COMERCIO VAREJISTA" },
  { cnae: "4712100", descricao: "MINIMERCADO; COMERCIO VAREJISTA" },
  {
    cnae: "47130",
    descricao:
      "COMERCIO VAREJISTA DE MERCADORIAS EM GERAL, SEM PREDOMINANCIA DE PRODUTOS ALIMENTICIOS",
  },
  {
    cnae: "4713002",
    descricao: "LOJAS DE VARIEDADES DE PEQUENO PORTE; COMERCIO VAREJISTA",
  },
  {
    cnae: "4713002",
    descricao: "COMPRA COLETIVA,; COMERCIO VAREJISTA EM SITES DE",
  },
  {
    cnae: "4713002",
    descricao:
      "LOJAS DE VARIEDADES (MIUDEZAS E QUINQUILHARIAS) EXCETO AGRICOLAS E ALIMENTOS; COMERCIO VAREJISTA",
  },
  { cnae: "4713004", descricao: "MAGAZINES; COMERCIO VAREJISTA" },
  { cnae: "4713004", descricao: "LOJA DE DEPARTAMENTOS; COMERCIO VAREJISTA" },
  {
    cnae: "4713005",
    descricao:
      "LOJAS FRANCAS DE AEROPORTOS INTERNACIONAIS, PORTOS OU EM FRONTEIRAS TERRESTRES; COMERCIO VAREJISTA",
  },
  { cnae: "4729699", descricao: "CESTAS DE CAFE DA MANHA; COMERCIO VAREJISTA" },
  {
    cnae: "4713005",
    descricao:
      "DUTY FREE DE AEROPORTOS INTERNACIONAIS, PORTOS OU EM FRONTEIRAS TERRESTRES; COMERCIO VAREJISTA",
  },
  {
    cnae: "4713005",
    descricao:
      "FREE SHOP DE AEROPORTOS INTERNACIONAIS, PORTOS OU EM FRONTEIRAS TERRESTRES; COMERCIO VAREJISTA",
  },
  {
    cnae: "47211",
    descricao:
      "COMERCIO VAREJISTA DE PRODUTOS DE PADARIA, LATICINIO, DOCES, BALAS E SEMELHANTES",
  },
  {
    cnae: "4721102",
    descricao: "PADARIA SEM VENDA PREDOMINANTE DE PRODUCAO PROPRIA",
  },
  {
    cnae: "4721102",
    descricao:
      "PADARIA E CONFEITARIA COM PREDOMINANCIA DE REVENDA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4721102",
    descricao:
      "PANIFICADORA SEM VENDA PREDOMINANTE DE PRODUCAO PROPRIA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4721102",
    descricao:
      "PRODUTOS DE CONFEITARIA COM PREDOMINANCIA DE REVENDA; COMERCIO VAREJISTA",
  },
  { cnae: "4721102", descricao: "PRODUTOS DE PADARIA; COMERCIO VAREJISTA" },
  { cnae: "4721102", descricao: "PAO; COMERCIO VAREJISTA DE" },
  { cnae: "4721102", descricao: "PAES, BOLOS, TORTAS; COMERCIO VAREJISTA" },
  { cnae: "4721103", descricao: "MANTEIGA, MARGARINA; COMERCIO VAREJISTA" },
  { cnae: "4721103", descricao: "LEITERIA; COMERCIO VAREJISTA" },
  { cnae: "4721103", descricao: "DOCE DE LEITE; COMERCIO VAREJISTA DE" },
  { cnae: "4721103", descricao: "LEITE; COMERCIO VAREJISTA" },
  { cnae: "4721103", descricao: "QUEIJO; COMERCIO VAREJISTA DE" },
  { cnae: "4721103", descricao: "CARNES CONSERVADAS; COMERCIO VAREJISTA DE" },
  { cnae: "4721103", descricao: "PRODUTOS DE LATICINIO; COMERCIO VAREJISTA" },
  {
    cnae: "4721103",
    descricao: "CONSERVAS DE FRUTAS, LEGUMES, VERDURAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4721103",
    descricao:
      "DELICATESSEN ESPECIALIZADA EM FRIOS E LATICINIOS; COMERCIO VAREJISTA",
  },
  { cnae: "4721103", descricao: "FRIOS E CONSERVAS; COMERCIO VAREJISTA" },
  {
    cnae: "4721103",
    descricao: "PRODUTOS DERIVADOS DO LEITE; COMERCIO VAREJISTA",
  },
  { cnae: "4721104", descricao: "DOCES; COMERCIO VAREJISTA DE" },
  { cnae: "4721104", descricao: "CHOCOLATES; COMERCIO VAREJISTA" },
  {
    cnae: "4721104",
    descricao: "BALAS, BOMBONS E SEMELHANTES; COMERCIO VAREJISTA",
  },
  { cnae: "4721104", descricao: "BOMBONIERE; COMERCIO VAREJISTA" },
  {
    cnae: "4721104",
    descricao: "BALAS, CONFEITOS E SEMELHANTES; COMERCIO VAREJISTA",
  },
  {
    cnae: "4721104",
    descricao: "CHOCOLATES, BOMBONS E SEMELHANTES; COMERCIO VAREJISTA",
  },
  {
    cnae: "47229",
    descricao: "COMERCIO VAREJISTA DE CARNES E PESCADOS  ACOUGUES E PEIXARIAS",
  },
  { cnae: "4722901", descricao: "CARNE BOVINA; COMERCIO VAREJISTA" },
  {
    cnae: "4722901",
    descricao: "CARNES FRESCAS, FRIGORIFICADAS, CONGELADAS; COMERCIO VAREJISTA",
  },
  { cnae: "4722901", descricao: "FRANGO ABATIDO; COMERCIO VAREJISTA" },
  { cnae: "4722901", descricao: "CASA DE CARNES; COMERCIO VAREJISTA" },
  {
    cnae: "4722901",
    descricao:
      "PEQUENOS ANIMAIS ABATIDOS (COELHOS (LEBRES), PATOS, PERUS, GALINHAS, ETC.); COMERCIO VAREJISTA",
  },
  { cnae: "4722901", descricao: "CARNE SUINA; COMERCIO VAREJISTA" },
  {
    cnae: "4722901",
    descricao: "CARNE DE CAPRINO, OVINO, EQUIDEO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4722901",
    descricao:
      "AVES ABATIDAS FRESCAS, CONGELADAS, FRIGORIFICADAS; COMERCIO VAREJISTA",
  },
  { cnae: "4722901", descricao: "ACOUGUE; COMERCIO VAREJISTA" },
  {
    cnae: "4722901",
    descricao:
      "ABATEDOURO DE ANIMAIS ASSOCIADO AO COMERCIO; COMERCIO VAREJISTA",
  },
  { cnae: "4722901", descricao: "MIUDOS, VISCERAS; COMERCIO VAREJISTA" },
  {
    cnae: "4722901",
    descricao: "ABATE DE ANIMAIS ASSOCIADO AO COMERCIO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4722902",
    descricao: "PESCADOS E FRUTOS DO MAR; COMERCIO VAREJISTA",
  },
  { cnae: "4722902", descricao: "PEIXARIA; COMERCIO VAREJISTA" },
  { cnae: "4722902", descricao: "PEIXES PARA ALIMENTACAO; COMERCIO VAREJISTA" },
  {
    cnae: "4722902",
    descricao:
      "PEIXES, CRUSTACEOS E MOLUSCOS FRESCOS, FRIGORIFICADOS E CONGELADOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4722902",
    descricao: "CAMARAO FRESCO, FRIGORIFICADO E CONGELADO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4722902",
    descricao:
      "PESCADOS FRESCOS, CONGELADOS, FRIGORIFICADOS; COMERCIO VAREJISTA",
  },
  { cnae: "47237", descricao: "COMERCIO VAREJISTA DE BEBIDAS" },
  {
    cnae: "4723700",
    descricao: "VINHOS, SEM ATIVIDADE DE SERVIR; COMERCIO VAREJISTA DE",
  },
  { cnae: "4723700", descricao: "DEPOSITO DE BEBIDAS; COMERCIO VAREJISTA" },
  { cnae: "4723700", descricao: "SAQUE; COMERCIO VAREJISTA" },
  {
    cnae: "4723700",
    descricao: "AGUA MINERAL, DEPOSITO DE; COMERCIO VAREJISTA",
  },
  {
    cnae: "4723700",
    descricao: "BEBIDAS ALCOOLICAS E NAO ALCOOLICAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4723700",
    descricao: "DISTRIBUIDORA DE BEBIDAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4723700",
    descricao: "CERVEJAS, SEM ATIVIDADE DE SERVIR; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4723700",
    descricao: "REFRIGERANTES, SEM ATIVIDADE DE SERVIR; COMERCIO VAREJISTA DE",
  },
  { cnae: "47245", descricao: "COMERCIO VAREJISTA DE HORTIFRUTIGRANJEIROS" },
  { cnae: "4724500", descricao: "SACOLAO; COMERCIO VAREJISTA" },
  { cnae: "4724500", descricao: "HORTALICAS; COMERCIO VAREJISTA" },
  { cnae: "4724500", descricao: "VERDURAS E FRUTAS; COMERCIO VAREJISTA" },
  {
    cnae: "4724500",
    descricao: "LEGUMES, RAIZES, TUBERCULOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4724500",
    descricao: "AVES VIVAS PARA ALIMENTACAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4724500",
    descricao: "QUITANDA ESPECIALIZADO EM FRUTAS E LEGUMES; COMERCIO VAREJISTA",
  },
  { cnae: "4724500", descricao: "HORTIGRANJEIROS; COMERCIO VAREJISTA" },
  { cnae: "4724500", descricao: "VERDUREIRO; COMERCIO VAREJISTA" },
  { cnae: "4724500", descricao: "FRUTAS; COMERCIO VAREJISTA" },
  {
    cnae: "4724500",
    descricao: "AVIARIO (EXCETO PASSARINHOS), AVES VIVAS; COMERCIO VAREJISTA",
  },
  { cnae: "4724500", descricao: "FRUTARIA; COMERCIO VAREJISTA" },
  {
    cnae: "4724500",
    descricao:
      "COELHOS E OUTROS PEQUENOS ANIMAIS PARA ALIMENTACAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4724500",
    descricao: "PEQUENOS ANIMAIS VIVOS PARA ALIMENTACAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4724500",
    descricao: "GALINHAS PARA ALIMENTACAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4724500",
    descricao: "FRANGOS VIVOS PARA ALIMENTACAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "47296",
    descricao:
      "COMERCIO VAREJISTA DE PRODUTOS ALIMENTICIOS EM GERAL OU ESPECIALIZADO EM PRODUTOS ALIMENTICIOS NAO ESPECIFICADOS ANTERIORMENTE, PRODUTOS DO FUMO",
  },
  { cnae: "4729601", descricao: "CHARUTARIA; COMERCIO VAREJISTA" },
  { cnae: "4729601", descricao: "ARTIGOS DE TABACARIA; COMERCIO VAREJISTA" },
  { cnae: "4729601", descricao: "FUMO EM ROLO E EM CORDA; COMERCIO VAREJISTA" },
  { cnae: "4729601", descricao: "PRODUTOS DE TABACARIA; COMERCIO VAREJISTA" },
  {
    cnae: "4729601",
    descricao:
      "ISQUEIROS, CACHIMBOS, PITEIRAS, CIGARRILHAS; COMERCIO VEREJISTA",
  },
  { cnae: "4729601", descricao: "CIGARRO; COMERCIO VAREJISTA" },
  {
    cnae: "4729601",
    descricao: "CHARUTOS, PITEIRAS, CACHIMBOS; COMERCIO VAREJISTA",
  },
  { cnae: "4729601", descricao: "TABACARIA; COMERCIO VAREJISTA" },
  { cnae: "4729602", descricao: "LOJAS DE CONVENIENCIA, COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "CERA DE ABELHA; COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "POLPA DE FRUTAS; COMERCIO VAREJISTA" },
  {
    cnae: "4729699",
    descricao: "CAFE EM GRAO, TORRADO OU MOIDO; COMERCIO VAREJISTA",
  },
  { cnae: "4729699", descricao: "DELICATESSEN; LOJAS DE" },
  { cnae: "4729699", descricao: "SAL DE COZINHA; COMERCIO VAREJISTA" },
  {
    cnae: "4729699",
    descricao: "CONDIMENTOS E ESPECIARIAS; COMERCIO VAREJISTA",
  },
  { cnae: "4729699", descricao: "BATATA FRITA; COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "PRODUTOS NATURAIS; COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "OVOS; COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "MASSAS ALIMENTICIAS; COMERCIO VAREJISTA" },
  {
    cnae: "4729699",
    descricao: "SUPLEMENTOS ALIMENTICIOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4729699",
    descricao: "PRODUTOS ALIMENTICIOS EM GERAL; COMERCIO VAREJISTA",
  },
  { cnae: "4729699", descricao: "CAFE MOIDO; COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "ERVA MATE BENEFICIADA; COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "COMIDAS CONGELADAS; COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "OLEO COMESTIVEL; COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "GELO; COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "CEREAIS; COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "GORDURA COMESTIVEL; COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "TORTAS GELADAS; COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "MEL; COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "OLEO VEGETAL; COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "PRODUTOS DIETETICOS; COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "PICOLES; COMERCIO VAREJISTA" },
  { cnae: "4729699", descricao: "FERMENTOS; COMERCIO VAREJISTA" },
  {
    cnae: "47318",
    descricao: "COMERCIO VAREJISTA DE COMBUSTIVEIS PARA VEICULOS AUTOMOTORES",
  },
  {
    cnae: "4731800",
    descricao: "GAS NATURAL DE PETROLEO PARA VEICULOS; COMERCIO VAREJISTA",
  },
  { cnae: "4731800", descricao: "ALCOOL CARBURANTE; COMERCIO VAREJISTA" },
  { cnae: "4731800", descricao: "GNV; COMERCIO VAREJISTA" },
  {
    cnae: "4731800",
    descricao: "OLEO DIESEL PARA VEICULOS; COMERCIO VAREJISTA",
  },
  { cnae: "4731800", descricao: "POSTO DE COMBUSTIVEL" },
  {
    cnae: "4731800",
    descricao:
      "COMBUSTIVEIS NATURAL DE PETROLEO PARA VEICULOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4731800",
    descricao:
      "COMBUSTIVEIS E LUBRIFICANTES PARA BARCOS DE PEQUENO PORTE; COMERCIO VAREJISTA",
  },
  { cnae: "4731800", descricao: "DERIVADOS DO PETROLEO; COMERCIO VAREJISTA" },
  {
    cnae: "4731800",
    descricao: "COMBUSTIVEIS PARA BARCOS DE PEQUENO PORTE; COMERCIO VAREJISTA",
  },
  { cnae: "4731800", descricao: "POSTO DE ABASTECIMENTO DE VEICULOS" },
  { cnae: "4731800", descricao: "POSTO DE GASOLINA" },
  { cnae: "4731800", descricao: "AUTO POSTO" },
  {
    cnae: "4731800",
    descricao: "COMBUSTIVEIS E LUBRIFICANTES PARA VEICULOS; COMERCIO VAREJISTA",
  },
  { cnae: "4731800", descricao: "GASOLINA PARA VEICULOS; COMERCIO VAREJISTA" },
  { cnae: "4731800", descricao: "GAS NATURAL VEICULAR; COMERCIO VAREJISTA" },
  {
    cnae: "4731800",
    descricao: "GAS NATURAL PARA VEICULOS; COMERCIO VAREJISTA",
  },
  { cnae: "47326", descricao: "COMERCIO VAREJISTA DE LUBRIFICANTES" },
  {
    cnae: "4732600",
    descricao: "LUBRIFICANTES PARA VEICULOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4732600",
    descricao: "LUBRIFICANTES PARA USOS DIVERSOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4732600",
    descricao: "LUBRIFICANTES PARA USO AUTOMOTIVO; COMERCIO VAREJISTA",
  },
  {
    cnae: "47415",
    descricao: "COMERCIO VAREJISTA DE TINTAS E MATERIAIS PARA PINTURA",
  },
  { cnae: "4741500", descricao: "IMPERMEABILIZANTES; COMERCIO VAREJISTA" },
  { cnae: "4741500", descricao: "LOJA DE TINTAS; COMERCIO VAREJISTA" },
  {
    cnae: "4741500",
    descricao: "MASSA PARA PINTURA DE PREDIOS; COMERCIO VAREJISTA",
  },
  { cnae: "4741500", descricao: "MATERIAL PARA PINTURA; COMERCIO VAREJISTA" },
  { cnae: "4741500", descricao: "TINTAS; COMERCIO VAREJISTA" },
  { cnae: "4741500", descricao: "TINERES PARA TINTA; COMERCIO VAREJISTA" },
  { cnae: "4741500", descricao: "PINCEIS, ROLOS, BROCHAS; COMERCIO VAREJISTA" },
  { cnae: "4741500", descricao: "SOLVENTES PARA TINTA; COMERCIO VAREJISTA" },
  { cnae: "4741500", descricao: "TINTAS AUTOMOTIVAS; COMERCIO VAREJISTA" },
  { cnae: "4741500", descricao: "TEXTURAS E GRAFIATOS; COMERCIO VAREJISTA DE" },
  { cnae: "47423", descricao: "COMERCIO VAREJISTA DE MATERIAL ELETRICO" },
  { cnae: "4742300", descricao: "LAMPADAS; COMERCIO VAREJISTA" },
  {
    cnae: "4742300",
    descricao: "MATERIAL ELETRICO PARA CONSTRUCAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4742300",
    descricao: "CHAVES ELETRICAS, INTERRUPTORES, TOMADAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4742300",
    descricao:
      "FIOS, CABOS E CONDUTORES ELETRICOS PARA CONSTRUCAO; COMERCIO VAREJISTA",
  },
  { cnae: "4742300", descricao: "CHUVEIROS ELETRICOS, COMERCIO VAREJISTA" },
  { cnae: "47431", descricao: "COMERCIO VAREJISTA DE VIDROS" },
  { cnae: "4743100", descricao: "VIDROS PARA BOXES; COMERCIO VAREJISTA DE" },
  { cnae: "4743100", descricao: "VIDROS PARA CONSTRUCAO; COMERCIO VAREJISTA" },
  {
    cnae: "4743100",
    descricao: "VIDROS PLANOS E TEMPERADOS PARA CONSTRUCAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4743100",
    descricao: "VIDRACARIA, EXCETO DE VEICULOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4743100",
    descricao: "ESPELHOS E VITRAIS PARA CONSTRUCAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4743100",
    descricao: "VIDROS PLANOS E DE SEGURANCA; COMERCIO VAREJISTA",
  },
  {
    cnae: "47440",
    descricao:
      "COMERCIO VAREJISTA DE FERRAGENS, MADEIRA E MATERIAIS DE CONSTRUCAO",
  },
  {
    cnae: "4744001",
    descricao: "FERRAMENTAS MANUAIS ELETRICAS; COMERCIO VAREJISTA",
  },
  { cnae: "4744001", descricao: "FECHADURAS, DOBRADICAS; COMERCIO VAREJISTA" },
  { cnae: "4744001", descricao: "ALUMINIO, CHAPAS DE; COMERCIO VAREJISTA" },
  {
    cnae: "4744001",
    descricao: "ARAMES, PREGOS, PARAFUSOS; COMERCIO VAREJISTA",
  },
  { cnae: "4744001", descricao: "MARTELO, PICARETAS; COMERCIO VAREJISTA" },
  { cnae: "4744001", descricao: "METAIS SANITARIOS; COMERCIO VAREJISTA" },
  {
    cnae: "4744001",
    descricao: "FERRAMENTAS MANUAIS NAO-ELETRICAS; COMERCIO VAREJISTA",
  },
  { cnae: "4744001", descricao: "PRODUTOS METALURGICOS; COMERCIO VAREJISTA" },
  { cnae: "4744001", descricao: "TELAS DE ARAME; COMERCIO VAREJISTA" },
  { cnae: "4744001", descricao: "ACO PARA CONSTRUCAO; COMERCIO VAREJISTA" },
  { cnae: "4744001", descricao: "FERRAMENTAS MANUAIS; COMERCIO VAREJISTA" },
  { cnae: "4744001", descricao: "SERROTES, SERRAS; COMERCIO VAREJISTA" },
  { cnae: "4744001", descricao: "FERRAGENS; COMERCIO VAREJISTA" },
  { cnae: "4744001", descricao: "FERRO PARA CONSTRUCAO; COMERCIO VAREJISTA" },
  { cnae: "4744001", descricao: "CADEADOS; COMERCIO VAREJISTA" },
  {
    cnae: "4744001",
    descricao: "FURADEIRAS, SERRAS ELETRICAS; COMERCIO VAREJISTA",
  },
  { cnae: "4744002", descricao: "MADEIRA SERRADA; COMERCIO VAREJISTA" },
  { cnae: "4744002", descricao: "MADEIRA PERFILADA; COMERCIO VAREJISTA DE" },
  {
    cnae: "4744002",
    descricao: "TACOS DE MADEIRA PARA PISOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4744002",
    descricao:
      "PRODUTOS DERIVADOS DA MADEIRA (TABUAS, RIPAS, VIGAS, PRANCHAS, DORMENTES, BARROTE, CAIBRO E SIMILARES); COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4744002",
    descricao: "PORTAS E JANELAS DE MADEIRA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4744002",
    descricao: "BRIQUETE (SERRAGEM COMPACTADA); COMERCIO VAREJISTA",
  },
  { cnae: "4744002", descricao: "PORTAS DE MADEIRA; COMERCIO VAREJISTA" },
  {
    cnae: "4744002",
    descricao: "MADEIRA FOLHEADA, PRENSADA E COMPENSADA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4744002",
    descricao: "PRE-MOLDADOS DE MADEIRA PARA CONSTRUCAO; COMERCIO VAREJISTA DE",
  },
  { cnae: "4744002", descricao: "ESQUADRIAS DE MADEIRA; COMERCIO VAREJISTA" },
  {
    cnae: "4744002",
    descricao: "MADEIRAS PARA CONSTRUCAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4744003",
    descricao: "VALVULAS, TORNEIRAS E REGISTROS; COMERCIO VAREJISTA",
  },
  { cnae: "4744003", descricao: "MATERIAL HIDRAULICO; COMERCIO VAREJISTA" },
  {
    cnae: "4744003",
    descricao: "CANOS, TUBOS, CONEXOES; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4744004",
    descricao: "TELHAS (DE QUALQUER MATERIAL); COMERCIO VAREJISTA DE",
  },
  { cnae: "4744004", descricao: "PEDRA BRITADA; COMERCIO VAREJISTA" },
  { cnae: "4744004", descricao: "AREIA; COMERCIO VAREJISTA" },
  {
    cnae: "4744004",
    descricao: "TELHAS DE MATERIAL CERAMICO; COMERCIO VAREJISTA",
  },
  { cnae: "4744004", descricao: "CAL PARA CONSTRUCAO; COMERCIO VAREJISTA DE" },
  { cnae: "4759801", descricao: "PERSIANAS; COMERCIO VAREJISTA" },
  {
    cnae: "4744004",
    descricao: "TIJOLOS DE MATERIAL CERAMICO; COMERCIO VAREJISTA",
  },
  { cnae: "4744005", descricao: "CAIXAS DE AGUA; COMERCIO VAREJISTA" },
  {
    cnae: "4744005",
    descricao: "ARTIGOS E EQUIPAMENTOS PARA SAUNAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4744005",
    descricao: "ESQUADRIAS DE PLASTICO; COMERCIO VAREJISTA DE",
  },
  { cnae: "4744005", descricao: "PORTAS SANFONADAS; COMERCIO VAREJISTA" },
  {
    cnae: "4744005",
    descricao: "PISOS E REVESTIMENTOS PARA CONSTRUCAO; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4744005",
    descricao: "DIVISORIAS E PORTAS SANFONADAS; COMERCIO VAREJISTA",
  },
  { cnae: "4744005", descricao: "JANELAS METALICAS; COMERCIO VAREJISTA" },
  {
    cnae: "4744005",
    descricao:
      "PELICULAS DE POLIESTER (INSULFILM) PARA REVESTIMENTO DE VIDROS RESIDENCIAIS E COMERCIAIS; COMERCIO VAREJISTA",
  },
  { cnae: "4744005", descricao: "GESSO PARA CONSTRUCAO; COMERCIO VAREJISTA" },
  { cnae: "4744005", descricao: "PORTOES ELETRONICOS; COMERCIO VAREJISTA" },
  {
    cnae: "4744005",
    descricao: "ARTEFATOS DE CIMENTO; GESSO E AMIANTO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4744005",
    descricao: "VERGALHOES E ARMACOES METALICAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4744005",
    descricao: "PIAS, LAVATORIOS, BANHEIRAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4744005",
    descricao:
      "PISOS E REVESTIMENTOS CERAMICOS PARA CONSTRUCAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4744005",
    descricao:
      "PISCINAS E EQUIPAMENTOS PARA SUA INSTALACAO; COMERCIO VAREJISTA",
  },
  { cnae: "4744005", descricao: "BOX PARA BANHEIRO; COMERCIO VAREJISTA" },
  { cnae: "4744005", descricao: "AZULEJOS, CERAMICAS; COMERCIO VAREJISTA" },
  { cnae: "4744005", descricao: "PORTAS METALICAS; COMERCIO VAREJISTA" },
  { cnae: "4744005", descricao: "BOMBAS HIDRAULICAS; COMERCIO VAREJISTA" },
  { cnae: "4744005", descricao: "CALHAS PARA CONSTRUCAO; COMERCIO VAREJISTA" },
  { cnae: "4744005", descricao: "FORRO PARA CONSTRUCAO; COMERCIO VAREJISTA" },
  { cnae: "4744005", descricao: "PORTAS ELETRONICAS; COMERCIO VAREJISTA" },
  { cnae: "4744005", descricao: "ESQUADRIAS METALICAS; COMERCIO VAREJISTA" },
  { cnae: "4744005", descricao: "BOMBAS DE AGUA; COMERCIO VAREJISTA" },
  { cnae: "4744005", descricao: "RODAPES DE CERAMICA; COMERCIO VAREJISTA" },
  { cnae: "4744005", descricao: "CIMENTO; COMERCIO VAREJISTA" },
  {
    cnae: "4744006",
    descricao:
      "PEDRAS DECORATIVAS (ARDOSIA, SAO TOME, GRANITO); COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4744006",
    descricao: "PEDRAS DECORATIVAS PARA REVESTIMENTO; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4744006",
    descricao: "MARMORES E GRANITOS PARA CONSTRUCAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4744006",
    descricao: "PEDRAS DECORATIVAS PARA CONSTRUCAO; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4744099",
    descricao:
      "MATERIAL DE CONSTRUCAO EM GERAL (NO MESMO ESTABELECIMENTO); COMERCIO VAREJISTA",
  },
  {
    cnae: "4744099",
    descricao:
      "VIGAS E VIGOTAS PRE-MOLDADAS DE CONCRETO; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4744099",
    descricao: "COMERCIO VAREJISTA DE MATERIAIS DE CONSTRUCAO EM GERAL",
  },
  {
    cnae: "47512",
    descricao:
      "COMERCIO VAREJISTA ESPECIALIZADO DE EQUIPAMENTOS E SUPRIMENTOS DE INFORMATICA",
  },
  { cnae: "4751201", descricao: "CARTOES MEMORIA; COMERCIO VAREJISTA" },
  {
    cnae: "4751201",
    descricao: "SUPRIMENTOS PARA MICROCOMPUTADORES; COMERCIO VAREJISTA",
  },
  {
    cnae: "4751201",
    descricao:
      "FORMULARIOS CONTINUOS PARA INFORMATICA, EXCETO PRE-IMPRESSOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4751201",
    descricao: "SUPRIMENTOS PARA INFORMATICA; COMERCIO VAREJISTA",
  },
  { cnae: "4751201", descricao: "MICROCOMPUTADORES; COMERCIO VAREJISTA" },
  {
    cnae: "4751201",
    descricao:
      "PROGRAMAS DE COMPUTADOR NAO-COSTUMIZAVEIS; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4751201",
    descricao:
      "ASSESSORIA EM INFORMATICA ASSOCIADO A VENDA DE COMPUTADORES E PERIFERICOS",
  },
  {
    cnae: "4751201",
    descricao: "APLICATIVOS INFORMATICOS, COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4751201",
    descricao: "PERIFERICOS PARA INFORMATICA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4751201",
    descricao: "IMPRESSORAS PARA COMPUTADORES; COMERCIO VAREJISTA",
  },
  {
    cnae: "4751201",
    descricao: "TECLADOS PARA COMPUTADORES; COMERCIO VAREJISTA",
  },
  {
    cnae: "4751201",
    descricao:
      "MIDIAS (VIRGENS) PARA GRAVACAO E REPRODUCAO DE ARQUIVOS ELETRONICOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4751201",
    descricao: "EQUIPAMENTOS DE INFORMATICA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4751201",
    descricao: "MICROCOMPUTADORES E PERIFERICOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4751201",
    descricao: "DRIVES, PEN-DRIVES, MOUSE; COMERCIO VAREJISTA",
  },
  { cnae: "4751201", descricao: "SOFTWARES; COMERCIO VAREJISTA" },
  {
    cnae: "4751201",
    descricao: "PLACAS PARA COMPUTADORES; COMERCIO VAREJISTA",
  },
  {
    cnae: "4751201",
    descricao:
      "ACESSORIOS PARA EQUIPAMENTOS DE INFORMATICA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4751201",
    descricao: "CARTUCHOS NOVOS PARA IMPRESSORAS; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4751201",
    descricao:
      "PECAS E ACESSORIOS PARA EQUIPAMENTOS DE INFORMATICA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4751201",
    descricao: "COMPUTADORES DE PEQUENO PORTE; COMERCIO VAREJISTA",
  },
  { cnae: "4751201", descricao: "WINCHESTER; COMERCIO VAREJISTA" },
  {
    cnae: "4751201",
    descricao: "SUPRIMENTOS PARA COMPUTADORES; COMERCIO VAREJISTA",
  },
  { cnae: "4751201", descricao: "MONITORES DE VIDEO; COMERCIO VAREJISTA" },
  {
    cnae: "4751202",
    descricao:
      "RECARGA DE CARTUCHOS PARA EQUIPAMENTOS DE INFORMATICA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4751202",
    descricao: "RECARGA DE CARTUCHOS PARA IMPRESSORAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4751202",
    descricao: "RECARGA DE CARTUCHOS PARA COMPUTADORES; COMERCIO VAREJISTA",
  },
  {
    cnae: "47521",
    descricao:
      "COMERCIO VAREJISTA ESPECIALIZADO DE EQUIPAMENTOS DE TELEFONIA E COMUNICACAO",
  },
  {
    cnae: "4752100",
    descricao: "INTERCOMUNICADORES, INTERFONES; COMERCIO VAREJISTA",
  },
  {
    cnae: "4752100",
    descricao: "APARELHOS TELEFONICOS, INCLUSIVE CELULAR; COMERCIO VAREJISTA",
  },
  { cnae: "4752100", descricao: "RADIOCOMUNICADORES; COMERCIO VAREJISTA" },
  { cnae: "4752100", descricao: "FAX; COMERCIO VAREJISTA" },
  {
    cnae: "4752100",
    descricao: "MAQUINAS PARA COMUNICACAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4752100",
    descricao: "EQUIPAMENTOS PARA COMUNICACAO; COMERCIO VAREJISTA",
  },
  { cnae: "4752100", descricao: "SECRETARIAS ELETRONICAS; COMERCIO VAREJISTA" },
  {
    cnae: "4752100",
    descricao: "MATERIAIS PARA COMUNICACAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4752100",
    descricao: "EQUIPAMENTOS DE COMUNICACAO PESSOAL; COMERCIO VAREJISTA",
  },
  {
    cnae: "4752100",
    descricao: "PECAS E ACESSORIOS PARA TELEFONES; COMERCIO VAREJISTA",
  },
  {
    cnae: "4752100",
    descricao: "EQUIPAMENTOS PARA RADIOCOMUNICADORES; COMERCIO VAREJISTA",
  },
  {
    cnae: "4752100",
    descricao:
      "PECAS E ACESSORIOS PARA EQUIPAMENTOS DE COMUNICACAO; COMERCIO VAREJISTA",
  },
  { cnae: "4752100", descricao: "TELEFONES; COMERCIO VAREJISTA" },
  { cnae: "4752100", descricao: "SECRETARIA ELETRONICA; COMERCIO VAREJISTA" },
  {
    cnae: "4752100",
    descricao:
      "PECAS E ACESSORIOS PARA EQUIPAMENTOS DE TELECOMUNICACAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4752100",
    descricao:
      "PECAS E ACESSORIOS PARA EQUIPAMENTOS RADIOCOMUNICADORES; COMERCIO VAREJISTA",
  },
  {
    cnae: "4752100",
    descricao: "EQUIPAMENTOS DE TELECOMUNICACAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "47539",
    descricao:
      "COMERCIO VAREJISTA ESPECIALIZADO DE ELETRODOMESTICOS E EQUIPAMENTOS DE AUDIO E VIDEO",
  },
  { cnae: "4753900", descricao: "FILMADORAS; COMERCIO VAREJISTA DE" },
  { cnae: "4753900", descricao: "AMPLIFICADORES DE SOM; COMERCIO VAREJISTA" },
  {
    cnae: "4753900",
    descricao: "LENTES OBJETIVAS PARA USO FOTOGRAFICO; COMERCIO VAREJISTA",
  },
  { cnae: "4753900", descricao: "APARELHOS DE BLU-RAY; COMERCIO VAREJISTA DE" },
  { cnae: "4753900", descricao: "TELAS PARA PROJECAO; COMERCIO VAREJISTA" },
  { cnae: "4759801", descricao: "CARPETES; COMERCIO VAREJISTA" },
  {
    cnae: "4753900",
    descricao:
      "APARELHO DE VIDEO, EXCETO MONITOR DE MICROCOMPUTADOR; COMERCIO VAREJISTA",
  },
  { cnae: "4753900", descricao: "APARELHOS DE DVD; COMERCIO VAREJISTA DE" },
  {
    cnae: "4753900",
    descricao: "PURIFICADORES DE AGUA ELETRICOS; COMERCIO VAREJISTA",
  },
  { cnae: "4753900", descricao: "CAIXAS ACUSTICAS; COMERCIO VAREJISTA" },
  {
    cnae: "4753900",
    descricao:
      "APARELHOS ELETROELETRONICOS DOMESTICOS OU PESSOAIS; COMERCIO VAREJISTA",
  },
  { cnae: "4753900", descricao: "FORNO DE MICROONDAS; COMERCIO VAREJISTA" },
  {
    cnae: "4753900",
    descricao: "APARELHOS DE USO DOMESTICO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4753900",
    descricao: "EQUIPAMENTOS ELETRONICOS DOMESTICOS; COMERCIO VAREJISTA",
  },
  { cnae: "4753900", descricao: "APARELHOS DE TELEVISAO; COMERCIO VAREJISTA" },
  {
    cnae: "4753900",
    descricao: "MAQUINA DE LAVAR E SECAR DOMESTICAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4753900",
    descricao: "APARELHO DE AR CONDICIONADO DOMESTICO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4753900",
    descricao: "REFRIGERADORES DOMESTICOS; COMERCIO VAREJISTA",
  },
  { cnae: "4753900", descricao: "APARELHO DE SOM; COMERCIO VAREJISTA" },
  {
    cnae: "4753900",
    descricao: "MAQUINA DE TRICO DOMESTICA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4753900",
    descricao: "MAQUINA DE COSTURA DOMESTICA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4753900",
    descricao: "MAQUINAS DE USO DOMESTICO; COMERCIO VAREJISTA",
  },
  { cnae: "4753900", descricao: "MAQUINAS FOTOGRAFICAS; COMERCIO VAREJISTA" },
  { cnae: "4753900", descricao: "ELETRODOMESTICOS; COMERCIO VAREJISTA" },
  {
    cnae: "4753900",
    descricao: "ASPIRADOR DE PO DOMESTICO; COMERCIO VAREJISTA",
  },
  { cnae: "4753900", descricao: "GRAVADORES; COMERCIO VAREJISTA" },
  {
    cnae: "4753900",
    descricao: "OZONIZADORES DE AGUA ELETRICOS; COMERCIO VAREJISTA",
  },
  { cnae: "4753900", descricao: "GELADEIRA; COMERCIO VAREJISTA" },
  {
    cnae: "4753900",
    descricao: "APARELHO DE AR REFRIGERADO DOMESTICO; COMERCIO VAREJISTA",
  },
  { cnae: "4753900", descricao: "PROJETORES DE SLIDES; COMERCIO VAREJISTA" },
  { cnae: "4753900", descricao: "APARELHOS DE RADIO; COMERCIO VAREJISTA DE" },
  { cnae: "4753900", descricao: "VIDEOCASSETE; COMERCIO VAREJISTA" },
  {
    cnae: "4753900",
    descricao: "CAMERAS, CAMARAS FOTOGRAFICAS; COMERCIO VAREJISTA",
  },
  { cnae: "4753900", descricao: "FOTO, CINE E SOM; COMERCIO VAREJISTA" },
  { cnae: "4753900", descricao: "TELEVISOES; COMERCIO VAREJISTA" },
  { cnae: "4753900", descricao: "VENTILADOR; COMERCIO VAREJISTA" },
  { cnae: "4753900", descricao: "AQUECEDOR ELETRICO; COMERCIO VAREJISTA" },
  {
    cnae: "4753900",
    descricao:
      "APARELHOS ELETRONICOS DOMESTICOS OU PESSOAIS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4753900",
    descricao: "LOJA DE ELETRODOMESTICOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4753900",
    descricao: "OZONIZADORES, PURIFICADORES DE AGUA; COMERCIO VAREJISTA",
  },
  { cnae: "4753900", descricao: "FOGAO; COMERCIO VAREJISTA" },
  { cnae: "4753900", descricao: "ENCERADEIRAS; COMERCIO VAREJISTA" },
  {
    cnae: "4753900",
    descricao:
      "APARELHOS DE REPRODUCAO DE MIDIA (AUDIO E VIDEO), EXCETO PERIFERICOS DE MICROCOMPUTADOR; COMERCIO VAREJISTA",
  },
  {
    cnae: "47547",
    descricao:
      "COMERCIO VAREJISTA ESPECIALIZADO DE MOVEIS, COLCHOARIA E ARTIGOS DE ILUMINACAO",
  },
  { cnae: "4754701", descricao: "MESAS E CADEIRAS; COMERCIO VAREJISTA" },
  { cnae: "4754701", descricao: "SOFAS; COMERCIO VAREJISTA" },
  { cnae: "4754701", descricao: "POLTRONAS; COMERCIO VAREJISTA" },
  { cnae: "4754701", descricao: "MOVEIS ESTOFADOS; COMERCIO VAREJISTA" },
  { cnae: "4754701", descricao: "LOJA DE MOVEIS NOVOS; COMERCIO VAREJISTA" },
  {
    cnae: "4754701",
    descricao: "MOVEIS NOVOS PARA ESCRITORIO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4754701",
    descricao: "MOVEIS NOVOS DE QUALQUER MATERIAL; COMERCIO VAREJISTA",
  },
  {
    cnae: "4754701",
    descricao: "MOVEIS NOVOS PARA RESIDENCIAS; COMERCIO VAREJISTA",
  },
  { cnae: "4754701", descricao: "CADEIRAS; COMERCIO VAREJISTA" },
  {
    cnae: "4754701",
    descricao:
      "ESTOFADOS (EXCETO PARA AUTOMOVEIS), SOFAS E POLTRONAS; COMERCIO VAREJISTA",
  },
  { cnae: "4754701", descricao: "CAMAS; COMERCIO VAREJISTA DE" },
  { cnae: "4754702", descricao: "COLCHOES, TRAVESSEIROS; COMERCIO VAREJISTA" },
  { cnae: "4754702", descricao: "ARTIGOS DE COLCHOARIA; COMERCIO VAREJISTA" },
  { cnae: "4754703", descricao: "ARTIGOS DE ILUMINACAO; COMERCIO VAREJISTA" },
  { cnae: "4754703", descricao: "LUMINARIAS; COMERCIO VAREJISTA" },
  { cnae: "4754703", descricao: "LUSTRES; COMERCIO VAREJISTA" },
  { cnae: "4754703", descricao: "ABAJURES; COMERCIO VAREJISTA" },
  {
    cnae: "47555",
    descricao:
      "COMERCIO VAREJISTA ESPECIALIZADO DE TECIDOS E ARTIGOS DE CAMA, MESA E BANHO",
  },
  {
    cnae: "4755501",
    descricao: "TECIDOS DE MALHA PARA CONFECCAO; COMERCIO VAREJISTA",
  },
  { cnae: "4755501", descricao: "TECIDOS DE MALHA; COMERCIO VAREJISTA" },
  { cnae: "4755501", descricao: "LOJA DE TECIDOS; COMERCIO VAREJISTA" },
  { cnae: "4755501", descricao: "FAZENDAS, TECIDOS; COMERCIO VAREJISTA" },
  { cnae: "4755501", descricao: "TECIDOS EM GERAL; COMERCIO VAREJISTA" },
  { cnae: "4755502", descricao: "FIOS E CORDOES TEXTEIS; COMERCIO VAREJISTA" },
  { cnae: "4755502", descricao: "NOVELOS DE LA; COMERCIO VAREJISTA" },
  { cnae: "4755502", descricao: "LINHAS; COMERCIO VAREJISTA" },
  { cnae: "4755502", descricao: "ARMARINHO; COMERCIO VAREJISTA" },
  { cnae: "4755502", descricao: "AVIAMENTOS PARA COSTURA; COMERCIO VAREJISTA" },
  {
    cnae: "4755502",
    descricao: "ARTIGOS DE PASSAMANARIA, FILOS, ELASTICOS; COMERCIO VAREJISTA",
  },
  { cnae: "4755502", descricao: "ARTIGOS DE ARMARINHO; COMERCIO VAREJISTA" },
  { cnae: "4755502", descricao: "LOJA DE ARMARINHO; COMERCIO VAREJISTA" },
  { cnae: "4755502", descricao: "ARTIGOS DE PASSAMANARIA; COMERCIO VAREJISTA" },
  { cnae: "4755502", descricao: "FELTROS, ENTRETELAS; COMERCIO VAREJISTA" },
  { cnae: "4755502", descricao: "BOTOES; COMERCIO VAREJISTA" },
  {
    cnae: "4755503",
    descricao: "ROUPAS DE CAMA, MESA E BANHO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4755503",
    descricao: "ARTIGOS DE CAMA, MESA E BANHO; COMERCIO VAREJISTA",
  },
  {
    cnae: "47563",
    descricao:
      "COMERCIO VAREJISTA ESPECIALIZADO DE INSTRUMENTOS MUSICAIS E ACESSORIOS",
  },
  { cnae: "4756300", descricao: "MUSICAS IMPRESSAS; COMERCIO VAREJISTA" },
  { cnae: "4756300", descricao: "INSTRUMENTOS MUSICAIS; COMERCIO VAREJISTA" },
  { cnae: "4756300", descricao: "PIANO; COMERCIO VAREJISTA" },
  {
    cnae: "4756300",
    descricao: "ACESSORIOS PARA INSTRUMENTOS MUSICAIS; COMERCIO VAREJISTA",
  },
  {
    cnae: "47571",
    descricao:
      "COMERCIO VAREJISTA ESPECIALIZADO DE PECAS E ACESSORIOS PARA APARELHOS ELETROELETRONICOS PARA USO DOMESTICO, EXCETO INFORMATICA E COMUNICACAO",
  },
  { cnae: "4757100", descricao: "COMPONENTES ELETRONICOS; COMERCIO VAREJISTA" },
  { cnae: "4757100", descricao: "PECAS PARA TELEVISORES; COMERCIO VAREJISTA" },
  { cnae: "4757100", descricao: "ANTENAS; COMERCIO VAREJISTA" },
  {
    cnae: "4757100",
    descricao: "PECAS E ACESSORIOS PARA ELETRODOMESTICOS; COMERCIO VAREJISTA",
  },
  { cnae: "4757100", descricao: "PECAS PARA GELADEIRAS; COMERCIO VAREJISTA" },
  { cnae: "4757100", descricao: "ANTENAS PARABOLICAS; COMERCIO VAREJISTA" },
  {
    cnae: "4757100",
    descricao:
      "PECAS E ACESSORIOS PARA APARELHOS ELETRONICOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4757100",
    descricao: "PECAS PARA MAQUINAS DE COSTURA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4757100",
    descricao: "PECAS E ACESSORIOS PARA FOGOES; COMERCIO VAREJISTA",
  },
  {
    cnae: "4757100",
    descricao: "PECAS PARA VIDEO CASSETES; COMERCIO VAREJISTA",
  },
  {
    cnae: "47598",
    descricao:
      "COMERCIO VAREJISTA DE ARTIGOS DE USO DOMESTICO NAO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "4759801", descricao: "TAPETES; COMERCIO VAREJISTA" },
  { cnae: "4759801", descricao: "CORTINAS; COMERCIO VAREJISTA" },
  { cnae: "4759801", descricao: "ARTIGOS DE TAPECARIA; COMERCIO VAREJISTA" },
  { cnae: "4759801", descricao: "VENEZIANAS; COMERCIO VAREJISTA" },
  { cnae: "4759801", descricao: "FORRACOES; COMERCIO VAREJISTA" },
  { cnae: "4759899", descricao: "AQUECEDORES SOLARES; COMERCIO VAREJISTA" },
  {
    cnae: "4759899",
    descricao: "ARTIGO DE CRISTAL PARA HABITACAO; COMERCIO VAREJISTA DE",
  },
  { cnae: "4759899", descricao: "UTENSILIOS DOMESTICOS; COMERCIO VAREJISTA" },
  { cnae: "4759899", descricao: "ARTIGOS DE VIME, COMERCIO VAREJISTA" },
  { cnae: "4759899", descricao: "ARTIGOS DE BAMBU; COMERCIO VAREJISTA" },
  {
    cnae: "4759899",
    descricao: "FILTROS DE AGUA DOMESTICO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4759899",
    descricao: "PURIFICADORES DE AGUA NAO ELETRICOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4759899",
    descricao: "UTENSILIOS PARA USO DOMESTICO; COMERCIO VAREJISTA",
  },
  { cnae: "4759899", descricao: "VASSOURAS; COMERCIO VAREJISTA" },
  {
    cnae: "4759899",
    descricao: "ARTIGO DE PLASTICO PARA HABITACAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4759899",
    descricao: "UTILIDADES DOMESTICAS EM GERAL; COMERCIO VAREJISTA",
  },
  { cnae: "4759899", descricao: "TOLDOS; COMERCIO VAREJISTA" },
  {
    cnae: "4759899",
    descricao:
      "ALARMES ELETRONICOS NAO ASSOCIADO A INSTALACAO OU MANUTENCAO - USO DOMESTICO (EXCETO VEICULOS); COMERCIO VAREJISTA",
  },
  {
    cnae: "4759899",
    descricao: "ARTIGO DE PORCELANA PARA HABITACAO; COMERCIO VAREJISTA DE",
  },
  { cnae: "4759899", descricao: "PANELAS; COMERCIO VAREJISTA" },
  { cnae: "4759899", descricao: "ARTIGOS DE CUTELARIA; COMERCIO VAREJISTA" },
  { cnae: "4759899", descricao: "TALHERES; COMERCIO VAREJISTA" },
  {
    cnae: "4759899",
    descricao: "ARTIGO DE METAL PARA HABITACAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4759899",
    descricao: "ARTIGO DE BORRACHA PARA HABITACAO; COMERCIO VAREJISTA",
  },
  { cnae: "4759899", descricao: "FACAS; COMERCIO VAREJISTA" },
  {
    cnae: "4759899",
    descricao:
      "SISTEMA DE SEGURANCA RESIDENCIAL NAO ASSOCIADO A INSTALACAO OU MANUTENCAO; COMERCIO VAREJISTA DE",
  },
  { cnae: "4759899", descricao: "TALHAS; COMERCIO VAREJISTA" },
  {
    cnae: "4759899",
    descricao: "PAPEL DE PAREDE  E SIMILARES; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4759899",
    descricao: "ARTIGO DE VIDRO PARA HABITACAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4759899",
    descricao:
      "EQUIPAMENTOS DE SEGURANCA RESIDENCIAL NAO ASSOCIADO A INSTALACAO OU MANUTENCAO; COMERCIO VAREJISTA",
  },
  { cnae: "4759899", descricao: "ALARME PARA RESIDENCIA; COMERCIO VAREJISTA" },
  { cnae: "4759899", descricao: "UTILIDADES DOMESTICAS; COMERCIO VAREJISTA" },
  {
    cnae: "4759899",
    descricao: "OZONIZADORES DE AGUA NAO ELETRICOS; COMERCIO VAREJISTA",
  },
  { cnae: "4759899", descricao: "PRATOS; COMERCIO VAREJISTA" },
  {
    cnae: "4759899",
    descricao: "TALHAS E FILTROS DE QUALQUER MATERIAL; COMERCIO VAREJISTA",
  },
  {
    cnae: "4759899",
    descricao: "ARTIGO DE MADEIRA PARA HABITACAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4759899",
    descricao: "ARTIGO DE LOUCA PARA HABITACAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4759899",
    descricao: "REDES DE PROTECAO PARA RESIDENCIAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4759899",
    descricao: "PAPEIS DE PAREDE E SIMILARES; COMERCIO VAREJISTA",
  },
  {
    cnae: "4759899",
    descricao: "CAMERAS DE SEGURANCA PARA USO DOMESTICO; COMERCIO VAREJISTA DE",
  },
  { cnae: "4759899", descricao: "ARTIGOS DE PEDRA SABAO, COMERCIO VAREJISTA" },
  { cnae: "4759899", descricao: "SISTEMAS DE SEGURANCA; COMERCIO VAREJISTA" },
  {
    cnae: "47610",
    descricao: "COMERCIO VAREJISTA DE LIVROS, JORNAIS, REVISTAS E PAPELARIA",
  },
  { cnae: "4761001", descricao: "LIVROS; COMERCIO VAREJISTA" },
  { cnae: "4761001", descricao: "APOSTILAS; COMERCIO VAREJISTA" },
  { cnae: "4761001", descricao: "LIVRARIA; COMERCIO VAREJISTA" },
  { cnae: "4761002", descricao: "JORNAIS; COMERCIO VAREJISTA" },
  { cnae: "4761002", descricao: "BANCA DE REVISTA; COMERCIO VAREJISTA" },
  { cnae: "4761002", descricao: "PERIODICOS; COMERCIO VAREJISTA" },
  { cnae: "4761002", descricao: "REVISTAS; COMERCIO VAREJISTA" },
  { cnae: "4761002", descricao: "BANCA DE JORNAIS; COMERCIO VAREJISTA" },
  { cnae: "4761002", descricao: "JORNALEIRO" },
  { cnae: "4761003", descricao: "MATERIAL PARA DESENHO; COMERCIO VAREJISTA" },
  {
    cnae: "4761003",
    descricao: "ETIQUETAS DE PAPEL, PLASTICO; COMERCIO VAREJISTA",
  },
  { cnae: "4761003", descricao: "PAPELARIA; COMERCIO VAREJISTA" },
  {
    cnae: "4761003",
    descricao: "CANETAS, LAPIS, BORRACHAS; COMERCIO VAREJISTA",
  },
  { cnae: "4761003", descricao: "ARTIGOS DE PAPELARIA; COMERCIO VAREJISTA" },
  { cnae: "4761003", descricao: "MATERIAL ESCOLAR; COMERCIO VAREJISTA" },
  { cnae: "4761003", descricao: "MATERIAL DE EXPEDIENTE; COMERCIO VAREJISTA" },
  {
    cnae: "4761003",
    descricao: "EMBALAGENS DE PAPEL E PAPELAO; COMERCIO VAREJISTA",
  },
  { cnae: "4761003", descricao: "ARTIGOS DE ESCRITORIO; COMERCIO VAREJISTA" },
  { cnae: "4761003", descricao: "CADERNOS; COMERCIO VAREJISTA" },
  {
    cnae: "4761003",
    descricao: "GRAMPEADORES, ROTULADORES, PERFURADORES; COMERCIO VAREJISTA",
  },
  {
    cnae: "47628",
    descricao: "COMERCIO VAREJISTA DE DISCOS, CDS, DVDS E FITAS",
  },
  { cnae: "4762800", descricao: "DISCOS DE BLU-RAY; COMERCIO VAREJISTA DE" },
  { cnae: "4762800", descricao: "DISCOS; COMERCIO VAREJISTA" },
  { cnae: "4762800", descricao: "CD, DVD; COMERCIO VAREJISTA" },
  {
    cnae: "4762800",
    descricao: "FITAS DE AUDIO E DE VIDEO, GRAVADAS OU NAO; COMERCIO VAREJISTA",
  },
  { cnae: "4762800", descricao: "DISCOS E FITAS; COMERCIO VAREJISTA" },
  {
    cnae: "4762800",
    descricao: "FITA MAGNETICA GRAVADA OU NAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "47636",
    descricao: "COMERCIO VAREJISTA DE ARTIGOS RECREATIVOS E ESPORTIVOS",
  },
  {
    cnae: "4763601",
    descricao:
      "BRINQUEDOS, JOGOS ELETRONICOS OU NAO, PECAS E ACESSORIOS PARA; COMERCIO VAREJISTA",
  },
  { cnae: "4763601", descricao: "BRINQUEDOS ARTESANAIS; COMERCIO VAREJISTA" },
  { cnae: "4763601", descricao: "ARTIGOS RECREATIVOS; COMERCIO VAREJISTA" },
  { cnae: "4763601", descricao: "JOGOS RECREATIVOS; COMERCIO VAREJISTA" },
  {
    cnae: "4763601",
    descricao:
      "CARRINHOS DE CONTROLE REMOTO (AUTOMODELOS); COMERCIO VAREJISTA DE",
  },
  { cnae: "4763601", descricao: "ARTIGOS DE BRINQUEDOS; COMERCIO VAREJISTA" },
  {
    cnae: "4763601",
    descricao: "BRINQUEDOS DE QUALQUER MATERIAL; COMERCIO VAREJISTA",
  },
  {
    cnae: "4763601",
    descricao: "VIDEO GAME, ELETRONICOS OU NAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4763602",
    descricao: "ROUPAS PARA PRATICA DE ESPORTES; COMERCIO VAREJISTA",
  },
  {
    cnae: "4763602",
    descricao: "ESQUIS E OUTROS EQUIPAMENTOS PARA ESQUIAR; COMERCIO VAREJISTA",
  },
  { cnae: "4763602", descricao: "PRANCHAS DE SURF; COMERCIO VAREJISTA" },
  { cnae: "4763602", descricao: "ARTIGOS DESPORTIVOS; COMERCIO VAREJISTA" },
  { cnae: "4763602", descricao: "ARTIGOS PARA ESPORTE; COMERCIO VAREJISTA" },
  {
    cnae: "4763602",
    descricao:
      "BOLAS PARA FUTEBOL, JOELHEIRAS, TORNOZELEIRAS E CANELEIRAS; COMERCIO VAREJISTA",
  },
  { cnae: "4763602", descricao: "ARTIGOS ESPORTIVOS; COMERCIO VAREJISTA" },
  { cnae: "4763602", descricao: "PATINS; COMERCIO VAREJISTA" },
  { cnae: "4763602", descricao: "ARTIGOS PARA EQUITACAO; COMERCIO VAREJISTA" },
  { cnae: "4763602", descricao: "MATERIAL PARA ESPORTE; COMERCIO VAREJISTA" },
  { cnae: "4763602", descricao: "LUVAS DE BOXE; COMERCIO VAREJISTA" },
  { cnae: "4763602", descricao: "ARTIGOS PARA GOLFE; COMERCIO VAREJISTA" },
  { cnae: "4763602", descricao: "ARTIGOS PARA HOQUEI; COMERCIO VAREJISTA" },
  {
    cnae: "4763602",
    descricao: "RAQUETES E REDES ESPORTIVAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4763602",
    descricao: "EQUIPAMENTOS PARA CACA SUBMARINA; COMERCIO VAREJISTA",
  },
  { cnae: "4763602", descricao: "ARTIGOS PARA GINASTICA; COMERCIO VAREJISTA" },
  { cnae: "4763603", descricao: "BICICLETAS; COMERCIO VAREJISTA" },
  {
    cnae: "4763603",
    descricao:
      "PECAS E ACESSORIOS PARA BICICLETAS E TRICICLOS; COMERCIO VAREJISTA",
  },
  { cnae: "4763603", descricao: "TRICICLOS; COMERCIO VAREJISTA" },
  {
    cnae: "4763604",
    descricao: "LINHAS E MOLINETES PARA VARA DE PESCAR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4763604",
    descricao: "ARTIGOS DE CACA, PESCA, CAMPING; COMERCIO VAREJISTA",
  },
  {
    cnae: "4763604",
    descricao: "MATERIAL PARA CACA, PESCA, CAMPING; COMERCIO VAREJISTA",
  },
  { cnae: "4763604", descricao: "BARRACAS PARA CAMPING; COMERCIO VAREJISTA" },
  {
    cnae: "4763604",
    descricao:
      "ACAMPAMENTO, ARTIGOS MATERIAIS E EQUIPAMENTOS PARA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4763605",
    descricao: "ACESSORIOS PARA VEICULOS RECREATIVOS; COMERCIO VAREJISTA",
  },
  { cnae: "4763605", descricao: "VEICULOS RECREATIVOS; COMERCIO VAREJISTA" },
  {
    cnae: "4763605",
    descricao:
      "PECAS E ACESSORIOS PARA BARCOS PARA ESPORTE E LAZER; COMERCIO VAREJISTA",
  },
  {
    cnae: "4763605",
    descricao:
      "PECAS E ACESSORIOS PARA MOTORES DE BARCOS PARA ESPORTE E LAZER; COMERCIO VAREJISTA",
  },
  { cnae: "4763605", descricao: "ASA DELTA, ULTRA-LEVES; COMERCIO VAREJISTA" },
  {
    cnae: "4763605",
    descricao:
      "MOTORES MARITIMOS PARA EMBARCACOES PARA ESPORTE E LAZER; COMERCIO VAREJISTA",
  },
  {
    cnae: "4763605",
    descricao:
      "PECAS E ACESSORIOS PARA EMBARCACOES PARA ESPORTE E LAZER; COMERCIO VAREJISTA",
  },
  { cnae: "4763605", descricao: "JET SKI; COMERCIO VAREJISTA DE" },
  { cnae: "4763605", descricao: "VELEIROS; COMERCIO VAREJISTA" },
  { cnae: "4763605", descricao: "CAIAQUES; COMERCIO VAREJISTA" },
  { cnae: "4763605", descricao: "KART; COMERCIO VAREJISTA" },
  {
    cnae: "4763605",
    descricao: "EMBARCACOES PARA ESPORTE E LAZER; COMERCIO VAREJISTA",
  },
  {
    cnae: "47717",
    descricao:
      "COMERCIO VAREJISTA DE PRODUTOS FARMACEUTICOS PARA USO HUMANO E VETERINARIO",
  },
  {
    cnae: "4771701",
    descricao: "PRODUTOS FARMACEUTICOS ALOPATICOS; COMERCIO VAREJISTA",
  },
  { cnae: "4771701", descricao: "MEDICAMENTOS ALOPATICOS; COMERCIO VAREJISTA" },
  { cnae: "4771701", descricao: "REMEDIOS ALOPATICOS; COMERCIO VAREJISTA" },
  {
    cnae: "4771701",
    descricao: "FARMACIAS, DROGARIAS ALOPATICAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4771702",
    descricao: "FARMACIAS DE MANIPULACAO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4771702",
    descricao: "PRODUTOS FARMACEUTICOS MANIPULADOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4771703",
    descricao: "PRODUTOS DA FLORA MEDICINAL; COMERCIO VAREJISTA",
  },
  {
    cnae: "4771703",
    descricao: "PRODUTOS FARMACEUTICOS HOMEOPATICOS; COMERCIO VAREJISTA",
  },
  { cnae: "4771703", descricao: "REMEDIOS HOMEOPATICOS; COMERCIO VAREJISTA" },
  { cnae: "4771703", descricao: "ERVANARIO; COMERCIO VAREJISTA" },
  {
    cnae: "4771703",
    descricao: "MEDICAMENTOS HOMEOPATICOS; COMERCIO VAREJISTA",
  },
  { cnae: "4771703", descricao: "PRODUTOS FITOTERAPICOS; COMERCIO VAREJISTA" },
  {
    cnae: "4771703",
    descricao: "FARMACIAS, DROGARIAS HOMEOPATICAS; COMERCIO VAREJISTA",
  },
  { cnae: "4771703", descricao: "FLORA MEDICINAL; COMERCIO VAREJISTA" },
  { cnae: "4771703", descricao: "HERBANARIO; COMERCIO VAREJISTA" },
  {
    cnae: "4771704",
    descricao: "MEDICAMENTOS VETERINARIOS; COMERCIO VAREJISTA",
  },
  { cnae: "4771704", descricao: "REMEDIOS PARA ANIMAIS; COMERCIO VAREJISTA" },
  {
    cnae: "4771704",
    descricao: "MEDICAMENTOS PARA ANIMAIS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4771704",
    descricao:
      "PRODUTOS FARMACEUTICOS MANIPULADOS PARA USO VETERINARIO, COMERCIO VAREJISTA",
  },
  { cnae: "4771704", descricao: "REMEDIOS VETERINARIOS; COMERCIO VAREJISTA" },
  {
    cnae: "4771704",
    descricao:
      "PRODUTOS FARMACEUTICOS PARA USO VETERINARIO; COMERCIO VAREJISTA",
  },
  {
    cnae: "47725",
    descricao:
      "COMERCIO VAREJISTA DE COSMETICOS, PRODUTOS DE PERFUMARIA E DE HIGIENE PESSOAL",
  },
  { cnae: "4772500", descricao: "COSMETICOS; COMERCIO VAREJISTA" },
  { cnae: "4772500", descricao: "PERFUMES, ESSENCIAS; COMERCIO VAREJISTA" },
  {
    cnae: "4772500",
    descricao: "PRESERVATIVOS (CAMISINHAS); COMERCIO VAREJISTA",
  },
  {
    cnae: "4772500",
    descricao: "CREME DENTAL, PASTA DE DENTE, DENTIFRICIO; COMERCIO VAREJISTA",
  },
  { cnae: "4772500", descricao: "ARTIGOS DE TOUCADOR; COMERCIO VAREJISTA DE" },
  { cnae: "4772500", descricao: "BASES PARA UNHA E ROSTO; COMERCIO VAREJISTA" },
  {
    cnae: "4772500",
    descricao:
      "BRONZEADORES E ACELERADORES DE BRONZEAMENTO; COMERCIO VAREJISTA",
  },
  { cnae: "4772500", descricao: "PRODUTOS DE PERFUMARIA; COMERCIO VAREJISTA" },
  {
    cnae: "4772500",
    descricao: "ABSORVENTE HIGIENICO INTIMO; COMERCIO VAREJISTA",
  },
  { cnae: "4772500", descricao: "ESMALTES PARA UNHAS; COMERCIO VAREJISTA" },
  {
    cnae: "4772500",
    descricao:
      "PRODUTOS DE HIGIENE E PERFUMARIA PARA USO VETERINARIO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4772500",
    descricao: "PRODUTOS DE BELEZA E HIGIENE PESSOAL; COMERCIO VEREJISTA",
  },
  {
    cnae: "4772500",
    descricao:
      "PRODUTOS DE HIGIENE E PERFUMARIA PARA ANIMAIS; COMERCIO VAREJISTA",
  },
  { cnae: "4772500", descricao: "ARTIGOS DE PERFUMARIA; COMERCIO VAREJISTA" },
  {
    cnae: "4772500",
    descricao: "ARTIGOS DE HIGIENE PESSOAL; COMERCIO VAREJISTA",
  },
  {
    cnae: "4772500",
    descricao: "PRODUTOS DE HIGIENE PESSOAL; COMERCIO VAREJISTA",
  },
  { cnae: "4772500", descricao: "FRALDAS DESCARTAVEIS; COMERCIO VAREJISTA" },
  { cnae: "4772500", descricao: "BATONS E BLUSH; COMERCIO VAREJISTA" },
  { cnae: "4772500", descricao: "PERFUMARIA; COMERCIO VAREJISTA" },
  { cnae: "4772500", descricao: "CREMES E LOCOES; COMERCIO VAREJISTA" },
  {
    cnae: "47733",
    descricao: "COMERCIO VAREJISTA DE ARTIGOS MEDICOS E ORTOPEDICOS",
  },
  { cnae: "4773300", descricao: "TERMOMETRO; COMERCIO VAREJISTA" },
  { cnae: "4773300", descricao: "PROTESES; COMERCIO VAREJISTA" },
  { cnae: "4773300", descricao: "ANDADOR; COMERCIO VAREJISTA" },
  { cnae: "4773300", descricao: "APARELHOS ORTOPEDICOS; COMERCIO VAREJISTA" },
  {
    cnae: "4773300",
    descricao: "KITS DIAGNOSTICO PARA EXAMES; COMERCIO VAREJISTA",
  },
  {
    cnae: "4773300",
    descricao: "TRAVESSEIROS E ENCOSTO ORTOPEDICOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4773300",
    descricao: "CALCADOS ORTOPEDICOS PRONTOS; COMERCIO VAREJISTA",
  },
  { cnae: "4773300", descricao: "APARELHOS PARA SURDEZ; COMERCIO VAREJISTA" },
  { cnae: "4773300", descricao: "APARELHOS DE PRESSAO; COMERCIO VAREJISTA DE" },
  { cnae: "4773300", descricao: "NEBULIZADOR; COMERCIO VAREJISTA" },
  { cnae: "4773300", descricao: "MULETAS; COMERCIO VAREJISTA" },
  { cnae: "4773300", descricao: "MASSAGEADOR; COMERCIO VAREJISTA" },
  { cnae: "4773300", descricao: "APARELHOS AUDITIVOS; COMERCIO VAREJISTA" },
  {
    cnae: "4773300",
    descricao: "APARELHOS ORTODONTICOS; COMERCIO VAREJISTA DE",
  },
  { cnae: "4773300", descricao: "VAPORIZADOR; COMERCIO VAREJISTA" },
  {
    cnae: "4773300",
    descricao: "ARTIGOS MEDICOS E ORTOPEDICOS; COMERCIO VAREJISTA",
  },
  { cnae: "4773300", descricao: "HEMOGLUCOTESTE, KIT DE; COMERCIO VAREJISTA" },
  { cnae: "4773300", descricao: "CADEIRA DE RODAS; COMERCIO VAREJISTA" },
  {
    cnae: "4773300",
    descricao: "KITS PARA EXAMES DE LABORATORIO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4773300",
    descricao: "PRODUTOS ODONTOLOGICOS E ORTODONTICOS; COMERCIO DE",
  },
  {
    cnae: "4773300",
    descricao: "KITS DIAGNOSTICO DE GRAVIDEZ; COMERCIO VAREJISTA",
  },
  { cnae: "47741", descricao: "COMERCIO VAREJISTA DE ARTIGOS DE OPTICA" },
  { cnae: "4774100", descricao: "ARTIGOS DE OPTICAS; COMERCIO VAREJISTA" },
  { cnae: "4774100", descricao: "OCULOS; COMERCIO VAREJISTA" },
  { cnae: "4774100", descricao: "ARTIGOS DE OPTICA; COMERCIO VAREJISTA" },
  { cnae: "4774100", descricao: "LENTES PARA OCULOS; COMERCIO VAREJISTA" },
  { cnae: "4774100", descricao: "ARMACOES PARA OCULOS; COMERCIO VAREJISTA" },
  {
    cnae: "4774100",
    descricao: "PRODUTOS DE LIMPEZA PARA OCULOS; COMERCIO VAREJISTA",
  },
  { cnae: "4774100", descricao: "LENTES DE CONTATO; COMERCIO VAREJISTA" },
  {
    cnae: "47814",
    descricao: "COMERCIO VAREJISTA DE ARTIGOS DO VESTUARIO E ACESSORIOS",
  },
  { cnae: "4781400", descricao: "LOJA DE ROUPAS; COMERCIO VAREJISTA" },
  { cnae: "4781400", descricao: "ARTIGOS DO VESTUARIO; COMERCIO VAREJISTA" },
  {
    cnae: "4781400",
    descricao: "MODA MASCULINA, FEMININA, INFANTIL; COMERCIO VAREJISTA",
  },
  {
    cnae: "4781400",
    descricao: "ROUPAS MASCULINAS, FEMININAS, INFANTIS; COMERCIO VAREJISTA",
  },
  { cnae: "4781400", descricao: "GUARDA-CHUVA; COMERCIO VAREJISTA" },
  { cnae: "4781400", descricao: "CHAPELARIA; COMERCIO VAREJISTA" },
  {
    cnae: "4781400",
    descricao: "CHAPEU DE CHUVA, CHAPEU DE SOL; COMERCIO VAREJISTA",
  },
  { cnae: "4781400", descricao: "ROUPA PRONTA; COMERCIO VAREJISTA" },
  { cnae: "4781400", descricao: "CAMISARIA; COMERCIO VAREJISTA" },
  { cnae: "4781400", descricao: "ROUPAS DE COURO; COMERCIO VAREJISTA" },
  { cnae: "4781400", descricao: "VESTUARIO; COMERCIO VAREJISTA" },
  {
    cnae: "4781400",
    descricao: "VESTUARIO, ROUPAS PARA ANIMAIS; COMERCIO VAREJISTA DE",
  },
  { cnae: "4781400", descricao: "ROUPA FEITA; COMERCIO VAREJISTA" },
  {
    cnae: "4781400",
    descricao: "BLUSAS E CAMISAS PARA CRIANCAS, COMERCIO VAREJISTA",
  },
  { cnae: "4781400", descricao: "CAPAS DE CHUVA; COMERCIO VAREJISTA" },
  { cnae: "4781400", descricao: "UNIFORMES ESCOLARES; COMERCIO VAREJISTA" },
  { cnae: "4781400", descricao: "PECAS DO VESTUARIO; COMERCIO VAREJISTA" },
  { cnae: "4781400", descricao: "SOMBRINHA; COMERCIO VAREJISTA" },
  { cnae: "4781400", descricao: "UNIFORMES MEDICOS; COMERCIO VAREJISTA" },
  {
    cnae: "4781400",
    descricao: "CONFECCAO MASCULINA, FEMININA, INFANTIL; COMERCIO VAREJISTA",
  },
  { cnae: "4781400", descricao: "VESTIDO DE NOIVA; COMERCIO VAREJISTA" },
  {
    cnae: "4781400",
    descricao: "CINTOS DE QUALQUER MATERIAL; COMERCIO VAREJISTA",
  },
  { cnae: "4781400", descricao: "BOUTIQUE, BUTIQUE; COMERCIO VAREJISTA" },
  { cnae: "4781400", descricao: "ROUPAS EM GERAL; COMERCIO VAREJISTA" },
  {
    cnae: "4781400",
    descricao: "COMPLEMENTOS DO VESTUARIO; COMERCIO VAREJISTA",
  },
  { cnae: "4781400", descricao: "ROUPAS INTIMAS; COMERCIO VAREJISTA" },
  { cnae: "4781400", descricao: "LINGERIE; COMERCIO VAREJISTA" },
  { cnae: "4781400", descricao: "MEIAS DE QUALQUER TIPO; COMERCIO VAREJISTA" },
  { cnae: "4781400", descricao: "ENXOVAL; COMERCIO VAREJISTA" },
  { cnae: "4781400", descricao: "GRAVATAS; COMERCIO VAREJISTA" },
  { cnae: "4781400", descricao: "ACESSORIOS DO VESTUARIO; COMERCIO VAREJISTA" },
  {
    cnae: "47822",
    descricao: "COMERCIO VAREJISTA DE CALCADOS E ARTIGOS DE VIAGEM",
  },
  { cnae: "4782201", descricao: "LOJA DE SAPATOS; COMERCIO VAREJISTA" },
  { cnae: "4782201", descricao: "SAPATOS ESPORTIVOS; COMERCIO VAREJISTA" },
  {
    cnae: "4782201",
    descricao: "CALCADOS DE QUALQUER MATERIAL; COMERCIO VAREJISTA",
  },
  { cnae: "4782201", descricao: "SAPATOS; COMERCIO VAREJISTA" },
  { cnae: "4782201", descricao: "CALCADOS DE COURO; COMERCIO VAREJISTA" },
  { cnae: "4782201", descricao: "SAPATENIS; COMERCIO VAREJISTA" },
  { cnae: "4782201", descricao: "SAPATARIA; COMERCIO VAREJISTA" },
  { cnae: "4782201", descricao: "TAMANCOS; COMERCIO VAREJISTA" },
  { cnae: "4782201", descricao: "TENIS; COMERCIO VAREJISTA" },
  { cnae: "4782201", descricao: "CALCADOS DESPORTIVOS; COMERCIO VAREJISTA" },
  { cnae: "4782201", descricao: "SANDALIAS; COMERCIO VAREJISTA" },
  { cnae: "4782201", descricao: "CALCADOS EM GERAL; COMERCIO VAREJISTA" },
  {
    cnae: "4782202",
    descricao: "CARTEIRAS, PORTA-NIQUEIS, PORTA-DOCUMENTOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4782202",
    descricao:
      "BOLSAS, MOCHILAS, MALAS, VALISES DE QUALQUER MATERIAL; COMERCIO VAREJISTA DE",
  },
  {
    cnae: "4782202",
    descricao: "ARTIGOS DE VIAGEM DE QUALQUER MATERIAL; COMERCIO VAREJISTA",
  },
  {
    cnae: "4782202",
    descricao:
      "ARTIGOS DE COURO E DE OUTROS MATERIAIS PARA USO PESSOAL; COMERCIO VAREJISTA",
  },
  {
    cnae: "4782202",
    descricao: "BOLSAS E SACOLAS DE TECIDOS; COMERCIO VAREJISTA",
  },
  { cnae: "47831", descricao: "COMERCIO VAREJISTA DE JOIAS E RELOGIOS" },
  { cnae: "4783101", descricao: "JOIAS; COMERCIO VAREJISTA" },
  { cnae: "4783101", descricao: "METAIS PRECIOSOS; COMERCIO VAREJISTA" },
  { cnae: "4783101", descricao: "ARTIGOS DE JOALHERIA; COMERCIO VAREJISTA" },
  { cnae: "4783101", descricao: "JOALHERIA; COMERCIO VAREJISTA" },
  {
    cnae: "4783101",
    descricao: "PEDRAS PRECIOSAS, SEMIPRECIOSAS LAPIDADAS; COMERCIO VAREJISTA",
  },
  { cnae: "4783101", descricao: "OURIVESARIA; COMERCIO VAREJISTA" },
  { cnae: "4783101", descricao: "VITRINE DE JOALHERIA" },
  { cnae: "4783101", descricao: "ARTIGOS DE OURIVESARIA; COMERCIO VAREJISTA" },
  {
    cnae: "4783101",
    descricao:
      "JOALHERIA (ANEIS, BRINCOS, ALIANCAS, PULSEIRAS, COLARES, PIERCINGS, PINGENTES, GARGANTILHAS DE METAIS PRECIOSOS); COMERCIO VAREJISTA",
  },
  { cnae: "4783102", descricao: "ARTIGOS DE RELOJOARIA; COMERCIO VAREJISTA" },
  { cnae: "4783102", descricao: "RELOGIOS; COMERCIO VAREJISTA" },
  { cnae: "4783102", descricao: "RELOJOARIA; COMERCIO VAREJISTA" },
  {
    cnae: "4783102",
    descricao: "PECAS E PARTES PARA RELOGIOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "47849",
    descricao: "COMERCIO VAREJISTA DE GAS LIQUEFEITO DE PETROLEO (GLP)",
  },
  {
    cnae: "4784900",
    descricao: "GAS DE BOTIJAO PARA USO DOMESTICO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4784900",
    descricao: "GAS ENGARRAFADO PARA USO DOMESTICO; COMERCIO VAREJISTA",
  },
  { cnae: "4784900", descricao: "GLP PARA USO DOMESTICO; COMERCIO VAREJISTA" },
  {
    cnae: "4784900",
    descricao: "GAS DE COZINHA PARA USO DOMESTICO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4784900",
    descricao:
      "GAS LIQUEFEITO DE PETROLEO PARA USO DOMESTICO; COMERCIO VAREJISTA",
  },
  {
    cnae: "4784900",
    descricao: "GAS BUTANO PARA USO DOMESTICO; COMERCIO VAREJISTA",
  },
  { cnae: "47857", descricao: "COMERCIO VAREJISTA DE ARTIGOS USADOS" },
  { cnae: "4785701", descricao: "OBJETOS DE ARTES USADOS; COMERCIO VAREJISTA" },
  { cnae: "4785701", descricao: "ANTIGUIDADES; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "BIJUTERIAS USADAS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "NUMISMATICA; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "RADIOS USADOS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "MOEDAS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "ROUPAS USADAS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "GELADEIRAS USADAS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "SELOS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "FOGOES USADOS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "FILATELICO; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "FILATELIA; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "BOLSAS USADAS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "MOVEIS USADOS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "CALCADOS USADOS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "TELEVISORES USADOS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "SOFAS, POLTRONAS USADAS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "MATERIAL DE DEMOLICAO; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "SELO PARA COLECAO; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "ARTIGOS USADOS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "SACARIA USADA; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "SACOS DE ALGODAO USADOS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "ELETRODOMESTICOS USADOS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "REVISTAS USADAS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "CAMAS USADAS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "VESTUARIO USADO; COMERCIO VAREJISTA" },
  {
    cnae: "4785799",
    descricao: "MOEDAS, SELOS, COLECAO DE; COMERCIO VAREJISTA",
  },
  { cnae: "4785799", descricao: "REFRIGERADORES USADOS; COMERCIO VAREJISTA" },
  { cnae: "4785799", descricao: "LIVROS USADOS; COMERCIO VAREJISTA" },
  {
    cnae: "4785799",
    descricao: "UTENSILIOS DOMESTICOS USADOS; COMERCIO VAREJISTA",
  },
  { cnae: "4785799", descricao: "BRECHO, LOJAS DE; COMERCIO VAREJISTA" },
  {
    cnae: "47890",
    descricao:
      "COMERCIO VAREJISTA DE OUTROS PRODUTOS NOVOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "4789001", descricao: "SUVENIRES; COMERCIO" },
  { cnae: "4789001", descricao: "BIJUTERIA; COMERCIO VAREJISTA" },
  { cnae: "4789001", descricao: "SOUVENIRS; COMERCIO VAREJISTA" },
  {
    cnae: "4789001",
    descricao:
      "JOALHERIA CHAPEADA (ANEIS, BRINCOS, ALIANCAS, PULSEIRAS, COLARES, PIERCINGS, PINGENTES, GARGANTILHAS DE MATERIAIS DIVERSOS- EXCETO DE METAIS PRECIOSOS); COMERCIO VAREJISTA",
  },
  { cnae: "4789001", descricao: "ARTIGOS DE ARTESANATO; COMERCIO VAREJISTA" },
  { cnae: "4789001", descricao: "ARTIGOS DE BIJUTERIA; COMERCIO VAREJISTA" },
  { cnae: "4789001", descricao: "BIJUTERIAS, CHAPEADOS; COMERCIO VAREJISTA" },
  { cnae: "4789002", descricao: "FLORICULTURA" },
  { cnae: "4789002", descricao: "SEMENTES E MUDAS; COMERCIO VAREJISTA" },
  { cnae: "4789002", descricao: "PLANTAS; COMERCIO VAREJISTA" },
  { cnae: "4789002", descricao: "VASOS PARA PLANTAS; COMERCIO VAREJISTA" },
  {
    cnae: "4789002",
    descricao: "PLANTAS E FLORES NATURAIS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4789002",
    descricao: "HUMO (HUMOS) PARA PLANTAS; COMERCIO VAREJISTA DE",
  },
  { cnae: "4789002", descricao: "FLORISTA; COMERCIO VAREJISTA" },
  { cnae: "4789002", descricao: "FLORES NATURAIS; COMERCIO VAREJISTA" },
  {
    cnae: "4789002",
    descricao: "CANTONEIRAS PARA PLANTAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4789002",
    descricao: "FRUTOS ORNAMENTAIS NATURAIS; COMERCIO VAREJISTA",
  },
  { cnae: "4789002", descricao: "ADUBOS; COMERCIO VAREJISTA" },
  { cnae: "4789003", descricao: "GALERIAS DE ARTE; COMERCIO VAREJISTA" },
  { cnae: "4789003", descricao: "OBJETOS DE ARTE NOVOS; COMERCIO VAREJISTA" },
  {
    cnae: "4789004",
    descricao:
      "PRODUTOS VETERINARIOS (EXCETO MEDICAMENTOS); COMERCIO VAREJISTA",
  },
  {
    cnae: "4789004",
    descricao:
      "MORDACAS, FOCINHEIRAS, COLEIRAS PARA ANIMAIS; COMERCIO VAREJISTA",
  },
  { cnae: "4789004", descricao: "ARTIGOS PARA AQUARIOS; COMERCIO VAREJISTA" },
  {
    cnae: "4789004",
    descricao: "PASSAROS PARA CRIACAO DOMESTICA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4789004",
    descricao:
      "ARTEFATOS DE COURO (EXCETO PARA USO DOMESTICO); COMERCIO VAREJISTA",
  },
  {
    cnae: "4789004",
    descricao: "CACHORROS, GATOS PARA CRIACAO DOMESTICA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4789004",
    descricao: "PASSARINHOS PARA CRIACAO DOMESTICA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4789004",
    descricao: "SELAS, ARREIOS PARA ANIMAIS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4789004",
    descricao: "RACOES PARA ANIMAIS DOMESTICOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4789004",
    descricao: "ARTIGOS DE COURO PARA PEQUENOS ANIMAIS; COMERCIO VAREJISTA",
  },
  { cnae: "4789004", descricao: "CAO, CACHORRO; COMERCIO VAREJISTA" },
  { cnae: "4789004", descricao: "GATO; COMERCIO VAREJISTA" },
  { cnae: "4789004", descricao: "ARTIGOS VETERINARIOS; COMERCIO VAREJISTA" },
  { cnae: "4789004", descricao: "GAIOLAS, VIVEIROS; COMERCIO VAREJISTA" },
  {
    cnae: "4789004",
    descricao: "ARTEFATOS DE SELARIA PARA PEQUENOS ANIMAIS; COMERCIO VAREJISTA",
  },
  { cnae: "4789004", descricao: "PET-SHOP; LOJAS DE" },
  { cnae: "4789004", descricao: "PEIXES ORNAMENTAIS; COMERCIO VAREJISTA" },
  { cnae: "4789004", descricao: "AQUARIOS; COMERCIO VAREJISTA" },
  {
    cnae: "4789004",
    descricao: "ANIMAIS VIVOS PARA CRIACAO DOMESTICA; COMERCIO VAREJISTA",
  },
  { cnae: "4789004", descricao: "ARTIGOS DE MONTARIA; COMERCIO VAREJISTA" },
  {
    cnae: "4789005",
    descricao:
      "PRODUTOS BIOLOGICOS PARA TRATAMENTO DE SISTEMAS SEPTICOS; COMERCIO VAREJISTA",
  },
  { cnae: "4789005", descricao: "SAPOLEO E SAPONACEO; COMERCIO VAREJISTA" },
  { cnae: "4789005", descricao: "ESTERILIZANTES; COMERCIO VAREJISTA" },
  {
    cnae: "4789005",
    descricao: "CRESOIS PARA USO DESINFETANTE; COMERCIO VAREJISTA",
  },
  {
    cnae: "4789005",
    descricao: "DETERGENTES, SABOES E ALVEJANTES; COMERCIO VAREJISTA",
  },
  { cnae: "4789005", descricao: "SANEANTE DOMISSANITARIO; COMERCIO VAREJISTA" },
  { cnae: "4789005", descricao: "RATICIDAS; COMERCIO VAREJISTA" },
  {
    cnae: "4789005",
    descricao: "ALGICIDAS E FUNGICIDAS PARA PISCINAS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4789005",
    descricao:
      "PRODUTOS DE LIMPEZA PARA VEICULOS, AUTOMOVEIS; COMERCIO VAREJISTA",
  },
  { cnae: "4789005", descricao: "REPELENTES; COMERCIO VAREJISTA" },
  {
    cnae: "4789005",
    descricao: "PRODUTOS DE LIMPEZA DOMESTICA; COMERCIO VAREJISTA",
  },
  {
    cnae: "4789005",
    descricao: "PRODUTOS QUIMICOS PARA PISCINAS; COMERCIO VAREJISTA",
  },
  { cnae: "4789005", descricao: "CLORO PARA PISCINAS; COMERCIO VAREJISTA" },
  {
    cnae: "4789005",
    descricao:
      "FORMICIDAS, FUNGICIDAS E INSETICIDAS BIOLOGICO PARA USO DOMESTICO; COMERCIO VAREJISTA",
  },
  { cnae: "4789005", descricao: "INSETICIDAS; COMERCIO VAREJISTA" },
  { cnae: "4789005", descricao: "DESODORIZANTES; COMERCIO VAREJISTA" },
  { cnae: "4789005", descricao: "RATICIDAS E REPELENTES; COMERCIO VAREJISTA" },
  {
    cnae: "4789005",
    descricao: "ARTIGOS DE LIMPEZA DOMESTICA; COMERCIO VAREJISTA",
  },
  { cnae: "4789005", descricao: "DESINFETANTES; COMERCIO VAREJISTA" },
  {
    cnae: "4789006",
    descricao: "FOGOS DE ARTIFICIOS E ARTIGOS PIROTECNICOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4789007",
    descricao: "MAQUINAS PARA ESCRITORIO; COMERCIO VAREJISTA",
  },
  { cnae: "4789007", descricao: "MAQUINAS DE ESCREVER; COMERCIO VAREJISTA" },
  { cnae: "4789007", descricao: "MAQUINAS DE CALCULAR; COMERCIO VAREJISTA" },
  { cnae: "4789007", descricao: "CALCULADORAS; COMERCIO VAREJISTA" },
  {
    cnae: "4789007",
    descricao:
      "PECAS E ACESSORIOS PARA MAQUINAS DE ESCREVER, CALCULAR; COMERCIO VAREJISTA",
  },
  {
    cnae: "4789008",
    descricao: "ARTIGOS FOTOGRAFICOS E CINEMATOGRAFICOS; COMERCIO VAREJISTA",
  },
  {
    cnae: "4789008",
    descricao: "MATERIAIS, ARTIGOS FOTOGRAFICOS; COMERCIO VAREJISTA",
  },
  { cnae: "4789008", descricao: "FILMES FOTOGRAFICOS; COMERCIO VAREJISTA" },
  {
    cnae: "4789009",
    descricao: "ARMAS, PECAS E ACESSORIOS; COMERCIO VAREJISTA",
  },
  { cnae: "4789009", descricao: "ARMAS DE USO PESSOAL; COMERCIO VAREJISTA" },
  { cnae: "4789009", descricao: "ARMAS E MUNICOES; COMERCIO VAREJISTA" },
  {
    cnae: "4789099",
    descricao: "PLACAS E PLAQUETAS PARA VEICULOS; COMERCIO VAREJISTA",
  },
  { cnae: "4789099", descricao: "ESCOVAS; COMERCIO VAREJISTA" },
  { cnae: "4789099", descricao: "SACOS DE LIXO; COMERCIO VAREJISTA" },
  { cnae: "4789099", descricao: "MOLDURAS DE QUADRO; COMERCIO VAREJISTA" },
  { cnae: "4789099", descricao: "CARRINHOS PARA BEBE; COMERCIO VAREJISTA" },
  {
    cnae: "4789099",
    descricao: "SEX SHOP, ARTIGOS DE LOJA; COMERCIO VAREJISTA",
  },
  { cnae: "4789099", descricao: "RETROPROJETORES; COMERCIO VAREJISTA" },
  {
    cnae: "47903",
    descricao: "COMERCIO AMBULANTE E OUTROS TIPOS DE COMERCIO VAREJISTA",
  },
  { cnae: "49116", descricao: "TRANSPORTE FERROVIARIO DE CARGA" },
  {
    cnae: "4911600",
    descricao: "LOCACAO DE INFRA ESTRUTURA DE REDE DE FERROVIAS",
  },
  {
    cnae: "4911600",
    descricao: "TRANSPORTE FERROVIARIO INTERURBANO DE CARGAS",
  },
  { cnae: "4911600", descricao: "TREM; TRANSPORTE INTERURBANO DE CARGAS" },
  {
    cnae: "4911600",
    descricao:
      "TRANSPORTE FERROVIARIO DE CARGAS, INTERMUNICIPAL E INTERESTADUAL",
  },
  { cnae: "49124", descricao: "TRANSPORTE METROFERROVIARIO DE PASSAGEIROS" },
  {
    cnae: "4912401",
    descricao: "TRANSPORTE FERROVIARIO INTERURBANO DE PASSAGEIROS",
  },
  {
    cnae: "4912401",
    descricao:
      "TRANSPORTE FERROVIARIO DE PASSAGEIROS INTERMUNICIPAL E INTERESTADUAL",
  },
  {
    cnae: "4912402",
    descricao:
      "TRANSPORTE FERROVIARIO DE PASSAGEIROS MUNICIPAL E EM REGIAO METROPOLITANA",
  },
  {
    cnae: "4912402",
    descricao: "TRANSPORTE FERROVIARIO DE PASSAGEIROS URBANO",
  },
  { cnae: "4912402", descricao: "TREM; TRANSPORTE DE PASSAGEIROS URBANO" },
  {
    cnae: "4912402",
    descricao:
      "BONDES; TRANSPORTE FERROVIARIO DE PASSAGEIROS EM REGIAO METROPOLITANA",
  },
  {
    cnae: "4912402",
    descricao: "BONDES; TRANSPORTE FERROVIARIO DE PASSAGEIROS MUNICIPAL",
  },
  {
    cnae: "4912402",
    descricao:
      "VEICULO LEVE SOBRE TRILHOS - VLT; TRANSPORTE DE PASSAGEIROS URBANO",
  },
  { cnae: "4912403", descricao: "METRO; TRANSPORTE DE PASSAGEIROS" },
  { cnae: "4912403", descricao: "TRANSPORTE METROVIARIO" },
  {
    cnae: "49213",
    descricao:
      "TRANSPORTE RODOVIARIO COLETIVO DE PASSAGEIROS, COM ITINERARIO FIXO, MUNICIPAL E EM REGIAO METROPOLITANA",
  },
  {
    cnae: "4921301",
    descricao:
      "ONIBUS; TRANSPORTE RODOVIARIO DE PASSAGEIROS, REGULAR, MUNICIPAL URBANO",
  },
  {
    cnae: "4921301",
    descricao:
      "TRANSPORTE RODOVIARIO DE PASSAGEIROS, REGULAR, MUNICIPAL URBANO",
  },
  {
    cnae: "4921302",
    descricao:
      "ONIBUS; TRANSPORTE RODOVIARIO DE PASSAGEIROS, REGULAR, INTRAMUNICIPAL, NAO METROPOLITANO",
  },
  {
    cnae: "4921302",
    descricao:
      "TRANSPORTE RODOVIARIO DE PASSAGEIROS, REGULAR, INTERMUNICIPAL METROPOLITANO",
  },
  {
    cnae: "4921302",
    descricao:
      "TRANSPORTE RODOVIARIO DE PASSAGEIROS, REGULAR, INTRAMUNICIPAL, NAO METROPOLITANO",
  },
  {
    cnae: "49221",
    descricao:
      "TRANSPORTE RODOVIARIO COLETIVO DE PASSAGEIROS, COM ITINERARIO FIXO, INTERMUNICIPAL, INTERESTADUAL E INTERNACIONAL",
  },
  {
    cnae: "4922101",
    descricao:
      "ONIBUS; TRANSPORTE RODOVIARIO DE PASSAGEIROS, REGULAR, INTERMUNICIPAL, NAO METROPOLITANO",
  },
  {
    cnae: "4922101",
    descricao:
      "TRANSPORTE RODOVIARIO DE PASSAGEIROS, REGULAR, INTERMUNICIPAL NAO URBANO",
  },
  {
    cnae: "4922101",
    descricao:
      "TRANSPORTE RODOVIARIO DE PASSAGEIROS, REGULAR, INTERMUNICIPAL, NAO METROPOLITANO",
  },
  {
    cnae: "4922102",
    descricao:
      "TRANSPORTE RODOVIARIO DE PASSAGEIROS, REGULAR, INTERESTADUAL NAO URBANO",
  },
  {
    cnae: "4922102",
    descricao:
      "ONIBUS; TRANSPORTE RODOVIARIO DE PASSAGEIROS, REGULAR, INTERESTADUAL",
  },
  {
    cnae: "4922102",
    descricao: "TRANSPORTE RODOVIARIO DE PASSAGEIROS, REGULAR, INTERESTADUAL",
  },
  {
    cnae: "4922103",
    descricao: "TRANSPORTE RODOVIARIO DE PASSAGEIROS, REGULAR, INTERNACIONAL",
  },
  {
    cnae: "4922103",
    descricao:
      "TRANSPORTE RODOVIARIO DE PASSAGEIROS, REGULAR, INTERNACIONAL NAO URBANO",
  },
  {
    cnae: "4922103",
    descricao:
      "ONIBUS; TRANSPORTE RODOVIARIO DE PASSAGEIROS, REGULAR, INTERNACIONAL",
  },
  { cnae: "49230", descricao: "TRANSPORTE RODOVIARIO DE TAXI" },
  { cnae: "4923001", descricao: "MOTO TAXI" },
  {
    cnae: "4923001",
    descricao: "MOTOBOY; SERVICO DE TRANSPORTE DE PASSAGEIROS",
  },
  { cnae: "4923001", descricao: "TAXI; EMPRESA DE" },
  {
    cnae: "4923001",
    descricao: "MOTORISTA AUTONOMO; SERVICO DE TAXI OU SIMILAR PRESTADO POR",
  },
  { cnae: "4923001", descricao: "TAXI; SERVICOS DE" },
  {
    cnae: "4923002",
    descricao: "ALUGUEL DE AUTOMOVEIS COM MOTORISTA, MUNICIPAL",
  },
  {
    cnae: "4923002",
    descricao: "ALUGUEL DE VEICULOS RODOVIARIOS COM MOTORISTA, MUNICIPAL",
  },
  {
    cnae: "4923002",
    descricao: "ALUGUEL DE AUTOMOVEIS COM CONDUTOR, MUNICIPAL",
  },
  {
    cnae: "4923002",
    descricao: "ALUGUEL DE AUTOMOVEIS COM CONDUTOR, INTERMUNICIPAL",
  },
  {
    cnae: "4923002",
    descricao: "AUTOMOVEIS COM MOTORISTA, MUNICIPAL; LOCACAO DE",
  },
  {
    cnae: "4923002",
    descricao:
      "VEICULOS RODOVIARIOS DE PASSAGEIROS COM MOTORISTA, MUNICIPAL; LOCACAO DE",
  },
  {
    cnae: "4923002",
    descricao: "ALUGUEL DE AUTOMOVEIS COM MOTORISTA, INTERMUNICIPAL",
  },
  {
    cnae: "4923002",
    descricao: "AUTOMOVEIS COM MOTORISTA, INTERMUNICIPAL; LOCACAO DE",
  },
  { cnae: "49248", descricao: "TRANSPORTE ESCOLAR" },
  {
    cnae: "4924800",
    descricao: "TRANSPORTE RODOVIARIO DE ALUNOS, ESTUDANTES; SERVICOS DE",
  },
  { cnae: "4924800", descricao: "ONIBUS ESCOLAR MUNICIPAL" },
  { cnae: "4924800", descricao: "TRANSPORTE ESCOLAR MUNICIPAL" },
  { cnae: "4924800", descricao: "TRANSPORTE DE ESCOLARES" },
  { cnae: "4924800", descricao: "ONIBUS ESCOLAR INTERMUNICIPAL" },
  { cnae: "4924800", descricao: "TRANSPORTE ESCOLAR INTERMUNICIPAL" },
  {
    cnae: "49299",
    descricao:
      "TRANSPORTE RODOVIARIO COLETIVO DE PASSAGEIROS, SOB REGIME DE FRETAMENTO, E OUTROS TRANSPORTES RODOVIARIOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "4929901", descricao: "ALUGUEL DE ONIBUS, MUNICIPAL, COM MOTORISTA" },
  { cnae: "4929901", descricao: "ONIBUS MUNICIPAL COM MOTORISTA; LOCACAO DE" },
  {
    cnae: "4929901",
    descricao:
      "TRANSPORTE RODOVIARIO COLETIVO DE PASSAGEIROS, SOB REGIME DE FRETAMENTO, MUNICIPAL",
  },
  {
    cnae: "4929901",
    descricao: "TRANSPORTE RODOVIARIO DE FUNCIONARIOS; SERVICOS DE",
  },
  {
    cnae: "4929902",
    descricao:
      "VEICULOS RODOVIARIOS DE PASSAGEIROS COM MOTORISTA, INTERESTADUAL; LOCACAO DE",
  },
  {
    cnae: "4929902",
    descricao:
      "ALUGUEL DE AUTOMOVEIS COM CONDUTOR, INTERMUNICIPAL, INTERESTADUAL, INTERNACIONAL",
  },
  {
    cnae: "4929902",
    descricao: "ALUGUEL DE VEICULOS RODOVIARIOS COM MOTORISTA, INTERESTADUAL",
  },
  {
    cnae: "4929902",
    descricao:
      "AUTOMOVEIS COM MOTORISTA INTERMUNICIPAL, INTERESTADUAL, INTERNACIONAL; LOCACAO DE",
  },
  {
    cnae: "4929902",
    descricao:
      "ALUGUEL DE ONIBUS, INTERMUNICIPAL, INTERESTADUAL, INTERNACIONAL, COM MOTORISTA",
  },
  {
    cnae: "4929902",
    descricao: "ALUGUEL DE VEICULOS RODOVIARIOS COM MOTORISTA, INTERMUNICIPAL",
  },
  {
    cnae: "4929902",
    descricao:
      "VEICULOS RODOVIARIOS DE PASSAGEIROS COM MOTORISTA, INTERMUNICIPAL; LOCACAO DE",
  },
  {
    cnae: "4929902",
    descricao:
      "VEICULOS RODOVIARIOS DE PASSAGEIROS COM MOTORISTA, INTERNACIONAL; LOCACAO DE",
  },
  {
    cnae: "4929902",
    descricao:
      "ALUGUEL DE AUTOMOVEIS COM MOTORISTA, INTERMUNICIPAL, INTERESTADUAL, INTERNACIONAL",
  },
  {
    cnae: "4929902",
    descricao:
      "TRANSPORTE RODOVIARIO COLETIVO DE PASSAGEIROS, SOB REGIME DE FRETAMENTO, INTERMUNICIPAL, INTERESTADUAL E INTERNACIONAL",
  },
  {
    cnae: "4929902",
    descricao: "ALUGUEL DE VEICULOS RODOVIARIOS COM MOTORISTA, INTERNACIONAL",
  },
  {
    cnae: "4929903",
    descricao:
      "ORGANIZACAO DE EXCURSOES EM VEICULOS RODOVIARIOS PROPRIOS, MUNICIPAL",
  },
  {
    cnae: "4929904",
    descricao:
      "ORGANIZACAO DE EXCURSOES EM VEICULOS RODOVIARIOS PROPRIOS INTERESTADUAL",
  },
  {
    cnae: "4929904",
    descricao:
      "ORGANIZACAO DE EXCURSOES EM VEICULOS RODOVIARIOS PROPRIOS INTERMUNICIPAL",
  },
  {
    cnae: "4929904",
    descricao:
      "ORGANIZACAO DE EXCURSOES EM VEICULOS RODOVIARIOS PROPRIOS INTERNACIONAL",
  },
  {
    cnae: "4929999",
    descricao:
      "OUTROS TRANSPORTES RODOVIARIOS DE PASSAGEIROS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "49302", descricao: "TRANSPORTE RODOVIARIO DE CARGA" },
  {
    cnae: "4930201",
    descricao:
      "TRANSPORTE RODOVIARIO MUNICIPAL DE CARGAS EM GERAL, EM CONTEINERES",
  },
  { cnae: "4930201", descricao: "TRANSPORTE RODOVIARIO DE CARGA, MUNICIPAL" },
  {
    cnae: "4930201",
    descricao: "TRANSPORTE RODOVIARIO DE CARGAS EM GERAL, MUNICIPAL",
  },
  {
    cnae: "4930201",
    descricao: "TRANSPORTE RODOVIARIO DE CARGA VIVA, MUNICIPAL",
  },
  {
    cnae: "4930201",
    descricao: "TRANSPORTE RODOVIARIO DE ANIMAIS VIVOS, MUNICIPAL",
  },
  {
    cnae: "4930202",
    descricao:
      "TRANSPORTE RODOVIARIO DE ANIMAIS VIVOS, INTERMUNICIPAL, INTERESTADUAL E INTERNACIONAL",
  },
  {
    cnae: "4930202",
    descricao:
      "TRANSPORTE RODOVIARIO INTERESTADUAL, INTERNACIONAL DE CARGA EM GERAL",
  },
  {
    cnae: "4930202",
    descricao: "TRANSPORTE RODOVIARIO DE CARGAS EM GERAL, INTERESTADUAL",
  },
  {
    cnae: "4930202",
    descricao:
      "TRANSPORTE RODOVIARIO DE CARGA VIVA, INTERMUNICIPAL, INTERESTADUAL E INTERNACIONAL",
  },
  {
    cnae: "4930202",
    descricao:
      "TRANSPORTE RODOVIARIO DE CARGA, INTERMUNICIPAL, INTERESTADUAL E INTERNACIONAL",
  },
  {
    cnae: "4930202",
    descricao:
      "TRANSPORTE RODOVIARIO INTERMUNICIPAL, INTERESTADUAL E INTERNACIONAL DE CARGAS EM GERAL, EM CONTEINERES",
  },
  {
    cnae: "4930202",
    descricao:
      "TRANSPORTE EM VEICULOS DE TRACAO ANIMAL OU HUMANA - EXCETO NO AMBITO MUNICIPAL",
  },
  {
    cnae: "4930202",
    descricao: "TRANSPORTE RODOVIARIO DE CARGAS EM GERAL, INTERMUNICIPAL",
  },
  {
    cnae: "4930203",
    descricao: "PRODUTOS PERIGOSOS EM CONTEINERES; TRANSPORTE RODOVIARIO DE",
  },
  {
    cnae: "4930203",
    descricao: "PRODUTOS RADIOATIVOS; TRANSPORTE RODOVIARIO DE",
  },
  {
    cnae: "4930203",
    descricao: "PRODUTOS CORROSIVOS; TRANSPORTE RODOVIARIO DE",
  },
  {
    cnae: "4930203",
    descricao: "CAMINHAO TANQUE DE COMBUSTIVEIS; TRANSPORTE RODOVIARIO",
  },
  {
    cnae: "4930203",
    descricao: "MATERIAS RADIOATIVAS; TRANSPORTE RODOVIARIO DE",
  },
  { cnae: "4930203", descricao: "OLEO DIESEL; TRANSPORTE RODOVIARIO DE" },
  {
    cnae: "4930203",
    descricao: "MATERIAS CORROSIVAS; TRANSPORTE RODOVIARIO DE",
  },
  {
    cnae: "4930203",
    descricao: "SUBSTANCIAS TOXICAS OU INFECTANTES; TRANSPORTE RODOVIARIO DE",
  },
  { cnae: "4930203", descricao: "TRANSPORTE RODOVIARIO DE CARGAS PERIGOSAS" },
  {
    cnae: "4930203",
    descricao: "PRODUTOS INFLAMAVEIS LIQUIDOS; TRANSPORTE RODOVIARIO DE",
  },
  { cnae: "4930203", descricao: "PRODUTOS PERIGOSOS; TRANSPORTE RODOVIARIO" },
  { cnae: "4930203", descricao: "GASOLINA; TRANSPORTE RODOVIARIO DE" },
  { cnae: "4930203", descricao: "EXPLOSIVOS; TRANSPORTE RODOVIARIO DE" },
  {
    cnae: "4930203",
    descricao: "PRODUTOS INFLAMAVEIS SOLIDOS; TRANSPORTE RODOVIARIO DE",
  },
  { cnae: "4930203", descricao: "COMBUSTIVEIS; TRANSPORTE RODOVIARIO DE" },
  {
    cnae: "4930203",
    descricao: "SUBSTANCIAS OXIDANTES; TRANSPORTE RODOVIARIO DE",
  },
  {
    cnae: "4930203",
    descricao: "GASES DE TODOS OS TIPOS; TRANSPORTE RODOVIARIO DE",
  },
  { cnae: "4930204", descricao: "MUDANCAS; TRANSPORTE RODOVIARIO DE" },
  {
    cnae: "4930204",
    descricao:
      "GUARDA MOVEIS QUANDO INTEGRADO A EMPRESAS DE TRANSPORTE DE MUDANCAS; SERVICOS DE",
  },
  { cnae: "4930204", descricao: "SERVICOS DE MUDANCA NO MESMO IMOVEL (LOCAL)" },
  { cnae: "49400", descricao: "TRANSPORTE DUTOVIARIO" },
  {
    cnae: "4940000",
    descricao: "BOMBEAMENTO DE COMBUSTIVEIS EM REFINARIAS; SERVICOS DE",
  },
  { cnae: "4940000", descricao: "TRANSPORTE DUTOVIARIO" },
  { cnae: "4940000", descricao: "DUTOS DE TRANSPORTE; MANUTENCAO DE" },
  {
    cnae: "4940000",
    descricao: "ESTACAO DE OPERACAO E BOMBEAMENTO; TRANSPORTE DUTOVIARIO",
  },
  { cnae: "4940000", descricao: "TRANSPORTE POR TUBULACAO" },
  {
    cnae: "4940000",
    descricao: "TRANSPORTE DE GASES, LIQUIDOS, GRAOS E MINERIOS POR DUTOS",
  },
  { cnae: "49507", descricao: "TRENS TURISTICOS, TELEFERICOS E SIMILARES" },
  {
    cnae: "4950700",
    descricao: "TRANSPORTE DE PASSAGEIROS EM TRENS TURISTICOS",
  },
  {
    cnae: "4950700",
    descricao:
      "TRANSPORTE TURISTICO DE PASSAGEIROS EM TELEFERICOS, FUNICULARES E SIMILARES",
  },
  { cnae: "50114", descricao: "TRANSPORTE MARITIMO DE CABOTAGEM" },
  {
    cnae: "5011401",
    descricao:
      "TRANSPORTE HIDROVIARIO DE CARGAS, REGULAR E NAO REGULAR NO LITORAL",
  },
  {
    cnae: "5011401",
    descricao:
      "TRANSPORTE AQUAVIARIO DE CARGAS, REGULAR E NAO REGULAR NO LITORAL",
  },
  {
    cnae: "5011401",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE HIDROVIARIO DE CARGAS NO LITORAL; LOCACAO OU ALUGUEL DE",
  },
  { cnae: "5011401", descricao: "TRANSPORTE MARITIMO DE CABOTAGEM - CARGA" },
  {
    cnae: "5011401",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE AQUAVIARIO DE CARGAS NO LITORAL; LOCACAO OU ALUGUEL DE",
  },
  {
    cnae: "5011402",
    descricao:
      "TRANSPORTE AQUAVIARIO DE PASSAGEIROS, REGULAR E NAO REGULAR NO LITORAL",
  },
  {
    cnae: "5011402",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE HIDROVIARIO DE PASSAGEIROS, NO LITORAL; LOCACAO OU ALUGUEL DE",
  },
  {
    cnae: "5011402",
    descricao: "TRANSPORTE HIDROVIARIO DE PASSAGEIROS NO LITORAL",
  },
  {
    cnae: "5011402",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE AQUAVIARIO DE PASSAGEIROS, NO LITORAL; LOCACAO OU ALUGUEL DE",
  },
  {
    cnae: "5011402",
    descricao: "TRANSPORTE MARITIMO DE CABOTAGEM - PASSAGEIROS",
  },
  { cnae: "50122", descricao: "TRANSPORTE MARITIMO DE LONGO CURSO" },
  {
    cnae: "5012201",
    descricao: "TRANSPORTE MARITIMO DE LONGO CURSO INTERNACIONAL DE CARGA",
  },
  {
    cnae: "5012201",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE MARITIMO DE LONGO CURSO INTERNACIONAL DE CARGA; FRETAMENTO DE",
  },
  {
    cnae: "5012201",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE MARITIMO DE LONGO CURSO INTERNACIONAL DE CARGA; LOCACAO OU ALUGUEL DE",
  },
  {
    cnae: "5012202",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE MARITIMO DE LONGO CURSO INTERNACIONAL DE PASSAGEIROS; FRETAMENTO DE",
  },
  {
    cnae: "5012202",
    descricao:
      "TRANSPORTE MARITIMO DE LONGO CURSO INTERNACIONAL DE PASSAGEIROS",
  },
  {
    cnae: "5012202",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE MARITIMO DE LONGO CURSO INTERNACIONAL DE PASSAGEIROS; LOCACAO OU ALUGUEL DE",
  },
  { cnae: "50211", descricao: "TRANSPORTE POR NAVEGACAO INTERIOR DE CARGA" },
  {
    cnae: "5021101",
    descricao:
      "TRANSPORTE HIDROVIARIO DE CARGA, MUNICIPAL, EXCETO DE TRAVESSIA, POR RIOS, LAGOAS, CANAIS E OUTRAS VIAS DE NAVEGACAO INTERIOR",
  },
  {
    cnae: "5021101",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE HIDROVIARIO DE CARGA, MUNICIPAL, POR NAVEGACAO INTERIOR EXCETO DE TRAVESSIA; FRETAMENTO DE",
  },
  {
    cnae: "5021101",
    descricao:
      "TRANSPORTE HIDROVIARIO DE CARGA, MUNICIPAL, POR NAVEGACAO INTERIOR, EXCETO DE TRAVESSIA",
  },
  {
    cnae: "5021101",
    descricao:
      "TRANSPORTE AQUAVIARIO DE CARGA, MUNICIPAL, POR NAVEGACAO INTERIOR, EXCETO TRAVESSIA",
  },
  {
    cnae: "5021101",
    descricao:
      "TRANSPORTE POR NAVEGACAO INTERIOR DE CARGA, MUNICIPAL, EXCETO TRAVESSIA",
  },
  {
    cnae: "5021101",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE AQUAVIARIO DE CARGA, MUNICIPAL, POR NAVEGACAO INTERIOR, EXCETO TRAVESSIA; LOCACAO, ALUGUEL DE",
  },
  {
    cnae: "5021101",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE HIDROVIARIO DE CARGA, MUNICIPAL, POR NAVEGACAO INTERIOR EXCETO DE TRAVESSIA; LOCACAO OU ALUGUEL DE",
  },
  {
    cnae: "5021101",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE AQUAVIARIO DE CARGA, MUNICIPAL, POR NAVEGACAO INTERIOR, EXCETO TRAVESSIA; FRETAMENTO DE",
  },
  {
    cnae: "5021101",
    descricao:
      "TRANSPORTE AQUAVIARIO DE CARGA, MUNICIPAL, EXCETO DE TRAVESSIA, POR RIOS, LAGOAS, CANAIS E OUTRAS VIAS DE NAVEGACAO INTERIOR",
  },
  {
    cnae: "5021102",
    descricao:
      "TRANSPORTE AQUAVIARIO DE CARGA, INTERMUNICIPAL, EXCETO DE TRAVESSIA, POR RIOS, LAGOAS, CANAIS E OUTRAS VIAS DE NAVEGACAO INTERIOR",
  },
  {
    cnae: "5021102",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE AQUAVIARIO DE CARGA POR NAVEGACAO INTERIOR, INTERMUNICIPAL (EXCETO TRAVESSIA), INTERESTADUAL E INTERNACIONAL; LOCACAO, ALUGUEL DE",
  },
  {
    cnae: "5021102",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE AQUAVIARIO DE CARGA POR NAVEGACAO INTERIOR, INTERMUNICIPAL (EXCETO TRAVESSIA), INTERESTADUAL E INTERNACIONAL; FRETAMENTO DE",
  },
  {
    cnae: "5021102",
    descricao:
      "TRANSPORTE HIDROVIARIO DE CARGA, MUNICIPAL, EXCETO DE TRAVESSIA, POR RIOS, LAGOAS, CANAIS E OUTRAS VIAS DE NAVEGACAO INTERIOR",
  },
  {
    cnae: "5021102",
    descricao:
      "TRANSPORTE POR NAVEGACAO INTERIOR DE CARGA INTERESTADUAL E INTERNACIONAL",
  },
  {
    cnae: "5021102",
    descricao:
      "TRANSPORTE AQUAVIARIO DE CARGA, INTERMUNICIPAL, POR NAVEGACAO INTERIOR, EXCETO TRAVESSIA",
  },
  {
    cnae: "5021102",
    descricao:
      "TRANSPORTE AQUAVIARIO DE CARGA, INTERESTADUAL E INTERNACIONAL, POR NAVEGACAO INTERIOR",
  },
  {
    cnae: "5021102",
    descricao:
      "TRANSPORTE HIDROVIARIO DE CARGA, INTERMUNICIPAL, POR NAVEGACAO INTERIOR, EXCETO TRAVESSIA",
  },
  {
    cnae: "5240101",
    descricao: "EXPLORACAO DE AEROPORTOS E CAMPOS DE ATERRISSAGEM",
  },
  {
    cnae: "5021102",
    descricao:
      "TRANSPORTE POR NAVEGACAO INTERIOR DE CARGA INTERMUNICIPAL, EXCETO TRAVESSIA",
  },
  {
    cnae: "5021102",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE HIDROVIARIO DE CARGA POR NAVEGACAO INTERIOR, INTERMUNICIPAL (EXCETO TRAVESSIA), INTERESTADUAL E INTERNACIONAL; LOCACAO, ALUGUEL DE",
  },
  { cnae: "5099801", descricao: "TURISMO FLUVIAL; SERVICOS DE" },
  {
    cnae: "5099801",
    descricao: "TRANSPORTE HIDROVIARIO TURISTICO; SERVICOS DE",
  },
  {
    cnae: "5021102",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE HIDROVIARIO DE CARGA POR NAVEGACAO INTERIOR, INTERMUNICIPAL (EXCETO TRAVESSIA), INTERESTADUAL E INTERNACIONAL; FRETAMENTO DE",
  },
  {
    cnae: "5021102",
    descricao:
      "TRANSPORTE HIDROVIARIO DE CARGA, INTERESTADUAL E INTERNACIONAL, POR NAVEGACAO INTERIOR",
  },
  {
    cnae: "50220",
    descricao:
      "TRANSPORTE POR NAVEGACAO INTERIOR DE PASSAGEIROS EM LINHAS REGULARES",
  },
  {
    cnae: "5022001",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE HIDROVIARIO DE PASSAGEIROS, MUNICIPAL, EM LINHAS REGULARES, POR NAVEGACAO INTERIOR, EXCETO TRAVESSIA; FRETAMENTO DE",
  },
  {
    cnae: "5022001",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE AQUAVIARIO DE PASSAGEIROS, MUNICIPAL, EM LINHAS REGULARES, POR NAVEGACAO INTERIOR, EXCETO TRAVESSIA; FRETAMENTO DE",
  },
  {
    cnae: "5022001",
    descricao:
      "TRANSPORTE AQUAVIARIO DE PASSAGEIROS MUNICIPAL, EXCETO DE TRAVESSIA, POR RIOS, LAGOAS, CANAIS E OUTRAS VIAS DE NAVEGACAO INTERIOR",
  },
  {
    cnae: "5022001",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE AQUAVIARIO DE PASSAGEIROS, MUNICIPAL, EM LINHAS REGULARES, POR NAVEGACAO INTERIOR, EXCETO TRAVESSIA; LOCACAO, ALUGUEL DE",
  },
  {
    cnae: "5022001",
    descricao:
      "TRANSPORTE HIDROVIARIO DE PASSAGEIROS, EM LINHAS REGULARES, MUNICIPAL, POR NAVEGACAO INTERIOR, EXCETO TRAVESSIA",
  },
  {
    cnae: "5022001",
    descricao:
      "TRANSPORTE POR NAVEGACAO INTERIOR (RIOS, CANAIS, LAGOS, LAGOAS) DE PASSAGEIROS, MUNICIPAL, EXCETO TRAVESSIA",
  },
  {
    cnae: "5022001",
    descricao:
      "TRANSPORTE POR NAVEGACAO INTERIOR DE PASSAGEIROS EM LINHAS REGULARES, MUNICIPAL, EXCETO TRAVESSIA",
  },
  {
    cnae: "5022001",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE HIDROVIARIO DE PASSAGEIROS, MUNICIPAL, EM LINHAS REGULARES, POR NAVEGACAO INTERIOR, EXCETO TRAVESSIA; LOCACAO, ALUGUEL DE",
  },
  {
    cnae: "5022001",
    descricao:
      "TRANSPORTE HIDROVIARIO DE PASSAGEIROS MUNICIPAL, EXCETO DE TRAVESSIA, POR RIOS, LAGOAS, CANAIS E OUTRAS VIAS DE NAVEGACAO INTERIOR",
  },
  {
    cnae: "5022001",
    descricao:
      "TRANSPORTE AQUAVIARIO DE PASSAGEIROS, EM LINHAS REGULARES, MUNICIPAL, POR NAVEGACAO INTERIOR, EXCETO TRAVESSIA",
  },
  {
    cnae: "5022002",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE HIDROVIARIO DE PASSAGEIROS POR NAVEGACAO INTERIOR, INTERMUNICIPAL (EXCETO TRAVESSIA), INTERESTADUAL E INTERNACIONAL; FRETAMENTO DE",
  },
  {
    cnae: "5022002",
    descricao:
      "TRANSPORTE HIDROVIARIO DE PASSAGEIROS, EM LINHAS REGULARES, INTERESTADUAL E INTERNACIONAL, POR NAVEGACAO INTERIOR",
  },
  {
    cnae: "5022002",
    descricao:
      "TRANSPORTE POR NAVEGACAO INTERIOR (RIOS, CANAIS, LAGOS, LAGOAS) DE PASSAGEIROS, INTERMUNICIPAL (EXCETO TRAVESSIA), INTERESTADUAL E INTERNACIONAL, EM LINHAS REGULARES",
  },
  {
    cnae: "5022002",
    descricao:
      "TRANSPORTE AQUAVIARIO DE PASSAGEIROS, EM LINHAS REGULARES, INTERMUNICIPAL, POR NAVEGACAO INTERIOR, EXCETO TRAVESSIA",
  },
  {
    cnae: "5022002",
    descricao:
      "TRANSPORTE HIDROVIARIO DE PASSAGEIROS, EM LINHAS REGULARES, INTERMUNICIPAL, POR NAVEGACAO INTERIOR, EXCETO TRAVESSIA",
  },
  {
    cnae: "5022002",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE AQUAVIARIO DE PASSAGEIROS POR NAVEGACAO INTERIOR, INTERMUNICIPAL (EXCETO TRAVESSIA), INTERESTADUAL E INTERNACIONAL; LOCACAO, ALUGUEL DE",
  },
  {
    cnae: "5022002",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE HIDROVIARIO DE PASSAGEIROS POR NAVEGACAO INTERIOR, INTERMUNICIPAL (EXCETO TRAVESSIA), INTERESTADUAL E INTERNACIONAL; LOCACAO, ALUGUEL DE",
  },
  {
    cnae: "5022002",
    descricao:
      "TRANSPORTE AQUAVIARIO DE PASSAGEIROS, EM LINHAS REGULARES, INTERESTADUAL E INTERNACIONAL, POR NAVEGACAO INTERIOR",
  },
  {
    cnae: "5022002",
    descricao:
      "TRANSPORTE POR NAVEGACAO INTERIOR DE PASSAGEIROS EM LINHAS REGULARES, INTERMUNICIPAL, INTERESTADUAL E INTERNACIONAL, EXCETO TRAVESSIA",
  },
  {
    cnae: "5022002",
    descricao:
      "TRANSPORTE HIDROVIARIO DE PASSAGEIROS INTERMUNICIPAL (EXCETO TRAVESSIA), INTERESTADUAL E INTERNACIONAL, POR RIOS, LAGOAS, CANAIS E OUTRAS VIAS DE NAVEGACAO INTERIOR, EM LINHAS REGULARES",
  },
  {
    cnae: "5022002",
    descricao:
      "TRANSPORTE AQUAVIARIO DE PASSAGEIROS INTERMUNICIPAL (EXCETO TRAVESSIA), INTERESTADUAL E INTERNACIONAL, POR RIOS, LAGOAS, CANAIS E OUTRAS VIAS DE NAVEGACAO INTERIOR, EM LINHAS REGULARES",
  },
  {
    cnae: "5022002",
    descricao:
      "EMBARCACOES COM TRIPULACAO PARA TRANSPORTE AQUAVIARIO DE PASSAGEIROS POR NAVEGACAO INTERIOR, INTERMUNICIPAL (EXCETO TRAVESSIA), INTERESTADUAL E INTERNACIONAL; FRETAMENTO DE",
  },
  { cnae: "50301", descricao: "NAVEGACAO DE APOIO" },
  { cnae: "5030101", descricao: "NAVEGACAO OFFSHORE; SERVICOS DE" },
  {
    cnae: "5030101",
    descricao:
      "TRANSPORTE DE MERCADORIAS E PESSOAS PARA SUPRIMENTO E APOIO A PLATAFORMAS DE PESQUISA",
  },
  {
    cnae: "5030101",
    descricao:
      "TRANSPORTE DE MERCADORIAS E PESSOAS PARA SUPRIMENTO E APOIO A NAVIOS",
  },
  {
    cnae: "5030101",
    descricao:
      "TRANSPORTE DE MERCADORIAS E PESSOAS PARA SUPRIMENTO E APOIO A PLATAFORMAS DE EXPLORACAO DE MINERAIS E HIDROCARBONETOS",
  },
  {
    cnae: "5030101",
    descricao: "APOIO MARITIMO (APOIO A PLATAFORMAS); SERVICOS DE",
  },
  { cnae: "5030101", descricao: "NAVEGACAO DE APOIO MARITIMO; SERVICOS DE" },
  {
    cnae: "5030102",
    descricao: "SOCORRO REALIZADO POR EMPRESAS DE APOIO PORTUARIO; SERVICOS DE",
  },
  { cnae: "5030102", descricao: "NAVEGACAO DE APOIO PORTUARIO; SERVICOS DE" },
  { cnae: "5030102", descricao: "DESENCALHE DE EMBARCACOES; SERVICOS DE" },
  {
    cnae: "5030102",
    descricao:
      "SALVAMENTO REALIZADO POR EMPRESAS DE APOIO PORTUARIO; SERVICOS DE",
  },
  { cnae: "5030102", descricao: "REFLUTACAO DE EMBARCACOES ; SERVICOS DE" },
  {
    cnae: "5030102",
    descricao: "SALVAMENTO DE EMBARCACOES E CARGAS; SERVICOS DE",
  },
  {
    cnae: "5030103",
    descricao: "SOCORRO E SALVAMENTO (SALVATAGEM); SERVICOS DE",
  },
  {
    cnae: "5030103",
    descricao:
      "REBOCADOR, REBOCAGEM REALIZADO POR EMPRESAS DE APOIO MARITIMO; SERVICOS DE",
  },
  {
    cnae: "5030103",
    descricao:
      "REBOCADORES E EMPURRADORES AQUAVIARIO E HIDROVIARIO; SERVICOS DE",
  },
  {
    cnae: "5030103",
    descricao:
      "REBOCADORES E EMPURRADORES REALIZADO POR EMPRESAS DE APOIO PORTUARIO; SERVICOS DE",
  },
  {
    cnae: "5030103",
    descricao: "REBOQUE AQUAVIARIO, HIDROVIARIO; SERVICOS DE",
  },
  { cnae: "50912", descricao: "TRANSPORTE POR NAVEGACAO DE TRAVESSIA" },
  {
    cnae: "5091201",
    descricao: "TRANSPORTE AQUAVIARIO DE CARGAS MUNICIPAL, URBANO",
  },
  {
    cnae: "5091201",
    descricao:
      "EMBARCACOES PARA TRANSPORTE AQUAVIARIO MUNICIPAL URBANO, COM TRIPULACAO; LOCACAO DE",
  },
  {
    cnae: "5091201",
    descricao: "TRANSPORTE AQUAVIARIO DE PASSAGEIROS, MUNICIPAL; SERVICOS DE",
  },
  {
    cnae: "5091201",
    descricao: "TRANSPORTE POR NAVEGACAO DE TRAVESSIA, MUNICIPAL; SERVICOS DE",
  },
  {
    cnae: "5091201",
    descricao:
      "ALUGUEL DE EMBARCACOES PARA TRANSPORTE AQUAVIARIO MUNICIPAL URBANO, COM TRIPULACAO",
  },
  { cnae: "5091201", descricao: "TRANSPORTE AQUAVIARIO MUNICIPAL, URBANO" },
  {
    cnae: "5091202",
    descricao:
      "TRANSPORTE POR NAVEGACAO DE TRAVESSIA, INTERESTADUAL SERVICOS DE",
  },
  {
    cnae: "5091202",
    descricao:
      "EMBARCACOES PARA TRANSPORTE AQUAVIARIO INTERMUNICIPAL COM TRIPULACAO; LOCACAO DE",
  },
  {
    cnae: "5091202",
    descricao:
      "TRANSPORTE POR NAVEGACAO DE TRAVESSIA, INTERNACIONAL SERVICOS DE",
  },
  { cnae: "5091202", descricao: "TRANSPORTE AQUAVIARIO INTERMUNICIPAL" },
  {
    cnae: "5091202",
    descricao:
      "ALUGUEL DE EMBARCACOES PARA TRANSPORTE AQUAVIARIO INTERMUNICIPAL, COM TRIPULACAO",
  },
  {
    cnae: "5091202",
    descricao:
      "TRANSPORTE POR NAVEGACAO DE TRAVESSIA, INTERMUNICIPAL SERVICOS DE",
  },
  {
    cnae: "5091202",
    descricao: "TRANSPORTE AQUAVIARIO DE CARGAS INTERMUNICIPAL",
  },
  {
    cnae: "5091202",
    descricao:
      "TRANSPORTE AQUAVIARIO DE PASSAGEIROS, INTERMUNICIPAL; SERVICOS DE",
  },
  {
    cnae: "50998",
    descricao: "TRANSPORTES AQUAVIARIOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "5099801",
    descricao: "TRANSPORTE AQUAVIARIO TURISTICO; SERVICOS DE",
  },
  {
    cnae: "5099801",
    descricao:
      "PASSEIO TURISTICO EM AGUAS COSTEIRA OU EM VIAS INTERNAS; SERVICOS DE",
  },
  { cnae: "5099801", descricao: "PASSEIO DE ESCUNA; SERVICOS DE" },
  {
    cnae: "5099899",
    descricao:
      "SERVICOS COMBINADOS DE TRANSPORTE AQUAVIARIO DE PASSAGEIROS ASSOCIADO COM OS SERVICOS DE ALOJAMENTO E ALIMENTACAO",
  },
  {
    cnae: "5099899",
    descricao:
      "FLOATEL OU HOTEL FLUTUANTE PARA SUPORTE E APOIO DE UNIDADES DE PRODUCAO OFFSHORE; SERVICOS DE",
  },
  {
    cnae: "5099899",
    descricao:
      "ALOJAMENTO EM EMBARCACOES PARA SUPORTEE APOIO DE UNIDADES DE PRODUCAO OFFSHORE; SERVICOS DE",
  },
  {
    cnae: "5099899",
    descricao:
      "OPERACAO DE NAVIOS COM UNIDADES DE ALOJAMENTO PARA APOIO NAS OPERACOES DE PLATAFORMAS MARITIMAS OFFSHORE DE PETROLEO; SERVICOS DE",
  },
  { cnae: "51111", descricao: "TRANSPORTE AEREO DE PASSAGEIROS REGULAR" },
  {
    cnae: "5111100",
    descricao: "TRANSPORTE AEREO DE PASSAGEIROS REGULAR; SERVICO DE",
  },
  {
    cnae: "5111100",
    descricao: "TRANSPORTE AEREO DE PASSAGEIROS REGULAR, EM LINHAS DOMESTICAS",
  },
  {
    cnae: "5111100",
    descricao: "TRANSPORTE AEREO DE PASSAGEIROS, REGULAR, INTERNACIONAL",
  },
  { cnae: "5111100", descricao: "TRANSPORTE AEREO DE PASSAGEIROS, REGULAR" },
  { cnae: "51129", descricao: "TRANSPORTE AEREO DE PASSAGEIROS NAOREGULAR" },
  { cnae: "5112901", descricao: "ALUGUEL DE HELICOPTEROS COM TRIPULACAO" },
  {
    cnae: "5112901",
    descricao: "LOCACAO DE AERONAVES COM TRIPULACAO; SERVICOS DE",
  },
  {
    cnae: "5112901",
    descricao:
      "AERONAVES COM TRIPULACAO PARA TRANSPORTE DE PASSAGEIROS; FRETAMENTO DE",
  },
  {
    cnae: "5112901",
    descricao: "AEROCLUBES COM FINS DE INSTRUCAO OU RECREACAO; SERVICOS DE",
  },
  {
    cnae: "5112901",
    descricao: "AERONAVES PARA FINS DE PASSEIOS TURISTICOS; TRANSPORTE EM",
  },
  { cnae: "5112901", descricao: "AERONAVES COM TRIPULACAO; LOCACAO DE" },
  { cnae: "5112901", descricao: "ALUGUEL DE AERONAVES COM TRIPULACAO" },
  { cnae: "5112901", descricao: "TAXI AEREO; SERVICOS DE" },
  { cnae: "5112901", descricao: "ALUGUEL DE AVIOES COM TRIPULACAO" },
  { cnae: "5112999", descricao: "VOO PANORAMICO; SERVICOS DE" },
  { cnae: "51200", descricao: "TRANSPORTE AEREO DE CARGA" },
  { cnae: "5120000", descricao: "TRANSPORTE AEREO DE CARGAS, REGULAR" },
  {
    cnae: "5120000",
    descricao: "TRANSPORTE AEREO DE CARGAS, REGULAR, INTERNACIONAL",
  },
  { cnae: "5120000", descricao: "TRANSPORTE AEREO DE CARGA; SERVICOS DE" },
  {
    cnae: "5120000",
    descricao: "TRANSPORTE AEREO DE CARGAS, REGULAR, EM LINHAS DOMESTICAS",
  },
  { cnae: "51307", descricao: "TRANSPORTE ESPACIAL" },
  {
    cnae: "5130700",
    descricao: "LANCAMENTO DE SATELITES E VEICULOS ESPACIAIS; TRANSPORTE",
  },
  { cnae: "5130700", descricao: "TRANSPORTE ESPACIAL" },
  { cnae: "52117", descricao: "ARMAZENAMENTO" },
  { cnae: "5211701", descricao: "ARMAZENS GERAIS (EMISSAO DE WARRANT)" },
  { cnae: "5211701", descricao: "DEPOSITO GERAL (EMISSAO DE WARRANT)" },
  {
    cnae: "5211702",
    descricao:
      "GUARDA DE DOCUMENTOS E ARQUIVOS NAO ASSOCIADO AO TRANSPORTE DE MUDANCAS; SERVICOS DE",
  },
  {
    cnae: "5211702",
    descricao:
      "DEPOSITO DE MOVEIS NAO ASSOCIADO AO TRANSPORTE DE MUDANCAS; SERVICOS DE",
  },
  {
    cnae: "5211702",
    descricao:
      "GUARDA MOVEIS NAO ASSOCIADO AO TRANSPORTE DE MUDANCAS; SERVICOS DE",
  },
  { cnae: "5211702", descricao: "GUARDA-MOVEIS; SERVICOS DE" },
  {
    cnae: "5211799",
    descricao:
      "GUARDA VOLUMES NAO VINCULADA A ATIVIDADE DE TRANSPORTE; SERVICOS DE",
  },
  {
    cnae: "5211799",
    descricao: "ARMAZENAMENTO DE PRODUTOS PERIGOSOS POR CONTA DE TERCEIROS",
  },
  {
    cnae: "5211799",
    descricao: "ARMAZENAGEM DE CEREAIS POR CONTA DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "5211799",
    descricao:
      "ARMAZENAGEM DE PRODUTOS AGRICOLAS POR CONTA DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "5211799",
    descricao:
      "ARMAZENAGEM DE PRODUTOS AGROPECUARIOS POR CONTA DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "5211799",
    descricao:
      "DEPOSITOS DE MERCADORIAS PARA TERCEIROS, EXCETO ARMAZENS GERAIS E GUARDA-MOVEIS",
  },
  {
    cnae: "5211799",
    descricao:
      "ARMAZENAGEM DE MERCADORIAS EM GERAL POR CONTA DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "5211799",
    descricao: "ARMAZENAGEM DE GRAOS POR CONTA DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "5211799",
    descricao:
      "CAMARAS FRIGORIFICAS; SERVICOS DE ARMAZENAMENTO POR CONTA DE TERCEIROS",
  },
  { cnae: "5211799", descricao: "DEPOSITO DE PRODUTOS POR CONTA DE TERCEIROS" },
  {
    cnae: "5211799",
    descricao:
      "ARMAZENAMENTO DE PRODUTOS EM ZONA FRANCA POR CONTA DE TERCEIROS",
  },
  {
    cnae: "5211799",
    descricao: "SILO; SERVICOS DE ARMAZENAMENTO POR CONTA DE TERCEIROS",
  },
  {
    cnae: "5211799",
    descricao: "ARMAZENAMENTO DE CARGAS POR CONTA DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "5211799",
    descricao: "ARMAZENAMENTO DE PRODUTOS EM GERAL POR CONTA DE TERCEIROS",
  },
  { cnae: "52125", descricao: "CARGA E DESCARGA" },
  { cnae: "5212500", descricao: "CARGA E DESCARGA; SERVICOS DE" },
  {
    cnae: "5212500",
    descricao: "CARREGAMENTO E DESCARREGAMENTO DE CARGA; SERVICOS DE",
  },
  { cnae: "5212500", descricao: "TRANSBORDO DE MERCADORIA; SERVICOS DE" },
  { cnae: "5212500", descricao: "CONTAINERES; MOVIMENTACAO DE" },
  { cnae: "5212500", descricao: "BALDEACAO, BALDEIO; SERVICOS DE" },
  {
    cnae: "5212500",
    descricao: "CARREGAMENTO E DESCARREGAMENTO DE BAGAGENS; SERVICOS DE",
  },
  { cnae: "5212500", descricao: "ESTIVA, DESESTIVA, CAPATAZIA; SERVICOS DE" },
  {
    cnae: "5212500",
    descricao:
      "CARGA E DESCARGA, POR MANUSEIO OU NAO, DE BAGAGENS; SERVICOS DE",
  },
  { cnae: "5212500", descricao: "CONTEINERES; MOVIMENTACAO DE" },
  { cnae: "5212500", descricao: "PATIAMENTO DE CARROS; SERVICOS DE" },
  {
    cnae: "5212500",
    descricao:
      "CARGA E DESCARGA COM LOCACAO DE MAO-DE-OBRA E EQUIPAMENTO DE MOVIMENTACAO AO CONTRATANTE; SERVICOS DE",
  },
  { cnae: "5212500", descricao: "PEACAO; SERVICOS DE" },
  { cnae: "5212500", descricao: "MOVIMENTACAO DE CONTEINERES" },
  { cnae: "5212500", descricao: "EMBARQUE E DESEMBARQUE DE CONTEINERES" },
  {
    cnae: "5212500",
    descricao: "DESEMBARQUE DE MERCADORIAS, PRODUTOS; SERVICOS DE",
  },
  {
    cnae: "5212500",
    descricao:
      "GUINCHOS, GUINDASTES, EMPILHADEIRAS PARA MOVIMENTACAO, CARGA E DESCARGA DE MERCADORIAS, COM OPERADOR; ALUGUEL DE",
  },
  {
    cnae: "5212500",
    descricao:
      "ESTUFACAO, ARRUMACAO DE MERCADORIAS EM CONTEINERES; SERVICOS DE",
  },
  {
    cnae: "5212500",
    descricao:
      "GUINDASTES PARA CARGA E DESCARGA DE MERCADORIAS COM OPERADOR; LOCACAO DE",
  },
  { cnae: "5212500", descricao: "MANUSEIO DE BAGAGENS; SERVICOS DE" },
  { cnae: "5212500", descricao: "CARGA, MOVIMENTACAO DE" },
  {
    cnae: "52214",
    descricao:
      "CONCESSIONARIAS DE RODOVIAS, PONTES, TUNEIS E SERVICOS RELACIONADOS",
  },
  {
    cnae: "5221400",
    descricao: "CONCESSAO, CONCESSIONARIA DE RODOVIAS; SERVICOS DE",
  },
  { cnae: "5221400", descricao: "ADMINISTRACAO DE ESTRADAS; SERVICOS DE" },
  { cnae: "5221400", descricao: "OPERACAO DE ESTRADAS; SERVICOS DE" },
  {
    cnae: "5221400",
    descricao:
      "ADMINISTRACAO E CONSERVACAO DE RODOVIAS, PONTES E/OU TUNEIS; SERVICO DE",
  },
  {
    cnae: "5221400",
    descricao: "COBRANCA DE PEDAGIO EM RODOVIAS; SERVICOS DE",
  },
  { cnae: "5221400", descricao: "EXPLORACAO DE PEDAGIOS" },
  {
    cnae: "5221400",
    descricao: "OPERACAO DE PONTES, TUNEIS, RODOVIAS E SERVICOS RELACIONADOS",
  },
  {
    cnae: "5221400",
    descricao: "ARRECADACAO DE PEDAGIOS (POR CONCESSIONARIAS); SERVICOS DE",
  },
  { cnae: "52222", descricao: "TERMINAIS RODOVIARIOS E FERROVIARIOS" },
  {
    cnae: "5222200",
    descricao: "OPERACAO EM TERMINAIS FERROVIARIOS; SERVICOS DE",
  },
  { cnae: "5222200", descricao: "EXPLORACAO DE TERMINAL RODO-FERROVIARIO" },
  {
    cnae: "5222200",
    descricao: "OPERACAO EM TERMINAIS RODOVIARIOS; SERVICOS DE",
  },
  { cnae: "5222200", descricao: "TERMINAL FERROVIARIO; EXPLORACAO DE" },
  { cnae: "5222200", descricao: "TERMINAL RODOVIARIO; EXPLORACAO DE" },
  { cnae: "52231", descricao: "ESTACIONAMENTO DE VEICULOS" },
  {
    cnae: "5223100",
    descricao: "ESTACIONAMENTO DE MOTOS E BICICLETAS; EXPLORACAO DE",
  },
  { cnae: "5223100", descricao: "ESTADIA, GUARDA DE VEICULOS; SERVICOS DE" },
  { cnae: "5223100", descricao: "ESTACIONAMENTO PRIVATIVO DO TIPO DRIVE-IN" },
  { cnae: "5223100", descricao: "GUARDA DE VEICULOS; SERVICOS DE" },
  { cnae: "5223100", descricao: "PARQUEAMENTO DE VEICULOS; SERVICOS DE" },
  { cnae: "5223100", descricao: "PARQUE DE ESTACIONAMENTO PARA VEICULOS" },
  { cnae: "5223100", descricao: "GARAGEM PARA VEICULOS; SERVICOS DE" },
  { cnae: "5223100", descricao: "ESTACIONAMENTO DE AUTOMOVEIS" },
  { cnae: "5223100", descricao: "ESTACIONAMENTO DE VEICULOS; SERVICOS DE" },
  { cnae: "5223100", descricao: "ESTACIONAMENTO; EXPLORACAO DE" },
  {
    cnae: "5223100",
    descricao: "EXPLORACAO DE AREAS DE ESTACIONAMENTO EM VIAS PUBLICAS",
  },
  { cnae: "5223100", descricao: "EDIFICIO GARAGEM; EXPLORACAO DE" },
  {
    cnae: "52290",
    descricao:
      "ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES NAO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "5229001", descricao: "RADIO TAXI; SERVICOS DE" },
  { cnae: "5229001", descricao: "RADIO CHAMADA; SERVICOS DE" },
  { cnae: "5229001", descricao: "TELE TAXI; SERVICOS DE" },
  { cnae: "5229001", descricao: "COOPERATIVAS DE TAXI; SERVICOS DE" },
  {
    cnae: "5229001",
    descricao:
      "APOIO AO TRANSPORTE POR TAXI, INCLUSIVE CENTRAIS DE CHAMADA; SERVICOS DE",
  },
  {
    cnae: "5229001",
    descricao: "CENTRAL DE CHAMADA E RESERVA DE TAXIS; EXPLORACAO DE",
  },
  { cnae: "5229001", descricao: "GESTAO DE APLICATIVOS DE TRANSPORTE" },
  {
    cnae: "5229002",
    descricao: "REBOQUE, GUINCHO DE VEICULO AUTOMOTOR; SERVICOS DE",
  },
  {
    cnae: "5229002",
    descricao: "ASSISTENCIA A VEICULOS EM ESTRADAS; SERVICOS DE",
  },
  { cnae: "5229002", descricao: "REMOCAO DE VEICULOS; SERVICOS DE" },
  {
    cnae: "5229002",
    descricao: "AUTO-SOCORRO (COM USO DE GUINCHO OU REBOQUE); SERVICOS DE",
  },
  {
    cnae: "5229099",
    descricao: "GERENCIAMENTO DE TRANSITO,TRAFEGO; SERVICOS DE",
  },
  {
    cnae: "5229099",
    descricao:
      "ESCOLTA NO TRANSPORTE RODOVIARIO DE CARGAS ESPECIAIS; SERVICOS DE",
  },
  {
    cnae: "5229099",
    descricao: "MONITORAMENTO E CONTROLE DE TRANSITO; SERVICOS DE",
  },
  { cnae: "5229099", descricao: "GESTAO DE TRANSITO, TRAFEGO; SERVICOS DE" },
  {
    cnae: "5229099",
    descricao:
      "GUARDA MALAS EM TERMINAIS, ESTACOES RODOVIARIAS E FERROVIARIAS; SERVICOS DE",
  },
  {
    cnae: "5229099",
    descricao: "GUARDA-VOLUMES EM TERMINAIS RODOVIARIOS; SERVICOS DE",
  },
  {
    cnae: "5229099",
    descricao: "BILHETAGEM ELETRONICA PARA O TRANSPORTE TERRESTRE",
  },
  { cnae: "5229099", descricao: "GESTAO E OPERACAO DE TRAFEGO; SERVICOS DE" },
  { cnae: "5229099", descricao: "LIMPEZA DE LOCOMOTIVAS; SERVICOS DE" },
  { cnae: "5229099", descricao: "PESAGEM DE VEICULOS; SERVICOS DE" },
  {
    cnae: "5229099",
    descricao:
      "TRANSLADO DE PASSAGEIROS ENTRE TERMINAIS, NO TRANSPORTE TERRESTRE; SERVICOS DE",
  },
  {
    cnae: "5229099",
    descricao:
      "LIQUEFACAO DE GAS PARA FINS DE TRANSPORTE EM VEICULOS DUTOS MOVEIS; SERVICOS DE",
  },
  {
    cnae: "5229099",
    descricao: "TRANSLADO DE PASSAGEIROS NO TRANSPORTE TERRESTRE; SERVICOS DE",
  },
  { cnae: "52311", descricao: "GESTAO DE PORTOS E TERMINAIS" },
  { cnae: "5231101", descricao: "INFRA-ESTRUTURA PORTUARIA; ADMINISTRACAO DE" },
  {
    cnae: "5231102",
    descricao:
      "GESTAO E OPERACAO DE TERMINAIS AQUAVIARIOS DE TURISMO; SERVICOS DE",
  },
  {
    cnae: "5231102",
    descricao: "GESTAO DE TERMINAIS DE PASSAGEIROS; SERVICOS DE",
  },
  {
    cnae: "5231102",
    descricao: "PORTOS, TERMINAIS MARITIMOS, ATRACADOUROS; EXPLORACAO DE",
  },
  { cnae: "5231102", descricao: "OPERADORA PORTUARIA; SERVICOS DE" },
  { cnae: "5231102", descricao: "OPERACOES DE TERMINAIS; SERVICOS DE" },
  {
    cnae: "5231102",
    descricao:
      "GESTAO E OPERACAO DE TERMINAIS AQUAVIARIOS DE PESCA; SERVICOS DE",
  },
  {
    cnae: "5231102",
    descricao: "CARGA E DESCARGA DE EMBARCACOES; SERVICOS DE",
  },
  { cnae: "5231102", descricao: "OPERADORES PORTUARIOS; SERVICOS DE" },
  { cnae: "5231102", descricao: "OPERACAO PORTUARIA; SERVICOS DE" },
  {
    cnae: "5231103",
    descricao:
      "GESTAO E OPERACAO DE TERMINAIS AQUAVIARIOS DE CARGA; SERVICOS DE",
  },
  {
    cnae: "5231103",
    descricao: "INSTALACOES PORTUARIAS PUBLICAS DE PEQUENO PORTE  IP4",
  },
  {
    cnae: "5231103",
    descricao: "ESTACAO DE TRANSBORDO DE CARGAS; SERVICOS DE",
  },
  { cnae: "52320", descricao: "ATIVIDADES DE AGENCIAMENTO MARITIMO" },
  { cnae: "5232000", descricao: "ABASTECIMENTO DE NAVIOS; SERVICOS DE" },
  { cnae: "5232000", descricao: "AGENCIA DE NAVEGACAO; SERVICOS DE" },
  { cnae: "5232000", descricao: "AGRUPACAO DE CARGAS; SERVICOS DE" },
  { cnae: "5232000", descricao: "AGENCIAMENTO MARITIMO; SERVICOS DE" },
  {
    cnae: "5232000",
    descricao: "ASSESSORIA E SUPORTE A ARMADORES; SERVICOS DE",
  },
  { cnae: "5232000", descricao: "AGENCIAMENTO DE REBOCADORES; SERVICOS DE" },
  { cnae: "5232000", descricao: "AGENCIAMENTO DE NAVIOS; SERVICOS DE" },
  {
    cnae: "52397",
    descricao:
      "ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIARIOS NAO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "5239701", descricao: "PRATICAGEM REALIZADA EM PORTOS; SERVICOS DE" },
  {
    cnae: "5239701",
    descricao: "PILOTAGEM E PRATICAGEM EM ESTUARIOS E PORTOS; SERVICOS DE",
  },
  {
    cnae: "5239799",
    descricao:
      "GUARDA MALAS NO TRANSPORTE HIDROVIARIO OU EM PORTOS; SERVICOS DE",
  },
  {
    cnae: "5239799",
    descricao: "ABASTECIMENTO DE AGUA PARA EMBARCACOES; SERVICOS DE",
  },
  {
    cnae: "5239799",
    descricao:
      "TRANSLADO DE PASSAGEIROS NO INTERIOR DAS INSTALACOES PORTUARIAS; SERVICOS DE",
  },
  {
    cnae: "5239799",
    descricao: "CLASSIFICACAO DE NAVIOS,EMBARCACOES; SERVICOS DE",
  },
  { cnae: "5239799", descricao: "VISTORIA DE CONTEINERES; SERVICOS DE" },
  {
    cnae: "5239799",
    descricao:
      "APOIO A NAVIOS NA AREA DO PORTO; SERVICOS DE PRATICAGEM; SERVICOS DE",
  },
  {
    cnae: "5239799",
    descricao: "GUARDA-VOLUMES, EM TERMINAIS AQUAVIARIOS; SERVICOS DE",
  },
  {
    cnae: "5239799",
    descricao: "OPERACAO DE RADIOFAROIS PARA NAVEGACAO MARITIMA; SERVICOS DE",
  },
  { cnae: "5239799", descricao: "CENTRO DE CONTROLE DE NAVEGACAO" },
  { cnae: "5239799", descricao: "VISTORIA DE EMBARCACOES; SERVICOS DE" },
  { cnae: "5239799", descricao: "ECLUSAS; SERVICOS DE" },
  { cnae: "52401", descricao: "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS" },
  { cnae: "5240101", descricao: "CENTRO DE CONTROLE DE VOO" },
  { cnae: "5240101", descricao: "OPERACAO DE CAMPOS DE ATERRISSAGEM" },
  {
    cnae: "5240101",
    descricao: "GESTAO DE AEROPORTOS E CAMPOS DE ATERRISSAGEM",
  },
  { cnae: "5240101", descricao: "OPERACAO DE CAMPO DE POUSO DE AERONAVES" },
  {
    cnae: "5240101",
    descricao: "ESTACOES DE RADAR PARA NAVEGACAO AEREA; SERVICOS DE",
  },
  {
    cnae: "5240101",
    descricao: "OPERACAO DE AEROPORTOS E CAMPOS DE ATERRISSAGEM",
  },
  {
    cnae: "5240101",
    descricao: "OPERACAO DE RADIOFAROIS PARA NAVEGACAO AEREA; SERVICOS DE",
  },
  {
    cnae: "5240101",
    descricao: "OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
  },
  { cnae: "5240199", descricao: "CENTROS DE CONTROLE DE VOO; OPERACAO DE" },
  {
    cnae: "5240199",
    descricao: "OPERACAO DE INSTALACOES PARA NAVEGACAO AEREA",
  },
  {
    cnae: "5240199",
    descricao: "GUARDA MALAS EM TERMINAIS AEREOS, AEROPORTOS; SERVICOS DE",
  },
  {
    cnae: "5240199",
    descricao: "GUARDA-VOLUMES, EM TERMINAIS AEREOS; SERVICOS DE",
  },
  { cnae: "5240199", descricao: "HANGARAGEM; SERVICOS DE" },
  {
    cnae: "5240199",
    descricao:
      "MOVIMENTACAO DE CARGAS E BAGAGENS EM TERMINAIS AEROPORTUARIOS (AEROPORTOS); SERVICOS DE",
  },
  {
    cnae: "5240199",
    descricao: "PILOTOS DE AVIOES, AERONAVES CONSTITUIDOS COMO EMPRESA",
  },
  { cnae: "5240199", descricao: "RADIOFAROIS; OPERACAO DE" },
  { cnae: "5240199", descricao: "ESTACIONAMENTO DE HELICOPTEROS; SERVICOS DE" },
  {
    cnae: "5240199",
    descricao: "LIMPEZA DE INTERIORES DE AERONAVES; SERVICOS DE",
  },
  { cnae: "5240199", descricao: "HANGARAGEM DE HELICOPTEROS; SERVICOS DE" },
  {
    cnae: "5240199",
    descricao: "INTERIOR DE AERONAVES (CARPETES E ESTOFADOS); MANUTENCAO DE",
  },
  {
    cnae: "5240199",
    descricao:
      "PILOTOS DE AVIOES, AERONAVES, INDEPENDENTES OU AUTONOMOS; SERVICOS DE",
  },
  { cnae: "5240199", descricao: "ESTACOES DE RADAR; OPERACAO DE" },
  {
    cnae: "5240199",
    descricao:
      "TRANSLADO (TRASLADO) DE PASSAGEIROS EM TERMINAIS AEROPORTUARIOS (AEROPORTOS); SERVICOS DE",
  },
  {
    cnae: "5240199",
    descricao: "PREVENCAO E EXTINCAO DE INCENDIO EM AEROPORTOS; SERVICOS DE",
  },
  { cnae: "5240199", descricao: "AVIOES, AERONAVES; LIMPEZA DE" },
  {
    cnae: "52508",
    descricao: "ATIVIDADES RELACIONADAS A ORGANIZACAO DO TRANSPORTE DE CARGA",
  },
  {
    cnae: "5250801",
    descricao: "REGULARIZACAO DE IMPOSTOS SOBRE MERCADORIAS; SERVICOS DE",
  },
  { cnae: "5250801", descricao: "ASSESSORIA ADUANEIRA; SERVICOS DE" },
  {
    cnae: "5250801",
    descricao:
      "TRAMITES PARA DESEMBARACO ALFANDEGARIO E ADUANEIRO; SERVICOS DE",
  },
  {
    cnae: "5250801",
    descricao:
      "TRAMITES DO COMERCIO EXTERIOR REALIZADO POR EMPRESAS DE COMISSARIA DE DESPACHO;SERVICOS DE",
  },
  { cnae: "5250801", descricao: "DESEMBARACO ADUANEIRO; SERVICOS DE" },
  {
    cnae: "5250801",
    descricao:
      "REGULARIZACAO DE DOCUMENTOS SOBRE ENTRADA E SAIDA DE MERCADORIAS; SERVICOS DE",
  },
  {
    cnae: "5250801",
    descricao: "ASSESSORIA EM IMPORTACAO E EXPORTACAO DE CARGAS; SERVICOS DE",
  },
  { cnae: "5250801", descricao: "COMISSARIA DE DESPACHOS; SERVICOS DE" },
  {
    cnae: "5250802",
    descricao: "TRAMITACAO DE FORMALIDADES ADUANEIRAS; SERVICOS DE",
  },
  { cnae: "5250802", descricao: "DESPACHOS ADUANEIROS; SERVICOS DE" },
  {
    cnae: "5250802",
    descricao:
      "SUBSCRICAO DE DOCUMENTOS QUE SIRVA DE BASE AO DESPACHO ADUANEIRO; SERVICOS DE",
  },
  { cnae: "5250802", descricao: "DESPACHANTE ADUANEIRO; SERVICOS DE" },
  { cnae: "5250802", descricao: "LEGALIZACOES ADUANEIRAS; SERVICOS DE" },
  { cnae: "5250802", descricao: "CONFERENCIA ADUANEIRA; SERVICOS DE" },
  { cnae: "5250802", descricao: "DESPACHOS MARITIMOS; SERVICOS DE" },
  {
    cnae: "5250803",
    descricao:
      "AGENCIAMENTO DE CARGAS PARA O TRANSPORTE FERROVIARIO; SERVICOS DE",
  },
  { cnae: "5250803", descricao: "CONTRATACAO DE FRETES; SERVICOS DE" },
  {
    cnae: "5250803",
    descricao:
      "CONTRATACAO DE ESPACO PARA EMBARQUE EM AERONAVES E NAVIOS; SERVICOS DE",
  },
  {
    cnae: "5250803",
    descricao: "ACONDICIONAMENTO DE CARGAS NO TRANSPORTE AEREO; SERVICOS DE",
  },
  {
    cnae: "5250803",
    descricao:
      "INTERMEDIACAO DE CARGAS, FRETES NO TRANSPORTE FERROVIARIO; SERVICOS DE",
  },
  {
    cnae: "5250803",
    descricao: "AGRUPAMENTO DE CARGAS NO TRANSPORTE RODOVIARIO; SERVICOS DE",
  },
  {
    cnae: "5250803",
    descricao:
      "ACONDICIONAMENTO DE CARGAS NO TRANSPORTE FERROVIARIO; SERVICOS DE",
  },
  {
    cnae: "5250803",
    descricao: "AGRUPAMENTO DE CARGAS NO TRANSPORTE AEREO; SERVICOS DE",
  },
  {
    cnae: "5250803",
    descricao: "CONTRATACAO DE ESPACOS EM AERONAVES; SERVICOS DE",
  },
  {
    cnae: "5250803",
    descricao:
      "AGENCIAMENTO DE CARGAS PARA O TRANSPORTE RODOVIARIO; SERVICOS DE",
  },
  {
    cnae: "5250803",
    descricao:
      "ACONDICIONAMENTO DE CARGAS NO TRANSPORTE RODOVIARIO; SERVICOS DE",
  },
  {
    cnae: "5250803",
    descricao: "AGENCIAMENTO DE CARGAS PARA O TRANSPORTE AEREO; SERVICOS DE",
  },
  {
    cnae: "5250803",
    descricao:
      "AGENCIAMENTO DE CARGAS, EXCETO PARA O TRANSPORTE MARITIMO; SERVICOS DE",
  },
  {
    cnae: "5250803",
    descricao: "CONTRATACAO DE ESPACOS EM NAVIOS, EMBARCACOES; SERVICOS DE",
  },
  {
    cnae: "5250803",
    descricao: "AGRUPAMENTO DE CARGAS NO TRANSPORTE FERROVIARIO; SERVICOS DE",
  },
  {
    cnae: "5250803",
    descricao:
      "INTERMEDIACAO DE CARGAS, FRETES NO TRANSPORTE RODOVIARIO; SERVICOS DE",
  },
  {
    cnae: "5250803",
    descricao:
      "INTERMEDIACAO DE CARGAS, FRETES NO TRANSPORTE AEREO; SERVICOS DE",
  },
  {
    cnae: "5250804",
    descricao:
      "COORDENACAO E DESENVOLVIMENTO DE PROJETOS LOGISTICOS PARA O TRANSPORTE DE CARGAS; SERVICOS DE",
  },
  {
    cnae: "5250805",
    descricao: "ATIVIDADES DO OPERADOR DE TRANSPORTE MULTIMODAL (OTM)",
  },
  {
    cnae: "5250805",
    descricao:
      "ORGANIZACAO LOGISTICA DO TRANSPORTE DE CARGA (OTM); SERVICOS DE",
  },
  { cnae: "53105", descricao: "ATIVIDADES DE CORREIO" },
  { cnae: "5310501", descricao: "CORREIO NACIONAL; AGENCIA" },
  { cnae: "5310501", descricao: "EMPRESA BRASILEIRA DE CORREIOS E TELEGRAFOS" },
  { cnae: "5310501", descricao: "CORREIO NACIONAL" },
  { cnae: "5310501", descricao: "ALUGUEL DE CAIXAS POSTAIS; SERVICOS DE" },
  { cnae: "5310501", descricao: "CORREIO; AGENCIA DA EBCT" },
  { cnae: "5310501", descricao: "EBCT; AGENCIA" },
  { cnae: "5310502", descricao: "CORREIO; AGENCIA FRANQUEADA" },
  { cnae: "5310502", descricao: "CORREIO NACIONAL; FRANCHISING" },
  { cnae: "5310502", descricao: "FRANQUIA POSTAL;SERVICOS DE" },
  { cnae: "5310502", descricao: "CORREIO; AGENCIAS PERMISSIONARIAS" },
  { cnae: "5310502", descricao: "CORREIO, AGENCIA; FRANQUIA DE" },
  { cnae: "53202", descricao: "ATIVIDADES DE MALOTE E DE ENTREGA" },
  {
    cnae: "5320201",
    descricao:
      "ENTREGA DE CARTAS, CONVITES E SIMILARES POR TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "5320201",
    descricao: "ENTREGA EXPRESSA DE DOCUMENTOS POR TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "5320201",
    descricao:
      "ENTREGA RAPIDA DE DOCUMENTOS E MALOTES, POR TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "5320201",
    descricao:
      "COLETA, TRANSPORTE E ENTREGA DE DOCUMENTOS POR TERCEIROS; SERVICOS DE",
  },
  { cnae: "5320201", descricao: "COURRIER; SERVICOS DE" },
  {
    cnae: "5320201",
    descricao:
      "COLETA, TRANSPORTE E ENTREGA DE VOLUMES POR TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "5320201",
    descricao:
      "MOTOBOY; SERVICOS DE COLETA, DISTRIBUICAO E ENTREGA DE CORRESPONDENCIA E VOLUMES",
  },
  {
    cnae: "5320202",
    descricao: "DISTRIBUICAO DE JORNAIS EM DOMICILIOS; SERVICOS DE",
  },
  { cnae: "5320202", descricao: "COLETA DE ENCOMENDAS; SERVICOS DE" },
  {
    cnae: "5320202",
    descricao:
      "JORNAIS, REVISTAS, CATALOGOS E OUTRAS PUBLICACOES, ENTREGA EM DOMICILIO DE",
  },
  {
    cnae: "5320202",
    descricao: "ENTREGA RAPIDA DE ENCOMENDAS POR MOTO BOY; SERVICOS DE",
  },
  {
    cnae: "5320202",
    descricao: "ENTREGA DE REFEICOES, ALIMENTOS PARA RESTAURANTES; SERVICOS DE",
  },
  {
    cnae: "5320202",
    descricao:
      "ENTREGA DE JORNAIS E REVISTAS EM DOMICILIO SOB CONTRATO; SERVICOS DE",
  },
  { cnae: "5320202", descricao: "ENTREGA DE MEDICAMENTOS; SERVICOS DE" },
  { cnae: "55108", descricao: "HOTEIS E SIMILARES" },
  { cnae: "5510801", descricao: "SPA COM SERVICO DE ALOJAMENTO; SERVICOS DE" },
  { cnae: "5510801", descricao: "HOTEL FAZENDA" },
  { cnae: "5510801", descricao: "POUSADA" },
  { cnae: "5510801", descricao: "HOTEL COM OU SEM SERVICO DE RESTAURANTE" },
  { cnae: "5510801", descricao: "POUSADAS COM OU SEM SERVICO DE ALIMENTACAO" },
  { cnae: "5510801", descricao: "ADMINISTRACAO DE HOTEIS" },
  { cnae: "5510801", descricao: "HOTEL" },
  { cnae: "5510802", descricao: "APART-HOTEL (USADO COMO HOTEL)" },
  { cnae: "5510803", descricao: "MOTEL" },
  {
    cnae: "55906",
    descricao: "OUTROS TIPOS DE ALOJAMENTO NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "5590601",
    descricao: "ALOJAMENTO EM ALBERGUES NAO ASSISTENCIAIS; SERVICO DE",
  },
  { cnae: "5590601", descricao: "ALOJAMENTO EM HOSTEL; SERVICOS DE" },
  {
    cnae: "5590601",
    descricao: "ALBERGUE (EXCETO ASSISTENCIAL); SERVICO DE ALOJAMENTO",
  },
  { cnae: "5590602", descricao: "CAMPING; SERVICO DE ALOJAMENTO" },
  { cnae: "5590602", descricao: "ACAMPAMENTO; SERVICO DE ALOJAMENTO" },
  {
    cnae: "5590603",
    descricao: "PENSAO COM SERVICO DE ALIMENTACAO; SERVICO DE ALOJAMENTO",
  },
  { cnae: "5590603", descricao: "PENSAO; SERVICO DE ALOJAMENTO" },
  { cnae: "5590603", descricao: "PENSAO; HOSPEDAGEM" },
  {
    cnae: "5590603",
    descricao: "PENSAO SEM SERVICO DE ALIMENTACAO; SERVICO DE ALOJAMENTO",
  },
  {
    cnae: "5590699",
    descricao: "REPUBLICA DE ESTUDANTES; SERVICO DE ALOJAMENTO",
  },
  { cnae: "5590699", descricao: "DORMITORIO; SERVICO DE ALOJAMENTO" },
  { cnae: "5590699", descricao: "ALOJAMENTO PARA ESTUDANTES" },
  {
    cnae: "5590699",
    descricao: "EXPLORACAO DE VAGOES-LEITO, POR TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "5590699",
    descricao: "CABINE DE DORMIR (POD HOTEL); SERVICOS DE ALOJAMENTO",
  },
  { cnae: "5590699", descricao: "CASA DE ESTUDANTE" },
  { cnae: "5590699", descricao: "ESTALAGEM; SERVICO DE ALOJAMENTO" },
  {
    cnae: "5590699",
    descricao: "IMOVEIS PROPRIOS PARA CURTA TEMPORADA; ALUGUEL DE",
  },
  { cnae: "5590699", descricao: "PENSIONATO" },
  { cnae: "5590699", descricao: "HOSPEDARIA" },
  {
    cnae: "56112",
    descricao:
      "RESTAURANTES E OUTROS ESTABELECIMENTOS DE SERVICOS DE ALIMENTACAO E BEBIDAS",
  },
  { cnae: "5611201", descricao: "RESTAURANTE COM SERVICO COMPLETO" },
  {
    cnae: "5611201",
    descricao:
      "RESTAURANTES E BARES EM EMBARCACOES EXPLORADOS POR TERCEIROS; SERVICO DE",
  },
  { cnae: "5611201", descricao: "RESTAURANTES E SIMILARES" },
  { cnae: "5611201", descricao: "A PESO; ALIMENTACAO, COMIDA, REFEICAO" },
  { cnae: "5611201", descricao: "ROTISSERIA; SERVICO DE ALIMENTACAO" },
  { cnae: "5611201", descricao: "CHURRASCARIA; SERVICO DE ALIMENTACAO" },
  { cnae: "5611201", descricao: "TRATTORIA; SERVICO DE ALIMENTACAO" },
  { cnae: "5611201", descricao: "SELF-SERVICE; SERVICO DE ALIMENTACAO" },
  {
    cnae: "5611201",
    descricao:
      "EXPLORACAO DE VAGOES-RESTAURANTES, POR TERCEIROS; SERVICO DE ALIMENTACAO",
  },
  {
    cnae: "5611201",
    descricao: "ALIMENTACAO COM SERVICO COMPLETO; SERVICOS DE",
  },
  { cnae: "5611201", descricao: "A KILO; ALIMENTACAO, COMIDA, REFEICAO" },
  { cnae: "5611201", descricao: "RESTAURANTE POR COMIDA A QUILO; SERVICO DE" },
  { cnae: "5611201", descricao: "A QUILO; SERVICO DE ALIMENTACAO" },
  { cnae: "5611201", descricao: "PENSAO; SERVICO DE ALIMENTACAO" },
  {
    cnae: "5611201",
    descricao: "PIZZARIA COM SERVICO COMPLETO; SERVICO DE ALIMENTACAO",
  },
  { cnae: "5611203", descricao: "BAR SEM SERVICO COMPLETO" },
  {
    cnae: "5611203",
    descricao: "ALIMENTACAO SEM SERVICO COMPLETO; SERVICO DE",
  },
  {
    cnae: "5611203",
    descricao: "PIZZARIA (TIPO FAST- FOOD); SERVICO DE ALIMENTACAO",
  },
  { cnae: "5611203", descricao: "GELATERIA; SERVICO DE ALIMENTACAO" },
  { cnae: "5611203", descricao: "SORVETERIA; SERVICO DE ALIMENTACAO" },
  { cnae: "5611203", descricao: "LANCHERIA; SERVICO DE ALIMENTACAO" },
  { cnae: "5611203", descricao: "ACAITERIA; SERVICO DE ALIMENTACAO" },
  { cnae: "5611203", descricao: "BIROSCA; SERVICO DE ALIMENTACAO" },
  { cnae: "5611203", descricao: "PASTELARIA; SERVICO DE ALIMENTACAO" },
  { cnae: "5611203", descricao: "BROWNERIA; SERVICO DE ALIMENTACAO" },
  {
    cnae: "5611203",
    descricao: "CASA DE DOCES E SALGADOS; SERVICO DE ALIMENTACAO",
  },
  { cnae: "5611203", descricao: "CASA DE SUCO; SERVICO DE ALIMENTACAO" },
  { cnae: "5611203", descricao: "LANCHONETE; SERVICO DE ALIMENTACAO" },
  { cnae: "5611203", descricao: "FAST-FOOD; SERVICO DE ALIMENTACAO" },
  { cnae: "5611203", descricao: "CAFETERIA; SERVICOS DE" },
  { cnae: "5611203", descricao: "CASAS DE CHA" },
  { cnae: "5611203", descricao: "CALDO DE CANA; SERVICO DE ALIMENTACAO" },
  {
    cnae: "5611204",
    descricao: "SERVICO AMBULANTE DE BEBIDAS, SEM ENTRETENIMENTO",
  },
  {
    cnae: "5611204",
    descricao: "CHOPARIA, CHOPERIA COM SERVICO COMPLETO, SEM ENTRETENIMENTO",
  },
  {
    cnae: "5611204",
    descricao: "WHISKERIA, WHISKARIA COM SERVICO COMPLETO, SEM ENTRETENIMENTO",
  },
  { cnae: "5611204", descricao: "TRUCK BEER, SEM ENTRETENIMENTO" },
  { cnae: "5611204", descricao: "SNACK-BAR, SEM ENTRETENIMENTO" },
  {
    cnae: "5611204",
    descricao: "ADEGA COM SERVICO COMPLETO, SEM ENTRETENIMENTO",
  },
  {
    cnae: "5611204",
    descricao:
      "BARES E OUTROS ESTABELECIMENTOS ESPECIALIZADOS EM SERVIR BEBIDAS, SEM ENTRETENIMENTO",
  },
  {
    cnae: "5611204",
    descricao: "BAR COM SERVICO COMPLETO, SEM ENTRETENIMENTO",
  },
  {
    cnae: "5611205",
    descricao:
      "ADEGA COM SERVICO COMPLETO, COM ENTRETENIMENTO (MUSICA, APRESENTACOES DE SHOWS, ENTRE OUTROS)",
  },
  {
    cnae: "5611205",
    descricao:
      "SNACK-BAR, COM ENTRETENIMENTO (MUSICA, APRESENTACOES DE SHOWS, ENTRE OUTROS)",
  },
  {
    cnae: "5611205",
    descricao:
      "BARES E OUTROS ESTABELECIMENTOS ESPECIALIZADOS EM SERVIR BEBIDAS, COM ENTRETENIMENTO  (MUSICA, APRESENTACOES DE SHOWS, ENTRE OUTROS)",
  },
  {
    cnae: "5611205",
    descricao:
      "BAR COM SERVICO COMPLETO, COM ENTRETENIMENTO (MUSICA, APRESENTACOES DE SHOWS, ENTRE OUTROS)",
  },
  {
    cnae: "5611205",
    descricao:
      "WHISKERIA, WHISKARIA COM SERVICO COMPLETO, COM ENTRETENIMENTO (MUSICA, APRESENTACOES DE SHOWS, ENTRE OUTROS)",
  },
  {
    cnae: "5611205",
    descricao:
      "SERVICO AMBULANTE DE BEBIDAS, COM ENTRETENIMENTO (MUSICA, APRESENTACOES DE SHOWS, ENTRE OUTROS)",
  },
  {
    cnae: "5611205",
    descricao:
      "TRUCK BEER, COM ENTRETENIMENTO (MUSICA, APRESENTACOES DE SHOWS, ENTRE OUTROS)",
  },
  {
    cnae: "5611205",
    descricao:
      "CHOPERIA, CHOPARIA COM SERVICO COMPLETO, COM ENTRETENIMENTO (MUSICA, APRESENTACOES DE SHOWS, ENTRE OUTROS)",
  },
  { cnae: "56121", descricao: "SERVICOS AMBULANTES DE ALIMENTACAO" },
  { cnae: "5612100", descricao: "PIPOQUEIRO; SERVICO DE ALIMENTACAO" },
  { cnae: "5612100", descricao: "AMBULANTE; SERVICO DE ALIMENTACAO" },
  { cnae: "5612100", descricao: "TRAILLER; SERVICO DE ALIMENTACAO" },
  { cnae: "5612100", descricao: "FOOD TRUCKS; SERVICO DE ALIMENTACAO" },
  { cnae: "5612100", descricao: "BARRAQUEIRO; SERVICO DE ALIMENTACAO" },
  { cnae: "5612100", descricao: "ALIMENTACAO EM BARRACAS; SERVICOS DE" },
  { cnae: "5612100", descricao: "CARROCINHA; SERVICO DE ALIMENTACAO" },
  { cnae: "5612100", descricao: "ALIMENTACAO EM LOCAL ABERTO; SERVICOS DE" },
  { cnae: "5612100", descricao: "EM VEICULOS; SERVICO DE ALIMENTACAO" },
  { cnae: "5612100", descricao: "TRAILER; SERVICO DE ALIMENTACAO" },
  { cnae: "5612100", descricao: "QUIOSQUE; SERVICO DE ALIMENTACAO" },
  {
    cnae: "5612100",
    descricao: "VENDA DE ALIMENTOS EM MAQUINAS DE SERVICOS AUTOMATICAS",
  },
  {
    cnae: "56201",
    descricao:
      "SERVICOS DE CATERING, BUFE E OUTROS SERVICOS DE COMIDA PREPARADA",
  },
  { cnae: "5620101", descricao: "COZINHA INDUSTRIAL; SERVICOS DE" },
  {
    cnae: "5620101",
    descricao: "REFEICOES PARA FORNECIMENTO A CANTINAS; PREPARACAO DE",
  },
  {
    cnae: "5620101",
    descricao:
      "FORNECIMENTO DE MARMITAS PREPONDERANTEMENTE PARA EMPRESAS; SERVICO DE ALIMENTACAO",
  },
  {
    cnae: "5620101",
    descricao:
      "FORNECIMENTO DE COMIDA PREPARADA DE PRODUCAO PROPRIA PREPONDERANTEMENTE PARA EMPRESAS; SERVICO DE ALIMENTACAO",
  },
  {
    cnae: "5620101",
    descricao: "EMPRESA AEREA, AVIOES; FORNECIMENTO DE REFEICOES PARA",
  },
  {
    cnae: "5620101",
    descricao:
      "FORNECIMENTO DE ALIMENTOS PREPARADOS PREPONDERANTEMENTE PARA EMPRESAS; SERVICO DE ALIMENTACAO",
  },
  { cnae: "5620101", descricao: "VENDA SOB CONTRATO DE REFEICOES PREPARADAS" },
  {
    cnae: "5620101",
    descricao: "FORNECIMENTO DE REFEICOES INDUSTRIAIS; SERVICO DE ALIMENTACAO",
  },
  { cnae: "5620101", descricao: "CATERING; SERVICO DE ALIMENTACAO" },
  {
    cnae: "5620101",
    descricao:
      "REFEICOES PARA FORNECIMENTO A RESTAURANTES DE EMPRESA; PREPARACAO DE",
  },
  {
    cnae: "5620101",
    descricao:
      "FORNECIMENTO DE REFEICOES PREPARADAS E EMBALADAS, PREPONDERANTEMENTE PARA EMPRESAS; SERVICO DE ALIMENTACAO",
  },
  { cnae: "5620102", descricao: "BUFE; SERVICOS DE ALIMENTACAO" },
  { cnae: "5620102", descricao: "BUFFET; SERVICO DE ALIMENTACAO" },
  {
    cnae: "5620102",
    descricao:
      "ALIMENTACAO FORNECIDOS POR BUFE (BUFFET) PARA BANQUETES, COQUETEIS E RECEPCOES; SERVICOS DE",
  },
  {
    cnae: "5620103",
    descricao:
      "CANTINA (SERVICO DE ALIMENTACAO PRIVATIVO) - EXPLORACAO POR TERCEIROS",
  },
  { cnae: "7119701", descricao: "HIDROMETRIA; SERVICOS DE" },
  {
    cnae: "5620103",
    descricao:
      "CANTINA (SERVICO DE ALIMENTACAO PRIVATIVO) - EXPLORACAO PROPRIA",
  },
  {
    cnae: "5620104",
    descricao:
      "FORNECIMENTO DE ALIMENTOS PREPARADOS PARA CONSUMO DOMICILIAR; SERVICO DE ALIMENTACAO",
  },
  {
    cnae: "5620104",
    descricao:
      "FORNECIMENTO DE MARMITAS PARA CONSUMO DOMICILIAR; SERVICO DE ALIMENTACAO",
  },
  { cnae: "5620104", descricao: "FORNECIMENTO DE MARMITEX; SERVICOS DE" },
  {
    cnae: "5620104",
    descricao:
      "RESTAURANTE DELIVERY (EXCLUSIVAMENTE PARA ENTREGA EM DOMICILIO, SEM CONSUMO NO LOCAL); SERVICO DE ALIMENTACAO",
  },
  {
    cnae: "5620104",
    descricao:
      "FORNECIMENTO DE REFEICOES PREPARADAS E EMBALADAS, PARA CONSUMO DOMICILIAR; SERVICO DE ALIMENTACAO",
  },
  {
    cnae: "5620104",
    descricao:
      "FORNECIMENTO DE COMIDA PREPARADA DE PRODUCAO PROPRIA, PARA CONSUMO DOMICILIAR; SERVICO DE ALIMENTACAO",
  },
  {
    cnae: "5620104",
    descricao:
      "PIZZARIA (EXCLUSIVAMENTE PARA ENTREGA EM DOMICILIO, SEM CONSUMO NO LOCAL); SERVICO DE ALIMENTACAO",
  },
  { cnae: "58115", descricao: "EDICAO DE LIVROS" },
  { cnae: "5811500", descricao: "LIVROS INFANTIS; EDICAO DE" },
  { cnae: "5811500", descricao: "DICIONARIOS; EDICAO DE" },
  { cnae: "5811500", descricao: "LIVROS ELETRONICOS; EDICAO DE" },
  {
    cnae: "5811500",
    descricao: "DIREITOS AUTORAIS DE OBRAS LITERARIAS; GESTAO DE",
  },
  { cnae: "5811500", descricao: "OBRAS LITERARIAS; EDICAO DE" },
  { cnae: "5811500", descricao: "ATLAS; EDICAO DE" },
  { cnae: "5811500", descricao: "APOSTILAS; EDICAO DE" },
  {
    cnae: "5811500",
    descricao: "OBRAS LITERARIAS; AQUISICAO DE DIREITOS AUTORAIS",
  },
  { cnae: "5811500", descricao: "ENCICLOPEDIAS; EDICAO DE" },
  { cnae: "5811500", descricao: "LIVROS TECNICOS; EDICAO DE" },
  { cnae: "5811500", descricao: "LIVROS NA INTERNET; EDICAO DE" },
  { cnae: "5811500", descricao: "LIVROS EM GERAL; EDICAO DE" },
  { cnae: "5811500", descricao: "MAPAS; EDICAO DE" },
  { cnae: "58123", descricao: "EDICAO DE JORNAIS" },
  { cnae: "5812301", descricao: "JORNAIS PUBLICITARIOS DIARIOS; EDICAO DE" },
  { cnae: "5812301", descricao: "JORNAIS NA INTERNET DIARIOS; EDICAO DE" },
  { cnae: "5812301", descricao: "JORNAIS IMPRESSOS DIARIOS; EDICAO DE" },
  { cnae: "5812301", descricao: "JORNAIS ELETRONICOS DIARIOS; EDICAO DE" },
  { cnae: "5812302", descricao: "JORNAIS ELETRONICOS NAO DIARIOS; EDICAO DE" },
  {
    cnae: "5812302",
    descricao: "JORNAIS PUBLICITARIOS NAO DIARIOS; EDICAO DE",
  },
  { cnae: "5812302", descricao: "JORNAIS NA INTERNET NAO DIARIOS; EDICAO DE" },
  { cnae: "5812302", descricao: "JORNAIS IMPRESSOS NAO DIARIOS; EDICAO DE" },
  { cnae: "58131", descricao: "EDICAO DE REVISTAS" },
  {
    cnae: "5813100",
    descricao: "REVISTAS DE PROGRAMACAO DE TELEVISAO; EDICAO DE",
  },
  { cnae: "5813100", descricao: "PUBLICACOES PERIODICAS; EDICAO DE" },
  {
    cnae: "5813100",
    descricao: "VENDA DE ESPACOS PARA PUBLICIDADE EM REVISTAS",
  },
  {
    cnae: "5813100",
    descricao: "REVISTAS DE CONTEUDO TECNICO OU GERAL; EDICAO DE",
  },
  { cnae: "5813100", descricao: "REVISTAS NA INTERNET; EDICAO DE" },
  { cnae: "5813100", descricao: "REVISTAS ELETRONICAS; EDICAO DE" },
  {
    cnae: "58191",
    descricao: "EDICAO DE CADASTROS, LISTAS E OUTROS PRODUTOS GRAFICOS",
  },
  { cnae: "5819100", descricao: "REPRODUCAO DE TRABALHOS DE ARTE; EDICAO DE" },
  { cnae: "5819100", descricao: "CALENDARIOS; EDICAO DE" },
  { cnae: "5819100", descricao: "CADASTROS PARA MALA DIRETA; EDICAO DE" },
  { cnae: "5819100", descricao: "LISTAS PARA MALAS DIRETAS; EDICAO DE" },
  { cnae: "5819100", descricao: "CARTOES POSTAIS; EDICAO DE" },
  { cnae: "5819100", descricao: "MATERIAL PUBLICITARIO; EDICAO DE" },
  { cnae: "5819100", descricao: "CARTOES DE FELICITACOES; EDICAO DE" },
  { cnae: "5819100", descricao: "PANFLETOS E FOLHETOS; EDICAO DE" },
  { cnae: "5819100", descricao: "GRAVURAS; EDICAO DE" },
  {
    cnae: "5819100",
    descricao:
      "ESTATISTICAS E OUTRAS INFORMACOES PARA DIVULGACAO NA INTERNET; EDICAO DE",
  },
  { cnae: "5819100", descricao: "LISTAS DE PRODUTOS FARMACEUTICOS; EDICAO DE" },
  { cnae: "5819100", descricao: "LISTAS TELEFONICAS; EDICAO DE" },
  { cnae: "5819100", descricao: "CADASTROS ON-LINE; EDICAO DE" },
  { cnae: "58212", descricao: "EDICAO INTEGRADA A IMPRESSAO DE LIVROS" },
  {
    cnae: "5821200",
    descricao: "LIVROS DE ARTE; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  { cnae: "5821200", descricao: "ATLAS; EDICAO INTEGRADA A IMPRESSAO DE" },
  {
    cnae: "5821200",
    descricao: "DICIONARIOS; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  { cnae: "5821200", descricao: "MAPAS; EDICAO INTEGRADA A IMPRESSAO DE" },
  {
    cnae: "5821200",
    descricao: "ENCICLOPEDIAS; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  {
    cnae: "5821200",
    descricao: "LIVROS TECNICOS; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  {
    cnae: "5821200",
    descricao: "LIVROS EM GERAL; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  {
    cnae: "5821200",
    descricao: "LIVROS INFANTIS; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  { cnae: "5821200", descricao: "APOSTILAS; EDICAO INTEGRADA A IMPRESSAO DE" },
  { cnae: "58221", descricao: "EDICAO INTEGRADA A IMPRESSAO DE JORNAIS" },
  {
    cnae: "5822101",
    descricao: "JORNAIS DIARIOS; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  {
    cnae: "5822101",
    descricao: "JORNAIS PUBLICITARIOS DIARIOS; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  {
    cnae: "5822102",
    descricao:
      "JORNAIS PUBLICITARIOS NAO DIARIOS; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  {
    cnae: "5822102",
    descricao: "JORNAIS NAO DIARIOS; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  { cnae: "58239", descricao: "EDICAO INTEGRADA A IMPRESSAO DE REVISTAS" },
  {
    cnae: "5823900",
    descricao: "REVISTAS DE CONTEUDO TECNICO; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  {
    cnae: "5823900",
    descricao: "PUBLICACOES PERIODICAS; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  {
    cnae: "5823900",
    descricao:
      "REVISTAS DE PROGRAMACAO DE TELEVISAO; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  { cnae: "5823900", descricao: "REVISTAS; EDICAO INTEGRADA A IMPRESSAO DE" },
  {
    cnae: "58298",
    descricao:
      "EDICAO INTEGRADA A IMPRESSAO DE CADASTROS, LISTAS E OUTROS PRODUTOS GRAFICOS",
  },
  {
    cnae: "5829800",
    descricao:
      "LISTAS DE PRODUTOS FARMACEUTICOS; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  {
    cnae: "5829800",
    descricao:
      "REPRODUCAO DE TRABALHOS DE ARTE; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  {
    cnae: "5829800",
    descricao: "LISTAS TELEFONICAS; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  {
    cnae: "5829800",
    descricao: "MATERIAL PUBLICITARIO; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  {
    cnae: "5829800",
    descricao: "LISTAS PARA MALAS DIRETAS; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  {
    cnae: "5829800",
    descricao: "PANFLETOS E FOLHETOS; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  {
    cnae: "5829800",
    descricao: "CARTOES POSTAIS; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  {
    cnae: "5829800",
    descricao: "CALENDARIOS; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  { cnae: "5829800", descricao: "CATALOGOS; EDICAO INTEGRADA A IMPRESSAO DE" },
  { cnae: "5829800", descricao: "GRAVURAS; EDICAO INTEGRADA A IMPRESSAO DE" },
  {
    cnae: "5829800",
    descricao: "CARTOES DE FELICITACOES; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  {
    cnae: "59111",
    descricao:
      "ATIVIDADES DE PRODUCAO CINEMATOGRAFICA, DE VIDEOS E DE PROGRAMAS DE TELEVISAO",
  },
  { cnae: "5911101", descricao: "PRODUTORA DE FILMES CINEMATOGRAFICOS" },
  {
    cnae: "5911101",
    descricao: "PRODUCAO DE FILMES CINEMATOGRAFICOS; ATIVIDADE DE",
  },
  { cnae: "5911101", descricao: "PRODUCAO CINEMATOGRAFICA; SERVICOS DE" },
  { cnae: "5911101", descricao: "ESTUDIO CINEMATOGRAFICO; ATIVIDADES DE" },
  {
    cnae: "5911101",
    descricao: "ARQUIVOS DE FILMES CINEMATOGRAFICOS; ATIVIDADES DE",
  },
  { cnae: "5911102", descricao: "FILMES DE PUBLICIDADE; ARQUIVOS DE" },
  {
    cnae: "5911102",
    descricao: "ARQUIVOS DE FILMES DE PUBLICIDADE; ATIVIDADES DE",
  },
  {
    cnae: "5911102",
    descricao: "FILMES PARA CAMPANHAS DE RESPONSABILIDADE SOCIAL; PRODUCAO DE",
  },
  { cnae: "5911102", descricao: "COMERCIAIS DE TELEVISAO; PRODUCAO DE" },
  { cnae: "5911102", descricao: "FILMES INSTITUCIONAIS; PRODUCAO DE" },
  { cnae: "5911102", descricao: "FILMES PARA PUBLICIDADE; PRODUCAO DE" },
  { cnae: "5911102", descricao: "PRODUTORA DE FILMES DE PUBLICIDADE" },
  {
    cnae: "5911102",
    descricao: "FILMES PARA CAMPANHAS POLITICAS; PRODUCAO DE",
  },
  {
    cnae: "5911199",
    descricao:
      "PRODUTORA DE FILMES E DE PROGRAMAS DE TELEVISAO FORA DOS ESTUDIOS DE TELEVISAO",
  },
  {
    cnae: "5911199",
    descricao:
      "PRODUCAO DE PROGRAMAS DE TELEVISAO FORA DOS ESTUDIOS DE TELEVISAO; ATIVIDADES DE",
  },
  { cnae: "5911199", descricao: "COMPUTACAO GRAFICA NA PRODUCAO DE FILMES" },
  {
    cnae: "5911199",
    descricao:
      "PROGRAMAS DE TELEVISAO AO VIVO EM ESTUDIOS DE TV POR PRODUTORES INDEPENDENTES; PRODUCAO DE",
  },
  {
    cnae: "5911199",
    descricao:
      "GRAVACAO DE PROGRAMAS DE TV FORA DOS ESTUDIOS DE TELEVISAO; SERVICOS DE",
  },
  {
    cnae: "5911199",
    descricao: "FILMES PARA TELEVISAO E INTERNET; PRODUCAO DE",
  },
  {
    cnae: "59120",
    descricao:
      "ATIVIDADES DE POSPRODUCAO CINEMATOGRAFICA, DE VIDEOS E DE PROGRAMAS DE TELEVISAO",
  },
  { cnae: "5912001", descricao: "DUBLAGEM DE FILMES CINEMATOGRAFICOS" },
  { cnae: "5912001", descricao: "DUBLAGEM DE VIDEOS" },
  { cnae: "5912001", descricao: "DUBLAGEM DE PROGRAMAS DE TELEVISAO" },
  { cnae: "5912001", descricao: "DUBLAGEM DE FILMES; SERVICOS DE" },
  { cnae: "5912002", descricao: "MIXAGEM SONORA EM PROGRAMAS DE TELEVISAO" },
  { cnae: "5912002", descricao: "MIXAGEM SONORA EM FILMES" },
  { cnae: "5912002", descricao: "MIXAGEM SONORA EM PRODUCAO AUDIOVISUAL" },
  { cnae: "5912002", descricao: "MIXAGEM SONORA EM VIDEOS" },
  { cnae: "5912099", descricao: "MONTAGEM DE FILMES; SERVICOS DE" },
  { cnae: "5912099", descricao: "LEGENDAS, TITULOS; COLOCACAO EM FILMES" },
  { cnae: "5912099", descricao: "EDICAO DE CREDITOS EM FILMES" },
  { cnae: "5912099", descricao: "FILMES CINEMATOGRAFICOS; DUPLICACAO DE" },
  {
    cnae: "5912099",
    descricao: "RECUPERACAO DE FILMES CINEMATOGRAFICOS; SERVICOS DE",
  },
  {
    cnae: "5912099",
    descricao: "REPRODUCAO DE COPIAS DE FILMES CINEMATOGRAFICOS EM PELICULA",
  },
  { cnae: "5912099", descricao: "LABORATORIOS CINEMATOGRAFICOS" },
  { cnae: "5912099", descricao: "ANIMACAO EM FILMES" },
  { cnae: "5912099", descricao: "LABORATORIO DE ANIMACAO DE FILMES" },
  {
    cnae: "5912099",
    descricao: "FILMES CINEMATOGRAFICOS EM PELICULA; REPRODUCAO DE COPIAS DE",
  },
  { cnae: "5912099", descricao: "FILMES EM PELICULA; EDICAO DE" },
  {
    cnae: "5912099",
    descricao: "RESTAURACAO, MANUTENCAO DE FILMES CINEMATOGRAFICOS",
  },
  {
    cnae: "5912099",
    descricao: "EFEITOS ESPECIAIS PARA FILMES CINEMATOGRAFICOS; SERVICOS DE",
  },
  { cnae: "5912099", descricao: "TELECINAGEM" },
  {
    cnae: "59138",
    descricao:
      "DISTRIBUICAO CINEMATOGRAFICA, DE VIDEO E DE PROGRAMAS DE TELEVISAO",
  },
  {
    cnae: "5913800",
    descricao: "FILMES E PROGRAMAS DE TELEVISAO; DIREITOS DE EXIBICAO DE",
  },
  {
    cnae: "5913800",
    descricao: "DIREITOS AUTORAIS DE OBRAS AUDIOVISUAIS; GESTAO DE",
  },
  {
    cnae: "5913800",
    descricao: "DISTRIBUICAO DE FILMES A REDES E CANAIS DE TELEVISAO",
  },
  {
    cnae: "5913800",
    descricao: "FILMES E PROGRAMAS DE TELEVISAO; DISTRIBUICAO DE",
  },
  {
    cnae: "5913800",
    descricao:
      "DIREITOS DE EXIBICAO DE PROGRAMAS DE TELEVISAO; LICENCIAMENTO, CESSAO DE",
  },
  {
    cnae: "5913800",
    descricao: "DIREITOS AUTORAIS DE OBRAS CINEMATOGRAFICAS; GESTAO DE",
  },
  {
    cnae: "5913800",
    descricao: "DISTRIBUICAO DE FILMES CINEMATOGRAFICOS EM PELICULAS",
  },
  {
    cnae: "5913800",
    descricao: "DIREITOS DE EXIBICAO DE VIDEOS; LICENCIAMENTO, CESSAO DE",
  },
  {
    cnae: "5913800",
    descricao: "DISTRIBUICAO DE FILMES A CINEMAS E CINECLUBES",
  },
  {
    cnae: "5913800",
    descricao: "DISTRIBUICAO DE FILMES EM FITAS DE VIDEO E DVD'S A EXIBIDORES",
  },
  {
    cnae: "5913800",
    descricao:
      "DIREITOS DE EXIBICAO DE FILMES CINEMATOGRAFICOS; LICENCIAMENTO, CESSAO DE",
  },
  { cnae: "59146", descricao: "ATIVIDADES DE EXIBICAO CINEMATOGRAFICA" },
  { cnae: "5914600", descricao: "CINECLUBES" },
  { cnae: "5914600", descricao: "PROJECAO DE FILMES E DE VIDEOS" },
  {
    cnae: "5914600",
    descricao: "EXIBICAO DE FILMES CINEMATOGRAFICOS; ATIVIDADE DE",
  },
  { cnae: "5914600", descricao: "PROJECAO DE VIDEOS; SALAS DE" },
  { cnae: "5914600", descricao: "DRIVE-IN" },
  { cnae: "5914600", descricao: "PROJECAO DE FILMES; SALAS DE" },
  { cnae: "5914600", descricao: "FILMES CINEMATOGRAFICOS; EXIBICAO DE" },
  {
    cnae: "5914600",
    descricao: "ESPACO CULTURAL DE PROJECAO DE FILMES E VIDEOS",
  },
  { cnae: "5914600", descricao: "EXIBICAO CINEMATOGRAFICA; ATIVIDADE DE" },
  { cnae: "5914600", descricao: "EXIBICAO DE PELICULAS; SALAS DE" },
  { cnae: "5914600", descricao: "EXIBICAO DE VIDEOS; SALAS DE" },
  { cnae: "5914600", descricao: "SALAS DE CINEMA" },
  { cnae: "5914600", descricao: "CINEMA" },
  { cnae: "5914600", descricao: "AUTO-CINE" },
  {
    cnae: "59201",
    descricao: "ATIVIDADES DE GRAVACAO DE SOM E DE EDICAO DE MUSICA",
  },
  { cnae: "5920100", descricao: "SONS EM MEIOS MAGNETICOS; TRANSCRICAO DE" },
  { cnae: "5920100", descricao: "MASTERIZACAO DE GRAVACAO DE SOM" },
  {
    cnae: "5920100",
    descricao: "GRAVACAO DE SOM EM ESTUDIO MUSICAL; SERVICOS DE",
  },
  { cnae: "5920100", descricao: "GRAVACAO DE SOM; PROMOCAO DE" },
  { cnae: "5920100", descricao: "ESTUDIO MUSICAL; PRESTACAO DE SERVICOS" },
  { cnae: "5920100", descricao: "SONS EM MEIOS MAGNETICOS; REEDICAO DE" },
  { cnae: "5920100", descricao: "ESTUDIO DE GRAVACAO DE SOM" },
  { cnae: "5920100", descricao: "EDICAO DE MATERIAL SONORO" },
  { cnae: "5920100", descricao: "MATRIZES ORIGINAIS DE SOM; PRODUCAO DE" },
  {
    cnae: "5920100",
    descricao: "PARTITURAS MUSICAIS; EDICAO INTEGRADA A IMPRESSAO DE",
  },
  { cnae: "5920100", descricao: "GRAVACAO DE PROGRAMAS DE RADIO" },
  { cnae: "5920100", descricao: "MIXAGEM SONORA EM MATERIAL GRAVADO" },
  { cnae: "5920100", descricao: "ESTUDIO DE GRAVACAO SONORA" },
  { cnae: "5920100", descricao: "MATERIAL SONORO GRAVADO; DISTRIBUICAO DE" },
  { cnae: "5920100", descricao: "EQUALIZACAO SONORA; ATIVIDADE DE" },
  {
    cnae: "5920100",
    descricao:
      "DIREITOS AUTORAIS MUSICAIS; CESSAO DE, ADMINISTRACAO DE, REGISTRO DE",
  },
  { cnae: "5920100", descricao: "PARTITURAS MUSICAIS; EDICAO DE" },
  { cnae: "5920100", descricao: "SOM PARA PUBLICIDADE; PRODUCAO DE" },
  { cnae: "5920100", descricao: "GRAVACAO DE SOM; MASTERIZACAO DE" },
  {
    cnae: "5920100",
    descricao: "FITAS E CDS; PRODUCAO DE MATRIZES ORIGINAIS DE",
  },
  { cnae: "5920100", descricao: "FITAS E CDS; MASTERIZACAO DE" },
  { cnae: "5920100", descricao: "MIXAGEM SONORA EM FITAS E CDS" },
  {
    cnae: "5920100",
    descricao: "MASTERIZACAO E REMASTERIZACAO DE SONS EM MEIOS MAGNETICOS;",
  },
  {
    cnae: "5920100",
    descricao: "DIREITOS AUTORAIS DE OBRAS MUSICAIS; GESTAO DE",
  },
  { cnae: "5920100", descricao: "COMPOSICOES MUSICAIS; AUTORIZACAO DE USO DE" },
  { cnae: "5920100", descricao: "ATIVIDADES FONOGRAFICAS" },
  { cnae: "5920100", descricao: "EDICAO DE MUSICA" },
  { cnae: "5920100", descricao: "GRAVACOES SONORAS; PRODUCAO DE" },
  { cnae: "60101", descricao: "ATIVIDADES DE RADIO" },
  {
    cnae: "6010100",
    descricao: "MARKETING EM RADIO; VENDA DE ESPACO DE PROPAGANDA EM RADIO",
  },
  { cnae: "6010100", descricao: "CANAIS DE MUSICA; SERVICOS DE" },
  { cnae: "6010100", descricao: "RADIO AFILIADA" },
  { cnae: "6010100", descricao: "EMISSORA DE RADIO NA INTERNET" },
  { cnae: "6010100", descricao: "PROGRAMAS DE RADIO; DIFUSAO DE" },
  { cnae: "6010100", descricao: "RADIO; ESTACAO DE" },
  { cnae: "6010100", descricao: "ESTACAO DE RADIO" },
  {
    cnae: "6010100",
    descricao: "ESPACO PUBLICITARIO EM RADIO; ATIVIDADE DE VENDA DE",
  },
  { cnae: "6010100", descricao: "PRODUCAO E DIFUSAO DE PROGRAMAS DE RADIO" },
  { cnae: "6010100", descricao: "RADIO; EMISSORA DE" },
  { cnae: "6010100", descricao: "DIFUSAO DE PROGRAMAS DE RADIO" },
  {
    cnae: "6010100",
    descricao:
      "RETRANSMISSAO (TRANSPORTE) DE SINAIS DE RADIODIFUSAO SONORA; SERVICOS DE",
  },
  {
    cnae: "6010100",
    descricao:
      "TRANSMISSAO (TRANSPORTE) DE SINAIS DE RADIODIFUSAO SONORA; SERVICOS DE",
  },
  { cnae: "6010100", descricao: "ESTUDIO DE RADIO; ATIVIDADES DE" },
  { cnae: "6010100", descricao: "RADIO COMUNITARIA; SERVICOS DE" },
  { cnae: "6010100", descricao: "BROADCASTING EM RADIO" },
  { cnae: "6010100", descricao: "CADEIAS RADIOFONICAS" },
  { cnae: "6010100", descricao: "RADIO; ATIVIDADES DE" },
  { cnae: "6010100", descricao: "RADIODIFUSAO; ESTACAO DE" },
  { cnae: "6010100", descricao: "DIFUSAO DE SINAIS DE RADIO" },
  { cnae: "6010100", descricao: "RADIO REPETIDORA" },
  { cnae: "6010100", descricao: "PROGRAMAS DE RADIO VIA INTERNET" },
  { cnae: "6010100", descricao: "PROGRAMAS DE RADIO; TRANSMISSAO DE" },
  { cnae: "60217", descricao: "ATIVIDADES DE TELEVISAO ABERTA" },
  { cnae: "6021700", descricao: "EMISSORA DE TELEVISAO ABERTA" },
  {
    cnae: "6021700",
    descricao: "PRODUCAO DE PROGRAMAS EM CANAL DE TELEVISAO ABERTA",
  },
  {
    cnae: "6021700",
    descricao: "DIFUSAO DE SINAIS DE TELEVISAO ABERTA; BROADCASTING",
  },
  {
    cnae: "6021700",
    descricao: "ESTACAO CAPTADORA DE SINAIS DE TELEVISAO ABERTA",
  },
  {
    cnae: "6021700",
    descricao:
      "PRODUTORES INDEPENDENTES; PRODUCAO DE PROGRAMAS DE TELEVISAO AO VIVO EM ESTUDIOS DE TV POR",
  },
  { cnae: "6021700", descricao: "ATIVIDADES DE TELEVISAO ABERTA VIA INTERNET" },
  { cnae: "6021700", descricao: "ESTACAO DE TELEVISAO ABERTA" },
  { cnae: "6021700", descricao: "PROGRAMACAO DE TELEVISAO ABERTA; DIFUSAO DE" },
  { cnae: "6021700", descricao: "REDE DE TELEVISAO ABERTA" },
  { cnae: "6021700", descricao: "CANAL DE TELEVISAO ABERTA" },
  { cnae: "6021700", descricao: "ESTUDIO DE TELEVISAO ABERTA; OPERACAO DE" },
  {
    cnae: "6021700",
    descricao: "PRODUCAO E DIFUSAO DE PROGRAMAS DE TELEVISAO ABERTA",
  },
  {
    cnae: "6021700",
    descricao: "MERCHANDISING EM PROGRAMAS DE TELEVISAO ABERTA",
  },
  { cnae: "6021700", descricao: "DIFUSAO DE PROGRAMAS DE TELEVISAO ABERTA" },
  {
    cnae: "6021700",
    descricao: "MARKETING EM TELEVISAO ABERTA; VENDA DE ESPACO DE PROPAGANDA",
  },
  {
    cnae: "6021700",
    descricao:
      "ESPACO PUBLICITARIO EM CANAL DE TELEVISAO ABERTA; ATIVIDADE DE VENDA DE",
  },
  { cnae: "6021700", descricao: "BROADCASTING EM TELEVISAO ABERTA" },
  {
    cnae: "6021700",
    descricao:
      "PRODUCAO DE PROGRAMAS DE TELEVISAO AO VIVO EM ESTUDIO DE TV; ATIVIDADE DE",
  },
  { cnae: "6021700", descricao: "AFILIADA DE TELEVISAO ABERTA" },
  { cnae: "6021700", descricao: "AFILIADAS; ESTACOES DE TELEVISAO" },
  {
    cnae: "60225",
    descricao:
      "PROGRAMADORAS E ATIVIDADES RELACIONADAS A TELEVISAO POR ASSINATURA",
  },
  { cnae: "6022501", descricao: "PROGRAMADORA DE TELEVISAO POR ASSINATURA" },
  {
    cnae: "6022501",
    descricao:
      "MARKETING EM TELEVISAO POR ASSINATURA; VENDA DE ESPACO DE PROPAGANDA",
  },
  {
    cnae: "6022501",
    descricao:
      "ESPACO PUBLICITARIO EM CANAL DE TELEVISAO POR ASSINATURA; VENDA DE",
  },
  { cnae: "6022501", descricao: "PROGRAMADORAS" },
  {
    cnae: "6022501",
    descricao: "PROGRAMAS DE TELEVISAO POR ASSINATURA; ATIVIDADE DE DIFUSAO DE",
  },
  { cnae: "6022501", descricao: "TELEVISAO POR ASSINATURA; EMISSORA DE" },
  { cnae: "6022501", descricao: "TELEVISAO POR ASSINATURA; CANAL DE" },
  {
    cnae: "6022501",
    descricao: "TELEVISAO POR ASSINATURA; DIFUSAO DE SINAIS DE",
  },
  {
    cnae: "6022501",
    descricao: "PROGRAMACAO DE TELEVISAO POR ASSINATURA; ATIVIDADE DE",
  },
  {
    cnae: "6022502",
    descricao:
      "INTERMEDIACAO ENTRE PROGRAMADORAS NACIONAIS E ESTRANGEIRAS E AS OPERADORAS; ATIVIDADE DE",
  },
  {
    cnae: "6022502",
    descricao:
      "NEGOCIACAO DE PROGRAMACAO DE TELEVISAO POR ASSINATURA, EXCETO POR PROGRAMADORAS; EMPRESA DE",
  },
  { cnae: "61108", descricao: "TELECOMUNICACOES POR FIO" },
  { cnae: "6110801", descricao: "TELEFONE CONVENCIONAL POR FIO; COMPANHIA DE" },
  {
    cnae: "6110801",
    descricao: "TELEFONIA FIXA DE LONGA DISTANCIA NACIONAL; SERVICOS DE",
  },
  { cnae: "6110801", descricao: "TELEFONIA CONVENCIONAL POR FIO; SERVICOS DE" },
  {
    cnae: "6110801",
    descricao: "TELEFONIA FIXA DE LONGA DISTANCIA INTERNACIONAL; SERVICOS DE",
  },
  { cnae: "6110801", descricao: "TELEFONE CONVENCIONAL POR FIO; EMPRESA DE" },
  { cnae: "6110801", descricao: "TELEFONIA FIXA COMUTADA - STFC; SERVICOS DE" },
  { cnae: "6110801", descricao: "TELEFONICA CONVENCIONAL - POR FIO; EMPRESA" },
  { cnae: "6110801", descricao: "TELEFONE POR FIO; EMPRESA DE" },
  { cnae: "6110801", descricao: "TELEFONIA FIXA LOCAL; SERVICOS DE" },
  {
    cnae: "6110801",
    descricao: "COMUNICACAO TELEFONICA CONVENCIONAL POR FIO; SERVICOS DE",
  },
  { cnae: "6110802", descricao: "REDE COMUTADA POR PACOTE; SERVICOS DE" },
  { cnae: "6110802", descricao: "REDE COMUTADA POR CIRCUITO; SERVICOS DE" },
  { cnae: "6110802", descricao: "LINHA DEDICADA; SERVICOS POR" },
  {
    cnae: "6110802",
    descricao: "REDES DE TRANSPORTE DE TELECOMUNICACOES - SRTT; SERVICOS DE",
  },
  {
    cnae: "6110803",
    descricao:
      "INFORMACOES MULTIMIDIA, TRANSMISSAO DE, EMISSAO DE, RECEPCAO DE; SERVICOS DE",
  },
  { cnae: "6110803", descricao: "COMUNICACAO MULTIMIDIA - SCM; SERVICOS DE" },
  { cnae: "6110899", descricao: "CARRIER; SERVICOS DE" },
  {
    cnae: "6110899",
    descricao: "INTERCONEXAO ENTRE REDES DE TELECOMUNICACOES; SERVICOS DE",
  },
  { cnae: "6110899", descricao: "TELEX; SERVICOS DE" },
  { cnae: "61205", descricao: "TELECOMUNICACOES SEM FIO" },
  { cnae: "6120501", descricao: "TELEFONIA CELULAR; SERVICOS DE" },
  { cnae: "6120501", descricao: "TELEFONIA MOVEL CELULAR; SERVICOS DE" },
  { cnae: "6120501", descricao: "TELEFONE CELULAR SEM FIO; EMPRESA DE" },
  { cnae: "6120501", descricao: "TELEFONICA CELULAR - SEM FIO; COMPANHIA" },
  { cnae: "6120501", descricao: "SERVICO MOVEL DE CELULAR - SMC" },
  { cnae: "6120501", descricao: "TELEFONE CELULAR SEM FIO; COMPANHIA DE" },
  { cnae: "6120501", descricao: "TELEFONE CELULAR; SERVICOS DE" },
  { cnae: "6120501", descricao: "TELEFONICA CELULAR SEM FIO; EMPRESA DE" },
  { cnae: "6120502", descricao: "TRUNKING; SERVICOS DE" },
  { cnae: "6120502", descricao: "SISTEMAS TRONCALIZADOS; SERVICOS DE" },
  { cnae: "6120502", descricao: "SERVICO MOVEL ESPECIALIZADO - SME" },
  { cnae: "6120502", descricao: "TRUNK; SERVICOS DE" },
  { cnae: "6120599", descricao: "RADIOCOMUNICACAO; SERVICOS DE" },
  { cnae: "6120599", descricao: "SERVICOS MOVEIS MARITIMOS - SMM" },
  { cnae: "6120599", descricao: "RADIOAMADORES; COMUNICACAO VIA SERVICOS DE" },
  { cnae: "6120599", descricao: "SERVICOS MOVEIS PESSOAIS - SMP" },
  { cnae: "6120599", descricao: "RADIOCHAMADA - SER; SERVICOS DE" },
  { cnae: "6120599", descricao: "COMUNICACAO POR BIP, PAGER; SERVICOS DE" },
  { cnae: "6120599", descricao: "SERVICOS MOVEIS AERONAUTICOS" },
  { cnae: "6120599", descricao: "PAGER; SERVICOS DE" },
  { cnae: "6120599", descricao: "RADIOAMADOR; SERVICOS DE" },
  { cnae: "61302", descricao: "TELECOMUNICACOES POR SATELITE" },
  { cnae: "6130200", descricao: "ESTACAO DE SATELITE DE COMUNICACAO" },
  {
    cnae: "6130200",
    descricao:
      "RETRANSMISSAO, TRANSPORTE DE SINAIS DE SONS E IMAGENS DOS CANAIS DE TELEVISAO ABERTA; SERVICOS DE",
  },
  {
    cnae: "6130200",
    descricao: "TRANSMISSAO DE IMAGENS E DADOS POR SATELITE; SERVICOS DE",
  },
  { cnae: "6130200", descricao: "CAPACIDADE SATELITAL; EXPLORACAO DE" },
  { cnae: "6130200", descricao: "SERVICO MOVEL GLOBAL POR SATELITE - SMGS" },
  { cnae: "6130200", descricao: "SERVICO LIMITADO MOVEL MARITIMO" },
  { cnae: "6130200", descricao: "OPERADORAS DE SATELITES; SERVICOS DE" },
  { cnae: "6130200", descricao: "SERVICO LIMITADO ESPECIALIZADO - SLE" },
  {
    cnae: "6130200",
    descricao:
      "PROVIMENTO DE CAPACIDADE ESPACIAL; TELECOMUNICACOES POR SATELITE",
  },
  {
    cnae: "6130200",
    descricao: "TRANSMISSAO DE SONS POR SATELITE; SERVICOS DE",
  },
  { cnae: "6130200", descricao: "TELECOMUNICACOES POR SATELITE; SERVICOS DE" },
  {
    cnae: "6130200",
    descricao:
      "TRANSMISSAO DE VOZES, DADOS, TEXTOS, SONS E IMAGENS POR SATELITE; SERVICOS DE",
  },
  { cnae: "6130200", descricao: "TRANSMISSAO POR SATELITE; SERVICOS DE" },
  {
    cnae: "61418",
    descricao: "OPERADORAS DE TELEVISAO POR ASSINATURA POR CABO",
  },
  { cnae: "6141800", descricao: "OPERADORAS DE TELEVISAO A CABO" },
  { cnae: "6141800", descricao: "TELEVISAO POR ASSINATURA POR CABO" },
  {
    cnae: "6141800",
    descricao: "TRANSMISSAO DE TELEVISAO POR ASSINATURA POR CABO",
  },
  {
    cnae: "6141800",
    descricao: "SERVICOS DE TELEVISAO POR ASSINATURA POR CABO",
  },
  {
    cnae: "6141800",
    descricao: "ACESSO A INTERNET POR OPERADORAS DE TELEVISAO POR CABO",
  },
  {
    cnae: "6141800",
    descricao:
      "DISTRIBUICAO DE SINAIS PARA ASSINANTES DE TELEVISAO POR ASSINATURA POR CABO",
  },
  {
    cnae: "61426",
    descricao: "OPERADORAS DE TELEVISAO POR ASSINATURA POR MICROONDAS",
  },
  { cnae: "6142600", descricao: "TELEVISAO POR ASSINATURA POR MICROONDAS" },
  {
    cnae: "6142600",
    descricao:
      "DISTRIBUICAO DE SINAIS PARA ASSINANTES DE TELEVISAO POR MICROONDAS",
  },
  {
    cnae: "6142600",
    descricao: "ACESSO A INTERNET POR OPERADORAS DE TELEVISAO POR MICROONDAS",
  },
  {
    cnae: "6142600",
    descricao:
      "DISTRIBUICAO DE SINAIS MULTIPONTO MULTICANAIS - MMDS; SERVICOS DE",
  },
  { cnae: "6142600", descricao: "OPERADORAS DE TELEVISAO POR MICROONDAS" },
  {
    cnae: "6142600",
    descricao: "SERVICOS DE TELEVISAO POR ASSINATURA POR MICROONDAS",
  },
  {
    cnae: "6142600",
    descricao: "TRANSMISSAO DE TELEVISAO POR ASSINATURA POR MICROONDAS",
  },
  {
    cnae: "61434",
    descricao: "OPERADORAS DE TELEVISAO POR ASSINATURA POR SATELITE",
  },
  {
    cnae: "6143400",
    descricao: "OPERADORAS DE TELEVISAO POR ASSINATURA POR SATELITE",
  },
  {
    cnae: "6143400",
    descricao: "DIRECT TO THE HOME; OPERADORA DE TELEVISAO POR SATELITE",
  },
  { cnae: "6143400", descricao: "TELEVISAO POR ASSINATURA POR SATELITE" },
  {
    cnae: "6143400",
    descricao: "TRANSMISSAO DE TELEVISAO POR ASSINATURA POR SATELITE",
  },
  {
    cnae: "6143400",
    descricao:
      "DISTRIBUICAO DE SINAIS DE TELEVISAO E AUDIO POR ASSINATURA VIA SATELITE - DTH; SERVICOS DE",
  },
  { cnae: "61906", descricao: "OUTRAS ATIVIDADES DE TELECOMUNICACOES" },
  { cnae: "6190601", descricao: "PROVEDORES DA INTRANET; SERVICOS DE" },
  { cnae: "6190601", descricao: "PROVEDORES DA INTERNET; SERVICOS DE" },
  {
    cnae: "6190601",
    descricao: "PROVEDORES DE ACESSO A REDE DE TELECOMUNICACOES; SERVICOS DE",
  },
  {
    cnae: "6190601",
    descricao: "ACESSO A INTERNET POR PROVEDORES DA INTERNET",
  },
  {
    cnae: "6190602",
    descricao: "PROVEDORES DE VOZ SOBRE PROTOCOLO INTERNET- VOIP",
  },
  { cnae: "6190602", descricao: "VOZ SOBRE IP - VOIP" },
  { cnae: "6190699", descricao: "SERVICOS ESPECIAIS EM TELECOMUNICACOES - SE" },
  { cnae: "6190699", descricao: "CHIPS DE TELEFONE CELULAR; SERVICOS DE" },
  { cnae: "6190699", descricao: "SUPORTE A INTERLIGACAO DE REDES" },
  {
    cnae: "6190699",
    descricao: "REDE E CIRCUITO ESPECIALIZADO - RCE; SERVICOS DE",
  },
  {
    cnae: "6190699",
    descricao:
      "REDES DE TELECOMUNICACOES PARA PESSOAS JURIDICAS COM ATIVIDADE ESPECIFICA; SERVICOS DE",
  },
  { cnae: "6190699", descricao: "CHIPS DE CELULAR; COMERCIO VAREJISTA DE" },
  {
    cnae: "6190699",
    descricao:
      "COMPRA E VENDA DA CAPACIDADE DE REDES, SEM PRESTACAO DE OUTROS SERVICOS DE TELECOMUNICACAO",
  },
  {
    cnae: "6190699",
    descricao:
      "RECARGA DE TELEFONE CELULAR (EXCETO VENDA DE CARTAO TELEFONICO); SERVICOS DE",
  },
  { cnae: "6190699", descricao: "ESTACAO DE RADAR; OPERACAO DE" },
  {
    cnae: "6190699",
    descricao:
      "INSTALACAO E MANUTENCAO DE LINHAS DE TELEFONES PUBLICOS; SERVICOS DE",
  },
  { cnae: "6190699", descricao: "RASTREAMENTO POR SATELITE; SERVICOS DE" },
  {
    cnae: "6190699",
    descricao: "SERVICOS DE CONEXAO E DE REDES DE TELECOMUNICACOES EM PREDIOS",
  },
  {
    cnae: "6190699",
    descricao: "SERVICO LIMITADO PRIVADO - SLP EM TELECOMUNICACOES",
  },
  { cnae: "6190699", descricao: "SUPORTE A PABX; SERVICOS DE" },
  { cnae: "6190699", descricao: "SATELITES PARA RASTREAMENTO; SERVICOS DE" },
  { cnae: "6190699", descricao: "USO DE SATELITE PARA TRACKING; SERVICOS DE" },
  {
    cnae: "6190699",
    descricao:
      "INSTALACAO E MANUTENCAO DAS CONEXOES DE TERMINAIS DE REDE DE TELECOMUNICACAO EM PREDIOS",
  },
  {
    cnae: "6190699",
    descricao:
      "INSTALACAO E MANUTENCAO DE SUPORTES PARA CIRCUITOS DE DADOS; SERVICOS DE",
  },
  { cnae: "6190699", descricao: "TELEMETRIA; SERVICOS DE" },
  {
    cnae: "6190699",
    descricao:
      "INSTALACAO E MANUTENCAO DE LINHAS DE TELEFONES PARTICULARES; SERVICOS DE",
  },
  {
    cnae: "6190699",
    descricao: "OUTORGADOS DOS SERVICOS DE TELECOMUNICACOES; REVENDEDORES DE",
  },
  {
    cnae: "62015",
    descricao: "DESENVOLVIMENTO DE PROGRAMAS DE COMPUTADOR SOB ENCOMENDA",
  },
  { cnae: "6201501", descricao: "TRADUCAO DE SOFTWARE; SERVICOS DE" },
  {
    cnae: "6201501",
    descricao: "SOFTWARE SOB ENCOMENDA; DESENVOLVIMENTO, PRODUCAO DE",
  },
  {
    cnae: "6201501",
    descricao: "PROGRAMAS DE COMPUTADOR SOB ENCOMENDA; ELABORACAO DE",
  },
  {
    cnae: "6201501",
    descricao:
      "PROGRAMACAO DE SISTEMAS INFORMATICOS SOB ENCOMENDA; SERVICOS DE",
  },
  {
    cnae: "6201501",
    descricao: "BANCO DE DADOS SOB ENCOMENDA; DESENVOLVIMENTO DE",
  },
  {
    cnae: "6201501",
    descricao: "EDICAO ON LINE DE BANCO DE DADOS; SERVICOS DE",
  },
  {
    cnae: "6201501",
    descricao:
      "DESENVOLVIMENTO DE PROJETOS E MODELAGEM DE BANCO DE DADOS SOB ENCOMENDA",
  },
  {
    cnae: "6201501",
    descricao:
      "PROGRAMACAO COM O USO DE LINGUAGENS DE PROGRAMACAO; ATIVIDADES DE",
  },
  {
    cnae: "6201501",
    descricao: "DESENVOLVIMENTO DE APLICATIVO INFORMATICO SOB ENCOMENDA",
  },
  {
    cnae: "6201501",
    descricao: "EDICAO DE SOFTWARE SOB ENCOMENDA; SERVICOS DE",
  },
  { cnae: "6311900", descricao: "TRATAMENTO DE DADOS PARA PROCESSAMENTO" },
  {
    cnae: "6201501",
    descricao:
      "CRIACAO, CONFIGURACAO DE SOFTWARE DE BANCO DE DADOS SOB ENCOMENDA",
  },
  {
    cnae: "6201501",
    descricao:
      "SOFTWARE DE BANCO DE DADOS SOB ENCOMENDA; PRODUCAO, CRIACAO, DESENVOLVIMENTO DE",
  },
  {
    cnae: "6201501",
    descricao: "PROGRAMAS DE BANCO DE DADOS SOB ENCOMENDA; ELABORACAO DE",
  },
  {
    cnae: "6201501",
    descricao:
      "PROGRAMAS DE INFORMATICA SOB ENCOMENDA; DESENVOLVIMENTO, PRODUCAO, DOCUMENTACAO DE",
  },
  {
    cnae: "6201501",
    descricao:
      "DOCUMENTACAO DE PROGRAMAS DE COMPUTADOR DESENVOLVIDOS SOB ENCOMENDA; FORNECIMENTO DE",
  },
  {
    cnae: "6201501",
    descricao: "PROGRAMAS DE INFORMATICA SOB ENCOMENDA; ELABORACAO DE",
  },
  {
    cnae: "6201502",
    descricao:
      "PORTAIS NA INTERNET; CONFECCAO DE, DESENVOLVIMENTO DE, CRIACAO DE, SERVICOS DE",
  },
  {
    cnae: "6201502",
    descricao:
      "PAGINAS NA INTERNET; CONFECCAO DE, DESENVOLVIMENTO DE, CRIACAO DE, SERVICOS DE",
  },
  {
    cnae: "6201502",
    descricao:
      "WEB DESIGN; DESENVOLVIMENTO, CRIACAO DE INTERFACES PARA A INTERNET",
  },
  { cnae: "6201502", descricao: "EDICAO DE SITE; SERVICOS DE" },
  {
    cnae: "62023",
    descricao:
      "DESENVOLVIMENTO E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR CUSTOMIZAVEIS",
  },
  {
    cnae: "6202300",
    descricao: "PROGRAMAS DE COMPUTADOR CUSTOMIZAVEIS; DESENVOLVIMENTO DE",
  },
  {
    cnae: "6202300",
    descricao: "CESSAO DE DIREITO DE USO DE SOFTWARE CUSTOMIZAVEL; SERVICOS DE",
  },
  {
    cnae: "6202300",
    descricao: "PROGRAMAS DE COMPUTADOR CUSTOMIZAVEIS; LICENCIAMENTO DE",
  },
  {
    cnae: "6202300",
    descricao: "PROGRAMAS DE INFORMATICA CUSTOMIZAVEIS; DESENVOLVIMENTO DE",
  },
  {
    cnae: "6202300",
    descricao:
      "CESSAO DE DIREITO DE USO DE PROGRAMAS DE COMPUTADOR CUSTOMIZAVEIS; SERVICOS DE",
  },
  { cnae: "6202300", descricao: "SOFTWARE CUSTOMIZAVEIS; REPRESENTACAO DE" },
  { cnae: "6202300", descricao: "SOFTWARE CUSTOMIZAVEIS; LICENCIAMENTO DE" },
  { cnae: "6202300", descricao: "SOFTWARE CUSTOMIZAVEIS; DESENVOLVIMENTO DE" },
  {
    cnae: "6202300",
    descricao: "PROGRAMAS DE INFORMATICA CUSTOMIZAVEIS; LICENCIAMENTO DE",
  },
  {
    cnae: "62031",
    descricao:
      "DESENVOLVIMENTO E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR NAOCUSTOMIZAVEIS",
  },
  {
    cnae: "6203100",
    descricao: "PROGRAMAS DE INFORMATICA NAO-CUSTOMIZAVEIS; REPRESENTACAO DE",
  },
  {
    cnae: "6203100",
    descricao:
      "JOGOS DE COMPUTADOR PARA TODAS AS PLATAFORMAS; DESENVOLVIMENTO DE",
  },
  {
    cnae: "6203100",
    descricao: "PROGRAMAS DE INFORMATICA NAO-CUSTOMIZAVEIS; LICENCIAMENTO DE",
  },
  {
    cnae: "6203100",
    descricao: "SOFTWARE NAO-CUSTOMIZAVEIS; LICENCIAMENTO DE",
  },
  {
    cnae: "6203100",
    descricao:
      "APLICATIVOS E PROGRAMAS DE COMPUTADOR NAO-CUSTOMIZAVEIS; REPRESENTACAO DE",
  },
  {
    cnae: "6203100",
    descricao:
      "APLICATIVOS E PROGRAMAS DE COMPUTADOR NAO-CUSTOMIZAVEIS; PRODUCAO, DESENVOLVIMENTO DE",
  },
  {
    cnae: "6203100",
    descricao:
      "APLICATIVOS E PROGRAMAS DE COMPUTADOR NAO-CUSTOMIZAVEIS; LICENCIAMENTO DE",
  },
  {
    cnae: "6203100",
    descricao: "SOFTWARE NAO-CUSTOMIZAVEIS; DESENVOLVIMENTO DE",
  },
  { cnae: "6203100", descricao: "SISTEMAS OPERACIONAIS; DESENVOLVIMENTO DE" },
  {
    cnae: "6203100",
    descricao:
      "PROGRAMAS DE INFORMATICA NAO-CUSTOMIZAVEIS; PRODUCAO, DESENVOLVIMENTO DE",
  },
  { cnae: "62040", descricao: "CONSULTORIA EM TECNOLOGIA DA INFORMACAO" },
  { cnae: "6204000", descricao: "CONSULTORIA EM INFORMATICA" },
  { cnae: "6204000", descricao: "HARDWARE; CONSULTORIA EM" },
  {
    cnae: "6204000",
    descricao: "ASSESSORIA PARA COMPRA E INSTALACAO DE PERIFERICOS",
  },
  {
    cnae: "6204000",
    descricao: "ASSESSORIA EM SOFTWARE, PROGRAMAS DE INFORMATICA",
  },
  { cnae: "6204000", descricao: "CONSULTORIA EM ANALISE DE SISTEMAS" },
  { cnae: "6204000", descricao: "CUSTOMIZACAO DE PROGRAMAS DE COMPUTADOR" },
  {
    cnae: "6204000",
    descricao: "SOFTWARE, PROGRAMAS DE INFORMATICA; ASSESSORIA EM",
  },
  {
    cnae: "6204000",
    descricao:
      "SOFTWARE, PROGRAMAS DE INFORMATICA, SOB ENCOMENDA; ATUALIZACAO DE",
  },
  { cnae: "6204000", descricao: "HARDWARE; ASSESSORIA EM" },
  { cnae: "6204000", descricao: "ASSESSORIA, CONSULTORIA EM INFORMATICA" },
  {
    cnae: "6204000",
    descricao:
      "GATEWAY (PONTE DE LIGACAO), SISTEMA INTERMEDIARIO (SOFTWARE) DE INTERLIGACAO DE REDES COMERCIAIS; SERVICOS DE",
  },
  { cnae: "6204000", descricao: "CONSULTORIA EM HARDWARE E SOFTWARE" },
  { cnae: "6204000", descricao: "CONSULTORIA EM TECNOLOGIA DA INFORMACAO" },
  {
    cnae: "6204000",
    descricao: "PROJETOS PARA INSTALACOES DE REDE; DESENVOLVIMENTO DE",
  },
  { cnae: "6204000", descricao: "CUSTOMIZACAO DE SOFTWARE" },
  {
    cnae: "6204000",
    descricao: "CONSULTORIA TECNICA EM INFORMATICA; SERVICOS DE",
  },
  { cnae: "6204000", descricao: "CONSULTORIA EM PROGRAMAS DE COMPUTADOR" },
  {
    cnae: "6204000",
    descricao: "ASSESSORIA, CONSULTORIA EM SISTEMAS DE INFORMATICA",
  },
  {
    cnae: "62091",
    descricao:
      "SUPORTE TECNICO, MANUTENCAO E OUTROS SERVICOS EM TECNOLOGIA DA INFORMACAO",
  },
  { cnae: "6209100", descricao: "INSTALACAO DE SOFTWARE; SERVICOS DE" },
  {
    cnae: "6209100",
    descricao:
      "APOIO NA CONFIGURACAO DE EQUIPAMENTOS, INSTALACAO E USO DE APLICATIVOS INFORMATICOS; SERVICOS DE",
  },
  {
    cnae: "6209100",
    descricao: "RECUPERACAO DE PANES EM PROGRAMAS DE INFORMATICA; SERVICOS DE",
  },
  { cnae: "6209100", descricao: "HELP-DESK; SERVICOS DE APOIO A CLIENTES" },
  {
    cnae: "6209100",
    descricao:
      "INSTALACAO DE EQUIPAMENTOS DE INFORMATICA (HARDWARE) E PROGRAMAS DE COMPUTADOR; SERVICOS DE",
  },
  { cnae: "6209100", descricao: "MANUTENCAO EM TECNOLOGIA DA INFORMACAO" },
  {
    cnae: "6209100",
    descricao: "MANUTENCAO DE SISTEMAS INFORMATICOS; SERVICOS DE",
  },
  {
    cnae: "6209100",
    descricao:
      "SEGURANCA EM INFORMATICA, ANTIVIRUS, CRIPTOGRAFIA, AUTENTICACAO, DETECCAO DE HACKERS; SERVICOS DE",
  },
  { cnae: "6209100", descricao: "SUPORTE TECNICO EM TECNOLOGIA DA INFORMACAO" },
  {
    cnae: "6209100",
    descricao: "SEGURANCA EM TECNOLOGIA DA INFORMACAO; SERVICOS DE",
  },
  {
    cnae: "6209100",
    descricao:
      "CONFIGURACAO DE EQUIPAMENTOS DE INFORMATICA; SERVICOS DE APOIO A CLIENTES",
  },
  { cnae: "6209100", descricao: "RECUPERACAO DE DADOS, ARQUIVOS; SERVICOS DE" },
  { cnae: "6209100", descricao: "PANES INFORMATICAS; RECUPERACAO DE" },
  {
    cnae: "6209100",
    descricao: "MANUTENCAO DE PROGRAMAS DE INFORMATICA; SERVICOS DE",
  },
  {
    cnae: "63119",
    descricao:
      "TRATAMENTO DE DADOS, PROVEDORES DE SERVICOS DE APLICACAO E SERVICOS DE HOSPEDAGEM NA INTERNET",
  },
  {
    cnae: "6311900",
    descricao: "BANCO DE DADOS; PRODUCAO DE LISTAGENS, TABULACOES, CONSULTAS",
  },
  {
    cnae: "6311900",
    descricao: "HOSPEDAGEM DE PAGINAS, SITES - WEB HOSTING; SERVICOS DE",
  },
  {
    cnae: "6311900",
    descricao: "DIGITACAO DE DADOS PARA PROCESSAMENTO; SERVICOS DE",
  },
  { cnae: "6311900", descricao: "ALUGUEL DE HORA EM COMPUTADOR" },
  {
    cnae: "6311900",
    descricao: "HOSPEDAGEM DE DADOS NA INTERNET; SERVICOS DE",
  },
  {
    cnae: "6311900",
    descricao: "PROCESSAMENTO E ARMAZENAMENTO DE MIDIA ELETRONICA; SERVICOS DE",
  },
  {
    cnae: "6311900",
    descricao: "BANCOS DE DADOS DE TERCEIROS; GESTAO E OPERACAO DE",
  },
  { cnae: "6311900", descricao: "PROCESSAMENTO DE DADOS; SERVICOS DE" },
  {
    cnae: "6311900",
    descricao: "COMPUTADORES; SERVICOS DE COMPARTILHAMENTO DE",
  },
  {
    cnae: "6311900",
    descricao: "INSTALACOES INFORMATICAS; USO COMPARTILHADO DE",
  },
  {
    cnae: "6311900",
    descricao: "WEB HOSTING; SERVICOS DE HOSPEDAGEM DE SITES",
  },
  {
    cnae: "6311900",
    descricao:
      "PROCESSAMENTO E GUARDA DE DOCUMENTOS NA FORMA ELETRONICA; SERVICOS DE",
  },
  {
    cnae: "6311900",
    descricao: "TRANSCRICAO DE DADOS PARA PROCESSAMENTO; SERVICOS DE",
  },
  {
    cnae: "6311900",
    descricao: "ENTRADA DE DADOS PARA PROCESSAMENTO; SERVICOS DE",
  },
  { cnae: "6311900", descricao: "BANCO DE DADOS DE TERCEIROS; GESTAO DE" },
  { cnae: "6311900", descricao: "DIGITALIZACAO PARA ENTRADA DE DADOS" },
  {
    cnae: "6311900",
    descricao:
      "ESCANEAMENTO E LEITURA OTICA DE DOCUMENTOS (DIGITALIZACAO DE DOCUMENTOS); ATIVIDADES DE",
  },
  {
    cnae: "6311900",
    descricao:
      "PROCESSAMENTO DE DADOS DE TERCEIROS; GESTAO E OPERACAO DE EQUIPAMENTOS DE",
  },
  {
    cnae: "6311900",
    descricao: "HOSPEDAGEM NA INTERNET - WEB HOSTING; SERVICOS DE",
  },
  { cnae: "6311900", descricao: "CPD; SERVICOS DE" },
  {
    cnae: "63194",
    descricao:
      "PORTAIS, PROVEDORES DE CONTEUDO E OUTROS SERVICOS DE INFORMACAO NA INTERNET",
  },
  {
    cnae: "6319400",
    descricao: "MUSICA ATRAVES DA INTERNET; SERVICOS DE DISPONIBILIZACAO DE",
  },
  { cnae: "6319400", descricao: "PORTAL DE BUSCA DA WEB; SERVICOS DE" },
  { cnae: "6319400", descricao: "PAGINA DE PUBLICIDADE NA INTERNET" },
  { cnae: "6319400", descricao: "SEARCH ENGINE; ATIVIDADE DE" },
  { cnae: "6319400", descricao: "CONSULTA A BANCO DE DADOS; SERVICOS DE" },
  {
    cnae: "6319400",
    descricao:
      "IDENTIFICACAO E CADASTRAMENTO DE USUARIOS PARA FINS DE EMISSAO DE CERTIFICADOS DIGITAIS; SERVICO DE",
  },
  {
    cnae: "6319400",
    descricao: "SITE DE JOGOS NA INTERNET, EXCETO JOGOS DE AZAR; SERVICOS DE",
  },
  {
    cnae: "6319400",
    descricao: "BANCO DE INFORMACAO PARA PESQUISA E ANALISE; SERVICOS DE",
  },
  { cnae: "6319400", descricao: "SITE DE BUSCA NA INTERNET" },
  { cnae: "6319400", descricao: "CERTIFICACAO DIGITAL; SERVICOS DE" },
  {
    cnae: "6319400",
    descricao:
      "FORNECIMENTO DE BOLETINS METEOROLOGICOS DISPONIVEIS NA INTERNET; SERVICOS DE",
  },
  {
    cnae: "6319400",
    descricao:
      "EMISSAO, EXPEDICAO, DISTRIBUICAO, REVOGACAO E GERENCIAMENTO DE CERTIFICADOS DIGITAIS; SERVICO DE",
  },
  {
    cnae: "6319400",
    descricao: "DISTRIBUICAO ON LINE DE CONTEUDO; SERVICOS DE",
  },
  {
    cnae: "6319400",
    descricao: "OPERACAO DE PORTAIS DE CONTEUDO DA INTERNET, ATIVIDADES DE",
  },
  { cnae: "7120100", descricao: "TESTES ACUSTICOS; SERVICOS DE" },
  {
    cnae: "6319400",
    descricao:
      "PAGINAS DE ENTRETENIMENTO NA INTERNET, EXCETO JOGOS DE AZAR; OPERACAO DE",
  },
  { cnae: "6319400", descricao: "PROVEDORES DE E-MAIL; SERVICOS DE" },
  { cnae: "63917", descricao: "AGENCIAS DE NOTICIAS" },
  { cnae: "6391700", descricao: "AGENCIA DE NOTICIAS; SERVICOS DE" },
  {
    cnae: "6391700",
    descricao: "DISTRIBUICAO DE MATERIA NOTICIOSA; SERVICOS DE",
  },
  { cnae: "6391700", descricao: "DIVULGACAO DE NOTICIAS; SERVICOS DE" },
  {
    cnae: "6391700",
    descricao: "DISTRIBUICAO DE MATERIAL DE IMPRENSA; SERVICOS DE",
  },
  { cnae: "6391700", descricao: "AGENCIAMENTO DE NOTICIAS; SERVICOS DE" },
  {
    cnae: "63992",
    descricao:
      "OUTRAS ATIVIDADES DE PRESTACAO DE SERVICOS DE INFORMACAO NAO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "6399200", descricao: "SERVICOS DE INFORMACAO TELEFONICA" },
  {
    cnae: "6399200",
    descricao:
      "LEVANTAMENTO DE INFORMACOES POR CONTRATO OU COMISSAO; SERVICOS DE",
  },
  { cnae: "6399200", descricao: "CLIPPING; SERVICOS DE" },
  { cnae: "6399200", descricao: "RECORTES DE JORNAL, NOTICIAS; SERVICOS DE" },
  { cnae: "6399200", descricao: "CLIPAGEM; SERVICOS DE" },
  { cnae: "6399200", descricao: "RECORTE DE DIARIO OFICIAL; SERVICOS DE" },
  { cnae: "64107", descricao: "BANCO CENTRAL" },
  { cnae: "6410700", descricao: "BANCO CENTRAL DO BRASIL" },
  { cnae: "6410700", descricao: "ATIVIDADES DO BANCO CENTRAL DO BRASIL" },
  { cnae: "6410700", descricao: "AUTORIDADE MONETARIA" },
  { cnae: "6410700", descricao: "BANCO CENTRAL" },
  { cnae: "64212", descricao: "BANCOS COMERCIAIS" },
  { cnae: "6421200", descricao: "BANCO COMERCIAL" },
  {
    cnae: "6421200",
    descricao: "BANCO COMERCIAL PRIVADO COM PARTICIPACAO ESTRANGEIRA",
  },
  { cnae: "6421200", descricao: "BANCO COMERCIAL PRIVADO NACIONAL" },
  { cnae: "6421200", descricao: "FILIAL DE BANCO COMERCIAL ESTRANGEIRO" },
  { cnae: "6421200", descricao: "BANCO COMERCIAL PUBLICO FEDERAL" },
  { cnae: "6421200", descricao: "BANCO COMERCIAL PUBLICO ESTADUAL" },
  {
    cnae: "6421200",
    descricao: "BANCO COMERCIAL PRIVADO NACIONAL COM CONTROLE ESTRANGEIRO",
  },
  { cnae: "64221", descricao: "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL" },
  {
    cnae: "6422100",
    descricao:
      "BANCO MULTIPLO COM CARTEIRA COMERCIAL, NACIONAL COM PARTICIPACAO ESTRANGEIRA",
  },
  {
    cnae: "6422100",
    descricao: "BANCO MULTIPLO COM CARTEIRA COMERCIAL, NACIONAL PRIVADO",
  },
  { cnae: "6422100", descricao: "BANCO MULTIPLO COM CARTEIRA COMERCIAL" },
  {
    cnae: "6422100",
    descricao:
      "BANCO MULTIPLO COM CARTEIRA COMERCIAL, NACIONAL COM CONTROLE ESTRANGEIRO",
  },
  {
    cnae: "6422100",
    descricao: "BANCO MULTIPLO COM CARTEIRA COMERCIAL, PUBLICO ESTADUAL",
  },
  {
    cnae: "6422100",
    descricao: "FILIAL DE BANCO MULTIPLO COM CARTEIRA COMERCIAL, ESTRANGEIRO",
  },
  {
    cnae: "6422100",
    descricao: "BANCO MULTIPLO COM CARTEIRA COMERCIAL, PUBLICO FEDERAL",
  },
  { cnae: "64239", descricao: "CAIXAS ECONOMICAS" },
  { cnae: "6423900", descricao: "CAIXAS ECONOMICAS" },
  { cnae: "64247", descricao: "CREDITO COOPERATIVO" },
  { cnae: "6424701", descricao: "BANCO COOPERATIVO" },
  { cnae: "6424702", descricao: "COOPERATIVAS CENTRAIS DE CREDITO" },
  { cnae: "6424703", descricao: "COOPERATIVAS DE CREDITO MUTUO" },
  { cnae: "6424704", descricao: "COOPERATIVA DE CREDITO RURAL" },
  { cnae: "64310", descricao: "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL" },
  {
    cnae: "6431000",
    descricao: "FILIAL DE BANCO MULTIPLO SEM CARTEIRA COMERCIAL, ESTRANGEIRO",
  },
  {
    cnae: "6431000",
    descricao:
      "BANCO MULTIPLO SEM CARTEIRA COMERCIAL, NACIONAL, COM CONTROLE ESTRANGEIRO",
  },
  {
    cnae: "6431000",
    descricao: "BANCO MULTIPLO SEM CARTEIRA COMERCIAL, PUBLICO FEDERAL",
  },
  {
    cnae: "6431000",
    descricao: "BANCO MULTIPLO SEM CARTEIRA COMERCIAL, PUBLICO ESTADUAL",
  },
  {
    cnae: "6431000",
    descricao:
      "BANCO MULTIPLO SEM CARTEIRA COMERCIAL, NACIONAL, COM PARTICIPACAO ESTRANGEIRA",
  },
  {
    cnae: "6431000",
    descricao: "BANCO MULTIPLO SEM CARTEIRA COMERCIAL, NACIONAL PRIVADO",
  },
  { cnae: "6431000", descricao: "BANCO MULTIPLO SEM CARTEIRA COMERCIAL" },
  { cnae: "64328", descricao: "BANCOS DE INVESTIMENTO" },
  { cnae: "6432800", descricao: "BANCO DE INVESTIMENTO" },
  { cnae: "6432800", descricao: "FILIAL DE BANCO DE INVESTIMENTO ESTRANGEIRO" },
  {
    cnae: "6432800",
    descricao:
      "BANCO DE INVESTIMENTO PRIVADO NACIONAL COM PARTICIPACAO ESTRANGEIRA",
  },
  {
    cnae: "6432800",
    descricao:
      "BANCO DE INVESTIMENTO PRIVADO NACIONAL COM CONTROLE ESTRANGEIRO",
  },
  { cnae: "64336", descricao: "BANCOS DE DESENVOLVIMENTO" },
  { cnae: "6433600", descricao: "BANCO DE DESENVOLVIMENTO FEDERAL" },
  { cnae: "6433600", descricao: "BANCO DE FOMENTO" },
  {
    cnae: "6433600",
    descricao: "BNDES - BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO SOCIAL",
  },
  { cnae: "6433600", descricao: "BANCO DE DESENVOLVIMENTO ESTADUAL" },
  { cnae: "6433600", descricao: "BANCO DE DESENVOLVIMENTO" },
  { cnae: "64344", descricao: "AGENCIAS DE FOMENTO" },
  { cnae: "6434400", descricao: "AGENCIAS DE FOMENTO" },
  { cnae: "64352", descricao: "CREDITO IMOBILIARIO" },
  { cnae: "6435201", descricao: "CREDITO IMOBILIARIO; EMPRESA DE" },
  { cnae: "6435201", descricao: "INSTITUICAO DE CREDITO IMOBILIARIO" },
  { cnae: "6435201", descricao: "SOCIEDADE DE CREDITO IMOBILIARIO" },
  { cnae: "6435202", descricao: "ASSOCIACAO DE POUPANCA E EMPRESTIMO" },
  { cnae: "6435203", descricao: "COMPANHIA HIPOTECARIA" },
  {
    cnae: "64361",
    descricao:
      "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO  FINANCEIRAS",
  },
  {
    cnae: "6436100",
    descricao:
      "SOCIEDADE DE CREDITO, FINANCIAMENTO E INVESTIMENTO COM CONTROLE ESTRANGEIRO",
  },
  { cnae: "6436100", descricao: "CREDITO PESSOAL; SOCIEDADE, EMPRESA DE" },
  { cnae: "6436100", descricao: "FINANCIADORA DE CONSUMO; SOCIEDADE" },
  {
    cnae: "6436100",
    descricao:
      "COMPANHIA DE CREDITO, FINANCIAMENTO E INVESTIMENTO (FINANCEIRA)",
  },
  { cnae: "6436100", descricao: "CREDITO DIRETO AO CONSUMIDOR; EMPRESA DE" },
  {
    cnae: "6436100",
    descricao: "SOCIEDADE DE CREDITO, FINANCIAMENTO E INVESTIMENTO",
  },
  {
    cnae: "6436100",
    descricao:
      "SOCIEDADE DE CREDITO, FINANCIAMENTO E INVESTIMENTO COM PARTICIPACAO ESTRANGEIRA",
  },
  { cnae: "64379", descricao: "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR" },
  { cnae: "6437900", descricao: "SOCIEDADE DE CREDITO AO MICROEMPREENDEDOR" },
  {
    cnae: "64387",
    descricao:
      "BANCOS DE CAMBIO E OUTRAS INSTITUICOES DE INTERMEDIACAO NAOMONETARIA",
  },
  { cnae: "6438701", descricao: "BANCO DE CAMBIO" },
  {
    cnae: "6438799",
    descricao: "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA",
  },
  { cnae: "64409", descricao: "ARRENDAMENTO MERCANTIL" },
  { cnae: "6440900", descricao: "LEASING FINANCEIRO; EMPRESA DE" },
  {
    cnae: "6440900",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA CONSTRUCAO E OBRAS DE ENGENHARIA CIVIL; ARRENDAMENTO MERCANTIL (LEASING FINANCEIRO) DE",
  },
  {
    cnae: "6440900",
    descricao: "EMBARCACOES; ARRENDAMENTO MERCANTIL (LEASING FINANCEIRO) DE",
  },
  {
    cnae: "6440900",
    descricao:
      "MAQUINAS E EQUIPAMENTOS AGRICOLAS; ARRENDAMENTO MERCANTIL (LEASING FINANCEIRO) DE",
  },
  {
    cnae: "6440900",
    descricao: "BENS MOVEIS; ARRENDAMENTO MERCANTIL (LEASING FINANCEIRO) DE",
  },
  { cnae: "6440900", descricao: "ARRENDAMENTO MERCANTIL; ORGANISMO DE" },
  {
    cnae: "6440900",
    descricao:
      "QUALQUER MEIO DE TRANSPORTE TERRESTRE; ARRENDAMENTO MERCANTIL (LEASING FINANCEIRO) DE",
  },
  {
    cnae: "6440900",
    descricao: "BENS TANGIVEIS; ARRENDAMENTO MERCANTIL (LEASING FINANCEIRO) DE",
  },
  {
    cnae: "6440900",
    descricao:
      "PALCOS, COBERTURAS E OUTRAS ESTRUTURAS DE USO TEMPORARIO; ARRENDAMENTO MERCANTIL (LEASING FINANCEIRO) DE",
  },
  { cnae: "6440900", descricao: "VEICULOS; LEASING FINANCEIRO" },
  {
    cnae: "6440900",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA ESCRITORIO, INCLUSIVE COMPUTADORES E MATERIAL TELEFONICO; ARRENDAMENTO MERCANTIL (LEASING FINANCEIRO) DE",
  },
  { cnae: "6440900", descricao: "MAQUINAS E EQUIPAMENTOS; LEASING FINANCEIRO" },
  { cnae: "6440900", descricao: "LEASING FINANCEIRO" },
  { cnae: "6440900", descricao: "AUTOMOVEIS, CAMINHOES; LEASING FINANCEIRO" },
  { cnae: "6440900", descricao: "ARRENDAMENTO MERCANTIL; EMPRESA DE" },
  {
    cnae: "6440900",
    descricao:
      "EQUIPAMENTOS CIENTIFICOS, MEDICOS E HOSPITALARES, SEM OPERADOR; ARRENDAMENTO MERCANTIL (LEASING FINANCEIRO) DE",
  },
  { cnae: "6440900", descricao: "EMPRESA DE LEASING FINANCEIRO" },
  { cnae: "6440900", descricao: "LEASING FINANCEIRO DE AVIOES" },
  {
    cnae: "6440900",
    descricao: "AERONAVES; ARRENDAMENTO MERCANTIL (LEASING FINANCEIRO) DE",
  },
  {
    cnae: "6440900",
    descricao:
      "MAQUINAS E EQUIPAMENTOS COMERCIAIS E INDUSTRIAIS; ARRENDAMENTO MERCANTIL (LEASING FINANCEIRO) DE",
  },
  {
    cnae: "6440900",
    descricao:
      "ANDAIMES E PLATAFORMAS DE TRABALHO; ARRENDAMENTO MERCANTIL (LEASING FINANCEIRO) DE",
  },
  {
    cnae: "6440900",
    descricao:
      "ARRENDAMENTO MERCANTIL (LEASING FINANCEIRO) DE MAQUINAS E EQUIPAMENTOS PARA EXTRACAO DE MINERIOS E PETROLEO, SEM OPERADOR",
  },
  { cnae: "64506", descricao: "SOCIEDADES DE CAPITALIZACAO" },
  {
    cnae: "6450600",
    descricao:
      "NEGOCIACAO DE CONTRATOS DE TITULOS DE CAPITALIZACAO; INTERMEDIACAO FINANCEIRA",
  },
  { cnae: "6450600", descricao: "CAPITALIZACAO; EMPRESA DE" },
  { cnae: "6450600", descricao: "CAPITALIZACAO; SOCIEDADE DE" },
  { cnae: "64611", descricao: "HOLDINGS DE INSTITUICOES FINANCEIRAS" },
  {
    cnae: "6461100",
    descricao: "HOLDING DE PARTICIPACAO ACIONARIA - FINANCEIRA",
  },
  { cnae: "6461100", descricao: "HOLDING DE INSTITUICOES FINANCEIRAS" },
  {
    cnae: "6461100",
    descricao: "ADMINISTRACAO DE INSTITUICOES FINANCEIRAS DO MESMO GRUPO",
  },
  {
    cnae: "6461100",
    descricao: "GESTAO DE PARTICIPACOES SOCIETARIAS - HOLDING FINANCEIRA",
  },
  { cnae: "6461100", descricao: "EMPRESA FINANCEIRA CONTROLADORA" },
  { cnae: "64620", descricao: "HOLDINGS DE INSTITUICOES NAOFINANCEIRAS" },
  {
    cnae: "6462000",
    descricao: "GESTAO DE PARTICIPACOES SOCIETARIAS - HOLDING NAO FINANCEIRA",
  },
  {
    cnae: "6462000",
    descricao: "HOLDING DE PARTICIPACAO ACIONARIA - NAO FINANCEIRA",
  },
  {
    cnae: "6462000",
    descricao: "EMPRESAS NAO FINANCEIRAS DO MESMO GRUPO; ADMINISTRACAO DE",
  },
  { cnae: "6462000", descricao: "HOLDING DE INSTITUICOES NAO FINANCEIRAS" },
  { cnae: "6462000", descricao: "EMPRESA NAO-FINANCEIRA CONTROLADORA" },
  {
    cnae: "64638",
    descricao: "OUTRAS SOCIEDADES DE PARTICIPACAO, EXCETO HOLDINGS",
  },
  {
    cnae: "6463800",
    descricao:
      "SOCIEDADES DE PARTICIPACAO QUE NAO EXERCAM CONTROLE E GESTAO DE OUTRAS EMPRESAS",
  },
  {
    cnae: "6463800",
    descricao: "APLICACAO EM INVESTIMENTOS PROPRIOS (ATIVOS FINANCEIROS)",
  },
  { cnae: "6463800", descricao: "EMPRESA COLIGADA; PARTICIPACAO EM" },
  { cnae: "64701", descricao: "FUNDOS DE INVESTIMENTO" },
  { cnae: "6470101", descricao: "FUNDOS DE APLICACOES FINANCEIRAS" },
  { cnae: "6470101", descricao: "FUNDOS DE INVESTIMENTO DE CURTO PRAZO" },
  {
    cnae: "6470101",
    descricao: "FUNDOS MUTUOS DE PRIVATIZACAO - FGTS - CARTEIRA LIVRE",
  },
  { cnae: "6470101", descricao: "FUNDOS DE INVESTIMENTO REFERENCIADOS" },
  { cnae: "6470101", descricao: "FUNDOS DE INVESTIMENTO CAMBIAIS" },
  { cnae: "6470101", descricao: "FUNDOS DE INVESTIMENTO EM ACOES" },
  { cnae: "6470101", descricao: "FUNDOS DE INVESTIMENTO DE DIVIDA EXTERNA" },
  {
    cnae: "6470101",
    descricao:
      "FUNDOS DE INVESTIMENTO EM COTAS DE FUNDOS DE INVESTIMENTO EM DIREITOS CREDITORIOS",
  },
  {
    cnae: "6470101",
    descricao: "FUNDOS DE INVESTIMENTO, EXCETO PREVIDENCIARIOS E IMOBILIARIOS",
  },
  {
    cnae: "6470101",
    descricao: "FUNDOS DE INVESTIMENTO EM DIREITOS CREDITORIOS",
  },
  { cnae: "6470101", descricao: "FUNDOS MUTUOS DE ACOES INCENTIVADAS" },
  { cnae: "6470101", descricao: "FUNDOS DE INVESTIMENTO MULTIMERCADO" },
  { cnae: "6470101", descricao: "FUNDOS DE CONVERSAO" },
  {
    cnae: "6470101",
    descricao:
      "FUNDOS DE INVESTIMENTO EM COTAS DE FUNDOS DE INVESTIMENTO EM PARTICIPACOES",
  },
  {
    cnae: "6470101",
    descricao: "FUNDOS DE PRIVATIZACAO - CAPITAL ESTRANGEIRO",
  },
  { cnae: "6470101", descricao: "FUNDOS DE INVESTIMENTO FINANCEIRO - FIF" },
  {
    cnae: "6470101",
    descricao: "FUNDOS DE INVESTIMENTO EM EMPRESAS EMERGENTES",
  },
  {
    cnae: "6470101",
    descricao: "FUNDOS DE FINANCIAMENTO DA INDUSTRIA CINEMATOGRAFICA NACIONAL",
  },
  {
    cnae: "6470101",
    descricao:
      "FUNDOS DE INVESTIMENTO EM EMPRESAS EMERGENTES - CAPITAL ESTRANGEIRO",
  },
  { cnae: "6470101", descricao: "FUNDOS MUTUOS DE PRIVATIZACAO - FGTS" },
  {
    cnae: "6470101",
    descricao:
      "FUNDOS DE INVESTIMENTO EM DIREITOS CREDITORIOS NO AMBITO DO PROGRAMA DE INCENTIVO A IMPLEMENTACAO DE PROJETOS DE INTERESSE SOCIAL",
  },
  { cnae: "6470101", descricao: "FUNDOS DE INVESTIMENTO - RENDA VARIAVEL" },
  { cnae: "6470101", descricao: "FUNDOS DE INVESTIMENTO - RENDA FIXA" },
  {
    cnae: "6470101",
    descricao:
      "FUNDOS DE INDICE, COM COTAS NEGOCIAVEIS EM BOLSA DE VALORES OU MERCADO DE BALCAO ORGANIZADO",
  },
  { cnae: "6470101", descricao: "FUNDOS DE INVESTIMENTO EM PARTICIPACOES" },
  { cnae: "6470101", descricao: "FUNDOS DE INVESTIMENTO CULTURAL E ARTISTICO" },
  { cnae: "6470102", descricao: "FUNDOS DE INVESTIMENTO PREVIDENCIARIOS" },
  { cnae: "6470102", descricao: "PROGRAMA GERADOR DE BENEFICIO LIVRE - PGBL" },
  {
    cnae: "6470102",
    descricao: "FUNDOS DE APOSENTADORIA PROGRAMADA INDIVIDUAL - FAPI",
  },
  { cnae: "6470102", descricao: "VIDA GERADOR DE BENEFICIO LIVRE - VGBL" },
  { cnae: "6470103", descricao: "FUNDOS DE INVESTIMENTO IMOBILIARIO" },
  { cnae: "6470103", descricao: "FUNDO DE ARRENDAMENTO RESIDENCIAL" },
  { cnae: "64913", descricao: "SOCIEDADES DE FOMENTO MERCANTIL  FACTORING" },
  { cnae: "6491300", descricao: "FACTORING, FOMENTO MERCANTIL" },
  { cnae: "6491300", descricao: "COMPRA DE DUPLICATAS A RECEBER COM DESCONTO" },
  {
    cnae: "6491300",
    descricao: "COMPRA DE CREDITOS GERADOS POR VENDAS MERCANTIS",
  },
  {
    cnae: "6491300",
    descricao: "COMPRA DE CREDITO CONJUGADA COM A GESTAO EMPRESARIAL",
  },
  { cnae: "6491300", descricao: "SOCIEDADE DE FOMENTO MERCANTIL" },
  { cnae: "6491300", descricao: "FACTORING" },
  { cnae: "6491300", descricao: "FACTORING; EMPRESA DE" },
  { cnae: "64921", descricao: "SECURITIZACAO DE CREDITOS" },
  {
    cnae: "6492100",
    descricao:
      "EMISSAO E TRANSACAO DE TITULOS COM LASTROS EM CREDITOS A RECEBER",
  },
  { cnae: "6492100", descricao: "SECURITIZACAO DE CREDITOS; EMPRESAS DE" },
  { cnae: "6492100", descricao: "AQUISICAO DE DIREITOS CREDITORIOS" },
  {
    cnae: "64930",
    descricao: "ADMINISTRACAO DE CONSORCIOS PARA AQUISICAO DE BENS E DIREITOS",
  },
  { cnae: "6493000", descricao: "CONSORCIO DE VEICULOS; ADMINISTRACAO DE" },
  { cnae: "6493000", descricao: "CONSORCIO DE AUTOMOVEIS; ADMINISTRACAO DE" },
  { cnae: "6493000", descricao: "CONSORCIO; ADMINISTRACAO DE" },
  {
    cnae: "64999",
    descricao:
      "OUTRAS ATIVIDADES DE SERVICOS FINANCEIROS NAO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "6499901", descricao: "CLUBES DE INVESTIMENTO" },
  { cnae: "6499901", descricao: "CLUBE DE INVESTIMENTO; EMPRESA DE" },
  { cnae: "6499902", descricao: "SOCIEDADE DE INVESTIMENTO" },
  { cnae: "6499903", descricao: "FUNDO GARANTIDOR DE CREDITO" },
  {
    cnae: "6499903",
    descricao: "FUNDO GARANTIDOR DE CREDITO DO SISTEMA BANCARIO",
  },
  {
    cnae: "6499904",
    descricao:
      "CAIXA DE FINANCIAMENTO IMOBILIARIO DOS MILITARES (EXERCITO, MARINHA E AERONAUTICA)",
  },
  { cnae: "6499904", descricao: "CAIXA DE FINANCIAMENTO DE CORPORACOES" },
  {
    cnae: "6499905",
    descricao:
      "CONCESSAO DE CREDITO PELAS ORGANIZACOES DA SOCIEDADE CIVIL DE INTERESSE PUBLICO - OSCIP",
  },
  { cnae: "6499999", descricao: "CASAS DE PENHOR" },
  {
    cnae: "6499999",
    descricao:
      "COMPRA DE DIREITOS CREDITORIOS TRIBUTARIOS PARA PAGAMENTO DE DEBITOS FEDERAIS, ESTADUAIS E MUNICIPAIS EXCETO FACTORING E SECURITIZACAO; SERVICOS DE",
  },
  { cnae: "6499999", descricao: "COMPRA E VENDA DE PRECATORIOS; SERVICOS DE" },
  { cnae: "65111", descricao: "SEGUROS DE VIDA" },
  { cnae: "6511101", descricao: "SOCIEDADE SEGURADORA DE SEGUROS DE VIDA" },
  { cnae: "6511101", descricao: "ADMINISTRADORA DE SEGUROS DE VIDA" },
  { cnae: "6511101", descricao: "PLANOS DE SEGURO DE VIDA" },
  {
    cnae: "6511101",
    descricao: "SEGURADORA; COMPANHIA - RAMO DE SEGURO DE VIDA",
  },
  { cnae: "6511101", descricao: "SEGUROS DE VIDA; COMPANHIA DE" },
  { cnae: "6511101", descricao: "SEGUROS DE VIDA; EMPRESA DE" },
  { cnae: "6511102", descricao: "VENDA DE PLANOS DE AUXILIO FUNERAL" },
  { cnae: "6511102", descricao: "PLANOS DE AUXILIO FUNERAL; EMPRESA DE" },
  { cnae: "6511102", descricao: "PLANOS DE AUXILIO FUNERAL; COMPANHIA DE" },
  { cnae: "65120", descricao: "SEGUROS NAOVIDA" },
  { cnae: "6512000", descricao: "ADMINISTRADORA DE SEGUROS NAO VIDA" },
  {
    cnae: "6512000",
    descricao: "EXTENSAO DE GARANTIA DE PRODUTOS; SERVICOS DE",
  },
  {
    cnae: "6512000",
    descricao: "SEGUROS CONTRA PERDAS DE CAPITAL; EMPRESA DE",
  },
  { cnae: "6512000", descricao: "SERVICOS DE SEGUROS DE CASCOS MARITIMOS" },
  { cnae: "6512000", descricao: "SEGUROS DE CONDOMINIOS" },
  { cnae: "6512000", descricao: "SERVICOS DE SEGUROS DE RISCOS DE PETROLEO" },
  { cnae: "6512000", descricao: "SEGUROS DE AERONAVES; EMPRESA DE" },
  { cnae: "6512000", descricao: "SEGUROS DE TRANSPORTES; EMPRESA DE" },
  { cnae: "6512000", descricao: "SERVICOS DE SEGUROS DE RISCOS DE ENGENHARIA" },
  {
    cnae: "6512000",
    descricao: "SEGUROS DE RESPONSABILIDADE CIVIL; EMPRESA DE",
  },
  { cnae: "6512000", descricao: "SERVICOS DE SEGUROS DE LUCROS CESSANTES" },
  { cnae: "6512000", descricao: "SEGUROS RESIDENCIAIS" },
  { cnae: "6512000", descricao: "SOCIEDADE SEGURADORA DE SEGUROS NAO VIDA" },
  { cnae: "6512000", descricao: "SEGUROS CONTRA ACIDENTES; EMPRESA DE" },
  {
    cnae: "6512000",
    descricao: "SEGUROS DE VEICULOS E EMBARCACOES; EMPRESA DE",
  },
  { cnae: "6512000", descricao: "SERVICOS DE SEGUROS DE GRANDES RISCOS" },
  { cnae: "6512000", descricao: "SEGUROS CONTRA INCENDIOS; EMPRESA DE" },
  {
    cnae: "6512000",
    descricao: "PROLONGAMENTO DE GARANTIA DE PRODUTOS; SERVICOS DE",
  },
  { cnae: "65201", descricao: "SEGUROSSAUDE" },
  { cnae: "6520100", descricao: "SOCIEDADE SEGURADORA DE SEGUROS SAUDE" },
  { cnae: "6520100", descricao: "SEGURADORA (SEGUROS SAUDE); COMPANHIA" },
  { cnae: "6520100", descricao: "SEGUROS SAUDE; EMPRESA DE" },
  { cnae: "65308", descricao: "RESSEGUROS" },
  { cnae: "6530800", descricao: "RESSEGUROS" },
  { cnae: "65413", descricao: "PREVIDENCIA COMPLEMENTAR FECHADA" },
  { cnae: "6541300", descricao: "PLANOS DE PREVIDENCIA DE ENTIDADE FECHADA" },
  { cnae: "6541300", descricao: "ENTIDADE DE PREVIDENCIA PRIVADA - FECHADA" },
  { cnae: "6541300", descricao: "PREVIDENCIA PRIVADA FECHADA; EMPRESA DE" },
  { cnae: "6541300", descricao: "FUNDOS DE PENSAO DE EMPRESAS PUBLICAS" },
  {
    cnae: "6541300",
    descricao: "PREVIDENCIA COMPLEMENTAR FECHADA; EMPRESA DE",
  },
  { cnae: "6541300", descricao: "FUNDOS DE PENSAO" },
  {
    cnae: "6541300",
    descricao: "ENTIDADE DE PREVIDENCIA COMPLEMENTAR - FECHADA",
  },
  { cnae: "6541300", descricao: "FUNDOS DE PENSAO DE EMPRESAS PRIVADAS" },
  { cnae: "65421", descricao: "PREVIDENCIA COMPLEMENTAR ABERTA" },
  { cnae: "6542100", descricao: "PREVIDENCIA PRIVADA ABERTA; EMPRESA DE" },
  { cnae: "6542100", descricao: "ENTIDADE DE PREVIDENCIA PRIVADA - ABERTA" },
  { cnae: "6542100", descricao: "PLANOS DE PREVIDENCIA DE ENTIDADE ABERTA" },
  {
    cnae: "6542100",
    descricao: "ENTIDADE DE PREVIDENCIA COMPLEMENTAR - ABERTA",
  },
  { cnae: "65502", descricao: "PLANOS DE SAUDE" },
  { cnae: "6550200", descricao: "MEDICINA DE GRUPO; EMPRESA DE" },
  { cnae: "6550200", descricao: "PLANOS DE SAUDE; EMPRESA DE" },
  { cnae: "6550200", descricao: "PLANOS DE ASSISTENCIA A SAUDE" },
  { cnae: "6550200", descricao: "PLANOS DE SAUDE; EMPRESA DE AUTOGESTAO EM" },
  { cnae: "6550200", descricao: "PLANOS DE SAUDE PARA ANIMAIS" },
  {
    cnae: "6550200",
    descricao: "PLANOS DE SAUDE; EMPRESA DE ADMINISTRACAO DE",
  },
  { cnae: "6550200", descricao: "PLANOS DE SAUDE; COOPERATIVA MEDICA" },
  { cnae: "6550200", descricao: "OPERADORAS DE PLANOS DE SAUDE" },
  {
    cnae: "66118",
    descricao: "ADMINISTRACAO DE BOLSAS E MERCADOS DE BALCAO ORGANIZADOS",
  },
  { cnae: "6611801", descricao: "BOLSA DE VALORES" },
  {
    cnae: "6611801",
    descricao: "ADMINISTRACAO DE MERCADO BURSATIL; SERVICO DE",
  },
  {
    cnae: "6611801",
    descricao: "MERCADO A VISTA DE NEGOCIACAO DE ATIVOS FINANCEIROS",
  },
  { cnae: "6611802", descricao: "BOLSA DE MERCADORIAS" },
  { cnae: "6611803", descricao: "MERCADO A TERMO" },
  { cnae: "6611803", descricao: "BOLSA DE MERCADORIAS E FUTUROS" },
  { cnae: "6611803", descricao: "MERCADO DE FUTUROS" },
  { cnae: "6611803", descricao: "MERCADO DE ESTOQUES" },
  { cnae: "6611803", descricao: "MERCADO DE OPCOES" },
  { cnae: "6611804", descricao: "ADMINISTRACAO DE MERCADO DE BALCAO" },
  {
    cnae: "6611804",
    descricao: "ADMINISTRACAO DE MERCADOS DE BALCAO ORGANIZADOS",
  },
  {
    cnae: "66126",
    descricao:
      "ATIVIDADES DE INTERMEDIARIOS EM TRANSACOES DE TITULOS, VALORES MOBILIARIOS E MERCADORIAS",
  },
  { cnae: "6612601", descricao: "CORRETORA DE VALORES" },
  {
    cnae: "6612601",
    descricao: "ADMINISTRACAO DE CARTEIRA DE ACOES DE TERCEIROS; SERVICOS DE",
  },
  { cnae: "6612601", descricao: "OPERACAO NA BOLSA DE VALORES; SERVICOS DE" },
  {
    cnae: "6612601",
    descricao: "SOCIEDADE CORRETORA DE TITULOS E VALORES MOBILIARIOS",
  },
  {
    cnae: "6612601",
    descricao:
      "NEGOCIACAO DE TITULOS E VALORES NA BOLSA DE VALORES; SERVICOS DE",
  },
  {
    cnae: "6612601",
    descricao: "NEGOCIACAO DE ACOES NA BOLSA DE VALORES; SERVICOS DE",
  },
  {
    cnae: "6612601",
    descricao: "ADMINISTRACAO DE CARTEIRA DE TITULOS DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "6612601",
    descricao: "OPERACAO DE TITULOS E VALORES MOBILIARIOS; SERVICOS DE",
  },
  {
    cnae: "6612601",
    descricao: "INTERMEDIARIO DE TITULOS E VALORES MOBILIARIOS - TVM",
  },
  {
    cnae: "6612602",
    descricao: "DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS",
  },
  {
    cnae: "6612602",
    descricao: "SOCIEDADE DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS",
  },
  { cnae: "6612603", descricao: "INTERMEDIARIO DE CAMBIO" },
  {
    cnae: "6612603",
    descricao: "INTERMEDIARIO NA OPERACAO DE CAMBIO; SERVICOS DE",
  },
  { cnae: "6612603", descricao: "SOCIEDADE CORRETORA DE CAMBIO" },
  { cnae: "6612603", descricao: "CORRETORA DE CAMBIO" },
  { cnae: "6612604", descricao: "CORRETORA DE CONTRATO DE MERCADORIAS" },
  { cnae: "6612604", descricao: "CORRETORA DE MERCADORIAS" },
  { cnae: "6612604", descricao: "INTERMEDIARIO DE CONTRATOS DE MERCADORIAS" },
  {
    cnae: "6612604",
    descricao: "COMISSAO SOBRE CONTRATO DE MERCADORIAS; SERVICOS DE",
  },
  {
    cnae: "6612605",
    descricao: "AGENTE PREPOSTO DE DISTRIBUIDORAS DE VALORES",
  },
  {
    cnae: "6612605",
    descricao: "CONSULTORIA EM INVESTIMENTOS FINANCEIROS; SERVICOS DE",
  },
  { cnae: "6612605", descricao: "AGENTE AUTONOMO DE INVESTIMENTO" },
  { cnae: "66134", descricao: "ADMINISTRACAO DE CARTOES DE CREDITO" },
  { cnae: "6613400", descricao: "ADMINISTRACAO DE CARTAO DE CREDITO" },
  {
    cnae: "66193",
    descricao:
      "ATIVIDADES AUXILIARES DOS SERVICOS FINANCEIROS NAO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "6619301", descricao: "COMPENSACAO BANCARIA; SERVICOS DE" },
  {
    cnae: "6619301",
    descricao: "SELIC - SISTEMA ESPECIAL DE LIQUIDACAO E CUSTODIA",
  },
  { cnae: "6619301", descricao: "CAMARA BRASILEIRA DE LIQUIDACAO E CUSTODIA" },
  {
    cnae: "6619301",
    descricao:
      "CETIP - CENTRAL DE CUSTODIA E DE LIQUIDACAO FINANCEIRA DE TITULOS",
  },
  { cnae: "6619301", descricao: "LIQUIDACAO E CUSTODIA; SERVICOS DE" },
  {
    cnae: "6619301",
    descricao: "CBLC - CAMARA BRASILEIRA DE LIQUIDACAO E CUSTODIA",
  },
  { cnae: "6619301", descricao: "CAIXA DE LIQUIDACAO DE MERCADOS BURSATEIS" },
  {
    cnae: "6619301",
    descricao: "COMPE - CAMARA DE COMPENSACAO DE CHEQUES E OUTROS PAPEIS",
  },
  {
    cnae: "6619302",
    descricao:
      "SERVICOS DE RECEBIMENTO DE CONTAS EM NOME DE INSTITUICAO FINANCEIRA",
  },
  { cnae: "6619302", descricao: "CORRESPONDENTES DE INSTITUICOES FINANCEIRAS" },
  { cnae: "6619302", descricao: "CORRESPONDENTES BANCARIOS" },
  {
    cnae: "6619303",
    descricao: "ESCRITORIO DE REPRESENTACAO DE BANCOS ESTRANGEIROS",
  },
  { cnae: "6619303", descricao: "REPRESENTACOES DE BANCOS ESTRANGEIROS" },
  { cnae: "6619304", descricao: "CAIXA ELETRONICO" },
  { cnae: "6619304", descricao: "BANCO 24 HORAS" },
  {
    cnae: "6619304",
    descricao:
      "CAIXAS ELETRONICAS - BANCO 24 HORAS - MOVIMENTACAO BANCARIA; SERVICOS DE",
  },
  { cnae: "6619305", descricao: "OPERADORAS DE CARTOES DE DEBITO" },
  { cnae: "6619305", descricao: "OPERADORAS DE CARTOES DE DEBITO AUTOMATICO" },
  { cnae: "6619305", descricao: "OPERADORAS DE CARTOES DE DEBITO EM CONTA" },
  { cnae: "6619399", descricao: "TITULOS DE CAPITALIZACAO; CORRETAGEM DE" },
  { cnae: "6619399", descricao: "CORRETOR HIPOTECARIO; SERVICOS DE" },
  { cnae: "6619399", descricao: "LOCACAO DE TERMINAL ELETRONICO PARA BANCO" },
  { cnae: "6619399", descricao: "CORRETAGEM DE CONSORCIOS; SERVICOS DE" },
  {
    cnae: "6619399",
    descricao: "INTERMEDIACAO NA OBTENCAO DE EMPRESTIMOS; SERVICOS DE",
  },
  {
    cnae: "6619399",
    descricao:
      "PROCESSAMENTO E LIQUIDACAO DE TRANSACOES COM CARTOES DE CREDITO E DEBITO; SERVICOS DE",
  },
  { cnae: "6619399", descricao: "CLUBE DE MERCADORIAS" },
  { cnae: "6619399", descricao: "CASAS DE CAMBIO" },
  {
    cnae: "6619399",
    descricao:
      "SOLUCOES ELETRONICAS COMERCIAIS NA TRANSMISSAO, PROCESSAMENTO E LIQUIDACAO FINANCEIRA COM CARTOES DE CREDITO E DEBITO; SERVICOS DE",
  },
  { cnae: "66215", descricao: "AVALIACAO DE RISCOS E PERDAS" },
  { cnae: "6621501", descricao: "PERITO E AVALIADOR DE SEGUROS" },
  {
    cnae: "6621501",
    descricao: "PERITO; ENCARREGADO NA AVALIACAO DE PREJUIZOS OU RISCOS",
  },
  {
    cnae: "6621501",
    descricao: "INSPECAO DE RISCO PARA EMPRESAS DE SEGUROS; SERVICOS DE",
  },
  { cnae: "6621501", descricao: "INSPECAO DE SINISTRO; SERVICOS DE" },
  { cnae: "6621501", descricao: "ESPECIALISTA EM AVALIACAO DE SEGUROS" },
  { cnae: "6621501", descricao: "PERICIA DE SINISTRO; SERVICOS DE" },
  {
    cnae: "6621501",
    descricao: "PERICIA E AUDITORIA DE SEGUROS DE SAUDE; SERVICOS DE",
  },
  { cnae: "6621501", descricao: "ANALISTA DE SEGUROS" },
  {
    cnae: "6621502",
    descricao: "AUDITORIA E CONSULTORIA ATUARIAL; SERVICOS DE",
  },
  { cnae: "6621502", descricao: "CONSULTORIA ATUARIAL" },
  { cnae: "6621502", descricao: "SERVICOS DE CALCULOS ATUARIAIS" },
  { cnae: "6621502", descricao: "SERVICOS DE CALCULOS DE VALORES ATUARIAIS" },
  { cnae: "6621502", descricao: "ASSESSORIA ATUARIAL; SERVICOS DE" },
  {
    cnae: "66223",
    descricao:
      "CORRETORES E AGENTES DE SEGUROS, DE PLANOS DE PREVIDENCIA COMPLEMENTAR E DE SAUDE",
  },
  { cnae: "6622300", descricao: "VENDA DE PLANO ODONTOLOGICO; SERVICOS DE" },
  { cnae: "6622300", descricao: "VENDA DE PLANOS DE SAUDE PARA ANIMAIS" },
  { cnae: "6622300", descricao: "CORRETORA DE PLANOS DE SAUDE" },
  { cnae: "6622300", descricao: "CORRETOR DE PLANO DE AUXILIO FUNERAL" },
  { cnae: "6622300", descricao: "SEGUROS; CORRETOR" },
  { cnae: "6622300", descricao: "PLANOS DE SAUDE; INTERMEDIARIO DE" },
  { cnae: "6622300", descricao: "PREVIDENCIA PRIVADA; CORRETOR" },
  { cnae: "6622300", descricao: "AGENTE E CORRETOR DE SEGUROS; SERVICOS DE" },
  {
    cnae: "6622300",
    descricao: "AGENTE E CORRETOR DE PLANOS DE SAUDE; SERVICOS DE",
  },
  { cnae: "6622300", descricao: "PREVIDENCIA COMPLEMENTAR; CORRETOR" },
  {
    cnae: "6622300",
    descricao: "PLANOS DE PREVIDENCIA PRIVADA; INTERMEDIARIO DE",
  },
  { cnae: "6622300", descricao: "VENDA DE SEGUROS; SERVICOS DE" },
  { cnae: "6622300", descricao: "VENDA DE PLANO DE SAUDE PARA ANIMAIS" },
  { cnae: "6622300", descricao: "CORRETAGEM DE SEGUROS; SERVICOS DE" },
  { cnae: "6622300", descricao: "VENDA DE PLANO DE PREVIDENCIA; SERVICOS DE" },
  {
    cnae: "6622300",
    descricao:
      "AGENTE E CORRETOR DE PLANOS DE PREVIDENCIA PRIVADA; SERVICOS DE",
  },
  {
    cnae: "6622300",
    descricao: "PLANOS DE PREVIDENCIA COMPLEMENTAR; INTERMEDIARIO DE",
  },
  { cnae: "6622300", descricao: "CORRETOR DE PLANOS DE SAUDE PARA ANIMAIS" },
  { cnae: "6622300", descricao: "SOCIEDADE CORRETORA DE SEGUROS" },
  {
    cnae: "6622300",
    descricao: "VENDA DE PLANO DE ASSISTENCIA MEDICA; SERVICOS DE",
  },
  { cnae: "6622300", descricao: "CORRETOR DE SEGURO DE VIDA" },
  { cnae: "6622300", descricao: "PLANOS DE SAUDE; CORRETOR" },
  {
    cnae: "6622300",
    descricao:
      "AGENTE E CORRETOR DE PLANOS DE PREVIDENCIA COMPLEMENTAR; SERVICOS DE",
  },
  { cnae: "6622300", descricao: "SEGUROS; INTERMEDIARIO DE" },
  { cnae: "6622300", descricao: "CORRETOR DE SEGURO-SAUDE" },
  { cnae: "6622300", descricao: "COMISSAO NA VENDA DE SEGUROS; SERVICOS DE" },
  { cnae: "6622300", descricao: "CORRETOR DE RESSEGUROS" },
  {
    cnae: "66291",
    descricao:
      "ATIVIDADES AUXILIARES DOS SEGUROS, DA PREVIDENCIA COMPLEMENTAR E DOS PLANOS DE SAUDE NAO ESPECIFICADAS ANTERIORMENTE",
  },
  {
    cnae: "6629100",
    descricao:
      "ASSISTENCIA A SEGURADOS EM QUESTAO DE SEGUROS EM GERAL; SERVICOS DE",
  },
  {
    cnae: "6629100",
    descricao:
      "INTERMEDIACAO ENTRE OPERADORAS DE PLANOS DE SAUDE E EMPRESAS CONVENIADAS (HOSPITAIS, CLINICAS, ETC.); SERVICOS DE",
  },
  { cnae: "6629100", descricao: "OBJETOS RECUPERADOS; ADMINISTRACAO DE" },
  {
    cnae: "66304",
    descricao: "ATIVIDADES DE ADMINISTRACAO DE FUNDOS POR CONTRATO OU COMISSAO",
  },
  {
    cnae: "6630400",
    descricao: "GESTAO PERSONALIZADA DO PATRIMONIO FINANCEIRO DE TERCEIROS",
  },
  {
    cnae: "6630400",
    descricao: "SERVICOS DE GESTAO DE PATRIMONIO PESSOAL DE TERCEIROS",
  },
  {
    cnae: "6630400",
    descricao:
      "GERENCIAMENTO DE FUNDOS COM FINS DIVERSOS - CULTURAIS, BENEFICENTES E DE OUTROS TIPOS",
  },
  {
    cnae: "6630400",
    descricao: "ADMINISTRACAO DE CARTEIRAS DE TITULOS E VALORES PARA TERCEIROS",
  },
  {
    cnae: "6630400",
    descricao: "EMPRESA DE ADMINISTRACAO DE RECURSOS FINANCEIROS DE TERCEIROS",
  },
  {
    cnae: "6630400",
    descricao: "GESTAO DE CARTEIRA DE FUNDOS DE INVESTIMENTO DE TERCEIROS",
  },
  {
    cnae: "6630400",
    descricao: "EMPRESA DE ADMINISTRACAO DE INVESTIMENTOS DE TERCEIROS",
  },
  {
    cnae: "6630400",
    descricao: "ADMINISTRACAO DE CARTEIRA DE ACOES POR CONTA DE TERCEIROS",
  },
  { cnae: "68102", descricao: "ATIVIDADES IMOBILIARIAS DE IMOVEIS PROPRIOS" },
  { cnae: "6810201", descricao: "APARTAMENTOS PROPRIOS; COMPRA E VENDA DE" },
  { cnae: "6810201", descricao: "CASAS PROPRIAS; COMPRA E VENDA DE" },
  { cnae: "6810201", descricao: "COMPRA E VENDA DE IMOVEIS PROPRIOS" },
  { cnae: "6810201", descricao: "IMOVEIS PROPRIOS; COMPRA E VENDA DE" },
  { cnae: "6810201", descricao: "TERRENOS PROPRIOS; COMPRA E VENDA DE" },
  { cnae: "6810202", descricao: "SELF STORAGE" },
  {
    cnae: "6810202",
    descricao: "VAGAS DE GARAGEM PROPRIAS; ALUGUEL MENSAL DE",
  },
  { cnae: "6810202", descricao: "IMOVEIS PROPRIOS; LOCACAO DE" },
  { cnae: "6810202", descricao: "APART-HOTEL RESIDENCIAL PROPRIO; ALUGUEL DE" },
  {
    cnae: "6810202",
    descricao: "ALUGUEL DE IMOVEL PROPRIO RESIDENCIAL EM APART-HOTEL",
  },
  { cnae: "6810202", descricao: "ADMINISTRACAO DE IMOVEIS PROPRIOS" },
  { cnae: "6810202", descricao: "VAGA DE GARAGEM PROPRIA; ALUGUEL DE" },
  {
    cnae: "6810202",
    descricao: "ALUGUEL DE IMOVEIS PROPRIOS; RESIDENCIAIS E NAO-RESIDENCIAIS",
  },
  { cnae: "6810202", descricao: "ALUGUEL MENSAL DE VAGAS DE GARAGEM PROPRIAS" },
  {
    cnae: "6810202",
    descricao: "ALUGUEL DE TERRAS PROPRIAS PARA EXPLORACAO AGROPECUARIA",
  },
  { cnae: "6810202", descricao: "PASTOS; ALUGUEL DE" },
  { cnae: "6810202", descricao: "SUBLOCACAO DE IMOVEIS DE TERCEIROS" },
  { cnae: "6810202", descricao: "ALUGUEL DE TERRAS PROPRIAS" },
  { cnae: "6810202", descricao: "ALUGUEL DE IMOVEL PROPRIO" },
  { cnae: "6810203", descricao: "LOTEAMENTO SEM BENFEITORIAS" },
  { cnae: "6810203", descricao: "LOTEAMENTO E VENDA DE IMOVEIS PROPRIOS" },
  {
    cnae: "6810203",
    descricao: "LOTEAMENTO DE TERRENO PROPRIO SEM BENFEITORIAS",
  },
  {
    cnae: "68218",
    descricao: "INTERMEDIACAO NA COMPRA, VENDA E ALUGUEL DE IMOVEIS",
  },
  {
    cnae: "6821801",
    descricao:
      "ELABORACAO DE PARECER DE AVALIACAO DE VALOR DE MERCADO DE IMOVEIS; SERVICOS DE",
  },
  { cnae: "6821801", descricao: "CORRETOR IMOBILIARIO; SERVICOS DE" },
  {
    cnae: "6821801",
    descricao: "AVALIACAO PATRIMONIAL DE IMOVEIS; SERVICOS DE",
  },
  { cnae: "6821801", descricao: "COMISSAO SOBRE IMOVEIS DE TERCEIROS" },
  { cnae: "6821801", descricao: "CORRETAGEM DE IMOVEIS; SERVICOS DE" },
  { cnae: "6821801", descricao: "CORRETOR DE IMOVEIS; SERVICOS DE" },
  { cnae: "6821801", descricao: "AVALIACAO DE IMOVEIS; SERVICOS DE" },
  { cnae: "6821801", descricao: "IMOVEIS DE TERCEIROS; COMPRA E VENDA DE" },
  {
    cnae: "70107",
    descricao: "SEDES DE EMPRESAS E UNIDADES ADMINISTRATIVAS LOCAIS",
  },
  {
    cnae: "68226",
    descricao: "GESTAO E ADMINISTRACAO DA PROPRIEDADE IMOBILIARIA",
  },
  { cnae: "6822600", descricao: "ATIVIDADES DE SINDICO PROFISSIONAL" },
  {
    cnae: "6822600",
    descricao: "GESTAO DA PROPRIEDADE IMOBILIARIA POR CONTA DE TERCEIROS",
  },
  { cnae: "6822600", descricao: "IMOVEIS; GERENCIA ADMINISTRATIVA" },
  { cnae: "6822600", descricao: "IMOVEIS; GERENCIA OPERACIONAL" },
  { cnae: "6822600", descricao: "CONDOMINIOS PREDIAIS; ADMINISTRACAO DE" },
  {
    cnae: "6822600",
    descricao:
      "IMOBILIARIA; GERENCIA OPERACIONAL E ADMINISTRATIVA DE IMOVEIS DE TERCEIROS",
  },
  { cnae: "6822600", descricao: "ADMINISTRACAO DE CONDOMINIOS PREDIAIS" },
  {
    cnae: "6822600",
    descricao:
      "GESTAO DE CONDOMINIOS, RESIDENCIAIS OU NAO, POR CONTA DE TERCEIROS",
  },
  {
    cnae: "6822600",
    descricao: "IMOVEIS ALUGADOS DE TERCEIROS; ADMINISTRACAO DE",
  },
  { cnae: "6822600", descricao: "ADMINISTRADORA DE IMOVEIS DE TERCEIROS" },
  { cnae: "6822600", descricao: "ADMINISTRADORAS DE IMOVEIS" },
  {
    cnae: "6822600",
    descricao:
      "ADMINISTRACAO DE CONDOMINIOS PREDIAIS, RESIDENCIAIS E COMERCIAIS, POR CONTA DE TERCEIROS",
  },
  { cnae: "6822600", descricao: "ADMINISTRACAO DE IMOVEIS DE TERCEIROS" },
  { cnae: "6822600", descricao: "SHOPPING CENTERS; ADMINISTRADORAS DE" },
  { cnae: "69117", descricao: "ATIVIDADES JURIDICAS, EXCETO CARTORIOS" },
  {
    cnae: "6911701",
    descricao: "ACONSELHAMENTO E REPRESENTACAO EM ACOES CRIMINAIS;",
  },
  { cnae: "6911701", descricao: "ASSISTENCIA JURIDICA EM DIREITOS AUTORAIS" },
  {
    cnae: "6911701",
    descricao: "ACONSELHAMENTO E REPRESENTACAO EM ACOES ADMINISTRATIVAS;",
  },
  {
    cnae: "6911701",
    descricao: "REGISTRO DE PATENTES; ASSISTENCIA JURIDICA EM",
  },
  {
    cnae: "6911701",
    descricao: "ACONSELHAMENTO E REPRESENTACAO EM ACOES CIVIS;",
  },
  {
    cnae: "6911701",
    descricao:
      "REPRESENTACAO JURIDICA EM ACOES CIVIS, CRIMINAIS, ADMINISTRATIVAS, TRABALHISTAS E COMERCIAIS",
  },
  {
    cnae: "6911701",
    descricao:
      "ESTATUTOS SOCIAIS,  ESCRITURAS DE CONSTITUICAO DE SOCIEDADES; PREPARO DE DOCUMENTOS JURIDICOS",
  },
  { cnae: "6911701", descricao: "ADVOCATICIOS; SERVICOS" },
  { cnae: "6911701", descricao: "ESCRITORIO JURIDICO; SERVICOS DE" },
  { cnae: "6911701", descricao: "ASSESSORIA E ASSISTENCIA JURIDICA" },
  {
    cnae: "6911701",
    descricao:
      "ACONSELHAMENTO E REPRESENTACAO EM ACOES TRABALHISTAS E COMERCIAIS;",
  },
  { cnae: "6911701", descricao: "ADVOGADOS; ESCRITORIO DE" },
  {
    cnae: "6911701",
    descricao:
      "CONTRATOS, ESCRITURAS, INVENTARIOS; PREPARO DE DOCUMENTOS JURIDICOS",
  },
  {
    cnae: "6911701",
    descricao: "DOCUMENTACAO JURIDICA; SERVICOS DE PREPARO DE",
  },
  { cnae: "6911701", descricao: "ADVOGADO; SERVICOS DE" },
  { cnae: "6911701", descricao: "CONSULTORIA EM DIREITO" },
  {
    cnae: "6911701",
    descricao: "ACONSELHAMENTO E REPRESENTACAO EM; SERVICOS DE",
  },
  { cnae: "6911701", descricao: "ESCRITORIO DE ADVOCACIA" },
  { cnae: "6911702", descricao: "PERICIA JUDICIAL; SERVICOS DE" },
  { cnae: "6911702", descricao: "PERITO JUDICIAL; SERVICOS DE" },
  {
    cnae: "6911702",
    descricao: "NUCLEOS DE CONCILIACAO PREVIA; GESTAO, ADMINISTRACAO DE",
  },
  {
    cnae: "6911702",
    descricao: "CAMARAS DE CONCILIACAO PREVIA; GESTAO E ADMINISTRACAO DE",
  },
  {
    cnae: "6911702",
    descricao: "MEDIACAO E PRATICA DE ARBITRAGEM; SERVICOS DE",
  },
  { cnae: "6911702", descricao: "JUSTICA; ATIVIDADE AUXILIAR DA" },
  { cnae: "6911702", descricao: "COMISSAO DE CONCILIACAO PREVIA" },
  { cnae: "6911703", descricao: "DESENHOS INDUSTRIAIS; REGISTRO DE" },
  { cnae: "6911703", descricao: "AGENTE DA PROPRIEDADE INDUSTRIAL" },
  {
    cnae: "6911703",
    descricao: "REGISTRO DE DOMINIOS DE ENDERECOS DE INTERNET; ATIVIDADE DE",
  },
  { cnae: "6911703", descricao: "PROGRAMAS DE COMPUTADOR; REGISTRO DE" },
  {
    cnae: "6911703",
    descricao: "REGISTRO DE PROPRIEDADE INTELECTUAL; SERVICOS DE",
  },
  { cnae: "6911703", descricao: "MARCAS E PATENTES; CONCESSAO DE" },
  {
    cnae: "6911703",
    descricao:
      "ASSISTENCIA JURIDICA LIGADA A PROPRIEDADE INDUSTRIAL E INTELECTUAL; SERVICOS DE",
  },
  { cnae: "6911703", descricao: "MARCAS E PATENTES; REGISTRO DE" },
  { cnae: "6911703", descricao: "INDICACOES GEOGRAFICAS; REGISTRO DE" },
  { cnae: "6911703", descricao: "TRANSFERENCIA DE TECNOLOGIA; CONTRATOS DE" },
  { cnae: "6911703", descricao: "PATENTES; REGISTRO DE" },
  { cnae: "6911703", descricao: "MARCAS; REGISTRO DE" },
  { cnae: "69125", descricao: "CARTORIOS" },
  { cnae: "6912500", descricao: "ATIVIDADES CARTORIAIS" },
  {
    cnae: "6912500",
    descricao: "REGISTRO DE IMOVEIS, DE DOCUMENTOS; CARTORIOS",
  },
  { cnae: "6912500", descricao: "CARTORIO; SERVICOS DE" },
  { cnae: "6912500", descricao: "CERTIDAO DE OBITO, DE NASCIMENTO; CARTORIOS" },
  {
    cnae: "69206",
    descricao:
      "ATIVIDADES DE CONTABILIDADE, CONSULTORIA E AUDITORIA CONTABIL E TRIBUTARIA",
  },
  { cnae: "6920601", descricao: "BALANCO ANUAL DE EMPRESAS; ELABORACAO DE" },
  {
    cnae: "6920601",
    descricao:
      "REPRESENTACAO DE CLIENTES ANTE A ADMINISTRACAO TRIBUTARIA; SERVICOS DE",
  },
  { cnae: "6920601", descricao: "CONTADOR; SERVICOS DE" },
  { cnae: "6920601", descricao: "REPRESENTACAO CONTABIL" },
  { cnae: "6920601", descricao: "GUARDA-LIVROS; SERVICOS DE" },
  {
    cnae: "6920601",
    descricao: "DECLARACAO DE IMPOSTO DE RENDA; PREPARO POR CONTADORES",
  },
  { cnae: "6920601", descricao: "SERVICOS CONTABEIS" },
  {
    cnae: "6920601",
    descricao: "LEGALIZACAO DE CONSTITUICAO OU BAIXA DE EMPRESA; SERVICOS DE",
  },
  { cnae: "6920601", descricao: "CONTABILISTA; SERVICOS DE" },
  { cnae: "6920601", descricao: "CONTABILIDADE; SERVICOS DE" },
  { cnae: "6920601", descricao: "ESCRITA, ESCRITURACAO FISCAL; SERVICOS DE" },
  { cnae: "6920601", descricao: "ESCRITURACAO CONTABIL; SERVICOS DE" },
  {
    cnae: "6920601",
    descricao: "REGISTRO CONTABIL DE TRANSACOES COMERCIAIS; SERVICOS DE",
  },
  { cnae: "6920601", descricao: "ESCRITORIO DE CONTABILIDADE; SERVICOS DE" },
  { cnae: "6920602", descricao: "AUDITORIA TRIBUTARIA; SERVICOS DE" },
  { cnae: "6920602", descricao: "ASSESSORIA CONTABIL" },
  { cnae: "6920602", descricao: "ASSESSORAMENTO TRIBUTARIO; SERVICOS DE" },
  { cnae: "6920602", descricao: "PERICIAS TECNICAS CONTABEIS; SERVICOS DE" },
  { cnae: "6920602", descricao: "PERICIAS TECNICAS TRIBUTARIAS; SERVICOS DE" },
  { cnae: "6920602", descricao: "ASSESSORIA TRIBUTARIA" },
  { cnae: "6920602", descricao: "AUDITOR CONTABIL; SERVICOS DE" },
  { cnae: "6920602", descricao: "ASSESSORAMENTO CONTABIL; SERVICOS DE" },
  { cnae: "6920602", descricao: "AUDITORIA CONTABIL; SERVICOS DE" },
  {
    cnae: "70204",
    descricao: "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL",
  },
  {
    cnae: "7020400",
    descricao: "ASSESSORIA E CONSULTORIA EM RECURSOS HUMANOS",
  },
  { cnae: "7020400", descricao: "ASSESSORIA EMPRESARIAL" },
  { cnae: "7020400", descricao: "ASSESSORIA A GESTAO HOSPITALAR" },
  { cnae: "7020400", descricao: "LOGISTICA DE LOCALIZACAO; CONSULTORIA EM" },
  {
    cnae: "7020400",
    descricao: "CONSULTORIA EM GESTAO DE EMPRESAS AGROPECUARIAS",
  },
  { cnae: "7020400", descricao: "LOBISTA; ATIVIDADE DE" },
  {
    cnae: "7020400",
    descricao: "ASSESSORIA EM GESTAO EMPRESARIAL; ATIVIDADE DE",
  },
  { cnae: "7020400", descricao: "RELACOES PUBLICAS; ATIVIDADE DE" },
  {
    cnae: "7020400",
    descricao: "ASSESSORIA, CONSULTORIA EM SISTEMA HIPOTECARIO",
  },
  { cnae: "7020400", descricao: "ASSESSORIA EM COMUNICACAO; SERVICOS DE" },
  { cnae: "7020400", descricao: "ASSESSORIA DE IMPRENSA; SERVICOS DE" },
  { cnae: "7020400", descricao: "CONSULTORIA FINANCEIRA A EMPRESAS" },
  { cnae: "7020400", descricao: "CONSULTORIA NA AREA ECONOMICA" },
  { cnae: "7020400", descricao: "CONSULTORIA EM RELACOES PUBLICAS" },
  {
    cnae: "7020400",
    descricao:
      "ASSESSORIA, ORIENTACAO E ASSISTENCIA PRESTADA AS EMPRESAS EM MATERIA DE PLANEJAMENTO, ORGANIZACAO, REENGENHARIA, CONTROLE E GESTAO",
  },
  { cnae: "7020400", descricao: "CONSULTORIA EM NEGOCIACAO TRABALHISTA" },
  { cnae: "7020400", descricao: "ASSESSORIA CREDITICIA; SERVICOS DE" },
  { cnae: "7020400", descricao: "REENGENHARIA; CONSULTORIA EM" },
  { cnae: "7020400", descricao: "CONTROLE ORCAMENTARIO; CONSULTORIA EM" },
  { cnae: "7020400", descricao: "CONSULTORIA NA ADMINISTRACAO DE EMPRESAS" },
  {
    cnae: "7020400",
    descricao:
      "GESTAO EMPRESARIAL; SERVICOS DE ORIENTACAO, ASSISTENCIA, ASSESSORIA",
  },
  {
    cnae: "7020400",
    descricao: "CONSULTORIA A EMPRESAS EM COMERCIO EXTERIOR; SERVICOS DE",
  },
  {
    cnae: "7020400",
    descricao: "ASSESSORIA AS EMPRESAS EM QUESTOES DE GESTAO",
  },
  {
    cnae: "7020400",
    descricao: "ASSESSORIA AS EMPRESAS EM QUESTOES FINANCEIRAS",
  },
  {
    cnae: "7020400",
    descricao: "INTERMEDIARIO DE CONTRATACAO DE OBRAS PUBLICAS; SERVICOS DE",
  },
  { cnae: "71111", descricao: "SERVICOS DE ARQUITETURA" },
  { cnae: "7111100", descricao: "PAISAGISMO; DESENVOLVIMENTO DE PROJETOS DE" },
  {
    cnae: "7111100",
    descricao: "PROJETOS ARQUITETONICOS E PAISAGISTICOS; SERVICOS DE",
  },
  { cnae: "7111100", descricao: "ESCRITORIO DE ARQUITETURA" },
  { cnae: "7111100", descricao: "URBANISMO; ESCRITORIO DE" },
  {
    cnae: "7111100",
    descricao: "ESCRITORIO DE PROJETISTA; SERVICOS DE ARQUITETURA",
  },
  { cnae: "7111100", descricao: "PROJETOS DE ARQUITETURA" },
  { cnae: "7111100", descricao: "GERENCIAMENTO DE PROJETO DE ARQUITETURA" },
  {
    cnae: "7111100",
    descricao: "PROJETOS PARA ORDENACAO URBANA; ELABORACAO DE",
  },
  { cnae: "7111100", descricao: "CONSULTORIA, ASSESSORIA EM ARQUITETURA" },
  { cnae: "7111100", descricao: "ARQUITETURA; CONSULTORIA EM" },
  {
    cnae: "7111100",
    descricao: "ARQUITETURA; SERVICOS TECNICOS, ESCRITORIO DE",
  },
  {
    cnae: "7111100",
    descricao: "PROJETOS DE ARQUITETURA; SUPERVISAO DA EXECUCAO DE",
  },
  { cnae: "7111100", descricao: "ARQUITETURA PAISAGISTICA; SERVICOS DE" },
  {
    cnae: "7111100",
    descricao:
      "PROJETOS ARQUITETONICOS DE JARDINS; ATIVIDADE DE DESENVOLVIMENTO DE",
  },
  {
    cnae: "7111100",
    descricao:
      "PROJETOS DE INSTALACOES ESPORTIVAS; ATIVIDADES DE DESENVOLVIMENTO DE",
  },
  { cnae: "71120", descricao: "SERVICOS DE ENGENHARIA" },
  {
    cnae: "7112000",
    descricao: "PROJETOS DE EDIFICIOS; SERVICOS DE ENGENHARIA",
  },
  {
    cnae: "7112000",
    descricao:
      "PROJETOS DE ENGENHARIA ELETRONICA, DE MINAS, QUIMICA, MECANICA, INDUSTRIAL, DE SISTEMAS E DE SEGURANCA, AGRARIA",
  },
  { cnae: "7112000", descricao: "ESCRITORIO DE PROJETOS DE ENGENHARIA" },
  {
    cnae: "7112000",
    descricao: "PROJETOS PARA INSTALACOES ELETRICAS; ELABORACAO DE",
  },
  { cnae: "7112000", descricao: "ENGENHARIA AUTOMOTIVA; SERVICOS DE" },
  { cnae: "7112000", descricao: "PROJETOS DE ENGENHARIA; ELABORACAO DE" },
  { cnae: "7112000", descricao: "ENGENHARIA NAVAL; SERVICOS DE" },
  { cnae: "7112000", descricao: "ENGENHARIA AMBIENTAL; SERVICOS DE" },
  {
    cnae: "7112000",
    descricao: "PROJETOS PARA CAPTACAO E DISTRIBUICAO DE AGUA; ELABORACAO DE",
  },
  { cnae: "7112000", descricao: "PROJETOS AERONAUTICOS; ELABORACAO DE" },
  {
    cnae: "7112000",
    descricao: "SUPERVISAO DO PROJETO DE CONSTRUCAO; SERVICOS DE",
  },
  { cnae: "7112000", descricao: "PROJETOS ESTRUTURAIS; ELABORACAO DE" },
  { cnae: "7112000", descricao: "ENGENHARIA DE PROJETOS; SERVICOS DE" },
  { cnae: "7112000", descricao: "PROJETOS HIDRAULICOS; ELABORACAO DE" },
  { cnae: "7112000", descricao: "PROJETOS DE OBRAS VIARIAS; ELABORACAO DE" },
  { cnae: "7112000", descricao: "ASSISTENCIA TECNICA NA AREA DE ENGENHARIA" },
  { cnae: "7112000", descricao: "ENGENHARIA PORTUARIA; SERVICOS DE" },
  {
    cnae: "7112000",
    descricao: "PROJETOS PARA REDES DE TELEFONIA; ELABORACAO DE",
  },
  { cnae: "7112000", descricao: "PROJETOS DE RODOFERROVIAS; ELABORACAO DE" },
  {
    cnae: "7112000",
    descricao: "GERENCIAMENTO DA ELABORACAO DE PROJETOS DE ENGENHARIA",
  },
  {
    cnae: "7112000",
    descricao:
      "MAQUINARIA E INSTALACOES INDUSTRIAIS; PROJETOS DE, CONCEPCAO DE",
  },
  { cnae: "7112000", descricao: "ENGENHARIA CONSULTIVA; SERVICOS DE" },
  { cnae: "7112000", descricao: "ASSESSORIA TECNICA EM CONSTRUCAO" },
  { cnae: "7112000", descricao: "CALCULISTA EM CONSTRUCAO; ESCRITORIO DE" },
  { cnae: "7112000", descricao: "CONSULTORIA EM ENGENHARIA DE TRAFEGO" },
  { cnae: "7112000", descricao: "BIOENGENHARIA" },
  { cnae: "7112000", descricao: "CALCULO ESTRUTURAL; SERVICOS DE" },
  {
    cnae: "7112000",
    descricao: "SUPERVISAO DE OBRAS POR ENGENHEIROS; SERVICOS DE",
  },
  { cnae: "7112000", descricao: "ENGENHARIA DE TRANSPORTE; CONSULTORIA EM" },
  {
    cnae: "7112000",
    descricao:
      "CONSULTORIA EM ENGENHARIA CIVIL, NAVAL, ELETRICA, ELETRONICA, HIDRAULICA; SERVICOS DE",
  },
  {
    cnae: "7112000",
    descricao: "PROJETOS PARA TELECOMUNICACOES; ELABORACAO DE",
  },
  {
    cnae: "7112000",
    descricao:
      "PROJETOS DE ACONDICIONAMENTO DE AR, REFRIGERACAO, SANEAMENTO, CONTROLE DE CONTAMINACAO E ENGENHARIA ACUSTICA",
  },
  {
    cnae: "7112000",
    descricao: "PROJETOS DE ENGENHARIA AMBIENTAL; ELABORACAO DE",
  },
  { cnae: "7112000", descricao: "INSPECAO TECNICA DE ENGENHARIA" },
  { cnae: "7112000", descricao: "PROJETOS NA CONSTRUCAO CIVIL; ELABORACAO DE" },
  { cnae: "7112000", descricao: "ENGENHARIA DE TRANSITO; SERVICOS DE" },
  { cnae: "7112000", descricao: "PROJETOS DE ENGENHARIA CIVIL; SERVICOS DE" },
  {
    cnae: "7112000",
    descricao: "ESCRITORIO DE PROJETISTA; SERVICOS DE ENGENHARIA",
  },
  { cnae: "7112000", descricao: "PLANEJAMENTO DE OBRAS; SERVICOS DE" },
  {
    cnae: "7112000",
    descricao: "PROJETOS DE ENGENHARIA DE TRAFEGO; SERVICOS DE",
  },
  {
    cnae: "7112000",
    descricao:
      "CONSULTORIA EM ENGENHARIA DE OBRAS EM ESTRADAS, OBRAS HIDRAULICAS E URBANAS",
  },
  { cnae: "7112000", descricao: "FISCALIZACAO DE OBRAS; SERVICOS DE" },
  {
    cnae: "7112000",
    descricao: "PROJETOS PARA INFRA-ESTRUTURA; ELABORACAO DE",
  },
  { cnae: "7112000", descricao: "ENGENHARIA; SERVICOS TECNICOS DE" },
  {
    cnae: "7112000",
    descricao: "AVALIACAO, PERICIA E INSPECAO EM ENGENHARIA; SERVICOS DE",
  },
  { cnae: "7112000", descricao: "PROJETOS DE INSTALACOES ESPORTIVAS" },
  {
    cnae: "71197",
    descricao: "ATIVIDADES TECNICAS RELACIONADAS A ARQUITETURA E ENGENHARIA",
  },
  { cnae: "7119701", descricao: "PROJETOS DE TOPOGRAFIA" },
  { cnae: "7119701", descricao: "ESTUDOS TOPOGRAFICOS; SERVICOS DE" },
  { cnae: "7119701", descricao: "TOPOGRAFIA; SERVICOS DE" },
  { cnae: "7119701", descricao: "AGRIMENSURA; SERVICOS DE" },
  { cnae: "7119701", descricao: "BATIMETRIA; SERVICOS DE" },
  { cnae: "7119701", descricao: "GEODESIA; SERVICOS DE" },
  { cnae: "7119701", descricao: "ESTUDOS E DEMARCACAO DE SOLOS; SERVICOS DE" },
  { cnae: "7119701", descricao: "CARTOGRAFICOS, SERVICOS" },
  { cnae: "7119701", descricao: "ESTUDOS GEODESICOS; SERVICOS DE" },
  { cnae: "7119701", descricao: "GEOPROCESSAMENTO; SERVICOS DE" },
  { cnae: "7119701", descricao: "LIMITES TOPOGRAFICOS; LEVANTAMENTOS DE" },
  { cnae: "7119701", descricao: "LEVANTAMENTOS BATIMETRICOS" },
  { cnae: "7119701", descricao: "PROJETOS TOPOGRAFICOS" },
  { cnae: "7119701", descricao: "LEVANTAMENTOS GEODESICOS; SERVICOS DE" },
  {
    cnae: "7119701",
    descricao: "INFORMACAO CARTOGRAFICA E ESPACIAL; SERVICOS DE",
  },
  { cnae: "7119701", descricao: "CARTOGRAFIA; SERVICOS DE" },
  { cnae: "7119701", descricao: "LEVANTAMENTOS SISMICOS; SERVICOS DE" },
  { cnae: "7119702", descricao: "SERVICOS TECNICOS EM GEOLOGIA" },
  {
    cnae: "7119702",
    descricao:
      "PROSPECCAO DE PETROLEO E GAS NATURAL; ELABORACAO DE PROJETOS DE",
  },
  { cnae: "7119702", descricao: "ESTUDOS SISMOGRAFICOS" },
  { cnae: "7119702", descricao: "PROSPECCAO SISMICA" },
  {
    cnae: "7119702",
    descricao: "LEVANTAMENTOS, ESTUDOS GEOFISICOS; SERVICOS DE",
  },
  { cnae: "7119702", descricao: "PROSPECCAO GEOLOGICA; SERVICOS DE" },
  {
    cnae: "7119702",
    descricao: "ANALISES DE SOLO PARA INVESTIGACAO GEOLOGICA; SERVICOS DE",
  },
  { cnae: "7119702", descricao: "ESTUDOS GEOTECNICOS" },
  { cnae: "7119702", descricao: "LEVANTAMENTOS GEOLOGICOS; SERVICOS DE" },
  {
    cnae: "7119702",
    descricao: "LEVANTAMENTOS, ESTUDOS HIDROGRAFICOS; SERVICOS DE",
  },
  { cnae: "7119702", descricao: "PERFILAGEM DE SOLO; SERVICOS DE" },
  { cnae: "7119702", descricao: "PROSPECCAO, PESQUISA MINERAL; SERVICOS DE" },
  {
    cnae: "7119703",
    descricao:
      "DESENHO TECNICO ESPECIALIZADO PARA ARQUITETURA E ENGENHARIA; SERVICOS DE",
  },
  {
    cnae: "7119703",
    descricao: "CONFECCAO DE MAQUETES PARA ENGENHARIA E ARQUITETURA",
  },
  {
    cnae: "7119703",
    descricao: "DESENHOS DE ARQUITETURA E ENGENHARIA; SERVICOS DE",
  },
  {
    cnae: "7119703",
    descricao: "COMPUTACAO GRAFICA PARA A CRIACAO DE MAQUETES",
  },
  {
    cnae: "7119703",
    descricao: "COMPUTACAO GRAFICA PARA A CRIACAO DE PLANTAS HUMANIZADAS",
  },
  {
    cnae: "7119704",
    descricao: "PERICIA TECNICA RELACIONADA A SEGURANCA DO TRABALHO",
  },
  {
    cnae: "7119704",
    descricao: "PROJETOS DE SEGURANCA DO TRABALHO; ELABORACAO DE",
  },
  {
    cnae: "7119704",
    descricao:
      "SEGURANCA DO TRABALHO; SERVICOS DE PERICIA TECNICA RELACIONADA A",
  },
  { cnae: "7119799", descricao: "AEROLEVANTAMENTOS; SERVICOS DE" },
  { cnae: "7119799", descricao: "GESTAO DE AGUAS; PROJETOS DE" },
  { cnae: "7119799", descricao: "AEROFOTOGRAMETRIA; SERVICOS DE" },
  { cnae: "71201", descricao: "TESTES E ANALISES TECNICAS" },
  { cnae: "7120100", descricao: "CERTIFICADOS DE VISTORIA; SERVICOS DE" },
  {
    cnae: "7120100",
    descricao:
      "CERTIFICADOS DE HOMOLOGACAO DE BARCOS, AVIOES, VEICULOS MOTORIZADOS; SERVICOS FORNECIMENTO DE",
  },
  { cnae: "7120100", descricao: "ENSAIOS TECNOLOGICOS; SERVICOS DE" },
  { cnae: "7120100", descricao: "ANALISE DE QUALIDADE; SERVICOS DE" },
  { cnae: "7120100", descricao: "LABORATORIO DE ENSAIOS" },
  { cnae: "7120100", descricao: "LABORATORIO DE ANALISE MINERAL" },
  {
    cnae: "7120100",
    descricao:
      "ENSAIOS E ANALISES QUIMICOS E BIOLOGICOS PARA FINS DE CUMPRIMENTO DE NORMAS LEGAIS",
  },
  { cnae: "7120100", descricao: "EXAMES, ENSAIOS BROMATOLOGICOS; SERVICOS DE" },
  { cnae: "7120100", descricao: "ANALISES DE PROCESSOS MINERAIS; SERVICOS DE" },
  { cnae: "7120100", descricao: "TESTES AUTOMOTIVOS; SERVICOS DE" },
  { cnae: "7120100", descricao: "CONTROLE TECNOLOGICO DE MATERIAIS" },
  {
    cnae: "7120100",
    descricao: "CERTIFICACAO DE SEGURANCA VEICULAR; SERVICO DE",
  },
  { cnae: "7120100", descricao: "ANALISES DE SEMENTES; SERVICOS DE" },
  { cnae: "7120100", descricao: "ANALISES BROMATOLOGICAS; SERVICOS DE" },
  {
    cnae: "7120100",
    descricao: "TESTES FISICOS, QUIMICOS DE MATERIAIS E PRODUTOS; SERVICOS DE",
  },
  { cnae: "7120100", descricao: "CALIBRACOES DE INSTRUMENTOS; SERVICOS DE" },
  {
    cnae: "7120100",
    descricao:
      "TESTES DAS CARACTERISTICAS FISICAS, DESEMPENHO, PROVAS DE RESISTENCIA,  DURABILIDADE E RADIOATIVIDADE DE MATERIAIS E DE PRODUTOS; SERVICOS DE",
  },
  { cnae: "7120100", descricao: "LABORATORIO DE ANALISE DE ALIMENTOS" },
  {
    cnae: "7120100",
    descricao:
      "TESTES E ENSAIOS DE SOLOS PARA CARACTERIZACAO DE SUAS PROPRIEDADES FISICO-QUIMICAS, TAIS COMO: CONDUTIVIDADE ELETRICA, MEDICAO DE TEORES DE SAIS E MINERAIS, GRANULOMETRIA, DENSIDADE, ETC.; SERVICOS DE",
  },
  { cnae: "7120100", descricao: "AFERICAO DE INSTRUMENTOS DE MEDIDAS" },
  {
    cnae: "7120100",
    descricao:
      "DETECCAO DE VAZAMENTOS EM TUBULACOES DE GAS, AGUA, COMBUSTIVEIS; SERVICOS DE",
  },
  { cnae: "7120100", descricao: "MONITORAMENTO AMBIENTAL; SERVICOS DE" },
  { cnae: "7120100", descricao: "ANALISES DE OLEOS MINERAIS; SERVICOS DE" },
  { cnae: "7120100", descricao: "ALIMENTOS; CONTROLE DE QUALIDADE DE" },
  { cnae: "7120100", descricao: "ANALISES DE CONCRETO; SERVICOS DE" },
  {
    cnae: "7120100",
    descricao: "TESTES NA AREA DE HIGIENE ALIMENTAR; SERVICOS DE",
  },
  {
    cnae: "7120100",
    descricao: "CERTIFICADOS DE HOMOLOGACAO; SERVICOS DE CONCESSAO DE",
  },
  { cnae: "7120100", descricao: "ANALISES CROMATOGRAFICAS; SERVICOS DE" },
  { cnae: "7120100", descricao: "MEDICAO DA POLUICAO; SERVICOS DE" },
  {
    cnae: "7120100",
    descricao: "ANALISE E AMOSTRAGEM DE MINERIO; SERVICOS DE",
  },
  { cnae: "7120100", descricao: "GAS NATURAL; INSPECAO DE SEGURANCA VEICULAR" },
  {
    cnae: "7120100",
    descricao: "CONTROLE DE QUALIDADE DE PRODUTOS ALIMENTICIOS; SERVICOS DE",
  },
  { cnae: "7120100", descricao: "AFERICAO DE BALANCAS; SERVICOS DE" },
  {
    cnae: "7120100",
    descricao:
      "ENSAIOS E ANALISES FISICAS PARA FINS DE CUMPRIMENTO DE NORMAS LEGAIS",
  },
  {
    cnae: "7120100",
    descricao: "MEDICAO DA PUREZA DA AGUA OU AR; SERVICOS DE",
  },
  { cnae: "7120100", descricao: "ANALISES BIOTECNOLOGICAS; SERVICOS DE" },
  { cnae: "7120100", descricao: "ANALISES QUIMICO-BIOLOGICAS; SERVICOS DE" },
  { cnae: "7120100", descricao: "ANALISES, TESTES BIOLOGICOS; SERVICOS DE" },
  {
    cnae: "7120100",
    descricao:
      "CONTROLE DE NORMAS TECNICAS LEGAIS NAS CONSTRUCOES; SERVICOS DE",
  },
  {
    cnae: "7120100",
    descricao: "ANALISES FISICO-QUIMICA DA AGUA; SERVICOS DE",
  },
  { cnae: "7120100", descricao: "ANALISES QUIMICAS; SERVICOS DE" },
  { cnae: "7120100", descricao: "ANALISES DE VIBRACAO; SERVICOS DE" },
  { cnae: "7120100", descricao: "LABORATORIOS POLICIAIS; OPERACAO DE" },
  { cnae: "7120100", descricao: "MEDICAO DE RADIOATIVIDADE; SERVICOS DE" },
  { cnae: "7120100", descricao: "ANALISE BACTERIOLOGICA DA AGUA; SERVICOS DE" },
  {
    cnae: "7120100",
    descricao: "ANALISE DE RESISTENCIA DE INSTALACOES E MATERIAIS; SERVICOS DE",
  },
  { cnae: "7120100", descricao: "ANALISES MICROBIOLOGICAS; SERVICOS DE" },
  { cnae: "7120100", descricao: "GAMAGRAFIAS; SERVICOS DE" },
  {
    cnae: "7120100",
    descricao:
      "ENSAIO E INSPECOES DE MATERIAIS E DE PRODUTOS PARA FINS DE CUMPRIMENTO DE NORMAS LEGAIS",
  },
  {
    cnae: "7120100",
    descricao:
      "INSPECAO DE EQUIPAMENTO DE GAS NATURAL PARA SEGURANCA VEICULAR; SERVICOS DE",
  },
  {
    cnae: "7120100",
    descricao: "TESTES SOBRE A COMPOSICAO E A PUREZA DE MINERAIS; SERVICOS DE",
  },
  {
    cnae: "7120100",
    descricao: "ANALISES TECNOLOGICAS DE CONCRETO; SERVICOS DE",
  },
  {
    cnae: "7120100",
    descricao:
      "CERTIFICADOS DE HOMOLOGACAO DE PROJETOS NUCLEARES; SERVICOS DE FORNECIMENTO DE",
  },
  { cnae: "7120100", descricao: "ENSAIOS METROLOGICOS; SERVICOS DE" },
  {
    cnae: "7120100",
    descricao: "RADIOGRAFIA E ULTRA-SOM INDUSTRIAL; SERVICOS DE",
  },
  { cnae: "7120100", descricao: "METROLOGIA; SERVICOS DE" },
  { cnae: "7120100", descricao: "TESTES EM MATERIAIS; SERVICOS DE" },
  { cnae: "7120100", descricao: "AFERICAO METROLOGICA; SERVICOS DE" },
  {
    cnae: "72100",
    descricao:
      "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS FISICAS E NATURAIS",
  },
  { cnae: "7210000", descricao: "FAZENDA EXPERIMENTAL; PESQUISA" },
  { cnae: "7210000", descricao: "MEDICINA; PESQUISA E DESENVOLVIMENTO EM" },
  { cnae: "7210000", descricao: "QUIMICA; PESQUISA E DESENVOLVIMENTO EM" },
  { cnae: "7210000", descricao: "BIOQUIMICA; PESQUISA E DESENVOLVIMENTO EM" },
  { cnae: "7210000", descricao: "PESQUISA MEDICA NAO COMERCIAL" },
  {
    cnae: "7210000",
    descricao: "PESQUISA E DESENVOLVIMENTO DAS CIENCIAS FISICAS E NATURAIS",
  },
  { cnae: "7210000", descricao: "FARMACIA; PESQUISA E DESENVOLVIMENTO EM" },
  {
    cnae: "7210000",
    descricao: "PESQUISA MATEMATICA, FISICA, ASTRONOMIA; DESENVOLVIMENTO DE",
  },
  { cnae: "7210000", descricao: "PESQUISA BIOGENETICA" },
  {
    cnae: "7210000",
    descricao: "LABORATORIO DE PESQUISA FISICA, COMERCIAL E NAO COMERCIAL",
  },
  {
    cnae: "7210000",
    descricao:
      "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS FISICAS E NATURAIS; ATIVIDADES DE",
  },
  {
    cnae: "7210000",
    descricao: "LABORATORIO DE PESQUISA QUIMICA, COMERCIAL E NAO COMERCIAL",
  },
  { cnae: "7210000", descricao: "LABORATORIO INDUSTRIAL; PESQUISA" },
  { cnae: "7210000", descricao: "AGRONOMIA; PESQUISA E DESENVOLVIMENTO EM" },
  { cnae: "7210000", descricao: "PESQUISA BIOLOGICA" },
  {
    cnae: "72207",
    descricao:
      "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS SOCIAIS E HUMANAS",
  },
  {
    cnae: "7220700",
    descricao: "PESQUISA E DESENVOLVIMENTO EM CIENCIAS SOCIAIS E HUMANAS",
  },
  {
    cnae: "7220700",
    descricao: "PESQUISA ECONOMICA, COMERCIAL E NAO COMERCIAL",
  },
  { cnae: "7220700", descricao: "ARTES; PESQUISA E DESENVOLVIMENTO EM" },
  { cnae: "7220700", descricao: "PESQUISA EDUCACIONAL" },
  { cnae: "7220700", descricao: "ECONOMIA; PESQUISA E DESENVOLVIMENTO EM" },
  { cnae: "7220700", descricao: "ARQUEOLOGIA; PESQUISA E DESENVOLVIMENTO EM" },
  { cnae: "7220700", descricao: "SOCIOLOGIA; PESQUISA E DESENVOLVIMENTO EM" },
  { cnae: "7220700", descricao: "DIREITO; PESQUISA E DESENVOLVIMENTO EM" },
  { cnae: "7220700", descricao: "LINGUISTICA; PESQUISA E DESENVOLVIMENTO EM" },
  { cnae: "73114", descricao: "AGENCIAS DE PUBLICIDADE" },
  { cnae: "7311400", descricao: "EMPRESA DE PUBLICIDADE" },
  {
    cnae: "7311400",
    descricao: "CAMPANHAS DE PUBLICIDADE; SERVICOS DE CRIACAO DE",
  },
  { cnae: "7311400", descricao: "MERCHANDISING; SERVICOS DE" },
  {
    cnae: "7311400",
    descricao:
      "MATERIAL PUBLICITARIO, ANUNCIOS; COLOCACAO EM NOME DE CLIENTES EM JORNAIS, REVISTAS, RADIO, TELEVISAO E INTERNET",
  },
  { cnae: "7311400", descricao: "PROPAGANDA; AGENCIA DE, SERVICOS DE" },
  { cnae: "7311400", descricao: "PUBLICIDADE; AGENCIA DE, SERVICOS DE" },
  { cnae: "7311400", descricao: "EMPRESA DE PROPAGANDA" },
  {
    cnae: "7311400",
    descricao: "PUBLICIDADE EM DIARIO OFICIAL; SERVICOS DE COLOCACAO DE",
  },
  {
    cnae: "73122",
    descricao:
      "AGENCIAMENTO DE ESPACOS PARA PUBLICIDADE, EXCETO EM VEICULOS DE COMUNICACAO",
  },
  { cnae: "7312200", descricao: "FRONT LIGHT, OUTDOOR; SERVICOS DE" },
  {
    cnae: "7312200",
    descricao:
      "OUTDOORS; AGENCIA DE ALUGUEL E REVENDA DE ESPACO PARA COLOCACAO DE",
  },
  { cnae: "7312200", descricao: "ALUGUEL DE ESPACO FISICO PARA PUBLICIDADE" },
  {
    cnae: "7312200",
    descricao:
      "PAINEIS DE TRENS, ONIBUS, METROS E AERONAVES; ALUGUEL E REVENDA DE ESPACO PARA COLOCACAO DE PUBLICIDADE EM",
  },
  {
    cnae: "7312200",
    descricao:
      "CARTAZES PUBLICITARIOS; AGENCIA DE ALUGUEL E REVENDA DE ESPACO PARA COLOCACAO DE",
  },
  {
    cnae: "7312200",
    descricao: "OUTDOORS; ALUGUEL E REVENDA DE ESPACO PARA COLOCACAO DE",
  },
  {
    cnae: "7312200",
    descricao: "LOCACAO DE PAINEIS ELETRONICOS PARA PUBLICIDADE; SERVICOS DE",
  },
  {
    cnae: "7312200",
    descricao: "AGENCIAMENTO DE ESPACOS FISICOS PARA PUBLICIDADE",
  },
  {
    cnae: "7312200",
    descricao:
      "PAINEIS PUBLICITARIOS; AGENCIA DE ALUGUEL E REVENDA DE ESPACO PARA COLOCACAO DE",
  },
  { cnae: "7312200", descricao: "EXPLORACAO DE PAINEL ELETRONICO" },
  { cnae: "7312200", descricao: "ESPACO FISICO PARA PUBLICIDADE; LOCACAO DE" },
  {
    cnae: "7312200",
    descricao: "PUBLICIDADE; GESTAO DE INFRA-ESTRUTURA SOB CONTRATO DE",
  },
  {
    cnae: "7312200",
    descricao: "EXIBICAO DE CARTAZES; SERVICOS DE ALUGUEL DE ESPACO PARA",
  },
  { cnae: "7420004", descricao: "FILMAGEM DE EVENTOS; SERVICOS DE" },
  {
    cnae: "7312200",
    descricao:
      "EMPENA DE PREDIOS, CARTAZES OU TRIEDROS EM TAXIS; ALUGUEL E REVENDA DE ESPACO PARA COLOCACAO DE PUBLICIDADE EM",
  },
  {
    cnae: "7312200",
    descricao:
      "EXIBICAO DE PROPAGANDA, PUBLICIDADE EM ESPACO PUBLICO; SERVICOS DE ALUGUEL DE ESPACO PARA",
  },
  { cnae: "7312200", descricao: "PROPAGANDA EM OUTDOOR; SERVICOS DE" },
  { cnae: "7312200", descricao: "ESPACO PUBLICITARIO; LOCACAO DE" },
  {
    cnae: "7312200",
    descricao:
      "BUSDOOR; ALUGUEL E REVENDA DE ESPACO PARA COLOCACAO DE PUBLICIDADE EM",
  },
  {
    cnae: "73190",
    descricao: "ATIVIDADES DE PUBLICIDADE NAO ESPECIFICADAS ANTERIORMENTE",
  },
  {
    cnae: "7319001",
    descricao:
      "MONTAGEM DE ESTANDES PARA FEIRAS E EXPOSICOES INTEGRADA A CRIACAO; ATIVIDADE DE",
  },
  {
    cnae: "7319001",
    descricao: "CRIACAO DE ESTANDES PARA FEIRAS E EXPOSICOES; ATIVIDADES DE",
  },
  {
    cnae: "7319001",
    descricao:
      "ESTANDES PARA FEIRAS E EXPOSICOES; CRIACAO E MONTAGEM QUANDO ASSOCIADA A CRIACAO",
  },
  { cnae: "7319002", descricao: "FULLFILMENT; ATIVIDADE DE" },
  { cnae: "7319002", descricao: "PROMOTORA DE VENDAS; SERVICOS DE" },
  {
    cnae: "7319002",
    descricao: "MATERIAL PUBLICITARIO; ATIVIDADE DE DISTRIBUICAO DE",
  },
  { cnae: "7319002", descricao: "PROPAGANDA EM VIA PUBLICA; SERVICOS DE" },
  { cnae: "7319002", descricao: "DISTRIBUICAO DE FOLHETOS; SERVICOS DE" },
  {
    cnae: "7319002",
    descricao: "DEMONSTRACAO DE PRODUTOS EM PONTOS DE VENDA; SERVICOS DE",
  },
  {
    cnae: "7319002",
    descricao: "PROMOCAO DE VENDA NO LOCAL DE VENDA; ATIVIDADE DE",
  },
  {
    cnae: "7319002",
    descricao: "DISTRIBUICAO DE MATERIAL PROMOCIONAL; SERVICOS DE",
  },
  { cnae: "7319002", descricao: "PANFLETAGEM" },
  { cnae: "7319002", descricao: "PROMOCAO NO PONTO DE VENDAS; SERVICOS DE" },
  {
    cnae: "7319002",
    descricao: "DISTRIBUICAO DE MATERIAL PUBLICITARIO; ATIVIDADE DE",
  },
  { cnae: "7319003", descricao: "MARKETING DIRETO; SERVICOS DE" },
  { cnae: "7319003", descricao: "MARKETING DE PROPAGANDA POLITICA" },
  { cnae: "7319003", descricao: "PUBLICIDADE POR MALA DIRETA; SERVICOS DE" },
  { cnae: "7319003", descricao: "MARKETING PROMOCIONAL; SERVICOS DE" },
  { cnae: "7319003", descricao: "PUBLICIDADE; ATIVIDADES DE REPRESENTANTES" },
  {
    cnae: "7319003",
    descricao: "TELEMARKETING SEM COMERCIALIZACAO DE MERCADORIAS; SERVICOS DE",
  },
  { cnae: "7319003", descricao: "PUBLICIDADE POR TELEFONE; ATIVIDADE DE" },
  { cnae: "7319004", descricao: "ASSESSORIA NA DIVULGACAO DE PRODUTOS" },
  { cnae: "7319004", descricao: "CONSULTORIA EM PUBLICIDADE E PROPAGANDA" },
  { cnae: "7319004", descricao: "ASSESSORIA EM MARKETING" },
  { cnae: "7319004", descricao: "CONSULTORIA EM MARKETING" },
  { cnae: "7319099", descricao: "CARRO DE SOM PARA PUBLICIDADE; SERVICOS DE" },
  { cnae: "7319099", descricao: "PROPAGANDA VOLANTE; SERVICOS DE" },
  {
    cnae: "7319099",
    descricao: "ALTO-FALANTES  PARA PUBLICIDADE; SERVICOS DE",
  },
  { cnae: "7319099", descricao: "PUBLICIDADE; SERVICOS DE SOM PARA" },
  {
    cnae: "7319099",
    descricao:
      "PUBLICIDADE E PROPAGANDA EM BALOES E BONECOS INFLAVEIS; VEICULACAO DE",
  },
  {
    cnae: "7319099",
    descricao:
      "PUBLICIDADE EM LOCAIS FECHADOS (VEICULOS DE TRANSPORTE, ELEVADORES, AEROPORTOS, ETC.) ATRAVES DE MONITORES DE TV",
  },
  {
    cnae: "7319099",
    descricao: "COMPUTACAO GRAFICA PARA PUBLICIDADE; ATIVIDADE DE",
  },
  { cnae: "7319099", descricao: "PUBLICIDADE AEREA; SERVICOS DE" },
  { cnae: "73203", descricao: "PESQUISAS DE MERCADO E DE OPINIAO PUBLICA" },
  { cnae: "7320300", descricao: "CHECKING DE PUBLICIDADE; SERVICOS DE" },
  {
    cnae: "7320300",
    descricao:
      "OPINIAO PUBLICA; SERVICOS DE CONSULTA, COLETA DE INFORMACOES DE",
  },
  { cnae: "7320300", descricao: "ESTUDO DE MERCADO" },
  {
    cnae: "7320300",
    descricao:
      "ANALISE ESTATISTICA DOS RESUTADOS DE PESQUISAS DE MERCADO, DE OPINIAO PUBLICA",
  },
  { cnae: "7320300", descricao: "PESQUISA MERCADOLOGICA" },
  { cnae: "7320300", descricao: "ANALISE DE MERCADO; SERVICOS DE" },
  {
    cnae: "7320300",
    descricao:
      "COLETA E ANALISE DE DADOS RESULTANTES DE PESQUISAS DE MERCADO, DE OPINIAO PUBLICA",
  },
  {
    cnae: "7320300",
    descricao: "COLETA DE DADOS E DE INFORMACOES; SERVICOS DE",
  },
  {
    cnae: "7320300",
    descricao: "PESQUISA E COLETA DE DADOS PARA PESQUISAS DE MERCADO E OPINIAO",
  },
  {
    cnae: "7320300",
    descricao: "MONITORACAO DE PROPAGANDA, DE PUBLICIDADE; SERVICOS DE",
  },
  {
    cnae: "7320300",
    descricao: "CONTROLE DE VEICULACAO PUBLICITARIA; SERVICOS DE",
  },
  { cnae: "7320300", descricao: "PESQUISA DE MERCADO E DE OPINIAO PUBLICA" },
  { cnae: "7320300", descricao: "PESQUISA POLITICA; SERVICOS DE" },
  { cnae: "7320300", descricao: "PESQUISA DE OPINIAO PUBLICA" },
  {
    cnae: "7320300",
    descricao:
      "CENTROS DE EMISSAO DE CHAMADAS TELEFONICAS PARA PESQUISAS DE MERCADO E DE OPINIAO PUBLICA",
  },
  {
    cnae: "7320300",
    descricao:
      "MONITORAMENTO DE VEICULOS DE PROPAGANDA (RADIO, TV, REVISTAS, ETC.) SERVICOS DE",
  },
  { cnae: "74102", descricao: "DESIGN E DECORACAO DE INTERIORES" },
  { cnae: "7410202", descricao: "DESIGN DE INTERIORES" },
  { cnae: "7410202", descricao: "DECORACAO DE  INTERIORES" },
  { cnae: "7410203", descricao: "DESIGN DE SAPATOS" },
  { cnae: "7410203", descricao: "DESIGN DE MODA" },
  { cnae: "7410203", descricao: "DESIGNER DE MODAS" },
  {
    cnae: "7410203",
    descricao: "CONFECCAO DE MAQUETES EXCETO PARA ENGENHARIA E ARQUITETURA",
  },
  { cnae: "7410203", descricao: "PRODUTOR DE MODA" },
  { cnae: "7410203", descricao: "DESENHOS DE JOIAS; SERVICOS DE" },
  { cnae: "7410203", descricao: "DESIGN DE OBJETOS DOMESTICOS" },
  { cnae: "7410203", descricao: "DESIGN DE OBJETOS PESSOAIS" },
  { cnae: "7410203", descricao: "DESENHO INDUSTRIAL; SERVICOS DE" },
  { cnae: "7410203", descricao: "DESENHO DE ROUPAS; SERVICOS DE" },
  { cnae: "7410203", descricao: "DESIGN DE JOIAS" },
  { cnae: "7410203", descricao: "ESTILISTA DE MODAS" },
  { cnae: "7410203", descricao: "DESIGN DE MOBILIARIO" },
  { cnae: "7410299", descricao: "DIAGRAMACAO; SERVICOS DE" },
  { cnae: "7410299", descricao: "DESIGN GRAFICO" },
  { cnae: "74200", descricao: "ATIVIDADES FOTOGRAFICAS E SIMILARES" },
  { cnae: "7420001", descricao: "FOTOGRAFOS INDEPENDENTES; PRODUCAO POR" },
  { cnae: "7420001", descricao: "FOTOGRAFO DE IMPRENSA; SERVICOS DE" },
  {
    cnae: "7420001",
    descricao:
      "COBERTURA FOTOGRAFICA PARA JORNAIS, REVISTAS E EVENTOS; SERVICOS DE",
  },
  { cnae: "7420001", descricao: "PRODUCAO FOTOGRAFICA PARA PUBLICIDADE" },
  {
    cnae: "7420001",
    descricao: "PRODUCAO FOTOGRAFICA PARA FESTAS E OUTROS EVENTOS",
  },
  { cnae: "7420001", descricao: "PRODUCAO FOTOGRAFICA PARA FINS PESSOAIS" },
  { cnae: "7420001", descricao: "ATELIER FOTOGRAFICO" },
  { cnae: "7420001", descricao: "ESTUDIO FOTOGRAFICO" },
  { cnae: "7420002", descricao: "FOTOGRAFIA AEREA; SERVICOS DE" },
  { cnae: "7420002", descricao: "FOTOGRAFIA SUBMARINA; SERVICOS DE" },
  {
    cnae: "7420002",
    descricao: "FOTOGRAFIA AEREA PARA PUBLICIDADE; SERVICOS DE",
  },
  {
    cnae: "7420002",
    descricao: "FOTOGRAFIA SUBMARINA PARA PUBLICIDADE; SERVICOS DE",
  },
  {
    cnae: "7420003",
    descricao: "LABORATORIO DE REVELACAO DE FILMES FOTOGRAFICOS",
  },
  { cnae: "7420003", descricao: "LABORATORIO FOTOGRAFICO" },
  { cnae: "7420003", descricao: "MONTAGEM DE SLIDES; SERVICOS DE" },
  {
    cnae: "7420003",
    descricao:
      "FILMES FOTOGRAFICOS; ATIVIDADES DE REVELACAO, IMPRESSAO, AMPLIACAO DE",
  },
  {
    cnae: "7420003",
    descricao: "FOTOGRAFIAS; COPIAS, RESTAURACAO DE, RETOQUE DE",
  },
  { cnae: "7420003", descricao: "REVELACAO DE FOTOS; SERVICOS DE" },
  { cnae: "7420003", descricao: "DIAPOSITIVOS; MONTAGEM DE" },
  { cnae: "7420004", descricao: "FILMAGEM DE FESTAS; SERVICOS DE" },
  { cnae: "7420004", descricao: "FESTAS E EVENTOS; PRODUCAO DE VIDEO PARA" },
  { cnae: "7420004", descricao: "FILMAGEM DE EVENTOS CULTURAIS; SERVICOS DE" },
  { cnae: "7420004", descricao: "GRAVACAO DE VIDEOS PARA FESTAS E EVENTOS" },
  { cnae: "7420005", descricao: "MICROFILMAGEM; SERVICOS DE" },
  { cnae: "7420005", descricao: "MICROFILMAGEM DE DOCUMENTOS; SERVICOS DE" },
  {
    cnae: "74901",
    descricao:
      "ATIVIDADES PROFISSIONAIS, CIENTIFICAS E TECNICAS NAO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "7490101", descricao: "TRADUCAO DE LIVROS EM GERAL" },
  { cnae: "7490101", descricao: "TRADUCAO SIMULTANEA; SERVICOS DE" },
  {
    cnae: "7490101",
    descricao: "TRADUCAO DE TEXTOS JURAMENTADOS; SERVICOS DE",
  },
  { cnae: "7490101", descricao: "TRADUCAO, INTERPRETACAO; SERVICOS DE" },
  { cnae: "7490101", descricao: "TRADUCAO DE MANUAIS EM GERAL" },
  { cnae: "7490101", descricao: "REVISAO GRAMATICAL DE TEXTOS; SERVICOS DE" },
  {
    cnae: "7490102",
    descricao: "RESGATES; SERVICOS DE ESCAFANDRIA E MERGULHO",
  },
  { cnae: "7490102", descricao: "ESCAFANDRIA E MERGULHO; SERVICOS DE" },
  { cnae: "7490103", descricao: "EXTENSAO AGROPECUARIA; SERVICOS DE" },
  { cnae: "7490103", descricao: "EXTENSAO RURAL; ATIVIDADES DE" },
  {
    cnae: "7490103",
    descricao: "PROJETOS AGRICOLAS, AGROPECUARIOS; CONSULTORIA, ASSESSORIA EM",
  },
  {
    cnae: "7490103",
    descricao: "ASSESSORIA, ORIENTACAO E ASSISTENCIA NA AGROPECUARIA",
  },
  {
    cnae: "7490103",
    descricao: "ASSESSORIA POR AGRONOMO A ESTABELECIMENTOS AGRICOLAS",
  },
  {
    cnae: "7490103",
    descricao:
      "ASSESSORIA, CONSULTORIA, ORIENTACAO E ASSISTENCIA NA AGRICULTURA",
  },
  {
    cnae: "7490103",
    descricao:
      "ASSESSORIA, ORIENTACAO E ASSISTENCIA A ESTABELECIMENTOS AGRICOLAS E PECUARIOS",
  },
  {
    cnae: "7490103",
    descricao: "ASSESSORIA, ORIENTACAO E ASSISTENCIA TECNICA NA AGRICULTURA",
  },
  { cnae: "7490103", descricao: "ASSISTENCIA TECNICA RURAL" },
  {
    cnae: "7490103",
    descricao: "ASSESSORIA E EXTENSAO EM ATIVIDADES AGRICOLAS E PECUARIAS",
  },
  {
    cnae: "7490103",
    descricao:
      "ORIENTACAO E ASSISTENCIA DE AGRONOMO A ESTABELECIMENTO AGRICOLA",
  },
  {
    cnae: "7490103",
    descricao: "ANALISES DE SOLO PARA FINS AGRICOLAS; SERVICOS DE",
  },
  {
    cnae: "7490104",
    descricao: "INTERMEDIACAO NA COMPRA E VENDA DE PATENTES; SERVICOS DE",
  },
  { cnae: "7490104", descricao: "LICITACOES; SERVICOS DE INTERMEDIACAO EM" },
  {
    cnae: "7490104",
    descricao:
      "ATIVIDADES DE INTERMEDIACAO E AGENCIAMENTO DE SERVICOS E NEGOCIOS EM GERAL, SEM ESPECIALIZACAO DEFINIDA",
  },
  {
    cnae: "7490104",
    descricao: "COMPRA COLETIVA; INTERMEDIACAO DE SERVICOS EM SITES DE",
  },
  { cnae: "7490105", descricao: "AGENTE DE ATLETAS DE FUTEBOL" },
  { cnae: "7490105", descricao: "EMPRESARIO ARTISTICO; SERVICOS DE" },
  {
    cnae: "7490105",
    descricao: "CESSAO DE USO DE IMAGEM DE ARTISTAS, ESPORTISTAS; ATIVIDADE DE",
  },
  { cnae: "7490105", descricao: "AGENTE DE OBRAS LITERARIAS; SERVICOS DE" },
  {
    cnae: "7490105",
    descricao: "AGENCIAMENTO DE MODELOS E MANEQUINS PARA DESFILES",
  },
  {
    cnae: "7490105",
    descricao: "AGENCIAMENTO DE ATLETAS DE FUTEBOL; SERVICOS DE",
  },
  { cnae: "7490105", descricao: "AGENTE LITERARIO; SERVICOS DE" },
  { cnae: "7490105", descricao: "AGENTE DE ATORES; SERVICOS DE" },
  { cnae: "7490105", descricao: "AGENCIA DE MODELOS" },
  { cnae: "7490105", descricao: "AGENTE DE OBRAS DE ARTE; SERVICOS DE" },
  { cnae: "7490199", descricao: "METEOROLOGIA; SERVICOS DE" },
  { cnae: "7490199", descricao: "CONSULTORIA EM ESTATISTICA; SERVICOS DE" },
  {
    cnae: "7490199",
    descricao: "RATING OU AVALIACAO DE RISCOS PARA CLASSIFICACAO DE EMPRESAS",
  },
  { cnae: "7490199", descricao: "PREVISAO METEOROLOGICA; SERVICOS DE" },
  {
    cnae: "7490199",
    descricao: "ASSESSORIA E CONSULTORIA NA PRODUCAO DE PROGRAMAS DE TELEVISAO",
  },
  {
    cnae: "7490199",
    descricao: "ASSESSORIA E CONSULTORIA EM SAUDE E MEDICINA DO TRABALHO",
  },
  { cnae: "7490199", descricao: "ASSESSORIA E CONSULTORIA EM CULINARIA" },
  {
    cnae: "7490199",
    descricao: "ASSESSORIA E CONSULTORIA EM PROJETOS CULTURAIS",
  },
  {
    cnae: "7490199",
    descricao: "ASSESSORIA E CONSULTORIA EM MODA E IMAGEM PESSOAL",
  },
  {
    cnae: "7490199",
    descricao: "SOMMELIER, DEGUSTACAO DE VINHOS; SERVICOS DE",
  },
  { cnae: "7490199", descricao: "CONSULTORIA EM ESPORTES" },
  { cnae: "7490199", descricao: "CONSULTORIA MEDICA EM MEDICINA DO TRABALHO" },
  { cnae: "7490199", descricao: "AVALIACAO DE JOIAS; SERVICOS DE" },
  {
    cnae: "7490199",
    descricao:
      "SERVICOS DE PROMOCAO EM SAUDE JUNTO A AREA DE RECURSOS HUMANOS DE EMPRESAS",
  },
  {
    cnae: "7490199",
    descricao: "CONSULTORIA EM SISTEMAS DE SEGURANCA; SERVICOS DE",
  },
  { cnae: "7490199", descricao: "ASSESSORIA EM ESPORTE" },
  {
    cnae: "7490199",
    descricao: "ORGANIZACAO DE CONCURSOS PUBLICOS; SERVICOS DE",
  },
  { cnae: "7490199", descricao: "AVALIACAO DE ANTIGUIDADES; SERVICOS DE" },
  { cnae: "7490199", descricao: "AVALIACAO NAO-IMOBILIARIA; SERVICOS DE" },
  { cnae: "7490199", descricao: "LOCUCAO; SERVICOS DE" },
  { cnae: "7490199", descricao: "CONSULTORIA NA AREA DE ESTATISTICA" },
  {
    cnae: "7490199",
    descricao: "CONSULTORIA EM QUESTOES DE SUSTENTABILIDADE DO MEIO AMBIENTE",
  },
  {
    cnae: "7490199",
    descricao:
      "CONSULTORIA, ASSESSORIA EM PROJETOS DE MEIO AMBIENTE; SERVICOS DE",
  },
  { cnae: "75001", descricao: "ATIVIDADES VETERINARIAS" },
  { cnae: "7500100", descricao: "IMUNIZACAO VETERINARIA; SERVICOS DE" },
  { cnae: "7500100", descricao: "VACINACAO EM ANIMAIS; SERVICOS DE" },
  {
    cnae: "7500100",
    descricao:
      "ASSISTENCIA VETERINARIA EM ESTABELECIMENTOS AGROPECUARIOS; SERVICOS DE",
  },
  { cnae: "7500100", descricao: "TESTES EM ESPECIES ANIMAIS; REALIZACAO DE" },
  {
    cnae: "7500100",
    descricao: "TRANSPORTE DE ANIMAIS EM AMBULANCIAS; SERVICOS DE",
  },
  { cnae: "7500100", descricao: "HOSPITAL VETERINARIO" },
  { cnae: "7500100", descricao: "CONSULTORIO VETERINARIO" },
  { cnae: "7500100", descricao: "AMBULANCIA PARA ANIMAIS" },
  { cnae: "7500100", descricao: "LABORATORIO DE ANALISE VETERINARIA" },
  { cnae: "7500100", descricao: "SERVICOS VETERINARIOS" },
  { cnae: "7500100", descricao: "CLINICA VETERINARIA" },
  { cnae: "7500100", descricao: "MEDICO VETERINARIO; SERVICOS DE" },
  {
    cnae: "7500100",
    descricao: "UNIDADE MOVEL DE ATENDIMENTO MEDICO VETERINARIO",
  },
  { cnae: "7500100", descricao: "VETERINARIA; SERVICOS DE" },
  { cnae: "7500100", descricao: "ESTERILIZACAO DE ANIMAIS; SERVICOS DE" },
  { cnae: "77110", descricao: "LOCACAO DE AUTOMOVEIS SEM CONDUTOR" },
  {
    cnae: "7711000",
    descricao:
      "AUTOMOVEIS SEM MOTORISTA; LOCACAO DE, ALUGUEL DE, LEASING OPERACIONAL DE",
  },
  { cnae: "7711000", descricao: "LOCADORA DE CARROS" },
  { cnae: "7711000", descricao: "BUGRES; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7711000",
    descricao: "CAMINHONETES DE PASSEIO SEM MOTORISTA; LOCACAO DE, ALUGUEL DE",
  },
  {
    cnae: "7711000",
    descricao:
      "AUTOMOVEIS SEM CONDUTOR; LOCACAO DE, ALUGUEL DE, LEASING OPERACIONAL DE",
  },
  {
    cnae: "7711000",
    descricao: "CARROS DE PASSEIO SEM MOTORISTA; LOCACAO DE, ALUGUEL DE",
  },
  {
    cnae: "7711000",
    descricao: "AUTOMOVEIS; ARRENDAMENTO SEM OPCAO DE COMPRA DE",
  },
  { cnae: "7711000", descricao: "LOCADORA DE AUTOMOVEIS" },
  {
    cnae: "7711000",
    descricao: "AUTOS DE PASSEIO SEM MOTORISTA; LOCACAO DE, ALUGUEL DE",
  },
  {
    cnae: "77195",
    descricao:
      "LOCACAO DE MEIOS DE TRANSPORTE, EXCETO AUTOMOVEIS, SEM CONDUTOR",
  },
  {
    cnae: "7719501",
    descricao: "EMBARCACOES COMERCIAIS SEM TRIPULACAO; LOCACAO DE, ALUGUEL DE",
  },
  {
    cnae: "7719501",
    descricao: "EMBARCACOES COMERCIAIS; ARRENDAMENTO SEM OPCAO DE COMPRA DE",
  },
  { cnae: "8299799", descricao: "DOTS; SERVICOS DE" },
  {
    cnae: "7719501",
    descricao: "LEASING OPERACIONAL DE LANCHAS SEM TRIPULACAO",
  },
  {
    cnae: "7719501",
    descricao: "BARCOS COMERCIAIS SEM TRIPULACAO; LOCACAO DE, ALUGUEL DE",
  },
  {
    cnae: "7719501",
    descricao: "LEASING OPERACIONAL DE BARCOS SEM TRIPULACAO",
  },
  { cnae: "7719501", descricao: "CASCO NU; FRETAMENTO DE" },
  {
    cnae: "7719501",
    descricao: "LANCHAS COMERCIAIS SEM TRIPULACAO; LOCACAO DE, ALUGUEL DE",
  },
  {
    cnae: "7719501",
    descricao: "EMBARCACOES SEM TRIPULACAO; LEASING OPERACIONAL DE",
  },
  {
    cnae: "7719502",
    descricao: "AVIOES SEM TRIPULACAO; LOCACAO DE, ALUGUEL DE",
  },
  {
    cnae: "7719502",
    descricao: "AERONAVES; ARRENDAMENTO SEM OPCAO DE COMPRA DE",
  },
  {
    cnae: "7719502",
    descricao: "AERONAVES SEM TRIPULACAO; LOCACAO DE, ALUGUEL DE",
  },
  { cnae: "7719502", descricao: "AERONAVES; LEASING OPERACIONAL DE" },
  {
    cnae: "7719502",
    descricao: "HELICOPTEROS SEM TRIPULACAO; LOCACAO DE, ALUGUEL DE",
  },
  {
    cnae: "7719599",
    descricao: "CARRETOS; LOCACAO DE, ALUGUEL DE TRANPORTE PARA",
  },
  {
    cnae: "7719599",
    descricao: "TRAILERS SEM CONDUTOR; LOCACAO DE, ALUGUEL DE",
  },
  { cnae: "7719599", descricao: "ONIBUS; ARRENDAMENTO SEM OPCAO DE COMPRA DE" },
  { cnae: "7719599", descricao: "CAMINHOES; LEASING OPERACIONAL DE" },
  {
    cnae: "7719599",
    descricao: "ONIBUS SEM MOTORISTA; LOCACAO DE, ALUGUEL DE",
  },
  { cnae: "7719599", descricao: "SEMIRREBOQUES; LOCACAO DE, ALUGUEL DE" },
  {
    cnae: "7719599",
    descricao: "CAMINHOES; ARRENDAMENTO SEM OPCAO DE COMPRA DE",
  },
  { cnae: "7719599", descricao: "ONIBUS SEM CONDUTOR; LOCACAO DE, ALUGUEL DE" },
  { cnae: "7719599", descricao: "MOTOCICLETAS; LOCACAO DE, ALUGUEL DE" },
  {
    cnae: "7719599",
    descricao: "REBOQUES E SEMIRREBOQUES; ARRENDAMENTO SEM OPCAO DE COMPRA DE",
  },
  { cnae: "7719599", descricao: "REBOQUES; LOCACAO DE, ALUGUEL DE" },
  { cnae: "7719599", descricao: "ONIBUS; LEASING OPERACIONAL DE" },
  {
    cnae: "7719599",
    descricao: "CAMINHOES SEM MOTORISTA; LOCACAO DE, ALUGUEL DE",
  },
  {
    cnae: "7719599",
    descricao: "CAMINHOES SEM CONDUTOR; LOCACAO DE, ALUGUEL DE",
  },
  {
    cnae: "77217",
    descricao: "ALUGUEL DE EQUIPAMENTOS RECREATIVOS E ESPORTIVOS",
  },
  { cnae: "7721700", descricao: "BICICLETAS; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7721700", descricao: "EQUIPAMENTOS DE GINASTICA; LOCACAO DE" },
  {
    cnae: "7721700",
    descricao: "CADEIRAS DE PRAIA, GUARDA-SOIS; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7721700", descricao: "MESA DE SINUCA; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7721700",
    descricao: "BRINQUEDOS NAO ELETRONICOS; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7721700", descricao: "MESA DE BILHAR; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7721700",
    descricao: "BARCOS DE LAZER, CANOAS, BARCOS A VELA; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7721700", descricao: "TOTO; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7721700",
    descricao: "MATERIAIS E EQUIPAMENTOS ESPORTIVOS; ALUGUEL DE; LOCACAO DE",
  },
  { cnae: "77225", descricao: "ALUGUEL DE FITAS DE VIDEO, DVDS E SIMILARES" },
  { cnae: "7722500", descricao: "VHS; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7722500", descricao: "CD; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7722500",
    descricao: "CARTUCHOS DE VIDEO GAME; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7722500", descricao: "VIDEOLOCADORA" },
  {
    cnae: "7722500",
    descricao: "FILMES EM DVD OU CARTUCHO; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7722500", descricao: "LOCADORA DE FITAS DE VIDEO" },
  { cnae: "7722500", descricao: "DISCOS; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7722500", descricao: "CDS; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7722500", descricao: "BLU-RAY; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7722500", descricao: "LOCADORA DE VIDEO" },
  { cnae: "7722500", descricao: "DVDS; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7722500", descricao: "FITAS DE VIDEO; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "77233",
    descricao: "ALUGUEL DE OBJETOS DO VESTUARIO, JOIAS E ACESSORIOS",
  },
  { cnae: "7723300", descricao: "ENXOVAL; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7723300", descricao: "ROUPAS; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7723300",
    descricao: "ACESSORIOS DO VESTUARIO; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7723300", descricao: "VESTIDOS; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7723300", descricao: "CALCADOS; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7723300",
    descricao: "ACESSORIOS PARA NOIVAS; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7723300", descricao: "TRAJES; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7723300", descricao: "JOIAS; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7723300",
    descricao: "ARTIGOS DO VESTUARIO; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7723300", descricao: "VESTUARIO; ALUGUEL DE; LOCACAO DE" },
  { cnae: "7723300", descricao: "TERNO; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "77292",
    descricao:
      "ALUGUEL DE OBJETOS PESSOAIS E DOMESTICOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "7729201",
    descricao: "BRINQUEDOS ELETRONICOS; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7729201",
    descricao: "CONSOLES DE JOGOS ELETRONICOS; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7729201", descricao: "FLIPERAMAS; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7729201",
    descricao: "APARELHOS DE VIDEOGAMES; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7729201",
    descricao: "APARELHOS DE JOGOS ELETRONICOS; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7729202",
    descricao: "OBJETOS E UTENSILIOS DOMESTICOS; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7729202", descricao: "APARELHOS ELETROELETRONICOS; ALUGUEL DE" },
  { cnae: "7729202", descricao: "MESAS; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7729202", descricao: "AR CONDICIONADO; ALUGUEL DE" },
  {
    cnae: "7729202",
    descricao: "APARELHOS ELETRODOMESTICOS; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7729202",
    descricao: "TELEVISOR, TELEVISAO; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7729202", descricao: "VIDEOCASSETE; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7729202", descricao: "GELADEIRA, FREEZER; ALUGUEL DE" },
  {
    cnae: "7729202",
    descricao: "INSTRUMENTOS MUSICAIS; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7729202", descricao: "CADEIRAS; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7729202",
    descricao: "MOVEIS E UTENSILIOS PARA FESTAS; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7729202", descricao: "MOVEIS; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7729202",
    descricao:
      "UTENSILIOS E APARELHOS DE USO DOMESTICO; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7729202", descricao: "APARELHOS DE DVD; ALUGUEL DE" },
  {
    cnae: "7729202",
    descricao:
      "UTENSILIOS E APARELHOS ELETROELETRONICOS DE USO DOMESTICO; ALUGUEL DE",
  },
  { cnae: "7729203", descricao: "CADEIRAS DE RODA; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7729203", descricao: "MATERIAL MEDICO; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7729203", descricao: "MULETAS; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7729203", descricao: "INALADORES; ALUGUEL DE" },
  { cnae: "7729203", descricao: "CAMAS HOSPITALARES; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7729299", descricao: "ESSAS E ANDORES, LOCACAO DE, ALUGUEL DE" },
  { cnae: "7729299", descricao: "LIVROS E REVISTAS; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7729299", descricao: "PLANTAS E FLORES; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "77314",
    descricao: "ALUGUEL DE MAQUINAS E EQUIPAMENTOS AGRICOLAS SEM OPERADOR",
  },
  {
    cnae: "7731400",
    descricao: "IMPLEMENTOS AGRICOLAS SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7731400", descricao: "ADUBADORAS SEM OPERADOR; ALUGUEL DE" },
  {
    cnae: "7731400",
    descricao: "EQUIPAMENTOS AGRICOLAS SEM OPERADOR; LEASING OPERACIONAL DE",
  },
  {
    cnae: "7731400",
    descricao:
      "MAQUINAS E EQUIPAMENTOS AGRICOLAS SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7731400",
    descricao: "MAQUINAS AGRICOLAS SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7731400",
    descricao:
      "MAQUINAS E EQUIPAMENTOS AGRICOLAS SEM OPERADOR; LEASING OPERACIONAL DE",
  },
  {
    cnae: "7731400",
    descricao: "EQUIPAMENTOS AGRICOLAS SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7731400", descricao: "ARADOS SEM OPERADOR; ALUGUEL DE" },
  {
    cnae: "7731400",
    descricao: "TRATORES AGRICOLAS SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7731400", descricao: "COLHEDEIRAS SEM OPERADOR; ALUGUEL DE" },
  {
    cnae: "77322",
    descricao:
      "ALUGUEL DE MAQUINAS E EQUIPAMENTOS PARA CONSTRUCAO SEM OPERADOR",
  },
  {
    cnae: "7732201",
    descricao:
      "MOTONIVELADORES PARA CONSTRUCAO SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7732201",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA CONSTRUCAO SEM OPERADOR; LEASING OPERACIONAL DE",
  },
  {
    cnae: "7732201",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA CONSTRUCAO, SEM OPERADOR; ARRENDAMENTO SEM OPCAO DE COMPRA DE",
  },
  {
    cnae: "7732201",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA CONSTRUCAO SEM OPERADOR, EXCETO ANDAIMES; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7732201", descricao: "BETONEIRAS; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7732201",
    descricao:
      "GUINDASTES, EMPILHADEIRAS PARA CONSTRUCAO CIVIL, SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7732201",
    descricao: "TRATORES PARA CONSTRUCAO SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7732201",
    descricao: "MAQUINAS DE TERRAPLENAGEM SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7732201",
    descricao:
      "ESCAVADORAS PARA CONSTRUCAO SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7732202",
    descricao:
      "ANDAIMES E ESCORAMENTOS SEM MONTAGEM E DESMONTAGEM; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7732202",
    descricao: "PLATAFORMAS SEM MONTAGEM E DESMONTAGEM; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7732202",
    descricao: "ANDAIMES SEM MONTAGEM E DESMONTAGEM; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7732202", descricao: "FORMAS METALICAS PARA CONCRETO; ALUGUEL DE" },
  { cnae: "7732202", descricao: "ANDAIMES; LEASING OPERACIONAL DE" },
  {
    cnae: "77331",
    descricao: "ALUGUEL DE MAQUINAS E EQUIPAMENTOS PARA ESCRITORIO",
  },
  { cnae: "7733100", descricao: "REPRODUTORAS DE COPIAS; ALUGUEL, LOCACAO DE" },
  {
    cnae: "7733100",
    descricao: "MAQUINAS DE ESCREVER; ALUGUEL DE , LOCACAO DE",
  },
  { cnae: "7733100", descricao: "MICROCOMPUTADORES; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7733100",
    descricao:
      "MAQUINAS REPRODUTORAS DE COPIAS; ALUGUEL DE, LOCACAO DE, LEASING OPERACIONAL DE",
  },
  {
    cnae: "7733100",
    descricao: "EQUIPAMENTOS PERIFERICOS DE INFORMATICA; ALUGUEL DE",
  },
  { cnae: "7733100", descricao: "CALCULADORAS ELETRONICAS; ALUGUEL DE" },
  { cnae: "7733100", descricao: "PROJETORES; ALUGUEL DE" },
  {
    cnae: "7733100",
    descricao: "COMPUTADORES; ALUGUEL DE, LOCACAO DE, LEASING OPERACIONAL DE",
  },
  {
    cnae: "7733100",
    descricao:
      "MAQUINAS E EQUIPAMENTOS DE CONTABILIDADE; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7733100", descricao: "CELULAR; ALUGUEL, LOCACAO DE" },
  {
    cnae: "7733100",
    descricao: "IMPRESSORAS; ALUGUEL DE, LOCACAO DE, LEASING OPERACIONAL DE",
  },
  { cnae: "7733100", descricao: "MAQUINAS COPIADORAS; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7733100", descricao: "FOTOCOPIADORAS; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7733100",
    descricao:
      "MAQUINAS, EQUIPAMENTOS E MOVEIS DE ESCRITORIO; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7733100", descricao: "CAIXAS REGISTRADORAS; ALUGUEL DE" },
  { cnae: "7733100", descricao: "DATA-SHOW; ALUGUEL DE" },
  {
    cnae: "7733100",
    descricao:
      "MAQUINAS, EQUIPAMENTOS E MOVEIS DE ESCRITORIO; LEASING OPERACIONAL DE",
  },
  { cnae: "7733100", descricao: "MATERIAL TELEFONICO; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7733100",
    descricao: "RECEPTORES DE MENSAGENS - BIP; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7733100", descricao: "FAX; ALUGUEL, LOCACAO DE" },
  {
    cnae: "7733100",
    descricao: "EQUIPAMENTOS DE PROCESSAMENTO DE DADOS; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "8423000", descricao: "ADMINISTRACAO PENITENCIARIA" },
  {
    cnae: "7733100",
    descricao: "TELEFONES PARA ESCRITORIO; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7733100",
    descricao: "EQUIPAMENTO TELEFONICO; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "77390",
    descricao:
      "ALUGUEL DE MAQUINAS E EQUIPAMENTOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "7739001",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA EXTRACAO DE MINERIOS; LEASING OPERACIONAL DE",
  },
  {
    cnae: "7739001",
    descricao:
      "EQUIPAMENTOS PARA EXTRACAO DE MINERIO SEM OPERADOR; ALUGUEL DE,  LEASING OPERACIONAL DE",
  },
  {
    cnae: "7739001",
    descricao:
      "EQUIPAMENTOS PARA EXTRACAO DE PETROLEO SEM OPERADOR; ALUGUEL DE, LEASING OPERACIONAL DE",
  },
  {
    cnae: "7739001",
    descricao:
      "MAQUINAS E EQUIPAMENTOS PARA EXTRACAO DE PETROLEO; LEASING OPERACIONAL DE",
  },
  {
    cnae: "7739002",
    descricao:
      "EQUIPAMENTOS CIENTIFICOS, MEDICOS E HOSPITALARES, SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739002",
    descricao: "APARELHOS DE USO CIENTIFICO; ALUGUEL, LOCACAO DE",
  },
  {
    cnae: "7739002",
    descricao: "EQUIPAMENTOS CIENTIFICOS; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739002",
    descricao:
      "EQUIPAMENTOS CIENTIFICOS, MEDICOS E HOSPITALARES; LEASING OPERACIONAL DE",
  },
  {
    cnae: "7739002",
    descricao:
      "EQUIPAMENTOS MEDICO-CIRURGICO HOSPITALARES; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739002",
    descricao:
      "APARELHOS TERAPEUTICOS ELETROMAGNETICOS; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739002",
    descricao: "EQUIPAMENTOS E MOVEIS PARA HOSPITAIS; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739003",
    descricao: "MODULOS METALICOS PARA ALOJAMENTO; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7739003", descricao: "SANITARIOS QUIMICOS; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7739003",
    descricao: "SANITARIOS PORTATEIS; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7739003", descricao: "BANHEIROS QUIMICOS; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7739003",
    descricao:
      "ESTANDES PARA FEIRAS E EVENTOS, COM OU SEM MONTAGEM; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739003",
    descricao:
      "PALCOS, COBERTURAS E OUTRAS ESTRUTURAS DE USO TEMPORARIO; LEASING OPERACIONAL DE",
  },
  {
    cnae: "7739003",
    descricao: "TABULEIROS DE FEIRA; LOCACAO DE, MONTAGEM DE",
  },
  { cnae: "7739003", descricao: "TOLDOS; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7739003", descricao: "TENDAS; ALUGUEL, LOCACAO DE" },
  {
    cnae: "7739003",
    descricao:
      "PALCO, COBERTURAS E OUTRAS ESTRUTURAS DE USO TEMPORARIO; LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao: "EQUIPAMENTOS DE SEGURANCA; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao:
      "APARELHOS, EQUIPAMENTOS DE SOM (PARA USO PROFISSIONAL); ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao:
      "MAQUINAS-FERRAMENTA SEM OPERADOR PARA USO INDUSTRIAL; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7739099", descricao: "CHOPEIRAS; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7739099",
    descricao: "EQUIPAMENTOS DE AUDIO VISUAL; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao: "CAMERAS DE VIGILANCIA; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao:
      "EQUIPAMENTOS DE TESTE, MEDICAO E CONTROLE; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7739099", descricao: "EQUIPAMENTOS DE SOM; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7739099", descricao: "FILMADORA DIGITAL; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7739099",
    descricao:
      "MICROFONES, CAIXAS ACUSTICAS E AUTO FALANTES; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao: "CAMARAS FRIGORIFICAS; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7739099", descricao: "COMPRESSORES; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7739099",
    descricao: "FERRAMENTAS ELETRICAS; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao:
      "APARELHOS DE USO INDUSTRIAL SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao: "BALCOES FRIGORIFICOS; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao:
      "EQUIPAMENTO DE RADIO DE COMUNICACAO PROFISSIONAL; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao: "MAQUINAS FILMADORAS PROFISSIONAIS; ALUGUEL DE",
  },
  {
    cnae: "7739099",
    descricao: "EQUIPAMENTOS DE FILMAGEM; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7739099", descricao: "GERADORES; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7739099",
    descricao:
      "APARELHOS, EQUIPAMENTOS DE SINALIZACAO E ALARMES SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao:
      "EQUIPAMENTOS DE USO COMERCIAL SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao: "RELOGIOS DE HORA E TEMPERATURA; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao:
      "EQUIPAMENTOS CINEMATOGRAFICOS SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao:
      "GUINCHOS, GUINDASTES E EMPILHADEIRAS PARA MOVIMENTACAO DE CARGAS, SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao:
      "APARELHOS E EQUIPAMENTOS DE RADIOAMADORES; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao:
      "EQUIPAMENTO PROFISSIONAL DE SOM E VIDEO; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao: "APARELHOS DE RADIOAMADORES; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7739099", descricao: "ALARMES; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7739099",
    descricao:
      "ALUGUEL DE OUTRAS MAQUINAS E EQUIPAMENTOS COMERCIAIS E INDUSTRIAIS (INCLUSIVE JUKEBOXES) NAO ESPECIFICADOS ANTERIORMENTE, SEM OPERADOR",
  },
  { cnae: "7739099", descricao: "CAMERAS DIGITAIS; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7739099",
    descricao:
      "MOTORES E TURBINAS SEM OPERADOR PARA USO INDUSTRIAL; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "7739099", descricao: "CONTEINERES; ALUGUEL DE, LOCACAO DE" },
  { cnae: "7739099", descricao: "CONTEINER; ALUGUEL DE, LOCACAO DE" },
  {
    cnae: "7739099",
    descricao:
      "EQUIPAMENTOS PARA ILUMINACAO DE EVENTOS; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao: "MAQUINAS DE CAFE EXPRESSO; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao:
      "EQUIPAMENTO PROFISSIONAL DE TELECOMUNICACOES; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao:
      "MAQUINAS DE GERACAO DE ENERGIA ELETRICA; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao:
      "EQUIPAMENTO DE SIMULACAO DE VOO, SEM OPERADOR; LOCACAO DE,  ALUGUEL DE",
  },
  {
    cnae: "7739099",
    descricao: "EQUIPAMENTOS DE RADIOAMADORES; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao: "CAMARAS DE VIGILANCIA; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "7739099",
    descricao:
      "APARELHOS DE USO COMERCIAL SEM OPERADOR; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "77403", descricao: "GESTAO DE ATIVOS INTANGIVEIS NAOFINANCEIROS" },
  { cnae: "7740300", descricao: "PATENTES E ROYALTIES; GERENCIAMENTO DE" },
  { cnae: "7740300", descricao: "FRANQUEADOR DE MARCAS ESPORTIVAS" },
  { cnae: "7740300", descricao: "ROYALTIES; ADMINISTRACAO DE" },
  {
    cnae: "7740300",
    descricao:
      "INTERMEDIACAO DE CERTIFICADOS DE CREDITOS DE CARBONO; SERVICOS DE",
  },
  {
    cnae: "7740300",
    descricao: "PATENTES E ROYALTIES; CESSAO DE DIREITO DE USO DE",
  },
  { cnae: "7740300", descricao: "LICENCIAMENTO DO USO DE MARCAS E PATENTES" },
  {
    cnae: "7740300",
    descricao: "COMPRA E VENDA DO DIREITO DE USO DE MARCAS E PATENTES",
  },
  { cnae: "7740300", descricao: "PATENTES; GESTAO DE" },
  { cnae: "7740300", descricao: "PATENTES; COMPRA E VENDA DE" },
  { cnae: "7740300", descricao: "EXPLORACAO MINERAL; DIREITOS DE" },
  { cnae: "7740300", descricao: "ROYALTIES; SERVICOS DE COBRANCA DE" },
  {
    cnae: "7740300",
    descricao: "FRANQUIA, FRANCHISING; VENDA E LICENCIAMENTO DE",
  },
  { cnae: "7740300", descricao: "MARCAS E PATENTES; ARRENDAMENTO DE" },
  { cnae: "78108", descricao: "SELECAO E AGENCIAMENTO DE MAODEOBRA" },
  {
    cnae: "7810800",
    descricao: "AGENCIA DE RECRUTAMENTO DE ATORES PARA TEATRO E CINEMA",
  },
  { cnae: "7810800", descricao: "AGENCIA DE EMPREGO ON-LINE" },
  {
    cnae: "7810800",
    descricao: "SELECAO E AGENCIAMENTO DE MAO-DE-OBRA; SERVICOS DE",
  },
  { cnae: "7810800", descricao: "AGENCIA DE EMPREGOS" },
  { cnae: "7810800", descricao: "INTERMEDIACAO DE EMPREGO; SERVICOS DE" },
  {
    cnae: "7810800",
    descricao: "RECRUTAMENTO E SELECAO DE PESSOAL; SERVICOS DE",
  },
  { cnae: "7810800", descricao: "AGENCIA DE CONTRATACAO DE EMPREGO" },
  { cnae: "7810800", descricao: "AGENCIAMENTO DE MAO-DE-OBRA" },
  { cnae: "7810800", descricao: "SELECAO DE MAO-DE-OBRA" },
  { cnae: "7810800", descricao: "CASTING; ATIVIDADE DE" },
  { cnae: "78205", descricao: "LOCACAO DE MAODEOBRA TEMPORARIA" },
  { cnae: "7820500", descricao: "PART-TIME; EMPRESA DE" },
  {
    cnae: "7820500",
    descricao: "MAO-DE-OBRA TEMPORARIA TERCEIRIZADA; LOCACAO DE",
  },
  {
    cnae: "7820500",
    descricao: "CONTRATACAO DE MAO-DE-OBRA TEMPORARIA; SERVICOS DE",
  },
  { cnae: "7820500", descricao: "AGENCIAS DE TRABALHO TEMPORARIO" },
  {
    cnae: "7820500",
    descricao: "LOCACAO DE MAO-DE-OBRA, DE PESSOAL, TEMPORARIA",
  },
  {
    cnae: "7820500",
    descricao: "TERCEIRIZACAO DE PESSOAL TEMPORARIO; SERVICOS DE",
  },
  {
    cnae: "7820500",
    descricao:
      "ALOCACAO DE MAO-DE-OBRA TEMPORARIA EM EMPRESA CLIENTE; SERVICOS DE",
  },
  {
    cnae: "7820500",
    descricao: "CESSAO DE MAO-DE-OBRA TEMPORARIA; SERVICOS DE",
  },
  {
    cnae: "7820500",
    descricao: "DISPONIBILIZACAO DE MAO-DE-OBRA TEMPORARIA; SERVICOS DE",
  },
  { cnae: "7820500", descricao: "MAO-DE-OBRA TEMPORARIA; LOCACAO DE" },
  {
    cnae: "7820500",
    descricao:
      "COLOCACAO E FORNECIMENTO DE MAO-DE-OBRA TEMPORARIA; SERVICOS DE",
  },
  { cnae: "7820500", descricao: "EMPREITEIRA DE MAO-DE-OBRA TEMPORARIA" },
  {
    cnae: "78302",
    descricao: "FORNECIMENTO E GESTAO DE RECURSOS HUMANOS PARA TERCEIROS",
  },
  {
    cnae: "7830200",
    descricao: "RECURSOS HUMANOS NA EMPRESA CLIENTE; COLOCACAO DE",
  },
  {
    cnae: "7830200",
    descricao: "RECURSOS HUMANOS NA EMPRESA CLIENTE; GESTAO DE",
  },
  {
    cnae: "7830200",
    descricao: "GESTAO DE MAO-DE-OBRA DO TRABALHO PORTUARIO, SERVICO DE",
  },
  {
    cnae: "7830200",
    descricao:
      "SERVICOS DE RECURSOS HUMANOS A EMPRESAS CLIENTES; FORNECIMENTO DE",
  },
  {
    cnae: "7830200",
    descricao: "MAO-DE-OBRA DO TRABALHO PORTUARIO, GESTAO DE",
  },
  {
    cnae: "7830200",
    descricao: "MAO-DE-OBRA NA EMPRESA CLIENTE; FORNECIMENTO DE",
  },
  {
    cnae: "7830200",
    descricao: "COLOCACAO DE MAO-DE-OBRA NA EMPRESA CLIENTE; SERVICOS DE",
  },
  { cnae: "79112", descricao: "AGENCIAS DE VIAGENS" },
  {
    cnae: "7911200",
    descricao:
      "FORNECIMENTO DE INFORMACOES, ASSESSORAMENTO E PLANEJAMENTO DE VIAGENS; SERVICOS DE",
  },
  { cnae: "7911200", descricao: "PROGRAMAS E PACOTES TURISTICOS; VENDA DE" },
  { cnae: "7911200", descricao: "VIAGEM DE TURISMO; VENDA DE" },
  { cnae: "7911200", descricao: "PACOTES DE VIAGEM; VENDA DE" },
  { cnae: "7911200", descricao: "ASSESSORIA TECNICA DE TURISMO; SERVICOS DE" },
  { cnae: "7911200", descricao: "VIAGEM TURISTICA; VENDA DE" },
  {
    cnae: "7911200",
    descricao: "VIAGENS E PACOTES TURISTICOS; ORGANIZACAO E VENDA DE",
  },
  {
    cnae: "7911200",
    descricao: "PLANEJAMENTO, ASSESSORAMENTO E ORGANIZACAO DE VIAGENS",
  },
  { cnae: "7911200", descricao: "TURISMO; AGENCIA DE" },
  { cnae: "7911200", descricao: "TURISMOLOGO" },
  { cnae: "7911200", descricao: "PACOTES DE VIAGEM MARITIMA; VENDA DE" },
  { cnae: "7911200", descricao: "RESERVA DE HOTEL; SERVICOS DE" },
  { cnae: "7911200", descricao: "AGENCIAMENTOS TURISTICOS; SERVICOS DE" },
  {
    cnae: "7911200",
    descricao: "VENDA DE PASSAGENS AEREAS; AGENCIA DE EMPRESA ESTRANGEIRA DE",
  },
  { cnae: "7911200", descricao: "AGENCIA DE VIAGEM; SERVICOS DE" },
  { cnae: "7911200", descricao: "EXCURSOES; VENDA DE" },
  { cnae: "7911200", descricao: "INTERMEDIARIO NA VENDA DE PASSAGENS AEREAS" },
  { cnae: "79121", descricao: "OPERADORES TURISTICOS" },
  { cnae: "7912100", descricao: "GUIA TURISTICO; SERVICOS DE" },
  {
    cnae: "7912100",
    descricao: "ORGANIZACAO DE PACOTES DE TURISMO; SERVICOS DE",
  },
  { cnae: "7912100", descricao: "PROGRAMAS TURISTICOS; SERVICOS DE" },
  { cnae: "7912100", descricao: "OPERACAO DE TURISMO; SERVICOS DE" },
  { cnae: "7912100", descricao: "ORGANIZACAO DE EXCURSOES; SERVICOS DE" },
  { cnae: "7912100", descricao: "OPERADORES TURISTICOS" },
  { cnae: "7912100", descricao: "ORGANIZADORES DE VIAGEM; SERVICOS DE" },
  {
    cnae: "7912100",
    descricao: "ORGANIZACAO DE PROGRAMAS DE VIAGEM; SERVICOS DE",
  },
  {
    cnae: "7912100",
    descricao: "ORGANIZACAO DE PACOTES DE VIAGEM; SERVICOS DE",
  },
  {
    cnae: "7912100",
    descricao: "ORGANIZACAO DE PROGRAMAS TURISTICOS; SERVICOS DE",
  },
  { cnae: "7912100", descricao: "INTERMEDIACAO DE VIAGEM; SERVICOS DE" },
  {
    cnae: "7912100",
    descricao: "ORGANIZACAO DE ROTEIROS DE TURISMO; SERVICOS DE",
  },
  {
    cnae: "7912100",
    descricao: "ORGANIZACAO DE ROTEIROS DE VIAGEM; SERVICOS DE",
  },
  {
    cnae: "7912100",
    descricao: "ORGANIZACAO DE ROTEIROS TURISTICOS; SERVICOS DE",
  },
  { cnae: "7912100", descricao: "OPERACOES DE VIAGEM; SERVICOS DE" },
  {
    cnae: "7912100",
    descricao: "ORGANIZACAO DE VIAGEM TURISTICA; SERVICOS DE",
  },
  { cnae: "7912100", descricao: "PACOTES TURISTICOS; ORGANIZACAO DE" },
  {
    cnae: "7912100",
    descricao: "ORGANIZACAO DE PROGRAMAS DE TURISMO; SERVICOS DE",
  },
  {
    cnae: "79902",
    descricao:
      "SERVICOS DE RESERVAS E OUTROS SERVICOS DE TURISMO NAO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "7990200",
    descricao:
      "TITULOS PARA HOSPEDAGEM COM DESCONTO EM HOTEIS PROPRIOS OU CONVENIADOS; VENDA DE",
  },
  { cnae: "7990200", descricao: "GUICHE DE VENDA DE PASSAGENS DE ONIBUS" },
  {
    cnae: "7990200",
    descricao: "BILHETES DE PASSAGEM PARA QUALQUER FINALIDADE; VENDA DE",
  },
  { cnae: "7990200", descricao: "ASSISTENCIA A TURISTAS; SERVICOS DE" },
  { cnae: "7990200", descricao: "TURISMO LOCAL; PROMOCAO DE" },
  {
    cnae: "7990200",
    descricao: "TITULOS DE HOSPEDAGEM PARA TURISMO; VENDA DE",
  },
  {
    cnae: "7990200",
    descricao: "RESERVAS RELACIONADAS A VIAGENS; SERVICOS DE",
  },
  {
    cnae: "7990200",
    descricao: "TURISMO; SERVICOS DE INFORMACAO E ASSISTENCIA AO",
  },
  { cnae: "7990200", descricao: "INFORMACOES TURISTICAS; SERVICOS DE" },
  {
    cnae: "7990200",
    descricao:
      "ASSISTENCIA A TURISTAS, INCLUSIVE DE ORGAOS MUNICIPAIS, ESTADUAIS E FEDERAIS; ATIVIDADES DE",
  },
  {
    cnae: "7990200",
    descricao:
      "RESERVA E VENDA DE INGRESSOS PARA RECREACAO E LAZER; SERVICOS DE",
  },
  { cnae: "7990200", descricao: "TITULOS DE HOTEIS PARA TURISMO; VENDA DE" },
  { cnae: "7990200", descricao: "ASSISTENCIA A VIAJANTES; SERVICOS DE" },
  {
    cnae: "7990200",
    descricao:
      "AGENCIA DE VENDA DE INGRESSOS PARA TEATROS, CINEMAS E OUTRAS ATIVIDADES ARTISTICAS",
  },
  { cnae: "80111", descricao: "ATIVIDADES DE VIGILANCIA E SEGURANCA PRIVADA" },
  {
    cnae: "8011101",
    descricao:
      "IDENTIFICACAO ATRAVES DA IMPRESSAO DIGITAL (DATILOSCOPIA); SERVICO DE",
  },
  { cnae: "8011101", descricao: "VIGILANCIA A PROPRIEDADES; SERVICO DE" },
  { cnae: "8011101", descricao: "GUARDA DE SEGURANCA; SERVICO DE" },
  { cnae: "8011101", descricao: "GUARDA PATRIMONIAL; SERVICO DE" },
  { cnae: "8011101", descricao: "SEGURANCA INDUSTRIAL; SERVICO DE" },
  { cnae: "8011101", descricao: "VIGILANCIA E SEGURANCA; SERVICOS DE" },
  { cnae: "8011101", descricao: "PROTECAO DE PROPRIEDADES; SERVICO DE" },
  { cnae: "8011101", descricao: "PROTECAO DE PESSOAS; SERVICO DE" },
  { cnae: "8011101", descricao: "VIGILANCIA E SEGURANCA PRIVADA; SERVICOS DE" },
  {
    cnae: "8011101",
    descricao: "SEGURANCA DE LUGARES E INSTITUICOES PUBLICAS; SERVICOS DE",
  },
  { cnae: "8011101", descricao: "EMPRESA DE SEGURANCA" },
  { cnae: "8011101", descricao: "EMPRESA DE VIGILANCIA" },
  { cnae: "8011101", descricao: "ESCOLTA DE PESSOAS E BENS; SERVICO DE" },
  { cnae: "8011102", descricao: "CAES DE GUARDA; SERVICO DE ADESTRAMENTO" },
  { cnae: "8011102", descricao: "ADESTRAMENTO DE CAES DE GUARDA; SERVICO DE" },
  { cnae: "80129", descricao: "ATIVIDADES DE TRANSPORTE DE VALORES" },
  { cnae: "8012900", descricao: "TRANSPORTE DE VALORES; SERVICO DE" },
  {
    cnae: "8012900",
    descricao: "TRANSPORTE DE DINHEIRO E OUTROS ITENS DE VALOR; SERVICOS DE",
  },
  {
    cnae: "8012900",
    descricao: "TRANSPORTE DE VALORES EM VEICULOS BLINDADOS; SERVICO DE",
  },
  {
    cnae: "80200",
    descricao: "ATIVIDADES DE MONITORAMENTO DE SISTEMAS DE SEGURANCA",
  },
  { cnae: "8020001", descricao: "ALARMES DE INCENDIO; SERVICO DE" },
  { cnae: "8020001", descricao: "ALARMES DE PROTECAO A ROUBOS; SERVICOS DE" },
  {
    cnae: "8020001",
    descricao: "SISTEMAS DE SEGURANCA ELETRONICOS; SERVICOS DE",
  },
  {
    cnae: "8020001",
    descricao: "VEICULOS ROUBADOS; SERVICOS DE RECUPERACAO DE",
  },
  { cnae: "8020001", descricao: "SISTEMAS DE SEGURANCA ELETRICA; SERVICOS DE" },
  {
    cnae: "8020001",
    descricao: "MONITORAMENTO ELETRONICO DE SEGURANCA, SERVICOS DE",
  },
  {
    cnae: "8020001",
    descricao: "LOCALIZACAO DE VEICULOS ROUBADOS; SERVICO DE",
  },
  {
    cnae: "8020001",
    descricao: "VEICULOS ROUBADOS; SERVICOS DE RASTREADORA DE",
  },
  {
    cnae: "8020001",
    descricao: "CONTROLE A DISTANCIA DE SISTEMAS DE SEGURANCA; SERVICO DE",
  },
  {
    cnae: "8020001",
    descricao:
      "MONITORAMENTO DE EQUIPAMENTOS DE SEGURANCA COM A VENDA, INSTALACAO E MANUTENCAO ASSOCIADAS",
  },
  {
    cnae: "8020001",
    descricao:
      "MONITORAMENTO DE BENS E PESSOAS, COM USO DE IMAGEM POR SATELITE; SERVICO DE",
  },
  {
    cnae: "8020001",
    descricao:
      "MONITORAMENTO DE VEICULOS COM RASTREAMENTO VIA SATELITE; SERVICO DE",
  },
  {
    cnae: "8020001",
    descricao: "MONITORAMENTO DE SISTEMAS DE SEGURANCA; SERVICO DE",
  },
  {
    cnae: "8020002",
    descricao:
      "COFRES, TRANCAS E TRAVAS; INSTALACAO, REPARACAO, AJUSTE MECANICO DE",
  },
  { cnae: "80307", descricao: "ATIVIDADES DE INVESTIGACAO PARTICULAR" },
  { cnae: "8030700", descricao: "DETETIVE PARTICULAR; AGENCIA DE" },
  { cnae: "8030700", descricao: "AGENCIA DE DETETIVES PARTICULARES" },
  { cnae: "8030700", descricao: "INVESTIGACAO PRIVADA; SERVICO DE" },
  { cnae: "8030700", descricao: "INVESTIGACAO PARTICULAR; AGENCIA DE" },
  { cnae: "8030700", descricao: "DETETIVE PARTICULAR; SERVICO DE" },
  {
    cnae: "81117",
    descricao:
      "SERVICOS COMBINADOS PARA APOIO A EDIFICIOS, EXCETO CONDOMINIOS PREDIAIS",
  },
  { cnae: "8111700", descricao: "ZELADORIA; SERVICO DE" },
  {
    cnae: "8111700",
    descricao:
      "RECEPCAO,ZELADORIA, DISPOSICAO DE LIXO; FORNECIMENTO DE SERVICOS COMBINADOS DE",
  },
  {
    cnae: "8111700",
    descricao:
      "LIMPEZA, DISPOSICAO DE LIXO E OUTROS SERVICOS DE CONSERVACAO; FORNECIMENTO DE SERVICOS COMBINADOS DE",
  },
  {
    cnae: "8111700",
    descricao: "PORTARIA, LIMPEZA, MANUTENCAO; SERVICOS COMBINADOS DE",
  },
  {
    cnae: "8111700",
    descricao:
      "APOIO E CONSERVACAO (LIMPEZA) DE PREDIOS; FORNECIMENTO DE SERVICOS COMBINADOS DE",
  },
  { cnae: "8111700", descricao: "CONTROLE DE ACESSO; SERVICOS DE" },
  {
    cnae: "8111700",
    descricao:
      "LIMPEZA, MANUTENCAO, RECEPCAO EM PREDIOS; SERVICOS COMBINADOS DE",
  },
  { cnae: "8111700", descricao: "SERVICOS COMBINADOS PARA APOIO A EDIFICIOS" },
  { cnae: "8111700", descricao: "SERVICOS COMBINADOS EM PREDIOS" },
  { cnae: "8111700", descricao: "PORTARIA; SERVICO DE" },
  { cnae: "8111700", descricao: "RECEPCAO EM PREDIOS; SERVICO DE" },
  { cnae: "81125", descricao: "CONDOMINIOS PREDIAIS" },
  {
    cnae: "8112500",
    descricao: "EMPREGADOS DE CONDOMINIOS PREDIAIS; SERVICOS DE",
  },
  {
    cnae: "8112500",
    descricao: "CONDOMINIO DE PREDIO, RESIDENCIAL OU NAO; ATIVIDADE DE",
  },
  {
    cnae: "8112500",
    descricao: "CONDOMINIO PREDIAL; SERVICOS PRESTADOS POR EMPREGADOS DE",
  },
  { cnae: "8112500", descricao: "CONDOMINIO PREDIAL, RESIDENCIAL E COMERCIAL" },
  { cnae: "81214", descricao: "LIMPEZA EM PREDIOS E EM DOMICILIOS" },
  { cnae: "8121400", descricao: "ASSEIO EM IMOVEIS; SERVICOS DE" },
  {
    cnae: "8121400",
    descricao: "HIGIENIZACAO DE PREDIOS E DOMICILIOS; SERVICOS DE",
  },
  {
    cnae: "8121400",
    descricao:
      "LIMPEZA (CONSERVACAO) DE PREDIOS E DOMICILIOS; EMPRESA QUE EXECUTA APENAS SERVICOS DE",
  },
  { cnae: "8121400", descricao: "ASSEIO DE PREDIOS; SERVICOS DE" },
  {
    cnae: "8121400",
    descricao: "LIMPEZA (CONSERVACAO) DE IMOVEIS; SERVICOS ESPECIFICOS DE",
  },
  {
    cnae: "8121400",
    descricao: "CONSERVADORA DE PREDIOS E DOMICILIOS; EMPRESA",
  },
  {
    cnae: "8121400",
    descricao: "HIGIENIZACAO E CONSERVACAO DE PREDIOS; SERVICOS DE",
  },
  { cnae: "8121400", descricao: "CONSERVADORAS; LIMPEZA EM IMOVEIS" },
  {
    cnae: "8121400",
    descricao: "CONSERVACAO E ASSEIO EM PREDIOS E DOMICILIOS",
  },
  { cnae: "8121400", descricao: "FAXINA EM PREDIO E DOMICILIOS; SERVICOS DE" },
  {
    cnae: "8121400",
    descricao: "ASSEIO E CONSERVACAO DE PREDIOS; SERVICOS DE",
  },
  {
    cnae: "8121400",
    descricao: "LIMPEZA E HIGIENIZACAO DE BANHEIROS PUBLICOS; SERVICOS DE",
  },
  {
    cnae: "8121400",
    descricao: "LIMPEZA DE JANELAS E DE CORREDORES EXTERNOS; SERVICO DE",
  },
  { cnae: "8121400", descricao: "HIGIENIZACAO EM IMOVEIS; SERVICOS DE" },
  { cnae: "81222", descricao: "IMUNIZACAO E CONTROLE DE PRAGAS URBANAS" },
  { cnae: "8122200", descricao: "DESINSETIZACAO; SERVICO DE" },
  {
    cnae: "8122200",
    descricao: "COMBATE E CONTROLE DE PRAGAS URBANAS; ATIVIDADE DE",
  },
  { cnae: "8122200", descricao: "DESINFECCAO; SERVICOS DE" },
  { cnae: "8122200", descricao: "FUMIGACAO; SERVICO DE" },
  {
    cnae: "8122200",
    descricao: "IMUNIZACAO E CONTROLE DE PRAGAS URBANAS; SERVICOS DE",
  },
  { cnae: "8122200", descricao: "DESCUPINIZACAO; SERVICO DE" },
  { cnae: "8122200", descricao: "DESRATIZACAO; SERVICO DE" },
  {
    cnae: "8122200",
    descricao: "IMUNIZACAO E COMBATE DE PRAGAS URBANAS; SERVICOS DE",
  },
  { cnae: "8122200", descricao: "DEDETIZACAO; SERVICO DE" },
  {
    cnae: "81290",
    descricao: "ATIVIDADES DE LIMPEZA NAO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "8129000", descricao: "LAVAGEM DE RECIPIENTES; SERVICO DE" },
  { cnae: "8129000", descricao: "LIMPEZA DE INCINERADORES; SERVICO DE" },
  {
    cnae: "8129000",
    descricao:
      "LIMPEZA DE FORNOS, DUTOS, INCINERADORES; SERVICOS DE HIDROJATEAMENTO",
  },
  {
    cnae: "8129000",
    descricao: "LIMPEZA E CONSERVACAO DE RUAS, LOGRADOUROS; SERVICOS DE",
  },
  { cnae: "8129000", descricao: "LIMPEZA DE CAIXA DE AGUA; SERVICO DE" },
  { cnae: "8129000", descricao: "CAPINA, CAPINACAO DE RUA, LOGRADOURO" },
  { cnae: "8129000", descricao: "SACARIA (SACOS PARA EMBALAGEM); LAVAGEM DE" },
  { cnae: "8129000", descricao: "LIMPEZA DE PISCINAS; SERVICOS DE" },
  { cnae: "8129000", descricao: "LIMPEZA DE RUAS, LOGRADOUROS; ATIVIDADE DE" },
  {
    cnae: "8129000",
    descricao: "LIMPEZA EM CAMINHAO-TANQUE PARA DESGASEIFICACAO DE VAPOR",
  },
  { cnae: "8129000", descricao: "LIMPEZA DE MAQUINAS INDUSTRIAIS; SERVICO DE" },
  { cnae: "8129000", descricao: "LIMPEZA EM ONIBUS; ATIVIDADE DE" },
  { cnae: "8129000", descricao: "ESTERILIZACAO; SERVICO DE" },
  { cnae: "8129000", descricao: "LIMPEZA DE CALDEIRAS; SERVICO DE" },
  { cnae: "8129000", descricao: "LIMPEZA EM TRENS; ATIVIDADE DE" },
  {
    cnae: "8129000",
    descricao: "LIMPEZA DE DUTOS PARA A INDUSTRIA; SERVICO DE",
  },
  {
    cnae: "8129000",
    descricao: "ESTERILIZACAO DE EQUIPAMENTOS MEDICO-HOSPITALARES; SERVICO DE",
  },
  { cnae: "8129000", descricao: "LIMPEZA DE VASILHAMES; ATIVIDADE DE" },
  { cnae: "8129000", descricao: "VARREDURA, VARRICAO DE RUAS, LOGRADOUROS" },
  { cnae: "8129000", descricao: "LIMPEZA EM EMBARCACOES; ATIVIDADE DE" },
  {
    cnae: "8129000",
    descricao:
      "ELIMINACAO DE MICROORGANISMOS NOCIVOS EM PRODUTOS AGRICOLAS, LIVROS E OUTROS; SERVICO DE",
  },
  { cnae: "8129000", descricao: "DESENTUPIMENTO EM PREDIOS; SERVICO DE" },
  {
    cnae: "8129000",
    descricao: "LIMPEZA DE MAQUINAS INDUSTRIAIS; SERVICOS DE HIDROJATEAMENTO",
  },
  { cnae: "8129000", descricao: "ESTERILIZACAO DE OBJETOS; SERVICO DE" },
  {
    cnae: "8129000",
    descricao:
      "LIMPEZA DE DUTOS DE VENTILACAO E REFRIGERACAO DE AR; SERVICO DE",
  },
  { cnae: "8129000", descricao: "ESTERILIZACAO HOSPITALAR; SERVICO DE" },
  { cnae: "8129000", descricao: "LIMPEZA DE CAIXA DE GORDURA; SERVICO DE" },
  { cnae: "8129000", descricao: "TRATAMENTO DE PISCINAS; SERVICOS DE" },
  {
    cnae: "8129000",
    descricao:
      "LIMPEZA EM CAMINHOES-TANQUE, EMBARCACOES, ONIBUS, TRENS, SERVICOS DE HIDROJATEAMENTO",
  },
  {
    cnae: "8129000",
    descricao: "LIMPEZA DE ACOSTAMENTO DE ESTRADA; ATIVIDADE DE",
  },
  { cnae: "8129000", descricao: "LIMPEZA DE CHAMINES DE FORNOS; SERVICO DE" },
  { cnae: "8129000", descricao: "LAVAGEM DE EMBALAGENS; SERVICO DE" },
  { cnae: "81303", descricao: "ATIVIDADES PAISAGISTICAS" },
  { cnae: "8130300", descricao: "JARDINAGEM; SERVICO DE" },
  {
    cnae: "8130300",
    descricao:
      "PODA DE ARVORES EM AREA DE TRANSMISSAO DE ENERGIA ELETRICA; SERVICOS DE",
  },
  { cnae: "8130300", descricao: "PODA E PLANTIO DE ARVORES NA AREA URBANA" },
  {
    cnae: "8130300",
    descricao:
      "PODA EM LINHAS DE TRANSMISSAO NA AREA RURAL E URBANA, SERVICOS DE",
  },
  { cnae: "8130300", descricao: "AREAS VERDES; MANUTENCAO DE" },
  { cnae: "8130300", descricao: "JARDINS; MANUTENCAO DE" },
  { cnae: "8130300", descricao: "GRAMADO DE FUTEBOL; MANUTENCAO DE" },
  { cnae: "8130300", descricao: "HIDROSSEMEADURA; SERVICOS DE PAISAGISMO" },
  { cnae: "8130300", descricao: "GRAMADO; PLANTIO DE" },
  { cnae: "8130300", descricao: "PLANTIO DE GRAMA PARA RECOMPOSICAO DE AREAS" },
  {
    cnae: "8130300",
    descricao:
      "PAISAGISMO,  LIMPEZA, MANUTENCAO, PLANTIO DE JARDINS; SERVICOS DE",
  },
  {
    cnae: "8130300",
    descricao:
      "PLANTIO, TRATAMENTO E MANUTENCAO DE JARDINS, GRAMADOS E PLANTAS PARA FINS PAISAGISTICOS; SERVICOS DE",
  },
  {
    cnae: "82113",
    descricao: "SERVICOS COMBINADOS DE ESCRITORIO E APOIO ADMINISTRATIVO",
  },
  {
    cnae: "8211300",
    descricao:
      "COMBINACAO DE SERVICOS DE RECEPCAO, PLANEJAMENTO FINANCEIRO, CONTABILIDADE, ARQUIVAMENTO, PREPARACAO DE MATERIAL PARA ENVIO POR CORREIO, ETC.; FORNECIMENTO DE",
  },
  { cnae: "8211300", descricao: "ORGANIZACAO DE ARQUIVOS; SERVICOS DE" },
  { cnae: "8211300", descricao: "CENTROS DE SERVICOS DE APOIO AS EMPRESAS" },
  { cnae: "8211300", descricao: "ESCRITORIO VIRTUAL; SERVICOS DE" },
  {
    cnae: "8211300",
    descricao:
      "CENTROS DE NEGOCIOS, APOIO OPERACIONAL A EMPRESAS OU A PROFISSIONAIS LIBERAIS",
  },
  { cnae: "8211300", descricao: "ARQUIVAMENTO DE DOCUMENTOS; SERVICOS DE" },
  {
    cnae: "8211300",
    descricao: "COWORKING; ESCRITORIOS COMPARTILHADOS; SERVICOS DE",
  },
  { cnae: "8211300", descricao: "PREPARO DE FOLHA DE PAGAMENTO; SERVICOS DE" },
  {
    cnae: "8211300",
    descricao:
      "ORGANIZACAO DE ARQUIVO DE DOCUMENTOS NO LOCAL DO CONTRATANTE; SERVICOS DE",
  },
  {
    cnae: "8211300",
    descricao: "SERVICOS ADMINISTRATIVOS COMBINADOS PARA TERCEIROS",
  },
  {
    cnae: "82199",
    descricao:
      "FOTOCOPIAS, PREPARACAO DE DOCUMENTOS E OUTROS SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO",
  },
  { cnae: "8219901", descricao: "COPIA FOTOSTATICA; SERVICO DE" },
  { cnae: "8219901", descricao: "REPROGRAFIA; SERVICOS DE" },
  { cnae: "8219901", descricao: "DIGITALIZACAO PARA REPRODUCAO DE FOTOS" },
  { cnae: "8219901", descricao: "DIGITALIZACAO PARA REPRODUCAO DE COPIAS" },
  { cnae: "8219901", descricao: "COPIA XEROGRAFICA; SERVICOS DE" },
  { cnae: "8219901", descricao: "XEROX; SERVICOS DE" },
  {
    cnae: "8219901",
    descricao: "SERVICO DE ENCADERNACAO COMBINADO COM A REPRODUCAO DE COPIAS",
  },
  { cnae: "8219901", descricao: "COPIA HELIOGRAFICA; SERVICO DE" },
  { cnae: "8219901", descricao: "REPRODUCAO DE DOCUMENTOS; SERVICO DE" },
  { cnae: "8219901", descricao: "COPIADORA; LOJA" },
  { cnae: "8219901", descricao: "IMPRESSAO A LASER; SERVICOS DE" },
  { cnae: "8219901", descricao: "PLOTAGEM; SERVICO DE" },
  { cnae: "8219901", descricao: "FOTOCOPIA; SERVICOS DE" },
  { cnae: "8219901", descricao: "XEROGRAFIAS; SERVICOS DE" },
  { cnae: "8219901", descricao: "FOTOPLOTAGEM; SERVICOS DE" },
  { cnae: "8219901", descricao: "XEROCOPIAS; SERVICOS DE" },
  { cnae: "8219901", descricao: "ESCANEAMENTO PARA REPRODUCAO DE COPIAS" },
  {
    cnae: "8219999",
    descricao:
      "ROTULACAO E DESPACHO DE ENCOMENDAS E DOCUMENTOS POR CORREIO; SERVICOS DE",
  },
  { cnae: "8219999", descricao: "EDITORACAO ELETRONICA; SERVICOS DE" },
  { cnae: "8219999", descricao: "ESTENOTIPIA; SERVICOS DE" },
  { cnae: "8219999", descricao: "DIGITACAO DE TEXTOS; SERVICO DE" },
  {
    cnae: "8219999",
    descricao: "DIGITACAO DE FATURAS, DOCUMENTOS, CARNES; SERVICOS DE",
  },
  {
    cnae: "8219999",
    descricao: "CONFERENCIA DE TEXTOS DIGITADOS POR TERCEIROS; SERVICO DE",
  },
  {
    cnae: "8219999",
    descricao: "CARTOES DE VISITA, CRACHAS; SERVICOS DE ELABORACAO DE",
  },
  {
    cnae: "8219999",
    descricao:
      "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO",
  },
  {
    cnae: "8219999",
    descricao: "ENVIO DE CORRESPONDENCIA POR MALA DIRETA; SERVICO DE",
  },
  { cnae: "8219999", descricao: "CARTAS E RESUMOS; REDACAO DE" },
  { cnae: "8219999", descricao: "SECRETARIA; SERVICOS DE" },
  {
    cnae: "8219999",
    descricao: "PREENCHIMENTO, SELAGEM E DESPACHO DE ENCOMENDAS; SERVICOS DE",
  },
  { cnae: "8219999", descricao: "DATILOGRAFIA; SERVICO DE" },
  { cnae: "8219999", descricao: "TRANSCRICAO DE DOCUMENTOS; SERVICO DE" },
  { cnae: "82202", descricao: "ATIVIDADES DE TELEATENDIMENTO" },
  {
    cnae: "8220200",
    descricao: "CONSULTA SOBRE PRODUTOS POR TELEFONE; SERVICOS DE",
  },
  {
    cnae: "8220200",
    descricao:
      "CENTRAL DE ATENDIMENTO POR TELEFONE; SERVICO PRESTADO POR TERCEIROS",
  },
  { cnae: "8220200", descricao: "CENTRAL DE RECADOS; SERVICO DE" },
  { cnae: "8220200", descricao: "CALL CENTER; SERVICO DE" },
  { cnae: "8220200", descricao: "CENTROS DE RECEPCAO DE CHAMADAS" },
  {
    cnae: "8220200",
    descricao: "PRESTACAO DE INFORMACOES POR TELEFONE; SERVICO DE",
  },
  { cnae: "8220200", descricao: "TELEATENDIMENTO (TELEMARKETING); SERVICO DE" },
  {
    cnae: "8220200",
    descricao: "SISTEMAS DE RESPOSTA VOCAL INTERATIVA; ATIVIDADES DE",
  },
  {
    cnae: "8220200",
    descricao: "SISTEMAS DE INTEGRACAO TELEFONE-COMPUTADOR; ATIVIDADES DE",
  },
  {
    cnae: "8220200",
    descricao:
      "ATENDIMENTO A CLIENTES POR TELEFONE; SERVICO PRESTADO POR TERCEIROS",
  },
  {
    cnae: "8220200",
    descricao:
      "ATENDIMENTO A CLIENTES - SAC POR TELEFONE, POR TERCEIROS; SERVICO DE",
  },
  { cnae: "8220200", descricao: "CONTACT CENTER; SERVICOS DE" },
  {
    cnae: "8220200",
    descricao:
      "CONTATOS TELEFONICOS, RECADOS; SERVICOS PRESTADOS POR TERCEIROS",
  },
  {
    cnae: "82300",
    descricao:
      "ATIVIDADES DE ORGANIZACAO DE EVENTOS, EXCETO CULTURAIS E ESPORTIVOS",
  },
  { cnae: "8230001", descricao: "ORGANIZACAO DE FESTAS INFANTIS; SERVICO DE" },
  { cnae: "8230001", descricao: "REMATES RURAIS; SERVICOS DE" },
  { cnae: "8230001", descricao: "PARQUE PARA FEIRAS AGROPECUARIAS; GESTAO DE" },
  { cnae: "8230001", descricao: "ORGANIZACAO DE FORMATURAS; SERVICO DE" },
  {
    cnae: "8230001",
    descricao: "ORGANIZACAO DE FESTAS FAMILIARES; SERVICO DE",
  },
  { cnae: "8230001", descricao: "EXPOSICAO DE ANIMAIS EM FEIRAS" },
  {
    cnae: "8230001",
    descricao:
      "ORGANIZACAO, PRODUCAO E PROMOCAO DE EVENTOS, EXCETO CULTURAIS E ESPORTIVOS; SERVICOS DE",
  },
  { cnae: "8230001", descricao: "ORGANIZACAO DE FESTAS; SERVICO DE" },
  { cnae: "8230001", descricao: "SHOW-ROOM; GESTAO DE" },
  {
    cnae: "8230001",
    descricao:
      "ORGANIZACAO, PRODUCAO E PROMOCAO DE FEIRAS E EXPOSICOES; SERVICOS DE",
  },
  { cnae: "8230001", descricao: "PARQUE DE LEILAO DE GADO; ORGANIZACAO DE" },
  {
    cnae: "8230001",
    descricao:
      "ORGANIZACAO, PRODUCAO E PROMOCAO DE ENCONTROS E CONGRESSOS; SERVICOS DE",
  },
  { cnae: "8230002", descricao: "CASA DE FESTAS; GESTAO DE" },
  { cnae: "8230002", descricao: "CASA DE RECEPCOES; GESTAO DE" },
  { cnae: "8230002", descricao: "INSTALACOES PARA EVENTOS; GESTAO DE" },
  { cnae: "8230002", descricao: "CASA DE EVENTOS, GESTAO DE" },
  { cnae: "8592903", descricao: "AULAS DE MUSICA; ATIVIDADE DE" },
  {
    cnae: "8230002",
    descricao: "ESPACO PARA EXPOSICAO, PARA USO DE TERCEIROS; GESTAO DE",
  },
  {
    cnae: "8230002",
    descricao: "ESPACOS PARA EVENTOS; ALUGUEL DE, LOCACAO DE",
  },
  {
    cnae: "82911",
    descricao: "ATIVIDADES DE COBRANCA E INFORMACOES CADASTRAIS",
  },
  { cnae: "8291100", descricao: "INFORMACOES CADASTRAIS; SERVICOS DE" },
  {
    cnae: "8291100",
    descricao: "ANALISE DE CADASTRO PARA APROVACAO DE CREDITO; SERVICOS DE",
  },
  { cnae: "8291100", descricao: "ANALISE E APROVACAO DE CREDITO; SERVICOS DE" },
  {
    cnae: "8291100",
    descricao: "COBRANCA DE FATURAS E DIVIDAS DE CLIENTES; ATIVIDADES DE",
  },
  {
    cnae: "8291100",
    descricao:
      "INFORMACOES SOBRE A CAPACIDADE DE ENDIVIDAMENTO DE PESSOAS E DE EMPRESAS; FORNECIMENTO DE",
  },
  {
    cnae: "8291100",
    descricao:
      "COMPILACAO DE INFORMACOES SOBRE HISTORICO DE CREDITO DE PESSOAS PARA EMPRESAS CLIENTES; ATIVIDADES DE",
  },
  {
    cnae: "8291100",
    descricao:
      "INFORMACOES PARA AVALIACAO DE CAPACIDADE DE CREDITO DE PESSOAS E EMPRESAS; SERVICO DE",
  },
  {
    cnae: "8291100",
    descricao:
      "CONSULTA SOBRE HISTORICO DE CREDITO DE PESSOAS POR TELEFONE; SERVICOS DE",
  },
  { cnae: "82920", descricao: "ENVASAMENTO E EMPACOTAMENTO SOB CONTRATO" },
  {
    cnae: "8292000",
    descricao: "EMBALADORA DE PRODUTOS SOLIDOS/LIQUIDOS POR CONTA DE TERCEIROS",
  },
  {
    cnae: "8292000",
    descricao: "REEMBALAMENTO DE PRODUTOS POR CONTA DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "8292000",
    descricao: "ENVELOPAGEM DE PRODUTOS POR CONTA DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "8292000",
    descricao: "ENVASE DE PRODUTOS POR CONTA DE TERCEIROS; SERVICO DE",
  },
  {
    cnae: "8292000",
    descricao:
      "ROTULAGEM E ETIQUETAGEM DE PRODUTOS POR CONTA DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "8292000",
    descricao: "ENVASAMENTO EM AEROSSOIS SOB CONTRATO; SERVICO DE",
  },
  {
    cnae: "8292000",
    descricao:
      "EMPACOTAMENTO DE PRODUTOS SOLIDOS POR CONTA DE TERCEIROS; SERVICO DE",
  },
  {
    cnae: "8292000",
    descricao: "REEMBALAGEM DE PRODUTOS POR CONTA DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "8292000",
    descricao: "ENVASILHAMENTO DE PRODUTOS POR CONTA DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "8292000",
    descricao: "ENCARTELAGEM DE PRODUTOS POR CONTA DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "8292000",
    descricao: "ENSACAGEM DE PRODUTOS POR CONTA DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "8292000",
    descricao:
      "EMPACOTADEIRA DE PRODUTOS SOLIDOS POR CONTA DE TERCEIROS; SERVICO DE",
  },
  {
    cnae: "8292000",
    descricao:
      "ACONDICIONAMENTO DE PRODUTOS SOLIDOS/LIQUIDOS POR CONTA DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "8292000",
    descricao: "EMPACOTAMENTO DE PREPARADOS FARMACEUTICOS; SERVICO DE",
  },
  {
    cnae: "8292000",
    descricao:
      "ENVASAMENTO E EMPACOTAMENTO POR CONTA DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "8292000",
    descricao:
      "EMBALAGEM E ETIQUETAGEM DE PRODUTOS POR CONTA DE TERCEIROS; SERVICO DE",
  },
  {
    cnae: "8292000",
    descricao:
      "ACONDICIONAMENTO, FRACIONAMENTO E EMPACOTAMENTO DE ARROZ, ALGODAO E FUMO, FORA DA UNIDADE AGRICOLA E NAO COMPLEMENTAR AO CULTIVO; SERVICOS DE",
  },
  { cnae: "8299799", descricao: "SONORIZACAO PARA TELEFONE; SERVICOS DE" },
  {
    cnae: "8292000",
    descricao:
      "ENGARRAFAMENTO DE PRODUTOS LIQUIDOS, POR CONTA DE TERCEIROS; SERVICO DE",
  },
  {
    cnae: "8292000",
    descricao:
      "BENEFICIAMENTO DE PRODUTOS AGRICOLAS, SEM TRANSFORMACAO QUIMICA OU FISICA, EXCETO ARROZ, ALGODAO E FUMO, FORA DA UNIDADE AGRICOLA E NAO COMPLEMENTAR AO CULTIVO; SERVICOS DE",
  },
  {
    cnae: "8292000",
    descricao:
      "EMBALAMENTO DE PRODUTOS SOLIDOS/LIQUIDOS POR CONTA DE TERCEIROS",
  },
  {
    cnae: "8292000",
    descricao: "ENCARTELAMENTO DE PRODUTOS POR CONTA DE TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "82997",
    descricao:
      "ATIVIDADES DE SERVICOS PRESTADOS PRINCIPALMENTE AS EMPRESAS NAO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "8299701", descricao: "RELIGACAO DE GAS; SERVICOS DE" },
  {
    cnae: "8299701",
    descricao: "LEITURA DE MEDIDORES DE ENERGIA, AGUA E LUZ; SERVICOS DE",
  },
  { cnae: "8299701", descricao: "LEITURA E ENTREGA DE CONTAS; SERVICOS DE" },
  {
    cnae: "8299701",
    descricao: "LEITURA DE RELOGIO DE ENERGIA/LUZ; SERVICOS DE",
  },
  { cnae: "8299701", descricao: "MEDICAO DE CONSUMO DE GAS; SERVICO DE" },
  { cnae: "8299701", descricao: "RELIGACAO DE ENERGIA ELETRICA; SERVICOS DE" },
  {
    cnae: "8299701",
    descricao: "LEITURA DE MEDIDORES DE GAS, LUZ E AGUA; SERVICOS DE",
  },
  {
    cnae: "8299701",
    descricao: "MEDICAO DE CONSUMO ENERGIA ELETRICA; SERVICO DE",
  },
  { cnae: "8299701", descricao: "RELIGACAO DE AGUA; SERVICOS DE" },
  { cnae: "8299701", descricao: "LEITURA DE HIDROMETRO; SERVICOS DE" },
  { cnae: "8299701", descricao: "MEDICAO DE CONSUMO DE AGUA; SERVICO DE" },
  { cnae: "8299701", descricao: "LIGACAO E CORTE DE GAS; SERVICOS DE" },
  { cnae: "8299701", descricao: "LIGACAO E CORTE DE AGUA; SERVICOS DE" },
  {
    cnae: "8299701",
    descricao: "LIGACAO E CORTE DE ENERGIA ELETRICA; SERVICOS DE",
  },
  { cnae: "8299702", descricao: "VALE REFEICAO; EMISSAO DE" },
  { cnae: "8299702", descricao: "TICKET FARMACIA, REMEDIO; EMISSAO DE" },
  { cnae: "8299702", descricao: "VALE ALIMENTACAO; ADMINISTRACAO DE" },
  { cnae: "8299702", descricao: "VALE ALIMENTACAO; EMISSAO DE" },
  { cnae: "8299702", descricao: "VALE COMBUSTIVEL; EMISSAO DE" },
  { cnae: "8299702", descricao: "TICKET ALIMENTACAO; ADMINISTRACAO DE" },
  { cnae: "8299702", descricao: "VALE TRANSPORTE; ADMINISTRACAO DE" },
  { cnae: "8299702", descricao: "VALE COMBUSTIVEL; ADMINISTRACAO DE" },
  { cnae: "8299702", descricao: "TICKET COMBUSTIVEL; ADMINISTRACAO DE" },
  { cnae: "8299702", descricao: "VALE RESTAURANTE; EMISSAO DE" },
  { cnae: "8299702", descricao: "VALE REFEICAO; ADMINISTRACAO DE" },
  { cnae: "8299702", descricao: "VALE RESTAURANTE; ADMINISTRACAO DE" },
  { cnae: "8299702", descricao: "TICKET RESTAURANTE; EMISSAO DE" },
  { cnae: "8299702", descricao: "TICKET REFEICAO; EMISSAO DE" },
  { cnae: "8299702", descricao: "TICKET REFEICAO; ADMINISTRACAO DE" },
  { cnae: "8299702", descricao: "TICKET COMBUSTIVEL; EMISSAO DE" },
  { cnae: "8299702", descricao: "TICKET RESTAURANTE; ADMINISTRACAO DE" },
  { cnae: "8299702", descricao: "VALE TRANSPORTE; EMISSAO DE" },
  { cnae: "8299702", descricao: "TICKET FARMACIA, REMEDIO; ADMINISTRACAO DE" },
  {
    cnae: "8299702",
    descricao:
      "VALES TRANSPORTE, BILHETE ELETRONICO DE TRANSPORTE; EMISSAO, ADMINISTRACAO",
  },
  { cnae: "8299702", descricao: "TICKET ALIMENTACAO; EMISSAO DE" },
  { cnae: "8299703", descricao: "GRAVACAO DE CARIMBOS; SERVICO DE" },
  { cnae: "8299704", descricao: "LEILOEIRO; SERVICO DE" },
  { cnae: "8299704", descricao: "LEILOEIRO DE VEICULOS; SERVICO DE" },
  {
    cnae: "8299704",
    descricao: "LEILAO, LEILOES; SERVICOS DE LEILOEIROS INDEPENDENTES",
  },
  { cnae: "8299704", descricao: "LEILOEIRO INDEPENDENTE; SERVICO DE" },
  { cnae: "8299704", descricao: "LEILOEIRO VIA INTERNET; ATIVIDADE DE" },
  {
    cnae: "8299705",
    descricao: "LEVANTAMENTO DE FUNDOS SOB CONTRATO; SERVICO DE",
  },
  {
    cnae: "8299705",
    descricao: "LEVANTAMENTO DE FUNDOS PARA TERCEIROS; SERVICO DE",
  },
  {
    cnae: "8299705",
    descricao:
      "LEVANTAMENTO DE FUNDOS PARA ORGANIZACOES FILANTROPICAS; SERVICO DE",
  },
  { cnae: "8299706", descricao: "SENA ESPORTIVA; VENDA DE BILHETES DE" },
  { cnae: "8299706", descricao: "JOGO DA SENA; VENDA DE BILHETES DE" },
  { cnae: "8299706", descricao: "QUINA ESPORTIVA; VENDA DE BILHETES DE" },
  { cnae: "8299706", descricao: "VENDA DE BILHETES DE LOTERIA" },
  { cnae: "8299706", descricao: "RASPADINHAS; VENDA DE" },
  { cnae: "8299706", descricao: "LOTERIA ESPORTIVA; ATIVIDADE DE" },
  { cnae: "8299706", descricao: "CASA LOTERICA" },
  {
    cnae: "8299706",
    descricao:
      "CONTAS DE TELEFONICA, GAS, LUZ, AGUA, ESGOTO,  E DE OUTROS TITULOS DE VALORES QUANDO REALIZADO POR CORRESPONDENTE BANCARIO; RECEBIMENTO DE",
  },
  { cnae: "8299706", descricao: "CONCESSIONARIA DE LOTERIAS; ATIVIDADE DE" },
  { cnae: "8299706", descricao: "BILHETES DE LOTERIAS; VENDA DE" },
  { cnae: "8299706", descricao: "JOGO DA LOTO; VENDA DE BILHETES DE" },
  { cnae: "8299706", descricao: "JOGOS DE SORTE E APOSTAS; VENDA DE BILHETES" },
  {
    cnae: "8299707",
    descricao: "ACESSO A INTERNET MEDIANTE O USO DE COMPUTADORES E PERIFERICOS",
  },
  { cnae: "8299707", descricao: "POSTO TELEFONICO" },
  {
    cnae: "8299707",
    descricao:
      "COMPUTADORES E PERIFERICOS PARA USO TEMPORARIO PREDOMINANTE PARA APOIO ADMINISTRATIVO OU ESCRITORIO; DISPONIBILIZACAO DE",
  },
  {
    cnae: "8299707",
    descricao:
      "LAN HOUSE COM ACESSO A INTERNET PREDOMINANTE PARA APOIO ADMINISTRATIVO OU ESCRITORIO",
  },
  {
    cnae: "8299707",
    descricao:
      "ACESSO A INTERNET COMBINADO COM FAX , DIGITACAO, ESCANEAMENTO, ETC; SALA DE",
  },
  {
    cnae: "8299707",
    descricao:
      "ACESSO A INTERNET PARA APOIO ADMINISTRATIVO OU ESCRITORIO; SALA DE",
  },
  { cnae: "8299799", descricao: "DESPACHANTE DOCUMENTALISTA; SERVICOS DE" },
  {
    cnae: "8299799",
    descricao: "REPOSICAO DE MERCADORIAS EM SUPERMERCADOS; SERVICOS DE",
  },
  { cnae: "8299799", descricao: "DESPACHANTES, EXCETO ADUANEIROS; SERVICO DE" },
  { cnae: "8299799", descricao: "INVENTARIO DE BENS; SERVICO DE" },
  { cnae: "8299799", descricao: "MANUTENCAO DE AQUARIOS; SERVICO DE" },
  { cnae: "8299799", descricao: "BOMBEIRO CIVIL; ATIVIDADES DE" },
  { cnae: "8299799", descricao: "FUNDOS DE FORMATURA; ADMINISTRACAO DE" },
  { cnae: "8299799", descricao: "INVENTARIOS DE ESTOQUES; SERVICO DE" },
  {
    cnae: "8299799",
    descricao:
      "REPOSICAO DE PRODUTOS EM SUPERMERCADOS, ESTABELECIMENTOS COMERCIAIS; SERVICOS DE",
  },
  {
    cnae: "8299799",
    descricao:
      "INTERMEDIACAO NA VENDA DE ASSINATURA DE TELEVISAO POR CABO, POR SATELITE OU POR MICROONDAS",
  },
  { cnae: "8299799", descricao: "ALMOXARIFADO; SERVICO DE" },
  {
    cnae: "8299799",
    descricao: "REPOSICAO DE MERCADORIAS EXTERNAS PARA VENDA; SERVICOS DE",
  },
  { cnae: "8299799", descricao: "ARRUMACAO DE ESTOQUE; SERVICO DE" },
  {
    cnae: "8299799",
    descricao:
      "ADESIVAMENTO, ENVELOPAMENTO DE VEICULOS PARA FINS PUBLICITARIOS, PROPAGANDA; SERVICOS DE",
  },
  { cnae: "8299799", descricao: "COMPUTACAO GRAFICA; SERVICOS DE" },
  {
    cnae: "8299799",
    descricao: "DISTRIBUICAO DE DIARIO OFICIAL DA UNIAO; SERVICO DE",
  },
  {
    cnae: "8299799",
    descricao: "ADMINISTRACAO DE CARTAO CONVENIO; SERVICOS DE",
  },
  {
    cnae: "8299799",
    descricao:
      "ADMINISTRACAO DE PROGRAMAS DE FIDELIDADE, RELACIONAMENTO; SERVICOS DE",
  },
  {
    cnae: "8299799",
    descricao:
      "CAPTACAO DE IMAGENS DE REUNIOES E CONFERENCIAS AO VIVO (VIDEOCONFERENCIA); SERVICO DE",
  },
  { cnae: "8299799", descricao: "REPOSICAO DE ESTOQUES; SERVICO DE" },
  {
    cnae: "8299799",
    descricao: "MONTAGEM, COLOCACAO DE ENCARTES EM JORNAIS; SERVICOS DE",
  },
  {
    cnae: "8299799",
    descricao:
      "ESCRITORIO DE REPRESENTACAO - FILIAL DE EMPRESA ESTRANGEIRA, EXCETO DE BANCOS ESTRANGEIROS",
  },
  { cnae: "8299799", descricao: "PINTURA DE FAIXAS; SERVICO DE" },
  {
    cnae: "8299799",
    descricao: "AVALIADORES, EXCETO DE SEGUROS E IMOVEIS; SERVICO DE",
  },
  {
    cnae: "8299799",
    descricao: "LEGALIZACAO DE AUTOMOVEIS; SERVICOS DE DESPACHANTES",
  },
  {
    cnae: "8299799",
    descricao: "ADESIVAMENTO PARA FINS PUBLICITARIOS, PROPAGANDA; SERVICOS DE",
  },
  { cnae: "8299799", descricao: "CARTAZISTA; SERVICO DE" },
  {
    cnae: "8299799",
    descricao: "PREVENCAO DE INCENDIO POR EMPRESA PRIVADA; SERVICO DE",
  },
  { cnae: "8299799", descricao: "ESTENOGRAFIA; SERVICO DE" },
  {
    cnae: "8299799",
    descricao:
      "REPOSITOR DE MERCADORIAS EM ESTABELECIMENTOS COMERCIAIS; SERVICOS DE",
  },
  {
    cnae: "8299799",
    descricao: "OPERACAO DE RADARES PARA ORGAOS PUBLICOS; SERVICOS DE",
  },
  {
    cnae: "8299799",
    descricao: "LEGALIZACAO DE VEICULOS; SERVICOS DE DESPACHANTES",
  },
  { cnae: "8299799", descricao: "CONTROLE DE ESTOQUES; SERVICO DE" },
  { cnae: "8299799", descricao: "COLETA DE BOTIJAO DE GAS; SERVICO DE" },
  {
    cnae: "8299799",
    descricao: "POSTO, AGENCIA DE COLETA DE ANUNCIOS DE JORNAIS INDEPENDENTE",
  },
  {
    cnae: "8299799",
    descricao: "BRIGADA DE INCENDIO DE EMPRESA PRIVADA; SERVICOS DE",
  },
  { cnae: "8299799", descricao: "PINTURA DE LETRAS; SERVICO DE" },
  { cnae: "8299799", descricao: "ADMINISTRACAO DE CARTOES DE DESCONTO" },
  { cnae: "8299799", descricao: "MANUSEIO DE DOCUMENTOS; SERVICOS DE" },
  {
    cnae: "8299799",
    descricao:
      "VISTORIA DE AUTOMOVEIS PARA VENDA, AVALIACAO, SEGURO, DOCUMENTAL OU LEGALIZACAO; SERVICOS DE",
  },
  {
    cnae: "8299799",
    descricao:
      "POSTO, AGENCIA EM ESTABELECIMENTOS COMERCIAIS PARA PAGAMENTO DE CONTAS DE LUZ, GAS, ETC.",
  },
  { cnae: "8299799", descricao: "TAQUIGRAFIA; SERVICO DE" },
  {
    cnae: "8299799",
    descricao: "TITULOS DE CLUBES; PROMOCAO, VENDA SOB CONTRATO",
  },
  {
    cnae: "8299799",
    descricao:
      "GRAVACAO DO NUMERO DO CHASSI EM PECAS DE VEICULOS AUTOMOTORES; SERVICOS DE",
  },
  {
    cnae: "8299799",
    descricao:
      "INTERMEDIACAO NA COMPRA E VENDA DE PONTOS OU MILHAS OU SIMILARES ADQUIRIDOS EM PROGRAMAS DE FIDELIDADE, RELACIONAMENTOS; SERVICOS DE",
  },
  {
    cnae: "8299799",
    descricao: "LEGALIZACAO DE MOTOS; SERVICOS DE DESPACHANTES",
  },
  { cnae: "8299799", descricao: "DESTROCA DE BOTIJOES DE GAS; SERVICOS DE" },
  { cnae: "84116", descricao: "ADMINISTRACAO PUBLICA EM GERAL" },
  {
    cnae: "8411600",
    descricao: "SECRETARIA DE GOVERNO ESTADUAL; SUPERVISAO E COORDENACAO",
  },
  { cnae: "8411600", descricao: "ASSEMBLEIA LEGISLATIVA; ESTADUAL, MUNICIPAL" },
  {
    cnae: "8411600",
    descricao:
      "ADMINISTRACAO E GESTAO DO PATRIMONIO E GASTOS GERAIS; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  { cnae: "8411600", descricao: "ADMINISTRACAO TRIBUTARIA" },
  {
    cnae: "8411600",
    descricao: "PROCURADORIA DE ESTADO; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8411600",
    descricao:
      "GESTAO DE FUNDOS MUNICIPAIS, EXCETO DE FUNDOS DE INVESTIMENTOS; SERVICOS DE",
  },
  {
    cnae: "8411600",
    descricao: "SECRETARIA DE GOVERNO MUNICIPAL; SUPERVISAO E COORDENACAO",
  },
  {
    cnae: "8411600",
    descricao: "ADMINISTRACAO E GESTAO DE PESSOAL; ADMINISTRACAO PUBLICA",
  },
  {
    cnae: "8411600",
    descricao:
      "ADMINISTRACAO DA POLITICA ECONOMICA E FISCAL; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8411600",
    descricao: "PLANEJAMENTO SOCIAL E ECONOMICO; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8411600",
    descricao: "SUPERINTENDENCIA DE SEGUROS PRIVADOS - SUSEP",
  },
  { cnae: "8411600", descricao: "ADMINISTRACAO DE TARIFAS ADUANEIRAS" },
  { cnae: "8411600", descricao: "GESTAO DE RECURSOS PUBLICOS" },
  { cnae: "8411600", descricao: "ADMINISTRACAO PUBLICA EM GERAL" },
  { cnae: "8411600", descricao: "OUVIDORIA PUBLICA; SERVICOS DE" },
  { cnae: "8411600", descricao: "GABINETE DA VICE-PRESIDENCIA DA REPUBLICA" },
  { cnae: "8411600", descricao: "GESTAO DA DIVIDA PUBLICA, EXTERNA E INTERNA" },
  { cnae: "8411600", descricao: "CASA CIVIL" },
  {
    cnae: "8411600",
    descricao: "ARRECADACAO DE IMPOSTOS E TAXAS; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8411600",
    descricao: "ADMINISTRACAO ORCAMENTARIA; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  { cnae: "8411600", descricao: "MINISTERIO; SUPERVISAO E COORDENACAO" },
  { cnae: "8411600", descricao: "ADMINISTRACAO DA DIVIDA PUBLICA" },
  { cnae: "8411600", descricao: "GABINETE CIVIL" },
  { cnae: "8411600", descricao: "ADMINISTRACAO ALFANDEGARIA" },
  { cnae: "8411600", descricao: "JUSTICA ELEITORAL; ATIVIDADE DA" },
  { cnae: "8411600", descricao: "ADVOCACIA GERAL DA UNIAO" },
  { cnae: "8411600", descricao: "COMISSAO DE VALORES MOBILIARIOS, CVM" },
  { cnae: "8411600", descricao: "DEFENSORIA PUBLICA DA UNIAO" },
  { cnae: "8411600", descricao: "PLANEJAMENTO SOCIAL E ECONOMICO" },
  { cnae: "8411600", descricao: "SENADO FEDERAL" },
  { cnae: "8411600", descricao: "ESTATISTICAS; FEDERAL, ESTADUAL, MUNICIPAL" },
  { cnae: "8411600", descricao: "SERVICOS DE OUVIDORIA PUBLICA" },
  { cnae: "8411600", descricao: "FUNDACAO DE APOIO A PESQUISA E EXTENSAO" },
  {
    cnae: "8411600",
    descricao: "FINANCAS PUBLICAS; ADMINISTRACAO FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8411600",
    descricao: "TRIBUNAL DE CONTAS DA UNIAO, ESTADO, MUNICIPIO",
  },
  { cnae: "8411600", descricao: "ATIVIDADES DA JUSTICA ELEITORAL" },
  { cnae: "8411600", descricao: "CAMARA DOS DEPUTADOS; FEDERAL" },
  { cnae: "8411600", descricao: "GABINETE MILITAR" },
  { cnae: "8411600", descricao: "GABINETE DA PRESIDENCIA DA REPUBLICA" },
  { cnae: "8411600", descricao: "INSPECAO SANITARIA; SERVICOS DE" },
  {
    cnae: "8411600",
    descricao:
      "INFORMACAO GEOGRAFICA E ESTATISTICA; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  { cnae: "8411600", descricao: "CONGRESSO NACIONAL" },
  { cnae: "8411600", descricao: "INSPECAO SANITARIA, ATIVIDADE DE" },
  {
    cnae: "84124",
    descricao:
      "REGULACAO DAS ATIVIDADES DE SAUDE, EDUCACAO, SERVICOS CULTURAIS E OUTROS SERVICOS SOCIAIS",
  },
  {
    cnae: "8412400",
    descricao:
      "CONTROLE DA POLUICAO DA AGUA; ADMINISTRACAO FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao: "PROGRAMAS DE AUXILIO GAS, FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao:
      "REGULACAO, CONTROLE, DEFINICAO DE POLITICA E COORDENACAO DE ACOES SOCIAIS; ADMINISTRACAO FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao:
      "REGULACAO, CONTROLE, DEFINICAO DE POLITICA E COORDENACAO DOS SERVICOS URBANOS; ADMINISTRACAO FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao:
      "REGULACAO, CONTROLE, DEFINICAO DE POLITICA E COORDENACAO DA SAUDE; ADMINISTRACAO FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao:
      "DESENVOLVIMENTO DE PROGRAMA DE FOMENTO A CULTURA; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao: "REGULACAO DE PROGRAMAS DE ABASTECIMENTO DE AGUA POTAVEL",
  },
  {
    cnae: "8412400",
    descricao:
      "PROGRAMA DE CONCESSAO DE BOLSAS DE ESTUDO; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao: "PROMOCAO DE PROGRAMAS DE SAUDE; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao:
      "REGULACAO, CONTROLE, DEFINICAO DE POLITICA E COORDENACAO DA CULTURA; ADMINISTRACAO FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao:
      "REGULACAO, CONTROLE, DEFINICAO DE POLITICA E COORDENACAO DO ESPORTE E LAZER; ADMINISTRACAO FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao: "REGULACAO DAS OPERACOES DE COLETA E DISPOSICAO DE RESIDUOS",
  },
  {
    cnae: "8412400",
    descricao: "PROGRAMA DE SAUDE COMUNITARIA; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao: "DISTRIBUICAO DE FINANCIAMENTO PUBLICO A ARTISTAS",
  },
  {
    cnae: "8412400",
    descricao:
      "REGULACAO, CONTROLE, DEFINICAO DE POLITICA E COORDENACAO DA HABITACAO; ADMINISTRACAO FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao:
      "REGULACAO, CONTROLE, DEFINICAO DE POLITICA E COORDENACAO DO MEIO AMBIENTE; ADMINISTRACAO FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao:
      "REGULACAO DAS ATIVIDADES SOCIAIS E CULTURAIS; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao: "PROGRAMAS DE ERRADICACAO DA FOME, FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao: "PROGRAMA DE SAUDE DENTAL; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao: "ADMINISTRACAO DE ARTE E CULTURA; ADMINISTRACAO PUBLICA",
  },
  {
    cnae: "8412400",
    descricao:
      "REGULACAO, CONTROLE, DEFINICAO DE POLITICA E COORDENACAO DE SANEAMENTO BASICO; ADMINISTRACAO FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8412400",
    descricao:
      "REGULACAO, CONTROLE, DEFINICAO DE POLITICA E COORDENACAO DA EDUCACAO; ADMINISTRACAO FEDERAL, ESTADUAL, MUNICIPAL",
  },
  { cnae: "84132", descricao: "REGULACAO DAS ATIVIDADES ECONOMICAS" },
  {
    cnae: "8413200",
    descricao:
      "REGULAMENTACAO E FISCALIZACAO DAS QUESTOES ECONOMICAS RELATIVAS AO USO DO SOLO; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8413200",
    descricao:
      "REGULAMENTACAO E FISCALIZACAO DAS QUESTOES ECONOMICAS NOS TRANSPORTES; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8413200",
    descricao: "PROMOCAO DE INCENTIVOS A DIFERENTES SETORES ECONOMICOS",
  },
  { cnae: "8413200", descricao: "LICENCA E INSPECAO DE VEICULOS; ESTADUAL" },
  {
    cnae: "8413200",
    descricao:
      "REGULAMENTACAO E FISCALIZACAO DAS QUESTOES ECONOMICAS NOS SERVICOS DE ALIMENTACAO E ALOJAMENTO; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8413200",
    descricao:
      "DEFINICAO DE POLITICAS DE DESENVOLVIMENTO REGIONAIS OU SETORIAIS",
  },
  {
    cnae: "8413200",
    descricao:
      "REGULACAO DAS ATIVIDADES ECONOMICAS; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8413200",
    descricao:
      "REGULAMENTACAO E FISCALIZACAO DAS QUESTOES ECONOMICAS NAS COMUNICACOES; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8413200",
    descricao: "REGISTRO DE EMPRESAS; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8413200",
    descricao:
      "REGULAMENTACAO E FISCALIZACAO DAS QUESTOES ECONOMICAS NOS RECURSOS ENERGETICOS E MINERAIS; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8413200",
    descricao:
      "REGULAMENTACAO E FISCALIZACAO DAS QUESTOES ECONOMICAS NA AGRICULTURA; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8413200",
    descricao:
      "REGULAMENTACAO E FISCALIZACAO DAS QUESTOES ECONOMICAS NA INFRA-ESTRUTURA; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8413200",
    descricao:
      "DEFINICAO DE POLITICAS DE PRESERVACAO E PROTECAO DO MEIO AMBIENTE",
  },
  { cnae: "8413200", descricao: "REGULACAO DO DIREITO DE PATENTE; FEDERAL" },
  { cnae: "8413200", descricao: "FISCALIZACAO DE PESOS E MEDIDAS; FEDERAL" },
  {
    cnae: "8413200",
    descricao: "ORGANIZACOES DE PROTECAO E DEFESA AO CONSUMIDOR",
  },
  {
    cnae: "8413200",
    descricao:
      "REGULAMENTACAO E FISCALIZACAO DAS QUESTOES ECONOMICAS NO TURISMO; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8413200",
    descricao: "REGULAMENTACAO DAS DIRETRIZES SOBRE O TRANSITO; FEDERAL",
  },
  {
    cnae: "8413200",
    descricao:
      "REGULAMENTACAO E FISCALIZACAO DO MERCADO DE TRABALHO; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  {
    cnae: "8413200",
    descricao:
      "REGULAMENTACAO E FISCALIZACAO DAS QUESTOES ECONOMICAS NO COMERCIO; FEDERAL, ESTADUAL, MUNICIPAL",
  },
  { cnae: "84213", descricao: "RELACOES EXTERIORES" },
  {
    cnae: "8421300",
    descricao: "CONSULADO NO EXTERIOR; ADMINISTRACAO E GESTAO",
  },
  { cnae: "8421300", descricao: "COOPERACAO ECONOMICA COM OUTROS PAISES" },
  { cnae: "8421300", descricao: "SERVICO CULTURAL NO EXTERIOR" },
  { cnae: "8421300", descricao: "SERVICOS CULTURAIS MANTIDOS NO EXTERIOR" },
  {
    cnae: "8421300",
    descricao: "MINISTERIO DAS RELACOES EXTERIORES; ADMINISTRACAO E GESTAO",
  },
  {
    cnae: "8421300",
    descricao: "FORNECIMENTO DE AJUDA MILITAR A PAISES ESTRANGEIROS",
  },
  {
    cnae: "8421300",
    descricao: "ASSISTENCIA INTERNACIONAL; ADMINISTRACAO FEDERAL",
  },
  { cnae: "8421300", descricao: "MISSAO DIPLOMATICA; ADMINISTRACAO E GESTAO" },
  { cnae: "8421300", descricao: "GESTAO DO COMERCIO EXTERIOR" },
  { cnae: "8421300", descricao: "RELACOES EXTERIORES" },
  { cnae: "84221", descricao: "DEFESA" },
  { cnae: "8422100", descricao: "ATIVIDADES DE INTELIGENCIA MILITAR" },
  {
    cnae: "8422100",
    descricao: "MINISTERIOS MILITARES; ATIVIDADES ADMINISTRATIVAS",
  },
  { cnae: "8422100", descricao: "ABASTECIMENTO DAS FORCAS ARMADAS" },
  { cnae: "8422100", descricao: "MINISTERIO DA DEFESA" },
  { cnae: "8422100", descricao: "MINISTERIOS MILITARES" },
  { cnae: "8422100", descricao: "ATIVIDADES DE LOGISTICA MILITAR" },
  { cnae: "8422100", descricao: "ATIVIDADES DE ENGENHARIA MILITAR" },
  { cnae: "8422100", descricao: "ADMINISTRACAO E GESTAO DAS FORCAS ARMADAS" },
  {
    cnae: "8422100",
    descricao: "ADMINISTRACAO E GESTAO DAS ATIVIDADES DE DEFESA NACIONAL",
  },
  { cnae: "84230", descricao: "JUSTICA" },
  { cnae: "8423000", descricao: "JUSTICA FEDERAL" },
  { cnae: "8423000", descricao: "SERVICOS DE REABILITACAO SOCIAL" },
  { cnae: "8423000", descricao: "SUPREMO TRIBUNAL FEDERAL" },
  { cnae: "8423000", descricao: "GESTAO DE PENITENCIARIAS, PRESIDIOS" },
  { cnae: "8423000", descricao: "JUSTICA DO TRABALHO" },
  { cnae: "8423000", descricao: "SECRETARIAS DE JUSTICA ESTADUAIS" },
  { cnae: "8423000", descricao: "TRIBUNAL SUPERIOR DO TRABALHO" },
  { cnae: "8423000", descricao: "JUSTICA" },
  { cnae: "8423000", descricao: "TRIBUNAL DE JUSTICA" },
  { cnae: "8423000", descricao: "ADMINISTRACAO DE REFORMATORIO" },
  { cnae: "8423000", descricao: "JUSTICA MILITAR" },
  { cnae: "8423000", descricao: "MINISTERIO DA JUSTICA" },
  {
    cnae: "8423000",
    descricao: "ADMINISTRACAO TERCEIRIZADA DE PENITENCIARIAS; SERVICO DE",
  },
  { cnae: "8423000", descricao: "SUPERIOR TRIBUNAL DE JUSTICA" },
  { cnae: "8423000", descricao: "ADMINISTRACAO DO SISTEMA JUDICIAL" },
  { cnae: "8423000", descricao: "JUIZADO ESPECIAL DE PEQUENAS CAUSAS" },
  { cnae: "84248", descricao: "SEGURANCA E ORDEM PUBLICA" },
  {
    cnae: "8424800",
    descricao: "ADMINISTRACAO E FUNCIONAMENTO DA POLICIA MILITAR",
  },
  {
    cnae: "8424800",
    descricao: "ADMINISTRACAO E FUNCIONAMENTO DA POLICIA ESTADUAL",
  },
  {
    cnae: "8424800",
    descricao: "ADMINISTRACAO E FUNCIONAMENTO DA POLICIA PORTUARIA",
  },
  {
    cnae: "8424800",
    descricao: "ADMINISTRACAO E FUNCIONAMENTO DA POLICIA CIVIL",
  },
  {
    cnae: "8424800",
    descricao: "ADMINISTRACAO E FUNCIONAMENTO DA POLICIA DE TRANSITO",
  },
  {
    cnae: "8424800",
    descricao: "ADMINISTRACAO E FUNCIONAMENTO DA POLICIA FLORESTAL",
  },
  {
    cnae: "8424800",
    descricao: "ADMINISTRACAO E FUNCIONAMENTO DA POLICIA FEDERAL",
  },
  { cnae: "8424800", descricao: "SECRETARIA DE SEGURANCA ESTADUAL, MUNICIPAL" },
  {
    cnae: "8424800",
    descricao: "ADMINISTRACAO E FUNCIONAMENTO DA POLICIA RODOVIARIA",
  },
  { cnae: "8424800", descricao: "SEGURANCA E ORDEM PUBLICA" },
  {
    cnae: "8424800",
    descricao: "ADMINISTRACAO E FUNCIONAMENTO DA GUARDA MUNICIPAL",
  },
  {
    cnae: "8424800",
    descricao: "ADMINISTRACAO E FUNCIONAMENTO DA POLICIA MUNICIPAL",
  },
  { cnae: "84256", descricao: "DEFESA CIVIL" },
  {
    cnae: "8425600",
    descricao: "SERVICO DE LANCHA CONTRA INCENDIO; ADMINISTRACAO PUBLICA",
  },
  { cnae: "8425600", descricao: "DEFESA CIVIL" },
  { cnae: "8425600", descricao: "SALVA-VIDAS; CORPO DE BOMBEIROS" },
  {
    cnae: "8425600",
    descricao: "DIRECAO E FUNCIONAMENTO DO CORPO DE BOMBEIROS",
  },
  {
    cnae: "8425600",
    descricao:
      "ORGANIZACAO DE ASSISTENCIA EM CATASTROFES; ADMINISTRACAO PUBLICA",
  },
  {
    cnae: "8425600",
    descricao: "PREVENCAO E EXTINCAO DE INCENDIOS; ADMINISTRACAO PUBLICA",
  },
  { cnae: "84302", descricao: "SEGURIDADE SOCIAL OBRIGATORIA" },
  { cnae: "8430200", descricao: "PREVIDENCIA SOCIAL OBRIGATORIA" },
  {
    cnae: "8430200",
    descricao: "GESTAO, FINANCIAMENTO E CONCESSAO DE AUXILIO NATALIDADE",
  },
  {
    cnae: "8430200",
    descricao: "GESTAO, FINANCIAMENTO E CONCESSAO DE BENEFICIOS DE INVALIDEZ",
  },
  { cnae: "8430200", descricao: "CONCESSAO DE AUXILIO DESEMPREGO" },
  {
    cnae: "8430200",
    descricao: "GESTAO, FINANCIAMENTO E CONCESSAO DE AUXILIO-DOENCA",
  },
  {
    cnae: "8430200",
    descricao:
      "GESTAO, FINANCIAMENTO E CONCESSAO DE BENEFICIOS DE APOSENTADORIA",
  },
  { cnae: "8430200", descricao: "MINISTERIO DA PREVIDENCIA SOCIAL" },
  { cnae: "8430200", descricao: "SEGURIDADE SOCIAL" },
  {
    cnae: "8430200",
    descricao: "GESTAO, FINANCIAMENTO E CONCESSAO DE AUXILIO FUNERAL",
  },
  { cnae: "85112", descricao: "EDUCACAO INFANTIL  CRECHE" },
  {
    cnae: "8511200",
    descricao: "EDUCACAO INFANTIL - CRECHE - ENSINO DE LIBRA",
  },
  { cnae: "8511200", descricao: "CRECHES ASSISTENCIAIS" },
  { cnae: "8511200", descricao: "BERCARIO; SERVICOS DE" },
  { cnae: "8511200", descricao: "EDUCACAO INFANTIL - CRECHE; ENSINO ESPECIAL" },
  { cnae: "8511200", descricao: "EDUCACAO INFANTIL - CRECHE" },
  { cnae: "8511200", descricao: "CRECHE; SERVICO DE" },
  { cnae: "85121", descricao: "EDUCACAO INFANTIL  PREESCOLA" },
  { cnae: "8512100", descricao: "ESCOLA DE ENSINO PRE-ESCOLAR ESPECIAL" },
  {
    cnae: "8512100",
    descricao: "EDUCACAO INFANTIL - PRE-ESCOLA, ENSINO DE LIBRA",
  },
  {
    cnae: "8512100",
    descricao:
      "CLASSES DE ALFABETIZACAO - CA; SERVICO PRESTADO POR ESCOLA MATERNAL",
  },
  { cnae: "8512100", descricao: "ENSINO PRE-ESCOLAR" },
  { cnae: "8512100", descricao: "JARDIM DE INFANCIA PUBLICO OU PARTICULAR" },
  { cnae: "8512100", descricao: "ENSINO PRE-ESCOLAR PUBLICO OU PARTICULAR" },
  { cnae: "8512100", descricao: "ESCOLA MATERNAL" },
  { cnae: "8512100", descricao: "EDUCACAO PRE-ESCOLAR ESPECIAL" },
  { cnae: "8512100", descricao: "ENSINO PRE-ESCOLAR ESPECIAL" },
  {
    cnae: "8512100",
    descricao: "EDUCACAO INFANTIL - PRE-ESCOLA, ENSINO ESPECIAL",
  },
  { cnae: "8512100", descricao: "EDUCACAO PRE-ESCOLAR" },
  { cnae: "8512100", descricao: "EDUCACAO INFANTIL - PRE-ESCOLA" },
  { cnae: "85139", descricao: "ENSINO FUNDAMENTAL" },
  { cnae: "8513900", descricao: "ESCOLA DE ENSINO FUNDAMENTAL COMPLETO" },
  { cnae: "8513900", descricao: "EDUCACAO FUNDAMENTAL ESPECIAL" },
  { cnae: "8513900", descricao: "ENSINO FUNDAMENTAL POR CORRESPONDENCIA" },
  { cnae: "8513900", descricao: "ENSINO FUNDAMENTAL" },
  {
    cnae: "8513900",
    descricao:
      "CLASSES DE ALFABETIZACAO - CA; SERVICO PRESTADO POR ESTABELECIMENTO DE EDUCACAO FUNDAMENTAL",
  },
  {
    cnae: "8513900",
    descricao: "EDUCACAO INTENSIVA FUNDAMENTAL; PUBLICA OU PARTICULAR",
  },
  {
    cnae: "8513900",
    descricao: "ENSINO DE ALFABETIZACAO DE ADULTOS NIVEL FUNDAMENTAL",
  },
  {
    cnae: "8513900",
    descricao: "ESCOLA DE SEXTO AO NONO ANO DO ENSINAMENTO FUNDAMENTAL",
  },
  { cnae: "8513900", descricao: "ENSINO FUNDAMENTAL PUBLICO OU PARTICULAR" },
  { cnae: "8513900", descricao: "ESCOLA DE ENSINO FUNDAMENTAL ESPECIAL" },
  {
    cnae: "8513900",
    descricao: "EDUCACAO SUPLETIVA FUNDAMENTAL; PUBLICA OU PARTICULAR",
  },
  {
    cnae: "8513900",
    descricao: "EDUCACAO COMPACTADA FUNDAMENTAL; PUBLICA OU PARTICULAR",
  },
  { cnae: "8513900", descricao: "EDUCACAO FUNDAMENTAL" },
  { cnae: "8513900", descricao: "ENSINO SUPLETIVO FUNDAMENTAL" },
  {
    cnae: "8513900",
    descricao: "ESCOLA DE SEXTO AO NONO ANO DE EDUCACAO FUNDAMENTAL",
  },
  { cnae: "8513900", descricao: "ENSINO FUNDAMENTAL; ENSINO DE LIBRA" },
  { cnae: "8513900", descricao: "ENSINO FUNDAMENTAL A DISTANCIA" },
  {
    cnae: "8513900",
    descricao: "ESCOLA DE PRIMEIRO AO QUINTO ANO DE EDUCACAO FUNDAMENTAL",
  },
  { cnae: "8513900", descricao: "ENSINO FUNDAMENTAL ESPECIAL" },
  { cnae: "85201", descricao: "ENSINO MEDIO" },
  {
    cnae: "8520100",
    descricao: "EDUCACAO MEDIA DE FORMACAO GERAL; PUBLICA OU PARTICULAR",
  },
  {
    cnae: "8520100",
    descricao: "EDUCACAO COMPACTADA MEDIA; PUBLICA OU PARTICULAR",
  },
  {
    cnae: "8520100",
    descricao: "ENSINO MEDIO DE FORMACAO GERAL; PUBLICO OU PARTICULAR",
  },
  { cnae: "8520100", descricao: "ENSINO MEDIO ESPECIAL" },
  { cnae: "8520100", descricao: "ENSINO SUPLETIVO MEDIO" },
  {
    cnae: "8520100",
    descricao: "EDUCACAO INTENSIVA MEDIA; PUBLICA OU PARTICULAR",
  },
  { cnae: "8520100", descricao: "ENSINO MEDIO" },
  { cnae: "8520100", descricao: "ENSINO MEDIO; ENSINO DE LIBRA" },
  { cnae: "8520100", descricao: "ENSINO GERAL MEDIO; PUBLICO OU PARTICULAR" },
  {
    cnae: "8520100",
    descricao: "EDUCACAO SUPLETIVA MEDIA; PUBLICA OU PARTICULAR",
  },
  { cnae: "8520100", descricao: "ENSINO MEDIO A DISTANCIA" },
  {
    cnae: "8520100",
    descricao:
      "ESCOLA DE ENSINO MEDIO DE FORMACAO GERAL; PUBLICA OU PARTICULAR",
  },
  { cnae: "8520100", descricao: "ESCOLA NORMAL" },
  { cnae: "8520100", descricao: "ENSINO MEDIO POR CORRESPONDENCIA" },
  {
    cnae: "8520100",
    descricao: "EDUCACAO GERAL DE ENSINO MEDIO; PUBLICA OU PARTICULAR",
  },
  { cnae: "85317", descricao: "EDUCACAO SUPERIOR  GRADUACAO" },
  { cnae: "8531700", descricao: "ENSINO SUPERIOR GRADUACAO - EAD" },
  { cnae: "8531700", descricao: "ENSINO SUPERIOR MILITAR - (GRADUACAO)" },
  { cnae: "8531700", descricao: "EDUCACAO SUPERIOR - GRADUACAO" },
  { cnae: "85325", descricao: "EDUCACAO SUPERIOR  GRADUACAO E POSGRADUACAO" },
  { cnae: "8532500", descricao: "MESTRADO; CURSO DE" },
  { cnae: "8532500", descricao: "POS-DOUTORADO; CURSO DE" },
  {
    cnae: "8532500",
    descricao: "EDUCACAO SUPERIOR - GRADUACAO E POS GRADUACAO",
  },
  { cnae: "8532500", descricao: "DOUTORADO; CURSO DE" },
  { cnae: "85333", descricao: "EDUCACAO SUPERIOR  POSGRADUACAO E EXTENSAO" },
  { cnae: "8533300", descricao: "CURSO MBA" },
  {
    cnae: "8533300",
    descricao: "EDUCACAO SUPERIOR - POS GRADUACAO E EXTENSAO",
  },
  { cnae: "8533300", descricao: "ENSINO DE POS GRADUACAO" },
  { cnae: "8533300", descricao: "EDUCACAO SUPERIOR - EXTENSAO" },
  { cnae: "8533300", descricao: "ENSINO DE POS GRADUACAO E EXTENSAO - EAD" },
  { cnae: "8533300", descricao: "CURSO DE POS GRADUACAO" },
  { cnae: "8533300", descricao: "ENSINO MILITAR DE POS GRADUACAO E EXTENSAO" },
  { cnae: "85414", descricao: "EDUCACAO PROFISSIONAL DE NIVEL TECNICO" },
  { cnae: "8541400", descricao: "ESCOLA TECNICA INDUSTRIAL" },
  { cnae: "8541400", descricao: "EDUCACAO PROFISSIONAL DE NIVEL TECNICO" },
  {
    cnae: "8541400",
    descricao: "ESCOLA TECNICA PROFISSIONALIZANTE; PUBLICA OU PARTICULAR",
  },
  {
    cnae: "8541400",
    descricao: "ENSINO MEDIO TECNICO E PROFISSIONAL; PUBLICO OU PARTICULAR",
  },
  { cnae: "8541400", descricao: "ENSINO PROFISSIONAL DE NIVEL TECNICO" },
  {
    cnae: "8541400",
    descricao: "EDUCACAO MEDIA TECNICA E PROFISSIONAL; PUBLICA OU PARTICULAR",
  },
  {
    cnae: "8541400",
    descricao: "CENTRO DE ENSINO TECNICO; PUBLICO OU PARTICULAR",
  },
  {
    cnae: "8541400",
    descricao:
      "COLEGIO DE FORMACAO TECNICA E PROFISSIONALIZANTE; PUBLICO OU PARTICULAR",
  },
  { cnae: "8541400", descricao: "ESCOLA TECNICA COMERCIAL" },
  {
    cnae: "8541400",
    descricao: "EDUCACAO TECNICA E PROFISSIONAL; PUBLICA OU PARTICULAR",
  },
  { cnae: "8541400", descricao: "ESCOLA DE CURSOS TECNICOS" },
  {
    cnae: "8541400",
    descricao:
      "ESCOLA MEDIA DE FORMACAO TECNICA E PROFISSIONAL; PUBLICA OU PARTICULAR",
  },
  { cnae: "8541400", descricao: "ESCOLA AGROTECNICA DE ENSINO MEDIO" },
  {
    cnae: "8541400",
    descricao: "ENSINO TECNICO E PROFISSIONALIZANTE; PUBLICO OU PARTICULAR",
  },
  {
    cnae: "8541400",
    descricao: "EDUCACAO TECNICA E PROFISSIONALIZANTE; PUBLICA OU PARTICULAR",
  },
  {
    cnae: "8541400",
    descricao: "ESCOLA TECNICA E PROFISSIONAL; PUBLICA OU PARTICULAR",
  },
  { cnae: "8541400", descricao: "ENSINO PROFISSIONAL DE NIVEL TECNICO - EAD" },
  { cnae: "85422", descricao: "EDUCACAO PROFISSIONAL DE NIVEL TECNOLOGICO" },
  { cnae: "8542200", descricao: "ENSINO DE HABILITACAO AO MAGISTERIO" },
  { cnae: "8542200", descricao: "ENSINO PROFISSIONAL DE NIVEL TECNOLOGICO" },
  {
    cnae: "8542200",
    descricao: "EDUCACAO PROFISSIONAL TECNOLOGICA DE GRADUACAO",
  },
  { cnae: "8542200", descricao: "EDUCACAO PROFISSIONAL DE NIVEL TECNOLOGICO" },
  {
    cnae: "8542200",
    descricao: "EDUCACAO PROFISSIONAL TECNOLOGICA DE GRADUACAO - EAD",
  },
  {
    cnae: "8542200",
    descricao: "CURSOS PARA LICENCIATURA DE PROFISSIONAIS EM EDUCACAO INFANTIL",
  },
  { cnae: "85503", descricao: "ATIVIDADES DE APOIO A EDUCACAO" },
  {
    cnae: "8550301",
    descricao:
      "ADMINISTRACAO DOS RECURSOS DA CAIXA ESCOLAR DAS ESCOLAS PUBLICAS, PARA AQUISICAO DE MERENDA ESCOLAR E CONTRATACAO DE PEQUENOS SERVICOS (MANUTENCAO, CONSERVACAO E OUTROS)",
  },
  {
    cnae: "8550301",
    descricao:
      "ADMINISTRACAO DOS RECURSOS FINANCEIROS TRANSFERIDOS DOS GOVERNOS ESTADUAIS E MUNICIPAIS PARA A CAIXA ESCOLAR DE ESCOLAS PUBLICAS ESTADUAIS E MUNICIPAIS",
  },
  { cnae: "8550301", descricao: "CAIXA ESCOLAR" },
  {
    cnae: "8550302",
    descricao: "INTERCAMBIO DE ESTUDANTES; AGENCIAS PROMOTORAS DE",
  },
  { cnae: "8550302", descricao: "TESTES VOCACIONAIS; SERVICOS DE" },
  {
    cnae: "8550302",
    descricao: "AGENCIAS PROMOTORAS DE INTERCAMBIO DE ESTUDANTES",
  },
  {
    cnae: "8550302",
    descricao:
      "GESTAO, ASSESSORIA, CONSULTORIA, ORIENTACAO E ASSISTENCIA PRESTADOS AO SISTEMA E AO PROCESSO  EDUCACIONAL; SERVICOS DE",
  },
  {
    cnae: "8550302",
    descricao: "INTEGRACAO EMPRESA-ESCOLA; AGENCIAS PROMOTORAS DE",
  },
  { cnae: "8550302", descricao: "INTERCAMBIO CULTURAL; SERVICOS DE" },
  {
    cnae: "8550302",
    descricao:
      "FUNDACOES DE APOIO A PESQUISAS LIGADAS A UNIVERSIDADES EXCETO NA AREA DE SAUDE",
  },
  { cnae: "8550302", descricao: "ASSESSORIA E CONSULTORIA EM PEDAGOGIA" },
  { cnae: "8550302", descricao: "AVALIACAO EDUCACIONAL; SERVICOS DE" },
  {
    cnae: "8550302",
    descricao:
      "ATIVIDADES DE APOIO A EDUCACAO; GESTAO, ASSESSORIA, CONSULTORIA, ORIENTACAO E ASSISTENCIA",
  },
  { cnae: "8550302", descricao: "CONSULTORIA EM EDUCACAO" },
  {
    cnae: "8550302",
    descricao: "AGENCIAS PROMOTORAS DE INTEGRACAO ENTRE EMPRESAS E ESCOLAS",
  },
  {
    cnae: "8550302",
    descricao: "ASSESSORIA E CONSULTORIA EM ASSUNTOS EDUCACIONAIS",
  },
  { cnae: "8550302", descricao: "PSICOPEDAGOGIA; ATIVIDADES DE" },
  { cnae: "8550302", descricao: "ASSESSORIA PEDAGOGICA; SERVICOS DE" },
  { cnae: "85911", descricao: "ENSINO DE ESPORTES" },
  { cnae: "8591100", descricao: "VOLEI; ENSINO, CURSO, ESCOLA DE" },
  { cnae: "8591100", descricao: "ATLETISMO; ESCOLINHA DE" },
  { cnae: "8591100", descricao: "BASQUETE; ESCOLINHA DE" },
  {
    cnae: "8591100",
    descricao: "TECNICOS E ASSISTENTES DE ATIVIDADES ESPORTIVAS; ATIVIDADES DE",
  },
  { cnae: "8591100", descricao: "ESCOLINHA DE NATACAO" },
  { cnae: "8591100", descricao: "BASQUETE; ENSINO, CURSO, ESCOLA DE" },
  { cnae: "8591100", descricao: "ARTES MARCIAIS; ENSINO, CURSO DE" },
  { cnae: "8591100", descricao: "ESCOLINHA DE VOLEI" },
  { cnae: "8591100", descricao: "HALTEROFILISMO; ENSINO DE" },
  { cnae: "8591100", descricao: "ESCOLINHA DE ESPORTE" },
  { cnae: "8591100", descricao: "TIRO AO ALVO; ENSINO, CURSO, ESCOLA DE" },
  { cnae: "8591100", descricao: "JIU-JITSU; ENSINO DE, ACADEMIA DE" },
  { cnae: "8591100", descricao: "AULAS DE EQUITACAO; ATIVIDADE DE" },
  { cnae: "8591100", descricao: "KARATE; ENSINO DE, ACADEMIA DE" },
  { cnae: "8591100", descricao: "JUDO; ENSINO DE, ACADEMIA DE" },
  { cnae: "8591100", descricao: "BOXE; ENSINO, ACADEMIA DE" },
  { cnae: "8591100", descricao: "MERGULHO; ENSINO, CURSO, ESCOLA DE" },
  { cnae: "8591100", descricao: "NATACAO; ENSINO, CURSO, ESCOLA DE" },
  { cnae: "8591100", descricao: "FUTEBOL; ENSINO, CURSO, ESCOLA DE" },
  { cnae: "8591100", descricao: "AIKIDO; ENSINO DE" },
  { cnae: "8591100", descricao: "ATLETISMO; ENSINO, CURSO, ESCOLA DE" },
  { cnae: "8591100", descricao: "ESCOLINHA DE FUTEBOL" },
  { cnae: "8591100", descricao: "ESPORTES; ENSINO, CURSO, ESCOLA DE" },
  { cnae: "85929", descricao: "ENSINO DE ARTE E CULTURA" },
  { cnae: "8592901", descricao: "DANCA DE SALAO; CURSO, ENSINO DE" },
  { cnae: "8592901", descricao: "AULAS DE DANCA, DE BALET" },
  { cnae: "8592901", descricao: "BALLET; CURSO, ENSINO DE" },
  {
    cnae: "8592901",
    descricao: "ACADEMIA DE DANCA POPULAR E FOLCLORICA; ATIVIDADE DE",
  },
  { cnae: "8592901", descricao: "DANCA  - BALLET; CURSO, ENSINO DE" },
  { cnae: "8592901", descricao: "INSTRUTOR DE DANCA; ATIVIDADE DE" },
  { cnae: "8592902", descricao: "INSTRUTORES INDEPENDENTES DE ARTES CENICAS" },
  {
    cnae: "8592902",
    descricao: "IMPOSTACAO DE VOZ PARA PRATICAS DE ARTES CENICAS; CURSO DE",
  },
  {
    cnae: "8592902",
    descricao: "ENSINO DE TEATRO (EXCETO DE NIVEL SUPERIOR E TECNICO)",
  },
  {
    cnae: "8592902",
    descricao:
      "TECNICAS DE DIRECAO E MONTAGEM DE ESPETACULOS TEATRAIS; ENSINO DE",
  },
  {
    cnae: "8592903",
    descricao: "MUSICA, EXCETO ENSINO SUPERIOR; CURSO, ENSINO DE",
  },
  {
    cnae: "8592903",
    descricao: "CONSERVATORIO DE MUSICA, EXCETO DE GRADUACAO EDUCACIONAL",
  },
  {
    cnae: "8593700",
    descricao: "PROFESSORES DE IDIOMA INDEPENDENTES; ATIVIDADE DE",
  },
  { cnae: "8593700", descricao: "ITALIANO; CURSO, ENSINO DE" },
  { cnae: "8593700", descricao: "FRANCES; CURSO, ENSINO DE" },
  { cnae: "8593700", descricao: "PORTUGUES, CURSO, ENSINO DE" },
  { cnae: "8593700", descricao: "INGLES; CURSO, ENSINO DE" },
  { cnae: "8593700", descricao: "LINGUAS ESTRANGEIRAS; CURSO, ENSINO DE" },
  {
    cnae: "85996",
    descricao: "ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
  },
  {
    cnae: "8599601",
    descricao: "FORMACAO DE CONDUTORES DE VEICULOS; ESCOLA, CENTRO DE",
  },
  { cnae: "8599601", descricao: "AULAS DE DIRECAO DE AUTOMOVEIS" },
  { cnae: "8599601", descricao: "AULAS PARA CONDUTORES DE VEICULOS" },
  { cnae: "8599601", descricao: "CURSO DE DIRECAO DE AUTOMOVEIS" },
  {
    cnae: "8599601",
    descricao: "TREINAMENTO DE CONDUTORES DE VEICULOS; CENTRO DE",
  },
  { cnae: "8599601", descricao: "AUTO MOTO ESCOLA" },
  { cnae: "8599601", descricao: "CURSO DE CONDUTORES DE VEICULOS; ENSINO DE" },
  { cnae: "8599601", descricao: "ENSINO EM AUTO-ESCOLA" },
  { cnae: "8599601", descricao: "ENSINO EM MOTO-ESCOLA" },
  { cnae: "8599601", descricao: "ESCOLA PARA MOTORISTA" },
  { cnae: "8599601", descricao: "AULAS DE HABILITACAO PARA MOTORISTAS" },
  { cnae: "8599601", descricao: "AULAS PRATICAS DE CARRO" },
  { cnae: "8599601", descricao: "AULAS DE TRANSITO" },
  { cnae: "8599601", descricao: "AUTO-ESCOLA" },
  {
    cnae: "8599601",
    descricao: "CAPACITACAO DE CONDUTORES DE VEICULOS; SERVICOS DE",
  },
  { cnae: "8599602", descricao: "CURSOS DE PILOTAGEM DE BARCOS" },
  { cnae: "8599602", descricao: "CURSOS DE APERFEICOAMENTO DE PILOTOS" },
  { cnae: "8599602", descricao: "CURSOS DE PILOTAGEM DE AERONAVES" },
  {
    cnae: "8599602",
    descricao: "PILOTAGEM PARA PILOTOS PROFISSIONAIS; CURSO, ENSINO DE",
  },
  { cnae: "8599602", descricao: "CURSO DE PILOTAGEM NAO PROFISSIONAL" },
  { cnae: "8599603", descricao: "AULAS DE INFORMATICA" },
  { cnae: "8599603", descricao: "MICROCOMPUTADOR; CURSO, ENSINO DE" },
  { cnae: "8599603", descricao: "INFORMATICA; CURSO, ENSINO DE" },
  { cnae: "8599603", descricao: "COMPUTADOR; CURSO, ENSINO DE" },
  {
    cnae: "8599603",
    descricao: "TECNOLOGIAS DA INFORMACAO E ACESSO A INTERNET; ENSINO DE",
  },
  {
    cnae: "8599604",
    descricao: "TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL",
  },
  { cnae: "8599604", descricao: "CURSO DE APERFEICOAMENTO JURIDICO" },
  {
    cnae: "8599604",
    descricao:
      "CURSO DE TREINAMENTO PROFISSIONAL, GERENCIAL COM ACESSO A INTERNET",
  },
  {
    cnae: "8599604",
    descricao:
      "CURSO DE APRENDIZAGEM E TREINAMENTO GERENCIAL; PRESENCIAL E A DISTANCIA",
  },
  { cnae: "8599604", descricao: "PALESTRANTE; SERVICOS DE" },
  { cnae: "8599604", descricao: "CURSO DE APERFEICOAMENTO PROFISSIONAL" },
  { cnae: "8599604", descricao: "CURSO DE COMISSARIAS" },
  { cnae: "8599605", descricao: "ESCOLA PREPARATORIA PARA CONCURSOS" },
  { cnae: "8599605", descricao: "PREPARATORIO PARA CONCURSOS; CURSO" },
  { cnae: "8599605", descricao: "VESTIBULAR; CURSO, ENSINO DE" },
  { cnae: "8599605", descricao: "PRE-VESTIBULAR; CURSO, ENSINO DE" },
  { cnae: "8599605", descricao: "CONCURSO; ENSINO PARA" },
  { cnae: "8599699", descricao: "ROBOTICA; CURSO, ENSINO DE" },
  {
    cnae: "8599699",
    descricao:
      "PROFESSOR PARTICULAR, EXCETO PROFESSOR DE IDIOMAS; ATIVIDADES DE",
  },
  {
    cnae: "8599699",
    descricao:
      "UNIDADES CENTRAIS E REGIONAIS DE ORGAOS VOLTADOS PARA O BEM ESTAR SOCIAL QUE TEM A EDUCACAO COMO PRIORIDADE",
  },
  {
    cnae: "8599699",
    descricao: "DESENHO, EXCETO ENSINO SUPERIOR; CURSO, ENSINO DE",
  },
  { cnae: "8599699", descricao: "AULAS PARTICULARES" },
  {
    cnae: "8599699",
    descricao: "PROFESSOR AUTONOMO, INDEPENDENTE; ATIVIDADES DE",
  },
  { cnae: "8599699", descricao: "ESTETICA; CURSO DE" },
  { cnae: "8599699", descricao: "DATILOGRAFIA, TAQUIGRAFIA; CURSO, ENSINO DE" },
  { cnae: "8599699", descricao: "ENSINO PARTICULAR, AULA PARTICULAR" },
  { cnae: "8599699", descricao: "CORTE E COSTURA; CURSO, ENSINO DE" },
  {
    cnae: "8599699",
    descricao:
      "UNIDADES CENTRAIS E REGIONAIS DE ORGAOS VOLTADOS AO BEM-ESTAR SOCIAL QUE TEM EDUCACAO COMO ATIVIDADE PRIORITARIA",
  },
  { cnae: "8599699", descricao: "SEGURANCA; CURSO DE" },
  { cnae: "8599699", descricao: "CABELEIREIRO; CURSO, ENSINO DE" },
  { cnae: "8599699", descricao: "VIGILANTES; CURSOS, ENSINO, TREINAMENTO DE" },
  { cnae: "8599699", descricao: "CULINARIA; CURSO, ENSINO DE" },
  { cnae: "86101", descricao: "ATIVIDADES DE ATENDIMENTO HOSPITALAR" },
  {
    cnae: "8610101",
    descricao: "CENTRO MEDICO COM INTERNACAO; PUBLICO OU PARTICULAR",
  },
  { cnae: "8610101", descricao: "HOSPITAL UNIVERSITARIO" },
  {
    cnae: "8610101",
    descricao: "INSTITUICAO DE SAUDE COM INTERNACAO; PUBLICA OU PARTICULAR",
  },
  { cnae: "8610101", descricao: "HOSPICIO COM INTERNACAO" },
  { cnae: "8610101", descricao: "HOSPITAL PSIQUIATRICO" },
  {
    cnae: "8610101",
    descricao: "HOSPITAL ESPECIALIZADO; PUBLICO OU PARTICULAR",
  },
  {
    cnae: "8610101",
    descricao: "CASA DE SAUDE COM INTERNACAO; PUBLICA OU PARTICULAR",
  },
  { cnae: "8610101", descricao: "HOSPITAL INFANTIL" },
  {
    cnae: "8610101",
    descricao:
      "UNIDADE DE TRATAMENTO (TERAPIA) INTENSIVA (UTI) EM ESTABELECIMENTOS HOSPITALARES; SERVICOS DE",
  },
  {
    cnae: "8610101",
    descricao: "POLICLINICA COM INTERNACAO; PUBLICA OU PARTICULAR",
  },
  {
    cnae: "8610101",
    descricao: "HOSPITAL COM UNIDADE DE TRATAMENTO (TERAPIA) INTENSIVA (UTI)",
  },
  {
    cnae: "8610101",
    descricao: "SANATORIO COM INTERNACAO; PUBLICO OU PARTICULAR",
  },
  { cnae: "8610101", descricao: "CASAS DE PARTO" },
  { cnae: "8610101", descricao: "CENTROS CIRURGICOS" },
  { cnae: "8610101", descricao: "HOSPITAL DE BASE MILITAR" },
  { cnae: "8610101", descricao: "NAVIO-HOSPITAL COM INTERNACAO" },
  {
    cnae: "8610101",
    descricao: "CENTRO DE MEDICINA PREVENTIVA COM INTERNACAO",
  },
  { cnae: "8610101", descricao: "HOSPITAL MATERNIDADE; PUBLICO OU PARTICULAR" },
  { cnae: "8610101", descricao: "HOSPITAL; PUBLICO OU PARTICULAR" },
  {
    cnae: "8610101",
    descricao:
      "CENTRO DE TRATAMENTO (TERAPIA) INTENSIVA (CTI) EM ESTABELECIMENTOS HOSPITALARES; SERVICOS DE",
  },
  { cnae: "8610101", descricao: "HOSPITAL GERAL; PUBLICO OU PARTICULAR" },
  { cnae: "8610101", descricao: "MANICOMIO COM INTERNACAO" },
  {
    cnae: "8610101",
    descricao: "ATENDIMENTO HOSPITALAR COM INTERNACAO; ATIVIDADES DE",
  },
  { cnae: "8610101", descricao: "HOSPITAL PENITENCIARIO" },
  {
    cnae: "8610101",
    descricao: "CLINICA MEDICA COM INTERNACAO; PUBLICA OU PARTICULAR",
  },
  {
    cnae: "8610102",
    descricao: "ATENDIMENTO A URGENCIAS E EMERGENCIAS MEDICAS",
  },
  { cnae: "8610102", descricao: "PRONTO-SOCORRO" },
  { cnae: "8610102", descricao: "PRONTO CLINICA SEM INTERNACAO" },
  { cnae: "8610102", descricao: "CLINICA DE PRONTO-SOCORRO" },
  { cnae: "86216", descricao: "SERVICOS MOVEIS DE ATENDIMENTO A URGENCIAS" },
  {
    cnae: "8621601",
    descricao: "UNIDADES MOVEIS TERRESTRES COM UTI; SERVICOS DE",
  },
  { cnae: "8621601", descricao: "UTI MOVEL" },
  { cnae: "8621601", descricao: "UNIDADES MOVEIS AEREAS COM UTI; SERVICOS DE" },
  { cnae: "8621601", descricao: "AMBULANCIAS COM UTI" },
  {
    cnae: "8621602",
    descricao:
      "UNIDADES MOVEIS DO SETOR PUBLICO PARA ATENDIMENTO A URGENCIAS FORA DO DOMICILIO  - SAMU, EXCETO UTI MOVEL",
  },
  {
    cnae: "8621602",
    descricao:
      "UNIDADES MOVEIS TERRESTRES PARA ATENDIMENTO A EMERGENCIAS, EXCETO UTI MOVEL",
  },
  { cnae: "8621602", descricao: "SERVICOS AEROMEDICOS" },
  {
    cnae: "8621602",
    descricao:
      "UNIDADES MOVEIS DE ATENDIMENTO A URGENCIAS LIGADAS A PLANOS DE SAUDE, EXCETO UTI MOVEL",
  },
  { cnae: "8621602", descricao: "SAMU" },
  {
    cnae: "8621602",
    descricao:
      "UNIDADES MOVEIS AEREAS PARA ATENDIMENTO A EMERGENCIAS, EXCETO UTI MOVEL",
  },
  { cnae: "8640212", descricao: "HEMOTERAPIA; SERVICOS DE, NUCLEOS DE" },
  {
    cnae: "86224",
    descricao:
      "SERVICOS DE REMOCAO DE PACIENTES, EXCETO OS SERVICOS MOVEIS DE ATENDIMENTO A URGENCIAS",
  },
  {
    cnae: "8622400",
    descricao: "AMBULANCIA SOMENTE PARA TRANSPORTE DE PACIENTE; SERVICO DE",
  },
  {
    cnae: "8622400",
    descricao: "REMOCOES, SOMENTE TRANSPORTE DE PACIENTE; SERVICOS DE",
  },
  {
    cnae: "86305",
    descricao:
      "ATIVIDADES DE ATENCAO AMBULATORIAL EXECUTADAS POR MEDICOS E ODONTOLOGOS",
  },
  {
    cnae: "8630501",
    descricao:
      "CLINICA DE OLHOS COM RECURSOS PARA A REALIZACAO DE PROCEDIMENTOS CIRURGICOS",
  },
  {
    cnae: "8630501",
    descricao:
      "CLINICA OFTALMOLOGICA COM RECURSOS PARA A REALIZACAO DE PROCEDIMENTOS CIRURGICOS",
  },
  { cnae: "8630501", descricao: "IMPLANTE CAPILAR; CLINICA DE" },
  {
    cnae: "8630501",
    descricao:
      "CLINICA MEDICA COM RECURSOS PARA A REALIZACAO DE PROCEDIMENTOS CIRURGICOS; ATIVIDADES DE",
  },
  {
    cnae: "8630501",
    descricao:
      "CLINICA DERMATOLOGICA COM RECURSOS PARA A REALIZACAO DE PROCEDIMENTOS CIRURGICOS",
  },
  {
    cnae: "8630502",
    descricao:
      "CLINICA OFTALMOLOGICA COM RECURSOS PARA A REALIZACAO DE EXAMES COMPLEMENTARES",
  },
  { cnae: "8630502", descricao: "POSTO DE ASSISTENCIA MEDICA SEM INTERNACAO" },
  { cnae: "8630502", descricao: "POSTO DE SAUDE PUBLICA" },
  {
    cnae: "8630502",
    descricao:
      "CLINICA DE OLHOS COM RECURSOS PARA A REALIZACAO DE EXAMES COMPLEMENTARES",
  },
  {
    cnae: "8630502",
    descricao:
      "CLINICA DERMATOLOGICA COM RECURSOS PARA A REALIZACAO DE EXAMES COMPLEMENTARES",
  },
  {
    cnae: "8630502",
    descricao:
      "CLINICA MEDICA COM RECURSOS PARA A REALIZACAO DE EXAMES COMPLEMENTARES; ATIVIDADES DE",
  },
  {
    cnae: "8630503",
    descricao: "CONSULTORIO MEDICO EM UNIDADES MOVEIS FLUVIAIS",
  },
  {
    cnae: "8630503",
    descricao: "CLINICA MEDICA RESTRITA A CONSULTAS; ATIVIDADES DE",
  },
  { cnae: "8630503", descricao: "PERICIA MEDICA; SERVICOS DE" },
  { cnae: "8630503", descricao: "CONSULTORIO MEDICO PARTICULAR" },
  { cnae: "8630503", descricao: "POLICLINICA" },
  { cnae: "8630503", descricao: "CLINICA MEDICA EM EMPRESA" },
  { cnae: "8630503", descricao: "CONSULTORIOS PRIVADOS EM HOSPITAIS" },
  { cnae: "8630504", descricao: "CLINICA ODONTOLOGICA; PUBLICA OU PARTICULAR" },
  { cnae: "8630504", descricao: "AMBULATORIO ODONTOLOGICO" },
  {
    cnae: "8630504",
    descricao: "UNIDADES MOVEIS TERRESTRES PARA CONSULTA ODONTOLOGICA",
  },
  { cnae: "8630504", descricao: "ODONTOLOGIA; ATIVIDADES DE" },
  { cnae: "8630504", descricao: "CONSULTORIO ODONTOLOGICO" },
  { cnae: "8630504", descricao: "CONSULTORIO DENTARIO" },
  { cnae: "8630504", descricao: "PRONTO SOCORRO ODONTOLOGICO" },
  { cnae: "8630506", descricao: "VACINACAO E IMUNIZACAO HUMANA; SERVICOS DE" },
  { cnae: "8630506", descricao: "IMUNIZACAO HUMANA; CLINICA DE" },
  { cnae: "8630506", descricao: "CLINICA DE VACINACAO HUMANA" },
  {
    cnae: "8630507",
    descricao: "LABORATORIOS RELACIONADOS A FERTILIZACAO HUMANA ASSISTIDA",
  },
  { cnae: "8630507", descricao: "BANCO DE SEMEN HUMANO" },
  { cnae: "8630507", descricao: "CLINICA DE REPRODUCAO HUMANA ASSISTIDA" },
  { cnae: "8630507", descricao: "BANCO DE ESPERMA" },
  { cnae: "8630507", descricao: "REPRODUCAO HUMANA ASSISTIDA; ATIVIDADE DE" },
  { cnae: "8630507", descricao: "INSEMINACAO ARTIFICIAL DE SERES HUMANOS" },
  {
    cnae: "8630507",
    descricao: "FERTILIZACAO IN VITRO DE SERES HUMANOS; ATIVIDADE DE",
  },
  {
    cnae: "8630599",
    descricao:
      "MEDICOS AUTONOMOS EM UNIDADES HOSPITALARES E CONSULTORIOS DE TERCEIROS; ATIVIDADES DE",
  },
  { cnae: "8630599", descricao: "MEDICINA DO TRABALHO; SERVICOS DE" },
  { cnae: "8630599", descricao: "ANESTESISTAS; ATIVIDADES DE MEDICOS" },
  {
    cnae: "86402",
    descricao:
      "ATIVIDADES DE SERVICOS DE COMPLEMENTACAO DIAGNOSTICA E TERAPEUTICA",
  },
  { cnae: "8640201", descricao: "PECAS BIOLOGICAS; EXAME DE" },
  {
    cnae: "8640201",
    descricao: "TESTE DE DNA PARA DETERMINACAO DE PATERNIDADE; SERVICOS DE",
  },
  { cnae: "8640201", descricao: "TESTE DE PATERNIDADE; SERVICOS DE" },
  { cnae: "8640201", descricao: "ANATOMIA CITOLOGICA; LABORATORIO DE" },
  { cnae: "8640201", descricao: "APOIO A MEDICINA LEGAL; ATIVIDADES DE" },
  {
    cnae: "8640201",
    descricao: "LABORATORIO DE ANATOMIA PATOLOGICA E CITOLOGICA",
  },
  { cnae: "8640201", descricao: "ANATOMIA PATOLOGICA; LABORATORIO DE" },
  { cnae: "8640201", descricao: "AUTOPSIAS; ATIVIDADE DE" },
  { cnae: "8640202", descricao: "LABORATORIO DE ANALISES CLINICAS" },
  { cnae: "8640202", descricao: "LABORATORIO DE PATOLOGIA CLINICA" },
  { cnae: "8640202", descricao: "POSTOS DE COLETA LABORATORIAL" },
  { cnae: "8640202", descricao: "ANALISES CLINICAS; SERVICOS DE" },
  { cnae: "8640202", descricao: "LABORATORIO DE BIOLOGIA MOLECULAR" },
  { cnae: "8640202", descricao: "PATOLOGIA CLINICA; SERVICOS DE" },
  { cnae: "8640202", descricao: "SANGUE E URINA PARA LABORATORIOS; COLETA DE" },
  {
    cnae: "8640202",
    descricao: "LABORATORIOS DE ANALISE CLINICAS EM UNIDADES MOVEIS",
  },
  { cnae: "8640203", descricao: "DIALISE PERITONEAL; SERVICOS DE" },
  { cnae: "8640203", descricao: "NEFROLOGIA; SERVICOS DE" },
  { cnae: "8640203", descricao: "HEMODIALISE; SERVICOS DE" },
  { cnae: "8640204", descricao: "TOMOGRAFIA; SERVICOS DE" },
  { cnae: "8640204", descricao: "TOMOGRAFIA COMPUTADORIZADA; SERVICOS DE" },
  { cnae: "8640205", descricao: "RADIOLOGIA MEDICA; SERVICOS DE" },
  { cnae: "8640205", descricao: "MAMOGRAFIA" },
  { cnae: "8640205", descricao: "RADIOLOGIA ODONTOLOGICA" },
  { cnae: "8640205", descricao: "FLUOROSCOPIA" },
  { cnae: "8640205", descricao: "CLINICA DE RADIOLOGIA" },
  { cnae: "8640205", descricao: "DENSITOMETRIA OSSEA" },
  { cnae: "8640205", descricao: "ABREUGRAFIA; SERVICOS DE" },
  {
    cnae: "8640205",
    descricao:
      "LABORATORIO RADIOLOGICO EM UNIDADES MOVEIS, EXCETO COM CONSULTAS",
  },
  { cnae: "8640205", descricao: "HEMODINAMICA" },
  { cnae: "8640205", descricao: "MEDICINA NUCLEAR; SERVICOS DE" },
  { cnae: "8640205", descricao: "RADIODIAGNOSTICO; SERVICOS DE" },
  { cnae: "8640205", descricao: "RADIO-IMUNODIAGNOSTICO; SERVICOS DE" },
  { cnae: "8640205", descricao: "RAIOS X; SERVICOS DE" },
  {
    cnae: "8640205",
    descricao: "LABORATORIOS RADIOLOGICOS EM UNIDADES MOVEIS TERRESTRES",
  },
  { cnae: "8640205", descricao: "RADIOLOGIA; SERVICOS DE" },
  { cnae: "8640206", descricao: "RESSONANCIA MAGNETICA; EXAME DE" },
  { cnae: "8640206", descricao: "RESSONANCIA MAGNETICA; SERVICOS DE" },
  { cnae: "8640207", descricao: "ULTRASOM; SERVICOS DE" },
  { cnae: "8640207", descricao: "ULTRASSONOGRAFIA; SERVICOS DE" },
  { cnae: "8640207", descricao: "ULTRASSONOGRAFIA; EXAMES DE" },
  { cnae: "8640207", descricao: "EXAMES ECOSONOGRAFICOS; SERVICOS DE" },
  { cnae: "8640208", descricao: "POLISSONOGRAFIA" },
  { cnae: "8640208", descricao: "AUDIOMETRIA" },
  { cnae: "8640208", descricao: "ELETROENCEFALOGRAFIA; SERVICOS DE" },
  { cnae: "8640208", descricao: "ELETROENCEFALOGRAMA - EEG; SERVICOS DE" },
  {
    cnae: "8640208",
    descricao: "DIAGNOSTICO POR REGISTRO GRAFICO; SERVICOS DE",
  },
  { cnae: "8640208", descricao: "ELETROCARDIOGRAMA - ECG; SERVICOS DE" },
  { cnae: "8640209", descricao: "DIAGNOSTICO POR METODOS OTICOS; SERVICOS DE" },
  { cnae: "8640209", descricao: "ENDOSCOPIA RESPIRATORIA; EXAME DE" },
  { cnae: "8640209", descricao: "ENDOSCOPIA; SERVICOS DE" },
  { cnae: "8640209", descricao: "ENDOSCOPIA DIGESTIVA; EXAME DE" },
  { cnae: "8640210", descricao: "QUIMIOTERAPIA; SERVICOS DE" },
  { cnae: "8640211", descricao: "RADIOTERAPIA; SERVICOS DE" },
  { cnae: "8640211", descricao: "RADIACAO IONIZANTE; SERVICOS DE" },
  {
    cnae: "8640212",
    descricao:
      "CENTRAL DE TRIAGEM LABORATORIAL DE DOADORES E AGENCIA TRANSFUSIONAL",
  },
  { cnae: "8640212", descricao: "BANCO DE SANGUE" },
  { cnae: "8640212", descricao: "HEMOCENTROS" },
  { cnae: "8640212", descricao: "UNIDADES DE COLETA E TRANSFUSAO" },
  { cnae: "8640213", descricao: "CALCULOS RENAIS; SERVICOS DE ELIMINACAO DE" },
  { cnae: "8640214", descricao: "BANCO DE OLHOS" },
  { cnae: "8640214", descricao: "BANCO DE OSSOS" },
  { cnae: "8640214", descricao: "BANCO DE CELULAS E TECIDOS HUMANOS" },
  { cnae: "8640214", descricao: "BANCO DE ORGAOS" },
  { cnae: "8640214", descricao: "CELULAS E TECIDOS HUMANOS; BANCO DE" },
  { cnae: "8640214", descricao: "BANCO DE PELE" },
  { cnae: "8640299", descricao: "FUNCAO PULMONAR; EXAMES DE" },
  { cnae: "8640299", descricao: "OXIGENOTERAPIA" },
  { cnae: "8640299", descricao: "ESPIROMETRIA" },
  {
    cnae: "86500",
    descricao:
      "ATIVIDADES DE PROFISSIONAIS DA AREA DE SAUDE, EXCETO MEDICOS E ODONTOLOGOS",
  },
  { cnae: "8650001", descricao: "ENFERMAGEM; SERVICOS DE" },
  { cnae: "8650001", descricao: "ENFERMEIROS; ATIVIDADES DE" },
  { cnae: "8650002", descricao: "NUTRICAO; SERVICOS DE" },
  { cnae: "8650002", descricao: "NUTRICIONISTA; SERVICOS DE" },
  { cnae: "8650003", descricao: "PSICANALISE; CONSULTORIO DE" },
  { cnae: "8650003", descricao: "PSICOLOGIA; CLINICA, CONSULTORIO, CENTRO DE" },
  { cnae: "8650003", descricao: "PSICANALISE; CLINICA DE" },
  { cnae: "8650003", descricao: "PSICANALISE; ATIVIDADE DE" },
  { cnae: "8650003", descricao: "PSICOLOGIA; SERVICOS DE" },
  { cnae: "8650004", descricao: "HIDROTERAPIA; SERVICOS DE" },
  {
    cnae: "8650004",
    descricao: "FISIOTERAPIA; CLINICA, CONSULTORIO, CENTRO DE",
  },
  { cnae: "8650004", descricao: "NUCLEO DE REABILITACAO MOTORA" },
  { cnae: "8650004", descricao: "HIDROTERAPEUTA; SERVICOS DE" },
  { cnae: "8650004", descricao: "CLINICA DE FISIOTERAPIA" },
  {
    cnae: "8650004",
    descricao: "REABILITACAO POSTURAL GLOBAL - RPG; SERVICOS DE",
  },
  { cnae: "8650004", descricao: "RPG - REABILITACAO POSTURAL GLOBAL" },
  { cnae: "8650005", descricao: "TERAPEUTA OCUPACIONAL; SERVICOS DE" },
  { cnae: "8650005", descricao: "TERAPIA OCUPACIONAL; ATIVIDADES DE" },
  { cnae: "9003500", descricao: "TEATRO; GESTAO DE SALA DE" },
  {
    cnae: "8650006",
    descricao: "FONOAUDIOLOGIA; CLINICA, CONSULTORIO, CENTRO DE",
  },
  { cnae: "8650006", descricao: "FONOAUDIOLOGOS; ATIVIDADES DE" },
  { cnae: "8650006", descricao: "FONOAUDIOLOGIA; SERVICOS DE" },
  {
    cnae: "8650007",
    descricao: "TERAPIA DE NUTRICAO PARENTERAL; ATIVIDADES DE",
  },
  {
    cnae: "8650007",
    descricao: "TERAPIA DE NUTRICAO ENTERAL E PARENTERAL; SERVICOS DE",
  },
  { cnae: "8650007", descricao: "TERAPIA DE NUTRICAO ENTERAL; ATIVIDADES DE" },
  { cnae: "8650099", descricao: "ORTOPTICA; ATIVIDADES DE" },
  { cnae: "8650099", descricao: "CONSULTORIOS FARMACEUTICOS" },
  { cnae: "8650099", descricao: "OSTEOPATIA; SERVICOS DE" },
  { cnae: "8650099", descricao: "PERFUSIONISTAS; SERVICOS DE" },
  { cnae: "8650099", descricao: "QUIROPRAXIA; SERVICOS DE" },
  { cnae: "8650099", descricao: "INSTRUMENTACAO CIRURGICA; SERVICOS DE" },
  {
    cnae: "8650099",
    descricao: "FARMACEUTICOS CLINICOS; SERVICOS PRESTADOS POR",
  },
  {
    cnae: "8650099",
    descricao:
      "PERFUSAO (OPERACAO DE MAQUINA DE CIRCULACAO EXTRACORPOREA); SERVICOS DE",
  },
  { cnae: "8650099", descricao: "OPTOMETRIA; SERVICOS DE" },
  { cnae: "86607", descricao: "ATIVIDADES DE APOIO A GESTAO DE SAUDE" },
  {
    cnae: "8660700",
    descricao: "CONSULTORIA E ASSESSORIA NA AREA DE SAUDE; SERVICOS DE",
  },
  { cnae: "8660700", descricao: "CENTRAIS DE REGULACAO DO SISTEMA DE SAUDE" },
  {
    cnae: "8660700",
    descricao:
      "FUNDACOES DE APOIO A PESQUISAS LIGADAS A UNIVERSIDADES NA AREA DE SAUDE",
  },
  {
    cnae: "86909",
    descricao:
      "ATIVIDADES DE ATENCAO A SAUDE HUMANA NAO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "8690901", descricao: "TERAPIAS ALTERNATIVAS; SERVICOS DE" },
  { cnae: "8690901", descricao: "TERAPIA INDIANA; SERVICOS DE" },
  { cnae: "8690901", descricao: "TERAPIAS NAO TRADICIONAIS; SERVICOS DE" },
  { cnae: "8690901", descricao: "EQUOTERAPIA; SERVICOS DE" },
  { cnae: "8690901", descricao: "CROMOTERAPIA; SERVICOS DE" },
  { cnae: "8690901", descricao: "MASSOTERAPIA" },
  { cnae: "8690901", descricao: "AROMOTERAPIA; SERVICOS DE" },
  { cnae: "8690901", descricao: "SHIATSU; SERVICOS DE" },
  { cnae: "8690901", descricao: "ROLFING; SERVICOS DE" },
  { cnae: "8690901", descricao: "DO-IN; SERVICOS DE" },
  { cnae: "8690901", descricao: "TERAPIA REICHIANA; SERVICOS DE" },
  { cnae: "8690901", descricao: "REIKI; SERVICOS DE" },
  { cnae: "8690901", descricao: "TERAPIA FLORAL; SERVICOS DE" },
  { cnae: "8690902", descricao: "BANCO DE LEITE HUMANO; ATIVIDADE DE" },
  { cnae: "8690902", descricao: "BANCO DE LEITE MATERNO; ATIVIDADE DE" },
  { cnae: "8690903", descricao: "ACUPUNTURA; SERVICOS DE" },
  { cnae: "8690903", descricao: "ACUPUNTURISTA; SERVICOS DE" },
  { cnae: "8690904", descricao: "PODOLOGOS; SERVICOS DE" },
  { cnae: "8690904", descricao: "TRATAMENTO DOS PES (PODOLOGIA); SERVICOS DE" },
  { cnae: "8690904", descricao: "PODOLOGIA" },
  { cnae: "8690999", descricao: "CURANDEIRO; SERVICOS DE" },
  { cnae: "8690999", descricao: "PARTEIRA; SERVICOS DE" },
  { cnae: "8690999", descricao: "DOULA; ATIVIDADES DE" },
  {
    cnae: "87115",
    descricao:
      "ATIVIDADES DE ASSISTENCIA A IDOSOS, DEFICIENTES FISICOS, IMUNODEPRIMIDOS E CONVALESCENTES PRESTADAS EM RESIDENCIAS COLETIVAS E PARTICULARES",
  },
  { cnae: "8711501", descricao: "CASA DE REPOUSO PARA IDOSOS" },
  { cnae: "8711501", descricao: "CENTRO MEDICO GERIATRICO" },
  { cnae: "8711501", descricao: "CASA GERIATRICA COM INTERNACAO" },
  { cnae: "8711501", descricao: "RESIDENCIAS GERIATRICAS" },
  { cnae: "8711501", descricao: "CENTRO GERIATRICO COM INTERNACAO" },
  { cnae: "8711501", descricao: "CLINICA GERIATRICA COM INTERNACAO" },
  {
    cnae: "8711502",
    descricao: "INSTITUICOES DE LONGA PERMANENCIA PARA IDOSOS - ILPI",
  },
  {
    cnae: "8711502",
    descricao: "RESIDENCIAS PROTEGIDAS DESTINADAS AO ABRIGO DE IDOSOS",
  },
  { cnae: "8711502", descricao: "ABRIGO DE IDOSOS" },
  { cnae: "8711502", descricao: "ASILO PARA IDOSOS" },
  { cnae: "8711502", descricao: "CASA PARA VELHICE COM ALOJAMENTO" },
  {
    cnae: "8711503",
    descricao: "ASSISTENCIA A DEFICIENTES FISICOS COM INTERNACAO",
  },
  { cnae: "8711503", descricao: "ASSISTENCIA A CONVALESCENTES COM INTERNACAO" },
  {
    cnae: "8711503",
    descricao: "CASAS DE REPOUSO PARA CONVALESCENTES E IMONODEPRIMIDOS",
  },
  {
    cnae: "8711503",
    descricao: "ASSISTENCIA A IMUNODEPRIMIDOS COM INTERNACAO",
  },
  {
    cnae: "8711503",
    descricao:
      "ASSISTENCIA MEDICA E PSICOSSOCIAL PARA DEFICIENTES FISICOS COM INTERNACAO",
  },
  { cnae: "8711504", descricao: "CENTROS DE APOIO A PACIENTES COM CANCER" },
  { cnae: "8711504", descricao: "CENTROS DE APOIO A PACIENTES COM AIDS" },
  { cnae: "8711505", descricao: "CONDOMINIOS RESIDENCIAIS PARA IDOSOS" },
  {
    cnae: "87123",
    descricao:
      "ATIVIDADES DE FORNECIMENTO DE INFRAESTRUTURA DE APOIO E ASSISTENCIA A PACIENTE NO DOMICILIO",
  },
  {
    cnae: "8712300",
    descricao:
      "ACOMPANHAMENTO DE ENFERMOS OU DOENTES EM DOMICILIOS; SERVICO DE",
  },
  {
    cnae: "8712300",
    descricao:
      "FORNECIMENTO DE INFRA-ESTRUTURA DE APOIO E ASSISTENCIA A SAUDE A PACIENTES NO DOMICILIO",
  },
  {
    cnae: "8712300",
    descricao:
      "HOMECARE; SERVICOS DE ATENDIMENTO MEDICO HOSPITALAR NO DOMICILIO",
  },
  {
    cnae: "8712300",
    descricao:
      "FORNECIMENTO DE EQUIPAMENTOS ACOMPANHADOS DE PROFISSIONAIS DA AREA DE SAUDE A PACIENTES NO DOMICILIO",
  },
  {
    cnae: "87204",
    descricao:
      "ATIVIDADES DE ASSISTENCIA PSICOSSOCIAL E A SAUDE A PORTADORES DE DISTURBIOS PSIQUICOS, DEFICIENCIA MENTAL E DEPENDENCIA QUIMICA",
  },
  {
    cnae: "8720401",
    descricao:
      "ASSISTENCIA MEDICA E PSICOSSOCIAL PARA PESSOAS COM DEFICIENCIA OU DOENCA MENTAL, DISTURBIOS PSIQUICOS E USUARIAS DE DROGAS, COM ALOJAMENTO",
  },
  { cnae: "8720401", descricao: "CENTROS DE ASSISTENCIA PSICOSSOCIAL" },
  {
    cnae: "8720499",
    descricao:
      "CENTRO DE REABILITACAO PARA DEPENDENTES QUIMICOS COM ALOJAMENTO",
  },
  {
    cnae: "8720499",
    descricao:
      "ATIVIDADES DE ASSISTENCIA PSICOSSOCIAL E A SAUDE A PORTADORES DE DISTURBIOS PSIQUICOS, DEFICIENCIA MENTAL E DEPENDENCIA QUIMICA E GRUPOS SIMILARES NAO ESPECIFICADAS ANTERIORMENTE",
  },
  {
    cnae: "8720499",
    descricao: "INSTITUICAO PARA INCAPACITADOS, COM INTERNACAO",
  },
  {
    cnae: "87301",
    descricao:
      "ATIVIDADES DE ASSISTENCIA SOCIAL PRESTADAS EM RESIDENCIAS COLETIVAS E PARTICULARES",
  },
  { cnae: "8730101", descricao: "ORFANATO" },
  { cnae: "8730101", descricao: "UNIDADES DE ACOLHIMENTO; SERVICOS DE" },
  { cnae: "8730102", descricao: "ALBERGUE ASSISTENCIAL COM ALOJAMENTO" },
  { cnae: "8730102", descricao: "ABRIGOS PARA CRIANCAS DE RUA" },
  { cnae: "8730102", descricao: "ALBERGUES ASSISTENCIAIS" },
  {
    cnae: "8730199",
    descricao: "CENTRO DE REABILITACAO SOCIAL COM ALOJAMENTO",
  },
  {
    cnae: "8730199",
    descricao: "CENTRO CORRECIONAL PARA JOVENS COM ALOJAMENTO",
  },
  { cnae: "88006", descricao: "SERVICOS DE ASSISTENCIA SOCIAL SEM ALOJAMENTO" },
  { cnae: "8800600", descricao: "CENTRO DE ORIENTACAO A FAMILIAS" },
  {
    cnae: "8800600",
    descricao: "ASSISTENCIA SOCIAL A IMIGRANTES; SERVICOS DE",
  },
  {
    cnae: "8800600",
    descricao: "ASSISTENCIA SOCIAL A REFUGIADOS; SERVICOS DE",
  },
  {
    cnae: "8800600",
    descricao:
      "ASSISTENCIA SOCIAL A DEFICIENTES, PRESTADOS EM DOMICILIO; SERVICOS DE",
  },
  {
    cnae: "8800600",
    descricao: "REABILITACAO PROFISSIONAL PARA DESEMPREGADOS; CENTROS DE",
  },
  {
    cnae: "8800600",
    descricao: "PRESTACAO DE SERVICOS SOCIAIS A TERCEIRA IDADE",
  },
  { cnae: "8800600", descricao: "ADOCAO; ATIVIDADES DE" },
  {
    cnae: "8800600",
    descricao: "ASSISTENCIA SOCIAL A VITIMAS DE CATASTROFES; SERVICOS DE",
  },
  { cnae: "8800600", descricao: "ASSOCIACAO DOS ALCOOLICOS ANONIMOS" },
  { cnae: "8800600", descricao: "CENTRO DE ORIENTACAO A REFUGIADOS" },
  {
    cnae: "8800600",
    descricao:
      "ASSISTENCIA SOCIAL A IDOSOS, PRESTADOS EM DOMICILIO; SERVICOS DE",
  },
  {
    cnae: "8800600",
    descricao: "ASSISTENCIA SOCIAL SEM ALOJAMENTO; SERVICOS DE",
  },
  { cnae: "8800600", descricao: "CENTRO DE ORIENTACAO A DETENTOS" },
  {
    cnae: "8800600",
    descricao: "CONSELHO TUTELAR PARA CRIANCA E ADOLESCENCIA; SERVICOS DE",
  },
  { cnae: "8800600", descricao: "CENTRO DE ORIENTACAO A PESSOAS ESTRANGEIRAS" },
  { cnae: "8800600", descricao: "DISTRIBUICAO DE CESTAS BASICAS" },
  {
    cnae: "8800600",
    descricao:
      "ASSISTENCIA MEDICA E PSICOSSOCIAL PARA PESSOAS COM DEFICIENCIA OU DOENCA MENTAL, DISTURBIOS PSIQUICOS E USUARIAS DE DROGAS, SEM ALOJAMENTO",
  },
  { cnae: "8800600", descricao: "ORIENTACAO SOCIAL; SERVICOS DE" },
  {
    cnae: "8800600",
    descricao:
      "ASSISTENCIA SOCIAL A INCAPACITADOS, PRESTADOS EM DOMICILIO; SERVICOS DE",
  },
  { cnae: "8800600", descricao: "NARCOTICOS ANONIMOS; ASSOCIACAO DE" },
  {
    cnae: "90019",
    descricao: "ARTES CENICAS, ESPETACULOS E ATIVIDADES COMPLEMENTARES",
  },
  { cnae: "9001901", descricao: "ATOR INDEPENDENTE; ATIVIDADE DE" },
  { cnae: "9001901", descricao: "COMPANHIA TEATRAL; ATIVIDADE DE" },
  { cnae: "9001901", descricao: "SERVICOS DE FIGURACAO" },
  { cnae: "9001901", descricao: "EVENTO CULTURAL TEATRAL" },
  {
    cnae: "9001901",
    descricao: "ORGANIZACAO, PROMOCAO DE EVENTOS DE TEATRO; ATIVIDADE DE",
  },
  { cnae: "9001901", descricao: "PRODUCAO TEATRAL; ATIVIDADE DE" },
  {
    cnae: "9001901",
    descricao: "ARTES CENICAS TEATRAIS INDEPENDENTES; ATIVIDADES DE",
  },
  { cnae: "9001901", descricao: "COMPANHIA DE TEATRO" },
  { cnae: "9001901", descricao: "GRUPO, CONJUNTO TEATRAL; ATIVIDADE DE" },
  { cnae: "9001902", descricao: "MUSICO; ATIVIDADE DE" },
  {
    cnae: "9001902",
    descricao: "ARTES CENICAS MUSICAIS INDEPENDENTES; ATIVIDADES DE",
  },
  { cnae: "9001902", descricao: "COMPANHIAS MUSICAIS; ATIVIDADE DE" },
  { cnae: "9001902", descricao: "PRODUCAO MUSICAL; ATIVIDADE DE" },
  { cnae: "9001902", descricao: "EVENTO CULTURAL MUSICAL" },
  { cnae: "9001902", descricao: "COMPOSICAO DE PARTITURAS; ATIVIDADE DE" },
  {
    cnae: "9001902",
    descricao: "EVENTOS MUSICAIS; ORGANIZACAO DE, PROMOCAO DE",
  },
  { cnae: "9001902", descricao: "ARRANJO MUSICAL; PRODUCAO DE" },
  { cnae: "9001902", descricao: "GRUPO MUSICAL; ATIVIDADE DE" },
  { cnae: "9001902", descricao: "CORAL; ATIVIDADES DE" },
  { cnae: "9001902", descricao: "ORQUESTRA; ATIVIDADE DE" },
  { cnae: "9001902", descricao: "CONCERTOS E OPERAS; ATIVIDADES DE" },
  { cnae: "9001902", descricao: "ORQUESTRA MUSICAL; ATIVIDADE DE" },
  { cnae: "9001902", descricao: "TRIO ELETRICO; ATIVIDADE DE" },
  { cnae: "9001902", descricao: "CONJUNTO MUSICAL; ATIVIDADE DE" },
  { cnae: "9001902", descricao: "BANDA MUSICAL; ATIVIDADE DE" },
  { cnae: "9001903", descricao: "CONJUNTO DE FOLCLORE; ATIVIDADE DE" },
  { cnae: "9001903", descricao: "GRUPO DE DANCA; ATIVIDADE DE" },
  { cnae: "9001903", descricao: "GRUPO DE DANCA FOLCLORICA; ATIVIDADE DE" },
  { cnae: "9001903", descricao: "ESPETACULO DE DANCA; ATIVIDADE DE" },
  {
    cnae: "9001903",
    descricao: "ARTES CENICAS DE DANCA INDEPENDENTES; ATIVIDADES DE",
  },
  {
    cnae: "9001903",
    descricao: "PROFISSIONAIS DE DANCA INDEPENDENTES; ATIVIDADES DE",
  },
  { cnae: "9001903", descricao: "EVENTO CULTURAL DE DANCA" },
  {
    cnae: "9001903",
    descricao: "ESPETACULOS DE DANCA; PRODUCAO, CRIACAO, PROMOCAO",
  },
  { cnae: "9001903", descricao: "CONJUNTO DE DANCA FOLCLORICA; ATIVIDADE DE" },
  {
    cnae: "9001903",
    descricao: "EVENTOS DE DANCA; ORGANIZACAO DE, PROMOCAO DE",
  },
  { cnae: "9001904", descricao: "CIRCENSE; PRODUCAO DE ESPETACULO" },
  { cnae: "9001904", descricao: "MARIONETE; ESPETACULO DE" },
  { cnae: "9001904", descricao: "MARIONETES; PRODUCAO DE ESPETACULO" },
  { cnae: "9001904", descricao: "ESPETACULO CIRCENSE" },
  { cnae: "9001904", descricao: "CIRCO; ATIVIDADE DE" },
  {
    cnae: "9001904",
    descricao:
      "EVENTOS DE CIRCO, FANTOCHE, MARIONETE; ORGANIZACAO DE, PROMOCAO DE",
  },
  { cnae: "9001904", descricao: "FANTOCHES; PRODUCAO DE ESPETACULO" },
  {
    cnae: "9001904",
    descricao: "EVENTO CULTURAL DE CIRCO, FANTOCHE, MARIONETE",
  },
  { cnae: "9001905", descricao: "RODEIO; PRODUCAO DE ESPETACULO DE" },
  { cnae: "9001905", descricao: "VAQUEJADA; PRODUCAO DE ESPETACULO DE" },
  { cnae: "9001906", descricao: "ILUMINACAO CENICA; SERVICOS DE" },
  { cnae: "9001906", descricao: "SONORIZACAO DE ESPACOS PARA ARTES CENICAS" },
  {
    cnae: "9001906",
    descricao: "FORNECIMENTO DE SOM PARA CASAS DE ESPETACULOS; SERVICOS DE",
  },
  {
    cnae: "9001906",
    descricao:
      "ILUMINACAO LIGADA AS ATIVIDADES ARTISTICAS, CENICAS; SERVICOS DE",
  },
  {
    cnae: "9001906",
    descricao: "TELAO COM OPERADOR; ATIVIDADE DE FORNECIMENTO DE",
  },
  {
    cnae: "9001906",
    descricao: "EQUIPAMENTO DE SOM COM OPERADOR; ATIVIDADE DE",
  },
  { cnae: "9001999", descricao: "OPERACAO DE CAMERA; SERVICOS DE" },
  {
    cnae: "9001999",
    descricao:
      "APRESENTADORES DE PROGRAMAS DE TELEVISAO E RADIO; ATIVIDADES DE",
  },
  {
    cnae: "9001999",
    descricao: "SERVICOS AUXILIARES AS ATIVIDADES ARTISTICAS",
  },
  { cnae: "9001999", descricao: "ELABORACAO DE ROTEIROS; ATIVIDADES DE" },
  { cnae: "9001999", descricao: "ESPETACULO PIROTECNICO" },
  {
    cnae: "9001999",
    descricao:
      "EFEITOS ESPECIAIS LIGADOS AS ATIVIDADES ARTISTICAS; SERVICOS DE",
  },
  {
    cnae: "9001999",
    descricao:
      "DIRETORES, PRODUTORES E EMPRESARIOS DE EVENTOS ARTISTICOS AO VIVO; ATIVIDADES DE",
  },
  { cnae: "9001999", descricao: "ESPETACULO DE FOGOS DE ARTIFICIO" },
  { cnae: "9001999", descricao: "ESPETACULO DE SOM E LUZ" },
  { cnae: "9001999", descricao: "MONTAGEM DE CENARIOS; SERVICOS DE" },
  {
    cnae: "9001999",
    descricao: "CENOGRAFIA LIGADA AS ATIVIDADES ARTISTICAS; SERVICOS DE",
  },
  {
    cnae: "9001999",
    descricao: "FIGURINOS ESTILIZADOS; SERVICOS DE CRIACAO DE",
  },
  { cnae: "90027", descricao: "CRIACAO ARTISTICA" },
  { cnae: "9002701", descricao: "ESCRITOR; ATIVIDADE DE" },
  { cnae: "9002701", descricao: "PINTOR ARTISTICO; ATIVIDADE DE" },
  { cnae: "9002701", descricao: "ARTISTAS PLASTICOS; ATIVIDADES DE" },
  {
    cnae: "9002701",
    descricao: "DIREITOS AUTORAIS DE OBRAS DE ARTE; GESTAO DE",
  },
  { cnae: "9002701", descricao: "CRIACAO ARTISTICA; ATIVIDADES DE" },
  { cnae: "9002701", descricao: "CRIADOR DE DESENHO ANIMADO; ATIVIDADE DE" },
  { cnae: "9002701", descricao: "PINTURA DE ARTESANATO; SERVICOS DE" },
  { cnae: "9002701", descricao: "ATIVIDADES LITERARIAS" },
  { cnae: "9002701", descricao: "ESCULTOR; ATIVIDADE DE" },
  { cnae: "9002701", descricao: "JORNALISTA INDEPENDENTE; ATIVIDADE DE" },
  { cnae: "9002701", descricao: "DESENHISTAS E COLORISTAS; ATIVIDADES DE" },
  { cnae: "9002701", descricao: "CARTUNISTA; SERVICOS DE" },
  { cnae: "9002701", descricao: "CRIACAO DE FOTOGRAVURAS; ATIVIDADE DE" },
  { cnae: "9002701", descricao: "ILUSTRADOR DE LIVROS; SERVICOS DE" },
  { cnae: "9002702", descricao: "RESTAURACAO DE OBRAS E IMAGENS SACRAS" },
  { cnae: "9002702", descricao: "RESTAURACAO DE ESCULTURAS; SERVICOS DE" },
  { cnae: "9002702", descricao: "OBJETOS DE ARTE; RESTAURACAO DE" },
  { cnae: "9002702", descricao: "RESTAURACAO DE QUADROS; SERVICOS DE" },
  { cnae: "9002702", descricao: "RESTAURACAO DE OBRAS DE ARTE; SERVICOS DE" },
  {
    cnae: "90035",
    descricao:
      "GESTAO DE ESPACOS PARA ARTES CENICAS, ESPETACULOS E OUTRAS ATIVIDADES ARTISTICAS",
  },
  { cnae: "9003500", descricao: "SALA DE SHOWS; ATIVIDADE DE GESTAO DE" },
  { cnae: "9003500", descricao: "CASA DE SHOWS" },
  { cnae: "9003500", descricao: "GESTAO DE SALAS DE ESPETACULOS" },
  { cnae: "9003500", descricao: "SALA DE ESPETACULOS" },
  { cnae: "9003500", descricao: "GESTAO DE SALAS DE TEATRO" },
  {
    cnae: "9003500",
    descricao: "GESTAO DE SALAS DEDICADAS A ATIVIDADES ARTISTICAS",
  },
  { cnae: "9003500", descricao: "CASA DE ESPETACULOS" },
  { cnae: "9003500", descricao: "CASA DE CULTURA" },
  { cnae: "9003500", descricao: "EXPLORACAO DE SALAS DE ESPETACULOS" },
  { cnae: "9003500", descricao: "EXPLORACAO DE CAFES-TEATRO" },
  { cnae: "9003500", descricao: "GESTAO DE SALAS DE MUSICA" },
  { cnae: "91015", descricao: "ATIVIDADES DE BIBLIOTECAS E ARQUIVOS" },
  { cnae: "9101500", descricao: "ARQUIVO; ATIVIDADE DE" },
  { cnae: "9101500", descricao: "GESTAO DE ARQUIVOS PUBLICOS" },
  { cnae: "9101500", descricao: "SALAS DE LEITURA" },
  {
    cnae: "9101500",
    descricao: "DOCUMENTACAO E PESQUISA BIBLIOGRAFICA; ATIVIDADE DE",
  },
  { cnae: "9101500", descricao: "BIBLIOTECA" },
  { cnae: "9101500", descricao: "GESTAO DE BIBLIOTECAS PUBLICAS" },
  { cnae: "9101500", descricao: "CATALOGACAO DE COLECOES; ATIVIDADES DE" },
  {
    cnae: "9101500",
    descricao:
      "EMPRESTIMO E ARMAZENAMENTO DE LIVROS, MAPAS, PERIODICOS, REVISTAS, FITAS DE VIDEO, DVDS, OBRAS DE ARTE, ETC; ATIVIDADES DE",
  },
  {
    cnae: "9101500",
    descricao:
      "RECUPERACAO DE INFORMACAO (BUSCA EM COLECOES DE BIBLIOTECAS OU ARQUIVOS); ATIVIDADES DE",
  },
  {
    cnae: "91023",
    descricao:
      "ATIVIDADES DE MUSEUS E DE EXPLORACAO, RESTAURACAO ARTISTICA E CONSERVACAO DE LUGARES E PREDIOS HISTORICOS E ATRACOES SIMILARES",
  },
  { cnae: "9102301", descricao: "EXPLORACAO DE LUGARES E PREDIOS HISTORICOS" },
  { cnae: "9102301", descricao: "CASA HISTORICA; ATIVIDADE DE" },
  { cnae: "9102301", descricao: "MUSEU DE ARTE; ATIVIDADE DE" },
  { cnae: "9102301", descricao: "MUSEU DE HISTORIA; ATIVIDADE DE" },
  { cnae: "9102301", descricao: "MUSEU DE CIENCIA E TECNOLOGIA; ATIVIDADE DE" },
  { cnae: "9102301", descricao: "PLANETARIO; ATIVIDADES DE" },
  { cnae: "9102301", descricao: "MUSEU" },
  { cnae: "9102301", descricao: "PINACOTECA; ATIVIDADES DE" },
  { cnae: "9102301", descricao: "GESTAO DE MUSEUS" },
  { cnae: "9102301", descricao: "MUSEU MILITAR; ATIVIDADE DE" },
  {
    cnae: "9102302",
    descricao: "RESTAURACAO ARTISTICA DE PREDIOS HISTORICOS; ATIVIDADES DE",
  },
  {
    cnae: "9102302",
    descricao: "CONSERVACAO DO PATRIMONIO HISTORICO; ATIVIDADE DE",
  },
  {
    cnae: "9102302",
    descricao: "CONSERVACAO DE LUGARES E EDIFICIOS HISTORICOS; ATIVIDADE DE",
  },
  {
    cnae: "91031",
    descricao:
      "ATIVIDADES DE JARDINS BOTANICOS, ZOOLOGICOS, PARQUES NACIONAIS, RESERVAS ECOLOGICAS E AREAS DE PROTECAO AMBIENTAL",
  },
  { cnae: "9103100", descricao: "JARDIM ZOOLOGICO E BOTANICO" },
  { cnae: "9103100", descricao: "JARDIM ZOOLOGICO" },
  { cnae: "9103100", descricao: "GESTAO DE JARDINS ZOOLOGICOS" },
  { cnae: "9103100", descricao: "PARQUE NACIONAL" },
  { cnae: "9103100", descricao: "JARDIM BOTANICO" },
  { cnae: "9103100", descricao: "GESTAO DE PARQUES NACIONAIS" },
  { cnae: "9103100", descricao: "GESTAO DE RESERVAS ECOLOGICAS" },
  { cnae: "9103100", descricao: "RESERVA ECOLOGICA" },
  { cnae: "9103100", descricao: "GESTAO DE JARDINS BOTANICOS" },
  {
    cnae: "92003",
    descricao: "ATIVIDADES DE EXPLORACAO DE JOGOS DE AZAR E APOSTAS",
  },
  { cnae: "9200301", descricao: "JOGO DE BINGO; ATIVIDADE DE" },
  { cnae: "9200301", descricao: "BINGO; EXPLORACAO DE" },
  { cnae: "9200301", descricao: "BINGO; CASA DE" },
  { cnae: "9200302", descricao: "CORRIDAS DE CAVALOS; EXPLORACAO DE APOSTAS" },
  {
    cnae: "9200302",
    descricao: "CAVALOS; EXPLORACAO DE APOSTAS EM CORRIDAS DE",
  },
  { cnae: "9200399", descricao: "JOGO DE DADOS; EXPLORACAO DE" },
  { cnae: "9200399", descricao: "JOGOS DE AZAR POR TELEFONE; EXPLORACAO DE" },
  { cnae: "9200399", descricao: "SORTEIOS POR TELEFONE; EXPLORACAO DE" },
  { cnae: "9200399", descricao: "JOGO DO BICHO; BANCA DE" },
  { cnae: "9200399", descricao: "JOGO DE BARALHO; CASA DE, BANCA DE" },
  { cnae: "9200399", descricao: "JOGOS DE AZAR E APOSTAS NA INTERNET" },
  { cnae: "9200399", descricao: "SORTEIO VIA TELEVISAO, TELEFONE, REVISTA" },
  { cnae: "9200399", descricao: "SALAO DE CARTEADO" },
  {
    cnae: "9200399",
    descricao:
      "MAQUINAS DE JOGOS E DE APOSTAS OPERADAS POR MOEDAS; OPERACAO DE",
  },
  { cnae: "9200399", descricao: "CASSINO; ATIVIDADE DE" },
  { cnae: "9200399", descricao: "JOGO DE ROLETA; EXPLORACAO DE" },
  { cnae: "9200399", descricao: "RINHA, BRIGA DE GALO; EXPLORACAO DE" },
  { cnae: "93115", descricao: "GESTAO DE INSTALACOES DE ESPORTES" },
  { cnae: "9311500", descricao: "ESTADIO DE ESPORTES" },
  { cnae: "9311500", descricao: "CENTRO DE EQUITACAO; EXPLORACAO DE" },
  { cnae: "9311500", descricao: "GESTAO DE ESTADIO" },
  { cnae: "9311500", descricao: "GESTAO DE GINASIO DE ESPORTES" },
  { cnae: "9311500", descricao: "ARENAS DE RODEIO; GESTAO DE" },
  { cnae: "9311500", descricao: "CAMPOS DE GOLFE; GESTAO DE" },
  { cnae: "9311500", descricao: "QUADRAS ESPORTIVAS; GESTAO DE" },
  { cnae: "9311500", descricao: "HIPODROMO; GESTAO DE" },
  { cnae: "9311500", descricao: "CAMPO OU QUADRA DE FUTEBOL; LOCACAO DE" },
  { cnae: "9311500", descricao: "GINASIO DE ESPORTES" },
  { cnae: "9311500", descricao: "ESTADIO DE FUTEBOL" },
  { cnae: "9311500", descricao: "PISCINAS ESPORTIVAS; GESTAO DE" },
  { cnae: "9311500", descricao: "GINASIO DE ESPORTES; GESTAO DE" },
  { cnae: "9311500", descricao: "ESTADIOS DE ATLETISMO; GESTAO DE" },
  { cnae: "9311500", descricao: "QUADRAS DE BASQUETE; GESTAO DE" },
  { cnae: "9311500", descricao: "QUADRAS DE TENIS; GESTAO DE" },
  { cnae: "9311500", descricao: "AUTODROMOS; GESTAO DE" },
  { cnae: "9311500", descricao: "PISTA DE PATINACAO; EXPLORACAO DE" },
  { cnae: "9311500", descricao: "GESTAO DE INSTALACOES ESPORTIVAS" },
  { cnae: "9311500", descricao: "QUADRAS DE BOXE; GESTAO DE" },
  { cnae: "9311500", descricao: "QUADRAS DE VOLEIBOL; GESTAO DE" },
  { cnae: "9311500", descricao: "QUADRAS POLIESPORTIVAS; GESTAO DE" },
  { cnae: "9311500", descricao: "ESTADIOS; GESTAO DE" },
  { cnae: "9311500", descricao: "PISTA DE GELO PARA PATINACAO; EXPLORACAO DE" },
  { cnae: "93123", descricao: "CLUBES SOCIAIS, ESPORTIVOS E SIMILARES" },
  { cnae: "9312300", descricao: "CLUBE DE FUTEBOL, PROFISSIONAL OU NAO" },
  { cnae: "9312300", descricao: "CLUBE SOCIAL" },
  { cnae: "9312300", descricao: "CLUBE DE GOLFE" },
  { cnae: "9312300", descricao: "CLUBE DE REGATAS" },
  { cnae: "9312300", descricao: "CLUBE DE REMO" },
  { cnae: "9312300", descricao: "CLUBE DE VOO" },
  { cnae: "9312300", descricao: "CLUBE DE VOLEI" },
  { cnae: "93131", descricao: "ATIVIDADES DE CONDICIONAMENTO FISICO" },
  { cnae: "9313100", descricao: "PERSONAL TRAINERS; SERVICOS DE" },
  { cnae: "9313100", descricao: "HIDROGINASTICA; ATIVIDADE DE" },
  { cnae: "9313100", descricao: "ALONGAMENTO CORPORAL" },
  { cnae: "9313100", descricao: "CONDICIONAMENTO FISICO; ATIVIDADES DE" },
  { cnae: "9313100", descricao: "GINASTICA E MUSCULACAO; ACADEMIA DE" },
  { cnae: "9313100", descricao: "AEROBICA; ATIVIDADE DE" },
  { cnae: "9313100", descricao: "ACADEMIA DE GINASTICA; ATIVIDADE DE" },
  { cnae: "9313100", descricao: "MUSCULACAO; CENTRO DE" },
  { cnae: "9313100", descricao: "GINASTICA LABORAL SERVICOS DE" },
  { cnae: "9313100", descricao: "IOGA" },
  { cnae: "9313100", descricao: "YOGA" },
  { cnae: "9313100", descricao: "ACADEMIA DE MUSCULACAO E AEROBICA" },
  { cnae: "9313100", descricao: "PILATES" },
  { cnae: "9313100", descricao: "FITNESS" },
  {
    cnae: "9313100",
    descricao: "EDUCACAO FISICA; ATIVIDADES DE INSTRUTORES DE",
  },
  { cnae: "9313100", descricao: "ANTI-GINASTICA" },
  {
    cnae: "93191",
    descricao: "ATIVIDADES ESPORTIVAS NAO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "9319101", descricao: "FEDERACAO ESPORTIVA" },
  {
    cnae: "9319101",
    descricao: "ORGANIZACAO DE CORRIDA DE MOTOS; ATIVIDADE DE",
  },
  { cnae: "9319101", descricao: "KENNELS CLUBES" },
  { cnae: "9319101", descricao: "REGULACAO ESPORTIVA; ATIVIDADES DE" },
  {
    cnae: "9319101",
    descricao:
      "ORGANIZACAO, PRODUCAO E PROMOCAO DE EVENTOS ESPORTIVOS; SERVICOS DE",
  },
  { cnae: "9319101", descricao: "ASSOCIACAO ESPORTIVA" },
  {
    cnae: "9319101",
    descricao: "ORGANIZACAO DE COMPETICOES HIPICAS; ATIVIDADES DE",
  },
  {
    cnae: "9319101",
    descricao: "ORGANIZACAO DE CORRIDA DE KARTS; ATIVIDADE DE",
  },
  { cnae: "9319101", descricao: "FEDERACAO DE ESPORTE" },
  {
    cnae: "9319101",
    descricao: "ORGANIZACAO DE CORRIDA DE AUTOMOVEIS; ATIVIDADE DE",
  },
  {
    cnae: "9319199",
    descricao: "TREINADORES ESPORTIVOS POR CONTA PROPRIA; ATIVIDADES DE",
  },
  {
    cnae: "9319199",
    descricao: "ARBITROS E JUIZES ESPORTIVOS POR CONTA PROPRIA; ATIVIDADES DE",
  },
  { cnae: "9319199", descricao: "BAIAS, PICADEIROS E SEMOVENTES; ALUGUEL DE" },
  { cnae: "9319199", descricao: "CACA ESPORTIVA; EXPLORACAO DE" },
  { cnae: "9319199", descricao: "PESQUE PAGUE, PESCA DE LAZER; ATIVIDADE DE" },
  {
    cnae: "9319199",
    descricao: "CRONOMETRAGEM PARA COMPETICOES ESPORTIVAS; SERVICOS DE",
  },
  { cnae: "9319199", descricao: "PESCA ESPORTIVA E DE LAZER; ATIVIDADE DE" },
  { cnae: "9319199", descricao: "ALPINISMO; CLUBE DE" },
  {
    cnae: "9319199",
    descricao: "ATLETAS ESPORTIVOS POR CONTA PROPRIA; ATIVIDADES DE",
  },
  { cnae: "9319199", descricao: "OPERACAO DE ESTABULOS DE HIPODROMOS" },
  { cnae: "9319199", descricao: "ALPINISMO; ATIVIDADE DE" },
  { cnae: "93212", descricao: "PARQUES DE DIVERSAO E PARQUES TEMATICOS" },
  { cnae: "9321200", descricao: "PARQUE TEMATICO; EXPLORACAO DE" },
  { cnae: "9321200", descricao: "PARQUE DE DIVERSAO; EXPLORACAO DE" },
  { cnae: "9321200", descricao: "PARQUE AQUATICO; EXPLORACAO DE" },
  {
    cnae: "93298",
    descricao:
      "ATIVIDADES DE RECREACAO E LAZER NAO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "9329801", descricao: "LAMBATERIA" },
  { cnae: "9329801", descricao: "DANCETERIA" },
  { cnae: "9329801", descricao: "CASA DE PAGODE" },
  { cnae: "9329801", descricao: "CASA DE FUNK" },
  { cnae: "9329801", descricao: "SALA DE DANCA" },
  { cnae: "9329801", descricao: "CASA DE DANCA; ATIVIDADE DE" },
  { cnae: "9329801", descricao: "FORRO; SALAO DE" },
  { cnae: "9329801", descricao: "DISCOTECA" },
  { cnae: "9329801", descricao: "SALAO DE BAILE; ATIVIDADE DE" },
  { cnae: "9329801", descricao: "BOITE; CASA DE DANCA" },
  { cnae: "9329801", descricao: "GAFIEIRA; SALAO DE" },
  { cnae: "9329801", descricao: "CABARE; ATIVIDADE DE" },
  { cnae: "9329801", descricao: "DANCING" },
  { cnae: "9329801", descricao: "BOATE; CASA DE DANCA" },
  { cnae: "9329802", descricao: "BOLICHE; EXPLORACAO DE" },
  { cnae: "9329803", descricao: "SINUCA; SALAO DE, EXPLORACAO DE" },
  { cnae: "9329803", descricao: "BILHAR; SALAO DE, EXPLORACAO DE" },
  {
    cnae: "9329804",
    descricao: "EXPLORACAO DE JOGOS NO COMPUTADOR; SERVICOS DE",
  },
  { cnae: "9329804", descricao: "JOGOS ELETRONICOS; EXPLORACAO DE" },
  {
    cnae: "9329804",
    descricao:
      "CYBER CAFE COM PREDOMINANCIA DE EXPLORACAO DE JOGOS ELETRONICOS E ACESSO A INTERNET",
  },
  { cnae: "9329804", descricao: "ACESSO A INTERNET PARA JOGOS EM REDE" },
  {
    cnae: "9329804",
    descricao:
      "LAN HOUSE COM ACESSO A INTERNET PREDOMINANTEMENTE PARA JOGOS EM REDE",
  },
  { cnae: "9329804", descricao: "JOGO DE FLIPERAMA; EXPLORACAO DE" },
  { cnae: "9329899", descricao: "SITIOS DE LAZER; ALUGUEL DE" },
  { cnae: "9329899", descricao: "SHOWS DE NATUREZA RECREACIONAL" },
  { cnae: "9329899", descricao: "CHARRETES PARA PASSEIO; LOCACAO DE" },
  {
    cnae: "9329899",
    descricao: "EMBARCACOES PARA FINS RECREATIVOS; LOCACAO DE",
  },
  { cnae: "9329899", descricao: "BARCOS RECREATIVOS; LOCACAO DE" },
  { cnae: "9329899", descricao: "VAGAS PARA BARCOS; SERVICOS DE" },
  {
    cnae: "9329899",
    descricao: "CARRINHOS ELETRICOS EM CAMPO DE GOLFE; LOCACAO DE",
  },
  {
    cnae: "9329899",
    descricao: "ESTADIA PARA EMBARCACOES DE ESPORTE E LAZER; SERVICOS DE",
  },
  { cnae: "9329899", descricao: "MINI BUGGY; LOCACAO DE, ALUGUEL DE" },
  { cnae: "9329899", descricao: "PEDALINHOS; LOCACAO DE" },
  { cnae: "9329899", descricao: "AQUARIO PARA VISITACAO" },
  { cnae: "9329899", descricao: "COLONIA DE FERIAS" },
  { cnae: "9329899", descricao: "PAINT BALL; ATIVIDADES DE RECREACAO COM" },
  { cnae: "9329899", descricao: "VAGAS PARA EMBARCACOES; SERVICOS DE" },
  {
    cnae: "9329899",
    descricao: "TRENZINHO PARA PASSEIOS TURISTICOS;  EXPLORACAO DE",
  },
  { cnae: "9329899", descricao: "KARTS; EXPLORACAO DE" },
  {
    cnae: "9329899",
    descricao: "ENTRETENIMENTO INFANTIL; PRESTACAO DE SERVICOS DE",
  },
  { cnae: "9329899", descricao: "VEICULOS DE TRACAO ANIMAL; TRANSPORTE DE" },
  { cnae: "9329899", descricao: "GARAGEM DE BARCOS; LANCHAS" },
  { cnae: "9329899", descricao: "PRANCHAS DE SURFE; LOCACAO, ALUGUEL DE" },
  {
    cnae: "9329899",
    descricao: "PEBOLIM, FUTEBOL DE MESA, TOTO; EXPLORACAO DE",
  },
  { cnae: "9329899", descricao: "GUARDA DE EMBARCACOES; SERVICOS DE" },
  { cnae: "9329899", descricao: "JOGO DE SNOOKER; EXPLORACAO DE" },
  { cnae: "9329899", descricao: "MARINA; GUARDA DE BARCOS, IATES, JET SKI" },
  { cnae: "9329899", descricao: "GARAGEM NAUTICA; GUARDA DE BARCOS DE LAZER" },
  { cnae: "9329899", descricao: "NAUTICA E LAZER; LOCACAO DE, ALUGUEL DE" },
  { cnae: "9329899", descricao: "ESCUNAS PARA FINS RECREATIVOS; LOCACAO DE" },
  {
    cnae: "9329899",
    descricao: "ANIMACAO E RECREACAO EM FESTAS E EVENTOS; ATIVIDADES DE",
  },
  { cnae: "9329899", descricao: "BARCOS PARA LAZER; LOCACAO DE" },
  { cnae: "9329899", descricao: "ESTADIA DE BARCOS; SERVICOS DE" },
  {
    cnae: "9329899",
    descricao: "BICICLETAS PARA FINS RECREATIVOS; EXPLORACAO, LOCACAO DE",
  },
  { cnae: "9329899", descricao: "ESCUNAS PARA LAZER; LOCACAO DE" },
  { cnae: "9329899", descricao: "EMBARCACOES PARA LAZER; LOCACAO DE" },
  { cnae: "9329899", descricao: "TRENZINHO RECREACIONAL; EXPLORACAO DE" },
  {
    cnae: "94111",
    descricao:
      "ATIVIDADES DE ORGANIZACOES ASSOCIATIVAS PATRONAIS E EMPRESARIAIS",
  },
  { cnae: "9411100", descricao: "CONFEDERACAO EMPRESARIAL" },
  { cnae: "9411100", descricao: "CAMARA DE COMERCIO EMPRESARIAL" },
  { cnae: "9411100", descricao: "ORGANIZACOES EMPRESARIAIS E PATRONAIS" },
  { cnae: "9411100", descricao: "ASSOCIACAO EMPRESARIAL" },
  {
    cnae: "94120",
    descricao: "ATIVIDADES DE ORGANIZACOES ASSOCIATIVAS PROFISSIONAIS",
  },
  {
    cnae: "9412001",
    descricao:
      "ORGAOS, CONSELHOS REGULADORES E FISCALIZADORES DO CUMPRIMENTO DE NORMAS PROFISSIONAIS",
  },
  { cnae: "9412099", descricao: "ECONOMISTAS; ASSOCIACOES DE" },
  { cnae: "9412099", descricao: "PROFISSIONAIS MEDICOS; ASSOCIACOES DE" },
  { cnae: "9412099", descricao: "ARQUITETOS; ASSOCIACOES DE" },
  { cnae: "9412099", descricao: "CONTADORES; ASSOCIACOES DE" },
  { cnae: "9412099", descricao: "ASSOCIACAO PROFISSIONAL; ENTIDADE DE" },
  { cnae: "9412099", descricao: "ENGENHEIROS; ASSOCIACOES DE" },
  { cnae: "9412099", descricao: "ORGANIZACAO DE PROFISSIONAIS" },
  {
    cnae: "9412099",
    descricao: "ASSOCIACOES ARTISTICAS; DE ATORES, DE PINTORES",
  },
  { cnae: "9412099", descricao: "ADVOGADOS; ASSOCIACOES DE" },
  { cnae: "9609299", descricao: "VALET PARKING" },
  { cnae: "94201", descricao: "ATIVIDADES DE ORGANIZACOES SINDICAIS" },
  { cnae: "9420100", descricao: "CONFEDERACAO SINDICAL" },
  { cnae: "9420100", descricao: "ASSOCIACAO DE EMPREGADOS" },
  { cnae: "9420100", descricao: "CONFEDERACAO DE TRABALHADORES" },
  { cnae: "9420100", descricao: "SINDICATO DE TRABALHADORES" },
  { cnae: "9420100", descricao: "ORGANIZACAO SINDICAL" },
  { cnae: "9420100", descricao: "CENTRAL SINDICAL" },
  { cnae: "9420100", descricao: "ASSOCIACAO DE TRABALHADORES" },
  {
    cnae: "94308",
    descricao: "ATIVIDADES DE ASSOCIACOES DE DEFESA DE DIREITOS SOCIAIS",
  },
  {
    cnae: "9430800",
    descricao:
      "ATIVIDADE DE OPERACAO DE CENTRAIS DE DISQUE DENUNCIA QUANDO REALIZADO POR ENTIDADES SEM FINS LUCRATIVOS",
  },
  { cnae: "9430800", descricao: "ASSOCIACAO, ONG, DE GRUPOS MINORITARIOS" },
  { cnae: "9430800", descricao: "ASSOCIACAO DE PROTECAO DE MINORIAS ETNICAS" },
  { cnae: "9430800", descricao: "ASSOCIACAO, ONG, DE DEFESA DO MEIO AMBIENTE" },
  { cnae: "9430800", descricao: "ASSOCIACAO, ONG, DE MOVIMENTOS ECOLOGICOS" },
  {
    cnae: "9430800",
    descricao: "ASSOCIACAO, ONG, DE DEFESA DOS DIREITOS HUMANOS",
  },
  { cnae: "94910", descricao: "ATIVIDADES DE ORGANIZACOES RELIGIOSAS" },
  { cnae: "9491000", descricao: "TERREIRO DE UMBANDA" },
  { cnae: "9491000", descricao: "ORGANIZACAO FILOSOFICA" },
  { cnae: "9491000", descricao: "CASA DE BENCAO" },
  { cnae: "9491000", descricao: "CENTRO DE UMBANDA" },
  { cnae: "9491000", descricao: "CENTRO ESPIRITA" },
  { cnae: "9491000", descricao: "TEMPLO" },
  { cnae: "9491000", descricao: "MESQUITA" },
  { cnae: "9491000", descricao: "CULTO RELIGIOSO; CELEBRACAO E ORGANIZACAO" },
  { cnae: "9491000", descricao: "CASA MACONICA" },
  { cnae: "9491000", descricao: "TENDA ESPIRITA" },
  { cnae: "9491000", descricao: "IGREJA" },
  { cnae: "9491000", descricao: "ARQUIDIOCESE" },
  { cnae: "9491000", descricao: "INSTITUICAO RELIGIOSA" },
  { cnae: "9491000", descricao: "CASA DE ORACAO" },
  { cnae: "9491000", descricao: "CATEQUESE" },
  { cnae: "9491000", descricao: "MISSAO RELIGIOSA" },
  { cnae: "9491000", descricao: "IRMANDADE RELIGIOSA" },
  { cnae: "9491000", descricao: "TERREIRO DE MACUMBA" },
  { cnae: "9491000", descricao: "TERREIRO DE CANDOMBLE" },
  { cnae: "9491000", descricao: "SINAGOGA" },
  { cnae: "9491000", descricao: "ORGANIZACAO RELIGIOSA" },
  { cnae: "9491000", descricao: "MOSTEIRO" },
  { cnae: "9491000", descricao: "CONVENTO" },
  { cnae: "9491000", descricao: "CERIMONIAS RELIGIOSAS DE HONRAS FUNEBRES" },
  { cnae: "9491000", descricao: "TEMPLO RELIGIOSO" },
  { cnae: "9491000", descricao: "CONGREGACAO RELIGIOSA" },
  { cnae: "94928", descricao: "ATIVIDADES DE ORGANIZACOES POLITICAS" },
  {
    cnae: "9492800",
    descricao: "ARRECADACAO DE FUNDOS PARA CAMPANHAS POLITICAS; ATIVIDADE DE",
  },
  { cnae: "9492800", descricao: "PARTIDO POLITICO" },
  { cnae: "9492800", descricao: "ORGANIZACAO POLITICA" },
  { cnae: "9492800", descricao: "DIRETORIO REGIONAL DE PARTIDO POLITICO" },
  { cnae: "9492800", descricao: "COMITE POLITICO; ORGANIZACAO DE" },
  {
    cnae: "94936",
    descricao:
      "ATIVIDADES DE ORGANIZACOES ASSOCIATIVAS LIGADAS A CULTURA E A ARTE",
  },
  { cnae: "9493600", descricao: "ORGANIZACAO ASSOCIATIVA DE ARTESANATO" },
  { cnae: "9493600", descricao: "CLUBE DE ARTESANATO" },
  { cnae: "9493600", descricao: "ARTE; ASSOCIACOES DE" },
  { cnae: "9493600", descricao: "CLUBE DE COLECIONADORES" },
  { cnae: "9493600", descricao: "CLUBE DE CINEMA" },
  { cnae: "9493600", descricao: "ORGANIZACAO ASSOCIATIVA DE COLECIONADORES" },
  { cnae: "9493600", descricao: "CLUBE DE FOTOGRAFIA" },
  { cnae: "9493600", descricao: "ASSOCIACAO CARNAVALESCA" },
  { cnae: "9493600", descricao: "ASSOCIACAO DE BLOCOS CARNAVALESCOS" },
  { cnae: "9493600", descricao: "CLUBE LITERARIO" },
  { cnae: "9493600", descricao: "BLOCOS CARNAVALESCOS; ATIVIDADE DE" },
  { cnae: "9493600", descricao: "ASSOCIACAO CULTURAL" },
  { cnae: "9493600", descricao: "CLUBE CARNAVALESCO" },
  { cnae: "9493600", descricao: "ESCOLA DE SAMBA (AGREMIACOES)" },
  { cnae: "9493600", descricao: "MUSICA; ASSOCIACOES DE" },
  { cnae: "9493600", descricao: "CLUBE DE MUSICA E ARTE" },
  {
    cnae: "94995",
    descricao: "ATIVIDADES ASSOCIATIVAS NAO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "9499500", descricao: "ASSOCIACAO DE BAIRROS" },
  { cnae: "9499500", descricao: "SOCIEDADE DE ANIMAIS" },
  { cnae: "9499500", descricao: "FRATERNIDADES" },
  { cnae: "9499500", descricao: "ASSOCIACAO DE VETERANOS" },
  { cnae: "9499500", descricao: "ASSOCIACAO COMUNITARIA" },
  { cnae: "9499500", descricao: "ASSOCIACAO DE PROTECAO DE ANIMAIS" },
  {
    cnae: "9499500",
    descricao:
      "SUPORTE OPERACIONAL A ATIVIDADES EXECUTADAS POR GRUPOS DE AUTOAJUDA, OU AJUDA MUTUA EM PROGRAMAS DE RECUPERACAO DE DEPENDENCIA AFETIVA A VICIOS EM ALCOOL, DROGAS, JOGOS, E GRUPOS SIMILARES; SERVICOS DE",
  },
  { cnae: "9499500", descricao: "CLUBE DE CIENCIAS, DE ASTRONOMIA" },
  { cnae: "9499500", descricao: "ATIVIDADES DE CENTRO ACADEMICOS" },
  { cnae: "9499500", descricao: "SOCIEDADE PROTETORA DOS ANIMAIS" },
  { cnae: "9499500", descricao: "ORGANIZACAO DE CARIDADE" },
  { cnae: "9499500", descricao: "ORGANIZACAO SOCIAL, CIVIL, FRATERNAL" },
  { cnae: "9499500", descricao: "ASSOCIACAO DE ESCOTEIROS" },
  { cnae: "9499500", descricao: "ASSOCIACAO DE PAIS DE ALUNOS" },
  { cnae: "9499500", descricao: "FA CLUBES" },
  { cnae: "9499500", descricao: "ASSOCIACAO DOS EX-COMBATENTES" },
  { cnae: "9499500", descricao: "ORGANIZACAO DAS BANDEIRANTES" },
  { cnae: "9499500", descricao: "ASSOCIACAO VOLUNTARIA DE SAUDE" },
  { cnae: "9499500", descricao: "ASSOCIACAO E CLUBE ESTUDANTIL" },
  { cnae: "9499500", descricao: "ASSOCIACAO DE BOMBEIROS VOLUNTARIOS" },
  { cnae: "9499500", descricao: "ROTARY CLUB" },
  { cnae: "9499500", descricao: "CONSUMIDORES; ASSOCIACAO DE" },
  { cnae: "9499500", descricao: "ATIVIDADES DE DIRETORIO ESTUDANTIL" },
  { cnae: "9499500", descricao: "ASSOCIACAO DE MULHERES" },
  {
    cnae: "95118",
    descricao:
      "REPARACAO E MANUTENCAO DE COMPUTADORES E DE EQUIPAMENTOS PERIFERICOS",
  },
  {
    cnae: "9511800",
    descricao: "IMPRESSORAS; MANUTENCAO DE, REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "9511800",
    descricao: "MICROCOMPUTADORES; MANUTENCAO DE, REPARACAO DE",
  },
  {
    cnae: "9511800",
    descricao: "SCANNERS (ESCANER); MANUTENCAO DE, REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "9511800",
    descricao: "PROJETOR; CONSERTO, REPARACAO, MANUTENCAO DE",
  },
  { cnae: "9511800", descricao: "COMPUTADORES; CONSERTO DE, REPARO DE" },
  {
    cnae: "9511800",
    descricao:
      "MAQUINA COPIADORA, XEROGRAFICA, FOTOSTATICA; ASSISTENCIA TECNICA EM",
  },
  {
    cnae: "9511800",
    descricao:
      "SUPORTE E MANUTENCAO DE HARDWARE, INCLUSIVE UPGRADE; SERVICOS DE",
  },
  {
    cnae: "9511800",
    descricao:
      "TERMINAIS DE AUTO-ATENDIMENTO DE BANCOS; MANUTENCAO DE, REPARACAO DE",
  },
  {
    cnae: "9511800",
    descricao:
      "EQUIPAMENTOS DE INFORMATICA; MANUTENCAO DE, REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "9511800",
    descricao: "ASSISTENCIA TECNICA EM COMPUTADORES; SERVICOS DE",
  },
  {
    cnae: "9511800",
    descricao: "TECLADO E MOUSE; CONSERTO, REPARACAO, MANUTENCAO DE",
  },
  {
    cnae: "9511800",
    descricao: "CAIXAS ELETRONICOS DE BANCOS; MANUTENCAO DE, REPARACAO DE",
  },
  {
    cnae: "9511800",
    descricao: "EQUIPAMENTO PERIFERICO CONEXO; MANUTENCAO DE, REPARACAO DE",
  },
  {
    cnae: "9511800",
    descricao:
      "EQUIPAMENTOS DE PROCESSAMENTO DE DADOS; MANUTENCAO DE, REPARACAO DE",
  },
  {
    cnae: "9511800",
    descricao: "EQUIPAMENTOS EMISSORES DE CUPOM FISCAL; MANUTENCAO DE",
  },
  {
    cnae: "9511800",
    descricao: "ASSISTENCIA TECNICA EM MICROCOMPUTADORES; SERVICOS DE",
  },
  { cnae: "9511800", descricao: "COMPUTADORES; MANUTENCAO, REPARACAO DE" },
  {
    cnae: "9511800",
    descricao: "APARELHOS DE INFORMATICA; MANUTENCAO DE, REPARACAO DE",
  },
  {
    cnae: "9511800",
    descricao:
      "ASSISTENCIA TECNICA EM EQUIPAMENTOS DE INFORMATICA; SERVICOS DE",
  },
  {
    cnae: "9511800",
    descricao:
      "MAQUINA DE CARTAO DE CREDITO; MANUTENCAO DE, REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "95126",
    descricao: "REPARACAO E MANUTENCAO DE EQUIPAMENTOS DE COMUNICACAO",
  },
  {
    cnae: "9512600",
    descricao: "TELEFONIA FIXA, EQUIPAMENTOS DE; MANUTENCAO DE, REPARACAO DE",
  },
  { cnae: "9512600", descricao: "MODEMS; REPARACAO E MANUTENCAO DE" },
  {
    cnae: "9512600",
    descricao:
      "EQUIPAMENTOS DE RADIOTELEFONIA E RADIOTELEGRAFIA, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "9512600",
    descricao: "CELULAR, TELEFONE; REPARACAO DE, MANUTENCAO DE",
  },
  {
    cnae: "9512600",
    descricao:
      "TELEFONIA MOVEL CELULAR; MANUTENCAO,  REPARACAO DE EQUIPAMENTOS DE",
  },
  { cnae: "9512600", descricao: "ROTEADORES; REPARACAO E MANUTENCAO DE" },
  {
    cnae: "9512600",
    descricao:
      "TELEFONE; CONSERTO, REPARACAO, MANUTENCAO, ASSISTENCIA TECNICA DE",
  },
  {
    cnae: "9512600",
    descricao:
      "APARELHO TELEFONICO DE USO PUBLICO; REPARACAO DE, MANUTENCAO DE",
  },
  {
    cnae: "9512600",
    descricao:
      "EQUIPAMENTOS PARA ESTACOES TELEFONICAS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "9512600",
    descricao:
      "EQUIPAMENTOS TRANSMISSORES DE RADIO E TELEVISAO, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "9512600",
    descricao: "SISTEMAS DE INTERCOMUNICACAO; MANUTENCAO DE, REPARACAO DE",
  },
  {
    cnae: "9512600",
    descricao:
      "EQUIPAMENTOS DE CENTRAIS TELEFONICAS, MANUTENCAO E REPARACAO EXECUTADA POR UNIDADE ESPECIALIZADA",
  },
  {
    cnae: "9512600",
    descricao:
      "SISTEMAS DE CIRCUITOS INTERNOS DE SEGURANCA; MANUTENCAO DE,  REPARACAO DE",
  },
  {
    cnae: "9512600",
    descricao:
      "CAMERAS DE TELEVISAO E DE VIDEO DE USO PROFISSIONAL; REPARACAO E MANUTENCAO DE",
  },
  {
    cnae: "9512600",
    descricao:
      "RADIO DE TRANSMISSAO-RECEPCAO; REPARACAO E MANUTENCAO DE EQUIPAMENTOS DE",
  },
  {
    cnae: "9512600",
    descricao: "APARELHO TELEFONICO; REPARACAO DE, MANUTENCAO DE",
  },
  { cnae: "9512600", descricao: "FAX; REPARACAO, MANUTENCAO DE" },
  {
    cnae: "9512600",
    descricao: "EQUIPAMENTOS DE COMUNICACAO; REPARACAO DE, MANUTENCAO DE",
  },
  {
    cnae: "95215",
    descricao:
      "REPARACAO E MANUTENCAO DE EQUIPAMENTOS ELETROELETRONICOS DE USO PESSOAL E DOMESTICO",
  },
  {
    cnae: "9521500",
    descricao: "EQUIPAMENTOS DOMESTICOS; CONSERTO, MANUTENCAO, REPARACAO DE",
  },
  {
    cnae: "9521500",
    descricao: "PECAS E ACESSORIOS PARA ELETRODOMESTICOS; INSTALACAO DE",
  },
  {
    cnae: "9521500",
    descricao: "MAQUINAS DOMESTICAS; MANUTENCAO DE, REPARACAO DE",
  },
  { cnae: "9521500", descricao: "RADIOS; REPARACAO DE, CONSERTO DE" },
  {
    cnae: "9521500",
    descricao:
      "MAQUINAS E EQUIPAMENTOS FOTOGRAFICOS; REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "9521500",
    descricao:
      "APARELHOS E EQUIPAMENTOS DE AR CONDICIONADO PARA USO NAO INDUSTRIAL, QUANDO EXECUTADA POR UNIDADE ESPECIALIZADA; INSTALACAO E MANUTENCAO DE",
  },
  {
    cnae: "9521500",
    descricao: "AR CONDICIONADO DOMESTICO; CONSERTO, MANUTENCAO, REPARACAO DE",
  },
  {
    cnae: "9521500",
    descricao: "AQUECEDORES; MAUTENCAO DE, REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "9521500",
    descricao: "MAQUINA DE LAVAR LOUCAS; REPARACAO DE, CONSERTO DE",
  },
  { cnae: "9521500", descricao: "OFICINA DE ELETRODOMESTICOS" },
  {
    cnae: "9521500",
    descricao:
      "APARELHO DE REPRODUCAO E GRAVACAO DE CDS E DVDS; REPARACAO E MANUTENCAO DE",
  },
  {
    cnae: "9521500",
    descricao:
      "APARELHOS DE REFRIGERACAO DOMESTICOS; MANUTENCAO DE, REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "9521500",
    descricao: "ELETRONICOS DOMESTICOS; CONSERTO, MANUTENCAO, REPARACAO DE",
  },
  {
    cnae: "9521500",
    descricao: "APARELHOS ELETRODOMESTICOS; CONSERTO, MANUTENCAO, REPARACAO DE",
  },
  {
    cnae: "9521500",
    descricao:
      "ANTENAS E SEUS RECEPTORES PARA USO INDIVIDUAL E COLETIVO; MANUTENCAO E REPARACAO DE",
  },
  {
    cnae: "9521500",
    descricao: "ELETRODOMESTICOS; CONSERTO, MANUTENCAO, REPARACAO DE",
  },
  {
    cnae: "9521500",
    descricao:
      "CAMERAS FOTOGRAFICAS DE USO DOMESTICO; REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "9521500",
    descricao:
      "GELADEIRA, REFRIGERADOR E FREEZER PARA USO DOMESTICO; CONSERTO, MANUTENCAO, REPARACAO DE",
  },
  {
    cnae: "9521500",
    descricao:
      "TELEVISAO, TELEVISOR, TV; MANUTENCAO DE, REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "9521500",
    descricao:
      "MAQUINAS DE COSTURA DE USO DOMESTICO; REPARACAO E MANUTENCAO DE",
  },
  {
    cnae: "9521500",
    descricao: "FOGAO DOMESTICO; MANUTENCAO, REPARACAO, CONSERTO DE",
  },
  {
    cnae: "9521500",
    descricao: "MAQUINA DE LAVAR ROUPAS; REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "9521500",
    descricao: "MAQUINAS SECADORAS DE ROUPA E LOUCA; REPARACAO E MANUTENCAO DE",
  },
  { cnae: "9521500", descricao: "OFICINA DE ELETRONICOS DOMESTICOS" },
  {
    cnae: "9521500",
    descricao:
      "ALARMES DE USO DOMESTICO; MANUTENCAO DE, REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "9521500",
    descricao: "AR CONDICIONANDO PARA RESIDENCIAS; MANUTENCAO DE, REPARACAO DE",
  },
  {
    cnae: "9521500",
    descricao:
      "FILTRO, PURIFICADOR DE AGUA, OZONIZADORES; MANUTENCAO DE, REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "9521500",
    descricao: "ALARMES RESIDENCIAIS; MANUTENCAO DE, REPARACAO DE, CONSERTO DE",
  },
  { cnae: "9521500", descricao: "FILMADORAS; REPARACAO, CONSERTO DE" },
  {
    cnae: "9521500",
    descricao: "APARELHOS DOMESTICOS; MANUTENCAO DE, REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "9521500",
    descricao:
      "APARELHOS DE SOM E VIDEO; MANUTENCAO DE, REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "9521500",
    descricao: "VIDEO CASSETE; CONSERTO, MANUTENCAO , REPARACAO DE",
  },
  {
    cnae: "95291",
    descricao:
      "REPARACAO E MANUTENCAO DE OBJETOS E EQUIPAMENTOS PESSOAIS E DOMESTICOS NAO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "9529101", descricao: "SAPATEIRO" },
  { cnae: "9529101", descricao: "TENIS; REPARACAO DE, CONSERTO DE" },
  { cnae: "9529101", descricao: "BOLSAS; REPARACAO DE, CONSERTO DE" },
  {
    cnae: "9529101",
    descricao:
      "CALCADOS DE QUALQUER MATERIAL; RENOVACAO, REPARACAO, CONSERTO DE",
  },
  {
    cnae: "9529101",
    descricao: "ARTIGOS DE VIAGEM; REPARACAO DE, CONSERTO DE",
  },
  { cnae: "9529101", descricao: "RENOVADORA DE CALCADOS" },
  { cnae: "9529101", descricao: "SAPATOS; RENOVACAO, REPARACAO, CONSERTO DE" },
  { cnae: "9529102", descricao: "CHAVEIRO" },
  { cnae: "9529102", descricao: "COPIA DE CHAVES; OFICINA DE" },
  { cnae: "9529102", descricao: "CADEADOS; REPARACAO DE, CONSERTO DE" },
  { cnae: "9529102", descricao: "FECHADURAS; REPARACAO DE, CONSERTO DE" },
  { cnae: "9529103", descricao: "CRONOMETROS; REPARACAO E MANUTENCAO DE" },
  {
    cnae: "9529103",
    descricao: "ARTIGOS DE RELOJOARIA; CONSERTO DE, REPARACAO DE",
  },
  { cnae: "9529103", descricao: "RELOGIOS; REPARACAO DE, CONSERTO DE" },
  {
    cnae: "9529104",
    descricao: "PNEUS E CAMARAS DE AR DE BICICLETAS; REPARACAO DE, CONSERTO DE",
  },
  { cnae: "9529104", descricao: "TRICICLO; REPARACAO DE, CONSERTO DE" },
  {
    cnae: "9529104",
    descricao: "BICICLETAS; CONSERTO, REPARACAO, MANUTENCAO DE",
  },
  { cnae: "9529104", descricao: "BICICLETAS; INSTALACAO DE ACESSORIOS" },
  { cnae: "9529104", descricao: "OFICINA DE BICICLETAS" },
  {
    cnae: "9529104",
    descricao: "VEICULOS NAO MOTORIZADOS; REPARACAO E MANUTENCAO DE",
  },
  {
    cnae: "9529104",
    descricao:
      "PINTURA DE BICICLETAS, TRICICLOS E DE OUTROS VEICULOS RECREATIVOS; SERVICOS DE",
  },
  { cnae: "9529105", descricao: "ESTOFARIA; SERVICOS DE" },
  { cnae: "9529105", descricao: "MOBILIARIO; REPARACAO DE, CONSERTO DE" },
  { cnae: "9529105", descricao: "ARMARIOS; CONSERTO DE, REPARACAO DE" },
  { cnae: "9529105", descricao: "ARTIGOS DO MOBILIARIO; ESTOFAMENTO DE" },
  {
    cnae: "9529105",
    descricao: "ARTIGOS DE TAPECARIA; REPARACAO DE, CONSERTO DE",
  },
  { cnae: "9529105", descricao: "SOFAS; REPARACAO DE" },
  { cnae: "9529105", descricao: "EMPALHAMENTO DE CADEIRAS; SERVICOS DE" },
  { cnae: "9529105", descricao: "PERSIANAS; REPARACAO DE, CONSERTO DE" },
  { cnae: "9529105", descricao: "MOVEIS; REPARACAO, CONSERTO, RESTAURACAO DE" },
  {
    cnae: "9529105",
    descricao: "ESTOFADOS; REPARACAO DE, CONSERTO DE, REFORMA DE",
  },
  { cnae: "9529105", descricao: "ESTOFADOR; SERVICOS DE" },
  { cnae: "9529105", descricao: "EMPALHADOR; SERVICOS DE" },
  {
    cnae: "9529105",
    descricao: "TOLDOS; REPARACAO DE, MANUTENCAO DE, CONSERTO DE",
  },
  {
    cnae: "9529105",
    descricao: "ARTIGOS DE MADEIRA; REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "9529105",
    descricao: "ARTIGOS DO MOBILIARIO; REPARACAO DE, CONSERTO DE",
  },
  { cnae: "9529105", descricao: "IMPERMEABILIZACAO DE MOVEIS; SERVICOS DE" },
  { cnae: "9529106", descricao: "JOIAS; REPARACAO, CONSERTO DE" },
  { cnae: "9529106", descricao: "GRAVACAO EM JOIAS; SERVICOS DE" },
  { cnae: "9529199", descricao: "LIVROS; RESTAURACAO DE" },
  { cnae: "9529199", descricao: "PANELEIRO; SERVICOS DE" },
  { cnae: "9529199", descricao: "ARTIGOS DE METAL; REPARACAO DE, CONSERTO DE" },
  { cnae: "9529199", descricao: "OCULOS; REPARACAO DE, CONSERTO DE" },
  { cnae: "9529199", descricao: "GUARDA-CHUVA; REPARACAO DE, CONSERTO DE" },
  { cnae: "9529199", descricao: "AFIADOR DE ARTIGOS DE CUTELARIA" },
  { cnae: "9529199", descricao: "MALA; REPARACAO DE, CONSERTO DE" },
  {
    cnae: "9529199",
    descricao: "ARTIGOS DE TECIDOS; REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "9529199",
    descricao: "REDES DE DORMIR DOMESTICAS; REPARACAO DE, CONSERTO DE",
  },
  { cnae: "9529199", descricao: "PIANOS; AFINACAO DE" },
  {
    cnae: "9529199",
    descricao: "SELAS, ARREIOS, REDEAS; REPARACAO DE, CONSERTO DE",
  },
  {
    cnae: "9529199",
    descricao: "ARTIGOS DE BORRACHA; REPARACAO DE, CONSERTO DE",
  },
  { cnae: "9529199", descricao: "AMOLADOR DE ARTIGOS DE CUTELARIA" },
  { cnae: "9529199", descricao: "ARTIGOS DE PELE; REPARACAO DE, CONSERTO DE" },
  {
    cnae: "9529199",
    descricao: "ARTIGOS DE CUTELARIA; REPARACAO DE, CONSERTO DE",
  },
  { cnae: "9529199", descricao: "LONA; REPARACAO DE, CONSERTO DE" },
  { cnae: "9529199", descricao: "VIOLAO; CONSERTO DE, REPARACAO DE" },
  { cnae: "9529199", descricao: "ARTIGOS OTICOS; CONSERTO DE, REPARACAO DE" },
  {
    cnae: "9529199",
    descricao: "ACESSORIOS DO VESTUARIO; RECUPERACAO, REPARACAO, CONSERTO DE",
  },
  { cnae: "9529199", descricao: "CONSERTO DE ROUPAS; SERVICOS DE" },
  { cnae: "9529199", descricao: "PANELAS, BALDES; REPARACAO DE, CONSERTO DE" },
  {
    cnae: "9529199",
    descricao: "INSTRUMENTOS MUSICAIS; REPARACAO DE, CONSERTO DE",
  },
  { cnae: "9529199", descricao: "AMOLADOR DE FACAS;ATIVIDADE DE" },
  { cnae: "9529199", descricao: "BRINQUEDOS; REPARACAO DE, CONSERTO DE" },
  { cnae: "9529199", descricao: "CERZIDEIRA; SERVICOS DE" },
  {
    cnae: "9529199",
    descricao: "ARTIGOS DO VESTUARIO; RECUPERACAO, REPARACAO, CONSERTO DE",
  },
  {
    cnae: "9529199",
    descricao: "CALCAS, CAMISAS, TERNOS, VESTIDOS; CONSERTO DE, REPARACAO DE",
  },
  { cnae: "96017", descricao: "LAVANDERIAS, TINTURARIAS E TOALHEIROS" },
  { cnae: "9601701", descricao: "LAVAGEM DE TAPETE; SERVICOS DE" },
  { cnae: "9601701", descricao: "ROUPAS DE COURO E PELE; LAVAGEM DE" },
  { cnae: "9601701", descricao: "LAVAGEM DE CORTINA; SERVICOS DE" },
  { cnae: "9601701", descricao: "POSTO DE COLETA DE LAVANDERIA" },
  { cnae: "9601701", descricao: "LAVANDERIA; SERVICOS DE" },
  { cnae: "9601701", descricao: "PASSADORIA DE ROUPAS; SERVICOS DE" },
  { cnae: "9601701", descricao: "LIMPEZA DE ROUPAS; SERVICOS DE" },
  { cnae: "9601701", descricao: "LAVAGEM DE ROUPAS; SERVICOS DE" },
  { cnae: "9601701", descricao: "LAVAR E PASSAR ROUPAS; SERVICOS DE" },
  { cnae: "9601701", descricao: "LAVAGEM AUTOMATICA DE ROUPAS" },
  { cnae: "9601701", descricao: "LAVANDERIA AUTOMATICA" },
  { cnae: "9601701", descricao: "HIGIENIZACAO DE ROUPAS; SERVICOS DE" },
  {
    cnae: "9601701",
    descricao: "HIGIENIZACAO DE AVENTAIS, LUVAS; SERVICOS DE",
  },
  { cnae: "9601701", descricao: "LAVAGEM DE CARPETES; SERVICOS DE" },
  {
    cnae: "9601701",
    descricao:
      "POSTO DE COLETA, POSTO DE ENTREGA DE ROUPAS PARA LAVAGEM; SERVICOS DE",
  },
  { cnae: "9601701", descricao: "LAVANDERIA DE AUTO-SERVICO" },
  { cnae: "9601701", descricao: "LAVAGEM A SECO; SERVICOS DE" },
  { cnae: "9601701", descricao: "DESINFECCAO TEXTIL; SERVICOS DE" },
  {
    cnae: "9601702",
    descricao: "TINGIMENTO DE ARTIGOS TEXTEIS E DE VESTUARIO; SERVICOS DE",
  },
  { cnae: "9601702", descricao: "LAVAR E TINGIR ROUPAS; SERVICOS DE" },
  { cnae: "9601702", descricao: "TINTURARIA; SERVICOS DE" },
  { cnae: "9601702", descricao: "POSTO DE COLETA DE TINTURARIA" },
  { cnae: "9601702", descricao: "TINGIMENTOS DE COURO E PELES; SERVICOS DE" },
  {
    cnae: "9601702",
    descricao: "COLETA E ENTREGA DE ROUPAS PARA TINTURARIAS; SERVICOS DE",
  },
  {
    cnae: "9601703",
    descricao: "ROUPAS DE CAMA, MESA E BANHO; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "9601703", descricao: "ROUPAS DE CAMA, MESA E BANHO; LAVAGEM DE" },
  {
    cnae: "9601703",
    descricao:
      "UNIDADES ESPECIALIZADAS EM LAVANDERIA HOSPITALAR QUANDO REALIZADOS POR TERCEIROS; SERVICOS DE",
  },
  {
    cnae: "9601703",
    descricao: "UNIFORMES DE TRABALHO; ALUGUEL DE, LOCACAO DE",
  },
  { cnae: "9601703", descricao: "LAVAGEM DE ROUPAS INDUSTRIAIS; SERVICOS DE" },
  { cnae: "9601703", descricao: "TOALHAS; ALUGUEL DE, LOCACAO DE" },
  { cnae: "9601703", descricao: "TOALHEIRO" },
  { cnae: "9601703", descricao: "LAVAGEM DE UNIFORMES; SERVICOS DE" },
  {
    cnae: "96025",
    descricao: "CABELEIREIROS E OUTRAS ATIVIDADES DE TRATAMENTO DE BELEZA",
  },
  { cnae: "9602501", descricao: "TRATAMENTO DOS CABELOS; SERVICOS DE" },
  { cnae: "9602501", descricao: "BARBEARIA" },
  {
    cnae: "9602501",
    descricao: "ALISAMENTO, PERMANENTE DE CABELO; SERVICOS DE",
  },
  { cnae: "9602501", descricao: "SALAO DE CABELEIREIRO" },
  { cnae: "9602501", descricao: "MANICURA; SERVICOS DE" },
  { cnae: "9602501", descricao: "BARBEIRO; SALAO DE" },
  { cnae: "9602501", descricao: "SALAO DE CABELEIREIRO UNISSEX" },
  { cnae: "9602501", descricao: "CORTE DE CABELO; SERVICOS DE" },
  { cnae: "9602501", descricao: "COIFFURE" },
  { cnae: "9602501", descricao: "CALISTA; SERVICOS DE" },
  { cnae: "9602501", descricao: "TINTURA E PINTURA DE CABELO; SERVICO DE" },
  { cnae: "9602501", descricao: "EMBELEZAMENTO DOS CABELOS; SERVICOS DE" },
  { cnae: "9602501", descricao: "PEDICURO; SERVICOS DE" },
  { cnae: "9602501", descricao: "HIDRATACAO DE CABELOS; SERVICOS DE" },
  { cnae: "9602501", descricao: "TRATAMENTO CAPILAR; SERVICOS DE" },
  { cnae: "9602501", descricao: "LAVAGEM E PENTEADO DE CABELO; SERVICOS DE" },
  { cnae: "9602501", descricao: "RELAXAMENTO DE CABELOS; SERVICOS DE" },
  { cnae: "9602501", descricao: "TINGIMENTO DE CABELO; SERVICOS DE" },
  { cnae: "9602501", descricao: "PEDICURE, MANICURE; SERVICOS DE" },
  { cnae: "9602502", descricao: "DEPILACAO; SERVICOS DE" },
  {
    cnae: "9602502",
    descricao: "CLINICA DE EMAGRECIMENTO COM USO DE EQUIPAMENTOS",
  },
  { cnae: "9602502", descricao: "ESTETICA CORPORAL; SERVICOS DE" },
  { cnae: "9602502", descricao: "MASSAGEM ESTETICA; SERVICOS DE" },
  {
    cnae: "9602502",
    descricao: "MICROPIGMENTACAO DE SOMBRANCELHA; SERVICOS DE",
  },
  { cnae: "9602502", descricao: "MASSAGEM FACIAL; SERVICOS DE" },
  { cnae: "9602502", descricao: "ENDERMOTERAPIA; SERVICO DE" },
  {
    cnae: "9602502",
    descricao: "INSTITUTO DE EMAGRECIMENTO COM USO DE EQUIPAMENTOS",
  },
  { cnae: "9602502", descricao: "TRATAMENTO FACIAL; SERVICOS DE" },
  { cnae: "9602502", descricao: "MAQUIAGEM; SERVICOS DE" },
  { cnae: "9602502", descricao: "HIGIENE PESSOAL; SERVICOS DE" },
  { cnae: "9602502", descricao: "INSTITUTO DE MASSAGEM ESTETICA" },
  { cnae: "9602502", descricao: "DEPILACAO COM CERA; SERVICOS DE" },
  {
    cnae: "9602502",
    descricao: "DESIGN, DEPILACAO E LIMPEZA DE SOBRANCELHAS; SERVICOS DE",
  },
  { cnae: "9602502", descricao: "HIGIENE E BELEZA; SERVICOS DE" },
  { cnae: "9602502", descricao: "TRATAMENTO ESTETICO; SERVICOS DE" },
  { cnae: "9602502", descricao: "MAQUILAGEM; SERVICOS DE" },
  { cnae: "9602502", descricao: "MASSAGEM PARA EMAGRECIMENTO; ATIVIDADE DE" },
  { cnae: "9602502", descricao: "HIGIENE E EMBELEZAMENTO; SERVICOS DE" },
  { cnae: "9602502", descricao: "BRONZEAMENTO ARTIFICIAL; SERVICOS DE" },
  { cnae: "9602502", descricao: "HIDRATACAO DE PELE; SERVICOS DE" },
  { cnae: "9602502", descricao: "PEELING; SERVICOS DE" },
  { cnae: "9602502", descricao: "TONIFICACAO DE PELE; SERVICOS DE" },
  { cnae: "9602502", descricao: "ESTETICISTA; SERVICOS DE" },
  { cnae: "9602502", descricao: "LIMPEZA DE PELE; SERVICOS DE" },
  { cnae: "9602502", descricao: "LIMPEZA FACIAL; SERVICOS DE" },
  { cnae: "9602502", descricao: "CORRENTE RUSSA; SERVICO DE" },
  { cnae: "9602502", descricao: "REVITALIZACAO DE PELE; SERVICOS DE" },
  { cnae: "9602502", descricao: "INSTITUTO DE BELEZA; SERVICOS DE" },
  { cnae: "9602502", descricao: "SPA SEM SERVICO DE ALOJAMENTO; SERVICOS DE" },
  { cnae: "96033", descricao: "ATIVIDADES FUNERARIAS E SERVICOS RELACIONADOS" },
  { cnae: "9603301", descricao: "VENDA DE GAVETAS DE CEMITERIOS; SERVICOS DE" },
  { cnae: "9603301", descricao: "VENDA DE JAZIGO; SERVICOS DE" },
  { cnae: "9603301", descricao: "VENDA DE SEPULTURAS; SERVICOS DE" },
  { cnae: "9603301", descricao: "ADMINISTRACAO DE NECROPOLE; SERVICOS DE" },
  { cnae: "9603301", descricao: "GESTAO DE CEMITERIOS" },
  { cnae: "9603301", descricao: "JAZIGOS; LOCACAO DE" },
  { cnae: "9603301", descricao: "MANUTENCAO DE CEMITERIOS; SERVICOS DE" },
  { cnae: "9603301", descricao: "LOCULOS; ALUGUEL DE, LOCACAO DE" },
  { cnae: "9603301", descricao: "CESSAO DO USO DE JAZIGOS; SERVICOS DE" },
  { cnae: "9603302", descricao: "FORNO CREMATORIO; SERVICOS DE" },
  {
    cnae: "9603302",
    descricao:
      "CAPTURA E CREMACAO DE ANIMAIS DOMESTICOS ABANDONADOS EM VIAS PUBLICAS - CARROCINHA",
  },
  {
    cnae: "9603302",
    descricao: "CREMACAO DE CADAVERES DE ANIMAIS; SERVICOS DE",
  },
  { cnae: "9603302", descricao: "CREMACAO DE CADAVERES HUMANOS; SERVICOS DE" },
  {
    cnae: "9603302",
    descricao:
      "APREENSAO E TRANSPORTE DE ANIMAIS ABANDONADOS EM VIAS PUBLICAS PARA CREMACAO; SERVICOS DE",
  },
  { cnae: "9603303", descricao: "SEPULTAMENTO; SERVICOS DE" },
  { cnae: "9603304", descricao: "FUNERARIA; SERVICOS DE" },
  {
    cnae: "9603304",
    descricao:
      "ADMINISTRACAO DE PLANOS DE ASSISTENCIA FUNERARIA COM A PRESTACAO DE SERVICO FUNERARIO; SERVICO DE",
  },
  { cnae: "9603304", descricao: "FUNERARIA; ATIVIDADE DE" },
  {
    cnae: "9603304",
    descricao:
      "PLANOS DE ASSISTENCIA FUNERARIA COM A PRESTACAO DE SERVICO FUNERARIO; ADMINISTRACAO DE",
  },
  { cnae: "9603305", descricao: "NECROMAQUIADORA DE CADAVERES; SERVICOS DE" },
  { cnae: "9603305", descricao: "TANATOPRAXIA; SERVICOS DE" },
  { cnae: "9603305", descricao: "EMBALSAMENTO DE CADAVERES; SERVICOS DE" },
  { cnae: "9603305", descricao: "SOMATOCONSERVACAO; SERVICOS DE" },
  { cnae: "9603305", descricao: "NECROMAQUIAGEM ; SERVICOS DE" },
  { cnae: "9603399", descricao: "TUMBAS; VENDA DE" },
  { cnae: "9603399", descricao: "NECROTERIOS; SERVICOS DE" },
  {
    cnae: "9603399",
    descricao: "REMOCAO E EXUMACAO DE CADAVERES; SERVICOS DE",
  },
  { cnae: "9603399", descricao: "CAPELA; ALUGUEL DE" },
  { cnae: "9603399", descricao: "LOCAIS PARA VELORIOS; ALUGUEL DE" },
  {
    cnae: "96092",
    descricao:
      "ATIVIDADES DE SERVICOS PESSOAIS NAO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "9609202", descricao: "AGENCIA MATRIMONIAL" },
  { cnae: "9609202", descricao: "AGENCIA DE CASAMENTO" },
  {
    cnae: "9609204",
    descricao:
      "EXPLORACAO DE MAQUINAS DE SERVICOS PESSOAIS ACIONADAS POR MOEDAS",
  },
  {
    cnae: "9609204",
    descricao: "MAQUINAS FOTOGRAFICAS ACIONADAS POR MOEDAS; EXPLORACAO DE",
  },
  {
    cnae: "9609204",
    descricao:
      "BALANCAS ACIONADAS POR MOEDAS, CARTOES OU FICHAS; EXPLORACAO DE",
  },
  {
    cnae: "9609204",
    descricao: "MAQUINAS FOTOGRAFICAS DE AUTO ATENDIMENTO; EXPLORACAO DE",
  },
  { cnae: "9609205", descricao: "BANHO TURCO; SERVICOS DE" },
  { cnae: "9609205", descricao: "BANHO DE VAPOR; SERVICOS DE" },
  { cnae: "9609205", descricao: "CASA DE BANHO; SERVICOS DE" },
  { cnae: "9609205", descricao: "TERMAS; SERVICOS DE" },
  { cnae: "9609205", descricao: "SAUNA; SERVICOS DE" },
  {
    cnae: "9609205",
    descricao: "MASSAGENS PARA FINS DE RELAXAMENTO MUSCULAR; SERVICOS DE",
  },
  { cnae: "9609206", descricao: "TATUAGEM; SERVICOS DE" },
  { cnae: "9609206", descricao: "PIERCING; SERVICOS DE COLOCACAO DE" },
  {
    cnae: "9609207",
    descricao: "ALOJAMENTO DE ANIMAIS DOMESTICOS; SERVICOS DE",
  },
  {
    cnae: "9609207",
    descricao:
      "ALOJAMENTO, HOTEL DE ANIMAIS DOMESTICOS E DE ESTIMACAO; SERVICOS DE",
  },
  {
    cnae: "9609207",
    descricao: "ADESTRAMENTO DE ANIMAIS DOMESTICOS; SERVICOS DE",
  },
  { cnae: "9609208", descricao: "EMBELEZAMENTO DE ANIMAIS; SERVICOS DE" },
  { cnae: "9609208", descricao: "BANHO EM ANIMAIS DOMESTICOS; SERVICOS DE" },
  {
    cnae: "9609208",
    descricao: "PREPARACAO DE ANIMAIS PARA EXPOSICAO; SERVICOS DE",
  },
  { cnae: "9609208", descricao: "PET SHOP - BANHO, CORTE, EMBELEZAMENTO" },
  { cnae: "9609208", descricao: "PET SHOP - SERVICOS PARA ANIMAIS DOMESTICOS" },
  { cnae: "9609208", descricao: "TOSQUIA DE PELOS EM ANIMAIS DOMESTICOS" },
  {
    cnae: "9609208",
    descricao: "HIGIENE E EMBELEZAMENTO DE ANIMAIS DOMESTICOS; SERVICOS DE",
  },
  { cnae: "9609208", descricao: "TOSA DE PELO EM ANIMAIS DOMESTICOS" },
  { cnae: "9609208", descricao: "CUIDADOS DE ANIMAIS DOMESTICOS" },
  {
    cnae: "9609208",
    descricao: "CORTE DE PELO EM ANIMAIS DOMESTICOS; SERVICOS DE",
  },
  { cnae: "9609299", descricao: "SALAO DE ENGRAXATE" },
  {
    cnae: "9609299",
    descricao:
      "SERVICOS PESSOAIS POR TELEFONE, DISQUE SEXO, DISQUE AMIZADE E SIMILARES",
  },
  { cnae: "9609299", descricao: "VIGILANTE EM PISCINA; SERVICOS DE" },
  { cnae: "9609299", descricao: "PASSEADOR DE CAES; SERVICOS DE" },
  { cnae: "9609299", descricao: "JOGO DE BUZIOS; SERVICOS DE" },
  { cnae: "9609299", descricao: "ENGRAXATE; SERVICOS DE" },
  { cnae: "9609299", descricao: "LUSTRACAO DE CALCADOS; SERVICOS DE" },
  {
    cnae: "9609299",
    descricao: "ATIVIDADE DE PROTECAO E LACRACAO DE BAGAGENS",
  },
  {
    cnae: "9609299",
    descricao: "LAVAGEM DE ESTOFADO - EXCETO DE VEICULOS; SERVICOS DE",
  },
  { cnae: "9609299", descricao: "TELEMENSAGENS PESSOAIS; SERVICOS DE" },
  { cnae: "9609299", descricao: "CARREGADOR DE MALA; SERVICOS DE" },
  { cnae: "9609299", descricao: "JOGO DE TARO; SERVICOS DE" },
  { cnae: "9609299", descricao: "GUARDA DE PISCINA EM PREDIOS; SERVICOS DE" },
  { cnae: "9609299", descricao: "MANOBRISTAS; SERVICOS DE" },
  { cnae: "9609299", descricao: "CUIDADOS DE BEBES E CRIANCAS; SERVICOS DE" },
  { cnae: "9609299", descricao: "GUARDIAO DE PISCINA; SERVICOS DE" },
  { cnae: "9609299", descricao: "LAVAGEM DE SOFA; SERVICOS DE" },
  { cnae: "9609299", descricao: "CARTOMANCIA; SERVICOS DE" },
  { cnae: "9609299", descricao: "MANOBREIRO DE AUTOMOVEIS; SERVICOS DE" },
  { cnae: "9609299", descricao: "GUARDA DE PISCINA; SERVICOS DE" },
  {
    cnae: "9609299",
    descricao: "EMBALAGEM, EMPACOTAMENTO DE MALAS EM AEROPORTOS; SERVICOS DE",
  },
  { cnae: "9609299", descricao: "VIDENTE E SIMILARES; SERVICOS DE" },
  { cnae: "9609299", descricao: "SERVICOS ESOTERICOS POR TELEFONE" },
  { cnae: "9609299", descricao: "ASTROLOGIA; SERVICOS DE" },
  { cnae: "9609299", descricao: "CARTOMANTE; SERVICOS DE" },
  { cnae: "9609299", descricao: "MANOBRISTA DE AUTOMOVEIS; SERVICOS DE" },
  { cnae: "9609299", descricao: "MANOBRISTA DE VEICULOS; SERVICOS DE" },
  { cnae: "9609299", descricao: "MENSAGENS FONADAS; SERVICOS DE" },
  { cnae: "9609299", descricao: "BABY SITER; SERVICOS DE" },
  {
    cnae: "9609299",
    descricao: "SEGURANCA DE PISCINA EM PREDIOS; SERVICOS DE",
  },
  { cnae: "9609299", descricao: "QUIROMANCIA; SERVICOS DE" },
  { cnae: "9609299", descricao: "ENGRAXATARIA; SERVICOS DE" },
  { cnae: "9609299", descricao: "MENSAGEM AO VIVO; SERVICOS DE" },
  { cnae: "9609299", descricao: "ASTROLOGO; SERVICOS DE" },
  { cnae: "97005", descricao: "SERVICOS DOMESTICOS" },
  {
    cnae: "9700500",
    descricao:
      "BABA; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "9700500",
    descricao:
      "PORTEIRO EM RESIDENCIA; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "9700500",
    descricao:
      "CAMAREIRA DOMESTICA; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "9700500",
    descricao:
      "JARDINEIRO EM RESIDENCIA; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "9700500",
    descricao:
      "COZINHEIRO DOMESTICO; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "9700500",
    descricao:
      "GOVERNANTA; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "9700500",
    descricao:
      "MORDOMO; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "9700500",
    descricao:
      "PASSADEIRA; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "9700500",
    descricao:
      "DIARISTA; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "9700500",
    descricao:
      "COZINHEIRA DOMESTICA; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "9700500",
    descricao:
      "ACOMPANHANTE; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "9700500",
    descricao:
      "GUARDA-COSTA; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "9700500",
    descricao:
      "MOTORISTA DOMESTICO; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "9700500",
    descricao:
      "EMPREGADO DOMESTICO EM RESIDENCIA; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "9700500",
    descricao:
      "LAVADEIRA DOMESTICA; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "9700500",
    descricao:
      "ORGANIZACAO DE GUARDA-ROUPAS E ARMARIOS EM GERAL  ORGANIZER; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICO DE",
  },
  {
    cnae: "9700500",
    descricao:
      "CASEIROS; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "9700500",
    descricao:
      "CAMAREIRO DOMESTICO; UNIDADES DOMICILIARES QUE CONTRATAM EMPREGADOS DOMESTICOS PARA PRESTACAO DE SERVICOS DE",
  },
  {
    cnae: "99008",
    descricao:
      "ORGANISMOS INTERNACIONAIS E OUTRAS INSTITUICOES EXTRATERRITORIAIS",
  },
  {
    cnae: "9900800",
    descricao: "ORGANIZACAO MUNDIAL DA SAUDE - OMS; REPRESENTACAO NO PAIS",
  },
  { cnae: "9900800", descricao: "UNESCO; REPRESENTACAO NO PAIS" },
  { cnae: "9900800", descricao: "EMBAIXADA ESTRANGEIRA" },
  {
    cnae: "9900800",
    descricao:
      "ORGANIZACAO PARA A COOPERACAO E DESENVOLVIMENTO ECONOMICO -OCDE",
  },
  { cnae: "9900800", descricao: "INSTITUICAO EXTRATERRITORIAL" },
  {
    cnae: "9900800",
    descricao: "ORGANIZACAO DAS NACOES UNIDAS - ONU; REPRESENTACAO NO PAIS",
  },
  {
    cnae: "9900800",
    descricao:
      "BANCO INTER-AMERICANO DE DESENVOLVIMENTO - BID; REPRESENTACAO NO PAIS",
  },
  {
    cnae: "9900800",
    descricao: "ORGANISMO INTERNACIONAL; REPRESENTACAO NO PAIS",
  },
  { cnae: "9900800", descricao: "CONSULADO ESTRANGEIRO" },
  {
    cnae: "9900800",
    descricao: "ORGANIZACAO DOS PAISES EXPORTADORES DE PETROLEO - OPEP",
  },
  { cnae: "9900800", descricao: "BANCO MUNDIAL; REPRESENTACAO NO PAIS" },
  { cnae: "9900800", descricao: "SERVICO DIPLOMATICO ESTRANGEIRO" },
  {
    cnae: "9900800",
    descricao:
      "COMISSAO ECONOMICA PARA AMERICA LATINA E CARIBE - CEPAL; REPRESENTACAO NO PAIS",
  },
  {
    cnae: "9900800",
    descricao: "FUNDO MONETARIO INTERNACIONAL - FMI; REPRESENTACAO NO PAIS",
  },
  {
    cnae: "9900800",
    descricao:
      "ORGANIZACAO DOS ESTADOS AMERICANOS - OEA; REPRESENTACAO NO PAIS",
  },
  { cnae: "11", descricao: "PRODUÇÃO DE LAVOURAS TEMPORÁRIAS" },
  { cnae: "12", descricao: "HORTICULTURA E FLORICULTURA" },
  { cnae: "13", descricao: "PRODUÇÃO DE LAVOURAS PERMANENTES" },
  { cnae: "14", descricao: "PRODUÇÃO DE SEMENTES E MUDAS CERTIFICADAS" },
  { cnae: "15", descricao: "PECUÁRIA" },
  {
    cnae: "16",
    descricao:
      "ATIVIDADES DE APOIO À AGRICULTURA E À PECUÁRIA; ATIVIDADES DE PÓS-COLHEITA",
  },
  { cnae: "17", descricao: "CAÇA E SERVIÇOS RELACIONADOS" },
  { cnae: "02", descricao: "PRODUÇÃO FLORESTAL" },
  { cnae: "21", descricao: "PRODUÇÃO FLORESTAL - FLORESTAS PLANTADAS" },
  { cnae: "22", descricao: "PRODUÇÃO FLORESTAL - FLORESTAS NATIVAS" },
  { cnae: "23", descricao: "ATIVIDADES DE APOIO À PRODUÇÃO FLORESTAL" },
  { cnae: "03", descricao: "PESCA E AQÜICULTURA" },
  { cnae: "31", descricao: "PESCA" },
  { cnae: "32", descricao: "AQÜICULTURA" },
  { cnae: "05", descricao: "EXTRAÇÃO DE CARVÃO MINERAL" },
  { cnae: "50", descricao: "EXTRAÇÃO DE CARVÃO MINERAL" },
  { cnae: "06", descricao: "EXTRAÇÃO DE PETRÓLEO E GÁS NATURAL" },
  { cnae: "60", descricao: "EXTRAÇÃO DE PETRÓLEO E GÁS NATURAL" },
  { cnae: "07", descricao: "EXTRAÇÃO DE MINERAIS METÁLICOS" },
  { cnae: "71", descricao: "EXTRAÇÃO DE MINÉRIO DE FERRO" },
  { cnae: "72", descricao: "EXTRAÇÃO DE MINERAIS METÁLICOS NÃO-FERROSOS" },
  { cnae: "08", descricao: "EXTRAÇÃO DE MINERAIS NÃO-METÁLICOS" },
  { cnae: "81", descricao: "EXTRAÇÃO DE PEDRA, AREIA E ARGILA" },
  { cnae: "89", descricao: "EXTRAÇÃO DE OUTROS MINERAIS NÃO-METÁLICOS" },
  { cnae: "09", descricao: "ATIVIDADES DE APOIO À EXTRAÇÃO DE MINERAIS" },
  {
    cnae: "91",
    descricao: "ATIVIDADES DE APOIO À EXTRAÇÃO DE PETRÓLEO E GÁS NATURAL",
  },
  {
    cnae: "99",
    descricao:
      "ATIVIDADES DE APOIO À EXTRAÇÃO DE MINERAIS, EXCETO PETRÓLEO E GÁS NATURAL",
  },
  { cnae: "10", descricao: "FABRICAÇÃO DE PRODUTOS ALIMENTÍCIOS" },
  { cnae: "101", descricao: "ABATE E FABRICAÇÃO DE PRODUTOS DE CARNE" },
  {
    cnae: "102",
    descricao: "PRESERVAÇÃO DO PESCADO E FABRICAÇÃO DE PRODUTOS DO PESCADO",
  },
  {
    cnae: "103",
    descricao: "FABRICAÇÃO DE CONSERVAS DE FRUTAS, LEGUMES E OUTROS VEGETAIS",
  },
  {
    cnae: "104",
    descricao: "FABRICAÇÃO DE ÓLEOS E GORDURAS VEGETAIS E ANIMAIS",
  },
  { cnae: "105", descricao: "LATICÍNIOS" },
  {
    cnae: "106",
    descricao:
      "MOAGEM, FABRICAÇÃO DE PRODUTOS AMILÁCEOS E DE ALIMENTOS PARA ANIMAIS",
  },
  { cnae: "107", descricao: "FABRICAÇÃO E REFINO DE AÇÚCAR" },
  { cnae: "108", descricao: "TORREFAÇÃO E MOAGEM DE CAFÉ" },
  { cnae: "109", descricao: "FABRICAÇÃO DE OUTROS PRODUTOS ALIMENTÍCIOS" },
  { cnae: "11", descricao: "FABRICAÇÃO DE BEBIDAS" },
  { cnae: "111", descricao: "FABRICAÇÃO DE BEBIDAS ALCOÓLICAS" },
  { cnae: "112", descricao: "FABRICAÇÃO DE BEBIDAS NÃO-ALCOÓLICAS" },
  { cnae: "12", descricao: "FABRICAÇÃO DE PRODUTOS DO FUMO" },
  { cnae: "121", descricao: "PROCESSAMENTO INDUSTRIAL DO FUMO" },
  { cnae: "122", descricao: "FABRICAÇÃO DE PRODUTOS DO FUMO" },
  { cnae: "13", descricao: "FABRICAÇÃO DE PRODUTOS TÊXTEIS" },
  { cnae: "131", descricao: "PREPARAÇÃO E FIAÇÃO DE FIBRAS TÊXTEIS" },
  { cnae: "132", descricao: "TECELAGEM, EXCETO MALHA" },
  { cnae: "133", descricao: "FABRICAÇÃO DE TECIDOS DE MALHA" },
  {
    cnae: "134",
    descricao: "ACABAMENTOS EM FIOS, TECIDOS E ARTEFATOS TÊXTEIS",
  },
  {
    cnae: "135",
    descricao: "FABRICAÇÃO DE ARTEFATOS TÊXTEIS, EXCETO VESTUÁRIO",
  },
  { cnae: "14", descricao: "CONFECÇÃO DE ARTIGOS DO VESTUÁRIO E ACESSÓRIOS" },
  { cnae: "141", descricao: "CONFECÇÃO DE ARTIGOS DO VESTUÁRIO E ACESSÓRIOS" },
  { cnae: "142", descricao: "FABRICAÇÃO DE ARTIGOS DE MALHARIA E TRICOTAGEM" },
  {
    cnae: "15",
    descricao:
      "PREPARAÇÃO DE COUROS E FABRICAÇÃO DE ARTEFATOS DE COURO, ARTIGOS PARA VIAGEM E CALÇADOS",
  },
  { cnae: "151", descricao: "CURTIMENTO E OUTRAS PREPARAÇÕES DE COURO" },
  {
    cnae: "152",
    descricao:
      "FABRICAÇÃO DE ARTIGOS PARA VIAGEM E DE ARTEFATOS DIVERSOS DE COURO",
  },
  { cnae: "153", descricao: "FABRICAÇÃO DE CALÇADOS" },
  {
    cnae: "154",
    descricao: "FABRICAÇÃO DE PARTES PARA CALÇADOS, DE QUALQUER MATERIAL",
  },
  { cnae: "16", descricao: "FABRICAÇÃO DE PRODUTOS DE MADEIRA" },
  { cnae: "161", descricao: "DESDOBRAMENTO DE MADEIRA" },
  {
    cnae: "162",
    descricao:
      "FABRICAÇÃO DE PRODUTOS DE MADEIRA, CORTIÇA E MATERIAL TRANÇADO, EXCETO MÓVEIS",
  },
  {
    cnae: "17",
    descricao: "FABRICAÇÃO DE CELULOSE, PAPEL E PRODUTOS DE PAPEL",
  },
  {
    cnae: "171",
    descricao:
      "FABRICAÇÃO DE CELULOSE E OUTRAS PASTAS PARA A FABRICAÇÃO DE PAPEL",
  },
  { cnae: "172", descricao: "FABRICAÇÃO DE PAPEL, CARTOLINA E PAPEL-CARTÃO" },
  {
    cnae: "173",
    descricao:
      "FABRICAÇÃO DE EMBALAGENS DE PAPEL, CARTOLINA, PAPEL-CARTÃO E PAPELÃO ONDULADO",
  },
  {
    cnae: "174",
    descricao:
      "FABRICAÇÃO DE PRODUTOS DIVERSOS DE PAPEL, CARTOLINA, PAPEL-CARTÃO E PAPELÃO ONDULADO",
  },
  { cnae: "18", descricao: "IMPRESSÃO E REPRODUÇÃO DE GRAVAÇÕES" },
  { cnae: "181", descricao: "ATIVIDADE DE IMPRESSÃO" },
  {
    cnae: "182",
    descricao: "SERVIÇOS DE PRÉ-IMPRESSÃO E ACABAMENTOS GRÁFICOS",
  },
  {
    cnae: "183",
    descricao: "REPRODUÇÃO DE MATERIAIS GRAVADOS EM QUALQUER SUPORTE",
  },
  {
    cnae: "19",
    descricao:
      "FABRICAÇÃO DE COQUE, DE PRODUTOS DERIVADOS DO PETRÓLEO E DE BIOCOMBUSTÍVEIS",
  },
  { cnae: "191", descricao: "COQUERIAS" },
  { cnae: "192", descricao: "FABRICAÇÃO DE PRODUTOS DERIVADOS DO PETRÓLEO" },
  { cnae: "193", descricao: "FABRICAÇÃO DE BIOCOMBUSTÍVEIS" },
  { cnae: "20", descricao: "FABRICAÇÃO DE PRODUTOS QUÍMICOS" },
  { cnae: "201", descricao: "FABRICAÇÃO DE PRODUTOS QUÍMICOS INORGÂNICOS" },
  { cnae: "202", descricao: "FABRICAÇÃO DE PRODUTOS QUÍMICOS ORGÂNICOS" },
  { cnae: "203", descricao: "FABRICAÇÃO DE RESINAS E ELASTÔMEROS" },
  { cnae: "204", descricao: "FABRICAÇÃO DE FIBRAS ARTIFICIAIS E SINTÉTICAS" },
  {
    cnae: "205",
    descricao:
      "FABRICAÇÃO DE DEFENSIVOS AGRÍCOLAS E DESINFESTANTES DOMISSANITÁRIOS",
  },
  {
    cnae: "206",
    descricao:
      "FABRICAÇÃO DE SABÕES, DETERGENTES, PRODUTOS DE LIMPEZA, COSMÉTICOS, PRODUTOS DE PERFUMARIA E DE HIGIENE PESSOAL",
  },
  {
    cnae: "207",
    descricao:
      "FABRICAÇÃO DE TINTAS, VERNIZES, ESMALTES, LACAS E PRODUTOS AFINS",
  },
  {
    cnae: "209",
    descricao: "FABRICAÇÃO DE PRODUTOS E PREPARADOS QUÍMICOS DIVERSOS",
  },
  {
    cnae: "21",
    descricao: "FABRICAÇÃO DE PRODUTOS FARMOQUÍMICOS E FARMACÊUTICOS",
  },
  { cnae: "211", descricao: "FABRICAÇÃO DE PRODUTOS FARMOQUÍMICOS" },
  { cnae: "212", descricao: "FABRICAÇÃO DE PRODUTOS FARMACÊUTICOS" },
  {
    cnae: "22",
    descricao: "FABRICAÇÃO DE PRODUTOS DE BORRACHA E DE MATERIAL PLÁSTICO",
  },
  { cnae: "221", descricao: "FABRICAÇÃO DE PRODUTOS DE BORRACHA" },
  { cnae: "222", descricao: "FABRICAÇÃO DE PRODUTOS DE MATERIAL PLÁSTICO" },
  { cnae: "23", descricao: "FABRICAÇÃO DE PRODUTOS DE MINERAIS NÃO-METÁLICOS" },
  { cnae: "231", descricao: "FABRICAÇÃO DE VIDRO E DE PRODUTOS DO VIDRO" },
  { cnae: "232", descricao: "FABRICAÇÃO DE CIMENTO" },
  {
    cnae: "233",
    descricao:
      "FABRICAÇÃO DE ARTEFATOS DE CONCRETO, CIMENTO, FIBROCIMENTO, GESSO E MATERIAIS SEMELHANTES",
  },
  { cnae: "234", descricao: "FABRICAÇÃO DE PRODUTOS CERÂMICOS" },
  {
    cnae: "239",
    descricao:
      "APARELHAMENTO DE PEDRAS E FABRICAÇÃO DE OUTROS PRODUTOS DE MINERAIS NÃO-METÁLICOS",
  },
  { cnae: "24", descricao: "METALURGIA" },
  { cnae: "241", descricao: "PRODUÇÃO DE FERRO-GUSA E DE FERROLIGAS" },
  { cnae: "242", descricao: "SIDERURGIA" },
  {
    cnae: "243",
    descricao: "PRODUÇÃO DE TUBOS DE AÇO, EXCETO TUBOS SEM COSTURA",
  },
  { cnae: "244", descricao: "METALURGIA DOS METAIS NÃO-FERROSOS" },
  { cnae: "245", descricao: "FUNDIÇÃO" },
  {
    cnae: "25",
    descricao:
      "FABRICAÇÃO DE PRODUTOS DE METAL, EXCETO MÁQUINAS E EQUIPAMENTOS",
  },
  {
    cnae: "251",
    descricao:
      "FABRICAÇÃO DE ESTRUTURAS METÁLICAS E OBRAS DE CALDEIRARIA PESADA",
  },
  {
    cnae: "252",
    descricao: "FABRICAÇÃO DE TANQUES, RESERVATÓRIOS METÁLICOS E CALDEIRAS",
  },
  {
    cnae: "253",
    descricao:
      "FORJARIA, ESTAMPARIA, METALURGIA DO PÓ E SERVIÇOS DE TRATAMENTO DE METAIS",
  },
  {
    cnae: "254",
    descricao:
      "FABRICAÇÃO DE ARTIGOS DE CUTELARIA, DE SERRALHERIA E FERRAMENTAS",
  },
  {
    cnae: "255",
    descricao:
      "FABRICAÇÃO DE EQUIPAMENTO BÉLICO PESADO, ARMAS DE FOGO E MUNIÇÕES",
  },
  {
    cnae: "259",
    descricao:
      "FABRICAÇÃO DE PRODUTOS DE METAL NÃO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "26",
    descricao:
      "FABRICAÇÃO DE EQUIPAMENTOS DE INFORMÁTICA, PRODUTOS ELETRÔNICOS E ÓPTICOS",
  },
  { cnae: "261", descricao: "FABRICAÇÃO DE COMPONENTES ELETRÔNICOS" },
  {
    cnae: "262",
    descricao: "FABRICAÇÃO DE EQUIPAMENTOS DE INFORMÁTICA E PERIFÉRICOS",
  },
  { cnae: "263", descricao: "FABRICAÇÃO DE EQUIPAMENTOS DE COMUNICAÇÃO" },
  {
    cnae: "264",
    descricao:
      "FABRICAÇÃO DE APARELHOS DE RECEPÇÃO, REPRODUÇÃO, GRAVAÇÃO E AMPLIFICAÇÃO DE ÁUDIO E VÍDEO",
  },
  {
    cnae: "265",
    descricao:
      "FABRICAÇÃO DE APARELHOS E INSTRUMENTOS DE MEDIDA, TESTE E CONTROLE; CRONÔMETROS E RELÓGIOS",
  },
  {
    cnae: "266",
    descricao:
      "FABRICAÇÃO DE APARELHOS ELETROMÉDICOS E ELETROTERAPÊUTICOS E EQUIPAMENTOS DE IRRADIAÇÃO",
  },
  {
    cnae: "267",
    descricao:
      "FABRICAÇÃO DE EQUIPAMENTOS E INSTRUMENTOS ÓPTICOS, FOTOGRÁFICOS E CINEMATOGRÁFICOS",
  },
  {
    cnae: "268",
    descricao: "FABRICAÇÃO DE MÍDIAS VIRGENS, MAGNÉTICAS E ÓPTICAS",
  },
  {
    cnae: "27",
    descricao: "FABRICAÇÃO DE MÁQUINAS, APARELHOS E MATERIAIS ELÉTRICOS",
  },
  {
    cnae: "271",
    descricao: "FABRICAÇÃO DE GERADORES, TRANSFORMADORES E MOTORES ELÉTRICOS",
  },
  {
    cnae: "272",
    descricao: "FABRICAÇÃO DE PILHAS, BATERIAS E ACUMULADORES ELÉTRICOS",
  },
  { cnae: "771", descricao: "LOCAÇÃO DE MEIOS DE TRANSPORTE SEM CONDUTOR" },
  {
    cnae: "273",
    descricao:
      "FABRICAÇÃO DE EQUIPAMENTOS PARA DISTRIBUIÇÃO E CONTROLE DE ENERGIA ELÉTRICA",
  },
  {
    cnae: "274",
    descricao: "FABRICAÇÃO DE LÂMPADAS E OUTROS EQUIPAMENTOS DE ILUMINAÇÃO",
  },
  { cnae: "275", descricao: "FABRICAÇÃO DE ELETRODOMÉSTICOS" },
  {
    cnae: "279",
    descricao:
      "FABRICAÇÃO DE EQUIPAMENTOS E APARELHOS ELÉTRICOS NÃO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "28", descricao: "FABRICAÇÃO DE MÁQUINAS E EQUIPAMENTOS" },
  {
    cnae: "281",
    descricao:
      "FABRICAÇÃO DE MOTORES, BOMBAS, COMPRESSORES E EQUIPAMENTOS DE TRANSMISSÃO",
  },
  {
    cnae: "282",
    descricao: "FABRICAÇÃO DE MÁQUINAS E EQUIPAMENTOS DE USO GERAL",
  },
  {
    cnae: "283",
    descricao:
      "FABRICAÇÃO DE TRATORES E DE MÁQUINAS E EQUIPAMENTOS PARA A AGRICULTURA E PECUÁRIA",
  },
  { cnae: "284", descricao: "FABRICAÇÃO DE MÁQUINAS-FERRAMENTA" },
  {
    cnae: "285",
    descricao:
      "FABRICAÇÃO DE MÁQUINAS E EQUIPAMENTOS DE USO NA EXTRAÇÃO MINERAL E NA CONSTRUÇÃO",
  },
  {
    cnae: "286",
    descricao:
      "FABRICAÇÃO DE MÁQUINAS E EQUIPAMENTOS DE USO INDUSTRIAL ESPECÍFICO",
  },
  {
    cnae: "29",
    descricao: "FABRICAÇÃO DE VEÍCULOS AUTOMOTORES, REBOQUES E CARROCERIAS",
  },
  {
    cnae: "291",
    descricao: "FABRICAÇÃO DE AUTOMÓVEIS, CAMIONETAS E UTILITÁRIOS",
  },
  { cnae: "292", descricao: "FABRICAÇÃO DE CAMINHÕES E ÔNIBUS" },
  {
    cnae: "293",
    descricao:
      "FABRICAÇÃO DE CABINES, CARROCERIAS E REBOQUES PARA VEÍCULOS AUTOMOTORES",
  },
  {
    cnae: "294",
    descricao: "FABRICAÇÃO DE PEÇAS E ACESSÓRIOS PARA VEÍCULOS AUTOMOTORES",
  },
  {
    cnae: "295",
    descricao:
      "RECONDICIONAMENTO E RECUPERAÇÃO DE MOTORES PARA VEÍCULOS AUTOMOTORES",
  },
  {
    cnae: "30",
    descricao:
      "FABRICAÇÃO DE OUTROS EQUIPAMENTOS DE TRANSPORTE, EXCETO VEÍCULOS AUTOMOTORES",
  },
  { cnae: "301", descricao: "CONSTRUÇÃO DE EMBARCAÇÕES" },
  { cnae: "303", descricao: "FABRICAÇÃO DE VEÍCULOS FERROVIÁRIOS" },
  { cnae: "304", descricao: "FABRICAÇÃO DE AERONAVES" },
  { cnae: "305", descricao: "FABRICAÇÃO DE VEÍCULOS MILITARES DE COMBATE" },
  {
    cnae: "309",
    descricao:
      "FABRICAÇÃO DE EQUIPAMENTOS DE TRANSPORTE NÃO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "31", descricao: "FABRICAÇÃO DE MÓVEIS" },
  { cnae: "310", descricao: "FABRICAÇÃO DE MÓVEIS" },
  { cnae: "32", descricao: "FABRICAÇÃO DE PRODUTOS DIVERSOS" },
  {
    cnae: "321",
    descricao: "FABRICAÇÃO DE ARTIGOS DE JOALHERIA, BIJUTERIA E SEMELHANTES",
  },
  { cnae: "322", descricao: "FABRICAÇÃO DE INSTRUMENTOS MUSICAIS" },
  { cnae: "323", descricao: "FABRICAÇÃO DE ARTEFATOS PARA PESCA E ESPORTE" },
  { cnae: "324", descricao: "FABRICAÇÃO DE BRINQUEDOS E JOGOS RECREATIVOS" },
  {
    cnae: "325",
    descricao:
      "FABRICAÇÃO DE INSTRUMENTOS E MATERIAIS PARA USO MÉDICO E ODONTOLÓGICO E DE ARTIGOS ÓPTICOS",
  },
  { cnae: "329", descricao: "FABRICAÇÃO DE PRODUTOS DIVERSOS" },
  {
    cnae: "33",
    descricao: "MANUTENÇÃO, REPARAÇÃO E INSTALAÇÃO DE MÁQUINAS E EQUIPAMENTOS",
  },
  {
    cnae: "331",
    descricao: "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS",
  },
  { cnae: "332", descricao: "INSTALAÇÃO DE MÁQUINAS E EQUIPAMENTOS" },
  { cnae: "35", descricao: "ELETRICIDADE, GÁS E OUTRAS UTILIDADES" },
  {
    cnae: "351",
    descricao: "GERAÇÃO, TRANSMISSÃO E DISTRIBUIÇÃO DE ENERGIA ELÉTRICA",
  },
  {
    cnae: "352",
    descricao:
      "PRODUÇÃO E DISTRIBUIÇÃO DE COMBUSTÍVEIS GASOSOS POR REDES URBANAS",
  },
  {
    cnae: "353",
    descricao:
      "PRODUÇÃO E DISTRIBUIÇÃO DE VAPOR, ÁGUA QUENTE E AR CONDICIONADO",
  },
  { cnae: "36", descricao: "CAPTAÇÃO, TRATAMENTO E DISTRIBUIÇÃO DE ÁGUA" },
  { cnae: "360", descricao: "CAPTAÇÃO, TRATAMENTO E DISTRIBUIÇÃO DE ÁGUA" },
  { cnae: "37", descricao: "ESGOTO E ATIVIDADES RELACIONADAS" },
  { cnae: "370", descricao: "ESGOTO E ATIVIDADES RELACIONADAS" },
  {
    cnae: "38",
    descricao:
      "COLETA, TRATAMENTO E DISPOSIÇÃO DE RESÍDUOS; RECUPERAÇÃO DE MATERIAIS",
  },
  { cnae: "381", descricao: "COLETA DE RESÍDUOS" },
  { cnae: "382", descricao: "TRATAMENTO E DISPOSIÇÃO DE RESÍDUOS" },
  { cnae: "383", descricao: "RECUPERAÇÃO DE MATERIAIS" },
  {
    cnae: "39",
    descricao: "DESCONTAMINAÇÃO E OUTROS SERVIÇOS DE GESTÃO DE RESÍDUOS",
  },
  {
    cnae: "390",
    descricao: "DESCONTAMINAÇÃO E OUTROS SERVIÇOS DE GESTÃO DE RESÍDUOS",
  },
  { cnae: "41", descricao: "CONSTRUÇÃO DE EDIFÍCIOS" },
  { cnae: "411", descricao: "INCORPORAÇÃO DE EMPREENDIMENTOS IMOBILIÁRIOS" },
  { cnae: "412", descricao: "CONSTRUÇÃO DE EDIFÍCIOS" },
  { cnae: "42", descricao: "OBRAS DE INFRA-ESTRUTURA" },
  {
    cnae: "421",
    descricao:
      "CONSTRUÇÃO DE RODOVIAS, FERROVIAS, OBRAS URBANAS E OBRAS-DE-ARTE ESPECIAIS",
  },
  {
    cnae: "422",
    descricao:
      "OBRAS DE INFRA-ESTRUTURA PARA ENERGIA ELÉTRICA, TELECOMUNICAÇÕES, ÁGUA, ESGOTO E TRANSPORTE POR DUTOS",
  },
  { cnae: "429", descricao: "CONSTRUÇÃO DE OUTRAS OBRAS DE INFRA-ESTRUTURA" },
  { cnae: "43", descricao: "SERVIÇOS ESPECIALIZADOS PARA CONSTRUÇÃO" },
  { cnae: "431", descricao: "DEMOLIÇÃO E PREPARAÇÃO DO TERRENO" },
  {
    cnae: "432",
    descricao:
      "INSTALAÇÕES ELÉTRICAS, HIDRÁULICAS E OUTRAS INSTALAÇÕES EM CONSTRUÇÕES",
  },
  { cnae: "433", descricao: "OBRAS DE ACABAMENTO" },
  { cnae: "439", descricao: "OUTROS SERVIÇOS ESPECIALIZADOS PARA CONSTRUÇÃO" },
  {
    cnae: "45",
    descricao: "COMÉRCIO E REPARAÇÃO DE VEÍCULOS AUTOMOTORES E MOTOCICLETAS",
  },
  { cnae: "451", descricao: "COMÉRCIO DE VEÍCULOS AUTOMOTORES" },
  { cnae: "452", descricao: "MANUTENÇÃO E REPARAÇÃO DE VEÍCULOS AUTOMOTORES" },
  {
    cnae: "453",
    descricao: "COMÉRCIO DE PEÇAS E ACESSÓRIOS PARA VEÍCULOS AUTOMOTORES",
  },
  {
    cnae: "454",
    descricao:
      "COMÉRCIO, MANUTENÇÃO E REPARAÇÃO DE MOTOCICLETAS, PEÇAS E ACESSÓRIOS",
  },
  {
    cnae: "46",
    descricao:
      "COMÉRCIO POR ATACADO, EXCETO VEÍCULOS AUTOMOTORES E MOTOCICLETAS",
  },
  {
    cnae: "461",
    descricao:
      "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO, EXCETO DE VEÍCULOS AUTOMOTORES E MOTOCICLETAS",
  },
  {
    cnae: "462",
    descricao:
      "COMÉRCIO ATACADISTA DE MATÉRIAS-PRIMAS AGRÍCOLAS E ANIMAIS VIVOS",
  },
  {
    cnae: "463",
    descricao:
      "COMÉRCIO ATACADISTA ESPECIALIZADO EM PRODUTOS ALIMENTÍCIOS, BEBIDAS E FUMO",
  },
  {
    cnae: "464",
    descricao: "COMÉRCIO ATACADISTA DE PRODUTOS DE CONSUMO NÃO-ALIMENTAR",
  },
  {
    cnae: "465",
    descricao:
      "COMÉRCIO ATACADISTA DE EQUIPAMENTOS E PRODUTOS DE TECNOLOGIAS DE INFORMAÇÃO E COMUNICAÇÃO",
  },
  {
    cnae: "466",
    descricao:
      "COMÉRCIO ATACADISTA DE MÁQUINAS, APARELHOS E EQUIPAMENTOS, EXCETO DE TECNOLOGIAS DE INFORMAÇÃO E COMUNICAÇÃO",
  },
  {
    cnae: "467",
    descricao:
      "COMÉRCIO ATACADISTA DE MADEIRA, FERRAGENS, FERRAMENTAS, MATERIAL ELÉTRICO E MATERIAL DE CONSTRUÇÃO",
  },
  {
    cnae: "468",
    descricao: "COMÉRCIO ATACADISTA ESPECIALIZADO EM OUTROS PRODUTOS",
  },
  { cnae: "469", descricao: "COMÉRCIO ATACADISTA NÃO-ESPECIALIZADO" },
  { cnae: "47", descricao: "COMÉRCIO VAREJISTA" },
  { cnae: "471", descricao: "COMÉRCIO VAREJISTA NÃO-ESPECIALIZADO" },
  {
    cnae: "472",
    descricao: "COMÉRCIO VAREJISTA DE PRODUTOS ALIMENTÍCIOS, BEBIDAS E FUMO",
  },
  {
    cnae: "473",
    descricao: "COMÉRCIO VAREJISTA DE COMBUSTÍVEIS PARA VEÍCULOS AUTOMOTORES",
  },
  { cnae: "474", descricao: "COMÉRCIO VAREJISTA DE MATERIAL DE CONSTRUÇÃO" },
  {
    cnae: "475",
    descricao:
      "COMÉRCIO VAREJISTA DE EQUIPAMENTOS DE INFORMÁTICA E COMUNICAÇÃO; EQUIPAMENTOS E ARTIGOS DE USO DOMÉSTICO",
  },
  {
    cnae: "476",
    descricao:
      "COMÉRCIO VAREJISTA DE ARTIGOS CULTURAIS, RECREATIVOS E ESPORTIVOS",
  },
  {
    cnae: "477",
    descricao:
      "COMÉRCIO VAREJISTA DE PRODUTOS FARMACÊUTICOS, PERFUMARIA E COSMÉTICOS E ARTIGOS MÉDICOS, ÓPTICOS E ORTOPÉDICOS",
  },
  { cnae: "772", descricao: "ALUGUEL DE OBJETOS PESSOAIS E DOMÉSTICOS" },
  {
    cnae: "478",
    descricao:
      "COMÉRCIO VAREJISTA DE PRODUTOS NOVOS NÃO ESPECIFICADOS ANTERIORMENTE E DE PRODUTOS USADOS",
  },
  {
    cnae: "479",
    descricao: "COMÉRCIO AMBULANTE E OUTROS TIPOS DE COMÉRCIO VAREJISTA",
  },
  { cnae: "49", descricao: "TRANSPORTE TERRESTRE" },
  { cnae: "491", descricao: "TRANSPORTE FERROVIÁRIO E METROFERROVIÁRIO" },
  { cnae: "492", descricao: "TRANSPORTE RODOVIÁRIO DE PASSAGEIROS" },
  { cnae: "493", descricao: "TRANSPORTE RODOVIÁRIO DE CARGA" },
  { cnae: "494", descricao: "TRANSPORTE DUTOVIÁRIO" },
  { cnae: "495", descricao: "TRENS TURÍSTICOS, TELEFÉRICOS E SIMILARES" },
  { cnae: "50", descricao: "TRANSPORTE AQUAVIÁRIO" },
  { cnae: "501", descricao: "TRANSPORTE MARÍTIMO DE CABOTAGEM E LONGO CURSO" },
  { cnae: "502", descricao: "TRANSPORTE POR NAVEGAÇÃO INTERIOR" },
  { cnae: "503", descricao: "NAVEGAÇÃO DE APOIO" },
  { cnae: "509", descricao: "OUTROS TRANSPORTES AQUAVIÁRIOS" },
  { cnae: "51", descricao: "TRANSPORTE AÉREO" },
  { cnae: "511", descricao: "TRANSPORTE AÉREO DE PASSAGEIROS" },
  { cnae: "512", descricao: "TRANSPORTE AÉREO DE CARGA" },
  { cnae: "513", descricao: "TRANSPORTE ESPACIAL" },
  {
    cnae: "52",
    descricao: "ARMAZENAMENTO E ATIVIDADES AUXILIARES DOS TRANSPORTES",
  },
  { cnae: "521", descricao: "ARMAZENAMENTO, CARGA E DESCARGA" },
  {
    cnae: "522",
    descricao: "ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES",
  },
  {
    cnae: "523",
    descricao: "ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
  },
  { cnae: "524", descricao: "ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS" },
  {
    cnae: "525",
    descricao: "ATIVIDADES RELACIONADAS À ORGANIZAÇÃO DO TRANSPORTE DE CARGA",
  },
  { cnae: "53", descricao: "CORREIO E OUTRAS ATIVIDADES DE ENTREGA" },
  { cnae: "531", descricao: "ATIVIDADES DE CORREIO" },
  { cnae: "532", descricao: "ATIVIDADES DE MALOTE E DE ENTREGA" },
  { cnae: "55", descricao: "ALOJAMENTO" },
  { cnae: "551", descricao: "HOTÉIS E SIMILARES" },
  {
    cnae: "559",
    descricao: "OUTROS TIPOS DE ALOJAMENTO NÃO ESPECIFICADOS ANTERIORMENTE",
  },
  { cnae: "56", descricao: "ALIMENTAÇÃO" },
  {
    cnae: "561",
    descricao: "RESTAURANTES E OUTROS SERVIÇOS DE ALIMENTAÇÃO E BEBIDAS",
  },
  {
    cnae: "562",
    descricao:
      "SERVIÇOS DE CATERING, BUFÊ E OUTROS SERVIÇOS DE COMIDA PREPARADA",
  },
  { cnae: "58", descricao: "EDIÇÃO E EDIÇÃO INTEGRADA À IMPRESSÃO" },
  {
    cnae: "581",
    descricao:
      "EDIÇÃO DE LIVROS, JORNAIS, REVISTAS E OUTRAS ATIVIDADES DE EDIÇÃO",
  },
  {
    cnae: "582",
    descricao:
      "EDIÇÃO INTEGRADA À IMPRESSÃO DE LIVROS, JORNAIS, REVISTAS E OUTRAS PUBLICAÇÕES",
  },
  {
    cnae: "59",
    descricao:
      "ATIVIDADES CINEMATOGRÁFICAS, PRODUÇÃO DE VÍDEOS E DE PROGRAMAS DE TELEVISÃO; GRAVAÇÃO DE SOM E EDIÇÃO DE MÚSICA",
  },
  {
    cnae: "591",
    descricao:
      "ATIVIDADES CINEMATOGRÁFICAS, PRODUÇÃO DE VÍDEOS E DE PROGRAMAS DE TELEVISÃO",
  },
  {
    cnae: "592",
    descricao: "ATIVIDADES DE GRAVAÇÃO DE SOM E DE EDIÇÃO DE MÚSICA",
  },
  { cnae: "60", descricao: "ATIVIDADES DE RÁDIO E DE TELEVISÃO" },
  { cnae: "601", descricao: "ATIVIDADES DE RÁDIO" },
  { cnae: "602", descricao: "ATIVIDADES DE TELEVISÃO" },
  { cnae: "61", descricao: "TELECOMUNICAÇÕES" },
  { cnae: "611", descricao: "TELECOMUNICAÇÕES POR FIO" },
  { cnae: "612", descricao: "TELECOMUNICAÇÕES SEM FIO" },
  { cnae: "613", descricao: "TELECOMUNICAÇÕES POR SATÉLITE" },
  { cnae: "614", descricao: "OPERADORAS DE TELEVISÃO POR ASSINATURA" },
  { cnae: "619", descricao: "OUTRAS ATIVIDADES DE TELECOMUNICAÇÕES" },
  {
    cnae: "62",
    descricao: "ATIVIDADES DOS SERVIÇOS DE TECNOLOGIA DA INFORMAÇÃO",
  },
  {
    cnae: "620",
    descricao: "ATIVIDADES DOS SERVIÇOS DE TECNOLOGIA DA INFORMAÇÃO",
  },
  {
    cnae: "63",
    descricao: "ATIVIDADES DE PRESTAÇÃO DE SERVIÇOS DE INFORMAÇÃO",
  },
  {
    cnae: "631",
    descricao:
      "TRATAMENTO DE DADOS, HOSPEDAGEM NA INTERNET E OUTRAS ATIVIDADES RELACIONADAS",
  },
  {
    cnae: "639",
    descricao: "OUTRAS ATIVIDADES DE PRESTAÇÃO DE SERVIÇOS DE INFORMAÇÃO",
  },
  { cnae: "64", descricao: "ATIVIDADES DE SERVIÇOS FINANCEIROS" },
  { cnae: "641", descricao: "BANCO CENTRAL" },
  { cnae: "642", descricao: "INTERMEDIAÇÃO MONETÁRIA - DEPÓSITOS À VISTA" },
  {
    cnae: "643",
    descricao: "INTERMEDIAÇÃO NÃO-MONETÁRIA - OUTROS INSTRUMENTOS DE CAPTAÇÃO",
  },
  { cnae: "644", descricao: "ARRENDAMENTO MERCANTIL" },
  { cnae: "645", descricao: "SOCIEDADES DE CAPITALIZAÇÃO" },
  { cnae: "646", descricao: "ATIVIDADES DE SOCIEDADES DE PARTICIPAÇÃO" },
  { cnae: "646", descricao: "ATIVIDADES DE SOCIEDADES DE PARTICIPAÇÃO" },
  {
    cnae: "649",
    descricao:
      "ATIVIDADES DE SERVIÇOS FINANCEIROS NÃO ESPECIFICADAS ANTERIORMENTE",
  },
  {
    cnae: "65",
    descricao:
      "SEGUROS, RESSEGUROS, PREVIDÊNCIA COMPLEMENTAR E PLANOS DE SAÚDE",
  },
  { cnae: "651", descricao: "SEGUROS DE VIDA E NÃO-VIDA" },
  { cnae: "652", descricao: "SEGUROS-SAÚDE" },
  { cnae: "653", descricao: "RESSEGUROS" },
  { cnae: "654", descricao: "PREVIDÊNCIA COMPLEMENTAR" },
  { cnae: "655", descricao: "PLANOS DE SAÚDE" },
  {
    cnae: "66",
    descricao:
      "ATIVIDADES AUXILIARES DOS SERVIÇOS FINANCEIROS, SEGUROS, PREVIDÊNCIA COMPLEMENTAR E PLANOS DE SAÚDE",
  },
  { cnae: "661", descricao: "ATIVIDADES AUXILIARES DOS SERVIÇOS FINANCEIROS" },
  {
    cnae: "662",
    descricao:
      "ATIVIDADES AUXILIARES DOS SEGUROS, DA PREVIDÊNCIA COMPLEMENTAR E DOS PLANOS DE SAÚDE",
  },
  {
    cnae: "663",
    descricao: "ATIVIDADES DE ADMINISTRAÇÃO DE FUNDOS POR CONTRATO OU COMISSÃO",
  },
  { cnae: "68", descricao: "ATIVIDADES IMOBILIÁRIAS" },
  { cnae: "681", descricao: "ATIVIDADES IMOBILIÁRIAS DE IMÓVEIS PRÓPRIOS" },
  {
    cnae: "682",
    descricao: "ATIVIDADES IMOBILIÁRIAS POR CONTRATO OU COMISSÃO",
  },
  {
    cnae: "69",
    descricao: "ATIVIDADES JURÍDICAS, DE CONTABILIDADE E DE AUDITORIA",
  },
  { cnae: "691", descricao: "ATIVIDADES JURÍDICAS" },
  {
    cnae: "692",
    descricao:
      "ATIVIDADES DE CONTABILIDADE, CONSULTORIA E AUDITORIA CONTÁBIL E TRIBUTÁRIA",
  },
  {
    cnae: "70",
    descricao:
      "ATIVIDADES DE SEDES DE EMPRESAS E DE CONSULTORIA EM GESTÃO EMPRESARIAL",
  },
  {
    cnae: "701",
    descricao: "SEDES DE EMPRESAS E UNIDADES ADMINISTRATIVAS LOCAIS",
  },
  { cnae: "702", descricao: "ATIVIDADES DE CONSULTORIA EM GESTÃO EMPRESARIAL" },
  {
    cnae: "71",
    descricao:
      "SERVIÇOS DE ARQUITETURA E ENGENHARIA; TESTES E ANÁLISES TÉCNICAS",
  },
  {
    cnae: "711",
    descricao:
      "SERVIÇOS DE ARQUITETURA E ENGENHARIA E ATIVIDADES TÉCNICAS RELACIONADAS",
  },
  { cnae: "712", descricao: "TESTES E ANÁLISES TÉCNICAS" },
  { cnae: "72", descricao: "PESQUISA E DESENVOLVIMENTO CIENTÍFICO" },
  {
    cnae: "721",
    descricao:
      "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIÊNCIAS FÍSICAS E NATURAIS",
  },
  {
    cnae: "722",
    descricao:
      "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIÊNCIAS SOCIAIS E HUMANAS",
  },
  { cnae: "73", descricao: "PUBLICIDADE E PESQUISA DE MERCADO" },
  { cnae: "731", descricao: "PUBLICIDADE" },
  { cnae: "732", descricao: "PESQUISAS DE MERCADO E DE OPINIÃO PÚBLICA" },
  {
    cnae: "74",
    descricao: "OUTRAS ATIVIDADES PROFISSIONAIS, CIENTÍFICAS E TÉCNICAS",
  },
  { cnae: "741", descricao: "DESIGN E DECORAÇÃO DE INTERIORES" },
  { cnae: "742", descricao: "ATIVIDADES FOTOGRÁFICAS E SIMILARES" },
  {
    cnae: "749",
    descricao:
      "ATIVIDADES PROFISSIONAIS, CIENTÍFICAS E TÉCNICAS NÃO ESPECIFICADAS ANTERIORMENTE",
  },
  { cnae: "75", descricao: "ATIVIDADES VETERINÁRIAS" },
  { cnae: "750", descricao: "ATIVIDADES VETERINÁRIAS" },
  {
    cnae: "77",
    descricao:
      "ALUGUÉIS NÃO-IMOBILIÁRIOS E GESTÃO DE ATIVOS INTANGÍVEIS NÃO-FINANCEIROS",
  },
  { cnae: "773", descricao: "ALUGUEL DE MÁQUINAS E EQUIPAMENTOS SEM OPERADOR" },
  { cnae: "774", descricao: "GESTÃO DE ATIVOS INTANGÍVEIS NÃO-FINANCEIROS" },
  { cnae: "78", descricao: "SELEÇÃO, AGENCIAMENTO E LOCAÇÃO DE MÃO-DE-OBRA" },
  { cnae: "781", descricao: "SELEÇÃO E AGENCIAMENTO DE MÃO-DE-OBRA" },
  { cnae: "782", descricao: "LOCAÇÃO DE MÃO-DE-OBRA TEMPORÁRIA" },
  {
    cnae: "783",
    descricao: "FORNECIMENTO E GESTÃO DE RECURSOS HUMANOS PARA TERCEIROS",
  },
  {
    cnae: "79",
    descricao:
      "AGÊNCIAS DE VIAGENS, OPERADORES TURÍSTICOS E SERVIÇOS DE RESERVAS",
  },
  { cnae: "791", descricao: "AGÊNCIAS DE VIAGENS E OPERADORES TURÍSTICOS" },
  {
    cnae: "799",
    descricao:
      "SERVIÇOS DE RESERVAS E OUTROS SERVIÇOS DE TURISMO NÃO ESPECIFICADOS ANTERIORMENTE",
  },
  {
    cnae: "80",
    descricao: "ATIVIDADES DE VIGILÂNCIA, SEGURANÇA E INVESTIGAÇÃO",
  },
  {
    cnae: "801",
    descricao:
      "ATIVIDADES DE VIGILÂNCIA, SEGURANÇA PRIVADA E TRANSPORTE DE VALORES",
  },
  {
    cnae: "802",
    descricao: "ATIVIDADES DE MONITORAMENTO DE SISTEMAS DE SEGURANÇA",
  },
  { cnae: "803", descricao: "ATIVIDADES DE INVESTIGAÇÃO PARTICULAR" },
  {
    cnae: "81",
    descricao: "SERVIÇOS PARA EDIFÍCIOS E ATIVIDADES PAISAGÍSTICAS",
  },
  { cnae: "811", descricao: "SERVIÇOS COMBINADOS PARA APOIO A EDIFÍCIOS" },
  { cnae: "812", descricao: "ATIVIDADES DE LIMPEZA" },
  { cnae: "813", descricao: "ATIVIDADES PAISAGÍSTICAS" },
  {
    cnae: "82",
    descricao:
      "SERVIÇOS DE ESCRITÓRIO, DE APOIO ADMINISTRATIVO E OUTROS SERVIÇOS PRESTADOS ÀS EMPRESAS",
  },
  { cnae: "821", descricao: "SERVIÇOS DE ESCRITÓRIO E APOIO ADMINISTRATIVO" },
  { cnae: "822", descricao: "ATIVIDADES DE TELEATENDIMENTO" },
  {
    cnae: "823",
    descricao:
      "ATIVIDADES DE ORGANIZAÇÃO DE EVENTOS, EXCETO CULTURAIS E ESPORTIVOS",
  },
  {
    cnae: "829",
    descricao:
      "OUTRAS ATIVIDADES DE SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
  },
  {
    cnae: "84",
    descricao: "ADMINISTRAÇÃO PÚBLICA, DEFESA E SEGURIDADE SOCIAL",
  },
  {
    cnae: "841",
    descricao: "ADMINISTRAÇÃO DO ESTADO E DA POLÍTICA ECONÔMICA E SOCIAL",
  },
  {
    cnae: "842",
    descricao: "SERVIÇOS COLETIVOS PRESTADOS PELA ADMINISTRAÇÃO PÚBLICA",
  },
  { cnae: "843", descricao: "SEGURIDADE SOCIAL OBRIGATÓRIA" },
  { cnae: "85", descricao: "EDUCAÇÃO" },
  { cnae: "851", descricao: "EDUCAÇÃO INFANTIL E ENSINO FUNDAMENTAL" },
  { cnae: "852", descricao: "ENSINO MÉDIO" },
  { cnae: "853", descricao: "EDUCAÇÃO SUPERIOR" },
  {
    cnae: "854",
    descricao: "EDUCAÇÃO PROFISSIONAL DE NÍVEL TÉCNICO E TECNOLÓGICO",
  },
  { cnae: "855", descricao: "ATIVIDADES DE APOIO À EDUCAÇÃO" },
  { cnae: "859", descricao: "OUTRAS ATIVIDADES DE ENSINO" },
  { cnae: "86", descricao: "ATIVIDADES DE ATENÇÃO À SAÚDE HUMANA" },
  { cnae: "861", descricao: "ATIVIDADES DE ATENDIMENTO HOSPITALAR" },
  {
    cnae: "862",
    descricao:
      "SERVIÇOS MÓVEIS DE ATENDIMENTO A URGÊNCIAS E DE REMOÇÃO DE PACIENTES",
  },
  {
    cnae: "863",
    descricao:
      "ATIVIDADES DE ATENÇÃO AMBULATORIAL EXECUTADAS POR MÉDICOS E ODONTÓLOGOS",
  },
  {
    cnae: "864",
    descricao:
      "ATIVIDADES DE SERVIÇOS DE COMPLEMENTAÇÃO DIAGNÓSTICA E TERAPÊUTICA",
  },
  {
    cnae: "865",
    descricao:
      "ATIVIDADES DE PROFISSIONAIS DA ÁREA DE SAÚDE, EXCETO MÉDICOS E ODONTÓLOGOS",
  },
  { cnae: "866", descricao: "ATIVIDADES DE APOIO À GESTÃO DE SAÚDE" },
  {
    cnae: "869",
    descricao:
      "ATIVIDADES DE ATENÇÃO À SAÚDE HUMANA NÃO ESPECIFICADAS ANTERIORMENTE",
  },
  {
    cnae: "87",
    descricao:
      "ATIVIDADES DE ATENÇÃO À SAÚDE HUMANA INTEGRADAS COM ASSISTÊNCIA SOCIAL, PRESTADAS EM RESIDÊNCIAS COLETIVAS E PARTICULARES",
  },
  {
    cnae: "871",
    descricao:
      "ATIVIDADES DE ASSISTÊNCIA A IDOSOS, DEFICIENTES FÍSICOS, IMUNODEPRIMIDOS E CONVALESCENTES, E DE INFRA-ESTRUTURA E APOIO A PACIENTES PRESTADAS EM RESIDÊNCIAS COLETIVAS E PARTICULARES",
  },
  {
    cnae: "872",
    descricao:
      "ATIVIDADES DE ASSISTÊNCIA PSICOSSOCIAL E À SAÚDE A PORTADORES DE DISTÚRBIOS PSÍQUICOS, DEFICIÊNCIA MENTAL E DEPENDÊNCIA QUÍMICA",
  },
  {
    cnae: "873",
    descricao:
      "ATIVIDADES DE ASSISTÊNCIA SOCIAL PRESTADAS EM RESIDÊNCIAS COLETIVAS E PARTICULARES",
  },
  { cnae: "88", descricao: "SERVIÇOS DE ASSISTÊNCIA SOCIAL SEM ALOJAMENTO" },
  { cnae: "880", descricao: "SERVIÇOS DE ASSISTÊNCIA SOCIAL SEM ALOJAMENTO" },
  {
    cnae: "90",
    descricao: "ATIVIDADES ARTÍSTICAS, CRIATIVAS E DE ESPETÁCULOS",
  },
  {
    cnae: "900",
    descricao: "ATIVIDADES ARTÍSTICAS, CRIATIVAS E DE ESPETÁCULOS",
  },
  {
    cnae: "91",
    descricao: "ATIVIDADES LIGADAS AO PATRIMÔNIO CULTURAL E AMBIENTAL",
  },
  {
    cnae: "910",
    descricao: "ATIVIDADES LIGADAS AO PATRIMÔNIO CULTURAL E AMBIENTAL",
  },
  {
    cnae: "92",
    descricao: "ATIVIDADES DE EXPLORAÇÃO DE JOGOS DE AZAR E APOSTAS",
  },
  {
    cnae: "920",
    descricao: "ATIVIDADES DE EXPLORAÇÃO DE JOGOS DE AZAR E APOSTAS",
  },
  { cnae: "93", descricao: "ATIVIDADES ESPORTIVAS E DE RECREAÇÃO E LAZER" },
  { cnae: "931", descricao: "ATIVIDADES ESPORTIVAS" },
  { cnae: "932", descricao: "ATIVIDADES DE RECREAÇÃO E LAZER" },
  { cnae: "94", descricao: "ATIVIDADES DE ORGANIZAÇÕES ASSOCIATIVAS" },
  {
    cnae: "941",
    descricao:
      "ATIVIDADES DE ORGANIZAÇÕES ASSOCIATIVAS PATRONAIS, EMPRESARIAIS E PROFISSIONAIS",
  },
  { cnae: "942", descricao: "ATIVIDADES DE ORGANIZAÇÕES SINDICAIS" },
  {
    cnae: "943",
    descricao: "ATIVIDADES DE ASSOCIAÇÕES DE DEFESA DE DIREITOS SOCIAIS",
  },
  {
    cnae: "949",
    descricao:
      "ATIVIDADES DE ORGANIZAÇÕES ASSOCIATIVAS NÃO ESPECIFICADAS ANTERIORMENTE",
  },
  {
    cnae: "95",
    descricao:
      "REPARAÇÃO E MANUTENÇÃO DE EQUIPAMENTOS DE INFORMÁTICA E COMUNICAÇÃO E DE OBJETOS PESSOAIS E DOMÉSTICOS",
  },
  {
    cnae: "951",
    descricao:
      "REPARAÇÃO E MANUTENÇÃO DE EQUIPAMENTOS DE INFORMÁTICA E COMUNICAÇÃO",
  },
  {
    cnae: "952",
    descricao:
      "REPARAÇÃO E MANUTENÇÃO DE OBJETOS E EQUIPAMENTOS PESSOAIS E DOMÉSTICOS",
  },
  { cnae: "96", descricao: "OUTRAS ATIVIDADES DE SERVIÇOS PESSOAIS" },
  { cnae: "960", descricao: "OUTRAS ATIVIDADES DE SERVIÇOS PESSOAIS" },
  { cnae: "97", descricao: "SERVIÇOS DOMÉSTICOS" },
  { cnae: "970", descricao: "SERVIÇOS DOMÉSTICOS" },
  {
    cnae: "99",
    descricao:
      "ORGANISMOS INTERNACIONAIS E OUTRAS INSTITUIÇÕES EXTRATERRITORIAIS",
  },
  {
    cnae: "990",
    descricao:
      "ORGANISMOS INTERNACIONAIS E OUTRAS INSTITUIÇÕES EXTRATERRITORIAIS",
  },
];
