import React, { CSSProperties } from "react";
import fonts from "../../config/fonts";
interface TitleProps {
  color?: string;
  style?: CSSProperties;
  children: React.ReactNode;
}

export function Title({ children, style, color = "#3A3A3A" }: TitleProps) {
  return (
    <h1
      style={{
        fontSize: fonts.sizes.regular,
        color: color,
        ...style,
      }}
    >
      {children}
    </h1>
  );
}
