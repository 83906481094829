import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import {
  FaArrowRight,
  FaCheck,
  FaCheckDouble,
  FaFloppyDisk,
  FaUser,
  FaX,
} from "react-icons/fa6";
import { ButtonDefault } from "../../../../components/Button/ButtonDefault";
import { CustomInput } from "../../../../components/Input/CustomInput";
import { ModalDefault } from "../../../../components/Modal/ModalDefault";
import { SelectDefault } from "../../../../components/Select/SelectDefault";
import { TitleModal } from "../../../../components/typography/TitleModal";
import { SubTitleModal } from "../../../../components/typography/SubTitleModalReports";
import { UserByCompany } from "../../types";
import {
  createAdminCompanyUser,
  createAdminUser,
  createUser,
  updateUser,
} from "../../../../services/getOptionsData";
import { Title } from "../../../../components/typography/Title";
import { SubTitle } from "../../../../components/typography/SubTitle";

export interface CreateOrEditUserData {
  user?: UserByCompany;
  tokenCompany: string;
  setUsers: (value: UserByCompany[]) => void;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: (icon: string, title: string, msg: string) => void;
  canclose: boolean;
}

export function CreateOrEditUser({
  user,
  tokenCompany,
  setUsers,
  setModalOpen,
  setMessage: setMessage,
  canclose,
}: CreateOrEditUserData) {
  const [email, setEmail] = useState(user ? user.email : "");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState(user ? user.firstName : "");
  const [surname, setSurname] = useState(user ? user.lastName : "");
  const [permission, setPermission] = useState(user ? user.function.name : "");
  const [enable, setEnable] = useState(user ? user.enable : true);

  const editOrAdd = user
    ? {
        titleModal: "Editar Usuário",
        title: "EDITAR USUÁRIO",
        subTitle: `Abaixo você edita os dados do usuário e vincula-lo a empresa ${user.company.name}.`,
        buttonTitle: "EDITAR USUÁRIO",
      }
    : {
        titleModal: "Adicionar Usuário",
        title: "ADICIONAR USUÁRIO",
        subTitle:
          "Abaixo você insere os dados necessários para criar um novo usuário e vincula-lo a empresa",
        buttonTitle: "ADICIONAR USUÁRIO",
      };

  const optionsPermissions = [
    { label: "Administrador Franqueado", value: "2" },
    { label: "Franqueado", value: "1" },
    { label: "Usuário Franqueado", value: "3" },
  ];

  const optionsPermissionsEdit = [
    { label: "Administrador Deepen", value: "Administrador Deepen" },
    { label: "Administrador da empresa", value: "Administrador da empresa" },
    { label: "Usuario da empresa", value: "Usuario da empresa" },
    { label: "Usuario", value: "Usuario" },
  ];

  const handleSaveUser = () => {
    if (!email || !firstName || !surname || !permission) {
      return;
    }
    const newUserData = {
      email,
      password,
      firstName,
      lastName: surname,
      token: tokenCompany,
    };

    const updateUserData = {
      email,
      firstName,
      lastName: surname,
      role: permission,
      enable,
    };

    if (!user) {
      if (permission === "1") {
        // franqueado
        createAdminCompanyUser(newUserData).then((response) => {
          setUsers([]);
        });
      } else if (permission === "2") {
        // admin franqueado
        const setErrorMessage = (title: string, msg: string) => {
          setMessage("X", title, msg);
        };
        createAdminUser(newUserData, setErrorMessage).then((response) => {
          setUsers([]);
        });
      } else if (permission === "3") {
        // usuario franqueado
        const setErrorMessage = (title: string, msg: string) => {
          setMessage("X", title, msg);
        };
        createUser(newUserData, setErrorMessage).then((response) => {
          setUsers([]);
        });
      }
    } else {
      updateUser(user.company.id, user?.id, updateUserData).then((response) => {
        setUsers([]);
      });
    }

    setModalOpen(false);
  };

  return (
    <>
      <ModalDefault
        icon={<FaUser />}
        setModalOpen={setModalOpen}
        title={editOrAdd.titleModal}
        styleContent={{
          maxWidth: 600,
        }}
        canclose={canclose}
      >
        <TitleModal style={{ textAlign: "center" }}>
          {editOrAdd.title}
        </TitleModal>

        <SubTitleModal
          style={{
            padding: "10px 20px",
          }}
        >
          {editOrAdd.subTitle}
        </SubTitleModal>

        <CustomInput
          style={{
            marginRight: 8,
            marginBottom: 8,
          }}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          editable={user ? false : true}
          placeholder="Email do usuário"
          type="email"
        />
        {!user && (
          <CustomInput
            style={{
              marginRight: 8,
              marginBottom: 8,
            }}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            placeholder="Senha para a conta do usuário"
            type="new-password"
          />
        )}
        <CustomInput
          style={{
            marginRight: 8,
            marginBottom: 8,
          }}
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          placeholder="Nome"
          type="text"
        />
        <CustomInput
          style={{
            marginRight: 8,
            marginBottom: 8,
          }}
          value={surname}
          onChange={(e) => {
            setSurname(e.target.value);
          }}
          placeholder="Sobrenome"
          type="text"
        />
        <SelectDefault
          borderRadius={5}
          onChange={(value) => {
            setPermission(value);
          }}
          options={user ? optionsPermissionsEdit : optionsPermissions}
          placeholder="Selecionar permissões"
          defaultValue={user ? user.function.name : ""}
        />
        <Divider />

        {user && (
          <ButtonDefault
            style={{
              marginRight: 0,
              marginLeft: 0,
            }}
            size="superLarge"
            title={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: 450,
                }}
              >
                <Title
                  style={{
                    color: enable ? undefined : "#3A3A3A",
                    transition: "all 0.09s",
                  }}
                >
                  ATIVAR CONTA
                </Title>
                <SubTitle
                  style={{
                    color: undefined,
                    paddingBottom: 0,
                  }}
                >
                  Ativar a conta de uma empresa vai possibilitar que seus
                </SubTitle>

                <SubTitle
                  style={{
                    color: undefined,
                    paddingTop: 0,
                  }}
                >
                  usuários tenham acesso a solução.
                </SubTitle>
              </div>
            }
            icon={<FaArrowRight />}
            iconPosition="right"
            border={true}
            color={enable ? "deepblueInverted" : "disabled"}
            onClick={() => {
              setEnable(!enable);
            }}
          />
        )}

        <Divider />
        <ButtonDefault
          style={{
            flex: 1,
            marginRight: "auto",
            marginLeft: "auto",
            width: "fit-content",
          }}
          color="secondary"
          size="medium"
          title={editOrAdd.buttonTitle}
          borderRadius={40}
          icon={<FaFloppyDisk />}
          iconPosition="right"
          onClick={() => {
            handleSaveUser();
          }}
        />
      </ModalDefault>
    </>
  );
}
