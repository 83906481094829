export const getBrasilGeoJson = async () => {
  const url = "/mapshapers/brazil_states.txt";

  const response = await fetch(url);

  const data = await response.text();

  const geoJson = JSON.parse(data);

  return geoJson;
};

export const getStateGeoJson = async (stateId, setLoading) => {
  const url = `/mapshapers/states/${stateId}.txt`;
  try {
    setLoading(true);
    const response = await fetch(url);
    const data = await response.text();
    const geoJson = JSON.parse(data);
    return geoJson;
  } catch (error) {
    throw error;
  } finally {
    setLoading(false);
  }
};
