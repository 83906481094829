import { useState, useEffect } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { FaCheck, FaX } from "react-icons/fa6";
import { ButtonDefault } from "../../../../../components/Button/ButtonDefault";
import { ModalDefault } from "../../../../../components/Modal/ModalDefault";
import fonts from "../../../../../config/fonts";
import images from "../../../../../config/images";
import { ConfirmaDados } from "../../../Companies";

export interface ExportMessagesScreenData {
  titulo: string;
  mensagem: string;
  mostraConfirma: boolean;
  setMostraConfirma: React.Dispatch<React.SetStateAction<boolean>>;
  confirmaDados: ConfirmaDados | null;
}

export function ExportMessagesScreen({
  titulo,
  mensagem,
  mostraConfirma,
  setMostraConfirma,
  confirmaDados,
}: ExportMessagesScreenData) {
  const [currentComponentIndex, setCurrentComponentIndex] = useState(0);

  const firstPiece = () => {
    return (
      <>
        <img src={images.Pin} className="pin"></img>
      </>
    );
  };

  const secondPiece = () => {
    return (
      <>
        <img src={images.Pin} className="pin"></img>
        <img src={images.Line}></img>
        <img src={images.Pin} className="pin"></img>
      </>
    );
  };

  const thirdPiece = () => {
    return (
      <>
        <img src={images.Pin} className="pin"></img>
        <img src={images.Line}></img>
        <img src={images.Pin} className="pin"></img>
        <img src={images.Line}></img>
        <img src={images.Pin} className="pin"></img>
      </>
    );
  };

  const components = [firstPiece, secondPiece, thirdPiece];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentComponentIndex(
        (prevIndex) => (prevIndex + 1) % components.length
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [components.length]);

  const CurrentComponent = components[currentComponentIndex];

  const mystyle = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "5px",
  };

  return (
    <div className="loading-container" style={{ fontSize: 20 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: 580,
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <h1
          className="title"
          style={{
            fontFamily: fonts.expBold,
          }}
        >
          {titulo}
        </h1>
        <p
          className="subtitle"
          style={{
            fontFamily: fonts.regular,
            fontSize: fonts.regular,
          }}
        >
          {mensagem}
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "left",
            marginTop: 70,
            width: 310,
          }}
        >
          <CurrentComponent />
          {mostraConfirma ? (
            <ModalDefault
              icon={<FaQuestionCircle />}
              setModalOpen={setMostraConfirma}
              title={confirmaDados?.titulo + ""}
              styleContent={{
                maxWidth: 500,
              }}
            >
              <div>
                <div style={mystyle}>
                  <p>{confirmaDados?.mensagem1}</p>
                  <p>{confirmaDados?.valor1}</p>
                </div>
                {confirmaDados?.mensagem2 ? (
                  <div style={mystyle}>
                    <div>{confirmaDados?.mensagem2}</div>
                    <div>{confirmaDados?.valor2}</div>
                  </div>
                ) : (
                  <></>
                )}
                {confirmaDados?.mensagem3 ? (
                  <div style={mystyle}>
                    <div>{confirmaDados?.mensagem3}</div>
                    <div>{confirmaDados?.valor3}</div>
                  </div>
                ) : (
                  <></>
                )}
                {confirmaDados?.mensagem4 ? (
                  <div>
                    <div>{confirmaDados?.mensagem4}</div>
                  </div>
                ) : (
                  <></>
                )}
                {confirmaDados?.mensagem5 ? (
                  <div>
                    <div>{confirmaDados?.mensagem5}</div>
                  </div>
                ) : (
                  <></>
                )}
                <div style={{ padding: 10 }} />
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <ButtonDefault
                    icon={<FaCheck />}
                    iconPosition="right"
                    title="Exportar"
                    style={{
                      borderRadius: "15px",
                      margin: 0,
                    }}
                    size={"medium"}
                    color={"orange"}
                    transparent={false}
                    onClick={async () => {
                      const nome = "arquivoExportado";
                      confirmaDados?.funcaoAoConfirmar(nome);
                      setMostraConfirma(false);
                    }}
                  />
                  <div style={{ padding: 20 }} />
                  <ButtonDefault
                    icon={<FaX />}
                    iconPosition="right"
                    title="Cancelar"
                    style={{
                      borderRadius: "15px",
                      margin: 0,
                    }}
                    size={"medium"}
                    color={"secondary"}
                    transparent={false}
                    onClick={async () => {
                      confirmaDados?.funcaoAoCancelar();
                      setMostraConfirma(false);
                    }}
                  />
                </div>
              </div>
            </ModalDefault>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
