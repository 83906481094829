import { FaArrowRight, FaGlobeAmericas, FaTrash } from "react-icons/fa";
import { ModalDefault } from "../../../../../components/Modal/ModalDefault";
import { TitleModal } from "../../../../../components/typography/TitleModal";
import { SubTitle } from "../../../../../components/typography/SubTitle";
import { ButtonLink } from "../../../../../components/Button/ButtonLink";
import { Icon } from "../../../../../components/icon/Icon";
import { FilesIcon } from "../../../../../components/icon/FilesIcon";
import { colors } from "../../../../../assets/colors";
import { Fragment, useEffect, useState } from "react";
import { Divider } from "antd";
import { ButtonDefault } from "../../../../../components/Button/ButtonDefault";
import { CustomInput } from "../../../../../components/Input/CustomInput";
import { Checkbox2 } from "../../../../../components/Checkbox/CheckBox2";
import { VariableSelectOption } from "../../../../../Types/Variable";
import { AutoComplete } from "../../../../../components/AutoComplete/AutoComplete";
import { StatesProps } from "../MenuCenter";
import { FreeNavigationParams } from "../../../../../Types/Params";
import { Tags } from "../../Preview/components/Tags";

const grayColor = colors.default.disabled.content;

interface ModalFreeNavigationProps {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  variableOptions?: VariableSelectOption[];
  statesOptions?: StatesProps[];
  paramsToFreeNavigation: FreeNavigationParams;
  setParamsToFreeNavigation: React.Dispatch<
    React.SetStateAction<FreeNavigationParams>
  >;
}

interface UnitProps {
  type: "Cidade" | "Setor Censitário";
}

interface ParamsProps {
  label: string;
  min: string;
  max: string;
}

interface stateProps {
  label: string;
  value: string;
}

interface RangeProps {
  [key: string]: ParamsProps;
}

export function ModalFreeNavigation({
  setModalOpen,
  variableOptions,
  statesOptions,
  paramsToFreeNavigation,
  setParamsToFreeNavigation,
}: ModalFreeNavigationProps) {
  const [type, setType] = useState<UnitProps["type"]>("Setor Censitário");
  const [exclude, setExclude] = useState<boolean>(false);
  const [params, setParams] = useState<RangeProps>({});
  const [state, setState] = useState<stateProps>({ label: "", value: "" });
  const [filterCodes, setFilterCodes] = useState<stateProps[]>([]);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (paramsToFreeNavigation.id) {
      const filteredState = statesOptions?.find(
        ({ stateCode }) => stateCode.toString() === paramsToFreeNavigation.id
      );
      if (filteredState) {
        setState({
          label: filteredState.state,
          value: filteredState.stateCode.toString(),
        });
      }
    }
    if (paramsToFreeNavigation.ranges) {
      const newParams: RangeProps = {};
      paramsToFreeNavigation.ranges.forEach(({ label, min, max }) => {
        newParams[label] = {
          label,
          min: min.toString(),
          max: max.toString(),
        };
      });
      setParams(newParams);
    }
    if (paramsToFreeNavigation.filterCodes) {
      const newFilterCodes: stateProps[] = [];
      paramsToFreeNavigation.filterCodes.forEach((code) => {
        const filteredState = statesOptions?.find(
          ({ stateCode }) => stateCode === code
        );
        if (filteredState) {
          newFilterCodes.push({
            label: filteredState.state,
            value: filteredState.stateCode.toString(),
          });
        }
      });
      setFilterCodes(newFilterCodes);
    }

    if (paramsToFreeNavigation.exclude !== null) {
      setExclude(paramsToFreeNavigation.exclude);
      setType("Cidade");
    }
  }, [paramsToFreeNavigation, statesOptions]);

  const paramsKeysfull = () => {
    const keys = Object.keys(params);
    const full = keys.every((key) => {
      const { min, max } = params[key];
      return min !== "" && max !== "";
    });
    return full;
  };

  const full =
    (state.label !== "" && paramsKeysfull()) ||
    type === "Cidade" ||
    (type === "Setor Censitário" && paramsToFreeNavigation.exclude !== null);

  const optionsVariable = statesOptions?.map(({ state, stateCode }) => {
    return {
      label: state,
      value: stateCode.toString(),
    };
  });

  const StateSelection = () => {
    return (
      <AutoComplete
        options={optionsVariable ? optionsVariable : []}
        placeholder="Estado"
        defaultValue={type === "Cidade" ? "" : state.label}
        onChange={(newvalue) => {
          const filteredState = statesOptions?.find(
            ({ stateCode }) => stateCode.toString() === newvalue
          );
          if (filteredState) {
            if (type !== "Cidade") {
              setState({
                label: filteredState.state,
                value: filteredState.stateCode.toString(),
              });
            } else {
              //no reapeat
              const isRepeat = filterCodes.find(
                ({ value }) => value === filteredState.stateCode.toString()
              );
              if (!isRepeat)
                setFilterCodes([
                  ...filterCodes,
                  {
                    label: filteredState.state,
                    value: filteredState.stateCode.toString(),
                  },
                ]);
            }
          }
        }}
        clearOnSelect={type === "Cidade"}
        searchFilter={(text, options) => {
          return options!.filter((option: any) =>
            option.label.toUpperCase().includes(text.toUpperCase())
          );
        }}
      />
    );
  };

  const handleParamsToFreeNavigation = () => {
    setParamsToFreeNavigation({
      ...paramsToFreeNavigation,
      exclude: type === "Cidade" ? exclude : null,
      filterCodes:
        type === "Cidade" ? filterCodes.map(({ value }) => Number(value)) : [],
      id: state.value,
      ranges: Object.values(params).map(({ label, min, max }) => ({
        label,
        min: Number(min),
        max: Number(max),
      })),
    });

    setModalOpen(false);
  };

  return (
    <ModalDefault
      icon={<FaGlobeAmericas />}
      setModalOpen={setModalOpen}
      title="Navegação livre"
      styleContent={{
        maxWidth: 546,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TitleModal>PARÂMETROS</TitleModal>
        <SubTitle
          style={{
            textAlign: "center",
            marginBottom: 26,
          }}
        >
          Caso esteja interessado você pode selecionar abaixo os parâmetros que
          desejar para sua visualização no mapa.
        </SubTitle>

        {/* implementar depois
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonLink
            underline={false}
            style={{
              fontWeight: "bold",
            }}
          >
            REUTILIZAR PARÂMETROS ANTERIORES&nbsp;
            <Icon icon={<FilesIcon />} color={colors.link} />
          </ButtonLink>
        </div>
      */}
        <div
          style={{
            width: "100%",
            marginTop: 16,
            alignItems: "center",
            display: "flex",
          }}
        >
          <Checkbox2
            style={{
              marginRight: 8,
            }}
            label={"Cidade"}
            color={grayColor}
            checked={type === "Cidade"}
            onChange={() => {
              setType("Cidade");
            }}
          />
          <Checkbox2
            style={{
              marginLeft: 8,
            }}
            label={"Setor Censitário"}
            color={grayColor}
            checked={type === "Setor Censitário"}
            onChange={() => {
              setType("Setor Censitário");
            }}
          />
        </div>
        <div style={{ width: "100%", marginTop: 16 }}>
          <AutoComplete
            searchFilter={(text, options) => {
              return options
                ?.map((item: any) => ({
                  ...item,
                  options: item.options.filter((option: any) =>
                    option.label.toUpperCase().includes(text.toUpperCase())
                  ),
                }))
                .filter((item) => item.options.length > 0);
            }}
            clearOnSelect
            options={variableOptions}
            placeholder="Parâmetro"
            onChange={(newvalue) => {
              const label = newvalue.toString();
              setParams({
                ...params,
                [label]: {
                  label,
                  min: "",
                  max: "",
                },
              });
            }}
          />
        </div>

        {params &&
          Object.values(params).map(({ label, min, max }) => {
            return (
              <Fragment key={label}>
                <div
                  style={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: 1,
                      backgroundColor: "#f0f0f0",
                    }}
                  />

                  <SubTitle
                    style={{
                      margin: "0 10px",
                      maxWidth: 300,
                      textAlign: "center",
                    }}
                  >
                    {label}
                  </SubTitle>

                  <div
                    style={{
                      flex: 1,
                      height: 1,
                      backgroundColor: "#f0f0f0",
                    }}
                  />
                </div>

                <div
                  style={{
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <CustomInput
                    style={{
                      width: "50%",
                      marginRight: 16,
                    }}
                    value={min}
                    onChange={(e) => {
                      const newParams = { ...params };
                      newParams[label].min = e.target.value;
                      if (newParams[label].max !== "") {
                        if (newParams[label].min > newParams[label].max) {
                          setError(true);
                          setParams(newParams);
                        } else {
                          setError(false);
                          setParams(newParams);
                        }
                      } else {
                        setParams(newParams);
                      }
                    }}
                    placeholder="Mínimo"
                    type="number"
                  />
                  <div style={{ width: "50%" }}>
                    <CustomInput
                      style={{
                        marginRight: 16,
                      }}
                      value={max}
                      onChange={(e) => {
                        const newParams = { ...params };
                        newParams[label].max = e.target.value;
                        if (newParams[label].max < newParams[label].min) {
                          setError(true);
                          setParams(newParams);
                        } else {
                          setError(false);
                          setParams(newParams);
                        }
                      }}
                      placeholder="Máximo"
                      type="number"
                    />
                    {error ? (
                      <p
                        style={{
                          position: "fixed",
                          color: "red",
                          fontSize: 12,
                        }}
                      >
                        Valor máximo deve ser maior do que o mínimo
                      </p>
                    ) : null}
                  </div>
                  <div style={{ flex: 1, marginLeft: 8 }}>
                    <ButtonDefault
                      style={{
                        borderRadius: "24px",
                        margin: 0,
                      }}
                      icon={<FaTrash />}
                      size={"smallTwo"}
                      color={"secondary"}
                      onClick={() => {
                        const newParams = { ...params };
                        delete newParams[label];
                        setParams(newParams);
                      }}
                    />
                  </div>
                </div>
              </Fragment>
            );
          })}
      </div>

      {type === "Cidade" && (
        <div
          style={{
            width: "100%",
            marginTop: 16,
            alignItems: "center",
            display: "flex",
          }}
        >
          <Checkbox2
            style={{
              marginRight: 8,
            }}
            label={"Excluir"}
            color={grayColor}
            checked={exclude}
            onChange={() => {
              setExclude(!exclude);
            }}
          />
          <Checkbox2
            style={{
              marginLeft: 8,
            }}
            label={"Incluir"}
            color={grayColor}
            checked={!exclude}
            onChange={() => {
              setExclude(!exclude);
            }}
          />
        </div>
      )}

      <div style={{ width: "100%", marginTop: 16 }}>
        {type === "Cidade" ? <StateSelection /> : <StateSelection />}
      </div>

      {type === "Cidade" && filterCodes.length > 0 && (
        <div
          style={{
            width: "100%",
            marginTop: 16,
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {filterCodes.map(({ label, value }, index) => {
            return (
              <Tags
                key={index}
                label={label}
                color={colors.default.secondary.background}
                onClick={() => {
                  setFilterCodes(
                    filterCodes.filter((item) => item.value !== value)
                  );
                }}
              />
            );
          })}
        </div>
      )}

      {/* implementar depois
      <SubTitle
        style={{
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Não quer selecionar parâmetros?&nbsp;
        <ButtonLink style={{ width: "auto", margin: 0 }} underline={false}>
          Navegue livremente.
        </ButtonLink>
      </SubTitle>
      */}
      <Divider />
      <ButtonDefault
        style={{
          marginRight: "auto",
          marginLeft: "auto",
        }}
        disabled={!full && error}
        size="medium"
        title="ADICIONAR PARÂMETROS PARA A NAVEGAÇÃO"
        icon={<FaArrowRight />}
        iconPosition="right"
        color={full && !error ? "secondary" : "disabled"}
        border={true}
        borderRadius={40}
        onClick={() => {
          handleParamsToFreeNavigation();
        }}
      />
    </ModalDefault>
  );
}
