import { httpConfig } from "./httpConfig";
import { httpMethod } from "./httpMethod";
import { UserToken } from "../services/userToken";
import {
  OptionsSelectResponseProps,
  StatesUfProps,
  limitResponseProps,
} from "../Types/Variable";
import { ContractResponse, LimiterResponse, LimiterUpdate, UserByCompany } from "../pages/Administration/types";
import { NewInformationsCompany } from "../pages/Administration/components/ModalToAddOrEditCompany/ModalToAddOrEditCompany";
import { findCityCode, findStateCode } from "../data/cityNumbers";

interface Params {
  [key: string]: any;
}

type FetchDataFunction = (options: {
  url: string;
  method: string;
  params?: Params;
  setLoading?: (isLoading: boolean) => void;
  setErrorMessage?: (title: string, msg: string) => void;
}) => Promise<any>;

const fetchData: FetchDataFunction = async (original) => {
  const { url, method, params, setLoading, setErrorMessage } = original;
  const options = JSON.parse(JSON.stringify(original));//cria copia para não modificar instancia original
  if(options.params?.nodefault){
    //if this option is set, dont add default values
  }else
  if(options.params?.model){
    if(options.params?.model.capitalSocial){
      addDefaultParams(options.params?.model);
    }else{
      addDefaultParams(options.params?.model.model);
    }
  }else if(options.params?.filter?.model){
    addDefaultParams(options.params?.filter?.model);
  }
  try {
    setLoading && setLoading(true);
    const response = await fetch(url, {
      method: method,
      body: JSON.stringify(options.params),
      headers: {
        "Content-Type": "application/json",
        Authorization: UserToken.token,
      },
    });

    if (response.status === 401) {
      document.dispatchEvent(new CustomEvent("logout"));
      return;
    }

    if (response.status === 400) {
      const data = await response.json();
      if(data.log==="Limite de administradores master atingido."){
        setErrorMessage && setErrorMessage("Erro!", "Limite de administradoes atingido.");
      }else if(data.log==="Limite de usuários franqueados atingido."){
        setErrorMessage && setErrorMessage("Erro!", "Limite de usuários atingido.");
      }else{
        setErrorMessage && setErrorMessage("Erro!", data.log);
      }
      return;
    }
    
    if(response.status === 500) {
      return null;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    setErrorMessage && setErrorMessage("Erro!", error+"");
  } finally {
    setLoading && setLoading(false);
  }
};

const fetchAddress: FetchDataFunction = async (original) => {
  const { url, method, params, setLoading } = original;
  const options = JSON.parse(JSON.stringify(original));//cria copia para não modificar instancia original
  if(options.params?.nodefault){
    //if this option is set, dont add default values
  }else
  if(options.params?.model){
    if(options.params?.model.capitalSocial){
      addDefaultParams(options.params?.model);
    }else{
      addDefaultParams(options.params?.model.model);
    }
  }else if(options.params?.filter?.model){
    addDefaultParams(options.params?.filter?.model);
  }
  try {
    setLoading && setLoading(true);
    const response = await fetch(url, {
      method: method,
      body: JSON.stringify(options.params),
      headers: {
        "Content-Type": "application/json",
        Authorization: UserToken.token,
      },
    });

    if (response.status === 401) {
      document.dispatchEvent(new CustomEvent("logout"));
      return;
    }
    
    if (response.status === 400){
      return null;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  } finally {
    setLoading && setLoading(false);
  }
};

export const addDefaultParams = (model:any)=>{
  model.capitalSocial.push(...UserToken.defaultfilters.model.capitalSocial );
  model.cidade.push(...UserToken.defaultfilters.model.cidade);
  model.cnae.push(...UserToken.defaultfilters.model.cnae);
  model.estado.push(...UserToken.defaultfilters.model.estado);
  model.inicioDeAtividade.push(...UserToken.defaultfilters.model.inicioDeAtividade);
  model.porte.push(...UserToken.defaultfilters.model.porte);
};

export const addDefaultParamsStudy = (model:any)=>{
  if(!model.filtroCidades){
    model.filtroCidades = [];
  }
  for (let index = 0; index < UserToken.defaultfilters.model.cidade.length; index++) {
    const city = UserToken.defaultfilters.model.cidade[index];
    model.filtroCidades.push(findCityCode(city));
    
  }
  /*
  if(!model.filtroEstados){
    model.filtroEstados = [];
  }
  for (let index = 0; index < UserToken.defaultfilters.model.estado.length; index++) {
    const state = UserToken.defaultfilters.model.estado[index];
    model.filtroEstados.push(findStateCode(state));
  }*/
};

const fetchTxt: FetchDataFunction = async (original) => {
  const { url, method, params, setLoading } = original;
  const options = JSON.parse(JSON.stringify(original));//cria copia para não modificar instancia original
  if(options.params?.nodefault){
    //if this option is set, dont add default values
  }else
  if(options.params?.model){
    if(options.params?.model.capitalSocial){
      addDefaultParams(options.params?.model);
    }else{
      addDefaultParams(options.params?.model.model);
    }
  }else if(options.params?.filter?.model){
    addDefaultParams(options.params?.filter?.model);
  }
  try {
    setLoading && setLoading(true);
    const response = await fetch(url, {
      method: method,
      body: JSON.stringify(options.params),
      headers: {
        "Content-Type": "application/json",
        Authorization: UserToken.token,
      },
    });

    if (response.status === 401) {
      document.dispatchEvent(new CustomEvent("logout"));
      return;
    }
    
    const data = await response.text();
    return data;
  } catch (error) {
    console.log(error);
  } finally {
    setLoading && setLoading(false);
  }
};

export const getBestAreasAnalyze = async (
  params: Params,
  setLoading: (isLoading: boolean) => void
) => {
  const options = {
    url: httpConfig.memoryUrl + "/bestareas/analyze",
    method: httpMethod.post,
    params,
    setLoading,
  };
  return fetchData(options);
};

export const getSurroundings = async (
  params: Params,
  setLoading: (isLoading: boolean) => void
) => {
  const options = {
    url: httpConfig.memoryUrl + "/entorno/analyze",
    method: httpMethod.post,
    params,
    setLoading,
  };
  return fetchData(options);
};

export const getRadiusExclusivity = async (
  params: Params,
  setLoading: (isLoading: boolean) => void
) => {
  const options = {
    url: httpConfig.memoryUrl + "/coveragearea/analyze",
    method: httpMethod.post,
    params,
    setLoading,
  };
  return fetchData(options);
};

export const getDataFromCep =async (cep:string) => {
  const options = {
    url: "https://brasilapi.com.br/api/cep/v1/"+cep,
    method: httpMethod.post,
    undefined,
  };
  return fetchData(options);
}

export const getDataFilter = async (params: Params) => {
  const options = {
    url: httpConfig.memoryUrl + "/polygon/data-filter",
    method: httpMethod.post,
    params,
  };
  return fetchData(options);
};

export const getVariables = async () => {
  const options = {
    url: httpConfig.memoryUrl + "/polygon/groupedvariables",
    method: httpMethod.get,
  };
  return fetchData(options);
};

export const getAllDataStudiesPin = async (
  params: Params
) => {
  const data = [];
  let hasToken;
  let response = await getDataStudiesPin(params);
  let studiesData = response.studiesData;
  data.push(studiesData);

  do {
    hasToken = false;
    const params2: Params = {
      // eslint-disable-next-line no-loop-func
      studyTokens: studiesData.map(({ index, token }: any) => {
        if (token !== null) hasToken = true;
        return {
          index,
          token,
        };
      }),
      keyword: params.keyword,
    };

    if (hasToken) {
      await new Promise<void>((res) => {
        setTimeout(() => res(), 1400);
      });
      response = await getDataStudiesPin(params2);
      studiesData = response.studiesData;
      data.push(studiesData);
    }
  } while (hasToken);

  return data;
};

export const getDataStudiesPin = async (
  params: Params
) => {
  addDefaultParamsStudy(params);
  const options = {
    url: httpConfig.memoryUrl + "/polygon/study/studies-pin-search",
    method: httpMethod.post,
    params,
  };
  let responseData = [];
  try {
    const response = await fetchData(options);
    responseData = response;
    return response;
  } catch (error) {
    throw error;
  }
};

export const google_autocomplete = async (params: Params = {}) => {
  const options = {
    url: httpConfig.memoryUrl + "/polygon/google/autocomplete",
    method: httpMethod.post,
    params,
  };
  return fetchData(options);
};

export const find_lat_lon_by_address = async (params: Params) => {
  const options = {
    url: httpConfig.memoryUrl + "/polygon/lat-lon-by-address",
    method: httpMethod.post,
    params,
  };
  return fetchAddress(options);
};

export const getCompanyByCnpj = async (params: Params) => {
  const options = {
    url: httpConfig.cnpjUrl + "/cnpj",
    method: httpMethod.post,
    params,
  };
  return fetchData(options);
};

export const exportCSVFile = async (params: Params) => {
  const options = {
    url: httpConfig.cnpjUrl + "/export/createFile",
    method: httpMethod.post,
    params,
  };
  return fetchTxt(options);
};

export const listCSVFile = async (params: Params) => { //params: {id:$userId}
  const options = {
    url: httpConfig.cnpjUrl + "/export/getFilesById",
    method: httpMethod.post,
    params,
  };
  return fetchData(options);
};
/*
{
  "id": 156,
  "userId": 300,
  "companyId": 1,
  "study": "{\"model\":{\"model\":{\"cnae\":[\"4711301\"],\"inicioDeAtividade\":[],\"capitalSocial\":[],\"porte\":[],\"estado\":[\"SC\"],\"cidade\":[]},\"studyType\":null,\"points\":null},\"amount\":268,\"name\":\"Teste21\"}",
  "name": "Teste21",
  "createdDate": 1703154878713,
  "created": true,
  "spentCredits": 268
}
*/

export const getExportCredits = async (params: Params) => {
  const options = {
    url: httpConfig.cnpjUrl + "/export/getCredits",
    method: httpMethod.post,
    params,
  };
  return fetchData(options);
};


export const getCompanyList = async (params: Params) => {
  const options = {
    url: httpConfig.cnpjUrl + "/cnpjQuadTree/getCnpjList",
    method: httpMethod.post,
    params,
  };
  return fetchData(options);
};

export const getPagesBySize = async (params: Params) => {
  const options = {
    url: httpConfig.cnpjUrl + "/cnpjQuadTree/getPagBySize",
    method: httpMethod.post,
    params,
  };
  return fetchData(options);
};

export const getStates = async () => {
  const options = {
    url: httpConfig.backendUrl + "/state",
    method: httpMethod.get,
  };
  return fetchData(options);
};

export const getSegments = async () => {
  const options = {
    url: httpConfig.backendUrl + "/segment",
    method: httpMethod.get,
  };
  return fetchData(options) as Promise<OptionsSelectResponseProps[]>;
};

export const getUF = async () => {
  const options = {
    url: httpConfig.backendUrl + "/uf",
    method: httpMethod.get,
  };
  return fetchData(options) as Promise<StatesUfProps[]>;
};

export const getPreference = async () => {
  const options = {
    url: httpConfig.backendUrl + "/preference",
    method: httpMethod.get,
  };
  return fetchData(options) as Promise<OptionsSelectResponseProps[]>;
};

export const getUsersByCompany = async (id: number) => {
  const options = {
    url: httpConfig.backendUrl + `/company/${id}/users`,
    method: httpMethod.get,
  };
  return fetchData(options) as Promise<UserByCompany[]>;
};

export const getCompanyFilters = async (id: number) => {
  const options = {
    url: httpConfig.backendUrl + `/limiter/${id}`,
    method: httpMethod.get,
  };
  return fetchData(options) as Promise<LimiterResponse>;
};

export const getCompanyInformations = async (id: number) => {
  const options = {
    url: httpConfig.backendUrl + `/contract/${id}`,
    method: httpMethod.get,
  };
  return fetchData(options) as Promise<ContractResponse>;
};

export const userContract = async () => {
  const options = {
    url: httpConfig.backendUrl + "/contract/user-contract",
    method: httpMethod.get,
  };
  return fetchData(options) as Promise<ContractResponse>;
};

export const getPreferenceByCompany = async (id: number) => {
  const options = {
    url: httpConfig.backendUrl + `/preference-company/${id}`,
    method: httpMethod.get,
  };
  return fetchData(options);
};

export const updateLimiter = async (id: number, params: LimiterUpdate) => {
  const options = {
    url: httpConfig.backendUrl + `/limiter/${id}`,
    method: httpMethod.put,
    params,
  };
  return fetchData(options);
};

export const updateContract = async (id: number, params: Params) => {
  const options = {
    url: httpConfig.backendUrl + `/contract/${id}`,
    method: httpMethod.put,
    params,
  };
  return fetchData(options);
};

export const updateCompany = async (id: number, params: Params) => {
  const options = {
    url: httpConfig.backendUrl + `/company/${id}`,
    method: httpMethod.put,
    params,
  };
  return fetchData(options);
};

export const createCompany = async (params: NewInformationsCompany) => {
  const options = {
    url: httpConfig.backendUrl + "/company",
    method: httpMethod.post,
    params,
  };
  return fetchData(options);
};

export const createAdminCompanyUser = async (params: Params) => {
  const options = {
    url: httpConfig.backendUrl + "/admin-company-user",
    method: httpMethod.post,
    params,
  };
  return fetchData(options);
};

export const createAdminUser = async (params: Params, setErrorMessage:(title: string, msg: string) => void) => {
  const options = {
    url: httpConfig.backendUrl + "/admin-user",
    method: httpMethod.post,
    params,
    setErrorMessage
  };
  return fetchData(options);
};

export const createUser = async (params: Params, setErrorMessage:(title: string, msg: string) => void) => {
  const options = {
    url: httpConfig.backendUrl + "/active",
    method: httpMethod.post,
    params,
    setErrorMessage
  };
  return fetchData(options);
};

export const updateUser = async (
  companyId: number,
  userId: number,
  params: Params
) => {
  const options = {
    url: httpConfig.backendUrl + `/company/${companyId}/user/${userId}`,
    method: httpMethod.put,
    params,
  };
  return fetchData(options);
};

export const getEmpresaByCnpj = async (params: Params) => {
  const options = {
    url: httpConfig.cnpjUrl + "/cnpj",
    method: httpMethod.post,
    params,
  };
  return fetchData(options);
};

export const getSimpleStudy = async (params: Params) => {
  addDefaultParamsStudy(params);
  const options = {
    url: "https://app.deepen.com.br/inmemoryssl/polygon/study/study_simple",
    method: httpMethod.post,
    params,
  };

  return fetchData(options);
};

export const getCompleteStudy = async (
  params: Params,
  setLoading: (isLoading: boolean) => void
) => {
  addDefaultParamsStudy(params);
  const options = {
    url: "https://app.deepen.com.br/inmemoryssl/polygon/study",
    method: httpMethod.post,
    params,
    setLoading,
  };

  return fetchData(options);
};

export const getValidLimitStudies = async (params: Params) => {
  const options = {
    url: httpConfig.backendUrl + "/contract/validate-limit-studies",
    method: httpMethod.post,
    params,
  };
  return fetchData(options) as Promise<limitResponseProps>;
};

export const setLimitReports = async () => {
  const options = {
    url: httpConfig.backendUrl + "/contract/use-count-reports",
    method: httpMethod.post,
  };
  return fetchData(options) as Promise<limitResponseProps>;
};

export const getEmpresabyCnaes = async (params: Params) => {
  const cnaenovos = params.model.model.cnae.map((element: string) =>
    element.indexOf(";") > -1
      ? element.substring(0, element.indexOf(";"))
      : element
  );
  params.model.model.cnae = cnaenovos;
  const options = {
    url: httpConfig.cnpjUrl + "/cnpjQuadTree/getCnpjList",
    method: httpMethod.post,
    params,
  };
  return fetchData(options);
};

export const getEstudosSalvos = async () => {
  const options = {
    url: httpConfig.backendUrl + "/save-study",
    method: httpMethod.get,
  };
  return fetchData(options);
};

export const salvaEstudos = async (params: Params) => {
  const options = {
    url: httpConfig.backendUrl + "/save-study",
    method: httpMethod.post,
    params,
  };
  return fetchData(options);
};

export const salvaLog = async (params: Params) => {
  const options = {
    url: httpConfig.backendUrl + "/log",
    method: httpMethod.post,
    params,
  };
  return fetchData(options);
};

export const atualizaEstudo = async (id: number, params: Params) => {
  const url = httpConfig.backendUrl + "/save-study/" + id;
  const options = {
    url,
    method: httpMethod.put,
    params,
  };
  return fetchData(options);
};

export const getDataCompaniesContract = async () => {
  const options = {
    url: httpConfig.backendUrl + "/contract",
    method: httpMethod.get,
  };
  return fetchData(options);
};

export const getDadosTotalizados = async (params: Params) => {
  const cnaenovos = params.model.cnae.map((element: string) =>
    element.indexOf(";") > -1
      ? element.substring(0, element.indexOf(";"))
      : element
  );
  params.model.cnae = cnaenovos;
  const options = {
    url: httpConfig.cnpjUrl + "/cnpjQuadTree/getStudyTotal",
    method: httpMethod.post,
    params,
  };
  return fetchData(options);
};
