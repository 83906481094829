import L, { LatLngTuple } from "leaflet";
import GA4 from "react-ga4";
import { UserToken } from "../../../../../services/userToken";
import React from "react";
import { PolygonParamList } from "../../../../../Types/interfacs";
import { RadiusExclusivityParams } from "../../../../../Types/Params";
import { getDistanceBetweenPoints, getPointAtDistanceLimit } from "../getDistanceBetweenPoints";
import { RadiusExclusivityProps } from "../types";

import { EditRectangle } from "../Edition/EditRectangle";
import { salvaLog } from "../../../../../services/getOptionsData";
import { deselectText } from "../../../Utils";

interface CreateRadiusExclusivityProps {
  map: L.Map;
  layerShapes: L.LayerGroup;
  color: string;
  setPolygon?: React.Dispatch<React.SetStateAction<PolygonParamList>>;
  setParamsToRadiusExclusivity: React.Dispatch<
    React.SetStateAction<RadiusExclusivityParams>
  >;
}

export const CreateRadiusExclusivity = ({
  map,
  layerShapes,
  color,
  setParamsToRadiusExclusivity,
}: CreateRadiusExclusivityProps) => {
  let firstLatLng: [number, number];
  let secondLatLng: [number, number];
  let distanceBetweenPoints: number;
  let radiusExclusivity: RadiusExclusivityProps;
  let toolTip: L.Tooltip;
  let editMarker: L.Marker;
  let deleteMarker: L.Marker;

  map.off("mousedown");
  map.off("mousemove");
  map.off("mouseup");

  map.on("mousedown", handleClickDown);
  map.on("mouseup", handleClickUp);
  map.getContainer().style.cursor = "crosshair";

  function handleClickDown(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      map.dragging.disable();
      firstLatLng = [e.latlng.lat, e.latlng.lng];
      secondLatLng = [e.latlng.lat, e.latlng.lng];
      map.on("mousemove", handleMouseMove);
      radiusExclusivity = drawRectangle(
        firstLatLng,
        secondLatLng,
        color
      ) as RadiusExclusivityProps;
      map.on("mouseout", (e: L.LeafletMouseEvent) => {
        document.addEventListener("mouseup", handleClickUp);

        const zoom = map.getZoom();
        if (radiusExclusivity) {
          const center = radiusExclusivity.getBounds().getCenter();

          const middlePoint = [
            (center.lat + secondLatLng[0]) / 2,
            (center.lng + secondLatLng[1]) / 2,
          ] as LatLngTuple;

          map.setView(middlePoint, zoom);
        }
      });
      radiusExclusivity.addTo(layerShapes);
    } else if (e.originalEvent.button === 1) {
      map.getContainer().style.cursor = "grabbing";
    }
  }

  function handleMouseMove(e: L.LeafletMouseEvent) {
    secondLatLng = [e.latlng.lat, e.latlng.lng];
    secondLatLng = getPointAtDistanceLimit(firstLatLng, secondLatLng, 250000);
    console.log(secondLatLng);

    if (radiusExclusivity) {
      radiusExclusivity.setBounds([firstLatLng, secondLatLng]);

      radiusExclusivity._path.style.cursor = "crosshair";
    }

    if (toolTip) {
      toolTip.remove();
    }

    distanceBetweenPoints = getDistanceBetweenPoints(firstLatLng, secondLatLng);

    const distanceKm = distanceBetweenPoints / 1000;
    const distanceKmRounded = Math.round(distanceKm * 100) / 100;

    toolTip = L.tooltip()
      .setLatLng(secondLatLng)
      .setContent(
        `<div
          style="
          background-color: white;
            padding: 5px;
            font-size: 14px;
            font-weight: bold;
          "
          >${distanceKmRounded < 250 ? distanceKmRounded : "Max 250"} km</div>`
      )
      .addTo(map);
  }

  function handleClickUp() {
    map.off("mousemove");
    map.off("mouseout");
    document.removeEventListener("mouseup", handleClickUp);

    if (radiusExclusivity && distanceBetweenPoints > 0) {
      map.off("mousedown");
      map.off("mouseup");

      salvaLog({
        action: "Finalizou radius exclusivity",
        params: "",
      }).then((result: any) => {
      });

      const idShape = radiusExclusivity._leaflet_id;

      setParamsToRadiusExclusivity((oldParams: any) => {
        return {
          ...oldParams,
          rects: [
            ...oldParams.rects,
            {
              index: idShape,
              lat1: firstLatLng[0],
              lat2: secondLatLng[0],
              lon1: firstLatLng[1],
              lon2: secondLatLng[1],
            },
          ],
        };
      });

      if (toolTip) {
        toolTip.remove();
      }
      const northEast = radiusExclusivity.getBounds().getNorthEast();

      const editIcon = L.icon({
        iconUrl: "edit-variable.png",
        iconSize: [35, 35],
        iconAnchor: [80, 45],
      });

      const editActiveIcon = L.icon({
        iconUrl: "edit-variable-active.png",
        iconSize: [35, 35],
        iconAnchor: [80, 45],
      });

      editMarker = L.marker(northEast, {
        icon: editIcon,
      });

      const deleteIcon = L.icon({
        iconUrl: "delete.png",
        iconSize: [35, 35],
        iconAnchor: [35, 45],
      });

      deleteMarker = L.marker(northEast, {
        icon: deleteIcon,
      });

      editMarker.setOpacity(0.7);
      deleteMarker.setOpacity(0.7);

      editMarker.addTo(layerShapes);

      deleteMarker.addTo(layerShapes);

      deleteMarker.on("mouseover", () => {
        deleteMarker.setOpacity(1);
      });

      deleteMarker.on("mouseout", () => {
        deleteMarker.setOpacity(0.7);
      });

      editMarker.on("mouseover", () => {
        editMarker.setOpacity(1);
      });

      editMarker.on("mouseout", () => {
        editMarker.setOpacity(0.7);
      });

      editMarker.on("click", () => {
        if (radiusExclusivity) {
          if (editMarker.options.icon === editIcon) {
            editMarker.setIcon(editActiveIcon);
            map.off("mousedown");
            map.off("mousemove");
            map.off("mouseup");
            EditRectangle({
              map,
              layerShapes,
              rectangle: radiusExclusivity,
              editMarker,
              deleteMarker,
            });
          } else {
            editMarker.setIcon(editIcon);
            map.off("mousemove");
            map.off("mouseup");

            const radiusExclusivityEdited = radiusExclusivity.getBounds();

            setParamsToRadiusExclusivity((oldParams: any) => ({
              ...oldParams,
              rects: [
                ...oldParams.rects.filter(
                  (rect: any) => rect.index !== idShape
                ),
                {
                  index: idShape,
                  lat1: radiusExclusivityEdited.getNorthEast().lat,
                  lat2: radiusExclusivityEdited.getSouthWest().lat,
                  lon1: radiusExclusivityEdited.getSouthWest().lng,
                  lon2: radiusExclusivityEdited.getNorthEast().lng,
                },
              ],
            }));

            CreateRadiusExclusivity({
              map,
              layerShapes,
              color,
              setParamsToRadiusExclusivity,
            });
          }
        }
      });

      deleteMarker.on("click", () => {
        if (radiusExclusivity) {
          layerShapes.removeLayer(radiusExclusivity);
          layerShapes.removeLayer(editMarker);
          layerShapes.removeLayer(deleteMarker);

          setParamsToRadiusExclusivity((oldParams: any) => ({
            ...oldParams,
            rects: oldParams.rects.filter(
              (rect: any) => rect.index !== idShape
            ),
          }));

          CreateRadiusExclusivity({
            map,
            layerShapes,
            color,
            setParamsToRadiusExclusivity,
          });
        }
        map.dragging.enable();
      });

      distanceBetweenPoints = 0;
      CreateRadiusExclusivity({
        map,
        layerShapes,
        color,
        setParamsToRadiusExclusivity,
      });

      map.dragging.enable();

      deselectText();
    } else {
      map.on("mousedown", handleClickDown);

      radiusExclusivity && layerShapes.removeLayer(radiusExclusivity);

      map.getContainer().style.cursor = "crosshair";
    }
  }

  function drawRectangle(
    firstLatLng: [number, number],
    secondLatLng: [number, number],
    color: string
  ) {
    const rectangle = L.rectangle([firstLatLng, secondLatLng], {
      color: color,
      dashArray: "15, 15",
      weight: 4,
      fillOpacity: 0.1,
    });

    return rectangle;
  }

  return null;
};
