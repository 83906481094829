import {
  GraphicsList,
  TableData,
} from "../../Preview/components/graphs/graphTSInterface";
import { format } from "../../../Utils";
import { CorrectIcon } from "./CorrectIcon";

interface LVGBCProps {
  k: number;
  parametrosevalores: TableData;
  grafico: GraphicsList;
}

export function ListaValoresGraficoBarComplexo({
  k,
  parametrosevalores,
  grafico,
}: LVGBCProps) {
  return (
    <div
      key={k}
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        width: "100%",
        paddingTop: "10px",
        paddingBottom: "10px",
        margin: "20px",
        marginLeft: "20px",
        marginRight: "20px",
        border: "1px",
        color: "darkgray",
        borderColor: "lightgray",
        borderStyle: "solid",
        borderRadius: "20px",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: parametrosevalores.color,
          verticalAlign: "center",
          textAlign: "center",
          textAlignLast: "center",
          width: "90%",
        }}
      >
        <CorrectIcon
          form={parametrosevalores.form}
          color={parametrosevalores.color}
        />
        {" " + parametrosevalores.areaLabel}
        {parametrosevalores.totalValue.map((totval, j) => {
          return (
            <div
              key={j}
              style={{
                display: "flex",
                color: "darkgray",
                justifyContent: "space-between",
                width: "90%",
                border: "0px",
                paddingTop: "5px",
                borderTop: "1px",
                borderStyle: "solid",
              }}
            >
              <span style={{ color: parametrosevalores.color }}>
                {grafico.data[0].variable.labels[j].nameDesc + ": "}
              </span>
              <span style={{ color: "darkgray" }}>
                {format(totval, grafico.measureUnit)}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
