import React, { CSSProperties } from "react";
import { colors } from "../../assets/colors";

interface IconProps {
  icon: React.ReactElement;
  color?: string;
  size?: number;
}
export function Icon({ icon, color = "primary", size }: IconProps) {
  let colorIcon;
  if (color === "primary" || color === "secondary" || color === "disabled") {
    colorIcon = colors.default[color].content;
  }
  return React.cloneElement(icon, {
    fill: colorIcon ? colorIcon : color,
    size: size ? size : undefined,
  });
}
