import React, { useState, useEffect } from "react";
import { FaLayerGroup } from "react-icons/fa";
import { TbTriangleInvertedFilled } from "react-icons/tb";
import { Divider, Select } from "antd";
import "./Legend.css";
import { labelsProps } from "../../ReactMap";
import { VariableGroupProps } from "../../../../Types/Variable";
import { colors } from "../../../../assets/colors";
import { Tab } from "../../../../components/Tab/Tab";
import { Title } from "../../../../components/typography/Title";
import { ButtonLink } from "../../../../components/Button/ButtonLink";
import { Checkbox } from "../../../../components/Checkbox/Checkbox";
import { ButtonDefault } from "../../../../components/Button/ButtonDefault";
import { ModalDefault } from "../../../../components/Modal/ModalDefault";
import { TitleModal } from "../../../../components/typography/TitleModal";
import { SubTitle } from "../../../../components/typography/SubTitle";
import { Option } from "./components/Option";
import { SelectDefault } from "../../../../components/Select/SelectDefault";

interface LegendProps {
  actualLabels: labelsProps[];
  setActiveLabels: React.Dispatch<React.SetStateAction<string[]>>;
  title: string;
  setActualLabels: React.Dispatch<React.SetStateAction<labelsProps[]>>;
  setVariable: React.Dispatch<React.SetStateAction<string>>;
  tabOpen: boolean;
  setTabOpen: React.Dispatch<React.SetStateAction<boolean>>;
  variables?: VariableGroupProps;
  setActualColors: React.Dispatch<
    React.SetStateAction<{ hue: number; sat: string; lum: string }[]>
  >;
}

const colorLegend = colors.actualHEXColors;
const actualHSLColors = colors.actualHSLColors;
const borderColor = colors.textLight;
const contentColor = colors.default.primary.content;
let timeoutClickLabel: any = null;

export function Legend({
  actualLabels,
  setActiveLabels,
  title,
  setVariable,
  setActualLabels,
  tabOpen,
  setTabOpen,
  variables,
  setActualColors,
}: LegendProps) {
  const [labelStatus, setLabelStatus] = useState();
  const [modalVariables, setModalVariables] = useState(false);
  const [filteredVariable, setFilteredVariable] = useState(
    "Mostrar todas as variáveis"
  );

  useEffect(() => {
    setLabelStatus(
      Object.assign(
        {},
        ...actualLabels.map((label) => ({ [label.value]: true }))
      )
    );

    setActualColors(actualHSLColors.slice(0, actualLabels.length));
  }, [actualLabels]);

  useEffect(() => {
    if (timeoutClickLabel) {
      clearTimeout(timeoutClickLabel);
    }

    timeoutClickLabel = setTimeout(activeLabels, 2000);
  }, [labelStatus]);

  const activeLabels = () => {
    const indexColors: number[] = [];
    if (labelStatus) {
      const selectedLabels = Object.keys(labelStatus).filter((label, index) => {
        if (labelStatus[label]) {
          indexColors.push(index);
        }
        return labelStatus[label];
      });

      const actualColors = indexColors.map((index) => {
        return actualHSLColors[index];
      });

      setActualColors(actualColors);
      setActiveLabels(selectedLabels);
    }
  };

  const toggleLabel = (label: any) => {
    setLabelStatus((prevStatus: any) => ({
      ...prevStatus,
      [label]: !prevStatus[label],
    }));
  };

  const SelectVariable = () => {
    let allVariables = Object.keys(variables!).map(
      (variableGroup) => variableGroup
    );
    allVariables = ["Mostrar todas as variáveis", ...allVariables];

    const options = allVariables.map((variable) => {
      return {
        label: variable,
        value: variable,
      };
    });

    return (
      <SelectDefault
        defaultValue={filteredVariable}
        onChange={(value) => {
          setFilteredVariable(value);
        }}
        options={options}
      />
    );
  };

  return (
    <>
      {tabOpen ? (
        <Tab
          icon={<FaLayerGroup />}
          name="Variáveis globais"
          onClickClose={() => setTabOpen(!tabOpen)}
        >
          <Title>
            Variável:{" "}
            <ButtonLink onClick={() => setModalVariables(!modalVariables)}>
              {title}
            </ButtonLink>
          </Title>
          {actualLabels.length > 0 && <Divider />}
          {actualLabels.map((label, index) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
              key={index}
            >
              <Checkbox
                key={index}
                id={label.name}
                label={label.name}
                name={label.name}
                color={colorLegend[index]}
                checked={labelStatus![label.value]}
                onChange={() => toggleLabel(label.value)}
              />
            </div>
          ))}
        </Tab>
      ) : (
        <ButtonDefault
          icon={<FaLayerGroup />}
          color="primary"
          style={{
            minWidth: 56,
          }}
          onClick={() => {
            setTabOpen(!tabOpen);
          }}
        />
      )}
      {modalVariables && (
        <ModalDefault
          icon={<FaLayerGroup />}
          setModalOpen={setModalVariables}
          title="Selecionar Variável global"
          styleContent={{
            maxWidth: 500,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TitleModal>VARIÁVEL GLOBAL</TitleModal>
            <SubTitle
              style={{
                textAlign: "center",
                marginBlock: 14,
              }}
            >
              Selecione abaixo uma variável global que deseja aplicar no mapa
              como um todo para seus facilitar suas análises.
            </SubTitle>
            <SelectVariable />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: `1px solid ${borderColor}`,
                width: "100%",
                marginTop: 16,
                height: 353,
                borderRadius: 8,
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  borderBottom: `2px solid ${borderColor}`,
                  alignItems: "center",
                  padding: 24,
                  height: 70,
                }}
              >
                <Title color={contentColor} style={{ fontWeight: "bold" }}>
                  Nome
                </Title>
                <Title color={contentColor} style={{ fontWeight: "bold" }}>
                  Ações
                </Title>
              </div>
              <div
                style={{
                  width: "100%",
                  overflowX: "scroll",
                }}
              >
                {variables &&
                filteredVariable !== "Mostrar todas as variáveis" ? (
                  <>
                    {variables[filteredVariable].map((subVariable, index) => {
                      return (
                        <Option
                          key={index}
                          info={subVariable.labels}
                          onClick={() => {
                            setActiveLabels([]);
                            setActualLabels([]);
                            setVariable(subVariable.name);
                            setModalVariables(!modalVariables);
                          }}
                        >
                          {subVariable.name}
                        </Option>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {Object.keys(variables!).map((variable) => {
                      return variables![variable].map((subVariable, index) => {
                        return (
                          <Option
                            key={index}
                            info={subVariable.labels}
                            onClick={() => {
                              setActiveLabels([]);
                              setActualLabels([]);
                              setVariable(subVariable.name);
                              setModalVariables(!modalVariables);
                            }}
                          >
                            {subVariable.name}
                          </Option>
                        );
                      });
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </ModalDefault>
      )}
    </>
  );
}
