import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Legend
);
function formatNumber(number) {
  if (number >= 1000000000) {
    const formattedNumber = (number / 1000000000).toFixed(1);
    return formattedNumber.replace(".0", "") + "B";
  } else if (number >= 1000000) {
    const formattedNumber = (number / 1000000).toFixed(1);
    return formattedNumber.replace(".0", "") + "M";
  } else if (number >= 1000) {
    const formattedNumber = (number / 1000).toFixed(1);
    return formattedNumber.replace(".0", "") + "K";
  } else {
    return number.toString();
  }
}
export function GraficoHorizontalPessoa(props) {
  const { titulo, areas } = props;
  const optionsPessoas = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: titulo, //'Total de pessoas',
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            let title = context[0].dataset.label[context[0].dataIndex] || "";
            return title;
          },
          label: function (context) {
            let label = "";
            if (context.dataset.label[0].indexOf("R$") !== -1) {
              label += "R$ ";
            }
            if (context.parsed.y !== null) {
              label += parseFloat(context.parsed.x).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              });
            }

            return label;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function (value, index, ticks) {
            return formatNumber(value);
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  var labels = [""];

  var dadosPessoas = [];
  for (let index = 0; index < areas.length; index++) {
    const element = areas[index];
    if (element.datatooltip.length > 1 && labels.length === 1) {
      labels = [];
      for (let i = 0; i < element.datatooltip.length; i++) {
        labels.push(element.datatooltip[i].substring(0, 2));
      }
    }

    dadosPessoas.push({
      label: element.datatooltip,
      data: element.value,
      borderColor: "rgb(" + element.rgb + ")",
      backgroundColor: "rgba(" + element.rgb + ", 0.5)",
    });
  }

  const data = {
    labels,
    datasets: dadosPessoas,
  };
  return (
    <div
      style={{
        height: `calc(120px + ${
          areas.length * areas[0].datatooltip.length * 15
        }px)`,
      }}
    >
      <Bar options={optionsPessoas} data={data} />
    </div>
  );
}
