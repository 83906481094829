import React, {
  ChangeEventHandler,
  CSSProperties,
  useEffect,
  useRef,
  useState,
} from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import { colors } from "../../assets/colors";
import { VariableSelectOption } from "../../Types/Variable";
import { SubOptionItem } from "./components/SubOptionItem";
import { OptionGroup } from "./components/OptionGroup";

interface SelectProps {
  CaretIcon?: boolean;
  minTextLength?: number;
  defaultValue?: string;
  placeholder?: string;
  clearOnSelect?: boolean;
  options?: VariableSelectOption[];
  onChange: (
    value: string,
    option: { label: string; variable: string } | null
  ) => void | undefined;

  searchFilter: (
    text: string,
    list?: VariableSelectOption[]
  ) => VariableSelectOption[] | undefined;
  style?: CSSProperties;
}

type StylesObject = Record<string, CSSProperties>;

const backgroundColor = colors.default.primary.background;
const grayColor = colors.default.disabled.content;
const shadowColor = colors.shadow;
interface FocusInputProps {
  isOpen: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  selectedOption: VariableSelectOption | null;
}

const FocusInput: React.FC<FocusInputProps> = ({
  isOpen,
  onChange,
  selectedOption,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div>
      {isOpen && (
        <input
          className="search-input"
          style={{
            border: 0,
            fontFamily: "'D-DIN Regular', sans-serif",
            fontSize: 16,
            padding: 8,
          }}
          ref={inputRef}
          type="text"
          onChange={onChange}
          placeholder={
            selectedOption ? selectedOption?.label : "Digite algo..."
          }
        />
      )}
    </div>
  );
};

export function AutoComplete({
  CaretIcon = true,
  options,
  defaultValue,
  placeholder,
  onChange,
  searchFilter,
  clearOnSelect,
  minTextLength = 0,
  style,
}: SelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchList, setSearchList] = useState<any[] | undefined>(options);
  const [selectedOption, setSelectedOption] =
    useState<VariableSelectOption | null>(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setSearchText("");
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setSearchList(options);
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (
    option: VariableSelectOption,
    index: number,
    variable?: string
  ) => {
    if (clearOnSelect) {
      setSelectedOption(null);
    } else {
      setSelectedOption(option);
    }

    setSearchText("");
    setIsOpen(false);
    onChange(
      option.value ? option.value : option.options![index].value,
      variable ? { label: option.label, variable } : null
    );
  };

  return (
    <div style={{ ...selectStyles.container, ...style }} ref={selectRef}>
      <div style={selectStyles.header} onClick={toggleDropdown}>
        <div
          style={{
            ...selectStyles.selectedOption,
            color: selectedOption || defaultValue ? "#222" : undefined,
          }}
        >
          <FocusInput
            selectedOption={selectedOption}
            isOpen={isOpen}
            onChange={(event: any) => {
              const newList = searchFilter(event.target.value, options);
              setSearchText(event.target.value);

              setSearchList(newList);
            }}
          />

          {isOpen
            ? null
            : selectedOption
            ? selectedOption.label
            : defaultValue
            ? defaultValue
            : placeholder}
        </div>

        {CaretIcon && (
          <CaretDownOutlined
            style={{
              ...selectStyles.caretIcon,
              transform: isOpen ? "rotate(180deg)" : "rotate(0deg)", // Adicionar a rotação do ícone aqui
            }}
            className={isOpen ? "open" : ""}
          />
        )}
      </div>
      {isOpen && searchText.length >= minTextLength && searchList && (
        <ul style={selectStyles.optionList}>
          {searchList.map((option, index) => {
            if (option.options) {
              return (
                <OptionGroup
                  key={index}
                  option={option}
                  handleOptionClick={handleOptionClick}
                />
              );
            } else {
              return (
                <React.Fragment key={index}>
                  <SubOptionItem
                    option={option}
                    index={index}
                    handleOptionClick={handleOptionClick}
                  />
                  {index !== searchList.length - 1 && <hr />}
                </React.Fragment>
              );
            }
          })}
        </ul>
      )}
    </div>
  );
}

const selectStyles: StylesObject = {
  container: {
    position: "relative",
    width: "100%",
    height: 48,
    color: grayColor,
  },
  header: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    padding: 8,
    paddingLeft: 12,
    border: `1px solid ${grayColor}`,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
  },
  selectedOption: {
    marginRight: 8,
  },
  caretIcon: {
    marginLeft: "auto",
  },
  optionList: {
    listStyle: "none",
    position: "absolute",
    zIndex: 9999,
    top: "105%",
    width: "100%",
    border: `1px solid ${grayColor}`,
    borderRadius: 5,
    background: backgroundColor,
    boxShadow: `0px 2px 8px ${shadowColor}`,
    height: 300,
    overflow: "scroll",
  },
};
