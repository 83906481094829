import { Tab } from "../../../../../components/Tab/Tab";
import { Title } from "../../../../../components/typography/Title";
import { SubTitle } from "../../../../../components/typography/SubTitle";
import { MarkIcon } from "../../../../../components/icon/MarkIcon";
import { useEffect, useState } from "react";
import { ButtonDefault } from "../../../../../components/Button/ButtonDefault";
import {
  getAllDataStudiesPin,
  salvaLog,
} from "../../../../../services/getOptionsData";
import { InterestOptions } from "./components/InterestOptions";
import {
  AreaPinsGroup,
  Pins,
  PolygonParamList,
  PolygonProps,
} from "../../../../../Types/interfacs";
import { Tooltip } from "antd";
import { UserToken } from "../../../../../services/userToken";
import GA4 from "react-ga4";
import { Loading } from "../../../../../components/Loading/Loading";
import {
  insideCircle,
  insidePolygon,
  insideRectangle,
} from "../../../../../utils/checkpoly";
import { tooltip } from "leaflet";

interface PointOfInterestProps {
  hasContent: boolean;
  polygonParams: PolygonParamList;
  setMarkersGoogle: React.Dispatch<
    React.SetStateAction<{ [key: string]: InterestProps[] }>
  >;
  setPolygon: React.Dispatch<React.SetStateAction<PolygonParamList>>;
}

export interface InterestProps {
  index: number;
  keyword: string;
  token: string | null;
  pins: Pins[];
  visible?: boolean;
}
export interface CircleProp {
  index: number;
  ilat: number;
  ilon: number;
  radius?: number;
}

export interface PolyProp {
  index: number;
  latlngs: { lat: number; lon: number }[];
}

export interface RectProp {
  index: number;
  lat1: number;
  lon1: number;
  lat2: number;
  lon2: number;
}

interface ParamsProps {
  keyword: string;
  pins: Pins[];
  polygonList: Array<{
    index: number;
    latlngs: Array<{
      lat: number;
      lon: number;
    }>;
  }>;
  radiusList: Array<{
    index: number;
    ilat: number;
    ilon: number;
    radius?: number;
  }>;
  rectangleList: Array<{
    index: number;
    lat1: number;
    lat2: number;
    lon1: number;
    lon2: number;
  }>;
  regionList: []; //TODO ver tipo certo
  tokens: [];
  type: null;
}

let keyword = "";

function InterestPropsToAreaPinsGroup(
  a: InterestProps,
  polygonParams: PolygonParamList
): AreaPinsGroup {
  const rightPoly: PolygonProps = findPolygon(a.index, polygonParams);

  return {
    id: a.index, // id da figura geometrica (circulo/retangulo/poligono) que foi utilizado para pesquisar esse pin
    color: rightPoly.color, // cor da figura geometrica
    type: traduzType(rightPoly.type), //"Raio"|
    values: [
      {
        keyword: a.keyword, // palavra chave utilizada na pesquisa
        values: a.pins,
      },
    ],
  };
}

function traduzType(rawType: string): string {
  if (rawType === "RECTANGLE_STUDY") {
    return "Retângulo";
  }
  if (rawType === "RADIUS_STUDY") {
    return "Raio";
  }
  if (rawType === "POLYGON_STUDY") {
    return "Poligono";
  }
  return "Linha";
}

function findPolygon(
  id: number,
  polygonParams: PolygonParamList
): PolygonProps {
  for (let index = 0; index < polygonParams.radiusList.length; index++) {
    const element = polygonParams.radiusList[index];
    if (element.id === id) {
      return element;
    }
  }
  for (let index = 0; index < polygonParams.rectangleList.length; index++) {
    const element = polygonParams.rectangleList[index];
    if (element.id === id) {
      return element;
    }
  }
  for (let index = 0; index < polygonParams.polygonList.length; index++) {
    const element = polygonParams.polygonList[index];
    if (element.id === id) {
      return element;
    }
  }
  return {
    id: -1,
    points: [],
    ray: 0,
    type: "RECTANGLE_STUDY",
    color: "black",
  }; //em teoria nunca vai cair aqui...
}

export function PointsOfInterest({
  hasContent,
  polygonParams,
  setPolygon,
  setMarkersGoogle,
}: PointOfInterestProps) {
  const [markTabOpen, setMarkTabOpen] = useState(false);
  const [interests, setInterests] = useState<string[]>([]);
  const [points, setPoints] = useState<{ [key: string]: InterestProps[] }>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setMarkersGoogle(points);
  }, [points]);

  function atualizaPins(studiesData: InterestProps[]) {
    setPolygon((oldPolygon) => {
      for (let index = 0; index < studiesData.length; index++) {
        const p = studiesData[index];
        const newCandidate = InterestPropsToAreaPinsGroup(p, polygonParams);
        var found = -1;
        for (let i = 0; i < oldPolygon.pins.length; i++) {
          const addedCandidate = oldPolygon.pins[i];
          if (addedCandidate.id === p.index) {
            found = i;
            for (let jn = 0; jn < newCandidate.values.length; jn++) {
              var foundKeyholder = -1;
              const newkeyword = newCandidate.values[jn];
              for (let j = 0; j < addedCandidate.values.length; j++) {
                const keywordHolder = addedCandidate.values[j];
                if (newkeyword.keyword === keywordHolder.keyword) {
                  foundKeyholder = j;
                  for (let kn = 0; kn < newkeyword.values.length; kn++) {
                    var foundPin = -1;
                    const newPin = newkeyword.values[kn];
                    for (let k = 0; k < keywordHolder.values.length; k++) {
                      const oldPin = keywordHolder.values[k];
                      if (newPin === oldPin) {
                        foundPin = k;
                        break;
                      }
                    }
                    if (foundPin === -1) {
                      oldPolygon.pins[i].values[j].values.push(newPin);
                    }
                  }
                  break;
                }
              }
              if (foundKeyholder === -1) {
                oldPolygon.pins[i].values.push(newkeyword);
              }
            }
            break;
          }
        }
        if (found === -1) {
          oldPolygon.pins.push(newCandidate);
        }
      }
      return {
        ...oldPolygon,
      };
    });
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    keyword = e.target[0].value;
    e.target[0].value = "";
    if (!interests.includes(keyword)) {
      salvaLog({
        action: "Pesquisou Ponto de Interesse",
        params: "" + keyword,
      }).then((result: any) => {});
      setInterests([...interests, keyword]);

      const rectangleList = polygonParams.rectangleList.map(
        ({ id, points }) => {
          return {
            index: id,
            lat1: points[0][0],
            lat2: points[1][0],
            lon1: points[0][1],
            lon2: points[1][1],
          };
        }
      );

      const radiusList = polygonParams.radiusList.map(({ id, points, ray }) => {
        return {
          index: id,
          ilat: points[0][0],
          ilon: points[0][1],
          radius: ray,
        };
      });

      const polygonList = polygonParams.polygonList.map(({ id, points }) => {
        return {
          index: id,
          latlngs: points.map((point) => ({
            lat: point[0],
            lon: point[1],
          })),
        };
      });

      const params: ParamsProps = {
        rectangleList: rectangleList,
        polygonList: polygonList,
        radiusList: radiusList,
        regionList: [],
        tokens: [],
        pins: [],
        type: null,
        keyword: keyword,
      };
      setLoading(true);
      getAllDataStudiesPin(params).then((response: InterestProps[][]) => {
        // Flatten the response array
        const data = response.flat();
        filterFromArea(
          params.polygonList,
          params.radiusList,
          params.rectangleList,
          data
        );
        atualizaPins(data);

        // Combine data and update pins
        const combinedData: { [key: number]: InterestProps } = {};
        data.forEach((item) => {
          if (!combinedData[item.index]) {
            combinedData[item.index] = { ...item, pins: [...item.pins] };
          } else {
            combinedData[item.index].pins.push(...item.pins);
          }
        });

        // Update points object
        const newPoints = {
          ...points,
          [keyword]: Object.values(combinedData).map((interest) => ({
            ...interest,
            token: null,
            visible: true,
          })),
        };
        setPoints(newPoints);
        setLoading(false);
      });
    }
  };

  function filterFromArea(
    polyList: PolyProp[],
    circList: CircleProp[],
    rectList: RectProp[],
    pins: InterestProps[]
  ) {
    for (let index = 0; index < pins.length; index++) {
      const grupodepins = pins[index];
      for (let i = 0; i < polyList.length; i++) {
        const poly = polyList[i];
        if (poly.index === grupodepins.index) {
          const points: number[] = [];
          for (let index = 0; index < poly.latlngs.length; index++) {
            const latlngs = poly.latlngs[index];
            points.push(latlngs.lon);
            points.push(latlngs.lat);
          }

          const filteredPins = [];
          for (let p = 0; p < grupodepins.pins.length; p++) {
            const pin = grupodepins.pins[p];
            if (insidePolygon(pin.x, pin.y, points)) {
              filteredPins.push(pin);
            }
          }
          grupodepins.pins = filteredPins;
        }
      }
      for (let j = 0; j < circList.length; j++) {
        const circ = circList[j];
        if (circ.index === grupodepins.index) {
          const filteredPins = [];
          for (let p = 0; p < grupodepins.pins.length; p++) {
            const pin = grupodepins.pins[p];
            if (insideCircle(circ.ilon, circ.ilat, circ.radius, pin)) {
              filteredPins.push(pin);
            }
          }
          grupodepins.pins = filteredPins;
        }
      }
      for (let k = 0; k < rectList.length; k++) {
        const rect = rectList[k];
        if (rect.index === grupodepins.index) {
          const filteredPins = [];
          for (let p = 0; p < grupodepins.pins.length; p++) {
            const pin = grupodepins.pins[p];
            if (
              insideRectangle(rect.lon1, rect.lat1, rect.lon2, rect.lat2, pin)
            ) {
              filteredPins.push(pin);
            }
          }
          grupodepins.pins = filteredPins;
        }
      }
    }
  }

  return (
    <>
      {!markTabOpen ? (
        <ButtonDefault
          icon={<MarkIcon />}
          color={"primary"}
          onClick={() => {
            setMarkTabOpen(!markTabOpen);
          }}
        />
      ) : (
        <>
          {loading ? (
            <Loading />
          ) : (
            <Tab
              floating={true}
              icon={<MarkIcon />}
              name={"Pontos de Interesse"}
              onClickClose={() => setMarkTabOpen(!markTabOpen)}
            >
              <Title>Buscar Pontos</Title>
              <SubTitle>
                Entre com uma palavra chave para buscar pontos de interesse
                cadastrados no Google e mostre no mapa.
              </SubTitle>

              <form onSubmit={handleSubmit}>
                <Tooltip
                  title={!hasContent ? "Selecione uma área no mapa" : ""}
                >
                  <input
                    style={{
                      width: "100%",
                      height: 35,
                      fontSize: 16,
                      padding: 8,
                      borderRadius: 5,
                      border: "1px solid #D5D5D5",
                      opacity: 0.5,
                      outline: "none",
                      cursor: !hasContent ? "not-allowed" : "pointer",
                    }}
                    disabled={!hasContent}
                    name="interesses"
                    type="text"
                    placeholder="Entre com uma palavra chave"
                  />
                </Tooltip>
              </form>
              {points &&
                Object.keys(points).map((point, index) => {
                  return (
                    <InterestOptions
                      key={index}
                      title={point}
                      setPoints={setPoints}
                      setInterests={setInterests}
                      setPolygon={setPolygon}
                    />
                  );
                })}
            </Tab>
          )}
        </>
      )}
    </>
  );
}
