import { GraphicsList } from "../../Preview/components/graphs/graphTSInterface";
import { CorrectBarsGraphic } from "./CorrectGraphic";

interface SeparaListaEmDuasColunasData {
  lista: GraphicsList[];
  index: number;
}

export function SeparaListaEmDuasColunas({
  lista,
  index,
}: SeparaListaEmDuasColunasData) {
  var found: number = -1;
  const novalista = [];
  let ids = 0;
  for (let i = 0; i < lista.length; i++) {
    const element = lista[i];
    if (element.graphicname !== "Lista de todos os alfinetes") {
      if (
        element.data[0].variable.labels.length === 1 &&
        element.data.length === 1
      ) {
        if (found !== -1) {
          const priorelement = lista[found];
          found = -1;
          novalista.push({ first: priorelement, second: element, id: ids });
          ids += 2;
        } else {
          found = i;
        }
      } else {
        if (found !== -1) {
          const priorelement = lista[found];
          found = -1;
          novalista.push({ first: priorelement, second: null, id: ids });
          ids += 1;
        }
        novalista.push({ first: element, second: null, id: ids });
        ids += 1;
      }
    } else {
      //ignore
    }
  }
  if (found !== -1) {
    const priorelement = lista[found];
    found = -1;
    novalista.push({ first: priorelement, second: null, id: ids });
    ids += 1;
  }

  return (
    <div>
      {novalista.map((grafico, indx) => {
        if (grafico.second === null) {
          return (
            <div style={{ paddingRight: "10px", paddingLeft: "10px" }}>
              <CorrectBarsGraphic
                grafico={grafico.first}
                grafico_index={indx}
                sessao_index={index}
              />
            </div>
          );
        } else {
          return (
            <div style={{ display: "flex" }} id={"sessao" + index + "_" + indx}>
              <div
                style={{
                  width: "50%",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                }}
              >
                <CorrectBarsGraphic
                  grafico={grafico.first}
                  grafico_index={999}
                  sessao_index={999}
                />
              </div>
              <div
                style={{
                  width: "50%",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                }}
              >
                <CorrectBarsGraphic
                  grafico={grafico.second}
                  grafico_index={999}
                  sessao_index={999}
                />
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}
