import { CustomInput } from "../../../../../../components/Input/CustomInput";
import { DateInput } from "../../../../../../components/Input/DateInput";

interface CompanyDetailsProps {
  companyName: string;
  setCompanyName: (value: string) => void;
  maxFranchisees: string;
  setMaxFranchisees: (value: string) => void;
  credits: string;
  setCredits: (value: string) => void;
  maxUsers: string;
  setMaxUsers: (value: string) => void;
  expirationDate: string;
  setExpirationDate: (value: string) => void;
}

export function CompanyDetails({
  companyName,
  setCompanyName,
  maxFranchisees,
  setMaxFranchisees,
  credits,
  setCredits,
  maxUsers,
  setMaxUsers,
  expirationDate,
  setExpirationDate,
}: CompanyDetailsProps) {
  return (
    <>
      <CustomInput
        autocomplete="off"
        type="text"
        placeholder="Nome da empresa"
        onChange={(e) => {
          setCompanyName(e.target.value);
        }}
        value={companyName}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "20px",
          flexDirection: "row",
        }}
      >
        <CustomInput
          autocomplete="off"
          type="number"
          style={{ marginRight: "16px" }}
          prefix="Máximo de franqueados:"
          onChange={(e) => {
            setMaxFranchisees(e.target.value);
          }}
          placeholder="Máximo de franqueados"
          value={maxFranchisees}
        />
        <CustomInput
          autocomplete="off"
          type="number"
          style={{ marginRight: "16px" }}
          prefix="Máximo de usuários:"
          onChange={(e) => {
            setMaxUsers(e.target.value);
          }}
          placeholder="Máximo de usuários"
          value={maxUsers}
        />
        <DateInput
          placeholder="Expiração de contrato"
          prefix="Expiração de contrato:"
          onChange={(value) => {
            setExpirationDate(value);
          }}
          value={expirationDate}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "20px",
          flexDirection: "row",
        }}
      >
        <CustomInput
          autocomplete="off"
          type="number"
          prefix="Total de créditos:"
          onChange={(e) => {
            setCredits(e.target.value);
          }}
          placeholder="Total de créditos"
          value={credits}
        />
      </div>
    </>
  );
}
