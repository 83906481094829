import React, { CSSProperties } from "react";
import { ButtonDefault } from "../Button/ButtonDefault";
import { Icon } from "../icon/Icon";
import { FaChevronDown } from "react-icons/fa";
import "./Tab.css";

interface TabProps {
  icon: React.ReactElement;
  name: string;
  styleContent?: CSSProperties;
  styleHeader?: CSSProperties;
  styleBody?: CSSProperties;
  onClickClose: () => void;
  children: React.ReactNode;
  floating?: boolean;
  bodyRef?: React.LegacyRef<HTMLDivElement>;
}

export function Tab({
  icon,
  name,
  onClickClose,
  children,
  styleContent,
  styleHeader,
  styleBody,
  floating,
  bodyRef,
}: TabProps) {
  return (
    <div
      className={`tab-content${floating ? " floating" : ""}`}
      style={{ zIndex: 99, ...styleContent }}
    >
      <div className="tab-header" style={styleHeader}>
        <Icon icon={icon} color={"secondary"} size={24} />
        <div className="tab-name">
          <p>{name}</p>
        </div>
        <ButtonDefault
          style={{
            marginRight: 10,
          }}
          icon={<FaChevronDown />}
          color={"secondary"}
          size={"small"}
          transparent={true}
          onClick={onClickClose}
        />
      </div>
      <div
        ref={bodyRef}
        className={`tab-body${floating ? " floating" : ""}`}
        style={styleBody}
      >
        {children}
      </div>
    </div>
  );
}
