import L, { LatLngTuple } from "leaflet";
import GA4 from "react-ga4";
import { PolygonParamList } from "../../../../../Types/interfacs";
import { drawLine } from "../Creation/CreateLine";
import { CircleProps } from "../types";
import { getDistanceBetweenPoints } from "../getDistanceBetweenPoints";
import { UserToken } from "../../../../../services/userToken";
import { deselectText } from "../../../Utils";

interface EditCircleProps {
  map: L.Map;
  layerShapes: L.LayerGroup;
  outlineRetangle?: L.Rectangle;
  circle: CircleProps;
  editMarker?: L.Marker;
  deleteMarker?: L.Marker;
  setPolygon?: React.Dispatch<React.SetStateAction<PolygonParamList>>;
}

export function EditCircle({
  map,
  layerShapes,
  circle,
  editMarker,
  deleteMarker,
  outlineRetangle,
}: EditCircleProps) {
  const { lat, lng } = circle.getLatLng();
  let centerLatLng = [lat, lng] as L.LatLngTuple;
  const east = circle.getBounds().getEast();
  let secondLatLng = [lat, east] as L.LatLngTuple;
  const color = circle.options.color as string;

  let radius: number;
  let toolTip: L.Tooltip;
  let line: L.Polyline = drawLine(centerLatLng, secondLatLng, color, true);

  const icon = L.icon({
    iconUrl: "edit-Icon.png",
    iconSize: [20, 20],
  });

  const centerMarker = L.marker(centerLatLng, {
    icon,
  });
  centerMarker.on("mousedown", handleClickOnCenter);

  const secondMarker = L.marker(secondLatLng, {
    icon,
  });
  secondMarker.on("mousedown", handleClickOnSecond);

  centerMarker.setZIndexOffset(1000);
  secondMarker.setZIndexOffset(1000);

  map.getContainer().style.cursor = "crosshair";

  function onMouseOver() {
    map.on("mouseout", (e: L.LeafletMouseEvent) => {
      document.addEventListener("mouseup", handleClickUp);
      const secondLatLng = [e.latlng.lat, e.latlng.lng] as L.LatLngTuple;

      const zoom = map.getZoom();
      if (circle) {
        const center = circle.getBounds().getCenter();

        const middlePoint = [
          (center.lat + secondLatLng[0]) / 2,
          (center.lng + secondLatLng[1]) / 2,
        ] as LatLngTuple;

        map.setView(middlePoint, zoom);
      }
    });
  }

  function addGuides() {
    centerMarker.addTo(layerShapes);
    secondMarker.addTo(layerShapes);
    line.addTo(layerShapes);

    map.on("mouseup", handleClickUp);
  }

  addGuides();

  function removeGuides() {
    layerShapes.removeLayer(line);
    layerShapes.removeLayer(centerMarker);
    layerShapes.removeLayer(secondMarker);
  }

  outlineRetangle?.on("remove", () => {
    map.off("mouseup", handleClickUp);
    removeGuides();
  });

  editMarker?.on("click", () => {
    layerShapes.removeLayer(line);
    layerShapes.removeLayer(centerMarker);
    layerShapes.removeLayer(secondMarker);
  });

  deleteMarker?.on("click", () => {
    layerShapes.removeLayer(circle);
    layerShapes.removeLayer(line);
    layerShapes.removeLayer(centerMarker);
    layerShapes.removeLayer(secondMarker);
    outlineRetangle?.remove();
  });

  function removeOnMoveOrResize() {
    editMarker?.remove();
    deleteMarker?.remove();
    outlineRetangle?.remove();

    addGuides();
  }

  function addOnMoveOrResizeFinish() {
    const southWest = circle.getBounds().getSouthWest();
    const northEast = circle.getBounds().getNorthEast();

    const firstLatLng = [southWest.lat - 0.0005, southWest.lng - 0.0005] as [
      number,
      number
    ];
    const secondLatLng = [northEast.lat + 0.0005, northEast.lng + 0.0005] as [
      number,
      number
    ];

    outlineRetangle?.setBounds([firstLatLng, secondLatLng]);

    const northEastOutiline = outlineRetangle?.getBounds().getNorthEast();

    editMarker?.setLatLng(northEastOutiline ?? northEast);
    deleteMarker?.setLatLng(northEastOutiline ?? northEast);
    editMarker?.addTo(layerShapes);
    deleteMarker?.addTo(layerShapes);
    outlineRetangle?.addTo(layerShapes);
  }

  function handleClickOnCenter(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      onMouseOver();
      map.dragging.disable();
      map.on("mousemove", handleMouseMoveCenter);
      if (editMarker || deleteMarker) {
        removeOnMoveOrResize();
      }
    }
  }

  function handleClickOnSecond(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      onMouseOver();
      map.dragging.disable();
      map.on("mousemove", handleMouseMoveSecond);
      if (editMarker || deleteMarker) {
        removeOnMoveOrResize();
      }
    }
  }

  function handleMouseMoveCenter(e: L.LeafletMouseEvent) {
    centerLatLng = [e.latlng.lat, e.latlng.lng];

    // Calcule o deslocamento entre a posição anterior de centerLatLng e a nova posição
    const latDiff = centerLatLng[0] - circle.getLatLng().lat;
    const lngDiff = centerLatLng[1] - circle.getLatLng().lng;

    // Atualize secondLatLng com base no deslocamento
    secondLatLng = [secondLatLng[0] + latDiff, secondLatLng[1] + lngDiff];

    circle.setLatLng(centerLatLng);
    line.setLatLngs([centerLatLng, secondLatLng]);
    centerMarker.setLatLng(centerLatLng);
    secondMarker.setLatLng(secondLatLng);
  }

  function handleMouseMoveSecond(e: L.LeafletMouseEvent) {
    const newSecondLatLng = [e.latlng.lat, e.latlng.lng];

    radius = getDistanceBetweenPoints(centerLatLng, newSecondLatLng);

    // Limitando o raio a 100 km
    const maxRadius = 100000; // 100 km em metros
    if (radius > maxRadius) {
      radius = maxRadius;
    } else {
      secondLatLng = newSecondLatLng as L.LatLngTuple;
      line.setLatLngs([centerLatLng, secondLatLng]);
      secondMarker.setLatLng(secondLatLng);
    }

    circle.setRadius(radius);

    if (toolTip) {
      toolTip.remove();
    }

    const distanceKmRounded = Math.round((radius / 1000) * 100) / 100;

    toolTip = L.tooltip()
      .setLatLng(secondLatLng)
      .setContent(
        `<div
        style="
        background-color: white;
          padding: 5px;
          font-size: 14px;
          font-weight: bold;
        "
        >${distanceKmRounded} km</div>`
      )
      .addTo(map);
  }

  function handleClickUp() {
    map.off("mousedown");
    map.off("mousemove");
    map.off("mouseup");
    map.off("mouseout");
    document.removeEventListener("mouseup", handleClickUp);

    deselectText();

    if (circle && circle.getRadius() > 0) {
      addOnMoveOrResizeFinish();

      if (toolTip) {
        toolTip.remove();
      }

      map.dragging.enable();

      map.getContainer().focus();
    } else {
      map.on("mouseup", handleClickUp);

      map.getContainer().style.cursor = "crosshair";
    }
  }
}
