import { FaArrowRight } from "react-icons/fa";
import { ButtonDefault } from "../../../../../../components/Button/ButtonDefault";
import { SubTitle } from "../../../../../../components/typography/SubTitle";
import { Title } from "../../../../../../components/typography/Title";
import { CustomInput } from "../../../../../../components/Input/CustomInput";

interface ActionsProps {
  countReports: string;
  setCountReports: (value: string) => void;
  limitStudies: string;
  setLimitStudies: (value: string) => void;
  trial: boolean;
  setTrial: (value: boolean) => void;
  active: boolean;
  setActive: (value: boolean) => void;
}

export function Actions({
  trial,
  setTrial,
  active,
  setActive,
  countReports,
  setCountReports,
  limitStudies,
  setLimitStudies,
}: ActionsProps) {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          width: 1124,
        }}
      >
        <ButtonDefault
          style={{
            marginRight: 0,
            marginLeft: 0,
            width: 554,
          }}
          size="superLarge"
          title={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: 450,
              }}
            >
              <Title
                style={{
                  color: trial ? undefined : "#3A3A3A",
                  transition: "all 0.09s",
                }}
              >
                TORNAR CONTA TRIAL
              </Title>
              <SubTitle
                style={{
                  color: undefined,
                  paddingBottom: 0,
                }}
              >
                Tornar a conta de uma empresa trial vai limitar as ações
              </SubTitle>

              <SubTitle
                style={{
                  color: undefined,
                  paddingTop: 0,
                }}
              >
                que seus usuários tenham acesso na solução.
              </SubTitle>
            </div>
          }
          icon={<FaArrowRight />}
          iconPosition="right"
          border={true}
          color={trial ? "deepblueInverted" : "disabled"}
          onClick={() => {
            setTrial(!trial);
          }}
        />
        <ButtonDefault
          style={{
            marginRight: 0,
            marginLeft: 0,
            width: 554,
          }}
          size="superLarge"
          title={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: 450,
              }}
            >
              <Title
                style={{
                  color: active ? undefined : "#3A3A3A",
                  transition: "all 0.09s",
                }}
              >
                ATIVAR CONTA
              </Title>
              <SubTitle
                style={{
                  color: undefined,
                  paddingBottom: 0,
                }}
              >
                Ativar a conta de uma empresa vai possibilitar que seus
              </SubTitle>

              <SubTitle
                style={{
                  color: undefined,
                  paddingTop: 0,
                }}
              >
                usuários tenham acesso a solução.
              </SubTitle>
            </div>
          }
          icon={<FaArrowRight />}
          iconPosition="right"
          border={true}
          color={active ? "deepblueInverted" : "disabled"}
          onClick={() => {
            setActive(!active);
          }}
        />
      </div>
      {trial && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "20px",
            flexDirection: "row",
          }}
        >
          <CustomInput
            autocomplete="off"
            type="number"
            style={{ marginRight: "16px" }}
            prefix="Máximo de relatórios:"
            onChange={(e) => {
              setCountReports(e.target.value);
            }}
            placeholder="Máximo de relatórios"
            value={countReports}
          />
          <CustomInput
            autocomplete="off"
            type="number"
            prefix="Máximo de estudos:"
            onChange={(e) => {
              setLimitStudies(e.target.value);
            }}
            placeholder="Máximo de estudos"
            value={limitStudies}
          />
        </div>
      )}
    </>
  );
}
