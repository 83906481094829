import L, { LatLngTuple } from "leaflet";
import { Shapes } from "../../../../../Types/Shapes";
import React from "react";
import { PolygonParamList } from "../../../../../Types/interfacs";
import {
  getDistanceBetweenPoints,
  getPointAtDistanceLimit,
} from "../getDistanceBetweenPoints";
import { RectangleProps } from "../types";
import { salvaLog } from "../../../../../services/getOptionsData";
import { deselectText } from "../../../Utils";

interface CreateRectangleProps {
  map: L.Map;
  layerShapes: L.LayerGroup;
  color: string;
  setPolygon: React.Dispatch<React.SetStateAction<PolygonParamList>>;
  setShapeActive: React.Dispatch<React.SetStateAction<Shapes>>;
  setShapeSelectedToEdition: React.Dispatch<
    React.SetStateAction<{
      id: number;
      shape: L.Rectangle;
      optionsButton: {
        editButton: boolean;
        deleteButton: boolean;
      };
    }>
  >;
}

export const CreateRectangle = ({
  map,
  layerShapes,
  color,
  setPolygon,
  setShapeActive,
  setShapeSelectedToEdition,
}: CreateRectangleProps) => {
  let firstLatLng: [number, number];
  let secondLatLng: [number, number];
  let distanceBetweenPoints: number;
  let rectangle: RectangleProps | null;
  let toolTip: L.Tooltip;

  map.on("mousedown", handleClickDown);
  map.on("mouseup", handleClickUp);
  map.getContainer().style.cursor = "crosshair";

  function handleClickDown(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      map.dragging.disable();
      firstLatLng = [e.latlng.lat, e.latlng.lng];
      secondLatLng = [e.latlng.lat, e.latlng.lng];
      map.on("mousemove", handleMouseMove);
      rectangle = drawRectangle(
        firstLatLng,
        secondLatLng,
        color
      ) as RectangleProps;
      map.on("mouseout", (e: L.LeafletMouseEvent) => {
        document.addEventListener("mouseup", handleClickUp);

        const zoom = map.getZoom();
        if (rectangle) {
          const center = rectangle.getBounds().getCenter();

          const middlePoint = [
            (center.lat + secondLatLng[0]) / 2,
            (center.lng + secondLatLng[1]) / 2,
          ] as LatLngTuple;

          map.setView(middlePoint, zoom);
        }
      });

      rectangle.addTo(layerShapes);
    } else if (e.originalEvent.button === 1) {
      map.getContainer().style.cursor = "grabbing";
    }
  }

  function handleMouseMove(e: L.LeafletMouseEvent) {
    secondLatLng = [e.latlng.lat, e.latlng.lng];

    secondLatLng = getPointAtDistanceLimit(firstLatLng, secondLatLng, 250000);

    distanceBetweenPoints = getDistanceBetweenPoints(firstLatLng, secondLatLng);

    if (toolTip) {
      toolTip.remove();
    }

    // Atualiza o retângulo com os novos limites
    if (rectangle) {
      rectangle.setBounds([firstLatLng, secondLatLng]);

      rectangle._path.style.cursor = "crosshair";
    }

    const distanceKm = distanceBetweenPoints / 1000;
    const distanceKmRounded = Math.round(distanceKm * 100) / 100;

    toolTip = L.tooltip()
      .setLatLng(secondLatLng)
      .setContent(
        `<div
          style="
          background-color: white;
            padding: 5px;
            font-size: 14px;
            font-weight: bold;
          "
          >${distanceKmRounded < 200 ? distanceKmRounded : "Max 200"} km</div>`
      )
      .addTo(map);
  }

  function handleClickUp() {
    map.off("mousedown");
    map.off("mouseup");
    map.off("mousemove");
    map.off("mouseout");
    document.removeEventListener("mouseup", handleClickUp);

    if (rectangle && distanceBetweenPoints > 0) {
      salvaLog({
        action: "Finalizou rectangle",
        params: "",
      }).then((result: any) => {
      });

      const idShape = rectangle._leaflet_id;

      const type = "RECTANGLE_STUDY";

      setPolygon((prev: PolygonParamList) => {
        return {
          ...prev,
          rectangleList: [
            ...prev.rectangleList,
            {
              id: idShape,
              points: [firstLatLng, secondLatLng],
              type,
              color,
            },
          ],
        };
      });

      rectangle.on("mousedown", () => {
        map.off("click");
      });

      rectangle.on("click", () => {
        setShapeSelectedToEdition({
          id: idShape,
          shape: rectangle as RectangleProps,
          optionsButton: {
            editButton: true,
            deleteButton: true,
          },
        });
      });
      deselectText();

      setShapeActive("arrow");
      if (toolTip) {
        toolTip.remove();
      }

      map.dragging.enable();
      map.getContainer().style.cursor = "grab";
      rectangle._path.style.cursor = "pointer";
    } else {
      map.on("mousedown", handleClickDown);
      map.on("mouseup", handleClickUp);
      rectangle && layerShapes.removeLayer(rectangle);

      map.getContainer().style.cursor = "crosshair";
    }
  }

  return null;
};

export function drawRectangle(
  firstLatLng: [number, number],
  secondLatLng: [number, number],
  color: string
) {
  const rectangle = L.rectangle([firstLatLng, secondLatLng], {
    color: color,
    weight: 4,
    fillOpacity: 0.1,
  });

  return rectangle;
}

function polygonArea(polygonPoints: LatLngTuple[]) {
  let j = 0;
  let area = 0;

  for (let i = 0; i < polygonPoints.length; i++) {
    j = (i + 1) % polygonPoints.length;

    const xi = polygonPoints[i][0];
    const yi = polygonPoints[i][1];
    const xj = polygonPoints[j][0];
    const yj = polygonPoints[j][1];

    area += xi * yj;
    area -= yi * xj;
  }

  area /= 2;
  return area < 0 ? -area : area;
}
