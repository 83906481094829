import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import { Title } from "../../../../components/typography/Title";
import fonts from "../../../../config/fonts";
import { InformationPoint } from "./components/InformationPoint";
import L, { IconOptions, LatLngExpression } from "leaflet";
import { CompanyInfoProps } from "../../Companies";
import { useEffect, useState } from "react";
import { addressFormat, dateFormat, format } from "../../../Map/Utils";
import { cityInfoFilter } from "../../../../data/cityNumbers";

interface InformationCompanyProps {
  selectedInfo: CompanyInfoProps | null;
}

export function InformationCompany({ selectedInfo }: InformationCompanyProps) {
  const [latlon, setLatLon] = useState<LatLngExpression | null>(null);

  useEffect(() => {
    setLatLon(null);
    if (selectedInfo?.lat && selectedInfo?.lng) {
      setLatLon([Number(selectedInfo?.lat), Number(selectedInfo?.lng)]);
    }
  }, [selectedInfo]);

  const GeneralInformation = [
    "Nome Fantasia:",
    "Razão Social:",
    "CNPJ:",
    "CNAE:",
    "Natureza Jurídica:",
    "Data de inicio de atividade:",
    "Matriz / Filial:",
    "Porte:",
    "Capital Social:",
  ];

  const capitalSocialString = parseInt(selectedInfo?.capital_social || "0");
  const capitalSocialReal =
    capitalSocialString > 0 ? format(capitalSocialString, "Moeda") : " ";

  const dateStart =
    selectedInfo?.data_inicio_ativ &&
    dateFormat(selectedInfo?.data_inicio_ativ);

  const dataGeneralInformation = [
    selectedInfo?.nome_fantasia,
    selectedInfo?.razao_social,
    selectedInfo?.cnpj,
    selectedInfo?.cnae_fiscal,
    selectedInfo?.cod_nat_juridica,
    dateStart,
    selectedInfo?.matriz_filial,
    selectedInfo?.porte,
    capitalSocialReal,
  ];

  const AddressInformation = [
    "Endereço:",
    "Cidade:",
    "Estado:",
    "Latitude:",
    "Longitude:",
  ];

  const adress = selectedInfo?.endereco
    ? addressFormat(selectedInfo.endereco)
    : " ";

  const cidade = selectedInfo?.cidade
    ? cityInfoFilter(selectedInfo.cidade)
    : " ";

  const dataAddressInformation = [
    adress,
    cidade,
    selectedInfo?.estado,
    selectedInfo?.lat,
    selectedInfo?.lng,
  ];

  const ContactInformation = ["Telefone 1:", "Telefone 2:", "Email:"];
  const dataContactInformation = [
    selectedInfo?.telefone1,
    selectedInfo?.telefone2,
    selectedInfo?.email,
  ];

  let customPin: IconOptions = {
    iconUrl: "pinMarker.png",
    iconSize: [50, 50],
    iconAnchor: [25, 50],
  };
  let IconPin = L.icon(customPin);

  const PreviewMap = () => {
    const map = useMap();

    if (latlon) {
      map.setView(latlon, 17);
    }
    return null;
  };

  return (
    <div
      id="companies-info"
      style={{
        flex: 3,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        paddingRight: 16,
      }}
    >
      <Title
        style={{
          fontSize: 32,
          paddingBottom: 20,
          fontFamily: fonts.expBold,
        }}
      >
        Informações Gerais
      </Title>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {GeneralInformation.map((item, index) => (
          <InformationPoint
            key={index}
            title={item}
            data={dataGeneralInformation[index] || " "}
          />
        ))}
      </div>

      <Title
        style={{
          fontSize: 32,
          padding: "20px 0",
          fontFamily: fonts.expBold,
        }}
      >
        Endereço
      </Title>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {AddressInformation.map((item, index) => (
          <InformationPoint
            key={index}
            title={item}
            data={dataAddressInformation[index] || " "}
          />
        ))}
        <div
          id="preview-map"
          style={{
            width: "100%",
            height: 200,
            borderRadius: 8,
            padding: "12px 0",
            boxShadow: !latlon
              ? "inset 0px 0px 10px rgba(0, 0, 0, 0.25)"
              : undefined,
          }}
        >
          {latlon && (
            <MapContainer
              dragging={false}
              zoomControl={false}
              scrollWheelZoom={false}
              style={{
                width: "100%",
                height: 200,
                borderRadius: 8,
              }}
            >
              <TileLayer
                attribution=""
                url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                subdomains={["mt0", "mt1", "mt2", "mt3"]}
              />
              <Marker position={latlon} icon={IconPin} />
              <PreviewMap />
            </MapContainer>
          )}
        </div>
      </div>

      <Title
        style={{
          fontSize: 32,
          padding: "20px 0",
          fontFamily: fonts.expBold,
        }}
      >
        Contato
      </Title>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {ContactInformation.map((item, index) => (
          <InformationPoint
            key={index}
            title={item}
            data={dataContactInformation[index] || " "}
          />
        ))}
      </div>
    </div>
  );
}
