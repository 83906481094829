import React, { useState, ReactNode, useEffect, CSSProperties } from "react";
import { ButtonDefault } from "../../components/Button/ButtonDefault";
import { FaChevronLeft } from "react-icons/fa";

interface ExpandableToolbarProps {
  children: ReactNode;
  startOpen?: boolean;
  direction?: "row" | "column";
  setOpen?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  style?: CSSProperties;
}

export function ExpandableToolbar({
  children,
  startOpen = false,
  direction = "row",
  setOpen,
  style,
}: ExpandableToolbarProps) {
  const [toolsOpen, setToolsOpen] = useState(startOpen);

  useEffect(() => {
    setOpen && setOpen(toolsOpen);
  }, []);

  const containerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    marginLeft: "5px",
    marginRight: "5px",
    position: "relative",
  };

  const contentStyle: React.CSSProperties = {
    display: "flex",
    paddingRight: 12,
    paddingLeft: 12,
    flexDirection: direction,
    justifyContent: direction === "row" ? "flex-start" : "center",
    alignItems: direction === "row" ? "center" : "flex-start",
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    overflow: "hidden",
    width: toolsOpen ? "240px" : "56px",
    transition: "width 0.6s",
    ...style,
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>{children}</div>
      <ButtonDefault
        style={{
          position: "absolute",
          right: "-16px",
          borderRadius: "15px",
          transform: `rotate(${toolsOpen ? "0deg" : "180deg"})`,
          transition: "all 0.5s",
        }}
        icon={<FaChevronLeft />}
        size={"small"}
        color={"secondary"}
        onClick={() => {
          setToolsOpen(!toolsOpen);
          setOpen && setOpen(!toolsOpen);
        }}
      />
    </div>
  );
}
