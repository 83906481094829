import React, { CSSProperties, ReactNode } from "react";
import { colors } from "../../assets/colors";
import fonts from "../../config/fonts";

interface SubTitleModalProps {
  children: ReactNode;
  color?: string;
  style?: CSSProperties;
}

const colorSubtitle = colors.default.disabled.content;

export function SubTitleModal({
  children,
  color = colorSubtitle,
  style,
}: SubTitleModalProps) {
  return (
    <h1
      style={{
        fontSize: fonts.sizes.regular,
        fontWeight: "normal",
        textAlign: "center",
        padding: "10px 220px",
        marginBlock: "14px",
        color,
        ...style,
      }}
    >
      {children}
    </h1>
  );
}
