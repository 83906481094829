import L from "leaflet";
import React from "react";
import { SurroundingsParams } from "../../../../../Types/Params";

interface CustomMarker extends L.Marker {
  _leaflet_id: number;
}

let pins: any[] = [];

export function CreatePinToSurroundings(
  map: L.Map,
  paramsToSurroundings: SurroundingsParams,
  setParamsToSurroundings: React.Dispatch<
    React.SetStateAction<SurroundingsParams>
  >,
  pinsLayer: L.LayerGroup
) {
  let pin = null;

  if (!paramsToSurroundings.pins || paramsToSurroundings.pins.length === 0) {
    pins = [];
  }

  let positionPin: [number, number] | null = null;

  let customPin: any = {
    iconUrl: "pinMarker.png",
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  };
  let IconPin = L.icon(customPin);

  map.getContainer().style.cursor = "crosshair";
  map.on("mousedown", handleClickDown);
  map.on("mouseup", handleClickUp);

  function handleClickDown(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      positionPin = [e.latlng.lat, e.latlng.lng];
      map.on("mouseup", handleClickUp);
    } else if (e.originalEvent.button === 1) {
      map.getContainer().style.cursor = "grabbing";
      map.dragging.enable();
    }
  }

  function handleClickUp(e: L.LeafletMouseEvent) {
    if (positionPin) {
      pin = L.marker(positionPin, { icon: IconPin }) as CustomMarker;
      pin.addTo(pinsLayer);
      pins.push({
        id: pin._leaflet_id,
        lat: positionPin[0],
        lon: positionPin[1],
      });
      setParamsToSurroundings((oldParams: any) => ({
        ...oldParams,
        pins,
      }));

      positionPin = null;
    } else {
      map.getContainer().style.cursor = "crosshair";
    }
  }
}
