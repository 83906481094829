import L, { LatLngTuple } from "leaflet";
import { Shapes } from "../../../../../Types/Shapes";
import React from "react";
import { BestAreaVariablesParams } from "../../../../../Types/Params";
import {
  getDistanceBetweenPoints,
  getPointAtDistanceLimit,
} from "../getDistanceBetweenPoints";
import { BestAreaProps } from "../types";
import { EditRectangle } from "../Edition/EditRectangle";
import { salvaLog } from "../../../../../services/getOptionsData";
import { deselectText } from "../../../Utils";

interface CreateBestAreaProps {
  map: L.Map;
  layerShapes: L.LayerGroup;
  color: string;
  setParamsToBestAreaVariables: React.Dispatch<
    React.SetStateAction<BestAreaVariablesParams>
  >;
  setButtonToConfirmBestAreaVariables: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setShapeActive: React.Dispatch<React.SetStateAction<Shapes>>;
}

export const CreateBestArea = ({
  map,
  layerShapes,
  color,
  setParamsToBestAreaVariables,
  setButtonToConfirmBestAreaVariables,
  setShapeActive,
}: CreateBestAreaProps) => {
  let firstLatLng: [number, number];
  let secondLatLng: [number, number];
  let distanceBetweenPoints: number;
  let bestArea: BestAreaProps;
  let toolTip: L.Tooltip;
  let editMarker: L.Marker;
  let deleteMarker: L.Marker;

  map.on("mousedown", handleClickDown);
  map.on("mouseup", handleClickUp);
  map.getContainer().style.cursor = "crosshair";

  function handleClickDown(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button === 0) {
      map.dragging.disable();
      firstLatLng = [e.latlng.lat, e.latlng.lng];
      map.on("mousemove", handleMouseMove);
      bestArea = drawRectangle(
        firstLatLng,
        secondLatLng,
        color
      ) as BestAreaProps;

      map.on("mouseout", (e: L.LeafletMouseEvent) => {
        document.addEventListener("mouseup", handleClickUp);

        const zoom = map.getZoom();
        if (bestArea) {
          const center = bestArea.getBounds().getCenter();

          const middlePoint = [
            (center.lat + secondLatLng[0]) / 2,
            (center.lng + secondLatLng[1]) / 2,
          ] as LatLngTuple;

          map.setView(middlePoint, zoom);
        }
      });
      bestArea.addTo(layerShapes);
    } else if (e.originalEvent.button === 1) {
      map.getContainer().style.cursor = "grabbing";
    }
  }

  function handleMouseMove(e: L.LeafletMouseEvent) {
    secondLatLng = [e.latlng.lat, e.latlng.lng];

    secondLatLng = getPointAtDistanceLimit(firstLatLng, secondLatLng, 250000);

    distanceBetweenPoints = getDistanceBetweenPoints(firstLatLng, secondLatLng);

    if (toolTip) {
      toolTip.remove();
    }

    if (bestArea) {
      bestArea.setBounds([firstLatLng, secondLatLng]);
    }

    bestArea._path.style.cursor = "crosshair";

    const distanceKm = distanceBetweenPoints / 1000;
    const distanceKmRounded = Math.round(distanceKm * 100) / 100;

    toolTip = L.tooltip()
      .setLatLng(secondLatLng)
      .setContent(
        `<div
          style="
          background-color: white;
            padding: 5px;
            font-size: 14px;
            font-weight: bold;
          "
          >${distanceKmRounded < 250 ? distanceKmRounded : "Max 250"} km</div>`
      )
      .addTo(map);
  }

  function handleClickUp() {
    map.off("mousedown");
    map.off("mouseup");
    map.off("mousemove");
    map.off("mouseout");
    document.removeEventListener("mouseup", handleClickUp);

    if (bestArea && distanceBetweenPoints > 0) {
      salvaLog({
        action: "Finalizou Best Area",
        params: "",
      }).then((result: any) => {
      });

      const idShape = bestArea._leaflet_id;

      setParamsToBestAreaVariables((oldParams: any) => ({
        ...oldParams,
        rect: {
          index: idShape,
          lat1: firstLatLng[0],
          lat2: secondLatLng[0],
          lon1: firstLatLng[1],
          lon2: secondLatLng[1],
        },
      }));
      setShapeActive("arrow");
      if (toolTip) {
        toolTip.remove();
      }

      const northEast = bestArea.getBounds().getNorthEast();

      const editIcon = L.icon({
        iconUrl: "edit-variable.png",
        iconSize: [35, 35],
        iconAnchor: [80, 45],
      });

      const editActiveIcon = L.icon({
        iconUrl: "edit-variable-active.png",
        iconSize: [35, 35],
        iconAnchor: [80, 45],
      });

      editMarker = L.marker(northEast, {
        icon: editIcon,
      });

      const deleteIcon = L.icon({
        iconUrl: "delete.png",
        iconSize: [35, 35],
        iconAnchor: [35, 45],
      });

      deleteMarker = L.marker(northEast, {
        icon: deleteIcon,
      });

      editMarker.setOpacity(0.7);
      deleteMarker.setOpacity(0.7);

      editMarker.addTo(layerShapes);

      deleteMarker.addTo(layerShapes);

      deleteMarker.on("mouseover", () => {
        map.dragging.disable();
        deleteMarker.setOpacity(1);
      });

      deleteMarker.on("mouseout", () => {
        map.dragging.enable();
        deleteMarker.setOpacity(0.7);
      });

      editMarker.on("mouseover", () => {
        map.dragging.disable();
        editMarker.setOpacity(1);
      });

      editMarker.on("mouseout", () => {
        map.dragging.enable();
        editMarker.setOpacity(0.7);
      });

      editMarker.on("click", () => {
        if (bestArea) {
          if (editMarker.options.icon === editIcon) {
            editMarker.setIcon(editActiveIcon);
            map.off("mousemove");
            map.off("mouseup");
            setButtonToConfirmBestAreaVariables(false);

            EditRectangle({
              map,
              layerShapes,
              rectangle: bestArea,
              editMarker,
              deleteMarker,
            });
          } else {
            editMarker.setIcon(editIcon);
            map.off("mousemove");
            map.off("mouseup");

            setParamsToBestAreaVariables((oldParams: any) => ({
              ...oldParams,
              rect: {
                index: idShape,
                lat1: firstLatLng[0],
                lat2: secondLatLng[0],
                lon1: firstLatLng[1],
                lon2: secondLatLng[1],
              },
            }));

            setButtonToConfirmBestAreaVariables(true);
          }
        }
      });

      deleteMarker.on("click", () => {
        map.dragging.enable();
        if (bestArea) {
          layerShapes.removeLayer(bestArea);
          layerShapes.removeLayer(editMarker);
          layerShapes.removeLayer(deleteMarker);

          setButtonToConfirmBestAreaVariables(false);

          setParamsToBestAreaVariables((oldParams: any) => ({
            ...oldParams,
            rect: null,
          }));

          setShapeActive("bestArea");
        }
        map.dragging.enable();
      });

      deselectText();

      distanceBetweenPoints = 0;

      setButtonToConfirmBestAreaVariables(true);

      map.dragging.enable();
    } else {
      map.on("mousedown", handleClickDown);
      map.on("mouseup", handleClickUp);
      bestArea && layerShapes.removeLayer(bestArea);

      map.getContainer().style.cursor = "crosshair";
    }
  }

  function drawRectangle(
    firstLatLng: [number, number],
    secondLatLng: [number, number],
    color: string
  ) {
    const rectangle = L.rectangle([firstLatLng, secondLatLng], {
      color: color,
      dashArray: "15, 15",
      weight: 4,
      fillOpacity: 0.1,
    });

    return rectangle;
  }

  return null;
};
