import { FaCircle, FaSquare, FaStar } from "react-icons/fa";

interface CorrectIconProps {
  form: string;
  color: string;
}

export function CorrectIcon({ form, color }: CorrectIconProps) {
  if (form === "RADIUS_STUDY") {
    return (
      <FaCircle
        id="tagsIcon"
        style={{
          backgroundColor: "transparent",
          color: color,
        }}
      />
    );
  } else if (form === "RECTANGLE_STUDY") {
    return (
      <FaSquare
        id="tagsIcon"
        style={{
          backgroundColor: "transparent",
          color: color,
        }}
      />
    );
  } else if (form === "POLYGON_STUDY") {
    return (
      <FaStar
        id="tagsIcon"
        style={{
          backgroundColor: "transparent",
          color: color,
        }}
      />
    );
  } else {
    return <></>;
  }
}
