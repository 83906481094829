import { useEffect, useRef, useState } from "react";
import { getSimpleStudy } from "../../../../services/getOptionsData";
import { colorToRGBString } from "../../Utils";
import { Tab } from "../../../../components/Tab/Tab";
import { FaBriefcase, FaChartPie, FaUserAlt } from "react-icons/fa";
import { Title } from "../../../../components/typography/Title";
import { SubTitle } from "../../../../components/typography/SubTitle";
import { ButtonDefault } from "../../../../components/Button/ButtonDefault";
import { depara } from "../../../../pages/Map/components/reports/order";
import { Divider } from "antd";
import { AreaList } from "./AreaList";
import { DadosDasPessoas } from "./dadosdaspessoas";
import { DadosDasEmpresas } from "./dadosdasempresas";

var carga;
var pendingresults;

export function PreviewLayer(props) {
  const {
    hasContent,
    selectedFilter,
    setSelectedFilter,
    selectedArea,
    setSelectedArea,
    markers,
    setMarkers,
    CNAEPesquisado,
    setCNAEPesquisado,
    polygon,
    setPolygon,
    areaList,
    setAreaList,
    graphicsData,
    setGraphicsData,
    totalStudyResults,
    updateStudyResults,
    updateStudyResultsCnaes,
    shapeSelectedToEdition,
    newSearch,
  } = props;
  const [open, setOpen] = useState(true);
  const [estudoTypePessoa, setEstudoTypePessoa] = useState(true);
  const divRef = useRef(null);

  useEffect(() => {
    requestSimpleStudy();
  }, [selectedArea, areaList]);

  function findAreaById(id) {
    for (let index = 0; index < areaList.length; index++) {
      const element = areaList[index];
      if (element.id === id) {
        return element;
      }
    }
    return null;
  }

  function requestSimpleStudy() {
    setGraphicsData([]);
    pendingresults = [];
    if (selectedArea.length > 0) {
      carga = selectedArea.length;
      for (let index = 0; index < selectedArea.length; index++) {
        const element = selectedArea[index];
        simpleAreaStudy(element);
      }
    } else {
      if (areaList) {
        carga = areaList.length;
        for (let index = 0; index < areaList.length; index++) {
          const element = areaList[index];
          simpleAreaStudy(element);
        }
      }
    }
  }

  function simpleAreaStudy(area) {
    const polylist = [];
    const circlist = [];
    const rectlist = [];

    if (area !== null) {
      if (area.label.startsWith("Retângulo")) {
        var lowX;
        var lowY;
        var hiX;
        var hiY;
        if (area.points[0] < area.points[2]) {
          lowX = area.points[2];
          hiX = area.points[0];
        } else {
          lowX = area.points[0];
          hiX = area.points[2];
        }
        if (area.points[1] > area.points[3]) {
          lowY = area.points[3];
          hiY = area.points[1];
        } else {
          lowY = area.points[1];
          hiY = area.points[3];
        }
        rectlist.push({
          index: area.id,
          lat1: lowX,
          lon1: lowY,
          lat2: hiX,
          lon2: hiY,
        });
      } else if (area.label.startsWith("Polígono")) {
        const pon = [];
        for (let index = 0; index < area.points.length; index += 2) {
          pon.push({
            lat: area.points[index],
            lon: area.points[index + 1],
          });
        }
        polylist.push({ index: area.id, latlngs: pon });
      } else if (area.label.startsWith("Círculo")) {
        circlist.push({
          index: area.id,
          ilat: area.points[0],
          ilon: area.points[1],
          radius: area.points[2],
        });
      }
      const params = {
        pins: [],
        polygonList: polylist,
        radiusList: circlist,
        rectangleList: rectlist,
        regionList: [],
        tokens: [],
      };

      getSimpleStudy(params).then((val) => {
        if (!val) {
          return;
        }
        const myMap = new Map();
        const dp = depara();
        val.polygonList?.forEach((element) => {
          const relatedArea = findAreaById(element.index);
          element.variableList?.forEach((graphData) => {
            graphData.name = dp.get(graphData.name);
            const labels = [];
            const values = [];
            graphData.labels?.forEach((label) => {
              labels.push(label.nameDesc);
              values.push(label.value);
            });
            const dt = {
              datatooltip: labels,
              color: relatedArea?.cor,
              type: graphData.type,
              area: element.area,
              centroid: element.centroid,
              id: element.index,
              value: values,
              rgb: colorToRGBString(relatedArea?.cor),
            };
            if (myMap.has(graphData.name)) {
              myMap.get(graphData.name).push(dt);
            } else {
              myMap.set(graphData.name, [dt]);
            }
          });
        });
        val.radiusList?.forEach((element) => {
          const relatedArea = findAreaById(element.index);
          element.variableList?.forEach((graphData) => {
            graphData.name = dp.get(graphData.name);
            const labels = [];
            const values = [];
            graphData.labels?.forEach((label) => {
              labels.push(label.nameDesc);
              values.push(label.value);
            });
            const dt = {
              datatooltip: labels,
              color: relatedArea?.cor,
              type: graphData.type,
              area: element.area,
              centroid: element.centroid,
              id: element.index,
              value: values,
              rgb: colorToRGBString(relatedArea?.cor),
            };
            if (myMap.has(graphData.name)) {
              myMap.get(graphData.name).push(dt);
            } else {
              myMap.set(graphData.name, [dt]);
            }
          });
        });
        val.rectangleList?.forEach((element) => {
          const relatedArea = findAreaById(element.index);
          element.variableList?.forEach((graphData) => {
            graphData.name = dp.get(graphData.name);
            const labels = [];
            const values = [];
            graphData.labels?.forEach((label) => {
              labels.push(label.nameDesc);
              values.push(label.value);
            });
            const dt = {
              datatooltip: labels,
              color: relatedArea?.cor,
              type: graphData.type,
              area: element.area,
              centroid: element.centroid,
              id: element.index,
              value: values,
              rgb: colorToRGBString(relatedArea?.cor),
            };
            if (myMap.has(graphData.name)) {
              myMap.get(graphData.name).push(dt);
            } else {
              myMap.set(graphData.name, [dt]);
            }
          });
        });
        const iteratorMap = myMap.entries();
        const finalArray = [];
        var vl = iteratorMap.next().value;
        while (vl !== undefined) {
          finalArray.push({ graphLabel: vl[0], graphData: vl[1] });
          vl = iteratorMap.next().value;
        }
        pendingresults = fuseResults(pendingresults, finalArray);
        //pendingresults = [...pendingresults, ...finalArray];
        carga--;
        if (carga === 0) {
          setGraphicsData(pendingresults);
          pendingresults = [];
        }
      });
    }
  }

  function fuseResults(newresults, oldresults) {
    for (let indexN = 0; indexN < newresults.length; indexN++) {
      const elementNew = newresults[indexN];
      for (let index = 0; index < oldresults.length; index++) {
        const element = oldresults[index];
        if (element.graphLabel === elementNew.graphLabel) {
          element.graphData = [...element.graphData, ...elementNew.graphData];
          break;
        }
      }
    }
    return [...oldresults];
  }

  useEffect(() => {
    const createPolygonArea = (poly) => {
      const allPoints = poly.points.flatMap((p) => [p[0], p[1]]);
      return {
        label: "Polígono",
        cor: poly.color,
        studyType: poly.type,
        points: allPoints,
        id: poly.id,
      };
    };

    const createCircleArea = (circle) => ({
      label: "Círculo",
      cor: circle.color,
      studyType: circle.type,
      id: circle.id,
      points:
        circle.points[0] !== null
          ? [circle.points[0][0], circle.points[0][1], circle.ray]
          : [newSearch.latLng[0], newSearch.latLng[1], circle.ray],
    });

    const createRectangleArea = (rect) => {
      const allPoints = rect.points.flatMap((p) => [p[0], p[1]]);
      return {
        label: "Retângulo",
        cor: rect.color,
        studyType: rect.type,
        points: allPoints,
        id: rect.id,
      };
    };
    const polygonAreas = polygon.polygonList.map(createPolygonArea);
    const circleAreas = polygon.radiusList.map(createCircleArea);
    const rectangleAreas = polygon.rectangleList.map(createRectangleArea);

    const areaList = [...polygonAreas, ...circleAreas, ...rectangleAreas];

    setAreaList(areaList);
  }, [polygon]);

  const adjustDivHeight = () => {
    if (divRef.current && divRef.current.style) {
      if (window.innerHeight < 650) {
        divRef.current.style.maxHeight = "90%";
      } else if (window.innerHeight < 850 && window.innerHeight > 651) {
        divRef.current.style.maxHeight = "92%";
      } else {
        divRef.current.style.maxHeight = "95%";
      }
    }
  };

  useEffect(() => {
    adjustDivHeight();
    window.addEventListener("resize", adjustDivHeight);

    return () => {
      window.removeEventListener("resize", adjustDivHeight);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",

        position: "absolute",
        right: 0,
        height: open && hasContent && "calc(100% - 35px)",
        paddingTop: 25,
        paddingRight: 25,
        flexDirection: "column",
        alignItems: "flex-end",
        zIndex: 999,
      }}
    >
      {open ? (
        <Tab
          bodyRef={divRef}
          styleBody={{
            maxHeight: "calc(100% - 35px)",
            overflowY: "auto",
          }}
          icon={<FaChartPie size={24} />}
          name="Estudos de área"
          onClickClose={() => {
            setOpen(!open);
          }}
        >
          <Title>Tipo de estudo</Title>
          <SubTitle>
            Selecione abaixo o tipo de estudo para ter uma preview dos dados.
          </SubTitle>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
            }}
          >
            <ButtonDefault
              style={{
                marginLeft: 0,
                width: 126,
              }}
              size="medium"
              title="Pessoas"
              icon={<FaUserAlt />}
              color={estudoTypePessoa ? "secondary" : "disabled"}
              border={estudoTypePessoa ? false : true}
              borderRadius={40}
              onClick={() => {
                setEstudoTypePessoa(true);
              }}
            />
            <ButtonDefault
              style={{
                marginRight: 0,
                width: 126,
              }}
              size="medium"
              title="Empresas"
              icon={<FaBriefcase />}
              color={estudoTypePessoa ? "disabled" : "secondary"}
              border={estudoTypePessoa ? true : false}
              borderRadius={40}
              onClick={() => {
                setEstudoTypePessoa(false);
              }}
            />
          </div>
          <Divider />
          <AreaList
            polygon={polygon}
            setPolygon={setPolygon}
            areaList={areaList}
            setAreaList={setAreaList}
            selectedArea={selectedArea}
            setSelectedArea={setSelectedArea}
            shapeSelectedToEdition={shapeSelectedToEdition}
          />

          <Divider />

          {estudoTypePessoa ? (
            <DadosDasPessoas graphicsData={graphicsData} />
          ) : (
            <DadosDasEmpresas
              polygon={polygon}
              areaList={areaList}
              markers={markers}
              setMarkers={setMarkers}
              CNAEPesquisado={CNAEPesquisado}
              setCNAEPesquisado={setCNAEPesquisado}
              selectedArea={selectedArea}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              totalStudyResults={totalStudyResults}
              updateStudyResults={updateStudyResults}
              updateStudyResultsCnaes={updateStudyResultsCnaes}
            />
          )}
        </Tab>
      ) : (
        <div
          style={{
            display: "flex",
          }}
        >
          <ButtonDefault
            icon={<FaChartPie />}
            color={"primary"}
            onClick={() => {
              setOpen(!open);
            }}
          />
        </div>
      )}
    </div>
  );
}
