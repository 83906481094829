import { AreaListData } from "../Preview/components/AreaListData";
import {
  CompleteRDPolygon,
  CompleteReportData,
} from "../Preview/components/graphs/graphTSInterface";
import { AreaInformations } from "./components/AreaInformations";
import { SubTitle } from "../../../../components/typography/SubTitle";

interface ReportAreasProps {
  areaList: AreaListData[];
  graphicsData: CompleteReportData;
  addressFormat: Function;
}

export function ReportAreas({
  graphicsData,
  areaList,
  addressFormat,
}: ReportAreasProps) {
  const todasfiguras: CompleteRDPolygon[] = [
    ...graphicsData.radiusList,
    ...graphicsData.rectangleList,
    ...graphicsData.polygonList,
  ];

  function getRelatedArea(id: number): AreaListData | null {
    for (let index = 0; index < areaList.length; index++) {
      const element = areaList[index];
      if (element.id === id) {
        return element;
      }
    }
    return null;
  }

  return (
    <div
      id="areaList"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: 410,
          paddingTop: "30px",
        }}
      >
        <SubTitle style={{ color: "#459CA0" }}>Cor e Nome da Área</SubTitle>
        <SubTitle style={{ color: "#459CA0" }}>Endereço</SubTitle>
        <SubTitle style={{ color: "#459CA0" }}>Formato</SubTitle>
        <SubTitle style={{ color: "#459CA0" }}>Área em Km²</SubTitle>
      </div>

      {todasfiguras.map((reportarea, index) => {
        const relArea = getRelatedArea(reportarea.index);

        if (relArea === null) return <></>;
        const label = relArea!.label;
        const areakm = String(Math.floor(reportarea.area * 100) / 100) + " km²";
        const type = relArea!.studyType;
        const color = relArea!.cor;

        return (
          <div key={index} style={{ width: "800px", maxWidth: "800px" }}>
            <AreaInformations
              ikey={index}
              name={"Área " + (index + 1)}
              studyType={type}
              color={color}
              informations={[
                reportarea.centroid === "Nao encontrado"
                  ? "Endereço não encontrado"
                  : addressFormat(reportarea.centroid),
                label,
                areakm,
              ]}
            />
          </div>
        );
      })}
    </div>
  );
}
