import { Icon } from "../../../../../components/icon/Icon";
import { FaTrash } from "react-icons/fa";
import { ButtonDefault } from "../../../../../components/Button/ButtonDefault";
import { PentagonIcon } from "../../../../../components/icon/PentagonIcon";
import { colors } from "../../../../../assets/colors";
import { PiCircleDuotone, PiSquareDuotone } from "react-icons/pi";
import { SubTitle } from "../../../../../components/typography/SubTitle";

interface AreaTagProps {
  studyType?: string;
  color?: string;
  selected: boolean;
  name: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onClickDelete?: () => void;
}

export function AreaTag({
  color,
  selected,
  studyType,
  name,
  onClick,
  onClickDelete,
}: AreaTagProps) {
  const typeColor = selected ? "secondary" : "disabled";
  const contentColor: string = colors.default[typeColor].content;
  const backgroundColor: string = colors.default[typeColor].background;
  const icon =
    studyType !== "RADIUS_STUDY" ? (
      studyType === "RECTANGLE_STUDY" ? (
        <PiSquareDuotone size={20} />
      ) : (
        <PentagonIcon size={20} />
      )
    ) : (
      <PiCircleDuotone size={20} />
    );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: backgroundColor,
        border: `1px solid ${contentColor}`,
        borderRadius: 20,
        width: 126,
        height: 35,
        paddingRight: 5,
        marginBottom: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          paddingLeft: 10,
          width: "100%",
          height: "100%",
        }}
        onClick={onClick}
      >
        <Icon icon={icon} color={color} />
        <SubTitle
          style={{
            fontSize: 12,
            marginLeft: 5,
            marginRight: 5,
          }}
          color={color}
        >
          {name}
        </SubTitle>
      </div>
      <div
        style={{
          width: 1,
          borderRadius: 1,
          marginRight: 3,
          height: "80%",
          backgroundColor: contentColor,
        }}
      />
      <ButtonDefault
        style={{
          borderRadius: "50%",
        }}
        size="medium"
        transparent
        icon={<FaTrash />}
        color={typeColor}
        onClick={onClickDelete}
      />
    </div>
  );
}
