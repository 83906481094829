import { useAuth } from "../../../../contexts/AuthContext";
import { UserToken } from "../../../../services/userToken";

interface HeaderProps {
  tabSelected: "MAPA" | "EMPRESA" | "NOTÍCIAS" | "ADMINISTRAÇÃO";
  setTabSelected: React.Dispatch<
    React.SetStateAction<"MAPA" | "EMPRESA" | "NOTÍCIAS" | "ADMINISTRAÇÃO">
  >;
}

export function Header({ setTabSelected, tabSelected }: HeaderProps) {
  const { logout, user } = useAuth();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#F2F2F2",
      }}
    >
      <div
        style={{
          alignSelf: "center",
          color: "#3A3A3A",
          fontWeight: "bolder",
          fontSize: 24,
          paddingLeft: "30px",
          paddingTop: "15px",
        }}
      >
        <img src="deepen_logo.png" alt="DEEPEN LIGHT" height="40px" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "40%",
        }}
      >
        <div
          onClick={() => {
            setTabSelected("MAPA");
          }}
          style={{
            alignSelf: "center",
            color: tabSelected === "MAPA" ? "#004257" : "#B2B2B2",
            fontWeight: "bold",
            fontSize: 16,
            paddingLeft: 10,
            paddingRight: 10,
            cursor: "pointer",
          }}
        >
          MAPA
        </div>
        <div
          onClick={() => {
            setTabSelected("EMPRESA");
          }}
          style={{
            alignSelf: "center",
            color: tabSelected === "EMPRESA" ? "#004257" : "#B2B2B2",
            fontWeight: "bold",
            fontSize: 16,
            paddingLeft: 10,
            paddingRight: 10,
            cursor: "pointer",
          }}
        >
          EMPRESAS
        </div>
        {/*
          É para deixar comentado enquanto não for implementado.

        <div
          onClick={() => {
            setTabSelected("NOTÍCIAS");
          }}
          style={{
            alignSelf: "center",
            color: tabSelected === "NOTÍCIAS" ? "#004257" : "#B2B2B2",
            fontWeight: "bold",
            fontSize: 16,
            paddingLeft: 10,
            paddingRight: 10,
            cursor: "pointer",
          }}
        >
          NOTÍCIAS
        </div>

        */}
        <div
          onClick={() => {
            setTabSelected("ADMINISTRAÇÃO");
          }}
          style={{
            alignSelf: "center",
            color: tabSelected === "ADMINISTRAÇÃO" ? "#004257" : "#B2B2B2",
            fontWeight: "bold",
            fontSize: 16,
            paddingLeft: 10,
            paddingRight: 10,
            cursor: "pointer",
          }}
        >
          ADMINISTRAÇÃO
        </div>
      </div>
      <div className="dropdown" style={{ float: "right" }}>
        <button
          className="dropbtn"
          style={{
            color: "#3A3A3A",
            fontSize: 16,
            fontWeight: "bold",
            paddingTop: "20px",
            backgroundColor: "transparent",
          }}
        >
          {user?.username}
        </button>
        <div className="dropdown-content">
          <button
            style={{
              width: "100%",
              borderWidth: "0px",
              height: "50px",
              justifyContent: "center",
              textAlign: "center",
            }}
            onClick={() => {
              logout();
            }}
          >
            Sair
          </button>
        </div>
      </div>
    </div>
  );
}
