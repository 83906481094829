import { HiMenu } from "react-icons/hi";
import { ButtonDefault } from "../../../../components/Button/ButtonDefault";
import { Search } from "./Search/Search";
import { PointsOfInterest } from "./PointsOfInterest/PointsOfInterest";
import { GeometricShapes } from "./GeometricShapes/GeometricShapes";
import { SaveLoadModal } from "../SaveLoad";

export function ToolsLayer(props) {
  const {
    shapeActive,
    setShapeActive,
    polygon,
    setPolygon,
    setMarkersGoogle,
    setCenter,
    setNewSearch,
    processing,
    hasContent,
  } = props;
  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        padding: "25px 25px 0px 25px",
        flexDirection: "column",
        alignItems: "flex-start",
        zIndex: "1000",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <ButtonDefault icon={<HiMenu />} color={"primary"} onClick={() => {}} />
        <Search setCenter={setCenter} setNewSearch={setNewSearch} />
        <SaveLoadModal
          polygonParams={polygon}
          setPolygon={setPolygon}
          updatefunction={polygon.updatefunction}
        />
        <PointsOfInterest
          hasContent={hasContent}
          polygonParams={polygon}
          setPolygon={setPolygon}
          setMarkersGoogle={setMarkersGoogle}
        />
        <GeometricShapes
          polygon={polygon}
          processing={processing}
          shapeActive={shapeActive}
          setShapeActive={setShapeActive}
        />
      </div>
    </div>
  );
}
