export interface AreaListData {
  label: string;
  cor: string;
  studyType: string;
  points: number[];
  id: number;
}

export function getRelatedArea(
  id: number,
  areaList: AreaListData[]
): AreaListData {
  for (let index = 0; index < areaList.length; index++) {
    const element = areaList[index];
    if (element.id === id) {
      return element;
    }
  }
  return {
    label: "",
    cor: "",
    studyType: "",
    points: [],
    id: 0,
  };
}
