import React from "react";

interface props {
  fill?: string;
}

export const MarkIcon = ({ fill }: props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox={"0 0 17 24"}
      fill={fill}
    >
      <path
        d="M1.421,1.5A1.5,1.5,0,0,1,2.92,0h12a1.5,1.5,0,0,1,0,3H13.534l.534,6.947a7.876,7.876,0,0,1,3.725,4.439l.047.141a1.491,1.491,0,0,1-.206,1.35,1.507,1.507,0,0,1-1.218.623H1.421A1.5,1.5,0,0,1,.2,15.877,1.482,1.482,0,0,1,0,14.527l.047-.141A7.876,7.876,0,0,1,3.768,9.947L4.3,3H2.92A1.5,1.5,0,0,1,1.421,1.5Zm6,16.5h3v4.5a1.5,1.5,0,1,1-3,0Z"
        transform="translate(0.082)"
      />
    </svg>
  );
};
