import { CSSProperties } from "react";
import { colors } from "../../assets/colors";
import "./Checkbox.css";

interface Checkbox2Props {
  label: string;
  onChange?: () => void;
  checked: boolean;
  color: string;
  style?: CSSProperties;
}

const grayColor = colors.default.disabled.content;

export function Checkbox2({
  label,
  onChange,
  checked,
  color,
  style,
}: Checkbox2Props) {
  return (
    <div
      className="container-checkbox2"
      style={{
        width: "100%",
        height: 48,
        fontSize: 16,
        padding: 2,
        paddingLeft: 24,
        borderRadius: 5,
        border: `1px solid ${grayColor}`,
        outline: "none",
        ...style,
      }}
    >
      <input
        style={{
          color: color,
          borderColor: color,
        }}
        type="checkbox"
        id={label}
        name={label}
        checked={checked ? checked : false}
        readOnly
        onChange={() => {
          onChange && onChange();
        }}
      />
      <label
        style={{
          fontSize: 16,
          color: colors.checked,
        }}
        htmlFor={label}
      >
        {label}
      </label>
    </div>
  );
}
