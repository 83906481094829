interface County {
  municipio: string;
}

interface State {
  estado: string;
  municipios: County[];
}

export const estadosMunicipios: State[] = [
  {
    municipios: [
      { municipio: "ALTO ALEGRE" },
      { municipio: "AMAJARI" },
      { municipio: "BOA VISTA" },
      { municipio: "BONFIM" },
      { municipio: "CANTÁ" },
      { municipio: "CARACARAÍ" },
      { municipio: "CAROEBE" },
      { municipio: "IRACEMA" },
      { municipio: "MUCAJAÍ" },
      { municipio: "NORMANDIA" },
      { municipio: "PACARAIMA" },
      { municipio: "RORAINÓPOLIS" },
      { municipio: "SÃO JOÃO DA BALIZA" },
      { municipio: "SÃO LUIZ" },
      { municipio: "UIRAMUTÃ" },
    ],
    estado: "RR",
  },
  {
    municipios: [
      { municipio: "ACEGUÁ" },
      { municipio: "ÁGUA SANTA" },
      { municipio: "AGUDO" },
      { municipio: "AJURICABA" },
      { municipio: "ALECRIM" },
      { municipio: "ALEGRETE" },
      { municipio: "ALEGRIA" },
      { municipio: "ALMIRANTE TAMANDARÉ DO SUL" },
      { municipio: "ALPESTRE" },
      { municipio: "ALTO ALEGRE" },
      { municipio: "ALTO FELIZ" },
      { municipio: "ALVORADA" },
      { municipio: "AMARAL FERRADOR" },
      { municipio: "AMETISTA DO SUL" },
      { municipio: "ANDRÉ DA ROCHA" },
      { municipio: "ANTA GORDA" },
      { municipio: "ANTÔNIO PRADO" },
      { municipio: "ARAMBARÉ" },
      { municipio: "ARARICÁ" },
      { municipio: "ARATIBA" },
      { municipio: "ARROIO DO MEIO" },
      { municipio: "ARROIO DO PADRE" },
      { municipio: "ARROIO DO SAL" },
      { municipio: "ARROIO DO TIGRE" },
      { municipio: "ARROIO DOS RATOS" },
      { municipio: "ARROIO GRANDE" },
      { municipio: "ARVOREZINHA" },
      { municipio: "AUGUSTO PESTANA" },
      { municipio: "ÁUREA" },
      { municipio: "BAGÉ" },
      { municipio: "BALNEÁRIO PINHAL" },
      { municipio: "BARÃO" },
      { municipio: "BARÃO DE COTEGIPE" },
      { municipio: "BARÃO DO TRIUNFO" },
      { municipio: "BARRA DO GUARITA" },
      { municipio: "BARRA DO QUARAÍ" },
      { municipio: "BARRA DO RIBEIRO" },
      { municipio: "BARRA DO RIO AZUL" },
      { municipio: "BARRA FUNDA" },
      { municipio: "BARRACÃO" },
      { municipio: "BARROS CASSAL" },
      { municipio: "BENJAMIN CONSTANT DO SUL" },
      { municipio: "BENTO GONÇALVES" },
      { municipio: "BOA VISTA DAS MISSÕES" },
      { municipio: "BOA VISTA DO BURICÁ" },
      { municipio: "BOA VISTA DO CADEADO" },
      { municipio: "BOA VISTA DO INCRA" },
      { municipio: "BOA VISTA DO SUL" },
      { municipio: "BOM JESUS" },
      { municipio: "BOM PRINCÍPIO" },
      { municipio: "BOM PROGRESSO" },
      { municipio: "BOM RETIRO DO SUL" },
      { municipio: "BOQUEIRÃO DO LEÃO" },
      { municipio: "BOSSOROCA" },
      { municipio: "BOZANO" },
      { municipio: "BRAGA" },
      { municipio: "BROCHIER" },
      { municipio: "BUTIÁ" },
      { municipio: "CAÇAPAVA DO SUL" },
      { municipio: "CACEQUI" },
      { municipio: "CACHOEIRA DO SUL" },
      { municipio: "CACHOEIRINHA" },
      { municipio: "CACIQUE DOBLE" },
      { municipio: "CAIBATÉ" },
      { municipio: "CAIÇARA" },
      { municipio: "CAMAQUÃ" },
      { municipio: "CAMARGO" },
      { municipio: "CAMBARÁ DO SUL" },
      { municipio: "CAMPESTRE DA SERRA" },
      { municipio: "CAMPINA DAS MISSÕES" },
      { municipio: "CAMPINAS DO SUL" },
      { municipio: "CAMPO BOM" },
      { municipio: "CAMPO NOVO" },
      { municipio: "CAMPOS BORGES" },
      { municipio: "CANDELÁRIA" },
      { municipio: "CÂNDIDO GODÓI" },
      { municipio: "CANDIOTA" },
      { municipio: "CANELA" },
      { municipio: "CANGUÇU" },
      { municipio: "CANOAS" },
      { municipio: "CANUDOS DO VALE" },
      { municipio: "CAPÃO BONITO DO SUL" },
      { municipio: "CAPÃO DA CANOA" },
      { municipio: "CAPÃO DO CIPÓ" },
      { municipio: "CAPÃO DO LEÃO" },
      { municipio: "CAPELA DE SANTANA" },
      { municipio: "CAPITÃO" },
      { municipio: "CAPIVARI DO SUL" },
      { municipio: "CARAÁ" },
      { municipio: "CARAZINHO" },
      { municipio: "CARLOS BARBOSA" },
      { municipio: "CARLOS GOMES" },
      { municipio: "CASCA" },
      { municipio: "CASEIROS" },
      { municipio: "CATUÍPE" },
      { municipio: "CAXIAS DO SUL" },
      { municipio: "CENTENÁRIO" },
      { municipio: "CERRITO" },
      { municipio: "CERRO BRANCO" },
      { municipio: "CERRO GRANDE" },
      { municipio: "CERRO GRANDE DO SUL" },
      { municipio: "CERRO LARGO" },
      { municipio: "CHAPADA" },
      { municipio: "CHARQUEADAS" },
      { municipio: "CHARRUA" },
      { municipio: "CHIAPETTA" },
      { municipio: "CHUÍ" },
      { municipio: "CHUVISCA" },
      { municipio: "CIDREIRA" },
      { municipio: "CIRÍACO" },
      { municipio: "COLINAS" },
      { municipio: "COLORADO" },
      { municipio: "CONDOR" },
      { municipio: "CONSTANTINA" },
      { municipio: "COQUEIRO BAIXO" },
      { municipio: "COQUEIROS DO SUL" },
      { municipio: "CORONEL BARROS" },
      { municipio: "CORONEL BICACO" },
      { municipio: "CORONEL PILAR" },
      { municipio: "COTIPORÃ" },
      { municipio: "COXILHA" },
      { municipio: "CRISSIUMAL" },
      { municipio: "CRISTAL" },
      { municipio: "CRISTAL DO SUL" },
      { municipio: "CRUZ ALTA" },
      { municipio: "CRUZALTENSE" },
      { municipio: "CRUZEIRO DO SUL" },
      { municipio: "DAVID CANABARRO" },
      { municipio: "DERRUBADAS" },
      { municipio: "DEZESSEIS DE NOVEMBRO" },
      { municipio: "DILERMANDO DE AGUIAR" },
      { municipio: "DOIS IRMÃOS" },
      { municipio: "DOIS IRMÃOS DAS MISSÕES" },
      { municipio: "DOIS LAJEADOS" },
      { municipio: "DOM FELICIANO" },
      { municipio: "DOM PEDRITO" },
      { municipio: "DOM PEDRO DE ALCÂNTARA" },
      { municipio: "DONA FRANCISCA" },
      { municipio: "DOUTOR MAURÍCIO CARDOSO" },
      { municipio: "DOUTOR RICARDO" },
      { municipio: "ELDORADO DO SUL" },
      { municipio: "ENCANTADO" },
      { municipio: "ENCRUZILHADA DO SUL" },
      { municipio: "ENGENHO VELHO" },
      { municipio: "ENTRE RIOS DO SUL" },
      { municipio: "ENTRE-IJUÍS" },
      { municipio: "EREBANGO" },
      { municipio: "ERECHIM" },
      { municipio: "ERNESTINA" },
      { municipio: "ERVAL GRANDE" },
      { municipio: "ERVAL SECO" },
      { municipio: "ESMERALDA" },
      { municipio: "ESPERANÇA DO SUL" },
      { municipio: "ESPUMOSO" },
      { municipio: "ESTAÇÃO" },
      { municipio: "ESTÂNCIA VELHA" },
      { municipio: "ESTEIO" },
      { municipio: "ESTRELA" },
      { municipio: "ESTRELA VELHA" },
      { municipio: "EUGÊNIO DE CASTRO" },
      { municipio: "FAGUNDES VARELA" },
      { municipio: "FARROUPILHA" },
      { municipio: "FAXINAL DO SOTURNO" },
      { municipio: "FAXINALZINHO" },
      { municipio: "FAZENDA VILANOVA" },
      { municipio: "FELIZ" },
      { municipio: "FLORES DA CUNHA" },
      { municipio: "FLORIANO PEIXOTO" },
      { municipio: "FONTOURA XAVIER" },
      { municipio: "FORMIGUEIRO" },
      { municipio: "FORQUETINHA" },
      { municipio: "FORTALEZA DOS VALOS" },
      { municipio: "FREDERICO WESTPHALEN" },
      { municipio: "GARIBALDI" },
      { municipio: "GARRUCHOS" },
      { municipio: "GAURAMA" },
      { municipio: "GENERAL CÂMARA" },
      { municipio: "GENTIL" },
      { municipio: "GETÚLIO VARGAS" },
      { municipio: "GIRUÁ" },
      { municipio: "GLORINHA" },
      { municipio: "GRAMADO" },
      { municipio: "GRAMADO DOS LOUREIROS" },
      { municipio: "GRAMADO XAVIER" },
      { municipio: "GRAVATAÍ" },
      { municipio: "GUABIJU" },
      { municipio: "GUAÍBA" },
      { municipio: "GUAPORÉ" },
      { municipio: "GUARANI DAS MISSÕES" },
      { municipio: "HARMONIA" },
      { municipio: "HERVAL" },
      { municipio: "HERVEIRAS" },
      { municipio: "HORIZONTINA" },
      { municipio: "HULHA NEGRA" },
      { municipio: "HUMAITÁ" },
      { municipio: "IBARAMA" },
      { municipio: "IBIAÇÁ" },
      { municipio: "IBIRAIARAS" },
      { municipio: "IBIRAPUITÃ" },
      { municipio: "IBIRUBÁ" },
      { municipio: "IGREJINHA" },
      { municipio: "IJUÍ" },
      { municipio: "ILÓPOLIS" },
      { municipio: "IMBÉ" },
      { municipio: "IMIGRANTE" },
      { municipio: "INDEPENDÊNCIA" },
      { municipio: "INHACORÁ" },
      { municipio: "IPÊ" },
      { municipio: "IPIRANGA DO SUL" },
      { municipio: "IRAÍ" },
      { municipio: "ITAARA" },
      { municipio: "ITACURUBI" },
      { municipio: "ITAPUCA" },
      { municipio: "ITAQUI" },
      { municipio: "ITATI" },
      { municipio: "ITATIBA DO SUL" },
      { municipio: "IVORÁ" },
      { municipio: "IVOTI" },
      { municipio: "JABOTICABA" },
      { municipio: "JACUIZINHO" },
      { municipio: "JACUTINGA" },
      { municipio: "JAGUARÃO" },
      { municipio: "JAGUARI" },
      { municipio: "JAQUIRANA" },
      { municipio: "JARI" },
      { municipio: "JÓIA" },
      { municipio: "JÚLIO DE CASTILHOS" },
      { municipio: "LAGOA BONITA DO SUL" },
      { municipio: "LAGOA DOS TRÊS CANTOS" },
      { municipio: "LAGOA VERMELHA" },
      { municipio: "LAGOÃO" },
      { municipio: "LAJEADO" },
      { municipio: "LAJEADO DO BUGRE" },
      { municipio: "LAVRAS DO SUL" },
      { municipio: "LIBERATO SALZANO" },
      { municipio: "LINDOLFO COLLOR" },
      { municipio: "LINHA NOVA" },
      { municipio: "MAÇAMBARÁ" },
      { municipio: "MACHADINHO" },
      { municipio: "MAMPITUBA" },
      { municipio: "MANOEL VIANA" },
      { municipio: "MAQUINÉ" },
      { municipio: "MARATÁ" },
      { municipio: "MARAU" },
      { municipio: "MARCELINO RAMOS" },
      { municipio: "MARIANA PIMENTEL" },
      { municipio: "MARIANO MORO" },
      { municipio: "MARQUES DE SOUZA" },
      { municipio: "MATA" },
      { municipio: "MATO CASTELHANO" },
      { municipio: "MATO LEITÃO" },
      { municipio: "MATO QUEIMADO" },
      { municipio: "MAXIMILIANO DE ALMEIDA" },
      { municipio: "MINAS DO LEÃO" },
      { municipio: "MIRAGUAÍ" },
      { municipio: "MONTAURI" },
      { municipio: "MONTE ALEGRE DOS CAMPOS" },
      { municipio: "MONTE BELO DO SUL" },
      { municipio: "MONTENEGRO" },
      { municipio: "MORMAÇO" },
      { municipio: "MORRINHOS DO SUL" },
      { municipio: "MORRO REDONDO" },
      { municipio: "MORRO REUTER" },
      { municipio: "MOSTARDAS" },
      { municipio: "MUÇUM" },
      { municipio: "MUITOS CAPÕES" },
      { municipio: "MULITERNO" },
      { municipio: "NÃO-ME-TOQUE" },
      { municipio: "NICOLAU VERGUEIRO" },
      { municipio: "NONOAI" },
      { municipio: "NOVA ALVORADA" },
      { municipio: "NOVA ARAÇÁ" },
      { municipio: "NOVA BASSANO" },
      { municipio: "NOVA BOA VISTA" },
      { municipio: "NOVA BRÉSCIA" },
      { municipio: "NOVA CANDELÁRIA" },
      { municipio: "NOVA ESPERANÇA DO SUL" },
      { municipio: "NOVA HARTZ" },
      { municipio: "NOVA PÁDUA" },
      { municipio: "NOVA PALMA" },
      { municipio: "NOVA PETRÓPOLIS" },
      { municipio: "NOVA PRATA" },
      { municipio: "NOVA RAMADA" },
      { municipio: "NOVA ROMA DO SUL" },
      { municipio: "NOVA SANTA RITA" },
      { municipio: "NOVO BARREIRO" },
      { municipio: "NOVO CABRAIS" },
      { municipio: "NOVO HAMBURGO" },
      { municipio: "NOVO MACHADO" },
      { municipio: "NOVO TIRADENTES" },
      { municipio: "NOVO XINGU" },
      { municipio: "OSÓRIO" },
      { municipio: "PAIM FILHO" },
      { municipio: "PALMARES DO SUL" },
      { municipio: "PALMEIRA DAS MISSÕES" },
      { municipio: "PALMITINHO" },
      { municipio: "PANAMBI" },
      { municipio: "PANTANO GRANDE" },
      { municipio: "PARAÍ" },
      { municipio: "PARAÍSO DO SUL" },
      { municipio: "PARECI NOVO" },
      { municipio: "PAROBÉ" },
      { municipio: "PASSA SETE" },
      { municipio: "PASSO DO SOBRADO" },
      { municipio: "PASSO FUNDO" },
      { municipio: "PAULO BENTO" },
      { municipio: "PAVERAMA" },
      { municipio: "PEDRAS ALTAS" },
      { municipio: "PEDRO OSÓRIO" },
      { municipio: "PEJUÇARA" },
      { municipio: "PELOTAS" },
      { municipio: "PICADA CAFÉ" },
      { municipio: "PINHAL" },
      { municipio: "PINHAL DA SERRA" },
      { municipio: "PINHAL GRANDE" },
      { municipio: "PINHEIRINHO DO VALE" },
      { municipio: "PINHEIRO MACHADO" },
      { municipio: "PINTO BANDEIRA" },
      { municipio: "PIRAPÓ" },
      { municipio: "PIRATINI" },
      { municipio: "PLANALTO" },
      { municipio: "POÇO DAS ANTAS" },
      { municipio: "PONTÃO" },
      { municipio: "PONTE PRETA" },
      { municipio: "PORTÃO" },
      { municipio: "PORTO ALEGRE" },
      { municipio: "PORTO LUCENA" },
      { municipio: "PORTO MAUÁ" },
      { municipio: "PORTO VERA CRUZ" },
      { municipio: "PORTO XAVIER" },
      { municipio: "POUSO NOVO" },
      { municipio: "PRESIDENTE LUCENA" },
      { municipio: "PROGRESSO" },
      { municipio: "PROTÁSIO ALVES" },
      { municipio: "PUTINGA" },
      { municipio: "QUARAÍ" },
      { municipio: "QUATRO IRMÃOS" },
      { municipio: "QUEVEDOS" },
      { municipio: "QUINZE DE NOVEMBRO" },
      { municipio: "REDENTORA" },
      { municipio: "RELVADO" },
      { municipio: "RESTINGA SÊCA" },
      { municipio: "RIO DOS ÍNDIOS" },
      { municipio: "RIO GRANDE" },
      { municipio: "RIO PARDO" },
      { municipio: "RIOZINHO" },
      { municipio: "ROCA SALES" },
      { municipio: "RODEIO BONITO" },
      { municipio: "ROLADOR" },
      { municipio: "ROLANTE" },
      { municipio: "RONDA ALTA" },
      { municipio: "RONDINHA" },
      { municipio: "ROQUE GONZALES" },
      { municipio: "ROSÁRIO DO SUL" },
      { municipio: "SAGRADA FAMÍLIA" },
      { municipio: "SALDANHA MARINHO" },
      { municipio: "SALTO DO JACUÍ" },
      { municipio: "SALVADOR DAS MISSÕES" },
      { municipio: "SALVADOR DO SUL" },
      { municipio: "SANANDUVA" },
      { municipio: "SANTA BÁRBARA DO SUL" },
      { municipio: "SANTA CECÍLIA DO SUL" },
      { municipio: "SANTA CLARA DO SUL" },
      { municipio: "SANTA CRUZ DO SUL" },
      { municipio: "SANTA MARGARIDA DO SUL" },
      { municipio: "SANTA MARIA" },
      { municipio: "SANTA MARIA DO HERVAL" },
      { municipio: "SANTA ROSA" },
      { municipio: "SANTA TEREZA" },
      { municipio: "SANTA VITÓRIA DO PALMAR" },
      { municipio: "SANTANA DA BOA VISTA" },
      { municipio: "SANT'ANA DO LIVRAMENTO" },
      { municipio: "SANTANA DO LIVRAMENTO" },
      { municipio: "SANTIAGO" },
      { municipio: "SANTO ÂNGELO" },
      { municipio: "SANTO ANTÔNIO DA PATRULHA" },
      { municipio: "SANTO ANTÔNIO DAS MISSÕES" },
      { municipio: "SANTO ANTÔNIO DO PALMA" },
      { municipio: "SANTO ANTÔNIO DO PLANALTO" },
      { municipio: "SANTO AUGUSTO" },
      { municipio: "SANTO CRISTO" },
      { municipio: "SANTO EXPEDITO DO SUL" },
      { municipio: "SÃO BORJA" },
      { municipio: "SÃO DOMINGOS DO SUL" },
      { municipio: "SÃO FRANCISCO DE ASSIS" },
      { municipio: "SÃO FRANCISCO DE PAULA" },
      { municipio: "SÃO GABRIEL" },
      { municipio: "SÃO JERÔNIMO" },
      { municipio: "SÃO JOÃO DA URTIGA" },
      { municipio: "SÃO JOÃO DO POLÊSINE" },
      { municipio: "SÃO JORGE" },
      { municipio: "SÃO JOSÉ DAS MISSÕES" },
      { municipio: "SÃO JOSÉ DO HERVAL" },
      { municipio: "SÃO JOSÉ DO HORTÊNCIO" },
      { municipio: "SÃO JOSÉ DO INHACORÁ" },
      { municipio: "SÃO JOSÉ DO NORTE" },
      { municipio: "SÃO JOSÉ DO OURO" },
      { municipio: "SÃO JOSÉ DO SUL" },
      { municipio: "SÃO JOSÉ DOS AUSENTES" },
      { municipio: "SÃO LEOPOLDO" },
      { municipio: "SÃO LOURENÇO DO SUL" },
      { municipio: "SÃO LUIZ GONZAGA" },
      { municipio: "SÃO MARCOS" },
      { municipio: "SÃO MARTINHO" },
      { municipio: "SÃO MARTINHO DA SERRA" },
      { municipio: "SÃO MIGUEL DAS MISSÕES" },
      { municipio: "SÃO NICOLAU" },
      { municipio: "SÃO PAULO DAS MISSÕES" },
      { municipio: "SÃO PEDRO DA SERRA" },
      { municipio: "SÃO PEDRO DAS MISSÕES" },
      { municipio: "SÃO PEDRO DO BUTIÁ" },
      { municipio: "SÃO PEDRO DO SUL" },
      { municipio: "SÃO SEBASTIÃO DO CAÍ" },
      { municipio: "SÃO SEPÉ" },
      { municipio: "SÃO VALENTIM" },
      { municipio: "SÃO VALENTIM DO SUL" },
      { municipio: "SÃO VALÉRIO DO SUL" },
      { municipio: "SÃO VENDELINO" },
      { municipio: "SÃO VICENTE DO SUL" },
      { municipio: "SAPIRANGA" },
      { municipio: "SAPUCAIA DO SUL" },
      { municipio: "SARANDI" },
      { municipio: "SEBERI" },
      { municipio: "SEDE NOVA" },
      { municipio: "SEGREDO" },
      { municipio: "SELBACH" },
      { municipio: "SENADOR SALGADO FILHO" },
      { municipio: "SENTINELA DO SUL" },
      { municipio: "SERAFINA CORRÊA" },
      { municipio: "SÉRIO" },
      { municipio: "SERTÃO" },
      { municipio: "SERTÃO SANTANA" },
      { municipio: "SETE DE SETEMBRO" },
      { municipio: "SEVERIANO DE ALMEIDA" },
      { municipio: "SILVEIRA MARTINS" },
      { municipio: "SINIMBU" },
      { municipio: "SOBRADINHO" },
      { municipio: "SOLEDADE" },
      { municipio: "TABAÍ" },
      { municipio: "TAPEJARA" },
      { municipio: "TAPERA" },
      { municipio: "TAPES" },
      { municipio: "TAQUARA" },
      { municipio: "TAQUARI" },
      { municipio: "TAQUARUÇU DO SUL" },
      { municipio: "TAVARES" },
      { municipio: "TENENTE PORTELA" },
      { municipio: "TERRA DE AREIA" },
      { municipio: "TEUTÔNIA" },
      { municipio: "TIO HUGO" },
      { municipio: "TIRADENTES DO SUL" },
      { municipio: "TOROPI" },
      { municipio: "TORRES" },
      { municipio: "TRAMANDAÍ" },
      { municipio: "TRAVESSEIRO" },
      { municipio: "TRÊS ARROIOS" },
      { municipio: "TRÊS CACHOEIRAS" },
      { municipio: "TRÊS COROAS" },
      { municipio: "TRÊS DE MAIO" },
      { municipio: "TRÊS FORQUILHAS" },
      { municipio: "TRÊS PALMEIRAS" },
      { municipio: "TRÊS PASSOS" },
      { municipio: "TRINDADE DO SUL" },
      { municipio: "TRIUNFO" },
      { municipio: "TUCUNDUVA" },
      { municipio: "TUNAS" },
      { municipio: "TUPANCI DO SUL" },
      { municipio: "TUPANCIRETÃ" },
      { municipio: "TUPANDI" },
      { municipio: "TUPARENDI" },
      { municipio: "TURUÇU" },
      { municipio: "UBIRETAMA" },
      { municipio: "UNIÃO DA SERRA" },
      { municipio: "UNISTALDA" },
      { municipio: "URUGUAIANA" },
      { municipio: "VACARIA" },
      { municipio: "VALE DO SOL" },
      { municipio: "VALE REAL" },
      { municipio: "VALE VERDE" },
      { municipio: "VANINI" },
      { municipio: "VENÂNCIO AIRES" },
      { municipio: "VERA CRUZ" },
      { municipio: "VERANÓPOLIS" },
      { municipio: "VESPASIANO CORRÊA" },
      { municipio: "VIADUTOS" },
      { municipio: "VIAMÃO" },
      { municipio: "VICENTE DUTRA" },
      { municipio: "VICTOR GRAEFF" },
      { municipio: "VILA FLORES" },
      { municipio: "VILA LÂNGARO" },
      { municipio: "VILA MARIA" },
      { municipio: "VILA NOVA DO SUL" },
      { municipio: "VISTA ALEGRE" },
      { municipio: "VISTA ALEGRE DO PRATA" },
      { municipio: "VISTA GAÚCHA" },
      { municipio: "VITÓRIA DAS MISSÕES" },
      { municipio: "WESTFÁLIA" },
      { municipio: "XANGRI-LÁ" },
      { municipio: "ENTRE IJUIS" },
      { municipio: "NAO ME TOQUE" },
      { municipio: "XANGRI LA" },
    ],
    estado: "RS",
  },
  {
    municipios: [
      { municipio: "ABATIÁ" },
      { municipio: "ADRIANÓPOLIS" },
      { municipio: "AGUDOS DO SUL" },
      { municipio: "ALMIRANTE TAMANDARÉ" },
      { municipio: "ALTAMIRA DO PARANÁ" },
      { municipio: "ALTO PARAÍSO" },
      { municipio: "ALTO PARANÁ" },
      { municipio: "ALTO PIQUIRI" },
      { municipio: "ALTÔNIA" },
      { municipio: "ALVORADA DO SUL" },
      { municipio: "AMAPORÃ" },
      { municipio: "AMPÉRE" },
      { municipio: "ANAHY" },
      { municipio: "ANDIRÁ" },
      { municipio: "ÂNGULO" },
      { municipio: "ANTONINA" },
      { municipio: "ANTÔNIO OLINTO" },
      { municipio: "APUCARANA" },
      { municipio: "ARAPONGAS" },
      { municipio: "ARAPOTI" },
      { municipio: "ARAPUÃ" },
      { municipio: "ARARUNA" },
      { municipio: "ARAUCÁRIA" },
      { municipio: "ARIRANHA DO IVAÍ" },
      { municipio: "ASSAÍ" },
      { municipio: "ASSIS CHATEAUBRIAND" },
      { municipio: "ASTORGA" },
      { municipio: "ATALAIA" },
      { municipio: "BALSA NOVA" },
      { municipio: "BANDEIRANTES" },
      { municipio: "BARBOSA FERRAZ" },
      { municipio: "BARRA DO JACARÉ" },
      { municipio: "BARRACÃO" },
      { municipio: "BELA VISTA DA CAROBA" },
      { municipio: "BELA VISTA DO PARAÍSO" },
      { municipio: "BITURUNA" },
      { municipio: "BOA ESPERANÇA" },
      { municipio: "BOA ESPERANÇA DO IGUAÇU" },
      { municipio: "BOA VENTURA DE SÃO ROQUE" },
      { municipio: "BOA VISTA DA APARECIDA" },
      { municipio: "BOCAIÚVA DO SUL" },
      { municipio: "BOM JESUS DO SUL" },
      { municipio: "BOM SUCESSO" },
      { municipio: "BOM SUCESSO DO SUL" },
      { municipio: "BORRAZÓPOLIS" },
      { municipio: "BRAGANEY" },
      { municipio: "BRASILÂNDIA DO SUL" },
      { municipio: "CAFEARA" },
      { municipio: "CAFELÂNDIA" },
      { municipio: "CAFEZAL DO SUL" },
      { municipio: "CALIFÓRNIA" },
      { municipio: "CAMBARÁ" },
      { municipio: "CAMBÉ" },
      { municipio: "CAMBIRA" },
      { municipio: "CAMPINA DA LAGOA" },
      { municipio: "CAMPINA DO SIMÃO" },
      { municipio: "CAMPINA GRANDE DO SUL" },
      { municipio: "CAMPO BONITO" },
      { municipio: "CAMPO DO TENENTE" },
      { municipio: "CAMPO LARGO" },
      { municipio: "CAMPO MAGRO" },
      { municipio: "CAMPO MOURÃO" },
      { municipio: "CÂNDIDO DE ABREU" },
      { municipio: "CANDÓI" },
      { municipio: "CANTAGALO" },
      { municipio: "CAPANEMA" },
      { municipio: "CAPITÃO LEÔNIDAS MARQUES" },
      { municipio: "CARAMBEÍ" },
      { municipio: "CARLÓPOLIS" },
      { municipio: "CASCAVEL" },
      { municipio: "CASTRO" },
      { municipio: "CATANDUVAS" },
      { municipio: "CENTENÁRIO DO SUL" },
      { municipio: "CERRO AZUL" },
      { municipio: "CÉU AZUL" },
      { municipio: "CHOPINZINHO" },
      { municipio: "CIANORTE" },
      { municipio: "CIDADE GAÚCHA" },
      { municipio: "CLEVELÂNDIA" },
      { municipio: "COLOMBO" },
      { municipio: "COLORADO" },
      { municipio: "CONGONHINHAS" },
      { municipio: "CONSELHEIRO MAIRINCK" },
      { municipio: "CONTENDA" },
      { municipio: "CORBÉLIA" },
      { municipio: "CORNÉLIO PROCÓPIO" },
      { municipio: "CORONEL DOMINGOS SOARES" },
      { municipio: "CORONEL VIVIDA" },
      { municipio: "CORUMBATAÍ DO SUL" },
      { municipio: "CRUZ MACHADO" },
      { municipio: "CRUZEIRO DO IGUAÇU" },
      { municipio: "CRUZEIRO DO OESTE" },
      { municipio: "CRUZEIRO DO SUL" },
      { municipio: "CRUZMALTINA" },
      { municipio: "CURITIBA" },
      { municipio: "CURIÚVA" },
      { municipio: "DIAMANTE DO NORTE" },
      { municipio: "DIAMANTE DO SUL" },
      { municipio: "DIAMANTE D'OESTE" },
      { municipio: "DOIS VIZINHOS" },
      { municipio: "DOURADINA" },
      { municipio: "DOUTOR CAMARGO" },
      { municipio: "DOUTOR ULYSSES" },
      { municipio: "ENÉAS MARQUES" },
      { municipio: "ENGENHEIRO BELTRÃO" },
      { municipio: "ENTRE RIOS DO OESTE" },
      { municipio: "ESPERANÇA NOVA" },
      { municipio: "ESPIGÃO ALTO DO IGUAÇU" },
      { municipio: "FAROL" },
      { municipio: "FAXINAL" },
      { municipio: "FAZENDA RIO GRANDE" },
      { municipio: "FÊNIX" },
      { municipio: "FERNANDES PINHEIRO" },
      { municipio: "FIGUEIRA" },
      { municipio: "FLOR DA SERRA DO SUL" },
      { municipio: "FLORAÍ" },
      { municipio: "FLORESTA" },
      { municipio: "FLORESTÓPOLIS" },
      { municipio: "FLÓRIDA" },
      { municipio: "FORMOSA DO OESTE" },
      { municipio: "FOZ DO IGUAÇU" },
      { municipio: "FOZ DO JORDÃO" },
      { municipio: "FRANCISCO ALVES" },
      { municipio: "FRANCISCO BELTRÃO" },
      { municipio: "GENERAL CARNEIRO" },
      { municipio: "GODOY MOREIRA" },
      { municipio: "GOIOERÊ" },
      { municipio: "GOIOXIM" },
      { municipio: "GRANDES RIOS" },
      { municipio: "GUAÍRA" },
      { municipio: "GUAIRAÇÁ" },
      { municipio: "GUAMIRANGA" },
      { municipio: "GUAPIRAMA" },
      { municipio: "GUAPOREMA" },
      { municipio: "GUARACI" },
      { municipio: "GUARANIAÇU" },
      { municipio: "GUARAPUAVA" },
      { municipio: "GUARAQUEÇABA" },
      { municipio: "GUARATUBA" },
      { municipio: "HONÓRIO SERPA" },
      { municipio: "IBAITI" },
      { municipio: "IBEMA" },
      { municipio: "IBIPORÃ" },
      { municipio: "ICARAÍMA" },
      { municipio: "IGUARAÇU" },
      { municipio: "IGUATU" },
      { municipio: "IMBAÚ" },
      { municipio: "IMBITUVA" },
      { municipio: "INÁCIO MARTINS" },
      { municipio: "INAJÁ" },
      { municipio: "INDIANÓPOLIS" },
      { municipio: "IPIRANGA" },
      { municipio: "IPORÃ" },
      { municipio: "IRACEMA DO OESTE" },
      { municipio: "IRATI" },
      { municipio: "IRETAMA" },
      { municipio: "ITAGUAJÉ" },
      { municipio: "ITAIPULÂNDIA" },
      { municipio: "ITAMBARACÁ" },
      { municipio: "ITAMBÉ" },
      { municipio: "ITAPEJARA D'OESTE" },
      { municipio: "ITAPERUÇU" },
      { municipio: "ITAÚNA DO SUL" },
      { municipio: "IVAÍ" },
      { municipio: "IVAIPORÃ" },
      { municipio: "IVATÉ" },
      { municipio: "IVATUBA" },
      { municipio: "JABOTI" },
      { municipio: "JACAREZINHO" },
      { municipio: "JAGUAPITÃ" },
      { municipio: "JAGUARIAÍVA" },
      { municipio: "JANDAIA DO SUL" },
      { municipio: "JANIÓPOLIS" },
      { municipio: "JAPIRA" },
      { municipio: "JAPURÁ" },
      { municipio: "JARDIM ALEGRE" },
      { municipio: "JARDIM OLINDA" },
      { municipio: "JATAIZINHO" },
      { municipio: "JESUÍTAS" },
      { municipio: "JOAQUIM TÁVORA" },
      { municipio: "JUNDIAÍ DO SUL" },
      { municipio: "JURANDA" },
      { municipio: "JUSSARA" },
      { municipio: "KALORÉ" },
      { municipio: "LAPA" },
      { municipio: "LARANJAL" },
      { municipio: "LARANJEIRAS DO SUL" },
      { municipio: "LEÓPOLIS" },
      { municipio: "LIDIANÓPOLIS" },
      { municipio: "LINDOESTE" },
      { municipio: "LOANDA" },
      { municipio: "LOBATO" },
      { municipio: "LONDRINA" },
      { municipio: "LUIZIANA" },
      { municipio: "LUNARDELLI" },
      { municipio: "LUPIONÓPOLIS" },
      { municipio: "MALLET" },
      { municipio: "MAMBORÊ" },
      { municipio: "MANDAGUAÇU" },
      { municipio: "MANDAGUARI" },
      { municipio: "MANDIRITUBA" },
      { municipio: "MANFRINÓPOLIS" },
      { municipio: "MANGUEIRINHA" },
      { municipio: "MANOEL RIBAS" },
      { municipio: "MARECHAL CÂNDIDO RONDON" },
      { municipio: "MARIA HELENA" },
      { municipio: "MARIALVA" },
      { municipio: "MARILÂNDIA DO SUL" },
      { municipio: "MARILENA" },
      { municipio: "MARILUZ" },
      { municipio: "MARINGÁ" },
      { municipio: "MARIÓPOLIS" },
      { municipio: "MARIPÁ" },
      { municipio: "MARMELEIRO" },
      { municipio: "MARQUINHO" },
      { municipio: "MARUMBI" },
      { municipio: "MATELÂNDIA" },
      { municipio: "MATINHOS" },
      { municipio: "MATO RICO" },
      { municipio: "MAUÁ DA SERRA" },
      { municipio: "MEDIANEIRA" },
      { municipio: "MERCEDES" },
      { municipio: "MIRADOR" },
      { municipio: "MIRASELVA" },
      { municipio: "MISSAL" },
      { municipio: "MOREIRA SALES" },
      { municipio: "MORRETES" },
      { municipio: "MUNHOZ DE MELO" },
      { municipio: "NOSSA SENHORA DAS GRAÇAS" },
      { municipio: "NOVA ALIANÇA DO IVAÍ" },
      { municipio: "NOVA AMÉRICA DA COLINA" },
      { municipio: "NOVA AURORA" },
      { municipio: "NOVA CANTU" },
      { municipio: "NOVA ESPERANÇA" },
      { municipio: "NOVA ESPERANÇA DO SUDOESTE" },
      { municipio: "NOVA FÁTIMA" },
      { municipio: "NOVA LARANJEIRAS" },
      { municipio: "NOVA LONDRINA" },
      { municipio: "NOVA OLÍMPIA" },
      { municipio: "NOVA PRATA DO IGUAÇU" },
      { municipio: "NOVA SANTA BÁRBARA" },
      { municipio: "NOVA SANTA ROSA" },
      { municipio: "NOVA TEBAS" },
      { municipio: "NOVO ITACOLOMI" },
      { municipio: "ORTIGUEIRA" },
      { municipio: "OURIZONA" },
      { municipio: "OURO VERDE DO OESTE" },
      { municipio: "PAIÇANDU" },
      { municipio: "PALMAS" },
      { municipio: "PALMEIRA" },
      { municipio: "PALMITAL" },
      { municipio: "PALOTINA" },
      { municipio: "PARAÍSO DO NORTE" },
      { municipio: "PARANACITY" },
      { municipio: "PARANAGUÁ" },
      { municipio: "PARANAPOEMA" },
      { municipio: "PARANAVAÍ" },
      { municipio: "PATO BRAGADO" },
      { municipio: "PATO BRANCO" },
      { municipio: "PAULA FREITAS" },
      { municipio: "PAULO FRONTIN" },
      { municipio: "PEABIRU" },
      { municipio: "PEROBAL" },
      { municipio: "PÉROLA" },
      { municipio: "PÉROLA D'OESTE" },
      { municipio: "PIÊN" },
      { municipio: "PINHAIS" },
      { municipio: "PINHAL DE SÃO BENTO" },
      { municipio: "PINHALÃO" },
      { municipio: "PINHÃO" },
      { municipio: "PIRAÍ DO SUL" },
      { municipio: "PIRAQUARA" },
      { municipio: "PITANGA" },
      { municipio: "PITANGUEIRAS" },
      { municipio: "PLANALTINA DO PARANÁ" },
      { municipio: "PLANALTO" },
      { municipio: "PONTA GROSSA" },
      { municipio: "PONTAL DO PARANÁ" },
      { municipio: "PORECATU" },
      { municipio: "PORTO AMAZONAS" },
      { municipio: "PORTO BARREIRO" },
      { municipio: "PORTO RICO" },
      { municipio: "PORTO VITÓRIA" },
      { municipio: "PRADO FERREIRA" },
      { municipio: "PRANCHITA" },
      { municipio: "PRESIDENTE CASTELO BRANCO" },
      { municipio: "PRIMEIRO DE MAIO" },
      { municipio: "PRUDENTÓPOLIS" },
      { municipio: "QUARTO CENTENÁRIO" },
      { municipio: "QUATIGUÁ" },
      { municipio: "QUATRO BARRAS" },
      { municipio: "QUATRO PONTES" },
      { municipio: "QUEDAS DO IGUAÇU" },
      { municipio: "QUERÊNCIA DO NORTE" },
      { municipio: "QUINTA DO SOL" },
      { municipio: "QUITANDINHA" },
      { municipio: "RAMILÂNDIA" },
      { municipio: "RANCHO ALEGRE" },
      { municipio: "RANCHO ALEGRE D'OESTE" },
      { municipio: "REALEZA" },
      { municipio: "REBOUÇAS" },
      { municipio: "RENASCENÇA" },
      { municipio: "RESERVA" },
      { municipio: "RESERVA DO IGUAÇU" },
      { municipio: "RIBEIRÃO CLARO" },
      { municipio: "RIBEIRÃO DO PINHAL" },
      { municipio: "RIO AZUL" },
      { municipio: "RIO BOM" },
      { municipio: "RIO BONITO DO IGUAÇU" },
      { municipio: "RIO BRANCO DO IVAÍ" },
      { municipio: "RIO BRANCO DO SUL" },
      { municipio: "RIO NEGRO" },
      { municipio: "ROLÂNDIA" },
      { municipio: "RONCADOR" },
      { municipio: "RONDON" },
      { municipio: "ROSÁRIO DO IVAÍ" },
      { municipio: "SABÁUDIA" },
      { municipio: "SALGADO FILHO" },
      { municipio: "SALTO DO ITARARÉ" },
      { municipio: "SALTO DO LONTRA" },
      { municipio: "SANTA AMÉLIA" },
      { municipio: "SANTA CECÍLIA DO PAVÃO" },
      { municipio: "SANTA CRUZ DE MONTE CASTELO" },
      { municipio: "SANTA FÉ" },
      { municipio: "SANTA HELENA" },
      { municipio: "SANTA INÊS" },
      { municipio: "SANTA ISABEL DO IVAÍ" },
      { municipio: "SANTA IZABEL DO OESTE" },
      { municipio: "SANTA LÚCIA" },
      { municipio: "SANTA MARIA DO OESTE" },
      { municipio: "SANTA MARIANA" },
      { municipio: "SANTA MÔNICA" },
      { municipio: "SANTA TEREZA DO OESTE" },
      { municipio: "SANTA TEREZINHA DE ITAIPU" },
      { municipio: "SANTANA DO ITARARÉ" },
      { municipio: "SANTO ANTÔNIO DA PLATINA" },
      { municipio: "SANTO ANTÔNIO DO CAIUÁ" },
      { municipio: "SANTO ANTÔNIO DO PARAÍSO" },
      { municipio: "SANTO ANTÔNIO DO SUDOESTE" },
      { municipio: "SANTO INÁCIO" },
      { municipio: "SÃO CARLOS DO IVAÍ" },
      { municipio: "SÃO JERÔNIMO DA SERRA" },
      { municipio: "SÃO JOÃO" },
      { municipio: "SÃO JOÃO DO CAIUÁ" },
      { municipio: "SÃO JOÃO DO IVAÍ" },
      { municipio: "SÃO JOÃO DO TRIUNFO" },
      { municipio: "SÃO JORGE DO IVAÍ" },
      { municipio: "SÃO JORGE DO PATROCÍNIO" },
      { municipio: "SÃO JORGE D'OESTE" },
      { municipio: "SÃO JOSÉ DA BOA VISTA" },
      { municipio: "SÃO JOSÉ DAS PALMEIRAS" },
      { municipio: "SÃO JOSÉ DOS PINHAIS" },
      { municipio: "SÃO MANOEL DO PARANÁ" },
      { municipio: "SÃO MATEUS DO SUL" },
      { municipio: "SÃO MIGUEL DO IGUAÇU" },
      { municipio: "SÃO PEDRO DO IGUAÇU" },
      { municipio: "SÃO PEDRO DO IVAÍ" },
      { municipio: "SÃO PEDRO DO PARANÁ" },
      { municipio: "SÃO SEBASTIÃO DA AMOREIRA" },
      { municipio: "SÃO TOMÉ" },
      { municipio: "SAPOPEMA" },
      { municipio: "SARANDI" },
      { municipio: "SAUDADE DO IGUAÇU" },
      { municipio: "SENGÉS" },
      { municipio: "SERRANÓPOLIS DO IGUAÇU" },
      { municipio: "SERTANEJA" },
      { municipio: "SERTANÓPOLIS" },
      { municipio: "SIQUEIRA CAMPOS" },
      { municipio: "SULINA" },
      { municipio: "TAMARANA" },
      { municipio: "TAMBOARA" },
      { municipio: "TAPEJARA" },
      { municipio: "TAPIRA" },
      { municipio: "TEIXEIRA SOARES" },
      { municipio: "TELÊMACO BORBA" },
      { municipio: "TERRA BOA" },
      { municipio: "TERRA RICA" },
      { municipio: "TERRA ROXA" },
      { municipio: "TIBAGI" },
      { municipio: "TIJUCAS DO SUL" },
      { municipio: "TOLEDO" },
      { municipio: "TOMAZINA" },
      { municipio: "TRÊS BARRAS DO PARANÁ" },
      { municipio: "TUNAS DO PARANÁ" },
      { municipio: "TUNEIRAS DO OESTE" },
      { municipio: "TUPÃSSI" },
      { municipio: "TURVO" },
      { municipio: "UBIRATÃ" },
      { municipio: "UMUARAMA" },
      { municipio: "UNIÃO DA VITÓRIA" },
      { municipio: "UNIFLOR" },
      { municipio: "URAÍ" },
      { municipio: "VENTANIA" },
      { municipio: "VERA CRUZ DO OESTE" },
      { municipio: "VERÊ" },
      { municipio: "VIRMOND" },
      { municipio: "VITORINO" },
      { municipio: "WENCESLAU BRAZ" },
      { municipio: "XAMBRÊ" },
    ],
    estado: "PR",
  },
  { municipios: [{ municipio: "BRASÍLIA" }], estado: "DF" },
  {
    municipios: [
      { municipio: "ABDON BATISTA" },
      { municipio: "ABELARDO LUZ" },
      { municipio: "AGROLÂNDIA" },
      { municipio: "AGRONÔMICA" },
      { municipio: "ÁGUA DOCE" },
      { municipio: "ÁGUAS DE CHAPECÓ" },
      { municipio: "ÁGUAS FRIAS" },
      { municipio: "ÁGUAS MORNAS" },
      { municipio: "ALFREDO WAGNER" },
      { municipio: "ALTO BELA VISTA" },
      { municipio: "ANCHIETA" },
      { municipio: "ANGELINA" },
      { municipio: "ANITA GARIBALDI" },
      { municipio: "ANITÁPOLIS" },
      { municipio: "ANTÔNIO CARLOS" },
      { municipio: "APIÚNA" },
      { municipio: "ARABUTÃ" },
      { municipio: "ARAQUARI" },
      { municipio: "ARARANGUÁ" },
      { municipio: "ARMAZÉM" },
      { municipio: "ARROIO TRINTA" },
      { municipio: "ARVOREDO" },
      { municipio: "ASCURRA" },
      { municipio: "ATALANTA" },
      { municipio: "AURORA" },
      { municipio: "BALNEÁRIO ARROIO DO SILVA" },
      { municipio: "BALNEÁRIO BARRA DO SUL" },
      { municipio: "BALNEÁRIO CAMBORIÚ" },
      { municipio: "BALNEÁRIO GAIVOTA" },
      { municipio: "BALNEÁRIO PIÇARRAS" },
      { municipio: "BALNEÁRIO RINCÃO" },
      { municipio: "BANDEIRANTE" },
      { municipio: "BARRA BONITA" },
      { municipio: "BARRA VELHA" },
      { municipio: "BELA VISTA DO TOLDO" },
      { municipio: "BELMONTE" },
      { municipio: "BENEDITO NOVO" },
      { municipio: "BIGUAÇU" },
      { municipio: "BLUMENAU" },
      { municipio: "BOCAINA DO SUL" },
      { municipio: "BOM JARDIM DA SERRA" },
      { municipio: "BOM JESUS" },
      { municipio: "BOM JESUS DO OESTE" },
      { municipio: "BOM RETIRO" },
      { municipio: "BOMBINHAS" },
      { municipio: "BOTUVERÁ" },
      { municipio: "BRAÇO DO NORTE" },
      { municipio: "BRAÇO DO TROMBUDO" },
      { municipio: "BRUNÓPOLIS" },
      { municipio: "BRUSQUE" },
      { municipio: "CAÇADOR" },
      { municipio: "CAIBI" },
      { municipio: "CALMON" },
      { municipio: "CAMBORIÚ" },
      { municipio: "CAMPO ALEGRE" },
      { municipio: "CAMPO BELO DO SUL" },
      { municipio: "CAMPO ERÊ" },
      { municipio: "CAMPOS NOVOS" },
      { municipio: "CANELINHA" },
      { municipio: "CANOINHAS" },
      { municipio: "CAPÃO ALTO" },
      { municipio: "CAPINZAL" },
      { municipio: "CAPIVARI DE BAIXO" },
      { municipio: "CATANDUVAS" },
      { municipio: "CAXAMBU DO SUL" },
      { municipio: "CELSO RAMOS" },
      { municipio: "CERRO NEGRO" },
      { municipio: "CHAPADÃO DO LAGEADO" },
      { municipio: "CHAPECÓ" },
      { municipio: "COCAL DO SUL" },
      { municipio: "CONCÓRDIA" },
      { municipio: "CORDILHEIRA ALTA" },
      { municipio: "CORONEL FREITAS" },
      { municipio: "CORONEL MARTINS" },
      { municipio: "CORREIA PINTO" },
      { municipio: "CORUPÁ" },
      { municipio: "CRICIÚMA" },
      { municipio: "CUNHA PORÃ" },
      { municipio: "CUNHATAÍ" },
      { municipio: "CURITIBANOS" },
      { municipio: "DESCANSO" },
      { municipio: "DIONÍSIO CERQUEIRA" },
      { municipio: "DONA EMMA" },
      { municipio: "DOUTOR PEDRINHO" },
      { municipio: "ENTRE RIOS" },
      { municipio: "ERMO" },
      { municipio: "ERVAL VELHO" },
      { municipio: "FAXINAL DOS GUEDES" },
      { municipio: "FLOR DO SERTÃO" },
      { municipio: "FLORIANÓPOLIS" },
      { municipio: "FORMOSA DO SUL" },
      { municipio: "FORQUILHINHA" },
      { municipio: "FRAIBURGO" },
      { municipio: "FREI ROGÉRIO" },
      { municipio: "GALVÃO" },
      { municipio: "GAROPABA" },
      { municipio: "GARUVA" },
      { municipio: "GASPAR" },
      { municipio: "GOVERNADOR CELSO RAMOS" },
      { municipio: "GRÃO PARÁ" },
      { municipio: "GRAVATAL" },
      { municipio: "GUABIRUBA" },
      { municipio: "GUARACIABA" },
      { municipio: "GUARAMIRIM" },
      { municipio: "GUARUJÁ DO SUL" },
      { municipio: "GUATAMBÚ" },
      { municipio: "HERVAL D'OESTE" },
      { municipio: "IBIAM" },
      { municipio: "IBICARÉ" },
      { municipio: "IBIRAMA" },
      { municipio: "IÇARA" },
      { municipio: "ILHOTA" },
      { municipio: "IMARUÍ" },
      { municipio: "IMBITUBA" },
      { municipio: "IMBUIA" },
      { municipio: "INDAIAL" },
      { municipio: "IOMERÊ" },
      { municipio: "IPIRA" },
      { municipio: "IPORÃ DO OESTE" },
      { municipio: "IPUAÇU" },
      { municipio: "IPUMIRIM" },
      { municipio: "IRACEMINHA" },
      { municipio: "IRANI" },
      { municipio: "IRATI" },
      { municipio: "IRINEÓPOLIS" },
      { municipio: "ITÁ" },
      { municipio: "ITAIÓPOLIS" },
      { municipio: "ITAJAÍ" },
      { municipio: "ITAPEMA" },
      { municipio: "ITAPIRANGA" },
      { municipio: "ITAPOÁ" },
      { municipio: "ITUPORANGA" },
      { municipio: "JABORÁ" },
      { municipio: "JACINTO MACHADO" },
      { municipio: "JAGUARUNA" },
      { municipio: "JARAGUÁ DO SUL" },
      { municipio: "JARDINÓPOLIS" },
      { municipio: "JOAÇABA" },
      { municipio: "JOINVILLE" },
      { municipio: "JOSÉ BOITEUX" },
      { municipio: "JUPIÁ" },
      { municipio: "LACERDÓPOLIS" },
      { municipio: "LAGES" },
      { municipio: "LAGUNA" },
      { municipio: "LAJEADO GRANDE" },
      { municipio: "LAURENTINO" },
      { municipio: "LAURO MÜLLER" },
      { municipio: "LEBON RÉGIS" },
      { municipio: "LEOBERTO LEAL" },
      { municipio: "LINDÓIA DO SUL" },
      { municipio: "LONTRAS" },
      { municipio: "LUIZ ALVES" },
      { municipio: "LUZERNA" },
      { municipio: "MACIEIRA" },
      { municipio: "MAFRA" },
      { municipio: "MAJOR GERCINO" },
      { municipio: "MAJOR VIEIRA" },
      { municipio: "MARACAJÁ" },
      { municipio: "MARAVILHA" },
      { municipio: "MAREMA" },
      { municipio: "MASSARANDUBA" },
      { municipio: "MATOS COSTA" },
      { municipio: "MELEIRO" },
      { municipio: "MIRIM DOCE" },
      { municipio: "MODELO" },
      { municipio: "MONDAÍ" },
      { municipio: "MONTE CARLO" },
      { municipio: "MONTE CASTELO" },
      { municipio: "MORRO DA FUMAÇA" },
      { municipio: "MORRO GRANDE" },
      { municipio: "NAVEGANTES" },
      { municipio: "NOVA ERECHIM" },
      { municipio: "NOVA ITABERABA" },
      { municipio: "NOVA TRENTO" },
      { municipio: "NOVA VENEZA" },
      { municipio: "NOVO HORIZONTE" },
      { municipio: "ORLEANS" },
      { municipio: "OTACÍLIO COSTA" },
      { municipio: "OURO" },
      { municipio: "OURO VERDE" },
      { municipio: "PAIAL" },
      { municipio: "PAINEL" },
      { municipio: "PALHOÇA" },
      { municipio: "PALMA SOLA" },
      { municipio: "PALMEIRA" },
      { municipio: "PALMITOS" },
      { municipio: "PAPANDUVA" },
      { municipio: "PARAÍSO" },
      { municipio: "PASSO DE TORRES" },
      { municipio: "PASSOS MAIA" },
      { municipio: "PAULO LOPES" },
      { municipio: "PEDRAS GRANDES" },
      { municipio: "PENHA" },
      { municipio: "PERITIBA" },
      { municipio: "PESCARIA BRAVA" },
      { municipio: "PETROLÂNDIA" },
      { municipio: "PINHALZINHO" },
      { municipio: "PINHEIRO PRETO" },
      { municipio: "PIRATUBA" },
      { municipio: "PLANALTO ALEGRE" },
      { municipio: "POMERODE" },
      { municipio: "PONTE ALTA" },
      { municipio: "PONTE ALTA DO NORTE" },
      { municipio: "PONTE SERRADA" },
      { municipio: "PORTO BELO" },
      { municipio: "PORTO UNIÃO" },
      { municipio: "POUSO REDONDO" },
      { municipio: "PRAIA GRANDE" },
      { municipio: "PRESIDENTE CASTELLO BRANCO" },
      { municipio: "PRESIDENTE GETÚLIO" },
      { municipio: "PRESIDENTE NEREU" },
      { municipio: "PRINCESA" },
      { municipio: "QUILOMBO" },
      { municipio: "RANCHO QUEIMADO" },
      { municipio: "RIO DAS ANTAS" },
      { municipio: "RIO DO CAMPO" },
      { municipio: "RIO DO OESTE" },
      { municipio: "RIO DO SUL" },
      { municipio: "RIO DOS CEDROS" },
      { municipio: "RIO FORTUNA" },
      { municipio: "RIO NEGRINHO" },
      { municipio: "RIO RUFINO" },
      { municipio: "RIQUEZA" },
      { municipio: "RODEIO" },
      { municipio: "ROMELÂNDIA" },
      { municipio: "SALETE" },
      { municipio: "SALTINHO" },
      { municipio: "SALTO VELOSO" },
      { municipio: "SANGÃO" },
      { municipio: "SANTA CECÍLIA" },
      { municipio: "SANTA HELENA" },
      { municipio: "SANTA ROSA DE LIMA" },
      { municipio: "SANTA ROSA DO SUL" },
      { municipio: "SANTA TEREZINHA" },
      { municipio: "SANTA TEREZINHA DO PROGRESSO" },
      { municipio: "SANTIAGO DO SUL" },
      { municipio: "SANTO AMARO DA IMPERATRIZ" },
      { municipio: "SÃO BENTO DO SUL" },
      { municipio: "SÃO BERNARDINO" },
      { municipio: "SÃO BONIFÁCIO" },
      { municipio: "SÃO CARLOS" },
      { municipio: "SÃO CRISTÓVÃO DO SUL" },
      { municipio: "SÃO DOMINGOS" },
      { municipio: "SÃO FRANCISCO DO SUL" },
      { municipio: "SÃO JOÃO BATISTA" },
      { municipio: "SÃO JOÃO DO ITAPERIÚ" },
      { municipio: "SÃO JOÃO DO OESTE" },
      { municipio: "SÃO JOÃO DO SUL" },
      { municipio: "SÃO JOAQUIM" },
      { municipio: "SÃO JOSÉ" },
      { municipio: "SÃO JOSÉ DO CEDRO" },
      { municipio: "SÃO JOSÉ DO CERRITO" },
      { municipio: "SÃO LOURENÇO DO OESTE" },
      { municipio: "SÃO LUDGERO" },
      { municipio: "SÃO MARTINHO" },
      { municipio: "SÃO MIGUEL DA BOA VISTA" },
      { municipio: "SÃO MIGUEL DO OESTE" },
      { municipio: "SÃO PEDRO DE ALCÂNTARA" },
      { municipio: "SAUDADES" },
      { municipio: "SCHROEDER" },
      { municipio: "SEARA" },
      { municipio: "SERRA ALTA" },
      { municipio: "SIDERÓPOLIS" },
      { municipio: "SOMBRIO" },
      { municipio: "SUL BRASIL" },
      { municipio: "TAIÓ" },
      { municipio: "TANGARÁ" },
      { municipio: "TIGRINHOS" },
      { municipio: "TIJUCAS" },
      { municipio: "TIMBÉ DO SUL" },
      { municipio: "TIMBÓ" },
      { municipio: "TIMBÓ GRANDE" },
      { municipio: "TRÊS BARRAS" },
      { municipio: "TREVISO" },
      { municipio: "TREZE DE MAIO" },
      { municipio: "TREZE TÍLIAS" },
      { municipio: "TROMBUDO CENTRAL" },
      { municipio: "TUBARÃO" },
      { municipio: "TUNÁPOLIS" },
      { municipio: "TURVO" },
      { municipio: "UNIÃO DO OESTE" },
      { municipio: "URUBICI" },
      { municipio: "URUPEMA" },
      { municipio: "URUSSANGA" },
      { municipio: "VARGEÃO" },
      { municipio: "VARGEM" },
      { municipio: "VARGEM BONITA" },
      { municipio: "VIDAL RAMOS" },
      { municipio: "VIDEIRA" },
      { municipio: "VITOR MEIRELES" },
      { municipio: "WITMARSUM" },
      { municipio: "XANXERÊ" },
      { municipio: "XAVANTINA" },
      { municipio: "XAXIM" },
      { municipio: "ZORTÉA" },
      { municipio: "BALNEARIO DE PICARRAS" },
    ],
    estado: "SC",
  },
  {
    municipios: [
      { municipio: "AMPARO DE SÃO FRANCISCO" },
      { municipio: "AQUIDABÃ" },
      { municipio: "ARACAJU" },
      { municipio: "ARAUÁ" },
      { municipio: "AREIA BRANCA" },
      { municipio: "BARRA DOS COQUEIROS" },
      { municipio: "BOQUIM" },
      { municipio: "BREJO GRANDE" },
      { municipio: "CAMPO DO BRITO" },
      { municipio: "CANHOBA" },
      { municipio: "CANINDÉ DE SÃO FRANCISCO" },
      { municipio: "CAPELA" },
      { municipio: "CARIRA" },
      { municipio: "CARMÓPOLIS" },
      { municipio: "CEDRO DE SÃO JOÃO" },
      { municipio: "CRISTINÁPOLIS" },
      { municipio: "CUMBE" },
      { municipio: "DIVINA PASTORA" },
      { municipio: "ESTÂNCIA" },
      { municipio: "FEIRA NOVA" },
      { municipio: "FREI PAULO" },
      { municipio: "GARARU" },
      { municipio: "GENERAL MAYNARD" },
      { municipio: "GRACHO CARDOSO" },
      { municipio: "ILHA DAS FLORES" },
      { municipio: "INDIAROBA" },
      { municipio: "ITABAIANA" },
      { municipio: "ITABAIANINHA" },
      { municipio: "ITABI" },
      { municipio: "ITAPORANGA D'AJUDA" },
      { municipio: "JAPARATUBA" },
      { municipio: "JAPOATÃ" },
      { municipio: "LAGARTO" },
      { municipio: "LARANJEIRAS" },
      { municipio: "MACAMBIRA" },
      { municipio: "MALHADA DOS BOIS" },
      { municipio: "MALHADOR" },
      { municipio: "MARUIM" },
      { municipio: "MOITA BONITA" },
      { municipio: "MONTE ALEGRE DE SERGIPE" },
      { municipio: "MURIBECA" },
      { municipio: "NEÓPOLIS" },
      { municipio: "NOSSA SENHORA APARECIDA" },
      { municipio: "NOSSA SENHORA DA GLÓRIA" },
      { municipio: "NOSSA SENHORA DAS DORES" },
      { municipio: "NOSSA SENHORA DE LOURDES" },
      { municipio: "NOSSA SENHORA DO SOCORRO" },
      { municipio: "PACATUBA" },
      { municipio: "PEDRA MOLE" },
      { municipio: "PEDRINHAS" },
      { municipio: "PINHÃO" },
      { municipio: "PIRAMBU" },
      { municipio: "POÇO REDONDO" },
      { municipio: "POÇO VERDE" },
      { municipio: "PORTO DA FOLHA" },
      { municipio: "PROPRIÁ" },
      { municipio: "RIACHÃO DO DANTAS" },
      { municipio: "RIACHUELO" },
      { municipio: "RIBEIRÓPOLIS" },
      { municipio: "ROSÁRIO DO CATETE" },
      { municipio: "SALGADO" },
      { municipio: "SANTA LUZIA DO ITANHY" },
      { municipio: "SANTA ROSA DE LIMA" },
      { municipio: "SANTANA DO SÃO FRANCISCO" },
      { municipio: "SANTO AMARO DAS BROTAS" },
      { municipio: "SÃO CRISTÓVÃO" },
      { municipio: "SÃO DOMINGOS" },
      { municipio: "SÃO FRANCISCO" },
      { municipio: "SÃO MIGUEL DO ALEIXO" },
      { municipio: "SIMÃO DIAS" },
      { municipio: "SIRIRI" },
      { municipio: "TELHA" },
      { municipio: "TOBIAS BARRETO" },
      { municipio: "TOMAR DO GERU" },
      { municipio: "UMBAÚBA" },
      { municipio: "GRACCHO CARDOSO" },
    ],
    estado: "SE",
  },
  {
    municipios: [
      { municipio: "AÇAILÂNDIA" },
      { municipio: "AFONSO CUNHA" },
      { municipio: "ÁGUA DOCE DO MARANHÃO" },
      { municipio: "ALCÂNTARA" },
      { municipio: "ALDEIAS ALTAS" },
      { municipio: "ALTAMIRA DO MARANHÃO" },
      { municipio: "ALTO ALEGRE DO MARANHÃO" },
      { municipio: "ALTO ALEGRE DO PINDARÉ" },
      { municipio: "ALTO PARNAÍBA" },
      { municipio: "AMAPÁ DO MARANHÃO" },
      { municipio: "AMARANTE DO MARANHÃO" },
      { municipio: "ANAJATUBA" },
      { municipio: "ANAPURUS" },
      { municipio: "APICUM-AÇU" },
      { municipio: "ARAGUANÃ" },
      { municipio: "ARAIOSES" },
      { municipio: "ARAME" },
      { municipio: "ARARI" },
      { municipio: "AXIXÁ" },
      { municipio: "BACABAL" },
      { municipio: "BACABEIRA" },
      { municipio: "BACURI" },
      { municipio: "BACURITUBA" },
      { municipio: "BALSAS" },
      { municipio: "BARÃO DE GRAJAÚ" },
      { municipio: "BARRA DO CORDA" },
      { municipio: "BARREIRINHAS" },
      { municipio: "BELA VISTA DO MARANHÃO" },
      { municipio: "BELÁGUA" },
      { municipio: "BENEDITO LEITE" },
      { municipio: "BEQUIMÃO" },
      { municipio: "BERNARDO DO MEARIM" },
      { municipio: "BOA VISTA DO GURUPI" },
      { municipio: "BOM JARDIM" },
      { municipio: "BOM JESUS DAS SELVAS" },
      { municipio: "BOM LUGAR" },
      { municipio: "BREJO" },
      { municipio: "BREJO DE AREIA" },
      { municipio: "BURITI" },
      { municipio: "BURITI BRAVO" },
      { municipio: "BURITICUPU" },
      { municipio: "BURITIRANA" },
      { municipio: "CACHOEIRA GRANDE" },
      { municipio: "CAJAPIÓ" },
      { municipio: "CAJARI" },
      { municipio: "CAMPESTRE DO MARANHÃO" },
      { municipio: "CÂNDIDO MENDES" },
      { municipio: "CANTANHEDE" },
      { municipio: "CAPINZAL DO NORTE" },
      { municipio: "CAROLINA" },
      { municipio: "CARUTAPERA" },
      { municipio: "CAXIAS" },
      { municipio: "CEDRAL" },
      { municipio: "CENTRAL DO MARANHÃO" },
      { municipio: "CENTRO DO GUILHERME" },
      { municipio: "CENTRO NOVO DO MARANHÃO" },
      { municipio: "CHAPADINHA" },
      { municipio: "CIDELÂNDIA" },
      { municipio: "CODÓ" },
      { municipio: "COELHO NETO" },
      { municipio: "COLINAS" },
      { municipio: "CONCEIÇÃO DO LAGO-AÇU" },
      { municipio: "COROATÁ" },
      { municipio: "CURURUPU" },
      { municipio: "DAVINÓPOLIS" },
      { municipio: "DOM PEDRO" },
      { municipio: "DUQUE BACELAR" },
      { municipio: "ESPERANTINÓPOLIS" },
      { municipio: "ESTREITO" },
      { municipio: "FEIRA NOVA DO MARANHÃO" },
      { municipio: "FERNANDO FALCÃO" },
      { municipio: "FORMOSA DA SERRA NEGRA" },
      { municipio: "FORTALEZA DOS NOGUEIRAS" },
      { municipio: "FORTUNA" },
      { municipio: "GODOFREDO VIANA" },
      { municipio: "GONÇALVES DIAS" },
      { municipio: "GOVERNADOR ARCHER" },
      { municipio: "GOVERNADOR EDISON LOBÃO" },
      { municipio: "GOVERNADOR EUGÊNIO BARROS" },
      { municipio: "GOVERNADOR LUIZ ROCHA" },
      { municipio: "GOVERNADOR NEWTON BELLO" },
      { municipio: "GOVERNADOR NUNES FREIRE" },
      { municipio: "GRAÇA ARANHA" },
      { municipio: "GRAJAÚ" },
      { municipio: "GUIMARÃES" },
      { municipio: "HUMBERTO DE CAMPOS" },
      { municipio: "ICATU" },
      { municipio: "IGARAPÉ DO MEIO" },
      { municipio: "IGARAPÉ GRANDE" },
      { municipio: "IMPERATRIZ" },
      { municipio: "ITAIPAVA DO GRAJAÚ" },
      { municipio: "ITAPECURU MIRIM" },
      { municipio: "ITINGA DO MARANHÃO" },
      { municipio: "JATOBÁ" },
      { municipio: "JENIPAPO DOS VIEIRAS" },
      { municipio: "JOÃO LISBOA" },
      { municipio: "JOSELÂNDIA" },
      { municipio: "JUNCO DO MARANHÃO" },
      { municipio: "LAGO DA PEDRA" },
      { municipio: "LAGO DO JUNCO" },
      { municipio: "LAGO DOS RODRIGUES" },
      { municipio: "LAGO VERDE" },
      { municipio: "LAGOA DO MATO" },
      { municipio: "LAGOA GRANDE DO MARANHÃO" },
      { municipio: "LAJEADO NOVO" },
      { municipio: "LIMA CAMPOS" },
      { municipio: "LORETO" },
      { municipio: "LUÍS DOMINGUES" },
      { municipio: "MAGALHÃES DE ALMEIDA" },
      { municipio: "MARACAÇUMÉ" },
      { municipio: "MARAJÁ DO SENA" },
      { municipio: "MARANHÃOZINHO" },
      { municipio: "MATA ROMA" },
      { municipio: "MATINHA" },
      { municipio: "MATÕES" },
      { municipio: "MATÕES DO NORTE" },
      { municipio: "MILAGRES DO MARANHÃO" },
      { municipio: "MIRADOR" },
      { municipio: "MIRANDA DO NORTE" },
      { municipio: "MIRINZAL" },
      { municipio: "MONÇÃO" },
      { municipio: "MONTES ALTOS" },
      { municipio: "MORROS" },
      { municipio: "NINA RODRIGUES" },
      { municipio: "NOVA COLINAS" },
      { municipio: "NOVA IORQUE" },
      { municipio: "NOVA OLINDA DO MARANHÃO" },
      { municipio: "OLHO D'ÁGUA DAS CUNHÃS" },
      { municipio: "OLINDA NOVA DO MARANHÃO" },
      { municipio: "PAÇO DO LUMIAR" },
      { municipio: "PALMEIRÂNDIA" },
      { municipio: "PARAIBANO" },
      { municipio: "PARNARAMA" },
      { municipio: "PASSAGEM FRANCA" },
      { municipio: "PASTOS BONS" },
      { municipio: "PAULINO NEVES" },
      { municipio: "PAULO RAMOS" },
      { municipio: "PEDREIRAS" },
      { municipio: "PEDRO DO ROSÁRIO" },
      { municipio: "PENALVA" },
      { municipio: "PERI MIRIM" },
      { municipio: "PERITORÓ" },
      { municipio: "PINDARÉ-MIRIM" },
      { municipio: "PINHEIRO" },
      { municipio: "PIO XII" },
      { municipio: "PIRAPEMAS" },
      { municipio: "POÇÃO DE PEDRAS" },
      { municipio: "PORTO FRANCO" },
      { municipio: "PORTO RICO DO MARANHÃO" },
      { municipio: "PRESIDENTE DUTRA" },
      { municipio: "PRESIDENTE JUSCELINO" },
      { municipio: "PRESIDENTE MÉDICI" },
      { municipio: "PRESIDENTE SARNEY" },
      { municipio: "PRESIDENTE VARGAS" },
      { municipio: "PRIMEIRA CRUZ" },
      { municipio: "RAPOSA" },
      { municipio: "RIACHÃO" },
      { municipio: "RIBAMAR FIQUENE" },
      { municipio: "ROSÁRIO" },
      { municipio: "SAMBAÍBA" },
      { municipio: "SANTA FILOMENA DO MARANHÃO" },
      { municipio: "SANTA HELENA" },
      { municipio: "SANTA INÊS" },
      { municipio: "SANTA LUZIA" },
      { municipio: "SANTA LUZIA DO PARUÁ" },
      { municipio: "SANTA QUITÉRIA DO MARANHÃO" },
      { municipio: "SANTA RITA" },
      { municipio: "SANTANA DO MARANHÃO" },
      { municipio: "SANTO AMARO DO MARANHÃO" },
      { municipio: "SANTO ANTÔNIO DOS LOPES" },
      { municipio: "SÃO BENEDITO DO RIO PRETO" },
      { municipio: "SÃO BENTO" },
      { municipio: "SÃO BERNARDO" },
      { municipio: "SÃO DOMINGOS DO AZEITÃO" },
      { municipio: "SÃO DOMINGOS DO MARANHÃO" },
      { municipio: "SÃO FÉLIX DE BALSAS" },
      { municipio: "SÃO FRANCISCO DO BREJÃO" },
      { municipio: "SÃO FRANCISCO DO MARANHÃO" },
      { municipio: "SÃO JOÃO BATISTA" },
      { municipio: "SÃO JOÃO DO CARÚ" },
      { municipio: "SÃO JOÃO DO PARAÍSO" },
      { municipio: "SÃO JOÃO DO SOTER" },
      { municipio: "SÃO JOÃO DOS PATOS" },
      { municipio: "SÃO JOSÉ DE RIBAMAR" },
      { municipio: "SÃO JOSÉ DOS BASÍLIOS" },
      { municipio: "SÃO LUÍS" },
      { municipio: "SÃO LUÍS GONZAGA DO MARANHÃO" },
      { municipio: "SÃO MATEUS DO MARANHÃO" },
      { municipio: "SÃO PEDRO DA ÁGUA BRANCA" },
      { municipio: "SÃO PEDRO DOS CRENTES" },
      { municipio: "SÃO RAIMUNDO DAS MANGABEIRAS" },
      { municipio: "SÃO RAIMUNDO DO DOCA BEZERRA" },
      { municipio: "SÃO ROBERTO" },
      { municipio: "SÃO VICENTE FERRER" },
      { municipio: "SATUBINHA" },
      { municipio: "SENADOR ALEXANDRE COSTA" },
      { municipio: "SENADOR LA ROCQUE" },
      { municipio: "SERRANO DO MARANHÃO" },
      { municipio: "SÍTIO NOVO" },
      { municipio: "SUCUPIRA DO NORTE" },
      { municipio: "SUCUPIRA DO RIACHÃO" },
      { municipio: "TASSO FRAGOSO" },
      { municipio: "TIMBIRAS" },
      { municipio: "TIMON" },
      { municipio: "TRIZIDELA DO VALE" },
      { municipio: "TUFILÂNDIA" },
      { municipio: "TUNTUM" },
      { municipio: "TURIAÇU" },
      { municipio: "TURILÂNDIA" },
      { municipio: "TUTÓIA" },
      { municipio: "URBANO SANTOS" },
      { municipio: "VARGEM GRANDE" },
      { municipio: "VIANA" },
      { municipio: "VILA NOVA DOS MARTÍRIOS" },
      { municipio: "VITÓRIA DO MEARIM" },
      { municipio: "VITORINO FREIRE" },
      { municipio: "ZÉ DOCA" },
      { municipio: "APICUM ACU" },
      { municipio: "CONCEICAO DO LAGO ACU" },
      { municipio: "PINDARE MIRIM" },
    ],
    estado: "MA",
  },
  {
    municipios: [
      { municipio: "ABADIA DOS DOURADOS" },
      { municipio: "ABAETÉ" },
      { municipio: "ABRE CAMPO" },
      { municipio: "ACAIACA" },
      { municipio: "AÇUCENA" },
      { municipio: "ÁGUA BOA" },
      { municipio: "ÁGUA COMPRIDA" },
      { municipio: "AGUANIL" },
      { municipio: "ÁGUAS FORMOSAS" },
      { municipio: "ÁGUAS VERMELHAS" },
      { municipio: "AIMORÉS" },
      { municipio: "AIURUOCA" },
      { municipio: "ALAGOA" },
      { municipio: "ALBERTINA" },
      { municipio: "ALÉM PARAÍBA" },
      { municipio: "ALFENAS" },
      { municipio: "ALFREDO VASCONCELOS" },
      { municipio: "ALMENARA" },
      { municipio: "ALPERCATA" },
      { municipio: "ALPINÓPOLIS" },
      { municipio: "ALTEROSA" },
      { municipio: "ALTO CAPARAÓ" },
      { municipio: "ALTO JEQUITIBÁ" },
      { municipio: "ALTO RIO DOCE" },
      { municipio: "ALVARENGA" },
      { municipio: "ALVINÓPOLIS" },
      { municipio: "ALVORADA DE MINAS" },
      { municipio: "AMPARO DO SERRA" },
      { municipio: "ANDRADAS" },
      { municipio: "ANDRELÂNDIA" },
      { municipio: "ANGELÂNDIA" },
      { municipio: "ANTÔNIO CARLOS" },
      { municipio: "ANTÔNIO DIAS" },
      { municipio: "ANTÔNIO PRADO DE MINAS" },
      { municipio: "ARAÇAÍ" },
      { municipio: "ARACITABA" },
      { municipio: "ARAÇUAÍ" },
      { municipio: "ARAGUARI" },
      { municipio: "ARANTINA" },
      { municipio: "ARAPONGA" },
      { municipio: "ARAPORÃ" },
      { municipio: "ARAPUÁ" },
      { municipio: "ARAÚJOS" },
      { municipio: "ARAXÁ" },
      { municipio: "ARCEBURGO" },
      { municipio: "ARCOS" },
      { municipio: "AREADO" },
      { municipio: "ARGIRITA" },
      { municipio: "ARICANDUVA" },
      { municipio: "ARINOS" },
      { municipio: "ASTOLFO DUTRA" },
      { municipio: "ATALÉIA" },
      { municipio: "AUGUSTO DE LIMA" },
      { municipio: "BAEPENDI" },
      { municipio: "BALDIM" },
      { municipio: "BAMBUÍ" },
      { municipio: "BANDEIRA" },
      { municipio: "BANDEIRA DO SUL" },
      { municipio: "BARÃO DE COCAIS" },
      { municipio: "BARÃO DE MONTE ALTO" },
      { municipio: "BARBACENA" },
      { municipio: "BARRA LONGA" },
      { municipio: "BARROSO" },
      { municipio: "BELA VISTA DE MINAS" },
      { municipio: "BELMIRO BRAGA" },
      { municipio: "BELO HORIZONTE" },
      { municipio: "BELO ORIENTE" },
      { municipio: "BELO VALE" },
      { municipio: "BERILO" },
      { municipio: "BERIZAL" },
      { municipio: "BERTÓPOLIS" },
      { municipio: "BETIM" },
      { municipio: "BIAS FORTES" },
      { municipio: "BICAS" },
      { municipio: "BIQUINHAS" },
      { municipio: "BOA ESPERANÇA" },
      { municipio: "BOCAINA DE MINAS" },
      { municipio: "BOCAIÚVA" },
      { municipio: "BOM DESPACHO" },
      { municipio: "BOM JARDIM DE MINAS" },
      { municipio: "BOM JESUS DA PENHA" },
      { municipio: "BOM JESUS DO AMPARO" },
      { municipio: "BOM JESUS DO GALHO" },
      { municipio: "BOM REPOUSO" },
      { municipio: "BOM SUCESSO" },
      { municipio: "BONFIM" },
      { municipio: "BONFINÓPOLIS DE MINAS" },
      { municipio: "BONITO DE MINAS" },
      { municipio: "BORDA DA MATA" },
      { municipio: "BOTELHOS" },
      { municipio: "BOTUMIRIM" },
      { municipio: "BRÁS PIRES" },
      { municipio: "BRASILÂNDIA DE MINAS" },
      { municipio: "BRASÍLIA DE MINAS" },
      { municipio: "BRAÚNAS" },
      { municipio: "BRAZÓPOLIS" },
      { municipio: "BRUMADINHO" },
      { municipio: "BUENO BRANDÃO" },
      { municipio: "BUENÓPOLIS" },
      { municipio: "BUGRE" },
      { municipio: "BURITIS" },
      { municipio: "BURITIZEIRO" },
      { municipio: "CABECEIRA GRANDE" },
      { municipio: "CABO VERDE" },
      { municipio: "CACHOEIRA DA PRATA" },
      { municipio: "CACHOEIRA DE MINAS" },
      { municipio: "CACHOEIRA DE PAJEÚ" },
      { municipio: "CACHOEIRA DOURADA" },
      { municipio: "CAETANÓPOLIS" },
      { municipio: "CAETÉ" },
      { municipio: "CAIANA" },
      { municipio: "CAJURI" },
      { municipio: "CALDAS" },
      { municipio: "CAMACHO" },
      { municipio: "CAMANDUCAIA" },
      { municipio: "CAMBUÍ" },
      { municipio: "CAMBUQUIRA" },
      { municipio: "CAMPANÁRIO" },
      { municipio: "CAMPANHA" },
      { municipio: "CAMPESTRE" },
      { municipio: "CAMPINA VERDE" },
      { municipio: "CAMPO AZUL" },
      { municipio: "CAMPO BELO" },
      { municipio: "CAMPO DO MEIO" },
      { municipio: "CAMPO FLORIDO" },
      { municipio: "CAMPOS ALTOS" },
      { municipio: "CAMPOS GERAIS" },
      { municipio: "CANA VERDE" },
      { municipio: "CANAÃ" },
      { municipio: "CANÁPOLIS" },
      { municipio: "CANDEIAS" },
      { municipio: "CANTAGALO" },
      { municipio: "CAPARAÓ" },
      { municipio: "CAPELA NOVA" },
      { municipio: "CAPELINHA" },
      { municipio: "CAPETINGA" },
      { municipio: "CAPIM BRANCO" },
      { municipio: "CAPINÓPOLIS" },
      { municipio: "CAPITÃO ANDRADE" },
      { municipio: "CAPITÃO ENÉAS" },
      { municipio: "CAPITÓLIO" },
      { municipio: "CAPUTIRA" },
      { municipio: "CARAÍ" },
      { municipio: "CARANAÍBA" },
      { municipio: "CARANDAÍ" },
      { municipio: "CARANGOLA" },
      { municipio: "CARATINGA" },
      { municipio: "CARBONITA" },
      { municipio: "CAREAÇU" },
      { municipio: "CARLOS CHAGAS" },
      { municipio: "CARMÉSIA" },
      { municipio: "CARMO DA CACHOEIRA" },
      { municipio: "CARMO DA MATA" },
      { municipio: "CARMO DE MINAS" },
      { municipio: "CARMO DO CAJURU" },
      { municipio: "CARMO DO PARANAÍBA" },
      { municipio: "CARMO DO RIO CLARO" },
      { municipio: "CARMÓPOLIS DE MINAS" },
      { municipio: "CARNEIRINHO" },
      { municipio: "CARRANCAS" },
      { municipio: "CARVALHÓPOLIS" },
      { municipio: "CARVALHOS" },
      { municipio: "CASA GRANDE" },
      { municipio: "CASCALHO RICO" },
      { municipio: "CÁSSIA" },
      { municipio: "CATAGUASES" },
      { municipio: "CATAS ALTAS" },
      { municipio: "CATAS ALTAS DA NORUEGA" },
      { municipio: "CATUJI" },
      { municipio: "CATUTI" },
      { municipio: "CAXAMBU" },
      { municipio: "CEDRO DO ABAETÉ" },
      { municipio: "CENTRAL DE MINAS" },
      { municipio: "CENTRALINA" },
      { municipio: "CHÁCARA" },
      { municipio: "CHALÉ" },
      { municipio: "CHAPADA DO NORTE" },
      { municipio: "CHAPADA GAÚCHA" },
      { municipio: "CHIADOR" },
      { municipio: "CIPOTÂNEA" },
      { municipio: "CLARAVAL" },
      { municipio: "CLARO DOS POÇÕES" },
      { municipio: "CLÁUDIO" },
      { municipio: "COIMBRA" },
      { municipio: "COLUNA" },
      { municipio: "COMENDADOR GOMES" },
      { municipio: "COMERCINHO" },
      { municipio: "CONCEIÇÃO DA APARECIDA" },
      { municipio: "CONCEIÇÃO DA BARRA DE MINAS" },
      { municipio: "CONCEIÇÃO DAS ALAGOAS" },
      { municipio: "CONCEIÇÃO DAS PEDRAS" },
      { municipio: "CONCEIÇÃO DE IPANEMA" },
      { municipio: "CONCEIÇÃO DO MATO DENTRO" },
      { municipio: "CONCEIÇÃO DO PARÁ" },
      { municipio: "CONCEIÇÃO DO RIO VERDE" },
      { municipio: "CONCEIÇÃO DOS OUROS" },
      { municipio: "CÔNEGO MARINHO" },
      { municipio: "CONFINS" },
      { municipio: "CONGONHAL" },
      { municipio: "CONGONHAS" },
      { municipio: "CONGONHAS DO NORTE" },
      { municipio: "CONQUISTA" },
      { municipio: "CONSELHEIRO LAFAIETE" },
      { municipio: "CONSELHEIRO PENA" },
      { municipio: "CONSOLAÇÃO" },
      { municipio: "CONTAGEM" },
      { municipio: "COQUEIRAL" },
      { municipio: "CORAÇÃO DE JESUS" },
      { municipio: "CORDISBURGO" },
      { municipio: "CORDISLÂNDIA" },
      { municipio: "CORINTO" },
      { municipio: "COROACI" },
      { municipio: "COROMANDEL" },
      { municipio: "CORONEL FABRICIANO" },
      { municipio: "CORONEL MURTA" },
      { municipio: "CORONEL PACHECO" },
      { municipio: "CORONEL XAVIER CHAVES" },
      { municipio: "CÓRREGO DANTA" },
      { municipio: "CÓRREGO DO BOM JESUS" },
      { municipio: "CÓRREGO FUNDO" },
      { municipio: "CÓRREGO NOVO" },
      { municipio: "COUTO DE MAGALHÃES DE MINAS" },
      { municipio: "CRISÓLITA" },
      { municipio: "CRISTAIS" },
      { municipio: "CRISTÁLIA" },
      { municipio: "CRISTIANO OTONI" },
      { municipio: "CRISTINA" },
      { municipio: "CRUCILÂNDIA" },
      { municipio: "CRUZEIRO DA FORTALEZA" },
      { municipio: "CRUZÍLIA" },
      { municipio: "CUPARAQUE" },
      { municipio: "CURRAL DE DENTRO" },
      { municipio: "CURVELO" },
      { municipio: "DATAS" },
      { municipio: "DELFIM MOREIRA" },
      { municipio: "DELFINÓPOLIS" },
      { municipio: "DELTA" },
      { municipio: "DESCOBERTO" },
      { municipio: "DESTERRO DE ENTRE RIOS" },
      { municipio: "DESTERRO DO MELO" },
      { municipio: "DIAMANTINA" },
      { municipio: "DIOGO DE VASCONCELOS" },
      { municipio: "DIONÍSIO" },
      { municipio: "DIVINÉSIA" },
      { municipio: "DIVINO" },
      { municipio: "DIVINO DAS LARANJEIRAS" },
      { municipio: "DIVINOLÂNDIA DE MINAS" },
      { municipio: "DIVINÓPOLIS" },
      { municipio: "DIVISA ALEGRE" },
      { municipio: "DIVISA NOVA" },
      { municipio: "DIVISÓPOLIS" },
      { municipio: "DOM BOSCO" },
      { municipio: "DOM CAVATI" },
      { municipio: "DOM JOAQUIM" },
      { municipio: "DOM SILVÉRIO" },
      { municipio: "DOM VIÇOSO" },
      { municipio: "DONA EUSÉBIA" },
      { municipio: "DORES DE CAMPOS" },
      { municipio: "DORES DE GUANHÃES" },
      { municipio: "DORES DO INDAIÁ" },
      { municipio: "DORES DO TURVO" },
      { municipio: "DORESÓPOLIS" },
      { municipio: "DOURADOQUARA" },
      { municipio: "DURANDÉ" },
      { municipio: "ELÓI MENDES" },
      { municipio: "ENGENHEIRO CALDAS" },
      { municipio: "ENGENHEIRO NAVARRO" },
      { municipio: "ENTRE FOLHAS" },
      { municipio: "ENTRE RIOS DE MINAS" },
      { municipio: "ERVÁLIA" },
      { municipio: "ESMERALDAS" },
      { municipio: "ESPERA FELIZ" },
      { municipio: "ESPINOSA" },
      { municipio: "ESPÍRITO SANTO DO DOURADO" },
      { municipio: "ESTIVA" },
      { municipio: "ESTRELA DALVA" },
      { municipio: "ESTRELA DO INDAIÁ" },
      { municipio: "ESTRELA DO SUL" },
      { municipio: "EUGENÓPOLIS" },
      { municipio: "EWBANK DA CÂMARA" },
      { municipio: "EXTREMA" },
      { municipio: "FAMA" },
      { municipio: "FARIA LEMOS" },
      { municipio: "FELÍCIO DOS SANTOS" },
      { municipio: "FELISBURGO" },
      { municipio: "FELIXLÂNDIA" },
      { municipio: "FERNANDES TOURINHO" },
      { municipio: "FERROS" },
      { municipio: "FERVEDOURO" },
      { municipio: "FLORESTAL" },
      { municipio: "FORMIGA" },
      { municipio: "FORMOSO" },
      { municipio: "FORTALEZA DE MINAS" },
      { municipio: "FORTUNA DE MINAS" },
      { municipio: "FRANCISCO BADARÓ" },
      { municipio: "FRANCISCO DUMONT" },
      { municipio: "FRANCISCO SÁ" },
      { municipio: "FRANCISCÓPOLIS" },
      { municipio: "FREI GASPAR" },
      { municipio: "FREI INOCÊNCIO" },
      { municipio: "FREI LAGONEGRO" },
      { municipio: "FRONTEIRA" },
      { municipio: "FRONTEIRA DOS VALES" },
      { municipio: "FRUTA DE LEITE" },
      { municipio: "FRUTAL" },
      { municipio: "FUNILÂNDIA" },
      { municipio: "GALILÉIA" },
      { municipio: "GAMELEIRAS" },
      { municipio: "GLAUCILÂNDIA" },
      { municipio: "GOIABEIRA" },
      { municipio: "GOIANÁ" },
      { municipio: "GONÇALVES" },
      { municipio: "GONZAGA" },
      { municipio: "GOUVEIA" },
      { municipio: "GOVERNADOR VALADARES" },
      { municipio: "GRÃO MOGOL" },
      { municipio: "GRUPIARA" },
      { municipio: "GUANHÃES" },
      { municipio: "GUAPÉ" },
      { municipio: "GUARACIABA" },
      { municipio: "GUARACIAMA" },
      { municipio: "GUARANÉSIA" },
      { municipio: "GUARANI" },
      { municipio: "GUARARÁ" },
      { municipio: "GUARDA-MOR" },
      { municipio: "GUAXUPÉ" },
      { municipio: "GUIDOVAL" },
      { municipio: "GUIMARÂNIA" },
      { municipio: "GUIRICEMA" },
      { municipio: "GURINHATÃ" },
      { municipio: "HELIODORA" },
      { municipio: "IAPU" },
      { municipio: "IBERTIOGA" },
      { municipio: "IBIÁ" },
      { municipio: "IBIAÍ" },
      { municipio: "IBIRACATU" },
      { municipio: "IBIRACI" },
      { municipio: "IBIRITÉ" },
      { municipio: "IBITIÚRA DE MINAS" },
      { municipio: "IBITURUNA" },
      { municipio: "ICARAÍ DE MINAS" },
      { municipio: "IGARAPÉ" },
      { municipio: "IGARATINGA" },
      { municipio: "IGUATAMA" },
      { municipio: "IJACI" },
      { municipio: "ILICÍNEA" },
      { municipio: "IMBÉ DE MINAS" },
      { municipio: "INCONFIDENTES" },
      { municipio: "INDAIABIRA" },
      { municipio: "INDIANÓPOLIS" },
      { municipio: "INGAÍ" },
      { municipio: "INHAPIM" },
      { municipio: "INHAÚMA" },
      { municipio: "INIMUTABA" },
      { municipio: "IPABA" },
      { municipio: "IPANEMA" },
      { municipio: "IPATINGA" },
      { municipio: "IPIAÇU" },
      { municipio: "IPUIÚNA" },
      { municipio: "IRAÍ DE MINAS" },
      { municipio: "ITABIRA" },
      { municipio: "ITABIRINHA" },
      { municipio: "ITABIRITO" },
      { municipio: "ITACAMBIRA" },
      { municipio: "ITACARAMBI" },
      { municipio: "ITAGUARA" },
      { municipio: "ITAIPÉ" },
      { municipio: "ITAJUBÁ" },
      { municipio: "ITAMARANDIBA" },
      { municipio: "ITAMARATI DE MINAS" },
      { municipio: "ITAMBACURI" },
      { municipio: "ITAMBÉ DO MATO DENTRO" },
      { municipio: "ITAMOGI" },
      { municipio: "ITAMONTE" },
      { municipio: "ITANHANDU" },
      { municipio: "ITANHOMI" },
      { municipio: "ITAOBIM" },
      { municipio: "ITAPAGIPE" },
      { municipio: "ITAPECERICA" },
      { municipio: "ITAPEVA" },
      { municipio: "ITATIAIUÇU" },
      { municipio: "ITAÚ DE MINAS" },
      { municipio: "ITAÚNA" },
      { municipio: "ITAVERAVA" },
      { municipio: "ITINGA" },
      { municipio: "ITUETA" },
      { municipio: "ITUIUTABA" },
      { municipio: "ITUMIRIM" },
      { municipio: "ITURAMA" },
      { municipio: "ITUTINGA" },
      { municipio: "JABOTICATUBAS" },
      { municipio: "JACINTO" },
      { municipio: "JACUÍ" },
      { municipio: "JACUTINGA" },
      { municipio: "JAGUARAÇU" },
      { municipio: "JAÍBA" },
      { municipio: "JAMPRUCA" },
      { municipio: "JANAÚBA" },
      { municipio: "JANUÁRIA" },
      { municipio: "JAPARAÍBA" },
      { municipio: "JAPONVAR" },
      { municipio: "JECEABA" },
      { municipio: "JENIPAPO DE MINAS" },
      { municipio: "JEQUERI" },
      { municipio: "JEQUITAÍ" },
      { municipio: "JEQUITIBÁ" },
      { municipio: "JEQUITINHONHA" },
      { municipio: "JESUÂNIA" },
      { municipio: "JOAÍMA" },
      { municipio: "JOANÉSIA" },
      { municipio: "JOÃO MONLEVADE" },
      { municipio: "JOÃO PINHEIRO" },
      { municipio: "JOAQUIM FELÍCIO" },
      { municipio: "JORDÂNIA" },
      { municipio: "JOSÉ GONÇALVES DE MINAS" },
      { municipio: "JOSÉ RAYDAN" },
      { municipio: "JOSENÓPOLIS" },
      { municipio: "JUATUBA" },
      { municipio: "JUIZ DE FORA" },
      { municipio: "JURAMENTO" },
      { municipio: "JURUAIA" },
      { municipio: "JUVENÍLIA" },
      { municipio: "LADAINHA" },
      { municipio: "LAGAMAR" },
      { municipio: "LAGOA DA PRATA" },
      { municipio: "LAGOA DOS PATOS" },
      { municipio: "LAGOA DOURADA" },
      { municipio: "LAGOA FORMOSA" },
      { municipio: "LAGOA GRANDE" },
      { municipio: "LAGOA SANTA" },
      { municipio: "LAJINHA" },
      { municipio: "LAMBARI" },
      { municipio: "LAMIM" },
      { municipio: "LARANJAL" },
      { municipio: "LASSANCE" },
      { municipio: "LAVRAS" },
      { municipio: "LEANDRO FERREIRA" },
      { municipio: "LEME DO PRADO" },
      { municipio: "LEOPOLDINA" },
      { municipio: "LIBERDADE" },
      { municipio: "LIMA DUARTE" },
      { municipio: "LIMEIRA DO OESTE" },
      { municipio: "LONTRA" },
      { municipio: "LUISBURGO" },
      { municipio: "LUISLÂNDIA" },
      { municipio: "LUMINÁRIAS" },
      { municipio: "LUZ" },
      { municipio: "MACHACALIS" },
      { municipio: "MACHADO" },
      { municipio: "MADRE DE DEUS DE MINAS" },
      { municipio: "MALACACHETA" },
      { municipio: "MAMONAS" },
      { municipio: "MANGA" },
      { municipio: "MANHUAÇU" },
      { municipio: "MANHUMIRIM" },
      { municipio: "MANTENA" },
      { municipio: "MAR DE ESPANHA" },
      { municipio: "MARAVILHAS" },
      { municipio: "MARIA DA FÉ" },
      { municipio: "MARIANA" },
      { municipio: "MARILAC" },
      { municipio: "MÁRIO CAMPOS" },
      { municipio: "MARIPÁ DE MINAS" },
      { municipio: "MARLIÉRIA" },
      { municipio: "MARMELÓPOLIS" },
      { municipio: "MARTINHO CAMPOS" },
      { municipio: "MARTINS SOARES" },
      { municipio: "MATA VERDE" },
      { municipio: "MATERLÂNDIA" },
      { municipio: "MATEUS LEME" },
      { municipio: "MATHIAS LOBATO" },
      { municipio: "MATIAS BARBOSA" },
      { municipio: "MATIAS CARDOSO" },
      { municipio: "MATIPÓ" },
      { municipio: "MATO VERDE" },
      { municipio: "MATOZINHOS" },
      { municipio: "MATUTINA" },
      { municipio: "MEDEIROS" },
      { municipio: "MEDINA" },
      { municipio: "MENDES PIMENTEL" },
      { municipio: "MERCÊS" },
      { municipio: "MESQUITA" },
      { municipio: "MINAS NOVAS" },
      { municipio: "MINDURI" },
      { municipio: "MIRABELA" },
      { municipio: "MIRADOURO" },
      { municipio: "MIRAÍ" },
      { municipio: "MIRAVÂNIA" },
      { municipio: "MOEDA" },
      { municipio: "MOEMA" },
      { municipio: "MONJOLOS" },
      { municipio: "MONSENHOR PAULO" },
      { municipio: "MONTALVÂNIA" },
      { municipio: "MONTE ALEGRE DE MINAS" },
      { municipio: "MONTE AZUL" },
      { municipio: "MONTE BELO" },
      { municipio: "MONTE CARMELO" },
      { municipio: "MONTE FORMOSO" },
      { municipio: "MONTE SANTO DE MINAS" },
      { municipio: "MONTE SIÃO" },
      { municipio: "MONTES CLAROS" },
      { municipio: "MONTEZUMA" },
      { municipio: "MORADA NOVA DE MINAS" },
      { municipio: "MORRO DA GARÇA" },
      { municipio: "MORRO DO PILAR" },
      { municipio: "MUNHOZ" },
      { municipio: "MURIAÉ" },
      { municipio: "MUTUM" },
      { municipio: "MUZAMBINHO" },
      { municipio: "NACIP RAYDAN" },
      { municipio: "NANUQUE" },
      { municipio: "NAQUE" },
      { municipio: "NATALÂNDIA" },
      { municipio: "NATÉRCIA" },
      { municipio: "NAZARENO" },
      { municipio: "NEPOMUCENO" },
      { municipio: "NINHEIRA" },
      { municipio: "NOVA BELÉM" },
      { municipio: "NOVA ERA" },
      { municipio: "NOVA LIMA" },
      { municipio: "NOVA MÓDICA" },
      { municipio: "NOVA PONTE" },
      { municipio: "NOVA PORTEIRINHA" },
      { municipio: "NOVA RESENDE" },
      { municipio: "NOVA SERRANA" },
      { municipio: "NOVA UNIÃO" },
      { municipio: "NOVO CRUZEIRO" },
      { municipio: "NOVO ORIENTE DE MINAS" },
      { municipio: "NOVORIZONTE" },
      { municipio: "OLARIA" },
      { municipio: "OLHOS-D'ÁGUA" },
      { municipio: "OLÍMPIO NORONHA" },
      { municipio: "OLIVEIRA" },
      { municipio: "OLIVEIRA FORTES" },
      { municipio: "ONÇA DE PITANGUI" },
      { municipio: "ORATÓRIOS" },
      { municipio: "ORIZÂNIA" },
      { municipio: "OURO BRANCO" },
      { municipio: "OURO FINO" },
      { municipio: "OURO PRETO" },
      { municipio: "OURO VERDE DE MINAS" },
      { municipio: "PADRE CARVALHO" },
      { municipio: "PADRE PARAÍSO" },
      { municipio: "PAI PEDRO" },
      { municipio: "PAINEIRAS" },
      { municipio: "PAINS" },
      { municipio: "PAIVA" },
      { municipio: "PALMA" },
      { municipio: "PALMÓPOLIS" },
      { municipio: "PAPAGAIOS" },
      { municipio: "PARÁ DE MINAS" },
      { municipio: "PARACATU" },
      { municipio: "PARAGUAÇU" },
      { municipio: "PARAISÓPOLIS" },
      { municipio: "PARAOPEBA" },
      { municipio: "PASSA QUATRO" },
      { municipio: "PASSA TEMPO" },
      { municipio: "PASSA VINTE" },
      { municipio: "PASSABÉM" },
      { municipio: "PASSOS" },
      { municipio: "PATIS" },
      { municipio: "PATOS DE MINAS" },
      { municipio: "PATROCÍNIO" },
      { municipio: "PATROCÍNIO DO MURIAÉ" },
      { municipio: "PAULA CÂNDIDO" },
      { municipio: "PAULISTAS" },
      { municipio: "PAVÃO" },
      { municipio: "PEÇANHA" },
      { municipio: "PEDRA AZUL" },
      { municipio: "PEDRA BONITA" },
      { municipio: "PEDRA DO ANTA" },
      { municipio: "PEDRA DO INDAIÁ" },
      { municipio: "PEDRA DOURADA" },
      { municipio: "PEDRALVA" },
      { municipio: "PEDRAS DE MARIA DA CRUZ" },
      { municipio: "PEDRINÓPOLIS" },
      { municipio: "PEDRO LEOPOLDO" },
      { municipio: "PEDRO TEIXEIRA" },
      { municipio: "PEQUERI" },
      { municipio: "PEQUI" },
      { municipio: "PERDIGÃO" },
      { municipio: "PERDIZES" },
      { municipio: "PERDÕES" },
      { municipio: "PERIQUITO" },
      { municipio: "PESCADOR" },
      { municipio: "PIAU" },
      { municipio: "PIEDADE DE CARATINGA" },
      { municipio: "PIEDADE DE PONTE NOVA" },
      { municipio: "PIEDADE DO RIO GRANDE" },
      { municipio: "PIEDADE DOS GERAIS" },
      { municipio: "PIMENTA" },
      { municipio: "PINGO D'ÁGUA" },
      { municipio: "PINTÓPOLIS" },
      { municipio: "PIRACEMA" },
      { municipio: "PIRAJUBA" },
      { municipio: "PIRANGA" },
      { municipio: "PIRANGUÇU" },
      { municipio: "PIRANGUINHO" },
      { municipio: "PIRAPETINGA" },
      { municipio: "PIRAPORA" },
      { municipio: "PIRAÚBA" },
      { municipio: "PITANGUI" },
      { municipio: "PIUMHI" },
      { municipio: "PLANURA" },
      { municipio: "POÇO FUNDO" },
      { municipio: "POÇOS DE CALDAS" },
      { municipio: "POCRANE" },
      { municipio: "POMPÉU" },
      { municipio: "PONTE NOVA" },
      { municipio: "PONTO CHIQUE" },
      { municipio: "PONTO DOS VOLANTES" },
      { municipio: "PORTEIRINHA" },
      { municipio: "PORTO FIRME" },
      { municipio: "POTÉ" },
      { municipio: "POUSO ALEGRE" },
      { municipio: "POUSO ALTO" },
      { municipio: "PRADOS" },
      { municipio: "PRATA" },
      { municipio: "PRATÁPOLIS" },
      { municipio: "PRATINHA" },
      { municipio: "PRESIDENTE BERNARDES" },
      { municipio: "PRESIDENTE JUSCELINO" },
      { municipio: "PRESIDENTE KUBITSCHEK" },
      { municipio: "PRESIDENTE OLEGÁRIO" },
      { municipio: "PRUDENTE DE MORAIS" },
      { municipio: "QUARTEL GERAL" },
      { municipio: "QUELUZITO" },
      { municipio: "RAPOSOS" },
      { municipio: "RAUL SOARES" },
      { municipio: "RECREIO" },
      { municipio: "REDUTO" },
      { municipio: "RESENDE COSTA" },
      { municipio: "RESPLENDOR" },
      { municipio: "RESSAQUINHA" },
      { municipio: "RIACHINHO" },
      { municipio: "RIACHO DOS MACHADOS" },
      { municipio: "RIBEIRÃO DAS NEVES" },
      { municipio: "RIBEIRÃO VERMELHO" },
      { municipio: "RIO ACIMA" },
      { municipio: "RIO CASCA" },
      { municipio: "RIO DO PRADO" },
      { municipio: "RIO DOCE" },
      { municipio: "RIO ESPERA" },
      { municipio: "RIO MANSO" },
      { municipio: "RIO NOVO" },
      { municipio: "RIO PARANAÍBA" },
      { municipio: "RIO PARDO DE MINAS" },
      { municipio: "RIO PIRACICABA" },
      { municipio: "RIO POMBA" },
      { municipio: "RIO PRETO" },
      { municipio: "RIO VERMELHO" },
      { municipio: "RITÁPOLIS" },
      { municipio: "ROCHEDO DE MINAS" },
      { municipio: "RODEIRO" },
      { municipio: "ROMARIA" },
      { municipio: "ROSÁRIO DA LIMEIRA" },
      { municipio: "RUBELITA" },
      { municipio: "RUBIM" },
      { municipio: "SABARÁ" },
      { municipio: "SABINÓPOLIS" },
      { municipio: "SACRAMENTO" },
      { municipio: "SALINAS" },
      { municipio: "SALTO DA DIVISA" },
      { municipio: "SANTA BÁRBARA" },
      { municipio: "SANTA BÁRBARA DO LESTE" },
      { municipio: "SANTA BÁRBARA DO MONTE VERDE" },
      { municipio: "SANTA BÁRBARA DO TUGÚRIO" },
      { municipio: "SANTA CRUZ DE MINAS" },
      { municipio: "SANTA CRUZ DE SALINAS" },
      { municipio: "SANTA CRUZ DO ESCALVADO" },
      { municipio: "SANTA EFIGÊNIA DE MINAS" },
      { municipio: "SANTA FÉ DE MINAS" },
      { municipio: "SANTA HELENA DE MINAS" },
      { municipio: "SANTA JULIANA" },
      { municipio: "SANTA LUZIA" },
      { municipio: "SANTA MARGARIDA" },
      { municipio: "SANTA MARIA DE ITABIRA" },
      { municipio: "SANTA MARIA DO SALTO" },
      { municipio: "SANTA MARIA DO SUAÇUÍ" },
      { municipio: "SANTA RITA DE CALDAS" },
      { municipio: "SANTA RITA DE IBITIPOCA" },
      { municipio: "SANTA RITA DE JACUTINGA" },
      { municipio: "SANTA RITA DE MINAS" },
      { municipio: "SANTA RITA DO ITUETO" },
      { municipio: "SANTA RITA DO SAPUCAÍ" },
      { municipio: "SANTA ROSA DA SERRA" },
      { municipio: "SANTA VITÓRIA" },
      { municipio: "SANTANA DA VARGEM" },
      { municipio: "SANTANA DE CATAGUASES" },
      { municipio: "SANTANA DE PIRAPAMA" },
      { municipio: "SANTANA DO DESERTO" },
      { municipio: "SANTANA DO GARAMBÉU" },
      { municipio: "SANTANA DO JACARÉ" },
      { municipio: "SANTANA DO MANHUAÇU" },
      { municipio: "SANTANA DO PARAÍSO" },
      { municipio: "SANTANA DO RIACHO" },
      { municipio: "SANTANA DOS MONTES" },
      { municipio: "SANTO ANTÔNIO DO AMPARO" },
      { municipio: "SANTO ANTÔNIO DO AVENTUREIRO" },
      { municipio: "SANTO ANTÔNIO DO GRAMA" },
      { municipio: "SANTO ANTÔNIO DO ITAMBÉ" },
      { municipio: "SANTO ANTÔNIO DO JACINTO" },
      { municipio: "SANTO ANTÔNIO DO MONTE" },
      { municipio: "SANTO ANTÔNIO DO RETIRO" },
      { municipio: "SANTO ANTÔNIO DO RIO ABAIXO" },
      { municipio: "SANTO HIPÓLITO" },
      { municipio: "SANTOS DUMONT" },
      { municipio: "SÃO BENTO ABADE" },
      { municipio: "SÃO BRÁS DO SUAÇUÍ" },
      { municipio: "SÃO DOMINGOS DAS DORES" },
      { municipio: "SÃO DOMINGOS DO PRATA" },
      { municipio: "SÃO FÉLIX DE MINAS" },
      { municipio: "SÃO FRANCISCO" },
      { municipio: "SÃO FRANCISCO DE PAULA" },
      { municipio: "SÃO FRANCISCO DE SALES" },
      { municipio: "SÃO FRANCISCO DO GLÓRIA" },
      { municipio: "SÃO GERALDO" },
      { municipio: "SÃO GERALDO DA PIEDADE" },
      { municipio: "SÃO GERALDO DO BAIXIO" },
      { municipio: "SÃO GONÇALO DO ABAETÉ" },
      { municipio: "SÃO GONÇALO DO PARÁ" },
      { municipio: "SÃO GONÇALO DO RIO ABAIXO" },
      { municipio: "SÃO GONÇALO DO RIO PRETO" },
      { municipio: "SÃO GONÇALO DO SAPUCAÍ" },
      { municipio: "SÃO GOTARDO" },
      { municipio: "SÃO JOÃO BATISTA DO GLÓRIA" },
      { municipio: "SÃO JOÃO DA LAGOA" },
      { municipio: "SÃO JOÃO DA MATA" },
      { municipio: "SÃO JOÃO DA PONTE" },
      { municipio: "SÃO JOÃO DAS MISSÕES" },
      { municipio: "SÃO JOÃO DEL REI" },
      { municipio: "SÃO JOÃO DO MANHUAÇU" },
      { municipio: "SÃO JOÃO DO MANTENINHA" },
      { municipio: "SÃO JOÃO DO ORIENTE" },
      { municipio: "SÃO JOÃO DO PACUÍ" },
      { municipio: "SÃO JOÃO DO PARAÍSO" },
      { municipio: "SÃO JOÃO EVANGELISTA" },
      { municipio: "SÃO JOÃO NEPOMUCENO" },
      { municipio: "SÃO JOAQUIM DE BICAS" },
      { municipio: "SÃO JOSÉ DA BARRA" },
      { municipio: "SÃO JOSÉ DA LAPA" },
      { municipio: "SÃO JOSÉ DA SAFIRA" },
      { municipio: "SÃO JOSÉ DA VARGINHA" },
      { municipio: "SÃO JOSÉ DO ALEGRE" },
      { municipio: "SÃO JOSÉ DO DIVINO" },
      { municipio: "SÃO JOSÉ DO GOIABAL" },
      { municipio: "SÃO JOSÉ DO JACURI" },
      { municipio: "SÃO JOSÉ DO MANTIMENTO" },
      { municipio: "SÃO LOURENÇO" },
      { municipio: "SÃO MIGUEL DO ANTA" },
      { municipio: "SÃO PEDRO DA UNIÃO" },
      { municipio: "SÃO PEDRO DO SUAÇUÍ" },
      { municipio: "SÃO PEDRO DOS FERROS" },
      { municipio: "SÃO ROMÃO" },
      { municipio: "SÃO ROQUE DE MINAS" },
      { municipio: "SÃO SEBASTIÃO DA BELA VISTA" },
      { municipio: "SÃO SEBASTIÃO DA VARGEM ALEGRE" },
      { municipio: "SÃO SEBASTIÃO DO ANTA" },
      { municipio: "SÃO SEBASTIÃO DO MARANHÃO" },
      { municipio: "SÃO SEBASTIÃO DO OESTE" },
      { municipio: "SÃO SEBASTIÃO DO PARAÍSO" },
      { municipio: "SÃO SEBASTIÃO DO RIO PRETO" },
      { municipio: "SÃO SEBASTIÃO DO RIO VERDE" },
      { municipio: "SÃO THOMÉ DAS LETRAS" },
      { municipio: "SÃO TIAGO" },
      { municipio: "SÃO TOMÁS DE AQUINO" },
      { municipio: "SÃO VICENTE DE MINAS" },
      { municipio: "SAPUCAÍ-MIRIM" },
      { municipio: "SARDOÁ" },
      { municipio: "SARZEDO" },
      { municipio: "SEM-PEIXE" },
      { municipio: "SENADOR AMARAL" },
      { municipio: "SENADOR CORTES" },
      { municipio: "SENADOR FIRMINO" },
      { municipio: "SENADOR JOSÉ BENTO" },
      { municipio: "SENADOR MODESTINO GONÇALVES" },
      { municipio: "SENHORA DE OLIVEIRA" },
      { municipio: "SENHORA DO PORTO" },
      { municipio: "SENHORA DOS REMÉDIOS" },
      { municipio: "SERICITA" },
      { municipio: "SERITINGA" },
      { municipio: "SERRA AZUL DE MINAS" },
      { municipio: "SERRA DA SAUDADE" },
      { municipio: "SERRA DO SALITRE" },
      { municipio: "SERRA DOS AIMORÉS" },
      { municipio: "SERRANIA" },
      { municipio: "SERRANÓPOLIS DE MINAS" },
      { municipio: "SERRANOS" },
      { municipio: "SERRO" },
      { municipio: "SETE LAGOAS" },
      { municipio: "SETUBINHA" },
      { municipio: "SILVEIRÂNIA" },
      { municipio: "SILVIANÓPOLIS" },
      { municipio: "SIMÃO PEREIRA" },
      { municipio: "SIMONÉSIA" },
      { municipio: "SOBRÁLIA" },
      { municipio: "SOLEDADE DE MINAS" },
      { municipio: "TABULEIRO" },
      { municipio: "TAIOBEIRAS" },
      { municipio: "TAPARUBA" },
      { municipio: "TAPIRA" },
      { municipio: "TAPIRAÍ" },
      { municipio: "TAQUARAÇU DE MINAS" },
      { municipio: "TARUMIRIM" },
      { municipio: "TEIXEIRAS" },
      { municipio: "TEÓFILO OTONI" },
      { municipio: "TIMÓTEO" },
      { municipio: "TIRADENTES" },
      { municipio: "TIROS" },
      { municipio: "TOCANTINS" },
      { municipio: "TOCOS DO MOJI" },
      { municipio: "TOLEDO" },
      { municipio: "TOMBOS" },
      { municipio: "TRÊS CORAÇÕES" },
      { municipio: "TRÊS MARIAS" },
      { municipio: "TRÊS PONTAS" },
      { municipio: "TUMIRITINGA" },
      { municipio: "TUPACIGUARA" },
      { municipio: "TURMALINA" },
      { municipio: "TURVOLÂNDIA" },
      { municipio: "UBÁ" },
      { municipio: "UBAÍ" },
      { municipio: "UBAPORANGA" },
      { municipio: "UBERABA" },
      { municipio: "UBERLÂNDIA" },
      { municipio: "UMBURATIBA" },
      { municipio: "UNAÍ" },
      { municipio: "UNIÃO DE MINAS" },
      { municipio: "URUANA DE MINAS" },
      { municipio: "URUCÂNIA" },
      { municipio: "URUCUIA" },
      { municipio: "VARGEM ALEGRE" },
      { municipio: "VARGEM BONITA" },
      { municipio: "VARGEM GRANDE DO RIO PARDO" },
      { municipio: "VARGINHA" },
      { municipio: "VARJÃO DE MINAS" },
      { municipio: "VÁRZEA DA PALMA" },
      { municipio: "VARZELÂNDIA" },
      { municipio: "VAZANTE" },
      { municipio: "VERDELÂNDIA" },
      { municipio: "VEREDINHA" },
      { municipio: "VERÍSSIMO" },
      { municipio: "VERMELHO NOVO" },
      { municipio: "VESPASIANO" },
      { municipio: "VIÇOSA" },
      { municipio: "VIEIRAS" },
      { municipio: "VIRGEM DA LAPA" },
      { municipio: "VIRGÍNIA" },
      { municipio: "VIRGINÓPOLIS" },
      { municipio: "VIRGOLÂNDIA" },
      { municipio: "VISCONDE DO RIO BRANCO" },
      { municipio: "VOLTA GRANDE" },
      { municipio: "WENCESLAU BRAZ" },
      { municipio: "GUARDA MOR" },
      { municipio: "MG BARAO DO MONTE ALTO" },
      { municipio: "OLHOS D'AGUA" },
      { municipio: "SAPUCAI MIRIM" },
      { municipio: "BRASOPOLIS" },
      { municipio: "AMPARO DA SERRA" },
      { municipio: "SAO TOME DAS LETRAS" },
      { municipio: "SEM PEIXE" },
      { municipio: "DONA EUZEBIA" },
    ],
    estado: "MG",
  },
  {
    municipios: [
      { municipio: "ADAMANTINA" },
      { municipio: "ADOLFO" },
      { municipio: "AGUAÍ" },
      { municipio: "ÁGUAS DA PRATA" },
      { municipio: "ÁGUAS DE LINDÓIA" },
      { municipio: "ÁGUAS DE SANTA BÁRBARA" },
      { municipio: "ÁGUAS DE SÃO PEDRO" },
      { municipio: "AGUDOS" },
      { municipio: "ALAMBARI" },
      { municipio: "ALFREDO MARCONDES" },
      { municipio: "ALTAIR" },
      { municipio: "ALTINÓPOLIS" },
      { municipio: "ALTO ALEGRE" },
      { municipio: "ALUMÍNIO" },
      { municipio: "ÁLVARES FLORENCE" },
      { municipio: "ÁLVARES MACHADO" },
      { municipio: "ÁLVARO DE CARVALHO" },
      { municipio: "ALVINLÂNDIA" },
      { municipio: "AMERICANA" },
      { municipio: "AMÉRICO BRASILIENSE" },
      { municipio: "AMÉRICO DE CAMPOS" },
      { municipio: "AMPARO" },
      { municipio: "ANALÂNDIA" },
      { municipio: "ANDRADINA" },
      { municipio: "ANGATUBA" },
      { municipio: "ANHEMBI" },
      { municipio: "ANHUMAS" },
      { municipio: "APARECIDA" },
      { municipio: "APARECIDA D'OESTE" },
      { municipio: "APIAÍ" },
      { municipio: "ARAÇARIGUAMA" },
      { municipio: "ARAÇATUBA" },
      { municipio: "ARAÇOIABA DA SERRA" },
      { municipio: "ARAMINA" },
      { municipio: "ARANDU" },
      { municipio: "ARAPEÍ" },
      { municipio: "ARARAQUARA" },
      { municipio: "ARARAS" },
      { municipio: "ARCO-ÍRIS" },
      { municipio: "AREALVA" },
      { municipio: "AREIAS" },
      { municipio: "AREIÓPOLIS" },
      { municipio: "ARIRANHA" },
      { municipio: "ARTUR NOGUEIRA" },
      { municipio: "ARUJÁ" },
      { municipio: "ASPÁSIA" },
      { municipio: "ASSIS" },
      { municipio: "ATIBAIA" },
      { municipio: "AURIFLAMA" },
      { municipio: "AVAÍ" },
      { municipio: "AVANHANDAVA" },
      { municipio: "AVARÉ" },
      { municipio: "BADY BASSITT" },
      { municipio: "BALBINOS" },
      { municipio: "BÁLSAMO" },
      { municipio: "BANANAL" },
      { municipio: "BARÃO DE ANTONINA" },
      { municipio: "BARBOSA" },
      { municipio: "BARIRI" },
      { municipio: "BARRA BONITA" },
      { municipio: "BARRA DO CHAPÉU" },
      { municipio: "BARRA DO TURVO" },
      { municipio: "BARRETOS" },
      { municipio: "BARRINHA" },
      { municipio: "BARUERI" },
      { municipio: "BASTOS" },
      { municipio: "BATATAIS" },
      { municipio: "BAURU" },
      { municipio: "BEBEDOURO" },
      { municipio: "BENTO DE ABREU" },
      { municipio: "BERNARDINO DE CAMPOS" },
      { municipio: "BERTIOGA" },
      { municipio: "BILAC" },
      { municipio: "BIRIGUI" },
      { municipio: "BIRITIBA MIRIM" },
      { municipio: "BOA ESPERANÇA DO SUL" },
      { municipio: "BOCAINA" },
      { municipio: "BOFETE" },
      { municipio: "BOITUVA" },
      { municipio: "BOM JESUS DOS PERDÕES" },
      { municipio: "BOM SUCESSO DE ITARARÉ" },
      { municipio: "BORÁ" },
      { municipio: "BORACÉIA" },
      { municipio: "BORBOREMA" },
      { municipio: "BOREBI" },
      { municipio: "BOTUCATU" },
      { municipio: "BRAGANÇA PAULISTA" },
      { municipio: "BRAÚNA" },
      { municipio: "BREJO ALEGRE" },
      { municipio: "BRODOWSKI" },
      { municipio: "BROTAS" },
      { municipio: "BURI" },
      { municipio: "BURITAMA" },
      { municipio: "BURITIZAL" },
      { municipio: "CABRÁLIA PAULISTA" },
      { municipio: "CABREÚVA" },
      { municipio: "CAÇAPAVA" },
      { municipio: "CACHOEIRA PAULISTA" },
      { municipio: "CACONDE" },
      { municipio: "CAFELÂNDIA" },
      { municipio: "CAIABU" },
      { municipio: "CAIEIRAS" },
      { municipio: "CAIUÁ" },
      { municipio: "CAJAMAR" },
      { municipio: "CAJATI" },
      { municipio: "CAJOBI" },
      { municipio: "CAJURU" },
      { municipio: "CAMPINA DO MONTE ALEGRE" },
      { municipio: "CAMPINAS" },
      { municipio: "CAMPO LIMPO PAULISTA" },
      { municipio: "CAMPOS DO JORDÃO" },
      { municipio: "CAMPOS NOVOS PAULISTA" },
      { municipio: "CANANÉIA" },
      { municipio: "CANAS" },
      { municipio: "CÂNDIDO MOTA" },
      { municipio: "CÂNDIDO RODRIGUES" },
      { municipio: "CANITAR" },
      { municipio: "CAPÃO BONITO" },
      { municipio: "CAPELA DO ALTO" },
      { municipio: "CAPIVARI" },
      { municipio: "CARAGUATATUBA" },
      { municipio: "CARAPICUÍBA" },
      { municipio: "CARDOSO" },
      { municipio: "CASA BRANCA" },
      { municipio: "CÁSSIA DOS COQUEIROS" },
      { municipio: "CASTILHO" },
      { municipio: "CATANDUVA" },
      { municipio: "CATIGUÁ" },
      { municipio: "CEDRAL" },
      { municipio: "CERQUEIRA CÉSAR" },
      { municipio: "CERQUILHO" },
      { municipio: "CESÁRIO LANGE" },
      { municipio: "CHARQUEADA" },
      { municipio: "CHAVANTES" },
      { municipio: "CLEMENTINA" },
      { municipio: "COLINA" },
      { municipio: "COLÔMBIA" },
      { municipio: "CONCHAL" },
      { municipio: "CONCHAS" },
      { municipio: "CORDEIRÓPOLIS" },
      { municipio: "COROADOS" },
      { municipio: "CORONEL MACEDO" },
      { municipio: "CORUMBATAÍ" },
      { municipio: "COSMÓPOLIS" },
      { municipio: "COSMORAMA" },
      { municipio: "COTIA" },
      { municipio: "CRAVINHOS" },
      { municipio: "CRISTAIS PAULISTA" },
      { municipio: "CRUZÁLIA" },
      { municipio: "CRUZEIRO" },
      { municipio: "CUBATÃO" },
      { municipio: "CUNHA" },
      { municipio: "DESCALVADO" },
      { municipio: "DIADEMA" },
      { municipio: "DIRCE REIS" },
      { municipio: "DIVINOLÂNDIA" },
      { municipio: "DOBRADA" },
      { municipio: "DOIS CÓRREGOS" },
      { municipio: "DOLCINÓPOLIS" },
      { municipio: "DOURADO" },
      { municipio: "DRACENA" },
      { municipio: "DUARTINA" },
      { municipio: "DUMONT" },
      { municipio: "ECHAPORÃ" },
      { municipio: "ELDORADO" },
      { municipio: "ELIAS FAUSTO" },
      { municipio: "ELISIÁRIO" },
      { municipio: "EMBAÚBA" },
      { municipio: "EMBU DAS ARTES" },
      { municipio: "EMBU-GUAÇU" },
      { municipio: "EMILIANÓPOLIS" },
      { municipio: "ENGENHEIRO COELHO" },
      { municipio: "ESPÍRITO SANTO DO PINHAL" },
      { municipio: "ESPÍRITO SANTO DO TURVO" },
      { municipio: "ESTIVA GERBI" },
      { municipio: "ESTRELA DO NORTE" },
      { municipio: "ESTRELA D'OESTE" },
      { municipio: "EUCLIDES DA CUNHA PAULISTA" },
      { municipio: "FARTURA" },
      { municipio: "FERNANDO PRESTES" },
      { municipio: "FERNANDÓPOLIS" },
      { municipio: "FERNÃO" },
      { municipio: "FERRAZ DE VASCONCELOS" },
      { municipio: "FLORA RICA" },
      { municipio: "FLOREAL" },
      { municipio: "FLÓRIDA PAULISTA" },
      { municipio: "FLORÍNEA" },
      { municipio: "FRANCA" },
      { municipio: "FRANCISCO MORATO" },
      { municipio: "FRANCO DA ROCHA" },
      { municipio: "GABRIEL MONTEIRO" },
      { municipio: "GÁLIA" },
      { municipio: "GARÇA" },
      { municipio: "GASTÃO VIDIGAL" },
      { municipio: "GAVIÃO PEIXOTO" },
      { municipio: "GENERAL SALGADO" },
      { municipio: "GETULINA" },
      { municipio: "GLICÉRIO" },
      { municipio: "GUAIÇARA" },
      { municipio: "GUAIMBÊ" },
      { municipio: "GUAÍRA" },
      { municipio: "GUAPIAÇU" },
      { municipio: "GUAPIARA" },
      { municipio: "GUARÁ" },
      { municipio: "GUARAÇAÍ" },
      { municipio: "GUARACI" },
      { municipio: "GUARANI D'OESTE" },
      { municipio: "GUARANTÃ" },
      { municipio: "GUARARAPES" },
      { municipio: "GUARAREMA" },
      { municipio: "GUARATINGUETÁ" },
      { municipio: "GUAREÍ" },
      { municipio: "GUARIBA" },
      { municipio: "GUARUJÁ" },
      { municipio: "GUARULHOS" },
      { municipio: "GUATAPARÁ" },
      { municipio: "GUZOLÂNDIA" },
      { municipio: "HERCULÂNDIA" },
      { municipio: "HOLAMBRA" },
      { municipio: "HORTOLÂNDIA" },
      { municipio: "IACANGA" },
      { municipio: "IACRI" },
      { municipio: "IARAS" },
      { municipio: "IBATÉ" },
      { municipio: "IBIRÁ" },
      { municipio: "IBIRAREMA" },
      { municipio: "IBITINGA" },
      { municipio: "IBIÚNA" },
      { municipio: "ICÉM" },
      { municipio: "IEPÊ" },
      { municipio: "IGARAÇU DO TIETÊ" },
      { municipio: "IGARAPAVA" },
      { municipio: "IGARATÁ" },
      { municipio: "IGUAPE" },
      { municipio: "ILHA COMPRIDA" },
      { municipio: "ILHA SOLTEIRA" },
      { municipio: "ILHABELA" },
      { municipio: "INDAIATUBA" },
      { municipio: "INDIANA" },
      { municipio: "INDIAPORÃ" },
      { municipio: "INÚBIA PAULISTA" },
      { municipio: "IPAUSSU" },
      { municipio: "IPERÓ" },
      { municipio: "IPEÚNA" },
      { municipio: "IPIGUÁ" },
      { municipio: "IPORANGA" },
      { municipio: "IPUÃ" },
      { municipio: "IRACEMÁPOLIS" },
      { municipio: "IRAPUÃ" },
      { municipio: "IRAPURU" },
      { municipio: "ITABERÁ" },
      { municipio: "ITAÍ" },
      { municipio: "ITAJOBI" },
      { municipio: "ITAJU" },
      { municipio: "ITANHAÉM" },
      { municipio: "ITAOCA" },
      { municipio: "ITAPECERICA DA SERRA" },
      { municipio: "ITAPETININGA" },
      { municipio: "ITAPEVA" },
      { municipio: "ITAPEVI" },
      { municipio: "ITAPIRA" },
      { municipio: "ITAPIRAPUÃ PAULISTA" },
      { municipio: "ITÁPOLIS" },
      { municipio: "ITAPORANGA" },
      { municipio: "ITAPUÍ" },
      { municipio: "ITAPURA" },
      { municipio: "ITAQUAQUECETUBA" },
      { municipio: "ITARARÉ" },
      { municipio: "ITARIRI" },
      { municipio: "ITATIBA" },
      { municipio: "ITATINGA" },
      { municipio: "ITIRAPINA" },
      { municipio: "ITIRAPUÃ" },
      { municipio: "ITOBI" },
      { municipio: "ITU" },
      { municipio: "ITUPEVA" },
      { municipio: "ITUVERAVA" },
      { municipio: "JABORANDI" },
      { municipio: "JABOTICABAL" },
      { municipio: "JACAREÍ" },
      { municipio: "JACI" },
      { municipio: "JACUPIRANGA" },
      { municipio: "JAGUARIÚNA" },
      { municipio: "JALES" },
      { municipio: "JAMBEIRO" },
      { municipio: "JANDIRA" },
      { municipio: "JARDINÓPOLIS" },
      { municipio: "JARINU" },
      { municipio: "JAÚ" },
      { municipio: "JERIQUARA" },
      { municipio: "JOANÓPOLIS" },
      { municipio: "JOÃO RAMALHO" },
      { municipio: "JOSÉ BONIFÁCIO" },
      { municipio: "JÚLIO MESQUITA" },
      { municipio: "JUMIRIM" },
      { municipio: "JUNDIAÍ" },
      { municipio: "JUNQUEIRÓPOLIS" },
      { municipio: "JUQUIÁ" },
      { municipio: "JUQUITIBA" },
      { municipio: "LAGOINHA" },
      { municipio: "LARANJAL PAULISTA" },
      { municipio: "LAVÍNIA" },
      { municipio: "LAVRINHAS" },
      { municipio: "LEME" },
      { municipio: "LENÇÓIS PAULISTA" },
      { municipio: "LIMEIRA" },
      { municipio: "LINDÓIA" },
      { municipio: "LINS" },
      { municipio: "LORENA" },
      { municipio: "LOURDES" },
      { municipio: "LOUVEIRA" },
      { municipio: "LUCÉLIA" },
      { municipio: "LUCIANÓPOLIS" },
      { municipio: "LUÍS ANTÔNIO" },
      { municipio: "LUIZIÂNIA" },
      { municipio: "LUPÉRCIO" },
      { municipio: "LUTÉCIA" },
      { municipio: "MACATUBA" },
      { municipio: "MACAUBAL" },
      { municipio: "MACEDÔNIA" },
      { municipio: "MAGDA" },
      { municipio: "MAIRINQUE" },
      { municipio: "MAIRIPORÃ" },
      { municipio: "MANDURI" },
      { municipio: "MARABÁ PAULISTA" },
      { municipio: "MARACAÍ" },
      { municipio: "MARAPOAMA" },
      { municipio: "MARIÁPOLIS" },
      { municipio: "MARÍLIA" },
      { municipio: "MARINÓPOLIS" },
      { municipio: "MARTINÓPOLIS" },
      { municipio: "MATÃO" },
      { municipio: "MAUÁ" },
      { municipio: "MENDONÇA" },
      { municipio: "MERIDIANO" },
      { municipio: "MESÓPOLIS" },
      { municipio: "MIGUELÓPOLIS" },
      { municipio: "MINEIROS DO TIETÊ" },
      { municipio: "MIRA ESTRELA" },
      { municipio: "MIRACATU" },
      { municipio: "MIRANDÓPOLIS" },
      { municipio: "MIRANTE DO PARANAPANEMA" },
      { municipio: "MIRASSOL" },
      { municipio: "MIRASSOLÂNDIA" },
      { municipio: "MOCOCA" },
      { municipio: "MOGI DAS CRUZES" },
      { municipio: "MOGI GUAÇU" },
      { municipio: "MOGI MIRIM" },
      { municipio: "MOMBUCA" },
      { municipio: "MONÇÕES" },
      { municipio: "MONGAGUÁ" },
      { municipio: "MONTE ALEGRE DO SUL" },
      { municipio: "MONTE ALTO" },
      { municipio: "MONTE APRAZÍVEL" },
      { municipio: "MONTE AZUL PAULISTA" },
      { municipio: "MONTE CASTELO" },
      { municipio: "MONTE MOR" },
      { municipio: "MONTEIRO LOBATO" },
      { municipio: "MORRO AGUDO" },
      { municipio: "MORUNGABA" },
      { municipio: "MOTUCA" },
      { municipio: "MURUTINGA DO SUL" },
      { municipio: "NANTES" },
      { municipio: "NARANDIBA" },
      { municipio: "NATIVIDADE DA SERRA" },
      { municipio: "NAZARÉ PAULISTA" },
      { municipio: "NEVES PAULISTA" },
      { municipio: "NHANDEARA" },
      { municipio: "NIPOÃ" },
      { municipio: "NOVA ALIANÇA" },
      { municipio: "NOVA CAMPINA" },
      { municipio: "NOVA CANAÃ PAULISTA" },
      { municipio: "NOVA CASTILHO" },
      { municipio: "NOVA EUROPA" },
      { municipio: "NOVA GRANADA" },
      { municipio: "NOVA GUATAPORANGA" },
      { municipio: "NOVA INDEPENDÊNCIA" },
      { municipio: "NOVA LUZITÂNIA" },
      { municipio: "NOVA ODESSA" },
      { municipio: "NOVAIS" },
      { municipio: "NOVO HORIZONTE" },
      { municipio: "NUPORANGA" },
      { municipio: "OCAUÇU" },
      { municipio: "ÓLEO" },
      { municipio: "OLÍMPIA" },
      { municipio: "ONDA VERDE" },
      { municipio: "ORIENTE" },
      { municipio: "ORINDIÚVA" },
      { municipio: "ORLÂNDIA" },
      { municipio: "OSASCO" },
      { municipio: "OSCAR BRESSANE" },
      { municipio: "OSVALDO CRUZ" },
      { municipio: "OURINHOS" },
      { municipio: "OURO VERDE" },
      { municipio: "OUROESTE" },
      { municipio: "PACAEMBU" },
      { municipio: "PALESTINA" },
      { municipio: "PALMARES PAULISTA" },
      { municipio: "PALMEIRA D'OESTE" },
      { municipio: "PALMITAL" },
      { municipio: "PANORAMA" },
      { municipio: "PARAGUAÇU PAULISTA" },
      { municipio: "PARAIBUNA" },
      { municipio: "PARAÍSO" },
      { municipio: "PARANAPANEMA" },
      { municipio: "PARANAPUÃ" },
      { municipio: "PARAPUÃ" },
      { municipio: "PARDINHO" },
      { municipio: "PARIQUERA-AÇU" },
      { municipio: "PARISI" },
      { municipio: "PATROCÍNIO PAULISTA" },
      { municipio: "PAULICÉIA" },
      { municipio: "PAULÍNIA" },
      { municipio: "PAULISTÂNIA" },
      { municipio: "PAULO DE FARIA" },
      { municipio: "PEDERNEIRAS" },
      { municipio: "PEDRA BELA" },
      { municipio: "PEDRANÓPOLIS" },
      { municipio: "PEDREGULHO" },
      { municipio: "PEDREIRA" },
      { municipio: "PEDRINHAS PAULISTA" },
      { municipio: "PEDRO DE TOLEDO" },
      { municipio: "PENÁPOLIS" },
      { municipio: "PEREIRA BARRETO" },
      { municipio: "PEREIRAS" },
      { municipio: "PERUÍBE" },
      { municipio: "PIACATU" },
      { municipio: "PIEDADE" },
      { municipio: "PILAR DO SUL" },
      { municipio: "PINDAMONHANGABA" },
      { municipio: "PINDORAMA" },
      { municipio: "PINHALZINHO" },
      { municipio: "PIQUEROBI" },
      { municipio: "PIQUETE" },
      { municipio: "PIRACAIA" },
      { municipio: "PIRACICABA" },
      { municipio: "PIRAJU" },
      { municipio: "PIRAJUÍ" },
      { municipio: "PIRANGI" },
      { municipio: "PIRAPORA DO BOM JESUS" },
      { municipio: "PIRAPOZINHO" },
      { municipio: "PIRASSUNUNGA" },
      { municipio: "PIRATININGA" },
      { municipio: "PITANGUEIRAS" },
      { municipio: "PLANALTO" },
      { municipio: "PLATINA" },
      { municipio: "POÁ" },
      { municipio: "POLONI" },
      { municipio: "POMPÉIA" },
      { municipio: "PONGAÍ" },
      { municipio: "PONTAL" },
      { municipio: "PONTALINDA" },
      { municipio: "PONTES GESTAL" },
      { municipio: "POPULINA" },
      { municipio: "PORANGABA" },
      { municipio: "PORTO FELIZ" },
      { municipio: "PORTO FERREIRA" },
      { municipio: "POTIM" },
      { municipio: "POTIRENDABA" },
      { municipio: "PRACINHA" },
      { municipio: "PRADÓPOLIS" },
      { municipio: "PRAIA GRANDE" },
      { municipio: "PRATÂNIA" },
      { municipio: "PRESIDENTE ALVES" },
      { municipio: "PRESIDENTE BERNARDES" },
      { municipio: "PRESIDENTE EPITÁCIO" },
      { municipio: "PRESIDENTE PRUDENTE" },
      { municipio: "PRESIDENTE VENCESLAU" },
      { municipio: "PROMISSÃO" },
      { municipio: "QUADRA" },
      { municipio: "QUATÁ" },
      { municipio: "QUEIROZ" },
      { municipio: "QUELUZ" },
      { municipio: "QUINTANA" },
      { municipio: "RAFARD" },
      { municipio: "RANCHARIA" },
      { municipio: "REDENÇÃO DA SERRA" },
      { municipio: "REGENTE FEIJÓ" },
      { municipio: "REGINÓPOLIS" },
      { municipio: "REGISTRO" },
      { municipio: "RESTINGA" },
      { municipio: "RIBEIRA" },
      { municipio: "RIBEIRÃO BONITO" },
      { municipio: "RIBEIRÃO BRANCO" },
      { municipio: "RIBEIRÃO CORRENTE" },
      { municipio: "RIBEIRÃO DO SUL" },
      { municipio: "RIBEIRÃO DOS ÍNDIOS" },
      { municipio: "RIBEIRÃO GRANDE" },
      { municipio: "RIBEIRÃO PIRES" },
      { municipio: "RIBEIRÃO PRETO" },
      { municipio: "RIFAINA" },
      { municipio: "RINCÃO" },
      { municipio: "RINÓPOLIS" },
      { municipio: "RIO CLARO" },
      { municipio: "RIO DAS PEDRAS" },
      { municipio: "RIO GRANDE DA SERRA" },
      { municipio: "RIOLÂNDIA" },
      { municipio: "RIVERSUL" },
      { municipio: "ROSANA" },
      { municipio: "ROSEIRA" },
      { municipio: "RUBIÁCEA" },
      { municipio: "RUBINÉIA" },
      { municipio: "SABINO" },
      { municipio: "SAGRES" },
      { municipio: "SALES" },
      { municipio: "SALES OLIVEIRA" },
      { municipio: "SALESÓPOLIS" },
      { municipio: "SALMOURÃO" },
      { municipio: "SALTINHO" },
      { municipio: "SALTO" },
      { municipio: "SALTO DE PIRAPORA" },
      { municipio: "SALTO GRANDE" },
      { municipio: "SANDOVALINA" },
      { municipio: "SANTA ADÉLIA" },
      { municipio: "SANTA ALBERTINA" },
      { municipio: "SANTA BÁRBARA D'OESTE" },
      { municipio: "SANTA BRANCA" },
      { municipio: "SANTA CLARA D'OESTE" },
      { municipio: "SANTA CRUZ DA CONCEIÇÃO" },
      { municipio: "SANTA CRUZ DA ESPERANÇA" },
      { municipio: "SANTA CRUZ DAS PALMEIRAS" },
      { municipio: "SANTA CRUZ DO RIO PARDO" },
      { municipio: "SANTA ERNESTINA" },
      { municipio: "SANTA FÉ DO SUL" },
      { municipio: "SANTA GERTRUDES" },
      { municipio: "SANTA ISABEL" },
      { municipio: "SANTA LÚCIA" },
      { municipio: "SANTA MARIA DA SERRA" },
      { municipio: "SANTA MERCEDES" },
      { municipio: "SANTA RITA DO PASSA QUATRO" },
      { municipio: "SANTA RITA D'OESTE" },
      { municipio: "SANTA ROSA DE VITERBO" },
      { municipio: "SANTA SALETE" },
      { municipio: "SANTANA DA PONTE PENSA" },
      { municipio: "SANTANA DE PARNAÍBA" },
      { municipio: "SANTO ANASTÁCIO" },
      { municipio: "SANTO ANDRÉ" },
      { municipio: "SANTO ANTÔNIO DA ALEGRIA" },
      { municipio: "SANTO ANTÔNIO DE POSSE" },
      { municipio: "SANTO ANTÔNIO DO ARACANGUÁ" },
      { municipio: "SANTO ANTÔNIO DO JARDIM" },
      { municipio: "SANTO ANTÔNIO DO PINHAL" },
      { municipio: "SANTO EXPEDITO" },
      { municipio: "SANTÓPOLIS DO AGUAPEÍ" },
      { municipio: "SANTOS" },
      { municipio: "SÃO BENTO DO SAPUCAÍ" },
      { municipio: "SÃO BERNARDO DO CAMPO" },
      { municipio: "SÃO CAETANO DO SUL" },
      { municipio: "SÃO CARLOS" },
      { municipio: "SÃO FRANCISCO" },
      { municipio: "SÃO JOÃO DA BOA VISTA" },
      { municipio: "SÃO JOÃO DAS DUAS PONTES" },
      { municipio: "SÃO JOÃO DE IRACEMA" },
      { municipio: "SÃO JOÃO DO PAU D'ALHO" },
      { municipio: "SÃO JOAQUIM DA BARRA" },
      { municipio: "SÃO JOSÉ DA BELA VISTA" },
      { municipio: "SÃO JOSÉ DO BARREIRO" },
      { municipio: "SÃO JOSÉ DO RIO PARDO" },
      { municipio: "SÃO JOSÉ DO RIO PRETO" },
      { municipio: "SÃO JOSÉ DOS CAMPOS" },
      { municipio: "SÃO LOURENÇO DA SERRA" },
      { municipio: "SÃO LUIZ DO PARAITINGA" },
      { municipio: "SÃO MANUEL" },
      { municipio: "SÃO MIGUEL ARCANJO" },
      { municipio: "SÃO PAULO" },
      { municipio: "SÃO PEDRO" },
      { municipio: "SÃO PEDRO DO TURVO" },
      { municipio: "SÃO ROQUE" },
      { municipio: "SÃO SEBASTIÃO" },
      { municipio: "SÃO SEBASTIÃO DA GRAMA" },
      { municipio: "SÃO SIMÃO" },
      { municipio: "SÃO VICENTE" },
      { municipio: "SARAPUÍ" },
      { municipio: "SARUTAIÁ" },
      { municipio: "SEBASTIANÓPOLIS DO SUL" },
      { municipio: "SERRA AZUL" },
      { municipio: "SERRA NEGRA" },
      { municipio: "SERRANA" },
      { municipio: "SERTÃOZINHO" },
      { municipio: "SETE BARRAS" },
      { municipio: "SEVERÍNIA" },
      { municipio: "SILVEIRAS" },
      { municipio: "SOCORRO" },
      { municipio: "SOROCABA" },
      { municipio: "SUD MENNUCCI" },
      { municipio: "SUMARÉ" },
      { municipio: "SUZANÁPOLIS" },
      { municipio: "SUZANO" },
      { municipio: "TABAPUÃ" },
      { municipio: "TABATINGA" },
      { municipio: "TABOÃO DA SERRA" },
      { municipio: "TACIBA" },
      { municipio: "TAGUAÍ" },
      { municipio: "TAIAÇU" },
      { municipio: "TAIÚVA" },
      { municipio: "TAMBAÚ" },
      { municipio: "TANABI" },
      { municipio: "TAPIRAÍ" },
      { municipio: "TAPIRATIBA" },
      { municipio: "TAQUARAL" },
      { municipio: "TAQUARITINGA" },
      { municipio: "TAQUARITUBA" },
      { municipio: "TAQUARIVAÍ" },
      { municipio: "TARABAI" },
      { municipio: "TARUMÃ" },
      { municipio: "TATUÍ" },
      { municipio: "TAUBATÉ" },
      { municipio: "TEJUPÁ" },
      { municipio: "TEODORO SAMPAIO" },
      { municipio: "TERRA ROXA" },
      { municipio: "TIETÊ" },
      { municipio: "TIMBURI" },
      { municipio: "TORRE DE PEDRA" },
      { municipio: "TORRINHA" },
      { municipio: "TRABIJU" },
      { municipio: "TREMEMBÉ" },
      { municipio: "TRÊS FRONTEIRAS" },
      { municipio: "TUIUTI" },
      { municipio: "TUPÃ" },
      { municipio: "TUPI PAULISTA" },
      { municipio: "TURIÚBA" },
      { municipio: "TURMALINA" },
      { municipio: "UBARANA" },
      { municipio: "UBATUBA" },
      { municipio: "UBIRAJARA" },
      { municipio: "UCHOA" },
      { municipio: "UNIÃO PAULISTA" },
      { municipio: "URÂNIA" },
      { municipio: "URU" },
      { municipio: "URUPÊS" },
      { municipio: "VALENTIM GENTIL" },
      { municipio: "VALINHOS" },
      { municipio: "VALPARAÍSO" },
      { municipio: "VARGEM" },
      { municipio: "VARGEM GRANDE DO SUL" },
      { municipio: "VARGEM GRANDE PAULISTA" },
      { municipio: "VÁRZEA PAULISTA" },
      { municipio: "VERA CRUZ" },
      { municipio: "VINHEDO" },
      { municipio: "VIRADOURO" },
      { municipio: "VISTA ALEGRE DO ALTO" },
      { municipio: "VITÓRIA BRASIL" },
      { municipio: "VOTORANTIM" },
      { municipio: "VOTUPORANGA" },
      { municipio: "ZACARIAS" },
      { municipio: "EMBU GUACU" },
      { municipio: "PARIQUERA ACU" },
      { municipio: "ARCO IRIS" },
    ],
    estado: "SP",
  },
  {
    municipios: [
      { municipio: "ACRELÂNDIA" },
      { municipio: "ASSIS BRASIL" },
      { municipio: "BRASILÉIA" },
      { municipio: "BUJARI" },
      { municipio: "CAPIXABA" },
      { municipio: "CRUZEIRO DO SUL" },
      { municipio: "EPITACIOLÂNDIA" },
      { municipio: "FEIJÓ" },
      { municipio: "JORDÃO" },
      { municipio: "MÂNCIO LIMA" },
      { municipio: "MANOEL URBANO" },
      { municipio: "MARECHAL THAUMATURGO" },
      { municipio: "PLÁCIDO DE CASTRO" },
      { municipio: "PORTO ACRE" },
      { municipio: "PORTO WALTER" },
      { municipio: "RIO BRANCO" },
      { municipio: "RODRIGUES ALVES" },
      { municipio: "SANTA ROSA DO PURUS" },
      { municipio: "SENA MADUREIRA" },
      { municipio: "SENADOR GUIOMARD" },
      { municipio: "TARAUACÁ" },
      { municipio: "XAPURI" },
    ],
    estado: "AC",
  },
  {
    municipios: [
      { municipio: "ABAIARA" },
      { municipio: "ACARAPE" },
      { municipio: "ACARAÚ" },
      { municipio: "ACOPIARA" },
      { municipio: "AIUABA" },
      { municipio: "ALCÂNTARAS" },
      { municipio: "ALTANEIRA" },
      { municipio: "ALTO SANTO" },
      { municipio: "AMONTADA" },
      { municipio: "ANTONINA DO NORTE" },
      { municipio: "APUIARÉS" },
      { municipio: "AQUIRAZ" },
      { municipio: "ARACATI" },
      { municipio: "ARACOIABA" },
      { municipio: "ARARENDÁ" },
      { municipio: "ARARIPE" },
      { municipio: "ARATUBA" },
      { municipio: "ARNEIROZ" },
      { municipio: "ASSARÉ" },
      { municipio: "AURORA" },
      { municipio: "BAIXIO" },
      { municipio: "BANABUIÚ" },
      { municipio: "BARBALHA" },
      { municipio: "BARREIRA" },
      { municipio: "BARRO" },
      { municipio: "BARROQUINHA" },
      { municipio: "BATURITÉ" },
      { municipio: "BEBERIBE" },
      { municipio: "BELA CRUZ" },
      { municipio: "BOA VIAGEM" },
      { municipio: "BREJO SANTO" },
      { municipio: "CAMOCIM" },
      { municipio: "CAMPOS SALES" },
      { municipio: "CANINDÉ" },
      { municipio: "CAPISTRANO" },
      { municipio: "CARIDADE" },
      { municipio: "CARIRÉ" },
      { municipio: "CARIRIAÇU" },
      { municipio: "CARIÚS" },
      { municipio: "CARNAUBAL" },
      { municipio: "CASCAVEL" },
      { municipio: "CATARINA" },
      { municipio: "CATUNDA" },
      { municipio: "CAUCAIA" },
      { municipio: "CEDRO" },
      { municipio: "CHAVAL" },
      { municipio: "CHORÓ" },
      { municipio: "CHOROZINHO" },
      { municipio: "COREAÚ" },
      { municipio: "CRATEÚS" },
      { municipio: "CRATO" },
      { municipio: "CROATÁ" },
      { municipio: "CRUZ" },
      { municipio: "DEPUTADO IRAPUAN PINHEIRO" },
      { municipio: "ERERÊ" },
      { municipio: "EUSÉBIO" },
      { municipio: "FARIAS BRITO" },
      { municipio: "FORQUILHA" },
      { municipio: "FORTALEZA" },
      { municipio: "FORTIM" },
      { municipio: "FRECHEIRINHA" },
      { municipio: "GENERAL SAMPAIO" },
      { municipio: "GRAÇA" },
      { municipio: "GRANJA" },
      { municipio: "GRANJEIRO" },
      { municipio: "GROAÍRAS" },
      { municipio: "GUAIÚBA" },
      { municipio: "GUARACIABA DO NORTE" },
      { municipio: "GUARAMIRANGA" },
      { municipio: "HIDROLÂNDIA" },
      { municipio: "HORIZONTE" },
      { municipio: "IBARETAMA" },
      { municipio: "IBIAPINA" },
      { municipio: "IBICUITINGA" },
      { municipio: "ICAPUÍ" },
      { municipio: "ICÓ" },
      { municipio: "IGUATU" },
      { municipio: "INDEPENDÊNCIA" },
      { municipio: "IPAPORANGA" },
      { municipio: "IPAUMIRIM" },
      { municipio: "IPU" },
      { municipio: "IPUEIRAS" },
      { municipio: "IRACEMA" },
      { municipio: "IRAUÇUBA" },
      { municipio: "ITAIÇABA" },
      { municipio: "ITAITINGA" },
      { municipio: "ITAPAJÉ" },
      { municipio: "ITAPIPOCA" },
      { municipio: "ITAPIÚNA" },
      { municipio: "ITAREMA" },
      { municipio: "ITATIRA" },
      { municipio: "JAGUARETAMA" },
      { municipio: "JAGUARIBARA" },
      { municipio: "JAGUARIBE" },
      { municipio: "JAGUARUANA" },
      { municipio: "JARDIM" },
      { municipio: "JATI" },
      { municipio: "JIJOCA DE JERICOACOARA" },
      { municipio: "JUAZEIRO DO NORTE" },
      { municipio: "JUCÁS" },
      { municipio: "LAVRAS DA MANGABEIRA" },
      { municipio: "LIMOEIRO DO NORTE" },
      { municipio: "MADALENA" },
      { municipio: "MARACANAÚ" },
      { municipio: "MARANGUAPE" },
      { municipio: "MARCO" },
      { municipio: "MARTINÓPOLE" },
      { municipio: "MASSAPÊ" },
      { municipio: "MAURITI" },
      { municipio: "MERUOCA" },
      { municipio: "MILAGRES" },
      { municipio: "MILHÃ" },
      { municipio: "MIRAÍMA" },
      { municipio: "MISSÃO VELHA" },
      { municipio: "MOMBAÇA" },
      { municipio: "MONSENHOR TABOSA" },
      { municipio: "MORADA NOVA" },
      { municipio: "MORAÚJO" },
      { municipio: "MORRINHOS" },
      { municipio: "MUCAMBO" },
      { municipio: "MULUNGU" },
      { municipio: "NOVA OLINDA" },
      { municipio: "NOVA RUSSAS" },
      { municipio: "NOVO ORIENTE" },
      { municipio: "OCARA" },
      { municipio: "ORÓS" },
      { municipio: "PACAJUS" },
      { municipio: "PACATUBA" },
      { municipio: "PACOTI" },
      { municipio: "PACUJÁ" },
      { municipio: "PALHANO" },
      { municipio: "PALMÁCIA" },
      { municipio: "PARACURU" },
      { municipio: "PARAIPABA" },
      { municipio: "PARAMBU" },
      { municipio: "PARAMOTI" },
      { municipio: "PEDRA BRANCA" },
      { municipio: "PENAFORTE" },
      { municipio: "PENTECOSTE" },
      { municipio: "PEREIRO" },
      { municipio: "PINDORETAMA" },
      { municipio: "PIQUET CARNEIRO" },
      { municipio: "PIRES FERREIRA" },
      { municipio: "PORANGA" },
      { municipio: "PORTEIRAS" },
      { municipio: "POTENGI" },
      { municipio: "POTIRETAMA" },
      { municipio: "QUITERIANÓPOLIS" },
      { municipio: "QUIXADÁ" },
      { municipio: "QUIXELÔ" },
      { municipio: "QUIXERAMOBIM" },
      { municipio: "QUIXERÉ" },
      { municipio: "REDENÇÃO" },
      { municipio: "RERIUTABA" },
      { municipio: "RUSSAS" },
      { municipio: "SABOEIRO" },
      { municipio: "SALITRE" },
      { municipio: "SANTA QUITÉRIA" },
      { municipio: "SANTANA DO ACARAÚ" },
      { municipio: "SANTANA DO CARIRI" },
      { municipio: "SÃO BENEDITO" },
      { municipio: "SÃO GONÇALO DO AMARANTE" },
      { municipio: "SÃO JOÃO DO JAGUARIBE" },
      { municipio: "SÃO LUÍS DO CURU" },
      { municipio: "SENADOR POMPEU" },
      { municipio: "SENADOR SÁ" },
      { municipio: "SOBRAL" },
      { municipio: "SOLONÓPOLE" },
      { municipio: "TABULEIRO DO NORTE" },
      { municipio: "TAMBORIL" },
      { municipio: "TARRAFAS" },
      { municipio: "TAUÁ" },
      { municipio: "TEJUÇUOCA" },
      { municipio: "TIANGUÁ" },
      { municipio: "TRAIRI" },
      { municipio: "TURURU" },
      { municipio: "UBAJARA" },
      { municipio: "UMARI" },
      { municipio: "UMIRIM" },
      { municipio: "URUBURETAMA" },
      { municipio: "URUOCA" },
      { municipio: "VARJOTA" },
      { municipio: "VÁRZEA ALEGRE" },
      { municipio: "VIÇOSA DO CEARÁ" },
    ],
    estado: "CE",
  },
  {
    municipios: [
      { municipio: "ÁGUA CLARA" },
      { municipio: "ALCINÓPOLIS" },
      { municipio: "AMAMBAI" },
      { municipio: "ANASTÁCIO" },
      { municipio: "ANAURILÂNDIA" },
      { municipio: "ANGÉLICA" },
      { municipio: "ANTÔNIO JOÃO" },
      { municipio: "APARECIDA DO TABOADO" },
      { municipio: "AQUIDAUANA" },
      { municipio: "ARAL MOREIRA" },
      { municipio: "BANDEIRANTES" },
      { municipio: "BATAGUASSU" },
      { municipio: "BATAYPORÃ" },
      { municipio: "BELA VISTA" },
      { municipio: "BODOQUENA" },
      { municipio: "BONITO" },
      { municipio: "BRASILÂNDIA" },
      { municipio: "CAARAPÓ" },
      { municipio: "CAMAPUÃ" },
      { municipio: "CAMPO GRANDE" },
      { municipio: "CARACOL" },
      { municipio: "CASSILÂNDIA" },
      { municipio: "CHAPADÃO DO SUL" },
      { municipio: "CORGUINHO" },
      { municipio: "CORONEL SAPUCAIA" },
      { municipio: "CORUMBÁ" },
      { municipio: "COSTA RICA" },
      { municipio: "COXIM" },
      { municipio: "DEODÁPOLIS" },
      { municipio: "DOIS IRMÃOS DO BURITI" },
      { municipio: "DOURADINA" },
      { municipio: "DOURADOS" },
      { municipio: "ELDORADO" },
      { municipio: "FÁTIMA DO SUL" },
      { municipio: "FIGUEIRÃO" },
      { municipio: "GLÓRIA DE DOURADOS" },
      { municipio: "GUIA LOPES DA LAGUNA" },
      { municipio: "IGUATEMI" },
      { municipio: "INOCÊNCIA" },
      { municipio: "ITAPORÃ" },
      { municipio: "ITAQUIRAÍ" },
      { municipio: "IVINHEMA" },
      { municipio: "JAPORÃ" },
      { municipio: "JARAGUARI" },
      { municipio: "JARDIM" },
      { municipio: "JATEÍ" },
      { municipio: "JUTI" },
      { municipio: "LADÁRIO" },
      { municipio: "LAGUNA CARAPÃ" },
      { municipio: "MARACAJU" },
      { municipio: "MIRANDA" },
      { municipio: "MUNDO NOVO" },
      { municipio: "NAVIRAÍ" },
      { municipio: "NIOAQUE" },
      { municipio: "NOVA ALVORADA DO SUL" },
      { municipio: "NOVA ANDRADINA" },
      { municipio: "NOVO HORIZONTE DO SUL" },
      { municipio: "PARAÍSO DAS ÁGUAS" },
      { municipio: "PARANAÍBA" },
      { municipio: "PARANHOS" },
      { municipio: "PEDRO GOMES" },
      { municipio: "PONTA PORÃ" },
      { municipio: "PORTO MURTINHO" },
      { municipio: "RIBAS DO RIO PARDO" },
      { municipio: "RIO BRILHANTE" },
      { municipio: "RIO NEGRO" },
      { municipio: "RIO VERDE DE MATO GROSSO" },
      { municipio: "ROCHEDO" },
      { municipio: "SANTA RITA DO PARDO" },
      { municipio: "SÃO GABRIEL DO OESTE" },
      { municipio: "SELVÍRIA" },
      { municipio: "SETE QUEDAS" },
      { municipio: "SIDROLÂNDIA" },
      { municipio: "SONORA" },
      { municipio: "TACURU" },
      { municipio: "TAQUARUSSU" },
      { municipio: "TERENOS" },
      { municipio: "TRÊS LAGOAS" },
      { municipio: "VICENTINA" },
    ],
    estado: "MS",
  },
  {
    municipios: [
      { municipio: "ACORIZAL" },
      { municipio: "ÁGUA BOA" },
      { municipio: "ALTA FLORESTA" },
      { municipio: "ALTO ARAGUAIA" },
      { municipio: "ALTO BOA VISTA" },
      { municipio: "ALTO GARÇAS" },
      { municipio: "ALTO PARAGUAI" },
      { municipio: "ALTO TAQUARI" },
      { municipio: "APIACÁS" },
      { municipio: "ARAGUAIANA" },
      { municipio: "ARAGUAINHA" },
      { municipio: "ARAPUTANGA" },
      { municipio: "ARENÁPOLIS" },
      { municipio: "ARIPUANÃ" },
      { municipio: "BARÃO DE MELGAÇO" },
      { municipio: "BARRA DO BUGRES" },
      { municipio: "BARRA DO GARÇAS" },
      { municipio: "BOM JESUS DO ARAGUAIA" },
      { municipio: "BRASNORTE" },
      { municipio: "CÁCERES" },
      { municipio: "CAMPINÁPOLIS" },
      { municipio: "CAMPO NOVO DO PARECIS" },
      { municipio: "CAMPO VERDE" },
      { municipio: "CAMPOS DE JÚLIO" },
      { municipio: "CANABRAVA DO NORTE" },
      { municipio: "CANARANA" },
      { municipio: "CARLINDA" },
      { municipio: "CASTANHEIRA" },
      { municipio: "CHAPADA DOS GUIMARÃES" },
      { municipio: "CLÁUDIA" },
      { municipio: "COCALINHO" },
      { municipio: "COLÍDER" },
      { municipio: "COLNIZA" },
      { municipio: "COMODORO" },
      { municipio: "CONFRESA" },
      { municipio: "CONQUISTA D'OESTE" },
      { municipio: "COTRIGUAÇU" },
      { municipio: "CUIABÁ" },
      { municipio: "CURVELÂNDIA" },
      { municipio: "DENISE" },
      { municipio: "DIAMANTINO" },
      { municipio: "DOM AQUINO" },
      { municipio: "FELIZ NATAL" },
      { municipio: "FIGUEIRÓPOLIS D'OESTE" },
      { municipio: "GAÚCHA DO NORTE" },
      { municipio: "GENERAL CARNEIRO" },
      { municipio: "GLÓRIA D'OESTE" },
      { municipio: "GUARANTÃ DO NORTE" },
      { municipio: "GUIRATINGA" },
      { municipio: "INDIAVAÍ" },
      { municipio: "IPIRANGA DO NORTE" },
      { municipio: "ITANHANGÁ" },
      { municipio: "ITAÚBA" },
      { municipio: "ITIQUIRA" },
      { municipio: "JACIARA" },
      { municipio: "JANGADA" },
      { municipio: "JAURU" },
      { municipio: "JUARA" },
      { municipio: "JUÍNA" },
      { municipio: "JURUENA" },
      { municipio: "JUSCIMEIRA" },
      { municipio: "LAMBARI D'OESTE" },
      { municipio: "LUCAS DO RIO VERDE" },
      { municipio: "LUCIARA" },
      { municipio: "MARCELÂNDIA" },
      { municipio: "MATUPÁ" },
      { municipio: "MIRASSOL D'OESTE" },
      { municipio: "NOBRES" },
      { municipio: "NORTELÂNDIA" },
      { municipio: "NOSSA SENHORA DO LIVRAMENTO" },
      { municipio: "NOVA BANDEIRANTES" },
      { municipio: "NOVA BRASILÂNDIA" },
      { municipio: "NOVA CANAÃ DO NORTE" },
      { municipio: "NOVA GUARITA" },
      { municipio: "NOVA LACERDA" },
      { municipio: "NOVA MARILÂNDIA" },
      { municipio: "NOVA MARINGÁ" },
      { municipio: "NOVA MONTE VERDE" },
      { municipio: "NOVA MUTUM" },
      { municipio: "NOVA NAZARÉ" },
      { municipio: "NOVA OLÍMPIA" },
      { municipio: "NOVA SANTA HELENA" },
      { municipio: "NOVA UBIRATÃ" },
      { municipio: "NOVA XAVANTINA" },
      { municipio: "NOVO HORIZONTE DO NORTE" },
      { municipio: "NOVO MUNDO" },
      { municipio: "NOVO SANTO ANTÔNIO" },
      { municipio: "NOVO SÃO JOAQUIM" },
      { municipio: "PARANAÍTA" },
      { municipio: "PARANATINGA" },
      { municipio: "PEDRA PRETA" },
      { municipio: "PEIXOTO DE AZEVEDO" },
      { municipio: "PLANALTO DA SERRA" },
      { municipio: "POCONÉ" },
      { municipio: "PONTAL DO ARAGUAIA" },
      { municipio: "PONTE BRANCA" },
      { municipio: "PONTES E LACERDA" },
      { municipio: "PORTO ALEGRE DO NORTE" },
      { municipio: "PORTO DOS GAÚCHOS" },
      { municipio: "PORTO ESPERIDIÃO" },
      { municipio: "PORTO ESTRELA" },
      { municipio: "POXORÉU" },
      { municipio: "PRIMAVERA DO LESTE" },
      { municipio: "QUERÊNCIA" },
      { municipio: "RESERVA DO CABAÇAL" },
      { municipio: "RIBEIRÃO CASCALHEIRA" },
      { municipio: "RIBEIRÃOZINHO" },
      { municipio: "RIO BRANCO" },
      { municipio: "RONDOLÂNDIA" },
      { municipio: "RONDONÓPOLIS" },
      { municipio: "ROSÁRIO OESTE" },
      { municipio: "SALTO DO CÉU" },
      { municipio: "SANTA CARMEM" },
      { municipio: "SANTA CRUZ DO XINGU" },
      { municipio: "SANTA RITA DO TRIVELATO" },
      { municipio: "SANTA TEREZINHA" },
      { municipio: "SANTO AFONSO" },
      { municipio: "SANTO ANTÔNIO DO LESTE" },
      { municipio: "SANTO ANTÔNIO DO LEVERGER" },
      { municipio: "SÃO FÉLIX DO ARAGUAIA" },
      { municipio: "SÃO JOSÉ DO POVO" },
      { municipio: "SÃO JOSÉ DO RIO CLARO" },
      { municipio: "SÃO JOSÉ DO XINGU" },
      { municipio: "SÃO JOSÉ DOS QUATRO MARCOS" },
      { municipio: "SÃO PEDRO DA CIPA" },
      { municipio: "SAPEZAL" },
      { municipio: "SERRA NOVA DOURADA" },
      { municipio: "SINOP" },
      { municipio: "SORRISO" },
      { municipio: "TABAPORÃ" },
      { municipio: "TANGARÁ DA SERRA" },
      { municipio: "TAPURAH" },
      { municipio: "TERRA NOVA DO NORTE" },
      { municipio: "TESOURO" },
      { municipio: "TORIXORÉU" },
      { municipio: "UNIÃO DO SUL" },
      { municipio: "VALE DE SÃO DOMINGOS" },
      { municipio: "VÁRZEA GRANDE" },
      { municipio: "VERA" },
      { municipio: "VILA BELA DA SANTÍSSIMA TRINDADE" },
      { municipio: "VILA RICA" },
      { municipio: "POXOREO" },
    ],
    estado: "MT",
  },
  {
    municipios: [
      { municipio: "ABADIA DE GOIÁS" },
      { municipio: "ABADIÂNIA" },
      { municipio: "ACREÚNA" },
      { municipio: "ADELÂNDIA" },
      { municipio: "ÁGUA FRIA DE GOIÁS" },
      { municipio: "ÁGUA LIMPA" },
      { municipio: "ÁGUAS LINDAS DE GOIÁS" },
      { municipio: "ALEXÂNIA" },
      { municipio: "ALOÂNDIA" },
      { municipio: "ALTO HORIZONTE" },
      { municipio: "ALTO PARAÍSO DE GOIÁS" },
      { municipio: "ALVORADA DO NORTE" },
      { municipio: "AMARALINA" },
      { municipio: "AMERICANO DO BRASIL" },
      { municipio: "AMORINÓPOLIS" },
      { municipio: "ANÁPOLIS" },
      { municipio: "ANHANGUERA" },
      { municipio: "ANICUNS" },
      { municipio: "APARECIDA DE GOIÂNIA" },
      { municipio: "APARECIDA DO RIO DOCE" },
      { municipio: "APORÉ" },
      { municipio: "ARAÇU" },
      { municipio: "ARAGARÇAS" },
      { municipio: "ARAGOIÂNIA" },
      { municipio: "ARAGUAPAZ" },
      { municipio: "ARENÓPOLIS" },
      { municipio: "ARUANÃ" },
      { municipio: "AURILÂNDIA" },
      { municipio: "AVELINÓPOLIS" },
      { municipio: "BALIZA" },
      { municipio: "BARRO ALTO" },
      { municipio: "BELA VISTA DE GOIÁS" },
      { municipio: "BOM JARDIM DE GOIÁS" },
      { municipio: "BOM JESUS DE GOIÁS" },
      { municipio: "BONFINÓPOLIS" },
      { municipio: "BONÓPOLIS" },
      { municipio: "BRAZABRANTES" },
      { municipio: "BRITÂNIA" },
      { municipio: "BURITI ALEGRE" },
      { municipio: "BURITI DE GOIÁS" },
      { municipio: "BURITINÓPOLIS" },
      { municipio: "CABECEIRAS" },
      { municipio: "CACHOEIRA ALTA" },
      { municipio: "CACHOEIRA DE GOIÁS" },
      { municipio: "CACHOEIRA DOURADA" },
      { municipio: "CAÇU" },
      { municipio: "CAIAPÔNIA" },
      { municipio: "CALDAS NOVAS" },
      { municipio: "CALDAZINHA" },
      { municipio: "CAMPESTRE DE GOIÁS" },
      { municipio: "CAMPINAÇU" },
      { municipio: "CAMPINORTE" },
      { municipio: "CAMPO ALEGRE DE GOIÁS" },
      { municipio: "CAMPO LIMPO DE GOIÁS" },
      { municipio: "CAMPOS BELOS" },
      { municipio: "CAMPOS VERDES" },
      { municipio: "CARMO DO RIO VERDE" },
      { municipio: "CASTELÂNDIA" },
      { municipio: "CATALÃO" },
      { municipio: "CATURAÍ" },
      { municipio: "CAVALCANTE" },
      { municipio: "CERES" },
      { municipio: "CEZARINA" },
      { municipio: "CHAPADÃO DO CÉU" },
      { municipio: "CIDADE OCIDENTAL" },
      { municipio: "COCALZINHO DE GOIÁS" },
      { municipio: "COLINAS DO SUL" },
      { municipio: "CÓRREGO DO OURO" },
      { municipio: "CORUMBÁ DE GOIÁS" },
      { municipio: "CORUMBAÍBA" },
      { municipio: "CRISTALINA" },
      { municipio: "CRISTIANÓPOLIS" },
      { municipio: "CRIXÁS" },
      { municipio: "CROMÍNIA" },
      { municipio: "CUMARI" },
      { municipio: "DAMIANÓPOLIS" },
      { municipio: "DAMOLÂNDIA" },
      { municipio: "DAVINÓPOLIS" },
      { municipio: "DIORAMA" },
      { municipio: "DIVINÓPOLIS DE GOIÁS" },
      { municipio: "DOVERLÂNDIA" },
      { municipio: "EDEALINA" },
      { municipio: "EDÉIA" },
      { municipio: "ESTRELA DO NORTE" },
      { municipio: "FAINA" },
      { municipio: "FAZENDA NOVA" },
      { municipio: "FIRMINÓPOLIS" },
      { municipio: "FLORES DE GOIÁS" },
      { municipio: "FORMOSA" },
      { municipio: "FORMOSO" },
      { municipio: "GAMELEIRA DE GOIÁS" },
      { municipio: "GOIANÁPOLIS" },
      { municipio: "GOIANDIRA" },
      { municipio: "GOIANÉSIA" },
      { municipio: "GOIÂNIA" },
      { municipio: "GOIANIRA" },
      { municipio: "GOIÁS" },
      { municipio: "GOIATUBA" },
      { municipio: "GOUVELÂNDIA" },
      { municipio: "GUAPÓ" },
      { municipio: "GUARAÍTA" },
      { municipio: "GUARANI DE GOIÁS" },
      { municipio: "GUARINOS" },
      { municipio: "HEITORAÍ" },
      { municipio: "HIDROLÂNDIA" },
      { municipio: "HIDROLINA" },
      { municipio: "IACIARA" },
      { municipio: "INACIOLÂNDIA" },
      { municipio: "INDIARA" },
      { municipio: "INHUMAS" },
      { municipio: "IPAMERI" },
      { municipio: "IPIRANGA DE GOIÁS" },
      { municipio: "IPORÁ" },
      { municipio: "ISRAELÂNDIA" },
      { municipio: "ITABERAÍ" },
      { municipio: "ITAGUARI" },
      { municipio: "ITAGUARU" },
      { municipio: "ITAJÁ" },
      { municipio: "ITAPACI" },
      { municipio: "ITAPIRAPUÃ" },
      { municipio: "ITAPURANGA" },
      { municipio: "ITARUMÃ" },
      { municipio: "ITAUÇU" },
      { municipio: "ITUMBIARA" },
      { municipio: "IVOLÂNDIA" },
      { municipio: "JANDAIA" },
      { municipio: "JARAGUÁ" },
      { municipio: "JATAÍ" },
      { municipio: "JAUPACI" },
      { municipio: "JESÚPOLIS" },
      { municipio: "JOVIÂNIA" },
      { municipio: "JUSSARA" },
      { municipio: "LAGOA SANTA" },
      { municipio: "LEOPOLDO DE BULHÕES" },
      { municipio: "LUZIÂNIA" },
      { municipio: "MAIRIPOTABA" },
      { municipio: "MAMBAÍ" },
      { municipio: "MARA ROSA" },
      { municipio: "MARZAGÃO" },
      { municipio: "MATRINCHÃ" },
      { municipio: "MAURILÂNDIA" },
      { municipio: "MIMOSO DE GOIÁS" },
      { municipio: "MINAÇU" },
      { municipio: "MINEIROS" },
      { municipio: "MOIPORÁ" },
      { municipio: "MONTE ALEGRE DE GOIÁS" },
      { municipio: "MONTES CLAROS DE GOIÁS" },
      { municipio: "MONTIVIDIU" },
      { municipio: "MONTIVIDIU DO NORTE" },
      { municipio: "MORRINHOS" },
      { municipio: "MORRO AGUDO DE GOIÁS" },
      { municipio: "MOSSÂMEDES" },
      { municipio: "MOZARLÂNDIA" },
      { municipio: "MUNDO NOVO" },
      { municipio: "MUTUNÓPOLIS" },
      { municipio: "NAZÁRIO" },
      { municipio: "NERÓPOLIS" },
      { municipio: "NIQUELÂNDIA" },
      { municipio: "NOVA AMÉRICA" },
      { municipio: "NOVA AURORA" },
      { municipio: "NOVA CRIXÁS" },
      { municipio: "NOVA GLÓRIA" },
      { municipio: "NOVA IGUAÇU DE GOIÁS" },
      { municipio: "NOVA ROMA" },
      { municipio: "NOVA VENEZA" },
      { municipio: "NOVO BRASIL" },
      { municipio: "NOVO GAMA" },
      { municipio: "NOVO PLANALTO" },
      { municipio: "ORIZONA" },
      { municipio: "OURO VERDE DE GOIÁS" },
      { municipio: "OUVIDOR" },
      { municipio: "PADRE BERNARDO" },
      { municipio: "PALESTINA DE GOIÁS" },
      { municipio: "PALMEIRAS DE GOIÁS" },
      { municipio: "PALMELO" },
      { municipio: "PALMINÓPOLIS" },
      { municipio: "PANAMÁ" },
      { municipio: "PARANAIGUARA" },
      { municipio: "PARAÚNA" },
      { municipio: "PEROLÂNDIA" },
      { municipio: "PETROLINA DE GOIÁS" },
      { municipio: "PILAR DE GOIÁS" },
      { municipio: "PIRACANJUBA" },
      { municipio: "PIRANHAS" },
      { municipio: "PIRENÓPOLIS" },
      { municipio: "PIRES DO RIO" },
      { municipio: "PLANALTINA" },
      { municipio: "PONTALINA" },
      { municipio: "PORANGATU" },
      { municipio: "PORTEIRÃO" },
      { municipio: "PORTELÂNDIA" },
      { municipio: "POSSE" },
      { municipio: "PROFESSOR JAMIL" },
      { municipio: "QUIRINÓPOLIS" },
      { municipio: "RIALMA" },
      { municipio: "RIANÁPOLIS" },
      { municipio: "RIO QUENTE" },
      { municipio: "RIO VERDE" },
      { municipio: "RUBIATABA" },
      { municipio: "SANCLERLÂNDIA" },
      { municipio: "SANTA BÁRBARA DE GOIÁS" },
      { municipio: "SANTA CRUZ DE GOIÁS" },
      { municipio: "SANTA FÉ DE GOIÁS" },
      { municipio: "SANTA HELENA DE GOIÁS" },
      { municipio: "SANTA ISABEL" },
      { municipio: "SANTA RITA DO ARAGUAIA" },
      { municipio: "SANTA RITA DO NOVO DESTINO" },
      { municipio: "SANTA ROSA DE GOIÁS" },
      { municipio: "SANTA TEREZA DE GOIÁS" },
      { municipio: "SANTA TEREZINHA DE GOIÁS" },
      { municipio: "SANTO ANTÔNIO DA BARRA" },
      { municipio: "SANTO ANTÔNIO DE GOIÁS" },
      { municipio: "SANTO ANTÔNIO DO DESCOBERTO" },
      { municipio: "SÃO DOMINGOS" },
      { municipio: "SÃO FRANCISCO DE GOIÁS" },
      { municipio: "SÃO JOÃO DA PARAÚNA" },
      { municipio: "SÃO JOÃO D'ALIANÇA" },
      { municipio: "SÃO LUÍS DE MONTES BELOS" },
      { municipio: "SÃO LUIZ DO NORTE" },
      { municipio: "SÃO MIGUEL DO ARAGUAIA" },
      { municipio: "SÃO MIGUEL DO PASSA QUATRO" },
      { municipio: "SÃO PATRÍCIO" },
      { municipio: "SÃO SIMÃO" },
      { municipio: "SENADOR CANEDO" },
      { municipio: "SERRANÓPOLIS" },
      { municipio: "SILVÂNIA" },
      { municipio: "SIMOLÂNDIA" },
      { municipio: "SÍTIO D'ABADIA" },
      { municipio: "TAQUARAL DE GOIÁS" },
      { municipio: "TERESINA DE GOIÁS" },
      { municipio: "TEREZÓPOLIS DE GOIÁS" },
      { municipio: "TRÊS RANCHOS" },
      { municipio: "TRINDADE" },
      { municipio: "TROMBAS" },
      { municipio: "TURVÂNIA" },
      { municipio: "TURVELÂNDIA" },
      { municipio: "UIRAPURU" },
      { municipio: "URUAÇU" },
      { municipio: "URUANA" },
      { municipio: "URUTAÍ" },
      { municipio: "VALPARAÍSO DE GOIÁS" },
      { municipio: "VARJÃO" },
      { municipio: "VIANÓPOLIS" },
      { municipio: "VICENTINÓPOLIS" },
      { municipio: "VILA BOA" },
      { municipio: "VILA PROPÍCIO" },
    ],
    estado: "GO",
  },
  {
    municipios: [
      { municipio: "ÁGUA BRANCA" },
      { municipio: "ANADIA" },
      { municipio: "ARAPIRACA" },
      { municipio: "ATALAIA" },
      { municipio: "BARRA DE SANTO ANTÔNIO" },
      { municipio: "BARRA DE SÃO MIGUEL" },
      { municipio: "BATALHA" },
      { municipio: "BELÉM" },
      { municipio: "BELO MONTE" },
      { municipio: "BOCA DA MATA" },
      { municipio: "BRANQUINHA" },
      { municipio: "CACIMBINHAS" },
      { municipio: "CAJUEIRO" },
      { municipio: "CAMPESTRE" },
      { municipio: "CAMPO ALEGRE" },
      { municipio: "CAMPO GRANDE" },
      { municipio: "CANAPI" },
      { municipio: "CAPELA" },
      { municipio: "CARNEIROS" },
      { municipio: "CHÃ PRETA" },
      { municipio: "COITÉ DO NÓIA" },
      { municipio: "COLÔNIA LEOPOLDINA" },
      { municipio: "COQUEIRO SECO" },
      { municipio: "CORURIPE" },
      { municipio: "CRAÍBAS" },
      { municipio: "DELMIRO GOUVEIA" },
      { municipio: "DOIS RIACHOS" },
      { municipio: "ESTRELA DE ALAGOAS" },
      { municipio: "FEIRA GRANDE" },
      { municipio: "FELIZ DESERTO" },
      { municipio: "FLEXEIRAS" },
      { municipio: "GIRAU DO PONCIANO" },
      { municipio: "IBATEGUARA" },
      { municipio: "IGACI" },
      { municipio: "IGREJA NOVA" },
      { municipio: "INHAPI" },
      { municipio: "JACARÉ DOS HOMENS" },
      { municipio: "JACUÍPE" },
      { municipio: "JAPARATINGA" },
      { municipio: "JARAMATAIA" },
      { municipio: "JEQUIÁ DA PRAIA" },
      { municipio: "JOAQUIM GOMES" },
      { municipio: "JUNDIÁ" },
      { municipio: "JUNQUEIRO" },
      { municipio: "LAGOA DA CANOA" },
      { municipio: "LIMOEIRO DE ANADIA" },
      { municipio: "MACEIÓ" },
      { municipio: "MAJOR ISIDORO" },
      { municipio: "MAR VERMELHO" },
      { municipio: "MARAGOGI" },
      { municipio: "MARAVILHA" },
      { municipio: "MARECHAL DEODORO" },
      { municipio: "MARIBONDO" },
      { municipio: "MATA GRANDE" },
      { municipio: "MATRIZ DE CAMARAGIBE" },
      { municipio: "MESSIAS" },
      { municipio: "MINADOR DO NEGRÃO" },
      { municipio: "MONTEIRÓPOLIS" },
      { municipio: "MURICI" },
      { municipio: "NOVO LINO" },
      { municipio: "OLHO D'ÁGUA DAS FLORES" },
      { municipio: "OLHO D'ÁGUA DO CASADO" },
      { municipio: "OLHO D'ÁGUA GRANDE" },
      { municipio: "OLIVENÇA" },
      { municipio: "OURO BRANCO" },
      { municipio: "PALESTINA" },
      { municipio: "PALMEIRA DOS ÍNDIOS" },
      { municipio: "PÃO DE AÇÚCAR" },
      { municipio: "PARICONHA" },
      { municipio: "PARIPUEIRA" },
      { municipio: "PASSO DE CAMARAGIBE" },
      { municipio: "PAULO JACINTO" },
      { municipio: "PENEDO" },
      { municipio: "PIAÇABUÇU" },
      { municipio: "PILAR" },
      { municipio: "PINDOBA" },
      { municipio: "PIRANHAS" },
      { municipio: "POÇO DAS TRINCHEIRAS" },
      { municipio: "PORTO CALVO" },
      { municipio: "PORTO DE PEDRAS" },
      { municipio: "PORTO REAL DO COLÉGIO" },
      { municipio: "QUEBRANGULO" },
      { municipio: "RIO LARGO" },
      { municipio: "ROTEIRO" },
      { municipio: "SANTA LUZIA DO NORTE" },
      { municipio: "SANTANA DO IPANEMA" },
      { municipio: "SANTANA DO MUNDAÚ" },
      { municipio: "SÃO BRÁS" },
      { municipio: "SÃO JOSÉ DA LAJE" },
      { municipio: "SÃO JOSÉ DA TAPERA" },
      { municipio: "SÃO LUÍS DO QUITUNDE" },
      { municipio: "SÃO MIGUEL DOS CAMPOS" },
      { municipio: "SÃO MIGUEL DOS MILAGRES" },
      { municipio: "SÃO SEBASTIÃO" },
      { municipio: "SATUBA" },
      { municipio: "SENADOR RUI PALMEIRA" },
      { municipio: "TANQUE D'ARCA" },
      { municipio: "TAQUARANA" },
      { municipio: "TEOTÔNIO VILELA" },
      { municipio: "TRAIPU" },
      { municipio: "UNIÃO DOS PALMARES" },
      { municipio: "VIÇOSA" },
      { municipio: "CAMPO GRANDE" },
    ],
    estado: "AL",
  },
  {
    municipios: [
      { municipio: "ALVARÃES" },
      { municipio: "AMATURÁ" },
      { municipio: "ANAMÃ" },
      { municipio: "ANORI" },
      { municipio: "APUÍ" },
      { municipio: "ATALAIA DO NORTE" },
      { municipio: "AUTAZES" },
      { municipio: "BARCELOS" },
      { municipio: "BARREIRINHA" },
      { municipio: "BENJAMIN CONSTANT" },
      { municipio: "BERURI" },
      { municipio: "BOA VISTA DO RAMOS" },
      { municipio: "BOCA DO ACRE" },
      { municipio: "BORBA" },
      { municipio: "CAAPIRANGA" },
      { municipio: "CANUTAMA" },
      { municipio: "CARAUARI" },
      { municipio: "CAREIRO" },
      { municipio: "CAREIRO DA VÁRZEA" },
      { municipio: "COARI" },
      { municipio: "CODAJÁS" },
      { municipio: "EIRUNEPÉ" },
      { municipio: "ENVIRA" },
      { municipio: "FONTE BOA" },
      { municipio: "GUAJARÁ" },
      { municipio: "HUMAITÁ" },
      { municipio: "IPIXUNA" },
      { municipio: "IRANDUBA" },
      { municipio: "ITACOATIARA" },
      { municipio: "ITAMARATI" },
      { municipio: "ITAPIRANGA" },
      { municipio: "JAPURÁ" },
      { municipio: "JURUÁ" },
      { municipio: "JUTAÍ" },
      { municipio: "LÁBREA" },
      { municipio: "MANACAPURU" },
      { municipio: "MANAQUIRI" },
      { municipio: "MANAUS" },
      { municipio: "MANICORÉ" },
      { municipio: "MARAÃ" },
      { municipio: "MAUÉS" },
      { municipio: "NHAMUNDÁ" },
      { municipio: "NOVA OLINDA DO NORTE" },
      { municipio: "NOVO AIRÃO" },
      { municipio: "NOVO ARIPUANÃ" },
      { municipio: "PARINTINS" },
      { municipio: "PAUINI" },
      { municipio: "PRESIDENTE FIGUEIREDO" },
      { municipio: "RIO PRETO DA EVA" },
      { municipio: "SANTA ISABEL DO RIO NEGRO" },
      { municipio: "SANTO ANTÔNIO DO IÇÁ" },
      { municipio: "SÃO GABRIEL DA CACHOEIRA" },
      { municipio: "SÃO PAULO DE OLIVENÇA" },
      { municipio: "SÃO SEBASTIÃO DO UATUMÃ" },
      { municipio: "SILVES" },
      { municipio: "TABATINGA" },
      { municipio: "TAPAUÁ" },
      { municipio: "TEFÉ" },
      { municipio: "TONANTINS" },
      { municipio: "UARINI" },
      { municipio: "URUCARÁ" },
      { municipio: "URUCURITUBA" },
    ],
    estado: "AM",
  },
  {
    municipios: [
      { municipio: "AFONSO CLÁUDIO" },
      { municipio: "ÁGUA DOCE DO NORTE" },
      { municipio: "ÁGUIA BRANCA" },
      { municipio: "ALEGRE" },
      { municipio: "ALFREDO CHAVES" },
      { municipio: "ALTO RIO NOVO" },
      { municipio: "ANCHIETA" },
      { municipio: "APIACÁ" },
      { municipio: "ARACRUZ" },
      { municipio: "ATÍLIO VIVACQUA" },
      { municipio: "BAIXO GUANDU" },
      { municipio: "BARRA DE SÃO FRANCISCO" },
      { municipio: "BOA ESPERANÇA" },
      { municipio: "BOM JESUS DO NORTE" },
      { municipio: "BREJETUBA" },
      { municipio: "CACHOEIRO DE ITAPEMIRIM" },
      { municipio: "CARIACICA" },
      { municipio: "CASTELO" },
      { municipio: "COLATINA" },
      { municipio: "CONCEIÇÃO DA BARRA" },
      { municipio: "CONCEIÇÃO DO CASTELO" },
      { municipio: "DIVINO DE SÃO LOURENÇO" },
      { municipio: "DOMINGOS MARTINS" },
      { municipio: "DORES DO RIO PRETO" },
      { municipio: "ECOPORANGA" },
      { municipio: "FUNDÃO" },
      { municipio: "GOVERNADOR LINDENBERG" },
      { municipio: "GUAÇUÍ" },
      { municipio: "GUARAPARI" },
      { municipio: "IBATIBA" },
      { municipio: "IBIRAÇU" },
      { municipio: "IBITIRAMA" },
      { municipio: "ICONHA" },
      { municipio: "IRUPI" },
      { municipio: "ITAGUAÇU" },
      { municipio: "ITAPEMIRIM" },
      { municipio: "ITARANA" },
      { municipio: "IÚNA" },
      { municipio: "JAGUARÉ" },
      { municipio: "JERÔNIMO MONTEIRO" },
      { municipio: "JOÃO NEIVA" },
      { municipio: "LARANJA DA TERRA" },
      { municipio: "LINHARES" },
      { municipio: "MANTENÓPOLIS" },
      { municipio: "MARATAÍZES" },
      { municipio: "MARECHAL FLORIANO" },
      { municipio: "MARILÂNDIA" },
      { municipio: "MIMOSO DO SUL" },
      { municipio: "MONTANHA" },
      { municipio: "MUCURICI" },
      { municipio: "MUNIZ FREIRE" },
      { municipio: "MUQUI" },
      { municipio: "NOVA VENÉCIA" },
      { municipio: "PANCAS" },
      { municipio: "PEDRO CANÁRIO" },
      { municipio: "PINHEIROS" },
      { municipio: "PIÚMA" },
      { municipio: "PONTO BELO" },
      { municipio: "PRESIDENTE KENNEDY" },
      { municipio: "RIO BANANAL" },
      { municipio: "RIO NOVO DO SUL" },
      { municipio: "SANTA LEOPOLDINA" },
      { municipio: "SANTA MARIA DE JETIBÁ" },
      { municipio: "SANTA TERESA" },
      { municipio: "SÃO DOMINGOS DO NORTE" },
      { municipio: "SÃO GABRIEL DA PALHA" },
      { municipio: "SÃO JOSÉ DO CALÇADO" },
      { municipio: "SÃO MATEUS" },
      { municipio: "SÃO ROQUE DO CANAÃ" },
      { municipio: "SERRA" },
      { municipio: "SOORETAMA" },
      { municipio: "VARGEM ALTA" },
      { municipio: "VENDA NOVA DO IMIGRANTE" },
      { municipio: "VIANA" },
      { municipio: "VILA PAVÃO" },
      { municipio: "VILA VALÉRIO" },
      { municipio: "VILA VELHA" },
      { municipio: "VITÓRIA" },
    ],
    estado: "ES",
  },
  {
    municipios: [
      { municipio: "AMAPÁ" },
      { municipio: "CALÇOENE" },
      { municipio: "CUTIAS" },
      { municipio: "FERREIRA GOMES" },
      { municipio: "ITAUBAL" },
      { municipio: "LARANJAL DO JARI" },
      { municipio: "MACAPÁ" },
      { municipio: "MAZAGÃO" },
      { municipio: "OIAPOQUE" },
      { municipio: "PEDRA BRANCA DO AMAPARI" },
      { municipio: "PORTO GRANDE" },
      { municipio: "PRACUÚBA" },
      { municipio: "SANTANA" },
      { municipio: "SERRA DO NAVIO" },
      { municipio: "TARTARUGALZINHO" },
      { municipio: "VITÓRIA DO JARI" },
    ],
    estado: "AP",
  },
  {
    municipios: [
      { municipio: "ABAETETUBA" },
      { municipio: "ABEL FIGUEIREDO" },
      { municipio: "ACARÁ" },
      { municipio: "AFUÁ" },
      { municipio: "ÁGUA AZUL DO NORTE" },
      { municipio: "ALENQUER" },
      { municipio: "ALMEIRIM" },
      { municipio: "ALTAMIRA" },
      { municipio: "ANAJÁS" },
      { municipio: "ANANINDEUA" },
      { municipio: "ANAPU" },
      { municipio: "AUGUSTO CORRÊA" },
      { municipio: "AURORA DO PARÁ" },
      { municipio: "AVEIRO" },
      { municipio: "BAGRE" },
      { municipio: "BAIÃO" },
      { municipio: "BANNACH" },
      { municipio: "BARCARENA" },
      { municipio: "BELÉM" },
      { municipio: "BELTERRA" },
      { municipio: "BENEVIDES" },
      { municipio: "BOM JESUS DO TOCANTINS" },
      { municipio: "BONITO" },
      { municipio: "BRAGANÇA" },
      { municipio: "BRASIL NOVO" },
      { municipio: "BREJO GRANDE DO ARAGUAIA" },
      { municipio: "BREU BRANCO" },
      { municipio: "BREVES" },
      { municipio: "BUJARU" },
      { municipio: "CACHOEIRA DO ARARI" },
      { municipio: "CACHOEIRA DO PIRIÁ" },
      { municipio: "CAMETÁ" },
      { municipio: "CANAÃ DOS CARAJÁS" },
      { municipio: "CAPANEMA" },
      { municipio: "CAPITÃO POÇO" },
      { municipio: "CASTANHAL" },
      { municipio: "CHAVES" },
      { municipio: "COLARES" },
      { municipio: "CONCEIÇÃO DO ARAGUAIA" },
      { municipio: "CONCÓRDIA DO PARÁ" },
      { municipio: "CUMARU DO NORTE" },
      { municipio: "CURIONÓPOLIS" },
      { municipio: "CURRALINHO" },
      { municipio: "CURUÁ" },
      { municipio: "CURUÇÁ" },
      { municipio: "DOM ELISEU" },
      { municipio: "ELDORADO DO CARAJÁS" },
      { municipio: "FARO" },
      { municipio: "FLORESTA DO ARAGUAIA" },
      { municipio: "GARRAFÃO DO NORTE" },
      { municipio: "GOIANÉSIA DO PARÁ" },
      { municipio: "GURUPÁ" },
      { municipio: "IGARAPÉ-AÇU" },
      { municipio: "IGARAPÉ-MIRI" },
      { municipio: "INHANGAPI" },
      { municipio: "IPIXUNA DO PARÁ" },
      { municipio: "IRITUIA" },
      { municipio: "ITAITUBA" },
      { municipio: "ITUPIRANGA" },
      { municipio: "JACAREACANGA" },
      { municipio: "JACUNDÁ" },
      { municipio: "JURUTI" },
      { municipio: "LIMOEIRO DO AJURU" },
      { municipio: "MÃE DO RIO" },
      { municipio: "MAGALHÃES BARATA" },
      { municipio: "MARABÁ" },
      { municipio: "MARACANÃ" },
      { municipio: "MARAPANIM" },
      { municipio: "MARITUBA" },
      { municipio: "MEDICILÂNDIA" },
      { municipio: "MELGAÇO" },
      { municipio: "MOCAJUBA" },
      { municipio: "MOJU" },
      { municipio: "MOJUÍ DOS CAMPOS" },
      { municipio: "MONTE ALEGRE" },
      { municipio: "MUANÁ" },
      { municipio: "NOVA ESPERANÇA DO PIRIÁ" },
      { municipio: "NOVA IPIXUNA" },
      { municipio: "NOVA TIMBOTEUA" },
      { municipio: "NOVO PROGRESSO" },
      { municipio: "NOVO REPARTIMENTO" },
      { municipio: "ÓBIDOS" },
      { municipio: "OEIRAS DO PARÁ" },
      { municipio: "ORIXIMINÁ" },
      { municipio: "OURÉM" },
      { municipio: "OURILÂNDIA DO NORTE" },
      { municipio: "PACAJÁ" },
      { municipio: "PALESTINA DO PARÁ" },
      { municipio: "PARAGOMINAS" },
      { municipio: "PARAUAPEBAS" },
      { municipio: "PAU D'ARCO" },
      { municipio: "PEIXE-BOI" },
      { municipio: "PIÇARRA" },
      { municipio: "PLACAS" },
      { municipio: "PONTA DE PEDRAS" },
      { municipio: "PORTEL" },
      { municipio: "PORTO DE MOZ" },
      { municipio: "PRAINHA" },
      { municipio: "PRIMAVERA" },
      { municipio: "QUATIPURU" },
      { municipio: "REDENÇÃO" },
      { municipio: "RIO MARIA" },
      { municipio: "RONDON DO PARÁ" },
      { municipio: "RURÓPOLIS" },
      { municipio: "SALINÓPOLIS" },
      { municipio: "SALVATERRA" },
      { municipio: "SANTA BÁRBARA DO PARÁ" },
      { municipio: "SANTA CRUZ DO ARARI" },
      { municipio: "SANTA IZABEL DO PARÁ" },
      { municipio: "SANTA LUZIA DO PARÁ" },
      { municipio: "SANTA MARIA DAS BARREIRAS" },
      { municipio: "SANTA MARIA DO PARÁ" },
      { municipio: "SANTANA DO ARAGUAIA" },
      { municipio: "SANTARÉM" },
      { municipio: "SANTARÉM NOVO" },
      { municipio: "SANTO ANTÔNIO DO TAUÁ" },
      { municipio: "SÃO CAETANO DE ODIVELAS" },
      { municipio: "SÃO DOMINGOS DO ARAGUAIA" },
      { municipio: "SÃO DOMINGOS DO CAPIM" },
      { municipio: "SÃO FÉLIX DO XINGU" },
      { municipio: "SÃO FRANCISCO DO PARÁ" },
      { municipio: "SÃO GERALDO DO ARAGUAIA" },
      { municipio: "SÃO JOÃO DA PONTA" },
      { municipio: "SÃO JOÃO DE PIRABAS" },
      { municipio: "SÃO JOÃO DO ARAGUAIA" },
      { municipio: "SÃO MIGUEL DO GUAMÁ" },
      { municipio: "SÃO SEBASTIÃO DA BOA VISTA" },
      { municipio: "SAPUCAIA" },
      { municipio: "SENADOR JOSÉ PORFÍRIO" },
      { municipio: "SOURE" },
      { municipio: "TAILÂNDIA" },
      { municipio: "TERRA ALTA" },
      { municipio: "TERRA SANTA" },
      { municipio: "TOMÉ-AÇU" },
      { municipio: "TRACUATEUA" },
      { municipio: "TRAIRÃO" },
      { municipio: "TUCUMÃ" },
      { municipio: "TUCURUÍ" },
      { municipio: "ULIANÓPOLIS" },
      { municipio: "URUARÁ" },
      { municipio: "VIGIA" },
      { municipio: "VISEU" },
      { municipio: "VITÓRIA DO XINGU" },
      { municipio: "XINGUARA" },
      { municipio: "ELDORADO DOS CARAJAS" },
      { municipio: "TOME ACU" },
      { municipio: "IGARAPE ACU" },
      { municipio: "SANTA ISABEL DO PARA" },
      { municipio: "PEIXE BOI" },
      { municipio: "IGARAPE MIRI" },
    ],
    estado: "PA",
  },
  {
    municipios: [
      { municipio: "ÁGUA BRANCA" },
      { municipio: "AGUIAR" },
      { municipio: "ALAGOA GRANDE" },
      { municipio: "ALAGOA NOVA" },
      { municipio: "ALAGOINHA" },
      { municipio: "ALCANTIL" },
      { municipio: "ALGODÃO DE JANDAÍRA" },
      { municipio: "ALHANDRA" },
      { municipio: "AMPARO" },
      { municipio: "APARECIDA" },
      { municipio: "ARAÇAGI" },
      { municipio: "ARARA" },
      { municipio: "ARARUNA" },
      { municipio: "AREIA" },
      { municipio: "AREIA DE BARAÚNAS" },
      { municipio: "AREIAL" },
      { municipio: "AROEIRAS" },
      { municipio: "ASSUNÇÃO" },
      { municipio: "BAÍA DA TRAIÇÃO" },
      { municipio: "BANANEIRAS" },
      { municipio: "BARAÚNA" },
      { municipio: "BARRA DE SANTA ROSA" },
      { municipio: "BARRA DE SANTANA" },
      { municipio: "BARRA DE SÃO MIGUEL" },
      { municipio: "BAYEUX" },
      { municipio: "BELÉM" },
      { municipio: "BELÉM DO BREJO DO CRUZ" },
      { municipio: "BERNARDINO BATISTA" },
      { municipio: "BOA VENTURA" },
      { municipio: "BOA VISTA" },
      { municipio: "BOM JESUS" },
      { municipio: "BOM SUCESSO" },
      { municipio: "BONITO DE SANTA FÉ" },
      { municipio: "BOQUEIRÃO" },
      { municipio: "BORBOREMA" },
      { municipio: "BREJO DO CRUZ" },
      { municipio: "BREJO DOS SANTOS" },
      { municipio: "CAAPORÃ" },
      { municipio: "CABACEIRAS" },
      { municipio: "CABEDELO" },
      { municipio: "CACHOEIRA DOS ÍNDIOS" },
      { municipio: "CACIMBA DE AREIA" },
      { municipio: "CACIMBA DE DENTRO" },
      { municipio: "CACIMBAS" },
      { municipio: "CAIÇARA" },
      { municipio: "CAJAZEIRAS" },
      { municipio: "CAJAZEIRINHAS" },
      { municipio: "CALDAS BRANDÃO" },
      { municipio: "CAMALAÚ" },
      { municipio: "CAMPINA GRANDE" },
      { municipio: "CAPIM" },
      { municipio: "CARAÚBAS" },
      { municipio: "CARRAPATEIRA" },
      { municipio: "CASSERENGUE" },
      { municipio: "CATINGUEIRA" },
      { municipio: "CATOLÉ DO ROCHA" },
      { municipio: "CATURITÉ" },
      { municipio: "CONCEIÇÃO" },
      { municipio: "CONDADO" },
      { municipio: "CONDE" },
      { municipio: "CONGO" },
      { municipio: "COREMAS" },
      { municipio: "COXIXOLA" },
      { municipio: "CRUZ DO ESPÍRITO SANTO" },
      { municipio: "CUBATI" },
      { municipio: "CUITÉ" },
      { municipio: "CUITÉ DE MAMANGUAPE" },
      { municipio: "CUITEGI" },
      { municipio: "CURRAL DE CIMA" },
      { municipio: "CURRAL VELHO" },
      { municipio: "DAMIÃO" },
      { municipio: "DESTERRO" },
      { municipio: "DIAMANTE" },
      { municipio: "DONA INÊS" },
      { municipio: "DUAS ESTRADAS" },
      { municipio: "EMAS" },
      { municipio: "ESPERANÇA" },
      { municipio: "FAGUNDES" },
      { municipio: "FREI MARTINHO" },
      { municipio: "GADO BRAVO" },
      { municipio: "GUARABIRA" },
      { municipio: "GURINHÉM" },
      { municipio: "GURJÃO" },
      { municipio: "IBIARA" },
      { municipio: "IGARACY" },
      { municipio: "IMACULADA" },
      { municipio: "INGÁ" },
      { municipio: "ITABAIANA" },
      { municipio: "ITAPORANGA" },
      { municipio: "ITAPOROROCA" },
      { municipio: "ITATUBA" },
      { municipio: "JACARAÚ" },
      { municipio: "JERICÓ" },
      { municipio: "JOÃO PESSOA" },
      { municipio: "JOCA CLAUDINO" },
      { municipio: "JUAREZ TÁVORA" },
      { municipio: "JUAZEIRINHO" },
      { municipio: "JUNCO DO SERIDÓ" },
      { municipio: "JURIPIRANGA" },
      { municipio: "JURU" },
      { municipio: "LAGOA" },
      { municipio: "LAGOA DE DENTRO" },
      { municipio: "LAGOA SECA" },
      { municipio: "LASTRO" },
      { municipio: "LIVRAMENTO" },
      { municipio: "LOGRADOURO" },
      { municipio: "LUCENA" },
      { municipio: "MÃE D'ÁGUA" },
      { municipio: "MALTA" },
      { municipio: "MAMANGUAPE" },
      { municipio: "MANAÍRA" },
      { municipio: "MARCAÇÃO" },
      { municipio: "MARI" },
      { municipio: "MARIZÓPOLIS" },
      { municipio: "MASSARANDUBA" },
      { municipio: "MATARACA" },
      { municipio: "MATINHAS" },
      { municipio: "MATO GROSSO" },
      { municipio: "MATURÉIA" },
      { municipio: "MOGEIRO" },
      { municipio: "MONTADAS" },
      { municipio: "MONTE HOREBE" },
      { municipio: "MONTEIRO" },
      { municipio: "MULUNGU" },
      { municipio: "NATUBA" },
      { municipio: "NAZAREZINHO" },
      { municipio: "NOVA FLORESTA" },
      { municipio: "NOVA OLINDA" },
      { municipio: "NOVA PALMEIRA" },
      { municipio: "OLHO D'ÁGUA" },
      { municipio: "OLIVEDOS" },
      { municipio: "OURO VELHO" },
      { municipio: "PARARI" },
      { municipio: "PASSAGEM" },
      { municipio: "PATOS" },
      { municipio: "PAULISTA" },
      { municipio: "PEDRA BRANCA" },
      { municipio: "PEDRA LAVRADA" },
      { municipio: "PEDRAS DE FOGO" },
      { municipio: "PEDRO RÉGIS" },
      { municipio: "PIANCÓ" },
      { municipio: "PICUÍ" },
      { municipio: "PILAR" },
      { municipio: "PILÕES" },
      { municipio: "PILÕEZINHOS" },
      { municipio: "PIRPIRITUBA" },
      { municipio: "PITIMBU" },
      { municipio: "POCINHOS" },
      { municipio: "POÇO DANTAS" },
      { municipio: "POÇO DE JOSÉ DE MOURA" },
      { municipio: "POMBAL" },
      { municipio: "PRATA" },
      { municipio: "PRINCESA ISABEL" },
      { municipio: "PUXINANÃ" },
      { municipio: "QUEIMADAS" },
      { municipio: "QUIXABA" },
      { municipio: "REMÍGIO" },
      { municipio: "RIACHÃO" },
      { municipio: "RIACHÃO DO BACAMARTE" },
      { municipio: "RIACHÃO DO POÇO" },
      { municipio: "RIACHO DE SANTO ANTÔNIO" },
      { municipio: "RIACHO DOS CAVALOS" },
      { municipio: "RIO TINTO" },
      { municipio: "SALGADINHO" },
      { municipio: "SALGADO DE SÃO FÉLIX" },
      { municipio: "SANTA CECÍLIA" },
      { municipio: "SANTA CRUZ" },
      { municipio: "SANTA HELENA" },
      { municipio: "SANTA INÊS" },
      { municipio: "SANTA LUZIA" },
      { municipio: "SANTA RITA" },
      { municipio: "SANTA TERESINHA" },
      { municipio: "SANTANA DE MANGUEIRA" },
      { municipio: "SANTANA DOS GARROTES" },
      { municipio: "SANTO ANDRÉ" },
      { municipio: "SÃO BENTINHO" },
      { municipio: "SÃO BENTO" },
      { municipio: "SÃO DOMINGOS" },
      { municipio: "SÃO DOMINGOS DO CARIRI" },
      { municipio: "SÃO FRANCISCO" },
      { municipio: "SÃO JOÃO DO CARIRI" },
      { municipio: "SÃO JOÃO DO RIO DO PEIXE" },
      { municipio: "SÃO JOÃO DO TIGRE" },
      { municipio: "SÃO JOSÉ DA LAGOA TAPADA" },
      { municipio: "SÃO JOSÉ DE CAIANA" },
      { municipio: "SÃO JOSÉ DE ESPINHARAS" },
      { municipio: "SÃO JOSÉ DE PIRANHAS" },
      { municipio: "SÃO JOSÉ DE PRINCESA" },
      { municipio: "SÃO JOSÉ DO BONFIM" },
      { municipio: "SÃO JOSÉ DO BREJO DO CRUZ" },
      { municipio: "SÃO JOSÉ DO SABUGI" },
      { municipio: "SÃO JOSÉ DOS CORDEIROS" },
      { municipio: "SÃO JOSÉ DOS RAMOS" },
      { municipio: "SÃO MAMEDE" },
      { municipio: "SÃO MIGUEL DE TAIPU" },
      { municipio: "SÃO SEBASTIÃO DE LAGOA DE ROÇA" },
      { municipio: "SÃO SEBASTIÃO DO UMBUZEIRO" },
      { municipio: "SÃO VICENTE DO SERIDÓ" },
      { municipio: "SAPÉ" },
      { municipio: "SERRA BRANCA" },
      { municipio: "SERRA DA RAIZ" },
      { municipio: "SERRA GRANDE" },
      { municipio: "SERRA REDONDA" },
      { municipio: "SERRARIA" },
      { municipio: "SERTÃOZINHO" },
      { municipio: "SOBRADO" },
      { municipio: "SOLÂNEA" },
      { municipio: "SOLEDADE" },
      { municipio: "SOSSÊGO" },
      { municipio: "SOUSA" },
      { municipio: "SUMÉ" },
      { municipio: "TACIMA" },
      { municipio: "TAPEROÁ" },
      { municipio: "TAVARES" },
      { municipio: "TEIXEIRA" },
      { municipio: "TENÓRIO" },
      { municipio: "TRIUNFO" },
      { municipio: "UIRAÚNA" },
      { municipio: "UMBUZEIRO" },
      { municipio: "VÁRZEA" },
      { municipio: "VIEIRÓPOLIS" },
      { municipio: "VISTA SERRANA" },
      { municipio: "ZABELÊ" },
    ],
    estado: "PB",
  },
  {
    municipios: [
      { municipio: "ABREU E LIMA" },
      { municipio: "AFOGADOS DA INGAZEIRA" },
      { municipio: "AFRÂNIO" },
      { municipio: "AGRESTINA" },
      { municipio: "ÁGUA PRETA" },
      { municipio: "ÁGUAS BELAS" },
      { municipio: "ALAGOINHA" },
      { municipio: "ALIANÇA" },
      { municipio: "ALTINHO" },
      { municipio: "AMARAJI" },
      { municipio: "ANGELIM" },
      { municipio: "ARAÇOIABA" },
      { municipio: "ARARIPINA" },
      { municipio: "ARCOVERDE" },
      { municipio: "BARRA DE GUABIRABA" },
      { municipio: "BARREIROS" },
      { municipio: "BELÉM DE MARIA" },
      { municipio: "BELÉM DO SÃO FRANCISCO" },
      { municipio: "BELO JARDIM" },
      { municipio: "BETÂNIA" },
      { municipio: "BEZERROS" },
      { municipio: "BODOCÓ" },
      { municipio: "BOM CONSELHO" },
      { municipio: "BOM JARDIM" },
      { municipio: "BONITO" },
      { municipio: "BREJÃO" },
      { municipio: "BREJINHO" },
      { municipio: "BREJO DA MADRE DE DEUS" },
      { municipio: "BUENOS AIRES" },
      { municipio: "BUÍQUE" },
      { municipio: "CABO DE SANTO AGOSTINHO" },
      { municipio: "CABROBÓ" },
      { municipio: "CACHOEIRINHA" },
      { municipio: "CAETÉS" },
      { municipio: "CALÇADO" },
      { municipio: "CALUMBI" },
      { municipio: "CAMARAGIBE" },
      { municipio: "CAMOCIM DE SÃO FÉLIX" },
      { municipio: "CAMUTANGA" },
      { municipio: "CANHOTINHO" },
      { municipio: "CAPOEIRAS" },
      { municipio: "CARNAÍBA" },
      { municipio: "CARNAUBEIRA DA PENHA" },
      { municipio: "CARPINA" },
      { municipio: "CARUARU" },
      { municipio: "CASINHAS" },
      { municipio: "CATENDE" },
      { municipio: "CEDRO" },
      { municipio: "CHÃ DE ALEGRIA" },
      { municipio: "CHÃ GRANDE" },
      { municipio: "CONDADO" },
      { municipio: "CORRENTES" },
      { municipio: "CORTÊS" },
      { municipio: "CUMARU" },
      { municipio: "CUPIRA" },
      { municipio: "CUSTÓDIA" },
      { municipio: "DORMENTES" },
      { municipio: "ESCADA" },
      { municipio: "EXU" },
      { municipio: "FEIRA NOVA" },
      { municipio: "FERNANDO DE NORONHA" },
      { municipio: "FERREIROS" },
      { municipio: "FLORES" },
      { municipio: "FLORESTA" },
      { municipio: "FREI MIGUELINHO" },
      { municipio: "GAMELEIRA" },
      { municipio: "GARANHUNS" },
      { municipio: "GLÓRIA DO GOITÁ" },
      { municipio: "GOIANA" },
      { municipio: "GRANITO" },
      { municipio: "GRAVATÁ" },
      { municipio: "IATI" },
      { municipio: "IBIMIRIM" },
      { municipio: "IBIRAJUBA" },
      { municipio: "IGARASSU" },
      { municipio: "IGUARACY" },
      { municipio: "ILHA DE ITAMARACÁ" },
      { municipio: "INAJÁ" },
      { municipio: "INGAZEIRA" },
      { municipio: "IPOJUCA" },
      { municipio: "IPUBI" },
      { municipio: "ITACURUBA" },
      { municipio: "ITAÍBA" },
      { municipio: "ITAMBÉ" },
      { municipio: "ITAPETIM" },
      { municipio: "ITAPISSUMA" },
      { municipio: "ITAQUITINGA" },
      { municipio: "JABOATÃO DOS GUARARAPES" },
      { municipio: "JAQUEIRA" },
      { municipio: "JATAÚBA" },
      { municipio: "JATOBÁ" },
      { municipio: "JOÃO ALFREDO" },
      { municipio: "JOAQUIM NABUCO" },
      { municipio: "JUCATI" },
      { municipio: "JUPI" },
      { municipio: "JUREMA" },
      { municipio: "LAGOA DE ITAENGA" },
      { municipio: "LAGOA DO CARRO" },
      { municipio: "LAGOA DO OURO" },
      { municipio: "LAGOA DOS GATOS" },
      { municipio: "LAGOA GRANDE" },
      { municipio: "LAJEDO" },
      { municipio: "LIMOEIRO" },
      { municipio: "MACAPARANA" },
      { municipio: "MACHADOS" },
      { municipio: "MANARI" },
      { municipio: "MARAIAL" },
      { municipio: "MIRANDIBA" },
      { municipio: "MOREILÂNDIA" },
      { municipio: "MORENO" },
      { municipio: "NAZARÉ DA MATA" },
      { municipio: "OLINDA" },
      { municipio: "OROBÓ" },
      { municipio: "OROCÓ" },
      { municipio: "OURICURI" },
      { municipio: "PALMARES" },
      { municipio: "PALMEIRINA" },
      { municipio: "PANELAS" },
      { municipio: "PARANATAMA" },
      { municipio: "PARNAMIRIM" },
      { municipio: "PASSIRA" },
      { municipio: "PAUDALHO" },
      { municipio: "PAULISTA" },
      { municipio: "PEDRA" },
      { municipio: "PESQUEIRA" },
      { municipio: "PETROLÂNDIA" },
      { municipio: "PETROLINA" },
      { municipio: "POÇÃO" },
      { municipio: "POMBOS" },
      { municipio: "PRIMAVERA" },
      { municipio: "QUIPAPÁ" },
      { municipio: "QUIXABA" },
      { municipio: "RECIFE" },
      { municipio: "RIACHO DAS ALMAS" },
      { municipio: "RIBEIRÃO" },
      { municipio: "RIO FORMOSO" },
      { municipio: "SAIRÉ" },
      { municipio: "SALGADINHO" },
      { municipio: "SALGUEIRO" },
      { municipio: "SALOÁ" },
      { municipio: "SANHARÓ" },
      { municipio: "SANTA CRUZ" },
      { municipio: "SANTA CRUZ DA BAIXA VERDE" },
      { municipio: "SANTA CRUZ DO CAPIBARIBE" },
      { municipio: "SANTA FILOMENA" },
      { municipio: "SANTA MARIA DA BOA VISTA" },
      { municipio: "SANTA MARIA DO CAMBUCÁ" },
      { municipio: "SANTA TEREZINHA" },
      { municipio: "SÃO BENEDITO DO SUL" },
      { municipio: "SÃO BENTO DO UNA" },
      { municipio: "SÃO CAITANO" },
      { municipio: "SÃO JOÃO" },
      { municipio: "SÃO JOAQUIM DO MONTE" },
      { municipio: "SÃO JOSÉ DA COROA GRANDE" },
      { municipio: "SÃO JOSÉ DO BELMONTE" },
      { municipio: "SÃO JOSÉ DO EGITO" },
      { municipio: "SÃO LOURENÇO DA MATA" },
      { municipio: "SÃO VICENTE FÉRRER" },
      { municipio: "SERRA TALHADA" },
      { municipio: "SERRITA" },
      { municipio: "SERTÂNIA" },
      { municipio: "SIRINHAÉM" },
      { municipio: "SOLIDÃO" },
      { municipio: "SURUBIM" },
      { municipio: "TABIRA" },
      { municipio: "TACAIMBÓ" },
      { municipio: "TACARATU" },
      { municipio: "TAMANDARÉ" },
      { municipio: "TAQUARITINGA DO NORTE" },
      { municipio: "TEREZINHA" },
      { municipio: "TERRA NOVA" },
      { municipio: "TIMBAÚBA" },
      { municipio: "TORITAMA" },
      { municipio: "TRACUNHAÉM" },
      { municipio: "TRINDADE" },
      { municipio: "TRIUNFO" },
      { municipio: "TUPANATINGA" },
      { municipio: "TUPARETAMA" },
      { municipio: "VENTUROSA" },
      { municipio: "VERDEJANTE" },
      { municipio: "VERTENTE DO LÉRIO" },
      { municipio: "VERTENTES" },
      { municipio: "VICÊNCIA" },
      { municipio: "VITÓRIA DE SANTO ANTÃO" },
      { municipio: "XEXÉU" },
      { municipio: "LAGOA DO ITAENGA" },
      { municipio: "BELEM DE SAO FRANCISCO" },
      { municipio: "IGUARACI" },
    ],
    estado: "PE",
  },
  {
    municipios: [
      { municipio: "ANGRA DOS REIS" },
      { municipio: "APERIBÉ" },
      { municipio: "ARARUAMA" },
      { municipio: "AREAL" },
      { municipio: "ARMAÇÃO DOS BÚZIOS" },
      { municipio: "ARRAIAL DO CABO" },
      { municipio: "BARRA DO PIRAÍ" },
      { municipio: "BARRA MANSA" },
      { municipio: "BELFORD ROXO" },
      { municipio: "BOM JARDIM" },
      { municipio: "BOM JESUS DO ITABAPOANA" },
      { municipio: "CABO FRIO" },
      { municipio: "CACHOEIRAS DE MACACU" },
      { municipio: "CAMBUCI" },
      { municipio: "CAMPOS DOS GOYTACAZES" },
      { municipio: "CANTAGALO" },
      { municipio: "CARAPEBUS" },
      { municipio: "CARDOSO MOREIRA" },
      { municipio: "CARMO" },
      { municipio: "CASIMIRO DE ABREU" },
      { municipio: "COMENDADOR LEVY GASPARIAN" },
      { municipio: "CONCEIÇÃO DE MACABU" },
      { municipio: "CORDEIRO" },
      { municipio: "DUAS BARRAS" },
      { municipio: "DUQUE DE CAXIAS" },
      { municipio: "ENGENHEIRO PAULO DE FRONTIN" },
      { municipio: "GUAPIMIRIM" },
      { municipio: "IGUABA GRANDE" },
      { municipio: "ITABORAÍ" },
      { municipio: "ITAGUAÍ" },
      { municipio: "ITALVA" },
      { municipio: "ITAOCARA" },
      { municipio: "ITAPERUNA" },
      { municipio: "ITATIAIA" },
      { municipio: "JAPERI" },
      { municipio: "LAJE DO MURIAÉ" },
      { municipio: "MACAÉ" },
      { municipio: "MACUCO" },
      { municipio: "MAGÉ" },
      { municipio: "MANGARATIBA" },
      { municipio: "MARICÁ" },
      { municipio: "MENDES" },
      { municipio: "MESQUITA" },
      { municipio: "MIGUEL PEREIRA" },
      { municipio: "MIRACEMA" },
      { municipio: "NATIVIDADE" },
      { municipio: "NILÓPOLIS" },
      { municipio: "NITERÓI" },
      { municipio: "NOVA FRIBURGO" },
      { municipio: "NOVA IGUAÇU" },
      { municipio: "PARACAMBI" },
      { municipio: "PARAÍBA DO SUL" },
      { municipio: "PARATY" },
      { municipio: "PATY DO ALFERES" },
      { municipio: "PETRÓPOLIS" },
      { municipio: "PINHEIRAL" },
      { municipio: "PIRAÍ" },
      { municipio: "PORCIÚNCULA" },
      { municipio: "PORTO REAL" },
      { municipio: "QUATIS" },
      { municipio: "QUEIMADOS" },
      { municipio: "QUISSAMÃ" },
      { municipio: "RESENDE" },
      { municipio: "RIO BONITO" },
      { municipio: "RIO CLARO" },
      { municipio: "RIO DAS FLORES" },
      { municipio: "RIO DAS OSTRAS" },
      { municipio: "RIO DE JANEIRO" },
      { municipio: "SANTA MARIA MADALENA" },
      { municipio: "SANTO ANTÔNIO DE PÁDUA" },
      { municipio: "SÃO FIDÉLIS" },
      { municipio: "SÃO FRANCISCO DE ITABAPOANA" },
      { municipio: "SÃO GONÇALO" },
      { municipio: "SÃO JOÃO DA BARRA" },
      { municipio: "SÃO JOÃO DE MERITI" },
      { municipio: "SÃO JOSÉ DE UBÁ" },
      { municipio: "SÃO JOSÉ DO VALE DO RIO PRETO" },
      { municipio: "SÃO PEDRO DA ALDEIA" },
      { municipio: "SÃO SEBASTIÃO DO ALTO" },
      { municipio: "SAPUCAIA" },
      { municipio: "SAQUAREMA" },
      { municipio: "SEROPÉDICA" },
      { municipio: "SILVA JARDIM" },
      { municipio: "SUMIDOURO" },
      { municipio: "TANGUÁ" },
      { municipio: "TERESÓPOLIS" },
      { municipio: "TRAJANO DE MORAES" },
      { municipio: "TRÊS RIOS" },
      { municipio: "VALENÇA" },
      { municipio: "VARRE-SAI" },
      { municipio: "VASSOURAS" },
      { municipio: "VOLTA REDONDA" },
      { municipio: "VARRE SAI" },
    ],
    estado: "RJ",
  },
  {
    municipios: [
      { municipio: "ACAUÃ" },
      { municipio: "AGRICOLÂNDIA" },
      { municipio: "ÁGUA BRANCA" },
      { municipio: "ALAGOINHA DO PIAUÍ" },
      { municipio: "ALEGRETE DO PIAUÍ" },
      { municipio: "ALTO LONGÁ" },
      { municipio: "ALTOS" },
      { municipio: "ALVORADA DO GURGUÉIA" },
      { municipio: "AMARANTE" },
      { municipio: "ANGICAL DO PIAUÍ" },
      { municipio: "ANÍSIO DE ABREU" },
      { municipio: "ANTÔNIO ALMEIDA" },
      { municipio: "AROAZES" },
      { municipio: "AROEIRAS DO ITAIM" },
      { municipio: "ARRAIAL" },
      { municipio: "ASSUNÇÃO DO PIAUÍ" },
      { municipio: "AVELINO LOPES" },
      { municipio: "BAIXA GRANDE DO RIBEIRO" },
      { municipio: "BARRA D'ALCÂNTARA" },
      { municipio: "BARRAS" },
      { municipio: "BARREIRAS DO PIAUÍ" },
      { municipio: "BARRO DURO" },
      { municipio: "BATALHA" },
      { municipio: "BELA VISTA DO PIAUÍ" },
      { municipio: "BELÉM DO PIAUÍ" },
      { municipio: "BENEDITINOS" },
      { municipio: "BERTOLÍNIA" },
      { municipio: "BETÂNIA DO PIAUÍ" },
      { municipio: "BOA HORA" },
      { municipio: "BOCAINA" },
      { municipio: "BOM JESUS" },
      { municipio: "BOM PRINCÍPIO DO PIAUÍ" },
      { municipio: "BONFIM DO PIAUÍ" },
      { municipio: "BOQUEIRÃO DO PIAUÍ" },
      { municipio: "BRASILEIRA" },
      { municipio: "BREJO DO PIAUÍ" },
      { municipio: "BURITI DOS LOPES" },
      { municipio: "BURITI DOS MONTES" },
      { municipio: "CABECEIRAS DO PIAUÍ" },
      { municipio: "CAJAZEIRAS DO PIAUÍ" },
      { municipio: "CAJUEIRO DA PRAIA" },
      { municipio: "CALDEIRÃO GRANDE DO PIAUÍ" },
      { municipio: "CAMPINAS DO PIAUÍ" },
      { municipio: "CAMPO ALEGRE DO FIDALGO" },
      { municipio: "CAMPO GRANDE DO PIAUÍ" },
      { municipio: "CAMPO LARGO DO PIAUÍ" },
      { municipio: "CAMPO MAIOR" },
      { municipio: "CANAVIEIRA" },
      { municipio: "CANTO DO BURITI" },
      { municipio: "CAPITÃO DE CAMPOS" },
      { municipio: "CAPITÃO GERVÁSIO OLIVEIRA" },
      { municipio: "CARACOL" },
      { municipio: "CARAÚBAS DO PIAUÍ" },
      { municipio: "CARIDADE DO PIAUÍ" },
      { municipio: "CASTELO DO PIAUÍ" },
      { municipio: "CAXINGÓ" },
      { municipio: "COCAL" },
      { municipio: "COCAL DE TELHA" },
      { municipio: "COCAL DOS ALVES" },
      { municipio: "COIVARAS" },
      { municipio: "COLÔNIA DO GURGUÉIA" },
      { municipio: "COLÔNIA DO PIAUÍ" },
      { municipio: "CONCEIÇÃO DO CANINDÉ" },
      { municipio: "CORONEL JOSÉ DIAS" },
      { municipio: "CORRENTE" },
      { municipio: "CRISTALÂNDIA DO PIAUÍ" },
      { municipio: "CRISTINO CASTRO" },
      { municipio: "CURIMATÁ" },
      { municipio: "CURRAIS" },
      { municipio: "CURRAL NOVO DO PIAUÍ" },
      { municipio: "CURRALINHOS" },
      { municipio: "DEMERVAL LOBÃO" },
      { municipio: "DIRCEU ARCOVERDE" },
      { municipio: "DOM EXPEDITO LOPES" },
      { municipio: "DOM INOCÊNCIO" },
      { municipio: "DOMINGOS MOURÃO" },
      { municipio: "ELESBÃO VELOSO" },
      { municipio: "ELISEU MARTINS" },
      { municipio: "ESPERANTINA" },
      { municipio: "FARTURA DO PIAUÍ" },
      { municipio: "FLORES DO PIAUÍ" },
      { municipio: "FLORESTA DO PIAUÍ" },
      { municipio: "FLORIANO" },
      { municipio: "FRANCINÓPOLIS" },
      { municipio: "FRANCISCO AYRES" },
      { municipio: "FRANCISCO MACEDO" },
      { municipio: "FRANCISCO SANTOS" },
      { municipio: "FRONTEIRAS" },
      { municipio: "GEMINIANO" },
      { municipio: "GILBUÉS" },
      { municipio: "GUADALUPE" },
      { municipio: "GUARIBAS" },
      { municipio: "HUGO NAPOLEÃO" },
      { municipio: "ILHA GRANDE" },
      { municipio: "INHUMA" },
      { municipio: "IPIRANGA DO PIAUÍ" },
      { municipio: "ISAÍAS COELHO" },
      { municipio: "ITAINÓPOLIS" },
      { municipio: "ITAUEIRA" },
      { municipio: "JACOBINA DO PIAUÍ" },
      { municipio: "JAICÓS" },
      { municipio: "JARDIM DO MULATO" },
      { municipio: "JATOBÁ DO PIAUÍ" },
      { municipio: "JERUMENHA" },
      { municipio: "JOÃO COSTA" },
      { municipio: "JOAQUIM PIRES" },
      { municipio: "JOCA MARQUES" },
      { municipio: "JOSÉ DE FREITAS" },
      { municipio: "JUAZEIRO DO PIAUÍ" },
      { municipio: "JÚLIO BORGES" },
      { municipio: "JUREMA" },
      { municipio: "LAGOA ALEGRE" },
      { municipio: "LAGOA DE SÃO FRANCISCO" },
      { municipio: "LAGOA DO BARRO DO PIAUÍ" },
      { municipio: "LAGOA DO PIAUÍ" },
      { municipio: "LAGOA DO SÍTIO" },
      { municipio: "LAGOINHA DO PIAUÍ" },
      { municipio: "LANDRI SALES" },
      { municipio: "LUÍS CORREIA" },
      { municipio: "LUZILÂNDIA" },
      { municipio: "MADEIRO" },
      { municipio: "MANOEL EMÍDIO" },
      { municipio: "MARCOLÂNDIA" },
      { municipio: "MARCOS PARENTE" },
      { municipio: "MASSAPÊ DO PIAUÍ" },
      { municipio: "MATIAS OLÍMPIO" },
      { municipio: "MIGUEL ALVES" },
      { municipio: "MIGUEL LEÃO" },
      { municipio: "MILTON BRANDÃO" },
      { municipio: "MONSENHOR GIL" },
      { municipio: "MONSENHOR HIPÓLITO" },
      { municipio: "MONTE ALEGRE DO PIAUÍ" },
      { municipio: "MORRO CABEÇA NO TEMPO" },
      { municipio: "MORRO DO CHAPÉU DO PIAUÍ" },
      { municipio: "MURICI DOS PORTELAS" },
      { municipio: "NAZARÉ DO PIAUÍ" },
      { municipio: "NAZÁRIA" },
      { municipio: "NOSSA SENHORA DE NAZARÉ" },
      { municipio: "NOSSA SENHORA DOS REMÉDIOS" },
      { municipio: "NOVA SANTA RITA" },
      { municipio: "NOVO ORIENTE DO PIAUÍ" },
      { municipio: "NOVO SANTO ANTÔNIO" },
      { municipio: "OEIRAS" },
      { municipio: "OLHO D'ÁGUA DO PIAUÍ" },
      { municipio: "PADRE MARCOS" },
      { municipio: "PAES LANDIM" },
      { municipio: "PAJEÚ DO PIAUÍ" },
      { municipio: "PALMEIRA DO PIAUÍ" },
      { municipio: "PALMEIRAIS" },
      { municipio: "PAQUETÁ" },
      { municipio: "PARNAGUÁ" },
      { municipio: "PARNAÍBA" },
      { municipio: "PASSAGEM FRANCA DO PIAUÍ" },
      { municipio: "PATOS DO PIAUÍ" },
      { municipio: "PAU D'ARCO DO PIAUÍ" },
      { municipio: "PAULISTANA" },
      { municipio: "PAVUSSU" },
      { municipio: "PEDRO II" },
      { municipio: "PEDRO LAURENTINO" },
      { municipio: "PICOS" },
      { municipio: "PIMENTEIRAS" },
      { municipio: "PIO IX" },
      { municipio: "PIRACURUCA" },
      { municipio: "PIRIPIRI" },
      { municipio: "PORTO" },
      { municipio: "PORTO ALEGRE DO PIAUÍ" },
      { municipio: "PRATA DO PIAUÍ" },
      { municipio: "QUEIMADA NOVA" },
      { municipio: "REDENÇÃO DO GURGUÉIA" },
      { municipio: "REGENERAÇÃO" },
      { municipio: "RIACHO FRIO" },
      { municipio: "RIBEIRA DO PIAUÍ" },
      { municipio: "RIBEIRO GONÇALVES" },
      { municipio: "RIO GRANDE DO PIAUÍ" },
      { municipio: "SANTA CRUZ DO PIAUÍ" },
      { municipio: "SANTA CRUZ DOS MILAGRES" },
      { municipio: "SANTA FILOMENA" },
      { municipio: "SANTA LUZ" },
      { municipio: "SANTA ROSA DO PIAUÍ" },
      { municipio: "SANTANA DO PIAUÍ" },
      { municipio: "SANTO ANTÔNIO DE LISBOA" },
      { municipio: "SANTO ANTÔNIO DOS MILAGRES" },
      { municipio: "SANTO INÁCIO DO PIAUÍ" },
      { municipio: "SÃO BRAZ DO PIAUÍ" },
      { municipio: "SÃO FÉLIX DO PIAUÍ" },
      { municipio: "SÃO FRANCISCO DE ASSIS DO PIAUÍ" },
      { municipio: "SÃO FRANCISCO DO PIAUÍ" },
      { municipio: "SÃO GONÇALO DO GURGUÉIA" },
      { municipio: "SÃO GONÇALO DO PIAUÍ" },
      { municipio: "SÃO JOÃO DA CANABRAVA" },
      { municipio: "SÃO JOÃO DA FRONTEIRA" },
      { municipio: "SÃO JOÃO DA SERRA" },
      { municipio: "SÃO JOÃO DA VARJOTA" },
      { municipio: "SÃO JOÃO DO ARRAIAL" },
      { municipio: "SÃO JOÃO DO PIAUÍ" },
      { municipio: "SÃO JOSÉ DO DIVINO" },
      { municipio: "SÃO JOSÉ DO PEIXE" },
      { municipio: "SÃO JOSÉ DO PIAUÍ" },
      { municipio: "SÃO JULIÃO" },
      { municipio: "SÃO LOURENÇO DO PIAUÍ" },
      { municipio: "SÃO LUIS DO PIAUÍ" },
      { municipio: "SÃO MIGUEL DA BAIXA GRANDE" },
      { municipio: "SÃO MIGUEL DO FIDALGO" },
      { municipio: "SÃO MIGUEL DO TAPUIO" },
      { municipio: "SÃO PEDRO DO PIAUÍ" },
      { municipio: "SÃO RAIMUNDO NONATO" },
      { municipio: "SEBASTIÃO BARROS" },
      { municipio: "SEBASTIÃO LEAL" },
      { municipio: "SIGEFREDO PACHECO" },
      { municipio: "SIMÕES" },
      { municipio: "SIMPLÍCIO MENDES" },
      { municipio: "SOCORRO DO PIAUÍ" },
      { municipio: "SUSSUAPARA" },
      { municipio: "TAMBORIL DO PIAUÍ" },
      { municipio: "TANQUE DO PIAUÍ" },
      { municipio: "TERESINA" },
      { municipio: "UNIÃO" },
      { municipio: "URUÇUÍ" },
      { municipio: "VALENÇA DO PIAUÍ" },
      { municipio: "VÁRZEA BRANCA" },
      { municipio: "VÁRZEA GRANDE" },
      { municipio: "VERA MENDES" },
      { municipio: "VILA NOVA DO PIAUÍ" },
      { municipio: "WALL FERRAZ" },
      { municipio: "BOM JESUS" },
    ],
    estado: "PI",
  },
  {
    municipios: [
      { municipio: "ABREULÂNDIA" },
      { municipio: "AGUIARNÓPOLIS" },
      { municipio: "ALIANÇA DO TOCANTINS" },
      { municipio: "ALMAS" },
      { municipio: "ALVORADA" },
      { municipio: "ANANÁS" },
      { municipio: "ANGICO" },
      { municipio: "APARECIDA DO RIO NEGRO" },
      { municipio: "ARAGOMINAS" },
      { municipio: "ARAGUACEMA" },
      { municipio: "ARAGUAÇU" },
      { municipio: "ARAGUAÍNA" },
      { municipio: "ARAGUANÃ" },
      { municipio: "ARAGUATINS" },
      { municipio: "ARAPOEMA" },
      { municipio: "ARRAIAS" },
      { municipio: "AUGUSTINÓPOLIS" },
      { municipio: "AURORA DO TOCANTINS" },
      { municipio: "AXIXÁ DO TOCANTINS" },
      { municipio: "BABAÇULÂNDIA" },
      { municipio: "BANDEIRANTES DO TOCANTINS" },
      { municipio: "BARRA DO OURO" },
      { municipio: "BARROLÂNDIA" },
      { municipio: "BERNARDO SAYÃO" },
      { municipio: "BOM JESUS DO TOCANTINS" },
      { municipio: "BRASILÂNDIA DO TOCANTINS" },
      { municipio: "BREJINHO DE NAZARÉ" },
      { municipio: "BURITI DO TOCANTINS" },
      { municipio: "CACHOEIRINHA" },
      { municipio: "CAMPOS LINDOS" },
      { municipio: "CARIRI DO TOCANTINS" },
      { municipio: "CARMOLÂNDIA" },
      { municipio: "CARRASCO BONITO" },
      { municipio: "CASEARA" },
      { municipio: "CENTENÁRIO" },
      { municipio: "CHAPADA DA NATIVIDADE" },
      { municipio: "CHAPADA DE AREIA" },
      { municipio: "COLINAS DO TOCANTINS" },
      { municipio: "COLMÉIA" },
      { municipio: "COMBINADO" },
      { municipio: "CONCEIÇÃO DO TOCANTINS" },
      { municipio: "COUTO MAGALHÃES" },
      { municipio: "CRISTALÂNDIA" },
      { municipio: "CRIXÁS DO TOCANTINS" },
      { municipio: "DARCINÓPOLIS" },
      { municipio: "DIANÓPOLIS" },
      { municipio: "DIVINÓPOLIS DO TOCANTINS" },
      { municipio: "DOIS IRMÃOS DO TOCANTINS" },
      { municipio: "DUERÉ" },
      { municipio: "ESPERANTINA" },
      { municipio: "FÁTIMA" },
      { municipio: "FIGUEIRÓPOLIS" },
      { municipio: "FILADÉLFIA" },
      { municipio: "FORMOSO DO ARAGUAIA" },
      { municipio: "FORTALEZA DO TABOCÃO" },
      { municipio: "GOIANORTE" },
      { municipio: "GOIATINS" },
      { municipio: "GUARAÍ" },
      { municipio: "GURUPI" },
      { municipio: "IPUEIRAS" },
      { municipio: "ITACAJÁ" },
      { municipio: "ITAGUATINS" },
      { municipio: "ITAPIRATINS" },
      { municipio: "ITAPORÃ DO TOCANTINS" },
      { municipio: "JAÚ DO TOCANTINS" },
      { municipio: "JUARINA" },
      { municipio: "LAGOA DA CONFUSÃO" },
      { municipio: "LAGOA DO TOCANTINS" },
      { municipio: "LAJEADO" },
      { municipio: "LAVANDEIRA" },
      { municipio: "LIZARDA" },
      { municipio: "LUZINÓPOLIS" },
      { municipio: "MARIANÓPOLIS DO TOCANTINS" },
      { municipio: "MATEIROS" },
      { municipio: "MAURILÂNDIA DO TOCANTINS" },
      { municipio: "MIRACEMA DO TOCANTINS" },
      { municipio: "MIRANORTE" },
      { municipio: "MONTE DO CARMO" },
      { municipio: "MONTE SANTO DO TOCANTINS" },
      { municipio: "MURICILÂNDIA" },
      { municipio: "NATIVIDADE" },
      { municipio: "NAZARÉ" },
      { municipio: "NOVA OLINDA" },
      { municipio: "NOVA ROSALÂNDIA" },
      { municipio: "NOVO ACORDO" },
      { municipio: "NOVO ALEGRE" },
      { municipio: "NOVO JARDIM" },
      { municipio: "OLIVEIRA DE FÁTIMA" },
      { municipio: "PALMAS" },
      { municipio: "PALMEIRANTE" },
      { municipio: "PALMEIRAS DO TOCANTINS" },
      { municipio: "PALMEIRÓPOLIS" },
      { municipio: "PARAÍSO DO TOCANTINS" },
      { municipio: "PARANÃ" },
      { municipio: "PAU D'ARCO" },
      { municipio: "PEDRO AFONSO" },
      { municipio: "PEIXE" },
      { municipio: "PEQUIZEIRO" },
      { municipio: "PINDORAMA DO TOCANTINS" },
      { municipio: "PIRAQUÊ" },
      { municipio: "PIUM" },
      { municipio: "PONTE ALTA DO BOM JESUS" },
      { municipio: "PONTE ALTA DO TOCANTINS" },
      { municipio: "PORTO ALEGRE DO TOCANTINS" },
      { municipio: "PORTO NACIONAL" },
      { municipio: "PRAIA NORTE" },
      { municipio: "PRESIDENTE KENNEDY" },
      { municipio: "PUGMIL" },
      { municipio: "RECURSOLÂNDIA" },
      { municipio: "RIACHINHO" },
      { municipio: "RIO DA CONCEIÇÃO" },
      { municipio: "RIO DOS BOIS" },
      { municipio: "RIO SONO" },
      { municipio: "SAMPAIO" },
      { municipio: "SANDOLÂNDIA" },
      { municipio: "SANTA FÉ DO ARAGUAIA" },
      { municipio: "SANTA MARIA DO TOCANTINS" },
      { municipio: "SANTA RITA DO TOCANTINS" },
      { municipio: "SANTA ROSA DO TOCANTINS" },
      { municipio: "SANTA TEREZA DO TOCANTINS" },
      { municipio: "SANTA TEREZINHA DO TOCANTINS" },
      { municipio: "SÃO BENTO DO TOCANTINS" },
      { municipio: "SÃO FÉLIX DO TOCANTINS" },
      { municipio: "SÃO MIGUEL DO TOCANTINS" },
      { municipio: "SÃO SALVADOR DO TOCANTINS" },
      { municipio: "SÃO SEBASTIÃO DO TOCANTINS" },
      { municipio: "SÃO VALÉRIO" },
      { municipio: "SILVANÓPOLIS" },
      { municipio: "SÍTIO NOVO DO TOCANTINS" },
      { municipio: "SUCUPIRA" },
      { municipio: "TAGUATINGA" },
      { municipio: "TAIPAS DO TOCANTINS" },
      { municipio: "TALISMÃ" },
      { municipio: "TOCANTÍNIA" },
      { municipio: "TOCANTINÓPOLIS" },
      { municipio: "TUPIRAMA" },
      { municipio: "TUPIRATINS" },
      { municipio: "WANDERLÂNDIA" },
      { municipio: "XAMBIOÁ" },
      { municipio: "SAO VALERIO DA NATIVIDADE" },
      { municipio: "COUTO DE MAGALHAES" },
    ],
    estado: "TO",
  },
  {
    municipios: [
      { municipio: "ACARI" },
      { municipio: "AÇU" },
      { municipio: "AFONSO BEZERRA" },
      { municipio: "ÁGUA NOVA" },
      { municipio: "ALEXANDRIA" },
      { municipio: "ALMINO AFONSO" },
      { municipio: "ALTO DO RODRIGUES" },
      { municipio: "ANGICOS" },
      { municipio: "ANTÔNIO MARTINS" },
      { municipio: "APODI" },
      { municipio: "AREIA BRANCA" },
      { municipio: "ARÊS" },
      { municipio: "AUGUSTO SEVERO" },
      { municipio: "CAMPO GRANDE" },
      { municipio: "BAÍA FORMOSA" },
      { municipio: "BARAÚNA" },
      { municipio: "BARCELONA" },
      { municipio: "BENTO FERNANDES" },
      { municipio: "BODÓ" },
      { municipio: "BOM JESUS" },
      { municipio: "BREJINHO" },
      { municipio: "CAIÇARA DO NORTE" },
      { municipio: "CAIÇARA DO RIO DO VENTO" },
      { municipio: "CAICÓ" },
      { municipio: "CAMPO REDONDO" },
      { municipio: "CANGUARETAMA" },
      { municipio: "CARAÚBAS" },
      { municipio: "CARNAÚBA DOS DANTAS" },
      { municipio: "CARNAUBAIS" },
      { municipio: "CEARÁ-MIRIM" },
      { municipio: "CERRO CORÁ" },
      { municipio: "CORONEL EZEQUIEL" },
      { municipio: "CORONEL JOÃO PESSOA" },
      { municipio: "CRUZETA" },
      { municipio: "CURRAIS NOVOS" },
      { municipio: "DOUTOR SEVERIANO" },
      { municipio: "ENCANTO" },
      { municipio: "EQUADOR" },
      { municipio: "ESPÍRITO SANTO" },
      { municipio: "EXTREMOZ" },
      { municipio: "FELIPE GUERRA" },
      { municipio: "FERNANDO PEDROZA" },
      { municipio: "FLORÂNIA" },
      { municipio: "FRANCISCO DANTAS" },
      { municipio: "FRUTUOSO GOMES" },
      { municipio: "GALINHOS" },
      { municipio: "GOIANINHA" },
      { municipio: "GOVERNADOR DIX-SEPT ROSADO" },
      { municipio: "GROSSOS" },
      { municipio: "GUAMARÉ" },
      { municipio: "IELMO MARINHO" },
      { municipio: "IPANGUAÇU" },
      { municipio: "IPUEIRA" },
      { municipio: "ITAJÁ" },
      { municipio: "ITAÚ" },
      { municipio: "JAÇANÃ" },
      { municipio: "JANDAÍRA" },
      { municipio: "JANDUÍS" },
      { municipio: "JANUÁRIO CICCO" },
      { municipio: "BOA SAÚDE" },
      { municipio: "JAPI" },
      { municipio: "JARDIM DE ANGICOS" },
      { municipio: "JARDIM DE PIRANHAS" },
      { municipio: "JARDIM DO SERIDÓ" },
      { municipio: "JOÃO CÂMARA" },
      { municipio: "JOÃO DIAS" },
      { municipio: "JOSÉ DA PENHA" },
      { municipio: "JUCURUTU" },
      { municipio: "JUNDIÁ" },
      { municipio: "LAGOA D'ANTA" },
      { municipio: "LAGOA DE PEDRAS" },
      { municipio: "LAGOA DE VELHOS" },
      { municipio: "LAGOA NOVA" },
      { municipio: "LAGOA SALGADA" },
      { municipio: "LAJES" },
      { municipio: "LAJES PINTADAS" },
      { municipio: "LUCRÉCIA" },
      { municipio: "LUÍS GOMES" },
      { municipio: "MACAÍBA" },
      { municipio: "MACAU" },
      { municipio: "MAJOR SALES" },
      { municipio: "MARCELINO VIEIRA" },
      { municipio: "MARTINS" },
      { municipio: "MAXARANGUAPE" },
      { municipio: "MESSIAS TARGINO" },
      { municipio: "MONTANHAS" },
      { municipio: "MONTE ALEGRE" },
      { municipio: "MONTE DAS GAMELEIRAS" },
      { municipio: "MOSSORÓ" },
      { municipio: "NATAL" },
      { municipio: "NÍSIA FLORESTA" },
      { municipio: "NOVA CRUZ" },
      { municipio: "OLHO D'ÁGUA DO BORGES" },
      { municipio: "OURO BRANCO" },
      { municipio: "PARANÁ" },
      { municipio: "PARAÚ" },
      { municipio: "PARAZINHO" },
      { municipio: "PARELHAS" },
      { municipio: "PARNAMIRIM" },
      { municipio: "PASSA E FICA" },
      { municipio: "PASSAGEM" },
      { municipio: "PATU" },
      { municipio: "PAU DOS FERROS" },
      { municipio: "PEDRA GRANDE" },
      { municipio: "PEDRA PRETA" },
      { municipio: "PEDRO AVELINO" },
      { municipio: "PEDRO VELHO" },
      { municipio: "PENDÊNCIAS" },
      { municipio: "PILÕES" },
      { municipio: "POÇO BRANCO" },
      { municipio: "PORTALEGRE" },
      { municipio: "PORTO DO MANGUE" },
      { municipio: "PUREZA" },
      { municipio: "RAFAEL FERNANDES" },
      { municipio: "RAFAEL GODEIRO" },
      { municipio: "RIACHO DA CRUZ" },
      { municipio: "RIACHO DE SANTANA" },
      { municipio: "RIACHUELO" },
      { municipio: "RIO DO FOGO" },
      { municipio: "RODOLFO FERNANDES" },
      { municipio: "RUY BARBOSA" },
      { municipio: "SANTA CRUZ" },
      { municipio: "SANTA MARIA" },
      { municipio: "SANTANA DO MATOS" },
      { municipio: "SANTANA DO SERIDÓ" },
      { municipio: "SANTO ANTÔNIO" },
      { municipio: "SÃO BENTO DO NORTE" },
      { municipio: "SÃO BENTO DO TRAIRÍ" },
      { municipio: "SÃO FERNANDO" },
      { municipio: "SÃO FRANCISCO DO OESTE" },
      { municipio: "SÃO GONÇALO DO AMARANTE" },
      { municipio: "SÃO JOÃO DO SABUGI" },
      { municipio: "SÃO JOSÉ DE MIPIBU" },
      { municipio: "SÃO JOSÉ DO CAMPESTRE" },
      { municipio: "SÃO JOSÉ DO SERIDÓ" },
      { municipio: "SÃO MIGUEL" },
      { municipio: "SÃO MIGUEL DO GOSTOSO" },
      { municipio: "SÃO PAULO DO POTENGI" },
      { municipio: "SÃO PEDRO" },
      { municipio: "SÃO RAFAEL" },
      { municipio: "SÃO TOMÉ" },
      { municipio: "SÃO VICENTE" },
      { municipio: "SENADOR ELÓI DE SOUZA" },
      { municipio: "SENADOR GEORGINO AVELINO" },
      { municipio: "SERRA CAIADA" },
      { municipio: "SERRA DE SÃO BENTO" },
      { municipio: "SERRA DO MEL" },
      { municipio: "SERRA NEGRA DO NORTE" },
      { municipio: "SERRINHA" },
      { municipio: "SERRINHA DOS PINTOS" },
      { municipio: "SEVERIANO MELO" },
      { municipio: "SÍTIO NOVO" },
      { municipio: "TABOLEIRO GRANDE" },
      { municipio: "TAIPU" },
      { municipio: "TANGARÁ" },
      { municipio: "TENENTE ANANIAS" },
      { municipio: "TENENTE LAURENTINO CRUZ" },
      { municipio: "TIBAU" },
      { municipio: "TIBAU DO SUL" },
      { municipio: "TIMBAÚBA DOS BATISTAS" },
      { municipio: "TOUROS" },
      { municipio: "TRIUNFO POTIGUAR" },
      { municipio: "UMARIZAL" },
      { municipio: "UPANEMA" },
      { municipio: "VÁRZEA" },
      { municipio: "VENHA-VER" },
      { municipio: "VERA CRUZ" },
      { municipio: "VIÇOSA" },
      { municipio: "VILA FLOR" },
      { municipio: "CEARA MIRIM" },
      { municipio: "VENHA VER" },
      { municipio: "GOVERNADOR DIX SEPT ROSADO" },
      { municipio: "ASSU" },
    ],
    estado: "RN",
  },
  {
    municipios: [
      { municipio: "ALTA FLORESTA D'OESTE" },
      { municipio: "ALTO ALEGRE DOS PARECIS" },
      { municipio: "ALTO PARAÍSO" },
      { municipio: "ALVORADA D'OESTE" },
      { municipio: "ARIQUEMES" },
      { municipio: "BURITIS" },
      { municipio: "CABIXI" },
      { municipio: "CACAULÂNDIA" },
      { municipio: "CACOAL" },
      { municipio: "CAMPO NOVO DE RONDÔNIA" },
      { municipio: "CANDEIAS DO JAMARI" },
      { municipio: "CASTANHEIRAS" },
      { municipio: "CEREJEIRAS" },
      { municipio: "CHUPINGUAIA" },
      { municipio: "COLORADO DO OESTE" },
      { municipio: "CORUMBIARA" },
      { municipio: "COSTA MARQUES" },
      { municipio: "CUJUBIM" },
      { municipio: "ESPIGÃO D'OESTE" },
      { municipio: "GOVERNADOR JORGE TEIXEIRA" },
      { municipio: "GUAJARÁ-MIRIM" },
      { municipio: "ITAPUÃ DO OESTE" },
      { municipio: "JARU" },
      { municipio: "JI-PARANÁ" },
      { municipio: "MACHADINHO D'OESTE" },
      { municipio: "MINISTRO ANDREAZZA" },
      { municipio: "MIRANTE DA SERRA" },
      { municipio: "MONTE NEGRO" },
      { municipio: "NOVA BRASILÂNDIA D'OESTE" },
      { municipio: "NOVA MAMORÉ" },
      { municipio: "NOVA UNIÃO" },
      { municipio: "NOVO HORIZONTE DO OESTE" },
      { municipio: "OURO PRETO DO OESTE" },
      { municipio: "PARECIS" },
      { municipio: "PIMENTA BUENO" },
      { municipio: "PIMENTEIRAS DO OESTE" },
      { municipio: "PORTO VELHO" },
      { municipio: "PRESIDENTE MÉDICI" },
      { municipio: "PRIMAVERA DE RONDÔNIA" },
      { municipio: "RIO CRESPO" },
      { municipio: "ROLIM DE MOURA" },
      { municipio: "SANTA LUZIA D'OESTE" },
      { municipio: "SÃO FELIPE D'OESTE" },
      { municipio: "SÃO FRANCISCO DO GUAPORÉ" },
      { municipio: "SÃO MIGUEL DO GUAPORÉ" },
      { municipio: "SERINGUEIRAS" },
      { municipio: "TEIXEIRÓPOLIS" },
      { municipio: "THEOBROMA" },
      { municipio: "URUPÁ" },
      { municipio: "VALE DO ANARI" },
      { municipio: "VALE DO PARAÍSO" },
      { municipio: "VILHENA" },
      { municipio: "GUAJARA MIRIM" },
      { municipio: "JI PARANA" },
    ],
    estado: "RO",
  },
  {
    municipios: [
      { municipio: "ABAÍRA" },
      { municipio: "ABARÉ" },
      { municipio: "ACAJUTIBA" },
      { municipio: "ADUSTINA" },
      { municipio: "ÁGUA FRIA" },
      { municipio: "AIQUARA" },
      { municipio: "ALAGOINHAS" },
      { municipio: "ALCOBAÇA" },
      { municipio: "ALMADINA" },
      { municipio: "AMARGOSA" },
      { municipio: "AMÉLIA RODRIGUES" },
      { municipio: "AMÉRICA DOURADA" },
      { municipio: "ANAGÉ" },
      { municipio: "ANDARAÍ" },
      { municipio: "ANDORINHA" },
      { municipio: "ANGICAL" },
      { municipio: "ANGUERA" },
      { municipio: "ANTAS" },
      { municipio: "ANTÔNIO CARDOSO" },
      { municipio: "ANTÔNIO GONÇALVES" },
      { municipio: "APORÁ" },
      { municipio: "APUAREMA" },
      { municipio: "ARAÇÁS" },
      { municipio: "ARACATU" },
      { municipio: "ARACI" },
      { municipio: "ARAMARI" },
      { municipio: "ARATACA" },
      { municipio: "ARATUÍPE" },
      { municipio: "AURELINO LEAL" },
      { municipio: "BAIANÓPOLIS" },
      { municipio: "BAIXA GRANDE" },
      { municipio: "BANZAÊ" },
      { municipio: "BARRA" },
      { municipio: "BARRA DA ESTIVA" },
      { municipio: "BARRA DO CHOÇA" },
      { municipio: "BARRA DO MENDES" },
      { municipio: "BARRA DO ROCHA" },
      { municipio: "BARREIRAS" },
      { municipio: "BARRO ALTO" },
      { municipio: "BARRO PRETO" },
      { municipio: "BARROCAS" },
      { municipio: "BELMONTE" },
      { municipio: "BELO CAMPO" },
      { municipio: "BIRITINGA" },
      { municipio: "BOA NOVA" },
      { municipio: "BOA VISTA DO TUPIM" },
      { municipio: "BOM JESUS DA LAPA" },
      { municipio: "BOM JESUS DA SERRA" },
      { municipio: "BONINAL" },
      { municipio: "BONITO" },
      { municipio: "BOQUIRA" },
      { municipio: "BOTUPORÃ" },
      { municipio: "BREJÕES" },
      { municipio: "BREJOLÂNDIA" },
      { municipio: "BROTAS DE MACAÚBAS" },
      { municipio: "BRUMADO" },
      { municipio: "BUERAREMA" },
      { municipio: "BURITIRAMA" },
      { municipio: "CAATIBA" },
      { municipio: "CABACEIRAS DO PARAGUAÇU" },
      { municipio: "CACHOEIRA" },
      { municipio: "CACULÉ" },
      { municipio: "CAÉM" },
      { municipio: "CAETANOS" },
      { municipio: "CAETITÉ" },
      { municipio: "CAFARNAUM" },
      { municipio: "CAIRU" },
      { municipio: "CALDEIRÃO GRANDE" },
      { municipio: "CAMACAN" },
      { municipio: "CAMAÇARI" },
      { municipio: "CAMAMU" },
      { municipio: "CAMPO ALEGRE DE LOURDES" },
      { municipio: "CAMPO FORMOSO" },
      { municipio: "CANÁPOLIS" },
      { municipio: "CANARANA" },
      { municipio: "CANAVIEIRAS" },
      { municipio: "CANDEAL" },
      { municipio: "CANDEIAS" },
      { municipio: "CANDIBA" },
      { municipio: "CÂNDIDO SALES" },
      { municipio: "CANSANÇÃO" },
      { municipio: "CANUDOS" },
      { municipio: "CAPELA DO ALTO ALEGRE" },
      { municipio: "CAPIM GROSSO" },
      { municipio: "CARAÍBAS" },
      { municipio: "CARAVELAS" },
      { municipio: "CARDEAL DA SILVA" },
      { municipio: "CARINHANHA" },
      { municipio: "CASA NOVA" },
      { municipio: "CASTRO ALVES" },
      { municipio: "CATOLÂNDIA" },
      { municipio: "CATU" },
      { municipio: "CATURAMA" },
      { municipio: "CENTRAL" },
      { municipio: "CHORROCHÓ" },
      { municipio: "CÍCERO DANTAS" },
      { municipio: "CIPÓ" },
      { municipio: "COARACI" },
      { municipio: "COCOS" },
      { municipio: "CONCEIÇÃO DA FEIRA" },
      { municipio: "CONCEIÇÃO DO ALMEIDA" },
      { municipio: "CONCEIÇÃO DO COITÉ" },
      { municipio: "CONCEIÇÃO DO JACUÍPE" },
      { municipio: "CONDE" },
      { municipio: "CONDEÚBA" },
      { municipio: "CONTENDAS DO SINCORÁ" },
      { municipio: "CORAÇÃO DE MARIA" },
      { municipio: "CORDEIROS" },
      { municipio: "CORIBE" },
      { municipio: "CORONEL JOÃO SÁ" },
      { municipio: "CORRENTINA" },
      { municipio: "COTEGIPE" },
      { municipio: "CRAVOLÂNDIA" },
      { municipio: "CRISÓPOLIS" },
      { municipio: "CRISTÓPOLIS" },
      { municipio: "CRUZ DAS ALMAS" },
      { municipio: "CURAÇÁ" },
      { municipio: "DÁRIO MEIRA" },
      { municipio: "DIAS D'ÁVILA" },
      { municipio: "DOM BASÍLIO" },
      { municipio: "DOM MACEDO COSTA" },
      { municipio: "ELÍSIO MEDRADO" },
      { municipio: "ENCRUZILHADA" },
      { municipio: "ENTRE RIOS" },
      { municipio: "ÉRICO CARDOSO" },
      { municipio: "ESPLANADA" },
      { municipio: "EUCLIDES DA CUNHA" },
      { municipio: "EUNÁPOLIS" },
      { municipio: "FÁTIMA" },
      { municipio: "FEIRA DA MATA" },
      { municipio: "FEIRA DE SANTANA" },
      { municipio: "FILADÉLFIA" },
      { municipio: "FIRMINO ALVES" },
      { municipio: "FLORESTA AZUL" },
      { municipio: "FORMOSA DO RIO PRETO" },
      { municipio: "GANDU" },
      { municipio: "GAVIÃO" },
      { municipio: "GENTIO DO OURO" },
      { municipio: "GLÓRIA" },
      { municipio: "GONGOGI" },
      { municipio: "GOVERNADOR MANGABEIRA" },
      { municipio: "GUAJERU" },
      { municipio: "GUANAMBI" },
      { municipio: "GUARATINGA" },
      { municipio: "HELIÓPOLIS" },
      { municipio: "IAÇU" },
      { municipio: "IBIASSUCÊ" },
      { municipio: "IBICARAÍ" },
      { municipio: "IBICOARA" },
      { municipio: "IBICUÍ" },
      { municipio: "IBIPEBA" },
      { municipio: "IBIPITANGA" },
      { municipio: "IBIQUERA" },
      { municipio: "IBIRAPITANGA" },
      { municipio: "IBIRAPUÃ" },
      { municipio: "IBIRATAIA" },
      { municipio: "IBITIARA" },
      { municipio: "IBITITÁ" },
      { municipio: "IBOTIRAMA" },
      { municipio: "ICHU" },
      { municipio: "IGAPORÃ" },
      { municipio: "IGRAPIÚNA" },
      { municipio: "IGUAÍ" },
      { municipio: "ILHÉUS" },
      { municipio: "INHAMBUPE" },
      { municipio: "IPECAETÁ" },
      { municipio: "IPIAÚ" },
      { municipio: "IPIRÁ" },
      { municipio: "IPUPIARA" },
      { municipio: "IRAJUBA" },
      { municipio: "IRAMAIA" },
      { municipio: "IRAQUARA" },
      { municipio: "IRARÁ" },
      { municipio: "IRECÊ" },
      { municipio: "ITABELA" },
      { municipio: "ITABERABA" },
      { municipio: "ITABUNA" },
      { municipio: "ITACARÉ" },
      { municipio: "ITAETÉ" },
      { municipio: "ITAGI" },
      { municipio: "ITAGIBÁ" },
      { municipio: "ITAGIMIRIM" },
      { municipio: "ITAGUAÇU DA BAHIA" },
      { municipio: "ITAJU DO COLÔNIA" },
      { municipio: "ITAJUÍPE" },
      { municipio: "ITAMARAJU" },
      { municipio: "ITAMARI" },
      { municipio: "ITAMBÉ" },
      { municipio: "ITANAGRA" },
      { municipio: "ITANHÉM" },
      { municipio: "ITAPARICA" },
      { municipio: "ITAPÉ" },
      { municipio: "ITAPEBI" },
      { municipio: "ITAPETINGA" },
      { municipio: "ITAPICURU" },
      { municipio: "ITAPITANGA" },
      { municipio: "ITAQUARA" },
      { municipio: "ITARANTIM" },
      { municipio: "ITATIM" },
      { municipio: "ITIRUÇU" },
      { municipio: "ITIÚBA" },
      { municipio: "ITORORÓ" },
      { municipio: "ITUAÇU" },
      { municipio: "ITUBERÁ" },
      { municipio: "IUIU" },
      { municipio: "JABORANDI" },
      { municipio: "JACARACI" },
      { municipio: "JACOBINA" },
      { municipio: "JAGUAQUARA" },
      { municipio: "JAGUARARI" },
      { municipio: "JAGUARIPE" },
      { municipio: "JANDAÍRA" },
      { municipio: "JEQUIÉ" },
      { municipio: "JEREMOABO" },
      { municipio: "JIQUIRIÇÁ" },
      { municipio: "JITAÚNA" },
      { municipio: "JOÃO DOURADO" },
      { municipio: "JUAZEIRO" },
      { municipio: "JUCURUÇU" },
      { municipio: "JUSSARA" },
      { municipio: "JUSSARI" },
      { municipio: "JUSSIAPE" },
      { municipio: "LAFAIETE COUTINHO" },
      { municipio: "LAGOA REAL" },
      { municipio: "LAJE" },
      { municipio: "LAJEDÃO" },
      { municipio: "LAJEDINHO" },
      { municipio: "LAJEDO DO TABOCAL" },
      { municipio: "LAMARÃO" },
      { municipio: "LAPÃO" },
      { municipio: "LAURO DE FREITAS" },
      { municipio: "LENÇÓIS" },
      { municipio: "LICÍNIO DE ALMEIDA" },
      { municipio: "LIVRAMENTO DE NOSSA SENHORA" },
      { municipio: "LUÍS EDUARDO MAGALHÃES" },
      { municipio: "MACAJUBA" },
      { municipio: "MACARANI" },
      { municipio: "MACAÚBAS" },
      { municipio: "MACURURÉ" },
      { municipio: "MADRE DE DEUS" },
      { municipio: "MAETINGA" },
      { municipio: "MAIQUINIQUE" },
      { municipio: "MAIRI" },
      { municipio: "MALHADA" },
      { municipio: "MALHADA DE PEDRAS" },
      { municipio: "MANOEL VITORINO" },
      { municipio: "MANSIDÃO" },
      { municipio: "MARACÁS" },
      { municipio: "MARAGOGIPE" },
      { municipio: "MARAÚ" },
      { municipio: "MARCIONÍLIO SOUZA" },
      { municipio: "MASCOTE" },
      { municipio: "MATA DE SÃO JOÃO" },
      { municipio: "MATINA" },
      { municipio: "MEDEIROS NETO" },
      { municipio: "MIGUEL CALMON" },
      { municipio: "MILAGRES" },
      { municipio: "MIRANGABA" },
      { municipio: "MIRANTE" },
      { municipio: "MONTE SANTO" },
      { municipio: "MORPARÁ" },
      { municipio: "MORRO DO CHAPÉU" },
      { municipio: "MORTUGABA" },
      { municipio: "MUCUGÊ" },
      { municipio: "MUCURI" },
      { municipio: "MULUNGU DO MORRO" },
      { municipio: "MUNDO NOVO" },
      { municipio: "MUNIZ FERREIRA" },
      { municipio: "MUQUÉM DO SÃO FRANCISCO" },
      { municipio: "MURITIBA" },
      { municipio: "MUTUÍPE" },
      { municipio: "NAZARÉ" },
      { municipio: "NILO PEÇANHA" },
      { municipio: "NORDESTINA" },
      { municipio: "NOVA CANAÃ" },
      { municipio: "NOVA FÁTIMA" },
      { municipio: "NOVA IBIÁ" },
      { municipio: "NOVA ITARANA" },
      { municipio: "NOVA REDENÇÃO" },
      { municipio: "NOVA SOURE" },
      { municipio: "NOVA VIÇOSA" },
      { municipio: "NOVO HORIZONTE" },
      { municipio: "NOVO TRIUNFO" },
      { municipio: "OLINDINA" },
      { municipio: "OLIVEIRA DOS BREJINHOS" },
      { municipio: "OURIÇANGAS" },
      { municipio: "OUROLÂNDIA" },
      { municipio: "PALMAS DE MONTE ALTO" },
      { municipio: "PALMEIRAS" },
      { municipio: "PARAMIRIM" },
      { municipio: "PARATINGA" },
      { municipio: "PARIPIRANGA" },
      { municipio: "PAU BRASIL" },
      { municipio: "PAULO AFONSO" },
      { municipio: "PÉ DE SERRA" },
      { municipio: "PEDRÃO" },
      { municipio: "PEDRO ALEXANDRE" },
      { municipio: "PIATÃ" },
      { municipio: "PILÃO ARCADO" },
      { municipio: "PINDAÍ" },
      { municipio: "PINDOBAÇU" },
      { municipio: "PINTADAS" },
      { municipio: "PIRAÍ DO NORTE" },
      { municipio: "PIRIPÁ" },
      { municipio: "PIRITIBA" },
      { municipio: "PLANALTINO" },
      { municipio: "PLANALTO" },
      { municipio: "POÇÕES" },
      { municipio: "POJUCA" },
      { municipio: "PONTO NOVO" },
      { municipio: "PORTO SEGURO" },
      { municipio: "POTIRAGUÁ" },
      { municipio: "PRADO" },
      { municipio: "PRESIDENTE DUTRA" },
      { municipio: "PRESIDENTE JÂNIO QUADROS" },
      { municipio: "PRESIDENTE TANCREDO NEVES" },
      { municipio: "QUEIMADAS" },
      { municipio: "QUIJINGUE" },
      { municipio: "QUIXABEIRA" },
      { municipio: "RAFAEL JAMBEIRO" },
      { municipio: "REMANSO" },
      { municipio: "RETIROLÂNDIA" },
      { municipio: "RIACHÃO DAS NEVES" },
      { municipio: "RIACHÃO DO JACUÍPE" },
      { municipio: "RIACHO DE SANTANA" },
      { municipio: "RIBEIRA DO AMPARO" },
      { municipio: "RIBEIRA DO POMBAL" },
      { municipio: "RIBEIRÃO DO LARGO" },
      { municipio: "RIO DE CONTAS" },
      { municipio: "RIO DO ANTÔNIO" },
      { municipio: "RIO DO PIRES" },
      { municipio: "RIO REAL" },
      { municipio: "RODELAS" },
      { municipio: "RUY BARBOSA" },
      { municipio: "SALINAS DA MARGARIDA" },
      { municipio: "SALVADOR" },
      { municipio: "SANTA BÁRBARA" },
      { municipio: "SANTA BRÍGIDA" },
      { municipio: "SANTA CRUZ CABRÁLIA" },
      { municipio: "SANTA CRUZ DA VITÓRIA" },
      { municipio: "SANTA INÊS" },
      { municipio: "SANTA LUZIA" },
      { municipio: "SANTA MARIA DA VITÓRIA" },
      { municipio: "SANTA RITA DE CÁSSIA" },
      { municipio: "SANTA TEREZINHA" },
      { municipio: "SANTALUZ" },
      { municipio: "SANTANA" },
      { municipio: "SANTANÓPOLIS" },
      { municipio: "SANTO AMARO" },
      { municipio: "SANTO ANTÔNIO DE JESUS" },
      { municipio: "SANTO ESTÊVÃO" },
      { municipio: "SÃO DESIDÉRIO" },
      { municipio: "SÃO DOMINGOS" },
      { municipio: "SÃO FELIPE" },
      { municipio: "SÃO FÉLIX" },
      { municipio: "SÃO FÉLIX DO CORIBE" },
      { municipio: "SÃO FRANCISCO DO CONDE" },
      { municipio: "SÃO GABRIEL" },
      { municipio: "SÃO GONÇALO DOS CAMPOS" },
      { municipio: "SÃO JOSÉ DA VITÓRIA" },
      { municipio: "SÃO JOSÉ DO JACUÍPE" },
      { municipio: "SÃO MIGUEL DAS MATAS" },
      { municipio: "SÃO SEBASTIÃO DO PASSÉ" },
      { municipio: "SAPEAÇU" },
      { municipio: "SÁTIRO DIAS" },
      { municipio: "SAUBARA" },
      { municipio: "SAÚDE" },
      { municipio: "SEABRA" },
      { municipio: "SEBASTIÃO LARANJEIRAS" },
      { municipio: "SENHOR DO BONFIM" },
      { municipio: "SENTO SÉ" },
      { municipio: "SERRA DO RAMALHO" },
      { municipio: "SERRA DOURADA" },
      { municipio: "SERRA PRETA" },
      { municipio: "SERRINHA" },
      { municipio: "SERROLÂNDIA" },
      { municipio: "SIMÕES FILHO" },
      { municipio: "SÍTIO DO MATO" },
      { municipio: "SÍTIO DO QUINTO" },
      { municipio: "SOBRADINHO" },
      { municipio: "SOUTO SOARES" },
      { municipio: "TABOCAS DO BREJO VELHO" },
      { municipio: "TANHAÇU" },
      { municipio: "TANQUE NOVO" },
      { municipio: "TANQUINHO" },
      { municipio: "TAPEROÁ" },
      { municipio: "TAPIRAMUTÁ" },
      { municipio: "TEIXEIRA DE FREITAS" },
      { municipio: "TEODORO SAMPAIO" },
      { municipio: "TEOFILÂNDIA" },
      { municipio: "TEOLÂNDIA" },
      { municipio: "TERRA NOVA" },
      { municipio: "TREMEDAL" },
      { municipio: "TUCANO" },
      { municipio: "UAUÁ" },
      { municipio: "UBAÍRA" },
      { municipio: "UBAITABA" },
      { municipio: "UBATÃ" },
      { municipio: "UIBAÍ" },
      { municipio: "UMBURANAS" },
      { municipio: "UNA" },
      { municipio: "URANDI" },
      { municipio: "URUÇUCA" },
      { municipio: "UTINGA" },
      { municipio: "VALENÇA" },
      { municipio: "VALENTE" },
      { municipio: "VÁRZEA DA ROÇA" },
      { municipio: "VÁRZEA DO POÇO" },
      { municipio: "VÁRZEA NOVA" },
      { municipio: "VARZEDO" },
      { municipio: "VERA CRUZ" },
      { municipio: "VEREDA" },
      { municipio: "VITÓRIA DA CONQUISTA" },
      { municipio: "WAGNER" },
      { municipio: "WANDERLEY" },
      { municipio: "WENCESLAU GUIMARÃES" },
      { municipio: "XIQUE-XIQUE" },
      { municipio: "SANTA TERESINHA" },
      { municipio: "XIQUE XIQUE" },
      { municipio: "MUQUEM DE SAO FRANCISCO" },
    ],
    estado: "BA",
  },
];
