import { FaPlus } from "react-icons/fa";
import { ButtonDefault } from "../../../../../../components/Button/ButtonDefault";
import { useEffect, useState } from "react";
import {
  getPreference,
  getSegments,
  getUF,
} from "../../../../../../services/getOptionsData";
import { SelectOption, StatesUfProps } from "../../../../../../Types/Variable";
import { SelectCheckbox } from "../../../../../../components/Select/SelectCheckbox";
import { SelectCheckBoxOfGroup } from "../../../../../../components/Select/SelectCheckBoxOfGroup";

interface SelectionSectionProps {
  segment: string[];
  counties: string[];
  countiesOptions: SelectOption[];
  state: StatesUfProps[];
  variables: number[];
  permissions: string[];
  setVariables: React.Dispatch<React.SetStateAction<number[]>>;
  setPermissions: React.Dispatch<React.SetStateAction<string[]>>;
  setSegment: React.Dispatch<React.SetStateAction<string[]>>;
  setCounties: React.Dispatch<React.SetStateAction<string[]>>;
  setState: React.Dispatch<React.SetStateAction<StatesUfProps[]>>;
}

export function SelectionSection({
  segment,
  counties,
  countiesOptions,
  state,
  variables,
  permissions,
  setVariables,
  setPermissions,
  setSegment,
  setCounties,
  setState,
}: SelectionSectionProps) {
  const [segmentOptions, setSegmentOptions] = useState<SelectOption[]>([]);
  const [stateOptions, setStateOptions] = useState<SelectOption[]>([]);
  const [variablesOptions, setVariablesOptions] = useState<SelectOption[]>([]);
  const [permissionsOptions, setPermissionsOptions] = useState<SelectOption[]>(
    []
  );

  useEffect(() => {
    getUF().then((states) => {
      //ordernar por nome
      const sortedStates = states.sort((a, b) => a.name.localeCompare(b.name));
      setStateOptions(
        sortedStates.map((state) => ({
          label: state.name,
          value: JSON.stringify(state),
        }))
      );
    });

    getPreference().then((preferences) => {
      const sortedPreferences = preferences.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setVariablesOptions(
        sortedPreferences.map((preference) => ({
          label: preference.name,
          value: preference.id.toString(),
        }))
      );
    });

    getSegments().then((segments) => {
      const sortedSegments = segments.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setSegmentOptions(
        sortedSegments.map((segment) => ({
          label: segment.name,
          value: segment.name,
        }))
      );
    });

    const pesmissions = [
      {
        label: "Best Area",
        value: "ROLE_BEST_AREA",
      },
      {
        label: "Entorno",
        value: "ROLE_ENTORNO",
      },
      {
        label: "Raio de Exclusividade",
        value: "ROLE_RAIO_EXCLUSIVIDADE",
      },
      {
        label: "Cluster",
        value: "ROLE_CLUSTER",
      },
      {
        label: "Free Map",
        value: "ROLE_FREEMAP",
      },
      {
        label: "Heatmap",
        value: "ROLE_HEATMAP",
      },
    ];

    setPermissionsOptions(pesmissions);
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <SelectCheckbox
          defaultValue={variables.map((variable) => variable.toString())}
          style={{ width: "100%", marginRight: "16px" }}
          placeholder="Selecionar variáveis"
          options={variablesOptions}
          onChange={(value) => {
            setVariables(value.map((variable) => parseInt(variable)));
          }}
        />
        <SelectCheckbox
          defaultValue={permissions}
          style={{ width: "100%" }}
          placeholder="Selecionar permissões"
          options={permissionsOptions}
          onChange={(value) => {
            setPermissions(value.map((variable) => variable));
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "20px",
          flexDirection: "row",
        }}
      >
        <SelectCheckbox
          defaultValue={segment}
          style={{ width: "100%", marginRight: "16px" }}
          placeholder="Selecionar segmentos"
          options={segmentOptions}
          onChange={(value) => {
            setSegment(value.map((variable) => variable));
          }}
        />
        <SelectCheckbox
          defaultValue={state.map((state) => JSON.stringify(state))}
          style={{ width: "100%" }}
          placeholder="Selecionar estados"
          options={stateOptions}
          onChange={(value) => {
            setState(value.map((variable) => JSON.parse(variable)));
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          paddingTop: "20px",
          flexDirection: "row",
          visibility: countiesOptions.length > 0 ? "visible" : "hidden",
        }}
      >
        <SelectCheckBoxOfGroup
          defaultValue={counties}
          style={{ width: "calc(50% - 8px)" }}
          placeholder="Selecionar cidades"
          options={countiesOptions}
          onChange={(value) => {
            setCounties(value.map((variable) => variable));
          }}
        />
      </div>
      <ButtonDefault
        style={{
          marginTop: 19,
          marginRight: "auto",
          marginLeft: 0,
          width: 208,
        }}
        size="medium"
        title="NOVO SEGMENTO"
        icon={<FaPlus />}
        iconPosition="right"
        border={true}
        color={"secondary"}
        borderRadius={40}
        onClick={() => {}}
      />
    </>
  );
}
