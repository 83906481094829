import React, { CSSProperties, useEffect, useRef } from "react";
import { ButtonDefault } from "../Button/ButtonDefault";
import { HiXMark } from "react-icons/hi2";
import { Icon } from "../icon/Icon";
import "./ModalDefault.css";

interface ModalDefaultProps {
  icon: React.ReactElement;
  title: string;
  styleContainer?: CSSProperties;
  styleContent?: CSSProperties;
  styleHeader?: CSSProperties;
  styleBody?: CSSProperties;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  canclose?: boolean;
}

export function ModalDefault({
  icon,
  title,
  styleContainer,
  styleContent,
  styleHeader,
  styleBody,
  setModalOpen,
  children,
  canclose = true,
}: ModalDefaultProps) {
  const modalContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalContainerRef.current &&
        !modalContainerRef.current.contains(event.target as Node)
      ) {
        if (canclose) {
          setModalOpen(false);
        }
      }
    };

    // Adicione o ouvinte de evento quando o componente for montado
    document.addEventListener("mousedown", handleClickOutside);

    // Remova o ouvinte de evento quando o componente for desmontado
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setModalOpen]);

  return (
    <div className="modal-container" style={styleContainer}>
      <div
        className="modal-content"
        style={styleContent}
        ref={modalContainerRef}
      >
        <div className="modal-header" style={styleHeader}>
          <div className="modal-title">
            <Icon icon={icon} color={"secondary"} size={24} />
            <p>{title}</p>
          </div>
          <ButtonDefault
            icon={<HiXMark />}
            color={"secondary"}
            transparent={true}
            onClick={() => {
              setModalOpen(false);
            }}
          />
        </div>
        <div className="modal-body" style={styleBody}>
          {children}
        </div>
      </div>
    </div>
  );
}
