import React from "react";

interface Props {
  fill?: string;
  size?: number;
}

export const PentagonIcon = ({ fill, size = 20 }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="-1 -1 19 20"
      fill={fill}
      fillOpacity="0.2"
    >
      <path
        d="M7.685.256a.5.5 0 0 1 .63 0l7.421 6.03a.5.5 0 0 1 .162.538l-2.788 8.827a.5.5 0 0 1-.476.349H3.366a.5.5 0 0 1-.476-.35L.102 6.825a.5.5 0 0 1 .162-.538l7.42-6.03Z"
        stroke={fill}
        strokeWidth="1"
      />
    </svg>
  );
};
