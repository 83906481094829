import BackgroundLoading from "../assets/images/bg-loading.png";
import BackgroundLogin from "../assets/images/bolhas.png";
import Line from "../assets/images/line-1.png";
import Pin from "../assets/images/pin-loading.png";

const images = {
  BackgroundLoading,
  BackgroundLogin,
  Line,
  Pin,
};

export default images;
