import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { colors } from "../../assets/colors";
import { SelectOptionRadio } from "../../Types/Variable";
import { Checkbox2 } from "../Checkbox/CheckBox2";
import { CaretDownOutlined } from "@ant-design/icons";
import fonts from "../../config/fonts";

interface SelectProps {
  defaultValue?: string[];
  placeholder?: React.ReactNode;
  options?: SelectOptionRadio[];
  onChange: (selectedValues: string[]) => void; // Modifique o tipo da função onChange
  borderRadius?: number;
  style?: CSSProperties;
}

type StylesObject = Record<string, CSSProperties>;

const backgroundColor = colors.default.primary.background;
const grayColor = colors.default.disabled.content;
const shadowColor = colors.shadow;

export function SelectCheckbox({
  options,
  defaultValue,
  borderRadius,
  placeholder,
  onChange,
  style,
}: SelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedOptions(defaultValue);
    }
  }, [defaultValue]);

  const handleOptionClick = (option: SelectOptionRadio) => {
    if (option.value)
      if (selectedOptions.includes(option.value)) {
        // Se a opção já estiver selecionada, remova-a
        const updatedSelection = selectedOptions.filter(
          (value) => value !== option.value
        );
        setSelectedOptions(updatedSelection);
        onChange(updatedSelection);
      } else {
        // Caso contrário, adicione-a à seleção
        setSelectedOptions([...selectedOptions, option.value]);
        onChange([...selectedOptions, option.value]);
      }
  };

  useEffect(() => {
    if (selectedOptions.length === options?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedOptions]);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedOptions([]);
      onChange([]);
    } else {
      if (!options) return;
      setSelectedOptions(options.map((option) => option.value as string));
      onChange(options.map((option) => option.value as string));
    }
    setSelectAll(!selectAll);
  };

  return (
    <div style={{ ...selectStyles.container, ...style }} ref={selectRef}>
      <div
        style={{ ...selectStyles.header, borderRadius: borderRadius || 5 }}
        onClick={toggleDropdown}
      >
        <div
          style={{
            ...selectStyles.selectedOption,
          }}
        >
          {placeholder || "Select options"}
        </div>
        <CaretDownOutlined
          style={{
            ...selectStyles.caretIcon,
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)", // Adicionar a rotação do ícone aqui
          }}
          className={isOpen ? "open" : ""}
        />
      </div>
      {isOpen && options && (
        <div style={selectStyles.optionList}>
          <Checkbox2
            style={{
              border: "none",
              paddingLeft: 10,
              fontWeight: "bold",
            }}
            label={"Selecionar todos"}
            checked={selectAll}
            color={grayColor}
            onChange={() => handleSelectAll()}
          />
          {options.map((option) => (
            <div key={option.value} style={selectStyles.styleSuboptionItem}>
              <Checkbox2
                style={{
                  border: "none",
                  paddingLeft: 10,
                }}
                label={option.label as string}
                checked={
                  option.value ? selectedOptions.includes(option.value) : false
                }
                color={grayColor} // Defina a cor desejada
                onChange={() => handleOptionClick(option)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const selectStyles: StylesObject = {
  container: {
    position: "relative",
    width: "100%",
    height: 48,
    color: grayColor,
    fontSize: fonts.sizes.medium,
    fontWeight: "normal",
  },
  header: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    padding: 8,
    paddingLeft: 12,
    border: `1px solid ${grayColor}`,
    display: "flex",
    alignItems: "center",
  },
  selectedOption: {
    marginRight: 8,
  },
  caretIcon: {
    marginLeft: "auto",
  },
  optionList: {
    position: "absolute",
    zIndex: 9999,
    top: "105%",
    width: "100%",
    border: `1px solid ${grayColor}`,
    borderRadius: 5,
    background: backgroundColor,
    boxShadow: `0px 2px 8px ${shadowColor}`,
    maxHeight: 300, // Alterado para maxHeight
    overflowY: "auto", // Alterado para scroll vertical
    padding: 8, // Adicionado espaçamento
  },
  styleSuboptionItem: {
    cursor: "pointer",
    height: 48,
    display: "flex",
    alignItems: "center",
  },
};
