import { colors } from "./colors";

const actualHEXColors = colors.actualHEXColors;

export function hsl2rgb(h: number, s: number, l: number): number[] {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [r * 255, g * 255, b * 255];
}

export function hue2rgb(p: number, q: number, t: number): number {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}

export function getNextColor(colorIndex: number) {
  let color;
  if (colorIndex < actualHEXColors.length) {
    color = actualHEXColors[colorIndex];
  } else {
    // Generate random color
    color = getRandomColor();
  }
  return color;
}

export function getRandomColor() {
  const o = Math.round,
    r = Math.random,
    s = 255;
  const red = o(r() * s);
  const green = o(r() * s);
  const blue = o(r() * s);

  return rgbToHEX(red, green, blue);
}

export function rgbToHSL(
  r: number,
  g: number,
  b: number
): { hue: number; sat: string; lum: string } {
  r = r / 255;
  g = g / 255;
  b = b / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const lum = (max + min) / 2;
  let hue: number;
  let sat: number;
  if (max === min) {
    hue = 0;
    sat = 0;
  } else {
    const c = max - min;
    sat = c / (1 - Math.abs(2 * lum - 1));
    switch (max) {
      case r:
        hue = (g - b) / c + (g < b ? 6 : 0);
        break;
      case g:
        hue = (b - r) / c + 2;
        break;
      case b:
        hue = (r - g) / c + 4;
        break;
      default:
        hue = 0;
    }
  }

  return {
    hue: Math.round(hue * 60),
    sat: `${Math.round(sat * 100)}%`,
    lum: `${Math.round(lum * 100)}%`,
  };
}

export function componentToHex(c: number): string {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

export function rgbToHEX(r: number, g: number, b: number): string {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

export function rgbaToHex(color: string): string {
  color = color.replace(", 1)", ")");
  color = color.replace("a", "");
  color = color.replace("rgb(", "").replace(")", "");
  const colors = color.split(",");
  return rgbToHEX(+colors[0], +colors[1], +colors[2]);
}

export function hexToRgb(
  hex: string
): { r: number; g: number; b: number } | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function setPolygonClusterColor(
  type: number,
  slider_value: number,
  isHsl: boolean
): string {
  switch (type) {
    case 0:
      return isHsl
        ? "hsl(0, 0%, 40%)"
        : `rgba(169, 169, 169, ${slider_value / 100})`; //nao representativo
    case 1:
      return isHsl
        ? "hsl(0, 100%, 40%)"
        : `rgba(255, 0, 0, ${slider_value / 100})`; //menor
    case 2:
      return isHsl
        ? "hsl(100, 100%, 40%)"
        : `rgba(0, 255, 0, ${slider_value / 100})`; //maior
    case 3:
      return isHsl
        ? "hsl(0, 100%, 100%)"
        : `rgba(255, 255, 255, ${slider_value / 100})`; //igual
    case 4:
      return isHsl
        ? "hsl(280, 40%, 40%)"
        : `rgba(123, 104, 238,${slider_value / 100})`; //potencial
    default:
      return "";
  }
}

export function strRGB(strRGB: any, strAlpha: any) {
  var match = strRGB.match(
    /rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/
  );
  return (
    "rgba(" + match[1] + "," + match[2] + "," + match[3] + "," + strAlpha + ")"
  );
}
