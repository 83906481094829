import { CSSProperties } from "react";
import { colors } from "../../assets/colors";
import fonts from "../../config/fonts";

interface TitleProps {
  children?: string;
  style?: CSSProperties;
  size?: "default" | "large";
}

const color = colors.default.primary.content;

export function TitleModal({ children, size = "default", style }: TitleProps) {
  const titleSize = {
    default: 18,
    large: 48,
  };
  return (
    <h1
      style={{
        fontSize: titleSize[size],
        color,
        fontFamily: fonts.expBold,
        ...style,
      }}
    >
      {children}
    </h1>
  );
}
