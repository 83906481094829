import L from "leaflet";
import { PolygonParamList } from "../../../../../Types/interfacs";
import { MarkerProps } from "../types";
import { getDistanceBetweenPoints } from "../getDistanceBetweenPoints";

interface EditPinProps {
  map: L.Map;
  pinsLayer: L.LayerGroup;
  outpinRetangle?: L.Rectangle;
  pin: MarkerProps;
  moveMarker?: L.Marker;
  deleteMarker?: L.Marker;
  setPolygon?: React.Dispatch<React.SetStateAction<PolygonParamList>>;
}

export function EditPin({ pinsLayer, pin, deleteMarker }: EditPinProps) {
  const points = pin.getLatLng() as L.LatLng;

  function addDeleteIcon() {
    deleteMarker?.addTo(pinsLayer);
  }

  addDeleteIcon();

  pin.on("mousedown", mouseDownMarker);

  function mouseDownMarker(e: L.LeafletMouseEvent) {
    if (e.originalEvent.button !== 0) return;

    removeOnMove();
    pin.on("mousemove", moveMarker);
    pin.on("mouseup", mouseUpMarker);
  }

  function moveMarker(e: L.LeafletMouseEvent) {
    const newPoint = e.latlng;
    const distance = getDistanceBetweenPoints(points, newPoint);

    if (distance > 0) {
      deleteMarker?.remove();
    }

    deleteMarker?.setLatLng(newPoint);
    pin.setLatLng(newPoint);
  }

  function mouseUpMarker(e: L.LeafletMouseEvent) {
    pin.off("mousemove", moveMarker);
    pin.off("mouseup", mouseUpMarker);
    addDeleteIcon();
  }

  function removeOnMove() {
    deleteMarker?.remove();
  }
}
